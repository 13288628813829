<template>
  <div>
    <div class="mainHeading">Access Logs</div>
    <div class="mainDescription">
      <br/>
    </div>
    <div class="grayCard flexWrap">
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          LAST 24 HOURS
        </div>
        <div class="whiteSubCard-body">
          <div>3</div>
          <div class="bodyText">users</div>
          <div class="bodyPrimaryLinkText">View details</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          LAST 30 DAYS
          <div class="bodyText"></div>
        </div>
        <div class="whiteSubCard-body">
          <div>3</div>
          <div class="bodyText">users</div>
          <div class="bodyPrimaryLinkText">View details</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth mb-0">
        <div class="whiteSubCard-head">
          MOBILE APP USAGE
        </div>
        <div class="whiteSubCard-body">
          <div>3</div>
          <div class="bodyPrimaryLinkText mt-25px">View active sessions</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth mb-0">
        <div class="whiteSubCard-head">
          NEVER LOGGED IN
        </div>
        <div class="whiteSubCard-body">
          <div>9</div>
          <div class="bodyText">employees</div>
          <div class="bodyPrimaryLinkText">Re-send welcome email</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'AccessLogs'
}
</script>

<style>

</style>