<template>
  <div>
    <modal 
      height="auto" 
      :name="modalName" 
      class="center-zoomIn-transition v2 m-w-500 addUserModalV2" 
      @before-open="onBeforeOpen($event)" 
      :scrollable="true" 
      @before-close="onBeforeClose($event)"
    >
      <div class="dialer-edit-user">
        <div class="dialer-edit">
          <div class="dialer-form">

            <div class="dialer-edit-header pt-0 pb-0 d-block">
              <div class="d-flex justify-content-between align-items-center">
                <h2 class="dialer-edit-title">
                  Invite user
                  <vb-icon 
                    icon="checkSlotsModal-icon" 
                    class="ml-2 cursor_pointer checkSlotsModal-icon" 
                    height="22px" 
                    width="22px"
                    :disabled="api.add_user.send" 
                    @click="$modal.show('CheckedServiceAvailableModal',{service: 'user',onProceed: adduser,})"
                  /> 
                </h2> 
                <div class="dialer-edit-actions">
                  <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
                  <a v-else class="newCloseButton" @click="$modal.hide(modalName)">
                    <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                    <span class="newCloseButton-textPart">Close</span>
                  </a>
                </div>
              </div>
              <div class="vm--modal-text">
                Please provide the new user's details below and configure your account preferences.
              </div>
            </div>
            
            <div class="md-mar-vertical">
              <form @submit.prevent="adduser()">
                <b-alert :show="!!api.add_user.error_message" variant="danger">{{ api.add_user.error_message }}</b-alert>
                <div class="d-flex md-mar-top">
                  <!-- <div class="dialer-input-field input-consistent-inner-shadows dialer-input--error pr-2 w-50"> -->
                  <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error pr-2 w-50">
                    <label>First name</label>
                    <input 
                      :disabled="api.add_user.send" 
                      v-model="forms.add_user.firstname" 
                      :maxlength="$v.forms.add_user.firstname.$params.maxLength.max" 
                      onkeypress="return /[A-Za-z]/.test(event.key)" 
                      placeholder="Enter first name" 
                      class="bg-white w-100" 
                      type="text" 
                    />
                    <p 
                      v-if="(forms.add_user.submitted && $v.forms.add_user.firstname.$invalid) || api.add_user.validation_errors.firstname" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <span v-if="!$v.forms.add_user.firstname.required">* First name is required</span>
                      <span v-if="!$v.forms.add_user.firstname.alpha">* First name should be only alphabet character only</span>
                      <span v-else-if="!$v.forms.add_user.firstname.maxLength">* First name can be maximum {{$v.forms.add_user.firstname.$params.maxLength.max}} character</span>
                      <span v-for="(em, i) in api.add_user.validation_errors.firstname" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                  
                  <!-- <div class="dialer-input-field input-consistent-inner-shadows dialer-input--error pl-2 w-50"> -->
                  <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error pl-2 w-50">
                    <label>Last name</label>
                    <input 
                      :disabled="api.add_user.send" 
                      v-model="forms.add_user.lastname" 
                      :maxlength="$v.forms.add_user.lastname.$params.maxLength.max" 
                      onkeypress="return /[A-Za-z]/.test(event.key)" 
                      placeholder="Enter last name" 
                      class="w-100 bg-white" 
                      type="text" 
                    />
                    <p 
                      v-if="(forms.add_user.submitted && $v.forms.add_user.lastname.$invalid) || api.add_user.validation_errors.lastname" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <!-- <span v-if="!$v.forms.add_user.lastname.required">* Last Name is required</span> -->
                      <span v-if="!$v.forms.add_user.lastname.alpha">* Last name should be only alphabet character only</span>
                      <span v-else-if="!$v.forms.add_user.lastname.maxLength">* Last name can be maximum {{$v.forms.add_user.lastname.$params.maxLength.max}} character</span>
                      <span v-for="(em, i) in api.add_user.validation_errors.lastname" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                
                <!-- <div class="dialer-input-field input-consistent-inner-shadows dialer-input--error md-mar-top"> -->
                <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error md-mar-top">
                  <label>Email *</label>
                  <input :disabled="api.add_user.send" v-model="forms.add_user.email" placeholder="Enter email" class="w-100 bg-white" type="text" />
                  <p 
                    v-if="(forms.add_user.submitted && $v.forms.add_user.email.$invalid) || api.add_user.validation_errors.username || api.add_user.validation_errors.email" 
                    class="text text-small text-danger animated bounceIntop mb-0"
                  >
                    <span v-if="!$v.forms.add_user.email.required">* Email is required</span>
                    <span v-else-if="!$v.forms.add_user.email.email">* Email is not valid</span>
                    <span v-else-if="!$v.forms.add_user.email.minLength">* Email should be minimum {{$v.forms.add_user.lastname.$params.minLength.min}} character</span>
                    <span v-for="(em, i) in api.add_user.validation_errors.username" :key="i">* {{ em }}</span>
                    <span v-for="(em, i) in api.add_user.validation_errors.email" :key="i">* {{ em }}</span>
                  </p>
                </div>

                <div class="dialer-box-header md-mar-vertical">
                  <div class="d-flex align-items-center">
                    <span class="mr-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Set as admin</span>
                    <div class="dialer-box p-0 bg-transparent mb-0">
                      <b-form-checkbox class="dialer-switch textAtStart p-0" switch v-model="forms.add_user.role" name="check-button"/>
                    </div>
                  </div>
                  <p 
                    v-if="(forms.add_user.submitted && $v.forms.add_user.role.$invalid) || api.add_user.validation_errors.role" 
                    class="text text-small text-danger animated bounceIntop mb-0"
                  >
                    <span v-if="$v.forms.add_user.role.required">* Role is required</span>
                    <span v-for="(em, i) in api.add_user.validation_errors.role" :key="i">* {{ em }}</span>
                  </p>
                </div>

                <div v-if="!isFreePlan" class="dialer-box-header md-mar-bottom">
                  <div class="d-flex align-items-center">
                    <span class="mr-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Skip number</span>
                    <div class="dialer-box p-0 bg-transparent mb-0">
                      <b-form-checkbox 
                        class="dialer-switch p-0" 
                        :disabled="api.add_user.send" 
                        v-model="forms.add_user.skip_number" 
                        name="check-button"
                      />
                    </div>
                  </div>
                  <p 
                    v-if="api.add_user.validation_errors.skip_number" 
                    class="text text-small text-danger animated bounceIntop mb-0"
                  >
                    <span v-for="(em, i) in api.add_user.validation_errors.skip_number" :key="i">* {{ em }}</span>
                  </p>
                </div>

                <template v-if="isNumberSelect">
                  <div :class="`d-flex align-items-center justify-content-between mb-2 lg-mar-top ${forms.add_user.number.number?'numberSelected':''}`">
                    <template>
                      <h2 class="dialer-edit-title mb-0">{{ forms.add_user.number.number | number_formater }}</h2>
                    </template>
                    <button 
                      type="button" 
                      @click="$modal.show(`${modalName}-AddNumbersModal`,{ add_user: true, })" 
                      class="dialer-button dialer-button-primary w-100" 
                      :disabled="api.add_user.send"
                    >
                      <template>{{!forms.add_user.number.number ? 'Select number' : 'Change number'}}</template>
                    </button>
                  </div>
                  <p v-if="forms.add_user.submitted && $v.forms.add_user.number.$invalid" class="text text-small text-danger animated bounceIntop mb-0">
                    <span v-if="!$v.forms.add_user.number.required">* Number is required</span>
                  </p>
                </template>
                
                <div class="d-flex align-items-center justify-content-end mb-2 lg-mar-top">
                  <button type="submit" class="dialer-button dialer-button-primary w-100" :disabled="api.add_user.send">
                    <vb-spinner v-if="api.add_user.send" />
                    <template v-else>Invite user</template>
                  </button>
                </div>

              </form>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <AddNumbersModal :modalName="`${modalName}-AddNumbersModal`" @select-number="forms.add_user.number=$event" />
  </div>
</template>

<script>
import { check_service } from '@/mixin';
import {
  required,
  requiredIf,
  minLength,
  maxLength,
  email,
  alpha,
} from "vuelidate/lib/validators";

import { /*events,*/ VOIP_API } from "../../utils";
import AddNumbersModal from "./AddNumbersModal.vue";
import { mapGetters } from 'vuex';

export default {
  name: "AddUserByEmail",
  components: {
    AddNumbersModal,
  },
  mixins: [
    check_service
  ],
  props: {
    modalName: {
      type: String,
      default: 'AddUserByEmail',
    },
  },
  data() {
    return {
      forms: {
        add_user: this.$helperFunction.formInstance({
          data: {
            username: '',
            email: '',
            lastname: '',
            firstname: '',
            password: '',
            role: false,
            skip_number: false,
            number: {},
          }
        }),
      },
      api: {
        add_user: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
      },
      conditions: {
        user_password_show: false,
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'isFreePlan'
    ]),
    isNumberSelect(){ return !this.isFreePlan && !this.forms.add_user.skip_number },
  },
  validations: {
    forms: {
      add_user: {
        email: {
          required, 
          email,
          minLength: minLength(4), 
        },
        lastname: { 
          // required,
          maxLength: maxLength(16),
          alpha,
        },
        firstname: {
          required,  
          maxLength: maxLength(16),
          alpha,
        },
        number: {
          required: requiredIf(function () {
            return this.isNumberSelect;
          }),
        },
        role: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      // TODO - 
    },
    onBeforeClose(){
      this.forms.add_user.reset()
      this.api.add_user.reset()
      this.conditions.user_password_show=false
    },
    adduser(){
      let vm = this
      vm.forms.add_user.submitted=true
      vm.$v.forms.add_user.$touch()
      if(vm.api.add_user.send || vm.$v.forms.add_user.$invalid) return;
      vm.api.add_user.validation_errors={}
      vm.api.add_user.error_message=''
      vm.api.add_user.send=true
      vm.checkService({
        service: 'user',
        async then(){
          await VOIP_API.endpoints.users.create({
            username: vm.forms.add_user.email,
            email: vm.forms.add_user.email,
            lastname: vm.forms.add_user.lastname,
            firstname: vm.forms.add_user.firstname,
            number: vm.forms.add_user.number,
            role: vm.forms.add_user.role ? 1 : 0,
            accountcode: vm.getCurrentUser?.account,
            skip_number: vm.isNumberSelect ? '0' : '1',
            service: "user",
            action: 'invite'
          })
          // vm.$store.commit('setAddServiceQueue',{
          //   type: 'user',
          //   name: `${vm.forms.add_user.firstname} ${vm.forms.add_user.lastname}`,
          //   username: vm.forms.add_user.email,
          // })
          vm.$emit("user-added");
          vm.$modal.hide(vm.modalName)
          // vm.$root.$emit(events.check_pending_services)
        },
        catch(ex){
          vm.api.add_user.validation_errors=ex.own_errors || {}
          vm.api.add_user.error_message=ex.own_message || ''
        },
        finally(){
          vm.api.add_user.send=false
          vm.forms.add_user.submitted=false
        },
      })
    },
  },
};
</script>


<style>
.slide-ltr-enter-active {
  animation: slide-ltr 0.5s reverse;
}
.slide-ltr-leave-active {
  animation: slide-ltr 0.5s;
}
@keyframes slide-ltr {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>