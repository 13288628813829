var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `dialer-resizerArea content-area content-area-new ${_vm.getCurrentUser.suspended ? 'accountSuspended' : ''}`
  }, [_c('div', {
    class: ['content-area-body d-flex w-100 h-100 position-relative', _vm.GET_LOCAL_SETTING_DASHBOARD.design.flip ? 'flex-row-reverse' : '']
  }, [_vm.logs ? _c('DialPanel', {
    ref: "ActivityLog",
    staticClass: "DialPanelMobileLike",
    style: `width:${_vm.GET_LOCAL_SETTING_DASHBOARD.design.width}%`,
    on: {
      "click-play": function ($event) {
        return _vm.playAudio($event);
      },
      "make-call": function ($event) {
        return _vm.makeCall();
      },
      "call-info": function ($event) {
        _vm.call_info = $event;
      }
    }
  }) : _vm._e(), _vm.sound_file ? [_c('div', {
    staticClass: "AudioPlayerShownInsideCallLogsList",
    style: `width: ${_vm.GET_LOCAL_SETTING_DASHBOARD.design.width}%`
  }, [_c('div', {
    staticClass: "AudioPlayerShownInsideCallLogsList-inner"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm.sound_file,
      "autoPlay": true,
      "design": 'NoTranscript'
    }
  }), _c('b-button', {
    staticClass: "p-0 bg-transparent border-0",
    on: {
      "click": function ($event) {
        _vm.sound_file = null;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "squared-close-icon",
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  })], 1)], 1)])] : _vm._e(), _c('div', {
    staticClass: "panel-new",
    style: `width:calc(100% - ${_vm.GET_LOCAL_SETTING_DASHBOARD.design.width}%)`
  }, [_c('transition', {
    attrs: {
      "name": "slide-fade"
    }
  }, [_vm.call_info ? _c('CallInfo', {
    class: [_vm.GET_LOCAL_SETTING_DASHBOARD.design.width == 75 ? 'min-width-357' : ''],
    attrs: {
      "callInfo": _vm.call_info
    },
    on: {
      "close": function ($event) {
        _vm.call_info = null;
      }
    }
  }) : _vm._e()], 1), _vm.dialer ? _c('Dialpad', {
    ref: "audiocall"
  }) : _vm._e()], 1)], 2), _vm.getCurrentUser.suspended ? _c('div', {
    staticClass: "accountSuspended_container"
  }, [_c('div', {
    staticClass: "accountSuspended_overlay"
  }), _vm._m(0)]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "accountSuspended_inner"
  }, [_c('div', {
    staticClass: "accountSuspended_heading"
  }, [_vm._v(" Suspended Account ")]), _c('div', {
    staticClass: "accountSuspended_text"
  }, [_vm._v(" Outbound calls are currently blocked ")]), _c('div', {
    staticClass: "accountSuspended_button"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary"
  }, [_vm._v(" Please Contact Support ")])])]);

}]

export { render, staticRenderFns }