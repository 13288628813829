<template>
  <modal 
    class="center-zoomIn-transition v2 TopUpSettings" 
    width="50%" 
    height="auto" 
    :scrollable="true" 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()" 
    :clickToClose="false"
  >
    <div class="">
      <div class="dialer-edit-header pt-0 mt-0">
        <h2 class="dialer-settings-title newer mb-0">Topup setting</h2>
        <div class="dialer-edit-actions">
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else class="" @click="$modal.hide(modalName)">
            <button type="button" class="newCloseButton"> 
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </a>
        </div>
      </div>
      <div class="dialer-tabs">
        <div>
          <template v-if="!isEmpty(response.topup_settings)">
            Credit per day limit: {{ response.topup_settings | get_property('per_day_auto_topup_limit',0) }}
            Credit per month limit: {{ response.topup_settings | get_property('per_month_auto_topup_limit',0) }}
          </template>
          <b-form @submit.prevent="updateTopupSetting()">
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main">Auto top up</div>
                  <div class="latestGreyBox-descriptionText mr-16px">
                    Automatically recharge your account through your default payment method anytime 
                    your balance runs below the listed amount.
                  </div>
                </div>
                <b-form-checkbox class="newerSwitch" v-model="forms.update_topup_setting.voip_enable" name="check-button" switch />
              </div>
              <template v-if="forms.update_topup_setting.voip_enable">
                <div class="latestGreyBox-heading-main-18px mt-20px text-left">My balance falls below {{ forms.update_topup_setting.voip_balance_limit ? forms.update_topup_setting.voip_balance_limit : ''}}</div>
                <b-form-group id="phone-numbers-radio" class="whiteBoxWithBorderInsideGreyBox withoutBorder p-4 mt-16px topUpCheckbox-container">
                  <template v-if="api.fetch_topup_setting.send">
                    <div class="latestShimmerDesign m-2" v-for="n in 8" :key="n.id" style="height:40px;width:calc(25% - 24px)"></div>
                  </template>
                  <template v-else>
                    <b-form-radio 
                      :disabled="api.update_topup_setting.send || api.fetch_topup_setting.send"
                      class="topUpCheckbox" v-for="n in topupThreshold" :key="n"
                      :name="`${n}`" 
                      :value="n"
                      v-model="selectedSetting.limit"
                      @change="forms.update_topup_setting.voip_balance_limit = n"
                    >
                      <span class="topUpCheckbox-textPart">£{{ n }}</span>
                    </b-form-radio>
                  </template>
                </b-form-group>
                <p v-if="(forms.update_topup_setting.submitted && $v.forms.update_topup_setting.voip_balance_limit.$invalid) || api.update_topup_setting.validation_errors.voip_balance_limit" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.update_topup_setting.voip_balance_limit.required">* Credit limit field is required</span>
                  <span v-else-if="!$v.forms.update_topup_setting.voip_balance_limit.maxValue">* Credit limit maximum {{ $v.forms.update_topup_setting.voip_balance_limit.$params.maxValue.max }} only</span>
                  <span v-for="(em, i) in api.update_topup_setting.validation_errors.voip_balance_limit" :key="i">* {{ em }}</span>
                </p>

                <div class="latestGreyBox-heading-main-18px mt-20px text-left">Automatically recharge for</div>
                <b-form-group id="phone-numbers-radio" class="whiteBoxWithBorderInsideGreyBox withoutBorder p-4 mt-16px topUpCheckbox-container">
                  <template v-if="api.fetch_topup_setting.send || api.fetch_products.send">
                    <div class="latestShimmerDesign m-2" v-for="n in 8" :key="n.id" style="height:40px;width:calc(25% - 24px)"></div>
                  </template>
                  <template v-else>
                    <b-form-radio 
                      :disabled="api.update_topup_setting.send || api.fetch_topup_setting.send || api.fetch_products.send"
                      class="topUpCheckbox" 
                      v-for="product in response.products" 
                      :key="product.id"
                      :name="product.id" 
                      :value="product.id"
                      v-model="selectedSetting.autoRecharge"
                      @change="forms.update_topup_setting.voip_amount = product.price;forms.update_topup_setting.topup_product_id = product.real_id"
                    >
                      <span class="topUpCheckbox-textPart">£{{ product.price }}</span>
                    </b-form-radio>
                  </template>
                </b-form-group>
                <p v-if="(forms.update_topup_setting.submitted && $v.forms.update_topup_setting.voip_amount.$invalid) || api.update_topup_setting.validation_errors.voip_amount" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.update_topup_setting.voip_amount.required">* Amount to topup field is required</span>
                  <span v-for="(em, i) in api.update_topup_setting.validation_errors.voip_amount" :key="i">* {{ em }}</span>
                </p>
              </template>




              <!-- <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                  <template v-if="forms.update_topup_setting.voip_enable">
                    <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100">
                      <label>My balance falls below {{ forms.update_topup_setting.voip_balance_limit ? forms.update_topup_setting.voip_balance_limit : ''}}</label>
                      <b-dropdown :disabled="api.update_topup_setting.send || api.fetch_topup_setting.send">
                        <template #button-content>
                          <span class="d-flex align-items-center justify-content-between w-100 p-0">
                            <div class="mr-8px">{{forms.update_topup_setting.voip_balance_limit}}</div>
                            <b-icon icon="chevron-down" />
                          </span>
                        </template>
                        <b-dropdown-item v-for="n in topupThreshold" :key="n" @click="forms.update_topup_setting.voip_balance_limit = n">{{ n }}</b-dropdown-item>
                      </b-dropdown>
                      <p v-if="(forms.update_topup_setting.submitted && $v.forms.update_topup_setting.voip_balance_limit.$invalid) || api.update_topup_setting.validation_errors.voip_balance_limit">
                        <span v-if="!$v.forms.update_topup_setting.voip_balance_limit.required">* Credit limit field is required</span>
                        <span v-else-if="!$v.forms.update_topup_setting.voip_balance_limit.maxValue">* Credit limit maximum {{ $v.forms.update_topup_setting.voip_balance_limit.$params.maxValue.max }} only</span>
                        <span v-for="(em, i) in api.update_topup_setting.validation_errors.voip_balance_limit" :key="i">* {{ em }}</span>
                      </p>
                    </div>
                    <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100">
                      <label>Automatically recharge for</label>
                      <b-dropdown :disabled="api.update_topup_setting.send || api.fetch_topup_setting.send || api.fetch_products.send">
                        <template #button-content>
                          <span class="d-flex align-items-center justify-content-between w-100 p-0">
                            <div class="mr-8px">{{forms.update_topup_setting.voip_amount}}</div>
                            <b-icon icon="chevron-down" />
                          </span>
                        </template>
                        <b-dropdown-item 
                          v-for="product in response.products" 
                          :key="product.id" 
                          @click="forms.update_topup_setting.voip_amount = product.price;forms.update_topup_setting.topup_product_id = product.real_id"
                        >
                          {{ product.price }}
                        </b-dropdown-item>
                      </b-dropdown>
                      <p v-if="(forms.update_topup_setting.submitted && $v.forms.update_topup_setting.voip_amount.$invalid) || api.update_topup_setting.validation_errors.voip_amount">
                        <span v-if="!$v.forms.update_topup_setting.voip_amount.required">* Amount to topup field is required</span>
                        <span v-for="(em, i) in api.update_topup_setting.validation_errors.voip_amount" :key="i">* {{ em }}</span>
                      </p>
                    </div>
                  </template>
                </div>
              </div> -->
            </div>
            <div class="d-flex justify-content-end w-100">
              <b-button class="newDoneButton mt-4" type="submit" variant="primary" :disabled="api.update_topup_setting.send || api.fetch_topup_setting.send">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api.update_topup_setting.send" />
                  <template v-else>Save</template>
                </span>
              </b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { requiredIf, maxValue } from "vuelidate/lib/validators";
import { VOIP_API } from '../../utils';
const topup_threshold = [
  0,
  10,
  20,
  50,
  120
]
export default {
  name: 'TopupSettingModal',
  inject: [
    'isEmpty','appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'TopupSettingModal',
    },
  },
  data(){
    return {
      forms: {
        update_topup_setting: this.$helperFunction.formInstance({
          data: {
            voip_enable: false,
            voip_balance_limit: 0,
            voip_amount: '',
            topup_product_id: '',
          }
        }),
      },
      api: {
        update_topup_setting: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
        fetch_topup_setting: this.$helperFunction.apiInstance(),
        fetch_products: this.$helperFunction.apiInstance(),
      },
      response: this.$helperFunction.stateInstance({
        data: {
          products: [],
          topup_settings: {},
        }
      }),
      selectedSetting: {
        limit: '',
        autoRecharge: ''
      }
    }
  },
  computed: {
    topupThreshold(){ return topup_threshold },
  },
  validations: {
    forms: {
      update_topup_setting: {
        voip_balance_limit: {
          required: requiredIf(function(){
            return this.forms.update_topup_setting.voip_enable
          }),
          maxValue: maxValue(500),
        },
        voip_amount: {
          required: requiredIf(function(){
            return this.forms.update_topup_setting.voip_enable
          })
        },
        topup_product_id: {
          required: requiredIf(function(){
            return this.forms.update_topup_setting.voip_enable
          })
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchTopupSetting()
      this.fetchTopupProducts()
    },
    onBeforeClose(){
      this.forms.update_topup_setting.reset()
      this.api.update_topup_setting.reset()
      this.api.fetch_topup_setting.reset()
      this.api.fetch_products.reset()
      this.response.reset()
    },
    async updateTopupSetting(){
      this.forms.update_topup_setting.submitted=true
      this.$v.forms.update_topup_setting.$touch()
      if(this.$v.forms.update_topup_setting.$invalid || this.api.update_topup_setting.send) return;
      try {
        this.api.update_topup_setting.send=true
        this.api.update_topup_setting.validation_errors={}
        await VOIP_API.endpoints.topup.updatesetting({
          voip_balance_limit: this.forms.update_topup_setting.voip_balance_limit,
          voip_amount: this.forms.update_topup_setting.voip_amount,
          voip_enable: this.forms.update_topup_setting.voip_enable,
          topup_product_id: this.forms.update_topup_setting.topup_product_id,
          uid: this.$store.getters.getCurrentUser?.uid,
        })
        this.appNotify({
          message: 'Successfully updated!',
          type: 'success',
        })
      } catch (ex) {
        this.api.update_topup_setting.error=ex.own_errors || {}
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.forms.update_topup_setting.submitted=false
        this.api.update_topup_setting.send=false
      }
    },
    async fetchTopupSetting(){
      if(this.api.fetch_topup_setting.send) return;
      try {
        this.api.fetch_topup_setting.send=true
        const { data } = await VOIP_API.endpoints.topup.getsetting({
          uid: this.$store.getters.getCurrentUser?.uid,
        })
        this.response.topup_settings=data ?? {}
        if(data){
          this.forms.update_topup_setting.voip_balance_limit = parseInt(data.voip_balance_limit || '') || 0
          this.forms.update_topup_setting.topup_product_id = data.topup_product_id || ''
          this.forms.update_topup_setting.voip_amount = parseInt(data.voip_amount || '') || 0
          this.forms.update_topup_setting.voip_enable = data.voip_enable=='yes'
        }
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_topup_setting.send=false
      }
    },
    async fetchTopupProducts(){
      if(this.api.fetch_products.send) return;
      try {
        this.api.fetch_products.send=true
        const { data: { data } } = await VOIP_API.endpoints.topup.getproducts()
        this.response.products=data??[]
      } finally {
        this.api.fetch_products.send=false
      }
    },
  },
  mounted(){
    this.fetchTopupProducts()
  },
}
</script>

<style>

</style>