<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section">
      <div class="dialer-flex">
        <h2 class="dialer-settings-title">All Extensions</h2>
      </div>
      <p class="dialer-settings-intro with-button">Manage all your extensions.</p>
      <vb-table :isListEmpty="response.extensions.length==0" :listLength="extensions.length" :loading="api.fetch_extensions.send">
        <template slot="header">
          <tr>
            <th class="dialer-has-sort">
              <span class="dialer-sorted-by ascending">Label</span>
            </th>
            <th class="dialer-has-sort">
              <span>Type</span>
            </th>
            <th class="dialer-col-right">
              <span>Action</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr class v-for="(extension, index) in response.extensions" :key="index" v-show="index >= start && index <= end">
            <td class="dialer-row-title">
              <div class="nameWithExtension">
                <span class="extension_tag">{{ extension.extn }}</span>
                {{ extension | filterLabel }}
              </div>
            </td>
            <td class="dialer-col-left">{{ extension.type }}</td>
            <td class="dialer-col-right">
              <a class="d-flex dialer-button dialer-button-primary ml-auto" @click="$modal.show('EditExtensionModal', { data: extension })">Change</a>
            </td>
          </tr>
        </template>
      </vb-table>
      <EditExtensionModal @interface="fetchAllExtensions()" />
    </section>
  </div>
</template>

<script>
import { VOIP_API } from "../../utils";
import EditExtensionModal from "../Modals/EditExtensionModal.vue";
export default {
  name: "Invitation",
  components: {
    EditExtensionModal,
  },
  data() {
    return {
      response: {
        extensions: [],
      },
      api: {
        fetch_extensions: this.$helperFunction.apiInstance(),
      },
    };
  },
  filters: {
    filterLabel(value) {
      if (!value) return "-";
      else if (value.voipuser) return value.voipuser.display_name
      else return value.label;
    },
  },
  methods: {
    async fetchAllExtensions() {
      if (this.api.fetch_extensions.send) return;
      try {
        this.api.fetch_extensions.send = true;
        const { data: { data: extensions } } = await VOIP_API.endpoints.extensions.getallextensions()
        this.response.extensions = extensions;
      } finally {
        this.api.fetch_extensions.send = false;
      }
    },
  },
  created() {
    this.fetchAllExtensions();
  },
};
</script>
