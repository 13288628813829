var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "sec-common"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex"
  }, [_c('button', {
    staticClass: "btn btn-primarymike mr-3"
  }, [_c('svg', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.551",
      "height": "16.551",
      "viewBox": "0 0 16.551 16.551"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_ionic-ios-add",
      "data-name": "Icon ionic-ios-add",
      "d": "M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z",
      "transform": "translate(-8.465 -8.465)",
      "fill": "#fff",
      "stroke": "#fff",
      "stroke-width": "1"
    }
  })]), _vm._v(" Add workflow ")]), _c('button', {
    staticClass: "btn-activity",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('HRMAddNewTaskOnboardModal');
      }
    }
  }, [_c('svg', {
    staticClass: "grm-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.551",
      "height": "16.551",
      "viewBox": "0 0 16.551 16.551"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_ionic-ios-add",
      "data-name": "Icon ionic-ios-add",
      "d": "M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z",
      "transform": "translate(-8.465 -8.465)",
      "fill": "#5576d1",
      "stroke": "#5576d1",
      "stroke-width": "1"
    }
  })]), _vm._v(" Add Task ")])])]), _c('p', {
    staticClass: "notes-top-text space4text-when-scroll-activate"
  }, [_vm._v(" Manage onboarding tasks for Ahsen van der ")]), _c('div', {
    staticClass: "mt-5"
  }, [_c('button', {
    class: `tabs-hrm mr-3 ${_vm.selectedTabForTable == 1 ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectedTabForTable = 1;
      }
    }
  }, [_vm._v("Outstanding")]), _c('button', {
    class: `tabs-hrm ${_vm.selectedTabForTable == 2 ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectedTabForTable = 2;
      }
    }
  }, [_vm._v("Completed")])]), _vm.selectedTabForTable == 1 ? _c('div', {
    staticClass: "mar-top-hrm60"
  }, [_vm._m(1), _c('div', {
    staticClass: "tab-task-bg-border"
  }, [_c('table', {
    staticClass: "task-table-hrm"
  }, [_c('thead'), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "ob-emp-firsttd"
  }, [_vm._v(" Design Model ")]), _c('td', {
    staticClass: "ob-emp-secondtd"
  }, [_vm._v(" Jackson ")]), _c('td', {
    staticClass: "ob-emp-thirdtd"
  }, [_vm._v(" Due in 7 days ")]), _c('td', {
    staticClass: "ob-emp-fourtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }], null, false, 374507881)
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "ob-emp-firsttd"
  }, [_vm._v(" Design Model ")]), _c('td', {
    staticClass: "ob-emp-secondtd"
  }, [_vm._v(" Jackson ")]), _c('td', {
    staticClass: "ob-emp-thirdtd"
  }, [_vm._v(" Due in 7 days ")]), _c('td', {
    staticClass: "ob-emp-fourtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "lg",
      "variant": "link",
      "toggle-class": "text-decoration-none tb-notes-dd",
      "no-caret": "",
      "menu-class": "notes-table-dd"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }], null, false, 374507881)
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])])])]) : _vm._e(), _vm.selectedTabForTable == 2 ? _c('div', {
    staticClass: "mar-top-hrm60"
  }) : _vm._e(), _vm.selectedTabForTable == 1 ? _c('vb-table', {
    staticClass: "onboard-table-width",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth ob-emp-firsttd col-left"
  }, [_vm._v("TITLE")]), _c('h5', {
    staticClass: "commonth ob-emp-secondtd"
  }, [_vm._v("Assigned to")]), _c('h5', {
    staticClass: "commonth ob-emp-thirdtd"
  }, [_vm._v("Deadline")]), _c('h5', {
    staticClass: "commonth ob-emp-fourtd col-right"
  }, [_vm._v("Action")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "ob-emp-firsttd"
  }, [_vm._v(" Design Model ")]), _c('td', {
    staticClass: "ob-emp-secondtd"
  }, [_vm._v(" Jackson ")]), _c('td', {
    staticClass: "ob-emp-thirdtd"
  }, [_vm._v(" Due in 7 days ")]), _c('td', {
    staticClass: "ob-emp-fourtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }], null, false, 374507881)
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])], 2) : _vm._e()], 1)]), _c('HRMAddNewTaskOnboardModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Onboarding")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tb-task-upper-part"
  }, [_c('h5', {
    staticClass: "task-th ob-emp-firsttd col-left"
  }, [_vm._v("TITLE")]), _c('h5', {
    staticClass: "task-th ob-emp-secondtd"
  }, [_vm._v("Assigned to")]), _c('h5', {
    staticClass: "task-th ob-emp-thirdtd"
  }, [_vm._v("Deadline")]), _c('h5', {
    staticClass: "task-th ob-emp-fourtd col-right"
  }, [_vm._v("Action")])]);

}]

export { render, staticRenderFns }