<template>
  <div class="hrm-parent">
    <HRMHome v-if="activeTab === 'hrm-home'" ref="hrm-home" />
    <HrmHomePage v-if="activeTab === 'HrmHomePage'" ref="HrmHomePage" />
    <HRMCalender v-if="activeTab === 'hrm-calender'" ref="hrm-calender" />
    <HRMSetting v-if="activeTab === 'hrm-setting'" ref="hrm-setting" />
    <HRMReports v-if="activeTab === 'hrm-reports'" ref="hrm-reports" />
    <SettingsNew v-if="activeTab === 'hrm-settingNew'" ref="hrm-settingNew" />
    <HrmDocuments v-if="activeTab === 'HrmDocuments'" ref="HrmDocuments" />
  </div>
</template>

<script>

// hrm
import HRMHome from '@/views/hrm/Home.vue'
import HRMCalender from '@/views/hrm/Calender.vue'
import HRMSetting from '@/views/hrm/Setting.vue'
import HRMReports from '@/views/hrm/fahad/reports.vue'
import SettingsNew from '@/views/hrm/fahad/SettingsNew.vue'
import HrmHomePage from '@/views/hrm/HrmHomePage.vue'
import HrmDocuments from '@/views/hrm/HrmDocuments.vue'
export default {
  name: 'HRM',
  components: {
    HRMHome,
    HRMCalender,
    HRMSetting,
    HRMReports,
    SettingsNew,
    HrmHomePage,
    HrmDocuments,
  },
  computed: {
    activeTab(){ return this.$store.state.global_conditions.tab },
  },
}
</script>

<style>
</style>