<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.select_voices">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.text">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Voice</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                v-for="voice in voicesOptions" 
                :key="voice.value" 
                class="basicWhiteIOScard-item" 
                @click="forms.text_to_audio.voice=voice.value;conditions.screen=screens.text;"
              >
                <div class="headingInsideCard">{{ voice.text }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.text_to_audio.voice==voice.value" width="16.642" height="15.275" />
              </div>
              <vb-no-record v-if="voicesOptions.length==0" :text="api.voices.send?'':'There is no Option'" :design="3">
                <vb-loading v-if="api.voices.send" slot="loading" />
              </vb-no-record>
            </div>

          </div>
        </div>
      </template>
      <template v-if="conditions.screen==screens.text">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Text</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          
          <form class="dialer-form lg-mar-bottom" @submit.prevent="convert()">
            <b-alert class="mt-32px" :show="!!api.text_to_audio.error_message" variant="danger">{{api.text_to_audio.error_message}}</b-alert>
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Text</label>
                  <textarea 
                    placeholder="Enter Text" 
                    class="w-100" 
                    type="text" 
                    :disabled="api.text_to_audio.send" 
                    v-model="forms.text_to_audio.text"
                    @input="updateText"
                  />
                  <p 
                    v-if="(forms.text_to_audio.submitted && $v.forms.text_to_audio.text.$invalid) || api.text_to_audio.validation_errors.text" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.text_to_audio.text.required">* Text is required</span>
                    <span v-for="em in api.text_to_audio.validation_errors.text" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.select_voices">
                <div class="headingInsideTable">Voice</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forms.text_to_audio.voice }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
                <p 
                  v-if="(forms.text_to_audio.submitted && $v.forms.text_to_audio.voice.$invalid) || api.text_to_audio.validation_errors.voice" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.text_to_audio.voice.required">* Text is required</span>
                  <span v-for="em in api.text_to_audio.validation_errors.voice" :key="em">* {{ em }}</span>
                </p>
              </div>
            </div>
            <button class="IosFullWidthButton forDone mb-16px" :disabled="api.text_to_audio.send">
              <vb-spinner v-if="api.text_to_audio.send" />
              <template v-else>Add</template>
            </button>
          </form>

        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Welcome Greeting</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Audio</div>
                <vb-audio-player v-if="audio.src" class="mr-16px IosAudioPlayer" :src="audio.src" />
                <vb-audio-player v-else class="mr-16px IosAudioPlayer" :src="ivr | welcomgreetingsrc" />
              </div>
            </div>
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item" @click="$modal.show('UploadAudioModal')">
                <div class="headingInsideCard">Upload</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <div class="basicWhiteIOScard-item" @click="$modal.show('AudioRecording')">
                <div class="headingInsideCard">Recording</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <div class="basicWhiteIOScard-item" @click="$modal.show('SelectSoundFile')">
                <div class="headingInsideCard">Library</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.text">
                <div class="headingInsideCard">Text</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>

          </div>
        </div>
      </template>
      <SelectSoundFileModal @select-file="upload({ type: uploadType.library, sound: $event.file })" />
      <AudioRecordingModal @audio="$event.hide();upload({ type: uploadType.recording, blob: $event.blob, url: $event.url });" />
      <UploadAudioModal @audio="$event.hide();upload({ type: uploadType.upload, file: $event.file, url: $event.url });" />
    </modal>
  </div>
</template>

<script>
import SelectSoundFileModal from "../sounds/SelectSoundFile.vue";
import AudioRecordingModal from "../common/AudioRecording.vue";
import UploadAudioModal from "../common/UploadAudio.vue";

import { required } from "vuelidate/lib/validators";
import _ from "lodash";

import { VOIP_API } from "@/utils";
import { welcomgreetingsrc } from '@/filter';
import { mapGetters } from 'vuex';

let screens = {
  main : 'main',
  text: 'text',
  select_voices: 'select_voices',
}
let upload_type = {
  library: 'library',
  upload: 'upload',
  recording: 'recording',
}

export default {
  name: "WelcomeGreetingModal",
  components: {
    SelectSoundFileModal,
    AudioRecordingModal,
    UploadAudioModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'WelcomeGreeting',
    },
  },
  data() {
    return {
      forms: {
        text_to_audio: this.$helperFunction.formInstance({
          data: {
            text: "",
            voice: "",
          }
        }),
      },
      api: {
        update_ivr_text: this.$helperFunction.apiInstance({ }),
        upload: this.$helperFunction.apiInstance({ }),
        text_to_audio: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        voices: this.$helperFunction.apiInstance({ }),
      },
      response: {
        voices: [],
      },
      data: {
        accountcode: '',
      },
      conditions: {
        screen: screens.main
      },
      audio: {
        src: '',
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getVoipIVRS',
      'getCurrentUser',
    ]),
    voicesOptions() {
      return this.response.voices.filter(item=>item.SupportedEngines.includes('standard')).map((item) => {
        return {
          text: `( ${item.LanguageName} ) ( ${item.Gender} ) (${item.Name} )`,
          value: item.Id,
        };
      });
    },
    screens(){ return screens },
    uploadType(){ return upload_type },
    accountcode(){ return this.data.accountcode },
    ivr(){ return this.getVoipIVRS?.[this.accountcode] ?? {} },
  },
  validations: {
    forms: {
      text_to_audio: {
        text: {
          required,
        },
        voice: {
          required,
        },
      },
    },
  },
  filters: {
    welcomgreetingsrc,
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode=event?.params?.accountcode ?? ''
      this.fetchVoices()
    },
    onBeforeClose(){
      this.data.accountcode=''
      this.audio.src=''
      this.conditions.screen=screens.main
      this.response.voices=[]
      this.api.update_ivr_text.reset()
      this.api.upload.reset()
      this.api.text_to_audio.reset()
      this.api.voices.reset()
      this.forms.text_to_audio.reset()
    },
    upload(event){
      let vm = this
      // console.log(event)
      if(vm.api.upload.send || !upload_type[event.type]) return;
      vm.api.upload.send=true
      let audio_src = ''
      const form_data = new FormData();
      form_data.append("accountcode", vm.getCurrentUser.default_accountcode);
      form_data.append("uid", vm.getCurrentUser.uid);
      form_data.append("useraccount", vm.getCurrentUser.account);
      form_data.append("ivraccount", vm.accountcode);
      if(event.type==upload_type.library) {
        form_data.append("id", event.sound.id);
        form_data.append("flag", "library");
        audio_src = event?.sound?.sound_file ?? ''
      } else if(event.type==upload_type.upload) {
        form_data.append("file", event.file);
        form_data.append("flag", "upload");
        audio_src = event.url
      } else if(event.type==upload_type.recording) {
        form_data.append("file", event.blob);
        form_data.append("flag", "recording");
        audio_src = event.url
      }
      VOIP_API.endpoints.menu.uploadivrimage(form_data)
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated',
          type: 'success'
        })
        vm.audio.src=audio_src
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.upload.send=false
      })
    },
    fetchVoices(){
      let vm = this
      if(vm.api.voices.send) return;
      vm.api.voices.send = true;
      VOIP_API.endpoints.voicemail.getpollyVoices()
      .then(({ data: { data } }) => {
        vm.response.voices = data;
        vm.forms.text_to_audio.voice = "Salli";
      })
      .finally(() => {
        vm.api.voices.send = false;
      });
    },
    convert(){
      let vm = this
      vm.forms.text_to_audio.submitted = true;
      vm.$v.forms.text_to_audio.$touch()
      if (vm.api.text_to_audio.send || vm.$v.forms.text_to_audio.$invalid) return;
      vm.api.text_to_audio.send = true;
      vm.api.text_to_audio.error_message = '';
      vm.api.text_to_audio.validation_errors = {};
      VOIP_API.endpoints.menu.convert({
        text: vm.forms.text_to_audio.text,
        voice: vm.forms.text_to_audio.voice,
        label: "BasicIvr",
        accountcode: vm.accountcode,
      })
      .then(({ data }) => {
        if (!data.file_exists) {
          vm.appNotify({
            message: "Try after 5 second",
            type: "info",
          });
        } else {
          vm.appNotify({
            message: "Successfully converted",
            type: "success",
          })
          vm.audio.src=data.sound
          // data.text
          vm.$emit("file-change",{ sound: data.sound });
          vm.conditions.screen=screens.main
        }
      })
      .catch((ex) => {
        vm.api.text_to_audio.error_message = ex.own_message;
        vm.api.text_to_audio.validation_errors = ex.own_errors;
      })
      .finally(() => {
        vm.api.text_to_audio.send = false;
        vm.forms.text_to_audio.submitted = false;
      });
    },
    updateText: _.debounce(function(){
      let vm = this;
      vm.api.update_ivr_text.send = true;
      VOIP_API.endpoints.menu.updateivrtext({
        text: vm.forms.text_to_audio.text,
        uid: vm.getCurrentUser?.uid,
        id: vm.accountcode,
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully updated",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.update_ivr_text.send = false;
      });
    },2*1000)
  },
};
</script>

<style>

</style>