<template>
  <div>
    <span v-hotkey="keymap"></span>
    <ShortcutsModal></ShortcutsModal>
  </div>
</template>

<script>
import { events } from "../utils";
import ShortcutsModal from "../components/Modals/chat/KeyboardShortcutsModal";
export default {
  name: "KeyboardShortcuts",
  components: {
    ShortcutsModal,
  },
  computed: {
    keymap() {
      return {
        "ctrl+/": this.ctrlBackslash,
        "ctrl+w": this.ctrlW,
        "ctrl+,": this.ctrlComa,
        "ctrl+h": this.ctrlH,
        "ctrl+o": this.ctrlO,
        "ctrl+t": this.ctrlT,
        "ctrl+i": this.ctrlI,
        "ctrl+tab": this.ctrlTab,
        "ctrl+-": this.ctrlMinus,
        "ctrl+0": this.ctrl0,
        "ctrl+q": this.ctrlQ,
        "ctrl+n": this.ctrlN,
        "ctrl+g": this.ctrlG,
        "ctrl+p": this.ctrlP,
        "ctrl+f": this.ctrlF,
        "ctrl+m": this.ctrlM,
        "ctrl+d": this.ctrlD,
        "ctrl+s": this.ctrlS,
        "ctrl+shift+t": this.ctrlShiftT,
        "ctrl+shift+c": this.ctrlShiftC,
        "ctrl+shift+m": this.ctrlShiftM,
        "ctrl+shift+s": this.ctrlShiftS,
        "ctrl+shift++": this.ctrlShiftPlus,
        "ctrl+shift+f": this.ctrlShiftF,
        "ctrl+shift+g": this.ctrlShiftG,
        "ctrl+shift+u": this.ctrlShiftU,
        "ctrl+shift+l": this.ctrlShiftL,
        "ctrl+shift+e": this.ctrlShiftE,
        "ctrl+shift+j": this.ctrlShiftJ,
        // "ctrl+shift+a": this.ctrlShiftA,
        "ctrl+shift+h": this.ctrlShiftH,
        "ctrl+shift+k": this.ctrlShiftK,
        "ctrl+shift+p": this.ctrlShiftP,
        "ctrl+alt+m": this.ctrlAltM,
        "ctrl+alt+h": this.ctrlAltH,
        "ctrl+alt+shift+k": this.ctrlAltShiftK,
        "ctrl+shift+1": this.ctrlShift1,
        "ctrl+shift+2": this.ctrlShift2,
        "ctrl+shift+3": this.ctrlShift3,
        "ctrl+shift+4": this.ctrlShift4,
        "alt+1": this.alt1,
        "alt+2": this.alt2,
        "alt+3": this.alt3,
        "alt+4": this.alt4,
        "alt+5": this.alt5,
        "alt+6": this.alt6,
        "alt+7": this.alt7,
        "alt+shift+e": this.altShiftE,
      };
    },
  },
  methods: {
    showShortcuts() {
      this.$modal.show("KeyboardShortcutsModal");
    },
    ctrlBackslash(Event) {
      this.emitingEvent(Event, events.ctrl_backslash);
    },
    ctrlW(Event) {
      this.emitingEvent(Event, events.ctrl_w);
    },
    ctrlComa(Event) {
      this.emitingEvent(Event, events.ctrl_coma);
    },
    ctrlH(Event) {
      this.emitingEvent(Event, events.ctrl_h);
    },
    ctrlO(Event) {
      this.emitingEvent(Event, events.ctrl_o);
    },
    ctrlT(Event) {
      this.emitingEvent(Event, events.ctrl_t);
    },
    ctrlI(Event) {
      this.emitingEvent(Event, events.ctrl_i);
    },
    ctrlTab(Event) {
      this.emitingEvent(Event, events.ctrl_tab);
    },
    ctrlMinus(Event) {
      this.emitingEvent(Event, events.ctrl_minus);
    },
    ctrl0(Event) {
      this.emitingEvent(Event, events.ctrl_0);
    },
    ctrlQ(Event) {
      this.emitingEvent(Event, events.ctrl_q);
    },
    ctrlN(Event) {
      this.emitingEvent(Event, events.ctrl_n);
    },
    ctrlG(Event) {
      this.emitingEvent(Event, events.ctrl_g);
    },
    ctrlP(Event) {
      this.emitingEvent(Event, events.ctrl_p);
    },
    ctrlF(Event) {
      this.emitingEvent(Event, events.ctrl_f);
    },
    ctrlM(Event) {
      this.emitingEvent(Event, events.ctrl_m);
    },
    ctrlD(Event) {
      this.emitingEvent(Event, events.ctrl_d);
    },
    ctrlS(Event) {
      this.emitingEvent(Event, events.ctrl_s);
    },
    // ctrlShiftA(Event) {
    //   this.emitingEvent(Event, events.ctrl_shift_a);
    // },
    ctrlShiftP(Event) {
      this.emitingEvent(Event, events.ctrl_shift_p);
    },
    ctrlShiftJ(Event) {
      this.emitingEvent(Event, events.ctrl_shift_j);
    },
    ctrlShiftH(Event) {
      this.emitingEvent(Event, events.ctrl_shift_h);
    },
    ctrlShiftK(Event) {
      this.emitingEvent(Event, events.ctrl_shift_k);
    },
    ctrlShiftF(Event) {
      this.emitingEvent(Event, events.ctrl_shift_f);
    },
    ctrlShiftG(Event) {
      this.emitingEvent(Event, events.ctrl_shift_g);
    },
    ctrlShiftU(Event) {
      this.emitingEvent(Event, events.ctrl_shift_u);
    },
    ctrlShiftL(Event) {
      this.emitingEvent(Event, events.ctrl_shift_l);
    },
    ctrlShiftE(Event) {
      this.emitingEvent(Event, events.ctrl_shift_e);
    },
    ctrlShiftPlus(Event) {
      this.emitingEvent(Event, events.ctrl_shift_plus);
    },
    ctrlShiftS(Event) {
      this.emitingEvent(Event, events.ctrl_shift_s);
    },
    ctrlShiftT(Event) {
      this.emitingEvent(Event, events.ctrl_shift_t);
    },
    ctrlShiftC(Event) {
      this.emitingEvent(Event, events.ctrl_shift_c);
    },
    ctrlShiftM(Event) {
      this.emitingEvent(Event, events.ctrl_shift_m);
    },
    ctrlAltM(Event) {
      this.emitingEvent(Event, events.ctrl_alt_m);
    },
    ctrlAltH(Event) {
      this.emitingEvent(Event, events.ctrl_alt_h);
    },
    ctrlAltShiftK(Event) {
      this.emitingEvent(Event, events.ctrl_alt_shift_k);
    },
    ctrlShift1(Event) {
      this.emitingEvent(Event, events.ctrl_shift_1);
    },
    ctrlShift2(Event) {
      this.emitingEvent(Event, events.ctrl_shift_2);
    },
    ctrlShift3(Event) {
      this.emitingEvent(Event, events.ctrl_shift_3);
    },
    ctrlShift4(Event) {
      this.emitingEvent(Event, events.ctrl_shift_4);
    },
    alt1(Event) {
      this.emitingEvent(Event, events.alt_1);
    },
    alt2(Event) {
      this.emitingEvent(Event, events.alt_2);
    },
    alt3(Event) {
      this.emitingEvent(Event, events.alt_3);
    },
    alt4(Event) {
      this.emitingEvent(Event, events.alt_4);
    },
    alt5(Event) {
      this.emitingEvent(Event, events.alt_5);
    },
    alt6(Event) {
      this.emitingEvent(Event, events.alt_6);
    },
    alt7(Event) {
      this.emitingEvent(Event, events.alt_7);
    },

    altShiftE(Event) {
      this.emitingEvent(Event, events.alt_shift_e);
    },
    emitingEvent(Event, event_name) {
      if (!Event.target && Event.target.tagName !== "BODY") return;
      this.$root.$emit(event_name, Event);
    },
  },
  created(){
    this.$root.$on(events.ctrl_backslash,this.showShortcuts)
  },
  beforeDestroy(){
    this.$root.$off(events.ctrl_backslash,this.showShortcuts)
  },
};
</script>

<style></style>
