var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [true ? _c('modal', {
    staticClass: "dialer_animation right_side_popup AddNewBillingCard AddNewTicket",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createsupportticket();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header mt-3 mb-5"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Add New Ticket")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "disabled": _vm.api.ticket.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.ticket.error_message,
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.api.ticket.error_message) + " ")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Subject "), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.ticket.subject,
      expression: "forms.ticket.subject"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "add-support-ticket-subject",
      "type": "text",
      "maxlength": "256",
      "onkeypress": "return /([0-9A-Za-z \\s])/i.test(event.key)",
      "disabled": _vm.api.ticket.send
    },
    domProps: {
      "value": _vm.forms.ticket.subject
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.ticket, "subject", $event.target.value);
      }
    }
  }), _vm.forms.ticket.is_submitted ? [_vm.$v.forms.ticket.subject.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.ticket.subject.required ? _c('span', [_vm._v("* subject is required")]) : !_vm.$v.forms.ticket.subject.minLength ? _c('span', [_vm._v("* subject should be minimum 3 character")]) : !_vm.$v.forms.ticket.subject.maxLength ? _c('span', [_vm._v("* subject can be maximum 256 character")]) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.api.ticket.error.errors ? [_vm.api.ticket.error.errors.subject ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.ticket.error.errors.subject, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "d-flex mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2"
  }, [_c('label', [_vm._v("Department "), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])]), _c('vb-select', {
    attrs: {
      "id": "add-support-ticket-department",
      "defaultSelectedText": 'Select Department',
      "options": _vm.departmentsOptions,
      "disabled": _vm.api.departments.send || _vm.api.ticket.send
    },
    model: {
      value: _vm.forms.ticket.department,
      callback: function ($$v) {
        _vm.$set(_vm.forms.ticket, "department", $$v);
      },
      expression: "forms.ticket.department"
    }
  }), _vm.forms.ticket.is_submitted ? [_vm.$v.forms.ticket.department.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.ticket.department.required ? _c('span', [_vm._v("* Department is required")]) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.api.ticket.error.errors ? [_vm.api.ticket.error.errors.department ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.ticket.error.errors.department, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2"
  }, [_c('label', {
    staticClass: "mb-1"
  }, [_vm._v("Priority "), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])]), _c('vb-select', {
    attrs: {
      "id": "add-support-ticket-priority",
      "defaultSelectedText": 'Select Priority',
      "options": _vm.priorityOptions,
      "disabled": _vm.api.ticket.send
    },
    model: {
      value: _vm.forms.ticket.priority,
      callback: function ($$v) {
        _vm.$set(_vm.forms.ticket, "priority", $$v);
      },
      expression: "forms.ticket.priority"
    }
  }), _vm.forms.ticket.is_submitted ? [_vm.$v.forms.ticket.priority.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.ticket.priority.required ? _c('span', [_vm._v("* Priority is required")]) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.api.ticket.error.errors ? [_vm.api.ticket.error.errors.priority ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.ticket.error.errors.priority, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-20px"
  }, [_c('label', [_vm._v("Message "), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])]), _c('form', {
    staticClass: "w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.ticket.message,
      expression: "forms.ticket.message"
    }],
    staticClass: "ticket-textArea-text w-suto p-3",
    staticStyle: {
      "height": "auto",
      "width": "-webkit-fill-available"
    },
    attrs: {
      "placeholder": "Write your message",
      "rows": "11",
      "id": "add-support-ticket-message",
      "disabled": _vm.api.ticket.send
    },
    domProps: {
      "value": _vm.forms.ticket.message
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.ticket, "message", $event.target.value);
      }
    }
  }, [_vm._v(" Hi, hope you are doing great today. Please check the assigned tasks. Thanks ")])]), _vm.forms.ticket.is_submitted ? [_vm.$v.forms.ticket.message.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.ticket.message.required ? _c('span', [_vm._v("* message is required")]) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.api.ticket.error.errors ? [_vm.api.ticket.error.errors.message ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.ticket.error.errors.message, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Request a callback")]), _c('b-check', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.ticket.send
    },
    model: {
      value: _vm.forms.ticket.call_me,
      callback: function ($$v) {
        _vm.$set(_vm.forms.ticket, "call_me", $$v);
      },
      expression: "forms.ticket.call_me"
    }
  })], 1), _vm.api.ticket.error.errors ? [_vm.api.ticket.error.errors.call_me ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.ticket.error.errors.call_me, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()] : _vm._e(), _vm.forms.ticket.call_me ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion"
  }, [_c('label', [_vm._v("Phone number"), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v(" *")])]), _c('div', {
    staticClass: "problemDDInputContainer"
  }, [_c('vb-select', {
    staticClass: "problemDD",
    attrs: {
      "disabled": _vm.api.ticket.send,
      "options": _vm.numbersOption
    },
    model: {
      value: _vm.forms.ticket.country_code,
      callback: function ($$v) {
        _vm.$set(_vm.forms.ticket, "country_code", $$v);
      },
      expression: "forms.ticket.country_code"
    }
  }), _c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.ticket.send,
      "type": "text",
      "onkeypress": "return /([0-9])/i.test(event.key)",
      "placeholder": "Add your phone number"
    },
    model: {
      value: _vm.forms.ticket.number,
      callback: function ($$v) {
        _vm.$set(_vm.forms.ticket, "number", $$v);
      },
      expression: "forms.ticket.number"
    }
  }), _vm.forms.ticket.is_submitted ? [_vm.$v.forms.ticket.number.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.ticket.number.required ? _c('span', [_vm._v("* Phone Number is required")]) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.api.ticket.error.errors ? [_vm.api.ticket.error.errors.number ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.ticket.error.errors.number, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()] : _vm._e()], 2)], 1)])]) : _vm._e()], 2)])])], 1), _c('div', {
    staticClass: "d-flex justify-content-end mt-4"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "id": "add-support-ticket-submit",
      "disabled": _vm.api.ticket.send
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.ticket.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1), _c('button', {
    staticClass: "dialer-button dialer-button-transparent ml-3",
    attrs: {
      "id": "add-support-ticket-cancel",
      "type": "button",
      "disabled": _vm.api.ticket.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_vm._v(" Cancel ")])])])])]) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }