<template> 
  <modal
    class="right_side_popup m-w-600 addRemove_userModal bigSquareCheckbox min-width-50percent-modal RedirectToModal"
    width="50%"
    height="auto"
    :scrollable="true"
    :name="modalName"
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose($event)"
  >
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div v-if="getIsMobile" class="dialer-edit-header flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center mb-2">
          <h2 class="dialer-edit-title">Redirect to</h2>
          <b-icon class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
        </div>
        <div class="dialer-edit-actions d-flex justify-content-end w-100">
          <button :class="selected.extensions.length==0?'newDeleteButton':'newDoneButton'" :disabled="api.update_extension.send" @click="updateExtension()">
            <vb-icon :icon="selected.extensions.length==0?'squared-delete-icon':'squared-done-icon'" height="38px" width="38px" />
            <span class="newDoneButton-textPart">{{selected.extensions.length==0?'Unassign':'Done'}}</span>
          </button>
        </div>
      </div>
      <div v-else class="dialer-edit-header">
        <div>
          <h2 class="dialer-settings-title newer d-flex align-items-center">
            <!-- <vb-svg @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" /> -->
            <button class="newBackButton mr-16px" @click="$modal.hide(modalName)">
              <b-icon icon="arrow-left-circle" />
              <span class="newBackButton-textpart">Back</span>
            </button>
            Redirect to
          </h2>
          <!-- <p v-if="selected.extensions.length>1">team mode</p> -->
        </div>
        <div class="dialer-edit-actions d-flex">
          <button :class="selected.extensions.length==0?'newDeleteButton':'newDoneButton'" :disabled="api.update_extension.send" @click="updateExtension()">
            <vb-icon :icon="selected.extensions.length==0?'squared-delete-icon':'squared-done-icon'" height="38px" width="38px" />
            <span class="newDoneButton-textPart">{{selected.extensions.length==0?'Unassign':'Done'}}</span>
          </button>
        </div>
      </div>
      <div class="TabsDesignWithIcon-container mt-20px">
        <div 
          v-for="extension in allowExtensions" 
          :key="extension" 
          @click="selected.tab=extension" 
          class="TabsDesignWithIcon-item" 
          :class="{
            'active': selected.tab == extension
          }"
        >
          <vb-icon :icon="extension | extensionInfo('icon')" height="38px" width="38px" />
          <span class="TabsDesignWithIcon-item-textPart">{{ extension | extensionInfo('title') }}</span>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between w-100 mt-20px">
        <div class="latestGreyBox-heading-main-24px">{{selected.tab | extensionInfo('title')}}</div>
        <div class="w-50 whiteBGinputWithGreyRoundedBorder">
          <b-input type="text" :placeholder="selected.tab | extensionInfo('search-placeholder')" v-model="filter.list.search" />
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <b-alert :show="data.user_mode"  variant="info">
          <b-icon icon="info-circle-fill" variant="info" />
          <span class="ml-2">To ring multiple user you will need to turn off "dedicated number mode" or assign this number to a team where multiple user based on groups or departments can be added.</span>
        </b-alert>
        <div v-if="api.extensions.send" class="whiteBoxWithBorderInsideGreyBox">
          <div v-for="n in 4" :key="n.id" class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex align-items-center">
              <div class="latestShimmerDesign mr-2" style="height:45px;width:45px"></div>
              <div class="d-flex flex-column">
                <div class="latestShimmerDesign" style="height:21px;width:100px"></div>
                <div class="latestShimmerDesign mt-1" style="height:21px;width:70%"></div>
              </div>
            </div>
            <div class="latestShimmerDesign mr-2" style="height:24px;width:24px"></div>
          </div>
        </div>
        <div v-else class="whiteBoxWithBorderInsideGreyBox">
          <div v-for="extension in extensions" :key="extension.accountcode" class="whiteBoxWithBorderInsideGreyBox-row min-height-none">
            <b-form-checkbox
              :id="'checkbox-' + extension.accountcode"
              :disabled="api.update_extension.send"
              @change="onSelectExtension(extension)"
              name="telephoneextension"
              class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding noPYinLabel"
              :checked="selected.extensions.includes(extension.accountcode)"
            >
              <Info :id="extension.accountcode" :type="extension.account" :is_blf="false" />
            </b-form-checkbox>
          </div>
          <vb-no-record class="my-5" v-if="extensions.length==0" :text="api.extensions.send?'':'There is no record'" :design="3" />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from "../../utils";
import Info from '../Lists/Info.vue'
export default {
  name: "NumberAssignModal",
  components: {
    Info,
  },
  inject: [
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'NumberAssignModal'
    },
    allowExtensions: {
      type: Array,
      default: ()=>['USER','TEAM','BASICIVR','QUEUE']
    },
  },
  data() {
    return {
      api: {
        extensions: this.$helperFunction.apiInstance(),
        update_extension: this.$helperFunction.apiInstance(),
      },
      selected: {
        tab: 'USER',
        extensions: [],
      },
      response: {
        extensions: {},
      },
      filter: {
        list: {
          search: '',
        },
      },
      data: {
        number_id: '',
        user_mode: false,
      },
      extra_data: {

      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser',
    ]),
    useraccountcode(){ return this.getCurrentUser.account },
    extensions(){
      const search = this.filter.list.search.trim().toLowerCase()
      // const useraccountcode = this.useraccountcode
      const tab = this.selected.tab
      return Object.values(this.response.extensions?.data ?? {}).filter(extension=>/*extension.accountcode!=useraccountcode && */extension.account==tab && `${extension.accountname} ${extension.extn}`.trim().toLowerCase().includes(search))
    },
  },
  filters: {
    extensionInfo(value,type='icon'){
      const is_icon = type=='icon'
      const is_search_placeholder = type=='search-placeholder'
      const is_title = type=='title'
      if(value=='TEAM') {
        if(is_title) return 'Team'
        if(is_icon) return 'squared-multipleUsers-icon'
        if(is_search_placeholder) return 'Search team'
      } else if(value=='BASICIVR') {
        if(is_title) return 'IVR'
        if(is_icon) return 'squared-ivr-icon'
        if(is_search_placeholder) return 'Search ivr'
      } else if(value=='QUEUE') {
        if(is_title) return 'Call queue'
        if(is_icon) return 'squared-callQueue-icon'
        if(is_search_placeholder) return 'Search call queue'
      } else if(value=='USER') {
        if(is_title) return 'User'
        if(is_icon) return 'squared-singleUser-icon'
        if(is_search_placeholder) return 'Search user'
      }
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.number_id=event?.params?.number_id ?? ''
      this.data.user_mode=!!event?.params?.user_mode
      this.selected.extensions=event?.params?.assigneds ?? []
      this.extra_data=event?.params?.extra_data ?? {}
      this.fetchExtensions();
      let extnType = '';
      if(extnType == "BASICIVR"){
        this.selected.tab = 'BASICIVR'
      } else if(extnType == "TEAM" || extnType == "RINGGROUP"){
        this.selected.tab = 'TEAM'
      } else if(extnType == "USER"){
        this.selected.tab = 'USER'
      } else if(extnType == "QUEUE"){
        this.selected.tab = 'QUEUE'
      } else {
        this.selected.tab = 'USER'
      }
    },
    onBeforeClose() {
      this.api.extensions?.reset?.();
      this.api.update_extension?.reset?.();
      this.selected.extensions = [];
      this.selected.tab = 'USER';
      this.response.extensions = {};
      this.filter.list.search = '';
      this.data.number_id = '';
      this.data.user_mode = false;
      this.extra_data={}
    },
    async fetchExtensions() {
      try {
        this.api.extensions.send = true;
        const { data } = await VOIP_API.endpoints.telephone_number.extensions()
        this.response.extensions = data ?? {};
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.extensions.send = false;
      }
    },
    onSelectExtension(extension){
      const extensions = this.response.extensions?.data
      if(this.selected.extensions.includes(extension.accountcode)) {
        this.selected.extensions.splice(this.selected.extensions.indexOf(extension.accountcode),1)
      } else {
        if(!this.data.user_mode && extension.account=='USER' && this.selected.extensions.every(i=>extensions?.[i]?.account=='USER')) {
          this.selected.extensions.push(extension.accountcode)
        } else {
          this.selected.extensions=[extension.accountcode]
        }
      }
    },
    async updateExtension() {
      if(this.api.update_extension.send) return;
      try {
        this.api.update_extension.send=true
        let extension_type = ''
        if(this.selected.extensions.length==1) {
          const accountcode = this.selected.extensions[0]
          const extension = this.response.extensions?.data[accountcode]
          extension_type=extension.account
        }
        await VOIP_API.endpoints.telephone_number.updateExtn(this.data.number_id, {
          extn: this.selected.extensions?.join(','),
          field: "extn",
        })
        this.$emit("updated",{
          extensions: this.selected.extensions,
          extra_data: this.extra_data,
          extension_type: extension_type,
        });
        this.appNotify({
          message: this.selected.extensions.length==0?'Successfully Unassign':'Successfully Added',
          type: 'success',
        })
        this.$modal.hide(this.modalName);
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_extension.send=false
      }
    },
  },
};
</script>

<style lang="scss">
.slideToRigth-enter,
.slideToRigth-leave-active {
  right: -100%;
}
</style>
