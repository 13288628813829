<template>
  <modal class="dialer_animation right_side_popup m-w-900" width="50%" height="auto" :scrollable="true" :name="modalName">
    <!-- <div class="dialer-edit-header w-100">
      <h2 class="dialer-settings-title newer mb-0">Call recording</h2>
      <div class="dialer-edit-actions">
        <a class="newDoneButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-done-icon" height="38px" width="38px" />
          <span class="newDoneButton-textPart">Done</span>
        </a>
      </div> 
    </div> -->
    <CallRecordingSettings :openedFromModal="true" @closeModal="$modal.hide(modalName)" />
  </modal>
</template>

<script>
import CallRecordingSettings from '@/components/Setting/CallRecording.vue';
export default {
  name: 'CallRecordingModal',
  components: {
    CallRecordingSettings,
  },
  props: {
    modalName: {
      type: String,
      default: 'CallRecording'
    },
  },
}
</script>

<style>

</style>