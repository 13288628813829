<template>
  <modal  class="dialer_animation right_side_popup GenerateTimeOffReportsModal HrmForModalScroll" :clickToClose="true"  :scrollable="true" name="AddNewEmployeeDataLocationsModal">
    <div class="modalHeading-container">
      <div class="modalHeading">Add Locations</div>
      <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('AddNewEmployeeDataLocationsModal')">Close</button>
    </div>
    <div class="modalInner-container">
      <div class="innerShadowInput-container ">
        <label>Title</label>
        <input class=""/>
      </div>
      <b-form-checkbox class="HrmCheckBox-container">
        Use as the default employee location
      </b-form-checkbox>
      <button class="dialer-button dialer-button-primary mt-32px mr-0"> Add </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'AddNewEmployeeDataLocationsModal',
}
</script>

<style>

</style>