var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_vm.is_sip ? _c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center"
  }, [_c('div', {
    staticClass: "dialer-user-dp mr-2"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.callObjInfo.image
    }
  })]), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('div', {}, [_c('div', {
    staticClass: "forTextColor"
  }, [_vm._v(" From: " + _vm._s(_vm._f("number_formater")(_vm.callObjInfo.from_number)) + " " + _vm._s(_vm.callObjInfo.from_name)), _c('br'), _vm._v(" To: " + _vm._s(_vm._f("number_formater")(_vm.callObjInfo.to_number)) + " " + _vm._s(_vm.callObjInfo.to_name)), _c('br')]), _vm.callObjInfo.call_type ? _c('div', [_vm._v(_vm._s(_vm.callObjInfo.call_type))]) : _vm._e()])])]) : _c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center"
  }, [_c('div', {
    staticClass: "dialer-user-dp mr-2"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.callObjInfo.image
    }
  })]), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('div', {}, [_c('div', {
    staticClass: "forTextColor"
  }, [_vm._v(_vm._s(_vm.callObjInfo.name) + " " + _vm._s(_vm.callObjInfo.sub_info))])])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }