<template>
  <modal 
    class="dialer_animation center-zoomIn-popup v2 m-w-400 IosModal" 
    height="auto" 
    :name="modalName" 
    :clickToClose="false" 
    @before-open="onBeforeOpen" 
    @before-close="onBeforeClose"
  >
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="api.file.send ? '' : $modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">{{ title || data.title }}</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div class="innerModalIos">
      <div class="basicWhiteIOScard mt-32px mb-16px">
        
        

        <div class="py-4 pr-4 pl-2 text-center headingInsideTable">
          This feature is only available on Web and Desktop app
        </div>

      </div>
    </div>
  </modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
function maxSizeValidation(size){
  return function (file){ return !(file?.size>size) }
}
function minSizeValidation(size){
  return function (file){ return !(file?.size<=size) }
}
export default {
  name: "UploadAudioModal",
  inject: ['getProperty'],
  props: {
    modalName: {
      type: String,
      default: 'UploadAudioModal'
    },
    maxFileSize: {
      type: Number,
      default: 5 * 1024 * 1024
    },
    minFileSize: {
      type: Number,
      default: 5 * 1024
    },
    title: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      extra_data: {},
      data: {
        title: '',
        maxFileSize: 0,
        minFileSize: 0,
      },
      forms: {
        file: this.$helperFunction.formInstance({
          data: {
            file: null,
          },
        }),
      },
      api: {
        file: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, upload_progress: true, })
      },
    };
  },
  computed: {
    maxSize(){ return this.data.maxFileSize || this.maxFileSize },
    minSize(){ return this.data.minFileSize || this.minFileSize },
  },
  validations() {
    return {
      forms: {
        file: {
          file: {
            required,
            maxSize: maxSizeValidation(this.maxSize),
            minSize: minSizeValidation(this.minSize),
          },
        },
      },
    }
  },
  filters: {
    fileToUrl(file){
      if(file instanceof File){
        return URL.createObjectURL(file)
      } else {
        return ''
      }
    }
  },
  methods: {
    onBeforeOpen(event) {
      this.extra_data = event?.params?.extra_data ?? {}
      this.data.title = event?.params?.title ?? ''
      this.data.maxFileSize = event?.params?.maxFileSize ?? 0
      this.data.minFileSize = event?.params?.minFileSize ?? 0
    },
    onBeforeClose(){
      this.extra_data = {}
      this.data.title = ''
      this.data.maxFileSize = 0
      this.data.minFileSize = 0
      this.api.file.reset()
      this.forms.file.reset()
    },
    upload(){
      let vm = this
      vm.forms.file.submitted=true
      vm.$v.forms.file.$touch()
      if(vm.$v.forms.file.$invalid) return;
      vm.api.file.send=true
      vm.api.file.error_message=''
      this.$emit('audio',{
        file: vm.forms.file.file,
        url: URL.createObjectURL(vm.forms.file.file),
        upload_progress: vm.api.file.upload_progress,
        extra_data: {
          ...vm.extra_data,
        },
        hide(){
          vm.$modal.hide(vm.modalName);
          vm.api.file.send=false
        },
        load(){
          vm.api.file.send=false
        },
        error(message){
          vm.api.file.error_message=message
          vm.api.file.send=false
        },
      })
    },
  },
};
</script>

<style scoped>
/**
 * FilePond Custom Styles
 */
.filepond--drop-label {
  color: #4c4e53;
}

.filepond--label-action {
  text-decoration-color: #babdc0;
}

.filepond--panel-root {
  border-radius: 2em;
  background-color: #edf0f4;
  height: 1em;
}

.filepond--item-panel {
  background-color: #595e68;
}

.filepond--drip-blob {
  background-color: #7f8a9a;
}
/* [data-filepond-item-state="processing-complete"] .filepond--file-status-sub, */
.filepond--file-status .filepond--file-status-sub,
button.filepond--file-action-button.filepond--action-revert-item-processing {
  display: none;
}
</style>
