<template>
    <modal class="dialer_animation right_side_popup global-modal-hrm Scrollable-hrm " name="HRMAddNewAssetModal"  :clickToClose="true"  @before-open="beforeOpen" :scrollable="true">
     <form @submit.prevent="''" class="dialer-form ">
       <div class="dialer-edit-header">
          <h2 class="dialer-edit-title">Add new asset</h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('HRMAddNewAssetModal')" />
            <a id="CloseModalButton" v-else class="dialer-button dialer-button-delete" @click="$modal.hide('HRMAddNewAssetModal')">Close</a>
          </div>
       </div>
        <div class="sp-gm-if mt-5">
          <label for="Street" class="gm-hrm-label">Asset name</label>
          <b-form-input  placeholder="25090" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label class="gm-hrm-label">Category</label>
          <div class="dd-gm-container">
            <b-dropdown no-caret class="gm-mainBorderfordropi">
                <template #button-content>
                  <div class="gm-button-content-dropi">
                    <div class="gm-dropi-contenti">Category</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">A</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">B</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="sp-gm-if ">
          <label for="Street" class="gm-hrm-label">Description</label>
          <b-form-textarea
            id="textarea-small"
            size="sm"
            placeholder="Small textarea"
            class="gm-hrm-textnote">
          </b-form-textarea>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Asset number</label>
          <b-form-input  placeholder="25090" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Serial number</label>
          <b-form-input   placeholder="25090" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="">
          <label for="Date of birth" class="gm-hrm-label">Date of Birth</label>
          <b-form-datepicker
              id="datepicker-buttons"
              class="gm-hrm-date"
              locale="en">
          </b-form-datepicker>
          <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="20" viewBox="0 0 17.5 20" class="svg-img-calender">
            <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,18.125A1.875,1.875,0,0,0,1.875,20h13.75A1.875,1.875,0,0,0,17.5,18.125V7.5H0Zm12.5-7.656A.47.47,0,0,1,12.969,10h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,12.969,15h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,7.969,10H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,7.969,15H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,2.969,10H4.531A.47.47,0,0,1,5,10.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,2.969,15H4.531A.47.47,0,0,1,5,15.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469ZM15.625,2.5H13.75V.625A.627.627,0,0,0,13.125,0h-1.25a.627.627,0,0,0-.625.625V2.5h-5V.625A.627.627,0,0,0,5.625,0H4.375A.627.627,0,0,0,3.75.625V2.5H1.875A1.875,1.875,0,0,0,0,4.375V6.25H17.5V4.375A1.875,1.875,0,0,0,15.625,2.5Z" fill="#5576d1"/>
          </svg>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Comments</label>
          <b-form-textarea
            id="textarea-small"
            size="sm"
            placeholder="Small textarea"
            class="gm-hrm-textnote">
          </b-form-textarea>
        </div>
        <button class="btn-primarynewupdated1 mt-4 mb-4">Save</button>
     </form>
    </modal>
</template>
<script>

export default {
  name: 'HRMAddNewAssetModal',
}
</script>

<style>

</style>