var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-call-setting"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("My calls")])], 1)] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_vm.EditUserModal ? [_vm._v(" Calls ")] : [_vm._v(" My calls ")], _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "id": "ivr-info",
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'calls'
        });
      }
    }
  })], 2)]], 2), _vm._m(0), _c('div', {
    staticClass: "dialer-form mt-0"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call information")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside"
  }, [_c('CallForwardingSetting', {
    attrs: {
      "account": _vm.voipaccount,
      "forwardCallerId": true
    },
    on: {
      "cf-updated": function ($event) {
        return _vm.$emit('cf-updated');
      }
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0"
  }, [_c('ScheduleSetting', {
    attrs: {
      "account": _vm.voipaccount
    },
    on: {
      "ofh-days-updated": function ($event) {
        return _vm.$emit('ofh-days-updated');
      },
      "ofh-toggle-updated": function ($event) {
        return _vm.$emit('ofh-toggle-updated');
      },
      "ofh-default-updated": function ($event) {
        return _vm.$emit('ofh-default-updated');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.call_waiting
    },
    attrs: {
      "title": _vm.getUserPermissions.call_waiting ? '' : 'Your package does not support this feature'
    }
  }, [_vm.api.user_detail.send ? [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  })] : _c('b-form-checkbox', {
    ref: "call_waiting",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_waiting || _vm.api.update_call_waiting.send || _vm.api.user_detail.send,
      "checked": !_vm.conditions.call_waiting,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateCallWaiting();
      }
    }
  })], 2)]), _vm.voipaccount ? _c('MusicOnHoldWidget', {
    attrs: {
      "location": 'whiteBoxWithBorderInsideGreyBox',
      "accountcode": _vm.voipaccount
    }
  }) : _vm._e(), _vm.EditUserModal && _vm.voipaccount ? _c('UserCallRecordingWidget', {
    attrs: {
      "accountcode": _vm.voipaccount,
      "allUserData": {
        extn: _vm.user.extn,
        user: _vm.user.display_name
      },
      "editUserModal": _vm.EditUserModal
    }
  }) : _vm._e()], 1)]), _c('VoicemailSetting', {
    attrs: {
      "accountcode": _vm.voipaccount
    }
  }), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-24px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Notifications")]), _c('NotificationSettingsWidget', {
    attrs: {
      "accountcode": _vm.voipaccount
    }
  })], 1)], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v("Enhance your call experience. Take control of unanswered calls, availability hours, and call recording.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call waiting")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("If enabled, you will be able to receive multiple incoming calls at the same time.")])]);

}]

export { render, staticRenderFns }