var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v(_vm._s(_vm.data.title || _vm.title))]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "IosTabsSection fourTabs mt-16px mb-16px"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.value,
      staticClass: "IosTabButton",
      class: {
        'active': _vm.selected.tab.value === tab.value
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab;
        }
      }
    }, [_vm._v(" " + _vm._s(tab.text) + " ")]);
  }), 0), _c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": `Search ${_vm.selected.tab.text}`
    },
    model: {
      value: _vm.filter.list.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.list, "search", $$v);
      },
      expression: "filter.list.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.filterList, function (extension) {
    return _c('div', {
      key: extension.accountcode,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.extension = extension.accountcode;
          _vm.updateSelectedAccount();
        }
      }
    }, [_c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": extension.extn,
        "name": _vm._f("check_empty")(extension.accountname, 'None'),
        "sub_info": extension.extn
      }
    }), _vm.selected.extension == extension.accountcode ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.filterList.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.extensions.send ? '' : 'There is no playlist',
      "design": 3
    }
  }, [_vm.api.extensions.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }