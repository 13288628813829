<template>
  <modal :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose" class="dialer_animation right_side_popup m-w-500" width="50%" height="auto" :scrollable="true">
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div class="dialer-edit-header">
        <div>
          <h2 class="dialer-edit-title">Assign to</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" @click="updateSelectedAccount()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="dialer-tabs">
        <div class="dialer-tab-headers">
          <div v-for="(tab, index) in tabs" :key="index" :class="`dialer-tab text-capitalize ${selected.tab === tab.value ? 'selected':''}`" @click="selected.tab = tab.value">
            {{ tab.text }}
          </div>
        </div>
        <div class="dialer-tab-content">
          <template v-if="selected.tab === 'USER'">
            <div class="w-100  whiteBGinputWithGreyRoundedBorder md-mar-bottom">
              <b-input type="text" v-model="filter.user.search" placeholder="Search user, extension" />
            </div>
            <ul class="list-unstyled dialer-asssignee-list">
              <div v-for="extension in filterUsers" :key="extension.accountcode">
                <li class="dialer-assignee cursor_auto">
                  <b-form-checkbox 
                    :id="'checkbox-' + extension.accountcode" 
                    :checked="selected.extension_accountcode==extension.accountcode" 
                    @change="selected.extension_accountcode=selected.extension_accountcode==extension.accountcode ? '' : extension.accountcode"
                    class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare"
                  >
                    <Info :id="extension.accountcode" :name="extension.accountname" :sub_info="extension.extn" />
                  </b-form-checkbox>
                </li>
              </div>
              <vb-no-record v-if="isEmpty(filterUsers)" :text="api_sent.extensions_list ? '' : 'Sorry, no data found'" :design="3">
                <!-- <vb-loading class="my-2" :type="'loader'" v-if="api_sent.extensions_list" slot="loading" /> -->
                <vb-loading class="my-2" v-if="api_sent.extensions_list" slot="loading" />
              </vb-no-record>
            </ul>
          </template>
          <template v-if="selected.tab === 'TEAM'">
            <div class="w-100  whiteBGinputWithGreyRoundedBorder md-mar-bottom">
              <b-input type="text" class="w-100" v-model="filter.team.search" placeholder="Search team, extension" />
            </div>
            <ul class="list-unstyled dialer-asssignee-list">
              <div v-for="extension in filterTeams" :key="extension.accountcode">
                <li class="dialer-assignee cursor_auto">
                  <b-form-checkbox 
                    :id="'checkbox-' + extension.accountcode" 
                    :checked="selected.extension_accountcode==extension.accountcode"
                    @change="selected.extension_accountcode=selected.extension_accountcode==extension.accountcode ? '' : extension.accountcode"
                    class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare"
                  >
                    <Info :id="extension.accountcode" :name="extension.accountname" :sub_info="extension.extn" />
                  </b-form-checkbox>
                </li>
              </div>
              <vb-no-record v-if="isEmpty(filterTeams)" :text="api_sent.extensions_list ? '' : 'Sorry, no data found'" :design="3">
                <!-- <vb-loading class="my-2" :type="'loader'" v-if="api_sent.extensions_list" slot="loading" /> -->
                <vb-loading class="my-2" v-if="api_sent.extensions_list" slot="loading" />
              </vb-no-record>
            </ul>
          </template>
          <template v-if="selected.tab === 'BASICIVR'">
            <div class="w-100  whiteBGinputWithGreyRoundedBorder md-mar-bottom">
              <b-input type="text" class="w-100" v-model="filter.ivr.search" placeholder="Search user, extension" />
            </div>
            <ul class="list-unstyled dialer-asssignee-list">
              <div v-for="extension in filterIVRs" :key="extension.accountcode">
                <li class="dialer-assignee cursor_auto">
                  <b-form-checkbox 
                    :id="'checkbox-' + extension.accountcode" 
                    :checked="selected.extension_accountcode==extension.accountcode"
                    @change="selected.extension_accountcode=selected.extension_accountcode==extension.accountcode ? '' : extension.accountcode"
                    class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare"
                  >
                    <Info :id="extension.accountcode" :name="extension.accountname" :sub_info="extension.extn" />
                  </b-form-checkbox>
                </li>
              </div>
              <vb-no-record v-if="isEmpty(filterIVRs)" :text="api_sent.extensions_list ? '' : 'Sorry, no data found'" :design="3">
                <!-- <vb-loading class="my-2" :type="'loader'" v-if="api_sent.extensions_list" slot="loading" /> -->
                <vb-loading class="my-2" v-if="api_sent.extensions_list" slot="loading" />
              </vb-no-record>
            </ul>
          </template>
          <template v-if="selected.tab === 'QUEUE'">
            <div class="w-100  whiteBGinputWithGreyRoundedBorder md-mar-bottom">
              <b-input type="text" class="w-100" v-model="filter.call_queue.search" placeholder="Search user, extension" />
            </div>
            <ul class="list-unstyled dialer-asssignee-list">
              <div v-for="extension in filterCallQueue" :key="extension.accountcode">
                <li class="dialer-assignee cursor_auto">
                  <b-form-checkbox 
                    :id="'checkbox-' + extension.accountcode" 
                    :checked="selected.extension_accountcode==extension.accountcode"
                    @change="selected.extension_accountcode=selected.extension_accountcode==extension.accountcode ? '' : extension.accountcode"
                    class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare"
                  >
                    <Info :id="extension.accountcode" :name="extension.accountname" :sub_info="extension.extn" />
                  </b-form-checkbox>
                </li>
              </div>
              <vb-no-record v-if="isEmpty(filterCallQueue)" :text="api_sent.extensions_list ? '' : 'Sorry, no data found'" :design="3">
                <!-- <vb-loading class="my-2" :type="'loader'" v-if="api_sent.extensions_list" slot="loading" /> -->
                <vb-loading class="my-2" v-if="api_sent.extensions_list" slot="loading" />
              </vb-no-record>
            </ul>
          </template>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from "../../utils";
import Info from '../Lists/Info.vue'
export default {
  name: "SelectExtensionModal",
  components: {
    Info,
  },
  props: {
    modalName: {
      type: String,
      default: 'SelectExtensionModal'
    },
  },
  data() {
    return {
      response: {
        extensions: {},
      },
      selected: {
        tab: 'USER',
        extension_accountcode: '',
      },
      api_sent: {
        extensions_list: false,
      },
      filter: {
        user: {
          search: '',
        },
        team: {
          search: '',
        },
        ivr: {
          search: '',
        },
        call_queue: {
          search: '',
        },
      },
      data: {},
    };
  },
  inject:['isEmpty'],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    tabs(){
      return [
        {
          text: "User",
          value: 'USER'
        },
        {
          text: "Team",
          value: 'TEAM'
        },
        {
          // text: "Basic IVR",
          text: "IVR",
          value: 'BASICIVR'
        },
        {
          text: "Call Queue",
          value: 'QUEUE'
        },
      ]
    },
    filterExtensions(){
      const tab = this.selected.tab
      var array = Object.values(this.response.extensions)
      return array.filter(item=>item.account==tab && item.accountcode!=this.getCurrentUser.account)
    },
    filterUsers(){
      const search = this.filter.user.search.trim().toLowerCase()
      const selected = this.selected.extension_accountcode
      var sort_array = this.filterExtensions
      sort_array = sort_array.sort(item=>selected==item.accountcode?-1:1)
      return sort_array.filter(extn=>`${extn.extn} ${extn.accountname}`.trim().toLowerCase().includes(search))
    },
    filterIVRs(){
      const search = this.filter.ivr.search.trim().toLowerCase()
      const selected = this.selected.extension_accountcode
      var sort_array = this.filterExtensions
      sort_array = sort_array.sort(item=>selected==item.accountcode?-1:1)
      return sort_array.filter(extn=>`${extn.extn} ${extn.accountname}`.trim().toLowerCase().includes(search))
    },
    filterTeams(){
      const search = this.filter.team.search.trim().toLowerCase()
      const selected = this.selected.extension_accountcode
      var sort_array = this.filterExtensions
      sort_array = sort_array.sort(item=>selected==item.accountcode?-1:1)
      return sort_array.filter(extn=>`${extn.extn} ${extn.accountname}`.trim().toLowerCase().includes(search))
    },
    filterCallQueue(){
      const search = this.filter.call_queue.search.trim().toLowerCase()
      const selected = this.selected.extension_accountcode
      var sort_array = this.filterExtensions
      sort_array = sort_array.sort(item=>selected==item.accountcode?-1:1)
      return sort_array.filter(extn=>`${extn.extn} ${extn.accountname}`.trim().toLowerCase().includes(search))
    },
  },
  methods: {
    onBeforeOpen(event){
      this.selected.extension_accountcode = event.params?.account || '';
      this.fetchExtensions();
    },
    onBeforeClose(){
      this.data = {}
      this.selected.extension_accountcode = '';
      this.selected.tab = 'USER';
      this.response.extensions={}
    },
    fetchExtensions() {
      this.api_sent.extensions_list=true
      VOIP_API.endpoints.telephone_number.extensions()
      .then(({ data: res }) => {
        this.response.extensions = res.data;
      })
      .finally(()=>{
        this.api_sent.extensions_list=false
      });
    },
    updateSelectedAccount() {
      let data = Object.values(this.response.extensions).find((value) => value.accountcode == this.selected.extension_accountcode);
      if(data){
        this.$emit("select",data);
      }
      this.$modal.hide(this.modalName);
    },
  },
};
</script>
