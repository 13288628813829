<template>
  <div class="top-main-hrm">
    <section>
      <div class="bg-hrm-mike scrollow1">
        <div class="d-flex justify-content-between align-items-start">
          <div class="w-75 for-home-dd-width d-flex flex-wrap">     
            
          </div> 

          <div class="w-25 top-btn-align">
            <button class="btn-svg-none" @click="design.grid=false">
              <svg id="Group_16231" data-name="Group 16231" xmlns="http://www.w3.org/2000/svg" class ="mr-top-sidebsvg" width="12.033" height="15.016" viewBox="0 0 12.033 15.016">
                  <path id="Icon_ionic-md-arrow-dropright" data-name="Icon ionic-md-arrow-dropright" d="M0,0,6.016,6.016,0,12.033Z" transform="translate(12.033 9) rotate(90)" fill="#5576d1"/>
                  <path id="Icon_ionic-md-arrow-dropright-2" data-name="Icon ionic-md-arrow-dropright" d="M0,0,6.016,6.016,0,12.033Z" transform="translate(0 6.016) rotate(-90)" fill="#5576d1"/>
              </svg>
            </button>
            <button class="btn-svg-none" @click="design.grid=true">
              <svg id="Icon_ionic-ios-menu" data-name="Icon ionic-ios-menu" xmlns="http://www.w3.org/2000/svg" class ="mr-top-sidebsvg" width="21.607" height="15.75" viewBox="0 0 21.607 15.75">
                  <path id="Path_16" data-name="Path 16" d="M25.207,12.375H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,12.375Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
                  <path id="Path_17" data-name="Path 17" d="M25.207,19.125H5.4A1.035,1.035,0,0,1,4.5,18h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,19.125Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
                  <path id="Path_18" data-name="Path 18" d="M25.207,25.875H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,25.875Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
              </svg>
            </button>
          </div>

        </div>
        
        <div class="card-container-main-box mt-5">
          <template v-if="design.grid">
            <div class="card-contain" v-for="team in filterTeams" :key="team.id">
              <div class="imgify-home-box">
                <img :src="team | get_property('image')" @error="$event.target.src=local_filess.images.user_male" class="imgify-heiwid" />
              </div>
              <div class="decription-img-box-home">{{ team.teamName }}</div>
              <div class="decription-img-box-home">Members: {{ team | get_property('teams_users[0].usersCount',0) }}</div>
            </div>
            <vb-no-record v-if="filterTeams.length==0" :text="''" :design="3">
              <vb-loading v-if="api.fetch_teams.send" class="text-center w-100" slot="loading" />
            </vb-no-record>
          </template>
          <template v-else>
            <vb-table 
              :isListEmpty="filterTeams.length==0" 
              :listLength="filterTeams.length" 
              :loading="api.fetch_teams.send" 
            >
              <tr slot="header">
                <th class="dialer-has-sort">
                  <span>Name</span>
                </th>
                <th class="dialer-has-sort dialer-col-center">
                  <span>Members</span>
                </th>
              </tr>
              <template #body="{ start, end }">
                <tr v-for="(team, index) in filterTeams" :key="team.id" v-show="index >= start && index <= end">
                  <td class="dialer-row-title dialer-col-name-width wd-75">
                    <div class="d-flex dialer-numberWithCountry">
                      <span >
                        <img class="country-img" width="45px" :src="team | get_property('image')" @error="$event.target.src=local_filess.images.user_male" />
                      </span>
                      <span class="d-flex flex-column ml-1 dialer-numberInfo justify-content-center">
                        <span class="m-0 didNumber">{{ team.teamName }}</span>
                      </span>
                    </div>
                  </td>
                  <td class="dialer-col-center wd-25">{{ team | get_property('teams_users[0].usersCount',0) }}</td>
                </tr>
              </template>
            </vb-table>
          </template>
        </div>
      
      </div>
    </section>
  </div>
</template>

<script>

import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import { GET_PAMARLY_ORGANIZATION_ID } from '@/store/helper/getters'
export default {
  name: 'PamarlyTeams',
  inject:['local_filess','appNotify'],
  data(){
    return {
      api: {
        fetch_teams: this.$helperFunction.apiInstance(),
      },
      response: {
        teams: [],
      },
      design: {
        grid: true,
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_PAMARLY_ORGANIZATION_ID
    ]),
    filterTeams(){ 
      return this.response.teams
    },
  },
  methods: {
    fetchTeams(){
      let vm = this 
      vm.api.fetch_teams.send=true
      VOIP_API.endpoints.pamarly.teams.list(vm.GET_PAMARLY_ORGANIZATION_ID)
      .then(({ data: { result: teams } })=>{
        vm.response.teams=teams
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.fetch_teams.send=false
      })
    },
  },
  mounted(){
    this.fetchTeams()
  },
}
</script>

<style>

</style>