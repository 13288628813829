var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.api.fetch_balance_inquiry.send ? _c('div', {
    staticClass: "w-100 mt-16px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign w-100",
    staticStyle: {
      "height": "60px"
    }
  })]) : _vm.conditions.have_balance ? _c('div', {
    staticClass: "latestSMS-textArea-container"
  }, [_c('div', {
    staticClass: "latestSMS-textArea-inner"
  }, [_c('div', {
    class: `latestSMS-textArea ${_vm.forms.send_sms.sms.length > 50 ? '' : 'h-25px'}`
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-smileyFace-icon",
      "height": "25px",
      "width": "25px"
    }
  }), _c('b-form-textarea', {
    attrs: {
      "size": "sm",
      "disabled": _vm.api.send_sms.send,
      "maxlength": _vm.$v.forms.send_sms.sms.$params.maxLength.max,
      "placeholder": "Write your message",
      "rows": "1",
      "max-rows": "6"
    },
    model: {
      value: _vm.forms.send_sms.sms,
      callback: function ($$v) {
        _vm.$set(_vm.forms.send_sms, "sms", $$v);
      },
      expression: "forms.send_sms.sms"
    }
  }), _vm.forms.send_sms.submitted && _vm.$v.forms.send_sms.sms.$invalid || _vm.api.send_sms.validation_errors.sms ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.send_sms.sms.required ? _c('span', [_vm._v("Sms is required")]) : !_vm.$v.forms.send_sms.sms.maxLength ? _c('span', [_vm._v("Sms can be maximum " + _vm._s(_vm.$v.forms.send_sms.sms.$params.maxLength.max) + " character")]) : _vm.api.send_sms.validation_errors.sms ? _vm._l(_vm.api.send_sms.validation_errors.sms, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }) : _vm._e()], 2) : _vm._e()], 1), _c('b-dropdown', {
    staticClass: "latestSMS-textArea-DD",
    attrs: {
      "disabled": _vm.api.send_sms.send,
      "no-caret": "",
      "menu-class": "w-fit-content-imp",
      "top": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "latestSMS-textArea-DD-buttonText"
        }, [_vm.forms.send_sms.from_number ? [_vm._v(_vm._s(_vm._f("number_formater")(_vm.forms.send_sms.from_number)))] : [_vm._v("Select caller id")]], 2), _c('span', {
          staticClass: "latestSMS-textArea-DD-arrowIcon"
        }, [_c('b-icon', {
          attrs: {
            "icon": "chevron-down"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_vm._l(_vm.vonageNumbers, function (number) {
    return _c('b-dropdown-item', {
      key: number,
      on: {
        "click": function ($event) {
          _vm.forms.send_sms.from_number = number;
        }
      }
    }, [_vm._v(_vm._s(_vm._f("number_formater")(number)))]);
  }), _vm.vonageNumbers.length == 0 ? _c('b-dropdown-item', [_vm._v("No telephone numbers to list.")]) : _vm._e()], 2), _vm.forms.send_sms.submitted && _vm.$v.forms.send_sms.from_number.$invalid || _vm.api.send_sms.validation_errors.from_number ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.send_sms.from_number.required ? _c('span', [_vm._v("Sms is required")]) : _vm.api.send_sms.validation_errors.from_number ? _vm._l(_vm.api.send_sms.validation_errors.from_number, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }) : _vm._e()], 2) : _vm._e()], 1), _c('b-button', {
    staticClass: "noStyleButton",
    attrs: {
      "disabled": _vm.api.send_sms.send,
      "type": "submit",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.sendSms();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-sendSMS-icon",
      "width": "59.997px",
      "height": "59.997px"
    }
  })], 1)], 1) : !_vm.conditions.have_balance ? _c('div', [_vm._v("You don't have balance")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }