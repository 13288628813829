var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('List', {
    ref: "list",
    attrs: {
      "groups": _vm.response.groups
    },
    on: {
      "select-group-id": function ($event) {
        _vm.selected.group_id = $event;
      }
    }
  }), _c('Board', {
    ref: "board",
    attrs: {
      "groupId": _vm.selected.group_id,
      "breadcrumb": _vm.breadcrumb
    },
    on: {
      "select-group-id": function ($event) {
        _vm.selected.group_id = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }