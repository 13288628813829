var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 AddPortNumberModal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit s1"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom mb-0",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.add();
      }
    }
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Add port number")]), _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-edit-actions w-100 d-flex justify-content-end mt-2"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.add_port_number.send
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.add_port_number.send ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Add port number")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-4"
  }, [_c('label', [_vm._v("Number")]), _c('input', {
    directives: [{
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_port_number.number,
      expression: "forms.add_port_number.number"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter number",
      "type": "text",
      "disabled": _vm.api.add_port_number.send
    },
    domProps: {
      "value": _vm.forms.add_port_number.number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_port_number, "number", $event.target.value);
      }
    }
  }), _vm.forms.add_port_number.submitted && _vm.$v.forms.add_port_number.number.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_port_number.number.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.add_port_number.number.validNumber ? _c('span', [_vm._v("* Number is invalid")]) : _vm._e()]) : _vm.api.add_port_number.validation_errors.number ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.add_port_number.validation_errors.number, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()])]), _c('div', {
    staticClass: "d-flex w-100 justify-content-end mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.add_port_number.send
    }
  }, [_vm.api.add_port_number.send ? _c('vb-spinner') : [_vm._v("Port number")]], 2)])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }