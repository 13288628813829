<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-800 editTeamModal" 
      width="40%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <section class="ct-user-new dialer-edit pb-4">
        <div class="dialer-edit-header">
          <div>
            <h2 class="dialer-edit-title">Assign User</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" :disabled="api.assign.send" @click="assignCall()">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div v-for="user in getVoipUsers" :key="user.voipaccount">
          <b-form-checkbox 
            :disabled="api.assign.send" 
            :checked="selected.accountcode==user.voipaccount" 
            @change="selected.accountcode=$event ? user.voipaccount : ''"
          >
            <Info :id="user.voipaccount" />
          </b-form-checkbox>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
// import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import Info from '../Lists/Info.vue';
export default {
  name: "AssignTodoModal",
  components: { 
    Info 
  },
  props: {
    modalName: {
      type: String,
      default: 'AssignTodo'
    },
  },
  data() {
    return {
      api: {
        assign: this.$helperFunction.apiInstance(),
      },
      selected: {
        accountcode: '',
      },
      data: this.$helperFunction.stateInstance({
        data: {
          call_id: '',
        }
      }),
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsers',
    ]),
  },
  methods: {
    onBeforeOpen(event) {
      this.data.call_id = event?.params?.call_id ?? '';
      this.selected.accountcode = event?.params?.accountcode ?? '';
    },
    onBeforeClose() {
      this.api.assign.reset()
      this.data.reset()
    },
    async assignCall(){
      if(this.api.assign.send) return;
      try {
        this.api.assign.send=true
        await VOIP_API.endpoints.todos.assign({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          assigned_accountcode: this.selected.accountcode,
          cdr_id: this.data.call_id,
          type: 'assign',
        })
        this.$modal.hide(this.modalName)
        this.$emit('assigned')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.assign.send=false
      }
    },
  },
};
</script>
