<template>
  <div class="dialer-soundSetting-device border-0 main-container-bill-22">
    <video height="160pxx" width="100%" :id="`testing-video-${random_id}`" autoplay />
    <div v-if="!!error" class="error-message-bill-22">
      {{error}}
    </div>
  </div>
</template>

<script>
import { $fn, devices, permissions } from '../../../utils'
export default {
  name: 'CameraTesting',
  props: {
    deviceId: {
      type: String,
    },
  },
  data(){
    return {
      random_id: '',
      error: '',
      streams: null
    }
  },
  watch: {
    "deviceId": "watchindDeviceId"
  },
  methods: {
    watchindDeviceId(id,old_id){
      const video = !id ? true : {
        deviceId: {
          exact: id
        }
      }
      if(old_id){
        this.stopStreams()
      }
      let vm = this
      var video_el = document.getElementById(`testing-video-${this.random_id}`)
      navigator.mediaDevices.getUserMedia({
        audio: false,
        video,
      })
      .then((stream)=>{
        if(vm._isDestroyed){
          stream?.getTracks?.()?.forEach?.(track=>track.stop())
          return;
        }
        vm.streams = stream
        video_el.srcObject = vm.streams
      })
      .catch((ex)=>{
        if(permissions.camera=='denied'){
          vm.error='Your camera Permissions is Blocked'
        } else if(!devices.camera_id){
          vm.error='You dont have camera device'
        } else if(ex.name=='NotReadableError'){
          vm.error='Your camera device is in use by another application'
        } else if(ex?.message){
          vm.error=ex?.message
        } else {
          vm.error='There is something went wrong in starting the video camera'
        }
      })
    },
    stopStreams(){
      this.streams?.getTracks?.()?.forEach?.(track=>track.stop())
      // this.streams=null
    },
  },
  created(){
    this.random_id = $fn.makeid(10)
  },
  mounted(){
    this.watchindDeviceId()
  },
  beforeDestroy(){
    this.stopStreams()
  },
}
</script>

<style lang="scss" scoped>
.dialer-soundSetting-device{
  video{
    transform: scaleX(-1);
  }
}
// bill
.main-container-bill-22 {
  position: relative;
  justify-content: center;
}
.error-message-bill-22 {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  width: 100%;
  color: white;
  background-color: #666666;
  border-radius: 16px;
}
</style>