var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit IVR")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.update_label.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.update_label.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_label.label,
      expression: "forms.update_label.label"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Name",
      "type": "text",
      "disabled": _vm.api.update_label.send,
      "maxlength": _vm.$v.forms.update_label.label.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_label.label
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_label, "label", $event.target.value);
      }, _vm.updateLabel]
    }
  }), _vm.forms.update_label.submitted && _vm.$v.forms.update_label.label.$invalid || _vm.api.update_label.validation_errors.label ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_label.label.required ? _c('span', [_vm._v("* Name is required")]) : !_vm.$v.forms.update_label.label.maxLength ? _c('span', [_vm._v(" * Name can be maximum " + _vm._s(_vm.$v.forms.update_label.label.$params.maxLength.max) + " character ")]) : _vm._e(), _vm._l(_vm.api.update_label.validation_errors.label, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])])], 1)])])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit IVR")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "profile-image-section-container forIos mt-32px"
  }, [_c('div', {
    staticClass: "profile-image-section",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('IVRProfileImageUploader', {
          type: 'IVR',
          accountcode: _vm.accountcode,
          current_image: _vm.ivrImage
        });
      }
    }
  }, [_c('div', {
    staticClass: "profile-img"
  }, [_c('img', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    },
    attrs: {
      "id": "user-profile-image-updated",
      "src": _vm.ivrImage
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _c('div', {
    staticClass: "BiggestMainHeading mt-22px"
  }, [_vm._v(_vm._s(_vm.ivrLabel))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('OutOfHoursWidget', {
    staticClass: "withBottomBorder",
    attrs: {
      "accountcode": _vm.accountcode
    }
  }), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('EditIVRAssignNumberModal', {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("IVR Numbers")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-8px"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.ivr, 'userDid.length', 0)))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('WelcomeGreeting', {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Welcome Greeting")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-audio-player', {
    staticClass: "IosAudioPlayer mr-8px",
    attrs: {
      "src": _vm._f("welcomgreetingsrc")(_vm.ivr)
    }
  }), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('MenuOptions', {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Menu Options")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.ivr, 'schedules[0].options.length', 0)))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AdvanceOptions', {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Advance Options")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])])])], _c('ProfileImageUploaderModal', {
    attrs: {
      "modalName": 'IVRProfileImageUploader'
    },
    on: {
      "image-uploaded": function ($event) {
        return _vm.$emit('update-list');
      }
    }
  }), _c('AssignNumbersModal', {
    attrs: {
      "modalName": 'EditIVRAssignNumberModal'
    },
    on: {
      "added": function ($event) {
        return _vm.$emit('update-list');
      }
    }
  }), _c('AdvanceOptionsModal'), _c('WelcomeGreetingModal'), _c('MenuOptionsModal', {
    on: {
      "update-list": function ($event) {
        return _vm.$emit('update-list');
      }
    }
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }