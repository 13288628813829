var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_vm.not_show_on_empty && !_vm.info.name ? _c('b', [_vm._v(" " + _vm._s(_vm.name) + " ")]) : _vm.only_name ? _c('b', [_vm._v(_vm._s(_vm.info.name || _vm.name || ""))]) : [_vm.is_image_show ? _c('vb-avatar', {
    staticClass: "sm-mar-right mr-3",
    attrs: {
      "image": _vm.info.image || _vm.image || '',
      "name": _vm.info.name || _vm.name || '',
      "status_id": _vm.info.status_id,
      "is_blf": _vm.is_blf,
      "id": _vm.id
    }
  }) : _vm._e(), !_vm.hide_popover ? _c('b-popover', {
    attrs: {
      "custom-class": "thisDeviceCustomPopOver autoWidth",
      "target": `${_vm._uid}-popover`,
      "triggers": "hover",
      "placement": "topright"
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('span', {
    staticClass: "mb-2"
  }, [_vm._v(_vm._s(_vm.info.sub_info || _vm.sub_info || '') + " " + _vm._s(_vm.username) + " ")])])]) : _vm._e(), _c('div', {
    staticClass: "user-info d-flex align-items-center"
  }, [_c('div', {
    staticClass: "user-name-box"
  }, [_c('span', {
    staticClass: "user-name",
    attrs: {
      "id": `${_vm._uid}-popover`
    }
  }, [_vm._v(" " + _vm._s(_vm.info.name || _vm.name || "") + " ")]), _vm.is_suspended_show && (_vm.suspended || _vm.info.suspended) ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.is_suspended_show && (_vm.suspended || _vm.info.suspended) ? _c('div', {
    staticClass: "suspendedTag"
  }, [_vm._v("Suspended")]) : _vm._e()]) : _vm._e(), _vm.sub_text ? _c('div', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm.sub_text || ""))]) : _vm._e(), _vm.devices && _vm.info.devices ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, _vm._l(_vm.info.devices, function (device, accountcode) {
    return _c('vb-icon', {
      key: accountcode,
      class: `mr-12px statusWithRespectToDevice`,
      attrs: {
        "icon": _vm._f("accountType")(device.type),
        "width": "20.219px",
        "height": "24.997px"
      }
    });
  }), 1) : _vm._e(), _vm._t("sub-info")], 2)]), _vm.is_unread_show && _vm.info.unread ? _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Unread Messages',
      expression: "'Unread Messages'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "unreadMessageInRightSideModuleInUsersTab"
  }, [_vm._v(_vm._s(_vm.info.unread || ""))]) : _vm._e(), (_vm.info.type || _vm.extension_type) && _vm.type_show ? _c('div', {
    staticClass: "ml-1 accountTypeInfo my-auto font-12"
  }, [_vm._v(" ("), _c('b', [_vm._v(_vm._s(_vm.info.type || _vm.extension_type || ''))]), _vm._v(") ")]) : _vm._e()]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }