<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.edit">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.delete_number.send || api.update_label.send ? '' : conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Number</div>
          <div class="plus-IOSIcon-container"></div>
        </div>
        <div class="innerModalIos">

          <form class="dialer-form" @submit.prevent="updateLabel()">
            <b-alert class="mt-32px" :show="!!api.update_label.error_message" variant="danger">{{api.update_label.error_message}}</b-alert>
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Name</label>
                  <input 
                    type="text"
                    class="w-100" 
                    :disabled="api.delete_number.send || api.update_label.send" 
                    v-model="forms.update_label.name" 
                  />
                  <p 
                    v-if="(forms.update_label.submitted && $v.forms.update_label.name.$invalid) || api.update_label.validation_errors.name" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update_label.name.required">* Name is required</span>
                    <span v-for="em in api.update_label.validation_errors.name" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
            </div>
            <button type="submit" class="IosFullWidthButton forDone mb-16px" :disabled="api.delete_number.send || api.update_label.send">
              <vb-spinner v-if="api.update_label.send" />
              <template v-else>Submit</template>
            </button>
            
          </form>

        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Number</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">

          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Number</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard onRightSide textAlignRight">{{ data.number.number | number_formater }}</div>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Label</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard onRightSide textAlignRight">{{ data.number.label }}</div>
                </div>
              </div>
            </div>

            <div class="basicWhiteIOScard mt-16px mb-16px">
              <div class="basicWhiteIOScard-item" @click="$modal.show('AssignTo', { type: 'setting' })">
                <div class="headingInsideTable">Assign to</div>
                <div class="d-flex align-items-center">
                  <Info class="InfoComponentInsideAnroidIOSApp textAlignRight noImg mr-8px" :is_image_show="false" v-if="isExtensionAssign" :id="data.assign_to.extn" :name="data.assign_to.label" :sub_info="data.assign_to.extn" />
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
                </div>
              </div>
              <div class="basicWhiteIOScard-item" @click="$modal.show('CallerIdShared', { number: data.number, })">
                <div class="headingInsideTable">Caller ID Shared With</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard onRightSide textAlignRight mr-8px">{{ data.extensions.length }} Users</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
                </div>
              </div>
            </div>
            <button 
              v-if="isExtensionAssign" 
              type="button" 
              class="IosFullWidthButton forDone mb-16px" 
              :disabled="api.update_extension.send || api.update_label.send" 
              @click="updateExtension()"
            >
              <vb-spinner v-if="api.update_extension.send" />
              <template v-else>Unassign</template>
            </button>
            <button v-else type="button" class="IosFullWidthButton forDone mb-16px" :disabled="api.delete_number.send || api.update_label.send" @click="deletenumber()">
              <vb-spinner v-if="api.delete_number.send" />
              <template v-else>Delete</template>
            </button>
          </div>

        </div>
      </template>
    </modal>
    <SelectExtensionModal @selected="updateExtension($event)" />
    <AddRemoveUsersModal :modalName="'CallerIdShared'" :type="'caller_id_shared'" @update-list="onCallerIDUpdated($event)" />
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import Info from '../../../Lists/Info.vue';
import SelectExtensionModal from "../extensions/SelectExtension.vue";
import AddRemoveUsersModal from "../users/AddRemoveUsers.vue";
import { VOIP_API } from "@/utils";
let screens = {
  edit: 'edit',
  view: 'view',
}
export default {
  name: "EditNumbersModal",
  components: { 
    Info,
    SelectExtensionModal,
    AddRemoveUsersModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'EditNumber'
    },
  },
  data() {
    return {
      data: this.$helperFunction.stateInstance({
        data: {
          assign_to: {
            label: '',
            extn: '',
          },
          extensions: [],
          number: {},
        }
      }),
      api: {
        delete_number: this.$helperFunction.apiInstance(),
        update_label: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
        update_extension: this.$helperFunction.apiInstance(),
      },
      forms: {
        update_label: this.$helperFunction.formInstance({
          data: {
            name: '',
          }
        }),
      },
      conditions: {
        screen: screens.view,
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    isLabelChanged(){ return this.forms.update_label.name!=(this.data.number.label ?? '') },
    isExtensionAssign(){ return this.data.assign_to.extn && this.data.assign_to.label },
    screens(){ return screens },
  },
  validations: {
    forms: {
      update_label: {
        name: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.number = event?.params?.number || {};
      this.data.assign_to.extn = this.data.number?.assignedToUsersArr?.extn || ''
      this.data.assign_to.label = this.data.number?.assignedToUsersArr?.label || ''
      this.data.extensions = this.data.number?.extensions || []
      this.forms.update_label.name = this.data.number?.label || ''
    },
    onBeforeClose(){
      this.api.delete_number.reset()
      this.api.update_label.reset()
      this.forms.update_label.reset()
      this.data.reset()
      this.conditions.screen=screens.view
    },
    updateExtension($event) {
      let vm = this
      if(vm.api.update_extension.send) return;
      vm.api.update_extension.send=true
      VOIP_API.endpoints.telephone_number.updateExtn(vm.data.number.real_id, {
        extn: $event?.account ?? '',
        field: "extn",
      })
      .then(() => {
        vm.$emit("latest");
        vm.data.assign_to.extn = $event?.extn || ''
        vm.data.assign_to.label = $event?.accountname || ''
        vm.appNotify({
          message: $event ? "Successfully Added!" : "Successfully UnAssign!",
          type: "success",
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update_extension.send=false
      });
    },
    onCallerIDUpdated(Event){
      this.data.extensions=Object.keys(Event)
      this.$emit('latest')
    },
    deletenumber(){
      let vm = this
      if(vm.api.delete_number.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this Number`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if(confirm){
            vm.api.delete_number.send=true
            VOIP_API.endpoints.telephone_number.delete(vm.data.number.real_id,{
              action: 'delete',
            })
            .then(() => {
              vm.$emit("latest");
              vm.$modal.hide(vm.modalName);
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(() => {
              vm.api.delete_number.send=false
            });
          }
        }
      })
    },
    updateLabel(){
      let vm = this
      vm.forms.update_label.submitted=true
      vm.$v.forms.update_label.$touch()
      if (vm.$v.forms.update_label.$invalid || vm.api.update_label.send) return;
      vm.api.update_label.send=true
      vm.api.update_label.error_message=''
      vm.api.update_label.validation_errors={}
      VOIP_API.endpoints.telephone_number.updateLabel({
        field: "name",
        name: vm.forms.update_label.name,
      },vm.data.number.real_id)
      .then(()=>{
        vm.$emit("latest");
        vm.$modal.hide(vm.modalName);
        vm.appNotify({
          message: 'Successfully updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.update_label.error_message=ex.own_message
        vm.api.update_label.validation_errors=ex.own_errors
      })
      .finally(()=>{
        vm.api.update_label.send=false
        vm.forms.update_label.submitted=false
      });
    },
  },
};
</script>
