<template>
  <div>
    <div class="mainHeading withButton">
      Global permissions
    </div>
    <div class="mainDescription mb-4">
      These settings applies to everyone, including "Admin" level users
    </div>
    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Enabled for everyone:</div>
      <b-form-checkbox class="HrmCheckBox-container mt-16px">
        Assets
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Onboarding
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Offboarding
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Tasks
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Training
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Documnets
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Emergency contacts
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Notes
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Compensation
      </b-form-checkbox>
    </div>
    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Show tabs in employee profile</div>
      <b-form-checkbox class="HrmCheckBox-container mt-16px">
        Personal
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Documents
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Emergency
      </b-form-checkbox>
    </div>
    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Show the following fields in employee profiles</div>
      <b-form-checkbox class="HrmCheckBox-container mt-16px">
        Title
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Nationality
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Employment status
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Work phone
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Driving licence
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Skype
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Martial status
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Home Phone
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Gender
      </b-form-checkbox>
    </div>
    <button class="dialer-button dialer-button-primary mt-32px mr-0"> Save </button>
  </div>
</template>

<script>
export default {
  name: 'PermissionsGlobal',
}
</script>

<style>

</style>