<template>
  <div>
    <div class="mainHeading withButton">
      Awaiting approval
      <button @click="$modal.show('AwaitApprovalModal')" class="dialer-button dialer-button-primary mr-0">
        Genrate
      </button>
    </div>
    <div class="mainDescription mb-4">
      Time off requests in certain stages which are waiting to be approved.
    </div>
    <div class="hrmTabsContainer pt-3">
      <div v-for="tab in tabs" :key="tab.id" :class="`hrmTabsButton ${selectedTab.id == tab.id ? 'active' : ''} mr-16px`" @click="selected_tab = tab">{{ tab.text }}</div>
      <!-- <div :class="`hrmTabsButton ${showTabs == 'approval' ? 'active' : ''} mr-16px`" @click="showTabs = 'approval'">Awaiting approval</div>
      <div :class="`hrmTabsButton ${showTabs == 'resubmission' ? 'active' : ''} mr-16px`" @click="showTabs = 'resubmission'">Awaiting resubmission approval</div>
      <div :class="`hrmTabsButton ${showTabs == 'replacement' ? 'active' : ''} mr-16px`" @click="showTabs = 'replacement'">Awaiting replacement approval</div> -->
    </div>
    <div class="mt-32px">
      <template v-if="selectedTab.value == 'approval'">
        <div class="mainDescription mb-0">
          Time off requests in certain stages which are waiting to be approved.
        </div>
        <div class="AwaitingApproval mt-32px">
          <div class="HrmTableHead-container">
            <div class="HrmTableHead one">Name</div>
            <div class="HrmTableHead two">policy</div>
            <div class="HrmTableHead three">Start date</div>
            <div class="HrmTableHead four">end date</div>
            <div class="HrmTableHead five">Actions</div>
          </div>
          <vb-table class="HrmTable" :isListEmpty="isEmpty(filterAwaitingApprovals)" 
            :listLength="filterAwaitingApprovals.length" 
            :loading="api.await_approval.send" 
          >
            <!-- <template slot="header">
              <tr><th class="dialer-has-sort"></th></tr>
            </template> -->
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterAwaitingApprovals" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title one">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-row-title two">
                  <div class="circleWithText">
                    <div class="circleContainer" style="background-color:#D5761B"></div>
                    <div class="circleWithText-text" style="color:#D5761B">{{ data.policy }}</div>
                  </div>
                </td>
                <td class="dialer-row-title three">05/09/2022 All day{{ data.startDate }}</td>
                <td class="dialer-row-title four">05/09/2022{{ data.endDate }}</td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      All teams{{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
          <!-- <div class="dialer-input-field input-consistent-inner-shadows sm-mar-left seachBox">
            <b-input type="text" placeholder="Search user" v-model.lazy="filter.await_approval.search"/>
          </div> -->
          <!-- <vb-table
            :isListEmpty="isEmpty(filterAwaitingApprovals)" 
            :listLength="filterAwaitingApprovals.length" 
            :loading="api.await_approval.send" 
          >
            <template slot="header">
              <tr>
                <th class="dialer-has-sort">
                  <span>Name</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Policy</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Start date</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>end date</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Actions</span>
                </th>
              </tr>
            </template>
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterAwaitingApprovals" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title"><div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-col-right">
                  <div class="circleWithText">
                    <div class="circleContainer" style="background-color:#D5761B"></div>
                    <div class="circleWithText-text" style="color:#D5761B">{{ data.policy }}</div>
                  </div>
                </td>
                <td class="dialer-col-right">{{ data.startDate }} </td>
                <td class="dialer-col-right">{{ data.endDate }} </td>
                <td class="dialer-row-title eight">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      {{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table> -->
        </div>
      </template>
      <template v-if="selectedTab.value == 'resubmission'">
        <div class="mainDescription mb-0">
          Time off requests in certain stages which are waiting to be resubmission.
        </div>
        <div class="AwaitingApproval mt-32px">
          <!-- <div class="dialer-input-field input-consistent-inner-shadows sm-mar-left seachBox">
            <b-input type="text" placeholder="Search user" v-model.lazy="filter.resubmission_approval.search"/>
          </div> -->
          <!-- <vb-table
            :isListEmpty="isEmpty(filterResubmissionApprovals)" 
            :listLength="filterResubmissionApprovals.length" 
            :loading="api.resubmission_approval.send" 
          >
            <template slot="header">
              <tr>
                <th class="dialer-has-sort">
                  <span>Name</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Policy</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Start date</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>end date</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Actions</span>
                </th>
              </tr>
            </template>
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterResubmissionApprovals" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title"><div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-col-right">
                  <div class="circleWithText">
                    <div class="circleContainer" style="background-color:#D5761B"></div>
                    <div class="circleWithText-text" style="color:#D5761B">{{ data.policy }}</div>
                  </div>
                </td>
                <td class="dialer-col-right">{{ data.startDate }} </td>
                <td class="dialer-col-right">{{ data.endDate }} </td>
                <td class="dialer-row-title eight">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      {{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table> -->
          <div class="HrmTableHead-container">
            <div class="HrmTableHead one">Name</div>
            <div class="HrmTableHead two">policy</div>
            <div class="HrmTableHead three">Start date</div>
            <div class="HrmTableHead four">end date</div>
            <div class="HrmTableHead five">Actions</div>
          </div>
          <vb-table class="HrmTable" :isListEmpty="isEmpty(filterResubmissionApprovals)" 
            :listLength="filterResubmissionApprovals.length" 
            :loading="api.resubmission_approval.send" 
          >
            <!-- <template slot="header">
              <tr><th class="dialer-has-sort"></th></tr>
            </template> -->
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterResubmissionApprovals" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title one">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-row-title two">
                  <div class="circleWithText">
                    <div class="circleContainer" style="background-color:#D5761B"></div>
                    <div class="circleWithText-text" style="color:#D5761B">{{ data.policy }}</div>
                  </div>
                </td>
                <td class="dialer-row-title three">{{ data.startDate }}</td>
                <td class="dialer-row-title four">{{ data.endDate }}</td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      {{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
      </template>
      <template v-if="selectedTab.value == 'replacement'">
        <div class="mainDescription mb-0">
          Time off requests in certain stages which are waiting to be replacement.
        </div>
        <div class="AwaitingApproval mt-32px">
          <!-- <div class="dialer-input-field input-consistent-inner-shadows sm-mar-left seachBox">
            <b-input type="text" placeholder="Search user" v-model.lazy="filter.replacement_approval.search"/>
          </div> -->
          <!-- <vb-table
            :isListEmpty="isEmpty(filterReplacementApprovals)" 
            :listLength="filterReplacementApprovals.length" 
            :loading="api.replacement_approval.send" 
          >
            <template slot="header">
              <tr>
                <th class="dialer-has-sort">
                  <span>Name</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Policy</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Start date</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>end date</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Actions</span>
                </th>
              </tr>
            </template>
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterReplacementApprovals" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title"><div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-col-right">
                  <div class="circleWithText">
                    <div class="circleContainer" style="background-color:#D5761B"></div>
                    <div class="circleWithText-text" style="color:#D5761B">{{ data.policy }}</div>
                  </div>
                </td>
                <td class="dialer-col-right">{{ data.startDate }} </td>
                <td class="dialer-col-right">{{ data.endDate }} </td>
                <td class="dialer-row-title eight">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      {{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table> -->
          <div class="HrmTableHead-container">
            <div class="HrmTableHead one">Name</div>
            <div class="HrmTableHead two">policy</div>
            <div class="HrmTableHead three">Start date</div>
            <div class="HrmTableHead four">end date</div>
            <div class="HrmTableHead five">Actions</div>
          </div>
          <vb-table class="HrmTable">
            <!-- <template slot="header">
              <tr><th class="dialer-has-sort"></th></tr>
            </template> -->
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterReplacementApprovals" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title one">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-row-title two">
                  <div class="circleWithText">
                    <div class="circleContainer" style="background-color:#D5761B"></div>
                    <div class="circleWithText-text" style="color:#D5761B">{{ data.policy }}</div>
                  </div>
                </td>
                <td class="dialer-row-title three">{{ data.startDate }}</td>
                <td class="dialer-row-title four">{{ data.endDate }}</td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      {{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
      </template>
    </div>
    <AwaitApprovalModal />
  </div>
</template>

<script>
import AwaitApprovalModal from '@/components/Modals/HRM/reports/AwaitApprovalModal.vue';
import { $fn } from "@/utils";
import { HRM } from '@/data'
let tabs = [
  {
    id: 1,
    text: 'Awaiting approval',
    value: 'approval'
  },
  {
    id: 2,
    text: 'Awaiting resubmission approval',
    value: 'resubmission'
  },
  {
    id: 3,
    text: 'Awaiting replacement approval',
    value: 'replacement'
  }
]
export default {
  name: 'TimeOffAwaitingApproval',
  components: {
    AwaitApprovalModal,
  },
  data(){
    return {
      showTabs: 'approval',
      selected_tab: tabs[0],
      response: {
        await_approval: [],
        resubmission_approval: [],
        replacement_approval: []
      },
      filter: {
        await_approval: {
          search: '',
          direction: '',
        },
        resubmission_approval: {
          search: '',
          direction: '',
        },
        replacement_approval: {
          search: '',
          direction: '',
        }
      },
      api: {
        await_approval: this.$helperFunction.apiInstance({ error_message: true }),
        resubmission_approval: this.$helperFunction.apiInstance({ error_message: true }),
        replacement_approval: this.$helperFunction.apiInstance({ error_message: true }),
      },
    }
  },
  inject:['isEmpty'],
  computed:{
    tabs() { return tabs },
    selectedTab(){ return tabs.find(tab=>tab.id==this.selected_tab.id) },
    filterAwaitingApprovals(){
      const search = this.filter.await_approval.search.trim().toLowerCase()
      // const direction = this.filter.rules.direction
      const rules = this.response.await_approval
      const filter_await_approval = rules.filter(item=>
        `${(item.name || '').replace(/-/g,'')} ${item.policy}`.trim().toLowerCase().includes(search)
      )
      return filter_await_approval
    },
    filterReplacementApprovals(){
      const search = this.filter.replacement_approval.search.trim().toLowerCase()
      // const direction = this.filter.rules.direction
      const rules = this.response.replacement_approval
      const filter_replacement_approval = rules.filter(item=>
        `${(item.name || '').replace(/-/g,'')} ${item.policy}`.trim().toLowerCase().includes(search)
      )
      return filter_replacement_approval
    },
    filterResubmissionApprovals(){
      const search = this.filter.resubmission_approval.search.trim().toLowerCase()
      // const direction = this.filter.rules.direction
      const rules = this.response.resubmission_approval
      const filter_resubmission_approval = rules.filter(item=>
        `${(item.name || '').replace(/-/g,'')} ${item.policy}`.trim().toLowerCase().includes(search)
      )
      return filter_resubmission_approval
    }
  },
  methods: {
    fetchAwaitingApprovals(){
      // console.log("awaiting is called")
      let vm = this;
      if(vm.api.await_approval.send) return;
      vm.api.await_approval.send=true
      $fn.sleep(2 * 1000)
      .then(()=>{
        // console.log("HRM Data", HRM.awaitapproval)
        vm.response.await_approval = HRM.awaitapproval
      })
      .catch((/* err */)=>{
        // console.log("error", err)
      }).finally(()=> { vm.api.await_approval.send = false })
    },
    fetchResubmissionApprovals(){
      // console.log("resubmission is called")
      let vm = this;
      if(vm.api.resubmission_approval.send) return;
      vm.api.resubmission_approval.send=true
      $fn.sleep(2 * 1000)
      .then(()=>{
        // console.log("HRM Data", HRM.resubmissionapproval)
        vm.response.resubmission_approval = HRM.resubmissionapproval
      })
      .catch((/* err */)=>{
        // console.log("error", err)
      }).finally(()=> { vm.api.resubmission_approval.send = false })
    },
    fetchReplacementApprovals(){
      // console.log("replacement is called")
      let vm = this;
      if(vm.api.replacement_approval.send) return;
      vm.api.replacement_approval.send=true
      $fn.sleep(2 * 1000)
      .then(()=>{
        // console.log("HRM Data", HRM.replacementapproval)
        vm.response.replacement_approval = HRM.replacementapproval
      })
      .catch((/* err */)=>{
        // console.log("error", err)
      }).finally(()=> { vm.api.replacement_approval.send = false })
    }
  },
  mounted(){
    this.fetchAwaitingApprovals();
    this.fetchResubmissionApprovals();
    this.fetchReplacementApprovals();
  }
}
</script>

<style>

</style>