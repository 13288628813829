<template>
  <div>
    <audio id="chat-notification" :src="require('@/assets/sounds/notification.mp3')" />
    <audio id="send-message-ringtone" v-show="false" :src="media_devices_setting.selectedSendmessageRingtone | get_property('file')" />
    <audio id="recieve-message-ringtone" v-show="false" :src="media_devices_setting.selectedRecievemessageRingtone | get_property('file')" />
  </div>
</template>

<script>
import {
  events as app_event,
  LOGGER,
  $fn,
  // VOIP_API,
  events,
} from "../utils";
import { MATTERMOST_API, mattermost } from "../MatterMost";
import _ from "lodash";
import { local_files } from "../mixin";
import { TokenService } from '../services/storage.service';
import { electron_events } from '../electron/events';
import { mapState } from 'vuex';
export default {
  name: "CommonChat",
  mixins: [
    local_files
  ],
  inject: [
    'appNotify',
  ],
  data() {
    return {
      channels: [],
    };
  },
  computed: {
    ...mapState({
      media_devices_setting: state=>state.media_devices_setting,
    }),
    channelsView(){ return this.$store.state.mattermost.channels_view },
    directChannels() { return this.$store.state.mattermost.list_channels.filter((channel) => channel.type === "D") /*this.channels.filter((channel) => channel.type === "D");*/ },
    privateChannels() { return this.$store.state.mattermost.list_channels.filter((channel) => channel.type === "P");  /*this.channels.filter((channel) => channel.type === "P");*/ },
    publicChannels() { return this.$store.state.mattermost.list_channels.filter((channel) => channel.type === "O"); /*this.channels.filter((channel) => channel.type === "O");*/ },
    groupChannels() { return this.$store.state.mattermost.list_channels.filter((channel) => channel.type === "G"); /*this.channels.filter((channel) => channel.type === "G");*/ },
    channelsStore() { return this.$store.state.mattermost.channels; },
    channelsTab() { return this.privateChannels.filter((channel)=>!this.$store.getters.getVoipTeams.some((team)=>team.channel_id==channel.id)); }, 
    multiWatcingValues(){
      return JSON.stringify({
        teams: this.$store.getters.getVoipTeams,
        users: this.$store.state.common.voipusers,
        directChannels: this.directChannels,
        channelsTab: this.channelsTab,
      })
    },
    teams(){ return this.$store.getters.getVoipTeams },
  },
  watch: {
    "$store.state.mattermost.preferences": "watchingPreferences",
    multiWatcingValues: "watchingMultiWatchingValues",
    "$store.getters.getTotalUnread":{
      immediate: true,
      handler(total_unread){
        LOGGER.watch('total_unread',total_unread)
        if(process.env.IS_ELECTRON){
          window.electron.send(electron_events.SET_BADGES,{
            count: total_unread
          })
        }
      }
    },
    "media_devices_setting.send_message_ringtone": "mediaDeviceSettingSendMessageRingtone",
    "media_devices_setting.recieve_message_ringtone": "mediaDeviceSettingRecieveMessageRingtone",
    "media_devices_setting.ringing_speaker_id": "mediaDeviceSettingRingingSpeakerId",
    "media_devices_setting.ringing_speaker_volume": "mediaDeviceSettingRingingSpeakerVolume",
  },
  methods: {
    mediaDeviceSettingRingingSpeakerId(ringing_speaker_id){
      const audio = document.getElementById("send-message-ringtone"); 
      if(audio) audio.setSinkId(ringing_speaker_id);
      const audio1 = document.getElementById("recieve-message-ringtone"); 
      if(audio1) audio1.setSinkId(ringing_speaker_id);
    },
    mediaDeviceSettingRingingSpeakerVolume(ringing_speaker_volume){
      const audio = document.getElementById("send-message-ringtone"); 
      if(audio) audio.volume = ringing_speaker_volume / 100;
      const audio1 = document.getElementById("recieve-message-ringtone"); 
      if(audio1) audio1.volume = ringing_speaker_volume / 100;
    },
    mediaDeviceSettingSendMessageRingtone(){
      const audio = document.getElementById("send-message-ringtone");  
      if(audio){
        audio.load();
      }
    },
    mediaDeviceSettingRecieveMessageRingtone(){
      const audio = document.getElementById("recieve-message-ringtone");  
      if(audio){
        audio.load();
      }
    },
    
    mediaDevicesSettingSink(){
      if(this.media_devices_setting?.ringing_speaker_id !== undefined){ this.mediaDeviceSettingRingingSpeakerId(this.media_devices_setting.ringing_speaker_id) }
      if(this.media_devices_setting?.ringing_speaker_volume !== undefined){ this.mediaDeviceSettingRingingSpeakerVolume(this.media_devices_setting.ringing_speaker_volume) }
    },
    //--watching--
    watchingMultiWatchingValues(n_new_val,n_old_val){
      const new_val = JSON.parse(n_new_val)
      const old_val = JSON.parse(n_old_val)
      this.watchingTeams(new_val.teams,old_val.teams)
      this.watchingDirectChannels(new_val.directChannels,old_val.directChannels)
      this.watchingchannelTab(new_val.channelsTab,old_val.channelsTab)
    },
    watchingchannelTab(channels) {
      for (let index = 0; index < channels.length; index++) {
        const channel = channels[index];
        // LOGGER.log('channel',channel.id)
        if (!this.$store.state.mattermost.channels[channel.id]) {
          this.addingChannel({
            channel_id: channel.id,
            data: {
              channel: channel,
              // data: channel,
              type: "channel",
              is_admin: channel.creator_id === this.$store.getters.getMattermostCurrentUser?.id,//this.mattermost_current_user.id,
            },
          });
        }
      }
    },
    watchingDirectChannels(channels) {
      for (let index = 0; index < channels.length; index++) {
        const channel = channels[index];
        const ids = channel.name.split("_");
        let user = {};
        if (ids.length === 3) {
          // if (ids[0] === this.mattermost_current_user.id && ids[2] !== this.mattermost_current_user.id) {
          if (ids[0] === this.$store.getters.getMattermostCurrentUser?.id && ids[2] !== this.$store.getters.getMattermostCurrentUser?.id) {
            user = this.$store.getters.getVoipUsersAlises[ids[2]];//this.getUserInfo(ids[2]);
          } //else if (ids[2] === this.mattermost_current_user.id && ids[0] !== this.mattermost_current_user.id) {
          else if (ids[2] === this.$store.getters.getMattermostCurrentUser?.id && ids[0] !== this.$store.getters.getMattermostCurrentUser?.id) {  
            user = this.$store.getters.getVoipUsersAlises[ids[0]];//this.getUserInfo(ids[0]);
          }
          if (!_.isEmpty(user)) {
            this.$set(this.$store.state.common.voipusers[user.voipaccount],"channel_id",channel.id);
            // LOGGER.log('direct_channel',channel.id)
            if (!this.$store.state.mattermost.channels[channel.id]) {
              this.addingChannel({
                channel_id: channel.id,
                data: {
                  channel: channel,
                  type: "user",
                  id: user?.voipaccount
                },
              });
            }
          }
        }
      }
    },
    watchingTeams(teams) {
      for (let index = 0; index < teams.length; index++) {
        const team = teams[index];
        const channel = this.getChannel(team.channel_id);
        // LOGGER.watch('::::team',team.channel_id,this.$store.state.mattermost.channels?.[team.channel_id]?.type==='team',this.$store.state.mattermost.channels?.[team.channel_id])
        if (!!team.channel_id && this.$store.state.mattermost.channels?.[team.channel_id]?.type!=='team'){
          this.addingChannel({
            channel_id: team.channel_id,
            data: {
              channel: channel,
              // data: team,
              id: team?.real_id,
              type: "team",
            },
          });
        }
      }
    },
    watchingPreferences(preferences) {
      for (let index = 0; index < preferences.length; index++) {
        const preference = preferences[index];
        if (this.$store.state.mattermost.channels[preference.name] && preference.category === "favorite_channel") {
          this.$store.state.mattermost.channels[preference.name].is_fav = true;
        }
        if (this.$store.state.mattermost.channels[preference.name] && preference.category === "recent_channel") {
          this.$store.state.mattermost.channels[preference.name].is_recent = true;
        }
      }
    },
    //--method--
    async load() {
      let vm = this;
      try {
        // let key;
        // for (key in vm.$store.state.mattermost.channels) {
        //   vm.$store.state.mattermost.channels[key].is_view=false
        // }
        const { data: mattermost_user, headers: { token } } = await MATTERMOST_API.endpoints.users.login({
          login_id: this.$store.getters.getCurrentUser?.jid_account,//this.current_user.jid_account,
          password: this.$store.getters.getCurrentUser?.jid_pass,//this.current_user.jid_pass,
        })
        this.$store.state.mattermost.status="online"
        TokenService.MATTERMOST_USER.set(mattermost_user);
        TokenService.MATTERMOST_TOKEN.set(token);
        this.$store.commit("setToken", token);
        this.$store.commit("setMatterMostUser", mattermost_user);
        // setToken()
        // setWebSocket();
        mattermost.connect();
        mattermost.callBacks={
          onChannelCreated: vm.onChannelCreated,
          onChannelDeleted: vm.onChannelDeleted,
          onChannelMemberUpdated: vm.onChannelMemberUpdated,
          onChannelUpdated: vm.onChannelUpdated,
          onChannelViewed: vm.onChannelViewed,
          onDirectAdded: vm.onDirectAdded,
          onPostDeleted: vm.onPostedDeleted,
          onPostEdited: vm.onPostedEdited,
          onPosted: vm.onPosted,
          onPreferencesChanged: vm.onPreferencesChanged,
          onPreferencesDeleted: vm.onPreferencesDeleted,
          onReactionAdded: vm.onReactionAdded,
          onReactionRemoved: vm.onReactionRemoved,
          onTyping: vm.onTyping,
          onUserAdded: vm.onUserAdded,
          onUserRemoved: vm.onUserRemoved,
          onStatus: vm.onStatusChanged,
          onOpen: vm.onOpen,
        } 
        MATTERMOST_API.endpoints.preferences.getUsersPreferences(mattermost_user.id).then(({ data: preferences })=>{
          vm.$store.commit('setPreferences',preferences)
        })
        this.getAllChannels()
      } catch (error) {
        LOGGER.danger("error in mattermostSetStore", error);
      }
    },
    getChannel(channel_id) {
      const channel = this.$store.state.mattermost.list_channels.find(channel=>channel.id === channel_id); //this.channels.find(channel=>channel.id === channel_id);
      return channel || {};
    },
    getAllChannels(){
      let vm = this
      const mattermost_user = vm.$store.state.mattermost.user
      // MATTERMOST_API.endpoints.channels.getForUser(mattermost_user.id,this.current_user.mm_team_id).then(({ data: channels })=>{
      MATTERMOST_API.endpoints.channels.getForUser(mattermost_user.id,this.$store.getters.getCurrentUser?.mm_team_id).then(({ data: channels })=>{
        //vm.channels = channels;
        this.$store.state.mattermost.list_channels = channels
      });
    },
    setRecent(channel_id) {
      const obj = {};
      const key = channel_id
      Object.defineProperty(obj, key, {
        value: Number(new Date()),
        configurable: true,
        enumerable: true,
        writable: true,
      });
      const current_props =
        this.$store.state.mattermost.user &&
        this.$store.state.mattermost.user.props
          ? this.$store.state.mattermost.user.props
          : {};
      const current_props_recent_channel =
        this.$store.state.mattermost.user &&
        this.$store.state.mattermost.user.props &&
        this.$store.state.mattermost.user.props.recent_channels
          ? JSON.parse(this.$store.state.mattermost.user.props.recent_channels)
          : {};
      const props = {
        ...current_props,
        recent_channels: JSON.stringify({
          ...current_props_recent_channel,
          ...obj,
        }),
      };
      MATTERMOST_API.endpoints.users.patchUser(this.$store.state.mattermost.user.id, {
        props,
      });
      this.$store.state.mattermost.user = {
        ...this.$store.state.mattermost.user,
        props,
      };
    },
    //--event--
    //app
    channelView(message){
      const { channel_id } = message;
      // console.log(channel_id)
      if(channel_id) {
        MATTERMOST_API.endpoints.channels.viewChannel(this.$store.getters.getMattermostCurrentUser?.id,channel_id).then(()=>{
          mattermost.emitOnUserLevel(mattermost.events.channel_viewed,{
            channel_id: channel_id,
          })
          setTimeout(()=>{
            MATTERMOST_API.endpoints.channels.getChannelMembers(channel_id)
            .then(({ data: members }) => {
              this.$store.state.mattermost.channels[channel_id].members = members;
            });
          },2*1000)
        });
      }
    },
    addingChannel(message) {
      const { channel_id, data } = message;
      const channel_data = this.$store.state.mattermost.channels[channel_id] || {};
      this.$set(this.$store.state.mattermost.channels, channel_id, {
        id: '',
        channel: {},
        data: {},
        posts: {
          posts: {},
          order: [],
          previuos_post_id: "",
          current_page: 0,
        },
        pinned_posts: {
          loading: false,
          posts: {},
          order: [],
          previuos_post_id: "",
          current_page: 0,
        },
        type: "",
        members: [],
        is_admin: false,
        is_fav: false,
        is_recent: false,
        is_view: false,
        is_openned_main: false,
        unread: 0,
        typing_users: {},
        notification_prference: {
          is_mute: false,
          sound: true,
          reaction: true,
          schedule_call: false,
        },
        actions: {
          message: "",
          files: {},
          is_important: false,
          uploaded_files: {},
          reply_post: {},
          edit_post: {},
        },
        ...channel_data,
        ...data,
      });
      this.onGetPosts({
        channel_id
      })
      this.onCheckUnreadCount({
        channel_id
      })
    },
    onUploadFileOnChannel(message) {
      const { channel_id, file, type } = message;
      if (Object.values(this.$store.state.mattermost.channels[channel_id].actions.files).length >= 5) return;
      const CancelToken = require("axios").CancelToken;
      const source = CancelToken.source();
      let app = this;
      let uid = $fn.makeid(50);
      const onUploadProgress = (event) => {
        app.$set(
          app.$store.state.mattermost.channels[channel_id].actions.files[uid],
          "progress",
          {
            total: $fn.fileSize(event.total),
            loaded: $fn.fileSize(event.loaded),
            percentage: Math.floor((event.loaded / event.total) * 100),
            is_process: Math.floor((event.loaded / event.total) * 100) < 100,
          }
        );
      };
      var formData = new FormData();
      if (type === "recorded audio") {
        this.$set(
          app.$store.state.mattermost.channels[channel_id].actions.files,
          uid,
          {
            ...file,
            original_file: file.audioBlob,
            name: "recording.mp3",
            file_size: "",
            ext_size: "MP3" + " " + "",
            file_type: "audio",
            ext: "mp3",
            input_type: type,
            file_image: this.local_files.images.attachments.audio_file,
            uid,
            src: '',
            source,
          }
        );
        formData.append("files", file.audioBlob, "recording.mp3");
      } else if (type === "recorded video") {
        this.$set(
          app.$store.state.mattermost.channels[channel_id].actions.files,
          uid,
          {
            ...file,
            original_file: file,
            name: "recorded_video",
            file_size: "",
            ext_size: "MP4" + " " + "",
            file_type: "video",
            ext: "mp4",
            file_image: this.local_files.images.attachments.video_file,
            input_type: type,
            uid,
            src: '',
            source,
          }
        );
        formData.append("files", file, "recorded_video.mp4");
      } else {
        let file_type = file.type.split("/")[0];
        let file_image = this.local_files.images.attachments.other_file;
        if (file_type === "audio") {
          file_image = this.local_files.images.attachments.audio_file;
        } else if (file_type === "video") {
          file_image = this.local_files.images.attachments.video_file;
        } else if (file_type === "image") {
          file_image = this.local_files.images.attachments.image_file;
        } else if (file_type === "pdf") {
          file_image = this.local_files.images.attachments.pdf_file;
        } else if (file_type === "text") {
          file_image = this.local_files.images.attachments.txt_file;
        } else if (file_type === "zip") {
          file_image = this.local_files.images.attachments.zip_file;
        }
        this.$set(app.$store.state.mattermost.channels[channel_id].actions.files,uid,{
            original_file: file,
            name: file.name,
            file_size: $fn.fileSize(file.size),
            ext_size: file.name.split(".")[1] + " " + $fn.fileSize(file.size),
            file_type: file_type,
            ext: file.name.split(".")[1],
            file_image: file_image,
            input_type: type,
            uid,
            src: '',
            source,
          }
        );
        formData.append("files", file);
      }
      formData.append("channel_id", channel_id);
      formData.append("client_ids", uid);
      // console.log('start')
      const file_type = app.$store.state.mattermost.channels[channel_id].actions.files[uid].file_type
      const file_extn = app.$store.state.mattermost.channels[channel_id].actions.files[uid].ext
      if(["image","video"].includes(file_type) && (file_type=='image' ? ["jpg", "png", "jpeg"].includes(file_extn) : true)){
        $fn.readFile(file)
        .then((src)=>{
          app.$store.state.mattermost.channels[channel_id].actions.files[uid].src=src
        })
      }
      MATTERMOST_API.endpoints.files.upload(formData, {}, onUploadProgress, 24 * 60 * 1000, source.token)
      .then(({ data: { file_infos } }) => {
        app.$set(app.$store.state.mattermost.channels[channel_id].actions.files[uid],"file_id",file_infos[0].id);
        app.$set(app.$store.state.mattermost.channels[channel_id].actions.uploaded_files,file_infos[0].id,file_infos[0]);
      })
      .catch((/* ex */) => {
        // console.log(ex)
        app.$delete(app.$store.state.mattermost.channels[channel_id].actions.files,uid);
      });
    },
    async markUnreadEvent(message) {
      const { channel_id } = message;
      // const { data: { msg_count: count }, } = await MATTERMOST_API.endpoints.channels.getUnreadCount(this.mattermost_current_user.id,channel_id);
      const { data: { msg_count: count }, } = await MATTERMOST_API.endpoints.channels.getUnreadCount(this.$store.getters.getMattermostCurrentUser?.id,channel_id);
      this.$store.commit('setChatUnread',{ channel_id, count })
    },
    onCheckUnreadCount(message) {
      const { channel_id } = message;
      // if (!channel_id || !this.mattermost_current_user.id) return;
      if (!channel_id || !this.$store.getters.getMattermostCurrentUser?.id) return;
      // MATTERMOST_API.endpoints.channels.getUnreadCount(this.mattermost_current_user.id, channel_id).then(({ data: { msg_count: count } }) => {
      MATTERMOST_API.endpoints.channels.getUnreadCount(this.$store.getters.getMattermostCurrentUser?.id, channel_id).then(({ data: { msg_count: count } }) => {
        this.$store.commit('setChatUnread',{ channel_id, count })
      });
    },
    async onGetPosts(message) {
      try {
        const { channel_id, el, scrollHeight, since } = message;
        if (!channel_id) return;
        if (!this.$store.state.mattermost.channels[channel_id]) return;
        // if (!this.$store.state.mattermost.channels[channel_id].posts.previuos_post_id && this.$store.state.mattermost.channels[channel_id].posts.order.length > 0) return;
        // const { data: new_posts } = await MATTERMOST_API.endpoints.posts.getPosts(channel_id, {
        //   // page: this.$store.state.mattermost.channels[channel_id].posts.previuos_post_id ? "" : 0,
        //   per_page: 10,
        //   before: this.$store.state.mattermost.channels[channel_id]?.posts?.order?.[0] ?? "",
        // })
        let new_posts;
        let order = [];
        if(since){
          const { data } = await MATTERMOST_API.endpoints.posts.getPosts(channel_id, {
            since,
          })
          new_posts = data
          order = await new_posts?.order?.filter(post_id=>new_posts?.posts?.[post_id]?.delete_at==0)?.sort((a, b) => {
            return new_posts?.posts?.[a]?.create_at - new_posts?.posts?.[b]?.create_at
          })
        } else {
          const { data } = await MATTERMOST_API.endpoints.posts.getPosts(channel_id, {
            // page: this.$store.state.mattermost.channels[channel_id].posts.previuos_post_id ? "" : 0,
            per_page: 10,
            before: this.$store.state.mattermost.channels[channel_id]?.posts?.order?.[0] ?? "",
          })
          new_posts = data
          const new_posts_order = new_posts.order.filter(post_id=>!this.$store.state.mattermost.channels[channel_id].posts.order.includes(post_id))
          order = [
            ...new_posts_order.reverse(),
            ...this.$store.state.mattermost.channels[channel_id].posts.order,
          ]
        }
        if(new_posts?.order?.length==0) return;
        //const new_posts_order = new_posts.order.filter(post_id=>!this.$store.state.mattermost.channels[channel_id].posts.order.includes(post_id))
        // this.$store.state.mattermost.channels[channel_id].posts = {
        //   posts: {
        //     ...this.$store.state.mattermost.channels[channel_id].posts.posts,
        //     ...new_posts.posts,
        //   },
        //   order: [
        //     ...new_posts_order.reverse(),
        //     ...this.$store.state.mattermost.channels[channel_id].posts.order,
        //   ],
        // };
        this.$store.state.mattermost.channels[channel_id].posts = {
          posts: {
            ...this.$store.state.mattermost.channels[channel_id].posts.posts,
            ...new_posts.posts,
          },
          order,
        };
        this.$store.state.mattermost.channels[channel_id].posts.current_page = this.$store.state.mattermost.channels[channel_id].posts.current_page + 1;
        this.$store.state.mattermost.channels[channel_id].posts.previuos_post_id = new_posts.prev_post_id;
        if(since){
          this.onGetPosts({
            channel_id
          })
        }
        if (el && scrollHeight) {
          setTimeout(() => {
            el.scrollTop = el.scrollHeight - scrollHeight;
          }, 1 * 1000);
        }
      } catch(ex) {
        LOGGER.danger({ex})
      }
    },
    onGetPinnedPosts(message) {
      const { channel_id, el, scrollHeight } = message;
      if (!channel_id) return;
      if (!this.$store.state.mattermost.channels[channel_id]) return;
      if (
        !this.$store.state.mattermost.channels[channel_id].pinned_posts
          .previuos_post_id &&
        this.$store.state.mattermost.channels[channel_id].pinned_posts.order
          .length > 0
      )
        return;
      this.$store.state.mattermost.channels[
        channel_id
      ].pinned_posts.loading = true;
      MATTERMOST_API.endpoints.channels.getChannelPinnedPosts(channel_id, {
          page: this.$store.state.mattermost.channels[channel_id].pinned_posts
            .previuos_post_id
            ? ""
            : 0,
          per_page: 10,
          before:
            this.$store.state.mattermost.channels[channel_id].pinned_posts
              .previuos_post_id || "",
        })
        .then(({ data: new_posts }) => {
          this.$store.state.mattermost.channels[channel_id].pinned_posts = {
            posts: {
              ...this.$store.state.mattermost.channels[channel_id].pinned_posts
                .posts,
              ...new_posts.posts,
            },
            order: [
              ...new_posts.order.reverse(),
              ...this.$store.state.mattermost.channels[channel_id].pinned_posts
                .order,
            ],
          };
          this.$store.state.mattermost.channels[
            channel_id
          ].pinned_posts.current_page =
            this.$store.state.mattermost.channels[channel_id].pinned_posts
              .current_page + 1;
          this.$store.state.mattermost.channels[
            channel_id
          ].pinned_posts.previuos_post_id = new_posts.prev_post_id;
          if (el && scrollHeight) {
            setTimeout(() => {
              el.scrollTop = el.scrollHeight - scrollHeight;
            }, 0 * 1000);
          }
        })
        .finally(() => {
          this.$store.state.mattermost.channels[
            channel_id
          ].pinned_posts.loading = false;
        });
    },
    //user
    onUserRemoved(message) {
      LOGGER.event(message);
      const { user_id, removed_channel_id, channel_id } = message;
      if (removed_channel_id) {
        this.$delete(this.$store.state.mattermost.channels, removed_channel_id);
        //this.channels = this.channels.filter((channel) => channel.id !== removed_channel_id);
        this.$store.state.mattermost.list_channels = this.$store.state.mattermost.list_channels.filter((channel) => channel.id !== removed_channel_id);
      } else {
        this.$store.state.mattermost.channels[channel_id].members = this.$store.state.mattermost.channels[channel_id].members.filter((member) => member.user_id !== user_id);
      }
    },
    onUserAdded(message) {
      const { channel_id, user_id } = message;
      // if (user_id === this.mattermost_current_user.id) {
      if (user_id === this.$store.getters.getMattermostCurrentUser?.id) {
        MATTERMOST_API.endpoints.channels.getChannel(channel_id)
          .then(({ data: channel }) => {
            //this.channels.push(channel);
            this.$store.state.mattermost.list_channels.push(channel)
          });
      } else {
        MATTERMOST_API.endpoints.channels.getChannelMembers(channel_id)
          .then(({ data: members }) => {
            this.$store.state.mattermost.channels[channel_id].members = members;
          });
      }
    },
    //post
    onPosted(message) {
      let vm = this
      const { post, channel_id, channel_type, channel_display_name } = message;
      // const post = JSON.parse(post_json);
     
      if (this.$store.state.mattermost.channels[channel_id].posts.order.indexOf(post.id) === -1) {
        if(post.user_id === this.$store.getters.getMattermostCurrentUser?.id && post.pending_post_id && this.$store.state.mattermost.channels[channel_id].posts.order.includes(post.pending_post_id)){
          this.$delete(this.$store.state.mattermost.channels[channel_id].posts.posts[post.pending_post_id])
          this.$store.state.mattermost.channels[channel_id].posts.order[this.$store.state.mattermost.channels[channel_id].posts.order.indexOf(post.pending_post_id)] = post.id
        } else {
          this.$store.state.mattermost.channels[channel_id].posts.order.push(post.id)
        }
        this.$set(this.$store.state.mattermost.channels[channel_id].posts.posts,post.id,post)
      }
      // if (post.user_id === this.mattermost_current_user.id) {
      if (post.user_id === this.$store.getters.getMattermostCurrentUser?.id) {
        const obj = {};
        const key = channel_id;
        Object.defineProperty(obj, key, {
          value: Number(new Date()),
          configurable: true,
          enumerable: true,
          writable: true,
        });
        const current_props = this.$store.state.mattermost.user && this.$store.state.mattermost.user.props ? this.$store.state.mattermost.user.props : {};
        const current_props_recent_channel = this.$store.state.mattermost.user && this.$store.state.mattermost.user.props && this.$store.state.mattermost.user.props.recent_channels ? JSON.parse(this.$store.state.mattermost.user.props.recent_channels) : {};
        const props = {
          ...current_props,
          recent_channels: JSON.stringify({
            ...current_props_recent_channel,
            ...obj,
          }),
        };
        MATTERMOST_API.endpoints.users.patchUser(this.$store.state.mattermost.user.id, {
          props,
        });
        this.$store.state.mattermost.user = {
          ...this.$store.state.mattermost.user,
          props,
        };
        this.$root.$emit(events.channel_view,{
          channel_id: channel_id,
        })
        return;
      } else {
        LOGGER.log({
          is_iframe: window.self !== window.top,
          is_user_send: post.user_id === this.$store.getters.getMattermostCurrentUser?.id,//this.mattermost_current_user.id,
          tab: this.$store.state.global_conditions.tab,
          channels_view: this.$store.state.mattermost.channels_view,
          channel_id: channel_id,
          "channel_id(include)": this.$store.state.mattermost.channels_view.includes(channel_id),
          is_mute: this.$store.state.mattermost.channels[channel_id].notification_prference.is_mute,
          desktop_notification: !this.$store.getters.getCurrentUser?.desktop_notification, //this.current_user.desktop_notification,
        })
        if (window.document.hasFocus() && this.$store.state.global_conditions.tab === 'chat' && this.$store.state.mattermost.channels_view.includes(channel_id)) {
          this.$root.$emit(events.channel_view,{
            channel_id: channel_id,
          })
          return;
        }
        this.$store.commit('setChatUnread',{ channel_id, count: vm.$store.state.mattermost.channels[channel_id].unread + 1 })  
        if (this.$store.state.mattermost.channels[channel_id].notification_prference.is_mute) return;
        // if (!this.current_user.desktop_notification) return;
        // console.log(channel_type,channel_display_name)
        let title = this.$store.getters.getVoipUsersAlisesDisplayName[post.user_id]
        let icon = this.$store.getters.getVoipUsersAlisesImages[post.user_id]
        let type = 'user'
        if(channel_type && channel_type!=="D"){
          const team = this.$store.getters.getVoipTeams.find(team=>team.channel_id==channel_id)
          title = `${title} in "${team?.name ?? channel_display_name}"`
          icon = team?.avatar?.image?.image ?? icon
          type = team ? 'team' : 'group'
        }
        this.appNotify({
          toaster: true,
          title: title,
          icon: icon,
          message: $fn.messageShow(post,type),
          payload: {
            channel_id,
          },
          sound: this.$store.state.mattermost.channels[channel_id].notification_prference.sound,
          notify_type: 'alert',
          sound_type: post.user_id === this.$store.getters.getMattermostCurrentUser?.id ? 'send-message' : 'recieve-message',
          position: 'top-right',
          onClick: () =>{
            window.focus()
            vm.$store.state.global_conditions.tab='chat'
            setTimeout(()=>{
              vm.$root.$emit(events.open_chat,{
                channel_id,
              })
            },1 *1000)
          }
        })
      }

    },
    onPostedDeleted(message) {
      const { post, channel_id } = message;
      const { id: post_id } = post;
      this.$store.state.mattermost.channels[channel_id].posts.order = this.$store.state.mattermost.channels[channel_id].posts.order.filter((id) => id !== post_id);
      this.$store.state.mattermost.channels[channel_id].pinned_posts.order = this.$store.state.mattermost.channels[channel_id].pinned_posts.order.filter((id) => id !== post_id);
    },
    onPostedEdited(message) {
      const { post, channel_id } = message;
      // const post = JSON.parse(post_json);
      if (!this.$store.state.mattermost.channels[channel_id]) return;
      if (!this.$store.state.mattermost.channels[channel_id].posts.posts[post.id]) return;
      if (this.$store.state.mattermost.channels[channel_id].posts.posts[post.id].is_pinned !== post.is_pinned) {
        if (post.is_pinned) {
          const post_obj = {};
          Object.defineProperty(post_obj, post.id, {
            value: post,
            writable: true,
            configurable: true,
            enumerable: true,
          });
          this.$store.state.mattermost.channels[channel_id].pinned_posts = {
            ...this.$store.state.mattermost.channels[channel_id].pinned_posts
              .posts,
            posts: {
              ...this.$store.state.mattermost.channels[channel_id].pinned_posts
                .posts,
              ...post_obj,
            },
            order: [
              ...this.$store.state.mattermost.channels[channel_id].pinned_posts
                .order,
              post.id,
            ],
          };
        } else {
          this.$store.state.mattermost.channels[channel_id].pinned_posts = {
            ...this.$store.state.mattermost.channels[channel_id].pinned_posts,
            posts: {
              ...this.$store.state.mattermost.channels[channel_id].pinned_posts
                .posts,
            },
            order: this.$store.state.mattermost.channels[
              channel_id
            ].pinned_posts.order.filter((order) => order !== post.id),
          };
        }
      }
      this.$store.state.mattermost.channels[channel_id].posts.posts[post.id]=post
    },
    //channel
    onDirectAdded(message) {
      // const { broadcast: { channel_id }, } = message;
      const { channel_id } = message;
      MATTERMOST_API.endpoints.channels.getChannel(channel_id)
      .then(({ data: channel }) => {
        //this.channels.push(channel);
        this.$store.state.mattermost.list_channels.push(channel)
      });
    },
    onChannelCreated(message) {
      const { channel_id } = message;
      MATTERMOST_API.endpoints.channels.getChannel(channel_id)
      .then(({ data: channel }) => {
        //this.channels.push(channel);
        this.$store.state.mattermost.list_channels.push(channel)
      });
    },
    onChannelViewed(message) {
      // const { data: { channel_id }, } = message;
      const { channel_id } = message;
      this.$store.commit('setChatUnread',{ channel_id, count: 0 })
    },
    onChannelUpdated(message) {
      //let vm = this;
      // const {
      //   data: { channel: channel_json },
      //   broadcast: { channel_id },
      // } = message;
      // const updated_channel = JSON.parse(channel_json);
      const { channel_id, channel } = message;
      this.$store.state.mattermost.channels[
        channel_id
      ].channel = channel;
      //this.channels.forEach((channel, index) => {
      this.$store.state.mattermost.list_channels.forEach((channel, index) => {
        if (channel.id === channel.id) {
          LOGGER.log(channel, index);
          //vm.channels[index] = channel;
          this.$store.state.mattermost.list_channels[index] = channel;
        }
      });
    },
    onChannelDeleted(message) {
      const { channel_id } = message;
      this.$delete(this.$store.state.mattermost.channels,channel_id);
      //this.channels = this.channels.filter((channel) => channel.id !== channel_id);
      this.$store.state.mattermost.list_channels = this.$store.state.mattermost.list_channels.filter((channel) => channel.id !== channel_id);
    },
    onChannelMemberUpdated(message) {
      const { channel_member } = message
      // const channel_member = JSON.parse(channel_member_json)
      if(!this.$store.state.mattermost.channels[channel_member.channel_id]) return;
      if(!this.$store.state.mattermost.channels[channel_member.channel_id].members.length===0) return;
      LOGGER.log('this.$store.state.mattermost.channels[channel_member.channel_id].members',this.$store.state.mattermost.channels[channel_member.channel_id].members)
      this.$store.state.mattermost.channels[channel_member.channel_id].members = this.$store.state.mattermost.channels[channel_member.channel_id].members.map(member=>{
        if(member.user_id===channel_member.user_id){
          return channel_member
        } else {
          return member
        }
      })
      LOGGER.log('this.$store.state.mattermost.channels[channel_member.channel_id].members',this.$store.state.mattermost.channels[channel_member.channel_id].members)
    },
    //preference
    onPreferencesDeleted(message) {
      const { user_id, preferences } = message;
      // if (user_id !== this.mattermost_current_user.id) return;
      if (user_id !== this.$store.getters.getMattermostCurrentUser?.id) return;
      // const preferences = JSON.parse(preferences_json);
      const deleted_preferences_name = preferences.map((preference) => preference.name);
      this.$store.state.mattermost.preferences = this.$store.state.mattermost.preferences.filter((preference) => deleted_preferences_name.indexOf(preference.name) === -1);
    },
    onPreferencesChanged(message) {
      const { user_id, preferences } = message;
      // if (user_id !== this.mattermost_current_user.id) return;
      if (user_id !== this.$store.getters.getMattermostCurrentUser?.id) return;
      // const preferences = JSON.parse(preferences_json);
      const currently_preferences = this.$store.state.mattermost.preferences;
      this.$store.state.mattermost.preferences = currently_preferences.concat(preferences);
    },
    //status
    onStatusChanged(message) {
      const { status } = message;
      this.$store.state.mattermost.status = status;
    },
    //--typing--
    onTyping(message) {
      let app = this;
      const { user_id, channel_id } = message;
      if (
        this.$store.state.mattermost.channels[channel_id].typing_users[user_id]
      )
        return;
      this.$set(
        this.$store.state.mattermost.channels[channel_id].typing_users,
        user_id,
        user_id
      );
      setTimeout(() => {
        app.$delete(
          app.$store.state.mattermost.channels[channel_id].typing_users,
          user_id
        );
      }, 3 * 1000);
    }, //reactions
    onReactionAdded(message) {
      let vm = this
      const { reaction, channel_id } = message;
      // const reaction = JSON.parse(reaction_json);
      if (!this.$store.state.mattermost.channels[channel_id]) return;
      if (!this.$store.state.mattermost.channels[channel_id].posts.posts[reaction.post_id]) return;
      if (this.$store.state.mattermost.channels[channel_id].posts.posts[reaction.post_id].metadata.reactions) {
        this.$store.state.mattermost.channels[channel_id].posts.posts[reaction.post_id].metadata.reactions.push(reaction);
      } else {
        this.$set(this.$store.state.mattermost.channels[channel_id].posts.posts[reaction.post_id].metadata,"reactions",[reaction]);
      }

      LOGGER.log(
        'is_user_send',reaction.user_id === this.$store.getters.getMattermostCurrentUser?.id,//this.mattermost_current_user.id,
        'is_mute',this.$store.state.mattermost.channels[channel_id].notification_prference.is_mute,
        'reaction',!this.$store.state.mattermost.channels[channel_id].notification_prference.reaction,
        'desktop_notification',!this.$store.getters.getCurrentUser?.desktop_notification,//this.current_user.desktop_notification,
      )
      // if (reaction.user_id === this.mattermost_current_user.id) return;
      if (reaction.user_id === this.$store.getters.getMattermostCurrentUser?.id) return;
      if (this.$store.state.mattermost.channels[channel_id].notification_prference.is_mute) return;
      if (!this.$store.state.mattermost.channels[channel_id].notification_prference.reaction) return;
      // if(!this.current_user.desktop_notification) return;
      this.appNotify({
        toaster: false,
        title: this.$store.getters.getVoipUsersAlisesDisplayName[reaction.user_id],//this.showUserName(reaction.user_id),
        icon: this.$store.getters.getVoipUsersAlisesImages[reaction.user_id],//this.showUserImage(reaction.user_id),
        message: `${this.$store.getters.getVoipUsersAlisesDisplayName[reaction.user_id]} reaction to your message`,//`${this.showUserName(reaction.user_id)} reaction to your message`,
        payload: {
          channel_id,
        },
        sound: this.$store.state.mattermost.channels[channel_id].notification_prference.sound,
        notify_type: 'alert',
        sound_type: 'send-message',
        position: 'top-right',
        onClick: () =>{
          window.focus()
          vm.$store.state.global_conditions.tab='chat'
          setTimeout(()=>{
            vm.$root.$emit(events.open_chat,{
              channel_id,
            })
          },1 *1000)
        }
      })
    },
    onReactionRemoved(message) {
      const { reaction, channel_id } = message;
      // const reaction = JSON.parse(reaction_json);
      if (!this.$store.state.mattermost.channels[channel_id]) return;
      if (!this.$store.state.mattermost.channels[channel_id].posts.posts[reaction.post_id]) return;
      this.$store.state.mattermost.channels[channel_id].posts.posts[reaction.post_id].metadata.reactions = (this.$store.state.mattermost.channels[channel_id].posts.posts[reaction.post_id].metadata?.reactions ?? []).filter((react) => !(react.user_id === reaction.user_id && react.emoji_name === reaction.emoji_name));
    },
    //--window--
    onBeforeLoad(){
      TokenService.CHAT.set(this.$store.state.mattermost.channels)
    },
    //electron
    onNotificationClicked(event){
      LOGGER.event('onNotificationClicked',event)
      let vm = this
      const { payload } = event || {}
      if(payload){
        const { channel_id } = payload || {}
        LOGGER.log('run1')
        vm.$store.state.global_conditions.tab='chat'
        setTimeout(()=>{
          LOGGER.log('run2')
          vm.$root.$emit(events.open_chat,{
            channel_id,
          })
        },1 *1000)
      }
    },
      //connection
    onOpen(){
      this.getAllChannels()
    },
  },
  created() {
    this.$store.state.mattermost.channels = TokenService.CHAT.get() || {};
    TokenService.CHAT.remove()
  },
  mounted(){
    //app--
    this.$root.$on(app_event.adding_channel, this.addingChannel);
    this.$root.$on(app_event.channel_view, this.channelView);
    this.$root.$on(app_event.get_messages_api, this.onGetPosts);
    this.$root.$on(app_event.check_unread_count_api, this.onCheckUnreadCount);
    this.$root.$on(app_event.uploading_file_on_channel,this.onUploadFileOnChannel);
    this.$root.$on(app_event.get_pinned_messages_api, this.onGetPinnedPosts);
    this.$root.$on(app_event.get_all_channels, this.getAllChannels);
    //window---
    window.addEventListener("beforeunload", this.onBeforeLoad);
    //---------
    //electron-
    if(process.env.IS_ELECTRON){
      window.electron.receive('notification-clicked',this.onNotificationClicked)
    }
    //---------
    this.load();
    this.mediaDevicesSettingSink()
  },
  beforeDestroy() {
    //app--
    this.$root.$off(app_event.adding_channel, this.addingChannel);
    this.$root.$off(app_event.get_messages_api, this.onGetPosts);
    this.$root.$off(app_event.check_unread_count_api, this.onCheckUnreadCount);
    this.$root.$off(app_event.uploading_file_on_channel,this.onUploadFileOnChannel);
    this.$root.$off(app_event.get_pinned_messages_api, this.onGetPinnedPosts);
    this.$root.$off(app_event.get_all_channels, this.getAllChannels);
    //-----
    //window---
    window.removeEventListener("beforeunload", this.onBeforeLoad);
    //---------
  },
};
</script>
