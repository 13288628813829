var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.select_repeat_second ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Repeat Seconds")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.repeatSeconds, function (data) {
    return _c('div', {
      key: data.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.limits.input_seconds = data.value;
          _vm.updateAdvanceOptions();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(data.text))]), _vm.forms.limits.input_seconds == data.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])] : _vm.conditions.screen == _vm.screens.select_repeat_times ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Repeat Times")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.repeatTimes, function (data) {
    return _c('div', {
      key: data.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.limits.ivr_nodtmf_repeat_limit = data.value;
          _vm.updateAdvanceOptions();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(data.text))]), _vm.forms.limits.ivr_nodtmf_repeat_limit == data.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])] : _vm.conditions.screen == _vm.screens.select_type ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Action")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.cmdOptions, function (cmd) {
    return _c('div', {
      key: cmd.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.limits.cmd = cmd.value;
          _vm.updateAdvanceOptions();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(cmd.text))]), _vm.forms.limits.cmd == cmd.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])] : _vm.conditions.screen == _vm.screens.select_ivr ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select IVR")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.accountsFilter, function (account) {
    return _c('div', {
      key: account.accountcode,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.limits.account = account.accountcode;
          _vm.updateAdvanceOptions();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(account.accountname))]), _vm.forms.limits.account == account.accountcode ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])] : _vm.conditions.screen == _vm.screens.select_sound ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Sound File")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.response.common_values.sounds, function (sound) {
    return _c('div', {
      key: sound.sound,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.limits.sound_id = sound.sound;
          _vm.updateAdvanceOptions();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(sound.label))]), _vm.forms.limits.sound_id == sound.sound ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Advance Options")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.ivr_advance_options, function () {
          return _vm.conditions.screen = _vm.screens.select_repeat_second;
        });
      }
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Repeat menu sound file if no action is pressed after")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-16px"
  }, [_vm._v(_vm._s(_vm.forms.limits.input_seconds))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.limits.submitted ? _c('p', {
    staticClass: "text text-danger animated bounceIntop w-100 mb-0"
  }, [!_vm.$v.forms.limits.input_seconds.required ? _c('span', [_vm._v("* Required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.ivr_advance_options, function () {
          return _vm.conditions.screen = _vm.screens.select_repeat_times;
        });
      }
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Allow the sound file to repeat")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-16px"
  }, [_vm._v(_vm._s(_vm.forms.limits.ivr_nodtmf_repeat_limit))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.limits.submitted ? _c('p', {
    staticClass: "text text-danger animated bounceIntop w-100 mb-0"
  }, [!_vm.$v.forms.limits.ivr_nodtmf_repeat_limit.required ? _c('span', [_vm._v("*Required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.ivr_advance_options, function () {
          return _vm.conditions.screen = _vm.screens.select_type;
        });
      }
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("If no action has been pressed after playing sound file repeatedly")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-16px"
  }, [_vm._v(_vm._s(_vm.forms.limits.cmd))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.limits.submitted ? _c('p', {
    staticClass: "text text-danger animated bounceIntop w-100 mb-0"
  }, [!_vm.$v.forms.limits.cmd.required ? _c('span', [_vm._v("*Required")]) : _vm._e()]) : _vm._e()]), _vm.cmdArgs.account ? _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.ivr_advance_options, function () {
          return _vm.conditions.screen = _vm.screens.select_ivr;
        });
      }
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("IVR")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-16px"
  }, [_vm._v(_vm._s(_vm.selectedAccount.accountname || ''))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.limits.submitted ? _c('p', {
    staticClass: "text text-danger animated bounceIntop w-100 mb-0"
  }, [!_vm.$v.forms.limits.account.required ? _c('span', [_vm._v("*Required")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.cmdArgs.sound ? _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.ivr_advance_options, function () {
          return _vm.conditions.screen = _vm.screens.select_sound;
        });
      }
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Forward Sound")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.selectedSound.sound_file ? _c('vb-audio-player', {
    staticClass: "mr-16px IosAudioPlayer",
    attrs: {
      "src": _vm.selectedSound.sound_file
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.limits.submitted ? _c('p', {
    staticClass: "text text-danger animated bounceIntop w-100 mb-0"
  }, [!_vm.$v.forms.limits.sound_id.required ? _c('span', [_vm._v("*Required")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.cmdArgs.dail_number ? _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.ivr_advance_options, function () {
          return _vm.$modal.show('SelectExtensionModal', {
            account: _vm.selectedOperator ? _vm.selectedOperator.accountcode : ''
          });
        });
      }
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Forward the call to user/operator")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-16px"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedOperator, 'accountname')))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.limits.submitted ? _c('p', {
    staticClass: "text text-danger animated bounceIntop w-100 mb-0"
  }, [!_vm.$v.forms.limits.sound_id.required ? _c('span', [_vm._v("*Required")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.cmdArgs.code_dtmf ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Code DTMF")]), _c('b-input', {
    attrs: {
      "type": "number",
      "disabled": !_vm.getUserPermissions.ivr_advance_options
    },
    on: {
      "change": function ($event) {
        return _vm.updateAdvanceOptions();
      }
    },
    model: {
      value: _vm.forms.limits.code_dtmf,
      callback: function ($$v) {
        _vm.$set(_vm.forms.limits, "code_dtmf", $$v);
      },
      expression: "forms.limits.code_dtmf"
    }
  }), _vm.forms.limits.submitted ? [!_vm.$v.forms.limits.code_dtmf.required ? _c('small', [_vm._v("*Required")]) : _vm._e()] : _vm._e()], 2)]) : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.api.update_wrong_ivr_input.send ? '' : _vm.checkPermission(_vm.getUserPermissions.ivr_advance_options, function () {
          return _vm.$modal.show('RecordingListSelecForWrongInput');
        });
      }
    }
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Invalid Input sound")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit ? _c('vb-audio-player', {
    staticClass: "mr-16px IosAudioPlayer",
    attrs: {
      "src": _vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.update_wrong_ivr_input.submitted && _vm.$v.forms.update_wrong_ivr_input.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop w-100 mb-0"
  }, [!_vm.$v.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit.required ? _c('span', [_vm._v("* Required")]) : _vm._e()]) : _vm._e()])])])])])], _c('SelectSoundFileModal', {
    attrs: {
      "modalName": 'RecordingListSelecForWrongInput'
    },
    on: {
      "select-file": function ($event) {
        return _vm.updateWrongIVRInput($event.file);
      }
    }
  }), _c('SelectSounds', {
    on: {
      "selected-sound": function ($event) {
        _vm.forms.limits.sound_id = $event.sound;
        _vm.updateAdvanceOptions();
      }
    }
  }), _c('SelectAccounts', {
    on: {
      "selected-account": function ($event) {
        _vm.forms.limits.account = $event.accountcode;
        _vm.updateAdvanceOptions();
      }
    }
  }), _c('SelectExtensionModal', {
    attrs: {
      "modalName": `SelectExtensionModal`
    },
    on: {
      "selected": function ($event) {
        _vm.forms.limits.dail_number = $event.extn;
        _vm.updateAdvanceOptions();
      }
    }
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }