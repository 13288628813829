<template>
  <div class="dialer-table no-data" v-if="design === 0">
    <span v-if="text" class="dialer-no-table-data">
      <vb-icon icon="dialer-noRecord-icon" width="187" height="193" />
      <span class="lg-mar-top" v-html="text" />
    </span>
    <slot name="content" />
    <slot name="loading" />
  </div>
  <div
    v-else-if="design === 1"
    class="d-flex justify-content-center align-items-center"
  >
    <span v-if="text" class="dialer-no-table-data">
      <vb-icon icon="dialer-noRecord-icon" width="187" height="193" />
      <span class="lg-mar-top" v-html="text" />
    </span>
    <slot name="content" />
    <slot name="loading" />
  </div>
  <div
    v-else-if="design === 2"
    class="
      d-flex
      justify-content-center
      align-items-center
      p-4
      text-center
      member-area
    "
  >
    <span v-if="text" class="dialer-no-table-data">
      <vb-icon icon="dialer-noRecord-icon" width="187" height="193" />
      <span class="lg-mar-top" v-html="text" />
    </span>
    <slot name="content" />
    <slot name="loading" />
  </div>
  <div v-else-if="design === 3" class="dialer-table no-data text-center w-100 tun">
    <span v-if="text" class="dialer-no-table-data flex-column">
      
      <img v-if="$store.getters.getIsMobile && (0 === 1)" :src="noData_v2" alt="No Data" class="noDataImage_v2_mobile" />
      <img :src="noData_v3" alt="No Data" v-if="$store.getters.getIsMobile && (0 === 1)" class="noDataImage_v2_mobile" />
      <vb-icon icon="noDataFound-v2" width="57" height="63" />
      
      <span class="mt-3 text-transform-normal" v-html="text" />
    </span>
    <slot name="content" />
    <slot name="loading" />
  </div>
  <div v-else>
    <span v-if="text" class="dialer-no-table-data">
      <vb-icon icon="dialer-noRecord-icon" width="187" height="193" />
      <span class="lg-mar-top" v-html="text" />
    </span>
    <slot name="content" />
    <slot name="loading" />
  </div>
</template>

<script>
export default {
  name: "vb-no-record",
  data() {
    return {
      noData: require("@/assets/images/nodata.png"),
      noData_v2: require("@/assets/images/noData_v2.png"),
      noData_v3: require("@/assets/images/noData_v3.png")
    };
  },
  props: {
    text: {
      type: String,
      default: "Sorry, No Data Found",
    },
    design: {
      type: Number,
      default: 0,
    },
  },
};
</script>

<style lang="scss" scoped></style>