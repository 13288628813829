var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation center-zoomIn-popup m-w-600 dialer-addMemberModal dialer-addMemberModalDesign2 SelectAddressBookNumberModal",
    attrs: {
      "name": "SelectAddressBookNumberModal"
    },
    on: {
      "before-open": _vm.beforeOpen,
      "closed": _vm.onClose
    }
  }, [_c('div', {
    staticClass: "position-relative"
  }, [_c('div', {
    staticClass: "sticky-top"
  }, [_c('div', {
    staticClass: "dialer-modal-header"
  }, [_c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("User numbers")]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('SelectAddressBookNumberModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "searchBox dialer-input-field input-consistent-inner-shadows seachBox"
  }, [_c('b-form', {
    staticClass: "position-relative",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('b-icon', {
    staticClass: "position-absolute cursor_pointer",
    attrs: {
      "icon": "search",
      "scale": "1"
    }
  }), _c('b-icon', {
    staticClass: "position-absolute cursor_pointer",
    attrs: {
      "icon": "x-circle",
      "scale": "1"
    }
  })], 1)], 1)]), _c('ul', {
    staticClass: "items list-unstyled"
  }, [_vm._l(_vm.searchedNumber, function (number) {
    return _c('li', {
      key: number.number,
      staticClass: "item cursor_pointer",
      on: {
        "click": function ($event) {
          return _vm.dail(number);
        }
      }
    }, [_c('p', [_c('b', [_vm._v(" number: ")]), _vm._v(" "), _c('span', [_vm._v(" " + _vm._s(_vm._f("number_formater")(number.number)))])]), _c('div', {
      staticClass: "d-flex align-items-center justify-content-between w-100"
    }, [_c('p', [_c('b', [_vm._v(" short code:")]), _vm._v(" "), _c('span', [_vm._v(" " + _vm._s(number.dialShortCode) + " ")])]), _c('p', [_c('b', [_vm._v(" type: ")]), _vm._v(" "), _c('span', [_vm._v(" " + _vm._s(number.type) + " ")])])])]);
  }), _vm.isEmpty(_vm.searchedNumber) ? _c('vb-no-record', {
    attrs: {
      "text": "There in no number",
      "design": 3
    }
  }) : _vm._e()], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }