
import { VOIP_API } from "../index"

export const SCHEDULE_API_ENDPOINTS = {
  list(account) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: `room/account/${account}`
    })
  },
  getdefaultroom(account) {
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `room/default/${account}`
    })
  },
  createschedule(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'room',
      data,
    })
  },
  updateschedule(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'update-room',
      data,
    })
  },
  checkroompassword(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'room/check-password',
      data,
    })
  },
  getroomdetail(id){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `room/conf-id/${id}`,
    })
  },
  getinstantsetting(params={}){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `get-user-room-setting`,
      params,
    })
  },
  updateinstantsetting(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `update-room-setting`,
      data,
    })
  },
}