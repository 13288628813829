var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 h-100 d-flex calender-top"
  }, [_c('div', {
    staticClass: "left-calender"
  }, [_vm._m(0), _c('div', {
    staticClass: "wrappercalender scrollow-side"
  }, [_c('div', {
    staticClass: "sidebarhrmcalender"
  }, [_c('ul', {
    staticClass: "hrm-ul-calender-page"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('li', {
      key: tab.id,
      class: `${_vm.selectedTab == tab.component ? 'selected-active' : ''}`,
      on: {
        "click": function ($event) {
          _vm.selectedTab = tab.component;
        }
      }
    }, [_c('a', {
      attrs: {
        "href": "#"
      }
    }, [_c('vb-icon', {
      staticClass: "svgiconmenu svgicsize",
      attrs: {
        "icon": tab.icon,
        "height": "15.23",
        "width": "16.25"
      }
    }), _c('span', {
      staticClass: "padding-calender-menu"
    }, [_vm._v(_vm._s(tab.text))])], 1)]);
  }), 0)])])]), _c(_vm.selectedTab, {
    tag: "component"
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "side-calender-top-bg d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "side-calender-top-small-img",
    attrs: {
      "src": require("../../assets/images/credit-card/hrmprofiletest.png"),
      "alt": "logo"
    }
  }), _c('h5', {
    staticClass: "side-calender-top-head-text"
  }, [_vm._v("Amber West")])]);

}]

export { render, staticRenderFns }