<template>
  <div>
    <!-- mobile web version -->
    <template v-if="$store.getters.getIsMobile">
      <div class="basicWhiteIOScard-item">
        <div class="d-flex align-items-center">
          <div class="basicIOSIcon mr-16px">
            <vb-icon class="" icon="latest-mobileActivityComments-icon"/>
          </div>
          <div class="d-flex flex-column">
            <div class="headingInsideTable">
              Activity
            </div>
          </div>
        </div>
        <button class="dialer-button dialer-button-primary" @click="conditions.add_note = !conditions.add_note" type="button" >
          <template v-if="!conditions.add_note">Add note</template>
          <template v-else>Close note</template>
        </button>
      </div>
      <!-- <div class="basicWhiteIOScard-item" v-if="conditions.add_note">
        <div class="d-flex align-items-center flex-fill">
          <div class="InfoComponentInsideAnroidIOSApp mr-16px">
            <vb-avatar :is_blf="false" :id="getCurrentUser.account" />
          </div>
          <b-form @submit.prevent="createNote()" class="d-flex align-items-center w-100">
            <div class="d-flex flex-fill align-items-center w-100">
              <textarea 
                class="w-100 border px-2 py-1" 
                rows="auto" 
                v-model="forms.create_note.note" 
                :disabled="api.create_note.send || disabled" 
                placeholder="Write a comment..." 
                @keydown.exact.enter.prevent="createNote()" 
              />
            </div>
          </b-form>
        </div>
      </div> -->
      <b-form v-if="!!selected.update_id" @submit.prevent="updateNote()" class="d-flex align-items-center mt-12px w-100">
        <vb-avatar :is_blf="false" :id="getCurrentUser.account" class="mr-3" />
        <textarea 
          class="TODO-textArea" 
          rows="auto" 
          v-model="forms.update_note.note" 
          :disabled="api.update_note.send || disabled" 
          placeholder="Write a comment..." 
          @keydown.enter.prevent="updateNote()" 
        />
        <p 
          v-if="(forms.update_note.submitted && $v.forms.update_note.note.$invalid) || api.update_note.validation_errors.note"
          class="mb-1 text-small text text-danger animated bounceIntop"
        >
          <span v-if="!$v.forms.update_note.note.required">Note is required</span>
          <span v-for="(em,i) in api.update_note.validation_errors.note" :key="i">{{ em }}</span>
        </p>
        <b-button :disabled="api.update_note.send || disabled" variant="link" @click="selected.update_id=''">
          <b-icon icon="x" variant="danger" />
        </b-button>
      </b-form>
      <div class="basicWhiteIOScard-item" v-else-if="conditions.add_note">
        <div class="d-flex align-items-center flex-fill">
          <div class="InfoComponentInsideAnroidIOSApp mr-16px">
            <vb-avatar :is_blf="false" :id="getCurrentUser.account" />
          </div>
          <b-form @submit.prevent="createNote()" class="d-flex align-items-center w-100">
            <div class="d-flex flex-fill align-items-center w-100">
              <textarea 
                class="w-100 border px-2 py-1" 
                rows="auto" 
                v-model="forms.create_note.note" 
                :disabled="api.create_note.send || disabled" 
                placeholder="Write a comment..." 
                @keydown.exact.enter.prevent="createNote()" 
              />
            </div>
          </b-form>
        </div>
      </div>
      <div class="basicWhiteIOScard-item" v-for="note in response.notes" :key="note.id">
        <div class="d-flex align-items-center flex-fill">
          <div class="InfoComponentInsideAnroidIOSApp mr-16px">
            <vb-avatar :is_blf="false" :id="getCurrentUser.account" />
          </div>
          <div class="d-flex flex-column">
            <div class="TODO-comment-name">
              {{ getVoipUsersAlisesDisplayName[note.created_by] }}
              <div class="TODO-comment-commentedTime ml-2">{{ note.created_at | filter_date_current(null,{ formate_now: true }) }}</div>
            </div>
            <div >{{ note.note }}</div>
            <div v-if="note.created_by==getCurrentUser.account" class="todo-comment-actions">
              <vb-spinner v-if="api.remove_note.send==note.id" />
              <template v-else>
                <!-- <b-icon icon="emoji-laughing" class="darker-chevron-down withoutPath grey ml-0" /> -->
                <p class="todo-smallGreyBox-text mb-0 textUnderline" @click="disabled ? '' : setUpdate(note)">Edit</p>
                <p class="todo-smallGreyBox-text mb-0 textUnderline" @click="disabled ? '' : removeNote(note)">Delete</p>
              </template>
            </div>
          </div>
        </div>
      </div>
    </template>

    <!-- desktop web version -->
    <template v-else>
      
      <div class="TODO-innerHeading">Activity</div>
      <div class="TODO-comment-scrollSection">
        <div v-for="note in response.notes" :key="note.id" class="w-100 todo-activity-item">
          <div class="todo-activity-item-topSection">
            <div class="d-flex align-items-center">
              <vb-avatar :is_blf="false" :id="note.created_by" class="mr-3" />
              <p class="TODO-comment-name mb-0">
                {{ getVoipUsersAlisesDisplayName[note.created_by] }}
              </p>
            </div>
            <div class="todo-comment-Text">{{ note.note }}</div>
          </div>
          <div class="todo-activity-item-bottomSection">
            <div class="TODO-comment-commentedTime ml-2">{{ note.created_at | filter_date_current(null,{ formate_now: true }) }}</div>
          </div>
          <!--<div class="d-flex flex-column align-items-start w-100">
            <div class="TODO-comment-name">
              {{ getVoipUsersAlisesDisplayName[note.created_by] }}
              <div class="TODO-comment-commentedTime ml-2">{{ note.created_at | filter_date_current(null,{ formate_now: true }) }}</div>
            </div>
            <div class="todo-comment-smallGreyBox">{{ note.note }}</div>
            <!~~<div v-if="note.created_by==getCurrentUser.account" class="todo-comment-actions">
              <vb-spinner v-if="api.remove_note.send==note.id" />
              <template v-else>
                <!~~ <b-icon icon="emoji-laughing" class="darker-chevron-down withoutPath grey ml-0" /> ~~>
                <p class="todo-smallGreyBox-text mb-0 textUnderline" @click="disabled ? '' : setUpdate(note)">Edit</p>
                <p class="todo-smallGreyBox-text mb-0 textUnderline" @click="disabled ? '' : removeNote(note)">Delete</p>
              </template>
            </div>~~>
          </div>-->
        </div>
        <ActivityItem v-for="activity in response.activities.logs" :key="activity.id" :activity="activity" class="todo-activity-item" />
      </div>
      <div class="mt-12px todo-textarea-container">
        <b-form v-if="!!selected.update_id" @submit.prevent="updateNote()" class="d-flex align-items-center w-100">
          <!-- <vb-avatar :is_blf="false" :id="getCurrentUser.account" class="mr-3" /> -->
          <textarea 
            class="TODO-textArea" 
            rows="auto" 
            v-model="forms.update_note.note" 
            :disabled="api.update_note.send || disabled" 
            placeholder="Write a comment..." 
            @keydown.enter.prevent="updateNote()" 
          />
          <p 
            v-if="(forms.update_note.submitted && $v.forms.update_note.note.$invalid) || api.update_note.validation_errors.note"
            class="mb-1 text-small text text-danger animated bounceIntop"
          >
            <span v-if="!$v.forms.update_note.note.required">Note is required</span>
            <span v-for="(em,i) in api.update_note.validation_errors.note" :key="i">{{ em }}</span>
          </p>
          <b-button :disabled="api.update_note.send || disabled" variant="link" @click="selected.update_id=''">
            <b-icon icon="x" variant="danger" />
          </b-button>
        </b-form>
        <b-form v-else @submit.prevent="createNote()" class="d-flex align-items-center w-100">
          <!-- <vb-avatar :is_blf="false" :id="getCurrentUser.account" class="mr-3" /> -->
          <textarea 
            class="TODO-textArea" 
            rows="auto" 
            v-model="forms.create_note.note" 
            :disabled="api.create_note.send || disabled" 
            placeholder="Write a comment..." 
            @keydown.exact.enter.prevent="createNote()" 
          />
          <div class="TODO-textArea-button-container">
            <button class="smallBlueButton"> Send </button>
          </div>
          <p 
            v-if="(forms.create_note.submitted && $v.forms.create_note.note.$invalid) || api.create_note.validation_errors.note"
            class="mb-1 text-small text text-danger animated bounceIntop"
          >
            <span v-if="!$v.forms.create_note.note.required">Note is required</span>
            <span v-for="(em,i) in api.create_note.validation_errors.note" :key="i">{{ em }}</span>
          </p>
        </b-form>
      </div>
    </template>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import required from 'vuelidate/lib/validators/required'
import { mapGetters } from 'vuex'
import ActivityItem from './ActivityItem.vue'
export default {
  name: 'TaskComments',
  components: { 
    ActivityItem 
  },
  props: {
    task_id: {
      type: [String,Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      forms: {
        create_note: this.$helperFunction.formInstance({
          data: {
            note: '',
          },
        }),
        update_note: this.$helperFunction.formInstance({
          data: {
            note: '',
          },
        }),
      },
      api: {
        get_activities: this.$helperFunction.apiInstance(),
        get_notes: this.$helperFunction.apiInstance(),
        remove_note: this.$helperFunction.apiInstance({ send: '' }),
        update_note: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        create_note: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
      },
      selected: {
        update_id: '',
      },
      response: {
        activities: [],
        notes: [],
      },
      conditions: {
        add_note: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsersAlisesDisplayName'
    ]),
  },
  validations: {
    forms: {
      create_note: {
        note: {
          required: required,
        },
      },
      update_note: {
        note: {
          required: required,
        },
      },
    },
  },
  methods: {
    async getActivities(){
      if(this.api.get_activities.send) return;
      try {
        this.api.get_activities.send=true
        const { data } = await VOIP_API.endpoints.tasklist.logs.list({
          task_id: this.task_id,
          uid: this.getCurrentUser.uid,
        })
        this.response.activities=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.get_activities.send=false
      }
    },
    setUpdate(note){
      if(this.api.remove_note.send==note.id) return;
      this.forms.update_note.note=note.note
      this.selected.update_id=note.id
    },
    async getNotes(){
      if(this.api.get_notes.send) return;
      try {
        this.api.get_notes.send=true
        const { data } = await VOIP_API.endpoints.tasklist.notes.list({
          task_id: this.task_id
        })
        this.response.notes=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.get_notes.send=false
      }
    },
    async removeNote(note){
      const note_id = note?.id
      if(!note_id || !!this.api.remove_note.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "WARNING",
          message: `Are you sure you want to delete?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.remove_note.send=note_id
        await VOIP_API.endpoints.tasklist.notes.delete(note_id,{
          task_id: this.task_id
        })
        this.$emit('comment-removed')
        this.getNotes()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.remove_note.send=''
      }
    },
    async createNote(){
      this.forms.create_note.submitted=true
      this.$v.forms.create_note.$touch()
      if(this.api.create_note.send || this.$v.forms.create_note.$invalid) return;
      try {
        this.api.create_note.send=true
        this.api.create_note.error_message=''
        this.api.create_note.validation_errors={}
        await VOIP_API.endpoints.tasklist.notes.create({
          note: this.forms.create_note.note,
          task_id: this.task_id,
          created_by: this.getCurrentUser.account
        })
        this.$emit('comment-created')
        this.forms.create_note.reset()
        this.getNotes()
      } catch (ex) {
        this.api.create_note.error_message=ex.own_message ?? ''
        this.api.create_note.validation_errors=ex.own_errors ?? {}
      } finally {
        this.api.create_note.send=false
        this.forms.create_note.submitted=false
      }
    },
    async updateNote(){
      this.forms.update_note.submitted=true
      this.$v.forms.update_note.$touch()
      if(this.api.update_note.send || this.$v.forms.update_note.$invalid) return;
      try {
        this.api.update_note.send=true
        this.api.update_note.error_message=''
        this.api.update_note.validation_errors={}
        await VOIP_API.endpoints.tasklist.notes.update(this.selected.update_id,{
          note: this.forms.update_note.note,
          task_id: this.task_id,
          created_by: this.getCurrentUser.account
        })
        this.$emit('comment-updated')
        this.forms.update_note.reset()
        this.selected.update_id=''
        this.getNotes()
      } catch (ex) {
        this.api.update_note.error_message=ex.own_message ?? ''
        this.api.update_note.validation_errors=ex.own_errors ?? {}
      } finally {
        this.api.update_note.send=false
        this.forms.update_note.submitted=false
      }
    },
  },
  mounted () {
    this.getNotes();
    this.getActivities();
  },
}
</script>

<style>

</style>