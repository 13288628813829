<template>
  <div 
    :style="`height:${size || '45px'};width:${size || '45px'};`" 
    class="main v2"
    v-b-popover.hover.top="{
      content: info.name,
      disabled: !showPopover
    }"
    :class="{
      'block': !image,
      'is_ringing': info.is_ringing,
      'is-config': info.is_blf_config,
      'notOnline': info.is_not_online,
      'busy': info.is_on_the_phone,
      'ready': info.is_ready,
    }"
  >
    
    <div class="imgBox">
      <b-icon v-if="loading" icon="arrow-clockwise" animation="spin-pulse" variant="danger" />
      <img v-else @click="$emit('click')" :src="info.image || image || ''" @error="$event.target.src = user_male" alt="name" class="clip-svg" />
    </div>
    <!-- <svg class="for-desktop forClipPathSVG"
      xmlns="http://www.w3.org/2000/svg"
      width="45"
      height="45"
      viewBox="0 0 45 45"
    >
      <defs>
        <clipPath id="avatar-icon">
          <path
            xmlns="http://www.w3.org/2000/svg"
            id="avatar-icon"
            data-name="avatar-icon"
            d="M31.576,44.827a55.249,55.249,0,0,1-17.166,0A15.9,15.9,0,0,1,1.174,31.576a54.77,54.77,0,0,1,0-17.166A15.887,15.887,0,0,1,14.41,1.174a54.77,54.77,0,0,1,17.166,0A15.9,15.9,0,0,1,44.827,14.41a55.248,55.248,0,0,1,0,17.166A15.9,15.9,0,0,1,31.576,44.827Z"
            transform="translate(-0.497 -0.497)"
            fill="none"
            stroke-width="3"
            stroke="black"
          />
        </clipPath>
      </defs>
    </svg> -->
    <svg class="for-desktop forClipPathSVG" xmlns="http://www.w3.org/2000/svg" width="35" height="35" viewBox="0 0 35 35">
      <defs>
        <clipPath id="avatar-icon">
          <path id="Path_12116" data-name="Path 12116" d="M24.668,34.975a42.971,42.971,0,0,1-13.351,0,12.363,12.363,0,0,1-10.3-10.306,42.6,42.6,0,0,1,0-13.351,12.354,12.354,0,0,1,10.3-10.3,42.6,42.6,0,0,1,13.351,0,12.363,12.363,0,0,1,10.306,10.3,42.97,42.97,0,0,1,0,13.351A12.369,12.369,0,0,1,24.668,34.975Z" transform="translate(-0.496 -0.497)" fill="none"/>
        </clipPath>
      </defs>
    </svg>
    <svg class="rect_status_svg" width="57" height="57" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 57 57">
      <rect rx="20" ry="20" x="1.75" y="1.75" height="53" width="53" stroke-width="3.5" stroke="#DFDFDF" style="fill:transparent;" />
    </svg>
    <svg class="for-mobile"
      xmlns="http://www.w3.org/2000/svg"
      width="29.005"
      height="29.005"
      viewBox="0 0 29.005 29.005"
    >
      <defs>
        <clipPath id="avatar-icon-mobile">
          <path
            xmlns="http://www.w3.org/2000/svg"
            id="avatar-icon-mobile"
            data-name="avatar-icon-mobile"
            d="M19.835,28.08a34.377,34.377,0,0,1-10.681,0A9.89,9.89,0,0,1,.919,19.835a34.079,34.079,0,0,1,0-10.681A9.885,9.885,0,0,1,9.154.919a34.079,34.079,0,0,1,10.681,0A9.89,9.89,0,0,1,28.08,9.154a34.377,34.377,0,0,1,0,10.681A9.9,9.9,0,0,1,19.835,28.08Z"
            transform="translate(0.005 0.005)" 
            fill="none"
          />
        </clipPath>
      </defs>
    </svg>
  

    <Status v-if="showStatus && !isStatusIdUndefined" :statusId="status_id || info.status_id" :callQueueStatus="info.call_queue_status" />
  </div>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import Status from "./Status.vue";
export default {
  name: "vb-avatar",
  components: {
    Status,
  },
  props: {
    image: {
      type: String,
    },
    name: {
      type: String,
    },
    status_id: {
      type: [String,Number],
    },
    size: {
      type: [String,Number],
    },
    id: {
      type: [String,Number],
    },
    is_blf: {
      type: Boolean,
      default: true,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    showPopover: {
      type: Boolean,
      default: false,
    },
    showStatus: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      user_male: require("@/assets/images/default.png"),
    };
  },
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getCurrentUser: 'getCurrentUser',
      getVoipUsersAlises: 'getVoipUsersAlises',
      getVoipTeams: 'getVoipTeams',
      getVoipCallQueues: 'getVoipCallQueues',
    }),
    ...mapState({
      statuses: (state)=>state.common.statuses
    }),
    availableStatusId(){ return this.statuses?.find?.(i=>i.status === 'Available' && !i.accountcode)?.id },
    busyStatusId(){ return this.statuses?.find?.(i=>i.status === 'Busy' && !i.accountcode)?.id },
    offlineStatusId(){ return this.statuses?.find?.(i=>i.status === 'Offline' && !i.accountcode)?.id },
    info() {
      let vm = this
      const key = this.id;
      if (!key) return {};
      const user = this.getVoipUsersAlises[key];
      const team = this.getVoipTeams.find(team=>key==team?.ringroup?.accountcode || key==team?.real_id || key==team?.ringroup?.extn)
      const call_queue = this.getVoipCallQueues.find(call_queue=>key==call_queue?.voipaccount || key==call_queue?.extn)
      if (call_queue) {
        return {
          image: call_queue.avatar?.image?.image,
          name: call_queue?.label ?? '',
          call_queue_status: call_queue?.call_queue_status,
        };
      } else if (team) {
        const members = team?.members?.map?.(i=>i.accountcode)?.map?.(i=>vm.getVoipUsersAlises[i])?.filter?.(i=>!!i) ?? []
        const members_state = members?.map(i=>i.state)?.filter(i=>!!i)
        const members_status = members?.map(i=>i.status ? i.status.id || i.status : '')?.filter(i=>!!i)
        const first_id = members_status?.[0]
        let status_id = 
          members_status.length<=0 ? 
            this.offlineStatusId :
            members_status?.every?.(i=>i==first_id) ? 
              first_id :  
              members_status?.some?.(i=>i==this.availableStatusId) ? 
                this.availableStatusId : 
                members_status?.some?.(i=>i==this.busyStatusId) ? 
                  this.busyStatusId : 
                  members_status?.some?.(i=>i==this.offlineStatusId) ? 
                    this.offlineStatusId : 
                    this.offlineStatusId
        let state = 
          members_state.length<=0 ? 
            'Not online' :
            members_state?.every?.(i=>i=='Ringing') ? 
              'Ringing' : 
              members_state?.every?.(i=>i=='On the phone') ? 
                'On the phone' : 
                members_state?.every?.(i=>i=='Ready') ? 
                  'Ready' : 
                  members_state?.every?.(i=>i=='Not online') ? 
                    'Not online' : 
                    members_state?.some?.(i=>i=='Ringing') ? 
                      'Ringing' : 
                      members_state?.some?.(i=>i=='Ready') ? 
                        'Ready' : 
                        members_state?.some?.(i=>i=='On the phone') ? 
                          'On the phone' : 
                          'Not online'
        return {
          image: team?.avatar?.image?.image ?? '',
          name: team?.name ?? '',
          is_ringing: state=='Ringing',
          is_on_the_phone: state=='On the phone',
          is_ready: state=='Ready',
          is_not_online: state=='Not online',
          is_blf_config: this.is_blf && this.getCurrentUser.blf && this.getUserPermissions.blf,
          status_id,
        };
      } else if (user) {
        return {
          image: user.profile_img,
          name: user?.user?.display_name ?? '',
          status_id: user.ami_status=='inactive' ? 'offline' : user.idle_state?.state=='idle' ? 'idle' : user.ami_status=='active' && user.status ? user.status.id || user.status : '',
          is_ringing: user.state=='Ringing',
          is_on_the_phone: user.state=='On the phone',
          is_ready: user.state=='Ready',
          is_not_online: user.state=='Not online',
          is_blf_config: this.is_blf && this.getCurrentUser.blf && this.getUserPermissions.blf, //user.blf_state!='Terminated'
        };
      }
      return {};
    },
    isStatusIdUndefined(){ return typeof (this.status_id ?? this.info.status_id) == 'undefined' && typeof this.info.call_queue_status == 'undefined'  },
  },
};
</script>

<style lang="scss" scoped>
.main {
  position: relative;
  border-radius: 16px;
  // .imgBox {
  //   // width: 45px;
  //   // height: 45px;
  //   .clip-svg {
  //     clip-path: url(#avatar-icon);
  //     height: 45px;
  //     width: 45px;
  //     object-fit: cover;
  //   }
  // }
  &.block {
    display: flex;
    justify-content: center;
    align-items: center;
    // border: 5px solid $white;
    color: $white;
  }
  &.v2{
    display: flex;
    justify-content: center;
    align-items: center;
    // min-width: 55px;
    // min-height: 55px;
    // height: 55px !important;
    // width: 55px !important;
    min-width: 35px;
    min-height: 35px;
    height: 35px !important;
    width: 35px !important;
    // .rect_status_svg{
    //   display: block;
    //   width: 40px;
    //   height: 40px;
    // }
    &.is-config{
      .rect_status_svg{
        display: block;
        width: 40px;
        height: 40px;
      }
      & > .imgBox{
        width: 30px;
        height: 30px;
        .clip-svg {
          // clip-path: url(#avatar-icon);
          clip-path: none;
          height: 30px;
          width: 30px;
          object-fit: cover;
          // @include border-radius(50%);
          // @include border-radius(0%);
        }
      }
    }
    & > svg{
      position: absolute;
    }
    & > .imgBox{
      width: 35px;
      height: 35px;
      .clip-svg {
        // clip-path: url(#avatar-icon);
        clip-path: none;
        height: 35px;
        width: 35px;
        object-fit: cover;
        // @include border-radius(50%);
      }
    }
  }
  &.is_ringing{
    // animation: blink 1s infinite;
    // .dialer-userStatus{
    //   animation: blink_status 1s infinite;
    // }
    // & > .rect{
      svg > rect{
        stroke-width: 3px;  
        animation: blink_svg 1s infinite;
        stroke:#29C941;
      }
    // }
  }
  &.busy{
    svg > rect{
      stroke-width: 3px;  
      stroke:rgb(255, 178, 67);
    }
  }
  &.notOnline{
    svg > rect{
      stroke-width: 3px;  
      // stroke:rgb(135, 135, 135);
      // stroke:white;
      stroke: #DFDFDF;
    }
  }
  &.ready{
    svg > rect{
      stroke-width: 3px;  
      // animation: blink_svg 1s infinite;
      // stroke:$primary;
      stroke:#29C941;
    }
  }
}
@keyframes blink {
  from {border-width: 1px;}
  to {border-width: 5px;}
}
@keyframes blink_svg {
  from {stroke-width: 0.5px;}
  to {stroke-width: 3.5px;}
}
@keyframes blink_status {
  from {
    right: -3px;
    bottom: -3px;
  }
  to {
    right: -8px;
    bottom: -8px;
  }
}
.rect_status_svg{
  display: none;
}
</style>