<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup KbSupportModal" 
      :class="{
        'iframeContainer-c': data.kb,
        'isFullScreen-container': conditions.isFullScreen
      }" 
      width="40%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div v-if="data.kb" class="position-relative-c" :class="{'isFullScreen':conditions.isFullScreen}" >
        <div class="dialer-edit-header flex-column">
          <div class="d-flex justify-content-between w-100 align-items-center">
            <h2 class="dialer-edit-title mb-0">Help & support</h2>
            <div class="d-flex">
              <div class="dialer-edit-actions">
                <button class="newDoneButton" type="button" @click="$modal.hide(modalName);">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
          </div>
          <template v-if="conditions.isFullScreen">
            <div class="d-flex w-100 align-items-center isFullScreenContainer">
              <div class="expandText" @click="$modal.hide(modalName);">
                <b-icon icon="arrow-right" class="mr-2 " />
                <span class=" cursor_pointer">Close</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="d-flex w-100 align-items-center isFullScreenContainer">
              <div class="expandText" @click="conditions.isFullScreen = true">
                <b-icon icon="arrow-left" class="mr-2 " />
                <span class="cursor_pointer">Expand</span>
              </div>
            </div>
          </template>
        </div>
        <div class="ticketTabContainer iframeContainer-c">
          <div class="iframeContainer">
            <!-- <div v-if="api.kb_link.send" class="loading-container">
              <vb-loading />
            </div> -->
            <template v-if="api.kb_link.send">
              <div class="w-100 p-5">
                <div class="d-flex align-items-center mb-4" v-for="n in 3" :key="n.id">
                  <div class="latestShimmerDesign" style="height:150px;width:150px;"></div>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <div class="latestShimmerDesign mb-4" style="height:40px;width:80%;"></div>
                    <div class="latestShimmerDesign mb-3" style="height:28px;width:50%;"></div>
                    <div class="latestShimmerDesign" style="height:28px;width:60%;"></div>
                  </div>
                </div>
              </div>
            </template>
            <!-- <div v-else class="iframeContainer-gradient"></div> -->
            <iframe style="height:100%;width:100%" :style="`opacity:${api.kb_link.send? 0 : 1}`" :src="response.kb_link" />
          </div>
        </div>
      </div>
      
      <section v-else class="ct-user-new dialer-edit">
        <div v-if="!getIsMobile" class="dialer-edit-header flex-column">
          <div class="d-flex justify-content-between w-100 align-items-center">
            <h2 class="dialer-edit-title mb-0">Help & support</h2>
            <div class="d-flex">
              <!-- <div class="latestTabsDesign">
                <div 
                  class="latestTabsDesign-item"  
                  :class="{
                    'selected': selected.tab==tab.value
                  }" 
                  v-for="tab in tabs" 
                  :key="tab.value" 
                  @click="selected.tab=tab.value;"
                >
                  {{tab.text}}
                </div>
              </div> -->
              <div class="TabsDesignWithIcon-container">
                <div class="TabsDesignWithIcon-item" v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value" :class="{'active': selected.tab==tab.value,'mwd-168px' : tab.value != 3}">
                  <vb-icon :icon="tab.icon" height="38px" width="38px" />
                  <span class="TabsDesignWithIcon-item-textPart">{{tab.text}}</span>
                </div>
              </div>
              <div class="dialer-edit-actions">
                <button class="newDoneButton ml-8px" type="button" @click="$modal.hide(modalName);">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
          </div>
          <template v-if="conditions.isFullScreen">
            <div class="d-flex w-100 align-items-center">
              <div class="expandText" @click="$modal.hide(modalName);">
                <b-icon icon="arrow-right" class="mr-2 " />
                <span class=" cursor_pointer">Close</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="d-flex w-100 align-items-center">
              <div class="expandText" @click="conditions.isFullScreen = true">
                <b-icon icon="arrow-left" class="mr-2 " />
                <span class=" cursor_pointer">Expand</span>
              </div>
            </div>
          </template>
        </div>
        <div v-else class="dialer-edit-header flex-column">
          <div class="d-flex justify-content-between w-100 align-items-center">
            <h2 class="dialer-edit-title mb-0">Help & support</h2>
            <div class="dialer-edit-actions">
              <button class="newCloseButton" type="button" @click="$modal.hide(modalName);">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </button>
            </div>
          </div>
          <div class="d-flex w-100 justify-content-start mt-3">
            <div class="d-flex align-items-center">
              <div 
                class="dialer-button dialer-button-primary-outline border-0 v2 mr-3" 
                :class="{
                  'active': selected.tab==tab.value
                }" 
                v-for="tab in tabs" 
                :key="tab.value" 
                @click="selected.tab=tab.value;"
              >
                {{ tab.text }}
              </div>
            </div>
          </div>
          <template v-if="conditions.isFullScreen">
            <div class="d-flex w-100 align-items-center expand-container">
              <div class="expandText" @click="$modal.hide(modalName);">
                <b-icon icon="arrow-right" class="mr-2 " />
                <span class=" cursor_pointer">Close</span>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="d-flex w-100 align-items-center expand-container">
              <div class="expandText" @click="isFullScreen = true">
                <b-icon icon="arrow-left" class="mr-2 " />
                <span class=" cursor_pointer">Expand</span>
              </div>
            </div>
          </template>
        </div>
        <div v-show="selected.tab == tabs.knowledge_base.value" class="ticketTabContainer iframeContainer-c">
          <div class="iframeContainer" style="">
            <!-- <div v-if="api.kb_link.send" class="loading-container">
              <vb-loading />
            </div> -->
            <template v-if="api.kb_link.send">
              <div class="w-100 p-5">
                <div class="d-flex align-items-center mb-4" v-for="n in 3" :key="n.id">
                  <div class="latestShimmerDesign" style="height:150px;width:150px;"></div>
                  <div class="d-flex flex-column flex-fill ml-4">
                    <div class="latestShimmerDesign mb-4" style="height:40px;width:80%;"></div>
                    <div class="latestShimmerDesign mb-3" style="height:28px;width:50%;"></div>
                    <div class="latestShimmerDesign" style="height:28px;width:60%;"></div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <!-- <div class="iframeContainer-gradient"></div> -->
              <iframe style="height:100%;width:100%" :style="`opacity:${api.kb_link.send? 0 : 1}`" :src="response.kb_link" />
            </template>
          </div>
        </div>
        <div v-if="selected.tab == tabs.contact_support.value" class="ticketTabContainer mt-5">
          <!--<div class="ticketTabContainer">
            <div class="ticketHeading-container">
              <div class="d-flex align-items-center justify-content-between">
                <div class="numberTickets-container">
                  <div class="bigText purple">{{openTickets}}</div>
                  <div class="smallText">Open Tickets</div>
                </div>
                <div class="numberTickets-container">
                  <div class="bigText green">{{closeTickets}}</div>
                  <div class="smallText">Closed Tickets</div>
                </div>
              </div>
              <button @click="$modal.show('AddSupportTicketFromKBModal')" type="button" class="dialer-button dialer-button-primary d-flex align-items-center" >
                <b-icon icon="plus" font-scale="1.4" class="mr-2" />
                Create a support ticket
              </button>
            </div>
            <vb-spinner v-if="api.tickets.send" />
            <vb-table v-else-if="response.tickets.length!=0" :isListEmpty="response.tickets.length==0" :listLength="response.tickets.length" :perPage="5" :loading="api.tickets.send" class="kbTable">
              <template slot="header">
                <tr>
                  
                  <th class="dialer-has-sort">
                    <span class="dialer-sorted-by ascending">SUBJECT</span>
                  </th>
                  <th class="dialer-has-sort dialer-col-right">
                    <span>STATUS</span>
                  </th>
                  
                </tr>
              </template>
              <template #body="{ start, end }">
                <tr v-for="(ticket, index) in response.tickets" :key="index" v-show="index >= start && index <= end" class="dialer-row-select" @click="$modal.show('SupportTicketDetailFromKBModal',{ id: ticket.id })">
                  
                  <td>
                    <div class="d-flex align-items-center">
                      <b-icon icon="exclamation-circle-fill" v-b-tooltip.hover :title="`${ticket.priority} Priority`" :variant="ticket.priority | priorityType" font-scale="1.5" class="mr-2"/>
                      {{ticket.subject}}
                    </div>
                    
                  </td>
                  <td class="dialer-col-right">{{ticket.status}}</td>
                  
                </tr>
              </template>
            </vb-table>
            <div class="dialer-box alt table mt-5">
              <div class="description-text-container">
                <div class="description-text">
                  If you didn't find solution to your problem 
                  please visit our knowledge base for more information
                  We are happy to help you!
                </div>
                <button type="button" @click="selected.tab = tabs.knowledge_base.value" class="dialer-button dialer-button-primary" >
                  Go to Knowledge base
                </button>
              </div>
            </div>
          </div>-->
          <ContectSupport @go-to-knowledge-base="selected.tab = tabs.knowledge_base.value" />
        </div>
        <div v-if="selected.tab == tabs.quick_start.value" class="ticketTabContainer mt-5">
          <QuickStart />
        </div>
        <!-- <div v-if="selected.tab == tabs.sms.value" class="ticketTabContainer mt-5">
          <div>
            
          </div>
        </div> -->
      </section>
      
      <!-- <AddSupportTicketModal :modalName="'AddSupportTicketFromKBModal'" @added="fetchTickets();$modal.show('SupportTicketDetailFromKBModal',{ id: $event.id })" />
      <SupportTicketDetailModal :modalName="'SupportTicketDetailFromKBModal'" /> -->
    </modal>
  </div>
</template>

<script>
// import AddSupportTicketModal from "./AddSupportTicketModal.vue";
// import SupportTicketDetailModal from './SupportTicketDetailModal.vue';
import QuickStart from '../../views/QuickStart.vue';
// import required from "vuelidate/lib/validators/required";
import { $fn, VOIP_API } from '../../utils';
import { mapGetters } from 'vuex';
import ContectSupport from '../ContectSupport.vue';
// import { TIDIO } from '../../tidio'
const tabs = {
  knowledge_base: {
    text: "Knowledge base",
    value: 1,
    icon: 'squared-kb-icon'
  },
  // raise_a_ticket: {
  //   text: "Raise a ticket",
  //   value: 2,
  // },
  contact_support: {
    text: "Contact support",
    value: 2,
    icon: 'squared-contactSupport-icon'
  },
  quick_start: {
    text: "Quick start",
    value: 3,
    icon: 'squared-quickStart2-icon'
  },
  // sms: {
  //   text: "SMS",
  //   value: 4,
  // },
}
// const contact_support_tabs = {
//   chat: {
//     text: "Chat",
//     value: 2,
//   },
//   raise_a_ticket: {
//     text: "Raise a ticket",
//     value: 1,
//   },
//   call_me: {
//     text: "Call Me",
//     value: 3,
//   },
// }
// const number_options = {
//   pk: {
//     text: "PKR(+92)",
//     value: '+92',
//   },
//   uk: {
//     text: "UK(+44)",
//     value: '+44',
//   },
//   usa: {
//     text: "USA(+1)",
//     value: '+1',
//   },
// }
export default {
  name: "KbInfoModal",
  components: {
    // AddSupportTicketModal,
    // SupportTicketDetailModal,
    QuickStart,
    ContectSupport,
  },
  props: {
    modalName: {
      type: String,
      default: 'KbInfoModal',
    },
  },
  data() {
    return {
      selected: this.$helperFunction.stateInstance({
        data: {
          // tab: tabs.knowledge_base.value,
          tab: tabs.contact_support.value,
          // contact_support_tabs: contact_support_tabs.raise_a_ticket.value,
        }
      }),
      forms: {
        // call_me: this.$helperFunction.formInstance({
        //   data: {
        //     problem: '',
        //     desc: '',
        //     number: '',
        //     country_code: number_options.usa.value,
        //   }
        // }),
        reset(){
          // this.call_me?.reset?.()
        },
      },
      api: {
        // fetch_sms: this.$helperFunction.apiInstance({  }),
        // tickets: this.$helperFunction.apiInstance({  }),
        // delete_ticket: this.$helperFunction.apiInstance({  }),
        // call_me: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        kb_link: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        reset(){
          // this.tickets?.reset?.()
          // this.delete_ticket?.reset?.()
          // this.call_me?.reset?.()
          this.kb_link?.reset?.()
        },
      },
      filter: {
        // tickets: this.$helperFunction.stateInstance({
        //   data: {
        //     search: '',
        //     priority: '',
        //     status: '',
        //   }
        // }),
        reset(){
          // this.tickets?.reset?.()
        },
      },
      response: this.$helperFunction.stateInstance({
        data: {
          // tickets: [],
          kb_link: '',
          // sms: [],
        }
      }),
      data: this.$helperFunction.stateInstance({
        data: {
          kb: '',
        }
      }),
      conditions: this.$helperFunction.stateInstance({
        data: {
          isFullScreen: false,
        }
      }),
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile'
    ]),
    tabs(){ return tabs },
    // contactSupportTabs(){ return contact_support_tabs },
    // numbersOption(){ return Object.values(number_options) },
    // filterTickets(){
    //   const search = this.filter.tickets?.search?.trim?.()?.toLowerCase?.() ?? ''
    //   const priority = this.filter.tickets?.priority ?? ''
    //   const status = this.filter.tickets?.status ?? ''
    //   return this.response.tickets?.filter?.(item=>`${item.subject}`.trim().toLowerCase().includes(search) && (!priority || item.priority == priority) && (!status || item.status == status)) ?? []
    // },
    // totalTickets(){ return this.response.tickets?.length ?? 0 },
    // openTickets(){ return this.response.tickets?.reduce?.((c,v)=>['Customer-Reply','Open'].includes(v?.status) ? c+1: c,0) ?? 0 },
    // closeTickets(){ return this.response.tickets?.reduce?.((c,v)=>['Closed'].includes(v?.status) ? c+1: c,0) ?? 0 },
  },
  // validations: {
  //   forms: {
  //     call_me: {
  //       problem: {
  //         required,
  //       },
  //       desc: {
  //         required,
  //       },
  //       number: {
  //         required,
  //       },
  //     },
  //   },
  // },
  filters: {
    // priorityType(value){
    //   if(value=='Low') return 'success';
    //   else if(value=='Medium') return 'warning';
    //   else if(value=='High') return 'danger';
    //   else return 'success';
    // },
  },
  methods: {
    onBeforeOpen(event){
      this.data.kb = event?.params?.kb ?? ''
      if(event?.params?.kb_tab){
        this.selected.tab = tabs.knowledge_base.value
      }
      if(!this.data.kb){
        // this.fetchTickets()
      }
      this.fetchKb()
      // this.fetchSMS()
    },
    onBeforeClose(){
      this.forms.reset()
      this.data.reset()
      this.selected.reset()
      this.api.reset()
      this.filter.reset()
      this.response.reset()
      this.conditions.reset()
    },
    // fetchSMS(){
    //   let vm = this
    //   if(vm.api.fetch_sms.send) return;
    //   vm.api.fetch_sms.send=true
    //   VOIP_API.endpoints.users.smslist({
    //     uid: vm.getCurrentUser.uid,
    //     accountcode: vm.getCurrentUser.account,
    //   })
    //   .then((res)=>{
    //     vm.response.sms=res?.data?.data ?? []
    //   })
    //   .catch((ex)=>{
    //     vm.appNotify({
    //       message: ex.own_message,
    //       type: 'error'
    //     })
    //   }).finally(()=>{
    //     vm.api.fetch_sms.send=false
    //   })
    // },
    // fetchTickets(){
    //   let vm = this
    //   if(vm.api.tickets.send) return;
    //   vm.api.tickets.send=true
    //   VOIP_API.endpoints.supportticket.list()
    //   .then((res)=>{
    //     vm.response.tickets=res?.data?.tickets?.ticket ?? []
    //   })
    //   .catch((ex)=>{
    //     vm.appNotify({
    //       message: ex.own_message,
    //       type: 'error'
    //     })
    //   }).finally(()=>{
    //     vm.api.tickets.send=false
    //   })
    // },
    // deletesupportticket(id){
    //   let vm = this
    //   if(vm.api.delete_ticket.send) return;
    //   vm.$appConfirmation({
    //     title: "warning",
    //     message: `Are you sure you want to delete this Support Ticket`,
    //     button: {
    //       no: "No",
    //       yes: "Yes",
    //     },
    //     callback: ({confirm}) => {
    //       if (confirm) {
    //         vm.api.delete_ticket.send=true
    //         VOIP_API.endpoints.supportticket.delete(id)
    //         .then(()=>{
    //           vm.appNotify({
    //             message: 'Successfully Deleted!',
    //             type: 'success',
    //           })
    //           vm.fetchTickets()
    //         })
    //         .catch((ex)=>{
    //           vm.appNotify({
    //             message: ex.own_message,
    //             type: 'error',
    //           })
    //         }).finally(()=>{
    //           vm.api.delete_ticket.send=false
    //         })
    //       }
    //     },
    //   });
    // },
    // callMe(){
    //   let vm = this
    //   vm.forms.call_me.submitted=true
    //   vm.$v.forms.call_me.$touch()
    //   if(vm.$v.forms.call_me.$invalid) return;
    //   if(vm.api.call_me.send) return;
    //   vm.api.call_me.send=true
    //   setTimeout(()=>{
    //     vm.appNotify({
    //       message: 'This feature is in process',
    //       type: 'info',
    //     })
    //     vm.api.call_me.send=false
    //     vm.forms.call_me.submitted=false
    //   }, 2 * 1000)
    // },
    fetchKb(){
      let vm = this
      if(vm.api.kb_link.send) return;
      vm.api.kb_link.send=true
      VOIP_API.endpoints.feed_back.kblink()
      .then(({ data })=>{
        // vm.response.kb_link=data?.data?.find?.(i=>i.label==vm.data.kb)?.link ?? 'https://kb.duplex.network?access_from=dialer'
        vm.response.kb_link=data?.data?.find?.(i=>i.label==vm.data.kb)?.link ?? 'https://www.voipbusiness.com/kb/?access_from=dialer'
        return $fn.sleep(3 * 1000)
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.kb_link.send=false
      })
    },
  },
};
</script>


<style>

</style>
