// SECTION - Pamarly
export const GET_PAMARLY_ORGANIZATION_ID = 'GET_PAMARLY_ORGANIZATION_ID'
export const GET_PAMARLY_TOKEN = 'GET_PAMARLY_TOKEN'

// SECTION - CRM
export const GET_CRM_TOKEN = 'GET_CRM_TOKEN'
export const GET_CRM_USER = 'GET_CRM_USER'
export const GET_CRM_API_URL = 'GET_CRM_API_URL'
export const GET_CRM_IS_CONNECTED = 'GET_CRM_IS_CONNECTED'
export const GET_CRM_PIPELINES = 'GET_CRM_PIPELINES'
export const GET_CRM_PIPELINE_STAGES = 'GET_CRM_PIPELINE_STAGES'
export const GET_CRM_ACTIVITY_TYPES = 'GET_CRM_ACTIVITY_TYPES'
export const GET_CRM_USERS = 'GET_CRM_USERS'

// SECTION - ERRORS
export const GET_API_ERRORS = 'GET_API_ERRORS'
export const GET_STORE_API_ERRORS = 'GET_STORE_API_ERRORS'
// export const GET_CENTRIFUGO_ERRORS = 'GET_CENTRIFUGO_ERRORS'
// export const GET_STORE_CENTRIFUGO_ERRORS = 'GET_STORE_CENTRIFUGO_ERRORS'

// SECTION - Local Setting
export const GET_LOCAL_SETTING_THEME = 'GET_LOCAL_SETTING_THEME'
export const GET_LOCAL_SETTING_SETTINGS = 'GET_LOCAL_SETTING_SETTINGS'
export const GET_LOCAL_SETTING_CHAT = 'GET_LOCAL_SETTING_CHAT'
export const GET_LOCAL_SETTING_NUMBERS = 'GET_LOCAL_SETTING_NUMBERS'
export const GET_LOCAL_SETTING_CALLS = 'GET_LOCAL_SETTING_CALLS'
export const GET_LOCAL_SETTING_ANALYTICS = 'GET_LOCAL_SETTING_ANALYTICS'
export const GET_LOCAL_SETTING_DASHBOARD = 'GET_LOCAL_SETTING_DASHBOARD'
export const GET_LOCAL_SETTING_MOBILE_SETTINGS = 'GET_LOCAL_SETTING_MOBILE_SETTINGS'
export const GET_LOCAL_SETTING_IS_DARK_MODE = 'GET_LOCAL_SETTING_IS_DARK_MODE'