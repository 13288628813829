<template>
  <modal
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"   
    :name="modalName" 
    @before-close="onBeforeClose()" 
    @before-open="onBeforeOpen($event)"
  >
    <template v-if="conditions.screen==screens.edit">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.view">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Edit Pickup Groups</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">

        <b-form @submit.prevent="update()">
          <b-alert :show="!!api.update.error_message" class="alert-danger mt-32px">{{ api.update.error_message }}</b-alert>
          <b-form-group class="mb-0">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Name</label>
                  <b-form-input :disabled="api.update.send || api.delete.send" type="text" placeholder="Enter Name" v-model="forms.update.name" />
                  <p v-if="forms.update.submitted && $v.forms.update.name.$invalid">
                    <span v-if="!$v.forms.update.name.required">*Name is Required</span>
                  </p>
                </div>
              </div>
            </div>
          </b-form-group>
          <b-button class="IosFullWidthButton forDone mb-16px" type="submit" :disabled="api.update.send || api.delete.send">
            <vb-spinner v-if="api.update.send" />
            <template v-else>Submit</template>
          </b-button>
        </b-form>
        

      </div>
    </template>
    <template v-else>
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Edit Pickup Groups</div>
        <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
          <div class="textInsideCard onRightSide primaryColor">Edit</div>
        </div>
      </div>
      <div class="innerModalIos">
        
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item">
            <div class="headingInsideTable">Name</div>
            <div class="textInsideCard onRightSide textAlignRight">{{ data.pickupgroup.name }}</div>
          </div>
        </div>

        <div class="basicWhiteIOScard mb-16px">
          <div class="basicWhiteIOScard-item" @click="$modal.show('SelectUserExtensionModal', { pickupgroup_id: pickupGroupId, })" :disabled="api.delete.send">
            <div class="headingInsideTable">Add/Remove Extension</div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
        </div>
        <div class="basicWhiteIOScard mb-16px">
          <div class="basicWhiteIOScard-item" v-for="data in  response.selected" :key="data.accountcode">
            <div class="d-flex mr-6px align-items-center" >
              <div class="">
                <Info class="InfoComponentInsideAnroidIOSApp" :id="data.accountcode" :name="data.label" :sub_info="data.extn" />
              </div>
            </div>
          </div>
          <vb-no-record v-if="isEmpty(response.selected)" :text="api.members.send ? '' : 'Sorry, No Data Found'" :design="3">
            <!-- <vb-loading class="my-2" :type="'loader'" v-if="api.members.send" slot="loading" /> -->
            <vb-loading class="my-2" v-if="api.members.send" slot="loading" />
          </vb-no-record>
        </div>
        <b-button @click="api.update.send || api.delete.send ? '' : deletePickGroup()" class="IosFullWidthButton forRemove mb-16px" type="submit">
          <template>Delete</template>
        </b-button>

      </div>
    </template>
    <SelectUserExtension @updated="fetchMembers()" />
  </modal>
</template>

<script>
import SelectUserExtension from "./SelectUserExtension.vue";
import Info from "../../../Lists/Info.vue";
import { required } from "vuelidate/lib/validators";
import _ from 'lodash'
import { VOIP_API } from '@/utils/index';
let screens = {
  edit: 'edit',
  view: 'view',
}
export default {
  name: "EditPickUpGroupModal",
  components: {
    SelectUserExtension,
    Info,
  },
  inject: ['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'EditPickUpGroupModal',
    },
  },
  data(){
    return {
      forms: {
        update: this.$helperFunction.formInstance({
          data: {
            name: '',
          },
        }),
      },
      api: {
        update: this.$helperFunction.apiInstance({ error_message: true, }),
        delete: this.$helperFunction.apiInstance({  }),
        members: this.$helperFunction.apiInstance({  }),
      },
      response: {
        available: {},
        selected: {},
      },
      data: {
        pickupgroup: {},
      },
      conditions: {
        screen: screens.view
      },
    }
  },
  computed: {
    pickupGroupId(){ return this.data.pickupgroup?.id },
    screens(){ return screens },
  },
  validations: {
    forms: {
      update: {
        name: {
          required,
        }
      }
    }
  },
  methods: {
    onBeforeOpen(event){
      this.data.pickupgroup = event.params?.pickupgroup ?? {}
      this.forms.update.name = this.data.pickupgroup?.name ?? ''
      this.fetchMembers()
    },
    onBeforeClose(){
      this.api.update.reset()
      this.api.delete.reset()
      this.api.members.reset()
      this.forms.update.reset()
      this.data.pickupgroup={}
      this.response.available={}
      this.response.selected={}
      this.conditions.screen=screens.view
    },
    update(){
      let vm = this
      vm.forms.update.submitted=true
      vm.$v.forms.update.$touch();
      if (vm.$v.forms.update.$invalid || vm.api.update.send) return;
      vm.api.update.send=true
      vm.api.update.error_message=''
      VOIP_API.endpoints.pickupgroup.updatelabel({
        id: vm.pickupGroupId,
        label: vm.forms.update.name
      })
      .then(()=>{
        vm.$emit('load')
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.update.error_message=ex.own_message
      })
      .finally(()=>{
        vm.api.update.send=false
        vm.forms.update.submitted=false
      })
    },
    fetchMembers(){
      let vm = this
      if (vm.api.members.send) return;
      vm.api.members.send=true
      VOIP_API.endpoints.pickupgroup.members(vm.pickupGroupId)
      .then(({ data: { selected, available } })=>{
        vm.response.available = _.isEmpty(available) ? {} : available
        vm.response.selected = _.isEmpty(selected) ? {} : selected
      })
      .finally(()=>{
        vm.api.members.send=false
      })
    },
    deletePickGroup(){
      let vm = this
      if(vm.api.delete.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete.send=true
            VOIP_API.endpoints.pickupgroup.delete(vm.pickupGroupId)
            .then(()=>{
              vm.appNotify({
                message: `Successfully Deleted ${vm.forms.update.name} Pickup Group`,
                type: 'success',
              })
              vm.$emit('load')
              vm.$modal.hide(vm.modalName)
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.delete.send=false
            })
          }
        },
      });
      
    },
  },
}
</script>

<style>

</style>