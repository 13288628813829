<template>
  <modal 
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"   
    :name="modalName" 
    @before-close="onBeforeClose()" 
    @before-open="onBeforeOpen($event)"
  >
    <div v-if="conditions.screen==1" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="api.create.send ? '' : conditions.screen=0">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Add Domains</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Domains</div>
      <div class="plus-IOSIcon-container" @click="conditions.screen=1">
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon"  width="20" height="19.992"/>
      </div>
    </div>

    <div class="innerModalIos">
      
      <template v-if="conditions.screen==1">
        

        <div class="">
          <b-form @submit.prevent="create()" class="dialer-form">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Domain</label>
                  <b-input type="url" placeholder="Enter URL" v-model="forms.create.url" class="w-100" :disabled="api.create.send || api.check.send" />
                  <p 
                    v-if="
                      (forms.create.submitted && $v.forms.create.url.$invalid) || 
                      api.check.validation_errors.domain  || 
                      api.check.validation_errors.root_service_id
                    "  
                    class="mb-1 text-small text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.create.url.required">* URL is required</span>
                    <span v-else-if="!$v.forms.create.url.url">* URL is invalid</span>
                    <span v-for="em in api.check.validation_errors.domain" :key="em">* {{em}}</span>
                    <span v-for="em in api.check.validation_errors.root_service_id" :key="em">* {{em}}</span>
                  </p>
                </div>
              </div>
            </div>
            <button type="button" @click="check()" class="IosFullWidthButton forDone mb-16px" variant="success" :disabled="api.create.send || api.check.send">
              <vb-spinner v-if="api.check.send" />
              <template v-else>Check</template>
            </button>
            <button class="IosFullWidthButton forDone mb-16px" variant="success" :disabled="api.create.send || api.check.send">
              <vb-spinner v-if="api.create.send" />
              <template v-else>Add</template>
            </button>
          </b-form>
        </div>

      </template>

      <template v-else>
        
        

        <div class="basicWhiteIOScard mt-32px mb-16px">
          
          <div v-for="(domain, index) in response.domains" :key="index" class="basicWhiteIOScard-item d-flex justify-content-between" @click="setUpdateId(tag)">
            <div class="headingInsideTable">{{ domain.route }}</div>
            
          </div>
          <vb-no-record v-if="!response.domains.length" :text="api.domains.send?'':'There is no Tags'" :design="3">
            <vb-loading v-if="api.domains.send" class="text-center w-100" slot="loading" />
          </vb-no-record>

        </div>

      </template>

    </div>

  </modal>
</template>

<script>
import { VOIP_API } from "../../../utils";
import { required, url } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';

export default {
  name: "DomainModal",
  inject: ['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'DomainModal',
    },
  },
  data() {
    return {
      conditions: {
        screen: 0,
      },
      response: {
        domains: [],
      },
      api: {
        domains: this.$helperFunction.apiInstance({  }),
        check: this.$helperFunction.apiInstance({ validation_errors: true }),
        create: this.$helperFunction.apiInstance({  }),
      },
      forms: {
        create: this.$helperFunction.formInstance({ 
          data: {
            url: "",
          },
        }),
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
  },
  validations: {
    forms: {
      create: {
        url: {
          required, 
          url,
        },
      },
    },
  },
  methods: {
    onBeforeOpen() {
      this.fetchDomains();
    },
    onBeforeClose() {
      this.forms.create.reset();
      this.api.domains.reset()
      this.api.check.reset()
      this.api.create.reset()
      this.response.domains = []
      this.conditions.screen = 0
    },
    fetchDomains(){
      let vm = this
      if(vm.api.domains.send) return;
      vm.api.domains.send = true;
      VOIP_API.endpoints.domain.list()
      .then(({ data: domains }) => {
        vm.response.domains = domains ?? [];
      })
      .finally(() => {
        vm.api.domains.send = false;
      });
    },
    create() {
      let vm = this;
      vm.forms.create.submitted = true;
      vm.$v.forms.create.$touch();
      if (vm.$v.forms.create.$invalid || vm.api.create.send) return;
      vm.api.create.send = true;
      VOIP_API.endpoints.domain.set({
        domain: vm.forms.create.url,
      })
      .then(() => {
        vm.forms.create.reset();
        vm.$emit("added");
        vm.$modal.hide(vm.modalName);
        vm.fetchDomains()
        vm.appNotify({
          message: "Successfully added!",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.create.send = false;
        vm.forms.create.submitted = false;
      });
    },
    check() {
      let vm = this;
      vm.forms.create.submitted = true;
      vm.$v.forms.create.$touch();
      if (vm.$v.forms.create.$invalid || vm.api.check.send) return;
      vm.api.check.send = true;
      vm.api.check.validation_errors = {};
      VOIP_API.endpoints.domain.check({
        domain: vm.forms.create.url,
      })
      .then(() => {
        vm.appNotify({
          message: `This ${vm.forms.create.url} are available`,
          type: "success",
        })
      })
      .catch((ex) => {
        vm.api.check.validation_errors = ex.own_errors ?? {};
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.check.send = false;
        vm.forms.create.submitted = false;
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.dialer-table {
  .dialer-col-center {
    span {
      .custom-control {
        padding-left: 0px;
      }
    }
  }
}
</style>
