
import { VOIP_API } from "../index"

export const CENTRIFUGE_API_ENDPOINTS = {
  gettoken(data={}){
    return VOIP_API.axios.voip.request({
      url: `centrifugo-token`,
      method: 'POST',
      data
    })
  },
  handledesktopevent(data={}){
    return VOIP_API.axios.voip_relay.request({
      url: `handle-desktop-event`,
      method: 'POST',
      data
    })
  },
}