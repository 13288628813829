<template>
  <div class="actions-info-container">
    <template v-if="is_sip">
      <div class="active-caller-box py-3 w-100">
        <div class="active-caller">
          <vb-avatar :image="callObjInfo.image" />
          <div class="active-caller-info">
            <div class="d-flex">
              <h6>{{ callObjInfo.name | number_formater }} {{ callObjInfo.sub_info | number_formater }}</h6>
            </div>
            <!-- <div v-if="activeCall.direction == 'out'" class="d-flex">
              <h6>{{ callObjInfo.to_name }} {{ callObjInfo.to_number | number_formater }}</h6>
            </div>
            <div v-else class="d-flex">
              <h6>{{ callObjInfo.from_name }} {{ callObjInfo.from_number | number_formater }}</h6>
            </div> -->
            <p class="mb-0">{{ data.time | incrementFilter }}</p>
          </div>
        </div>
        <div class="callsHoldButton">
          <span class="dialer-button  text-capitalize py-1 px-3" :class="isActiveCall?'dialer-button-primary':'dialer-button-secondary'">
            {{ isActiveCall ? "Active" : "On Hold" }}
          </span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="active-caller-box py-3 w-100">
        <div class="active-caller">
          
          <vb-avatar  :image="callObjInfo.image" />
          <div class="active-caller-info">
            <div class="d-flex">
              <h6>{{ callObjInfo.name }}</h6>
            </div>
            <p class="mb-0">{{ callObjInfo.sub_info }}</p>
            <p class="mb-0">{{ data.time | time_audio }}</p>
            <template v-if="$store.state.calls.outgoing_calls[data.room]">
              <div class="d-flex align-items-center" v-if="data.outgoing && data.outgoing.time" >
                <p class="m-0 pr-3"> Time Left: {{ data.outgoing.time | time_audio }} </p>
              </div>
              <div class="d-flex align-items-center" v-if="data.outgoing && data.outgoing.status" >
                <p class="m-0 pr-3">Status: {{ data.outgoing.status }}</p>
              </div>
            </template>
          </div>
        </div>
        <div class="callsHoldButton">
          <span class="dialer-button  text-capitalize py-1 px-3" :class="isActiveCall?'dialer-button-primary':'dialer-button-secondary'">
            {{ isActiveCall ? "Active" : "On Hold" }}
          </span>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
// import { events } from '../../../utils';
import { call_info } from '@/mixin';
export default {
  name: 'SwitchCallListItem',
  mixins: [
    call_info
  ],
  props: {
    data: {
      type: Object,
      default: ()=>({}),
    },
    is_sip: {
      type: Boolean,
      default: false,
    },
    activeCall: {
      type: Object,
    },
  },
  data() {
    return {
      user_male: require("@/assets/images/user-male.png"),
    };
  },
  computed: {
    
    callObj(){ return this.data },
    isActiveCall(){
      const identity = this.data.room || this.data.uuid
      const active_identity = this.activeCall.room || this.activeCall.uuid
      return active_identity==identity
    },
  },
  filters: {
    incrementFilter(value) {
      if (!value) {
        return null;
      }
      if (value.substring(0, 2) == "00") {
        return value.substring(3);
      }
      // console.log(value.substring(0,2))
      return value;
    },
  },
  methods: {
    // holdActiveSession() {
    //   this.$root.$emit(events.hold_audio_call);
    // },
    // onSwitch(){
    //   this.phones.markSessionActive(this.data.uuid)
    //   this.$root.$emit(events.hold_video_call)
    // },
  },

}
</script>

<style lang="scss" scoped>
.actions-info-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  position: relative;
  color: var(--white);
  .actions {
    display: flex;
    position: relative;
    div {
      border: currentColor solid 1px;
      padding: 3px;
      border-radius: 50%;
      height: 40px;
      width: 40px;
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 5px;
      cursor: pointer;

      div.volume {
        border: 0;
        border-radius: 5%;
        padding: 20px;
        background-color: var(--white);
        width: 250px;
      }
      &.volume-range {
        width: 140px;
        border-radius: 0px;
        background-color: #000;
        z-index: 11;
        top: 50px;
        position: absolute;
        right: 50px;
      }
    }
    div[disabled] {
      pointer-events: none;
      cursor: no-drop;
      color: #adadba;
    }

    .hangout {
      background-color: var(--danger);
      color: var(--white);
      border: 0;
    }
    .cross {
      background-color: #b5b5c3;
      color: var(--white);
      border: 0;
    }
  }
  .active-caller-box {
    display: flex;
    justify-content: space-between;
    align-items: center;
    .active-caller {
      display: flex;
      align-items: center;
      .active-caller-photo {
        img {
          border-radius: 50%;
        }
      }
      .active-caller-info {
        margin-left: 15px;
        h6 {
          text-transform: capitalize;
          margin-bottom: 0px;
          color: $body-color;
        }
        p {
          color: $gray-600;
          font-size: 14px;
        }
      }
    }
    .callsHoldButton {
      .dialer-button {
        color: $white;
        cursor: auto;
        &.dialer-button-secondary {
          background-color: $gray-600;
        }
      }
    }
  }
}
</style>
