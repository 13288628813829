var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.invoice_setting.send ? '' : _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Invoice Setting")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateInvoiceSetting();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.invoice_setting.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.invoice_setting.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Company Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.invoice_setting.companyname,
      expression: "forms.invoice_setting.companyname"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.invoice_setting.send
    },
    domProps: {
      "value": _vm.forms.invoice_setting.companyname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.invoice_setting, "companyname", $event.target.value);
      }
    }
  }), _vm.forms.invoice_setting.submitted && _vm.$v.forms.invoice_setting.companyname.$invalid || _vm.api.invoice_setting.validation_errors.companyname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.invoice_setting.companyname.required ? _c('span', [_vm._v("* Company Name field is required")]) : _vm._e(), _vm._l(_vm.api.invoice_setting.validation_errors.companyname, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("First Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.invoice_setting.firstname,
      expression: "forms.invoice_setting.firstname"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.invoice_setting.send
    },
    domProps: {
      "value": _vm.forms.invoice_setting.firstname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.invoice_setting, "firstname", $event.target.value);
      }
    }
  }), _vm.forms.invoice_setting.submitted && _vm.$v.forms.invoice_setting.firstname.$invalid || _vm.api.invoice_setting.validation_errors.firstname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.invoice_setting.firstname.required ? _c('span', [_vm._v("* First Name field is required")]) : _vm._e(), _vm._l(_vm.api.invoice_setting.validation_errors.firstname, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Last Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.invoice_setting.lastname,
      expression: "forms.invoice_setting.lastname"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.invoice_setting.send
    },
    domProps: {
      "value": _vm.forms.invoice_setting.lastname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.invoice_setting, "lastname", $event.target.value);
      }
    }
  }), _vm.forms.invoice_setting.submitted && _vm.$v.forms.invoice_setting.lastname.$invalid || _vm.api.invoice_setting.validation_errors.lastname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.invoice_setting.lastname.required ? _c('span', [_vm._v("* Last Name field is required")]) : _vm._e(), _vm._l(_vm.api.invoice_setting.validation_errors.lastname, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Address")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.invoice_setting.address,
      expression: "forms.invoice_setting.address"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.invoice_setting.send
    },
    domProps: {
      "value": _vm.forms.invoice_setting.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.invoice_setting, "address", $event.target.value);
      }
    }
  }), _vm.forms.invoice_setting.submitted && _vm.$v.forms.invoice_setting.address.$invalid || _vm.api.invoice_setting.validation_errors.address ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.invoice_setting.address.required ? _c('span', [_vm._v("* Address field is required")]) : _vm._e(), _vm._l(_vm.api.invoice_setting.validation_errors.address, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Town/City")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.invoice_setting.city,
      expression: "forms.invoice_setting.city"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.invoice_setting.send
    },
    domProps: {
      "value": _vm.forms.invoice_setting.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.invoice_setting, "city", $event.target.value);
      }
    }
  }), _vm.forms.invoice_setting.submitted && _vm.$v.forms.invoice_setting.city.$invalid || _vm.api.invoice_setting.validation_errors.city ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.invoice_setting.city.required ? _c('span', [_vm._v("* Town/City field is required")]) : _vm._e(), _vm._l(_vm.api.invoice_setting.validation_errors.city, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Postcode")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.invoice_setting.postcode,
      expression: "forms.invoice_setting.postcode"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.invoice_setting.send
    },
    domProps: {
      "value": _vm.forms.invoice_setting.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.invoice_setting, "postcode", $event.target.value);
      }
    }
  }), _vm.forms.invoice_setting.submitted && _vm.$v.forms.invoice_setting.postcode.$invalid || _vm.api.invoice_setting.validation_errors.postcode ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.invoice_setting.postcode.required ? _c('span', [_vm._v("* Postcode field is required")]) : _vm._e(), _vm._l(_vm.api.invoice_setting.validation_errors.postcode, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Country")]), _c('b-form-select', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.invoice_setting.send,
      "options": _vm.countryOptions
    },
    model: {
      value: _vm.forms.invoice_setting.country,
      callback: function ($$v) {
        _vm.$set(_vm.forms.invoice_setting, "country", $$v);
      },
      expression: "forms.invoice_setting.country"
    }
  }), _vm.forms.invoice_setting.submitted && _vm.$v.forms.invoice_setting.country.$invalid || _vm.api.invoice_setting.validation_errors.country ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.invoice_setting.country.required ? _c('span', [_vm._v("* Country field is required")]) : _vm._e(), _vm._l(_vm.api.invoice_setting.validation_errors.country, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.invoice_setting.email,
      expression: "forms.invoice_setting.email"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.invoice_setting.send
    },
    domProps: {
      "value": _vm.forms.invoice_setting.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.invoice_setting, "email", $event.target.value);
      }
    }
  }), _vm.forms.invoice_setting.submitted && _vm.$v.forms.invoice_setting.email.$invalid || _vm.api.invoice_setting.validation_errors.email ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.invoice_setting.email.required ? _c('span', [_vm._v("* Email field is required")]) : _vm._e(), _vm._l(_vm.api.invoice_setting.validation_errors.email, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.invoice_setting.send
    }
  }, [_vm.api.invoice_setting.send ? _c('vb-spinner') : [_vm._v("Update")]], 2)], 1)])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Invoice Setting")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("First Name")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.invoice_setting.firstname))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Last Name")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.invoice_setting.lastname))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Company Name")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.invoice_setting.companyname))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Address")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.invoice_setting.address))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("City")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.invoice_setting.city))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Country")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.invoice_setting.country))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Email")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.invoice_setting.email))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Post Code")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.invoice_setting.postcode))])])])])])]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }