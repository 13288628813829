<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup AddNewBillingCard AddNewTicket" 
      width="40%" 
      height="auto" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()" 
      :scrollable="true" 
      :name="modalName" 
      :clickToClose="false"
    >
      <section class="ct-user-new dialer-edit">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="createTicket()">
          <div class="dialer-edit-header mt-3 mb-5">
            <h2 class="dialer-settings-title newer mb-0">Add new ticket</h2>
            <div class="dialer-edit-actions">
              <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="api.create_ticket.send ? '' : $modal.hide(modalName)" />
              <a v-else class="newCloseButton" @click="api.create_ticket.send ? '' : $modal.hide(modalName)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          <div class="md-mar-vertical">
            <b-alert :show="!!api.create_ticket.error_message" variant="danger">{{ api.create_ticket.error_message }}</b-alert>
            <div class="latestGreyBox-9-9-2023">
              <div class="d-flex mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder w-50 pr-2">
                  <label>Subject <span style="color:red;">*</span></label>
                  <input 
                    class="w-100" 
                    type="text" 
                    v-model="forms.create_ticket.subject" 
                    :maxlength="$v.forms.create_ticket.subject.$params.maxLength.max" 
                    :disabled="api.create_ticket.send" 
                  />
                  <p 
                    v-if="(forms.create_ticket.submitted && $v.forms.create_ticket.subject.$invalid) || api.create_ticket.validation_errors.subject" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.create_ticket.subject.required">* subject is required</span>
                    <span v-else-if="!$v.forms.create_ticket.subject.minLength">* subject should be minimum {{$v.forms.create_ticket.subject.$params.minLength.min}} character</span>
                    <span v-else-if="!$v.forms.create_ticket.subject.maxLength">* subject can be maximum {{$v.forms.create_ticket.subject.$params.maxLength.max}} character</span>
                    <template v-else-if="api.create_ticket.validation_errors.subject">
                      <span v-for="(em, i) in api.create_ticket.validation_errors.subject" :key="i">{{em}}</span>
                    </template>
                  </p>
                </div>
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2">
                  <label>Department</label>
                  <vb-select 
                    v-model="forms.create_ticket.department_id" 
                    :valueField="'id'"
                    :textField="'name'"
                    :defaultSelectedText="'Select department'" 
                    :options="departments" 
                    :disabled="api.create_ticket.send" 
                  />
                  <p 
                    v-if="api.create_ticket.validation_errors.department_id" 
                    class="text text-danger animated bounceIntop"
                  >
                    <template v-if="api.create_ticket.validation_errors.department_id">
                      <span v-for="(em, i) in api.create_ticket.validation_errors.department_id" :key="i">{{em}}</span>
                    </template>
                  </p>
                </div>
              </div>
              <div class="d-flex mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2">
                  <label>Category</label>
                  <vb-select 
                    v-model="forms.create_ticket.category_id" 
                    :valueField="'id'"
                    :textField="'name'"
                    :defaultSelectedText="'Select category'" 
                    :options="categories" 
                    :disabled="api.create_ticket.send" 
                  />
                  <p 
                    v-if="api.create_ticket.validation_errors.category_id" 
                    class="text text-danger animated bounceIntop"
                  >
                    <template v-if="api.create_ticket.validation_errors.category_id">
                      <span v-for="(em, i) in api.create_ticket.validation_errors.category_id" :key="i">{{em}}</span>
                    </template>
                  </p>
                </div>
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2">
                  <label>Type</label>
                  <vb-select 
                    v-model="forms.create_ticket.type_id" 
                    :valueField="'id'"
                    :textField="'name'"
                    :defaultSelectedText="'Select type'" 
                    :options="types" 
                    :disabled="api.create_ticket.send" 
                  />
                  <p 
                    v-if="api.create_ticket.validation_errors.type_id" 
                    class="text text-danger animated bounceIntop"
                  >
                    <template v-if="api.create_ticket.validation_errors.type_id">
                      <span v-for="(em, i) in api.create_ticket.validation_errors.type_id" :key="i">{{em}}</span>
                    </template>
                  </p>
                </div>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder w-100 mt-20px">
                <label>Message <span style="color:red;">*</span></label>
                <form class="w-100" @submit.prevent="''">
                    <!-- :maxlength="$v.forms.create_ticket.details.$params.maxLength.max" -->
                  <textarea 
                    placeholder="Write your message" 
                    rows="11" 
                    class="ticket-textArea-text w-suto p-3"
                    style="height: auto;width: -webkit-fill-available;" 
                    :disabled="api.create_ticket.send" 
                    v-model="forms.create_ticket.details" 
                  >
                    Hi, hope you are doing great today. Please check the assigned tasks. 
                    Thanks
                  </textarea>
                </form>
                <p 
                  v-if="(forms.create_ticket.submitted && $v.forms.create_ticket.details.$invalid) || api.create_ticket.validation_errors.details" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.create_ticket.details.required">* Message is required</span>
                  <span v-else-if="!$v.forms.create_ticket.details.minLength">* Message should be minimum {{$v.forms.create_ticket.details.$params.minLength.min}} character</span>
                  <!-- <span v-else-if="!$v.forms.create_ticket.details.maxLength">* Message can be maximum {{$v.forms.create_ticket.details.$params.maxLength.max}} character</span> -->
                  <template v-else-if="api.create_ticket.validation_errors.details">
                    <span v-for="(em, i) in api.create_ticket.validation_errors.details" :key="i">{{em}}</span>
                  </template>
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-4">
            <button :disabled="api.create_ticket.send" class="newDoneButton">
              <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
              <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                <vb-spinner v-if="api.create_ticket.send" />
                <template v-else>Submit</template>
              </span>
            </button>
            <button type="button" :disabled="api.create_ticket.send" class="dialer-button dialer-button-transparent ml-3" @click="$modal.hide(modalName)">Cancel</button>
          </div>
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import required from "vuelidate/lib/validators/required";
import minLength from "vuelidate/lib/validators/minLength";
import maxLength from "vuelidate/lib/validators/maxLength";
import { VOIP_API } from '@/utils/index';
import { mapGetters } from 'vuex';

export default {
  name: "AddSupportTicketModal",
  props: {
    modalName: {
      type: String,
      default: 'AddSupportTicketModal',
    },
    categories: {
      type: Array,
      default: ()=>[]
    },
    departments: {
      type: Array,
      default: ()=>[]
    },
    types: {
      type: Array,
      default: ()=>[]
    },
  },
  data() {
    return {
      forms: {
        create_ticket: this.$helperFunction.formInstance({
          data: {
            subject: '',
            details: '',
            department_id: '',
            category_id: '',
            type_id: '',
          }
        }),
      },
      api: {
        create_ticket: this.$helperFunction.apiInstance({
          validation_errors: true,
          error_message: true,
        }),
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser',
    ]),
  },
  validations: {
    forms: {
      create_ticket: {
        subject: {
          required: required,
          minLength: minLength(3),
          maxLength: maxLength(60),
        },
        details: {
          required: required,
          minLength: minLength(3),
          // maxLength: maxLength(255),
        },
        department_id: {
          
        },
        category_id: {
          
        },
        type_id: {
          
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      // TODO - onBeforeOpen
    },
    onBeforeClose(){
      this.forms.create_ticket.reset()
      this.api.create_ticket.reset()
    },
    async createTicket(){
      this.forms.create_ticket.submitted=true
      this.$v.forms.create_ticket.$touch()
      if(this.api.create_ticket.send) return;
      if(this.$v.forms.create_ticket.$invalid) return;
      try {
        this.api.create_ticket.send=true
        this.api.create_ticket.error_message=''
        this.api.create_ticket.validation_errors={}
        /*const { data } =*/ await VOIP_API.endpoints.tickets.create({
          subject: this.forms.create_ticket.subject,
          details: this.forms.create_ticket.details,
          department_id: this.forms.create_ticket.department_id,
          category_id: this.forms.create_ticket.category_id,
          type_id: this.forms.create_ticket.type_id,
        })
        // console.log(data)
        this.appNotify({
          message: 'Successfully Created!',
          type: 'success'
        })
        this.$modal.hide(this.modalName)
        this.$emit('created')
      } catch (ex) {
        this.api.create_ticket.error_message=ex.own_message
        this.api.create_ticket.validation_errors=ex.own_errors
      } finally {
        this.api.create_ticket.send=false
        this.forms.create_ticket.submitted=false
      }
    },
  },
};
</script>
