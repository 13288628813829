var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.select_country ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Country")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.response.countries, function (country) {
    return _c('div', {
      key: country.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.purchase_number.country = country.value;
          _vm.isUS ? _vm.fetchRegions() : '';
          _vm.fetchNumbers();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(country.label))]), _vm.forms.purchase_number.country == country.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.response.countries.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.countries.send ? '' : 'There is no Country',
      "design": 3
    }
  }, [_vm.api.countries.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])])] : _vm.conditions.screen == _vm.screens.select_number_type ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Type Of Number")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.numberTypes, function (number_type) {
    return _c('div', {
      key: number_type.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.purchase_number.number_type = number_type.value;
          _vm.forms.purchase_number.city = '';
          _vm.response.cities = [];
          _vm.isUKC ? _vm.fetchCities() : _vm.fetchNumbers();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(number_type.label))]), _vm.forms.purchase_number.number_type == number_type.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.numberTypes.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Type Of Number',
      "design": 3
    }
  }) : _vm._e()], 2)])])] : _vm.conditions.screen == _vm.screens.select_city ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select City")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.response.cities, function (city) {
    return _c('div', {
      key: city.citycode,
      staticClass: "basicWhiteIOScard-item",
      attrs: {
        "value": city.citycode
      },
      on: {
        "click": function ($event) {
          _vm.forms.purchase_number.city = city.citycode;
          _vm.fetchNumbers();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(city.city))]), _vm.forms.purchase_number.city == city.citycode ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.response.cities.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.cities.send ? '' : 'There is no Country',
      "design": 3
    }
  }, [_vm.api.cities.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])])] : _vm.conditions.screen == _vm.screens.select_region ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Region")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.response.regions, function (region) {
    return _c('div', {
      key: region.id,
      staticClass: "basicWhiteIOScard-item",
      attrs: {
        "value": region.code
      },
      on: {
        "click": function ($event) {
          _vm.forms.purchase_number.region = region.code;
          _vm.forms.purchase_number.group = '';
          _vm.response.groups = [];
          _vm.fetchGroups();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(region.label))]), _vm.forms.purchase_number.region == region.code ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.response.regions.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.regions.send ? '' : 'There is no Country',
      "design": 3
    }
  }, [_vm.api.regions.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])])] : _vm.conditions.screen == _vm.screens.select_group ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Location")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.response.groups, function (group) {
    return _c('div', {
      key: group.id,
      staticClass: "basicWhiteIOScard-item",
      attrs: {
        "value": group.group_id
      },
      on: {
        "click": function ($event) {
          _vm.forms.purchase_number.group = group.group_id;
          _vm.fetchNumbers();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(group.label))]), _vm.forms.purchase_number.group == group.group_id ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.response.groups.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.groups.send ? '' : 'There is no Country',
      "design": 3
    }
  }, [_vm.api.groups.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])])] : _vm.conditions.screen == _vm.screens.select_category ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Number Type")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.numberCategories, function (category) {
    return _c('div', {
      key: category.value,
      staticClass: "basicWhiteIOScard-item",
      attrs: {
        "value": category.value
      },
      on: {
        "click": function ($event) {
          _vm.forms.purchase_number.category = category.value;
          _vm.fetchNumbers();
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(category.label))]), _vm.forms.purchase_number.category == category.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.numberCategories.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Country',
      "design": 3
    }
  }) : _vm._e()], 2)])])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Purchase Number")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CheckedSlot', {
          service: 'aql'
        });
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Detail")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_country;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Country")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedCountry, 'label')))])]), _vm.isUK ? [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_number_type;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Number Type")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedNumberType, 'label')))])]), _vm.isUKC ? _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_city;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("City")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedCity, 'city')))])]) : _vm._e()] : _vm.isUS ? [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_region;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Region")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedRegion, 'label')))])]), !!_vm.forms.purchase_number.region ? _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_group;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Location")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedGroup, 'label')))])]) : _vm._e()] : [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_category;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Number Type")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedCategory, 'label')))])])]], 2), _c('div', {
    class: `PurchaseNumberList mt-16px mb-16px ${_vm.response.numbers.length == 0 ? 'bg-white p-2' : ''}`
  }, [_vm._l(_vm.response.numbers, function (number) {
    return _c('div', {
      key: number.msisdn || number.number,
      staticClass: "PurchaseNumberList-item",
      class: `${_vm.forms.purchase_number.number == (number.msisdn || number.number) ? 'selected' : ''} `,
      on: {
        "click": function ($event) {
          _vm.api.purchase_number.send ? '' : _vm.forms.purchase_number.number = number.msisdn || number.number;
          _vm.isUS ? _vm.purchaseNumber() : _vm.$modal.show('SelectLocation', {
            number: number.msisdn || number.number,
            address_required: number.address_required
          });
        }
      }
    }, [_vm._v(" " + _vm._s(number.msisdn || number.number) + " "), _vm.forms.purchase_number.number == (number.msisdn || number.number) ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.response.numbers.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.numbers.send ? '' : 'There is no Number',
      "design": 3
    }
  }, [_vm.api.numbers.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)]), _c('SelectLocationModal', {
    on: {
      "select": function ($event) {
        _vm.forms.purchase_number.address_id = $event.location.real_id;
        _vm.purchaseNumber();
      }
    }
  })], 1)]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }