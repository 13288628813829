var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "opened": function ($event) {
        return _vm.onOpen();
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == 3 ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 2;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Call Type")])]) : _vm.conditions.screen == 2 ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 0;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Calls")])]) : _vm.conditions.screen == 1 ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 0;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Date")])]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Users Analytics")])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_vm.conditions.screen == 3 ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.calltypes, function (calltype) {
    return _c('div', {
      key: calltype.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.filter.stat.call_type = calltype.value;
          _vm.conditions.screen = 2;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(calltype.text))])])]), _vm.filter.stat.call_type == calltype.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)] : _vm.conditions.screen == 2 ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 3;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Call Type")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight text-capitalize onRightSide mr-8px"
  }, [_vm._v(_vm._s(_vm.selectedCallType.value))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', [_vm._l(_vm.filterStat, function (call, key) {
    return _c('div', {
      key: key,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "basicIOSIcon mr-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": _vm._f("callIcon")(call, _vm.getCurrentUser.account),
        "height": "13.922",
        "width": "13.922"
      }
    })], 1), _c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("name")(call)))])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard textAlignRight text-capitalize onRightSide"
    }, [_vm._v(" " + _vm._s(_vm._f("formatDate")(call.calldate)) + " " + _vm._s(_vm._f("timecode")(call.duration)) + " ")])])]);
  }), _vm.isEmpty(_vm.filterStat) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.stat.send ? '' : 'There is no Call Logs',
      "design": 3
    }
  }, [_vm.api.stat.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])] : _vm.conditions.screen == 1 ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
          !_vm.isCustom ? _vm.fetchstat() : '';
          !_vm.isCustom ? _vm.conditions.screen = 0 : '';
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(tab.text))])])]), _vm.selected.tab === tab.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0), _vm.isCustom ? [_vm.isCustom ? _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2 forDateTime"
  }, [_c('label', [_vm._v("From")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "starttime"
    },
    model: {
      value: _vm.filter.stat.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.filter.stat, "start_date", $$v);
      },
      expression: "filter.stat.start_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2 forDateTime"
  }, [_c('label', [_vm._v("To")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "endtime"
    },
    model: {
      value: _vm.filter.stat.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.filter.stat, "end_date", $$v);
      },
      expression: "filter.stat.end_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)])]) : _vm._e(), _c('a', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    on: {
      "click": function ($event) {
        _vm.fetchstat();
        _vm.conditions.screen = 0;
      }
    }
  }, [_vm._v("Fetch")])] : _vm._e()] : [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 1;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Date")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-8px"
  }, [_vm._v(_vm._s(_vm.selectedTab.text))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 2;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Call logs")])])]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Success RATE")])])]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.response.stat.successRate) + " %")])])]), _c('div', [_c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon circularProgressBar mr-16px"
  }, [_c('vb-circular-progressive-bar', {
    attrs: {
      "percentage": 100
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Total Calls")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow mr-16px",
    attrs: {
      "icon": "CallSummary-TotalCall-icon",
      "width": "16.642",
      "height": "15.275"
    }
  }), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.callpercentage.total))])], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item dialer-missed"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon circularProgressBar mr-16px"
  }, [_c('vb-circular-progressive-bar', {
    attrs: {
      "percentage": _vm.callpercentage.missed_percentage
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Missed Calls")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow mr-16px",
    attrs: {
      "icon": "CallSummary-MissedCall-icon",
      "width": "16.642",
      "height": "15.275"
    }
  }), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.callpercentage.missed))])], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item dialer-inbound"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon circularProgressBar mr-16px"
  }, [_c('vb-circular-progressive-bar', {
    attrs: {
      "percentage": _vm.callpercentage.incomming_percentage
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Inbound Calls")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow mr-16px",
    attrs: {
      "icon": "CallSummary-InboundCall-icon",
      "width": "16.642",
      "height": "15.275"
    }
  }), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.callpercentage.incomming))])], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item dialer-outbound"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon circularProgressBar mr-16px"
  }, [_c('vb-circular-progressive-bar', {
    attrs: {
      "percentage": _vm.callpercentage.outgoing_percentage
    }
  })], 1), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Outbound Calls")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow mr-16px",
    attrs: {
      "icon": "CallSummary-OutboundCall-icon",
      "width": "16.642",
      "height": "15.275"
    }
  }), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.callpercentage.outgoing))])], 1)])])]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "visavail mt-3",
    staticStyle: {
      "width": "100%",
      "overflow": "hidden"
    },
    attrs: {
      "id": `conatiner-measure-${_vm.data.time_line.measure}`
    }
  }, [_c('p', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "id": `p-measure-${_vm.data.time_line.measure}`
    }
  })])])])]], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }