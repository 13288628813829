var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Business hours")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Set Business hours")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "switch_ooh",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.update_out_of_hours.send,
      "name": "check-button",
      "switch": "",
      "checked": _vm.response.out_of_hours
    },
    on: {
      "change": function ($event) {
        return _vm.updateOutOfHours($event);
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_vm.response.out_of_hours ? [_vm.conditions.default ? _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.randomID}CallForwardSettingOutOfHourModal`, {
          account: _vm.data.account,
          setting: _vm.forwardSetting,
          is_sec_show: false
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Default Setting")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm.forwardSetting.forward_note == 'voicemail' ? [_vm._v("Voicemail")] : _vm.forwardSetting.forward_note == 'extension' ? [_vm._v("Redirect to " + _vm._s(_vm.forwardSetting.extension_name || _vm.forwardSetting.forward_number))] : _vm.forwardSetting.forward_note == 'number' ? [_vm._v("Redirect to " + _vm._s(_vm.forwardSetting.forward_number))] : [_vm._v("Keep Ringing")]], 2), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]) : _vm._e(), _vm.conditions.days ? _vm._l(_vm.weekDays, function (day) {
    return _c('div', {
      key: day.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          return _vm.$modal.show(`${_vm.randomID}-EditOutOfHoursDayModal`, {
            day: day.value
          });
        }
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v(_vm._s(day.text))]), _c('div', {
      staticClass: "textInsideCard font-10px"
    }, [_vm._v("Custom")])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "d-flex flex-column mr-8px text-right"
    }, [_c('div', {
      staticClass: "textInsideCard text-capitalize",
      class: `${_vm.days[day.value].type != 'availble' ? 'redColor' : 'greenColor'}`
    }, [_vm._v(_vm._s(_vm._f("filterTypeText")(_vm.days[day.value])))]), _c('div', {
      staticClass: "textInsideCard font-10px"
    }, [_vm._v(_vm._s(_vm._f("filterInfoText")(_vm._f("get_property")(_vm.days, `${day.value}`))))])]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)]);
  }) : _vm._e()] : _vm._e()], 2)]), _c('EditOutOfHoursDayModal', {
    attrs: {
      "modalName": `${_vm.randomID}-EditOutOfHoursDayModal`,
      "account": _vm.data.account,
      "schedules": _vm.response.schedules,
      "randomID": _vm.randomID
    },
    on: {
      "updated": function ($event) {
        _vm.response.schedules = $event;
      }
    }
  }), _c('CallForwardingModal', {
    attrs: {
      "modalName": `${_vm.randomID}CallForwardSettingOutOfHourModal`,
      "randomID": _vm.randomID
    },
    on: {
      "updated": function ($event) {
        return _vm.updateDefaultSetting($event);
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }