
import { VOIP_API } from "../index"

export const PAMARLY_API_ENDPOINTS = {
  auth: {
    login(data={}){
      return VOIP_API.axios.pamarly.request({
        url: `login`,
        method: 'POST',
        data
      })
    },
  },
  users: {
    list(organization_id=''){
      return VOIP_API.axios.pamarly.request({
        url: `user/organizations/${organization_id}`,
        method: 'GET',
      })
    },
  },
  history: {
    logs(params) {
      return VOIP_API.axios.pamarly.request({
        url: `logs`,
        method: "GET",
        params,
      });
    },
  },
  download: {
    logs(params) {
      return VOIP_API.axios.pamarly.request({
        url: `logs/download`,
        method: "GET",

        params,
      });
    },
  },
  teams: {
    list(organization_id=''){
      return VOIP_API.axios.pamarly.request({
        url: `user/organizations/teams/${organization_id}`,
        method: 'GET',
      })
    },
  },
  resources: {
    list(params){
      return VOIP_API.axios.pamarly.request({
        url: `resource`,
        method: 'GET',
        params,
      })
    },
    deletedresources(params) {
      return VOIP_API.axios.pamarly.request({
        url: `deleted/groups`,
        method: "GET",
        params,
      });
    },
    groupslist(organization_id=''){
      return VOIP_API.axios.pamarly.request({
        url: `groups/resources/${organization_id}`,
        method: 'GET',
      })
    },
    mainGroups(params){
      return VOIP_API.axios.pamarly.request({
        url: `resource/parent/group`,
        method: 'GET',
        params,
      })
    },
    subGroups(group_id=''){
      return VOIP_API.axios.pamarly.request({
        url: `resource/group/${group_id}`,
        method: 'GET',
      })
    },
    groupResources(params){
      return VOIP_API.axios.pamarly.request({
        url: `resource/get`,
        method: 'GET',
        params,
      })
    },
    allGroupsTree(params){
      return VOIP_API.axios.pamarly.request({
        url: `subgroups`,
        method: 'GET',
        params,
      })
    },
  },
  permissions: {
    list(organization_id=''){
      return VOIP_API.axios.pamarly.request({
        url: `get/user/${organization_id}/all`,
        method: 'GET',
      })
    },
  },
}