var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 MOHPlaylistFilesModal min-width-50percent-modal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      },
      "closed": function ($event) {
        return _vm.onClosed($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to dialer-edit"
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center mb-2"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v(_vm._s(_vm.forms.update_label.label))]), _vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        _vm.api.delete_playlist.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1)]) : _c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between flex-fill"
  }, [_vm.api.playlist.send && !_vm.playlist ? [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "32px",
      "width": "50%"
    }
  })] : _c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v(_vm._s(_vm.forms.update_label.label))]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.delete_playlist.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])], 2), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Below is a list of all the audio files within this playlist. You can modify the order, add, or remove music files. ")])]), _vm.api.playlist.send && !_vm.playlist ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "27px",
      "width": "calc(25% - 16px)"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-2",
    staticStyle: {
      "height": "50px",
      "width": "50%"
    }
  })]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(2, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row py-3 justify-content-start"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-4",
      staticStyle: {
        "height": "27px",
        "width": "calc(25% - 16px)"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "64px",
        "width": "50%"
      }
    })]);
  }), 0)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mr-4",
    staticStyle: {
      "height": "27px",
      "width": "calc(25% - 16px)"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "30px"
    }
  })])])])] : [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v(" " + _vm._s(_vm.conditions.change_order ? 'Change order' : 'Sound files') + " ")]), _vm.conditions.change_order ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" You can change the order of selected sound file by dragging the sound file name in desired order ")]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-between align-items-end mt-20px customMOHeditButtonsRow"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder wd-33-imp position-relative"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_label.label,
      expression: "forms.update_label.label"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.delete_playlist.send || _vm.api.update_label.send || _vm.isSystem || _vm.conditions.change_order,
      "placeholder": 'Enter Name',
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.update_label.label
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_label, "label", $event.target.value);
      }, function ($event) {
        return _vm.updatePlaylistLabel();
      }]
    }
  }), _vm.forms.update_label.submitted && _vm.$v.forms.update_label.label.$invalid || _vm.api.update_label.validation_errors.text ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_label.label.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), _vm._l(_vm.api.update_label.validation_errors.text, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "d-flex"
  }, [_vm.conditions.change_order ? _c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.delete_playlist.send || _vm.api.change_order.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.change_order = !_vm.conditions.change_order;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1) : [!_vm.isSystem && _vm.selectedFiles.length != 0 ? _c('button', {
    staticClass: "newCloseButton mr-12px",
    class: {
      'chnaging-order': _vm.conditions.change_order
    },
    attrs: {
      "disabled": _vm.api.delete_playlist.send || _vm.api.change_order.send
    },
    on: {
      "click": function ($event) {
        _vm.conditions.change_order = !_vm.conditions.change_order;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Change order")])], 1) : _vm._e()], !_vm.isSystem && !_vm.conditions.change_order ? _c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": _vm.api.delete_playlist.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.modalName}-Sounds`, {
          id: _vm.data.playlist_id,
          account: _vm.data.accountcode
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Add/Remove music")])], 1) : _vm._e()], 2)]), _vm.selectedFiles.length == 0 ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mb-32px"
  }, [_c('div', {
    staticClass: "emptyScreenContainer"
  }, [_c('img', {
    staticClass: "mt-50px w-50 h-auto",
    attrs: {
      "src": require('@/assets/images/emptyScreenImages/playlistFilesInMOH.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading"
  }, [_vm._v(" Start creating your playlist ")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75"
  }, [_vm._v(" Our playlist is empty, but it's easy to get started. Add your favorite tracks and create the perfect soundtrack for your day. ")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-20px"
  }, [_vm._v(" Add tracks ")])])]) : _vm._e(), _vm.conditions.change_order ? _c('Container', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px",
    on: {
      "drop": function ($event) {
        return _vm.changeOrder($event.removedIndex, $event.addedIndex);
      }
    }
  }, _vm._l(_vm.selectedFiles, function (element, index) {
    return _c('Draggable', {
      key: index
    }, [_c('div', {
      staticClass: "draggable-item"
    }, [_c('li', {
      staticClass: "list-group-item dialer-assignee bg-transparent d-flex",
      attrs: {
        "id": `${element.id}&&${element.real_id}&&${element.label}&&${element.sound}&&${element.sound_file}`
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center w-100"
    }, [_c('span', {
      staticClass: "dialer-assignee-name font-weight-700 wd-30"
    }, [_vm._v(_vm._s(_vm._f("check_empty")(element.label, 'None')))]), _c('vb-audio-player', {
      staticClass: "calc-100-32px w-50",
      attrs: {
        "src": element.sound_file
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-icon', {
      staticClass: "mr-16px",
      attrs: {
        "icon": "arrows-move",
        "font-scale": "1.5"
      }
    }), _vm.api.remove_file.send == element.id ? _c('b-spinner', {
      staticClass: "md-mar-left",
      attrs: {
        "label": "spinner",
        "variant": "primary"
      }
    }) : _vm.selectedFiles.length > 1 ? _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "md-mar-left",
      attrs: {
        "title": "remove to playlist"
      },
      on: {
        "click": function ($event) {
          _vm.api.delete_playlist.send ? '' : _vm.removeFile(element.id);
        }
      }
    }, [_c('vb-icon', {
      staticClass: "dialer-delete-icon-filled",
      attrs: {
        "icon": "dialer-delete-icon-filled",
        "height": "19.995",
        "width": "16"
      }
    })], 1) : _vm._e()], 1)], 1)])])]);
  }), 1) : [_vm.selectedFiles.length > 0 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, _vm._l(_vm.selectedFiles, function (file) {
    return _c('div', {
      key: file.real_id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(file.label))])]), _c('vb-audio-player', {
      staticClass: "whiteBGaudioWithGreyRoundedBorder-again Width30minus8px minWidth200px",
      attrs: {
        "src": file.sound_file
      }
    })], 1);
  }), 0) : _vm._e()], [!_vm.forms.change_default.default ? _c('div', {
    class: `${!_vm.forms.change_default.default && !_vm.conditions.change_order ? 'whiteBoxWithBorderInsideGreyBox' : ''} mt-20px`
  }, [!_vm.conditions.change_order && _vm.selectedFiles.length > 0 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Set this playlist as default music on hold for the organization")]), _vm.selectedFiles.length > 0 ? _c('b-checkbox', {
    ref: "change-default",
    staticClass: "newerSwitch ml-3",
    attrs: {
      "switch": "",
      "disabled": _vm.api.change_default.send,
      "checked": _vm.forms.change_default.default
    },
    on: {
      "change": function ($event) {
        return _vm.changeDefault();
      }
    }
  }) : _vm._e()], 1) : _vm._e(), !_vm.conditions.change_order && !_vm.isSystem ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this playlist?")]), _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "disabled": _vm.api.delete_playlist.send
    },
    on: {
      "click": function ($event) {
        return _vm.deletePlayList();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart"
  }, [_vm.api.delete_playlist.send ? _c('vb-spinner') : _c('span', [_vm._v("Delete")])], 1)], 1)]) : _vm._e()]) : _vm._e()]], 2)], _c('EditPlaylistSoundsModal', {
    attrs: {
      "modalName": `${_vm.modalName}-Sounds`
    },
    on: {
      "order-changed": function ($event) {
        return _vm.fetchPlaylists();
      },
      "files-updated": function ($event) {
        return _vm.fetchPlaylists();
      }
    }
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }