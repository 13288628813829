var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.isBoardView ? _c('div', {
    staticClass: "w-100",
    staticStyle: {
      "cursor": "move"
    },
    attrs: {
      "draggable": "true"
    },
    on: {
      "dragend": function ($event) {
        return _vm.$emit('dragend', $event);
      },
      "dragstart": function ($event) {
        return _vm.$emit('dragstart', $event);
      }
    }
  }, [_vm.isBoardView ? _c('div', {
    staticClass: "KanbanView-task",
    style: `border-left-color: ${_vm.priorityColor}`,
    on: {
      "click": function ($event) {
        return _vm.$emit('taskClick');
      }
    }
  }, [_c('div', {
    staticClass: "KanbanView-task-heading"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'title')))]), _vm.task.created_by ? _c('div', {
    staticClass: "KanbanView-task-text mt-10px"
  }, [_vm._v(" Created by "), _c('Info', {
    staticClass: "mx-1",
    attrs: {
      "only_name": true,
      "id": _vm.task.created_by
    }
  }), _vm._v(" on " + _vm._s(_vm._f("filter_date_current")(_vm.task.created_at)) + " ")], 1) : _vm._e(), _c('div', {
    staticClass: "categoryText w-fit-content mt-16px",
    style: `background-color: ${_vm.task.group ? _vm.task.group.color : ''};`
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'group.name')))]), _c('div', {
    staticClass: "KanbanView-task-lastLine mt-20px"
  }, [_c('div', {
    staticClass: "KanbanView-task-lastLine-leftPart"
  }, [_vm.myDay ? _c('b-icon', {
    staticClass: "mr-8px",
    attrs: {
      "icon": "sun-fill",
      "variant": "dark",
      "width": "auto",
      "height": "19px"
    }
  }) : _vm._e(), _vm.task.is_important == '1' ? _c('b-icon', {
    staticClass: "mr-8px",
    attrs: {
      "icon": "exclamation-lg",
      "variant": "dark",
      "width": "auto",
      "height": "19px"
    }
  }) : _vm._e(), _vm.task.note ? _c('b-icon', {
    attrs: {
      "icon": "file-earmark-text-fill",
      "variant": "dark",
      "width": "16px",
      "height": "19px"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "KanbanView-task-lastLine-rightPart ml-auto",
    class: `${_vm.assigned.count > 0 ? 'moreThanThree' : _vm.assigned.array.length == 3 ? 'exactlyThree' : ''}`
  }, [_vm._l(_vm.assigned.array, function (assigned) {
    return _c('vb-avatar', {
      key: assigned.id,
      attrs: {
        "showPopover": true,
        "is_blf": false,
        "id": assigned.assigned_to
      }
    });
  }), _vm.assigned.count ? _c('div', {
    staticClass: "countPlus"
  }, [_vm._v("+" + _vm._s(_vm.assigned.count))]) : _vm._e()], 2)])]) : _c('div', {
    staticClass: "TODO-taksTable-bodyRow mt-12px",
    on: {
      "click": function ($event) {
        return _vm.$emit('taskClick');
      }
    }
  }, [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column first",
    style: `border-left: 13px solid ${_vm.priorityColor}`
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "mainInfo-section"
  }, [_vm.task.spaces ? _c('b-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    style: `color:${_vm.task.spaces.color};`,
    attrs: {
      "icon": "circle-fill",
      "title": _vm.task.spaces.name
    }
  }) : _vm._e(), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text ml-2"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'title')))])], 1), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_vm.task.created_by ? _c('div', {
    staticClass: "subInfo-section"
  }, [_c('span', {
    staticClass: "d-flex firstPart align-items-center"
  }, [_c('span', {
    staticClass: "mr-8px"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'spaces.prefix')) + "#" + _vm._s(_vm._f("get_property")(_vm.task, 'id')))]), _c('span', {
    staticClass: "mr-8px d-flex align-items-center putSmallDotInBefore"
  }, [_vm._v(" created by "), _c('Info', {
    staticClass: "mx-1",
    attrs: {
      "only_name": true,
      "id": _vm.task.created_by
    }
  }), _vm._v(" on " + _vm._s(_vm._f("filter_date_current")(_vm.task.created_at)) + " ")], 1), _vm.task.due_date ? [_c('span', {
    staticClass: "mr-8px putSmallDotInBefore"
  }, [_vm._v(" Due "), _c('span', {
    staticClass: "selectedActive"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.task.due_date)))])])] : _vm._e(), _c('span', {
    staticClass: "mr-16px putSmallDotInBefore"
  }, [_vm._v(" Last updated "), _c('span', {
    staticClass: "selectedActive"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.task.updated_at)))])])], 2), _c('span', {
    staticClass: "d-flex secondPart"
  }, [_c('span', {
    staticClass: "mr-16px d-flex align-items-center"
  }, [_vm.myDay ? _c('b-icon', {
    staticClass: "mr-8px",
    attrs: {
      "icon": "sun-fill",
      "variant": "dark",
      "width": "auto",
      "height": "19px"
    }
  }) : _vm._e(), _vm.task.is_important == '1' ? _c('b-icon', {
    staticClass: "mr-8px",
    attrs: {
      "icon": "exclamation-lg",
      "variant": "dark",
      "width": "auto",
      "height": "19px"
    }
  }) : _vm._e(), _vm.task.note ? _c('b-icon', {
    attrs: {
      "icon": "file-earmark-text-fill",
      "variant": "dark",
      "width": "16px",
      "height": "19px"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "categoryText",
    style: `background-color: ${_vm.task.group ? _vm.task.group.color : ''};`
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'group.name')))])])]) : _vm._e()])])]), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column second",
    class: `${_vm.assigned.count > 0 ? 'moreThanThree' : _vm.assigned.array.length == 3 ? 'exactlyThree' : ''}`
  }, [_vm._l(_vm.assigned.array, function (assigned) {
    return _c('vb-avatar', {
      key: assigned.id,
      attrs: {
        "showPopover": true,
        "is_blf": false,
        "id": assigned.assigned_to
      }
    });
  }), _vm.assigned.count ? _c('div', {
    staticClass: "countPlus"
  }, [_vm._v("+ " + _vm._s(_vm.assigned.count))]) : _vm._e()], 2), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column third",
    style: `background-color: ${_vm.task.status ? _vm.task.status.color : 'transparent'}`
  }, [_vm.task.status ? [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text colorWhite"
  }, [_vm._v(" " + _vm._s(_vm.task.status.title) + " ")])] : _vm._e()], 2), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column fourth"
  }, [_vm.isMore ? _c('div', {
    staticClass: "filterDD dropdownVersion whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "disabled": _vm.api.remove_task.send,
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "mx-auto",
          attrs: {
            "icon": "todo-three-dots-vertical-icon",
            "variant": "dark",
            "width": "6px",
            "height": "27px"
          }
        })];
      },
      proxy: true
    }], null, false, 488352484)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.removeTask();
      }
    }
  }, [_vm._v(_vm._s(_vm.task.deleted_status == 'yes' ? 'Unarchived' : 'Archived'))])], 1)], 1) : _vm._e()])])]) : _c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "TODO-taksTable-bodyRow mt-12px",
    on: {
      "click": function ($event) {
        return _vm.$emit('taskClick');
      }
    }
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column onlyOne justify-content-start",
    style: `border-left: 9px solid ${_vm.priorityColor}`
  }, [_vm.task.spaces ? _c('b-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    style: `color:${_vm.task.spaces.color};`,
    attrs: {
      "icon": "circle-fill",
      "title": _vm.task.spaces.name
    }
  }) : _vm._e(), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text ml-2"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'title')))])], 1) : [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column first",
    style: `border-left: 13px solid ${_vm.priorityColor}`
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "mainInfo-section"
  }, [_vm.task.spaces ? _c('b-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    style: `color:${_vm.task.spaces.color};`,
    attrs: {
      "icon": "circle-fill",
      "title": _vm.task.spaces.name
    }
  }) : _vm._e(), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text ml-2"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'title')))])], 1), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_vm.task.created_by ? _c('div', {
    staticClass: "subInfo-section"
  }, [_c('span', {
    staticClass: "d-flex firstPart align-items-center"
  }, [_c('span', {
    staticClass: "mr-8px"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'spaces.prefix')) + "#" + _vm._s(_vm._f("get_property")(_vm.task, 'id')))]), _c('span', {
    staticClass: "mr-8px d-flex align-items-center putSmallDotInBefore"
  }, [_vm._v(" created by "), _c('Info', {
    staticClass: "mx-1",
    attrs: {
      "only_name": true,
      "id": _vm.task.created_by
    }
  }), _vm._v(" on " + _vm._s(_vm._f("filter_date_current")(_vm.task.created_at)) + " ")], 1), _vm.task.due_date ? [_c('span', {
    staticClass: "mr-8px putSmallDotInBefore"
  }, [_vm._v(" Due "), _c('span', {
    staticClass: "selectedActive"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.task.due_date)))])])] : _vm._e(), _c('span', {
    staticClass: "mr-16px putSmallDotInBefore"
  }, [_vm._v(" Last updated "), _c('span', {
    staticClass: "selectedActive"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.task.updated_at)))])])], 2), _c('span', {
    staticClass: "d-flex secondPart"
  }, [_c('span', {
    staticClass: "mr-16px d-flex align-items-center"
  }, [_vm.myDay ? _c('b-icon', {
    staticClass: "mr-8px",
    attrs: {
      "icon": "sun-fill",
      "variant": "dark",
      "width": "auto",
      "height": "19px"
    }
  }) : _vm._e(), _vm.task.is_important == '1' ? _c('b-icon', {
    staticClass: "mr-8px",
    attrs: {
      "icon": "exclamation-lg",
      "variant": "dark",
      "width": "auto",
      "height": "19px"
    }
  }) : _vm._e(), _vm.task.note ? _c('b-icon', {
    attrs: {
      "icon": "file-earmark-text-fill",
      "variant": "dark",
      "width": "16px",
      "height": "19px"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "categoryText",
    style: `background-color: ${_vm.task.group ? _vm.task.group.color : ''};`
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.task, 'group.name')))])])]) : _vm._e()])])]), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column second",
    class: `${_vm.assigned.count > 0 ? 'moreThanThree' : _vm.assigned.array.length == 3 ? 'exactlyThree' : ''}`
  }, [_vm._l(_vm.assigned.array, function (assigned) {
    return _c('vb-avatar', {
      key: assigned.id,
      attrs: {
        "showPopover": true,
        "is_blf": false,
        "id": assigned.assigned_to
      }
    });
  }), _vm.assigned.count ? _c('div', {
    staticClass: "countPlus"
  }, [_vm._v("+ " + _vm._s(_vm.assigned.count))]) : _vm._e()], 2), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column third",
    style: `background-color: ${_vm.task.status ? _vm.task.status.color : 'transparent'}`
  }, [_vm.task.status ? _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text colorWhite"
  }, [_vm._v(_vm._s(_vm.task.status.title))]) : _vm._e()]), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column fourth"
  }, [_vm.isMore ? _c('div', {
    staticClass: "filterDD dropdownVersion whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "disabled": _vm.api.remove_task.send,
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "mx-auto",
          attrs: {
            "icon": "todo-three-dots-vertical-icon",
            "variant": "dark",
            "width": "6px",
            "height": "27px"
          }
        })];
      },
      proxy: true
    }], null, false, 488352484)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        return _vm.removeTask();
      }
    }
  }, [_vm._v(_vm._s(_vm.task.deleted_status == 'yes' ? 'Unarchived' : 'Archived'))])], 1)], 1) : _vm._e()])]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }