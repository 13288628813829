<template>
  <div 
    :id="post.id" 
    v-if="messageType === 'sender' || messageType === 'receiver'" 
    class="msg-box"
    :class="{
      'd-flex flex-wrap justify-content-end': messageType === 'sender',
      'ReactionTrue': !fromForwardModal && !isEmpty(reactions) && type !== 'pinned',
      'rapidly-message-next-same': rapidlyMessage==3,
      'rapidly-message-after-time': rapidlyMessage==2,
      'rapidly-message': rapidlyMessage==1,

    }"
  >
    <div :class="`w-30 mb-1 chat-msg-box ${messageType === 'sender' ? 'text-right d-flex flex-column' : 'other-user-chat-msg-box'}`">
      <div class="chat-msg-box-inner">
        <!-- user profile image -->
        <span v-if="!fromForwardModal && messageType === 'receiver'" class="avatar d-flex align-items-center justify-content-center md-mar-left">
          <img :src="$store.getters.getVoipUsersAlisesImages[post.user_id]" @error="$event.target.src = default_user_profile_photo" :alt="$store.getters.getVoipUsersAlisesDisplayName[post.user_id]" class="d-block w-100" />
        </span>
        <!-- ------------------- -->
        <!-- message-container -->
        <div class="dialer-user-msg-box d-flex flex-column">
          <!-- links -->
          <!-- <div>
            <Link v-for="(link, index) in links" :key="`${index}-${link}`" :link="link" :channel_id="channel_id" :post_id="post.id" :channelData="channelData" :type="type" />
          </div> -->
          <!-- ------ -->
          <!-- time, name & pinned -->
          <div v-if="!fromForwardModal" class="dialer-user-msg-info-box" :style="`display: flex; align-items-end; flex-direction${messageType === 'sender' ? 'row' : 'row-reverse'}`">
            <span v-if="(messageType === 'receiver' || type === 'pinned')" style="margin-right: 5px; margin-left: 5px; font-size: 0.8rem">
              {{ $store.getters.getVoipUsersAlisesDisplayName[post.user_id] }}
            </span>
            <small class="dialer-user-msg-time ml-2" style="margin: 0">{{ post.create_at | time }}</small>
            <small class="d-inline-flex align-items-end forPinned mx-3" v-if="post.is_pinned && type !== 'pinned'" >
              <vb-svg width="20" height="20" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" name="dialer-pin" />
              pinned
            </small>
          </div>
          <!-- ----- -->
          <!-- message-container -->
          <span :class="`msg d-inline-block chat-message ${!fromForwardModal && post.props && post.props.is_important ? 'msg-important' : ''}`">
            <!-- reply -->
            <span v-if="!fromForwardModal && post.props && post.props.reply_post_id" class="d-flex flex-column align-items-start border-bottom border-dark pb-1 mb-1 repliedMsg-container" style="word-break: break-word;">
              <img :src="commaIcon_black" alt />
              <template v-if="parent_post.message">
                <span class="pl-2">{{ parent_post.message }}</span>
              </template>
              <span v-else class="pl-2">This message was deleted</span>
            </span>
            <!-- ------- -->
            <!-- message -->
            <div v-if="post.message" class="msg-textArea">
              <div class="forwardedMsg" v-if="isForwarded">
                <vb-icon icon="ShortcutKeysModal-duringJitsiCall-icon" height="16px" width="18px" />
                <div class="forwardedMsg-text">Forwarded</div>
              </div>
              <div class="msg-text">
                <span :id="`${post.id}-text-message`">{{ post.message }}</span>
              </div>
            </div>
            <!-- deleted message -->
            <div v-if="post.delete_at>0" class="msg-textArea">
              <div class="msg-text">
                <span :id="`${post.id}-text-message`">This message was deleted</span>
              </div>
            </div>
            <!-- -------- -->
            <!-- files -->
            <div 
              v-if="post.metadata" 
              :class="`
                msg-media 
                ${post.metadata && (post.metadata.files || []).every(file=>file.mime_type.includes('video'))?'video-msg-container':''} 
                ${post.metadata && (post.metadata.files || []).some(file=>file.mime_type.includes('image'))?'msg-contain-img':''}  
                ${post.metadata && (post.metadata.files || []).every(file=>file.mime_type.includes('image')) ? 'allImages' :''} 
              `"
            >  
              <File v-for="(file, index) in post.metadata.files" :ref="`file-component-${file.id}`" :random_id="random_id" :key="file.id" :file="file" :index="index" :post_id="post.id" :channel_id="channel.id" :message_type="messageType" :type="type" />
            </div>
            <!-- --------- -->

            <!-- reactions  -->
            <div v-if="!fromForwardModal && !isEmpty(reactions) && type !== 'pinned'" class="d-flex reaction_emoji_box">
              <div v-if="angryReachtions.length" :id="`angryReachtions-${post.id}`" :class="`reaction_emoji_box_inner ${angryReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`">
                <span v-if="angryReachtions.length > 1" style="line-height:1">{{ angryReachtions.length > 1 ? angryReachtions.length : '' }}</span>
                <span  @click="removeReaction(reactionEmojis.Angry.name)" :class="angryReachtions.length > 1 ? 'ml-1' : ''" style="cursor: pointer" v-html="reactionEmojis.Angry.img" />
                <b-popover  custom-class="emojiUserList-container" :target="`angryReachtions-${post.id}`" placement="top" triggers="hover focus"><!-- hover focus click-->
									<div class="reactedEmoji">
                    <span style="cursor: pointer" v-html="reactionEmojis.Angry.img" />
                  </div>
                  <div class="emojiUserList">
                    <div v-for="react in angryReachtions" :key="react.user_id" class="emojiUser" style="">
                      <img :src="react.profile_image" @error="$event.target.src=local_filess.images.user_male" style="" />
                      <p class="">{{ react.username }}</p>
                    </div>
                  </div>
                </b-popover>
              </div>
              <div v-if="cryReachtions.length" :id="`cryReachtions-${post.id}`" :class="`reaction_emoji_box_inner ${cryReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`">
                <span v-if="cryReachtions.length > 1" style="line-height:1">{{ cryReachtions.length > 1 ? cryReachtions.length : '' }}</span>
                <span @click="removeReaction(reactionEmojis.Cry.name)" :class="cryReachtions.length > 1 ? 'ml-1' : ''" style="cursor: pointer" v-html="reactionEmojis.Cry.img" />
								
                <b-popover  custom-class="emojiUserList-container" :target="`cryReachtions-${post.id}`" placement="top" triggers="hover focus"><!-- hover focus click-->
									<div class="reactedEmoji">
                    <span style="cursor: pointer" v-html="reactionEmojis.Cry.img" />
                  </div>
                  <div class="emojiUserList">
                    <div v-for="react in cryReachtions" :key="react.user_id" class="emojiUser" style="">
                      <img :src="react.profile_image" @error="$event.target.src=local_filess.images.user_male"  />
                      <p>{{ react.username }}</p>
                    </div>
                  </div>
                </b-popover>
							</div>
							<div v-if="thumbupReachtions.length" :id="`thumbupReachtions-${post.id}`" :class="`reaction_emoji_box_inner ${thumbupReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`">
                <span v-if="thumbupReachtions.length > 1" style="line-height:1">{{ thumbupReachtions.length > 1 ? thumbupReachtions.length : '' }}</span>
                <span @click="removeReaction(reactionEmojis.Thumbup.name)" :class="thumbupReachtions.length > 1 ? 'ml-1' : ''" style="cursor: pointer" v-html="reactionEmojis.Thumbup.img" />
								
                <b-popover  custom-class="emojiUserList-container" :target="`thumbupReachtions-${post.id}`" placement="top" triggers="hover focus"><!-- hover focus click-->
									<div class="reactedEmoji">
                    <span style="cursor: pointer" v-html="reactionEmojis.Thumbup.img" />
                  </div>
                  <div class="emojiUserList">
                    <div v-for="react in thumbupReachtions" :key="react.user_id" class="emojiUser" style="">
                      <img :src="react.profile_image" @error="$event.target.src=local_filess.images.user_male"  />
                      <p>{{ react.username }}</p>
                    </div>
                  </div>
                </b-popover>
							</div>
              <div v-if="wowReachtions.length" :id="`wowReachtions-${post.id}`" :class="`reaction_emoji_box_inner ${wowReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`">
                <span v-if="wowReachtions.length > 1" style="line-height:1">{{ wowReachtions.length > 1 ? wowReachtions.length : '' }}</span>
                <span @click="removeReaction(reactionEmojis.Wow.name)" :class="wowReachtions.length > 1 ? 'ml-1' : ''" style="cursor: pointer" v-html="reactionEmojis.Wow.img" />
                
                <b-popover  custom-class="emojiUserList-container" :target="`wowReachtions-${post.id}`" placement="top" triggers="hover focus"><!-- hover focus click-->
									<div class="reactedEmoji">
                    <span style="cursor: pointer" v-html="reactionEmojis.Wow.img" />
                  </div>
                  <div class="emojiUserList">
                    <div v-for="react in wowReachtions" :key="react.user_id" class="emojiUser" style="">
                      <img :src="react.profile_image" @error="$event.target.src=local_filess.images.user_male" style="" />
                      <p>{{ react.username }}</p>
                    </div>
                  </div>
                </b-popover>
              </div>
              <div v-if="heartReachtions.length" :id="`heartReachtions-${post.id}`" :class="`reaction_emoji_box_inner ${heartReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`">
                <span v-if="heartReachtions.length > 1" style="line-height:1">{{ heartReachtions.length > 1 ? heartReachtions.length : '' }}</span>
                <span @click="removeReaction(reactionEmojis.Heart.name)" :class="heartReachtions.length > 1 ? 'ml-1' : ''" style="cursor: pointer" v-html="reactionEmojis.Heart.img" />
                
                <b-popover  custom-class="emojiUserList-container" :target="`heartReachtions-${post.id}`" placement="top" triggers="hover focus"><!-- hover focus click-->
									<div class="reactedEmoji">
                    <span style="cursor: pointer" v-html="reactionEmojis.Heart.img" />
                  </div>
                  <div class="emojiUserList">
                    <div v-for="react in heartReachtions" :key="react.user_id" class="emojiUser" style="">
                      <img :src="react.profile_image" @error="$event.target.src=local_filess.images.user_male" style="" />
                      <p>{{ react.username }}</p>
                    </div>
                  </div>
                </b-popover>
              </div>
              <div v-if="laughingReachtions.length" :class="`reaction_emoji_box_inner ${laughingReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`">
                <span v-if="laughingReachtions.length > 1" style="line-height:1">{{ laughingReachtions.length > 1 ? laughingReachtions.length : '' }}</span>
                <span :id="`laughingReachtions-${post.id}`" :class="laughingReachtions.length > 1 ? 'ml-1' : ''" @click="removeReaction(reactionEmojis.Laughing.name)" style="cursor: pointer" v-html="reactionEmojis.Laughing.img" />
								
                <b-popover  custom-class="emojiUserList-container" :target="`laughingReachtions-${post.id}`" placement="top" triggers="hover focus"><!-- hover focus click-->
									<div class="reactedEmoji">
                    <span style="cursor: pointer" v-html="reactionEmojis.Laughing.img" />
                  </div>
                  <div class="emojiUserList">
                    <div v-for="react in laughingReachtions" :key="react.user_id" class="emojiUser" style="">
                      <img :src="react.profile_image" @error="$event.target.src=local_filess.images.user_male" style="" />
                      <p>{{ react.username }}</p>
                    </div>
                  </div>
                </b-popover>
							</div>
            </div>
            <!-- ---------------- -->
          </span>
          <!-- ------ -->
          <!-- edited -->
          <span v-if="!fromForwardModal && post.props && post.props.isEdited" class="editedMsgICon">
            <b-icon v-b-tooltip.hover title="This message has been edited." icon="pencil" />
          </span>
          <!-- ------ -->
        </div>
        <div v-if="api.send_message.send" class="spinner-border v2" role="status">
          <span class="sr-only">Loading...</span>
        </div>
        <div v-if="api.send_message.error_message" class="ChatErrorMsgContainer">
          <b-icon variant="danger" icon="exclamation-circle-fill" :id="`${post.id}-tooltip-target`"></b-icon>
          <b-tooltip :target="`${post.id}-tooltip-target`" triggers="hover">
            {{api.send_message.error_message}} 
            <b-icon icon="arrow-counterclockwise" @click="createPost()" />
          </b-tooltip>
        </div>
        <!-- ------ -->
        <!-- actions -->
        <div v-if="!fromForwardModal && type !== 'pinned' && post.delete_at === 0" class="dialer_show_emoji">
          <!-- reactions -->
          <template>
            <span v-for="react in reactionEmojis" :key="react.name" class="mr-2">
              <span class="cursor_pointer" @click="emojiReact(react.name)" v-html="react.img" />
							<span v-if="reactions.some(emoji => emoji.emoji_name === react.name && emoji.user_id === $store.getters.getMattermostCurrentUser.id)" class="selectedEmojiDot-container">
                <span class="selectedEmojiDot"></span>
              </span>
            </span>
          </template>
          <!-- ------ -->
          <!-- more -->
          <b-dropdown varient="link" no-caret class="ico-filter">
            <template v-slot:button-content>
              <b-icon icon="three-dots-vertical" scale="1.3" />
            </template>
            <b-dropdown-item v-if="isDownloadFile">
              <span @click="downloadAllFile">
                <vb-svg name="dialer-copy-icon" width="16" stroke="currentColor" height="16" viewBox="0 0 24 24" fill="none" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                <span class="ml-2">Download</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item v-if="post.message">
              <span @click="copyPost">
                <vb-svg name="dialer-copy-icon" width="16" stroke="currentColor" height="16" viewBox="0 0 24 24" fill="none" stroke-width="1.2" stroke-linecap="round" stroke-linejoin="round" />
                <span class="ml-2">Copy</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item v-if="messageType === 'sender' && post.message">
              <span @click="edit">
                <span>
                  <vb-svg width="16" height="16" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" name="dialer-edit-icon" />
                </span>
                <span class="ml-2">Edit</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item v-if="post.message">
              <span @click="reply">
                <span>
                  <vb-svg width="16" height="16" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" name="dialer-reply-icon" />
                </span>
                <span class="ml-2">Reply</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item v-if="!post.file_ids">
              <span @click="forward">
                <span>
                  <vb-svg width="16" height="16" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" name="dialer-forward-icon" />
                </span>
                <span class="ml-2">Forward</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item v-if="messageType === 'receiver'">
              <span @click="markUnread">
                <b-icon icon="envelope" />
                <span class="ml-2">Unread from here</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item>
              <span @click="pinned">
                <span>
                  <vb-svg width="16" height="16" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" name="dialer-pin" />
                </span>
                <span class="ml-2">{{ post.is_pinned ? "Unpinned" : "Pinned" }}</span>
              </span>
            </b-dropdown-item>
            <b-dropdown-item v-if="messageType === 'sender'">
              <span @click="deletePost">
                <span>
                  <vb-svg width="16" height="16" viewBox="0 0 24 24" stroke-width="1.2" stroke="currentColor" fill="none" stroke-linecap="round" stroke-linejoin="round" name="dialer-delete-icon" />
                </span>
                <span class="ml-2 danger">Delete</span>
              </span>
            </b-dropdown-item>
          </b-dropdown>
          <!-- ----- -->
        </div>
        <!-- ---- -->
        <!-- viewed -->
        <!-- <div>
          <div v-for="member in viewedMembers" :key="member.user_id">
            <vb-avatar :id="member.user_id" :is_blf="false" :showStatus="false" :showPopover="true" />
          </div>
        </div> -->
      </div>
    </div>
    
    <!-- viewed -->
    <div class="readBy-container" v-if="viewedMembers.length != 0">
      <div class="readBy-item" v-for="(member,index) in viewedMembers" :key="member.user_id" :style="`z-index: ${index+1};`">
        <vb-avatar :id="member.user_id" :is_blf="false" :showStatus="false" :showPopover="true" />
      </div>
      <div :id="`popover-ViewedByTooltipVersion-${post.id}`" v-if="viewedMembers.length > 3" class="readBy-item withPlus">
        <!-- + {{viewedMembers.length - 3}} -->
        + 3
      </div>
      <b-popover custom-class="ViewedByTooltipVersion"
        :target="`popover-ViewedByTooltipVersion-${post.id}`"
        placement="top"
        triggers="focus click" show
      >
        <div class="">
          <div class="ViewedByTooltipVersion-item" v-for="(member,index) in viewedMembers" :key="member.user_id" :style="`z-index: ${index+1};`">
            <!-- <vb-avatar :id="member.user_id" :is_blf="false" :showStatus="false" :showPopover="true" /> -->
            <Info :id="member.user_id" :is_blf="false" :showStatus="false" :showPopover="true"/>
          </div>
        </div>
      </b-popover>
    </div>
  </div>
  <!-- system msg section -->
  <div :id="post.id" v-else class="msg-box justify-content-center d-flex">
    <div class="mb-1 chat-msg-box text-center channelPurpose" style="max-width: calc(100% - 20%)">
      <div class="chat-msg-box-inner" v-html="systemMessage()"></div>
    </div>
  </div>
</template>

<script>
import { mattermost, MATTERMOST_API } from "@/MatterMost";
import File from "./File.vue";
// import Link from "./Link";
import Mark from "mark.js";
import { TokenService } from "@/services";
import { mapGetters } from 'vuex';
import { URLS } from '@/utils';
import Info from '../../../Lists/Info.vue';
export default {
  name: "Message",
  components: {
    File,
    // Link,
    Info,
  },
  props: {
    post: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    channel_id: {
      type: String,
      default: "",
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    channelData: {
      type: Object,
      default: () => ({}),
    },
    members: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
    },
    name: {
      type: String,
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    fromForwardModal: {
      type: Boolean,
      default: false,
    },
    rapidlyMessage: {
      type: Number,
      default: 0,
    },
    random_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      reactionEmojis: {
        Thumbup: {
          name: "Thumbup",
          img: `<img alt="👍" class="emojioneemoji" src="https://cdn.jsdelivr.net/emojione/assets/3.1/png/32/1f44d.png">`,
        },
        Heart: {
          name: "Heart",
          img: `<img alt="❤️" class="emojioneemoji" src="https://cdn.jsdelivr.net/emojione/assets/3.1/png/32/2764.png">`,
        },
        Laughing: {
          name: "Laughing",
          img: `<img alt="😂" class="emojioneemoji" src="https://cdn.jsdelivr.net/emojione/assets/3.1/png/32/1f602.png">`,
        },
        Cry: {
          name: "Cry",
          img: `<img alt="😢" class="emojioneemoji" src="https://cdn.jsdelivr.net/emojione/assets/3.1/png/32/1f622.png">`,
        },
        Wow: {
          name: "Wow",
          img: `<img alt="😮" class="emojioneemoji" src="https://cdn.jsdelivr.net/emojione/assets/3.1/png/32/1f62e.png">`,
        },
        Angry: {
          name: "Angry",
          img: `<img alt="😡" class="emojioneemoji" src="https://cdn.jsdelivr.net/emojione/assets/3.1/png/32/1f621.png">`,
        },
        disabled_emoji:[]
      },
      parent_post: {},
      commaIcon: require("@/assets/images/commas.svg"),
      commaIcon_black: require("@/assets/images/commas-black.svg"),
      default_user_profile_photo: require("@/assets/images/user-male-icon.svg"),
      isError: false,
      api: {
        send_message: this.$helperFunction.apiInstance({ error_message: true })
      },
    };
  },
  inject:['isEmpty', 'local_filess'],
  computed: {
    ...mapGetters([
			'getVoipUsersAlisesDisplayName',
			'getVoipUsersAlisesImages',
      'getCurrentUser'
		]),
    viewedMembers(){ return this.members?.filter?.(i=>this.$store.getters.getMattermostCurrentUser.id!=i.user_id && i.last_viewed_at>=this.post.update_at && (this.nextPost ? i.last_viewed_at<this.nextPost?.update_at : true)) },
    reactions(){ return this.post?.metadata?.reactions ?? [] },
    isForwarded(){ return !!this.post?.props?.is_forwarded },
    //check Post Id
    nextPost(){ return this.channelData?.posts?.order?.indexOf(this.post.id) != -1 ? this.channelData?.posts?.posts?.[this.channelData?.posts?.order?.[this.channelData?.posts?.order?.indexOf(this.post.id)+1]] : null },
    pendingPost(){ return this.post.pending_post_id === this.post.id },
    postEditing(){ return this.post.edit },
    //thumbup reactions
    thumbupReachtions(){ 
			return (this.reactions ?? []).filter(emoji => emoji?.emoji_name === this.reactionEmojis.Thumbup.name).map(react=>{
				return {
					...react,
					username: this.getVoipUsersAlisesDisplayName?.[react.user_id],
					profile_image: this.getVoipUsersAlisesImages?.[react.user_id],
				}
			})
		},
    //heart reactions
		heartReachtions(){ 
			return (this.reactions ?? []).filter(emoji => emoji?.emoji_name === this.reactionEmojis.Heart.name).map(react=>{
				return {
					...react,
					username: this.getVoipUsersAlisesDisplayName?.[react.user_id],
					profile_image: this.getVoipUsersAlisesImages?.[react.user_id],
				}
			})
		},
		//laughing reactions
		laughingReachtions(){ 
			return (this.reactions ?? []).filter(emoji => emoji?.emoji_name === this.reactionEmojis.Laughing.name).map(react=>{
				return {
					...react,
					username: this.getVoipUsersAlisesDisplayName?.[react.user_id],
					profile_image: this.getVoipUsersAlisesImages?.[react.user_id],
				}
			})
		},
    //cry reactions
		cryReachtions(){ 
			return (this.reactions ?? []).filter(emoji => emoji?.emoji_name === this.reactionEmojis.Cry.name).map(react=>{
				return {
					...react,
					username: this.getVoipUsersAlisesDisplayName?.[react.user_id],
					profile_image: this.getVoipUsersAlisesImages?.[react.user_id],
				}
			})
		},
    //wow reactions
		wowReachtions(){ 
			return (this.reactions ?? []).filter(emoji => emoji?.emoji_name === this.reactionEmojis.Wow.name).map(react=>{
				return {
					...react,
					username: this.getVoipUsersAlisesDisplayName?.[react.user_id],
					profile_image: this.getVoipUsersAlisesImages?.[react.user_id],
				}
			})
		},
    //angry reactions
		angryReachtions(){ 
			return (this.reactions ?? []).filter(emoji => emoji?.emoji_name === this.reactionEmojis.Angry.name).map(react=>{
				return {
					...react,
					username: this.getVoipUsersAlisesDisplayName?.[react.user_id],
					profile_image: this.getVoipUsersAlisesImages?.[react.user_id],
				}
			})
		},
    links() {
      if (!this.post || !this.post.message) return [];
      let links = [];
      var urlRegex = /(https?:\/\/[^\s]+)/g;
      this.post.message.replace(urlRegex, function (url) {
        links.push(url);
        return url;
      });
      return links;
    },
    messageType() {
      // if (this.mattermost_current_user.id === this.post.user_id && !this.post.type.includes("system")) return "sender";
      if (this.$store.getters.getMattermostCurrentUser?.id === this.post.user_id && !this.post.type.includes("system")) return "sender";
      // else if (this.mattermost_current_user.id !== this.post.user_id && !this.post.type.includes("system")) return "receiver";
      else if (this.$store.getters.getMattermostCurrentUser?.id !== this.post.user_id && !this.post.type.includes("system")) return "receiver";
      else return "system";
    },
    isDownloadFile() { return (this.post && this.post.metadata && this.post.metadata.files && this.post.metadata.files.length); },
  },
  watch: {
    "$parent.$parent.$refs.header.search": "watchingSearch",
    postEditing(value){
      let vm = this
      if(!value) return;
      if(vm.api.send_message.send) return;
      vm.api.send_message.send=true
      vm.api.send_message.error_message=''
      MATTERMOST_API.endpoints.posts.patchPost(vm.post.id,{
        message: vm.post.message,
        file_ids: vm.post?.metadata?.files?.map?.(i=>i.id) ?? [],
        props: vm.post.props,
      })
      .then(({ data })=>{
        mattermost.emitOnUserLevel(mattermost.events.post_edited,{
          channel_id: vm.channel_id,
          post: data,
        })
      })
      .catch((error)=>{
        vm.api.send_message.error_message = error.own_message
      })
      .finally(()=>{
        vm.api.send_message.send=false
      });
    },
  },
  methods: {
    //createPost
    async createPost(){
      let vm = this
      // console.log('run',vm.post.props.is_important,vm.post.props.reply_post_id)
      if(vm.api.send_message.send) return;
      vm.api.send_message.send=true
      vm.api.send_message.error_message=''
      MATTERMOST_API.endpoints.posts.createPosts({
        channel_id: vm.channel_id,
        message: vm.post.message,
        file_ids: vm.post?.metadata?.files?.map?.(i=>i.id) ?? [],
        props: {
          // message_html: document.querySelector(
          //   "#textarea-chat-action-container .emojionearea .emojionearea-editor"
          // ).innerHTML,
          isEdited: false,
          reply_post_id: vm.post.props.reply_post_id || "",
          is_important: !!vm.post.props.is_important,
          is_forwarded: false,
        },
        parent_id: "",
        pending_post_id: vm.post.pending_post_id,
        root_id: "",
      })
      .then(({ data })=>{
        mattermost.emitOnUserLevel(mattermost.events.posted,{
          channel_id: this.channel_id,
          post: data,
        })
      })
      .catch((error)=>{
        vm.api.send_message.error_message = error.own_message
      })
      .finally(()=>{
        vm.api.send_message.send=false
      })
    },
    watchingSearch(search) {
      var markInstance = new Mark(document.getElementById(`${this.post.id}-text-message`));
      markInstance.unmark({
        done() {
          markInstance.mark(search, {
            separateWordSearch: false,
            diacritics: true,
          });
        },
      });
    },
    downloadAllFile() {
      for (let file of this.post.metadata.files) {
        fetch(`${URLS.chatApi}/files/${file.id}?download=1`,{
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${TokenService.MATTERMOST_TOKEN.get()}`,
          },
        }).then((resp) => resp.blob()).then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = file.name || "download";
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        })
        // .catch((error) => console.log("error in downloading file", error));
      }
    },
    copyPost() {
      navigator.clipboard.writeText(this.post.message);
    },
    async markUnread() {
      // await MATTERMOST_API.endpoints.posts.markUnread(this.mattermost_current_user.id,this.post.id);
      await MATTERMOST_API.endpoints.posts.markUnread(this.$store.getters.getMattermostCurrentUser?.id,this.post.id);
      const { data: { msg_count: count }, } = await MATTERMOST_API.endpoints.channels.getUnreadCount(
        // this.mattermost_current_user.id,
        this.$store.getters.getMattermostCurrentUser?.id,
        this.channel_id
      );
      this.$store.state.mattermost.channels[this.channel_id].unread = count;
    },
    async pinned() {
      if (!this.post.is_pinned) {
        await MATTERMOST_API.endpoints.posts.pin(this.post.id);
        mattermost.emitOnUserLevel(mattermost.events.post_edited,{
          channel_id: this.channel_id,
          post: {
            ...this.post,
            is_pinned: true
          },
        })
      } else {
        await MATTERMOST_API.endpoints.posts.unpin(this.post.id);
        mattermost.emitOnUserLevel(mattermost.events.post_edited,{
          channel_id: this.channel_id,
          post: {
            ...this.post,
            is_pinned: false
          },
        })
      }
    },
    systemMessage() {
      const { message, props, type } = this.post;
      var new_message = "";
      const t_type =  this.channelData.type?.toLowerCase?.()=='channel' ? 'group' : this.channelData.type
      switch (type) {
        case "system_displayname_change":
          if (props.old_displayname) {
            new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} updated the ${t_type} display name from ${props.old_displayname} to ${props.new_displayname}.`;
          } else {
            new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} set the ${t_type} display name ${props.new_displayname}.`;
          }
          break;
        case "system_header_change":
          if (props.old_header) {
            new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} updated the ${t_type} header from ${props.old_header} to ${props.new_header}`;
          } else {
            new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} set the ${t_type} header ${props.new_header}`;
          }
          break;
        case "system_purpose_change":
          if (props.old_purpose) {
            new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} updated the ${t_type} purpose from ${props.old_purpose} to ${props.new_purpose}.`;
          } else {
            new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} set the ${t_type} purpose ${props.new_purpose}.`;
          }
          break;
        case "system_add_to_channel":
          new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.addedUsername]} added to the ${t_type} by ${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]}.`;
          break;
        case "system_remove_from_channel":
          new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.removedUsername]} removed from the ${t_type}`;
          break;
        case "system_channel_deleted":
          new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} delete the ${t_type}.`;
          break;
        case "system_leave_channel":
          new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} leave the ${t_type}.`;
          break;
        case "system_join_channel":
          new_message = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} created the ${t_type}.`;
          break;
        default:
          new_message = message;
          break;
      }
      return `<span style="text-align: center;" class="msg d-inline-block rounded py-2 px-2 chat-message bg-white text-black">${new_message}</span>`;
    },
    reply() {
      // console.log("Reply() is called")
      setTimeout(() => {
        document.getElementById(`${this.channel_id}-text-area`).focus()
      }, 0);
      
      this.$store.state.mattermost.channels[this.channel_id].actions.reply_post = this.post;
      this.$store.state.mattermost.channels[this.channel_id].actions.edit_post = {};
      
    },
    edit() {
      this.$store.state.mattermost.channels[this.channel_id].actions.edit_post = this.post;
      this.$store.state.mattermost.channels[this.channel_id].actions.message = this.post.message;
      this.$store.state.mattermost.channels[this.channel_id].actions.reply_post = {};
      this.$store.state.mattermost.channels[this.channel_id].actions.is_important = this.post.props.is_important;
    },
    forward() {
      this.$modal.show(`${this.random_id}_ForwardMessageModal`, {
        channel_id: this.channel_id,
        post: this.post,
      });
    },
    async deletePost() {
      await MATTERMOST_API.endpoints.posts.deletePost(this.post.id);
      mattermost.emitOnUserLevel(mattermost.events.post_deleted,{
        channel_id: this.channel_id,
        post: this.post,
      })
    },
    async emojiReact(reaction_name) {
      let vm = this;
      if(this.reactionEmojis.disabled_emoji.includes(reaction_name)) return;
			const react = (this.reactions ?? []).find((react) => react.user_id === vm.$store.getters.getMattermostCurrentUser?.id && react.emoji_name === reaction_name);
			if (!react) {
        this.reactionEmojis.disabled_emoji.push(reaction_name);
				const { data: reaction } = await MATTERMOST_API.endpoints.reactions.createReaction(this.$store.getters.getMattermostCurrentUser?.id,this.post.id,reaction_name).finally(()=>{
          vm.reactionEmojis.disabled_emoji.splice(vm.reactionEmojis.disabled_emoji.indexOf(reaction_name),1);
        });
        mattermost.emitOnUserLevel(mattermost.events.reaction_added,{
          channel_id: this.channel_id,
          reaction: reaction,
        })
			} else {
				this.removeReaction(reaction_name);
			}
    },
    async removeReaction(reaction_name) {
      const react = (this.reactions ?? []).find(react=>react.emoji_name==reaction_name&&react.user_id==this.$store.getters.getMattermostCurrentUser?.id)
			if(!react) return;
			await MATTERMOST_API.endpoints.reactions.deleteReaction(react.user_id,react.post_id,react.emoji_name);
      mattermost.emitOnUserLevel(mattermost.events.reaction_removed,{
        channel_id: this.channel_id,
        reaction: react,
      })
    },
    load() {
      let vm = this;
      if (!this.fromForwardModal && this.post.has_reactions && !this.post.reactions) {
        MATTERMOST_API.endpoints.reactions.getReactionList(this.post.id).then(({ data: reactions }) => {
          vm.$set(vm.$store.state.channels[vm.channel_id].posts.posts[vm.post.id],"reactions",reactions);
        });
      }
      if (this.post.props && this.post.props.reply_post_id) {
        if (this.channelData.posts.posts[this.post.props.reply_post_id]) {
          this.parent_post = this.channelData.posts.posts[this.post.props.reply_post_id];
        } else {
          MATTERMOST_API.endpoints.posts.getPost(this.post.props.reply_post_id).then(({ data: parent_post }) => {
            vm.parent_post = parent_post;
          });
        }
      }
    },
    //--event--
    postDeletedEvent(message) {
      const { channel_id, post } = message;
      if (channel_id !== this.channel_id) return;
      const { id: post_id } = post;
      if (this.parent_post && post_id !== this.parent_post.id) return;
      this.parent_post = {};
    },
  },
  async created() {
    mattermost.on(mattermost.events.post_deleted, this.postDeletedEvent);
    this.load();
    
  },
  mounted() {
    if (this.$parent.$parent.$refs && this.$parent.$parent.$refs.header && this.$parent.$parent.$refs.header.search) {
      this.watchingSearch(this.$parent.$parent.$refs.header.search);
    }
    if(this.pendingPost){
      this.createPost()
    }
  },
  beforeDestroy() {
    mattermost.off(mattermost.events.post_deleted, this.postDeletedEvent);
  },
};
</script>

<style lang="scss">
.emojioneemoji {
  height: 20px;
}
mark {
  color: $black;
  background-color: rgb(255, 247, 0) !important;
}
.avatar {
  height: 40px;
  width: 40px;
  @include border-radius(50%);
  overflow: hidden;
  img {
    object-fit: cover;
    height: 100%;
    width: 100%;
  }
}
</style>
