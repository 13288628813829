var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "CreateChanneFromUserslModal",
    attrs: {
      "name": "CreateChannelModal"
    },
    on: {
      "before-open": _vm.beforeOpen,
      "closed": _vm.closed
    }
  }, [_c('div', {
    staticClass: "topBar-withCloseIcon mt-0"
  }, [_c('h3', {
    staticClass: "dialer-box-header w-100 mb-0"
  }, [_vm._v("Create Group")]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('CreateChannelModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "searchBox"
  }, [_c('b-form', {
    staticClass: "position-relative",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search People"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('b-icon', {
    staticClass: "position-absolute cursor_pointer searchIcon",
    attrs: {
      "icon": "search",
      "scale": "1"
    }
  })], 1)], 1), _c('div', {
    staticClass: "addable-memberLisit"
  }, [_c('ul', {
    staticClass: "d-flex list-unstyled"
  }, _vm._l(_vm.selectedUsers, function (user) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account !== user.voipaccount,
        expression: "$store.getters.getCurrentUser.account !== user.voipaccount"
      }],
      key: user.voipaccount,
      staticClass: "d-inline-flex flex-column align-items-center pt-1 px-2"
    }, [_c('div', {
      staticClass: "user-avatar d-block avatar rounded-circle position-relative"
    }, [_vm.getProperty(user, 'user.user_chat_id') ? _c('span', {
      staticClass: "member-close position-absolute right-0 top-0",
      on: {
        "click": function ($event) {
          _vm.member.user_id !== user.user.user_chat_id ? _vm.$delete(_vm.selected_users, user.user.user_chat_id) : '';
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "x-circle-fill"
      }
    })], 1) : _vm._e(), _c('img', {
      attrs: {
        "src": user.profile_img
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.userDPMale;
        }
      }
    })]), _c('small', {
      staticClass: "member-name mt-1"
    }, [_vm._v(_vm._s(_vm._f("selectedUserDisplayName")(_vm._f("get_property")(user, 'user.display_name'))))])]);
  }), 0)]), _c('ul', {
    staticClass: "AvailableUsersList"
  }, [_vm._l(_vm.searchedNotSelectedUsers, function (user) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account !== user.voipaccount,
        expression: "$store.getters.getCurrentUser.account !== user.voipaccount"
      }],
      key: user.voipaccount,
      staticClass: "AvailableUsersList-item",
      on: {
        "click": function ($event) {
          _vm.getProperty(user, 'user.user_chat_id') ? _vm.$set(_vm.selected_users, user.user.user_chat_id, true) : '';
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": user.voipaccount,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _c('b-icon', {
      attrs: {
        "icon": "plus-circle-fill",
        "font-scale": "1.75",
        "variant": "primary"
      }
    })], 1);
  }), _vm.isEmpty(_vm.searchedNotSelectedUsers) ? _c('vb-no-record', {
    attrs: {
      "text": "There in no user",
      "design": 3
    }
  }) : _vm._e()], 2), _c('div', {
    staticClass: "d-flex justify-content-end w-100"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": _vm.onDone
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }