<template>
  <div id="calls-setting-desktop-notifications" class="dialer-box alt position-relative">
    <div class="dialer-flex">
      <div>
        <div class="dialer-box-header">Desktop notifications</div>
        <div class="dialer-box-text mb-0">Stay informed with on-screen alerts. Get real-time updates on calls, messages, and more, without disrupting your workflow</div>
      </div>
      <div class="dialer-audio-options d-flex">
        <div 
          id="DesktopNSwitchLock" 
          v-b-tooltip :title="getUserPermissions.desktop_notification?'':'Your package does not support this feature'"
          class="w-fit-content" 
          :class="{
            'for-disable': !getUserPermissions.desktop_notification
          }"
        >
          <!-- <b-form-checkbox
            ref="desktop_notification"
            class="dialer-switch ml-0"
            :disabled="!getUserPermissions.desktop_notification || api.update_desktop_notification.send  || api.user_detail.send"
            :checked="conditions.desktop_notification"
            name="check-button"
            switch
            @change="updateDesktopNotification($event)"
          /> -->
          <b-form-checkbox
            ref="desktop_notification"
            class="newerSwitch"
            :disabled="!getUserPermissions.desktop_notification || api.update_desktop_notification.send  || api.user_detail.send"
            :checked="conditions.desktop_notification"
            name="check-button"
            switch
            @change="updateDesktopNotification($event)"
          />
        </div>
      </div>
    </div>
    
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'DesktopNotificationWidget',
  props: {
    accountcode: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      api: {
        update_desktop_notification: this.$helperFunction.apiInstance(),
        user_detail: this.$helperFunction.apiInstance(),
      },
      conditions: {
        desktop_notification: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
    ]),
  },
  methods: {
    userdetail(){
      let vm = this
      let value = false
      if(vm.api.user_detail.send) return;
      vm.api.user_detail.send=true
      VOIP_API.endpoints.users.detail(this.accountcode)
      .then(({ data: detail })=>{
        value = ["1", 1, true, "yes", "Yes", "Y"].includes(detail.desktop_notification);
        vm.conditions.desktop_notification=value
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        if(vm.$refs['desktop_notification']) vm.$refs['desktop_notification'].localChecked=value
        vm.api.user_detail.send=false
      })
    },
    updateDesktopNotification(value) {
      let vm = this;
      if(vm.api.update_desktop_notification.send) return;
      vm.api.update_desktop_notification.send = true;
      VOIP_API.endpoints.users.updateuserdetail({
        field: `desktop_notification`,
        value: value ? '1' : '0',
        accountcode: vm.accountcode,
      })
      .then(() => {
        vm.conditions.desktop_notification=value
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        if(vm.accountcode==vm.$store.state.common.user.account){
          vm.$store.state.common.user.desktop_notification = vm.conditions.desktop_notification;
        }
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if(vm.$refs['desktop_notification']) vm.$refs['desktop_notification'].localChecked=vm.conditions.desktop_notification
        vm.api.update_desktop_notification.send=false;
      });
    },
  },
  mounted(){
    this.userdetail()
  },
  activated(){
    this.userdetail()
  },
}
</script>

<style>

</style>