
import { VOIP_API } from "../index"

export const USER_API_ENDPOINTS = {
  toggleFavourite(data={}){
    return VOIP_API.axios.voip.request({
      url: `toggle/voipusers/favourite`,
      method: "POST",
      data,
    })
  },
  verify2facode(data={}){
    return VOIP_API.axios.voip_relay.request({
      url: `verify-2fa-code`,
      method: "POST",
      data,
    })
  },
  login(data) {
    return VOIP_API.axios.voip_relay.request({
      method: 'post',
      url: 'login',
      data,
    })
  },
  userlogout(data={}){
    return VOIP_API.axios.voip_relay.request({
      method: 'post',
      url: 'user-logout',
      data,
    })
  },
  logout(headers={},data={}){
    return VOIP_API.axios.voip.request({
      method: 'DELETE',
      url: 'logout',
      data,
      headers,
      notHandleUnauthorize: true,
    })
  },
  updateprofileimage(data={},onUploadProgress=null,cancelToken=null){
    return VOIP_API.axios.voip.request({
      url: `updateprofilepicture`,
      method: 'POST',
      data,
      onUploadProgress,
      cancelToken,
    })
  },
  userinvitations(data={}) {
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'user_invitation',
        type: 'relay',
      },
    })
  },
  userinvitationslist(data={}) {
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'user_invitation_list',
        type: 'relay',
      },
    })
  },
  retryuserinvitation(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: "portal-proxy",
      data: {
        ...data,
        action: 'user-invitation-action',
        type: 'relay',
      },
    })
  },
  canceluserinvitation(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: "portal-proxy",
      data: {
        ...data,
        action: 'user-invitation-action',
        type: 'relay',
      },
    })
  },
  forgotpass(data={}) {
    return VOIP_API.axios.voip_relay.request({
      method: 'post',
      url: 'forgot-password',
      data,
    })
  },
  signups(data={}){
    return VOIP_API.axios.voip_relay.request({
      url: `signups`,
      method: "POST",
      headers: {
        Accept: "application/json",
      },
      data,
    })
  },
  domaindetail(domain){
    return VOIP_API.axios.voip_relay.request({
      method: 'GET',
      url: `domain-detail${domain}`,
    })
  },
  resetpass(data={}) {
    return VOIP_API.axios.voip_relay.request({
      method: 'post',
      url: 'reset-password',
      data,
    })
  },
  organizationLogin(data){
    return VOIP_API.axios.voip_relay.request({
      method: 'POST',
      url: 'origanzation-login',
      data,
    });
  },
  updateExtension(data){
    return VOIP_API.axios.voip.request({
      url: "updateextension",
      method: "POST",
      data,
    })
  },    
  invitationToken(data={}){
    return VOIP_API.axios.voip_relay.request({
      url: "oauth/token",
      method: "POST",
      data,
    })
  },
  addorganizationuser(data={}){
    return VOIP_API.axios.voip.request({
      url: "billingcons",
      method: "POST",
      data: {
        ...data,
        action: 'add_user_origanization',
        type: 'relay',
        service: "user"
      },
    })
  },
  groups(){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        action: 'email_groups',
        type: 'relay',
      },
    })
  },
  updateUserEmailGroups(data){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'update_user_email_groups',
        type: 'relay',
      },
    })
  },
  usergroups(data){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'user_email_groups',
        type: 'relay',
      },
    })
  },
  account(accountcode,data){
    return VOIP_API.axios.voip.request({
      url: `portal-proxy`,
      method: "POST",
      data: {
        ...data,
        id: accountcode,
        type: 'relay',
      },
    })
  },
  extensionAccountInfo(params){
    return VOIP_API.axios.voip.request({
      url: `get-user-extension-data`,
      method: "GET",
      params,
    })
  },
  leaveoriganization(accountcode,data){
    return VOIP_API.axios.voip.request({
      url: `portal-proxy`,
      method: "POST",
      data: {
        ...data,
        id: accountcode,
        action: 'leave_user_origanization',
        type: 'relay'
      },
    })
  },
  organizationlist(data={}){
    return VOIP_API.axios.voip.request({
      url: `portal-proxy`,
      method: "POST",
      data: {
        ...data,
        action: 'list_user_origanization',
        type: 'relay'
      },
    })
  },
  updateCallWaiting(data={}){
    return VOIP_API.axios.voip.request({
      url: `update-user-detail`,
      method: "POST",
      data: data,
    })
  },
}