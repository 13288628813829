var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section ProductSection"
  }, [_c('div', {
    staticClass: "crm-main-section-topBar"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading mb-0"
  }, [_vm._v(" Products ")]), _c('div', {
    staticClass: "section-topBar-middlePart"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": "Search"
    }
  })], 1), _c('div', {
    staticClass: "section-topBar-lastPart"
  }, [_c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CreateProductModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [_vm._v(" Create Products ")])], 1)])]), _c('div', {
    staticClass: "crm-main-section-body"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-end align-items-center mb-3"
  }, [_c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  })], 1)]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.tableData),
      "listLength": _vm.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', [_c('b-form-checkbox', {
            staticClass: "crm-checkbox",
            attrs: {
              "id": "checkbox-1"
            },
            model: {
              value: data.chk,
              callback: function ($$v) {
                _vm.$set(data, "chk", $$v);
              },
              expression: "data.chk"
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.m1))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.m1))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            class: `StatusInsideTable ml-auto ${index == 2 ? 'active' : 'notActive'}`
          })])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', [_c('b-form-checkbox', {
    staticClass: "crm-checkbox",
    attrs: {
      "id": "checkbox-1"
    }
  })], 1), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("SKU")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Unit Price")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Tax Rate")])]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', [_vm._v("Active")])])])])], 2)], 1), _c('modal', {
    staticClass: "dialer_animation right_side_popup scrollable bigSquareCheckbox IosModal CRM-modal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": "CreateProductModal"
    }
  }, [_c('div', {
    staticClass: "CRM-modal-header"
  }, [_c('div', {
    staticClass: "CRM-modal-header-text"
  }, [_vm._v("Create product")]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('CreateProductModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('b-form', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Name")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Name"
    }
  })], 1), _c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("SKU")]), _c('b-form-input', {
    attrs: {
      "placeholder": "SKU"
    }
  })], 1), _c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Description")]), _c('textarea', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Description"
    }
  })]), _c('div', {
    staticClass: "d-flex w-100"
  }, [_c('div', {
    staticClass: "crm-input-container min-width-200px pr-2 w-50"
  }, [_c('label', [_vm._v("Unit price")]), _c('div', {
    staticClass: "crm-input-inner"
  }, [_c('div', {
    staticClass: "crm-input-icon onlyText"
  }, [_vm._v(" GBP ")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Unit price"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-input-container min-width-200px pl-2 w-50"
  }, [_c('label', [_vm._v("Direct cost")]), _c('div', {
    staticClass: "crm-input-inner"
  }, [_c('div', {
    staticClass: "crm-input-icon onlyText"
  }, [_vm._v(" GBP ")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Direct cost"
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex w-100"
  }, [_c('div', {
    staticClass: "crm-input-container withoutIcon min-width-200px pr-2 w-50"
  }, [_c('label', [_vm._v("Tax rate")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Tax rate"
    }
  })], 1), _c('div', {
    staticClass: "crm-input-container withoutIcon min-width-200px pl-2 w-50"
  }, [_c('label', [_vm._v("Tax label")]), _c('b-form-input', {
    attrs: {
      "placeholder": "TAX"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Unit ( kg , lots )")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Unit ( kg , lots )"
    }
  })], 1), _c('b-button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Submit ")])], 1)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }