
import { VOIP_API } from "../index"

export const TEST_MODE_API_ENDPOINTS = {
  get(params={}){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `test/mode`,
      params,
    })
  },
  update(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'toggle/test/mode',
      data,
    })
  },
}