var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-ivr-setting TeamsSettings"
  }, [_c('div', {
    staticClass: "dialer-flex"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Notes")])], 1)] : _c('h2', {
    staticClass: "dialer-settings-title"
  }, [_vm._v("Notes")])], 2), _vm.getCurrentUser.logs_super_mode ? [_c('vb-select', {
    model: {
      value: _vm.selected.account,
      callback: function ($$v) {
        _vm.$set(_vm.selected, "account", $$v);
      },
      expression: "selected.account"
    }
  })] : _vm._e(), _vm.selected.account ? [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between md-mar-top md-mar-bottom teams-settings-2nd-section"
  }, [_c('span', [_vm._v("Here you can create teams of users who need to receive calls simultaneously. For example a sales team, or an office location.")]), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center w-50"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox"
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Search Note"
    },
    model: {
      value: _vm.filter.notes.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.notes, "search", $$v);
      },
      expression: "filter.notes.search"
    }
  })], 1)])]), _c('vb-table', {
    attrs: {
      "id": "teams-table",
      "isListEmpty": _vm.isEmpty(_vm.fetchNotes),
      "listLength": _vm.fetchNotes.length,
      "perPage": 5,
      "loading": _vm.api.notes.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [_vm._l(_vm.fetchNotes, function (team, index) {
          return [_c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: team.id,
            staticClass: "dialer-row-select",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('EditTeamModal', {
                  team: team
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('Info', {
            attrs: {
              "image": _vm._f("get_property")(team, 'avatar.image.image'),
              "name": team.name || '',
              "sub_info": _vm._f("filterExtension")(team.ringroup)
            }
          })], 1), _c('td', {
            staticClass: "dialer-col-left font-weight-bold"
          }, [team.ringroup && team.ringroup.did && team.ringroup.did[0] ? _c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "sm-mar-left font-weight-bold min-width-160px"
          }, [_c('span', {
            staticClass: "mr-1",
            staticStyle: {
              "height": "45px",
              "width": "45px"
            }
          }, [_c('img', {
            staticClass: "country-img",
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(team.ringroup.did[0].did))
            }
          })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(team.ringroup.did[0].did)) + " ")]), team.ringroup.did.length > 1 ? _c('div', {
            staticClass: "w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                _vm.conditions.more = _vm.conditions.more == team.id ? '' : team.id;
              }
            }
          }, [_c('div', {
            staticClass: "px-2 rounded more-numbers-tag"
          }, [_c('small', [_vm._v("+ " + _vm._s(team.ringroup.did.length - 1) + " more")])])]) : _vm._e()]) : [_c('span', {
            staticClass: "text-center"
          }, [_vm._v(" - ")])]], 2), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end"
          }, [_c('a', {
            staticClass: "dialer-link-more",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.$modal.show('AssignNumbersTeam', {
                  team: team,
                  type: 'AssignNumbersTeam'
                });
              }
            }
          }, [_vm._v(_vm._s(_vm._f("memberCount")(team.members)) + " users")])])])])];
        })];
      }
    }], null, false, 2236018410)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Numbers")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }, [_c('span', [_vm._v("Assigned To")])])])])], 2)] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }