var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "unsec-common"
  }, [_vm._m(0), _c('p', {
    staticClass: "notes-top-text space4text-when-scroll-activate"
  }, [_vm._v(" You can edit individual allowance for specific employees. The edited days will be applied to all future accrual periods. ")]), _c('div', {
    staticClass: "bg-modal-doc-grey-share-with mt-5"
  }, [_c('div', {
    staticClass: "calender-hrm-height mb-4"
  }, [_c('div', [_c('label', {
    staticClass: "calender-form-labels mr-imp"
  }, [_vm._v("Location")]), _c('div', {
    staticClass: "dd-container mt-1"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("Location")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Team")]), _c('div', {
    staticClass: "dd-container mt-1"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("Team")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Voip")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Kuflink")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Time off policy")]), _c('div', {
    staticClass: "opendd-container mt-1"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi spcfy-hi8-4-opn1",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('div', {
          staticClass: "d-flex flex-wrap"
        }, [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center slected-custom-border"
        }, [_c('h5', {
          staticClass: "topolicy-selected-head"
        }, [_vm._v("Holiday")]), _c('b-icon', {
          staticClass: "crossIconSvg",
          attrs: {
            "icon": "x-lg"
          }
        })], 1)]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Voip")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Kuflink")])], 1)], 1)])])]), _c('vb-table', {
    staticClass: "indivisual-allowance-table-width mt-5",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth onetd col-first-entryleft"
  }, [_vm._v("Employee")]), _c('h5', {
    staticClass: "commonth secondtd"
  }, [_vm._v("Holiday")]), _c('h5', {
    staticClass: "commonth thirdtd"
  }, [_vm._v("Sickday")]), _c('h5', {
    staticClass: "commonth fourtd"
  }, [_vm._v("Holiday -UK")]), _c('h5', {
    staticClass: "commonth fivetd"
  }, [_vm._v("Holiday -USA")]), _c('h5', {
    staticClass: "commonth sixtd"
  }, [_vm._v("Holiday -PAK")]), _c('h5', {
    staticClass: "commonth seventd col-right"
  }, [_vm._v("Business Trip")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" 02 days ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" 20 days ")]), _c('td', {
    staticClass: "seventd indivisual-allowances-detail-last"
  }, [_vm._v(" 02 days ")])]), _c('div', {
    staticClass: "HrmTablePaginationMain"
  }, [_c('div', {
    staticClass: "HrmTablePaginationMiddle"
  }, [_c('div', {
    staticClass: "HrmTablePaginationButton"
  }, [_c('svg', {
    staticClass: "HrmTablePaginationSvg Extrafast",
    attrs: {
      "width": "13.825",
      "height": "8.326",
      "viewBox": "0 0 13.825 8.326",
      "fill": "",
      "stroke-width": "",
      "stroke-linecap": "",
      "stroke-linejoin": "",
      "stroke": ""
    }
  }, [_c('path', {
    attrs: {
      "id": "arrowDown",
      "d": "M0,0,5.5,5.5,11,0",
      "transform": "translate(1.414 1.414)",
      "fill": "none",
      "stroke": "#5b5b5b",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })]), _c('svg', {
    staticClass: "HrmTablePaginationSvg Extrafast",
    attrs: {
      "width": "13.825",
      "height": "8.326",
      "viewBox": "0 0 13.825 8.326",
      "fill": "",
      "stroke-width": "",
      "stroke-linecap": "",
      "stroke-linejoin": "",
      "stroke": ""
    }
  }, [_c('path', {
    attrs: {
      "id": "arrowDown",
      "d": "M0,0,5.5,5.5,11,0",
      "transform": "translate(1.414 1.414)",
      "fill": "none",
      "stroke": "#5b5b5b",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])]), _c('div', {
    staticClass: "HrmTablePaginationButton"
  }, [_c('svg', {
    staticClass: "HrmTablePaginationSvg single",
    attrs: {
      "width": "13.825",
      "height": "8.326",
      "viewBox": "0 0 13.825 8.326",
      "fill": "",
      "stroke-width": "",
      "stroke-linecap": "",
      "stroke-linejoin": "",
      "stroke": ""
    }
  }, [_c('path', {
    attrs: {
      "id": "arrowDown",
      "d": "M0,0,5.5,5.5,11,0",
      "transform": "translate(1.414 1.414)",
      "fill": "none",
      "stroke": "#5b5b5b",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])]), _c('div', {
    staticClass: "HrmTablePaginationDigits"
  }, [_vm._v("1")]), _c('div', {
    staticClass: "HrmTablePaginationDigits"
  }, [_vm._v("2")]), _c('div', {
    staticClass: "HrmTablePaginationButton"
  }, [_c('svg', {
    attrs: {
      "width": "13.825",
      "height": "8.326",
      "viewBox": "0 0 13.825 8.326",
      "fill": "",
      "stroke-width": "",
      "stroke-linecap": "",
      "stroke-linejoin": "",
      "stroke": ""
    }
  }, [_c('path', {
    attrs: {
      "id": "arrowDown",
      "d": "M0,0,5.5,5.5,11,0",
      "transform": "translate(1.414 1.414)",
      "fill": "none",
      "stroke": "#5b5b5b",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])]), _c('div', {
    staticClass: "HrmTablePaginationButton"
  }, [_c('svg', {
    attrs: {
      "width": "13.825",
      "height": "8.326",
      "viewBox": "0 0 13.825 8.326",
      "fill": "",
      "stroke-width": "",
      "stroke-linecap": "",
      "stroke-linejoin": "",
      "stroke": ""
    }
  }, [_c('path', {
    attrs: {
      "id": "arrowDown",
      "d": "M0,0,5.5,5.5,11,0",
      "transform": "translate(1.414 1.414)",
      "fill": "none",
      "stroke": "#5b5b5b",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })]), _c('svg', {
    attrs: {
      "width": "13.825",
      "height": "8.326",
      "viewBox": "0 0 13.825 8.326",
      "fill": "",
      "stroke-width": "",
      "stroke-linecap": "",
      "stroke-linejoin": "",
      "stroke": ""
    }
  }, [_c('path', {
    attrs: {
      "id": "arrowDown",
      "d": "M0,0,5.5,5.5,11,0",
      "transform": "translate(1.414 1.414)",
      "fill": "none",
      "stroke": "#5b5b5b",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])])]), _c('div', {
    staticClass: "HrmTablePaginationLast"
  }, [_c('h5', [_vm._v("Result per page")])])])])], 2)], 1)]), _c('CreateNewPolicyTimeoffModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Individual allowances")])])]);

}]

export { render, staticRenderFns }