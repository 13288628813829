<template>
  <!-- <div v-if="!isEmpty(track)" :class="{ 'local-track': true, 'h-inherit': track.type === 'video' }"> -->
  <div v-if="!isEmpty(track)" class="remote-track-container">
    <!-- <div v-if="track.type === 'video'" class="remote-track-conatiner"> -->
      <div v-if="track.type === 'video'" class="video-container">
        <video playsinline :id="id" autoplay="1" :class="{
        'share-screen': track.videoType=='desktop',
      }" />
      </div>
    <!-- </div> -->
    <!-- <div v-if="track.type === 'audio'" class="remote-track-conatiner"> -->
      <div v-if="track.type === 'audio'" class="audio-container">
        <audio :id="id" muted autoplay="1" />
      </div>
    <!-- </div> -->
  </div>
</template>

<script>
// import _ from "lodash";
import JitsiMeetJS from "@/Jitsi";
import { LOGGER } from "../../../utils";
export default { 
  name: "LocalTrack",
  props: {
    index: {
      type: Number,
    },
    localTrack: {
      type: Object,
    },
    JitsiConference: {
      type: Object,
    },
    isJoined: {
      type: Boolean,
    },
    isVideoMuted: {
      type: Boolean,
    },
    payload: {
      type: Object,
    },
    listIndex: {
      type: Number,
    },
  },
  data() {
    return {
      track: null,

      id: "",
    };
  },
  inject:['isEmpty'],
  watch: {
    track: "watchingTrack",
    // isJoined: "watchingIsJoined",
  },
  methods: {
    //--watching--
    // watchingIsJoined(IsJoined) {
    //   if (IsJoined) {
    //     this.JitsiConference.addTrack(this.track);
    //     LOGGER.info("track added", { JitsiLocalTrack: this.track });
    //   }
    // },
    watchingTrack(track) {
      if (track !== null) {
        const {
          events: { track: events },
        } = JitsiMeetJS;
        track.addEventListener(
          events.LOCAL_TRACK_STOPPED,
          this.LOCAL_TRACK_STOPPED
        );
        track.addEventListener(events.NO_AUDIO_INPUT, this.NO_AUDIO_INPUT);
        track.addEventListener(
          events.NO_DATA_FROM_SOURCE,
          this.NO_DATA_FROM_SOURCE
        );
        track.addEventListener(
          events.TRACK_AUDIO_LEVEL_CHANGED,
          this.TRACK_AUDIO_LEVEL_CHANGED
        );
        track.addEventListener(
          events.TRACK_AUDIO_OUTPUT_CHANGED,
          this.TRACK_AUDIO_OUTPUT_CHANGED
        );
        track.addEventListener(
          events.TRACK_MUTE_CHANGED,
          this.TRACK_MUTE_CHANGED
        );
        track.addEventListener(
          events.TRACK_VIDEOTYPE_CHANGED,
          this.TRACK_VIDEOTYPE_CHANGED
        );
      }
    },

    //--other--

    //--events--
    LOCAL_TRACK_STOPPED() {
      LOGGER.event("LOCAL_TRACK_STOPPED");
      if (this.track.videoType === "desktop") {
        this.$store.state.calls.videoCalls[this.listIndex].isVideo =
          !this.$store.state.calls.videoCalls[this.listIndex].isVideo;
      }
    },
    NO_AUDIO_INPUT() {
      LOGGER.event("NO_AUDIO_INPUT");
    },
    NO_DATA_FROM_SOURCE() {
      LOGGER.event("NO_DATA_FROM_SOURCE");
    },
    TRACK_AUDIO_LEVEL_CHANGED() {
      LOGGER.event("TRACK_AUDIO_LEVEL_CHANGED");
    },
    TRACK_AUDIO_OUTPUT_CHANGED() {
      LOGGER.event("TRACK_AUDIO_OUTPUT_CHANGED");
    },
    TRACK_MUTE_CHANGED(JitsiLocalTrack) {
      LOGGER.event("TRACK_MUTE_CHANGED", { JitsiLocalTrack });
    },
    TRACK_VIDEOTYPE_CHANGED(videoType) {
      LOGGER.event("TRACK_VIDEOTYPE_CHANGED", { videoType });
    },
  },
  created() {
    this.track = this.localTrack;
    this.id = `local_${this.track.type}_${this.listIndex}`;
  },
  mounted() {
    try {
      if(this.track.type === 'video'){
        const el = this.$el.querySelector(`#${this.id}`);
        this.track.attach(el);
      }
      // if (this.isJoined) {
      //   this.JitsiConference.addTrack(this.track);
      //   LOGGER.info("track added", { JitsiLocalTrack: this.track });
      // }
    } catch (error) {
      LOGGER.danger("error in local track component mounted", {
        id: this.id,
        error,
      });
    }
  },
  beforeDestroy() {
    try {
      LOGGER.hook("beforeDestroy in localTrack");
      // this.track.dispose();
    } catch (error) {
      LOGGER.danger("error in beforeDestroy hook for localtrack", error);
    }
  },
};
</script>

<style lang="scss" scoped>
.local-track {
  height: auto;
  .video-container {
    height: inherit;
    width: 100%;
    video {
      height: inherit;
      width: 100%;
      // object-fit: fill;
      // transform: rotateY(180deg);
      // -webkit-transform: rotateY(180deg);
      // -moz-transform: rotateY(180deg);
      object-fit: contain;
      background-color: rgba(0, 0, 0, 0.6);
    }
    .video-hidden {
      height: inherit;
      width: 100%;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: rgba(0, 0, 0, 0.6);
      div {
        height: 50%;
        width: 50%;
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: white;
        border-radius: 50%;
        overflow: hidden;
        img {
          height: 100%;
          width: 100%;
        }
      }
    }
  }
  .audio-container {
    audio {
      height: 0px;
    }
  }
}
.h-inherit {
  height: inherit;
}
</style>