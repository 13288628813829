<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Business hours</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="lg-mar-bottom">
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideCard">Set Business hours</div>
              <div class="position-relative">
                <b-form-checkbox 
                  class="dialer-switch IosSwitch" 
                  ref="switch_ooh"
                  :disabled="api.update_out_of_hours.send"
                  name="check-button"
                  switch
                  :checked="response.out_of_hours" 
                  @change="updateOutOfHours($event)"
                />
              </div>
            </div>
          </div>
          <div class="basicWhiteIOScard mt-16px mb-16px">
            <template v-if="response.out_of_hours">
              <div 
                v-if="conditions.default" 
                class="basicWhiteIOScard-item" 
                @click="$modal.show(`${randomID}CallForwardSettingOutOfHourModal`,{ 
                  account: data.account, 
                  setting: forwardSetting,
                  is_sec_show: false 
                })"
              >
                <div class="headingInsideCard">Default Setting</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">
                    <template v-if="forwardSetting.forward_note == 'voicemail'">Voicemail</template>
                    <template v-else-if="forwardSetting.forward_note == 'extension'">Redirect to {{forwardSetting.extension_name || forwardSetting.forward_number}}</template>
                    <template v-else-if="forwardSetting.forward_note == 'number'">Redirect to {{forwardSetting.forward_number}}</template>
                    <template v-else>Keep Ringing</template>
                  </div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <template v-if="conditions.days">
                <div 
                  class="basicWhiteIOScard-item"
                  v-for="day in weekDays"
                  :key="day.value" 
                  @click="$modal.show(`${randomID}-EditOutOfHoursDayModal`,{ day: day.value })"
                >
                  <div class="d-flex flex-column">
                    <div class="headingInsideCard">{{ day.text }}</div>
                    <div class="textInsideCard font-10px">Custom</div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="d-flex flex-column mr-8px text-right">
                      <div :class="`${days[day.value].type!='availble' ? 'redColor' : 'greenColor'}`" class="textInsideCard text-capitalize">{{ days[day.value] | filterTypeText }}</div>
                      <div class="textInsideCard font-10px">{{ days | get_property(`${day.value}`) | filterInfoText }}</div>
                    </div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
              </template>
            </template>
          </div>
        </div>
        <EditOutOfHoursDayModal 
          :modalName="`${randomID}-EditOutOfHoursDayModal`" 
          :account="data.account" 
          :schedules="response.schedules" 
          :randomID="randomID"
          @updated="response.schedules=$event;" 
        />
        <CallForwardingModal 
          :modalName="`${randomID}CallForwardSettingOutOfHourModal`" 
          :randomID="randomID"
          @updated="updateDefaultSetting($event)"
        />
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VOIP_API } from '@/utils'
import EditOutOfHoursDayModal from './EditOutOfHourDay.vue'
import CallForwardingModal from '../callforwarding/CallForwarding.vue'
let week_days = [
  {
    value: "mon",
    text: "Monday",
  },
  {
    value: "tue",
    text: "Tuesday",
  },
  {
    value: "wed",
    text: "Wednesday",
  },
  {
    value: "thu",
    text: "Thursday",
  },
  {
    value: "fri",
    text: "Friday",
  },
  {
    value: "sat",
    text: "Saturday",
  },
  {
    value: "sun",
    text: "Sunday",
  },
]
export default {
  name: 'OutOfHourModal',
  components: {
    EditOutOfHoursDayModal,
    CallForwardingModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'OutOfHour',
    },
    randomID: {
      type: String,
      default: '',
    },
  },
  data(){
    return {
      api: {
        route_call: this.$helperFunction.apiInstance(),
        schedules: this.$helperFunction.apiInstance(),
        update_out_of_hours: this.$helperFunction.apiInstance(),
        update_schedule: this.$helperFunction.apiInstance({ source: true }),
        get_setting: this.$helperFunction.apiInstance(),
      },
      response: {
        schedules: [],
        out_of_hours: false,
        default_setting: {},
      },
      selected: {
        day: week_days[0].value,
      },
      data: {
        account: '',
        conditions: {},
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
    ]),
    weekDays() { return week_days; },
    days() {
      return {
        mon: this.response.schedules?.find?.(i=>i.day=='mon') ?? {},
        tue: this.response.schedules?.find?.(i=>i.day=='tue') ?? {},
        wed: this.response.schedules?.find?.(i=>i.day=='wed') ?? {},
        thu: this.response.schedules?.find?.(i=>i.day=='thu') ?? {},
        fri: this.response.schedules?.find?.(i=>i.day=='fri') ?? {},
        sat: this.response.schedules?.find?.(i=>i.day=='sat') ?? {},
        sun: this.response.schedules?.find?.(i=>i.day=='sun') ?? {},
      };
    },
    forwardSetting(){
      return {
        extension_name: this.response?.default_setting?.forward_name ?? '',
        forward_note: this.response?.default_setting?.extension_type || 'none',
        forward_number: this.response?.default_setting?.forward_to ?? '',
        number: this.response?.default_setting?.forward_to ?? '',
      }
    },
    conditions(){
      return {
        days: !!(this.data.conditions?.days ?? true),
        default: !!(this.data.conditions?.default ?? true),
      }
    },
  },
  filters: {
    filterTypeText(day){ 
      return day?.type === "availble" || !day?.type ? "available all day" : day?.type === "unavailable" ? "unavailable all day" : `${day.start_time} - ${day.end_time}` 
    },
    filterInfoText(day){
      const value = day?.forward_type ?? ''
      const name = day?.forward_name ?? ''
      const number = day?.forward_account ?? ''
      if(value=='voicemail') return 'Call will be forward to voicemail'
      else if(value=='extension') return `Call will be forward to ${name}`
      else if(value=='number') return `Call will be forward to ${number}`
      else return 'Call forwarding is off'
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.account = event?.params?.account ?? ''
      this.data.conditions = event?.params?.conditions ?? {}
      this.fetchSchedule()
      this.fetchcallRouteSetting()
      this.fetchDefaultSetting()
    },
    onBeforeClose(){
      this.api.route_call.reset()
      this.api.schedules.reset()
      this.api.update_out_of_hours.reset()
      this.api.get_setting.reset()
      this.api.update_schedule.reset()
      this.response.schedules=[]
      this.response.default_setting={}
      this.response.out_of_hours=false
      this.selected.day=week_days[0].value
      this.data.account=''
      this.data.conditions={}
    },
    fetchSchedule() {
      let vm = this
      if(vm.api.schedules.send) return;
      vm.api.schedules.send = true;
      VOIP_API.endpoints.users.schedules(vm.data.account, {
        fid: 12,
      })
      .then(({ data: res }) => {
        vm.response.schedules = res?.data ?? [];
      })
      .finally(() => {
        if(!vm.selected.day) vm.selected.day = "mon";
        vm.api.schedules.send = false;
      });
    },
    fetchcallRouteSetting() {
      let vm = this
      if(vm.api.route_call.send) return;
      vm.api.route_call.send = true;
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.data.account,
      })
      .then(({ data }) => {
        vm.response.out_of_hours = !!data.schedule_enable;
      })
      .finally(() => {
        vm.api.route_call.send = false;
      });
    },
    updateOutOfHours(value){
      let vm = this
      if(vm.api.update_out_of_hours.send) return;
      vm.api.update_out_of_hours.send=true
      VOIP_API.endpoints.users.updateoutofhours({
        field: 'schedule',
        uid: vm.getCurrentUser?.uid,
        value: value?'1':'0',
        accountcode: vm.data.account
      })
      .then(()=>{
        vm.response.out_of_hours=value
        vm.appNotify({
          message: 'Successfully Updated',
          type: "success",
        })
        vm.fetchcallRouteSetting()
        vm.$emit('ofh-toggle-updated')
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.$refs.switch_ooh.localChecked=vm.response.out_of_hours
        vm.api.update_out_of_hours.send=false
      })
    },
    
    fetchDefaultSetting(){
      let vm = this
      if(vm.api.get_setting.send) return;
      vm.api.get_setting.send = true;
      VOIP_API.endpoints.users.getscheduledefault(vm.data.account)
      .then(({ data }) => {
        vm.response.default_setting = data;
      })
      .finally(() => {
        vm.api.get_setting.send = false;
      });
    },
    updateDefaultSetting(setting={}){
      let vm = this;
      vm.api.update_schedule.send = true;
      const toaster_value = vm.appNotify({
        message: "Schedule setting Saving...",
        type: "success",
        duration: 60 * 1000,
      });
      const forward = setting?.forward_note=='off' ? 'none' : setting?.forward_note ?? vm.response.default_setting.extension_type
      VOIP_API.endpoints.users.updatescheduledefault({
        account: vm.data.account,
        extn: setting?.forward_note ?? vm.response.default_setting.extn,
        forward: forward,
        forward_account: setting?.forward_number ?? vm.response.default_setting.forward_account,
        forward_name: setting?.extension_name ?? vm.response.default_setting.forward_name,
        ext_type: forward,
        type: setting?.type ?? vm.response.default_setting.type,
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        vm.fetchDefaultSetting()
        vm.fetchSchedule()
        vm.$emit('ofh-default-updated')
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        toaster_value.close();
        vm.api.update_schedule.send = false;
      });
    },
  },
}
</script>

<style>

</style>