var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading"
  }, [_vm._v("Assets")]), _vm._m(0), _c('div', {
    staticClass: "grayCard"
  }, [_c('div', {
    staticClass: "leftSide halfWidth"
  }, [_vm._m(1), _vm._m(2), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Employees")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd bgWhite",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All Employees ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Category")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd bgWhite",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All Category ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Location")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd bgWhite",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("Test")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All Test ")])], 1)], 1)]), _vm._m(3)]), _c('div', {
    staticClass: "nameWithImage mt-32px"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/1.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Mellissa")])]), _c('div', {
    staticClass: "EmployeeDataAssetsTable mt-32px"
  }, [_vm._m(4), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_vm._v(" Laptop ")]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v(" Computer ")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_vm._v(" 445566 ")])])];
      },
      proxy: true
    }])
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription"
  }, [_c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "whiteSubCard"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Total assets ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("24")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "whiteSubCard"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Assets given out ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("2")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "rightSide halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Assets available ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("50")])])]), _c('div', {
    staticClass: "whiteSubCard"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Asset categories ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-center"
  }, [_c('canvas', {
    attrs: {
      "id": "doughnut-chart"
    }
  })])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("NAme")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("Category")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Description")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("Serial number")])]);

}]

export { render, staticRenderFns }