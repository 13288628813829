<template>
  <div>
    <div 
      class="basicWhiteIOScard-item withBottomBorder" 
      @click="checkPermission(getUserPermissions.custom_moh,test)"
    >
      <div class="headingInsideCard">Music on hold</div>
      <div class="d-flex align-items-center">
        <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ music_on_hold.name }}</div>
        <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
      </div>
    </div>
    <SelectPlaylistModal :modalName="`${randomID}-SelectPlaylist`" @updated="callRouteSetting()" />
  </div>
</template>

<script>
import { VOIP_API, $fn } from '@/utils';
import SelectPlaylistModal from "../modals/playlist/SelectPlaylist.vue";
import { mapGetters } from 'vuex';
import { permission_handling } from '@/mixin';
// import { Fragment } from 'vue-fragment'
export default {
  name: 'MusicOnHoldWidget',
  mixins: [permission_handling],
  components: {
    // Fragment,
    SelectPlaylistModal,
  },
  props: {
    accountcode: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      random_id: '',
      api: {
        call_route: this.$helperFunction.apiInstance({ source: true }),
      },
      music_on_hold: {
        enable: false,
        id: '',
        name: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser'
    ]),
    randomID(){ return this.random_id },
    isGlobal(){ return this.getCurrentUser.default_accountcode==this.accountcode },
  },
  methods: {
    test(){
      // console.log('run')
      this.$modal.show(`${this.randomID}-SelectPlaylist`,{
        account: this.accountcode, 
        id: this.music_on_hold.id 
      })
    },
    callRouteSetting() {
      let vm = this
      vm.api.call_route.send = true;
      vm.api.call_route.source?.cancel?.("Requesting Again");
      vm.api.call_route.source = require("axios").default.CancelToken.source()
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.accountcode,
        flag: vm.isGlobal ? 'globalmoh' : ''
      },vm.api.call_route.source.token)
      .then(({ data: call_route }) => {
        vm.music_on_hold.enable = !!call_route?.playlistid;
        vm.music_on_hold.id = call_route?.playlistid ?? '';
        vm.music_on_hold.name = call_route?.playlistname ?? '';
      })
      .finally(() => {
        vm.api.call_route.send = false;
        vm.api.call_route.source = null;
      });
    },
  },
  created(){
    this.random_id = $fn.makeid(10)
  },
  mounted(){
    this.callRouteSetting()
  },
  activated(){
    this.callRouteSetting()
  },
}
</script>

<style>

</style>