var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-card halfWidth"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', [_vm._v(" Won deals by day "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content mr-3 withCustomSvgCheck"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedUser, 'name')))]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.voipusers, function (user) {
    return _c('b-dropdown-item', {
      key: user.voipaccount,
      on: {
        "click": function ($event) {
          _vm.filters.won_deals_by_day.user_id = user.voipaccount;
          _vm.fetchWonDealsByDay();
        }
      }
    }, [_vm.filters.won_deals_by_day.user_id == user.voipaccount ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(user.user.display_name))])], 1);
  }), 1)], 1), _c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedRange, 'label')))]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.ranges, function (range) {
    return _c('b-dropdown-item', {
      key: range.value,
      on: {
        "click": function ($event) {
          _vm.filters.won_deals_by_day.range = range.value;
          _vm.fetchWonDealsByDay();
        }
      }
    }, [_vm.filters.won_deals_by_day.range == range.value ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(range.label))])], 1);
  }), 1)], 1)])]), _c('div', {
    staticClass: "crm-graph-container w-auto h-auto",
    staticStyle: {
      "position": "relative"
    }
  }, [_c('canvas', {
    ref: "won_deals_by_day_chart"
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }