var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: ['content-area d-flex h-100 newChatInterface', _vm.applyBackground ? 'calling_active' : '']
  }, [_c('ChatCopy')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }