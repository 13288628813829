<template>
	<div class="right-calender">
		<div class="top-main-calender scrollow1">
			<section class="sec-common">
				<h5 class="d-flex align-items-center justify-content-start emp-profile-heading">Personal</h5>
				<div class="personal-top-box">
					<div class="calender-hrm-height mb-3">
						<h5 class="calender-page-subheading mt-2">Personal Information</h5>
						<div class="personal-info-box">
							<div class="d-flex w-100 flex-wrap">
								<div class="p-info-width">
									<h5 class="p-info-head">Date of birth</h5>
									<p class="p-info-text">15/07/1992</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">Street</h5>
									<p class="p-info-text">Mariotteplein 199</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">City</h5>
									<p class="p-info-text">Amsterdam</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">State</h5>
									<p class="p-info-text">Amsterdam</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">Postcoded</h5>
									<p class="p-info-text">1090</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">Country</h5>
									<p class="p-info-text">London</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">Nationality</h5>
									<p class="p-info-text">British</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">Home phone </h5>
									<p class="p-info-text">441873740607</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">Home phone</h5>
									<p class="p-info-text">441873740607</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">Personal e-mail</h5>
									<p class="p-info-text">amber@vavoip.com</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">Gender</h5>
									<p class="p-info-text">Female</p>
								</div>
								<div class="p-info-width">
									<h5 class="p-info-head">Martial status</h5>
									<p class="p-info-text">Single</p>
								</div>
								<div class="p-info-width"></div>
								<div class="p-info-width"></div>
								<div class="p-info-button">
									<button class="btn-svg-none" @click="$modal.show('HRMEditPersonInfoModal')">
										<svg xmlns="http://www.w3.org/2000/svg" width="20" height="20" viewBox="0 0 20 20">
											<path id="Icon_material-edit" data-name="Icon material-edit" d="M4.5,20.33V24.5H8.666L20.953,12.209,16.787,8.043ZM24.175,8.987a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L17.976,6.854l4.166,4.166,2.033-2.033Z" transform="translate(-4.5 -4.496)" fill="#5576d1"/>
										</svg>
									</button>
								</div>
							</div>
						</div>
					</div>
				</div>
			</section>
		</div>
		<HRMEditPersonInfoModal />
	</div>
</template>

<script>
// import HRMPersonalInfoModal from "./components/Modal/HRMPersonalInfoModal.vue"
import HRMEditPersonInfoModal from "../Modals/HRMEditPersonInfoModal.vue"
// HRMEditPersonInfoModal.vue
export default {
  name: 'PersonalHRM',
  components : {
	HRMEditPersonInfoModal,
  },
}
</script>

<style>

</style>