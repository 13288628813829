var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": "AddLocationModal"
    },
    on: {
      "closed": _vm.onClose,
      "before-open": _vm.beforeOpen
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddLocationModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddLocationModal');
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Location")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addLocation.apply(null, arguments);
      }
    }
  }, [_vm.data.flag == 'purchased' ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("User Home Address")])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api_sent.add
    },
    on: {
      "change": function ($event) {
        $event ? _vm.forms.add_location.callerId = '' : '';
      }
    },
    model: {
      value: _vm.is_user_home_address,
      callback: function ($$v) {
        _vm.is_user_home_address = $$v;
      },
      expression: "is_user_home_address"
    }
  })], 1)])]), _vm.is_user_home_address ? [_vm.add_caller_id_screen == 'number' ? [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Caller ID")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.adding_caller_id.number,
      expression: "forms.adding_caller_id.number"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "caller-id-input-field",
      "type": "text",
      "onkeypress": "return /([0-9])/i.test(event.key)",
      "maxlength": "12",
      "disabled": _vm.api_sent.check
    },
    domProps: {
      "value": _vm.forms.adding_caller_id.number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.adding_caller_id, "number", $event.target.value);
      }
    }
  })]), _vm.submitted.check ? [!_vm.$v.forms.adding_caller_id.number.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Number is required")])]) : !_vm.$v.forms.adding_caller_id.number.minLength || !_vm.$v.forms.adding_caller_id.number.maxLength ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Number is should be between 8 to 12")])]) : _vm._e()] : _vm._e()], 2)])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "id": "check-button",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        if ($event.target !== $event.currentTarget) return null;
        return _vm.checkValidate();
      }
    }
  }, [_vm.api_sent.check ? _c('vb-spinner') : [_vm._v("Check")]], 2)] : _vm._e(), _vm.add_caller_id_screen == 'pin' ? [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('b-icon', {
    attrs: {
      "id": "back-icon",
      "icon": "arrow-left"
    },
    on: {
      "click": function ($event) {
        _vm.add_caller_id_screen = 'number';
      }
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Enter Pin Code")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.verify_caller_id_pin.pin,
      expression: "forms.verify_caller_id_pin.pin"
    }],
    attrs: {
      "type": "text",
      "maxlength": "6",
      "oninput": "this.value = this.value.replace(/[^0-9]/gi, '')",
      "disabled": _vm.api_sent.verify
    },
    domProps: {
      "value": _vm.forms.verify_caller_id_pin.pin
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.verify_caller_id_pin, "pin", $event.target.value);
      }
    }
  }), _vm.submitted.verify ? [_c('span', [_vm._v("*")]), !_vm.$v.forms.verify_caller_id_pin.pin.required ? _c('p', [_vm._v("Pin is Required")]) : _vm._e(), !_vm.$v.forms.verify_caller_id_pin.pin.minLength ? _c('p', [_vm._v("Pin should be 6 digit")]) : _vm._e()] : _vm._e()], 2)])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "id": "verify-button",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        if ($event.target !== $event.currentTarget) return null;
        return _vm.validatePin();
      }
    }
  }, [_vm.api_sent.verify ? _c('vb-spinner') : [_vm._v("Verify")]], 2)] : _vm._e(), _vm.add_caller_id_screen == 'verified' ? [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Caller ID")]), _c('div', [_c('input', {
    staticClass: "w-100",
    attrs: {
      "id": "caller-id-input-field",
      "type": "text",
      "onkeypress": "return /([0-9])/i.test(event.key)",
      "maxlength": "12",
      "disabled": true
    },
    domProps: {
      "value": _vm.forms.adding_caller_id.number
    }
  })]), _vm.submitted.check ? [!_vm.$v.forms.adding_caller_id.number.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Number is required")])]) : !_vm.$v.forms.adding_caller_id.number.minLength || !_vm.$v.forms.adding_caller_id.number.maxLength ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Number is should be between 8 to 12")])]) : _vm._e()] : _vm._e()], 2)])])] : _vm._e()] : [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Caller ID/ DID")])]), _c('div', {
    staticClass: "dd-updatedIos"
  }, [_c('vb-select', {
    staticClass: "w-100 custom-dd-select",
    attrs: {
      "id": "caller-id-did",
      "listClass": "select-caller-id-options",
      "selectDdDesign": 1,
      "options": _vm.callerIdsOptions,
      "defaultSelectedText": 'Select Extensions',
      "selected": _vm.forms.add_location.callerId,
      "disabled": _vm.api_sent.add || _vm.api_sent.list
    },
    on: {
      "change": function ($event) {
        _vm.forms.add_location.callerId = $event;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "slot": "right-icon",
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    },
    slot: "right-icon"
  })], 1)], 1)])])])], _vm.submitted.add ? [!_vm.$v.forms.add_location.callerId.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Extensions is required")])]) : _vm._e()] : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Country")])]), _c('div', {
    staticClass: "dd-updatedIos"
  }, [_c('vb-select', {
    staticClass: "w-100 custom-dd-select",
    attrs: {
      "id": "select-country",
      "listClass": "select-country-options",
      "selected": _vm.forms.add_location.country,
      "defaultSelectedText": 'Select Country',
      "options": _vm.countriesOptions,
      "selectDdDesign": 1
    },
    on: {
      "change": function ($event) {
        _vm.forms.add_location.country = $event;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "slot": "right-icon",
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    },
    slot: "right-icon"
  })], 1)], 1)]), _c('div', {
    staticClass: "w-100 d-flex flex-column"
  }, [_vm.submitted.add ? [!_vm.$v.forms.add_location.country.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Country is required")])]) : _vm._e()] : _vm._e()], 2)]), _vm.forms.add_location.country === 'GB' ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Enter Address Manually")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api_sent.add
    },
    model: {
      value: _vm.is_manual_address,
      callback: function ($$v) {
        _vm.is_manual_address = $$v;
      },
      expression: "is_manual_address"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("First Name")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_location.forename,
      expression: "forms.add_location.forename"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "first-name",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.add_location.forename
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_location, "forename", $event.target.value);
      }
    }
  })]), _vm.submitted.add ? [!_vm.$v.forms.add_location.forename.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* First Name is required")])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Last Name")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_location.surname,
      expression: "forms.add_location.surname"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "last-name",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.add_location.surname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_location, "surname", $event.target.value);
      }
    }
  })]), _vm.submitted.add ? [!_vm.$v.forms.add_location.surname.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Last Name is required")])]) : _vm._e()] : _vm._e()], 2)])]), _vm.is_manual_address ? [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Street")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.street,
      expression: "forms.manual_address.street"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "manual-street-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.manual_address.street
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "street", $event.target.value);
      }
    }
  })]), _vm.submitted.add ? [!_vm.$v.forms.manual_address.street.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Street is required")])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("City")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.city,
      expression: "forms.manual_address.city"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "manual-city-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.manual_address.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "city", $event.target.value);
      }
    }
  })]), _vm.submitted.add ? [!_vm.$v.forms.manual_address.city.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* City is required")])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Postal Code")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.postal_code,
      expression: "forms.manual_address.postal_code"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "manual-post-code-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.manual_address.postal_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "postal_code", $event.target.value);
      }
    }
  })]), _vm.submitted.add ? [!_vm.$v.forms.manual_address.postal_code.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Postal Code is required")])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("House Number")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.house_name,
      expression: "forms.manual_address.house_name"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "manual-house-number-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.manual_address.house_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "house_name", $event.target.value);
      }
    }
  })]), _vm.submitted.add ? [!_vm.$v.forms.manual_address.house_name.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* House Name is required")])]) : _vm._e()] : _vm._e()], 2)])])] : [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnRight InputIosV2"
  }, [_c('label', [_vm._v("Postal Code")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.uk_address.postal_code,
      expression: "forms.uk_address.postal_code"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "auto-postal-code-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.uk_address.postal_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.uk_address, "postal_code", $event.target.value);
      }
    }
  }), _c('div', {
    staticClass: "mobileInputIOS-icon"
  }, [_vm.api_sent.addresses ? _c('vb-spinner', {
    attrs: {
      "variant": "primary"
    }
  }) : [_vm.api_status.addresses == 'success' ? _c('b-icon', {
    attrs: {
      "icon": "check2",
      "variant": "success"
    }
  }) : _vm._e(), _vm.api_status.addresses == 'fail' ? _c('b-icon', {
    attrs: {
      "icon": "x",
      "variant": "danger"
    }
  }) : _vm._e()]], 2)]), _vm.submitted.add ? [!_vm.$v.forms.uk_address.postal_code.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Postal Code is required")])]) : _vm._e()] : _vm._e(), _vm.$v.forms.uk_address.postal_code.required ? [!_vm.$v.forms.uk_address.postal_code.valid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Postal Code is not valid")])]) : _vm._e()] : _vm._e(), !_vm.isEmpty(_vm.errors.addresses) ? [_vm.errors.addresses.message ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* " + _vm._s(_vm.errors.addresses.message))])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Address")]), _c('div', [_c('b-select', {
    attrs: {
      "id": "auto-select-address-select",
      "disabled": _vm.api_sent.add
    },
    model: {
      value: _vm.forms.uk_address.address,
      callback: function ($$v) {
        _vm.$set(_vm.forms.uk_address, "address", $$v);
      },
      expression: "forms.uk_address.address"
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select Address")]), _vm._l(_vm.response.address.addresses, function (address) {
    return _c('option', {
      key: address,
      domProps: {
        "value": address
      }
    }, [_vm._v(_vm._s(address.replace(/,/g, '').replace(/\s+/g, ' ').trim()))]);
  })], 2)], 1), _vm.submitted.add ? [!_vm.$v.forms.uk_address.address.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Address is required")])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("House Number")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.uk_address.house_name,
      expression: "forms.uk_address.house_name"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "auto-house-number-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.uk_address.house_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.uk_address, "house_name", $event.target.value);
      }
    }
  })]), _vm.submitted.add ? [!_vm.$v.forms.uk_address.house_name.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* House Number is required")])]) : _vm._e()] : _vm._e()], 2)])])], _c('button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.add
    }
  }, [_vm.api_sent.add ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 2)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }