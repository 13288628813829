var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 addUserModalV2",
    attrs: {
      "height": "auto",
      "name": _vm.modalName,
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-user"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 pb-0 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v(" Invite user "), _c('vb-icon', {
    staticClass: "ml-2 cursor_pointer checkSlotsModal-icon",
    attrs: {
      "icon": "checkSlotsModal-icon",
      "height": "22px",
      "width": "22px",
      "disabled": _vm.api.add_user.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CheckedServiceAvailableModal', {
          service: 'user',
          onProceed: _vm.adduser
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Please provide the new user's details below and configure your account preferences. ")])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.adduser();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.add_user.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_user.error_message))]), _c('div', {
    staticClass: "d-flex md-mar-top"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error pr-2 w-50"
  }, [_c('label', [_vm._v("First name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_user.firstname,
      expression: "forms.add_user.firstname"
    }],
    staticClass: "bg-white w-100",
    attrs: {
      "disabled": _vm.api.add_user.send,
      "maxlength": _vm.$v.forms.add_user.firstname.$params.maxLength.max,
      "onkeypress": "return /[A-Za-z]/.test(event.key)",
      "placeholder": "Enter first name",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.add_user.firstname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_user, "firstname", $event.target.value);
      }
    }
  }), _vm.forms.add_user.submitted && _vm.$v.forms.add_user.firstname.$invalid || _vm.api.add_user.validation_errors.firstname ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_user.firstname.required ? _c('span', [_vm._v("* First name is required")]) : _vm._e(), !_vm.$v.forms.add_user.firstname.alpha ? _c('span', [_vm._v("* First name should be only alphabet character only")]) : !_vm.$v.forms.add_user.firstname.maxLength ? _c('span', [_vm._v("* First name can be maximum " + _vm._s(_vm.$v.forms.add_user.firstname.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.add_user.validation_errors.firstname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error pl-2 w-50"
  }, [_c('label', [_vm._v("Last name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_user.lastname,
      expression: "forms.add_user.lastname"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "disabled": _vm.api.add_user.send,
      "maxlength": _vm.$v.forms.add_user.lastname.$params.maxLength.max,
      "onkeypress": "return /[A-Za-z]/.test(event.key)",
      "placeholder": "Enter last name",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.add_user.lastname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_user, "lastname", $event.target.value);
      }
    }
  }), _vm.forms.add_user.submitted && _vm.$v.forms.add_user.lastname.$invalid || _vm.api.add_user.validation_errors.lastname ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_user.lastname.alpha ? _c('span', [_vm._v("* Last name should be only alphabet character only")]) : !_vm.$v.forms.add_user.lastname.maxLength ? _c('span', [_vm._v("* Last name can be maximum " + _vm._s(_vm.$v.forms.add_user.lastname.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.add_user.validation_errors.lastname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-input--error md-mar-top"
  }, [_c('label', [_vm._v("Email *")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_user.email,
      expression: "forms.add_user.email"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "disabled": _vm.api.add_user.send,
      "placeholder": "Enter email",
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.add_user.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_user, "email", $event.target.value);
      }
    }
  }), _vm.forms.add_user.submitted && _vm.$v.forms.add_user.email.$invalid || _vm.api.add_user.validation_errors.username || _vm.api.add_user.validation_errors.email ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_user.email.required ? _c('span', [_vm._v("* Email is required")]) : !_vm.$v.forms.add_user.email.email ? _c('span', [_vm._v("* Email is not valid")]) : !_vm.$v.forms.add_user.email.minLength ? _c('span', [_vm._v("* Email should be minimum " + _vm._s(_vm.$v.forms.add_user.lastname.$params.minLength.min) + " character")]) : _vm._e(), _vm._l(_vm.api.add_user.validation_errors.username, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  }), _vm._l(_vm.api.add_user.validation_errors.email, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "dialer-box-header md-mar-vertical"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-3 setAsAdmin-text",
    staticStyle: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "#676767"
    }
  }, [_vm._v("Set as admin")]), _c('div', {
    staticClass: "dialer-box p-0 bg-transparent mb-0"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch textAtStart p-0",
    attrs: {
      "switch": "",
      "name": "check-button"
    },
    model: {
      value: _vm.forms.add_user.role,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_user, "role", $$v);
      },
      expression: "forms.add_user.role"
    }
  })], 1)]), _vm.forms.add_user.submitted && _vm.$v.forms.add_user.role.$invalid || _vm.api.add_user.validation_errors.role ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [_vm.$v.forms.add_user.role.required ? _c('span', [_vm._v("* Role is required")]) : _vm._e(), _vm._l(_vm.api.add_user.validation_errors.role, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), !_vm.isFreePlan ? _c('div', {
    staticClass: "dialer-box-header md-mar-bottom"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "mr-3 setAsAdmin-text",
    staticStyle: {
      "font-size": "16px",
      "font-weight": "400",
      "color": "#676767"
    }
  }, [_vm._v("Skip number")]), _c('div', {
    staticClass: "dialer-box p-0 bg-transparent mb-0"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch p-0",
    attrs: {
      "disabled": _vm.api.add_user.send,
      "name": "check-button"
    },
    model: {
      value: _vm.forms.add_user.skip_number,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_user, "skip_number", $$v);
      },
      expression: "forms.add_user.skip_number"
    }
  })], 1)]), _vm.api.add_user.validation_errors.skip_number ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.add_user.validation_errors.skip_number, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()]) : _vm._e(), _vm.isNumberSelect ? [_c('div', {
    class: `d-flex align-items-center justify-content-between mb-2 lg-mar-top ${_vm.forms.add_user.number.number ? 'numberSelected' : ''}`
  }, [[_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.forms.add_user.number.number)))])], _c('button', {
    staticClass: "dialer-button dialer-button-primary w-100",
    attrs: {
      "type": "button",
      "disabled": _vm.api.add_user.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.modalName}-AddNumbersModal`, {
          add_user: true
        });
      }
    }
  }, [[_vm._v(_vm._s(!_vm.forms.add_user.number.number ? 'Select number' : 'Change number'))]], 2)], 2), _vm.forms.add_user.submitted && _vm.$v.forms.add_user.number.$invalid ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_user.number.required ? _c('span', [_vm._v("* Number is required")]) : _vm._e()]) : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end mb-2 lg-mar-top"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary w-100",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.add_user.send
    }
  }, [_vm.api.add_user.send ? _c('vb-spinner') : [_vm._v("Invite user")]], 2)])], 2)])])])])]), _c('AddNumbersModal', {
    attrs: {
      "modalName": `${_vm.modalName}-AddNumbersModal`
    },
    on: {
      "select-number": function ($event) {
        _vm.forms.add_user.number = $event;
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }