<template>
	<div class="right-calender">
		<div class="top-main-calender scrollow1">
			<section class="sec-common">
				<div class="d-flex justify-content-between align-items-center">
					<div>
						<h5 class=" emp-profile-heading">Onboarding</h5>
					</div>
					<div class="d-flex">
						<button class="btn btn-primarymike  mr-3">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.551" height="16.551" viewBox="0 0 16.551 16.551" style="margin-right:10px;">
                <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z" transform="translate(-8.465 -8.465)" fill="#fff" stroke="#fff" stroke-width="1"/>
              </svg>
              Add workflow
						</button>
						<button class="btn-activity " @click="$modal.show('HRMAddNewTaskOnboardModal')">
							<svg xmlns="http://www.w3.org/2000/svg" width="16.551" height="16.551" viewBox="0 0 16.551 16.551" class="grm-svg">
								<path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z" transform="translate(-8.465 -8.465)" fill="#5576d1" stroke="#5576d1" stroke-width="1"/>
							</svg>
              Add Task
						</button>
					</div>
				</div>
				<p class="notes-top-text space4text-when-scroll-activate">
					Manage onboarding tasks for Ahsen van der
				</p>
				<div class="mt-5">
					<button :class="`tabs-hrm mr-3 ${selectedTabForTable == 1 ? 'active' : ''}`" @click="selectedTabForTable = 1">Outstanding</button>
					<button :class="`tabs-hrm ${selectedTabForTable == 2 ? 'active' : ''}`" @click="selectedTabForTable = 2">Completed</button>
				</div>
				<div class="mar-top-hrm60" v-if="selectedTabForTable == 1">
					<div class="tb-task-upper-part">
						<h5 class="task-th ob-emp-firsttd col-left ">TITLE</h5>
						<h5 class="task-th ob-emp-secondtd">Assigned to</h5>
						<h5 class="task-th ob-emp-thirdtd">Deadline</h5>
						<h5 class="task-th ob-emp-fourtd col-right">Action</h5>
					</div>
					<div class="tab-task-bg-border">
            <table class="task-table-hrm">
              <thead>
              </thead>
              <tbody>
                <tr>
                  <td class="ob-emp-firsttd">
                    Design Model
                  </td>
                  <td class="ob-emp-secondtd">
                    Jackson
                  </td>
                  
                  <td class="ob-emp-thirdtd  ">
                    Due in 7 days
                  </td>
                  <td class="ob-emp-fourtd">
                    <div class="d-flex justify-content-end commonTableMain">
                      <b-dropdown no-caret class="common-table-dd">
                        <template #button-content>
                          <b-icon icon="three-dots-vertical"></b-icon>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </td>
                </tr>
                <tr>
                  <td class="ob-emp-firsttd">
                    Design Model
                  </td>
                  <td class="ob-emp-secondtd">
                    Jackson
                  </td>
                  
                  <td class="ob-emp-thirdtd  ">
                    Due in 7 days
                  </td>
                  <td class="ob-emp-fourtd">
                    <div class="d-flex justify-content-end">
                      <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                        <template #button-content>
                          <b-icon icon="three-dots-vertical"></b-icon>
                        </template>
                        <b-dropdown-item href="#">Action</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </td>
                </tr>
              </tbody>
            </table>
					</div>
				</div>
				<div class="mar-top-hrm60 " v-if="selectedTabForTable == 2">
				</div>
        <vb-table :tableDesign="1" class="onboard-table-width" v-if="selectedTabForTable == 1">
          <template slot="header">
            <h5 class="commonth ob-emp-firsttd col-left ">TITLE</h5>
            <h5 class="commonth ob-emp-secondtd">Assigned to</h5>
            <h5 class="commonth ob-emp-thirdtd">Deadline</h5>
            <h5 class="commonth ob-emp-fourtd col-right">Action</h5>
          </template>
          <template slot="body">
            <tr>
              <td class="ob-emp-firsttd">
                Design Model
              </td>
              <td class="ob-emp-secondtd">
                Jackson
              </td>
              <td class="ob-emp-thirdtd ">
                Due in 7 days
              </td>
              <td class="ob-emp-fourtd">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown class="common-table-dd">
                    <template #button-content>
                     <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
          </template>
        </vb-table>
			</section>
		</div>
		<HRMAddNewTaskOnboardModal/>
	</div>
</template>

<script>
import HRMAddNewTaskOnboardModal from "../Modals/HRMAddNewTaskOnboardModal.vue"
export default {
  data() {
    return {
      selectedTabForTable:1,
    };
  }, 
  name: 'OnboardHRM',
  components:{
	HRMAddNewTaskOnboardModal,
  }
}
</script>

<style>

</style>