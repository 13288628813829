<template>
  <modal 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @opened="onOpened($event)"
    @before-close="onBeforeClose()" 
    class="dialer_animation right_side_popup m-w-500 addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue"
    width="50%" 
    height="auto" 
    :scrollable="true"
  >
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div class="dialer-edit-header">
        <div>
          <h2 class="dialer-edit-title">Assign list to</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" :disabled="api.fetch_extensions.send" @click="update()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="dialer-tabs">
        <div class="TabsDesignWithIcon-container mt-20px">
          <div 
            class="TabsDesignWithIcon-item" 
            v-for="tab in filterTabs" 
            :key="tab.value" 
            @click="selected.tab = tab.value" 
            :class="{
              'active': selected.tab == tab.value,
            }"
          >
            <vb-icon :icon="tab.icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">{{tab.text}}</span>
          </div>
        </div>
        <!-- search -->
        <div class="d-flex align-items-center justify-content-end w-100 mt-20px">
          <div class="w-50 whiteBGinputWithGreyRoundedBorder">
            <b-input v-if="selected.tab==tabs.USER.value"  type="text" v-model="filter.user.search" placeholder="Search user" />
            <b-input v-else-if="selected.tab==tabs.TEAM.value" type="text" v-model="filter.team.search" placeholder="Search team" />
            <b-input v-else-if="selected.tab==tabs.BASICIVR.value" type="text" v-model="filter.ivr.search" placeholder="Search IVR" />
            <b-input v-else-if="selected.tab==tabs.QUEUE.value" type="text" v-model="filter.call_queue.search" placeholder="Search call queue" />
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div v-if="api.fetch_extensions.send" class="whiteBoxWithBorderInsideGreyBox">
            <div v-for="n in 4" :key="n.id" class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex align-items-center">
                <div class="latestShimmerDesign mr-2" style="height:45px;width:45px"></div>
                <div class="d-flex flex-column">
                  <div class="latestShimmerDesign" style="height:21px;width:100px"></div>
                  <div class="latestShimmerDesign mt-1" style="height:21px;width:70%"></div>
                </div>
              </div>
              <div class="latestShimmerDesign mr-2" style="height:24px;width:24px"></div>
            </div>
          </div>
          <div v-else class="whiteBoxWithBorderInsideGreyBox">
            <div v-for="extension in filterExtensions" :key="extension.accountcode" class="whiteBoxWithBorderInsideGreyBox-row min-height-unset">
              <b-form-checkbox 
                :checked="!!selected.accountcodes[extension.accountcode]" 
                @change="onSelect(extension)"
                class="dialer_full_width_checkbox circle_btn_checkbox p-0 makeCheckBoxesSquare  less-padding leastPadding checkBoxOnRightSide"
              >
                <Info :id="extension.accountcode" :type="extension.account" :is_blf="false" />
              </b-form-checkbox>
            </div>
            <vb-no-record class="my-5" v-if="isEmpty(filterExtensions)" :text="api.fetch_extensions.send ? '' : 'Sorry, no data found'" :design="3" />
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from "../../utils";
import Info from '../Lists/Info.vue'
import _ from 'lodash'
const tabs = {
  USER: {
    text: "User",
    value: 'USER',
    icon: 'squared-singleUser-icon'
  },
  TEAM: {
    text: "Team",
    value: 'TEAM',
    icon: 'squared-multipleUsers-icon'
  },
  BASICIVR: {
    text: "IVR",
    value: 'BASICIVR',
    icon: 'squared-ivr-icon'
  },
  QUEUE: {
    text: "Call Queue",
    value: 'QUEUE',
    icon: 'squared-callQueue-icon'
  },
}
export default {
  name: "SelectExtensionsModal",
  components: {
    Info,
  },
  inject:[
    'isEmpty',
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'SelectExtensionsModal'
    },
    extensionTypes: {
      type: Array,
      default: ()=>[]
    },
    exceptsAccountcodes: {
      type: Array,
      default: ()=>[]
    },
    sortSelectFirst: {
      type: Boolean,
      default: false
    },
    filterCurrentUser: {
      type: Boolean,
      default: false
    },
    allowMultiSelect: {
      type: Boolean,
      default: false
    },
    allowEmptySelect: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      api: {
        fetch_extensions: this.$helperFunction.apiInstance({  }),
      },
      response: {
        extensions: {},
      },
      selected: {
        tab: tabs.USER.value,
        accountcodes: {},
      },
      filter: {
        user: {
          search: '',
        },
        team: {
          search: '',
        },
        ivr: {
          search: '',
        },
        call_queue: {
          search: '',
        },
      },
      payload: {
        conditions: null,
        data: null,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    conditions(){
      const conditions = this.payload.conditions
      const extension_types = conditions?.extension_types || this.extensionTypes
      const excepts_accountcodes = conditions?.excepts_accountcodes || this.exceptsAccountcodes
      const sort_selected_first = conditions?.sort_selected_first ?? this.sortSelectFirst
      const filter_current_user = conditions?.filter_current_user ?? this.filterCurrentUser
      const multi_select = conditions?.multi_select ?? this.allowMultiSelect
      const allow_empty_select = conditions?.allow_empty_select ?? this.allowEmptySelect
      return {
        excepts_accountcodes: _.isArray(excepts_accountcodes) ? excepts_accountcodes : [],
        extension_types: _.isArray(extension_types) ? extension_types : [],
        sort_selected_first: !!sort_selected_first,
        filter_current_user: !!filter_current_user,
        multi_select: !!multi_select,
        allow_empty_select: !!allow_empty_select,
      }
    },
    tabs(){ return tabs },
    filterTabs(){ 
      if(this.conditions.extension_types.length>0) return Object.values(tabs).filter(i=>this.conditions.extension_types.includes(i.value))
      else return Object.values(tabs)
    },
    filterExtensions(){
      const user_search = this.filter.user.search.trim().toLowerCase()
      const team_search = this.filter.team.search.trim().toLowerCase()
      const ivr_search = this.filter.ivr.search.trim().toLowerCase()
      const call_queue_search = this.filter.call_queue.search.trim().toLowerCase()
      const tab = this.selected.tab
      const selected = this.selected.extension_accountcode
      const conditions = this.conditions
      let extensions = Object.values(this.response.extensions)
      if(this.conditions.sort_selected_first) extensions = extensions.sort(item=>selected[item.accountcode]?-1:1)
      return extensions.filter(extn=>{
        let result = false
        if(tab==tabs.USER.value && tab==extn.account && `${extn.extn} ${extn.accountname}`.trim().toLowerCase().includes(user_search)) result=true
        else if(tab==tabs.TEAM.value && tab==extn.account && `${extn.extn} ${extn.accountname}`.trim().toLowerCase().includes(team_search)) result=true
        else if(tab==tabs.BASICIVR.value && tab==extn.account && `${extn.extn} ${extn.accountname}`.trim().toLowerCase().includes(ivr_search)) result=true
        else if(tab==tabs.QUEUE.value && tab==extn.account && `${extn.extn} ${extn.accountname}`.trim().toLowerCase().includes(call_queue_search)) result=true
        if(conditions.filter_current_user && extn.accountcode==this.getCurrentUser.account) result=false
        if(conditions.excepts_accountcodes.length>0 && conditions.excepts_accountcodes.includes(extn.accountcode)) result=false
        return result
      })
    },
  },
  methods: {
    onBeforeOpen(event){
      this.selected.accountcodes = _.isArray(event.params?.accountcodes) ? _.keyBy(event.params?.accountcodes) : {};
      this.payload.data = event.params?.data || null;
      this.payload.conditions = event.params?.conditions || null;
      this.fetchExtensions();
    },
    onOpened(){
      if(this.conditions.extension_types.length>0 && !this.conditions.extension_types.includes('USER')) this.selected.tabs=this.conditions.extension_types[0]
    },
    onBeforeClose(){
      this.api.fetch_extensions.reset()
      this.response.extensions={}
      this.selected.tab = tabs.USER.value;
      this.selected.accountcodes = {};
      this.filter.user.search=''
      this.filter.team.search=''
      this.filter.ivr.search=''
      this.filter.call_queue.search=''
      this.payload.data = null
      this.payload.conditions = null
    },
    async fetchExtensions() {
      if(this.api.fetch_extensions.send) return;
      try {
        this.api.fetch_extensions.send=true
        const { data: { data: extensions } } = await VOIP_API.endpoints.telephone_number.extensions()
        this.response.extensions=extensions
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_extensions.send=false
      }
    },
    onSelect(extension) {
      const accountcode = extension.accountcode
      if(this.conditions.multi_select) {
        if(this.selected.accountcodes[accountcode]) {
          this.$delete(this.selected.accountcodes,accountcode)
        } else {
          this.$set(this.selected.accountcodes,accountcode,accountcode)
        }
      } else {
        this.selected.accountcodes={
          [accountcode]: accountcode
        }
      }
    },
    update() {
      try {
        if(!this.conditions.allow_empty_select && _.isEmpty(this.selected.accountcodes)) {
          throw new Error(this.conditions.multi_select ? 'Please Select at least one extension' : 'Please Select extension')
        }
        const array = Object.values(this.response.extensions).filter(i=>!!this.selected.accountcodes[i.accountcode])
        const extensions = _.keyBy(array,'accountcode')
        this.$emit("update",{
          selected: {
            accountcodes: Object.keys(this.selected.accountcodes),
            extensions: extensions,
          },
          // added: {
          //   accountcodes: Object.keys(this.selected.accountcodes),
          //   extensions: extensions,
          // },
          // removed: {
          //   accountcodes: Object.keys(this.selected.accountcodes),
          //   extensions: extensions,
          // },
          payload: this.payload.data
        });
        this.$modal.hide(this.modalName);
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        // 
      }
    },
  },
};
</script>
