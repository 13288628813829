<template>
  <div>
    <modal 
      class="center-zoomIn-transition v2 m-w-500 AddLocationModal max-height-100vh min-width-50percent-modal" 
      width="500px" 
      height="auto" 
      :name="modalName" 
      :scrollable="true" 
      @closed="onClose" 
      @before-open="beforeOpen"
    >
      <!-- dialer_animation right_side_popup -->
      <section class="ct-user-new dialer-edit">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="addLocation">
          <div v-if="$store.getters.getIsMobile" class="dialer-edit-header flex-column">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <h2 class="dialer-edit-title">Number location</h2>
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            </div>
            <div class="dialer-edit-actions w-100 d-flex justify-content-end mt-2">
              <button class="newDoneButton" type="submit" :disabled="api_sent.add">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api_sent.add" />
                  <!-- Done -->
                  <template v-else>Save</template>
                </span>
              </button>
            </div>
          </div>
          <div v-else class="dialer-edit-header pb-0 flex-column align-items-start">
            <div class="d-flex justify-content-between w-100 align-items-center">
              <h2 class="dialer-settings-title newer mb-0">Number location</h2>
              <div class="dialer-edit-actions d-flex">
                <a class="newCloseButton mr-8px" id="edit-location-modal-close" @click="$modal.hide(modalName)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
                <!-- <button class="newDoneButton" type="submit" :disabled="api_sent.add">
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                    <vb-spinner v-if="api_sent.add" />
                    <template v-else>Save</template>
                  </span>
                </button> -->
              </div>
            </div>
            <p>
              Add or update loction for a number
            </p>
          </div>
          <div class="latestGreyBox-9-9-2023 mb-4 mt-20px">
            <div v-if="data.flag=='emergency'" class="whiteBGinputWithGreyRoundedBorder w-100 mb-0">
              <label>Name of location</label>
              <input class="w-100" type="text" v-model="forms.add_location.label" :maxlength="$v.forms.add_location.label.$params.maxLength.max" />
              <p v-if="submitted.add && $v.forms.add_location.label.$invalid" class="text text-danger animated bounceIntop mb-0">
                <span v-if="!$v.forms.add_location.label.required">* Name of location is required</span>
                <span v-else-if="!$v.forms.add_location.label.alphaNum">* Name of location can be only alphabet, number and space</span>
                <span v-else-if="!$v.forms.add_location.label.minLength">* Name of location should be minimum {{$v.forms.add_location.label.$params.minLength.min}} characters</span>
                <span v-else-if="!$v.forms.add_location.label.maxLength">* Name of location can be maximum {{$v.forms.add_location.label.$params.maxLength.max}} characters</span>
              </p>
            </div>
            <template v-if="data.flag=='purchased'">
              <div class="d-flex justify-content-between align-items-center whiteBGinputWithGreyRoundedBorder">
                <label>User home address</label>
                <b-check id="user-home-address-check" class="newerSwitch" v-model="is_user_home_address" @change="$event?(forms.add_location.callerId=''):''" :disabled="api_sent.add" switch />
              </div>
              <template v-if="is_user_home_address">
                <template v-if="add_caller_id_screen == 'number'">
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0">
                    <label>Caller ID</label>
                    <input id="caller-id-input-field" class="w-100" type="text" v-vb-input-number-formate v-model="forms.adding_caller_id.number" :disabled="api_sent.check" />
                    <template v-if="submitted.check">
                      <p v-if="!$v.forms.adding_caller_id.number.required" class="text text-danger animated bounceIntop mb-0">
                        <span>* Number is required</span>
                      </p>
                      <p v-else-if="!$v.forms.adding_caller_id.number.validNumber" class="text text-danger animated bounceIntop mb-0">
                        <span>* Number is invalid</span>
                      </p>
                    </template>
                  </div>
                  <div class="w-100">
                    <button id="check-button" class="dialer-button dialer-button-primary mt-20px" type="button" @click.stop.self="checkValidate()">
                      <vb-spinner v-if="api_sent.check" />
                      <template v-else>check</template>
                    </button>
                  </div>
                </template>
                <template v-if="add_caller_id_screen == 'pin'">
                  <b-icon id="back-icon" icon="arrow-left" class="mt-20px" @click="add_caller_id_screen = 'number'" />
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-12px w-100 mb-0">
                    <label>Enter pin code</label>
                    <div class="enterPinSection">
                      <vb-pin-code id="pin-code" :numberslength="6" :value="forms.verify_caller_id_pin.pin" @change="forms.verify_caller_id_pin.pin = $event" :disabled="api_sent.verify" />
                      <template v-if="submitted.verify">
                        <span>*</span>
                        <p v-if="!$v.forms.verify_caller_id_pin.pin.required">Pin is required</p>
                        <p v-if="!$v.forms.verify_caller_id_pin.pin.minLength">Pin should be 6 digit</p>
                      </template>
                    </div>
                  </div>
                  <div class="w-100 mt-20px">
                    <button id="verify-button" class="dialer-button dialer-button-primary" type="button" @click.stop.self="validatePin()">
                      <vb-spinner v-if="api_sent.verify" />
                      <template v-else>verify</template>
                    </button>
                  </div>
                </template>
                <template v-if="add_caller_id_screen == 'verified'">
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0">
                    <label>Caller ID</label>
                    <input id="caller-id-input-field" class="w-100" type="text" :value="forms.adding_caller_id.number" :disabled="true" />
                    <template v-if="submitted.check">
                      <p v-if="!$v.forms.adding_caller_id.number.required" class="text text-danger animated bounceIntop mb-0">
                        <span>* Number is required</span>
                      </p>
                      <p v-else-if="!$v.forms.adding_caller_id.number.validNumber" class="text text-danger animated bounceIntop mb-0">
                        <span>* Number is invalid</span>
                      </p>
                    </template>
                  </div>
                </template>
              </template>
              <template v-else>
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0">
                  <label>Select caller ID/ DID</label>
                  <vb-select id="caller-id-did" listClass="select-caller-id-options" class="w-100 custom-dd-select" :defaultSelectedText="'Select extensions'"  @change="forms.add_location.callerId=$event"  :selected="forms.add_location.callerId" :disabled="api_sent.add || api_sent.list" :options="callerIdsOptions" />
                </div>
              </template>
              <template v-if="submitted.add">
                <p v-if="!$v.forms.add_location.callerId.required" class="text text-danger animated bounceIntop mt-2">
                  <span>* Extensions is required</span>
                </p>
              </template>

            </template>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0">
              <label>Select Country</label>
              <vb-select id="select-country" listClass="select-country-options" :selected="forms.add_location.country" @change="forms.add_location.country=$event" class="w-100 custom-dd-select" :defaultSelectedText="'Select country'" :options="countriesOptions" />
              <template v-if="submitted.add">
                <p v-if="!$v.forms.add_location.country.required" class="text text-danger animated bounceIntop mb-0">
                  <span>* Country is required</span>
                </p>
              </template>
            </div>
            <div v-if="forms.add_location.country === 'GB'" class="d-flex justify-content-between align-items-center whiteBGinputWithGreyRoundedBorder mb-0 mt-20px w-100">
              <label>Manually enter the address</label>
              <b-check id="manually-enter-address-ckeck" class="newerSwitch" v-model="is_manual_address" :disabled="api_sent.add" switch />
            </div>
            <div class="d-flex w-100 justify-content-between mt-20px">
              <div class="whiteBGinputWithGreyRoundedBorder mb-0 w-50per-15px">
                <label>First name</label>
                <input id="first-name" class="w-100" type="text" v-model="forms.add_location.forename" :disabled="api_sent.add" />
                <template v-if="submitted.add">
                  <p v-if="!$v.forms.add_location.forename.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* First name is required</span>
                  </p>
                </template>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder mb-0 w-50per-15px">
                <label>Last name</label>
                <input id="last-name" class="w-100" type="text" v-model="forms.add_location.surname" :disabled="api_sent.add" />
                <template v-if="submitted.add">
                  <p v-if="!$v.forms.add_location.surname.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Last name is required</span>
                  </p>
                </template>
              </div>
            </div>
            <template v-if="is_manual_address">
              <div class="d-flex w-100 justify-content-between mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder w-30per-15px mb-0">
                  <label>House number</label>
                  <input id="manual-house-number-input-field" class="w-100" type="text" v-model="forms.manual_address.house_name" :disabled="api_sent.add" />
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* House number is required</span>
                    </p>
                  </template>
                </div>
                <div class="whiteBGinputWithGreyRoundedBorder w-70per-15px mb-0">
                  <label>Street</label>
                  <input id="manual-street-input-field" class="w-100" type="text" v-model="forms.manual_address.street" :disabled="api_sent.add" />
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.street.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Street is required</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="d-flex w-100 justify-content-between mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0">
                  <label>City</label>
                  <input id="manual-city-input-field" class="w-100" type="text" v-model="forms.manual_address.city" :disabled="api_sent.add" />
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.city.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* City is required</span>
                    </p>
                  </template>
                </div>
                <div class="whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0">
                  <label>Postal code </label>
                  <input id="manual-post-code-input-field" class="w-100" type="text" v-model="forms.manual_address.postal_code" :disabled="api_sent.add" />
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal code is required</span>
                    </p>
                  </template>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="d-flex w-100 justify-content-between mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0">
                  <label>House number</label>
                  <input id="auto-house-number-input-field" class="w-100" type="text" v-model="forms.uk_address.house_name" :disabled="api_sent.add" />
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.uk_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* House number is required</span>
                    </p>
                  </template>
                </div>
                <div class="whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0">
                  <label>Postal code</label>
                  <input id="auto-postal-code-input-field" class="w-100" type="text" v-model="forms.uk_address.postal_code" :disabled="api_sent.add" />
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.uk_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal code is required</span>
                    </p>
                  </template>
                  <template v-if="$v.forms.uk_address.postal_code.required">
                    <p v-if="!$v.forms.uk_address.postal_code.valid" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal code is not valid</span>
                    </p>
                  </template>
                  <vb-spinner v-if="api_sent.addresses" variant="primary" />
                  <template v-else>
                    <b-icon v-if="api_status.addresses == 'success'" icon="check2" variant="success" />
                    <b-icon v-if="api_status.addresses == 'fail'" icon="x" variant="danger" />
                  </template>
                  <template v-if="!isEmpty(errors.addresses)">
                    <p v-if="errors.addresses.message" class="text text-danger animated bounceIntop mb-0">
                      <span>* {{ errors.addresses.message }}</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0">
                <label>Select address</label>
                <b-select id="auto-select-address-select" v-model="forms.uk_address.address" :disabled="api_sent.add">
                  <option value="">Select address</option>
                  <option v-for="address in response.address.addresses" :key="address" :value="address">{{ address.replace(/,/g,'').replace(/\s+/g,' ').trim() }}</option>
                </b-select>
                <template v-if="submitted.add">
                  <p v-if="!$v.forms.uk_address.address.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Address is required</span>
                  </p>
                </template>
              </div>
            </template>
          </div>
          <button class="fullWidthDoneButton" type="submit" :disabled="api_sent.add">
            <vb-spinner v-if="api_sent.add" />
            <template v-else>Save</template>
          </button>



















          <!-- <div class="md-mar-vertical">
            <div class="dialer-team-name mb-4">
              
              <template v-if="data.flag=='purchased'">
                
                <div class="dialer-input-field md-mar-bottom">
                  <div class="w-100" style="display: flex;justify-content: space-between;align-items: center;">
                    <span>User Home Address</span>
                    
                    <b-check id="user-home-address-check" class="dialer-switch mr-4" v-model="is_user_home_address" @change="$event?(forms.add_location.callerId=''):''" :disabled="api_sent.add" switch />
                  </div>
                </div>

                <template v-if="is_user_home_address">

                  <template v-if="add_caller_id_screen == 'number'">
                    <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                      <div class="w-100">
                        <label>Caller ID</label>
                        <input id="caller-id-input-field" class="w-100" type="text" v-vb-input-number-formate v-model="forms.adding_caller_id.number" :disabled="api_sent.check" />
                      </div>
                      <template v-if="submitted.check">
                        <p v-if="!$v.forms.adding_caller_id.number.required" class="text text-danger animated bounceIntop mb-0">
                          <span>* Number is required</span>
                        </p>
                        <p v-else-if="!$v.forms.adding_caller_id.number.validNumber" class="text text-danger animated bounceIntop mb-0">
                          <span>* Number is invalid</span>
                        </p>
                      </template>
                    </div>
                    <button id="check-button" class="dialer-button dialer-button-primary" type="button" @click.stop.self="checkValidate()">
                      <vb-spinner v-if="api_sent.check" />
                      <template v-else>check</template>
                    </button>
                  </template>

                  <template v-if="add_caller_id_screen == 'pin'">
                    <div>
                      <b-icon id="back-icon" icon="arrow-left" @click="add_caller_id_screen = 'number'" />
                    </div>
                    <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                      <label class="dialer-input-label">Enter Pin Code</label>
                      <div class="enterPinSection">
                        <vb-pin-code id="pin-code" :numberslength="6" :value="forms.verify_caller_id_pin.pin" @change="forms.verify_caller_id_pin.pin = $event" :disabled="api_sent.verify" />
                        <template v-if="submitted.verify">
                          <span>*</span>
                          <p v-if="!$v.forms.verify_caller_id_pin.pin.required">Pin is Required</p>
                          <p v-if="!$v.forms.verify_caller_id_pin.pin.minLength">Pin should be 6 digit</p>
                        </template>
                      </div>
                    </div>
                    <button id="verify-button" class="dialer-button dialer-button-primary" type="button" @click.stop.self="validatePin()">
                      <vb-spinner v-if="api_sent.verify" />
                      <template v-else>verify</template>
                    </button>
                  </template>

                  <template v-if="add_caller_id_screen == 'verified'">
                    <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                      <div class="w-100">
                        <label>Caller ID</label>
                        <input id="caller-id-input-field" class="w-100" type="text" :value="forms.adding_caller_id.number" :disabled="true" />
                      </div>
                      <template v-if="submitted.check">
                        <p v-if="!$v.forms.adding_caller_id.number.required" class="text text-danger animated bounceIntop mb-0">
                          <span>* Number is required</span>
                        </p>
                        <p v-else-if="!$v.forms.adding_caller_id.number.validNumber" class="text text-danger animated bounceIntop mb-0">
                          <span>* Number is invalid</span>
                        </p>
                      </template>
                    </div>
                  </template>

                </template>

                <template v-else>
                  <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                    <div class="w-100">
                      <label>Select Caller ID/ DID</label>
                      <vb-select id="caller-id-did" listClass="select-caller-id-options" class="w-100 custom-dd-select" :defaultSelectedText="'Select Extensions'"  @change="forms.add_location.callerId=$event"  :selected="forms.add_location.callerId" :disabled="api_sent.add || api_sent.list" :options="callerIdsOptions" />
                    </div>
                  </div>
                </template>

                <template v-if="submitted.add">
                  <p v-if="!$v.forms.add_location.callerId.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Extensions is required</span>
                  </p>
                </template>

              </template>

              <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom md-mar-top">
                <div class="w-100">
                  <label>Select Country</label>
                  <vb-select id="select-country" listClass="select-country-options" :selected="forms.add_location.country" @change="forms.add_location.country=$event" class="w-100 custom-dd-select" :defaultSelectedText="'Select Country'" :options="countriesOptions" />
                </div>
                <template v-if="submitted.add">
                  <p v-if="!$v.forms.add_location.country.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Country is required</span>
                  </p>
                </template>
              </div>

              <div v-if="forms.add_location.country === 'GB'" class="dialer-input-field md-mar-bottom md-mar-top">
                <div class="w-100" style="display: flex;justify-content: space-between;align-items: center;">
                  <span>Manually Enter the Address</span>
                  <b-check id="manually-enter-address-ckeck" class="dialer-switch mr-4" v-model="is_manual_address" :disabled="api_sent.add" switch />
                </div>
              </div>

              <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                <div class="w-100">
                  <label>First Name</label>
                  <input id="first-name" class="w-100" type="text" v-model="forms.add_location.forename" :disabled="api_sent.add" />
                </div>
                <template v-if="submitted.add">
                  <p v-if="!$v.forms.add_location.forename.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* First Name is required</span>
                  </p>
                </template>
              </div>

              <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                <div class="w-100">
                  <label>Last Name</label>
                  <input id="last-name" class="w-100" type="text" v-model="forms.add_location.surname" :disabled="api_sent.add" />
                </div>
                <template v-if="submitted.add">
                  <p v-if="!$v.forms.add_location.surname.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Last Name is required</span>
                  </p>
                </template>
              </div>

              <template v-if="is_manual_address">
                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>Postal Code</label>
                    <input id="manual-post-code-input-field" class="w-100" type="text" v-model="forms.manual_address.postal_code" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal Code is required</span>
                    </p>
                  </template>
                </div>

                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>Street</label>
                    <input id="manual-street-input-field" class="w-100" type="text" v-model="forms.manual_address.street" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.street.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Street is required</span>
                    </p>
                  </template>
                </div>

                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>City</label>
                    <input id="manual-city-input-field" class="w-100" type="text" v-model="forms.manual_address.city" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.city.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* City is required</span>
                    </p>
                  </template>
                </div>

                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>House Number</label>
                    <input id="manual-house-number-input-field" class="w-100" type="text" v-model="forms.manual_address.house_name" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* House Name is required</span>
                    </p>
                  </template>
                </div>
              </template>

              <template v-else>
                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>Postal Code</label>
                    <input id="auto-postal-code-input-field" class="w-100" type="text" v-model="forms.uk_address.postal_code" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.uk_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal Code is required</span>
                    </p>
                  </template>
                  <template v-if="$v.forms.uk_address.postal_code.required">
                    <p v-if="!$v.forms.uk_address.postal_code.valid" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal Code is not valid</span>
                    </p>
                  </template>
                  <vb-spinner v-if="api_sent.addresses" variant="primary" />
                  <template v-else>
                    <b-icon v-if="api_status.addresses == 'success'" icon="check2" variant="success" />
                    <b-icon v-if="api_status.addresses == 'fail'" icon="x" variant="danger" />
                  </template>
                  <template v-if="!isEmpty(errors.addresses)">
                    <p v-if="errors.addresses.message" class="text text-danger animated bounceIntop mb-0">
                      <span>* {{ errors.addresses.message }}</span>
                    </p>
                  </template>
                </div>

                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>Select Address</label>
                    <b-select id="auto-select-address-select" v-model="forms.uk_address.address" :disabled="api_sent.add">
                      <option value="">Select Address</option>
                      <option v-for="address in response.address.addresses" :key="address" :value="address">{{ address.replace(/,/g,'').replace(/\s+/g,' ').trim() }}</option>
                    </b-select>
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.uk_address.address.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Address is required</span>
                    </p>
                  </template>
                </div>

                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>House Number</label>
                    <input id="auto-house-number-input-field" class="w-100" type="text" v-model="forms.uk_address.house_name" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.uk_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* House Number is required</span>
                    </p>
                  </template>
                </div>
              </template>
            </div>
          </div> -->
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { number_formater } from '@/filter';
import { required, minLength, helpers, } from "vuelidate/lib/validators";
import { $fn, LOGGER, VOIP_API } from "../../utils";
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import maxLength from 'vuelidate/lib/validators/maxLength';
export default {
  name: "AddLocationModal",
  props: {
    modalName: {
      type: String,
      default: 'AddLocationModal'
    }
  },
  data() {
    return {
      forms: {
        add_location: {
          callerId: "",
          country: "",
          surname: "",
          forename: "",
          label: '',
          reset() {
            this.callerId = "";
            this.country = "";
            this.surname = "";
            this.forename = "";
            this.label = '';
          },
        },
        manual_address: {
          postal_code: "",
          city: "",
          street: "",
          house_name: "",
          reset() {
            this.postal_code = "";
            this.city = "";
            this.street = "";
            this.house_name = "";
          },
        },
        uk_address: {
          postal_code: "",
          house_name: "",
          address: "",
          reset() {
            this.postal_code = "";
            this.house_name = "";
            this.address = "";
          },
        },
        adding_caller_id: {
          number: "",
          reset() {
            this.number = "";
          },
        },
        verify_caller_id_pin: {
          pin: "",
          reset() {
            this.pin = "";
          },
        },
      },
      selectedCallerid: '',
      selectedCallerCountry: '',
      selectedCallerCountry2: '',
      showCallerIDList : false,
      is_manual_address: true,
      is_user_home_address: false,
      add_caller_id_screen: "number",
      response: {
        list: {
          callerid: [],
          countries: [],
          extensionCount: 0,
          extensions: {},
        },
        address: {
          addresses: [],
          latitude: 0,
          longitude: 0,
        },
      },
      submitted: {
        add: false,
        check: false,
        verify: false,
        reset(){
          this.add = false
          this.check = false
          this.verify = false
        },
      },
      api_sent: {
        add: false,
        list: false,
        addresses: false,
        check: false,
        verify: false,
      },
      api_status: {
        addresses: "",
        reset(){
          this.addresses = ""
        },
      },
      errors: {
        addresses: {},
      },
      data: {
        flag: '',
        reset(){
          this.flag=''
        },
      },
    };
  },
  inject:['isEmpty','appNotify'],
  computed: {
    addLocationForm() {
      const address = this.forms?.uk_address?.address?.split?.(",") ?? [];
      return {
        street_address: this.is_manual_address ? this.forms.manual_address.street : address?.[0]?.trim?.() ?? '',
        locality: this.is_manual_address ? "" : "",
        county: this.is_manual_address ? "" : address?.[6]?.trim?.() ?? '',
        is_manual: this.is_manual_address,
        town: this.is_manual_address ? this.forms.manual_address.city : address?.[5]?.trim?.() ?? '',
        city: this.is_manual_address ? this.forms.manual_address.city : address?.[5]?.trim?.() ?? '',
        postcode: this.is_manual_address ? this.forms.manual_address.postal_code : this.forms.uk_address.postal_code,
        country: this.forms.add_location.country,
        house: this.is_manual_address ? this.forms.manual_address.house_name : this.forms.uk_address.house_name,
        caller: this.forms.add_location.callerId,
        forename: this.forms.add_location.forename,
        surname: this.forms.add_location.surname,
        flag: this.data.flag || "purchased",
      };
    },
    callerIdsOptions(){ 
      return this.response.list.callerid.map((callerid) => {
        return {
          text: ` ${number_formater(callerid)} `,
          value: callerid,
        }
      }) 
    },
    countriesOptions(){
      return this.response.list.countries.map((country) => {
        return {
          text: ` ${country.Name} `,
          value: country.Code2,
        }
      })
    },
  },
  validations: {
    forms: {
      add_location: {
        label: {
          required: requiredIf(function(){
            return this.data.flag=='emergency'
          }),
          alphaNum: helpers.regex('alphaNum',/^[a-zA-Z0-9 ]*$/i),
          maxLength: maxLength(15),
          minLength: minLength(3),
        },
        callerId: {
          required,
        },
        country: {
          required,
        },
        surname: {
          required,
        },
        forename: {
          required,
        },
      },
      manual_address: {
        postal_code: {
          required,
        },
        city: {
          required,
        },
        street: {
          required,
        },
        house_name: {
          required,
        },
      },
      uk_address: {
        postal_code: {
          required,
          valid: function(...args){ return $fn.isValidUKPostcode(...args) },
        },
        house_name: {
          required,
        },
        address: {
          required,
        },
      },
      adding_caller_id: {
        number: {
          required,
          validNumber(value){ return $fn.validPhoneNumber(value) },
        },
      },
      verify_caller_id_pin: {
        pin: {
          required,
          minLength: minLength(6),
        },
      },
    },
  },
  watch: {
    "forms.add_location.country"(country) {
      this.is_manual_address = country !== "GB";
    },
    "forms.uk_address.postal_code"(postal_code) {
      this.resetresponse("address");
      this.forms.uk_address.address=''
      if(!this.$v.forms.uk_address.postal_code.valid) return;
      this.getAddress(postal_code);
    },
  },
  methods: {
    getAddress(value) {
      let vm = this;
      this.api_sent.addresses = true;
      VOIP_API.endpoints.location.getAddress(value).then(({ data }) => {
        vm.response.address = data;
      })
      .then(() => {
        this.api_status.addresses = "success";
        this.errors.addresses = {};
      })
      .catch(() => {
        this.errors.addresses = {
          message: "Invalid postcode",
        };
        this.api_status.addresses = "fail";
      })
      .finally(() => {
        this.api_sent.addresses = false;
      });
    },
    resetresponse(type) {
      if (type === "address") {
        this.response.address.addresses = [];
        this.response.address.latitude = 0;
        this.response.address.longitude = 0;
      }
    },
    beforeOpen(event) {
      this.data.flag=event.params?.flag || 'purchased'
      this.forms.add_location.callerId=event.params?.callerId || ''
      this.load();
    },
    load() {
      if (this.api_sent.list) return;
      this.api_sent.list = true;
      VOIP_API.endpoints.location.listcountriesandextensions()
      .then(({ data }) => {
        this.response.list = data;
      })
      .catch((error) => {
        LOGGER.danger(error);
      })
      .finally(() => {
        this.api_sent.list = false;
      });
    },
    async addLocation() {
      this.submitted.add = true;
      this.$v.$touch();
      if (this.$v.forms.add_location.$invalid || this.api_sent.add || (this.is_manual_address ? this.$v.forms.manual_address.$invalid : this.$v.forms.uk_address.$invalid)) return;
      try {
        let locations;
        this.api_sent.add = true;
        if(this.data.flag=='emergency'){
          await VOIP_API.endpoints.location.addEmergencyAddress({
            number: this.addLocationForm.caller,
            postcode: this.addLocationForm.postcode,
            surname: this.addLocationForm.surname,
            forename: this.addLocationForm.forename,
            house: this.addLocationForm.house,
            street: this.addLocationForm.street_address,
            town: this.addLocationForm.town,
            accountcode: this.$store.getters.getCurrentUser?.account,
            uid: this.$store.getters.getCurrentUser?.uid,
            title: '',
            label: this.forms.add_location.label,
          })
        } else {
          const { data: { data: list } } = await VOIP_API.endpoints.location.add({
            ...this.addLocationForm,
            accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
            uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
          })
          locations=list
        }
        this.$modal.hide(this.modalName);
        this.appNotify({
          message: "Successfully Added",
          type: "success",
        })
        this.forms.add_location.reset();
        this.forms.manual_address.reset();
        this.forms.uk_address.reset();
        this.$emit("latest-list", {
          list: locations,
        });
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.submitted.add = false;
        this.api_sent.add = false;
      }
    },
    checkValidate() {
      let vm = this;
      vm.submitted.check=true
      if (this.$v.forms.adding_caller_id.$invalid || this.api_sent.check) return;
      this.api_sent.check = true;
      VOIP_API.endpoints.callerid.addCallerId({
        accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
        number: $fn.getNumberFormated(this.forms.adding_caller_id.number),
      })
      .then((res) => {
        LOGGER.log("run", res);
        this.add_caller_id_screen = "pin";
      })
      .catch((ex) => {
        LOGGER.danger({ ex });
        // if(ex.response_error?.status_code==409){
        //   vm.add_caller_id_screen = "pin";
        // }
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api_sent.check = false;
        vm.submitted.check = false;
      });
    },
    validatePin() {
      let vm = this;
      vm.submitted.verify=true
      if (this.$v.forms.verify_caller_id_pin.$invalid || this.$v.forms.adding_caller_id.$invalid || this.api_sent.verify) return;
      this.api_sent.verify = true;
      VOIP_API.endpoints.callerid.verifypin({
        accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
        pincode: this.forms.verify_caller_id_pin.pin,
        number: $fn.getNumberFormated(this.forms.adding_caller_id.number),
      })
      .then((res) => {
        LOGGER.log("run", res);
        vm.add_caller_id_screen = "verified";
        vm.forms.add_location.callerId=$fn.getNumberFormated(vm.forms.adding_caller_id.number)
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api_sent.verify = false;
        vm.submitted.verify = false;
      });
    },
    onClose() {
      this.$emit("close");
      this.forms.add_location.reset();
      this.forms.manual_address.reset();
      this.forms.uk_address.reset();
      this.forms.adding_caller_id.reset();
      this.forms.verify_caller_id_pin.reset();
      this.api_status.reset();
      this.is_manual_address = true;
      this.is_user_home_address = false;
      this.add_caller_id_screen = "number";
      this.submitted.reset();
    },
  },
};
</script>
