var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.account_detail.send || _vm.api.invoice_detail.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Invoice")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_vm.api.account_detail.send || _vm.api.invoice_detail.send ? _c('vb-loading') : [_c('div', {
    staticClass: "d-flex justify-content-end pb-4"
  }), _c('div', {
    staticClass: "allow_scroll_2",
    staticStyle: {
      "height": "calc(100vh - 141px)"
    }
  }, [_c('div', {
    staticClass: "px-4 py-4"
  }, [_c('div', {
    staticStyle: {
      "padding": "0mm !important",
      "margin": "0mm !important",
      "box-shadow": "0 2px 10px -2px rgb(27 33 58 / 40%)"
    },
    attrs: {
      "id": `invoice-download`
    }
  }, [_c('div', {
    staticClass: "pdf-page-container"
  }, [_c('div', {
    staticClass: "pdf-page-header"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "invoice-title"
  }, [_c('div', {
    staticClass: "invoice-title-icon"
  }, [_c('b-icon', {
    attrs: {
      "icon": "cpu"
    }
  })], 1), _c('h4', {
    staticClass: "invoice-title-heading"
  }, [_vm._v("Invoice")])]), _c('div', {
    staticClass: "our-company-detail-invoice"
  }, [_c('h4', {
    staticClass: "company-name"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.account_detail, 'companyname')))]), _c('span', {
    staticClass: "header-small-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.account_detail, 'address')))]), _c('span', {
    staticClass: "header-small-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.account_detail, 'city')))]), _c('span', {
    staticClass: "header-small-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.account_detail, 'countryname')))]), _c('span', {
    staticClass: "header-small-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.account_detail, 'postcode')))])])])]), _c('div', {
    staticClass: "pdf-page-body"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "bill-to-container"
  }, [_c('span', {
    staticClass: "bill-to-heading"
  }, [_vm._v("Bill To:")]), _c('span', {
    staticClass: "bill-to-company"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.account_detail, 'companyname')))]), _c('span', {
    staticClass: "bill-to-text mt-2"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.account_detail, 'address')))]), _c('span', {
    staticClass: "bill-to-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.account_detail, 'city')))]), _c('span', {
    staticClass: "bill-to-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.account_detail, 'countryname')))]), _c('span', {
    staticClass: "bill-to-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.account_detail, 'postcode')))])]), _c('div', {
    staticClass: "bill-to-container align-items-end"
  }, [_c('span', {
    staticClass: "bill-to-heading"
  }, [_vm._v("Invoice #")]), _c('span', {
    staticClass: "bill-to-text"
  }, [_vm._v(_vm._s(_vm._f("invoicename")(_vm._f("get_property")(_vm.response.invoice, 'invoiceid'))))]), _c('span', {
    staticClass: "bill-to-heading mt-1"
  }, [_vm._v("Date")]), _c('span', {
    staticClass: "bill-to-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.invoice, 'date')))]), _c('span', {
    staticClass: "bill-to-heading mt-1"
  }, [_vm._v("Invoice Due Date")]), _c('span', {
    staticClass: "bill-to-text"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.invoice, 'duedate')))])])]), _c('div', {
    staticClass: "dialer-invoice-preview mt-4"
  }, [_c('table', {
    staticClass: "dialer-invoice-table"
  }, [_c('tbody', _vm._l(_vm.items, function (item) {
    return _c('tr', {
      key: item.id,
      staticClass: "dialer-line-item"
    }, [_c('td', {
      staticClass: "bold"
    }, [_vm._v(_vm._s(item.description))]), _c('td', {
      staticClass: "price"
    }, [_vm._v("£" + _vm._s(item.amount))])]);
  }), 0)])]), _c('div', {
    staticClass: "d-flex mt-4 justify-content-end"
  }, [_c('span', {
    staticClass: "mr-5 color-black font-weight-700 font-18"
  }, [_vm._v("Subtotal : ")]), _c('span', {
    staticClass: "color-black font-weight-700 font-18"
  }, [_vm._v("£" + _vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.invoice, 'subtotal', 0))))])])]), _c('div', {
    staticClass: "pdf-page-footer d-flex"
  }, [_c('div', {
    staticClass: "d-flex flex-column pdf-page-footer-left"
  }, [_c('span', {
    staticClass: "mr-5 color-black font-weight-600 font-16"
  }, [_vm._v("Notice :- ")]), _c('span', {
    staticClass: "color-black font-weight-600 font-14 opacity-0-8"
  }, [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.response.invoice, 'notes')) + " ")])]), _c('div', {
    staticClass: "d-flex flex-column align-items-end pdf-page-footer-right"
  }, [_c('span', {
    staticClass: "header-small-text text-uppercase"
  }, [_vm._v("Total")]), _c('span', {
    staticClass: "color-white font-weight-600 font-28"
  }, [_vm._v("£" + _vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.invoice, 'total', 0))))])])])])])])])]], 2)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }