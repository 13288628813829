<template>
    <modal class="dialer_animation right_side_popup global-modal-hrm Scrollable-hrm" name="ExportTimeoffApproverModal"  :clickToClose="true"  @before-open="beforeOpen" :scrollable="true">
      <form  @submit.prevent="''">
        <div class="dialer-hrm-modal-header mar-top-hrm20">
          <h2 class="dialer-modal-hrm-title ">Time off approvers</h2>
          <div>
            <button class="dialer-hrm-button-modal" @click="$modal.hide('ExportTimeoffApproverModal')">Close</button>
          </div>
        </div>
        <div class="mar-top-hrm30 sp-gm-if">
          <p class="common-modal-text">Each time off approver will receive an email of each request and the 
            ability to accept and decline requests.
          </p>
          <p class="common-modal-text">Team Managers & Administrators, even if they are not approvers, 
              will still be able to edit requests.
          </p>
        </div>
        <div class="sp-gm-if">
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Administrators</span>
          </b-form-checkbox>
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Direct Manager</span>
          </b-form-checkbox>
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Team manager</span>
          </b-form-checkbox>
        </div>
        <div class="sp-gm-if">
          <label class="gm-hrm-label">Indivisual approvers</label>
          <div class="dd-gm-container">
            <b-dropdown no-caret class="gm-mainBorderfordropi">
              <template #button-content>
                <div class="gm-button-content-dropi">
                  <div class="gm-dropi-contenti">Indivisual approvers</div>
                  <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                </div>
              </template>
              <b-dropdown-item class="dropi-dropdown-toggle">A</b-dropdown-item>
              <b-dropdown-item class="dropi-dropdown-toggle">B</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
         <div class="sp-gm-if">
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Automatically approve time off requests</span>
          </b-form-checkbox>
        </div>
        <button class="btn-primarynewupdated1 mt-4 mar-bottom-hrm50">Save</button>
      </form>
    </modal>
</template>
<script>
export default {
  name: 'ExportTimeoffApproverModal',
}
</script>

<style>

</style>