var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    attrs: {
      "id": "user_detail_exten"
    }
  }, [[_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.extensionlimit),
      "loading": _vm.api.fetch_extension_detail.send,
      "listLength": _vm.extensionlimit.length
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.extensionlimit, function (limit, type) {
          return _c('tr', {
            key: type
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(_vm._f("extensionText")(type)))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(limit.allowed))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(limit.used))]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right"
          }, [_vm._v(_vm._s(limit.remaining))])]);
        });
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(3, function (n) {
          return _c('tr', {
            key: n.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', {
            staticClass: "dialer-row-title extentionSecretContainer-td"
          }, [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-left"
          }, [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign w-100",
            staticStyle: {
              "height": "24px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Label")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Allowed")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Used")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }, [_c('span', [_vm._v("Remaining")])])])])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }