var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading"
  }, [_vm._v("Your HR dashboard")]), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "innerShadowInput-container wd-46"
  }, [_c('label', [_vm._v("Country ")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd bgWhite noShadow",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("United Kingdom")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" United Kingdom ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container wd-46"
  }, [_c('label', [_vm._v("Default system language ")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd bgWhite noShadow",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("English")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" English ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container wd-46"
  }, [_c('label', [_vm._v("Time zone")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd bgWhite noShadow",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("(GMT+00:00) London")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" (GMT+00:00) London ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container wd-46 mb-0"
  }, [_c('label', [_vm._v("Date format")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd bgWhite noShadow",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" 10 Aug 2022 ")])], 1)], 1)]), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Time format")]), _c('b-form-group', {
    staticClass: "HrmRadio-container wd-32 mt-32px"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" 12-hour (3:00 PM) ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" 24-hour (15:00) ")])], 1)], 1), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Number of digits shown after comma")]), _c('b-form-group', {
    staticClass: "HrmRadio-container wd-32 mt-32px"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" None ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" 1 (1.2) ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" 2 (1.23) ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" 3 (1.234) ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" 4 (1.2345) ")])], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary w-fit-content mt-32px mr-0"
  }, [_vm._v(" Save ")])], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "innerShadowInput-container whiteBG wd-46"
  }, [_c('label', [_vm._v("Your company name")]), _c('input', {
    staticClass: "noInnerBoxShadow"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "innerShadowInput-container whiteBG wd-46"
  }, [_c('label', [_vm._v("Phone number")]), _c('input', {
    staticClass: "noInnerBoxShadow"
  })]);

}]

export { render, staticRenderFns }