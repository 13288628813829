var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex flex-column dialer-parent dialer-light-mode insideAnroidIOSApp"
  }, [_c('div', {
    staticClass: "dialer-parent-inner",
    staticStyle: {
      "height": "100vh"
    },
    attrs: {
      "id": "dialer-parent-inner"
    }
  }, [_c('div', {
    staticClass: "d-flex h-100"
  }, [_c('div', {
    staticClass: "dialer-main-center-area d-flex flex-column h-100 settingsIsOpened insideAnroidIOSApp"
  }, [_c('div', {
    staticClass: "w-100 h-100 d-flex align-items-center justify-content-center font-weight-bold font-20 text-center"
  }, [_vm._v(" Authentication Failed, Please login again ")])])])])])]);

}]

export { render, staticRenderFns }