<template>
  <modal 
    class="dialer_animation right_side_popup addCardModal width-60 ViewAllInvoicesModal" 
    width="80%" 
    height="auto" 
    :scrollable="true" 
    :name="modalName" 
    :clickToClose="false" 
    @before-open="onBeforeOpen" 
    @before-close="onBeforeClose"
  >
    <div class="dialer-box  table">
      <div class="dialer-edit-header mt-3">
        <h2 class="dialer-edit-title mb-0">View all invoices</h2>
        <div class="dialer-edit-actions">
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else class="" @click="$modal.hide(modalName)">
            
            <button type="button" class="newCloseButton"> 
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </a>
        </div>
      </div>
      <div class="py-5">
        <!-- <vb-table :isListEmpty="isEmpty(filterInvoices)" :listLength="filterInvoices.length" :perPage="5" :loading="api_sent.account_invoice" class="pt-3 pb-3 plan-license-table dialer-table">
          <tr slot="header" class="w-100 border-bottom-0">
            <th class="w-accounts-33-33 w-accounts-20 border-0 pt-3"> 
              <div class=" w-auto ">
                <span class="border-bottom w-auto font-14 pb-2 pr-3">Invoice Number</span>
              </div>
            </th>
            <th class="w-accounts-33-33 w-accounts-20 border-0 pt-3">
              <div class="w-auto  ">
                <span class="border-bottom w-auto font-14 pb-2 pr-3">Outstanding Amount</span>
              </div>
            </th>
            <th class="w-accounts-33-33 w-accounts-20 border-0 pt-3">
              <div class="w-auto  ">
                <span class="border-bottom w-auto font-14 pb-2 pr-3">Status</span>
              </div>
            </th>
            <th class="w-accounts-33-33 w-accounts-20 border-0 pt-3">
              <div class="w-auto  ">
                <span class="border-bottom w-auto font-14 pb-2 pr-3">Subtotal</span>
              </div>
            </th>
            <th class="w-accounts-33-33 w-accounts-20 border-0 pt-3">
              <div class="w-auto">
                <span class="border-bottom w-auto pb-2"><br/> </span>
              </div>
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr class="border-bottom" v-for="(invoice, index) in filterInvoices" :key="invoice.id" v-show="index >= start && index <= end">
              <td class="w-accounts-33-33 w-accounts-20 border-0 py-4 font-weight-700 font-16">
                {{ `VB-${invoice.id || ''}` }}
              </td>
              <td class="w-accounts-33-33 w-accounts-20 border-0 py-4 font-weight-700 font-16">
                {{ `${invoice.currencyprefix} ${invoice.total}` }}
              </td>
              <td class="w-accounts-33-33 w-accounts-20 border-0 py-4 font-weight-700 font-16">
                {{ ` ${invoice.status}` }}
              </td>
              <td class="w-accounts-33-33 w-accounts-20 border-0 py-4 font-weight-700 font-16">
                {{ `${invoice.currencyprefix} ${invoice.subtotal}` }}
              </td>
              <td class="w-accounts-33-33 w-accounts-20 border-0 py-4">
                <div class="d-flex d-flex justify-content-end w-100">
                  <button @click="$modal.show('InvoicePDFDownloadViewAllInvoices',{id:invoice.id})" class="dialer-button dialer-button-primary">View Invoice</button>
                </div>
              </td>
            </tr>
          </template>
        </vb-table> -->

        <vb-table :isListEmpty="isEmpty(filterInvoices)" :listLength="filterInvoices.length" :perPage="5" :loading="api_sent.account_invoice" class="dialer-table p-3">
          <tr slot="header" class="">
            <th class=""> 
              <div class=" w-auto ">
                <span class="w-auto font-14 pb-2 pr-3">Invoice number</span>
              </div>
            </th>
            <th class="">
              <div class="w-auto  ">
                <span class=" font-14 pb-2 pr-3">Outstanding amount</span>
              </div>
            </th>
            <th class="">
              <div class="w-auto  ">
                <span class=" font-14 pb-2 pr-3">Status</span>
              </div>
            </th>
            <th class="">
              <div class="w-auto  ">
                <span class=" font-14 pb-2 pr-3">Subtotal</span>
              </div>
            </th>
            <th class="">
              <div class="w-auto">
                <span class=" pb-2"><br/> </span>
              </div>
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr class="" v-for="(invoice, index) in filterInvoices" :key="invoice.id" v-show="index >= start && index <= end">
              <td class=" font-weight-700 font-16">
                {{ `VB-${invoice.id || ''}` }}
              </td>
              <td class=" font-weight-700 font-16">
                {{ `${invoice.currencyprefix} ${invoice.total}` }}
              </td>
              <td class=" font-weight-700 font-16">
                {{ ` ${invoice.status}` }}
              </td>
              <td class=" font-weight-700 font-16">
                {{ `${invoice.currencyprefix} ${invoice.subtotal}` }}
              </td>
              <td class="">
                <div class="d-flex d-flex justify-content-end w-100">
                  <button @click="$modal.show('InvoicePDFDownloadViewAllInvoices',{id:invoice.id})" class="dialer-button dialer-button-primary">View invoice</button>
                </div>
              </td>
            </tr>
          </template>
        </vb-table>
      </div>
    </div>
    <InvoicePDFDownload :modalName="`InvoicePDFDownloadViewAllInvoices`" />
  </modal>
</template>

<script>
import { VOIP_API } from '../../utils';
import _ from 'lodash'
import InvoicePDFDownload from './InvoicePDFDownload.vue'
export default {
  name: 'ViewAllInvoicesModal',
  components: {
    InvoicePDFDownload,
  },
  props: {
    modalName: {
      type: String,
      default: 'ViewAllInvoicesModal',
    },
  },
  data(){
    return {
      response: {
        account_invoice: []
      },
      api_sent: {
        account_invoice: false,
      },
    }
  },
  inject:['isEmpty'],
  computed: {
    filterInvoices(){
      const invoices = _.get(this.response.account_invoice,'invoices.invoices.invoice') || []
      return _.orderBy(invoices, ['created_at'], ['desc']);
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchAccountInvoices()
    },
    onBeforeClose(){
      this.response.account_invoice=[]
    },
    fetchAccountInvoices(){
      let vm = this;
      this.api_sent.account_invoice = true;
      VOIP_API.endpoints.users.accountinvoicedetail({
        uid: vm.$store.getters.getCurrentUser?.uid,
      })
      .then(({ data }) => {
        vm.response.account_invoice = data
      })
      .finally(() => {
        vm.api_sent.account_invoice = false
      });
    },
  },
}
</script>

<style>

</style>