import("./Strophe");
let JitsiMeetJS = window.JitsiMeetJS
const initializingOptions = {
  useIPv6: true,
  disableAudioLevels: true,
  disableSimulcast: true,
  enableWindowOnErrorHandler: false,
  disableThirdPartyRequests: true,
  enableAnalyticsLogging: false,
  externalStorage: null,
  callStatsCustomScriptUrl: "https://jitsi.hiyapp.com/",
  disableRtx: false,
  disableH264: false,
  preferH264: true,
};
// console.log('JitsiMeetJS', JitsiMeetJS)
JitsiMeetJS.init(initializingOptions);

JitsiMeetJS.setLogLevel(JitsiMeetJS.logLevels.ERROR)
export default JitsiMeetJS;
// export { default as videocalls } from './calls'