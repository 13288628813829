var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "SendSmsModal",
    attrs: {
      "width": "400px",
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-user w-100"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.sendSms();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Send SMS")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('b-alert', {
    attrs: {
      "show": _vm.api.fetch_balance_inquiry.status == 1 && !_vm.canSendSMS,
      "variant": "danger"
    }
  }, [_vm._v("You don't have balance to send sms")]), _c('b-alert', {
    attrs: {
      "show": !!_vm.api.send_sms.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.send_sms.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion dialer-max-width dialer-min-height"
  }, [_c('label', [_vm._v("Select number to send sms")]), _c('div', [_c('b-form-select', {
    model: {
      value: _vm.forms.send_sms.vonage_number,
      callback: function ($$v) {
        _vm.$set(_vm.forms.send_sms, "vonage_number", $$v);
      },
      expression: "forms.send_sms.vonage_number"
    }
  }, _vm._l(_vm.response.vonage_numbers, function (num) {
    return _c('b-form-select-option', {
      key: num,
      attrs: {
        "value": num
      }
    }, [_vm._v(_vm._s(_vm._f("number_formater")(num)))]);
  }), 1), _vm.forms.send_sms.submitted && _vm.$v.forms.send_sms.vonage_number.$invalid || _vm.api.send_sms.validation_errors.vonage_number ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.send_sms.vonage_number.required ? _c('span', [_vm._v("* Number is required")]) : _vm._e(), _vm._l(_vm.api.send_sms.validation_errors.vonage_number, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(_vm.m))]);
  })], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-max-width dialer-min-height mt-20px"
  }, [_c('label', [_vm._v("Number")]), _c('div', [_c('input', {
    directives: [{
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.forms.send_sms.number,
      expression: "forms.send_sms.number"
    }],
    staticClass: "touched w-100",
    attrs: {
      "placeholder": "Enter Number",
      "type": "text",
      "disabled": _vm.api.send_sms.send
    },
    domProps: {
      "value": _vm.forms.send_sms.number
    },
    on: {
      "change": function ($event) {
        return _vm.balanceInquiryDebounce();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.send_sms, "number", $event.target.value);
      }
    }
  }), _vm.forms.send_sms.submitted && _vm.$v.forms.send_sms.number.$invalid || _vm.api.send_sms.validation_errors.number ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.send_sms.number.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.send_sms.number.validNumber ? _c('span', [_vm._v("* Number is invalid")]) : _vm._e(), _vm._l(_vm.api.send_sms.validation_errors.number, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(_vm.m))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dialer-max-width mt-20px dialer-min-height"
  }, [_c('label', [_vm._v("Message")]), _c('div', [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.send_sms.message,
      expression: "forms.send_sms.message"
    }],
    staticClass: "touched textArea-SendSmsModal w-100",
    attrs: {
      "type": "text",
      "maxlength": _vm.$v.forms.send_sms.message.$params.maxLength.max,
      "disabled": !_vm.canSendSMS || _vm.api.send_sms.send
    },
    domProps: {
      "value": _vm.forms.send_sms.message
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.send_sms, "message", $event.target.value);
      }
    }
  }), _vm._v(" "), _vm.forms.send_sms.submitted && _vm.$v.forms.send_sms.message.$invalid || _vm.api.send_sms.validation_errors.message ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.send_sms.message.required ? _c('span', [_vm._v("* Message is required")]) : !_vm.$v.forms.send_sms.message.maxLength ? _c('span', [_vm._v("* Message can be maximum " + _vm._s(_vm.$v.forms.send_sms.message.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.send_sms.validation_errors.message, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(_vm.m))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "w-100"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "disabled": !_vm.canSendSMS || _vm.api.send_sms.send
    }
  }, [_vm.api.send_sms.send ? _c('vb-spinner', {
    attrs: {
      "variant": "primary"
    }
  }) : [_vm._v(" Send ")]], 2)])], 1)])])])]), _c('AddCreditModal', {
    attrs: {
      "modalName": `${_vm._uid}-AddCreditModal`
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }