<template>
  <div class="dialer-settings-main">
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Locations</div>
      <div class="plus-IOSIcon-container">
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" @click="$modal.show('AddLocationModal')" width="20" height="19.992"/>
      </div>
    </div>
    <section class="dialer-settings-section locationSettings">
      
      <template>
        
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-input  type="text" placeholder="Search Location" v-model="filter.locations.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
      </template>
      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div class="basicWhiteIOScard-item" v-for="location in filterLocations" :key="location.real_id" @click="$modal.show('EditLocationModal', { location: location })">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Locations-location-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable">{{ location.address }}</div>
              <div class="textInsideCard primaryColor">{{ (location.caller || '-') | number_formater }}</div>
            </div>  
          </div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
        </div>
      </div>
      <InfiniteLoading ref="locations_infinity_loader" @infinite="fetchLocations()">
        <div slot="spinner">
          <vb-loading type="loader" />
        </div>
        <div slot="no-more"></div>
        <div slot="no-results">
          <vb-no-record :text="'There is no Numbers'" :design="3" />
        </div>
        <template #error="{ trigger }">
          <div>
            <span>{{ api.locations.error_message }}</span>
            <b-icon icon="arrow-repeat" class="ml-3" variant="primary" font-scale="1.5" @click="trigger()" />
          </div>
        </template>
      </InfiniteLoading>
      
      
      
    </section>

    <AddLocationModal @latest-list="fetchLocations(true);" />
    <EditLocationModal @latest-list="fetchLocations(true);" />
  </div>
</template>

<script>
import AddLocationModal from "../modals/locations/AddLocation.vue";
import EditLocationModal from "../modals/locations/EditLocation.vue";
import { VOIP_API } from "../../../utils";
import InfiniteLoading from 'vue-infinite-loading';
export default {
  name: "Locations",
  inject:['isEmpty'],
  components: {
    AddLocationModal,
    EditLocationModal,
    InfiniteLoading,
  },
  data() {
    return {
      response: {
        locations: [],
      },
      api: {
        locations: this.$helperFunction.apiInstance({ error_message: true, }),
      },
      filter: {
        locations: {
          search: "",
        },
      },
      pagination: {
        locations: {
          per_page: 10,
          page: 1,
          total: 0,
        },
      },
    };
  },
  computed: {
    filterLocations() {
      const search = this.filter.locations.search.toLowerCase().trim();
      const locations = this.response.locations
      const filter_locations = locations.filter((item) => `${item.address} ${item.country} ${item.house} ${item.town} ${item.street} ${item.label} ${item.caller} ${(item.caller || '').replace(/-/g,'')}`.toLowerCase().trim().includes(search));
      return filter_locations
    },
  },
  methods: {
    fetchLocations(reset) {
      let vm = this
      if(vm.api.locations.send) return;
      if(reset==true){
        vm.response.locations = []
        vm.pagination.locations.page=1
        vm.$refs.locations_infinity_loader?.stateChanger?.reset?.()
      }
      vm.api.locations.send=true
      vm.api.locations.error_message=''
      VOIP_API.endpoints.location.list({
        page: vm.pagination.locations.page,
      })
      .then(({ data, no_record }) => {
        const locations = data?.data ?? []
        const total = data?.meta?.pagination?.total ?? 0
        vm.pagination.locations.total = total
        if(vm.pagination.locations.page==1) vm.response.locations = locations
        else vm.response.locations = [...vm.response.locations,...locations]
        if(no_record) vm.$refs.locations_infinity_loader?.stateChanger?.complete?.()
        else {
          vm.$refs.locations_infinity_loader?.stateChanger?.loaded?.()
          vm.pagination.locations.page=vm.pagination.locations.page+1
          if(vm.response.locations.length<vm.pagination.locations.total) setTimeout(()=>vm.$refs.locations_infinity_loader.attemptLoad(true),2 * 1000)
          else vm.$refs.locations_infinity_loader?.stateChanger?.complete?.()
        }
      })
      .catch((ex)=>{
        vm.$refs.locations_infinity_loader?.stateChanger?.error?.()
        vm.api.locations.error_message=ex.own_message || ex.message
      })
      .finally(() => {
        vm.api.locations.send=false
      });
    },
  },
  activated() {
    setTimeout(()=>this.fetchLocations(true),0.5*1000)
  },
};
</script>

