<template>
    <modal class="dialer_animation right_side_popup global-modal-policy-hrm Scrollable-hrm" name="CreateNewPolicyTimeoffModal"  :clickToClose="true"  @before-open="beforeOpen" :scrollable="true">
      <form @submit.prevent="''">
        <div class="dialer-hrm-modal-header mar-top-hrm20">
            <h2 class="dialer-modal-hrm-title ">Add new task</h2>
            <div>
              <button class="dialer-hrm-button-modal" @click="$modal.hide('CreateNewPolicyTimeoffModal')">Close</button>
            </div>
        </div>
        <div class="bg-modal-doc-grey-share-with sp-gm-if mar-top-hrm30">
            <h5 class="policy-sm">Add new policy</h5>
            <div class="d-flex">
              <div class="sp-gm-if w-50 mr-2">
                <label for="Street" class="gm-hrm-label">Policy name</label>
                <b-form-input list="input-list"  placeholder="" class="gm-hrm-fields"></b-form-input>
              </div>
              <div class="sp-gm-if w-50 ml-2 policy-gm-dd-container">
                <label class="gm-hrm-label">Pick a color</label>
                <b-dropdown no-caret class="policy-gm-mainBorderfordropi">
                  <template #button-content>
                    <div class="policy-gm-button-content-dropi">
                      <div class="policy-gm-dropi-contenti">Pick a color</div>
                      <div class="pickacolor">
                      <b-icon class="dropi-icon uniquesvgcolorpicker" icon="caret-down-fill"></b-icon>
                      </div>
                    </div>
                  </template>
                  <b-dropdown-item>Remd</b-dropdown-item>
                  <b-dropdown-item>Nemla</b-dropdown-item>
                  <b-dropdown-item>Yemlow</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div class="sp-gm-if ">
              <label for="Street" class="gm-hrm-label">Time off policy description</label>
              <b-form-textarea
                id="textarea-small"
                size="sm"
                placeholder="Small textarea"
                class="gm-hrm-textnote">
              </b-form-textarea>
            </div>
            <div class="d-flex justify-content-start align-items-center policy-gm-dd-container">
              <h5 class="onboard-inline-text mb-imp mr-3">Allocate</h5>
              <b-form-input list="input-list"  placeholder="" class="gm-hrm-sm-fields mr-3"></b-form-input>
              
              <b-dropdown no-caret class="policy-gm-mainBorderfordropi width-unique-one">
                <template #button-content>
                  <div class="policy-gm-button-content-dropi">
                    <div class="policy-gm-dropi-contenti">days</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">Weekdays</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">Off days</b-dropdown-item>
              </b-dropdown>
            </div>
            <div class="d-flex">
              <div class="sp-gm-if w-50 mr-2">
                <label class="gm-hrm-label">Accure</label>
                <div class="policy-gm-dd-container">
                  <b-dropdown no-caret class="policy-gm-mainBorderfordropi">
                    <template #button-content>
                      <div class="policy-gm-button-content-dropi">
                        <div class="policy-gm-dropi-contenti">Mentor</div>
                        <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                      </div>
                    </template>
                    <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                    <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <div class="sp-gm-if w-50 ml-2">
                <label class="gm-hrm-label">Duration</label>
                <div class="policy-gm-dd-container">
                  <b-dropdown no-caret class="policy-gm-mainBorderfordropi">
                      <template #button-content>
                        <div class="policy-gm-button-content-dropi">
                          <div class="policy-gm-dropi-contenti">Mentor</div>
                          <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                        </div>
                      </template>
                      <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                      <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
        </div>
        <div class="bg-modal-doc-grey-share-with sp-gm-if">
          <div class="bg-modal-setting-policy">
            <h5 class="policy-modal-semi-head">
              What should we consider to determine working days and daily working hours for this policy?\
            </h5>
            <p class="policy-modal-text">
              This will determine days that are considered working days and thus, count against allowance, as well as daily 
              working hours, this value is used to convert days into hours for part day requests.
            </p>
            <b-form-group  class="gm-hrm-custom-radio">
              <b-form-radio name="some-radios" value="A">Everyone</b-form-radio>
              <b-form-radio name="some-radios" value="B">Sasha Banks</b-form-radio>
              <b-form-radio  name="some-radios" value="C">Jenifer</b-form-radio>
            </b-form-group>
            <div class="d-flex justify-content-start align-items-center">
              <h5 class="onboard-inline-text mb-imp mr-3">Default working hours</h5>
              <b-form-input list="input-list"  placeholder="20" class="gm-hrm-sm-fields"></b-form-input>
            </div>
            <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Count weekends as workdays</span>
            </b-form-checkbox>
            <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Count public holidays as workdays</span>
            </b-form-checkbox>
          </div>
        </div>
        <div class="bg-modal-doc-grey-share-with sp-gm-if">
            <div class="d-flex justify-content-start align-items-center">
                <h5 class="onboard-inline-text mb-imp mr-3">Default working hours</h5>
                <div class="policy-gm-dd-container">
                <b-dropdown no-caret class="policy-gm-mainBorderfordropi width-unique-two">
                  <template #button-content>
                    <div class="policy-gm-button-content-dropi">
                      <div class="policy-gm-dropi-contenti">days not carry over</div>
                      <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                  <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
                </b-dropdown>
              </div>
              <h5 class="onboard-inline-text mb-imp ml-3">(this change will be applied only on the next yearly reset)</h5>
          </div>
         <div class="mar-top-hrm25">
            <label class="gm-hrm-label">Do not accure when taking leave from one of these policies </label>
              
            <div class="policy-gm-dd-container">
              <b-dropdown no-caret class="policy-gm-mainBorderfordropi width-unique-three">
                <template #button-content>
                  <div class="policy-gm-button-content-dropi">
                    <div class="policy-gm-dropi-contenti">Category</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
              </b-dropdown>
            </div>
         </div>
        </div>
        <div>
          <div class="mar-top-hrm25">
              <div class="hrm-bg-accordian flex-column justify-content-start align-item-start">
                <div class="d-flex justify-content-between w-100 align-items-center">
                  <h5 class="accordian-togg-text">Holiday-UK</h5>
                  <b-button v-b-toggle.Sickday-1 variant="primary" class="togg-btn-report">
                    <svg xmlns="http://www.w3.org/2000/svg" width="17.46" height="9.926" viewBox="0 0 17.46 9.926" class="svgtoggle">
                      <g id="Group_15893" data-name="Group 15893" transform="translate(-54.046 -5.227)">
                        <g id="Group_15891" data-name="Group 15891" transform="translate(55.46 6.64)">
                        <path id="Path_41822" data-name="Path 41822" d="M13996.459,9709.063l7.113,6.908" transform="translate(-13996.459 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
                        <path id="Path_41823" data-name="Path 41823" d="M14003.77,9709.063l-7.311,7.1" transform="translate(-13989.138 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
                        </g>
                      </g>
                    </svg>
                  </b-button>
                </div>
                <b-collapse id="Sickday-1" class="w-100">
                  <b-card class="report-cards">
                    <p class="report-card-text">Working hours per day: 8.0</p>
                    <p class="report-card-text">Annually allowed: 20 days</p>
                    <p class="report-card-text">Currently available: 8.87 days</p>
                  </b-card>
                  <h5 class="accordian-togg-text">Holiday</h5>
                  <vb-table :tableDesign="1" class="reports-table-width">
                    <template slot="header">
                      <h5 class="commonth emp-reports-firsttd col-left">created</h5>
                      <h5 class="commonth emp-reports-secondtd">created by</h5>
                      <h5 class="commonth emp-reports-thirdtd">time off requested</h5>
                      <h5 class="commonth emp-reports-fourtd">note</h5>
                      <h5 class="commonth emp-reports-fivetd">action</h5>
                      <h5 class="commonth emp-reports-sixtd ">days</h5>
                    </template>
                    <template slot="body">
                      <tr>
                        <td class="emp-reports-firsttd">
                          02/03/2022 - "Current"
                        </td>
                        <td class="emp-reports-secondtd">
                          System
                        </td>
                        <td class="emp-reports-thirdtd ">
                          N/A
                        </td>
                        <td class="emp-reports-fourtd">
                          Yearly accural
                        </td>
                        <td class="emp-reports-fivetd">
                          increase
                        </td>
                        <td class="emp-reports-sixtd ">
                          8.87 
                        </td>
                      </tr>
                    </template>
                  </vb-table>
                </b-collapse>
              </div>
          </div>
          <div class="mar-top-hrm25">
            <div class="hrm-bg-accordian flex-column justify-content-start align-item-start">
              <div class="d-flex justify-content-between w-100 align-items-center">
                <h5 class="accordian-togg-text">Holiday-UK</h5>
                <b-button v-b-toggle.holiduk-1 variant="primary" class="togg-btn-report">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17.46" height="9.926" viewBox="0 0 17.46 9.926" class="svgtoggle">
                    <g id="Group_15893" data-name="Group 15893" transform="translate(-54.046 -5.227)">
                      <g id="Group_15891" data-name="Group 15891" transform="translate(55.46 6.64)">
                      <path id="Path_41822" data-name="Path 41822" d="M13996.459,9709.063l7.113,6.908" transform="translate(-13996.459 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
                      <path id="Path_41823" data-name="Path 41823" d="M14003.77,9709.063l-7.311,7.1" transform="translate(-13989.138 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
                      </g>
                    </g>
                  </svg>
                </b-button>
              </div>
              <b-collapse id="holiduk-1" class="w-100">
                <b-card class="report-cards">
                  <p class="report-card-text">Working hours per day: 8.0</p>
                  <p class="report-card-text">Annually allowed: 20 days</p>
                  <p class="report-card-text">Currently available: 8.87 days</p>
                </b-card>
                <h5 class="accordian-togg-text">Holiday</h5>
                <vb-table :tableDesign="1" class="reports-table-width">
                  <template slot="header">
                    <h5 class="commonth emp-reports-firsttd col-left">created</h5>
                    <h5 class="commonth emp-reports-secondtd">created by</h5>
                    <h5 class="commonth emp-reports-thirdtd">time off requested</h5>
                    <h5 class="commonth emp-reports-fourtd">note</h5>
                    <h5 class="commonth emp-reports-fivetd">action</h5>
                    <h5 class="commonth emp-reports-sixtd ">days</h5>
                  </template>
                  <template slot="body">
                    <tr>
                      <td class="emp-reports-firsttd">
                        02/03/2022 - "Current"
                      </td>
                      <td class="emp-reports-secondtd">
                        System
                      </td>
                      <td class="emp-reports-thirdtd ">
                        N/A
                      </td>
                      <td class="emp-reports-fourtd">
                        Yearly accural
                      </td>
                      <td class="emp-reports-fivetd">
                        increase
                      </td>
                      <td class="emp-reports-sixtd ">
                        8.87 
                      </td>
                    </tr>
                  </template>
                </vb-table>
              </b-collapse>
            </div>
          </div>
          <div class="mar-top-hrm25">
            <div class="hrm-bg-accordian flex-column justify-content-start align-item-start">
              <div class="d-flex justify-content-between w-100 align-items-center">
                <h5 class="accordian-togg-text">Business trip</h5>
                <b-button v-b-toggle.btrip-1 variant="primary" class="togg-btn-report">
                  <svg xmlns="http://www.w3.org/2000/svg" width="17.46" height="9.926" viewBox="0 0 17.46 9.926" class="svgtoggle">
                    <g id="Group_15893" data-name="Group 15893" transform="translate(-54.046 -5.227)">
                      <g id="Group_15891" data-name="Group 15891" transform="translate(55.46 6.64)">
                      <path id="Path_41822" data-name="Path 41822" d="M13996.459,9709.063l7.113,6.908" transform="translate(-13996.459 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
                      <path id="Path_41823" data-name="Path 41823" d="M14003.77,9709.063l-7.311,7.1" transform="translate(-13989.138 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
                      </g>
                    </g>
                  </svg>
                </b-button>
              </div>
              <b-collapse id="btrip-1" class="w-100">
                <b-card class="report-cards">
                  <p class="report-card-text">Working hours per day: 8.0</p>
                  <p class="report-card-text">Annually allowed: 20 days</p>
                  <p class="report-card-text">Currently available: 8.87 days</p>
                </b-card>
                <h5 class="accordian-togg-text">Holiday</h5>
                <vb-table :tableDesign="1" class="reports-table-width">
                  <template slot="header">
                    <h5 class="commonth emp-reports-firsttd col-left">created</h5>
                    <h5 class="commonth emp-reports-secondtd">created by</h5>
                    <h5 class="commonth emp-reports-thirdtd">time off requested</h5>
                    <h5 class="commonth emp-reports-fourtd">note</h5>
                    <h5 class="commonth emp-reports-fivetd">action</h5>
                    <h5 class="commonth emp-reports-sixtd ">days</h5>
                  </template>
                  <template slot="body">
                    <tr>
                      <td class="emp-reports-firsttd">
                        02/03/2022 - "Current"
                      </td>
                      <td class="emp-reports-secondtd">
                        System
                      </td>
                      <td class="emp-reports-thirdtd ">
                        N/A
                      </td>
                      <td class="emp-reports-fourtd">
                        Yearly accural
                      </td>
                      <td class="emp-reports-fivetd">
                        increase
                      </td>
                      <td class="emp-reports-sixtd ">
                        8.87 
                      </td>
                    </tr>
                  </template>
                </vb-table>
              </b-collapse>
            </div>
          </div>
        </div>
        <button class="btn-primarynewupdated1 mt-4 mb-4">Create</button>
      </form>
    </modal>
</template>
<script>

export default {
  name: 'CreateNewPolicyTimeoffModal',
  
}

</script>

<style>

</style>