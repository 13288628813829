<template>
  <div>
    <div class="mainHeading withButton">
      Admin permissions
    </div>
    <div class="mainDescription mb-4">
      Employees in this group can see all fields, edit all fields, and do everything the system offers. Be careful with granting this access level!
    </div>
    <div class="documentsModule-Table mt-32px">
      <vb-table class="HrmTable">
        <template slot="header">
          <tr>
            <th class="HrmTableHead one pt-1">Current employees with admin rights</th>
            <th class="HrmTableHead text-left two pt-1">Employees</th>
          </tr>
        </template>
        <template #body>
          <tr v-for="n in 6" :key="n" class="dialer-row-select" >
            <td class="dialer-row-title one">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img src="/img/1.dd976805.jpeg">
                </div>
                <div class="nameWithImage-text">Marie jane</div>
              </div>
            </td>
            <td class="dialer-col-right text-left two">
              <b-form-checkbox class="HrmCheckBox-container"></b-form-checkbox>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionsAdmin',
}
</script>

<style>

</style>