<template>
  <div>
    
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.add_call_queue.send?'':$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add Call Queue</div>
        <div class="plus-IOSIcon-container" @click="$modal.show('CheckedSlot',{ service: 'queue', onProceed: addCallQueue })">
          <div class="textInsideCard onRightSide primaryColor">Detail</div>
        </div>
      </div>
      <div class="innerModalIos">

        <form class="dialer-form lg-mar-bottom" @submit.prevent="addCallQueue()">
          <b-alert class="mt-32px" :show="!!api.add_call_queue.error_message" variant="danger">{{api.add_call_queue.error_message}}</b-alert>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Name</label>
                <input 
                  placeholder="Enter Name" 
                  class="w-100" 
                  type="text" 
                  :disabled="api.add_call_queue.send" 
                  v-model="forms.call_queue.name" 
                />
                <p 
                  v-if="(forms.call_queue.submitted && $v.forms.call_queue.name.$invalid) || api.add_call_queue.validation_errors.name" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.call_queue.name.required">* Name is required</span>
                  <span v-for="em in api.add_call_queue.validation_errors.name" :key="em">* {{ em }}</span>
                </p>
              </div>
            </div>
          </div>
          <button class="IosFullWidthButton forDone mb-16px" :disabled="api.add_call_queue.send">
            <vb-spinner v-if="api.add_call_queue.send" />
            <template v-else>Add</template>
          </button>
        </form>

      </div>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils/index';
export default {
  name: "AddCallQueueModal",
  props: {
    modalName: {
      type: String,
      default: 'AddCallQueue',
    },
  },
  data() {
    return {
      forms: {
        call_queue: this.$helperFunction.formInstance({
          data: {
            name: '',
          }
        }),
      },
      api: {
        add_call_queue: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true }),
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
  },
  validations: {
    forms: {
      call_queue: {
        name: { 
          required 
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      this.forms.call_queue.name=event?.params?.suggested_name ?? ''
    },
    onBeforeClose(){
      this.forms.call_queue.reset()
      this.api.add_call_queue.reset()
    },
    addCallQueue() {
      let vm = this
      vm.forms.call_queue.submitted=true
      vm.$v.forms.call_queue.$touch()
      if(vm.api.add_call_queue.send || vm.$v.forms.call_queue.$invalid) return;
      vm.api.add_call_queue.send=true
      vm.api.add_call_queue.validation_errors={}
      vm.api.add_call_queue.error_message=''
      VOIP_API.endpoints.call_queues.create({
        name: vm.forms.call_queue.name,
        accountcode: vm.getCurrentUser?.account,
        default_account: vm.getCurrentUser?.default_accountcode,
        service: "queue",
        uid: vm.getCurrentUser?.uid,
      }).then(()=>{
        vm.$emit("call-queue-added");
        vm.$modal.hide(vm.modalName)
      }).catch((ex)=>{
        vm.api.add_call_queue.validation_errors=ex.own_errors || {}
        vm.api.add_call_queue.error_message=ex.own_message || ''
      }).finally(()=>{
        vm.api.add_call_queue.send=false
        vm.forms.call_queue.submitted=false
      })
    },
  },
};
</script>
