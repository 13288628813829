<template>
  <div>
    <div class="mainHeading withButton">
      Automated Reports
    </div>
    <div class="mainDescription mb-4">
     
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeOffAutomatedReports',
}
</script>

<style>

</style>