<template>
  <div>
    <div class="whiteBoxWithBorderInsideGreyBox-row UserCallRecordingWidget">
      <div class="d-flex flex-column">
        <div class="latestGreyBox-heading-main">Call Recording</div>
        <div v-if="userCallRecording" class="latestGreyBox-descriptionText d-flex align-items-center">
          <vb-icon :icon="userCallRecording | filterIcon" height="30" width="20" class="noRect svgTextFill-5757575"/>
          <span class="ml-16px">{{ userCallRecording | filterIconText }}</span>
        </div>
      </div>
      <div class="lg-mar-left">
        <a 
          class="newButton sm-mar-left" 
          @click="$modal.show('CallRecordingEditModal',{ 
            account: userCallRecording.account, 
            allow_recording: userCallRecording.allow_recording, 
            setting: userCallRecording.auto == 1 ? 'auto' : userCallRecording.ondemand == 1 ? 'ondemand' : 'off' ,
            allUserData: allUserData, 
            editUserModal: editUserModal, 
          })"
        >
          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
          <span class="newButton-textPart">Change</span>
        </a>
      </div>
    </div>
    <!-- <div v-else-if="userCallRecording">
      <Info class="w-fit-content" :id="userCallRecording.account" is_suspended_show :name="userCallRecording.user" :sub_info="userCallRecording.extn" :is_blf="false" />
      <vb-icon :icon="userCallRecording | filterIcon" height="30" :width="userCallRecording | filterIconWidth" class="noRect svgTextFill-5757575"/>
      <button 
        class="newButton"
        @click="$modal.show('CallRecordingEditModal',{ 
          account: userCallRecording.account, 
          allow_recording: userCallRecording.allow_recording, 
          setting: userCallRecording.auto == 1 ? 'auto' : userCallRecording.ondemand == 1 ? 'ondemand' : 'off' 
        })" 
        type="button"
      >
        <vb-icon icon="squared-show-icon" height="38px" width="38px" />
        <span class="newButton-textPart">View</span>
      </button>
    </div> -->
    <CallRecordingEditModal @latest="fetchCallRecordings()" 
      :allUserData="allUserData" 
      :editUserModal="editUserModal" 
    />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex'
import CallRecordingEditModal from "../../Modals/CallRecordingEditModal.vue";
export default {
  name: 'UserCallRecordingWidget',
  components: {
    CallRecordingEditModal,
  },
  props: {
    accountcode: {
      type: String,
      default: ''
    },
    editUserModal: {
      type: Boolean,
      default: false
    },
    allUserData: {
      type: Object,
      default: ()=>{return{}},
    }
  },
  data() {
    return {
      response: {
        call_recordings: {},
      },
      api: {
        call_recordings: this.$helperFunction.apiInstance(),
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getVoipUsersAlises',
      'getCurrentUser',
      'getIsMobile'
    ]),
    userCallRecording(){
      return this.response.call_recordings[this.accountcode]
    },
  },
  filters: {
    filterIcon(user){
      // user?.allow_recording != '1' || 
      if(user?.auto != 1 && user?.ondemand != 1) return 'CallRecordingOff-inSettings-icon'
      else if(user?.auto == 1) return 'CallRecordingAuto-inSettings-icon' //auto
      else if(user?.ondemand == 1) return 'CallRecordingOnDemand-inSettings-icon' //demand
      // else if(user?.allow_recording == '1') return 'CallRecordingOn-inSettings-icon'
      else return 'CallRecordingOff-inSettings-icon'
    },
    filterIconText(user){
      // user?.allow_recording != '1' || 
      if(user?.auto != 1 && user?.ondemand != 1) return 'Call recording off'
      else if(user?.auto == 1) return 'Automatic call recording' //auto
      else if(user?.ondemand == 1) return 'Ondemand call recording' //demand
      // else if(user?.allow_recording == '1') return 'CallRecordingOn-inSettings-icon'
      else return 'Call recording off'
    },
    filterIconWidth(user){
      // user?.allow_recording != '1' || 
      if(user?.auto != 1 && user?.ondemand != 1) return 155
      else if(user?.auto == 1) return 201
      else if(user?.ondemand == 1) return 210
      // else if(user?.allow_recording == '1') return 210
      else return 155
    },
  },
  methods: {
    async fetchCallRecordings() {
      if(this.api.call_recordings.send) return;
      try {
        this.api.call_recordings.send=true
        const { data } = await VOIP_API.endpoints.users.usercallrecordingsetting()
        this.response.call_recordings = data ?? [];
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.call_recordings.send=false
      }
    },
  },
  mounted() {
    this.fetchCallRecordings();
    // console.log("editUserModal",this.editUserModal)
    // console.log("allUserData",this.allUserData)
  },
}
</script>

<style>

</style>