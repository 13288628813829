<template>
  <div class="latestGreyBox-9-9-2023 firstSectionEditUserModal">
    <div class="leftSide">
      <div class="d-flex justify-content-between w-100">
        <div class="whiteBGinputWithGreyRoundedBorder withRightIconOnly widthCalc50-10px">
          <label>First name</label>
          <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
            <input class="w-100" type="text" disabled :value="generalInfo.first_name" />
            <vb-icon icon="pencil-fill" width="14.153px" height="14.152px" @click="mainOrAdminAccess ? updatedInfoModal() : ''" />
          </div>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder withRightIconOnly widthCalc50-10px">
          <label>Last name</label>
          <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
            <input class="w-100" type="text" disabled :value="generalInfo.last_name" />
            <vb-icon icon="pencil-fill" width="14.153px" height="14.152px" @click="mainOrAdminAccess ? updatedInfoModal() : ''" />
          </div>
        </div>
      </div>
      <div class="d-flex justify-content-between w-100 mt-38px">
        <div v-if="userType=='user'" class="whiteBGinputWithGreyRoundedBorder withRightIconOnly withTextOnRightSideOnly widthCalc50-10px">
          <label>Username</label>
          <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
            <input class="w-100" type="text" disabled :value="generalInfo.username" />
            <vb-icon icon="pencil-fill" width="14.153px" height="14.152px" style="z-index: 2" @click="mainOrAdminAccess ? updatedInfoModal() : ''" />
            <span class="withTextOnRightSideOnly-text smaller iconIsThereToo" style="z-index: 1">@{{organization}}</span>
          </div>
        </div>
        <div v-if="userType=='user'" class="whiteBGinputWithGreyRoundedBorder withRightIconOnly widthCalc50-10px">
          <label>Email for notification</label>
          <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
            <input class="w-100" type="text" disabled :value="generalInfo.email_for_notification" />
            <vb-icon icon="pencil-fill" width="14.153px" height="14.152px" @click="mainOrAdminAccess ? updatedInfoModal() : ''" />
          </div>
        </div>
        <div v-if="userType!='user'" class="whiteBGinputWithGreyRoundedBorder withRightIconOnly widthCalc50-10px">
          <label>Email</label>
          <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
            <input class="w-100" type="text" disabled :value="generalInfo.email" />
            <vb-icon icon="pencil-fill" width="14.153px" height="14.152px" @click="mainOrAdminAccess ? updatedInfoModal() : ''" />
          </div>
        </div>
      </div>
    </div>
    <div class="rightSide d-flex">
      <div class="settingsGridView-container align-items-stretch p-0 w-100">
        <div class="settingsGridView-item justify-content-between w-100 m-0 bg-transparent border-0 p-0">
          <div class="profileImg-section-latest-container">
            <div 
              class="profileImg-section-latest" 
              @click="mainOrAdminAccess ? $modal.show(`UserGeneralInfoUpdateWidget-${_uid}-ProfileImageUploader`, { 
                type: 'USER', 
                accountcode: accountcode, 
                current_image: generalInfo.image 
              }) : ''"
            >
              <img :src="generalInfo.image" @error="$event.target.src=local_filess.images.user_male" />
            </div>
          </div>
          <div class="firstSectionEditUserModal-heading">{{ generalInfo.first_name }} {{ generalInfo.last_name }}</div>
          <div 
            class="firstSectionEditUserModal-text text-underline cursor_pointer_no_hover"
            @click="mainOrAdminAccess ? $modal.show(`UserGeneralInfoUpdateWidget-${_uid}-UpdateUserExtension`, { 
              accountcode: accountcode, 
              extn: generalInfo.extn 
            }) : ''"
          >
            {{ generalInfo.extn }}
          </div>
          <span 
            class="settingsGridView-email text-underline cursor_pointer_no_hover" 
            @click="mainOrAdminAccess ? $modal.show(`UserGeneralInfoUpdateWidget-${_uid}-ChangeJobTitle`) : ''"
          >
            {{generalInfo.job_title || 'Job title'}}
          </span>
          <span 
            v-if="userType!='invite'"
            class="settingsGridView-email text-underline cursor_pointer_no_hover" 
            @click="mainOrAdminAccess ? $modal.show(`UserGeneralInfoUpdateWidget-${_uid}-ChangeUserPassword`,{
              accountcode: accountcode,
            }) : ''"
          >
            Change password
          </span>
          <div 
            v-if="userType=='main'" 
            class="OwnertaggedVersion cursor_pointer_no_hover mt-12px" 
            @click="mainUserAccessOnly?$modal.show(`UserGeneralInfoUpdateWidget-${_uid}-ChangeOwnerShip`,{
              accountcode: accountcode,
            }):''"
          >
            Owner
          </div>
        </div>
      </div>
    </div>
    <ChangeUserPasswordModal :modalName="`UserGeneralInfoUpdateWidget-${_uid}-ChangeUserPassword`" />
    <UpdateUserExtensionModal :modalName="`UserGeneralInfoUpdateWidget-${_uid}-UpdateUserExtension`" @updated="getVoipUsers()" />
    <ProfileImageUploader :modalName="`UserGeneralInfoUpdateWidget-${_uid}-ProfileImageUploader`" @image-uploaded="getVoipUsers()" />
    <UpdateUserGeneralInfoModal :modalName="`UserGeneralInfoUpdateWidget-${_uid}-UpdateUserGeneralInfo`" @updated="getVoipUsers();fetchVoicemailSettings();" />
    <ChangeOwnerShipModal :modalName="`UserGeneralInfoUpdateWidget-${_uid}-ChangeOwnerShip`" @changed="getVoipUsers()" />
    <modal :name="`UserGeneralInfoUpdateWidget-${_uid}-ChangeJobTitle`" class="center-zoomIn-transition v2 m-w-500" height="auto" @before-open="forms.update_jobtitle.title=generalInfo.job_title || '';" @before-close="api.update_jobtitle.reset();forms.update_jobtitle.reset()" >
      <div class="w-100">
        <div class="d-flex align-items-start justify-content-between w-100">
          <div class="d-flex flex-column">
            <h2 class="dialer-edit-title">Change job title</h2>
            <p>Update job title for user.</p>
          </div>
          <button @click="$modal.hide(`UserGeneralInfoUpdateWidget-${_uid}-ChangeJobTitle`)" class="newCloseButton" type="button">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
        <form @submit.prevent="updateJobTittle()" class="latestGreyBox-9-9-2023 mt-20px w-100">
          <b-alert :show="!!api.update_jobtitle.error_message" variant="danger">{{ api.update_jobtitle.error_message }}</b-alert>
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <label>Set job title</label>
            <input 
              class="w-100" 
              type="text" 
              v-model="forms.update_jobtitle.title" 
              :disabled="api.update_jobtitle.send" 
              :maxlength="$v.forms.update_jobtitle.title.$params.maxLength.max"
            />
            <p class="text text-danger animated bounceIntop" v-if="forms.update_jobtitle.submitted && $v.forms.update_jobtitle.title.$invalid">
              <span v-if="!$v.forms.update_jobtitle.title.required">job title is required</span>
              <span v-else-if="!$v.forms.update_jobtitle.title.minLength">job title should be minimum {{$v.forms.update_jobtitle.title.$params.minLength.min}} character</span>
              <span v-else-if="!$v.forms.update_jobtitle.title.maxLength">job title can be maximum {{$v.forms.update_jobtitle.title.$params.maxLength.max}} character</span>
              <span v-else-if="!$v.forms.update_jobtitle.title.valid">job title can only be alphabet character</span>
            </p>
            <p v-else-if="api.update_jobtitle.validation_errors.job_tittle">
              <span v-for="(em,i) in api.update_jobtitle.validation_errors.job_tittle" :key="i">{{em}}</span>
            </p>
          </div>
        </form>
        <div class="w-100 d-flex justify-content-end mt-20px">
          <button class="fullWidthDoneButton" type="button" @click="updateJobTittle()">
            <vb-spinner v-if="api.update_jobtitle.send" />
            <span v-else>Update</span>
          </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import ChangeUserPasswordModal from '@/components/Modals/ChangeUserPasswordModal.vue';
import UpdateUserExtensionModal from '@/components/Modals/UpdateUserExtensionModal.vue';
import ProfileImageUploader from '@/components/Modals/ProfileImageUploader.vue';
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils';
import UpdateUserGeneralInfoModal from '@/components/Modals/UpdateUserGeneralInfoModal.vue';
import ChangeOwnerShipModal from '@/components/Modals/ChangeOwnerShipModal.vue';
import required from 'vuelidate/lib/validators/required';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
import { helpers } from 'vuelidate/lib/validators';

export default {
  name: 'UserGeneralInfoUpdateWidget',
  components: { 
    ChangeUserPasswordModal,
    UpdateUserExtensionModal,
    ProfileImageUploader,
    UpdateUserGeneralInfoModal,
    ChangeOwnerShipModal,
  },
  inject: [
    'local_filess',
    'getVoipUsers',
    'appNotify'
  ],
  props: {
    accountcode: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      forms: {
        update_jobtitle: this.$helperFunction.formInstance({
          data: {
            title: '',
          },
        })
      },
      api: {
        fetch_voicemail_setting: this.$helperFunction.apiInstance(),
        update_jobtitle: this.$helperFunction.apiInstance({
          error_message: true, 
          validation_errors: true,
        }),
      },
      response: {
        voicemail_settings: {},
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsersAlises',
    ]),
    userType(){ return this.voipuser?.user?.user_type ?? '' },
    voipuser(){ return this.getVoipUsersAlises[this.accountcode] ?? this.getVoipUsersAlises[this.getCurrentUser?.account] },
    generalInfo(){
      const display_name = this.voipuser?.user?.display_name ?? ''
      return {
        first_name: display_name.split(' ')?.[0] ?? '',
        last_name: display_name.split(' ')?.[1] ?? '',
        extn: this.voipuser?.extn ?? '',
        username: this.voipuser?.user?.ac_user ?? '',
        email: this.email,
        email_for_notification: this.response.voicemail_settings?.detail?.settings?.mailboxemail ?? '',
        image: this.voipuser?.profile_img ?? '',
        job_title: this.voipuser?.user?.job_tittle ?? '',
      }
    },
    email(){
      const email = this.voipuser?.user?.email
      const email2 = this.voipuser?.user?.email2
      const ac_user = this.voipuser?.user?.ac_user
      const current_user_email = this.getCurrentUser.email
      return ((email2 || email) === current_user_email ? ac_user : (ac_user != email) ? ac_user : email2 || email || ac_user) ?? ''
    },
    organization(){
      const org = this.getCurrentUser.company
      return org
    },
    mainUserAccessOnly(){ return this.userType=='main' && this.accountcode==this.getCurrentUser.account },
    mainOrAdminAccess(){ return this.userType=='main' ? this.accountcode==this.getCurrentUser.account : true },
  },
  validations: {
    forms: {
      update_jobtitle: {
        title: {
          required: required,
          minLength: minLength(3),
          maxLength: maxLength(40),
          valid: helpers.regex('valid',/^[A-Za-z ]+/),
        }
      },
    },
  },
  methods: {
    updatedInfoModal(){
      this.$modal.show(`UserGeneralInfoUpdateWidget-${this._uid}-UpdateUserGeneralInfo`,{
        accountcode: this.accountcode,
        first_name: this.generalInfo.first_name,
        last_name: this.generalInfo.last_name,
        username: this.generalInfo.username,
        email: this.generalInfo.email,
        email_for_notification: this.generalInfo.email_for_notification,
        extn: this.generalInfo.extn,
      })
    },
    async fetchVoicemailSettings() {
      if (this.api.fetch_voicemail_setting.send) return;
      try {
        this.api.fetch_voicemail_setting.send = true;
        const { data } = await VOIP_API.endpoints.voicemail.list.servicevoicemail(this.accountcode, {
          fid: 12,
          id: this.accountcode
        })
        this.response.voicemail_settings=data ?? {}
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        
      } finally {
        this.api.fetch_voicemail_setting.send = false;
      }
    },
    async updateJobTittle(){
      this.forms.update_jobtitle.submitted=true
      this.$v.forms.update_jobtitle.$touch()
      if(this.$v.forms.update_jobtitle.$invalid) return;
      if(this.api.update_jobtitle.send) return;
      try {
        this.api.update_jobtitle.send=true
        this.api.update_jobtitle.error_message=''
        this.api.update_jobtitle.validation_errors={}
        await VOIP_API.endpoints.users.updateUserJobTitle({
          id: 12,
          accountcode: this.accountcode,
          job_tittle: this.forms.update_jobtitle.title,
        })
        this.getVoipUsers()
        this.appNotify({
          message: 'Succeessfully Updated',
          type: 'success'
        })
        this.$modal.hide(`UserGeneralInfoUpdateWidget-${this._uid}-ChangeJobTitle`)
      } catch (ex) {
        this.api.update_jobtitle.error_message=ex.own_message
        this.api.update_jobtitle.validation_errors=ex.own_errors
      } finally {
        this.forms.update_jobtitle.submitted=false
        this.api.update_jobtitle.send=false
      }
    },
  },
  mounted(){
    this.fetchVoicemailSettings()
  },
}
</script>

<style>

</style>