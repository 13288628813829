var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.menu.voipaccount ? _c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_vm.isModal ? _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "editModal-imageInsideModalTitle"
  }, [_c('img', {
    attrs: {
      "src": _vm.menu_image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })]), _c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Edit IVR")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('close-modal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]) : _vm._e(), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "newIVRLayout-forBorderBottom d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-50"
  }, [_c('label', [_vm._v("IVR name")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder d-flex"
  }, [_c('img', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: _vm.propMenu.extn,
      expression: "propMenu.extn",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "src": _vm.menu_image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_label.label,
      expression: "forms.update_label.label"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "maxlength": _vm.$v.forms.update_label.label.$params.maxLength.max,
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.update_label.label
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_label, "label", $event.target.value);
      }, _vm.updateLabel]
    }
  }), !_vm.isModal ? _c('div', [_c('vb-icon', {
    staticClass: "graphIcon position-relative cursor_pointer_no_hover",
    attrs: {
      "icon": "graph-icon",
      "height": "40.501",
      "width": "41.374"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click-graph');
      }
    }
  })], 1) : _vm._e()]), _vm.is_submitted.update_label ? [_vm.is_submitted.update_label && _vm.$v.forms.update_label.label.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_label.label.required ? _c('span', [_vm._v("IVR name is required")]) : !_vm.$v.forms.update_label.label.minLength ? _c('span', [_vm._v("IVR name should be minimum " + _vm._s(_vm.$v.forms.update_label.label.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_label.label.maxLength ? _c('span', [_vm._v("IVR name can be maximum " + _vm._s(_vm.$v.forms.update_label.label.$params.maxLength.max) + " character")]) : !_vm.$v.forms.update_label.label.valid ? _c('span', [_vm._v("IVR name can be number and alphabet")]) : _vm._e()]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "profileImg-section-latest-container"
  }, [_c('div', {
    staticClass: "profileImg-section-latest",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('ProfileImageUploader', {
          type: 'IVR',
          accountcode: _vm.menu.voipaccount,
          current_image: _vm.menu_image
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.menu_image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100 newIVRLayout-forBorderBottom mt-20px"
  }, [_c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Telephone number")]), _vm.ivr.userDid.length == 0 ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" No telephone number defined. ")]) : _c('div', {
    staticClass: "d-flex mt-12px IVR-numbers-container flex-wrap"
  }, [_vm._l(_vm.ivr.userDid.filter(function (item, index) {
    return index < 3;
  }), function (did, index) {
    return _c('div', {
      key: index,
      staticClass: "newIVRLayout-number-item"
    }, [!(!_vm.ivr.userDid || !_vm.ivr.userDid[0]) ? [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.did))
      }
    })] : _vm._e(), _c('span', {
      staticClass: "newIVRLayout-number-item-text"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(did.did)))])], 2);
  }), _vm.ivr.userDid.length > 3 ? _c('div', {
    staticClass: "newIVRLayout-number-item"
  }, [_vm._v(" + " + _vm._s(_vm.ivr.userDid.length - 3) + " ")]) : _vm._e()], 2)]), _c('button', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AssignNumberModal`, {
          accountcode: _vm.menu.voipaccount
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)]), _c('div', {
    staticClass: "w-100 newIVRLayout-forBorderBottom mt-20px"
  }, [_c('WelcomeGreeting', {
    staticClass: "newIVRLayout-WelcomeGreetingsSection",
    attrs: {
      "file": _vm.sound,
      "accountcode": _vm.menu.voipaccount,
      "menu": _vm.menu
    },
    on: {
      "fileChange": function ($event) {
        return _vm.$emit('interface');
      }
    }
  })], 1), _vm.menu ? _c('MenuOptionsWidget', {
    attrs: {
      "menu": _vm.menu
    },
    on: {
      "updated": function ($event) {
        _vm.menu = $event.menu;
        _vm.$emit('interface');
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mt-20px"
  }, [_vm.menu.voipaccount ? _c('AdvanceOptions', {
    staticClass: "w-100",
    attrs: {
      "accountcode": _vm.menu.voipaccount
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0"
  }, [_vm.menu.voipaccount ? _c('ScheduleSetting', {
    attrs: {
      "account": _vm.menu.voipaccount
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this IVR?")]), _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.deleteItem();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart"
  }, [_vm._v("Delete")])], 1)])])], 1), _c('RecordingListSelect', {
    attrs: {
      "is_single_select": true
    },
    on: {
      "selectedRecordings": function ($event) {
        return _vm.updateWrongIVRInput($event);
      }
    }
  }), _c('AssignNumberModal', {
    attrs: {
      "modalName": `${_vm._uid}-AssignNumberModal`
    },
    on: {
      "added": function ($event) {
        return _vm.$emit('interface');
      }
    }
  }), _c('ProfileImageUploader', {
    on: {
      "image-uploaded": function ($event) {
        _vm.menu_image = $event;
        _vm.$emit('interface');
      }
    }
  })], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }