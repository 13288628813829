var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_vm.conditions.screen == _vm.screens.select ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Extension")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Extension")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_vm.conditions.screen == _vm.screens.select ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.extnOptions, function (extn) {
    return _c('div', {
      key: extn.value,
      staticClass: "basicWhiteIOScard-item d-flex justify-content-between",
      on: {
        "click": function ($event) {
          return _vm.onSelectExtension(extn);
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v(_vm._s(extn.text))]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1);
  }), 0)] : [_vm.api.checkpurchaselimit.send ? _c('vb-loading') : [_vm.api.checkpurchaselimit.status == 2 ? [_c('div', {
    staticClass: "d-flex align-items-center justify-conent-center text-center headingInsideTable mt-32px mb-16px"
  }, [_vm._v(" Sorry you have reached your purchase limit.Please contact to your administrator. ")])] : [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addExtension();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.add_extension.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_extension.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS"
  }, [_c('label', [_vm._v("Extension Name")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.extension.name,
      expression: "forms.extension.name"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "extension name",
      "disabled": _vm.api.add_extension.send
    },
    domProps: {
      "value": _vm.forms.extension.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.extension, "name", $event.target.value);
      }
    }
  })]), _vm.forms.extension.submitted && _vm.$v.forms.extension.name.$invalid || _vm.api.add_extension.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.extension.name.required ? _c('span', [_vm._v("* Extension Name is required")]) : _vm._e(), _vm._l(_vm.api.add_extension.validation_errors.name || [], function (error_message) {
    return _c('span', {
      key: error_message
    }, [_vm._v("* " + _vm._s(error_message))]);
  })], 2) : _vm._e()])])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "disabled": _vm.api.add_extension.send,
      "type": "button"
    }
  }, [_vm.api.add_extension.send ? _c('vb-spinner') : [_vm._v("Check")]], 2)], 1)]]]], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }