var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isEmpty(_vm.videoCall) ? _c('div', {
    staticClass: "dialer-flex alt dialer-incommingVideoCall-active",
    on: {
      "dblclick": function ($event) {
        _vm.$store.state.global_conditions.tab = 'video-call';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center"
  }, [_c('div', {
    staticClass: "dialer-caller-dp d-flex align-items-center border-right pr-3 mr-3"
  }, [_c('div', [_c('vb-avatar', {
    attrs: {
      "image": _vm.callObjInfo.image
    }
  })], 1)]), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(" " + _vm._s(_vm.callObjInfo.name) + " ")]), _c('p', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(" " + _vm._s(_vm.callObjInfo.sub_info) + " ")]), _vm.$store.state.calls.outgoing_calls[_vm.videoCall.room] ? [_vm.videoCall.outgoing && _vm.videoCall.outgoing.time ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-3"
  }, [_vm._v(" Time Left: " + _vm._s(_vm._f("time_audio")(_vm.videoCall.outgoing.time)) + " ")])]) : _vm._e(), _vm.videoCall.outgoing && _vm.videoCall.outgoing.status ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-3"
  }, [_vm._v("Status: " + _vm._s(_vm.videoCall.outgoing.status))])]) : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-3"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.videoCall.time)))]), _c('b-icon', {
    attrs: {
      "icon": "camera-video"
    }
  })], 1)], 2)]), _c('div', {
    staticClass: "d-flex dialer-incommingVideoCall-close"
  }, [_vm._t("switchCalls"), _c('div', {
    staticClass: "actions-info-container"
  }, [_c('div', {
    staticClass: "actions"
  }, [_c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.bottom.html",
      value: '<small>Hang Up</small>',
      expression: "'<small>Hang Up</small>'",
      modifiers: {
        "hover": true,
        "bottom": true,
        "html": true
      }
    }],
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "dialer-callRejectInbox-icon",
      "width": "40",
      "height": "40",
      "id": "callRejectInbox"
    },
    on: {
      "click": function ($event) {
        return _vm.videoCall.toggleIsHangout();
      }
    }
  })], 1)])])], 2)]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }