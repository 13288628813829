import { VOIP_API } from "../../index"


export const COMPANIES_CRM_API_ENDPOINTS = {
  list(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: `companies`,
      method: 'GET',
      params,
      cancelToken,
    })
  },
  search(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: `companies/search`,
      method: 'GET',
      params,
      cancelToken,
    })
  },
}