import { $fn } from "@/utils/index"

export async function confirmation(options={}) {
  let that = this
  const callback = typeof options.callback == 'function' ? options.callback : null
  const ok = !!options.ok
  const modalId = options.id ?? `modalId-${$fn.getUniqueID()}`
  const message = options?.message ?? ''
  const title = options?.title ?? ''
  const variant = options?.variant ?? 'info'
  const alert = {
    enable: !!options?.alert,
    variant: options?.alert?.variant ?? 'info',
    title: options?.alert?.title ?? 'info',
    message: options?.alert?.message ?? '',
  }
  const default_options = {
    id: modalId,
    okTitle: options?.button?.yes ?? 'Yes',
    cancelTitle: options?.button?.no ?? 'No',
    centered: true,
    modalClass: `new-confirmation-modal-bill ${ok?'ok-confirmation':''} ${variant}`
  }
  const h = that.$createElement
  const el = h('div',{
    
  },[
    h('div',{

    },[
      h('b-icon',{
        props: {
          icon: 'x',
        },
        on: {
          click: ()=>{
            that.$bvModal.hide(modalId)
          }
        },
      }),
      title ? h('h6',{},[
        `${title}`
      ]) : '',
      message ? h('div',{},[
        `${message}`
      ]) : '',
    ]),
    alert.enable ? h('b-alert',{
      props: {
        variant: alert.variant,
        show: true,
      },
    },[
      h('div',{

      },[
        h('div',{

        },[
          alert.variant=='danger' ? h('b-icon',{
            props: {
              icon: 'x',
              variant: 'danger'
            },
          }) :
          h('b-icon',{
            props: {
              icon: 'pencil',
              variant: 'info'
            },
          })
        ]),
        h('div',{

        },[
          alert.title ? h('h5',{
            
          },[
            `${alert.title}`
          ]) : '',
          alert.message ? h('div',{

          },[
            `${alert.message}`
          ]) : '',
        ])
      ])
    ]) : '',
  ])
  async function modalConfirmation(){
    let confirm = null;
    if(ok) {
      confirm = await that.$bvModal.msgBoxOk(el, {
        ...default_options,
        ...options,
      })
    } else {
      confirm = await that.$bvModal.msgBoxConfirm(el, {
        ...default_options,
        ...options,
      })
    }
    return { confirm }
  }
  if(callback) {
    try {
      const data = await modalConfirmation()
      callback(data)
    } catch (ex) {
      callback({ 
        confirm: null,
      })
    }
  } else {
    return modalConfirmation()
  }
} 