<template>
  <div>
    <div class="mainHeading withButton">
      Training events
    </div>
    <div class="mainDescription mb-3">
      <br/>
    </div>
    <div class="dropdownsContainer">
      <div class="primaryBorderInput mr-16px">
        <div class="inputWithIconContainer">
          <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
          <vue-ctk-date-time-picker 
            v-bind="ctkDateTimePickerOptions"  
            :format="'YYYY-MM-DD HH:mm'"
            v-model="start_time" 
          />
        </div>
      </div>
      <div class="primaryBorderInput mr-16px">
        <div class="inputWithIconContainer">
          <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
          <vue-ctk-date-time-picker 
            v-bind="ctkDateTimePickerOptions"  
            :format="'YYYY-MM-DD HH:mm'"
            v-model="end_time"
            label="End data & time"
          />
        </div>
      </div>
      <b-dropdown no-caret class="primaryBorderDropdown w-220px">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All categories</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item>
          All categories
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="grayCard TrainingEventsSection-1 borderRadius-12px">
      <div class="w-25 pr-16px">
        <div class="smallHeading">Events</div>
        <div class="smallText mt-16px">1</div>
      </div>
      <div class="w-25 pr-16px">
        <div class="smallHeading">Attendants</div>
        <div class="smallText mt-16px">1</div>
      </div>
      <div class="w-25 pr-16px">
        <div class="smallHeading">Actual participants</div>
        <div class="smallText mt-16px">1</div>
      </div>
    </div>
    <div class="EmployeeTrainingEventsTable mt-32px">
      <div class="HrmTableHead-container">
        <div class="HrmTableHead one">Event</div>
        <div class="HrmTableHead two">date</div>
        <div class="HrmTableHead three">attending</div>
      </div>
      <vb-table class="HrmTable">
        <template #body>
          <tr class="dialer-row-select">
            <td class="dialer-row-title one">
              <div class="d-flex align-items-center">
                <img class="BigImageInsideTable mr-32px" :src="require('@/assets/images/credit-card/1.jpeg')" />
                <div class="d-flex flex-column">
                  <div class="td-heading">Test</div>
                  <div class="td-text mb-8px">In this example from GMail, they launched a tour that announced two new features...</div>
                  <div class="td-text mb-8px">London</div>
                  <div class="td-text mb-8px">Ticket limit: 50</div>
                  <div class="td-text mb-8px">Used tickets: 15</div>
                </div>
              </div>
            </td>
            <td class="dialer-row-title two">
              27/07/2022
            </td>
            <td class="dialer-row-title three">
              15
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
import { ctkDateTimePicker } from '@/mixin';
export default {
  name: 'EmployeeTrainingEvents',
  mixins: [ctkDateTimePicker],
  data(){
    return {
      start_time: '',
      end_time: ''
    }
  }
}
</script>

<style>

</style>