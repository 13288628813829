export default [
	{
		id: 1,
		name: "Jackson Will",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 2,
		name: "Jackson Will",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 3,
		name: "Jackson Will",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 4,
		name: "Jackson Will",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 5,
		name: "Jackson Will",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 6,
		name: "Jackson Will",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 7,
		name: "Jackson Will",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 8,
		name: "Jackson Will",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 9,
		name: "Jackson Will",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 10,
		name: "William Jack",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 11,
		name: "Jackson Will",
		holiday: "20 days",
		sickday: "20 days",
		holidayUk: "-",
		holidayUsa: "-",
		holidayPak: '20 days',
		businessTrip: '20 days',
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
]