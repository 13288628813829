var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup global-modal-hrm Scrollable-hrm",
    attrs: {
      "name": "HRMAddDocumentModal",
      "clickToClose": true,
      "scrollable": true
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-hrm-modal-header mar-top-hrm20"
  }, [_c('h2', {
    staticClass: "dialer-modal-hrm-title"
  }, [_vm._v("Add document")]), _c('div', [_c('button', {
    staticClass: "dialer-hrm-button-modal",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('HRMAddDocumentModal');
      }
    }
  }, [_vm._v("Close")])])]), _c('div', {
    staticClass: "bg-modal-doc-grey mar-top-hrm30 sp-gm-if"
  }, [_c('div', {
    staticClass: "upload-img-doc"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "89.921",
      "height": "65.389",
      "viewBox": "0 0 89.921 65.389"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_55915",
      "data-name": "Path 55915",
      "d": "M89.921,45.666a56.31,56.31,0,0,1-1.409,5.67c-2.933,8.214-10.987,14.182-19.7,14.051-5.559-.084-11.121-.016-16.681-.016H51.021v-19.5h5.1c1.134,0,2.347.188,2.993-1.107.623-1.249-.207-2.108-.853-3Q52.586,33.9,46.9,26.049c-1.28-1.769-2.626-1.769-3.9,0Q37.162,34.116,31.33,42.191a7.4,7.4,0,0,0-.442.653,1.93,1.93,0,0,0,1.647,3.011c1.784.054,3.57.017,5.355.02h1v19.5H37.861c-6.38,0-12.76-.032-19.14.009-6.336.041-11.2-2.754-14.87-7.731A18.7,18.7,0,0,1,.2,43.575,17.9,17.9,0,0,1,7.7,31.179a6,6,0,0,1,.73-.486.8.8,0,0,0,.437-1.126A12.428,12.428,0,0,1,21.686,13.56,30.7,30.7,0,0,1,25,14.139c.755-1.209,1.458-2.561,2.368-3.756C33.452,2.393,41.508-1.148,51.471.327a24.5,24.5,0,0,1,20.58,19.954,3,3,0,0,0,2.5,2.788c8.782,2.827,13.679,9.052,15.186,18.064.047.284.122.563.183.844Z",
      "transform": "translate(0 0)",
      "fill": "#5576d1"
    }
  })])]), _c('div', {
    staticClass: "upload-btn-doc"
  }, [_c('button', {
    staticClass: "btn-activity"
  }, [_vm._v(" Upload File")])])]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Description (optional)")]), _c('b-form-textarea', {
    staticClass: "gm-hrm-textnote",
    attrs: {
      "id": "textarea-small",
      "size": "sm",
      "placeholder": "Small textarea"
    }
  })], 1), _c('div', {
    staticClass: "custom-if-doc-file-upload sp-gm-if-doc-modal"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_c('img', {
    staticClass: "doc-modal-img",
    attrs: {
      "src": require("../../../assets/images/credit-card/Group 18124.png"),
      "alt": "logo"
    }
  }), _c('p', {
    staticClass: "mb-imp gm-hrm-prezi"
  }, [_vm._v("Presentation.pdf")])]), _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.984",
      "height": "23.123",
      "viewBox": "0 0 17.984 23.123"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_material-delete",
      "data-name": "Icon material-delete",
      "d": "M8.785,25.054a2.577,2.577,0,0,0,2.569,2.569H21.631A2.577,2.577,0,0,0,24.2,25.054V9.638H8.785Zm16.7-19.269h-4.5L19.7,4.5H13.281L12,5.785H7.5V8.354H25.484Z",
      "transform": "translate(-7.5 -4.5)",
      "fill": "#c7384f"
    }
  })])]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Category")]), _c('div', {
    staticClass: "dd-gm-container"
  }, [_c('b-dropdown', {
    staticClass: "gm-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "gm-dropi-contenti"
        }, [_vm._v("Category")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("A")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("B")])], 1)], 1)]), _c('div', {
    staticClass: "bg-modal-doc-grey-share-with sp-gm-if"
  }, [_c('h5', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Share with")]), _c('b-form-group', {
    staticClass: "gm-hrm-custom-radio"
  }, [_c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "A"
    }
  }, [_vm._v("Everyone")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "B"
    }
  }, [_vm._v("Sasha Banks")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "C"
    }
  }, [_vm._v("Jenifer")])], 1)], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('p', {
    staticClass: "gm-hrm-file-size-text"
  }, [_vm._v("Also share with:")]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Team manager")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Direct manager")])])], 1), _c('div', [_c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Ask to accept the document")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Notify employee by e-mail")])])], 1), _c('button', {
    staticClass: "btn-primarynewupdated1 mt-4 mar-bottom-hrm50"
  }, [_vm._v("Save")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }