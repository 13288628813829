<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.edit">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit IVR</div>
          <div class="plus-IOSIcon-container">
          
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">

            <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
              <b-alert class="mt-32px" :show="!!api.update_label.error_message" variant="danger">{{api.update_label.error_message}}</b-alert>
              <div class="basicWhiteIOScard mt-32px mb-16px">
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Name</label>
                    <input 
                      placeholder="Enter Name" 
                      class="w-100" 
                      type="text" 
                      :disabled="api.update_label.send" 
                      v-model="forms.update_label.label" 
                      @input="updateLabel"
                      :maxlength="$v.forms.update_label.label.$params.maxLength.max"
                    />
                    <p 
                      v-if="(forms.update_label.submitted && $v.forms.update_label.label.$invalid) || api.update_label.validation_errors.label" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_label.label.required">* Name is required</span>
                      <span 
                        v-else-if="!$v.forms.update_label.label.maxLength">
                        * Name can be maximum {{ $v.forms.update_label.label.$params.maxLength.max }} character
                      </span>
                      <span v-for="em in api.update_label.validation_errors.label" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </form>

            

          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit IVR</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="profile-image-section-container forIos mt-32px">
              <div 
                class="profile-image-section"
                @click="$modal.show('IVRProfileImageUploader',{ 
                  type: 'IVR', 
                  accountcode: accountcode, 
                  current_image: ivrImage
                })"
              >
                <div class="profile-img">
                  <img 
                    style="height: 100%; width: 100%" 
                    id="user-profile-image-updated" 
                    :src="ivrImage" 
                    @error="$event.target.src=local_filess.images.user_male"
                  />
                </div>
              </div>
            </div>
            <div class="BiggestMainHeading mt-22px">{{ ivrLabel }}</div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              <OutOfHoursWidget class="withBottomBorder" :accountcode="accountcode" />
              <div class="basicWhiteIOScard-item" @click="$modal.show('EditIVRAssignNumberModal', { accountcode: accountcode, })">
                <div class="headingInsideTable">IVR Numbers</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-8px">{{ ivr | get_property('userDid.length',0) }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div class="basicWhiteIOScard-item" @click="$modal.show('WelcomeGreeting', { accountcode })">
                <div class="headingInsideTable">Welcome Greeting</div>
                <div class="d-flex align-items-center">
                  <vb-audio-player class="IosAudioPlayer mr-8px" :src="ivr | welcomgreetingsrc" />
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
            </div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item" @click="$modal.show('MenuOptions',{ accountcode })">
                <div class="headingInsideTable">Menu Options</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard onRightSide textAlignRight mr-8px">{{ ivr | get_property('schedules[0].options.length',0) }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div class="basicWhiteIOScard-item" @click="$modal.show('AdvanceOptions',{ accountcode })">
                <div class="headingInsideTable">Advance Options</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>

          </div>
        </div>
      </template>
      <ProfileImageUploaderModal :modalName="'IVRProfileImageUploader'" @image-uploaded="$emit('update-list')" />
      <AssignNumbersModal :modalName="'EditIVRAssignNumberModal'" @added="$emit('update-list');" />
      <AdvanceOptionsModal />
      <WelcomeGreetingModal />
      <MenuOptionsModal @update-list="$emit('update-list')" />
    </modal>
  </div>
</template>

<script>
import OutOfHoursWidget from '../../widgets/OutOfHours.vue';
import AssignNumbersModal from '../numbers/AssignNumbers.vue';
import ProfileImageUploaderModal from '../common/ProfileImageUploader.vue';
import AdvanceOptionsModal from './AdvanceOptions.vue';
import MenuOptionsModal from './MenuOptions.vue';
import WelcomeGreetingModal from './WelcomeGreeting.vue';
import { required, maxLength } from "vuelidate/lib/validators";
import _ from "lodash";
import { VOIP_API } from "@/utils";
import { mapGetters } from 'vuex';
import { welcomgreetingsrc } from '@/filter';
let screens = {
  edit: 'edit',
  view: 'view',
}
export default {
  name: "EditIVRModal",
  inject: ['local_filess','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'EditIVR'
    },
  },
  components: {
    OutOfHoursWidget,
    AssignNumbersModal,
    ProfileImageUploaderModal,
    AdvanceOptionsModal,
    MenuOptionsModal,
    WelcomeGreetingModal,
  },
  data() {
    return {
      forms: {
        update_label: this.$helperFunction.formInstance({
          data: {
            label: ''
          }
        }),
      },
      api: {
        update_label: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true }),
        delete_ivr: this.$helperFunction.apiInstance({  }),
      },
      data: {
        accountcode: '',
      },
      conditions: {
        screen: screens.view
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVoipIVRS',
    ]),
    screens(){ return screens },
    ivr(){ return this.getVoipIVRS?.[this?.data?.accountcode] ?? {} },
    ivrImage(){ return this.ivr?.avatar?.avatar?.image ?? '' },
    ivrLabel(){ return this.ivr?.label ?? '' },
    accountcode(){ return this.ivr?.voipaccount ?? '' },
  },
  validations: {
    forms: {
      update_label: {
        label: {
          required,
          maxLength: maxLength(30),
        },
      },
    },
  },
  filters: {
    welcomgreetingsrc,
  },
  methods: {
    onBeforeOpen(event) {
      this.data.accountcode=event?.params?.accountcode
      this.$nextTick()
      .then(()=>{
        this.forms.update_label.label=this.ivrLabel
      })
    },
    onBeforeClose(){
      this.data.accountcode=''
      this.conditions.screen=screens.view
      this.forms.update_label.reset()
      this.api.update_label.reset()
      this.api.delete_ivr.reset()
    },
    updateLabel: _.debounce(function(){
      let vm = this
      vm.forms.update_label.submitted=true
      vm.$v.forms.update_label.$touch();
      if(vm.api.update_label.send || vm.$v.forms.update_label.$invalid) return;
      vm.api.update_label.send=true
      VOIP_API.endpoints.menu.labelUpdate(vm.accountcode,{
        label: vm.forms.update_label.label
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully updated!",
          type: "success",
        })
        vm.$emit('update-list')
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.forms.update_label.submitted = false;
        vm.api.update_label.send = false;
      });
    },2*1000),
    deleteIVR() {
      let vm = this
      if(vm.api.delete_ivr.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this?`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete_ivr.send=true
            VOIP_API.endpoints.menu.delete(vm.accountcode)
            .then(() => {
              vm.appNotify({
                message: 'Successsfully Deleted',
                type: 'success',
              })
              vm.$modal.hide(vm.modalName);
              vm.$emit("update-list");
            })
            .catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.delete_ivr.send=false
            });
          }
        },
      });
    },
  },
};
</script>


<style lang="scss" scoped>
.profile-image-section {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto;
  overflow: hidden;
  @include border-radius(50%);
  margin-bottom: 3 * $dialer-default-padding;
  .loader-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .profile-img-upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: none;
    justify-content: center;
    .upload-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      opacity: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(50%);
    }
  }
  &:hover {
    .profile-img-upload-btn {
      display: block;
      cursor: pointer;
    }
  }
}
</style>
