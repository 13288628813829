<template>
  <div class="latestSMS-conversation-row" :class="`${received?'received':'sent'}`">
    <div class="latestSMS-conversation-column">
      <div class="latestSMS-conversation-infoPart">
        <div v-if="received" class="infoPart-number">{{ message.to_number | number_formater }}</div>
        <div v-else class="infoPart-number">{{ message.from_number | number_formater }}</div>
        <div class="infoPart-time">{{ message.date | filter_date_current(false,{ formate: 'hh:mm a' }) }}</div>
      </div>
      <div class="latestSMS-conversation-messagePart">{{ message.message }}</div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'SMSMessage',
  props: {
    otherNumber: {
      type: String,
      default: ''
    },
    message: {
      type: Object,
      default: ()=>({})
    },
  },
  computed: {
    received(){ return this.otherNumber==this.message.from_number },
  },
}
</script>

<style>

</style>