
import { VOIP_API } from "../index"

export const MUSIC_ON_HOLD_API_ENDPOINTS = {
  systemplaylist(params={}){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: 'get-default-moh',
      params,
    })
  },
  unsetplaylist(data={}){
    return VOIP_API.axios.voip.request({
      method: 'PATCH',
      url: 'musiconholds',
      data,
    })
  },
  list(params={}) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: 'musiconholds',
      params,
    })
  },
  changeLabel(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `rename-musiconholds/${id}`,
      method: 'PATCH',
      data,
    })
  },
  create(data={},params={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'musiconholds',
      data,
      params,
    })
  },
  playlistfiles(id,params={},token) {
    return VOIP_API.axios.voip.request({
      url: `playlist-files/${id}`,
      method: 'GET',
      params,
      CancelToken: token,
    })
  },
  playlistaddfile(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'musiconholds/addfile',
      data,
    })
  },
  playlistremovefile(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'musiconholds/removefile',
      data,
    })
  },
  updateplaylist(id,data={}) {
    return VOIP_API.axios.voip.request({
      method: 'patch',
      url: `musiconholds/${id}`,
      data,
    })
  },
  updateplaylistorder(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'change-playlist-order-app',
      data,
    })
  },
  delete(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'delete',
      url: 'musiconholds',
      data,
    })
  }
}