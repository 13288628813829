<template>
  <div>
    <div class="mainHeading">Time off overview</div>
    <div class="mainDescription mb-4">
      <br/>
    </div>
    <div class="dropdownsContainer">
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All teams</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All teams
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All locations</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All locations
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All positions</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All positions
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="grayCard withHeader">
      <div class="withHeader-head">
        Heatmap
        <b-dropdown no-caret class="primaryBorderDropdown mr-0">
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText">Sickday</div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item >
            Sickday
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="withHeader-body">
        <div class="popularDay-container">
          <div class="popularDay-inner">
            <div class="popularDay">Mo</div>
            <div class="popularDay">Tu</div>
            <div class="popularDay">We</div>
            <div class="popularDay">Th</div>
            <div class="popularDay">Fr</div>
            <div class="popularDay active">Sa</div>
            <div class="popularDay">Su</div>
          </div>
          <div class="popularDay-headingText">
            Most popular day: Saturday
          </div>
        </div>
      </div>
    </div>
    <div class="grayCard withHeader mt-32px">
      <div class="withHeader-head">
        Average % away
        <b-dropdown no-caret class="primaryBorderDropdown mr-0">
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText">Sickday</div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item >
            Sickday
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="withHeader-body">
        <div class="my-4">0%</div>
      </div>
    </div>
    <div class="d-flex mt-32px">
      <div class="grayCard withHeader heavyBorder halfWidth">
        <div class="withHeader-head">
          Most days away
        </div>
        <div class="withHeader-body">
          <div class="withHeader-bodyRow">
            <div class="nameWithImage">
              <div class="imageContainer">
                <img class="circle" :src="require('@/assets/images/credit-card/1.jpeg')" />
              </div>
              <div class="nameWithImage-text">Marie jane</div>
            </div>
            <div class="bodyRow-text">1 days</div>
          </div>
          <div class="withHeader-bodyRow">
            <div class="nameWithImage">
              <div class="imageContainer">
                <img class="circle" :src="require('@/assets/images/credit-card/2.jpeg')" />
              </div>
              <div class="nameWithImage-text">Marie jane</div>
            </div>
            <div class="bodyRow-text">1 days</div>
          </div>
          <div class="withHeader-bodyRow">
            <div class="nameWithImage">
              <div class="imageContainer">
                <img class="circle" :src="require('@/assets/images/credit-card/3.jpeg')" />
              </div>
              <div class="nameWithImage-text">Marie jane</div>
            </div>
            <div class="bodyRow-text">1 days</div>
          </div>
        </div>
      </div>
      <div class="grayCard withHeader heavyBorder halfWidth">
        <div class="withHeader-head">
          Most days away
        </div>
        <div class="withHeader-body">
          <div class="withHeader-bodyRow">
            <div class="circleWithText">
              <div class="circleContainer" style="background-color:#D5761B"></div>
              <div class="circleWithText-text" style="color:#D5761B">Sickday</div>
            </div>
            <div class="bodyRow-text">1 x</div>
          </div>
          <div class="withHeader-bodyRow">
            <div class="circleWithText">
              <div class="circleContainer" style="background-color:#5576D1"></div>
              <div class="circleWithText-text" style="color:#5576D1">Holiday - UK</div>
            </div>
            <div class="bodyRow-text">1 x</div>
          </div>
          <div class="withHeader-bodyRow">
            <div class="circleWithText">
              <div class="circleContainer" style="background-color:#1A9211"></div>
              <div class="circleWithText-text" style="color:#1A9211">Holiday - NL</div>
            </div>
            <div class="bodyRow-text">1 x</div>
          </div>
          <div class="withHeader-bodyRow">
            <div class="circleWithText">
              <div class="circleContainer" style="background-color:#D2A657"></div>
              <div class="circleWithText-text" style="color:#D2A657">Business trip</div>
            </div>
            <div class="bodyRow-text">1 x</div>
          </div>
          <div class="withHeader-bodyRow">
            <div class="circleWithText">
              <div class="circleContainer" style="background-color:#5576D1"></div>
              <div class="circleWithText-text" style="color:#5576D1">Holiday - Pak</div>
            </div>
            <div class="bodyRow-text">1 x</div>
          </div>
          <div class="withHeader-bodyRow">
            <div class="circleWithText">
              <div class="circleContainer" style="background-color:#7B67FF"></div>
              <div class="circleWithText-text" style="color:#7B67FF">Conference</div>
            </div>
            <div class="bodyRow-text">1 x</div>
          </div>
          <div class="withHeader-bodyRow">
            <div class="circleWithText">
              <div class="circleContainer" style="background-color:#C7384F"></div>
              <div class="circleWithText-text" style="color:#C7384F">Work from home (UK)</div>
            </div>
            <div class="bodyRow-text">1 x</div>
          </div>
          <div class="withHeader-bodyRow">
            <div class="circleWithText">
              <div class="circleContainer" style="background-color:#22B100"></div>
              <div class="circleWithText-text" style="color:#22B100">Holiday USA</div>
            </div>
            <div class="bodyRow-text">1 x</div>
          </div>
          <div class="withHeader-bodyRow">
            <div class="circleWithText">
              <div class="circleContainer" style="background-color:#5576D1"></div>
              <div class="circleWithText-text" style="color:#5576D1">Holiday</div>
            </div>
            <div class="bodyRow-text">1 x</div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeOffOverview',
}
</script>

<style>

</style>