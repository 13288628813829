<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-users-setting">
      <div class="dialer-flex">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Customer Logs</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title">Customer Logs</h2>
      </div>
      <div class="users-settings-2nd-section d-flex align-items-center justify-content-between md-mar-top md-mar-bottom">
        <span>Here you can instantly add, remove and manage your Voip business users.</span>
        <div class="d-flex justify-content-end align-items-center w-50">
          <div class=" whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
            <b-input class="w-100" type="text" placeholder="Search log" />
          </div>
        </div>
      </div>
      <vb-table class="mt-2" :isListEmpty="isEmpty([])" :listLength="[].length"  :perPage="5" :loading="false" textNoRecord=" ">
        
        <template slot="header">
          <tr>
            <th class="dialer-has-sort">
              <span>LOG</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr class="dialer-row-select" v-for="(user, index) in []" :key="user.voipaccount" v-show="index >= start && index <= end">
            <td class="dialer-row-title">a</td>
          </tr>
        </template>
      </vb-table>
    </section>
  </div>
</template>

<script>
export default {
  name: "CustomerLogs",
  inject:['isEmpty', 'getProperty'],
};
</script>