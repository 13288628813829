<template>
<div class="panel-wide pl-4 d-flex align-items-center justify-content-center " style="border-radius: 24px;min-height:calc(100% - 71px);">
  <!-- <div class="welcome-box ml-auto mr-auto text-center w-100">
    
    <h1 class="mb-5">Welcome, {{ $store.getters.getCurrentUser.display_name }}</h1>
    <div class="position-relative d-inline-block">
      <div class="user-avatar rounded-circle overflow-hidden ml-auto mr-auto mb-5">
        
        <img class="fit" :src="$store.getters.getCurrentUser.profileImg" :alt="$store.getters.getCurrentUser.display_name" />
        
      </div>
    </div>
  </div> -->
  <div class="latestGreyBox-9-9-2023 mt-32px mr-4">
    <div class="emptyScreenContainer">
      <img class="mt-12px wd-30 h-auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/chat.png')"/>
      <img class="mt-12px wd-30 h-auto" v-else :src="require('@/assets/images/emptyScreenImages/chat.png')"/>
      <div class="emptyScreenContainer-heading">
        Let's start a conversation
      </div>
      <div class="emptyScreenContainer-text w-75">
        Gear up for collaboration! Kickstart the conversation by dropping 
        a message to your teammate or rally the crew by creating 
        a group to dive into discussions.
      </div>
      <button class="newButton mt-20px">
        <vb-icon icon="squared-chat-icon" height="38px" width="38px" />
        <span class="newButton-textPart">Start chatting</span>
      </button>
    </div>
  </div>
</div>
</template>
<script>
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import { mapGetters } from 'vuex';
export default {
  name: "WelcomePanel",
  components: {
    
  },
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
  },
};
</script>