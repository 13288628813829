<template>
  <div class="HrmCalender-container">
    <div >
      <div class="dropdownsContainer">
        <b-form-select class="primaryBorderDropdown" v-model="selectedTeam" :options="filteredTeams" style="width: 250px"></b-form-select>
        <b-form-select class="primaryBorderDropdown" v-model="selectedYear" :options="filteredYears" style="width: 250px"></b-form-select>
      </div>
     
    </div>
    <div  class="userInfo-withCalender-container mt-5">
      <div class="dayBar">
        <div class="dayBar-inner">
          <div :class="`dayName currentDay ${day.initial == 'Su' || day.initial == 'Sa' ? 'offDay' : ''}`" v-for="day in calendarData.days" :key="day.id">
            {{ day.initial }}
          </div>
        </div>
      </div>
      <div class="dayBar withDate" v-for="item in calendarData.items" :key="item.id">
        <div class="userInfo-container" v-if="item.type === 'employee'">
          <div class="userInfo-container-inner">
            <div class="userInfo-img">
              <img :src="item.employee.thumb_url" alt="logo" />
            </div>
            <div class="userInfo">
              <div class="userInfo-heading">{{ item.employee.full_name }}</div>
            </div>
          </div>
        </div>
        <div class="dayBar-inner" v-if="item.type === 'employee'">
          <div 
            class="dayName" 
            :class="{
              selectedDate: item.employee.events.hasOwnProperty(day.id),
              offDay: day.weekend,
            }" 
            v-for="day in calendarData.days" 
            :key="day.id"
          >
            <div 
              class="dateItself"
              :class="item.employee.events[day.id] | filterEventsClasses" 
              :style="`background: ${getProperty(item,'employee.events[day.id][0].policy_color')};`"
              v-b-popover.hover.focus.top="{
                title: getProperty(item,'employee.events[day.id][0].policy_name'),
                content: getProperty(item,'employee.events[day.id][0].details'),
                disabled: !item.employee.events.hasOwnProperty(day.id)
              }"
            >
              {{ day.day_of_month }}
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
    
<script>
  // import addTimeOffRequestModa from '../../modals/addTimeOffRequestModal.vue';
  const moment = require('moment');
  export default {
    props: {
      mode: {
        type: String,
        default: "week"
      },
      startDate: {
        type: String,
        default: moment().format('YYYY-MM-DD')
      },
      data: {
        type: Object
      }
    },
    components: {
      // addTimeOffRequestModa
    },
    inject: ['getProperty'],
    data(){
      return {
        dates: [],
        filtered: "all",
        selectedTeam:'all',
        selectedYear: this.data.filters.selected
      }
    },
    computed: {
      filteredTeams(){
        let teams = []
        teams.push({
          value: "all",
          text: "All Teams"
        })
        this.data.items.forEach(item => {
          if (item.type === "team"){
            teams.push({
              value: item.team_id,
              text: item.team.name
            })
          }
        })
        return teams
      },
      calendarData(){
        let selectedTeam = this.selectedTeam
        if( selectedTeam !== 'all'){
          return {
            ...this.data,
            items:this.data.items.filter(item => item.team_id === selectedTeam)
          }
        }
        return this.data
      },
      filteredYears(){
        // let selectedYear = this.data.filters.selected
        // this.selectedYear = selectedYear
        return this.data.filters.options.map(item => {
          return {
            value: item.id,
            text: item.text
          }
        })
      }
    },
    watch:{
      startDate: function (/*newValue, oldValue*/){
        // console.log("newValue", newValue, "oldValue", oldValue);
        let vm = this;
        if(vm.mode === "week"){
          const after4Weekday = moment(vm.startDate).add(4, 'weeks').format('YYYY-MM-DD')
          // console.log('next 4 week', vm.getDates(vm.startDate, after4Weekday));
          vm.dates = vm.getDates(vm.startDate, after4Weekday)
        } else {
          const dateList = vm.getDaysOfMonth(vm.startDate)
          // console.log('month',vm.getDates(dateList.start,dateList.end));
          vm.dates = vm.getDates(dateList.start,dateList.end)
        }
      }
    },
    filters: {
      getDay: function (value) {
        if (!value) return ''
        value = value.toString()
        // console.log("Filter", value)
        var dt = moment(value, "YYYY-MM-DD HH:mm:ss")
        // console.log("day name", dt.format('dddd'))
        return dt.format('dddd').substring(0,3)
      },
      filterEvents(value){
        // console.log("value", value);
        if( value[0].is_first_day && value[0].is_last_day ){
          return `background: ${value[0].policy_color};`
        } else if( value[0].is_first_day &&  !value[0].is_last_day ){
          return `background: ${value[0].policy_color}; border-top-left-radius: 20px; border-bottom-left-radius: 20px;`
        } else if( !value[0].is_first_day &&  value[0].is_last_day ){
          return `background: ${value[0].policy_color}; border-top-right-radius: 20px; border-bottom-right-radius: 20px;`
        } else if( !value[0].is_first_day &&  !value[0].is_last_day ){
          return `background: ${value[0].policy_color};`
        }
      },
      filterEventsClasses(value){
        const is_first_day = value?.[0]?.is_first_day
        const is_last_day = value?.[0]?.is_last_day
        if(!value) return ''
        else if(is_first_day==null && is_last_day==null) return ''
        else if(is_first_day && is_last_day) return `single`
        else if(is_first_day && !is_last_day) return `first`
        else if(!is_first_day && is_last_day) return `last`
        else if(!is_first_day && !is_last_day) return `middle`
      },
      filterWeekEnds(value){
        // console.log("weekends", value);
        if( value.weekend ){
          return `background: #c08df6;`
        }
        return ''
      },
      filterWeekEndsClasses(value){
        if( value.weekend ){
          return `offDay`
        }
        return ''
      }
    },
    methods: {
      getDates(startDate, stopDate) {
      var dateArray = [];
      var currentDate = moment(startDate);
      var stopdate = moment(stopDate);
      while (currentDate <= stopdate) {
        dateArray.push( moment(currentDate).format('YYYY-MM-DD') )
        currentDate = moment(currentDate).add(1, 'days');
      }
      return dateArray;
      },
      getDaysOfMonth(startDate) {
        const date = moment(startDate)
        const month = date.month()+1
        const year = date.year()
        const daysInMonth = date.daysInMonth()
        return {
          start: moment(`${year}-${month}-01`).format('YYYY-MM-DD'),
          end: moment(`${year}-${month}-${daysInMonth}`).format('YYYY-MM-DD')
        }
      }
    },
    mounted(){
      let vm = this;
      // console.log("mounted")
      if(vm.mode === "week"){
        const after4Weekday = moment(vm.startDate).add(4, 'weeks').format('YYYY-MM-DD')
        // console.log('next 4 week', vm.getDates(vm.startDate, after4Weekday));
        vm.dates = vm.getDates(vm.startDate, after4Weekday)
      }
      
    }
  }
</script>
    
  <style>
    /* * {
      margin: 0;
      padding: 0;
      box-sizing: border-box;    
    } */
    /* .container {
      max-width: 1200px;
      min-width: 650px;
      margin: 0 auto;
      padding: 50px;      
    } */
    
    .chart {
      display: grid;
      /* border: 2px solid #000; */
      position: relative;
      overflow: hidden;  
    }
    .chart-row {
      display: grid;      
      grid-template-columns: 250px 1fr;
      background-color: #DCDCDC;
    }
    .chart-row:nth-child(odd) {
      background-color: #ffffff;
    }
    .chart-period {
      color:  #291b1b;
      background-color:  #ffffff !important;
      /* border-bottom: 2px solid #000;  */
    }
    .chart-lines {
      position: absolute;
      height: 100%;
      width: 100%;
      background-color: transparent;
      grid-template-columns: 50px repeat(1fr);
    }
    .chart-period > span {    
      text-align: center;
      font-size: 13px;
      align-self: center;
      font-weight: bold;
      padding: 15px 0;    
    }
    .chart-lines > span {
      display: block;
      /* border-right: 1px solid rgba(0, 0, 0, 0.3); */
  
    }
    
    .chart-row-item {
      display: flex;
      background-color:#ffffff;
      /* border: 1px solid  #000; */
      border-top: 0;
      border-left: 0;      
      padding: 20px 0;
      font-size: 15px;
      font-weight: bold;
      text-align: center;
    } 
  
    .chart-row-bars {
      list-style: none;
      display: grid;
      /* padding: 15px 0; */
      margin: 0;
      grid-template-columns: repeat(28, 1fr);
      grid-gap: 10px 0;
      /* border-bottom: 1px solid  #000; */
    }
    li.chart-li-one {
      font-weight: 450;
      text-align: left;
      font-size: 15px;
      min-height: 15px;
      /* background-color: #708090; */
      padding: 5px 15px;
      color: #0d0d0e;
      /* overflow: hidden;
      position: relative; */
      cursor: pointer;
      /* border-radius: 15px; */
    }
  
  </style>