
import { VOIP_API } from "../index"

export const TRUSTED_DEVICES_API_ENDPOINTS = {
  list(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'relay',
        action: 'trusted_devices',
      },
    })
  },
  delete(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'relay',
        action: 'delete_trust_device',
      },
    })
  },
}