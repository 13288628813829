var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup FeedbackModal m-w-500 min-width-50percent-modal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose,
      "closed": _vm.onClosed
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title newer"
  }, [_vm._v("Feedback")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "disabled": _vm.api.feedback.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.feedback.error_message,
      "variant": "danger"
    }
  }, [_vm._v(" " + _vm._s(_vm.api.feedback.error_message) + " ")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("We won't reply to your message.")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Don't include any personal or financial information.")]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-8px"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.feedback.value,
      expression: "forms.feedback.value"
    }],
    staticClass: "w-100 feedback-textarea",
    attrs: {
      "disabled": _vm.api.feedback.send,
      "maxlength": "500"
    },
    domProps: {
      "value": _vm.forms.feedback.value
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.feedback, "value", $event.target.value);
      }
    }
  })]), _vm.forms.feedback.submitted ? [_vm.$v.forms.feedback.value.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text-danger animated bounceIntop"
  }, [!_vm.$v.forms.feedback.value.required ? _c('span', [_vm._v("* Feedback is required")]) : !_vm.$v.forms.feedback.value.maxLength ? _c('span', [_vm._v("* Feedback can be maximum 500 character")]) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.api.feedback.error.errors ? [_vm.api.feedback.error.errors.feedback ? _c('p', {
    staticClass: "mb-1 text-small text-danger animated bounceIntop"
  }, _vm._l(_vm.api.feedback.error.errors.feedback, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(_vm._s(500 - _vm.forms.feedback.value.length) + " characters remaining")]), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center mb-3"
  }, [_c('b-icon', {
    attrs: {
      "icon": "caret-right-fill",
      "variant": "black"
    }
  }), _c('p', {
    staticClass: "ml-2 mb-0 text-underline latestGreyBox-descriptionText"
  }, [_vm._v("I need help with my application")])], 1)], 2), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.feedback.send
    },
    on: {
      "click": function ($event) {
        return _vm.sendfeedback();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.feedback.send ? _c('vb-spinner') : [_vm._v("Submit feedback")]], 2)], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }