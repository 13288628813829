<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-700 updateCaller-idModal AddMemberInPickupGroupsModal IosModal" 
      width="500px" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @closed="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select User</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-input type="text" placeholder="Search Users" :disabled="api.update.send" v-model="filter.user.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item" v-for="data in filterUsers" 
            :key="data.accountcode" 
            @click="
              api.update.send ? '' :
              selected.extension.includes(data.accountcode) ? 
              selected.extension.splice(selected.extension.indexOf(data.accountcode),1) 
              : 
              selected.extension.push(data.accountcode)
            " 
            :class="{
              'IosActive': selected.extension.includes(data.accountcode)
            }"
          >
            
            <div class="d-flex mr-6px align-items-center" >
              <div class="">
                <Info class="InfoComponentInsideAnroidIOSApp" :id="data.accountcode" :name="data.label" :sub_info="data.extn" />
              </div>
            </div>
            <vb-icon v-if="selected.extension.includes(data.accountcode)" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
          </div>
          <vb-no-record v-if="!filterUsers.length" :text="api.members.send ? '' : 'Sorry, No Data Found'" :design="3">
            <!-- <vb-loading class="my-2" :type="'loader'" v-if="api.members.send" slot="loading" /> -->
            <vb-loading class="my-2" v-if="api.members.send" slot="loading" />
          </vb-no-record>
        </div>
        <b-button class="IosFullWidthButton forDone mb-16px" :disabled="api.update.send" @click="updatemembers()">
          <vb-spinner v-if="api.update.send" />
          <template v-else>Done</template>
        </b-button>

        
      
        
        
        

        

      </div>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { VOIP_API } from '@/utils/index';
import Info from '../../../Lists/Info.vue';
export default {
  name: "SelectUserExtensionModal",
  components: { 
    Info,
  },
  props: {
    modalName: {
      type: String,
      default: 'SelectUserExtensionModal',
    },
  },
  data() {
    return {
      response: {
        members: {
          available: {},
          selected: {},
        },
      },
      api: {
        update: this.$helperFunction.apiInstance({  }),
        members: this.$helperFunction.apiInstance({  }),
      },
      filter: {
        user: {
          search: '',
        },
      },
      selected: {
        extension: {},
      },
      data: {
        pickupgroup_id: '',
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    filterUsers(){
      const search = this.filter.user.search.trim().toLowerCase()
      const array = _.concat(Object.values(this.response.members.available),Object.values(this.response.members.selected))
      return array.filter(item=>`${item.label} ${item.extn}`.trim().toLowerCase().includes(search) && item.type=='user')
    },
    pickupGroupId(){ return this.data.pickupgroup_id },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.pickupgroup_id = event?.params?.pickupgroup_id || ''
      this.fetchMembers()
    },
    onBeforeClose() {
      this.data.pickupgroup_id=''
      this.filter.user.search=''
      this.response.members.available={}
      this.response.members.selected={}
      this.selected.extension=[]
      this.selected.tab='user'
    },
    fetchMembers(){
      let vm = this
      if (vm.api.members.send) return;
      vm.api.members.send=true
      VOIP_API.endpoints.pickupgroup.members(vm.pickupGroupId)
      .then(({ data: { selected, available } })=>{
        vm.response.members.available = _.isEmpty(available) ? {} : available
        vm.response.members.selected = _.isEmpty(selected) ? {} : selected
        vm.selected.extension = Object.keys(vm.response.members.selected)
      })
      .finally(()=>{
        vm.api.members.send=false
      })
    },
    updatemembers(){
      let vm = this
      if(vm.api.update.send) return;
      vm.api.update.send=true
      VOIP_API.endpoints.pickupgroup.updatemembers(vm.pickupGroupId,{
        members: vm.selected.extension.join(',')
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        vm.$modal.hide(vm.modalName)
        vm.$emit('updated',vm.selected.extension)
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update.send=false
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.bill-active {
  background-color: #000fff;
}
.updateCaller-idModal {
  .custom-control-label {
    cursor: pointer;
    & > span {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }

    &::before,
    &::after {
      top: 9px;
    }
  }
}
</style>
