var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-area d-flex h-100 dialer_main_body"
  }, [_c('div', {
    staticClass: "dialer_main_body_inner w-100"
  }, [!_vm.GET_LOCAL_SETTING_MOBILE_SETTINGS.tab ? _c('div', {
    class: ['dialer_inner_left_side ', _vm.getIsMobile ? '' : 'd-r-768px-none']
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-icon', {
    staticClass: "cursor_pointer XiconForClosingSettingsInMobile",
    attrs: {
      "icon": "x",
      "font-scale": "2"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.tab = 'user_profile';
      }
    }
  }), _c('div', {
    staticClass: "first-section align-items-center"
  }, [_c('h2', [_vm._v("Settings")]), _c('UserProfile', {
    staticClass: "w-auto"
  })], 1)], 1), _c('div', {
    staticClass: "dialer_inner_left_side_inner allow-scroll mobileSettingsPanel"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.tabs.user, function (tab) {
    return _c('li', {
      key: tab.component,
      class: `dialer_tab_btn ${_vm.GET_LOCAL_SETTING_MOBILE_SETTINGS.tab == tab.component ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          return _vm.SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB(tab.component);
        }
      }
    }, [_c('a', {
      class: `text-capitalize ${_vm.getIsMobile ? 'formobileResponsive' : ''}`
    }, [_c('span', {
      staticClass: "dialer-settingPanel-icon"
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.m_icon
      }
    })], 1), _c('span', {
      staticClass: "dialer-settingPanel-text"
    }, [_vm._v(_vm._s(tab.label))]), _c('span', [_c('b-icon', {
      attrs: {
        "icon": "chevron-right"
      }
    })], 1)])]);
  }), 0), _vm.getCurrentUser.administrator_account ? [_c('h2', {
    staticClass: "dialer_tabs_title text-uppercase"
  }, [_vm._v("administrator")]), _c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.tabs.admin, function (tab) {
    return _c('li', {
      key: tab.component,
      class: `dialer_tab_btn ${_vm.GET_LOCAL_SETTING_MOBILE_SETTINGS.tab == tab.component ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          return _vm.SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB(tab.component);
        }
      }
    }, [_c('a', {
      class: `text-capitalize ${_vm.getIsMobile ? 'formobileResponsive' : ''}`
    }, [_c('span', {
      staticClass: "dialer-settingPanel-icon"
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.m_icon
      }
    })], 1), _c('span', {
      staticClass: "dialer-settingPanel-text"
    }, [_vm._v(_vm._s(tab.label))]), _c('span', [_c('b-icon', {
      attrs: {
        "icon": "chevron-right"
      }
    })], 1)])]);
  }), 0)] : _vm._e(), _vm.getCurrentUser.administrator_account ? [_c('h2', {
    staticClass: "dialer_main_title text-capitalize"
  }, [_vm._v("Analytics")]), _c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.tabs.analytics, function (tab) {
    return _c('li', {
      key: tab.component,
      class: `dialer_tab_btn ${_vm.GET_LOCAL_SETTING_MOBILE_SETTINGS.tab == tab.component ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          return _vm.SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB(tab.component);
        }
      }
    }, [_c('a', {
      staticClass: "text-capitalize formobileResponsive"
    }, [_c('span', {
      staticClass: "dialer-settingPanel-icon"
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.m_icon
      }
    })], 1), _c('span', {
      staticClass: "dialer-settingPanel-text"
    }, [_vm._v(_vm._s(tab.label))]), _c('span', [_c('b-icon', {
      attrs: {
        "icon": "chevron-right"
      }
    })], 1)])]);
  }), 0)] : _vm._e()], 2)]) : _vm._e(), _c('div', {
    staticClass: "dialer_inner_right_side"
  }, [_c('keep-alive', {
    attrs: {
      "include": _vm.keepAliveTabs
    }
  }, [_c(_vm.GET_LOCAL_SETTING_MOBILE_SETTINGS.tab, {
    tag: "component",
    on: {
      "my-numbers": function ($event) {
        return _vm.SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB(_vm.getCurrentUser.administrator_account ? 'NumbersSettings' : _vm.GET_LOCAL_SETTING_MOBILE_SETTINGS.tab);
      },
      "back": function ($event) {
        _vm.GET_LOCAL_SETTING_MOBILE_SETTINGS.tab = '';
      }
    }
  })], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }