var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-users-setting"
  }, [_c('div', {
    staticClass: "dialer-flex"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Customer Logs")])], 1)] : _c('h2', {
    staticClass: "dialer-settings-title"
  }, [_vm._v("Customer Logs")])], 2), _c('div', {
    staticClass: "users-settings-2nd-section d-flex align-items-center justify-content-between md-mar-top md-mar-bottom"
  }, [_c('span', [_vm._v("Here you can instantly add, remove and manage your Voip business users.")]), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center w-50"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox"
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Search log"
    }
  })], 1)])]), _c('vb-table', {
    staticClass: "mt-2",
    attrs: {
      "isListEmpty": _vm.isEmpty([]),
      "listLength": [].length,
      "perPage": 5,
      "loading": false,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l([], function (user, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: user.voipaccount,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v("a")])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("LOG")])])])])], 2)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }