<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Address</div>
        <div class="plus-IOSIcon-container" @click="$modal.show('AddLocationModal',{ flag: 'unpurchased', callerId: data.number })">
          <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992" />
        </div>
      </div>

      <div class="innerModalIos">
        <div class="lg-mar-bottom">
          
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Address type required</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard onRightSide textAlignRight">{{ data.address_required || 'None' }}</div>
              </div>
            </div>
          </div>

          <div class="basicWhiteIOScard mt-16px mb-16px" v-for="location in response.locations" :key="location.real_id" @click="selectLocation(location)">
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <vb-icon class="" icon="Locations-location-IOSIcon"/>
                </div>
                <div class="headingInsideTable">Use this Address</div>
              </div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Street</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard onRightSide textAlignRight">{{ location.street }}</div>
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Town</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard onRightSide textAlignRight">{{ location.town }}</div>
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Postcode</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard onRightSide textAlignRight">{{ location.postcode }}</div>
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Country</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard onRightSide textAlignRight">{{ location.country }}</div>
              </div>
            </div>
            <vb-no-record v-if="response.locations.length==0" :text="api.locations.send?'':'There is no Number'" :design="3">
              <vb-loading v-if="api.locations.send" slot="loading" />
            </vb-no-record>
          </div>
        </div>
        <AddLocationModal @latest-list="fetchLocations()" />
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from "@/utils";
import AddLocationModal from "./AddLocation.vue";
export default {
  name: "SelectLocationModal",
  components: {
    AddLocationModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'SelectLocation',
    },
  },
  data() {
    return {
      api: {
        locations: this.$helperFunction.apiInstance({  }),
      },
      response: {
        locations: [],
      },
      data: {
        number: '',
        address_required: '',
      },
    };
  },
  methods: {
    onBeforeOpen(event){
      this.data.number = event?.params?.number ?? ''
      this.data.address_required = event?.params?.address_required ?? ''
      this.fetchLocations()
    },
    onBeforeClose(){
      this.api.locations.reset()
      this.response.locations=[]
      this.data.number=''
      this.data.address_required=''
    },
    selectLocation(location){
      this.$emit("select", { location: location });
      this.$modal.hide(this.modalName);
    },
    fetchLocations() {
      let vm = this;
      vm.api.locations.send = true;
      VOIP_API.endpoints.location.list({
        flag: "numbers",
      })
      .then(({ data: response }) => {
        vm.response.locations = response.data;
      })
      .finally(() => {
        vm.api.locations.send = false;
      });
    },
  },
};
</script>
