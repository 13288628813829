var render = function render(){
  var _vm$playlist;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 MOHPlaylistFilesModal min-width-50percent-modal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to dialer-edit"
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center mb-2"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Playlist files")]), _vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1)]) : _c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "flex-fill d-flex align-items-center justify-content-between"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v(" Edit " + _vm._s((_vm$playlist = _vm.playlist) === null || _vm$playlist === void 0 ? void 0 : _vm$playlist.label) + " music in the playlist")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Choose the music audio file you'd like to include in this playlist. ")])]), _vm.api.playlist.send && !_vm.playlist ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row py-2"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "27px",
        "width": "calc(25% - 16px)"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "64px",
        "width": "calc(50% - 16px)"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "50px",
        "width": "calc(25% - 16px)"
      }
    })]);
  }), 0)])] : [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 addSoundFilesInMOHplaylistSection mt-20px"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filter.files.search,
      expression: "filter.files.search"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search",
      "type": "text"
    },
    domProps: {
      "value": _vm.filter.files.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filter.files, "search", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "d-flex w-50 justify-content-between align-items-center pl-3"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD withCustomSvgCheck"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-svg', {
          staticClass: "filterDD-icon",
          attrs: {
            "name": "filter-icon",
            "width": "19",
            "height": "18",
            "viewBox": "0 0 19 18",
            "stroke-width": "0",
            "stroke": "",
            "fill": "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.files.type = 'all';
      }
    }
  }, [_vm.filter.files.type == 'all' ? _c('b-icon', {
    attrs: {
      "icon": "check2",
      "scale": "2"
    }
  }) : _vm._e(), _c('span', [_vm._v("All")])], 1), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.files.type = 'system';
      }
    }
  }, [_vm.filter.files.type == 'system' ? _c('b-icon', {
    attrs: {
      "icon": "check2",
      "scale": "2"
    }
  }) : _vm._e(), _c('span', [_vm._v("System")])], 1), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.files.type = 'custom';
      }
    }
  }, [_vm.filter.files.type == 'custom' ? _c('b-icon', {
    attrs: {
      "icon": "check2",
      "scale": "2"
    }
  }) : _vm._e(), _c('span', [_vm._v("Custom")])], 1)], 1)], 1), _c('button', {
    staticClass: "newButton mwd-200px",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AddRecordingModal`, {
          group: 'moh',
          select_category: false
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Upload new music")])], 1)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm._l(_vm.files, function (file) {
    return _c('div', {
      key: file.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
    }, [_c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(_vm._f("check_empty")(file.label, 'None')))]), _c('div', {
      staticClass: "wd-33-imp d-flex align-items-center justify-content-between"
    }, [_c('vb-audio-player', {
      staticClass: "whiteBGaudioWithGreyRoundedBorder-again calc-100-32px-imp",
      attrs: {
        "src": file.sound_file
      }
    }), _c('div', {
      staticClass: "actLikeSquareRadio checkboxVersion ml-auto",
      class: {
        'checked': _vm.selected.files.includes(`${file.real_id}`)
      },
      attrs: {
        "disabled": _vm.api.remove_file.send || _vm.api.add_file.send
      },
      on: {
        "click": function ($event) {
          _vm.selected.files.includes(`${file.real_id}`) ? _vm.removeFile(file.id) : _vm.addFile(file.id);
        }
      }
    })], 1)]);
  }), _vm.files.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'No files',
      "design": 3
    }
  }) : _vm._e()], 2)])]], 2)]), _c('AddRecordingModal', {
    attrs: {
      "allow_recording": false,
      "modalName": `${_vm._uid}-AddRecordingModal`
    },
    on: {
      "update-list": function ($event) {
        return _vm.fetchPlaylists();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }