var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "panel-wide pl-4 d-flex align-items-center justify-content-center",
    staticStyle: {
      "border-radius": "24px",
      "min-height": "calc(100% - 71px)"
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-32px mr-4"
  }, [_c('div', {
    staticClass: "emptyScreenContainer"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    staticClass: "mt-12px wd-30 h-auto",
    attrs: {
      "src": require('@/assets/images/emptyScreenImages/darkMode/chat.png')
    }
  }) : _c('img', {
    staticClass: "mt-12px wd-30 h-auto",
    attrs: {
      "src": require('@/assets/images/emptyScreenImages/chat.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading"
  }, [_vm._v(" Let's start a conversation ")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75"
  }, [_vm._v(" Gear up for collaboration! Kickstart the conversation by dropping a message to your teammate or rally the crew by creating a group to dive into discussions. ")]), _c('button', {
    staticClass: "newButton mt-20px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-chat-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Start chatting")])], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }