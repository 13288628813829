<template>
  <div>
    <div class="mainHeading withButton">
      Training events settings
      <button @click="$modal.show('AddTrainingEventsCategoryModal')" class="dialer-button dialer-button-primary mr-0 withIcon">
        <!-- <vb-icon icon="pencil-hrmIcon" class="mr-16px fillWhite" height="14px" width="14px" /> -->
        <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
        Add category
      </button>
    </div>
    <div class="mainDescription mb-4">
      <br/>
    </div>
    <div class="mainHeading withButton">
      Training event categories
    </div>
    <div class="documentsModule-Table mt-32px">
      <vb-table class="HrmTable">
        <template slot="header">
          <tr>
            <th class="HrmTableHead text-left one pt-1">name</th>
            <th class="HrmTableHead text-left two pt-1">training events</th>
            <th class="HrmTableHead three pt-1">Actions</th>
          </tr>
        </template>
        <template #body>
          <tr v-for="n in 6" :key="n" class="dialer-row-select" >
            <td class="dialer-row-title one">Employee handbook</td>
            <td class="dialer-col-right text-left two">0</td>
            <td class="dialer-col-right three">
              <b-dropdown no-caret right class="ActionsDD">
                <template #button-content>
                  <div class="button-content">
                    <b-icon class="downIcon" icon="three-dots-vertical" />
                  </div>
                </template>
                <b-dropdown-item >
                  Actions
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    <b-form-checkbox class="HrmCheckBox-container mt-32px">
      Allow employees to request tickets even when event is full and place them in waiting list
    </b-form-checkbox>
    <button class="dialer-button dialer-button-primary mt-32px mr-0"> Save </button>
    
    <AddTrainingEventsCategoryModal />
    <!-- <AddPaymentType /> -->
  </div>
</template>

<script>
import AddTrainingEventsCategoryModal from '@/components/Modals/HRM/settings/EmployeeData/AddTrainingEventsCategoryModal.vue';
// import AddPaymentType from '@/components/Modals/HRM/settings/EmployeeData/AddPaymentType.vue';
export default {
  name: 'EmployeeDataTrainingEvents',
  components: {
    AddTrainingEventsCategoryModal,
    // AddPaymentType,
  },
}
</script>

<style>

</style>