export const centrifuge = {
    state: {
        message: {},
        notification_message: {},
        presence_message: {},
    },
    getters: {
        getCentrifugeMessage(state){return state.message},
        getCentrifugeNotificationMessage(state){return state.notification_message},
        getCentrifugePresenceMessage(state){return state.presence_message},
    },
    mutations: {
        setCentrifugeMessage(state,message){state.message=message},
        setCentrifugeNotificationMessage(state,message){state.notification_message=message},
        setCentrifugePresenceMessage(state,message){state.presence_message=message},
    },
  };
  