var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Extensions")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search Pickup groups"
    },
    model: {
      value: _vm.filter.extensions.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.extensions, "search", $$v);
      },
      expression: "filter.extensions.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.filterExtensions, function (extension, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item d-flex justify-content-between",
      on: {
        "click": function ($event) {
          return _vm.$modal.show('EditExtension', {
            data: extension
          });
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideCard mr-8px"
    }, [_vm._v(_vm._s(_vm._f("extensionLabel")(extension)))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide mr-8px"
    }, [_vm._v(_vm._s(extension.type))]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)]);
  }), !_vm.filterExtensions.length ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.extensions.send ? '' : 'There is no Tags',
      "design": 3
    }
  }, [_vm.api.extensions.send ? _c('vb-loading', {
    staticClass: "text-center w-100",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2), _c('EditExtensionModal', {
    on: {
      "load": function ($event) {
        return _vm.fetchExtensions();
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }