var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 AddNumberExistingModal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "opened": function ($event) {
        return _vm.onOpened();
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Add an existing caller id")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('p', {
    staticClass: "dialer-segment-caption sm-mar-bottom w-100"
  }, [_vm._v(" If you have a telephone number from another telephone or mobile service provider like BT or virgin media, You can use that same number as caller id on VoIP Business Dialer. ")]), _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.api.send_otp.status == 1,
      expression: "api.send_otp.status==1"
    }]
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.verfiyOTP();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.verify_otp.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.verify_otp.error_message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px mb-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Please enter the 6-digit verification code we sent via a " + _vm._s(_vm.sendingCode))]), _c('vb-pin-code', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.verify_otp.send
    },
    model: {
      value: _vm.forms.verify_otp.pincode,
      callback: function ($$v) {
        _vm.$set(_vm.forms.verify_otp, "pincode", $$v);
      },
      expression: "forms.verify_otp.pincode"
    }
  }), _vm.forms.verify_otp.submitted && _vm.$v.forms.verify_otp.pincode.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.verify_otp.pincode.required ? _c('span', [_vm._v("* Pincode is required")]) : !_vm.$v.forms.verify_otp.pincode.validpincode ? _c('span', [_vm._v("* Pincode is invalid")]) : !_vm.$v.forms.verify_otp.pincode.minLength ? _c('span', [_vm._v("* Pincode is must be 6 character")]) : !_vm.$v.forms.verify_otp.pincode.maxLength ? _c('span', [_vm._v("* Pincode is must be 6 character")]) : !_vm.$v.forms.verify_otp.pincode.numeric ? _c('span', [_vm._v("* Pincode is invalid")]) : _vm._e()]) : _vm._e()], 1)]), _c('a', {
    staticClass: "dialer-box-text mb-0 highlight font-weight-bold text-underline-imp",
    attrs: {
      "disabled": _vm.api.verify_otp.send
    },
    on: {
      "click": function ($event) {
        _vm.api.send_otp.reset();
        _vm.forms.check_number_exits.reset();
        _vm.api.check_number_exits.reset();
        _vm.api.verify_otp.reset();
      }
    }
  }, [_vm._v(" Change number ")]), _c('button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "disabled": _vm.api.verify_otp.send,
      "type": "submit"
    }
  }, [_vm._v(" Verify ")])], 1)]), _c('form', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.api.send_otp.status != 1,
      expression: "api.send_otp.status!=1"
    }],
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-4"
  }, [_c('label', [_vm._v("Enter the number for caller id")]), _c('input', {
    directives: [{
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.forms.check_number_exits.number,
      expression: "forms.check_number_exits.number"
    }],
    staticClass: "touched w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.check_number_exits.send || _vm.api.send_otp.send
    },
    domProps: {
      "value": _vm.forms.check_number_exits.number
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.check_number_exits, "number", $event.target.value);
      }, function ($event) {
        return _vm.checkNumberExitsDebounce();
      }]
    }
  }), _vm.forms.check_number_exits.submitted && _vm.$v.forms.check_number_exits.number.$invalid || _vm.api.check_number_exits.status != 0 ? _c('p', {
    staticClass: "text animated bounceIntop",
    class: _vm.api.check_number_exits.status == 1 && !_vm.$v.forms.check_number_exits.number.$invalid ? 'text-success' : 'text-danger'
  }, [!_vm.$v.forms.check_number_exits.number.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.check_number_exits.number.validNumber ? _c('span', [_vm._v("Number is invalid")]) : _vm.api.check_number_exits.status == 1 ? _c('span', [_vm._v("Number is valid")]) : _vm.api.check_number_exits.status == 2 ? _c('span', [_vm._v("* " + _vm._s(_vm.api.check_number_exits.error_message))]) : _vm._e()]) : _vm._e()]), _c('div', {
    attrs: {
      "id": "recaptcha-firebase-add-caller-id"
    }
  })]), _c('div', {
    staticClass: "d-flex justify-content-between mt-20px w-100"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton w-50 mr-2",
    attrs: {
      "disabled": _vm.api.check_number_exits.status != 1,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.sendOTP();
        _vm.sendingCode = 'sms';
      }
    }
  }, [_vm._v(" SMS ")]), _c('button', {
    staticClass: "fullWidthDoneButton w-50 ml-2",
    attrs: {
      "disabled": _vm.api.check_number_exits.status != 1,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.sendOTP('call');
        _vm.sendingCode = 'call';
      }
    }
  }, [_vm._v(" Call ")])])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }