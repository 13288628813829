<template>
  <modal 
    class="center-zoomIn-transition v2 m-w-500" 
    height="auto" 
    :name="modalName"
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()"
  >
    <form @submit.prevent="changePassword()" class="w-100">
      <div class="d-flex align-items-start justify-content-between w-100">
        <div class="d-flex flex-column">
          <h2 class="dialer-edit-title">Change password</h2>
          <p>Enter a strong and memorable password</p>
        </div>
        <button @click="$modal.hide(modalName)" class="newCloseButton" type="button">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px w-100">
        <div class="whiteBGinputWithGreyRoundedBorder withRightIconOnly w-100">
          <label>Set new password</label>
          <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
            <input 
              class="w-100" 
              :type="`${forms.change_password.show_new_password?'text':'password'}`"
              v-model="forms.change_password.new_password" 
              :disabled="api.change_password.send" 
              :maxlength="$v.forms.change_password.new_password.$params.maxLength.max"
            />
            <b-icon :disabled="api.change_password.send" @click="forms.change_password.show_new_password=!forms.change_password.show_new_password" :icon="`${forms.change_password.show_new_password?'eye-slash-fill':'eye-fill'}`" />
            <!-- <b-button variant="link" :disabled="api.change_password.send" @click="forms.change_password.show_new_password=!forms.change_password.show_new_password">
              <b-icon :icon="`${forms.change_password.show_new_password?'eye-fill':'eye-slash-fill'}`" />
            </b-button> -->
          </div>
          <p class="text text-danger animated bounceIntop mb-0" v-if="(forms.change_password.submitted && $v.forms.change_password.new_password.$invalid) || api.change_password.validation_errors.password">
            <span>* </span>
            <span v-if="!$v.forms.change_password.new_password.required">New password is required</span>
            <span v-else-if="!$v.forms.change_password.new_password.capitalalphabet">New password required at least one capital character</span>
            <span v-else-if="!$v.forms.change_password.new_password.smallalphabet">New password required at least one small character</span>
            <span v-else-if="!$v.forms.change_password.new_password.numbers">New password required at least one integer character</span>
            <span v-else-if="!$v.forms.change_password.new_password.specialcharacter">New password required at least one special character</span>
            <span v-else-if="!$v.forms.change_password.new_password.minLength">New password is minimum {{$v.forms.change_password.new_password.$params.minLength.min}} is required</span>
            <span v-else-if="!$v.forms.change_password.new_password.maxLength">New password is maximum {{$v.forms.change_password.new_password.$params.maxLength.max}} character</span>
            <span v-for="(em,i) in api.change_password.validation_errors.password" :key="i">{{ em }}</span>
          </p>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder withRightIconOnly w-100 mt-20px">
          <label>Confirm password</label>
          <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
            <input 
              class="w-100" 
              :type="`${forms.change_password.show_confirm_password?'text':'password'}`"
              v-model="forms.change_password.confirm_password" 
              :disabled="api.change_password.send"  
            />
            <b-icon :icon="`${forms.change_password.show_confirm_password?'eye-slash-fill':'eye-fill'}`" :disabled="api.change_password.send" @click="forms.change_password.show_confirm_password=!forms.change_password.show_confirm_password" />
            <!-- <b-button variant="link" :disabled="api.change_password.send" @click="forms.change_password.show_confirm_password=!forms.change_password.show_confirm_password">
              <b-icon :icon="`${forms.change_password.show_confirm_password?'eye-fill':'eye-slash-fill'}`" />
            </b-button> -->
          </div>
          <p class="text text-danger animated bounceIntop mb-0" v-if="forms.change_password.submitted && $v.forms.change_password.confirm_password.$invalid">
            <span>* </span>
            <span v-if="!$v.forms.change_password.confirm_password.required">Confirm password is required</span>
            <span v-else-if="!$v.forms.change_password.confirm_password.capitalalphabet">Confirm password should be same as new password</span>
          </p>
        </div>
        <div class="PasswordLength-LineContainer mt-20px">
          <label class="mb-0">Password strength</label>
          <div class="line-wrap">
            <div 
              :class="`line-itself ${(passwordStrength/5 * 100) > 60 ? 'strong' : ''} `" 
              :style="`width: calc(${passwordStrength/5 * 100}% + ${passwordStrength>0 ? '2' : '0'}px);`"
            />
          </div>
          <small class="line-text">{{passwordStrength | filterPasswordStrengthText}}</small>
        </div>
      </div>
      <div class="w-100 mt-20px">
        <button class="fullWidthDoneButton" type="submit" >
          <vb-spinner v-if="api.change_password.send" /> 
          <span v-else>Update</span>
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils'
import maxLength from 'vuelidate/lib/validators/maxLength'
import minLength from 'vuelidate/lib/validators/minLength'
import required from 'vuelidate/lib/validators/required'
import sameAs from 'vuelidate/lib/validators/sameAs'
import { mapGetters } from 'vuex'
import { GET_CRM_TOKEN } from '@/store/helper/getters'
export default {
  name: 'ChangeUserPasswordModal',
  props: {
    modalName: {
      type: String,
      default: 'ChangeUserPassword',
    },
  },
  data() {
    return {
      forms: {
        change_password: this.$helperFunction.formInstance({
          data: {
            new_password: '',
            show_new_password: false,
            confirm_password: '',
            show_confirm_password: false,
          }
        }),
      },
      api: {
        change_password: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        })
      },
      data: {
        accountcode: '',
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      GET_CRM_TOKEN
    ]),
    passwordStrength(){
      const password = this.forms.change_password.new_password
      return ~~[/[A-Z]/.test(password),/[0-9]/.test(password),/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(password),/[a-z]/.test(password),password.length > 11,].reduce((a, b)=>b ? a + 1 : a)
    },
  },
  validations: {
    forms: {
      change_password: {
        new_password: {
          required: required,
          minLength: minLength(8),
          maxLength: maxLength(32),
          capitalalphabet: function(value){
            return /[A-Z]/.test(value)
          },
          smallalphabet: function(value){
            return /[a-z]/.test(value)
          },
          numbers: function(value){
            return /[0-9]/.test(value)
          },
          specialcharacter: function(value){
            return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(value)
          },
        },
        confirm_password: {
          required: required, 
          sameAsPassword: sameAs('new_password')
        }
      },
    },
  },
  filters: {
    filterPasswordStrengthText(value){
      return ['None','very weak','weak','average','strong','very strong'][value] || ''
    }
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode=event?.params?.accountcode ?? ''
    },
    onBeforeClose(){
      this.forms.change_password.reset()
      this.api.change_password.reset()
      this.data.accountcode=''
    },
    async changePassword() {
      this.forms.change_password.submitted=true
      this.$v.forms.change_password.$touch()
      if(this.$v.forms.change_password.$invalid || this.api.change_password.send) return;
      try {
        this.api.change_password.send=true
        this.api.change_password.error_message=''
        this.api.change_password.validation_errors={}
        await VOIP_API.endpoints.users.updatepassword({
          uid: this.getCurrentUser?.uid,
          action: "user_password",
          password: this.forms.change_password.new_password,
          id: this.data.accountcode,
          type: 'relay',
          crm_token: this.GET_CRM_TOKEN,
        })
        this.$emit('updated')
        this.appNotify({
          message: "Successfully updated",
          type: "success",
        })
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.change_password.error_message=ex.own_message
        this.api.change_password.validation_errors=ex.own_errors
      } finally {
        this.forms.change_password.submitted=false
        this.api.change_password.send=false
      }
    }
  },
}
</script>

<style>

</style>