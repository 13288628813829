<template>
  <div>
    <div class="mainHeading withButton">
      Time Off Ledger Report
      <button class="dialer-button dialer-button-primary mr-0">
        Record new transaction
      </button>
    </div>
    <div class="mainDescription mb-4">
      This page lists the transactions for time off that you have submitted.
    </div>
    <div class="dropdownsContainer">
      <div class="innerShadowInput-container w-auto mr-32px">
        <label>Employee</label>
        <b-dropdown class="hrmInnerShadow-dd big" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="nameWithImage insideDropdown">
                <div class="imageContainer">
                  <img class="circle" :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">Mellissa</div>
              </div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item >
            <div class="nameWithImage insideDropdown">
              <div class="imageContainer">
                <img class="circle" :src="require('@/assets/images/credit-card/1.jpeg')" />
              </div>
              <div class="nameWithImage-text">Mellissa</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item >
            <div class="nameWithImage insideDropdown">
              <div class="imageContainer">
                <img class="circle" :src="require('@/assets/images/credit-card/2.jpeg')" />
              </div>
              <div class="nameWithImage-text">Mellissa</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item >
            <div class="nameWithImage insideDropdown">
              <div class="imageContainer">
                <img class="circle" :src="require('@/assets/images/credit-card/3.jpeg')" />
              </div>
              <div class="nameWithImage-text">Mellissa</div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="innerShadowInput-container w-auto">
        <label>Export time unit</label>
        <b-dropdown class="hrmInnerShadow-dd big" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText">days</div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            days
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="TimeOffLedgerTable mt-16px">
      <!-- <div class="dialer-input-field input-consistent-inner-shadows sm-mar-left seachBox">
        <b-input type="text" placeholder="Search user" v-model.lazy="filter.time_off_ledger.search"/>
      </div> -->
      <!-- <vb-table
        :isListEmpty="isEmpty(filterTimeOffLedger)" 
        :listLength="filterTimeOffLedger.length" 
        :loading="api.time_off_ledger.send" 
      >
        <template slot="header">
          <tr>
            <th class="dialer-has-sort">
              <span>Employee</span>
            </th>
            <th class="dialer-has-sort">
              <span>Policy</span>
            </th>
            <th class="dialer-has-sort dialer-col-right">
              <span>Action</span>
            </th>
            <th class="dialer-has-sort dialer-col-right">
              <span>Days</span>
            </th>
            <th class="dialer-has-sort dialer-col-right">
              <span>Note</span>
            </th>
            <th class="dialer-has-sort dialer-col-right">
              <span>Created at</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr class="dialer-row-select" v-for="(data, index) in filterTimeOffLedger" v-show="index >= start && index <= end" :key="data.user">
            <td class="dialer-row-title"><div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">{{ data.name }}</div>
              </div>
            </td>
            <td class="dialer-col-right">
              <div class="circleWithText">
                <div class="circleContainer" style="background-color:#D5761B"></div>
                <div class="circleWithText-text" style="color:#D5761B">{{ data.policy }}</div>
              </div>
            </td>
            <td class="dialer-col-right">{{ data.action }} </td>
            <td class="dialer-col-right">{{ data.days }} </td>
            <td class="dialer-col-right">{{ data.note }} </td>
            <td class="dialer-col-right">{{ data.created_at }}</td>
          </tr>
        </template>
      </vb-table> -->
  
      <div class="HrmTableHead-container">
        <div class="HrmTableHead one">Employee</div>
        <div class="HrmTableHead two">Policy</div>
        <div class="HrmTableHead three">Action</div>
        <div class="HrmTableHead four">Days</div>
        <div class="HrmTableHead five">Note</div>
        <div class="HrmTableHead six">Created at</div>
      </div>
      <vb-table class="HrmTable" :isListEmpty="isEmpty(filterTimeOffLedger)" 
        :listLength="filterTimeOffLedger.length" 
        :loading="api.time_off_ledger.send" 
      >
        <!-- <template slot="header">
          <tr><th class="dialer-has-sort"></th></tr>
        </template> -->
        <template #body="{ start, end }">
          <tr class="dialer-row-select" v-for="(data, index) in filterTimeOffLedger" v-show="index >= start && index <= end" :key="data.user">
            <td class="dialer-row-title one">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">{{ data.name }}</div>
              </div>
            </td>
            <td class="dialer-row-title two">{{ data.policy }}</td>
            <td class="dialer-row-title three">{{ data.action }}</td>
            <td class="dialer-row-title four">
              {{ data.days }}
            </td>
            <td class="dialer-row-title five">
              {{ data.note }}
            </td>
            <td class="dialer-row-title six">
              {{ data.created_at }}
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
import { $fn } from "@/utils";
import { HRM } from '@/data'
export default {
  name: 'TimeOffLedger',
  data(){
    return {
      response: {
        time_off_ledger: []
      },
      filter: {
        time_off_ledger: {
          search: '',
          direction: '',
        }
      },
      api: {
        time_off_ledger: this.$helperFunction.apiInstance({ error_message: true }),
      },
    }
  },
  inject:['isEmpty'],
  computed:{
    filterTimeOffLedger(){
      const search = this.filter.time_off_ledger.search.trim().toLowerCase()
      // const direction = this.filter.rules.direction
      const rules = this.response.time_off_ledger
      const filter_time_off_ledger = rules.filter(item=>
        `${(item.name || '').replace(/-/g,'')} ${item.policy} ${item.action}`.trim().toLowerCase().includes(search)
      )
      return filter_time_off_ledger
    }
  },
  methods: {
    fetchTimeOffLedger(){
      // console.log("awaiting is called")
      let vm = this;
      if(vm.api.time_off_ledger.send) return;
      vm.api.time_off_ledger.send=true
      $fn.sleep(2 * 1000)
      .then(()=>{
        // console.log("HRM Data", HRM.timeoffledger)
        vm.response.time_off_ledger = HRM.timeoffledger
      })
      .catch((/* err */)=>{
        // console.log("error", err)
      }).finally(()=> { vm.api.time_off_ledger.send = false })
    }
  },
  mounted(){
    this.fetchTimeOffLedger();
  }
}
</script>

<style>

</style>