var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.organization_user.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add User By Username")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CheckedSlot', {
          service: 'user',
          onProceed: _vm.adduser
        });
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Detail")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addorganizationuser();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.organization_user.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.organization_user.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Username")]), _c('b-input-group', [_c('b-form-input', {
    staticClass: "withTextOnRight",
    attrs: {
      "placeholder": "Enter Username",
      "maxlength": _vm.$v.forms.organization_user.name.$params.maxLength.max,
      "type": "text",
      "disabled": _vm.api.organization_user.send
    },
    model: {
      value: _vm.forms.organization_user.name,
      callback: function ($$v) {
        _vm.$set(_vm.forms.organization_user, "name", $$v);
      },
      expression: "forms.organization_user.name"
    }
  }), _c('b-input-group-append', [_c('span', [_vm._v("@" + _vm._s(_vm.getCurrentUser.company))])])], 1), _vm.forms.organization_user.submitted && _vm.$v.forms.organization_user.name.$invalid || _vm.api.organization_user.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.organization_user.name.required ? _c('span', [_vm._v("* Username is Required")]) : !_vm.$v.forms.organization_user.name.minLength ? _c('span', [_vm._v(" * Username should be minimum " + _vm._s(_vm.$v.forms.organization_user.name.$params.minLength.max) + " character ")]) : !_vm.$v.forms.organization_user.name.maxLength ? _c('span', [_vm._v(" * Username can be maximum " + _vm._s(_vm.$v.forms.organization_user.name.$params.maxLength.max) + " character ")]) : !_vm.$v.forms.organization_user.name.spaceInclude ? _c('span', [_vm._v("* Spaces are not allowed in username")]) : !_vm.$v.forms.organization_user.name.alphaNum ? _c('span', [_vm._v("* Username should be an alphanumeric")]) : _vm._e(), _vm._l(_vm.api.organization_user.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Full Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.organization_user.display_name,
      expression: "forms.organization_user.display_name"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Full Name",
      "maxlength": _vm.$v.forms.organization_user.display_name.$params.maxLength.max,
      "type": "text",
      "onkeypress": "return /^[A-Za-z]+$/.test(event.key) || event.key==' '",
      "disabled": _vm.api.organization_user.send
    },
    domProps: {
      "value": _vm.forms.organization_user.display_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.organization_user, "display_name", $event.target.value);
      }
    }
  }), _vm.forms.organization_user.submitted && _vm.$v.forms.organization_user.display_name.$invalid || _vm.api.organization_user.validation_errors.display_name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.organization_user.display_name.required ? _c('span', [_vm._v("* Full Name is Required")]) : !_vm.$v.forms.organization_user.display_name.minLength ? _c('span', [_vm._v(" * Full Name should be minimum " + _vm._s(_vm.$v.forms.organization_user.display_name.$params.minLength.min) + " character ")]) : !_vm.$v.forms.organization_user.display_name.maxLength ? _c('span', [_vm._v(" * Full Name can be maximum " + _vm._s(_vm.$v.forms.organization_user.display_name.$params.maxLength.max) + " character ")]) : !_vm.$v.forms.organization_user.display_name.alpha ? _c('span', [_vm._v("* Full Name must be only alphabets")]) : !_vm.$v.forms.organization_user.display_name.valid ? _c('span', [_vm._v("* Full Name must have a space character")]) : _vm._e(), _vm._l(_vm.api.organization_user.validation_errors.display_name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Automatically create a password")])])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.organization_user.send,
      "switch": ""
    },
    on: {
      "input": function ($event) {
        _vm.forms.organization_user.is_send_email = false;
      }
    },
    model: {
      value: _vm.forms.organization_user.is_passwword_manual,
      callback: function ($$v) {
        _vm.$set(_vm.forms.organization_user, "is_passwword_manual", $$v);
      },
      expression: "forms.organization_user.is_passwword_manual"
    }
  })], 1)]), !_vm.forms.organization_user.is_passwword_manual ? [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Password")]), (_vm.conditions.organization_user_password_show ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.organization_user.password,
      expression: "forms.organization_user.password"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter password",
      "disabled": _vm.api.organization_user.send,
      "readonly": "",
      "onfocus": "this.removeAttribute('readonly');",
      "maxlength": _vm.$v.forms.organization_user.password.$params.maxLength.max,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.forms.organization_user.password) ? _vm._i(_vm.forms.organization_user.password, null) > -1 : _vm.forms.organization_user.password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.forms.organization_user.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.forms.organization_user, "password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.forms.organization_user, "password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.forms.organization_user, "password", $$c);
        }
      }
    }
  }) : (_vm.conditions.organization_user_password_show ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.organization_user.password,
      expression: "forms.organization_user.password"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter password",
      "disabled": _vm.api.organization_user.send,
      "readonly": "",
      "onfocus": "this.removeAttribute('readonly');",
      "maxlength": _vm.$v.forms.organization_user.password.$params.maxLength.max,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.forms.organization_user.password, null)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.forms.organization_user, "password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.organization_user.password,
      expression: "forms.organization_user.password"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter password",
      "disabled": _vm.api.organization_user.send,
      "readonly": "",
      "onfocus": "this.removeAttribute('readonly');",
      "maxlength": _vm.$v.forms.organization_user.password.$params.maxLength.max,
      "type": _vm.conditions.organization_user_password_show ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.forms.organization_user.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.organization_user, "password", $event.target.value);
      }
    }
  }), _c('b-icon', {
    staticClass: "dialer-showPassword-icon cursor_pointer",
    attrs: {
      "icon": _vm.conditions.organization_user_password_show ? 'eye-slash' : 'eye'
    },
    on: {
      "click": function ($event) {
        _vm.api.organization_user.send ? '' : _vm.conditions.organization_user_password_show = !_vm.conditions.organization_user_password_show;
      }
    }
  }), _vm.forms.organization_user.submitted && _vm.$v.forms.organization_user.password.$invalid || _vm.api.organization_user.validation_errors.password ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.organization_user.password.required ? _c('span', [_vm._v("* Password is required")]) : !_vm.$v.forms.organization_user.password.minLength ? _c('span', [_vm._v("* Password should be minimum 3 character")]) : !_vm.$v.forms.organization_user.password.maxLength ? _c('span', [_vm._v("* Password can be maximum 20 character")]) : _vm._e(), _vm._l(_vm.api.organization_user.validation_errors.password, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Send password in email upon completion")])])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.organization_user.send,
      "switch": ""
    },
    model: {
      value: _vm.forms.organization_user.is_send_email,
      callback: function ($$v) {
        _vm.$set(_vm.forms.organization_user, "is_send_email", $$v);
      },
      expression: "forms.organization_user.is_send_email"
    }
  })], 1)])] : _vm._e()], 2), _vm.forms.organization_user.is_send_email ? [_c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Email the new password to the following recepients")]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.organization_user.email,
      expression: "forms.organization_user.email"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Enter Email",
      "disabled": _vm.api.organization_user.send
    },
    domProps: {
      "value": _vm.forms.organization_user.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.organization_user, "email", $event.target.value);
      }
    }
  }), _vm.forms.organization_user.submitted && _vm.$v.forms.organization_user.email.$invalid || _vm.api.organization_user.validation_errors.email ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.organization_user.email.required ? _c('span', [_vm._v("* email is required")]) : !_vm.$v.forms.organization_user.email.email ? _c('span', [_vm._v("* email is invalid")]) : _vm._e(), _vm._l(_vm.api.organization_user.validation_errors.email, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])])] : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Set as admin")])])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.organization_user.send,
      "switch": ""
    },
    model: {
      value: _vm.forms.organization_user.role,
      callback: function ($$v) {
        _vm.$set(_vm.forms.organization_user, "role", $$v);
      },
      expression: "forms.organization_user.role"
    }
  })], 1), _vm.forms.organization_user.submitted && _vm.$v.forms.organization_user.role.$invalid || _vm.api.organization_user.validation_errors.role ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.organization_user.role.required ? _c('span', [_vm._v("* Email is Required")]) : _vm._e(), _vm._l(_vm.api.organization_user.validation_errors.role, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.organization_user.send
    }
  }, [_vm.api.organization_user.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)], 2)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }