var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 bigSquareCheckbox",
    attrs: {
      "name": _vm.modalName,
      "scrollable": true,
      "height": "auto"
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Select action")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "dialer-box alt position-relative"
  }, [_c('div', {
    staticClass: "dialer-call-options options"
  }, [_c('b-form-group', {
    staticClass: "dialer-call-options-list v2 mb-0",
    staticStyle: {
      "position": "relative",
      "pointer-events": "auto"
    }
  }, [[_c('div', {
    staticClass: "position-relative d-flex"
  }, [_c('b-form-radio', {
    staticClass: "makeCheckBoxesSquare",
    class: 'active',
    attrs: {
      "name": "some-radios",
      "value": "hangup"
    }
  }, [_c('div', {
    staticClass: "w-100 dialer-call-option-c withRadio",
    class: 'active'
  }, [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Hangup")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v("Call will be hungup")])])])], 1), _c('div', {
    staticClass: "position-relative d-flex"
  }, [_c('b-form-radio', {
    staticClass: "makeCheckBoxesSquare",
    attrs: {
      "name": "some-radios",
      "value": "forward"
    }
  }, [_c('div', {
    staticClass: "withRadio w-100 dialer-call-option-c",
    class: {
      'active': _vm.forms.forwarding_setting.forward_note === 'extension'
    }
  }, [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Forward to another user, team or IVR ")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v(" Forward to "), _c('span', {
    staticClass: "color-primary text-underline"
  }, [_vm._v("user")])])])])], 1)]], 2)], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }