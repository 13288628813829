import Emitter from "component-emitter"

/* eslint-disable no-undef */
export const MOBILE_APP = {
  interface: null,
  init(){
    try {
      Emitter(this)
      window.mobileMessage = this.onMessage.bind(this)
      if(window?.webkit?.messageHandlers?.iosListener) this.interface=window?.webkit?.messageHandlers?.iosListener
      else this.interface=Android
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  sendMessage(event,payload={}){
    console.log(event,payload)
    this.interface?.postMessage?.(JSON.stringify({ event, payload }))
  },
  onMessage(message){
    try {
      const { event, payload } = JSON.parse(message)
      this.emit(event,payload)
    } catch (error) {
      alert(`error: ${error}`)
    }
  },
}