<template>
  <modal :name="modalName" class="dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 bigSquareCheckbox" :scrollable="true" height="auto" @before-open="onBeforeOpen" @before-close="onBeforeClose" >
    <section> 
      <div class="dialer-edit-header">
        <div>
          <h2 class="dialer-edit-title mb-0">Select action</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newCloseButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div class="dialer-box alt position-relative">
        <div class="dialer-call-options options">
          <b-form-group class="dialer-call-options-list v2 mb-0 " style="position: relative;pointer-events:auto;">
            <template>
              <!-- keep ringing -->
              <div class="position-relative d-flex">
                <b-form-radio class="makeCheckBoxesSquare" :class="'active'" name="some-radios" value="hangup">
                  <div class="w-100 dialer-call-option-c withRadio" :class="'active'">
                    <div class="selectedText-big">Hangup</div>
                    <div class="selectedText-small">Call will be hungup</div>
                  </div>
                </b-form-radio>
              </div>
              <!-- extension -->
              <div class="position-relative d-flex">
                <b-form-radio class="makeCheckBoxesSquare" name="some-radios" value="forward">
                  <div class="withRadio w-100 dialer-call-option-c" :class="{'active':forms.forwarding_setting.forward_note === 'extension'}">
                    <div class="selectedText-big">Forward to another user, team or IVR </div>
                    <div  class="selectedText-small">
                      Forward to
                      <span class="color-primary text-underline">user</span> 
                    </div>
                  </div>
                </b-form-radio>
              </div>
            </template>
          </b-form-group>
        </div>
      </div>
      <!-- <ScheduleAssignModal :modalName="`CallForwardingSelectExtensionModal`" @interface="forms.forwarding_setting.extension_name=$event.accountname;forms.forwarding_setting.forward_number=$event.account;updateForwardingSetting();" />
      <DurationPickerModal :modalName="`CallForwardingDurationPickerModal`" @time="forms.forwarding_setting.timeout=$event.seconds;updateForwardingSetting();" />
      <CallForwardingNumberInputModal :modalName="`CallForwardingCallForwardingNumberInputModal`" @number="forms.forwarding_setting.forward_number=$event.ber;updateForwardingSetting();"/> -->
    </section>
  </modal>
</template>

<script>
import { 
  // minLength, 
  // maxLength, 
  requiredIf 
} from "vuelidate/lib/validators";
// import ScheduleAssignModal from "../Modals/ScheduleAssignModal.vue";
// import DurationPickerModal from "../Modals/DurationPickerModal.vue";
// import CallForwardingNumberInputModal from "../Modals/CallForwardingNumberInputModal.vue";
export default {
  name: "CallForwardSettingModal",
  components: {
    // ScheduleAssignModal,
    // DurationPickerModal,
    // CallForwardingNumberInputModal,
  },
  props: {
    modalName: {
      type: String,
      default: "IVRselectActionsModal",
    },
  },
  data() {
    return {
      forms: {
        forwarding_setting: this.$helperFunction.formInstance({
          data: {
            forward_setting: "",
            forward_note: "",
            forward_number: "",
            timeout: 0,
            extension_name: "",
            number: "",
          },
        }),
        reset(){
          this.forwarding_setting?.reset?.()
        },
      },
      data: this.$helperFunction.stateInstance({
        data: {
          account: '',
        },
      }),
      condition: this.$helperFunction.stateInstance({
        data: {
          is_ringing_show: true,
          is_sec_show: true
        },
      }),
    };
  },
  validations: {
    forms: {
      forwarding_setting: {
        forward_number: {
          required: requiredIf(function(){
            return ['extension','number'].includes(this.forms.forwarding_setting.forward_note)
          }),
          // minLength: minLength(8),
          // maxLength: maxLength(15),
        },
        extension_name: {
          required: requiredIf(function(){
            return ['extension'].includes(this.forms.forwarding_setting.forward_note)
          }),
        }
      },
    },
  },
  watch: {
    "forms.forwarding_setting.forward_note"(new_val,old_val){
      if(old_val){
        this.forms.forwarding_setting.forward_number = '';
        this.forms.forwarding_setting.extension_name = '';
      }
      if(old_val=='off'){
        this.forms.forwarding_setting.timeout = 60;
      }
    },
  },
  methods: {
    onBeforeOpen(event){
      // console.log('onBeforeOpen', event.params.setting, event?.params?.is_ringing_show, event?.params?.is_sec_show, event?.params?.account)
      this.data.account=event?.params?.account ?? ''
      this.condition.is_ringing_show=event?.params?.is_ringing_show ?? true;
      this.condition.is_sec_show=event?.params?.is_sec_show ?? true;
      const setting = event?.params?.setting ?? {}
      this.forms.forwarding_setting.forward_setting = setting?.forward_setting ?? "";
      this.forms.forwarding_setting.forward_note = setting?.forward_note=='none' ? 'off' : setting?.forward_note ?? "";
      this.forms.forwarding_setting.forward_number = setting?.forward_number ?? "";
      this.forms.forwarding_setting.timeout = setting?.timeout ?? 0;
      this.forms.forwarding_setting.extension_name = setting?.extension_name ?? "";
      this.forms.forwarding_setting.number = setting?.number ?? "";
    },
    onBeforeClose(){
      this.forms?.reset?.()
      this.data?.reset?.()
      this.condition?.reset?.()
    },
    updateForwardingSetting() {
      let vm = this;
      vm.forms.forwarding_setting.submitted=true
      vm.$v.forms.forwarding_setting.$touch()
      if(vm.$v.forms.forwarding_setting.$invalid) return;
      vm.$emit('updated',vm.forms.forwarding_setting)
      vm.forms.forwarding_setting.submitted=false
    },
  },
};
</script>

