<template>
  <div class="dialer-analytics-main dialer-analytics-reports">
    <div class="dialer-box">
      <header class="dialer-analytics-titleBar">
        <template v-if="getIsMobile">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h1>Reporting</h1>
          </div>
        </template>
        <div v-else class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h1 class="dialer-settings-title newer">Reporting</h1>
          </div>
          <a @click="$emit('change-tab','Billing')" class="w-auto billedCallsLink" style="text-decoration: underline;font-size: 16px;color: black;cursor: pointer;">Billed calls</a>
        </div>
      </header>
      <div class="my-4 latestGreyBox-9-9-2023">
        <div class="d-flex w-100 justify-content-between">
          <div class="whiteBGinputWithGreyRoundedBorder wd-33-imp px-2">
            <label>Search number</label>
            <input :disabled="api.reports.send" v-model="filters.reports.number" type="text" class="w-100" placeholder="Source or destination number" />
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker wd-33-imp px-2">
            <label>Select date range</label>
            <vue-ctk-date-time-picker 
              v-bind="ctkDateTimePickerOptions" 
              label="Show current date" 
              format="YYYY-MM-DD" 
              :disabled="api.reports.send" 
              :noButton="false" 
              :range="true" 
              v-model="filters.reports.date_range" 
              class="/" 
            />
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder CallType wd-33-imp px-2">
            <label>Call type</label>
            <v-select 
              v-model="filters.reports.type" 
              :disabled="api.reports.send" 
              :options="response.types" 
              placeholder="Received, dialed and missed" 
              class="cursor_pointer2" 
            />
          </div>
        </div>
        <div class="d-flex w-100 mt-20px">
          <div class="whiteBGinputWithGreyRoundedBorder CallType w-100 px-2">
            <label class="dialer-input-label pl-2">User & extension</label>
            <vSelect 
              class="cursor_pointer2 lighter-placeholder" 
              multiple 
              :disabled="api.reports.send" 
              v-model="filters.reports.agents" 
              :options="response.extensions" 
              placeholder="One user with multiple extensions, limit." 
            />
          </div>
        </div>
        <div class="d-flex w-100 mt-20px">
          <div class="whiteBGinputWithGreyRoundedBorder CallType w-100 px-2">
            <label class="dialer-input-label pl-2">Tags</label>
            <vSelect 
              class="cursor_pointer2 lighter-placeholder" 
              multiple 
              :disabled="api.reports.send" 
              v-model="filters.reports.tags" 
              :options="tagOptions" 
              placeholder="Select tags" 
            />
          </div>
        </div>
        <div class="d-flex w-100 justify-content-end mt-20px">
          <!-- <button class="dialer-button dialer-button-primary ml-auto" @click="paginations.reports.page=1;fetchReports()" :disabled="api.reports.send">Result</button> -->
          <button class="newButton ml-auto" @click="paginations.reports.page=1;fetchReports()" :disabled="api.reports.send">
            <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Result</span>
          </button>
        </div>
      </div>
      <div class="md-mar-top dialer-box latestTableDesign-withBlackBorders-again pt-3 alt table">
        <table class="dialer-table">
          <thead>
            <tr>
              <th class="dialer-has-sort two">
                <span>Test</span>
              </th>
              <th class="dialer-has-sort four">
                <span>TIME & DATE</span>
              </th>
              <th class="dialer-has-sort five">
                <span id="timeAndDuration">DURATION</span>
              </th>
              <th class="dialer-has-sort six dialer-col-right">
                <span>Call Recorded</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <template  v-if="api.reports.send">
              <tr v-for="n in 5" :key="n">
                <!-- <td>
                  <div class="d-flex">
                    <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                    <div class="d-flex flex-column justify-content-between">
                      <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                      <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                    </div>
                  </div>
                </td> -->
                <td>
                  <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                </td>
                <td>
                  <div class="d-flex">
                    <div class="latestShimmerDesign mr-2" style="height:20px;min-width:60px;"></div>
                  </div>
                </td>
                <td>
                  <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:40px;width:70px;"></div>
                </td>
              </tr>
            </template>
            <tr v-else-if="response.reports.length==0">
              <td colspan="6">
                <div class="emptyScreenContainer b-0">
                  <img width="210px" height="196px" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/emptyReports.png')"/>
                  <img width="210px" height="196px" v-else :src="require('@/assets/images/emptyScreenImages/emptyReports.png')"/>
                  <div class="emptyScreenContainer-heading">You haven't generated any reports yet. Get started now!</div>
                  <div class="emptyScreenContainer-text w-75">Your report library is empty at the moment. Start by creating custom reports based on your preferences and analyze your call data effectively.</div>
                  <button class="dialer-button dialer-button-primary mt-32px">Generate a report</button>
                </div>
              </td>
            </tr>
            <template v-else>
              <ReportItem 
                v-for="(data, key) in response.reports" 
                :key="key" 
                :index="key" 
                :data="data" 
                @transcript-click="$modal.show(`${_uid}-RecordingTranscript`,{
                  uniq_id: data.uniqueid,
                  sound_file: data.soundFile,
                })" 
              />
            </template>
          </tbody>
        </table>
        <div v-if="response.reports.length!=0" class="d-flex justify-content-center w-100">
          <vb-pagination 
            :listLength="paginations.reports.total" 
            :conditions="{ pagination: { per_page: false } }" 
            :perPage="paginations.reports.per_page" 
            :page="paginations.reports.page"
            :isListEmpty="false" 
            @latest-pagination-state="$event.page!=paginations.reports.page ? (paginations.reports.page=$event.page,fetchReports()) : ''" 
          />
        </div>
      </div>
    </div>
    <CreateReportModal />
    <RecordingTranscriptModal :modalName="`${_uid}-RecordingTranscript`" />
  </div>
</template>

<script>
import CreateReportModal from "../Modals/CreateReportModal.vue";
import ReportItem from "./ReportItem.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { VOIP_API } from "../../utils";
import { ctkDateTimePicker } from '../../mixin';
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import RecordingTranscriptModal from '../Modals/RecordingTranscriptModal.vue';

export default {
  name: "Report",
  mixins: [ctkDateTimePicker],
  inject:['isEmpty','appNotify'],
  components: {
    CreateReportModal,
    vSelect,
    ReportItem,
    RecordingTranscriptModal,
  },
  data() {
    return {
      response: this.$helperFunction.stateInstance({
        data: {
          reports: [],
          extensions: [],
          types: [],
        }
      }),
      api: {
        reports: this.$helperFunction.apiInstance({ source: true }),
        filters: this.$helperFunction.apiInstance({  }),
        reset(){
          this.reports.reset()
          this.filters.reset()
        },
      },
      filters: {
        reports: this.$helperFunction.stateInstance({
          data: {
            number: '',
            date_range: null,
            type: '',
            agents: [],
            tags: [],
          }
        }),
        reset(){
          this.reports.reset()
        },
      },
      paginations: {
        reports: this.$helperFunction.stateInstance({
          data: {
            per_page: 10,
            page: 1,
            total: 0,
          }
        }),
        reset(){
          this.reports.reset()
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVoipCallTags',
      'getIsMobile',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
    tagOptions(){
      return this.getVoipCallTags.map((item) => {
        return {
          label: item.tag,
          value: item.real_id,
        };
      });
    },
  },
  methods: {
    fetchFilters(){
      let vm = this
      vm.api.filters.send=true
      VOIP_API.endpoints.call_activity.reportingfilterdata({
        type: "filter",
      }).then(({ data: filters }) => {
        vm.response.extensions = filters?.extensions ?? [];
        vm.response.types = filters?.typeOptions ?? [];
      }).finally(() => {
        vm.api.filters.send=false
      });
    },
    fetchReports(){
      let vm = this
      vm.api.reports.source?.cancel?.("Requesting Again");
      vm.api.reports.source = require("axios").default.CancelToken.source()
      vm.api.reports.send=true
      VOIP_API.endpoints.call_activity.reportingfilterdata({
        type: "data",
        page: vm.paginations.reports.page,
        number: vm.filters.reports.number,
        from: vm.filters.reports.date_range?.start ?? '',
        to: vm.filters.reports.date_range?.end ?? '',
        agent: vm.filters.reports.agents?.map?.(i=>i.value)?.join?.(',') ?? '',
        tags: vm.filters.reports.tags?.map?.(i=>i.value)?.join?.(',') ?? '',
        callType: vm.filters.reports?.type?.value,
      },vm.api.reports.source.token)
      .then(({ data, no_record }) => {
        vm.paginations.reports.per_page=data.perPage
        if(no_record){
          vm.response.reports = [];
          if(vm.paginations.reports.page==1){
            vm.paginations.reports.total = 0;
          } else {
            vm.paginations.reports.page=vm.paginations.reports.page-1
            vm.fetchReports()
          }
        } else {
          vm.response.reports = data?.data ?? [];
          vm.paginations.reports.total = data?.total;
        }
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.reports.send=false
      });
    },
  },
  mounted() {
    this.fetchFilters();
    this.fetchReports();
  },
};
</script>
