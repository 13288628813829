<template>
  <div class="dialer-memberList-area">
    <div class="swiper-container">
      <div class="swiper-wrapper">
        <div
          class="swiper-slide"
          v-for="member in members"
          :key="member.voipaccount"
        >
          <div class="item">
            <vb-avatar
              :id="`avatar-video-call-${member.account}-${random_id}`"
              :name="`${member.user.profile.first_name} ${member.user.profile.last_name}`"
              :status_id="member.status.id"
              :image="member.profile_img"
              :size="'60px'"
            />
            <b-tooltip :target="`avatar-video-call-${member.account}-${random_id}`">{{ member.user.display_name }}</b-tooltip>
          </div>
        </div>
      </div>
      <div class="swiper-button swiper-button-next"></div>
      <div class="swiper-button swiper-button-prev"></div>
    </div>
  </div>
</template>

<script>
import { $fn, } from "../../utils";
import _ from "lodash";
export default {
  name: "MembersList",
  props: {
    participants: {
      type: Object,
      default: () => ({
        random_id: '',
      }),
    },
  },
  data(){
    return {
      random_id: '',
    }
  },
  computed: {
    members() {
      const participants_array = Object.values(this.participants);
      let array = [];
      for (let index = 0; index < participants_array.length; index++) {
        const participant = participants_array[index];
        if (!participant._displayName) continue;
        const user = this.$store.getters.getVoipUsersAlises[participant._displayName];
        if (_.isEmpty(user)) continue;
        array.push(user);
      }
      return array;
    },
  },
  mounted() {
    this.random_id = $fn.makeid(20)
    const Swiper = window?.Swiper
    if(Swiper){
      new Swiper(".swiper-container", {
        slidesPerView: 3,
        centeredSlides: false,
        spaceBetween: 10,
        navigation: {
          nextEl: ".swiper-button-next",
          prevEl: ".swiper-button-prev",
        },
      });
    }
  },
};
</script>

<style lang="scss" scoped>
.dialer-memberList-area {
  .swiper-container {
    width: 100%;
    height: 100px;
    .swiper-wrapper {
      padding: 0px 40px;
      .swiper-slide {
        background: transparent;
        width: 100px !important;
        display: -webkit-box;
        display: -ms-flexbox;
        display: -webkit-flex;
        display: flex;
        -webkit-box-pack: center;
        -ms-flex-pack: center;
        -webkit-justify-content: center;
        justify-content: center;
        -webkit-box-align: center;
        -ms-flex-align: center;
        -webkit-align-items: center;
        align-items: center;

        .item {
          display: flex;
          justify-content: center;
          align-items: center;
          cursor: pointer;
          width: 100%;
          height: 100%;
          img {
            margin: 0px;
            @include border-radius(16px);
            overflow: hidden;
          }
          .main{
            @include border-radius(16px);
          }
        }
      }
    }
    .swiper-button {
      pointer-events: unset;
      margin: 0px;
      transform: translateY(-50%);
      width: 30px;
      height: 30px;
      background: transparent;
      border: 1px solid $white;
      opacity: 1;
      @include border-radius(8px);
      &:after {
        color: $white;
        font-size: 16px;
      }
      &:hover {
        background: $white;
        &:after {
          color: $primary;
        }
      }
    }
  }
}
</style>