<template>
  <modal class="dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll" name="AddPaymentType" :clickToClose="true" :scrollable="true">
    <div class="modalHeading-container">
      <div class="modalHeading">New payment type</div>
      <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('AddPaymentType')">Close</button>
    </div>
    <div class="modalInner-container">
      <div class="innerShadowInput-container">
        <label>Title</label>
        <input class=""/>
      </div>
      <div class="grayCard borderRadius-12px flexColumn px-4 mt-32px">
        <div class="innerShadowInput-container mb-0">
          <label class="">Training requests with this payment type:</label>
        </div>
        <b-form-group class="HrmRadio-container mt-16px w-100">
          <b-form-radio class="HrmRadio" >
            Does not require approval
          </b-form-radio>
          <b-form-radio class="HrmRadio">
            Requires approval from direct manager
          </b-form-radio>
          <b-form-radio class="HrmRadio">
            Requires approval from specific employee
          </b-form-radio>
        </b-form-group>
      </div>
      <b-form-checkbox class="HrmCheckBox-container">
        Send email to specific person when training is approved
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Create a task for employee when training is approved
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Make "Costs" field mandatory
      </b-form-checkbox>
      <button class="dialer-button dialer-button-primary mt-32px mr-0"> Add </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'AddPaymentType',
}
</script>

<style>

</style>