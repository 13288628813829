var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" First day of the week for calendars ")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Calendars through Sage HR can start on Sunday or Monday, depending on your preference. Once set, this will be reflected anywhere a calendar appears. ")]), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('b-form-group', {
    staticClass: "HrmRadio-container wd-32"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Monday ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Sunday ")])], 1)], 1), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Weekend days")]), _c('div', {
    staticClass: "grayCard-description mt-16px"
  }, [_vm._v(" Mark the days which are weekends in your country. ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Monday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Tuesday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Wedensday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Thursday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Friday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Saturday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Sunday ")])], 1), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Dashboard widgets")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Show job anniversaries in \"Anniversaries\" widget ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Show birthdays in \"Anniversaries\" widget ")])], 1), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Calendar feed")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Show anniversaries ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Show bithdays ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Show custom holidays ")])], 1), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Calendar ordering")]), _c('b-form-group', {
    staticClass: "HrmRadio-container wd-32 mt-24px"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" By teams & employee first name ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" By teams & employee last name ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" By teams & org chart hierarchy ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" By employee first name ")])], 1)], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary w-fit-content mt-32px mr-0"
  }, [_vm._v(" Save ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }