<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.delete.send || api.set.send ? '' : $modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">{{ conditions.is_primary ? 'Primary' : 'Secondary' }} Cards</div>
        <div class="plus-IOSIcon-container" @click="''">
          <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992" />
        </div>
      </div>

      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" v-for="card in cards" :key="card.real_id" @click="setPriority(card.real_id)">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <vb-icon class="" icon="Billing-MasterCard-IOSIcon"/>
                </div>
                <div class="d-flex flex-column">
                  <div class="headingInsideTable d-flex">
                    Mastercard 
                    <div class="s1-c-container">
                      <div class="s1-c" v-for="i in 4" :key="i" /> 
                    </div>
                    {{ card | get_property('digits','0000') }}
                  </div>
                  <div v-if="card.primary=='yes'" class="textInsideCard primaryColor">Primary</div>
                  <div v-else-if="card.secondary=='yes'" class="textInsideCard primaryColor">Seconday</div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard textAlignRight onRightSide mr-6px">
                  Expires {{ card | get_property('expiry_month','00') }}/{{ card | get_property('expiry_year','00') }}
                </div>
                
                <span @click.stop="deleteCard(card.real_id)">
                  <vb-icon icon="trash-IOSIcon" class="rightFacingArrow fillDanger" width="16.642" height="15.275" />
                </span>
              </div>
            </div>
            <vb-no-record v-if="cards.length==0" :text="api.cards.send?'':'There is no playlist'" :design="3">
              <vb-loading v-if="api.cards.send" slot="loading" />
            </vb-no-record>
          </div>

        </div>
      </div>
    </modal>
  </div>
</template>

<script>
// import { centrifugo } from '@/Centrifuge';
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
export default {
  name: 'SetCardsPriorityModal',
  props: {
    modalName: {
      type: String,
      default: 'SetCardsPriority',
    },
  },
  data(){
    return {
      api: {
        cards: this.$helperFunction.apiInstance({ }),
        set: this.$helperFunction.apiInstance({ send: '' }),
        delete: this.$helperFunction.apiInstance({ send: '' }),
      },
      response: {
        cards: []
      },
      conditions: {
        is_primary: true,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    cards(){ return this.response.cards?.filter?.(card=>card.real_id != (this.conditions.is_primary ? this.secondaryCard?.real_id : this.primaryCard?.real_id)) ?? [] },
    primaryCard(){ return this.response?.cards?.find?.(item=>item.primary=='yes') },
    secondaryCard(){ return this.response?.cards?.find?.(item=>item.secondary=='yes') },
  },
  methods: {
    onBeforeOpen(event){
      this.fetchCards()
      this.conditions.is_primary = !event?.params?.is_secondary
      AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
      // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
      // centrifugo.mothership.on(centrifugo.mothership.events.message,this.onNotification)
    },
    onBeforeClose(){
      this.response.cards=[]
      this.conditions.is_primary=true
      this.api.cards.reset()
      this.api.set.reset()
      this.api.delete.reset()
      AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
      // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
      // centrifugo.mothership.off(centrifugo.mothership.events.message,this.onNotification)
    },
    fetchCards(){
      let vm = this
      if(vm.api.cards.send) return;
      vm.api.cards.send=true
      VOIP_API.endpoints.billing_api.getcreditcards({  
        uid: vm.getCurrentUser?.uid 
      })
      .then(({ data: { data: cards } }) => {
        vm.response.cards = cards;
      })
      .finally(() => {
        vm.api.cards.send = false;
      });
    },
    deleteCard(card_real_id){
      let vm = this
      if(vm.api.delete.send || !card_real_id) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this Card`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete.send=card_real_id
            VOIP_API.endpoints.billing_api.deletecreditcard({
              id: card_real_id,
              accountcode: vm.getCurrentUser?.account,
              uid: vm.getCurrentUser?.uid,
            })
            .then(()=>{
              vm.fetchCards()
              vm.appNotify({
                message: 'Successfully Updated!',
                type: 'success',
              })
              vm.$emit('priority-set')
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.delete.send=''
            })
          }
        },
      });
    },
    setPriority(card_real_id){
      let vm = this
      if(vm.api.set.send || !card_real_id) return;
      vm.api.set.send=card_real_id
      VOIP_API.endpoints.billing_api.makedefaultcreditcard({
        id: card_real_id,
        accountcode: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
        flag: vm.conditions.is_primary ? 'primary' : 'secondary'
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.$emit('priority-set')
        vm.fetchCards()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.set.send=''
      })
    },
    onNotification(message) {
      if (message.data.action == "add" && message.data.section == "plan") {
        this.fetchCards();
      }
      if(message.data.action == "new_card_add" && message.data.section == "card" && message.data.flag == "new_card"){
        this.fetchCards();
      }
    },
  },
}
</script>

<style>

</style>