
import { VOIP_API } from "../index"

export const CONFERENCE_API_ENDPOINTS = {
  globalconf(){
    return VOIP_API.axios.voip.request({
      url: "global-conference",
      method: "GET",
    })
  },
  updateconferencepin(id,data={}){
    return VOIP_API.axios.voip.request({
      url: `conferences/${id}`,
      method: "PATCH",
      data,
    })
  },
  conferencenumberpool(data={}){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'conference_number_pool',
        type: 'relay',
      },
    })
  },
}