<template>
  <modal 
    :name="modalName" 
    height="100%" 
    width="100%" 
    class="dialer_animation center-zoomIn-popup mediaFullscreen-modal"
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()" 
  >
    <div class="mediaFullscreen-container">
      <div class="mediaFullscreen-header">
        <div class="close-popup cursor_pointer ml-2" @click="$modal.hide(modalName)">
          <b-icon icon="x" font-scale="2" />
        </div>
      </div>
      <div class="mediaFullscreen-body">
        <div v-if="navigation.prev" @click="navigate('prev')" class="pre-btn">
          <b-icon icon="chevron-left" font-scale="4" />
        </div>
        <div 
          :style="`background-image:url(${selectedImage})`" 
          class="media-itself"
        />
        <div v-if="navigation.next" @click="navigate('next')" class="next-btn">
          <b-icon icon="chevron-right" font-scale="4" />
        </div>
      </div>
      <div class="mediaFullscreen-footer">
        <div class="mediaFullscreen-slider">
          <div v-for="(image,index) in data.images" :key="index" class="media-container" :class="{selected:selected.index==index}">
            <div :style="`background-image:url(${image})`" @click="navigate('',index)" class="media-itself"></div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "MediaGalleryModal",
  props: {
    modalName: {
      type: String,
      default: 'MediaGallery'
    },
  },
  data() {
    return {
      selected: {
        index: 0,
      },
      data: {
        images: [],
      },
    };
  },
  computed: {
    selectedImage(){ return this.data.images[this.selected.index] },
    navigation() {
      return {
        next: !!this.data.images[this.selected.index+1],
        prev: !!this.data.images[this.selected.index-1],
      }
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.images=event?.params?.images || []
      this.selected.index=event?.params?.index || 0
      document.addEventListener('keydown',this.onkeydown)
    },
    onBeforeClose(){
      document.removeEventListener('keydown',this.onkeydown)
    },
    onkeydown(Event){
      if(Event?.key=='ArrowRight') this.navigate('next')
      if(Event?.key=='ArrowLeft')  this.navigate('prev')
    },
    navigate(type,index){
      if(type=='next') {
        if(this.navigation.next){
          this.selected.index=this.selected.index+1
        }
      } else if(type=='prev') {
        if(this.navigation.prev){
          this.selected.index=this.selected.index-1
        }
      } else {
        if(this.data.images[index]){
          this.selected.index=index
        }
      }
    },
  },
};
</script>
<style lang="scss">
.addable-memberLisit {
    ul {
        /* width */
        ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        }
    }
    ul {
        overflow-x: auto;
    }
    .avatar {
        &.user-avatar {
        overflow: visible;
        .member-close {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -3px;
            right: -3px;
        }
        }
    }
    .member-name {
        width: 18px;
        overflow: hidden;
        text-overflow: unset;
    }
}
</style>
