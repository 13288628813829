var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.delete_number.send || _vm.api.update_label.send ? '' : _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Number")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateLabel();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.update_label.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.update_label.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_label.name,
      expression: "forms.update_label.name"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.delete_number.send || _vm.api.update_label.send
    },
    domProps: {
      "value": _vm.forms.update_label.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_label, "name", $event.target.value);
      }
    }
  }), _vm.forms.update_label.submitted && _vm.$v.forms.update_label.name.$invalid || _vm.api.update_label.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_label.name.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), _vm._l(_vm.api.update_label.validation_errors.name, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.delete_number.send || _vm.api.update_label.send
    }
  }, [_vm.api.update_label.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1)])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Number")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Number")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.data.number.number)))])])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Label")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm.data.number.label))])])])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AssignTo', {
          type: 'setting'
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Assign to")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.isExtensionAssign ? _c('Info', {
    staticClass: "InfoComponentInsideAnroidIOSApp textAlignRight noImg mr-8px",
    attrs: {
      "is_image_show": false,
      "id": _vm.data.assign_to.extn,
      "name": _vm.data.assign_to.label,
      "sub_info": _vm.data.assign_to.extn
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallerIdShared', {
          number: _vm.data.number
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Caller ID Shared With")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
  }, [_vm._v(_vm._s(_vm.data.extensions.length) + " Users")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)])]), _vm.isExtensionAssign ? _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "type": "button",
      "disabled": _vm.api.update_extension.send || _vm.api.update_label.send
    },
    on: {
      "click": function ($event) {
        return _vm.updateExtension();
      }
    }
  }, [_vm.api.update_extension.send ? _c('vb-spinner') : [_vm._v("Unassign")]], 2) : _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "type": "button",
      "disabled": _vm.api.delete_number.send || _vm.api.update_label.send
    },
    on: {
      "click": function ($event) {
        return _vm.deletenumber();
      }
    }
  }, [_vm.api.delete_number.send ? _c('vb-spinner') : [_vm._v("Delete")]], 2)])])]], 2), _c('SelectExtensionModal', {
    on: {
      "selected": function ($event) {
        return _vm.updateExtension($event);
      }
    }
  }), _c('AddRemoveUsersModal', {
    attrs: {
      "modalName": 'CallerIdShared',
      "type": 'caller_id_shared'
    },
    on: {
      "update-list": function ($event) {
        return _vm.onCallerIDUpdated($event);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }