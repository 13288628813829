<template>
  <modal 
    :name="modalName" 
    class="aiTranscriptModal" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <div class="d-flex flex-wrap align-items-center justify-content-between mb-0 dialer-edit-header">
      <h2 class="dialer-edit-title newer mb-0">AI transcription</h2>
      <div>
        <button type="button" class="newCloseButton" @click="$modal.hide(modalName)"> 
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    <AudioTranscriptDummy 
      v-if="data.call.call_type=='audio' && data.call.soundFile && data.call.status!='Missed'"  
      :soundFile="data.call.soundFile" 
      :uniqueID="data.call.uniqueid"
      :call="data.call"
      :CallItemShow="CallItemShow"
      :twoPartsView="true"
    >
    </AudioTranscriptDummy>
      <!--  -->
  </modal>
</template>

<script>
import AudioTranscriptDummy from '../widgets/AudioTranscriptDummy.vue'
export default {
  name: 'AITranscriptModal',
  components: { 
    AudioTranscriptDummy 
  },
  props: {
    modalName: {
      type: String,
      default: 'AITranscript',
    },
    CallItemShow: {
      type: Boolean,
      defualt: true
    },
  },
  data() {
    return {
      data: {
        call: {},
      }
    }
  },
  methods: {
    onBeforeOpen(event) {
      this.data.call = event?.params?.call ?? {}
    },
    onBeforeClose() {
      this.data.call = {}
    },
  },
}
</script>

<style>

</style>