<template>
  <div>
    <div class="d-flex flex-column align-items-start mr-20px">
      <div class="lightGreyText-labelType">Task duration</div>
      <div class="mt-12px d-flex align-items-center">
        <b-button variant="link" class="p-0" :disabled="api.update_status.send || disabled" @click="updateStatus()">
          <vb-icon :icon="conditions.start?'taskduration-stop-icon':'taskduration-start-icon'" height="45px" width="45px" />
        </b-button>
        <p class="lightGreyText-labelType mb-0 ml-16px" v-if="conditions.start">{{ secs | duration_format }}</p>
      </div>
    </div>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
export default {
  name: 'TaskTimer',
  inject: [
    'appNotify',
  ],
  props: {
    taskId: {
      type: [String,Number],
      default: '',
    },
    started: {
      type: Boolean,
      default: false,
    },
    seconds: {
      type: [Number,String],
      default: 0,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      api: {
        update_status: this.$helperFunction.apiInstance(),
      },
      conditions: {
        start: false,
      },
      secs: 0,
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
  },
  watch: {
    "conditions.start": {
      immediate: true,
      handler(start){
        if(start){
          this.timer()
        }
      },
    },
  },
  methods: {
    timer() {
      let vm = this
      if(this.conditions.start) {
        this.secs=this.secs+1
        setTimeout(()=>{
          vm.timer()
        },1*1000)
      }
    },
    async updateStatus(){
      if(this.api.update_status.send) return;
      try {
        this.api.update_status.send=true
        const { data: seconds } = await VOIP_API.endpoints.tasklist.timerUpdate({
          task_id: this.taskId,
          accountcode: this.getCurrentUser.account,
          status: this.conditions.start?0:1
        })
        this.conditions.start=!this.conditions.start
        await this.$nextTick()
        this.secs=seconds
        this.$emit('update-timer')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.update_status.send=false
      }
    },
  },
  mounted(){
    this.conditions.start=this.started
    this.secs=parseInt(this.seconds) || 0
  },
}
</script>

<style>

</style>