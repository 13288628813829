<template>
  <div>
    
    <div class="acceptedCallBar">
      <div class="dialer-incommingVideoCall-info d-flex align-items-center">
        <div class="dialer-user-dp mr-2">
          <vb-icon
            class="cursor_pointer"
            icon="video-call-chat-icon"
            width="40"
            height="40"
          />
          <!-- {{ callObjInfo.image }} -->
        </div>
        <div class="dialer-user-bio">
          <h6 class="text-capitalize mb-0">{{ callObjInfo.name }}</h6>
          <p class="text-capitalize mb-0">{{ callObjInfo.sub_info }}</p>
          <div class="d-flex align-items-center">
            <p class="m-0 pr-2">{{ videoCall.time | time_audio }}</p>
          </div>
        </div>
      </div>
      <div class="d-flex jitsiIncomming_call_bar_actions">

        <span class="md-mar-left">
          <vb-icon
            class="cursor_pointer with-shadow"
            @click="$emit('call-play')"
            :icon="isActiveCall ? 'video-call-play-icon' : 'video-call-pause-icon' "
            width="40"
            height="40"
          />
        </span>
        
      </div>
    </div>
  </div>
</template>

<script>
import { call_info } from '@/mixin'
import { mapGetters } from 'vuex'
export default {
  name: 'JitsiCalls',
  mixins: [
    call_info
  ],
  props: {
    videoCall: {
      type: Object,
      default: ()=>({})
    },
  },
  computed: {
    
    ...mapGetters([
      'activeCall',
    ]),
    callObj(){ return this.videoCall },
    isActiveCall(){
      const identity = this.videoCall.room || this.videoCall.uuid
      const active_identity = this.activeCall?.room || this.activeCall?.uuid
      return active_identity==identity
    },
  },
}
</script>

<style>

</style>