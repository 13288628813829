var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Employee reports ")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Generate employee-specific reports for their personal time off ")]), _c('div', {
    staticClass: "dropdownsContainer flex-column align-items-start"
  }, [_c('b-dropdown', {
    staticClass: "primaryBorderDropdown big",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "nameWithImage insideDropdown"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          staticClass: "circle",
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Mellissa")])]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('div', {
    staticClass: "nameWithImage insideDropdown"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/1.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Mellissa")])])]), _c('b-dropdown-item', [_c('div', {
    staticClass: "nameWithImage insideDropdown"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/2.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Mellissa")])])]), _c('b-dropdown-item', [_c('div', {
    staticClass: "nameWithImage insideDropdown"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/3.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Mellissa")])])])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown big mt-16px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("Date Range")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Date Range ")])], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-16px mr-0"
  }, [_vm._v(" Apply Date Range ")]), _vm._m(0)], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-center mt-5"
  }, [_c('canvas', {
    attrs: {
      "id": "employee-report-chart"
    }
  })]);

}]

export { render, staticRenderFns }