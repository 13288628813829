<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-users-setting">
      <!-- <div class="dialer-flex">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Users</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title">
          Users
          <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'users' })" class="cursor_pointer infoKbIcon md-mar-left" />
        </h2>
        <div>
          <button class="newButton mr-0" :disabled="api.check_service.send" @click="checkservice()">
            <vb-icon icon="squared-add-icon" height="38px" width="38px" />
            <span class="newButton-textPart d-flex align-items-center justify-content-center position-relative">
              <vb-spinner v-if="api.check_service.send" />
              <template v-else>
                <span>Add user</span>
              </template>
            </span>
          </button>
        </div>
      </div> -->
      <div class="settingsFirstSection">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Users</h2>
          </div>
          <div>
            <button class="newButton mr-0" :disabled="api.check_service.send" @click="checkservice()">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart d-flex align-items-center justify-content-center position-relative">
                <vb-spinner v-if="api.check_service.send" />
                <template v-else>
                  <span>Add user</span>
                </template>
              </span>
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
            Users
            <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'users' })" class="cursor_pointer infoKbIcon md-mar-left" />
          </h2>
          <div class="settingsFirstSection-right">
            <div class="d-flex justify-content-end align-items-center mt-0">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD ul-width-fit-content">
                <b-dropdown no-caret right  class=" dd-text-dark dd-with-checkbox">
                  <template #button-content>
                    <vb-svg name="filter-icon" class="filterDD-icon" width="19" height="18" viewBox="0 0 19 18" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  </template>
                  <b-dropdown-form class="">
                    <b-form-checkbox class="makeCheckBoxesSquare" v-model="GridView">
                      <span class="ml-3">Grid view</span>
                    </b-form-checkbox>
                    <b-form-checkbox class="makeCheckBoxesSquare" v-model="filter.list.active_users">
                      <span class="ml-3">Active users</span>
                    </b-form-checkbox>
                    <b-form-checkbox class="makeCheckBoxesSquare" v-model="filter.list.inactive_users">
                      <span class="ml-3">Inactive users</span>
                    </b-form-checkbox>
                    <b-form-checkbox class="makeCheckBoxesSquare" v-model="filter.list.invitations">
                      <span class="ml-3">Invitations</span>
                    </b-form-checkbox>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox">
                <b-input type="text" placeholder="Search user" v-model.lazy="filter.list.search" />
              </div>
              <button class="newButton mr-0" :disabled="api.check_service.send" @click="checkservice()">
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart d-flex align-items-center justify-content-center position-relative">
                  <vb-spinner v-if="api.check_service.send" />
                  <template v-else>
                    <span>Add user</span>
                  </template>
                </span>
              </button>
            </div>
          </div>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">Instantly add, remove, and effectively manage users within the organization.</span>
        <!-- <div class="d-flex justify-content-end align-items-center w-50">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD ul-width-fit-content">
            <b-dropdown no-caret right  class=" dd-text-dark dd-with-checkbox">
              <template #button-content>
                <vb-svg name="filter-icon" width="19" height="18" viewBox="0 0 19 18" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              </template>
              <b-dropdown-form class="">
                <b-form-checkbox class="makeCheckBoxesSquare" v-model="GridView">
                  <span class="ml-3">Grid view</span>
                </b-form-checkbox>
                <b-form-checkbox class="makeCheckBoxesSquare" v-model="filter.list.active_users">
                  <span class="ml-3">Active users</span>
                </b-form-checkbox>
                <b-form-checkbox class="makeCheckBoxesSquare" v-model="filter.list.inactive_users">
                  <span class="ml-3">Inactive users</span>
                </b-form-checkbox>
                <b-form-checkbox class="makeCheckBoxesSquare" v-model="filter.list.invitations">
                  <span class="ml-3">Invitations</span>
                </b-form-checkbox>
              </b-dropdown-form>
            </b-dropdown>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
            <b-input type="text" placeholder="Search user" v-model.lazy="filter.list.search" />
          </div>
        </div> -->
      </div>
      <template v-if="GridView">
        <div class="settingsGridView-container mt-32px">
          <div class="settingsGridView-item" v-for="data in filterList" :key="data.id || data.voipaccount">
            <!-- user -->
            <div v-if="data.voipaccount" :class="`settingsGridView-accountStatus ${data.user.userRole != 0 || data.active_status != '1' ? 'showKaro' : 'naiShowKrna'}`">
              <div class="settingsGridView-accountStatus-text">
                <span v-if="data.active_status != '1'">Disabled</span>
                <span v-else-if="data.user.user_type == 'main'">Owner</span>
                <span v-else-if="data.user.userRole != 0">Admin</span>
                <div :class="`settingsGridView-accountStatus-circle ${data.active_status=='1'?'active':'inActive'}`"></div>
              </div>
            </div>
            <!-- user -->
            <vb-avatar 
              v-if="data.voipaccount" 
              :account="data.voipaccount" 
              :image="data.profile_img" 
              :name="data | get_property('user.display_name')" 
              :status_id="data | get_property('status')"  
            />
            <!-- queue service -->
            <vb-avatar v-if="data.queue_service" :id="data.id" />
            <!-- invite -->
            <vb-avatar v-if="data.object=='UserInvitation'" />
            <!-- user -->
            <div v-if="data.voipaccount" class="settingsGridView-display_name">{{ data | get_property('user.display_name') }}</div>
            <!-- invite -->
            <div v-if="data.object=='UserInvitation'" class="settingsGridView-display_name">
              {{ data | get_property('data.firstname') }} {{ data | get_property('data.lastname') }}
            </div>
            <!-- queue service -->
            <div v-if="data.queue_service" class="settingsGridView-display_name">{{ data | get_property('name') }}</div>
            <!-- invite -->
            <div v-if="data.object=='UserInvitation'" class="d-flex">
              <div>
                <span class="d-flex flex-column align-items-start justify-content-end text-center">
                  <div class="d-flex align-items-center text-center">
                    {{ data | invitationExpireText }}
                    <b-icon
                      class="cursor_pointer_no_hover ml-2"
                      icon="arrow-clockwise"
                      variant="primary"
                      :animation="api.retry_invitation.send.indexOf(data.id)>-1?'spin':''"
                      @click="retryInvitation(data)"
                    />
                    <b-icon
                      class="cursor_pointer_no_hover ml-2"
                      icon="trash"
                      variant="danger"
                      :animation="api.cancel_invitation.send.includes(data.id)?'spin':''"
                      @click="cancelInvitation(data)"
                    />
                  </div>
                </span>
              </div>
            </div>
            <div class="settingsGridView-email">
              <!-- user -->
              <span v-if="data.voipaccount">{{ data | usernameDisplay($store.getters.getCurrentUser.email) }}</span>
              <!-- queue service -->
              <span v-if="data.queue_service">{{ data.username }}</span>
              <!-- invite -->
              <span v-if="data.object=='UserInvitation'" v-b-tooltip.hover :title="getProperty(data, 'created_at.date', '').substring(0, 10)">{{ data.email }}</span>
            </div>
            <!-- user -->
            <template v-if="data.voipaccount">
              <div class="d-flex flex-column mt-1" v-if="data.userDid && data.userDid[0]">
                <div class="d-flex flex-column align-items-center">
                  <span class="text-center font-weight-bold min-width-160px">
                    <img class="country-img" width="45px" :src="data | get_property('userDid[0].did') | number_formater | flag_icon" />
                    {{ data | get_property('userDid[0].did') | number_formater }}
                  </span>
                  <div 
                    v-if="data.userDid.length>1" 
                    class="w-auto dialer-dropdown mt-2 dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" 
                    @click.stop="conditions.more=conditions.more==data.voipaccount?'':data.voipaccount"
                  >
                    <div class="px-2 more-numbers-tag cursor_pointer_no_hover">
                      <small v-if="conditions.more==data.voipaccount">- Hide</small>
                      <small v-else>+ {{ data.userDid.length-1 }} more</small>
                    </div>
                  </div>
                </div>
                <template v-if="conditions.more==data.voipaccount">
                  <div v-for="did in data.userDid.filter((item,index)=>index>0)" class="mt-2 d-flex align-items-center text-center" :key="did.did">
                    <img  class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                    <div class="d-flex align-items-center font-weight-bold sm-mar-left text-center justify-content-center">{{ did.did | number_formater }}</div>
                  </div>
                </template>
              </div>
              <span v-else class="settingsGridView-email">No numbers</span>
            </template>
            <!-- invite -->
            <template v-if="data.object=='UserInvitation'">
              <div class="d-flex flex-column mt-1" v-if="data.data && data.data.telephone">
                <div class="d-flex flex-column align-items-center">
                  <span class="text-center font-weight-bold min-width-160px">{{ data.data.telephone | number_formater }}</span>
                </div>
              </div>
              <span v-else class="settingsGridView-email">No numbers</span>
            </template>
            <!-- user -->
            <div v-if="data.voipaccount" class="d-flex settingsGridView-bottomIcons-container mt-20px">
              <div @click.stop="api.delete_user.send?'': data.active_status != '1' ? appNotify({ 
                  type: 'danger', 
                  message: 'Unable to proceed because this user is suspended' 
                }) : $modal.show('UsersGraphModal', { 
                  accountcode: data.voipaccount 
                })">
                <vb-icon 
                  icon="UserSettings-gridView-graph-icon" class="cursor_pointer_no_hover mr-20px" height="55px" width="55px"
                />
              </div>
              <div @click.stop="!data.voipaccount || api.delete_user.send?'': data.active_status != '1' ? appNotify({ 
                type: 'danger', 
                message: 'Unable to proceed because this user is suspended' 
              }) : userDetail(data.voipaccount)">
                <vb-icon 
                  icon="UserSettings-gridView-edit-icon" class="cursor_pointer_no_hover" height="55px" width="55px" 
                />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <template v-if="!$store.getters.getIsMobile">
          <vb-table 
            textNoRecord=" " 
            id="users-table" 
            class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" 
            :isListEmpty="isEmpty(filterList)" 
            :listLength="filterList.length" 
            :perPage="5" 
            :conditions="{ 
              loading: { 
                show: false 
              }, 
              pagination: { 
                show: false 
              }, 
            }"
          >
            <template slot="header">
              <tr>
                <th class="dialer-has-sort">
                  <span>
                    Name
                  </span>
                </th>
                <th class="dialer-has-sort">
                  <span>Numbers</span>
                </th>
                <!-- <th class="dialer-has-sort">
                  <span>
                    Role
                  </span>
                </th> -->
                <th class="dialer-has-sort dialer-col-right">
                  <!-- <span>Flow</span> -->
                </th>
              </tr>
            </template>
            <template #body>
              <tr 
                class="dialer-row-select" 
                v-for="data in filterList" 
                :key="data.id || data.voipaccount" 
                @click="!data.voipaccount || api.delete_user.send?'': data.active_status != '1' ? appNotify({ 
                  type: 'danger', 
                  message: 'Unable to proceed because this user is suspended' 
                }) : userDetail(data.voipaccount)"
              >
                <td class="dialer-row-title one">
                  <div class="d-flex align-items-center">
                    <!-- user -->
                    <Info class="w-fit-content"
                      v-if="data.voipaccount" 
                      is_suspended_show 
                      :id="data.voipaccount" 
                      :is_blf="false" 
                      :username="data | usernameDisplay($store.getters.getCurrentUser.email)" 
                    />
                    <!-- invite -->
                    <template v-if="data.object=='UserInvitation'">
                      <!-- <Info class="w-fit-content" v-if="data.object=='UserInvitation'" :name="`${data.data.firstname} ${data.data.lastname}`" :username="`${data.email}`" />
                      <span class="AdmintaggedVersion mb-0 ml-16px">
                        {{ data | invitationExpireText }}
                        <b-icon
                          class="cursor_pointer_no_hover ml-2"
                          icon="arrow-clockwise"
                          variant="light"
                          :animation="api.retry_invitation.send.includes(data.id)?'spin':''"
                          @click="retryInvitation(data)"
                        />
                        <b-icon
                          class="cursor_pointer_no_hover ml-2"
                          icon="trash"
                          variant="danger"
                          :animation="api.cancel_invitation.send.includes(data.id)?'spin':''"
                          @click="cancelInvitation(data)"
                        />
                      </span> -->
                      <Info class="w-fit-content" v-if="data.object=='UserInvitation'" :name="`${data.data.firstname} ${data.data.lastname}`" :username="`${data.email}`">
                        <template #sub-info>
                          <span class="verySmallText">
                            {{ data | invitationExpireText }}
                            <b-icon
                              class="cursor_pointer_no_hover ml-2"
                              icon="arrow-clockwise"
                              variant="dark"
                              :animation="api.retry_invitation.send.includes(data.id)?'spin':''"
                              @click="retryInvitation(data)"
                            />
                            <b-icon
                              class="cursor_pointer_no_hover ml-2"
                              icon="trash-fill"
                              variant="danger"
                              :animation="api.cancel_invitation.send.includes(data.id)?'spin':''"
                              @click="cancelInvitation(data)"
                            />
                          </span>
                        </template>
                      </Info>
                    </template>
                    <!-- queue service -->
                    <Info class="w-fit-content" v-if="data.queue_service" :id="data.id" />

                    <template v-if="data.voipaccount">
                      <span v-if="data.user.user_type == 'main'" class="OwnertaggedVersion mb-0 ml-16px">Owner</span>
                      <span v-else-if="data.user.userRole != 0" class="AdmintaggedVersion mb-0 ml-16px">Admin</span>
                    </template>
                  </div>
                </td>
                <td class="dialer-col-left font-weight-bold two">
                  <!-- user -->
                  <template v-if="data.voipaccount">
                    <div class="d-flex flex-column" v-if="data.userDid && data.userDid[0]">
                      <div class="d-flex align-items-center">
                        <span class="sm-mar-left font-weight-bold min-width-160px">
                          <span class="mr-1">
                            <img class="country-img" width="45px" :src="data | get_property('userDid[0].did') | number_formater | flag_icon" />
                          </span>
                          {{ data | get_property('userDid[0].did') | number_formater }}
                        </span>
                        <div 
                          v-if="data.userDid.length>1" 
                          class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" 
                          @click.stop="conditions.more=conditions.more==data.voipaccount?'':data.voipaccount"
                        >
                          <div class="px-2 rounded more-numbers-tag">
                            <small v-if="conditions.more==data.voipaccount">- Hide</small>
                            <small v-else>+ {{ data.userDid.length-1 }} more</small>
                          </div>
                        </div>
                      </div>
                      <template v-if="conditions.more==data.voipaccount">
                        <template v-for="did in data.userDid.filter((item,index)=>index>0)" >
                          <div class="mt-2" :key="did.did">
                            <div class="d-flex align-items-center sm-mar-left">
                              <span class="mr-2 d-flex align-items-center">
                                <img  class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                              </span>
                              {{ did.did | number_formater }}
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                    <span v-else class="text-center"> - </span>
                  </template>
                  <!-- invite -->
                  <template v-if="data.object=='UserInvitation'">
                    <div class="d-flex flex-column" v-if="data.data && data.data.telephone">
                      <div class="d-flex align-items-center">
                        <span class="sm-mar-left font-weight-bold min-width-160px">
                          <span class="mr-1">
                            <img class="country-img" width="45px" :src="data.data.telephone | number_formater | flag_icon" />
                          </span>
                          {{ data.data.telephone | number_formater }}
                        </span>
                      </div>
                    </div>
                    <span v-else class="text-center"> - </span>
                  </template>
                  <!-- queue service -->
                  <template v-if="data.queue_service">
                    <span class="text-center"> - </span>
                  </template>
                </td>
                <!--<td class="dialer-row-title three">
                  <!~~ user ~~>
                  <template v-if="data.voipaccount">
                    <span v-if="data.user.user_type == 'main'" class="OwnertaggedVersion ml-1">Owner</span>
                    <span v-else-if="data.user.userRole != 0" class="AdmintaggedVersion">Admin</span>
                  </template>
                </td>-->
                <td class="dialer-col-right four">
                  <!-- user -->
                  <span v-if="data.voipaccount" class="d-flex align-items-center">
                    <span class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-16px showOnly-OnHover-insideTable" > 
                      Configure
                    </span>
                    <div class="dialer-row-title three">
                      <button 
                        class="dialer-button dialer-button-delete-alt buttonNothing" 
                        type="button" 
                        @click.stop="api.delete_user.send?'': data.active_status != '1' ? appNotify({ 
                          type: 'danger', 
                          message: 'Unable to proceed because this user is suspended' 
                        }) : $modal.show('UsersGraphModal', { 
                          accountcode: data.voipaccount 
                        })"
                      >
                        <vb-icon icon="graph-icon" class="graphIcon" height="40.501" width="41.374" />
                      </button>
                    </div>
                  </span>
                  <!-- invite -->
                  <!-- <div v-if="data.object=='UserInvitation'" class="dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight">
                    <span class="d-flex flex-column align-items-start justify-content-end">
                      <div class="d-flex align-items-center ">
                        {{ data | invitationExpireText }}
                        <b-icon
                          class="cursor_pointer_no_hover ml-2"
                          icon="arrow-clockwise"
                          variant="primary"
                          :animation="api.retry_invitation.send.includes(data.id)?'spin':''"
                          @click="retryInvitation(data)"
                        />
                      </div>
                    </span>
                  </div> -->
                  <!-- queue service -->
                  <b-spinner v-if="data.queue_service" variant="primary" type="grow" small label="Spinning" />
                </td>
              </tr>
            </template>
            <template #loading>
              <tr v-for="n in 5" :key="n">
                <td>
                  <div class="d-flex">
                    <div class="latestShimmerDesign mr-2" style="height:35px;width:35px;"></div>
                    <div class="d-flex flex-column justify-content-between">
                      <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
                      <div class="latestShimmerDesign mt-1" style="height:16px;width:30px"></div>
                    </div>
                  </div>
                </td>
                <td>
                  <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                </td>
                <td>
                  <div class="d-flex">
                    <div class="latestShimmerDesign mr-2" style="height:20px;min-width:40px;"></div>
                    <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                  </div>
                </td>
                <td>
                  <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                </td>
                <td>
                  <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
                </td>
                <td class="dialer-col-right">
                  <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
                </td>
              </tr>
            </template>
          </vb-table>
        </template>
        <template v-if="$store.getters.getIsMobile">
          <vb-table textNoRecord=" " class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover smallerFontSizes" :isListEmpty="isEmpty(filterList)" :listLength="filterList.length" :perPage="5" :conditions="{ loading: { show: false }, pagination: { show: false }, }">
            <!--<template slot="header">
              <tr>
                <th class="dialer-has-sort">
                  <span>
                    Name
                  </span>
                </th>
                <!~~ <th class="dialer-has-sort">
                  <span>Username / Email</span>
                </th> ~~>
                <th class="dialer-has-sort">
                  <span>Role</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span class="for-flex-column">
                    <span>Status</span>
                  </span>
                </th>
              </tr>
            </template>-->
            <template #body>
              <!--<tr v-for="data in filterList" :key="data.id || data.voipaccount" 
                @click="!data.voipaccount || api.delete_user.send?'': data.active_status != '1' ? appNotify({ 
                  type: 'danger', 
                  message: 'Unable to proceed because this user is suspended' 
                }) : userDetail(data.voipaccount)"
              >
                <td class="avatar-container">
                  <!~~<!~~ user ~~>
                  <vb-avatar 
                    v-if="data.voipaccount" 
                    :image="data.profile_img" 
                    :name="data | get_property('user.display_name')" 
                    :status_id="data | get_property('status')" 
                    :account="data.voipaccount" 
                  />
                  <!~~ invite ~~>
                  <vb-avatar v-if="data.object=='UserInvitation'" />
                  <!~~ queue service ~~>
                  <vb-avatar v-if="data.queue_service" :id="data.id" />~~>
                  <div class="d-flex align-items-start flex-column">
                    <!~~ user ~~>
                    <Info class="w-fit-content"
                      v-if="data.voipaccount" 
                      is_suspended_show 
                      :id="data.voipaccount" 
                      :is_blf="false" 
                      :username="data | usernameDisplay($store.getters.getCurrentUser.email)" 
                    />
                    <!~~ invite ~~>
                    <Info class="w-fit-content" v-if="data.object=='UserInvitation'" :name="`${data.data.firstname} ${data.data.lastname}`" :username="`${data.email}`" />
                    <!~~ queue service ~~>
                    <Info class="w-fit-content" v-if="data.queue_service" :id="data.id" />

                    <template v-if="data.voipaccount">
                      <span v-if="data.user.user_type == 'main'" class="OwnertaggedVersion mb-0 mt-8px">Owner</span>
                      <span v-else-if="data.user.userRole != 0" class="AdmintaggedVersion mb-0 mt-8px">Admin</span>
                    </template>
                  </div>
                </td>
                <!~~<td>
                  <div class="d-flex flex-column">
                    <!~~ user ~~>
                    <template v-if="data.voipaccount">
                      <div class="name">{{ data | get_property('user.display_name') }}</div>
                      <div class="email">{{data | usernameDisplay($store.getters.getCurrentUser.email)}}</div>
                    </template>
                    <!~~ invite ~~>
                    <template v-if="data.object=='UserInvitation'">
                      <div class="name">{{ `${data.data.firstname} ${data.data.lastname}` }}</div>
                      <div class="email">{{ `${data.email}` }}</div>
                    </template>
                    <!~~ queue service ~~>
                    <template v-if="data.queue_service">
                      <div class="name">{{ `${data.name}` }}</div>
                    </template>
                  </div>
                </td>~~>
                <td>
                  <!~~ user ~~>
                  <template v-if="data.voipaccount">
                    <div class="name">{{ data | get_property('extn') }}</div>
                    <!~~ <div v-if="data.user.userRole != 0" class="email">Admin</div> ~~>
                  </template>
                  <!~~ invite ~~>
                  <template v-if="data.object=='UserInvitation'">
                    {{ data | invitationExpireText }}
                    <b-icon
                      class="cursor_pointer_no_hover ml-2"
                      icon="arrow-clockwise"
                      variant="primary"
                      :animation="api.retry_invitation.send.includes(data.id)?'spin':''"
                      @click="retryInvitation(data)"
                    />
                    <b-icon
                      class="cursor_pointer_no_hover ml-2"
                      icon="trash"
                      variant="danger"
                      :animation="api.cancel_invitation.send.includes(data.id)?'spin':''"
                      @click="cancelInvitation(data)"
                    />
                  </template>
                  <!~~ queue service ~~>
                  <template v-if="data.queue_service">
                    <div class="name">{{ `${data.name}` }}</div>
                  </template>
                </td>
                <td>
                  <!~~ user ~~>
                  <template v-if="data.voipaccount">
                    <div class="dialer-row-title three">
                      <!~~ <button 
                        class="dialer-button dialer-button-delete-alt buttonNothing" 
                        type="button" 
                        @click.stop="api.delete_user.send?'': data.active_status != '1' ? appNotify({ 
                          type: 'danger', 
                          message: 'Unable to proceed because this user is suspended' 
                        }) : $modal.show('UsersGraphModal', { 
                          accountcode: data.voipaccount 
                        })"
                      >
                        <vb-icon icon="graph-icon" class="graphIcon" height="40.501" width="41.374" />
                      </button> ~~>
                    </div>
                  </template>
                </td>
              </tr>-->
              <tr 
                class="dialer-row-select" 
                v-for="data in filterList" 
                :key="data.id || data.voipaccount" 
                @click="!data.voipaccount || api.delete_user.send?'': data.active_status != '1' ? appNotify({ 
                  type: 'danger', 
                  message: 'Unable to proceed because this user is suspended' 
                }) : userDetail(data.voipaccount)"
              >
                <td class="left">
                  <div class="">
                    <div class="mb-8px">
                      <!-- user -->
                      <Info class="w-fit-content"
                        v-if="data.voipaccount" 
                        is_suspended_show 
                        :id="data.voipaccount" 
                        :is_blf="false" 
                        :username="data | usernameDisplay($store.getters.getCurrentUser.email)" 
                      />
                      <!-- invite -->
                      <template v-if="data.object=='UserInvitation'">
                        <Info class="w-fit-content" v-if="data.object=='UserInvitation'" :name="`${data.data.firstname} ${data.data.lastname}`" :username="`${data.email}`">
                          <template #sub-info>
                            <span class="verySmallText">
                              {{ data | invitationExpireText }}
                              <b-icon
                                class="cursor_pointer_no_hover ml-2"
                                icon="arrow-clockwise"
                                variant="dark"
                                :animation="api.retry_invitation.send.includes(data.id)?'spin':''"
                                @click="retryInvitation(data)"
                              />
                              <b-icon
                                class="cursor_pointer_no_hover ml-2"
                                icon="trash-fill"
                                variant="danger"
                                :animation="api.cancel_invitation.send.includes(data.id)?'spin':''"
                                @click="cancelInvitation(data)"
                              />
                            </span>
                          </template>
                        </Info>
                      </template>
                      <!-- queue service -->
                      <Info class="w-fit-content" v-if="data.queue_service" :id="data.id" />
                    </div>
                  </div>

                  <!-- user -->
                  <template v-if="data.voipaccount">
                    <div class="d-flex flex-column mt-8px" v-if="data.userDid && data.userDid[0]">
                      <div class="d-flex align-items-center">
                        <span class="font-weight-bold">
                          <span class="mr-1">
                            <img class="country-img" width="45px" :src="data | get_property('userDid[0].did') | number_formater | flag_icon" />
                          </span>
                          <span class="textInsideCard">{{ data | get_property('userDid[0].did') | number_formater }}</span>
                        </span>
                        <div 
                          v-if="data.userDid.length>1" 
                          class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" 
                          @click.stop="conditions.more=conditions.more==data.voipaccount?'':data.voipaccount"
                        >
                          <div class="px-2 rounded more-numbers-tag">
                            (<small v-if="conditions.more==data.voipaccount">- Hide</small>
                            <small v-else>+ {{ data.userDid.length-1 }} more</small>)
                          </div>
                        </div>
                      </div>
                      <template v-if="conditions.more==data.voipaccount">
                        <template v-for="did in data.userDid.filter((item,index)=>index>0)" >
                          <div class="mt-2" :key="did.did">
                            <div class="d-flex align-items-center">
                              <span class="mr-2 d-flex align-items-center">
                                <img  class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                              </span>
                              <span class="textInsideCard">{{ did.did | number_formater }}</span>
                            </div>
                          </div>
                        </template>
                      </template>
                    </div>
                    <div v-else class="textInsideCard mt-4px"> No number </div>
                  </template>
                  <!-- invite -->
                  <template v-if="data.object=='UserInvitation'">
                    <div class="d-flex flex-column mt-8px" v-if="data.data && data.data.telephone">
                      <div class="d-flex align-items-center">
                        <span class="sm-mar-left font-weight-bold min-width-160px">
                          <span class="mr-1">
                            <img class="country-img" width="45px" :src="data.data.telephone | number_formater | flag_icon" />
                          </span>
                          <span class="textInsideCard">{{ data.data.telephone | number_formater }}</span>
                        </span>
                      </div>
                    </div>
                    <div v-else class="textInsideCard mt-4px"> No number </div>
                  </template>
                  <!-- queue service -->
                  <template v-if="data.queue_service">
                    <div class="textInsideCard mt-4px"> No number </div>
                  </template>
                </td>
                <td class="dialer-col-right right">
                  <template v-if="data.voipaccount">
                    <span v-if="data.user.user_type == 'main'" class="OwnertaggedVersion mb-0 ml-0">Owner</span>
                    <span v-else-if="data.user.userRole != 0" class="AdmintaggedVersion mb-0 ml-0">Admin</span>
                  </template>
                  <!-- queue service -->
                  <b-spinner v-if="data.queue_service" variant="primary" type="grow" small label="Spinning" />
                </td>
              </tr>
            </template>
          </vb-table>
        </template>
      </template>
      <InvitePeopleModal @load="fetchInvitations()" />
      <!-- <AddUserModal @interface="afterAdding()" /> -->
      <EditUserModal />
      <!-- <UsersGraphModal modalName="UsersGraphModal" /> -->
      <GraphModal modalName="UsersGraphModal" />
      <!-- <AddOrganizationUserModal @after-adding="afterAdding()" /> -->
      <AddUserANDSubUserModal @user-added="afterAdding()" />
    </section>
  </div>
</template>

<script>
// import AddUserModal from "../Modals/AddUserModal";
import EditUserModal from "../Modals/EditUserModal.vue";
import GraphModal from "../Modals/GraphModal.vue";
// import UsersGraphModal from '../Modals/UsersGraphModal.vue';
// import AddOrganizationUserModal from "../Modals/settings/AddOrganizationUserModal";
import AddUserANDSubUserModal from "../Modals/AddUserANDSubUserModal.vue";
import InvitePeopleModal from "../Modals/settings/InvitePeopleModal.vue";
import { $fn, VOIP_API } from "../../utils";
import Info from "../Lists/Info.vue";
import _ from 'lodash'
import moment from "moment";
import { date_current } from '@/filter';
import { check_service } from '@/mixin';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
// import { centrifugo } from '@/Centrifuge';
function get_username(data,email=''){
  return ((data?.user?.email2 || data?.user?.email) === email ? data.user.ac_user : (data?.user?.ac_user != data?.user?.email) ? data?.user?.ac_user : data?.user?.email2 || data?.user?.email || data?.user?.ac_user) ?? ''
}
export default {
  name: "UsersSettings",
  mixins: [
    check_service
  ],
  components: {
    // AddUserModal,
    EditUserModal,
    // UsersGraphModal,
    GraphModal,
    // AddOrganizationUserModal,
    AddUserANDSubUserModal,
    InvitePeopleModal,
    Info,
  },
  data() {
    return {
      filter: {
        search: '',
        list: {
          search: '',
          invitations: true,
          active_users: true,
          inactive_users: true,
        }
      },
      selected: {
        table: "", //user
      },
      response: {
        invitations: [],
      },
      api: {
        update_role: this.$helperFunction.apiInstance(),
        invitations: this.$helperFunction.apiInstance(),
        retry_invitation: this.$helperFunction.apiInstance({ send: [] }),
        cancel_invitation: this.$helperFunction.apiInstance({ send: [] }),
        users: this.$helperFunction.apiInstance(),
        delete_user: this.$helperFunction.apiInstance(),
        check_service: this.$helperFunction.apiInstance(),
      },
      conditions: {
        more: '',
      },
      GridView: false,
    };
  },
  inject:['isEmpty', 'getProperty','getVoipUsers','appNotify'],
  computed: {
    filterList(){
      const search = this.filter.list.search.toLowerCase().trim();
      const filters = this.filter.list
      const invitations = this.response.invitations
      const users = Object.values(this.$store.state.common.voipusers).filter(item=>!!item.user)
      const user_email = this.$store.getters.getCurrentUser.email
      return _.orderBy([...users,...invitations,this.$store.getters.addServiceQueue].filter((val) =>{
        if(!val) return false
        else if(val.queue_service) {
          if(val.type=='user') return `${val.name || ''} ${val.username || ''}`.toLowerCase().trim().includes(search)
          else return false
        }
        else return `${val.email || ''} ${val.extn || ''} ${val?.user?.display_name || ''} ${get_username(val,user_email)}`.toLowerCase().trim().includes(search) && (val.active_status == '0' && filters.inactive_users || val.active_status == '1' && filters.active_users || !val.active_status && filters.invitations)
      }), ['name','user.display_name'], ['asc','asc'])
    },
    activeUsers(){
      const users = Object.values(this.$store.state.common.voipusers).filter(item=>!!item.user);
      const filter_users = users.filter((val) => val.active_status=='1');
      return filter_users
    },
    inActiveUsers(){
      const users = Object.values(this.$store.state.common.voipusers).filter(item=>!!item.user);
      const filter_users = users.filter((val) => val.active_status=='0');
      return filter_users
    },
    searchedUsers() {
      const search = this.filter.search.toLowerCase().trim();
      const users = this.activeUsers;
      const filter_users = users.filter((val) => `${val.extn} ${val.user.display_name} ${val.user.email2 || val.user.email || val.user.ac_user}`.toLowerCase().trim().includes(search) && val.active_status=='1');
      return filter_users
    },
    searchedInactiveUsers() {
      const search = this.filter.search.toLowerCase().trim();
      const users = this.inActiveUsers;
      const filter_users = users.filter((val) => `${val.extn}_${val.user.ac_user}_${val.user.display_name}_${val.user.email}_${val.user.email2}`.toLowerCase().trim().includes(search) && val.active_status=='0');
      return filter_users
    },
    searchedInvitation() {
      const search = this.filter.search.toLowerCase().trim();
      const invitations = this.response.invitations;
      const filter_invitations = invitations.filter((val) => `${val.email}`.toLowerCase().trim().includes(search));
      return filter_invitations
    },
    options() {
      return [
        {
          value: "user",
          text: "Active users",
        },
        {
          value: "inactive",
          text: "Inactive users",
        },
        {
          value: "invited",
          text: "Invited users",
        },
      ];
    },
  },
  watch: {
    searchUsersWithFirstLastUserNameEmail: {
      immediate: true,
      handler(new_value) {
        this.pagination_data = new_value;
      },
    },
  },
  filters: {
    invitationExpireText(data){
      const expire_time = data.expire_time * 1000
      if(moment.utc(expire_time).diff(moment.utc()) > 0){
        return `Pending - Expire ${date_current(expire_time)}`
      } else {
        return 'Expired'
      }
    },
    invitaionText(data){
      // console.log('run',data.email,moment(data.expire_time).calendar().localeCompare())
      // const current_date = moment();
      // const message_date = moment(data.expire_time);
      // const duration = moment.duration({ from: message_date, to: current_date })
      // console.log(duration)
      if(moment(data.expire_time * 1000).calendar().localeCompare()==-1) return 'Expired'
      if(data?.status=='failed' && data?.invitation_status=='expired') return 'Expired'  
      else return data.status
    },
    
    firstCharacter(value) {
      if (!value) return "";
      return value.charAt(0);
    },
    userRole: function (value) {
      if (!value) return "";
      if (value === "3") return "Admin";
      return "User";
    },
    usernameDisplay(value,email=''){
      return get_username(value,email)
    },
  },
  methods: {
    
    checkservice(){
      let vm = this
      if(vm.api.check_service.send) return;
      vm.api.check_service.send=true
      vm.checkService({
        service: 'user',
        only_check: true,
        then(){
          // console.log('data',data)
          vm.$modal.show('AddUserANDSubUserModal')
        },
        catch(ex){
          vm.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        },
        finally(){
          vm.api.check_service.send=false
        }
      })
    },
    deleteUser(user) {
      let vm = this;
      if(vm.api.delete_user.send) return;
      vm.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to delete "${user.user.display_name}" user.`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback({confirm}) {
          if (confirm) {
            vm.api.delete_user.send=true
            VOIP_API.endpoints.users.delete({
              id: user.voipaccount,
              uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
              accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
            }).then(()=>{
              setTimeout(()=>{
                vm.getVoipUsers()
              },15 * 1000)
              vm.appNotify({
                message: 'Successfully Deleted',
                type: 'success',
              })
            }).catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(()=>{
              vm.api.delete_user.send=false
            })
          }
        },
      });
    },
    fetchUsers() {
      let vm = this
      if(vm.api.users.send) return;
      vm.api.users.send=true
      VOIP_API.endpoints.users.list().then(({ data: { data: voipusers } })=>{
        if(_.isEmpty(voipusers)) throw 'Users is Empty'
        return $fn.manipulateVoipUsers(voipusers,vm.$store.state.common.voipusers)
      }).then((voipusers)=>{
        return _.keyBy(voipusers,'voipaccount')
      }).then((voipusers)=>{
        vm.$store.commit("setVoipUsers", voipusers);
      }).catch((ex)=>{
        if(ex.own_message){
          vm.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      }).finally(()=>{
        vm.api.users.send=false
      })
    },
    afterAdding() {
      let vm = this;
      setTimeout(() => {
        vm.getVoipUsers();
        vm.fetchInvitations();
      }, 5 * 1000);
    },
    userDetail(account){
      let vm = this
      vm.$modal.show("EditUserModal", { accountcode: account })
      // if(vm.api.user_detail.send) return;
      // vm.api.user_detail.send=true
      // VOIP_API.endpoints.users.detail(account).then(({ data: detail })=>{
      //   vm.$modal.show("EditUserModal", { user: detail });
      // }).catch((ex)=>{
      //   vm.appNotify({
      //     message: ex.own_message,
      //     type: 'error',
      //   })
      // }).finally(()=>{
      //   vm.api.user_detail.send=false
      // })
    },
    updateUserRole(account){
      let vm = this
      if(vm.api.update_role.send) return;
      vm.api.update_role.send=true
      VOIP_API.endpoints.users.updateuserrole(account).then(({ data: { data: voipusers } })=>{
        if(_.isEmpty(voipusers)) throw 'Users is Empty'
        return $fn.manipulateVoipUsers(voipusers,vm.$store.state.common.voipusers)
      }).then((voipusers)=>{
        return _.keyBy(voipusers,'voipaccount')
      }).then((voipusers)=>{
        vm.$store.commit("setVoipUsers", voipusers);
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.update_role.send=false
      })
    },
    fetchInvitations(){
      let vm = this
      if(vm.api.invitations.send) return;
      vm.api.invitations.send=true
      VOIP_API.endpoints.user.userinvitationslist({
        uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
      }).then(({ data, })=>{
        vm.response.invitations = data?.data ?? [];
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.invitations.send=false
      })
    },
    async retryInvitation(data){
      const invitation_id = data.id
      const email = data.email
      if(this.api.retry_invitation.send.indexOf(invitation_id)>-1) return;
      try {
        this.api.retry_invitation.send.push(invitation_id)
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to resend the invitation`,
          button: {
            no: "No",
            yes: "Yes",
          },
        });
        if(!confirm) return;
        await VOIP_API.endpoints.user.retryuserinvitation({
          id: invitation_id,
          email: email,
          accountcode: this.$store.getters.getCurrentUser?.account,
          uid: this.$store.getters.getCurrentUser?.uid,
          email_type: 'resend',
        })
        this.appNotify({
          message: 'Successfully Resend Invitation!',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.api.retry_invitation.send.indexOf(invitation_id)>-1){
          this.api.retry_invitation.send.splice(this.api.retry_invitation.send.indexOf(invitation_id),1)
        }
      }
    },
    async cancelInvitation(data){
      const invitation_id = data.id
      const email = data.email
      if(this.api.cancel_invitation.send.indexOf(invitation_id)>-1) return;
      try {
        this.api.cancel_invitation.send.push(invitation_id)
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to cancel the invitation`,
          button: {
            no: "No",
            yes: "Yes",
          },
        });
        if(!confirm) return;
        await VOIP_API.endpoints.user.canceluserinvitation({
          id: invitation_id,
          email: email,
          accountcode: this.$store.getters.getCurrentUser?.account,
          uid: this.$store.getters.getCurrentUser?.uid,
          email_type: 'cancel',
        })
        this.appNotify({
          message: 'Invitation Canceled!',
          type: 'success',
        })
        this.fetchInvitations()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.api.cancel_invitation.send.indexOf(invitation_id)>-1){
          this.api.cancel_invitation.send.splice(this.api.cancel_invitation.send.indexOf(invitation_id),1)
        }
      }
    },
    isReInvite(data){ return (data?.status!='failed' && moment(data.expire_time * 1000).calendar().localeCompare()==-1) || data?.invitation_status=='expired' },
    
    // event
    onNotification(message) {
      if(message.data.flag == "USER_ADDED") {
        this.fetchInvitations()
      }
    },
  },
  created() {
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
    // centrifugo.notification_admin.on(centrifugo.notification_admin.events.message,this.onNotification)
  },
  beforeDestroy() {
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
    // centrifugo.notification_admin.off(centrifugo.notification_admin.events.message,this.onNotification)
  },
  activated(){
    this.fetchInvitations()
    this.getVoipUsers()
  },
};
</script>