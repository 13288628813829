<template>
  <div id="message-box-334" class="chat-center-area custom-width panel-wide">
    <div v-if="!!channel_id" class="d-flex flex-column overflow-hidden justify-content-center w-100 h-100">
      <div v-if="loading" class="d-flex justify-content-center align-items-center h-80">
        <div class="lds-ring">
          <div></div>
          <div></div>
          <div></div>
          <div></div>
        </div>
      </div>
      <template v-else-if="!!channelData">
        <Header 
          ref="header"
          :random_id="random_id"
          :dataIdentity="dataIdentity" 
          :type="type" 
          :members="members" 
          :voipMembers="voipMembers" 
          :channel="channel" 
          :data="data" 
          :name="name" 
          :image="image" 
          :channelData="channelData" 
          :channel_id="channel_id" 
          :currentUserMember="currentUserMember" 
          @back="$emit('back')" 
        />
        <div :id="type === 'main' ? 'chat-box-section' : 'chat-box-section-2'" style="position: relative;" @dragenter.prevent="drag_and_drop=true" class="chat-box-section overflow-visible not">
          <div v-if="drag_and_drop" class="chat-drag-and-drop" @dragover.prevent="drag_and_drop=true" @dragenter.prevent="drag_and_drop=true" @dragleave.prevent="drag_and_drop=false" @drop.prevent="drag_and_drop=false;$refs['action'].filesUpload(getProperty($event,'dataTransfer.files',[]))">
            Drag and Drop
          </div>
          <Messages 
            ref="messages" 
            :random_id="random_id"
            :type="type" 
            :members="members" 
            :voipMembers="voipMembers" 
            :channel="channel" 
            :data="data" 
            :name="name" 
            :image="image" 
            :channelData="channelData" 
            :channel_id="channel_id" 
            :currentUserMember="currentUserMember" 
          />
          <Actions 
            ref="action" 
            :random_id="random_id"
            :type="type" 
            :members="members" 
            :voipMembers="voipMembers" 
            :channel="channel" 
            :data="data" 
            :name="name" 
            :image="image" 
            :channelData="channelData" 
            :channel_id="channel_id" 
            :currentUserMember="currentUserMember" 
          />
        </div>
        <ForwardMessageModal 
          ref="forward-message"
          :modalName="`${random_id}_ForwardMessageModal`" 
          :type="type" 
          :members="members" 
          :voipMembers="voipMembers" 
          :channel="channel" 
          :data="data" 
          :name="name" 
          :image="image" 
          :channelData="channelData" 
          :channel_id="channel_id" 
          :currentUserMember="currentUserMember" 
        />
        <RecordedVideoModal 
          :modalName="`${random_id}_RecordedVideoModal`" 
          @send="$refs.action.uploadFile($event, 'recorded video')" 
        />
        <MediaFilesModal 
          :modalName="`${random_id}-MediaFilesModal`" 
        />
      </template>
    </div>
    <WelcomePanel v-else />
  </div>
</template>

<script>
import { MATTERMOST_API, mattermost } from "@/MatterMost";
import _ from "lodash";
import Header from "./Header";
import Messages from "./Messages/index";
import Actions from "./Actions/index";
import WelcomePanel from "../WelcomePanel";
import { LOGGER, events, $fn } from "@/utils";
import ForwardMessageModal from "../Modal/ForwardMessageModal.vue";
import RecordedVideoModal from "../../Modals/chat/RecordedVideoModal.vue";
import MediaFilesModal from "../Modal/MediaFilesModal.vue"

export default {
  name: "Box",
  components: {
    WelcomePanel,
    ForwardMessageModal,
    RecordedVideoModal,
    MediaFilesModal,
    Header,
    Messages,
    Actions,
  },
  inject:['getProperty'],
  props: {
    type: {
      type: String,
      default: "main",
    },
    channel_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      random_id: '',
      loading: false,
      drag_and_drop: false,
    };
  },
  computed: {
    lastPost(){
      if(_.isEmpty(this.channelData)) return {}
      if(this.channelData.posts.order.length===0) return {}
      const order = this.channelData.posts.order
      const id = order[order.length-1]
      return this.channelData.posts.posts[id]
    },
    firstPost(){
      if(_.isEmpty(this.channelData)) return {}
      if(this.channelData.posts.order.length===0) return {}
      const order = this.channelData.posts.order
      const id = order[0]
      return this.channelData.posts.posts[id]
    },
    channelData() {
      return this.$store.state.mattermost.channels[this.channel_id];
    },
    voipMembers() {
      if (!_.isEmpty(this.members)) {
        const ids = this.members.map((mem) => mem.user_id);
        return Object.values(this.$store.state.common.voipusers).filter(
          (user) => ids.indexOf(user?.user?.user_chat_id) >= 0
        );
      }
      return [];
    },
    members() {
      if (!_.isEmpty(this.channelData)) {
        return this.channelData.members;
      }
      return [];
    },
    currentUserMember() {
      let vm = this;
      const [member] = (this.channelData?.members ?? []).filter(
        (member) => member.user_id === vm.$store.getters.getMattermostCurrentUser?.id //vm.mattermost_current_user.id
      );
      return member || {};
    },
    channel() {
      if (!_.isEmpty(this.channelData)) {
        return this.channelData.channel;
      }
      return {};
    },
    data() {
      // if (!_.isEmpty(this.channelData)) {
      //   return this.channelData.data;
      // }
      // return {};
      const id = this.channelData?.id
      const user = this.$store.getters.getVoipUsers[id]
      const team = this.$store.getters.getVoipTeams?.find?.(i=>i.real_id==id)
      //return user ?? team ?? this.channelData?.data ?? {}
      return user ?? team ?? this.channel ?? {}
    },
    dataIdentity(){ return this.data?.voipaccount ?? this.data?.real_id ?? this.data?.id ?? '' },
    image() {
      return (this.channelData?.type == "user" && this.data?.profile_img) || (this.channelData?.type === "team" && this.data?.avatar?.image?.image) || require("@/assets/images/channel-image.svg")
    },
    name() {
      if (!_.isEmpty(this.data)) {
        return this.channelData.type === "team"
          ? this.data.name
          : this.channelData.type === "channel"
          ? this.channel.display_name
          : this.data?.user?.display_name || '';
      }
      return "";
    },
    teamsChannelIds() {
      return this.$store.getters.getVoipTeams.map((team) => team.channel_id);
    },
  },
  watch: {
    channel_id: "watchingChannelId",
    channelData: "watchingChannelData",
    "channelData.members": "watchingChannelDataMembers",
    "channelData.posts.order.length": "watchingChannelDataPostsOrderLength",
  },
  methods: {
    //--watchings--
    async watchingChannelId(channel_id, old_channel_id) {
      let vm = this;
      this.$root.$emit(events.get_messages_api, {
        channel_id: channel_id,
        since: this.firstPost?.create_at ?? ''
      });
      if (this.type === "main") {
        this.$store.state.mattermost.main_open = "";
      }
      if (this.$store.state.mattermost.channels[old_channel_id]) {
        this.$store.state.mattermost.channels_view.splice(this.$store.state.mattermost.channels_view.indexOf(old_channel_id),1);
        // this.$store.state.mattermost.channels[old_channel_id].is_view = false;
        // if (this.type === "main") {
        //   this.$store.state.mattermost.channels[old_channel_id].is_openned_main = false;
        // }
      }
      if (!channel_id) return;
      this.loading = true;
      this.$root.$emit(events.channel_view,{
        channel_id: channel_id,
      })
      if (!this.$store.state.mattermost.channels[channel_id]) {
        const { data: channel } = await MATTERMOST_API.endpoints.channels.getChannel(channel_id);
        const team = this.$store.getters.getVoipTeams?.find?.((team) => team.channel_id === channel_id);
        const user = Object.values(this.$store.state.common.voipusers)?.find?.((user) => channel.name.includes(user?.user?.user_chat_id) && user?.user?.user_chat_id !== vm.$store.getters.getMattermostCurrentUser?.id);
        this.$root.$emit(events.adding_channel, {
          channel_id: channel_id,
          data: {
            channel: channel,
            // data: channel.type === "D" ? user : this.teamsChannelIds.indexOf(channel_id) >= 0 ? team : channel,
            id: channel.type === "D" ? user?.voipaccount : this.teamsChannelIds.indexOf(channel_id) >= 0 ? team?.real_id : '',
            type: channel.type === "D" ? "user" : this.teamsChannelIds.indexOf(channel_id) >= 0 ? "team" : "channel",
          },
        });
      } else {
        this.$root.$on(events.get_all_channels)
      }
    },
    async watchingChannelData(channelData) {
      if (!channelData) return;
      try {
        if (_.isEmpty(channelData.channel)) {
          const { data: channel } = await MATTERMOST_API.endpoints.channels.getChannel(this.channel_id);
          this.$store.state.mattermost.channels[this.channel_id].channel = channel;
        }
        // this.$store.state.mattermost.channels[this.channel_id].is_view = true;
        this.$store.state.mattermost.channels_view.push(this.channel_id);
        // this.$root.$emit(events.channel_view,{
        //   channel_id: this.channel_id,
        // })
        if (this.type === "main") {
          // this.$store.state.mattermost.channels[this.channel_id].is_openned_main = true;
          this.$store.state.mattermost.main_open = this.channel_id;
        }
      } catch(ex) {
        LOGGER.log(ex)
      } finally {
        this.loading = false;
      }
    },
    async watchingChannelDataMembers(members) {
      try {
        if (members && members.length === 0) {
          const { data: members } = await MATTERMOST_API.endpoints.channels.getChannelMembers(
            this.channel_id
          );
          this.$store.state.mattermost.channels[this.channel_id].members = members;
        }
      } catch(ex){
        LOGGER.log(ex)
      }
    },
    watchingChannelDataPostsOrderLength(length) {
      if (length === 0 && this.channelData.channel && !(this.channelData.channel.total_msg_count === 0 && this.channelData.type === "user")) {
        this.$root.$emit(events.get_messages_api, {
          channel_id: this.channel_id,
        });
      }
    },
    //--events--
    userRemovedEvent(message) {
      LOGGER.info(mattermost.events.user_removed, message);
      const { channel_id, } = message;
      if (!channel_id) {
        this.$emit('change-channel-id','')
        // this.channel_id = "";
      }
    },
    channelDeletedEvent(message) {
      LOGGER.info(mattermost.events.channel_deleted, message);
      const { channel_id } = message;
      if (this.channel_id !== channel_id) return;
      this.$emit('change-channel-id','')
      // this.channel_id = "";
    },
    // onBeforeUnload() {
    //   if (this.channelData) {
    //     this.$store.state.mattermost.channels[
    //       this.channel_id
    //     ].is_openned_main = false;
    //     this.$store.state.mattermost.channels[this.channel_id].is_view = false;
    //   }
    // },
    //--others--
    // async view() {
    //   try {
    //     const { id: user_id } = TokenService.MATTERMOST_USER.get();
    //     const { id: channel_id } = this.channel;
        // this.$root.$emit(events.channel_view,{
        //   channel_id: this.channel_id,
        // })
    //   } catch (error) {
    //     LOGGER.danger("error in toggleView function", error);
    //   }
    // },
    onClickListener(Event){
      let vm = this
      let index = $fn.pointerEventPath(Event)?.reverse?.()?.findIndex?.(el=>el.id=='message-box-334')
      if(index <= -1) return;
      if(vm.channelData?.unread > 0){
        this.$root.$emit(events.channel_view,{
          channel_id: this.channel_id,
        })
      }
    },
  },
  created() {
    this.random_id = $fn.makeid()
    document.addEventListener('click',this.onClickListener)
    mattermost.on(mattermost.events.user_removed, this.userRemovedEvent);
    mattermost.on(mattermost.events.channel_deleted, this.channelDeletedEvent);
    // window.addEventListener("beforeunload", this.onBeforeUnload);
  },
  mounted(){
    if(this.channel_id){
      this.$root.$emit(events.channel_view,{
        channel_id: this.channel_id,
      })
    }
  },
  beforeDestroy() {
    document.removeEventListener('click',this.onClickListener)
    // this.$store.state.mattermost.channels[this.channel_id].is_view = false;
    mattermost.off(mattermost.events.user_removed, this.userRemovedEvent);
    mattermost.off(mattermost.events.channel_deleted, this.channelDeletedEvent);
    // window.removeEventListener("beforeunload", this.onBeforeUnload);
  },
};
</script>

<style lang="scss" scoped>
.chat-drag-and-drop {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: rgba(0, 0, 0, 0.683);
  z-index: 999;
  display: flex;
  justify-content: center;
  align-items: center;
  border-style: dashed;
  border-width: 5px;
  color: white;
  border-color: white;
  font-size: xxx-large;
}
.chat-box-section {
  height: calc(100vh - 130px);
  @include border-radius(24px );
}

.tab-pane {
  height: calc(100% - 200px);
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid #766af0;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #766af0 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}
</style>
