<template>
  <b-dropdown-item @click="$emit('click')">
    <div class="d-flex align-items-start">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div v-if="is_sip" class="dialer-incommingVideoCall-info d-flex align-items-center">
          <div class="dialer-user-dp mr-2">
            <img width="40" height="40" :src="callObjInfo.image" class="rounded"/>
          </div>
          <div class="dialer-user-bio">
            <div class>
              <div class="forTextColor">
                From: {{ callObjInfo.from_number | number_formater }} {{ callObjInfo.from_name }}<br />
                To: {{ callObjInfo.to_number | number_formater }} {{ callObjInfo.to_name }}<br/>
              </div>
              <div v-if="callObjInfo.call_type">{{ callObjInfo.call_type }}</div>
            </div>
          </div>
        </div>
        <div v-else class="dialer-incommingVideoCall-info d-flex align-items-center">
          <div class="dialer-user-dp mr-2">
            <img width="40" height="40" :src="callObjInfo.image" class="rounded"/>
          </div>
          <div class="dialer-user-bio">
            <div class>
              <div class="forTextColor">{{ callObjInfo.name }} {{ callObjInfo.sub_info }}</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </b-dropdown-item>
</template>

<script>
import { call_info } from '@/mixin';
export default {
  name: 'SwitchIncomingCall',
  mixins: [
    call_info
  ],
  props: {
    incoming_call: {
      type: Object,
      default: ()=>({})
    },
    is_sip: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    
    callObj(){ return this.incoming_call },
    
  },
}
</script>

<style>

</style>