import { TokenService } from "../services"

export const URLS = {
  relay: '',
  kone: '',
  integration: '',
  relay_api: '',
  relay_centrifugo: '',
  centrifugo_notification: '',
  chat_socket: '',
  Jitsi_url: '',
  sipSocket_url: '',
  chat_api: '',
  billing_api: '',
  kone_api: '',

  get relayApi(){ return this.relay_api ? `${this.relay_api}/v1/` : `` },
  get relayCentrifugo(){ return this.relay_centrifugo ?? `` },
  get centrifugoNotification(){ return this.centrifugo_notification ??  `` },
  get chatSocket(){ return this.chat_socket ? `${this.chat_socket}api/v4/websocket`.replace('https://','wss://') :  `` },
  get JitsiUrl(){ return this.Jitsi_url ?? `` },
  get sipSocketUrl(){ return this.sipSocket_url ?? `` },
  get chatApi(){ return this.chat_api ? `${this.chat_api}api/v4/` :  `` },
  get billingApi(){ return this.billing_api ? `${this.billing_api}api/v1/` : `` },
  get koneApi(){ return this.kone_api ? `${this.kone_api}/v1/` : `` },

  set relayApi(value){ this.relay_api=value },
  set relayCentrifugo(value){ this.relay_centrifugo=value },
  set centrifugoNotification(value){ this.centrifugo_notification=value },
  set chatSocket(value){ this.chat_socket=value },
  set JitsiUrl(value){ this.Jitsi_url=value },
  set sipSocketUrl(value){ this.sipSocket_url=value },
  set chatApi(value){ this.chat_api=value },
  set billingApi(value){ this.billing_api=value },
  set koneApi(value){  this.kone_api=value },
  
  set relayURL(value){ 
    this.relay=value 
  },
  set koneURL(value){ 
    this.kone=value 
  },
  
  init(){
    try {
      const store_urls = TokenService.URLS.get()
      this.setUrls(store_urls)
      if(process.env.NODE_ENV!='production'){
        window.URLS = this
      }
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  storeUrls(){
    TokenService.URLS.set({
      relay: this.relay,
      kone: this.kone,
      relay_api: this.relay_api,
      relay_centrifugo: this.relay_centrifugo,
      centrifugo_notification: this.centrifugo_notification,
      chat_socket: this.chat_socket,
      Jitsi_url: this.Jitsi_url,
      sipSocket_url: this.sipSocket_url,
      chat_api: this.chat_api,
      billing_api: this.billing_api,
      kone_api: this.kone_api,
    })
  },
  setUrls(message){
    this.relayURL = message?.relay ?? this.relay;
    this.koneURL = message?.kone ?? this.kone;
    this.relayApi = message?.relay_api ?? this.relay_api;
    this.relayCentrifugo = message?.relay_centrifugo ?? this.relay_centrifugo;
    this.centrifugoNotification = message?.centrifugo_notification ?? this.centrifugo_notification;
    this.chatSocket = message?.chat_socket ?? this.chat_socket;
    this.JitsiUrl = message?.Jitsi_url ?? this.Jitsi_url;
    this.sipSocketUrl = message?.sipSocket_url ?? this.sipSocket_url;
    this.chatApi = message?.chat_api ?? this.chat_api;
    this.billingApi = message?.billing_api ?? this.billing_api;
    this.koneApi = message?.kone_api ?? this.kone_api;
    this.storeUrls()
  },
}