import Vue from 'vue'
import VueRouter from "vue-router";
import Login from './components/Login.vue'
import Dashboard from './Layout/Dashboard.vue'
import ForceLogin from './views/ForceLogin.vue'
import MobileAppPages from './views/mobileAppPages/index.vue'
import MobileAppPagesAuthenticationFailed from './views/mobileAppPages/AuthenticationFailed.vue'
import DirectAccessDenied from './views/DirectAccessDenied.vue'
import MothershipNotListen from './views/MothershipNotListen.vue'
// import ForceLogin from './views/ForceLogin'
import { TokenService } from './services/storage.service';
import { LOGGER } from './utils';
//import ChatRoom from './components/ChatRoom.vue';
Vue.use(VueRouter);


// {
// 	path: '/login',
// 	name: 'login',
// 	exact: true,
// 	component: () => import('@/views/Login.vue'),
// 	meta: {
// 		notRequiresAuth: true
// 	}
// },
// {
// 	path: '/forget-password',
// 	name: 'forget-password',
// 	exact: true,
// 	component: () => import('@/views/Login.vue'),
// 	meta: {
// 		notRequiresAuth: true
// 	}
// },
// {
// 	path: '/forcelogin',
// 	name: 'forceLogin',
// 	component: () => import('@/views/ForceLogin'),
// 	meta: {
// 		notRequiresAuth: true
// 	}
// },

// {
// 	path: '/',
// 	exact: true,
// 	name: 'dashboard',
// 	component: () => import('@/Layout/Dashboard'),
// 	meta: {
// 		requiresAuth: true
// 	}
// },
// {
// 	path: '/chat',
// 	exact: true,
// 	name: 'chat',
// 	component: () => import('@/Layout/Dashboard'),
// 	meta: {
// 		requiresAuth: true
// 	},
// 	// children: [
// 	// 	{
// 	// 		path: '/',
// 	// 		exact: true,
// 	// 		name: 'chat-main',
// 	// 		component: () => import('@/Layout/Dashboard'),
// 	// 		meta: {
// 	// 			requiresAuth: true
// 	// 		},
// 	// 	},
// 	// 	{
// 	// 		path: '/',
// 	// 		exact: true,
// 	// 		name: 'chat-channel',
// 	// 		component: () => import('@/Layout/Dashboard'),
// 	// 		meta: {
// 	// 			requiresAuth: true
// 	// 		},
// 	// 	},
// 	// ]
// },
// {
// 	path: '/meeting',
// 	exact: true,
// 	name: 'meeting',
// 	component: () => import('@/Layout/Dashboard'),
// 	meta: {
// 		requiresAuth: true
// 	}
// },
// {
// 	path: '/analytics',
// 	exact: true,
// 	name: 'analytics',
// 	component: () => import('@/Layout/Dashboard'),
// 	meta: {
// 		requiresAuth: true
// 	}
// },
// {
// 	path: '/monitor-dashboard',
// 	exact: true,
// 	name: 'monitor-dashboard',
// 	component: () => import('@/Layout/Dashboard'),
// 	meta: {
// 		requiresAuth: true
// 	}
// },
// {
// 	path: '/setting',
// 	exact: true,
// 	name: 'setting',
// 	component: () => import('@/Layout/Dashboard'),
// 	meta: {
// 		requiresAuth: true
// 	}
// },

// {
// 	path: '/direct-access-denied',
// 	exact: true,
// 	name: 'direct-access-denied',
// 	component: () => import('@/views/DirectAccessDenied'),
// 	meta: {
// 		requiresAuth: true
// 	}
// },
// {
// 	path: '/mothership-not-listen',
// 	exact: true,
// 	name: 'mothership-not-listen',
// 	component: () => import('@/views/MothershipNotListen'),
// 	meta: {
// 		requiresAuth: true
// 	}
// },

//const firstPath = window.location.pathname.split('/')[1];
const router = new VueRouter({
  mode: process.env.IS_ELECTRON ? 'hash' : 'history',
  //base: '/'+firstPath,
  routes: [
		{
			path: '/login',
			name: 'login',
			exact: true,
			components: {
				login:Login
			},
			meta: {
				notRequiresAuth: true,
			}
		},
		{
			path: '/forcelogin',
			name: 'forceLogin',
			// exact: true,
			component: ForceLogin,
			meta: {
			}
		},
		{
			path: '/mobile-app-pages',
			name: 'mobile-app-pages',
			// exact: true,
			component: MobileAppPages,
			meta: {
				mobilePages: true,
			}
		},
		{
			path: '/mobile-app-page-authentiacation-failed',
			name: 'mobile-app-page-authentiacation-failed',
			// exact: true,
			component: MobileAppPagesAuthenticationFailed,
			meta: {
				mobilePages: true,
			}
		},
		{
			path: '/',
			exact: true,
			name: 'dashboard',
			component: Dashboard,
			meta: {
				requiresAuth: true
			}
		},
		{
			path: '/direct-access-denied',
			exact: true,
			name: 'direct-access-denied',
			component: DirectAccessDenied,
			meta: {
			}
		},
		{
			path: '/mothership-not-listen',
			exact: true,
			name: 'mothership-not-listen',
			component: MothershipNotListen,
			meta: {
			}
		},
	],
});
router.beforeEach((to, from, next) => {
	LOGGER.log('to',to.name)
	let requiresAuth = to.matched.some((record) => record.meta.requiresAuth)
	let notRequiresAuth = to.matched.some((record) => record.meta.notRequiresAuth)
	let mobilePages = to.matched.some((record) => record.meta.mobilePages)
	let token = TokenService.TOKEN.get();
	LOGGER.log(':::::conditions:::::',
		!mobilePages,
		process.env.NODE_ENV === 'production',
		!process.env.IS_ELECTRON,
		process.env.VUE_APP_INTEGRATION!='true',
		window.self==window.top,
		window.self!=window.top,
		requiresAuth,
		notRequiresAuth,
		!!token,
	)
	if(!mobilePages){
		if(process.env.NODE_ENV === 'production' && !process.env.IS_ELECTRON){
			if(to.name!=='direct-access-denied' && window.self==window.top){
				next({
					name: 'direct-access-denied'
				});
			} else if(to.name==='login' && window.self!=window.top && process.env.VUE_APP_INTEGRATION!='true'){
				next({
					name: 'mothership-not-listen'
				});
			}
		}
		if(requiresAuth && !token) {
			next({
				name: 'login'
			});
		} else if(notRequiresAuth && token) {
			next({
				name: 'dashboard'
			});
		}
	}
	next();
});

export default router;