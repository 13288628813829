<template>
  <div>
    <modal 
      height="auto" 
      :name="modalName" 
      class="center-zoomIn-transition v2 m-w-500 addUserModalV2" 
      @before-open="onBeforeOpen($event)" 
      :scrollable="true" 
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-user">
        <div class="dialer-edit">
          <div class="dialer-form">
            <div class="dialer-edit-header pt-0 pb-0 d-block">
              <div class="d-flex justify-content-between align-items-center">
                <h2 class="dialer-edit-title">
                  Create user
                  <vb-icon 
                    icon="checkSlotsModal-icon" 
                    class="ml-2 cursor_pointer checkSlotsModal-icon" 
                    height="22px" 
                    width="22px"
                    :disabled="api.organization_user.send" 
                    @click="$modal.show('CheckedServiceAvailableModal',{service: 'user',onProceed: addorganizationuser,})"
                  />
                </h2>
                <div class="dialer-edit-actions">
                  <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
                  <a v-else class="newCloseButton" @click="$modal.hide(modalName)">
                    <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                    <span class="newCloseButton-textPart">Close</span>
                  </a>
                </div>
              </div>
              <div class="vm--modal-text">
                Please provide the new user's details below and configure your account preferences.
              </div>
            </div>
            <div class="md-mar-vertical">
                <form @submit.prevent="addorganizationuser()">
                  <b-alert :show="!!api.organization_user.error_message" variant="danger">{{ api.organization_user.error_message }}</b-alert>
                  <!-- username -->
                  <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error md-mar-top">
                    <label>Username *</label>
                    <div class="d-flex align-items-center justify-content-between">
                      <input 
                        :disabled="api.organization_user.send" 
                        v-model="forms.organization_user.name" 
                        placeholder="Enter username" 
                        class="w-50per-15px bg-white" 
                        type="text" 
                      />
                      @
                      <input 
                        class="w-50per-15px bg-white" 
                        :maxlength="$v.forms.organization_user.name.$params.maxLength.max" 
                        :disabled="true" 
                        :value="getCurrentUser.company" 
                      />
                    </div>
                    <p 
                      v-if="(forms.organization_user.submitted && $v.forms.organization_user.name.$invalid) || api.organization_user.validation_errors.name" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <span v-if="!$v.forms.organization_user.name.required">* Username is required</span>
                      <span v-else-if="!$v.forms.organization_user.name.alphaStart">* Username should start with an alphabet</span>
                      <span v-else-if="!$v.forms.organization_user.name.alphaNum">* Username can be only alphabet or numeric</span>
                      <span v-else-if="!$v.forms.organization_user.name.minLength">* Username should be minimum {{ $v.forms.organization_user.name.$params.minLength.min }} character</span>
                      <span v-else-if="!$v.forms.organization_user.name.maxLength">* Username can be maximum {{ $v.forms.organization_user.name.$params.maxLength.max }} character</span>
                      <span v-for="(em, i) in api.organization_user.validation_errors.name" :key="i">* {{ em }}</span>
                      
                    </p>
                  </div>
                  <div class="w-100 d-flex md-mar-top">
                    <!-- first name -->
                    <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error w-50 pr-3">
                      <label>First name *</label>
                      <input 
                        :disabled="api.organization_user.send" 
                        v-model="forms.organization_user.first_name" 
                        :maxlength="$v.forms.organization_user.first_name.$params.maxLength.max" 
                        onkeypress="return /^[A-Za-z]+$/.test(event.key)" 
                        placeholder="Enter first name" 
                        class="w-100 bg-white" 
                        type="text" 
                      />
                      <p 
                        v-if="(forms.organization_user.submitted && $v.forms.organization_user.first_name.$invalid) || api.organization_user.validation_errors.display_name" 
                        class="text text-small text-danger animated bounceIntop mb-0"
                      >
                        <span v-if="!$v.forms.organization_user.first_name.required">* First name is required</span>
                        <span v-else-if="!$v.forms.organization_user.first_name.maxLength">* First name can be maximum {{ $v.forms.organization_user.first_name.$params.maxLength.max }} character</span>
                        <span v-else-if="!$v.forms.organization_user.first_name.alpha">* First name must be only alphabets</span>
                        <span v-for="(em, i) in api.organization_user.validation_errors.display_name" :key="i">* {{ em }}</span>
                      </p>
                    </div>
                    <!-- last mame -->
                    <div class="whiteBGinputWithGreyRoundedBorder dialer-input--error w-50 pl-3">
                      <label>Last name</label>
                      <input 
                        :disabled="api.organization_user.send" 
                        v-model="forms.organization_user.last_name" 
                        :maxlength="$v.forms.organization_user.last_name.$params.maxLength.max" 
                        onkeypress="return /^[A-Za-z]+$/.test(event.key)" 
                        placeholder="Enter last name" 
                        class="w-100 bg-white" 
                        type="text" 
                      />
                      <p 
                        v-if="forms.organization_user.submitted && $v.forms.organization_user.last_name.$invalid" 
                        class="text text-small text-danger animated bounceIntop mb-0"
                      >
                        <span v-if="!$v.forms.organization_user.last_name.maxLength">* Last name can be maximum {{ $v.forms.organization_user.last_name.$params.maxLength.max }} character</span>
                        <span v-else-if="!$v.forms.organization_user.last_name.alpha">* Last name must be only alphabets</span>
                        <span v-else-if="!$v.forms.organization_user.last_name.valid">* Last name must have a space character</span>
                      </p>
                    </div>
                  </div>
                  <!-- admin -->
                  <div class="dialer-box-header md-mar-top md-mar-bottom">
                    <div class="d-flex align-items-center">
                      <span class="mr-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Set as admin</span>
                      <b-form-checkbox switch
                        class="newerSwitch"
                        :disabled="api.organization_user.send" 
                        v-model="forms.organization_user.role" 
                        name="check-button"
                      />
                    </div>
                    <p 
                      v-if="(forms.organization_user.submitted && $v.forms.organization_user.role.$invalid) || api.organization_user.validation_errors.role" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <span v-if="$v.forms.organization_user.role.required">* Role is required</span>
                      <span v-for="(em, i) in api.organization_user.validation_errors.role" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                  <!-- set password -->
                  <div class="dialer-box-header md-mar-bottom md-mar-top">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox 
                        class="makeCheckBoxesSquare topLeft-0px mr-16px"
                        :disabled="api.organization_user.send" 
                        :checked="!forms.organization_user.is_passwword_manual" 
                        @change="forms.organization_user.is_passwword_manual=!forms.organization_user.is_passwword_manual;forms.organization_user.password='';"
                      />
                      <span class="mr-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Set password</span>
                    </div>
                  </div>
                  <!-- password -->
                  <div v-if="!forms.organization_user.is_passwword_manual" class="whiteBGinputWithGreyRoundedBorder dialer-input--error md-mar-top">
                    <label>Password</label>
                    <div class="position-relative b-0 adduser-two-input-icon">
                      <span class="icon ml-2">
                        <b-icon icon="lock" />
                      </span>
                      <input
                        class="w-100 bg-white"
                        :type="conditions.organization_user_password_show ? 'text' : 'password'"
                        placeholder="Enter password"
                        :disabled="api.organization_user.send"
                        readonly
                        onfocus="this.removeAttribute('readonly');"
                        v-model="forms.organization_user.password"
                        :maxlength="$v.forms.organization_user.password.$params.maxLength.max"
                      />
                      <b-icon
                        class="dialer-showPassword-icon cursor_pointer"
                        :aria-disabled="api.organization_user.send"
                        :icon="conditions.organization_user_password_show ? 'eye-slash' : 'eye'"
                        @click="conditions.organization_user_password_show = !conditions.organization_user_password_show"
                      />
                    </div>
                    <p 
                      v-if="(forms.organization_user.submitted && $v.forms.organization_user.password.$invalid) || api.organization_user.validation_errors.password" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <span v-if="!$v.forms.organization_user.password.required">* password is required</span>
                      <span v-else-if="!$v.forms.organization_user.password.capitalalphabet">* password required at least one capital character</span>
                      <span v-else-if="!$v.forms.organization_user.password.smallalphabet">* password required at least one small character</span>
                      <span v-else-if="!$v.forms.organization_user.password.numbers">* password required at least one integer character</span>
                      <span v-else-if="!$v.forms.organization_user.password.specialcharacter">* password required at least one special character</span>
                      <span v-else-if="!$v.forms.organization_user.password.minLength">* password is minimum {{$v.forms.organization_user.password.$params.minLength.min}} is required</span>
                      <span v-else-if="!$v.forms.organization_user.password.maxLength">* password is maximum {{$v.forms.organization_user.password.$params.maxLength.max}} character</span>
                      <span v-for="(em,i) in api.organization_user.validation_errors.password" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                  <!-- send password email -->
                  <div class="dialer-box-header md-mar-bottom md-mar-top">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox 
                        class="makeCheckBoxesSquare topLeft-0px mr-16px"
                        :disabled="api.organization_user.send" 
                        v-model="forms.organization_user.is_send_email"
                      />
                      <span class="mr-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Send password in email upon completion</span>
                    </div>
                  </div>
                  <!-- email -->
                  <div v-if="forms.organization_user.is_send_email" class="whiteBGinputWithGreyRoundedBorder dialer-input--error mb-3 md-mar-top">
                    <label>Email the new password to the following recepients *</label>
                    <div class="position-relative ">
                      <input 
                        class="w-100 bg-white" 
                        type="text" 
                        placeholder="Enter email" 
                        :disabled="api.organization_user.send"  
                        v-model="forms.organization_user.email" 
                      />
                    </div>
                    <p 
                      v-if="(forms.organization_user.submitted && $v.forms.organization_user.email.$invalid) || api.organization_user.validation_errors.email" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <span v-if="!$v.forms.organization_user.email.required">* email is required</span>
                      <span v-else-if="!$v.forms.organization_user.email.email">* email is invalid</span>
                      <span v-for="(em, i) in api.organization_user.validation_errors.email" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                  <!-- skip number -->
                  <div v-if="!isFreePlan" class="dialer-box-header md-mar-bottom">
                    <div class="d-flex align-items-center">
                      <b-form-checkbox 
                        class="makeCheckBoxesSquare topLeft-0px mr-16px"
                        :disabled="api.organization_user.send" 
                        :checked="forms.organization_user.skip_number" 
                        @change="
                          forms.organization_user.skip_number=!forms.organization_user.skip_number;
                          forms.organization_user.is_shared_caller_id=false;
                        " 
                        name="check-button"
                      />
                      <span class="mr-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Skip number</span>
                    </div>
                    <p v-if="api.organization_user.validation_errors.skip_number" class="text text-small text-danger animated bounceIntop mb-0">
                      <span v-for="(em, i) in api.organization_user.validation_errors.skip_number" :key="i">* {{ em }}</span>
                    </p>
                  </div>
                  <!-- number -->
                  <template v-if="isNumberSelect">
                    <div 
                      class="d-flex align-items-center justify-content-between mb-2 lg-mar-top"
                      :class="{
                        'numberSelected': forms.organization_user.number.number
                      }"
                    >
                      <template>
                        <div v-if="forms.organization_user.number.number" class="beforeDeleteNumbers-item mb-0">
                          {{ forms.organization_user.number.number | number_formater }}
                        </div>
                      </template>
                      <button 
                        type="button" 
                        @click="$modal.show(`${modalName}-AddNumbersModal`,{ add_user: true, })" 
                        class="dialer-button dialer-button-primary w-100" 
                        :disabled="api.organization_user.send"
                      >
                        {{!forms.organization_user.number.number ? 'Select number' : 'Change number'}}
                      </button>
                    </div>
                    <p 
                      v-if="forms.organization_user.submitted && $v.forms.organization_user.number.$invalid" 
                      class="text text-small text-danger animated bounceIntop mb-0"
                    >
                      <span v-if="!$v.forms.organization_user.number.required">* Number is required</span>
                    </p>
                  </template>
                  <!-- <div v-else class="dialer-box-header md-mar-bottom">
                    <div class="d-flex align-items-center">
                      <span class="mr-3 setAsAdmin-text" style="font-size: 16px;font-weight: 400;color: #676767;">Assign your user an outgoing number</span>
                      <div class="dialer-box p-0 bg-transparent mb-0">
                        <b-form-checkbox 
                          class="dialer-switch p-0" 
                          :disabled="api.organization_user.send" 
                          v-model="forms.organization_user.is_shared_caller_id"
                        />
                      </div>
                    </div>
                  </div> -->
                  <div class="d-flex align-items-center justify-content-end mb-2 lg-mar-top">
                    <button type="submit" class="dialer-button dialer-button-primary w-100" :disabled="api.organization_user.send">
                      <vb-spinner v-if="api.organization_user.send" />
                      <template v-else>Create user</template>
                    </button>
                  </div>
                </form>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <AddNumbersModal :modalName="`${modalName}-AddNumbersModal`" @select-number="forms.organization_user.number=$event" />
  </div>
</template>

<script>
import { check_service } from '@/mixin';
import {
  required,
  minLength,
  maxLength,
  requiredIf,
  email,
  alphaNum,
  helpers,
} from "vuelidate/lib/validators";
import AddNumbersModal from "./AddNumbersModal.vue";

import { /*events,*/ VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import alpha from 'vuelidate/lib/validators/alpha';

export default {
  name: "AddUserByUserName",
  components: {
    AddNumbersModal,
  },
  mixins: [
    check_service
  ],
  props: {
    modalName: {
      type: String,
      default: 'AddUserByUserName',
    },
  },
  data() {
    return {
      forms: {
        organization_user: this.$helperFunction.formInstance({
          data: {
            name: "",
            first_name: "",
            last_name: "",
            password: "",
            is_passwword_manual: true,
            is_send_email: false,
            is_shared_caller_id: true,
            email: '',
            skip_number: false,
            number: {},
            role: false,
          }
        }),
      },
      api: {
        organization_user: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
      },
      conditions: {
        organization_user_password_show: false,
        organization_user_show_more: false,
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'isFreePlan',
    ]),
    isNumberSelect(){ return !this.isFreePlan && !this.forms.organization_user.skip_number },
  },
  validations: {
    forms: {
      organization_user: {
        name: {
          required,
          alphaNum,
          minLength: minLength(4), 
          maxLength: maxLength(40),
          alphaStart: helpers.regex('alphaStart',/^[A-Za-z]+/),
        },
        // display_name: {
        //   required, 
        //   maxLength: maxLength(33),
        //   alpha: function(value){ return /^[a-zA-Z ]*$/.test(value) },
        //   valid: function(value){
        //     const value1 = value.split(' ')
        //     return !!value1[0] && !!value1[1]
        //   },
        // },
        first_name: {
          required, 
          maxLength: maxLength(16),
          alpha: alpha,
        },
        last_name: {
          maxLength: maxLength(16),
          alpha: alpha,
        },
        password: {
          required: requiredIf(function () {
            return !this.forms.organization_user.is_passwword_manual
          }),
          minLength: minLength(8),
          maxLength: maxLength(32),
          capitalalphabet: function(value){
            return !value || /[A-Z]/.test(value)
          },
          smallalphabet: function(value){
            return !value || /[a-z]/.test(value)
          },
          numbers: function(value){
            return !value || /[0-9]/.test(value)
          },
          specialcharacter: function(value){
            return !value || /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(value)
          },
        },
        email: {
          required: requiredIf(function () {
            return this.forms.organization_user.is_send_email
          }),
          email,
        },
        role: {
          required,
        },
        number: {
          required: requiredIf(function () {
            return this.isNumberSelect;
          }),
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      // TODO - 
    },
    onBeforeClose(){
      this.forms.organization_user.reset()
      this.api.organization_user.reset()
      this.conditions.organization_user_password_show=false
      this.conditions.organization_user_show_more=false
    },
    addorganizationuser(){
      let vm = this
      vm.forms.organization_user.submitted=true
      vm.$v.forms.organization_user.$touch()
      if(vm.api.organization_user.send || vm.$v.forms.organization_user.$invalid) return;
      vm.api.organization_user.validation_errors={}
      vm.api.organization_user.error_message=''
      vm.api.organization_user.send=true
      vm.checkService({
        service: 'user',
        async then(){
          await VOIP_API.endpoints.user.addorganizationuser({
            username: `${vm.forms.organization_user.name}`,
            display_name: `${vm.forms.organization_user.first_name}${vm.forms.organization_user.last_name ? ` ${vm.forms.organization_user.last_name}` : ''}`,
            password: !vm.forms.organization_user.is_passwword_manual ? vm.forms.organization_user.password : null,
            email: vm.forms.organization_user.is_send_email ? vm.forms.organization_user.email : `${vm.forms.organization_user.name}@${vm.$store.getters.getCurrentUser?.company.replace(/ /g, "")}.com`,
            number: vm.forms.organization_user.number,
            is_passwword_manual: !vm.forms.organization_user.is_passwword_manual,
            is_send_email: vm.forms.organization_user.is_send_email,
            role: vm.forms.organization_user.role ? 1 : 0,
            uid: vm.$store.getters.getCurrentUser?.uid,
            accountcode: vm.$store.getters.getCurrentUser?.account,
            skip_number: vm.isNumberSelect ? '0' : '1',
            is_shared_caller_id: vm.forms.organization_user.is_shared_caller_id ? '1' : '0',
          })
          // vm.$store.commit('setAddServiceQueue',{
          //   type: 'user',
          //   name: vm.forms.organization_user.display_name,
          //   username: `${vm.forms.organization_user.name}`,
          // })
          vm.$emit("user-added");
          vm.$modal.hide(vm.modalName)
          // vm.$root.$emit(events.check_pending_services)
        },
        catch(ex){
          vm.api.organization_user.error_message=ex.own_message
          vm.api.organization_user.validation_errors=ex.response_error || {}
        },
        finally(){
          vm.api.organization_user.send=false
          vm.forms.organization_user.submitted=false
        },
      })
    },
  },
};
</script>


<style>
.slide-ltr-enter-active {
  animation: slide-ltr 0.5s reverse;
}
.slide-ltr-leave-active {
  animation: slide-ltr 0.5s;
}
@keyframes slide-ltr {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>