var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "react-code-input v2",
    attrs: {
      "id": _vm.id
    }
  }, _vm._l(_vm.numberslength, function (item, index) {
    return _c('input', {
      key: item,
      attrs: {
        "id": `${_vm.random_id}-pin-code-${index}`,
        "data-index": index,
        "type": "number",
        "onkeypress": "return /^[0-9]+$/.test(event.key)",
        "max": "9",
        "min": "0",
        "maxlength": "1",
        "minlength": "1",
        "disabled": _vm.disabled,
        "readonly": true,
        "onfocus": "this.removeAttribute('readonly')",
        "onblur": "this.setAttribute('readonly','readonly')"
      },
      on: {
        "keydown": function ($event) {
          return _vm.onKeyDown($event, index);
        },
        "paste": function ($event) {
          $event.preventDefault();
          return _vm.onPaste($event);
        }
      }
    });
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }