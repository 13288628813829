var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-box alt position-relative",
    attrs: {
      "id": "calls-setting-desktop-notifications"
    }
  }, [_c('div', {
    staticClass: "dialer-flex"
  }, [_vm._m(0), _c('div', {
    staticClass: "dialer-audio-options d-flex"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.desktop_notification
    },
    attrs: {
      "id": "DesktopNSwitchLock",
      "title": _vm.getUserPermissions.desktop_notification ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-form-checkbox', {
    ref: "desktop_notification",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.desktop_notification || _vm.api.update_desktop_notification.send || _vm.api.user_detail.send,
      "checked": _vm.conditions.desktop_notification,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateDesktopNotification($event);
      }
    }
  })], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "dialer-box-header"
  }, [_vm._v("Desktop notifications")]), _c('div', {
    staticClass: "dialer-box-text mb-0"
  }, [_vm._v("Stay informed with on-screen alerts. Get real-time updates on calls, messages, and more, without disrupting your workflow")])]);

}]

export { render, staticRenderFns }