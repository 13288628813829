<template>
  <modal
    class="center-zoomIn-transition v2 m-w-500"
    width="500px"
    height="auto"
    :scrollable="true"
    name="AddPickUpGroupModal"
    @closed="onClosed"
  >
    <div class="dialer-form s1">
      <div v-if="$store.getters.getIsMobile" class="dialer-edit-header">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <h2 class="dialer-edit-title">Add pickup group</h2>
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('AddPickUpGroupModal')" />
        </div>
        <!-- <div class="dialer-edit-actions d-flex w-100 justify-content-end mt-2">
          <button type="submit" @click="add" class="newDoneButton" :disabled="api_sent.add">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
              <vb-spinner v-if="api_sent.add" />
              <template v-else>Done</template>
            </span>
          </button>
        </div> -->
      </div>
      <div v-else class="dialer-edit-header">
        <h2 class="dialer-settings-title newer mb-0">Add pickup group</h2>
        <div class="dialer-edit-actions">
          <a class="newCloseButton" @click="$modal.hide('AddPickUpGroupModal')">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <b-alert :dismissible="true" :fade="true" :show="!!error" @dismissed="error=''" class="alert-danger">{{ error }}</b-alert>
      <b-form @submit.prevent="add">
        <b-form-group>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder">
              <label>Name</label>
              <b-form-input :disabled="api_sent.add" type="text" placeholder="Enter name" v-model="forms.create.name" />
              <template v-if="submitted.add">
                <p class="text text-danger animated bounceIntop" v-if="!$v.forms.create.name.required">*Name is Required</p>
              </template>
            </div>
          </div>
        </b-form-group>
      </b-form>
      <div class="w-100 mt-4">
        <button type="submit" @click="add" class="fullWidthDoneButton" :disabled="api_sent.add">
          <vb-spinner v-if="api_sent.add" />
          <template v-else>Create pickup group</template>
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '../../../utils';
import { required } from "vuelidate/lib/validators";
export default {
  name: "AddPickUpGroupModal",
  data(){
    return {
      forms: {
        create: {
          name: '',
          reset(){
            this.name=''
          }
        }
      },
      error: "",
      submitted: {
        add: false,
      },
      api_sent: {
        add: false,
      },
      assets: {
        spinner: require("@/assets/images/spinner.svg"),
      },
    }
  },
  inject: [
    'appNotify',
  ],
  validations: {
    forms: {
      create: {
        name: {
          required,
        }
      }
    }
  },
  methods: {
    async add(){
      var app = this
      this.submitted.add=true
      this.$v.$touch();
      if (this.$v.forms.create.$invalid || this.api_sent.add) return;
      this.api_sent.add=true
      VOIP_API.endpoints.pickupgroup.create({
        name: this.forms.create.name,
        uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
        accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
      }).then(()=>{
        app.forms.create.reset()
        app.appNotify({
          message: "Successfully Added",
          type: 'success',
        })
        app.$emit('load')
        app.$modal.hide('AddPickUpGroupModal')
      }).catch((ex)=>{
        app.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        app.api_sent.add=false
        app.submitted.add=false
      })
    },
    onClosed(){
      this.submitted.add=false
      this.error=''
    },
  },
}
</script>

<style>

</style>