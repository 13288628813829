<template>
  <div class="crm-SettingsContainer">
    <div class="crm-Settings-leftSideBar">
      <h2 class="crm-menu-title">Settings</h2>
      <div class="crm-Settings-leftSideBar-inner">
        <ul class="list-unstyled">
          <li @click="selectTab = 'General'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'General' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-general-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                General
              </span>
            </a>
          </li>
          <li @click="selectTab = 'CompanyFields'" v-b-toggle.Fields :class="`crm-Settings-leftSideBar-item ${selectTab.includes('Fields','CompanyFields','ContactFields','DealFields','ProductFields') ? 'active' : ''}`">
            <a>
              <div class="d-flex align-items-center">
                <span class="leftSideBar-item-icon">
                  <vb-icon icon="crm-fields-settings-icon" height="22px" width="22px" />
                </span>
                <span class="leftSideBar-item-text">
                  Fields
                </span>
              </div>
              <b-icon icon="chevron-down" font-scale="1.2"/>
            </a>
          </li>
          <b-collapse id="Fields" class=" crm-leftsideBar-subMenu">
            <ul class="list-unstyled">
              <li :class="`${selectTab == 'CompanyFields' ? 'active' : ''}`" class="" @click="selectTab = 'CompanyFields'">
                <a href="#">Company</a>
              </li>
              <li :class="`${selectTab == 'ContactFields' ? 'active' : ''}`" class="" @click="selectTab = 'ContactFields'">
                <a href="#">Contact</a>
              </li>
              <li :class="`${selectTab == 'DealFields' ? 'active' : ''}`" class="" @click="selectTab = 'DealFields'">
                <a href="#">Deal</a>
              </li>
              <li :class="`${selectTab == 'ProductFields' ? 'active' : ''}`" class="" @click="selectTab = 'ProductFields'">
                <a href="#">Product</a>
              </li>
            </ul>
          </b-collapse>
          <li @click="selectTab = 'PusherIntegrations'" v-b-toggle.Integrations :class="`crm-Settings-leftSideBar-item ${selectTab.includes('Integrations','PusherIntegrations','MicrosoftIntegrations','GoogleIntegrations','TwilioIntegrations','ZapierIntegrations') ? 'active' : ''}`">
            <a>
              <div class="d-flex align-items-center">
                <span class="leftSideBar-item-icon">
                  <vb-icon icon="crm-integrations-settings-icon" height="22px" width="22px" />
                </span>
                <span class="leftSideBar-item-text">
                  Integrations
                </span>
              </div>
              <b-icon icon="chevron-down" font-scale="1.2"/>
            </a>
          </li>
          <b-collapse id="Integrations" class=" crm-leftsideBar-subMenu">
            <ul class="list-unstyled">
              <li :class="`${selectTab == 'PusherIntegrations' ? 'active' : ''}`" class="" @click="selectTab = 'PusherIntegrations'">
                <a href="#">Pusher</a>
              </li>
              <li :class="`${selectTab == 'MicrosoftIntegrations' ? 'active' : ''}`" class="" @click="selectTab = 'MicrosoftIntegrations'">
                <a href="#">Microsoft</a>
              </li>
              <li :class="`${selectTab == 'GoogleIntegrations' ? 'active' : ''}`" class="" @click="selectTab = 'GoogleIntegrations'">
                <a href="#">Google</a>
              </li>
              <li :class="`${selectTab == 'TwilioIntegrations' ? 'active' : ''}`" class="" @click="selectTab = 'TwilioIntegrations'">
                <a href="#">Twilio</a>
              </li>
              <li :class="`${selectTab == 'ZapierIntegrations' ? 'active' : ''}`" class="" @click="selectTab = 'ZapierIntegrations'">
                <a href="#">Zapier</a>
              </li>
            </ul>
          </b-collapse>
          <li @click="selectTab = 'Activities'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Activities' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-activities-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Activities
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Deals'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Deals' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-deals-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Deals
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Documents'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Documents' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-documents-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Documents
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Products'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Products' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-products-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Products
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Companies'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Companies' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-companies-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Companies
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Calls'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Calls' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-calls-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Calls
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Web Forms'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Web Forms' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-webForms-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Web Forms
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Workflows'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Workflows' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-workflows-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Workflows
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Users'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Users' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-users-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Users
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Mail Templates'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Mail Templates' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-mailTemplates-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Mail Templates
              </span>
            </a>
          </li>
          <li @click="selectTab = 'Brands'" :class="`crm-Settings-leftSideBar-item ${selectTab == 'Brands' ? 'active' : ''}`">
            <a>
              <span class="leftSideBar-item-icon">
                <vb-icon icon="crm-brands-settings-icon" height="22px" width="22px" />
              </span>
              <span class="leftSideBar-item-text">
                Brands
              </span>
            </a>
          </li>
          <li @click="selectTab = 'GeneralSecurity'" v-b-toggle.Security :class="`crm-Settings-leftSideBar-item ${selectTab.includes('Security','GeneralSecurity','reCaptchaSecurity') ? 'active' : ''}`">
            <a>
              <div class="d-flex align-items-center">
                <span class="leftSideBar-item-icon">
                  <vb-icon icon="crm-security-settings-icon" height="22px" width="22px" />
                </span>
                <span class="leftSideBar-item-text">
                  Security
                </span>
              </div>
              <b-icon icon="chevron-down" font-scale="1.2"/>
            </a>
          </li>
          <b-collapse id="Security" class=" crm-leftsideBar-subMenu">
            <ul class="list-unstyled">
              <li :class="`${selectTab == 'GeneralSecurity' ? 'active' : ''}`" class="" @click="selectTab = 'GeneralSecurity'">
                <a href="#">General</a>
              </li>
              <li :class="`${selectTab == 'reCaptchaSecurity' ? 'active' : ''}`" class="" @click="selectTab = 'reCaptchaSecurity'">
                <a href="#">reCaptcha</a>
              </li>
            </ul>
          </b-collapse>
          <li @click="selectTab = 'UpdateSystem'" v-b-toggle.System :class="`crm-Settings-leftSideBar-item ${selectTab.includes('System','UpdateSystem','ToolsSystem','TranslatorSystem',) ? 'active' : ''}`">
            <a>
              <div class="d-flex align-items-center">
                <span class="leftSideBar-item-icon">
                  <vb-icon icon="crm-system-settings-icon" height="22px" width="22px" />
                </span>
                <span class="leftSideBar-item-text">
                  System
                </span>
              </div>
              <b-icon icon="chevron-down" font-scale="1.2"/>
            </a>
          </li>
          <b-collapse id="System" class=" crm-leftsideBar-subMenu">
            <ul class="list-unstyled">
              <li :class="`${selectTab == 'UpdateSystem' ? 'active' : ''}`" class="" @click="selectTab = 'UpdateSystem'">
                <a href="#">Update</a>
              </li>
              <li :class="`${selectTab == 'ToolsSystem' ? 'active' : ''}`" class="" @click="selectTab = 'ToolsSystem'">
                <a href="#">Tools</a>
              </li>
              <li :class="`${selectTab == 'TranslatorSystem' ? 'active' : ''}`" class="" @click="selectTab = 'TranslatorSystem'">
                <a href="#">Translator</a>
              </li>
              <li :class="`${selectTab == 'SystemInfoSystem' ? 'active' : ''}`" class="" @click="selectTab = 'SystemInfoSystem'">
                <a href="#">System Info</a>
              </li>
              <li :class="`${selectTab == 'LogsSystem' ? 'active' : ''}`" class="" @click="selectTab = 'LogsSystem'">
                <a href="#">Logs</a>
              </li>
            </ul>
          </b-collapse>
        </ul>
      </div>
    </div>
    <div class="crm-Settings-rightSide">
      <div class="crm-Settings-rightSide-inner">
        <!-- {{selectTab}} -->
        <General v-if="selectTab == 'General'"/>
        <CompanyFields v-if="selectTab == 'CompanyFields'"/>
        <ContactFields v-if="selectTab == 'ContactFields'"/>
        <DealsFields v-if="selectTab == 'DealFields'"/>
        <ProductFields v-if="selectTab == 'ProductFields'"/>

        <PusherIntegration v-if="selectTab == 'PusherIntegrations'"/>
        <MicrosoftIntegration v-if="selectTab == 'MicrosoftIntegrations'"/>
        <GoogleIntegration v-if="selectTab == 'GoogleIntegrations'"/>
        <TwilioIntegration v-if="selectTab == 'TwilioIntegrations'"/>
        <ZapierIntegration v-if="selectTab == 'ZapierIntegrations'"/>

        <GeneralSecurity v-if="selectTab == 'GeneralSecurity'"/>
        <ReCaptcha v-if="selectTab == 'reCaptchaSecurity'"/>

        <Update v-if="selectTab == 'UpdateSystem'"/>
        <Tools v-if="selectTab == 'ToolsSystem'"/>
        <Translator v-if="selectTab == 'TranslatorSystem'"/>
        <Logs v-if="selectTab == 'LogsSystem'"/>
        <SystemInfo v-if="selectTab == 'SystemInfoSystem'"/>

        <Activities v-if="selectTab == 'Activities'"/>
        <Deals v-if="selectTab == 'Deals'"/>
        <Documents v-if="selectTab == 'Documents'"/>
        <Products v-if="selectTab == 'Products'"/>
        <Companies v-if="selectTab == 'Companies'"/>
        <Calls v-if="selectTab == 'Calls'"/>
        <Users v-if="selectTab == 'Users'"/>
        <Brands v-if="selectTab == 'Brands'"/>
      </div>
    </div>
  </div>
</template>

<script>

import General from '@/components/CRM/settings/General.vue'

import CompanyFields from '@/components/CRM/settings/fieldSettings/CompanyFields.vue'
import ContactFields from '@/components/CRM/settings/fieldSettings/ContactFields.vue'
import DealsFields from '@/components/CRM/settings/fieldSettings/DealsFields.vue'
import ProductFields from '@/components/CRM/settings/fieldSettings/ProductFields.vue'

import PusherIntegration from '@/components/CRM/settings/integrationSettings/PusherIntegration.vue'
import MicrosoftIntegration from '@/components/CRM/settings/integrationSettings/MicrosoftIntegration.vue'
import GoogleIntegration from '@/components/CRM/settings/integrationSettings/GoogleIntegration.vue'
import TwilioIntegration from '@/components/CRM/settings/integrationSettings/TwilioIntegration.vue'
import ZapierIntegration from '@/components/CRM/settings/integrationSettings/ZapierIntegration.vue'

import GeneralSecurity from '@/components/CRM/settings/securitySettings/General.vue'
import ReCaptcha from '@/components/CRM/settings/securitySettings/ReCaptcha.vue'

import Update from '@/components/CRM/settings/systemSettings/Update.vue'
import Tools from '@/components/CRM/settings/systemSettings/Tools.vue'
import Translator from '@/components/CRM/settings/systemSettings/Translator.vue'
import Logs from '@/components/CRM/settings/systemSettings/Logs.vue'
import SystemInfo from '@/components/CRM/settings/systemSettings/SystemInfo.vue'

import Activities from '@/components/CRM/settings/Activities.vue'
import Deals from '@/components/CRM/settings/Deals.vue'
import Documents from '@/components/CRM/settings/Documents.vue'
import Products from '@/components/CRM/settings/Products.vue'
import Companies from '@/components/CRM/settings/Companies.vue'
import Calls from '@/components/CRM/settings/Calls.vue'
import Users from '@/components/CRM/settings/Users.vue'
import Brands from '@/components/CRM/settings/Brands.vue'

export default {
  name: 'CRMSetting',
  components: {
    General,

    CompanyFields,
    ContactFields,
    DealsFields,
    ProductFields,
    
    PusherIntegration,
    MicrosoftIntegration,
    GoogleIntegration,
    TwilioIntegration,
    ZapierIntegration,

    GeneralSecurity,
    ReCaptcha,

    Update,
    Tools,
    Translator,
    Logs,
    SystemInfo,

    Activities,
    Deals,
    Documents,
    Products,
    Companies,
    Calls,
    Users,
    Brands,
  },
  data() {
    return {
      selectTab: 'General'
      
    };
  },
}
</script>

<style scoped>
@keyframes growProgressBar {
  0%, 33% { --pgPercentage: 0; }
  100% { --pgPercentage: var(--value); }
}

@property --pgPercentage {
  syntax: '<number>';
  inherits: false;
  initial-value: 0;
}

div[role="progressbar"] {
  --size: 12rem;
  --fg: #369;
  --bg: #def;
  --pgPercentage: var(--value);
  animation: growProgressBar 3s 1 forwards;
  width: var(--size);
  height: var(--size);
  border-radius: 50%;
  display: grid;
  place-items: center;
  background: 
    radial-gradient(closest-side, white 80%, transparent 0 99.9%, white 0),
    conic-gradient(var(--fg) calc(var(--pgPercentage) * 1%), var(--bg) 0)
    ;
  font-family: Helvetica, Arial, sans-serif;
  font-size: calc(var(--size) / 5);
  color: var(--fg);
}

div[role="progressbar"]::before {
  counter-reset: percentage var(--value);
  content: counter(percentage) '%';
}


</style>
