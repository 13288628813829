<template>
  <modal class="dialer_animation right_side_popup m-w-500 chatUserModal" width="500px" height="auto" :name="modalName" @before-open="beforeOpen">
    <section class="ct-user-new dialer-edit"> 
      <div class="dialer-form">
        <div class="dialer-edit-header mt-2">
          <h2 class="dialer-edit-title">Chat</h2>
          <div class="dialer-edit-actions">
            <a v-if="!$store.getters.getIsMobile" class="newCloseButton" @click="$modal.hide(modalName)">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
            <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          </div>
        </div>
        <Chatbox :channel_id="channel_id" @change-channel-id="channel_id=$event" ref="chat_box" type="chatModal" />
      </div>
    </section>
  </modal>
</template>

<script>
import Chatbox from "../../Chat/Box/index";
import { LOGGER } from "../../../utils";
export default {
  name: "TeamChatModal",
  components: {
    Chatbox,
  },
  props: {
    modalName: {
      type: String,
      default: 'TeamChatModal'
    },
  },
  data() {
    return {
      channel_id: "",
    };
  },
  methods: {
    async beforeOpen(event) {
      try {
        const channel_id = event.params.channel_id;
        if (!channel_id) {
          this.$modal.hide(this.modalName)
        } else {
          this.channel_id = channel_id
        }
      } catch (error) {
        LOGGER.danger("error in beforeOpen function", error);
      }
    },
  },
};
</script>
