var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "nodeBasic-new"
  }, [_c('Info', {
    attrs: {
      "id": _vm.voipaccount,
      "is_blf": false
    }
  }), _c('div', {
    staticClass: "w-100 d-flex justify-content-end"
  }, [_c('span', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "pencil-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('edit');
      }
    }
  })], 1)])], 1), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-center",
    staticStyle: {
      "width": "200px"
    }
  }, [_c('div', {
    staticClass: "lineWithArrow"
  }, [_c('div', {
    staticClass: "lineWithArrow-line"
  }), _c('div', {
    staticClass: "lineWithArrow-arrow"
  })])]);

}]

export { render, staticRenderFns }