<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup scrollable dialer-analytics-user-modal" 
      width="60%" 
      height="auto" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose($event)"
    >
      <div class="dialer-edit-header pb-0">
        <div>
          <h2 class="dialer-settings-title newer mb-0">User analytics</h2>
        </div>
        <div class="dialer-edit-actions">
          <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="dialer-analytics-user dialer-analytics-tray">
        <div class="analytics-newHeader mb-20px">
          <div class="analytics-newHeader-leftSide">
            <h1 v-if="user" class="dialer-settings-title newer mb-0">{{ user.user.display_name }} ({{ user.extn }})</h1>
          </div>
          <div class="analytics-newHeader-rightSide">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px">
              <b-dropdown varient="link" no-caret class="ico-filter" right> 
                <template #button-content>
                  <span class="mr-12px" v-if="dateLabel">{{ dateLabel }}</span>
                  <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
                </template>
                <b-dropdown-item v-for="period in periods" :key="period.value" @click="filters.stat.period=period.value;filters.stat.period=='custom' ? $modal.show(`${_uid}-dateFilter`) : fetchStat()" >
                  <vb-icon icon="markAsComplete-icon" :style="`opacity:${filters.stat.period==period.value?'1':'0'}`" class="mr-12px" width="14.49px" height="10.431px" />
                  <span >{{period.text}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023">
          <!-- stats -->
          <div class="d-flex align-items-center justify-content-between flex-wrap w-100">
            <div class="d-flex flex-column mb-3">
              <div class="latestGreyBox-descriptionText">Success RATE: {{ response.stat | get_property('response.data.successRate',0) }} %</div>
            </div>
          </div>
          <div class="dialer-analytics-totals v2 updatedAgain mt-20px">
            <div class="dialer-call-total dialer-total">
              <div class="dialer-call-total-inner">
                <div class="dialer-call-total-top">
                  <div class="dialer-call-type">
                    <vb-icon icon="CallSummary-TotalCall-icon"/>
                    <div>total Calls</div>
                  </div>
                </div>
                <div class="dialer-call-figures">
                  <h4 class="dialer-call-number">{{ callpercentage.total }}</h4>
                </div>
              </div>
            </div>
            <div class="dialer-call-total dialer-missed">
              <div class="dialer-call-total-inner">
                <div class="dialer-call-total-top">
                  <div class="dialer-call-type">
                    <vb-icon icon="CallSummary-MissedCall-icon"/>
                    <div>missed Calls</div>
                  </div>
                  <span class="dialer-call-total-time"></span>
                </div>
                <div class="dialer-call-figures">
                  <h4 class="dialer-call-number">{{ callpercentage.missed }}</h4>
                  <div class="newProgressBar">
                    <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${callpercentage.missed_percentage};--fg:#dc3545`">
                      <div class="newProgressBar-inner">
                        {{callpercentage.missed_percentage}}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dialer-call-total dialer-inbound">
              <div class="dialer-call-total-inner">
                <div class="dialer-call-total-top">
                  <div class="dialer-call-type">
                    <vb-icon icon="CallSummary-InboundCall-icon"/>
                    <div>Receive Calls</div>
                  </div>
                  <span class="dialer-call-total-time"></span>
                </div>
                <div class="dialer-call-figures">
                  <h4 class="dialer-call-number">{{ callpercentage.received }}</h4>
                  <div class="newProgressBar">
                    <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${callpercentage.received_percentage};--fg:#28a745`">
                      <div class="newProgressBar-inner">
                        {{callpercentage.received_percentage}}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dialer-call-total dialer-outbound">
              <div class="dialer-call-total-inner">
                <div class="dialer-call-total-top">
                  <div class="dialer-call-type">
                    <vb-icon icon="CallSummary-OutboundCall-icon"/>
                    <div>outbound Calls</div>
                  </div>
                </div>
                <div class="dialer-call-figures">
                  <h4 class="dialer-call-number">{{ callpercentage.outgoing }}</h4>
                  <div class="newProgressBar">
                    <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${callpercentage.outgoing_percentage};--fg:#5576d1`">
                      <div class="newProgressBar-inner">
                        {{callpercentage.outgoing_percentage}}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <!-- graph -->
          <div style="width: 100%; overflow: hidden" class="visavail mt-20px" :id="`conatiner-measure-${data.accountcode}`">
            <p :id="`p-measure-${data.accountcode}`" style="overflow: hidden"></p>
          </div>
          <!-- call logs -->
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-25 minWidth250px">
              <b-dropdown varient="link" no-caret>
                <template #button-content>
                  <span class="mr-3">Filter</span>
                  <b-icon icon="chevron-down" />
                </template>
                <b-dropdown-text>
                  <ul class="d-flex flex-column list-unstyled mb-0">
                    <li v-for="calltype in calltypes" :key="calltype.value" class="d-inline-flex px-1 justify-content-between align-items-center">
                      <label class="mb-0">{{calltype.text}}</label>
                      <b-form-radio class="before-after-checkbox-align-center" v-model="filters.stat.call_type" name="some-radios" :value="calltype.value"></b-form-radio>
                    </li>
                  </ul>
                </b-dropdown-text>
              </b-dropdown>
            </div>
          </div>
          <div class="mt-20px w-100">
            <template v-if="api.stat.send">
              <div class="latestShimmerDesign w-100" style="height: 40px;margin-bottom: 12px;" v-for="n in 10" :key="n.id"></div>
            </template>
            <vb-no-record v-else-if="isEmpty(callLogs)" class="my-5" :text="'There is no Call Logs'" :design="3" />
            <template v-else>
              <CallActivityTestItem v-for="call in callLogs" :key="call.id" :call="call" :conditions="{ actions: false, }" />
            </template>
          </div>
        </div>
      </div>
    </modal>
    <modal :name="`${_uid}-dateFilter`" class="callReportingFilterModal">
      <div class="dialer-edit-header pt-0 position-relative">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Filters</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" type="button" @click="$modal.hide(`${_uid}-dateFilter`)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="analytics-newHeader-text">
          <div class="d-flex customDate">
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-50 pr-2">
              <span>From:</span>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filters.stat.start_date" id="starttime" />
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned w-50 pl-2">
              <span>To:</span>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filters.stat.end_date" id="endtime" />
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end mt-20px">
            <a class="newButton" @click="fetchStat();$modal.hide(`${_uid}-dateFilter`)">
              <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Fetch</span>
            </a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import * as visavail from "visavail";
import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import { ctkDateTimePicker } from '../../mixin';
import CallActivityTestItem from "../Home/CallActivity/TestItem/index.vue";
const period_filter = {
  today: {
    text: "Today",
    value: "today",
    icon: 'squared-today-icon'
  },
  week: {
    text: "This week",
    value: "week",
    icon: 'squared-thisWeek-icon'
  },
  month: {
    text: "This month",
    value: "month",
    icon: 'squared-thisMonth-icon'
  },
  custom: {
    text: "Custom",
    value: "custom",
    icon: 'squared-customDate-icon'
  },
}
const call_types = [
  {
    text: "All",
    value: "all",
  },
  {
    text: "Outgoing calls",
    value: "Dialed",
  },
  {
    text: "Incoming calls",
    value: "Received",
  },
  {
    text: "Missed calls",
    value: "Missed",
  },
]
export default {
  name: "UserAnalyticsModal",
  mixins: [ctkDateTimePicker],
  components: {
    CallActivityTestItem,
  },
  inject:[
    'isEmpty',
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'UserAnalytics',
    },
  },
  data() {
    return {
      response: this.$helperFunction.stateInstance({
        data : {
          stat: {},
        }
      }),
      filters: {
        stat: this.$helperFunction.stateInstance({
          data: {
            call_type: call_types[0].value,
            period: period_filter.today.value,
            start_date: null,
            end_date: null,
          }
        }),
        reset(){
          this.stat?.reset?.()
        },
      },
      data: this.$helperFunction.stateInstance({
        data: {
          accountcode: '',
        }
      }),
      api: {
        stat: this.$helperFunction.apiInstance(),
        reset(){
          this.stat?.reset?.()
        },
      },
      chart: this.$helperFunction.stateInstance({
        data: {
          timeline: null,
        }
      }),
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'getVoipUsersAlises',
    ]),
    periods(){ return period_filter },
    calltypes(){ return call_types },
    callLogs() { return this.response.stat?.response?.data?.callLogs?.filter?.(i=>this.filters.stat.call_type=='all'?true:i.status==this.filters.stat.call_type) ?? [] },
    callpercentage(){
      const total = this.response.stat?.response?.data?.totalCalls || 0
      const received = this.response.stat?.response?.data?.totalReceivedCalls || 0
      const missed = this.response.stat?.response?.data?.totalMissCalls || 0
      const outgoing = this.response.stat?.response?.data?.totalOutgoingCalls || 0
      const received_percentage = received > 0 ? Math.ceil((received / total) * 100) : 0;
      const outgoing_percentage = outgoing > 0 ? Math.ceil((outgoing / total) * 100) : 0;
      const missed_percentage = missed > 0 ? Math.ceil((missed / total) * 100) : 0;
      return {
        total,
        received,
        outgoing,
        missed,
        received_percentage,
        outgoing_percentage,
        missed_percentage,
      };
    },
    dateLabel(){ return this.response.stat?.response?.date_label ?? '' },
    user(){ return this.getVoipUsersAlises[this.data.accountcode] },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.accountcode = event.params?.accountcode;
      this.fetchStat();
    },
    onBeforeClose(){
      this.response.reset()
      this.filters.reset()
      this.data.reset()
      this.api.reset()
      this.chart.timeline?.destroy?.()
      this.chart.reset()
    },
    async fetchStat(){
      if(this.api.stat.send) return;
      if(this.filters.stat.period=='custom' && (!this.filters.stat.start_date || !this.filters.stat.end_date)) return;
      try {
        this.api.stat.send=true
        const { data } = await VOIP_API.endpoints.analytics.singlestat({
          accountcode: this.data.accountcode,
          type: this.filters.stat.period,
          clean_data: 'yes',
          ...this.filters.stat.period=='custom' ? {
            start: this.filters.stat.start_date,
            end: this.filters.stat.end_date,
          } : {},
        })
        this.response.stat = data;
        if(data.timeLineData) {
          const datasets = data.timeLineData?.exten?.map?.(item=>{
            let measure = item.accountname
            if(item.accountname.includes('extension')) measure = item.accountname.replace('extension','app')
            else if(item.accountname=='WEBEXT') measure = 'web app'
            else if(item.accountname=='DESKTOPEXT') measure = 'desktop app'
            else if(item.accountname=='MOBILEEXT') measure = 'mobile app'
            else if(item.accountname=='HEADEXT') measure = 'headset app'
            else if(item.accountname=='INTEGRATIONEXT') measure = 'integration app'
            else if(item.accountname=='EXTERNALEXT') measure = 'external app'
            return {
              ...item,
              measure: measure
            }
          }) ?? []
          datasets.unshift({
            accountname: data.accountcode,
            data: data.timeLineData.data,
            interval_s: data.timeLineData.interval_s,
            measure: "User",
          })
          if(this.chart.timeline) {
            this.chart.timeline.updateGraph(datasets)
          } else {
            this.chart.timeline = visavail.generate({
              id_div_container: `conatiner-measure-${this.data.accountcode}`,
              id_div_graph: `p-measure-${this.data.accountcode}`,
              date_in_utc: false,
              width: document.getElementById(`p-measure-${this.data.accountcode}`).offsetWidth,
              line_spacing: 24,
              title: {
                text: "Phone Status",
              },
              icon: {
                class_has_data: "fas fa-fw fa-check",
                class_has_no_data: "fas fa-fw fa-exclamation-circle",
              },
              legend: {
                enabled: true,
                has_data_text: 'Online',
                has_no_data_text: 'Offline',
              },
              tooltip: {
                height: 18,
                position: "overlay",
                left_spacing: 20,
                only_first_date: false,
                date_plus_time: true,
              },
              graph: {
                type: 'bar'
              },
              responsive: {
                enabled: true,
              },
            },datasets);
          }
        }
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.stat.send=false
      }
    },
  },
};
</script>

<style scoped>
@import "../../../node_modules/visavail/visavail.min.css";
.allow_scroll {
  height: calc(100vh - 460px);
  overflow-y: auto;
  margin: 10px 0px;
}
.dialer-dropdown .dropdown .dropdown-menu {
  border: 0;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.21);
  width: 100%;
  min-width: 580px;
  line-height: normal;
  padding: 10px;
  transform: none;
  left: unset;
  right: 0;
  top: 100%;
}
.dialer-dropdown .dropdown .dropdown-menu li ul {
  box-shadow: none;
}
.dialer-dropdown .dropdown .b-dropdown-text {
  padding: 0px;
}
</style>
