<template>
  <div class="crm-main-section ProductSection">
    <div class="crm-main-section-topBar">
      <h2 class="crm-main-section-heading mb-0">
        Products
      </h2>
      <div class="section-topBar-middlePart">
        <vb-icon icon="crm-inputSearch-icon" />
        <input type="text" placeholder="Search">
      </div>
      <div class="section-topBar-lastPart">
        <!-- <button class="crm-button listGridView-btn centerBtn ml-0">
          <vb-icon class="crm-threeDotsHorizontal-icon" icon="crm-threeDotsHorizontal-icon" />
        </button> -->
        <button class="newButton" @click="$modal.show('CreateProductModal')">
          <vb-icon icon="squared-add-icon" height="38px" width="38px" />
          <span class="newButton-textPart position-relative d-flex justify-content-center">
            Create Products
          </span>
        </button>
      </div>
    </div>
    <div class="crm-main-section-body">
      <div class="w-100 d-flex justify-content-end align-items-center mb-3">
        <div class="crm-input-container mb-0">
          <input placeholder="Search" class="w-100"/>
          <vb-icon icon="crm-inputSearch-icon" />
        </div>
        <!-- <div class="totalsTextOnMain">
          1 Products
        </div> -->
      </div>
      <vb-table class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
        <template slot="header">
          <tr>
            <th>
              <b-form-checkbox class="crm-checkbox" id="checkbox-1"></b-form-checkbox>
            </th>
            <th class="dialer-has-sort">
              <span class="dialer-sorted-by ascending">Name</span>
            </th>
            <th class="dialer-has-sort">
              <span>SKU</span>
            </th>
            <th class="dialer-has-sort">
              <span>Unit Price</span>
            </th>
            <th class="dialer-has-sort">
              <span>Tax Rate</span>
            </th>
            <th class="dialer-col-right cursor_pointer">
              <span>Active</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
            <td>
              <b-form-checkbox class="crm-checkbox" v-model="data.chk" id="checkbox-1"></b-form-checkbox>
            </td>
            <td class="dialer-row-title">
              <span>{{ data.m1 }}</span>
            </td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-col-right">
              <div :class="`StatusInsideTable ml-auto ${index == 2 ? 'active' : 'notActive'}`"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    
    <modal
      class="dialer_animation right_side_popup scrollable bigSquareCheckbox IosModal CRM-modal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      name="CreateProductModal"
    >
      <div class="CRM-modal-header">
        <div class="CRM-modal-header-text">Create product</div>
        <a class="newCloseButton" @click="$modal.hide('CreateProductModal')">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </a>
      </div>
      <b-form class="latestGreyBox-9-9-2023">
        <div class="crm-input-container withoutIcon">
          <label>Name</label>
          <b-form-input placeholder="Name" />
        </div>
        <div class="crm-input-container withoutIcon">
          <label>SKU</label>
          <b-form-input placeholder="SKU" />
        </div>
        <div class="crm-input-container withoutIcon">
          <label>Description</label>
          <textarea class="w-100" placeholder="Description" />
        </div>
        <div class="d-flex w-100">
          <div class="crm-input-container min-width-200px pr-2 w-50">
            <label>Unit price</label>
            <div class="crm-input-inner">
              <div class="crm-input-icon onlyText">
                GBP
              </div>
              <b-form-input placeholder="Unit price" />
            </div>
          </div>
          <div class="crm-input-container min-width-200px pl-2 w-50">
            <label>Direct cost</label>
            <div class="crm-input-inner">
              <div class="crm-input-icon onlyText">
                GBP
              </div>
              <b-form-input placeholder="Direct cost" />
            </div>
          </div>
        </div>
        <div class="d-flex w-100">
          <div class="crm-input-container withoutIcon min-width-200px pr-2 w-50">
            <label>Tax rate</label>
            <b-form-input placeholder="Tax rate" />
          </div>
          <div class="crm-input-container withoutIcon min-width-200px pl-2 w-50">
            <label>Tax label</label>
            <b-form-input placeholder="TAX" />
          </div>
        </div>
        <div class="crm-input-container withoutIcon">
          <label>Unit ( kg , lots )</label>
          <b-form-input placeholder="Unit ( kg , lots )" />
        </div>

        <b-button type="submit" class="fullWidthDoneButton mt-20px">
          Submit
        </b-button>
      </b-form>
    </modal>

  </div>
</template>

<script>
// import axios from 'axios'
// import { VOIP_API } from '@/utils'
import { mapGetters, mapState } from 'vuex'
import { GET_CRM_USER } from '@/store/helper/getters'
// import required from 'vuelidate/lib/validators/required'
// import numeric from 'vuelidate/lib/validators/numeric'
// import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { ctkDateTimePicker } from '@/mixin'

export default {
  name: 'Products',
  mixins: [ctkDateTimePicker],
  components: {
    // vSelect,
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      }
    }
  },
  computed: {
    ...mapGetters([
      GET_CRM_USER,
    ]),
    ...mapState({
      voipusers: (state) => Object.values(state.common.voipusers),
    }),
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>