<template>
  <modal 
    class="dialer_animation right_side_popup m-w-700 CallRecordingEditModal addExtension-voicemailModal addRemove_userModal min-width-50percent-modal" 
    height="auto" 
    :name="modalName" 
    :scrollable="true" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <div class="dialer-settings-main b-0">
      <section class="dialer-settings-section px-0">
        <div v-if="getIsMobile" class="d-flex w-100 justify-content-between align-items-center">
          <h2 class="dialer-settings-title">Configure call recording</h2>
          <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
        </div>
        <div v-else class="d-flex w-100 justify-content-between align-items-center mt-16px">
          <h2 class="dialer-settings-title newer mb-0">Call recording settings for ({{allUserData.user}})({{allUserData.extn}})</h2>
          <button class="newDoneButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
        <div class="vm--modal-text">Set your preference for user-based call recording. When enabled, this extension can record calls on demand or automatically record all calls.</div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
              <div class="d-flex align-items-center justify-content-between w-100">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main">On demand call recording</div>
                  <div class="latestGreyBox-descriptionText">Capture important conversations whenever you need, with just a single click on the dialer.</div>
                </div>
                <div 
                  v-b-tooltip :title="getUserPermissions.call_recording?'':'Your package does not support this feature'"
                  class="dialer-dropdown dialer-combineDropDown"
                >
                  <div :class="['',!getUserPermissions.call_recording ? 'for-disable' : '']">
                    <b-form-checkbox 
                      ref="ondeman_check" 
                      class="newerSwitch" 
                      switch 
                      :disabled="api.update.send || !getUserPermissions.call_recording"
                      :checked="selected.setting!='off'" 
                      @change="updateSetting($event?'ondemand':'off')" 
                    />
                  </div>
                </div>
              </div>
              <template v-if="['ondemand','auto'].includes(selected.setting)">
                <div class="latestGreyBox-9-9-2023 mt-20px w-100">
                  <div class="whiteBoxWithBorderInsideGreyBox">
                    <div class="whiteBoxWithBorderInsideGreyBox-row">
                      <div class="d-flex flex-column">
                        <div class="latestGreyBox-heading-main">Automatically record all calls</div>
                        <div class="latestGreyBox-descriptionText">All calls will be recorded automatically in the background.</div>
                      </div>
                      <div class="dialer-dropdown dialer-combineDropDown">
                        <div>
                          <b-form-checkbox 
                            v-b-tooltip :title="getUserPermissions.call_recording?'':'Your package does not support this feature'"
                            ref="auto_check" 
                            class="newerSwitch" 
                            switch 
                            :disabled="api.update.send|| !getUserPermissions.call_recording"
                            :checked="selected.setting=='auto'" 
                            @change="updateSetting($event?'auto':'ondemand')" 
                          />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div>
            <h3>Mino</h3>
            <p><b>Storage:</b> {{ response.storage | get_property('accountstorage.mino',0) | mbToBytes | format_bytes }}</p>
            <p><b>Files:</b> {{ response.storage | get_property('accountstorage.file_count',0) }}</p>
          </div>
          <div>
            <h3>Centeral</h3>
            <p><b>Storage:</b> {{ response.storage | get_property('accountstorage.central_storage',0) | mbToBytes | format_bytes }}</p>
            <p><b>Files:</b> {{ response.storage | get_property('accountstorage.central_files',0) }}</p>
          </div>
        </div>
      </section>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { VOIP_API } from '../../utils'
export default {
  name: 'CallRecordingEditModal',
  props: {
    modalName: {
      type: String,
      default: 'CallRecordingEditModal',
    },
  },
  data(){
    return {
      api: {
        update: this.$helperFunction.apiInstance(),
        storage: this.$helperFunction.apiInstance(),
      },
      allUserData: {},
      selected: {
        setting: '',
      },
      data: {
        account: '',
      },
      response: {
        storage: {},
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getIsMobile'
    ]),
    recordingOptions(){
      return [
        {
          text: 'off',
          value: 'off',
        },
        {
          text: 'Auto call recording',
          value: 'auto',
        },
        {
          text: 'call recording ondemand',
          value: 'ondemand',
        },
      ]
    },
  },
  filters: {
    mbToBytes(value){ return value/1000000 },
  },
  methods: {
    onBeforeOpen(event){
      this.data.account = event?.params?.account ?? ''
      this.selected.setting = event?.params?.setting ?? ''
      if(event?.params.editUserModal){
        this.allUserData = event?.params?.allUserData ?? ''
      }
      else{
        this.allUserData = event?.params?.user_info ?? ''
      }
      this.fetchStorage()
    },
    onBeforeClose(){
      this.api.update.reset()
      this.api.storage.reset()
      this.data.account=''
      this.selected.setting=''
      this.response.storage={}
    },
    async updateSetting(value) {
      if(this.api.update.send) return;
      try {
        this.api.update.send = true;
        await VOIP_API.endpoints.users.updateusercallrecordingsetting({
          ondemand: value === "ondemand" ? "1" : "0",
          auto: value === "auto" ? "1" : "0",
          off: value === "off" ? "1" : "0",
          account: this.data.account,
        })
        this.selected.setting=value
        this.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        this.$emit('latest');
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['ondeman_check']) this.$refs['ondeman_check'].localChecked = this.selected.setting!='off';
        if(this.$refs['auto_check']) this.$refs['auto_check'].localChecked = this.selected.setting=='auto';
        this.api.update.send = false;
      }
    },
    async fetchStorage(){
      if(this.api.storage.send) return;
      try {
        this.api.storage.send=true
        const { data } = await VOIP_API.endpoints.call_activity.getStorage({
          accountcode: this.data.account,
        })
        this.response.storage = data ?? {};
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.storage.send=false
      }
    },
  },
}
</script>

<style>

</style>