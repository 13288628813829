var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "EditSpaceModal FilterTasksBy",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "dialer-edit-header position-relative pt-0"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Filter tasks by")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        _vm.api.save_filter.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.api.save_filter.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "inputLikewhiteBox-container"
  }, [_c('label', [_vm._v("Assignees")]), _c('div', {
    staticClass: "inputLikewhiteBox"
  }, [_c('vb-icon', {
    staticClass: "pencil-fill",
    attrs: {
      "icon": "pencil-fill"
    },
    on: {
      "click": function ($event) {
        _vm.api.save_filter.send ? '' : _vm.$modal.show(`${_vm.modalName}-SelectUsers`);
      }
    }
  }), _vm.assigned.array.length > 0 ? _c('div', {
    staticClass: "inputLikewhiteBox-row lessPadding"
  }, [_c('div', {
    staticClass: "d-flex align-items-center stackedAvatars-container"
  }, [_vm._l(_vm.assigned.array, function (assigne, index) {
    return _c('vb-avatar', {
      key: assigne,
      style: `z-index:${_vm.assigned.array.length - index}`,
      attrs: {
        "is_blf": false,
        "id": assigne
      }
    });
  }), _vm.assigned.count ? _c('div', {
    staticClass: "countPlus",
    style: `z-index:${_vm.assigned.array.length - 3}`
  }, [_vm._v("+ " + _vm._s(_vm.assigned.count))]) : _vm._e()], 2)]) : _c('div', {
    staticClass: "inputLikewhiteBox-row"
  }, [_vm._v(" Nothing selected ")])], 1)]), _c('div', {
    staticClass: "inputLikewhiteBox-container mt-16px"
  }, [_c('label', [_vm._v("Statuses")]), _c('div', {
    staticClass: "inputLikewhiteBox"
  }, [_c('vb-icon', {
    staticClass: "pencil-fill",
    attrs: {
      "icon": "pencil-fill"
    },
    on: {
      "click": function ($event) {
        _vm.api.save_filter.send ? '' : _vm.$modal.show(`${_vm.modalName}-SelectStatuses`);
      }
    }
  }), _vm._l(_vm.selected.statuses, function (status_id) {
    return _c('div', {
      key: status_id,
      staticClass: "inputLikewhiteBox-row"
    }, [_vm.statusesHashMap[status_id] ? [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "color-square",
      style: `background-color: ${_vm.statusesHashMap[status_id].color};`
    }), _vm._v(" " + _vm._s(_vm.statusesHashMap[status_id].title) + " ")]), _vm.statusesHashMap[status_id].space ? _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "color-square",
      style: `background-color: ${_vm.statusesHashMap[status_id].space.color};`
    }), _vm._v(" " + _vm._s(_vm.statusesHashMap[status_id].space.name) + " ")]) : _vm._e()] : _vm._e()], 2);
  }), _vm.selected.statuses.length == 0 ? _c('div', {
    staticClass: "inputLikewhiteBox-row"
  }, [_vm._v(" Nothing selected ")]) : _vm._e()], 2)]), _c('div', {
    staticClass: "inputLikewhiteBox-container mt-16px"
  }, [_c('label', [_vm._v("Groups")]), _c('div', {
    staticClass: "inputLikewhiteBox"
  }, [_c('vb-icon', {
    staticClass: "pencil-fill",
    attrs: {
      "icon": "pencil-fill"
    },
    on: {
      "click": function ($event) {
        _vm.api.save_filter.send ? '' : _vm.$modal.show(`${_vm.modalName}-SelectGroups`);
      }
    }
  }), _vm._l(_vm.selected.groups, function (group_id) {
    return _c('div', {
      key: group_id,
      staticClass: "inputLikewhiteBox-row"
    }, [_vm.groupsHashMap[group_id] ? [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "color-square",
      style: `background-color: ${_vm.groupsHashMap[group_id].color};`
    }), _vm._v(" " + _vm._s(_vm.groupsHashMap[group_id].name) + " ")]), _vm.groupsHashMap[group_id].space ? _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "color-square",
      style: `background-color: ${_vm.groupsHashMap[group_id].space.color};`
    }), _vm._v(" " + _vm._s(_vm.groupsHashMap[group_id].space.name) + " ")]) : _vm._e()] : _vm._e()], 2);
  }), _vm.selected.groups.length == 0 ? _c('div', {
    staticClass: "inputLikewhiteBox-row"
  }, [_vm._v(" Nothing selected ")]) : _vm._e()], 2)]), _c('div', {
    staticClass: "inputLikewhiteBox-container mt-16px"
  }, [_c('label', [_vm._v("Priority")]), _c('div', {
    staticClass: "inputLikewhiteBox"
  }, [_c('vb-icon', {
    staticClass: "pencil-fill",
    attrs: {
      "icon": "pencil-fill"
    },
    on: {
      "click": function ($event) {
        _vm.api.save_filter.send ? '' : _vm.$modal.show(`${_vm.modalName}-SelectPriority`);
      }
    }
  }), _vm._l(_vm.selected.priorities, function (priority) {
    return _c('div', {
      key: priority,
      staticClass: "inputLikewhiteBox-row"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.priorities[priority] ? [_c('div', {
      staticClass: "color-square",
      style: `background-color: ${_vm.priorities[priority].color};`
    }), _vm._v(" " + _vm._s(_vm.priorities[priority].text) + " ")] : _vm._e()], 2)]);
  }), _vm.selected.priorities.length == 0 ? _c('div', {
    staticClass: "inputLikewhiteBox-row"
  }, [_vm._v(" Nothing selected ")]) : _vm._e()], 2)]), _c('div', {
    staticClass: "mt-20px whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Save this filter")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.save_filter.send,
      "checked": _vm.conditions.save,
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.conditions.save,
      callback: function ($$v) {
        _vm.$set(_vm.conditions, "save", $$v);
      },
      expression: "conditions.save"
    }
  })], 1), _vm.conditions.save ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.saveFilter();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.save_filter.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.save_filter.error_message))]), _c('label', [_vm._v("Filter name")]), _c('div', {
    staticClass: "d-flex align-items-center w-100"
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.save_filter.send,
      "maxlength": _vm.$v.forms.save_filter.label.$params.maxLength.max
    },
    model: {
      value: _vm.forms.save_filter.label,
      callback: function ($$v) {
        _vm.$set(_vm.forms.save_filter, "label", $$v);
      },
      expression: "forms.save_filter.label"
    }
  })], 1), _vm.forms.save_filter.submitted && _vm.$v.forms.save_filter.label.$invalid || _vm.api.save_filter.validation_errors.label ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.save_filter.label.required ? _c('span', [_vm._v("Label is required")]) : !_vm.$v.forms.save_filter.label.alpha ? _c('span', [_vm._v("Label can only be alphabet character")]) : !_vm.$v.forms.save_filter.label.maxLength ? _c('span', [_vm._v("Label can maximum " + _vm._s(_vm.$v.forms.save_filter.label.$params.maxLength.max) + " character")]) : _vm.api.save_filter.validation_errors.label ? _vm._l(_vm.api.save_filter.validation_errors.label, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }) : _vm._e()], 2) : _vm._e()], 1)], 1) : _vm._e()])])]), _vm.conditions.save ? [_c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.saveFilter();
      }
    }
  }, [_vm._v(" Save ")])])] : [_c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.onDone();
      }
    }
  }, [_vm._v(" Done ")])])]], 2)]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 TODOfilterByModal addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue",
    attrs: {
      "name": `${_vm.modalName}-SelectUsers`,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    }
  }, [_c('div', [_c('div', {
    staticClass: "dialer-edit-header position-relative"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v(" Filter by assignees ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm.modalName}-SelectUsers`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.users.search,
      expression: "filters.users.search"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.filters.users.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.users, "search", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center",
    on: {
      "click": function ($event) {
        _vm.allSelectedUsers = !_vm.allSelectedUsers;
      }
    }
  }, [_vm.allSelectedUsers ? _c('b-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "check-square-fill",
      "height": "24px",
      "width": "24px"
    }
  }) : _c('b-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "square",
      "variant": "#ACACAC",
      "height": "24px",
      "width": "24px"
    }
  }), _vm._v(" Select all ")], 1)]), _vm._l(_vm.filterUsers, function (user) {
    return _c('div', {
      key: user,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3"
    }, [_c('div', {
      staticClass: "d-flex align-items-center",
      on: {
        "click": function ($event) {
          _vm.selected.assignees.includes(user) ? _vm.selected.assignees.splice(_vm.selected.assignees.indexOf(user), 1) : _vm.selected.assignees.push(user);
        }
      }
    }, [_vm.selected.assignees.includes(user) ? _c('b-icon', {
      staticClass: "mr-16px",
      attrs: {
        "icon": "check-square-fill",
        "height": "24px",
        "width": "24px"
      }
    }) : _c('b-icon', {
      staticClass: "mr-16px",
      attrs: {
        "icon": "square",
        "variant": "#ACACAC",
        "height": "24px",
        "width": "24px"
      }
    }), _c('Info', {
      attrs: {
        "id": user,
        "is_blf": false
      }
    })], 1)]);
  })], 2)])])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 TODOfilterByModal addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue",
    attrs: {
      "name": `${_vm.modalName}-SelectStatuses`,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    }
  }, [_c('div', [_c('div', {
    staticClass: "dialer-edit-header position-relative"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Filter by status")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm.modalName}-SelectStatuses`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.statuses.search,
      expression: "filters.statuses.search"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.filters.statuses.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.statuses, "search", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center",
    on: {
      "click": function ($event) {
        _vm.allSelectedStatuses = !_vm.allSelectedStatuses;
      }
    }
  }, [_vm.allSelectedStatuses ? _c('b-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "check-square-fill",
      "height": "24px",
      "width": "24px"
    }
  }) : _c('b-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "square",
      "variant": "#ACACAC",
      "height": "24px",
      "width": "24px"
    }
  }), _vm._v(" Select all ")], 1)]), _vm._l(_vm.filterStatuses, function (status) {
    return _c('div', {
      key: status.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3"
    }, [_c('div', {
      staticClass: "d-flex align-items-center",
      on: {
        "click": function ($event) {
          _vm.selected.statuses.includes(status.id) ? _vm.selected.statuses.splice(_vm.selected.statuses.indexOf(status.id), 1) : _vm.selected.statuses.push(status.id);
        }
      }
    }, [_vm.selected.statuses.includes(status.id) ? _c('b-icon', {
      staticClass: "mr-16px",
      attrs: {
        "icon": "check-square-fill",
        "height": "24px",
        "width": "24px"
      }
    }) : _c('b-icon', {
      staticClass: "mr-16px",
      attrs: {
        "icon": "square",
        "height": "24px",
        "width": "24px"
      }
    }), _c('div', {
      staticClass: "createSpace-selectColor-container w-auto mr-16px"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item smaller selected",
      style: `background-color: ${status.color};`
    })]), _vm._v(" " + _vm._s(status.title) + " ")], 1), status.space ? _c('div', {
      staticClass: "d-flex align-items-center customClassWidthForTextAtEnd"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-container mr-16px"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item smaller selected",
      style: `background-color: ${status.space.color};`
    })]), _vm._v(" " + _vm._s(status.space.name) + " ")]) : _vm._e()]);
  })], 2)])])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 TODOfilterByModal addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue",
    attrs: {
      "name": `${_vm.modalName}-SelectGroups`,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    }
  }, [_c('div', [_c('div', {
    staticClass: "dialer-edit-header position-relative"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Filter by groups")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm.modalName}-SelectGroups`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.groups.search,
      expression: "filters.groups.search"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.filters.groups.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.groups, "search", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center",
    on: {
      "click": function ($event) {
        _vm.allSelectedGroups = !_vm.allSelectedGroups;
      }
    }
  }, [_vm.allSelectedGroups ? _c('b-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "check-square-fill",
      "height": "24px",
      "width": "24px"
    }
  }) : _c('b-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "square",
      "height": "24px",
      "width": "24px"
    }
  }), _vm._v(" Select all ")], 1)]), _vm._l(_vm.filterGroups, function (group) {
    return _c('div', {
      key: group.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3"
    }, [_c('div', {
      staticClass: "d-flex align-items-center",
      on: {
        "click": function ($event) {
          _vm.selected.groups.includes(group.id) ? _vm.selected.groups.splice(_vm.selected.groups.indexOf(group.id), 1) : _vm.selected.groups.push(group.id);
        }
      }
    }, [_vm.selected.groups.includes(group.id) ? _c('b-icon', {
      staticClass: "mr-16px",
      attrs: {
        "icon": "check-square-fill",
        "height": "24px",
        "width": "24px"
      }
    }) : _c('b-icon', {
      staticClass: "mr-16px",
      attrs: {
        "icon": "square",
        "height": "24px",
        "width": "24px"
      }
    }), _c('div', {
      staticClass: "createSpace-selectColor-container w-auto mr-16px"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item smaller selected",
      style: `background-color: ${group.color};`
    })]), _vm._v(" " + _vm._s(group.name) + " ")], 1), group.space ? _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-container w-auto mr-16px"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item smaller selected",
      style: `background-color: ${group.space.color};`
    })]), _vm._v(" " + _vm._s(group.space.name) + " ")]) : _vm._e()]);
  })], 2)])])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 TODOfilterByModal addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue",
    attrs: {
      "name": `${_vm.modalName}-SelectPriority`,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    }
  }, [_c('div', [_c('div', {
    staticClass: "dialer-edit-header position-relative"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Filter by priority")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm.modalName}-SelectPriority`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.priorities.search,
      expression: "filters.priorities.search"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text"
    },
    domProps: {
      "value": _vm.filters.priorities.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.priorities, "search", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "d-flex align-items-center",
    on: {
      "click": function ($event) {
        _vm.allSelectedPriorities = !_vm.allSelectedPriorities;
      }
    }
  }, [_vm.allSelectedPriorities ? _c('b-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "check-square-fill",
      "height": "24px",
      "width": "24px"
    }
  }) : _c('b-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "square",
      "height": "24px",
      "width": "24px"
    }
  }), _vm._v(" Select all ")], 1)]), _vm._l(_vm.filterPriorities, function (priority) {
    return _c('div', {
      key: priority.value,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3"
    }, [_c('div', {
      staticClass: "d-flex align-items-center",
      on: {
        "click": function ($event) {
          _vm.selected.priorities.includes(priority.value) ? _vm.selected.priorities.splice(_vm.selected.priorities.indexOf(priority.value), 1) : _vm.selected.priorities.push(priority.value);
        }
      }
    }, [_vm.selected.priorities.includes(priority.value) ? _c('b-icon', {
      staticClass: "mr-16px",
      attrs: {
        "icon": "check-square-fill",
        "height": "24px",
        "width": "24px"
      }
    }) : _c('b-icon', {
      staticClass: "mr-16px",
      attrs: {
        "icon": "square",
        "height": "24px",
        "width": "24px"
      }
    }), _c('div', {
      staticClass: "createSpace-selectColor-container w-auto mr-16px"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item smaller selected",
      style: `background-color: ${priority.color};`
    })]), _vm._v(" " + _vm._s(priority.text) + " ")], 1)]);
  })], 2)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }