<template>
  <div>
    <div class="mainHeading withButton">
      Notes
    </div>
    <div class="mainDescription mb-3">
      <br/>
    </div>
    <div class="EmployeeDataNotesTable mt-32px">
      <div class="HrmTableHead-container">
        <div class="HrmTableHead one">Added to</div>
        <div class="HrmTableHead two">Date</div>
        <div class="HrmTableHead three">Note</div>
        <div class="HrmTableHead four">Created by</div>
        <div class="HrmTableHead five">Documents</div>
      </div>
      <vb-table class="HrmTable">
        <template #body>
          <tr class="dialer-row-select">
            <td class="dialer-row-title one">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-textContainer">
                  <div class="nameWithImage-text">Jackson Will</div>
                  <div class="nameWithImage-textSmall">8001</div>
                </div>
              </div>
            </td>
            <td class="dialer-row-title two">
              27/07/2022
            </td>
            <td class="dialer-row-title three">
              In this example from GMail, they launched a tour that announced two new features...
            </td>
            <td class="dialer-row-title four">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-textContainer">
                  <div class="nameWithImage-text">Jackson Will</div>
                  <div class="nameWithImage-textSmall">8001</div>
                </div>
              </div>
            </td>
            <td class="dialer-row-title five"></td>
          </tr>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeDataNotes',
  components: {},
  data(){
    return {
      
    }
  },
}
</script>

<style>

</style>