import { VOIP_API } from "@/utils/index";
import { CRM_FETCH_ACTIVITY_TYPES, CRM_FETCH_PIPELINE_STAGES, CRM_FETCH_PIPELINES, CRM_FETCH_STORE_DATA, CRM_FETCH_USERS, CRM_LOGIN, CRM_REFRESH_TOKEN, CRM_RESET } from "../helper/actions";
import { GET_CRM_ACTIVITY_TYPES, GET_CRM_API_URL, GET_CRM_IS_CONNECTED, GET_CRM_PIPELINE_STAGES, GET_CRM_PIPELINES, GET_CRM_TOKEN, GET_CRM_USER, GET_CRM_USERS } from "../helper/getters";
import { SET_CRM_ACTIVITY_TYPES, SET_CRM_API_URL, SET_CRM_PIPELINE_STAGES, SET_CRM_PIPELINES, SET_CRM_TOKEN, SET_CRM_USER, SET_CRM_USERS } from "../helper/mutations";
import _ from 'lodash'
const api_sent = {
  refresh: false,
}

export default {
  state: {
    user: {},
    api_url: '',
    token: '',
    pipelines: [],
    pipeline_stages: [],
    activity_types: [],
    users: [],
  },
  getters: {
    [GET_CRM_TOKEN](state){ return state.token },
    [GET_CRM_USER](state){ return state.user },
    [GET_CRM_API_URL](state){ return state.api_url },
    [GET_CRM_IS_CONNECTED](state,getters){ return !!getters[GET_CRM_TOKEN] },
    [GET_CRM_PIPELINES](state){ return state.pipelines },
    [GET_CRM_PIPELINE_STAGES](state){ return state.pipeline_stages },
    [GET_CRM_ACTIVITY_TYPES](state){ return state.activity_types },
    [GET_CRM_USERS](state){ return state.users },
  },
  actions: {
    async [CRM_LOGIN](context){
      const { getCurrentUser, fpData } = context.rootGetters
      const { data: { accessToken, userId, email, name } } = await VOIP_API.endpoints.crm.auth.login({
        email: `${getCurrentUser.jid_account}@voip.com`,
        password: getCurrentUser.jid_pass,
        device_name: fpData.device_id,
      })
      context.commit(SET_CRM_TOKEN,accessToken)
      context.commit(SET_CRM_USER,{
        id: userId,
        name: name,
        email: email,
      })
    },
    [CRM_REFRESH_TOKEN]: _.debounce(async function(context){
      api_sent.refresh=true
      await context.dispatch(CRM_LOGIN)
      api_sent.refresh=false
    },3*1000,{
      leading: true,
      trailing: false,
    }),
    async [CRM_FETCH_STORE_DATA](context) {
      context.dispatch(CRM_FETCH_PIPELINES)
      context.dispatch(CRM_FETCH_PIPELINE_STAGES)
      context.dispatch(CRM_FETCH_ACTIVITY_TYPES)
      context.dispatch(CRM_FETCH_USERS)
    },
    async [CRM_RESET](context){
      context.commit(SET_CRM_API_URL)
      context.commit(SET_CRM_TOKEN)
      context.commit(SET_CRM_USER)
      context.commit(SET_CRM_PIPELINES)
      context.commit(SET_CRM_PIPELINE_STAGES)
      context.commit(SET_CRM_ACTIVITY_TYPES)
      context.commit(SET_CRM_USERS)
    },
    async [CRM_FETCH_ACTIVITY_TYPES](context){
      try {
        let activity_types = [], page=1, completed=false
        while (!completed) {
          const { data } = await VOIP_API.endpoints.crm.activity_type.list({
            page: page,
            per_page: 100,
          })
          activity_types=activity_types.concat(data.data)
          page=data.meta.current_page+1
          completed=data.meta.to==data.meta.total
        }
        context.commit(SET_CRM_ACTIVITY_TYPES,activity_types)
      } catch (ex) {
        console.log('CRM activity types',ex)
      }
    },
    async [CRM_FETCH_PIPELINES](context){
      try {
        let pipelines = [], page=1, completed=false
        while (!completed) {
          const { data } = await VOIP_API.endpoints.crm.pipelines.list({
            page: page,
            per_page: 100,
          })
          pipelines=pipelines.concat(data.data)
          page=data.meta.current_page+1
          completed=data.meta.to==data.meta.total
        }
        context.commit(SET_CRM_PIPELINES,pipelines)
      } catch (ex) {
        console.log('CRM piplines',ex)
      }
    },
    async [CRM_FETCH_PIPELINE_STAGES](context){
      try {
        let pipeline_stages = [], page=1, completed=false
        while (!completed) {
          const { data } = await VOIP_API.endpoints.crm.pipeline_stages.list({
            page: page,
            per_page: 1000,
          })
          pipeline_stages=pipeline_stages.concat(data.data)
          page=data.meta.current_page+1
          completed=data.meta.to==data.meta.total
        }
        context.commit(SET_CRM_PIPELINE_STAGES,pipeline_stages)
      } catch (ex) {
        console.log('CRM pipline stages',ex)
      }
    },
    async [CRM_FETCH_USERS](context){
      try {
        let users = [], page=1, completed=false
        while (!completed) {
          const { data } = await VOIP_API.endpoints.crm.users.list({
            page: page,
            per_page: 100,
          })
          users=users.concat(data.data)
          page=data.meta.current_page+1
          completed=data.meta.to==data.meta.total
        }
        context.commit(SET_CRM_USERS,users)
      } catch (ex) {
        console.log('CRM users',ex)
      }
    },
  },
  mutations: {
    [SET_CRM_API_URL](state,data){ 
      state.api_url=data ?? ''
    },
    [SET_CRM_TOKEN](state,data){ 
      state.token=data ?? ''
    },
    [SET_CRM_USER](state,data){ 
      state.user=data ?? {}
    },
    [SET_CRM_PIPELINES](state,data){ 
      state.pipelines=data ?? []
    },
    [SET_CRM_PIPELINE_STAGES](state,data){ 
      state.pipeline_stages=data ?? []
    },
    [SET_CRM_ACTIVITY_TYPES](state,data){ 
      state.activity_types=data ?? []
    },
    [SET_CRM_USERS](state,data){ 
      state.users=data ?? []
    },
  },
};