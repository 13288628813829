var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup developerOptionsModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Features")]), _c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticStyle: {
      "height": "85%",
      "width": "100%",
      "display": "flex"
    }
  }, [_c('div', {
    staticStyle: {
      "width": "50%",
      "height": "100%",
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('button', {
    class: `${_vm.api.fetch_old_call_activity.send ? 'rotate-animation' : ''}`,
    attrs: {
      "disabled": _vm.api.fetch_old_call_activity.send
    },
    on: {
      "click": function ($event) {
        return _vm.fetchOldCallActivity();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "callActivity-refresh",
      "width": "18.979",
      "height": "17.257"
    }
  })], 1)]), _c('div', {
    staticStyle: {
      "overflow-y": "auto"
    },
    attrs: {
      "id": `${_vm._uid}-old-call-activity`
    },
    on: {
      "scroll": function ($event) {
        return _vm.onScrollOldCallActivity($event);
      }
    }
  }, [_vm._l(_vm.oldCallActivities, function (call, index) {
    return [_vm._v(" " + _vm._s(index + 1) + " "), call.own_date ? _c('h3', {
      key: `${index}-date-call-activity`,
      staticClass: "pb-1 ddd"
    }, [_c('span', {
      staticStyle: {
        "color": "black",
        "font-size": "12px"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("filter_date_current")(call.own_date, false, {
      unix: true
    })) + " ")])]) : _vm._e(), _c('li', {
      key: index,
      staticStyle: {
        "list-style-type": "none"
      }
    }, [_c('CallActivityItem1', {
      attrs: {
        "call": call
      }
    })], 1)];
  })], 2)]), _c('div', {
    staticStyle: {
      "width": "50%",
      "height": "100%",
      "display": "flex",
      "flex-direction": "column"
    }
  }, [_c('div', {
    staticStyle: {
      "display": "flex"
    }
  }, [_c('button', {
    class: `${_vm.api.fetch_latest_call_activity.send ? 'rotate-animation' : ''}`,
    attrs: {
      "disabled": _vm.api.fetch_latest_call_activity.send
    },
    on: {
      "click": function ($event) {
        return _vm.fetchLatestCallActivity();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "callActivity-refresh",
      "width": "18.979",
      "height": "17.257"
    }
  })], 1)]), _c('div', {
    staticStyle: {
      "overflow-y": "auto"
    },
    attrs: {
      "id": `${_vm._uid}-latest-call-activity`
    },
    on: {
      "scroll": function ($event) {
        return _vm.onScrollLatestCallActivity($event);
      }
    }
  }, [_vm._l(_vm.latestCallActivities, function (call, index) {
    return [_vm._v(" " + _vm._s(index + 1) + " "), call.own_date ? _c('h3', {
      key: `${index}-date-call-activity`,
      staticClass: "pb-1 ddd"
    }, [_c('span', {
      staticStyle: {
        "color": "black",
        "font-size": "12px"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("filter_date_current")(call.own_date, null, {
      unix: true
    })) + " ")])]) : _vm._e(), _c('li', {
      key: index,
      staticStyle: {
        "list-style-type": "none"
      }
    }, [_c('CallActivityTestItem', {
      attrs: {
        "call": call
      }
    })], 1)];
  })], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }