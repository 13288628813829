<template>
  <modal 
    class="center-zoomIn-transition v2 m-w-500" 
    height="auto" 
    :name="modalName"
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()"
  >
    <form @submit.prevent="changeExtension()" class="w-100">
      <div class="d-flex align-items-start justify-content-between w-100">
        <div class="d-flex flex-column">
          <h2 class="dialer-edit-title">Change extension</h2>
          <p>Enter a strong and memorable extension</p>
        </div>
        <button @click="$modal.hide(modalName)" class="newCloseButton" type="button">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px w-100">
        <b-alert :show="!!api.change_extn.error_message" variant="danger" >{{api.change_extn.error_message}}</b-alert>
        <div class="whiteBGinputWithGreyRoundedBorder w-100">
          <label>Set extension</label>
          <input 
            class="w-100" 
            type="text" 
            onkeypress="return /[0-9]/i.test(event.key)" 
            maxlength="4"
            v-model="forms.change_extn.extn" 
            :disabled="api.change_extn.send" 
          />
          <p class="text text-danger animated bounceIntop mb-0" v-if="(forms.change_extn.submitted && $v.forms.change_extn.extn.$invalid) || api.change_extn.validation_errors.extension">
            <span>* </span>
            <span v-if="!$v.forms.change_extn.extn.required">Extension is required</span>
            <span v-else-if="!$v.forms.change_extn.extn.number">Extension should be only number</span>
            <span v-else-if="!$v.forms.change_extn.extn.valid">Extension should be only 4 digits</span>
            <span v-for="(em,i) in api.change_extn.validation_errors.extension" :key="i">{{ em }}</span>
          </p>
        </div>
      </div>
      <div class="w-100 mt-20px">
        <button :disabled="api.change_extn.send" class="fullWidthDoneButton" type="submit" >
          Update
        </button>
      </div>
    </form>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils'
import required from 'vuelidate/lib/validators/required'
import { mapGetters } from 'vuex'
import { helpers } from 'vuelidate/lib/validators'
export default {
  name: 'UpdateUserExtensionModal',
  props: {
    modalName: {
      type: String,
      default: 'UpdateUserExtension',
    },
  },
  data() {
    return {
      forms: {
        change_extn: this.$helperFunction.formInstance({
          data: {
            extn: '',
          }
        }),
      },
      api: {
        change_extn: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        })
      },
      data: {
        accountcode: '',
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
  },
  validations: {
    forms: {
      change_extn: {
        extn: {
          required: required,
          number: helpers.regex('numeric',/^[0-9]*$/i),
          valid: function (value){ return value.length==4 },
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode=event?.params?.accountcode ?? ''
      this.forms.change_extn.extn=event?.params?.extn ?? ''
    },
    onBeforeClose(){
      this.forms.change_extn.reset()
      this.api.change_extn.reset()
      this.data.accountcode=''
    },
    async changeExtension() {
      this.forms.change_extn.submitted=true
      this.$v.forms.change_extn.$touch()
      if(this.$v.forms.change_extn.$invalid || this.api.change_extn.send) return;
      try {
        this.api.change_extn.send=true
        this.api.change_extn.error_message=''
        this.api.change_extn.validation_errors={}
        await VOIP_API.endpoints.user.updateExtension({
          extn: this.forms.change_extn.extn,
          extension: this.forms.change_extn.extn,
          account: this.data.accountcode,
        })
        this.$emit('updated')
        if(this.data.accountcode==this.getCurrentUser.account) {
          this.$store.state.common.user.extn = this.forms.change_extn.extn;
        }
        this.appNotify({
          message: "Successfully updated",
          type: "success",
        })
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.change_extn.error_message=ex.own_message
        this.api.change_extn.validation_errors=ex.own_errors
      } finally {
        this.forms.change_extn.submitted=false
        this.api.change_extn.send=false
      }
    }
  },
}
</script>

<style>

</style>