<template>
  <div>
    <div class="mainHeading withButton">
      Blackout dates
      <div class="d-flex">
        <button @click="$modal.show('hrmAddNewBlackoutDateModal')" class="dialer-button dialer-button-primary mr-0 withIcon">
          <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
          Add blackout day
        </button>
      </div>
    </div>
    <div class="mainDescription mb-4">
      These are days during which your employees won't be allowed to book any leave.
    </div>
    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Allow to override blackout date for selected policies</div>
      <b-form-checkbox class="HrmCheckBox-container mt-16px">
        Holiday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Sickday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Holiday - UK
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Holiday - PAK
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Holiday - NL
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Business trip
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Sickday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Conference
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Holiday - USA
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Work from home (UK)
      </b-form-checkbox>
      <button class="dialer-button dialer-button-primary mt-32px  w-fit-content mr-0"> Save </button>
    </div>
    <modal class="dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll" name="hrmAddNewBlackoutDateModal">
      <div>
        <div class="modalHeading-container">
          <div class="modalHeading">Blackout date</div>
          <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('hrmAddNewBlackoutDateModal')">Close</button>
        </div>
        <div class="modalInner-container">
          <div class="innerShadowInput-container ">
            <label>Name</label>
            <input />
          </div>
          <div class="d-flex mt-25px">
            <div class="innerShadowInput-container halfWidth">
              <label>From</label>
              <div class="inputWithIconContainer">
                <input class="withIconOnRight primaryBorder noInnerBoxShadow"/>
                <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
              </div>
            </div>
            <div class="innerShadowInput-container halfWidth">
              <label>To</label>
              <div class="inputWithIconContainer">
                <input class="withIconOnRight primaryBorder noInnerBoxShadow"/>
                <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
              </div>
            </div>
          </div>
          <div class="innerShadowInput-container mt-24px">
            <label>Employees assigned:</label>
            <input placeholder="Search by name"/>
          </div>
          <div class="usersList-withCheckBox-container">
            <b-form-checkbox class="HrmCheckBox-container mt-16px">
              Select All
            </b-form-checkbox>
            <b-form-checkbox v-for="n in 5" :key="n" class="HrmCheckBox-container">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img src="/img/1.dd976805.jpeg">
                </div>
                <div class="nameWithImage-text">Marie jane</div>
              </div>
            </b-form-checkbox>
          </div>
          <button class="dialer-button dialer-button-primary mt-32px mr-0"> Create </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'CalendarBlackoutDates',
}
</script>

<style>

</style>