<template>
  <div>
    <div class="latestGreyBox-9-9-2023">
      <ul class="phone-list full-width-call-info-number list-unstyled pr-0 pb-0 overlowX-visible-imp">
        <li class="p-0">
          <!-- call -->
          <CallActivityTestItem class="m-0 w-100" :call="call" :conditions="{ actions: false, }">
            <template #more>
              <b-dropdown varient="link" no-caret class="ico-filter" menu-class="w-fit-content-imp" right>
                <template v-slot:button-content>
                  <b-icon icon="three-dots" variant="#393939" font-scale="1.4"/>
                </template>
              </b-dropdown>
            </template>
          </CallActivityTestItem>
        </li>
      </ul>
    </div>
    <div>
      <div class="d-flex justify-content-between align-items-center w-100 mt-32px mb-32px">
        <div class="TabsDesignWithIcon-container">
          <div 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.tags_and_note
            }"
            @click="selected.tab = tabs.tags_and_note" 
          >
            <vb-icon icon="newbilling-tab-tags-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">Call info</span>
          </div>
          <div 
            v-if="call.task_assigned" 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.task
            }" 
            @click="selected.tab = tabs.task" 
          >
            <vb-icon icon="newbilling-tab-tags-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">Task</span>
          </div>
          <div 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.history
            }" 
            @click="selected.tab = tabs.history"
          >
            <vb-icon icon="newbilling-tab-history-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">History</span>
          </div>
          <div 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.recordings
            }" 
            @click="selected.tab = tabs.recordings" 
          >
            <vb-icon icon="newbilling-tab-brain-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">AI transcript</span>
          </div>
          <div 
            v-if="isMobileNumber" 
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.sms
            }" 
            @click="selected.tab = tabs.sms"  
          >
            <vb-icon icon="newbilling-tab-sms-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">SMS</span>
          </div>
          <div 
            v-if="true" 
            @click="selected.tab = tabs.add_contact"
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.add_contact
            }" 
          >
            <vb-icon icon="newbilling-tab-sms-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">Add contact</span>
          </div>
          <div 
            v-if="true && getCurrentUser.administrator_account && !conditions.block_number" @click="selected.tab = tabs.block"
            class="TabsDesignWithIcon-item" 
            :class="{
              selected: selected.tab == tabs.block
            }" 
          >
            <vb-icon icon="newbilling-tab-sms-icon" height="38px" width="38px" />
            <span class="TabsDesignWithIcon-item-textPart">Block</span>
          </div>
        </div>
        <!-- history filter -->
        <slot v-if="selected.tab == tabs.history" name="history-filter"></slot>
      </div>
      <ul 
        class="allow_scroll phone-list full-width-call-info-number dialor-callHistoryList list-unstyled pl-0 pr-2"
        :class="{
          'tags_and_note_tab': selected.tab == tabs.tags_and_note,
        }" 
        style="overflow-y: unset;height: auto !important;"
      > 
        <!-- tags and notes -->
        <div v-if="selected.tab == tabs.tags_and_note" class="latestGreyBox-9-9-2023">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <!-- recording -->
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
              <div class="latestGreyBox-heading-main">Recording</div>
              <vb-audio-player v-if="call.soundFile" class="w-100 mt-20px" :src="call.soundFile" :design="'NoTranscript'" >
                <template #player-end>
                  <vb-icon class="cursor_pointer_no_hover" icon="audioTranscript-download-icon" width="14.2px" height="14.2px" @click="download()" />
                </template>
              </vb-audio-player>
              <div v-else class="latestGreyBox-9-9-2023 w-100 flex-wrap d-flex mt-20px">
                <div class="latestGreyBox-descriptionText mt-0">There is no recording</div>
              </div>
            </div>
            <!-- tags -->
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="latestGreyBox-heading-main">Call tags</div>
                <div 
                  class="callInfoEditNotesTagsBoxTypeButton" 
                  v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
                  @click="conditions.edit_tags=!conditions.edit_tags"
                >
                  <template v-if="conditions.edit_tags"><b-icon icon="x-lg" variant="dark" font-scale="2" /> Cancel</template>
                  <template v-else-if="isEmpty(callTags)"><b-icon font-scale="1.3" icon="plus-lg" variant="dark"  /> Add</template>
                  <template v-else><b-icon font-scale="1.3" icon="pencil-fill" variant="primary"  /> Edit</template>
                </div>
              </div>
              <div v-if="!conditions.edit_tags" class="latestGreyBox-9-9-2023 w-100 flex-wrap d-flex mt-20px">
                <span v-for="tag in callTags" :key="tag.id" class="latestTagDesignLayout">
                  <small v-vb-tag-color="tag.colour">
                    <span v-vb-tag-color.text="tag.text_color">{{ tag.tag }}</span>
                  </small>
                </span>
                <div class="latestGreyBox-descriptionText mt-0" v-if="isEmpty(callTags)">There is no tags</div>
              </div>
              <div v-else class="latestGreyBox-9-9-2023 w-100 mt-20px">
                <b-form class="whiteBGinputWithGreyRoundedBorder" @submit.prevent="''">
                  <b-form-input placeholder="Search tags" v-model="filter.tag.search" />
                </b-form>
                <p v-for="(em, i) in api.tag.validation_errors.log" :key="i">{{ em }}</p>
                <div class="d-flex flex-wrap w-100 mt-20px">
                  <div
                    v-for="tag in filterTags" 
                    :key="tag.real_id" 
                    @click="
                      api.tag.send ? '' :
                      forms.tag.tags.includes(tag.real_id) ?
                        forms.tag.tags.splice(forms.tag.tags.indexOf(tag.real_id),1) :
                        forms.tag.tags.push(tag.real_id)" 
                    :class="`${forms.tag.tags.includes(tag.real_id)?'selected':''} latestTagDesignLayout`" 
                  >
                    <small v-vb-tag-color="tag.colour" :style="`border-color: ${forms.tag.tags.includes(tag.real_id)?'#3699FF':''}`">
                      <span v-vb-tag-color.text="tag.text_color">{{ tag.tag }}</span>
                    </small>
                  </div>
                  <div class="latestGreyBox-descriptionText" v-if="isEmpty(filterTags)">There is no tags</div>
                </div>
                <p class="latestGreyBox-descriptionText" v-for="(em, i) in api.tag.validation_errors.log" :key="i">{{ em }}</p>
                <div class="d-flex justify-content-end mt-20px">
                  <b-button :disabled="api.tag.send" class="newDoneButton" variant="primary" @click="updatetag()">
                    <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                    <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                      <vb-spinner v-if="api.tag.send" />
                      <template v-else>
                        Submit
                      </template>
                    </span>
                  </b-button>
                </div>
              </div>
            </div>
            <!-- note -->
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="latestGreyBox-heading-main">Call notes</div>
                <div 
                  class="callInfoEditNotesTagsBoxTypeButton" 
                  v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
                  @click="conditions.edit_notes=!conditions.edit_notes"
                >
                  <template v-if="data.note">
                    <b-icon v-if="conditions.edit_notes" icon="x-lg" variant="dark" font-scale="2" />
                    <b-icon v-else font-scale="1.3" icon="pencil-fill" variant="primary"  />
                    {{conditions.edit_notes ? 'Cancel' : 'Edit'}}
                  </template>
                  <template v-else>
                    <b-icon v-if="conditions.edit_notes" icon="x-lg" variant="dark" font-scale="2" />
                    <b-icon v-else font-scale="1.3" icon="plus-lg" variant="dark"  />
                    {{conditions.edit_notes ? 'Cancel' : 'Add'}}
                  </template>
                </div>
              </div>
              <div v-if="!conditions.edit_notes" class="latestGreyBox-9-9-2023 w-100 mt-20px">
                <div class="latestGreyBox-descriptionText mt-0">{{ data.note || 'There is no note' }}</div>
              </div>
              <template v-else>
                <div class="latestGreyBox-9-9-2023 w-100 mt-20px">
                  <div class="whiteBGinputWithGreyRoundedBorder w-100">
                    <label>Write some note for this call</label>
                    <b-textarea :disabled="api.note.send" rows="10" no-resize placeholder="Enter note" v-model="forms.note.note" class="w-100" />
                  </div>
                  <div class="d-flex justify-content-end mt-20px">
                    <b-button :disabled="api.note.send" variant="primary" class="newDoneButton" @click="updatenote()">
                      <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                      <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                        <vb-spinner v-if="api.note.send" />
                        <template v-else>Submit</template>
                      </span>
                    </b-button>
                  </div>
                </div>
              </template>
            </div>
            <!-- task -->
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
              <div class="w-100 d-flex justify-content-between align-items-center">
                <div class="latestGreyBox-heading-main">Task</div>
                <div 
                  v-if="callID && call.call_type=='audio' && !call.task_assigned"
                  @click="addTodoTask()" 
                  class="callInfoEditNotesTagsBoxTypeButton" 
                >
                  <vb-icon icon="callInfoModal-assignTo-icon" class="mr-16px" width="23.875px" height="20.436px" />
                  Assign to
                </div>
              </div>
              <div v-if="!call.task_assigned" class="latestGreyBox-9-9-2023 w-100 mt-20px">
                <div class="latestGreyBox-descriptionText mt-0">No task has been created</div>
              </div>
              <TaskItem 
                v-else 
                :task="call.task_assigned" 
                @taskClick="$modal.show('GlobalEditTask',{
                  id: call.task_assigned.id,
                })" 
                :isMore="false" 
              />
            </div>
            <!-- feedback -->
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-center justify-content-center">
              <div class="latestGreyBox-heading-main">How was the quality of your call?</div>
              <b-form-rating class="latestCallFeedback" no-border  v-model="forms.feedback.rating" @change="updateFeedback()" />
            </div>
          </div>
        </div>
        <!-- recording -->
        <template v-if="selected.tab == tabs.recordings">
          <div class="d-flex justify-content-between align-items-center my-3">
            <h2 class="mb-0">Call recording</h2>
          </div>
          <AudioTranscriptDummy 
            v-if="call.soundFile && call.status!='Missed'" 
            :soundFile="call.soundFile" 
            :uniqueID="uniqueID"
          >
            <template #transcript-end>
              <div class="latestGreyBox-9-9-2023 mt-20px mb-20px">
                <div class="latestGreyBox-heading-main w-100 d-flex align-items-center justify-content-between">
                  <span>Call context</span>
                  <button 
                    class="newButton"
                    v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
                    @click="!getUserPermissions.call_tagging_and_notes ? '' : $modal.show(`${_uid}-UpdateCallNotesAndTags`,{
                      note: data.note,
                      tags: callTags,
                    })"
                  >
                    <vb-icon icon="squared-update-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Update</span>
                  </button>
                </div>
                <div class="AudioTranscript-whiteBox mt-20px">
                  <div class="latestGreyBox-heading-main-16px">Notes</div>
                  <div class="latestGreyBox-descriptionText-13px mt-10px">{{data.note}}</div>
                  <div class="latestGreyBox-heading-main-16px mt-24px">tags</div>
                  <div class="AI-tags-container">
                    <div class="AI-tag" v-for="tag in callTags" :key="tag.id" v-vb-tag-color.bg="tag.colour">{{tag.tag}}</div>
                  </div>
                </div>
              </div>
            </template>
          </AudioTranscriptDummy>
          <div v-else>
            <h2 class="w-100 text-center my-3">No recordings available </h2>
          </div>
        </template>
        <!-- sms -->
        <SMS v-if="selected.tab == tabs.sms" :otherNumber="call.dialable" />
        <!-- history -->
        <slot v-if="selected.tab == tabs.history" name="history" ></slot>
        <!-- block number -->
        <div v-else-if="selected.tab == tabs.block" class="latestGreyBox-9-9-2023">
          <b-form @submit.prevent="!getUserPermissions.block_and_privacy ? '' : blocknumber()">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <b-form-group class="whiteBGinputWithGreyRoundedBorder mb-0 w-100">
                  <label>Reason for blocking</label>
                  <div 
                    v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
                    :class="{
                      'for-disable' : !getUserPermissions.block_and_privacy
                    }"
                  >
                    <b-input :disabled="api.block_number.send || !getUserPermissions.block_and_privacy" v-model="forms.block_number.displayname" />
                  </div>
                  <p v-if="(forms.block_number.submitted && $v.forms.block_number.displayname.$invalid) || api.block_number.validation_errors.displayname" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.block_number.displayname.required">* First name is required</span>
                    <span v-for="(error_message, index) in api.block_number.validation_errors.displayname" :key="index">* {{ error_message }}</span>
                  </p>
                </b-form-group>
              </div>
            </div>
            <div class="latestGreyBox-heading-main mt-20px">Direction of number block</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
                <div 
                  class="align-items-center justify-content-between d-flex w-100 bigSquareCheckbox" 
                  v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
                  :class="{
                    'for-disable w-fit-content' : !getUserPermissions.block_and_privacy 
                  }"
                >
                  <div class="latestGreyBox-heading-main">Block inbound calls</div>
                  <b-form-checkbox switch
                    :disabled="api.block_number.send || !getUserPermissions.block_and_privacy" 
                    v-model="forms.block_number.inbound" 
                    class="newerSwitch"
                  />
                </div>
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
                <div 
                  class="align-items-center justify-content-between d-flex w-100 bigSquareCheckbox" 
                  v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
                  :class="{
                    'for-disable w-fit-content' : !getUserPermissions.block_and_privacy 
                  }"
                >
                  <div class="latestGreyBox-heading-main">Block otbound calls</div>
                  <b-form-checkbox switch
                    variant="black" 
                    :disabled="api.block_number.send || !getUserPermissions.block_and_privacy" 
                    v-model="forms.block_number.outbound" 
                    class="newerSwitch"
                  />
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-end mt-20px">
              <b-form-group class="mb-0">
                <div 
                  v-b-tooltip :title="getUserPermissions.block_and_privacy?'':'Your package does not support this feature'"
                  :class="{
                    'for-disable w-fit-content' : !getUserPermissions.block_and_privacy
                  }"
                >
                  <b-button type="submit" :disabled="api.block_number.send || !getUserPermissions.block_and_privacy" class="newDoneButton">
                    <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                    <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                      <vb-spinner v-if="api.block_number.send" />
                      <template v-else>Submit</template>
                    </span>
                  </b-button>
                </div>
              </b-form-group>
            </div>
          </b-form>
        </div>
        <!-- add contact -->
        <div v-else-if="selected.tab == tabs.add_contact" class="latestGreyBox-9-9-2023">
          <b-form v-if="api.add_contact.status==1" @submit.prevent="updatecontact()">
            <div class="d-flex">
              <b-form-group class="whiteBGinputWithGreyRoundedBorder w-50 pr-2 mb-0">
                <label>First Name</label>
                <b-input 
                  placeholder="First name" 
                  :disabled="api.edit_contact.send" 
                  v-model="forms.edit_contact.firstname" 
                  :maxlength="$v.forms.edit_contact.firstname.$params.maxLength.max" 
                />
                <p class="text text-danger animated bounceIntop mb-0" v-if="forms.edit_contact.submitted && $v.forms.edit_contact.firstname.$invalid">
                  <span v-if="!$v.forms.edit_contact.firstname.required">* First name is required</span>
                  <span v-else-if="!$v.forms.edit_contact.firstname.maxLength">* First name should be minimum {{ $v.forms.edit_contact.firstname.$params.maxLength.max }} character</span>
                </p>
                <p class="text text-danger animated bounceIntop mb-0" v-else-if="api.edit_contact.validation_errors.firstname">
                  <span v-for="(error_message, index) in api.edit_contact.validation_errors.firstname" :key="index">* {{ error_message }}</span>
                </p>
              </b-form-group>
              <b-form-group class="whiteBGinputWithGreyRoundedBorder w-50 pl-2 mb-0">
                <label>Last name</label>
                <b-input 
                  placeholder="Last name" 
                  :disabled="api.edit_contact.send" 
                  :maxlength="$v.forms.edit_contact.lastname.$params.maxLength.max"
                  v-model="forms.edit_contact.lastname" 
                />
                <p class="text text-danger animated bounceIntop mb-0" v-if="forms.edit_contact.submitted && $v.forms.edit_contact.lastname.$invalid">
                  <span v-if="!$v.forms.edit_contact.lastname.maxLength">* Last name can be maximum {{ $v.forms.edit_contact.lastname.$params.maxLength.max }} character</span>
                </p>
                <p class="text text-danger animated bounceIntop mb-0" v-else-if="api.edit_contact.validation_errors.lastname">
                  <span v-for="(error_message, index) in api.edit_contact.validation_errors.lastname" :key="index">* {{ error_message }}</span>
                </p>
              </b-form-group>
            </div>
            <b-form-group class="whiteBGinputWithGreyRoundedBorder mt-20px mb-0">
              <label>Company</label>
              <b-input 
                placeholder="Company" 
                :disabled="api.edit_contact.send" 
                v-model="forms.edit_contact.company" 
              />
              <p class="text text-danger animated bounceIntop mb-0" v-if="forms.edit_contact.submitted && $v.forms.edit_contact.company.$invalid">
                <span v-if="!$v.forms.edit_contact.company.minLength">* Company should be minimum {{ $v.forms.edit_contact.company.$params.minLength.min }} character</span>
              </p>
              <p class="text text-danger animated bounceIntop mb-0" v-else-if="api.edit_contact.validation_errors.company">
                <span v-for="(error_message, index) in api.edit_contact.validation_errors.company" :key="index">* {{ error_message }}</span>
              </p>
            </b-form-group>
            <div class="latestGreyBox-heading-main mt-20px">Phones</div>
            <div v-for="(phone, index) in forms.edit_contact.phones" :key="index" class="d-flex align-items-end">
              <div class="flex-fill">
                <div class="d-flex w-100 mt-20px">
                  <b-form-group class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2 mb-0">
                    <label>Type</label>
                    <b-select :disabled="api.edit_contact.send" v-model="phone.phone">
                      <b-select-option v-for="option in phoneTypeOptions" :key="option.value" :value="option.value">{{ option.text }}</b-select-option>
                    </b-select>
                    <p class="text text-danger animated bounceIntop mb-0" v-if="forms.edit_contact.submitted && $v.forms.edit_contact.phones.$each[index].phone.$invalid">
                      <span v-if="!$v.forms.edit_contact.phones.$each[index].phone.required">* Type is required</span>
                    </p>
                  </b-form-group>
                  <b-form-group class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2 mb-0">
                    <label>Short code</label>
                    <b-select 
                      :disabled="api.edit_contact.send" 
                      v-model="phone.shortcode" 
                      :options="phone.shortcode ? [phone.shortcode,...filterShortCodes] : filterShortCodes" 
                    />
                    <p class="text text-danger animated bounceIntop mb-0" v-if="forms.edit_contact.submitted && $v.forms.edit_contact.phones.$each[index].shortcode.$invalid">
                      <span v-if="!$v.forms.edit_contact.phones.$each[index].shortcode.required">* Short code is required</span>
                    </p>
                  </b-form-group>
                </div>
                <b-form-group class="whiteBGinputWithGreyRoundedBorder w-100 mt-20px mb-0">
                  <label>Number</label>
                  <b-input 
                    :disabled="api.edit_contact.send" 
                    v-model="phone.number" 
                    v-vb-input-number-formate
                  />
                  <p class="text text-danger animated bounceIntop mb-0" v-if="forms.edit_contact.submitted && $v.forms.edit_contact.phones.$each[index].number.$invalid">
                    <span v-if="!$v.forms.edit_contact.phones.$each[index].number.required">* Number is required</span>
                    <span v-else-if="!$v.forms.edit_contact.phones.$each[index].number.validNumber">* Number is invalid</span>
                  </p>
                </b-form-group>
              </div>
              <div class="ml-3 mb-2" v-if="forms.edit_contact.phones.length>1" @click="forms.edit_contact.phones.length>1?forms.edit_contact.phones.splice(index,1):''">
                <b-icon icon="trash" font-scale="1.8" variant="danger"/>
              </div>
            </div>
            <p class="latestGreyBox-descriptionText" v-if="forms.edit_contact.submitted && $v.forms.edit_contact.phones.$invalid">
              <span v-if="!$v.forms.edit_contact.phones.required">* Phones is required</span>
              <span v-else-if="!$v.forms.edit_contact.phones.minLength">* Phones should be minimum {{ $v.forms.edit_contact.phones.$params.minLength.min }} required</span>
            </p>
            <p class="latestGreyBox-descriptionText" v-else-if="api.edit_contact.validation_errors.phones">
              <span v-for="(error_message, index) in api.edit_contact.validation_errors.phones" :key="index">* {{ error_message }}</span>
            </p>
            <div class="d-flex justify-content-end w-100 mt-20px">
              <div 
                v-if="forms.edit_contact.phones.length<=10"
                class="dialer-button-primary dialer-button" style="cursor: pointer;" 
                @click="api.shortcode.send ? '' : forms.edit_contact.phones.push({ phone: '', shortcode: firstOption, number: '', })"
              >
                <b-icon icon="plus-circle-fill" class="mr-3" variant="white"/> 
                <span>Add phone</span>
              </div>
            </div>
            <template v-if="conditions.show_more_edit_contact">
              <div class="d-flex mt-20px w-100">
                <b-form-group class="whiteBGinputWithGreyRoundedBorder w-50 mb-0 pr-2">
                  <label>Job title</label>
                  <b-input 
                    :disabled="api.edit_contact.send" 
                    v-model="forms.edit_contact.jobtitle" 
                  />
                  
                  <p class="text text-danger animated bounceIntop mb-0" v-if="forms.edit_contact.submitted && $v.forms.edit_contact.jobtitle.$invalid">
                    <span v-if="!$v.forms.edit_contact.jobtitle.minLength">* jobtitle should be minimum {{ $v.forms.edit_contact.jobtitle.$params.minLength.min }} character</span>
                  </p>
                  <p class="text text-danger animated bounceIntop mb-0" v-else-if="api.edit_contact.validation_errors.jobtitle">
                    <span v-for="(error_message, index) in api.edit_contact.validation_errors.jobtitle" :key="index">* {{ error_message }}</span>
                  </p>
                </b-form-group>
                <b-form-group class="whiteBGinputWithGreyRoundedBorder w-50 mb-0 pl-2">
                  <label>Department</label>
                  <b-input 
                    :disabled="api.edit_contact.send" 
                    v-model="forms.edit_contact.department" 
                  />
                  <p class="text text-danger animated bounceIntop mb-0" v-if="forms.edit_contact.submitted && $v.forms.edit_contact.department.$invalid">
                    <span v-if="!$v.forms.edit_contact.department.minLength">* department should be minimum {{ $v.forms.edit_contact.department.$params.minLength.min }} character</span>
                  </p>
                  <p class="text text-danger animated bounceIntop mb-0" v-else-if="api.edit_contact.validation_errors.department">
                    <span v-for="(error_message, index) in api.edit_contact.validation_errors.department" :key="index">* {{ error_message }}</span>
                  </p>
                </b-form-group>
              </div>
              <b-form-group class="whiteBGinputWithGreyRoundedBorder mt-20px mb-0 dropdownVersion">
                <label>Suffix</label>
                <b-select :disabled="api.edit_contact.send" v-model="forms.edit_contact.suffix">
                  <b-select-option v-for="option in sufixOptions" :key="option.value" :value="option.value">{{ option.text }}</b-select-option>
                </b-select>
                <p class="text text-danger animated bounceIntop mb-0" v-if="api.edit_contact.validation_errors.suffix">
                  <span v-for="(error_message, index) in api.edit_contact.validation_errors.suffix" :key="index">* {{ error_message }}</span>
                </p>
              </b-form-group>
              <div class="latestGreyBox-heading-main mt-20px">Emails</div>
              <b-form-group class="mb-0">
                <div v-for="(emails, index) in forms.edit_contact.emails" :key="index" class="d-flex align-items-end">
                  <b-form-group class="whiteBGinputWithGreyRoundedBorder flex-fill mt-20px mb-0">
                    <label>Email</label>
                    <b-input :disabled="api.edit_contact.send" type="email" v-model="emails.emails" />
                    <p class="text text-danger animated bounceIntop mb-0" v-if="forms.edit_contact.submitted && $v.forms.edit_contact.emails.$each[index].emails.$invalid">
                      <span v-if="!$v.forms.edit_contact.emails.$each[index].emails.email">* Email is invalid</span>
                    </p>
                  </b-form-group>
                  <div class="ml-3 mb-2" @click="forms.edit_contact.emails.length>1?forms.edit_contact.emails.splice(index,1):''">
                    <b-icon icon="trash" font-scale="1.8" variant="danger"/>
                  </div>
                </div>
                <div class="d-flex justify-content-end w-100 mt-20px">
                  <div 
                    v-if="forms.edit_contact.emails.length<=10"
                    class="dialer-button-primary dialer-button" 
                    style="cursor: pointer;" 
                    @click="forms.edit_contact.emails.push({ emails: '', })"
                  >
                    <b-icon icon="plus-circle-fill" class="mr-3" variant="white"/>
                    <span>Add email</span>
                  </div>
                </div>
              </b-form-group>
              <b-form-group class="whiteBGinputWithGreyRoundedBorder mt-20px mb-0">
                <label>Note</label>
                <b-textarea :disabled="api.edit_contact.send" v-model="forms.edit_contact.note" />
                <p class="text text-danger animated bounceIntop mb-0" v-if="api.edit_contact.validation_errors.note">
                  <span v-for="(error_message, index) in api.edit_contact.validation_errors.note" :key="index">* {{ error_message }}</span>
                </p>
              </b-form-group>
            </template>
            <div class="d-flex justify-content-center mt-20px" @click="conditions.show_more_edit_contact=!conditions.show_more_edit_contact">
              <div class="font-weight-700 dialer-button-primary dialer-button">{{ conditions.show_more_edit_contact ? 'Hide' : 'Show' }}</div>
            </div>
          </b-form>
          <b-form v-else @submit.prevent="addcontact()">
            <div class="d-flex">
              <b-form-group class="whiteBGinputWithGreyRoundedBorder pr-2 w-50 mb-0">
                <label>First name</label>
                <b-input 
                  placeholder="First name" 
                  :disabled="api.add_contact.send" 
                  v-model="forms.add_contact.firstname" 
                  :maxlength="$v.forms.add_contact.firstname.$params.maxLength.max" 
                />
                <p class="text text-danger animated bounceIntop mb-0" v-if="forms.add_contact.submitted && $v.forms.add_contact.firstname.$invalid">
                  <span v-if="!$v.forms.add_contact.firstname.required">* First name is required</span>
                  <span v-else-if="!$v.forms.add_contact.firstname.maxLength">* First name should be minimum {{ $v.forms.add_contact.firstname.$params.maxLength.max }} character</span>
                </p>
                <p class="text text-danger animated bounceIntop mb-0" v-else-if="api.add_contact.validation_errors.firstname">
                  <span v-for="(error_message, index) in api.add_contact.validation_errors.firstname" :key="index">* {{ error_message }}</span>
                </p>
              </b-form-group>
              <b-form-group class="whiteBGinputWithGreyRoundedBorder pl-2 w-50 mb-0">
                <label>Last name</label>
                <b-input 
                  placeholder="Last name" 
                  :disabled="api.add_contact.send" 
                  :maxlength="$v.forms.add_contact.lastname.$params.maxLength.max"
                  v-model="forms.add_contact.lastname" 
                />
                <p class="text text-danger animated bounceIntop mb-0" v-if="forms.add_contact.submitted && $v.forms.add_contact.lastname.$invalid">
                  <span v-if="!$v.forms.add_contact.lastname.maxLength">* Last name can be maximum {{ $v.forms.add_contact.lastname.$params.maxLength.max }} character</span>
                </p>
                <p class="text text-danger animated bounceIntop mb-0" v-else-if="api.add_contact.validation_errors.lastname">
                  <span v-for="(error_message, index) in api.add_contact.validation_errors.lastname" :key="index">* {{ error_message }}</span>
                </p>
              </b-form-group>
            </div>
            <b-form-group class="whiteBGinputWithGreyRoundedBorder mt-20px mb-0">
              <label>Company</label>
              <b-input 
                placeholder="Company" 
                :disabled="api.add_contact.send" 
                v-model="forms.add_contact.company" 
              />
              <p class="text text-danger animated bounceIntop mb-0" v-if="forms.add_contact.submitted && $v.forms.add_contact.company.$invalid">
                <span v-if="!$v.forms.add_contact.company.minLength">* Company should be minimum {{ $v.forms.add_contact.company.$params.minLength.min }} character</span>
              </p>
              <p class="text text-danger animated bounceIntop mb-0" v-else-if="api.add_contact.validation_errors.company">
                <span v-for="(error_message, index) in api.add_contact.validation_errors.company" :key="index">* {{ error_message }}</span>
              </p>
            </b-form-group>
            <div class="latestGreyBox-heading-main mt-20px">Phones</div>
            <b-form-group>
              <div v-for="(phone, index) in forms.add_contact.phones" :key="index" class="d-flex align-items-end">
                <div class="flex-fill">
                  <div class="d-flex w-100 mt-20px">
                    <b-form-group class="whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-50 pr-2">
                      <label>Type</label>
                      <b-select :disabled="api.add_contact.send" v-model="phone.phone">
                        <b-select-option v-for="option in phoneTypeOptions" :key="option.value" :value="option.value">{{ option.text }}</b-select-option>
                      </b-select>
                      <p class="text text-danger animated bounceIntop mb-0" v-if="forms.add_contact.submitted && $v.forms.add_contact.phones.$each[index].phone.$invalid">
                        <span v-if="!$v.forms.add_contact.phones.$each[index].phone.required">* Type is required</span>
                      </p>
                    </b-form-group>
                    <b-form-group class="whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-50 pl-2">
                      <label>Short code</label>
                      <b-select 
                        :disabled="api.add_contact.send" 
                        v-model="phone.shortcode" 
                        :options="phone.shortcode ? [phone.shortcode,...filterShortCodes] : filterShortCodes" 
                      />
                      <p class="text text-danger animated bounceIntop mb-0" v-if="forms.add_contact.submitted && $v.forms.add_contact.phones.$each[index].shortcode.$invalid">
                        <span v-if="!$v.forms.add_contact.phones.$each[index].shortcode.required">* Short Code is required</span>
                      </p>
                    </b-form-group>
                  </div>
                  <b-form-group class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
                    <label>Number</label>
                    <b-input 
                      :disabled="api.add_contact.send" 
                      v-model="phone.number" 
                      v-vb-input-number-formate
                    />
                    <p class="text text-danger animated bounceIntop mb-0" v-if="forms.add_contact.submitted && $v.forms.add_contact.phones.$each[index].number.$invalid">
                      <span v-if="!$v.forms.add_contact.phones.$each[index].number.required">* Number is required</span>
                      <span v-else-if="!$v.forms.add_contact.phones.$each[index].number.validNumber">* Number is invalid</span>
                    </p>
                  </b-form-group>
                </div>
                <div class="ml-3 mb-2" v-if="forms.add_contact.phones.length>1" @click="forms.add_contact.phones.length>1?forms.add_contact.phones.splice(index,1):''">
                  <b-icon icon="trash" font-scale="1.8" variant="danger"/>
                </div>
              </div>
              <p class="text text-danger animated bounceIntop " v-if="forms.add_contact.submitted && $v.forms.add_contact.phones.$invalid">
                <span v-if="!$v.forms.add_contact.phones.required">* Phones is required</span>
                <span v-else-if="!$v.forms.add_contact.phones.minLength">* Phones should be minimum {{ $v.forms.add_contact.phones.$params.minLength.min }} required</span>
              </p>
              <p class="text text-danger animated bounceIntop " v-else-if="api.add_contact.validation_errors.phones">
                <span v-for="(error_message, index) in api.add_contact.validation_errors.phones" :key="index">* {{ error_message }}</span>
              </p>
              <div class="d-flex justify-content-end w-100 mt-20px">
                <div 
                  v-if="forms.add_contact.phones.length<=10"
                  class="dialer-button-primary dialer-button" 
                  @click="api.shortcode.send ? '' : forms.add_contact.phones.push({ phone: '', shortcode: firstOption, number: '', })"
                >
                  <b-icon icon="plus-circle-fill" class="mr-3" variant="white"/> 
                  <span>Add phone</span>
                </div>
              </div>
            </b-form-group>
            <template v-if="conditions.show_more_add_contact">
              <div class="d-flex w-100 mt-20px">
                <b-form-group class="whiteBGinputWithGreyRoundedBorder pr-2 w-50 mb-0">
                  <label>Job title</label>
                  <b-input 
                    :disabled="api.add_contact.send" 
                    v-model="forms.add_contact.jobtitle" 
                  />
                  
                  <p class="text text-danger animated bounceIntop mb-0" v-if="forms.add_contact.submitted && $v.forms.add_contact.jobtitle.$invalid">
                    <span v-if="!$v.forms.add_contact.jobtitle.minLength">* jobtitle should be minimum {{ $v.forms.add_contact.jobtitle.$params.minLength.min }} character</span>
                  </p>
                  <p class="text text-danger animated bounceIntop mb-0" v-else-if="api.add_contact.validation_errors.jobtitle">
                    <span v-for="(error_message, index) in api.add_contact.validation_errors.jobtitle" :key="index">* {{ error_message }}</span>
                  </p>
                </b-form-group>
                <b-form-group class="whiteBGinputWithGreyRoundedBorder pl-2 w-50 mb-0">
                  <label>Department</label>
                  <b-input 
                    :disabled="api.add_contact.send" 
                    v-model="forms.add_contact.department" 
                  />
                  <p class="text text-danger animated bounceIntop mb-0" v-if="forms.add_contact.submitted && $v.forms.add_contact.department.$invalid">
                    <span v-if="!$v.forms.add_contact.department.minLength">* department should be minimum {{ $v.forms.add_contact.department.$params.minLength.min }} character</span>
                  </p>
                  <p class="text text-danger animated bounceIntop mb-0" v-else-if="api.add_contact.validation_errors.department">
                    <span v-for="(error_message, index) in api.add_contact.validation_errors.department" :key="index">* {{ error_message }}</span>
                  </p>
                </b-form-group>
              </div>
              <b-form-group class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px dropdownVersion">
                <label>Suffix</label>
                <b-select :disabled="api.add_contact.send" v-model="forms.add_contact.suffix">
                  <b-select-option v-for="option in sufixOptions" :key="option.value" :value="option.value">{{ option.text }}</b-select-option>
                </b-select>
                <p class="text text-danger animated bounceIntop mb-0" v-if="api.add_contact.validation_errors.suffix">
                  <span v-for="(error_message, index) in api.add_contact.validation_errors.suffix" :key="index">* {{ error_message }}</span>
                </p>
              </b-form-group>
              <div class="latestGreyBox-heading-main mt-20px">Emails</div>
              <b-form-group>
                <div v-for="(emails, index) in forms.add_contact.emails" :key="index" class="d-flex align-items-end">
                  <b-form-group class="whiteBGinputWithGreyRoundedBorder flex-fill mb-0 mt-20px">
                    <label>Email</label>
                    <b-input :disabled="api.add_contact.send" type="email" v-model="emails.emails" />
                    <p class="text text-danger animated bounceIntop mb-0" v-if="forms.add_contact.submitted && $v.forms.add_contact.emails.$each[index].emails.$invalid">
                      <span v-if="!$v.forms.add_contact.emails.$each[index].emails.email">* Email is invalid</span>
                    </p>
                  </b-form-group>
                  <div class="ml-3 mb-2" @click="forms.add_contact.emails.length>1?forms.add_contact.emails.splice(index,1):''">
                    <b-icon icon="trash" font-scale="1.8" variant="danger"/>
                  </div>
                </div>
                <p class="text text-danger animated bounceIntop mb-0" v-if="api.add_contact.validation_errors.emails">
                  <span v-for="(error_message, index) in api.add_contact.validation_errors.emails" :key="index">* {{ error_message }}</span>
                </p>
                <div class="d-flex justify-content-end w-100 mt-20px">
                  <div 
                    v-if="forms.add_contact.emails.length<=10"
                    class="dialer-button-primary dialer-button" 
                    @click="forms.add_contact.emails.push({ emails: '', })"
                  >
                    <b-icon icon="plus-circle-fill" class="mr-3" variant="white"/> 
                    <span>Add email</span>
                  </div>
                </div>
              </b-form-group>
              <b-form-group class="whiteBGinputWithGreyRoundedBorder mb-0 mt-20px">
                <label>Note</label>
                <b-textarea :disabled="api.add_contact.send" v-model="forms.add_contact.note" />
                <p class="text text-danger animated bounceIntop mb-0" v-if="api.add_contact.validation_errors.note">
                  <span v-for="(error_message, index) in api.add_contact.validation_errors.note" :key="index">* {{ error_message }}</span>
                </p>
              </b-form-group>
            </template>
            <b-form-group class="mb-0 mt-20px">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="latestGreyBox-heading-main">Save as global</div>
                <b-check switch
                  class="newerSwitch" 
                  :disabled="api.add_contact.send" 
                  v-model="forms.add_contact.is_global"
                >
                </b-check>
              </div>
            </b-form-group>
            <div class="d-flex justify-content-center mt-20px" @click="conditions.show_more_add_contact=!conditions.show_more_add_contact">
              <div class="font-weight-700 dialer-button-primary dialer-button">{{ conditions.show_more_add_contact ? 'Hide' : 'Show' }}</div>
            </div>
            <div class="d-flex justify-content-end mt-20px">
              <b-form-group class="w-auto">
                <b-button class="newDoneButton" :disabled="api.add_contact.send" type="submit">
                  <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                    <vb-spinner v-if="api.add_contact.send" />
                    <template v-else>Submit</template>
                  </span>
                </b-button>
              </b-form-group>
            </div>
          </b-form>
        </div>
        <!-- task -->
        <EditTasksModal v-else-if="selected.tab == tabs.task" :propTaskID="call.task_assigned.id" />
      </ul>
      <UpdateCallNotesAndTags
        :modalName="`${_uid}-UpdateCallNotesAndTags`" 
        :callID="callID" 
        @note-updated="data.note=$event.note;$emit('latest-record');$emit('updated');" 
        @tags-updated="data.tag_ids=$event.tag_ids;$emit('latest-record');$emit('updated');" 
      />
    </div>
  </div>
</template>

<script>
import { number_formater } from '@/filter'
import { 
  events, 
  VOIP_API, 
  $fn, 
} from "../../../utils";
import CallActivityTestItem from '../../Home/CallActivity/TestItem/index.vue';
import _ from 'lodash';
import { required, maxLength, minLength, email } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import AudioTranscriptDummy from '../../widgets/AudioTranscriptDummy.vue';
import TaskItem from '../../Todo/TaskItem.vue';
import SMS from '../../sms/index.vue';
import UpdateCallNotesAndTags from '../UpdateCallNotesAndTags.vue';
import { parsePhoneNumber } from 'libphonenumber-js';
import parseMax from 'libphonenumber-js/max'
const tabs = {
  history: 'history',
  block: 'block',
  tags_and_note: 'tags_and_note',
  add_contact: 'add_contact',
  recordings: 'recordings',
  sms: 'sms',
  task: 'task',
}
const phone_type_options = [
  {
    text: 'Home',
    value: 'Home',
  },
  {
    text: 'Office',
    value: 'Office',
  },
  {
    text: 'Cell',
    value: 'Cell',
  },
  {
    text: 'Work',
    value: 'Work',
  },
  {
    text: 'Main',
    value: 'Main',
  },
  {
    text: 'HOME FAX',
    value: 'HOME FAX',
  },
  {
    text: 'Office FAX',
    value: 'Office FAX',
  },
  {
    text: 'Others',
    value: 'Others',
  },
]
const sufix_options = [
  {
    text: 'Mr',
    value: 'Mr',
  }, 
  {
    text: 'Miss',
    value: 'Miss',
  }, 
  {
    text: 'Mrs',
    value: 'Mrs',
  }, 
  {
    text: 'Other',
    value: 'Other',
  }, 
]
export default {
  name: "SipCallLog",
  props: {
    log: {
      type: Object,
      default: ()=>({}),
    },
    params: {
      type: Object,
      default: null,
    },
  },
  components: {
    EditTasksModal: () => import('../Todo/EditTasksModal.vue'),
    CallActivityTestItem,
    AudioTranscriptDummy,
    TaskItem,
    SMS,
    UpdateCallNotesAndTags,
  },
  inject:[
    'isEmpty',
    'getAddressBooks',
    'appNotify'
  ],
  data() {
    return {
      api: {
        add_todo: this.$helperFunction.apiInstance(),
        text_copy: this.$helperFunction.apiInstance(),
        tag: this.$helperFunction.apiInstance({
          status: true,
          error_message: true,
          validation_errors: true,
        }),
        note: this.$helperFunction.apiInstance({
          status: true,
          error_message: true,
          validation_errors: true,
        }),
        add_contact: this.$helperFunction.apiInstance({
          validation_errors: true,
          status: true,
        }),
        edit_contact: this.$helperFunction.apiInstance({
          validation_errors: true,
          status: true,
        }),
        block_number: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        }),
        shortcode: this.$helperFunction.apiInstance(),
        update_feedback: this.$helperFunction.apiInstance({}),
      },
      forms: {
        note: this.$helperFunction.formInstance({
          data: {
            note: '',
          },
        }),
        tag: this.$helperFunction.formInstance({
          data: {
            tags: [],
          },
        }),
        add_contact: this.$helperFunction.formInstance({
          data: {
            firstname: '',
            lastname: '',
            company: '',
            jobtitle: '',
            nickname: '',
            department: '',
            suffix: '',
            note: '',
            emails: [],
            phones: [
              {
                phone: "Main",
                number: "",
                shortcode: "",
              },
            ],
            is_global: false,
          },
        }),
        edit_contact: this.$helperFunction.formInstance({
          data: {
            id: '',
            firstname: '',
            lastname: '',
            company: '',
            jobtitle: '',
            nickname: '',
            department: '',
            suffix: '',
            note: '',
            emails: [],
            phones: [
              {
                phone: "Main",
                number: "",
                shortcode: "",
              },
            ],
            is_global: false,
          },
        }),
        block_number: this.$helperFunction.formInstance({
          data: {
            displayname: '',
            inbound: true,
            outbound: true,
          },
        }),
        feedback: this.$helperFunction.formInstance({
          data: {
            rating: '0.0',
          },
        }),
      },
      response: this.$helperFunction.stateInstance({
        data: {
          short_codes: [],
        },
      }),
      selected: this.$helperFunction.stateInstance({
        data: {
          tab: tabs.tags_and_note,
        },
      }),
      data: this.$helperFunction.stateInstance({
        data: {
          call_info: {},
          tag_ids: [],
          note: '',
        },
      }),
      filter: {
        tag: this.$helperFunction.stateInstance({
          data: {
            search: '',
          },
        }),
      },
      conditions: this.$helperFunction.stateInstance({
        data: {
          edit_tags_and_note: false,
          edit_tags: false,
          edit_notes: false,
          show_more_add_contact: false,
          show_more_edit_contact: false,
          edit_contact_id: '',
          block_number: false,
        },
      }),
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getVoipCallTags',
      'getCurrentUser',
      'getIsMobile',
      'getVoipUsersAlisesDisplayName',
      'getVoipUsersAlises',
    ]),
    call(){ return this.data.call_info },
    callID() { return this.call?.call_id; },
    uniqueID() { return this.call?.uniqueid },
    tabs(){ return tabs },
    isMobileNumber(){
      let is_mobile = false
      try {
        const number = this.call?.receiver || this.call?.dialable
        const phoneNumber = parsePhoneNumber(`+${number}`)
        if(phoneNumber) {
          const maxPhoneNumber = parseMax(phoneNumber.number)
          if(maxPhoneNumber){
            is_mobile=maxPhoneNumber.getType()?.toLocaleLowerCase?.()?.includes?.('mobile')
          }
        }
        return is_mobile
      } catch (ex) {
        return is_mobile
      }
    },
    callTags() {
      return _.uniqBy([
        ...this.getVoipCallTags,
        ...(this.call?.tags_notes?.filter?.(i=>i.type=="tag" && !!i.tag)?.map(i=>({...i.tag,real_id:i.tag.id})) ?? [])
      ],'real_id')
      .filter(item=>this.data.tag_ids.includes(item.real_id))
      .filter(item=>item.deleted_tags<1);
    },
    filterTags() {
      return _.uniqBy([
        ...this.getVoipCallTags,
        ...(this.data.call_info?.tags_notes?.filter?.(i=>i.type=="tag" && !!i.tag)?.map(i=>({...i.tag,real_id:i.tag.id})) ?? [])
      ],'real_id')
      .filter(item => `${item.tag}`.toLowerCase().trim().includes(this.filter.tag.search.toLowerCase().trim()))
      .filter(item=>item.deleted_tags<1);
    },
    phoneTypeOptions(){ return phone_type_options },
    sortCodeOptions(){ return this.response.short_codes },
    sufixOptions(){ return sufix_options },
    filterShortCodes(){ return (this.response.short_codes ?? []).filter(i=>!(this.forms.add_contact?.phones?.map(i=>i.shortcode).filter(i=>!!i) ?? []).includes(i)) },
    firstOption(){ return this.filterShortCodes?.[0] ?? '' },
    isDialableNumber(){ return $fn.validPhoneNumber(`+${this.call.dialable}`) },
  },
  validations: {
    forms: {
      feedback: {
        rating: {
          required: required,
        }
      },
      add_contact: {
        firstname: {
          required,
          maxLength: maxLength(16),
        },
        suffix: {
          
        },
        lastname: {
          maxLength: maxLength(16),
        },
        company: {
          minLength: minLength(3),
        },
        jobtitle: {
          minLength: minLength(3),
        },
        department: {
          minLength: minLength(3),
        },
        emails: {
          $each: {
            emails: {
              email,
            },
          }
        },
        phones: {
          required,
          minLength: minLength(1),
          $each: {
            phone: {
              required,
            },
            number: {
              required,
              validNumber(value){ return $fn.validPhoneNumber(value) },
            },
            shortcode: {
              required,
            },
          }
        },
      },
      edit_contact: {
        id: {
          required,
        },
        firstname: {
          required,
          maxLength: maxLength(16),
        },
        suffix: {
          
        },
        lastname: {
          maxLength: maxLength(16),
        },
        company: {
          minLength: minLength(3),
        },
        jobtitle: {
          minLength: minLength(3),
        },
        department: {
          minLength: minLength(3),
        },
        emails: {
          $each: {
            emails: {
              email,
            },
          }
        },
        phones: {
          required,
          minLength: minLength(1),
          $each: {
            phone: {
              required,
            },
            number: {
              required,
              validNumber(value){ return $fn.validPhoneNumber(value) },
            },
            shortcode: {
              required,
            },
          }
        },
      },
      block_number: {
        displayname: {
          required,
        },
      },
    },
  },
  watch: {
    "conditions.edit_contact_id"(id){
      const addressbook = this.$store.state.common.address_books.data.find((addressbook) => addressbook.real_id == id);
      if (addressbook) {
        const emails = addressbook.emails.split(";").map((email) => {
          return {
            emails: email,
          };
        });
        const phones = addressbook.phones.map((phone) => {
          return {
            phone: phone.type,
            number: number_formater(phone.number,'',true),
            shortcode: phone.shortCode,
          };
        });
        const shortCodes = addressbook.phones.map((phone)=>phone.shortCode).filter(shortCode=>!!shortCode);
        this.forms.edit_contact.firstname = addressbook.firstName
        this.forms.edit_contact.lastname = addressbook.lastName
        this.forms.edit_contact.company = addressbook.company
        this.forms.edit_contact.jobtitle = addressbook.jobtitle
        this.forms.edit_contact.nickname = addressbook.nickname
        this.forms.edit_contact.department = addressbook.department
        this.forms.edit_contact.suffix = addressbook.suffix
        this.forms.edit_contact.note = addressbook.note
        this.forms.edit_contact.emails = emails
        this.forms.edit_contact.phones = phones
        this.forms.edit_contact.is_global = addressbook.global==1
        this.fetchavailableshortcodes(shortCodes);
      }
    }
  },
  methods: {
    load(){
      this.data.tag_ids = this.call.tags_notes?.filter?.(i=>i.type=="tag" && i.tag)?.map?.(tag=>tag.tag.id) ?? [];
      this.forms.tag.tags = _.cloneDeep(this.data.tag_ids)
      this.forms.note.note = this.data.note = this.call.tags_notes?.find?.(i=>i.log && i.type=="note")?.log || '';
      if(this.call.call_type != 'voicemail' && this.call.call_type != 'sms' && this.call.is_did){
        this.forms.add_contact.phones[0].number=number_formater(this.call.dialable || '','',true) || ''
      }
      this.forms.feedback.rating=this.data.call_info?.caller_feedback?.stars || 0
      this.fetchavailableshortcodes();
    },
    async updateFeedback(){
      this.forms.feedback.submitted=true
      this.$v.forms.feedback.$touch()
      if(this.api.update_feedback.send || this.$v.forms.feedback.$invalid) return;
      try {
        this.api.update_feedback.send=true
        await VOIP_API.endpoints.call_activity.callFeedback({
          accountcode: this.getCurrentUser.account,
          description: '',
          caller_id: this.callID,
          stars: this.forms.feedback.rating,
        })
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.forms.feedback.submitted=false
        this.api.update_feedback.send=false
      }
    },
    async addcontact(){
      this.forms.add_contact.submitted = true;
      this.$v.forms.add_contact.$touch();
      if (this.$v.forms.add_contact.$invalid || this.api.add_contact.send) return;
      try {
        this.api.add_contact.send = true;
        this.api.add_contact.validation_errors = {};
        this.api.add_contact.status = 0;
        const { data: { data: address_book } } = await VOIP_API.endpoints.address_books.create({
          firstname: this.forms.add_contact.firstname,
          lastname: this.forms.add_contact.lastname,
          company: this.forms.add_contact.company,
          jobtitle: this.forms.add_contact.jobtitle,
          nickname: this.forms.add_contact.nickname || 'nickname',
          department: this.forms.add_contact.department,
          suffix: this.forms.add_contact.suffix,
          note: this.forms.add_contact.note,
          emails: this.forms.add_contact.emails,
          is_global: this.forms.add_contact.is_global ? 1 : 0,
          phones: this.forms.add_contact.phones.map(phone=>({...phone,number:$fn.getNumberFormated(phone.number)})),
          flag: this.forms.add_contact.is_global ? 'global' : 'local',
        })
        this.$emit('latest-record')
        const _address_book = $fn.manipulateAddressBook(address_book)
        this.$store.state.common.address_books.total = this.$store.state.common.address_books.total + 1
        this.$store.state.common.address_books.data.unshift(_address_book)
        this.getAddressBooks();
        this.api.add_contact.status = 1;
        this.appNotify({
          message: 'Successfully Added',
          type: "success",
        })
        this.conditions.edit_contact_id = _address_book.real_id
        this.$root.$emit(events.fetch_call_activity)
      } catch (ex) {
        this.api.add_contact.status = 2;
        this.api.add_contact.validation_errors=ex.own_errors
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.add_contact.send = false;
        this.forms.add_contact.submitted = false;
      }
    },
    async updatecontact(){
      this.forms.edit_contact.submitted = true;
      this.$v.forms.edit_contact.$touch();
      if (this.$v.forms.edit_contact.$invalid || this.api.edit_contact.send) return;
      try {
        this.api.edit_contact.send = true;
        this.api.edit_contact.validation_errors = {};
        this.api.edit_contact.status = 0;
        const { data: { data: address_book } } = await VOIP_API.endpoints.address_books.update({
          id: this.conditions.edit_contact_id,
          firstname: this.forms.edit_contact.firstname,
          lastname: this.forms.edit_contact.lastname,
          company: this.forms.edit_contact.company,
          jobtitle: this.forms.edit_contact.jobtitle,
          nickname: this.forms.edit_contact.nickname || 'nickname',
          department: this.forms.edit_contact.department,
          suffix: this.forms.edit_contact.suffix,
          note: this.forms.edit_contact.note,
          emails: this.forms.edit_contact.emails,
          is_global: this.forms.edit_contact.is_global ? 1 : 0,
          phones: this.forms.edit_contact.phones.map(phone=>({...phone,number:$fn.getNumberFormated(phone.number)})),
          flag: this.forms.edit_contact.is_global ? 'global' : 'local',
        })
        this.$emit('latest-record')
        const _address_book = $fn.manipulateAddressBook(address_book)
        let address_books = [];
        address_books = await  this.$store.state.common.address_books.data.map((item)=>item.real_id==this.updated_id?_address_book:item)
        this.$store.state.common.address_books.data = address_books
        this.api.edit_contact.status = 1;
        this.appNotify({
          message: 'Successfully Updated',
          type: "success",
        })
        this.$root.$emit(events.fetch_call_activity)
      } catch (ex) {
        this.api.edit_contact.status = 2;
        this.api.edit_contact.validation_errors=ex.own_errors
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.edit_contact.send = false;
        this.forms.edit_contact.submitted = false;
      }
    },
    async blocknumber(){
      this.forms.block_number.submitted=true
      this.$v.forms.block_number.$touch();
      if(this.api.block_number.send || this.$v.forms.block_number.$invalid) return;
      try {
        this.api.block_number.send=true
        this.api.block_number.validation_errors={}
        this.api.block_number.error_message=''
        await VOIP_API.endpoints.inbound_rule.create({
          callerid: this.call.dialable,
          displayname: this.forms.block_number.displayname,
          type: this.forms.block_number.inbound && this.forms.block_number.outbound ? 'both' : this.forms.block_number.inbound ? 'in' : this.forms.block_number.outbound ? 'out' : '',
          action: "save",
          enable: "Y",
          command: "busy",
          fid: 12,
          args: "fsdfsd",
          source: "app",
        })
        this.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
        this.conditions.block_number=true
        this.selected.tab=tabs.history
      } catch (ex) {
        this.api.block_number.validation_errors=ex.own_errors
        this.api.block_number.error_message=ex.own_message
      } finally {
        this.api.block_number.send=false
        this.forms.block_number.submitted=false
      }
    },
    async updatetag() {
      this.forms.tag.submitted=true
      if (this.api.tag.send) return;
      try {
        this.api.tag.send = true;
        this.api.tag.error_message = '';
        this.api.tag.validation_errors = {};
        this.api.tag.status = 0;
        await VOIP_API.endpoints.call_activity.calltagsnotes({
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
          id: this.callID,
          log: this.forms.tag.tags.toString(),
          type: "tag",
        })
        this.api.tag.status = 1;
        this.conditions.edit_tags=false
        this.data.tag_ids=_.cloneDeep(this.forms.tag.tags)
        this.$emit('latest-record')
        this.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
        this.$emit('updated')
      } catch (ex) {
        this.api.tag.status = 2;
        this.api.tag.error_message = ex.own_message
        this.api.tag.validation_errors = ex.own_errors
        this.appNotify({
          message: this.api.tag.error_message,
          type: 'danger',
        })
      } finally {
        this.api.tag.send = false;
        this.forms.tag.submitted=false
      }
    },
    async updatenote() {
      this.forms.note.submitted=true
      if (this.api.note.send) return;
      try {
        this.api.note.send = true;
        this.api.note.error_message = '';
        this.api.note.validation_errors = {};
        this.api.note.status = 0;
        await VOIP_API.endpoints.call_activity.calltagsnotes({
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
          id: this.callID,
          log: this.forms.note.note,
          type: "note",
        })
        this.api.note.status = 1;
        this.conditions.edit_notes=false
        this.data.note=this.forms.note.note
        this.$emit('latest-record')
        this.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
        this.$emit('updated')
      } catch (ex) {
        this.api.note.status = 2;
        this.api.note.error_message = ex.own_message
        this.api.note.validation_errors = ex.own_errors
        this.appNotify({
          message: this.api.note.error_message,
          type: 'danger',
        })
      } finally {
        this.api.note.send = false;
        this.forms.note.submitted=false
      }
    },
    async block(){
      try {
        const { confirm } = await this.$appConfirmation({
          title: "warning",
          message: `Are you sure you want to block this number`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        });
        if (confirm) {
          this.appNotify({
            message: 'This feature is in process',
            type: 'info',
          })
        }
      } finally {
        // TODO - 
      }
    },
    async download() {
      try {
        const response = await fetch(this.call.soundFile)
        const blob = await response.blob()
        let blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = 'download';
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        // TODO - 
      }
    },
    async fetchavailableshortcodes(shortCodes=[]) {
      if(this.api.shortcode.send) return;
      try {
        this.api.shortcode.send = true;
        const { data: shortcodes } = await VOIP_API.endpoints.address_books.shortcodes({
          type: 'local',
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid,
        })
        this.response.short_codes = [...shortcodes,...shortCodes];
        await this.$nextTick()
        this.forms.add_contact.phones[0].shortcode=this.firstOption
      } finally {
        this.api.shortcode.send = false;
      }
    },
    async addTodoTask(){
      if(this.api.add_todo.send || this.isTodoAdded) return;
      try {
        this.api.add_todo.send=true
        let type = 'outgoing'
        if(this.call.status=='Missed') type='missed'
        else if(this.call.status=='Received') type='incoming'
        let title = ''
        if(this.call.status=='Dialed') {
          if(this.call.dst_type=='Number') title=number_formater(this.call.dst_number)
          else title=this.call.dst_name
        } else {
          if(this.call.src_type=='Number') title=number_formater(this.call.src_number)
          else title=this.call.src_name
        }
        const { data } = await VOIP_API.endpoints.tasklist.callAssign({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          title: title,
          cdr_id: this.callID,
          type: type
        })
        this.appNotify({
          message: 'Successfully Added to Tasks',
          type: 'success',
        })
        this.$modal.show('GlobalEditTask',{
          id: data.id,
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.add_todo.send=false
      }
    },
  },
  mounted(){
    if(this.params.task_modal) {
      this.selected.tab=tabs.task
    }
    this.data.call_info = this.log
    this.load()
  },
};
</script>

<style>
</style>