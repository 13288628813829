<template>
  <div class="latestMonitorDashboardBox-row" @click="$emit('clicked')">
    <template v-if="userDashboard">
      <template v-if="isLive">
        <div class="latestMonitorDashboardBox-column one">
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon " v-if="call.type=='incoming'" icon="monitorDashboard-table-inboundCall" width="25.827px" height="25.833px" />
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-else icon="monitorDashboard-table-outboundCall" width="25.829px" height="25.829px" />
          <!-- <vb-icon class="latestMonitorDashboardBox-firstColumn-icon " v-if="call.status=='Received'" icon="monitorDashboard-table-inboundCall" width="25.827px" height="25.833px" />
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon " v-else-if="call.status=='Missed'" icon="monitorDashboard-table-missedCall" width="25.827px" height="25.833px" />
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-else icon="monitorDashboard-table-outboundCall" width="25.829px" height="25.829px" /> -->
          <Info :id="call.accountcode" />
        </div>
        <div class="latestMonitorDashboardBox-column two">
          <div class="latestMonitorDashboardBox-text">{{duration | time_audio}}</div>
        </div>
        <div class="latestMonitorDashboardBox-column three">
          <template v-if="constextensionAccountcodeInfo">
            <vb-icon class="mr-12px" :icon="constextensionAccountcodeInfo.type | accountType('icon')" width="20.219px" height="24.997px" />
            <span class="latestMonitorDashboardBox-text">{{constextensionAccountcodeInfo.accountname}}</span>
          </template>
        </div>
        <div class="latestMonitorDashboardBox-column four">
          <b-dropdown 
            v-if="call.accountcode!=getCurrentUser.sip.user"
            :disabled="!getUserPermissions.monitor_dashboard" 
            v-b-tooltip :title="getUserPermissions.monitor_dashboard?'':'Your package does not support this feature'"
            varient="link" no-caret class="latestMonitorDashboardBox-dropdown"
          >
            <template #button-content>
              <span class="mr-8px">Listen in</span>
              <vb-icon width="20.524px" height="17.127px" icon="monitordashboard-listenIn-icon" />
            </template>
            <b-dropdown-item :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send" @click.stop="calling(call,'listen')">Listen</b-dropdown-item>
            <b-dropdown-item :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send" @click.stop="calling(call,'whisper')">Whisper</b-dropdown-item>
            <b-dropdown-item :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send" @click.stop="calling(call,'barge')">Barge</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="latestMonitorDashboardBox-column five">
          <div class="latestMonitorDashboardBox-text">{{ call.created_at | filter_date_current('',{ unix: true, formate: 'YYYY-MM-DD h:mm:ss a' }) }}</div>
        </div>
      </template>
      <template v-else>
        <div class="latestMonitorDashboardBox-column one">
          <Info :id="call.accountcode" />
        </div>
        <div class="latestMonitorDashboardBox-column two">
          <div v-if="call.bill_sec" class="latestMonitorDashboardBox-text">{{call | get_property('bill_sec') | time_audio}}</div>
        </div>
        <div class="latestMonitorDashboardBox-column three">
          <template v-if="constextensionAccountcodeInfo">
            <vb-icon class="mr-12px" :icon="constextensionAccountcodeInfo.type | accountType('icon')" width="20.219px" height="24.997px" />
            <span class="latestMonitorDashboardBox-text">{{constextensionAccountcodeInfo.accountname}}</span>
          </template>
        </div>
        <div class="latestMonitorDashboardBox-column four">
          <div v-if="call.soundFile" class="d-flex align-items-center">
            <button @click.stop="$emit('click-play',{ soundFile: call.soundFile })" class="border-0 monitorDashboardIconsButton mr-8px">
              <vb-icon icon="playSound-callLog-icon" width="35px" height="35px" v-b-tooltip.hover title="Call recording" />
            </button>
            <button @click.stop="$emit('ai-modal')" class="border-0 monitorDashboardIconsButton">
              <vb-icon icon="ai-audio-icon" width="26px" height="24px" class="cursor_pointer_no_hover ai-audio-icon" />
            </button>
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column five">
          <div class="latestMonitorDashboardBox-text">{{parseInt(call.date) | filter_date_current(null,{ unix: true, formate: 'YYYY-MM-DD h:mm:ss a' })}}</div>
        </div>
      </template>
    </template>
    <template v-else>
      <template v-if="isLive">
        <div class="latestMonitorDashboardBox-column one">
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-if="call.type!='outgoing'" icon="monitorDashboard-table-inboundCall" width="25.827px" height="25.833px" />
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-else icon="monitorDashboard-table-outboundCall" width="25.829px" height="25.829px" />
          <div class="latestMonitorDashboardBox-text">
            <Info :id="call.accountcode" :only_name="true" />
            is in call with
            <span class="darkerColor">{{call.number | number_formater}}</span>
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column two">
          <div class="latestMonitorDashboardBox-text">{{duration | time_audio}}</div>
          <div v-if="call.status=='Ringing'" class="latestMonitorDashboardBox-text mr-8px">Ringing</div>
        </div>
        <div class="latestMonitorDashboardBox-column three">
          <div class="latestMonitorDashboardBox-text">
            Via <img :src="call.number | number_formater | flag_icon" /> {{call.number | number_formater}} 
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column four">
          <b-dropdown 
            v-if="call.accountcode!=getCurrentUser.sip.user"
            :disabled="!getUserPermissions.monitor_dashboard" 
            v-b-tooltip :title="getUserPermissions.monitor_dashboard?'':'Your package does not support this feature'"
            varient="link" no-caret class="latestMonitorDashboardBox-dropdown"
          >
            <template #button-content>
              <span class="mr-8px">Listen in</span>
              <vb-icon width="20.524px" height="17.127px" icon="monitordashboard-listenIn-icon" />
            </template>
            <b-dropdown-item :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send" @click.stop="calling(call,'listen')">Listen
            </b-dropdown-item>
            <b-dropdown-item :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send" @click.stop="calling(call,'whisper')">Whisper</b-dropdown-item>
            <b-dropdown-item :disabled="!getUserPermissions.monitor_dashboard || call.status!='Up' || api.calling.send" @click.stop="calling(call,'barge')">Barge</b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="latestMonitorDashboardBox-column five">
          <div class="latestMonitorDashboardBox-text">{{ call.created_at | filter_date_current('',{ unix: true, formate: 'YYYY-MM-DD h:mm:ss a' }) }}</div>
        </div>
      </template>
      <template v-else>
        <div class="latestMonitorDashboardBox-column one">
          <!-- <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-if="call.call_direction=='inbound'" icon="monitorDashboard-table-inboundCall" width="25.827px" height="25.833px" />
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-else icon="monitorDashboard-table-outboundCall" width="25.829px" height="25.829px" /> -->
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon " v-if="call.status=='Received'" icon="monitorDashboard-table-inboundCall" width="25.827px" height="25.833px" />
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon " v-else-if="call.status=='Missed'" icon="monitorDashboard-table-missedCall" width="25.827px" height="25.833px" />
          <vb-icon class="latestMonitorDashboardBox-firstColumn-icon" v-else icon="monitorDashboard-table-outboundCall" width="25.829px" height="25.829px" />
          
          <div v-if="call.call_direction=='outbound'" class="latestMonitorDashboardBox-text">
            <span class="darkerColor">{{ (call.src_name || call.src_number) | number_formater }}{{ call.src_extn ? ` (${call.src_extn})` : '' }}</span>
            was in call with
            <span v-if="call.dst_type=='Number'" class="darkerColor">{{call.dst_number | number_formater}}</span>
            <span v-else class="darkerColor">{{ (call.dst_name || call.dst_number) | number_formater }}{{ call.dst_extn ? ` (${call.dst_extn})` : '' }}</span>
          </div>
          <div v-else class="latestMonitorDashboardBox-text">
            <template v-if="call.status=='Missed'">
              <span class="darkerColor">{{ (call.dst_name || call.dst_number) | number_formater }}{{ call.dst_extn ? ` (${call.dst_extn})` : '' }}</span>
              missed a call from
              <span v-if="call.src_type=='Number'" class="darkerColor">{{call.src_number | number_formater}}</span>
              <span v-else class="darkerColor">{{ (call.src_name || call.src_number) | number_formater }}{{ call.src_extn ? ` (${call.src_extn})` : '' }}</span>
            </template>
            <template v-else>
              <span class="darkerColor">{{ (call.dst_name || call.dst_number) | number_formater }}{{ call.dst_extn ? ` (${call.dst_extn})` : '' }}</span>
              was in call with
              <span v-if="call.src_type=='Number'" class="darkerColor">{{call.src_number | number_formater}}</span>
              <span v-else class="darkerColor">{{ (call.src_name || call.src_number) | number_formater }}{{ call.src_extn ? ` (${call.src_extn})` : '' }}</span>
            </template>
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column two">
          <div class="latestMonitorDashboardBox-text">{{call | get_property('bill_sec')}}</div>
        </div>
        <div class="latestMonitorDashboardBox-column three">
          <div v-if="call.src_type=='Number' || call.dst_type=='Number'" class="latestMonitorDashboardBox-text">
            Via 
            <template v-if="call.call_direction=='outbound'">
              <img :src="call.src_number | number_formater | flag_icon" /> {{call.src_number | number_formater}}
            </template>
            <template v-else>
              <img :src="call.dst_number | number_formater | flag_icon" /> {{call.dst_number | number_formater}} 
            </template>
          </div>
        </div>
        <div class="latestMonitorDashboardBox-column four">
          <template v-if="call.soundFile">
            <div class="d-flex align-items-center">
              <button class="monitorDashboardIconsButton mr-8px" @click.stop="$emit('click-play',{ soundFile: call.soundFile })">
                <vb-icon icon="playSound-callLog-icon" width="35px" height="35px" v-b-tooltip.hover title="Call recording" />
              </button>
              <button class="monitorDashboardIconsButton" @click.stop="$emit('ai-modal')">
                <vb-icon icon="ai-audio-icon" width="26px" height="24px" class="cursor_pointer_no_hover ai-audio-icon" />
              </button>
            </div>
          </template>
        </div>
        <div class="latestMonitorDashboardBox-column five">
          <div class="latestMonitorDashboardBox-text">{{parseInt(call.date) | filter_date_current(null,{ unix: true, formate: 'YYYY-MM-DD h:mm:ss a' })}}</div>
        </div>
      </template>
    </template>
  </div>
</template>

<script>
import { events, VOIP_API } from '@/utils'
import Info from '../Lists/Info.vue'
import { mapGetters } from 'vuex'
// import _ from 'lodash'
// import moment from 'moment-timezone'
export default {
  name: 'ActiveCallItem',
  components: { 
    Info 
  },
  inject: [
    'appNotify',
  ],
  props: {
    call: {
      type: Object,
      default: ()=>({}),
    },
    extension_data: {
      type: Object,
      default: ()=>({}),
    },
    userDashboard: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      duration: 0,
      api: {
        calling: this.$helperFunction.apiInstance(),
      },
    }
  },
  filters: {
    accountType(val,type='text') {
      if(type=='icon') {
        if(val=='WEBEXT') return 'devices-webExtension-icon'
        else if(val=="USER") return "monitorDashboard-table-user";
        else if(val=='DESKTOPEXT') return 'devices-desktopExtension-icon'
        else if(val=='MOBILEEXT') return 'devices-mobileExtension-icon'
        else if(val=='HEADEXT') return 'devices-headExtension-icon'
        else if(val=='INTEGRATIONEXT') return 'devices-headExtension-icon'
        else if(val=='EXTERNALEXT') return 'devices-headExtension-icon'
        else return 'devices-webExtension-icon'
      } else if(type=='text') {
        if(val=="MOBILEEXT") return "Mobile app";
        else if(val=="USER") return "User";
        else if(val=="DESKTOPEXT") return "Desktop app";
        else if(val=="WEBEXT") return "Web app";
        else if(val=="HEADEXT") return "IP phone";
        else if(val=="INTEGRATIONEXT") return "Integration app";
        else if(val=="EXTERNALEXT") return "External app";
        return val;
      }
    },
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
    ]),
    isLive(){ return this.call.ami },
    constextensionAccountcodeInfo(){ return this.extension_data?.[this.call?.extension_accountcode || this.call?.accountcode] },
  },
  methods: {
    async calling(call,type='listen'){
      if(this.api.calling.send) return;
      try {
        this.api.calling.send=true
        const { data } = await VOIP_API.endpoints.user.extensionAccountInfo({
          accountcode: call.accountcode
        })
        this.$root.$emit(events.audio_call,{
          call_type: type,
          number: data.extn,
          callee_id: data.user_ext,
          callee_type: 'user',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.calling.send=false
      }
    },
    // startTimer: _.debounce(function(){
    //   if(this.call.answered_at) {
    //     this.duration=moment.utc().diff(moment.unix(this.call.answered_at),'seconds')
    //   }
    //   this.startTimer()
    // }, 1*1000)
  },
  mounted(){
    // this.startTimer()
  },
}
</script>

<style>

</style>