var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hrm-parent"
  }, [_vm.activeTab === 'hrm-home' ? _c('HRMHome', {
    ref: "hrm-home"
  }) : _vm._e(), _vm.activeTab === 'HrmHomePage' ? _c('HrmHomePage', {
    ref: "HrmHomePage"
  }) : _vm._e(), _vm.activeTab === 'hrm-calender' ? _c('HRMCalender', {
    ref: "hrm-calender"
  }) : _vm._e(), _vm.activeTab === 'hrm-setting' ? _c('HRMSetting', {
    ref: "hrm-setting"
  }) : _vm._e(), _vm.activeTab === 'hrm-reports' ? _c('HRMReports', {
    ref: "hrm-reports"
  }) : _vm._e(), _vm.activeTab === 'hrm-settingNew' ? _c('SettingsNew', {
    ref: "hrm-settingNew"
  }) : _vm._e(), _vm.activeTab === 'HrmDocuments' ? _c('HrmDocuments', {
    ref: "HrmDocuments"
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }