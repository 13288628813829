
import { VOIP_API } from "../index"

export const TICKETS_API_ENDPOINTS = {
  get(params={},token){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `tickets`,
      params,
      cancelToken: token,
    })
  },
  update(id='',data={}) {
    return VOIP_API.axios.voip.request({
      method: 'PATCH',
      url: `tickets/${id}`,
      data,
    })
  },
  create(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'tickets',
      data,
    })
  },
  getById(id='',params={},token){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `tickets/${id}`,
      params,
      cancelToken: token,
    })
  },
  createTicket(data={}){
    return VOIP_API.axios.voip.request({
      url: `ticket/comments`,
      method: 'POST',
      data,
    })
  },
  toggleStatus(id='',params={}){
    return VOIP_API.axios.voip.request({
      url: `tickets/${id}/toggle/status`,
      method: 'GET',
      params,
    })
  },
  updateFeedback(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `tickets/${id}/feedbacks`,
      method: 'POST',
      data,
    })
  },
}