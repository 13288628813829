<template>
  <div>
    <modal
      class="SendSmsModal"
      width="400px"
      height="auto"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-user w-100">
        <div class="dialer-edit">
          <form class="dialer-form" @submit.prevent="sendSms()">
            <div class="dialer-edit-header w-100 d-flex justify-content-between align-items-center">
              <h2 class="dialer-edit-title newer mb-0">Send SMS</h2>
              <div class="dialer-edit-actions">
                <a class="newCloseButton" @click="$modal.hide(modalName)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <b-alert :show="api.fetch_balance_inquiry.status==1 && !canSendSMS" variant="danger">You don't have balance to send sms</b-alert>
              <b-alert :show="!!api.send_sms.error_message" variant="danger">{{ api.send_sms.error_message }}</b-alert>
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion dialer-max-width dialer-min-height">
                <label>Select number to send sms</label>
                <div>
                  <b-form-select v-model="forms.send_sms.vonage_number">
                    <b-form-select-option v-for="num in response.vonage_numbers" :key="num" :value="num">{{ num | number_formater }}</b-form-select-option>
                  </b-form-select>
                  <p v-if="(forms.send_sms.submitted && $v.forms.send_sms.vonage_number.$invalid) || api.send_sms.validation_errors.vonage_number" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.send_sms.vonage_number.required">* Number is required</span>
                    <span v-for="(em, i) in api.send_sms.validation_errors.vonage_number" :key="i">* {{m}}</span>
                  </p>
                </div>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder dialer-max-width dialer-min-height mt-20px">
                <label>Number</label>
                <div>
                  <input
                    placeholder="Enter Number"
                    class="touched w-100"
                    type="text"
                    v-vb-input-number-formate
                    v-model="forms.send_sms.number"
                    :disabled="api.send_sms.send"
                    @change="balanceInquiryDebounce()"
                  />
                  <p v-if="(forms.send_sms.submitted && $v.forms.send_sms.number.$invalid) || api.send_sms.validation_errors.number" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.send_sms.number.required">* Number is required</span>
                    <span v-else-if="!$v.forms.send_sms.number.validNumber">* Number is invalid</span>
                    <span v-for="(em, i) in api.send_sms.validation_errors.number" :key="i">* {{m}}</span>
                  </p>
                </div>
              </div>
              <div class=" whiteBGinputWithGreyRoundedBorder dialer-max-width mt-20px dialer-min-height">
                <label >Message</label>
                <div>
                  <textarea
                    class="touched textArea-SendSmsModal w-100"
                    type="text"
                    v-model="forms.send_sms.message"
                    :maxlength="$v.forms.send_sms.message.$params.maxLength.max"
                    :disabled="!canSendSMS || api.send_sms.send"
                  />
                  <p v-if="(forms.send_sms.submitted && $v.forms.send_sms.message.$invalid) || api.send_sms.validation_errors.message" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.send_sms.message.required">* Message is required</span>
                    <span v-else-if="!$v.forms.send_sms.message.maxLength">* Message can be maximum {{ $v.forms.send_sms.message.$params.maxLength.max }} character</span>
                    <span v-for="(em, i) in api.send_sms.validation_errors.message" :key="i">* {{m}}</span>
                  </p>
                </div>
              </div>
              <div class="w-100">
                <button :disabled="!canSendSMS || api.send_sms.send" class="fullWidthDoneButton mt-20px">
                  <vb-spinner v-if="api.send_sms.send" variant="primary" />
                  <template v-else>
                    Send
                  </template>
                </button>
              </div>
              <!-- {{ response.balance_inquiry | get_property('sms_balance',0) | to_fixed }}
              {{ response.balance_inquiry | get_property('balanceAfterDeduction',0) | to_fixed }} -->
            </div>
          </form>
        </div>
      </div>
    </modal>
    <AddCreditModal :modalName="`${_uid}-AddCreditModal`" />
  </div>
</template>

<script>
import AddCreditModal from '../Modals/AddCreitModal.vue';
import { required, maxLength } from "vuelidate/lib/validators";
import { mapGetters, mapMutations } from 'vuex';
import { $fn, VOIP_API } from '../../utils';
import _ from 'lodash'
export default {
  name: "SendSmsModal",
  components: {
    AddCreditModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'SendSmsModal',
    },
  },
  data() {
    return {
      forms: {
        send_sms: this.$helperFunction.formInstance({
          data: {
            message: '',
            number: '',
            vonage_number: '',
          },
        }),
      },
      api: {
        send_sms: this.$helperFunction.apiInstance({ 
          error_message: true, 
          validation_errors: true, 
        }),
        fetch_balance_inquiry: this.$helperFunction.apiInstance({
          status: true
        }),
        fetch_vonage_number: this.$helperFunction.apiInstance({
          status: true
        }),
      },
      response: {
        balance_inquiry: {},
        vonage_numbers: {},
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    canSendSMS(){ return parseFloat(this.response.balance_inquiry?.sms_balance || '0')>0 },
  },
  validations: {
    forms: {
      send_sms: {
        message: { 
          required,
          maxLength: maxLength(160) 
        },
        number: { 
          required, 
          validNumber(value){ return $fn.validPhoneNumber(value,'','mobile') },
        },
        vonage_number: { 
          required, 
        },
      },
    },
  },
  methods: {
    ...mapMutations([
      'pushCallActivity',
    ]),
    onBeforeOpen(event){
      this.forms.send_sms.number=event?.params?.number ?? ''
      this.balanceInquiry()
      this.fetchVonageNumber()
    },
    onBeforeClose(){
      this.forms.send_sms.reset()
      this.api.send_sms.reset()
    },
    balanceInquiryDebounce: _.debounce(function(){
      this.balanceInquiry()
    }, 1*1000),
    async balanceInquiry(){
      this.$v.forms.send_sms.number.$touch()
      if(this.$v.forms.send_sms.number.$invalid) return;
      try {
        this.api.fetch_balance_inquiry.status=0
        this.response.balance_inquiry={}
        this.api.fetch_balance_inquiry.send=true
        const { data } = await VOIP_API.endpoints.sms.balanceInquiry({
          uid: this.getCurrentUser?.uid,
          number: this.forms.send_sms.number,
        })
        this.response.balance_inquiry=data
        this.api.fetch_balance_inquiry.status=1
        await this.$nextTick()
        if(!this.canSendSMS && this.getCurrentUser.administrator_account) {
          this.$modal.show(`${this._uid}-AddCreditModal`,{ sms_balance_products: true, })
        }
      } catch (ex) {
        this.api.fetch_balance_inquiry.status=2
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_balance_inquiry.send=false
      }
    },
    async fetchVonageNumber(){
      if(this.api.fetch_vonage_number.send) return;
      try {
        this.api.fetch_vonage_number.status=0
        this.api.fetch_vonage_number.send=true
        const { data } = await VOIP_API.endpoints.sms.getvonagenumbers({
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
        })
        this.response.vonage_numbers=data
        this.api.fetch_vonage_number.status=1
      } catch (ex) {
        this.api.fetch_vonage_number.status=2
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_vonage_number.send=false
      }
    },
    async sendSms(){
      this.forms.send_sms.submitted=true
      this.$v.forms.send_sms.$touch()
      if(this.$v.forms.send_sms.$invalid || this.api.send_sms.send || this.api.fetch_balance_inquiry.status!=1) return;
      try {
        this.api.send_sms.send=true
        this.api.send_sms.error_message=''
        this.api.send_sms.validation_errors={}
        const number = $fn.getNumberFormated(this.forms.send_sms.number)
        await VOIP_API.endpoints.sms.sendsms({
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid,
          to: number,
          from_number: this.forms.send_sms.vonage_number,
          from: this.getCurrentUser?.account,
          text: this.forms.send_sms.message,
        })
        this.appNotify({
          message: "Successfully send",
          type: "success",
        })
        this.$emit('sms-send')
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.send_sms.error_message=ex.own_message || ex.message
        this.api.send_sms.validation_errors=ex.own_errors || {}
      } finally {
        this.api.send_sms.send=false
      }
    },
  },
};
</script>
