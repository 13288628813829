var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "top-main-hrm"
  }, [_c('section', [_c('div', {
    staticClass: "bg-hrm-mike scrollow1"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-start"
  }, [_c('div', {
    staticClass: "w-75 for-home-dd-width d-flex flex-wrap"
  }), _c('div', {
    staticClass: "w-25 top-btn-align"
  }, [_c('button', {
    staticClass: "btn-svg-none",
    on: {
      "click": function ($event) {
        _vm.design.grid = false;
      }
    }
  }, [_c('svg', {
    staticClass: "mr-top-sidebsvg",
    attrs: {
      "id": "Group_16231",
      "data-name": "Group 16231",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12.033",
      "height": "15.016",
      "viewBox": "0 0 12.033 15.016"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_ionic-md-arrow-dropright",
      "data-name": "Icon ionic-md-arrow-dropright",
      "d": "M0,0,6.016,6.016,0,12.033Z",
      "transform": "translate(12.033 9) rotate(90)",
      "fill": "#5576d1"
    }
  }), _c('path', {
    attrs: {
      "id": "Icon_ionic-md-arrow-dropright-2",
      "data-name": "Icon ionic-md-arrow-dropright",
      "d": "M0,0,6.016,6.016,0,12.033Z",
      "transform": "translate(0 6.016) rotate(-90)",
      "fill": "#5576d1"
    }
  })])]), _c('button', {
    staticClass: "btn-svg-none",
    on: {
      "click": function ($event) {
        _vm.design.grid = true;
      }
    }
  }, [_c('svg', {
    staticClass: "mr-top-sidebsvg",
    attrs: {
      "id": "Icon_ionic-ios-menu",
      "data-name": "Icon ionic-ios-menu",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21.607",
      "height": "15.75",
      "viewBox": "0 0 21.607 15.75"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_16",
      "data-name": "Path 16",
      "d": "M25.207,12.375H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,12.375Z",
      "transform": "translate(-4.5 -10.125)",
      "fill": "#5576d1"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_17",
      "data-name": "Path 17",
      "d": "M25.207,19.125H5.4A1.035,1.035,0,0,1,4.5,18h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,19.125Z",
      "transform": "translate(-4.5 -10.125)",
      "fill": "#5576d1"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_18",
      "data-name": "Path 18",
      "d": "M25.207,25.875H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,25.875Z",
      "transform": "translate(-4.5 -10.125)",
      "fill": "#5576d1"
    }
  })])])])]), _c('div', {
    staticClass: "card-container-main-box mt-5"
  }, [_vm.design.grid ? [_vm._l(_vm.filterResources, function (resource) {
    return _c('div', {
      key: resource.id,
      staticClass: "card-contain"
    }, [_c('div', {
      staticClass: "imgify-home-box"
    }, [_c('img', {
      staticClass: "imgify-heiwid",
      attrs: {
        "src": _vm._f("get_property")(resource, 'image')
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.local_filess.images.user_male;
        }
      }
    })]), _c('div', {
      staticClass: "decription-img-box-home"
    }, [_vm._v(_vm._s(resource.resource_name))]), _c('div', {
      staticClass: "decription-img-box-home"
    }, [_vm._v(_vm._s(_vm._f("get_property")(resource, 'resource_group.resource_type')))]), _c('div', {
      staticClass: "decription-img-box-home"
    }, [_vm._v("Users Assigned: " + _vm._s(_vm._f("get_property")(resource, 'count', 0)))])]);
  }), _vm.filterResources.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": '',
      "design": 3
    }
  }, [_vm.api.fetch_resources.send ? _c('vb-loading', {
    staticClass: "text-center w-100",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()] : [_c('vb-table', {
    attrs: {
      "isListEmpty": _vm.filterResources.length == 0,
      "listLength": _vm.filterResources.length,
      "loading": _vm.api.fetch_resources.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterResources, function (resource, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: resource.id
          }, [_c('td', {
            staticClass: "dialer-row-title dialer-col-name-width wd-75"
          }, [_c('div', {
            staticClass: "d-flex dialer-numberWithCountry"
          }, [_c('span', [_c('img', {
            staticClass: "country-img",
            attrs: {
              "width": "45px",
              "src": _vm._f("get_property")(resource, 'image')
            },
            on: {
              "error": function ($event) {
                $event.target.src = _vm.local_filess.images.user_male;
              }
            }
          })]), _c('span', {
            staticClass: "d-flex flex-column ml-1 dialer-numberInfo justify-content-center"
          }, [_c('span', {
            staticClass: "m-0 didNumber"
          }, [_vm._v(_vm._s(resource.resource_name))])])])]), _c('td', {
            staticClass: "dialer-col-center wd-25"
          }, [_vm._v(_vm._s(_vm._f("get_property")(resource, 'resource_group.resource_type')))]), _c('td', {
            staticClass: "dialer-col-center wd-25"
          }, [_vm._v(_vm._s(_vm._f("get_property")(resource, 'count', 0)))])]);
        });
      }
    }])
  }, [_c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-center"
  }, [_c('span', [_vm._v("Type")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-center"
  }, [_c('span', [_vm._v("Users Assigned")])])])])]], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }