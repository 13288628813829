<template>
  <modal 
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"  
    :name="modalName" 
    @before-open="onBeforeOpen" 
    @before-close="onBeforeClose"
    @closed="onClose"
  >
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Playlist Files</div>
      <div class="plus-IOSIcon-container"></div>
    </div>

    <div class="innerModalIos">


      <div class="">
        
        <div class="basicWhiteIOScard mb-16px mt-32px">
          <div class="basicWhiteIOScard-item flex-column align-items-start">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div class="headingInsideTable">Set Default MOH</div>
              <div class="dd-updatedIos">
                <select class="custom-select" v-model="forms.playlist.id" @change="updateUserPlaylist()">
                  <option value="" disabled>Choose Playlist</option>
                  <option v-for="playlist in response.playlists" :key="playlist.id" :value="playlist.id">{{ playlist.label }}</option>
                </select>
              </div>
            </div>
            <template v-if="forms.playlist.submitted && $v.forms.playlist.id.$invalid">
              <small v-if="!$v.forms.playlist.id.required">Select Playlist</small>
            </template>
          </div>
        </div>
        <div class="">
          <div class="textBelowCard">Following are the audio files present in the current MOH playlist.</div>
          <div class="basicWhiteIOScard mb-16px">
            <div v-for="item in selectedList" :key="item.real_id" class="basicWhiteIOScard-item">
              <div class="headingInsideTable">{{ item.label }}</div>
              <vb-audio-player class="IosAudioPlayer" :src="item.sound_file" />
            </div>
            <vb-no-record v-if="isEmpty(selectedList)" :text="api.playlists.send ? '' : 'there is no selected recording list'" :design="3">
              <vb-loading v-if="api.playlists.send" slot="loading" />
            </vb-no-record>
          </div>
        </div>
        

      </div>

    </div>

  </modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { VOIP_API } from "@/utils";
import { mapGetters } from 'vuex';

export default {
  name: "SelectPlaylist",
  inject:['isEmpty','appNotify'],
  props: {
    fromGlobal: {
      type: Boolean,
      default: false,
    },
    modalName: {
      type: String,
      default: 'SelectPlaylist',
    },
  },
  data() {
    return {
      response: {
        available: [],
        playlists: [],
        selected: [],
      },
      api: {
        playlists: this.$helperFunction.apiInstance({  }),
        update: this.$helperFunction.apiInstance({  }),
      },
      forms: {
        playlist: this.$helperFunction.formInstance({
          data: {
            id: '',
          }
        }),
      },
      data: {
        accountcode: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    selectedList(){
      return this.response.playlists.find(i=>i.id==this.data.id)?.playlist?.map?.(i=>{
        return {
          id: i.sound_id ?? i.id,
          label: i.sound_file?.sound_name ?? i.label,
          real_id: i.id ?? i.real_id,
          sound: i.sound_file?.sound_file ?? i.sound,
          sound_file: i.sound_file?.sound ?? i.sound_file,
        }
      }) ?? this.response.selected ?? []
    },
  },
  validations: {
    forms: {
      playlist: {
        id: required,
      },
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.forms.playlist.id = event.params?.id ?? '';
      this.data.accountcode = event.params?.account ?? '',
      this.fetchPlayListFiles();
    },
    onBeforeClose() {
      this.forms.playlist.reset()
      this.api.playlists.reset()
      this.api.update.reset()
      this.response.playlists=[]
      this.response.selected=[]
      this.response.available=[]
    },
    updateUserPlaylist() {
      let vm = this
      vm.forms.playlist.submitted=true
      vm.$v.forms.playlist.$touch()
      if(vm.$v.forms.playlist.$invalid || vm.api.update.send) return;
      vm.api.update.send = true
      VOIP_API.endpoints.music_on_hold.updateplaylist(vm.forms.playlist.id, {
        flag: "changedefault",
        type: vm.fromGlobal?'admin':"user",
        fid: 12,
        account: vm.data.accountcode,
      })
      .then(()=>{
        vm.$emit('updated')
        vm.fetchPlayListFiles()
        vm.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.forms.playlist.submitted=false
        vm.api.update.send=false
      });
    },
    fetchPlayListFiles() {
      let vm = this
      if (vm.api.playlists.send) return;
      vm.api.playlists.send = true;
      VOIP_API.endpoints.music_on_hold.playlistfiles(vm.forms.playlist.id || vm.getCurrentUser.account, {
        account: vm.data.accountcode,
        accountcode: vm.data.accountcode,
      })
      .then(({ data })=>{
        vm.response.available = data?.available?.data
        vm.response.playlists = data?.playlists?.data
        vm.response.selected = data?.selected?.data
      })
      .finally(()=>{
        vm.api.playlists.send=false
      })
    },
    onClose() {
      this.$emit("close");
    },
  }
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
