
import { VOIP_API } from "../index"

export const SNOOZ_API_ENDPOINTS = {
  getSetting(accountcode=''){
    return VOIP_API.axios.voip.request({
      url: `snooze-setting/${accountcode}`,
      method: "GET",
    })
  },
  setSetting(data={}){
    return VOIP_API.axios.voip.request({
      url: `snooze-setttings`,
      method: "POST",
      data,
    })
  },
}