var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Time Off Approvers ")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Here you can set up who will receive time off requests for approval for your employees ")]), _c('div', {
    staticClass: "dropdownsContainer"
  }, [_c('b-dropdown', {
    staticClass: "primaryBorderDropdown w-240px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All teams")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All teams ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown w-240px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All locations")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown w-240px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All employment statuses")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All employment statuses ")])], 1)], 1), _c('div', {
    staticClass: "settings-TimeOffApproversTable mt-16px"
  }, [_vm._m(0), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson")])])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v("20 days")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_vm._v("02 days")]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_vm._v("-")]), _c('td', {
          staticClass: "dialer-row-title six"
        }, [_vm._v("20 days")]), _c('td', {
          staticClass: "dialer-row-title seven"
        }, [_vm._v("02 days")])])];
      },
      proxy: true
    }])
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Employee")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("Employee")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Admin")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("Direct manager")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Team Manager")]), _c('div', {
    staticClass: "HrmTableHead six"
  }, [_vm._v("Individual approvers")]), _c('div', {
    staticClass: "HrmTableHead seven"
  }, [_vm._v("Actions")])]);

}]

export { render, staticRenderFns }