var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_vm.conditions.screen == 2 ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 0;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Pin for voicemail")])]) : _vm.conditions.screen == 1 ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 0;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v(_vm._s(_vm.selected.greeting_type ? 'Basic' : 'Advanced'))])]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Voicemail Settings")])]), _c('section', {
    staticClass: "dialer-settings-section dialer-voicemail-settings"
  }, [_c('div', [_vm.conditions.screen == 2 ? _c('div', [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Pin")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_voicemail_setting.pin,
      expression: "forms.update_voicemail_setting.pin"
    }],
    attrs: {
      "placeholder": "6 digit Pin",
      "type": "text",
      "maxlength": "6",
      "oninput": "this.value = this.value.replace(/[^0-9]/gi, '')",
      "disabled": _vm.api.update_voicemail_setting.send || !_vm.forms.update_voicemail_setting.password_enable || _vm.api.load.send
    },
    domProps: {
      "value": _vm.forms.update_voicemail_setting.pin
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_voicemail_setting, "pin", $event.target.value);
      }
    }
  }), _vm.forms.update_voicemail_setting.submitted && _vm.forms.update_voicemail_setting.pin.$invalid || _vm.api.update_voicemail_setting.validation_errors.mailboxpin ? [_vm._l(_vm.api.update_voicemail_setting.validation_errors.mailboxpin, function (em, i) {
    return _c('small', {
      key: i,
      staticClass: "danger field-error bold-4"
    }, [_vm._v(_vm._s(em))]);
  }), !_vm.$v.forms.update_voicemail_setting.pin.required ? _c('small', {
    staticClass: "danger sm-mar-top"
  }, [_vm._v("*Pin is Required")]) : _vm._e(), !_vm.$v.forms.update_voicemail_setting.pin.minLength ? _c('small', {
    staticClass: "danger sm-mar-top"
  }, [_vm._v(" *Pin should be " + _vm._s(_vm.$v.forms.update_voicemail_setting.pin.$params.minLength.max) + " digit ")]) : _vm._e()] : _vm._e()], 2)])])]) : _vm.conditions.screen == 1 ? _c('div', [_vm.api.list.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading",
      "type": "loader"
    },
    slot: "loading"
  }) : [_vm.selected.greeting_type ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item justify-content-between d-flex"
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Temporary")])])]), _c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "mr-8px"
  }, [_vm.temp.voice ? _c('vb-audio-player', {
    staticClass: "IosAudioPlayer",
    attrs: {
      "disabled": _vm.api.change_greeting_type.send,
      "src": _vm._f("base64_to_url")(_vm.temp.voice)
    }
  }) : _c('div', {
    staticClass: "textInsideCard mt-5px"
  }, [_vm._v("No Audio")])], 1), _c('div', {
    staticClass: "dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight"
  }, [_vm.api.delete_record_voice_mail.send.includes('temp') ? _c('vb-loading', {
    attrs: {
      "type": "loader"
    }
  }) : _c('b-dropdown', {
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }], null, false, 342389325)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('record', 'temp');
      }
    }
  }, [_vm._v("Record a new Greeting Message")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('upload', 'temp');
      }
    }
  }, [_vm._v("Upload a Greeting Message")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('shift', 'temp');
      }
    }
  }, [_vm._v("Select file from sound library")])], 1)], 1)])])])] : [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Busy")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.api.delete_record_voice_mail.send.includes('busy') ? _c('vb-loading', {
    attrs: {
      "type": "loader"
    }
  }) : [_c('div', {
    staticClass: "mr-6px"
  }, [_vm.busy.voice ? _c('vb-audio-player', {
    staticClass: "IosAudioPlayer",
    attrs: {
      "disabled": _vm.api.change_greeting_type.send,
      "src": _vm._f("base64_to_url")(_vm.busy.voice)
    }
  }) : _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v("No Audio")])], 1), _c('div', {
    staticClass: "dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight"
  }, [_c('b-dropdown', {
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('record', 'busy');
      }
    }
  }, [_vm._v("Record a new Greeting Message")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('upload', 'busy');
      }
    }
  }, [_vm._v("Upload a Greeting Message")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('shift', 'busy');
      }
    }
  }, [_vm._v("Select file from sound library")])], 1)], 1)]], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Greet")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.api.delete_record_voice_mail.send.includes('greet') ? _c('vb-loading', {
    attrs: {
      "type": "loader"
    }
  }) : [_c('div', {
    staticClass: "mr-6px"
  }, [_vm.greet.voice ? _c('vb-audio-player', {
    staticClass: "IosAudioPlayer",
    attrs: {
      "disabled": _vm.api.change_greeting_type.send,
      "src": _vm._f("base64_to_url")(_vm.greet.voice)
    }
  }) : _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v("No Audio")])], 1), _c('div', {
    staticClass: "dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight"
  }, [_c('b-dropdown', {
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('record', 'greet');
      }
    }
  }, [_vm._v("Record a new Greeting Message")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('upload', 'greet');
      }
    }
  }, [_vm._v("Upload a Greeting Message")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('shift', 'greet');
      }
    }
  }, [_vm._v("Select file from sound library")])], 1)], 1)]], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Unavailable")])])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.api.delete_record_voice_mail.send.includes('unavail') ? _c('vb-loading', {
    attrs: {
      "type": "loader"
    }
  }) : [_c('div', {
    staticClass: "mr-6px"
  }, [_vm.unavail.voice ? _c('vb-audio-player', {
    staticClass: "IosAudioPlayer",
    attrs: {
      "disabled": _vm.api.change_greeting_type.send,
      "src": _vm._f("base64_to_url")(_vm.unavail.voice)
    }
  }) : _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v("No Audio")])], 1), _c('div', {
    staticClass: "dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight"
  }, [_c('b-dropdown', {
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('record', 'unavail');
      }
    }
  }, [_vm._v("Record a new Greeting Message")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('upload', 'unavail');
      }
    }
  }, [_vm._v("Upload a Greeting Message")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.greetingOptionSelect('shift', 'unavail');
      }
    }
  }, [_vm._v("Select file from sound library")])], 1)], 1)]], 2)])])]]], 2) : [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_vm._m(0), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "password_enable",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.update_voicemail_setting.send || _vm.api.load.send,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.voice_mailbox_sharing, function () {
          return _vm.updateVoicemailSetting();
        });
      }
    },
    model: {
      value: _vm.forms.update_voicemail_setting.password_enable,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_voicemail_setting, "password_enable", $$v);
      },
      expression: "forms.update_voicemail_setting.password_enable"
    }
  })], 1)]), _vm.forms.update_voicemail_setting.password_enable ? _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 2;
      }
    }
  }, [_vm._m(1), _c('div', {
    staticClass: "d-flex align-items-start",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.voice_mailbox_sharing, function () {
          return _vm.conditions.screen = 2;
        });
      }
    }
  }, [_c('div', {
    staticClass: "mr-8px textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.forms.update_voicemail_setting.pin))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_vm._m(2), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "email_enable",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.update_voicemail_setting.send || _vm.api.load.send,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateVoicemailSetting();
      }
    },
    model: {
      value: _vm.forms.update_voicemail_setting.email_enable,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_voicemail_setting, "email_enable", $$v);
      },
      expression: "forms.update_voicemail_setting.email_enable"
    }
  })], 1)]), _vm.forms.update_voicemail_setting.email_enable ? [_vm.userType == 'user' ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Email for voicemail")]), _c('div', {
    staticClass: "mobileInputIOS w-100 mt-8px"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_voicemail_setting.email,
      expression: "forms.update_voicemail_setting.email"
    }],
    staticClass: "w-100",
    attrs: {
      "label": "Company name",
      "disabled": _vm.api.update_voicemail_setting.send || _vm.api.load.send
    },
    domProps: {
      "value": _vm.forms.update_voicemail_setting.email
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_voicemail_setting, "email", $event.target.value);
      }, function ($event) {
        return _vm.updateVoicemailSetting();
      }]
    }
  }), _vm.forms.update_voicemail_setting.submitted && _vm.$v.forms.update_voicemail_setting.email.$invalid || _vm.api.update_voicemail_setting.validation_errors.mailboxemail ? [_vm._l(_vm.api.update_voicemail_setting.validation_errors.mailboxemail, function (em, i) {
    return _c('small', {
      key: i,
      staticClass: "danger field-error bold-4"
    }, [_vm._v(_vm._s(em))]);
  }), !_vm.$v.forms.update_voicemail_setting.email.required ? _c('small', {
    staticClass: "danger"
  }, [_vm._v("*Email is Required")]) : _vm._e(), !_vm.$v.forms.update_voicemail_setting.email.email ? _c('small', {
    staticClass: "danger"
  }, [_vm._v("*Invalid Email")]) : _vm._e()] : _vm._e()], 2)])]) : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_vm._m(3), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "voicemail_attachment",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.update_voicemail_setting.send || !_vm.getUserPermissions.voicemail_attachment || _vm.api.load.send,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateVoicemailSetting();
      }
    },
    model: {
      value: _vm.forms.update_voicemail_setting.voicemail_attachment,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_voicemail_setting, "voicemail_attachment", $$v);
      },
      expression: "forms.update_voicemail_setting.voicemail_attachment"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_vm._m(4), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "voicemail_transcript",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.update_voicemail_setting.send || !_vm.getUserPermissions.voice_mail_transcript || _vm.api.load.send,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateVoicemailSetting();
      }
    },
    model: {
      value: _vm.forms.update_voicemail_setting.voicemail_transcript,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_voicemail_setting, "voicemail_transcript", $$v);
      },
      expression: "forms.update_voicemail_setting.voicemail_transcript"
    }
  })], 1)])] : _vm._e()], 2), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_vm._m(5), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-16px"
  }, [_vm._v(" " + _vm._s(_vm.selected.greeting_type ? 'Basic' : 'Advanced') + " ")]), _c('div', {
    class: `${_vm.conditions.change_greeting_type ? 'rotate-svg IosVersion' : ''}`
  }, [_c('b-form-checkbox', {
    ref: "greeting_switch",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.change_greeting_type.send || _vm.api.load.send,
      "checked": !_vm.selected.greeting_type,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changeGreetingType();
      }
    }
  })], 1)])])]), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, [_vm.conditions.change_greeting_type ? [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.changeGreetingType();
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Basic")]), _c('div'), _vm.selected.greeting_type ? _c('vb-icon', {
    attrs: {
      "icon": "MyNumbers-tick-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.changeGreetingType();
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Advanced")]), !_vm.selected.greeting_type ? _c('vb-icon', {
    attrs: {
      "icon": "MyNumbers-tick-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  }) : _vm._e()], 1)] : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 1;
      }
    }
  }, [_vm._m(6), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard mr-8px textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.selected.greeting_type ? 'Basic' : 'Advanced'))]), _c('div', [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])])], 2), _c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Mailbox Shared Accounts Settings")]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.checkPermission(_vm.getUserPermissions.voice_mailbox_sharing, function () {
          return _vm.$modal.show('MailboxShared', {
            accountcode: _vm.voipaccount
          });
        });
      }
    }
  }, [_vm._m(7), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard primaryColor mr-6px textAlignRight onRightSide"
  }, [_vm.selectedMemebersLength > 0 ? [_vm._v(_vm._s(_vm.selectedMemebersLength))] : [_vm._v(" Add ")], _vm._v(" users ")], 2), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])])]], 2)]), _c('AddRemoveUsersModal', {
    attrs: {
      "modalName": 'MailboxShared',
      "type": 'share_voice_mail_box'
    },
    on: {
      "update-list": function ($event) {
        return _vm.load();
      }
    }
  }), _c('ShiftSoundFileModal', {
    on: {
      "update-list": function ($event) {
        return _vm.afterAdding();
      }
    }
  }), _c('UploadAudioModal', {
    attrs: {
      "title": "Upload voicemail greetings"
    },
    on: {
      "audio": function ($event) {
        return _vm.uploadAudio($event);
      }
    }
  }), _c('AudioRecordingModal', {
    on: {
      "audio": function ($event) {
        return _vm.uploadAudio($event);
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Voicemail Pin Enabled")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Pin for voicemail")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Send voicemail alerts to email")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Voicemail attachment via email")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Voicemail transcript via email")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Voicemail Greetings")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Greetings Type")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Mailbox Shared With")])])]);

}]

export { render, staticRenderFns }