var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" Zapier "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex justify-content-center mt-3"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-zapier-icon",
      "width": "350px",
      "height": "auto"
    }
  })], 1), _c('p', {
    staticClass: "crm-card-text text-center wd-90 mx-auto my-5"
  }, [_vm._v(" Zapier integration is at \"Invite Only\" and \"Testing\" stage, we are inviting you to test the integration before it's available for everyone. Before this, we need to verify your purchase key and after that we will share the Zapier invite link with you can try it! ")]), _vm._m(1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset my-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Zapier ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-input-container withoutIcon justify-content-center mx-auto d-flex wd-70 mb-0"
  }, [_c('input', {
    staticStyle: {
      "flex": "1"
    }
  }), _c('button', {
    staticClass: "crm-button ml-2"
  }, [_vm._v(" Get Integration Link ")])]);

}]

export { render, staticRenderFns }