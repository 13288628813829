<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <Calls :accountcode="data.accountcode" :user="data.user" @back="$modal.hide(modalName)" />
    </modal>
  </div>
</template>

<script>
import Calls from '../settings/Calls.vue'
export default {
  name: "CallsSettingModal",
  components: {
    Calls,
  },
  props: {
    modalName: {
      type: String,
      default: 'CallsSetting'
    },
  },
  data() {
    return {
      data: {
        accountcode: '',
        user: {},
      },
    };
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode=event?.params?.account ?? ''
      this.data.user=event?.params?.user ?? {}
    },
    onBeforeClose(){
      this.data.accountcode=''
      this.data.user={}
    },
  },
};
</script>

<style lang="scss" scoped>

</style>