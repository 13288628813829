
import { VOIP_API } from "../index"

export const LOCAL_SETTING_API_ENDPOINTS = {
  update(data={}){
    return VOIP_API.axios.voip.request({
      url: `update-local-storage`,
      method: "POST",
      data,
    })
  },
  get(params={}){
    return VOIP_API.axios.voip.request({
      url: `get-local-storage`,
      method: "GET",
      params,
    })
  },
}