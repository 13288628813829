import { LOGGER } from "@/utils/index";

export const ZOHO = {
  cti: null,
  call_backs: {
    onReady: null,
    onDialNumber: null,
  },
  init(props){
    this.call_backs.onDialNumber = props.onDialNumber
    this.call_backs.onReady = props.onReady
    this.scriptLoad()
    return this
  },
  scriptLoad(){
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = "/assets/js/integrations/zoho/ZohoEmbededAppSDK.min.js";
    head.appendChild(script);
    script.onload = this.onLoad.bind(this)
  },
  onLoad(){
    this.cti=window.ZOHO
    if(this.cti){
      this.cti.embeddedApp.on("PageLoad",this.onPageLoad.bind(this))
      this.cti.embeddedApp.on("DialerActive",this.onDialerActive.bind(this))
      this.cti.embeddedApp.on("Dial",this.onDial.bind(this))
      this.cti.embeddedApp.init()
      .then(()=>{
        this.call_backs.onReady?.()
      })
    }
  },
  onDialerActive(){
    LOGGER.log('ZOHO::onDialerActive')
  },
  onDial(message){
    LOGGER.log('ZOHO::onDial',message)
    this.call_backs.onDialNumber?.({
      number: message.Number,
      Entity : message.EntityType,
      RecordID : message.EntityID,
      extraHeader: `ZOHO: Number/${message.Number},Entity/${message.EntityType},RecordID/${message.RecordID}`,
    })
  },
  onPageLoad(){
    LOGGER.log('ZOHO::onPageLoad')
  },
  minimize(){
    this.cti.CRM.UI.Dialer.minimize()
  },
  maximize(){
    this.cti.CRM.UI.Dialer.maximize()
  },
}