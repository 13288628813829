var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('ul', {
    staticClass: "phone-list full-width-call-info-number list-unstyled pr-0 pb-0 overlowX-visible-imp"
  }, [_c('li', {
    staticClass: "p-0"
  }, [_c('CallActivityTestItem', {
    staticClass: "m-0 w-100",
    attrs: {
      "call": _vm.call,
      "conditions": {
        actions: false
      }
    },
    scopedSlots: _vm._u([{
      key: "more",
      fn: function () {
        return [_c('b-dropdown', {
          staticClass: "ico-filter",
          attrs: {
            "varient": "link",
            "no-caret": "",
            "menu-class": "w-fit-content-imp",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots",
                  "variant": "#393939",
                  "font-scale": "1.4"
                }
              })];
            },
            proxy: true
          }])
        })];
      },
      proxy: true
    }])
  })], 1)])]), _c('div', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-32px mb-32px"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-container"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.history
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.history;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-history-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("History")])], 1)]), _vm.selected.tab == _vm.tabs.history ? _vm._t("history-filter") : _vm._e()], 2), _c('ul', {
    staticClass: "allow_scroll phone-list full-width-call-info-number dialor-callHistoryList list-unstyled pl-0 pr-2",
    staticStyle: {
      "overflow-y": "unset",
      "height": "auto !important"
    }
  }, [_vm.selected.tab == _vm.tabs.history ? _vm._t("history") : _vm._e()], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }