var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    class: `AddListModal ${_vm.conditions.table_show ? 'tableIsVisible' : ''}`,
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Create List ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "id": "CloseModalButton"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)])]), _vm.conditions.table_show ? _c('div', [_c('ul', {
    staticClass: "list-unstyled mt-20px"
  }, [_c('li', [_c('b-table', {
    attrs: {
      "show-empty": "",
      "responsive": "",
      "sticky-header": "700px",
      "fields": _vm.file_data.columns,
      "items": _vm.after_filter_file_data.data,
      "busy": _vm.api.create_sheet.send
    },
    scopedSlots: _vm._u([{
      key: "empty",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-content-center align-items-center flex-column w-100 my-2"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "noDataFound-v2",
            "width": "57",
            "height": "63"
          }
        }), _c('div', {
          staticClass: "mt-10px latestGreyBox-heading-main-18px"
        }, [_vm._v(" No data found ")])], 1)];
      },
      proxy: true
    }, {
      key: "table-busy",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('div', {
            key: n.id,
            staticClass: "w-100 py-2"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "24px",
              "width": "100%"
            }
          })]);
        });
      },
      proxy: true
    }], null, false, 1492553813)
  })], 1), _c('li', [_c('div', {
    staticClass: "d-flex"
  }, [!_vm.$v.forms.add_sheet.$invalid ? _c('button', {
    staticClass: "fullWidthCloseButton w-50 mr-3",
    on: {
      "click": function ($event) {
        _vm.conditions.table_show = false;
      }
    }
  }, [_vm._v("No")]) : _vm._e(), !_vm.$v.forms.add_sheet.$invalid ? _c('button', {
    staticClass: "fullWidthDoneButton w-50 ml-3",
    on: {
      "click": function ($event) {
        return _vm.upload();
      }
    }
  }, [_vm._v("Yes")]) : _vm._e()])])])]) : _c('div', [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px"
  }, [_c('label', [_vm._v("List name")]), _c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Enter list name"
    },
    model: {
      value: _vm.forms.add_sheet.name,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_sheet, "name", $$v);
      },
      expression: "forms.add_sheet.name"
    }
  }), _vm.$v.forms.add_sheet.name.$dirty && _vm.$v.forms.add_sheet.name.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_sheet.name.required ? _c('span', [_vm._v("Name is required")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mb-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Assign to")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" " + _vm._s(_vm.forms.add_sheet.assign_to.length == 0 ? 'Select User or Team' : `${_vm.forms.add_sheet.assign_to.length} User or Team`) + " ")]), _vm.$v.forms.add_sheet.assign_to.$dirty && _vm.$v.forms.add_sheet.assign_to.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop mt-10px"
  }, [!_vm.$v.forms.add_sheet.assign_to.required ? _c('span', [_vm._v("Assign to is required")]) : _vm._e()]) : _vm._e()]), _c('button', {
    staticClass: "newButton sm-mar-left",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SelectExtensions`, {
          accountcodes: _vm.forms.add_sheet.assign_to
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Assign")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px mb-10px"
  }, [_vm._v("Upload a CSV")]), _c('input', {
    ref: `${_vm._uid}-file_upload_csv`,
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "accept": ".csv"
    },
    on: {
      "change": function ($event) {
        _vm.validateFile(_vm.getProperty($event, 'target.files[0]', null));
      }
    }
  }), _c('div', {
    staticClass: "uploadSoundFileDesign-latest py-3 position-relative mb-20px",
    on: {
      "dragenter": function ($event) {
        $event.preventDefault();
        _vm.conditions.drag_and_drop = true;
      }
    }
  }, [_vm.conditions.drag_and_drop ? _c('div', {
    staticClass: "w-100 h-100 position-absolute total-centring d-flex justify-content-center align-items-center text-center",
    on: {
      "dragenter": function ($event) {
        $event.preventDefault();
        _vm.conditions.drag_and_drop = true;
      },
      "dragover": function ($event) {
        $event.preventDefault();
        _vm.conditions.drag_and_drop = true;
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        _vm.conditions.drag_and_drop = false;
      },
      "drop": function ($event) {
        $event.preventDefault();
        _vm.conditions.drag_and_drop = false;
        _vm.validateFile(_vm.getProperty($event, 'dataTransfer.files[0]', null));
      }
    }
  }, [_c('b', [_vm._v("Drag and drop")])]) : _c('div', [_vm.forms.add_sheet.csv_file ? _c('div', {
    staticClass: "position-relative d-flex flex-column align-items-center w-100 justify-content-center"
  }, [_c('vb-icon', {
    staticClass: "mb-20px",
    attrs: {
      "icon": "CSV-powerDialer-icon",
      "height": "100px",
      "width": "100px"
    }
  }), _c('div', {
    staticClass: "uploadSoundFileDesign-text mt-10px"
  }, [_c('b', [_c('span', [_vm._v(_vm._s(_vm.forms.add_sheet.csv_file.name))])])]), _c('b-button', {
    staticClass: "topRightCornerAbsoluteButtonIcon",
    attrs: {
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.forms.add_sheet.csv_file = null;
        _vm.forms.add_sheet.name_column = '';
        _vm.forms.add_sheet.number_column = '';
        _vm.$refs[`${_vm._uid}-file_upload_csv`].value = '';
        _vm.file_data.data = {};
        _vm.file_data.columns = [];
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "variant": "danger",
      "icon": "x"
    }
  })], 1)], 1) : _c('div', [_c('div', {
    staticClass: "w-100 d-flex justify-content-center"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "PowerDialer-upload-icon",
      "width": "48.68px",
      "height": "39.645px"
    }
  })], 1), _c('div', {
    staticClass: "uploadSoundFileDesign-heading"
  }, [_vm._v(" Select a "), _c('span', {
    staticClass: "bluer",
    staticStyle: {
      "text-decoration": "none",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.$refs[`${_vm._uid}-file_upload_csv`].click();
      }
    }
  }, [_vm._v("CSV")]), _vm._v(" file to upload ")]), _c('div', {
    staticClass: "uploadSoundFileDesign-text mt-10px"
  }, [_c('b', [_vm._v("or drag and drop it here")])])])])]), _vm.$v.forms.add_sheet.csv_file.$dirty && _vm.$v.forms.add_sheet.csv_file.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_sheet.csv_file.required ? _c('span', [_vm._v("File is required")]) : _vm._e()]) : _vm._e(), _vm.forms.add_sheet.csv_file ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-20px"
  }, [_c('label', [_vm._v("Number column")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.file_data.columns
    },
    model: {
      value: _vm.forms.add_sheet.number_column,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_sheet, "number_column", $$v);
      },
      expression: "forms.add_sheet.number_column"
    }
  }), _vm.$v.forms.add_sheet.number_column.$dirty && _vm.$v.forms.add_sheet.number_column.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_sheet.number_column.required ? _c('span', [_vm._v("Number column is required")]) : _vm._e()]) : _vm._e()], 1) : _vm._e(), _vm.forms.add_sheet.csv_file ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("Name column")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.file_data.columns
    },
    model: {
      value: _vm.forms.add_sheet.name_column,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_sheet, "name_column", $$v);
      },
      expression: "forms.add_sheet.name_column"
    }
  }), _vm.$v.forms.add_sheet.name_column.$dirty && _vm.$v.forms.add_sheet.name_column.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_sheet.name_column.required ? _c('span', [_vm._v("Name column is required")]) : _vm._e()]) : _vm._e()], 1) : _vm._e()]), _c('button', {
    staticClass: "fullWidthDoneButton mt-20px",
    on: {
      "click": function ($event) {
        _vm.$v.forms.add_sheet.$touch();
        _vm.validatePhoneNumber();
      }
    }
  }, [[_vm._v("Next")]], 2)])]), _c('SelectExtensionsModal', {
    attrs: {
      "modalName": `${_vm._uid}-SelectExtensions`,
      "extensionTypes": ['USER', 'TEAM'],
      "allowMultiSelect": true
    },
    on: {
      "update": function ($event) {
        _vm.forms.add_sheet.assign_to = $event.selected.accountcodes;
      }
    }
  }), _c('modal', {
    staticClass: "AddListModal-InvalidNumbers-modal tableIsVisible",
    attrs: {
      "name": `${_vm._uid}-InvalidNumbers`
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 d-block mb-20px"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Removed items")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-InvalidNumbers`);
      }
    }
  }) : _c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-InvalidNumbers`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)], 1)]), _c('div', [_vm._v(" Quick view of rows removed from the uploaded CSV file because they contain invalid phone numbers. ")])]), _c('b-table', {
    attrs: {
      "responsive": "",
      "sticky-header": "700px",
      "items": _vm.after_filter_file_data.removed
    }
  })], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }