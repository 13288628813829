var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestSMS-conversation-row",
    class: `${_vm.received ? 'received' : 'sent'}`
  }, [_c('div', {
    staticClass: "latestSMS-conversation-column"
  }, [_c('div', {
    staticClass: "latestSMS-conversation-infoPart"
  }, [_vm.received ? _c('div', {
    staticClass: "infoPart-number"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.message.to_number)))]) : _c('div', {
    staticClass: "infoPart-number"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.message.from_number)))]), _c('div', {
    staticClass: "infoPart-time"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.message.date, false, {
    formate: 'hh:mm a'
  })))])]), _c('div', {
    staticClass: "latestSMS-conversation-messagePart"
  }, [_vm._v(_vm._s(_vm.message.message))])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }