var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation center-zoomIn-popup mediaFullscreen-modal",
    attrs: {
      "name": _vm.modalName,
      "height": "100%",
      "width": "100%"
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "mediaFullscreen-container"
  }, [_c('div', {
    staticClass: "mediaFullscreen-header"
  }, [_c('div', {
    staticClass: "close-popup cursor_pointer ml-2",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "font-scale": "2"
    }
  })], 1)]), _c('div', {
    staticClass: "mediaFullscreen-body"
  }, [_vm.navigation.prev ? _c('div', {
    staticClass: "pre-btn",
    on: {
      "click": function ($event) {
        return _vm.navigate('prev');
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-left",
      "font-scale": "4"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "media-itself",
    style: `background-image:url(${_vm.selectedImage})`
  }), _vm.navigation.next ? _c('div', {
    staticClass: "next-btn",
    on: {
      "click": function ($event) {
        return _vm.navigate('next');
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-right",
      "font-scale": "4"
    }
  })], 1) : _vm._e()]), _c('div', {
    staticClass: "mediaFullscreen-footer"
  }, [_c('div', {
    staticClass: "mediaFullscreen-slider"
  }, _vm._l(_vm.data.images, function (image, index) {
    return _c('div', {
      key: index,
      staticClass: "media-container",
      class: {
        selected: _vm.selected.index == index
      }
    }, [_c('div', {
      staticClass: "media-itself",
      style: `background-image:url(${image})`,
      on: {
        "click": function ($event) {
          return _vm.navigate('', index);
        }
      }
    })]);
  }), 0)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }