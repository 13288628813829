var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [false ? _c('div', {
    class: `chatMe ${_vm.message.user_id == _vm.userId ? 'right' : 'left'} `
  }, [_c('div', {
    staticClass: "chatMe-inner"
  }, [_c('div', {
    staticClass: "msgInfo-container"
  }, [_c('div', {
    staticClass: "sm-text"
  }, [_vm._v(_vm._s(_vm.message.user.first_name) + " " + _vm._s(_vm.message.user.last_name))])]), _c('div', {
    staticClass: "msg-container"
  }, [_c('div', {
    staticClass: "msg-itself"
  }, [_vm._v(_vm._s(_vm.message.details))])]), _c('div', {
    staticClass: "msgInfo-container"
  }, [_c('div', {
    staticClass: "sm-text"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.message.created_at, '', {
    formate: 'hh:mm a'
  })))])])])]) : _vm._e(), _c('div', {
    class: `ticketConversation-item ${_vm.message.user_id == _vm.userId ? 'sender' : 'receiver'}`
  }, [_c('div', {
    staticClass: "ticketConversation-item-top"
  }, [_c('div', {
    staticClass: "ticketConversation-item-top-left"
  }, [_vm._v(" " + _vm._s(_vm.message.user.first_name) + " " + _vm._s(_vm.message.user.last_name) + " ")]), _c('div', {
    staticClass: "ticketConversation-item-top-right"
  }, [_c('b-icon', {
    attrs: {
      "icon": "clock"
    }
  }), _vm._v(" At " + _vm._s(_vm._f("filter_date_current")(_vm.message.created_at, '', {
    formate: 'hh:mm a , DD MMM YYYY'
  })) + " ")], 1)]), _c('div', {
    staticClass: "ticketConversation-item-bottom"
  }, [_c('p', {
    staticClass: "mb-0"
  }, [_vm._v(" " + _vm._s(_vm.message.details) + " ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }