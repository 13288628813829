<template>
  <div class="top-main-hrm">
    <section>
      <div class="bg-hrm-mike scrollow1">
        <div class="d-flex justify-content-between align-items-start">
          <div class="w-75 for-home-dd-width d-flex flex-wrap">     
            
            <div class="home-dd-container">
              <b-dropdown no-caret class="home-mainBorderfordropi">
                <template #button-content>
                  <div class="home-button-content-dropi">
                    <div class="home-dropi-contenti">All teams</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-form-checkbox id="checkbox-1" name="checkbox-1" class="new-checkcheck">
                  <span class="home-checkbox-space">Voip (4)</span>
                </b-form-checkbox>
                <b-form-checkbox id="checkbox-2" name="checkbox-2" class="new-checkcheck">
                  <span class="home-checkbox-space">Wanio (10)</span>
                </b-form-checkbox>
                <b-form-checkbox id="checkbox-3" name="checkbox-3" class="new-checkcheck">
                  <span class="home-checkbox-space">Kuflink (16)</span>
                </b-form-checkbox>
              </b-dropdown>
            </div>
           
            <div class="home-dd-container">
              <b-dropdown no-caret class="home-mainBorderfordropi">
                <template #button-content>
                  <div class="home-button-content-dropi">
                    <div class="home-dropi-contenti">All locations</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="drop-dropdown-toggle">Amsterdam (4)</b-dropdown-item>
                <b-dropdown-item class="drop-dropdown-toggle">London (4)</b-dropdown-item>
                <b-dropdown-item class="drop-dropdown-toggle">New York (1)</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">No location (1)</b-dropdown-item>
              </b-dropdown>
            </div>
           
            <div class="home-dd-container">
              <b-dropdown no-caret class="home-mainBorderfordropi">
                <template #button-content>
                  <div class="home-button-content-dropi">
                    <div class="home-dropi-contenti">All employment statuses</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="drop-dropdown-toggle">Second Item</b-dropdown-item>
                <b-dropdown-item class="drop-dropdown-toggle">Second Item </b-dropdown-item>
              </b-dropdown>
            </div>
           
            <div class="home-dd-container">
              <b-dropdown no-caret class="home-mainBorderfordropi">
                <template #button-content>
                  <div class="home-button-content-dropi">
                    <div class="home-dropi-contenti">Everyone</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="drop-dropdown-toggle">Second Item</b-dropdown-item>
                <b-dropdown-item class="drop-dropdown-toggle">Second Item </b-dropdown-item>
              </b-dropdown>
            </div>
          </div> 
          <div class="w-25 top-btn-align">
            <button class="bt btn-primarymike mt-2 mr-top-sidebsvg">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.551" height="16.551" viewBox="0 0 16.551 16.551" class="primarymike-svg">
                  <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z" transform="translate(-8.465 -8.465)" fill="#fff" stroke="#fff" stroke-width="1"/>
              </svg>
              Add
            </button>
            <button class="btn-svg-none">
              <svg id="Group_16231" data-name="Group 16231" xmlns="http://www.w3.org/2000/svg" class ="mr-top-sidebsvg" width="12.033" height="15.016" viewBox="0 0 12.033 15.016">
                  <path id="Icon_ionic-md-arrow-dropright" data-name="Icon ionic-md-arrow-dropright" d="M0,0,6.016,6.016,0,12.033Z" transform="translate(12.033 9) rotate(90)" fill="#5576d1"/>
                  <path id="Icon_ionic-md-arrow-dropright-2" data-name="Icon ionic-md-arrow-dropright" d="M0,0,6.016,6.016,0,12.033Z" transform="translate(0 6.016) rotate(-90)" fill="#5576d1"/>
              </svg>
            </button>
            <button class="btn-svg-none">
              <svg id="Icon_ionic-ios-menu" data-name="Icon ionic-ios-menu" xmlns="http://www.w3.org/2000/svg" class ="mr-top-sidebsvg" width="21.607" height="15.75" viewBox="0 0 21.607 15.75">
                  <path id="Path_16" data-name="Path 16" d="M25.207,12.375H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,12.375Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
                  <path id="Path_17" data-name="Path 17" d="M25.207,19.125H5.4A1.035,1.035,0,0,1,4.5,18h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,19.125Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
                  <path id="Path_18" data-name="Path 18" d="M25.207,25.875H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,25.875Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
              </svg>
            </button>
          </div>
            
        
        </div>
        <div class="card-container-main-box mt-5">
          <div class="card-contain" v-for="n in 11" :key="n">
            <div class="imgify-home-box">
                <img src="../../assets/images/credit-card/hrmprofiletest.png" class="imgify-heiwid">
            </div>
            <div class="decription-img-box-home">
                {{n}}
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
</template>

<script>
// import axios from 'axios'
export default {
  name: 'Home',
  computed:{
    // activeTab(){ return this.$store.state.global_conditions.tab },
  },
  data() {
    return {
      info: []
    }
  },
  mounted () {
    // axios
      // .get('https://api.coindesk.com/v1/bpi/currentprice.json')
      // .then(response => (this.info = response.data))
  }
}
</script>

<style>

</style>