var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup GenerateTimeOffReportsModal HrmForModalScroll",
    attrs: {
      "clickToClose": true,
      "scrollable": true,
      "name": "ExportOrgChartModal"
    }
  }, [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Paul Jackson")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('ExportOrgChartModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Direct manager")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "nameWithImage insideDropdown"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          staticClass: "circle",
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Mellissa")])]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(6, function (n) {
    return _c('b-dropdown-item', {
      key: n
    }, [_c('div', {
      staticClass: "nameWithImage insideDropdown"
    }, [_c('div', {
      staticClass: "imageContainer"
    }, [_c('img', {
      staticClass: "circle",
      attrs: {
        "src": require('@/assets/images/credit-card/1.jpeg')
      }
    })]), _c('div', {
      staticClass: "nameWithImage-text"
    }, [_vm._v("Mellissa")])])]);
  }), 1)], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Save ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }