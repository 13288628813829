var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup global-modal-policy-hrm Scrollable-hrm",
    attrs: {
      "name": "CreateNewPolicyTimeoffModal",
      "clickToClose": true,
      "scrollable": true
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-hrm-modal-header mar-top-hrm20"
  }, [_c('h2', {
    staticClass: "dialer-modal-hrm-title"
  }, [_vm._v("Add new task")]), _c('div', [_c('button', {
    staticClass: "dialer-hrm-button-modal",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('CreateNewPolicyTimeoffModal');
      }
    }
  }, [_vm._v("Close")])])]), _c('div', {
    staticClass: "bg-modal-doc-grey-share-with sp-gm-if mar-top-hrm30"
  }, [_c('h5', {
    staticClass: "policy-sm"
  }, [_vm._v("Add new policy")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "sp-gm-if w-50 mr-2"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Policy name")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "list": "input-list",
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if w-50 ml-2 policy-gm-dd-container"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Pick a color")]), _c('b-dropdown', {
    staticClass: "policy-gm-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "policy-gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "policy-gm-dropi-contenti"
        }, [_vm._v("Pick a color")]), _c('div', {
          staticClass: "pickacolor"
        }, [_c('b-icon', {
          staticClass: "dropi-icon uniquesvgcolorpicker",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v("Remd")]), _c('b-dropdown-item', [_vm._v("Nemla")]), _c('b-dropdown-item', [_vm._v("Yemlow")])], 1)], 1)]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Time off policy description")]), _c('b-form-textarea', {
    staticClass: "gm-hrm-textnote",
    attrs: {
      "id": "textarea-small",
      "size": "sm",
      "placeholder": "Small textarea"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center policy-gm-dd-container"
  }, [_c('h5', {
    staticClass: "onboard-inline-text mb-imp mr-3"
  }, [_vm._v("Allocate")]), _c('b-form-input', {
    staticClass: "gm-hrm-sm-fields mr-3",
    attrs: {
      "list": "input-list",
      "placeholder": ""
    }
  }), _c('b-dropdown', {
    staticClass: "policy-gm-mainBorderfordropi width-unique-one",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "policy-gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "policy-gm-dropi-contenti"
        }, [_vm._v("days")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Weekdays")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Off days")])], 1)], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "sp-gm-if w-50 mr-2"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Accure")]), _c('div', {
    staticClass: "policy-gm-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "policy-gm-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "policy-gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "policy-gm-dropi-contenti"
        }, [_vm._v("Mentor")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1)]), _c('div', {
    staticClass: "sp-gm-if w-50 ml-2"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Duration")]), _c('div', {
    staticClass: "policy-gm-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "policy-gm-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "policy-gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "policy-gm-dropi-contenti"
        }, [_vm._v("Mentor")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1)])])]), _c('div', {
    staticClass: "bg-modal-doc-grey-share-with sp-gm-if"
  }, [_c('div', {
    staticClass: "bg-modal-setting-policy"
  }, [_c('h5', {
    staticClass: "policy-modal-semi-head"
  }, [_vm._v(" What should we consider to determine working days and daily working hours for this policy?\\ ")]), _c('p', {
    staticClass: "policy-modal-text"
  }, [_vm._v(" This will determine days that are considered working days and thus, count against allowance, as well as daily working hours, this value is used to convert days into hours for part day requests. ")]), _c('b-form-group', {
    staticClass: "gm-hrm-custom-radio"
  }, [_c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "A"
    }
  }, [_vm._v("Everyone")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "B"
    }
  }, [_vm._v("Sasha Banks")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "C"
    }
  }, [_vm._v("Jenifer")])], 1), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('h5', {
    staticClass: "onboard-inline-text mb-imp mr-3"
  }, [_vm._v("Default working hours")]), _c('b-form-input', {
    staticClass: "gm-hrm-sm-fields",
    attrs: {
      "list": "input-list",
      "placeholder": "20"
    }
  })], 1), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Count weekends as workdays")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Count public holidays as workdays")])])], 1)]), _c('div', {
    staticClass: "bg-modal-doc-grey-share-with sp-gm-if"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('h5', {
    staticClass: "onboard-inline-text mb-imp mr-3"
  }, [_vm._v("Default working hours")]), _c('div', {
    staticClass: "policy-gm-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "policy-gm-mainBorderfordropi width-unique-two",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "policy-gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "policy-gm-dropi-contenti"
        }, [_vm._v("days not carry over")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1), _c('h5', {
    staticClass: "onboard-inline-text mb-imp ml-3"
  }, [_vm._v("(this change will be applied only on the next yearly reset)")])]), _c('div', {
    staticClass: "mar-top-hrm25"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Do not accure when taking leave from one of these policies ")]), _c('div', {
    staticClass: "policy-gm-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "policy-gm-mainBorderfordropi width-unique-three",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "policy-gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "policy-gm-dropi-contenti"
        }, [_vm._v("Category")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1)])]), _c('div', [_c('div', {
    staticClass: "mar-top-hrm25"
  }, [_c('div', {
    staticClass: "hrm-bg-accordian flex-column justify-content-start align-item-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Holiday-UK")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.Sickday-1",
      modifiers: {
        "Sickday-1": true
      }
    }],
    staticClass: "togg-btn-report",
    attrs: {
      "variant": "primary"
    }
  }, [_c('svg', {
    staticClass: "svgtoggle",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.46",
      "height": "9.926",
      "viewBox": "0 0 17.46 9.926"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15893",
      "data-name": "Group 15893",
      "transform": "translate(-54.046 -5.227)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15891",
      "data-name": "Group 15891",
      "transform": "translate(55.46 6.64)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_41822",
      "data-name": "Path 41822",
      "d": "M13996.459,9709.063l7.113,6.908",
      "transform": "translate(-13996.459 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_41823",
      "data-name": "Path 41823",
      "d": "M14003.77,9709.063l-7.311,7.1",
      "transform": "translate(-13989.138 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])])])])], 1), _c('b-collapse', {
    staticClass: "w-100",
    attrs: {
      "id": "Sickday-1"
    }
  }, [_c('b-card', {
    staticClass: "report-cards"
  }, [_c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Working hours per day: 8.0")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Annually allowed: 20 days")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Currently available: 8.87 days")])]), _c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Holiday")]), _c('vb-table', {
    staticClass: "reports-table-width",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth emp-reports-firsttd col-left"
  }, [_vm._v("created")]), _c('h5', {
    staticClass: "commonth emp-reports-secondtd"
  }, [_vm._v("created by")]), _c('h5', {
    staticClass: "commonth emp-reports-thirdtd"
  }, [_vm._v("time off requested")]), _c('h5', {
    staticClass: "commonth emp-reports-fourtd"
  }, [_vm._v("note")]), _c('h5', {
    staticClass: "commonth emp-reports-fivetd"
  }, [_vm._v("action")]), _c('h5', {
    staticClass: "commonth emp-reports-sixtd"
  }, [_vm._v("days")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "emp-reports-firsttd"
  }, [_vm._v(" 02/03/2022 - \"Current\" ")]), _c('td', {
    staticClass: "emp-reports-secondtd"
  }, [_vm._v(" System ")]), _c('td', {
    staticClass: "emp-reports-thirdtd"
  }, [_vm._v(" N/A ")]), _c('td', {
    staticClass: "emp-reports-fourtd"
  }, [_vm._v(" Yearly accural ")]), _c('td', {
    staticClass: "emp-reports-fivetd"
  }, [_vm._v(" increase ")]), _c('td', {
    staticClass: "emp-reports-sixtd"
  }, [_vm._v(" 8.87 ")])])])], 2)], 1)], 1)]), _c('div', {
    staticClass: "mar-top-hrm25"
  }, [_c('div', {
    staticClass: "hrm-bg-accordian flex-column justify-content-start align-item-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Holiday-UK")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.holiduk-1",
      modifiers: {
        "holiduk-1": true
      }
    }],
    staticClass: "togg-btn-report",
    attrs: {
      "variant": "primary"
    }
  }, [_c('svg', {
    staticClass: "svgtoggle",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.46",
      "height": "9.926",
      "viewBox": "0 0 17.46 9.926"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15893",
      "data-name": "Group 15893",
      "transform": "translate(-54.046 -5.227)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15891",
      "data-name": "Group 15891",
      "transform": "translate(55.46 6.64)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_41822",
      "data-name": "Path 41822",
      "d": "M13996.459,9709.063l7.113,6.908",
      "transform": "translate(-13996.459 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_41823",
      "data-name": "Path 41823",
      "d": "M14003.77,9709.063l-7.311,7.1",
      "transform": "translate(-13989.138 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])])])])], 1), _c('b-collapse', {
    staticClass: "w-100",
    attrs: {
      "id": "holiduk-1"
    }
  }, [_c('b-card', {
    staticClass: "report-cards"
  }, [_c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Working hours per day: 8.0")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Annually allowed: 20 days")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Currently available: 8.87 days")])]), _c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Holiday")]), _c('vb-table', {
    staticClass: "reports-table-width",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth emp-reports-firsttd col-left"
  }, [_vm._v("created")]), _c('h5', {
    staticClass: "commonth emp-reports-secondtd"
  }, [_vm._v("created by")]), _c('h5', {
    staticClass: "commonth emp-reports-thirdtd"
  }, [_vm._v("time off requested")]), _c('h5', {
    staticClass: "commonth emp-reports-fourtd"
  }, [_vm._v("note")]), _c('h5', {
    staticClass: "commonth emp-reports-fivetd"
  }, [_vm._v("action")]), _c('h5', {
    staticClass: "commonth emp-reports-sixtd"
  }, [_vm._v("days")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "emp-reports-firsttd"
  }, [_vm._v(" 02/03/2022 - \"Current\" ")]), _c('td', {
    staticClass: "emp-reports-secondtd"
  }, [_vm._v(" System ")]), _c('td', {
    staticClass: "emp-reports-thirdtd"
  }, [_vm._v(" N/A ")]), _c('td', {
    staticClass: "emp-reports-fourtd"
  }, [_vm._v(" Yearly accural ")]), _c('td', {
    staticClass: "emp-reports-fivetd"
  }, [_vm._v(" increase ")]), _c('td', {
    staticClass: "emp-reports-sixtd"
  }, [_vm._v(" 8.87 ")])])])], 2)], 1)], 1)]), _c('div', {
    staticClass: "mar-top-hrm25"
  }, [_c('div', {
    staticClass: "hrm-bg-accordian flex-column justify-content-start align-item-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Business trip")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.btrip-1",
      modifiers: {
        "btrip-1": true
      }
    }],
    staticClass: "togg-btn-report",
    attrs: {
      "variant": "primary"
    }
  }, [_c('svg', {
    staticClass: "svgtoggle",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.46",
      "height": "9.926",
      "viewBox": "0 0 17.46 9.926"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15893",
      "data-name": "Group 15893",
      "transform": "translate(-54.046 -5.227)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15891",
      "data-name": "Group 15891",
      "transform": "translate(55.46 6.64)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_41822",
      "data-name": "Path 41822",
      "d": "M13996.459,9709.063l7.113,6.908",
      "transform": "translate(-13996.459 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_41823",
      "data-name": "Path 41823",
      "d": "M14003.77,9709.063l-7.311,7.1",
      "transform": "translate(-13989.138 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])])])])], 1), _c('b-collapse', {
    staticClass: "w-100",
    attrs: {
      "id": "btrip-1"
    }
  }, [_c('b-card', {
    staticClass: "report-cards"
  }, [_c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Working hours per day: 8.0")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Annually allowed: 20 days")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Currently available: 8.87 days")])]), _c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Holiday")]), _c('vb-table', {
    staticClass: "reports-table-width",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth emp-reports-firsttd col-left"
  }, [_vm._v("created")]), _c('h5', {
    staticClass: "commonth emp-reports-secondtd"
  }, [_vm._v("created by")]), _c('h5', {
    staticClass: "commonth emp-reports-thirdtd"
  }, [_vm._v("time off requested")]), _c('h5', {
    staticClass: "commonth emp-reports-fourtd"
  }, [_vm._v("note")]), _c('h5', {
    staticClass: "commonth emp-reports-fivetd"
  }, [_vm._v("action")]), _c('h5', {
    staticClass: "commonth emp-reports-sixtd"
  }, [_vm._v("days")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "emp-reports-firsttd"
  }, [_vm._v(" 02/03/2022 - \"Current\" ")]), _c('td', {
    staticClass: "emp-reports-secondtd"
  }, [_vm._v(" System ")]), _c('td', {
    staticClass: "emp-reports-thirdtd"
  }, [_vm._v(" N/A ")]), _c('td', {
    staticClass: "emp-reports-fourtd"
  }, [_vm._v(" Yearly accural ")]), _c('td', {
    staticClass: "emp-reports-fivetd"
  }, [_vm._v(" increase ")]), _c('td', {
    staticClass: "emp-reports-sixtd"
  }, [_vm._v(" 8.87 ")])])])], 2)], 1)], 1)])]), _c('button', {
    staticClass: "btn-primarynewupdated1 mt-4 mb-4"
  }, [_vm._v("Create")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }