<template>
  <div style="display: flex;">
    <List 
      ref="list"
      :groups="response.groups"
      @select-group-id="selected.group_id=$event"
    />
    <Board 
      ref="board"
      :groupId="selected.group_id"
      :breadcrumb="breadcrumb"
      @select-group-id="selected.group_id=$event"
    />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import { GET_PAMARLY_ORGANIZATION_ID } from '@/store/helper/getters'
import List from './List.vue'
import Board from './Board.vue'
export default {
  name: 'PamarlyFolder',
  components: {
    List,
    Board,
  },
  data(){
    return {
      api: {
        fetch_all_groups: this.$helperFunction.apiInstance(),
      },
      response: {
        groups: [],
      },
      selected: {
        group_id: '',
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      GET_PAMARLY_ORGANIZATION_ID
    ]),
    breadcrumb(){
      return this.allBreadcrumbs[this.selected.group_id] ?? []
    },
    allBreadcrumbs(){
      let list = {};
      function traverse(node, path) {
        if (!path)path = [];
        if (node) {
          path.push({
            name: node.resource_type,
            id: node.id,
          })
        }
        list[node.id] = path
        if (node.children) {
          node.children.forEach((item) => {
            traverse(item, path.slice());
          });
        }
      }
      this.response?.groups?.forEach?.((item) => {
        traverse(item, [
          {
            name: 'Home',
            id: '',
          }
        ]);
      });
      return list
    },
  },
  methods: {
    fetchGroups(){
      // TODO - fetch all groups
      let vm = this 
      vm.api.fetch_all_groups.send=true
      VOIP_API.endpoints.pamarly.resources.allGroupsTree({
        organizationId: vm.GET_PAMARLY_ORGANIZATION_ID
      })
      .then(({ data: { result: groups } })=>{
        vm.response.groups=groups
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.fetch_all_groups.send=false
      })
    },
  },
  mounted(){
    this.fetchGroups()
  },
}
</script>

<style>

</style>