<template>
<div>
  <div class="mainHeading withButton">
    Annual individual allowances
    <button @click="$modal.show('EditIndividualAllowanceModal')" class="dialer-button dialer-button-primary mr-0 withIcon">
      <vb-icon icon="pencil-hrmIcon" class="mr-16px fillWhite" height="14px" width="14px" />
      Edit
    </button>
  </div>
  <div class="mainDescription mb-4">
    Review the amount of leave allocated to individual employees.
  </div>
  <div class="IndividualAllowancesTable mt-32px">
    
    
    <div class="HrmTableHead-container">
      <div class="HrmTableHead one">Employee</div>
      <div class="HrmTableHead two">Holiday</div>
      <div class="HrmTableHead three">Sickday</div>
      <div class="HrmTableHead four">Holiday-UK</div>
      <div class="HrmTableHead five">Holiday-USA</div>
      <div class="HrmTableHead six">Holiday-pAK</div>
      <div class="HrmTableHead seven">Business trip</div>
    </div>
    <vb-table class="HrmTable" >
      
      <template #body="{ start, end }">
        <tr class="dialer-row-select" v-for="(data, index) in filterAllowances" v-show="index >= start && index <= end" :key="data.user">
          <td class="dialer-row-title one">
            <div class="nameWithImage">
              <div class="imageContainer">
                <img :src="require('@/assets/images/credit-card/1.jpeg')" />
              </div>
              <div class="nameWithImage-text">{{ data.name }}</div>
            </div>
          </td>
          <td class="dialer-row-title two">{{ data.holiday }}</td>
          <td class="dialer-row-title three">{{ data.sickday }}</td>
          <td class="dialer-row-title four">{{ data.holidayUk }}</td>
          <td class="dialer-row-title five">{{ data.holidayUsa }}</td>
          <td class="dialer-row-title six">{{ data.holidayPak }}</td>
          <td class="dialer-row-title seven">{{ data.businessTrip }}</td>
        </tr>
      </template>
    </vb-table>
  </div>
  <EditIndividualAllowanceModal />
</div>
</template>

<script>
import EditIndividualAllowanceModal from '../../../Modals/HRM/reports/EditIndividualAllowanceModal.vue'
import { $fn } from "@/utils";
import { HRM } from '@/data'
export default {
  name:'IndividualAllowances',
  components:{
    EditIndividualAllowanceModal,
  },
  inject:['isEmpty'],
  data(){
    return {
      response: {
        allowances: [],
      },
      filter: {
        allowances: {
          search: '',
          direction: '',
        }
      },
      api: {
        allowances: this.$helperFunction.apiInstance({ error_message: true }),
      },
    }
  },
  computed:{
    filterAllowances(){
      const search = this.filter.allowances.search.trim().toLowerCase()
      // const direction = this.filter.rules.direction
      const rules = this.response.allowances
      const filter_allowances = rules.filter(item=>
        `${(item.name || '').replace(/-/g,'')}`.trim().toLowerCase().includes(search)
      )
      return filter_allowances
    }
  },
  methods: {
    fetchAllowances(){
      let vm = this;
      if(vm.api.allowances.send) return;
      vm.api.allowances.send=true
      $fn.sleep(2 * 1000)
      .then(()=>{
        // console.log("HRM Data", HRM.allowances)
        vm.response.allowances = HRM.allowances
      })
      .catch((/* err */)=>{
        // console.log("error", err)
      }).finally(()=> { vm.api.allowances.send = false })
    }
  },
  mounted(){
    this.fetchAllowances()
  }
}
</script>

<style>

</style>