var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "signUpPage-leftSide withSlider"
  }, [_c('div', {
    staticClass: "whiteLogo-contaienr-updated"
  }, [_c('img', {
    attrs: {
      "src": require('../assets/images/latestLogo.png')
    }
  })]), _c('b-carousel', {
    attrs: {
      "interval": _vm.slider.interval,
      "indicators": "",
      "img-width": "50%",
      "img-height": "100%",
      "no-animation": ""
    },
    on: {
      "sliding-start": function ($event) {
        _vm.slider.animated = true;
      },
      "sliding-end": function ($event) {
        _vm.slider.animated = false;
      }
    },
    model: {
      value: _vm.slider.current,
      callback: function ($$v) {
        _vm.$set(_vm.slider, "current", $$v);
      },
      expression: "slider.current"
    }
  }, _vm._l(_vm.slider.list, function (slide, index) {
    return _c('b-carousel-slide', {
      key: index,
      attrs: {
        "img-src": slide.bg_img
      }
    }, [_c('img', {
      attrs: {
        "src": slide.img
      }
    }), _c('div', {
      staticClass: "whiteHeading"
    }, [_vm._v(_vm._s(slide.title))]), _c('div', {
      staticClass: "whiteText"
    }, [_vm._v(_vm._s(slide.desc))])]);
  }), 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }