import { chunk1_icons } from "./chunk1";
import { chunk10_icons } from "./chunk10";
import { chunk11_icons } from "./chunk11";
import { chunk12_icons } from "./chunk12";
import { chunk13_icons } from "./chunk13";
import { chunk2_icons } from "./chunk2";
import { chunk3_icons } from "./chunk3";
import { chunk4_icons } from "./chunk4";
import { chunk5_icons } from "./chunk5";
import { chunk6_icons } from "./chunk6";
import { chunk7_icons } from "./chunk7";
import { chunk8_icons } from "./chunk8";
import { chunk9_icons } from "./chunk9";

export const icons = {
  ...chunk1_icons,
  ...chunk2_icons,
  ...chunk3_icons,
  ...chunk4_icons,
  ...chunk5_icons,
  ...chunk6_icons,
  ...chunk7_icons,
  ...chunk8_icons,
  ...chunk9_icons,
  ...chunk10_icons,
  ...chunk11_icons,
  ...chunk12_icons,
  ...chunk13_icons,
  "": {
    name: "",
    viewBox: "0 0 0 0",
    fill: "",
    "stroke-width": "",
    "stroke-linecap": "",
    "stroke-linejoin": "",
    stroke: "",
    icon: `

    `,
  },
}