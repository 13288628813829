<template>
  <modal :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose" height="100%" width="100%" class="dialer_animation center-zoomIn-popup mediaFullscreen-modal">
    <div class="mediaFullscreen-container">
      <div class="mediaFullscreen-header">
        <div style="color:white;width:calc(100% - 45px);text-align:center">{{user | get_property('user.display_name')}}</div>
        <div v-if="!is_action" @click="downloadFile()">
          <vb-icon class="whiteDownloadIcon" height="15px" width="15px" icon="callAnalytics-reporting-download-icon" />
        </div>
        <div class="close-popup cursor_pointer ml-2" @click="$modal.hide(modalName)">
          <b-icon icon="x" font-scale="2" />
        </div>
      </div>
      <div class="mediaFullscreen-body">
        <template v-if="is_action">
          <div v-if="actionFileNavigation.previous_id" @click="file_id=actionFileNavigation.previous_id" class="pre-btn">
            <b-icon icon="chevron-left" font-scale="4" />
          </div>
          <div 
            v-if="getProperty(selectedActionFile,'file_type') === 'image'" 
            :style="`background-image:url(${getProperty(selectedActionFile,'src')})`" 
            class="media-itself"
          ></div>
          <div v-else class="media-itself">
            <video width="320" height="240" controls>
              <source :src="getProperty(selectedActionFile,'src')">
            </video>
          </div>
          <div v-if="actionFileNavigation.next_id" @click="file_id=actionFileNavigation.next_id" class="next-btn">
            <b-icon icon="chevron-right" font-scale="4" />
          </div>
        </template>
        <template v-else>
          <div v-if="fileNavigation.previous_id" @click="file_id=fileNavigation.previous_id" class="pre-btn">
            <b-icon icon="chevron-left" font-scale="4" />
          </div>
          <div 
            v-if="getProperty(selectedFile,'extra_data.file_type') === 'image'" 
            :style="`background-image:url(${getProperty(selectedFile,'extra_data.src')})`" 
            class="media-itself"
          ></div>
          <div v-else class="media-itself">
            <video width="320" height="240" controls>
              <source :src="getProperty(selectedFile,'extra_data.src')">
            </video>
          </div>
          <div v-if="fileNavigation.next_id" @click="file_id=fileNavigation.next_id" class="next-btn">
            <b-icon icon="chevron-right" font-scale="4" />
          </div>
        </template>
      </div>
      <div class="mediaFullscreen-footer">
        <div class="mediaFullscreen-slider">
          <template v-if="is_action">
            <div v-for="file in filteredActionFiles" :key="file.uid" class="media-container" :class="{selected:file_id==file.uid}">
              <div class="media-itself" v-if="getProperty(file,'file_type')=='video'" @click="file_id=file.uid">
                <VueVideoThumbnail
                  :video-src="getProperty(file,'src')"
                  :snapshot-at-duration-percent="parseInt(0, 10)"
                  :width="500"
                  :height="300"
                  :chunks-quantity="4"
                />
              </div>
              <div v-else :style="`background-image:url(${getProperty(file,'src')})`" @click="file_id=file.uid" class="media-itself"></div>
            </div>
          </template>
          <template v-else>
            <div v-for="file in filteredFiles" :key="file.id" class="media-container" :class="{selected:file_id==file.id}">
              <div class="media-itself" v-if="getProperty(file,'extra_data.file_type')=='video'" @click="file_id=file.id">
                <VueVideoThumbnail
                  :video-src="getProperty(file,'extra_data.src')"
                  :snapshot-at-duration-percent="parseInt(0, 10)"
                  :width="500"
                  :height="300"
                  :chunks-quantity="4"
                />
              </div>
              <div v-else :style="`background-image:url(${getProperty(file,'extra_data.src')})`" @click="file_id=file.id" class="media-itself"></div>
            </div>
          </template>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { LOGGER, URLS } from '../../../utils';
import { mapGetters } from 'vuex';
import { TokenService } from "../../../services/storage.service";
import VueVideoThumbnail from 'vue-video-thumbnail'
export default {
  name: "MediaFilesModal",
  components:{
    VueVideoThumbnail
  },
  inject: ['getProperty'],
  props: {
    modalName: {
      type: String,
      default: 'MediaFilesModal'
    },
  },
  data() {
    return {
      channel_id: '',
      file_id: '',
      image_url: require('@/assets/images/credit-card/2.jpeg'),
      is_action: false
    };
  },
  computed: {
    ...mapGetters({
      getVoipUsers: 'getVoipUsers'
    }),
    channelData() {
      return this.$store.state.mattermost.channels[this.channel_id];
    },
    actionFiles(){
      const files = Object.values(this.channelData?.actions?.files ?? {})
      return files
    },
    filteredActionFiles(){
      return this.actionFiles?.filter?.(file=> ["image","video"].includes(file?.file_type) && (file?.file_type=='image' ? ["jpg", "png", "jpeg"].includes(file?.ext) : true));
    },
    actionFileNavigation(){
      const selected_index = this.filteredActionFiles.findIndex(file=>file.uid==this.file_id)
      return {
        next_id: this.filteredActionFiles?.[selected_index+1]?.uid ?? '',
        previous_id: this.filteredActionFiles?.[selected_index-1]?.uid ?? '',
      }
    },
    selectedActionFile(){
      return this.filteredActionFiles.find(file=>file.uid==this.file_id)
    },
    files() {
      const posts = this.channelData?.posts?.order?.map?.((order) => this.channelData.posts.posts[order]) ?? [];
      const multimediaposts = posts.filter((message) => message.metadata && message.metadata.files);
      let files = [];
      multimediaposts.forEach((message) => {files = [...files, ...message.metadata.files];});
      return files;
    },
    filteredFiles() {
      return this.files?.filter?.(file=> ["image","video"].includes(file?.extra_data?.file_type) && !file?.extra_data?.is_card);
    },
    selectedFile(){
      return this.filteredFiles.find(file=>file.id==this.file_id)
    },
    fileNavigation(){
      const selected_index = this.filteredFiles.findIndex(file=>file.id==this.file_id)
      return {
        next_id: this.filteredFiles?.[selected_index+1]?.id ?? '',
        previous_id: this.filteredFiles?.[selected_index-1]?.id ?? '',
      }
    },
    user(){
      return Object.values(this.getVoipUsers).find(vuser => vuser?.user?.user_chat_id === this.selectedFile?.user_id);
    }
  },
  methods: {
    onBeforeOpen(event){
      this.channel_id = event?.params?.channel_id || '';
      this.file_id = event?.params?.file_id || '';
      this.is_action = !!event?.params?.is_action
      document.addEventListener('keydown',this.onkeydown)
    },
    onBeforeClose(){
      document.removeEventListener('keydown',this.onkeydown)
    },
    onkeydown(Event){
      LOGGER.log(Event)
      if(Event?.key=='ArrowRight' && this.fileNavigation.next_id){
        this.file_id=this.fileNavigation.next_id
      }
      if(Event?.key=='ArrowLeft' && this.fileNavigation.previous_id){
        this.file_id=this.fileNavigation.previous_id
      }
    },
    downloadFile() {
      let vm = this
      fetch(`${URLS.chatApi}files/${vm.file_id}?download=1`,{
        headers: {
          "X-Requested-With": "XMLHttpRequest",
          Authorization: `Bearer ${TokenService.MATTERMOST_TOKEN.get()}`,
        },
      }).then((resp) => resp.blob()).then((blob) => {
        const url = window.URL.createObjectURL(blob);
        const a = document.createElement("a");
        a.style.display = "none";
        a.href = url;
        a.download = vm.getProperty(this.selectedFile,'name') || "download";
        document.body.appendChild(a);
        a.click();
        a.remove();
        window.URL.revokeObjectURL(url);
      })
      // .catch((error) => console.log("error in downloading file", error));
    },
  },
};
</script>
<style lang="scss">
.addable-memberLisit {
    ul {
        /* width */
        ::-webkit-scrollbar {
        width: 4px;
        height: 4px;
        }
    }
    ul {
        overflow-x: auto;
    }
    .avatar {
        &.user-avatar {
        overflow: visible;
        .member-close {
            cursor: pointer;
            display: flex;
            align-items: center;
            justify-content: center;
            top: -3px;
            right: -3px;
        }
        }
    }
    .member-name {
        width: 18px;
        overflow: hidden;
        text-overflow: unset;
    }
}
</style>
