var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.dids.length ? _c('div', [_c('div', {
    staticClass: "nodeBasic-new"
  }, [_c('div', _vm._l(_vm.dids, function (did) {
    return _c('div', {
      key: did.did,
      staticClass: "sm-mar-left font-weight-bold min-width-160px mb-8px"
    }, [_c('span', {
      staticClass: "mr-2"
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(did.did))
      }
    })]), _vm._v(" " + _vm._s(_vm._f("number_formater")(did.did)) + " ")]);
  }), 0)]), _vm._m(0)]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-center w-100",
    staticStyle: {
      "width": "200px"
    }
  }, [_c('div', {
    staticClass: "lineWithArrow"
  }, [_c('div', {
    staticClass: "lineWithArrow-line"
  }), _c('div', {
    staticClass: "lineWithArrow-arrow"
  })])]);

}]

export { render, staticRenderFns }