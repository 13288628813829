<template>
  <div>
    <modal class="dialer_animation right_side_popup UpdateTeamsProfileImage-v2 min-width-50percent-modal" width="50%" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
      <div class=""> 
        <div class="dialer-edit-header">
          <h2 class="dialer-settings-title newer mb-0">
            Update 
            profile picture
          </h2> 
          <div class="dialer-edit-actions">
            <a class="" @click="$modal.hide(modalName)">
              <b-button class="newDoneButton">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </b-button>
            </a>
          </div>
        </div>
        <div class="mt-0">
          <!-- <div class="current-image-container mt-0 d-flex align-items-center justify-content-center" :aria-disabled="api.upload.send">
            <div class="loader-icon" v-if="api.upload.send">
              <div class="latestShimmerDesign mb-3" style="height: 150px;width: 150px;border-radius: 50%;"></div>
              <div class="w-100 d-flex justify-content-center">{{ api.upload.upload_progress.percentage }}%</div>
            </div>
            <div class="current-image mt-0" v-else>
              <img id="profile-image" :src="data.current_image || ''" @error="$event.target.src=local_filess.images.user_male" :alt="'Profile Image'" />
            </div>
          </div>
          <h4 class="mt-4 dialer-edit-title w-100 text-center font-weight-700 mb-0 ">Update <span style="text-transform:lowercase">{{ data.type | updateText }}</span> profile</h4>
          <template v-if="['USER','COMPANY','ADDRESS_BOOK'].includes(data.type)">
            <div class="w-100 d-flex justify-content-center mt-4" v-if="!conditions.show_upload">
              <button @click="conditions.toggleShowUpload()" class="newButton mwd-168px">
                <vb-icon icon="squared-singleUser-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Upload image</span>
              </button>
            </div>
            <template v-else>
              <div class="uploadImage-container position-relative">
                <div class="uploadImage-image">
                  <img src="../../assets/images/upimg_placeholder.png" />
                </div>
                <div class="position-relative w-auto mx-auto">
                  <button id="profile-image-uploader-browse-image" @click="conditions.toggleShowUpload()" class="newButton mwd-168px">
                    <vb-icon icon="squared-singleUser-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Browse image</span>
                  </button>
                  <input class="hide_input cursor_pointer_no_hover" style="" type="file" accept="image/*" :disabled="api.upload.send" :multiple="false" @change="uploadImage({ type: 'upload', event: $event })" v-b-tooltip.hover title="Upload image" />
                </div>
                <div class="position-absolute" style="top:10px;right:14px;cursor:pointer;">
                  <b-icon icon="x-lg" @click="conditions.toggleShowUpload()"/>
                </div>
              </div>
            </template>
          </template> -->
          <div class="latestGreyBox-9-9-2023">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start py-4">
                <div class="current-image-container p-0 mt-0 d-flex align-items-center justify-content-center mr-32px" :aria-disabled="api.upload.send">
                  <div class="loader-icon" v-if="api.upload.send">
                    <div class="latestShimmerDesign mb-3" style="height: 110px;width: 110px;border-radius: 50%;"></div>
                    <div class="w-100 d-flex justify-content-center">{{ api.upload.upload_progress.percentage }}%</div>
                  </div>
                  <div class="current-image mt-0" v-else>
                    <img id="profile-image" :src="data.current_image || ''" @error="$event.target.src=local_filess.images.user_male" :alt="'Profile Image'" />
                  </div>
                </div>
                <div class="uploadImage-container position-relative mt-0" v-if="['USER','COMPANY'].includes(data.type) && conditions.show_upload">
                  <div class="uploadImage-image mr-32px">
                    <img src="../../assets/images/upimg_placeholder.png" />
                  </div>
                  <div class="position-relative w-auto">
                    <button id="profile-image-uploader-browse-image" @click="conditions.toggleShowUpload()" class="newButton mwd-168px">
                      <vb-icon icon="squared-singleUser-icon" height="38px" width="38px" />
                      <span class="newButton-textPart">Browse image</span>
                    </button>
                    <input class="hide_input cursor_pointer_no_hover" ref="file_upload" type="file" accept="image/*" :disabled="api.upload.send" :multiple="false" @change="uploadImage({ type: 'upload', event: $event })" v-b-tooltip.hover title="Upload image" />
                  </div>
                  <div class="position-absolute" style="top:10px;right:14px;cursor:pointer;">
                    <b-icon icon="x-lg" @click="conditions.toggleShowUpload()"/>
                  </div>
                </div>
                <div v-else>
                  <h4 class="dialer-edit-title w-100 font-weight-700 mb-0 ">Update <span style="text-transform:lowercase">{{ data.type | updateText }}</span> profile</h4>
                  <template v-if="['USER','COMPANY'].includes(data.type)">
                    <div class="w-100 d-flex mt-16px" v-if="!conditions.show_upload">
                      <button @click="conditions.toggleShowUpload()" class="newButton mwd-168px">
                        <vb-icon icon="squared-singleUser-icon" height="38px" width="38px" />
                        <span class="newButton-textPart">Upload image</span>
                      </button>
                    </div>
                  </template>
                </div>
              </div>
            </div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-0">
              <b-dropdown id="profile-image-uploader-select-category" :disabled="api.fetch_categories.send || api.fetch_avatars.send" no-caret :class="`profile-category-dd ${conditions.show_upload ? 'small' : ''}`">
                <template v-slot:button-content>
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <span class="mr-2">{{ selected.category ? selected.category.label : 'Select category'}}</span>
                    <b-icon icon="chevron-down" font-scale="1" />
                  </div>
                </template>
                <div class="dd-item-container">
                  <b-dropdown-item class="profile-image-uploader-select-category-item" :disabled="api.fetch_categories.send || api.fetch_avatars.send" v-for="category in response.categories" :key="category.id" @click="selected.category=category">{{ category.label }}</b-dropdown-item>
                  <b-dropdown-item disabled v-if="isEmpty(response.categories)">There is nothing to show</b-dropdown-item>
                </div>
              </b-dropdown>
            </div>
            <div :class="`all-images-container ${conditions.show_upload ? 'small' : ''}`">
              <div id="profile-image-uploader-avatars-div" class="all-images-container-inner">
                <!-- <vb-loading v-if="isEmpty(response.avatars) && (api.fetch_avatars.send || api.fetch_categories.send )" /> -->
                <template v-if="isEmpty(response.avatars) && (api.fetch_avatars.send || api.fetch_categories.send )">
                  <div v-for="n in 24" :key="n.id" class="latestShimmerDesign image-container" style="height: 75px;width: 75px;border-radius: 50%;">
                  </div>
                </template>
                <div v-for="avatar in response.avatars" class="image-container" :key="avatar.id" @click="uploadImage({ type: 'avatar', avatar: avatar })">
                  <img :id="`avatar-${avatar.id}`" :src="avatar.image" @error="$event.target.src=require('@/assets/images/user-male.png')" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '../../utils'
let types = {
  USER: 'USER',
  COMPANY: 'COMPANY',
  IVR: 'IVR',
  TEAM: 'TEAM',
  CONFERENCE: 'CONFERENCE',
  ADDRESS_BOOK: 'ADDRESS_BOOK',
  SPEED_DIAL: 'SPEED_DIAL',
  CALL_QUEUE: 'CALL_QUEUE',
}
export default {
  name: 'ProfileImageUploader',
  inject: ['local_filess','isEmpty','getVoipUsers','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'ProfileImageUploader',
    },
  },
  data(){
    return {
      api: {
        upload: this.$helperFunction.apiInstance({ source: true, upload_progress: true, }),
        fetch_avatars: this.$helperFunction.apiInstance(),
        fetch_categories: this.$helperFunction.apiInstance(),
        organizations: this.$helperFunction.apiInstance(),
        reset(){
          this.upload?.reset?.({ cancel_message: 'Cancel Request' })
          this.fetch_avatars?.reset?.()
          this.fetch_categories?.reset?.()
          this.organizations?.reset?.()
        },
      },
      selected: {
        category: null,
        reset(){
          this.category = null
        },
      },
      data: {
        accountcode: '',
        type: '',
        current_image: '',
        reset(){
          this.accountcode = ''
          this.type = ''
          this.current_image = ''
        },
      },
      response: {
        avatars: [],
        categories: [],
        reset(){
          this.avatars=[]
          this.categories=[]
        },
      },
      conditions: {
        show_upload: false,
        toggleShowUpload(){
          this.show_upload=!this.show_upload
        },
        reset(){
          this.show_upload=false
        },
      },
    }
  },
  computed: {
    organizationimagespath(){ return `${this.$store.getters.getCurrentUser?.apiServer.split("/").slice(0, -1).join("/")}/storage/defaults/logos/` },
    userimagepath(){ return this.$store.getters.getCurrentUser?.profileImg.split("/").slice(0, -1).join("/") },
  },
  watch: {
    "selected.category"(category){
      this.response.avatars=[]
      if(category){
        this.fetchAvatars()
      }
    }
  },
  filters: {
    updateText(value){
      if(types.USER==value) return value
      else if(types.COMPANY==value) return value
      else if(types.IVR==value) return value
      else if(types.TEAM==value) return value
      else if(types.CONFERENCE==value) return value
      // else if(types.ADDRESS_BOOK==value) return 'address book'
      else if(types.ADDRESS_BOOK==value) return 'contact'
      else if(types.SPEED_DIAL==value) return value
      else if(types.CALL_QUEUE==value) return 'Call Queue'
      else return value
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.type = event?.params?.type ?? ''
      this.data.accountcode = event?.params?.accountcode ?? ''
      this.data.current_image = event?.params?.current_image ?? ''
      if(!this.data.accountcode || !types[this.data.type]){
        this.$modal.hide(this.modalName)
      }
      this.fetchCategories()
    },
    onBeforeClose(){
      this.data.reset()
      this.selected.reset()
      this.api.reset()
      this.response.reset()
      this.conditions.reset()
    },
    async uploadImage(data = {}) {
      let vm = this
      try {
        const upload_type = data?.type ?? ''
        const files = data?.event?.target?.files
        const avatar = data?.avatar
        if (this.api.upload.send || !upload_type || (upload_type=='upload' && files.length==0)) return;
        const formData = new FormData();
        this.api.upload.source?.cancel?.("Requesting Again");
        this.api.upload.send=true
        this.api.upload.source = require("axios").default.CancelToken.source();
        formData.append("accountcode", this.data.accountcode);
        formData.append("id", this.data.accountcode);
        formData.append("uid", this.$store.getters.getCurrentUser?.uid);
        if(upload_type=='upload'){
          const [file] = files;
          const [type, extension] = file.type.split("/");
          formData.append("image", file, file.name);
          formData.append("extension", extension);
          formData.append("type", type);
          formData.append("is_upload", "yes");
          formData.append("avatar_id", 0);
        } else {
          formData.append("is_upload", "no");
          formData.append("avatar_id", avatar.id);
        }
        if(this.data.type==types.USER) formData.append("change_image_type", "profile")
        else if(this.data.type==types.COMPANY) formData.append("change_image_type", "company")
        let type = ''
        if(this.data.type==types.USER) type='USER'
        else if(this.data.type==types.COMPANY) type='COMPANY'
        else if(this.data.type==types.IVR) type='IVR'
        else if(this.data.type==types.TEAM) type='TEAM'
        else if(this.data.type==types.CONFERENCE) type='CONFERENCE'
        else if(this.data.type==types.ADDRESS_BOOK) type='ADDRESSBOOK'
        else if(this.data.type==types.SPEED_DIAL) type='SPEEDDIAL'
        else if(this.data.type==types.CALL_QUEUE) type='CALLQUEUE'
        formData.append("type", type);
        const res = await VOIP_API.endpoints.user.updateprofileimage(formData,this.api.upload.upload_progress.onProgress.bind(this.api.upload.upload_progress),this.api.upload.source.token)
        let image_path = ''
        if(upload_type=='upload'){
          if(this.data.type==types.USER) image_path = `${this.userimagepath}/${res?.data?.profile_img}`
          if(this.data.type==types.COMPANY) image_path = `${this.organizationimagespath}${res?.data?.company_logo}`
        } else {
          image_path=avatar?.image ?? ''
        }
        if(this.data.type==types.USER){ // current user
          if(this.data.accountcode==this.$store.getters.getCurrentUser?.account){
            this.$store.state.common.user.profileImg = image_path;
          }
          this.getVoipUsers()
        } else if(this.data.type==types.COMPANY){ // organizzation
          this.$store.state.common.user.companyLogo = image_path;
          setTimeout(()=>{
            vm.fetchOrganizations()
          },5 * 1000)
        } else if(this.data.type==types.TEAM){ // team
          //TODO
        } else if(this.data.type==types.IVR){ // ivr
          //TODO
        } else if(this.data.type==types.CONFERENCE){ // conference
          //TODO
        } else if(this.data.type==types.ADDRESS_BOOK){ // address book
          //TODO
        } else if(this.data.type==types.SPEED_DIAL){ // speed dial
          //TODO
        } else if(this.data.type==types.CALL_QUEUE){ // call queue
          //TODO
        }
        this.data.current_image=image_path
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        this.$emit('image-uploaded',this.data.current_image)
        if(this.$refs['file_upload']) this.$refs['file_upload'].value=''
      } catch(ex){
        this.appNotify({
          message: ex.own_message ?? ex.message ?? '',
          type: 'danger',
        })
      } finally {
        this.api.upload.send = false;
        this.api.upload.source = null;
      }
    },
    fetchAvatars(){
      let vm = this
      if(vm.api.fetch_avatars.send) return;
      vm.api.fetch_avatars.send=true
      VOIP_API.endpoints.default_avatars.getdefaultavatarsbycategoryid({
        category_id: this.selected.category.id
      }).then(({ data })=>{
        const avatars = data?.data ?? data
        vm.response.avatars=avatars
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.fetch_avatars.send=false
      })
    },
    fetchCategories(){
      let vm = this
      if(vm.api.fetch_categories.send) return;
      vm.api.fetch_categories.send=true
      VOIP_API.endpoints.default_avatars.getcategories().then(({ data })=>{
        const categories = data?.data ?? data
        vm.response.categories=categories
        vm.selected.category=categories?.[0]
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.fetch_categories.send=false
      })
    },
    fetchOrganizations(){
      let vm = this
      if(vm.api.organizations.send) return;
      vm.api.organizations.send=true
      VOIP_API.endpoints.user.organizationlist({
        accountcode: vm.$store.getters.getCurrentUser?.account, //vm.current_user.account,
        uid: vm.$store.getters.getCurrentUser?.uid, //vm.current_user.uid
      }).then(({ data: { data: organizations } })=>{
        const new_organizations_array = organizations.map(organization=>{
          return {
            id: organization.origanization.origanization_id,
            uid: organization.origanization.origanization_uid,
            label: organization.origanization.origanization_label,
            logo: organization.origanization.origanization_logo,
          }
        })
        this.$store.state.common.user.organizations = new_organizations_array;
        this.$store.state.common.user.selected_organization_id = this.$store.state.common.user.selected_organization_id || new_organizations_array[0] ? new_organizations_array[0].id : '';
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.organizations.send=false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-image-section {
  position: relative;
  width: 180px;
  height: 180px;
  display: flex;
  overflow: hidden;
  // @include border-radius(50%);
  @include border-radius(12%);
  .loader-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .profile-img-upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: none;
    justify-content: center;
    .upload-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      opacity: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(50%);
    }
  }
  &:hover {
    .profile-img-upload-btn {
      display: block;
      cursor: pointer;
    }
  }
}
</style>