var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "newPermissionsModal",
    attrs: {
      "height": "auto",
      "name": "NotificationPermissionsModal",
      "clickToClose": false
    },
    on: {
      "before-open": _vm.beforeOpen,
      "closed": _vm.onClosed
    }
  }, [_c('div', {
    staticClass: "newPermissionsModal-innerContainer"
  }, [_c('button', {
    staticClass: "close-nothing-button",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('NotificationPermissionsModal');
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "scale": "1.5"
    }
  })], 1), _vm.selected == 'microphone' ? [_c('div', {
    staticClass: "permission-icon-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "permission-microphone-icon",
      "width": "71.287px",
      "height": "125.075px"
    }
  })], 1), _vm.permissions.microphone == 'denied' ? [_c('div', {
    staticClass: "permission-heading"
  }, [_vm._v(" Allow microphone access ")]), _c('div', {
    staticClass: "permission-text"
  }, [_vm._v(" VoIP Business needs access to your device's microphone to enable voice calls and voice messaging. Granting this permission ensures clear and reliable audio communication. ")]), _c('div', {
    staticClass: "w-100 mt-40px"
  }, [_c('button', {
    staticClass: "fullWidthBlueButton",
    on: {
      "click": function ($event) {
        return _vm.getPermission(_vm.selected);
      }
    }
  }, [_vm._v(" Enable Microphone ")])])] : _vm.permissions.microphone == 'granted' ? [_c('div', {
    staticClass: "permission-heading"
  }, [_vm._v(" Allow microphone access ")]), _c('div', {
    staticClass: "permission-text"
  }, [_vm._v(" VoIP Business needs access to your device's microphone to enable voice calls and voice messaging. Granting this permission ensures clear and reliable audio communication. ")]), _c('div', {
    staticClass: "w-100 mt-40px"
  }, [_c('button', {
    staticClass: "fullWidthBlueButton",
    on: {
      "click": function ($event) {
        return _vm.onClick();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.permissions.microphone == 'granted' && _vm.permissions.notification == 'granted' && _vm.permissions.camera == 'granted' ? 'Finish' : 'Continue') + " ")])])] : [_c('div', {
    staticClass: "permission-heading"
  }, [_vm._v(" Allow microphone access ")]), _c('div', {
    staticClass: "permission-text"
  }, [_vm._v(" VoIP Business needs access to your device's microphone to enable voice calls and voice messaging. Granting this permission ensures clear and reliable audio communication. ")]), _c('div', {
    staticClass: "w-100 mt-40px"
  }, [_c('button', {
    staticClass: "fullWidthBlueButton",
    on: {
      "click": function ($event) {
        return _vm.getPermission(_vm.selected);
      }
    }
  }, [_vm._v(" Enable Microphone ")])])]] : _vm._e(), _vm.selected == 'camera' ? [_c('div', {
    staticClass: "permission-icon-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "permission-camera-icon",
      "width": "150.377px",
      "height": "93.317px"
    }
  })], 1), _vm.permissions.camera == 'denied' ? [_c('div', {
    staticClass: "permission-heading"
  }, [_vm._v(" Enable video conversations ")]), _c('div', {
    staticClass: "permission-text"
  }, [_vm._v(" Give VoIP Business access to your device's camera for video calls and video conferencing. This permission is essential for engaging in face-to-face conversations. ")]), _c('div', {
    staticClass: "w-100 mt-40px"
  }, [_c('button', {
    staticClass: "fullWidthBlueButton",
    on: {
      "click": function ($event) {
        return _vm.getPermission(_vm.selected);
      }
    }
  }, [_vm._v(" Enable camera ")])])] : _vm.permissions.camera == 'granted' ? [_c('div', {
    staticClass: "permission-heading"
  }, [_vm._v(" Enable video conversations ")]), _c('div', {
    staticClass: "permission-text"
  }, [_vm._v(" Give VoIP Business access to your device's camera for video calls and video conferencing. This permission is essential for engaging in face-to-face conversations. ")]), _c('div', {
    staticClass: "w-100 mt-40px"
  }, [_c('button', {
    staticClass: "fullWidthBlueButton",
    on: {
      "click": function ($event) {
        return _vm.onClick();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.permissions.microphone == 'granted' && _vm.permissions.notification == 'granted' && _vm.permissions.camera == 'granted' ? 'Finish' : 'Continue') + " ")])])] : [_c('div', {
    staticClass: "permission-heading"
  }, [_vm._v(" Enable video conversations ")]), _c('div', {
    staticClass: "permission-text"
  }, [_vm._v(" Give VoIP Business access to your device's camera for video calls and video conferencing. This permission is essential for engaging in face-to-face conversations. ")]), _c('div', {
    staticClass: "w-100 mt-40px"
  }, [_c('button', {
    staticClass: "fullWidthBlueButton",
    on: {
      "click": function ($event) {
        return _vm.getPermission(_vm.selected);
      }
    }
  }, [_vm._v(" Enable camera ")])])]] : _vm._e(), _vm.selected == 'notification' ? [_c('div', {
    staticClass: "permission-icon-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "permission-notification-icon",
      "width": "116.987px",
      "height": "124.012px"
    }
  })], 1), _vm.permissions.notification == 'denied' ? [_c('div', {
    staticClass: "permission-heading"
  }, [_vm._v(" Stay connected seamlessly ")]), _c('div', {
    staticClass: "permission-text"
  }, [_vm._v(" Keep in touch effortlessly. Allow VoIP Business to send notifications for incoming calls and messages. ")]), _c('div', {
    staticClass: "w-100 mt-40px"
  }, [_c('button', {
    staticClass: "fullWidthBlueButton",
    on: {
      "click": function ($event) {
        return _vm.getPermission(_vm.selected);
      }
    }
  }, [_vm._v(" Enable notification ")])]), _c('div', {
    staticClass: "d-flex justify-content-center w-100"
  }, [_c('div', {
    staticClass: "permission-notNowText",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('NotificationPermissionsModal');
      }
    }
  }, [_vm._v(" Not Now ")])])] : _vm.permissions.notification == 'granted' ? [_c('div', {
    staticClass: "permission-heading"
  }, [_vm._v(" Stay connected seamlessly ")]), _c('div', {
    staticClass: "permission-text"
  }, [_vm._v(" Keep in touch effortlessly. Allow VoIP Business to send notifications for incoming calls and messages. ")]), _c('div', {
    staticClass: "w-100 mt-40px"
  }, [_c('button', {
    staticClass: "fullWidthBlueButton",
    on: {
      "click": function ($event) {
        return _vm.onClick();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.permissions.microphone == 'granted' && _vm.permissions.notification == 'granted' && _vm.permissions.camera == 'granted' ? 'Finish' : 'Continue') + " ")])])] : [_c('div', {
    staticClass: "permission-heading"
  }, [_vm._v(" Stay connected seamlessly ")]), _c('div', {
    staticClass: "permission-text"
  }, [_vm._v(" Keep in touch effortlessly. Allow VoIP Business to send notifications for incoming calls and messages. ")]), _c('div', {
    staticClass: "w-100 mt-40px"
  }, [_c('button', {
    staticClass: "fullWidthBlueButton",
    on: {
      "click": function ($event) {
        return _vm.getPermission(_vm.selected);
      }
    }
  }, [_vm._v(" Enable notification ")])]), _c('div', {
    staticClass: "d-flex justify-content-center w-100"
  }, [_c('div', {
    staticClass: "permission-notNowText",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('NotificationPermissionsModal');
      }
    }
  }, [_vm._v(" Not Now ")])])]] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }