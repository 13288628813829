var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup UpdateTeamsProfileImage-v2 min-width-50percent-modal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {}, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v(" Update profile picture ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-button', {
    staticClass: "newDoneButton"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)], 1)])]), _c('div', {
    staticClass: "mt-0"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start py-4"
  }, [_c('div', {
    staticClass: "current-image-container p-0 mt-0 d-flex align-items-center justify-content-center mr-32px",
    attrs: {
      "aria-disabled": _vm.api.upload.send
    }
  }, [_vm.api.upload.send ? _c('div', {
    staticClass: "loader-icon"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-3",
    staticStyle: {
      "height": "110px",
      "width": "110px",
      "border-radius": "50%"
    }
  }), _c('div', {
    staticClass: "w-100 d-flex justify-content-center"
  }, [_vm._v(_vm._s(_vm.api.upload.upload_progress.percentage) + "%")])]) : _c('div', {
    staticClass: "current-image mt-0"
  }, [_c('img', {
    attrs: {
      "id": "profile-image",
      "src": _vm.data.current_image || '',
      "alt": 'Profile Image'
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])]), ['USER', 'COMPANY'].includes(_vm.data.type) && _vm.conditions.show_upload ? _c('div', {
    staticClass: "uploadImage-container position-relative mt-0"
  }, [_c('div', {
    staticClass: "uploadImage-image mr-32px"
  }, [_c('img', {
    attrs: {
      "src": require("../../assets/images/upimg_placeholder.png")
    }
  })]), _c('div', {
    staticClass: "position-relative w-auto"
  }, [_c('button', {
    staticClass: "newButton mwd-168px",
    attrs: {
      "id": "profile-image-uploader-browse-image"
    },
    on: {
      "click": function ($event) {
        return _vm.conditions.toggleShowUpload();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-singleUser-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Browse image")])], 1), _c('input', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    ref: "file_upload",
    staticClass: "hide_input cursor_pointer_no_hover",
    attrs: {
      "type": "file",
      "accept": "image/*",
      "disabled": _vm.api.upload.send,
      "multiple": false,
      "title": "Upload image"
    },
    on: {
      "change": function ($event) {
        return _vm.uploadImage({
          type: 'upload',
          event: $event
        });
      }
    }
  })]), _c('div', {
    staticClass: "position-absolute",
    staticStyle: {
      "top": "10px",
      "right": "14px",
      "cursor": "pointer"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x-lg"
    },
    on: {
      "click": function ($event) {
        return _vm.conditions.toggleShowUpload();
      }
    }
  })], 1)]) : _c('div', [_c('h4', {
    staticClass: "dialer-edit-title w-100 font-weight-700 mb-0"
  }, [_vm._v("Update "), _c('span', {
    staticStyle: {
      "text-transform": "lowercase"
    }
  }, [_vm._v(_vm._s(_vm._f("updateText")(_vm.data.type)))]), _vm._v(" profile")]), ['USER', 'COMPANY'].includes(_vm.data.type) ? [!_vm.conditions.show_upload ? _c('div', {
    staticClass: "w-100 d-flex mt-16px"
  }, [_c('button', {
    staticClass: "newButton mwd-168px",
    on: {
      "click": function ($event) {
        return _vm.conditions.toggleShowUpload();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-singleUser-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Upload image")])], 1)]) : _vm._e()] : _vm._e()], 2)])])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-0"
  }, [_c('b-dropdown', {
    class: `profile-category-dd ${_vm.conditions.show_upload ? 'small' : ''}`,
    attrs: {
      "id": "profile-image-uploader-select-category",
      "disabled": _vm.api.fetch_categories.send || _vm.api.fetch_avatars.send,
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center w-100"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(_vm.selected.category ? _vm.selected.category.label : 'Select category'))]), _c('b-icon', {
          attrs: {
            "icon": "chevron-down",
            "font-scale": "1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('div', {
    staticClass: "dd-item-container"
  }, [_vm._l(_vm.response.categories, function (category) {
    return _c('b-dropdown-item', {
      key: category.id,
      staticClass: "profile-image-uploader-select-category-item",
      attrs: {
        "disabled": _vm.api.fetch_categories.send || _vm.api.fetch_avatars.send
      },
      on: {
        "click": function ($event) {
          _vm.selected.category = category;
        }
      }
    }, [_vm._v(_vm._s(category.label))]);
  }), _vm.isEmpty(_vm.response.categories) ? _c('b-dropdown-item', {
    attrs: {
      "disabled": ""
    }
  }, [_vm._v("There is nothing to show")]) : _vm._e()], 2)])], 1), _c('div', {
    class: `all-images-container ${_vm.conditions.show_upload ? 'small' : ''}`
  }, [_c('div', {
    staticClass: "all-images-container-inner",
    attrs: {
      "id": "profile-image-uploader-avatars-div"
    }
  }, [_vm.isEmpty(_vm.response.avatars) && (_vm.api.fetch_avatars.send || _vm.api.fetch_categories.send) ? _vm._l(24, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "latestShimmerDesign image-container",
      staticStyle: {
        "height": "75px",
        "width": "75px",
        "border-radius": "50%"
      }
    });
  }) : _vm._e(), _vm._l(_vm.response.avatars, function (avatar) {
    return _c('div', {
      key: avatar.id,
      staticClass: "image-container",
      on: {
        "click": function ($event) {
          return _vm.uploadImage({
            type: 'avatar',
            avatar: avatar
          });
        }
      }
    }, [_c('img', {
      attrs: {
        "id": `avatar-${avatar.id}`,
        "src": avatar.image
      },
      on: {
        "error": function ($event) {
          $event.target.src = require('@/assets/images/user-male.png');
        }
      }
    })]);
  })], 2)])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }