var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.add_call_queue.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Call Queue")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CheckedSlot', {
          service: 'queue',
          onProceed: _vm.addCallQueue
        });
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Detail")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addCallQueue();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.add_call_queue.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_call_queue.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.call_queue.name,
      expression: "forms.call_queue.name"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Name",
      "type": "text",
      "disabled": _vm.api.add_call_queue.send
    },
    domProps: {
      "value": _vm.forms.call_queue.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.call_queue, "name", $event.target.value);
      }
    }
  }), _vm.forms.call_queue.submitted && _vm.$v.forms.call_queue.name.$invalid || _vm.api.add_call_queue.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.call_queue.name.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), _vm._l(_vm.api.add_call_queue.validation_errors.name, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.add_call_queue.send
    }
  }, [_vm.api.add_call_queue.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }