export const chunk9_icons = {
  "CallRecording-IOSIcon": {
    name: "CallRecording-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18328" data-name="Group 18328" transform="translate(-27 -918.992)">
          <rect id="Rectangle_8986" data-name="Rectangle 8986" width="28" height="28" rx="6" transform="translate(27 918.992)" fill="#ff1900"/>
          <g id="Group_18250" data-name="Group 18250" transform="translate(-5655.38 -11519.483)">
            <path id="Union_65" data-name="Union 65" d="M11.406,12.953l-.124-.019c-.465-.053-.94-.086-1.4-.162A11.314,11.314,0,0,1,6.213,11.5,11.874,11.874,0,0,1,1.82,7.358a11.73,11.73,0,0,1-1.744-5C.045,2.091.025,1.819,0,1.546l0,0V.91a3.438,3.438,0,0,1,.221-.5A1.032,1.032,0,0,1,.913,0H3.543c.007,0,.013.012.022.013a1.065,1.065,0,0,1,.9,1.073A6.692,6.692,0,0,0,4.55,2.141c.074.43.193.852.293,1.278a1.031,1.031,0,0,1-.2.9c-.365.48-.732.958-1.091,1.441a.151.151,0,0,0-.013.133A9.056,9.056,0,0,0,4.667,7.559,8.284,8.284,0,0,0,7.05,9.406.161.161,0,0,0,7.19,9.4c.465-.344.931-.685,1.381-1.044a1.082,1.082,0,0,1,1.053-.206A8.058,8.058,0,0,0,11.9,8.5a.982.982,0,0,1,.843.429,2.8,2.8,0,0,1,.209.48v2.63a3.453,3.453,0,0,1-.221.5,1.032,1.032,0,0,1-.689.413Zm1.572-9.707a2,2,0,0,1-.412-1.355,2.215,2.215,0,0,1,.2-.976,1.47,1.47,0,0,1,.582-.643A1.726,1.726,0,0,1,14.24.049,2.4,2.4,0,0,1,15.286.3l-.245.615a4.03,4.03,0,0,0-.4-.166,1.192,1.192,0,0,0-.4-.071A.772.772,0,0,0,13.578,1a1.5,1.5,0,0,0-.234.895q0,1.193.9,1.193a2.833,2.833,0,0,0,.911-.187v.635a2.52,2.52,0,0,1-.981.183A1.5,1.5,0,0,1,12.978,3.246ZM9.916,3.67V.1h2.056v.62h-1.3V1.5h1.209v.62H10.673v.921h1.3V3.67Zm-1.3,0L7.768,2.3h-.4V3.67H6.608V.1h1.04A1.779,1.779,0,0,1,8.725.365a.946.946,0,0,1,.349.8.944.944,0,0,1-.174.561,1.142,1.142,0,0,1-.49.384q.806,1.2,1.05,1.555ZM7.365,1.686h.244a.938.938,0,0,0,.53-.12A.427.427,0,0,0,8.31,1.19.39.39,0,0,0,8.135.829,1.055,1.055,0,0,0,7.594.721H7.365Z" transform="translate(5688.737 12446)" fill="#fff"/>
          </g>
        </g>
      `,
  },
  "Billing-IOSIcon": {
    name: "Billing-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18327" data-name="Group 18327" transform="translate(-27 -967.992)">
          <rect id="Rectangle_8987" data-name="Rectangle 8987" width="28" height="28" rx="6" transform="translate(27 967.992)" fill="#fa9402"/>
          <g id="Group_14344" data-name="Group 14344" transform="translate(0.998 -741.998)">
            <g id="Group_14007" data-name="Group 14007" transform="translate(32.5 1717.203)">
              <path id="Path_12235" data-name="Path 12235" d="M13.631,22.214h-.187V20.44A1.359,1.359,0,0,0,12.072,19.1H2.119a2.1,2.1,0,0,0-2.108,1.87C-.005,21.042,0,20.382,0,30.6a2.1,2.1,0,0,0,2.119,2.072H13.631A1.359,1.359,0,0,0,15,31.331V23.556a1.359,1.359,0,0,0-1.372-1.342ZM2.119,20.02h9.953a.426.426,0,0,1,.43.42v1.773H2.119a1.1,1.1,0,1,1,0-2.194ZM13.631,31.751H2.119A1.165,1.165,0,0,1,.943,30.6V22.786a2.14,2.14,0,0,0,1.176.349H13.631a.426.426,0,0,1,.43.42v1.869H10.514a2.143,2.143,0,1,0,0,4.285h3.547v1.621A.426.426,0,0,1,13.631,31.751Zm.43-2.963H10.514a1.221,1.221,0,1,1,0-2.441h3.547Z" transform="translate(0 -19.098)" fill="#fff"/>
            </g>
            <circle id="Ellipse_979" data-name="Ellipse 979" cx="0.748" cy="0.748" r="0.748" transform="translate(43.125 1724.902)" fill="#fff"/>
          </g>
        </g>
      `,
  },
  "GlobalSetting-IOSIcon": {
    name: "GlobalSetting-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18326" data-name="Group 18326" transform="translate(-27 -1016.992)">
          <rect id="Rectangle_8988" data-name="Rectangle 8988" width="28" height="28" rx="6" transform="translate(27 1016.992)" fill="#5576d1"/>
          <g id="Group_14008" data-name="Group 14008" transform="translate(0.498 -772.506)">
            <g id="global" transform="translate(33 1796)">
              <g id="Group_12684" data-name="Group 12684">
                <path id="Path_12236" data-name="Path 12236" d="M7.5,0a4.467,4.467,0,0,1,.524.031L8.44.08V1.958c.028.006.057.008.085.013l.869-1.5A.467.467,0,0,1,10.034.3L12.472,1.7a.469.469,0,0,1,.172.64l-.868,1.5a5.565,5.565,0,0,1,1.031,1.78h1.729A.469.469,0,0,1,15,6.1V8.909a.469.469,0,0,1-.469.469H12.806a5.55,5.55,0,0,1-1.032,1.78l.869,1.5a.469.469,0,0,1-.172.64l-2.438,1.407a.473.473,0,0,1-.234.063.466.466,0,0,1-.406-.234l-.869-1.5c-.027.006-.056.008-.084.013v1.877l-.414.049A4.275,4.275,0,0,1,7.5,15a7.5,7.5,0,0,1,0-15ZM8.44,9.639a2.327,2.327,0,0,0,0-4.274Zm0-5.265a3.26,3.26,0,0,1,0,6.258v1.452c.066-.015.137-.02.2-.037a.473.473,0,0,1,.523.218l.806,1.4,1.626-.938-.806-1.394a.47.47,0,0,1,.072-.564,4.615,4.615,0,0,0,1.149-1.983.468.468,0,0,1,.451-.341h1.6V6.564h-1.6a.468.468,0,0,1-.451-.342,4.62,4.62,0,0,0-1.149-1.983.468.468,0,0,1-.072-.563L11.6,2.282,9.971,1.344l-.806,1.4a.474.474,0,0,1-.523.218c-.065-.017-.136-.022-.2-.036ZM2.19,3.661a14.223,14.223,0,0,0,2.1.662A9.6,9.6,0,0,1,6.228,1.064,6.572,6.572,0,0,0,2.19,3.661ZM7.5,1.11A8.672,8.672,0,0,0,5.233,4.494a14.569,14.569,0,0,0,2.269.2Zm0,4.516A15.422,15.422,0,0,1,4.954,5.4a8.461,8.461,0,0,0,0,4.209A15.4,15.4,0,0,1,7.5,9.377Zm0,8.267V10.317a15.362,15.362,0,0,0-2.267.2A8.668,8.668,0,0,0,7.5,13.894Zm-1.273.046a9.626,9.626,0,0,1-1.936-3.256,14.038,14.038,0,0,0-2.1.659A6.568,6.568,0,0,0,6.228,13.94Zm-2.2-4.159a9.4,9.4,0,0,1,0-4.558A15.113,15.113,0,0,1,1.685,4.47a6.521,6.521,0,0,0,0,6.063A15.049,15.049,0,0,1,4.031,9.781Z" fill="#fff"/>
              </g>
            </g>
          </g>
        </g>
      `,
  },
  "Locations-IOSIcon": {
    name: "Locations-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18325" data-name="Group 18325" transform="translate(-27 -1065.74)">
          <rect id="Rectangle_8989" data-name="Rectangle 8989" width="28" height="28" rx="6" transform="translate(27 1065.74)" fill="#a1b400"/>
          <g id="Group_14009" data-name="Group 14009" transform="translate(-0.625 -810.759)">
            <g id="placeholder" transform="translate(36 1883)">
              <g id="Group_12686" data-name="Group 12686" transform="translate(0)">
                <g id="Group_12685" data-name="Group 12685">
                  <path id="Path_12237" data-name="Path 12237" d="M69.625,0A5.631,5.631,0,0,0,64,5.625c0,3.9,5.077,9.022,5.293,9.238a.471.471,0,0,0,.664,0c.216-.217,5.293-5.339,5.293-9.238A5.631,5.631,0,0,0,69.625,0Zm0,13.853c-1.119-1.2-4.687-5.25-4.687-8.228a4.687,4.687,0,1,1,9.375,0C74.312,8.6,70.744,12.655,69.625,13.853Z" transform="translate(-64)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
                </g>
              </g>
              <g id="Group_12688" data-name="Group 12688" transform="translate(2.599 2.982)">
                <g id="Group_12687" data-name="Group 12687" transform="translate(0 0)">
                  <path id="Path_12238" data-name="Path 12238" d="M163.026,96a3.026,3.026,0,1,0,3.026,3.026A3.03,3.03,0,0,0,163.026,96Zm0,5.044a2.018,2.018,0,1,1,2.018-2.018A2.02,2.02,0,0,1,163.026,101.044Z" transform="translate(-160 -96)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
                </g>
              </g>
            </g>
          </g>
        </g>
      `,
  },
  "Summary-analytics-IOSIcon": {
    name: "Summary-analytics-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18352" data-name="Group 18352" transform="translate(-27 -1226)">
          <g id="Group_18254" data-name="Group 18254" transform="translate(0 872)">
            <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#4cd964"/>
          </g>
          <g id="Group_18351" data-name="Group 18351" transform="translate(-2520.968 -15957.999)">
            <path id="Path_29973" data-name="Path 29973" d="M1.035,10.343h.226q6.562,0,13.123,0a.847.847,0,0,1,.357.073.5.5,0,0,1,.247.549.523.523,0,0,1-.466.407c-.054,0-.108,0-.162,0H.64c-.453,0-.64-.185-.64-.634Q0,5.686,0,.628A.967.967,0,0,1,.026.373.508.508,0,0,1,.581,0a.524.524,0,0,1,.452.477c0,.059,0,.118,0,.178q0,4.734,0,9.469Z" transform="translate(2554.468 17192.311)" fill="#fff" stroke="#fff" stroke-width="0.35"/>
            <path id="Path_29974" data-name="Path 29974" d="M47.871,34.646,51.521,31c-.052,0-.1-.01-.141-.01-.474,0-.948,0-1.422,0a.519.519,0,1,1,0-1.034q1.4,0,2.8,0a.52.52,0,0,1,.563.564q0,1.4,0,2.795a.519.519,0,1,1-1.034-.006c0-.463,0-.926,0-1.389v-.188c-.066.061-.108.1-.148.138l-3.8,3.8a.537.537,0,0,1-.888,0l-1.1-1.1c-.052.048-.1.087-.136.127q-1.669,1.667-3.337,3.335a.529.529,0,0,1-.567.173.512.512,0,0,1-.249-.812,1.414,1.414,0,0,1,.111-.117l3.725-3.724a.539.539,0,0,1,.888,0l1.1,1.1" transform="translate(2514.342 17163.646)" fill="#fff" stroke="#fff" stroke-width="0.35"/>
          </g>
        </g>
      `,
  },
  "Users-analytics-IOSIcon": {
    name: "Users-analytics-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18259" data-name="Group 18259" transform="translate(-27 -378)">
          <rect id="Rectangle_8979" data-name="Rectangle 8979" width="28" height="28" rx="6" transform="translate(27 378)" fill="#2eaeb7"/>
          <g id="Group_14000" data-name="Group 14000" transform="translate(0.12 -477.203)">
            <g id="user" transform="translate(33.38 861.703)">
              <g id="Group_12533" data-name="Group 12533">
                <path id="Path_12152" data-name="Path 12152" d="M14.962,12.853a.586.586,0,0,0-1.147.239.612.612,0,0,1-.124.513.6.6,0,0,1-.469.223H1.778a.6.6,0,0,1-.469-.223.612.612,0,0,1-.124-.513A6.474,6.474,0,0,1,7.345,7.966q.077,0,.155,0t.156,0a6.446,6.446,0,0,1,5.206,2.861.586.586,0,1,0,.973-.653,7.622,7.622,0,0,0-3.949-3,3.984,3.984,0,1,0-4.769,0A7.621,7.621,0,0,0,.038,12.853,1.78,1.78,0,0,0,1.778,15H13.222a1.78,1.78,0,0,0,1.74-2.147ZM4.688,3.984A2.813,2.813,0,1,1,7.643,6.793H7.359A2.816,2.816,0,0,1,4.688,3.984Z" transform="translate(0)" fill="#fff" stroke="#fff" stroke-width="0.3"/>
              </g>
            </g>
          </g>
        </g>
      `,
  },
  "Teams-analytics-IOSIcon": {
    name: "Teams-analytics-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18261" data-name="Group 18261" transform="translate(-27 -378)">
          <rect id="Rectangle_8979" data-name="Rectangle 8979" width="28" height="28" rx="6" transform="translate(27 378)" fill="#ff7258"/>
          <g id="Group_18248" data-name="Group 18248" transform="translate(3.351 -724.418)">
            <g id="user" transform="translate(29.307 1112.593)">
              <g id="Group_12533" data-name="Group 12533">
                <path id="Path_12152" data-name="Path 12152" d="M9.016,7.745a.353.353,0,0,0-.691.144A.369.369,0,0,1,8.25,8.2a.359.359,0,0,1-.282.134h-6.9A.359.359,0,0,1,.789,8.2a.369.369,0,0,1-.075-.309A3.9,3.9,0,0,1,4.426,4.8l.094,0,.094,0A3.884,3.884,0,0,1,7.751,6.524a.353.353,0,1,0,.587-.393,4.593,4.593,0,0,0-2.38-1.808,2.4,2.4,0,1,0-2.874,0A4.592,4.592,0,0,0,.023,7.745,1.073,1.073,0,0,0,1.072,9.039h6.9A1.073,1.073,0,0,0,9.016,7.745ZM2.825,2.4A1.695,1.695,0,1,1,4.606,4.094H4.434A1.7,1.7,0,0,1,2.825,2.4Z" transform="translate(0)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
              </g>
            </g>
            <g id="user-2" data-name="user" transform="translate(35.565 1111.204)">
              <g id="Group_12533-2" data-name="Group 12533">
                <path id="Path_12152-2" data-name="Path 12152" d="M10.4,8.934a.407.407,0,0,0-.8.166.426.426,0,0,1-.086.357.414.414,0,0,1-.326.155H1.236A.414.414,0,0,1,.91,9.457.425.425,0,0,1,.824,9.1,4.5,4.5,0,0,1,5.105,5.537l.108,0,.108,0A4.48,4.48,0,0,1,8.94,7.525a.407.407,0,1,0,.677-.454A5.3,5.3,0,0,0,6.872,4.986a2.769,2.769,0,1,0-3.315,0A5.3,5.3,0,0,0,.027,8.934a1.237,1.237,0,0,0,1.209,1.492H9.19A1.237,1.237,0,0,0,10.4,8.934ZM3.259,2.769A1.955,1.955,0,1,1,5.312,4.722h-.2A1.957,1.957,0,0,1,3.259,2.769Z" transform="translate(0)" fill="#fff" stroke="#fff" stroke-width="0.5"/>
              </g>
            </g>
          </g>
        </g>
      `,
  },
  "Voicemail-analytics-IOSIcon": {
    name: "Voicemail-analytics-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18264" data-name="Group 18264" transform="translate(-27 -1422)">
          <g id="Group_18258" data-name="Group 18258" transform="translate(0 1093)">
            <rect id="Rectangle_8978" data-name="Rectangle 8978" width="28" height="28" rx="6" transform="translate(27 329)" fill="#a1b400"/>
          </g>
          <g id="Group_18263" data-name="Group 18263" transform="translate(-49.038 -118.557)">
            <path id="Icon_material-call" data-name="Icon material-call" d="M7.1,10.085A10.861,10.861,0,0,0,11.82,14.81L13.4,13.233a.713.713,0,0,1,.731-.172,8.179,8.179,0,0,0,2.56.409.719.719,0,0,1,.717.717v2.5a.719.719,0,0,1-.717.717A12.188,12.188,0,0,1,4.5,5.217.719.719,0,0,1,5.217,4.5H7.726a.719.719,0,0,1,.717.717,8.145,8.145,0,0,0,.409,2.56.72.72,0,0,1-.179.731Z" transform="translate(78.023 1544.396)" fill="#fff"/>
            <g id="Group_18262" data-name="Group 18262" transform="translate(87.916 1547.309)">
              <path id="Path_44418" data-name="Path 44418" d="M483.189,1248a.975.975,0,0,1,.971-1.584.971.971,0,0,1-.314,1.91.126.126,0,0,0-.138.078q-.784,1.384-1.574,2.765a.116.116,0,0,0,.009.148.949.949,0,0,1,0,1.089.969.969,0,0,1-1.031.388.95.95,0,0,1-.717-.821,1.732,1.732,0,0,1,.056-.452c.01-.053.019-.088-.025-.131-.394-.39-.785-.784-1.179-1.173a.127.127,0,0,0-.108-.027,1.025,1.025,0,0,1-.521.053c-.031,0-.082.033-.1.065q-.605.944-1.205,1.891l-.386.608a.987.987,0,0,1,.256.671.963.963,0,1,1-.928-.977c.058,0,.1,0,.141-.06q.8-1.261,1.6-2.518c.009-.014.017-.028.027-.046a1,1,0,0,1-.2-.606.963.963,0,0,1,1.905-.181.928.928,0,0,1-.043.54.1.1,0,0,0,.023.131q.591.585,1.177,1.176a.094.094,0,0,0,.122.025.984.984,0,0,1,.472-.062.107.107,0,0,0,.085-.039q.81-1.415,1.615-2.833A.2.2,0,0,0,483.189,1248Zm.732-.313a.32.32,0,1,0-.323-.317A.329.329,0,0,0,483.921,1247.687Zm-5.135,1.924a.32.32,0,1,0-.321-.319A.328.328,0,0,0,478.786,1249.611Zm2.246,2.25a.32.32,0,1,0,.316-.323A.33.33,0,0,0,481.032,1251.861Zm-4.814,1.28a.32.32,0,1,0,.321.319A.328.328,0,0,0,476.218,1253.141Z" transform="translate(-475.256 -1246.386)" fill="#fff"/>
            </g>
          </g>
        </g>
      `,
  },
  "MyNumbers-CallerID-IOSIcon": {
    name: "MyNumbers-CallerID-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18322" data-name="Group 18322" transform="translate(-27 -189)">
          <g id="Group_18229" data-name="Group 18229" transform="translate(0 -165)">
            <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#e9f3ff"/>
          </g>
          <g id="Group_13895" data-name="Group 13895" transform="translate(33.679 195.394)">
            <g id="Group_13588" data-name="Group 13588" transform="translate(0 2.524)">
              <g id="Group_13097" data-name="Group 13097">
                <path id="Path_29943" data-name="Path 29943" d="M222.45,561.973v-.62a3.408,3.408,0,0,1,.217-.487,1.012,1.012,0,0,1,.676-.405h2.577c.007,0,.013.011.021.012a1.042,1.042,0,0,1,.88,1.051,6.634,6.634,0,0,0,.086,1.035c.072.421.189.835.287,1.252a1.011,1.011,0,0,1-.192.886q-.536.7-1.069,1.411a.146.146,0,0,0-.013.128,8.873,8.873,0,0,0,1.1,1.627,8.123,8.123,0,0,0,2.335,1.81.157.157,0,0,0,.138-.011c.454-.337.911-.67,1.354-1.022a1.061,1.061,0,0,1,1.032-.2,7.9,7.9,0,0,0,2.229.352.961.961,0,0,1,.827.42,2.731,2.731,0,0,1,.2.47v2.577a3.386,3.386,0,0,1-.217.487,1.012,1.012,0,0,1-.675.405h-.62c-.04-.006-.081-.014-.121-.019-.459-.052-.921-.085-1.376-.158a11.09,11.09,0,0,1-3.591-1.249,11.64,11.64,0,0,1-4.3-4.056,11.486,11.486,0,0,1-1.709-4.9c-.03-.266-.05-.532-.074-.8" transform="translate(-222.45 -560.461)" fill="#575757"/>
              </g>
            </g>
            <g id="Group_13894" data-name="Group 13894" transform="translate(7.352)">
              <path id="Path_29941" data-name="Path 29941" d="M132.6,7.3a3.826,3.826,0,0,1-1.773-.431.248.248,0,0,0-.3.016,1.963,1.963,0,0,1-1.3.323c-.074-.007-.143-.076-.214-.117a.714.714,0,0,1,.141-.2,1.8,1.8,0,0,0,.617-.7.225.225,0,0,0-.021-.2,3.645,3.645,0,0,1-.84-1.989,3.421,3.421,0,0,1,.286-1.816,3.624,3.624,0,0,1,6.18-.8,3.474,3.474,0,0,1,.657,3.194,3.52,3.52,0,0,1-2.976,2.662c-.174.03-.351.04-.462.052m-.085-.866a2.78,2.78,0,1,0-2.754-2.93,2.767,2.767,0,0,0,2.754,2.93" transform="translate(-128.89 0)" fill="#575757" stroke="#e9f3ff" stroke-width="0.35"/>
              <path id="Path_29942" data-name="Path 29942" d="M228.636,73.314c0-.349,0-.7,0-1.047,0-.124.03-.184.167-.175.4.024.8.009,1.191.066a1.2,1.2,0,0,1-.071,2.356c-.385.037-.774.026-1.161.041-.112,0-.125-.057-.125-.145,0-.365,0-.731,0-1.1Zm1.647-.067a.637.637,0,0,0-.2-.472.913.913,0,0,0-.735-.177c-.025,0-.065.07-.066.108-.005.421-.006.841,0,1.262a.143.143,0,0,0,.087.106.752.752,0,0,0,.917-.827" transform="translate(-225.318 -69.695)" fill="#575757" stroke="#e9f3ff" stroke-width="0.15"/>
              <path id="Path_29943-2" data-name="Path 29943" d="M191.581,73.311c0,.354,0,.709,0,1.063,0,.12-.032.174-.159.163a1.962,1.962,0,0,0-.315,0c-.12.009-.167-.029-.166-.157q.007-1.071,0-2.143c0-.12.035-.174.16-.165a2.013,2.013,0,0,0,.315,0c.136-.012.169.051.167.175-.006.354,0,.709,0,1.063Z" transform="translate(-188.879 -69.675)" fill="#575757" stroke="#e9f3ff" stroke-width="0.15"/>
            </g>
          </g>
        </g>
      `,
  },
  "MyNumbers-TelephoneNumber-IOSIcon": {
    name: "MyNumbers-TelephoneNumber-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18321" data-name="Group 18321" transform="translate(-27 -238)">
          <g id="Group_18317" data-name="Group 18317" transform="translate(0 -116)">
            <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#e9f3ff"/>
          </g>
          <g id="Group_14412" data-name="Group 14412" transform="translate(-174.477 -183.233)">
            <path id="Path_39279" data-name="Path 39279" d="M22.636,71.35h1.887c0-.378,0-.744,0-1.11a.657.657,0,0,1,.336-.6,1.088,1.088,0,0,1,.789-.149.788.788,0,0,1,.753.9c-.023.312,0,.627,0,.969.178,0,.333,0,.488,0a.839.839,0,0,1,.693.342,17.252,17.252,0,0,1,2.317,4.116,4.091,4.091,0,0,1,.258,1.508c-.011.486,0,.972,0,1.457A.941.941,0,0,1,29.141,79.8q-5.561,0-11.122,0a.928.928,0,0,1-1.005-1.013c0-.582-.032-1.165.009-1.744a4.217,4.217,0,0,1,.249-1.133,17.742,17.742,0,0,1,1.64-3.141c.182-.3.381-.585.56-.884a1,1,0,0,1,.971-.526c.323.013.324,0,.324-.317,0-.246,0-.493,0-.739a1.277,1.277,0,0,1,.007-.184.908.908,0,0,1,1.037-.635.794.794,0,0,1,.827.742c0,.368,0,.736,0,1.123m.972,7.521a2.821,2.821,0,1,0-2.844-2.82,2.835,2.835,0,0,0,2.844,2.82" transform="translate(191.897 362.495)" fill="#575757"/>
            <path id="Path_39280" data-name="Path 39280" d="M7.479.051a14.012,14.012,0,0,1,3.54.387,7.43,7.43,0,0,1,3.554,2.144,1.612,1.612,0,0,1,.44,1.12c.007.349.008.7,0,1.047a.9.9,0,0,1-.937.932c-.616.006-1.232.006-1.847,0a.913.913,0,0,1-.929-.884,2.59,2.59,0,0,0-1.822-2.5,5.388,5.388,0,0,0-4.048.045A2.6,2.6,0,0,0,3.8,4.237a2.62,2.62,0,0,0-.039.469.947.947,0,0,1-.977.974q-.9.015-1.806,0A.94.94,0,0,1,0,4.684c0-.315,0-.63,0-.944a1.784,1.784,0,0,1,.571-1.3A7.46,7.46,0,0,1,4.925.245C5.77.129,6.625.08,7.475,0l0,.051" transform="translate(207.967 428.169)" fill="#575757"/>
            <path id="Path_39281" data-name="Path 39281" d="M104.839,155.267a1.878,1.878,0,1,1-1.886,1.855,1.889,1.889,0,0,1,1.886-1.855" transform="translate(110.649 281.4)" fill="#575757"/>
          </g>
        </g>
      `,
  },
  "MyNumbers-tick-IOSIcon": {
    name: "MyNumbers-tick-IOSIcon",
    viewBox: "0 0 16.642 15.275",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <path id="Icon_feather-check" data-name="Icon feather-check" d="M17.752,11.377,9.894,23.07,5.3,17.6" transform="translate(-3.191 -9.295)" fill="none" stroke="#5576d1" stroke-linecap="round" stroke-linejoin="round" stroke-width="3"/>
      `,
  },
  "backToSettingsList-IOSIcon": {
    name: "backToSettingsList-IOSIcon",
    viewBox: "0 0 11.434 20",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M14.7,16.191,22.265,8.63a1.429,1.429,0,0,0-2.024-2.018l-8.574,8.568a1.426,1.426,0,0,0-.042,1.971l8.609,8.627a1.429,1.429,0,1,0,2.024-2.018Z" transform="translate(-11.251 -6.194)" fill="#5576d1"/>  
      `,
  },
  "rightFacingArrow-IOSIcon": {
    name: "rightFacingArrow-IOSIcon",
    viewBox: "0 0 7.066 11.57",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <path id="Icon_ionic-ios-arrow-back" data-name="Icon ionic-ios-arrow-back" d="M13.059,11.44,17.03,7.472a.75.75,0,0,0-1.062-1.059l-4.5,4.5a.749.749,0,0,0-.022,1.034l4.518,4.527a.75.75,0,0,0,1.062-1.059Z" transform="translate(17.751 17.189) rotate(180)" fill="#c5c5c7" stroke="#c5c5c7" stroke-width="1"/>
      `,
  },
  "plus-IOSIcon": {
    name: "plus-IOSIcon",
    viewBox: "0 0 20 19.992",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_13452" data-name="Group 13452" transform="translate(-320.41 -43.194)">
          <path id="Icon_awesome-plus" data-name="Icon awesome-plus" d="M18.571,10.1H12.14V3.678A1.428,1.428,0,0,0,10.712,2.25H9.283A1.428,1.428,0,0,0,7.855,3.678V10.1H1.428A1.428,1.428,0,0,0,0,11.535v1.428a1.428,1.428,0,0,0,1.428,1.428H7.855v6.427a1.428,1.428,0,0,0,1.428,1.424H10.71a1.428,1.428,0,0,0,1.428-1.428V14.39h6.433A1.428,1.428,0,0,0,20,12.962V11.535A1.428,1.428,0,0,0,18.571,10.1Z" transform="translate(320.41 40.944)" fill="#c0c5ca"/>
        </g>
      `,
  },
  "Phones-desktopExtension-IOSIcon": {
    name: "Phones-desktopExtension-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18374" data-name="Group 18374" transform="translate(-3399 -16083)">
          <g id="Group_18373" data-name="Group 18373" transform="translate(3372 15729)">
            <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#e9f3ff"/>
          </g>
          <path id="Union_75" data-name="Union 75" d="M14.775,14.158c-.623-.085-1.247-.163-1.869-.249-.816-.113-1.633-.233-2.449-.347-.388-.055-.778-.1-1.165-.157-.491-.068-.983-.139-1.474-.206-.357-.048-.714-.1-1.072-.134-.238-.026-.258-.041-.259-.281q-.007-1.71-.014-3.421,0-.849,0-1.7c0-.247.01-.258.252-.256l3.694.054c1.128,0,1.94,0,2.751,0,.5,0,1,.013,1.506.018.327,0,.327,0,.327.325q0,2.457,0,4.914c0,.419,0,.84,0,1.259,0,.147-.015.189-.106.189A.906.906,0,0,1,14.775,14.158Zm-9-1.237c-.479-.067-.958-.128-1.436-.193q-.88-.12-1.76-.242L.212,12.152C.032,12.127,0,12.1,0,11.915q0-1.951,0-3.9C0,7.86,0,7.7,0,7.549c0-.12.049-.175.171-.175.447,0,.894.006,1.341.008H2.99v.024H5.712c.243,0,.255.013.255.256q0,2.252,0,4.5c0,.205-.013.41-.016.616,0,.1-.04.145-.124.145A.338.338,0,0,1,5.775,12.921ZM0,6.781Q0,4.534,0,2.29c0-.236.005-.233.241-.266C.874,1.935,1.5,1.838,2.136,1.75c.465-.065.932-.12,1.4-.183.347-.047.694-.1,1.041-.148C5,1.359,5.431,1.3,5.861,1.247c.029,0,.08.039.092.07a.48.48,0,0,1,.014.162q0,2.628,0,5.257c0,.229-.017.247-.252.247H2.979v.006H.2C.022,6.991,0,6.967,0,6.781Zm6.793.187c-.05,0-.1,0-.15,0-.121,0-.177-.051-.173-.173.007-.21.017-.419.016-.629,0-.826-.013-1.652-.013-2.478,0-.607.012-1.214.014-1.821,0-.173,0-.347-.014-.519-.005-.112.026-.188.147-.206.158-.023.314-.051.472-.072C7.571,1.005,8.05.946,8.527.88,9.119.8,9.709.712,10.3.63,10.915.545,11.53.465,12.144.382l1.5-.21L14.879,0C15-.015,15,.069,15,.149c0,.507,0,1.013,0,1.52,0,.579-.01,1.158-.011,1.738q0,1.54,0,3.08a2.524,2.524,0,0,0,.006.274c.014.13-.032.182-.166.182-.47,0-.94.013-1.409.013-.895,0-1.789,0-2.683,0v.011Z" transform="translate(3405.5 16089.916)" fill="#00b6eb" stroke="rgba(0,0,0,0)" stroke-miterlimit="10" stroke-width="1"/>
        </g>
      `,
  },
  "Phones-webExtension-IOSIcon": {
    name: "Phones-webExtension-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18334" data-name="Group 18334" transform="translate(-27 -185.252)">
          <g id="Group_18269" data-name="Group 18269" transform="translate(0 -168.748)">
            <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#e9f3ff"/>
          </g>
          <g id="Group_18272" data-name="Group 18272" transform="translate(1359.5 409.83)">
            <path id="Path_39521" data-name="Path 39521" d="M195.859,137.439c1.541,0,3.082,0,4.622,0a.2.2,0,0,1,.143.114A7.38,7.38,0,0,1,201,140.34a7.526,7.526,0,0,1-5.929,6.928,7.446,7.446,0,0,1-1,.141c-.331.024-.665.005-1.029.005.095-.164.178-.311.265-.457q1.413-2.392,2.827-4.784a4.908,4.908,0,0,0,.682-1.467,3.346,3.346,0,0,0-.9-3.164.36.36,0,0,0-.094-.052l.038-.052" transform="translate(-1512.012 -350.506)" fill="#fbc31e"/>
            <path id="Path_39522" data-name="Path 39522" d="M53.27,6.744,52.258,4.985c-.413-.717-.818-1.438-1.244-2.146a.3.3,0,0,1,.047-.425A7.846,7.846,0,0,1,53,.9,7.037,7.037,0,0,1,55.574.061a7.5,7.5,0,0,1,5.65,1.531,7.35,7.35,0,0,1,1.961,2.327c.028.051.049.106.081.176H61.123c-1.486,0-2.973-.009-4.459,0a3.323,3.323,0,0,0-2.232.753,3.51,3.51,0,0,0-1.1,1.688c-.017.056-.033.112-.061.206" transform="translate(-1375.09 -218.071)" fill="#e43e31"/>
            <path id="Path_39523" data-name="Path 39523" d="M8.466,100.639l-1.483,2.5c-.289.488-.581.976-.865,1.467a.167.167,0,0,1-.213.093,7.46,7.46,0,0,1-5.265-4.3,7.546,7.546,0,0,1-.629-3.491,7.47,7.47,0,0,1,1.2-3.645c.111.187.215.358.314.531,1.024,1.777,2.028,3.567,3.077,5.329a3.242,3.242,0,0,0,3.1,1.64,6.143,6.143,0,0,0,.766-.125" transform="translate(-1326 -307.939)" fill="#269846"/>
            <path id="Path_39524" data-name="Path 39524" d="M137.363,139.8a2.5,2.5,0,1,1,2.632,2.483,2.5,2.5,0,0,1-2.632-2.483" transform="translate(-1458.362 -350.366)" fill="#317def"/>
          </g>
        </g>
      `,
  },
  "Phones-mobileExtension-IOSIcon": {
    name: "Phones-mobileExtension-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18335" data-name="Group 18335" transform="translate(-27 -242.752)">
          <g id="Group_18270" data-name="Group 18270" transform="translate(0 -111.248)">
            <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#e9f3ff"/>
          </g>
          <g id="Group_13993" data-name="Group 13993" transform="translate(-1.487 -7.64)">
            <g id="Group_12544" data-name="Group 12544" transform="translate(37.826 256)">
              <path id="Path_12162" data-name="Path 12162" d="M121.239,0H115.5a1.794,1.794,0,0,0-1.806,1.75V15.036a1.776,1.776,0,0,0,1.788,1.748l5.751-.006a1.765,1.765,0,0,0,1.774-1.748l.008-13.281A1.761,1.761,0,0,0,121.239,0Zm-3.7,1.049H119.2a.262.262,0,1,1,0,.524h-1.666a.262.262,0,1,1,0-.524Zm-1,.083a.278.278,0,0,1,.376,0,.259.259,0,0,1,0,.371.27.27,0,0,1-.187.077.273.273,0,0,1-.188-.077.262.262,0,0,1,0-.371Zm1.78,15.132a.785.785,0,1,1,.795-.785A.791.791,0,0,1,118.318,16.264Zm3.872-2.1h-7.569V2.491h7.569Z" transform="translate(-113.692 0)" fill="#5576d1"/>
            </g>
          </g>
        </g>
      `,
  },
  "Phones-headExtension-IOSIcon": {
    name: "Phones-headExtension-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18336" data-name="Group 18336" transform="translate(-27 -300.252)">
          <g id="Group_18271" data-name="Group 18271" transform="translate(0 -53.748)">
            <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#e9f3ff"/>
          </g>
          <g id="Group_14613" data-name="Group 14613" transform="translate(-174.401 -120.909)">
            <path id="Path_39279" data-name="Path 39279" d="M22.579,71.331h1.867c0-.374,0-.736,0-1.1a.651.651,0,0,1,.332-.589,1.077,1.077,0,0,1,.781-.148.78.78,0,0,1,.745.887c-.023.309,0,.621,0,.959.176,0,.329,0,.483,0a.831.831,0,0,1,.686.339,17.078,17.078,0,0,1,2.294,4.075,4.05,4.05,0,0,1,.255,1.493c-.011.481,0,.962,0,1.443a.931.931,0,0,1-1,1.007q-5.5,0-11.01,0a.919.919,0,0,1-1-1c0-.576-.031-1.154.009-1.726a4.174,4.174,0,0,1,.247-1.122,17.562,17.562,0,0,1,1.623-3.109c.18-.294.377-.579.554-.875a.994.994,0,0,1,.961-.52c.319.013.32,0,.32-.313,0-.244,0-.488,0-.731a1.264,1.264,0,0,1,.007-.183.9.9,0,0,1,1.027-.628.786.786,0,0,1,.819.735c0,.364,0,.729,0,1.111m.962,7.445a2.793,2.793,0,1,0-2.815-2.792,2.806,2.806,0,0,0,2.815,2.792" transform="translate(191.887 362.456)" fill="#00c268"/>
            <path id="Path_39280" data-name="Path 39280" d="M7.4.05a13.87,13.87,0,0,1,3.5.383,7.355,7.355,0,0,1,3.518,2.122,1.6,1.6,0,0,1,.436,1.108c.007.345.008.691,0,1.036a.894.894,0,0,1-.928.923c-.609.006-1.219.006-1.829,0a.9.9,0,0,1-.92-.875,2.564,2.564,0,0,0-1.8-2.477,5.334,5.334,0,0,0-4.007.044A2.574,2.574,0,0,0,3.758,4.194a2.594,2.594,0,0,0-.039.464.938.938,0,0,1-.967.964q-.894.015-1.788,0A.93.93,0,0,1,0,4.637c0-.312,0-.623,0-.935A1.766,1.766,0,0,1,.566,2.419,7.385,7.385,0,0,1,4.875.243C5.712.128,6.558.079,7.4,0l0,.05" transform="translate(207.967 428.169)" fill="#00c268"/>
            <path id="Path_39281" data-name="Path 39281" d="M104.82,155.267a1.859,1.859,0,1,1-1.867,1.836,1.87,1.87,0,0,1,1.867-1.836" transform="translate(110.592 281.314)" fill="#00c268"/>
          </g>
        </g>
      `,
  },
  "NumberBlocking-incomming-IOSIcon": {
    name: "NumberBlocking-incomming-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
          <g id="Group_18407" data-name="Group 18407" transform="translate(-27 -189)">
            <g id="Group_18229" data-name="Group 18229" transform="translate(0 -165)">
              <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#e9f3ff"/>
            </g>
            <g id="Group_13588" data-name="Group 13588" transform="translate(25.813 187.813)">
              <g id="Group_13097" data-name="Group 13097" transform="translate(9.188 9.188)">
                <path id="Path_29943" data-name="Path 29943" d="M222.45,561.891V561.3a3.219,3.219,0,0,1,.2-.461.957.957,0,0,1,.639-.383h2.437c.007,0,.013.011.02.012a.986.986,0,0,1,.832.994,6.278,6.278,0,0,0,.081.979c.068.4.179.79.272,1.184a.957.957,0,0,1-.182.838q-.507.666-1.011,1.335a.139.139,0,0,0-.012.121,8.4,8.4,0,0,0,1.04,1.539,7.682,7.682,0,0,0,2.209,1.712.148.148,0,0,0,.13-.01c.43-.319.862-.634,1.28-.967a1,1,0,0,1,.976-.192,7.47,7.47,0,0,0,2.108.333.908.908,0,0,1,.782.4,2.589,2.589,0,0,1,.194.444v2.437a3.207,3.207,0,0,1-.205.46.957.957,0,0,1-.639.383h-.586c-.038-.006-.076-.013-.115-.018-.434-.049-.871-.08-1.3-.15a10.49,10.49,0,0,1-3.4-1.181,11.008,11.008,0,0,1-4.071-3.836,10.863,10.863,0,0,1-1.617-4.63c-.028-.251-.047-.5-.07-.755" transform="translate(-222.45 -560.461)" fill="#00c267" stroke="rgba(0,0,0,0)" stroke-width="1"/>
                <path id="Path_29944" data-name="Path 29944" d="M398.568,561.386a2.987,2.987,0,0,1-.21.262q-1.421,1.427-2.847,2.85c-.031.031-.061.064-.108.113.058,0,.1.007.133.007h1.241a.353.353,0,0,1,.4.4v.572a.358.358,0,0,1-.409.411h-2.994a.688.688,0,0,1-.75-.716c-.01-1.022-.005-2.044,0-3.067a.345.345,0,0,1,.386-.372h.608a.348.348,0,0,1,.388.392q0,.621,0,1.241v.151c.054-.051.088-.081.12-.114q1.424-1.424,2.85-2.847a3,3,0,0,1,.262-.21h.17a2.75,2.75,0,0,1,.755.755Z" transform="translate(-386.568 -560.461)" fill="#00c267" stroke="rgba(0,0,0,0)" stroke-width="1"/>
              </g>
            </g>
          </g>
      `,
  },
  "NumberBlocking-outgoing-IOSIcon": {
    name: "NumberBlocking-outgoing-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18407" data-name="Group 18407" transform="translate(-27 -189)">
          <g id="Group_18229" data-name="Group 18229" transform="translate(0 -165)">
            <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#e9f3ff"/>
          </g>
          <g id="Group_13588" data-name="Group 13588" transform="translate(25.813 187.813)">
            <g id="Group_13097" data-name="Group 13097" transform="translate(9.188 9.188)">
              <path id="Path_29943" data-name="Path 29943" d="M222.45,561.891V561.3a3.219,3.219,0,0,1,.2-.461.957.957,0,0,1,.639-.383h2.437c.007,0,.013.011.02.012a.986.986,0,0,1,.832.994,6.278,6.278,0,0,0,.081.979c.068.4.179.79.272,1.184a.957.957,0,0,1-.182.838q-.507.666-1.011,1.335a.139.139,0,0,0-.012.121,8.4,8.4,0,0,0,1.04,1.539,7.682,7.682,0,0,0,2.209,1.712.148.148,0,0,0,.13-.01c.43-.319.862-.634,1.28-.967a1,1,0,0,1,.976-.192,7.47,7.47,0,0,0,2.108.333.908.908,0,0,1,.782.4,2.589,2.589,0,0,1,.194.444v2.437a3.207,3.207,0,0,1-.205.46.957.957,0,0,1-.639.383h-.586c-.038-.006-.076-.013-.115-.018-.434-.049-.871-.08-1.3-.15a10.49,10.49,0,0,1-3.4-1.181,11.008,11.008,0,0,1-4.071-3.836,10.863,10.863,0,0,1-1.617-4.63c-.028-.251-.047-.5-.07-.755" transform="translate(-222.45 -560.461)" fill="#5576d1" stroke="rgba(0,0,0,0)" stroke-width="1"/>
              <path id="Path_29944" data-name="Path 29944" d="M393.024,565.079a2.967,2.967,0,0,1,.21-.262q1.421-1.427,2.847-2.85c.031-.031.061-.064.108-.113-.058,0-.1-.007-.133-.007h-1.241a.353.353,0,0,1-.4-.4q0-.286,0-.572a.358.358,0,0,1,.409-.411h2.994a.688.688,0,0,1,.75.716c.01,1.022.005,2.044,0,3.067a.345.345,0,0,1-.386.372h-.609a.348.348,0,0,1-.388-.392q0-.621,0-1.241v-.151c-.054.051-.088.081-.12.114q-1.424,1.424-2.85,2.847a3,3,0,0,1-.262.21h-.17a2.751,2.751,0,0,1-.755-.755Z" transform="translate(-386.568 -560.461)" fill="#5576d1" stroke="rgba(0,0,0,0)" stroke-width="1"/>
            </g>
          </g>
        </g>
      `,
  },
  "NumberBlocking-missed-IOSIcon": {
    name: "NumberBlocking-missed-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
        <g id="Group_18582" data-name="Group 18582" transform="translate(-13117 -18787)">
          <g id="Group_18581" data-name="Group 18581" transform="translate(13090 18598)">
            <g id="Group_18229" data-name="Group 18229" transform="translate(0 -165)">
              <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#e9f3ff"/>
            </g>
          </g>
          <g id="Group_15670" data-name="Group 15670" transform="translate(13124.645 18795.193)">
            <g id="Group_13592" data-name="Group 13592" transform="translate(0 0)">
              <path id="Path_31104" data-name="Path 31104" d="M0,194.745a5.711,5.711,0,0,1,.792-1.166,6.484,6.484,0,0,1,2.786-1.814,8.225,8.225,0,0,1,6.233.277,5.95,5.95,0,0,1,2.61,2.2c.085.132.162.269.235.408a.383.383,0,0,1-.074.488q-.638.643-1.283,1.28a.388.388,0,0,1-.549.041Q9.675,195.683,8.6,194.9a.484.484,0,0,1-.187-.31c-.08-.368-.164-.737-.244-1.1a.474.474,0,0,0-.376-.384,5.936,5.936,0,0,0-2.9,0,.461.461,0,0,0-.361.368c-.082.368-.166.737-.243,1.1a.551.551,0,0,1-.228.353c-.691.5-1.379,1-2.07,1.5a.409.409,0,0,1-.625-.05c-.39-.388-.78-.774-1.168-1.167a2.363,2.363,0,0,1-.2-.272v-.2" transform="translate(0 -184.942)" fill="#e2797a"/>
              <path id="Path_31105" data-name="Path 31105" d="M57.285,3.689l2.05-2.047L58.14.452l.015-.034L61.922,0l-.416,3.758-1.2-1.2c-.059.056-.092.086-.124.118L57.861,4.988a.674.674,0,0,1-1.054.038l-3.831-3.4a.663.663,0,0,1-.248-.673.617.617,0,0,1,.45-.484.659.659,0,0,1,.663.165q.684.612,1.372,1.221l2.07,1.842" transform="translate(-50.965)" fill="#e2797a"/>
            </g>
          </g>
        </g>
      `,
  },
  "NumberBlocking-incommingOutgoing-IOSIcon": {
    name: "NumberBlocking-incommingOutgoing-IOSIcon",
    viewBox: "0 0 28 28",
    fill: "none",
    "stroke-width": "1",
    "stroke-linecap": "round",
    "stroke-linejoin": "round",
    stroke: "",
    icon: `
          <g id="Group_18337" data-name="Group 18337" transform="translate(-27 -189)">
            <g id="Group_18407" data-name="Group 18407">
              <g id="Group_18229" data-name="Group 18229" transform="translate(0 -165)">
                <rect id="Rectangle_8950" data-name="Rectangle 8950" width="28" height="28" rx="6" transform="translate(27 354)" fill="#e9f3ff"/>
              </g>
            </g>
            <g id="Group_13978" data-name="Group 13978" transform="translate(35 196.783)">
              <g id="Group_13856" data-name="Group 13856">
                <g id="Group_13097" data-name="Group 13097">
                  <path id="Path_29943" data-name="Path 29943" d="M222.45,561.768v-.536a2.969,2.969,0,0,1,.184-.421.856.856,0,0,1,.574-.35H225.4c.006,0,.011.01.018.011a.9.9,0,0,1,.747.909,5.836,5.836,0,0,0,.073.9c.061.364.16.722.244,1.083a.887.887,0,0,1-.163.766q-.456.609-.908,1.221a.129.129,0,0,0-.011.11,7.682,7.682,0,0,0,.934,1.407,6.918,6.918,0,0,0,1.984,1.565.131.131,0,0,0,.117-.01c.386-.291.774-.58,1.15-.884a.889.889,0,0,1,.877-.175,6.6,6.6,0,0,0,1.893.3.811.811,0,0,1,.7.364,2.382,2.382,0,0,1,.174.406v2.228a2.957,2.957,0,0,1-.184.421.856.856,0,0,1-.574.351h-.526c-.034-.005-.068-.012-.1-.016-.39-.045-.782-.073-1.169-.137a9.306,9.306,0,0,1-3.05-1.08,10.123,10.123,0,0,1-5.108-7.741c-.025-.23-.042-.46-.063-.691" transform="translate(-222.45 -558.997)" fill="#575757"/>
                  <path id="Path_29944" data-name="Path 29944" d="M393.024,563.613a2.021,2.021,0,0,1,.143-.179q.97-.974,1.943-1.945l.074-.077h-.938a.241.241,0,0,1-.276-.274q0-.2,0-.39a.244.244,0,0,1,.279-.281h2.044a.469.469,0,0,1,.512.489c.007.7,0,1.4,0,2.093a.235.235,0,0,1-.263.254h-.415a.238.238,0,0,1-.265-.267v-.947c-.037.035-.06.056-.082.078l-1.945,1.943a2.052,2.052,0,0,1-.179.143h-.116a1.877,1.877,0,0,1-.515-.515Z" transform="translate(-387.004 -560.461)" fill="#575757"/>
                </g>
              </g>
              <path id="Path_39277" data-name="Path 39277" d="M396.808,561.093a2.017,2.017,0,0,1-.143.179q-.97.974-1.943,1.945l-.074.077h.938a.241.241,0,0,1,.276.274q0,.2,0,.39a.245.245,0,0,1-.279.281h-2.044a.469.469,0,0,1-.512-.489c-.007-.7,0-1.4,0-2.093a.235.235,0,0,1,.263-.254h.415a.238.238,0,0,1,.265.267v.947c.037-.035.06-.056.082-.078L396,560.6a2.02,2.02,0,0,1,.179-.143h.116a1.878,1.878,0,0,1,.515.515Z" transform="translate(-384.808 -557.97)" fill="#575757"/>
            </g>
          </g>
      `,
  },
}