<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-musicOnHold-settings">
      <!-- <div class="dialer-flex">
        <template v-if="getIsMobile"> 
          <div class="d-flex align-items-center mb-3 calc-title-width-button">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Music on hold</h2>
          </div>
          <div class="w-100 d-flex justify-content-end">
            <div 
              v-b-tooltip :title="getUserPermissions.multi_moh?'':'Your package does not support this feature'"
              :class="{
                'for-disable': !getUserPermissions.multi_moh
              }"
            >
              <button 
                class="newButton ml-auto"
                :disabled="!getUserPermissions.multi_moh" 
                @click="$modal.show('AddPlayListModal',{ 
                  suggested_name: `music on hold ${response.playlists.length+1}` 
                })"
              >
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Add playlist</span>
              </button>
            </div>
          </div>
        </template>
        <div v-else class="w-100 d-flex justify-content-between align-items-center">
          <h2 class="dialer-settings-title">
            Music on hold
            <b-icon 
              icon="info-circle-fill" 
              class="cursor_pointer infoKbIcon md-mar-left" 
              @click="$modal.show('KbInfoModal',{ 
                kb: 'musicOnHold' 
              })" 
            />
          </h2>
          <div 
            v-b-tooltip :title="getUserPermissions.multi_moh?'':'Your package does not support this feature'"
            :class="{
              'for-disable': !getUserPermissions.multi_moh
            }"
          >
            <button 
              class="newButton"
              :disabled="!getUserPermissions.multi_moh" 
              @click="$modal.show('AddPlayListModal',{ 
                suggested_name: `music on hold ${response.playlists.length+1}` 
              })"
            >
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add playlist</span>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom IVR-settings-2nd-section">
        <span class="dialer-settings-intro newerTextVersion mb-0">
          Engage callers on hold with customised music. Create and manage the playlist assigned to your users, teams, or queues.
        </span>
        <div class="d-flex justify-content-end align-items-center w-50">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
            <b-dropdown no-caret class="dd-text-dark dd-with-checkbox">
              <template #button-content>
                <vb-svg 
                  class="filterDD-icon" 
                  name="filter-icon" 
                  width="19" 
                  height="18" 
                  viewBox="0 0 19 18" 
                  stroke-width="0" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
              </template>
              <b-dropdown-form>
                <b-form-checkbox 
                  class="makeCheckBoxesSquare fixVerticalAlign" 
                  :checked="selectAll" 
                  @change="filter.playlist.types = $event ? Object.values(types).map(i=>i.key) : []"
                >
                  <span class="ml-4">All</span>
                </b-form-checkbox>
                <b-form-checkbox 
                  v-for="type in types" 
                  :key="type.key" 
                  class="makeCheckBoxesSquare fixVerticalAlign" 
                  :checked="filter.playlist.types.includes(type.key)"
                  @change="
                    filter.playlist.types.includes(type.key) ? 
                      filter.playlist.types.splice(filter.playlist.types.indexOf(type.key),1) : 
                      filter.playlist.types.push(type.key)
                  "
                >
                  <span class="ml-4">{{ type.text }}</span>
                </b-form-checkbox>
              </b-dropdown-form>
            </b-dropdown>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
            <b-input placeholder="Search playlist" type="text" v-model.lazy="filter.playlist.search" />
          </div>
        </div>
      </div> -->

      <div class="settingsFirstSection">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Music on hold</h2>
          </div>
          <div 
            v-b-tooltip :title="getUserPermissions.multi_moh?'':'Your package does not support this feature'"
            :class="{
              'for-disable': !getUserPermissions.multi_moh
            }"
          >
            <!-- v-b-tooltip :title="getUserPermissions.multi_moh?'':'Your package does not support this feature'" -->
            <button 
              class="newButton ml-auto"
              :disabled="!getUserPermissions.multi_moh" 
              @click="$modal.show('AddPlayListModal',{ 
                suggested_name: `music on hold ${response.playlists.length+1}` 
              })"
            >
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add playlist</span>
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title settingsFirstSection-left mb-0 newer">
            Music on hold
            <b-icon 
              icon="info-circle-fill" 
              class="cursor_pointer infoKbIcon md-mar-left" 
              @click="$modal.show('KbInfoModal',{ 
                kb: 'musicOnHold' 
              })" 
            />
          </h2>
          <div class="settingsFirstSection-right">
            <div class="d-flex justify-content-end align-items-center mt-0">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
                <b-dropdown no-caret class="dd-text-dark dd-with-checkbox">
                  <template #button-content>
                    <vb-svg 
                      class="filterDD-icon" 
                      name="filter-icon" 
                      width="19" 
                      height="18" 
                      viewBox="0 0 19 18" 
                      stroke-width="0" 
                      stroke="" 
                      fill="none" 
                      stroke-linecap="round" 
                      stroke-linejoin="round" 
                    />
                  </template>
                  <b-dropdown-form>
                    <b-form-checkbox 
                      class="makeCheckBoxesSquare fixVerticalAlign" 
                      :checked="selectAll" 
                      @change="filter.playlist.types = $event ? Object.values(types).map(i=>i.key) : []"
                    >
                      <span class="ml-4">All</span>
                    </b-form-checkbox>
                    <b-form-checkbox 
                      v-for="type in types" 
                      :key="type.key" 
                      class="makeCheckBoxesSquare fixVerticalAlign" 
                      :checked="filter.playlist.types.includes(type.key)"
                      @change="
                        filter.playlist.types.includes(type.key) ? 
                          filter.playlist.types.splice(filter.playlist.types.indexOf(type.key),1) : 
                          filter.playlist.types.push(type.key)
                      "
                    >
                      <span class="ml-4">{{ type.text }}</span>
                    </b-form-checkbox>
                  </b-dropdown-form>
                </b-dropdown>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox">
                <b-input placeholder="Search playlist" type="text" v-model.lazy="filter.playlist.search" />
              </div>
              <div 
                v-b-tooltip :title="getUserPermissions.multi_moh?'':'Your package does not support this feature'"
                :class="{
                  'for-disable': !getUserPermissions.multi_moh
                }"
              >
                <!-- v-b-tooltip :title="getUserPermissions.multi_moh?'':'Your package does not support this feature'" -->
                <button 
                  class="newButton"
                  :disabled="!getUserPermissions.multi_moh" 
                  @click="$modal.show('AddPlayListModal',{ 
                    suggested_name: `music on hold ${response.playlists.length+1}` 
                  })"
                >
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Add playlist</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">Manage calls with virtual waiting areas. Apply fair distribution, music/messages for callers.</span>
      </div>

      <vb-table 
        class="latestTableDesign-withBlackBorders-again hasSoundFiles" 
        textNoRecord=" " 
        :isListEmpty="filterPlaylists.length==0" 
        :listLength="filterPlaylists.length" 
        :loading="api.playlists.send"
      >
        <template slot="header">
          <tr v-if="!$store.getters.getIsMobile">
            <th class="dialer-has-sort wd-30">Playlist</th>
            <th class="dialer-has-sort w-50"></th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <MOHItem 
            v-for="(playlist, index) in filterPlaylists"
            :key="playlist.id"
            :playlist="playlist"
            v-show="index >= start && index <= end"
            :class="{
              'for-disable-disable': !getUserPermissions.multi_moh
            }" 
            @clicked="!getUserPermissions.multi_moh ? 
              appNotify({ 
                message: 'Your package does not support this feature', 
                type: 'danger' 
              }) : 
              $modal.show('MainMusicOnHoldFileModal', { 
                id: playlist.id, 
                section: playlist.type==types.custom.key ? 'admin' : 'system', 
                name: playlist.label,
                account: $store.getters.getCurrentUser.account,
                type: playlist.order,
              })
            "
          />
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
            </td>
            <td>
              <div class="d-flex align-items-center w-fit-content">
                <div class="latestShimmerDesign flex-1" style="height:64px;width:250px"></div>
                <div class="latestShimmerDesign flex-1 ml-2" style="height:21px;width:40px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center">
                <div class="latestShimmerDesign" style="height:20px;width:-webkit-fill-available;"></div>
              </div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
      <EditPlaylistModal :modalName="'MainMusicOnHoldFileModal'" @close="fetchPlaylistDebounce()" />
      <AddPlayListModal @interface="fetchPlaylistDebounce()" />
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { VOIP_API } from "../../utils";
import EditPlaylistModal from "../Modals/EditPlaylistModal.vue";
import AddPlayListModal from "../Modals/AddPlayListModal.vue";
import MOHItem from '../Lists/MOHItem.vue';
const types = {
  library: {
    key: 'system',
    text: 'Library',
  },
  custom: {
    key: 'custom',
    text: 'Custom',
  },
}
export default {
  name: "MusicOnHoldSettings",
  components: {
    AddPlayListModal,
    EditPlaylistModal,
    MOHItem,
  },
  data() {
    return {
      api: {
        playlists: this.$helperFunction.apiInstance(),
      },
      response: {
        playlists: [],
      },
      filter: {
        playlist: {
          search: '',
          types: Object.values(types).map(i=>i.key),
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
      'getIsMobile'
    ]),
    types(){ return types },
    selectAll(){ return this.filter.playlist.types.length == Object.values(this.types).length },
    filterPlaylists(){ 
      const search = this.filter.playlist.search.trim().toLowerCase()
      const filter_types = this.filter.playlist.types
      return this.response.playlists
        .filter(i=>filter_types.includes(i.type) && `${i.label}`.trim().toLowerCase().includes(search))
        .sort(item=>item.default==1?-1:1)
    },
  },
  methods: {
    fetchPlaylistDebounce: _.debounce(function(){
      this.fetchPlaylist()
    }, 2*1000),
    async fetchPlaylist() {
      try {
        this.api.playlists.send = true;
        const { data: res } = await VOIP_API.endpoints.music_on_hold.list({
          fid: 12,
        })
        this.response.playlists = res?.musiconholds?.data ?? [];
        
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.playlists.send = false;
      }
    },
  },
  activated() {
    this.fetchPlaylist();
  },
};
</script>
