var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'whiteBoxWithBorderInsideGreyBox-row flex-column': _vm.location == 'whiteBoxWithBorderInsideGreyBox',
      'dialer-box alt': _vm.location != 'whiteBoxWithBorderInsideGreyBox'
    }
  }, [_vm.location == 'whiteBoxWithBorderInsideGreyBox' ? [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center"
  }, [_vm._m(0), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.custom_moh
    },
    attrs: {
      "title": _vm.getUserPermissions.custom_moh ? '' : 'Your package does not support this feature'
    }
  }, [_vm.api.call_route.send ? [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  })] : _c('b-form-checkbox', {
    ref: "music_on_hold_enable",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.custom_moh || _vm.api.set_to_default.send,
      "checked": _vm.conditions.music_on_hold_enable,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        $event ? _vm.conditions.music_on_hold_enable = true : _vm.setToDefault();
      }
    }
  })], 2)]), _vm.conditions.music_on_hold_enable ? _c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center mt-20px"
  }, [_c('span', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v(_vm._s(_vm.playlist.name || (_vm.defaultPlaylist ? _vm.defaultPlaylist.label : '')))]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.custom_moh
    },
    attrs: {
      "title": _vm.getUserPermissions.custom_moh ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.custom_moh || _vm.api.set_to_default.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(_vm.modalNames.music_on_hold, {
          id: _vm.playlist.id || '',
          name: _vm.playlist.name || '',
          section: 'user',
          account: _vm.accountcode
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change playlist")])], 1)])]) : _vm._e()] : [_c('div', {
    staticClass: "dialer-flex"
  }, [_c('div', {
    staticClass: "dialer-box-header"
  }, [_vm._v("Custom music on hold")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.custom_moh
    },
    attrs: {
      "title": _vm.getUserPermissions.custom_moh ? '' : 'Your package does not support this feature'
    }
  }, [_vm.api.call_route.send ? [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  })] : _c('b-form-checkbox', {
    ref: "music_on_hold_enable",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.custom_moh || _vm.api.set_to_default.send,
      "checked": _vm.conditions.music_on_hold_enable,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        $event ? _vm.conditions.music_on_hold_enable = true : _vm.setToDefault();
      }
    }
  })], 2)]), _c('div', {
    staticClass: "dialer-flex"
  }, [_c('div', {
    staticClass: "dialer-audio-options w-100"
  }, [_c('div', {
    staticClass: "dialer-box-text"
  }, [_vm._v("Elevate caller experience with music on hold")]), _vm.conditions.music_on_hold_enable ? _c('div', {
    staticClass: "dialer-audio-options-actions d-flex justify-content-between w-100"
  }, [_c('span', {
    staticClass: "sm-mar-right"
  }, [_vm._v(_vm._s(_vm.playlist.name || (_vm.defaultPlaylist ? _vm.defaultPlaylist.label : '')))]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.custom_moh
    },
    attrs: {
      "title": _vm.getUserPermissions.custom_moh ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.custom_moh || _vm.api.set_to_default.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(_vm.modalNames.music_on_hold, {
          id: _vm.playlist.id || '',
          name: _vm.playlist.name || '',
          section: 'user',
          account: _vm.accountcode
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change playlist")])], 1)])]) : _vm._e()])])], _c('ChangePlaylistMusicOnHoldModal', {
    attrs: {
      "modalName": _vm.modalNames.music_on_hold
    },
    on: {
      "updated": function ($event) {
        return _vm.callRouteSetting();
      }
    }
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Custom music on hold")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Elevate caller experience with music on hold")])]);

}]

export { render, staticRenderFns }