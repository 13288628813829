var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 TopUpProductsModal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-form lg-mar-bottom"
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_vm.conditions.top_up_balance_products ? _c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Topup balance products")]) : _vm.conditions.sms_balance_products ? _c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("SMS balance products")]) : _c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Topup call products")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button",
      "disabled": !!_vm.api.add_topup.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm.conditions.top_up_balance_products ? _c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Topup balance products")]) : _vm.conditions.sms_balance_products ? _c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("SMS balance products")]) : _c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Topup call products")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "TabsDesignWithIcon-container mt-20px"
  }, _vm._l(_vm.tabs, function (allTab) {
    return _c('div', {
      key: allTab.id,
      staticClass: "TabsDesignWithIcon-item",
      class: {
        'active': _vm.selected.tab == allTab.id
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = allTab.id;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": allTab.icon,
        "height": "38px",
        "width": "38px"
      }
    }), _c('span', {
      staticClass: "TabsDesignWithIcon-item-textPart"
    }, [_vm._v(_vm._s(allTab.text))])], 1);
  }), 0), _vm.selected.tab == _vm.tabs.add_topup.id ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main-18px text-left"
  }, [_vm._v("Topup the following amount")]), _c('b-form-group', {
    staticClass: "whiteBoxWithBorderInsideGreyBox withoutBorder p-4 mt-16px topUpCheckbox-container",
    attrs: {
      "id": "phone-numbers-radio"
    }
  }, [_vm.api.fetch_topup_products.send ? _vm._l(8, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "latestShimmerDesign m-2",
      staticStyle: {
        "height": "40px",
        "width": "calc(25% - 24px)"
      }
    });
  }) : _vm._l(_vm.response.products, function (product) {
    return _c('b-form-radio', {
      key: product.id,
      staticClass: "topUpCheckbox",
      attrs: {
        "disabled": !!_vm.api.add_topup.send || _vm.api.fetch_topup_products.send,
        "name": `${product.real_id}`,
        "value": product.real_id
      },
      model: {
        value: _vm.selected.product_id,
        callback: function ($$v) {
          _vm.$set(_vm.selected, "product_id", $$v);
        },
        expression: "selected.product_id"
      }
    }, [_c('span', {
      staticClass: "topUpCheckbox-textPart"
    }, [_vm._v("£" + _vm._s(product.price))])]);
  })], 2), _c('div', {
    staticClass: "w-100 d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newBlueButton",
    on: {
      "click": function ($event) {
        return _vm.addTopup(_vm.selected.product_id);
      }
    }
  }, [_vm._v("Add top up")])])], 1)] : _vm._e(), _vm.selected.tab == _vm.tabs.history.id ? [_c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('vb-table', {
    staticClass: "TopUpProductsBox latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.response.history.length == 0,
      "listLength": _vm.response.history.length,
      "loading": _vm.api.fetch_topup_history.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.response.history, function (credit, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: credit.id
          }, [_c('td', [_vm._v(_vm._s(_vm._f("to_fixed")(credit.amount)))]), _c('td', [_vm._v(_vm._s(credit.type))]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-right"
          }, [_vm._v(_vm._s(credit.description))])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(3, function (n) {
          return _c('tr', {
            key: n.id
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign mb-2",
            staticStyle: {
              "height": "21px",
              "width": "100%"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign mb-2",
            staticStyle: {
              "height": "21px",
              "width": "100%"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign mb-2",
            staticStyle: {
              "height": "21px",
              "width": "100%"
            }
          })])]);
        });
      },
      proxy: true
    }], null, false, 3322279825)
  }, [_c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Amount")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Type")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }, [_c('span', [_vm._v("Description")])])])])], 1)] : _vm._e()], 2)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }