<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto" 
      name="AddLocationModal" 
      @closed="onClose" 
      @before-open="beforeOpen"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide('AddLocationModal')">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$modal.hide('AddLocationModal')" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add Location</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">
        

        <form class="dialer-form " @submit.prevent="addLocation">
          <template v-if="data.flag=='purchased'">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="d-flex align-items-center">
                  <div class="headingInsideTable">User Home Address</div>
                </div>
                <div class="position-relative">
                  <b-form-checkbox  
                    class="dialer-switch IosSwitch" 
                    switch :disabled="api_sent.add"
                    v-model="is_user_home_address" @change="$event?(forms.add_location.callerId=''):''" 
                  />
                </div>
              </div>
            </div>
            <template v-if="is_user_home_address">
              <template v-if="add_caller_id_screen == 'number'">
                <div class="basicWhiteIOScard mb-16px">
                  <div class="basicWhiteIOScard-item">
                    <div class="mobileInputIOS InputIosV2 ">
                      <label>Caller ID</label>
                      <div>
                        <input id="caller-id-input-field" class="w-100" type="text" onkeypress="return /([0-9])/i.test(event.key)" maxlength="12" v-model="forms.adding_caller_id.number" :disabled="api_sent.check" />
                      </div>
                      <template v-if="submitted.check">
                        <p v-if="!$v.forms.adding_caller_id.number.required" class="text text-danger animated bounceIntop mb-0">
                          <span>* Number is required</span>
                        </p>
                        <p v-else-if="!$v.forms.adding_caller_id.number.minLength || !$v.forms.adding_caller_id.number.maxLength" class="text text-danger animated bounceIntop mb-0">
                          <span>* Number is should be between 8 to 12</span>
                        </p>
                      </template>
                    </div>
                  </div>
                </div>
                <button id="check-button" class="IosFullWidthButton forDone mt-16px mb-16px" type="button" @click.stop.self="checkValidate()">
                  <vb-spinner v-if="api_sent.check" />
                  <template v-else>Check</template>
                </button>
              </template>
              <template v-if="add_caller_id_screen == 'pin'">
                <div class="basicWhiteIOScard mb-16px">
                  <div class="basicWhiteIOScard-item">
                    <b-icon id="back-icon" icon="arrow-left" @click="add_caller_id_screen = 'number'" />
                  </div>
                  <div class="basicWhiteIOScard-item" >
                    <div class="mobileInputIOS InputIosV2">
                      <label>Enter Pin Code</label>
                      
                        <input 
                          type="text"
                          maxlength="6"
                          oninput="this.value = this.value.replace(/[^0-9]/gi, '')"
                          v-model="forms.verify_caller_id_pin.pin" 
                          :disabled="api_sent.verify"
                        />
                      
                      <template v-if="submitted.verify">
                        <span>*</span>
                        <p v-if="!$v.forms.verify_caller_id_pin.pin.required">Pin is Required</p>
                        <p v-if="!$v.forms.verify_caller_id_pin.pin.minLength">Pin should be 6 digit</p>
                      </template>
                    </div>
                  </div>
                </div>
                <button id="verify-button" class="IosFullWidthButton forDone mt-16px mb-16px" type="button" @click.stop.self="validatePin()">
                  <vb-spinner v-if="api_sent.verify" />
                  <template v-else>Verify</template>
                </button>
              </template>
              <template v-if="add_caller_id_screen == 'verified'">
                <div class="basicWhiteIOScard mb-16px">
                  <div class="basicWhiteIOScard-item">
                    <div class="mobileInputIOS InputIosV2">
                      <label>Caller ID</label>
                      <div>
                        <input id="caller-id-input-field" class="w-100" type="text" onkeypress="return /([0-9])/i.test(event.key)" maxlength="12" :value="forms.adding_caller_id.number" :disabled="true" />
                      </div>
                      <template v-if="submitted.check">
                        <p v-if="!$v.forms.adding_caller_id.number.required" class="text text-danger animated bounceIntop mb-0">
                          <span>* Number is required</span>
                        </p>
                        <p v-else-if="!$v.forms.adding_caller_id.number.minLength || !$v.forms.adding_caller_id.number.maxLength" class="text text-danger animated bounceIntop mb-0">
                          <span>* Number is should be between 8 to 12</span>
                        </p>
                      </template>
                    </div>
                  </div>
                </div>
              </template>
            </template>
            <template v-else>
              <div class="basicWhiteIOScard mb-16px">
                <div class="basicWhiteIOScard-item flex-column">
                  <div class="w-100 d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <div class="headingInsideTable">Caller ID/ DID</div>
                    </div>
                    <div class="dd-updatedIos">
                      <vb-select id="caller-id-did" listClass="select-caller-id-options" class="w-100 custom-dd-select" 
                        :selectDdDesign="1" :options="callerIdsOptions"
                        :defaultSelectedText="'Select Extensions'"  @change="forms.add_location.callerId=$event"  
                        :selected="forms.add_location.callerId" :disabled="api_sent.add || api_sent.list" 
                      >
                        <vb-icon slot="right-icon" icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                      </vb-select>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-if="submitted.add">
              <p v-if="!$v.forms.add_location.callerId.required" class="text text-danger animated bounceIntop mb-0">
                <span>* Extensions is required</span>
              </p>
            </template>
          </template>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item flex-column">
              <div class="w-100 d-flex justify-content-between">
                <div class="d-flex align-items-center">
                  <div class="headingInsideTable">Country</div>
                </div>
                <div class="dd-updatedIos">
                  <vb-select id="select-country" listClass="select-country-options" 
                    :selected="forms.add_location.country" @change="forms.add_location.country=$event"
                    class="w-100 custom-dd-select" :defaultSelectedText="'Select Country'" 
                    :options="countriesOptions" :selectDdDesign="1"
                  >
                    <vb-icon slot="right-icon" icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </vb-select>
                </div>
              </div>
              <div class="w-100 d-flex flex-column">
                <template v-if="submitted.add">
                  <p v-if="!$v.forms.add_location.country.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Country is required</span>
                  </p>
                </template>
              </div>
            </div>
            <div v-if="forms.add_location.country === 'GB'" class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Enter Address Manually</div>
              <div class="position-relative">
                <b-form-checkbox  
                  class="dialer-switch IosSwitch"  switch
                  v-model="is_manual_address" :disabled="api_sent.add" 
                />
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>First Name</label>
                <div>
                  <input id="first-name" class="w-100" type="text" v-model="forms.add_location.forename" :disabled="api_sent.add" />
                </div>
                <template v-if="submitted.add">
                  <p v-if="!$v.forms.add_location.forename.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* First Name is required</span>
                  </p>
                </template>
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Last Name</label>
                <div>
                  <input id="last-name" class="w-100" type="text" v-model="forms.add_location.surname" :disabled="api_sent.add" />
                </div>
                <template v-if="submitted.add">
                  <p v-if="!$v.forms.add_location.surname.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Last Name is required</span>
                  </p>
                </template>
              </div>
            </div>
          </div>
          <template v-if="is_manual_address">
            <div class="basicWhiteIOScard mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Street</label>
                  <div>
                    <input id="manual-street-input-field" class="w-100" type="text" v-model="forms.manual_address.street" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.street.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Street is required</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>City</label>
                  <div>
                    <input id="manual-city-input-field" class="w-100" type="text" v-model="forms.manual_address.city" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.city.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* City is required</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Postal Code</label>
                  <div>
                    <input id="manual-post-code-input-field" class="w-100" type="text" v-model="forms.manual_address.postal_code" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal Code is required</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>House Number</label>
                  <div>
                    <input id="manual-house-number-input-field" class="w-100" type="text" v-model="forms.manual_address.house_name" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.manual_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* House Name is required</span>
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <template v-else>
            <div class="basicWhiteIOScard mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS iconOnRight InputIosV2">
                  <label>Postal Code</label>
                  <div class="position-relative">
                    <input id="auto-postal-code-input-field" class="w-100" type="text" v-model="forms.uk_address.postal_code" :disabled="api_sent.add" />
                    <div class="mobileInputIOS-icon">
                      <vb-spinner v-if="api_sent.addresses" variant="primary" />
                      <template v-else>
                        <b-icon v-if="api_status.addresses == 'success'" icon="check2" variant="success" />
                        <b-icon v-if="api_status.addresses == 'fail'" icon="x" variant="danger" />
                      </template>
                    </div>
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.uk_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal Code is required</span>
                    </p>
                  </template>
                  <template v-if="$v.forms.uk_address.postal_code.required">
                    <p v-if="!$v.forms.uk_address.postal_code.valid" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal Code is not valid</span>
                    </p>
                  </template>
                  <template v-if="!isEmpty(errors.addresses)">
                    <p v-if="errors.addresses.message" class="text text-danger animated bounceIntop mb-0">
                      <span>* {{ errors.addresses.message }}</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Address</label>
                  <div>
                    <b-select id="auto-select-address-select" v-model="forms.uk_address.address" :disabled="api_sent.add">
                      <option value="">Select Address</option>
                      <option v-for="address in response.address.addresses" :key="address" :value="address">{{ address.replace(/,/g,'').replace(/\s+/g,' ').trim() }}</option>
                    </b-select>
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.uk_address.address.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Address is required</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>House Number</label>
                  <div>
                    <input id="auto-house-number-input-field" class="w-100" type="text" v-model="forms.uk_address.house_name" :disabled="api_sent.add" />
                  </div>
                  <template v-if="submitted.add">
                    <p v-if="!$v.forms.uk_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* House Number is required</span>
                    </p>
                  </template>
                </div>
              </div>
            </div>
          </template>
          <button class="IosFullWidthButton forDone mt-16px mb-16px" type="submit" :disabled="api_sent.add">
            <vb-spinner v-if="api_sent.add" />
            <template v-else>Done</template>
          </button>





          
        </form>

      </div>
    </modal>
  </div>
</template>

<script>
import { required, minLength, maxLength,  } from "vuelidate/lib/validators";
import { $fn, LOGGER, VOIP_API } from "../../../../utils";
export default {
  name: "AddLocationModal",
  data() {
    return {
      forms: {
        add_location: {
          callerId: "",
          country: "",
          surname: "",
          forename: "",
          reset() {
            this.callerId = "";
            this.country = "";
            this.surname = "";
            this.forename = "";
          },
        },
        manual_address: {
          postal_code: "",
          city: "",
          street: "",
          house_name: "",
          reset() {
            this.postal_code = "";
            this.city = "";
            this.street = "";
            this.house_name = "";
          },
        },
        uk_address: {
          postal_code: "",
          house_name: "",
          address: "",
          reset() {
            this.postal_code = "";
            this.house_name = "";
            this.address = "";
          },
        },
        adding_caller_id: {
          number: "",
          reset() {
            this.number = "";
          },
        },
        verify_caller_id_pin: {
          pin: "",
          reset() {
            this.pin = "";
          },
        },
      },
      selectedCallerid: '',
      selectedCallerCountry: '',
      selectedCallerCountry2: '',
      showCallerIDList : false,
      is_manual_address: true,
      is_user_home_address: false,
      add_caller_id_screen: "number",
      response: {
        list: {
          callerid: [],
          countries: [],
          extensionCount: 0,
          extensions: {},
        },
        address: {
          addresses: [],
          latitude: 0,
          longitude: 0,
        },
      },
      submitted: {
        add: false,
        check: false,
        verify: false,
        reset(){
          this.add = false
          this.check = false
          this.verify = false
        },
      },
      api_sent: {
        add: false,
        list: false,
        addresses: false,
        check: false,
        verify: false,
      },
      api_status: {
        addresses: "",
        reset(){
          this.addresses = ""
        },
      },
      errors: {
        addresses: {},
      },
      data: {
        flag: '',
        reset(){
          this.flag=''
        },
      },
    };
  },
  inject:['isEmpty','appNotify'],
  computed: {
    addLocationForm() {
      const address = this.forms?.uk_address?.address?.split?.(",") ?? [];
      return {
        street_address: this.is_manual_address ? this.forms.manual_address.street : address?.[0]?.trim?.() ?? '',
        locality: this.is_manual_address ? "" : "",
        county: this.is_manual_address ? "" : address?.[6]?.trim?.() ?? '',
        is_manual: this.is_manual_address,
        town: this.is_manual_address ? this.forms.manual_address.city : address?.[5]?.trim?.() ?? '',
        city: this.is_manual_address ? this.forms.manual_address.city : address?.[5]?.trim?.() ?? '',
        postcode: this.is_manual_address ? this.forms.manual_address.postal_code : this.forms.uk_address.postal_code,
        country: this.forms.add_location.country,
        house: this.is_manual_address ? this.forms.manual_address.house_name : this.forms.uk_address.house_name,
        caller: this.forms.add_location.callerId,
        forename: this.forms.add_location.forename,
        surname: this.forms.add_location.surname,
        flag: this.data.flag || "purchased",
      };
    },
    callerIdsOptions(){ 
      return this.response.list.callerid.map((callerid) => {
        return {
          text: ` ${callerid} `,
          value: callerid,
        }
      }) 
    },
    countriesOptions(){
      return this.response.list.countries.map((country) => {
        return {
          text: ` ${country.Name} `,
          value: country.Code2,
        }
      })
    },
  },
  validations: {
    forms: {
      add_location: {
        callerId: {
          required,
        },
        country: {
          required,
        },
        surname: {
          required,
        },
        forename: {
          required,
        },
      },
      manual_address: {
        postal_code: {
          required,
        },
        city: {
          required,
        },
        street: {
          required,
        },
        house_name: {
          required,
        },
      },
      uk_address: {
        postal_code: {
          required,
          valid: function(...args){ return $fn.isValidUKPostcode(...args) },
        },
        house_name: {
          required,
        },
        address: {
          required,
        },
      },
      adding_caller_id: {
        number: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(12)
        },
      },
      verify_caller_id_pin: {
        pin: {
          required,
          minLength: minLength(6),
        },
      },
    },
  },
  watch: {
    "forms.add_location.country"(country) {
      this.is_manual_address = country !== "GB";
    },
    "forms.uk_address.postal_code"(postal_code) {
      this.resetresponse("address");
      this.forms.uk_address.address=''
      if(!this.$v.forms.uk_address.postal_code.valid) return;
      this.getAddress(postal_code);
    },
  },
  methods: {
    getAddress(value) {
      let vm = this;
      this.api_sent.addresses = true;
      VOIP_API.endpoints.location.getAddress(value).then(({ data }) => {
        vm.response.address = data;
      }).then(() => {
        this.api_status.addresses = "success";
        this.errors.addresses = {};
      }).catch(() => {
        this.errors.addresses = {
          message: "Invalid postcode",
        };
        this.api_status.addresses = "fail";
      }).finally(() => {
        this.api_sent.addresses = false;
      });
    },
    resetresponse(type) {
      if (type === "address") {
        this.response.address.addresses = [];
        this.response.address.latitude = 0;
        this.response.address.longitude = 0;
      }
    },
    beforeOpen(event) {
      this.data.flag=event.params?.flag || 'purchased'
      this.forms.add_location.callerId=event.params?.callerId || ''
      this.load();
    },
    load() {
      if (this.api_sent.list) return;
      this.api_sent.list = true;
      VOIP_API.endpoints.location.listcountriesandextensions().then(({ data }) => {
        this.response.list = data;
      }).catch((error) => {
        LOGGER.danger(error);
      }).finally(() => {
        this.api_sent.list = false;
      });
    },
    addLocation() {
      this.submitted.add = true;
      this.$v.$touch();
      if (this.$v.forms.add_location.$invalid || this.api_sent.add || (this.is_manual_address ? this.$v.forms.manual_address.$invalid : this.$v.forms.uk_address.$invalid)) return;
      this.api_sent.add = true;
      VOIP_API.endpoints.location.add({
        ...this.addLocationForm,
        accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
        uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
      }).then(({ data: { data: list } }) => {
        this.$modal.hide("AddLocationModal");
        this.appNotify({
          message: "Request for adding menu is pending",
          type: "success",
        })
        this.forms.add_location.reset();
        this.forms.manual_address.reset();
        this.forms.uk_address.reset();
        this.$emit("latest-list", {
          list,
        });
      }).catch((ex) => {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        this.submitted.add = false;
        this.api_sent.add = false;
      });
    },
    checkValidate() {
      let vm = this;
      vm.submitted.check=true
      if (this.$v.forms.adding_caller_id.$invalid || this.api_sent.check) return;
      this.api_sent.check = true;
      VOIP_API.endpoints.callerid.addCallerId({
        accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
        number: this.forms.adding_caller_id.number,
      }).then((res) => {
        LOGGER.log("run", res);
        this.add_caller_id_screen = "pin";
      }).catch((ex) => {
        LOGGER.danger({ ex });
        // if(ex.response_error?.status_code==409){
        //   vm.add_caller_id_screen = "pin";
        // }
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        vm.api_sent.check = false;
        vm.submitted.check = false;
      });
    },
    validatePin() {
      let vm = this;
      vm.submitted.verify=true
      if (this.$v.forms.verify_caller_id_pin.$invalid || this.$v.forms.adding_caller_id.$invalid || this.api_sent.verify) return;
      this.api_sent.verify = true;
      VOIP_API.endpoints.callerid.verifypin({
        accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
        pincode: this.forms.verify_caller_id_pin.pin,
        number: this.forms.adding_caller_id.number,
      }).then((res) => {
        LOGGER.log("run", res);
        vm.add_caller_id_screen = "verified";
        vm.forms.add_location.callerId=vm.forms.adding_caller_id.number
      }).catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        vm.api_sent.verify = false;
        vm.submitted.verify = false;
      });
    },
    onClose() {
      this.$emit("close");
      this.forms.add_location.reset();
      this.forms.manual_address.reset();
      this.forms.uk_address.reset();
      this.forms.adding_caller_id.reset();
      this.forms.verify_caller_id_pin.reset();
      this.api_status.reset();
      this.is_manual_address = true;
      this.is_user_home_address = false;
      this.add_caller_id_screen = "number";
      this.submitted.reset();
    },
  },
};
</script>
