<template>
  <modal
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"   
    :name="modalName" 
    @before-close="onBeforeClose()" 
    @before-open="onBeforeOpen($event)"
  >
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Extensions</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div class="innerModalIos">
      <div class="mobileInputIOS iconOnLeft mt-32px">
        <b-input type="text" placeholder="Search Pickup groups" v-model.lazy="filter.extensions.search" />
        <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
      </div>

      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div 
          v-for="(extension, index) in filterExtensions" 
          :key="index"
          class="basicWhiteIOScard-item d-flex justify-content-between" 
          @click="$modal.show('EditExtension',{ data: extension })"
        >
          <div class="headingInsideCard mr-8px">{{ extension | extensionLabel }}</div>
          
          <div class="d-flex align-items-center">
            <div class="textInsideCard textAlignRight onRightSide mr-8px">{{ extension.type }}</div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
        </div>
        <vb-no-record v-if="!filterExtensions.length" :text="api.extensions.send?'':'There is no Tags'" :design="3">
          <vb-loading v-if="api.extensions.send" class="text-center w-100" slot="loading" />
        </vb-no-record>
      </div>
      
      <EditExtensionModal @load="fetchExtensions()" />
    </div>
  </modal>
</template>

<script>
import EditExtensionModal from "./EditExtension.vue";
import { VOIP_API } from "@/utils/index";
import { mapGetters } from 'vuex';
export default {
  name: 'ExtensionsModal',
  components: {
    EditExtensionModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'ExtensionsModal',
    },
  },
  data() {
    return {
      response: {
        extensions: [],
      },
      api: {
        extensions: this.$helperFunction.apiInstance({  }),
      },
      filter: {
        extensions: {
          search: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    filterExtensions(){
      const search = this.filter.extensions.search.trim().toLowerCase()
      return this.response.extensions.filter(item=>`${item.extn} ${item.type} ${item.label} ${item.voipuser ? item.voipuser.display_name : ''}`.trim().toLowerCase().includes(search))
    },
  },
  filters: {
    extensionLabel(value) { return value?.voipuser?.display_name ?? value?.label ?? '-' },
  },
  methods: {
    onBeforeOpen(){
      this.fetchExtensions();
    },
    onBeforeClose(){
      this.api.extensions.reset()
      this.response.extensions = []
      this.filter.extensions.search = ''
    },
    fetchExtensions() {
      let vm = this
      if(vm.api.extensions.send) return;
      vm.api.extensions.send = true;
      VOIP_API.endpoints.extensions.getallextensions()
      .then(({ data: { data: extensions }, })=>{
        vm.response.extensions = extensions;
      })
      .finally(()=>{
        vm.api.extensions.send = false;
      });
    },
  },
}
</script>

<style>

</style>