<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.edit">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.topupsetting.send ? '' : conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Topup Setting</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <form class="dialer-form lg-mar-bottom" @submit.prevent="updateTopupSetting()">

            <b-alert class="mt-32px" :show="!!api.topupsetting.error_message" variant="danger">{{api.topupsetting.error_message}}</b-alert>
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Voip Balance</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    :disabled="api.topupsetting.send" 
                    v-model="forms.topupsetting.voip_enable"
                  />
                </div>
              </div>
              <template v-if="forms.topupsetting.voip_enable">
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>My balance hit threshold of </label>
                    <input 
                      class="w-100" 
                      type="number" 
                      v-model="forms.topupsetting.voip_balance_limit" 
                      :disabled="api.topupsetting.send"
                    />
                    <p 
                      v-if="(forms.topupsetting.submitted && $v.forms.topupsetting.voip_balance_limit.$invalid) || api.topupsetting.validation_errors.voip_balance_limit" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.topupsetting.voip_balance_limit.required">* Credit Limit field is required</span>
                      <span 
                        v-if="!$v.forms.topupsetting.voip_balance_limit.maxValue"
                      >
                        * Credit Limit maximum {{ $v.forms.topupsetting.voip_balance_limit.$params.maxValue.max }} only
                      </span>
                      <span v-for="em in api.topupsetting.validation_errors.voip_balance_limit" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Topup the following amount</label>
                    <input 
                      class="w-100" 
                      type="number" 
                      v-model="forms.topupsetting.voip_amount" 
                      :disabled="api.topupsetting.send"
                    />
                    <p 
                      v-if="(forms.topupsetting.submitted && $v.forms.topupsetting.voip_amount.$invalid) || api.topupsetting.validation_errors.voip_amount" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.topupsetting.voip_amount.required">* Amount to topup field is required</span>
                      <span v-for="em in api.topupsetting.validation_errors.voip_amount" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
              </template>
            </div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Voip Balance</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    :disabled="api.topupsetting.send" 
                    v-model="forms.topupsetting.whmcs_enable"
                  />
                </div>
              </div>
              <template v-if="forms.topupsetting.whmcs_enable">
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>My balance hit threshold of </label>
                    <input 
                      class="w-100" 
                      type="number" 
                      v-model="forms.topupsetting.whmcs_balance_limit" 
                      :disabled="api.topupsetting.send"
                    />
                    <p 
                      v-if="(forms.topupsetting.submitted && $v.forms.topupsetting.whmcs_balance_limit.$invalid) || api.topupsetting.validation_errors.whmcs_balance_limit" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.topupsetting.whmcs_balance_limit.required">* Credit Limit field is required</span>
                      <span 
                        v-if="!$v.forms.topupsetting.whmcs_balance_limit.maxValue"
                      >
                        * Credit Limit maximum {{ $v.forms.topupsetting.whmcs_balance_limit.$params.maxValue.max }} only
                      </span>
                      <span v-for="em in api.topupsetting.validation_errors.whmcs_balance_limit" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Topup the following amount</label>
                    <input 
                      class="w-100" 
                      type="number" 
                      v-model="forms.topupsetting.whmcs_amount" 
                      :disabled="api.topupsetting.send"
                    />
                    <p 
                      v-if="(forms.topupsetting.submitted && $v.forms.topupsetting.whmcs_amount.$invalid) || api.topupsetting.validation_errors.whmcs_amount" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.topupsetting.whmcs_amount.required">* Amount to topup field is required</span>
                      <span v-for="em in api.topupsetting.validation_errors.whmcs_amount" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
              </template>
            </div>

            <button class="IosFullWidthButton forDone mb-16px" :disabled="api.topupsetting.send">
              <vb-spinner v-if="api.topupsetting.send" />
              <template v-else>Update</template>
            </button>

          </form>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Topup Setting</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">

            <div class="basicWhiteIOScard mt-32px mb-16px">
              
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Voip</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.topupsetting.voip_enable || 'no' }}</div>
              </div>

              <template v-if="response.topupsetting.voip_enable=='yes'">
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideTable">Voip Balance Limit</div>
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.topupsetting.voip_balance_limit }}</div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideTable">Voip Amount</div>
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.topupsetting.voip_amount }}</div>
                </div>
              </template>

            </div>

            <div class="basicWhiteIOScard mt-32px mb-16px">

              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">WHMCS</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.topupsetting.whmcs_enable || 'no' }}</div>
              </div>

              <template v-if="response.topupsetting.whmcs_enable=='yes'">
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideTable">WHMCS Balance Limit</div>
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.topupsetting.whmcs_balance_limit }}</div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideTable">WHMCS Amount</div>
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.topupsetting.whmcs_amount }}</div>
                </div>
              </template>

            </div>
            
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { maxValue, requiredIf } from 'vuelidate/lib/validators';
import { mapGetters } from 'vuex';

let screens = {
  edit: 'edit',
  view: 'view',
}

export default {
  name: "TopupSettingModal",
  inject: ['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'TopupSetting',
    }
  },
  data() {
    return {
      forms: {
        topupsetting: this.$helperFunction.formInstance({
          data: {
            whmcs_balance_limit: '',
            whmcs_amount: '',
            whmcs_enable: false,
            voip_balance_limit: '',
            voip_amount: '',
            voip_enable: false,
          },
        }),
      },
      api: {
        topupsetting: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        get_topupsetting: this.$helperFunction.apiInstance({  }),
      },
      conditions: {
        screen: screens.view
      },
      response: {
        topupsetting: {},
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    screens(){ return screens },
  },
  validations: {
    forms: {
      topupsetting: {
        whmcs_balance_limit: {
          required: requiredIf(function(){
            return this.forms.topupsetting.whmcs_enable
          }),
          maxValue: maxValue(500),
        },
        whmcs_amount: {
          required: requiredIf(function(){
            return this.forms.topupsetting.whmcs_enable
          })
        },
        voip_balance_limit: {
          required: requiredIf(function(){
            return this.forms.topupsetting.voip_enable
          }),
          maxValue: maxValue(500),
        },
        voip_amount: {
          required: requiredIf(function(){
            return this.forms.topupsetting.voip_enable
          })
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchTopupSetting()
    },
    onBeforeClose(){
      this.conditions.screen=screens.view
      this.response.topupsetting={}
      this.forms.topupsetting.reset()
      this.api.topupsetting.reset()
      this.api.get_topupsetting.reset()
    },
    updateTopupSetting(){
      let vm = this
      vm.forms.topupsetting.submitted=true
      vm.$v.forms.topupsetting.$touch()
      if(vm.$v.forms.topupsetting.$invalid || vm.api.topupsetting.send) return;
      vm.api.topupsetting.send=true
      vm.api.topupsetting.error_message=''
      vm.api.topupsetting.validation_errors={}
      VOIP_API.endpoints.topup.updatesetting({
        whmcs_balance_limit: vm.forms.topupsetting.whmcs_balance_limit,
        whmcs_amount: vm.forms.topupsetting.whmcs_amount,
        whmcs_enable: vm.forms.topupsetting.whmcs_enable,
        voip_balance_limit: vm.forms.topupsetting.voip_balance_limit,
        voip_amount: vm.forms.topupsetting.voip_amount,
        voip_enable: vm.forms.topupsetting.voip_enable,
        uid: vm.getCurrentUser?.uid,
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully updated!',
          type: 'success',
        })
        vm.conditions.screen=screens.view
        vm.fetchTopupSetting()
      })
      .catch((ex)=>{
        vm.api.topupsetting.validation_errors=ex.own_errors || {}
        vm.api.topupsetting.error_message=ex.own_message || ''
      })
      .finally(()=>{
        vm.forms.topupsetting.submitted=false
        vm.api.topupsetting.send=false
      })
    },
    fetchTopupSetting(){
      let vm = this
      if(vm.api.get_topupsetting.send) return;
      vm.api.get_topupsetting.send=true
      VOIP_API.endpoints.topup.getsetting({
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data: setting })=>{
        vm.response.topupsetting=setting ?? {}
        if(setting){
          vm.forms.topupsetting.whmcs_balance_limit = parseInt(setting.whmcs_balance_limit || '') || 0
          vm.forms.topupsetting.whmcs_amount = parseInt(setting.whmcs_amount || '') || 0
          vm.forms.topupsetting.whmcs_enable = setting.whmcs_enable=='yes'
          vm.forms.topupsetting.voip_balance_limit = parseInt(setting.voip_balance_limit || '') || 0
          vm.forms.topupsetting.voip_amount = parseInt(setting.voip_amount || '') || 0
          vm.forms.topupsetting.voip_enable = setting.voip_enable=='yes'
        }
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.get_topupsetting.send=false
      })
    },
  },
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
