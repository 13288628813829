<template>
  <div>
    <b-form @submit.prevent="updateAccountRetention()">
      <b-alert :show="!!api.update_account_retention.error_message" variant="danger">{{api.update_account_retention.error_message}}</b-alert>
      <b-form-spinbutton 
        v-model="forms.update_account_retention.c_mino_days" 
        :disabled="api.update_account_retention.send || api.fetch_account_retention.send" 
        wrap 
        :min="$v.forms.update_account_retention.c_mino_days.$params.minValue.min" 
        :max="$v.forms.update_account_retention.c_mino_days.$params.maxValue.max" 
      />
      <p v-if="(forms.update_account_retention.submitted && $v.forms.update_account_retention.c_mino_days.$invalid) || api.update_account_retention.validation_errors.c_mino_time" class="text text-danger animated bounceIntop">
        <span v-if="!$v.forms.update_account_retention.c_mino_days.required">Mino Days is Required</span>
        <span v-else-if="!$v.forms.update_account_retention.c_mino_days.numeric">Mino Days should be numeric</span>
        <span v-else-if="!$v.forms.update_account_retention.c_mino_days.maxValue">Mino Days can be maximum {{$v.forms.update_account_retention.c_mino_days.$params.maxValue.max}}</span>
        <span v-else-if="!$v.forms.update_account_retention.c_mino_days.minValue">Mino Days can be minimum {{$v.forms.update_account_retention.c_mino_days.$params.maxValue.max}}</span>
        <span v-for="(em,i) in api.update_account_retention.validation_errors.c_mino_time" :key="i">{{em}}</span>
      </p>
      <b-form-spinbutton 
        v-model="forms.update_account_retention.mino_days" 
        :disabled="api.update_account_retention.send || api.fetch_account_retention.send" 
        wrap 
        :min="$v.forms.update_account_retention.mino_days.$params.minValue.min" 
        :max="$v.forms.update_account_retention.mino_days.$params.maxValue.max" 
      />
      <p v-if="(forms.update_account_retention.submitted && $v.forms.update_account_retention.mino_days.$invalid) || api.update_account_retention.validation_errors.mino_time" class="text text-danger animated bounceIntop">
        <span v-if="!$v.forms.update_account_retention.mino_days.required">Mino Days is Required</span>
        <span v-else-if="!$v.forms.update_account_retention.mino_days.numeric">Mino Days should be numeric</span>
        <span v-else-if="!$v.forms.update_account_retention.mino_days.maxValue">Mino Days can be maximum {{$v.forms.update_account_retention.mino_days.$params.maxValue.max}}</span>
        <span v-else-if="!$v.forms.update_account_retention.mino_days.minValue">Mino Days can be minimum {{$v.forms.update_account_retention.mino_days.$params.maxValue.max}}</span>
        <span v-for="(em,i) in api.update_account_retention.validation_errors.mino_time" :key="i">{{em}}</span>
      </p>
      <b-button type="submit">
        Submit
      </b-button>
    </b-form>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import required from 'vuelidate/lib/validators/required';
import numeric from 'vuelidate/lib/validators/numeric';
import maxValue from 'vuelidate/lib/validators/maxValue';
import minValue from 'vuelidate/lib/validators/minValue';
import { mapGetters } from 'vuex';
export default {
  name: 'AccountRetentionWidget',
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
  },
  data() {
    return {
      response: {
        call_recordings: {},
        account_retention: {},
      },
      api: {
        fetch_account_retention: this.$helperFunction.apiInstance(),
        update_account_retention: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        }),
      },
      forms: {
        update_account_retention: this.$helperFunction.formInstance({
          data: {
            c_mino_days: 0,
            mino_days: 0,
          },
        }),
      },
    }
  },
  inject: [
    'appNotify',
  ],
  validations: {
    forms: {
      update_account_retention: {
        c_mino_days: {
          required: required,
          numeric: numeric,
          maxValue: maxValue(500),
          minValue: minValue(1),
        },
        mino_days: {
          required: required,
          numeric: numeric,
          maxValue: maxValue(500),
          minValue: minValue(1),
        },
      },
    },
  },
  methods: {
    async fetchAccountRetention() {
      if(this.api.fetch_account_retention.send) return;
      try {
        this.api.fetch_account_retention.send=true
        const { data: account_retention } = await VOIP_API.endpoints.users.getAccountRetention(this.getCurrentUser.uid)
        this.response.account_retention=account_retention
        this.forms.update_account_retention.c_mino_days=account_retention?.c_mino_time ?? 0
        this.forms.update_account_retention.mino_days=account_retention?.mino_time ?? 0
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_account_retention.send=false
      }
    },
    async updateAccountRetention() {
      this.forms.update_account_retention.submitted=true
      this.$v.forms.update_account_retention.$touch()
      if(this.api.update_account_retention.send || this.$v.forms.update_account_retention.$invalid) return;
      try {
        this.api.update_account_retention.send=true
        this.api.update_account_retention.error_message=''
        this.api.update_account_retention.validation_errors={}
        await VOIP_API.endpoints.users.updateAccountRetention(this.getCurrentUser.uid,{
          c_mino_time: this.forms.update_account_retention.c_mino_days,
          mino_time: this.forms.update_account_retention.mino_days,
        })
        this.fetchAccountRetention()
      } catch (ex) {
        this.api.update_account_retention.error_message=ex.own_message
        this.api.update_account_retention.validation_errors=ex.own_errors
      } finally {
        this.forms.update_account_retention.submitted=false
        this.api.update_account_retention.send=false
      }
    },
  },
  mounted(){
    this.fetchAccountRetention()
  },
}
</script>

<style>

</style>