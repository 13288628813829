<template>
  <modal class="dialer_animation right_side_popup new-call-info-modal new-call-info-modal-newer v2" :name="modalName" @before-open="onBeforeOpen($event)" @before-close="onBeforeClose()">
    <div class="panel-inner panel-inner-callInfo new-call-info-inner">
      <div class="d-flex flex-wrap align-items-center justify-content-between py-0 mb-0 dialer-edit-header">
        <h2 class="dialer-edit-title newer mb-0">Audio transcript</h2>
        <div>
          <button type="button" class="newCloseButton" @click="$modal.hide(modalName)"> 
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <AudioTranscriptDummy class="mt-20px"
        v-if="data.sound_file && data.uniq_id"
        :soundFile="data.sound_file"
        :uniqueID="data.uniq_id"
      />
    </div>
  </modal>
</template>

<script>
import AudioTranscriptDummy from '../widgets/AudioTranscriptDummy.vue'
export default {
  components: { AudioTranscriptDummy },
  name: 'RecordingTranscriptModal',
  props: {
    modalName: {
      type: String,
      default: 'RecordingTranscript',
    },
  },
  data() {
    return {
      data: {
        uniq_id: '',
        sound_file: '',
      }
    }
  },
  methods: {
    onBeforeOpen(event) {
      this.data.uniq_id=event?.params?.uniq_id ?? ''
      this.data.sound_file=event?.params?.sound_file ?? ''
    },
    onBeforeClose(){
      this.data.uniq_id=''
      this.data.sound_file=''
    },
  },
}
</script>

<style>

</style>