<template>
  <modal 
    class="center-zoomIn-transition v2 m-w-500" 
    height="auto" 
    :name="modalName"
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <template v-if="selected.screen==screens.new_email">
      <div class="w-100">
        <div class="d-flex align-items-center justify-content-between w-100">
          <div class="d-flex flex-column">
            <h2 class="dialer-edit-title">Change email</h2>
          </div>
          <button @click="$modal.hide(modalName)" class="newCloseButton">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px w-100">
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <label>Current email address set on the account</label>
            <div class="currentEmailText">{{userEmail}}</div>
          </div>
          <b-alert :show="!!api.add_new_email.error_message" variant="danger">{{ api.add_new_email.error_message }}</b-alert>
          <div class="whiteBGinputWithGreyRoundedBorder w-100 mt-20px">
            <label>Enter the new email address to be set on this account</label>
            <input class="w-100" type="text" v-model="forms.add_new_email.email" :disabled="api.add_new_email.send" />
            <p v-if="(forms.add_new_email.submitted && $v.forms.add_new_email.email.$invalid) || api.add_new_email.validation_errors.email" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.add_new_email.email.required">Email is required</span>
              <span v-else-if="!$v.forms.add_new_email.email.email">Email should be valid email</span>
              <span v-for="(em,i) in api.add_new_email.validation_errors.email" :key="i">{{em}}</span>
            </p>
          </div>
        </div>
        <div class="w-100 mt-20px">
          <button class="fullWidthDoneButton" type="button" :disabled="api.add_new_email.send" @click="addNewEmail()">
            <span>Update</span>
          </button>
        </div>
      </div>
    </template>
    <template v-if="selected.screen==screens.both_email_confirm_pincode">
      <div class="w-100">
        <div class="d-flex align-items-start justify-content-between w-100">
          <div class="d-flex flex-column pr-3">
            <h2 class="dialer-edit-title">Verify your email</h2>
            <p>
              A pin has been sent to your new email please enter the pin below 
              to verify your email.
            </p>
          </div>
          <button @click="$modal.hide(modalName)" class="dialer-button dialer-button-delete">Close</button>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px w-100">
          <b-alert :show="!!api.verify_both_email.error_message" variant="danger">{{ api.verify_both_email.error_message }}</b-alert>
          <div class="whiteBGinputWithGreyRoundedBorder w-100 mt-20px">
            <p>{{voipuser | get_property('user.email')}}</p>
            <label>Enter the 6-digit code:</label>
            <vb-pin-code @change="forms.verify_both_email.prev_pincode=$event;" :disabled="api.verify_both_email.send" />
            <p v-if="(forms.verify_both_email.submitted && $v.forms.verify_both_email.prev_pincode.$invalid) || api.verify_both_email.validation_errors.cpincode" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.verify_both_email.prev_pincode.required">Pincode is required</span>
              <span v-else-if="!$v.forms.verify_both_email.prev_pincode.valid">Pincode should be 6 digits</span>
              <span v-for="(em,i) in api.verify_both_email.validation_errors.cpincode" :key="i">{{em}}</span>
            </p>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder w-100 mt-20px">
            <p>{{ forms.add_new_email.email }}</p>
            <label>Enter the 6-digit code:</label>
            <vb-pin-code @change="forms.verify_both_email.pincode=$event;" :disabled="api.verify_both_email.send" />
            <p v-if="(forms.verify_both_email.submitted && $v.forms.verify_both_email.pincode.$invalid) || api.verify_both_email.validation_errors.npincode" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.verify_both_email.pincode.required">Pincode is required</span>
              <span v-else-if="!$v.forms.verify_both_email.pincode.valid">Pincode should be 6 digits</span>
              <span v-for="(em,i) in api.verify_both_email.validation_errors.npincode" :key="i">{{em}}</span>
            </p>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-end mt-20px">
          <button class="fullWidthDoneButton" type="button" :disabled="api.verify_both_email.send" @click="verifyBothEmail()">
            <span>Save</span>
          </button>
        </div>
      </div>
    </template>
    <template v-if="selected.screen==screens.congratulation">
      <div class="w-100">
        <div class="thumbCongrtulations-container">
          <img :src="require('@/assets/images/thumbCongrtulations.svg')" />
          <h6>Congratulations!</h6>
          <p>
            Email change process has been completed
            Stay connected, stay productive!
          </p>
        </div>
      </div>
    </template>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import required from 'vuelidate/lib/validators/required';
import email from 'vuelidate/lib/validators/email';
const screens = {
  new_email: 'new_email',
  both_email_confirm_pincode: 'both_email_confirm_pincode',
  congratulation: 'congratulation',
}
export default {
  name: 'ChangeEmailModal',
  props: {
    modalName: {
      type: String,
      default: 'ChangeEmail',
    },
  },
  data() {
    return {
      forms: {
        add_new_email: this.$helperFunction.formInstance({
          data: {
            email: '',
          }
        }),
        verify_both_email: this.$helperFunction.formInstance({
          data: {
            pincode: '',
            prev_pincode: '',
          }
        }),
      },
      api: {
        add_new_email: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        }),
        verify_both_email: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        }),
      },
      data: {
        accountcode: '',
      },
      selected: {
        screen: screens.new_email
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getCurrentUser',
    ]),
    voipuser(){ return this.getVoipUsersAlises[this.data.accountcode] },
    screens(){ return screens },
    userEmail(){
      const email = this.voipuser?.user?.email
      const email2 = this.voipuser?.user?.email2
      const ac_user = this.voipuser?.user?.ac_user
      const current_user_email = this.getCurrentUser.email
      return ((email2 || email) === current_user_email ? ac_user : (ac_user != email) ? ac_user : email2 || email || ac_user) ?? ''
    },
  },
  validations: {
    forms: {
      add_new_email: {
        email: {
          required: required,
          email: email,
        },
      },
      verify_both_email: {
        pincode: {
          required: required,
          valid(value){ return value.length==6 },
        },
        prev_pincode: {
          required: required,
          valid(value){ return value.length==6 },
        },
      },
    },
  },
  watch: {
    "selected.screen"(screen){
      let vm = this
      if(screen==screens.congratulation){
        setTimeout(()=>{
          vm.$modal.hide(vm.modalName)
        },3*1000)
      }
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode=event?.params?.accountcode ?? ''
    },
    onBeforeClose(){
      this.data.accountcode=''
      this.selected.screen=screens.new_email
      this.forms.add_new_email.reset()
      this.forms.verify_both_email.reset()
      this.api.add_new_email.reset()
      this.api.verify_both_email.reset()
    },
    async addNewEmail(){
      this.forms.add_new_email.submitted=true
      this.$v.forms.add_new_email.$touch()
      if(this.api.add_new_email.send || this.$v.forms.add_new_email.$invalid) return;
      try {
        this.api.add_new_email.send=true
        this.api.add_new_email.error_message=''
        this.api.add_new_email.validation_errors={}
        await VOIP_API.endpoints.users.changeEmail({
          accountcode: this.data.accountcode,
          uid: this.getCurrentUser.uid,
          email: this.userEmail,
          email_to_update: this.forms.add_new_email.email,
          // prev_pincode: this.forms.verify_prev_email.pincode,
          // pincode: this.forms.verify_new_email.pincode,
        })
        this.selected.screen=screens.both_email_confirm_pincode
        this.appNotify({
          message: 'Successfully Send',
          type: 'success',
        })
      } catch (ex) {
        this.api.add_new_email.error_message=ex.own_message
        this.api.add_new_email.validation_errors=ex.own_errors
      } finally {
        this.api.add_new_email.send=false
      }
    },
    async verifyBothEmail(){
      this.forms.verify_both_email.submitted=true
      this.$v.forms.verify_both_email.$touch()
      if(this.api.verify_both_email.send || this.$v.forms.verify_both_email.$invalid) return;
      try {
        this.api.verify_both_email.send=true
        this.api.verify_both_email.error_message=''
        this.api.verify_both_email.validation_errors={}
        const { data } = await VOIP_API.endpoints.users.changeEmail({
          accountcode: this.data.accountcode,
          uid: this.getCurrentUser.uid,
          email: this.userEmail,
          email_to_update: this.forms.add_new_email.email,
          npincode: this.forms.verify_both_email.pincode,
          cpincode: this.forms.verify_both_email.prev_pincode,
        })
        if(data?.code==422) throw new Error('invalid pincode')
        this.selected.screen=screens.congratulation
        this.$emit('updated')
        if(this.data.accountcode==this.getCurrentUser.account) {
          this.$store.state.common.user.email = this.forms.add_new_email.email;
        }
        this.appNotify({
          message: 'Successfully Send',
          type: 'success',
        })
      } catch (ex) {
        this.api.verify_both_email.error_message=ex.own_message || ex.message || ''
        this.api.verify_both_email.validation_errors=ex.own_errors ?? {}
      } finally {
        this.api.verify_both_email.send=false
      }
    },
  },
}
</script>

<style>

</style>