<template>
  <modal 
    :name="modalName" 
    class="center-zoomIn-transition v2 m-w-500 addTagsModal-centerVersion onlyTagsModal scrollable"
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()" 
  >
    <div class="w-100">
      <div class="dialer-edit-header position-relative d-block pb-0">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h2 class="dialer-settings-title newer mb-0">Add tags</h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            <a v-else class="newCloseButton" type="button" @click="$modal.hide(modalName)" >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <div class="vm--modal-text mb-0">
          Add tags name and details.
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 w-100">
        <b-form @submit.prevent="create()" class="dialer-form w-100">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
            <label>Tag name</label>
            <b-input type="text" class="w-100" v-model="forms.create.tag" :disabled="api.create.send" placeholder="Enter name" />
            <p v-if="forms.create.submitted && $v.forms.create.tag.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
              <span v-if="!$v.forms.create.tag.required">* Name is required</span>
            </p>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder mt-20px">
            <label>Tag color</label>
            <div class="createSpace-selectColor-container">
              <template v-if="api.fetch_color_combination.send">
                <div v-for="n in 32" :key="n.id" class="createSpace-selectColor-item-container">
                  <div class="latestShimmerDesign" style="height: 30px;width: 30px;"></div>
                </div>
              </template>
              <template v-else>
                <div v-for="color in colorComboOptions" :key="color.id" class="createSpace-selectColor-item-container">
                  <div 
                    @click="api.create.send ? '' : forms.create.color_combo_id = color.value;" 
                    class="createSpace-selectColor-item"
                    :class="{
                      'selected': forms.create.color_combo_id==color.value,
                      'TransparentSelected': color.color == 'rgba(0,0,0,0)',
                    }"
                    :style="`background-color:${color.color};`">
                    <vb-icon v-if="color.color == 'rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
                  </div>
                </div>
              </template>
            </div>
            <p v-if="forms.create.submitted && $v.forms.create.color_combo_id.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
              <span v-if="!$v.forms.create.color_combo_id.required">* Tag color is required</span>
            </p>
          </div>

          <!-- <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
                <label>Tag name</label>
                <b-input type="text" class="w-100" v-model="forms.create.tag" :disabled="api.create.send" placeholder="Enter name" />
                <p v-if="forms.create.submitted && $v.forms.create.tag.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.create.tag.required">* Name is required</span>
                </p>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row pb-1 flex-column align-items-start b-0">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
                <label>Select color combination</label>
                <vb-select
                  :defaultSelectedText="'Select Color Combination'"
                  class="colorCombinationDD"
                  :disabled="api.create.send"
                  :selected="forms.create.color_combo_id"
                  @change="forms.create.color_combo_id = $event;"
                  :options="colorComboOptions"
                />
                
                <p v-if="forms.create.submitted && $v.forms.create.color_combo_id.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.create.color_combo_id.required">* Select color is required</span>
                </p>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row pt-0">
              <p class="d-flex align-items-start latestGreyBox-descriptionText my-0">
                <b-icon icon="info-circle-fill" class="mt-1"/>
                <span class="md-mar-left">Type your tag name and paste or pick your relevant color code to define your tag.</span>
              </p>
            </div>
          </div> -->
          <div class="mt-4">
            <button class="fullWidthDoneButton" variant="success" :disabled="api.create.send">
              <vb-spinner v-if="api.create.send" />
              <template v-else>Add</template>
            </button>
          </div>
        </b-form>
      </div>










      <!--<div class="">
        <!~~ grayBox dialer-box ~~>
        <div class="dialer-tab-content">
          <!~~ <h2 class="dialer-edit-title">Add Tag</h2> ~~>
          <b-form @submit.prevent="create()" class="dialer-form">
            <div class="dialer-input-field input-consistent-inner-shadows w-100">
              <label>Tag Name</label>
              <b-input type="text" v-model="forms.create.tag" :disabled="api.create.send" placeholder="Enter name" />
              <p v-if="forms.create.submitted && $v.forms.create.tag.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                <span v-if="!$v.forms.create.tag.required">* Name is required</span>
              </p>
            </div>
            <div class="dialer-input-field input-consistent-inner-shadows w-100 mt-3">
              <label>Select Color Combination</label>
              <vb-select
                :defaultSelectedText="'Select Color Combination'"
                class="colorCombinationDD"
                :disabled="api.create.send"
                :selected="forms.create.color_combo_id"
                @change="forms.create.color_combo_id = $event;"
                :options="colorComboOptions"
              />
              
              <p v-if="forms.create.submitted && $v.forms.create.color_combo_id.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                <span v-if="!$v.forms.create.color_combo_id.required">* Select Color is required</span>
              </p>
            </div>
            <div class="dialer-input-group-2 align-items-start">
              <!~~<vb-color-picker 
                class="dialer-input-field input-consistent-inner-shadows color-picker-input mx-2" 
                title="Color" 
                :value="forms.create.color" 
                @change="forms.create.color = $event"
              >
                <p slot="error" v-if="forms.create.submitted && $v.forms.create.color.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.create.color.required">* Color is required</span>
                  <span v-else-if="!$v.forms.create.color.validColor">* Color is invalid</span>
                </p>
              </vb-color-picker>~~>

              <!~~<vb-color-picker 
                class="dialer-input-field input-consistent-inner-shadows color-picker-input mx-2" 
                title="Text Color" 
                :value="forms.create.text_color" 
                @change="forms.create.text_color = $event"
              >
                <p slot="error" v-if="forms.create.submitted && $v.forms.create.text_color.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.create.text_color.required">* Text Color is required</span>
                  <span v-else-if="!$v.forms.create.text_color.validColor">* Text Color is invalid</span>
                </p>
              </vb-color-picker>~~>

            </div>
            <div class="d-flex flex-column justify-content-start md-mar-top">
              <p class="d-flex align-items-start mt-4">
                <b-icon icon="info-circle-fill" class="mt-1"/>
                <span class="md-mar-left">Type your tag name and paste or pick your relevant color code to define your tag.</span>
              </p>
              <div class="w-100 d-flex justify-content-end mt-3">
                <button class="dialer-button dialer-button-primary" variant="success" :disabled="api.create.send">
                  <vb-spinner v-if="api.create.send" />
                  <template v-else>Add</template>
                </button>
              </div>
            </div>
          </b-form>
        </div>
      </div>-->
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from "../../../utils";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
// import validateColor from "validate-color";
export default {
  name: 'AddTagModal',
  props: {
    modalName: {
      type: String,
      default: 'AddTag',
    },
  },
  data() {
    return {
      forms: {
        create: this.$helperFunction.formInstance({
          data: {
            tag: "",
            color_combo_id: '',
            // color: "rgba(0,0,0,1)",
            // text_color: "rgba(0,0,0,1)",
          }
        }),
      },
      api: {
        create: this.$helperFunction.apiInstance({ error_message: true }),
        fetch_color_combination: this.$helperFunction.apiInstance({  }),
      },
      response: {
        color_combinations: [],
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
    ]),
    colorComboOptions(){
      return this.response.color_combinations.map(i=>({
        text: i.label,
        value: i.id,
        color: i.color
      }))
    },
    selectedColorCombo(){ return this.response.color_combinations.find(i=>i.id==this.forms.create.color_combo_id) },
  },
  validations: {
    forms: {
      create: {
        tag: {
          required,
        },
        color_combo_id: {
          required,
        },
        // color: {
        //   required,
        //   validColor: (value) => { return validateColor(value) },
        // },
        // text_color: {
        //   required,
        //   validColor: (value) => { return validateColor(value) },
        // },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      this.colorCombinations()
    },
    onBeforeClose(){
      this.forms.create.reset()
      this.api.create.reset()
      this.api.fetch_color_combination.reset()
    },
    async create() {
      this.forms.create.submitted = true;
      this.$v.forms.create.$touch();
      if (this.$v.forms.create.$invalid || this.api.create.send) return;
      try {
        this.api.create.send = true;
        this.api.create.error_message=''
        await VOIP_API.endpoints.tags.create({
          tag: this.forms.create.tag,
          color_combo_id: this.forms.create.color_combo_id,
          color: this.selectedColorCombo.color,
          colour: this.selectedColorCombo.color,
          text_color: this.selectedColorCombo.text_color,
          allow_call: this.forms.create.allow_call,
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
        })
        this.$emit('created')
        this.appNotify({
          message: 'Successfully Added',
          type: 'success'
        })
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.create.error_message=ex.own_message
      } finally {
        this.forms.create.submitted = false;
        this.api.create.send = false;
      }
    },
    async colorCombinations(){
      if(this.api.fetch_color_combination.send) return;
      try {
        this.api.fetch_color_combination.send=true
        const { data: color_combinations } = await VOIP_API.endpoints.tags.colorCombinations()
        this.response.color_combinations=color_combinations ?? []
      } finally {
        this.api.fetch_color_combination.send=false
      }
    },
  },
}
</script>

<style>

</style>