var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-tabs dialer-tabs-switch-version"
  }, [_c('div', {
    staticClass: "dialer-tab-headers"
  }, [_c('div', {
    staticClass: "can-toggle"
  }, [_c('input', {
    attrs: {
      "id": _vm.random_id,
      "disabled": _vm.disabled,
      "type": "checkbox"
    },
    domProps: {
      "checked": _vm.value,
      "value": _vm.value
    },
    on: {
      "change": _vm.onChange
    }
  }), _c('label', {
    attrs: {
      "for": _vm.random_id
    }
  }, [_c('div', {
    staticClass: "can-toggle__switch",
    attrs: {
      "data-checked": _vm.onText,
      "data-unchecked": _vm.offText
    }
  }), _c('div', {
    staticClass: "can-toggle__label-text"
  })])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }