var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll",
    attrs: {
      "name": "AddTrainingType",
      "clickToClose": true,
      "scrollable": true
    }
  }, [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Add training type")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddTrainingType');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Name")]), _c('input', {})]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Invoice ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" No payment ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Online payment ")]), _c('div', {
    staticClass: "d-flex flex-wrap w-100 mt-32px align-items-center"
  }, [_c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Notify employees by email ")]), _c('div', {
    staticClass: "innerShadowInput-container my-0",
    staticStyle: {
      "width": "68px"
    }
  }, [_c('input', {
    attrs: {
      "placeholder": "2"
    }
  })]), _c('div', {
    staticClass: "innerShadowInput-container ml-3 my-0 w-auto"
  }, [_c('label', [_vm._v("( days before due date )")])])], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Add ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }