<template>
  <modal
    class="dialer-addMemberModal editChanelChatInfo"
    width="300"
    height="auto"
    :scrollable="true"
    :name="modalName"
    @before-open="beforeOpen"
  >
    <!-- dialer_animation center-zoomIn-popup m-w-600  -->
    <div class="dialer-edit-header ">
      <div>
        <h2 class="dialer-edit-title mb-0">Edit group info</h2>
      </div>
      <div class="dialer-edit-actions">
        <button class="newCloseButton" type="button" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    <div class="edit-channel-header-popup p-2 mt-3">
      <b-form @submit.prevent="onSubmit">
        <div class="latestGreyBox-9-9-2023">
          <b-form-group id="input-group-1" >
            <div class="whiteBGinputWithGreyRoundedBorder">
              <label>Group name:</label>
              <b-form-input
                type="text"
                v-model="name"
                placeholder="Group name"
                required
              ></b-form-input>
            </div>
          </b-form-group>

          <b-form-group id="input-group-2">
            <div class="whiteBGinputWithGreyRoundedBorder">
              <label>Group description:</label>
              <b-form-textarea class="line-height-1-7-imp py-2"
                v-model="purpose"
                placeholder="Group description"
                rows="5"
                onkeypress="return event.target.value.length+1<=200"
              ></b-form-textarea>
            </div>
          </b-form-group>
        </div>

        <div class="d-flex justify-content-end align-items-center mt-20px">
          <button type="submit" variant="primary" class="ml-2 newDoneButton">
            <vb-icon icon="squared-done-icon" class="" height="38px" width="38px" />
            <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
              Save
            </span>
          </button>
        </div>
      </b-form>
    </div>
  </modal>
</template>

<script>
import { mattermost, MATTERMOST_API } from "@/MatterMost";
import { LOGGER } from "@/utils";
export default {
  name: "EditChannelInfo",
  props: {
    modalName: {
      type: String,
      default: 'EditChannelInfo',
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      name: "",
      purpose: "",
    };
  },
  inject: [
    'appNotify',
  ],
  methods: {
    beforeOpen(event) {
      const { name, purpose } = event.params;
      this.name = name;
      this.purpose = purpose;
    },
    async onSubmit() {
      try {
        const { data: channel } = await MATTERMOST_API.endpoints.channels.patchChannel(this.channel.id, {
          purpose: this.purpose,
          display_name: this.name,
        });
        mattermost.emitOnUserLevel(mattermost.events.channel_updated,{
          channel_id: channel.id,
          channel: channel
        }) 
        this.appNotify({
          message: "Successfully Updated",
          type: "success",
        })
        // this.$modal.hide("EditChannelInfo");
        this.$modal.hide(this.modalName);
      } catch (ex) {
        this.appNotify({
          message: ex?.response?.data?.message || 'Invalid Description',
          type: 'danger',
        })
        LOGGER.danger("error in onSubmit function", ex);
      }
    },
  },
};
</script>

<style lang="scss" scoped>
  .col-form-label{
    font-weight: bold;
  }
</style>