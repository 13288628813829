<template>
  <modal :name="modalName" class="dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 EditOutOfHoursDayModal bigSquareCheckbox min-width-50percent-modal" :scrollable="true" height="auto" @before-open="onBeforeOpen" @before-close="onBeforeClose" >
    <section> 
      <div class="dialer-edit-header d-block">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <!-- <h2 class="dialer-settings-title newer mb-0">Call Forwarding Settings</h2> -->
            <h2 class="dialer-settings-title newer mb-0">{{data.day | getFullDayName}} business hours</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" type="button" @click="$modal.hide(modalName)">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="vm--modal-text">Configure your business hours settings for a specific day.</div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="latestGreyBox-heading-main">Select for {{data.day | getFullDayName}} if you are...</div>
        <div class="whiteBoxWithBorderInsideGreyBox withOutPadding mt-12px">
          <b-form-group class="mb-0" style="position: relative;pointer-events:auto;">
            <div class="w-100">
              <!-- availble -->
                <b-form-radio 
                  @change="updateSchedule({ type: 'availble' })" 
                  ref="available_radio"
                  class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare" 
                  :class="data.type==='availble'?'active':''" 
                  :checked="data.type" 
                  value="availble"
                  :disabled="api.update_schedule.send"
                >
                  <div class="w-100 dialer-call-option-c withRadio" :class="{'active': data.type === 'availble' }">
                    <div class="latestGreyBox-heading-main">Available all day</div>
                  </div>
                </b-form-radio>
              
              <!-- unavailable -->
                <b-form-radio 
                  @change="updateSchedule({ type: 'unavailable' })" 
                  ref="unavailable_radio"
                  class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare b-b" 
                  :class="data.type==='unavailable'?'active':''" 
                  :checked="data.type" 
                  value="unavailable"
                  :disabled="api.update_schedule.send"
                >
                  <div class="w-100 dialer-call-option-c withRadio" :class="{'active': data.type === 'unavailable' }">
                    <div class="latestGreyBox-heading-main">Unavailable all day</div>
                  </div>
                </b-form-radio>
              
              <!-- custom -->
              <div class="position-relative" @click="$modal.show(`${randomId}-TimeSelectionModal`,{ start: data.start, end: data.end })">
                <b-form-radio 
                  class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare" 
                  ref="custom_radio"
                  :class="data.type==='custom'?'active':''" 
                  :checked="data.type" 
                  value="custom"
                  :disabled="api.update_schedule.send"
                >
                  <div class="w-100 dialer-call-option-c withRadio" :class="{'active': data.type === 'custom' }">
                    <div class="latestGreyBox-heading-main">
                      Unavailable between 
                      <span class="text-decoration-underline" v-if="data.start && data.end">{{ data.start }} - {{ data.end }}</span>
                    </div>
                  </div>
                </b-form-radio>
              </div>
            </div>
          </b-form-group>
        </div>
        <template v-if="['unavailable','custom'].includes(data.type)">
          <div v-if="forwardSetting.is_default" class="d-flex flex-column w-100 mt-20px">
            <div class="latestGreyBox-heading-main mb-0">What happens to calls after business hours ?</div>
            <template v-if="defaultForwardSetting.forward_note == 'none'">
              <div class="latestGreyBox-descriptionText-18px">Keep ringing</div>
              <div class="latestGreyBox-descriptionText-14px">Call forwarding is off</div>
            </template>
            <template v-if="defaultForwardSetting.forward_note == 'voicemail'">
              <div class="latestGreyBox-descriptionText-18px">Ask to leave a voicemail</div>
              <div class="latestGreyBox-descriptionText-14px">Call will be forward to voicemail</div>
            </template>
            <template v-if="defaultForwardSetting.forward_note == 'extension'">
              <div class="latestGreyBox-descriptionText-18px">Forward to a user, team or IVR menu</div>
              <div class="latestGreyBox-descriptionText-14px">
                Call will be forward to
                <span class="color-primary cursor_pointer" :class="{'text-underline': !defaultForwardSetting.extension_name}">{{defaultForwardSetting.extension_name || 'Select extension'}}</span>
              </div>
            </template>
            <template v-if="defaultForwardSetting.forward_note == 'number'">
              <div class="latestGreyBox-descriptionText-18px">Forward to another number</div>
              <div class="latestGreyBox-descriptionText-14px">
                Call will be forward to 
                <span class="color-primary cursor_pointer" :class="{'text-underline': !defaultForwardSetting.forward_number}">{{defaultForwardSetting.forward_number || 'Select number'}}</span>
              </div>
            </template>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start min-height-unset">
              <div class="d-flex align-items-center justify-content-between w-100">
                <div class="latestGreyBox-heading-main">Customise business hour behavior for {{data.day | getFullDayName}}</div>
                <b-form-checkbox :disabled="api.update_schedule.send" class="newerSwitch ml-3" ref="default-toggle" switch :checked="!forwardSetting.is_default" @change="updateSchedule({ default: !$event })"/>
              </div>
              <div v-if="!forwardSetting.is_default" class="d-flex align-items-center justify-content-between w-100 mt-5">
                <div class="d-flex flex-column">
                  <!-- <div class="latestGreyBox-heading-main mb-0">When calls are received out of hours your phone will...</div> -->
                  <div class="latestGreyBox-heading-main mb-0">What happens to calls after business hours ?</div>
                  <template v-if="forwardSetting.forward_note == 'none'">
                    <div class="latestGreyBox-descriptionText-18px">Keep ringing</div>
                    <div class="latestGreyBox-descriptionText-14px">Call forwarding is off</div>
                  </template>
                  <template v-if="forwardSetting.forward_note == 'voicemail'">
                    <div class="latestGreyBox-descriptionText-18px">Ask to leave a voicemail</div>
                    <div class="latestGreyBox-descriptionText-14px">Call will be forward to voicemail</div>
                  </template>
                  <template v-if="forwardSetting.forward_note == 'extension'">
                    <div class="latestGreyBox-descriptionText-18px">Forward to a user, team or IVR menu</div>
                    <div class="latestGreyBox-descriptionText-14px">
                      Call will be forward to
                      <span @click="api.update_schedule.send ? '' : $modal.show(`${randomId}-ScheduleAssignModal`, { account: forwardSetting.forward_number, type: 'setting', })" class="color-primary cursor_pointer text-decoration-underline" :class="{'text-underline': !forwardSetting.extension_name}">{{forwardSetting.extension_name || 'Select extension'}}</span>
                    </div>
                  </template>
                  <template v-if="forwardSetting.forward_note == 'number'">
                    <div class="latestGreyBox-descriptionText-18px">Forward to another number</div>
                    <div class="latestGreyBox-descriptionText-14px">
                      Call will be forward to 
                      <span @click="api.update_schedule.send ? '' : $modal.show(`${randomId}-CallForwardingNumberInputModal`)" class="color-primary cursor_pointer text-decoration-underline" :class="{'text-underline': !forwardSetting.forward_number}">{{forwardSetting.forward_number || 'Select number'}}</span>
                    </div>
                  </template>
                </div>
                <button 
                  class="newButton" 
                  @click.stop="api.update_schedule.send ? '' : $modal.show(`${randomId}-CallForwardSettingModal`,{ 
                    account, 
                    accountcode: account,
                    setting: forwardSetting, 
                    is_ringing_show: selectedSchedule.type=='availble', 
                    is_sec_show: false , 
                    day:data.day 
                  })">
                    <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Change</span>
                  </button>
              </div>
            </div>
          </div>
        </template>
      </div>


      <!--<div v-if="false" class="dialer-box alt position-relative">
        <div class="dialer-call-options options noMinHeight">
          <b-form-group class="dialer-call-options-list v2 mb-0 " style="position: relative;pointer-events:auto;">
            <template>
              <!~~ availble ~~>
              <div class="position-relative d-flex">
                <b-form-radio 
                  @change="updateSchedule({ type: 'availble' })" 
                  class="makeCheckBoxesSquare" 
                  :class="data.type==='availble'?'active':''" 
                  v-model="data.type" 
                  value="availble"
                >
                  <!~~ name="some-radios"  ~~>
                  <div class="w-100 dialer-call-option-c withRadio" :class="{'active': data.type === 'availble' }">
                    <div class="selectedText-big">Available All Day</div>
                  </div>
                </b-form-radio>
              </div>
              <!~~ unavailable ~~>
              <div class="position-relative d-flex">
                <b-form-radio 
                  @change="updateSchedule({ type: 'unavailable' })" 
                  class="makeCheckBoxesSquare" 
                  :class="data.type==='unavailable'?'active':''" 
                  v-model="data.type" 
                  value="unavailable"
                >
                  <!~~ name="some-radios"  ~~>
                  <div class="w-100 dialer-call-option-c withRadio" :class="{'active': data.type === 'unavailable' }">
                    <div class="selectedText-big">Unavailable All Day</div>
                  </div>
                </b-form-radio>
              </div>
              <!~~ custom ~~>
              <div class="position-relative d-flex" @click="$modal.show(`${randomId}-TimeSelectionModal`,{ start: data.start, end: data.end })">
                <b-form-radio 
                  class="makeCheckBoxesSquare" 
                  :class="data.type==='custom'?'active':''" 
                  v-model="data.type" 
                  value="custom"
                >
                  <!~~ name="some-radios"  ~~>
                  <div class="w-100 dialer-call-option-c withRadio" :class="{'active': data.type === 'custom' }">
                    <div class="selectedText-big">Unavailable between {{ data.start }} {{ data.end }}</div>
                  </div>
                </b-form-radio>
              </div>
            </template>
          </b-form-group>
        </div>
      </div>-->
    </section>
    <!--<div v-if="false" class="md-mar-top dialer-box alt position-relative">
      <div class="dialer-box-text md-mar-bottom">What happens to calls after Business hours ?</div>
      <div class="d-flex justify-content-between">
        <div class="d-flex flex-column">
          <div class="dialer-box-header mb-0">When calls aren't answered...</div>
        </div>
        <div class="defaultSwitchContainer">
          Use Default
          <b-form-checkbox class="dialer-switch" ref="default-toggle" switch :checked="forwardSetting.is_default" @change="updateSchedule({ default: $event })"/>
          </div>
      </div>

      <div v-if="!forwardSetting.is_default" class="d-flex justify-content-between">
        <div class="dialer-call-options v3 w-75">
          <div class="selectedContainer">
            <template v-if="forwardSetting.forward_note == 'none'">
              <div class="selectedText-big">Keep ringing</div>
              <div class="selectedText-small">Call forwarding is off</div>
            </template>
            <template v-if="forwardSetting.forward_note == 'voicemail'">
              <div class="selectedText-big">Ask to leave a voicemail</div>
              <div class="selectedText-small">Call will be forward to voicemail</div>
            </template>
            <template v-if="forwardSetting.forward_note == 'extension'">
              <div class="selectedText-big">Redirect to a user, team or IVR menu</div>
              <div class="selectedText-small">
                Call will be forward to
                <span @click="$modal.show(`${randomId}-ScheduleAssignModal`, { account: forwardSetting.forward_number, type: 'setting', })" class="color-primary cursor_pointer" :class="{'text-underline': !forwardSetting.extension_name}">{{forwardSetting.extension_name || 'Select extension'}}</span>
              </div>
            </template>
            <template v-if="forwardSetting.forward_note == 'number'">
              <div class="selectedText-big">Redirect to another number</div>
              <div class="selectedText-small">
                Call will be forward to 
                <span @click="$modal.show(`${randomId}-CallForwardingNumberInputModal`)" class="color-primary cursor_pointer" :class="{'text-underline': !forwardSetting.forward_number}">{{forwardSetting.forward_number || 'Select Number'}}</span>
              </div>
            </template>
          </div>
          <button 
            class="dialer-button dialer-button-primary" 
            @click.stop="$modal.show(`${randomId}-CallForwardSettingModal`,{ 
              account, 
              accountcode: account,
              setting: forwardSetting, 
              is_ringing_show: 
              selectedSchedule.type=='availble', 
              is_sec_show: false 
            })"
          >
            Change
          </button>
        </div>
      </div>
    </div>-->
    <TimeSelectionModal 
      :modalName="`${randomId}-TimeSelectionModal`" 
      @updated="data.start=`${$event.start.data.HH}:${$event.start.data.mm}:${'00'}`;data.end=`${$event.end.data.HH}:${$event.end.data.mm}:${'00'}`;updateSchedule({ start: $event.start, end: $event.end, type: 'custom' })" 
      @before-close="$refs.available_radio.localChecked=data.type;$refs.unavailable_radio.localChecked=data.type;$refs.custom_radio.localChecked=data.type"
    />
    <CallForwardSettingModal :modalName="`${randomId}-CallForwardSettingModal`" @updated="updateSchedule($event)" />
    <ScheduleAssignModal 
      :modalName="`${randomId}-ScheduleAssignModal`" 
      :account="account"
      @interface="updateSchedule({ 
        extension_name: $event.accountname, 
        forward_number: $event.account, 
      })" 
    />
    <CallForwardingNumberInputModal :modalName="`${randomId}-CallForwardingNumberInputModal`" @number="updateSchedule({ forward_number: $event.ber })" />
  </modal>
</template>

<script>
import { $fn, VOIP_API } from '@/utils';
import TimeSelectionModal from "./TimeSelection.vue";
import CallForwardSettingModal from "./CallForwardSettingModal.vue";
import ScheduleAssignModal from "./ScheduleAssignModal.vue";
import CallForwardingNumberInputModal from "./CallForwardingNumberInputModal.vue";
export default {
  name: 'EditOutOfHoursDayModal',
  components: {
    TimeSelectionModal,
    CallForwardSettingModal,
    ScheduleAssignModal,
    CallForwardingNumberInputModal,
  },
  props: {
    modalName: {
      type: String,
      default: "EditOutOfHoursDayModal",
    },
    account: {
      type: String,
      default: '',
    },
    schedules: {
      type: Array,
      default: ()=>[],
    },
    defaultForwardSetting: {
      type: Object,
      default: ()=>({})
    }
  },
  data() {
    return {
      random_id: '',
      data: this.$helperFunction.stateInstance({
        data: {
          day: '',
          start: '',
          end: '',
          type: '',
        }
      }),
      api: {
        update_schedule: this.$helperFunction.apiInstance({ source: true }),
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    selectedSchedule(){
      let schedule = this.schedules?.find?.(i=>i.day==this.data.day);
      return {
        day: this.data.day,
        forward_name: schedule?.forward_name ?? "",
        forward: schedule?.forward_type ?? "none",
        start: schedule?.start_time ?? "",
        end: schedule?.end_time ?? "",
        type: schedule?.type ?? "availble",
        ext_type: "",
        forward_account: schedule?.forward_account ?? "",
        extn: schedule?.extn ?? "",
        setting_type: schedule?.setting_type ?? "",
      }
    },
    forwardSetting(){
      return {
        extension_name: this.selectedSchedule.forward_name ?? '',
        forward_note: this.selectedSchedule.forward ?? 'none',
        forward_number: this.selectedSchedule.forward_account ?? '',
        forward_setting: this.selectedSchedule.forward_account ?? '',
        number: this.selectedSchedule.forward_account ?? '',
        timeout: "0",
        is_default: this.selectedSchedule.setting_type == 'default'
      }
    },
    randomId(){ return this.random_id },
  },
  watch: {
    "forwardSetting.is_default"(value){
      if(this.$refs['default-toggle']) this.$refs['default-toggle'].localChecked=!value
    },
  },
  filters: {
    getFullDayName(value){
      if(value=='mon'){
        return 'Monday'
      }
      else if(value=='tue'){
        return 'Tuesday'
      }
      else if(value=='wed'){
        return 'Wednesday'
      }
      else if(value=='thu'){
        return 'Thursday'
      }
      else if(value=='fri'){
        return 'Friday'
      }
      else if(value=='sat'){
        return 'Saturday'
      }
      else if(value=='sun'){
        return 'Sunday'
      }
      else{
        return value
      }
    }
  },
  methods: {
    onBeforeOpen(event){
      let vm = this
      this.data.day=event?.params?.day ?? ''
      this.$nextTick(()=>[
        vm.data.start = vm.selectedSchedule.type=='custom' ? vm.selectedSchedule.start.displayTime || vm.selectedSchedule.start || '' : '', 
        vm.data.end = vm.selectedSchedule.type=='custom' ? vm.selectedSchedule.end.displayTime || vm.selectedSchedule.end || '' : '', 
        vm.data.type = vm.selectedSchedule.type,
      ])
    },
    onBeforeClose(){
      this.data?.reset?.()
      this.api.update_schedule.reset()
    },
    updateSchedule(setting={}) {
      let vm = this;
      if(vm.api.update_schedule.send) return;
      vm.api.update_schedule.send = true;
      const toaster_value = vm.appNotify({
        message: "Schedule setting Saving...",
        type: "success",
        duration: 60 * 1000,
      });
      const type = setting?.type ?? vm.selectedSchedule.type
      const forward = setting?.forward_note=='off' ? 'none' : setting?.forward_note ?? vm.selectedSchedule.forward
      const update_forward = ['custom','unavailable'].includes(type) && forward=='none' ? 'voicemail' : ['availble'].includes(setting?.type) && forward!='none' ? 'none' : forward
      const isdefault = ['custom','unavailable'].includes(type) && forward=='none' ? true : setting?.default ?? vm.forwardSetting.is_default
      const prev_type = this.data.type
      this.data.type = ''
      VOIP_API.endpoints.users.udpateschedule({
        account: vm.account,
        day: vm.data.day,
        extn: setting?.forward_note ?? vm.selectedSchedule.extn,
        forward: update_forward,
        forward_account: setting?.forward_number ?? vm.selectedSchedule.forward_account,
        forward_name: setting?.extension_name ?? vm.selectedSchedule.forward_name,
        ext_type: update_forward,
        start: setting?.start?.data ?? (vm.selectedSchedule.start ? {HH:vm.selectedSchedule.start?.split?.(':')?.[0] ?? '',mm:vm.selectedSchedule.start?.split?.(':')?.[1] ?? ''} : ''),
        end: setting?.end?.data ?? (vm.selectedSchedule.end ? {HH:vm.selectedSchedule.end?.split?.(':')?.[0] ?? '',mm:vm.selectedSchedule.end?.split?.(':')?.[1] ?? ''} : ''),
        type: type,
        flag: isdefault ? 'default' : 'custom'
      })
      .then(({ data, }) => {
        if(['availble','unavailable'].includes(type)) {
          this.data.start=''
          this.data.end=''
        }
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        vm.$emit('updated',data?.data ?? []);
        this.data.type = type
        return vm.$nextTick()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        this.data.type = prev_type
      })
      .finally(() => {
        toaster_value.close();
        vm.api.update_schedule.send = false;
        vm.$refs['default-toggle'].localChecked=!vm.forwardSetting.is_default
      });
    },
  },
  mounted(){
    this.random_id = $fn.makeid(10)
  },
}
</script>

<style>

</style>