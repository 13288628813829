<template>
  <modal
    class="dialer_animation center-zoomIn-popup dialer-notificationModal KeyboardShortcutsModal"
    transition="center-zoomIn-transition"
    :name="modalName" 
    :scrollable="false"
  >
    <div class="dialer-assign-to dialer-edit w-100">
      <div class="pb-3 dialer-edit-header d-flex aling-items-center justify-content-between w-100">
        <h2 class="dialer-edit-title newer mb-0">Shortcut Keys</h2>
        <div class="dialer-edit-actions">
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else class="newCloseButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="mt-20px latestGreyBox-9-9-2023">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4" v-for="data in shortcuts" :key="data.shortcut">
            <div class="latestGreyBox-heading-main">{{data.text}}</div> 
            <div class="latestGreyBox-heading-main">{{data.shortcut}}</div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
let shortcuts = [
  {
    text: 'toggle fullscreen',
    shortcut: 'ctrl + shift + f',
  },
  {
    text: 'hang out',
    shortcut: 'ctrl + shift + h',
  },
  {
    text: 'hold / unhold',
    shortcut: 'ctrl + alt + h',
  },
  {
    text: 'toggle chat',
    shortcut: 'ctrl + shift + c',
  },
  {
    text: 'toggle members',
    shortcut: 'ctrl + shift + m',
  },
]
export default {
  name: "ShortCuts",
  props: {
    modalName: {
      type: String,
      default: 'ShortCuts'
    },
  },
  computed: {
    shortcuts(){ return shortcuts },
  },
};
</script>

<style>
</style>