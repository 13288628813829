<template>
	<div class="right-calender">
    <div class="top-main-calender scrollow1">
      <section class="sec-common">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h5 class="emp-profile-heading">Reminders</h5>
          </div>
        </div>
        <div>
          <p class="notes-top-text">
            Set up custom email notifications to employees who have time off requests pending on them
          </p>
        </div>
        <div>
          <b-form-checkbox class="gm-hrm-checkbox">
            <span class="home-checkbox-space">Send a reminder email o approvers who have unapproved time off requests</span>
          </b-form-checkbox>
          <div class="mar-left-30hrm">
            <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Monday</span>
            </b-form-checkbox>
            <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Tuesday</span>
            </b-form-checkbox>
            <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Wednesday</span>
            </b-form-checkbox>
            <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Thursday</span>
            </b-form-checkbox>
            <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Friday</span>
            </b-form-checkbox>
            <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Saturday</span>
            </b-form-checkbox>
            <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Sunday</span>
            </b-form-checkbox>
            <div class="d-flex justify-content-start align-items-center mar-top-hrm25">
             <p class="smallFont mb-imp">at</p>
             <div class="dd-gm-container mar-left-hrm20">
              <b-dropdown no-caret class="gm-mainBorderfordropi width-one">
                <template #button-content>
                  <div class="gm-button-content-dropi">
                    <div class="gm-dropi-contenti">08:00</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">A</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">B</b-dropdown-item>
              </b-dropdown>
             </div>
            </div>
            <div class="mar-top-hrm30">
              <label class="gm-hrm-label">Don’t send reminders while the approver is away on following policies</label>
              <div class="dd-gm-container">
                <b-dropdown no-caret class="gm-mainBorderfordropi width-two">
                  <template #button-content>
                    <div class="gm-button-content-dropi">
                      <div class="gm-dropi-contenti">Indivisual approvers</div>
                      <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item class="dropi-dropdown-toggle">A</b-dropdown-item>
                  <b-dropdown-item class="dropi-dropdown-toggle">B</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
      </section>
    </div>
	</div>
</template>

<script>
export default {
  name: 'TimeoffRemainderSettingHRM',
  components:{
	
  },
}
</script>

<style>

</style>