<template>
  <modal class="center-zoomIn-transition sipActiveCall-Modal NotesModal" :transition="!$store.getters.getIsMobile ? 'center-zoomIn-transition' : ''" :name="modalName" @before-open="beforeOpen" @before-close="beforeClose">
    <div class="dialer-call-notification-tray">
      <div class="dialer-call-notification">
        <!-- <div class="dialer-call-notification-close" @click="$modal.hide(modalName)">
          <vb-icon icon="dialer-close-icon" width="14" height="14" />
        </div> -->
        <div class="dialer-call-notification-inner justify-content-between w-100">
          <div class="dialer-call-notification-text">
            <div class="dialer-call-notification-main-text">Note</div>
            <div class="dialer-call-notification-sub-text">Write some note for this call</div>
          </div>
          <a class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div> 
        <div class="dialer-call-notification-actions">
          <div class="dialer-call-notification-actions-inner">
            <form @submit.prevent="''" class="whiteBGinputWithGreyRoundedBorder">
              <textarea v-model="form.note" @input="sendNote"></textarea>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '../../utils';
import _ from 'lodash';
export default {
  name: 'NoteModal',
  props: {
    modalName: {
      type: String,
      default: 'NoteModal'
    },
  },
  data(){
    return {
      api_sent: {
        note: false,
      },
      form: {
        note: '',
      },
      data: {
        id: '',
      },
      api_status: {
        note: '',
      },
    }
  },
  inject: [
    'appNotify',
  ],
  methods: {
    beforeOpen(event){
      this.data.id = event.params.id
      this.form.note = event.params.note || ''
    },
    beforeClose(){
      // this.form.note = ''
      // this.data.id = ''
      this.api_status.note=''
    },
    sendNote: _.debounce(function () {
      let vm = this;
      vm.api_sent.note = true;
      VOIP_API.endpoints.call_activity
        .calltagsnotes({
          uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
          accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
          id: vm.data.id,
          log: vm.form.note,
          type: "note",
        })
        .then(() => {
          vm.api_status.note = "success";
          vm.$emit('updated')
          vm.$emit('updated-note',vm.form.note)
          vm.appNotify({
            message: 'Successfully Updated!',
            type: 'success',
          })
        })
        .catch((ex) => {
          vm.api_status.note = "fail";
          vm.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(() => {
          vm.api_sent.note = false;
        });
    }, 4 * 1000),
  },
}
</script>

<style>

</style>