
import { VOIP_API } from "../index"

export const TEAMS_API_ENDPOINTS = {
  toogleFavourite(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'mark/favourite/team',
      data,
    })
  },
  delete(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'billing_service_action',
      },
    })
  },
  list(params) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: 'teams',
      params,
    })
  },
  updatepickupgroupoption(team_id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `teams/${team_id}`,
      method: 'PATCH',
      data,
    })
  },
  extensionTeams(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'fetch-extension-teams',
      data,
    })
  },
  updateTeams(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'update-extension-teams',
      data,
    })
  },
  create(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'billingcons',
      data,
    })
  },
  teammembers(id,params={}) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: `getavailableandselectedteammembers/${id}`,
      params,
    })
  },
  updateteammmembers(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'updateammembers',
      data,
    })
  },
  update(id,data={}) {
    return VOIP_API.axios.voip.request({
      method: 'patch',
      url: `teams/${id}`,
      data,
    })
  },
  adduserchannel() {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'mattermost-channel'
    })
  },
}