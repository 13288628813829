var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hrm-home-container forDocumentsModule"
  }, [_c('div', {
    staticClass: "hrm-home-inner"
  }, [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Documents "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('hrmAddNewDocumentsModal');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "plus",
      "font-scale": "1.3",
      "scale": "1.5"
    }
  }), _vm._v(" Add new ")], 1)]), _c('div', {
    staticClass: "hrmTabsContainer pt-3 pb-4"
  }, [_c('div', {
    class: `hrmTabsButton ${_vm.showTabs == 'General' ? 'active' : ''} mr-16px`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'General';
      }
    }
  }, [_vm._v("General")]), _c('div', {
    class: `hrmTabsButton ${_vm.showTabs == 'Onboarding' ? 'active' : ''} mr-16px`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'Onboarding';
      }
    }
  }, [_vm._v("Onboarding")]), _c('div', {
    class: `hrmTabsButton ${_vm.showTabs == 'Offboarding' ? 'active' : ''} mr-16px`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'Offboarding';
      }
    }
  }, [_vm._v("Offboarding")])]), _vm.showTabs == 'General' ? [_c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
          staticClass: "dialer-col-right two"
        }, [_c('div', {
          staticClass: "DocumentIconWithText"
        }, [_c('div', {
          staticClass: "DocumentIcon-Container"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "pdfIcon-insideTable-hrmIcon"
          }
        })], 1), _c('div', {
          staticClass: "DocumentIconWithText-text"
        }, [_vm._v("backupfile")])])]), _c('td', {
          staticClass: "dialer-col-right three"
        }, [_vm._v("Jason")]), _c('td', {
          staticClass: "dialer-col-right four"
        }, [_vm._v("Everyone")]), _c('td', {
          staticClass: "dialer-col-right five"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link",
            "toggle-class": "text-decoration-none tb-notes-dd",
            "no-caret": "",
            "menu-class": "notes-table-dd"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })];
            },
            proxy: true
          }], null, false, 374507881)
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Action")])], 1)], 1)]), _c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
          staticClass: "dialer-col-right two"
        }, [_c('div', {
          staticClass: "DocumentIconWithText"
        }, [_c('div', {
          staticClass: "DocumentIcon-Container"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "wordIcon-insideTable-hrmIcon"
          }
        })], 1), _c('div', {
          staticClass: "DocumentIconWithText-text"
        }, [_vm._v("task.doc")])])]), _c('td', {
          staticClass: "dialer-col-right three"
        }, [_vm._v("Jason")]), _c('td', {
          staticClass: "dialer-col-right four"
        }, [_vm._v("Everyone")]), _c('td', {
          staticClass: "dialer-col-right five"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link",
            "toggle-class": "text-decoration-none tb-notes-dd",
            "no-caret": "",
            "menu-class": "notes-table-dd"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })];
            },
            proxy: true
          }], null, false, 374507881)
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Action")])], 1)], 1)])];
      },
      proxy: true
    }], null, false, 94655094)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead one pt-1"
  }, [_vm._v("Notes")]), _c('th', {
    staticClass: "HrmTableHead two pt-1"
  }, [_vm._v("Filename")]), _c('th', {
    staticClass: "HrmTableHead three pt-1"
  }, [_vm._v("Created by")]), _c('th', {
    staticClass: "HrmTableHead four pt-1"
  }, [_vm._v("shared with")]), _c('th', {
    staticClass: "HrmTableHead five pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1)] : _vm._e(), _vm.showTabs == 'Onboarding' ? [_c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
          staticClass: "dialer-col-right two"
        }, [_c('div', {
          staticClass: "DocumentIconWithText"
        }, [_c('div', {
          staticClass: "DocumentIcon-Container"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "pdfIcon-insideTable-hrmIcon"
          }
        })], 1), _c('div', {
          staticClass: "DocumentIconWithText-text"
        }, [_vm._v("backupfile")])])]), _c('td', {
          staticClass: "dialer-col-right three"
        }, [_vm._v("Jason")]), _c('td', {
          staticClass: "dialer-col-right four"
        }, [_vm._v("Everyone")]), _c('td', {
          staticClass: "dialer-col-right five"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link",
            "toggle-class": "text-decoration-none tb-notes-dd",
            "no-caret": "",
            "menu-class": "notes-table-dd"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })];
            },
            proxy: true
          }], null, false, 374507881)
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Action")])], 1)], 1)]), _c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
          staticClass: "dialer-col-right two"
        }, [_c('div', {
          staticClass: "DocumentIconWithText"
        }, [_c('div', {
          staticClass: "DocumentIcon-Container"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "wordIcon-insideTable-hrmIcon"
          }
        })], 1), _c('div', {
          staticClass: "DocumentIconWithText-text"
        }, [_vm._v("task.doc")])])]), _c('td', {
          staticClass: "dialer-col-right three"
        }, [_vm._v("Jason")]), _c('td', {
          staticClass: "dialer-col-right four"
        }, [_vm._v("Everyone")]), _c('td', {
          staticClass: "dialer-col-right five"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link",
            "toggle-class": "text-decoration-none tb-notes-dd",
            "no-caret": "",
            "menu-class": "notes-table-dd"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })];
            },
            proxy: true
          }], null, false, 374507881)
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Action")])], 1)], 1)])];
      },
      proxy: true
    }], null, false, 94655094)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead one pt-1"
  }, [_vm._v("Notes")]), _c('th', {
    staticClass: "HrmTableHead two pt-1"
  }, [_vm._v("Filename")]), _c('th', {
    staticClass: "HrmTableHead three pt-1"
  }, [_vm._v("Created by")]), _c('th', {
    staticClass: "HrmTableHead four pt-1"
  }, [_vm._v("shared with")]), _c('th', {
    staticClass: "HrmTableHead five pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1)] : _vm._e(), _vm.showTabs == 'Offboarding' ? [_c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
          staticClass: "dialer-col-right two"
        }, [_c('div', {
          staticClass: "DocumentIconWithText"
        }, [_c('div', {
          staticClass: "DocumentIcon-Container"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "pdfIcon-insideTable-hrmIcon"
          }
        })], 1), _c('div', {
          staticClass: "DocumentIconWithText-text"
        }, [_vm._v("backupfile")])])]), _c('td', {
          staticClass: "dialer-col-right three"
        }, [_vm._v("Jason")]), _c('td', {
          staticClass: "dialer-col-right four"
        }, [_vm._v("Everyone")]), _c('td', {
          staticClass: "dialer-col-right five"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link",
            "toggle-class": "text-decoration-none tb-notes-dd",
            "no-caret": "",
            "menu-class": "notes-table-dd"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })];
            },
            proxy: true
          }], null, false, 374507881)
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Action")])], 1)], 1)]), _c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
          staticClass: "dialer-col-right two"
        }, [_c('div', {
          staticClass: "DocumentIconWithText"
        }, [_c('div', {
          staticClass: "DocumentIcon-Container"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "wordIcon-insideTable-hrmIcon"
          }
        })], 1), _c('div', {
          staticClass: "DocumentIconWithText-text"
        }, [_vm._v("task.doc")])])]), _c('td', {
          staticClass: "dialer-col-right three"
        }, [_vm._v("Jason")]), _c('td', {
          staticClass: "dialer-col-right four"
        }, [_vm._v("Everyone")]), _c('td', {
          staticClass: "dialer-col-right five"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link",
            "toggle-class": "text-decoration-none tb-notes-dd",
            "no-caret": "",
            "menu-class": "notes-table-dd"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })];
            },
            proxy: true
          }], null, false, 374507881)
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Action")])], 1)], 1)])];
      },
      proxy: true
    }], null, false, 94655094)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead one pt-1"
  }, [_vm._v("Notes")]), _c('th', {
    staticClass: "HrmTableHead two pt-1"
  }, [_vm._v("Filename")]), _c('th', {
    staticClass: "HrmTableHead three pt-1"
  }, [_vm._v("Created by")]), _c('th', {
    staticClass: "HrmTableHead four pt-1"
  }, [_vm._v("shared with")]), _c('th', {
    staticClass: "HrmTableHead five pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1)] : _vm._e()], 2), _c('hrmAddNewDocumentsModal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }