
import { VOIP_API } from "../index"

export const DNS_API_ENDPOINTS = {
  getCP(params={}){
    return VOIP_API.axios.voip_centeral_point.v2.request({
      url: `centeral-point`,
      method: "GET",
      params,
    })
  },
  checkMaintenance(params={}){
    return VOIP_API.axios.voip_centeral_point.v2.request({
      url: `centeral-point/check-maintenance`,
      method: "GET",
      params,
    })
  },
  getCPList(params={}){
    return VOIP_API.axios.voip_centeral_point.v2.request({
      url: `centeral-point/all`,
      method: "GET",
      params,
    })
  },
  deviceInfo(){
    return VOIP_API.axios.voip_centeral_point.v1.request({
      url: `device-info`,
      method: "GET",
    })
  },
  verifyPincode(data={}){
    return VOIP_API.axios.voip_centeral_point.v1.request({
      url: `verify-pincode`,
      method: "POST",
      data,
    })
  },
}