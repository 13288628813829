<template>
  <modal 
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"   
    :name="modalName" 
    @before-close="onBeforeClose()" 
    @before-open="onBeforeOpen($event)"
  >
    <div v-if="conditions.screen==screens.add" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="api.create.send ? '' : conditions.screen=screens.list">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20"  />
      </div>
      <div class="dialer-settings-title topMainHeading">Add Status</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div v-else-if="conditions.screen==screens.view" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.list;selected.tag_id=''">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Edit Status</div>
      <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
        <div class="textInsideCard onRightSide primaryColor">Edit</div>
      </div>
    </div>
    <div v-else-if="conditions.screen==screens.edit" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="api.delete.send || api.update.send ? '' : conditions.screen=screens.view;">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Edit Status</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20"   />
      </div>
      <div class="dialer-settings-title topMainHeading">Status</div>
      <div class="plus-IOSIcon-container" @click="conditions.screen=screens.add">
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon"  width="20" height="19.992"/>
      </div>
    </div>

    <div class="innerModalIos">
      
      <template v-if="conditions.screen==screens.add">
        
        

        <div class="">
          <b-form @submit.prevent="create()" class="dialer-form">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Status Name</label>
                  <b-input type="text" v-model="forms.create.text" :disabled="api.create.send" placeholder="Enter name" />
                  <p 
                    v-if="(forms.create.submitted && $v.forms.create.text.$invalid) || api.create.validation_errors.text" 
                    class="mb-1 text-small text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.create.text.required">* Name is required</span>
                    <span v-for="em in api.create.validation_errors.text" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <vb-color-picker 
                  class="mobileIos color-picker-input" 
                  :disabled="api.create.send"
                  :value="forms.create.color" 
                  @change="forms.create.color = $event"
                >
                  <p 
                    slot="error" 
                    v-if="(forms.create.submitted && $v.forms.create.color.$invalid) || api.create.validation_errors.color || api.create.validation_errors.colour" 
                    class="mb-1 text-small text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.create.color.required">* Color is required</span>
                    <span v-for="em in api.create.validation_errors.color" :key="em">* {{ em }}</span>
                    <span v-for="em in api.create.validation_errors.colour" :key="em">* {{ em }}</span>
                  </p>
                </vb-color-picker>
              </div>
            </div>
            <div class="basicWhiteIOScard mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Allow Call</div>
                <div class="position-relative">
                  <b-form-checkbox :disabled="api.create.send" class="dialer-switch IosSwitch" v-model="forms.create.allow_call" switch />
                </div>
              </div>
            </div>
            <button class="IosFullWidthButton forDone mt-16px mb-16px" variant="success" :disabled="api.create.send">
              <vb-spinner v-if="api.create.send" />
              <template v-else>Add</template>
            </button>
            

          </b-form>
        </div>

      </template>

      <template v-else-if="conditions.screen==screens.view">
        

        <div class="">
          
          <div class="dialer-form">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Status Name</div>
                <div class="textInsideCard textAlignRight onRightSide">{{ selected.status.status }}</div>
              </div>

              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Color</div>
                <div class="textInsideCard textAlignRight onRightSide">{{ selected.status.colour }}</div>
              </div>

              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Allow Call</div>
                <div class="textInsideCard textAlignRight onRightSide">{{ selected.status.allow_call }}</div>
              </div>

            </div>
          </div>

        </div>
      </template>

      <template v-else-if="conditions.screen==screens.edit">
        

        <div class="">
          
          <b-form @submit.prevent="update()" class="dialer-form">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Status Name</label>
                  <b-input type="text" v-model="forms.update.text" :disabled="api.delete.send || api.update.send" placeholder="Enter name" />
                  <p 
                    v-if="(forms.update.submitted && $v.forms.update.text.$invalid) || api.update.validation_errors.text" 
                    class="mb-1 text-small text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update.text.required">* Name is required</span>
                    <span v-for="em in api.update.validation_errors.text" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <vb-color-picker 
                  class="mobileIos color-picker-input v2" 
                  :disabled="api.delete.send || api.update.send"
                  :value="forms.update.color" 
                  @change="forms.update.color = $event"
                >
                  <p 
                    slot="error" 
                    v-if="(forms.update.submitted && $v.forms.update.color.$invalid) || api.update.validation_errors.color || api.update.validation_errors.colour" 
                    class="mb-1 text-small text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update.color.required">* Color is required</span>
                    <span v-for="em in api.update.validation_errors.color" :key="em">* {{ em }}</span>
                    <span v-for="em in api.update.validation_errors.colour" :key="em">* {{ em }}</span>
                  </p>
                </vb-color-picker>
              </div>
            </div>
            <div class="basicWhiteIOScard mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Allow Call</div>
                <div class="position-relative">
                  <b-form-checkbox :disabled="api.delete.send || api.update.send" class="dialer-switch IosSwitch" v-model="forms.update.allow_call" switch />
                </div>
              </div>
            </div>
            <button class="IosFullWidthButton forDone mb-16px" variant="success" :disabled="api.delete.send || api.update.send">
              <vb-spinner v-if="api.update.send" />
              <template v-else>Update</template>
            </button>
          </b-form>
          <button @click="api.delete.send || api.update.send ? '' : remove()" class="IosFullWidthButton forRemove mb-16px" variant="success">
            <template >Delete</template>
          </button>

        </div>
      </template>

      <template v-else>
        
        <div class="basicWhiteIOScard mt-32px mb-16px">
          
          <div v-for="status in statuses" :key="status.id" class="basicWhiteIOScard-item d-flex justify-content-between" @click="setUpdateId(status)">
            <div class="d-flex align-items-center">
              <div class="basicIOSIcon mr-16px">
                <vb-icon class="" :icon="status.allow_call ? 'allowCallsTrue-IOSIcon' : 'allowCallsFalse-IOSIcon'"/>
              </div>
              <div class="statusInsideList-Ios mr-6px" style="">
                <vb-status :statusId="status.id" style="height: 20px; width: 20px; border-radius: 50%" />
              </div>
              <div class="headingInsideTable mr-6px">{{ status.status }}</div>
            </div>
            <div class="d-flex align-items-center">
              <b-icon v-if="status.id == getCurrentUser.status_id" class="mr-6px" icon="check2-circle" variant="primary" />
              <div class="textInsideCard textAlignRight onRightSide" v-if="!status.accountcode">Default</div>
              <vb-icon v-if="status.accountcode" icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
          </div>
          <vb-no-record v-if="!statuses.length" :text="api.statuses.send?'':'There is no Tags'" :design="3">
            <vb-loading v-if="api.statuses.send" class="text-center w-100" slot="loading" />
          </vb-no-record>

        </div>

      </template>

    </div>
  </modal>
</template>

<script>
import { VOIP_API } from "../../../utils";
import { required } from "vuelidate/lib/validators";
import { mapGetters, mapState } from 'vuex';
let screens = {
  edit: 'edit',
  add: 'add',
  list: 'list',
  view: 'view',
}
export default {
  name: "StatusesModal",
  inject:['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'StatusesModal',
    },
  },
  data() {
    return {
      conditions: {
        screen: screens.list,
      },
      selected: {
        status_id: '',
        status: {},
      },
      api: {
        create: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        update: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        delete: this.$helperFunction.apiInstance({  }),
        allow_call: this.$helperFunction.apiInstance({  }),
        set: this.$helperFunction.apiInstance({  }),
        statuses: this.$helperFunction.apiInstance({  }),
      },
      forms: {
        create: this.$helperFunction.formInstance({ 
          data: {
            text: "",
            color: "#000000",
            allow_call: false,
          },
        }),
        update: this.$helperFunction.formInstance({ 
          data: {
            text: "",
            color: "",
            allow_call: false,
          },
        })
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    ...mapState({
      statuses: (state) => state.common.statuses
    }),
    selectedStatus(){ return this.statuses.find(status=>status.id==this.selected.status_id) },
    screens(){ return screens },
  },
  validations: {
    forms: {
      create: {
        text: {
          required,
        },
        color: {
          required,
        },
      },
      update: {
        text: {
          required,
        },
        color: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen() {
      // this.fetchTags();
    },
    onBeforeClose() {
      this.selected.status_id = "";
      this.forms.create.reset();
      this.forms.update.reset();
      this.api.create.reset()
      this.api.update.reset()
      this.api.delete.reset()
      this.api.statuses.reset()
      this.api.allow_call.reset()
      this.api.set.reset()
      this.conditions.screen=screens.list
    },
    create() {
      let vm = this;
      vm.forms.create.submitted = true;
      vm.$v.forms.create.$touch();
      if (vm.$v.forms.create.$invalid || vm.api.create.send) return;
      vm.api.create.send = true;
      vm.api.create.validation_errors={}
      VOIP_API.endpoints.statuses.create({
        status: vm.forms.create.text,
        color: vm.forms.create.color,
        colour: vm.forms.create.color,
        allow_call: vm.forms.create.allow_call,
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data: status }) => {
        vm.$store.state.common.statuses.push({
          ...status,
          uid: null,
          allow_call: vm.forms.create.allow_call,
        });
        vm.forms.create.reset();
        vm.conditions.screen=screens.list
        vm.appNotify({
          message: 'Successfully created',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        vm.api.create.validation_errors=ex.own_errors ?? {}
      })
      .finally(() => {
        vm.forms.create.submitted = false;
        vm.api.create.send = false;
      });
    },
    remove() {
      let vm = this;
      const id = vm.selectedStatus?.id
      const sync_code = vm.selectedStatus?.sync_code
      if (!id || vm.api.delete.send || vm.api.set.send == id) return;
      vm.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to delete.`,
        variant: 'danger',
        button: {
          no: "no",
          yes: "yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete.send = true;
            VOIP_API.endpoints.statuses.delete(sync_code,{
              flag: 'portal_status_delete'
            })
            .then(() => {
              return vm.$store.state.common.statuses.filter((status) => status.id !== id);
            })
            .then((statuses)=>{
              vm.$store.state.common.statuses = statuses
              vm.conditions.screen=screens.list
              if (vm.getCurrentUser?.status_id === id) {
                vm.set(1, "Online");
              }
            })
            .finally(() => {
              vm.api.delete.send = false;
            });
          }
        },
      });
    },
    update() {
      let vm = this;
      vm.forms.update.submitted = true;
      vm.$v.forms.update.$touch();
      if (vm.$v.forms.update.$invalid || vm.api.update.send) return;
      vm.api.update.send = true;
      vm.api.update.validation_errors={}
      VOIP_API.endpoints.statuses.update({
        id: vm.selected.status_id,
        status: vm.forms.update.text,
        color: vm.forms.update.color,
        colour: vm.forms.create.color,
        allow_call: vm.forms.update.allow_call,
        flag: 'portal_status_update'
      })
      .then(() => {
        return vm.$store.state.common.statuses.map((status) => {
          if (status.id === vm.selected.status_id) {
            return {
              ...status,
              status: vm.forms.update.text,
              colour: vm.forms.update.color,
              allow_call: vm.forms.update.allow_call,
            };
          } else {
            return status;
          }
        });
      })
      .then((statuses)=>{
        vm.$store.state.common.statuses = statuses
        vm.selected.status_id = "";
        vm.forms.update.reset();
        vm.conditions.screen=screens.list
        vm.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })

      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        vm.api.update.validation_errors=ex.own_errors ?? {}
      })
      .finally(() => {
        vm.forms.update.submitted = false;
        vm.api.update.send = false;
      });
    },
    setUpdateId(status) {
      if (!status.accountcode) return;
      this.selected.status_id = status.id
      this.forms.update.text = status.status;
      this.forms.update.color = status.colour;
      this.forms.update.allow_call = status.allow_call;
      this.conditions.screen = screens.view
      this.selected.status=status
    },
    set(id, name) {
      let vm = this;
      if (!id || vm.api.set.send || vm.api.delete) return;
      vm.api.set.send = id;
      VOIP_API.endpoints.user_statuses.set({ id })
      .then(() => {
        this.$store.state.common.user.status_id = id;
        this.$store.state.common.user.status = name;
      })
      .finally(() => {
        vm.api.set.send = "";
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 35px;
}
.dialer-table {
  .dialer-col-center {
    span {
      .custom-control {
        padding-left: 0px;
      }
    }
  }
}

.colorPicker-section {
  ul {
    display: flex;
    justify-content: flex-end;
    li {
      .color-circle {
        cursor: pointer;
        display: block;
        width: 35px !important;
        height: 35px;
        margin-right: 15px;
        padding: 0px !important;
        @include border-radius(50% !important);
        overflow: hidden;
        &.primary {
          background-color: $primary;
        }
        &.secondary {
          background-color: $secondary;
        }
        &.danger {
          background-color: $danger;
        }
        &.success {
          background-color: $success;
        }
        .color-swatch {
          position: absolute;
          padding: 0px;
          right: -8px;
          top: -8px;
          width: 56px !important;
          height: 56px;
          border: none;
          @include border-radius(50% !important);
        }
        &:after {
          position: absolute;
          content: "";
          top: 11px;
          left: 50%;
          transform: translate(-50%) rotate(135deg);
          border-top: solid $white;
          border-right: solid $white;
          border-width: 1px;
          width: 10px;
          height: 10px;
          cursor: pointer;
          pointer-events: none;
        }
      }
      &:last-child {
        .color-circle {
          margin-right: 0px;
        }
      }
    }
  }
}
.dialer-userStatusArea {
  display: flex;
  justify-content: center;
  .dialer-userStatus {
    position: relative;
  }
}
</style>
