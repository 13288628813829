<template>
  <div class="w-100">
    <div class="TODO-taksTable-bodyRow thisIsAddTaskRow mt-12px" style="min-height:auto;">
      <b-form v-if="conditions.add_task" @submit.prevent="createTask()" class="w-100 p-2">
        <div class="TODO-taksTable-bodyRow-column align-items-start py-2 w-auto" >
          <div class="TODO-taksTable-bodyRow-column-text d-flex justify-content-end w-100">
            <b-button :disabled="api.create_task.send" @click="conditions.add_task=false;forms.create_task.reset()" variant="link" class="ml-3" type="button">
              <b-icon icon="x-lg" variant="danger" />
            </b-button>
          </div>
        </div>
        <div class="align-items-start py-2 justify-content-start flex-1">
          <div class="d-flex flex-column whiteBGinputWithGreyRoundedBorder mb-2">
            <b-form-input 
              type="text" 
              placeholder="Add title" 
              class="bg-white w-100" 
              :disabled="api.create_task.send" 
              v-model="forms.create_task.title" 
              :maxLength="$v.forms.create_task.title.$params.maxLength.max"
            />
            <p class="error-red text text-danger animated bounceIntop" v-if="(forms.create_task.submitted && $v.forms.create_task.title.$invalid) || api.create_task.validation_errors.title">
              <span v-if="!$v.forms.create_task.title.required">Title is required</span>
              <span v-else-if="!$v.forms.create_task.title.minLength"> Title should be minimum {{$v.forms.create_task.title.$params.minLength.min}} character</span>
              <span v-else-if="!$v.forms.create_task.title.maxLength"> Title can be maximum {{$v.forms.create_task.title.$params.maxLength.max}} character</span>
              <span v-for="(em,i) in api.create_task.validation_errors.title" :key="i">{{ em }}</span>
            </p>
          </div>
          <div class="d-flex flex-column whiteBGinputWithGreyRoundedBorder flex-fill dropdownVersion ml-4">
            <b-select 
              placeholder="Select space"
              :disabled="api.create_task.send" 
              v-model="forms.create_task.space_id" 
              :options="Object.values(spaces)" 
              value-field="id" 
              text-field="name" 
            />
            <p class="error-red" v-if="(forms.create_task.submitted && $v.forms.create_task.space_id.$invalid) || api.create_task.validation_errors.space_id">
              <span v-if="!$v.forms.create_task.space_id.required">Space is required</span>
              <span v-for="(em,i) in api.create_task.validation_errors.space_id" :key="i">{{ em }}</span>
            </p>
          </div>
        </div>
        <div class="TODO-taksTable-bodyRow-column align-items-start py-2 w-auto" >
          <div class="TODO-taksTable-bodyRow-column-text d-flex justify-content-end w-100">
            <b-button type="submit" class="newDoneButton" variant="primary" :disabled="api.create_task.send">
              <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
              <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                Submit
              </span>
            </b-button>
          </div>
        </div>
      </b-form>
      <div class="TODO-taksTable-bodyRow-column onlyOne justify-content-start" v-else @click="conditions.add_task=true;forms.create_task.space_id=space_id || ''">
        <div class="TODO-taksTable-bodyRow-column-text">
          <b-icon icon="plus-lg" class="mr-3" />
          Add task
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import required from 'vuelidate/lib/validators/required'
import { mapGetters } from 'vuex';
import maxLength from 'vuelidate/lib/validators/maxLength';
import minLength from 'vuelidate/lib/validators/minLength';
import moment from 'moment';
export default {
  name: 'AddTask',
  props: {
    space_id: {
      type: [String,Number],
      default: '',
    },
    spaces: {
      type: Object,
      default: ()=>({})
    },
    id: {
      type: [String,Number],
      default: '',
    },
    id_type: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      conditions: {
        add_task: false,
      },
      api: {
        create_task: this.$helperFunction.apiInstance({
          validation_errors: true,
        }),
      },
      forms: {
        create_task: this.$helperFunction.formInstance({
          data: {
            title: '',
            space_id: '',
          },
        })
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
  },
  validations: {
    forms: {
      create_task: {
        title: {
          required: required,
          minLength: minLength(1),
          maxLength: maxLength(150),
        },
        space_id: {
          required: required,
        }
      },
    },
  },
  methods: {
    async createTask(){
      this.forms.create_task.submitted=true
      this.$v.forms.create_task.$touch()
      if(this.api.create_task.send || this.$v.forms.create_task.$invalid) return;
      try {
        this.api.create_task.send=true
        this.api.create_task.validation_errors={}
        let status_id = '', group_id = '', priority = '', due_date = ''
        if(this.id) {
          if(this.id_type=='status') status_id = this.id
          else if(this.id_type=='group') group_id = this.id
          else if(this.id_type=='priority') priority = this.id
          else if(this.id_type=='due_date') due_date = moment(this.id, 'YYYY-MM-DD').format('YYYY-MM-DD HH:mm')
        }
        console.log(status_id,group_id,priority,due_date,this.id_type,this.id)
        await VOIP_API.endpoints.tasklist.create({
          title: this.forms.create_task.title,
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          space_id: this.forms.create_task.space_id,
          ...status_id ? { status_id: status_id } : {},
          ...group_id ? { group_id: group_id } : {},
          ...priority ? { priority: priority } : {},
          ...due_date ? { due_date: due_date } : {},
        })
        this.$emit('created')
        this.$emit('update-list')
        this.conditions.add_task=false
        this.forms.create_task.reset()
        this.appNotify({
          message: 'Successfully created',
          type: 'success',
        })
      } catch (ex) {
        this.api.create_task.validation_errors=ex.own_errors ?? {}
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.create_task.send=false
      }
    },
  },
}
</script>

<style>

</style>