<template>
<div>
  <div class="mainHeading withButton">
   Unused days
  </div>
  <div class="mainDescription mb-4">
    Currently available days from selected policy, shown by the number of employees & split by teams.
  </div>
  <div class="dropdownsContainer flex-column align-items-start">
    <b-dropdown no-caret class="primaryBorderDropdown mt-16px">
      <template #button-content>
        <div class="button-content">
          <div class="buttonContentText">Holiday UK</div>
          <b-icon class="downIcon" icon="caret-down-fill" />
        </div>
      </template>
      <b-dropdown-item >
        Holiday UK
      </b-dropdown-item>
    </b-dropdown>
  </div>
  <div class="w-100 d-flex align-items-center justify-content-center mt-5">
    <canvas id="unused-days-chart"></canvas>
  </div>
</div>
</template>

<script>
import { HRM } from '@/data'
import Chart from "chart.js";
import ChartDataLabels from 'chartjs-plugin-datalabels';
// Chart.register(ChartDataLabels);
export default {
  name:'UnusedDays',
  data() {
    return {
      unusedDaysChart: {}
    }
  },
  methods: {
    async barChart() {
      const ctx = document.getElementById("unused-days-chart");
      this.unusedDaysChart?.destroy?.();
      this.unusedDaysChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: HRM.charts.unusedDays.labels,
            datasets: [
              {
                label: '<= 0',
                data: HRM.charts.unusedDays.data,
                backgroundColor: [
                  '#c7384f', '#c7384f', '#c7384f', '#c7384f', '#c7384f', '#c7384f',
                ],
                hoverBackgroundColor: [
                  '#c7384f', '#c7384f', '#c7384f', '#c7384f', '#c7384f', '#c7384f',
                ],
                borderColor: [
                  '#c7384f', '#c7384f', '#c7384f', '#c7384f', '#c7384f', '#c7384f',
                ],
                borderWidth: 1
            },
            {
                label: '0 - 5',
                backgroundColor: [
                  '#5576d1',
                ],
                hoverBackgroundColor:[
                  '#5576d1',
                ],
                borderColor: [
                  '#5576d1'
                ],
                borderWidth: 1
            },
            {
                label: '5 - 10',
                backgroundColor: [
                  '#1a9211',
                ],
                hoverBackgroundColor:[
                  '#1a9211',
                ],
                borderColor: [
                  '#1a9211'
                ],
                borderWidth: 1
            },
            {
                label: '10+',
                backgroundColor: [
                  '#7b67ff',
                ],
                hoverBackgroundColor:[
                  '#7b67ff',
                ],
                borderColor: [
                  '#7b67ff'
                ],
                borderWidth: 1
            }
          ]
        },
        options: {
          lineTension: 1,
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          scales: {
            xAxes: [{
                stacked: true,
                gridLines: {
                  display:false,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Cities'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
            }],
            yAxes: [{
                stacked: true,
                gridLines: {
                  // display:false,
                  drawBorder: false,
                },
                scaleLabel: {
                 
                  // beginAtZero: true,
                  display: true,
                  labelString: 'Time Slot'
                },
                ticks: {
                  min: 0,
                  max: 4,
                  stepSize: 0.5,
                },
            }]
          },
          plugins: {
            // Change options for ALL labels of THIS CHART
            datalabels: {
              color: '#FFFFFF'
            }
          }
        },
        plugins: [ChartDataLabels],
      });
      this.unusedDaysChart.update();
    },
  },
  mounted(){
    this.barChart()
  }
}
</script>

<style>

</style>