<template>
  <div class="position-relative">
    <div class="flow__graph designed designedAgain responsiveGraph scaleDown-0-6" ref="app_designed" id="app_designed">
      <div v-if="number" class="w-100 d-flex justify-content-center align-items-center flex-column">
        <div class="w-auto d-flex justify-content-center align-items-start flex-column" >
          <div class="w-auto">
            <NumbersNode :dids="[number]" />
            <div class="w-100 d-flex align-items-center flex-column">
              <div class="nodeBasic-new" >
                <div class="nodeBasic-heading mb-8px">Assign to</div>
                <div v-if="number.assignedToUsersArr && number.assignedToUsersArr.extn">
                  <Info :id="number.assignedToUsersArr.extn" :is_blf="false" />
                  <vb-audio-player 
                    v-if="getVoipIVRSAlises[number.assignedToUsersArr.extn]" 
                    :design="'minimum'" 
                    style="position: relative;" 
                    :src="getVoipIVRSAlises[number.assignedToUsersArr.extn] | welcomgreetingsrc" 
                    class="audioForMobile"
                  />
                </div>
                <div class="d-flex justify-content-end align-items-center w-100 mt-3">
                  <b-icon @click="updateExtension()" icon="trash-fill" variant="primary" class="cursor_pointer mr-2"/>
                  <b-icon 
                    style="position: relative;" 
                    @click="$modal.show('CallForwardingSelectExtensionModalForGraph',{
                      type: 'setting',
                    })" 
                    icon="pencil-fill" 
                    variant="primary" 
                    class="cursor_pointer" 
                  />
                </div>
              </div>
            </div>
          </div>
          <div :class="`mb-3 d-flex align-items-start position-relative`">
            <div>
              <div class="d-flex justify-content-center" style="width: 200px;">
                <div class="lineWithArrow ">
                  <div class="lineWithArrow-line"></div>
                  <div class="lineWithArrow-arrow"></div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="nodeBasic-new" >
                  Shared with
                  <div :class="`d-flex justify-content-end align-items-center w-100 mt-3 position-relative`">
                    <b-icon 
                      style="position: relative;" 
                      @click="$modal.show('UserCallerIdShared2', {
                        extensions: number?.extensions ?? [], 
                        number: number, 
                        type: 'UserCallerIdShared'
                      })" 
                      icon="pencil-fill" 
                      variant="primary" 
                      class="cursor_pointer ml-3" 
                    />
                  </div>
                </div>
                <div v-if="number.html.length>0" class="lineWithArrow right longLine-150px pt-0 d-flex align-items-center">
                  <div class="lineWithArrow-line "></div>
                </div>  
              </div>
            </div>
            <template v-if="number.html.length>0">
              <!-- <div class="lineWithArrow right longLine-150px pt-0 d-flex align-items-center">
                <div class="lineWithArrow-line "></div>
              </div> -->
              <div class="nodeBasic-new mt-auto">
                <div v-for="user in firstFourShared" :key="user" class="mb-12px">
                  <Info :id="user | filterExtensionValue" :type="'USER'" :is_blf="false" />
                </div>
                <div v-if="number.html.length>4" class="d-flex justify-content-end w-100 mt-4">
                  <div style="color: rgb(85, 118, 209); font-weight: 600; font-size: 16px;"> {{ number.html.length - 4 }} more members </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </div>
    <ScheduleAssignModal 
      :disableCurrentUser="false"
      :modalName="`CallForwardingSelectExtensionModalForGraph`" 
      @interface="updateExtension($event)" 
    />
    <AddRemoveFromList modalName="UserCallerIdShared2" @update-list="getNumbers()" />
  </div>
</template>

<script>
import NumbersNode from "../common/custom/NumbersNode.vue";
import ScheduleAssignModal from "../../Modals/ScheduleAssignModal.vue";
import AddRemoveFromList from "../../Modals/AddRemoveFromList.vue";
import Info from "../../Lists/Info.vue";
import { VOIP_API } from '../../../utils';
import { mapGetters } from 'vuex';
import _ from 'lodash'
import { welcomgreetingsrc } from '@/filter';

export default {
  name: 'NumberGraphMain',
  components:{
    NumbersNode,
    Info,
    ScheduleAssignModal,
    AddRemoveFromList,
  },
  inject: ['getNumbers','appNotify'],
  props:{
    id: {
      type: [String,Number]
    }
  },
  computed: {
    ...mapGetters([
      'getVoipNumbers',
      'getCurrentUser',
      'getVoipIVRSAlises',
    ]),
    number(){ return this.getVoipNumbers.find(i=>i.real_id==this.id) },
    shared(){ return this.number?.html ?? [] },
    firstFourShared(){ return _.take(this.shared,4) },
  },
  filters: {
    filterExtensionValue(value) { return value.replace('<span class="label label-primary">','').replace('</span>','').slice(0,4) },
    welcomgreetingsrc: welcomgreetingsrc,
  },
  methods:{
    async updateExtension($event) {
      await VOIP_API.endpoints.telephone_number.updateExtn(this.number.real_id, {
        extn: $event?.account ?? '',
        field: 'extn',
      })
      this.getNumbers()
      this.appNotify({
        message: "Successfully Added!",
        type: "success",
      })
    },
  },
}
</script>

<style>

</style>