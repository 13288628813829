var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation center-zoomIn-popup dialer-notificationModal KeyboardShortcutsModal",
    attrs: {
      "name": "KeyboardShortcutsModal"
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 d-flex align-items-center justify-content-between w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Keyboard shortcuts modal")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('KeyboardShortcutsModal');
      }
    }
  }) : _c('a', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('KeyboardShortcutsModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)], 1)]), _c('div', {
    staticClass: "mt-20px latestGreyBox-9-9-2023"
  }, [_c('ul', {
    staticClass: "list-unstyled whiteBoxWithBorderInsideGreyBox"
  }, [_c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Open shortcuts modal")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ctrl + /")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("home")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Alt + 1")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("chat")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Alt + 2")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("analytics")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Alt + 3")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("call Dashboard")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Alt + 4")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("schedule")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Alt + 5")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("settings")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Alt + 6")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("timeline")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Alt + 7")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Open SpeedDials")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ctrl + shift + 1")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Close SpeedDials")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ctrl + shift + 1")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Open Users")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ctrl + shift + 2")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Close Users")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ctrl + shift + 2")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Open Departments")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ctrl + shift + 3")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Close Departments")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ctrl + shift + 3")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Open Addressbooks")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ctrl + shift + 4")])]), _c('li', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Close Addressbooks")]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Ctrl + shift + 4")])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }