<template>
  <div>
    <div class="d-flex flex-column dialer-parent dialer-light-mode insideAnroidIOSApp">
      <div id="dialer-parent-inner" class="dialer-parent-inner" style="height: 100vh;">
        <div class="d-flex h-100">
          <div class="dialer-main-center-area d-flex flex-column h-100 settingsIsOpened insideAnroidIOSApp">
            <div class="w-100 h-100 d-flex align-items-center justify-content-center font-weight-bold font-20 text-center">
              Authentication Failed, Please login again
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { MOBILE_APP } from '@/mobile-app'
import { MOBILE_APP_SEND_EVENTS } from '@/mobile-app/events'
export default {
  name: 'MobileAppAuthenticationFailed',
  mounted(){
    MOBILE_APP.sendMessage(MOBILE_APP_SEND_EVENTS.AUTH_FAILED)
  },
}
</script>

<style lang="scss">
</style>