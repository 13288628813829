var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "EditSpaceModal smallerModal",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header w-100 d-flex position-relative py-0 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title my-0"
  }, [_vm.conditions.screen == _vm.screens.space ? [_vm._v("Move task")] : _vm._e(), _vm.conditions.screen == _vm.screens.status ? [_vm._v("Task status")] : _vm._e(), _vm.conditions.screen == _vm.screens.group ? [_vm._v("Task group")] : _vm._e()], 2), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "createSpace-greyBox mt-50px"
  }, [_vm.conditions.screen == _vm.screens.space ? _c('div', {
    staticClass: "createSpace-whiteBorderedBox"
  }, [_c('b-form-radio-group', {
    attrs: {
      "name": "radio-sub-component"
    },
    model: {
      value: _vm.selected.space_id,
      callback: function ($$v) {
        _vm.$set(_vm.selected, "space_id", $$v);
      },
      expression: "selected.space_id"
    }
  }, _vm._l(_vm.response.spaces, function (space) {
    return _c('b-form-radio', {
      key: space.id,
      staticClass: "radio_btn_like_checkbox makeCheckBoxesSquare createSpace-whiteBorderedBox-row",
      attrs: {
        "value": space.id
      }
    }, [_c('div', {
      staticClass: "createSpace-whiteBorderedBox-row"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-container w-auto mr-3 ml-0 my-0"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item smaller selected",
      class: {
        'TransparentSelected': 'rgba(0,0,0,0)'
      },
      style: `background-color:${space.color};`
    })]), _c('div', {
      staticClass: "TODO-smallInnerHeading"
    }, [_vm._v(_vm._s(space.name))])])])]);
  }), 1), _vm.response.spaces.length == 0 ? _c('vb-no-record', {
    staticClass: "my-4",
    attrs: {
      "text": _vm.api.spaces.send ? '' : 'There is no space',
      "design": 3
    }
  }, [_vm.api.spaces.send ? _c('div', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }, _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "createSpace-whiteBorderedBox-row"
    }, [_c('div', {
      staticClass: "latestShimmerDesign w-25",
      staticStyle: {
        "height": "24px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign w-25",
      staticStyle: {
        "height": "24px"
      }
    })]);
  }), 0) : _vm._e()]) : _vm._e()], 1) : _vm.conditions.screen == _vm.screens.status ? _c('div', {
    staticClass: "createSpace-whiteBorderedBox"
  }, [_c('b-form-radio-group', {
    attrs: {
      "disabled": _vm.api.move_task.send,
      "name": "radio-sub-component"
    },
    model: {
      value: _vm.selected.status_id,
      callback: function ($$v) {
        _vm.$set(_vm.selected, "status_id", $$v);
      },
      expression: "selected.status_id"
    }
  }, _vm._l(_vm.response.status, function (status) {
    return _c('b-form-radio', {
      key: status.id,
      staticClass: "radio_btn_like_checkbox makeCheckBoxesSquare createSpace-whiteBorderedBox-row",
      attrs: {
        "value": status.id
      }
    }, [_c('div', {
      staticClass: "createSpace-whiteBorderedBox-row"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-container w-auto mr-3 ml-0 my-0"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item smaller selected",
      class: {
        'TransparentSelected': 'rgba(0,0,0,0)'
      },
      style: `background-color:${status.color};`
    })]), _c('div', {
      staticClass: "TODO-smallInnerHeading"
    }, [_vm._v(_vm._s(status.title))])])])]);
  }), 1), _vm.response.status.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.status.send ? '' : 'There is no status',
      "design": 3
    }
  }, [_vm.api.status.send ? _c('div', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }, _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "createSpace-whiteBorderedBox-row"
    }, [_c('div', {
      staticClass: "latestShimmerDesign w-25",
      staticStyle: {
        "height": "24px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign w-25",
      staticStyle: {
        "height": "24px"
      }
    })]);
  }), 0) : _vm._e()]) : _vm._e()], 1) : _vm.conditions.screen == _vm.screens.group ? _c('div', {
    staticClass: "createSpace-whiteBorderedBox"
  }, [_c('b-form-radio-group', {
    attrs: {
      "disabled": _vm.api.move_task.send,
      "name": "radio-sub-component"
    },
    model: {
      value: _vm.selected.group_id,
      callback: function ($$v) {
        _vm.$set(_vm.selected, "group_id", $$v);
      },
      expression: "selected.group_id"
    }
  }, _vm._l(_vm.response.groups, function (groups) {
    return _c('b-form-radio', {
      key: groups.id,
      staticClass: "radio_btn_like_checkbox makeCheckBoxesSquare createSpace-whiteBorderedBox-row",
      attrs: {
        "value": groups.id
      }
    }, [_c('div', {
      staticClass: "createSpace-whiteBorderedBox-row"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-container w-auto mr-3 ml-0 my-0"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item smaller selected",
      class: {
        'TransparentSelected': 'rgba(0,0,0,0)'
      },
      style: `background-color:${groups.color};`
    })]), _c('div', {
      staticClass: "TODO-smallInnerHeading"
    }, [_vm._v(_vm._s(groups.name))])])])]);
  }), 1), _vm.response.groups.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.groups.send ? '' : 'There is no group',
      "design": 3
    }
  }, [_vm.api.groups.send ? _c('div', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }, _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "createSpace-whiteBorderedBox-row"
    }, [_c('div', {
      staticClass: "latestShimmerDesign w-25",
      staticStyle: {
        "height": "24px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign w-25",
      staticStyle: {
        "height": "24px"
      }
    })]);
  }), 0) : _vm._e()]) : _vm._e()], 1) : _vm._e()]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mt-38px"
  }, [_vm.data.select == 'space' && _vm.conditions.screen != _vm.screens.space ? _c('div', {
    staticClass: "d-flex align-items-center",
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "disabled": _vm.api.move_task.send
    },
    on: {
      "click": function ($event) {
        return _vm.onBack();
      }
    }
  }, [_c('b-icon', {
    staticClass: "darker-chevron-down mx-0",
    attrs: {
      "icon": "chevron-left"
    }
  }), _c('b', {
    staticClass: "darker-chevron-down ml-1"
  }, [_vm._v("Back")])], 1) : _vm._e(), _c('b-button', {
    staticClass: "newDoneButton ml-auto",
    on: {
      "click": function ($event) {
        return _vm.onNext();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm.conditions.screen == _vm.screens.group ? [_vm._v("Save")] : [_vm._v("Next")]], 2)], 1)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }