var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Blackout dates "), _c('div', {
    staticClass: "d-flex"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('hrmAddNewBlackoutDateModal');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "plus",
      "font-scale": "1.3",
      "scale": "1.5"
    }
  }), _vm._v(" Add blackout day ")], 1)])]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" These are days during which your employees won't be allowed to book any leave. ")]), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Allow to override blackout date for selected policies")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Holiday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Sickday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Holiday - UK ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Holiday - PAK ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Holiday - NL ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Business trip ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Sickday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Conference ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Holiday - USA ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Work from home (UK) ")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px w-fit-content mr-0"
  }, [_vm._v(" Save ")])], 1), _c('modal', {
    staticClass: "dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll",
    attrs: {
      "name": "hrmAddNewBlackoutDateModal"
    }
  }, [_c('div', [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Blackout date")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('hrmAddNewBlackoutDateModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Name")]), _c('input')]), _c('div', {
    staticClass: "d-flex mt-25px"
  }, [_c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("From")]), _c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('input', {
    staticClass: "withIconOnRight primaryBorder noInnerBoxShadow"
  }), _c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  })], 1)]), _c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("To")]), _c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('input', {
    staticClass: "withIconOnRight primaryBorder noInnerBoxShadow"
  }), _c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  })], 1)])]), _c('div', {
    staticClass: "innerShadowInput-container mt-24px"
  }, [_c('label', [_vm._v("Employees assigned:")]), _c('input', {
    attrs: {
      "placeholder": "Search by name"
    }
  })]), _c('div', {
    staticClass: "usersList-withCheckBox-container"
  }, [_c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Select All ")]), _vm._l(5, function (n) {
    return _c('b-form-checkbox', {
      key: n,
      staticClass: "HrmCheckBox-container"
    }, [_c('div', {
      staticClass: "nameWithImage"
    }, [_c('div', {
      staticClass: "imageContainer"
    }, [_c('img', {
      attrs: {
        "src": "/img/1.dd976805.jpeg"
      }
    })]), _c('div', {
      staticClass: "nameWithImage-text"
    }, [_vm._v("Marie jane")])])]);
  })], 2), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Create ")])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }