export default {
  state: {
    web: {
      dashboard: {
        name: 'web-dashboard',
        steps: [
          {
            target: '[app-tour-web-dashboard-dialer]',
            header: {
              title: 'Dashboard',
            },
            // content: `There you can open the dashboard`,
            content: `Hello, This is the dialler page of VoIP Business!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-chat]',
            header: {
              title: 'Chat',
            },
            // content: `There you can open the chat`,
            content: `Here you can chat with your friends!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-meeting]',
            header: {
              title: 'Meeting',
            },
            // content: `There you can open the meeting`,
            content: `This is the conference room, start a meeting here!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-analytics]',
            header: {
              title: 'Analytics',
            },
            // content: `There you can open the analytics`,
            content: `You can access your complete call analytics here!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-monitor]',
            header: {
              title: 'Monitor Dashboard',
            },
            // content: `There you can open the monitor dashboard`,
            content : `Monitor your entire team's activity here!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: true,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-settings]',
            header: {
              title: 'Settings',
            },
            // content: `There you can open the settings`,
            content: `Access your apps settings here!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-media-device]',
            header: {
              title: 'Media Devices Setting',
            },
            content: `Test your AV settings here!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-speed-dials]',
            header: {
              title: 'Speed Dials',
            },
            content: `You can add speed dial shortcuts through this function!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-users]',
            header: {
              title: 'Users',
            },
            content: `View all your colleagues here!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-teams]',
            header: {
              title: 'Teams',
            },
            content: `You can manage your call queues and teams here!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-addressbook]',
            header: {
              title: 'Addressbook',
            },
            content: `All your contacts are saved here!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-support-ticket]',
            header: {
              title: 'Support Ticket',
            },
            content: `Let us know if you are facing any problem here!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
        ],
        options: {
          highlight: false,
        },
        started: false,
        sub: {
          media_setting: {
            name: 'web-dashboard-media-setting',
            steps: [
              {
                target: '[web-dashboard-media-setting-microphone]',
                header: {
                  title: 'Microphone Setting',
                },
                content: `Select your microphone input!`,
                params: {
                  placement: 'top',
                  // enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
              {
                target: '[web-dashboard-media-setting-speaker]',
                header: {
                  title: 'Speaker Setting',
                },
                content: `which speaker device do you want to choose when on a call? Choose here!`,
                params: {
                  placement: 'top',
                  // enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
              {
                target: '[web-dashboard-media-setting-video]',
                header: {
                  title: 'Video Setting',
                },
                content: `which camera device do you want to use, select here!`,
                params: {
                  placement: 'top',
                  // enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
              {
                target: '[web-dashboard-media-setting-ringing-device]',
                header: {
                  title: 'Ringing Setting',
                },
                content: `which device do you want to ring, when an inbound call comes!`,
                params: {
                  placement: 'top',
                  // enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
              {// hide this
                target: '[web-dashboard-media-setting-incoming-ringtone]',
                header: {
                  title: 'Incoming Ringtone',
                },
                content: `There you can select the incoming ringtone`,
                params: {
                  placement: 'top',
                  // enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
              {// hide this
                target: '[web-dashboard-media-setting-alert-ringtone]',
                header: {
                  title: 'Alert Ringtone',
                },
                content: `There you can select the alert ringtone`,
                params: {
                  placement: 'top',
                  // enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
              {// hide this
                target: '[web-dashboard-media-setting-send-message-ringtone]',
                header: {
                  title: 'Send Message Ringtone',
                },
                content: `There you can select the send message ringtone`,
                params: {
                  placement: 'top',
                  // enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
              {// hide this
                target: '[web-dashboard-media-setting-recieve-message-ringtone]',
                header: {
                  title: 'Receive Message Ringtone',
                },
                content: `There you can select the receive message ringtone`,
                params: {
                  placement: 'top',
                  // enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: false,
            },
            started: false,
          },
          speed_dial: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          users: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          teams: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          addressbook: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          help: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
        },
      },
      dialer: {
        name: 'web-dashboard-dialer',
        steps: [
          {//hide this
            target: '[app-tour-web-dashboard-dialer-input]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `There you can open the dashboard`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-dialer-select-cid]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `This shows the range of numbers through which you can call`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {//hide this
            target: '[app-tour-web-dashboard-dialer-dialpad]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `There you can open the dashboard`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-dialer-design-adjust]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `You can customise your dashboard here! `,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-dialer-call-logs-filter]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `Apply your desired filter here!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
          {
            target: '[app-tour-web-dashboard-dialer-call-logs-reload]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `Want to view your latest call activity? Press this button!`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
        ],
        options: {
          highlight: true,
        },
        started: false,
      },
      chat: {
        name: 'web-dashboard-chat',
        steps: [
          {
            target: '[app-tour-web-dashboard-dialer-input]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `There you can open the dashboard`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
        ],
        options: {
          highlight: true,
        },
        started: false,
      },
      meeting: {
        name: 'web-dashboard-chat',
        steps: [
          {
            target: '[app-tour-web-dashboard-dialer-input]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `There you can open the dashboard`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
        ],
        options: {
          highlight: true,
        },
        started: false,
      },
      analytics: {
        name: 'web-dashboard-chat',
        steps: [
          {
            target: '[app-tour-web-dashboard-dialer-input]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `There you can open the dashboard`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
        ],
        options: {
          highlight: true,
        },
        started: false,
      },
      monitor: {
        name: 'web-dashboard-chat',
        steps: [
          {
            target: '[app-tour-web-dashboard-dialer-input]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `There you can open the dashboard`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
        ],
        options: {
          highlight: true,
        },
        started: false,
      },
      setting: {
        name: 'web-dashboard-chat',
        steps: [
          {
            target: '[app-tour-web-dashboard-dialer-input]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `There you can open the dashboard`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
        ],
        options: {
          highlight: true,
        },
        started: false,
        sub: {
          my_numbers: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          calls: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          phones: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          sound_library: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          voicemail: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          profile: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          users: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          teams: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          numbers: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          ivr: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          call_queue: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          moh: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          block: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          call_recording: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          billing: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          global_setting: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          locations: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
          integrations: {
            name: 'web-dashboard-chat',
            steps: [
              {
                target: '[app-tour-web-dashboard-dialer-input]',
                header: {
                  title: 'Move To Dashboard',
                },
                content: `There you can open the dashboard`,
                params: {
                  placement: 'top',
                  enableScrolling: false
                },
                is_admin: false,
                permission: false,
              },
            ],
            options: {
              highlight: true,
            },
            started: false,
          },
        }
      },
      sip_calls: {
        name: 'web-dashboard-chat',
        steps: [
          {
            target: '[app-tour-web-dashboard-dialer-input]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `There you can open the dashboard`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
        ],
        options: {
          highlight: true,
        },
        started: false,
      },
      jitsi_calls: {
        name: 'web-dashboard-chat',
        steps: [
          {
            target: '[app-tour-web-dashboard-dialer-input]',
            header: {
              title: 'Move To Dashboard',
            },
            content: `There you can open the dashboard`,
            params: {
              placement: 'top',
              enableScrolling: false
            },
            is_admin: false,
            permission: false,
          },
        ],
        options: {
          highlight: true,
        },
        started: false,
      },
    }
  },
  getters: {
    getWebDashboardTour(state){ //, getters, rootState, rootGetters 
      return {
        name: state.web.dashboard.name,
        // steps: state?.web?.dashboard?.steps?.filter?.((i)=>{ 
        //   if(i.is_admin) return !!rootGetters?.getCurrentUser?.administrator_account
        //   else return true
        // }) ?? [],
        steps: state.web.dashboard.steps,
        options: state.web.dashboard.options,
        started: state.web.dashboard.started,
      }
    },
    getWebDashboardDialerTour(state){
      return {
        name: state.web.dialer.name,
        steps: state.web.dialer.steps,
        options: state.web.dialer.options,
        started: state.web.dialer.started,
      }
    },
    isAppTourStarted(state){ 
      return state.web.dashboard.started ||
      state.web.dashboard.sub.media_setting.started ||
      state.web.dashboard.sub.speed_dial.started ||
      state.web.dashboard.sub.users.started ||
      state.web.dashboard.sub.teams.started ||
      state.web.dashboard.sub.addressbook.started ||
      state.web.dashboard.sub.help ||
      state.web.dialer.started ||
      state.web.chat.started ||
      state.web.meeting.started ||
      state.web.analytics.started ||
      state.web.monitor.started ||
      state.web.setting.started ||
      state.web.setting.sub.my_numbers.started ||
      state.web.setting.sub.calls.started ||
      state.web.setting.sub.phones.started ||
      state.web.setting.sub.sound_library.started ||
      state.web.setting.sub.voicemail.started ||
      state.web.setting.sub.profile.started ||
      state.web.setting.sub.users.started ||
      state.web.setting.sub.teams.started ||
      state.web.setting.sub.numbers.started ||
      state.web.setting.sub.ivr.started ||
      state.web.setting.sub.call_queue.started ||
      state.web.setting.sub.moh.started ||
      state.web.setting.sub.block.started ||
      state.web.setting.sub.call_recording.started ||
      state.web.setting.sub.billing.started ||
      state.web.setting.sub.global_setting.started ||
      state.web.setting.sub.locations.started ||
      state.web.setting.sub.integrations.started ||
      state.web.sip_calls.started ||
      state.web.jitsi_calls.started
    },
  },
  mutations: {
    setTourStarted(state,message){
      switch (message?.type) {
        case state.web.dashboard.name:
          state.web.dashboard.started=!!message?.started
          break;
        case state.web.dialer.name:
          state.web.dialer.started=!!message?.started
          break;
        default:
          break;
      }
    },
  },
};