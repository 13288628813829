<template>
	<div class="right-calender">
			<div class="top-main-calender scrollow1">
				<section class="sec-common">
					<div class="d-flex justify-content-between align-items-center">
						<div>
							<h5 class="emp-profile-heading">Assets</h5>
						</div>
						<button class="btn btn-primarymike space4btn-when-scroll-activate" @click="$modal.show('HRMAddNewAssetModal')">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.004" height="12.803" viewBox="0 0 16.004 12.803" class="mr-2">
                <path id="Icon_awesome-tags" data-name="Icon awesome-tags" d="M12.452,5.65l-5.3-5.3A1.2,1.2,0,0,0,6.3,0H1.2A1.2,1.2,0,0,0,0,1.2V6.3a1.2,1.2,0,0,0,.352.849l5.3,5.3a1.2,1.2,0,0,0,1.7,0l5.1-5.1A1.2,1.2,0,0,0,12.452,5.65ZM2.8,4A1.2,1.2,0,1,1,4,2.8,1.2,1.2,0,0,1,2.8,4ZM15.652,7.347l-5.1,5.1a1.2,1.2,0,0,1-1.7,0l-.009-.009L13.194,8.09a2.251,2.251,0,0,0,0-3.183L8.287,0H9.505a1.2,1.2,0,0,1,.849.352l5.3,5.3a1.2,1.2,0,0,1,0,1.7Z" fill="#fff"/>
              </svg>
							Give asset
						</button>
					</div>
					<div>
          <p class="notes-top-text space4text-when-scroll-activate">
            Here is the list of company assets currently given to Kendal Jenner
          </p>
					</div>
					<div class="mt-5 mb-4 emp-asset-table-main" >
							<div class="tb-task-upper-part">
								<h5 class="commonth asset-firsttd col-left ">Name</h5>
								<h5 class="commonth asset-secondtd ">Category</h5>
								<h5 class="commonth asset-thirdtd">Description</h5>
								<h5 class="commonth asset-fourtd">Serial Number</h5>
								<h5 class="commonth asset-fivetd">Date</h5>
								<h5 class="commonth asset-sixtd col-right">Action</h5>
							</div>
							<div class="tab-task-bg-border">
								<table class="asset-table-hrm">
									<thead>
									</thead>
									<tbody>
										<tr>
											<td class="asset-firsttd">
												Laptop
											</td>
											<td class="asset-secondtd">
												Computer
											</td>
											
											<td class="asset-thirdtd ">
												One Lenovo Laptop do not break it or I will break your legs!
											</td>
											<td class="asset-fourtd">
												445566
											</td>
											<td class="asset-fivetd">
												27/07/2022
											</td>
											<td class="asset-sixtd">
												
													<div class="d-flex justify-content-end">
														<b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
															<template #button-content>
																<b-icon icon="three-dots-vertical"></b-icon>
															</template>
															<b-dropdown-item href="#">Action</b-dropdown-item>
														</b-dropdown>
													</div>
											</td>
										</tr>
										<tr>
											<td class="asset-firsttd">
												Laptop
											</td>
											<td class="asset-secondtd">
												Computer
											</td>
											
											<td class="asset-thirdtd ">
												One Lenovo Laptop do not break it or I will break your legs!
											</td>
											<td class="asset-fourtd">
												445566
											</td>
											<td class="asset-fivetd">
												27/07/2022
											</td>
											<td class="asset-sixtd">
												
													<div class="d-flex justify-content-end">
														<b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
															<template #button-content>
																<b-icon icon="three-dots-vertical"></b-icon>
															</template>
															<b-dropdown-item href="#">Action</b-dropdown-item>
														</b-dropdown>
													</div>
											</td>
										</tr>
									</tbody>
								</table>
							</div>
							
					</div>	
					<vb-table :tableDesign="1" class="assettablewidth">
            <template slot="header">
							<h5 class="commonth asset-firsttd col-left ">Name</h5>
							<h5 class="commonth asset-secondtd ">Category</h5>
							<h5 class="commonth asset-thirdtd">Description</h5>
							<h5 class="commonth asset-fourtd">Serial Number</h5>
							<h5 class="commonth asset-fivetd">Date</h5>
							<h5 class="commonth asset-sixtd col-right">Action</h5>
						</template>
						<template slot="body">
							<tr>
								<td class="asset-firsttd">
									Laptop
								</td>
								<td class="asset-secondtd">
									Computer
								</td>
								
								<td class="asset-thirdtd ">
									One Lenovo Laptop do not break it or I will break your legs!
								</td>
								<td class="asset-fourtd">
									445566
								</td>
								<td class="asset-fivetd">
									27/07/2022
								</td>
								<td class="asset-sixtd">
									<div class="d-flex justify-content-end commonTableMain">
										<b-dropdown no-caret class="common-table-dd">
										<template #button-content>
											<b-icon icon="three-dots-vertical"></b-icon>
										</template>
										<b-dropdown-item href="#">Action</b-dropdown-item>
										</b-dropdown>
									</div>
								</td>
							</tr>
							<tr>
								<td class="asset-firsttd">
									Laptop
								</td>
								<td class="asset-secondtd">
									Computer
								</td>
								<td class="asset-thirdtd ">
									One Lenovo Laptop do not break it or I will break your legs!
								</td>
								<td class="asset-fourtd">
									445566
								</td>
								<td class="asset-fivetd">
									27/07/2022
								</td>
								<td class="asset-sixtd">
                  <div class="d-flex justify-content-end commonTableMain">
                    <b-dropdown no-caret class="common-table-dd">
                      <template #button-content>
                        <b-icon icon="three-dots-vertical"></b-icon>
                      </template>
                      <b-dropdown-item href="#">Action</b-dropdown-item>
                    </b-dropdown>
                  </div>
								</td>
							</tr>
						</template>
					</vb-table>
				</section>
			</div>
		<HRMAddNewAssetModal/>
	</div>
</template>

<script>
import HRMAddNewAssetModal from "../Modals/HRMAddNewAssetModal.vue"
export default {
  name: 'AssetHRM',
  components:{
	HRMAddNewAssetModal,
  },
}
</script>

<style>

</style>