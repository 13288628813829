import _ from "lodash";
import moment from 'moment'
import { TokenService } from "../../services";
import { $fn, VOIP_API } from "../../utils/index";
import { number_formater } from "@/filter";

export const common = {
  state: {
    speeddials: [],
    global_speeddials: [],
    voipusers: {},
    deleted_voipusers: {},
    departments: [],
    addressbooks: [],
    callActivity: [],
    call_activity: {
      data: [],
      page: 1,
      total: 0,
      is_completed: false,
      loading: false,
    },
    teams: {
      data: [],
      total: 0,
    },
    call_queues: {
      data: [],
      total: 0,
    },
    ivr: {
      data: {},
      total: 0,
    },
    muted_channels: {},
    user: {},
    statuses: [],
    call_tags: [],
    speed_dials: {
      local: {
        data: [],
        total: 0,
      },
      global: {
        data: [],
        total: 0,
      },
    },
    address_books: {
      data: [],
      per_page: 10,
      current_page: 1,
      next_link: '',
      total: 0,
      total_pages: 0,
      behind_numbers: 0,
    },
    numbers: [],
    features: {
      'desktop-notification': 'default',
      'chat': 'default',
      'meeting': 'default',
      'analytics': 'default',
      'share-sound-file': 'default',
      'add-sound-file': 'default',
      'block-and-privacy': 'default',
      'forward-source-caller-id': 'default',
      'status': 'default',
      'call-waiting': 'default',
      'missed-call-notification': 'default',
      'out-of-hours': 'default',
      'monitor-dashboard': 'default',
      'mobile-extension': 'default',
      'incoming-call-display': 'default',
      'external-extension': 'default',
      'call-tagging-and-notes': 'default',
      'multi-moh': 'default',
      'blf': 'default',
      'unlimited-custom-status': 'default',
      'call-pickup': 'default',
      'advanced-greetings-in-voice-mail': 'default',
      'voice-mailbox-sharing': 'default',
      'voicemail-attachment': 'default',
      'voice-mail-transcript': 'default',
      'advanced-call-handling': 'default',
      'call-recordings-backup': 'default',
      'call-recording': 'default',
      'call-queue': 'default',
      'integrations': 'default',
      'custom-moh': 'default',
      'enabling-pin-for-voicemail': 'default',
      'after-call-feedback': 'default',
      'redirecting-to-another-number': 'default',
      'call-recording-transcriptions': 'default',
      'ivr-advance-options': 'default',
      'call-tags': 'default',
      'sound-library-add-sound-files': 'default',
      'task-hub': 'default',
      'enable-push-notifications': 'default',

      // 'multi-level-ivr': 'default',
      // 'unlimited-ivr': 'default',
      // 'hotdesking': 'default',
      // 'conference-callings': 'default',
      // 'domain': 'default',
      // 'arif': 'default',
      // 'yyy': 'default',
    },
    user_allowed_features: {},
    current_subscription: {},
    plan_logs: null,
    add_service_queue: {
      queue_service: true,
      id: 'service_queue_23596',
      type: '', //ivr,team,user,call_queue,number
      name: '',
      username: '',
    },
    user_accountcode: {},
    // pending_services: [],
    call_recording_setting: {},
  },
  getters: {
    getVoipUserAccountcode(state){ return $fn.manipulateUserAccountCodes(state.user_accountcode) },
    addServiceQueue(state){ return state.add_service_queue.type ? state.add_service_queue : null },
    isTrailPlan(state){ return state?.current_subscription?.usercheckTrial?.trial?.trial_plan_status=='active' },
    isGracePeriod(state){ return state?.current_subscription?.userCurrentSubscription?.subscription_status!='INACTIVE' && typeof (state?.current_subscription?.grace_period_remaining_days ?? '') == 'number' },
    isFreePlan(state){ return state?.current_subscription?.userCurrentSubscription?.plan_detail?.plan_free==1 },
    getTrialPlanDetails(state){return state?.current_subscription?.usercheckTrial?.trial},
    getVoipStatuses(state){ return state.statuses },
    getVoipCallTags(state){ return state.call_tags },
    getVoipTeams(state,) { // getters
      // return getters.getCurrentUser?.administrator_account ? state.teams.data : getters.getCurrentUserVoipTeams;
      return state.teams.data;
    },
    getVoipTeamsAlises(state,getters) {
      let obj = {
        ..._.keyBy(getters.getVoipTeams,'ringroup.accountcode'),
        ..._.keyBy(getters.getVoipTeams,'ringroup.extn'),
        ..._.keyBy(getters.getVoipTeams,'real_id'),
        ..._.keyBy(getters.getVoipTeams,'channel_id'),
      }
      delete obj.undefined
      return obj
    },
    getCurrentUserVoipTeams(state,getters) {
      return state.teams.data?.filter?.((team) => team?.members?.some?.((member) => member?.accountcode === getters.getCurrentUser?.account) ) ?? [];
    },
    getVoipCallQueues(state,getters) { 
      return getters.getCurrentUser?.administrator_account ? state.call_queues.data : getters.getCurrentUserVoipCallQueues;
    },
    getVoipCallQueuesAlises(state) {
      let obj = {
        ..._.keyBy(state.call_queues.data,'voipaccount'),
        ..._.keyBy(state.call_queues.data,'extn'),
      }
      delete obj.undefined
      return obj
    },
    getCurrentUserVoipCallQueues(state,getters) {
      return state.call_queues.data?.filter?.((call_queue) => call_queue?.members?.some?.((member) => member?.membername === getters.getCurrentUser?.account) ) ?? [];
    },
    getVoipIVRS(state) { 
      return state.ivr.data;
    },
    getVoipIVRSAlises(state,getters) { 
      let obj = {
        ..._.keyBy(getters.getVoipIVRS,'voipaccount'),
        ..._.keyBy(getters.getVoipIVRS,'extn'),
      }
      delete obj.undefined
      return obj
    },
    getVoipNumbers(state) {
      return state.numbers;
    },
    getVoipNumbersAlises(state,getters) {
      let obj = {
        ..._.keyBy(getters.getVoipNumbers,'real_id'),
      }
      delete obj.undefined
      return obj
    },
    getVoipUsers(state) {
      return state.voipusers;
    },
    getVoipUsersAlises(state) {
      let obj = {
        ..._.keyBy(state.deleted_voipusers,'user.jid_account'),
        ..._.keyBy(state.deleted_voipusers,'user.user_chat_id'),
        ..._.keyBy(state.deleted_voipusers,'user.profile_id'),
        ..._.keyBy(state.deleted_voipusers,'extn'),
        ..._.keyBy(state.deleted_voipusers,'voipaccount'),
        ..._.keyBy(state.deleted_voipusers,'web_extension'),
        ..._.keyBy(state.voipusers,'user.jid_account'),
        ..._.keyBy(state.voipusers,'user.user_chat_id'),
        ..._.keyBy(state.voipusers,'user.profile_id'),
        ..._.keyBy(state.voipusers,'extn'),
        ..._.keyBy(state.voipusers,'voipaccount'),
        ..._.keyBy(state.voipusers,'web_extension'),
      }
      delete obj.undefined
      return obj
    },
    getVoipUsersAlisesImages(state,getters) {
      return _.zipObject(Object.keys(getters.getVoipUsersAlises),Object.values(getters.getVoipUsersAlises).map((i)=>i?.profile_img ?? require("@/assets/images/user-male.png")))
    },
    getVoipUsersAlisesDisplayName(state,getters) {
      return _.zipObject(Object.keys(getters.getVoipUsersAlises),Object.values(getters.getVoipUsersAlises).map((i)=>i?.user?.display_name ?? ''))
    },
    getCurrentUser(state){
      return _.isEmpty(state.user)?TokenService.USER.get():state.user
    },
    getCurrentOrganization(state){
      return (state?.user?.organizations ?? []).find(organization=>organization.id===state?.user?.selected_organization_id)
    },
    getDepartments(state) {
      return state.departments;
    },
    getUserSpeedDials(state) {
      return state.speeddials;
    },
    getAddressbooks(state) {
      return state.addressbooks;
    },
    getCallActivity(state) {
      return state.callActivity;
    },
    getUserPlanFeatures(state){ return state.user?.features?.map?.(i=> typeof i == 'string' ? i : i.name) || [] },
    getUserAllowedFeatures(state){ return state.user_allowed_features?.map?.(i=>i?.features_assgined?.[0]?.feature_value=='enable' ? `yes|${i?.label}` : i?.features_assgined?.[0]?.feature_value=='disable' ? `no|${i?.label}` : '')?.filter?.(i=>!!i) ?? [] },
    getUserPermissions(state,getters){
      const is_standalone = state.user.appMode === 'standalone'
      function getPermission(permission){
        if(state.features[permission]=='yes') return true
        else if(state.features[permission]=='no') return false
        else if(getters.getUserAllowedFeatures.includes(`no|${permission}`)) return false
        else if(getters.getUserAllowedFeatures.includes(`yes|${permission}`)) return true
        else if(is_standalone) return true
        else if(getters.getUserPlanFeatures.includes(permission)) return true
        else return false
      }
      return {
        desktop_notification: getPermission('desktop-notification'), // done
        chat: getPermission('chat'), // done
        meeting: getPermission('meeting'), // done
        analytics: getPermission('analytics'), // done
        share_sound_file: getPermission('share-sound-file'), // done
        add_sound_file: getPermission('add-sound-file'), // done
        block_and_privacy: getPermission('block-and-privacy'), // done
        forward_source_caller_id: getPermission('forward-source-caller-id'), // done
        status: getPermission('status'), // ----
        call_waiting: getPermission('call-waiting'), // done
        missed_call_notification: getPermission('missed-call-notification'), // done
        out_of_hours: getPermission('out-of-hours'), // done
        monitor_dashboard: getPermission('monitor-dashboard'), // done
        mobile_extension: getPermission('mobile-extension'), // done
        incoming_call_display: getPermission('incoming-call-display'), // done
        external_extension: getPermission('external-extension'), // done
        call_tagging_and_notes: getPermission('call-tagging-and-notes'), // done
        multi_moh: getPermission('multi-moh'), // done
        blf: getPermission('blf'), // done
        unlimited_custom_status: getPermission('unlimited-custom-status'), // done
        call_pickup: getPermission('call-pickup'), // done
        advanced_greetings_in_voice_mail: getPermission('advanced-greetings-in-voice-mail'), // done
        voice_mailbox_sharing: getPermission('voice-mailbox-sharing'), // done
        voicemail_attachment: getPermission('voicemail-attachment'), // done
        voice_mail_transcript: getPermission('voice-mail-transcript'), // done
        advanced_call_handling: getPermission('advanced-call-handling'), // done
        call_recordings_backup: getPermission('call-recordings-backup'), // done
        call_recording: getPermission('call-recording'), // done
        call_queue: getPermission('call-queue'),  // done
        integrations: getPermission('integrations'),  // done
        custom_moh: getPermission('custom-moh'), // done
        enabling_pin_for_voicemail: getPermission('enabling-pin-for-voicemail'), // done
        after_call_feedback: getPermission('after-call-feedback'), // done
        redirecting_to_another_number: getPermission('redirecting-to-another-number'), // done
        call_recording_transcriptions: getPermission('call-recording-transcriptions'), // done
        ivr_advance_options: getPermission('ivr-advance-options'), // done
        call_tags: getPermission('call-tags'), // done
        sound_library_add_sound_files: getPermission('sound-library-add-sound-files'), // ----
        todo: getPermission('task-hub'), // done
        enable_push_notifications: getPermission('enable-push-notifications'), // done

        customer_logs: getters.getUserPlanFeatures.includes('customer_logs'), // ----

        // multi_level_ivr: getPermission('multi-level-ivr'), // ----
        // unlimited_ivr: getPermission('unlimited-ivr'), // ----
        // hotdesking: getPermission('hotdesking'), // ----
        // conference_callings: getPermission('conference-callings'), // ----
        // domain: getPermission('domain'), // ----
        // arif: getPermission('arif'), // ----
        // yyy: getPermission('yyy'), // ----
      }
    },
    getAddressbooksNumbers(state){
      const address_books = state.address_books.data
      let final_array = [];
      for (let index = 0;index < address_books.length;index++) {
        const addressbook = address_books[index];
        const name = `${addressbook.firstName || ""} ${addressbook.middleName || ""} ${addressbook.lastName || ""}`.trim();
        const numbers = addressbook.shorCodes.map((shorCode) => {
          return {
            shorCode: {
              ...shorCode,
              natianal_formate: number_formater(shorCode.contact_number,'',true),
            },
            name,
            ...addressbook,
          };
        });
        final_array = [...final_array, ...numbers];
      }
      return final_array
    },
    getAddressbooksAlaisesNumbers(state,getters){
      
      let obj = {
        ..._.keyBy(getters.getAddressbooksNumbers,'phone.uid'),
        ..._.keyBy(getters.getAddressbooksNumbers,'dialShortCode'),
        ..._.keyBy(getters.getAddressbooksNumbers,'real_id'),
        ..._.keyBy(getters.getAddressbooksNumbers,'shorCode.natianal_formate'),
      }
      delete obj.undefined
      return obj
    },
    getSpeedDials(state){ return [...state.speed_dials.local.data,...state.speed_dials.global.data] },
    currentStatus(state){
      const status_id = state.user.status_id
      const status = (state.statuses || []).find(item=>item.id==status_id)
      if(!status) return {}
      return status
    }
  },
  actions: {
    searchUsers({rootState},search){
      const users = rootState.common.voipusers
      // console.log(users)
      if (!search) return users;
      var te = Object.values(users);
      var searchRegex = new RegExp(search.toLowerCase().trim(), "i");
      return te.filter((user) => searchRegex.test(user.extn) || searchRegex.test(user.user.ac_user) || searchRegex.test(user.user.display_name) || searchRegex.test(user.account));
    },
    setStatus(context,status_id){
      const statuses = context.state.statuses
      const status = statuses.find(item=>item.id==status_id) ?? statuses?.find?.(status=>status.status=='Available' && status.accountcode==null)
      if(status){
        VOIP_API.endpoints.user_statuses.set({
          id: status.id,
        }).then(() => {
          let new_user = {
            ...context.state.user,
            status_id: status.id,
            status: status.status,
          };
          if(context.state.voipusers[context.state.user.account]) context.state.voipusers[context.state.user.account].status = status.id
          context.commit('setUser',new_user)
        });
      }
    },
  },
  mutations: {
    setAddServiceQueue(state,data){
      state.add_service_queue.type=data?.type || ''
      state.add_service_queue.name=data?.name || ''
      state.add_service_queue.username=data?.username || ''
    },
    setPlanLogs(state, data){
      state.plan_logs=data
    },
    setVoipTeams(state, data) {
      state.teams = data
    },
    setVoipNumbers(state, data) {
      state.numbers = data
    },
    setUserSpeedDial(state, data) {
      state.speeddials = data;
    },
    setVoipUsers(state, data) {
      state.voipusers = data;
    },
    setVoipDeletedUsers(state, data) {
      state.deleted_voipusers = data;
    },
    setDepartments(state, data) {
      state.departments = data;
    },
    setAddressbooks(state, data) {
      state.addressbooks = data;
    },
    setCallActivity(state, data) {
      state.callActivity = data;
    },
    pushCallActivity(state, data) {

      if (!_.isEmpty(state.call_activity.data)) {
        let data1 = {
          ...data,
          call_date: moment().utc().format('MMM DD YYYY, hh:mm:ss a')
        }

        state.call_activity.data.unshift(data1);
      } else {


        state.call_activity.data = [];
        state.call_activity.data.push(data);
      }
    },
    setCallTags(state, data){
      state.call_tags = data;
    },
    setCommonState(state, data) {
      const { value } = data || {}
      const { 
        speeddials,
        voipusers,
        deleted_voipusers,
        departments,
        addressbooks,
        callActivity,
        teams,
        muted_channels,
        user,
        call_activity,
        statuses,
        call_tags,
        speed_dials,
        call_queues,
        ivr,
        address_books,
        numbers,
      } = value || {}
      state.speeddials = speeddials || [];
      state.voipusers = voipusers || {};
      state.deleted_voipusers = deleted_voipusers || {};
      state.departments = departments || [];
      state.addressbooks = addressbooks || [];
      state.callActivity = callActivity || [];
      state.numbers = numbers || []
      state.teams = teams || {
        data: [],
        total: 0,
      };
      state.call_queues = call_queues || {
        data: [],
        total: 0,
      };
      state.ivr = ivr || {
        data: {},
        total: 0,
      };
      state.muted_channels = muted_channels || {};
      state.user = user ? {
        ...user,
        status_id: '',
      } : {};
      state.call_activity = call_activity || {
        data: [],
        page: 1,
        total: 0,
        is_completed: false,
        loading: false,
      };
      state.statuses = statuses || [];
      state.call_tags = call_tags || [];
      state.speed_dials = speed_dials || {
        local: {
          data: [],
          total: 0,
        },
        global: {
          data: [],
          total: 0,
        },
      }
      state.address_books = address_books || {
        data: [],
        per_page: 10,
        current_page: 1,
        next_link: '',
        total: 0,
        total_pages: 0,
        behind_numbers: 0,
      }
    },
    setLocalSpeedDial(state, data){
      state.speed_dials.local = {
        data: data.data,
        total: data.total
      }
    },
    setGlobalSpeedDial(state, data){
      state.speed_dials.global = {
        data: data.data,
        total: data.total
      }
    },
    setAddressbook(state, data){
      state.address_books.data = data.data || []
      state.address_books.total = data.total || 0
    },
    setCallQueues(state, data){
      state.call_queues.data = data.data || []
      state.call_queues.total = data.total || 0
    },
    setIVRS(state, data){
      state.ivr.data = data.data || {}
      state.ivr.total = data.total || 0
    },
    setUser(state, data){
      state.user = data
    },
    setCallRecordingSetting(state,data){
      state.call_recording_setting=data ?? {}
    },
  },
};