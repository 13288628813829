var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup full-width-big-modal",
    attrs: {
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to my-4 dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px mb-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main mb-0"
  }, [_vm._v("Business hours")]), _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "newerSwitch",
    attrs: {
      "title": _vm.getUserPermissions.out_of_hours ? '' : 'Your package does not support this feature',
      "disabled": !_vm.getUserPermissions.out_of_hours || _vm.api.update_out_of_hours.send || _vm.api.route_call.send,
      "checked": _vm.response.out_of_hours,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateOutOfHours($event);
      }
    }
  })], 1)]), _vm.info ? [_vm.info.type == 'ivr' ? _c('AdvanceOptions', {
    attrs: {
      "accountcode": _vm.data.accountcode
    },
    on: {
      "updated": function ($event) {
        return _vm.$emit('interface', {
          from: 'info',
          event: 'update_advance_option'
        });
      }
    }
  }) : _vm._e(), _vm.info.type == 'team' ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px mb-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Team members")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(_vm._s(_vm._f("memberCount")(_vm.info.data.members)))])]), _c('button', {
    staticClass: "newButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddRemoveMembersTeams', {
          team: _vm.info.data,
          type: 'AssignNumbersTeam'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add/remove users")])], 1)])]) : _vm._e(), _vm.info.type == 'team' ? _c('AddRemoveFromList', {
    attrs: {
      "modalName": `AddRemoveMembersTeams`
    },
    on: {
      "update-list": function ($event) {
        return _vm.getVoipTeams(true);
      }
    }
  }) : _vm._e()] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }