var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-dropdown"
  }, [_c('b-dropdown', {
    ref: "dropdown",
    staticClass: "m-2",
    attrs: {
      "id": _vm.id,
      "disabled": _vm.disabled,
      "text": _vm.Text ? _vm.Text.toString() : _vm.defaultSelectedText
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_vm.isTextShow ? [_vm._t("selected-option", function () {
          return [_c('span', {
            style: _vm.textElStyle,
            attrs: {
              "id": `${_vm.id}-text`
            }
          }, [_vm._v(_vm._s(_vm.Text ? _vm.Text : _vm.defaultSelectedText))])];
        }, {
          "option": _vm.selectedOption,
          "onSelect": _vm.onSelect
        })] : _vm._e(), _c('span', {
          staticStyle: {
            "display": "none"
          },
          attrs: {
            "id": `${_vm.id}-value`
          }
        }, [_vm._v(_vm._s(_vm.Value))]), _vm._t("right-icon", function () {
          return [_c('b-icon-chevron-down', {
            attrs: {
              "scale": "1.1"
            }
          })];
        })], 2)];
      },
      proxy: true
    }], null, true)
  }, [_vm.options.length == 0 ? _c('b-dropdown-item', {
    staticClass: "noRecordItem"
  }, [_vm._t("no-record", function () {
    return [_vm._v(_vm._s(_vm.onEmptyListText))];
  })], 2) : _vm._e(), _vm._l(_vm.options, function (option, index) {
    return _c('b-dropdown-item', {
      key: index,
      class: `${_vm.listClass} ${_vm.isMarkShow ? 'vb-select-item-lessPadding' : ''}`,
      attrs: {
        "disabled": _vm.disabled
      },
      on: {
        "click": function ($event) {
          return _vm.onSelect(option);
        }
      }
    }, [_vm._t("option", function () {
      return [_vm.isCheckShow ? _c('b-icon', {
        staticClass: "mr-2",
        attrs: {
          "icon": _vm.selected == _vm.getProperty(option, `${_vm.valueField}`, option) ? 'check2' : 'blank'
        }
      }) : _vm._e(), _vm._v(" " + _vm._s(_vm._f("get_property")(option, `${_vm.textField}`, option)) + " "), _vm.isMarkShow && option.mark ? _c('span', {
        staticClass: "checkMarkIconAtEnd"
      }, [_vm._v("Default")]) : _vm._e()];
    }, {
      "option": option
    })], 2);
  }), _vm.isSelectedOnBottom && _vm.Text ? _c('b-dropdown-item', {
    class: _vm.listClass,
    attrs: {
      "disabled": ""
    }
  }, [_vm._t("bottom-selected-option", function () {
    return [_c('b-icon', {
      staticClass: "mr-2",
      attrs: {
        "icon": "check2"
      }
    }), _vm._v(" " + _vm._s(_vm.Text) + " ")];
  }, {
    "option": _vm.selectedOption
  })], 2) : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }