var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.add_manual_number.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Manual Number")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addManualNumber();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.add_manual_number.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_manual_number.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_manual_number.did,
      expression: "forms.add_manual_number.did"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Number",
      "disabled": _vm.api.add_manual_number.send,
      "min": "1",
      "type": "number",
      "onkeypress": "return /([0-9])/i.test(event.key)"
    },
    domProps: {
      "value": _vm.forms.add_manual_number.did
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_manual_number, "did", $event.target.value);
      }
    }
  }), _vm.forms.add_manual_number.submitted && _vm.$v.forms.add_manual_number.did.$invalid || _vm.api.add_manual_number.validation_errors.did ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_manual_number.did.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.add_manual_number.did.minValue ? _c('span', [_vm._v("* Number is minimum 1")]) : _vm._e(), _vm._l(_vm.api.add_manual_number.validation_errors.did, function (error_number) {
    return _c('span', {
      key: error_number
    }, [_vm._v("* " + _vm._s(error_number))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Description (Optional)")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_manual_number.label,
      expression: "forms.add_manual_number.label"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Description",
      "disabled": _vm.api.add_manual_number.send
    },
    domProps: {
      "value": _vm.forms.add_manual_number.label
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_manual_number, "label", $event.target.value);
      }
    }
  }), _vm._v(" "), _vm.api.add_manual_number.validation_errors.label ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.add_manual_number.validation_errors.label, function (error_number) {
    return _c('span', {
      key: error_number
    }, [_vm._v("* " + _vm._s(error_number))]);
  }), 0) : _vm._e()])])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.add_manual_number.send
    }
  }, [_vm.api.add_manual_number.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }