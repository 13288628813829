var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "createNewSpaceModal",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header w-100 d-flex position-relative py-0 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title my-0"
  }, [_vm._v("Create new space")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "disabled": _vm.api.add_task_space.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": !!_vm.api.add_task_space.error_message
    }
  }, [_vm._v(_vm._s(_vm.api.add_task_space.error_message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-24px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Space name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_task_space.name,
      expression: "forms.add_task_space.name"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "disabled": _vm.api.add_task_space.send,
      "type": "text",
      "placeholder": "Space title",
      "maxlength": _vm.$v.forms.add_task_space.name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.add_task_space.name
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_task_space, "name", $event.target.value);
      }, function ($event) {
        _vm.api.get_is_unique.error_message = '';
      }]
    }
  }), _c('p', {
    staticClass: "MakeWhiteInDarkMode mb-4px"
  }, [_vm._v(" Space name can be maximum " + _vm._s(_vm.$v.forms.add_task_space.name.$params.maxLength.max) + " characters ")]), _vm.conditions.validation_step == 1 && _vm.$v.forms.add_task_space.name.$invalid || _vm.api.get_is_unique.error_message ? _c('p', [!_vm.$v.forms.add_task_space.name.required ? _c('span', {
    staticClass: "text-danger field-error bold-4"
  }, [_vm._v("* Space name is required")]) : !_vm.$v.forms.add_task_space.name.valid ? _c('span', {
    staticClass: "text-danger field-error bold-4"
  }, [_vm._v("* Space name can be only alphabet or number")]) : !_vm.$v.forms.add_task_space.name.minLength ? _c('span', {
    staticClass: "text-danger field-error bold-4"
  }, [_vm._v("* Space name should be minimum " + _vm._s(_vm.$v.forms.add_task_space.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.add_task_space.name.maxLength ? _c('span', {
    staticClass: "text-danger field-error bold-4"
  }, [_vm._v("* Space name can be maximum " + _vm._s(_vm.$v.forms.add_task_space.name.$params.maxLength.max) + " character")]) : _vm._e(), _vm.api.get_is_unique.error_message ? _c('span', {
    staticClass: "text-danger field-error bold-4"
  }, [_vm._v(_vm._s(_vm.api.get_is_unique.error_message))]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Space color")]), _c('div', {
    staticClass: "createSpace-selectColor-container"
  }, _vm._l(_vm.colors, function (color) {
    return _c('div', {
      key: color.id,
      staticClass: "createSpace-selectColor-item-container"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item",
      class: {
        'selected': _vm.forms.add_task_space.color == color,
        'TransparentSelected': color == 'rgba(0,0,0,0)'
      },
      style: `background-color:${color};`,
      on: {
        "click": function ($event) {
          _vm.api.add_task_space.send ? '' : _vm.forms.add_task_space.color = color;
        }
      }
    }, [color == 'rgba(0,0,0,0)' ? _c('vb-icon', {
      attrs: {
        "icon": "todo-transparentColored-icon",
        "height": "28px",
        "width": "30px"
      }
    }) : _vm._e()], 1)]);
  }), 0)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Space type private")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.add_task_space.send,
      "checked": _vm.forms.add_task_space.type == _vm.types[1].value,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        _vm.forms.add_task_space.type == _vm.types[1].value ? _vm.forms.add_task_space.type = _vm.types[0].value : _vm.forms.add_task_space.type = _vm.types[1].value;
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.add_task_space.send
    },
    on: {
      "click": function ($event) {
        return _vm.create();
      }
    }
  }, [_vm.api.add_task_space.send ? _c('vb-spinner') : [_vm._v("Create space")]], 2)])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }