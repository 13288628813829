var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll",
    attrs: {
      "name": "createNewTeamEmployeeData",
      "clickToClose": true,
      "scrollable": true
    }
  }, [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Create a new team")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('createNewTeamEmployeeData');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Team name")]), _c('input', {})]), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Country")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Country ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Time Zone")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Time Zone ")])], 1)], 1), _c('div', {
    staticClass: "grayCard borderRadius-12px flexColumn px-4 mt-32px"
  }, [_c('div', {
    staticClass: "innerShadowInput-container mb-0"
  }, [_c('label', {}, [_vm._v("Time Format")])]), _c('b-form-group', {
    staticClass: "HrmRadio-container mt-16px w-100"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" 12-hour (3:00PM) ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" 24-hour (15:00) ")])], 1)], 1), _c('div', {
    staticClass: "grayCard borderRadius-12px flexColumn px-4 mt-32px"
  }, [_c('div', {
    staticClass: "innerShadowInput-container mb-0"
  }, [_c('label', {}, [_vm._v("First day of the week for calendars")])]), _c('b-form-group', {
    staticClass: "HrmRadio-container mt-16px w-100"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Monday ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Sunday ")])], 1)], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Create ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }