var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "changeOwnershipModal center-zoomIn-transition v2 m-w-500",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_vm.conditions.screen == _vm.screens.email.value ? [_c('div', {
    staticClass: "d-flex align-items-start justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Owner details")]), _c('p', [_vm._v("You are the owner of this organization.")])]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Email")]), _c('input', {
    staticClass: "w-100",
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.email
    }
  })])]), _c('div', {
    staticClass: "mt-20px w-100"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_owner.value;
      }
    }
  }, [_vm._v(" Change ownership ")])])] : _vm._e(), _vm.conditions.screen == _vm.screens.select_owner.value ? [_c('div', {
    staticClass: "d-flex align-items-start justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Transfer ownership")]), _c('p', [_vm._v("Current ownership belongs to you")])]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("Select a user to transfer ownership to")]), _c('div', [_vm._v("you can only transfer your ownership to a user with a valid email")]), _c('b-dropdown', {
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center justify-content-between w-100 p-0"
        }, [_c('div', {
          staticClass: "mr-8px"
        }, [_vm.forms.change_owner.account ? _c('Info', {
          attrs: {
            "id": _vm.forms.change_owner.account,
            "is_blf": false
          }
        }) : [_vm._v("Select an account")]], 2), _c('b-icon', {
          attrs: {
            "icon": "chevron-down"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 924129370)
  }, [_vm._l(_vm.invitedUsers, function (user) {
    return _c('b-dropdown-item', {
      key: user.voipaccount,
      on: {
        "click": function ($event) {
          _vm.forms.change_owner.account = user.voipaccount;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex w-fit-content"
    }, [_c('Info', {
      attrs: {
        "id": user.voipaccount,
        "is_blf": false
      }
    })], 1)]);
  }), _vm.invitedUsers.length == 0 ? _c('b-dropdown-item', [_c('div', {
    staticClass: "d-flex w-fit-content"
  }, [_vm._v(" There is no record ")])]) : _vm._e()], 2), _vm.$v.forms.change_owner.account.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.change_owner.account.required ? _c('span', [_vm._v("Required")]) : _vm._e()]) : _vm._e()], 1)]), _c('div', {
    staticClass: "mt-20px w-100 d-flex justify-content-between"
  }, [_c('button', {
    staticClass: "darkGreyButton mr-1 w-50",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "newButton ml-1 w-50",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.$v.forms.change_owner.account.$touch();
        _vm.$v.forms.change_owner.account.$invalid ? '' : _vm.conditions.screen = _vm.screens.password.value;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-singleUser-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Transfer ownership")])], 1)])] : _vm._e(), _vm.conditions.screen == _vm.screens.password.value ? [_c('div', {
    staticClass: "d-flex align-items-start justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Owner details")]), _c('p', [_vm._v("You are the owner of this organization.")])]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "disabled": _vm.api.change_owner.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withRightIconOnly w-100"
  }, [_c('label', [_vm._v("Enter Password")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [(_vm.forms.change_owner.password_show ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_owner.password,
      expression: "forms.change_owner.password"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.change_owner.send,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.forms.change_owner.password) ? _vm._i(_vm.forms.change_owner.password, null) > -1 : _vm.forms.change_owner.password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.forms.change_owner.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.forms.change_owner, "password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.forms.change_owner, "password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.forms.change_owner, "password", $$c);
        }
      }
    }
  }) : (_vm.forms.change_owner.password_show ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_owner.password,
      expression: "forms.change_owner.password"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.change_owner.send,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.forms.change_owner.password, null)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.forms.change_owner, "password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_owner.password,
      expression: "forms.change_owner.password"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.change_owner.send,
      "type": _vm.forms.change_owner.password_show ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.forms.change_owner.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.change_owner, "password", $event.target.value);
      }
    }
  }), _c('b-icon', {
    attrs: {
      "icon": `${_vm.forms.change_owner.password_show ? 'eye-slash-fill' : 'eye-fill'}`
    },
    on: {
      "click": function ($event) {
        _vm.api.change_owner.send ? '' : _vm.forms.change_owner.password_show = !_vm.forms.change_owner.password_show;
      }
    }
  })], 1)]), _vm.$v.forms.change_owner.password.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.change_owner.password.required ? _c('span', [_vm._v("Required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "mt-20px w-100"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.change_owner.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.changePassword();
      }
    }
  }, [_vm.api.change_owner.send ? _c('vb-spinner') : [_vm._v("Change ownership")]], 2)])] : _vm._e(), _vm.conditions.screen == _vm.screens.otp.value ? [_c('div', {
    staticClass: "d-flex align-items-start justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column pr-2"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("OTP verification - current & new owners")]), _c('p', [_vm._v(" To securely transfer ownership, enter the one-time passwords (OTPs) sent to the emails associated with the current and new owner's accounts. Verify both identities to proceed. ")])]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "disabled": _vm.api.verify_otp.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText-14px w-100 mt-0"
  }, [_vm._v(" Enter OTP sent to "), _c('Info', {
    staticClass: "newBlue",
    attrs: {
      "id": _vm.getCurrentUser.account,
      "is_blf": false,
      "only_name": true
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-10px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Enter the 6-digit code:")]), _c('vb-pin-code', {
    attrs: {
      "numberslength": _vm.$v.forms.verify_otp.cowner_pincode.$params.maxLength.max,
      "value": _vm.forms.verify_otp.cowner_pincode,
      "disabled": _vm.api.verify_otp.send
    },
    on: {
      "change": function ($event) {
        _vm.forms.verify_otp.cowner_pincode = $event;
      }
    }
  }), _vm.forms.verify_otp.submitted && _vm.$v.forms.verify_otp.cowner_pincode.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.verify_otp.cowner_pincode.required ? _c('span', [_vm._v("Pincode is required")]) : !_vm.$v.forms.verify_otp.cowner_pincode.minLength ? _c('span', [_vm._v("Pincode can be minimum " + _vm._s(_vm.$v.forms.verify_otp.cowner_pincode.$params.minLength.min) + " character")]) : !_vm.$v.forms.verify_otp.cowner_pincode.maxLength ? _c('span', [_vm._v("Pincode should be maximum " + _vm._s(_vm.$v.forms.verify_otp.cowner_pincode.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm._e()], 1)])]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px w-100 mt-4"
  }, [_vm._v(" Enter OTP sent to "), _c('Info', {
    staticClass: "newBlue",
    attrs: {
      "id": _vm.forms.change_owner.account,
      "is_blf": false,
      "only_name": true
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-10px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Enter the 6-digit code:")]), _c('vb-pin-code', {
    attrs: {
      "numberslength": _vm.$v.forms.verify_otp.new_owner_pincode.$params.maxLength.max,
      "value": _vm.forms.verify_otp.new_owner_pincode,
      "disabled": _vm.api.verify_otp.send
    },
    on: {
      "change": function ($event) {
        _vm.forms.verify_otp.new_owner_pincode = $event;
      }
    }
  }), _vm.forms.verify_otp.submitted && _vm.$v.forms.verify_otp.new_owner_pincode.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.verify_otp.new_owner_pincode.required ? _c('span', [_vm._v("Pincode is required")]) : !_vm.$v.forms.verify_otp.new_owner_pincode.minLength ? _c('span', [_vm._v("Pincode can be minimum " + _vm._s(_vm.$v.forms.verify_otp.new_owner_pincode.$params.minLength.min) + " character")]) : !_vm.$v.forms.verify_otp.new_owner_pincode.maxLength ? _c('span', [_vm._v("Pincode should be maximum " + _vm._s(_vm.$v.forms.verify_otp.new_owner_pincode.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm._e()], 1)])])]), _c('div', {
    staticClass: "mt-20px w-100"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.verify_otp.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.verifyPincode();
      }
    }
  }, [_vm.api.verify_otp.send ? _c('vb-spinner') : [_vm._v("Verify")]], 2)])] : _vm._e(), _vm.conditions.screen == _vm.screens.success.value ? [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "thumbCongrtulations-container"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/thumbCongrtulations.svg')
    }
  }), _c('h6', [_vm._v("Ownership successfully transferred")]), _c('p', [_vm._v(" Congratulations! The ownership of the organization has been successfully transferred to Frank Fahad. ")])])])] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }