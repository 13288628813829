var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "acceptedCallBar"
  }, [_c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center"
  }, [_c('div', {
    staticClass: "dialer-user-dp mr-2"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-call-chat-icon",
      "width": "40",
      "height": "40"
    }
  })], 1), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.name))]), _c('p', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.sub_info))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-2"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.videoCall.time)))])])])]), _c('div', {
    staticClass: "d-flex jitsiIncomming_call_bar_actions"
  }, [_c('span', {
    staticClass: "md-mar-left"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer with-shadow",
    attrs: {
      "icon": _vm.isActiveCall ? 'video-call-play-icon' : 'video-call-pause-icon',
      "width": "40",
      "height": "40"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('call-play');
      }
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }