<template>
  <modal class="CreateChanneFromUserslModal ForwardMessageModal" :name="modalName" :width="400" @before-open="beforeOpen">
    <!-- dialer_animation center-zoomIn-popup dialer-addMemberModal inCall-forward-message-modal chatForward-message-modal -->
    <!-- <div v-if="false" class="dialer-chatBox-inVideo">
      <div class="dialer-videoCall-addMember mb-0 pt-0 position-relative">
        <div class="px-3 py-3 border-bottom sticky-top">
          <div class="d-flex align-items-center justify-content-between">
            <span class="close-popup cursor_pointer ml-2 position-relative top-0 right-0" style="top:0px;left:-10px;right:auto;" @click="$modal.hide(modalName)">
              <b-icon icon="x" scale="1.8" />
            </span>
            <h4 class="mb-0">Forward Message</h4>
            <button type="button" class="d-flex dialer-button dialer-button-primary px-4 py-2" @click="$modal.hide(modalName)"  style="line-height:1;">Done</button>
          </div>
          <div class="pt-3 px-2">
            <div class="searchBox"> 
              <b-form class="position-relative"  @submit.prevent="''">
                <b-form-input placeholder="Search User" v-model="search" class="w-100 px-5"></b-form-input>
                <b-icon icon="search" scale="1" class="position-absolute cursor_pointer" />
                <b-icon icon="x-circle" scale="1" @click="search = ''" class="position-absolute cursor_pointer" />
              </b-form>
            </div>
          </div>
          <div v-if="!isEmpty(post)" class="pt-3 px-2">
            <Message ref="forward_modal_single_message" :fromForwardModal="true" :post="post" :channel_id="channel_id" :channel="channel" :data="data" :channelData="channelData" :members="members" :type="type" :name="name" :image="image" />
          </div>
        </div>
        <ul class="items">
          <template v-if="!isEmpty(searchedUsers)">
            <li class="item">
              <h6 class="m-0">Users</h6>
            </li>
            <li v-for="user in searchedUsers" :key="user.voipaccount" v-show="user.channel_id !== channel_id" class="item cursor_pointer" @click="sent(user, 'user')">
              <div class="info">
                <div class="image-container">
                  <img :src="user.profile_img" @error="$event.target.src = local_filess.user_male"  />
                </div>
                <div>
                  <h5 class="text-capitalize">{{ user | get_property('user.display_name') }}</h5>
                  <h5 class="text-capitalize">{{ user.extn }}</h5>
                </div>
              </div>
              <div class="actions">
                <a v-if="!sentChannels[user.channel_id]" class="d-flex dialer-button dialer-button-primary">Send</a>
                <a v-if="sentChannels[user.channel_id]" class="d-flex align-items-center dialer-button dialer-button-primary message-sent">
                  <b-icon icon="check2" scale="1.2" variant="success" />
                  Sent
                </a>
              </div>
            </li>
          </template>
          <template v-if="!isEmpty(searchedTeams)">
            <li class="item">
              <h6 class="m-0">Teams</h6>
            </li>
            <li v-for="team in searchedTeams" :key="team.channel_id" v-show="team.channel_id !== channel_id" class="item cursor_pointer" @click="sent(team, 'team')">
              <div class="info">
                <div class="image-container">
                  <img :src="team | get_property('avatar.image.image')" @error="$event.target.src=channelImg" :alt="team.name" />
                </div>
                <div>
                  <h5 class="text-capitalize">{{ team.name }}</h5>
                  <h5 class="text-capitalize">{{ team.ringroup.extn }}</h5>
                </div>
              </div>
              <div class="actions">
                <a v-if="!sentChannels[team.channel_id]" class="d-flex dialer-button dialer-button-primary">Send</a>
                <a v-if="sentChannels[team.channel_id]" class="d-flex align-items-center dialer-button dialer-button-primary message-sent">
                  <b-icon icon="check2" scale="1.2" variant="success" />
                  Sent
                </a>
              </div>
            </li>
          </template>
          <template v-if="!isEmpty(searchedChannels)">
            <li class="item">
              <h6 class="m-0">
                Groups
              </h6>
            </li>
            <li v-for="channel in searchedChannels" :key="channel.channel.id" v-show="channel.channel.id !== channel_id" class="item cursor_pointer" @click="sent(channel, 'channel')">
              <div class="info">
                <div class="image-container">
                  <img :src="channelImg" />
                </div>
                <div>
                  <h5 class="text-capitalize">{{ channel | get_property('channel.display_name') }}</h5>
                </div>
              </div>
              <div class="actions">
                <a v-if="!sentChannels[channel.channel.id]" class="d-flex dialer-button dialer-button-primary">Send</a>
                <a v-if="sentChannels[channel.channel.id]" class="d-flex align-items-center dialer-button dialer-button-primary message-sent">
                  <b-icon icon="check2" scale="1.2" variant="success" />
                  Sent
                </a>
              </div>
            </li>
          </template>
          <vb-no-record v-if="isEmpty(searchedChannels) && isEmpty(searchedTeams) && isEmpty(searchedUsers)" text="There is no record" :design="3" />
        </ul>
      </div>
    </div> -->


    <div class="topBar-withCloseIcon mt-0">
      <h3 class="dialer-box-header w-100 mb-0">Forward Message</h3>
      <button type="button" class="newCloseButton" @click="$modal.hide(modalName)">
        <vb-icon icon="squared-close-icon" height="38px" width="38px" />
        <span class="newCloseButton-textPart">Close</span>
      </button>
    </div>
    <div class="searchBox">
      <b-form @submit.prevent="''" class="position-relative">
        <b-form-input placeholder="Search User" v-model="search" class="w-100"></b-form-input>
        <b-icon icon="search" scale="1" class="position-absolute cursor_pointer searchIcon"/>
        <!-- <b-icon icon="x-circle" scale="1" class="position-absolute cursor_pointer xCircleIcon" /> -->
      </b-form>
    </div>
    <div class="addable-memberLisit">
      <div v-if="!isEmpty(post)" class="px-2">
        <Message ref="forward_modal_single_message" class="forward_modal_single_message" :fromForwardModal="true" :post="post" :channel_id="channel_id" :channel="channel" :data="data" :channelData="channelData" :members="members" :type="type" :name="name" :image="image" />
      </div>
    </div>
    <ul class="AvailableUsersList">
      <!-- <li
        v-for="user in searchedNotSelectedUsers"
        :key="user.voipaccount"
        v-show="$store.getters.getCurrentUser.account !== user.voipaccount"
        class="AvailableUsersList-item"
        @click="getProperty(user,'user.user_chat_id')?$set(selected_users, user.user.user_chat_id, true):''"
      >
        <Info :id="user.voipaccount" :is_suspended_show="false" :is_blf="false" />
        <b-icon icon="plus-circle-fill" font-scale="1.75" variant="primary" />
      </li> -->
      <!-- <vb-no-record
        v-if="isEmpty(searchedNotSelectedUsers)"
        text="There in no user"
        :design="3"
      /> -->
      <template v-if="!isEmpty(searchedUsers)">
        <li class="AvailableUsersList-item">
          <h6 class="m-0">Users</h6>
        </li>
        <li v-for="user in searchedUsers" :key="user.voipaccount" v-show="user.channel_id !== channel_id" class="AvailableUsersList-item" @click="sent(user, 'user')">
          <!-- <div class="info">
            <div class="image-container">
              <img :src="user.profile_img" @error="$event.target.src = local_filess.user_male"  />
            </div>
            <div>
              <h5 class="text-capitalize">{{ user | get_property('user.display_name') }}</h5>
              <h5 class="text-capitalize">{{ user.extn }}</h5>
            </div>
          </div> -->
          <Info :id="user.voipaccount" :is_suspended_show="false" :is_blf="false" />
          <div class="actions">
            <a v-if="!sentChannels[user.channel_id]" class="d-flex dialer-button dialer-button-primary">Send</a>
            <a v-if="sentChannels[user.channel_id]" class="d-flex align-items-center dialer-button dialer-button-primary message-sent">
              <b-icon icon="check2" scale="1.2" variant="success" />
              Sent
            </a>
          </div>
        </li>
      </template>
      <template v-if="!isEmpty(searchedTeams)">
        <li class="AvailableUsersList-item">
          <h6 class="m-0">Teams</h6>
        </li>
        <li v-for="team in searchedTeams" :key="team.channel_id" v-show="team.channel_id !== channel_id" class="AvailableUsersList-item" @click="sent(team, 'team')">
          <!-- <div class="info">
            <div class="image-container">
              <img :src="team | get_property('avatar.image.image')" @error="$event.target.src=channelImg" :alt="team.name" />
            </div>
            <div>
              <h5 class="text-capitalize">{{ team.name }}</h5>
              <h5 class="text-capitalize">{{ team.ringroup.extn }}</h5>
            </div>
          </div> -->
          <Info :id="team.real_id" :is_suspended_show="false" :is_blf="false" />
          <div class="actions">
            <a v-if="!sentChannels[team.channel_id]" class="d-flex dialer-button dialer-button-primary">Send</a>
            <a v-if="sentChannels[team.channel_id]" class="d-flex align-items-center dialer-button dialer-button-primary message-sent">
              <b-icon icon="check2" scale="1.2" variant="success" />
              Sent
            </a>
          </div>
        </li>
      </template>
      <template v-if="!isEmpty(searchedChannels)">
        <li class="AvailableUsersList-item">
          <h6 class="m-0">
            Groups
          </h6>
        </li>
        <li v-for="channel in searchedChannels" :key="channel.channel.id" v-show="channel.channel.id !== channel_id" class="AvailableUsersList-item" @click="sent(channel, 'channel')">
          <!-- <div class="info">
            <div class="image-container">
              <img :src="channelImg" />
            </div>
            <div>
              <h5 class="text-capitalize">{{ channel | get_property('channel.display_name') }}</h5>
            </div>
          </div> -->
          <Info :name="channel | get_property('channel.display_name')" :image="channelImg" />
          <div class="actions">
            <a v-if="!sentChannels[channel.channel.id]" class="d-flex dialer-button dialer-button-primary">Send</a>
            <a v-if="sentChannels[channel.channel.id]" class="d-flex align-items-center dialer-button dialer-button-primary message-sent">
              <b-icon icon="check2" scale="1.2" variant="success" />
              Sent
            </a>
          </div>
        </li>
      </template>
      <vb-no-record v-if="isEmpty(searchedChannels) && isEmpty(searchedTeams) && isEmpty(searchedUsers)" text="There is no record" :design="3" />
    </ul>


    <!-- <div class="d-flex justify-content-end w-100">
      <button type="button" class="dialer-button dialer-button-primary" @click="onDone">
        Done
      </button>
    </div> -->
  </modal>
</template>

<script>
import { mattermost, MATTERMOST_API } from "@/MatterMost";
import Message from "../Box/Messages/Message.vue";
import Info from '@/components/Lists/Info.vue'
import _ from "lodash";
export default {
  name: "ForwardMessageModal",
  components: {
    Message,
    Info,
  },
  inject: ['local_filess','isEmpty'],
  props: {
    modalName: {
      type: String,
      default: 'ForwardMessageModal'
    },
    channel_id: {
      type: String,
      default: "",
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    channelData: {
      type: Object,
      default: () => ({}),
    },
    members: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
    },
    name: {
      type: String,
      deault: "",
    },
    image: {
      type: String,
      deault: "",
    },
    currentUserMember: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      search: "",
      sentChannels: {},
      post: {},
      is_submitted: false,
    };
  },
  computed: {
    channels() {
      return Object.values(this.$store.state.mattermost.channels).filter((channel) => channel.type === "channel");
    },
    usersSorted(){
      const channels_store = this.$store.state.mattermost.channels
      return Object.values(this.$store.state.common.voipusers).filter((user) => user.voipaccount !== this.$store.getters.getCurrentUser?.account && user?.active_status != '0').sort((a,b)=>((channels_store?.[b?.channel_id]?.posts?.posts?.[channels_store?.[b?.channel_id]?.posts?.order?.[(channels_store?.[b?.channel_id]?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0) - (channels_store?.[a?.channel_id]?.posts?.posts?.[channels_store?.[a?.channel_id]?.posts?.order?.[(channels_store?.[a?.channel_id]?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0)))
    },
    searchedUsers() {
      const search = this.search.toLowerCase().trim();
      return this.usersSorted.filter((user) => `${user?.user?.display_name} ${user.extn}`.trim().toLowerCase().includes(search));
    },
    teamsSorted(){ 
      const channels_store = this.$store.state.mattermost.channels
      return this.$store.getters.getVoipTeams.filter((teamMembers) => ( teamMembers?.members?.some?.((member) => ( member?.accountcode === this.$store.getters.getCurrentUser?.account )) ?? [] )).sort((a,b)=>((channels_store?.[b?.channel_id]?.posts?.posts?.[channels_store?.[b?.channel_id]?.posts?.order?.[(channels_store?.[b?.channel_id]?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0) - (channels_store?.[a?.channel_id]?.posts?.posts?.[channels_store?.[a?.channel_id]?.posts?.order?.[(channels_store?.[a?.channel_id]?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0)))
    },
    searchedTeams() {
      const search = this.search.toLowerCase().trim();
      return this.teamsSorted.filter((teamMembers) => `${teamMembers.name} ${teamMembers.ringroup.extn}`.trim().toLowerCase().includes(search))
    },
    channelsSorted(){ 
      return (this.channels ?? []).sort((a,b)=>((b?.posts?.posts?.[b?.posts?.order?.[(b?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0) - (a?.posts?.posts?.[a?.posts?.order?.[(a?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0)))
    },
    searchedChannels() {
      return this.channelsSorted.filter((channel) => channel?.channel?.display_name.includes(this.search.toLowerCase().trim()));
    },
    fileIds() {
      if (!this.post.metadata) return [];
      if (!this.post.metadata.files) return [];
      return this.post.metadata.files.map((file) => file.id);
    },
    channelImg(){ return require("@/assets/images/channel.svg") },
  },
  methods: {
    beforeOpen(event) {
      this.post = event?.params?.post || {};
      this.sentChannels = {};
      this.search = "";
    },
    async sent(data, type) {
      if (this.is_submitted || (!this.post.message && _.isEmpty(this.fileIds))) return;
      this.is_submitted = true;
      try {
        let channel_id = type === "channel" ? data.channel.id : data.channel_id;
        if (this.sentChannels[channel_id]) throw Error("Already sent");
        if (!channel_id && type === "user") {
          const { data: channel, } = await MATTERMOST_API.endpoints.channels.createDirectChannel([
            data.user.user_chat_id,
            // this.mattermost_current_user.id,
            this.$store.getters.getMattermostCurrentUser?.id,
          ]);
          mattermost.emitOnUserLevel(mattermost.events.direct_added,{
            channel_id: channel.id
          })
          // this.$root.$emit(events.adding_channel, {
          //   channel_id: channel.id,
          //   data: {
          //     channel: channel,
          //     type: "user",
          //   },
          // });
          channel_id=channel.id
        }
        const { data: post } = await MATTERMOST_API.endpoints.posts.createPosts({
          channel_id: channel_id,
          message: this.post.message,
          file_ids: this.fileIds,
          props: {
            ...this.post.props,
            is_forwarded: true,
          },
          parent_id: "",
          root_id: "",
        });
        mattermost.emitOnUserLevel(mattermost.events.posted,{
          channel_id: post.channel_id,
          post: post,
        })
        this.$set(this.sentChannels, channel_id, true);
      } finally {
        this.is_submitted = false;
      }
    },
  },
};
</script>

<style>
</style>