var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup KbSupportModal",
    class: {
      'iframeContainer-c': _vm.data.kb,
      'isFullScreen-container': _vm.conditions.isFullScreen
    },
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.data.kb ? _c('div', {
    staticClass: "position-relative-c",
    class: {
      'isFullScreen': _vm.conditions.isFullScreen
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Help & support")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])])]), _vm.conditions.isFullScreen ? [_c('div', {
    staticClass: "d-flex w-100 align-items-center isFullScreenContainer"
  }, [_c('div', {
    staticClass: "expandText",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "arrow-right"
    }
  }), _c('span', {
    staticClass: "cursor_pointer"
  }, [_vm._v("Close")])], 1)])] : [_c('div', {
    staticClass: "d-flex w-100 align-items-center isFullScreenContainer"
  }, [_c('div', {
    staticClass: "expandText",
    on: {
      "click": function ($event) {
        _vm.conditions.isFullScreen = true;
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "arrow-left"
    }
  }), _c('span', {
    staticClass: "cursor_pointer"
  }, [_vm._v("Expand")])], 1)])]], 2), _c('div', {
    staticClass: "ticketTabContainer iframeContainer-c"
  }, [_c('div', {
    staticClass: "iframeContainer"
  }, [_vm.api.kb_link.send ? [_c('div', {
    staticClass: "w-100 p-5"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "d-flex align-items-center mb-4"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "150px",
        "width": "150px"
      }
    }), _c('div', {
      staticClass: "d-flex flex-column flex-fill ml-4"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mb-4",
      staticStyle: {
        "height": "40px",
        "width": "80%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign mb-3",
      staticStyle: {
        "height": "28px",
        "width": "50%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "28px",
        "width": "60%"
      }
    })])]);
  }), 0)] : _vm._e(), _c('iframe', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    },
    style: `opacity:${_vm.api.kb_link.send ? 0 : 1}`,
    attrs: {
      "src": _vm.response.kb_link
    }
  })], 2)])]) : _c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [!_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Help & support")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-container"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.value,
      staticClass: "TabsDesignWithIcon-item",
      class: {
        'active': _vm.selected.tab == tab.value,
        'mwd-168px': tab.value != 3
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.icon,
        "height": "38px",
        "width": "38px"
      }
    }), _c('span', {
      staticClass: "TabsDesignWithIcon-item-textPart"
    }, [_vm._v(_vm._s(tab.text))])], 1);
  }), 0), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton ml-8px",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])])]), _vm.conditions.isFullScreen ? [_c('div', {
    staticClass: "d-flex w-100 align-items-center"
  }, [_c('div', {
    staticClass: "expandText",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "arrow-right"
    }
  }), _c('span', {
    staticClass: "cursor_pointer"
  }, [_vm._v("Close")])], 1)])] : [_c('div', {
    staticClass: "d-flex w-100 align-items-center"
  }, [_c('div', {
    staticClass: "expandText",
    on: {
      "click": function ($event) {
        _vm.conditions.isFullScreen = true;
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "arrow-left"
    }
  }), _c('span', {
    staticClass: "cursor_pointer"
  }, [_vm._v("Expand")])], 1)])]], 2) : _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Help & support")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "d-flex w-100 justify-content-start mt-3"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.value,
      staticClass: "dialer-button dialer-button-primary-outline border-0 v2 mr-3",
      class: {
        'active': _vm.selected.tab == tab.value
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
        }
      }
    }, [_vm._v(" " + _vm._s(tab.text) + " ")]);
  }), 0)]), _vm.conditions.isFullScreen ? [_c('div', {
    staticClass: "d-flex w-100 align-items-center expand-container"
  }, [_c('div', {
    staticClass: "expandText",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "arrow-right"
    }
  }), _c('span', {
    staticClass: "cursor_pointer"
  }, [_vm._v("Close")])], 1)])] : [_c('div', {
    staticClass: "d-flex w-100 align-items-center expand-container"
  }, [_c('div', {
    staticClass: "expandText",
    on: {
      "click": function ($event) {
        _vm.isFullScreen = true;
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "arrow-left"
    }
  }), _c('span', {
    staticClass: "cursor_pointer"
  }, [_vm._v("Expand")])], 1)])]], 2), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selected.tab == _vm.tabs.knowledge_base.value,
      expression: "selected.tab == tabs.knowledge_base.value"
    }],
    staticClass: "ticketTabContainer iframeContainer-c"
  }, [_c('div', {
    staticClass: "iframeContainer"
  }, [_vm.api.kb_link.send ? [_c('div', {
    staticClass: "w-100 p-5"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "d-flex align-items-center mb-4"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "150px",
        "width": "150px"
      }
    }), _c('div', {
      staticClass: "d-flex flex-column flex-fill ml-4"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mb-4",
      staticStyle: {
        "height": "40px",
        "width": "80%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign mb-3",
      staticStyle: {
        "height": "28px",
        "width": "50%"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "28px",
        "width": "60%"
      }
    })])]);
  }), 0)] : [_c('iframe', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    },
    style: `opacity:${_vm.api.kb_link.send ? 0 : 1}`,
    attrs: {
      "src": _vm.response.kb_link
    }
  })]], 2)]), _vm.selected.tab == _vm.tabs.contact_support.value ? _c('div', {
    staticClass: "ticketTabContainer mt-5"
  }, [_c('ContectSupport', {
    on: {
      "go-to-knowledge-base": function ($event) {
        _vm.selected.tab = _vm.tabs.knowledge_base.value;
      }
    }
  })], 1) : _vm._e(), _vm.selected.tab == _vm.tabs.quick_start.value ? _c('div', {
    staticClass: "ticketTabContainer mt-5"
  }, [_c('QuickStart')], 1) : _vm._e()])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }