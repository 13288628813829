<template>
  <div class="d-flex align-items-center mb-16px">
    <div 
      v-for="activity_type in GET_CRM_ACTIVITY_TYPES" 
      :key="activity_type.id" 
      @click="disabled ? '' : $emit('change',activity_type.id)" 
      class="newCloseButton onlyIcon mr-2"
      :class="{
        active: activity_type.id==value
      }"
    >
      <vb-icon :icon="activity_type.icon | filterIcon" height="18px" width="18px"/>
    </div>
    <slot name="errors" />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_CRM_ACTIVITY_TYPES } from '@/store/helper/getters';
import { crmFilterActivityTypeIcon } from '@/filter';
export default {
  name: 'SelectActivityTypeInput',
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: [String,Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      GET_CRM_ACTIVITY_TYPES
    ])
  },
  filters: {
    filterIcon: crmFilterActivityTypeIcon,
  },
  methods: {
    
  },
}
</script>

<style>

</style>