<template>
  <PowerDialerFile />
</template>

<script>
import PowerDialerFile from "../components/PowerDialer/PowerDialerFile.vue";

export default {
  name: 'PowerDialer',
  components: {
    PowerDialerFile,
  },
}
</script>

<style>

</style>