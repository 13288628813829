<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto" 
      :name="modalName" 
      @before-open="onBeforeOpen" 
      @before-close="onBeforeClose"
    >
      <template>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$modal.hide(modalName)" />
          </div>
          <div class="dialer-settings-title topMainHeading">Add Blocked Number</div>
          <div class="plus-IOSIcon-container"></div>
        </div>
      </template>
      <div class="innerModalIos">
        <form novalidate @submit.prevent="addnumber()">
          
          <b-alert :show="!!api.add_number.error_message" variant="danger">{{ api.add_number.error_message }}</b-alert>
          <div class="basicWhiteIOScard mt-32px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Number</label>
                <input type="text" onkeypress="return /[0-9]/i.test(event.key)" maxlength="12" :disabled="api.add_number.send" v-model="forms.add_number.callerid" label="Number" />
                <template v-if="forms.add_number.submitted">
                  <p v-if="$v.forms.add_number.callerid.$invalid" class="text text-danger animated bounceIntop mt-5px">
                    <span v-if="!$v.forms.add_number.callerid.required">* Number is required</span>
                    <span v-else-if="!$v.forms.add_number.callerid.numeric">* Number is can only be numeric character</span>
                    <span v-else-if="!$v.forms.add_number.callerid.minLength">* Number is should be minimum {{ $v.forms.add_number.callerid.$params.minLength.min }} character</span>
                    <span v-else-if="!$v.forms.add_number.callerid.maxLength">* Number is can be maximum {{ $v.forms.add_number.callerid.$params.maxLength.max }} character</span>
                  </p>
                </template>
                <template v-if="api.add_number.validation_errors">
                  <p v-if="api.add_number.validation_errors.callerid" class="text text-danger animated bounceIntop mt-5px">
                    <span v-for="(error_message,index) in api.add_number.validation_errors.callerid" :key="index">* {{ error_message }}</span>
                  </p>
                </template>
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Label</label>
                <input class type="text" :disabled="api.add_number.send" v-model="forms.add_number.displayname" label="Label " value />
                <template v-if="forms.add_number.submitted">
                  <p v-if="$v.forms.add_number.displayname.$invalid" class="text text-danger animated bounceIntop mt-5px">
                    <span v-if="!$v.forms.add_number.displayname.required">* Label is required</span>
                  </p>
                </template>
                <template v-if="api.add_number.validation_errors">
                  <p v-if="api.add_number.validation_errors.displayname" class="text text-danger animated bounceIntop mt-5px">
                    <span v-for="(error_message,index) in api.add_number.validation_errors.displayname" :key="index">* {{ error_message }}</span>
                  </p>
                </template>
              </div>
            </div>
          </div>
          <div class="textAboveCard">Direction Of Number Block</div>
          <div class="basicWhiteIOScard">
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="headingInsideTable">Block Inbound Calls</div>
              </div>
              <div class="position-relative">
                <b-form-checkbox 
                  :disabled="api.add_number.send" 
                  v-model="forms.add_number.inbound" 
                  class="dialer-switch IosSwitch" switch 
                />
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="headingInsideTable">Block Outbound Calls</div>
              </div>
              <div class="position-relative">
                <b-form-checkbox 
                  :disabled="api.add_number.send" 
                  v-model="forms.add_number.outbound" 
                  class="dialer-switch IosSwitch" switch 
                />
              </div>
            </div>
          </div>
          <button class="IosFullWidthButton forDone mt-16px mb-16px" :disabled="api.add_number.send">
            <vb-spinner v-if="api.add_number.send" />
            <template v-else> Done </template>
          </button>
          <div class="textBelowCard">
            You can choose which direction you'd like to block this number.
            Inbound will
            <br />prevent calls coming in whereas,
          </div>
          <div class="textBelowCard">
            Outbound will prevent
            calls being made to that <br />number. 
          </div>
          <div class="textBelowCard">
            Selecting both In and
            Outbound will prevent calls to or from this number.
          </div>
        </form>



      </div>
    </modal>
  </div>
</template>

<script>
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators";
import { VOIP_API } from "../../../../utils";
export default {
  name: "AddBlockAndPrivacy",
  props: {
    modalName: {
      type: String,
      default: 'AddBlockAndPrivacy',
    },
  },
  data() {
    return {
      forms: {
        add_number: this.$helperFunction.formInstance({ 
          data: {
            callerid: '',
            displayname: '',
            inbound: true,
            outbound: true,
          } 
        }),
      },
      api: {
        add_number: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true, }),
      },
    };
  },
  inject: [
    'appNotify',
  ],
  validations: {
    forms: {
      add_number: {
        callerid: { 
          required,
          numeric,
          minLength: minLength(8),
          maxLength: maxLength(12),
        },
        displayname: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      //TODO
    },
    onBeforeClose(){
      this.forms.add_number.reset()
      this.api.add_number.reset()
    },
    addnumber(){
      let vm = this
      vm.forms.add_number.submitted=true
      vm.$v.forms.add_number.$touch();
      if(vm.api.add_number.send || vm.$v.forms.add_number.$invalid) return;
      vm.api.add_number.send=true
      vm.api.add_number.validation_errors={}
      vm.api.add_number.error_message=''
      VOIP_API.endpoints.inbound_rule.create({
        callerid: vm.forms.add_number.callerid,
        displayname: vm.forms.add_number.displayname,
        type: vm.forms.add_number.inbound && vm.forms.add_number.outbound ? 'both' : vm.forms.add_number.inbound ? 'in' : vm.forms.add_number.outbound ? 'out' : '',
        action: "save",
        enable: "Y",
        command: "busy",
        fid: 12,
        args: "fsdfsd",
        source: "app",
      }).then(() => {
        vm.appNotify({
          message: "Number added successfully",
          type: "success",
        })
        vm.$modal.hide(vm.modalName);
        vm.$emit("interface");
      }).catch((ex) => {
        vm.api.add_number.validation_errors=ex.own_errors
        vm.api.add_number.error_message=ex.own_message
      }).finally(() => {
        vm.api.add_number.send=false
        vm.forms.add_number.submitted=false
      });
    },
  },
};
</script>
