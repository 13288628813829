<template>
  <div ref="departmentContainer" class="departmentContainer"> 
    
    <div v-if="!$store.getters.getIsMobile" class="dialer-tabs">
      <div class="dialer-tab-headers">
        <div v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value" :class="['dialer-tab text-capitalize', { selected: selected.tab==tab.value }]">
          {{ tab.text }}
        </div>
      </div>
    </div>
    <!--<div v-else class="IOSlike-tabs totalTabsIs2 mt-2">
      <!~~ <div class="dialer-tab-headers"> ~~>
        <div v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value" :class="['TabsHeaderNames', { active: selected.tab==tab.value }]">
          {{ tab.text }}
        </div>
      <!~~ </div> ~~>
    </div>-->
    <template v-if="selected.tab==tabs.teams.value">
      <b-form v-if="!$store.getters.getIsMobile" class="whiteBGinputWithGreyRoundedBorder mb-3" @submit.prevent="''">
        <b-input v-model="filter.teams.search" placeholder="Search here"/>
      </b-form>
      <!-- mobile view design START here -->
      <b-form v-else class="updatingContactsAgain-contacts-searchBar-container" @submit.prevent="''">
        <div class="updatingContactsAgain-contacts-searchBar">
          <vb-icon icon="mobile-searchBar-icon-likeAirCall-icon" width="12.68px" height="12.67px"/>
          <b-input type="text" class="w-100" placeholder="Search" v-model="filter.teams.search"/>
        </div>
      </b-form>
      <!-- mobile view design END here -->
      <div id="departments_tab" class="tab-pane active">
        <div class="dialer_contact_list departments_list allow_scroll">
          <div class="dialer_contact_department_box" v-for="team in filterTeams" :key="team.id">
            <template v-if="!$store.getters.getIsMobile">
              <div class="dialer_contact_department_box_header  justify-content-between show-on-hover" block v-b-toggle="'accordion-' + team.id" variant="info">
                <div class="d-flex align-items-center" style="width:inherit;">
                  <info :id="team.real_id" />
                  <!-- <span v-if="team.is_favourite">
                    <b-icon icon="star-fill" variant="primary"/>
                  </span> -->
                </div>
                <div class="dialer_contact_hidden_area hidden-for-scroll mr-4">
                  <div class="dialer_contact_connection_box">
                    <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                      <b-dropdown varient="link" no-caret class="ico-filter">
                        <template v-slot:button-content>
                          <b-icon icon="three-dots" scale="1.5" />
                        </template>
                        <b-dropdown-item @click="dialTeam(team)">
                          <vb-svg name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                          <span class="ml-2">Call</span>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="team.members.findIndex(mem=>mem.accountcode==getCurrentUser.account)>-1" @click="chatTeam(team)">
                          <vb-svg name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                          <span class="ml-2">Chat</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleFavourite(team)">
                          <b-icon :icon="team.is_favourite ? 'star-fill' : 'star'"/>
                          <span class="ml-2">{{ team.is_favourite ? 'Remove from Favourite' : 'Add To Favourite' }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </div>
                </div>
                <span v-if="team.is_favourite" class="mr-4 inTeamsFavouriteIcon">
                  <b-icon icon="star-fill" variant="primary"/>
                </span>
                <vb-svg name="dialer-downArrow-icon" width="14" height="8.001" viewBox="0 0 14 8.001" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialer-downArrow" />
              </div>
              <b-collapse class="dialer_contact_department_box_hidden_area" :id="'accordion-' + team.id" accordion="my-accordion">
                <div class="dialer_contact_row" v-if="!isEmpty(team.members)">
                  <div class="dialer_contact" v-for="(member, key) in team.members" :key="key">
                    <div class="d-flex align-items-center" style="width:inherit">
                      <info :id="member.accountcode" />
                    </div>
                    <div class="dialer_contact_hidden_area">
                      <div class="dialer_contact_connection_box">
                        <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                          <b-dropdown v-if="getCurrentUser.account!=member.accountcode" varient="link" no-caret class="ico-filter">
                            <template v-slot:button-content>
                              <b-icon icon="three-dots" scale="1.5" />
                            </template>
                            <b-dropdown-item @click="dial(member)">
                              <vb-svg class="mr-2" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Call</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="chatUser(member)">
                              <vb-svg class="mr-2" name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Chat</span>
                            </b-dropdown-item>
                            <b-dropdown-item @click="videoCall(member)">
                              <vb-svg class="mr-2" name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                              <span>Video</span>
                            </b-dropdown-item>
                          </b-dropdown>
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
                <vb-no-record v-if="isEmpty(team.members)" :text="'No members in this team'" :design="3" />
              </b-collapse>
            </template>
            <!-- mobile view design START here -->
            <template v-else>
              <div class="updatingContactsAgain-contact-item" v-b-toggle="'accordion-' + team.id">
                <info :id="team.real_id" :is_unread_show="false" :is_suspended_show="false" :is_blf="false"/>
                <div class="showOnlyOnHover">
                  <vb-svg name="dialer-downArrow-icon" width="16px" height="10px" viewBox="0 0 14 8.001" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialer-downArrow mr-3" />
                  <b-dropdown varient="link" no-caret class="ico-filter">
                    <template v-slot:button-content>
                      <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                    </template>
                    <b-dropdown-item @click="dialTeam(team)">
                      <vb-svg name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span class="ml-2">Call</span>
                    </b-dropdown-item>
                    <b-dropdown-item v-if="team.members.findIndex(mem=>mem.accountcode==getCurrentUser.account)>-1" @click="chatTeam(team)">
                      <vb-svg name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                      <span class="ml-2">Chat</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="toggleFavourite(team)">
                      <b-icon icon="star-fill"/>
                      <span class="ml-2">{{ team.is_favourite ? 'Remove from Favourite' : 'Add To Favourite' }}</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
              <b-collapse :id="'accordion-' + team.id" accordion="my-accordion">
                <div class="dialer_contact_row pr-0 py-0 " v-if="!isEmpty(team.members)">
                  <div class="updatingContactsAgain-contact-item" v-for="(member, key) in team.members" :key="key">
                    <info :id="member.accountcode" :is_unread_show="false" :is_suspended_show="false" :is_blf="false" />
                    <b-dropdown  varient="link" no-caret class="ico-filter">
                      <template v-slot:button-content>
                        <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
                      </template>
                      <b-dropdown-item @click="dial(member)">
                        <vb-svg name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span class="ml-2">Call</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="chatUser(member)">
                        <vb-svg name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span class="ml-2">Chat</span>
                      </b-dropdown-item>
                      <b-dropdown-item @click="videoCall(member)">
                        <vb-svg name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span class="ml-2">Video</span>
                      </b-dropdown-item>
                    </b-dropdown>
                  </div>
                </div>
                <vb-no-record v-if="isEmpty(team.members)" :text="'No members in this team'" :design="3" />
              </b-collapse>
            </template>
            <!-- mobile view design END here -->
          </div>
          <vb-no-record v-if="isEmpty(filterTeams)" :text="'No teams available'" :design="3" />
        </div>
      </div>
    </template>
    <template v-if="selected.tab==tabs.call_queue.value">
      <b-form class="whiteBGinputWithGreyRoundedBorder mb-3" @submit.prevent="''">
        <b-input v-model="filter.call_queue.search" placeholder="Search here"/>
      </b-form>
      <div id="departments_tab" class="tab-pane active">
        <div class="dialer_contact_list departments_list allow_scroll">
          <div class="dialer_contact_department_box" v-for="call_queue in filterCallQueues" :key="call_queue.voipaccount">
            <div class="dialer_contact_department_box_header  justify-content-between show-on-hover" block v-b-toggle="'accordion-' + call_queue.voipaccount" variant="info">
              <div class="d-flex align-items-center" style="width:inherit;">
                <info :id="call_queue.voipaccount" />
              </div>
              <div class="dialer_contact_hidden_area hidden-for-scroll mr-4">
                <div class="dialer_contact_connection_box">
                  <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                    <b-dropdown varient="link" no-caret class="ico-filter">
                      <template v-slot:button-content>
                        <b-icon icon="three-dots" scale="1.5" />
                      </template>
                      <b-dropdown-item @click.stop="dialCallQueue(call_queue)">
                        <vb-svg class="mr-2" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                        <span>Call</span>
                      </b-dropdown-item>
                      <template v-if="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.adminforce`,'no')=='no'">
                        <b-dropdown-item @click.stop="callQueueToggleLogin(call_queue)">
                          <b-icon class="mr-2" icon="box-arrow-in-left" />
                          <span>{{call_queue | get_property(`call_queue_status.${getCurrentUser.account}.logged_in`,false) | is_logged_in }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item v-if="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.logged_in`,false)" @click.stop="callQueueTogglePaused(call_queue)">
                          <b-icon class="mr-2" :icon="getProperty(call_queue,`call_queue_status.${getCurrentUser.account}.is_paused`,false)?'play-fill':'pause-fill'" />
                          <span>{{call_queue | get_property(`call_queue_status.${getCurrentUser.account}.is_paused`,false) | is_paused }}</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleCallQueueFavourite(call_queue)">
                          <b-icon class="mr-2" :icon="call_queue.is_favourite ? 'star-fill' : 'star'"/>
                          <span class="ml-2">{{ call_queue.is_favourite ? 'Remove from Favourite' : 'Add To Favourite' }}</span>
                        </b-dropdown-item>
                      </template>
                    </b-dropdown>
                  </span>
                </div>
              </div>
              <span v-if="call_queue.is_favourite" class="mr-4 inTeamsFavouriteIcon">
                <b-icon icon="star-fill" variant="primary"/>
              </span>
              <vb-svg name="dialer-downArrow-icon" width="14" height="8.001" viewBox="0 0 14 8.001" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialer-downArrow" />
            </div>
            <b-collapse class="dialer_contact_department_box_hidden_area" :id="'accordion-' + call_queue.voipaccount" accordion="my-accordion">
              <div class="dialer_contact_row" v-if="!isEmpty(call_queue.members)">
                <div class="dialer_contact" v-for="member in call_queue.members" :key="member.membername">
                  <div class="d-flex align-items-center" style="width:inherit">
                    <info :id="member.membername" />
                  </div>
                  <div class="dialer_contact_hidden_area">
                    <div class="dialer_contact_connection_box">
                      <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                        <b-dropdown v-if="getCurrentUser.account!=member.membername" varient="link" no-caret class="ico-filter">
                          <template v-slot:button-content>
                            <b-icon icon="three-dots" scale="1.5" />
                          </template>
                          <b-dropdown-item @click="dial(member)">
                            <vb-svg class="mr-2" name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                            <span>Call</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="chatUser(member)">
                            <vb-svg class="mr-2" name="dialer-outlineChat-icon" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                            <span>Chat</span>
                          </b-dropdown-item>
                          <b-dropdown-item @click="videoCall(member)">
                            <vb-svg class="mr-2" name="dialer-outlineVideo-icon" width="20.6" height="13.6" viewBox="0 0 20.6 13.6" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                            <span>Video</span>
                          </b-dropdown-item>
                          <template v-if="getCurrentUser.administrator_account">
                            <template v-if="getProperty(call_queue,`call_queue_status.${member.membername}.adminforce`,'no')=='no'">
                              <b-dropdown-item @click.stop="callQueueToggleLogin(call_queue,member.membername)">
                                <b-icon class="mr-2" icon="box-arrow-in-left" />
                                <span>{{call_queue | get_property(`call_queue_status.${member.membername}.logged_in`,false) | is_logged_in }}</span>
                              </b-dropdown-item>
                              <b-dropdown-item v-if="getProperty(call_queue,`call_queue_status.${member.membername}.logged_in`,false)" @click.stop="callQueueTogglePaused(call_queue,member.membername)">
                                <b-icon class="mr-2" icon="pause-fill" />
                                <span>{{call_queue | get_property(`call_queue_status.${member.membername}.is_paused`,false) | is_paused }}</span>
                              </b-dropdown-item>
                            </template>
                          </template>
                        </b-dropdown>
                      </span>
                    </div>
                  </div>
                </div>
              </div>
              <vb-no-record v-if="isEmpty(call_queue.members)" :text="'No members in this Group'" :design="3" />
            </b-collapse>
          </div>
          <vb-no-record v-if="isEmpty(filterCallQueues)" :text="'No Call Queue available'" :design="3" />
        </div>
      </div>
    </template>
    <DirectChatModal />
    <TeamChatModal />
  </div>
</template>

<script>
import DirectChatModal from "@/components/Modals/chat/DirectChatModal";
import TeamChatModal from "../Chat/Modal/TeamChatModal.vue";
import { 
  events, 
  LOGGER,
  VOIP_API, 
  // VOIP_API 
} from "../../utils";
import Info from '../Lists/Info.vue';
import { mapGetters } from 'vuex';
import { MATTERMOST_API, mattermost } from '@/MatterMost';
let tabs = {
  teams: {
    value: 1,
    text: 'Teams'
  },
  call_queue: {
    value: 2,
    text: 'Call Queue'
  }
}
export default {
  name: "Departments",
  components: {
    DirectChatModal,
    TeamChatModal,
    Info,
  },
  inject:[
    'isEmpty',
    'getCallQueue',
    'getProperty',
    'getVoipTeams',
    'appNotify'
  ],
  data() {
    return {
      api: {
        chat_user: this.$helperFunction.apiInstance(),
        call_queue: this.$helperFunction.apiInstance(),
        toggle_logged: this.$helperFunction.apiInstance(),
        toggle_paused: this.$helperFunction.apiInstance(),
        toggle_team_fav: this.$helperFunction.apiInstance({ send: [] }),
        toggle_call_queue_fav: this.$helperFunction.apiInstance({ send: [] }),
      },
      filter: {
        teams: {
          search: '',
        },
        call_queue: {
          search: '',
        },
      },
      selected: {
        tab: tabs.teams.value,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getCurrentUserVoipCallQueues',
      'getCurrentUser',
      'getMattermostCurrentUser',
    ]),
    tabs(){return tabs},
    filterTeams() {
      const search = this.filter.teams.search.toLowerCase().trim();
      return this.$store.getters.getVoipTeams.filter((event) => `${event.name}`.toLowerCase().trim().split('-').join('').includes(search));
    },
    filterCallQueues() {
      const search = this.filter.call_queue.search.toLowerCase().trim();
      return this.getCurrentUserVoipCallQueues.filter((event) => `${event.label}`.toLowerCase().trim().split('-').join('').includes(search));
    },
  },
  filters: {
    is_logged_in(value){ return value ? 'logout' : 'login' },
    is_paused(value){ return [true,'1',1].includes(value) ? 'Un Pause' : 'Pause' },
  },
  methods: {
    async chatUser(member) {
      const accountcode = member.accountcode || member.membername
      const user = this.getVoipUsersAlises?.[accountcode]
      if(!user?.user?.user_chat_id || this.api.chat_user.send) return;
      let channel_id = user.channel_id
      try {
        this.api.chat_user.send=true
        if(!channel_id){
          const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([
            user.user.user_chat_id,
            this.getMattermostCurrentUser?.id
          ]);
          channel_id=channel.id
          mattermost.emitOnUserLevel(mattermost.events.direct_added,{
            channel_id: channel_id
          })
          this.$set(this.$store.state.common.voipusers[user.voipaccount],'channel_id',channel_id)
        }
        this.$store.state.global_conditions.tab='chat'
        this.$root.$emit(events.pinned_post_changed,{
          channel_id: channel_id
        })
        await this.$nextTick()
        this.$root.$emit(events.open_chat,{
          channel_id: channel_id,
        })
        this.$emit('close-side-bar')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.chat_user.send=false
      }
    },
    async chatTeam(team){
      if(!team?.channel_id || this.api.chat_user.send) return;
      let channel_id = team.channel_id
      try {
        this.api.chat_user.send=true
        this.$store.state.global_conditions.tab='chat'
        this.$root.$emit(events.pinned_post_changed,{
          channel_id: channel_id
        })
        await this.$nextTick()
        this.$root.$emit(events.open_chat,{
          channel_id: channel_id,
        })
        this.$emit('close-side-bar')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.chat_user.send=false
      }
    },
    // chatUser(member) {
    //   const accountcode = member.accountcode || member.membername
    //   const _user = this.getVoipUsersAlises?.[accountcode]
    //   LOGGER.log('user',_user)
    //   if(!_user) return;
    //   this.$modal.show("ChatUserModal", { user: _user });
    // },
    // chatTeam(team){
    //   this.$modal.show("TeamChatModal", { channel_id: team.channel_id });
    // },
    videoCall(member) {
      const accountcode = member.accountcode || member.membername
      const _user = this.getVoipUsersAlises?.[accountcode]
      LOGGER.log('user',_user)
      if(!_user) return;
      this.$root.$emit(events.video_call, {
        accounts: [_user.voipaccount],
        call_type: "video",
      });
    },
    dial(member) {
      const accountcode = member.accountcode || member.membername
      const _user = this.getVoipUsersAlises?.[accountcode]
      LOGGER.log('user',_user)
      if(!_user) return;
      this.$root.$emit(events.audio_call, {
        number: _user.extn,
        id: _user.voipaccount,
      });
    },
    dialTeam(team){
      this.$root.$emit(events.audio_call, {
        number: team.ringroup.extn,
        id: team.real_id,
      });
    },
    toggleFavourite(team){
      let vm = this
      const real_id = team.real_id
      const is_favourite = team.is_favourite
      if(vm.api.toggle_team_fav.send.includes(real_id)) return;
      vm.api.toggle_team_fav.send.push(real_id)
      VOIP_API.endpoints.teams.toogleFavourite({ 
        accountcode: vm.getCurrentUser.account, 
        teamId: real_id, 
      })
      .then(()=>{
        return vm.$store.state.common.teams.data.findIndex(item=>item.real_id==real_id)
      })
      .then((index)=>{
        vm.$store.state.common.teams.data[index].is_favourite = !vm.$store.state.common.teams.data[index].is_favourite
        vm.appNotify({
          message: `Successfully ${is_favourite ? 'Remove from Favourite' : 'Add to Favourite'}`,
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        if(vm.api.toggle_team_fav.send.includes(real_id)) vm.api.toggle_team_fav.send.splice(vm.api.toggle_team_fav.send.indexOf(real_id),1)
      })
    },
    toggleCallQueueFavourite(call_queue){
      let vm = this
      const voipaccount = call_queue.voipaccount
      const is_favourite = call_queue.is_favourite
      if(vm.api.toggle_call_queue_fav.send.includes(voipaccount)) return;
      vm.api.toggle_call_queue_fav.send.push(voipaccount)
      VOIP_API.endpoints.call_queues.toggleFavourite({
        accountcode: voipaccount, 
      })
      .then(()=>{
        return vm.$store.state.common.call_queues.data.findIndex(item=>item.voipaccount==voipaccount)
      })
      .then((index)=>{
        vm.$store.state.common.call_queues.data[index].is_favourite = !vm.$store.state.common.call_queues.data[index].is_favourite
        vm.appNotify({
          message: `Successfully ${is_favourite ? 'Remove from Favourite' : 'Add to Favourite'}`,
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        if(vm.api.toggle_call_queue_fav.send.includes(voipaccount)) vm.api.toggle_call_queue_fav.send.splice(vm.api.toggle_call_queue_fav.send.indexOf(voipaccount),1)
      })
    },
    dialCallQueue(call_queu){
      this.$root.$emit(events.audio_call, {
        number: call_queu.extn,
        id: call_queu.voipaccount,
      });
    },
    callQueueToggleLogin(call_queue,accountcode){
      let vm = this
      if(vm.api.toggle_logged.send) return;
      vm.api.toggle_logged.send=true
      VOIP_API.endpoints.call_queues.togglelogin(call_queue.voipaccount,{
        member: accountcode || vm.getCurrentUser?.sip?.user,
      })
      .then(({ data })=>{
        vm.appNotify({
          message: `Successfully ${data.join ? 'Logged in' : 'Logged out'}`,
          type: 'success',
        })
        vm.getCallQueue()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.toggle_logged.send=false
      })
    },
    callQueueTogglePaused(call_queue,accountcode){
      let vm = this
      if(vm.api.toggle_paused.send) return;
      vm.api.toggle_paused.send=true
      VOIP_API.endpoints.call_queues.togglepaused(call_queue.voipaccount,{
        member: accountcode || vm.getCurrentUser?.sip?.user,
      })
      .then(({ data })=>{
        vm.appNotify({
          message: `Successfully ${data.paused ? 'Paused' : 'Un Pause'}`,
          type: 'success',
        })
        vm.getCallQueue()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.toggle_paused.send=false
      })
    },
    // load() {
    //   let vm = this
    //   vm.api.teams.send = true;
    //   VOIP_API.endpoints.departments.list({
    //     fid: 12,
    //   })
    //   .then(({ data: res }) => {
    //     this.$store.state.common.departments = res;
    //   })
    //   .finally(() => {
    //     vm.api.teams.send = false;
    //   });
    // },
  },
  mounted() {
    // this.load();
    this.getCallQueue()
    this.getVoipTeams()
  },
};
</script>
