var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v(_vm._s(_vm.label))]), _c('Multiselect', {
    attrs: {
      "value": _vm.value,
      "label": "name",
      "track-by": "id",
      "placeholder": "Type to search",
      "open-direction": "bottom",
      "options": _vm.response.companies,
      "multiple": true,
      "searchable": true,
      "loading": _vm.api.fetch_companies.send,
      "internal-search": false,
      "clear-on-select": false,
      "close-on-select": false,
      "options-limit": 300,
      "limit": 3,
      "max-height": 600,
      "show-no-results": false,
      "hide-selected": true,
      "disabled": _vm.disabled
    },
    on: {
      "input": function ($event) {
        return _vm.$emit('change', $event);
      },
      "search-change": function ($event) {
        return _vm.fetchCompanies($event);
      }
    },
    scopedSlots: _vm._u([{
      key: "noResult",
      fn: function () {
        return [_c('span', [_vm._v("There is no Companies")])];
      },
      proxy: true
    }, {
      key: "noOptions",
      fn: function () {
        return [_c('span', [_vm._v("There is no Companies")])];
      },
      proxy: true
    }])
  }), _vm._t("errors")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }