<template>
  <div class="dialer-analytics-main dialer-analytics-likeSomeThing">
    <div class="dialer-box">
      <h2 class="dialer-settings-title newer">
        Call activity
      </h2>
      <p class="dialer-settings-intro newerTextVersion">
        <span>
          Here’s where you can monitor calls and users.
        </span>
      </p>
      <div class="d-flex w-100">
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mr-20px wd-20">
          <label>Date</label>
          <vb-select class="w-100"
            :selected="5"
            :options="[
              { text: 'Last 14 days', value: 5 },
              { text: 'Last 14 days', value: 10 },
              { text: 'Last 14 days', value: 15 },
              { text: 'Last 14 days', value: 20 },
              { text: 'Last 14 days', value: 25 },
            ]"
          />
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mr-20px wd-20">
          <label>Date breakdown</label>
          <vb-select class="w-100"
            :selected="5"
            :options="[
              { text: 'is daily', value: 5 },
              { text: 'is daily', value: 10 },
              { text: 'is daily', value: 15 },
              { text: 'is daily', value: 20 },
              { text: 'is daily', value: 25 },
            ]"
          />
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mr-20px wd-20">
          <label>Numbers</label>
          <vb-select class="w-100"
            :selected="5"
            :options="[
              { text: 'is any value', value: 5 },
              { text: 'is any value', value: 10 },
              { text: 'is any value', value: 15 },
              { text: 'is any value', value: 20 },
              { text: 'is any value', value: 25 },
            ]"
          />
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mr-20px wd-20">
          <label>SLA (in sec)</label>
          <vb-select class="w-100"
            :selected="5"
            :options="[
              { text: 'is 30', value: 5 },
              { text: 'is 30', value: 10 },
              { text: 'is 30', value: 15 },
              { text: 'is 30', value: 20 },
              { text: 'is 30', value: 25 },
            ]"
          />
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20">
          <label>Timezone</label>
          <vb-select class="w-100"
            :selected="5"
            :options="[
              { text: 'UTC', value: 5 },
              { text: 'UTC', value: 10 },
              { text: 'UTC', value: 15 },
              { text: 'UTC', value: 20 },
              { text: 'UTC', value: 25 },
            ]"
          />
        </div>
      </div>
      <div class="d-flex w-100 mt-32px">
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading">178</div>
          <div class="likeSomething-box-text">
            <span>Total calls</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleSuccess-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller success">235.8%</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading newPurple">8</div>
          <div class="likeSomething-box-text">
            <span>Total inbound calls</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleDanger-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller danger">-75.0%</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading basicPurple">170</div>
          <div class="likeSomething-box-text">
            <span>Total outbound calls</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleSuccess-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller success">709.5%</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading">00:00:32</div>
          <div class="likeSomething-box-text">
            <span>Average call talk time</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleSuccess-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller success">50.8%</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
      </div>
      <div class="mt-15px likeSomething-box w-100">
        <!-- put graph here -->
      </div>
      <div class="mt-45px likeSomething-sectionHeading">Inbound calls</div>
      <div class="d-flex w-100 mt-45px">
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading redSomething">75.0%</div>
          <div class="likeSomething-box-text">
            <span>Missed calls</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleDanger-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller danger">40.6%</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading">100.0%</div>
          <div class="likeSomething-box-text">
            <span>Service level</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleNormal-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller">0.0</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading ">00:00:04</div>
          <div class="likeSomething-box-text">
            <span>Average waiting duration</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleSuccess-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller success"> -00:00:04</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading">00:00:12</div>
          <div class="likeSomething-box-text">
            <span>Average inbound talk time</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleDanger-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller danger">-71.7%</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
      </div>
      <div class="d-flex w-100 mt-15px justify-content-between">
        <div class="wd-62-imp likeSomething-box">
          <!-- put graph here -->
        </div>
        <div class="likeSomething-box-withHeader">
          <div class="withHeader">
            The inbound calls breakdown by missed reason is:
          </div>
          <div class="withBody">
            <div class="with-tag-row">
              <div class="with-tag-row-tagItself">
                25.0%
              </div>
              <div class="with-tag-row-text lighter">
                of calls were not missed.
              </div>
            </div>
            <div class="with-tag-row">
              <div class="with-tag-row-tagItself lightOrange">
                62.5%
              </div>
              <div class="with-tag-row-text">
                of calls were not answered by an agent because no user was available.
              </div>
            </div>
            <div class="with-tag-row">
              <div class="with-tag-row-text regular">
                To reduce this, consider adding more users.
              </div>
            </div>
            <div class="with-tag-row">
              <div class="with-tag-row-tagItself orange">
                12.5%
              </div>
              <div class="with-tag-row-text">
                of calls were not answered by an agent because no available user picked up the call.
              </div>
            </div>
            <div class="with-tag-row">
              <div class="with-tag-row-text regular">
                To reduce this communication a clear strategy to your users.
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="mt-15px likeSomething-box w-100">
        <!-- put graph here -->
      </div>
      <div class="mt-45px likeSomething-sectionHeading">Inbound calls</div>
      <div class="d-flex w-100 mt-45px">
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading success">90.6%</div>
          <div class="likeSomething-box-text">
            <span>Connected rate</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleSuccess-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller success">9.6</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading">00:00:32</div>
          <div class="likeSomething-box-text">
            <span>Average outbound talk time</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleDanger-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller danger">-00:00:51</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
      </div>
      <div class="mt-15px likeSomething-box w-100">
        <!-- put graph here -->
      </div>
      <div class="mt-45px likeSomething-sectionHeading">Tags</div>
      <div class="d-flex w-100 mt-45px">
        <div class="wd-33-imp likeSomething-box align-items-center justify-content-center">
          <div class="likeSomething-box-heading success">90.6%</div>
          <div class="likeSomething-box-text">
            <span>Connected rate</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
        </div>
        <div class="likeSomething-box flex-fill">
          <!-- put graph here -->
        </div>
      </div>
      <div class="mt-45px likeSomething-sectionHeading">Calls by main participant</div>
      <div class="likeSomething-box-text mt-24px mb-32px text-left">
        The main participant of a call is whoever finalizes it. A call may have more than one participant, but on this chart only the last one gets credited for the call. 
        Missed inbound calls and transferred outbound calls don’t have main users associated to them.
      </div>
      <vb-table 
        class="latestTableDesign-withBlackBorders-again mt-0"
        :isListEmpty="sampleData.length==0" 
        :listLength="sampleData.length" 
        :perPage="5" 
      >
        <tr slot="header" class="w-100">
          <th class="border-0"> 
            <span class="">User</span>
          </th>
          <th class=" border-0 ">
            <span class="">Total calls finalized by agent</span>
          </th>
          <th class=" border-0 ">
            <span class="">Inbound calls finalized by agent</span>
          </th>
          <th class=" border-0 ">
            <span class="">Outbound calls finalized by agent </span>
          </th>
        </tr>
        <template #body="{ start, end }">
          <tr class="" v-for="(data,index) in sampleData" :key="data.id" v-show="index >= start && index <= end" >
            <td>{{data.one}}</td>
            <td>{{data.two}}</td>
            <td>{{data.three}}</td>
            <td>{{data.four}}</td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex w-fit-content">
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex w-fit-content">
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex w-fit-content">
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
      <div class="mt-45px likeSomething-sectionHeading">SMS</div>
      <div class="d-flex mt-45px w-100">
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading">0</div>
          <div class="likeSomething-box-text">
            <span>Total SMS</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleNormal-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller">0%</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading">0</div>
          <div class="likeSomething-box-text">
            <span>Inbound SMS</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleNormal-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller">0%</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
        <div class="likeSomething-box flex-fill">
          <div class="likeSomething-box-heading">0</div>
          <div class="likeSomething-box-text">
            <span>Outbound SMS</span>
            <vb-icon icon="latest-infoWithCircle-icon" />
          </div>
          <div class="d-flex align-items-center mt-24px">
            <vb-icon icon="latest-triangleNormal-icon" class="mr-2" width="18px" height="11.617px"/>
            <div class="likeSomething-box-text-smaller">0%</div>
            <div class="likeSomething-box-text-smaller">vs previous period</div>
          </div>
        </div>
      </div>
      <div class="mt-15px likeSomething-box w-100">
        <!-- put graph here -->
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "Something",
  data() {
    return {
      data: {
        sampleDataArray: [
          {
            one: 'Dave usama',
            two: '170',
            three: '2',
            four: '168',
          },
          {
            one: 'Dave usama',
            two: '170',
            three: '2',
            four: '168',
          },
          {
            one: 'Dave usama',
            two: '170',
            three: '2',
            four: '168',
          },
          {
            one: 'Dave usama',
            two: '170',
            three: '2',
            four: '168',
          }
        ],
      }
    }
  },
  computed: {
    sampleData(){ return this.data.sampleDataArray },
  },
}
</script>

<style>

</style>