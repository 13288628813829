var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-soundSetting-container"
  }, [_c('div', {
    staticClass: "dialer-soundSetting dialer-soundSetting-responsive"
  }, [_c('h4', [_vm._v("Audio")]), _c('div', {
    staticClass: "dialer-soundSetting-device dialer-volumeSetting-device"
  }, [_c('div', {
    staticClass: "dialer-soundSetting-device-inner"
  }, [_c('span', {
    staticClass: "dialer-soundSetting-device-name"
  }, [_vm._v(" Microphone ")]), _c('div', {
    staticClass: "dialer-dropdown dialer-soundSetting-device-dropdown"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedMicDevice, 'label', 'Select Device')))]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.media_devices_setting.audioinputs, function (device) {
    return _c('b-dropdown-item', {
      key: device.deviceId,
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.micId = device.deviceId;
        }
      }
    }, [device.deviceId == _vm.media_devices_setting.mic_id ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(device.label) + " ")])], 1);
  }), 1)], 1)]), _vm.media_devices_setting.mic_id ? _c('MicrophoneTester', {
    attrs: {
      "deviceId": _vm.media_devices_setting.mic_id
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "dialer-soundSetting-device dialer-volumeSetting-device"
  }, [_c('div', {
    staticClass: "dialer-soundSetting-device-inner"
  }, [_c('span', {
    staticClass: "dialer-soundSetting-device-name"
  }, [_vm._v(" Speaker ")]), _c('div', {
    staticClass: "dialer-dropdown dialer-soundSetting-device-dropdown"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedSpeakerDevice, 'label', 'Select Device')) + " ")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.media_devices_setting.audiooutputs, function (device) {
    return _c('b-dropdown-item', {
      key: device.deviceId,
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.speakerId = device.deviceId;
        }
      }
    }, [_vm.media_devices_setting.speaker_id == device.deviceId ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(device.label))])], 1);
  }), 1)], 1)]), _c('input', {
    attrs: {
      "min": "0",
      "max": "100",
      "type": "range",
      "step": "1"
    },
    domProps: {
      "value": _vm.media_devices_setting.speaker_volume
    },
    on: {
      "change": function ($event) {
        _vm.media_devices_setting.speakerVolume = $event.target.value;
      }
    }
  }), _c('audio', {
    attrs: {
      "show": false,
      "id": "testing-audio",
      "loop": "",
      "src": require('@/assets/sounds/incoming.mp3')
    }
  })]), _c('div', {
    staticClass: "dialer-soundSetting-device border-0"
  }, [_c('span', {
    staticClass: "dialer-soundSetting-device-name"
  }, [_vm._v(" Video ")]), _c('div', {
    staticClass: "dialer-dropdown dialer-soundSetting-device-dropdown"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedCameraDevice, 'label', 'Select Device')) + " ")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.media_devices_setting.videoinputs, function (device) {
    return _c('b-dropdown-item', {
      key: device.deviceId,
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.cameraId = device.deviceId;
        }
      }
    }, [_vm.media_devices_setting.camera_id == device.deviceId ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(device.label) + " ")])], 1);
  }), 1)], 1)]), _vm.media_devices_setting.camera_id ? _c('CameraTester', {
    attrs: {
      "deviceId": _vm.media_devices_setting.camera_id
    }
  }) : _vm._e(), _c('div', {
    staticClass: "dialer-soundSetting-testing"
  }, [_c('div', {
    staticClass: "dialer-soundSetting-testing-audio",
    on: {
      "click": function ($event) {
        _vm.$parent.$parent.conditions.clicked = true;
        _vm.is_play = !_vm.is_play;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "id": "toggle-play",
      "name": !_vm.is_play ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead',
      "width": "16",
      "stroke": "",
      "height": "18.035",
      "viewBox": "0 0 16 18.035",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-soundSetting-testing-audio-text"
  }, [_vm._v(" Test audio ")])], 1), _c('div', {
    staticClass: "dialer-soundSetting-testing-audio dialer-soundSetting-testing-call",
    on: {
      "click": function ($event) {
        _vm.isEmpty(_vm.testCallSession) ? _vm.testCall() : _vm.$store.state.sip.phone.activeSession.hangup();
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-outlineCall-icon",
      "width": "18.801",
      "stroke": "",
      "height": "18.802",
      "viewBox": "0 0 18.801 18.802",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-soundSetting-testing-audio-text"
  }, [_vm._v(" " + _vm._s(_vm.isEmpty(_vm.testCallSession) ? "Make a free test call" : "End Call") + " ")])], 1)]), _c('div', {
    staticClass: "dialer-soundSetting-device dialer-volumeSetting-device"
  }, [_c('div', {
    staticClass: "dialer-soundSetting-device-inner"
  }, [_c('span', {
    staticClass: "dialer-soundSetting-device-name"
  }, [_vm._v("Ringtone")]), _c('div', {
    staticClass: "dialer-dropdown dialer-soundSetting-device-dropdown"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.selectedRingtone, 'label', 'Select Ringtone')) + " ")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.ringtones, function (ring, key) {
    return _c('b-dropdown-item', {
      key: key,
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.ringtoneAudio = ring.value;
        }
      }
    }, [_vm.media_devices_setting.ringtoneAudio == ring.value ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(ring.label))])], 1);
  }), 1)], 1)])]), _c('div', {
    staticClass: "dialer-soundSetting-device dialer-volumeSetting-device"
  }, [_c('div', {
    staticClass: "dialer-soundSetting-device-inner"
  }, [_c('span', {
    staticClass: "dialer-soundSetting-device-name"
  }, [_vm._v("Ringing Device")]), _c('div', {
    staticClass: "dialer-dropdown dialer-soundSetting-device-dropdown"
  }, [_c('b-dropdown', {
    attrs: {
      "id": "sound-setting-micro-devices",
      "text": "Select Device"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.media_devices_setting.selectedRingingSpeakerDevice, 'label', 'Select Device')) + " ")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.media_devices_setting.audiooutputs, function (device) {
    return _c('b-dropdown-item', {
      key: device.deviceId,
      on: {
        "click": function ($event) {
          _vm.media_devices_setting.ringingSpeakerId = device.deviceId;
        }
      }
    }, [_vm.media_devices_setting.ringing_speaker_id == device.deviceId ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(device.label))])], 1);
  }), 1)], 1)]), _c('input', {
    attrs: {
      "min": "0",
      "max": "100",
      "type": "range",
      "step": "1"
    },
    domProps: {
      "value": _vm.media_devices_setting.ringing_speaker_volume
    },
    on: {
      "change": function ($event) {
        _vm.media_devices_setting.ringingSpeakerVolume = $event.target.value;
      }
    }
  }), _c('audio', {
    attrs: {
      "show": false,
      "id": "ringing-testing-audio",
      "loop": "",
      "src": require(`@/assets/sounds/${_vm.media_devices_setting.ringtone}`)
    }
  })]), _c('div', {
    staticClass: "dialer-soundSetting-testing"
  }, [_c('div', {
    staticClass: "dialer-soundSetting-testing-audio",
    on: {
      "click": function ($event) {
        _vm.$parent.$parent.conditions.clicked = true;
        _vm.ringing_is_play = !_vm.ringing_is_play;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "id": "toggle-play",
      "name": !_vm.ringing_is_play ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead',
      "width": "16",
      "stroke": "",
      "height": "18.035",
      "viewBox": "0 0 16 18.035",
      "fill": "none",
      "stroke-width": "0",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-soundSetting-testing-audio-text"
  }, [_vm._v(" Test audio ")])], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }