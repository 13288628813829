var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.design === 0 ? _c('div', {
    staticClass: "dialer-table no-data"
  }, [_vm.text ? _c('span', {
    staticClass: "dialer-no-table-data"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-noRecord-icon",
      "width": "187",
      "height": "193"
    }
  }), _c('span', {
    staticClass: "lg-mar-top",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })], 1) : _vm._e(), _vm._t("content"), _vm._t("loading")], 2) : _vm.design === 1 ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_vm.text ? _c('span', {
    staticClass: "dialer-no-table-data"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-noRecord-icon",
      "width": "187",
      "height": "193"
    }
  }), _c('span', {
    staticClass: "lg-mar-top",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })], 1) : _vm._e(), _vm._t("content"), _vm._t("loading")], 2) : _vm.design === 2 ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center p-4 text-center member-area"
  }, [_vm.text ? _c('span', {
    staticClass: "dialer-no-table-data"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-noRecord-icon",
      "width": "187",
      "height": "193"
    }
  }), _c('span', {
    staticClass: "lg-mar-top",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })], 1) : _vm._e(), _vm._t("content"), _vm._t("loading")], 2) : _vm.design === 3 ? _c('div', {
    staticClass: "dialer-table no-data text-center w-100 tun"
  }, [_vm.text ? _c('span', {
    staticClass: "dialer-no-table-data flex-column"
  }, [_vm.$store.getters.getIsMobile && 0 === 1 ? _c('img', {
    staticClass: "noDataImage_v2_mobile",
    attrs: {
      "src": _vm.noData_v2,
      "alt": "No Data"
    }
  }) : _vm._e(), _vm.$store.getters.getIsMobile && 0 === 1 ? _c('img', {
    staticClass: "noDataImage_v2_mobile",
    attrs: {
      "src": _vm.noData_v3,
      "alt": "No Data"
    }
  }) : _vm._e(), _c('vb-icon', {
    attrs: {
      "icon": "noDataFound-v2",
      "width": "57",
      "height": "63"
    }
  }), _c('span', {
    staticClass: "mt-3 text-transform-normal",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })], 1) : _vm._e(), _vm._t("content"), _vm._t("loading")], 2) : _c('div', [_vm.text ? _c('span', {
    staticClass: "dialer-no-table-data"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-noRecord-icon",
      "width": "187",
      "height": "193"
    }
  }), _c('span', {
    staticClass: "lg-mar-top",
    domProps: {
      "innerHTML": _vm._s(_vm.text)
    }
  })], 1) : _vm._e(), _vm._t("content"), _vm._t("loading")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }