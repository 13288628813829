<template>
  <div class="dialer-callListing-area ml-auto mr-2 mb-2">
    <div class="dialer-dropdown sm-mar-left whiteBGinputWithGreyRoundedBorder dropdownVersion duringCallDropdown" v-if="totalCalls.length>1">
      <b-dropdown class="m-2 w-350px  during-video-call" dropleft no-caret> 
        <template #button-content> {{ `${totalCalls.length} Calls` }} <b-icon-chevron-down class="sm-mar-left" />
        </template>
        <h6 class="m-0 pt-2">Switch Call</h6>
        <b-dropdown-item v-for="call in totalCalls" :key="`${call.room || call.uuid}-nested`" @click="playCall(call)" > 
          
          <Item :activeCall="activeCall" :data="call" :is_sip="call.is_sip" />
        </b-dropdown-item>
        <vb-no-record v-if="isEmpty(totalCalls)" text="No calls on Hold" :design="3" />
      </b-dropdown>
    </div>
  </div>
</template>

<script>
import { mapActions } from 'vuex'
// import { events } from '../../../utils'
import Item from './Item.vue'
export default {
  name: 'SwitchCall',
  props: {
    activeCall: {
      type: Object,
    },
  },
  components: {
    Item,
  },
  computed: {
    sipsessions() {
      return this.$store.state.sip.phone.getSessions ? this.$store.state.sip.phone.getSessions() : []
    },
    totalCalls(){
      const videoCalls = this.$store.state.calls.videoCalls
      const sipCalls = this.sipsessions.filter(session=>session.callState === 'incall' || (session.callState === 'pending' && session.direction == 'out'))
      return [...videoCalls,...sipCalls]
    },
  },
  inject:['isEmpty'],
  methods: {
    ...mapActions([
      'playCall',
    ]),
    // play(data){
    //   console.log('data',data)
    //   const is_sip = !!data.is_sip
    //   const id = is_sip ? data.uuid : data.room
    //   if(this.$store.state.calls.active_call==id) return;
    //   if(data.uuid){
    //     data.hold()
    //     this.$store.state.sip.phone.markSessionActive(data.uuid)
    //     this.$store.state.calls.active_call=data.uuid
    //     this.$root.$emit(events.hold_video_call)
    //   } else {
    //     data.isPlay = true;
    //     data.isShown = true;
    //     this.$store.state.calls.active_call=data.room
    //     this.$root.$emit(events.hold_audio_call)
    //   } 
    // },
  },
}
</script>

<style>

</style>