var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "sec-common"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('button', {
    staticClass: "btn btn-primarymike space4btn-when-scroll-activate",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('HRMAddNewAssetModal');
      }
    }
  }, [_c('svg', {
    staticClass: "mr-2",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.004",
      "height": "12.803",
      "viewBox": "0 0 16.004 12.803"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_awesome-tags",
      "data-name": "Icon awesome-tags",
      "d": "M12.452,5.65l-5.3-5.3A1.2,1.2,0,0,0,6.3,0H1.2A1.2,1.2,0,0,0,0,1.2V6.3a1.2,1.2,0,0,0,.352.849l5.3,5.3a1.2,1.2,0,0,0,1.7,0l5.1-5.1A1.2,1.2,0,0,0,12.452,5.65ZM2.8,4A1.2,1.2,0,1,1,4,2.8,1.2,1.2,0,0,1,2.8,4ZM15.652,7.347l-5.1,5.1a1.2,1.2,0,0,1-1.7,0l-.009-.009L13.194,8.09a2.251,2.251,0,0,0,0-3.183L8.287,0H9.505a1.2,1.2,0,0,1,.849.352l5.3,5.3a1.2,1.2,0,0,1,0,1.7Z",
      "fill": "#fff"
    }
  })]), _vm._v(" Give asset ")])]), _vm._m(1), _c('div', {
    staticClass: "mt-5 mb-4 emp-asset-table-main"
  }, [_vm._m(2), _c('div', {
    staticClass: "tab-task-bg-border"
  }, [_c('table', {
    staticClass: "asset-table-hrm"
  }, [_c('thead'), _c('tbody', [_c('tr', [_c('td', {
    staticClass: "asset-firsttd"
  }, [_vm._v(" Laptop ")]), _c('td', {
    staticClass: "asset-secondtd"
  }, [_vm._v(" Computer ")]), _c('td', {
    staticClass: "asset-thirdtd"
  }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
    staticClass: "asset-fourtd"
  }, [_vm._v(" 445566 ")]), _c('td', {
    staticClass: "asset-fivetd"
  }, [_vm._v(" 27/07/2022 ")]), _c('td', {
    staticClass: "asset-sixtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "lg",
      "variant": "link",
      "toggle-class": "text-decoration-none tb-notes-dd",
      "no-caret": "",
      "menu-class": "notes-table-dd"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "asset-firsttd"
  }, [_vm._v(" Laptop ")]), _c('td', {
    staticClass: "asset-secondtd"
  }, [_vm._v(" Computer ")]), _c('td', {
    staticClass: "asset-thirdtd"
  }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
    staticClass: "asset-fourtd"
  }, [_vm._v(" 445566 ")]), _c('td', {
    staticClass: "asset-fivetd"
  }, [_vm._v(" 27/07/2022 ")]), _c('td', {
    staticClass: "asset-sixtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-dropdown', {
    attrs: {
      "size": "lg",
      "variant": "link",
      "toggle-class": "text-decoration-none tb-notes-dd",
      "no-caret": "",
      "menu-class": "notes-table-dd"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])])])]), _c('vb-table', {
    staticClass: "assettablewidth",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth asset-firsttd col-left"
  }, [_vm._v("Name")]), _c('h5', {
    staticClass: "commonth asset-secondtd"
  }, [_vm._v("Category")]), _c('h5', {
    staticClass: "commonth asset-thirdtd"
  }, [_vm._v("Description")]), _c('h5', {
    staticClass: "commonth asset-fourtd"
  }, [_vm._v("Serial Number")]), _c('h5', {
    staticClass: "commonth asset-fivetd"
  }, [_vm._v("Date")]), _c('h5', {
    staticClass: "commonth asset-sixtd col-right"
  }, [_vm._v("Action")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "asset-firsttd"
  }, [_vm._v(" Laptop ")]), _c('td', {
    staticClass: "asset-secondtd"
  }, [_vm._v(" Computer ")]), _c('td', {
    staticClass: "asset-thirdtd"
  }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
    staticClass: "asset-fourtd"
  }, [_vm._v(" 445566 ")]), _c('td', {
    staticClass: "asset-fivetd"
  }, [_vm._v(" 27/07/2022 ")]), _c('td', {
    staticClass: "asset-sixtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "asset-firsttd"
  }, [_vm._v(" Laptop ")]), _c('td', {
    staticClass: "asset-secondtd"
  }, [_vm._v(" Computer ")]), _c('td', {
    staticClass: "asset-thirdtd"
  }, [_vm._v(" One Lenovo Laptop do not break it or I will break your legs! ")]), _c('td', {
    staticClass: "asset-fourtd"
  }, [_vm._v(" 445566 ")]), _c('td', {
    staticClass: "asset-fivetd"
  }, [_vm._v(" 27/07/2022 ")]), _c('td', {
    staticClass: "asset-sixtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])], 2)], 1)]), _c('HRMAddNewAssetModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Assets")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "notes-top-text space4text-when-scroll-activate"
  }, [_vm._v(" Here is the list of company assets currently given to Kendal Jenner ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "tb-task-upper-part"
  }, [_c('h5', {
    staticClass: "commonth asset-firsttd col-left"
  }, [_vm._v("Name")]), _c('h5', {
    staticClass: "commonth asset-secondtd"
  }, [_vm._v("Category")]), _c('h5', {
    staticClass: "commonth asset-thirdtd"
  }, [_vm._v("Description")]), _c('h5', {
    staticClass: "commonth asset-fourtd"
  }, [_vm._v("Serial Number")]), _c('h5', {
    staticClass: "commonth asset-fivetd"
  }, [_vm._v("Date")]), _c('h5', {
    staticClass: "commonth asset-sixtd col-right"
  }, [_vm._v("Action")])]);

}]

export { render, staticRenderFns }