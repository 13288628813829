<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.select_ring_strategy">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main_option">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Ring Strategy</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="data in ringStartegyOptions" 
                :key="data.value" 
                @click="
                  forms.main_options.strategy=data.value;
                  updateMenuOptions();
                  conditions.screen = screens.main_option;
                "
              >
                <div class="headingInsideTable">{{ data.text }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.main_options.strategy==data.value" width="16.642" height="15.275" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_music_on_hold">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main_option">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Music On Hold Class</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="data in musicOnHoldClassOptions" 
                :key="data.value" 
                @click="
                  forms.main_options.musiconhold=data.value;
                  updateMenuOptions();
                  conditions.screen = screens.main_option;
                "
              >
                <div class="headingInsideTable">{{ data.text }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.main_options.musiconhold==data.value" width="16.642" height="15.275" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_join_empty">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main_option">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Join Empty</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="data in joinEmptyOptions" 
                :key="data.value" 
                @click="
                  forms.main_options.joinempty=data.value;
                  updateMenuOptions();
                  conditions.screen = screens.main_option;
                "
              >
                <div class="headingInsideTable">{{ data.text }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.main_options.joinempty==data.value" width="16.642" height="15.275" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_leave_empty">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main_option">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Leave an Empty</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="data in leaveAnEmptyOptions" 
                :key="data.value" 
                @click="
                  forms.main_options.leavewhenempty=data.value;
                  updateMenuOptions();
                  conditions.screen = screens.main_option;
                "
              >
                <div class="headingInsideTable">{{ data.text }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.main_options.leavewhenempty==data.value" width="16.642" height="15.275" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_announce_position">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.advance_option">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Announce Position</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="data in announcePositionOptions" 
                :key="data.value" 
                @click="
                  forms.advance_options.announce_position=data.value;
                  updateAdvanceOptions();
                  conditions.screen = screens.advance_option;
                "
              >
                <div class="headingInsideTable">{{ data.text }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.advance_options.announce_position==data.value" width="16.642" height="15.275" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_announce_hold_time">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.agent_option">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Announce Hold time</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="data in announceHoldTimeOptions" 
                :key="data.value" 
                @click="
                  forms.agent_options.announce_holdtime=data.value;
                  updateAgentOptions();
                  conditions.screen = screens.agent_option;
                "
              >
                <div class="headingInsideTable">{{ data.text }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.agent_options.announce_holdtime==data.value" width="16.642" height="15.275" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_announce_round_seconds">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.agent_option">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Announce Round Seconds</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="data in announceRoundSecondsOptions" 
                :key="data.value" 
                @click="
                  forms.agent_options.announce_round_seconds=data.value;
                  updateAgentOptions();
                  conditions.screen = screens.agent_option;
                "
              >
                <div class="headingInsideTable">{{ data.text }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.agent_options.announce_round_seconds==data.value" width="16.642" height="15.275" />
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.main_option">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Main Options</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
            <b-alert class="mt-32px" :show="!!api.main_options.error_message" variant="danger">{{api.main_options.error_message}}</b-alert>
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS progressBarInput">
                  <label>Select Retry {{ forms.main_options.retry }}</label>
                  <input 
                    :disabled="api.main_options.send" 
                    v-model="forms.main_options.retry" 
                    class="w-100" 
                    type="range" 
                    min="5" 
                    max="180"
                    @change="updateMenuOptions" 
                  />
                  <p 
                    v-if="(forms.main_options.submitted && $v.forms.main_options.retry.$invalid) || api.main_options.validation_errors.retry"
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.main_options.retry.required">*Required</span>
                    <span v-for="em in api.main_options.validation_errors.retry" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item flex-column" @click="api.main_options.send?'':conditions.screen=screens.select_ring_strategy">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="headingInsideCard">Ring Strategy</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forms.main_options.strategy }}</div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
                <p 
                  v-if="(forms.main_options.submitted && $v.forms.main_options.strategy.$invalid) || api.main_options.validation_errors.strategy"
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.main_options.strategy.required">*Required</span>
                  <span v-for="em in api.main_options.validation_errors.strategy" :key="em">* {{ em }}</span>
                </p>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS progressBarInput">
                  <label>Select Wrap Up {{ forms.main_options.wrapuptime }}</label>
                  <input 
                    :disabled="api.main_options.send" 
                    v-model="forms.main_options.wrapuptime" 
                    class="w-100" 
                    type="range" 
                    min="0" 
                    max="180" 
                    @change="updateMenuOptions"
                  />
                  <p 
                    v-if="(forms.main_options.submitted && $v.forms.main_options.wrapuptime.$invalid) || api.main_options.validation_errors.wrapuptime"
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.main_options.wrapuptime.required">*Required</span>
                    <span v-for="em in api.main_options.validation_errors.wrapuptime" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item flex-column" @click="api.main_options.send?'':conditions.screen=screens.select_music_on_hold">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="headingInsideCard">Music On Hold Class</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forms.main_options.musiconhold }}</div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
                <p 
                  v-if="(forms.main_options.submitted && $v.forms.main_options.musiconhold.$invalid) || api.main_options.validation_errors.musiconhold"
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.main_options.musiconhold.required">*Required</span>
                  <span v-for="em in api.main_options.validation_errors.musiconhold" :key="em">* {{ em }}</span>
                </p>
              </div>
              <div class="basicWhiteIOScard-item flex-column" @click="api.main_options.send?'':conditions.screen=screens.select_join_empty">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="headingInsideCard">Join Empty</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forms.main_options.joinempty }}</div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
                <p 
                  v-if="(forms.main_options.submitted && $v.forms.main_options.joinempty.$invalid) || api.main_options.validation_errors.joinempty"
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.main_options.joinempty.required">*Required</span>
                  <span v-for="em in api.main_options.validation_errors.joinempty" :key="em">* {{ em }}</span>
                </p>
              </div>
              <div class="basicWhiteIOScard-item flex-column" @click="api.main_options.send?'':conditions.screen=screens.select_leave_empty">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="headingInsideCard">Leave an Empty</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forms.main_options.leavewhenempty }}</div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
                <p 
                  v-if="(forms.main_options.submitted && $v.forms.main_options.leavewhenempty.$invalid) || api.main_options.validation_errors.leavewhenempty"
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.main_options.leavewhenempty.required">*Required</span>
                  <span v-for="em in api.main_options.validation_errors.leavewhenempty" :key="em">* {{ em }}</span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.advance_option">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Advance Options</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
            <b-alert class="mt-32px" :show="!!api.advance_options.error_message" variant="danger">{{api.advance_options.error_message}}</b-alert>
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Call Recording</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.monitor_join"
                    @change="updateAdvanceOptions"
                  />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Ring in Use</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.ringinuse"
                    @change="updateAdvanceOptions"
                  />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Announce to First User</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.announce_to_first_user" 
                    @change="updateAdvanceOptions"
                  />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Relative Periodic Announce</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.relative_periodic_announce" 
                    @change="updateAdvanceOptions"
                  />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Random Periodic Announce</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.random_periodic_announce" 
                    @change="updateAdvanceOptions"
                  />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Auto Pause</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.autopause" 
                    @change="updateAdvanceOptions"
                  />
                </div>
              </div>
              <template v-if="forms.advance_options.autopause">
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideCard">Auto Pause Busy</div>
                  <div class="position-relative">
                    <b-form-checkbox 
                      class="dialer-switch IosSwitch" 
                      name="check-button"
                      switch
                      :disabled="api.advance_options.send" 
                      v-model="forms.advance_options.autopausebusy"
                      @change="updateAdvanceOptions"
                    />
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideCard">Auto Pause Unavailable</div>
                  <div class="position-relative">
                    <b-form-checkbox 
                      class="dialer-switch IosSwitch" 
                      name="check-button"
                      switch
                      :disabled="api.advance_options.send" 
                      v-model="forms.advance_options.autopauseunavail"
                      @change="updateAdvanceOptions"
                    />
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS progressBarInput">
                    <label>Auto Pause Delay {{ forms.advance_options.autopausedelay }}</label>
                    <input 
                      :disabled="api.advance_options.send" 
                      v-model="forms.advance_options.autopausedelay" 
                      @change="updateAdvanceOptions" 
                      class="w-100" 
                      type="range" 
                      min="0" 
                      max="180" 
                    />
                    <p 
                      v-if="(forms.advance_options.submitted && $v.forms.advance_options.autopausedelay.$invalid) || api.advance_options.validation_errors.autopausedelay"
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.advance_options.autopausedelay.required">*Required</span>
                      <span v-for="em in api.advance_options.validation_errors.autopausedelay" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
              </template>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Timeout Restart</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.timeoutrestart"
                    @change="updateAdvanceOptions"
                  />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS progressBarInput">
                  <label>Select Max Length {{ forms.advance_options.maxlen }}</label>
                  <input 
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.maxlen" 
                    @change="updateAdvanceOptions" 
                    class="w-100" 
                    type="range" 
                    min="0" 
                    max="180" 
                  />
                  <p 
                    v-if="(forms.advance_options.submitted && $v.forms.advance_options.maxlen.$invalid) || api.advance_options.validation_errors.maxlen"
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.advance_options.maxlen.required">*Required</span>
                    <span v-for="em in api.advance_options.validation_errors.maxlen" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS progressBarInput">
                  <label>Select Weight {{ forms.advance_options.weight }}</label>
                  <input 
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.weight" 
                    @change="updateAdvanceOptions" 
                    class="w-100" 
                    type="range" 
                    min="0" 
                    max="180" 
                  />
                  <p 
                    v-if="(forms.advance_options.submitted && $v.forms.advance_options.weight.$invalid) || api.advance_options.validation_errors.weight"
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.advance_options.weight.required">*Required</span>
                    <span v-for="em in api.advance_options.validation_errors.weight" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS progressBarInput">
                  <label>Select Time out {{ forms.advance_options.timeout }}</label>
                  <input 
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.timeout" 
                    @change="updateAdvanceOptions" 
                    class="w-100" 
                    type="range" 
                    min="0" 
                    max="180" 
                  />
                  <p 
                    v-if="(forms.advance_options.submitted && $v.forms.advance_options.timeout.$invalid) || api.advance_options.validation_errors.timeout"
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.advance_options.timeout.required">*Required</span>
                    <span v-for="em in api.advance_options.validation_errors.timeout" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item flex-column" @click="api.advance_options.send?'':conditions.screen=screens.select_announce_position">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="headingInsideCard">Select Announce Position</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forms.advance_options.announce_position }}</div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
                <p 
                  v-if="api.advance_options.submitted && forms.advance_options.announce_position.$invalid"
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.advance_options.announce_position.required">*Required</span>
                </p>
              </div>
              <div v-if="['limit','more'].includes(forms.advance_options.announce_position)" class="basicWhiteIOScard-item">
                <div class="mobileInputIOS progressBarInput">
                  <label>Announce Position Limit {{ forms.advance_options.announce_position_limit }}</label>
                  <input 
                    :disabled="api.advance_options.send" 
                    v-model="forms.advance_options.announce_position_limit" 
                    @change="updateAdvanceOptions" 
                    class="w-100" 
                    type="range" 
                    min="0" 
                    max="180" 
                  />
                  <p 
                    v-if="(forms.advance_options.submitted && $v.forms.advance_options.announce_position_limit.$invalid) || 
                      api.advance_options.validation_errors.announce_position_limit
                    "
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.advance_options.announce_position_limit.required">*Required</span>
                    <span v-for="em in api.advance_options.validation_errors.announce_position_limit" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.agent_option">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Agent Options</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
            <b-alert class="mt-32px" :show="!!api.agent_options.error_message" variant="danger">{{api.agent_options.error_message}}</b-alert>
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Report Hold Time</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    :disabled="api.agent_options.send" 
                    v-model="forms.agent_options.reportholdtime" 
                    @change="updateAgentOptions"
                  />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS progressBarInput">
                  <label>Select Members Delay {{ forms.agent_options.memberdelay }}</label>
                  <input 
                    :disabled="api.agent_options.send" 
                    v-model="forms.agent_options.memberdelay" 
                    @change="updateAgentOptions" 
                    class="w-100" 
                    type="range" 
                    min="0" 
                    max="180" 
                  />
                  <p 
                    v-if="(api.agent_options.submitted && forms.agent_options.memberdelay.$invalid) || api.agent_options.validation_errors.memberdelay"
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.agent_options.memberdelay.required">*Required</span>
                    <span v-for="em in api.agent_options.validation_errors.memberdelay" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item flex-column" @click="api.agent_options.send?'':conditions.screen=screens.select_announce_hold_time">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="headingInsideCard">Select Announce Hold time</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forms.agent_options.announce_holdtime }}</div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
                <p 
                  v-if="(api.agent_options.submitted && forms.agent_options.announce_holdtime.$invalid) || api.agent_options.validation_errors.announce_holdtime"
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.agent_options.announce_holdtime.required">*Required</span>
                  <span v-for="em in api.agent_options.validation_errors.announce_holdtime" :key="em">* {{ em }}</span>
                </p>
              </div>
              <div class="basicWhiteIOScard-item flex-column" @click="api.agent_options.send?'':conditions.screen=screens.select_announce_round_seconds">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <div class="headingInsideCard">Select Announce Round Seconds</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forms.agent_options.announce_round_seconds }}</div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
                <p 
                  v-if="(api.agent_options.submitted && forms.agent_options.announce_round_seconds.$invalid) || 
                    api.agent_options.validation_errors.announce_round_seconds
                  "
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.agent_options.announce_round_seconds.required">*Required</span>
                  <span v-for="em in api.agent_options.validation_errors.announce_round_seconds" :key="em">* {{ em }}</span>
                </p>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.announcement_option">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Announcement Options</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
            <b-alert class="mt-32px" :show="!!api.announcement_options.error_message" variant="danger">{{api.announcement_options.error_message}}</b-alert>
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS progressBarInput">
                  <label>Select Priodic Frequency {{ forms.announcement_options.periodic_announce_frequency }}</label>
                  <input 
                    :disabled="api.announcement_options.send" 
                    v-model="forms.announcement_options.periodic_announce_frequency" 
                    @change="updateAnnouncementOptions" 
                    class="w-100" 
                    type="range" 
                    min="0" 
                    max="180" 
                  />
                  <p 
                    v-if="(api.announcement_options.submitted && forms.announcement_options.periodic_announce_frequency.$invalid) || 
                      api.announcement_options.validation_errors.periodic_announce_frequency
                    "
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.announcement_options.periodic_announce_frequency.required">*Required</span>
                    <span v-for="em in api.announcement_options.validation_errors.periodic_announce_frequency" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS progressBarInput">
                  <label>Select Announce Frequency {{ forms.announcement_options.announce_frequency }}</label>
                  <input 
                    :disabled="api.announcement_options.send" 
                    v-model="forms.announcement_options.announce_frequency" 
                    @change="updateAnnouncementOptions" 
                    class="w-100" 
                    type="range" 
                    min="0" 
                    max="180" 
                  />
                  <p 
                    v-if="(api.announcement_options.submitted && forms.announcement_options.announce_frequency.$invalid) || 
                      api.announcement_options.validation_errors.announce_frequency
                    "
                  >
                    <span v-if="!$v.forms.announcement_options.announce_frequency.required">*Required</span>
                    <span v-for="em in api.announcement_options.validation_errors.announce_frequency" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS progressBarInput">
                  <label>Select Minimum Announce Frequecy {{ forms.announcement_options.min_announce_frequency }}</label>
                  <input 
                    :disabled="api.announcement_options.send" 
                    v-model="forms.announcement_options.min_announce_frequency" 
                    @change="updateAnnouncementOptions" 
                    class="w-100" 
                    type="range" 
                    min="0" 
                    max="180" 
                  />
                  <p 
                    v-if="(api.announcement_options.submitted && forms.announcement_options.min_announce_frequency.$invalid) || 
                      api.announcement_options.validation_errors.min_announce_frequency
                    "
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.announcement_options.min_announce_frequency.required">*Required</span>
                    <span v-for="em in api.announcement_options.validation_errors.min_announce_frequency" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div 
                class="basicWhiteIOScard-item" 
                @click="$modal.show('SelectSoundFile', { 
                  type: 'queue_reporthold', 
                  value: forms.announcement_options.queue_reporthold.value 
                })"
              >
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Queue Report Hold</div>
                </div>
                <div class="d-flex align-items-center">
                  <vb-audio-player 
                    v-if="forms.announcement_options.queue_reporthold.file" 
                    class="mr-6px IosAudioPlayer" 
                    :src="forms.announcement_options.queue_reporthold.file" 
                  />
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div 
                class="basicWhiteIOScard-item" 
                @click="$modal.show('SelectSoundFile', { 
                  type: 'queue_thankyou', 
                  value: forms.announcement_options.queue_thankyou.value
                })"
              >
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Queue Thank you</div>
                </div>
                <div class="d-flex align-items-center">
                  <vb-audio-player 
                    v-if="forms.announcement_options.queue_thankyou.file" 
                    class="mr-6px IosAudioPlayer" 
                    :src="forms.announcement_options.queue_thankyou.file" 
                  />
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div 
                class="basicWhiteIOScard-item" 
                @click="$modal.show('SelectSoundFile', { 
                  type: 'queue_holdtime', 
                  value: forms.announcement_options.queue_holdtime.value
                })"
              >
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Queue Hold Time</div>
                </div>
                <div class="d-flex align-items-center">
                  <vb-audio-player 
                    v-if="forms.announcement_options.queue_holdtime.file" 
                    class="mr-6px IosAudioPlayer" 
                    :src="forms.announcement_options.queue_holdtime.file" 
                  />
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div 
                class="basicWhiteIOScard-item" 
                @click="$modal.show('SelectSoundFile', { 
                  type: 'queue_callswaiting', 
                  value: forms.announcement_options.queue_callswaiting.value
                })"
              >
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Queue Calls Waiting</div>
                </div>
                <div class="d-flex align-items-center">
                  <vb-audio-player 
                    v-if="forms.announcement_options.queue_callswaiting.file" 
                    class="mr-6px IosAudioPlayer" 
                    :src="forms.announcement_options.queue_callswaiting.file" 
                  />
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div 
                class="basicWhiteIOScard-item" 
                @click="$modal.show('SelectSoundFile', { 
                  type: 'queue_thereare', 
                  value: forms.announcement_options.queue_thereare.value
                })"
              >
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Queue There Are</div>
                </div>
                <div class="d-flex align-items-center">
                  <vb-audio-player 
                    v-if="forms.announcement_options.queue_thereare.file" 
                    class="mr-6px IosAudioPlayer" 
                    :src="forms.announcement_options.queue_thereare.file" 
                  />
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div 
                class="basicWhiteIOScard-item" 
                @click="$modal.show('SelectSoundFile', { 
                  type: 'queue_youarenext', 
                  value: forms.announcement_options.queue_youarenext.value
                })"
              >
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Queue You Are Next</div>
                </div>
                <div class="d-flex align-items-center">
                  <vb-audio-player 
                    v-if="forms.announcement_options.queue_youarenext.file" 
                    class="mr-6px IosAudioPlayer" 
                    :src="forms.announcement_options.queue_youarenext.file" 
                  />
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div 
                class="basicWhiteIOScard-item" 
                @click="$modal.show('SelectSoundFile', { 
                  type: 'queue_callerannounce', 
                  value: forms.announcement_options.queue_callerannounce.value
                })"
              >
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Queue Caller Announce</div>
                </div>
                <div class="d-flex align-items-center">
                  <vb-audio-player 
                    v-if="forms.announcement_options.queue_callerannounce.file" 
                    class="mr-6px IosAudioPlayer" 
                    :src="forms.announcement_options.queue_callerannounce.file" 
                  />
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.edit">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Add Call Queue</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
              <b-alert class="mt-32px" :show="!!api.update_label.error_message" variant="danger">{{api.update_label.error_message}}</b-alert>
              <div class="basicWhiteIOScard mt-32px mb-16px">
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Name</label>
                    <input 
                      placeholder="Enter Name" 
                      class="w-100" 
                      type="text" 
                      :disabled="api.update_label.send" 
                      v-model="forms.update_label.label" 
                      @input="updateLabel"
                    />
                    <p 
                      v-if="(forms.update_label.submitted && $v.forms.update_label.label.$invalid) || api.update_label.validation_errors.label" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_label.label.required">* Name is required</span>
                      <span v-for="em in api.update_label.validation_errors.label" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Call Queue</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="profile-image-section-container forIos mt-32px">
              <div 
                class="profile-image-section"
                @click="$modal.show('CallQueueProfileImageUploader',{ 
                  type: 'CALL_QUEUE', 
                  accountcode: callQueueAccountcode, 
                  current_image: data.profile_image 
                })"
              >
                <div class="profile-img">
                  <img 
                    style="height: 100%; width: 100%" 
                    id="user-profile-image-updated" 
                    :src="data.profile_image" 
                    @error="$event.target.src=local_filess.images.user_male" 
                    :alt="data.call_queue.label" 
                  />
                </div>
              </div>
            </div>
            <div class="BiggestMainHeading mt-22px">{{ data.call_queue.label }}</div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item" @click="$modal.show('EditCallQueueAssignNumberModal', { accountcode: callQueueAccountcode, })">
                <div class="headingInsideTable">Call Queue Numbers</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ callQueue | get_property('userDid.length',0) }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div class="basicWhiteIOScard-item" @click="$modal.show('EditCallQueueMembersModal1', { accountcode: callQueueAccountcode, })">
                <div class="headingInsideTable">Call Queue Members</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ callQueue | get_property('members.length',0) }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
            </div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.main_option">
                <div class="headingInsideTable">Main Options</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.advance_option">
                <div class="headingInsideTable">Advance Options</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.agent_option">
                <div class="headingInsideTable">Agent Options</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.announcement_option">
                <div class="headingInsideTable">Announcement Options</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>

          </div>
        </div>
      </template>
      <ProfileImageUploaderModal :modalName="'CallQueueProfileImageUploader'" @image-uploaded="data.profile_image=$event;getCallQueue()" />
      <SelectSoundFileModal @select-file="onSelectFile($event)" />
      <AssignNumbersModal :modalName="'EditCallQueueAssignNumberModal'" @added="getCallQueue();" />
      <AddRemoveUsersModal :modalName="`EditCallQueueMembersModal1`" :type="'assign_call_queue'" @update-list="getCallQueue();" />
    </modal>
  </div>
</template>

<script>
import AddRemoveUsersModal from '../users/AddRemoveUsers.vue'
import ProfileImageUploaderModal from '../common/ProfileImageUploader.vue'
import AssignNumbersModal from '../numbers/AssignNumbers.vue'
import SelectSoundFileModal from '../sounds/SelectSoundFile.vue'
import { LOGGER, VOIP_API } from "@/utils";
import { required, } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import _ from 'lodash';

let announce_positions_options = [
  {
    text: 'yes',
    value: 'yes',
  },
  {
    text: 'no',
    value: 'no',
  },
  {
    text: 'limit',
    value: 'limit',
  },
  {
    text: 'more',
    value: 'more',
  },
]
let join_empty = [
  {
    text: 'loose',
    value: 'loose',
  },
  {
    text: 'no',
    value: 'no',
  },
  {
    text: 'strict',
    value: 'strict',
  },
  {
    text: 'yes',
    value: 'yes',
  },
]
let tabs = {
  main_options: {
    id: 1,
    text: "Main Options",
  },
  advance_options: {
    id: 2,
    text: "Advance Options",
  },
  agent_options: {
    id: 3,
    text: "Agent Options",
  },
  announcement_options: {
    id: 4,
    text: "Announcement Options",
  },
  call_queue_members: {
    id: 5,
    text: "Call Queue Members",
  },
  call_queue_numbers: {
    id: 6,
    text: "Call Queue Numbers",
  },
}
let screens = {
  view: 'view',
  edit: 'edit',
  main_option: 'main_option',
  advance_option: 'advance_option',
  agent_option: 'agent_option',
  announcement_option: 'announcement_option',
  select_ring_strategy: 'select_ring_strategy',
  select_music_on_hold: 'select_music_on_hold',
  select_join_empty: 'select_join_empty',
  select_leave_empty: 'select_leave_empty',
  select_announce_position: 'select_announce_position',
  select_announce_hold_time: 'select_announce_hold_time',
  select_announce_round_seconds: 'select_announce_round_seconds',
}
export default {
  name: "EditCallQueueModal",
  components: {
    ProfileImageUploaderModal,
    SelectSoundFileModal,
    AssignNumbersModal,
    AddRemoveUsersModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'EditCallQueueModal',
    },
  },
  inject: ['local_filess','getCallQueue','appNotify'],
  data() {
    return {
      forms: {
        update_label: this.$helperFunction.formInstance({
          data: {
            label: '',
          }
        }),
        main_options: this.$helperFunction.formInstance({
          data: {
            retry: '0',
            wrapuptime: '0',
            strategy: '',
            musiconhold: '',
            joinempty: '',
            leavewhenempty: '',
          }
        }),
        advance_options: this.$helperFunction.formInstance({
          data: {
            maxlen: '0',
            weight: '0',
            monitor_join: false,
            ringinuse: false,
            timeout: '0',
            timeoutrestart: false,
            announce_to_first_user: false,
            relative_periodic_announce: false,
            random_periodic_announce: false,
            announce_position: '',
            announce_position_limit: '0',
            autopause: false,
            autopausedelay: '0',
            autopausebusy: false,
          }
        }),
        agent_options: this.$helperFunction.formInstance({
          data: {
            memberdelay: '0',
            announce_holdtime: '',
            announce_round_seconds: '',
            // timeoutrestart: false,
            reportholdtime: false,
          }
        }),
        announcement_options: this.$helperFunction.formInstance({
          data: {
            periodic_announce_frequency: '0',
            announce_frequency: '0',
            min_announce_frequency: '0',
            queue_reporthold: {
              value: '',
              file: '',
            },
            queue_thankyou: {
              value: '',
              file: '',
            },
            queue_holdtime: {
              value: '',
              file: '',
            },
            queue_callswaiting: {
              value: '',
              file: '',
            },
            queue_thereare: {
              value: '',
              file: '',
            },
            queue_youarenext: {
              value: '',
              file: '',
            },
            queue_callerannounce: {
              value: '',
              file: '',
            },
          }
        }),
        reset(){
          this.update_label.reset()
          this.main_options.reset()
          this.advance_options.reset();
          this.agent_options.reset();
          this.announcement_options.reset();
        },
      },
      api: {
        detail: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
        advance_options: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, source: true }),
        agent_options: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, source: true }),
        announcement_options: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
        main_options: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, source: true }),
        update_label: this.$helperFunction.apiInstance({ validation_errors: true, }),
        reset(){
          this.detail?.reset?.()
          this.advance_options?.reset?.()
          this.agent_options?.reset?.()
          this.announcement_options?.reset?.()
          this.main_options?.reset?.()
          this.update_label?.reset?.()
        },
      },
      response: this.$helperFunction.stateInstance({
        data: {
          call_queue: {},
        },
      }),
      data: this.$helperFunction.stateInstance({
        data: {
          accountcode: '',
          call_queue: {},
          profile_image: '',
        },
      }),
      selected: this.$helperFunction.stateInstance({
        data: {
          tab: tabs.main_options.id,
        },
      }),
      conditions: this.$helperFunction.stateInstance({
        data: {
          screen: screens.view,
        },
      }),
    };
  },
  computed: {
    ...mapGetters([
      'getVoipCallQueues'
    ]),

    screens(){ return screens },

    callQueue(){ return this.getVoipCallQueues?.find?.(call_queue=>call_queue.voipaccount==this.data.accountcode) ?? {} },
    
    tabs(){ return tabs },
    soundFiles(){ return Object.values(this.response.call_queue?.soundFiles ?? {}) ?? [] },
    callQueueAccountcode(){ return this.data?.call_queue?.voipaccount },

    announcePositionOptions(){ return announce_positions_options },

    announceHoldTimeOptions(){ 
      return Object.keys(this.response.call_queue?.announceholdtime ?? {}).map(i=>{ return { text: this.response.call_queue.announceholdtime[i], value: i } }) 
    },
    announceRoundSecondsOptions(){ 
      return Object.keys(this.response.call_queue?.announceroundsecond ?? {}).map(i=>{ return { text: this.response.call_queue.announceroundsecond[i], value: i } }) 
    },

    periodicFrequencyOptions(){ 
      return Object.keys(this.response.call_queue?.announceroundsecond ?? {}).map(i=>{ return { text: this.response.call_queue.announceroundsecond[i], value: i } }) 
    },
    announceFrequencyOptions(){ 
      return Object.keys(this.response.call_queue?.announceroundsecond ?? {}).map(i=>{ return { text: this.response.call_queue.announceroundsecond[i], value: i } }) 
    },
    minimumAnnounceFrequencyOptions(){ 
      return Object.keys(this.response.call_queue?.announceroundsecond ?? {}).map(i=>{ return { text: this.response.call_queue.announceroundsecond[i], value: i } }) 
    },

    ringStartegyOptions(){ 
      return Object.keys(this.response.call_queue?.ringstrategy ?? {}).map(i=>{ return { text: this.response.call_queue.ringstrategy[i], value: i } }) 
    },
    musicOnHoldClassOptions(){ return Object.keys(this.response.call_queue?.playlist ?? {}).map(i=>{ return { text: this.response.call_queue.playlist[i], value: i } }) },
    joinEmptyOptions(){ return join_empty },
    leaveAnEmptyOptions(){ return Object.keys(this.response.call_queue?.leaveEmpty ?? {}).map(i=>{ return { text: this.response.call_queue.leaveEmpty[i], value: i } }) },
  },
  validations: {
    forms: {
      update_label: {
        label: {
          required
        },
      },
      main_options: {
        retry: {
          // required
        },
        wrapuptime: {
          // required
        },
        strategy: {
          // required
        },
        musiconhold: {
          // required
        },
        joinempty: {
          // required
        },
        leavewhenempty: {
          // required
        },
      },
      advance_options: {
        maxlen: {
          // required
        },
        weight: {
          // required
        },
        // monitor_join: false,
        // ringinuse: false,
        timeout: {
          // required
        },
        // timeoutrestart: false,
        // announce_to_first_user: false,
        // relative_periodic_announce: false,
        // random_periodic_announce: false,
        announce_position: {
          // required
        },
        announce_position_limit: {
          // required: requiredIf(function(){
          //   return ['limit','more'].includes(this.forms.advance_options.announce_position)
          // })
        },
        // autopause: false,
        autopausedelay: {
          // required: requiredIf(function(){
          //   return this.forms.advance_options.autopause
          // })
        },
        // autopausebusy: false,
      },
      agent_options: {
        memberdelay: {
          // required
        },
        announce_holdtime: {
          // required
        },
        announce_round_seconds: {
          // required
        },
        // timeoutrestart: false,
        // reportholdtime: false,
      },
      announcement_options: {
        periodic_announce_frequency: {
          // required
        },
        announce_frequency: {
          // required
        },
        min_announce_frequency: {
          // required
        },
        // queue_reporthold: {
        //   value: '',
        //   file: '',
        // },
        // queue_thankyou: {
        //   value: '',
        //   file: '',
        // },
        // queue_holdtime: {
        //   value: '',
        //   file: '',
        // },
        // queue_callswaiting: {
        //   value: '',
        //   file: '',
        // },
        // queue_thereare: {
        //   value: '',
        //   file: '',
        // },
        // queue_youarenext: {
        //   value: '',
        //   file: '',
        // },
        // queue_callerannounce: {
        //   value: '',
        //   file: '',
        // },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.call_queue=event?.params?.call_queue ?? {}
      this.data.accountcode=event?.params?.call_queue?.voipaccount ?? ''
      this.data.profile_image=event?.params?.call_queue?.avatar?.image?.image ?? ''
      this.forms.update_label.label=event?.params?.call_queue?.label ?? ''
      this.fetchDetail()
    },
    onBeforeClose(){
      this.data.reset()
      this.api.reset()
      this.response.reset()
      this.forms.reset()
      this.conditions.reset()
    },
    onSelectFile(Event){
      const data = this.forms.announcement_options[Event?.type]
      if(!data) return;
      const sound_file = Event?.file?.sound_file
      const value = sound_file.split('/')[sound_file.split('/').length-1]
      data.value = value
      data.file = sound_file
      this.updateAnnouncementOptions()
    },
    updateLabel: _.debounce(function(){
      let vm = this
      vm.forms.update_label.submitted=true
      vm.$v.forms.update_label.$touch()
      if(vm.$v.forms.update_label.$invalid) return;
      if(vm.forms.update_label.label==vm.callQueue?.label) return;
      vm.api.update_label.send=true
      vm.api.update_label.validation_errors={}
      vm.api.update_label.error_message=''
      VOIP_API.endpoints.menu.labelUpdate(vm.callQueueAccountcode,{
        label: vm.forms.update_label.label
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully updated!",
          type: "success",
        })
        vm.getCallQueue()
        vm.data.call_queue.label=vm.forms.update_label.label
      })
      .catch((ex) => {
        vm.api.update_label.validation_errors=ex.own_errors ?? {}
        vm.api.update_label.error_message=ex.own_message
      })
      .finally(() => {
        vm.forms.update_label.submitted=false
        vm.api.update_label.send=false
      });
    },2*1000),
    fetchDetail(){
      let vm = this
      vm.api.detail.send=true
      VOIP_API.endpoints.call_queues.detail(vm.callQueueAccountcode)
      .then(({ data: detail })=>{
        vm.response.call_queue=detail
        //main
        vm.forms.main_options.retry                                     = parseInt(detail?.list?.retry) > '5' ? detail?.list?.retry : '5';
        vm.forms.main_options.wrapuptime                                = detail?.list?.wrapuptime ?? '0';
        vm.forms.main_options.strategy                                  = detail?.list?.strategy ?? '';
        vm.forms.main_options.musiconhold                               = detail?.list?.musiconhold ?? '';
        vm.forms.main_options.joinempty                                 = detail?.list?.joinempty ?? '';
        vm.forms.main_options.leavewhenempty                            = detail?.list?.leavewhenempty ?? '';
        //advance
        vm.forms.advance_options.maxlen                                 = detail?.list?.maxlen ?? '0';
        vm.forms.advance_options.weight                                 = detail?.list?.weight ?? '0';
        vm.forms.advance_options.monitor_join                           = detail?.list?.monitor_join=='1' ?? false;
        vm.forms.advance_options.ringinuse                              = detail?.list?.ringinuse=='yes' ?? false;
        vm.forms.advance_options.timeout                                = detail?.list?.timeout ?? '0';
        vm.forms.advance_options.timeoutrestart                         = detail?.list?.timeoutrestart=='yes' ?? false;
        vm.forms.advance_options.announce_to_first_user                 = detail?.list?.announce_to_first_user=='yes' ?? false;
        vm.forms.advance_options.relative_periodic_announce             = detail?.list?.relative_periodic_announce=='yes' ?? false;
        vm.forms.advance_options.random_periodic_announce               = detail?.list?.random_periodic_announce=='yes' ?? false;
        vm.forms.advance_options.announce_position                      = detail?.list?.announce_position ?? '';
        vm.forms.advance_options.announce_position_limit                = detail?.list?.announce_position_limit ?? '0';
        vm.forms.advance_options.autopause                              = detail?.list?.autopause=='yes' ?? false;
        vm.forms.advance_options.autopausedelay                         = detail?.list?.autopausedelay ?? '0';
        vm.forms.advance_options.autopausebusy                          = detail?.list?.autopausebusy=='yes' ?? false;
        //agent
        vm.forms.agent_options.memberdelay                              = detail?.list?.memberdelay ?? '0';
        vm.forms.agent_options.announce_holdtime                        = detail?.list?.announce_holdtime ?? '';
        vm.forms.agent_options.announce_round_seconds                   = detail?.list?.announce_round_seconds ?? '';
        // vm.forms.agent_options.timeoutrestart                           = detail?.list?.timeoutrestart=='yes' ?? false;
        vm.forms.agent_options.reportholdtime                           = detail?.list?.reportholdtime=='yes' ?? false;
        //announcement
        vm.forms.announcement_options.periodic_announce_frequency       = detail?.list?.periodic_announce_frequency ?? '0';
        vm.forms.announcement_options.announce_frequency                = detail?.list?.announce_frequency ?? '0';
        vm.forms.announcement_options.min_announce_frequency            = detail?.list?.min_announce_frequency ?? '0';
        vm.forms.announcement_options.queue_reporthold.value            = detail?.list?.queue_reporthold ?? '';
        vm.forms.announcement_options.queue_reporthold.file             = detail?.list?.queue_reporthold_file ?? '';
        vm.forms.announcement_options.queue_thankyou.value              = detail?.list?.queue_thankyou ?? '';
        vm.forms.announcement_options.queue_thankyou.file               = detail?.list?.queue_thankyou_file ?? '';
        vm.forms.announcement_options.queue_holdtime.value              = detail?.list?.queue_holdtime ?? '';
        vm.forms.announcement_options.queue_holdtime.file               = detail?.list?.queue_holdtime_file ?? '';
        vm.forms.announcement_options.queue_callswaiting.value          = detail?.list?.queue_callswaiting ?? '';
        vm.forms.announcement_options.queue_callswaiting.file           = detail?.list?.queue_callswaiting_file ?? '';
        vm.forms.announcement_options.queue_thereare.value              = detail?.list?.queue_thereare ?? '';
        vm.forms.announcement_options.queue_thereare.file               = detail?.list?.queue_thereare_file ?? '';
        vm.forms.announcement_options.queue_youarenext.value            = detail?.list?.queue_youarenext ?? '';
        vm.forms.announcement_options.queue_youarenext.file             = detail?.list?.queue_youarenext_file ?? '';
        vm.forms.announcement_options.queue_callerannounce.value        = detail?.list?.queue_callerannounce ?? '';
        vm.forms.announcement_options.queue_callerannounce.file         = detail?.list?.queue_callerannounce_file ?? '';
      }).catch((ex)=>{
        LOGGER.danger({ex})
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.detail.send=false
      })
    },
    updateMenuOptions(){
      let vm = this
      vm.forms.main_options.submitted=true
      vm.api.main_options.source?.cancel?.("Requesting Again")
      vm.api.main_options.source = require("axios").default.CancelToken.source();
      vm.$v.forms.main_options.$touch()
      if(vm.$v.forms.main_options.$invalid) return;
      // if(vm.api.main_options.send) return;
      // vm.api.main_options.send=true
      vm.api.main_options.validation_errors={}
      // vm.api.main_options.error_message=''
      VOIP_API.endpoints.call_queues.updateMainOptions(vm.callQueueAccountcode,{
        joinempty: vm.forms.main_options.joinempty,
        leavewhenempty: vm.forms.main_options.leavewhenempty,
        musiconhold: vm.forms.main_options.musiconhold,
        retry: vm.forms.main_options.retry, 
        strategy: vm.forms.main_options.strategy,
        wrapuptime: vm.forms.main_options.wrapuptime,
      },vm.api.main_options.source.token)
      .then(()=>{
        vm.appNotify({
          message: 'successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.main_options.validation_errors=ex.own_errors
        // vm.api.main_options.error_message=ex.own_message
        vm.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.forms.main_options.submitted=false
        // vm.api.main_options.send=false
        vm.api.main_options.source=null
      })
    },
    updateAdvanceOptions(){
      let vm = this
      vm.forms.advance_options.submitted=true
      vm.api.advance_options.source?.cancel?.("Requesting Again")
      vm.api.advance_options.source = require("axios").default.CancelToken.source();
      vm.$v.forms.advance_options.$touch()
      if(vm.$v.forms.advance_options.$invalid) return;
      // if(vm.api.advance_options.send) return;
      // vm.api.advance_options.send=true
      vm.api.advance_options.validation_errors={}
      // vm.api.advance_options.error_message=''
      VOIP_API.endpoints.call_queues.updateAdvanceOptions(vm.callQueueAccountcode,{
        // monitor_format: '',
        retry: vm.forms.main_options.retry,
        maxlen: vm.forms.advance_options.maxlen,
        weight: vm.forms.advance_options.weight,
        monitor_join: vm.forms.advance_options.monitor_join?'1':'0',
        ringinuse: vm.forms.advance_options.ringinuse?'yes':'no',
        timeout: vm.forms.advance_options.timeout,
        timeoutrestart: vm.forms.advance_options.timeoutrestart?'yes':'no',
        announce_to_first_user: vm.forms.advance_options.announce_to_first_user?'yes':'no',
        relative_periodic_announce: vm.forms.advance_options.relative_periodic_announce?'yes':'no',
        random_periodic_announce: vm.forms.advance_options.random_periodic_announce?'yes':'no',
        announce_position: vm.forms.advance_options.announce_position,
        announce_position_limit: vm.forms.advance_options.announce_position_limit,
        autopause: vm.forms.advance_options.autopause?'yes':'no',
        autopausedelay: vm.forms.advance_options.autopausedelay,
        autopausebusy: vm.forms.advance_options.autopausebusy?'yes':'no',
      },vm.api.advance_options.source.token)
      .then(()=>{
        vm.appNotify({
          message: 'successfully Updated!',
          type: 'success',
        })
        
      })
      .catch((ex)=>{
        vm.api.advance_options.validation_errors=ex.own_errors
        // vm.api.advance_options.error_message=ex.own_message
        vm.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.forms.advance_options.submitted=false
        // vm.api.advance_options.send=false
        vm.api.advance_options.source=null
      })
    },
    updateAgentOptions(){
      let vm = this
      vm.forms.agent_options.submitted=true
      vm.api.agent_options.source?.cancel?.("Requesting Again")
      vm.api.agent_options.source = require("axios").default.CancelToken.source();
      vm.$v.forms.agent_options.$touch()
      if(vm.$v.forms.agent_options.$invalid) return;
      // if(vm.api.agent_options.send) return;
      // vm.api.agent_options.send=true
      vm.api.agent_options.validation_errors={}
      // vm.api.agent_options.error_message=''
      VOIP_API.endpoints.call_queues.updateAgentOptions(vm.callQueueAccountcode,{
        timeoutrestart: vm.forms.advance_options.timeoutrestart?'yes':'no',
        announce_round_seconds: vm.forms.agent_options.announce_round_seconds,
        announce_holdtime: vm.forms.agent_options.announce_holdtime,
        reportholdtime: vm.forms.agent_options.reportholdtime?'yes':'no', 
        memberdelay: vm.forms.agent_options.memberdelay,
      },vm.api.agent_options.source.token)
      .then(()=>{
        vm.appNotify({
          message: 'successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.agent_options.validation_errors=ex.own_errors
        // vm.api.agent_options.error_message=ex.own_message
        vm.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.forms.agent_options.submitted=false
        // vm.api.agent_options.send=false
        vm.api.agent_options.source=null
      })
    },
    updateAnnouncementOptions(){
      let vm = this
      vm.forms.announcement_options.submitted=true
      vm.api.announcement_options.source?.cancel?.("Requesting Again")
      vm.api.announcement_options.source = require("axios").default.CancelToken.source();
      vm.$v.forms.announcement_options.$touch()
      if(vm.$v.forms.announcement_options.$invalid) return;
      // if(vm.api.announcement_options.send) return;
      // vm.api.announcement_options.send=true
      vm.api.announcement_options.validation_errors={}
      // vm.api.announcement_options.error_message=''
      VOIP_API.endpoints.call_queues.updateAnnounceOptions(vm.callQueueAccountcode,{
        periodic_announce_frequency: vm.forms.announcement_options.periodic_announce_frequency,
        announce_frequency: vm.forms.announcement_options.announce_frequency,
        min_announce_frequency: vm.forms.announcement_options.min_announce_frequency,
        queue_reporthold: vm.forms.announcement_options.queue_reporthold.value,
        queue_thankyou: vm.forms.announcement_options.queue_thankyou.value,
        queue_holdtime: vm.forms.announcement_options.queue_holdtime.value,
        queue_callswaiting: vm.forms.announcement_options.queue_callswaiting.value,
        queue_thereare: vm.forms.announcement_options.queue_thereare.value,
        queue_youarenext: vm.forms.announcement_options.queue_youarenext.value,
        queue_callerannounce: vm.forms.announcement_options.queue_callerannounce.value,
      },vm.api.announcement_options.source.token)
      .then(()=>{
        vm.appNotify({
          message: 'successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.announcement_options.validation_errors=ex.own_errors
        // vm.api.announcement_options.error_message=ex.own_message
        vm.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.forms.announcement_options.submitted=false
        // vm.api.announcement_options.send=false
        vm.api.announcement_options.source=null
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-image-section {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto;
  overflow: hidden;
  @include border-radius(50%);
  margin-bottom: 3 * $dialer-default-padding;
  .loader-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .profile-img-upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: none;
    justify-content: center;
    .upload-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      opacity: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(50%);
    }
  }
  &:hover {
    .profile-img-upload-btn {
      display: block;
      cursor: pointer;
    }
  }
}
</style>
