var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.selected_call_plan ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Call Plan")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.callPlans, function (plan) {
    return _c('div', {
      key: plan.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.change_prefix.prefix = plan.value;
          _vm.conditions.screen = _vm.screens.main;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(plan.text))]), _vm.forms.change_prefix.prefix == plan.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.callPlans.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Call Plan',
      "design": 3
    }
  }) : _vm._e()], 2)])])] : _vm._e(), _vm.conditions.screen == _vm.screens.selected_prefix ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.update_addon_prefixes.send ? '' : _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Bundles")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.selectedPrefixBundles, function (bundle) {
    return _c('div', {
      key: bundle.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.prefix_id = _vm.prefixe.product_id;
          _vm.conditions.screen = _vm.screens.selected_prefix;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(bundle.bundle.label))]), _c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
    }, [_vm._v(_vm._s(bundle.balance))])]);
  }), _vm.selectedPrefixBundles.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no playlist',
      "design": 3
    }
  }) : _vm._e()], 2), _c('button', {
    staticClass: "IosFullWidthButton mb-16px",
    attrs: {
      "disabled": _vm.api.update_addon_prefixes.send
    },
    on: {
      "click": function ($event) {
        return _vm.updateAddonPrefix(_vm.selectedPrefix.product_id);
      }
    }
  }, [_vm.api.update_addon_prefixes.send ? _c('vb-spinner') : _vm._e(), [_vm._v("Purchase")]], 2)])])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("VS Packages")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_vm.conditions.call_plan && !!_vm.currentUserAccount ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.update_prefixes.send ? '' : _vm.conditions.screen = _vm.screens.selected_call_plan;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(_vm._s(_vm.currentUserAccount.first_name) + " " + _vm._s(_vm.currentUserAccount.last_name) + " ")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forms.change_prefix.prefix))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _vm.forms.change_prefix.submitted && _vm.$v.forms.change_prefix.prefix.$invalid || _vm.api.update_prefixes.validation_errors.prefix ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.change_prefix.prefix.required ? _c('span', [_vm._v("Prefix is required")]) : _vm._e(), _vm._l(_vm.api.update_prefixes.validation_errors.prefix, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()])]), _c('button', {
    staticClass: "IosFullWidthButton mb-16px",
    attrs: {
      "disabled": _vm.api.update_prefixes.send
    },
    on: {
      "click": function ($event) {
        return _vm.updatePrefix();
      }
    }
  }, [_vm.api.update_prefixes.send ? _c('vb-spinner') : _vm._e(), [_vm._v("Change")]], 2)] : _vm._e(), _vm.conditions.vs_packages ? _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.addonPrefixes, function (prefixe) {
    return _c('div', {
      key: prefixe.product_id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.prefix_id = prefixe.product_id;
          _vm.conditions.screen = _vm.screens.selected_prefix;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("get_property")(prefixe, 'product.product_label')))]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1);
  }), _vm.addonPrefixes.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.get_prefixes.send ? '' : 'There is no playlist',
      "design": 3
    }
  }, [_vm.api.get_prefixes.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2) : _vm._e()], 2)])]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }