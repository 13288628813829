var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 editTeamModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit pb-4"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newBackButton mr-16px",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Share caller ids ")]), _c('div', {
    staticClass: "dialer-edit-actions d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newDoneButton ml-16px",
    on: {
      "click": function ($event) {
        _vm.assignCallerids();
        _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Outgoing calls only. By selecting from the numbers below, the user can present the chosen number as the caller ID to the recipient.")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Available numbers")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-12px"
  }, [_vm.api.fetch_callerids.send ? _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row"
    }, [_c('div', {
      staticClass: "d-flex align-items-center w-50"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-2",
      staticStyle: {
        "height": "35px",
        "width": "35px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "21px",
        "width": "40%"
      }
    })]), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "24px"
      }
    })]);
  }) : _vm._l(_vm.numbers, function (number) {
    return _c('div', {
      key: number.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding",
      attrs: {
        "disabled": _vm.api.assign_callerids.send,
        "checked": _vm.forms.assign_callerids.numbers[number.did] ? true : false
      },
      on: {
        "change": function ($event) {
          _vm.forms.assign_callerids.numbers[number.did] ? _vm.$delete(_vm.forms.assign_callerids.numbers, number.did) : _vm.$set(_vm.forms.assign_callerids.numbers, number.did, number.did);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('img', {
      staticClass: "country-img mr-2",
      staticStyle: {
        "border-radius": "8px"
      },
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(number.did))
      }
    }), _c('span', {
      staticClass: "m-0 didNumber"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(number.did)))])])])], 1);
  })], 2)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }