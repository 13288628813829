<template>

	<div class="right-calender">
		<div class="top-main-calender scrollow1">
			<section class="sec-common">
				<div class="d-flex justify-content-between align-items-center">
					<div>
						<h5 class="emp-profile-heading">Tasks</h5>
					</div>
					<button class="btn btn-primarymike" @click="$modal.show('HRMAddNewTaskModal')">
            <svg xmlns="http://www.w3.org/2000/svg" width="16.551" height="16.551" viewBox="0 0 16.551 16.551" style="margin-right:10px;">
              <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z" transform="translate(-8.465 -8.465)" fill="#fff" stroke="#fff" stroke-width="1"/>
            </svg>
						Add Task
					</button>
				</div>
				<div class="mt-5">
					<button class="tabs-hrm mr-3">Outstanding</button>
					<button class="tabs-hrm">Completed</button>
					<h5 class=" emp-profile-heading mar-top-hrm25">My Tasks</h5>
				</div>
				<vb-table :tableDesign="1" class="tasktablewidth">
					<template slot="header">
						<h5 class="commonth task-firsttd ">Name</h5>
						<h5 class="commonth task-secondtd ">Assigned By</h5>
						<h5 class="commonth task-thirdtd ">Attachement required</h5>
						<h5 class="commonth task-fourtd  tbl-tskdeadline">deadline</h5>
						<h5 class="commonth task-fivetd  col-right">Action</h5>
					</template>
          <template slot="body">
            <tr>
              <td class="task-firsttd">
                Design Model
              </td>
              <td class="task-secondtd">
                Jackson
              </td>
              <td class="task-thirdtd ">
                No
              </td>
              <td class="task-fourtd">
                27/07/2022
              </td>
              <td class="task-fivetd">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown size="lg"  no-caret class="common-table-dd">
                      <template #button-content>
                        <b-icon icon="three-dots-vertical"></b-icon>
                      </template>
                      <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <tr>
              <td class="task-firsttd">
                Design Model
              </td>
              <td class="task-secondtd">
                Jackson
              </td>
              <td class="task-thirdtd ">
                No
              </td>
              <td class="task-fourtd">
                27/07/2022
              </td>
              <td class="task-fivetd">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
          </template>
				</vb-table>
			</section>
		</div>
		<HRMAddNewTaskModal/>
	</div>
</template>

<script>
import HRMAddNewTaskModal from "../Modals/HRMAddNewTaskModal.vue"
export default {
  name: 'TasksHRM',
  components:{
	HRMAddNewTaskModal,
  },
}
</script>

<style>

</style>