var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_vm.conditions.emergency_services ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.emergency_services = null;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Emergeny Services")])]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Phones")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('PurchaseExtension');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    }
  })], 1)]), _c('section', {
    staticClass: "dialer-settings-section dialer-devices-settings"
  }, [_vm.conditions.emergency_services ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Emergency service location")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm._f("check_empty")(_vm.conditions.emergency_services.location_house, '-')))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Emergency caller ID")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm._f("check_empty")(_vm.conditions.emergency_services.location_callerid, '-')))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Emergency addresses")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm._f("check_empty")(_vm.conditions.emergency_services.location, '-')))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_vm._m(0), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])] : [_c('div', {
    staticClass: "position-relative"
  }, [_vm.extensions.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.extension_detail.send ? `` : 'No record found',
      "design": 3
    }
  }, [_vm.api.extension_detail.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, _vm._l(_vm.extensionslist, function (extension, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "d-flex align-items-start"
    }, [_c('div', {
      staticClass: "basicIOSIcon mr-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": _vm._f("extensionIcon")(extension.type)
      }
    })], 1), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(" " + _vm._s(_vm._f("extensionText")(extension.type)) + " ")])])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
    }, [_vm._v(_vm._s(_vm._f("get_property")(extension, 'device_status.status')))]), _c('div', {
      staticClass: "position-relative"
    }, [_c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      staticClass: "position-relative",
      class: {
        'for-disable': extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension
      },
      attrs: {
        "id": `ext-${index}`,
        "title": extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension ? 'Your package does not support this feature' : ''
      }
    }, [_c('b-form-checkbox', {
      staticClass: "dialer-switch IosSwitch",
      attrs: {
        "disabled": _vm.api.update_setting.send.includes(extension.accountcode) || extension.type == 'MOBILEEXT' && !_vm.getUserPermissions.mobile_extension || extension.type == 'EXTERNALEXT' && !_vm.getUserPermissions.external_extension,
        "checked": extension.extension_call_group === 'yes',
        "name": "check-button",
        "switch": ""
      },
      on: {
        "change": function ($event) {
          return _vm.updateSetting(extension.extension_call_group, extension.accountcode);
        }
      }
    })], 1)])])]);
  }), 0), _c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Endpoints Handset")]), _vm._l(_vm.headExtensions, function (data, key) {
    return _c('div', {
      key: key
    }, [_c('div', {
      staticClass: "basicWhiteIOScard mb-16px"
    }, [_c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v("Handset " + _vm._s(key + 1))])]), _c('div', {
      staticClass: "position-relative"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer-switch IosSwitch",
      attrs: {
        "checked": data.extension_call_group === 'yes',
        "name": "check-button",
        "switch": ""
      },
      on: {
        "change": function ($event) {
          return _vm.updateSetting(data.extension_call_group, data.accountcode);
        }
      }
    })], 1)]), _c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v("Handset location")]), _c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide"
    }, [_vm._v("N/A")])]), _c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v("Handset status")]), _c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide"
    }, [_vm._v(_vm._s(_vm._f("check_empty")(data.device_status.status, '-')))])]), _c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v("SIP username")]), _c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide"
    }, [_vm._v(_vm._s(_vm._f("check_empty")(data.accountcode, '-')))])]), _c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "d-flex flex-column w-100"
    }, [data.secret ? _c('div', [_c('div', {
      staticClass: "mobileInputIOS iconOnRight InputIosV2"
    }, [_c('label', [_vm._v("SIP password")]), _c('input', {
      staticClass: "password-input-with-icon",
      attrs: {
        "type": `${_vm.conditions.sip_password_shows.includes(data.accountcode) ? 'text' : 'password'}`,
        "disabled": true
      },
      domProps: {
        "value": data.secret || ''
      }
    }), _c('div', [_c('b-icon', {
      staticClass: "mobileInputIOS-icon",
      attrs: {
        "icon": `${_vm.conditions.sip_password_shows.includes(data.accountcode) ? 'eye-fill' : 'eye-slash-fill'}`
      },
      on: {
        "click": function ($event) {
          _vm.conditions.sip_password_shows.includes(data.accountcode) ? _vm.conditions.sip_password_shows.splice(_vm.conditions.sip_password_shows.indexOf(data.accountcode), 1) : _vm.conditions.sip_password_shows.push(data.accountcode);
        }
      }
    })], 1)])]) : _vm._e(), _c('div', {
      staticClass: "d-flex mt-8px"
    }, [_c('div', {
      staticClass: "linkTypeText mr-16px",
      on: {
        "click": function ($event) {
          return _vm.updateSecret(data.accountcode);
        }
      }
    }, [_vm._v("Change")]), _c('div', {
      staticClass: "linkTypeText",
      on: {
        "click": function ($event) {
          return _vm.copyText(data.secret);
        }
      }
    }, [_vm._v("Copy")])])])]), _c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v("SIP Servers")]), _c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide"
    }, [_vm._v("sip.voipbusiness.com")])]), _c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v("SIP port")]), _c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide"
    }, [_vm._v("5060 or 5061")])])]), _c('div', {
      staticClass: "basicWhiteIOScard mb-16px"
    }, [_c('div', {
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.conditions.emergency_services = data;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v("Emergency Services")])]), _c('div', [_c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)])])]);
  })], _c('PurchaseExtensionModal', {
    on: {
      "load": function ($event) {
        return _vm.afterAdding();
      }
    }
  })], 2)]], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Manage handset location")])]);

}]

export { render, staticRenderFns }