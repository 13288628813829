var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_vm.conditions.screen == _vm.screens.add ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.create.send ? '' : _vm.conditions.screen = _vm.screens.list;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Status")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]) : _vm.conditions.screen == _vm.screens.view ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.list;
        _vm.selected.tag_id = '';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Status")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]) : _vm.conditions.screen == _vm.screens.edit ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.delete.send || _vm.api.update.send ? '' : _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Status")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.add;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    }
  })], 1)]), _c('div', {
    staticClass: "innerModalIos"
  }, [_vm.conditions.screen == _vm.screens.add ? [_c('div', {}, [_c('b-form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.create();
      }
    }
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Status Name")]), _c('b-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.api.create.send,
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.forms.create.text,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create, "text", $$v);
      },
      expression: "forms.create.text"
    }
  }), _vm.forms.create.submitted && _vm.$v.forms.create.text.$invalid || _vm.api.create.validation_errors.text ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create.text.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), _vm._l(_vm.api.create.validation_errors.text, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('vb-color-picker', {
    staticClass: "mobileIos color-picker-input",
    attrs: {
      "disabled": _vm.api.create.send,
      "value": _vm.forms.create.color
    },
    on: {
      "change": function ($event) {
        _vm.forms.create.color = $event;
      }
    }
  }, [_vm.forms.create.submitted && _vm.$v.forms.create.color.$invalid || _vm.api.create.validation_errors.color || _vm.api.create.validation_errors.colour ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  }, [!_vm.$v.forms.create.color.required ? _c('span', [_vm._v("* Color is required")]) : _vm._e(), _vm._l(_vm.api.create.validation_errors.color, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), _vm._l(_vm.api.create.validation_errors.colour, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Allow Call")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.create.send,
      "switch": ""
    },
    model: {
      value: _vm.forms.create.allow_call,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create, "allow_call", $$v);
      },
      expression: "forms.create.allow_call"
    }
  })], 1)])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "variant": "success",
      "disabled": _vm.api.create.send
    }
  }, [_vm.api.create.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)])], 1)] : _vm.conditions.screen == _vm.screens.view ? [_c('div', {}, [_c('div', {
    staticClass: "dialer-form"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Status Name")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.selected.status.status))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Color")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.selected.status.colour))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Allow Call")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.selected.status.allow_call))])])])])])] : _vm.conditions.screen == _vm.screens.edit ? [_c('div', {}, [_c('b-form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update();
      }
    }
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Status Name")]), _c('b-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.api.delete.send || _vm.api.update.send,
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.forms.update.text,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update, "text", $$v);
      },
      expression: "forms.update.text"
    }
  }), _vm.forms.update.submitted && _vm.$v.forms.update.text.$invalid || _vm.api.update.validation_errors.text ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update.text.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), _vm._l(_vm.api.update.validation_errors.text, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('vb-color-picker', {
    staticClass: "mobileIos color-picker-input v2",
    attrs: {
      "disabled": _vm.api.delete.send || _vm.api.update.send,
      "value": _vm.forms.update.color
    },
    on: {
      "change": function ($event) {
        _vm.forms.update.color = $event;
      }
    }
  }, [_vm.forms.update.submitted && _vm.$v.forms.update.color.$invalid || _vm.api.update.validation_errors.color || _vm.api.update.validation_errors.colour ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  }, [!_vm.$v.forms.update.color.required ? _c('span', [_vm._v("* Color is required")]) : _vm._e(), _vm._l(_vm.api.update.validation_errors.color, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), _vm._l(_vm.api.update.validation_errors.colour, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Allow Call")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.delete.send || _vm.api.update.send,
      "switch": ""
    },
    model: {
      value: _vm.forms.update.allow_call,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update, "allow_call", $$v);
      },
      expression: "forms.update.allow_call"
    }
  })], 1)])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "variant": "success",
      "disabled": _vm.api.delete.send || _vm.api.update.send
    }
  }, [_vm.api.update.send ? _c('vb-spinner') : [_vm._v("Update")]], 2)]), _c('button', {
    staticClass: "IosFullWidthButton forRemove mb-16px",
    attrs: {
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        _vm.api.delete.send || _vm.api.update.send ? '' : _vm.remove();
      }
    }
  }, [[_vm._v("Delete")]], 2)], 1)] : [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.statuses, function (status) {
    return _c('div', {
      key: status.id,
      staticClass: "basicWhiteIOScard-item d-flex justify-content-between",
      on: {
        "click": function ($event) {
          return _vm.setUpdateId(status);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "basicIOSIcon mr-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": status.allow_call ? 'allowCallsTrue-IOSIcon' : 'allowCallsFalse-IOSIcon'
      }
    })], 1), _c('div', {
      staticClass: "statusInsideList-Ios mr-6px"
    }, [_c('vb-status', {
      staticStyle: {
        "height": "20px",
        "width": "20px",
        "border-radius": "50%"
      },
      attrs: {
        "statusId": status.id
      }
    })], 1), _c('div', {
      staticClass: "headingInsideTable mr-6px"
    }, [_vm._v(_vm._s(status.status))])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [status.id == _vm.getCurrentUser.status_id ? _c('b-icon', {
      staticClass: "mr-6px",
      attrs: {
        "icon": "check2-circle",
        "variant": "primary"
      }
    }) : _vm._e(), !status.accountcode ? _c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide"
    }, [_vm._v("Default")]) : _vm._e(), status.accountcode ? _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1)]);
  }), !_vm.statuses.length ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.statuses.send ? '' : 'There is no Tags',
      "design": 3
    }
  }, [_vm.api.statuses.send ? _c('vb-loading', {
    staticClass: "text-center w-100",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }