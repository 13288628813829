<template>
  <modal 
    class="center-zoomIn-transition v2 m-w-500 UpdateInvoiceAddress max-height-100vh min-width-50percent-modal" 
    width="50%" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose"
  >
    <div class="w-100 ">
      <div class="dialer-edit-header pt-0">
        <h2 class="dialer-edit-title newer mb-0 d-flex align-items-center">
          <!-- <vb-svg @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" /> -->
          <button class="newBackButton mr-16px" @click="$modal.hide(modalName)">
            <b-icon icon="arrow-left-circle" />
            <span class="newBackButton-textpart">Back</span>
          </button>
          Update invoice address
        </h2>
        <div class="d-flex align-items-center">
          <!-- <a class="newCloseButton mr-3" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a> -->
          <button class="newDoneButton" :disabled="api.update.send" @click="updatedetail()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
              <vb-spinner v-if="api.update.send" />
              <template v-else>Done</template>
            </span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023" v-if="api.account_detail.send">
        <div class="w-100 d-flex flex-column">
          <div class="latestShimmerDesign mb-2" style="height: 21px;width: 200px"></div>
          <div class="latestShimmerDesign" style="height: 50px;width: 100%"></div>
        </div>
        <div class="d-flex mt-20px">
          <div class="d-flex flex-column w-50 pr-2">
            <div class="latestShimmerDesign mb-2" style="height: 21px;width: 200px"></div>
            <div class="latestShimmerDesign" style="height: 50px;width: 100%"></div>
          </div>
          <div class="d-flex flex-column w-50 pl-2">
            <div class="latestShimmerDesign mb-2" style="height: 21px;width: 200px"></div>
            <div class="latestShimmerDesign" style="height: 50px;width: 100%"></div>
          </div>
        </div>
        <div class="w-100 d-flex flex-column mt-20px">
          <div class="latestShimmerDesign mb-2" style="height: 21px;width: 200px"></div>
          <div class="latestShimmerDesign" style="height: 50px;width: 100%"></div>
        </div>
        <div class="d-flex mt-20px">
          <div class="d-flex flex-column w-50 pr-2">
            <div class="latestShimmerDesign mb-2" style="height: 21px;width: 200px"></div>
            <div class="latestShimmerDesign" style="height: 50px;width: 100%"></div>
          </div>
          <div class="d-flex flex-column w-50 pl-2">
            <div class="latestShimmerDesign mb-2" style="height: 21px;width: 200px"></div>
            <div class="latestShimmerDesign" style="height: 50px;width: 100%"></div>
          </div>
        </div>
        <div class="w-100 d-flex flex-column mt-20px">
          <div class="latestShimmerDesign mb-2" style="height: 21px;width: 200px"></div>
          <div class="latestShimmerDesign" style="height: 50px;width: 100%"></div>
        </div>
        <div class="w-100 d-flex flex-column mt-20px">
          <div class="latestShimmerDesign mb-2" style="height: 21px;width: 200px"></div>
          <div class="latestShimmerDesign" style="height: 50px;width: 100%"></div>
        </div>
      </div>
      <form v-else class="dialer-form mt-20px" @submit.prevent="updatedetail()">
        <div class="latestGreyBox-9-9-2023">
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <label>Company name</label>
            <input class="w-100" name="company" label="Company name" :disabled="api.update.send" v-model="forms.detail.companyname" />
            <p v-if="forms.detail.submitted && $v.forms.detail.companyname.$invalid" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.detail.companyname.required">* Company is required</span>
            </p>
          </div>
          <div class="d-flex mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder w-50 pr-2">
              <label>First name</label>
              <input class="w-100" name="first_name" label="First name" :disabled="api.update.send" v-model="forms.detail.firstname" />
              <p v-if="forms.detail.submitted && $v.forms.detail.firstname.$invalid" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.detail.firstname.required">* Firstname is required</span>
              </p>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder w-50 pl-2">
              <label>Last name</label>
              <input class="w-100" name="last_name" label="Last name" :disabled="api.update.send" v-model="forms.detail.lastname" />
              <p v-if="forms.detail.submitted && $v.forms.detail.lastname.$invalid" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.detail.lastname.required">* Lastname is required</span>
              </p>
            </div>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder mt-20px w-100">
            <label>Address</label>
            <input class="w-100" name="line1" label="Address" :disabled="api.update.send" v-model="forms.detail.address" />
            <p v-if="forms.detail.submitted && $v.forms.detail.address.$invalid" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.detail.address.required">* Address is required</span>
            </p>
          </div>
          <div class="d-flex mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder w-50 pr-2">
              <label>Town / City</label>
              <input class="w-100" name="town" label="Town / City" :disabled="api.update.send" v-model="forms.detail.city" />
              <p v-if="forms.detail.submitted && $v.forms.detail.city.$invalid" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.detail.city.required">* City is required</span>
              </p>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder w-50 pl-2">
              <label>Postcode</label>
              <input class="w-100" name="postcode" label="Postcode" :disabled="api.update.send" v-model="forms.detail.postcode" />
              <p v-if="forms.detail.submitted && $v.forms.detail.postcode.$invalid" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.detail.postcode.required">* postcode is required</span>
              </p>
            </div>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100">
            <label>Country</label>
            <b-form-select class="dialer-select" :disabled="api.update.send" v-model="forms.detail.country" :options="countryOptions"></b-form-select>
            <p v-if="forms.detail.submitted && $v.forms.detail.country.$invalid" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.detail.country.required">* Country is required</span>
            </p>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder mt-20px w-100">
            <label>Email</label>
            <input class="w-100" name="email" label="Email" disabled :value="forms.detail.email" />
          </div>
        </div>
      </form>
      <!-- <div class="dialer-box">
        <vb-spinner v-if="api.account_detail.send" />
        <form v-else class="dialer-form" @submit.prevent="updatedetail()">
          <div class="dialer-update-company bg-white">
            <div class="dialer-input-section">
              <div class="dialer-input-field input-consistent-inner-shadows dialer-max-width">
                <label class="dialer-input-label">Company Name</label>
                <div class="">
                  <input class="" name="company" label="Company Name" :disabled="api.update.send" v-model="forms.detail.companyname" />
                  <p v-if="forms.detail.submitted && $v.forms.detail.companyname.$invalid" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.detail.companyname.required">* Company is required</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="dialer-input-section">
              <div class="dialer-input-group-2">
                <div class="dialer-input-field input-consistent-inner-shadows dialer-max-width">
                  <label class="dialer-input-label">First Name</label>
                  <div>
                    <input class name="first_name" label="First Name" :disabled="api.update.send" v-model="forms.detail.firstname" />
                    <p v-if="forms.detail.submitted && $v.forms.detail.firstname.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.detail.firstname.required">* Firstname is required</span>
                    </p>
                  </div>
                </div>
                <div class="dialer-input-field input-consistent-inner-shadows dialer-max-width">
                  <label class="dialer-input-label">Last Name</label>
                  <div>
                    <input class name="last_name" label="Last Name" :disabled="api.update.send" v-model="forms.detail.lastname" />
                    <p v-if="forms.detail.submitted && $v.forms.detail.lastname.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.detail.lastname.required">* Lastname is required</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="dialer-input-section">
              <div class="dialer-input-field input-consistent-inner-shadows dialer-max-width">
                <label class="dialer-input-label">Address</label>
                <div>
                  <input class name="line1" label="Address" :disabled="api.update.send" v-model="forms.detail.address" />
                  <p v-if="forms.detail.submitted && $v.forms.detail.address.$invalid" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.detail.address.required">* Address is required</span>
                  </p>
                </div>
              </div>
            </div>
            <div class="dialer-input-section">
              <div class="dialer-input-group-2">
                <div class="dialer-input-field input-consistent-inner-shadows dialer-max-width">
                  <label class="dialer-input-label">Town / City</label>
                  <div>
                    <input class name="town" label="Town / City" :disabled="api.update.send" v-model="forms.detail.city" />
                    <p v-if="forms.detail.submitted && $v.forms.detail.city.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.detail.city.required">* City is required</span>
                    </p>
                  </div>
                </div>
                <div class="dialer-input-field input-consistent-inner-shadows dialer-max-width">
                  <label class="dialer-input-label">Postcode</label>
                  <div>
                    <input class name="postcode" label="Postcode" :disabled="api.update.send" v-model="forms.detail.postcode" />
                    <p v-if="forms.detail.submitted && $v.forms.detail.postcode.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.detail.postcode.required">* postcode is required</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div class="dialer-input-section">
              <div class="dialer-input-field input-consistent-inner-shadows dialer-select-field fixed-height">
                <label class="dialer-input-label">Country</label>
                <b-form-select class="dialer-select" :disabled="api.update.send" v-model="forms.detail.country" :options="countryOptions"></b-form-select>
                <p v-if="forms.detail.submitted && $v.forms.detail.country.$invalid" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.detail.country.required">* Country is required</span>
                </p>
              </div>
            </div>
            <div class="dialer-input-section">
              <div class="dialer-input-field input-consistent-inner-shadows dialer-max-width">
                <label class="dialer-input-label">Email</label>
                <div>
                  <input class name="email" label="Email" disabled :value="forms.detail.email" />
                </div>
              </div>
            </div>
          </div>
          <div class="dialer-input-section align-to-form md-mar-top">
            <div class="dialer-flex ">
            </div>
          </div>
        </form>
      </div> -->
    </div>
  </modal>
</template>

<script>
import moment from 'moment-timezone';
import _ from 'lodash';
import { required } from "vuelidate/lib/validators";
import { VOIP_API } from "../../utils";
import { CountryName2, flagsCountryCode } from '@/utils/flags';
export default {
  name: "InvoiceDetailModal",
  props: {
    modalName: {
      type: String,
      default: 'InvoiceDetailModal'
    },
  },
  data() {
    return {
      forms: {
        detail: this.$helperFunction.formInstance({
          data: {
            firstname: '',
            lastname: '',
            companyname: '',
            address: '',
            city: '',
            country: '',
            email: '',
            postcode: '',
          }
        })
      },
      api: {
        account_detail: this.$helperFunction.apiInstance(),
        update: this.$helperFunction.apiInstance(),
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    // countryOptions(){
    //   return [
    //     { value: "GB", text: "United Kingdom" },
    //     { value: "PK", text: "Pakistan" },
    //   ]
    // },
    countryOptions(){ 
      return _.orderBy(moment.tz.countries().map((i)=>({
        value: i,
        text: CountryName2[i],
        flag: flagsCountryCode[i],
      })) , ['text'], ['asc']);
    },
  },
  validations: {
    forms: {
      detail: {
        firstname: { 
          required, 
        },
        lastname: { 
          required, 
        },
        companyname: { 
          required, 
        },
        address: { 
          required, 
        },
        city: { 
          required, 
        },
        country: { 
          required, 
        },
        postcode: { 
          required, 
        },
      },
    }
  },
  methods: {
    onBeforeOpen() {
      this.fetchaccountdetail()
    },
    onBeforeClose() {
      this.forms.detail.reset()
      this.api.account_detail.reset()
      this.api.update.reset()
    },
    updatedetail() {
      let vm = this
      vm.forms.detail.submitted=true
      vm.$v.forms.detail.$touch();
      if (vm.$v.forms.detail.$invalid || vm.api.update.send || vm.api.account_detail.send) return;
      vm.api.update.send = true;
      vm.api.update.error_messsage = '';
      VOIP_API.endpoints.users.updateinvoicedetail({
        firstname: vm.forms.detail.firstname,
        lastname: vm.forms.detail.lastname,
        companyname: vm.forms.detail.companyname,
        address: vm.forms.detail.address,
        city: vm.forms.detail.city,
        country: vm.forms.detail.country,
        email: vm.forms.detail.email,
        postcode: vm.forms.detail.postcode,
      })
      .then(() => {
        vm.appNotify({
          message: "Invoice Information Updated Successfully",
          type: "success",
        })
        vm.$modal.hide(vm.modalName);
        vm.$emit('updated')
      })
      .catch((ex) => {
        vm.api.update.error_messsage=ex.own_message
      })
      .finally(() => {
        vm.forms.detail.submitted=false
        vm.api.update.send=false
      });
    },
    fetchaccountdetail(){
      let vm = this
      vm.api.account_detail.send=true
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data }) => {
        const detail = data?.user_detail ?? {}
        vm.forms.detail.firstname = detail?.firstname ?? '';
        vm.forms.detail.lastname = detail?.lastname ?? '';
        vm.forms.detail.companyname = detail?.companyname ?? '';
        vm.forms.detail.address = detail?.address ?? '';
        vm.forms.detail.city = detail?.city ?? '';
        vm.forms.detail.country = detail?.country ?? moment.tz(moment().tz())._z.countries()?.[0] ?? ''; 
        vm.forms.detail.email = detail?.email ?? '';
        vm.forms.detail.postcode = detail?.postcode ?? '';
      })
      .finally(() => {
        vm.api.account_detail.send=false
      });
    },
  },
};
</script>
