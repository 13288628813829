var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section MyNumbers"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("My numbers")])], 1)] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_vm._v(" My numbers "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'myNumbers'
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-0"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search number"
    },
    model: {
      value: _vm.filter.numbers.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.numbers, "search", $$v);
      },
      expression: "filter.numbers.search"
    }
  })], 1)])])]], 2), _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "newerTextVersion dialer-settings-intro mb-0 mt-0",
    class: _vm.getIsMobile ? 'text-center' : ''
  }, [_vm._v(" View all your telephone numbers and caller IDs. "), _vm.getCurrentUser.administrator_account ? [_vm._v(" If you want to add or remove telephone numbers, the administrator can do this in the "), _c('a', {
    on: {
      "click": function ($event) {
        return _vm.$emit('my-numbers');
      }
    }
  }, [_c('span', {
    staticClass: "innerRedBoxwithRoundedBorder-PurpleText"
  }, [_vm._v(" number section.")])])] : _vm._e()], 2)]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filternumbers),
      "listLength": _vm.filternumbers.length,
      "loading": _vm.api.fetch_cids.send && _vm.api.fetch_dids.send,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [!_vm.getIsMobile ? _c('tr', [_c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', {
          staticClass: "dialer-sorted-by ascending"
        }, [_vm._v("Number")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', [_vm._v("type")])]), _c('th', {
          staticClass: "dialer-col-right cursor_pointer"
        }, [_c('span', [_vm._v("Default outgoing numbers")])])]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [_vm.getIsMobile ? _vm._l(_vm.filternumbers, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title left"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "basicIOSIcon withoutAfter mr-6px"
          }, [_c('vb-icon', {
            attrs: {
              "icon": "TelephoneNumbersSettings-table-tdIcon",
              "width": "35px",
              "height": "35px"
            }
          })], 1), _c('div', {
            staticClass: "headingInsideTable"
          }, [_c('div', [_vm._v(_vm._s(_vm._f("number_formater")(data.number)))]), data.name ? _c('div', {
            staticClass: "textInsideCard mb-0 ml-0 mr-6px"
          }, [_vm._v(_vm._s(data.name))]) : _vm._e(), _c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "mobileTag blue mr-6px"
          }, [_vm._v(_vm._s(data.type == 'cid' ? "Outgoing Calls Only" : "Telephone number"))]), data.type != 'cid' && data.user_mode ? _c('div', {
            staticClass: "mobileTag green"
          }, [_vm._v("Dedicated")]) : _vm._e()])])])]), _c('td', {
            staticClass: "dialer-col-right right"
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-end"
          }, [data.ringgroupid && !data.user_mode ? _c('b-icon', {
            staticClass: "mr-4px cursor_pointer_no_hover editPencilMobileIcon",
            staticStyle: {
              "font-size": "16px"
            },
            attrs: {
              "icon": "pencil-fill"
            },
            on: {
              "click": function ($event) {
                return _vm.$modal.show(`${_vm._uid}-NumberSettingForUserModal`, {
                  ringgroupid: data.ringgroupid,
                  members: data.ringgroup_members,
                  number: data.number
                });
              }
            }
          }) : _vm._e(), data.is_cid ? _c('b-form-checkbox', {
            staticClass: "onlyCheckBox",
            attrs: {
              "disabled": _vm.api.update_cid.send || data.number == _vm.$store.state.sip.caller_id,
              "checked": data.number == _vm.$store.state.sip.caller_id
            },
            on: {
              "change": function ($event) {
                return _vm.updateCID(data.number);
              }
            }
          }) : _vm._e()], 1)])]);
        }) : _vm._l(_vm.filternumbers, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('vb-icon', {
            staticClass: "mr-16px",
            attrs: {
              "icon": "TelephoneNumbersSettings-table-tdIcon",
              "width": "35px",
              "height": "35px"
            }
          }), _c('span', [_vm._v(_vm._s(_vm._f("number_formater")(data.number)))]), _c('br'), data.name ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 ml-16px"
          }, [_vm._v(_vm._s(data.name))]) : _vm._e()], 1)]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(" " + _vm._s(data.type == 'cid' ? "Outgoing Calls Only" : "Telephone number") + " "), data.type != 'cid' && data.user_mode ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 ml-16px"
          }, [_vm._v(" Dedicated ")]) : _vm._e()]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-between"
          }, [_c('span', [data.ringgroupid && !data.user_mode ? _c('span', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 d-flex h-fit-content ml-0 showOnly-OnHover-insideTable",
            on: {
              "click": function ($event) {
                return _vm.$modal.show(`${_vm._uid}-NumberSettingForUserModal`, {
                  ringgroupid: data.ringgroupid,
                  members: data.ringgroup_members,
                  number: data.number
                });
              }
            }
          }, [_vm._v(" Configure voicemail and forwarding ")]) : _vm._e()]), _c('div', {
            staticClass: "bigSquareCheckbox"
          }, [data.is_cid ? _c('b-form-checkbox', {
            staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight users-lists-item-999 makeCheckBoxesSquare",
            attrs: {
              "disabled": _vm.api.update_cid.send || data.number == _vm.$store.state.sip.caller_id,
              "checked": data.number == _vm.$store.state.sip.caller_id
            },
            on: {
              "change": function ($event) {
                return _vm.updateCID(data.number);
              }
            }
          }) : _vm._e()], 1)])])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "130px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "150px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "25px",
              "width": "25px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  })], 1), _c('NumberSettingForUserModal', {
    attrs: {
      "modalName": `${_vm._uid}-NumberSettingForUserModal`
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }