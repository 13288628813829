<template>
  <modal class="dialer_animation right_side_popup billingAllNumbersModal" width="50%" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose" :clickToClose="false">
    <div class="dialer-box  table">
      <div class="dialer-edit-header">
        <h2 class="dialer-edit-title mb-0">Users</h2>
        <div class="dialer-edit-actions">
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a class="" v-else @click="$modal.hide(modalName)">
            <button type="button" class="newCloseButton">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </a> 
        </div>
      </div>
      <div class="mt-5 mb-3">
        <vb-table class="plan-license-table callPrefixTable dialer-table dialer-table-bg-f3f3f3 px-3 pb-3"
          :isListEmpty="!(response.account.users || false)"
          :listLength="Object.values(response.account.users || {}).length"
          :perPage="5"
          :loading="api_sent.account_detail"
        >
          <tr slot="header" class="border-bottom-0">
            <th class="dialer-has-sort pt-3 pb-3 pl-0 border-radius-16">
              <span class=" border-bottom pb-3 pr-4">Name</span>
            </th>
            <th class="dialer-has-sort pt-3 pb-3 border-radius-16">
              <span class="border-bottom pb-3 pr-4">Call plan</span>
            </th>
            <th class="dialer-has-sort dialer-col-right pt-3 pr-0 border-radius-16 pb-3">
              <span class="border-bottom pb-3 pl-4">Action</span>
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr class="dialer-row-select mx-0 px-0" v-for="(user, key, index) in (response.account.users || {})" :key="key" v-show="index >= start && index <= end">
              <td class="dialer-row-title pl-0">
                <Info :id="key" />
              </td>
              <td class="dialer-has-sort px-0">
                <div class=" whiteBGinputWithGreyRoundedBorder dropdownVersion whiteBg-select simple-image">
                  <vb-select :disabled="api_sent.update_prefixes" :selected="user.plan_prefix || ''" :defaultSelectedText="`Select call plan`" class="dialer-menu-filterOptions md-mar-left" @change="updatePrefix(key,$event)" :options="callPlans" />
                </div>
              </td>
              <td class="dialer-has-sort dialer-col-right pr-0">
                <button class="dialer-button dialer-button-primary">Change</button>
              </td>
            </tr>
          </template>
        </vb-table>
      </div>
      
      
      <!-- <div class="mt-5 mb-3">
        <h2>VS Packages</h2>
        <vb-table class="plan-license-table dialer-table dialer-table-bg-f3f3f3 VSpackagesTable mt-3 px-3 pb-3" :isListEmpty="addonPrefixes.length==0" :listLength="addonPrefixes.length" :perPage="5" :loading="api_sent.get_prefixes">
          <tr slot="header" class="border-bottom-0">
            <th class="dialer-has-sort pt-3 pb-3 pl-0 border-radius-16">
              <span class=" border-bottom pb-3 pr-4">Name</span>
            </th>
            <th class="dialer-has-sort pt-3 pr-0 border-radius-16 pb-3">
              <span class="border-bottom pb-3 pl-4">Price</span>
            </th>
            <th class="dialer-has-sort pt-3 pr-0 border-radius-16 pb-3">
              <span class="border-bottom pb-3 pl-4">Billing Period</span>
            </th>
            <th class="dialer-has-sort dialer-col-right pt-3 pr-0 border-radius-16 pb-3">
              <span class="border-bottom pb-3 pl-4">Action</span>
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr class="dialer-row-select mx-0 px-0" v-for="(prefixe, index) in addonPrefixes" :key="prefixe.product_id" v-show="index >= start && index <= end">
              <td v-b-toggle="`prefix-bundles-${prefixe.id}`" class="dialer-row-title pl-0">
                <span>
                  {{ prefixe | get_property('product.product_label') }}
                  <b-collapse :id="`prefix-bundles-${prefixe.id}`">
                    <div v-for="bundle in getProperty(prefixe,'product.plan.vspackage.bundles',[])" :key="bundle.id">
                      {{bundle | get_property('bundle.label')}} ({{bundle | get_property('balance')}})
                    </div>
                  </b-collapse>
                </span>
              </td>
              <td class="dialer-has-sort pr-0">
                <span>{{ prefixe | get_property('product.plan.plan_cost') | to_fixed }}</span>
              </td>
              <td class="dialer-has-sort pr-0">
                <span>{{ prefixe | get_property('product.plan.plan_billing_period') }}</span>
              </td>
              <td class="dialer-has-sort dialer-col-right pr-0">
                <button class="dialer-button dialer-button-primary" :disabled="api_sent.update_addon_prefixes" @click="updateAddonPrefix(prefixe.product_id)" >Purchase</button>
              </td>
            </tr>
          </template>
        </vb-table>
      </div> -->
    </div>
  </modal>
</template>

<script>
import { $fn, VOIP_API } from '../../utils';
import Info from '../Lists/Info.vue';
export default {
  name: 'UserDetailModal',
  components: {
    Info,
  },
  inject:['isEmpty','getProperty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'UserDetailModal',
    },
  },
  data(){
    return {
      api_sent: {
        account_detail: false,
        get_prefixes: false,
        update_prefixes: false,
        update_addon_prefixes: false,
      },
      response: {
        account: {},
        prefixes: {},
      },
    }
  },
  computed: {
    callPlans(){ return (this.response.prefixes.callPrefixes || []).map(item=>( { text: `${item.prefix.label} ${item.prefix.prefix}`, value: item.prefix.prefix, } ))
    },
    addonPrefixes(){ return this.response?.prefixes?.addonPrefixes ?? [] }
  },
  methods: {
    onBeforeOpen(){
      this.fetchAccountDetail()
      this.fetchPrefixes()
    },
    onBeforeClose(){
      this.response.account={}
    },
    fetchAccountDetail(){
      let vm = this
      if(vm.api_sent.account_detail) return;
      vm.api_sent.account_detail=true
      VOIP_API.endpoints.users
        .accountdetail()
        .then(({ data }) => (vm.response.account = data))
        .finally(() => (vm.api_sent.account_detail = false));
    },
    fetchPrefixes(){
      let vm = this
      vm.api_sent.get_prefixes=true
      VOIP_API.endpoints.techprifex.get({
        uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
        accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
      }).then(({ data: prefixes })=>{
        vm.response.prefixes = prefixes || {}
      }).finally(()=>{
        vm.api_sent.get_prefixes=false
      })
    },
    updatePrefix(accountcode,prefix){
      let vm = this
      const prefix_obj = (vm.response.prefixes.callPrefixes || []).find(item=>item.prefix.prefix==prefix)
      if(!prefix_obj || vm.api_sent.update_prefixes) return;
      vm.api_sent.update_prefixes=true
      VOIP_API.endpoints.techprifex.update(prefix_obj.product_id,{
        id: prefix_obj.product_id,
        prefix: prefix,
        useraccount: accountcode,
        accountcode: accountcode,
        account: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
        uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
      })
      .then(()=>{
        return $fn.sleep(2 * 1000)
      })
      .then(()=>{
        vm.fetchAccountDetail()
        // vm.appNotify({
        //   message: 'Successfully Updated',
        //   type: 'success',
        // })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api_sent.update_prefixes=false
      })
    },
    updateAddonPrefix(product_id){
      let vm = this
      const prefix_obj = (vm.response.prefixes.addonPrefixes || []).find(item=>item.product_id==product_id)
      if(!prefix_obj || vm.api_sent.update_addon_prefixes) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to purchase this`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api_sent.update_addon_prefixes=true
            VOIP_API.endpoints.techprifex.update(prefix_obj.product_id,{
              id: prefix_obj.product_id,
              prefix: product_id,
              useraccount: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
              accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
              account: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
              uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
            }).then(()=>{
              vm.appNotify({
                message: 'Successfully Updated',
                type: 'success',
              })
            }).catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(()=>{
              vm.api_sent.update_addon_prefixes=false
            })
          }
        },
      });
    },
  },
}
</script>

<style>

</style>