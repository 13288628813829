export class VideoRecorder {
  started = false
  stream = null
  recorder = null
  max_time = 0
  time_out_id = null
  timer_id = null
  time = 0
  chunks = []
  blob = null
  url = ''
  onblobready = null
  constructor(options){
    this.max_time=options?.max_time || 3*60*1000
  }
  async makeStream(){
    await this.reset()
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
      video: true,
    })
    this.stream=stream
    this.recorder = new MediaRecorder(stream);
    this.recorder.addEventListener("dataavailable", this.onDataAvailable.bind(this));
    this.recorder.addEventListener("stop", this.onStop.bind(this));
    this.recorder.addEventListener("start", this.onStart.bind(this));
    return stream
  }
  onDataAvailable(event){
    this.chunks.push(event.data)
  }
  onStart(){
    let self = this
    this.started=true
    this.time_out_id = setTimeout(this.stop, this.max_time);
    this.timer_id = setInterval(() => {
      self.time = self.time + 1;
    }, 1 * 1000);
  }
  onStop(){
    this.started=false
    clearTimeout(this.time_out_id);
    clearInterval(this.timer_id)
    this.stream.getTracks().forEach((track) => track.stop());
    this.blob = new Blob(this.chunks, {
      type: "video/webm",
    });
    this.onblobready?.(this.blob)
  }
  async start(){
    await this.recorder.start()
  }
  async stop(){
    await this.recorder.stop()
  }
  async reset(){
    if(this.started) await this.stop()
    if(this.stream) this.stream.getTracks().forEach((track) => track.stop());
    if(this.recorder) {
      this.recorder.removeEventListener("dataavailable", this.onDataAvailable.bind(this));
      this.recorder.removeEventListener("stop", this.onStop.bind(this));
      this.recorder.removeEventListener("start", this.onStart.bind(this));
      this.recorder=null
    }
    this.blob=null
    this.chunks=[]
    this.url=''
    this.time=0
  }
}