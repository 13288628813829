export default [
  {
    id: 1,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 2,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 3,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 4,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 5,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 6,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 7,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 8,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 9,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 10,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 11,
    name: "Jackson Will",
    policy: "Half day",
    action: "decrease",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },
  {
    id: 12,
    name: "Jackson Will",
    policy: "Half day",
    action: "increase",
    days: "25",
    note: "05/09/2022",
    created_at: '05/09/2022',
    imgSrc: '@/assets/images/credit-card/1.jpeg'
  },


]