<template>
  <b-tab  @click="onTab">
    <template #title>
      
      <div class="d-flex align-items-center unreadTextCountBadge-container">
        <div class="mr-8px">{{text}}</div>
        <div :class="['unreadTextCountBadge',totalUnreadTitle<10 ? 'single' : totalUnreadTitle<=99 ? 'double':totalUnreadTitle > 99 ? 'triple' : '']" v-if="totalUnreadTitle>0">{{totalUnreadTitle > 99 ? '+99' : totalUnreadTitle}}</div>
      </div>
    </template>
    <vb-loading v-if="loading" />

    <div v-else class="allow_scroll position-relative">
      
      <template v-if="!isEmpty(recent_users) || !isEmpty(recent_channels) || !isEmpty(recent_teams)">
        <div class="dialer-dropdown position-absolute right-15 -top-15">
          <b-dropdown id="dropdown-form" text="appearance" menu-class="latestDesignMenuClass" ref="dropdown" class="m-2 top-12">
            
            <template #button-content>
              <span class="mr-2">Views</span>
              <b-icon icon="filter" scale="1" />
              <span class="sr-only">Views</span>
            </template>

            <b-dropdown-form>
              <b-form-radio-group class="p-2"  :value="GET_LOCAL_SETTING_CHAT.view.recent_type" @change="SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE($event)" name="radio-sub-component">
                <b-form-radio class="radio_btn_like_checkbox makeCheckBoxesSquare" value="separate">Separate</b-form-radio>
                <b-form-radio class="radio_btn_like_checkbox makeCheckBoxesSquare" value="mixed">Mixed</b-form-radio>
              </b-form-radio-group>
            </b-dropdown-form>

          </b-dropdown>
        </div>
      </template>

      <template v-if="!isEmpty(fav)">
        <strong class="filter-title d-flex justify-content-between align-items-center">
          <span class="title d-flex align-items-center">
            Favorites
            <b-icon @click="is_show_fav = !is_show_fav" scale="0.8" class="expand-icon ml-2" icon="chevron-down" :rotate="is_show_fav ? '180' : ''" />
          </span>
        </strong>
        <transition name="slide">
          <ul v-show="is_show_fav" class="contact-list favorites list-unstyled m-0">
            <slot name="fav-items" />
          </ul>
        </transition>
      </template>

      <template v-if="nofav">
        <strong class="filter-title d-flex justify-content-between align-items-center">
          <span class="title d-flex align-items-center">
            {{ text }}
            <b-icon v-if="!isEmpty(nofav)" @click="is_show_not_fav = !is_show_not_fav" class="expand-icon ml-2" icon="chevron-down" :rotate="is_show_not_fav ? '180' : ''" scale="0.8" />
          </span>
          <a v-if="isShowAdd" @click="onAdd">
            <b-icon icon="plus" class="border border-primary rounded mr-3" font-scale="1.1" variant="primary" />
          </a>
        </strong>
        <transition name="slide">
          <div v-show="is_show_not_fav">
            <vb-no-record v-if="isEmpty(nofav)" :text="'There is no content'" :design="3" />
            <ul class="contact-list nofav list-unstyled m-0" v-else>
              <slot name="not-fav-items" />
            </ul>
          </div>
        </transition>
      </template>

      <template v-if="!isEmpty(all_recent)">
        <strong class="filter-title d-flex justify-content-between align-items-center">
          <span class="title d-flex align-items-center">
            All
            <b-icon @click="is_show.allRecent = !is_show.allRecent" scale="0.8" class="expand-icon ml-2" icon="chevron-down" :rotate="is_show.allRecent ? '180' : ''" />
          </span>
        </strong>
        <transition name="slide">
          <ul v-show="is_show.allRecent" class="contact-list recent_all list-unstyled m-0">
            <slot name="recent-all-items" />
          </ul>
        </transition>
      </template>

      <template v-if="!isEmpty(recent_users) && GET_LOCAL_SETTING_CHAT.view.recent_type==='separate'">
        <strong class="filter-title d-flex justify-content-between align-items-center">
          <span class="title d-flex align-items-center">
            Users
            <b-icon @click="is_show.users = !is_show.users" scale="0.8" class="expand-icon ml-2" icon="chevron-down" :rotate="is_show.users ? '180' : ''" />
          </span>
        </strong>
        <transition name="slide">
          <ul v-show="is_show.users" class="contact-list users list-unstyled m-0">
            <slot name="recent-user-items" />
          </ul>
        </transition>
      </template>

      <template v-if="!isEmpty(recent_channels) && GET_LOCAL_SETTING_CHAT.view.recent_type==='separate'">
        <strong class="filter-title d-flex justify-content-between align-items-center">
          <span class="title d-flex align-items-center">
            <!-- Channels -->
            Groups
            <b-icon @click="is_show.channels = !is_show.channels" scale="0.8" class="expand-icon ml-2" icon="chevron-down" :rotate="is_show.channels ? '180' : ''" />
          </span>
        </strong>
        <transition name="slide">
          <ul v-show="is_show.channels" class="contact-list list-unstyled channels m-0">
            <slot name="recent-channel-items" />
          </ul>
        </transition>
      </template>

      <template v-if="!isEmpty(recent_teams) && GET_LOCAL_SETTING_CHAT.view.recent_type==='separate'">
        <strong class="filter-title d-flex justify-content-between align-items-center">
          <span class="title d-flex align-items-center">
            Teams
            <b-icon @click="is_show.teams = !is_show.teams" scale="0.8" class="expand-icon ml-2" icon="chevron-down" :rotate="is_show.teams ? '180' : ''" />
          </span>
        </strong>
        <transition name="slide">
          <ul v-show="is_show.teams" class="contact-list teams list-unstyled m-0">
            <slot name="recent-team-items" />
          </ul>
        </transition>
      </template>

    </div>
  </b-tab>
</template>

<script>
import { GET_LOCAL_SETTING_CHAT } from '@/store/helper/getters';
import { mapGetters, mapMutations } from 'vuex';
import { SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE } from '@/store/helper/mutations';
export default {
  name: "PanelTab",
  props: {
    fav: {
      type: Array,
      default: () => [],
    },
    nofav: {
      type: Array,
    },
    recent_users: {
      type: Array,
      default: () => [],
    },
    recent_channels: {
      type: Array,
      default: () => [],
    },
    recent_teams: {
      type: Array,
      default: () => [],
    },
    all_recent: {
      type: Array,
      default: () => [],
    },
    isShowAdd: {
      type: Boolean,
      default: false,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    searchPlaceholder: {
      type: String,
      default: "",
    },
    text: {
      type: String,
      default: "",
    },
    recentUsersUnreadCount:{
      type: [String,Number],
      default: ""
    },
    recentTeamsUnreadCount:{
      type: [String,Number],
      default: ""
    },
    recentChannelsUnreadCount:{
      type: [String,Number],
      default: ""
    },
    totalUnread:{
      type: [String,Number],
      default: ""
    },
    totalUnreadTitle:{
      type: [String,Number],
      default: ""
    },
  },
  data() {
    return {
      search: "",
      is_recent_mixup: false,
      is_separate_mixup: true,
      is_show_fav: true,
      is_show_not_fav: true,
      is_show: {
        teams: true,
        channels: true,
        users: true,
        allRecent: true,
      },
      ops: {
        vuescroll: {
          wheelScrollDuration: 500,
        },
        scrollPanel: {
          scrollingX: false,
        },
        bar: {
          background: "#7367F0",
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_CHAT
    ]),
  },
  inject:['isEmpty'],
  watch: {
    search(newVal) {
      this.$emit("on-change-search", newVal);
    },
  },
  methods: {
    ...mapMutations([
      SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE
    ]),
    onAdd() {
      this.$emit("add");
    },
    onTab() {
      this.$emit("tab");
    },
  },
};
</script>

<style lang="scss" scoped>
.allow_scroll {
  height: calc(100vh - 228px);
  // width: calc(100% - 10px);
  width: calc(100% - 22px);
  // padding-right: 10px;
  padding-right: 0px;
  // overflow-y: auto;
  overflow-y: overlay;
  margin-bottom: 0;
  max-height: calc(100% - 6px);
}
.expand-icon {
  width: 20px;
  height: 20px;
  cursor: pointer;
}

// slide top to bottom &bottom to top animation
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
