<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-devices-settings">
      <div class="settingsFirstSection">
        <template v-if="getIsMobile">
          <div class="d-flex align-items-center calc-title-width-button">
            <b-icon icon="chevron-left" style="opacity:0;pinter-events:none;" font-scale="1.5" class="settings-back-icon cursor_pointer" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">My devices</h2>
            <button class="newButton mr-0" @click="$modal.show('AddDevicesOptionsModal')">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add devices</span>
            </button>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
            My devices
            <b-icon 
              icon="info-circle-fill" 
              @click="$modal.show('KbInfoModal',{ kb: 'phones' })" 
              class="cursor_pointer infoKbIcon md-mar-left" 
            />
          </h2>
          <div class="settingsFirstSection-right">
            <button class="newButton mwd-175px mr-0" @click="$modal.show('AddDevicesOptionsModal')">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add devices</span>
            </button>
          </div>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="newerTextVersion dialer-settings-intro mb-0 mt-0" :class="getIsMobile ? 'text-center' : ''">
          View all your active devices and statuses.
        </span>
      </div>
      <vb-table 
        :isListEmpty="extensions.length==0" 
        :listLength="extensions.length" 
        :loading="api.extension_detail.send" 
        textNoRecord=" " 
        class="for-modal-box-bg-gray latestTableDesign-withBlackBorders-again"
      >
        <template slot="header">
          <tr v-if="!getIsMobile">
            <th class="dialer-has-sort">Extension type</th>
            <th class="dialer-col-right cursor_pointer">Receive calls</th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <template v-if="getIsMobile">
            <tr 
              v-for="(extension, index) in extensions" 
              :key="extension.accountcode" 
              v-show="index >= start && index <= end" 
              @click="
              (extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension) ? 
                appNotify({
                  message:'Your package does not support this feature',
                  type:'danger'
                }) 
                : 
                $modal.show('EditExtensionDetail',{
                  extension,
                  voipaccount,
                  limit: response.extension_detail.extensionlimit[extension.limit_type],
                })
              "
            >
              <td class="dialer-row-title left">
                <div class="w-auto">
                  <div class="d-flex align-items-center">
                    <div :class="`ExtentionIcon mr-8px ${extension.device_status.status == 'online' ? 'statusOnline' : 'statusOffline'}`">
                      <vb-icon :icon="extension.type | extensionIcon" width="25px" height="25px" />
                    </div>
                    <div>
                      <div class="headingInsideTable">
                        <template v-if="extension.type=='EXTERNALEXT'">
                          <div>External app</div>
                          <div class="extensionDeviceName">
                            ( {{ extension.accountname }} )
                          </div>
                        </template>
                        <div class="onlyfirstLetterCapital" v-else>
                          <div>{{ extension.accountname }}</div>
                          <div v-if="extension.device_status.device_detail" class="extensionDeviceName">
                            ( {{ extension | get_property('device_status.device_detail') | DeviceInfo }} )
                          </div>
                        </div>
                      </div>
                      <div class="deviceContainer">
                        <span 
                          class="newThisDeviceIndicaterTextBox-25-11-2023 ml-0" 
                          v-if="extension.accountcode==registeredExtension" 
                          :id="`${extension.accountcode}-current-device-popover`"
                        >
                          <span>This device</span>
                        </span>
                        <b-popover 
                          custom-class="thisDeviceCustomPopOver" 
                          v-if="extension.accountcode==registeredExtension" 
                          :target="`${extension.accountcode}-current-device-popover`" 
                          triggers="hover" 
                          placement="topright"
                        >
                          <div class="d-flex flex-column">
                            <span class="mb-2">{{extension | get_property('device_status.device_detail') | extensionDeviceInfo('ip')}} </span> 
                            <span class="mb-2">{{extension | get_property('device_status.device_detail') | extensionDeviceInfo('app-name')}} {{extension | get_property('device_status.device_detail') | extensionDeviceInfo('location')}}</span>
                            <span class="mb-2">Next registration: {{ extension | get_property('device_status.next_registration') | dateFormate }} </span>
                            <span class="mb-0">Last activity: {{ extension | get_property('device_status.last_registration') | dateFormate }} </span>
                          </div>
                        </b-popover>
                      </div>
                      <span 
                        v-if="getProperty(extension,'device_status.device_detail.extra_data')" 
                        class="mobileTag blue mb-0"
                      >
                        Online
                      </span>
                      <div v-if="user.active_status!='1'" class="suspendedTag">Suspended</div>
                    </div>
                    
                  </div>
                </div>
              </td>
              <td @click.stop="''" class="dialer-row-title dialer-col-right right">
                <div class="d-flex flex-row justify-content-end align-items-center w-fit-content ml-auto">
                  <b-icon 
                    icon="pencil-fill" 
                    class="mr-8px cursor_pointer_no_hover editPencilMobileIcon"
                    style="font-size:16px" 
                    @click="
                      (extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension) ? 
                        appNotify({
                          message:'Your package does not support this feature',
                          type:'danger'
                        })
                        : 
                        $modal.show('EditExtensionDetail',{
                          extension,
                          voipaccount,
                          limit: response.extension_detail.extensionlimit[extension.limit_type],
                        })
                    "
                  />
                  <div 
                    v-b-tooltip :title="(extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension)?'Your package does not support this feature':''"
                    :class="{
                      'for-disable': (extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || 
                      (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension)
                    }"
                  >
                    <b-form-checkbox 
                      :disabled="
                        api.update_setting.send.includes(extension.accountcode) || 
                        (extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || 
                        (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension)
                      " 
                      :checked="extension.extension_call_group === 'yes'" 
                      class="newerSwitch" 
                      @change="updateSetting(extension.extension_call_group,extension.accountcode)" 
                      name="check-button" 
                      switch 
                    />
                  </div>  
                </div>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr 
              v-for="(extension, index) in extensions" 
              :key="extension.accountcode" 
              v-show="index >= start && index <= end" 
              @click="
              (extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension) ? 
                appNotify({
                  message:'Your package does not support this feature',
                  type:'danger'
                }) 
                : 
                $modal.show('EditExtensionDetail',{
                  extension,
                  voipaccount,
                  limit: response.extension_detail.extensionlimit[extension.limit_type],
                })
              "
            >
              <td class="dialer-row-title">
                <div class="d-flex align-items-center">
                  <div :class="`ExtentionIcon ${extension.type=='EXTERNALEXT' || extension.device_status.status == 'online' ? 'statusOnline' : 'statusOffline'}`">
                    <vb-icon :icon="extension.type | extensionIcon" width="25px" height="25px" />
                  </div>
                  <div class="d-flex flex-column">
                    <div class="d-flex align-items-center">
                      <template v-if="extension.type=='EXTERNALEXT'">
                        <span class="mx-3 onlyfirstLetterCapital">
                          External app
                          <span class="extensionDeviceName">
                            <img class="country-img" style="border-radius:8px;" width="45px" :src="extension.accountname | number_formater | flag_icon"/>
                            ( {{ extension.accountname | number_formater }} )
                          </span>
                        </span>
                      </template>
                      <span class="mx-3 onlyfirstLetterCapital" v-else>
                        {{ extension.accountname }}
                        <span v-if="extension.device_status.device_detail" class="extensionDeviceName">
                          ( {{ extension | get_property('device_status.device_detail') | DeviceInfo }} )
                        </span>
                      </span>
                      <div class="deviceContainer">
                        <span 
                          class="newThisDeviceIndicaterTextBox-25-11-2023 ml-0" 
                          v-if="extension.accountcode==registeredExtension" 
                          :id="`${extension.accountcode}-current-device-popover`"
                        >
                          <span>This device</span>
                        </span>
                        <b-popover 
                          custom-class="thisDeviceCustomPopOver" 
                          v-if="extension.accountcode==registeredExtension" 
                          :target="`${extension.accountcode}-current-device-popover`" 
                          triggers="hover" 
                          placement="topright"
                        >
                          <div class="d-flex flex-column">
                            <span class="mb-2">{{extension | get_property('device_status.device_detail') | extensionDeviceInfo('ip')}} </span> 
                            <span class="mb-2">{{extension | get_property('device_status.device_detail') | extensionDeviceInfo('app-name')}} {{extension | get_property('device_status.device_detail') | extensionDeviceInfo('location')}}</span>
                            <span class="mb-2">Next registration: {{ extension | get_property('device_status.next_registration') | dateFormate }} </span>
                            <span class="mb-0">Last activity: {{ extension | get_property('device_status.last_registration') | dateFormate }} </span>
                          </div>
                        </b-popover>
                      </div>
                      <span 
                        v-if="getProperty(extension,'device_status.device_detail.extra_data')" 
                        class="OwnertaggedVersion mb-0 ml-16px"
                      >
                        Online
                      </span>
                    </div>
                    <div v-if="user.active_status!='1'" class="suspendedTag ml-3">Suspended</div>
                  </div>
                </div>
              </td>
              <td @click.stop="''" class="dialer-row-title dialer-col-right">
                <div class="d-flex flex-row justify-content-end align-items-center w-fit-content ml-auto">
                  <span class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-16px showOnly-OnHover-insideTable" 
                    @click="
                    (extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension) ? 
                      appNotify({
                        message:'Your package does not support this feature',
                        type:'danger'
                      })
                      : 
                      $modal.show('EditExtensionDetail',{
                        extension,
                        voipaccount,
                        limit: response.extension_detail.extensionlimit[extension.limit_type],
                      })
                    "
                  > 
                    View info 
                  </span>
                  <div 
                    v-b-tooltip :title="(extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension)?'Your package does not support this feature':''"
                    :class="{
                      'for-disable': (extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || 
                      (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension)
                    }"
                  >
                    <b-form-checkbox 
                      :disabled="
                        api.update_setting.send.includes(extension.accountcode) || 
                        (extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || 
                        (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension)
                      " 
                      :checked="extension.extension_call_group === 'yes'" 
                      class="newerSwitch" 
                      @change="updateSetting(extension.extension_call_group,extension.accountcode)" 
                      name="check-button" 
                      switch 
                    />
                  </div>  
                </div>
              </td>
            </tr>
          </template>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex align-items-center w-fit-content">
                <div class="latestShimmerDesign mr-3" style="height:48px;width:48px;"></div>
                <div class="latestShimmerDesign" style="height:21px;width:180px"></div>
              </div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:32px;width:60px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
      <div class="position-relative">
        <modal name="AddDevicesOptionsModal" class="AddDevicesOptionsModal" :clickToClose="false" width="50%" height="auto" :scrollable="true">
          <div class="w-100">
            <div class="dialer-edit-header pt-0 w-100 d-block">
              <div class="d-flex alingn-items-center justify-content-between w-100">
                <h2 class="dialer-edit-title newer my-0">Choose device type </h2>
                <div class="dialer-edit-actions">
                  <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('AddDevicesOptionsModal')" />
                  <a v-else class="newCloseButton" @click="$modal.hide('AddDevicesOptionsModal')">
                    <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                    <span class="newCloseButton-textPart">Close</span>
                  </a>
                </div>
              </div>
              <div class="vm--modal-text">
                Select the device type you want to add: either a handset for traditional 
                usage or an external device for expanded functionality.
              </div>
            </div>
            <div class="AddDevicesOptionsModal-boxes">
              <div class="AddDevicesOptionsModal-box" @click="remainings.desktop<=0 ? appNotify({message:'Your limit for adding this service has been reached.',type:'danger'}) : $modal.show('PurchaseExtensionModal', { type: 'desktop', });">
                <vb-icon icon="squared-desktop-icon" height="47px" width="47px" />
                <div class="AddDevicesOptionsModal-box-heading">Desktop app</div>
                <div class="AddDevicesOptionsModal-box-limitText">
                  Limit: {{response.extension_detail | get_property('extensionlimit.desktop.allowed',0)}}
                </div>
              </div>
              <div class="AddDevicesOptionsModal-box" @click="remainings.web<=0 ? appNotify({message:'Your limit for adding this service has been reached.',type:'danger'}) : $modal.show('PurchaseExtensionModal', { type: 'web', });">
                <vb-icon icon="squared-web-icon" height="47px" width="47px" />
                <div class="AddDevicesOptionsModal-box-heading">Web extension</div>
                <div class="AddDevicesOptionsModal-box-limitText">
                  Limit: {{response.extension_detail | get_property('extensionlimit.web.allowed',0)}}
                </div>
              </div>
              <div class="AddDevicesOptionsModal-box" @click="!getUserPermissions.mobile_extension ? appNotify({message:'Your package does not support this feature',type:'danger'}) : remainings.mobile<=0 ? appNotify({message:'Your limit for adding this service has been reached.',type:'danger'}) : $modal.show('PurchaseExtensionModal', { type: 'mobile', });">
                <vb-icon icon="squared-mobile-icon" height="47px" width="47px" />
                <div class="AddDevicesOptionsModal-box-heading">Mobile app</div>
                <div class="AddDevicesOptionsModal-box-limitText">
                  Limit: {{response.extension_detail | get_property('extensionlimit.mobile.allowed',0)}}
                </div>
              </div>
              <div class="AddDevicesOptionsModal-box" @click="!getUserPermissions.external_extension ? appNotify({message:'Your package does not support this feature',type:'danger'}) : remainings.external<=0 ? appNotify({message:'Your limit for adding this service has been reached.',type:'danger'}) : $modal.show('PurchaseExtensionModal', { type: 'external', });">
                <vb-icon icon="squared-external-icon" height="47px" width="47px" />
                <div class="AddDevicesOptionsModal-box-heading">External app</div>
                <div class="AddDevicesOptionsModal-box-limitText">
                  Limit: {{response.extension_detail | get_property('extensionlimit.external.allowed',0)}}
                </div>
              </div>
              <div class="AddDevicesOptionsModal-box" @click="remainings.handset<=0 ? appNotify({message:'Your limit for adding this service has been reached.',type:'danger'}) : $modal.show('PurchaseExtensionModal', { type: 'handset', });">
                <vb-icon icon="squared-handset-icon" height="47px" width="47px" />
                <div class="AddDevicesOptionsModal-box-heading">Handset</div>
                <div class="AddDevicesOptionsModal-box-limitText">
                  Limit: {{response.extension_detail | get_property('extensionlimit.handset.allowed',0)}}
                </div>
              </div>
              <div class="AddDevicesOptionsModal-box" @click="remainings.integration<=0 ? appNotify({message:'Your limit for adding this service has been reached.',type:'danger'}) : $modal.show('PurchaseExtensionModal', { type: 'integration', });">
                <vb-icon icon="squared-integration-icon" height="47px" width="47px" />
                <div class="AddDevicesOptionsModal-box-heading">Integration</div>
                <div class="AddDevicesOptionsModal-box-limitText">
                  Limit: {{response.extension_detail | get_property('extensionlimit.integration.allowed',0)}}
                </div>
              </div>
            </div>
          </div>  
        </modal>
        <div class="dialer-flex dialer-dropdown justify-content-end"></div>
        <PurchaseExtensionModal @interface="$emit('updated');fetchExtensionDetailDebounce();" />
        <EditExtensionDetailModal ref="edit-extension-detail-modal" @update-list="$emit('updated');fetchExtensionDetailDebounce();" />
      </div>
    </section>
  </div>
</template>

<script>
import moment from "moment-timezone";
import { mapGetters } from 'vuex';
import _ from 'lodash';
import { VOIP_API } from "@/utils";
import { INTEGRATIONS } from '@/integrations';
import PurchaseExtensionModal from "../Modals/PurchaseExtensionModal.vue";
import EditExtensionDetailModal from '../Modals/EditExtensionDetailModal.vue'
export default {
  name: "DevicesLatest",
  components: {
    PurchaseExtensionModal,
    EditExtensionDetailModal,
  },
  inject: [
    'getProperty',
    'appNotify'
  ],
  props: {
    accountcode: {
      type: String,
    },
  },
  data() {
    return {
      response: {
        extension_detail: {},
      },
      api: {
        extension_detail: this.$helperFunction.apiInstance(),
        update_setting: this.$helperFunction.apiInstance({ send: [] }),
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
      'getIsMobile',
      'getVoipUsersAlises',
    ]),
    registeredExtension(){ return this.getCurrentUser?.sip?.user ?? '' },
    remainings(){
      return {
        desktop: Math.max(0, this.response?.extension_detail?.extensionlimit?.desktop?.remaining ?? 0),
        web: Math.max(0, this.response?.extension_detail?.extensionlimit?.web?.remaining ?? 0),
        handset: Math.max(0, this.response?.extension_detail?.extensionlimit?.handset?.remaining ?? 0),
        external: Math.max(0, this.response?.extension_detail?.extensionlimit?.external?.remaining ?? 0),
        mobile: Math.max(0, this.response?.extension_detail?.extensionlimit?.mobile?.remaining ?? 0),
        integration: Math.max(0, this.response?.extension_detail?.extensionlimit?.integration?.remaining ?? 0),
      }
    },
    extensions(){ return this.response?.extension_detail?.extensions?.map(i=>({
      ...i,
      limit_type: i.type=='MOBILEEXT' ? 'mobile' : 
        i.type=='HEADEXT' ? 'handset' : 
        i.type=='WEBEXT' ? 'web' : 
        i.type=='DESKTOPEXT' ? 'desktop' : 
        i.type=='INTEGRATIONEXT' ? 'integration' : 
        i.type=='EXTERNALEXT' ? 'external' : 
        ''
    })) ?? [] },
    voipaccount(){ return this.accountcode || this.getCurrentUser.account },
    user(){ return this.getVoipUsersAlises[this.voipaccount] ?? {} },
  },
  filters: {
    dateFormate(value){
      const date = moment(value,'MMM DD YYYY, hh:mm:ss a').calendar(null, {
        sameDay: '[Today] hh:mm:ss a',
        nextDay: '[Tomorrow] hh:mm:ss a',
        nextWeek: 'dddd hh:mm:ss a',
        lastDay: '[Yesterday] hh:mm:ss a',
        lastWeek: '[Last] dddd',
        sameElse: 'DD MMM YYYY'
      })
      return date!=='Invalid date' ? date : '';
    },
    extensionIcon(value){
      if(value=='WEBEXT') return 'devices-webExtension-icon'
      else if(value=='DESKTOPEXT') return 'devices-desktopExtension-icon'
      else if(value=='MOBILEEXT') return 'devices-mobileExtension-icon'
      else if(value=='HEADEXT') return 'devices-headExtension-icon'
      // else if(value=='INTEGRATIONEXT') return 'devices-headExtension-icon'
      else if(value=='INTEGRATIONEXT') return 'devices-integrationExtension-icon'
      else if(value=='EXTERNALEXT') return 'devices-externalExtension-icon'
      else return 'devices-webExtension-icon'
    },
    extensionDeviceInfo(value,value_type=''){
      if(!value) return ''
      let device = value;
      if(value.length){
        device = value[value.length-1]
      }
      const fp_data = JSON.parse(device.extra_data)
      const data = fp_data.fp_data
      const is_desktop = data?.platform=='desktop'
      const is_web = data?.platform=='web'
      const is_mobile = data?.platform=='mobile'
      const is_postman = data?.platform=='postman'
      const is_extension = data?.platform=='extension'
      const is_integration = data?.platform=='integration'

      const browser = data?.browser ?? ''
      const name = data?.name ?? ''
      const os = data?.os ?? ''
      const os_version = data?.os_version ?? ''
      if(value_type=='icon'){
        if(is_desktop) return 'trustedDevice-desktop-icon'
        else if(is_mobile) {
          if(os=='android') return 'trustedDevice-android-OSIcon'
          if(os=='ios') return 'trustedDevice-apple-OSIcon'
        } else if(is_web) {
          if(browser=='Chrome') return 'trustedDevice-chrome-icon'
          else if(browser=='Opera') return 'trustedDevice-opera-icon'
          else if(browser=='Edge') return 'trustedDevice-microsoft-icon'
          else if(browser=='Firefox') return 'trustedDevice-firefox-icon'
          else if(browser=='Safari') return 'trustedDevice-safari-icon'
        } else if (is_integration){
          if(name==INTEGRATIONS.platform_instance.hubspot) return 'trustedDevice-chrome-icon'
          else if(name==INTEGRATIONS.platform_instance.zoho) return 'trustedDevice-chrome-icon'
          else if(name==INTEGRATIONS.platform_instance.salesforce) return 'trustedDevice-chrome-icon'
        } else if (is_postman) return 'trustedDevice-chrome-icon'
        else if(is_extension) return 'trustedDevice-chrome-icon'
      } else if (value_type=='osicon') {
        if(os=='android') return 'trustedDevice-androidPhone-icon'
        else if(os=='ios') return 'trustedDevice-IPhone-icon'
        else if(os=='Windows' && os_version=='11') return 'trustedDevice-window11-OSIcon'
        else if(os=='Windows') return 'trustedDevice-window10-OSIcon'
        else if(os=='Linux') return 'trustedDevice-linux-OSIcon'
        else if(os?.includes?.('MAC')) return 'trustedDevice-appleMac-OSIcon'
        else return 'trustedDevice-window10-OSIcon'
      } else if(value_type=='ip'){
        return data?.ip ?? ''
      } else if(value_type=='location'){
        return `${data?.city ?? ''} ${data?.country ?? ''}`
      } else if(value_type=='app-name'){
        if(name) return name
        else if(is_desktop) return 'Desktop app'
        else if(is_mobile) return 'Mobile app'
        else if(is_web) return 'Web app'
        else if(is_postman) return 'Postman app'
        else if(is_extension) return 'Browser extension app'
      }
      return ''
    },
    DeviceInfo(value){
      if(!value) return ''
      let device = value;
      if(value.length){
        device = value[value.length-1]
      }
      const fp_data = JSON.parse(device.extra_data)
      const data = fp_data.fp_data
      const is_desktop = data?.platform=='desktop'
      const is_web = data?.platform=='web'
      const is_mobile = data?.is_mobile
      const is_extension = data?.platform=='extension'
      const is_integration = data?.platform=='integration'
      if(is_desktop){
        return `${ data.browser } ${ data.os }`
      }
      else if(is_web){
        return `${ data.browser } ${ data.os }`
      }
      else if(is_mobile){
        return `${ data.name } ${ data.mobile_type }`
      }
      else if(is_extension){
        return `${ data.browser } ${ data.os }`
      }
      else if(is_integration){
        return `${ data.browser } ${ data.os }`
      }
      else{
        return ''
      }
    },

  },
  methods: {
    async updateSetting(value, account) {
      try {
        this.api.update_setting.send.push(account);
        await VOIP_API.endpoints.users.updateuserdetail({
          field: "extension",
          accountcode: account,
          account: this.voipaccount,
          value: value == "yes" ? "no" : "yes",
        })
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        this.fetchExtensionDetail();
        this.$emit('updated')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if (this.api.update_setting.send.includes(account)){
          this.api.update_setting.send.splice(this.api.update_setting.send.indexOf(account),1)
        }
      }
    },
    fetchExtensionDetailDebounce: _.debounce(function(){
      this.fetchExtensionDetail();
    }, 5*1000),
    async fetchExtensionDetail() {
      try {
        this.api.extension_detail.send = true;
        const res = await VOIP_API.endpoints.extensions.mobileextensions(this.voipaccount)
        this.response.extension_detail = res?.data?.data ?? {};
        this.$refs['edit-extension-detail-modal']?.onListUpdate?.(this.response.extension_detail)
      } finally {
        this.api.extension_detail.send = false;
      }
    },
  },
  mounted() {
    if(this.accountcode){
      this.fetchExtensionDetail();
    }
  },
  activated(){
    this.fetchExtensionDetail();
  },
};
</script>





<style lang="scss">
  .dd-popover{
    left: -170px !important;
    &.fixPosition{
      left: 0px !important;
    }
  }
</style>