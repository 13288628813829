<template>

	<div class="right-calender">
		<div class="top-main-calender scrollow1">
			<h5 class="d-flex align-items-center justify-content-start ml-4 emp-profile-heading">Employee Profle</h5>
			<div class="calender-employee-top mt-5">
				<div class="calender-hrm-height mb-4">
					<div class="calender-large-profile-box">
						<div class="img-wrap-calender" >
							<img src="../../../assets/images/credit-card/hrmprofiletest.png" alt="logo"  class="calender-side-large-img mx-auto d-block "/>
						</div>
					</div>
					<div>
            <label class="calender-form-labels" >Title</label>
            <div class="dd-container">
              <b-dropdown no-caret class="mainBorderfordropi">
                <template #button-content>
                  <div class="button-content-dropi">
                    <div class="dropi-contenti">All time high BTC</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">1 to a 4</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">1 to a 4</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">1 to a 4</b-dropdown-item>
              </b-dropdown>
						</div>
					</div>
					<div>
						<label class="calender-form-labels" >First Name</label>
						<div>
							<b-form-input  placeholder="kendal" class="form-hrm  mt-1"></b-form-input>
						</div>
					</div>
					<div>
						<label class="calender-form-labels">Last Name</label>
						<div>
							<b-form-input placeholder="kendal" class="form-hrm  mt-1"></b-form-input>
						</div>
					</div>
				</div>
			</div>
			<!-- second grey box -->
			<div class="calender-employee-top">
				<div class="calender-hrm-height mb-3">
					<h5 class="calender-page-subheading">Positions</h5>
            <div class="calender-large-position-box">
              <div class="d-flex justify-content-start align-items-center">
                <h4 class="ofc-admin">
                  <svg xmlns="http://www.w3.org/2000/svg" width="8" height="8" viewBox="0 0 8 8" class="calender-ofc-svg">
                    <circle id="Ellipse_21" data-name="Ellipse 21" cx="4" cy="4" r="4" fill="#5576d1"/>
                  </svg>
                  Office administrator
                </h4>
                <button class="custom-hrm-badge">Most recent</button>
              </div>
              <div class="pos-since-date">
                <p class="positions-date-since">Since 15/07/2015</p>
                <p class="positions-date-past">7 years 12 days</p>
              </div>
            </div>
            <div class="d-flex justify-content-end">
              <button class="btn btn-primarymike mt-3">
                
                <b-icon icon="plus" class="primarymike-svg"></b-icon>
                New Position
              </button>
            </div>
				</div>
			</div>
			<!-- second box end's here -->
			<!-- 3rd grey box starts here -->
			<div class="calender-employee-top">
				<div class="calender-hrm-height mb-5">
          <h5 class="calender-page-subheading">Job Information</h5>
          <div>
            <label class="calender-form-labels" for="input-with-list">Work Email</label>
            <div>
              <b-form-input list="input-list"  placeholder="Alisha@vavoip.com" class="work-emailphone-hrm  mt-1" ></b-form-input>
              <div class="svgblock">
                <svg xmlns="http://www.w3.org/2000/svg" width="17.966" height="14.373" viewBox="0 0 17.966 14.373" class="svginput">
                <path id="Icon_material-email" data-name="Icon material-email" d="M19.17,6H4.8A1.794,1.794,0,0,0,3.009,7.8L3,18.576a1.8,1.8,0,0,0,1.8,1.8H19.17a1.8,1.8,0,0,0,1.8-1.8V7.8A1.8,1.8,0,0,0,19.17,6Zm0,3.593-7.187,4.492L4.8,9.593V7.8l7.187,4.492L19.17,7.8Z" transform="translate(-3 -6)" fill="#5576d1"/>
                </svg>
              </div>
            </div>
          </div>
          <div>
            <label class="calender-form-labels" for="input-with-list">Work Phone</label>
            <div>
              <b-form-input list="input-list"  placeholder="03033728896" class="work-emailphone-hrm mt-1" ></b-form-input>
              <div class="svgblock">
                <svg xmlns="http://www.w3.org/2000/svg" width="13.5" height="13.5" viewBox="0 0 13.5 13.5" class="svginput">
                <path id="Icon_material-call" data-name="Icon material-call" d="M4.965,8.092a11.361,11.361,0,0,0,4.943,4.943l1.65-1.65a.746.746,0,0,1,.765-.18A8.555,8.555,0,0,0,15,11.632a.752.752,0,0,1,.75.75V15a.752.752,0,0,1-.75.75A12.749,12.749,0,0,1,2.25,3,.752.752,0,0,1,3,2.25H5.625a.752.752,0,0,1,.75.75A8.52,8.52,0,0,0,6.8,5.677a.753.753,0,0,1-.187.765l-1.65,1.65Z" transform="translate(-2.25 -2.25)" fill="#5576d1"/>
              </svg>
              </div>
            </div>
          </div>
          <div>
            <label class="calender-form-labels" for="datepicker-buttons">Employment start date</label>
            <div class="d-flex justify-content-start align-items-center">
              <b-form-datepicker
                id="datepicker-buttons"
                class="dataformcontrol"
                :date-format-options="{ year: 'numeric', month: 'short', day: '2-digit', weekday: 'short' }"
                locale="en">
              </b-form-datepicker>
              <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="20" viewBox="0 0 17.5 20" class="svg-img-calender-emp">
                <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,18.125A1.875,1.875,0,0,0,1.875,20h13.75A1.875,1.875,0,0,0,17.5,18.125V7.5H0Zm12.5-7.656A.47.47,0,0,1,12.969,10h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,12.969,15h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,7.969,10H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,7.969,15H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,2.969,10H4.531A.47.47,0,0,1,5,10.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,2.969,15H4.531A.47.47,0,0,1,5,15.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469ZM15.625,2.5H13.75V.625A.627.627,0,0,0,13.125,0h-1.25a.627.627,0,0,0-.625.625V2.5h-5V.625A.627.627,0,0,0,5.625,0H4.375A.627.627,0,0,0,3.75.625V2.5H1.875A1.875,1.875,0,0,0,0,4.375V6.25H17.5V4.375A1.875,1.875,0,0,0,15.625,2.5Z" fill="#5576d1"/>
              </svg>
              <h5 class="date-return-date">7 years 11 days</h5>
            </div>
          </div>
          <div>
            <label class="calender-form-labels" >Employment Status</label>
            <div class="dd-container">
              <b-dropdown no-caret class="mainBorderfordropi">
                <template #button-content>
                  <div class="button-content-dropi">
                    <div class="dropi-contenti">Full-time</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
              </b-dropdown>
              </div>
          </div>
          <div>
            <label class="calender-form-labels" >Onboarding mentor</label>
            <div class="dd-container">
              <b-dropdown no-caret class="mainBorderfordropi">
                <template #button-content>
                  <div class="button-content-dropi">
                    <div class="dropi-contenti">Mentor</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div>
            <label class="calender-form-labels" >Direct Manager</label>
            <div class="dd-container">
              <b-dropdown no-caret class="mainBorderfordropi">
                <template #button-content>
                  <div class="button-content-dropi">
                    <div class="d-flex justify-content-between align-items-center">
                        <img src="../../../assets/images/credit-card/hrmprofiletest.png" alt="logo"  class="img-direct-manager-ddshow"/>
                        <h5 class="text-direct-manager-ddshow">Melissa</h5>
                    </div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item-button class="d-flex justify-content-start align-items-center">
                  <img src="../../../assets/images/credit-card/hrmprofiletest.png" alt="logo"  class="img-direct-manager"/>
                  <h5 class="text-direct-manager-ddshow">Melissa</h5>
                </b-dropdown-item-button>
              </b-dropdown>
            </div>
          </div>
          <div>
            <label class="calender-form-labels" >Team</label>
            <div class="dd-container">
              <b-dropdown no-caret class="mainBorderfordropi">
                <template #button-content>
                  <div class="button-content-dropi">
                    <div class="dropi-contenti">Teams</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">Voip Business</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">Wanio</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div>
            <label class="calender-form-labels" >Location</label>
            <div class="dd-container">
              <b-dropdown no-caret class="mainBorderfordropi">
                <template #button-content>
                  <div class="button-content-dropi">
                    <div class="dropi-contenti">London</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">London</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">Wales</b-dropdown-item>
              </b-dropdown>
              </div>
          </div>
          <div>
            <label class="calender-form-labels" >Public holiday group</label>
            <div class="dd-container">
              <b-dropdown no-caret class="mainBorderfordropi">
                <template #button-content>
                  <div class="button-content-dropi">
                    <div class="dropi-contenti">Genral</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">Genral</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">Casual</b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
          <div>
            <label class="calender-form-labels">Employee #</label>
            <div>
              <b-form-input placeholder="25090" class="form-hrm  mt-1"></b-form-input>
            </div>
          </div>
          <div>
            <label class="calender-form-labels" >Linkelln</label>
            <div>
              <b-form-input placeholder="25090" class="form-hrm  mt-1"></b-form-input>
          </div>
          </div>
          <div>
            <label class="calender-form-labels" >Skype</label>
            <div>
              <b-form-input placeholder="25090" class="form-hrm  mt-1"></b-form-input>
            </div>
          </div>
				</div>
			</div>
			<!-- 3rd box ends here -->
			<!-- 4th grey box-->
			<div class="calender-employee-top">
				<div class="calender-hrm-height mb-3">
          <h5 class="calender-page-subheading">Daily Reports</h5>
					<div class=" d-flex justify-content-start align-items-center mt-4">
						<img src="../../../assets/images/credit-card/hrmprofiletest.png" alt="logo"  class="right-side-calender-avatar-img"/>
						<h5 class="right-side-calender-avatar-text">Amber West</h5>
					</div>
					<div class=" mar-top-hrm40 mar-bottom-hrm40">
						<button class="btn btn-primarynewupdated mr-3 space-4-button">Personal Setting</button>
						<button class="btn btn-primarynewupdated mr-3">Access log</button>
						<button class="btn btn-primarynewupdated">Permissions</button>
					</div>
					<div class=" d-flex mar-top-hrm40 mar-bottom-hrm40">
						<button class="btn btn-primarynewupdated1 mr-3">Save</button>
						<button class="btn btn-primarymikedel">Terminate</button>
					</div>
					<div>
						<a href="#" class="direct-report-htm-viewlink">View as this employee</a>
					</div>
				</div>
			</div>
			<!-- 4th box ends here -->
		</div>
	</div>
</template>

<script>

export default {
  name: 'EmployessProfile',
}
</script>

<style>

</style>