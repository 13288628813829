var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-700 CallRecordingEditModal addExtension-voicemailModal addRemove_userModal min-width-50percent-modal",
    attrs: {
      "height": "auto",
      "name": _vm.modalName,
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-settings-main b-0"
  }, [_c('section', {
    staticClass: "dialer-settings-section px-0"
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title"
  }, [_vm._v("Configure call recording")]), _vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1) : _c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center mt-16px"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Call recording settings for (" + _vm._s(_vm.allUserData.user) + ")(" + _vm._s(_vm.allUserData.extn) + ")")]), _c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Set your preference for user-based call recording. When enabled, this extension can record calls on demand or automatically record all calls.")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("On demand call recording")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Capture important conversations whenever you need, with just a single click on the dialer.")])]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "dialer-dropdown dialer-combineDropDown",
    attrs: {
      "title": _vm.getUserPermissions.call_recording ? '' : 'Your package does not support this feature'
    }
  }, [_c('div', {
    class: ['', !_vm.getUserPermissions.call_recording ? 'for-disable' : '']
  }, [_c('b-form-checkbox', {
    ref: "ondeman_check",
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.update.send || !_vm.getUserPermissions.call_recording,
      "checked": _vm.selected.setting != 'off'
    },
    on: {
      "change": function ($event) {
        return _vm.updateSetting($event ? 'ondemand' : 'off');
      }
    }
  })], 1)])]), ['ondemand', 'auto'].includes(_vm.selected.setting) ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Automatically record all calls")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("All calls will be recorded automatically in the background.")])]), _c('div', {
    staticClass: "dialer-dropdown dialer-combineDropDown"
  }, [_c('div', [_c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "auto_check",
    staticClass: "newerSwitch",
    attrs: {
      "title": _vm.getUserPermissions.call_recording ? '' : 'Your package does not support this feature',
      "switch": "",
      "disabled": _vm.api.update.send || !_vm.getUserPermissions.call_recording,
      "checked": _vm.selected.setting == 'auto'
    },
    on: {
      "change": function ($event) {
        return _vm.updateSetting($event ? 'auto' : 'ondemand');
      }
    }
  })], 1)])])])])] : _vm._e()], 2)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', [_c('h3', [_vm._v("Mino")]), _c('p', [_c('b', [_vm._v("Storage:")]), _vm._v(" " + _vm._s(_vm._f("format_bytes")(_vm._f("mbToBytes")(_vm._f("get_property")(_vm.response.storage, 'accountstorage.mino', 0)))))]), _c('p', [_c('b', [_vm._v("Files:")]), _vm._v(" " + _vm._s(_vm._f("get_property")(_vm.response.storage, 'accountstorage.file_count', 0)))])]), _c('div', [_c('h3', [_vm._v("Centeral")]), _c('p', [_c('b', [_vm._v("Storage:")]), _vm._v(" " + _vm._s(_vm._f("format_bytes")(_vm._f("mbToBytes")(_vm._f("get_property")(_vm.response.storage, 'accountstorage.central_storage', 0)))))]), _c('p', [_c('b', [_vm._v("Files:")]), _vm._v(" " + _vm._s(_vm._f("get_property")(_vm.response.storage, 'accountstorage.central_files', 0)))])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }