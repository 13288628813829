<template>
	<div class="right-calender">
		<div class="top-main-calender scrollow1">
			<section class="sec-common">
				<div class="d-flex justify-content-between align-items-center">
					<div>
						<h5 class=" emp-profile-heading">Reports</h5>
					</div>
				</div>
				<div class="mar-top-hrm60 mar-bottom-hrm70">
					<h5 class="reports-last-tweleve-month-hdng">Last 12 Months</h5>
				</div>
				<div class="mar-top-hrm25">
					<div class="hrm-bg-accordian flex-column justify-content-start align-item-start">
						<div class="d-flex justify-content-between w-100 align-items-center">
							<h5 class="accordian-togg-text">Holiday</h5>
							<b-button v-b-toggle.holidnl-1 variant="primary" class="togg-btn-report">
								<svg xmlns="http://www.w3.org/2000/svg" width="17.46" height="9.926" viewBox="0 0 17.46 9.926" class="svgtoggle">
									<g id="Group_15893" data-name="Group 15893" transform="translate(-54.046 -5.227)">
										<g id="Group_15891" data-name="Group 15891" transform="translate(55.46 6.64)">
										<path id="Path_41822" data-name="Path 41822" d="M13996.459,9709.063l7.113,6.908" transform="translate(-13996.459 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
										<path id="Path_41823" data-name="Path 41823" d="M14003.77,9709.063l-7.311,7.1" transform="translate(-13989.138 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
										</g>
									</g>
								</svg>
							</b-button>
						</div>
						<b-collapse id="holidnl-1" class="w-100">
							<b-card class="report-cards">
								<p class="report-card-text">Working hours per day: 8.0</p>
								<p class="report-card-text">Annually allowed: 20 days</p>
								<p class="report-card-text">Currently available: 8.87 days</p>
							</b-card>
							<h5 class="accordian-togg-text">Applied transactions</h5>
              <vb-table :tableDesign="1" class="reports-table-width">
                <template slot="header">
                  <h5 class="commonth emp-reports-firsttd col-left">created</h5>
                  <h5 class="commonth emp-reports-secondtd">created by</h5>
                  <h5 class="commonth emp-reports-thirdtd">time off requested</h5>
                  <h5 class="commonth emp-reports-fourtd">note</h5>
                  <h5 class="commonth emp-reports-fivetd">action</h5>
                  <h5 class="commonth emp-reports-sixtd ">days</h5>
                </template>
                <template slot="body">
                  <tr>
                    <td class="emp-reports-firsttd">
                      02/03/2022 - "Current"
                    </td>
                    <td class="emp-reports-secondtd">
                      System
                    </td>
                    <td class="emp-reports-thirdtd ">
                      N/A
                    </td>
                    <td class="emp-reports-fourtd">
                      Yearly accural
                    </td>
                    <td class="emp-reports-fivetd">
                      increase
                    </td>
                    <td class="emp-reports-sixtd ">
                      8.87 
                    </td>
                  </tr>
                </template>
              </vb-table>
						</b-collapse>
					</div>
				</div>
				<div class="mar-top-hrm25">
					<div class="hrm-bg-accordian flex-column justify-content-start align-item-start">
						<div class="d-flex justify-content-between w-100 align-items-center">
							<h5 class="accordian-togg-text">Holiday-UK</h5>
							<b-button v-b-toggle.Sickday-1 variant="primary" class="togg-btn-report">
								<svg xmlns="http://www.w3.org/2000/svg" width="17.46" height="9.926" viewBox="0 0 17.46 9.926" class="svgtoggle">
									<g id="Group_15893" data-name="Group 15893" transform="translate(-54.046 -5.227)">
										<g id="Group_15891" data-name="Group 15891" transform="translate(55.46 6.64)">
										<path id="Path_41822" data-name="Path 41822" d="M13996.459,9709.063l7.113,6.908" transform="translate(-13996.459 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
										<path id="Path_41823" data-name="Path 41823" d="M14003.77,9709.063l-7.311,7.1" transform="translate(-13989.138 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
										</g>
									</g>
								</svg>
							</b-button>
						</div>
						<b-collapse id="Sickday-1" class="w-100">
							<b-card class="report-cards">
								<p class="report-card-text">Working hours per day: 8.0</p>
								<p class="report-card-text">Annually allowed: 20 days</p>
								<p class="report-card-text">Currently available: 8.87 days</p>
							</b-card>
							<h5 class="accordian-togg-text mar-top-hrm30 mar-bottom-hrm10">Applied transactions</h5>
							<vb-table :tableDesign="1" class="reports-table-width">
								<template slot="header">
									<h5 class="commonth emp-reports-firsttd col-left">created</h5>
									<h5 class="commonth emp-reports-secondtd">created by</h5>
									<h5 class="commonth emp-reports-thirdtd">time off requested</h5>
									<h5 class="commonth emp-reports-fourtd">note</h5>
									<h5 class="commonth emp-reports-fivetd">action</h5>
									<h5 class="commonth emp-reports-sixtd ">days</h5>
								</template>
								<template slot="body">
									<tr>
                    <td class="emp-reports-firsttd">
                      02/03/2022 - "Current"
                    </td>
                    <td class="emp-reports-secondtd">
                      System
                    </td>
                    <td class="emp-reports-thirdtd ">
                      N/A
                    </td>
                    <td class="emp-reports-fourtd">
                      Yearly accural
                    </td>
                    <td class="emp-reports-fivetd">
                      increase
                    </td>
                    <td class="emp-reports-sixtd ">
                      8.87 
                    </td>
                  </tr>
								</template>
							</vb-table>
						</b-collapse>
					</div>
				</div>
				<div class="mar-top-hrm25">
					<div class="hrm-bg-accordian flex-column justify-content-start align-item-start">
						<div class="d-flex justify-content-between w-100 align-items-center">
							<h5 class="accordian-togg-text">Holiday-UK</h5>
							<b-button v-b-toggle.holiduk-1 variant="primary" class="togg-btn-report">
								<svg xmlns="http://www.w3.org/2000/svg" width="17.46" height="9.926" viewBox="0 0 17.46 9.926" class="svgtoggle">
									<g id="Group_15893" data-name="Group 15893" transform="translate(-54.046 -5.227)">
										<g id="Group_15891" data-name="Group 15891" transform="translate(55.46 6.64)">
										<path id="Path_41822" data-name="Path 41822" d="M13996.459,9709.063l7.113,6.908" transform="translate(-13996.459 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
										<path id="Path_41823" data-name="Path 41823" d="M14003.77,9709.063l-7.311,7.1" transform="translate(-13989.138 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
										</g>
									</g>
								</svg>
							</b-button>
						</div>
						<b-collapse id="holiduk-1" class="w-100">
							<b-card class="report-cards">
								<p class="report-card-text">Working hours per day: 8.0</p>
								<p class="report-card-text">Annually allowed: 20 days</p>
								<p class="report-card-text">Currently available: 8.87 days</p>
							</b-card>
							<h5 class="accordian-togg-text">Holiday</h5>
              <vb-table :tableDesign="1" class="reports-table-width">
								<template slot="header">
									<h5 class="commonth emp-reports-firsttd col-left">created</h5>
									<h5 class="commonth emp-reports-secondtd">created by</h5>
									<h5 class="commonth emp-reports-thirdtd">time off requested</h5>
									<h5 class="commonth emp-reports-fourtd">note</h5>
									<h5 class="commonth emp-reports-fivetd">action</h5>
									<h5 class="commonth emp-reports-sixtd ">days</h5>
								</template>
								<template slot="body">
									<tr>
                    <td class="emp-reports-firsttd">
                      02/03/2022 - "Current"
                    </td>
                    <td class="emp-reports-secondtd">
                      System
                    </td>
                    <td class="emp-reports-thirdtd ">
                      N/A
                    </td>
                    <td class="emp-reports-fourtd">
                      Yearly accural
                    </td>
                    <td class="emp-reports-fivetd">
                      increase
                    </td>
                    <td class="emp-reports-sixtd ">
                      8.87 
                    </td>
                  </tr>
								</template>
							</vb-table>
						</b-collapse>
					</div>
				</div>
				<div class="mar-top-hrm25">
					<div class="hrm-bg-accordian flex-column justify-content-start align-item-start">
						<div class="d-flex justify-content-between w-100 align-items-center">
							<h5 class="accordian-togg-text">Business trip</h5>
							<b-button v-b-toggle.btrip-1 variant="primary" class="togg-btn-report">
								<svg xmlns="http://www.w3.org/2000/svg" width="17.46" height="9.926" viewBox="0 0 17.46 9.926" class="svgtoggle">
									<g id="Group_15893" data-name="Group 15893" transform="translate(-54.046 -5.227)">
										<g id="Group_15891" data-name="Group 15891" transform="translate(55.46 6.64)">
										<path id="Path_41822" data-name="Path 41822" d="M13996.459,9709.063l7.113,6.908" transform="translate(-13996.459 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
										<path id="Path_41823" data-name="Path 41823" d="M14003.77,9709.063l-7.311,7.1" transform="translate(-13989.138 -9709.063)" fill="none" stroke="#828282" stroke-linecap="round" stroke-width="2"/>
										</g>
									</g>
								</svg>
							</b-button>
						</div>
						<b-collapse id="btrip-1" class="w-100">
							<b-card class="report-cards">
								<p class="report-card-text">Working hours per day: 8.0</p>
								<p class="report-card-text">Annually allowed: 20 days</p>
								<p class="report-card-text">Currently available: 8.87 days</p>
							</b-card>
							<h5 class="accordian-togg-text">Holiday</h5>
              <vb-table :tableDesign="1" class="reports-table-width">
								<template slot="header">
									<h5 class="commonth emp-reports-firsttd col-left">created</h5>
									<h5 class="commonth emp-reports-secondtd">created by</h5>
									<h5 class="commonth emp-reports-thirdtd">time off requested</h5>
									<h5 class="commonth emp-reports-fourtd">note</h5>
									<h5 class="commonth emp-reports-fivetd">action</h5>
									<h5 class="commonth emp-reports-sixtd ">days</h5>
								</template>
								<template slot="body">
									<tr>
                    <td class="emp-reports-firsttd">
                      02/03/2022 - "Current"
                    </td>
                    <td class="emp-reports-secondtd">
                      System
                    </td>
                    <td class="emp-reports-thirdtd ">
                      N/A
                    </td>
                    <td class="emp-reports-fourtd">
                      Yearly accural
                    </td>
                    <td class="emp-reports-fivetd">
                      increase
                    </td>
                    <td class="emp-reports-sixtd ">
                      8.87 
                    </td>
                  </tr>
								</template>
							</vb-table>
						</b-collapse>
					</div>
				</div>
				
			</section>
		</div>
	
	</div>
</template>

<script>

export default {
  name: 'ReportsHRM',
  data() {
    return {
      visible: true,
    };
  },
}
</script>

<style>

</style>