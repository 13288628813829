var render = function render(){
  var _vm$user;
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.remove.send ? '' : _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit User")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Extension")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_extension.value,
      expression: "forms.update_extension.value"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Name",
      "type": "text",
      "maxlength": "4",
      "oninput": "this.value = this.value.replace(/[^0-9]/gi, '')",
      "disabled": _vm.api.remove.send || _vm.api.update_extension.send
    },
    domProps: {
      "value": _vm.forms.update_extension.value
    },
    on: {
      "change": function ($event) {
        return _vm.updateExtension();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_extension, "value", $event.target.value);
      }
    }
  }), _vm.forms.update_extension.submitted && _vm.$v.forms.update_extension.value.$invalid || _vm.api.update_extension.validation_errors.value ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_extension.value.required ? _c('span', [_vm._v("* Extension is required")]) : !_vm.$v.forms.update_extension.value.numeric ? _c('span', [_vm._v("* Extension is should be numeric")]) : !_vm.$v.forms.update_extension.value.valid ? _c('span', [_vm._v("* Extension is valid")]) : _vm._e(), _vm.api.update_extension.error_message ? _c('span', [_vm._v(_vm._s(_vm.api.update_extension.error_message))]) : _vm._e(), _vm._l(_vm.api.update_extension.validation_errors.value, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Display Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_displayname.value,
      expression: "forms.update_displayname.value"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Name",
      "type": "text",
      "disabled": _vm.api.remove.send || _vm.api.update_displayname.send
    },
    domProps: {
      "value": _vm.forms.update_displayname.value
    },
    on: {
      "change": function ($event) {
        return _vm.updateDisplayName();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_displayname, "value", $event.target.value);
      }
    }
  }), _vm.forms.update_displayname.submitted && _vm.$v.forms.update_displayname.value.$invalid || _vm.api.update_displayname.validation_errors.value ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_displayname.value.required ? _c('span', [_vm._v("* Display Name is required")]) : _vm._e(), _vm.api.update_displayname.error_message ? _c('span', [_vm._v(_vm._s(_vm.api.update_displayname.error_message))]) : _vm._e(), _vm._l(_vm.api.update_displayname.validation_errors.value, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _vm.isInvitedUser ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_email.value,
      expression: "forms.update_email.value"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Name",
      "type": "text",
      "disabled": _vm.api.remove.send || _vm.api.update_email.send
    },
    domProps: {
      "value": _vm.forms.update_email.value
    },
    on: {
      "change": function ($event) {
        return _vm.updateEmail();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_email, "value", $event.target.value);
      }
    }
  }), _vm.forms.update_email.submitted && _vm.$v.forms.update_email.value.$invalid || _vm.api.update_email.validation_errors.value ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_email.value.email ? _c('span', [_vm._v("* Email is invalid")]) : _vm._e(), _vm.api.update_email.error_message ? _c('span', [_vm._v(_vm._s(_vm.api.update_email.error_message))]) : _vm._e(), _vm._l(_vm.api.update_email.validation_errors.value, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]) : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v(_vm._s(_vm.isMainUser ? 'Email' : 'Username'))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_username.value,
      expression: "forms.update_username.value"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Name",
      "type": "text",
      "disabled": _vm.api.remove.send || _vm.api.update_username.send
    },
    domProps: {
      "value": _vm.forms.update_username.value
    },
    on: {
      "change": function ($event) {
        return _vm.updateUsername();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_username, "value", $event.target.value);
      }
    }
  }), _vm.forms.update_username.submitted && _vm.$v.forms.update_username.value.$invalid || _vm.api.update_username.validation_errors.value || _vm.api.update_username.error_message ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_username.value.required ? _c('span', [_vm._v("* " + _vm._s(_vm.isMainUser ? 'Email' : 'Username') + " is required")]) : !_vm.$v.forms.update_username.value.invalid ? _c('span', [_vm._v("* " + _vm._s(_vm.isMainUser ? 'Email' : 'Username') + " is invalid")]) : !_vm.$v.forms.update_username.value.maxLength ? _c('span', [_vm._v(" * " + _vm._s(_vm.isMainUser ? 'Email' : 'Username') + " is maximum " + _vm._s(_vm.$v.forms.update_username.value.$params.maxLength.max) + " character ")]) : _vm._e(), _vm.api.update_username.error_message ? _c('span', [_vm._v(_vm._s(_vm.api.update_username.error_message))]) : _vm._e(), _vm._l(_vm.api.update_username.validation_errors.value, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), !_vm.isInvitedUser ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Password")]), (_vm.conditions.password_show ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_password.value,
      expression: "forms.update_password.value"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "********",
      "disabled": _vm.api.remove.send || _vm.api.update_password.send,
      "readonly": true,
      "onfocus": "this.removeAttribute('readonly')",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.forms.update_password.value) ? _vm._i(_vm.forms.update_password.value, null) > -1 : _vm.forms.update_password.value
    },
    on: {
      "change": [function ($event) {
        var $$a = _vm.forms.update_password.value,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.forms.update_password, "value", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.forms.update_password, "value", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.forms.update_password, "value", $$c);
        }
      }, function ($event) {
        return _vm.updatePassword();
      }]
    }
  }) : (_vm.conditions.password_show ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_password.value,
      expression: "forms.update_password.value"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "********",
      "disabled": _vm.api.remove.send || _vm.api.update_password.send,
      "readonly": true,
      "onfocus": "this.removeAttribute('readonly')",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.forms.update_password.value, null)
    },
    on: {
      "change": [function ($event) {
        return _vm.$set(_vm.forms.update_password, "value", null);
      }, function ($event) {
        return _vm.updatePassword();
      }]
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_password.value,
      expression: "forms.update_password.value"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "********",
      "disabled": _vm.api.remove.send || _vm.api.update_password.send,
      "readonly": true,
      "onfocus": "this.removeAttribute('readonly')",
      "type": _vm.conditions.password_show ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.forms.update_password.value
    },
    on: {
      "change": function ($event) {
        return _vm.updatePassword();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_password, "value", $event.target.value);
      }
    }
  }), _c('b-icon', {
    staticClass: "dialer-showPassword-icon cursor_pointer",
    attrs: {
      "icon": _vm.conditions.password_show ? 'eye-slash' : 'eye'
    },
    on: {
      "click": function ($event) {
        _vm.api.update_password.send ? '' : _vm.conditions.password_show = !_vm.conditions.password_show;
      }
    }
  }), _vm.forms.update_password.submitted && _vm.$v.forms.update_password.value.$invalid || _vm.api.update_password.validation_errors.value ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_password.value.required ? _c('span', [_vm._v("* Password is required")]) : !_vm.$v.forms.update_password.value.minLength ? _c('span', [_vm._v(" * Password is minimum " + _vm._s(_vm.$v.forms.update_password.value.$params.minLength.min) + " ")]) : _vm._e(), _vm.api.update_password.error_message ? _c('span', [_vm._v(_vm._s(_vm.api.update_password.error_message))]) : _vm._e(), _vm._l(_vm.api.update_password.validation_errors.value, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1)]) : _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.remove.send ? '' : _vm.requestForResetPassword();
      }
    }
  }, [_vm._v(" Request For Update Password "), _vm.api.request_reset.error_message ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v(_vm._s(_vm.api.request_reset.error_message))])]) : _vm._e()])])])])])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit User")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "profile-image-section-container forIos mt-32px"
  }, [_c('div', {
    staticClass: "profile-image-section",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('EditUserProfileImageUploader', {
          type: 'USER',
          accountcode: _vm.accountcode,
          current_image: _vm.userImage
        });
      }
    }
  }, [_c('div', {
    staticClass: "profile-img"
  }, [_c('img', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    },
    attrs: {
      "id": "user-profile-image-updated",
      "src": _vm.userImage,
      "alt": _vm.userDisplayName
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _c('div', {
    staticClass: "BiggestMainHeading mt-22px"
  }, [_vm._v(_vm._s(_vm.userDisplayName) + " (" + _vm._s(((_vm$user = _vm.user) === null || _vm$user === void 0 ? void 0 : _vm$user.extn) || '') + ")")]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.remove.send ? '' : _vm.$modal.show(`${_vm._uid}-AssignNumberModal`, {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("User Numbers")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.dids.length))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.remove.send ? '' : _vm.$modal.show('EditUserAssignTeamModal', {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("User Teams")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.teams.length))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallsSetting', {
          accountcode: _vm.accountcode,
          user: _vm.response.user_detail
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Calls Setting")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('PhoneSetting', {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Phone Setting")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('VoicemailSetting', {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Voicemail Setting")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm.subscriptionPlan ? _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.remove.send ? '' : _vm.$modal.show('UserPrefix', {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Call Prefix")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm._f("prefixLabel")(_vm.response.user_detail)))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.remove.send ? '' : _vm.$modal.show('EditUserEmailGroupsModal', {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Email Groups")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.groups.length))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Extension Limits")])])]), !_vm.isMainUser ? _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Set as Admin")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "role",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.update_role.send,
      "checked": _vm.isAdmin
    },
    on: {
      "change": function ($event) {
        return _vm.updateRole($event);
      }
    }
  })], 1)])]) : _vm._e(), !_vm.isMainUser ? _c('button', {
    staticClass: "IosFullWidthButton forRemove mb-16px",
    attrs: {
      "disabled": _vm.api.remove.send
    },
    on: {
      "click": function ($event) {
        return _vm.remove();
      }
    }
  }, [_vm.api.remove.send ? _c('vb-spinner') : _vm._e(), [_vm._v("Delete")]], 2) : _vm._e()])])], _c('ProfileImageUploaderModal', {
    attrs: {
      "modalName": 'EditUserProfileImageUploader'
    },
    on: {
      "image-uploaded": function ($event) {
        return _vm.$emit('update-list');
      }
    }
  }), _c('AssignNumbersModal', {
    attrs: {
      "modalName": `${_vm._uid}-AssignNumberModal`
    },
    on: {
      "added": function ($event) {
        return _vm.$emit('update-list');
      }
    }
  }), _c('AssignTeamsModal', {
    attrs: {
      "modalName": 'EditUserAssignTeamModal'
    },
    on: {
      "added": function ($event) {
        _vm.$emit('update-list');
        _vm.fetchUserDetail(false);
      }
    }
  }), _c('EmailGroupsModal', {
    attrs: {
      "modalName": 'EditUserEmailGroupsModal'
    },
    on: {
      "added": function ($event) {
        return _vm.$emit('update-list');
      }
    }
  }), _c('UserPrefixModal'), _c('CallsSettingModal'), _c('PhoneSettingModal'), _c('VoicemailSettingModal')], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }