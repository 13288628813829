<template>
  <modal class="dialer_animation right_side_popup addCardModal SetCardPriorityModal" width="40%" height="auto" :scrollable="true" :name="modalName" :clickToClose="false" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    <div class="dialer-box mb-4 table">
      <div class="dialer-edit-header mt-3">
        <h2 class="dialer-edit-title mb-0">Select card</h2>
        <div class="dialer-edit-actions">
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else class="" @click="$modal.hide(modalName)">
            
            <button type="button" class="newCloseButton"> 
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </a>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-4 mb-3">
        <button class="dialer-button dialer-button-primary " @click="$modal.show('PaymentDetailModal')">
          <b-icon icon="plus" class="mr-3" scale="2"/>
          Add new card
        </button>
      </div>
      <div v-if="[1,0].includes(conditions.card_select_show)" class="dialer-box alt p-4">
        <div class="dialer-box-title">Select primary card</div>
        <template v-for="card in availableprimarycards">
          <b-form-checkbox 
            :key="card.real_id"
            :ref="`make_primary_${card.real_id}`"
            :aria-disabled="api_sent.set || !!api_sent.primary"
            :disabled="api_sent.set || !!api_sent.primary || api_sent.secondary==card.real_id || selected.primary==card.real_id"
            :checked="selected.primary==card.real_id"
            @change="$event ? makePrimary(card.real_id) : ''"
            class="dialer_full_width_checkbox border-b circle_btn_checkbox circle_btn_onRight mt-4 big-circle-checkbox3 makeCheckBoxesSquare"
          >
            <div class="dialer-flex justify-content-start align-items-start mr-3 cursor_pointer">
              <img :src="require('@/assets/images/visa.svg')" class="dialer-payment-logo" />
              <div class="ml-2">
                <div class="dialer-box-header d-flex align-items-center mb-0">
                  {{ card.label }}
                  <div class="ml-2 py-1 px-3 card-tag bg-primary" v-if="selected.primary==card.real_id">Primary</div>
                </div>
                <div class="dialer-box-text d-flex flex-column">
                  <span class="line-height-1" style="letter-spacing:1px;">xxxx xxxx xxxx {{ card.digits }}</span>
                  <span class="line-height-1 mt-1">Expiration date: {{ `${card.expiry_month}/${card.expiry_year}` }}</span>
                </div>
              </div>
            </div>
          </b-form-checkbox>
          <div class="trashButtonContainer" :key="`delete_${card.real_id}`">
            <vb-spinner v-if="api_sent.delete==card.real_id" />
            <button v-else>
              <b-icon icon="trash" variant="primary" @click="deleteCard(card.real_id)" />
            </button>
          </div>
        </template>
        <vb-no-record v-if="isEmpty(availableprimarycards)" :text="api_sent.cards ? '' : 'Sorry, No Data Found'" :design="3">
          <vb-loading v-if="api_sent.cards" slot="loading" />
        </vb-no-record>
      </div>
      <div v-if="[2,0].includes(conditions.card_select_show)" class="dialer-box alt p-4">
        <div class="dialer-box-title">Select secondary card</div>
        <template v-for="card in availablesecondarycards">
          <b-form-checkbox 
            :key="card.real_id"
            :ref="`make_secondary_${card.real_id}`"
            :aria-disabled="api_sent.set || !!api_sent.secondary"
            :disabled="api_sent.set || !!api_sent.secondary || api_sent.primary==card.real_id || selected.secondary==card.real_id"
            :checked="selected.secondary==card.real_id"
            @change="$event ? makeSecondary(card.real_id) : ''"
            class="dialer_full_width_checkbox border-b circle_btn_checkbox circle_btn_onRight mt-4 big-circle-checkbox3 makeCheckBoxesSquare"
          >
            <div class="dialer-flex justify-content-start align-items-start mr-3 cursor_pointer">
              <img :src="require('@/assets/images/visa.svg')" class="dialer-payment-logo" />
              <div class="ml-2">
                <div class="dialer-box-header d-flex align-items-center mb-0">
                  {{ card.label }}
                  <div v-if="selected.secondary==card.real_id" class="ml-2 py-1 px-3 card-tag bg-primary">Secondary</div>
                </div>
                <div class="dialer-box-text d-flex flex-column">
                  <span class="line-height-1" style="letter-spacing:1px;">xxxx xxxx xxxx {{ card.digits }}</span>
                  <span class="line-height-1 mt-1">Expiration date: {{ `${card.expiry_month}/${card.expiry_year}` }}</span>
                </div>
              </div>
            </div>
          </b-form-checkbox>
          <div class="trashButtonContainer" :key="`delete_${card.real_id}`">
            <vb-spinner v-if="api_sent.delete==card.real_id" />
            <button v-else>
              <b-icon icon="trash" variant="primary" @click="deleteCard(card.real_id)" />
            </button>
          </div>
        </template>
        <vb-no-record v-if="isEmpty(availablesecondarycards)" :text="api_sent.cards ? '' : 'Sorry, no data found'" :design="3">
          <vb-loading v-if="api_sent.cards" slot="loading" />
        </vb-no-record>
      </div>
      <PaymentDetailModal />
    </div>
  </modal>
</template>

<script>
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
// import { centrifugo } from '../../Centrifuge';
import {  
  VOIP_API 
} from '../../utils';
import PaymentDetailModal from './PaymentDetailModal.vue'
export default {
  name: 'SetCardPriorityModal',
  components: {
    PaymentDetailModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'SetCardPriorityModal',
    },
  },
  data(){
    return {
      response: {
        cards: []
      },
      api_sent: {
        cards: false,
        set: false,
        primary: '',
        secondary: '',
        delete: '',
      },
      selected: {
        primary: '',
        secondary: '',
      },
      conditions: {
        card_select_show: 0, // 0: both, 1: primary, 2: secondary
      },
    }
  },
  inject:['isEmpty','appNotify'],
  computed: {
    availableprimarycards(){
      const cards = this.response.cards
      const secondary = this.selected.secondary
      return cards.filter(item=>item.real_id!=secondary)
    },
    availablesecondarycards(){
      const cards = this.response.cards
      const primary = this.selected.primary
      return cards.filter(item=>item.real_id!=primary)
    },
  },
  methods: {
    onBeforeOpen(event){
      this.fetchCards()
      if(event?.params?.is_primary) this.conditions.card_select_show=1
      if(event?.params?.is_secondary) this.conditions.card_select_show=2
      AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
      // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
      // centrifugo.mothership.on(centrifugo.mothership.events.message,this.onNotification)
    },
    onBeforeClose(){
      this.response.cards=[]
      this.selected.primary=''
      this.selected.secondary=''
      this.conditions.card_select_show=0
      AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
      // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
      // centrifugo.mothership.off(centrifugo.mothership.events.message,this.onNotification)
    },
    fetchCards(){
      let vm = this
      if(vm.api_sent.cards) return;
      vm.api_sent.cards=true
      VOIP_API.endpoints.billing_api.getcreditcards({  
        uid: vm.$store.getters.getCurrentUser?.uid 
      })
      .then(({ data: { data: cards } }) => {
        const primary = cards.find(item=>item.primary=='yes')
        const secondary = cards.find(item=>item.secondary=='yes')
        vm.selected.primary = primary?.real_id ?? ''
        vm.selected.secondary = secondary?.real_id ?? ''
        vm.response.cards = cards;
      })
      .finally(() => {
        vm.api_sent.cards = false;
      });
    },
    deleteCard(card_real_id){
      let vm = this
      if(vm.api_sent.delete || !card_real_id) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this Card`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api_sent.delete=card_real_id
            VOIP_API.endpoints.billing_api.deletecreditcard({
              id: card_real_id,
              accountcode: vm.$store.getters.getCurrentUser?.account,
              uid: vm.$store.getters.getCurrentUser?.uid,
            })
            .then(()=>{
              vm.fetchCards()
              vm.appNotify({
                message: 'Successfully Updated!',
                type: 'success',
              })
              vm.$emit('priority-set')
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api_sent.delete=''
            })
          }
        },
      });
    },
    makePrimary(card_real_id){
      let vm = this
      if(vm.api_sent.primary || !card_real_id) return;
      vm.api_sent.primary=card_real_id
      VOIP_API.endpoints.billing_api.makedefaultcreditcard({
        id: card_real_id,
        accountcode: vm.$store.getters.getCurrentUser?.account,
        uid: vm.$store.getters.getCurrentUser?.uid,
        flag: 'primary'
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.selected.primary=card_real_id
        vm.$emit('priority-set')
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        const toggle_ref = vm.$refs[`make_primary_${card_real_id}`]?.[0]
        if(toggle_ref) toggle_ref.localChecked=vm.selected.primary==card_real_id
        vm.api_sent.primary=''
      })
    },
    makeSecondary(card_real_id){
      let vm = this
      if(vm.api_sent.secondary || !card_real_id) return;
      vm.api_sent.secondary=card_real_id
      VOIP_API.endpoints.billing_api.makedefaultcreditcard({
        id: card_real_id,
        accountcode: vm.$store.getters.getCurrentUser?.account,
        uid: vm.$store.getters.getCurrentUser?.uid,
        flag: 'secondary'
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.$emit('priority-set')
        vm.selected.secondary=card_real_id
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        const toggle_ref = vm.$refs[`make_secondary_${card_real_id}`]?.[0]
        if(toggle_ref) toggle_ref.localChecked=(vm.selected.secondary==card_real_id)
        vm.api_sent.secondary=''
      })
    },
    onNotification(message) {
      if (message.data.action == "add" && message.data.section == "plan") {
        this.fetchCards();
      }
      if(message.data.action == "new_card_add" && message.data.section == "card" && message.data.flag == "new_card"){
        this.fetchCards();
      }
    },
  },
}
</script>

<style>

</style>