<template>
  <div>
    <div class="mainHeading withButton">
      Time off Reports
      <button @click="$modal.show('GenerateTimeOffReportsModal')" class="dialer-button dialer-button-primary mr-0">
        Genrate
      </button>
    </div>
    <div class="mainDescription mb-4">
      <br/>
    </div>
    <!-- <div class="dialer-input-field input-consistent-inner-shadows sm-mar-left seachBox">
      <b-input type="text" placeholder="Search user" v-model.lazy="filter.time_off_reports.search" />
    </div> -->
    <div class="TimeOffReportsTable mt-32px">
      <div class="HrmTableHead-container">
        <div class="HrmTableHead one">Name</div>
        <div class="HrmTableHead two">employee #</div>
        <div class="HrmTableHead three">user #</div>
        <div class="HrmTableHead four">policy</div>
        <div class="HrmTableHead five">Start date</div>
        <div class="HrmTableHead six">end date</div>
        <div class="HrmTableHead seven">Hours used</div>
        <div class="HrmTableHead eight">Actions</div>
      </div>
      <vb-table class="HrmTable" :isListEmpty="isEmpty(filterTimeOffReports)" 
        :listLength="filterTimeOffReports.length" 
        :loading="api.time_off_reports.send"
      >
        <template #body="{ start, end }">
          <tr class="dialer-row-select" v-for="(data, index) in filterTimeOffReports" v-show="index >= start && index <= end" :key="data.user">
            <td class="dialer-row-title one">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">{{ data.name }}</div>
              </div>
            </td>
            <td class="dialer-row-title two">{{ data.employee }}</td>
            <td class="dialer-row-title three">{{ data.user }}</td>
            <td class="dialer-row-title four">
              <div class="circleWithText">
                <div class="circleContainer" style="background-color:#D5761B"></div>
                <div class="circleWithText-text" style="color:#D5761B">{{ data.policy }}</div>
              </div>
            </td>
            <td class="dialer-row-title five">{{ data.startDate }}</td>
            <td class="dialer-row-title six">{{ data.endDate }}</td>
            <td class="dialer-row-title seven">{{ data.hoursUsed }}</td>
            <td class="dialer-row-title eight">
              <b-dropdown no-caret right class="ActionsDD">
                <template #button-content>
                  <div class="button-content">
                    <b-icon class="downIcon" icon="three-dots-vertical" />
                  </div>
                </template>
                <b-dropdown-item >
                  {{ data.actions }}
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    <GenerateTimeOffReportsModal />
  </div>
</template>

<script>
import GenerateTimeOffReportsModal from "@/components/Modals/HRM/reports/GenerateTimeOffReportsModal.vue";
import { $fn } from "@/utils";
import { HRM } from '@/data'
export default {
  name: 'TimeOffReports',
  components: {
    GenerateTimeOffReportsModal,
  },
  inject:['isEmpty'],
  data(){
    return {
      response: {
        time_off_reports: [],
      },
      filter: {
        time_off_reports: {
          search: '',
          direction: '',
        }
      },
      api: {
        time_off_reports: this.$helperFunction.apiInstance({ error_message: true }),
      },
    }
  },
  computed:{
    filterTimeOffReports(){
      const search = this.filter.time_off_reports.search.trim().toLowerCase()
      // const direction = this.filter.rules.direction
      const rules = this.response.time_off_reports
      const filter_time_off_reports = rules.filter(item=>
        `${(item.name || '').replace(/-/g,'')} ${item.employee} ${item.user} ${item.policy}`.trim().toLowerCase().includes(search)
      )
      return filter_time_off_reports
    }
  },
  methods: {
    fetchTimeOffReports(){
      let vm = this;
      if(vm.api.time_off_reports.send) return;
      vm.api.time_off_reports.send=true
      $fn.sleep(2 * 1000)
      .then(()=>{
        // console.log("HRM Data", HRM.timeoffreports)
        vm.response.time_off_reports = HRM.timeoffreports
      })
      .catch((/* err */)=>{
        // console.log("error", err)
      }).finally(()=> { vm.api.time_off_reports.send = false })
    }
  },
  mounted(){
    this.fetchTimeOffReports()
  }
}
</script>

<style>

</style>