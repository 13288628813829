var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup scrollable bigSquareCheckbox IosModal CRM-modal",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "scrollable": true,
      "height": "auto"
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_c('div', {
    staticClass: "CRM-modal-header"
  }, [_c('div', {
    staticClass: "CRM-modal-header-text"
  }, [_vm._v("Create activity")]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        _vm.api.create_activity.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('b-form', {
    staticClass: "latestGreyBox-9-9-2023",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createActivity();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": !!_vm.api.create_activity.error_message
    }
  }, [_vm._v(_vm._s(_vm.api.create_activity.error_message))]), _c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Title")]), _c('b-form-input', {
    attrs: {
      "disabled": _vm.api.create_activity.send,
      "placeholder": "Title"
    },
    model: {
      value: _vm.forms.create_activity.title,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_activity, "title", $$v);
      },
      expression: "forms.create_activity.title"
    }
  }), _vm.$v.forms.create_activity.title.$dirty && _vm.$v.forms.create_activity.title.$invalid || _vm.api.create_activity.validation_errors.title ? _c('p', [!_vm.$v.forms.create_activity.title.required ? _c('span', [_vm._v("Title is required")]) : _vm._e(), _vm._l(_vm.api.create_activity.validation_errors.title, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('SelectActivityTypeInput', {
    attrs: {
      "disabled": _vm.api.create_activity.send
    },
    scopedSlots: _vm._u([{
      key: "errors",
      fn: function () {
        return [_vm.$v.forms.create_activity.activity_type_id.$dirty && _vm.$v.forms.create_activity.activity_type_id.$invalid || _vm.api.create_activity.validation_errors.activity_type_id ? _c('p', [!_vm.$v.forms.create_activity.activity_type_id.required ? _c('span', [_vm._v("activity type is required")]) : _vm._e(), _vm._l(_vm.api.create_activity.validation_errors.activity_type_id, function (em) {
          return _c('span', {
            key: em
          }, [_vm._v("* " + _vm._s(em))]);
        })], 2) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.forms.create_activity.activity_type_id,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_activity, "activity_type_id", $$v);
      },
      expression: "forms.create_activity.activity_type_id"
    }
  }), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "crm-input-container VueCtkDateTimePicker subSectionLeftAligned"
  }, [_c('vue-ctk-date-time-picker', _vm._b({
    attrs: {
      "noClearButton": true,
      "disabled": _vm.api.create_activity.send,
      "format": 'YYYY-MM-DD HH:mm'
    },
    model: {
      value: _vm.forms.create_activity.due.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_activity.due, "start_date", $$v);
      },
      expression: "forms.create_activity.due.start_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false)), _vm.$v.forms.create_activity.due.start_date.$dirty && _vm.$v.forms.create_activity.due.start_date.$invalid || _vm.api.create_activity.validation_errors.due_date || _vm.api.create_activity.validation_errors.due_time ? _c('p', [!_vm.$v.forms.create_activity.due.start_date.required ? _c('span', [_vm._v("Start Date is required")]) : _vm._e(), _vm._l(_vm.api.create_activity.validation_errors.due_date, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), _vm._l(_vm.api.create_activity.validation_errors.due_time, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('span', {
    staticClass: "mx-2 mb-16px"
  }, [_vm._v(" - ")]), _c('div', {
    staticClass: "crm-input-container VueCtkDateTimePicker subSectionRightAligned"
  }, [_c('vue-ctk-date-time-picker', _vm._b({
    attrs: {
      "noClearButton": true,
      "disabled": _vm.api.create_activity.send,
      "min-date": _vm.forms.create_activity.due.start_date,
      "format": 'YYYY-MM-DD HH:mm'
    },
    model: {
      value: _vm.forms.create_activity.due.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_activity.due, "end_date", $$v);
      },
      expression: "forms.create_activity.due.end_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false)), _vm.$v.forms.create_activity.due.end_date.$dirty && _vm.$v.forms.create_activity.due.end_date.$invalid || _vm.api.create_activity.validation_errors.end_date || _vm.api.create_activity.validation_errors.end_time ? _c('p', [!_vm.$v.forms.create_activity.due.end_date.required ? _c('span', [_vm._v("End Date is required")]) : _vm._e(), _vm._l(_vm.api.create_activity.validation_errors.end_date, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), _vm._l(_vm.api.create_activity.validation_errors.end_time, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "w-100 d-flex align-items-end"
  }, [_c('div', {
    staticClass: "crm-input-container w-25 min-width-250px mr-3"
  }, [_c('label', [_vm._v("Reminder")]), _c('div', {
    staticClass: "crm-input-inner w-100 position-relative"
  }, [_c('b-button', {
    attrs: {
      "disabled": _vm.api.create_activity.send,
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.forms.create_activity.reminder.enable = !_vm.forms.create_activity.reminder.enable;
      }
    }
  }, [_vm.forms.create_activity.reminder.enable ? _c('b-icon', {
    staticClass: "crm-input-icon",
    attrs: {
      "icon": "x",
      "variant": "danger"
    }
  }) : _c('b-icon', {
    staticClass: "crm-input-icon",
    attrs: {
      "icon": "x",
      "variant": "danger"
    }
  })], 1), _c('b-form-input', {
    attrs: {
      "disabled": !_vm.forms.create_activity.reminder.enable,
      "placeholder": "minutes",
      "type": "number"
    },
    model: {
      value: _vm.forms.create_activity.reminder.before,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_activity.reminder, "before", $$v);
      },
      expression: "forms.create_activity.reminder.before"
    }
  }), _vm.$v.forms.create_activity.due.end_date.$dirty && _vm.$v.forms.create_activity.due.end_date.$invalid || _vm.api.create_activity.validation_errors.end_date || _vm.api.create_activity.validation_errors.end_time ? _c('p', [!_vm.$v.forms.create_activity.due.end_date.required ? _c('span', [_vm._v("Reminder is required")]) : _vm._e(), _vm._l(_vm.api.create_activity.validation_errors.end_date, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), _vm._l(_vm.api.create_activity.validation_errors.end_time, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "crm-main-dd dropdownVersion mr-3 mb-16px"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "disabled": !_vm.forms.create_activity.reminder.enable || _vm.api.create_activity.send
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v(_vm._s(_vm.forms.create_activity.reminder.type))]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, _vm._l(['minutes', 'hours', 'days', 'weeks'], function (type) {
    return _c('b-dropdown-item', {
      key: type,
      on: {
        "click": function ($event) {
          _vm.forms.create_activity.reminder.type = type;
        }
      }
    }, [_c('span', [_vm._v(_vm._s(type))])]);
  }), 1)], 1), _c('div', {
    staticClass: "d-flex align-items-center h-40px mb-16px"
  }, [_c('div', [_vm._v("before due")])]), _vm.api.create_activity.validation_errors.reminder_minutes_before ? _c('p', _vm._l(_vm.api.create_activity.validation_errors.reminder_minutes_before, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()]), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Select user")]), _c('vb-select', {
    attrs: {
      "disabled": _vm.api.create_activity.send,
      "options": _vm.GET_CRM_USERS,
      "valueField": "id",
      "textField": "name",
      "placeholder": "Select User"
    },
    model: {
      value: _vm.forms.create_activity.owner,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_activity, "owner", $$v);
      },
      expression: "forms.create_activity.owner"
    }
  }), _vm.$v.forms.create_activity.owner.$dirty && _vm.$v.forms.create_activity.owner.$invalid || _vm.api.create_activity.validation_errors.user_id ? _c('p', [!_vm.$v.forms.create_activity.owner.required ? _c('span', [_vm._v("User is required")]) : _vm._e(), _vm._l(_vm.api.create_activity.validation_errors.user_id, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Description")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_activity.description,
      expression: "forms.create_activity.description"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.create_activity.send,
      "placeholder": "Description"
    },
    domProps: {
      "value": _vm.forms.create_activity.description
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_activity, "description", $event.target.value);
      }
    }
  }), _vm._v(" "), _vm.$v.forms.create_activity.description.$dirty && _vm.$v.forms.create_activity.description.$invalid || _vm.api.create_activity.validation_errors.description ? _c('p', _vm._l(_vm.api.create_activity.validation_errors.description, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()]), _c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Notes")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_activity.note,
      expression: "forms.create_activity.note"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.create_activity.send,
      "placeholder": "Notes"
    },
    domProps: {
      "value": _vm.forms.create_activity.note
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_activity, "note", $event.target.value);
      }
    }
  }), _vm._v(" "), _vm.$v.forms.create_activity.note.$dirty && _vm.$v.forms.create_activity.note.$invalid || _vm.api.create_activity.validation_errors.note ? _c('p', _vm._l(_vm.api.create_activity.validation_errors.note, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()]), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Guests")]), _c('vb-select', {
    attrs: {
      "disabled": _vm.api.create_activity.send,
      "options": _vm.GET_CRM_USERS,
      "placeholder": "Guests"
    }
  }), _vm.$v.forms.create_activity.guests.$dirty && _vm.$v.forms.create_activity.guests.$invalid || _vm.api.create_activity.validation_errors.guests ? _c('p', _vm._l(_vm.api.create_activity.validation_errors.guests, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()], 1), _c('DealSearchInput', {
    attrs: {
      "disabled": _vm.api.create_activity.send
    },
    scopedSlots: _vm._u([{
      key: "errors",
      fn: function () {
        return [_vm.$v.forms.create_activity.deals.$dirty && _vm.$v.forms.create_activity.deals.$invalid || _vm.api.create_activity.validation_errors.deals ? _c('p', _vm._l(_vm.api.create_activity.validation_errors.deals, function (em) {
          return _c('span', {
            key: em
          }, [_vm._v("* " + _vm._s(em))]);
        }), 0) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.forms.create_activity.deals,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_activity, "deals", $$v);
      },
      expression: "forms.create_activity.deals"
    }
  }), _c('ContactSearchInput', {
    attrs: {
      "disabled": _vm.api.create_activity.send
    },
    scopedSlots: _vm._u([{
      key: "errors",
      fn: function () {
        return [_vm.$v.forms.create_activity.deals.$dirty && _vm.$v.forms.create_activity.deals.$invalid || _vm.api.create_activity.validation_errors.deals ? _c('p', _vm._l(_vm.api.create_activity.validation_errors.deals, function (em) {
          return _c('span', {
            key: em
          }, [_vm._v("* " + _vm._s(em))]);
        }), 0) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.forms.create_activity.contacts,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_activity, "contacts", $$v);
      },
      expression: "forms.create_activity.contacts"
    }
  }), _c('CompanySearchInput', {
    attrs: {
      "disabled": _vm.api.create_activity.send
    },
    scopedSlots: _vm._u([{
      key: "errors",
      fn: function () {
        return [_vm.$v.forms.create_activity.deals.$dirty && _vm.$v.forms.create_activity.deals.$invalid || _vm.api.create_activity.validation_errors.deals ? _c('p', _vm._l(_vm.api.create_activity.validation_errors.deals, function (em) {
          return _c('span', {
            key: em
          }, [_vm._v("* " + _vm._s(em))]);
        }), 0) : _vm._e()];
      },
      proxy: true
    }]),
    model: {
      value: _vm.forms.create_activity.companies,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_activity, "companies", $$v);
      },
      expression: "forms.create_activity.companies"
    }
  }), _c('b-button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.create_activity.send
    }
  }, [_vm._v("Submit")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }