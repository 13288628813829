<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Time Range</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        
        <form class="dialer-form lg-mar-bottom" @submit.prevent="onDone()">
          <b-alert class="mt-32px" :show="submitted.done && !isInvalid" variant="danger">Start time should be greater than end time</b-alert>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Start Time</label>
                <VueTimepicker
                  class="dialer-outofhours-timeslot input-consistent-VueTimepicker"
                  placeholder="select start Time"
                  format="HH:mm"
                  close-on-complete
                  v-model="form.start.displayTime"
                  @change="form.start = $event"
                />
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>End Time</label>
                <VueTimepicker
                  class="dialer-outofhours-timeslot input-consistent-VueTimepicker"
                  placeholder="select end Time"
                  format="HH:mm"
                  close-on-complete
                  v-model="form.end.displayTime"
                  @change="form.end = $event"
                />
              </div>
            </div>
          </div>
          <button class="IosFullWidthButton forDone mb-16px">Done</button>
        </form>

      </div>
    </modal>
  </div>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { LOGGER } from "@/utils";
export default {
  name: "StartAndEndTimePickerModal",
  components: {
    VueTimepicker,
  },
  props: {
    modalName: {
      type: String,
      default: "StartAndEndTimePicker",
    },
  },
  data() {
    return {
      form: {
        start: {
          data: {},
          displayTime: "",
        },
        end: {
          data: {},
          displayTime: "",
        },
      },
      submitted: {
        done: false,
      },
    };
  },
  computed: {
    startTimeFormate() {
      return {
        HH: this.form.start.displayTime.slice(0, 2) == "HH" ? "" : this.form.start.displayTime.slice(0, 2),
        mm: this.form.start.displayTime.slice(3, 5) == "mm" ? "" : this.form.start.displayTime.slice(3, 5),
        // a:
        //   this.form.start.displayTime.slice(6, 8) == "a"
        //     ? ""
        //     : this.form.start.displayTime.slice(6, 8),
      };
    },
    endTimeFormate() {
      return {
        HH: this.form.end.displayTime.slice(0, 2) == "HH" ? "" : this.form.end.displayTime.slice(0, 2),
        mm: this.form.end.displayTime.slice(3, 5) == "mm" ? "" : this.form.end.displayTime.slice(3, 5),
        // a:
        //   this.form.end.displayTime.slice(6, 8) == "a"
        //     ? ""
        //     : this.form.end.displayTime.slice(6, 8),
      };
    },
    isInvalid(){
      if (
        this.startTimeFormate.HH == "" ||
        this.startTimeFormate.mm == "" ||
        this.startTimeFormate.ss == "" ||
        // this.startTimeFormate.a == "" ||
        // this.endTimeFormate.a == "" ||
        this.endTimeFormate.HH == "" ||
        this.endTimeFormate.mm == "" ||
        this.endTimeFormate.ss == ""
      ) {
        return false;
      } else if (
        // this.startTimeFormate.a == this.endTimeFormate.a &&
        this.startTimeFormate.HH == this.endTimeFormate.HH &&
        this.startTimeFormate.mm == this.endTimeFormate.mm
      ) {
        return false;
      } else if (
        // this.startTimeFormate.a == this.endTimeFormate.a &&
        this.startTimeFormate.HH > this.endTimeFormate.HH
      ) {
        return false;
      } else if (
        this.startTimeFormate.HH == this.endTimeFormate.HH &&
        this.startTimeFormate.mm > this.endTimeFormate.mm
      ) {
        return false;
      } else if (
        this.startTimeFormate.HH == this.endTimeFormate.HH &&
        this.startTimeFormate.mm == this.endTimeFormate.mm &&
        this.startTimeFormate.ss > this.endTimeFormate.ss
      ) {
        return false;
      } else {
        return true;
      }
    },
    isSame() {
      if (
        this.startTimeFormate.HH == "" ||
        this.startTimeFormate.mm == "" ||
        // this.startTimeFormate.a == "" ||
        // this.endTimeFormate.a == "" ||
        this.endTimeFormate.HH == "" ||
        this.endTimeFormate.mm == ""
      ) {
        return false;
      } else if (
        // this.startTimeFormate.a == this.endTimeFormate.a &&
        this.startTimeFormate.HH == this.endTimeFormate.HH &&
        this.startTimeFormate.mm == this.endTimeFormate.mm
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    onDone() {
      this.submitted.done = true;
      if (!this.isInvalid) return;
      this.$emit("updated", {
        start: this.form.start,
        end: this.form.end,
      });
      this.$modal.hide(this.modalName);
    },
    onBeforeOpen(event) {
      LOGGER.log("event.params", event.params);
      this.form.start.displayTime = event.params.start || "";
      this.form.end.displayTime = event.params.end || "";
    },
    onBeforeClose() {
      this.submitted.done = false;
    },
  },
};
</script>

<style>
</style>