<template>
  <div>
    <modal
      class="center-zoomIn-transition v2 m-w-500"
      width="500px"
      height="auto"
      :scrollable="true"
      name="EditExtensionModal"
      @before-open="beforeOpen"
    >
      <section class="ct-user-new dialer-edit">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="update">
          <div v-if="$store.getters.getIsMobile" class="dialer-edit-header flex-column">
            <div class="d-flex w-100 justify-content-between align-items-center">
              <h2 class="dialer-edit-title">Edit extension</h2>
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('EditExtensionModal')" />
            </div>
            <!-- <div class="dialer-edit-actions d-flex w-100 justify-content-end mt-2">
              <button class="dialer-button dialer-button-primary" :disabled="api_sent.update">
                <vb-spinner v-if="api_sent.update" />
                
                <template v-else>Done</template>
              </button>
            </div> -->
          </div>
          <div v-else class="dialer-edit-header">
            <div>
              <h2 class="dialer-settings-title newer mb-0">Edit extension</h2>
            </div>
            <div class="dialer-edit-actions">
              <a class="newCloseButton" @click="$modal.hide('EditExtensionModal')">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder">
              <label>Extension</label>
              <input id="edit-extension-name-input-field" class="w-100" :disabled="api_sent.update"  type="text" v-model="forms.update_extension.extn" />
              <template v-if="is_submitted.update">
                <p v-if="!$v.forms.update_extension.extn.required" class="text text-danger animated bounceIntop">
                  <span>* Extn is required</span>
                </p>
              </template>
            </div>
          </div>
          <div class="w-100 mt-4">
            <button class="fullWidthDoneButton" :disabled="api_sent.update">
              <vb-spinner v-if="api_sent.update" />
              <template v-else>Update</template>
            </button>
          </div>






          <!-- <div class="md-mar-vertical">
            <div class="dialer-team-name mb-4">
              <div class="dialer-input-field input-consistent-inner-shadows">
                <div class="w-100">
                  <label>Extension</label>
                  <input id="edit-extension-name-input-field" class="w-100" :disabled="api_sent.update"  type="text" v-model="forms.update_extension.extn" />
                </div>
                <template v-if="is_submitted.update">
                  <p v-if="!$v.forms.update_extension.extn.required" class="text text-danger animated bounceIntop">
                    <span>* Extn is required</span>
                  </p>
                </template>
              </div>
            </div>
          </div> -->
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { VOIP_API } from '../../utils';

export default {
  name: "EditExtensionModal",
  data(){
    return {
      forms: {
        update_extension: {
          extn: null,
          reset(){
            this.extn= null
          },
        },
      },
      account: null,
      api_sent: {
        update: false,
      },
      is_submitted: {
        update: false,
      },
      assets: {
        spinner: require("@/assets/images/spinner.svg"),
      },
    }
  },
  inject: [
    'appNotify',
  ],
  validations: {
    forms: {
      update_extension: {
        extn: {
          required
        },
      },
    },
  },
  methods: {

    beforeOpen(event) {
        this.forms.update_extension.extn = event.params.data.extn;
        this.account = event.params.data.voipaccount;
    },
    update(){
      let vm = this
      this.is_submitted.add=true
      this.$v.$touch();
      if (this.$v.forms.update_extension.$invalid || this.api_sent.update) return;
      this.api_sent.update = true
      VOIP_API.endpoints.user.updateExtension({
        account: this.account,
        extn: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
        extension: this.forms.update_extension.extn,
      }).then(()=>{
        this.appNotify({
          message: 'Successfully updated!',
          type: 'success',
        })
        vm.forms.update_extension.reset()
        vm.$modal.hide('EditExtensionModal')
        vm.$emit('interface')
      }).catch((error)=>{
        this.appNotify({
          message:  error.response.data.message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.is_submitted.update = false
        vm.api_sent.update = false
      })
    },
  },
};
</script>
