<template>
  <div>
    <modal
      class="center-zoomIn-transition v2 m-w-500" 
      width="500px" 
      height="auto"
      :scrollable="true"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <section class="ct-user-new dialer-edit s1">
        <form class="dialer-form " @submit.prevent="changePassword()">
          <div v-if="getIsMobile" class="dialer-edit-header flex-column">
            <div class="d-flex justify-content-between align-items-center w-100">
              <h2 class="dialer-edit-title">Change password</h2>
              <b-icon class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            </div>
          </div>
          <div v-else class="dialer-edit-header d-block">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <h2 class="dialer-settings-title newer mb-0">Change password</h2>
              </div>
              <div class="dialer-edit-actions">
                <a class="newCloseButton" @click="$modal.hide(modalName)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <div class="vm--modal-text">Update your login account password.</div>
          </div>

          <div class="md-mar-top">
            <b-alert :show="!!api.change_password.error_message" variant="danger">{{ api.change_password.error_message }}</b-alert>
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <div class="whiteBGinputWithGreyRoundedBorder withIconsOnBothSides w-100">
                <label>Current password</label>
                <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
                  <vb-icon icon="latest-lockInsideInput-icon" class="leftSideIcon" width="17.609px" height="20.124px" />
                  <input 
                    class="touched w-100 py-0" 
                    :type="conditions.password_show.current ? 'text' : 'password'" 
                    name="currentpassword" 
                    :disabled="api.change_password.send" 
                    label="Current password" 
                    v-model="forms.change_password.current_password" 
                  />
                  <vb-icon 
                    width="24px" 
                    height="24px" 
                    class="rightSideIcon fill-primary" 
                    :icon="conditions.password_show.current ? 'profileSettings-eyeOff-icon' : 'profileSettings-eye-icon'" 
                    @click="conditions.password_show.current = !conditions.password_show.current" 
                  />
                </div>
                <p 
                  v-if="(forms.change_password.submitted && $v.forms.change_password.current_password.$invalid) || api.change_password.validation_errors.cpassword" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.change_password.current_password.required">* Current password is required</span>
                  <span v-for="(em,i) in api.change_password.validation_errors.cpassword" :key="i">* {{ em }}</span>
                </p>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder withIconsOnBothSides w-100 mt-20px">
                <label>New password</label>
                <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
                  <vb-icon icon="latest-lockInsideInput-icon" class="leftSideIcon" width="17.609px" height="20.124px" />
                  <input 
                    class="touched w-100 py-0" 
                    :type="conditions.password_show.new ? 'text' : 'password'" 
                    name="currentpassword" 
                    :disabled="api.change_password.send" 
                    label="Current password" 
                    v-model="forms.change_password.new_password" 
                    :maxlength="$v.forms.change_password.new_password.$params.maxLength.max"
                  />
                  <vb-icon 
                    width="24px" 
                    height="24px" 
                    class="rightSideIcon fill-primary" 
                    :icon="conditions.password_show.new ? 'profileSettings-eyeOff-icon' : 'profileSettings-eye-icon'" 
                    @click="conditions.password_show.new = !conditions.password_show.new" 
                  />
                </div>
                <p 
                  v-if="(forms.change_password.submitted && $v.forms.change_password.new_password.$invalid) || api.change_password.validation_errors.password" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.change_password.new_password.required">* password is required</span>
                  <span v-else-if="!$v.forms.change_password.new_password.validpassword">* password is invalid</span>
                  <span v-else-if="!$v.forms.change_password.new_password.capitalalphabet">* password required at least one capital character</span>
                  <span v-else-if="!$v.forms.change_password.new_password.smallalphabet">* password required at least one small character</span>
                  <span v-else-if="!$v.forms.change_password.new_password.numbers">* password required at least one integer character</span>
                  <span v-else-if="!$v.forms.change_password.new_password.specialcharacter">* password required at least one special character</span>
                  <span v-else-if="!$v.forms.change_password.new_password.minLength">* password is minimum {{$v.forms.change_password.new_password.$params.minLength.min}} is required</span>
                  <span v-else-if="!$v.forms.change_password.new_password.maxLength">* password is maximum {{$v.forms.change_password.new_password.$params.maxLength.max}} character</span>
                  <span v-else-if="!$v.forms.change_password.new_password.notSameCurrentPassword">password should not be same as your current password</span>
                  <span v-for="(em,i) in api.change_password.validation_errors.password" :key="i">* {{ em }}</span>
                </p>
              </div>
            </div>
            <div class="d-flex justify-content-end w-100 mt-4">
              <!-- <button class="dialer-button dialer-button-primary" :disabled="api.change_password.send">
                <vb-spinner v-if="api.change_password.send" />
                <template v-else>Save changes</template>
              </button> -->
              <button :disabled="api.change_password.send" class="fullWidthDoneButton">
                <span class="d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api.change_password.send" />
                  <template v-else>Update</template>
                </span>
              </button>
            </div>
          </div>
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils/index';
import { mapGetters } from 'vuex';
import minLength from 'vuelidate/lib/validators/minLength';
import required from 'vuelidate/lib/validators/required';
import { helpers, maxLength } from 'vuelidate/lib/validators';
import { GET_CRM_TOKEN } from '@/store/helper/getters';
export default {
  name: "ChangePasswordModal",
  props: {
    modalName: {
      type: String,
      default: 'ChangePassword',
    },
  },
  data() {
    return {
      forms: {
        change_password: this.$helperFunction.formInstance({
          data: {
            current_password: '',
            new_password: '',
          }
        }),
      },
      api: {
        change_password: this.$helperFunction.apiInstance({ 
          error_message: true, 
          validation_errors: true, 
        }),
      },
      data: {
        accountcode: '',
      },
      conditions: {
        password_show: {
          current: false,
          new: false,
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      GET_CRM_TOKEN,
    ]),
  },
  validations: {
    forms: {
      change_password: {
        current_password: { 
          required: required 
        },
        new_password: {
          required: required,
          minLength: minLength(8),
          maxLength: maxLength(32),
          validpassword: helpers.regex('validpassword', /^[a-zA-Z0-9`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]*$/),
          capitalalphabet(value){ return /[A-Z]/.test(value) },
          smallalphabet(value){ return /[a-z]/.test(value) },
          numbers(value){ return /[0-9]/.test(value) },
          specialcharacter(value){ return /[`!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(value) },
          notSameCurrentPassword(value){ return this.forms.change_password.current_password!=value },
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode=event?.params?.accountcode
    },
    onBeforeClose(){
      this.forms.change_password.reset()
      this.api.change_password.reset()
      this.data.accountcode=''
    },
    async changePassword() {
      this.forms.change_password.submitted = true;
      this.$v.forms.change_password.$touch();
      if (this.$v.forms.change_password.$invalid || this.api.change_password.send) return;
      try {
        this.api.change_password.send = true;
        this.api.change_password.error_message = '';
        this.api.change_password.validation_errors = {};
        await VOIP_API.endpoints.users.updatepassword({
          cpassword: this.forms.change_password.current_password,
          password: this.forms.change_password.new_password,
          accountcode: this.getCurrentUser.account,
          id: this.data.accountcode,
          section: 'profile',
          crm_token: this.GET_CRM_TOKEN,
        })
        this.appNotify({
          message: "Password Updated Successfully",
          type: "success",
        })
        this.$emit('updated')
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.api.change_password.error_message = ex.own_message ?? '';
        this.api.change_password.validation_errors = ex.own_errors ?? {};
      } finally {
        this.api.change_password.send = false;
        this.forms.change_password.submitted = false;
      }
    },
  }
};
</script>
