var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Playlist Files")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {}, [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px mt-32px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    staticClass: "w-100",
    attrs: {
      "disabled": true,
      "placeholder": 'Enter Name',
      "type": "text"
    },
    domProps: {
      "value": _vm.data.name
    }
  })])])])]), _c('div', {
    staticClass: "textBelowCard"
  }, [_vm._v("Following are the audio files present in the current MOH playlist.")]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_vm._l(_vm.selectedList, function (item) {
    return _c('div', {
      key: item.real_id,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(item.label))]), _c('vb-audio-player', {
      staticClass: "IosAudioPlayer",
      attrs: {
        "src": item.sound_file
      }
    })], 1);
  }), _vm.isEmpty(_vm.selectedList) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.playlists.send ? '' : 'there is no selected recording list',
      "design": 3
    }
  }, [_vm.api.playlists.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }