var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "position-relative"
  }, [_c('div', {
    ref: "app_designed",
    staticClass: "flow__graph designed designedAgain responsiveGraph scaleDown-0-6",
    attrs: {
      "id": "app_designed"
    }
  }, [_vm.number ? _c('div', {
    staticClass: "w-100 d-flex justify-content-center align-items-center flex-column"
  }, [_c('div', {
    staticClass: "w-auto d-flex justify-content-center align-items-start flex-column"
  }, [_c('div', {
    staticClass: "w-auto"
  }, [_c('NumbersNode', {
    attrs: {
      "dids": [_vm.number]
    }
  }), _c('div', {
    staticClass: "w-100 d-flex align-items-center flex-column"
  }, [_c('div', {
    staticClass: "nodeBasic-new"
  }, [_c('div', {
    staticClass: "nodeBasic-heading mb-8px"
  }, [_vm._v("Assign to")]), _vm.number.assignedToUsersArr && _vm.number.assignedToUsersArr.extn ? _c('div', [_c('Info', {
    attrs: {
      "id": _vm.number.assignedToUsersArr.extn,
      "is_blf": false
    }
  }), _vm.getVoipIVRSAlises[_vm.number.assignedToUsersArr.extn] ? _c('vb-audio-player', {
    staticClass: "audioForMobile",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "design": 'minimum',
      "src": _vm._f("welcomgreetingsrc")(_vm.getVoipIVRSAlises[_vm.number.assignedToUsersArr.extn])
    }
  }) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center w-100 mt-3"
  }, [_c('b-icon', {
    staticClass: "cursor_pointer mr-2",
    attrs: {
      "icon": "trash-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updateExtension();
      }
    }
  }), _c('b-icon', {
    staticClass: "cursor_pointer",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallForwardingSelectExtensionModalForGraph', {
          type: 'setting'
        });
      }
    }
  })], 1)])])], 1), _c('div', {
    class: `mb-3 d-flex align-items-start position-relative`
  }, [_c('div', [_vm._m(0), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "nodeBasic-new"
  }, [_vm._v(" Shared with "), _c('div', {
    class: `d-flex justify-content-end align-items-center w-100 mt-3 position-relative`
  }, [_c('b-icon', {
    staticClass: "cursor_pointer ml-3",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "icon": "pencil-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        var _vm$number$extensions, _vm$number;
        return _vm.$modal.show('UserCallerIdShared2', {
          extensions: (_vm$number$extensions = (_vm$number = _vm.number) === null || _vm$number === void 0 ? void 0 : _vm$number.extensions) !== null && _vm$number$extensions !== void 0 ? _vm$number$extensions : [],
          number: _vm.number,
          type: 'UserCallerIdShared'
        });
      }
    }
  })], 1)]), _vm.number.html.length > 0 ? _c('div', {
    staticClass: "lineWithArrow right longLine-150px pt-0 d-flex align-items-center"
  }, [_c('div', {
    staticClass: "lineWithArrow-line"
  })]) : _vm._e()])]), _vm.number.html.length > 0 ? [_c('div', {
    staticClass: "nodeBasic-new mt-auto"
  }, [_vm._l(_vm.firstFourShared, function (user) {
    return _c('div', {
      key: user,
      staticClass: "mb-12px"
    }, [_c('Info', {
      attrs: {
        "id": _vm._f("filterExtensionValue")(user),
        "type": 'USER',
        "is_blf": false
      }
    })], 1);
  }), _vm.number.html.length > 4 ? _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-4"
  }, [_c('div', {
    staticStyle: {
      "color": "rgb(85, 118, 209)",
      "font-weight": "600",
      "font-size": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.number.html.length - 4) + " more members ")])]) : _vm._e()], 2)] : _vm._e()], 2)])]) : _vm._e()]), _c('ScheduleAssignModal', {
    attrs: {
      "disableCurrentUser": false,
      "modalName": `CallForwardingSelectExtensionModalForGraph`
    },
    on: {
      "interface": function ($event) {
        return _vm.updateExtension($event);
      }
    }
  }), _c('AddRemoveFromList', {
    attrs: {
      "modalName": "UserCallerIdShared2"
    },
    on: {
      "update-list": function ($event) {
        return _vm.getNumbers();
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-center",
    staticStyle: {
      "width": "200px"
    }
  }, [_c('div', {
    staticClass: "lineWithArrow"
  }, [_c('div', {
    staticClass: "lineWithArrow-line"
  }), _c('div', {
    staticClass: "lineWithArrow-arrow"
  })])]);

}]

export { render, staticRenderFns }