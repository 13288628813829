<template>
  <div class="content-area d-flex h-100 dialer_main_body Schedule">
    <div class="dialer_main_body_inner w-100">
      <div class="dialer_inner_right_side ml-0">
        <Schedule />
      </div>
    </div>
  </div>
</template>

<script>
import Schedule from "../components/Schedule/ScheduleCopy.vue";
// /Modals/FeedbackModal.vue

export default {
  name: 'Scheduling',
  components: {
    Schedule,
  },
};
</script>
