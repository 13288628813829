
import { VOIP_API } from "../index"

export const JITSI_CONFERENCE_API_ENDPOINTS = {
  invitemember(data={}){
    return VOIP_API.axios.voip.request({
      url: `invite-conference-members`,
      method: "POST",
      data,
    })
  }
}