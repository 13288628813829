<template>
  <div>

    <modal class="ChatSettingsModal" :name="modalName">
      <!-- dialer_animation center-zoomIn-popup dialer-notificationModal -->
      <div class="ChatSettingsModal-innerContainer">
        <div class="firstSection-empty">
          <!-- <b-icon icon="x" class="svg-primary cursor_pointer" font-scale="1.8" @click="$modal.hide(modalName)"/> -->
          <button type="button" class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
        <div class="imgContainer">
          <img :src="image || ''" @error="$event.target.src=require('@/assets/images/userImgForChatSettings.png')" />
        </div>
        <div class="nameAndDescription-container">
          <div class="nameAndDescription-title">
            {{ name }}
          </div>
          <div v-if="channelData.type === 'channel'" class="nameAndDescription-Description">
            {{ channel.purpose || `Add a ${channelData && channelData.type=='team'?'team':'group'} description` }}
          </div>
          <vb-icon v-if="channelData.type === 'channel'" @click="$modal.show(`${channel_id}_EditChannelInfoModal`, { name: name, purpose: channel.purpose, })" class="svg-primary cursor_pointer" icon="pencil-hrmIcon"/>
        </div>
        <div class="chatSettings-options-container">
          <div class="chatSettings-options-item">
            <div class="d-flex align-items-center">
              <div class="itemIcon">
                <vb-icon class="svg-primary cursor_pointer" height="22px" width="22px" icon="chatNotification-ChatSettingsIcon"/>
              </div>
              <div class="itemTitle">
                Chat notification
              </div>
            </div>
            <div class="wd-50px">
              <b-form-checkbox 
                class="newerSwitch" 
                :checked="!$store.state.mattermost.channels[channel_id].notification_prference.is_mute"
                @change="$store.state.mattermost.channels[channel_id].notification_prference.is_mute=!$store.state.mattermost.channels[channel_id].notification_prference.is_mute"
                name="check-button" 
                switch 
              />
            </div>
          </div>
          <div class="chatSettings-options-item">
            <div class="d-flex align-items-center">
              <div class="itemIcon">
                <vb-icon class="svg-primary cursor_pointer" height="22px" width="22px" icon="ReactionsNotification-ChatSettingsIcon"/>
              </div>
              <div class="itemTitle">
                Reactions notification
              </div>
            </div>
            <div class="wd-50px">
              <b-form-checkbox 
                class="newerSwitch" 
                :class="{
                  'disabled': $store.state.mattermost.channels[channel_id].notification_prference.is_mute,
                }"  
                :disabled="$store.state.mattermost.channels[channel_id].notification_prference.is_mute"
                v-model="$store.state.mattermost.channels[channel_id].notification_prference.reaction" 
                name="check-button" 
                switch 
              />
            </div>
          </div>
          <div class="chatSettings-options-item">
            <div class="d-flex align-items-center">
              <div class="itemIcon">
                <vb-icon class="svg-primary cursor_pointer" height="22px" width="22px" icon="NotificationSound-ChatSettingsIcon"/>
              </div>
              <div class="itemTitle">
                Notification sounds
              </div>
            </div>
            <div class="wd-50px">
              <b-form-checkbox 
                class="newerSwitch"  
                :class="{
                  'disabled': $store.state.mattermost.channels[channel_id].notification_prference.is_mute,
                }"  
                :disabled="$store.state.mattermost.channels[channel_id].notification_prference.is_mute"
                v-model="$store.state.mattermost.channels[channel_id].notification_prference.sound" 
                name="check-button" 
                switch 
              />
            </div>
          </div>
          <div class="chatSettings-options-item">
            <div class="d-flex align-items-center">
              <div class="itemIcon">
                <vb-icon class="svg-primary cursor_pointer" height="22px" width="22px" icon="scheduleNotification-ChatSettingsIcon"/>
              </div>
              <div class="itemTitle">
                Scheduled call notifications
              </div>
            </div>
            <div class="wd-50px">
              <b-form-checkbox 
                class="newerSwitch"
                :class="{
                  'disabled': $store.state.mattermost.channels[channel_id].notification_prference.is_mute,
                }"  
                :disabled="$store.state.mattermost.channels[channel_id].notification_prference.is_mute"
                v-model="$store.state.mattermost.channels[channel_id].notification_prference.schedule_call" 
                name="check-button" 
                switch 
              />
            </div>
          </div>
          <div class="chatSettings-options-item withCursorPointer" v-if="type === 'main'" @click="$modal.hide(modalName);$emit('members')">
            <div class="d-flex align-items-center">
              <div class="itemIcon">
                <vb-icon class="svg-primary cursor_pointer" height="22px" width="22px" icon="showParticipants-ChatSettingsIcon"/>
              </div>
              <div class="itemTitle">
                Show participants
              </div>
            </div>
            <b-icon icon="chevron-right" class="forRightIcon" />
          </div>
          <div class="chatSettings-options-item withCursorPointer" v-if="channelData.type === 'channel'" @click="leave()">
            <div class="d-flex align-items-center">
              <div class="itemIcon forRed">
                <vb-icon class="svg-primary cursor_pointer" height="22px" width="22px" icon="chatLeave-ChatSettingsIcon"/>
              </div>
              <div class="itemTitle forRed">
                Leave
              </div>
            </div>
          </div>
          <div class="chatSettings-options-item withCursorPointer" v-if="channelData.type === 'channel' && !isEmpty(currentUserMember) && currentUserMember.roles.indexOf('channel_admin') >= 0" @click="deleteChannel()">
            <div class="d-flex align-items-center">
              <div class="itemIcon forRed">
                <vb-icon class="svg-primary cursor_pointer" height="22px" width="22px" icon="trash-IOSIcon"/>
              </div>
              <div class="itemTitle forRed">
                Delete
              </div>
            </div>
          </div>
        </div>
        <!-- <div class="dialer-edit-header d-flex align-items-start justify-content-between px-3 pt-3">
          <h2 class="dialer-edit-title">Settings</h2>
          
          <div class="dialer-edit-actions">
            <a class="cursor_pointer ml-2" @click="$modal.hide(modalName)">
              <b-icon icon="x" scale="1.8" />
            </a>
          </div>
        </div> -->
        <div class="dialer-modal-body md-mar-vertical px-3 mx-2">
          <ul class="list-unstyled">
            <!-- <li class="d-flex justify-content-between border-bottom py-2">
              <div>
                <h5 class="m-0">Chat Notification</h5>
                <p class="mb-0">Receive push notifications for new messages</p>
              </div>
              <div class="wd-50px">
                <b-form-checkbox class="dialer-switch" :checked="!$store.state.mattermost.channels[channel_id].notification_prference.is_mute" @change="$store.state.mattermost.channels[channel_id].notification_prference.is_mute=!$store.state.mattermost.channels[channel_id].notification_prference.is_mute" name="check-button" switch />
              </div>
            </li>
            <li class="d-flex justify-content-between border-bottom py-2">
              <div>
                <h5 class="m-0">Reactions</h5>
                <p class="mb-0">Receive push notifications when someone reacts to your message.</p>
              </div>
              <div class="wd-50px">
                <b-form-checkbox class="dialer-switch" v-model="$store.state.mattermost.channels[channel_id].notification_prference.reaction" name="check-button" switch />
              </div>
            </li>
            <li class="d-flex justify-content-between border-bottom py-2">
              <div>
                <h5 class="m-0">Notification sounds</h5>
                <p class="mb-0">Play sounds for new messages</p>
              </div>
              <div class="wd-50px">
                <b-form-checkbox class="dialer-switch" v-model="$store.state.mattermost.channels[channel_id].notification_prference.sound" name="check-button" switch />
              </div>
            </li>
            <li class="d-flex justify-content-between border-bottom py-2">
              <div>
                <h5 class="m-0">Scheduled call notifications</h5>
                <p class="mb-0">Receive notifications for scheduled calls</p>
              </div>
              <div class="wd-50px">
                <b-form-checkbox class="dialer-switch" v-model="$store.state.mattermost.channels[channel_id].notification_prference.schedule_call" name="check-button" switch />
              </div>
            </li> -->
            <!-- <li class="d-flex justify-content-between border-bottom py-2 cursor_pointer" v-if="channelData.type === 'channel'" @click="$modal.show(`${channel_id}_EditChannelInfoModal`, { name: name, purpose: channel.purpose, })">
              <div >
                <h5 class="m-0">Edit</h5>
                <p class="mb-0">Edit Group Name and Description</p>
              </div>
            </li> -->
            <!-- <li class="d-flex justify-content-between border-bottom py-2 cursor_pointer" v-if="channelData.type === 'channel'" @click="leave()">
              <div >
                <h5 class="m-0">Leave</h5>
                <p class="mb-0">Leave this Group</p>
              </div>
            </li>
            <li class="d-flex justify-content-between border-bottom py-2 cursor_pointer" v-if="channelData.type === 'channel' && !isEmpty(currentUserMember) && currentUserMember.roles.indexOf('channel_admin') >= 0" @click="deleteChannel()">
              <div >
                <h5 class="m-0">Delete</h5>
                <p class="mb-0">Delete this Group</p>
              </div>
            </li> -->
          </ul>
        </div>
      </div>
    </modal>
    <EditChannelInfo :modalName="`${channel_id}_EditChannelInfoModal`" :channel="channel" />
  </div>
</template>

<script>
import EditChannelInfo from "../../Modals/chat/EditChannelInfo.vue";
import { mattermost, MATTERMOST_API } from '@/MatterMost';
export default {
  name: "ChannelSettingModal",
  components: {
    EditChannelInfo,
  },
  inject: ['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'ChannelSettingModal',
    },
    channel_id: {
      type: String,
      default: '',
    },
    channelData: {
      type: Object,
      default: ()=>({})
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
    currentUserMember: {
      type: Object,
      default: () => ({}),
    },
    name: {
      type: String,
      deault: "",
    },
    type: {
      type: String,
    },
    image: {
      type: String,
      deault: "",
    },
  },
  computed: {
    currentChannel(){
      return this.$store.state.mattermost.channels[this.channel_id]
    },
  },
  methods: {
    
    leave() {
      let vm = this;
      this.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to leave.`,
        variant: 'danger',
        button: {
          no: "no",
          yes: "yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            MATTERMOST_API.endpoints.channels.removeChannelMember(vm.channel_id, vm.$store.getters.getMattermostCurrentUser?.id)
              .then(() => {
                vm.appNotify({
                  message: "Successfully deleted",
                  type: 'success',
                })
              }).then(()=>{
                mattermost.emitOnUserLevel(mattermost.events.user_removed,{
                  user_id: vm.$store.getters.getMattermostCurrentUser?.id,
                  removed_channel_id: vm.channel_id,
                })
              });
          }
        },
      });
    },
    async deleteChannel() {
      let vm = this;
      this.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to delete.`,
        variant: 'danger',
        button: {
          no: "no",
          yes: "yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            MATTERMOST_API.endpoints.channels.deleteChannel(vm.channel_id).then(() => {
              vm.appNotify({
                message: "Successfully deleted",
                type: 'success',
              })
            })
            .then(()=>{
              mattermost.emitOnUserLevel(mattermost.events.channel_deleted,{
                channel_id: vm.channel_id
              })
            });
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
</style>