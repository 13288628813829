var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup new-call-info-modal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "panel-inner panel-inner-callInfo new-call-info-inner"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-between pt-0 mb-3 dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v(" Call Info ")]), _c('div', {}, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "list-area"
  }, [_vm.callInfo ? _c('div', {
    staticClass: "holder"
  }, [_c('ul', {
    staticClass: "phone-list full-width-call-info-number list-unstyled pr-0 pb-2"
  }, [_c('li', [_c('a', {
    staticClass: "d-flex align-items-start justify-content-between text-dark mb-0",
    attrs: {
      "status": _vm.callInfo.status,
      "isLeft": _vm.callInfo.status === 'Dialed' ? 'Right' : 'Left'
    }
  }, [_c('span', {
    staticClass: "ico text-white rounded-circle d-flex align-items-center justify-content-center"
  }, [_vm.callInfo.call_type === 'audio' ? [_vm.callInfo.status === 'Missed' ? _c('vb-svg', {
    attrs: {
      "name": "missedCall-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _vm.callInfo.status === 'Received' ? _c('vb-svg', {
    attrs: {
      "name": "receivedCall-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _c('vb-svg', {
    attrs: {
      "name": "dialedCall-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })] : _vm._e(), _vm.callInfo.call_type === 'video' ? [_vm.callInfo.status === 'Missed' ? _c('vb-svg', {
    attrs: {
      "name": "missedVideo-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _vm.callInfo.status === 'Received' ? _c('vb-svg', {
    attrs: {
      "name": "receivedVideo-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _c('vb-svg', {
    attrs: {
      "name": "dialedVideo-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })] : _vm._e(), _vm.callInfo.call_type === 'voicemail' ? [_c('vb-svg', {
    attrs: {
      "name": "voiceMessage-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })] : _vm._e(), _vm.callInfo.call_type === 'sms' ? [_vm.callInfo.status === 'Dialed' ? _c('vb-svg', {
    attrs: {
      "name": "sendMessage-icon",
      "width": "19",
      "height": "18",
      "viewBox": "0 0 19 18",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _c('vb-svg', {
    attrs: {
      "name": "receivedMessage-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })] : _vm._e()], 2), _c('span', {
    staticClass: "info-area px-2"
  }, [_c('div', {
    staticClass: "call-date"
  }, [_c('span', {
    staticClass: "d-block"
  }, [_vm.callInfo.call_type === 'voicemail' ? [_vm._v(_vm._s(_vm.callInfo.caller.replace(/"/g, "")))] : _vm._e(), _vm.callInfo.status == 'Missed' || _vm.callInfo.status == 'Received' ? [_vm._v(_vm._s(`${_vm.callInfo.sourceName || ""}`))] : [_vm._v(_vm._s(`${_vm.callInfo.destinationName || ""}`))]], 2), _c('div', {
    staticClass: "dialpad_activity_box_other"
  }, [_c('small', {
    staticClass: "duration"
  }, [_vm._v(_vm._s(_vm._f("showTime")(_vm.callInfo.call_date)))])])]), _c('span', {
    staticClass: "small text-capitalize"
  }, [_vm.callInfo.call_type === 'audio' || _vm.callInfo.call_type === 'video' ? [_c('span', {
    staticClass: "dialer-call-prefix"
  }, [_vm.callInfo.status == 'Missed' || _vm.callInfo.status == 'Received' ? _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(`${_vm.callInfo.sourceExt || ""}`))]) : _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.callInfo.destinationExt || ""))]), _c('div', {
    staticClass: "dialpad_activity_box_other"
  }, [_c('small', {
    staticClass: "duration"
  }, [_vm._v(_vm._s(_vm.callInfo.billSec))])])]), _vm.callInfo.is_did == '1' && _vm.callInfo.call_type === 'audio' && (_vm.callInfo.status == 'Missed' || _vm.callInfo.status == 'Received') && _vm.callInfo.caller_extension_type && _vm.callInfo.caller_extension_detail ? _c('span', {
    staticClass: "dialer-call-prefix"
  }, [_c('span', {
    staticClass: "d-block bg-primary",
    staticStyle: {
      "padding": "2px 8px",
      "border-radius": "8px",
      "color": "white",
      "width": "auto"
    }
  }, [_vm._v(" From: " + _vm._s(typeof _vm.callInfo.caller_extension_detail == 'string' ? _vm.callInfo.caller_extension_detail : _vm.callInfo.caller_extension_detail.accountname || _vm.callInfo.caller_extension_detail.user_detail_limit.display_name) + " ")])]) : _vm._e(), _vm.callInfo.is_did == '1' && _vm.callInfo.call_type === 'audio' && _vm.callInfo.status == 'Dailed' && _vm.callInfo.callee_extension_type && _vm.callInfo.callee_extension_detail ? _c('span', {
    staticClass: "dialer-call-prefix"
  }, [_c('span', {
    staticClass: "d-block bg-primary",
    staticStyle: {
      "padding": "2px 8px",
      "border-radius": "8px",
      "color": "white",
      "width": "auto"
    }
  }, [_vm._v(" To: " + _vm._s(typeof _vm.callInfo.callee_extension_detail == 'string' ? _vm.callInfo.callee_extension_detail : _vm.callInfo.callee_extension_detail.accountname || _vm.callInfo.callee_extension_detail.user_detail_limit.display_name) + " ")])]) : _vm._e()] : _vm._e()], 2)])])])]), _c('div', {
    staticClass: "allow_scroll",
    staticStyle: {
      "overflow-y": "auto",
      "height": "calc(100vh - 182px - 16px - 16px - 16px)"
    }
  }, [_c('div', {
    staticClass: "dialer-callInfo-actions"
  }, [_vm.callInfo.is_did == '1' ? _c('div', {
    staticClass: "dialer-callInfo-action block-action",
    on: {
      "click": function ($event) {
        return _vm.block();
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-block-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-action-name"
  }, [_vm._v(" Block ")])], 1) : _vm._e(), _vm.callInfo.is_did == '1' ? _c('div', {
    staticClass: "dialer-callInfo-action sendSms-action",
    on: {
      "click": function ($event) {
        return _vm.sms(_vm.callInfo);
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-sms-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-action-name"
  }, [_vm._v(" SMS ")])], 1) : _vm._e(), !_vm.callInfo.is_did == '1' ? _c('div', {
    staticClass: "dialer-callInfo-action sendSms-action",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('ChatUserModal', {
          user: _vm.user
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-chatInBox-icon",
      "width": "34",
      "height": "34"
    }
  }), _c('span', {
    staticClass: "dialer-action-name"
  }, [_vm._v("Chat")])], 1) : _vm._e(), (_vm.callInfo.call_type !== 'voicemail' || _vm.callInfo.call_type !== 'sms') && _vm.callID ? _c('div', {
    staticClass: "dialer-callInfo-action sendSms-action",
    on: {
      "click": function ($event) {
        _vm.$modal.show('TagsAndNotesUpdateModal', {
          id: _vm.callID,
          note: _vm.note,
          tags: _vm.tagsId
        });
        _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-chatInBox-icon",
      "width": "34",
      "height": "34"
    }
  }), _c('span', {
    staticClass: "dialer-action-name"
  }, [_vm._v("Edit Tags and Note")])], 1) : _vm._e(), _c('div', {
    staticClass: "dialer-callInfo-action call-action"
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-callinfo-call-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "dialer-action-name"
  }, [_vm._v(" Call ")])], 1)]), _c('ul', {
    staticClass: "phone-list full-width-call-info-number dialor-callHistoryList list-unstyled px-0"
  }, [_vm.callInfo.call_type === 'voicemail' || _vm.callInfo.call_type === 'sms' || _vm.callInfo.call_type === 'audio' && _vm.callInfo.soundFile && _vm.callInfo.status !== 'Missed' ? _c('li', [_c('a', {
    staticClass: "d-flex align-items-start justify-content-between text-dark",
    attrs: {
      "status": _vm.callInfo.status
    }
  }, [_c('span', {
    staticClass: "info-area"
  }, [_vm.callInfo.call_type === 'voicemail' || _vm.callInfo.call_type === 'sms' || _vm.callInfo.call_type === 'audio' && _vm.callInfo.soundFile && _vm.callInfo.status !== 'Missed' ? _c('div', {
    staticClass: "call-date"
  }, [_c('span', {
    staticClass: "d-block"
  }, [_vm.callInfo.call_type === 'voicemail' || _vm.callInfo.call_type === 'sms' ? [_vm._v("Message")] : [_vm._v(" Call Recording ")]], 2), _c('div', {
    staticClass: "dialpad_activity_box_other"
  }, [_c('span', {
    staticClass: "dialer-dropdown dialpad_activity_box_menu"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots",
            "scale": "1"
          }
        })];
      },
      proxy: true
    }], null, false, 2567964649)
  }, [_vm.callInfo.body ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.copyText(_vm.callInfo.body);
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-outlineCall-icon2",
      "width": "16.501",
      "height": "16.501",
      "viewBox": "0 0 16.501 16.501",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v("Copy Text")])], 1) : _vm._e(), _vm.callInfo.soundFile || _vm.callInfo.voice ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.download();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "download"
    }
  }), _c('span', [_vm._v("Download")])], 1) : _vm._e()], 1)], 1)])]) : _vm._e(), _vm.callInfo.call_type === 'voicemail' || _vm.callInfo.call_type === 'sms' || _vm.callInfo.call_type === 'audio' && _vm.callInfo.soundFile && _vm.callInfo.status !== 'Missed' ? _c('span', {
    staticClass: "small text-capitalize"
  }, [_vm.callInfo.call_type === 'voicemail' ? [_c('div', {
    staticClass: "dialer-audio-playback"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm._f("base64_to_url")(_vm.callInfo.voice)
    }
  })], 1)] : _vm.callInfo.call_type === 'sms' ? [_c('span', {
    staticClass: "dialer-call-prefix"
  }, [_vm._v(_vm._s(_vm.callInfo.body || ""))])] : _vm.callInfo.call_type === 'audio' && _vm.callInfo.soundFile && _vm.callInfo.status !== 'Missed' ? [_c('div', {
    staticClass: "dialer-audio-playback"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm.callInfo.soundFile
    }
  })], 1)] : _vm._e()], 2) : _vm._e()])])]) : _vm._e(), _c('div', {
    staticClass: "pr-2"
  }, [!_vm.isEmpty(_vm.tags) ? _c('li', [_c('div', {
    staticClass: "tags"
  }, [_c('span', {
    staticClass: "info-area w-100"
  }, [_c('div', {
    staticClass: "call-date"
  }, [_c('span', {
    staticClass: "d-block"
  }, [_vm._v("Call Tags")])]), _c('span', {
    staticClass: "small text-capitalize sm-mar-top"
  }, _vm._l(_vm.tags, function (tag) {
    return _c('span', {
      key: tag.id,
      staticClass: "dialer-call-prefix tag"
    }, [_c('small', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color",
        value: tag.colour,
        expression: "tag.colour"
      }]
    }, [_c('span', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text",
        value: tag.text_color,
        expression: "tag.text_color",
        modifiers: {
          "text": true
        }
      }]
    }, [_vm._v(" " + _vm._s(tag.tag) + " ")])])]);
  }), 0)])])]) : _vm._e(), _vm.note ? _c('li', [_c('div', {
    staticClass: "note"
  }, [_c('span', {
    staticClass: "info-area w-100"
  }, [_c('div', {
    staticClass: "call-date"
  }, [_c('span', {
    staticClass: "d-block"
  }, [_vm._v("Call Note")])]), _c('span', {
    staticClass: "small text-capitalize"
  }, [_vm._v(" " + _vm._s(_vm.note) + " ")])])])]) : _vm._e(), _c('li', [_c('span', {
    staticClass: "info-area"
  }, [_c('div', {
    staticClass: "call-date"
  }, [_c('span', {
    staticClass: "d-block mb-3"
  }, [_vm._v("History")])])])]), _vm.isEmpty(_vm.data.history) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api_sent.history ? '' : 'Sorry, There is no History',
      "design": 3
    }
  }, [_vm.api_sent.history ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e(), _vm._l(_vm.data.history, function (call_history, index) {
    return _c('Item', {
      key: index,
      attrs: {
        "call": call_history,
        "type": 'history'
      }
    });
  })], 2)])])]) : _vm._e()])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }