import { AMI_LOGGER } from '@/utils/console';
import { io } from "socket.io-client";
import { VOIP_API } from '@/utils/index';
import { TokenService } from '@/services/storage.service';

const Emitter = require('component-emitter');
export const AMI_SOCKET = {
  socket: null,
  token: '',
  connected: false,
  connecting: false,
  init(){
    try {
      AMI_LOGGER.log('Init')
      Emitter(this)
      window.ami_socket = this
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  async connect(){
    if(this.connecting) return;
    try {
      this.connecting=true
      AMI_LOGGER.log('Connect')
      let token = '' 
      let url = ''
      const current_user = TokenService.USER.get()
      if(process.env.VUE_APP_AMI_LOCALLY) {
        const { data: { token: ami_token } } = await VOIP_API.endpoints.ami.generateToken({
          extension_accountcode: current_user.sip.user, 
          user_accountcode: current_user.account, 
          default_accountcode: current_user.default_accountcode, 
          uid: current_user.uid, 
          is_admin: current_user.administrator_account ? '1' : '0',
        })
        token=ami_token
        url=`http://ami.server:5022`
      } else {
        const { data: { token: ami_token, url: ami_url } } = await VOIP_API.endpoints.ami.authenticate({
          extension_account: current_user.sip.user, 
        })
        token=ami_token
        url=ami_url
      }
      this.disconnect()
      this.socket = io(url,{
        autoConnect: false,
        closeOnBeforeunload: true,
        auth(cb){
          cb({
            token: token
          })
        }
      });
      this.socket.connect()
      this.socket.on('connect',this.onConnect.bind(this))
      this.socket.on('disconnect',this.onDisconnect.bind(this))
      this.socket.on("connect_error", this.onConnectionError.bind(this));
      this.socket.onAny(this.onAnyEvent.bind(this));
    } catch (ex) {
      AMI_LOGGER.danger('Connect',ex)
    } finally {
      this.connecting=false
    }
  },
  disconnect(){
    this.socket?.disconnect?.()
  },
  onConnect(){
    AMI_LOGGER.connected()
    this.emit('connect')
    this.connected=true
  },
  async onConnectionError(err){
    if (err.message === "un authorized") {
      let token = ''
      const current_user = TokenService.USER.get()
      if(process.env.VUE_APP_AMI_LOCALLY) {
        const { data: { token: ami_token } } = await VOIP_API.endpoints.ami.generateToken({
          extension_accountcode: current_user.sip.user, 
          user_accountcode: current_user.account, 
          default_accountcode: current_user.default_accountcode, 
          uid: current_user.uid, 
          is_admin: current_user.administrator_account ? '1' : '0',
        })
        token=ami_token
      } else {
        const { data: { token: ami_token/*, url: ami_url*/ } } = await VOIP_API.endpoints.ami.authenticate({
          extension_account: current_user.sip.user, 
        })
        token=ami_token
      }
      this.socket.auth.token = token;
      this.socket.connect();
    }
  },
  onDisconnect(){
    AMI_LOGGER.disconnected()
    this.emit('disconnect')
    this.connected=false
  },
  onAnyEvent(event,payload){
    AMI_LOGGER.event(event,payload)
    this.emit(event,payload)
  },
  transferCall(message){
    AMI_LOGGER.log('transfer-call',message)
    this.socket?.emit?.('transfer-call',message)
  },
  idleState(message){
    AMI_LOGGER.log('idle-state',message)
    this.socket?.emit?.('idle-state',message)
  },
}