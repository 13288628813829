<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @opened="onOpen()"
      @before-close="onBeforeClose()"
    >
      <div v-if="conditions.screen==3" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.screen = 2" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Call Type</div>
      </div>
      <div v-else-if="conditions.screen==2" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.screen = 0" />
        </div>
        <div class="dialer-settings-title topMainHeading">Calls</div>
      </div>
      <div v-else-if="conditions.screen==1" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.screen = 0" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Date</div>
      </div>
      <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Users Analytics</div>
      </div>

      <div class="innerModalIos">
        <template v-if="conditions.screen==3">
          

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              class="basicWhiteIOScard-item" 
              v-for="calltype in calltypes" 
              :key="calltype.value" 
              @click="filter.stat.call_type = calltype.value;conditions.screen = 2;"
            >
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">{{ calltype.text }}</div>
                </div>  
              </div>
              <vb-icon v-if="filter.stat.call_type == calltype.value" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            </div>
          </div>

        </template>
        <template v-else-if="conditions.screen==2">
          

          <div class="basicWhiteIOScard mt-32px mb-16px">
            
            <!-- filter -->
            <div class="basicWhiteIOScard-item" @click="conditions.screen=3">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Call Type</div>
                </div>  
              </div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard textAlignRight text-capitalize onRightSide  mr-8px">{{ selectedCallType.value }}</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
              </div>
            </div>
          </div>  
          <div class="basicWhiteIOScard mt-16px mb-16px">  
            <div>
              <div class="basicWhiteIOScard-item" v-for="(call, key) in filterStat" :key="key">
                <div class="d-flex align-items-center">
                  <div class="basicIOSIcon mr-16px">
                    <vb-icon :icon="call | callIcon(getCurrentUser.account)" height="13.922" width="13.922"/>
                  </div>
                  <div class="headingInsideTable">{{ call | name }}</div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight text-capitalize onRightSide">
                    {{ call.calldate | formatDate }}
                    {{ call.duration | timecode }}
                  </div>
                </div>
              </div>
              <vb-no-record v-if="isEmpty(filterStat)" :text="api.stat.send?'':'There is no Call Logs'" :design="3">
                <vb-loading v-if="api.stat.send" slot="loading" />
              </vb-no-record>
            </div>

          </div>

        </template>
        <template v-else-if="conditions.screen==1">
          
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              class="basicWhiteIOScard-item" 
              v-for="(tab, index) in tabs" 
              :key="index" 
              @click="selected.tab = tab.value; !isCustom ? fetchstat() : ''; !isCustom ? conditions.screen = 0 : '';"
            >
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">{{ tab.text }}</div>
                </div>  
              </div>
              <vb-icon v-if="selected.tab === tab.value" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            </div>
          </div>
          <template  v-if="isCustom">
            <div v-if="isCustom" class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2 forDateTime">
                  <label>From</label>
                  <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.stat.start_date" id="starttime" />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2 forDateTime">
                  <label>To</label>
                  <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class=" date-time-input" v-model="filter.stat.end_date" id="endtime" />
                </div>
              </div>
            </div>
            <a class="IosFullWidthButton forDone mb-16px" @click="fetchstat();conditions.screen = 0">Fetch</a>
          </template>

        </template>
        <template v-else>
          <!-- filter -->
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" @click="conditions.screen=1">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Date</div>
                </div>  
              </div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard textAlignRight onRightSide mr-8px">{{ selectedTab.text }}</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
              </div>
            </div>
          </div>
          <!-- calls -->
          <div class="basicWhiteIOScard mt-16px mb-16px">
            <div class="basicWhiteIOScard-item" @click="conditions.screen=2">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Call logs</div>
                </div>
              </div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
          </div>
          <!-- success rate -->
          <div class="basicWhiteIOScard mt-16px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Success RATE</div>
                </div>  
              </div>
              <div class="textInsideCard textAlignRight onRightSide">{{ response.stat.successRate }} %</div>
            </div>
          </div>

          

          <div>
            <div class="basicWhiteIOScard mt-16px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="d-flex align-items-center">
                  <div class="basicIOSIcon circularProgressBar mr-16px">
                    <vb-circular-progressive-bar :percentage="100"/>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="headingInsideTable">Total Calls</div>
                  </div>  
                </div>
                <div class="d-flex align-items-center">
                  <vb-icon icon="CallSummary-TotalCall-icon" class="rightFacingArrow mr-16px" width="16.642" height="15.275" />
                  <div class="textInsideCard textAlignRight onRightSide">{{ callpercentage.total }}</div>
                </div>
              </div>
            </div>
            <div class="basicWhiteIOScard mt-16px mb-16px">
              <div class="basicWhiteIOScard-item dialer-missed">
                <div class="d-flex align-items-center">
                  <div class="basicIOSIcon circularProgressBar mr-16px">
                    <vb-circular-progressive-bar :percentage="callpercentage.missed_percentage"/>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="headingInsideTable">Missed Calls</div>
                  </div>  
                </div>
                <div class="d-flex align-items-center">
                  <vb-icon icon="CallSummary-MissedCall-icon" class="rightFacingArrow mr-16px" width="16.642" height="15.275" />
                  <div class="textInsideCard textAlignRight onRightSide">{{ callpercentage.missed }}</div>
                </div>
              </div>
            </div>
            <div class="basicWhiteIOScard mt-16px mb-16px">
              <div class="basicWhiteIOScard-item dialer-inbound">
                <div class="d-flex align-items-center">
                  <div class="basicIOSIcon circularProgressBar mr-16px">
                    <vb-circular-progressive-bar :percentage="callpercentage.incomming_percentage"/>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="headingInsideTable">Inbound Calls</div>
                  </div>  
                </div>
                <div class="d-flex align-items-center">
                  <vb-icon icon="CallSummary-InboundCall-icon" class="rightFacingArrow mr-16px" width="16.642" height="15.275" />
                  <div class="textInsideCard textAlignRight onRightSide">{{ callpercentage.incomming }}</div>
                </div>
              </div>
            </div>
            <div class="basicWhiteIOScard mt-16px mb-16px">
              <div class="basicWhiteIOScard-item dialer-outbound">
                <div class="d-flex align-items-center">
                  <div class="basicIOSIcon circularProgressBar mr-16px">
                    <vb-circular-progressive-bar :percentage="callpercentage.outgoing_percentage"/>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="headingInsideTable">Outbound Calls</div>
                  </div>  
                </div>
                <div class="d-flex align-items-center">
                  <vb-icon icon="CallSummary-OutboundCall-icon" class="rightFacingArrow mr-16px" width="16.642" height="15.275" />
                  <div class="textInsideCard textAlignRight onRightSide">{{ callpercentage.outgoing }}</div>
                </div>
              </div>
            </div>
          </div>
          
          <!-- graph -->
          <div class="basicWhiteIOScard mb-16px">
            <div class="basicWhiteIOScard-item">
              <div style="width: 100%; overflow: hidden" class="visavail mt-3" :id="`conatiner-measure-${data.time_line.measure}`">
                <p :id="`p-measure-${data.time_line.measure}`" style="overflow: hidden"></p>
              </div>
            </div>
          </div>
            

          

        </template>

      </div>
    </modal>
  </div>
</template>

<script>
import * as visavail from "visavail";
import { mapGetters } from 'vuex';
import { VOIP_API } from "@/utils";
import { ctkDateTimePicker } from '@/mixin';
let tabs = {
  today: {
    text: "Today",
    value: "today",
  },
  week: {
    text: "This Week",
    value: "week",
  },
  month: {
    text: "This Month",
    value: "month",
  },
  custom: {
    text: "Custom",
    value: "custom",
  },
}
let call_types = [
  {
    text: "All",
    value: "all",
  },
  {
    text: "Outgoing Calls",
    value: "outgoing",
  },
  {
    text: "Incoming Calls",
    value: "incoming",
  },
  {
    text: "Missed Calls",
    value: "missed",
  },
]
export default {
  name: "UserAnalyticsModal",
  mixins: [ctkDateTimePicker],
  inject:['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'UserAnalyticsModal',
    },
  },
  data() {
    return {
      conditions: {
        screen: 0,
      },
      response: {
        stat: {},
      },
      selected: {
        tab: tabs.today.value
      },
      filter: {
        stat: {
          call_type: call_types[0].value,
          start_date: null,
          end_date: null,
          date: null,
        },
      },
      data: {
        time_line: {},
        user: {},
      },
      api: {
        stat: this.$helperFunction.apiInstance(),
      },
    };
  },
  filters: {
    callImage(value,account){
      if(value.disposition=='ANSWERED' && value?.source?.accountcode==account) return require("@/assets/images/dialed_call_icon.svg");
      else if(value.disposition=='ANSWERED' && value?.source?.accountcode!=account) return require("@/assets/images/received_call_icon.svg");
      else if(['FAILED','NO ANSWER'].includes(value.disposition)) return require("@/assets/images/missed_call_icon.svg");
      else return require("@/assets/images/dialed_call_icon.svg");
    },
    callIcon(value,account){
      // if(value.disposition=='ANSWERED' && value?.source?.accountcode==account) return require("@/assets/images/dialed_call_icon.svg");
      // else if(value.disposition=='ANSWERED' && value?.source?.accountcode!=account) return require("@/assets/images/received_call_icon.svg");
      // else if(['FAILED','NO ANSWER'].includes(value.disposition)) return require("@/assets/images/missed_call_icon.svg");
      // else return require("@/assets/images/dialed_call_icon.svg");
      if(value.disposition=='ANSWERED' && value?.source?.accountcode==account) return "NumberBlocking-outgoing-IOSIcon";
      else if(value.disposition=='ANSWERED' && value?.source?.accountcode!=account) return "NumberBlocking-incomming-IOSIcon";
      else if(['FAILED','NO ANSWER'].includes(value.disposition)) return "NumberBlocking-missed-IOSIcon";
      else return "NumberBlocking-outgoing-IOSIcon";
    },
    formatDate(value) { return new Date(value)?.toDateString?.() ?? '-'; },
    name(value) { return (['ANSWERED','NO ANSWER'].includes(value?.disposition) ? value?.source : value?.destination)?.extn ?? '-' },
    timecode(value) {
      var seconds = Math.floor(value % 60).toString();
      var minutes = Math.floor(value / 60).toString();
      if (seconds.length === 1) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    },
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
    tabs(){ return tabs },
    selectedTab(){ return Object.values(tabs).find(tab=>tab.value==this.selected.tab) },
    selectedCallType(){ return call_types.find(ct=>ct.value==this.filter.stat.call_type) },
    isCustom(){ return this.selected.tab == tabs.custom.value },
    calltypes(){ return call_types },
    filterStat() {
      const call_type = this.filter.stat.call_type
      const array = this.response.stat.callLogs || []
      return array.filter(item=>{
        if(call_type=='all') return true;
        else if(call_type=='outgoing' && item.disposition=='ANSWERED' && item?.source?.accountcode==this.getCurrentUser.account) return true;
        else if(call_type=='incoming' && item.disposition=='ANSWERED' && item?.source?.accountcode!=this.getCurrentUser.account) return true;
        else if(call_type=='missed' && ['FAILED','NO ANSWER'].includes(item.disposition)) return true;
        return false
      })
    },
    callpercentage(){
      const total = this.response.stat.totalCalls || 0 //  || this.data.user.totalCalls
      const incomming = this.response.stat.totalIncommingCalls || 0 //  || this.data.user.totalIncommingCalls
      const missed = this.response.stat.totalMissCalls || 0 //  || this.data.user.totalMissCalls
      const outgoing = this.response.stat.totalOutgoingCalls || 0 //  || this.data.user.totalOutgoingCalls
      const incomming_percentage = incomming > 0 ? Math.ceil((incomming / total) * 100) : 0;
      const outgoing_percentage = outgoing > 0 ? Math.ceil((outgoing / total) * 100) : 0;
      const missed_percentage = missed > 0 ? Math.ceil((missed / total) * 100) : 0;
      return {
        total,
        incomming,
        outgoing,
        missed,
        incomming_percentage,
        outgoing_percentage,
        missed_percentage,
      };
    },
    visavailData(){
      const extensions = this.data.time_line?.exten?.map?.(item=>{
        return {
          ...item,
          measure: item.accountname.includes('extension') ? item.accountname.replace('extension','app') : item.accountname=='WEBEXT' ? 'web app' : item.accountname=='DESKTOPEXT' ? 'desktop app' : item.accountname=='MOBILEEXT' ? 'mobile app' : item.accountname=='HEADEXT' ? 'headset app' : 'web app'
        }
      }) ?? []
      if(extensions.length>0){
        extensions.unshift({
          accountname: this.data.time_line?.measure,
          data: this.data.time_line?.data,
          interval_s: this.data.time_line?.interval_s,
          measure: "User",
        })
      }
      return extensions
    },
  },
  watch: {
    "conditions.screen"(screen){
      let vm = this
      if(screen==0) vm.$nextTick().then(()=>vm.generateGraph())
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.user = event.params.user;
      this.data.time_line = event.params.time_line;
      this.fetchstat();
    },
    onOpen(){
      this.generateGraph()
    },
    onBeforeClose(){
      this.conditions.screen=0
      this.response.stat={}
      this.selected.tab=tabs.today.value
      this.filter.stat.call_type=call_types[0].value
      this.filter.stat.start_date=null
      this.filter.stat.end_date=null
      this.filter.stat.date=null
      this.data.time_line={}
      this.data.user={}
      this.api.stat.reset()
    },
    generateGraph(){
      if(!this.data.time_line.measure) return;
      const el = document.getElementById(`conatiner-measure-${this.data.time_line.measure}`)
      if(!el) return;
      visavail.generate({
        id_div_container: `conatiner-measure-${this.data.time_line.measure}`,
        id_div_graph: `p-measure-${this.data.time_line.measure}`,
        date_in_utc: false,
        width: document.getElementById(`p-measure-${this.data.time_line.measure}`).offsetWidth,
        line_spacing: 24,
        title: {
          // text: "Phone Status Timeline",
          text: "Phone Status",
        },
        icon: {
          class_has_data: "fas fa-fw fa-check",
          class_has_no_data: "fas fa-fw fa-exclamation-circle",
        },
        tooltip: {
          height: 18,
          position: "overlay",
          left_spacing: 20,
          only_first_date: true,
          date_plus_time: true,
        },
        responsive: {
          enabled: true,
        },
      },this.visavailData);
    },
    fetchstat(){
      let vm = this
      if(vm.api.stat.send) return;
      if(vm.isCustom && (!vm.filter.stat.start_date || !vm.filter.stat.end_date)){
        vm.response.stat={}
        return;
      }
      vm.filter.stat.call_type='all'
      vm.api.stat.send=true
      VOIP_API.endpoints.analytics.singlestat({
        type: vm.selected.tab,
        accountcode: vm.data.time_line.measure,
        start: vm.filter.stat.start_date,
        end: vm.filter.stat.end_date,
      })
      .then(({ data: { response: { data: stat, date_label } } }) => {
        vm.response.stat = stat;
        vm.filter.stat.date = date_label
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.stat.send=false
      });
    },
  },
};
</script>

<style scoped>
@import "../../../../../node_modules/visavail/visavail.min.css";
.allow_scroll {
  height: calc(100vh - 460px);
  overflow-y: auto;
  margin: 10px 0px;
}
.dialer-dropdown .dropdown .dropdown-menu {
  border: 0;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.21);
  width: 100%;
  min-width: 580px;
  line-height: normal;
  padding: 10px;
  transform: none;
  left: unset;
  right: 0;
  top: 100%;
}
.dialer-dropdown .dropdown .dropdown-menu li ul {
  box-shadow: none;
}
.dialer-dropdown .dropdown .b-dropdown-text {
  padding: 0px;
}
</style>
