import { VOIP_API } from '@/utils/index';
import { initializeApp } from 'firebase/app';
import { signInWithPhoneNumber, RecaptchaVerifier, getAuth  } from 'firebase/auth';

export const FIREBASE = {
  init(){
    try {
      initializeApp({
        // apiKey: "AIzaSyDa4VXIMcb-a1iZJsHcIpJI7nVcg3TJRCo",
        // authDomain: "textmessage-e3a47.firebaseapp.com",
        // databaseURL: "https://textmessage-e3a47.firebaseio.com",
        // projectId: "textmessage-e3a47",
        // storageBucket: "textmessage-e3a47.appspot.com",
        // messagingSenderId: "325828818760",
        // appId: "1:325828818760:web:03c7bf3d333f7966ac257d",
        // measurementId: "G-WR73T6TP9V",
        
        apiKey: "AIzaSyA9C28mXkWzvAIKdtETSTt13WJr6wRSA_U",
        authDomain: "voipbusiness-1dc67.firebaseapp.com",
        databaseURL: "https://voipbusiness-1dc67-default-rtdb.firebaseio.com",
        projectId: "voipbusiness-1dc67",
        storageBucket: "voipbusiness-1dc67.appspot.com",
        messagingSenderId: "157270702201",
        appId: "1:157270702201:web:206cf3f1e74c0b5272fca8",
        measurementId: "G-7ZFF37N69R"
      });
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  recaptchaRender(id){
    const auth = getAuth()
    const recaptchaVerifier = new RecaptchaVerifier(id,{}, auth);
    recaptchaVerifier.render();
    return recaptchaVerifier
  },
  sendOTP(number,recaptchaVerifier){
    const auth = getAuth()
    return signInWithPhoneNumber(auth, number, recaptchaVerifier)
  },
  verify(code,coderesult){
    return coderesult?.confirm?.(code)
  },
  exceptionErrorType(ex){
    if(ex?.message?.startsWith?.('Firebase')){
      // console.log(ex)
      return ex?.message?.replace?.('Firebase: Error (','')?.replace?.(').','') ?? ''
    }
    return ''
  },
  exceptionErrorMessage(ex){
    let message = ''
    const type = this.exceptionErrorType(ex)
    if(type){
      switch (type) {
        case 'auth/invalid-phone-number':
          message = 'Invalid Number'
          break;
        case 'auth/invalid-verification-code':
          message = 'Invalid Pincode'
          break;
        default:
          message = VOIP_API.errorMessages.default
          break;
      }
    }
    return message
  },
}