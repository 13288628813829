<template>
  <modal
    class="dialer_animation center-zoomIn-popup m-w-600 dialer-addMemberModal"
    name="AddMembersInChatModal"
  >
    <div class="dialer-chatBox-inVideo">
      <div class="dialer-videoCall-addMember mb-0 pt-0 position-relative">
        <div class="px-3 py-3 border-bottom sticky-top">
          <div class="d-flex align-items-center justify-content-between">
            <span
              class="close-popup cursor_pointer ml-2"
              @click="$modal.hide('AddMembersInChatModal')"
            >
              <b-icon icon="x" scale="1.8"></b-icon>
            </span>
            <h4 class="mb-0">Add Members</h4>
            <button
              type="button"
              class="newDoneButton"
              @click="$modal.hide('AddMembersInChatModal')"
            >
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
          <div class="pt-3 px-2">
            <div class="searchBox">
              <b-form @submit.prevent="''" class="position-relative">
                <b-form-input v-model="search" class="w-100"></b-form-input>
                <b-icon
                  icon="search"
                  scale="1"
                  class="position-absolute cursor_pointer"
                />
                <b-icon
                  icon="x-circle"
                  scale="1"
                  class="position-absolute cursor_pointer"
                />
              </b-form>
            </div>
          </div>
        </div>
        <ul class="items">
          <li
            v-for="user in otherMembers"
            :key="user.voipaccount"
            v-show="$store.getters.getCurrentUser.account !== user.voipaccount"
            class="item cursor_pointer"
            @click="add(user)"
          >
            <div class="info">
              <div class="image-container">
                <img :src="user.profile_img" @error="$event.target.src = user_male" />
              </div>
              <div>
                <h5 class="text-capitalize">{{ user | get_property('user.display_name') }}</h5>
              </div>
            </div>
            <div class="actions">
              <div class="action">
                <b-icon icon="plus-square" scale="1.2" variant="success" />
              </div>
            </div>
          </li>
          <li v-for="user in addedMembers" :key="user.voipaccount" v-show="$store.getters.getCurrentUser.account !== user.voipaccount" class="item cursor_pointer" @click="remove(user)">
            <div class="info">
              <div class="image-container">
                <img :src="user.profile_img" @error="$event.target.src = user_male" />
              </div>
              <div>
                <h5 class="text-capitalize">{{ user | get_property('user.display_name') }}</h5>
              </div>
            </div>
            <div class="actions">
              <div class="action">
                <b-icon icon="X-square" scale="1.2" variant="danger" />
              </div>
            </div>
          </li>
          <vb-no-record v-if="isEmpty(otherMembers) && isEmpty(addedMembers)" text="There in no user" :design="3" />
        </ul>
      </div>
    </div>
  </modal>
</template>

<script>
import { mattermost, MATTERMOST_API } from "@/MatterMost";
import _ from "lodash";
export default {
  name: "AddMembersInChatModal",
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    members: {
      type: Array,
      default: () => [],
    },
    channelData: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      search: "",
      user_male: require("@/assets/images/user-male.png"),
    };
  },
  inject:['isEmpty'],
  computed: {
    lastPost() {
      if (_.isEmpty(this.channelData)) return {};
      if (this.channelData.posts.order.length === 0) return {};
      const order = this.channelData.posts.order;
      const id = order[order.length - 1];
      return this.channelData.posts.posts[id];
    },
    addedMembers() {
      const user_ids = this.members.map((member) => member.user_id);
      const added_members = Object.values(
        this.$store.state.common.voipusers
      ).filter((user) => user_ids.indexOf(user?.user?.user_chat_id) >= 0);
      if (!this.search) return added_members;
      var searchRegex = new RegExp(this.search.toLowerCase().trim(), "i");
      return added_members.filter(
        (user) =>
          searchRegex.test(user.extn) || searchRegex.test(user?.user?.display_name)
      );
    },
    otherMembers() {
      const user_ids = this.members.map((member) => member.user_id);
      const other_members = Object.values(
        this.$store.state.common.voipusers
      ).filter((user) => user_ids.indexOf(user?.user?.user_chat_id) === -1);
      if (!this.search) return other_members;
      var searchRegex = new RegExp(this.search.toLowerCase().trim(), "i");
      return other_members.filter(
        (user) =>
          searchRegex.test(user.extn) || searchRegex.test(user?.user?.display_name)
      );
    },
  },
  methods: {
    add(user) {
      MATTERMOST_API.endpoints.channels.addChannelMember(this.channel.id, {
        user_id: user?.user?.user_chat_id,
        post_root_id: !_.isEmpty(this.lastPost) ? this.lastPost.id : "",
      }).then(({ data })=>{
        mattermost.emitOnUserLevel(mattermost.events.user_added,{
          user_id: data?.user_id,
          channel_id: data?.channel_id,
        })
      });
    },
    async remove(user) {
      await MATTERMOST_API.endpoints.channels.removeChannelMember(
        this.channel.id,
        user?.user?.user_chat_id
      );
      mattermost.emitOnUserLevel(mattermost.events.user_removed,{
        user_id: user?.user?.user_chat_id,
        channel_id: this.channel.id,
      })
    },
  },
};
</script>

<style lang="scss">
.dialer-chatBox-inVideo {
  .dialer-videoCall-addMember {
    padding: 0px 0px 20px;
    h5 {
      font-size: 1rem;
      font-weight: 600;
      padding: 0px 20px;
    }
    .items {
      padding: 0px;
      margin: 0px;
      .item {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info {
          display: flex;
          max-width: calc(100% - 30px);
          align-items: center;
          img {
            height: inherit;
            width: 40px;
            border-radius: 50%;
          }
          div {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            height: 100%;
            h5 {
              margin: 0;
              color: $black;
            }
            p {
              margin: 0;
            }
          }
        }
        .actions {
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            margin: 0px;
            position: relative;
            top: -15px;
            font-size: 13px;
          }
          div {
            cursor: pointer;
            border-radius: 50%;
            color: white;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          div.success {
            background-color: var(--success);
          }
          div.danger {
            background-color: var(--danger);
          }
          .action {
            color: $purple;
          }
          .dialer-button{
            &:not(.dialer-button-blank) {
              display: flex;
              align-items: center;
              background-color: $white;
              border: 2px solid $primary;
              color: $primary;
              font-weight: 700;
              &.message-sent{
                svg{
                  margin-right: 10px;
                  fill:$white;
                }
              }
              &:hover, &.message-sent{
                background: $primary;
                border-color: $primary;
                color: $white;
              }
            }
          }
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  .dialer-addAble-users-array {
    .dialer_contact_row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow-x: auto;
      margin: 0px 10px;
      max-width: calc(100% - 20px);
      .dialer_contact {
        padding: 7px 5px;
        width: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
        .dialer_contact_img {
          position: relative;
          margin-right: 10px;
          cursor: auto;
          img {
            width: 41px;
            height: 41px;
            @include border-radius(50%);
            object-fit: cover;
          }
        }
      }
      &:hover {
        background: transparent;
      }
    }
    .close-popup {
      top: -2px;
      right: -2px;
    }
  }
  .dialer-button {
    &:not(.dialer-button-blank) {
      height: 35px;
      line-height: 35px;
      @include border-radius(50px);
      background: $purple;
    }
  }
  .sticky-top {
    background: $white;
  }
}

// slide top to bottom &bottom to top animation
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
