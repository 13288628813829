<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Assign Number</div>
        <div class="plus-IOSIcon-container" @click="$modal.show('AddNumber')">
          <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992" />
        </div>
      </div>
      <div class="innerModalIos">
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-form-input placeholder="Search Numbers" v-model="filter.numbers.search" onkeypress="return /([0-9])/i.test(event.key)" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
        <div class="basicWhiteIOScard mt-16px mb-16px">
          <div 
            class="basicWhiteIOScard-item" 
            v-for="(number, index) in filterNumbers" 
            :key="index" 
            @click="
              api.add.send ? '' :
              selected.numbers.includes(number) ? 
              selected.numbers.splice(selected.numbers.indexOf(number), 1) : 
              selected.numbers.push(number)
            "
          >
            <div class="headingInsideTable">{{ number | number_formater }}</div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center forRadio-container">
                <vb-icon v-if="selected.numbers.includes(number)" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />   
              </div>
            </div>
          </div>
          <vb-no-record v-if="!filterNumbers.length" :text="api.list.send?'':'There is no Numbers'" :design="3">
            <vb-loading v-if="api.list.send" slot="loading" />
          </vb-no-record>
        </div>
        <b-button class="IosFullWidthButton forDone mt-16px mb-16px" v-if="selected.numbers.length" :disabled="api.add.send" @click="add()">Add Numbers</b-button>
        <AddNumbersModal @added="fetchNumbers()" />
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API, } from "@/utils";
import AddNumbersModal from "./AddNumber.vue";
export default {
  name: "AssignNumberModal",
  components: {
    AddNumbersModal
  },
  props: {
    modalName: {
      type: String,
      default: "AssignNumber",
    },
  },
  data() {
    return {
      api: {
        add: this.$helperFunction.apiInstance({  }),
        list: this.$helperFunction.apiInstance({  }),
      },
      filter: {
        numbers: {
          search: "",
          reset() {
            this.search = "";
          },
        },
      },
      response: {
        numbers: [],
        reset() {
          this.numbers = [];
        },
      },
      selected: {
        numbers: [],
        reset() {
          this.numbers = [];
        },
      },
      data: {
        accountcode: "",
        reset() {
          this.accountcode = "";
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    filterNumbers() {
      const search = `${this.filter.numbers.search}`.trim().toLowerCase();
      return this.response.numbers.filter((number) => `${number}`.replace(/-/g,'').trim().toLowerCase().includes(search));
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.accountcode = event?.params?.accountcode || "";
      this.fetchNumbers();
    },
    onBeforeClose() {
      this.response.reset();
      this.selected.reset();
      this.filter.numbers.reset();
      this.data.reset();
    },
    add() {
      let vm = this
      if (vm.api.add.send) return;
      vm.api.add.send = true;
      VOIP_API.endpoints.telephone_number.assign(vm.data.accountcode, {
        numbers: vm.selected.numbers.join("&"),
        account: vm.data.accountcode,
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully Updated",
          type: "success",
        })
        vm.$emit("added", {
          selected_list: vm.selected.numbers,
        });
        vm.$modal.hide(vm.modalName);
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.add.send = false;
      });
    },
    fetchNumbers() {
      let vm = this;
      vm.api.list.send = true;
      VOIP_API.endpoints.telephone_number.availablelist(vm.data.accountcode)
      .then(({ data: { data: { available, assigned_to_same_account }, }, }) => {
        vm.response.numbers = available;
        vm.selected.numbers = assigned_to_same_account;
      })
      .finally(() => {
        vm.api.list.send = false;
      });
    },
  },
};
</script>
