var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Topup Products")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "IosTabsSection twoTabs mt-16px mb-16px"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.value,
      staticClass: "IosTabButton",
      class: {
        'active': _vm.selected.tab == tab.value
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
        }
      }
    }, [_vm._v(" " + _vm._s(tab.text) + " ")]);
  }), 0), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_vm.selected.tab == _vm.tabs.topup.value ? _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.response.products, function (product) {
    return _c('div', {
      key: product.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          return _vm.addTopup(product.whmcs_id);
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(product.label))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
    }, [_vm._v(_vm._s(product.price))]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)]);
  }), _vm.response.products.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.products.send ? '' : 'There is no playlist',
      "design": 3
    }
  }, [_vm.api.products.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2) : _vm.selected.tab == _vm.tabs.credits.value ? _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.credits, function (credit) {
    return _c('div', {
      key: credit.id,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(credit.amount))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
    }, [_vm._v(_vm._s(credit.description))])])]);
  }), _vm.credits.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.account_invoice.send ? '' : 'There is no playlist',
      "design": 3
    }
  }, [_vm.api.account_invoice.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2) : _vm._e()])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }