<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-call-setting">
      <div class="settingsFirstSection">
        <template v-if="getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button w-100">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">My calls</h2>
          </div>
        </template>
        <template v-else>
          <h2 class="dialer-settings-title mb-0 settingsFirstSection-left newer">
            <template v-if="EditUserModal">
              Calls
            </template>
            <template v-else>
              My calls
            </template>
            <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'calls' })" class="cursor_pointer infoKbIcon md-mar-left" />
          </h2>
        </template>
      </div>
      <div class="settingsSecondSection">
        <span class="dialer-settings-intro newerTextVersion mt-0 mb-0">Enhance your call experience. Take control of unanswered calls, availability hours, and call recording.</span>
      </div>
      <div class="dialer-form mt-0">
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="latestGreyBox-heading-main">Call information</div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
              <CallForwardingSetting 
                @cf-updated="$emit('cf-updated')" 
                :account="voipaccount" 
                :forwardCallerId="true" 
              />
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0">
              <ScheduleSetting 
                @ofh-days-updated="$emit('ofh-days-updated')"
                @ofh-toggle-updated="$emit('ofh-toggle-updated')"
                @ofh-default-updated="$emit('ofh-default-updated')"
                :account="voipaccount" 
              />
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <!-- Call Waiting Row -->
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Call waiting</div>
                <div class="latestGreyBox-descriptionText">If enabled, you will be able to receive multiple incoming calls at the same time.</div>
              </div>
              <div 
                v-b-tooltip :title="getUserPermissions.call_waiting?'':'Your package does not support this feature'"
                class="w-fit-content" 
                :class="{
                  'for-disable': !getUserPermissions.call_waiting
                }"
              >
                <template v-if="api.user_detail.send">
                  <div class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
                </template>
                <b-form-checkbox
                  v-else
                  ref="call_waiting"
                  :disabled="!getUserPermissions.call_waiting || api.update_call_waiting.send || api.user_detail.send"
                  class="newerSwitch"
                  :checked="!conditions.call_waiting"
                  @change="updateCallWaiting()"
                  name="check-button"
                  switch
                />
              </div>
            </div>
            <MusicOnHoldWidget :location="'whiteBoxWithBorderInsideGreyBox'" v-if="voipaccount" :accountcode="voipaccount" />
            <!-- call recording -->
            <UserCallRecordingWidget v-if="EditUserModal && voipaccount" :accountcode="voipaccount" 
              :allUserData="{
                extn: user.extn,
                user: user.display_name
              }" :editUserModal="EditUserModal"
            />
          </div>
        </div>
        <VoicemailSetting :accountcode="voipaccount" />
        <div class="latestGreyBox-9-9-2023 mt-24px">
          <div class="latestGreyBox-heading-main">Notifications</div>
          <NotificationSettingsWidget :accountcode="voipaccount" />
        </div>
      </div>
    </section>
  </div>
</template>

<script>

import MusicOnHoldWidget from "../widgets/MusicOnHold.vue";
import CallForwardingSetting from "../Common/CallForwardingSetting.vue";
import ScheduleSetting from "../Common/ScheduleSetting.vue";
import VoicemailSetting from "@/components/Voicemail/Setting.vue";
import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import NotificationSettingsWidget from '../widgets/calls/NotificationSettingsWidget.vue';
import UserCallRecordingWidget from '../widgets/users/CallRecording.vue';

export default {
  name: "CallCopyC",
  props: {
    accountcode: {
      type: String,
    },
    user: {
      type: Object,
    },
    EditUserModal: {
      type: Boolean,
      default: false,
    },
  },
  components: {
    CallForwardingSetting,
    ScheduleSetting,
    MusicOnHoldWidget,
    VoicemailSetting,
    NotificationSettingsWidget,
    UserCallRecordingWidget
  },
  data() {
    return {
      api: {
        update_call_waiting: this.$helperFunction.apiInstance(),
        user_detail: this.$helperFunction.apiInstance(),
      },
      response: {
        user_detail: {},
      },
      conditions: {
        call_waiting: false,
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
      'getIsMobile',
    ]),
    voipaccount(){ return this.accountcode || this.getCurrentUser.account },
    isCurrentUser(){ return this.voipaccount == this.getCurrentUser.account },
  },
  methods: {
    updateCallWaiting() {
      let vm = this;
      if(vm.api.update_call_waiting.send) return;
      vm.api.update_call_waiting.send = true;
      VOIP_API.endpoints.users.updateuserdetail({
        field: `call_waiting`,
        value: !vm.conditions.call_waiting,
        accountcode: this.voipaccount,
      })
      .then(() => {
        vm.conditions.call_waiting=!vm.conditions.call_waiting
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if(vm.$refs['call_waiting']) vm.$refs['call_waiting'].localChecked=!vm.conditions.call_waiting
        vm.api.update_call_waiting.send=false;
      });
    },
    fetchUserDetail() {
      let vm = this
      if (vm.api.user_detail.send) return;
      vm.api.user_detail.send = true;
      VOIP_API.endpoints.users.detail(vm.voipaccount)
      .then(({ data: detail }) => {
        vm.conditions.call_waiting=detail.callwaiting=='Y'
      })
      .finally(() => {
        vm.api.user_detail.send = false;
      });
    },
  },
  mounted() {
    if(this.accountcode){
      this.fetchUserDetail();
    }
  },
  activated(){
    this.fetchUserDetail();
  },
};
</script>
