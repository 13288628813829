<template>
  <a :status="call.status" :isLeft="call.status === 'Dialed' ? 'Right' : 'Left'" class="d-flex align-items-start justify-content-between text-dark callLogItself">
    
    <span :id="`boundToolTip-${random_id}`" class="ico text-white rounded-circle d-flex align-items-center justify-content-center" >
      
      <template v-if="call.call_type === 'audio'">
        <vb-svg v-if="call.status === 'Missed'" name="missedCall-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
        <vb-svg v-else-if="call.status === 'Received'" name="receivedCall-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
        <vb-svg v-else name="dialedCall-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
      </template>
      
      <template v-if="call.call_type === 'video'">
        <vb-svg v-if="call.status === 'Missed'" name="missedVideo-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
        <vb-svg v-else-if="call.status === 'Received'" name="receivedVideo-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
        <vb-svg v-else name="dialedVideo-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
      </template>
      <template v-if="call.call_type === 'voicemail'">
        <vb-svg name="voiceMessage-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
      </template>
      <template v-if="call.call_type === 'sms'">
        <vb-svg v-if="call.status === 'Dialed'" name="sendMessage-icon" width="19" height="18" viewBox="0 0 19 18" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
        <vb-svg v-else name="receivedMessage-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
      </template>
    </span>
    <b-popover :target="`boundToolTip-${random_id}`"  placement="top" triggers="hover">
      
      <small>{{ call.status === 'Dialed' ? 'Outbound' : 'Inbound' }}</small>
    </b-popover>
    
    
    <span class="info-area  px-2">
      
      <div class="call-date position-relative">
        
        <div class="d-flex flex-column justify-content-between" style="width:max-content;">
          
          <div>
            
            <span class="d-block callLogTitle">
              <template v-if="call.call_type === 'voicemail'">{{ call.caller.replace(/"/g, "") }}</template>
              <template v-if="call.status=='Missed' || call.status=='Received'">{{ `${call.sourceName || ""}` }}</template>
              <template v-else>{{ `${call.destinationName || ""}` }}</template>
            </span>
            
            <template v-if="call.call_type === 'voicemail' && call.is_shared == 'yes'">
              <span :id="`sharedText-${random_id}`" class="sharedText ml-2" style="left:0px;">shared</span> 
              <b-popover :target="`sharedText-${random_id}`" placement="top" triggers="hover focus"> 
                
                <small>{{ call | get_property('sharedByDetail.label','') }} <b>{{ call | get_property('sharedByDetail.extn','') }}</b></small>
              </b-popover>
            </template>

          </div>

          <span class="small text-capitalize mb-2">
            <template v-if="call.call_type === 'audio' || call.call_type === 'video'">
              
              <span class="dialer-call-prefix 1">
                <span v-if="(call.status=='Missed' || call.status=='Received') && call.sourceExt" class="d-block">{{ `${call.sourceExt || ""}` }}</span>
                <span v-else-if="(call.status!='Missed' || call.status!='Received') && call.destinationExt" class="d-block">{{ call.destinationExt || "" }}</span>
              </span>
              
              <span v-if="call.remote_caller_id" class="dialer-call-prefix 2">
                <span class="d-block " :class="((call.status=='Missed' || call.status=='Received') && call.sourceExt) || ((call.status!='Missed' || call.status!='Received') && call.destinationExt) ? '' : 'mt-17c'" style="padding:2px 0px;border-radius:8px;color:#393939;width:auto;font-size:12px;">
                  <b class="position-absolute" style="margin-top:0px;left: -35px;color:#5576d1">
                    via:
                  </b>
                  {{ call.remote_caller_id }}
                </span>
              </span>
            
            </template>
          </span>

        </div>

        <div class="dialpad_activity_box_other justify-content-start flex-column">
          <small class="duration ml-0 text-right">{{ call.call_date | showTime }}</small>
          <div class="dialpad_activity_box_other mt-2">
            <small class="duration ml-0">{{ duration | secondsTimer }}</small>
          </div>
        </div>
      
      </div>

      <span :class="['small text-capitalize' , condition.message && call.call_type === 'sms' ? 'messageText-container' : '']">
        
        <template v-if="call.call_type === 'audio' || call.call_type === 'video'">
          
          
          
          <!-- tag -->
          <template v-if="condition.tag">
            <span v-for="tag in tags" :key="tag.id" class="dialer-call-prefix tag">
              <small v-vb-tag-color="tag.colour">
                <span v-vb-tag-color.text="tag.text_color">
                  
                  {{ tag.tag }}
                </span>
              </small>
            </span>
          </template>
          
          <!-- note -->
          <span v-if="condition.note && note" class="dialer-call-prefix note">
            {{ note }}
          </span>
        
        </template>
        
        <template v-else-if="condition.recording && call.call_type === 'voicemail'">
          <div class="dialer-audio-playback">
            <vb-audio-player :src="call.voice | base64_to_url" />
          </div>
        </template>
        
        <template v-else-if="condition.message && call.call_type === 'sms'">
          <span class="dialer-call-prefix messageText">{{ call.body || "" }}</span>
        </template>

      </span>

    </span>

    <span class="dialer-dropdown dialpad_activity_box_menu d-flex flex-column align-items-center justify-content-between" v-if="type=='activity'">
      <span class="dialpad_activity_box_menu_list">
        <a v-if="call.call_type !== 'voicemail'" class="m-0 w-auto p-0" @click="calling(call, call.is_jitsi ? 'video' : 'audio')">
          <vb-svg name="dialer-call-smallIcon" width="26" height="26" viewBox="0 0 26 26" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
        </a>
      </span>

      <!-- NEW drop down START -->
      <b-dropdown varient="link" no-caret class="ico-filter">
        <template v-slot:button-content>
          <b-icon icon="three-dots" variant="#393939" scale="1.5" style="margin-top: 5px;"/>
        </template>
        <b-dropdown-item v-if="call.is_did=='1'" @click="$store.state.global_conditions.add_addressbooks = call.status=='Missed' || call.status=='Received' ? (call.sourceName || '') : (call.destinationName || '')">
          <vb-icon icon="dialer-addToContact-icon" width="16.245" height="15.004" />
          <span class="ml-3">Add Contact</span>
        </b-dropdown-item>
        
        <b-dropdown-item v-if="call.call_type !== 'voicemail'" @click="calling(call, call.is_jitsi ? 'video' : 'audio')">
          <b-icon icon="telephone" font-scale="0.96"/>
          <span class="ml-3">Call</span>
        </b-dropdown-item>
        <b-dropdown-item @click="$emit('onClickInfo1')">
          <vb-icon icon="dialer-callinfo-icon" width="15.7" height="15.701" />
          <span class="ml-3">{{ call.call_type === "voicemail" || call.call_type === "sms" ? "Message" : "Call" }} Info New</span>
        </b-dropdown-item>
        
      </b-dropdown>
      <!-- NEW drop down END -->
    </span>

  </a>
</template>

<script>
import { events, $fn, VOIP_API } from "../../../utils";
import _ from "lodash";
import { showTime } from '@/filter'
import { mapGetters } from 'vuex';
var interval_id;
export default {
  name: "CallActivityItem",
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    tags_note_show: {
      type: Boolean,
      default: true,
    },
    type: {
      type: String,
      default: 'activity',
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      api_sent: {
        delete: "",
      },
      duration_val: "",
      random_id: '',
      isShowMoreNote: false,
    };
  },
  inject: [
    'appNotify',
  ],
  filters: {
    tagTextColor(hexcolor){
      // console.log(hexcolor)
      if(!hexcolor) return '#ffffff'
      if (hexcolor.slice(0, 1) === '#') {
        hexcolor = hexcolor.slice(1);
      }
      var r = parseInt(hexcolor.substr(0,2),16);
      var g = parseInt(hexcolor.substr(2,2),16);
      var b = parseInt(hexcolor.substr(4,2),16);
      var yiq = ((r * 299) + (g * 587) + (b * 114)) / 1000;
      return (yiq >= 128) ? '#000000' : '#ffffff';
    },
    secondsTimer(value) {
      if (typeof value === "string") return value;
      let sec = value % 60;
      let min = Math.floor(value / 60) % 60;
      let hours = Math.floor(value / 60 / 60) % 60;
      return `${hours < 10 ? "0" + hours : hours || '0'}:${ min < 10 ? "0" + min : min || '0' }:${sec < 10 ? "0" + sec : sec || '0'}`;
    },
    showTime,
    formatDate(value) {
      if (!value) return "-";
      var ts = new Date(value);
      return ts.toDateString();
    },
  },
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getVoipCallTags: 'getVoipCallTags',
    }),
    duration() {
      if (this.isTimer) {
        return this.duration_val;
      } else {
        return this.call.billSec;
      }
    },
    isTimer() {
      return this.$store.state.call_activity_timer[this.call.uniqueid];
    },
    note() {
      if (!this.call.notes) return "";
      const [note] = this.call.notes.filter((item) => item.log !== null && item.type === "note");
      return note ? note.log : "";
    },
    tags() {
      const tags = this.getVoipCallTags
      const tag_ids = this.tagsId
      return tags.filter(item=>tag_ids.includes(item.real_id));
    },
    tagsId() {
      if (!this.call.notes) return [];
      return this.call.notes.filter((item) => item.type === "tag" && !!item.tag).map(tag=>tag.tag.id);
    },
    user() {
      if (this.call.dialable) {
        return this.$store.getters.getVoipUsersAlises[this.call.dialable];
      } else {
        return {};
      }
    },
    callID() {
      return this.call?.callerid;
    },
    condition(){
      return {
        tag: _.isUndefined(_.get(this.conditions,'tag')) ? true : _.get(this.conditions,'tag'),
        note: _.isUndefined(_.get(this.conditions,'note')) ? true : _.get(this.conditions,'note'),
        recording: _.isUndefined(_.get(this.conditions,'recording')) ? true : _.get(this.conditions,'recording'),
        message: _.isUndefined(_.get(this.conditions,'message')) ? true : _.get(this.conditions,'message'),
      }
    },
  },
  watch: {
    isTimer: {
      immediate: true,
      handler(obj) {
        if (!obj) return;
        const { init_time, end_time } = obj;
        let vm = this;
        const created = new Date(init_time);
        const now = new Date();
        let difMilli = now - created;
        vm.duration_val = Math.ceil(difMilli / 1000);
        if (end_time) return;
        interval_id = setInterval(() => {
          if (
            !this.$store.state.call_activity_timer[this.call.uniqueid] ||
            this.$store.state.call_activity_timer[this.call.uniqueid].end_time
          ) {
            clearInterval(interval_id);
          } else {
            const value = difMilli / 1000;
            vm.duration_val = Math.ceil(value);
            difMilli += 1000;
          }
        }, 1000);
      },
    },
  },
  methods: {
    calling(call, call_type) {
      const { dialable, is_jitsi } = call;
      if (is_jitsi) {
        const accounts = typeof dialable === "string" ? [dialable] : dialable;
        this.$root.$emit(events.video_call, { accounts, call_type });
      } else {
        this.$root.$emit(events.audio_call, { number: dialable });
      }
    },
    deleteCallActivity(call) {
      let vm = this;
      this.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this voice mail`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api_sent.delete = call.id;
            VOIP_API.endpoints.voicemail
              .delete({
                id: call.id,
              })
              .then(() => {
                this.$store.state.common.call_activity.data =
                  this.$store.state.common.call_activity.data.filter(
                    (item) =>
                      `${item.call_type}_${item.id}` !==
                      `${call.call_type}_${call.id}`
                  );
                vm.appNotify({
                  message: "Successfully Deleted!",
                  type: "success",
                })
              })
              .catch((error) => {
                vm.appNotify({
                  message:
                    _.get(error, "response.data.message") ||
                    "Something went wrong",
                  type: "danger",
                })
              })
              .finally(() => {
                vm.api_sent.delete = false;
              });
          }
        },
      });
    },
    onClickInfo() {
      this.$emit("onClickInfo");
    },
    sms(call) {
      this.$root.$emit(events.send_sms, { dialable: call.dialable });
    },
  },
  mounted(){
    this.random_id = $fn.makeid(10)
  },
};
</script>

<style>
</style>