var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" Translator "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between aling-items-center"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Translator ")]), _c('button', {
    staticClass: "newButton"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [_vm._v(" Add Locale ")])], 1)]), _vm.showEditSection == false ? _c('teamplate', _vm._l(_vm.translatorData, function (data) {
    return _c('div', {
      key: data.id,
      staticClass: "d-flex justify-content-between align-items-center crm-innerCard mt-4 w-100",
      staticStyle: {
        "cursor": "pointer"
      },
      on: {
        "click": function ($event) {
          _vm.showEditSection = true;
          _vm.editSectionData = data;
        }
      }
    }, [_c('div', {
      staticClass: "crm-card-heading my-0"
    }, [_vm._v(_vm._s(data.label))]), _c('b-icon', {
      attrs: {
        "icon": "chevron-down",
        "color": "black",
        "font-scale": "1.5"
      }
    })], 1);
  }), 0) : _vm._e(), _vm.showEditSection ? [_c('div', {
    staticClass: "crm-innerCard mt-4 w-100"
  }, [_c('div', {
    staticClass: "crm-card-heading my-0"
  }, [_vm._v(_vm._s(_vm.editSectionData.label))]), _c('vb-table', {
    staticClass: "mt-3",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.editSectionData.tableData),
      "listLength": _vm.editSectionData.tableData.length,
      "textNoRecord": " "
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.editSectionData.tableData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(data.key))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.source))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "crm-input-container mb-0"
          }, [_c('textarea', {
            staticStyle: {
              "height": "70px"
            },
            domProps: {
              "value": data.en
            }
          })])])]);
        });
      }
    }], null, false, 3863850379)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("KEY")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("SOURCE")])]), _c('th', {
    staticClass: "dialer-col-right"
  }, [_c('span', [_vm._v("EN")])])])])], 2)], 1)] : _vm._e()], 2), _vm.showEditSection == true ? _c('button', {
    staticClass: "newCloseButton mt-20px",
    on: {
      "click": function ($event) {
        _vm.showEditSection = false;
        _vm.editSectionData = {};
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Cancel")])], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }