<template>
  <modal class="dialer_animation right_side_popup developerOptionsModal" :name="modalName" @before-open="onBeforeOpen($event)" @before-close="onBeforeClose()">
    <div class="dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between">
      <h2 class="dialer-edit-title newer mb-0">Features</h2>
      <div class="dialer-edit-actions d-flex justify-content-end">
        <button class="newCloseButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    <div style="height: 85%; width: 100%; display: flex;">
      <div style="width: 50%; height: 100%; display: flex; flex-direction: column;">
        <div style="display: flex;">
          <button :disabled="api.fetch_old_call_activity.send" @click="fetchOldCallActivity()" :class="`${api.fetch_old_call_activity.send?'rotate-animation':''}`">
            <vb-icon icon="callActivity-refresh" width="18.979" height="17.257" />
          </button>
        </div>
        <div @scroll="onScrollOldCallActivity($event)" :id="`${_uid}-old-call-activity`" style="overflow-y: auto;">
          <template v-for="(call, index) in oldCallActivities">
            {{index+1}}
            <h3 :key="`${index}-date-call-activity`" v-if="call.own_date" class="pb-1 ddd">
              <span style="color:black;font-size:12px;"> {{ call.own_date | filter_date_current(false,{ unix: true }) }} </span>
            </h3>
            <li :key="index" style="list-style-type: none;">
              <CallActivityItem1 :call="call" />
            </li>
          </template>
        </div>
      </div>
      <!--<div style="width: 33.33%; height: 100%; display: flex; flex-direction: column;">
        <div style="display: flex;">
          <button :disabled="api.fetch_new_call_activity.send" @click="fetchNewCallActivity()" :class="`${api.fetch_new_call_activity.send?'rotate-animation':''}`">
            <vb-icon icon="callActivity-refresh" width="18.979" height="17.257" />
          </button>
        </div>
        <div @scroll="onScrollNewCallActivity($event)" :id="`${_uid}-new-call-activity`" style="overflow-y: auto;">
          <template v-for="(call, index) in newCallActivities">
            <h3 :key="`${index}-date-call-activity`" v-if="call.own_date" class="pb-1 ddd">
              <span style="color:black;font-size:12px;"> {{ call.own_date | filter_date_current('YYYY-MM-DD hh:mm:ss') }} </span>
            </h3>
            <li :key="index" style="list-style-type: none;">
              <CallActivityNewItem :call="call" />
              <!~~ <pre>{{call | jsonPrint}}</pre> ~~>
            </li>
          </template>
        </div>
      </div>-->
      <div style="width: 50%; height: 100%; display: flex; flex-direction: column;">
        <div style="display: flex;">
          <button :disabled="api.fetch_latest_call_activity.send" @click="fetchLatestCallActivity()" :class="`${api.fetch_latest_call_activity.send?'rotate-animation':''}`">
            <vb-icon icon="callActivity-refresh" width="18.979" height="17.257" />
          </button>
        </div>
        <div @scroll="onScrollLatestCallActivity($event)" :id="`${_uid}-latest-call-activity`" style="overflow-y: auto;">
          <template v-for="(call, index) in latestCallActivities">
            {{index+1}}
            <h3 :key="`${index}-date-call-activity`" v-if="call.own_date" class="pb-1 ddd">
              <span style="color:black;font-size:12px;"> {{ call.own_date | filter_date_current(null, { unix: true }) }} </span>
            </h3>
            <li :key="index" style="list-style-type: none;">
              <CallActivityTestItem :call="call" />
              <!-- <pre>{{call | jsonPrint}}</pre> -->
            </li>
          </template>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import _ from 'lodash'
import CallActivityItem1 from "../Home/CallActivity/Item1.vue";
// import CallActivityNewItem from "../Home/CallActivity/NewItem.vue";
import CallActivityTestItem from "../Home/CallActivity/TestItem/index.vue";
import moment from 'moment-timezone';
export default {
  name: 'DeveloperCallActivityComparisonModal',
  components: {
    CallActivityItem1,
    // CallActivityNewItem,
    CallActivityTestItem,
  },
  props: {
    modalName: {
      type: String,
      default: 'DeveloperCallActivityComparison',
    },
  },
  data(){
    return {
      api: {
        fetch_old_call_activity: this.$helperFunction.apiInstance({
          error_message: true
        }),
        // fetch_new_call_activity: this.$helperFunction.apiInstance({
        //   error_message: true
        // }),
        fetch_latest_call_activity: this.$helperFunction.apiInstance({
          error_message: true
        }),
      },
      pagination: {
        old_call_activity: {
          page: 1,
          completed: false,
        },
        // new_call_activity: {
        //   page: 1,
        //   completed: false,
        // },
        latest_call_activity: {
          page: 1,
          completed: false,
        },
      },
      response: {
        old_call_activity: [],
        // new_call_activity: [],
        latest_call_activity: [],
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    oldCallActivities(){
      const call_activity = this.response.old_call_activity;
      return _.orderBy(call_activity,"date","desc").map((activity,index,array)=>{
        const prev_activity = array[index-1]
        let date = activity.date
        if(prev_activity && moment.unix(activity.date).isSame(moment.unix(prev_activity.date),'day')) date = ''
        return {
          ...activity,
          own_date: date,
        }
      })
    },
    // newCallActivities(){
    //   const call_activity = this.response.new_call_activity;
    //   return _.orderBy(call_activity,"date","desc").map((activity,index,array)=>{
    //     const prev_activity = array[index-1]
    //     let date = activity.calldate
    //     if(prev_activity && moment(activity.calldate,'YYYY-MM-DD hh:mm:ss').isSame(moment(prev_activity.calldate,'YYYY-MM-DD hh:mm:ss'),'day')) date = ''
    //     return {
    //       ...activity,
    //       own_date: date,
    //     }
    //   })
    // },
    latestCallActivities(){
      const call_activity = this.response.latest_call_activity/*.map(i=>({...i, date: parseInt(i.date)}))*/;
      return /*_.orderBy(*/call_activity/*,"date","desc")*/.map((activity,index,array)=>{
        const prev_activity = array[index-1]
        let date = activity.date
        if(prev_activity && moment.unix(activity.date).isSame(moment.unix(prev_activity.date),'day')) date = ''
        return {
          ...activity,
          own_date: date,
        }
      })
    },
  },
  filters: {
    jsonPrint(data){ return JSON.stringify(data, null, 2) },
  },
  methods: {
    onBeforeOpen(){
      this.fetchOldCallActivity()
      // this.fetchNewCallActivity()
      this.fetchLatestCallActivity()
    },
    onBeforeClose(){
      this.api.fetch_old_call_activity.reset()
      this.pagination.old_call_activity.page=1
      this.pagination.old_call_activity.completed=false
      this.response.old_call_activity=[]
      // this.api.fetch_new_call_activity.reset()
      // this.pagination.new_call_activity.page=1
      // this.pagination.new_call_activity.completed=false
      // this.response.new_call_activity=[]
      this.api.fetch_latest_call_activity.reset()
      this.pagination.latest_call_activity.page=1
      this.pagination.latest_call_activity.completed=false
      this.response.latest_call_activity=[]
    },
    onScrollOldCallActivity(Event) {
      const height = Event.target.scrollHeight;
      const top = Event.target.scrollTop;
      const offset_height = Event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      if (scroll_bottom <= 2) this.fetchOldCallActivity(false);
    },
    async fetchOldCallActivity(start=true){
      if(this.api.fetch_old_call_activity.send) return;
      if(!start && this.pagination.old_call_activity.completed) return;
      try {
        if(start){
          this.pagination.old_call_activity.page = 1;
          this.pagination.old_call_activity.completed = false;
        }
        this.api.fetch_old_call_activity.send=true
        const { data } = await VOIP_API.endpoints.call_activity.list(this.getCurrentUser.account,{
          page: this.pagination.old_call_activity.page
        })
        const array = [
          ..._.isArray(data.data) ? data.data : Object.values(data.data),
          ..._.isArray(data.voicemails) ? data.voicemails : Object.values(data.voicemails),
        ];
        if(array.length==0){
          this.pagination.old_call_activity.completed = true;
        } else {
          if(start || this.pagination.old_call_activity.page==1) {
            this.pagination.old_call_activity.page = 2;
            this.response.old_call_activity = array;
          } else {
            this.pagination.old_call_activity.page = this.pagination.old_call_activity.page + 1;
            this.response.old_call_activity = _.concat(this.response.old_call_activity,array);
          }
        }
      } catch (ex) {
        this.pagination.old_call_activity.completed = true;
      } finally {
        this.api.fetch_old_call_activity.send=false
        const el = document.getElementById(`${this._uid}-old-call-activity`)
        if(el && el.scrollHeight==el.offsetHeight) this.fetchOldCallActivity(false)
      }
    },
    // onScrollNewCallActivity(Event) {
    //   const height = Event.target.scrollHeight;
    //   const top = Event.target.scrollTop;
    //   const offset_height = Event.target.offsetHeight;
    //   const scroll_bar_height = height - (height - offset_height);
    //   const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
    //   if (scroll_bottom <= 2) this.fetchNewCallActivity(false);
    // },
    // async fetchNewCallActivity(start=true){
    //   if(this.api.fetch_new_call_activity.send) return;
    //   if(!start && this.pagination.new_call_activity.completed) return;
    //   try {
    //     if(start){
    //       this.pagination.new_call_activity.page = 1;
    //       this.pagination.new_call_activity.completed = false;
    //     }
    //     this.api.fetch_new_call_activity.send=true
    //     const { data } = await VOIP_API.endpoints.call_activity.newList({
    //       accountcode: this.getCurrentUser.account,
    //       page: this.pagination.new_call_activity.page
    //     })
    //     const array = data?.data ?? []
    //     if(array.length==0){
    //       this.pagination.new_call_activity.completed = true;
    //     } else {
    //       if(start || this.pagination.new_call_activity.page==1) {
    //         this.pagination.new_call_activity.page = 2;
    //         this.response.new_call_activity = array;
    //       } else {
    //         this.pagination.new_call_activity.page = this.pagination.new_call_activity.page + 1;
    //         this.response.new_call_activity = _.concat(this.response.new_call_activity,array);
    //       }
    //     }
    //   } catch (ex) {
    //     this.pagination.new_call_activity.completed = true;
    //   } finally {
    //     this.api.fetch_new_call_activity.send=false
    //     const el = document.getElementById(`${this._uid}-new-call-activity`)
    //     if(el && el.scrollHeight==el.offsetHeight) this.fetchNewCallActivity(false)
    //   }
    // },
    onScrollLatestCallActivity(Event) {
      const height = Event.target.scrollHeight;
      const top = Event.target.scrollTop;
      const offset_height = Event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      if (scroll_bottom <= 2) this.fetchLatestCallActivity(false);
    },
    async fetchLatestCallActivity(start=true){
      if(this.api.fetch_latest_call_activity.send) return;
      if(!start && this.pagination.latest_call_activity.completed) return;
      try {
        if(start){
          this.pagination.latest_call_activity.page = 1;
          this.pagination.latest_call_activity.completed = false;
        }
        this.api.fetch_latest_call_activity.send=true
        const { data } = await VOIP_API.endpoints.call_activity.newList({
          accountcode: this.getCurrentUser.account,
          page: this.pagination.latest_call_activity.page,
          clean_data: 'yes',
          start_date: parseInt(this.response.latest_call_activity[this.response.latest_call_activity.length-1]?.date ?? '') || ''
        })
        
        const array = [
          ..._.isArray(data.data) ? data.data : Object.values(data.data),
          // ..._.isArray(data.voicemails) ? data.voicemails : Object.values(data.voicemails),
        ];
        if(array.length==0){
          this.pagination.latest_call_activity.completed = true;
        } else {
          if(start || this.pagination.latest_call_activity.page==1) {
            this.pagination.latest_call_activity.page = 2;
            this.response.latest_call_activity = array;
          } else {
            this.pagination.latest_call_activity.page = this.pagination.latest_call_activity.page + 1;
            this.response.latest_call_activity = _.concat(this.response.latest_call_activity,array);
          }
        }
      } catch (ex) {
        this.pagination.latest_call_activity.completed = true;
      } finally {
        this.api.fetch_latest_call_activity.send=false
        const el = document.getElementById(`${this._uid}-latest-call-activity`)
        if(el && el.scrollHeight==el.offsetHeight) this.fetchLatestCallActivity(false)
      }
    },
  },
}
</script>

<style lang="scss" scoped>
.developerOptionsModal {
  &.vm--container {
    > :deep(.vm--modal) {
      width: 100% !important;
      min-width: 100% !important;
    }
  }
}
</style>