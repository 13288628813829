<template>
  <modal
    class="dialer_animation right_side_popup m-w-500 chatUserModal"
    width="500px"
    height="auto"
    name="ChatUserModal"
    @before-open="beforeOpen"
    @opened="onOpened"
  >
    <section class="ct-user-new dialer-edit">
      <div class="dialer-form">
        <div class="dialer-edit-header mt-2">
          <h2 class="dialer-edit-title">Chat</h2>
          <div class="dialer-edit-actions">
            <a v-if="!$store.getters.getIsMobile" class="newCloseButton" @click="$modal.hide('ChatUserModal')">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>Close
            </a>
            <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('ChatUserModal')" />
          </div>
        </div>
        <Chatbox :channel_id="channel_id" @change-channel-id="channel_id=$event" ref="chat_box" type="chatModal" />
      </div>
    </section>
  </modal>
</template>

<script>
import { MATTERMOST_API, mattermost } from "@/MatterMost";
import Chatbox from "../../Chat/Box/index";
import { LOGGER } from "@/utils";
export default {
  name: "ChatUserModal",
  components: {
    Chatbox,
  },
  data() {
    return {
      channel_id: "",
    };
  },
  // computed:{
  //   channelData(){return this.$store.state.mattermost.channels[this.channel_id] || {}},
  // },
  // watch: {
  //   "channelData.posts.order"(order){
  //     if(order.length===0 && !(this.channelData.channel.total_msg_count===0 && this.channelData.type==='user')){
  //       this.$root.$emit(events.get_messages_api,{
  //         channel_id: this.channel_id
  //       })
  //     }
  //   }
  // },
  methods: {
    onOpened() {
      // this.$refs.chat_box.channel_id = this.channel_id;
    },
    async beforeOpen(event) {
      try {
        const user = event.params.user;
        LOGGER.log('user',user);
        this.channel_id = user.channel_id;
        if (!this.channel_id) {
          const { data: channel, } = await MATTERMOST_API.endpoints.channels.createDirectChannel([
            user.user.user_chat_id,
            // this.mattermost_current_user.id,
            this.$store.getters.getMattermostCurrentUser?.id,
          ]);
          mattermost.emitOnUserLevel(mattermost.events.direct_added,{
            channel_id: channel.id
          })
          this.$set(this.$store.state.common.voipusers[user.voipaccount],"channel_id",channel.id);
          // this.$root.$emit(events.adding_channel, {
          //   channel_id: channel.id,
          //   data: {
          //     channel: channel,
          //     type: "user",
          //     // data: this.$store.state.common.voipusers[user.voipaccount],
          //     id: user?.voipaccount,
          //   },
          // });
          this.channel_id = channel.id;
        }
      } catch (error) {
        LOGGER.danger("error in beforeOpen function", error);
      }
    },
  },
};
</script>
