<template>
  <div v-if="dids.length">
    <div class="nodeBasic-new">
      <div>
        <div v-for="did in dids" :key="did.did" class="sm-mar-left font-weight-bold min-width-160px mb-8px">
          <span class="mr-2">
            <img width="45px" :src="did.did | number_formater | flag_icon" class="country-img">
          </span>
          {{did.did | number_formater}}
        </div>
      </div>
    </div>
    <div class="d-flex justify-content-center w-100" style="width: 200px;">
      <div class="lineWithArrow">
        <div class="lineWithArrow-line"></div>
        <div class="lineWithArrow-arrow"></div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'NumbersNode',
  props: {
    dids: {
      type: [Array,Object],
      default: ()=>[]
    },
  },
}
</script>

<style>

</style>