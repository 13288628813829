import { VOIP_API } from "../../index"


export const DEALS_CRM_API_ENDPOINTS = {
  search(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: `deals/search`,
      method: 'GET',
      params,
      cancelToken,
    })
  },
  list(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: `deals`,
      method: 'GET',
      params,
      cancelToken,
    })
  },
  listTable(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: `deals/table`,
      method: 'GET',
      params,
      cancelToken,
    })
  },
  create(data={}){
    return VOIP_API.axios.crm.request({
      url: `deals`,
      method: 'POST',
      data,
    })
  },
  createFields(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: `deals/create-fields`,
      method: 'GET',
      params,
      cancelToken,
    })
  },
}