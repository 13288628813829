var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "unsec-common"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex flex-wrap"
  }, [_c('button', {
    staticClass: "btn btn-primarymike space4btn-when-scroll-activate mr-3",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CreateNewPolicyTimeoffModal');
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.551",
      "height": "16.551",
      "viewBox": "0 0 16.551 16.551"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_ionic-ios-add",
      "data-name": "Icon ionic-ios-add",
      "d": "M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z",
      "transform": "translate(-8.465 -8.465)",
      "fill": "#fff",
      "stroke": "#fff",
      "stroke-width": "1"
    }
  })]), _vm._v(" Add new policy ")])])]), _c('vb-table', {
    staticClass: "setting-timeoff-policy-table",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth onetd p-l24"
  }, [_vm._v("Name")]), _c('h5', {
    staticClass: "commonth secondtd"
  }, [_vm._v("default Allowance")]), _c('h5', {
    staticClass: "commonth thirdtd"
  }, [_vm._v("accrual type")]), _c('h5', {
    staticClass: "commonth fourtd col-right"
  }, [_vm._v("Action ")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#D5761B"
    }
  })]), _c('span', {
    staticClass: "clr-sick"
  }, [_vm._v("Sickday")])])]), _c('td', {
    staticClass: "secondtd"
  }, [_c('span', [_vm._v("20days")]), _c('span', [_vm._v("0 days max carryover")])]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" Accrue annualay ")]), _c('td', {
    staticClass: "fourtd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#5576D1"
    }
  })]), _c('span', {
    staticClass: "clr-sick"
  }, [_vm._v("Holiday- UK")])])]), _c('td', {
    staticClass: "secondtd"
  }, [_c('span', [_vm._v("∞")]), _c('span')]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" Do not accrue, just track usage ")]), _c('td', {
    staticClass: "fourtd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#5576D1"
    }
  })]), _c('span', {
    staticClass: "clr-sick"
  }, [_vm._v("Holiday- UK")])])]), _c('td', {
    staticClass: "secondtd"
  }, [_c('span', [_vm._v("∞")]), _c('span')]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" Do not accrue, just track usage ")]), _c('td', {
    staticClass: "fourtd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#5576D1"
    }
  })]), _c('span', {
    staticClass: "clr-sick"
  }, [_vm._v("Holiday- UK")])])]), _c('td', {
    staticClass: "secondtd"
  }, [_c('span', [_vm._v("∞")]), _c('span')]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" Do not accrue, just track usage ")]), _c('td', {
    staticClass: "fourtd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#5576D1"
    }
  })]), _c('span', {
    staticClass: "clr-sick"
  }, [_vm._v("Holiday- UK")])])]), _c('td', {
    staticClass: "secondtd"
  }, [_c('span', [_vm._v("∞")]), _c('span')]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" Do not accrue, just track usage ")]), _c('td', {
    staticClass: "fourtd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#5576D1"
    }
  })]), _c('span', {
    staticClass: "clr-sick"
  }, [_vm._v("Holiday- UK")])])]), _c('td', {
    staticClass: "secondtd"
  }, [_c('span', [_vm._v("∞")]), _c('span')]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" Do not accrue, just track usage ")]), _c('td', {
    staticClass: "fourtd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#C7384F"
    }
  })]), _c('span', {
    staticClass: "clr-wfh"
  }, [_vm._v("Work from ")])])]), _c('td', {
    staticClass: "secondtd"
  }, [_c('span', [_vm._v("20")]), _c('br'), _c('span', {
    staticClass: "policy-da-span"
  }, [_vm._v("0 days max carryover")])]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" Accrue monthly (at end of month) ")]), _c('td', {
    staticClass: "fourtd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#22B100"
    }
  })]), _c('span', {
    staticClass: "clr-holiday-usa"
  }, [_vm._v("Holiday- USA")])])]), _c('td', {
    staticClass: "secondtd"
  }, [_c('span', [_vm._v("12 days")]), _c('br'), _c('span', {
    staticClass: "policy-da-span"
  }, [_vm._v("0 days max carryover")])]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" Accrue monthly (at end of month) ")]), _c('td', {
    staticClass: "fourtd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#D5761B"
    }
  })]), _c('span', {
    staticClass: "clr-sick"
  }, [_vm._v("Sickday")])])]), _c('td', {
    staticClass: "secondtd"
  }, [_c('span', [_vm._v("12 days")]), _c('br'), _c('span', {
    staticClass: "policy-da-span"
  }, [_vm._v("0 days max carryover")])]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" Accrue monthly (at end of month) ")]), _c('td', {
    staticClass: "fourtd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "onetd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#5576D1"
    }
  })]), _c('span', {
    staticClass: "clr-holiday1"
  }, [_vm._v("Holiday")])])]), _c('td', {
    staticClass: "secondtd"
  }, [_c('span', [_vm._v("12 days")]), _c('br'), _c('span', {
    staticClass: "policy-da-span"
  }, [_vm._v("0 days max carryover")])]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" Do not accrue, just track usage ")]), _c('td', {
    staticClass: "fourtd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])], 2)], 1)]), _c('CreateNewPolicyTimeoffModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("General")])]);

}]

export { render, staticRenderFns }