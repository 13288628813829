var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "leaveFeedmackModal PerformanceSetting",
    attrs: {
      "name": _vm.modalName,
      "scrollable": false
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to dialer-edit w-100"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex aling-items-center justify-content-between w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Performance Setting")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('label', [_vm._v("Set performance settings")]), _c('b-form-select', {
    attrs: {
      "value": _vm.forms.performance.value
    },
    on: {
      "change": function ($event) {
        return _vm.changePerformance($event);
      }
    }
  }, _vm._l(_vm.performanceSettings, function (performanceSetting) {
    return _c('b-form-select-option', {
      key: performanceSetting.value,
      attrs: {
        "value": performanceSetting.value
      }
    }, [_vm._v(" " + _vm._s(performanceSetting.value) + " ")]);
  }), 1)], 1)], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }