var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: {
      'recording-started': _vm.recorded.audio.is_start,
      'not-hover': _vm.conditions.screen != _vm.screens.select
    }
  }, [_c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "upload-input",
    attrs: {
      "type": "file",
      "accept": "audio/mpeg, mp3/wav"
    },
    on: {
      "change": function ($event) {
        _vm.selectFile(_vm.getProperty($event, 'target.files[0]'));
      }
    }
  }), _vm.conditions.screen == _vm.screens.select ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.allow_recording ? _c('b-button', {
    staticClass: "blueRecordingButton mr-12px",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_start = true;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "recordModal-record-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Record ")], 1) : _vm._e(), _vm.allow_sound_library ? _c('b-button', {
    staticClass: "whiteRecordingButton mr-12px",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AudioInputComponent`, {
          group: 'announcement'
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-library-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Sound library ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "whiteRecordingButton",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$refs['upload-input'].click();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-upload-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Upload ")], 1)], 1), _c('div', {
    staticClass: "RecordingLikeSomething-descriptionText mt-16px"
  }, [_vm.allow_recording && _vm.show_Text ? _c('span', [_vm._v("Maximum length for recording is 60 seconds.")]) : _vm._e(), _vm._v(" Maximum file size 5MB ")])]) : _vm._e(), _vm.conditions.screen == _vm.screens.before_recording ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('b-button', {
    staticClass: "blueRecordingButton mr-12px wd-40",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_start = true;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "recordModal-record-icon",
      "width": "38px",
      "height": "38px"
    }
  })], 1), _c('b-button', {
    staticClass: "whiteCancelButton",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-cancel-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Cancel ")], 1)], 1), _vm.show_Text ? _c('div', {
    staticClass: "RecordingLikeSomething-descriptionText mt-16px"
  }, [_vm._v(" Press the microphone button when ready to record ")]) : _vm._e()]) : _vm._e(), _vm.conditions.screen == _vm.screens.recording ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "divLike-blueRecordingButton mr-12px wd-40"
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "recordModal-stopRecording-icon",
      "width": "38px",
      "height": "38px"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_start = false;
      }
    }
  }), _c('div', {
    staticClass: "frequency-wave",
    attrs: {
      "id": `${_vm._uid}-frequency-wave`
    }
  }, _vm._l(_vm.waves, function (data, index) {
    return _c('div', {
      key: index,
      attrs: {
        "id": `${_vm._uid}-frequency-wave-${index}`,
        "data-value": data
      }
    });
  }), 0)], 1), _c('b-button', {
    staticClass: "whiteCancelButton",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select;
        _vm.recorded.audio.is_start = false;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-cancel-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Cancel ")], 1)], 1), _vm.show_Text ? _c('div', {
    staticClass: "RecordingLikeSomething-descriptionText mt-16px"
  }, [_vm._v(" Maximum length for recording is 60 seconds. Maximum file size 5MB ")]) : _vm._e()]) : _vm._e(), _vm.conditions.screen == _vm.screens.after_select_file ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-audio-player', {
    staticClass: "mr-12px wd-40",
    attrs: {
      "src": _vm._f("fileToSrc")(_vm.audio.file),
      "design": 'blueAudio'
    }
  }), _c('b-button', {
    staticClass: "GreenSaveButton mr-12px",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.save();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "recordModal-save-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Save ")], 1), _c('b-button', {
    staticClass: "whiteCancelButton",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.audio.file = null;
        _vm.conditions.screen = _vm.screens.select;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-cancel-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Cancel ")], 1)], 1), _vm.show_Text ? _c('div', {
    staticClass: "RecordingLikeSomething-descriptionText mt-16px"
  }, [_vm._v(" Review your recorded audio ")]) : _vm._e()]) : _vm._e(), _vm.conditions.screen == _vm.screens.after_save ? _c('div', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-audio-player', {
    staticClass: "mr-12px w-auto",
    attrs: {
      "src": _vm._f("fileToSrc")(_vm.audio.file),
      "design": 'blueAudio'
    }
  }), _vm.allow_recording ? _c('b-button', {
    staticClass: "redRedoButton mr-12px",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_start = true;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "recordIcon",
    attrs: {
      "icon": "recordModal-redo-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" redo ")], 1) : _vm._e(), _c('b-button', {
    staticClass: "whiteRecordingButton",
    attrs: {
      "disabled": _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$refs['upload-input'].click();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "uploadIcon",
    attrs: {
      "icon": "recordModal-upload-icon",
      "width": "38px",
      "height": "38px"
    }
  }), _vm._v(" Upload ")], 1)], 1), _vm.show_Text ? _c('div', {
    staticClass: "RecordingLikeSomething-descriptionText mt-16px"
  }, [_vm._v(" Maximum length for recording is 60 seconds. Maximum file size 5MB ")]) : _vm._e()]) : _vm._e(), _c('RecordingListSelect', {
    attrs: {
      "modalName": `${_vm._uid}-AudioInputComponent`,
      "is_single_select": true
    },
    on: {
      "selectedRecordings": function ($event) {
        return _vm.onSelectedLibrary($event);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }