<template>
  <div class="crm-homeContainer">
    <div class="crm-home-main">
      <div class="crm-home-main-inner">
        <Insights v-if="activeTab=='crm-insights'" ref="crm-insights" />
        <Deals v-if="activeTab=='crm-deals'" ref="crm-deals" />
        <Activities v-if="activeTab=='crm-activities'" ref="crm-activities" />
        <Documents v-if="activeTab=='crm-documents'" ref="crm-documents" />
        <Contacts v-if="activeTab=='crm-contacts'" ref="crm-contacts" />
        <Companies v-if="activeTab=='crm-companies'" ref="crm-companies" />
        <Products v-if="activeTab=='crm-products'" ref="crm-products" />
      </div>
      <CreateActivity 
        :modalName="'CRMCreateActivity'" 
        @created="$refs['crm-activities'] ? $refs['crm-activities'].fetchActivities() : ''" 
      />
      <CreateDeal 
        :modalName="'CRMCreateDeal'" 
        @created="$refs['crm-deals'] ? $refs['crm-deals'].fetchDealsDebounce() : ''" 
      />
    </div>
  </div>
</template>

<script>
import Insights from '@/components/CRM/Insights.vue'
import Deals from '@/components/CRM/Deals.vue'
import Activities from '@/components/CRM/Activities.vue'
import Documents from '@/components/CRM/Documents.vue'
import Contacts from '@/components/CRM/Contacts.vue'
import Companies from '@/components/CRM/Companies.vue'
import Products from '@/components/CRM/Products.vue'
import CreateActivity from '@/components/Modals/crm/activities/CreateActivity.vue'
import CreateDeal from '@/components/Modals/crm/deals/CreateDeal.vue'

export default {
  name: 'CrmHome',
  components: {
    Insights,
    Deals,
    Activities,
    Documents,
    Contacts,
    Companies,
    Products,
    CreateActivity,
    CreateDeal,
  },
  data(){
    return {
      selectTab: 'Insights'
    }
  },
  computed: {
    activeTab(){ return this.$store.state.global_conditions.tab },
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>