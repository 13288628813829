<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.select_repeat_second">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Repeat Seconds</div>
          <div class="plus-IOSIcon-container">
            
          </div>
        </div>
        <div class="innerModalIos">
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              class="basicWhiteIOScard-item" 
              v-for="data in repeatSeconds" 
              :key="data.value" 
              @click="
                forms.limits.input_seconds=data.value;
                updateAdvanceOptions();
                conditions.screen = screens.main;
              "
            >
              <div class="headingInsideTable">{{ data.text }}</div>
              <vb-icon 
                v-if="forms.limits.input_seconds==data.value" 
                icon="MyNumbers-tick-IOSIcon" 
                width="16.642" 
                height="15.275" 
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_repeat_times">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Repeat Times</div>
          <div class="plus-IOSIcon-container">
            
          </div>
        </div>
        <div class="innerModalIos">
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              class="basicWhiteIOScard-item" 
              v-for="data in repeatTimes" 
              :key="data.value" 
              @click="
                forms.limits.ivr_nodtmf_repeat_limit=data.value;
                updateAdvanceOptions();
                conditions.screen = screens.main;
              "
            >
              <div class="headingInsideTable">{{ data.text }}</div>
              <vb-icon 
                v-if="forms.limits.ivr_nodtmf_repeat_limit==data.value" 
                icon="MyNumbers-tick-IOSIcon" 
                width="16.642" 
                height="15.275" 
              />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_type">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Action</div>
          <div class="plus-IOSIcon-container">
            
          </div>
        </div>
        <div class="innerModalIos">
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              class="basicWhiteIOScard-item" 
              v-for="cmd in cmdOptions" 
              :key="cmd.value" 
              @click="
                forms.limits.cmd=cmd.value;
                updateAdvanceOptions();
                conditions.screen = screens.main;
              "
            >
              <div class="headingInsideTable">{{ cmd.text }}</div>
              <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.limits.cmd==cmd.value" width="16.642" height="15.275" />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_ivr">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select IVR</div>
          <div class="plus-IOSIcon-container">
            
          </div>
        </div>
        <div class="innerModalIos">
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              class="basicWhiteIOScard-item" 
              v-for="account in accountsFilter" 
              :key="account.accountcode" 
              @click="
                forms.limits.account = account.accountcode;
                updateAdvanceOptions()
                conditions.screen = screens.main;
              "
            >
              <div class="headingInsideTable">{{ account.accountname }}</div>
              <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.limits.account==account.accountcode" width="16.642" height="15.275" />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_sound">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Sound File</div>
          <div class="plus-IOSIcon-container">
            
          </div>
        </div>
        <div class="innerModalIos">
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              class="basicWhiteIOScard-item" 
              v-for="sound in response.common_values.sounds" 
              :key="sound.sound" 
              @click="
                forms.limits.sound_id = sound.sound;
                updateAdvanceOptions()
                conditions.screen = screens.main;
              "
            >
              <div class="headingInsideTable">{{ sound.label }}</div>
              <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.limits.sound_id==sound.sound" width="16.642" height="15.275" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Advance Options</div>
          <div class="plus-IOSIcon-container">
            
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">

            <div class="lg-mar-bottom">
              <div class="basicWhiteIOScard mt-32px mb-16px">
                <div class="basicWhiteIOScard-item flex-column" @click="checkPermission(getUserPermissions.ivr_advance_options,()=>conditions.screen=screens.select_repeat_second)">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <div class="headingInsideCard">Repeat menu sound file if no action is pressed after</div>
                    <div class="d-flex align-items-center">
                      <div class="textInsideCard onRightSide textAlignRight mr-16px">{{ forms.limits.input_seconds }}</div>
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                  <p v-if="forms.limits.submitted" class="text text-danger animated bounceIntop w-100 mb-0">
                    <span v-if="!$v.forms.limits.input_seconds.required">* Required</span>
                  </p>
                </div>
                <div class="basicWhiteIOScard-item flex-column" @click="checkPermission(getUserPermissions.ivr_advance_options,()=>conditions.screen=screens.select_repeat_times)">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <div class="headingInsideCard">Allow the sound file to repeat</div>
                    <div class="d-flex align-items-center">
                      <div class="textInsideCard onRightSide textAlignRight mr-16px">{{ forms.limits.ivr_nodtmf_repeat_limit }}</div>
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                  <p v-if="forms.limits.submitted" class="text text-danger animated bounceIntop w-100 mb-0">
                    <span v-if="!$v.forms.limits.ivr_nodtmf_repeat_limit.required">*Required</span>
                  </p>
                </div>
                <div class="basicWhiteIOScard-item flex-column" @click="checkPermission(getUserPermissions.ivr_advance_options,()=>conditions.screen=screens.select_type)">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <div class="headingInsideCard">If no action has been pressed after playing sound file repeatedly</div>
                    <div class="d-flex align-items-center">
                      <div class="textInsideCard onRightSide textAlignRight mr-16px">{{ forms.limits.cmd }}</div>
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                  <p v-if="forms.limits.submitted" class="text text-danger animated bounceIntop w-100 mb-0">
                    <span v-if="!$v.forms.limits.cmd.required">*Required</span>
                  </p>
                </div>
                <div v-if="cmdArgs.account" class="basicWhiteIOScard-item flex-column" @click="checkPermission(getUserPermissions.ivr_advance_options,()=>conditions.screen=screens.select_ivr)">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <div class="headingInsideTable">IVR</div>
                    <div class="d-flex align-items-center">
                      <div class="textInsideCard onRightSide textAlignRight mr-16px">{{ selectedAccount.accountname || '' }}</div>
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                  <p v-if="forms.limits.submitted" class="text text-danger animated bounceIntop w-100 mb-0">
                    <span v-if="!$v.forms.limits.account.required">*Required</span>
                  </p>
                </div>
                <div v-if="cmdArgs.sound" class="basicWhiteIOScard-item flex-column" @click="checkPermission(getUserPermissions.ivr_advance_options,()=>conditions.screen=screens.select_sound)">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <div class="headingInsideTable">Forward Sound</div>
                    <div class="d-flex align-items-center">
                      <vb-audio-player v-if="selectedSound.sound_file" class="mr-16px IosAudioPlayer" :src="selectedSound.sound_file" />
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                  <p v-if="forms.limits.submitted" class="text text-danger animated bounceIntop w-100 mb-0">
                    <span v-if="!$v.forms.limits.sound_id.required">*Required</span>
                  </p>
                </div>
                <div 
                  v-if="cmdArgs.dail_number" 
                  class="basicWhiteIOScard-item flex-column" 
                  @click="checkPermission(getUserPermissions.ivr_advance_options,()=>$modal.show('SelectExtensionModal',{ account: selectedOperator ? selectedOperator.accountcode : '' }))"
                >
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <div class="headingInsideTable">Forward the call to user/operator</div>
                    <div class="d-flex align-items-center">
                      <div class="textInsideCard onRightSide textAlignRight mr-16px">{{ selectedOperator | get_property('accountname') }}</div>
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                  <p v-if="forms.limits.submitted" class="text text-danger animated bounceIntop w-100 mb-0">
                    <span v-if="!$v.forms.limits.sound_id.required">*Required</span>
                  </p>
                </div>
                <div v-if="cmdArgs.code_dtmf" class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Code DTMF</label>
                    <b-input type="number" v-model="forms.limits.code_dtmf" :disabled="!getUserPermissions.ivr_advance_options" @change="updateAdvanceOptions()" />
                    <template v-if="forms.limits.submitted">
                      <small v-if="!$v.forms.limits.code_dtmf.required">*Required</small>
                    </template>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item flex-column" @click="api.update_wrong_ivr_input.send ? '' : checkPermission(getUserPermissions.ivr_advance_options,()=>$modal.show('RecordingListSelecForWrongInput'))">
                  <div class="w-100 d-flex align-items-center justify-content-between">
                    <div class="headingInsideTable">Invalid Input sound</div>
                    <div class="d-flex align-items-center">
                      <vb-audio-player 
                        v-if="forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit" 
                        :src="forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit" 
                        class="mr-16px IosAudioPlayer" 
                      />
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                  <p v-if="forms.update_wrong_ivr_input.submitted && $v.forms.update_wrong_ivr_input.$invalid" class="text text-danger animated bounceIntop w-100 mb-0">
                    <span v-if="!$v.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit.required">* Required</span>
                  </p>
                </div>
              </div>
            </div>

          </div>
        </div>
      </template>
      <SelectSoundFileModal @select-file="updateWrongIVRInput($event.file)" :modalName="'RecordingListSelecForWrongInput'" />
      <SelectSounds @selected-sound="forms.limits.sound_id = $event.sound;updateAdvanceOptions()" />
      <SelectAccounts @selected-account="forms.limits.account = $event.accountcode;updateAdvanceOptions()" />
      <SelectExtensionModal @selected="forms.limits.dail_number=$event.extn;updateAdvanceOptions()" :modalName="`SelectExtensionModal`" />
    </modal>
  </div>
</template>

<script>
import { required, requiredIf } from "vuelidate/lib/validators";
import { VOIP_API } from '@/utils';
import _ from 'lodash';

import SelectSoundFileModal from "../sounds/SelectSoundFile.vue";
import SelectExtensionModal from "../extensions/SelectExtension.vue";

import SelectSounds from "../../../IVR/SelectSounds.vue";
import SelectAccounts from "../../../IVR/SelectAccounts.vue";
import { permission_handling } from '@/mixin';

let screens = {
  select_repeat_second: 'select_repeat_second',
  select_repeat_times: 'select_repeat_times',
  select_ivr: 'select_ivr',
  select_sound: 'select_sound',
  main: 'main', 
}

export default {
  name: 'AdvanceOptionsModal',
  mixins: [permission_handling],
  components: {
    SelectSoundFileModal,
    SelectExtensionModal,
    SelectSounds,
    SelectAccounts,
  },
  props: {
    modalName: {
      type: String,
      default: 'AdvanceOptions'
    },
  },
  data(){
    return {
      forms: {
        limits: this.$helperFunction.formInstance({
          data: {
            ivr_nodtmf_repeat_limit: "",
            ivr_wrongdtmf_repeat_limit: null,
            input_seconds: "",
            cmd: "",
            account: "",
            sound_id: "",
            code_dtmf: "",
            dail_number: "",
          }
        }),
        update_wrong_ivr_input: this.$helperFunction.formInstance({
          data: {
            ivr_wrongdtmf_repeat_limit: '',
          }
        }),
      },
      api: {
        submit: this.$helperFunction.apiInstance({  }),
        common_values: this.$helperFunction.apiInstance({  }),
        advance_value: this.$helperFunction.apiInstance({  }),
        extensions_list: this.$helperFunction.apiInstance({  }),
        update_wrong_ivr_input: this.$helperFunction.apiInstance({  }),
      },
      response: {
        common_values: {},
        extensions: {},
      },
      data: {
        accountcode: '',
      },
      conditions: {
        screen: screens.main
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    screens(){ return screens },
    accountcode(){ return this.data.accountcode },
    cmdOptions() { return this.response?.common_values?.cmd_details?.map?.((item) => { return { text: item.display_label, value: item.ivr_cmd, }; }) ?? []; },
    repeatTimes(){ return _.map(_.range(2,10),function(v){return { text: `${v} times`, value: v }}) },
    repeatSeconds(){ return _.map(_.range(5,30,5),function(v){return { text: `${v} seconds`, value: v }}) },
    accountsFilter() {
      if (!this.response.common_values.accounts) return [];
      let array = [];
      if (this.forms.limits.cmd == "ivrfwd") {
        array = Object.values(this.response.common_values.accounts).filter(
          (data) => data.account_type === "BASICIVR"
        );
      } else {
        array = Object.values(this.response.common_values.accounts);
      }
      return array
    },
    cmdArgs() {
      const ivr_cmd = this.forms.limits.cmd;
      if (!this.response.common_values.cmd_details) return {};
      const cmd = this.response.common_values.cmd_details.find((item) => item.ivr_cmd == ivr_cmd);
      if (!cmd) return {};
      return {
        account: cmd.ivr_cmd_args.includes("accountcode"),
        sound: cmd.ivr_cmd_args.includes("soundfile"),
        dail_number: cmd.ivr_cmd_args.includes("dial-number"),
        code_dtmf: cmd.ivr_cmd_args.includes("code-dtmf"),
        ivr_cmd_args: cmd.ivr_cmd_args,
      };
    },
    ivrCmdArgsValue() {
      if (!this.cmdArgs.ivr_cmd_args) return "";
      const ivr_cmd_args = this.cmdArgs.ivr_cmd_args;
      // const str = ivr_cmd_args.includes("soundfile", this.forms.limits.sound_id) ? `${this.current_user.uid}/${this.current_user.default_accountcode}/ivr/` : "";
      const str = ivr_cmd_args.includes("soundfile", this.forms.limits.sound_id) ? `${this.$store.getters.getCurrentUser?.uid}/${this.$store.getters.getCurrentUser?.default_accountcode}/ivr/` : "";
      return (
        str + ivr_cmd_args
          .replace("accountcode", this.forms.limits.account)
          .replace("soundfile", this.forms.limits.sound_id)
          .replace("dial-number", this.forms.limits.dail_number)
          .replace("code-dtmf", this.forms.limits.code_dtmf)
          .replace(/{/g, "")
          .replace(/}/g, "")
      );
    },
    selectedAccount(){
      if(!this.response.common_values.accounts) return {}
      return this.response.common_values.accounts[this.forms.limits.account] || {}
    },
    selectedSound(){
      if(!this.response.common_values.sounds) return {}
      return this.response.common_values.sounds[this.forms.limits.sound_id] || {}
    },
    selectedOperator(){ return Object.values(this.response.extensions).find(data=>data.extn==this.forms.limits.dail_number) },
  },
  validations: {
    forms: {
      update_wrong_ivr_input: {
        ivr_wrongdtmf_repeat_limit: {
          required,
        },
      },
      limits: {
        ivr_nodtmf_repeat_limit: { 
          required 
        },
        input_seconds: { 
          required 
        },
        cmd: { 
          required 
        },
        account: {
          required: requiredIf(function () {
            return this.cmdArgs.account;
          }),
        },
        sound_id: {
          required: requiredIf(function () {
            return this.cmdArgs.sound;
          }),
        },
        code_dtmf: {
          required: requiredIf(function () {
            return this.cmdArgs.code_dtmf;
          }),
        },
        dail_number: {
          required: requiredIf(function () {
            return this.cmdArgs.dail_number;
          }),
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode=event?.params?.accountcode ?? ''
      this.getCommonValues();
      this.getIvrAdvanceValue();
      this.fetchExtensions()
    },
    onBeforeClose(){
      this.forms.limits.reset()
      this.forms.update_wrong_ivr_input.reset()
      this.api.submit.reset()
      this.api.common_values.reset()
      this.api.advance_value.reset()
      this.api.extensions_list.reset()
      this.api.update_wrong_ivr_input.reset()
      this.response.common_values={}
      this.response.extensions={}
      this.data.accountcode={}
      this.conditions.screen=screens.main
    },
    updateAdvanceOptions: _.debounce(function() {
      let vm = this;
      vm.forms.limits.submitted = true;
      vm.$v.forms.limits.$touch();
      if (vm.$v.forms.limits.$invalid || vm.api.submit.send) return;
      vm.api.submit.send = true;
      VOIP_API.endpoints.menu.advancesetting({
        ivr_wrongdtmf_repeat_limit: vm.forms.limits.ivr_nodtmf_repeat_limit,
        ivr_nodtmf_repeat_limit: vm.forms.limits.ivr_nodtmf_repeat_limit,
        input_seconds: vm.forms.limits.input_seconds,
        cmd: vm.forms.limits.cmd,
        ivr_cmd_args: vm.ivrCmdArgsValue,
        account: vm.accountcode,
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully updated!",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.forms.limits.submitted = false;
        vm.api.submit.send = false;
      });
    },2 * 1000),
    getCommonValues() {
      let vm =this
      vm.api.common_values.send = true;
      VOIP_API.endpoints.menu.getcommonvalues()
      .then(({ data }) => {
        vm.response.common_values = data;
      })
      .finally(() => {
        vm.api.common_values.send = false;
      });
    },
    getIvrAdvanceValue() {
      let vm = this
      vm.api.advance_value.send = true;
      VOIP_API.endpoints.menu.getivradvanceoption(vm.accountcode)
      .then(({ data }) => {
        vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit = data?.setting?.setting?.ivr_wrong_input_alert_file || '';
        vm.forms.limits.ivr_nodtmf_repeat_limit = data?.setting?.setting?.ivr_nodtmf_repeat_limit || "";
        vm.forms.limits.ivr_wrongdtmf_repeat_limit = data?.setting?.setting?.ivr_wrongdtmf_repeat_limit || null;
        vm.forms.limits.input_seconds = data?.setting?.setting?.input_seconds || "";
        vm.forms.limits.cmd = data?.setting?.setting?.ivr_cmd?.ivr_cmd || "";
        const args = data?.setting?.setting?.ivr_cmd?.ivr_cmd_args?.replace?.(/{/g, "")?.replace?.(/}/g, "")?.split?.("^") ?? [];
        const args_val = data?.setting?.setting?.ivr_error_cmd_args?.split?.("^") ?? [];
        vm.forms.limits.account = args_val[args.indexOf("accountcode")] || "";
        vm.forms.limits.sound_id = args_val[args.indexOf("soundfile")]?.split?.('/')?.[args_val[args.indexOf("soundfile")]?.split?.('/')?.length-1] ?? "";
        vm.forms.limits.code_dtmf = args_val[args.indexOf("code-dtmf")] || "";
        vm.forms.limits.dail_number = args_val[args.indexOf("dial-number")] || "";
      })
      .finally(() => {
        vm.api.advance_value.send = false;
      });
    },
    updateWrongIVRInput(file){
      let vm = this
      if(vm.api.update_wrong_ivr_input.send) return;
      vm.api.update_wrong_ivr_input.send=true
      VOIP_API.endpoints.menu.updateWrongIVRInput({
        file: file.sound,
        account: vm.accountcode,
      })
      .then(()=>{
        vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit=file.sound_file
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update_wrong_ivr_input.send=false
      })
    },
    fetchExtensions() {
      let vm = this
      vm.api.extensions_list.send=true
      VOIP_API.endpoints.telephone_number.extensions()
      .then(({ data: res }) => {
        vm.response.extensions = res.data;
      })
      .finally(()=>{
        vm.api.extensions_list.send=false
      });
    },
  },
}
</script>

<style>

</style>