var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500",
    attrs: {
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-edit w-100"
  }, [_c('div', {
    staticClass: "dialer-form w-100"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0 pl-0"
  }, [_vm._v(" Add group ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton text-center",
    attrs: {
      "aria-disabled": _vm.api.add_channel.send
    },
    on: {
      "click": function ($event) {
        _vm.api.add_channel.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.add_channel.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_channel.error_message))]), _c('b-form', {
    staticClass: "latestGreyBox-9-9-2023",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addChannel();
      }
    }
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Name")]), _c('b-form-input', {
    attrs: {
      "disabled": _vm.api.add_channel.send,
      "maxlength": _vm.$v.forms.add_channel.name.$params.maxLength.max,
      "type": "text",
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.forms.add_channel.name,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_channel, "name", $$v);
      },
      expression: "forms.add_channel.name"
    }
  }), _vm.forms.add_channel.submitted && _vm.$v.forms.add_channel.name.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_channel.name.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), !_vm.$v.forms.add_channel.name.maxLength ? _c('span', [_vm._v("* Name can be maximum " + _vm._s(_vm.$v.forms.add_channel.name.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm._e()], 1)], 1)], 1), _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-20px"
  }, [_c('a', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "aria-disabled": _vm.api.add_channel.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.addChannel();
      }
    }
  }, [_vm.api.add_channel.send ? _c('vb-spinner') : [_vm._v("Add group")]], 2)])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }