<template>
  <div class="right-calender">
      <div class="top-main-calender scrollow1">
        <section class="sec-common">
          <div class="d-flex justify-content-between align-items-center">
            <div>
                <h5 class="emp-profile-heading">Emergency contacts</h5>
            </div>
            <button class="btn btn-primarymike " @click="$modal.show('HRMAddEmergencyContactModal')">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.551" height="16.551" viewBox="0 0 16.551 16.551" style="margin-right:10px;">
                <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z" transform="translate(-8.465 -8.465)" fill="#fff" stroke="#fff" stroke-width="1"/>
              </svg>
                Add emergency notes
            </button>
          </div>
          <vb-table :tableDesign="1" class="emergency-contacts-table-width mt-5">
            <template slot="header">
              <h5 class="commonth emp-ec-firsttd  col-left ">Name</h5>
              <h5 class="commonth emp-ec-secondtd ">relationship</h5>
              <h5 class="commonth emp-ec-thirdtd">phone</h5>
              <h5 class="commonth emp-ec-fourtd">e-mail</h5>
              <h5 class="commonth emp-ec-fivetd">Address</h5>
              <h5 class="task-th emp-ec-sixtd col-right">Action</h5>
            </template>
            <template slot="body">
              <tr>
                <td class="emp-ec-firsttd">
                  Kendel Jenner
                </td>
                <td class="emp-ec-secondtd">
                  Sister
                </td>
                <td class="emp-ec-thirdtd">
                  441873740607
                </td>
                <td class="emp-ec-fourtd">
                  jenner@duplex.com
                </td>
                <td class="emp-ec-fivetd">
                  Kabul<br>
                  Asia<br>
                  Kabul, 66000
                </td>
                <td class="emp-ec-sixtd">
                  <div class="d-flex justify-content-end commonTableMain">
                    <b-dropdown no-caret class="common-table-dd">
                      <template #button-content>
                        <b-icon icon="three-dots-vertical"></b-icon>
                      </template>
                      <b-dropdown-item href="#">Action</b-dropdown-item>
                    </b-dropdown>
                  </div>
                </td>
              </tr>
            </template>
          </vb-table>
        </section>
      </div>
      <HRMAddEmergencyContactModal/>
  </div>
</template>

<script>
import HRMAddEmergencyContactModal from "../Modals/HRMAddEmergencyContactModal.vue"
export default {
  name: 'EmergencycontactsHRM',
  components:{
	HRMAddEmergencyContactModal,
  },
}
</script>

<style>

</style>