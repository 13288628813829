var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Time Off Policies "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CreateNewPolicy');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "plus",
      "font-scale": "1.3",
      "scale": "1.5"
    }
  }), _vm._v(" Add new policy ")], 1)]), _c('div', {
    staticClass: "mainDescription mb-0"
  }, [_vm._v(" Create and manage time-off policies. ")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" You can create as many policies as you need (e.g. \"Work from home\") and edit the default ones ")]), _c('div', {
    staticClass: "settings-TimeOffPoliciesTable mt-16px"
  }, [_vm._m(0), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "circleWithText"
        }, [_c('div', {
          staticClass: "circleContainer",
          staticStyle: {
            "background-color": "#D5761B"
          }
        }), _c('div', {
          staticClass: "circleWithText-text",
          staticStyle: {
            "color": "#D5761B"
          }
        }, [_vm._v("Sickday")])])]), _c('td', {
          staticClass: "dialer-col-right two"
        }, [_c('div', {
          staticClass: "td-heading"
        }, [_vm._v("20 days")]), _c('div', {
          staticClass: "td-subText"
        }, [_vm._v("0 days max carryover")])]), _c('td', {
          staticClass: "dialer-col-right three"
        }, [_vm._v(" Accrue annually ")]), _c('td', {
          staticClass: "dialer-col-right four"
        }, [_c('b-dropdown', {
          staticClass: "ActionsDD",
          attrs: {
            "no-caret": "",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "button-content"
              }, [_c('b-icon', {
                staticClass: "downIcon",
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })], 1)];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)])];
      },
      proxy: true
    }])
  })], 1), _c('CreateNewPolicy')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("Default allowance")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Accrual type")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("Action")])]);

}]

export { render, staticRenderFns }