var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Tasks ")]), _vm._m(0), _c('div', {
    staticClass: "hrmTabsContainer"
  }, [_c('div', {
    class: `hrmTabsButton ${_vm.showTabs == 'Active' ? 'active' : ''} mr-16px`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'Active';
      }
    }
  }, [_vm._v("Active")]), _c('div', {
    class: `hrmTabsButton ${_vm.showTabs == 'Upcoming' ? 'active' : ''} mr-16px`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'Upcoming';
      }
    }
  }, [_vm._v("Upcoming")]), _c('div', {
    class: `hrmTabsButton ${_vm.showTabs == 'Completed' ? 'active' : ''} mr-16px`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'Completed';
      }
    }
  }, [_vm._v("Completed")])]), _c('div', {
    staticClass: "mt-32px"
  }, [_vm.showTabs == 'Active' ? [_c('div', {
    staticClass: "dropdownsContainer"
  }, [_c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All teams")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3428189402)
  }, [_c('b-dropdown-item', [_vm._v(" All teams ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All locations")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 778788602)
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1), _c('div', {
    staticClass: "EmployeeDataTasksTable mt-32px"
  }, [_vm._m(1), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "td-text"
        }, [_vm._v("this is test task description lorem ipsum")])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v(" 13/09/2022 ")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_c('b-dropdown', {
          staticClass: "ActionsDD",
          attrs: {
            "no-caret": "",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "button-content"
              }, [_c('b-icon', {
                staticClass: "downIcon",
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })], 1)];
            },
            proxy: true
          }], null, false, 1472627253)
        }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)]), _c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "td-text"
        }, [_vm._v("this is test task description lorem ipsum")])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v(" 13/09/2022 ")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_c('b-dropdown', {
          staticClass: "ActionsDD",
          attrs: {
            "no-caret": "",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "button-content"
              }, [_c('b-icon', {
                staticClass: "downIcon",
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })], 1)];
            },
            proxy: true
          }], null, false, 1472627253)
        }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)])];
      },
      proxy: true
    }], null, false, 4250348137)
  })], 1)] : _vm._e(), _vm.showTabs == 'Upcoming' ? [_c('div', {
    staticClass: "dropdownsContainer"
  }, [_c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All teams")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3428189402)
  }, [_c('b-dropdown-item', [_vm._v(" All teams ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All locations")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 778788602)
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1), _c('div', {
    staticClass: "EmployeeDataTasksTable mt-32px"
  }, [_vm._m(2), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "td-text"
        }, [_vm._v("this is test task description lorem ipsum")])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v(" 13/09/2022 ")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_c('b-dropdown', {
          staticClass: "ActionsDD",
          attrs: {
            "no-caret": "",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "button-content"
              }, [_c('b-icon', {
                staticClass: "downIcon",
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })], 1)];
            },
            proxy: true
          }], null, false, 1472627253)
        }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)]), _c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "td-text"
        }, [_vm._v("this is test task description lorem ipsum")])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v(" 13/09/2022 ")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_c('b-dropdown', {
          staticClass: "ActionsDD",
          attrs: {
            "no-caret": "",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "button-content"
              }, [_c('b-icon', {
                staticClass: "downIcon",
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })], 1)];
            },
            proxy: true
          }], null, false, 1472627253)
        }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)])];
      },
      proxy: true
    }], null, false, 4250348137)
  })], 1)] : _vm._e(), _vm.showTabs == 'Completed' ? [_c('div', {
    staticClass: "dropdownsContainer"
  }, [_c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All teams")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3428189402)
  }, [_c('b-dropdown-item', [_vm._v(" All teams ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All locations")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 778788602)
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1), _c('div', {
    staticClass: "EmployeeDataTasksTable mt-32px"
  }, [_vm._m(3), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "td-text"
        }, [_vm._v("this is test task description lorem ipsum")])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v(" 13/09/2022 ")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_c('b-dropdown', {
          staticClass: "ActionsDD",
          attrs: {
            "no-caret": "",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "button-content"
              }, [_c('b-icon', {
                staticClass: "downIcon",
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })], 1)];
            },
            proxy: true
          }], null, false, 1472627253)
        }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)]), _c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "td-text"
        }, [_vm._v("this is test task description lorem ipsum")])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v(" 13/09/2022 ")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_c('b-dropdown', {
          staticClass: "ActionsDD",
          attrs: {
            "no-caret": "",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "button-content"
              }, [_c('b-icon', {
                staticClass: "downIcon",
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })], 1)];
            },
            proxy: true
          }], null, false, 1472627253)
        }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)])];
      },
      proxy: true
    }], null, false, 4250348137)
  })], 1)] : _vm._e()], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription mb-3"
  }, [_c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("task")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("DUE DATE")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Assigned to")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("EMPLOYEE")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Actions")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("task")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("DUE DATE")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Assigned to")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("EMPLOYEE")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Actions")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("task")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("DUE DATE")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Assigned to")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("EMPLOYEE")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Actions")])]);

}]

export { render, staticRenderFns }