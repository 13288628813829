<template>
  <modal class="dialer_animation right_side_popup" width="50%" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div class="dialer-edit-header">
        <div>
          <h2 class="dialer-edit-title">Send Invites</h2>
        </div>
        <div class="dialer-edit-actions">
          <button @click="$modal.hide(modalName)" class="newCloseButton w-auto">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <b-form @submit.prevent="inviteMember()">
        <div class="dialer-box grayBox inModal">
          <div class="dialer-box-header mb-4">Enter Email</div>
          <b-form-group v-for="(data, index) in forms.invite_persons.emails" :key="index">
            <div class="d-flex align-items-center">
              <div class=" whiteBGinputWithGreyRoundedBorder white-noShadow w-100-30 mb-3">
                <b-form-input class="w-100" :disabled="api.invite_persons.send" v-model="data.email" />
              </div>
              
              <b-icon class="ml-2 mb-3 cursor_pointer" font-scale="1.3" v-if="forms.invite_persons.emails.length>1" @click="forms.invite_persons.emails.splice(index,1)" icon="trash-fill"/>
            </div>
            <p v-if="forms.invite_persons.submitted && $v.forms.invite_persons.emails.$each[index].email.$invalid" class="text text-danger animated bounceIntop d-flex flex-column">
              <span class="w-100" v-if="!$v.forms.invite_persons.emails.$each[index].email.required">* email is required</span>
              <span class="w-100" v-if="!$v.forms.invite_persons.emails.$each[index].email.email">* email is invalid</span>
            </p>
            
          </b-form-group>
          <p v-if="forms.invite_persons.submitted && $v.forms.invite_persons.emails.$invalid" class="text text-danger animated bounceIntop d-flex flex-column">
            <span v-if="!$v.forms.invite_persons.emails.required">* emails is required</span>
            <span v-if="!$v.forms.invite_persons.emails.minLength">* emails should be minimum {{ $v.forms.invite_persons.emails.$params.minLength.min }}</span>
          </p>
          <div class="d-flex justify-content-center">
            <button class="dialer-button dialer-button-primary" @click="forms.invite_persons.emails.push({ email: '' })">
              <b-icon icon="plus" scale="1.3" class="mr-2"/>
              Add
            </button>
          </div>
        </div>
        <div class="d-flex justify-content-end w-100">
          <b-button :disabled="api.invite_persons.send" type="submit" class="newDoneButton">
            <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
            <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
              <vb-spinner v-if="api.invite_persons.send" />
              <template v-else>Submit</template>
            </span>
          </b-button>
        </div>
      </b-form>
    </div>
  </modal>
</template>

<script>
import { required, minLength, email } from "vuelidate/lib/validators";
import { VOIP_API } from '../../../utils';
export default {
  name: "AddPersonByEmail",
  props: {
    modalName: {
      type: String,
      default: 'AddPersonByEmail'
    },
  },
  data(){
    return {
      forms: {
        invite_persons: {
          emails: [
            {
              email: '',
            }
          ],
          reset(){
            this.emails = [
              {
                email: '',
              }
            ]

            this.submitted = false
          },
          submitted: false,
        },
        reset(){
          this.invite_persons.reset()
        },
      },
      api: {
        invite_persons: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true }),
        reset(){
          this.invite_persons?.reset?.()
        },
      },
      data: {
        meeting_id: '',
        link: '',
        reset(){
          this.meeting_id = ''
          this.link=''
        },
      },
    }
  },
  validations: {
    forms: {
      invite_persons: {
        emails: {
          required,
          minLength: minLength(1),
          $each: {
            email: {
              email,
              required,
            },
          }
        },
      }
    }
  },
  methods: {
    onBeforeOpen(event){
      this.data.meeting_id=event?.params?.meeting_id
      this.data.link=event?.params?.link
    },
    onBeforeClose(){
      this.forms.reset()
      this.api.reset()
      this.data.reset()
    },
    inviteMember(){
      let vm = this
      vm.forms.invite_persons.submitted=true
      vm.$v.forms.invite_persons.$touch()
      if(vm.$v.forms.invite_persons.$invalid || vm.api.invite_persons.send) return;
      vm.api.invite_persons.send=true
      vm.api.invite_persons.validation_errors=true
      vm.api.invite_persons.error_message=''
      VOIP_API.endpoints.jitsi_conference.invitemember({
        emails: vm.forms.invite_persons.emails,
        meeting_id: vm.data.meeting_id,
        meeting_link: vm.data.link
      })
      .then(()=>{
        vm.$modal.hide(vm.modalName)
      })
      .catch((ex)=>{
        vm.api.invite_persons.validation_errors=ex.own_errors
        vm.api.invite_persons.error_message=ex.own_message
      })
      .finally(()=>{
        vm.forms.invite_persons.submitted=false
        vm.api.invite_persons.send=false
      })
    },
  },
};
</script>
