var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation center-zoomIn-popup m-w-600 dialer-addMemberModal",
    attrs: {
      "name": "AddMembersInChatModal"
    }
  }, [_c('div', {
    staticClass: "dialer-chatBox-inVideo"
  }, [_c('div', {
    staticClass: "dialer-videoCall-addMember mb-0 pt-0 position-relative"
  }, [_c('div', {
    staticClass: "px-3 py-3 border-bottom sticky-top"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('span', {
    staticClass: "close-popup cursor_pointer ml-2",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddMembersInChatModal');
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "scale": "1.8"
    }
  })], 1), _c('h4', {
    staticClass: "mb-0"
  }, [_vm._v("Add Members")]), _c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddMembersInChatModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)]), _c('div', {
    staticClass: "pt-3 px-2"
  }, [_c('div', {
    staticClass: "searchBox"
  }, [_c('b-form', {
    staticClass: "position-relative",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('b-icon', {
    staticClass: "position-absolute cursor_pointer",
    attrs: {
      "icon": "search",
      "scale": "1"
    }
  }), _c('b-icon', {
    staticClass: "position-absolute cursor_pointer",
    attrs: {
      "icon": "x-circle",
      "scale": "1"
    }
  })], 1)], 1)])]), _c('ul', {
    staticClass: "items"
  }, [_vm._l(_vm.otherMembers, function (user) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account !== user.voipaccount,
        expression: "$store.getters.getCurrentUser.account !== user.voipaccount"
      }],
      key: user.voipaccount,
      staticClass: "item cursor_pointer",
      on: {
        "click": function ($event) {
          return _vm.add(user);
        }
      }
    }, [_c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "image-container"
    }, [_c('img', {
      attrs: {
        "src": user.profile_img
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.user_male;
        }
      }
    })]), _c('div', [_c('h5', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(_vm._f("get_property")(user, 'user.display_name')))])])]), _c('div', {
      staticClass: "actions"
    }, [_c('div', {
      staticClass: "action"
    }, [_c('b-icon', {
      attrs: {
        "icon": "plus-square",
        "scale": "1.2",
        "variant": "success"
      }
    })], 1)])]);
  }), _vm._l(_vm.addedMembers, function (user) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account !== user.voipaccount,
        expression: "$store.getters.getCurrentUser.account !== user.voipaccount"
      }],
      key: user.voipaccount,
      staticClass: "item cursor_pointer",
      on: {
        "click": function ($event) {
          return _vm.remove(user);
        }
      }
    }, [_c('div', {
      staticClass: "info"
    }, [_c('div', {
      staticClass: "image-container"
    }, [_c('img', {
      attrs: {
        "src": user.profile_img
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.user_male;
        }
      }
    })]), _c('div', [_c('h5', {
      staticClass: "text-capitalize"
    }, [_vm._v(_vm._s(_vm._f("get_property")(user, 'user.display_name')))])])]), _c('div', {
      staticClass: "actions"
    }, [_c('div', {
      staticClass: "action"
    }, [_c('b-icon', {
      attrs: {
        "icon": "X-square",
        "scale": "1.2",
        "variant": "danger"
      }
    })], 1)])]);
  }), _vm.isEmpty(_vm.otherMembers) && _vm.isEmpty(_vm.addedMembers) ? _c('vb-no-record', {
    attrs: {
      "text": "There in no user",
      "design": 3
    }
  }) : _vm._e()], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }