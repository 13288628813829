<template>
  <modal 
    class="dialer_animation right_side_popup otherCreditCards-Modal min-width-50percent-modal" 
    width="553px" 
    height="auto" 
    :scrollable="true" 
    :name="modalName" 
    @before-close="onBeforeClose()" 
    @before-open="onBeforeOpen($event)"
  >
    <div class="dialer-form">
      <div v-if="getIsMobile" class="dialer-edit-header flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center">
          <h2 class="dialer-edit-title">Other cards</h2>
          <b-icon class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
        </div>
      </div>
      <div v-else class="dialer-edit-header">
        <h2 class="dialer-edit-title d-flex align-items-center">
          <!-- <vb-svg @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" /> -->
          <button class="newBackButton mr-16px" @click="$modal.hide(modalName)">
            <b-icon icon="arrow-left-circle" />
            <span class="newBackButton-textpart">Back</span>
          </button>
          Other cards
        </h2>
        <div class="dialer-edit-actions d-flex align-items-center">
          <!-- <a class="newCloseButton mr-3" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a> -->
          <button class="newButton" @click="$modal.show('AddCardStripe')">
            <vb-icon icon="squared-add-icon" height="38px" width="38px" />
            <span class="newButton-textPart d-flex justify-content-center position-relative">Add</span>
          </button>
        </div>
      </div>
      <div class="d-flex flex-column credit-cards-container-v2 mt-3">
        <div v-for="card in response.cards" :key="card.real_id" :class="`primary-card-container w-100 mt-2 mb-4 ${card.primary=='yes' ? 'itIsPrimaryCard' : ''}`">
          <div class="primary-card-s1">
            <vb-icon icon="masterCard-v2-icon" width="100" height="60"/>
            <div class="s1-text-container">
              <div class="s1-big-text">
                <div class="text-b">Mastercard</div>
                <div class="s1-c-container">
                  <div class="s1-c"></div>
                  <div class="s1-c"></div>
                  <div class="s1-c"></div>
                  <div class="s1-c"></div>
                </div>
                <div class="text-b">{{ card | get_property('digits','0000') }}</div>
              </div>
              <div class="s1-small-text">Expires {{ card | get_property('expiry_month','00') }}/{{ card | get_property('expiry_year','00') }}</div>
              <div class="s1-small-text">{{card.label}}</div>
            </div>
          </div>
          <div class="primary-card-s2">
            <b-dropdown no-caret :disabled="card.primary=='yes'" class="creditCardDD">
              <template #button-content>
                <template v-if="card.primary=='yes'">Primary</template>
                <template v-else>Other</template>
                <b-icon v-if="card.primary!='yes'" icon="chevron-down" class="ml-2" />
              </template>
              <b-dropdown-item v-if="card.primary!='yes'" href="#" @click="makePrimary(card)">Set primary</b-dropdown-item>
            </b-dropdown>
            <div class="d-flex align-items-center">
              <div class="s2-text primary cursor_pointer" style="color:red;" @click="deleteCard(card)">Remove</div>
            </div>
          </div>
        </div>
        <vb-no-record v-if="response.cards.length==0" :text="api.cards.send ? '' : 'Sorry, no data found'" :design="3">
          <template v-if="api.cards.send" slot="loading">
            <div v-for="n in 5" :key="n.id" class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
              <div class="latestShimmerDesign" style="height:175px;width:100%;border-radius:13px;margin-bottom: 16px;"></div>
            </div>
          </template>
        </vb-no-record>
        <!-- <div class="addBackup-card-container w-100 mt-4" >
          <div class="addBackup-text primary" @click="$modal.show('AddCardStripe')">
            <b-icon icon="plus" font-scale="1.1" scale="1.3"/>
            Add a payment method
          </div>
        </div> -->
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '../../../utils';
import { mapGetters } from 'vuex';
export default {
  name: "OtherCreditCardModal",
  props: {
    modalName: {
      type: String,
      default: 'OtherCreditCard',
    },
    isOnFreePlan: {
      type: Boolean,
      default: false,
    },
    anyUnlimitedPlan: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      api: {
        cards: this.$helperFunction.apiInstance({  }),
        primary_card: this.$helperFunction.apiInstance({ send: '', }),
        secondary_card: this.$helperFunction.apiInstance({ send: '', }),
        delete_card: this.$helperFunction.apiInstance({ send: '', }),
      },
      response: {
        cards: [],
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser'
    ]),
    otherCards(){ return this.response?.cards?.filter?.(item=>item.is_dafault!=1) },
  },
  methods: {
    onBeforeOpen(){
      this.fetchCards()
    },
    onBeforeClose(){
      this.api.cards.reset()
      this.response.cards = []
    },
    fetchCards(){
      let vm = this;
      vm.api.cards.send = true;
      VOIP_API.endpoints.billing_api.getcreditcards({ 
        uid: vm.getCurrentUser?.uid 
      })
      .then(({ data: { data: cards } }) => {
        vm.response.cards = cards;
      })
      .finally(() => {
        vm.api.cards.send = false;
      });
    },
    async deleteCard(card){
      const card_real_id = card.real_id
      const primary_card = card.primary=='yes'
      if(this.api.delete_card.send || !card_real_id) return;
      try {
        this.api.delete_card.send=card_real_id
        if(primary_card && !this.isOnFreePlan) {
          await this.$appConfirmation({
            title: "Attention",
            message: `To delete a primary card, please note that you must first downgrade your account to the free plan.`,
            variant: 'info',
            button: {
              yes: "OK",
            },
            ok: true
          });
          return;
        }
        if(primary_card && this.isOnFreePlan && this.anyUnlimitedPlan) {
          await this.$appConfirmation({
            title: "Attention",
            // message: `To delete a primary card, please note that you must delete the unlimited plan.`,
            message: `To delete a primary card, please note that you must downgrade from an Unlimited plan to a Limited plan.`,
            variant: 'info',
            button: {
              yes: "OK",
            },
            ok: true
          });
          return;
        }
        const { confirm } = await this.$appConfirmation({
          title: "warning",
          message: `Are you sure you want to delete this Card`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        });
        if(!confirm) return;
        await VOIP_API.endpoints.billing_api.deletecreditcard({
          id: card_real_id,
          accountcode: this.getCurrentUser?.account,
          uid: this.getCurrentUser?.uid,
        })
        this.fetchCards()
        this.$emit('delete-card',{card_real_id})
        this.$emit('fetch-cards')
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.delete_card.send=''
      }
    },
    makePrimary(card){
      let vm = this
      const card_real_id = card.real_id
      if(vm.api.delete_card.send==card_real_id || vm.api.primary_card.send || !card_real_id) return;
      vm.api.primary_card.send=card_real_id
      VOIP_API.endpoints.billing_api.makedefaultcreditcard({
        id: card_real_id,
        accountcode: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
        flag: 'primary'
      })
      .then(()=>{
        vm.$emit('make-primary-card',{card_real_id})
        vm.$emit('fetch-cards')
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.fetchCards()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.primary_card.send=''
      })
    },
    makeSecondary(card_real_id){
      let vm = this
      if(vm.api.delete_card.send==card_real_id || vm.api.secondary_card.send || !card_real_id) return;
      vm.api.secondary_card.send=card_real_id
      VOIP_API.endpoints.billing_api.makedefaultcreditcard({
        id: card_real_id,
        accountcode: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
        flag: 'secondary'
      })
      .then(()=>{
        vm.$emit('make-secondary-card',{card_real_id})
        vm.$emit('fetch-cards')
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.fetchCards()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.secondary_card.send=''
      })
    },
  },
}
</script>

<style>

</style>