var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading"
  }, [_vm._v("Onboarding")]), _c('div', {
    staticClass: "mainDescription"
  }, [_c('br')]), _c('div', {
    staticClass: "grayCard flexWrap"
  }, [_c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" ACTIVE TASKS ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("3")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" EMPLOYEES IN ONBOARDING ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("3")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" UPCOMING TASKS ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("3")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" COMPLETED IN LAST 30 DAYS ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("3")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth mb-0"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" STARTERS IN LAST 30 DAYS WITH NO TASKS ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("3")])])])])]);

}]

export { render, staticRenderFns }