<template>
  <div>
    <modal class="dialer_animation right_side_popup " width = "600px" height="auto" :scrollable="true" @before-open="onBeforeOpen" :name="modalName">
      <div class="dialer-assign-to my-4 dialer-edit">
        <div class="dialer-edit-actions d-flex justify-content-end">
          <button class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="latestGreyBox-heading-main">Menu option</div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row" v-for="(sc, key) in menuOptions.schedules" :key="key">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion withGreyButton">
                  <vb-select
                    :selected="scheduleOption.option"
                    @change="scheduleOption.option = $event"
                    class="right-0"
                    :defaultSelectedText="'Select a Button'"
                    :options="
                      (Object.values(sc.avaliableOptions) || []).map(
                        (item) => {
                          return {
                            text: `Press ${item}`,
                            value: item,
                          };
                        }
                      )
                    "
                  >
                  </vb-select>
                </div>
                <div :disabled="!scheduleOption.option" class="addnewMenuOptions-Text ml-32px"
                  @click="
                    $modal.show('MenuOptionNewExtensionAssignModal', {
                      account: '',
                      type: 'setting',
                    })
                  "
                >
                  Select user, team, queue or IVR
                </div>
                <input type="hidden" :value="sc.id" ref="scheduleid" />
                <input type="hidden" :value="key + 1" ref="schedulekey" />
              </div>
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
            <div class="latestGreyBox-heading-main">Invalid Input sound</div>
            <div class="d-flex align-items-center justify-content-between w-100 mt-20px">
              <vb-audio-player v-if="forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit" :src="forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit" class="w-auto min-w-250px"/>
              <button 
                v-b-tooltip :title="getUserPermissions.ivr_advance_options?'':'Your package does not support this feature'"
                class="dialer-button dialer-button-primary md-mar-left w-auto d-inline-block" 
                :disabled="!getUserPermissions.ivr_advance_options || api_sent.update_wrong_ivr_input" 
                type="button" 
                @click="api_sent.update_wrong_ivr_input ? '' : $modal.show('IvrGraphRecordingListSelect',{ group: 'announcement' })"
              >
                <span>{{ forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit ? "Change" : "Assign" }}</span>
              </button>
            </div>
            <template v-if="forms.update_wrong_ivr_input.submitted">
              <p v-if="$v.forms.update_wrong_ivr_input.$invalid" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit.required">* Required</span>
              </p>
            </template>
          </div>
        </div>
      </div>

      <!--<div class="dialer-menuOptions md-mar-top dialer-box">
        <h3 class="dialer-box-header mb-3">Menu Options</h3>
        <div class="dialer-extensions" v-for="(sc, key) in menuOptions.schedules" :key="key">
          <div class="dialer-extension dialer-flex addnewoption">
            <div class="dialer-extension-assign">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion whiteBg-select simple-image">
                <vb-select
                  :selected="scheduleOption.option"
                  @change="scheduleOption.option = $event"
                  class="right-0"
                  :defaultSelectedText="'Select a Button'"
                  :options="
                    (Object.values(sc.avaliableOptions) || []).map(
                      (item) => {
                        return {
                          text: `Press ${item}`,
                          value: item,
                        };
                      }
                    )
                  "
                >
                </vb-select>
              </div>
              <button
                class="
                  dialer-button dialer-button-primary
                  md-mar-left
                "
                :disabled="!scheduleOption.option"
                type="button"
                style="position: relative"
                @click="
                  $modal.show('MenuOptionNewExtensionAssignModal', {
                    account: '',
                    type: 'setting',
                  })
                "
              >
                <span>Add New Menu Option</span>
              </button>
            </div>

            <input type="hidden" :value="sc.id" ref="scheduleid" />
            <input type="hidden" :value="key + 1" ref="schedulekey" />
          </div>
        </div>
        
        <div :class="`d-flex w-100 ${forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit ? 'flex-column justify-content-center':'align-items-center'}`">
          <label>Invalid Input sound</label>
          <div class="d-flex ">
            <vb-audio-player v-if="forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit" :src="forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit" class="w-auto min-w-250px"/>
            <button class="dialer-button dialer-button-primary md-mar-left w-auto d-inline-block" :disabled="api_sent.update_wrong_ivr_input" type="button" @click="api_sent.update_wrong_ivr_input ? '' : $modal.show('IvrGraphRecordingListSelect')">
              <span>{{ forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit ? "Change" : "Assign" }}</span>
            </button>
          </div>
          <template v-if="forms.update_wrong_ivr_input.submitted">
            <p v-if="$v.forms.update_wrong_ivr_input.$invalid">
              <span v-if="!$v.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit.required">* Required</span>
            </p>
          </template>
        </div>
      </div>-->
      
    
    </modal>
    <RecordingListSelect
      :is_single_select="true"
      @selectedRecordings="updateWrongIVRInput($event)"
      :modalName="`IvrGraphRecordingListSelect`"
    />
    <MenuOptionExtensionAssignModal modalName="MenuOptionNewExtensionAssignModal" @interface="assignExtension" />
  </div>
</template>

<script>
import { VOIP_API } from "../../utils";
import { required } from "vuelidate/lib/validators";
import RecordingListSelect from "./RecordingListSelect.vue";
import MenuOptionExtensionAssignModal from "./MenuOptionExtensionAssignModal.vue";
import { mapGetters } from 'vuex';
// import AdvanceOptions from "../IVR/AdvanceOptions.vue";
export default {
  name: "IvrGraphMenuOptionsModal",
  components:{
    MenuOptionExtensionAssignModal,
    RecordingListSelect,
    // AdvanceOptions
  },
  props: {
    modalName: {
      type: String,
      default: "IvrGraphMenuOptionsModal",
    },
    menuOptions:{
      type: Object
    },
    accountcode: {
      type: String,
      default: "",
    },
  },
  data(){
    return{
      scheduleOption: {
        account: null,
        id: null,
        option: null,
        schedule_id: null,
        schedule_no: null,
        type: "extension",
        command: "callfwd",
        args: null,
      },
      forms: {
        update_wrong_ivr_input: this.$helperFunction.formInstance({
          data: {
            ivr_wrongdtmf_repeat_limit: '',
          }
        }),
        update_label: {
          label: '',
          reset() {
            this.label = '';
          },
        },
      },
      is_submitted: {
        submit: false,
        update_label: false,
        update_wrong_ivr_input: false,
      },
      api_sent: {
        tex_to_audio: false,
        list: false,
        update_label: false,
        submit: false,
        common_values: false,
        advance_value: false,
        update_wrong_ivr_input: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions'
    ])
  },
  inject: [
    'appNotify',
  ],
  validations: {
    forms: {
      update_wrong_ivr_input: {
        ivr_wrongdtmf_repeat_limit: {
          required,
        },
      },
      update_label: {
        label: {
          required,
        },
      },
    },
  },
  methods: {
    async onBeforeOpen(/* event */){
      // console.log(event)
      this.getIvrAdvanceValue()
    },
    assignExtension(event){
      this.scheduleOption.schedule_id = this.$refs.scheduleid[0].value;
      this.scheduleOption.schedule_no = this.$refs.schedulekey[0].value;
      this.scheduleOption.args = event.account;
      this.scheduleOption.id = this.menuOptions.voipaccount;
      this.scheduleOption.account = event.account;
      // console.log('console event1', event, this.scheduleOption);
      this.$emit('interface',{ from: 'menu_option', event: 'add_menu_option', payload: { option: this.scheduleOption }, })
      this.scheduleOption.option = null;
      this.scheduleOption.schedule_no = null;
      this.scheduleOption.args = null;
    },
    getIvrAdvanceValue() {
      let vm = this
      this.api_sent.advance_value = true;
      VOIP_API.endpoints.menu.getivradvanceoption(this.accountcode)
      .then(({ data }) => {
        vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit = data?.setting?.setting?.ivr_wrong_input_alert_file || '';
      })
      .finally(() => {
        this.api_sent.advance_value = false;
      });
    },
    updateWrongIVRInput(event){
      let vm = this
      // vm.forms.update_wrong_ivr_input.submitted=true
      // vm.$v.forms.update_wrong_ivr_input.$touch()
      // if(vm.$v.forms.update_wrong_ivr_input.$invalid) return;
      if(vm.api_sent.update_wrong_ivr_input) return;
      vm.api_sent.update_wrong_ivr_input=true
      VOIP_API.endpoints.menu.updateWrongIVRInput({
        file: event.sound,
        account: vm.accountcode,
      }).then(()=>{
        vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit=event.sound_file
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.$emit('interface',{ from: 'menu_option', event: 'update_invalid_input_file' })
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        // vm.forms.update_wrong_ivr_input.submitted=false
        vm.api_sent.update_wrong_ivr_input=false
      })
    },
  }
};
</script>
