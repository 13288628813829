<template>
  <div>
    <div class="mainHeading withButton">
      Team manager permissions
    </div>
    <div class="mainDescription mb-4">
      Employees in this group have access to their team member information. You can assign managers in TEAMS section.
    </div>
    <div class="grayCard flexColumn mt-32px">
      <b-form-group class="HrmRadio-container w-fit-content">
        <b-form-radio class="HrmRadio" >
          Team managers can see all company employees in the shared calendar and directory.
        </b-form-radio>
        <b-form-radio class="HrmRadio">
          Team managers can only see employees from their own team(s)
        </b-form-radio>
      </b-form-group>
    </div>
    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Time off requests for employees in manager teams</div>
      <div class="grayCard-description mt-16px">
        Team manager can add & edit time off requests for their employees:
      </div>
      <b-form-group class="HrmRadio-container w-fit-content mt-16px">
        <b-form-radio class="HrmRadio" >
          For any date, including past dates
        </b-form-radio>
        <b-form-radio class="HrmRadio">
          For any date starting from the current month
        </b-form-radio>
        <b-form-radio class="HrmRadio">
          For any date in the future
        </b-form-radio>
      </b-form-group>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionsTeamManager',
}
</script>

<style>

</style>