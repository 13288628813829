<template>
  <div>
    <div v-if="twoPartsView" :class="`aiTranscriptModal-twoParts-container AudioTranscriptContainer ${transcriptStatus==3 ? '' : 'addScrollToIt'}`">
      <template v-if="transcriptStatus==3">
        <div class="aiTranscriptModal-twoParts-leftSide">
          <Item1 v-if="CallItemShow" class="m-0 w-100" :call="call"></Item1>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="latestGreyBox-heading-main-16px w-100 d-flex align-items-center">
              <span class="mr-12px">AI call summary</span>
              <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
            </div>
            <div class="latestGreyBox-descriptionText-13px mt-10px">{{transcriptSummary}}</div>
            <div class="latestGreyBox-heading-main-16px mt-20px w-100 d-flex align-items-center">
              <span class="mr-12px">AI keywords</span>
              <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
            </div>
            <div class="AI-Keywords-container">
              <div class="AI-Keyword" v-for="data in transcriptTags" :key="data.id">{{data}}</div>
            </div>
            <div class="latestGreyBox-heading-main-16px w-100 d-flex align-items-center mt-10px">
              <span class="mr-12px">Sentiments</span>
              <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
            </div>
            <div class="Sentiments-boxes">
              <div class="Sentiments-box" v-for="item in total_percentages" :key="item.id">
                <div class="Sentiments-box-heading">{{item.type}}</div>
                <!-- {{(item.sum/transcriptTotalCount) * 100 | to_fixed }} -->
                <div class="newProgressBar">
                  <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${(item.sum/transcriptTotalCount) * 100};--fg:#249CE0`">
                    <div class="newProgressBar-inner">
                      {{(item.sum/transcriptTotalCount) * 100 | to_fixed(0)}}%
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <slot name="transcript-end" />
        </div>
        <div class="aiTranscriptModal-twoParts-rightSide">
          <div class="latestGreyBox-9-9-2023 withoutBorderRadius">
            <div class="latestGreyBox-heading-main w-100 d-flex align-items-center">
              <span class="mr-12px">AI transcript</span>
              <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
            </div>
            <div class="latestGreyBox-descriptionText-subText-14px mt-12px">{{totalDuration/60  | to_fixed}} mint read</div>
            <div class="TranscriptText-container" ref="onlyScrollThis">
              <div class="TranscriptText-itself speaker" v-for="(data, index) in transcript" :key="index">
                <div class="d-flex justify-content-between align-items-center w-100">
                  <div class="TranscriptText-heading">{{ data.channel_type }}</div>
                  <div class="latestGreyBox-descriptionText-13px">{{ (data.end - data.start) | to_fixed }} s</div>
                </div>
                <p 
                  class="TranscriptText-text" 
                  :class="`${currentTime > parseFloat(data.start) && currentTime < parseFloat(data.end) ? 'highlight':''} ${data.channel_type}`"
                >
                  <span 
                    v-for="(word, wordIndex) in data.text.split(' ')" 
                    :key="wordIndex"
                    :class="{
                      'highlight-word': currentTime >= (data.start + (wordIndex * (data.end - data.start) / (data.text.split(' ').length + 2))),
                    }"
                  >
                    {{ word }}
                  </span>
                </p>
              </div>
            </div>
          </div>
          <div class="aiTranscriptModal-twoParts-rightSide-borderSection">
            <vb-audio-player :src="soundFile" @currentTime="currentTime=$event;scrollView()" :design="'Transcript'">
              <template #player-end>
                <div class="TranscriptAudio-downloadShareSection">
                  <vb-icon class="cursor_pointer_no_hover mr-32px" icon="audioTranscript-share-icon" width="17.119px" height="18.826px" />
                  <vb-icon class="cursor_pointer_no_hover" icon="audioTranscript-download-icon" width="16.2px" height="16.197px" @click="download()" />
                </div>
              </template>
            </vb-audio-player>
            <div class="AudioTranscript-SpeakerPart mt-20px">
              <div v-for="speaker in speakers" :key="speaker" class="SpeakerPart-item">
                <div class="speaker-heading">
                  {{ speaker }}
                </div>
                <div class="d-flex timeline-container w-100 mt-10px">
                  <div
                    v-for="(data,index) in transcript" 
                    :key="index" 
                    :something="((data.end - data.start)/ transcriptTotalCount) * 100"
                    :style="`
                      height: 10px;
                      width: ${((data.end - data.start) / transcriptTotalCount) * 100}%;
                    `"
                    :class="`${speaker==data.channel_type ? 'timeline' : ''} ${speaker}`"
                    @click="speaker==data.channel_type?'':''"
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="api.fetch_transcript.send">
        <div class="d-flex justify-content-center w-100">
          <div class="mt-20px mb-20px w-50">
            <div v-for="n in 4" :key="n.id" :class="`latestShimmerDesign w-100 my-4`" style="height:80px;"></div>
          </div>
        </div>
      </template>
      <template v-else-if="transcriptStatus==1">
        <div class="d-flex justify-content-center w-100">
          <div class="mt-20px mb-20px w-50">
            <vb-audio-player class="w-100" :src="soundFile" :design="'NoTranscript'" >
              <template #player-end>
                <vb-icon class="cursor_pointer_no_hover" icon="audioTranscript-download-icon" width="14.2px" height="14.2px" @click="download()" />
              </template>
            </vb-audio-player>
            <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
              <img width="50%" height="auto" :src="require('@/assets/images/emptyScreenImages/audioTranscript.png')"/>
              <div class="emptyScreenContainer-heading mt-20px">Unlock intelligent insights! 🎙️🔍</div>
              <div class="emptyScreenContainer-text w-75">
                Transcribe your call recordings effortlessly with our AI-powered transcription feature. 
                Tap the button below, and watch as your audio transforms into actionable text. Elevate 
                your communication experience now!
              </div>
              <div v-b-tooltip :title="getUserPermissions.call_recording_transcriptions?'':'Your package does not support this feature'">
                <button :disabled="!getUserPermissions.call_recording_transcriptions" class="dialer-button dialer-button-primary mt-20px" @click="makeTranscript()">Transcript recording with AI</button>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="transcriptStatus==2">
        <div class="d-flex justify-content-center w-100">
          <div class="mt-20px mb-20px w-50">
            <p class="text-center processingText">Processing</p>
            <div v-for="n in 4" :key="n.id" :class="`latestShimmerDesign w-100 my-4`" style="height:80px;"></div>
          </div>
        </div>
      </template>
      <template v-else-if="transcriptStatus==0">
        <div class="d-flex justify-content-center w-100">
          <div class="mt-20px mb-20px w-50">
            <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
              <img width="50%" height="auto" :src="require('@/assets/images/emptyScreenImages/audioTranscript.png')"/>
              <div class="emptyScreenContainer-heading mt-20px">AI transcription is off! 🎙️🔍</div>
              <div class="emptyScreenContainer-text w-75">
                If you want you want your call recordings to be AI transcribed, then turn this service on from number settings.
              </div>
            </div>
          </div>
        </div>
      </template>
    </div>
    <div v-else class="AudioTranscriptContainer">
      <!-- complete -->
      <template v-if="transcriptStatus==3">
        <div class="latestGreyBox-9-9-2023">
          <div class="latestGreyBox-heading-main-16px w-100 d-flex align-items-center">
            <span class="mr-12px">AI call summary</span>
            <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
          </div>
          <div class="latestGreyBox-descriptionText-13px mt-10px">{{transcriptSummary}}</div>
          <div class="latestGreyBox-heading-main-16px mt-20px w-100 d-flex align-items-center">
            <span class="mr-12px">AI keywords</span>
            <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
          </div>
          <div class="AI-Keywords-container">
            <div class="AI-Keyword" v-for="data in transcriptTags" :key="data.id">{{data}}</div>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="latestGreyBox-heading-main w-100 d-flex align-items-center">
            <span class="mr-12px">AI transcript</span>
            <vb-icon icon="ai-audio-icon" width="14.184px" height="13.403px" />
          </div>
          <div class="latestGreyBox-descriptionText-subText-14px mt-12px">{{totalDuration/60  | to_fixed}} mint read</div>
          <div class="TranscriptText-container" ref="onlyScrollThis">
            <div class="TranscriptText-itself speaker" v-for="(data, index) in transcript" :key="index">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="TranscriptText-heading">{{ data.channel_type }}</div>
                <div class="latestGreyBox-descriptionText-13px">{{ (data.end - data.start) | to_fixed }} s</div>
              </div>
              <p 
                class="TranscriptText-text" 
                :class="`${currentTime > parseFloat(data.start) && currentTime < parseFloat(data.end) ? 'highlight':''} ${data.channel_type}`"
              >
                <span 
                  v-for="(word, wordIndex) in data.text.split(' ')" 
                  :key="wordIndex"
                  :class="{
                    'highlight-word': currentTime >= (data.start + (wordIndex * (data.end - data.start) / (data.text.split(' ').length + 2))),
                  }"
                >
                  {{ word }}
                </span>
              </p>
            </div>
          </div>
          <vb-audio-player :src="soundFile" @currentTime="currentTime=$event;scrollView()" :design="'Transcript'">
            <template #player-end>
              <div class="TranscriptAudio-downloadShareSection">
                <vb-icon class="cursor_pointer_no_hover mr-32px" icon="audioTranscript-share-icon" width="17.119px" height="18.826px" />
                <vb-icon class="cursor_pointer_no_hover" icon="audioTranscript-download-icon" width="16.2px" height="16.197px" @click="download()" />
              </div>
            </template>
          </vb-audio-player>
          <div class="AudioTranscript-SpeakerPart mt-20px">
            <div v-for="speaker in speakers" :key="speaker" class="SpeakerPart-item">
              <div class="speaker-heading">
                {{ speaker }}
              </div>
              <div class="d-flex timeline-container w-100 mt-10px">
                <div
                  v-for="(data,index) in transcript" 
                  :key="index" 
                  :something="((data.end - data.start)/ transcriptTotalCount) * 100"
                  :style="`
                    height: 10px;
                    width: ${((data.end - data.start) / transcriptTotalCount) * 100}%;
                  `"
                  :class="`${speaker==data.channel_type ? 'timeline' : ''} ${speaker}`"
                  @click="speaker==data.channel_type?'':''"
                />
              </div>
            </div>
          </div>
        </div>
        <slot name="transcript-end" />
      </template>
      <!-- loading -->
      <template v-else-if="api.fetch_transcript.send">
        <div v-for="n in 4" :key="n.id" :class="`latestShimmerDesign w-100 my-4`" style="height:80px;"></div>
      </template>
      <!-- ready to make a transcript -->
      <div v-else-if="transcriptStatus==1">
        <div class="mt-20px mb-20px">
          <vb-audio-player :src="soundFile" :design="'NoTranscript'" >
            <template #player-end>
              <vb-icon class="cursor_pointer_no_hover" icon="audioTranscript-download-icon" width="14.2px" height="14.2px" @click="download()" />
            </template>
          </vb-audio-player>
          <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
            <img width="50%" height="auto" :src="require('@/assets/images/emptyScreenImages/audioTranscript.png')"/>
            <div class="emptyScreenContainer-heading mt-20px">Unlock intelligent insights! 🎙️🔍</div>
            <div class="emptyScreenContainer-text w-75">
              Transcribe your call recordings effortlessly with our AI-powered transcription feature. 
              Tap the button below, and watch as your audio transforms into actionable text. Elevate 
              your communication experience now!
            </div>
            <div 
              v-b-tooltip :title="getUserPermissions.call_recording_transcriptions?'':'Your package does not support this feature'"
            >
              <button :disabled="!getUserPermissions.call_recording_transcriptions" class="dialer-button dialer-button-primary mt-20px" @click="makeTranscript()">Transcript recording with AI</button>
            </div>
          </div>
        </div>
      </div>
      <!-- processing -->
      <div v-else-if="transcriptStatus==2">
        <p class="text-center processingText">Processing</p>
        <div v-for="n in 4" :key="n.id" :class="`latestShimmerDesign w-100 my-4`" style="height:80px;"></div>
      </div>
      <!-- not allowed -->
      <div v-else-if="transcriptStatus==0">
        <!-- <p class="text-center transcriptisoff">There transcript is off</p> -->
        <div class="d-flex justify-content-center w-100">
          <div class="mt-20px mb-20px w-50 emptyScreenContainer-container">
            <div class="emptyScreenContainer mt-20px b-0 bg-transparent p-0">
              <img width="50%" height="auto" :src="require('@/assets/images/emptyScreenImages/audioTranscript.png')"/>
              <div class="emptyScreenContainer-heading mt-20px">AI transcription is off! 🎙️🔍</div>
              <div class="emptyScreenContainer-text w-75">
                If you want you want your call recordings to be AI transcribed, then turn this service on from number settings.
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import _ from 'lodash';
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import Item1 from '../Home/CallActivity/Item1.vue';
export default {
  name: 'AudioTranscriptDummy',
  props: {
    soundFile: {
      type: String,
      default: '',
    },
    uniqueID: {
      type: String,
      default: '',
    },
    call: {
      type: Object,
      default: () => ({}),
    },
    CallItemShow: {
      type: Boolean,
      default: false,
    },
    twoPartsView: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      currentTime: 0,
      api: {
        fetch_transcript: this.$helperFunction.apiInstance(),
        make_transcript: this.$helperFunction.apiInstance(),
        download: this.$helperFunction.apiInstance(),
      },
      response: {
        transcript: {},
      },
    }
  },
  inject: [
    'appNotify',
  ],
  components: {
    Item1
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
    ]),
    transcriptStatus(){ return this.response.transcript?.status },
    JSONText(){ 
      const json_text = this.response.transcript?.json_text
      if(json_text) return JSON.parse(json_text)
      return null 
    },
    transcript(){ return this.JSONText?.transcription ?? [] },
    speakers(){ return Object.keys(_.keyBy(this.transcript, 'channel_type') ?? {}) },
    totalDuration(){ return this.transcript[this.transcript.length-1]?.end },
    transcriptSummary(){ return this.JSONText?.summary },
    transcriptTags(){ return this.JSONText?.tags ?? [] },
    transcriptTotalCount(){ 
      let total = 0;
      this.transcript.forEach(i => {
        let diff = i.end - i.start
        total = total + diff
      });
      return total
    },
    sentiments(){ return this.JSONText?.sentiments ?? [] },
    total_percentages(){
      // let total = this.transcriptTotalCount
      let all_t = this.transcript
      let speakers = this.speakers
      let before_percentage = []
      for(let i =0;i<speakers.length;i++){
        before_percentage.push({type:speakers[i],arr:[],sum: 0})
        all_t.forEach((item)=>{
          if(speakers[i] == item.channel_type){
            // let sum = ((item.end - item.start)/ total) * 100
            let sum = item.end - item.start
            before_percentage[i].arr.push(sum)
          }
        })
      }
      for(let j =0;j<before_percentage.length;j++){
        before_percentage[j].arr
        for (let ij = 0; ij < before_percentage[j].arr.length; ij++) {
          before_percentage[j].sum += before_percentage[j].arr[ij];
        }
      }
      // console.log("before_percentage",before_percentage)
      return before_percentage
    }
  },
  methods: {
    scrollView(){
      const container = this.$refs.onlyScrollThis;
      const innerContainer = container.getElementsByClassName('highlight')
      if(innerContainer?.[0]) container.scrollTop = innerContainer?.[0]?.offsetTop - 900
    },
    async fetchTranscript(){
      if(this.api.fetch_transcript.send) return;
      try {
        this.api.fetch_transcript.send=true
        const { data } =  await VOIP_API.endpoints.call_activity.fetchTranscript({
          id: this.uniqueID,
        })
        this.response.transcript=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_transcript.send=false
      }
    },
    async makeTranscript(){
      if(this.api.make_transcript.send) return;
      try {
        this.api.make_transcript.send=true
        await VOIP_API.endpoints.call_activity.makeTranscript({
          id: this.uniqueID
        })
        this.fetchTranscript()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.make_transcript.send=false
      }
    },
    async download() {
      if(this.api.download.send) return;
      try {
        this.api.download.send=true
        const response = await fetch(this.soundFile)
        const blob = await response.blob()
        let blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = 'download';
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.download.send=false
      }
    },
  },
  mounted(){
    this.fetchTranscript()
  },
};
</script>

<style lang="scss" scoped>
.speaker {
  .highlight {
    .highlight-word {
      background-color: yellow;
      color: black;
    }
    &.SPEAKER_00 .highlight-word{
      background-color: #3699FF;
      color: white;
    }
    &.SPEAKER_01 .highlight-word{
      background-color: #7C3AED;
      color: white;
    }
    &.SPEAKER_02 .highlight-word{
      background-color: #0EC173;
      color: white;
    }
  }
}
</style>