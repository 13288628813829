var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "EditSpaceModal",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header w-100 d-flex position-relative py-0 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title my-0"
  }, [_vm._v("Edit space")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _vm.api.get_space.send && _vm.isEmpty(_vm.response.space) ? [_c('div', {
    staticClass: "createSpace-greyBox mt-50px"
  }, [_c('div', {
    staticClass: "createSpace-whiteBorderedBox"
  }, _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "createSpace-whiteBorderedBox-row"
    }, [_c('div', {
      staticClass: "latestShimmerDesign w-25",
      staticStyle: {
        "height": "24px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign w-25",
      staticStyle: {
        "height": "24px"
      }
    })]);
  }), 0)])] : [_vm.conditions.screen == _vm.screens.select.value ? _c('div', {
    staticClass: "createSpace-greyBox mt-50px"
  }, [_c('div', {
    staticClass: "createSpace-whiteBorderedBox"
  }, [_c('div', {
    staticClass: "createSpace-whiteBorderedBox-row",
    on: {
      "click": function ($event) {
        _vm.api.update_space.send ? '' : _vm.conditions.screen = _vm.screens.name.value;
      }
    }
  }, [_c('div', {
    staticClass: "TODO-smallInnerHeading"
  }, [_vm._v("Space name")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "createSpace-editableText"
  }, [_vm._v(_vm._s(_vm.forms.update_space.name))]), _vm.forms.update_space.submitted && _vm.$v.forms.update_space.name.$invalid || _vm.api.update_space.validation_errors.name ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_space.name.required ? _c('span', [_vm._v("Space name is required")]) : !_vm.$v.forms.update_space.name.valid ? _c('span', [_vm._v("Space name can be only alphabet or number")]) : !_vm.$v.forms.update_space.name.minLength ? _c('span', [_vm._v("Space name should be minimum " + _vm._s(_vm.$v.forms.update_space.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_space.name.maxLength ? _c('span', [_vm._v("Space name can be maximum " + _vm._s(_vm.$v.forms.update_space.name.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.update_space.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]), _c('b-icon', {
    staticClass: "darker-chevron-down",
    attrs: {
      "icon": "chevron-right"
    }
  })], 1)]), _c('div', {
    staticClass: "createSpace-whiteBorderedBox-row",
    on: {
      "click": function ($event) {
        _vm.api.update_space.send ? '' : _vm.conditions.screen = _vm.screens.prefix.value;
      }
    }
  }, [_c('div', {
    staticClass: "TODO-smallInnerHeading"
  }, [_vm._v("Space prefix")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "createSpace-editableText"
  }, [_vm._v(_vm._s(_vm.forms.update_space.prefix))]), _vm.forms.update_space.submitted && _vm.$v.forms.update_space.prefix.$invalid || _vm.api.update_space.validation_errors.prefix ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_space.prefix.required ? _c('span', [_vm._v("Space prefix is required")]) : !_vm.$v.forms.update_space.prefix.alpha ? _c('span', [_vm._v("Space prefix can only be alphabet")]) : !_vm.$v.forms.update_space.prefix.minLength ? _c('span', [_vm._v("Space prefix should be minimum " + _vm._s(_vm.$v.forms.update_space.prefix.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_space.prefix.maxLength ? _c('span', [_vm._v("Space prefix can be maximum " + _vm._s(_vm.$v.forms.update_space.prefix.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.update_space.validation_errors.prefix, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]), _c('b-icon', {
    staticClass: "darker-chevron-down",
    attrs: {
      "icon": "chevron-right"
    }
  })], 1)]), _c('div', {
    staticClass: "createSpace-whiteBorderedBox-row",
    on: {
      "click": function ($event) {
        _vm.api.update_space.send ? '' : _vm.conditions.screen = _vm.screens.color.value;
      }
    }
  }, [_c('div', {
    staticClass: "TODO-smallInnerHeading"
  }, [_vm._v("Space color")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "createSpace-selectColor-container"
  }, [_c('div', {
    staticClass: "createSpace-selectColor-item smaller selected",
    class: {
      'TransparentSelected': _vm.forms.update_space.color == 'rgba(0,0,0,0)'
    },
    style: `background-color:${_vm.forms.update_space.color};`
  })]), _vm.forms.update_space.submitted && _vm.$v.forms.update_space.color.$invalid || _vm.api.update_space.validation_errors.color ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_space.color.required ? _c('span', [_vm._v("Color is required")]) : !_vm.$v.forms.update_space.color.validColor ? _c('span', [_vm._v("Color is invalid")]) : _vm._e(), _vm._l(_vm.api.update_space.validation_errors.color, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]), _c('b-icon', {
    staticClass: "darker-chevron-down",
    attrs: {
      "icon": "chevron-right"
    }
  })], 1)]), _vm.response.space.status != 'private' ? _c('div', {
    staticClass: "createSpace-whiteBorderedBox-row",
    on: {
      "click": function ($event) {
        _vm.api.update_space.send ? '' : _vm.conditions.screen = _vm.screens.type.value;
      }
    }
  }, [_c('div', {
    staticClass: "TODO-smallInnerHeading"
  }, [_vm._v("Type")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "createSpace-editableText"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.types, `${_vm.forms.update_space.type}.text`)))]), _vm.forms.update_space.submitted && _vm.$v.forms.update_space.type.$invalid || _vm.api.update_space.validation_errors.type ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_space.type.required ? _c('span', [_vm._v("Type is required")]) : !_vm.$v.forms.update_space.type.valid ? _c('span', [_vm._v("Type is invalid")]) : _vm._e(), _vm._l(_vm.api.update_space.validation_errors.type, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]), _c('b-icon', {
    staticClass: "darker-chevron-down",
    attrs: {
      "icon": "chevron-right"
    }
  })], 1)]) : _vm._e(), _vm.response.space.type == 'private' ? _c('div', {
    staticClass: "createSpace-whiteBorderedBox-row",
    on: {
      "click": function ($event) {
        _vm.api.update_space.send ? '' : _vm.$modal.show('AddRemoveSpaceMembers', {
          space_id: _vm.data.space_id,
          type: 'space_members'
        });
      }
    }
  }, [_c('div', {
    staticClass: "TODO-smallInnerHeading"
  }, [_vm._v("Members")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "createSpace-editableText"
  }, [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.response.space, 'members.length', 0)) + " ")]), _c('b-icon', {
    staticClass: "darker-chevron-down",
    attrs: {
      "icon": "chevron-right"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "createSpace-whiteBorderedBox-row",
    on: {
      "click": function ($event) {
        _vm.api.update_space.send ? '' : _vm.conditions.screen = _vm.screens.status.value;
      }
    }
  }, [_c('div', {
    staticClass: "TODO-smallInnerHeading"
  }, [_vm._v("Task statuses")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "createSpace-editableText"
  }, [_vm._v(" " + _vm._s(_vm.data.status_count) + " ")]), _c('b-icon', {
    staticClass: "darker-chevron-down",
    attrs: {
      "icon": "chevron-right"
    }
  })], 1)]), _c('div', {
    staticClass: "createSpace-whiteBorderedBox-row",
    on: {
      "click": function ($event) {
        _vm.api.update_space.send ? '' : _vm.conditions.screen = _vm.screens.group.value;
      }
    }
  }, [_c('div', {
    staticClass: "TODO-smallInnerHeading"
  }, [_vm._v("Groups")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "createSpace-editableText"
  }, [_vm._v(" " + _vm._s(_vm.data.group_count) + " ")]), _c('b-icon', {
    staticClass: "darker-chevron-down",
    attrs: {
      "icon": "chevron-right"
    }
  })], 1)]), _c('div', {
    staticClass: "createSpace-whiteBorderedBox-row",
    on: {
      "click": function ($event) {
        _vm.api.update_space.send ? '' : _vm.$modal.show('TodoTaskMove', {
          moved: true,
          payload: {
            space_id: _vm.data.space_id,
            flag: 'space'
          }
        });
      }
    }
  }, [_c('div', {
    staticClass: "TODO-smallInnerHeading"
  }, [_vm._v("Move tasks")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "createSpace-editableText"
  }), _c('b-icon', {
    staticClass: "darker-chevron-down",
    attrs: {
      "icon": "chevron-right"
    }
  })], 1)])])]) : _vm._e(), _vm.conditions.screen == _vm.screens.name.value ? _c('div', {
    staticClass: "createSpace-greyBox mt-24px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Space name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_space.name,
      expression: "forms.update_space.name"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "type": "text",
      "placeholder": "Space title",
      "maxlength": _vm.$v.forms.update_space.name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_space.name
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_space, "name", $event.target.value);
      }, function ($event) {
        _vm.api.get_is_unique.error_message = '';
      }]
    }
  }), _vm.$v.forms.update_space.name.$invalid || _vm.api.update_space.validation_errors.name || _vm.api.get_is_unique.error_message ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_vm.api.get_is_unique.error_message ? _c('span', [_vm._v(_vm._s(_vm.api.get_is_unique.error_message))]) : _vm._e(), !_vm.$v.forms.update_space.name.required ? _c('span', [_vm._v("Name is required")]) : !_vm.$v.forms.update_space.name.minLength ? _c('span', [_vm._v("Name should be minimum " + _vm._s(_vm.$v.forms.update_space.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_space.name.maxLength ? _c('span', [_vm._v("Name can be maximum " + _vm._s(_vm.$v.forms.update_space.name.$params.maxLength.max) + " character")]) : !_vm.$v.forms.update_space.name.valid ? _c('span', [_vm._v("Name can not use special character")]) : _vm._e(), _vm._l(_vm.api.update_space.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()])]) : _vm._e(), _vm.conditions.screen == _vm.screens.prefix.value ? _c('div', {
    staticClass: "createSpace-greyBox mt-24px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Space prefix")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_space.prefix,
      expression: "forms.update_space.prefix"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "type": "text",
      "placeholder": "Space prefix",
      "maxlength": _vm.$v.forms.update_space.prefix.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_space.prefix
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_space, "prefix", $event.target.value);
      }
    }
  }), _vm.$v.forms.update_space.prefix.$invalid || _vm.api.update_space.validation_errors.prefix ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_space.prefix.required ? _c('span', [_vm._v("Prefix is required")]) : !_vm.$v.forms.update_space.prefix.minLength ? _c('span', [_vm._v("Prefix should be minimum " + _vm._s(_vm.$v.forms.update_space.prefix.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_space.prefix.maxLength ? _c('span', [_vm._v("Prefix can be maximum " + _vm._s(_vm.$v.forms.update_space.prefix.$params.maxLength.max) + " character")]) : !_vm.$v.forms.update_space.prefix.alpha ? _c('span', [_vm._v("Prefix can only be alphabet")]) : _vm._e(), _vm._l(_vm.api.update_space.validation_errors.prefix, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()])]) : _vm._e(), _vm.conditions.screen == _vm.screens.color.value ? _c('div', {
    staticClass: "createSpace-greyBox mt-24px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Space color")]), _c('div', {
    staticClass: "createSpace-selectColor-container"
  }, _vm._l(_vm.colors, function (color) {
    return _c('div', {
      key: color.id,
      staticClass: "createSpace-selectColor-item-container",
      on: {
        "click": function ($event) {
          _vm.api.update_space.send ? '' : _vm.forms.update_space.color = color;
        }
      }
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item",
      class: {
        'selected': _vm.forms.update_space.color == color,
        'TransparentSelected': color == 'rgba(0,0,0,0)'
      },
      style: `background-color:${color};`
    }, [color == 'rgba(0,0,0,0)' ? _c('vb-icon', {
      attrs: {
        "icon": "todo-transparentColored-icon",
        "height": "28px",
        "width": "30px"
      }
    }) : _vm._e()], 1)]);
  }), 0)])]) : _vm._e(), _vm.conditions.screen == _vm.screens.type.value ? _c('div', {
    staticClass: "mt-24px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Space type")])]), _c('div', {
    staticClass: "d-flex align-items-stretch justify-content-between"
  }, _vm._l(_vm.types, function (type) {
    return _c('div', {
      key: type.value,
      staticClass: "todoList-blackBordered-box halfWidth",
      class: {
        'selected': _vm.forms.update_space.type == type.value
      },
      on: {
        "click": function ($event) {
          _vm.api.update_space.send ? '' : _vm.forms.update_space.type = type.value;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": type.icon,
        "width": "92.998px",
        "height": "59.917px"
      }
    }), _c('div', {
      staticClass: "todoList-blackBordered-box-heading mt-24px"
    }, [_vm._v(_vm._s(type.text))])], 1);
  }), 0)]) : _vm._e(), _c('Status', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.conditions.screen == _vm.screens.status.value,
      expression: "conditions.screen==screens.status.value"
    }],
    ref: "status",
    attrs: {
      "space_id": _vm.data.space_id
    },
    on: {
      "back": function ($event) {
        _vm.conditions.screen = _vm.screens.select.value;
      },
      "status-count": function ($event) {
        _vm.data.status_count = $event;
      },
      "update": function ($event) {
        return _vm.$emit('status-update');
      },
      "create": function ($event) {
        return _vm.$emit('status-create');
      },
      "remove": function ($event) {
        return _vm.$emit('status-remove');
      }
    }
  }), _c('Groups', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.conditions.screen == _vm.screens.group.value,
      expression: "conditions.screen==screens.group.value"
    }],
    ref: "group",
    attrs: {
      "space_id": _vm.data.space_id
    },
    on: {
      "back": function ($event) {
        _vm.conditions.screen = _vm.screens.select.value;
      },
      "group-count": function ($event) {
        _vm.data.group_count = $event;
      },
      "update": function ($event) {
        return _vm.$emit('group-update');
      },
      "create": function ($event) {
        return _vm.$emit('group-create');
      },
      "remove": function ($event) {
        return _vm.$emit('group-remove');
      }
    }
  }), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between mt-38px"
  }, [_c('div', [_vm.conditions.screen != _vm.screens.select.value ? _c('div', {
    staticClass: "d-flex align-items-center",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_c('b-icon', {
    staticClass: "darker-chevron-down mx-0",
    attrs: {
      "icon": "chevron-left"
    }
  }), _c('b', {
    staticClass: "darker-chevron-down ml-1"
  }, [_vm._v("Back")])], 1) : _vm._e()]), _vm.conditions.screen == _vm.screens.select.value && _vm.isAnyChangings ? _c('button', {
    staticClass: "fullWidthDoneButton w-100",
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }, [_vm._v(" Save changes ")]) : _vm._e()])]], 2), _c('AddRemoveFromList', {
    attrs: {
      "modalName": 'AddRemoveSpaceMembers'
    },
    on: {
      "update-list": function ($event) {
        _vm.getSpace();
        _vm.$emit('update-list');
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }