<template>
  <div>
    <div class="mainHeading">Terminated employees</div>
    <div class="mainDescription">
      Below is a list of all 1 terminated employees.
    </div>
    <div class="TerminatedEmployeesTable mt-32px">
      <div class="HrmTableHead-container">
        <div class="HrmTableHead one">EMPLOYEE</div>
        <div class="HrmTableHead two">LAST WORKING DAY</div>
        <div class="HrmTableHead three">LENGTH OF SERVICE</div>
        <div class="HrmTableHead four">REASON</div>
        <div class="HrmTableHead five">TEAM</div>
      </div>
      <vb-table class="HrmTable">
        <template #body>
          <tr class="dialer-row-select">
            <td class="dialer-row-title one">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-textContainer">
                  <div class="nameWithImage-text">Jackson Will</div>
                  <div class="nameWithImage-textSmall">8001</div>
                </div>
              </div>
            </td>
            <td class="dialer-row-title two">
              13/09/2022
            </td>
            <td class="dialer-row-title three">
              8 years 5 months 19 days
            </td>
            <td class="dialer-row-title four">
              Retirement
            </td>
            <td class="dialer-row-title five">
              <b-dropdown no-caret right class="ActionsDD">
                <template #button-content>
                  <div class="button-content">
                    <b-icon class="downIcon" icon="three-dots-vertical" />
                  </div>
                </template>
                <b-dropdown-item >
                  All teams
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TerminatedEmployees',
}
</script>

<style>

</style>