
import { VOIP_API } from "../index"

export const ANALYTICS_API_ENDPOINTS = {
  graph(params,cancelToken=''){
    return VOIP_API.axios.voip.request({
      url: "new-call-analytics",
      method: "GET",
      params,
      cancelToken,
    })
  },
  list(params,cancelToken=''){
    return VOIP_API.axios.voip.request({
      url: "call-analytics",
      method: "GET",
      params,
      cancelToken,
    })
  },
  billingList(data={}){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'billing_cdr',
        type: 'relay',
      },
    })
  },
  singlestat(params={}){
    return VOIP_API.axios.voip.request({
      url: "get-user-call-stats",
      method: "GET",
      params,
    })
  },
}