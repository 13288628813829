<template>
  <div>
    <Home v-if="activeTab === 'pamarly-home'" ref="pamarly-home" />
    <Teams v-if="activeTab === 'pamarly-teams'" ref="pamarly-teams" />
    <Resources v-if="activeTab === 'pamarly-resources'" ref="pamarly-resources" />
    <Permissions v-if="activeTab === 'pamarly-permissions'" ref="pamarly-permissions" />
    <Folder v-if="activeTab === 'pamarly-folder'" ref="pamarly-folder" />
    <DeletedResources v-if="activeTab === 'pamarly-deleted-resources'" ref="pamarly-deleted-resources" />
    <History v-if="activeTab === 'pamarly-history'" ref="pamarly-history" />
  </div>
</template>

<script>
import Home from './Home.vue'
import Teams from './Teams.vue'
import Resources from './Resources.vue'
import Permissions from './Permissions.vue'
import History from './History.vue'
import DeletedResources from './DeletedResources.vue'
import Folder from './Folder/index.vue'
export default {
  name: 'PamarlyView',
  components: {
    Home,
    Teams,
    Resources,
    Permissions,
    Folder,
    History,
    DeletedResources,
  },
  computed: {
    activeTab(){ return this.$store.state.global_conditions.tab },
  },
}
</script>

<style>

</style>