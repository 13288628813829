<template>
  <div>

  </div>
</template>

<script>
import { mapActions } from 'vuex'
import { PAMARLY_LOGIN } from '@/store/helper/actions'
export default {
  name: 'CommonPamarly',
  methods: {
    ...mapActions([
      PAMARLY_LOGIN
    ]),
  },
  mounted(){
    this.PAMARLY_LOGIN()
  },
}
</script>

<style>

</style>