<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-800 editTeamModal bigSquareCheckbox editTaskModal-selectUserModal" 
      width="40%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <section class="ct-user-new dialer-edit pb-4">
        <div class="dialer-edit-header">
          <div>
            <h2 class="dialer-edit-title">Select user</h2>
          </div>
          <div class="dialer-edit-actions">
            <button 
              class="newDoneButton" 
              @click="$emit('select',{ accountcode: selected.accountcode, extra_data: data.extra_data });$modal.hide(modalName)"
            >
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBGinputWithGreyRoundedBorder">
            <b-input v-model="filter.search" />
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="user in users" :key="user.accountcode">
              <b-form-checkbox 
                :checked="selected.accountcode==user.accountcode" 
                @change="selected.accountcode=$event ? user.accountcode : ''"
                class="putBeforeAfterOnLeft16px p-0"
              >
                <Info :id="user.accountcode" :is_blf="false" />
              </b-form-checkbox>
            </div>
          </div>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Info from '../Lists/Info.vue';
export default {
  name: "SelectVoipUserModal",
  components: { 
    Info 
  },
  props: {
    modalName: {
      type: String,
      default: 'SelectVoipUser'
    },
  },
  data() {
    return {
      selected: {
        accountcode: '',
      },
      filter: {
        search: '',
      },
      data: {
        user_accountcodes: [],
        extra_data: {},
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsers',
      'getVoipUsersAlisesDisplayName',
      'getVoipUsersAlises',
    ]),
    users(){
      const search = this.filter.search.toLowerCase().trim()
      const user_accountcodes = this.data.user_accountcodes
      let accountcodes = user_accountcodes
      if(user_accountcodes.length==0){
        accountcodes = Object.keys(this.getVoipUsers)
      }
      const users = accountcodes.map(i=>({
        accountcode: i,
        display_name: this.getVoipUsersAlisesDisplayName[i] || '',
        extn: this.getVoipUsersAlises[i]?.extn || '',
      }))
      return users.filter(i=>`${i.display_name} ${i.extn}`.toLowerCase().trim().includes(search))
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.selected.accountcode = event?.params?.accountcode ?? '';
      this.data.user_accountcodes = event?.params?.accountcodes ?? [];
      this.data.extra_data = event?.params?.extra_data ?? {};
    },
    onBeforeClose() {
      this.selected.accountcode=''
      this.filter.search=''
      this.data.user_accountcodes=[]
      this.data.extra_data={}
    },
  },
};
</script>
