import { VOIP_API } from "../../index"


export const AUTH_CRM_API_ENDPOINTS = {
  login(data={}){
    return VOIP_API.axios.crm.request({
      url: 'token',
      method: 'POST',
      data,
      notHandleUnauthorize: true,
    })
  },
}