var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Notes ")]), _vm._m(0), _c('div', {
    staticClass: "EmployeeDataNotesTable mt-32px"
  }, [_vm._m(1), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-textContainer"
        }, [_c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")]), _c('div', {
          staticClass: "nameWithImage-textSmall"
        }, [_vm._v("8001")])])])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v(" 27/07/2022 ")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_vm._v(" In this example from GMail, they launched a tour that announced two new features... ")]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-textContainer"
        }, [_c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")]), _c('div', {
          staticClass: "nameWithImage-textSmall"
        }, [_vm._v("8001")])])])]), _c('td', {
          staticClass: "dialer-row-title five"
        })])];
      },
      proxy: true
    }])
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription mb-3"
  }, [_c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Added to")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("Date")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Note")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("Created by")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Documents")])]);

}]

export { render, staticRenderFns }