<template>
  <div style="height: 100vh; width:100vw;">
    <loaderMain />
  </div>
</template>

<script>
import LoaderMain from '../components/LoaderMain.vue';
export default {
  components: { LoaderMain },
  name: 'DirectAccessDenied',
  mounted(){
    window.location.href = `https://${process.env.VUE_APP_URL_MOTHERSHIP}/`;
  },
}
</script>

<style>

</style>