var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup developerOptionsModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Features")]), _c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticStyle: {
      "max-height": "calc(100vh - 120px)",
      "overflow-y": "auto",
      "height": "calc(100vh - 120px)"
    }
  }, [_c('div', {
    staticClass: "dialer-login-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.check();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 w-100"
  }, [_c('div', {
    staticClass: "wrap w-100"
  }, [_c('div', {
    staticClass: "signupLoginV2-section-2-right w-100 pr-3"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder d-flex justify-content-end mt-20px"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.features.search,
      expression: "filters.features.search"
    }],
    staticClass: "w-50",
    attrs: {
      "type": "text",
      "placeholder": "Search..."
    },
    domProps: {
      "value": _vm.filters.features.search
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.features, "search", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main text-left wd-33-imp"
  }, [_vm._v("All")]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder text-left dropdownVersion wd-33-imp"
  }, [_c('b-form-select', {
    staticClass: "w-100",
    attrs: {
      "options": _vm.featuresOptions
    },
    model: {
      value: _vm.allFeatures,
      callback: function ($$v) {
        _vm.allFeatures = $$v;
      },
      expression: "allFeatures"
    }
  })], 1)]), _vm._l(_vm.filterFeatures, function (key) {
    return _c('div', {
      key: key,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
    }, [_c('div', {
      staticClass: "latestGreyBox-heading-main text-left wd-33-imp"
    }, [_vm._v(_vm._s(key))]), _c('div', {
      staticClass: "whiteBGinputWithGreyRoundedBorder text-left dropdownVersion wd-33-imp"
    }, [_c('b-form-select', {
      staticClass: "w-100",
      attrs: {
        "options": _vm.featuresOptions
      },
      model: {
        value: _vm.$store.state.common.features[key],
        callback: function ($$v) {
          _vm.$set(_vm.$store.state.common.features, key, $$v);
        },
        expression: "$store.state.common.features[key]"
      }
    })], 1), _c('div', {
      staticClass: "d-flex wd-33-imp justify-content-end"
    }, [_vm.$store.state.common.features[key] == 'yes' ? _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'Locally',
        expression: "'Locally'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      attrs: {
        "font-scale": "1.6",
        "icon": "check-square-fill"
      }
    }) : _vm.$store.state.common.features[key] == 'no' ? _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'Locally',
        expression: "'Locally'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      attrs: {
        "font-scale": "1.6",
        "icon": "exclamation-square-fill"
      }
    }) : _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'Locally',
        expression: "'Locally'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      attrs: {
        "font-scale": "1.6",
        "icon": "square"
      }
    }), _vm.getUserAllowedFeatures.includes(`yes|${key}`) ? _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'User Allowed',
        expression: "'User Allowed'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "ml-1",
      attrs: {
        "font-scale": "1.6",
        "icon": "check-square-fill"
      }
    }) : _vm.getUserAllowedFeatures.includes(`no|${key}`) ? _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'User Allowed',
        expression: "'User Allowed'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "ml-1",
      attrs: {
        "font-scale": "1.6",
        "icon": "exclamation-square-fill"
      }
    }) : _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'User Allowed',
        expression: "'User Allowed'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "ml-1",
      attrs: {
        "font-scale": "1.6",
        "icon": "square"
      }
    }), _vm.$store.state.common.user.appMode == 'standalone' ? _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'Stand alone',
        expression: "'Stand alone'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "ml-1",
      attrs: {
        "font-scale": "1.6",
        "icon": "check-square-fill"
      }
    }) : _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'Stand alone',
        expression: "'Stand alone'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "ml-1",
      attrs: {
        "font-scale": "1.6",
        "icon": "square"
      }
    }), _vm.getUserPlanFeatures.includes(key) ? _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'Plan',
        expression: "'Plan'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "ml-1",
      attrs: {
        "font-scale": "1.6",
        "icon": "check-square-fill"
      }
    }) : _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'Plan',
        expression: "'Plan'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "ml-1",
      attrs: {
        "font-scale": "1.6",
        "icon": "square"
      }
    }), (_vm.$store.state.common.features[key] == 'yes' ? true : _vm.$store.state.common.features[key] == 'no' ? false : _vm.getUserAllowedFeatures.includes(`no|${key}`) ? false : _vm.getUserAllowedFeatures.includes(`yes|${key}`) ? true : _vm.$store.state.common.user.appMode == 'standalone' ? true : _vm.getUserPlanFeatures.includes(key) ? true : false) ? _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'Overall',
        expression: "'Overall'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "ml-1",
      attrs: {
        "font-scale": "1.6",
        "icon": "check-square-fill"
      }
    }) : _c('b-icon', {
      directives: [{
        name: "b-popover",
        rawName: "v-b-popover.hover.top",
        value: 'Overall',
        expression: "'Overall'",
        modifiers: {
          "hover": true,
          "top": true
        }
      }],
      staticClass: "ml-1",
      attrs: {
        "font-scale": "1.6",
        "icon": "square"
      }
    })], 1)]);
  })], 2)])])])])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }