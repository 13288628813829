var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable EditPictureModal IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_vm.conditions.screen == 1 ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 0;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Category")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Update Image")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_vm.conditions.screen == 1 ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.response.categories, function (category) {
    return _c('div', {
      key: category.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.category = category;
          _vm.conditions.screen = 0;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideCard mr-8px"
    }, [_vm._v(_vm._s(category.label))]), _vm.selected.category.id == category.id ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.isEmpty(_vm.response.categories) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.fetch_categories.send ? '' : 'There is no Category',
      "design": 3
    }
  }, [_vm.api.fetch_categories.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)] : [_c('div', {
    staticClass: "mt-32px currentImageContainerIos",
    attrs: {
      "aria-disabled": _vm.api.upload.send
    }
  }, [_vm.api.upload.send ? _c('div', {
    staticClass: "loader-icon"
  }, [_c('vb-loading'), _c('div', {
    staticClass: "loaderPercentageText w-100 d-flex justify-content-center"
  }, [_vm._v(" " + _vm._s(_vm.api.upload.upload_progress.percentage) + "% ")])], 1) : _c('div', {
    staticClass: "current-image"
  }, [_c('img', {
    attrs: {
      "id": "profile-image",
      "src": _vm.data.current_image || '',
      "alt": 'Profile Image'
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])]), _c('div', {
    staticClass: "textAboveCard mt-8px"
  }, [_vm._v("Update "), _c('span', {
    staticStyle: {
      "text-transform": "lowercase"
    }
  }, [_vm._v(_vm._s(_vm.data.type))]), _vm._v(" profile")]), ['USER', 'COMPANY'].includes(_vm.data.type) ? [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$refs.image_uploader.click();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "uploadProfileImage-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "headingInsideCard mr-8px"
  }, [_vm._v("Upload Image")]), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }, {
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    ref: "image_uploader",
    staticClass: "hide_input cursor_pointer_no_hover",
    attrs: {
      "type": "file",
      "accept": "image/*",
      "disabled": _vm.api.upload.send,
      "multiple": false,
      "title": "Upload image"
    },
    on: {
      "change": function ($event) {
        return _vm.uploadImage({
          type: 'upload',
          event: $event
        });
      }
    }
  })]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "uploadProfileImage-onRightSide-IOSIcon",
      "width": "21.562",
      "height": "21.562"
    }
  })], 1)])])] : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 1;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "uploadProfileImage-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "headingInsideCard mr-8px"
  }, [_vm._v("Category")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(" " + _vm._s(_vm.selected.category ? _vm.selected.category.label : 'Select Category') + " ")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])])]], 2), _vm.conditions.screen != 1 ? _c('div', {
    class: `all-images-container Ios ${_vm.conditions.show_upload ? 'small' : ''}`
  }, [_c('div', {
    staticClass: "all-images-container-inner",
    attrs: {
      "id": "profile-image-uploader-avatars-div"
    }
  }, [_vm.isEmpty(_vm.response.avatars) && (_vm.api.fetch_avatars.send || _vm.api.fetch_categories.send) ? _c('vb-loading') : _vm._e(), _vm._l(_vm.response.avatars, function (avatar) {
    return _c('div', {
      key: avatar.id,
      staticClass: "image-container",
      on: {
        "click": function ($event) {
          return _vm.uploadImage({
            type: 'avatar',
            avatar: avatar
          });
        }
      }
    }, [_c('img', {
      attrs: {
        "id": `avatar-${avatar.id}`,
        "src": avatar.image
      },
      on: {
        "error": function ($event) {
          $event.target.src = require('@/assets/images/user-male.png');
        }
      }
    })]);
  })], 2)]) : _vm._e()])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }