<template>
  <div class="crm-main-section DealsSection">
    <div class="d-flex justify-content-between align-items-center w-100 mb-5">
      <h2 class="crm-main-section-heading text-case-unset mb-0">
        Product Fields
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </h2>
      <button class="newButton">
        <vb-icon icon="squared-add-icon" height="38px" width="38px" />
        <span class="newButton-textPart position-relative d-flex justify-content-center">
          Add new
        </span>
      </button>
    </div>
    <div class="crm-card p-32">
      <h2 class="crm-main-section-heading text-case-unset my-0" style="color:black;">
        Creation Fields
      </h2>
      <div class="d-flex justify-content-between align-items-center w-100 mt-2">
        <p class="crm-card-text my-0">
          Fields that will be displayed when any user creates a record.
        </p>
        <button class="newButton">
          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
          <span class="newButton-textPart position-relative d-flex justify-content-center">
            Manage fields
          </span>
        </button>
      </div>
    </div>
    <div class="crm-card p-32">
      <h2 class="crm-main-section-heading text-case-unset my-0" style="color:black;">
        Edit / Preview Fields
      </h2>
      <div class="d-flex justify-content-between align-items-center w-100 mt-2">
        <p class="crm-card-text my-0">
          Fields that are available and will be displayed on record edit/preview view.
        </p>
        <button class="newButton">
          <vb-icon icon="squared-change-icon" height="38px" width="38px" />
          <span class="newButton-textPart position-relative d-flex justify-content-center">
            Manage fields
          </span>
        </button>
      </div>
    </div>
    <div class="crm-card p-32">
      <h2 class="crm-main-section-heading text-case-unset my-0" style="color:black;">
        List Fields
      </h2>
      <div class="d-flex justify-content-between align-items-center w-100 mt-2">
        <p class="crm-card-text my-0">
          To adjust the fields that are displayed on the list view, when viewing the Companies list, click the  icon located at the navigation bar and choose 
          "List settings".
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'ProductFields',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      },
      allowedUploadfileExtentions: 'jpg, jpeg, png, gif, svg, pdf, aac, ogg, oga, mp3, wav, mp4, m4v, mov, ogv, webm, zip, rar, doc, docx, txt, text, xml, json, xls, xlsx, odt, csv, ppt, pptx, ppsx, ics, eml'
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>