
import { VOIP_API } from "../index"

export const DEFAULT_AVATARS_API_ENDPOINTS = {
  getcategories(params={}){
    return VOIP_API.axios.voip.request({
      url: 'image-category',
      method: "GET",
      params,
    })
  },
  getdefaultavatarsbycategoryid(data={}){
    return VOIP_API.axios.voip.request({
      url: 'default-aviators',
      method: "POST",
      data,
    })
  },
}