var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "sec-common"
  }, [_vm._m(0), _c('p', {
    staticClass: "compensate-top-text space4text-when-scroll-activate"
  }, [_vm._v(" Manage onboarding tasks for Ahsen van der ")]), _c('vb-table', {
    staticClass: "compensate-table-width mt-5",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth compensate-one col-left"
  }, [_vm._v("Dates")]), _c('h5', {
    staticClass: "commonth compensate-two"
  }, [_vm._v("amount")]), _c('h5', {
    staticClass: "commonth compensate-third"
  }, [_vm._v("type")]), _c('h5', {
    staticClass: "commonth compensate-four"
  }, [_vm._v("reason")]), _c('h5', {
    staticClass: "commonth compensate-five"
  }, [_vm._v("Comments")]), _c('h5', {
    staticClass: "commonth compensate-six col-right"
  }, [_vm._v("Action")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "compensate-one"
  }, [_vm._v(" 02/03/2022 - \"Current\" ")]), _c('td', {
    staticClass: "compensate-two"
  }, [_vm._v(" £100.00 ")]), _c('td', {
    staticClass: "compensate-third"
  }, [_vm._v(" Salary ")]), _c('td', {
    staticClass: "compensate-four"
  }, [_vm._v(" Promotion ")]), _c('td', {
    staticClass: "compensate-five"
  }, [_vm._v(" No comments ")]), _c('td', {
    staticClass: "compensate-six"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])], 2)], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Compensation")])])]);

}]

export { render, staticRenderFns }