<template>
  <div :class="`dialer-circularProgressBar`">
    <div :class="`circularProgressBar ${percentage > 50 ? 'gt-50' : ''}`">
      <div class="circularProgressBar-fill"></div>
    </div>
    <div class="dialer-circularProgressBar-text">
      <span>{{ percentage }}%</span>
    </div>
  </div>
</template>

<script>
// import { LOGGER } from '../../utils';
export default {
  name: "vb-circular-progressive-bar",
  props: {
    percentage: {
      type: Number,
      default: 0,
    },
  },
  watch: {
    percentage() {
      this.percent();
    },
  },
  methods: {
    percent() {
      let vm = this;
      setTimeout(() => {
        var circularProgressBarFill = vm.$el.querySelector(
          ".circularProgressBar-fill"
        );
        var deg = (360 * this.percentage) / 100;
        // LOGGER.log(circularProgressBarFill)
        circularProgressBarFill.style.transform = "rotate(" + deg + "deg)";
      }, 1 * 1000);
    },
  },
  mounted() {
    this.percent();
  },
};
</script>

<style lang="scss" scoped>
$size: 60px;
@mixin circle($size) {
  content: "";
  position: absolute;
  border-radius: 50%;
  left: calc(50% - #{calc($size / 2)});
  top: calc(50% - #{calc($size / 2)});
  width: $size;
  height: $size;
}

.dialer-circularProgressBar {
  width: $size;
  height: $size;
  border-radius: 50%;
  background-color: $dialer-circularProgressBar-bgColor;
  position: relative;
  .circularProgressBar {
    @include circle($size);
    clip: rect(0, $size, $size, #{calc($size / 2)});
    .circularProgressBar-fill {
      @include circle($size);
      clip: rect(0, #{calc($size / 2)}, $size, 0);
      background: $dialer-circularProgressBar-bgColor;
    }
    &.gt-50  {
      clip: rect(0, #{calc($size / 2)}, $size, 0);
      .circularProgressBar-fill {
        clip: rect(0, $size, $size, #{calc($size / 2)});
        background: red;
      }
    }
  }
  .dialer-circularProgressBar-text {
    @include circle(#{calc($size / 1.15)});
    background: $white;
    display: flex;
    align-items: center;
    justify-content: center;
    span {
      display: block;
      font-size: 14px;
      font-weight: bold;
      color: $dialer-circularProgressBar-bgColor;
    }
  }
}
</style>