var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.topupsetting.send ? '' : _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Topup Setting")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateTopupSetting();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.topupsetting.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.topupsetting.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Voip Balance")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.topupsetting.send
    },
    model: {
      value: _vm.forms.topupsetting.voip_enable,
      callback: function ($$v) {
        _vm.$set(_vm.forms.topupsetting, "voip_enable", $$v);
      },
      expression: "forms.topupsetting.voip_enable"
    }
  })], 1)]), _vm.forms.topupsetting.voip_enable ? [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("My balance hit threshold of ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.topupsetting.voip_balance_limit,
      expression: "forms.topupsetting.voip_balance_limit"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "number",
      "disabled": _vm.api.topupsetting.send
    },
    domProps: {
      "value": _vm.forms.topupsetting.voip_balance_limit
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.topupsetting, "voip_balance_limit", $event.target.value);
      }
    }
  }), _vm.forms.topupsetting.submitted && _vm.$v.forms.topupsetting.voip_balance_limit.$invalid || _vm.api.topupsetting.validation_errors.voip_balance_limit ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.topupsetting.voip_balance_limit.required ? _c('span', [_vm._v("* Credit Limit field is required")]) : _vm._e(), !_vm.$v.forms.topupsetting.voip_balance_limit.maxValue ? _c('span', [_vm._v(" * Credit Limit maximum " + _vm._s(_vm.$v.forms.topupsetting.voip_balance_limit.$params.maxValue.max) + " only ")]) : _vm._e(), _vm._l(_vm.api.topupsetting.validation_errors.voip_balance_limit, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Topup the following amount")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.topupsetting.voip_amount,
      expression: "forms.topupsetting.voip_amount"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "number",
      "disabled": _vm.api.topupsetting.send
    },
    domProps: {
      "value": _vm.forms.topupsetting.voip_amount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.topupsetting, "voip_amount", $event.target.value);
      }
    }
  }), _vm.forms.topupsetting.submitted && _vm.$v.forms.topupsetting.voip_amount.$invalid || _vm.api.topupsetting.validation_errors.voip_amount ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.topupsetting.voip_amount.required ? _c('span', [_vm._v("* Amount to topup field is required")]) : _vm._e(), _vm._l(_vm.api.topupsetting.validation_errors.voip_amount, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])] : _vm._e()], 2), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Voip Balance")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.topupsetting.send
    },
    model: {
      value: _vm.forms.topupsetting.whmcs_enable,
      callback: function ($$v) {
        _vm.$set(_vm.forms.topupsetting, "whmcs_enable", $$v);
      },
      expression: "forms.topupsetting.whmcs_enable"
    }
  })], 1)]), _vm.forms.topupsetting.whmcs_enable ? [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("My balance hit threshold of ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.topupsetting.whmcs_balance_limit,
      expression: "forms.topupsetting.whmcs_balance_limit"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "number",
      "disabled": _vm.api.topupsetting.send
    },
    domProps: {
      "value": _vm.forms.topupsetting.whmcs_balance_limit
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.topupsetting, "whmcs_balance_limit", $event.target.value);
      }
    }
  }), _vm.forms.topupsetting.submitted && _vm.$v.forms.topupsetting.whmcs_balance_limit.$invalid || _vm.api.topupsetting.validation_errors.whmcs_balance_limit ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.topupsetting.whmcs_balance_limit.required ? _c('span', [_vm._v("* Credit Limit field is required")]) : _vm._e(), !_vm.$v.forms.topupsetting.whmcs_balance_limit.maxValue ? _c('span', [_vm._v(" * Credit Limit maximum " + _vm._s(_vm.$v.forms.topupsetting.whmcs_balance_limit.$params.maxValue.max) + " only ")]) : _vm._e(), _vm._l(_vm.api.topupsetting.validation_errors.whmcs_balance_limit, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Topup the following amount")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.topupsetting.whmcs_amount,
      expression: "forms.topupsetting.whmcs_amount"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "number",
      "disabled": _vm.api.topupsetting.send
    },
    domProps: {
      "value": _vm.forms.topupsetting.whmcs_amount
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.topupsetting, "whmcs_amount", $event.target.value);
      }
    }
  }), _vm.forms.topupsetting.submitted && _vm.$v.forms.topupsetting.whmcs_amount.$invalid || _vm.api.topupsetting.validation_errors.whmcs_amount ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.topupsetting.whmcs_amount.required ? _c('span', [_vm._v("* Amount to topup field is required")]) : _vm._e(), _vm._l(_vm.api.topupsetting.validation_errors.whmcs_amount, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])] : _vm._e()], 2), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.topupsetting.send
    }
  }, [_vm.api.topupsetting.send ? _c('vb-spinner') : [_vm._v("Update")]], 2)], 1)])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Topup Setting")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Voip")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.topupsetting.voip_enable || 'no'))])]), _vm.response.topupsetting.voip_enable == 'yes' ? [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Voip Balance Limit")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.topupsetting.voip_balance_limit))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Voip Amount")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.topupsetting.voip_amount))])])] : _vm._e()], 2), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("WHMCS")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.topupsetting.whmcs_enable || 'no'))])]), _vm.response.topupsetting.whmcs_enable == 'yes' ? [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("WHMCS Balance Limit")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.topupsetting.whmcs_balance_limit))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("WHMCS Amount")]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.topupsetting.whmcs_amount))])])] : _vm._e()], 2)])])]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }