var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-users"
  }, [_vm.conditions.screen == 1 ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 0;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Date")])]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Users")])]), _c('div', {
    staticClass: "dialer-box AnalyticsContainerInner"
  }, [_vm.conditions.screen == 1 ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.key;
          _vm.selected.tab != 'custom' ? _vm.fetchUsersAnalytics(true) : '';
          _vm.selected.tab != 'custom' ? _vm.conditions.screen = 0 : '';
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(tab.value))])])]), _vm.selected.tab === tab.key ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0), _vm.selected.tab == 'custom' ? _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2 forDateTime"
  }, [_c('label', [_vm._v("From")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "starttime"
    },
    model: {
      value: _vm.filter.users_analytics.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.filter.users_analytics, "start_date", $$v);
      },
      expression: "filter.users_analytics.start_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2 forDateTime"
  }, [_c('label', [_vm._v("To")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "endtime"
    },
    model: {
      value: _vm.filter.users_analytics.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.filter.users_analytics, "end_date", $$v);
      },
      expression: "filter.users_analytics.end_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)])]) : _vm._e(), _c('a', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    on: {
      "click": function ($event) {
        _vm.fetchUsersAnalytics(true);
        _vm.conditions.screen = 0;
      }
    }
  }, [_vm._v("Fetch")])] : [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search user"
    },
    model: {
      value: _vm.filter.users_analytics.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.users_analytics, "search", $$v);
      },
      expression: "filter.users_analytics.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 1;
      }
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-8px"
  }, [_vm._v(_vm._s(_vm.selectedTab.value))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)])]), _c('div', {
    staticClass: "IosTabsSection twoTabs mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "IosTabButton",
    class: {
      'active': !_vm.conditions.advanced
    },
    on: {
      "click": function ($event) {
        _vm.conditions.advanced = false;
      }
    }
  }, [_vm._v("Basic")]), _c('div', {
    staticClass: "IosTabButton",
    class: {
      'active': _vm.conditions.advanced
    },
    on: {
      "click": function ($event) {
        _vm.conditions.advanced = true;
      }
    }
  }, [_vm._v("Advance")])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', _vm._l(_vm.filterTimeLine, function (time_line) {
    return _c('div', {
      key: time_line.measure,
      class: `basicWhiteIOScard-item ${_vm.conditions.advanced ? 'withAdvancedSection' : ''}`,
      on: {
        "click": function ($event) {
          return _vm.$modal.show('UserAnalyticsModal', {
            user: time_line.user,
            time_line: time_line
          });
        }
      }
    }, [_c('div', {
      staticClass: "w-100 d-flex align-items-center justify-content-between"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "is_blf": false,
        "id": time_line.kpis.accountcode,
        "name": time_line.user.name,
        "sub_info": time_line.user.extn
      }
    })], 1), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "d-flex mr-6px"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight",
      staticStyle: {
        "color": "#87868C"
      }
    }, [_vm._v(_vm._s(_vm._f("get_property")(time_line, 'user.totalCalls', 0)))]), _c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight mx-1"
    }, [_vm._v("/")]), _c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight",
      staticStyle: {
        "color": "#FF1900"
      }
    }, [_vm._v(_vm._s(_vm._f("get_property")(time_line, 'user.totalMissCalls', 0)))])]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)]), _vm.conditions.advanced ? _c('div', {
      staticClass: "advanceSection"
    }, [_c('div', {
      staticClass: "advanceSection-inner InboundCall"
    }, [_c('div', {
      staticClass: "aboveProgressBar"
    }, [_c('vb-icon', {
      staticClass: "rightFacingArrow mr-8px",
      attrs: {
        "icon": "CallSummary-InboundCall-icon",
        "width": "16.642",
        "height": "15.275"
      }
    }), _c('div', {
      staticClass: "aboveProgressBar-text"
    }, [_vm._v(_vm._s(_vm._f("get_property")(time_line, 'user.totalIncommingCalls', 0)))])], 1), _c('vb-circular-progressive-bar', {
      attrs: {
        "percentage": time_line.user.totalIncommingCalls > 0 ? Math.ceil(time_line.user.totalIncommingCalls / time_line.user.totalCalls * 100) : 0
      }
    })], 1), _c('div', {
      staticClass: "advanceSection-inner outboundCall"
    }, [_c('div', {
      staticClass: "aboveProgressBar"
    }, [_c('vb-icon', {
      staticClass: "rightFacingArrow mr-8px",
      attrs: {
        "icon": "CallSummary-OutboundCall-icon",
        "width": "16.642",
        "height": "15.275"
      }
    }), _c('div', {
      staticClass: "aboveProgressBar-text"
    }, [_vm._v(_vm._s(_vm._f("get_property")(time_line, 'user.totalOutgoingCalls', 0)))])], 1), _c('vb-circular-progressive-bar', {
      attrs: {
        "percentage": time_line.user.totalOutgoingCalls > 0 ? Math.ceil(time_line.user.totalOutgoingCalls / time_line.user.totalCalls * 100) : 0
      }
    })], 1), _c('div', {
      staticClass: "advanceSection-inner missedCall"
    }, [_c('div', {
      staticClass: "aboveProgressBar"
    }, [_c('vb-icon', {
      staticClass: "rightFacingArrow mr-8px",
      attrs: {
        "icon": "CallSummary-MissedCall-icon",
        "width": "16.642",
        "height": "15.275"
      }
    }), _c('div', {
      staticClass: "aboveProgressBar-text"
    }, [_vm._v(_vm._s(_vm._f("get_property")(time_line, 'user.totalMissCalls', 0)))])], 1), _c('vb-circular-progressive-bar', {
      attrs: {
        "percentage": time_line.user.totalMissCalls > 0 ? Math.ceil(time_line.user.totalMissCalls / time_line.user.totalCalls * 100) : 0
      }
    })], 1)]) : _vm._e()]);
  }), 0), _c('InfiniteLoading', {
    ref: "users_analytics_infinity_loader",
    on: {
      "infinite": function ($event) {
        return _vm.fetchUsersAnalytics();
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (_ref) {
        var trigger = _ref.trigger;
        return [_c('div', [_c('span', [_vm._v(_vm._s(_vm.api.users_analytics.error_message))]), _c('b-icon', {
          staticClass: "ml-3",
          attrs: {
            "icon": "arrow-repeat",
            "variant": "primary",
            "font-scale": "1.5"
          },
          on: {
            "click": function ($event) {
              return trigger();
            }
          }
        })], 1)];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "spinner"
    },
    slot: "spinner"
  }, [_c('vb-loading', {
    attrs: {
      "type": "loader"
    }
  })], 1), _c('div', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }), _c('div', {
    attrs: {
      "slot": "no-results"
    },
    slot: "no-results"
  }, [_c('vb-no-record', {
    attrs: {
      "text": 'There is no Numbers',
      "design": 3
    }
  })], 1)])], 1)]], 2), _c('UserAnalyticsModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Date")])])]);

}]

export { render, staticRenderFns }