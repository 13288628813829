var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.select_voices ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.text;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Voice")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.voicesOptions, function (voice) {
    return _c('div', {
      key: voice.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.text_to_audio.voice = voice.value;
          _vm.conditions.screen = _vm.screens.text;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v(_vm._s(voice.text))]), _vm.forms.text_to_audio.voice == voice.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.voicesOptions.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.voices.send ? '' : 'There is no Option',
      "design": 3
    }
  }, [_vm.api.voices.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])])] : _vm._e(), _vm.conditions.screen == _vm.screens.text ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Text")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.convert();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.text_to_audio.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.text_to_audio.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Text")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.text_to_audio.text,
      expression: "forms.text_to_audio.text"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Text",
      "type": "text",
      "disabled": _vm.api.text_to_audio.send
    },
    domProps: {
      "value": _vm.forms.text_to_audio.text
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.text_to_audio, "text", $event.target.value);
      }, _vm.updateText]
    }
  }), _vm._v(" "), _vm.forms.text_to_audio.submitted && _vm.$v.forms.text_to_audio.text.$invalid || _vm.api.text_to_audio.validation_errors.text ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.text_to_audio.text.required ? _c('span', [_vm._v("* Text is required")]) : _vm._e(), _vm._l(_vm.api.text_to_audio.validation_errors.text, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_voices;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Voice")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forms.text_to_audio.voice))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _vm.forms.text_to_audio.submitted && _vm.$v.forms.text_to_audio.voice.$invalid || _vm.api.text_to_audio.validation_errors.voice ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.text_to_audio.voice.required ? _c('span', [_vm._v("* Text is required")]) : _vm._e(), _vm._l(_vm.api.text_to_audio.validation_errors.voice, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.text_to_audio.send
    }
  }, [_vm.api.text_to_audio.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)], 1)])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Welcome Greeting")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Audio")]), _vm.audio.src ? _c('vb-audio-player', {
    staticClass: "mr-16px IosAudioPlayer",
    attrs: {
      "src": _vm.audio.src
    }
  }) : _c('vb-audio-player', {
    staticClass: "mr-16px IosAudioPlayer",
    attrs: {
      "src": _vm._f("welcomgreetingsrc")(_vm.ivr)
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('UploadAudioModal');
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Upload")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AudioRecording');
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Recording")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectSoundFile');
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Library")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.text;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Text")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])])])], _c('SelectSoundFileModal', {
    on: {
      "select-file": function ($event) {
        return _vm.upload({
          type: _vm.uploadType.library,
          sound: $event.file
        });
      }
    }
  }), _c('AudioRecordingModal', {
    on: {
      "audio": function ($event) {
        $event.hide();
        _vm.upload({
          type: _vm.uploadType.recording,
          blob: $event.blob,
          url: $event.url
        });
      }
    }
  }), _c('UploadAudioModal', {
    on: {
      "audio": function ($event) {
        $event.hide();
        _vm.upload({
          type: _vm.uploadType.upload,
          file: $event.file,
          url: $event.url
        });
      }
    }
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }