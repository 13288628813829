import { LOGGER } from "@/utils/index";
export const SALESFORCE = {
  cti: null,
  call_backs: {
    onReady: null,
    onDialNumber: null,
  },
  init(props){
    this.call_backs.onDialNumber = props.onDialNumber
    this.call_backs.onReady = props.onReady
    this.scriptLoad()
    return this
  },
  scriptLoad(){
    let loaded = 0
    let that = this
    function onload(){
      loaded++
      if(loaded==2){
        that.onLoad()
      }
    }
    let head = document.getElementsByTagName('head')[0];
    let script = document.createElement('script');
    script.type = 'text/javascript';
    script.src = "/assets/js/integrations/salesforce/integration.js";
    head.appendChild(script);
    script.onload = onload
    let script2 = document.createElement('script');
    script2.type = 'text/javascript';
    script2.src = "/assets/js/integrations/salesforce/opencti_min.js";
    head.appendChild(script2);
    script2.onload = onload
  },
  onLoad(){
    this.cti=window.sforce
    if(this.cti){
      this.cti.opencti.enableClickToDial({
        callback: this.onReady.bind(this)
      });
      this.cti.opencti.onClickToDial({
        listener: this.onDialNumber.bind(this)
      });
    }
  },
  getIsVisible(){
    return new Promise((resolve,reject)=>{
      this.cti.opencti.isSoftphonePanelVisible({
        callback(result) {
          if (result.success) {
            let popup =  result.returnValue;
            resolve(!!popup.visible)
          } else {
            reject(result.errors);
          }
        }
      });
    })
  },
  setVisible(){
    return new Promise((resolve,reject)=>{
      this.cti.opencti.setSoftphonePanelVisibility({
        visible: true,
        callback(result) {
          if (result.success) {
            resolve()
          } else {
            reject()
          }
        }
      }) 
    })
  },
  async onDialNumber(event){
    LOGGER.log(`SALESFORCE:onDialNumber`,event)
    try {
      this.call_backs.onDialNumber?.({
        number: event.number,
        displayName : event.displayName,
        objectId : event.objectId,
        extraHeader: `SALESFORCE: number/${event.number},displayName/${event.displayName},objectId/${event.objectId}`,
      })
      const visible = await this.getIsVisible()
      if(!visible){
        await this.setVisible()
      }
    } catch(error) {
      LOGGER.danger(`SALESFORCE:onDialNumber`,error)
    }
  },
  async onReady(event){
    LOGGER.log(`SALESFORCE:onReady`,event)
    if(event.success){
      LOGGER.success(`SALESFORCE:onReady`)
      this.call_backs.onReady?.()
    } else if(event.error) {
      LOGGER.danger(`SALESFORCE:onReady`)
    }
  },
}