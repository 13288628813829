import _ from "lodash";
import store from "./store";
import parsePhoneNumber from 'libphonenumber-js'
import { TokenService } from "./services/storage.service";
import { flagsCountryCode , CountryName2 } from "./utils/flags";
import moment from "moment-timezone";
import prettyBytes from 'pretty-bytes';
import { GET_LOCAL_SETTING_NUMBERS } from "./store/helper/getters";

export function time(milliseconds) {
  return moment(milliseconds).format("hh:mm a");
}
export function date(milliseconds) {
  return moment(milliseconds)?.format?.('ddd MMM DD YYYY') ?? '';
}
export function isEmpty(value) {
  return _.isEmpty(value);
}
export function searchUsers(search) {
  const users = store.state.common.voipusers;
  if (!search) return users;
  var te = Object.values(users);
  var searchRegex = new RegExp(search.toLowerCase().trim(), "i");
  return te.filter(
    (user) =>
      searchRegex.test(user.extn) || searchRegex.test(user.user.display_name) || searchRegex.test(user.account)
  );
}
export function time_audio(value,min=false) {
  const seconds = parseInt(value)
  if(isNaN(seconds)) return value
  else if(min) return moment.utc(seconds*1000)?.format?.('mm:ss') ?? '00:00'
  else return moment.utc(seconds*1000)?.format?.('HH:mm:ss') ?? '00:00:00'
  // if(seconds<=0) return '00:00'
  // let sec = seconds % 60;
  // let mins = Math.floor(seconds / 60);
  // sec = !sec ? `00` : sec < 10 ? `0${sec}` : sec;
  // mins = !mins ? `00` : mins < 10 ? `0${mins}` : mins;
  // return `${mins}:${sec}`;
}
export function date_current(milliseconds, sidebar) {
  var value = milliseconds;
  if (value) {
    const current_date = moment();
    const message_date = moment(value);
    const duration = moment.duration({ from: message_date, to: current_date })._data.days;
    const days = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    if (current_date.date() === message_date.date()) {
      if (sidebar) {
        return message_date.format("hh:mm a");
      } else {
        return "Today";
      }
    } else if (current_date.date() - 1 === message_date.date()) {
      return "Yesterday";
    } else if (duration <= 7) {
      return days[message_date.day()];
    } else {
      return message_date.format("DD/MM/YYYY");
    }
  } else {
    return "";
  }
}
export function filter_date_current(value,formate,options){
  let moment_instance;
  if(options?.unix) {
    moment_instance = moment.unix(value)
  } else if(options?.utc){
    moment_instance = moment.utc(value)
  } else if(formate){
    moment_instance = moment(value,formate)
  } else if(options?.seconds){
    moment_instance = moment.utc(value*1000)
  } else {
    moment_instance = moment(value)
  }
  if(moment_instance=='Invalid date' || !moment_instance._isValid) return ''
  let date; 
  if(options?.formate_now){
    date = moment_instance.fromNow()
  } else if(options?.formate){
    date = moment_instance.format(options?.formate)
  } else if(options?.is_same){
    let other_moment = options?.other_date
    if(options?.other_date_unix) other_moment = moment.unix(options?.other_date)
    date = moment_instance.isSame(other_moment,options?.type) ? undefined : value;
  } else {
    date = moment_instance.calendar(null, {
      sameDay: options?.sameDay || '[Today]',
      nextDay: options?.nextDay || '[Tomorrow]',
      nextWeek: options?.nextWeek || 'dddd',
      lastDay: options?.lastDay || '[Yesterday]',
      lastWeek: options?.lastWeek || '[Last] dddd',
      sameElse: options?.sameElse || 'DD MMM YYYY'
    })
  }
  return date
}
export function first_Character(value) {
  if (!value) return "";
  return value.charAt(0);
}
export function get_property(value,attributes='',defaultValue=''){ 
  return _.get(value,attributes) ?? defaultValue 
}
export function check_empty(value,defaultValue=''){ 
  return value || defaultValue 
}
export function to_fixed(value,to_fixed=2){ 
  return parseFloat(value).toFixed(to_fixed)
}
export function base64_to_url(value) {
  if (!value) return "-";
  const base64 = window.atob(value);
  const arrayBuffer = new ArrayBuffer(base64.length);
  const typedArray = new Uint8Array(arrayBuffer);
  for (let i = 0; i < base64.length; i++) {
    typedArray[i] = base64.charCodeAt(i);
  }
  return window.URL.createObjectURL(
    new Blob([arrayBuffer], {
      type: "audio/mp3",
    })
  );
}
export function show_positive_value(value,dv=0){
  const v = parseInt(value)
  return v < 1 ? dv : v ? v : dv
}
export function showTime(value, dv='-'){
  if (!value) return dv;
  return moment(value).format("h:mm a");
}

export function welcomgreetingsrc(menu){
  return menu?.ivrWelcomeGreetingFile ?? ''
  // const base_url = menu?.base_url
  // let url = menu?.schedules?.[0]?.argument?.[0]?.ivr_list?.[0]?.ivr_application_args
  // if(url) url = url?.includes?.('defaults') ? 'storage/defaults/mp3/ivrs' : `storage/audio/recordings/${url.replace('ivr/','ivr/mp3/')}`
  // return base_url && url ? `${base_url}/${url}.mp3` : "";
}

export function duration_format(value,options={}) {
  const new_value = parseInt(value)
  if (!new_value) return options?.default || `00:00:00`;
  return moment.duration(value, options?.unit || "seconds").format(options?.format || "hh:mm:ss", {
    trim: options?.trim ?? false
  });
}

export function validNumberChecker(number,country='',other=true){
  if (!number || !['string','number'].includes(typeof number)) return null;
  const user = TokenService.USER.get()
  const phoneNumber = parsePhoneNumber(`${number}`, country || user?.country || '')
  const phoneNumberGB = parsePhoneNumber(`${number}`, 'GB')
  const phoneNumberUS = parsePhoneNumber(`${number}`, 'US')
  const phoneNumberAU = parsePhoneNumber(`${number}`, 'AU')
  const phoneNumberNZ = parsePhoneNumber(`${number}`, 'NZ')
  const phoneNumberCA = parsePhoneNumber(`${number}`, 'CA')
  const phoneNumberWithPlus = parsePhoneNumber(`+${number}`)
  if (other && phoneNumberGB?.isValid?.()) return phoneNumberGB
  else if (other && phoneNumberUS?.isValid?.()) return phoneNumberUS
  else if (other && phoneNumberAU?.isValid?.()) return phoneNumberAU
  else if (other && phoneNumberNZ?.isValid?.()) return phoneNumberNZ
  else if (other && phoneNumberCA?.isValid?.()) return phoneNumberCA
  else if (phoneNumberWithPlus?.isValid?.()) return phoneNumberWithPlus
  else if (phoneNumber?.isValid?.()) return phoneNumber
  else return null;
}

export function number_formater(number, country='', international = false){
  const user = TokenService.USER.get()
  const phoneNumber = validNumberChecker(number, country || user?.country)
  // if(!phoneNumber){
  //   phoneNumber = validNumberChecker(`+${number}`, country || user?.country)
  // }
  const numbers = store.getters[GET_LOCAL_SETTING_NUMBERS]
  if(international){
    return phoneNumber?.format?.("INTERNATIONAL") ?? number
  }
  if(phoneNumber?.country==user?.country && !numbers?.formating?.international_formate) {
    return phoneNumber?.format?.("NATIONAL") ?? number
  } else {
    return phoneNumber?.format?.("INTERNATIONAL") ?? number
  }
}

export function flag_icon(number,country=''){
  const phoneNumber = validNumberChecker(number, country)
  return flagsCountryCode[phoneNumber?.country ?? 'UN']
}
// added by Frank
export function getCountryName(number,country=''){
  const phoneNumber = validNumberChecker(number, country)
  // console.log(phoneNumber)
  // return flagsCountryCode[phoneNumber?.country ?? 'UN']
  return phoneNumber?.country
}

export function getFullCountryName(number,country=''){
  const phoneNumber = validNumberChecker(number, country, false)
  return CountryName2[phoneNumber?.country ?? 'UN']
}

export function getFullCountryCode(number,country=''){
  const phoneNumber = validNumberChecker(number, country, false)
  return phoneNumber?.country ?? ''
}

export function formatBytes(bytes) { // , decimals = 2, unit='ies'
  if (!+bytes) return '0 Bytes'
  return prettyBytes(bytes)
  // const k = unit=='ies' ? Math.pow(2,10) : Math.pow(10,3)
  // const dm = decimals < 0 ? 0 : decimals
  // const sizes = ['Bytes', 'KB', 'MB', 'GB', 'TB', 'PB', 'EB', 'ZB', 'YB']
  // const i = Math.floor(Math.log(bytes) / Math.log(k))
  // return `${parseFloat((bytes / Math.pow(k, i)).toFixed(dm))} ${sizes[i]}`
}

// SECTION - CRM
export function crmFilterActivityTypeIcon(icon) {
  switch (icon) {
    case 'Mail':
      return 'squared-mail-icon'
    case 'PencilAlt':
      return 'squared-call-icon'
    case 'OfficeBuilding':
      return 'squared-call-icon'
    case 'Phone':
      return 'squared-call-icon'
    case 'Calendar':
      return 'squared-call-icon'
    case 'Collection':
      return 'squared-call-icon'
    case 'Bell':
      return 'squared-call-icon'
    case 'AtSymbol':
      return 'squared-call-icon'
    case 'Briefcase':
      return 'squared-call-icon'
    case 'Chat':
      return 'squared-call-icon'
    case 'CheckCircle':
      return 'squared-done-icon'
    case 'BookOpen':
      return 'squared-call-icon'
    case 'Camera':
      return 'squared-call-icon'
    case 'Truck':
      return 'squared-call-icon'
    case 'Folder':
      return 'squared-call-icon'
    case 'DeviceMobile':
      return 'squared-call-icon'
    case 'Users':
      return 'squared-multipleUsers-icon'
    case 'CreditCard':
      return 'squared-call-icon'
    case 'Clock':
      return 'squared-clock-icon'
    case 'ShieldExclamation':
      return 'squared-call-icon'
    case 'WrenchScrewdriver':
      return 'squared-call-icon'
    default:
      return ''
  }
}