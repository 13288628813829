<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-900" 
      width="50%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <section class="dialer-settings-section pickupGroups">
        <div class="dialer-flex">
          <h2 class="dialer-settings-title">All extensions</h2>
          <a class="newDoneButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </a>
        </div>
        <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom">
          <span>Manage all your extensions.</span>
          <div class="d-flex justify-content-end align-items-center w-50">
            <div class="whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
              <b-input type="text" placeholder="Search extensions" v-model.lazy="filter.extensions.search" />
            </div>
          </div>
        </div>
        <vb-table 
          class="table2 latestTableDesign-withBlackBorders-again" 
          :isListEmpty="filterExtensions.length==0" 
          :listLength="filterExtensions.length" 
          :loading="api.fetch_extensions.send">
          <template slot="header">
            <tr class="cursor_auto">
              <th class="dialer-has-sort cursor_auto">
                <span class="dialer-sorted-by ascending dialer-has-sort">Label</span>
              </th>
              <th class="dialer-has-sort cursor_auto">
                <span class="dialer-has-sort">Type</span>
              </th>
            </tr>
          </template>
          <template #body="{ start, end }">
            <tr 
              class="dialer-row-select" 
              v-for="(extension, index) in filterExtensions" :key="index" v-show="index >= start && index <= end" 
              @click="$modal.show('EditExtensionModal',{
                data: extension
              })"
            >
              <td class="dialer-row-title"> 
                <!-- <span class="extension_tag">{{ extension.extn }}</span>{{ extension | filterLabel }} -->
                <info :id="extension.voipaccount" :name="extension | filterLabel" :sub_info="extension.extn" :is_blf="false" />
              </td>
              <td class="dialer-col-left">{{ extension.type }}</td>
            </tr>
          </template>
          <template #loading>
            <tr v-for="n in 5" :key="n">
              <td>
                <div class="d-flex align-items-center w-fit-content">
                  <div class="latestShimmerDesign mr-2" style="height:31px;width:55px;"></div>
                  <div class="latestShimmerDesign" style="height:21px;width:180px;"></div>
                </div>
              </td>
              <td>
                <div class="d-flex align-items-center w-fit-content">
                  <div class="latestShimmerDesign mr-2" style="height:21px;width:180px;"></div>
                </div>
              </td>
            </tr>
          </template>
        </vb-table>
      </section>
    </modal>
    <EditExtensionModal @interface="fetchExtensions()" />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import EditExtensionModal from './EditExtensionModal.vue'
import Info from '../Lists/Info.vue';
export default {
  name: 'AllExtensionsModal',
  components: {
    EditExtensionModal,
    Info
  },
  props: {
    modalName: {
      type: String,
      default: 'AllExtensions',
    },
  },
  data() {
    return {
      response: {
        extensions: [],
      },
      api: {
        fetch_extensions: this.$helperFunction.apiInstance({}),
      },
      filter: {
        extensions: {
          search: '',
        },
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    filterExtensions(){
      const search = this.filter.extensions.search.trim().toLowerCase()
      return this.response.extensions.filter(item=>`${item.extn} ${item.type} ${item.label} ${item.voipuser ? item.voipuser.display_name : ''}`.trim().toLowerCase().includes(search) && item.type=='User')
    },
  },
  filters: {
    filterLabel(value) {
      if (!value) return "-";
      if(value.voipuser){
        return value.voipuser.display_name;
      }
      return value.label;
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchExtensions()
    },
    onBeforeClose(){
      //TODO
    },
    async fetchExtensions() {
      if(this.api.fetch_extensions.send) return;
      try {
        this.api.fetch_extensions.send=true
        const { data: { data: extensions } } = await VOIP_API.endpoints.extensions.getallextensions()
        this.response.extensions=extensions
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_extensions.send=false
      }
    },
  },
}
</script>

<style>

</style>