<template>
  <div>
    <div class="mainHeading withButton">
      Org chart
      <button @click="$modal.show('ExportOrgChartModal')" class="dialer-button dialer-button-primary mr-0 withIcon">
        <!-- <vb-icon icon="pencil-hrmIcon" class="mr-16px fillWhite" height="14px" width="14px" /> -->
        <!-- <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" /> -->
        Export
      </button>
    </div>
    <div class="mainDescription mb-4">
      Org chart is automatically build based on who is reporting to whom. Here is a list of all employees & 
      their direct managers
    </div>
    <div class="dropdownsContainer">
      <b-dropdown no-caret class="primaryBorderDropdown w-220px">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All teams</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All teams
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown w-220px">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All locations</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All locations
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="documentsModule-Table mt-32px">
      <vb-table class="HrmTable">
        <template slot="header">
          <tr>
            <th class="HrmTableHead text-left one pt-1">Employee</th>
            <th class="HrmTableHead text-left two pt-1">direct manager</th>
            <th class="HrmTableHead three pt-1">Actions</th>
          </tr>
        </template>
        <template #body>
          <tr v-for="n in 6" :key="n" class="dialer-row-select" >
            <td class="dialer-row-title one">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">Marie jane</div>
              </div>
            </td>
            <td class="dialer-col-right text-left two">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">Marie jane</div>
              </div>
            </td>
            <td class="dialer-col-right three">
              <b-dropdown no-caret right class="ActionsDD">
                <template #button-content>
                  <div class="button-content">
                    <b-icon class="downIcon" icon="three-dots-vertical" />
                  </div>
                </template>
                <b-dropdown-item >
                  Actions
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    
    
    <ExportOrgChartModal />
    <!-- <AddPaymentType /> -->
  </div>
</template>

<script>
import ExportOrgChartModal from '@/components/Modals/HRM/settings/EmployeeData/ExportOrgChartModal.vue';
// import AddPaymentType from '@/components/Modals/HRM/settings/EmployeeData/AddPaymentType.vue';
export default {
  name: 'EmployeeDataOrgChart',
  components: {
    ExportOrgChartModal,
    // AddPaymentType,
  },
}
</script>

<style>

</style>