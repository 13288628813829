var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('TopBar', {
    attrs: {
      "title": 'Deals'
    }
  }, [_c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CRMCreateDeal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [_vm._v(" Create Deal ")])], 1)]), _c('div', {
    staticClass: "crm-main-section-body"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('DealsByStageGraphWidget'), _c('WonDealsByDayGraphWidget')], 1), _c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center mb-3"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_vm.selectedDealFilter ? [_vm._v(" " + _vm._s(_vm.selectedDealFilter.text) + " ")] : [_c('vb-icon', {
          staticClass: "crm-filterOptions-icon",
          attrs: {
            "icon": "crm-filterOptions-icon",
            "height": "16px",
            "width": "16px"
          }
        }), _c('span', [_vm._v("Filter")])], _c('b-icon', {
          staticClass: "ml-2",
          attrs: {
            "icon": "chevron-down"
          }
        })], 2)];
      },
      proxy: true
    }])
  }, _vm._l(_vm.dealFilters, function (filter) {
    return _c('b-dropdown-item', {
      key: filter.value,
      on: {
        "click": function ($event) {
          return _vm.selectFilter(filter.value);
        }
      }
    }, [_vm.filters.deals.filter_type == filter.value ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('span', [_vm._v(" " + _vm._s(filter.text) + " ")])], 1);
  }), 1)], 1), _c('button', {
    staticClass: "newButton onlyIcon ml-16px",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CRMCreateDeal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-pencil-icon",
      "height": "38px",
      "width": "38px"
    }
  })], 1)]), _c('div', {
    staticClass: "totalsTextOnMain"
  }, [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.response.summery, 'value', 0)) + " - " + _vm._s(_vm._f("get_property")(_vm.response.summery, 'count', 0)) + " deals ")])]), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.deals.search,
      expression: "filters.deals.search"
    }],
    attrs: {
      "placeholder": "Search"
    },
    domProps: {
      "value": _vm.filters.deals.search
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.deals, "search", $event.target.value);
      }, function ($event) {
        _vm.paginations.deals.page = 1;
        _vm.fetchDealsDebounce();
      }]
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  })], 1), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "isListEmpty": _vm.response.deals.length == 0,
      "listLength": _vm.paginations.deals.total,
      "textNoRecord": "There is no record",
      "perPage": _vm.paginations.deals.per_page,
      "page": _vm.paginations.deals.page,
      "loading": _vm.api.fetch_deals.send
    },
    on: {
      "page-changed": function ($event) {
        _vm.paginations.deals.page = $event;
        _vm.fetchDeals();
      },
      "per-page-changed": function ($event) {
        _vm.paginations.deals.per_page = $event;
        _vm.paginations.deals.page = 1;
        _vm.fetchDeals();
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.response.deals, function (data) {
          return _c('tr', {
            key: data.id
          }, [_c('td', [_c('b-form-checkbox', {
            staticClass: "crm-checkbox",
            attrs: {
              "checked": !!_vm.selected.deals[data.id]
            },
            on: {
              "change": function ($event) {
                $event ? _vm.$set(_vm.selected.deals, data.id, data) : _vm.$delete(_vm.selected.deals, data.id);
              }
            }
          })], 1), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(data, 'name')))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'stage.name')))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'amount')))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm._f("get_property")(data, 'expected_close_date'))))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', {
            staticClass: "tagTypeDesign"
          }, [_vm._v(_vm._s(data.status))])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm._f("get_property")(data, 'created_at'))))])]);
        });
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', [_c('b-form-checkbox', {
    staticClass: "crm-checkbox",
    model: {
      value: _vm.allSelected,
      callback: function ($$v) {
        _vm.allSelected = $$v;
      },
      expression: "allSelected"
    }
  })], 1), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending",
    on: {
      "click": function ($event) {
        return _vm.changeOrder('name');
      }
    }
  }, [_vm._v(" Deal name "), _vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'name';
  }) ? [_vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'name';
  }).direction == 'asc' ? _c('b-icon', {
    attrs: {
      "icon": "caret-up-fill"
    }
  }) : _c('b-icon', {
    attrs: {
      "icon": "caret-down-fill"
    }
  })] : _vm._e()], 2)]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.changeOrder('stage');
      }
    }
  }, [_vm._v(" stage "), _vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'stage';
  }) ? [_vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'stage';
  }).direction == 'asc' ? _c('b-icon', {
    attrs: {
      "icon": "caret-up-fill"
    }
  }) : _c('b-icon', {
    attrs: {
      "icon": "caret-down-fill"
    }
  })] : _vm._e()], 2)]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.changeOrder('amount');
      }
    }
  }, [_vm._v(" amount "), _vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'amount';
  }) ? [_vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'amount';
  }).direction == 'asc' ? _c('b-icon', {
    attrs: {
      "icon": "caret-up-fill"
    }
  }) : _c('b-icon', {
    attrs: {
      "icon": "caret-down-fill"
    }
  })] : _vm._e()], 2)]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.changeOrder('amount');
      }
    }
  }, [_vm._v(" expected close date "), _vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'expected_close_date';
  }) ? [_vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'expected_close_date';
  }).direction == 'asc' ? _c('b-icon', {
    attrs: {
      "icon": "caret-up-fill"
    }
  }) : _c('b-icon', {
    attrs: {
      "icon": "caret-down-fill"
    }
  })] : _vm._e()], 2)]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.changeOrder('status');
      }
    }
  }, [_vm._v(" status "), _vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'status';
  }) ? [_vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'status';
  }).direction == 'asc' ? _c('b-icon', {
    attrs: {
      "icon": "caret-up-fill"
    }
  }) : _c('b-icon', {
    attrs: {
      "icon": "caret-down-fill"
    }
  })] : _vm._e()], 2)]), _c('th', {
    staticClass: "dialer-col-right cursor_pointer"
  }, [_c('span', {
    on: {
      "click": function ($event) {
        return _vm.changeOrder('created_at');
      }
    }
  }, [_vm._v(" CREATED AT "), _vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'created_at';
  }) ? [_vm.filters.deals.orders.find(function (i) {
    return i.attribute == 'created_at';
  }).direction == 'asc' ? _c('b-icon', {
    attrs: {
      "icon": "caret-up-fill"
    }
  }) : _c('b-icon', {
    attrs: {
      "icon": "caret-down-fill"
    }
  })] : _vm._e()], 2)])])])], 2)], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }