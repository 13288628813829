var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-tab', {
    on: {
      "click": _vm.onTab
    },
    scopedSlots: _vm._u([{
      key: "title",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center unreadTextCountBadge-container"
        }, [_c('div', {
          staticClass: "mr-8px"
        }, [_vm._v(_vm._s(_vm.text))]), _vm.totalUnreadTitle > 0 ? _c('div', {
          class: ['unreadTextCountBadge', _vm.totalUnreadTitle < 10 ? 'single' : _vm.totalUnreadTitle <= 99 ? 'double' : _vm.totalUnreadTitle > 99 ? 'triple' : '']
        }, [_vm._v(_vm._s(_vm.totalUnreadTitle > 99 ? '+99' : _vm.totalUnreadTitle))]) : _vm._e()])];
      },
      proxy: true
    }])
  }, [_vm.loading ? _c('vb-loading') : _c('div', {
    staticClass: "allow_scroll position-relative"
  }, [!_vm.isEmpty(_vm.recent_users) || !_vm.isEmpty(_vm.recent_channels) || !_vm.isEmpty(_vm.recent_teams) ? [_c('div', {
    staticClass: "dialer-dropdown position-absolute right-15 -top-15"
  }, [_c('b-dropdown', {
    ref: "dropdown",
    staticClass: "m-2 top-12",
    attrs: {
      "id": "dropdown-form",
      "text": "appearance",
      "menu-class": "latestDesignMenuClass"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "mr-2"
        }, [_vm._v("Views")]), _c('b-icon', {
          attrs: {
            "icon": "filter",
            "scale": "1"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Views")])];
      },
      proxy: true
    }], null, false, 792279742)
  }, [_c('b-dropdown-form', [_c('b-form-radio-group', {
    staticClass: "p-2",
    attrs: {
      "value": _vm.GET_LOCAL_SETTING_CHAT.view.recent_type,
      "name": "radio-sub-component"
    },
    on: {
      "change": function ($event) {
        return _vm.SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE($event);
      }
    }
  }, [_c('b-form-radio', {
    staticClass: "radio_btn_like_checkbox makeCheckBoxesSquare",
    attrs: {
      "value": "separate"
    }
  }, [_vm._v("Separate")]), _c('b-form-radio', {
    staticClass: "radio_btn_like_checkbox makeCheckBoxesSquare",
    attrs: {
      "value": "mixed"
    }
  }, [_vm._v("Mixed")])], 1)], 1)], 1)], 1)] : _vm._e(), !_vm.isEmpty(_vm.fav) ? [_c('strong', {
    staticClass: "filter-title d-flex justify-content-between align-items-center"
  }, [_c('span', {
    staticClass: "title d-flex align-items-center"
  }, [_vm._v(" Favorites "), _c('b-icon', {
    staticClass: "expand-icon ml-2",
    attrs: {
      "scale": "0.8",
      "icon": "chevron-down",
      "rotate": _vm.is_show_fav ? '180' : ''
    },
    on: {
      "click": function ($event) {
        _vm.is_show_fav = !_vm.is_show_fav;
      }
    }
  })], 1)]), _c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.is_show_fav,
      expression: "is_show_fav"
    }],
    staticClass: "contact-list favorites list-unstyled m-0"
  }, [_vm._t("fav-items")], 2)])] : _vm._e(), _vm.nofav ? [_c('strong', {
    staticClass: "filter-title d-flex justify-content-between align-items-center"
  }, [_c('span', {
    staticClass: "title d-flex align-items-center"
  }, [_vm._v(" " + _vm._s(_vm.text) + " "), !_vm.isEmpty(_vm.nofav) ? _c('b-icon', {
    staticClass: "expand-icon ml-2",
    attrs: {
      "icon": "chevron-down",
      "rotate": _vm.is_show_not_fav ? '180' : '',
      "scale": "0.8"
    },
    on: {
      "click": function ($event) {
        _vm.is_show_not_fav = !_vm.is_show_not_fav;
      }
    }
  }) : _vm._e()], 1), _vm.isShowAdd ? _c('a', {
    on: {
      "click": _vm.onAdd
    }
  }, [_c('b-icon', {
    staticClass: "border border-primary rounded mr-3",
    attrs: {
      "icon": "plus",
      "font-scale": "1.1",
      "variant": "primary"
    }
  })], 1) : _vm._e()]), _c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.is_show_not_fav,
      expression: "is_show_not_fav"
    }]
  }, [_vm.isEmpty(_vm.nofav) ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no content',
      "design": 3
    }
  }) : _c('ul', {
    staticClass: "contact-list nofav list-unstyled m-0"
  }, [_vm._t("not-fav-items")], 2)], 1)])] : _vm._e(), !_vm.isEmpty(_vm.all_recent) ? [_c('strong', {
    staticClass: "filter-title d-flex justify-content-between align-items-center"
  }, [_c('span', {
    staticClass: "title d-flex align-items-center"
  }, [_vm._v(" All "), _c('b-icon', {
    staticClass: "expand-icon ml-2",
    attrs: {
      "scale": "0.8",
      "icon": "chevron-down",
      "rotate": _vm.is_show.allRecent ? '180' : ''
    },
    on: {
      "click": function ($event) {
        _vm.is_show.allRecent = !_vm.is_show.allRecent;
      }
    }
  })], 1)]), _c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.is_show.allRecent,
      expression: "is_show.allRecent"
    }],
    staticClass: "contact-list recent_all list-unstyled m-0"
  }, [_vm._t("recent-all-items")], 2)])] : _vm._e(), !_vm.isEmpty(_vm.recent_users) && _vm.GET_LOCAL_SETTING_CHAT.view.recent_type === 'separate' ? [_c('strong', {
    staticClass: "filter-title d-flex justify-content-between align-items-center"
  }, [_c('span', {
    staticClass: "title d-flex align-items-center"
  }, [_vm._v(" Users "), _c('b-icon', {
    staticClass: "expand-icon ml-2",
    attrs: {
      "scale": "0.8",
      "icon": "chevron-down",
      "rotate": _vm.is_show.users ? '180' : ''
    },
    on: {
      "click": function ($event) {
        _vm.is_show.users = !_vm.is_show.users;
      }
    }
  })], 1)]), _c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.is_show.users,
      expression: "is_show.users"
    }],
    staticClass: "contact-list users list-unstyled m-0"
  }, [_vm._t("recent-user-items")], 2)])] : _vm._e(), !_vm.isEmpty(_vm.recent_channels) && _vm.GET_LOCAL_SETTING_CHAT.view.recent_type === 'separate' ? [_c('strong', {
    staticClass: "filter-title d-flex justify-content-between align-items-center"
  }, [_c('span', {
    staticClass: "title d-flex align-items-center"
  }, [_vm._v(" Groups "), _c('b-icon', {
    staticClass: "expand-icon ml-2",
    attrs: {
      "scale": "0.8",
      "icon": "chevron-down",
      "rotate": _vm.is_show.channels ? '180' : ''
    },
    on: {
      "click": function ($event) {
        _vm.is_show.channels = !_vm.is_show.channels;
      }
    }
  })], 1)]), _c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.is_show.channels,
      expression: "is_show.channels"
    }],
    staticClass: "contact-list list-unstyled channels m-0"
  }, [_vm._t("recent-channel-items")], 2)])] : _vm._e(), !_vm.isEmpty(_vm.recent_teams) && _vm.GET_LOCAL_SETTING_CHAT.view.recent_type === 'separate' ? [_c('strong', {
    staticClass: "filter-title d-flex justify-content-between align-items-center"
  }, [_c('span', {
    staticClass: "title d-flex align-items-center"
  }, [_vm._v(" Teams "), _c('b-icon', {
    staticClass: "expand-icon ml-2",
    attrs: {
      "scale": "0.8",
      "icon": "chevron-down",
      "rotate": _vm.is_show.teams ? '180' : ''
    },
    on: {
      "click": function ($event) {
        _vm.is_show.teams = !_vm.is_show.teams;
      }
    }
  })], 1)]), _c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_c('ul', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.is_show.teams,
      expression: "is_show.teams"
    }],
    staticClass: "contact-list teams list-unstyled m-0"
  }, [_vm._t("recent-team-items")], 2)])] : _vm._e()], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }