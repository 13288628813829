<template>
  <div>
    <div class="basicWhiteIOScard-item noBottomBorder" @click="checkPermission(getUserPermissions.out_of_hours,()=>$modal.show(`${randomID}-OutOfHour`,{ account: accountcode, }))">
      
      <div class="headingInsideCard">Business hours</div>
      <div class="d-flex align-items-center">
        <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.out_of_hours ? 'On' : 'Off' }}</div>
        <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
      </div>
    </div>
    <OutOfHourModal :modalName="`${randomID}-OutOfHour`" :randomID="randomID" @ofh-toggle-updated="fetchcallRouteSetting()" />
  </div>
</template>

<script>
import { permission_handling } from '@/mixin'
import { mapGetters } from 'vuex'
import OutOfHourModal from '../modals/outofhours/OutOfHour.vue'
import { $fn, VOIP_API } from '@/utils'
// import { Fragment } from 'vue-fragment'
export default {
  name: 'OutOfHoursWidget',
  mixins: [permission_handling],
  components: {
    // Fragment,
    OutOfHourModal,
  },
  props: {
    accountcode: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      random_id: '',
      api: {
        route_call: this.$helperFunction.apiInstance(),
      },
      response: {
        out_of_hours: false,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions'
    ]),
    randomID(){ return this.random_id },
  },
  methods: {
    fetchcallRouteSetting() {
      let vm = this
      if(vm.api.route_call.send) return;
      vm.api.route_call.send = true;
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.accountcode,
      })
      .then(({ data }) => {
        vm.response.out_of_hours = !!data.schedule_enable;
      })
      .finally(() => {
        vm.api.route_call.send = false;
      });
    },
  },
  created(){
    this.random_id = $fn.makeid(10)
  },
  mounted(){
    this.fetchcallRouteSetting()
  },
  activated(){
    this.fetchcallRouteSetting()
  },
}
</script>

<style>

</style>