<template>
  <modal class="dialer_animation right_side_popup developerOptionsModal" :name="modalName" @before-open="onBeforeOpen($event)" @before-close="onBeforeClose()">
    <div class="dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between">
      <h2 class="dialer-edit-title newer mb-0">Features</h2>
      <div class="dialer-edit-actions d-flex justify-content-end">
        <button class="newCloseButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    <div style="max-height: calc(100vh - 120px);overflow-y: auto;height: calc(100vh - 120px);">
      <Something3 />
    </div>
  </modal>
</template>

<script>
import Something3 from '../Analytics/Something3.vue'
export default {
  name: 'DeveloperAnalyticsReportingModal',
  components: { 
    Something3 
  },
  props: {
    modalName: {
      type: String,
      default: 'DeveloperAnalyticsReporting',
    },
  },
  data(){
    return {
      
    }
  },
  methods: {
    onBeforeOpen(){
      // TODO - 
    },
    onBeforeClose(){
      // TODO - 
    },
  },
}
</script>

<style>

</style>