import { LOGGER } from './console'
import { permissions } from './permissions'
const Emitter = require('component-emitter')
const events = {
  devices_change: 'devices_change',
  videoinput_change: 'videoinput_change',
  audioinput_change: 'audioinput_change',
  audiooutput_change: 'audiooutput_change',
  camera_id_change: 'camera_id_change',
  mic_id_change: 'mic_id_change',
  speaker_id_change: 'speaker_id_change',
  ringing_speaker_id_change: 'ringing_speaker_id_change',
  speaker_volume_change: 'speaker_volume_change',
  ringing_speaker_volume_change: 'ringing_speaker_volume_change',
}
export const devices = {
  list: [],
  camera_id: '',
  speaker_id: '',
  ringing_speaker_id: '',
  mic_id: '',
  speaker_volume: 100,
  ringing_speaker_volume: 100,
  
  set cameraId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(!this.videoinputs.map(item=>item.deviceId).includes(value)) throw `id not exist in list ${value} cameraId`
    this.camera_id=value
    LOGGER.log('cameraId',this.camera_id)
    this.emit(this.events.camera_id_change,this.camera_id);
  },
  set micId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(!this.audioinputs.map(item=>item.deviceId).includes(value)) throw `id not exist in list ${value} micId`
    this.mic_id=value
    LOGGER.log('micId',this.mic_id)
    this.emit(this.events.mic_id_change,this.mic_id);
  },
  set speakerId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(!this.audiooutputs.map(item=>item.deviceId).includes(value)) throw `id not exist in list ${value} speakerId`
    this.speaker_id=value
    LOGGER.log('speakerId',this.speaker_id)
    this.emit(this.events.speaker_id_change,this.speaker_id);
  },
  set ringingSpeakerId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(!this.audiooutputs.map(item=>item.deviceId).includes(value)) throw `id not exist in list ${value} ringingSpeakerId`
    this.ringing_speaker_id=value
    LOGGER.log('ringingSpeakerId',this.ringing_speaker_id)
    this.emit(this.events.ringing_speaker_id_change,this.ringing_speaker_id);
  },
  set speakerVolume(value){
    if(typeof value != 'number') throw 'value must be number'
    if(value<=100 || value>=0) throw 'value must be between 0 to 100'
    this.speaker_volume=value
    LOGGER.log('speakerVolume',this.speaker_volume)
    this.emit(this.events.speaker_volume_change,this.speaker_volume);
  },
  set ringingSpeakerVolume(value){
    if(typeof value != 'number') throw 'value must be number'
    if(value<=100 || value>=0) throw 'value must be between 0 to 100'
    this.ringing_speaker_volume=value
    LOGGER.log('ringingSpeakerVolume',this.ringing_speaker_volume)
    this.emit(this.events.ringing_speaker_volume_change,this.ringing_speaker_volume);
  },
  
  get videoinputs(){ return this.list.filter((device) => device.kind === "videoinput") },
  get audioinputs(){ return this.list.filter((device) => device.kind === "audioinput"); },
  get audiooutputs(){ return this.list.filter((device) => device.kind === "audiooutput"); },

  get selectedCameraDevice(){ return this.videoinputs.find(item=>item.deviceId==this.camera_id) },
  get selectedSpeakerDevice(){ return this.audiooutputs.find(item=>item.deviceId==this.speaker_id) },
  get selectedRingingSpeakerDevice(){ return this.audiooutputs.find(item=>item.deviceId==this.ringing_speaker_id) },
  get selectedMicDevice(){ return this.audiooutputs.find(item=>item.deviceId==this.mic_id) },
  
  get events(){ return events },
  
  init(){
    try {
      Emitter(this)
      if(navigator?.mediaDevices?.ondevicechange !== undefined){
        navigator.mediaDevices.ondevicechange = this.devicesChanged.bind(this)
      }
      this.devicesChanged()
      permissions.on(permissions.events.change_camera_permission,this.devicesChanged.bind(this))
      permissions.on(permissions.events.change_mic_permission,this.devicesChanged.bind(this))
      if(process.env.NODE_ENV!='production'){ window.devices = this }
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  devicesChanged(){
    let that = this
    navigator?.mediaDevices?.enumerateDevices?.().then((devices)=>{
      const videoinputs = that.list.filter((device) => device.kind === "videoinput");
      const audioinputs = that.list.filter((device) => device.kind === "audioinput");
      const audiooutputs = that.list.filter((device) => device.kind === "audiooutput");
      if(devices.length!=that.list.length){ that.emit(that.events.devices_change,that.list); }
      that.list=devices
      if(videoinputs.length!=that.videoinputs.length){ that.emit(that.events.videoinput_change,that.videoinputs); }
      if(audioinputs.length!=that.audioinputs.length){ that.emit(that.events.audioinput_change,that.audioinputs); }
      if(audiooutputs.length!=that.audiooutputs.length){ that.emit(that.events.audiooutput_change,that.audiooutputs); }
      if(!that.selectedCameraDevice){ that.cameraId = that.videoinputs?.[0]?.deviceId ?? ''; }
      if(!that.selectedMicDevice){ that.micId = that.audioinputs?.[0]?.deviceId ?? ''; }
      if(!that.selectedSpeakerDevice){ that.speakerId = that.audiooutputs?.[0]?.deviceId ?? ''; }
      if(!that.selectedRingingSpeakerDevice){ that.ringingSpeakerId = that.audiooutputs?.[0]?.deviceId ?? ''; }
    }).catch((ex)=>{
      LOGGER.danger('devicesChanged',{ex})
    })
  }
}