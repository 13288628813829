<template>
  <modal
    class="dialer_animation right_side_popup m-w-500 writeNoteModal"
    name="WriteCallNoteModal"
    @closed="onClosed"
    @before-open="beforeOpen"
  >
    <section class="ct-user-new dialer-edit">
      <form class="dialer-form lg-mar-bottom" @submit.prevent="sendNote">
        <div class="dialer-edit-header">
          <div>
            <h2 class="dialer-edit-title">Write note</h2>
          </div>
          <div class="dialer-edit-actions">
            <a class="dialer-button dialer-button-delete" @click="$modal.hide('WriteCallNoteModal')">Cancel</a>
            <button type="submit" class="newDoneButton d-inline-flex justify-content-center" :disabled="api_sent.note">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                <vb-spinner class="sm-mar-right" v-if="api_sent.note" />
                <template v-else>Done</template>
              </span>
            </button>
          </div>
        </div>
        <div class="md-mar-vertical">
          <div class="dialer-team-name mb-4">
            <div class="dialer-input-field">
              <label for="">Write some note for this call</label>
              <textarea placeholder="Enter note" v-model="form.note" class="w-100" :disabled="api_sent.note" />
            </div>
          </div>
        </div>
      </form>
    </section>
  </modal>
</template>

<script>
import { LOGGER, VOIP_API } from "../../utils";
import _ from 'lodash'
export default {
  name: "WriteCallNoteModal",
  data() {
    return {
      call_id: "",
      form: {
        note: "",
      },
      api_sent: {
        note: false,
      },
      assets: {
        spinner: require("@/assets/images/spinner.svg"),
      },
      is_submitted: {
        note: false,
      },
    };
  },
  inject: [
    'appNotify',
  ],
  methods: {
    beforeOpen(event) {
      LOGGER.log(event);
      this.call_id = event.params.id;
      this.form.note = event.params.note;
    },
    onClosed() {
      this.call_id = "";
    },
    reset(form_type) {
      switch (form_type) {
        case "note":
          this.form.reset = "";
          break;
        default:
          break;
      }
    },
    sendNote() {
      this.is_submitted.note = true;
      if (this.api_sent.note) return;
      this.api_sent.note = true;
      VOIP_API.endpoints.call_activity
        .calltagsnotes({
          uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
          accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
          id: this.call_id,
          log: this.form.note,
          type: "note",
        })
        .then(() => {
          this.reset("note");
          this.appNotify({
            message: 'Successfully updated',
            type: 'success',
          })
          this.$emit('updated')
          this.$modal.hide('WriteCallNoteModal')
        })
        .catch((error) => {
          this.appNotify({
            message: _.get(error,'response.data.message') || 'Successfully updated',
            type: 'danger',
          })
        })
        .finally(() => {
          this.is_submitted.note = false;
          this.api_sent.note = false;
        });
    },
  },
};
</script>

<style>
</style>