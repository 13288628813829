var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mobileContactsContainer"
  }, [_c('div', [_c('div', {
    staticClass: "updatingContactsAgain-topBar"
  }, [_c('div', {
    staticClass: "updatingContactsAgain-tabs"
  }, [_c('div', {
    class: `updatingContactsAgain-tab ${_vm.conditions.showSecondTopBar == 'Contacts' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.conditions.showSecondTopBar = 'Contacts';
        _vm.conditions.show_side_bar = false;
        _vm.selected.tab = 'Addressbooks';
      }
    }
  }, [_vm._v(" My Contacts ")]), _c('div', {
    class: `updatingContactsAgain-tab ${_vm.conditions.showSecondTopBar == 'Organization' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.conditions.showSecondTopBar = 'Organization';
        _vm.conditions.show_side_bar = false;
        _vm.selected.tab = 'Users';
      }
    }
  }, [_vm._v(" organization ")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "mobile-topBar-favourites-likeAirCall-icon",
    attrs: {
      "icon": "mobile-topBar-favourites-likeAirCall-icon",
      "height": "16.71px",
      "width": "16px"
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = 'Favourites';
        _vm.conditions.show_side_bar = false;
        _vm.conditions.showSecondTopBar = 'Favourites';
      }
    }
  }), _c('vb-icon', {
    staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
    attrs: {
      "icon": "mobile-topBar-threeDots-likeAirCall-icon",
      "height": "27.06px",
      "width": "6px"
    }
  })], 1)]), _vm.conditions.showSecondTopBar == 'Organization' ? _c('div', {
    staticClass: "updatingContactsAgain-topBar-secondBar"
  }, [_c('div', {
    class: `updatingContactsAgain-topBar-secondBar-tab ${_vm.selected.tab == 'Users' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selected.tab = 'Users';
        _vm.conditions.show_side_bar = false;
      }
    }
  }, [_vm._v(" User ")]), _c('div', {
    class: `updatingContactsAgain-topBar-secondBar-tab ${_vm.selected.tab == 'Departments' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selected.tab = 'Departments';
        _vm.conditions.show_side_bar = false;
      }
    }
  }, [_vm._v(" Teams ")])]) : _vm._e(), _c('div', {
    staticClass: "mobileContactsTabsContent"
  }, [_vm.selected.tab == 'Favourites' ? _c('Favourites', {
    staticClass: "speedDial",
    on: {
      "show-side-bar": function ($event) {
        _vm.conditions.show_side_bar = true;
      }
    }
  }) : _vm._e(), _vm.selected.tab == 'Addressbooks' || _vm.conditions.showSecondTopBar == 'Contacts' ? _c('Addressbooks', {
    staticClass: "addressBook",
    on: {
      "show-side-bar": function ($event) {
        _vm.conditions.show_side_bar = true;
      }
    }
  }) : _vm._e(), _vm.selected.tab == 'Departments' ? _c('Departments', {
    staticClass: "departments",
    on: {
      "show-side-bar": function ($event) {
        _vm.conditions.show_side_bar = true;
      }
    }
  }) : _vm._e(), _vm.selected.tab == 'Users' ? _c('Users', {
    staticClass: "users",
    on: {
      "show-side-bar": function ($event) {
        _vm.conditions.show_side_bar = true;
      }
    }
  }) : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }