var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('ul', {
    staticClass: "phone-list full-width-call-info-number list-unstyled pr-0 pb-0 overlowX-visible-imp"
  }, [_c('li', {
    staticClass: "p-0"
  }, [_c('CallActivityTestItem', {
    staticClass: "m-0 w-100",
    attrs: {
      "call": _vm.call,
      "conditions": {
        actions: false
      }
    }
  })], 1)])]), _c('div', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-32px mb-32px"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-container"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.history
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.history;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-history-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("History")])], 1), _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.recordings
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.recordings;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-brain-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("AI transcript")])], 1)]), _vm.selected.tab == _vm.tabs.history ? _vm._t("history-filter") : _vm._e()], 2), _c('ul', {
    staticClass: "allow_scroll phone-list full-width-call-info-number dialor-callHistoryList list-unstyled pl-0 pr-2",
    staticStyle: {
      "overflow-y": "unset",
      "height": "auto !important"
    }
  }, [_vm.selected.tab == _vm.tabs.recordings ? [_vm._m(0), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 h-100"
  }, [_c('a', {
    staticClass: "d-flex align-items-start justify-content-between text-dark p-0 mb-0"
  }, [_c('span', {
    staticClass: "info-area"
  }, [_c('div', {
    staticClass: "call-date",
    staticStyle: {
      "justify-content": "flex-end",
      "display": "flex",
      "height": "20px"
    }
  }, [_c('div', {
    staticClass: "dialpad_activity_box_other"
  }, [_c('span', {
    staticClass: "dialer-dropdown dialpad_activity_box_menu whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute w-100"
  }, [_vm.call.voicemail ? _c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots",
            "scale": "1"
          }
        })];
      },
      proxy: true
    }], null, false, 2567964649)
  }, [_c('b-dropdown-item', {
    staticClass: "mt-0",
    on: {
      "click": function ($event) {
        return _vm.download();
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "download"
    }
  }), _c('span', [_vm._v("Download")])], 1)], 1) : _vm._e()], 1)])]), _c('span', {
    staticClass: "small text-capitalize w-100-60 mt-8px"
  }, [_c('div', {
    staticClass: "dialer-audio-playback"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100 mr-16px fullWidth backgroundWhite",
    attrs: {
      "design": 'whiteBgBlackBorder',
      "src": _vm.call.voicemail
    }
  })], 1)])])])])] : _vm._e(), _vm.selected.tab == _vm.tabs.history ? _vm._t("history") : _vm._e()], 2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-3"
  }, [_c('h2', {
    staticClass: "mb-0"
  }, [_vm._v("Message")])]);

}]

export { render, staticRenderFns }