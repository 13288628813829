var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-microphone-volumeSetting"
  }, [_c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-1': _vm.microphone_level >= 5
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-2': _vm.microphone_level >= 10
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-3': _vm.microphone_level >= 15
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-4': _vm.microphone_level >= 20
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-5': _vm.microphone_level >= 25
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-6': _vm.microphone_level >= 30
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-7': _vm.microphone_level >= 35
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-8': _vm.microphone_level >= 40
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-9': _vm.microphone_level >= 45
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-10': _vm.microphone_level >= 50
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-11': _vm.microphone_level >= 55
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-12': _vm.microphone_level >= 60
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-13': _vm.microphone_level >= 65
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-14': _vm.microphone_level >= 70
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-15': _vm.microphone_level >= 75
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-16': _vm.microphone_level >= 80
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-17': _vm.microphone_level >= 85
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-18': _vm.microphone_level >= 90
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-19': _vm.microphone_level >= 95
    }
  }), _c('span', {
    staticClass: "volumeControl-icon",
    class: {
      'volumeControl-icon-20': _vm.microphone_level >= 100
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }