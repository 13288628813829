var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.delete_sound.send ? '' : _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Sound File")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.edit_sound.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.edit_sound.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_sound.name,
      expression: "forms.edit_sound.name"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Name",
      "type": "text",
      "disabled": _vm.api.edit_sound.send || _vm.api.delete_sound.send
    },
    domProps: {
      "value": _vm.forms.edit_sound.name
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_sound, "name", $event.target.value);
      }, function ($event) {
        return _vm.editSound();
      }]
    }
  }), _vm.forms.edit_sound.submitted && _vm.$v.forms.edit_sound.name.$invalid || _vm.api.edit_sound.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.edit_sound.name.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), _vm._l(_vm.api.edit_sound.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])])], 1)] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.delete_sound.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Sound File")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "textInsideCard onRightSide"
  }, [_vm._v(_vm._s(_vm.data.sound.label))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Audio")]), _c('vb-audio-player', {
    staticClass: "IosAudioPlayer",
    attrs: {
      "src": _vm.data.sound.sound_file
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "textInsideCard onRightSide"
  }, [_vm._v(_vm._s(_vm.data.sound.status))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Shared")])])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "shared_check_box",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.shared_sound.send || _vm.api.delete_sound.send,
      "checked": _vm.forms.edit_sound_shared.shared,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.editSharedSound();
      }
    }
  })], 1)])]), _c('button', {
    staticClass: "IosFullWidthButton forRemove mb-16px",
    attrs: {
      "disabled": _vm.api.delete_sound.send
    },
    on: {
      "click": function ($event) {
        return _vm.deleteSound();
      }
    }
  }, [_vm.api.delete_sound.send ? _c('vb-spinner') : [_vm._v("Delete")]], 2)])]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }