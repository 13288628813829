<template>
  <div class="dialer-settings-main">
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Call Queue</div>
      <div class="plus-IOSIcon-container">
        <vb-icon 
          icon="plus-IOSIcon" 
          class="plus-IOSIcon" 
          width="20" 
          height="19.992" 
          @click="checkPermission(getUserPermissions.call_queue,()=>$modal.show('AddCallQueue',{ suggested_name: `call queue ${getVoipCallQueues.length+1}` }))"  
        />
      </div>
    </div>
    <section class="dialer-settings-section dialer-ivr-setting CallQueueSettings">
      <template>
        
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-input type="text" placeholder="Search numbers" v-model="filter.call_queues.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
      </template>
      
      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div class="basicWhiteIOScard-item" v-for="callQueue in filterCallQueue" :key="callQueue.voipaccount"
          @click="$modal.show('EditCallQueueModal',{ call_queue: callQueue })"
        >
          <div class="d-flex align-items-center">
            <div class="InfoComponentInsideAnroidIOSApp">
              <vb-avatar :id="callQueue.voipaccount" />
            </div>
            <div class="d-flex flex-column">
              <template>
                <div class="headingInsideTable">
                  {{ callQueue.label }}
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="d-flex mr-6px flex-column" v-if="callQueue.userDid && callQueue.userDid[0]">
              <div class="d-flex align-items-center" v-if="callQueue.userDid.length == 1">
                
                <div class="textInsideCard textAlignRight onRightSide">
                  {{ callQueue | get_property('userDid[0].did') }}
                </div>
              </div>
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
        </div>
      </div>
      

    </section>
    <EditCallQueueModal @list-update="afterAdding()" />
    <AddCallQueueModal @call-queue-added="afterAdding()" />
  </div>
</template>

<script>
import EditCallQueueModal from '../modals/callqueue/EditCallQueue.vue'
import AddCallQueueModal from '../modals/callqueue/AddCallQueue.vue'
import { mapGetters } from 'vuex';
import { permission_handling } from '@/mixin';

export default {
  name: "CallQueue",
  mixins: [permission_handling],
  components: {
    EditCallQueueModal,
    AddCallQueueModal,
  },
  inject:['isEmpty','getCallQueue','appNotify'],
  data() {
    return {
      filter: {
        call_queues: {
          search: '',
        },
      },
      api: {
        call_queues: this.$helperFunction.apiInstance(),
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getIsMobile',
      'getVoipCallQueues',
    ]),
    filterCallQueue(){
      const search = this.filter.call_queues.search.trim().toLowerCase()
      return this.getVoipCallQueues.filter(item=>`${item.label} ${item.extn}`.trim().toLowerCase().includes(search))
    },
  },
  methods: {
    fetchCallQueues() {
      let vm = this
      vm.api.call_queues.send=true
      this.getCallQueue()
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        vm.api.call_queues.send=false
      });
    },
    afterAdding() {
      let vm = this
      setTimeout(() => {
        vm.fetchCallQueues();
      }, 2 * 1000);
    },
  },
  activated() {
    this.fetchCallQueues();
  },
};
</script>
