var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "width": "30%",
      "background-color": "white",
      "border-radius": "20px",
      "padding": "35px"
    }
  }, [_c('vue-tree-list', {
    attrs: {
      "model": _vm.groupTree,
      "default-tree-node-name": "new node",
      "default-leaf-node-name": "new leaf",
      "default-expanded": false
    },
    on: {
      "click": function ($event) {
        !$event.isLeaf ? _vm.$emit('select-group-id', $event.id) : '';
      },
      "change-name": function ($event) {
        '';
      },
      "delete-node": function ($event) {
        '';
      },
      "add-node": function ($event) {
        '';
      }
    },
    scopedSlots: _vm._u([{
      key: "leafNameDisplay",
      fn: function (slotProps) {
        return [_c('span', {
          staticClass: "ml-1"
        }, [_vm._v(_vm._s(_vm._f("get_property")(slotProps, 'model.name')))])];
      }
    }, {
      key: "addTreeNodeIcon",
      fn: function (slotProps) {
        return [slotProps ? _c('b-icon', {
          attrs: {
            "icon": "folder-plus"
          }
        }) : _vm._e()];
      }
    }, {
      key: "addLeafNodeIcon",
      fn: function (slotProps) {
        return [slotProps ? _c('b-icon', {
          attrs: {
            "icon": "file-earmark-plus-fill"
          }
        }) : _vm._e()];
      }
    }, {
      key: "editNodeIcon",
      fn: function (slotProps) {
        return [slotProps ? _c('b-icon', {
          attrs: {
            "icon": "pencil-fill"
          }
        }) : _vm._e()];
      }
    }, {
      key: "delNodeIcon",
      fn: function (slotProps) {
        return [slotProps ? _c('b-icon', {
          attrs: {
            "icon": "trash-fill"
          }
        }) : _vm._e()];
      }
    }, {
      key: "leafNodeIcon",
      fn: function (slotProps) {
        return [slotProps ? _c('b-icon', {
          attrs: {
            "icon": "file-earmark"
          }
        }) : _vm._e()];
      }
    }, {
      key: "treeNodeIcon",
      fn: function (slotProps) {
        return [slotProps ? _c('b-icon', {
          attrs: {
            "icon": "folder-fill"
          }
        }) : _vm._e()];
      }
    }])
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }