<template>
  <modal class="dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll" name="hrmAddNewDocumentsModal">
    <div>
      <div class="modalHeading-container">
        <div class="modalHeading">Add document</div>
        <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('hrmAddNewDocumentsModal')">Close</button>
      </div>
      <div class="modalInner-container">
        <div class="uploadFile-container">
          <vb-icon icon="uploadFile-insideModal-hrmIcon" />
          <button type="button" class="OutlineButton" >Upload File</button>
        </div>
        <div class="innerShadowInput-container mt-25px">
          <label>Policy name</label>
          <textarea />
        </div>
        <div class="innerShadowInput-container forDiv mt-25px">
          <div class="DocumentIconWithText">
            <div class="DocumentIcon-Container">
              <vb-icon icon="pdfIcon-insideTable-hrmIcon" />
            </div>
            <div class="DocumentIconWithText-text">backupfile</div>
          </div>
          <vb-icon icon="trash-IOSIcon" class="trashIcon"/>
        </div>
        <div class="innerShadowInput-container">
          <label>Category</label>
          <b-dropdown class="hrmInnerShadow-dd" no-caret>
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText"></div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              Accure
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="grayCard flexColumn mt-32px">
          <div class="grayCard-heading">Number of digits shown after comma</div>
          <b-form-group class="HrmRadio-container w-100 mt-32px">
            <b-form-radio class="HrmRadio" >
              Everyone
            </b-form-radio>
            <b-form-radio class="HrmRadio">
              Multiple employees
            </b-form-radio>
          </b-form-group>
        </div>
        <div class="d-flex mt-32px">
          <div class="innerShadowInput-container halfWidth">
            <label>Team</label>
            <b-dropdown class="hrmInnerShadow-dd" no-caret>
              <template #button-content>
                <div class="button-content">
                  <div class="buttonContentText"></div>
                  <b-icon class="downIcon" icon="caret-down-fill" />
                </div>
              </template>
              <b-dropdown-item>
                Accure
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="innerShadowInput-container halfWidth">
            <label>Position</label>
            <b-dropdown class="hrmInnerShadow-dd" no-caret>
              <template #button-content>
                <div class="button-content">
                  <div class="buttonContentText"></div>
                  <b-icon class="downIcon" icon="caret-down-fill" />
                </div>
              </template>
              <b-dropdown-item>
                Accure
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="usersList-withCheckBox-container">
          <b-form-checkbox class="HrmCheckBox-container mt-16px">
            Select All
          </b-form-checkbox>
          <b-form-checkbox v-for="n in 5" :key="n" class="HrmCheckBox-container">
            <div class="nameWithImage">
              <div class="imageContainer">
                <img src="/img/1.dd976805.jpeg">
              </div>
              <div class="nameWithImage-text">Marie jane</div>
            </div>
          </b-form-checkbox>
        </div>
        <div class="innerShadowInput-container mt-32px">
          <label>Also shared with:</label>
        </div>
        <b-form-checkbox class="HrmCheckBox-container">
          Team manager
        </b-form-checkbox>
        <b-form-checkbox class="HrmCheckBox-container">
          Direct manager
        </b-form-checkbox>
        <b-form-checkbox class="HrmCheckBox-container mt-32px">
          Ask to accept the document
        </b-form-checkbox>
        <b-form-checkbox class="HrmCheckBox-container">
          Notify employee by e-mail
        </b-form-checkbox>
        <button class="dialer-button dialer-button-primary mt-32px mr-0"> Upload </button>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'hrmAddNewDocumentsModal',
}
</script>

<style>

</style>