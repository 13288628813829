import { TokenService } from "../../services/index";
import _ from "lodash";

export const mattermost = {
  state: {
    preferences: [],
    user: {},
    token: "",
    channels: {},
    channels_view: [],
    status: {},
    main_open: '',
    list_channels: []
  },
  getters: {
    //mattermost_current_user
    getMattermostCurrentUser(state){
      return _.isEmpty(state.user)?TokenService.MATTERMOST_USER.get():state.user
    },
    getFavoriteChannelPreferences(state){
      return state.preferences.filter(preference=>preference.category==="favorite_channel"&&!preference.is_direct)
    },
    getFavoriteChannelIds(state,getters){
      return getters.getFavoriteChannelPreferences.map(preference=>preference.name)
    },
    getFavoriteUsersIds(state,getters){
      return getters.getFavoriteChannelPreferences.map(preference=>preference.value)
    },
    getRecentTabPreferences(state){
      return state.preferences.filter(preference=>preference.category==="recent_channel")
    },
    getRecentChannelIds(state,getters){
      return getters.getRecentTabPreferences.map(preference=>preference.name)
    },
    getRecentUserIds(state,getters){
      return getters.getRecentTabPreferences.map(preference=>preference.value)
    },
    //user
    getUser(state) {
      return state.user;
    },
    getToken(state) {
      return state.token;
    },
    getTotalUnread(state){ return Object.values(state.channels || {}).map(item=>item.unread).reduce((item,item1)=>item+item1,0) },
  },
  mutations: {
    setPreferences(state, preferences){
      state.preferences = preferences;
    },
    setChatUnread(state, data){
      const { channel_id, count } = data || {}
      if(state.channels && state.channels[channel_id]){
        state.channels[channel_id].unread = count
      }
    },
    //user
    setMatterMostUser(state, user) {
      state.user = user;
    },
    setToken(state, token) {
      state.token = token;
    },
  },
};