var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "chat-center-area custom-width panel-wide",
    attrs: {
      "id": "message-box-334"
    }
  }, [!!_vm.channel_id ? _c('div', {
    staticClass: "d-flex flex-column overflow-hidden justify-content-center w-100 h-100"
  }, [_vm.loading ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center h-80"
  }, [_vm._m(0)]) : !!_vm.channelData ? [_c('Header', {
    ref: "header",
    attrs: {
      "random_id": _vm.random_id,
      "dataIdentity": _vm.dataIdentity,
      "type": _vm.type,
      "members": _vm.members,
      "voipMembers": _vm.voipMembers,
      "channel": _vm.channel,
      "data": _vm.data,
      "name": _vm.name,
      "image": _vm.image,
      "channelData": _vm.channelData,
      "channel_id": _vm.channel_id,
      "currentUserMember": _vm.currentUserMember
    },
    on: {
      "back": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('div', {
    staticClass: "chat-box-section overflow-visible not",
    staticStyle: {
      "position": "relative"
    },
    attrs: {
      "id": _vm.type === 'main' ? 'chat-box-section' : 'chat-box-section-2'
    },
    on: {
      "dragenter": function ($event) {
        $event.preventDefault();
        _vm.drag_and_drop = true;
      }
    }
  }, [_vm.drag_and_drop ? _c('div', {
    staticClass: "chat-drag-and-drop",
    on: {
      "dragover": function ($event) {
        $event.preventDefault();
        _vm.drag_and_drop = true;
      },
      "dragenter": function ($event) {
        $event.preventDefault();
        _vm.drag_and_drop = true;
      },
      "dragleave": function ($event) {
        $event.preventDefault();
        _vm.drag_and_drop = false;
      },
      "drop": function ($event) {
        $event.preventDefault();
        _vm.drag_and_drop = false;
        _vm.$refs['action'].filesUpload(_vm.getProperty($event, 'dataTransfer.files', []));
      }
    }
  }, [_vm._v(" Drag and Drop ")]) : _vm._e(), _c('Messages', {
    ref: "messages",
    attrs: {
      "random_id": _vm.random_id,
      "type": _vm.type,
      "members": _vm.members,
      "voipMembers": _vm.voipMembers,
      "channel": _vm.channel,
      "data": _vm.data,
      "name": _vm.name,
      "image": _vm.image,
      "channelData": _vm.channelData,
      "channel_id": _vm.channel_id,
      "currentUserMember": _vm.currentUserMember
    }
  }), _c('Actions', {
    ref: "action",
    attrs: {
      "random_id": _vm.random_id,
      "type": _vm.type,
      "members": _vm.members,
      "voipMembers": _vm.voipMembers,
      "channel": _vm.channel,
      "data": _vm.data,
      "name": _vm.name,
      "image": _vm.image,
      "channelData": _vm.channelData,
      "channel_id": _vm.channel_id,
      "currentUserMember": _vm.currentUserMember
    }
  })], 1), _c('ForwardMessageModal', {
    ref: "forward-message",
    attrs: {
      "modalName": `${_vm.random_id}_ForwardMessageModal`,
      "type": _vm.type,
      "members": _vm.members,
      "voipMembers": _vm.voipMembers,
      "channel": _vm.channel,
      "data": _vm.data,
      "name": _vm.name,
      "image": _vm.image,
      "channelData": _vm.channelData,
      "channel_id": _vm.channel_id,
      "currentUserMember": _vm.currentUserMember
    }
  }), _c('RecordedVideoModal', {
    attrs: {
      "modalName": `${_vm.random_id}_RecordedVideoModal`
    },
    on: {
      "send": function ($event) {
        return _vm.$refs.action.uploadFile($event, 'recorded video');
      }
    }
  }), _c('MediaFilesModal', {
    attrs: {
      "modalName": `${_vm.random_id}-MediaFilesModal`
    }
  })] : _vm._e()], 2) : _c('WelcomePanel')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lds-ring"
  }, [_c('div'), _c('div'), _c('div'), _c('div')]);

}]

export { render, staticRenderFns }