<template>
  <div v-if="tableDesign==0" :class="classes.tableClasses">
    <template v-if="noRecordWholePage && condition.is_no_record.show && isListEmpty && !loading">
      <slot name="no-record">
        <vb-no-record :text="loading ? '' : textNoRecord" :design="designNoRecord" />
      </slot>
    </template>
    <template v-else>
      <template v-if="horizontalScrollVersion">
        <div class="horizontalScrollVersion-container">
          <table :id="id" class="dialer-table">
            <thead>
              <slot name="header" />
            </thead>
            <tbody>
              <slot name="prepend-row" />
              <slot name="body" :start="pagination.start" :end="pagination.end" />
              <template v-if="condition.loading.show">
                <!-- <vb-loading class="my-2" :type="'circular'" v-if="isListEmpty && loading" /> -->
                <template v-if="isListEmpty && loading">
                  <slot name="loading">
                    <vb-loading class="my-2" :type="'circular'" />
                  </slot>
                </template>
              </template>
            </tbody>
          </table>
          <template v-if="condition.is_no_record.show && isListEmpty && !loading">
            <slot name="no-record">
              <vb-no-record :text="loading ? '' : textNoRecord" :design="designNoRecord" />
            </slot>
          </template>
        </div>
        <vb-pagination 
          :listLength="listLength" 
          :page="page" 
          :conditions="conditions" 
          :perPage="perPage" 
          :isListEmpty="isListEmpty" 
          @per-page-changed="$emit('per-page-changed',$event)" 
          @page-changed="$emit('page-changed',$event)" 
          @latest-pagination-state="pagination = $event" 
        />
      </template>
      <template v-else>
        <table :id="id" class="dialer-table">
          <thead>
            <slot name="header" />
          </thead>
          <tbody>
            <slot name="prepend-row" />
            <slot name="body" :start="pagination.start" :end="pagination.end" />
            <template v-if="condition.loading.show">
              <!-- <vb-loading class="my-2" :type="'circular'" v-if="isListEmpty && loading" /> -->
              <template v-if="isListEmpty && loading">
                <slot name="loading">
                  <vb-loading class="my-2" :type="'circular'" />
                </slot>
              </template>
            </template>
          </tbody>
        </table>
        <template v-if="condition.is_no_record.show && isListEmpty && !loading">
          <slot name="no-record">
            <vb-no-record :text="loading ? '' : textNoRecord" :design="designNoRecord" />
          </slot>
        </template>
        <!-- <template v-if="condition.loading.show">
          <vb-loading class="my-2" :type="'circular'" v-if="isListEmpty && loading" />
        </template> -->
        <vb-pagination 
          :listLength="listLength" 
          :page="page" 
          :conditions="conditions" 
          :perPage="perPage" 
          :isListEmpty="isListEmpty" 
          @per-page-changed="$emit('per-page-changed',$event)" 
          @page-changed="$emit('page-changed',$event)" 
          @latest-pagination-state="pagination = $event" 
        />
      </template>
    </template>
  </div>
  <div v-else class="mt-5 mb-4 table-hrm-main" >
    <div class="table-overall-header">
      <slot name="header" />
    </div>
    <div class="tab-doc-bg-border">
      <table class="">
        <thead>
        </thead>
        <tbody>
          <slot name="body" :start="pagination.start" :end="pagination.end" />
        </tbody>
      </table>
    </div>
  </div>
</template>

<script>
import _ from "lodash";
export default {
  name: "vb-table",
  props: {
    isListEmpty: {
      type: Boolean,
      default: true,
    },
    listLength: {
      type: Number,
      default: 0,
    },
    perPage: {
      type: Number,
    },
    loading: {
      type: Boolean,
      default: false,
    },
    textNoRecord: {
      type: String,
      default: "No record found",
    },
    designNoRecord: {
      type: [String, Number],
      default: 3,
    },
    design: {
      type: [String, Number],
      default: 0,
    },
    page: {
      type: [String, Number],
      default: 1,
    },
    conditions: {
      type: Object,
      default: () => ({}),
    },
    id: {
      type: String,
      default: ''
    },
    noRecordClass: {
      type: String,
      default: ''
    },
    tableDesign: {
      type: [String, Number],
      default: 0,
    },
    noRecordWholePage: {
      type: Boolean,
      default: false,
    },
    horizontalScrollVersion: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      pagination: {
        start: 0,
        end: 4,
        page: 1,
        per_page: 5,
      },
    };
  },
  computed: {
    condition() {
      return {
        is_no_record: {
          show: typeof _.get(this.conditions, "is_no_record.show") === "undefined" ? true : _.get(this.conditions, "is_no_record.show"),
        },
        loading: {
          show: typeof _.get(this.conditions, "loading.show") === "undefined" ? true : _.get(this.conditions, "loading.show"),
        },
        design: {
          subTable: typeof _.get(this.conditions, "design.subTable") === "undefined" ? false : _.get(this.conditions, "design.subTable"),
        },
      };
    },
    classes() {
      return {
        tableClasses: `dialer-box ${this.design == "grayTable"?'grayBox':'alt'} table ${this.isListEmpty?this.noRecordClass:''}`,
      };
      // if (this.design == "grayTable") {
      //   return {
      //     tableClasses: `dialer-box grayBox table ${this.isListEmpty?this.noRecordClass:''}`,
      //   };
      // } else {
      //   return {
      //     tableClasses: "dialer-box alt table",
      //   };
      // }
    },
  },
};
</script>
