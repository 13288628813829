var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup GenerateTimeOffReportsModal HrmForModalScroll",
    attrs: {
      "name": "GenerateTimeOffReportsModal",
      "clickToClose": true,
      "scrollable": true
    }
  }, [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Generate time off reports")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('GenerateTimeOffReportsModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Employee")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "nameWithImage insideDropdown"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          staticClass: "circle",
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Mellissa")])]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('div', {
    staticClass: "nameWithImage insideDropdown"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/1.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Mellissa")])])]), _c('b-dropdown-item', [_c('div', {
    staticClass: "nameWithImage insideDropdown"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/2.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Mellissa")])])]), _c('b-dropdown-item', [_c('div', {
    staticClass: "nameWithImage insideDropdown"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/3.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Mellissa")])])])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Time off policy")]), _c('b-dropdown', {
    staticClass: "withTags-hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "cancaleAbleTags-container"
        }, [_c('div', {
          staticClass: "cancaleAbleTags"
        }, [_c('div', {
          staticClass: "cancaleAbleTags-text"
        }, [_vm._v("Holiday")]), _c('div', {
          staticClass: "cancaleAbleTags-icon"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "cancaleableTags-x-hrmIcon"
          }
        })], 1)])]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Holiday ")])], 1)], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("From")]), _c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('input', {
    staticClass: "withIconOnRight primaryBorder noInnerBoxShadow"
  }), _c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  })], 1)]), _c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("To")]), _c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('input', {
    staticClass: "withIconOnRight primaryBorder noInnerBoxShadow"
  }), _c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  })], 1)])]), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Export time unit")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Status")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1), _c('div', {
    staticClass: "grayCard px-4"
  }, [_c('b-form-group', {
    staticClass: "HrmRadio-container"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Include full time off request if part of the request falls in the selected date range ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Cut multi-date time off requests to show only the days which fall in the selected date range ")])], 1)], 1), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-32px"
  }, [_vm._v(" Advance filters ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Include additional custom fields in excel export ")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Genrate ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }