var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.status ? _c('div', {
    staticClass: "dialer-userStatus",
    style: `background-color:${_vm.status.colour}`,
    attrs: {
      "id": _vm.id
    }
  }, [!_vm.status.accountcode ? _c('div', {
    staticClass: "dialer-userStatus-box"
  }, [_vm.status.status === 'Available' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  }, [_c('g', {
    attrs: {
      "id": "userOnline-icon",
      "transform": "translate(0.17 -5)"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_948",
      "data-name": "Ellipse 948",
      "cx": "14",
      "cy": "14",
      "r": "14",
      "transform": "translate(-0.17 5)",
      "fill": _vm.status.colour
    }
  }), _c('g', {
    attrs: {
      "id": "check_1_",
      "data-name": "check (1)",
      "transform": "translate(6.971 13.942)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_12151",
      "data-name": "Path 12151",
      "d": "M7.61,14.951a.72.72,0,0,1-1.019,0L2.524,10.883a1.08,1.08,0,0,1,0-1.528l.509-.509a1.08,1.08,0,0,1,1.528,0L7.1,11.385l6.862-6.862a1.08,1.08,0,0,1,1.528,0L16,5.032A1.08,1.08,0,0,1,16,6.56Z",
      "transform": "translate(-2.208 -4.207)",
      "fill": "#fff"
    }
  })])])]) : _vm._e(), _vm.status.status === 'Do not disturb' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "27",
      "viewBox": "0 0 28 27"
    }
  }, [_c('g', {
    attrs: {
      "id": "userDoNotDisturb-icon",
      "transform": "translate(0.17 -0.442)"
    }
  }, [_c('ellipse', {
    attrs: {
      "id": "Ellipse_948",
      "data-name": "Ellipse 948",
      "cx": "14",
      "cy": "13.5",
      "rx": "14",
      "ry": "13.5",
      "transform": "translate(-0.17 0.442)",
      "fill": _vm.status.colour
    }
  }), _c('rect', {
    attrs: {
      "id": "Rectangle_2999",
      "data-name": "Rectangle 2999",
      "width": "14",
      "height": "3",
      "rx": "1.5",
      "transform": "translate(6.83 12.442)",
      "fill": "#fff"
    }
  })])]) : _vm._e(), _vm.status.status === 'Offline' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  }, [_c('g', {
    attrs: {
      "id": "userOffline-icon",
      "transform": "translate(0.17 -0.277)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Ellipse_948",
      "data-name": "Ellipse 948",
      "transform": "translate(-0.17 0.277)",
      "fill": "#fff",
      "stroke": _vm.status.colour,
      "stroke-width": "1.5"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "14",
      "cy": "14",
      "r": "14",
      "stroke": "none"
    }
  }), _c('circle', {
    attrs: {
      "cx": "14",
      "cy": "14",
      "r": "13.25",
      "fill": "none"
    }
  })])])]) : _vm._e(), _vm.status.status === 'Busy' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  }, [_c('g', {
    attrs: {
      "id": "userAway-icon",
      "transform": "translate(0.17 0.393)"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_948",
      "data-name": "Ellipse 948",
      "cx": "14",
      "cy": "14",
      "r": "14",
      "transform": "translate(-0.17 -0.393)",
      "fill": _vm.status.colour
    }
  }), _c('g', {
    attrs: {
      "id": "Group_12515",
      "data-name": "Group 12515",
      "transform": "translate(11.328 7.026)"
    }
  }, [_c('rect', {
    attrs: {
      "id": "Rectangle_3000",
      "data-name": "Rectangle 3000",
      "width": "9.294",
      "height": "2.614",
      "rx": "1.307",
      "transform": "translate(1.346 6.567) rotate(31)",
      "fill": "#fff"
    }
  }), _c('rect', {
    attrs: {
      "id": "Rectangle_3001",
      "data-name": "Rectangle 3001",
      "width": "9.294",
      "height": "2.614",
      "rx": "1.307",
      "transform": "translate(0.29 9.294) rotate(-90)",
      "fill": "#fff"
    }
  })])])]) : _vm._e(), _vm.status.status === 'Idle' ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  }, [_c('g', {
    attrs: {
      "id": "userAway-icon",
      "transform": "translate(0.17 0.393)"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_948",
      "data-name": "Ellipse 948",
      "cx": "14",
      "cy": "14",
      "r": "14",
      "transform": "translate(-0.17 -0.393)",
      "fill": _vm.status.fillColor,
      "stroke": _vm.status.colour,
      "stroke-width": "4px"
    }
  }), _c('g', {
    attrs: {
      "id": "Group_12515",
      "data-name": "Group 12515",
      "transform": "translate(11.328 7.026)"
    }
  }, [_c('rect', {
    attrs: {
      "id": "Rectangle_3000",
      "data-name": "Rectangle 3000",
      "width": "9.294",
      "height": "2.614",
      "rx": "1.307",
      "transform": "translate(1.346 6.567) rotate(31)",
      "fill": "black"
    }
  }), _c('rect', {
    attrs: {
      "id": "Rectangle_3001",
      "data-name": "Rectangle 3001",
      "width": "9.294",
      "height": "2.614",
      "rx": "1.307",
      "transform": "translate(0.29 9.294) rotate(-90)",
      "fill": "black"
    }
  })])])]) : _vm._e(), _c('svg', {
    staticClass: "dialerUserStatusRing",
    attrs: {
      "height": "20",
      "width": "20",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 20 20"
    }
  }, [_c('circle', {
    staticStyle: {
      "stroke": "transparent !important"
    },
    attrs: {
      "cx": "10",
      "cy": "10",
      "r": "8",
      "stroke": "white",
      "stroke-width": "2",
      "fill": "transparent"
    }
  })])]) : _vm._e(), !_vm.$store.getters.getIsMobile ? _c('b-tooltip', {
    attrs: {
      "custom-class": "tooltip-arrow-align",
      "target": _vm.id,
      "title": _vm.status.status || '',
      "placement": "top"
    }
  }) : _vm._e()], 1) : _vm.callQueueStatus ? _c('div', {
    staticClass: "dialer-userStatus",
    style: `background-color:${_vm.callQueueStatusInfo.logged_in ? 'green' : 'black'};`,
    attrs: {
      "id": _vm.id
    }
  }, [_vm.callQueueStatusInfo.adminforce ? _c('div', {
    staticClass: "dialer-userStatus-box"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  }, [_c('g', {
    attrs: {
      "id": "userOffline-icon",
      "transform": "translate(0.17 -0.277)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Ellipse_948",
      "data-name": "Ellipse 948",
      "transform": "translate(-0.17 0.277)",
      "fill": "#fff",
      "stroke": 'green',
      "stroke-width": "1.5"
    }
  }, [_c('circle', {
    attrs: {
      "cx": "14",
      "cy": "14",
      "r": "14",
      "stroke": "none"
    }
  }), _c('circle', {
    attrs: {
      "cx": "14",
      "cy": "14",
      "r": "13.25",
      "fill": "none"
    }
  })])])])]) : _vm.callQueueStatusInfo.logged_in ? _c('div', {
    staticClass: "dialer-userStatus-box"
  }, [_vm.callQueueStatusInfo.is_paused ? _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 28 28"
    }
  }, [_c('g', {
    attrs: {
      "id": "userOnline-icon",
      "transform": "translate(0.17 -5)"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_948",
      "data-name": "Ellipse 948",
      "cx": "14",
      "cy": "14",
      "r": "14",
      "transform": "translate(-0.17 5)",
      "fill": 'green'
    }
  }), _c('g', {
    attrs: {
      "id": "check_1_",
      "data-name": "check (1)",
      "transform": "translate(6.971 13.942)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_12151",
      "data-name": "Path 12151",
      "d": "M7.61,14.951a.72.72,0,0,1-1.019,0L2.524,10.883a1.08,1.08,0,0,1,0-1.528l.509-.509a1.08,1.08,0,0,1,1.528,0L7.1,11.385l6.862-6.862a1.08,1.08,0,0,1,1.528,0L16,5.032A1.08,1.08,0,0,1,16,6.56Z",
      "transform": "translate(-2.208 -4.207)",
      "fill": "#fff"
    }
  })])])]) : _c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "28",
      "height": "27",
      "viewBox": "0 0 28 27"
    }
  }, [_c('g', {
    attrs: {
      "id": "userDoNotDisturb-icon",
      "transform": "translate(0.17 -0.442)"
    }
  }, [_c('ellipse', {
    attrs: {
      "id": "Ellipse_948",
      "data-name": "Ellipse 948",
      "cx": "14",
      "cy": "13.5",
      "rx": "14",
      "ry": "13.5",
      "transform": "translate(-0.17 0.442)",
      "fill": 'green'
    }
  }), _c('rect', {
    attrs: {
      "id": "Rectangle_2999",
      "data-name": "Rectangle 2999",
      "width": "14",
      "height": "3",
      "rx": "1.5",
      "transform": "translate(6.83 12.442)",
      "fill": "#fff"
    }
  })])])]) : _vm._e(), !_vm.$store.getters.getIsMobile ? _c('b-tooltip', {
    attrs: {
      "custom-class": "tooltip-arrow-align",
      "target": _vm.id,
      "title": `Logged ${_vm.callQueueStatusInfo.logged_in ? `in  and ${_vm.callQueueStatusInfo.is_paused ? 'paused' : 'play'}` : 'out'} `,
      "placement": "top"
    }
  }) : _vm._e()], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }