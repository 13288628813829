var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 vm--container scrollable",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-call-notification-tray px-3"
  }, [_c('div', {
    staticClass: "py-4"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "dialer-call-notification-inner"
  }, [_c('div', {
    staticClass: "dialer-call-notification-text"
  }, [_c('div', {
    staticClass: "dialer-call-notification-main-text"
  }, [_vm._v("Tag and note")]), _c('div', {
    staticClass: "dialer-call-notification-sub-text"
  }, [_vm._v("There you can update the tags and note of this call")])])]), _c('div', [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "pr-2",
    staticStyle: {
      "overflow": "auto",
      "height": "calc(100vh - 92px - 24px - 24px)"
    }
  }, [_c('div', [_c('div', {
    staticClass: "dialer-call-notification-actions-inner bg-white border-0"
  }, [_c('b-form', {
    staticClass: "dialer-input-field input-consistent-inner-shadows pt-3",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search here"
    },
    model: {
      value: _vm.filter.tag.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tag, "search", $$v);
      },
      expression: "filter.tag.search"
    }
  })], 1), !_vm.isEmpty(_vm.api.tag.validation_errors) ? _vm._l(_vm.api.tag.validation_errors.log, function (error_message, index) {
    return _c('p', {
      key: index
    }, [_vm._v(_vm._s(error_message))]);
  }) : _vm._e(), _c('ul', {
    staticClass: "dialer-tagList"
  }, _vm._l(_vm.filterTags, function (tag) {
    return _c('li', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color",
        value: _vm.forms.tag.tags.includes(tag.real_id) && tag.colour,
        expression: "forms.tag.tags.includes(tag.real_id) && tag.colour"
      }],
      key: tag.real_id,
      class: `${_vm.forms.tag.tags.indexOf(tag.real_id) > -1 ? 'selected' : ''}`,
      on: {
        "click": function ($event) {
          _vm.forms.tag.tags.indexOf(tag.real_id) > -1 ? _vm.forms.tag.tags.splice(_vm.forms.tag.tags.indexOf(tag.real_id), 1) : _vm.forms.tag.tags.push(tag.real_id);
        }
      }
    }, [_c('span', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text",
        value: _vm.forms.tag.tags.includes(tag.real_id) && tag.text_color,
        expression: "forms.tag.tags.includes(tag.real_id) && tag.text_color",
        modifiers: {
          "text": true
        }
      }]
    }, [_vm._v(_vm._s(tag.tag))])]);
  }), 0), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('b-button', {
    staticClass: "mb-3",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updatetag();
      }
    }
  }, [_vm._v("Submit")])], 1)], 2)]), _c('div', {
    staticClass: "md-mar-vertical mt-5"
  }, [_c('div', {
    staticClass: "dialer-team-name mb-4"
  }, [_c('div', {
    staticClass: "dialer-input-field input-consistent-inner-shadows"
  }, [!_vm.isEmpty(_vm.api.note.validation_errors) ? _vm._l(_vm.api.note.validation_errors.note, function (error_message, index) {
    return _c('p', {
      key: index
    }, [_vm._v(_vm._s(error_message))]);
  }) : _vm._e(), _c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Write some note for this call")]), _c('b-textarea', {
    staticClass: "w-100 p-3 line-height-1-imp",
    attrs: {
      "rows": "10",
      "no-resize": "",
      "placeholder": "Enter Note"
    },
    model: {
      value: _vm.forms.note.note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.note, "note", $$v);
      },
      expression: "forms.note.note"
    }
  }), _c('div', {
    staticClass: "d-flex justify-content-end mt-3"
  }, [_c('b-button', {
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updatenote();
      }
    }
  }, [_vm._v("Submit")])], 1)], 2)])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }