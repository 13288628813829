<template>
  <transition name="slide-fade">
    <div id="rightpanel" ref="abc" v-if="!$store.getters.getIsMobile ? showPanel : selected == 'PinnedMsgs'" class="rightpanel effect5 ifMobile">
      <div v-if="!$store.getters.getIsMobile" class="dialer-pinArea" :class="[is_pinned === true ? 'isPinned' : '']">
        <vb-icon @click="is_pinned = !is_pinned" icon="dialer-pinIcon" />
        <small class="sm-mar-left">{{ is_pinned === true ? "Unpin" : "Pin" }}</small>
      </div>
      <h2 v-if="selected!='PowerDialer'" class="d-flex flex-wrap align-items-center justify-content-between">
        <span class="d-block title">{{ titleName }}</span>
        <div v-if="$store.getters.getIsMobile" @click="handleMenu()">
          <b-icon icon="x" font-scale="1.5" class="cursor_pointer"/>
        </div>
        
      </h2>
      <div :class="[selected === 'PinnedMsgs' ? 'pinnedMsg-content' : '']">
        <component
          :is="selected"
          :ref="selected.toLowerCase()"
          @dial="dial($event)"
          @videocall="videoCall($event)"
          @close-side-bar="closeSideBarChat()"
        ></component>
      </div>
    </div>
  </transition>
</template>

<script>
import SpeedDials from "./Favourites.vue";
import Addressbooks from "./Addressbooks.vue";
import Todo from "./Todo.vue";
import Departments from "./Departments.vue";
import Users from "./Users.vue";
import SoundSetting from "./SoundSetting.vue";
import PinnedMsgs from "../Chat/PinnedMsgs.vue";
import Notifications from "./Notifications.vue";
import PowerDialer from "./PowerDialer.vue";
import { $fn } from '@/utils';
// import _ from 'lodash';

export default {
  name: "ContactPanel",
  data() {
    return {
      selected: "",
      showPanel: false,
      is_pinned: false,
    };
  },
  components: {
    SpeedDials,
    Addressbooks,
    Departments,
    Users,
    SoundSetting,
    PinnedMsgs,
    Todo,
    Notifications,
    PowerDialer
  },
  computed: {
    titleName(){
      if(this.selected==='Addressbooks'){
        return 'Address book'
      } else if(this.selected==='Departments'){
        return 'Teams'
      } else if(this.selected==='SpeedDials'){
        // return 'Speed Dials'
        return 'Favourites'
      } else if(this.selected==='SoundSetting'){
        return 'Audio Setting'
      } else if(this.selected==='PinnedMsgs'){
        return 'Pinned Messages'
      } else if(this.selected==='PowerDialer'){
        return 'Power dialer'
      } else {
        return this.selected
      }
    },
  },
  methods: {
    videoCall(event) {
      // console.log("Contact Panel Component: " + event.account);
      this.$emit("videocall", {
        account: event.account,
      });
    },
    dial(event) {
      this.$emit("dial", {
        number: event.number,
      });
    },
    ContactToggle() {
      this.showPanel = !this.showPanel;
    },
    closeSideBarChat(){
      if(!this.is_pinned) {
        // console.log('run')
        this.handleMenu()
      }
    },
    handleMenu() {
      this.$emit("interface");
      this.showPanel = false;
      this.$store.state.global_conditions.add_addressbooks = false;
      this.selected = '';
    },
    onClickListener(Event){
      let vm = this
      let index = $fn.pointerEventPath(Event)?.reverse?.()?.findIndex?.(el=>el.id=='right-side-bar-334')
      if(!vm.$store.state.global_conditions.is_right_bar_open || index>-1 || vm.is_pinned) return;
      if(!vm.$store.getters.getIsMobile){
        vm.handleMenu()
      }
    },
  },
  created(){
    document.addEventListener('click',this.onClickListener)
  },
  beforeDestroy(){
    document.removeEventListener('click',this.onClickListener)
  },
};
</script>
<style lang="scss">
.dialer-pinArea {
  cursor: pointer;
  position: relative;
  top: -4px;

  svg {
    transition: transform 0.2s ease-in-out;
    transform: rotate(-40deg) scale(2);
  }
  &:hover,
  &.isPinned {
    svg {
      transform: rotate(0deg) scale(2);
      path {
        stroke: $primary;
      }
    }
  }
}
</style>

<style lang="scss" scoped>
.slide-fade-enter-active {
  transition: all 0.3s ease;
}

.slide-fade-leave-active {
  transition: all 0.3s;
}

.slide-fade-enter,
.slide-fade-leave-to

/* .slide-fade-leave-active below version 2.1.8 */ {
  transform: translateX(10px);
  opacity: 0;
}
</style>
