<template>
  <div class="mobileContactsContainer">
    <div>
      <!--<div v-if="false" class="mobileContactsTabsHeader"> 
        
        <div :class="`TabsHeaderNames ${ selected.tab=='Addressbooks' ? 'active' : '' }`" @click="selected.tab='Addressbooks';conditions.show_side_bar=false;">
          <!~~ Speed Dials ~~>
          <vb-icon icon="mobile-addressBook-icon" class="mobile-addressBook-icon" width="63" height="63"/>
          Addressbook
        </div>
        <div :class="`TabsHeaderNames ${ selected.tab=='Favourites' ? 'active' : '' }`" @click="selected.tab='Favourites';conditions.show_side_bar=false;">
          <!~~ Addressbook ~~>
          <vb-icon icon="mobile-speedDial-icon" class="mobile-speedDial-icon" width="63" height="63"/>
          Favourites
        </div>
        <div :class="`TabsHeaderNames ${ selected.tab=='Users' ? 'active' : '' }`" @click="selected.tab='Users';conditions.show_side_bar=false;">
          <!~~ Users ~~>
          <vb-icon icon="mobile-usersContacts-icon" class="mobile-usersContacts-icon" width="63" height="63"/>
          Users
        </div>
        <div :class="`TabsHeaderNames ${ selected.tab=='Departments' ? 'active' : '' }`" @click="selected.tab='Departments';conditions.show_side_bar=false;">
          <!~~ Teams ~~>
          <vb-icon icon="mobile-teamsContacts-icon" class="mobile-teamsContacts-icon" width="63" height="63"/>
          Teams
        </div>
      </div>
      <div class="IOSlike-tabs totalTabsIs4"> 
        <div :class="`TabsHeaderNames ${ selected.tab=='Addressbooks' ? 'active' : '' }`" @click="selected.tab='Addressbooks';conditions.show_side_bar=false;">
          <!~~ Speed Dials ~~>
          Contacts
        </div>
        <div :class="`TabsHeaderNames ${ selected.tab=='Favourites' ? 'active' : '' }`" @click="selected.tab='Favourites';conditions.show_side_bar=false;">
          <!~~ Addressbook ~~>
          Favourites
        </div>
        <div :class="`TabsHeaderNames ${ selected.tab=='Users' ? 'active' : '' }`" @click="selected.tab='Users';conditions.show_side_bar=false;">
          <!~~ Users ~~>
          Users
        </div>
        <div :class="`TabsHeaderNames ${ selected.tab=='Departments' ? 'active' : '' }`" @click="selected.tab='Departments';conditions.show_side_bar=false;">
          <!~~ Teams ~~>
          Teams
        </div>
      </div>-->
      <div class="updatingContactsAgain-topBar">
        <div class="updatingContactsAgain-tabs">
          <div :class="`updatingContactsAgain-tab ${ conditions.showSecondTopBar=='Contacts' ? 'active' : '' }`" @click="conditions.showSecondTopBar='Contacts';conditions.show_side_bar=false;selected.tab='Addressbooks'">
            My Contacts
          </div>
          <div :class="`updatingContactsAgain-tab ${ conditions.showSecondTopBar=='Organization' ? 'active' : '' }`" @click="conditions.showSecondTopBar='Organization';conditions.show_side_bar=false;selected.tab='Users'">
            organization
          </div>
        </div>
        <div class="d-flex align-items-center">
          <vb-icon icon="mobile-topBar-favourites-likeAirCall-icon" @click="selected.tab='Favourites';conditions.show_side_bar=false;conditions.showSecondTopBar='Favourites'" class="mobile-topBar-favourites-likeAirCall-icon" height="16.71px" width="16px" />
          <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
        </div>
      </div>
      <div class="updatingContactsAgain-topBar-secondBar" v-if="conditions.showSecondTopBar=='Organization'">
        <div :class="`updatingContactsAgain-topBar-secondBar-tab ${ selected.tab=='Users' ? 'active' : '' }`" @click="selected.tab='Users';conditions.show_side_bar=false;">
          User
        </div>
        <div :class="`updatingContactsAgain-topBar-secondBar-tab ${ selected.tab=='Departments' ? 'active' : '' }`" @click="selected.tab='Departments';conditions.show_side_bar=false;">
          Teams
        </div>
      </div>
      <div class="mobileContactsTabsContent">
        
        <Favourites @show-side-bar="conditions.show_side_bar=true" v-if="selected.tab=='Favourites'" class="speedDial"/>
        <Addressbooks @show-side-bar="conditions.show_side_bar=true" v-if="selected.tab=='Addressbooks' || conditions.showSecondTopBar=='Contacts'" class="addressBook" />
        <Departments @show-side-bar="conditions.show_side_bar=true" v-if="selected.tab=='Departments'" class="departments"/>
        <Users @show-side-bar="conditions.show_side_bar=true" v-if="selected.tab=='Users'" class="users"/>
      </div>
    </div>
  </div>
</template>

<script>
import Favourites from "../components/rightsidebar/Favourites.vue";
import Addressbooks from "../components/rightsidebar/Addressbooks.vue";
import Departments from "../components/rightsidebar/Departments.vue";
import Users from "../components/rightsidebar/Users.vue";
export default {
  name: 'SelectContext',
  components: {
    Favourites,
    Addressbooks,
    Departments,
    Users,
  },
  data() {
    return {
      selected: {
        // tab: "Addressbooks",
        tab: "Contacts",
      },
      conditions: {
        show_side_bar: false,
        showSecondTopBar: "Contacts"
      },
    };
  },
}
</script>

<style>

</style>