<template>
  <div class="dialer-box alt"> 
    <div class="dialer-tabs">
      <div class="dialer-tab-headers justify-content-between">
        <div
          :class="[
            'dialer-tab text-capitalize',
            { selected: selectedtab === tab },
          ]"
          v-for="tab in tabs"
          :key="tab.key"
          @click="selectedtab = tab"
        >
          {{ tab }}
        </div>
      </div>
    </div>
    <div class="dialer-tab-content">
      <div v-if="selectedtab === 'Participants'" class="dialer_contact_list users_list allow_scroll">
        
        <div class="dialer_contact_row" v-for="member in channelData.members" :key="member.user_id" v-show="$store.getters.getVoipUsersAlises[member.user_id]">
          <div class="dialer_contact justify-content-between">
            <div class="dialer_contact_img">
              <Info :id="member.user_id" />
            </div>
            
            <div class="dialer_contact_hidden_area" v-if="$store.getters.getVoipUsersAlises[member.user_id].voipaccount !== $store.getters.getCurrentUser.account">
              <div class="dialer_contact_connection_box">
                
                <!-- <button class="dialer_contact_btn contact_btn" @click="dial($store.getters.getVoipUsersAlises[member.user_id])">
                  <font-awesome-icon icon="phone-alt" />
                </button>
                
                <button class="dialer_contact_btn contact_btn" @click="videoCall($store.getters.getVoipUsersAlises[member.user_id])">
                  <b-icon icon="camera-video" />
                </button>
                
                <button class="dialer_contact_btn contact_btn" @click="chatUser($store.getters.getVoipUsersAlises[member.user_id])">
                  <b-icon icon="chat" />
                </button> -->
                <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute mr-1">
                  <b-dropdown varient="link" no-caret class="ico-filter"> 
                    <template #button-content>
                      <b-icon icon="three-dots" scale="1.5" />
                    </template>
                    <b-dropdown-item @click="dial($store.getters.getVoipUsersAlises[member.user_id])">
                      <font-awesome-icon icon="phone-alt" />
                      <span class="ml-2">Call</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="videoCall($store.getters.getVoipUsersAlises[member.user_id])">
                      <b-icon icon="camera-video" />
                      <span class="ml-2">Video</span>
                    </b-dropdown-item>
                    <b-dropdown-item @click="chatUser($store.getters.getVoipUsersAlises[member.user_id])">
                      <b-icon icon="chat" />
                      <span class="ml-2">Chat</span>
                    </b-dropdown-item>
                  </b-dropdown>
                </span>


              </div>
            </div>
          </div>
        </div>
      </div>
      <div v-if="selectedtab === 'Pinned Messages'" class="dialer_contact_list users_list allow_scroll" style="height:calc(100vh - 243px);">
        <Pinned ref="pinned" :channel_id="$parent.$parent.pin_post.channel_id" :channelData="channelData" :channel="channel" :data="data" />
      </div>
      <div v-if="selectedtab === 'Files'" class="dialer_contact_list users_list allow_scroll user_files">
        <File v-for="file in files" :key="file.id" :file="file" design="files" />
      </div>
      <DirectChatModal />
    </div>
  </div>
</template>

<script>
import Pinned from "./Box/Messages/Pinned";
import File from "./Box/Messages/File";
import _ from "lodash";
import { events } from "../../utils";
import DirectChatModal from "@/components/Modals/chat/DirectChatModal";
import Info from '../Lists/Info.vue';

export default {
  name: "PinMsgs",
  components: {
    Pinned,
    File,
    DirectChatModal,
    Info,
  },
  computed: {
    channelData() {
      return this.$store.state.mattermost.channels[
        this.$parent.$parent.pin_post.channel_id
      ];
    },
    channel() {
      if (_.isEmpty(this.channelData)) return {};
      return this.channelData.channel;
    },
    data() {
      if (_.isEmpty(this.channelData)) return {};
      return this.channelData.data;
    },
    files() {
      const posts = this.channelData.posts.order.map(
        (order) => this.channelData.posts.posts[order]
      );
      const multimediaposts = posts.filter(
        (message) => message.metadata && message.metadata.files
      );
      let files = [];
      multimediaposts.forEach((message) => {
        files = [...files, ...message.metadata.files];
      });
      return files;
    },
  },
  data() {
    return {
      // tabs: ["Members", "Pinned Messages", "Files"],
      tabs: ["Participants", "Pinned Messages", "Files"],
      selectedtab: "Pinned Messages",
      user_male: require("@/assets/images/user-male.png"),
    };
  },
  inject:['isEmpty', 'getProperty'],
  methods: {
    onPinnedPostChanged(message) {
      const { channel_id } = message;
      this.$parent.$parent.pin_post.channel_id = channel_id;
    },
    chatUser(user) {
      this.$modal.show("ChatUserModal", { user: user });
    },
    videoCall(user) {
      this.$root.$emit(events.video_call,{
        accounts: [user.voipaccount],
        call_type: 'video'
      })
    },
    dial(user) {
      this.$root.$emit(events.audio_call,{
        accounts: user.extn,
      })
    },
  },
  created() {
    this.$root.$on(events.pinned_post_changed, this.onPinnedPostChanged);
  },
  mounted(){
    const tab = this.$parent?.$parent?.pin_post?.tab ?? ''
    if(tab=='members') this.selectedtab='Participants'
    else if(tab=='files') this.selectedtab='Files'
    else this.selectedtab='Pinned Messages'
  },
  beforeDestroy(){
    this.$root.$off(events.pinned_post_changed, this.onPinnedPostChanged);
  },
};
</script>

<style lang="scss" scoped>
.allow_scroll {
  overflow-y: auto;
}
</style>
