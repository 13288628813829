export const MOBILE_APP_EVENTS = {
  DIAL: 'dial',
  LOGIN_RESPONSE: 'login-response',
  NEW_TOKEN: 'new-token',
  ACCEPT_CALL: 'accept-call',
  REJECT_CALL: 'reject-call',
  HANGUP: 'hangup',
  TOGGLE_HOLD: 'toggle-hold',
  TOGGLE_PICTURE_IN_PICTURE: 'toggle-picture-in-picture',
}
export const MOBILE_APP_SEND_EVENTS = {
  MISSED: 'missed',
  HANGUP: 'hangup',
  SHOW_WEB_VIEW: 'show-web-view',
  NEW_TOKEN: 'new-token',
  AUTH_FAILED: 'auth-failed',
  LOGGED_IN: 'logged-in',
  LOADED: 'loaded',
  LOGOUT: 'logout',
  SHOW_INCOMING_CALL_SCREEN: 'show-incoming-call-screen',
  BUSY: 'busy',
  HOLD_STATE: 'hold-state',
}