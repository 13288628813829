<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen" 
      @before-close="onBeforeClose"
    >
      <template v-if="conditions.tab">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" >
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.tab=false" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Playlist</div>
        </div>
        <div class="innerModalIos">

          <div class="basicWhiteIOScard mt-32px">
            <div class="basicWhiteIOScard-item" v-for="tab in tabs" :key="tab.id"  @click="selected.tab=tab.id">
              <div class="headingInsideTable">{{tab.text}}</div>
              <vb-icon v-if="selected.tab==tab.id" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            </div>
          </div>

        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
            
          </div>
          <div class="dialer-settings-title topMainHeading">My Sound Files</div>
          <div 
            class="plus-IOSIcon-container" 
            @click="checkPermission(getUserPermissions.block_and_privacy,()=>$modal.show('AddSoundFile',{ 
              suggested_name: `sound library ${response.files.length+1}` 
            }))"
          >
            <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992" />
          </div>
        </div>
        <div class="innerModalIos">  
          <div class="mobileInputIOS iconOnLeft mt-32px">
            <b-form-input placeholder="Search" v-if="selected.tab==tabs.system.id" v-model="filter.system.search" />
            <b-form-input placeholder="Search" v-else-if="selected.tab==tabs.files.id" v-model="filter.files.search" />
            <b-form-input placeholder="Search" v-else-if="selected.tab==tabs.shared.id" v-model="filter.shared.search" />
            <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
          </div>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" @click="conditions.tab=true">
              <div class="headingInsideTable">Sound Library</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard onRightSide textAlignRight mr-8px">{{ selectedTab.text }}</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
          </div>
          <div class="basicWhiteIOScard mt-16px mb-16px">
            <div>
              <template v-if="selected.tab==tabs.system.id">
                <div class="basicWhiteIOScard-item" v-for="(file, index) in filterSystem" :key="index" @click="selectFile(file)">
                  <div class="headingInsideTable">{{ file.label }}</div>
                  <div class="d-flex align-items-center">
                    <vb-audio-player class="mr-16px IosAudioPlayer" :src="file.sound_file" />
                    <div class="d-flex align-items-center forRadio-container">
                      <vb-icon icon="MyNumbers-tick-IOSIcon" :style="`${selected.file_id==file.id ? 'opacity:1;':'opacity:0;'}`" width="16.642" height="15.275" />
                    </div>
                  </div>
                </div>
                <vb-no-record v-if="!filterSystem.length && !api.files.send" :text="'There is no Numbers'" :design="3" />
              </template>
              <template v-else-if="selected.tab==tabs.files.id">
                <div class="basicWhiteIOScard-item" v-for="(file, index) in filterFiles" :key="index" @click="selectFile(file)">
                  <div class="headingInsideTable">{{ file.label }}</div>
                  <div class="d-flex align-items-center">
                    <vb-audio-player class="mr-16px IosAudioPlayer" :src="file.sound_file" />
                    <div class="d-flex align-items-center forRadio-container">
                      <vb-icon icon="MyNumbers-tick-IOSIcon" :style="`${selected.file_id==file.id ? 'opacity:1;':'opacity:0;'}`" width="16.642" height="15.275" />
                    </div>
                  </div>
                </div>
                <vb-no-record v-if="!filterFiles.length && !api.files.send" :text="'There is no Numbers'" :design="3" />
              </template>
              <template v-else-if="selected.tab==tabs.shared.id">
                <div class="basicWhiteIOScard-item" v-for="(file, index) in filterShared" :key="index" @click="selectFile(file)">
                  <div class="headingInsideTable">{{ file.label }}</div>
                  <div class="d-flex align-items-center">
                    <vb-audio-player class="mr-16px IosAudioPlayer" :src="file.sound_file" />
                    <div class="d-flex align-items-center forRadio-container">
                      <vb-icon icon="MyNumbers-tick-IOSIcon" :style="`${selected.file_id==file.id ? 'opacity:1;':'opacity:0;'}`" width="16.642" height="15.275" />
                    </div>
                  </div>
                </div>
                <vb-no-record v-if="!filterShared.length && !api.files.send" :text="'There is no Numbers'" :design="3" />
              </template>
            </div>
            <InfiniteLoading ref="files_infinity_loading" @infinite="fetchFiles()">
              <div slot="spinner">
                <vb-loading type="loader" />
              </div>
              <div slot="no-more"></div>
              <div slot="no-results"></div>
              <template #error="{ trigger }">
                <div>
                  <span>{{ api.files.error_message }}</span>
                  <b-icon icon="arrow-repeat" class="ml-3" variant="primary" font-scale="1.5" @click="trigger()" />
                </div>
              </template>
            </InfiniteLoading>

          </div>
          <AddSoundFileModal @update-list="fetchFiles(true)" />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

import AddSoundFileModal from "./AddSound.vue";

import { mapGetters } from 'vuex';
import { VOIP_API } from "@/utils/index";
// import { centrifugo } from '@/Centrifuge/index';
import { permission_handling } from '@/mixin';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';

const tabs = {
  files: {
    id: 1,
    text: "Mine",
  },
  shared: {
    id: 2,
    text: "Shared with me",
  },
  system: {
    id: 3,
    text: "System",
  },
}
export default {
  name: "SelectSoundFileModal",
  mixins: [permission_handling],
  props: {
    modalName: {
      type: String,
      default: 'SelectSoundFile'
    },
  },
  components: {
    AddSoundFileModal,
    InfiniteLoading,
  },
  data() {
    return {
      api: {
        files: this.$helperFunction.apiInstance({ error_message: true, }),
      },
      response: {
        files: [],
        shared: [],
        system: [],
      },
      filter: {
        shared: {
          search: '',
        },
        files: {
          search: '',
        },
        system: {
          search: '',
        },
      },
      selected: {
        tab: tabs.files.id,
        file_id: '',
      },
      conditions: {
        tab: false,
      },
      data: {
        type: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'getUserPermissions',
    ]),
    tabs(){ return tabs },
    selectedTab(){ return Object.values(this.tabs).find(tab=>tab.id==this.selected.tab) },
    filterFiles(){ return this.response.files.filter(file=>`${file.label}`.trim().toLowerCase().includes(this.filter.files.search)) },
    filterShared(){ return this.response.shared.filter(shared=>`${shared.label}`.trim().toLowerCase().includes(this.filter.shared.search)) },
    filterSystem(){ return this.response.system.filter(system=>`${system.label}`.trim().toLowerCase().includes(this.filter.system.search)) },
  },
  methods: {
    onBeforeOpen(event){
      this.data.type = event?.params?.type ?? ''
      this.selected.file_id = event?.params?.value ?? ''
      setTimeout(()=>this.fetchFiles(true),0.5*1000)
      AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
      // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
    },
    onBeforeClose(){
      this.data.type = ''
      this.selected.file_id=''
      this.selected.tab=tabs.files.id
      this.filter.shared.search=''
      this.filter.files.search=''
      this.filter.system.search=''
      this.conditions.tab=false
      this.response.files=[]
      this.response.shared=[]
      this.response.system=[]
      this.api.files.reset()
      AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
      // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
    },
    selectFile(file){
      if(this.selected.file_id==file.id) return;
      this.selected.file_id=file.id
      this.$emit('select-file',{
        file: file,
        type: this.data.type 
      })
      this.$modal.hide(this.modalName)
    },
    fetchFiles(reset) {
      let vm = this;
      if(vm.api.files.send) return;
      vm.api.files.send = true;
      if(reset==true){
        vm.response.files = [];
        vm.response.shared = [];
        vm.response.system = [];
        vm.$refs.files_infinity_loading?.stateChanger?.reset?.()
      }
      VOIP_API.endpoints.recording.list({
        uid: vm.getCurrentUser.uid,
        accountcode: vm.getCurrentUser.account,
        type: 'ivr',
      })
      .then(({ data }) => {
        const files = data?.files?.data ?? data?.files ?? []
        const shared = data?.shared?.data ?? data?.shared ?? [];
        const system = data?.global?.data ?? data?.global ?? [];
        vm.response.files = files;
        vm.response.shared = shared;
        vm.response.system = system;
        if(files.length>0 || shared.length>0 || system.length>0) vm.$refs.files_infinity_loading?.stateChanger?.loaded()
        vm.$refs.files_infinity_loading?.stateChanger?.complete()
      })
      .catch((ex) => {
        vm.$refs.files_infinity_loading?.stateChanger?.error?.()
        vm.api.files.error_message=ex.own_message || ex.message
      })
      .finally(() => {
        vm.api.files.send = false;
      });
    },
    onNotification(message) {
      if (message.data.flag === "file_uploaded_successfully") {
        this.fetchFiles(true);
      }
    },
  },
};
</script>
