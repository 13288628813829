<template>
  <div>
    <modal class="dialer_animation right_side_popup graphDataModal" width = "600px" height="auto" :scrollable="true" @before-open="onBeforeOpen" :name="modalName">
      <div class="dialer-assign-to my-4 dialer-edit">
        <div class="dialer-edit-actions d-flex justify-content-end" id="zoomTestId">
          <button class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div id="app">
        <template v-if="info">
          <template v-if="info.type=='user'">
            <UserGraph @interface="$emit('interface')" :accountcode="info.data.voipaccount" @change-graph="data.accountcode.push($event)" />
          </template>
          <template v-if="info.type=='team'">
            <TeamGraph @interface="$emit('interface')" :accountcode="info.data.ringroup.accountcode" @change-graph="data.accountcode.push($event)" />
          </template>
          <template v-if="info.type=='call_queue'">
            <CallQueueGraph @interface="$emit('interface')" :accountcode="info.data.voipaccount" @change-graph="data.accountcode.push($event)" />
          </template>
          <template v-if="info.type=='ivr'">
            <IvrGraph @interface="$emit('interface')" :accountcode="info.data.voipaccount" @change-graph="data.accountcode.push($event)" />
          </template>
          <template v-if="info.type=='number'">
            <template v-if="info.assigned">
              <template v-if="info.assigned.type=='user'">
                <UserGraph @interface="$emit('interface')" :accountcode="info.assigned.data.voipaccount" @change-graph="data.accountcode.push($event)" />
              </template>
              <template v-if="info.assigned.type=='team'">
                <TeamGraph @interface="$emit('interface')" :accountcode="info.assigned.data.ringroup.accountcode" @change-graph="data.accountcode.push($event)" />
              </template>
              <template v-if="info.assigned.type=='call_queue'">
                <CallQueueGraph @interface="$emit('interface')" :accountcode="info.assigned.data.voipaccount" @change-graph="data.accountcode.push($event)" />
              </template>
              <template v-if="info.assigned.type=='ivr'">
                <IvrGraph @interface="$emit('interface')" :accountcode="info.assigned.data.voipaccount" @change-graph="data.accountcode.push($event)" />
              </template>
            </template>
            <template v-else>
              <NumberGraph @interface="$emit('interface')" :id="info.data.real_id" @change-graph="data.accountcode.push($event)" />
            </template>
          </template>
        </template>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import IvrGraph from '../Graph/ivr/index.vue';
import UserGraph from '../Graph/users/index.vue';
import TeamGraph from '../Graph/teams/index.vue';
import CallQueueGraph from '../Graph/callqueue/index.vue';
import NumberGraph from '../Graph/numbers/index.vue';
export default {
  name: "GraphModal",
  components:{
    IvrGraph,
    UserGraph,
    TeamGraph,
    CallQueueGraph,
    NumberGraph,
  },
  props: {
    modalName: {
      type: String,
      default: "GraphModal",
    },
  },
  data() {
    return {
      data: {
        accountcode: [],
      },
    }
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getVoipTeams',
      'getVoipCallQueues',
      'getVoipIVRSAlises',
      'getVoipNumbers',
    ]),
    info() {
      const key = this.data.accountcode?.[this.data.accountcode.length-1] ?? ''
      const user = this.getVoipUsersAlises[key];
      const team = this.getVoipTeams.find(team=>key==team?.ringroup?.accountcode || key==team?.real_id || key==team?.ringroup?.extn)
      const call_queue = this.getVoipCallQueues.find(call_queue=>key==call_queue?.voipaccount || key==call_queue?.extn)
      const number = this.getVoipNumbers.find(number=>key==number?.real_id)
      const ivr = this.getVoipIVRSAlises[key]
      if(team){ 
        return { 
          data: team, 
          type: 'team' 
        }
      } else if(call_queue) {
        return { 
          data: call_queue, 
          type: 'call_queue' 
        }
      } else if(ivr) {
        return { 
          data: ivr, 
          type: 'ivr' 
        }
      } else if(user) {
        return { 
          data: user, 
          type: 'user' 
        }
      } else if(number) {
        if(number.assigned) {
          const assigned_key = number.assigned
          const assigned_user = this.getVoipUsersAlises[assigned_key];
          const assigned_team = this.getVoipTeams.find(team=>assigned_key==team?.ringroup?.accountcode || assigned_key==team?.real_id || assigned_key==team?.ringroup?.extn)
          const assigned_call_queue = this.getVoipCallQueues.find(call_queue=>assigned_key==call_queue?.voipaccount || assigned_key==call_queue?.extn)
          const assigned_ivr = this.getVoipIVRSAlises[assigned_key]
          if(assigned_user){
            return { 
              data: number, 
              type: 'number',
              assigned: {
                data: assigned_user,
                type: 'user',
              },
            }
          } else if(assigned_team){
            return { 
              data: number, 
              type: 'number',
              assigned: {
                data: assigned_team,
                type: 'team',
              },
            }
          } else if(assigned_call_queue){
            return { 
              data: number, 
              type: 'number',
              assigned: {
                data: assigned_call_queue,
                type: 'call_queue',
              },
            }
          } else if(assigned_ivr){
            return { 
              data: number, 
              type: 'number',
              assigned: {
                data: assigned_ivr,
                type: 'ivr',
              },
            }
          } else {
            return { 
              data: number, 
              type: 'number',
            }
          }
        } else {
          return { 
            data: number, 
            type: 'number',
          }
        }
      }
      else return null;
    },
  },
  methods: {
    onBeforeOpen(event){
      // console.log("onBeforeOpen", event)
      this.data.accountcode=event?.params?.accountcode ? [event?.params?.accountcode] : []
    }
  },
};
</script>