<template>
  <modal class="dialer_animation right_side_popup CheckSlotsModal" :name="modalName" @before-open="onBeforeOpen($event)" @closed="onClosed()" :clickToClose="false">
    
    <!-- <vb-spinner v-if="api.check.send" /> -->
    <template v-if="api.check.send">
      <div class="w-100 p-5">
        <div class="d-flex align-items-center mb-4" v-for="n in 3" :key="n.id">
          <div class="latestShimmerDesign" style="height:150px;width:150px;"></div>
          <div class="d-flex flex-column flex-fill ml-4">
            <div class="latestShimmerDesign mb-4" style="height:40px;width:80%;"></div>
            <div class="latestShimmerDesign mb-3" style="height:28px;width:50%;"></div>
            <div class="latestShimmerDesign" style="height:28px;width:60%;"></div>
          </div>
        </div>
      </div>
    </template>
    <template v-else>
      <template v-if="slotAvailable">
        <div class="containerCC">
          <div class="headerContainer mb-5">
            <div class="headingText">Licences</div>
            <button class="newCloseButton" :disabled="api.check.send" @click="cancel()">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </div>
          <div class="w-100" v-if="isAddon">
            
            <div class="latestTableDesign-withBlackBorders-again dialer-box alt table mb-0">
              <table class="dialer-table">
                <thead>
                  <tr>
                    <th class="dialer-has-sort">
                      <span>Name</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Allowed</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Used</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Remaining</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Price</span>
                    </th>
                    <th class="dialer-has-sort dialer-col-right">
                      <span>Billing Cycle</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">
                      <span>{{ data.service }}</span>
                    </td>
                    <td class="">
                      <span>{{response.slot.PlanAddonAllowedProductCount | show_positive_value }}</span>
                    </td>
                    <td class="">
                      <span>{{response.slot.PlanAddonUsedProductCount | show_positive_value}}</span>
                    </td>
                    <td class="">
                      <span>{{response.slot.PlanAddonRemainingProductCount | show_positive_value}}</span>
                    </td>
                    <td class="">
                      <span>{{ response | get_property('product_detail.plan.plan_price',0) | to_fixed }} {{ response | get_property('product_detail.plan.plan_cost_currency') }}</span>
                    </td>
                    <td class="dialer-col-right">
                      <span>{{ response | get_property('product_detail.plan.plan_billing_period') }}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="w-100" v-else>
            <div class="dialer-box alt table latestTableDesign-withBlackBorders-again">
              <table class="dialer-table">
                <thead>
                  <tr>
                    <th class="dialer-has-sort">
                      <span>Name</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Allowed</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Used</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Remaining</span>
                    </th>
                    <th class="dialer-has-sort">
                      <span>Price</span>
                    </th>
                    <th class="dialer-has-sort dialer-col-right">
                      <span>Billing Cycle</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr>
                    <td class="">
                      <span>{{ data.service }}</span>
                    </td>
                    <td class="">
                      <span>{{response.slot.PlanAllowedProductCount | show_positive_value}}</span>
                    </td>
                    <td class="">
                      <span>{{response.slot.PlanUsedProductCount | show_positive_value}}</span>
                    </td>
                    <td class="">
                      <span>{{response.slot.PlanRemainingProductCount | show_positive_value}}</span>
                    </td>
                    <td class="">
                      <b-icon id="freeSlots" icon="check2-circle" variant="success" font-scale="1.5"/>
                      <b-popover ref="popover" target="freeSlots" triggers="hover">
                        <small>This resource is available in your current package.</small>
                      </b-popover>
                    </td>
                    <td class="dialer-col-right">
                      <span>{{response | get_property('product_detail.plan.plan_billing_period')}}</span>
                    </td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>
          <div class="proceedButtonContainer">
            <button class="newDoneButton" :disabled="api.check.send" @click="proceed()">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Proceed</span>
            </button>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="containerCC">
          <div class="headerContainer">
            <div class="headingText">Check Slots</div>
            <button class="newCloseButton" :disabled="api.check.send" @click="cancel()">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px w-100">
            <div class="latestGreyBox-heading-main w-100 text-center mt-20px px-4">
              You don't have any slot available for adding this service. Kindly upgrade your package to get it.
            </div>
            <div class="proceedButtonContainer">
              <button class="dialer-button dialer-button-primary" :disabled="api.check.send" @click="ok()">ok</button>
            </div>
          </div>
        </div>
      </template>
      
      
    </template>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import { show_positive_value } from '@/filter'
export default {
  name: 'CheckedServiceAvailableModal',
  props: {
    modalName: {
      type: String,
      default: 'CheckedServiceAvailableModal',
    },
  },
  data() {
    return {
      response: {
        slot: {},
        product_detail: {},
      },
      api: {
        check: this.$helperFunction.apiInstance()
      },
      data: {
        service: '',
        number_category: '',
      },
      call_backs: {
        proceed: null,
        cancel: null,
        ok: null,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    slotAvailable(){ return this.response.slot.PlanRemainingProductCount>0 || this.response.slot.PlanAddonRemainingProductCount>0 },
    isAddon(){ return this.response.slot.PlanRemainingProductCount<=0 && this.response.slot.PlanAddonRemainingProductCount>0 },
  },
  filters: {
    show_positive_value,
  },
  methods: {
    onBeforeOpen(event) {
      this.data.service=event?.params?.service
      this.data.number_category=event?.params?.number_category
      this.call_backs.proceed=event?.params?.onProceed
      this.call_backs.cancel=event?.params?.onCancel
      this.call_backs.ok=event?.params?.onOK
      this.check()
    },
    onClosed() {
      this.data.service=''
      this.data.number_category=''
      this.response.slot={}
      this.response.product_detail={}
      this.call_backs.proceed=null
      this.call_backs.cancel=null
      this.call_backs.ok=null
    },
    check(){
      let vm = this
      vm.api.check.send=true
      VOIP_API.endpoints.billing.checkservice({
        uid: vm.getCurrentUser.uid,
        accountcode: vm.getCurrentUser.account,
        service: vm.data.service,
        ...vm.data.number_category ? {
          number_category: vm.data.number_category
        } : {},
        email: vm.getCurrentUser.email,
      })
      .then(({ data: res })=>{
        vm.response.slot=res?.slots
        vm.response.product_detail=res?.product_detail
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.check.send=false
      })
    },
    proceed(){
      this.$emit('proceed');
      this.$modal.hide(this.modalName)
      this.call_backs?.proceed?.()
    },
    cancel(){
      this.$emit('cancel');
      this.$modal.hide(this.modalName)
      this.call_backs?.cancel?.()
    },
    ok(){
      this.$emit('ok');
      this.$modal.hide(this.modalName)
      this.call_backs?.ok?.()
    },
  },
}
</script>

<style>

</style>