var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Org chart "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('ExportOrgChartModal');
      }
    }
  }, [_vm._v(" Export ")])]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Org chart is automatically build based on who is reporting to whom. Here is a list of all employees & their direct managers ")]), _c('div', {
    staticClass: "dropdownsContainer"
  }, [_c('b-dropdown', {
    staticClass: "primaryBorderDropdown w-220px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All teams")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All teams ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown w-220px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All locations")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1), _c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "nameWithImage"
          }, [_c('div', {
            staticClass: "imageContainer"
          }, [_c('img', {
            attrs: {
              "src": require('@/assets/images/credit-card/1.jpeg')
            }
          })]), _c('div', {
            staticClass: "nameWithImage-text"
          }, [_vm._v("Marie jane")])])]), _c('td', {
            staticClass: "dialer-col-right text-left two"
          }, [_c('div', {
            staticClass: "nameWithImage"
          }, [_c('div', {
            staticClass: "imageContainer"
          }, [_c('img', {
            attrs: {
              "src": require('@/assets/images/credit-card/1.jpeg')
            }
          })]), _c('div', {
            staticClass: "nameWithImage-text"
          }, [_vm._v("Marie jane")])])]), _c('td', {
            staticClass: "dialer-col-right three"
          }, [_c('b-dropdown', {
            staticClass: "ActionsDD",
            attrs: {
              "no-caret": "",
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('div', {
                  staticClass: "button-content"
                }, [_c('b-icon', {
                  staticClass: "downIcon",
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead text-left one pt-1"
  }, [_vm._v("Employee")]), _c('th', {
    staticClass: "HrmTableHead text-left two pt-1"
  }, [_vm._v("direct manager")]), _c('th', {
    staticClass: "HrmTableHead three pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1), _c('ExportOrgChartModal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }