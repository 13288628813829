var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer_numberSetting"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Numbers")])], 1), _c('div', [_c('button', {
    staticClass: "newButton mr-0",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddNumbersModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add")])], 1)])] : [_c('h2', {
    staticClass: "dialer-settings-title mb-0 settingsFirstSection-left newer"
  }, [_vm._v(" Numbers "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "id": "ivr-info",
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'numberSettings'
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-0"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search number, label"
    },
    model: {
      value: _vm.filter.numbers.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.numbers, "search", $$v);
      },
      expression: "filter.numbers.search"
    }
  })], 1), _c('button', {
    staticClass: "newButton mr-0",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddNumbersModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add")])], 1)])])]], 2), _vm._m(0), !_vm.$store.getters.getIsMobile ? [_c('vb-table', {
    staticClass: "dialer-numberTable-customWidth latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover",
    attrs: {
      "textNoRecord": " ",
      "id": "numbers-table",
      "isListEmpty": _vm.isEmpty(_vm.filterTelephoneNumbers),
      "listLength": _vm.filterTelephoneNumbers.length,
      "perPage": 5,
      "loading": _vm.api.telephone_numbers.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterTelephoneNumbers, function (number, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: `${number.id}-${number.object}`,
            staticClass: "dialer-row-select",
            class: {
              'red-bg': number.status == '2'
            },
            on: {
              "click": function ($event) {
                number.queue_service ? '' : number.status == 2 ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this number is suspended'
                }) : _vm.$modal.show('EditNumbersModal', {
                  number: number
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("DID number :")]), _c('div', {
            staticClass: "d-flex justify-content-between dialer-numberWithCountry"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "country-img-container p-0"
          }, [number.object === 'TelephoneNumbers' ? _c('vb-icon', {
            attrs: {
              "icon": "numbersSettings-table-tdIcon",
              "width": "35px",
              "height": "35px"
            }
          }) : number.object === 'CallerId' ? _c('vb-icon', {
            attrs: {
              "icon": "numbersSettings-table-tdIcon-another",
              "width": "35px",
              "height": "35px"
            }
          }) : _c('vb-icon', {
            attrs: {
              "icon": "numbersSettings-table-tdIcon",
              "width": "35px",
              "height": "35px"
            }
          })], 1), number.queue_service ? _c('span', {
            staticClass: "ml-2 d-flex flex-column dialer-numberInfo justify-content-center"
          }, [_c('span', {
            staticClass: "m-0 didNumber"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.name)))])]) : number.object === 'TelephoneNumbers' ? _c('span', {
            staticClass: "ml-2 d-flex flex-column dialer-numberInfo justify-content-center"
          }, [_c('span', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "m-0 didNumber",
            attrs: {
              "title": number.is_trial == 1 ? 'Default' : ''
            }
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.number)))]), _c('div', {
            staticClass: "d-flex align-items-center"
          }, [number.label ? _c('small', {
            staticClass: "didNumber-text mr-2 w-auto h-fit-content addedLabelInsideEditNumbersModal"
          }, [_vm._v("(" + _vm._s(number.label) + ")")]) : _vm._e(), number.status == 2 ? _c('div', {
            staticClass: "suspendedTag"
          }, [_vm._v("Suspended")]) : _vm._e()])]) : _c('span', {
            staticClass: "d-flex flex-column ml-1 dialer-numberInfo justify-content-center"
          }, [_c('span', {
            staticClass: "m-0 didNumber"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.number)))]), number.status == 2 ? _c('div', {
            staticClass: "suspendedTag"
          }, [_vm._v("Suspended")]) : _vm._e()])]), _c('span', {
            staticClass: "d-flex align-items-center"
          }, [number.queue_service ? _c('img', {
            staticClass: "country-img",
            staticStyle: {
              "border-radius": "8px"
            },
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(number.name))
            }
          }) : _c('img', {
            staticClass: "country-img",
            staticStyle: {
              "border-radius": "8px"
            },
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(number.number))
            }
          })])])]), _c('td', {
            staticClass: "dialer-row-title two"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("Assigned to :")]), number.object === 'TelephoneNumbers' ? _c('div', {
            staticClass: "w-fit-content"
          }, [number.ringgroupId == number.assigned ? [number.ringgroup_members[0] ? _c('div', {
            staticClass: "d-flex flex-column"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('Info', {
            attrs: {
              "id": number.ringgroup_members[0],
              "is_blf": false
            }
          }), number.ringgroup_members.length > 1 ? _c('div', {
            staticClass: "w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                _vm.conditions.more = _vm.conditions.more == number.id ? '' : number.id;
              }
            }
          }, [_c('div', {
            staticClass: "px-2 rounded more-numbers-tag"
          }, [_c('small', [_vm._v(_vm._s(_vm.conditions.more == number.id ? '-' : '+') + " " + _vm._s(number.ringgroup_members.length - 1) + " more")])])]) : _vm._e()], 1), _vm.conditions.more == number.id ? [_vm._l(number.ringgroup_members.filter(function (item, index) {
            return index > 0;
          }), function (assigned, index) {
            return [_c('div', {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: index >= start && index <= end,
                expression: "index >= start && index <= end"
              }],
              key: assigned,
              staticClass: "mt-2"
            }, [_c('Info', {
              attrs: {
                "id": assigned,
                "is_blf": false
              }
            })], 1)];
          })] : _vm._e()], 2) : _vm._e()] : number.assigned ? [_c('div', [_c('Info', {
            attrs: {
              "id": number.assigned,
              "is_blf": false
            }
          })], 1)] : _vm._e()], 2) : _vm._e()]), _c('td', {
            staticClass: "three dialer-col-right"
          }, [number.queue_service ? _c('b-spinner', {
            attrs: {
              "variant": "primary",
              "type": "grow",
              "small": "",
              "label": "Spinning"
            }
          }) : number.object != 'CallerId' ? _c('div', {
            staticClass: "three dialer-col-right d-flex align-items-center"
          }, [_c('span', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content ml-0 mr-16px showOnly-OnHover-insideTable"
          }, [_vm._v(" Configure ")]), _c('button', {
            staticClass: "dialer-button dialer-button-delete-alt buttonNothing",
            attrs: {
              "type": "button"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                number.status != 1 ? '' : number.status != '2' && number.object != 'CallerId' ? _vm.$modal.show('NumberGraphModal', {
                  accountcode: number.real_id
                }) : '';
              }
            }
          }, [_c('vb-icon', {
            staticClass: "graphIcon",
            attrs: {
              "icon": "graph-icon",
              "height": "40.501",
              "width": "41.374"
            }
          })], 1)]) : _vm._e()], 1), _c('td', {
            staticClass: "four dialer-col-right"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("Caller ID shared with :")]), !number.queue_service ? _c('div', {
            staticClass: "d-flex justify-content-end"
          }, [_c('a', {
            staticClass: "dialer-link-more text-decoration-underline",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                number.status == '2' ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this number is suspended'
                }) : _vm.$modal.show('UserCallerIdShared', {
                  extensions: number.extensions,
                  number: number,
                  type: 'UserCallerIdShared'
                });
              }
            }
          }, [_c('span', {
            staticClass: "noOfUsers"
          }, [_vm._v(_vm._s(_vm._f("get_property")(number, "extensions.length", 0)))]), _c('span', {
            staticClass: "ml-1"
          }, [_vm._v("Users")])])]) : _vm._e()])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center justify-content-between"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "latestShimmerDesign rounded-circle mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          })]), _c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "20px",
              "width": "40px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "100px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }], null, false, 229194767)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort one"
  }, [_c('span', [_vm._v("DID number")])]), _c('th', {
    staticClass: "dialer-has-sort two"
  }, [_c('span', [_vm._v("Assigned to")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right three"
  }), _c('th', {
    staticClass: "dialer-has-sort four dialer-col-right"
  }, [_c('span', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Caller ID shared with',
      expression: "'Caller ID shared with'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }]
  }, [_vm._v("Outgoing calls only")])])])])], 2)] : _vm._e(), _c('EditNumbersModal', {
    on: {
      "latest": function ($event) {
        return _vm.afterUpdating(false);
      },
      "team-update": function ($event) {
        return _vm.fetchTeamsDebounce();
      },
      "ivr-update": function ($event) {
        return _vm.fetchIvrsDebounce();
      },
      "queue-update": function ($event) {
        return _vm.fetchCallQueuesDebounce();
      }
    }
  })], 2), _vm.$store.getters.getIsMobile ? [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again smallerFontSizes ml-1 mt-0",
    staticStyle: {
      "width": "calc(100% - 14px)"
    },
    attrs: {
      "textNoRecord": " ",
      "isListEmpty": _vm.isEmpty(_vm.filterTelephoneNumbers),
      "listLength": _vm.filterTelephoneNumbers.length,
      "perPage": 5,
      "loading": _vm.api.telephone_numbers.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref2) {
        var start = _ref2.start,
          end = _ref2.end;
        return _vm._l(_vm.filterTelephoneNumbers, function (number, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: `${number.id}-${number.object}`,
            staticClass: "dialer-row-select",
            class: {
              'red-bg': number.status == '2'
            },
            on: {
              "click": function ($event) {
                number.queue_service ? '' : number.status == 2 ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this number is suspended'
                }) : _vm.$modal.show('EditNumbersModal', {
                  number: number
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "d-flex justify-content-between dialer-numberWithCountry"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "country-img-container p-0 mr-6px"
          }, [number.object === 'TelephoneNumbers' ? _c('vb-icon', {
            attrs: {
              "icon": "numbersSettings-table-tdIcon",
              "width": "25px",
              "height": "25px"
            }
          }) : number.object === 'CallerId' ? _c('vb-icon', {
            attrs: {
              "icon": "numbersSettings-table-tdIcon-another",
              "width": "25px",
              "height": "25px"
            }
          }) : _c('vb-icon', {
            attrs: {
              "icon": "numbersSettings-table-tdIcon",
              "width": "25px",
              "height": "25px"
            }
          })], 1), number.queue_service ? _c('span', {}, [_c('span', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.name)))])]) : number.object === 'TelephoneNumbers' ? _c('span', {}, [_c('span', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "headingInsideTable",
            attrs: {
              "title": number.is_trial == 1 ? 'Default' : ''
            }
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.number)))]), _c('div', {
            staticClass: "d-flex align-items-center"
          }, [number.label ? _c('small', {
            staticClass: "mr-8px textInsideCard"
          }, [_vm._v("(" + _vm._s(number.label) + ")")]) : _vm._e(), number.status == 2 ? _c('div', {
            staticClass: "suspendedTag"
          }, [_vm._v("Suspended")]) : _vm._e()])]) : _c('span', {
            staticClass: "d-flex flex-column dialer-numberInfo justify-content-center"
          }, [_c('span', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.number)))]), number.status == 2 ? _c('div', {
            staticClass: "suspendedTag"
          }, [_vm._v("Suspended")]) : _vm._e()])])]), !number.queue_service ? _c('div', {
            staticClass: "mt-8px"
          }, [_c('a', {
            staticClass: "dialer-link-more text-decoration-underline",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                number.status == '2' ? _vm.appNotify({
                  type: 'danger',
                  message: 'Unable to proceed because this number is suspended'
                }) : _vm.$modal.show('UserCallerIdShared', {
                  extensions: number.extensions,
                  number: number,
                  type: 'UserCallerIdShared'
                });
              }
            }
          }, [_c('span', {
            staticClass: "noOfUsers"
          }, [_vm._v(_vm._s(_vm._f("get_property")(number, "extensions.length", 0)))]), _c('span', {
            staticClass: "ml-1"
          }, [_vm._v("Users")])])]) : _vm._e()]), _c('td', {
            staticClass: "right dialer-col-right pr-1"
          }, [number.object === 'TelephoneNumbers' ? _c('div', {
            staticClass: "d-flex justify-content-end"
          }, [number.ringgroupId == number.assigned ? [number.ringgroup_members[0] ? _c('div', {
            staticClass: "d-flex flex-column align-items-end"
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-end"
          }, [_c('Info', {
            attrs: {
              "id": number.ringgroup_members[0],
              "is_blf": false
            }
          }), number.ringgroup_members.length > 1 ? _c('div', {
            staticClass: "w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1",
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                _vm.conditions.more = _vm.conditions.more == number.id ? '' : number.id;
              }
            }
          }, [_c('div', {
            staticClass: "pl-1 rounded more-numbers-tag"
          }, [_c('small', [_vm._v("(" + _vm._s(_vm.conditions.more == number.id ? '-' : '+') + " " + _vm._s(number.ringgroup_members.length - 1) + ")")])])]) : _vm._e()], 1), _vm.conditions.more == number.id ? [_vm._l(number.ringgroup_members.filter(function (item, index) {
            return index > 0;
          }), function (assigned, index) {
            return [_c('div', {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: index >= start && index <= end,
                expression: "index >= start && index <= end"
              }],
              key: assigned,
              staticClass: "mt-2"
            }, [_c('Info', {
              attrs: {
                "id": assigned,
                "is_blf": false
              }
            })], 1)];
          })] : _vm._e()], 2) : _vm._e()] : number.assigned ? [_c('div', [_c('Info', {
            attrs: {
              "id": number.assigned,
              "is_blf": false
            }
          })], 1)] : _vm._e()], 2) : _vm._e()])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center justify-content-between"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "latestShimmerDesign rounded-circle mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          })]), _c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "20px",
              "width": "40px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "35px",
              "width": "35px"
            }
          }), _c('div', {
            staticClass: "d-flex flex-column justify-content-between"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-1",
            staticStyle: {
              "height": "16px",
              "width": "30px"
            }
          })])])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "100px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }], null, false, 936831795)
  })] : _vm._e(), _c('AddNumbersModal', {
    on: {
      "interface": function ($event) {
        return _vm.afterUpdating(true);
      },
      "manual-number-added": function ($event) {
        return _vm.afterUpdating(false);
      },
      "port-number-added": function ($event) {
        return _vm.afterUpdating(false);
      },
      "existing-number-added": function ($event) {
        return _vm.afterUpdating(false);
      }
    }
  }), _c('AddRemoveFromList', {
    attrs: {
      "modalName": "UserCallerIdShared"
    },
    on: {
      "update-list": function ($event) {
        return _vm.afterUpdating(false);
      }
    }
  }), _c('AddCallerIdModal'), _c('GraphModal', {
    attrs: {
      "modalName": "NumberGraphModal"
    }
  }), _c('ScheduleAssignModal', {
    attrs: {
      "disableCurrentUser": false,
      "modalName": `${_vm._uid}-CallForwardingSelectExtensionModal`
    },
    on: {
      "interface": function ($event) {
        return _vm.updateExtension($event);
      }
    }
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v("Manage all telephone numbers and caller IDs within your organization.")])]);

}]

export { render, staticRenderFns }