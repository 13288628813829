<template>
  <div class="right-calender">
    <div class="top-main-calender scrollow1">
      <section class="unsec-common">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h5 class="emp-profile-heading">Individual allowances</h5>
          </div>
         </div>
         <p class="notes-top-text space4text-when-scroll-activate">
          You can edit individual allowance for specific employees. The edited days will be applied to all future accrual periods.
         </p>
        <div class="bg-modal-doc-grey-share-with  mt-5">
          <div class="calender-hrm-height mb-4">
            <div>
              <label class="calender-form-labels mr-imp" >Location</label>
              <div class="dd-container mt-1">
                <b-dropdown no-caret class="mainBorderfordropi">
                  <template #button-content>
                    <div class="button-content-dropi">
                      <div class="dropi-contenti">Location</div>
                      <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                  <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div>
              <label class="calender-form-labels" >Team</label>
              <div class="dd-container mt-1">
                <b-dropdown no-caret class="mainBorderfordropi">
                  <template #button-content>
                    <div class="button-content-dropi">
                      <div class="dropi-contenti">Team</div>
                      <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item class="dropi-dropdown-toggle">Voip</b-dropdown-item>
                  <b-dropdown-item class="dropi-dropdown-toggle">Kuflink</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
            <div>
              <label class="calender-form-labels" >Time off policy</label>
              <div class="opendd-container mt-1">
                <b-dropdown no-caret class="mainBorderfordropi spcfy-hi8-4-opn1">
                  <template #button-content>
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="d-flex flex-wrap" >
                        <div class="d-flex justify-content-between align-items-center  slected-custom-border">
                          <h5 class="topolicy-selected-head">Holiday</h5>
                          <b-icon icon="x-lg" class="crossIconSvg"></b-icon>
                          </div>
                      </div>
                        <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item class="dropi-dropdown-toggle">Voip</b-dropdown-item>
                  <b-dropdown-item class="dropi-dropdown-toggle">Kuflink</b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
        </div>
        <vb-table :tableDesign="1" class="indivisual-allowance-table-width mt-5">
          <template slot="header">
            <h5 class="commonth onetd col-first-entryleft ">Employee</h5>
            <h5 class="commonth secondtd">Holiday</h5>
            <h5 class="commonth thirdtd">Sickday</h5>
            <h5 class="commonth fourtd">Holiday -UK</h5>
            <h5 class="commonth fivetd">Holiday -USA</h5>
            <h5 class="commonth sixtd">Holiday -PAK</h5>
            <h5 class="commonth seventd col-right">Business Trip</h5>
          </template>
          <template slot="body">
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <tr>
              <td class="onetd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
                </div>
              </td>	
              <td class="secondtd">
                20 days
              </td>
              <td class="thirdtd">
                02 days
              </td>
              <td class="fourtd">
                -
              </td>
              <td class="fivetd">
                -
              </td>
              <td class="sixtd">
                20 days
              </td>
              <td class="seventd  indivisual-allowances-detail-last ">
                02 days
              </td>
            </tr>
            <div class="HrmTablePaginationMain">
              <div class="HrmTablePaginationMiddle">
                <div class="HrmTablePaginationButton">
                  <svg width="13.825" height="8.326" viewBox="0 0 13.825 8.326" fill="" stroke-width="" stroke-linecap="" stroke-linejoin="" stroke="" class="HrmTablePaginationSvg Extrafast">
                    <path id="arrowDown" d="M0,0,5.5,5.5,11,0" transform="translate(1.414 1.414)" fill="none" stroke="#5b5b5b" stroke-linecap="round" stroke-width="2"></path>
                  </svg>
                  <svg width="13.825" height="8.326" viewBox="0 0 13.825 8.326" fill="" stroke-width="" stroke-linecap="" stroke-linejoin="" stroke="" class="HrmTablePaginationSvg Extrafast">
                    <path id="arrowDown" d="M0,0,5.5,5.5,11,0" transform="translate(1.414 1.414)" fill="none" stroke="#5b5b5b" stroke-linecap="round" stroke-width="2"></path>
                  </svg>
                </div>
                <div class="HrmTablePaginationButton">
                  <svg width="13.825" height="8.326" viewBox="0 0 13.825 8.326" fill="" stroke-width="" stroke-linecap="" stroke-linejoin="" stroke="" class="HrmTablePaginationSvg single">
                    <path id="arrowDown" d="M0,0,5.5,5.5,11,0" transform="translate(1.414 1.414)" fill="none" stroke="#5b5b5b" stroke-linecap="round" stroke-width="2"></path>
                  </svg>
                </div>
                <div class="HrmTablePaginationDigits">1</div>
                <div class="HrmTablePaginationDigits">2</div>
                <div class="HrmTablePaginationButton">
                  <svg width="13.825" height="8.326" viewBox="0 0 13.825 8.326" fill="" stroke-width="" stroke-linecap="" stroke-linejoin="" stroke="">
                    <path id="arrowDown" d="M0,0,5.5,5.5,11,0" transform="translate(1.414 1.414)" fill="none" stroke="#5b5b5b" stroke-linecap="round" stroke-width="2"></path>
                  </svg>
                </div>
                <div class="HrmTablePaginationButton">
                  <svg width="13.825" height="8.326" viewBox="0 0 13.825 8.326" fill="" stroke-width="" stroke-linecap="" stroke-linejoin="" stroke="">
                    <path id="arrowDown" d="M0,0,5.5,5.5,11,0" transform="translate(1.414 1.414)" fill="none" stroke="#5b5b5b" stroke-linecap="round" stroke-width="2"></path>
                  </svg>
                  <svg width="13.825" height="8.326" viewBox="0 0 13.825 8.326" fill="" stroke-width="" stroke-linecap="" stroke-linejoin="" stroke="">
                    <path id="arrowDown" d="M0,0,5.5,5.5,11,0" transform="translate(1.414 1.414)" fill="none" stroke="#5b5b5b" stroke-linecap="round" stroke-width="2"></path>
                  </svg>
                </div>
              </div>
              <div class="HrmTablePaginationLast"> 
                <h5>Result per page</h5>
              </div>
            </div>
          </template>
          
        </vb-table>
      </section>
    </div>
    <CreateNewPolicyTimeoffModal/>
  </div>
</template>
<script>
import CreateNewPolicyTimeoffModal from "../Modals/CreateNewPolicyTimeoffModal.vue"
export default {
  name: 'IndivisualAllowancesSettingHRM',
  components:{
	CreateNewPolicyTimeoffModal,
  }
}

</script>

<style>

</style>