<template>
  <div class="dialer-analytics-main dialer-analytics-reports">
    <div class="dialer-box">
      <header class="dialer-analytics-titleBar">
        <template v-if="$store.getters.getIsMobile">
          <div class="dialer-analytics-title calc-title-width-analytics">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h1 class="">Reporting</h1>
          </div>
        </template>
        <div v-else class="dialer-analytics-title">
          <b-icon icon="people" variant="primary"></b-icon>
          <h1 class="md-mar-left">Reporting</h1>
        </div>
      </header>
      <div class="dialer-analytics-reporting-filter my-4">
        <div class="dialer-analyticsReportingFilter-inner dialer-flex align-items-start">
          <div class="column col1 dialer-input-field input-consistent-inner-shadows">
            <label class="dialer-input-label pl-2">Search number</label>
            <div class="dialer-input-field">
              <input v-model="number" type="text" class="lighter-placeholder" placeholder="Source or Destination Number" />
            </div>
          </div>
          <div class="column col2 dialer-input-field input-consistent-inner-shadows VueCtkDateTimePicker no-label">
            <label class="dialer-input-label pl-2">Select date range</label>
            <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" :label="label" :format="format" :noButton="false" :range="true" v-model="daterange" class="lighter-placeholder" />
          </div>
          <div class="column col3 dialer-input-field input-consistent-inner-shadows">
            <label class="dialer-input-label pl-2">Call type</label>
            <v-select v-model="callType" :aria-disabled="api_sent.extensions_list" :options="types" placeholder="Received, dialed and missed" class="cursor_pointer2 lighter-placeholder" />
          </div>
          <div class="column col4 dialer-input-field input-consistent-inner-shadows">
            <label class="dialer-input-label pl-2">User & extension</label>
            <vSelect class="cursor_pointer2 lighter-placeholder" multiple :aria-disabled="api_sent.extensions_list" v-model="selected" :options="agents" placeholder="One user with multiple extensions, Limit." />
          </div>
          <div class="column col4 dialer-input-field input-consistent-inner-shadows">
            <label class="dialer-input-label pl-2">Tags</label>
            <vSelect class="cursor_pointer2 lighter-placeholder" multiple :aria-disabled="api_sent.extensions_list" v-model="selectedTags" :options="tagOptions" placeholder="Select tags" />
          </div>
          <button class="newButton ml-auto" @click="search(currentPage)" :disabled="loading">
            <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Result</span>
          </button>
        </div>
      </div>

      <div class="md-mar-top">
        <table class="dialer-table">
          <thead>
            <tr>
              <!--<th class="dialer-has-sort bg-white one">
                <span>SOURCE</span>
              </th>
              <th class="dialer-has-sort bg-white two">
                <span>DESTINATION</span>
              </th>-->
              <th class="dialer-has-sort  bg-white one">
                <span>TEST</span>
              </th>
              <th class="dialer-has-sort bg-white two">
                <span>TIME & DATE</span>
              </th>
              <th class="dialer-has-sort bg-white three">
                <span id="timeAndDuration">DURATION</span>
              </th>

              <th class="dialer-has-sort bg-white four">
                <span>Call Recorded</span>
              </th>
            </tr>
          </thead>
          <tbody>
            <tr v-if="loading">
              <td colspan="6">
                <vb-no-record :text="''" :design="3">
                  <vb-loading class="text-center w-100" slot="loading" />
                </vb-no-record>
              </td>
            </tr>
            <tr v-else-if="isEmpty(list)">
              <td colspan="6">
                <vb-no-record :text="'No record'" :design="3" />
              </td>
            </tr>
            <template v-else>
              <ReportItem v-for="(data, key) in list" :key="key" :index="key" :data="data" />
            </template>
          </tbody>
        </table>
        <div v-if="!isEmpty(list)" class="d-flex justify-content-center w-100">
          <vb-pagination :listLength="totalRecord" :conditions="{ pagination: { per_page: false } }" :perPage="10" :isListEmpty="isEmpty(list)" @latest-pagination-state="callback($event.page)" />
        </div>
      </div>
    </div>
    <CreateReportModal />
  </div>
</template>

<script>
import CreateReportModal from "../Modals/CreateReportModal";
import ReportItem from "./ReportItem.vue";
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
// import { BPagination } from "bootstrap-vue";
import { VOIP_API } from "../../utils";
import { ctkDateTimePicker } from '../../mixin';
import { mapGetters } from 'vuex';

export default {
  name: "Report",
  mixins: [
    ctkDateTimePicker
  ],
  components: {
    CreateReportModal,
    vSelect,
    ReportItem,
    // BPagination,
  },
  data() {
    return {
      list: {},
      loading: false,
      disableSubmitButton: false,
      // label: "Select date range",
      label: "Show current date",
      format: "YYYY-MM-DD",
      service: null,
      agents: [],
      types: [],
      number: null,
      callType: null,
      daterange: "",
      selected: null,
      selectedTags: null,
      page: 1,
      currentPage: 1,
      totalRecord: 0,
      api_sent: {
        extensions_list: false,
        reports: false,
      },
    };
  },
  inject:[
    'isEmpty'
  ],
  computed: {
    ...mapGetters([
      'getVoipCallTags',
    ]),
    tagOptions(){
      return this.getVoipCallTags.map((item) => {
        return {
          label: item.tag,
          value: item.real_id,
        };
      });
    },
    reportsParams(){
      const number = this.number
      const from = this.daterange ? this.daterange.start : ''
      const to = this.daterange ? this.daterange.end : ''
      const agent = this.selected ? this.selected.map((e) => e.value).join(",") : ''
      const tags = this.selectedTags ? this.selectedTags.map((e) => e.value).join(",") : ''
      const callType = this.callType ? this.callType.value : ''
      let obj = {
        type: "data",
        page: this.page,
      }
      let obj_number = number ? { number } : {}
      let obj_from = from ? { from } : {}
      let obj_to = to ? { to } : {}
      let obj_agent = agent ? { agent } : {}
      let obj_tags = tags ? { tags } : {}
      let obj_callType = callType ? { callType } : {}
      return {
        ...obj,
        ...obj_number,
        ...obj_from,
        ...obj_to,
        ...obj_agent,
        ...obj_tags,
        ...obj_callType,
      }
    },
  },
  methods: {
    createReport() {
      this.$modal.show("CreateReportModal");
    },
    fetchReports(){
      this.api_sent.reports=true
      VOIP_API.endpoints.call_activity.reportingfilterdata(this.reportsParams).then(({ data: { data: reports, total } }) => {
        this.list = reports;
        this.totalRecord = total;
      }).finally(() => {
        this.api_sent.reports=false
      });
    },
    async search() {
      this.loading = true;
      let agent = this.selected;
      let tags = this.selectedTags
      let obj = {
        type: "data",
        page: this.page,
      };
      if (this.number) {
        Object.defineProperty(obj, "number", {
          value: this.number,
          configurable: true,
          writable: true,
          enumerable: true,
        });
      }

      if (this.daterange && this.daterange.start) {
        Object.defineProperty(obj, "from", {
          value: this.daterange.start,
          configurable: true,
          writable: true,
          enumerable: true,
        });
      }

      if (this.daterange && this.daterange.end) {
        Object.defineProperty(obj, "to", {
          value: this.daterange.end,
          configurable: true,
          writable: true,
          enumerable: true,
        });
      }

      if (agent) {
        Object.defineProperty(obj, "agent", {
          value: agent.map((e) => e.value).join(","),
          configurable: true,
          writable: true,
          enumerable: true,
        });
      }

      if (tags) {
        Object.defineProperty(obj, "tags", {
          value: tags.map((e) => e.value).join(","),
          configurable: true,
          enumerable: true,
          writable: true,
        });
      }

      if (this.callType) {
        Object.defineProperty(obj, "callType", {
          value: this.callType.value,
          configurable: true,
          writable: true,
          enumerable: true,
        });
      }
      await VOIP_API.endpoints.call_activity
        .reportingfilterdata(obj)
        .then(({ data: res }) => {
          this.list = res.data;
          this.totalRecord = res.total;
        })
        .finally(() => {
          this.loading = false;
        });
    },
    callback(page) {
      this.loading = true;
      let agent = this.selected;
      let tags = this.selectedTags
      // console.log(tags)
      let obj = {
        type: "data",
        page: page,
      };
      if (this.number) {
        Object.defineProperty(obj, "number", {
          value: this.number,
          configurable: true,
          enumerable: true,
          writable: true,
        });
      }

      if (this.daterange) {
        Object.defineProperty(obj, "from", {
          value: this.daterange.start + "&to=" + this.daterange.end,
          configurable: true,
          enumerable: true,
          writable: true,
        });
      }

      if (agent) {
        Object.defineProperty(obj, "agent", {
          value: agent.map((e) => e.value).join(","),
          configurable: true,
          enumerable: true,
          writable: true,
        });
      }

      if (tags) {
        Object.defineProperty(obj, "tags", {
          value: tags.map((e) => e.value).join(","),
          configurable: true,
          enumerable: true,
          writable: true,
        });
      }

      if (this.callType) {
        Object.defineProperty(obj, "callType", {
          value: this.callType.value,
          configurable: true,
          enumerable: true,
          writable: true,
        });
      }
      VOIP_API.endpoints.call_activity.reportingfilterdata(obj).then(({ data: res }) => {
        // this.agents = res.extensions;
        // this.types = res.typeOptions;
        this.list = res.data;
        this.totalRecord = res.total;
      }).finally(()=>{
        this.loading = false;
      });
    },
    extensionList() {
      this.api_sent.extensions_list = true;
      VOIP_API.endpoints.call_activity.reportingfilterdata({
        type: "filter",
      }).then(({ data: res }) => {
        this.agents = res.extensions;
        this.types = res.typeOptions;
      }).finally(() => {
        this.api_sent.extensions_list = false;
      });
    },
  },
  mounted() {
    this.extensionList();
    this.search();
  },
};
</script>
