var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', [_c('modal', {
    staticClass: "newPackagesModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": true
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "newPackagesModal-header"
  }, [_c('div', {
    staticClass: "newPackagesModal-heading"
  }, [_vm._v("Subscription plan details")]), _c('b-icon', {
    staticClass: "newPackagesModal-closeIcon",
    attrs: {
      "icon": "x-lg"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1), _vm.api.packages.send || _vm.api.subscription_detail.send || _vm.api.update_plan.send ? [_c('div', {
    staticClass: "newPackagesModal-packages"
  }, _vm._l(2, function (n, index) {
    return _c('div', {
      key: index,
      staticClass: "newPackagesModal-package"
    }, [_c('div', [_c('div', {
      staticClass: "newPackagesModal-package-heading"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "32px",
        "width": "50%"
      }
    })]), _c('div', {
      staticClass: "newPackagesModal-package-price-row"
    }, [_c('div', {
      staticClass: "newPackagesModal-package-price-column-1 w-50"
    }, [_c('div', {
      staticClass: "newPackagesModal-package-price-column-1 w-100"
    }, [_c('div', {
      staticClass: "newPackagesModal-package-price-bigNumer",
      staticStyle: {
        "width": "48%"
      }
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "46.8px",
        "width": "100%"
      }
    })]), _c('div', {
      staticClass: "newPackagesModal-package-price-column-1-text",
      staticStyle: {
        "width": "48%"
      }
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "46.8px",
        "width": "100%"
      }
    })])])]), _c('div', {
      staticClass: "newPackagesModal-package-price-column-2 w-25"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "46.8px",
        "width": "100%"
      }
    })])]), _c('div', {
      staticClass: "newPackagesModal-package-features mb-20px"
    }, _vm._l(8, function (n) {
      return _c('div', {
        key: n.id,
        staticClass: "package-features-row mt-16px"
      }, [_c('div', {
        staticClass: "latestShimmerDesign",
        staticStyle: {
          "height": "23.39px",
          "width": "100%"
        }
      })]);
    }), 0)]), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "50px",
        "width": "100%"
      }
    })]);
  }), 0), _c('div', {
    staticClass: "newPackagesModal-freePackage"
  }, [_c('div', {
    staticClass: "newPackagesModal-freePackage-leftSide",
    staticStyle: {
      "width": "100px"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "30px",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "42px",
      "width": "100%",
      "margin-top": "6px"
    }
  })]), _c('div', {
    staticClass: "newPackagesModal-freePackage-rightSide w-75"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "23.39px",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "newPackagesModal-package-features d-flex align-items-center"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "latestShimmerDesign mt-16px mr-32px flex-1",
      staticStyle: {
        "height": "23.39px",
        "width": "100%"
      }
    });
  }), 0)])])] : _vm.response.packages.length == 0 ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-center"
  }, [_c('div', {
    staticClass: "w-100 d-flex flex-column align-items-center px-4"
  }, [_c('img', {
    staticClass: "mx-auto",
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/serverConnection.png')
    }
  }), _c('h4', {
    staticClass: "latestGreyBox-heading-main text-center mt-20px mb-0"
  }, [_vm._v(" It's taking longer than usual to connect to our servers. Please check your internet connection and try again. ")])])])])])] : [_vm.isTrailPlan ? _c('div', {
    staticClass: "innerBlueBoxwithRoundedBorder mt-20px"
  }, [_c('vb-icon', {
    staticClass: "AccountBalance-newBilling-icon",
    attrs: {
      "icon": "AccountBalance-newBilling-icon",
      "height": "25px",
      "width": "25px"
    }
  }), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between flex-fill"
  }, [_c('p', {
    staticClass: "innerRedBoxwithRoundedBorder-text mb-0"
  }, [_vm._v(" Cancel your trial plan first or wait for trial period ")])])], 1) : _vm._e(), _c('div', {
    staticClass: "newPackagesModal-packages"
  }, _vm._l(_vm.allPaidPlans, function (plan, index) {
    return _c('div', {
      key: index,
      staticClass: "newPackagesModal-package",
      class: {
        active: plan.real_id == _vm.currentPlanId
      }
    }, [_c('div', [_c('div', {
      staticClass: "newPackagesModal-package-heading"
    }, [_vm._v(_vm._s(index == 0 ? 'Professional' : 'Complete'))]), _c('div', {
      staticClass: "newPackagesModal-package-price-row"
    }, [_c('div', {
      staticClass: "newPackagesModal-package-price-column-1"
    }, [_c('div', {
      staticClass: "newPackagesModal-package-price-column-1"
    }, [_c('div', {
      staticClass: "newPackagesModal-package-price-bigNumer"
    }, [_vm._v(" " + _vm._s(index == 0 ? '£4.99' : '£9.99') + " ")]), _c('div', {
      staticClass: "newPackagesModal-package-price-column-1-text"
    }, [_vm._v(" PER "), _c('br'), _vm._v("MONTH /USER ")])])])]), index == 0 ? _c('div', {
      staticClass: "newPackagesModal-package-features mb-20px"
    }, [_c('div', {
      staticClass: "package-features-row"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Call analytics")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Unlimited calling options available")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Business hours setting")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Call notes and tags")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Integrations to other systems")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Call recording for numbers")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Custom sound files")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Number blocking and privacy")])], 1)]) : _c('div', {
      staticClass: "newPackagesModal-package-features mb-20px"
    }, [_c('div', {
      staticClass: "package-features-row"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Smart call queueing")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Live call monitoring")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("AI transcription")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("BLF( Busy lamp field )")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Unlimited custom statuses")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Advanced IVR options")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Custom MOH")])], 1), _c('div', {
      staticClass: "package-features-row mt-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "packages-feature-roundTick-icon",
        "width": "20px",
        "height": "20px"
      }
    }), _c('div', {
      staticClass: "package-features-row-text"
    }, [_vm._v("Advance greetings in voicemails")])], 1)])]), _c('button', {
      staticClass: "newPackagesModal-package-button mt-auto",
      attrs: {
        "disabled": plan.real_id == _vm.currentPlanId
      },
      on: {
        "click": function ($event) {
          return _vm.updatePlan(plan);
        }
      }
    }, [plan.real_id == _vm.currentPlanId ? [_vm._v(" Current plan ")] : [_vm._v(" Choose plan ")]], 2)]);
  }), 0), _vm.users.length > 3 ? _c('div', {
    staticClass: "innerBlueBoxwithRoundedBorder mt-20px"
  }, [_c('vb-icon', {
    staticClass: "AccountBalance-newBilling-icon",
    attrs: {
      "icon": "AccountBalance-newBilling-icon",
      "height": "25px",
      "width": "25px"
    }
  }), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between flex-fill"
  }, [_c('p', {
    staticClass: "innerRedBoxwithRoundedBorder-text mb-0"
  }, [_vm._v(" You are not eligible for free package as your account have more than two users! ")])])], 1) : _vm.freePlan ? _c('div', {
    class: `newPackagesModal-freePackage ${_vm.freePlan.real_id == _vm.currentPlanId ? 'active' : ''}`,
    attrs: {
      "disabled": _vm.freePlan.real_id == _vm.currentPlanId || _vm.isTrailPlan
    },
    on: {
      "click": function ($event) {
        return _vm.updatePlan(_vm.freePlan);
      }
    }
  }, [_c('div', {
    staticClass: "newPackagesModal-freePackage-leftSide",
    staticStyle: {
      "pointer-events": "none"
    }
  }, [_c('div', {
    staticClass: "newPackagesModal-freePackage-smallHeading"
  }, [_vm._v("Free")]), _c('div', {
    staticClass: "newPackagesModal-freePackage-bigHeading"
  }, [_vm._v("£0.00")])]), _c('div', {
    staticClass: "newPackagesModal-freePackage-rightSide",
    staticStyle: {
      "pointer-events": "none"
    }
  }, [_c('div', {
    staticClass: "newPackagesModal-freePackage-rightSide-text"
  }, [_vm._v(" Introductory package to get you going ")]), _c('div', {
    staticClass: "newPackagesModal-package-features d-flex align-items-center"
  }, [_c('div', {
    staticClass: "package-features-row mt-16px mr-32px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "packages-feature-roundTick-icon",
      "width": "20px",
      "height": "20px"
    }
  }), _c('div', {
    staticClass: "package-features-row-text"
  }, [_vm._v("Realtime notifications")])], 1), _c('div', {
    staticClass: "package-features-row mt-16px mr-32px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "packages-feature-roundTick-icon",
      "width": "20px",
      "height": "20px"
    }
  }), _c('div', {
    staticClass: "package-features-row-text"
  }, [_vm._v("Chat and video calling")])], 1), _c('div', {
    staticClass: "package-features-row mt-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "packages-feature-roundTick-icon",
      "width": "20px",
      "height": "20px"
    }
  }), _c('div', {
    staticClass: "package-features-row-text"
  }, [_vm._v("Task Management")])], 1)])])]) : _vm._e(), _c('div', {
    staticClass: "w-100 d-flex justify-content-center mt-20px"
  }, [_c('div', {
    staticClass: "newPackagesModal-viewDetails-button",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-newPackagesComparisonModal`);
      }
    }
  }, [_vm._v("View more details")])])]], 2)], 1), _c('div', [_c('modal', {
    staticClass: "newPackagesComparisonModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-newPackagesComparisonModal`,
      "clickToClose": true
    }
  }, [_vm.api.packages.send || _vm.api.subscription_detail.send || _vm.api.update_plan.send ? _c('div', {
    staticClass: "newPackagesComparisonModal-container"
  }, [_c('b-icon', {
    staticClass: "newPackagesComparisonModal-closeIcon",
    attrs: {
      "icon": "x-lg"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-newPackagesComparisonModal`);
      }
    }
  }), _c('div', {
    staticClass: "newPackagesComparisonModal-feature-column"
  }, [_c('div', {
    staticClass: "newPackagesComparisonModal-column-header"
  }, [_c('div', {
    staticClass: "newPackagesModal-heading"
  }, [_vm._v("Subscription plan details")]), _c('div', {
    staticClass: "newPackagesComparisonModal-features-heading"
  }, [_vm._v("Detailed pricing overview")])]), _c('div', {
    staticClass: "newPackagesComparisonModal-column-body"
  }, [_vm._l(_vm.products, function (product, index) {
    return _c('div', {
      key: product.value,
      class: `newPackagesComparisonModal-item ${index % 2 == 1 ? 'withStripeBg' : ''}`
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "22px",
        "width": "50%"
      }
    })]);
  }), _vm._l(_vm.features, function (feature, index) {
    return _c('div', {
      key: feature.value,
      class: `newPackagesComparisonModal-item ${index % 2 == 1 ? 'withStripeBg' : ''}`
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "22px",
        "width": "50%"
      }
    })]);
  })], 2)]), _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "newPackagesComparisonModal-package-column"
    }, [_c('div', {
      staticClass: "newPackagesComparisonModal-package-column-header"
    }, [_c('div', {
      staticClass: "newPackagesComparisonModal-package-name w-100 d-flex justify-content-center"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "41.59px",
        "width": "50%"
      }
    })]), _c('div', {
      staticClass: "newPackagesComparisonModal-package-price w-100 d-flex justify-content-center"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "40px",
        "width": "50%"
      }
    })]), _c('div', {
      staticClass: "newPackagesComparisonModal-package-subText w-100 d-flex justify-content-center"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "26px",
        "width": "50%",
        "margin-top": "5.5px"
      }
    })]), _c('div', {
      staticClass: "latestShimmerDesign mt-15px",
      staticStyle: {
        "height": "50px",
        "width": "100%"
      }
    })]), _c('div', {
      staticClass: "newPackagesComparisonModal-column-body"
    }, [_vm._l(_vm.products, function (product, index) {
      return _c('div', {
        key: product.value,
        class: `newPackagesComparisonModal-item ${index % 2 == 1 ? 'withStripeBg' : ''}`
      }, [_c('div', {
        staticClass: "latestShimmerDesign",
        staticStyle: {
          "height": "22px",
          "width": "50%"
        }
      })]);
    }), _vm._l(_vm.features, function (feature, index) {
      return _c('div', {
        key: feature.value,
        class: `newPackagesComparisonModal-item ${index % 2 == 1 ? 'withStripeBg' : ''}`
      }, [_c('div', {
        staticClass: "latestShimmerDesign",
        staticStyle: {
          "height": "22px",
          "width": "50%"
        }
      })]);
    })], 2)]);
  })], 2) : _c('div', {
    staticClass: "newPackagesComparisonModal-container"
  }, [_c('b-icon', {
    staticClass: "newPackagesComparisonModal-closeIcon",
    attrs: {
      "icon": "x-lg"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-newPackagesComparisonModal`);
      }
    }
  }), _c('div', {
    staticClass: "newPackagesComparisonModal-feature-column"
  }, [_c('div', {
    staticClass: "newPackagesComparisonModal-column-header"
  }, [_c('div', {
    staticClass: "newPackagesModal-heading"
  }, [_vm._v("Subscription plan details")]), _c('div', {
    staticClass: "newPackagesComparisonModal-features-heading"
  }, [_vm._v("Detailed pricing overview")])]), _c('div', {
    staticClass: "newPackagesComparisonModal-column-body"
  }, [_vm._l(_vm.products, function (product, index) {
    return _c('div', {
      key: product.value,
      class: `newPackagesComparisonModal-item ${index % 2 == 1 ? 'withStripeBg' : ''}`
    }, [_vm._v(_vm._s(product.label))]);
  }), _vm._l(_vm.features, function (feature, index) {
    return _c('div', {
      key: feature.value,
      class: `newPackagesComparisonModal-item ${index % 2 == 1 ? 'withStripeBg' : ''}`
    }, [_vm._v(_vm._s(feature.label))]);
  })], 2)]), _vm._l(_vm.plans, function (plan, index) {
    return _c('div', {
      key: plan.real_id,
      staticClass: "newPackagesComparisonModal-package-column",
      class: {
        'isCurrentPlan': plan.real_id == _vm.currentPlanId
      }
    }, [_c('div', {
      staticClass: "newPackagesComparisonModal-package-column-header"
    }, [_c('div', {
      staticClass: "newPackagesComparisonModal-package-name"
    }, [_vm._v(_vm._s(plan.name))]), _c('div', {
      staticClass: "newPackagesComparisonModal-package-price"
    }, [_vm._v(_vm._s(_vm._f("hardCodedPricing")(index)))]), _c('div', {
      staticClass: "newPackagesComparisonModal-package-subText"
    }, [_vm._v("per " + _vm._s(plan.interval) + " per user")]), _c('button', {
      class: `newPackagesModal-package-button mt-15px ${plan.real_id == _vm.currentPlanId ? 'active' : ''}`,
      attrs: {
        "disabled": plan.real_id == _vm.currentPlanId
      },
      on: {
        "click": function ($event) {
          return _vm.updatePlan(plan);
        }
      }
    }, [plan.real_id == _vm.currentPlanId ? [_vm._v("Current plan")] : [_vm._v("Choose plan")]], 2)]), _c('div', {
      staticClass: "newPackagesComparisonModal-column-body"
    }, [_vm._l(_vm.products, function (product, index) {
      return _c('div', {
        key: product.value,
        class: `newPackagesComparisonModal-item ${index % 2 == 1 ? 'withStripeBg' : ''}`
      }, [_vm._v(_vm._s(_vm._f("checkProductAllowed")(plan, product.value)))]);
    }), _vm._l(_vm.features, function (feature, index) {
      return _c('div', {
        key: feature.value,
        class: `newPackagesComparisonModal-item ${index % 2 == 1 ? 'withStripeBg' : ''}`
      }, [_c('b-icon', {
        attrs: {
          "icon": _vm._f("checkFeatureAllowed")(plan, feature.value),
          "variant": "primary"
        }
      })], 1);
    })], 2)]);
  })], 2)])], 1), _c('UpdatePlanComparisonsListModal', {
    attrs: {
      "subscription_detail": _vm.response.subscription_detail,
      "allAddons": _vm.allAddons
    },
    on: {
      "delete-service": function ($event) {
        return _vm.$emit('delete-service');
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }