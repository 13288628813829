<template>
  <modal class="dialer_animation right_side_popup developerOptionsModal" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    <div class="dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between">
      <h2 class="dialer-edit-title newer mb-0">Features</h2>
      <div class="dialer-edit-actions d-flex justify-content-end">
        <button class="newCloseButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    <div style="max-height: calc(100vh - 120px);overflow-y: auto;height: calc(100vh - 120px);">
      <div class="dialer-login-form">
        <b-form @submit.prevent="check()">
          <div class="dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 w-100">
            <div class="wrap w-100">
              <div class="signupLoginV2-section-2-right w-100 pr-3">
                <div class="latestGreyBox-9-9-2023 mt-20px">
                  <div class="whiteBGinputWithGreyRoundedBorder d-flex justify-content-end mt-20px">
                    <input type="text" class="w-50" placeholder="Search..." v-model="filters.features.search" />
                  </div>
                  <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none">
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">All</div>
                      <div class="whiteBGinputWithGreyRoundedBorder text-left dropdownVersion wd-33-imp">
                        <b-form-select 
                          class="w-100" 
                          v-model="allFeatures" 
                          :options="featuresOptions" 
                        />
                      </div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none" v-for="key in filterFeatures" :key="key">
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">{{key}}</div>
                      <div class="whiteBGinputWithGreyRoundedBorder text-left dropdownVersion wd-33-imp">
                        <b-form-select 
                          class="w-100" 
                          v-model="$store.state.common.features[key]" 
                          :options="featuresOptions" 
                        />
                      </div>
                      <div class="d-flex wd-33-imp justify-content-end">
                        <b-icon font-scale="1.6" v-if="$store.state.common.features[key]=='yes'" icon="check-square-fill" v-b-popover.hover.top="'Locally'" />
                        <b-icon font-scale="1.6" v-else-if="$store.state.common.features[key]=='no'" icon="exclamation-square-fill" v-b-popover.hover.top="'Locally'" />
                        <b-icon font-scale="1.6" v-else icon="square" v-b-popover.hover.top="'Locally'" />
                        <b-icon font-scale="1.6" v-if="getUserAllowedFeatures.includes(`yes|${key}`)" icon="check-square-fill" class="ml-1" v-b-popover.hover.top="'User Allowed'" />
                        <b-icon font-scale="1.6" v-else-if="getUserAllowedFeatures.includes(`no|${key}`)" icon="exclamation-square-fill" class="ml-1" v-b-popover.hover.top="'User Allowed'" />
                        <b-icon font-scale="1.6" v-else icon="square" class="ml-1" v-b-popover.hover.top="'User Allowed'" />
                        <b-icon font-scale="1.6" v-if="$store.state.common.user.appMode=='standalone'" icon="check-square-fill" class="ml-1" v-b-popover.hover.top="'Stand alone'" />
                        <b-icon font-scale="1.6" v-else icon="square" class="ml-1" v-b-popover.hover.top="'Stand alone'" />
                        <b-icon font-scale="1.6" v-if="getUserPlanFeatures.includes(key)" icon="check-square-fill" class="ml-1" v-b-popover.hover.top="'Plan'" />
                        <b-icon font-scale="1.6" v-else icon="square" class="ml-1" v-b-popover.hover.top="'Plan'" />
                        <b-icon font-scale="1.6" 
                          v-if="
                            $store.state.common.features[key]=='yes' ? true :
                            $store.state.common.features[key]=='no' ? false :
                            getUserAllowedFeatures.includes(`no|${key}`) ? false :
                            getUserAllowedFeatures.includes(`yes|${key}`) ? true :
                            $store.state.common.user.appMode=='standalone' ? true :
                            getUserPlanFeatures.includes(key) ? true :
                            false
                          " 
                          icon="check-square-fill" 
                          class="ml-1"
                          v-b-popover.hover.top="'Overall'"
                        />
                        <b-icon font-scale="1.6" v-else icon="square" class="ml-1" v-b-popover.hover.top="'Overall'" />
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
const features_options = [
  {
    text:'Enabled',
    value: 'yes'
  },
  {
    text:'Disable',
    value: 'no'
  },
  {
    text:'Default',
    value: 'default'
  }
]
export default {
  name: 'DeveloperFeaturesModal',
  props: {
    modalName: {
      type: String,
      default: 'DeveloperFeatures',
    },
  },
  data(){
    return {
      filters: {
        features: {
          search: '',
        },
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getUserPlanFeatures',
      'getUserAllowedFeatures',
    ]),
    allFeatures: {
      get(){ 
        const is_all_yes = Object.values(this.$store.state.common.features).every(i=>i=='yes') 
        const is_all_no = Object.values(this.$store.state.common.features).every(i=>i=='no') 
        const is_all_default = Object.values(this.$store.state.common.features).every(i=>i=='default') 
        return is_all_yes ? 'yes' : is_all_no ? 'no' : is_all_default ? 'default' : '';
      },
      set(value){ 
        if(value){
          Object.keys(this.$store.state.common.features).forEach((key)=>{
            this.$store.state.common.features[key]=value
          })
        }
      },
    },
    filterFeatures(){ return Object.keys(this.$store.state.common.features).filter(i=>i.includes(this.filters.features.search)) },
    featuresOptions(){ return features_options },
  },
  methods: {
    onBeforeOpen(){
      // TODO - 
    },
    onBeforeClose(){
      // TODO - 
    },
  },
}
</script>

<style>

</style>