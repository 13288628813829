var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading"
  }, [_vm._v("Terminated employees")]), _c('div', {
    staticClass: "mainDescription"
  }, [_vm._v(" Below is a list of all 1 terminated employees. ")]), _c('div', {
    staticClass: "TerminatedEmployeesTable mt-32px"
  }, [_vm._m(0), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-textContainer"
        }, [_c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")]), _c('div', {
          staticClass: "nameWithImage-textSmall"
        }, [_vm._v("8001")])])])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v(" 13/09/2022 ")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_vm._v(" 8 years 5 months 19 days ")]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_vm._v(" Retirement ")]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_c('b-dropdown', {
          staticClass: "ActionsDD",
          attrs: {
            "no-caret": "",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "button-content"
              }, [_c('b-icon', {
                staticClass: "downIcon",
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })], 1)];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', [_vm._v(" All teams ")])], 1)], 1)])];
      },
      proxy: true
    }])
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("EMPLOYEE")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("LAST WORKING DAY")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("LENGTH OF SERVICE")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("REASON")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("TEAM")])]);

}]

export { render, staticRenderFns }