var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Call recording")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('section', {
    staticClass: "dialer-settings-section dialer-callRecording-settings"
  }, [[_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "disabled": !_vm.getUserPermissions.call_recording,
      "type": "text",
      "placeholder": "Search numbers"
    },
    model: {
      value: _vm.filter.call_recordings.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.call_recordings, "search", $$v);
      },
      expression: "filter.call_recordings.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1)], _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.filterCallRecording, function (user) {
    return _c('div', {
      key: user.account,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.checkPermission(_vm.getUserPermissions.call_recording, function () {
            return _vm.$modal.show('CallRecordingEditModal', {
              account: user.account,
              allow_recording: user.allow_recording,
              setting: user.auto == 1 ? 'auto' : user.ondemand == 1 ? 'ondemand' : 'off'
            });
          });
        }
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "name": user.user,
        "is_blf": false
      }
    })], 1), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide mr-6px"
    }, [_c('vb-icon', {
      staticClass: "noRect",
      attrs: {
        "icon": _vm._f("filterIcon")(user),
        "height": "20",
        "width": _vm._f("filterIconWidth")(user)
      }
    })], 1), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)]);
  }), 0), _c('InfiniteLoading', {
    ref: "call_recordings_infinity_loader",
    on: {
      "infinite": function ($event) {
        return _vm.fetchCallRecordings();
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (_ref) {
        var trigger = _ref.trigger;
        return [_c('div', [_c('span', [_vm._v(_vm._s(_vm.api.call_recordings.error_message))]), _c('b-icon', {
          staticClass: "ml-3",
          attrs: {
            "icon": "arrow-repeat",
            "variant": "primary",
            "font-scale": "1.5"
          },
          on: {
            "click": function ($event) {
              return trigger();
            }
          }
        })], 1)];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "spinner"
    },
    slot: "spinner"
  }, [_c('vb-loading', {
    attrs: {
      "type": "loader"
    }
  })], 1), _c('div', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }), _c('div', {
    attrs: {
      "slot": "no-results"
    },
    slot: "no-results"
  }, [_c('vb-no-record', {
    attrs: {
      "text": 'There is no Numbers',
      "design": 3
    }
  })], 1)])], 2), _c('StorageModal'), _c('CallRecordingEditModal', {
    on: {
      "latest": function ($event) {
        return _vm.fetchCallRecordings(true);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }