<template>
  <div>
    <modal
      :name="modalName"
      class="EditSpaceModal smallerModal"
      width="50%"
      height="auto"
      :scrollable="true"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-header w-100 d-flex position-relative py-0 justify-content-between align-items-center">
        <h2 class="dialer-edit-title my-0">
          <template v-if="conditions.screen==screens.space">Move task</template>
          <template v-if="conditions.screen==screens.status">Task status</template>
          <template v-if="conditions.screen==screens.group">Task group</template>
        </h2>
        <div class="dialer-edit-actions">
          <button class="newCloseButton" @click="$modal.hide(modalName)" type="button" >
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div class="createSpace-greyBox mt-50px">
        <div v-if="conditions.screen==screens.space" class="createSpace-whiteBorderedBox">
          <b-form-radio-group v-model="selected.space_id" name="radio-sub-component">
            <b-form-radio v-for="space in response.spaces"  :key="space.id" class="radio_btn_like_checkbox makeCheckBoxesSquare createSpace-whiteBorderedBox-row" :value="space.id">
              <div class="createSpace-whiteBorderedBox-row" >
                <div class="d-flex align-items-center">
                  <div class="createSpace-selectColor-container w-auto mr-3 ml-0 my-0">
                    <div 
                      class="createSpace-selectColor-item smaller selected"
                      :class="{
                        'TransparentSelected':  'rgba(0,0,0,0)'
                      }"
                      :style="`background-color:${space.color};`"
                    />
                  </div>
                  <div class="TODO-smallInnerHeading ">{{space.name}}</div>
                </div>
              </div>
            </b-form-radio>
          </b-form-radio-group>
          <vb-no-record class="my-4" v-if="response.spaces.length==0" :text="api.spaces.send?'':'There is no space'" :design="3">
            <!-- <vb-loading v-if="api.spaces.send" slot="loading" /> -->
            <div v-if="api.spaces.send" slot="loading">
              <div class="createSpace-whiteBorderedBox-row" v-for="n in 5" :key="n.id">
                <div class="latestShimmerDesign w-25" style="height:24px;"></div>
                <div class="latestShimmerDesign w-25" style="height:24px;"></div>
              </div>
            </div>
          </vb-no-record>
        </div>
        <div v-else-if="conditions.screen==screens.status" class="createSpace-whiteBorderedBox">
          <b-form-radio-group v-model="selected.status_id" :disabled="api.move_task.send" name="radio-sub-component">
            <b-form-radio v-for="status in response.status" :key="status.id" class="radio_btn_like_checkbox makeCheckBoxesSquare createSpace-whiteBorderedBox-row" :value="status.id">
              <div class="createSpace-whiteBorderedBox-row" >
                <div class="d-flex align-items-center">
                  <div class="createSpace-selectColor-container w-auto mr-3 ml-0 my-0">
                    <div 
                      class="createSpace-selectColor-item smaller selected"
                      :class="{
                        'TransparentSelected':  'rgba(0,0,0,0)'
                      }"
                      :style="`background-color:${status.color};`"
                    />
                  </div>
                  <div class="TODO-smallInnerHeading ">{{status.title}}</div>
                </div>
              </div>
            </b-form-radio>
          </b-form-radio-group>
          <vb-no-record v-if="response.status.length==0" :text="api.status.send?'':'There is no status'" :design="3">
            <!-- <vb-loading v-if="api.status.send" slot="loading" /> -->
            <div v-if="api.status.send" slot="loading">
              <div class="createSpace-whiteBorderedBox-row" v-for="n in 5" :key="n.id">
                <div class="latestShimmerDesign w-25" style="height:24px;"></div>
                <div class="latestShimmerDesign w-25" style="height:24px;"></div>
              </div>
            </div>
          </vb-no-record>
        </div>
        <div v-else-if="conditions.screen==screens.group" class="createSpace-whiteBorderedBox">
          <b-form-radio-group v-model="selected.group_id" :disabled="api.move_task.send" name="radio-sub-component">
            <b-form-radio v-for="groups in response.groups"  :key="groups.id" class="radio_btn_like_checkbox makeCheckBoxesSquare createSpace-whiteBorderedBox-row" :value="groups.id">
              <div class="createSpace-whiteBorderedBox-row" >
                <div class="d-flex align-items-center">
                  <div class="createSpace-selectColor-container w-auto mr-3 ml-0 my-0">
                    <div 
                      class="createSpace-selectColor-item smaller selected"
                      :class="{
                        'TransparentSelected':  'rgba(0,0,0,0)'
                      }"
                      :style="`background-color:${groups.color};`"
                    />
                  </div>
                  <div class="TODO-smallInnerHeading ">{{groups.name}}</div>
                </div>
              </div>
            </b-form-radio>
          </b-form-radio-group>
          <vb-no-record v-if="response.groups.length==0" :text="api.groups.send?'':'There is no group'" :design="3">
            <!-- <vb-loading v-if="api.groups.send" slot="loading" /> -->
            <div v-if="api.groups.send" slot="loading">
              <div class="createSpace-whiteBorderedBox-row" v-for="n in 5" :key="n.id">
                <div class="latestShimmerDesign w-25" style="height:24px;"></div>
                <div class="latestShimmerDesign w-25" style="height:24px;"></div>
              </div>
            </div>
          </vb-no-record>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between mt-38px">
        <div 
          class="d-flex align-items-center"
          style="cursor:pointer"
          v-if="data.select=='space' && conditions.screen!=screens.space" :disabled="api.move_task.send" @click="onBack()"
        >
          <b-icon icon="chevron-left" class="darker-chevron-down mx-0" />
          <b class="darker-chevron-down ml-1">Back</b>
        </div>
        <b-button @click="onNext()" class="newDoneButton ml-auto">
          <vb-icon icon="squared-done-icon" height="38px" width="38px" />
          <span class="newDoneButton-textPart">
            <template v-if="conditions.screen==screens.group">Save</template>
            <template v-else>Next</template>
          </span>
        </b-button>
      </div>




      <!--<template v-if="false">
        <b-button :disabled="api.move_task.send" @click="$modal.hide(modalName)">Close</b-button>
        <b-button v-if="data.select=='space'" :disabled="api.move_task.send" @click="onBack()">Back</b-button>
        <b-button @click="onNext()">Next</b-button>
        <div v-if="conditions.screen==screens.space">
          <b-form-radio-group v-model="selected.space_id" :options="response.spaces" :disabled="api.move_task.send" value-field="id" text-field="name" />
          <vb-no-record v-if="response.spaces.length==0" :text="api.spaces.send?'':'There is no Space'" :design="3">
            <vb-loading v-if="api.spaces.send" slot="loading" />
          </vb-no-record>
        </div>
        <div v-else-if="conditions.screen==screens.status">
          <b-form-radio-group v-model="selected.status_id" :options="response.status" :disabled="api.move_task.send" value-field="id" text-field="title" />
          <vb-no-record v-if="response.status.length==0" :text="api.status.send?'':'There is no Status'" :design="3">
            <vb-loading v-if="api.status.send" slot="loading" />
          </vb-no-record>
        </div>
        <div v-else-if="conditions.screen==screens.group">
          <b-form-radio-group v-model="selected.group_id" :options="response.groups" :disabled="api.move_task.send" value-field="id" text-field="name" />
          <vb-no-record v-if="response.groups.length==0" :text="api.groups.send?'':'There is no Group'" :design="3">
            <vb-loading v-if="api.groups.send" slot="loading" />
          </vb-no-record>
        </div>
      </template>-->
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
let screens = {
  space: 'space',
  status: 'status',
  group: 'group',
}
export default {
  name: 'SelectSpaceGroupAndStatusModal',
  props: {
    modalName: {
      type: String,
      default: 'SelectSpaceGroupAndStatus'
    },
  },
  data() {
    return {
      api: {
        spaces: this.$helperFunction.apiInstance(),
        groups: this.$helperFunction.apiInstance(),
        status: this.$helperFunction.apiInstance(),
        move_task: this.$helperFunction.apiInstance(),
      },
      selected: {
        space_id: '',
        status_id: '',
        group_id: '',
      },
      response: {
        spaces: [],
        groups: [],
        status: [],
      },
      data: {
        select: 'space',
        payload: {},
      },
      conditions: {
        screen: screens.space,
        moved: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    screens(){ return screens },
  },
  methods: {
    onBeforeOpen(event){
      this.data.select=event?.params?.select ?? 'space'
      this.data.moved=!!event?.params?.moved
      this.data.payload=event?.params?.payload ?? {}
      if(this.data.select=='status'){
        this.selected.space_id=event?.params?.space_id
        this.conditions.screen=screens.status
        this.getStatus()
      } else if(this.data.select=='group'){
        this.selected.space_id=event?.params?.space_id
        this.conditions.screen=screens.group
        this.getGroups()
      } else {
        this.getSpaces()
      }
    },
    onBeforeClose(){
      this.api.groups.reset()
      this.api.spaces.reset()
      this.api.status.reset()
      this.api.move_task.reset()
      this.selected.group_id=''
      this.selected.space_id=''
      this.selected.status_id=''
      this.response.groups=[]
      this.response.spaces=[]
      this.response.status=[]
      this.data.payload={}
      this.conditions.screen=screens.space
    },
    onNext(){
      if(this.data.select=='space'){
        if(this.conditions.screen==screens.space){
          if(!this.selected.space_id){
            this.appNotify({
              message: 'Select Space',
              type: 'danger',
            })
          } else {
            this.getStatus()
            this.conditions.screen=screens.status
          }
        } else if(this.conditions.screen==screens.status){
          if(!this.selected.status_id){
            this.appNotify({
              message: 'Select Status',
              type: 'danger',
            })
          } else {
            this.getGroups()
            this.conditions.screen=screens.group
          }
        } else if(this.conditions.screen==screens.group){
          this.onSelected({
            status_id: this.selected.status_id,
            group_id: this.selected.group_id,
            space_id: this.selected.space_id,
            payload: this.data.payload
          })
        }
      } else if(this.data.select=='group') {
        this.onSelected({
          group_id: this.selected.group_id,
          payload: this.data.payload
        })
      } else if(this.data.select=='status') {
        if(!this.selected.status_id){
          this.appNotify({
            message: 'Select Status',
            type: 'danger',
          })
        } else {
          this.onSelected({
            status_id: this.selected.status_id,
            payload: this.data.payload
          })
        }
      }
    },
    onBack(){
      if(this.conditions.screen==screens.group){
        this.selected.group_id=''
        this.conditions.screen=screens.status
      } else if(this.conditions.screen==screens.status){
        this.selected.status_id=''
        this.conditions.screen=screens.space
      }
    },
    onSelected(obj={}){
      if(this.data.moved){
        this.moveTask(obj)
      } else {
        this.$emit('selected',obj)
        this.$modal.hide(this.modalName)
      }
    },
    async moveTask(event){
      function getRequestBody(data){
        const space_id = data?.space_id
        const status_id = data?.status_id
        const group_id = data?.group_id
        const payload = data?.payload
        const flag = payload?.flag
        let obj={}
        if(flag=='task'){
          const task_id = payload?.task_id
          obj={
            task_id,
            space_id,
            status_id,
            group_id,
            flag,
          }
        } else if(flag=='space'){
          const current_space_id = payload?.space_id
          obj={
            current_space_id,
            space_id,
            status_id,
            group_id,
            flag
          }
        } else if(flag=='group'){
          const current_group_id = payload?.group_id
          obj={
            current_group_id,
            group_id,
            flag
          }
        } else if(flag=='status'){
          const current_status_id = payload?.status_id
          obj={
            current_status_id,
            status_id,
            flag
          }
        }
        return obj
      }
      if(this.api.move_task.send) return;
      try {
        this.api.move_task.send=true
        await VOIP_API.endpoints.tasklist.moveTask(getRequestBody(event))
        this.$emit('moved',event)
        this.$modal.hide(this.modalName)
        this.appNotify({
          message: 'Successfully moved',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.move_task.send=false
      }
    },
    async getSpaces() {
      if(this.api.spaces.send) return;
      try {
        this.api.spaces.send=true
        const { data } = await VOIP_API.endpoints.task_spaces.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        })
        this.response.spaces=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.spaces.send=false  
      }
    },
    async getGroups(){
      if(this.api.groups.send) return;
      try {
        this.api.groups.send=true
        const { data: { data } } = await VOIP_API.endpoints.taskgroups.list({
          uid: this.getCurrentUser.uid,
          space_id: this.selected.space_id,
        })
        this.response.groups = data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.groups.send=false
      }
    },
    async getStatus(){
      if(this.api.status.send) return;
      try {
        this.api.status.send=true
        const { data } = await VOIP_API.endpoints.task_status.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          space_id: this.selected.space_id,
        })
        this.response.status = data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.status.send=false
      }
    },
  },
}
</script>

<style>

</style>