var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Annual individual allowances "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('EditIndividualAllowanceModal');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "pencil-hrmIcon",
      "height": "14px",
      "width": "14px"
    }
  }), _vm._v(" Edit ")], 1)]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Review the amount of leave allocated to individual employees. ")]), _c('div', {
    staticClass: "IndividualAllowancesTable mt-32px"
  }, [_vm._m(0), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterAllowances, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.user,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "nameWithImage"
          }, [_c('div', {
            staticClass: "imageContainer"
          }, [_c('img', {
            attrs: {
              "src": require('@/assets/images/credit-card/1.jpeg')
            }
          })]), _c('div', {
            staticClass: "nameWithImage-text"
          }, [_vm._v(_vm._s(data.name))])])]), _c('td', {
            staticClass: "dialer-row-title two"
          }, [_vm._v(_vm._s(data.holiday))]), _c('td', {
            staticClass: "dialer-row-title three"
          }, [_vm._v(_vm._s(data.sickday))]), _c('td', {
            staticClass: "dialer-row-title four"
          }, [_vm._v(_vm._s(data.holidayUk))]), _c('td', {
            staticClass: "dialer-row-title five"
          }, [_vm._v(_vm._s(data.holidayUsa))]), _c('td', {
            staticClass: "dialer-row-title six"
          }, [_vm._v(_vm._s(data.holidayPak))]), _c('td', {
            staticClass: "dialer-row-title seven"
          }, [_vm._v(_vm._s(data.businessTrip))])]);
        });
      }
    }])
  })], 1), _c('EditIndividualAllowanceModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Employee")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("Holiday")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Sickday")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("Holiday-UK")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Holiday-USA")]), _c('div', {
    staticClass: "HrmTableHead six"
  }, [_vm._v("Holiday-pAK")]), _c('div', {
    staticClass: "HrmTableHead seven"
  }, [_vm._v("Business trip")])]);

}]

export { render, staticRenderFns }