
import { VOIP_API } from "../index"

export const EXTENSIONS_API_ENDPOINTS = {
  history(params={}){
    return VOIP_API.axios.voip.request({
      url: `get-extn-history`,
      method: 'GET',
      params,
    })
  },
  deleteExtension(data={}){
    return VOIP_API.axios.voip.request({
      url: `delete-mobile-extension`,
      method: 'POST',
      data
    })
  },
  updatelabel(data){
    return VOIP_API.axios.voip.request({
      url: `edit-extension-name`,
      method: 'POST',
      data
    })
  },
  extensionLogout(extension_accountcode,data){
    return VOIP_API.axios.voip.request({
      url: `mobileextensions/${extension_accountcode}/logout`,
      method: 'POST',
      data
    })
  },
  updateSecret(data){
    return VOIP_API.axios.voip.request({
      url: 'update-extension-secret',
      method: 'POST',
      data
    })
  },
  mobileextensions(account){
    return VOIP_API.axios.voip.request({
      url: `mobileextension/${account}`,
      method: 'GET',
    })
  },
  getallextensions(){
    return VOIP_API.axios.voip.request({
      url: `getallextensions`,
      method: 'GET',
    })
  },

  
}