<template>
  <div>
    <div class="mainHeading withButton">
      Teams
      <button @click="$modal.show('createNewTeamEmployeeData')" class="dialer-button dialer-button-primary mr-0 withIcon">
        <!-- <vb-icon icon="pencil-hrmIcon" class="mr-16px fillWhite" height="14px" width="14px" /> -->
        <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
        Add new
      </button>
    </div>
    <div class="mainDescription mb-4">
      <!-- Employees in this group can see all fields, edit all fields, and do everything the system offers. Be careful with granting this access level! -->
    </div>
    <div class="documentsModule-Table mt-32px">
      <vb-table class="HrmTable">
        <template slot="header">
          <tr>
            <th class="HrmTableHead text-left one pt-1">Title</th>
            <th class="HrmTableHead text-left two pt-1">Employees</th>
            <th class="HrmTableHead text-left three pt-1">Managers</th>
            <th class="HrmTableHead text-left four pt-1">Actions</th>
          </tr>
        </template>
        <template #body>
          <tr v-for="n in 6" :key="n" class="dialer-row-select" >
            <td class="dialer-row-title one">Amsterdam - leadership</td>
            <td class="dialer-col-right text-left two">2</td>
            <td class="dialer-col-right text-left three">2</td>
            <td class="dialer-col-right text-left four">
              <b-dropdown no-caret right class="ActionsDD">
                <template #button-content>
                  <div class="button-content">
                    <b-icon class="downIcon" icon="three-dots-vertical" />
                  </div>
                </template>
                <b-dropdown-item >
                  Actions
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    <createNewTeamEmployeeData />
  </div>
</template>

<script>
import createNewTeamEmployeeData from '@/components/Modals/HRM/settings/EmployeeData/createNewTeamEmployeeData.vue';
export default {
  name: 'EmployeeDataTeams',
  components: {
    createNewTeamEmployeeData,
  },
}
</script>

<style>

</style>