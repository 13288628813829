var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _c('span', {
    staticClass: "dialer-edit-title md-mar-left"
  }, [_vm._v("Select account")])], 1)]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.onDone();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Apply")])], 1)])]), _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_c('div', {
    staticClass: "w-100 md-mar-bottom whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Search sound"
    },
    model: {
      value: _vm.filter.accounts.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.accounts, "search", $$v);
      },
      expression: "filter.accounts.search"
    }
  })], 1), _c('ul', {
    staticClass: "dialer-asssignee-list"
  }, _vm._l(_vm.filterAccounts, function (account) {
    return _c('li', {
      key: account.accountcode,
      staticClass: "dialer-assignee dialer-assignee-new"
    }, [_c('b-icon', {
      staticClass: "mr-3 ml-0",
      attrs: {
        "icon": _vm.selected.account.accountcode === account.accountcode ? 'check-circle-fill' : 'circle',
        "variant": "primary",
        "font-scale": "1.3"
      },
      on: {
        "click": function ($event) {
          _vm.selected.account = account;
        }
      }
    }), _c('Info', {
      attrs: {
        "id": account.accountcode,
        "image": '',
        "name": account.accountname,
        "sub_info": account.extn
      }
    })], 1);
  }), 0)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }