<template>
  <div>
    
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.add_manual_number.send?'':$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add Manual Number</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">

        <form class="dialer-form lg-mar-bottom" @submit.prevent="addManualNumber()">
          <b-alert class="mt-32px" :show="!!api.add_manual_number.error_message" variant="danger">{{api.add_manual_number.error_message}}</b-alert>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Number</label>
                <input 
                  placeholder="Enter Number" 
                  class="w-100" 
                  :disabled="api.add_manual_number.send" 
                  min="1" 
                  type="number" 
                  onkeypress="return /([0-9])/i.test(event.key)" 
                  v-model="forms.add_manual_number.did"
                />
                <p 
                  v-if="(forms.add_manual_number.submitted && $v.forms.add_manual_number.did.$invalid) || api.add_manual_number.validation_errors.did" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.add_manual_number.did.required">* Number is required</span>
                  <span v-else-if="!$v.forms.add_manual_number.did.minValue">* Number is minimum 1</span>
                  <span v-for="error_number in api.add_manual_number.validation_errors.did" :key="error_number">* {{ error_number }}</span>
                </p>
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Description (Optional)</label>
                <textarea 
                  placeholder="Enter Description" 
                  class="w-100" 
                  :disabled="api.add_manual_number.send" 
                  v-model="forms.add_manual_number.label"
                />
                <p 
                  v-if="api.add_manual_number.validation_errors.label" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-for="error_number in api.add_manual_number.validation_errors.label" :key="error_number">* {{ error_number }}</span>
                </p>
              </div>
            </div>
          </div>
          <button class="IosFullWidthButton forDone mb-16px" :disabled="api.add_manual_number.send">
            <vb-spinner v-if="api.add_manual_number.send" />
            <template v-else>Add</template>
          </button>
        </form>

      </div>
    </modal>
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils/index';
export default {
  name: "AddManualNumberModal",
  props: {
    modalName: {
      type: String,
      default: 'AddManualNumber'
    },
  },
  data(){
    return {
      forms: {
        add_manual_number: this.$helperFunction.formInstance({
          data: {
            did: '',
            label: '',
          },
        }),
      },
      api: {
        add_manual_number: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ])
  },
  validations: {
    forms: {
      add_manual_number: {
        did: {
          required,
          minValue: minValue(1),
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      // TODO
    },
    onBeforeClose(){
      this.api.add_manual_number.reset()
      this.forms.add_manual_number.reset()
    },
    addManualNumber(){
      let vm = this
      vm.forms.add_manual_number.submitted=true
      vm.$v.forms.add_manual_number.$touch()
      if(vm.$v.forms.add_manual_number.$invalid || vm.api.add_manual_number.send) return;
      vm.api.add_manual_number.send=true
      vm.api.add_manual_number.error_message=''
      vm.api.add_manual_number.validation_errors={}
      VOIP_API.endpoints.telephone_number.manualnumber.create({
        accountcode: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
        did: vm.forms.add_manual_number.did,
        label: vm.forms.add_manual_number.label,
      })
      .then(()=>{
        vm.$modal.hide(vm.modalName)
        vm.$emit('added')
      })
      .catch((ex)=>{
        vm.api.add_manual_number.error_message=ex.own_message
        vm.api.add_manual_number.validation_errors=ex.own_errors
      })
      .finally(()=>{
        vm.api.add_manual_number.send=false
        vm.forms.add_manual_number.submitted=false
      })
    },
  },
};
</script>
