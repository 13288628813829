<template>
  <div class="panel chat-panel h-100">
    <div 
      :class="{
        'panel-inner': true, 
        'panel-listView': GET_LOCAL_SETTING_CHAT.appearence.list_view === 'accepted', 
        'panel-withoutIcon': GET_LOCAL_SETTING_CHAT.appearence.is_icon_show === 'not_accepted', 
      }"
    >
      
      <h2 class="d-flex flex-wrap align-items-center justify-content-between">
        
        <span class="chat-sidebar-Title">Chat</span>
        
        <div class="dialer-dropdown">
          <b-dropdown id="dropdown-form" menu-class="latestDesignMenuClass" text="appearance" right ref="dropdown" class="m-2">
            <template #button-content>
              <span class="sm-mar-right">Appearance</span>
              <vb-svg class="callActivity-AdjustDesign-v3"
                name="filter-icon"
                width="19"
                height="18"
                viewBox="0 0 19 18"
                stroke-width="0"
                stroke=""
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
              />
              <span class="sr-only">Appearance</span>
            </template>
            <b-dropdown-form class="p-12px">
              <b-form-checkbox 
                class="makeCheckBoxesSquare" 
                :checked="GET_LOCAL_SETTING_CHAT.appearence.list_view"
                @change="SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW(GET_LOCAL_SETTING_CHAT.appearence.list_view=='accepted'?'not_accepted':'accepted')" 
                name="checkbox-1" 
                value="accepted" 
                unchecked-value="not_accepted"
              >
                Enable compact list view
              </b-form-checkbox>
              <b-form-checkbox 
                class="makeCheckBoxesSquare" 
                :checked="GET_LOCAL_SETTING_CHAT.appearence.is_icon_show"
                @change="SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW(GET_LOCAL_SETTING_CHAT.appearence.is_icon_show=='accepted'?'not_accepted':'accepted')"
                name="checkbox-2" 
                value="accepted" 
                unchecked-value="not_accepted"
              >
                Show user icon
              </b-form-checkbox>
              <b-form-checkbox 
                class="makeCheckBoxesSquare" 
                :checked="GET_LOCAL_SETTING_CHAT.appearence.blink_unread"
                @change="SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD(GET_LOCAL_SETTING_CHAT.appearence.blink_unread=='accepted'?'not_accepted':'accepted')"
                name="checkbox-3" 
                value="accepted" 
                unchecked-value="not_accepted"
              >
                Unread message flashing
              </b-form-checkbox>
            </b-dropdown-form>
          </b-dropdown>
        </div>

      </h2>

      <div class="searchBox mt-2">
        <b-form class="mx-auto position-relative" @submit.prevent="''">
          <div :class="{'chatFocusedSearch-container':is_searching}">
            <!-- <b-form-input v-model="search" placeholder="Users, teams & channels" @focus="is_searching = true" /> -->
            <b-form-input v-model="search" placeholder="Users, teams & groups" @focus="is_searching = true" />
            <b-icon v-if="is_searching" icon="x-circle" scale="1" class="position-absolute cursor_pointer" @click="is_searching = false;search = '';" />
          </div>
        </b-form>
      </div>

      <b-tabs v-if="is_searching" v-model="searchtabIndex">
        <PanelTab 
          :totalUnreadTitle="totalUnread" 
          :totalUnread="totalUnread" 
          :recentChannelsUnreadCount="recentChannelsUnreadCount" 
          :recentTeamsUnreadCount="recentTeamsUnreadCount" 
          :recentUsersUnreadCount="recentUsersUnreadCount" 
          :recent_users="searchedUsers" 
          :recent_teams="searchedTeams" 
          :recent_channels="searchedChannels"  
          :text="`Recent`" 
          @tab="tab = 'Recent'" 
          @on-change-search="search" 
          :recent_type="'separate'"
        >
          <!-- :text="`All Message`" -->
          <Item 
            slot="recent-team-items" 
            v-for="team in searchedTeams" 
            :key="team.id" 
            :type="'team'" 
            :data="team" 
            tabType="searched" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
          <Item 
            slot="recent-user-items" 
            v-for="user in searchedUsers" 
            :key="user.voipaccount" 
            :type="'user'" 
            :data="user" 
            tabType="searched" 
            v-show="$store.getters.getCurrentUser.account != user.voipaccount" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
          <Item 
            slot="recent-channel-items" 
            v-for="channel in searchedChannels" 
            :key="channel.id" 
            :type="'channel'" 
            :data="channel" 
            tabType="searched" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
        </PanelTab>
        <PanelTab 
          :totalUnreadTitle="teamsUnreadCount" 
          :nofav="searchedTeams" 
          :text="`Teams`" 
          @tab="tab = 'Teams'" 
          @on-change-search="search"
        >
          <Item 
            slot="not-fav-items" 
            v-for="team in searchedTeams" 
            :key="team.id" 
            :type="'team'" 
            :data="team" 
            tabType="searched" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
        </PanelTab>
        <PanelTab 
          :totalUnreadTitle="usersUnreadCount" 
          :nofav="searchedUsers" 
          :text="`Users`" 
          @tab="tab = 'Users'" 
          @on-change-search="search"
        >
          <Item 
            slot="not-fav-items" 
            v-for="user in searchedUsers" 
            :key="user.voipaccount" 
            :type="'user'" 
            :data="user" 
            tabType="searched" 
            v-show="$store.getters.getCurrentUser.account != user.voipaccount" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
        </PanelTab>
        <PanelTab 
          :totalUnreadTitle="channelsUnreadCount" 
          :nofav="searchedChannels" 
          :text="`Groups`" 
          @tab="tab = 'Channels'" 
          @on-change-search="search"
        >
          <Item 
            slot="not-fav-items" 
            v-for="channel in searchedChannels" 
            :key="channel.id" 
            :type="'channel'" 
            :data="channel" 
            tabType="searched" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
        </PanelTab>
      </b-tabs>

      <b-tabs v-else v-model="tabsIndex">
        <PanelTab 
          :totalUnreadTitle="totalUnread" 
          :totalUnread="totalUnread" 
          :recentChannelsUnreadCount="recentChannelsUnreadCount" 
          :recentTeamsUnreadCount="recentTeamsUnreadCount" 
          :recentUsersUnreadCount="recentUsersUnreadCount" 
          :recent_users="recentUsers" 
          :recent_teams="recentTeams" 
          :recent_channels="recentChannels" 
          :all_recent="mixupRecents" 
          :text="`Recent`" 
          @tab="tab = 'Recent'" 
          @on-change-search="search" 
        >
          <Item 
            slot="recent-all-items" 
            v-for="data in mixupRecents" 
            :key="data.voipaccount ? data.voipaccount : data.id" 
            :type="data.voipaccount ? 'user' : data.channel_id ? 'team' : 'channel'" 
            tabType="recent" 
            :data="data" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
          <template v-if="GET_LOCAL_SETTING_CHAT.view.recent_type === 'separate'">
            <Item 
              slot="recent-team-items" 
              v-for="team in recentTeams" 
              :key="team.id" 
              :type="'team'" 
              tabType="recent" 
              :data="team" 
              @change-channel-id="$emit('change-channel-id',$event)" 
            />
            <Item 
              slot="recent-user-items" 
              v-for="user in recentUsers" 
              :key="user.voipaccount" 
              :type="'user'" 
              tabType="recent" 
              :data="user" 
              v-show="$store.getters.getCurrentUser.account != user.voipaccount" 
              @change-channel-id="$emit('change-channel-id',$event)" 
            />
            <Item 
              slot="recent-channel-items" 
              v-for="channel in recentChannels" 
              :key="channel.id" 
              :type="'channel'" 
              tabType="recent" 
              :data="channel" 
              @change-channel-id="$emit('change-channel-id',$event)" 
            />
          </template>
        </PanelTab>
        <PanelTab 
          :totalUnreadTitle="teamsUnreadCount" 
          :fav="favoritTeams" 
          :nofav="notFavoriteTeams" 
          :text="`Teams`" 
          tabType="recent" 
          @tab="tab = 'Teams'" 
          @on-change-search="search"
        >
          <Item 
            slot="fav-items" 
            v-for="team in favoritTeams" 
            :key="team.id" 
            :type="'team'" 
            tabType="not recent" 
            :data="team" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
          <Item 
            slot="not-fav-items" 
            v-for="team in notFavoriteTeams" 
            :key="team.id" 
            :type="'team'" 
            tabType="not recent" 
            :data="team" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
        </PanelTab>
        <PanelTab 
          :totalUnreadTitle="usersUnreadCount" 
          :fav="favoritUsers" 
          :nofav="notFavoriteUsers" 
          :text="`Users`" 
          @tab="tab = 'Users'" 
          @on-change-search="search"
        >
          <Item 
            slot="fav-items" 
            v-for="user in favoritUsers" 
            :key="user.voipaccount" 
            :type="'user'" 
            tabType="not recent" 
            :data="user" 
            v-show="$store.getters.getCurrentUser.account != user.voipaccount" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
          <Item 
            slot="not-fav-items" 
            v-for="user in notFavoriteUsers" 
            :key="user.voipaccount" 
            :type="'user'" 
            tabType="not recent" 
            :data="user" 
            v-show="$store.getters.getCurrentUser.account != user.voipaccount" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
        </PanelTab>
        <PanelTab 
          :totalUnreadTitle="channelsUnreadCount" 
          :fav="favoritChannels" 
          :nofav="notFavoriteChannels" 
          isShowAdd 
          :text="`Groups`" 
          @tab="tab = 'Channels'" 
          @add="$modal.show('AddChannelModal')" 
          @on-change-search="search"
        >
          <Item 
            slot="fav-items" 
            v-for="channel in favoritChannels" 
            :key="channel.id" 
            :type="'channel'" 
            tabType="not recent" 
            :data="channel" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
          <Item 
            slot="not-fav-items" 
            v-for="channel in notFavoriteChannels" 
            :key="channel.id" 
            :type="'channel'" 
            tabType="not recent" 
            :data="channel" 
            @change-channel-id="$emit('change-channel-id',$event)" 
          />
        </PanelTab>
      </b-tabs>
      
      
      <AddChannelModal :team_id="$store.getters.getCurrentUser.mm_team_id" @complete="onCompleteAddingChannel" />
    
    </div>
  </div>
</template>

<script>
import AddChannelModal from "../Modals/chat/AddChannelModal.vue";
import Item from "./Item.vue";
import PanelTab from "./PanelTab";
import { events as AppEvents } from "../../utils";
import _ from "lodash";
import { mapGetters, mapMutations } from 'vuex';
import { GET_LOCAL_SETTING_CHAT } from '@/store/helper/getters';
import { SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD, SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW, SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW, SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE } from '@/store/helper/mutations';
export default {
  name: "Panel",
  components: {
    AddChannelModal,
    Item,
    PanelTab,
  },
  props: {
    channelsTab: {
      type: Array,
      default: () => [],
    },
    channel_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      conditions: {
        is_search: false,
        tab_index: 0,
        search_tab_index: 0,
      },
      filter: {
        all: {
          search: '',
        },
      },
      is_searching: false,
      is_icon_show: "accepted",
      list_view: "not_accepted",
      is_mixup_recent: true,
      is_separate_recent: false,
      search: "",
      tabsIndex: 0,
      searchtabIndex: 0,
      tab: "Teams",
      teamFigure: require("@/assets/images/users.svg"),
      teamFigure_new: require("@/assets/images/Teams.svg"),
      channelFigure: require("@/assets/images/channel.svg"),
      channelFigure_new: require("@/assets/images/channel.svg"),
      unread: {
        users: 0,
        teams: 0,
        channels: 0,
      },
      loading: {
        users: false,
        teams: false,
        channels: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_CHAT
    ]),
    totalUnread() {
      return (this.channelsUnreadCount + this.usersUnreadCount + this.teamsUnreadCount)
    },
    //--channels
    channelsSorted(){ 
      const channels_store = this.$store.state.mattermost.channels
      return (this.channelsTab ?? []).sort((a,b)=>((channels_store?.[b?.id]?.posts?.posts?.[channels_store?.[b?.id]?.posts?.order?.[(channels_store?.[b?.id]?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0) - (channels_store?.[a?.id]?.posts?.posts?.[channels_store?.[a?.id]?.posts?.order?.[(channels_store?.[a?.id]?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0)))
      .sort((a)=>{ if((!_.isEmpty(channels_store?.[a?.id]?.actions?.message) || !_.isEmpty(channels_store?.[a?.id]?.actions?.files)) && !this.$store.state.mattermost.channels_view.includes(a?.id)) return -1 })
    },
    favoritChannels() {
      return this.channelsSorted.filter((channel) => this.$store.getters.getFavoriteChannelIds.indexOf(channel.id) >= 0);
    },
    notFavoriteChannels() {
      return this.channelsSorted.filter((channel) => this.$store.getters.getFavoriteChannelIds.indexOf(channel.id) === -1);
    },
    recentChannels() {
      if (!this.$store.state.mattermost.user || !this.$store.state.mattermost.user.props || !this.$store.state.mattermost.user.props.recent_channels) return [];
      const recent_channels = JSON.parse(this.$store.state.mattermost.user.props.recent_channels);
      const recent_channelss = this.channelsSorted.filter((channel) => recent_channels[channel.id]);
      const recent_channelss_value = recent_channelss.map((chanenl) => {
        return {
          ...chanenl,
          recent_time: recent_channels[chanenl.id],
        };
      });
      return _.orderBy(recent_channelss_value, "recent_time", "desc");
    },
    recentChannelsUnreadCount() {
      const channels_store = this.$store.state.mattermost.channels; 
      return [...this.recentChannels].reduce((c,v)=>c+=channels_store?.[v.id]?.unread ?? 0,0)
    },
    favRecentChannels() {
      return this.recentChannels.filter((channel) => this.$store.getters.getFavoriteChannelIds.indexOf(channel.id) >= 0);
    },
    notFavRecentChannels() {
      return this.recentChannels.filter((channel) => this.$store.getters.getFavoriteChannelIds.indexOf(channel.id) === -1);
    },
    searchedChannels() {
      const search = this.search.toLowerCase().trim()
      if (!search) return [];
      return this.channelsSorted.filter((channel) => `${channel.display_name}`.toLowerCase().trim().includes(search));
    },
    channelsUnreadCount() {
      const channels_store = this.$store.state.mattermost.channels;
      return [...this.favoritChannels,...this.notFavoriteChannels].reduce((c,v)=> c+=channels_store?.[v.id]?.unread ?? 0,0)
    },
    //----
    //--teams
    teamsSorted(){ 
      const channels_store = this.$store.state.mattermost.channels
      return this.$store.getters.getVoipTeams.filter((teamMembers) => ( teamMembers?.members?.some?.((member) => ( member?.accountcode === this.$store.getters.getCurrentUser?.account ?? false )) ))
      ?.sort?.((a,b)=>((channels_store?.[b?.channel_id]?.posts?.posts?.[channels_store?.[b?.channel_id]?.posts?.order?.[(channels_store?.[b?.channel_id]?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0) - (channels_store?.[a?.channel_id]?.posts?.posts?.[channels_store?.[a?.channel_id]?.posts?.order?.[(channels_store?.[a?.channel_id]?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0)))
      ?.sort?.((a)=>{ if((!_.isEmpty(channels_store?.[a?.channel_id]?.actions?.message) || !_.isEmpty(channels_store?.[a?.channel_id]?.actions?.files)) && !this.$store.state.mattermost.channels_view?.includes?.(a?.channel_id)) return -1 })
    },
    favoritTeams() {
      return this.teamsSorted.filter((team) => this.$store.getters.getFavoriteChannelIds.indexOf(team.channel_id) >= 0)
    },
    notFavoriteTeams() {
      return this.teamsSorted.filter((team) => this.$store.getters.getFavoriteChannelIds.indexOf(team.channel_id) === -1)
    },
    recentTeams() {
      if (!this.$store.state.mattermost.user || !this.$store.state.mattermost.user.props || !this.$store.state.mattermost.user.props.recent_channels) return [];
      const recent_channels = JSON.parse(this.$store.state.mattermost.user.props.recent_channels);
      const recent_teams = this.teamsSorted.filter((team) => recent_channels[team.channel_id]);
      const recent_tems_values = recent_teams.map((team) => {
        return {
          ...team,
          recent_time: recent_channels[team.channel_id],
        };
      });
      return _.orderBy(recent_tems_values, "recent_time", "desc")
    },
    recentTeamsUnreadCount() {
      const channels_store = this.$store.state.mattermost.channels; 
      return [...this.recentTeams].reduce((c,v)=>c+=channels_store?.[v.channel_id]?.unread ?? 0,0)
    },
    favRecentTeams() {
      return this.recentTeams.filter((team) => this.$store.getters.getFavoriteChannelIds.indexOf(team?.channel_id) >= 0)
    },
    notFavRecentTeams() {
      return this.recentTeams.filter((team) => this.$store.getters.getFavoriteChannelIds.indexOf(team?.channel_id) === -1)
    },
    searchedTeams() {
      const search = this.search.toLowerCase().trim()
      if (!search) return [];
      return this.teamsSorted.filter((team) => `${team.name} ${team.ringroup.extn}`.toLowerCase().trim().includes(search))
    },
    teamsUnreadCount() {
      
      const channels_store = this.$store.state.mattermost.channels;
      return [...this.favoritTeams,...this.notFavoriteTeams].reduce((c,v)=>c+=channels_store?.[v.channel_id]?.unread ?? 0,0)
    },
    //----
    //--users
    usersSorted(){ 
      const channels_store = this.$store.state.mattermost.channels
      return Object.values(this.$store.state.common.voipusers)
      .filter((user) => user.voipaccount !== this.$store.getters.getCurrentUser?.account)
      .sort((a,b)=>((channels_store?.[b?.channel_id]?.posts?.posts?.[channels_store?.[b?.channel_id]?.posts?.order?.[(channels_store?.[b?.channel_id]?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0) - (channels_store?.[a?.channel_id]?.posts?.posts?.[channels_store?.[a?.channel_id]?.posts?.order?.[(channels_store?.[a?.channel_id]?.posts?.order?.length ?? 0)-1]]?.create_at ?? 0)))
      .sort((a)=>{ if((!_.isEmpty(channels_store?.[a?.channel_id]?.actions?.message) || !_.isEmpty(channels_store?.[a?.channel_id]?.actions?.files)) && !this.$store.state.mattermost.channels_view.includes(a?.channel_id)) return -1 })
    },
    favoritUsers() {
      return this.usersSorted.filter((user) => this.$store.getters.getFavoriteUsersIds.indexOf(user.voipaccount) >= 0);
    },
    notFavoriteUsers() {
      return this.usersSorted.filter((user) => this.$store.getters.getFavoriteUsersIds.indexOf(user.voipaccount) === -1);
    },
    recentUsers() {
      if (!this.$store.state.mattermost.user || !this.$store.state.mattermost.user.props || !this.$store.state.mattermost.user.props.recent_channels) return [];
      const recent_channels = JSON.parse(this.$store.state.mattermost.user.props.recent_channels);
      const recent_users = this.usersSorted.filter((user) => user.channel_id && recent_channels[user.channel_id]);
      const recent_users_value = recent_users.map((user) => {
        return {
          ...user,
          recent_time: recent_channels[user.voipaccount],
        };
      });
      return _.orderBy(recent_users_value, "recent_time", "desc");
    },
    recentUsersUnreadCount() {
      const channels_store = this.$store.state.mattermost.channels; 
      return [...this.recentUsers].reduce((c,v)=>c+=channels_store?.[v.channel_id]?.unread ?? 0,0)
    },
    favRecentUsers() {
      return this.recentUsers.filter((user) => this.$store.getters.getFavoriteUsersIds.indexOf(user.voipaccount) >= 0 && user.voipaccount !== this.$store.getters.getCurrentUser?.account);
    },
    notFavRecentUsers() {
      return this.recentUsers.filter((user) => this.$store.getters.getFavoriteUsersIds.indexOf(user.voipaccount) === -1 && user.voipaccount !== this.$store.getters.getCurrentUser?.account);
    },
    searchedUsers() {
      const search = this.search.toLowerCase().trim()
      if (!search) return [];
      return this.usersSorted.filter((user) => `${user.user.display_name} ${user.extn}`.toLowerCase().trim().includes(search));
    },
    usersUnreadCount() {
      const channels_store = this.$store.state.mattermost.channels; 
      return [...this.favoritUsers,...this.notFavoriteUsers].reduce((c,v)=>c+=channels_store?.[v.channel_id]?.unread ?? 0,0)
    },
    //----
    //--mixes
    mixupRecents() {
      const array = [
        ...this.recentTeams,
        ...this.recentChannels,
        ...this.recentUsers,
      ];
      return _.orderBy(array, "recent_time", "desc")
    },
    //---
  },
  watch: {
    // tab(newTab, oldTab) {
    //   if (newTab !== oldTab) {
    //     this.$emit('change-channel-id','')
    //     // this.$parent.$refs.box.channel_id = "";
    //   }
    // },
  },
  methods: {
    ...mapMutations([
      SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW,
      SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW,
      SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD,
      SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE,
    ]),
    changeTabIndex(index) {
      this.tabsIndex = index;
    },
    onCompleteAddingChannel(channel) {
      let vm = this;
      setTimeout(() => {
        vm.$emit('change-channel-id',channel.id)
        // vm.$parent.$refs.box.channel_id = channel.id;
      }, 3 * 1000);
    },
  },
  created() {
    this.$root.$on(AppEvents.change_chat_tab, this.changeTabIndex);
  },
  mounted(){
    if(!this.channel_id) {
      const channel_id = this.mixupRecents?.[0]?.channel_id ?? this.mixupRecents?.[0]?.id
      if(channel_id) this.$emit('change-channel-id',channel_id)
    }
  },
  beforeDestroy() {
    this.$root.$off(AppEvents.change_chat_tab, this.changeTabIndex);
  },
};
</script>

<style lang="scss" scoped>
.offile {
  color: red;
}

.h-80 {
  height: 80%;
  position: relative;
  left: -8px;
}

.lds-ring {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ring div {
  box-sizing: border-box;
  display: block;
  position: absolute;
  width: 64px;
  height: 64px;
  margin: 8px;
  border: 4px solid #766af0;
  border-radius: 50%;
  animation: lds-ring 1.2s cubic-bezier(0.5, 0, 0.5, 1) infinite;
  border-color: #766af0 transparent transparent transparent;
}

.lds-ring div:nth-child(1) {
  animation-delay: -0.45s;
}

.lds-ring div:nth-child(2) {
  animation-delay: -0.3s;
}

.lds-ring div:nth-child(3) {
  animation-delay: -0.15s;
}

@keyframes lds-ring {
  0% {
    transform: rotate(0deg);
  }

  100% {
    transform: rotate(360deg);
  }
}

.filter-title {
  .b-icon {
    cursor: pointer;
  }
}
</style>
