<template>
  <modal
    :name="modalName"
    class="dialer_animation right_side_popup m-w-500 LinkTaskModal min-width-50percent-modal"
    width="50%"
    height="auto"
    :scrollable="true"
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()"
  >
    <div>
      <div class="dialer-edit-header flex-column align-items-start">
        <div class="d-flex justify-content-between align-items-center w-100">
          <div>
            <h2 class="dialer-settings-title newer d-flex align-items-center">
              <!-- <vb-svg @click="$modal.hide(modalName)" :disabled="api.link_tasks.send" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" /> -->
              <a type="button" class="newBackButton mr-16px" @click="$modal.hide(modalName)" :disabled="api.link_tasks.send" >
                <b-icon icon="arrow-left-circle" />
                <span class="newBackButton-textpart">Back</span>
              </a>
              Link task to
            </h2>
          </div>
          <div class="dialer-edit-actions">
            <!-- <b-button @click="$modal.hide(modalName)" :disabled="api.link_tasks.send" class="newCloseButton">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </b-button> -->
            <b-button @click="linkTasks()" :disabled="api.link_tasks.send || !isAnyChange" class="newDoneButton">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </b-button>
          </div>
        </div>
      </div>
      <div class="d-flex w-100 mt-12px">
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px">
          <label>Space</label>
          <vb-select 
            v-model="filters.tasks.space_id"
            :options="response.spaces"
            @change="filters.tasks.status_id='';filters.tasks.group_id='';getGroups();getStatus();getTasks(true);"
            textField="name"
            valueField="id"
          >
            <template #selected-option="{ option, onSelect }">
              <div class="d-flex align-content-center justify-content-between w-100 h-100">
                <span v-if="!option" class="p-0">Select space</span>
                <div v-else>
                  <span>{{option.name}}</span>
                  <b-button variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;" @click="onSelect('')">
                    <b-icon icon="x" scale="1.5" />
                  </b-button>
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <span>{{option.name}}</span>
            </template>
            <template #no-record>There is no spaces</template>
          </vb-select>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px">
          <label>Group</label>
          <vb-select 
            v-model="filters.tasks.group_id"
            :options="response.groups"
            @change="getTasks(true);"
            textField="name"
            valueField="id"
          >
            <template #selected-option="{ option, onSelect }">
              <div class="d-flex align-content-center justify-content-between w-100 h-100">
                <span v-if="!option" class="p-0">Select group</span>
                <div v-else>
                  <span>{{option.name}}</span>
                  <b-button variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;" @click="onSelect('')">
                    <b-icon icon="x" scale="1.5" />
                  </b-button>
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <span>{{option.name}}</span>
            </template>
            <template #no-record>There is no groups</template>
          </vb-select>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px">
          <label>Priority</label>
          <vb-select 
            v-model="filters.tasks.priority"
            :options="Object.values(priorities)"
            @change="getTasks(true);"
          >
            <template #selected-option="{ option, onSelect }">
              <div class="d-flex align-content-center justify-content-between w-100 h-100">
                <span v-if="!option" class="p-0">Select priority</span>
                <div v-else>
                  <span>{{option.text}}</span>
                  <b-button variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;" @click="onSelect('')">
                    <b-icon icon="x" scale="1.5" />
                  </b-button>
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <span>{{option.text}}</span>
            </template>
            <template #no-record>There is no prioritys</template>
          </vb-select>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20 mr-20px">
          <label>Status</label>
          <vb-select 
            v-model="filters.tasks.status_id"
            :options="response.statuses"
            @change="getTasks(true);"
            textField="title"
            valueField="id"
          >
            <template #selected-option="{ option, onSelect }">
              <div class="d-flex align-content-center justify-content-between w-100 h-100">
                <span v-if="!option" class="p-0">Select status</span>
                <div v-else>
                  <span>{{option.title}}</span>
                  <b-button variant="link" class="align-content-center justify-content-center p-0 m-0 mr-2" style="line-height: 0;" @click="onSelect('')">
                    <b-icon icon="x" scale="1.5" />
                  </b-button>
                </div>
              </div>
            </template>
            <template #option="{ option }">
              <span>{{option.title}}</span>
            </template>
            <template #no-record>There is no statuses</template>
          </vb-select>
        </div>
      </div>
      <!-- <div class="d-flex justify-content-end align-items-center">
        <b-button @click="$modal.hide(modalName)" :disabled="api.link_tasks.send">
          Close
        </b-button>
        <b-button @click="linkTasks()" :disabled="api.link_tasks.send">
          Done
        </b-button>
      </div> -->
      <div class="latestGreyBox-9-9-2023 mt-12px">
        <vb-no-record v-if="response.tasks.length==0" :design="3" :text="api.fetch_tasks.send?'':'No Task'">
          <!-- <vb-loading v-if="api.fetch_tasks.send" slot="loading" /> -->
          <div v-if="api.fetch_tasks.send" slot="loading">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row" v-for="n in 6" :key="n.id">
                <div class="latestShimmerDesign w-25" style="height:24px;"></div>
                <div class="latestShimmerDesign w-25" style="height:24px;"></div>
              </div>
            </div>
          </div>
        </vb-no-record>
        <template v-else>
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <input placeholder="Search tasks" v-model="filters.tasks.search" class="w-100" @input="getTasksDebounce()" />
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="w-100 tasks-insideLinkedCallModal-container pr-3" @scroll="getFurthurTasks($event)">
                <div 
                  v-for="task in response.tasks" 
                  :key="task.id" 
                  class="tasks-insideLinkedCallModal"
                  :class="`priority-${task.priority}`" 
                >
                  <div class="d-flex align-items-center">
                    <b-button 
                      :disabled="api.link_tasks.send"
                      variant="link"
                      @click="selected.task_ids.includes(task.id) ? selected.task_ids.splice(selected.task_ids.indexOf(task.id),1) : selected.task_ids.push(task.id)"
                    >
                      <!-- <b-icon v-if="selected.task_ids.includes(task.id)" icon="check-circle-fill" />
                      <b-icon v-else icon="circle" /> -->
                      <div :class="`actLikeSquareRadio checkboxVersion ${selected.task_ids.includes(task.id) ? 'checked' : ''}`" ></div>
                    </b-button>
                    <div class="tasks-insideLinkedCallModal-text ml-8px">
                      <!-- <span>{{task | get_property('spaces.prefix')}}#{{task | get_property('id')}}</span> -->
                      <div class="tasks-insideLinkedCallModal-spaceCircle" :style="`background-color: ${task.spaces.color}`"></div>
                      <span>{{task | get_property('title')}}</span>
                    </div>
                  </div>
                  <div class="d-flex align-items-center stackedAvatars-container">
                    <vb-avatar :is_blf="false" v-for="(assignee,index) in task.assigned" :style="`z-index:${task.assigned.length - index}`" :key="assignee.assigned_to" :id="assignee.assigned_to" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import _ from 'lodash';
const priorities = {
  high: {
    text: 'High',
    value: 'high',
    color: '#ff403b',
  },
  mid: {
    text: 'Medium',
    value: 'mid',
    color: '#ffba39',
  },
  low: {
    text: 'Low',
    value: 'low',
    color: '#8ad129',
  }
}
export default {
  name: 'LinkedTasksModal',
  props: {
    modalName: {
      type: String,
      default: 'LinkedTasks'
    },
  },
  data() {
    return {
      api: {
        fetch_tasks: this.$helperFunction.apiInstance({
          source: true,
        }),
        fetch_spaces: this.$helperFunction.apiInstance({
          source: true,
        }),
        fetch_groups: this.$helperFunction.apiInstance({
          source: true,
        }),
        fetch_statuses: this.$helperFunction.apiInstance({
          source: true,
        }),
        link_tasks: this.$helperFunction.apiInstance(),
      },
      response: {
        spaces: [],
        groups: [],
        statuses: [],
        tasks: [],
      },
      data: {
        task_id: '',
        linked_tasks: [],
      },
      selected: {
        task_ids: [],
      },
      filters: {
        tasks: {
          search: '',
          space_id: '',
          group_id: '',
          priority: '',
          status_id: '',
        },
      },
      paginations: {
        tasks: {
          per_page: 10,
          page: 1,
          total: 0,
        },
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    priorities(){ return priorities },
    isAnyChange(){ return _.orderBy(this.data.linked_tasks).join(',')!=_.orderBy(this.selected.task_ids).join(',') },
  },
  filters: {
    priorityColor(priority){
      if(priority=='high') return '#ff403b'
      else if(priority=='mid') return '#ffba39'
      else return '#8ad129'
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.task_id=event?.params?.task_id ?? ''
      this.data.linked_tasks=event?.params?.task_ids ?? []
      this.selected.task_ids=[...this.data.linked_tasks]
      this.getTasks(true)
      this.getSpaces()
    },
    onBeforeClose() {
      this.api.fetch_tasks.reset()
      this.api.fetch_spaces.reset()
      this.api.fetch_groups.reset()
      this.api.fetch_statuses.reset()
      this.api.link_tasks.reset()
      this.response.tasks=[]
      this.response.spaces=[]
      this.response.groups=[]
      this.response.statuses=[]
      this.data.task_id=''
      this.data.linked_tasks=[]
      this.selected.task_ids=[]
      this.filters.tasks.group_id=''
      this.filters.tasks.priority=''
      this.filters.tasks.space_id=''
      this.filters.tasks.status_id=''
      this.paginations.tasks.page=1
      this.paginations.tasks.total=0
      this.paginations.tasks.per_page=10
    },
    async getSpaces() {
      this.response.spaces=[]
      this.api.fetch_spaces.source?.cancel?.("Requesting Again");
      this.api.fetch_spaces.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_spaces.send=true
        const { data } = await VOIP_API.endpoints.task_spaces.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        },this.api.fetch_spaces.source.token)
        this.response.spaces=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_spaces.send=false  
      }
    },
    async getGroups(){
      this.response.groups = []
      this.api.fetch_groups.source?.cancel?.("Requesting Again");
      this.api.fetch_groups.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_groups.send=true
        const { data: { data } } = await VOIP_API.endpoints.taskgroups.list({
          uid: this.getCurrentUser.uid,
          space_id: this.filters.tasks.space_id,
        },this.api.fetch_spaces.source.token)
        this.response.groups = data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_groups.send=false
      }
    },
    async getStatus(){
      this.response.statuses = []
      this.api.fetch_statuses.source?.cancel?.("Requesting Again");
      this.api.fetch_statuses.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_statuses.send=true
        const { data } = await VOIP_API.endpoints.task_status.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          space_id: this.filters.tasks.space_id,
        },this.api.fetch_statuses.source.token)
        this.response.statuses = data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_statuses.send=false
      }
    },
    getTasksDebounce: _.debounce(function (next){
      this.getTasks(next?false:true)
    }, 2*1000),
    async getTasks(start) {
      this.api.fetch_tasks.source?.cancel?.("Requesting Again");
      this.api.fetch_tasks.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_tasks.send=true
        if(start) {
          this.paginations.tasks.page=1
        } else if (this.paginations.tasks.total==this.response.tasks.length) return;
        else {
          this.paginations.tasks.page=this.paginations.tasks.page+1
        }
        const { data } = await VOIP_API.endpoints.tasklist.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          task_id: this.data.task_id,
          status: this.filters.tasks.status_id ? [this.filters.tasks.status_id] : [],
          groups: this.filters.tasks.group_id ? [this.filters.tasks.group_id] : [],
          priority: this.filters.tasks.priority ? [this.filters.tasks.priority] : [],
          space_id: this.filters.tasks.space_id ? [this.filters.tasks.space_id] : [],
          search: this.filters.tasks.search || null,
          page: this.paginations.tasks.page,
        },this.api.fetch_tasks.source.token)
        const tasks = data?.data ?? []
        if(this.paginations.tasks.page==1){
          this.response.tasks = tasks
        } else {
          this.response.tasks = this.response.tasks.concat(tasks)
        }
        this.paginations.tasks.total=data?.total ?? 0
        this.paginations.tasks.per_page=data?.per_page ?? 10
      } catch (ex) {
        if(!ex.cancel){
          this.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_tasks.send=false
      }
    },
    getFurthurTasks(event){
      const height = event.target.scrollHeight;
      const top = event.target.scrollTop;
      const offset_height = event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      if (scroll_bottom <= 25) {
        this.getTasksDebounce(true);
        event.target.scrollTop=top-25
      }
    },
    async linkTasks(){
      if(this.api.link_tasks.send) return;
      try {
        this.api.link_tasks.send=true
        await VOIP_API.endpoints.tasklist.linkTasks({
          task_id: this.data.task_id,
          task_link_id: this.selected.task_ids,
          accountcode: this.getCurrentUser.account,
          uid: this.getCurrentUser.uid,
        })
        this.$emit('updated',{
          task_ids: this.selected.task_ids
        })
        this.$modal.hide(this.modalName)
        this.appNotify({
          message: 'Successfully Changed',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.link_tasks.send=false
      }
    },
  },
}
</script>

<style>

</style>