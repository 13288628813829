<template>
  <div>
    <modal class="dialer_animation right_side_popup m-w-800 editTeamModal" width="40%" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
      <section class="ct-user-new dialer-edit pb-4">
        <div class="dialer-edit-header">
          <div>
            <h2 class="dialer-settings-title newer mb-0">Edit team</h2>
          </div>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" :disabled="api.update_label.send || api.delete_team.send" @click="updateTeam()">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023">
          <!-- Team name and profile picture section -->
          <div class="d-flex align-items-center justify-content-between editTeamModal-firstLine">
            <div class="whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-50">
              <label>Team name</label>
              <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
                <img :src="team | get_property('avatar.image.image')" @error="$event.target.src=local_filess.images.user_male" />
                <input 
                  :disabled="api.update_label.send || api.delete_team.send" 
                  class="w-100 bg-white" 
                  type="text" 
                  :maxlength="$v.forms.update_label.name.$params.maxLength.max" 
                  v-model="forms.update_label.name" 
                />
              </div>
              <p class="text text-danger animated bounceIntop mb-0" v-if="forms.update_label.submitted && $v.forms.update_label.name.$invalid">
                <span v-if="!$v.forms.update_label.name.required">Name is required</span>
                <span v-if="!$v.forms.update_label.name.maxLength">Name can be maximum {{$v.forms.update_label.name.$params.maxLength.max}} character</span>
              </p>
            </div>
            <div class="profileImg-section-latest-container">
              <div class="profileImg-section-latest" @click="api.delete_team.send ? '' : $modal.show('ProfileImageUploader', { type: 'TEAM', accountcode: team.real_id, current_image: getProperty(team,'avatar.image.image','') })">
                <img :src="team | get_property('avatar.image.image')" @error="$event.target.src=local_filess.images.user_male" />
              </div>
            </div>
          </div>
          <!-- Team Numbers Section -->
          <div class="mt-20px">
            <div class="latestGreyBox-heading-main">Telephone numbers</div>
            <div class="latestGreyBox-descriptionText">Direct incoming calls to the team from your available numbers</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row TelephoneNumbersRow">
                <!-- <ul class="list-unstyled mt-3 d-flex flex-wrap" v-if="dids.length > 0">
                  <li v-for="(phone, index) in dids" :key="index" class="dialer-box-text highlight alt table py-1 px-3 mr-2 telephone-number-box bg-primary d-flex align-items-center">
                    <div >
                      <span >
                        <img class="country-img" width="45px"  :src="phone.did | number_formater | flag_icon" />
                      </span>
                    </div>
                    <div class="d-flex flex-column">
                      <span class="sm-mar-left sm-mar-right font-weight-bold">{{ phone.did | number_formater }}</span>
                      <span class="sm-mar-left sm-mar-right" v-if="phone.name">( {{ phone.name | check_empty('-') }} )</span>
                    </div>
                  </li>
                </ul> -->
                <div class="d-flex IVR-numbers-container flex-wrap" v-if="dids.length > 0">
                  <div v-for="(phone, index) in dids" :key="index" class="newIVRLayout-number-item">
                    <img class="country-img" width="45px" :src="phone.did | number_formater | flag_icon" />
                    <span class="newIVRLayout-number-item-text">{{ phone.did | number_formater }}</span>
                  </div>
                </div>
                <template v-else>
                  <p class="dialer-box-text highlight mb-0">No numbers assigned</p>
                </template>
                <div class="lg-mar-left TelephoneNumbersRow-button-container">
                  <button class="newButton mwd-265px md-mar-left" type="button" :disabled="api.delete_team.send" @click="$modal.show(`${_uid}-AssignNumberModal`, {accountcode: accountcode,})">
                    <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Add/Remove telephone number</span>
                  </button>
                </div>
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main">Team members</div>
                  <div class="latestGreyBox-descriptionText">{{ team.members | memberCount }}</div>
                </div>
                <button class="newButton md-mar-left" type="button" :disabled="api.delete_team.send" @click="$modal.show('AssignNumbersTeam', { team: team, type: 'AssignNumbersTeam' })">
                  <vb-icon icon="squared-singleUser-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Add/Remove user</span>
                </button>
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main">Pickup groups</div>
                  <div class="latestGreyBox-descriptionText">Enable or disable pickup groups.</div>
                </div>
                <div 
                  id="PickupGroupSwitch" 
                  v-b-tooltip :title="getUserPermissions.call_pickup?'':'Your package does not support this feature'"
                  :class="['' , !getUserPermissions.call_pickup ? 'for-disable' : '']"
                >
                  <!-- <vb-spinner v-if="api.update_pickgroup.send || forms.update_pickup_group.pickup_group===0" />
                  <b-form-checkbox v-else :disabled="!getUserPermissions.call_pickup || api.delete_team.send || api.update_pickgroup.send" class="dialer-switch" name="check-button" switch :checked="forms.update_pickup_group.pickup_group" @change="updatePickupGroup($event);" /> -->
                  <b-form-checkbox :disabled="!getUserPermissions.call_pickup || api.delete_team.send || api.update_pickgroup.send" class="newerSwitch" name="check-button" switch :checked="forms.update_pickup_group.pickup_group" @change="updatePickupGroup($event);" />
                </div>
              </div>
            </div>
          </div>
          <!-- MOH and Pickup Groups Switch section -->
          <div class="mt-20px">
            <div class="latestGreyBox-heading-main">Call routing</div>
            <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
                <CallForwardingSetting 
                  v-if="!!accountcode" 
                  @cf-updated="$emit('cf-updated')"
                  :forwardCallerId="true"
                  :account="accountcode"
                />
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0">
                <ScheduleSetting 
                  v-if="!!accountcode" 
                  @ofh-days-updated="$emit('ofh-days-updated')"
                  @ofh-default-updated="$emit('ofh-default-updated')"
                  @ofh-toggle-updated="$emit('ofh-toggle-updated')"
                  :account="accountcode" 
                />
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
                <MusicOnHoldWidget v-if="accountcode" :accountcode="accountcode"  />
              </div>
              <MissedCallNotificationWidget v-if="data.accountcode" :accountcode="data.accountcode" />
            </div>
          </div>
          <VoicemailSetting class="VoicemailSetting-insideTeamsModal" v-if="data.accountcode" :accountcode="data.accountcode" />
          

          <div class=" mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main">Delete this team?</div>
                </div>
                <button :disabled="api.delete_team.send" class="newDeleteButton" type="button" @click="deleteTeam()">
                  <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
                  <span class="newDeleteButton-textPart">Delete</span>
                </button>
              </div>
            </div>
          </div>
        </div>



















      </section>
    </modal>
    <!-- <MusicOnHoldRecordingModal @updated="callRouteSetting()" /> -->
    <AssignNumberModal :modalName="`${_uid}-AssignNumberModal`" @added="$emit('interface')" />

    <ProfileImageUploader @image-uploaded="$emit('interface');" />
  </div>
</template>

<script>
import MusicOnHoldWidget from '../widgets/MusicOnHold.vue'
import ScheduleSetting from "../Common/ScheduleSetting.vue";
import CallForwardingSetting from "../Common/CallForwardingSetting.vue";
// import MusicOnHoldRecordingModal from "../Modals/MusicOnHoldRecordingModal.vue";
import { VOIP_API } from "../../utils";
import ProfileImageUploader from './ProfileImageUploader.vue';
import AssignNumberModal from "./AssignNumberModal.vue";
import { mapGetters } from 'vuex';
import { maxLength, required } from "vuelidate/lib/validators";
import VoicemailSetting from "../Voicemail/Setting.vue";
import MissedCallNotificationWidget from '../widgets/calls/MissedCallNotificationWidget.vue';
export default {
  name: "EditTeamModal",
  inject: ['local_filess', 'getProperty','appNotify'],
  components: {
    ProfileImageUploader,
    ScheduleSetting,
    CallForwardingSetting,
    // MusicOnHoldRecordingModal,
    AssignNumberModal,
    MusicOnHoldWidget,
    VoicemailSetting,
    MissedCallNotificationWidget,
  },
  props: {
    modalName: {
      type: String,
      default: 'EditTeamModal'
    },
  },
  data() {
    return {
      api: {
        delete_team: this.$helperFunction.apiInstance(),
        call_route: this.$helperFunction.apiInstance(),
        update_pickgroup: this.$helperFunction.apiInstance(),
        update_label: this.$helperFunction.apiInstance(),
        reset(){
          this.delete_team?.reset?.()
          this.call_route?.reset?.()
          this.update_pickgroup?.reset?.()
          this.update_label?.reset?.()
        },
      },
      data: this.$helperFunction.stateInstance({
        data: {
          real_id: '',
          accountcode: '',
          team: null,
        }
      }),
      forms: {
        music_on_hold: this.$helperFunction.formInstance({
          data: {
            enable: false,
            did: '',
            name: '',
          }
        }),
        update_label: this.$helperFunction.formInstance({
          data: {
            name: '',
          }
        }),
        update_pickup_group: this.$helperFunction.formInstance({
          data: {
            pickup_group: 0,
          }
        }),
        reset(){
          this.music_on_hold?.reset?.()
          this.update_label?.reset?.()
          this.update_pickup_group?.reset?.()
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getVoipTeams: 'getVoipTeams',
      getCurrentUser: 'getCurrentUser',
    }),
    team(){ return this.getVoipTeams?.find?.(team=>team.real_id==this.data.real_id) ?? this.data.team ?? {} },
    dids(){ return this.team?.ringroup?.did ?? [] },
    accountcode(){ return this.team?.ringroup?.accountcode ?? this.data.accountcode ?? '' },
  },
  validations: {
    forms: {
      update_label: {
        name: { 
          required,
          maxLength: maxLength(20) 
        },
      },
    },
  },
  filters: {
    memberCount: function (value) {
      if (typeof value == "number") {
        return value + " user";
      }

      if (!value) return "No users selected";
      return value.length + " user";
    },
    firstCharacter: function (value) {
      if (!value) return "";
      return value.charAt(0);
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.real_id = event?.params?.team?.real_id ?? '';
      this.data.accountcode = event?.params?.team?.ringroup?.accountcode ?? ''
      this.data.team = event?.params?.team ?? null
      this.forms.update_label.name = event?.params?.team?.name ?? ''
      // this.callRouteSetting();
      this.$nextTick()
      .then(()=>{
        this.forms.update_pickup_group.pickup_group=this.team.pickupgroup_enable=='yes'
      })
    },
    onBeforeClose() {
      this.api.reset()
      this.data.reset()
      this.forms.reset()
    },
    updatePickupGroup(value){
      let vm = this
      if(vm.api.update_pickgroup.send) return;
      vm.api.update_pickgroup.send=true
      VOIP_API.endpoints.teams.updatepickupgroupoption(vm.data.real_id,{
        pickupgroup: value ? 'yes' : 'no',
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.forms.update_pickup_group.pickup_group=!vm.forms.update_pickup_group.pickup_group
        vm.$emit('interface')
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update_pickgroup.send=false
      })
    },
    updateTeam() {
      let vm = this
      vm.forms.update_label.submitted = true
      vm.$v.forms.update_label.$touch()
      if(vm.api.update_label.send || vm.$v.forms.update_label.$invalid) return;
      vm.api.update_label.send=true
      VOIP_API.endpoints.teams.update(this.data.real_id, {
        name: vm.forms.update_label.name,
      })
      .then(()=>{
        vm.$emit('interface')
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update_label.send=false
        vm.forms.update_label.submitted = false
        vm.$modal.hide(vm.modalName)
      });
    },
    // callRouteSetting() {
    //   let vm = this
    //   vm.api.call_route.send=true
    //   VOIP_API.endpoints.users.callroute({
    //     accountcode: vm.accountcode,
    //   })
    //   .then((res) => {
    //     vm.forms.music_on_hold.enable = !!res?.data?.playlistid
    //     vm.forms.music_on_hold.did = res?.data?.playlistid ?? ''
    //     vm.forms.music_on_hold.name = res?.data?.playlistname ?? ''
    //   })
    //   .catch((ex) => {
    //     vm.appNotify({
    //       message: ex.own_message,
    //       type: 'error',
    //     })
    //   })
    //   .finally(()=>{
    //     vm.api.call_route.send=false
    //   });
    // },
    deleteTeam() {
      let vm = this
      if(vm.api.delete_team.send) return;
      vm.$appConfirmation({
        title: "Delete team?",
        message: `Are you sure you want to delete this team?`,
        variant: 'danger',
        alert: {
          variant: 'danger',
          title: 'Warning',
          message: `This action is irreversible, You will lose all the configurations for this team.`,
        },
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete_team.send=true
            VOIP_API.endpoints.teams.delete({
              id: vm.data.real_id,
              uid: vm.getCurrentUser?.uid,
              accountcode: vm.getCurrentUser?.account,
              deleted_type: 'team',
            }).then(()=>{
              vm.$emit('interface')
              vm.$modal.hide(vm.modalName)
            })/*.then(()=>{
              return vm.$store.getters.getVoipTeams.filter(i=>i.real_id!=vm.team.real_id)
            }).then((teams)=>{
              vm.$store.commit("setVoipTeams", {
                data: teams,
                total: vm.$store.state.common.teams.total-1
              });
              vm.$modal.hide(vm.modalName)
            })*/.catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(()=>{
              vm.api.delete_team.send=false
            })
          }
        },
      });
    },
  },
};
</script>
