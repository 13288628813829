<template>
  <div class="dialer-settings-main">
    <template>
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$emit('back')">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
          <div class="settingsTextOnTop">Settings</div>
        </div>
        <div class="dialer-settings-title topMainHeading">Calls</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
    </template>
    <section class="dialer-settings-section dialer-call-setting">
      <div class="textAboveCard mt-32px">CALL FORWARDING</div>
      <div class="basicWhiteIOScard">
        <CallForwardingWidget :accountcode="voipaccount" />
      </div>
      
      <div class="textAboveCard">Business hours</div>
      <div class="basicWhiteIOScard mb-16px">
        <OutOfHoursWidget :accountcode="voipaccount" />
      </div>
      <div class="basicWhiteIOScard">
        <div class="basicWhiteIOScard-item">
          <div class="headingInsideCard">Call Recording</div>
          <div class="position-relative">
            <b-form-checkbox 
              class="dialer-switch IosSwitch" 
              ref="recording"
              :disabled="!getUserPermissions.call_recording || api.update_recording.send"
              :checked="conditions.recording"
              name="check-button"
              switch
              @change="updateRecording($event)"
            />
          </div>
        </div>
        <MusicOnHoldWidget :accountcode="voipaccount" />
        <div class="basicWhiteIOScard-item">
          <div class="headingInsideCard">Call Waiting</div>
          <div class="position-relative">
            <b-form-checkbox 
              class="dialer-switch IosSwitch" 
              name="check-button"
              switch
              ref="call_waiting"
              :disabled="!getUserPermissions.call_waiting || api.update_call_waiting.send || api.user_detail.send"
              :checked="!conditions.call_waiting"
              @change="updateCallWaiting($event)"
            />
          </div>
        </div>
        <div class="basicWhiteIOScard-item">
          <div class="headingInsideCard">Missed Calls Notification</div>
          <div class="position-relative">
            <b-form-checkbox 
              class="dialer-switch IosSwitch" 
              id="checkbox-1"
              ref="missed_call_notification"
              :disabled="!getUserPermissions.missed_call_notification || api.update_missed_call_notification.send"
              :checked="conditions.missed_call_notification"
              @change="updateMissedCallNotification($event)"
              name="checkbox-button"
              switch
            />
          </div>
        </div>
        <div class="basicWhiteIOScard-item">
          <div class="headingInsideCard">Desktop Notifications</div>
          <div class="position-relative">
            <b-form-checkbox 
              class="dialer-switch IosSwitch" 
              ref="desktop_notification"
              :disabled="!getUserPermissions.desktop_notification || api.update_desktop_notification.send"
              :checked="conditions.desktop_notification"
              name="check-button"
              switch
              @change="updateDesktopNotification($event)"
            />
          </div>
        </div>
        <div class="basicWhiteIOScard-item" @click="$modal.show('IncommingcallDisplaySetting',{ account: voipaccount })">
          <!-- <div class="headingInsideCard">Incoming Call Display</div> -->
          <div class="headingInsideCard">Incoming Call Presentation</div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
        </div>
        <div class="basicWhiteIOScard-item">
          <div class="headingInsideCard">Forward Source Caller Id</div>
          <div class="position-relative">
            <b-form-checkbox 
              class="dialer-switch IosSwitch" 
              ref="forward_source_caller_id"
              :disabled="!getUserPermissions.forward_source_caller_id || api.update_forward_source_caller_id.send || api.user_detail.send"
              :checked="conditions.forward_source_caller_id"
              name="check-button"
              switch
              @change="updateForwardSourceCallerID($event)"
            />
          </div>
        </div>
      </div>
      <IncommingcallDisplaySettingModal />
    </section>
  </div>
</template>

<script>

import { 
  // LOGGER, 
  VOIP_API 
} from "@/utils";
import { TokenService } from "@/services/storage.service";
import { mapGetters } from 'vuex';
import CallForwardingWidget from '../widgets/CallForwarding.vue';
import OutOfHoursWidget from '../widgets/OutOfHours.vue';
import MusicOnHoldWidget from '../widgets/MusicOnHold.vue';
import IncommingcallDisplaySettingModal from '../modals/IncomingCallDisplaySetting.vue'

export default {
  name: "CallCopyC",
  components: {
    CallForwardingWidget,
    OutOfHoursWidget,
    MusicOnHoldWidget,
    IncommingcallDisplaySettingModal,
  },
  inject:['isEmpty','appNotify'],
  props: {
    accountcode: {
      type: String,
    },
    user: {
      type: Object,
    }
  },
  data() {
    return {
      api: {
        update_recording: this.$helperFunction.apiInstance(),
        update_call_waiting: this.$helperFunction.apiInstance(),
        update_missed_call_notification: this.$helperFunction.apiInstance(),
        update_desktop_notification: this.$helperFunction.apiInstance(),
        update_email_notification: this.$helperFunction.apiInstance(),
        update_forward_source_caller_id: this.$helperFunction.apiInstance(),
        call_route: this.$helperFunction.apiInstance(),
        user_detail: this.$helperFunction.apiInstance(),
        route_call: this.$helperFunction.apiInstance(),
      },
      response: {
        user_detail: {},
        forwarding_setting: {
          forward_setting: "",
          forward_note: "",
          forward_number: "",
          timeout: 5,
          extension_name: "",
          number: "",
        },
        allow_recording: false,
        outofhours: false,
        recording: false,
      },
      conditions: {
        allow_recording: false,
        recording: false,
        call_waiting: false,
        missed_call_notification: false,
        desktop_notification: false,
        email_notification: false,
        forward_source_caller_id: false,
      },
      playlist: {
        check: false,
        name: "",
        id: "",
      },
    };
  },
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getCurrentUser: 'getCurrentUser',
      getIsMobile: 'getIsMobile',
    }),
    voipaccount(){ return this.accountcode || this.getCurrentUser.account },
  },
  filters: {
    clidText(value){
      if(value==0) return `Display Number only`
      else if(value==1) return `Display DID name`
      else if(value==2) return `Display DID number`
      else if(value==5) return `Display Speed dial reference name`
      else ''
    },
  },
  methods: {
    fetchcallRouteSetting() {
      let vm = this
      if(vm.api.route_call.send) return;
      vm.api.route_call.send = true;
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.voipaccount,
      }).then(({ data }) => {
        vm.response.outofhours = data.schedule_enable;
        vm.response.forwarding_setting = data.forwarding_setting;
        vm.response.recording = data.recording;
        vm.response.allow_recording = data.allow_recording=='1';
      }).finally(() => {
        vm.api.route_call.send = false;
      });
    },
    updateRecording(value) {
      let vm = this;
      if(vm.api.update_recording.send) return;
      vm.api.update_recording.send = true;
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.voipaccount,
      })
      .then(({ data }) => {
        return VOIP_API.endpoints.users.updateroutecall({
          forward_setting: data.forwarding_setting,
          call_recording: value,
          accountcode: vm.voipaccount,
          schedule_enable: data.schedule_enable,
        })
      })
      .then(() => {
        vm.conditions.recording=value
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if(vm.$refs['recording']) vm.$refs['recording'].localChecked=vm.conditions.recording
        vm.api.update_recording.send=false;
      });
    },
    updateCallWaiting(value) {
      let vm = this;
      if(vm.api.update_call_waiting.send) return;
      vm.api.update_call_waiting.send = true;
      VOIP_API.endpoints.users.updateuserdetail({
        field: `call_waiting`,
        value: !value,
        accountcode: this.voipaccount,
      })
      .then(() => {
        vm.conditions.call_waiting=!value
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if(vm.$refs['call_waiting']) vm.$refs['call_waiting'].localChecked=!vm.conditions.call_waiting
        vm.api.update_call_waiting.send=false;
      });
    },
    updateMissedCallNotification(value) {
      let vm = this;
      if(vm.api.update_missed_call_notification.send) return;
      vm.api.update_missed_call_notification.send = true;
      VOIP_API.endpoints.users.updateuserdetail({
        field: `missed`,
        value: value ? '1' : '0',
        accountcode: this.voipaccount,
      })
      .then(() => {
        vm.conditions.missed_call_notification=value
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        this.updateUserObject('missed')
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if(vm.$refs['missed_call_notification']) vm.$refs['missed_call_notification'].localChecked=vm.conditions.missed_call_notification
        vm.api.update_missed_call_notification.send=false;
      });
    },
    updateDesktopNotification(value) {
      let vm = this;
      if(vm.api.update_desktop_notification.send) return;
      vm.api.update_desktop_notification.send = true;
      VOIP_API.endpoints.users.updateuserdetail({
        field: `desktop_notification`,
        value: value ? '1' : '0',
        accountcode: this.voipaccount,
      })
      .then(() => {
        vm.conditions.desktop_notification=value
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        this.updateUserObject('desktop_notification')
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if(vm.$refs['desktop_notification']) vm.$refs['desktop_notification'].localChecked=vm.conditions.desktop_notification
        vm.api.update_desktop_notification.send=false;
      });
    },
    updateEmailNotification(value) {
      let vm = this;
      if(vm.api.update_email_notification.send) return;
      vm.api.update_email_notification.send = true;
      VOIP_API.endpoints.users.updateuserdetail({
        field: `email_notification`,
        value: value ? '1' : '0',
        accountcode: this.voipaccount,
      })
      .then(() => {
        vm.conditions.email_notification=value
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        this.updateUserObject('email_notification')
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if(vm.$refs['email_notification']) vm.$refs['email_notification'].localChecked=vm.conditions.email_notification
        vm.api.update_email_notification.send=false;
      });
    },
    updateForwardSourceCallerID(value) {
      let vm = this;
      if(vm.api.update_forward_source_caller_id.send) return;
      vm.api.update_forward_source_caller_id.send = true;
      VOIP_API.endpoints.users.updateuserdetail({
        field: `enable_forward_callerid_remote`,
        value: value ? '1' : '0',
        accountcode: this.voipaccount,
      })
      .then(() => {
        vm.conditions.forward_source_caller_id=value
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        vm.fetchUserDetail()
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if(vm.$refs['forward_source_caller_id']) vm.$refs['forward_source_caller_id'].localChecked=vm.conditions.forward_source_caller_id
        vm.api.update_forward_source_caller_id.send=false;
      });
    },
    updateUserObject(type=''){
      if(this.voipaccount==this.$store.state.common.user.account){
        switch (type) {
          case "missed":
            this.$store.state.common.user.missed_call_notification = this.conditions.missed_call_notification ? "1" : "0";
            break;
          case "email_notification":
            this.$store.state.common.user.email_notification = this.conditions.email_notification;
            break;
          case "desktop_notification":
            this.$store.state.common.user.desktop_notification = this.conditions.desktop_notification;
            break;
          default:
            break;
        }
      }
    },
    fetchUserDetail() {
      let vm = this
      if (vm.api.user_detail.send) return;
      vm.api.user_detail.send = true;
      VOIP_API.endpoints.users.detail(vm.voipaccount)
      .then(({ data: detail }) => {
        vm.response.user_detail = detail;
        vm.conditions.call_waiting=detail.callwaiting=='Y'
        vm.conditions.forward_source_caller_id=detail.enable_forward_callerid_remote=='1'
      })
      .finally(() => {
        vm.api.user_detail.send = false;
      });
    },
  },
  created(){
    let user = TokenService.USER.get();
    this.conditions.email_notification = ["1", 1, true, "yes", "Yes", "Y"].indexOf(this.user?this.user.email_notification : user.email_notification) > -1;
    this.conditions.desktop_notification = ["1", 1, true, "yes", "Yes", "Y"].indexOf(this.user?this.user.desktop_notification : user.desktop_notification) > -1;
    this.conditions.missed_call_notification = ["1", 1, true, "yes", "Yes", "Y"].indexOf(this.user?this.user.missed_call_notification : user.missed_call_notification) > -1;
  },
  mounted() {
    if(this.accountcode){
      // this.fetchCallRouteSetting();
    }
    this.fetchUserDetail();
    this.fetchcallRouteSetting();
  },
  activated(){
    // this.fetchCallRouteSetting();
    this.fetchUserDetail();
    this.fetchcallRouteSetting()
  },
};
</script>
