var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-summary"
  }, [_vm.conditions.select_tab ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.select_tab = false;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Date")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Summary")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })])], _c('div', {
    staticClass: "dialer-box AnalyticsContainerInner"
  }, [_vm.conditions.select_tab ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected = tab.key, _vm.tab_index = index, _vm.load();
          _vm.selected == 'custom' ? _vm.conditions.select_tab = true : _vm.conditions.select_tab = false;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(tab.value))])])]), _vm.selected === tab.key ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0), _vm.selected == 'custom' ? _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2 forDateTime"
  }, [_c('label', [_vm._v("From")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "starttime"
    },
    model: {
      value: _vm.start_date,
      callback: function ($$v) {
        _vm.start_date = $$v;
      },
      expression: "start_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2 forDateTime"
  }, [_c('label', [_vm._v("To")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "endtime"
    },
    model: {
      value: _vm.end_date,
      callback: function ($$v) {
        _vm.end_date = $$v;
      },
      expression: "end_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)])]) : _vm._e(), _c('a', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    on: {
      "click": function ($event) {
        _vm.load();
        _vm.conditions.select_tab = false;
      }
    }
  }, [_vm._v("Fetch")])] : [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.select_tab = !_vm.conditions.select_tab;
      }
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-8px"
  }, [_vm._v(_vm._s(_vm.selectedTab.value))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)])]), _vm.isEmpty(_vm.response) ? _c('vb-no-record', {
    staticClass: "text-center w-100",
    attrs: {
      "text": _vm.loading ? '' : 'No record',
      "design": 3
    }
  }) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isEmpty(_vm.response) && !_vm.loading,
      expression: "!isEmpty(response) && !loading"
    }]
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon circularProgressBar mr-16px"
  }, [_c('vb-circular-progressive-bar', {
    attrs: {
      "percentage": 100
    }
  })], 1), _vm._m(1)]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow mr-16px",
    attrs: {
      "icon": "CallSummary-TotalCall-icon",
      "width": "16.642",
      "height": "15.275"
    }
  }), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.total))])], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item dialer-missed"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon circularProgressBar mr-16px"
  }, [_c('vb-circular-progressive-bar', {
    attrs: {
      "percentage": _vm.callsPercentage.missed
    }
  })], 1), _vm._m(2)]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow mr-16px",
    attrs: {
      "icon": "CallSummary-MissedCall-icon",
      "width": "16.642",
      "height": "15.275"
    }
  }), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.missed))])], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item dialer-inbound"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon circularProgressBar mr-16px"
  }, [_c('vb-circular-progressive-bar', {
    attrs: {
      "percentage": _vm.callsPercentage.incomming
    }
  })], 1), _vm._m(3)]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow mr-16px",
    attrs: {
      "icon": "CallSummary-InboundCall-icon",
      "width": "16.642",
      "height": "15.275"
    }
  }), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.incomming))])], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item dialer-outbound"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon circularProgressBar mr-16px"
  }, [_c('vb-circular-progressive-bar', {
    attrs: {
      "percentage": _vm.callsPercentage.outgoing
    }
  })], 1), _vm._m(4)]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow mr-16px",
    attrs: {
      "icon": "CallSummary-OutboundCall-icon",
      "width": "16.642",
      "height": "15.275"
    }
  }), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.outgoing))])], 1)])]), _vm._m(5)])]], 2)], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Date")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Total Calls")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Missed Calls")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Inbound Calls")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Outbound Calls")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "chart-container basicWhiteIOScard-item"
  }, [_c('canvas', {
    attrs: {
      "id": "planet-chart"
    }
  })])]);

}]

export { render, staticRenderFns }