<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.add">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Add DTMF</div>
          <div class="plus-IOSIcon-container">
            
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                v-for="(option,index) in avaliableOptions" 
                :key="index" 
                class="basicWhiteIOScard-item" 
                @click="$modal.show('SelectExtension',{ 
                  type: 'add',
                  extra_data: { 
                    dtmf: option, 
                  },
                })"
              >
                <div class="headingInsideCard">{{ `Press ${option}` }}</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <vb-no-record v-if="avaliableOptions.length==0" :text="'There is no Option'" :design="3" />
            </div>

          </div>
        </div>
      </template>
      <template v-if="conditions.screen==screens.edit">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.remove.send?'':conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Option</div>
          <div 
            class="plus-IOSIcon-container" 
            @click="api.remove.send?'':$modal.show('SelectExtension',{ 
              type: 'edit',
              account: selectedOption.ivr_cmd_args,
              extra_data: {
                dtmf: selectedOption.user_dtmf,
              },
            })">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Label</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard onRightSide textAlignRight">
                    Press {{ selectedOption.user_dtmf }}
                  </div>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                
                <div class="headingInsideCard">For</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard onRightSide textAlignRight">
                    {{ selectedOption.accountname }}
                  </div>
                </div>
              </div>
            </div>

            <button class="IosFullWidthButton forRemove mb-16px" :disabled="api.remove.send" @click="remove()">
              <vb-spinner v-if="api.remove.send" />
              <template>Delete</template>
            </button>

          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Menu Options</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.add">
            <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992" />
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                v-for="option in options" 
                :key="option.id" 
                class="basicWhiteIOScard-item" 
                @click="conditions.screen=screens.edit;selected.option_id=option.id"
              >
                <div class="headingInsideCard">Press {{ option.user_dtmf }}</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard onRightSide textAlignRight mr-16px">
                    {{ option.accountname }}
                  </div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <vb-no-record v-if="options.length==0" :text="'There is no Option'" :design="3" />
            </div>

          </div>
        </div>
      </template>
      <SelectExtensionModal @selected="conditions.screen=screens.main;update($event)" />
    </modal>
  </div>
</template>

<script>
import SelectExtensionModal from '../extensions/SelectExtension.vue'
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils';
let screens = {
  main : 'main',
  add : 'add',
  edit : 'edit',
}
export default {
  name: 'MenuOptionsModal',
  components: {
    SelectExtensionModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'MenuOptions'
    },
  },
  data(){
    return {
      api: {
        update: this.$helperFunction.apiInstance({  }),
        remove: this.$helperFunction.apiInstance({  }),
      },
      data: {
        accountcode: '',
      },
      selected: {
        option_id: '',
      },
      conditions: {
        screen: screens.main
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getVoipIVRS',
    ]),
    screens(){ return screens },
    ivr(){ return this.getVoipIVRS?.[this?.data?.accountcode] ?? {} },
    schedule(){ return this.ivr?.schedules?.[0] ?? {} },
    accountcode(){ return this.data.accountcode },
    options(){ return this.schedule?.options ?? [] },
    avaliableOptions(){ return this.schedule?.avaliableOptions ?? []},
    selectedOption(){ return this.options?.find(op=>op.id==this.selected.option_id) },
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode=event?.params?.accountcode ?? ''
    },
    onBeforeClose(){
      this.api.update.reset()
      this.api.remove.reset()
      this.data.accountcode=''
      this.selected.option_id=''
      this.conditions.screen=screens.main
    },
    update(event){
      // console.log(event)
      let vm = this
      if(vm.api.update.send) return;
      vm.api.update.send=true
      VOIP_API.endpoints.menu.addoption({
        account: event.account,
        id: vm.accountcode,
        option: event.extra_data.dtmf,
        schedule_id: vm.schedule.id,
        schedule_no: 1,
        type: "extension",
        command: "callfwd",
        args: event.account,
      })
      .then(() => {
        vm.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        vm.$emit("update-list");
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update.send=false
      });
    },
    remove(){
      let vm = this;
      if(vm.api.remove.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.remove.send=true
            VOIP_API.endpoints.menu.resetoption(vm.accountcode, {
              option: vm.selectedOption.user_dtmf == 10 ? "*" : vm.selectedOption.user_dtmf,
              schedule: vm.schedule.id,
            })
            .then(() => {
              vm.appNotify({
                message: 'Successfully Deleted',
                type: 'success'
              })
              vm.conditions.screen=screens.main
              vm.selected.option_id=''
              vm.$emit("update-list");
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.remove.send=false
            });
          }
        },
      });
    },
  },
}
</script>

<style>

</style>