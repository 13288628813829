<template>
  <div>
    
    <modal 
      class="center-zoomIn-transition v2 m-w-500 AddPortNumberModal" 
      width="500px" 
      height="auto" 
      :scrollable="true" 
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <section class="ct-user-new dialer-edit s1">
        <form class="dialer-form lg-mar-bottom mb-0" @submit.prevent="add()">
          <div v-if="getIsMobile" class="dialer-edit-header flex-column">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <h2 class="dialer-edit-title">Add port number</h2>
              <b-icon class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            </div>
            <div class="dialer-edit-actions  w-100 d-flex justify-content-end mt-2">
              <button class="newDoneButton" :disabled="api.add_port_number.send">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api.add_port_number.send" />
                  <template v-else>Done</template>
                </span>
              </button>
            </div>
          </div>
          <div v-else class="dialer-edit-header">
            <div>
              <h2 class="dialer-settings-title newer">Add port number</h2>
            </div>
            <div class="dialer-edit-actions">
              <a class="newCloseButton" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder mb-4">
              <label>Number</label>
              <input 
                placeholder="Enter number" 
                class="w-100" 
                type="text" 
                v-vb-input-number-formate
                v-model="forms.add_port_number.number" 
                :disabled="api.add_port_number.send" 
              />
              <p v-if="forms.add_port_number.submitted && $v.forms.add_port_number.number.$invalid" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.add_port_number.number.required">* Number is required</span>
                <span v-else-if="!$v.forms.add_port_number.number.validNumber">* Number is invalid</span>
              </p>
              <p v-else-if="api.add_port_number.validation_errors.number" class="text text-danger animated bounceIntop">
                <span v-for="em in api.add_port_number.validation_errors.number" :key="em">* {{ em }}</span>
              </p>
            </div>
          </div>
          <div class="d-flex w-100 justify-content-end mt-4">
            <button class="fullWidthDoneButton" :disabled="api.add_port_number.send">
              <vb-spinner v-if="api.add_port_number.send" />
              <template v-else>Port number</template>
            </button>
          </div>






          <!--<div class="md-mar-vertical">
            <div class="dialer-team-name mb-4">
              <div class="dialer-input-field">
                <div class="w-100 dialer-input-field input-consistent-inner-shadows">
                  <label>Number</label>
                  <input 
                    placeholder="Enter Number" 
                    class="w-100" 
                    type="text" 
                    v-vb-input-number-formate
                    v-model="forms.add_port_number.number" 
                    :disabled="api.add_port_number.send" 
                  />
                </div>
                <p v-if="(forms.add_port_number.submitted && $v.forms.add_port_number.number.$invalid) || api.add_port_number.validation_errors.number" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.add_port_number.number.required">* Number is required</span>
                  <span v-else-if="!$v.forms.add_port_number.number.validNumber">* Number is invalid</span>
                  <span v-for="em in api.add_port_number.validation_errors.number" :key="em">* {{ em }}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="d-flex w-100 justify-content-end">
            <button class="dialer-button dialer-button-primary" :disabled="api.add_port_number.send">
              <vb-spinner v-if="api.add_port_number.send" />
              <template v-else>Port Number</template>
            </button>
          </div>-->
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { $fn, VOIP_API } from '../../utils';
import { mapGetters } from 'vuex';
export default {
  name: "AddPortNumberModal",
  props: {
    modalName: {
      type: String,
      default: 'AddPortNumberModal',
    },
  },
  inject:['isEmpty','appNotify'],
  data(){
    return {
      forms: {
        add_port_number: {
          number: '',
          reset(){
            this.number=''
            this.submitted = false
          },
          submitted: false,
        },
      },
      api: {
        add_port_number: this.$helperFunction.apiInstance({ validation_errors: true })
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
    ])
  },
  validations: {
    forms: {
      add_port_number: {
        number: {
          required,
          validNumber(value){ return $fn.validPhoneNumber(value,'','mobile') }, 
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){},
    onBeforeClose(){
      this.forms.add_port_number.reset()
      this.api.add_port_number.reset()
    },
    add(){
      let vm = this
      vm.forms.add_port_number.submitted=true
      vm.$v.forms.add_port_number.$touch();
      if (vm.$v.forms.add_port_number.$invalid || vm.api.add_port_number.send) return;
      vm.api.add_port_number.send = true
      vm.api.add_port_number.validation_errors={}
      VOIP_API.endpoints.address_books.createPortNumber({
        accountcode: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
        number: vm.forms.add_port_number.number,
      }).then(()=>{
        vm.forms.add_port_number.reset()
        vm.$modal.hide(vm.modalName)
        vm.$emit('added')
        vm.appNotify({
          message:  'Successfully added',
          type: 'success',
        })
      }).catch((ex)=>{
        vm.api.add_port_number.validation_errors=ex.own_errors
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.forms.add_port_number.submitted = false
        vm.api.add_port_number.send = false
      })
    },
  },
};
</script>
