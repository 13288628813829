var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": "TagCallNoteModal"
    },
    on: {
      "closed": _vm.onClosed,
      "before-open": _vm.beforeOpen
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.sendTag();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Tags")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "dialer-button dialer-button-delete",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('TagCallNoteModal');
      }
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.tag
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.tag ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]), _c('div', {
    staticClass: "dialer-call-notification"
  }, [_c('div', {
    staticClass: "dialer-call-notification-inner"
  }, [_c('div', {
    staticClass: "dialer-call-notification-text"
  }, [_c('div', {
    staticClass: "dialer-call-notification-sub-text"
  }, [_vm._v(" There you can select the tags of this call ")])])]), _c('div', {
    staticClass: "dialer-call-notification-actions"
  }, [_c('div', {
    staticClass: "dialer-call-notification-actions-inner"
  }, [_c('b-form', {
    staticClass: "dialer-searchBox-now-default my-3 mx-3 w-auto",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search here"
    },
    model: {
      value: _vm.filter.tag.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tag, "search", $$v);
      },
      expression: "filter.tag.search"
    }
  })], 1), _c('ul', {
    staticClass: "dialer-tagList"
  }, _vm._l(_vm.searchedTags, function (tag) {
    return _c('li', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.bg.text.border",
        value: _vm.form.tags.includes(tag.real_id) && {
          color: tag.colour,
          text_color: tag.text_color
        },
        expression: "form.tags.includes(tag.real_id) && {color:tag.colour,text_color:tag.text_color}",
        modifiers: {
          "bg": true,
          "text": true,
          "border": true
        }
      }],
      key: tag.real_id,
      class: {
        selected: _vm.form.tags.indexOf(tag.real_id) > -1
      },
      on: {
        "click": function ($event) {
          return _vm.handleTags(tag);
        }
      }
    }, [_vm._v(" " + _vm._s(tag.tag) + " ")]);
  }), 0)], 1)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }