var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_vm._v(" TrainingHRM ")])]);

}]

export { render, staticRenderFns }