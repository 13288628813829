<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup ticketDetailModal" 
      width="40%" 
      height="auto" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()" 
      :scrollable="true" 
      :name="modalName" 
      :clickToClose="false"
    >
      <div v-if="api.fetch_ticket.send" class="w-100">
        <div v-for="n in 4" :key="n.id" :class="`latestShimmerDesign w-100 my-4`" style="height:80px;"></div>
      </div>
      <div v-else class="SupportTicketDetail-container">
        <div class="dialer-edit-header align-items-start pt-0">
          <div class="customWidth-parent">
            <div>
              <h2 class="dialer-edit-title newer mb-0">Ticket #{{response.ticket | get_property('ticket.type_id')}}</h2>
              <div class="d-flex mt-2 customWidth">
                <h2 class="dialer-edit-title newer mb-0">Subject:</h2>
                <span class="ticket-subject ml-2">
                  {{response.ticket | get_property('ticket.subject')}}
                </span>
              </div>
            </div>
          </div>
          <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
        <div class="latestGreyBox-9-9-2023">
          <div class="ticketTopInfoContainer">
            <div class="ticketTopInfoContainer-row">
              <div class="ticketTopInfoContainer-column one">SUBMITTER</div>
              <div class="ticketTopInfoContainer-column two">{{response.ticket | get_property('ticket.user')}}</div>
            </div>
            <div class="ticketTopInfoContainer-row">
              <div class="ticketTopInfoContainer-column one">SUBMITTED</div>
              <div class="ticketTopInfoContainer-column two">{{response.ticket | get_property('ticket.created_at') | filter_date_current('',{ formate_now: true, })}}</div>
            </div>
            <div class="ticketTopInfoContainer-row">
              <div class="ticketTopInfoContainer-column one">DEPARTMENT</div>
              <div class="ticketTopInfoContainer-column two">{{response.ticket | get_property('ticket.department')}}</div>
            </div>
            <div class="ticketTopInfoContainer-row">
              <div class="ticketTopInfoContainer-column one">STATUS/PRIORITY</div>
              <div class="ticketTopInfoContainer-column two">{{ response.ticket | get_property("ticket.status.name") }} </div>
            </div>
          </div>
          <Comments 
            v-if="response.ticket.ticket" 
            :ticket="response.ticket" 
            @status-updated="fetchTicket()"
          />
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils/index';
import { mapGetters } from 'vuex';
import Comments from './comments/index.vue'
export default {
  name: "UpdateSupportTicketModal",
  components: {
    Comments,
  },
  props: {
    modalName: {
      type: String,
      default: 'UpdateSupportTicketModal',
    },
  },
  data() {
    return {
      api: {
        fetch_ticket: this.$helperFunction.apiInstance(),
      },
      response: {
        ticket: {},
      },
      data: {
        ticket_id: '',
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser',
    ]),
  },
  methods: {
    onBeforeOpen(event){
      this.data.ticket_id=event?.params?.ticket_id ?? ''
      this.fetchTicket()
    },
    onBeforeClose(){
      this.api.fetch_ticket.reset()
      this.data.ticket_id=''
      this.response.ticket={}
    },
    async fetchTicket(){
      if(this.api.fetch_ticket.send) return;
      try {
        this.api.fetch_ticket.send=true
        const { data } = await VOIP_API.endpoints.tickets.getById(this.data.ticket_id)
        this.response.ticket=data ?? {}
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_ticket.send=false
      }
    },
  },
};
</script>
