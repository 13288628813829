var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup global-modal-hrm Scrollable-hrm",
    attrs: {
      "name": "HRMAddNewTaskOnboardModal",
      "clickToClose": true,
      "scrollable": true
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-hrm-modal-header mar-top-hrm20 mar-bottom-hrm30"
  }, [_c('h2', {
    staticClass: "dialer-modal-hrm-title"
  }, [_vm._v("Add new task")]), _c('button', {
    staticClass: "dialer-hrm-button-modal",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('HRMAddNewTaskOnboardModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Task")]), _c('div', {
    staticClass: "dd-gm-container"
  }, [_c('b-dropdown', {
    staticClass: "gm-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "gm-dropi-contenti"
        }), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("All")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Single")])], 1)], 1)]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Title")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Description (optional)")]), _c('b-form-textarea', {
    staticClass: "gm-hrm-textnote",
    attrs: {
      "id": "textarea-small",
      "size": "sm",
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Link name")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "mar-bottom-hrm15"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Link URL")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "mar-bottom-hrm10"
  }, [_c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Require attachment")])])], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Assigned to")]), _c('div', {
    staticClass: "dd-gm-container"
  }, [_c('b-dropdown', {
    staticClass: "gm-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "gm-dropi-contenti"
        }), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("All")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Single")])], 1)], 1)]), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('h5', {
    staticClass: "onboard-inline-text mb-imp mr-3"
  }, [_vm._v("Task to be completed")]), _c('b-form-input', {
    staticClass: "gm-hrm-sm-fields",
    attrs: {
      "list": "input-list",
      "placeholder": ""
    }
  }), _c('h5', {
    staticClass: "onboard-inline-text mb-imp ml-3"
  }, [_vm._v("days after employee start date")])], 1), _c('button', {
    staticClass: "btn-primarynewupdated1 mt-4 mar-bottom-hrm50"
  }, [_vm._v("Add task")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }