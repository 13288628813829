var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return true ? _c('div', {
    staticClass: "chat-actions pt-3"
  }, [_c('input', {
    ref: "file_upload_in_chat",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": function ($event) {
        return _vm.filesUpload($event.target.files);
      }
    }
  }), [_c('div', {
    staticClass: "dialer-chatBox-typingMessage-area v2",
    class: {
      'bg-transparent': _vm.is_file_uploading_error,
      'messageIsBeingTyped': _vm.typingMessage
    }
  }, [_c('div', {
    staticClass: "position-relative"
  }, [_vm.channelData.type !== 'user' && !_vm.isEmpty(_vm.afterSearchMembers) ? _c('div', {
    staticClass: "dialer-tags"
  }, [_c('ul', {
    attrs: {
      "id": `${_vm.channel_id}-mentions-items`
    }
  }, _vm._l(_vm.afterSearchMembers, function (user, index) {
    return _c('li', {
      key: user.user.user_chat_id
    }, [_c('a', {
      staticClass: "d-flex align-items-center",
      style: {
        'background-color': _vm.selectedUserIndex === index ? '#eee' : ''
      },
      on: {
        "click": function ($event) {
          return _vm.selectUser(user);
        },
        "mouseover": function ($event) {
          _vm.selectedUserIndex = index;
        }
      }
    }, [_c('span', {
      staticClass: "avatar d-flex align-items-center justify-content-center p-1"
    }, [_c('img', {
      staticStyle: {
        "height": "40px",
        "width": "40px"
      },
      attrs: {
        "src": user.profile_img,
        "alt": user.user.display_name
      }
    })]), _c('div', {
      staticClass: "name"
    }, [_c('h5', {
      staticClass: "m-0 text-dotted"
    }, [_vm._v(_vm._s(user.user.display_name))])])])]);
  }), 0)]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.typingMessage,
      expression: "typingMessage"
    }],
    staticClass: "messageIsBeingTyped-text"
  }, [_c('p', {
    staticClass: "pl-2 mb-1 isTypingText"
  }, [_c('span', [_vm._v(_vm._s(_vm.typingMessage))]), _vm._v(" is typing ... ")])]), _vm.is_file_uploading_error ? _c('div', [_vm._v("Uploading in process")]) : _vm._e(), _c('div', {
    staticClass: "dialer-uploadableFiles-showCase"
  }, [_vm._l(_vm.$store.state.mattermost.channels[_vm.channel_id].actions.files, function (file) {
    return _c('File', {
      key: file.uid,
      attrs: {
        "file": file,
        "channel_id": _vm.channel_id,
        "random_id": _vm.random_id
      }
    });
  }), _vm.filesLength > 0 ? _c('div', {
    staticClass: "bill-add-file ml-2 mt-2 cursor_pointer",
    on: {
      "click": function ($event) {
        return _vm.$refs.file_upload_in_chat.click();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "plus",
      "scale": "3"
    }
  })], 1) : _vm._e()], 2)]), _vm.update ? _c('div') : _vm._e(), _vm.$store.getters.getIsMobile ? _c('div', {
    class: `dialer-chatBox-actionIcon-area ${_vm.channelData.actions.is_important ? 'important_message_bill' : ''}`
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "d-inline-block px-1",
    staticStyle: {
      "position": "relative"
    }
  }, [_vm.is_emoji_modal ? _c('div', {
    staticStyle: {
      "position": "absolute",
      "bottom": "40px"
    }
  }, [_c('v-emoji-picker', {
    staticClass: "mobileChatEmojiContainer",
    on: {
      "select": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message = _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message + $event.data;
      }
    }
  })], 1) : _vm._e(), _c('a', {
    staticClass: "dialer-icon",
    on: {
      "click": function ($event) {
        _vm.is_emoji_modal = !_vm.is_emoji_modal;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "shush",
    attrs: {
      "width": "48",
      "height": "48",
      "icon": _vm.is_emoji_modal ? 'dialer-cross-icon' : 'chat-smileyIcon'
    }
  })], 1)]), !_vm.isEmpty(_vm.channelData.actions.edit_post) ? _c('li', {
    staticClass: "d-inline-block px-1",
    on: {
      "click": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.edit_post = {};
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message = '';
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.is_important = false;
      }
    }
  }, [_c('a', {
    staticClass: "ico-chat dialer-icon red-icon",
    attrs: {
      "href": "#"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "variant": "danger"
    }
  })], 1)]) : _vm._e()]), _c('div', {
    staticClass: "dialer-textArea d-flex flex-grow-1 flex-shrink-1 flex-column justify-content-center px-2"
  }, [_vm.is_submitted && !_vm.isEmpty(_vm.filesUploadings) ? _c('div', {
    staticClass: "file_uploading_msg"
  }, [_vm._v("Uploading in process")]) : _vm._e(), !_vm.isEmpty(_vm.channelData.actions.reply_post) ? _c('div', {
    staticClass: "d-flex repliedText-container justify-content-between w-100 pb-2 mb-2 border-bottom border-secondary"
  }, [_c('p', {
    staticClass: "pl-2 mb-1 d-flex flex-column align-items-start"
  }, [_c('img', {
    attrs: {
      "src": _vm.commaIcon,
      "alt": ""
    }
  }), _c('span', {
    staticClass: "pl-3 repliedText"
  }, [_vm._v(_vm._s(_vm.channelData.actions.reply_post.message))])]), _c('b-icon', {
    staticClass: "ml-3 cursor_pointer",
    attrs: {
      "icon": "x-circle"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.reply_post = {};
      }
    }
  })], 1) : _vm._e(), _c('b-form', {
    staticClass: "w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message,
      expression: "$store.state.mattermost.channels[channel_id].actions.message"
    }],
    class: [_vm.channelData.actions.is_important ? 'important_message dialer-textArea w-100' : 'dialer-textArea  w-100'],
    staticStyle: {
      "height": "auto"
    },
    attrs: {
      "placeholder": "Write your message",
      "rows": "1",
      "id": `${_vm.channel_id}-text-area`
    },
    domProps: {
      "value": _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message
    },
    on: {
      "keypress": _vm.onKeyPress,
      "keydown": _vm.onKeyDown,
      "paste": _vm.onPaste,
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.$store.state.mattermost.channels[_vm.channel_id].actions, "message", $event.target.value);
      }
    }
  })])], 1), !_vm.$store.state.mattermost.channels[_vm.channel_id].actions.message ? _c('ul', {
    staticClass: "list-unstyled mb-0"
  }, [_vm.permissions.mic != 'denied' ? _c('li', {
    staticClass: "d-inline-flex px-1 justify-content-between audio-record-area",
    class: !_vm.recorded.audio.is_show ? 'align-items-center' : 'align-items-end open'
  }, [!_vm.recorded.audio.is_show ? _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "dialer-icon",
    attrs: {
      "title": "Record a message"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_show = true;
        _vm.recorded.audio.is_start = true;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-micLineIcon",
      "width": "48",
      "height": "48"
    }
  })], 1) : [_c('a', {
    staticClass: "dialer-icon",
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_cancelled = true;
        _vm.recorded.audio.is_start = false;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "variant": "danger",
      "font-scale": "1.5"
    }
  })], 1), _c('div', {
    staticClass: "recording-timer"
  }, [_c('div', {
    staticClass: "recording-timer-inner"
  }, [_c('b-icon', {
    attrs: {
      "icon": "circle-fill",
      "variant": "danger",
      "animation": "throb",
      "scale": "0.8",
      "font-scale": "1.5"
    }
  }), _c('span', {
    staticClass: "timer"
  }, [_vm._v(" " + _vm._s(_vm._f("time_audio")(_vm.recorded.audio.time)) + " ")])], 1), _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "dialer-icon",
    attrs: {
      "title": "send audio message"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_cancelled = false;
        _vm.recorded.audio.is_start = false;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "check",
      "font-scale": "1.5"
    }
  })], 1)])]], 2) : _vm._e()]) : _vm._e(), !_vm.$store.state.mattermost.channels[_vm.channel_id].actions.message ? _c('b-dropdown', {
    staticClass: "ico-filter chat-actions-more-dots",
    attrs: {
      "varient": "link",
      "offset": "25",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "dialer-icon"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "chat-viewMoreDotsIcon",
            "width": "5",
            "height": "40"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1485667373)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.is_important = !_vm.channelData.actions.is_important;
      }
    }
  }, [_c('a', {
    staticClass: "dialer-icon d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "chat-importantIcon",
    attrs: {
      "icon": "chat-importantIcon",
      "width": "48",
      "height": "48"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Important message")])], 1)]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.random_id}_RecordedVideoModal`);
      }
    }
  }, [_c('a', {
    staticClass: "dialer-icon d-flex align-items-center"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-videoCameraLineIcon",
      "width": "48",
      "height": "48"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Record a message")])], 1)]), _vm.isEmpty(_vm.channelData.actions.edit_post) ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$refs.file_upload_in_chat.click();
      }
    }
  }, [_c('a', {
    staticClass: "dialer-icon d-flex align-items-center",
    attrs: {
      "href": "#"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-paperclip",
      "width": "48",
      "height": "48"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Attach file")])], 1)]) : _vm._e()], 1) : _vm._e()], 1) : _c('div', {
    class: `dialer-chatBox-actionIcon-area ${_vm.channelData.actions.is_important ? 'important_message_bill' : ''}`
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    staticClass: "d-inline-block px-1",
    staticStyle: {
      "position": "relative"
    }
  }, [_vm.is_emoji_modal ? _c('div', {
    staticClass: "vEmojiPicker",
    staticStyle: {
      "position": "absolute",
      "bottom": "85px"
    }
  }, [_c('v-emoji-picker', {
    on: {
      "select": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message = _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message + $event.data;
      }
    }
  })], 1) : _vm._e(), _c('a', {
    staticClass: "dialer-icon",
    on: {
      "click": function ($event) {
        _vm.is_emoji_modal = !_vm.is_emoji_modal;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "shush",
    attrs: {
      "width": "48",
      "height": "48",
      "icon": _vm.is_emoji_modal ? 'dialer-cross-icon' : 'chat-smileyIcon'
    }
  })], 1)])]), _c('div', {
    class: `dialer-textArea d-flex flex-grow-1 flex-shrink-1 flex-column justify-content-center px-2 ${_vm.channelData.actions.reply_post.message ? 'QuotedText-present' : ''}`
  }, [_vm.is_submitted && !_vm.isEmpty(_vm.filesUploadings) ? _c('div', {
    staticClass: "file_uploading_msg"
  }, [_vm._v("Uploading in process")]) : _vm._e(), !_vm.isEmpty(_vm.channelData.actions.reply_post) ? _c('div', {
    staticClass: "d-flex justify-content-between w-100 pb-2 mb-2 border-bottom border-secondary QuotedText-container"
  }, [_c('p', {
    staticClass: "pl-2 mb-1 d-flex flex-column align-items-start"
  }, [_c('img', {
    attrs: {
      "src": _vm.commaIcon,
      "alt": ""
    }
  }), _c('span', {
    staticClass: "pl-3 QuotedText"
  }, [_vm._v(_vm._s(_vm.channelData.actions.reply_post.message))]), _c('small', {
    staticClass: "dialer-user-msg-time ml-2",
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(_vm._s(_vm._f("time")(_vm.channelData.actions.reply_post.create_at)))])]), _c('b-icon', {
    staticClass: "ml-3 cursor_pointer",
    attrs: {
      "icon": "x-circle"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.reply_post = {};
      }
    }
  })], 1) : _vm._e(), _c('b-form', {
    staticClass: "w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message,
      expression: "$store.state.mattermost.channels[channel_id].actions.message"
    }],
    staticClass: "dialer-textArea w-100",
    staticStyle: {
      "height": "auto"
    },
    attrs: {
      "placeholder": "Write your message",
      "id": `${_vm.channel_id}-text-area`
    },
    domProps: {
      "value": _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message
    },
    on: {
      "keypress": _vm.onKeyPress,
      "keydown": _vm.onKeyDown,
      "paste": _vm.onPaste,
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.$store.state.mattermost.channels[_vm.channel_id].actions, "message", $event.target.value);
      }, _vm.onInput]
    }
  })])], 1), _c('ul', {
    staticClass: "list-unstyled mb-0"
  }, [!_vm.isEmpty(_vm.channelData.actions.edit_post) ? _c('li', {
    staticClass: "d-inline-block px-1",
    on: {
      "click": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.edit_post = {};
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message = '';
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.is_important = false;
      }
    }
  }, [_c('a', {
    staticClass: "ico-chat dialer-icon red-icon",
    attrs: {
      "href": "#"
    }
  }, [_c('b-icon', {
    staticClass: "newChatdesignIcon-close",
    attrs: {
      "icon": "x",
      "variant": "danger"
    }
  })], 1)]) : [_c('li', {
    staticClass: "d-inline-block px-1"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Important message"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.is_important = !_vm.channelData.actions.is_important;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "chat-importantIcon-v2",
    attrs: {
      "icon": "chat-importantIcon-v2",
      "width": "16",
      "height": "26"
    }
  })], 1)]), _vm.permissions.mic != 'denied' ? _c('li', {
    class: !_vm.recorded.audio.is_show ? 'd-inline-flex px-1 justify-content-between align-items-center audio-record-area' : 'd-inline-flex px-1 justify-content-between align-items-end audio-record-area open'
  }, [!_vm.recorded.audio.is_show ? _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "dialer-icon",
    attrs: {
      "title": "Record audio message"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_show = true;
        _vm.recorded.audio.is_start = true;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "chat-micLineIcon",
    attrs: {
      "icon": "chat-micLineIcon",
      "width": "48",
      "height": "48"
    }
  })], 1) : [_c('a', {
    staticClass: "dialer-icon",
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_cancelled = true;
        _vm.recorded.audio.is_start = false;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "variant": "danger",
      "font-scale": "1.5"
    }
  })], 1), _c('div', {
    staticClass: "recording-timer"
  }, [_c('div', {
    staticClass: "recording-timer-inner"
  }, [_c('b-icon', {
    attrs: {
      "icon": "circle-fill",
      "variant": "danger",
      "animation": "throb",
      "scale": "0.8",
      "font-scale": "1.5"
    }
  }), _c('span', {
    staticClass: "timer"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.recorded.audio.time)))])], 1), _c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "dialer-icon",
    attrs: {
      "title": "send audio message"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_cancelled = false;
        _vm.recorded.audio.is_start = false;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "check",
      "font-scale": "1.5"
    }
  })], 1)])]], 2) : _vm._e(), _c('li', {
    staticClass: "d-inline-block pr-0 pl-2"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "dialer-icon",
    attrs: {
      "title": "Record video message"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.random_id}_RecordedVideoModal`);
      }
    }
  }, [_c('vb-icon', {
    staticClass: "chat-videoCameraLineIcon",
    attrs: {
      "icon": "chat-videoCameraLineIcon",
      "width": "48",
      "height": "48"
    }
  })], 1)]), _vm.isEmpty(_vm.channelData.actions.edit_post) ? _c('li', {
    staticClass: "d-inline-block pr-1 pl-0",
    on: {
      "click": function ($event) {
        return _vm.$refs.file_upload_in_chat.click();
      }
    }
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "dialer-icon",
    attrs: {
      "title": "Attach file",
      "href": "#"
    }
  }, [_c('vb-icon', {
    staticClass: "chat-paperclip",
    attrs: {
      "icon": "chat-paperclip",
      "width": "48",
      "height": "48"
    }
  })], 1)]) : _vm._e()]], 2)])]), _c('div', {
    staticClass: "chat-actions-sendIcon md-mar-left"
  }, [_c('a', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "submit-icon dialer-icon",
    attrs: {
      "title": "Send"
    },
    on: {
      "click": _vm.submit
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-sendMsgIcon",
      "width": "66",
      "height": "66"
    }
  })], 1)])]], 2) : _c('div', {
    staticClass: "bill-1122-chat-action"
  }, [_c('input', {
    ref: "file_upload_in_chat",
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "type": "file",
      "multiple": ""
    },
    on: {
      "change": function ($event) {
        return _vm.filesUpload($event.target.files);
      }
    }
  }), _c('div', {
    staticClass: "bill-1122-input-bar"
  }, [_vm.typingMessage ? _c('div', {
    staticClass: "bill-1122-user-typing"
  }, [_vm._v(_vm._s(_vm.typingMessage) + " is typing ...")]) : _vm._e(), _vm.is_file_uploading_error ? _c('div', {
    staticClass: "bill-1122-uploading-error"
  }, [_vm._v("Uploading in process")]) : _vm._e(), _c('div', {
    staticClass: "bill-1122-input-bar-main",
    class: {
      'important': _vm.channelData.actions.is_important,
      'uploading-error': _vm.is_file_uploading_error
    }
  }, [!_vm.isEmpty(_vm.channelData.actions.reply_post) ? _c('div', {
    staticClass: "bill-1122-reply"
  }, [_c('div', {
    staticClass: "bill-1122-reply-quote"
  }, [_c('img', {
    attrs: {
      "src": _vm.commaIcon,
      "alt": ""
    }
  })]), _c('div', {
    staticClass: "bill-1122-reply-message"
  }, [_vm._v(" " + _vm._s(_vm.channelData.actions.reply_post.message) + " ")]), _c('div', {
    staticClass: "bill-1122-reply-close"
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x-circle"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.reply_post = {};
      }
    }
  })], 1)]) : _vm._e(), _vm.is_emoji_modal ? _c('div', {
    staticClass: "bill-1122-emoji-panel"
  }, [_c('v-emoji-picker', {
    on: {
      "select": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message = _vm.channelData.actions.message + $event.data;
      }
    }
  })], 1) : _vm._e(), _vm.filesLength > 0 ? _c('div', {
    staticClass: "bill-1122-files"
  }, [_vm._l(_vm.$store.state.mattermost.channels[_vm.channel_id].actions.files, function (file) {
    return _c('File', {
      key: file.uid,
      attrs: {
        "file": file,
        "channel_id": _vm.channel_id,
        "random_id": _vm.random_id
      }
    });
  }), _c('div', {
    staticClass: "bill-1122-file-add ml-2 cursor_pointer",
    on: {
      "click": function ($event) {
        return _vm.$refs.file_upload_in_chat.click();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "plus",
      "scale": "3"
    }
  })], 1)], 2) : _vm._e(), _c('div', {
    staticClass: "bill-1122-input-bar-sub-main"
  }, [_c('div', {
    staticClass: "bill-1122-emoji"
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "width": "48",
      "height": "48",
      "icon": _vm.is_emoji_modal ? 'dialer-cross-icon' : 'chat-smileyIcon'
    },
    on: {
      "click": function ($event) {
        _vm.is_emoji_modal = !_vm.is_emoji_modal;
      }
    }
  })], 1), _c('div', {
    staticClass: "bill-1122-editor"
  }, [_c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.channelData.type !== 'user' && !_vm.isEmpty(_vm.afterSearchMembers),
      expression: "channelData.type !== 'user' && !isEmpty(afterSearchMembers)"
    }],
    staticClass: "bill-1122-mention",
    attrs: {
      "id": `${_vm.channel_id}-mentions`
    }
  }, _vm._l(_vm.afterSearchMembers, function (user, index) {
    return _c('div', {
      key: user.user.user_chat_id,
      staticClass: "bill-1122-mention-item",
      class: {
        'hoverd': _vm.selectedUserIndex == index
      },
      on: {
        "click": function ($event) {
          return _vm.selectUser(user);
        },
        "mouseover": function ($event) {
          _vm.selectedUserIndex = index;
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": user.user.user_chat_id
      }
    })], 1);
  }), 0), _c('div', {
    staticClass: "bill-1122-input"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit.apply(null, arguments);
      }
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message,
      expression: "$store.state.mattermost.channels[channel_id].actions.message"
    }],
    attrs: {
      "placeholder": "Write your message",
      "id": `${_vm.channel_id}-text-area`
    },
    domProps: {
      "value": _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message
    },
    on: {
      "keypress": _vm.onKeyPress,
      "keydown": _vm.onKeyDown,
      "paste": _vm.onPaste,
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.$store.state.mattermost.channels[_vm.channel_id].actions, "message", $event.target.value);
      }, _vm.onInput]
    }
  })])], 1)]), _c('div', {
    staticClass: "bill-1122-actions"
  }, [_c('div', [!_vm.isEmpty(_vm.channelData.actions.edit_post) ? _c('div', {
    staticClass: "bill-1122-close cursor_pointer",
    on: {
      "click": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.edit_post = {};
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.message = '';
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.is_important = false;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "scale": "2",
      "variant": "danger"
    }
  })], 1) : _vm.recorded.audio.is_show ? _c('div', {
    staticClass: "bill-1122-recording-process"
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "variant": "danger",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_cancelled = true;
        _vm.recorded.audio.is_start = false;
      }
    }
  }), _c('b-icon', {
    attrs: {
      "icon": "circle-fill",
      "variant": "danger",
      "animation": "throb",
      "scale": "0.8",
      "font-scale": "1.5"
    }
  }), _c('span', {
    staticClass: "timer"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.recorded.audio.time)))]), _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "check",
      "variant": "success",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_cancelled = false;
        _vm.recorded.audio.is_start = false;
      }
    }
  })], 1) : [_c('div', {
    staticClass: "plus",
    attrs: {
      "id": "plus",
      "onmouseenter": "this.classList.add('plus--active')",
      "onmouseleave": "this.classList.remove('plus--active')"
    }
  }, [_c('div', {
    staticClass: "plus__line plus__line--v"
  }, [_c('a', {
    staticClass: "plus__link",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].actions.is_important = !_vm.channelData.actions.is_important;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "chat-importantIcon-v2",
    attrs: {
      "icon": "chat-importantIcon-v2",
      "width": "16",
      "height": "26"
    }
  })], 1), _vm.permissions.mic != 'denied' ? _c('a', {
    staticClass: "plus__link",
    class: {
      'recording-process': !_vm.recorded.audio.is_show
    },
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_show = true;
        _vm.recorded.audio.is_start = true;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-micLineIcon",
      "width": "48",
      "height": "48"
    }
  })], 1) : _vm._e(), _c('a', {
    staticClass: "plus__link",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.random_id}_RecordedVideoModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-videoCameraLineIcon",
      "width": "48",
      "height": "48"
    }
  })], 1), _vm.isEmpty(_vm.channelData.actions.edit_post) ? _c('a', {
    staticClass: "plus__link",
    attrs: {
      "href": "#"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.file_upload_in_chat.click();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-paperclip",
      "width": "48",
      "height": "48"
    }
  })], 1) : _vm._e()]), _c('vb-icon', {
    attrs: {
      "icon": "chat-videoCameraLineIcon",
      "width": "48",
      "height": "48"
    }
  })], 1)]], 2)])])])]), _c('div', {
    staticClass: "bill-1122-right-icons"
  }, [_c('div', {
    staticClass: "bill-1122-submit cursor_pointer",
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-sendMsgIcon",
      "width": "66",
      "height": "66"
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }