<template>
  <div class="dialer-soundSetting-container">
    <div class="dialer-soundSetting dialer-soundSetting-responsive">
      <h4>Audio</h4>
      <div class="dialer-soundSetting-device dialer-volumeSetting-device">
        <div class="dialer-soundSetting-device-inner">
          <span class="dialer-soundSetting-device-name"> Microphone </span>
          <div class="dialer-dropdown dialer-soundSetting-device-dropdown">
            <b-dropdown id="sound-setting-micro-devices" text="Select Device">
              <template #button-content>
                <span class="d-flex align-items-center p-0">
                  <span>{{ media_devices_setting.selectedMicDevice | get_property('label','Select Device') }}</span>
                  <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                </span>
              </template>
              <b-dropdown-item v-for="device in media_devices_setting.audioinputs" :key="device.deviceId" @click="media_devices_setting.micId=device.deviceId;">
                <vb-svg v-if="device.deviceId==media_devices_setting.mic_id" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span> {{ device.label }} </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <MicrophoneTester v-if="media_devices_setting.mic_id" :deviceId="media_devices_setting.mic_id" />
      </div>

      <div class="dialer-soundSetting-device dialer-volumeSetting-device">
        <div class="dialer-soundSetting-device-inner">
          <span class="dialer-soundSetting-device-name"> Speaker </span>
          <div class="dialer-dropdown dialer-soundSetting-device-dropdown">
            <b-dropdown id="sound-setting-micro-devices" text="Select Device">
              <template #button-content>
                <span class="d-flex align-items-center p-0">
                  <span> {{ media_devices_setting.selectedSpeakerDevice | get_property('label','Select Device') }} </span>
                  <b-icon-chevron-down scale="1.1" />
                </span>
              </template>
              <b-dropdown-item v-for="device in media_devices_setting.audiooutputs" :key="device.deviceId" @click="media_devices_setting.speakerId=device.deviceId">
                <vb-svg v-if="media_devices_setting.speaker_id== device.deviceId" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span>{{ device.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <input :value="media_devices_setting.speaker_volume" min="0" max="100" type="range" @change="media_devices_setting.speakerVolume=$event.target.value" step="1" />
        <audio :show="false" id="testing-audio" loop :src="require('@/assets/sounds/incoming.mp3')" />
      </div>

      <div class="dialer-soundSetting-device border-0">
        <span class="dialer-soundSetting-device-name"> Video </span>
        <div class="dialer-dropdown dialer-soundSetting-device-dropdown">
          <b-dropdown id="sound-setting-micro-devices" text="Select Device">
            <template #button-content>
              <span class="d-flex align-items-center p-0">
                <span> {{ media_devices_setting.selectedCameraDevice | get_property('label','Select Device') }} </span>
                <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
              </span>
            </template>
            <b-dropdown-item v-for="device in media_devices_setting.videoinputs" :key="device.deviceId" @click="media_devices_setting.cameraId=device.deviceId" >
              <vb-svg v-if="media_devices_setting.camera_id == device.deviceId" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              <span> {{ device.label }} </span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <CameraTester v-if="media_devices_setting.camera_id" :deviceId="media_devices_setting.camera_id" />
      
      <div class="dialer-soundSetting-testing">
        <div class="dialer-soundSetting-testing-audio" @click=" $parent.$parent.conditions.clicked = true; is_play = !is_play; " >
          <vb-svg id="toggle-play" :name=" !is_play ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
          <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
        </div>

        <div @click=" isEmpty(testCallSession) ? testCall() : $store.state.sip.phone.activeSession.hangup() " class=" dialer-soundSetting-testing-audio dialer-soundSetting-testing-call " >
          <vb-svg name="dialer-outlineCall-icon" width="18.801" stroke="" height="18.802" viewBox="0 0 18.801 18.802" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
          <span class="dialer-soundSetting-testing-audio-text"> {{ isEmpty(testCallSession) ? "Make a free test call" : "End Call" }} </span>
        </div>
      </div>


      <div class="dialer-soundSetting-device dialer-volumeSetting-device">
        <div class="dialer-soundSetting-device-inner">
          <span class="dialer-soundSetting-device-name">Ringtone</span>
          <div class="dialer-dropdown dialer-soundSetting-device-dropdown">
            <b-dropdown id="sound-setting-micro-devices" text="Select Device">
              <template #button-content>
                <span class="d-flex align-items-center p-0">
                  <span> {{ selectedRingtone | get_property('label','Select Ringtone') }} </span>
                  <b-icon-chevron-down scale="1.1" />
                </span>
              </template>
              <b-dropdown-item v-for="(ring, key) in ringtones" :key="key" @click="media_devices_setting.ringtoneAudio=ring.value;">
                <vb-svg v-if="media_devices_setting.ringtoneAudio==ring.value" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span>{{ ring.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>
      
      <div class="dialer-soundSetting-device dialer-volumeSetting-device">
        <div class="dialer-soundSetting-device-inner">
          <span class="dialer-soundSetting-device-name">Ringing Device</span>
          <div class="dialer-dropdown dialer-soundSetting-device-dropdown">
            <b-dropdown id="sound-setting-micro-devices" text="Select Device">
              <template #button-content>
                <span class="d-flex align-items-center p-0">
                  <span> {{ media_devices_setting.selectedRingingSpeakerDevice | get_property('label','Select Device') }} </span>
                  <b-icon-chevron-down scale="1.1" />
                </span>
              </template>
              <b-dropdown-item v-for="device in media_devices_setting.audiooutputs" :key="device.deviceId" @click="media_devices_setting.ringingSpeakerId=device.deviceId;">
                <vb-svg v-if="media_devices_setting.ringing_speaker_id==device.deviceId" name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span>{{ device.label }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>

        </div>
        <input :value="media_devices_setting.ringing_speaker_volume" min="0" max="100" type="range" @change="media_devices_setting.ringingSpeakerVolume=$event.target.value" step="1" />
        <audio :show="false" id="ringing-testing-audio" loop :src="require(`@/assets/sounds/${media_devices_setting.ringtone}`)" />
      </div>

      <div class="dialer-soundSetting-testing">
        <div class="dialer-soundSetting-testing-audio" @click=" $parent.$parent.conditions.clicked = true; ringing_is_play = !ringing_is_play; " >
          <vb-svg id="toggle-play" :name=" !ringing_is_play ? 'dialer-outlinePlay-icon' : 'dialer-pause-arrowhead' " width="16" stroke="" height="18.035" viewBox="0 0 16 18.035" fill="none" stroke-width="0" stroke-linecap="round" stroke-linejoin="round" ></vb-svg>
          <span class="dialer-soundSetting-testing-audio-text"> Test audio </span>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import MicrophoneTester from "../Common/TestingMedias/Microphone.vue";
import CameraTester from "../Common/TestingMedias/Camera.vue";
import { mapState } from 'vuex';
import { events } from '../../utils';
export default {
  name: "SoundSetting",
  inject:['isEmpty'],
  components: {
    MicrophoneTester,
    CameraTester,
  },
  data() {
    return {
      is_play: false,
      ringing_is_play: false,
    };
  },
  computed: {
    ...mapState({
      media_devices_setting: state=>state.media_devices_setting,
      sip_phone: state=>state.sip.phone
    }),
    ringtones(){ 
      return [
        {
          label: "Ringtone one",
          value: "dtmf.mp3",
        },
        {
          label: "Ringtone two",
          value: "incoming.mp3",
        },
        {
          label: "Ringtone three",
          value: "outgoing.mp3",
        },
      ] 
    },
    selectedRingtone(){return this.ringtones.find(i=>i.value==this.media_devices_setting.ringtone)},
    testCallSession() {
      const active_session = this.sip_phone?.activeSession
      return active_session?.number=="*154*" ? active_session : {}
    },
  },
  watch: {
    "media_devices_setting.speaker_volume": "mediaDeviceSettingSpeakerVolume",
    "media_devices_setting.speaker_id": "mediaDeviceSettingSpeakerId",
    "media_devices_setting.ringing_speaker_volume": "mediaDeviceSettingRingingSpeakerVolume",
    "media_devices_setting.ringing_speaker_id": "mediaDeviceSettingRingingSpeakerId",
    "media_devices_setting.ringtone": "mediaDeviceSettingRingtone",
    is_play(is_play){
      const audio = document.getElementById("testing-audio");
      if (is_play) {
        audio?.play?.();
      } else {
        audio?.pause?.();
      }
    },
    ringing_is_play(new_val) {
      const audio = document.getElementById("ringing-testing-audio");
      if (new_val) {
        this.is_play = false;
        audio?.play?.();
      } else {
        audio?.pause?.();
      }
    },
  },
  methods: {
    mediaDeviceSettingSpeakerId(speaker_id){
      const audio = document.getElementById("testing-audio");
      if(audio){
        audio.setSinkId(speaker_id);
      }
    },
    mediaDeviceSettingSpeakerVolume(speaker_volume){
      const audio = document.getElementById("testing-audio");
      if(audio){
        audio.volume = speaker_volume / 100;
      }
    },
    mediaDeviceSettingRingingSpeakerId(ringing_speaker_id){
      const audio = document.getElementById("ringing-testing-audio");
      if(audio){
        audio.setSinkId(ringing_speaker_id);
      }
    },
    mediaDeviceSettingRingingSpeakerVolume(ringing_speaker_volume){
      const audio = document.getElementById("ringing-testing-audio");
      if(audio){
        audio.volume = ringing_speaker_volume / 100;
      }
    },
    mediaDeviceSettingRingtone(){
      let vm = this
      const audio = document.getElementById("ringing-testing-audio");
      if(audio){
        audio.load();
        audio.onloadedmetadata = () => {
          if(vm.ringing_is_play){
            audio.play()
          }
        }
      }
    },
    mediaDevicesSettingSink(){
      if(this.media_devices_setting?.speaker_id !== undefined){ this.mediaDeviceSettingSpeakerId(this.media_devices_setting.speaker_id) }
      if(this.media_devices_setting?.ringing_speaker_id !== undefined){ this.mediaDeviceSettingRingingSpeakerId(this.media_devices_setting.ringing_speaker_id) }
      if(this.media_devices_setting?.speaker_volume !== undefined){ this.mediaDeviceSettingSpeakerVolume(this.media_devices_setting.speaker_volume) }
      if(this.media_devices_setting?.ringing_speaker_volume !== undefined){ this.mediaDeviceSettingRingingSpeakerVolume(this.media_devices_setting.ringing_speaker_volume) }
    },
    testCall() {
      this.$root.$emit(events.audio_call,{
        call_type: 'test',
      })
    },
  },
  mounted(){
    this.mediaDevicesSettingSink()
  },
};
</script>

<style lang="scss">

@media (min-width: 1200px) and (max-width: 1499.98px){
  @media screen and (max-width: 1366px),screen and (max-height: 667px) {
    .sidebar.right-sidebar .chat-info {
      .soundSetting-dropdown-responsive {
        &.soundSetting-dropdown.dropdown{
          & > ul.dropdown-menu {
            top:-60px !important;
            right:60px;
            padding: 16px;
            .dialer-soundSetting-responsive {
              .dialer-volumeSetting-device{
                .dialer-microphone-volumeSetting{
                  margin-top:8px;
                }
              }
            }
          }
        }
        
      }
    }
    .sidebar.right-sidebar.zeroRadius .rightpanel{
      z-index: 112;
    }
  }
}
// .dialer-soundSetting-container{
//   max-height:70vh;
//   overflow-y: auto;
//   .dialer-soundSetting{
//     padding-right: 12px;
//   }
// }

</style>