import { VOIP_API } from "../../index"


export const PIPELINES_STAGES_CRM_API_ENDPOINTS = {
  list(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: 'pipeline-stages',
      method: 'GET',
      params,
      cancelToken,
    })
  },
}