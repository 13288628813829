
import { VOIP_API } from "../index"

export const POWER_DIALER_API_ENDPOINTS = {
  createSheet(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'POST',
      url: 'autodialer/files',
      data: data,
    })
  },
  getSheets(params={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'GET',
      url: 'autodialer/files',
      params: params,
    })
  },
  deleteSheet(id='',data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'DELETE',
      url: `autodialer/file/${id}`,
      data: data,
    })
  },
  getSheetNumbers(id='',params={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'GET',
      url: `autodialer/file/${id}`,
      params: params,
    })
  },
  getNextNumber(id='',params={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'GET',
      url: `autodialer/number/${id}`,
      params: params,
    })
  },
  updateStatus(data={},option={}){
    return VOIP_API.axios.voip.request({
      ...option,
      method: 'PATCH',
      url: `autodialer/file/status`,
      data: data,
    })
  },
}