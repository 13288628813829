
import { VOIP_API } from "../index"

export const OWNERSHIP_CHANGE = {
  change(data){
    return VOIP_API.axios.voip.request({
      ...data,
      url: `portal-proxy`,
      method: "post",
      type: 'relay',
    })
  },
}


// return VOIP_API.axios.voip.request({
//   method: "post",
//   url: "portal-proxy",
//   data:{
//     ...data,
//     type: 'payment',
//     action: 'add_card',
//   },
// })