var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 chatUserModal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "name": "ChatUserModal"
    },
    on: {
      "before-open": _vm.beforeOpen,
      "opened": _vm.onOpened
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_c('div', {
    staticClass: "dialer-edit-header mt-2"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Chat")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [!_vm.$store.getters.getIsMobile ? _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('ChatUserModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")]), _vm._v("Close ")], 1) : _vm._e(), _vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('ChatUserModal');
      }
    }
  }) : _vm._e()], 1)]), _c('Chatbox', {
    ref: "chat_box",
    attrs: {
      "channel_id": _vm.channel_id,
      "type": "chatModal"
    },
    on: {
      "change-channel-id": function ($event) {
        _vm.channel_id = $event;
      }
    }
  })], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }