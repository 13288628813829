<template>
  <div>
    <section v-if="menu.voipaccount" class="ct-user-new dialer-edit">
      <div v-if="isModal" class="dialer-edit-header">
        <div class="d-flex align-items-center">
          <div class="editModal-imageInsideModalTitle">
            <img :src="menu_image" @error="$event.target.src=local_filess.images.user_male"/>
          </div>
          <h2 class="dialer-settings-title newer">Edit IVR</h2> 
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" type="button" @click="$emit('close-modal')">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="newIVRLayout-forBorderBottom d-flex align-items-center justify-content-between">
          <div class="whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-50">
            <label>IVR name</label>
            <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder d-flex">
              <img :src="menu_image" @error="$event.target.src=local_filess.images.user_male" v-b-popover.hover.top="propMenu.extn"  />
              <input class="w-100 bg-white" :maxlength="$v.forms.update_label.label.$params.maxLength.max" type="text" v-model="forms.update_label.label" @input="updateLabel" />
              <div v-if="!isModal">
                <vb-icon @click="$emit('click-graph')" icon="graph-icon" class="graphIcon position-relative cursor_pointer_no_hover" height="40.501" width="41.374" />
              </div>
            </div>
            <template v-if="is_submitted.update_label">
              <p v-if="is_submitted.update_label && $v.forms.update_label.label.$invalid" class="text text-danger animated bounceIntop">
                <span v-if="!$v.forms.update_label.label.required">IVR name is required</span>
                <span v-else-if="!$v.forms.update_label.label.minLength">IVR name should be minimum {{ $v.forms.update_label.label.$params.minLength.min }} character</span>
                <span v-else-if="!$v.forms.update_label.label.maxLength">IVR name can be maximum {{ $v.forms.update_label.label.$params.maxLength.max }} character</span>
                <span v-else-if="!$v.forms.update_label.label.valid">IVR name can be number and alphabet</span>
              </p>
            </template>
          </div>
          
          <div class="profileImg-section-latest-container">
            <div class="profileImg-section-latest" @click="$modal.show('ProfileImageUploader', { type: 'IVR', accountcode: menu.voipaccount, current_image: menu_image })">
              <img :src="menu_image" @error="$event.target.src=local_filess.images.user_male" />
            </div>
          </div>
        </div>
        <!-- IVR Numbers Section -->
        <div class="d-flex align-items-center justify-content-between w-100 newIVRLayout-forBorderBottom mt-20px">
          <div>
            <div class="latestGreyBox-heading-main">Telephone number</div>
            <div class="latestGreyBox-descriptionText" v-if="ivr.userDid.length == 0"> No telephone number defined. </div>
            <div class="d-flex mt-12px IVR-numbers-container flex-wrap" v-else>
              <div v-for="(did, index) in ivr.userDid.filter((item,index)=>index<3)" :key="index" class="newIVRLayout-number-item">
                <template v-if="!(!ivr.userDid || !ivr.userDid[0])">
                  <img class="country-img" width="45px" :src="did.did | number_formater | flag_icon" />
                </template>
                <span class="newIVRLayout-number-item-text">{{ did.did | number_formater }}</span>
              </div>
              <div v-if="ivr.userDid.length > 3" class="newIVRLayout-number-item">
                + {{ivr.userDid.length - 3}}
              </div>
            </div>
          </div>
          <button
            class="newButton sm-mar-left"
            @click="
              $modal.show(`${_uid}-AssignNumberModal`, {
                accountcode: menu.voipaccount,
              })
            "
          >
            <vb-icon icon="squared-change-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Change</span>
          </button>
        </div>
        <!-- Welcom Greetings Section -->
        <div class="w-100 newIVRLayout-forBorderBottom mt-20px">
          <WelcomeGreeting class="newIVRLayout-WelcomeGreetingsSection"
            @fileChange="$emit('interface')"
            :file="sound"
            :accountcode="menu.voipaccount"
            :menu="menu"
          />
          <!-- class="LatestwelcomGreetingsDesign" -->
        </div>
        <!-- Menu options section -->
        <MenuOptionsWidget v-if="menu" :menu="menu" @updated="menu=$event.menu;$emit('interface');" />
        <!-- advance options section -->
        <div class="mt-20px">
          <AdvanceOptions class="w-100" v-if="menu.voipaccount" :accountcode="menu.voipaccount" />
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0">
            <ScheduleSetting v-if="menu.voipaccount" :account="menu.voipaccount" />
          </div>
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="latestGreyBox-heading-main">Delete this IVR?</div>
            <button class="newDeleteButton" type="button" @click="deleteItem()">
              <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
              <span class="newDeleteButton-textPart">Delete</span>
            </button>
          </div>
        </div>
      </div>
      <RecordingListSelect
        :is_single_select="true"
        @selectedRecordings="updateWrongIVRInput($event)"
      />
      <AssignNumberModal :modalName="`${_uid}-AssignNumberModal`" @added="$emit('interface');" />
      <ProfileImageUploader @image-uploaded="menu_image=$event;$emit('interface');" />
    </section>
  </div>
</template>

<script>
import { required, maxLength, minLength, helpers } from "vuelidate/lib/validators";
import _ from "lodash";
import ScheduleSetting from "../Common/ScheduleSetting.vue";
import { LOGGER, VOIP_API } from "../../utils";
import RecordingListSelect from "../Modals/RecordingListSelect.vue";
import ProfileImageUploader from "../Modals/ProfileImageUploader.vue";
import AssignNumberModal from "../Modals/AssignNumberModal.vue";
import AdvanceOptions from "../IVR/AdvanceOptions.vue";
import WelcomeGreeting from "../IVR/WelcomeGreeting.vue";
import { mapGetters } from 'vuex';
import MenuOptionsWidget from '../widgets/ivr/MenuOptionsWidget.vue';
let keypad = [
  {
    icon: 'insideEditIVRmodal-dial-1',
    value: '1'
  },
  {
    icon: 'insideEditIVRmodal-dial-2',
    value: '2'
  },
  {
    icon: 'insideEditIVRmodal-dial-3',
    value: '3'
  },
  {
    icon: 'insideEditIVRmodal-dial-4',
    value: '4'
  },
  {
    icon: 'insideEditIVRmodal-dial-5',
    value: '5'
  },
  {
    icon: 'insideEditIVRmodal-dial-6',
    value: '6'
  },
  {
    icon: 'insideEditIVRmodal-dial-7',
    value: '7'
  },
  {
    icon: 'insideEditIVRmodal-dial-8',
    value: '8'
  },
  {
    icon: 'insideEditIVRmodal-dial-9',
    value: '9'
  },
  {
    icon: 'insideEditIVRmodal-dial-steric',
    value: '*'
  },
  {
    icon: 'insideEditIVRmodal-dial-0',
    value: '0'
  },
]
export default {
  name: "EditMenu",
  components: {
    RecordingListSelect,
    ScheduleSetting,
    ProfileImageUploader,
    // AudioIVR,
    AdvanceOptions,
    WelcomeGreeting,
    AssignNumberModal,
    MenuOptionsWidget,
  },
  inject: [
    'local_filess',
    'getProperty',
    'appNotify'
  ],
  props: {
    propMenu: {
      type: Object,
      default: ()=>({}),
    },
    isModal: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      menu_image: '',
      selected_recordings: [],
      menu: {},
      label: "BasicIvr",
      sound: null,
      text: null,
      is_music_on_hold: true,
      assignUserSection: false,
      service: null,
      numberservice: null,
      users: {},
      voices: [],
      voice: null,
      scheduleOption: {
        account: null,
        id: null,
        option: null,
        schedule_id: null,
        schedule_no: null,
        type: "extension",
        command: "callfwd",
        args: null,
      },
      incommingCallOption: "keepringing",
      outofhours: false,
      outofhoursoption: "keepringing",
      checked_call_recording: false,
      checked_music: false,
      selected_delay: null,
      musiconhold: false,
      musiconholdname: null,
      musiconholdid: null,
      forms: {
        update_wrong_ivr_input: this.$helperFunction.formInstance({
          data: {
            ivr_wrongdtmf_repeat_limit: '',
          }
        }),
        update_label: {
          label: '',
          reset() {
            this.label = '';
          },
        },
      },
      is_submitted: {
        submit: false,
        update_label: false,
        update_wrong_ivr_input: false,
      },
      api_sent: {
        tex_to_audio: false,
        list: false,
        update_label: false,
        submit: false,
        common_values: false,
        advance_value: false,
        update_wrong_ivr_input: false,
      },
      errors: {
        update_label: {},
      },
      response: {
        common_values: {},
      },
      source: {
        update_label: null
      },
      selectedMenuOptions: [],
      showNewSelectAButton: false
    };
  },
  computed: {
    ...mapGetters([
      'getVoipIVRS',
    ]),
    keypad(){ return keypad },
    cmdOptions() {
      if (!this.response.common_values.cmd_details) return [];
      return this.response.common_values.cmd_details.map((item) => {
        return {
          text: item.label,
          value: item.ivr_cmd,
        };
      });
    },
    accountsOptions() {
      if (!this.response.common_values.accounts) return [];
      let array = [];
      if (this.forms.limits.cmd == "ivrfwd") {
        array = Object.values(this.response.common_values.accounts).filter(
          (data) => data.account_type === "BASICIVR"
        );
      } else {
        array = Object.values(this.response.common_values.accounts);
      }
      return array.map((item) => {
        return {
          text: item.accountname,
          value: item.accountcode,
        };
      });
    },
    soundsOptions() {
      if (!this.response.common_values.sounds) return [];
      return Object.values(this.response.common_values.sounds).map((item) => {
        return {
          text: item.label,
          value: item.sound,
        };
      });
    },
    cmdArgs() {
      const ivr_cmd = this.forms.limits?.cmd;
      if (!this.response.common_values.cmd_details) return {};
      const [cmd] = this.response.common_values.cmd_details.filter(
        (item) => item.ivr_cmd == ivr_cmd
      );
      if (!cmd) return {};
      return {
        account: cmd.ivr_cmd_args.includes("accountcode"),
        sound: cmd.ivr_cmd_args.includes("soundfile"),
        dail_number: cmd.ivr_cmd_args.includes("dial-number"),
        code_dtmf: cmd.ivr_cmd_args.includes("code-dtmf"),
        ivr_cmd_args: cmd.ivr_cmd_args,
      };
    },
    ivrCmdArgsValue() {
      if (!this.cmdArgs.ivr_cmd_args) return "";
      const ivr_cmd_args = this.cmdArgs.ivr_cmd_args;
      const str = ivr_cmd_args.includes("soundfile", this.forms.limits.sound_id)
        // ? `${this.current_user.uid}/${this.current_user.default_accountcode}/ivr/`
        ? `${this.$store.getters.getCurrentUser?.uid}/${this.$store.getters.getCurrentUser?.default_accountcode}/ivr/`
        : "";
      return (
        str +
        ivr_cmd_args
          .replace("accountcode", this.forms.limits.account)
          .replace("soundfile", this.forms.limits.sound_id)
          .replace("dial-number", this.forms.limits.dail_number)
          .replace("code-dtmf", this.forms.limits.code_dtmf)
          .replace(/{/g, "")
          .replace(/}/g, "")
      );
    },
    ivr(){ return this.getVoipIVRS?.[this?.menu?.voipaccount] ?? {} },
    selectedIVROptions(){
      return _.keyBy(this.menu?.schedules?.[0]?.options ?? [], 'user_dtmf');
    },
  },
  filters: {
    filterDid: function (value) {
      if (!value || !value[0]) return "No numbers assigned";
      return value[0].did;
    },
    firstCharacter: function (value) {
      if (!value) return "";
      return value.charAt(0);
    },
    filterExtentionTypeIcon(value){
      if(value == 'USER'){
        return 'insideEditIVRmodal-userIcon'
      }
      else if(value == 'TEAM'){
        return 'insideEditIVRmodal-temsIcon'
      }
      else if(value == 'number'){
        return 'insideEditIVRmodal-callIcon'
      }
      else{
        return 'insideEditIVRmodal-temsIcon'
      }
    },
  },
  validations: {
    forms: {
      update_wrong_ivr_input: {
        ivr_wrongdtmf_repeat_limit: {
          required,
        },
      },
      update_label: {
        label: {
          required,
          minLength: minLength(3),
          maxLength: maxLength(20),
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        },
      },
    },
  },
  methods: {
    updateLabel: _.debounce(function(){
      let app = this
      this.is_submitted.update_label=true
      this.source.update_label = require("axios").default.CancelToken.source();
      this.$v.forms.update_label.$touch();
      if(this.api_sent.update_label || this.$v.forms.update_label.$invalid) return;
      this.api_sent.update_label=true
      VOIP_API.endpoints.menu.labelUpdate(this.menu.voipaccount,{
        label: this.forms.update_label.label
      },this.source.update_label.token).then(() => {
        app.appNotify({
          message: "Successfully updated!",
          type: "success",
        })
        app.$emit('interface')
      })
      .catch((ex) => {
        app.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        app.is_submitted.update_label = false;
        app.api_sent.update_label = false;
      });
    },2*1000),
    handleSubmit() {
      var app = this;
      this.is_submitted.submit = true;
      this.$v.$touch();
      if (this.$v.forms.limits.$invalid || this.api_sent.submit) return;
      this.api_sent.submit = true;
      VOIP_API.endpoints.menu
        .advancesetting({
          ivr_wrong_input_alert:
            this.forms.limits.ivr_wrongdtmf_repeat_limit.sound,
          ivr_wrongdtmf_repeat_limit: this.forms.limits.ivr_nodtmf_repeat_limit,
          ivr_nodtmf_repeat_limit: this.forms.limits.ivr_nodtmf_repeat_limit,
          input_seconds: this.forms.limits.input_seconds,
          cmd: this.forms.limits.cmd,
          ivr_cmd_args: this.ivrCmdArgsValue,
          account: this.menu.voipaccount,
        })
        .then(() => {
          app.appNotify({
            message: "Successfully updated!",
            type: "success",
          })
        })
        .catch((ex) => {
          app.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(() => {
          app.is_submitted.submit = false;
          app.api_sent.submit = false;
        });
    }, // not use
    async convert() {
      if (this.api_sent.tex_to_audio) return;
      this.api_sent.tex_to_audio = true;
      VOIP_API.endpoints.menu
        .convert({
          text: this.text,
          label: this.label,
          voice: this.voice,
          accountcode: this.menu.voipaccount,
        })
        .then(({ data: res }) => {
          if (!res.file_exists) {
            this.appNotify({
              message: "Try after 5 second",
              type: "info",
            });
          } else {
            this.sound = res.sound;
            this.text = res.text;
            this.appNotify({
              message: "Successfully converted",
              type: "success",
            })
            this.$emit("interface");
          }
        })
        .finally(() => {
          this.api_sent.tex_to_audio = false;
        });
    }, // not use
    async extensionUpdated(event) {
      this.scheduleOption.schedule_id = this.$refs.scheduleid[0].value;
      this.scheduleOption.schedule_no = this.$refs.schedulekey[0].value;
      this.scheduleOption.args = event.account;
      this.scheduleOption.id = this.menu.voipaccount;
      this.scheduleOption.account = event.account;
      this.scheduleOption.option = event.dtmf
      await VOIP_API.endpoints.menu
        .addoption(this.scheduleOption)
        .then(({ data: res }) => {
          this.menu = res.data;
          this.scheduleOption.option = null;
          this.scheduleOption.schedule_no = null;
          this.scheduleOption.args = null;
          this.$emit("interface");
        });
    },
    resetIvrOption(option, scheduleId, scheduleNo, voipaccount) {
      var app = this;
      this.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            VOIP_API.endpoints.menu
              .resetoption(voipaccount, {
                option: option == 10 ? "*" : option,
                schedule: scheduleId,
              })
              .then(({ data: res }) => {
                app.menu = res.data;
                app.$emit("interface");
              });
          }
        },
      });
    },
    beforeOpen(event) {
      this.menu = {};
      this.menu = event?.params?.menu;
      this.menu_image = this.menu?.avatar?.image?.image ?? ''
      if (
        event.params.menu.schedules &&
        event.params.menu.schedules[0] &&
        event.params.menu.schedules[0].soundText
      ) {
        this.text = event.params.menu.schedules[0].soundText;
      }

      if (
        event.params.menu.schedules &&
        event.params.menu.schedules[0] &&
        event.params.menu.schedules[0].sound
      ) {
        this.sound = event.params.menu.schedules[0].sound;
      }

      if (
        event.params.menu.schedules &&
        event.params.menu.schedules[0] &&
        event.params.menu.schedules[0].voice
      ) {
        this.voice = event.params.menu.schedules[0].voice;
      }

      if (
        event.params.menu.schedules &&
        event.params.menu.schedules[0] &&
        event.params.menu.schedules[0].selectedOptions
      ) {
        this.selectedMenuOptions = event.params.menu.schedules[0].selectedOptions;
        // console.log(this.selectedMenuOptions)
      }



      this.is_submitted.update_label=false
      this.forms.update_label.reset();
      this.forms.update_label.label=this.menu.label
      this.assignUserSection = false;
      // this.getIvrAdvanceValue();
    },
    onBeforeClose(){
      this.forms.update_wrong_ivr_input.reset()
    },
    deleteItem() {
      let vm = this
      this.$appConfirmation({
        title: "Delete IVR?",
        message: `Are you sure you want to delete this IVR service from your subscription?`,
        variant: 'danger',
        button: {
          no: "Cancel",
          yes: "Delete IVR",
        },
        alert: {
          variant: 'danger',
          title: 'Warning',
          message: `After deletion, the IVR's configurations and functionalities will no longer be available`,
        },
        callback: ({confirm}) => {
          if (confirm) {
            VOIP_API.endpoints.billing.unsubscribe({
              id: vm.menu.voipaccount,
              uid: vm.$store.getters.getCurrentUser?.uid,
              accountcode: vm.menu.voipaccount,
            }).then(() => {
              vm.$emit('close-modal');
              vm.$emit("interface");
            })
            // VOIP_API.endpoints.menu.delete(vm.menu.voipaccount).then(() => {
            //   vm.$emit('close-modal');
            //   vm.$emit("interface");
            // });
          }
        },
      });
    },
    load() {
      VOIP_API.endpoints.voicemail.getpollyVoices().then(({ data: { data } }) => {
        this.voices = data;
      });
    },
    getCommonValues() {
      this.api_sent.common_values = true;
      VOIP_API.endpoints.menu
        .getcommonvalues()
        .then(({ data }) => {
          this.response.common_values = data;
        })
        .finally(() => {
          this.api_sent.common_values = false;
        });
    },
    getIvrAdvanceValue() {
      let vm = this
      this.api_sent.advance_value = true;
      VOIP_API.endpoints.menu.getivradvanceoption(this.menu.voipaccount)
        .then(({ data }) => {
          LOGGER.log("data", data);
          if (!data.setting.setting) return;
          vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit = data.setting.setting.ivr_wrong_input_alert_file || '';
        })
        .finally(() => {
          this.api_sent.advance_value = false;
        });
    },
    updateWrongIVRInput(event){
      let vm = this
      // vm.forms.update_wrong_ivr_input.submitted=true
      // vm.$v.forms.update_wrong_ivr_input.$touch()
      // if(vm.$v.forms.update_wrong_ivr_input.$invalid) return;
      if(vm.api_sent.update_wrong_ivr_input) return;
      vm.api_sent.update_wrong_ivr_input=true
      VOIP_API.endpoints.menu.updateWrongIVRInput({
        file: event.sound,
        account: vm.menu.voipaccount,
      }).then(()=>{
        vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit=event.sound_file
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        // vm.forms.update_wrong_ivr_input.submitted=false
        vm.api_sent.update_wrong_ivr_input=false
      })
    },
  },
  mounted() {
    this.beforeOpen({
      params: {
        menu: this.propMenu,
      },
    })
  },
  beforeDestroy(){
    this.onBeforeClose()
  },
};
</script>


<style lang="scss" scoped>
.dialer-audio-playback {
  @include border-radius($border-radius);
  overflow: hidden;
  margin-top: 5px;
  display: flex;
  align-items: center;
  font-size: 12px;
  flex-grow: 1;
  .dialer-audio-playback-inner {
    width: 100%;
    background-color: $white;
    padding: $sidebarTop;
    audio {
      min-width: 220px;
      max-width: 250px;
      max-height: 30px;

      &::-webkit-media-controls-panel {
        background-color: $gray-300;
      }
    }
    .dialer-audio-message {
      width: 100%;
      svg {
        @include border-radius(0px);
        background-color: transparent;
        color: $body-color;
        margin-right: 8px;
      }
      .dialer-audio-progress {
        position: relative;
        margin: 0px;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        align-items: center;
        width: calc(100% - 24px);
        .progress {
          background-color: $progressBar_bg-color;
          width: calc(100% - 28px);
          .progress-bar {
            transition: width 0.1s ease;
          }
        }
        small {
          &:first-child {
            width: 39px;
            margin-right: 8px;
          }
          &:last-child {
            margin-left: 8px;
          }
        }
        input {
          &[type="range"] {
            width: calc(100% - 84px);
            background-color: transparent;
            -webkit-appearance: none;
            position: absolute;
            left: 42px;
            &::-webkit-slider-runnable-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-moz-range-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-ms-track {
              height: 4px;
              @include border-radius(50px);
              background: transparent;
              cursor: pointer;
            }
            &::-webkit-slider-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              -webkit-appearance: none;
              cursor: pointer;
            }
            &::-moz-range-thumb {
              width: 12px;
              height: 12px;
              margin-top: -5px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
            }
            &::-ms-thumb {
              width: 12px;
              height: 12px;
              background: $dialer-progressBarThumb-color;
              border: 1px solid $dialer-progressBarThumb-color;
              @include border-radius(50%);
              cursor: pointer;
              margin-top: -5px;
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &::-ms-fill-lower {
              background: $black;
              border: 0.2px solid $black;
              @include border-radius(2.6px);
            }
            &:focus {
              &::-ms-fill-lower {
                background: $black;
              }
              &::-ms-fill-upper {
                background: $black;
              }
            }
          }
        }
      }
    }
    .dialer-play-button {
      outline: none;
      border: none;
      background: none;
      padding: 2px 6px 3px;
    }

    .dialer-playback-time {
      margin: 0 5px;
      min-width: 25px;
      color: $secondary;
    }
  }
}
</style>
