<template>
  <modal class="center-zoomIn-transition sipActiveCall-Modal SelectSnoozeTimeModal DurationPickerModal" transition="center-zoomIn-transition" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    <div class="dialer-call-notification-tray">
      <div class="dialer-call-notification dialer-keypadNotification">
        <!-- <div class="dialer-call-notification-close" @click="$modal.hide(modalName)">
          <vb-icon icon="dialer-close-icon" width="14" height="14" />
        </div>
        <div class="dialer-call-notification-inner">
          <div class="dialer-call-notification-text">
            <div class="dialer-call-notification-main-text">{{ modalTitle }}</div>
          </div>
        </div> -->
        <div class="dialer-edit-header position-relative py-0">
          <div>
            <h2 class="dialer-settings-title newer mb-0">{{ modalTitle }}</h2>
          </div>
          <div class="dialer-edit-actions">
            <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            <a v-else
              class="newCloseButton"
              @click="$modal.hide(modalName)"
            >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row mainWrapper">
              <div class="d-flex flex-column flex-fill">
                <div class="latestGreyBox-heading-main">Select seconds</div>
                <div class="latestGreyBox-descriptionText">The call will be forwarded after the phone rings for {{ forms.time.seconds }} seconds</div>
              </div>
              <div class="d-flex align-items-center ml-3">
                <button class="dialer-button dialer-button-primary" style="min-width: 36px;max-width: 36px;padding: 0px 12px !important;height: 50px;" :disabled="forms.time.seconds <= 5" @click="forms.time.seconds = forms.time.seconds-5">-</button>
                <div class="whiteBGinputWithGreyRoundedBorder mx-2" style="max-width:60px;min-width:60px;">
                  <input disabled :value="forms.time.seconds" class="w-100 text-center" />
                </div>
                <button class="dialer-button dialer-button-primary" style="min-width: 36px;max-width: 36px;padding: 0px 12px !important;height: 50px;" :disabled="forms.time.seconds >= 50" @click="forms.time.seconds = forms.time.seconds+5">+</button>
              </div>
            </div>
          </div>
        </div>




        <div class="d-flex justify-content-end w-100">
          <!-- <button @click="onDone()" class="newDoneButton mt-20px">
            <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Submit</span>
          </button> -->
          <button @click="onDone()" class="fullWidthDoneButton mt-20px">
            Update
          </button>
          <template v-if="forms.time.submitted && $v.forms.time.$invalid">
            <span v-if="!$v.forms.time.required">* time is required</span>
          </template>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "DurationPickerModal",
  props: {
    modalName: {
      type: String,
      default: "DurationPickerModal",
    },
    modalTitle: {
      type: String,
      default: "Select seconds",
    },
  },
  data() {
    return {
      forms: {
				time:{
					seconds: 5,
          reset(){
            this.seconds = 5
          },
          submitted: false
				},
			}
    };
  },
  validations: {
    forms: {
      time: {
        seconds: {
          required(value) {
            return value >= 5 && value <= 50 
          },
        },
      }
    },
  },
  methods: {
    onBeforeOpen(event){
      this.forms.time.seconds = event.params.value ?? 5
    },
    onBeforeClose(){
      this.forms.time.reset()
    },
    onDone() {
      this.forms.time.submitted = true
      this.$v.forms.time.$touch()
      if(this.$v.forms.time.$invalid) return
      var seconds = this.forms.time.seconds
      this.$emit("time", {
        seconds
      });
      this.$modal.hide(this.modalName);
      this.forms.time.submitted = false
    },
  },
};
</script>

<style lang="scss">

.custom-snooze-time {
  padding: $dialer-default-padding;
  .b-time {
    width: 100%;
    .b-time-header {
      output {
        border-radius: 12px;
        border: 1px solid #f0f0f0;
        // background-color: #f0f0f0;
        background-color: $chat_primary_user;
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
      }
    }
    & > div {
      margin: $dialer-default-padding 0px;
      .b-form-spinbutton {
        margin: 0px 8px;
      }
    }
  }
}

.cancel-button-modal {
  padding: 5px 8px;
  border-radius: 50%;
  border: 0px;
  background-color: $primaryLight;
}
</style>
