var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column justify-content-center align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "w-fit-content"
  }, [_vm.api.fetch_notification_settings.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "misscall_notification",
    staticClass: "newerSwitch",
    attrs: {
      "title": _vm.getUserPermissions.missed_call_notification ? '' : 'Your package does not support this feature',
      "disabled": !_vm.getUserPermissions.missed_call_notification || _vm.api.change_misscall_notification.send || _vm.api.fetch_notification_settings.send,
      "checked": _vm.data.misscall_notification,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changeMissedCallNotification($event);
      }
    }
  })], 1)]), _vm.data.misscall_notification ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [!_vm.isTeam ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(1), _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "missed_call_notification",
    staticClass: "newerSwitch",
    attrs: {
      "title": _vm.getUserPermissions.missed_call_notification ? '' : 'Your package does not support this feature',
      "disabled": !_vm.getUserPermissions.missed_call_notification || _vm.api.change_missed_call_notification.send || _vm.api.fetch_notification_settings.send,
      "checked": _vm.data.missed_call_notification,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changeMissedCallEmail($event);
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column justify-content-center align-items-start"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_vm._m(2), _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "misscall_task",
    staticClass: "newerSwitch",
    attrs: {
      "title": _vm.getUserPermissions.missed_call_notification ? '' : 'Your package does not support this feature',
      "disabled": !_vm.getUserPermissions.missed_call_notification || _vm.api.change_misscall_task.send || _vm.api.fetch_notification_settings.send,
      "checked": _vm.data.misscall_task != 0,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.changeMissedCallTask($event ? 1 : 0);
      }
    }
  })], 1), !_vm.isTeam && _vm.data.misscall_task != 0 ? [_vm.data.misscall_task != 0 ? _c('div', {
    staticClass: "d-flex flex-column align-items-start w-100 mt-20px"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v("Missed call as a task will be added to the "), _c('span', {
    staticClass: "color-3699FF",
    staticStyle: {
      "color": "#3699FF"
    }
  }, [_vm._v(_vm._s(_vm.data.misscall_task == 2 ? 'my space' : 'global space'))]), _vm._v(".")]), _c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.missed_call_notification || _vm.api.change_misscall_task.send || _vm.api.fetch_notification_settings.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('taskSpaceSelectorModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change space")])], 1)])]) : _vm._e()] : _vm._e()], 2)])]) : _vm._e(), _c('modal', {
    staticClass: "EditSpaceModal min-height-400px",
    attrs: {
      "name": "taskSpaceSelectorModal"
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header w-100 d-flex position-relative py-0 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer my-0"
  }, [_vm._v("Select space")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('taskSpaceSelectorModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "mt-24px"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.forTempEvent == 1 ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        _vm.forTempEvent = 1;
        _vm.changeMissedCallTask(_vm.forTempEvent);
      }
    }
  }), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Global space")])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.forTempEvent == 2 ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        _vm.forTempEvent = 2;
        _vm.changeMissedCallTask(_vm.forTempEvent);
      }
    }
  }), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("My space")])])])])])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Missed calls")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("You will receive a notification for every missed call.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Missed calls notifications via email")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Receive an email alert notifying you of missed calls.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Add miss call as a task")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Turn missed calls into tasks to remind you of calls requiring attention later.")])]);

}]

export { render, staticRenderFns }