<template>
  <div class="dialer-box alt position-relative">
    <div class="dialer-flex">
      <div class="dialer-box-header">Incoming call presentation</div>
      <div class="dialer-audio-options d-flex">
        <div class="row">
          <div class="col-12 col-md-12">
            <div class="form-group mb-0">
              <div 
                v-b-tooltip :title="getUserPermissions.incoming_call_display?'':'Your package does not support this feature'"
                class="w-fit-content" 
                :class="{
                  'for-disable': !getUserPermissions.incoming_call_display
                }"
              >
                <div 
                  class="w-fit-content" 
                  :class="{
                    'for-disable': !getUserPermissions.incoming_call_display
                  }"
                >
                  <button 
                    :disabled="!getUserPermissions.incoming_call_display || disabled" 
                    class="newButton" 
                    @click="$modal.show(`${accountcode}-IncommingcallDisplaySettingModal`,{ account: accountcode })"
                  >
                    <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Change</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="dialer-flex">
      <div class="dialer-box-text mb-0">You will be set incoming CLID presentation option.</div>
    </div>
    <div class="dialer-flex dialer-call-options mt-3">
      <div class="selectedText-big">{{response.user_detail | get_property('select_clid_display') | clidText}}</div>
    </div>
    <IncommingcallDisplaySettingModal :modalName="`${accountcode}-IncommingcallDisplaySettingModal`" @latest="fetchUserDetail()"/>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import IncommingcallDisplaySettingModal from "../Modals/IncommingcallDisplaySettingModal.vue";
export default {
  name: 'IncomingCallPresentation',
  components: {
    IncommingcallDisplaySettingModal,
  },
  props: {
    accountcode: {
      type: [String,Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      api: {
        user_detail: this.$helperFunction.apiInstance({ }),
      },
      response: {
        user_detail: {},
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
    ]),
  },
  filters: {
    clidText(value){
      if(value==0) return `Display number only`
      else if(value==1) return `Display DID name`
      else if(value==2) return `Display DID number`
      else if(value==5) return `Display speed dial reference name`
      else ''
    },
  },
  methods: {
    fetchUserDetail() {
      let vm = this
      if (vm.api.user_detail.send) return;
      vm.api.user_detail.send = true;
      VOIP_API.endpoints.users.detail(vm.accountcode)
      .then(({ data: detail }) => {
        vm.response.user_detail = detail;
      })
      .finally(() => {
        vm.api.user_detail.send = false;
      });
    },
  },
  activated(){
    this.fetchUserDetail()
  },
  mounted(){
    this.fetchUserDetail()
  },
}
</script>

<style>

</style>