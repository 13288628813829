
import { VOIP_API } from "../index"

export const ADDRESS_API_ENDPOINTS = {
  list(params){
    return VOIP_API.axios.voip.request({
      url: "numberaddresses",
      method: "GET",
      params,
    })
  },
  create(data){
    return VOIP_API.axios.voip.request({
      url: "numberaddresses",
      method: "POST",
      data,
    })
  },
  delete(){
    return VOIP_API.axios.voip.request({
      url: "numberaddresses",
      method: "DELETE",
    })
  },
}