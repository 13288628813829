<template>
  <div>
    <!-- login  -->
    <div v-if="conditions.screen == screens.login" class="LoginPage-container" >
      <div class="LoginPage-leftSide">
        <div class="voipBusiness-whiteLogo-container">
          <vb-icon icon="voipBusiness-latestLogo-icon" class="voipBusiness-whiteLogo"/>
        </div>
        <img :src="randomImage"/>
      </div>
      <div class="LoginPage-rightSide">
        <div class="LoginPage-rightSide-inner">
          <b-form @submit.prevent="login()">
            <b-alert :show="!!api.login.error_message" variant="danger">{{ api.login.error_message }}</b-alert>
            <div class="mainHeading mb-40px mt-40px">Sign in</div>
            <div class="mainInput-container with-error mb-32px">
              <label>Email or Username</label>
              <div :class="`mainInput ${api.login.send?'disabled':''}`">
                <b-form-input placeholder="Please type your email" :disabled="api.login.send" v-model="forms.login.username" type="text"/>
              </div>
              <template v-if="(forms.login.submitted && $v.forms.login.username.$invalid) || (api.login.validation_errors && api.login.validation_errors.username)">
                <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                <div class="errorTemplate-v2">
                  <p v-if="forms.login.submitted && $v.forms.login.username.$invalid" class=" animated bounceIntop">
                    <span v-if="!$v.forms.login.username.required">* Username is required</span>
                    <span v-else-if="!$v.forms.login.username.maxLength">* Username is maximum 32 character</span>
                  </p>
                  <p v-if="api.login.validation_errors.username" class=" animated bounceIntop">
                    <span v-for="error_message in api.login.validation_errors.username" :key="error_message">* {{ error_message }}</span>
                  </p>
                </div>
              </template>
            </div>
            <div class="mainInput-container with-error mb-10px">
              <label>Password</label>
              <div :class="`mainInput password ${api.login.send?'disabled':''}`">
                <b-form-input :disabled="api.login.send" :type="forms.login.password_show?'text':'password'" v-model="forms.login.password"  />
                <span class="pass-icon">
                  <b-icon class="dialer-showpassword-icon cursor_pointer" :icon="forms.login.password_show ? 'eye-slash-fill' : 'eye-fill'" @click="forms.login.password_show=!forms.login.password_show" />
                </span>
              </div>
              <template v-if="(forms.login.submitted && $v.forms.login.password.$invalid) || (api.login.validation_errors && api.login.validation_errors.password)">
                <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                <div class="errorTemplate-v2">
                  <p v-if="forms.login.submitted && $v.forms.login.password.$invalid" class=" animated bounceIntop">
                    <span v-if="!$v.forms.login.password.required">* Password is required</span>
                    <span v-else-if="!$v.forms.login.password.minLength">* Password is minimum 8 is required</span>
                    <span v-else-if="!$v.forms.login.password.maxLength">* Password is maximum 32 is required</span>
                  </p>
                  <p v-if="api.login.validation_errors.password" class=" animated bounceIntop">
                    <span v-for="error_message in api.login.validation_errors.password" :key="error_message">* {{ error_message }}</span>
                  </p>
                </div>
              </template>
            </div>
            <div class="rememberMeCheckbox-container">
              <b-form-checkbox :disabled="api.login.send" v-model="forms.login.remember_me">Remember me</b-form-checkbox>
            </div>
            <!-- buttons -->
            <b-button :disabled="api.login.send"  class="fullBlackBTN mt-16px mb-24px" type="submit" variant="primary">
              <b-spinner v-if="api.login.send" /> 
              <template v-else>
                Log in
                <b-icon icon="arrow-right-short" class="ml-24px iconi" scale="1.5"/>
              </template>
            </b-button>
            <a v-if="!$store.getters.isIntegration" class="dialer-custom-link text-small text-center" @click="conditions.screen=screens.forget">Forgot password?</a>
          </b-form>
        </div>
      </div>
    </div>
    <!-- organization  -->
    <b-form v-else-if="conditions.screen == screens.organization" @submit.prevent="''">
      <div class="signUpPage-container">
        <SignupSlider />
        <div class="signUpPage-rightSide">
          <div class="signUpPage-rightSide-inner">
            <b-alert :show="!!api.organization_login.error_message" variant="danger">{{ api.organization_login.error_message }}</b-alert>
            <div v-if="api.organization_login.send" class="d-flex justify-content-center align-items-center" >
              <vb-spinner class="big" />
            </div>
            <ul v-else class="organizationList-container">
              <li v-for="organization in response.organizations" :key="organization.id" @click="organizationlogin(organization)">
                <a class="d-flex align-items-center justify-content-between ">
                  <span class="d-flex align-items-center">
                    <span class="py-1 d-flex align-items-left">
                      <b class="ml-8px">{{ `${organization.label}` }}</b>
                    </span>
                    <span class="ml-16px organizationName">{{`${organization.uid}`}}</span>
                  </span>
                </a>
              </li>
            </ul>
          </div>
          <div class="bottomSection">
            Back to 
            <a :disabled="api.organization_login.send" @click="conditions.screen=screens.login" class="mx-2 font-size-14 color-primary">Log In</a>
          </div>
        </div>
      </div>
    </b-form>
    <!-- extension occupied  -->
    <b-form v-else-if="conditions.screen == screens.extension_occupied" @submit.prevent="''">
      <div class="signUpPage-container">
        <SignupSlider />
        <div class="signUpPage-rightSide extension_occupied">
          <div class="signUpPage-rightSide-inner">
            <b-alert :show="!!api.extension_signout.error_message" variant="danger">{{ api.extension_signout.error_message }}</b-alert>
            <div class="currentExtentions-container">
              <div class="mainHeading w-100 mb-0 mt-40px">Sign out of one of the devices</div>
              <div class="mainText text-center mb-16px w-100">Sign out of one of the devices below to continue</div>
              <div class="currentExtentions-box" v-for="extension in response.extensions" :key="extension.id">
                <vb-icon :icon="extension | get_property('extra_data') | jsonParse | get_property('fp_data') | extensionDeviceInfo('icon')" />
                <div class="currentExtentions-heading">{{ extension | get_property('extra_data') | jsonParse | get_property('fp_data') | extensionDeviceInfo('app-name') }}</div>
                <div class="currentExtentions-text">Logged in from {{ extension | get_property('extra_data') | jsonParse | get_property('fp_data') | extensionDeviceInfo('ip') }}</div>
                <div class="currentExtentions-subText">Logged in {{ extension | get_property('created_at') | filter_date_current }}</div>
                <b-button :disabled="!!api.extension_signout.send" @click="extensionsignout(extension.id)" class="fullBlackBTN" type="submit" variant="primary">
                  <vb-spinner v-if="api.extension_signout.send==extension.id" /> 
                  <template v-else>Sign out</template>
                </b-button>
              </div>
            </div>
          </div>
          <div class="bottomSection">
            Back to 
            <a :disabled="api.extension_signout.send" @click="conditions.screen=screens.login" class="mx-2 font-size-14 color-primary">Log In</a>
          </div>
        </div>
      </div>
    </b-form>
    <!-- confirm device -->
    <b-form v-else-if="conditions.screen == screens.confirm_device" @submit.prevent="addconfirmdevice('yes')" >
      <div class="signUpPage-container">
        <SignupSlider />
        <div class="signUpPage-rightSide">
          <div class="signUpPage-rightSide-inner">
            <b-alert :show="!!api.confirm_device.error_message" variant="danger">{{ api.confirm_device.error_message }}</b-alert>
            <div class="w-100 d-flex justify-content-center mt-40px">
              <vb-icon height="179.85px" width="214.99px" icon="trustedDevicesMain-icon" />
            </div>
            <div class="mainText mt-40px">
              <b>This device is not on your trusted devices. Do you want to add on confirm Devices</b>
            </div>
            <vb-loading v-if="api.confirm_device.send" /> 
            <template v-else>
              <b-button class="fullBlackBTN mt-40px" type="submit" variant="primary">Yes</b-button>
              <b-button type="button" @click="addconfirmdevice('no')" class="BlackOutlineBTN mt-20px" variant="primary">No</b-button>
            </template>
          </div>
        </div>
      </div>
    </b-form>
    <!-- pin auth  -->
    <div v-else-if="conditions.screen == screens.pin_auth" class="signUpPage-container">
      <SignupSlider />
      <div class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner d-flex align-items-center">
          <b-form class="w-100" @submit.prevent="pinauth()">
            <div class="m-auto w-100">
              <b-alert :show="!!api.pin_auth.error_message" variant="danger">{{ api.pin_auth.error_message }}</b-alert>
              <!-- <div class="mainHeading-smallerVersion">Enter Pin</div> -->
              <div class="mainHeading-smallerVersion mt-40px">Enter PIN code</div>
              <div class="signupLoginV2-sub-heading text-center color-primary mt-32px">
                <!-- We have just sent you an email to {{forms.login.username}} with a security PIN code. Please enter the PIN code below.  -->
                We have just sent you an email with a security PIN code. Please enter the PIN code below. 
              </div>
              <div class="latestPin-container bigger mt-70px with-error">
                <div class="latestPin-inner pin">
                  <vb-pin-code class="input" @complete="forms.pin_auth.code=$event" />
                </div>
                <template v-if="(forms.pin_auth.submitted && $v.forms.pin_auth.code.$invalid) || api.pin_auth.validation_errors.code">
                  <div class="errorTemplate-v2">
                    <p v-if="forms.pin_auth.submitted && $v.forms.pin_auth.pin.$invalid" class=" animated bounceIntop">
                      <span v-if="!$v.forms.pin_auth.code.required">* pincode is required</span>
                      <span v-else-if="!$v.forms.pin_auth.code.valid">* pincode is invalid</span>
                    </p>
                    <p v-if="api.pin_auth.validation_errors.code" class=" animated bounceIntop">
                      <span v-for="error_message in api.pin_auth.validation_errors.code" :key="error_message">* {{ error_message }}</span>
                    </p>
                  </div>
                </template>
              </div>
              <!-- buttons -->
              <b-button  class="fullBlackBTN mt-40px" type="submit" variant="primary">
                <vb-spinner v-if="api.pin_auth.send" />
                <template v-else>
                  Log in
                  <b-icon icon="arrow-right-short" class="ml-24px iconi" scale="1.5"/>
                </template>
              </b-button>
              <b-button class="BlackOutlineBTN mt-20px" variant="primary" type="button" :disabled="api.pin_auth.send" @click="conditions.screen=screens.login">Back to Login</b-button>
            </div>
          </b-form>
        </div>
      </div>
    </div>
    <!-- blocked  -->
    <div v-else-if="conditions.screen == screens.blocked" class="dialer-login-form notAllowed">
      <div class="notAllowed-inner">
        <div class="notAllowed-innerBox">
          <vb-icon icon="newSignup-blockUser-icon" class="notAllowed-innerBox-icon" width="125.705px" height="124.001px" />
          <div class="notAllowed-innerBox-heading">User is Blocked</div>
          <div class="notAllowed-innerBox-text">
            Your account has been blocked. Please contact support for assistance.
          </div>
          <!-- <div class="notAllowed-innerBox-text">
            <span class="makeBold">Contact Number:</span> 123-456-7890
          </div> -->
          <div class="notAllowed-innerBox-text">
            <span class="makeBold">Reason:</span> {{ response.blocked.message }}
          </div>
          <button class="newButton mwd-265px mt-32px" @click="$modal.show('supportTicketModal')">
            <vb-icon icon="newSignup-submitTicket-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Submit a ticket</span>
          </button>
        </div>
        <div class="notAllowed-rightSideBox">
          <vb-icon icon="newSignup-supportTicketSideBar-icon" class="newSignup-supportTicketSideBar-icon" height="50px" width="50px" />
        </div>
        <div class="bottomSection">
          Back to
          <a :disabled="api.pin_auth.send" @click="conditions.screen=screens.login" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
        </div>
      </div>
      <modal name="supportTicketModal" class="dialer_animation right_side_popup ticketDetailModal">
        <div v-if="false" class="w-100">
          <div v-for="n in 4" :key="n.id" :class="`latestShimmerDesign w-100 my-4`" style="height:80px;"></div>
        </div>
        <div v-else class="SupportTicketDetail-container">
          <div class="dialer-edit-header headerInMobile">
            <div class="d-flex justify-content-between align-items-center">
              <h2 class="dialer-edit-title newer mb-0">Ticket #123</h2>
              <b-icon @click="$modal.hide('supportTicketModal')" class="cursor_pointer" icon="x" font-scale="1.8" />
            </div>
            <div class="d-flex mt-2 w-100">
              <h2 class="dialer-edit-title newer mb-0">Subject:</h2>
              <span class="ticket-subject ms-2">
                ticket.subject
              </span>
            </div>
          </div>
          <div class="dialer-edit-header align-items-start">
            <div class="customWidth-parent">
              <div>
                <h2 class="dialer-edit-title newer mb-0">Ticket #123</h2>
                <div class="d-flex mt-2 customWidth">
                  <h2 class="dialer-edit-title newer mb-0">Subject:</h2>
                  <span class="ticket-subject ms-2">
                    ticket.subject
                  </span>
                </div>
              </div>
            </div>
            <!-- <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" /> -->
            <!-- v-else -->
            <a class="newCloseButton" @click="$modal.hide('supportTicketModal')">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
          <div class="latestGreyBox-9-9-2023">
            <div class="ticketTopInfoContainer">
              <div class="ticketTopInfoContainer-row">
                <div class="ticketTopInfoContainer-column one">SUBMITTER</div>
                <div class="ticketTopInfoContainer-column two">myself</div>
              </div>
              <div class="ticketTopInfoContainer-row">
                <div class="ticketTopInfoContainer-column one">SUBMITTED</div>
                <div class="ticketTopInfoContainer-column two">25 days ago</div>
              </div>
              <div class="ticketTopInfoContainer-row">
                <div class="ticketTopInfoContainer-column one">DEPARTMENT</div>
                <div class="ticketTopInfoContainer-column two">Development</div>
              </div>
              <div class="ticketTopInfoContainer-row">
                <div class="ticketTopInfoContainer-column one">STATUS/PRIORITY</div>
                <div class="ticketTopInfoContainer-column two">Active</div>
              </div>
            </div>
            <div class="ticketConversation-container">
              <div v-if="false" class="h-100 d-flex d-flex justify-content-center align-items-center my-5">
                <div class="w-75 text-center mt-20px">
                  <div class="latestGreyBox-heading-main">No message to see here</div>
                  <div class="latestGreyBox-heading-description mt-10px">You can send one by writing a message below</div>
                </div>
              </div>
              <div v-else>
                <div :class="`ticketConversation-item sender`">
                  <!-- ${message.user_id==userId? 'sender':'receiver'} -->
                  <div class="ticketConversation-item-top">
                    <div class="ticketConversation-item-top-left">
                      Frank Fahad
                    </div>
                    <div class="ticketConversation-item-top-right">
                      <b-icon icon="clock" ></b-icon>
                      At 06:28 am , 11 Jul 2024
                    </div>
                  </div>
                  <div class="ticketConversation-item-bottom">
                    <p class="mb-0">
                      We apologise for the delayed response. Unfortunately, we do not offer SMS services in bulk. Our SMS service is available at a rate of 5 pence per message. Currently, we only provide call packages.

                      If there is anything else i can help you with please contact us
                    </p>
                  </div>
                </div>
              </div>
              <div class="ticketConversation-actions">
                <template v-if="true">
                  <!-- status.slug == 'active' -->
                  <div class="ticketActions-heading">To update this ticket with more information please enter the details below:-</div>
                  <div class="ticketActions-textArea">
                    <textarea rows="7" placeholder="Write your message" class="w-100"></textarea>
                    <!-- <p v-if="$v.forms.create_comment.$error" class="errorRed">
                      <span v-if="!$v.forms.create_comment.required">Message is required</span>
                    </p> -->
                  </div>
                </template>
                <div class="d-flex mt-16px">
                  <button v-if="true" class="newButton mr-20px" >
                    <vb-icon icon="squared-updateTicket-icon" height="38px" width="38px" />
                    <span class="newButton-textPart d-flex justify-content-center position-relative">
                      Update ticket
                    </span>
                  </button>
                  <a class="newCloseButton" >
                    <template v-if="true">
                      <vb-icon icon="squared-openTicket-icon" height="38px" width="38px" />
                      <span class="newCloseButton-textPart">Reopen ticket</span>
                    </template>
                    <template v-else>
                      <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                      <span class="newCloseButton-textPart">Close ticket</span>
                    </template>
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </modal>
      <!-- <SignupSlider />
      <div class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner d-flex align-items-center">
          <b-form class="w-100" @submit.prevent="''">
            <div class="m-auto w-100">
              you are blocked
              {{ response.blocked.message }}
              <b-button class="BlackOutlineBTN mt-20px" variant="primary" type="button" :disabled="api.pin_auth.send" @click="conditions.screen=screens.login">Back to Login</b-button>
            </div>
          </b-form>
        </div>
      </div> -->
    </div>
    <!-- forget  -->
    <div v-else-if="conditions.screen == screens.forget" class="signUpPage-container">
      <SignupSlider />
      <div class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner d-flex align-items-center">
          <b-form class="w-100" @submit.prevent="forgotpassword()">
            <div class="m-auto">
              <div class="mainHeading mt-16px">Forgot Password?</div>
              <div class="subIndicatorText mt-8px">
                <template>step 1</template>
              </div>
              <div class="SubHeading text-center mt-12px">
                <template v-if="forms.forgot.sub_user">
                  Enter the email address linked to your account or enter an admin email address
                </template>
                <template v-else>Enter your email or username</template>
              </div>
              <b-alert :show="!!api.forgot.error_message" variant="danger">{{ api.forgot.error_message }}</b-alert>
              <div v-if="forms.forgot.sub_user" class="mainInput-container mb-40px with-error">
                <label>Email</label>
                <div :class="`mainInput ${api.forgot.send?'disabled':''}`"> 
                  <b-form-input type="text" :disabled="api.forgot.send" v-model="forms.forgot.notfication_email" />
                </div>
                <template v-if="(forms.forgot.submitted && $v.forms.forgot.notfication_email.$invalid) || api.forgot.validation_errors.notfication_email">
                  <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p v-if="(forms.forgot.submitted && $v.forms.forgot.notfication_email.$invalid)" class="mb-1 text-small animated bounceIntop">
                      <span v-if="!$v.forms.forgot.notfication_email.required">* email is required</span>
                      <span v-else-if="!$v.forms.forgot.notfication_email.email">* email is invalid</span>
                    </p>
                    <p v-else-if="api.forgot.validation_errors.notfication_email" class=" animated bounceIntop">
                      <span v-for="em in api.forgot.validation_errors.notfication_email" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </template>
              </div>
              <div v-else class="mainInput-container mb-40px with-error">
                <label>Username / Email</label>
                <div :class="`mainInput ${api.forgot.send?'disabled':''}`"> 
                  <b-form-input type="text" :disabled="api.forgot.send" v-model="forms.forgot.email" />
                </div>
                <template v-if="(forms.forgot.submitted && $v.forms.forgot.email.$invalid) || api.forgot.validation_errors.email">
                  <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p v-if="(forms.forgot.submitted && $v.forms.forgot.email.$invalid)" class="mb-1 text-small animated bounceIntop">
                      <span v-if="!$v.forms.forgot.email.required">* email is required</span>
                    </p>
                    <p v-else-if="api.forgot.validation_errors.email" class=" animated bounceIntop">
                      <span v-for="em in api.forgot.validation_errors.email" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </template>
              </div>
              <!-- buttons -->
              <b-button :disabled="api.forgot.send" class="fullBlackBTN" type="submit" variant="primary">
                <vb-spinner v-if="api.forgot.send" />
                <template v-else>Reset Password</template>
              </b-button>
              <!-- <b-button @click="conditions.screen=screens.login" :disabled="api.forgot.send" class="BlackOutlineBTN mt-20px" type="button" variant="primary">Back to Login</b-button> -->
            </div>
          </b-form>
        </div>
        <div class="bottomSection">
          Oh!, I remember my password. Take me back to 
          <a @click="conditions.screen=screens.login" :disabled="api.forgot.send" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
        </div>
      </div>
    </div>
    <!-- pincode  -->
    <div v-else-if="conditions.screen == screens.pincode" class="signUpPage-container">
      <SignupSlider />
      <div class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner d-flex align-items-center">
          <div class="m-auto w-100">
            <img src="../assets/images/checkYourEmailIMG.png" alt="icon" class="newPasswordScreenImage"/>
            <div class="mainHeading mt-16px">Forgot Password?</div>
            <div class="subIndicatorText mt-8px">step 2</div>
            <div class="mainHeading-smallerVersion mt-16px">Check your email!</div>
            <div class="SubHeading text-center mt-12px">A pin has been sent to yours or an admin email please enter it below</div>
            <!-- <div class="mainHeading-smallerVersion">Enter Pin</div> -->
            <div class="latestPin-container bigger mt-70px with-error">
              <b-alert variant="danger" :show="!!api.pin.error_message">{{ api.pin.error_message }}</b-alert>
              <b-alert class="mb-16px" :show="!!api.resend.error_message" variant="danger">{{ api.resend.error_message }}</b-alert>
              <b-alert class="mb-16px" :show="!!api.resend.success_message" variant="success">{{ api.resend.success_message }}</b-alert>
              <div class="latestPin-inner pin">
                <vb-pin-code class="input" :disabled="api.pin.send || api.resend.send" @complete="forms.pin.pincode=$event;verifyPincode();" />
                <template v-if="(forms.pin.submitted && $v.forms.pin.pincode.$invalid) || api.pin.validation_errors.pincode">
                  <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p v-if="(forms.pin.submitted && $v.forms.pin.pincode.$invalid)" class="mb-1 text-small animated bounceIntop">
                      <span v-if="!$v.forms.pin.pincode.required">* pincode is required</span>
                    </p>
                    <p v-else-if="api.pin.validation_errors.pincode" class=" animated bounceIntop">
                      <span v-for="em in api.pin.validation_errors.pincode" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </template>
              </div>
              <div class="w-100 d-flex justify-content-center align-items-center mt-20px">
                <span :class="`cursor_pointer min-width-185px d-flex ${api.resend.count_down.seconds!=0?'justify-content-between':'justify-content-center'} align-items-center`" @click="api.resend.count_down.seconds!=0 ? '' : resend()">
                  <span :aria-disabled="api.resend.count_down.seconds!=0" :disabled="api.resend.count_down.seconds!=0">
                    <b-icon class="me-2" icon="arrow-clockwise" :animation="api.resend.send?'spin':''"  v-b-tooltip.hover  title="Resend Email to Confirm"  />
                    <span class="me-2">Resend Email</span>
                  </span>
                  <span v-if="api.resend.count_down.seconds!=0">{{ api.resend.count_down.seconds | duration_format({ format: 'mm:ss', default: '00:00' }) }}</span>
                </span>
              </div>
              <div v-if="api.pin.count!=0" class="attemptesIndicatorText mt-40px">{{3-api.pin.count+1}} attempts remaining</div>
            </div>
            <!-- buttons -->
            <b-button class="fullBlackBTN mt-40px" @click="conditions.screen=screens.forget" type="submit" variant="primary">Back</b-button>
          </div>
        </div>
        <div class="bottomSection">
          Oh!, I remember my password. Take me back to 
          <a @click="conditions.screen=screens.login" :disabled="api.forgot.send" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
        </div>
      </div>
    </div>
    <!-- reset pass  -->
    <div v-else-if="conditions.screen == screens.reset" class="signUpPage-container">
      <SignupSlider />
      <div class="signUpPage-rightSide">
        <div class="signUpPage-rightSide-inner d-flex align-items-center">
          <b-form class="w-100" @submit.prevent="reset()">
            <div class="m-auto w-100">
              <b-alert :show="!!api.reset.error_message" variant="danger">{{ api.reset.error_message }}</b-alert>
              <div class="mainHeading mt-16px">Forgot Password?</div>
              <!-- <div class="subIndicatorText mt-8px">step 4</div> -->
              <div class="subIndicatorText mt-8px">step 3</div>
              <div class="mainHeading-smallerVersion mt-16px">Create new password</div>
              <div class="SubHeading text-center mt-12px">Enter a strong and memorable password</div>
              <!-- <div class="mainHeading-smallerVersion">Enter New Password</div> -->
              <div class="mainInput-container mt-40px mb-32px with-error">
                <label>New Password</label>
                <div :class="`mainInput password ${api.reset.send?'disabled':''}`"> 
                  <b-form-input :type="forms.reset.password_show?'text':'password'" v-model="forms.reset.password"  />
                  <span class="pass-icon">
                    <b-icon class="dialer-showpassword-icon cursor_pointer" :icon="forms.reset.password_show ? 'eye-slash-fill' : 'eye-fill'" @click="forms.reset.password_show=!forms.reset.password_show" />
                  </span>
                </div>
                <template v-if="(forms.reset.submitted && $v.forms.reset.password.$invalid) || api.reset.validation_errors.password">
                  <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p v-if="forms.reset.submitted && $v.forms.reset.password.$invalid" class=" animated bounceIntop">
                      <span v-if="!$v.forms.reset.password.required">* Password is required</span>
                    </p>
                    <p v-if="api.reset.validation_errors.password" class=" animated bounceIntop">
                      <span v-for="error_message in api.reset.validation_errors.password" :key="error_message">* {{ error_message }}</span>
                    </p>
                  </div>
                </template>
              </div>
              <div class="mainInput-container mt-40px mb-32px with-error">
                <label>Confirm Password</label>
                <div :class="`mainInput password ${api.reset.send?'disabled':''}`"> 
                  <b-form-input :type="forms.reset.confirm_password_show?'text':'password'" v-model="forms.reset.confirm_password"  />
                  <span class="pass-icon">
                    <b-icon class="dialer-showpassword-icon cursor_pointer" :icon="forms.reset.confirm_password_show ? 'eye-slash-fill' : 'eye-fill'" @click="forms.reset.confirm_password_show=!forms.reset.confirm_password_show" />
                  </span>
                </div>
                <template v-if="(forms.reset.submitted && $v.forms.reset.confirm_password.$invalid)">
                  <b-icon icon="info-circle-fill" variant="danger" class="danger-info-icon"/>
                  <div class="errorTemplate-v2">
                    <p v-if="forms.reset.submitted && $v.forms.reset.confirm_password.$invalid" class=" animated bounceIntop">
                      <span v-if="!$v.forms.reset.confirm_password.required">* Confirm Password is required</span>
                    </p>
                  </div>
                </template>
              </div>
              <div class="PasswordLength-LineContainer mt-0 mb-16px">
                <div class="line-wrap">
                  <div 
                    :class="`line-itself ${(passwordStrength/5 * 100) > 60 ? 'strong' : ''} `" 
                    :style="`width: calc(${passwordStrength/5 * 100}% + ${passwordStrength>0 ? '2' : '0'}px);`"
                  />
                </div>
                <small class="line-text">{{passwordStrength | filterPasswordStrengthText}}</small>
              </div>
              <!-- buttons -->
              <b-button :disabled="api.reset.send" class="fullBlackBTN mt-40px" type="submit" variant="primary">
                <vb-spinner v-if="api.reset.send" />
                <template v-else>Reset Password</template>
              </b-button>
              <b-button class="BlackOutlineBTN mt-20px" variant="primary" type="button" @click="conditions.screen=screens.pincode">Back</b-button>
            </div>
          </b-form>
        </div>
        <div class="bottomSection">
          Oh!, I remember my password. Take me back to 
          <a @click="conditions.screen=screens.login" :disabled="api.forgot.send" class="mx-2 font-weight-bold font-size-14 color-primary">Log In</a>
        </div>
      </div>
    </div>
  </div>
</template>
<script>
import { required } from "vuelidate/lib/validators";
import { TokenService } from "../services/storage.service";
import { $fn, VOIP_API } from "../utils";
import { electron_events } from '../electron/events';
import { mapGetters, mapState } from 'vuex';
import { INTEGRATIONS } from '@/integrations';
import SignupSlider from './SignupSlider.vue';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
import sameAs from 'vuelidate/lib/validators/sameAs';
const screens = {
  login: "login",
  organization: "organization",
  extension_occupied: "extension_occupied",
  confirm_device: "confirm_device",
  blocked: "blocked",
  pin_auth: "pin_auth",
  forget: "forget",
  pincode: "pincode",
  reset: "reset",
};
export default {
  name: "Login",
  components: {
    SignupSlider
  },
  data() {
    return {
      forms: {
        login: this.$helperFunction.formInstance({
          data: {
            username: '',
            password: '',
            password_show: false,
            remember_me: false,
          },
        }),
        pin_auth: this.$helperFunction.formInstance({
          data: {
            code: '',
          },
        }),
        forgot: this.$helperFunction.formInstance({
          data: {
            email: '',
            notification_email: '',
            sub_user: false,
          },
        }),
        pin: this.$helperFunction.formInstance({
          data: {
            pincode: '',
          }
        }),
        reset: this.$helperFunction.formInstance({
          data: {
            password: '',
            password_show: false,
            confirm_password: '',
            confirm_password_show: false,
          },
        }),
      },
      response: {
        login: {},
        blocked: {},
        organizations: [],
        extensions: [],
      },
      conditions: {
        screen: screens.login,
      },
      api: {
        login: this.$helperFunction.apiInstance({ 
          error_message: true,
          validation_errors: true,
        }),
        pin_auth: this.$helperFunction.apiInstance({ 
          error_message: true,
          validation_errors: true,  
        }),
        confirm_device: this.$helperFunction.apiInstance({ 
          error_message: true,  
        }),
        organization_login: this.$helperFunction.apiInstance({ 
          error_message: true,  
        }),
        extension_signout: this.$helperFunction.apiInstance({ 
          error_message: true,  
        }),
        forgot: this.$helperFunction.apiInstance({ 
          error_message: true,
          validation_errors: true,
        }),
        pin: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
          count: true,
        }),
        reset: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
          status: true,
        }),
        resend: this.$helperFunction.apiInstance({
          error_message: true,
          success_message: true,
          validation_errors: true,
          request_time: true,
          count_down: true,
        }),
      },
      selected: {
        organization_id: '',
        organization: null,
      },
    };
  },
  computed: {
    ...mapState({
      electron: (state)=>state.electron,
    }),
    ...mapGetters([
      'fpData',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
    isDevelopment(){ return process.env.NODE_ENV!='production' },
    screens(){ return screens },
    loginPlatform(){
      if(process.env.IS_ELECTRON) return 'electron'
      else if(process.env.VUE_APP_INTEGRATION=='true') return 'integration'
      else return 'web'
    },
    randomImage(){ 
      const images = [
        require("@/assets/images/login/login-img1.png"),
        require("@/assets/images/login/login-img2.png"),
        require("@/assets/images/login/login-img3.png"),
        require("@/assets/images/login/login-img4.png"),
        require("@/assets/images/login/login-img5.png"),
        require("@/assets/images/login/login-img6.png"),
      ]
      return images[Math.floor(Math.random() * images.length)]; 
    },
    passwordStrength(){
      const password = this.forms.reset.password
      return ~~[/[A-Z]/.test(password),/[0-9]/.test(password),/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(password),/[a-z]/.test(password),password.length > 11,].reduce((c,v)=>v?c+1:c)
    },
  },
  validations: {
    forms: {
      login: {
        username: {
          required,
        },
        password: {
          required,
        },
      },
      pin_auth: {
        code: {
          required,
          valid(value){ return value.length==6 },
        },
      },
      forgot: {
        email: {
          required,
        },
        notfication_email: {
          required: requiredIf(function(){
            return this.forms.forgot.sub_user
          })
        },
      },
      pin: {
        pincode: {
          required,
          valid: (value)=>{
            return value.length===6
          },
        },
      },
      reset: {
        password: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(32),
          capitalalphabet: function(value){
            return /[A-Z]/.test(value)
          },
          smallalphabet: function(value){
            return /[a-z]/.test(value)
          },
          numbers: function(value){
            return /[0-9]/.test(value)
          },
          specialcharacter: function(value){
            return /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(value)
          },
        },
        confirm_password: {
          required: required, 
          sameAsPassword: sameAs('password')
        }
      },
    },
  },
  watch: {
    "conditions.screen"(screen){
      if(screen==screens.login){
        this.selected.organization_id=''
        this.api.forgot.reset()
        this.api.pin.reset()
        this.api.reset.reset()
        this.api.resend.reset()
        this.forms.forgot.reset()
        this.forms.pin.reset()
        this.forms.reset.reset()
      } else if(screen==screens.forget){
        this.response.login = {}
        this.forms.login.reset()
      } 
    },
  },
  filters: {
    jsonParse(value){ return JSON.parse(value) },
    extensionDeviceInfo(data,value_type,current_device_id){
      const is_desktop = data?.platform=='desktop'
      const is_web = data?.platform=='web'
      const is_mobile = data?.platform=='mobile'
      const is_postman = data?.platform=='postman'
      const is_extension = data?.platform=='extension'
      const is_integration = data?.platform=='integration'
      const browser = data?.browser ?? ''
      const name = data?.name ?? ''
      const os = data?.os ?? ''
      const os_version = data?.os_version ?? ''
      const device_id = data?.device_id ?? ''
      if(value_type=='icon'){
        if(is_desktop) return 'trustedDevice-desktop-icon'
        else if(is_mobile) {
          if(os=='android') return 'trustedDevice-android-OSIcon'
          if(os=='ios') return 'trustedDevice-apple-OSIcon'
        } else if(is_web) {
          if(browser=='Chrome') return 'trustedDevice-chrome-icon'
          else if(browser=='Opera') return 'trustedDevice-opera-icon'
          else if(browser=='Edge') return 'trustedDevice-microsoft-icon'
          else if(browser=='Firefox') return 'trustedDevice-firefox-icon'
          else if(browser=='Safari') return 'trustedDevice-safari-icon'
        } else if (is_integration){
          if(name==INTEGRATIONS.platform_instance.hubspot) return 'trustedDevice-chrome-icon'
          else if(name==INTEGRATIONS.platform_instance.zoho) return 'trustedDevice-chrome-icon'
          else if(name==INTEGRATIONS.platform_instance.salesforce) return 'trustedDevice-chrome-icon'
        } else if (is_postman) return 'trustedDevice-chrome-icon'
        else if(is_extension) return 'trustedDevice-chrome-icon'
      } else if (value_type=='osicon') {
        if(os=='android') return 'trustedDevice-androidPhone-icon'
        else if(os=='ios') return 'trustedDevice-IPhone-icon'
        else if(os=='Windows' && os_version=='11') return 'trustedDevice-window11-OSIcon'
        else if(os=='Windows') return 'trustedDevice-window10-OSIcon'
        else if(os=='Linux') return 'trustedDevice-linux-OSIcon'
        else if(os?.includes?.('MAC')) return 'trustedDevice-appleMac-OSIcon'
        else return 'trustedDevice-window10-OSIcon'
      } else if(value_type=='ip'){
        return data?.ip ?? ''
      } else if(value_type=='location'){
        return `${data?.city ?? ''} ${data?.country ?? ''}`
      } else if(value_type=='app-name'){
        if(name) return name
        else if(is_desktop) return 'Desktop App'
        else if(is_mobile) return 'Mobile App'
        else if(is_web) return 'Web App'
        else if(is_postman) return 'Postman App'
        else if(is_extension) return 'Browser Extension App'
      } else if(value_type=='current-device'){
        return current_device_id==device_id
      }
      return ''
    },
    filterPasswordStrengthText(value){
      return ['None','very weak','weak','average','strong','very strong'][value] || ''
    },
  },
  methods: {
    // login
    async login(){
      this.forms.login.submitted=true
      this.$v.forms.login.$touch();
      if (this.$v.forms.login.$invalid || this.api.login.send) return;
      try {
        this.api.login.send=true
        this.api.login.error_message=''
        this.api.login.validation_errors={}
        await VOIP_API.endpoints.dns.deviceInfo()
        const { data } = await VOIP_API.endpoints.auth.userServer({
          // intergrated_platform: INTEGRATIONS.platform,
          platform: this.loginPlatform,
          flag: 'dailer',
          username: this.forms.login.username,
          password: this.forms.login.password,
          device_id: this.fpData.device_id,
          fp_data: this.fpData,
          type: 'login',
        })
        this.afterlogin(data)
      } catch (ex) {
        this.api.login.error_message=ex?.response_error?.status_code==500 ? 'Invalid Username or Password' : ex.own_message || ex.message || 'Invalid Username or Password'
        this.api.login.validation_errors=ex.own_errors || {}
      } finally {
        this.api.login.send=false
        this.forms.login.submitted=false
      }
    },
    async organizationlogin(organization){
      if (this.api.organization_login.send) return;
      try {
        this.api.organization_login.send=true
        this.api.organization_login.error_message=''
        await VOIP_API.endpoints.dns.deviceInfo()
        const { data } = await VOIP_API.endpoints.auth.userServer({
          // intergrated_platform: INTEGRATIONS.platform,
          platform: this.loginPlatform,
          flag: 'dailer',
          username: this.forms.login.username,
          password: this.forms.login.password,
          device_id: this.fpData.device_id,
          fp_data: this.fpData,
          type: 'org-login',

          id: organization.id,
          uid: organization.uid,
        })
        this.selected.organization=organization
        this.afterlogin(data,organization.id)
      } catch (ex) {
        if(ex.response_error && ex.response_error.status_code==500){
          this.api.organization_login.error_message='invalid username or password'
        } else {
          this.api.organization_login.error_message=ex.own_message || ''
        }
      } finally {
        this.api.organization_login.send=false
      }
    },
    async addconfirmdevice(device_confirm){
      if (this.api.confirm_device.send) return;
      try {
        this.api.confirm_device.send=true
        this.api.confirm_device.error_message=''
        await VOIP_API.endpoints.dns.deviceInfo()
        const { data } = await VOIP_API.endpoints.auth.userServer({
          // intergrated_platform: INTEGRATIONS.platform,
          platform: this.loginPlatform,
          flag: 'dailer',
          username: this.forms.login.username,
          password: this.forms.login.password,
          device_id: this.fpData.device_id,
          fp_data: this.fpData,
          type: 'add-device',

          id: this.selected.organization?.id,
          uid: this.selected.organization?.uid,
          device_confirm: device_confirm,
        })
        this.afterlogin(data)
      } catch (ex) {
        if(ex.response_error && ex.response_error.status_code==500){
          this.api.confirm_device.error_message='invalid username or password'
        } else {
          this.api.confirm_device.error_message=ex.own_message || ''
        }
      } finally {
        this.api.confirm_device.send=false
      }
    },
    async pinauth(){
      this.forms.pin_auth.submitted=true
      this.$v.forms.pin_auth.$touch()
      if (this.api.pin_auth.send || this.$v.forms.pin_auth.$invalid) return;
      try {
        this.api.pin_auth.send=true
        this.api.pin_auth.error_message=''
        this.api.pin_auth.validation_errors={}
        await VOIP_API.endpoints.dns.deviceInfo()
        const { data } = await VOIP_API.endpoints.auth.userServer({
          // intergrated_platform: INTEGRATIONS.platform,
          platform: this.loginPlatform,
          flag: 'dailer',
          username: this.forms.login.username,
          password: this.forms.login.password,
          device_id: this.fpData.device_id,
          fp_data: this.fpData,
          type: '2FA',

          id: this.selected.organization?.id,
          uid: this.selected.organization?.uid,
          code: this.forms.pin_auth.code,
        })
        this.afterlogin(data)
      } catch (ex) {
        if(ex.response_error && ex.response_error.status_code==500){
          this.api.pin_auth.error_message='invalid username or password'
        } else {
          this.api.pin_auth.error_message=ex.own_message || ''
        }
        this.api.pin_auth.validation_errors=ex.own_errors || {}
      } finally {
        this.api.pin_auth.send=false
        this.forms.pin_auth.submitted=true
      }
    },
    async extensionsignout(id){
      if (this.api.extension_signout.send) return;
      try {
        this.api.extension_signout.send=id
        this.api.extension_signout.error_message=''
        await VOIP_API.endpoints.dns.deviceInfo()
        const { data } = await VOIP_API.endpoints.auth.userServer({
          // intergrated_platform: INTEGRATIONS.platform,
          platform: this.loginPlatform,
          flag: 'dailer',
          username: this.forms.login.username,
          password: this.forms.login.password,
          device_id: this.fpData.device_id,
          fp_data: this.fpData,
          type: 'extn-signout',
          id: this.selected.organization?.id,
          uid: this.selected.organization?.uid,
          kick_extension: id,
        })
        this.afterlogin(data)
      } catch (ex) {
        if(ex.response_error && ex.response_error.status_code==500){
          this.api.extension_signout.error_message='invalid username or password'
        } else {
          this.api.extension_signout.error_message=ex.own_message || ''
        }
      } finally {
        this.api.extension_signout.send=''
      }
    },
    afterlogin(data,id=''){
      if (data.field=="blocked") {
        this.response.blocked = data || {};
        this.conditions.screen = screens.blocked;
      } else if (data.field=="organization") {
        this.response.organizations = data.data;
        this.conditions.screen = screens.organization;
      } else if (data.account_created=="0") {
        this.$appConfirmation({
          title: "Error",
          message: `Please complete for signup process`,
          button: {
            no: "Cancel",
            yes: "Go",
          },
        });
      } else if (data?.response?.device || data.device){
        this.conditions.screen = screens.confirm_device;
      } else if (data?.response?.TFA || data.TFA){
        this.conditions.screen = screens.pin_auth;
      } else if (data?.response?.extension_occupied || data.extension_occupied){
        this.conditions.screen = screens.extension_occupied;
        this.response.extensions = data?.response?.extension_occupied_list || data.extension_occupied_list || []
      } else if (data.response) {
        if(this.forms.login.remember_me){
          TokenService.REMEMBER_ME.set({
            username: this.forms.login.username,
            password: this.forms.login.password,
          })
        }
        $fn.setUserLocally(data,this.forms.login.password,this.response.organizations,id);
        this.$router.push("/");
      }
    },
    // forget password
    async forgotpassword(){
      this.forms.forgot.submitted=true
      this.$v.forms.forgot.$touch()
      if(this.api.forgot.send || this.$v.forms.forgot.$invalid) return;
      try {
        this.api.forgot.send=true
        this.api.forgot.error_message = ''
        this.api.forgot.validation_errors = {}
        await VOIP_API.endpoints.dns.deviceInfo()
        const { data } = await VOIP_API.endpoints.auth.forgotpassword({
          username: this.forms.forgot.email,
          type: 'forget',
          device_id: this.fpData.device_id,
          ...this.forms.forgot.sub_user ? {
            notification_email: this.forms.forgot.notfication_email,
          } : {},
        })
        if(data.type=='sub-user'){
          this.forms.forgot.sub_user=true
        } else {
          this.conditions.screen=screens.pincode
        }
      } catch (ex) {
        this.api.forgot.validation_errors = ex.own_errors || {}
        this.api.forgot.error_message = ex.own_message || ex.message
      } finally {
        this.forms.forgot.submitted=false
        this.api.forgot.send=false
      }
    },
    async verifyPincode(){
      this.forms.pin.submitted=true
      this.$v.forms.pin.$touch()
      if(this.api.pin.send || this.$v.forms.pin.$invalid || this.api.pin.count>3) return;
      try {
        this.api.pin.send=true
        this.api.pin.error_message=''
        await VOIP_API.endpoints.dns.deviceInfo()
        await VOIP_API.endpoints.auth.forgotpassword({
          username: this.forms.forgot.email,
          type: 'verify',
          device_id: this.fpData.device_id,
          ...this.forms.forgot.sub_user ? {
            notification_email: this.forms.forgot.notfication_email,
          } : {},
          pincode: this.forms.pin.pincode,
        })
        this.conditions.screen=screens.reset;
      } catch (ex) {
        this.api.pin.increment()
        this.api.pin.error_message = ex.own_message
      } finally {
        this.api.pin.send=false
        this.forms.pin.submitted=false
      }
    },
    async resend(){
      if(this.api.resend.send) return;
      try {
        if(this.api.resend.request_time.compare('minutes',3)<3) throw new Error('Wait for 3 Minutes')
        this.api.resend.send=true
        this.api.resend.error_message=''
        this.api.resend.success_message=''
        await VOIP_API.endpoints.auth.forgotpassword({
          username: this.forms.forgot.email,
          type: 'resend',
          ...this.forms.forgot.sub_user ? {
            notification_email: this.forms.forgot.notfication_email,
          } : {},
        })
        this.api.resend.request_time.setTime()
        this.api.resend.count_down.start(3,'minute')
        this.api.resend.success_message='Successfully Send'
        this.api.resend.emptySuccessMessage(3)
      } catch (ex) {
        this.api.resend.error_message = ex.own_message || ex.message
      } finally {
        this.api.resend.send=false
      }
    },
    async reset(){
      this.forms.reset.submitted=true
      this.$v.forms.reset.$touch()
      if(this.api.reset.send || this.$v.forms.reset.$invalid) return;
      try {
        this.api.reset.send=true
        this.api.reset.error_message = ''
        this.api.reset.validation_errors = {}
        this.api.reset.status = 0
        await VOIP_API.endpoints.auth.forgotpassword({
          username: this.forms.forgot.email,
          type: 'reset',
          ...this.forms.forgot.sub_user ? {
            notification_email: this.forms.forgot.notfication_email,
          } : {},
          pincode: this.forms.pin.pincode,
          password: this.forms.reset.password,
        })
        this.api.reset.status = 1
        this.conditions.screen=screens.login
      } catch (ex) {
        this.api.reset.status = 2
        this.api.reset.error_message = ex.own_message
        this.api.reset.validation_errors = ex.own_errors
      } finally {
        this.api.reset.send=false
        this.forms.reset.submitted=false
      }
    },
  },
  created() {
    const remember_me = TokenService.REMEMBER_ME.get() ?? {}
    this.forms.login.username = remember_me?.username ?? ''
    this.forms.login.password = remember_me?.password ?? ''
  },
  mounted() {
    if(process.env.IS_ELECTRON){
      window.electron.send(electron_events.TURN_OFF_SPLASH)
      window.electron.send(electron_events.LOGOUT)
      window.electron.send(electron_events.SET_BADGES,{
        count: 0,
      })
    }
  },
};
</script>