<template>
	<div class="right-calender">
    <div class="top-main-calender scrollow1">
      <section class="sec-common">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h5 class="emp-profile-heading">Time off approvers</h5>
          </div>
          <button class="btn btn-primarymike space4btn-when-scroll-activate" @click="$modal.show('ExportTimeoffApproverModal')">
            Export
          </button>
        </div>
        <div>
          <p class="notes-top-text space4text-when-scroll-activate">
            Here you can set up who will receive time off requests for approval for your employees
          </p>
        </div>
         <div class="mar-top-hrm25 d-flex flex-wrap">
          <div class="home-dd-container mr-0">
            <b-dropdown no-caret class="home-mainBorderfordropi">
              <template #button-content>
                <div class="home-button-content-dropi">
                  <div class="home-dropi-contenti">All teams</div>
                  <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                </div>
              </template>
              <b-dropdown-item>New</b-dropdown-item>
              <b-dropdown-item>To be announced (4)</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="home-dd-container">
            <b-dropdown no-caret class="home-mainBorderfordropi">
              <template #button-content>
                <div class="home-button-content-dropi">
                  <div class="home-dropi-contenti">All locations</div>
                  <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                </div>
              </template>
              <b-dropdown-item>GMT 5+</b-dropdown-item>
							<b-dropdown-item>GMT 9+</b-dropdown-item>
							<b-dropdown-item>GMT 8+</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="home-dd-container">
            <b-dropdown no-caret class="home-mainBorderfordropi">
              <template #button-content>
                <div class="home-button-content-dropi">
                  <div class="home-dropi-contenti">All employment statuses</div>
                  <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                </div>
              </template>
              <b-dropdown-item>Approved</b-dropdown-item>
              <b-dropdown-item>Pending</b-dropdown-item>
							<b-dropdown-item>Rejected</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <vb-table :tableDesign="1" class="timeoffapprover-Setting-table">
          <template slot="header">
            <div class="commonth onetd col-left ">
              <b-form-checkbox b-form-checkbox class="gm-hrm-checkbox">
              </b-form-checkbox>
            </div>
            <h5 class="commonth secondtd ">Employee</h5>
            <h5 class="commonth thirdtd">Admin</h5>
            <h5 class="commonth fourtd">Direct Manager</h5>
            <h5 class="commonth fivetd">Team Manager</h5>
            <h5 class="commonth sixtd ">Indivisual Approvers</h5>
            <h5 class="commonth seventd col-right">Action</h5>
          </template>
          <template slot="body">
            <tr>
              <td class="firsttd col-left">
               <b-form-checkbox b-form-checkbox class="SettingPageCheckbox">
               </b-form-checkbox>
              </td>
              <td class="secondtd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
                  <div class="timeoff-profile">
                    <h5 class="timeoff-profile-name">Jackson</h5>
                  </div>
               </div>
              </td>
              <td class="thirdtd ">
                yes
              </td>
              <td class="fourtd">
                No
              </td>
              <td class="fivetd">
                No
              </td>
              <td class="sixtd">
                -
              </td>
              <td class="seventd col-right">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                  <template #button-content>
                    <b-icon icon="three-dots-vertical"></b-icon>
                  </template>
                  <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
          </template>
        </vb-table>
      </section>
    </div>
		<ExportTimeoffApproverModal/>
	</div>
</template>

<script>
import ExportTimeoffApproverModal from "../Modals/ExportTimeoffApproverModal.vue"
export default {
  name: 'TimeoffApproversSettingHRM',
  components:{
	ExportTimeoffApproverModal,
  },
}
</script>

<style>

</style>