var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-svg', {
    staticClass: "cursor_pointer",
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _c('span', {
    staticClass: "dialer-settings-title newer md-mar-left"
  }, [_vm._v("Select sound")])], 1)]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.onDone();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Apply")])], 1)])]), _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_c('div', {
    staticClass: "dialer-box grayBox"
  }, [_c('div', {
    staticClass: "w-100 md-mar-bottom whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search sound"
    },
    model: {
      value: _vm.filter.sound.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.sound, "search", $$v);
      },
      expression: "filter.sound.search"
    }
  })], 1), _c('div', {
    staticClass: "innerWhiteBoxwithRoundedBorder"
  }, _vm._l(_vm.filterSounds, function (sound) {
    return _c('div', {
      key: sound.real_id,
      staticClass: "mb-3"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between w-100"
    }, [_c('span', {
      staticClass: "dialer-assignee-name font-weight-700 mr-4 minWidth25minus16px"
    }, [_vm._v(_vm._s(sound.label))]), _c('vb-audio-player', {
      staticClass: "whiteBGaudioWithGreyRoundedBorder-again calc-100-32px w-50",
      attrs: {
        "src": sound.sound_file
      }
    }), _c('b-icon', {
      attrs: {
        "icon": _vm.selected.sound.real_id === sound.real_id ? 'check-square-fill' : 'square',
        "variant": "black",
        "font-scale": "1.5"
      },
      on: {
        "click": function ($event) {
          _vm.selected.sound = sound;
        }
      }
    })], 1)]);
  }), 0)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }