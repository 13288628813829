var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition feedbackDuringJitsiCallModal",
    attrs: {
      "transition": "center-zoomIn-transition",
      "name": _vm.modalName,
      "scrollable": false
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to dialer-edit w-100"
  }, [_c('div', {
    staticClass: "p-3 dialer-edit-header d-flex aling-items-center justify-content-between w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Feedback")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('b-form', {
    staticClass: "whiteBoxWithBorderInsideGreyBox",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.submit();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-center"
  }, [_c('h4', {
    staticClass: "latestGreyBox-heading-main text-center mt-20px mb-0"
  }, [_vm._v(" How was the quality of your call? ")]), _c('b-form-rating', {
    staticClass: "latestCallFeedback",
    attrs: {
      "size": "lg",
      "no-border": "",
      "variant": "primary",
      "stars": "5"
    },
    model: {
      value: _vm.forms.feed_back.rate,
      callback: function ($$v) {
        _vm.$set(_vm.forms.feed_back, "rate", $$v);
      },
      expression: "forms.feed_back.rate"
    }
  }), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-20px"
  }, [_c('b-form-textarea', {
    staticClass: "w-100",
    model: {
      value: _vm.forms.feed_back.detail,
      callback: function ($$v) {
        _vm.$set(_vm.forms.feed_back, "detail", $$v);
      },
      expression: "forms.feed_back.detail"
    }
  })], 1), _c('div', {
    staticClass: "d-flex aling-items-center justify-content-end w-100 mt-4"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit"
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm._v(" Submit ")])], 1)], 1)], 1)])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }