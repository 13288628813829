<template>
  <div>
    
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.add_ivr.send?'':$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add IVR</div>
        <div class="plus-IOSIcon-container">
          
        </div>
      </div>
      <div class="innerModalIos">

        <form class="dialer-form lg-mar-bottom" @submit.prevent="addIVR()">
          <b-alert class="mt-32px" :show="!!api.add_ivr.error_message" variant="danger">{{api.add_ivr.error_message}}</b-alert>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Name</label>
                <input 
                  placeholder="Enter Name" 
                  class="w-100" 
                  type="text" 
                  onkeypress="return /([0-9A-Za-z \s])/i.test(event.key)" 
                  :disabled="api.add_ivr.send" 
                  v-model="forms.ivr.name" 
                />
                <p 
                  v-if="(forms.ivr.submitted && $v.forms.ivr.name.$invalid) || api.add_ivr.validation_errors.name" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.ivr.name.required">* Name is required</span>
                  <span v-for="em in api.add_ivr.validation_errors.name" :key="em">* {{ em }}</span>
                </p>
              </div>
            </div>
          </div>
          <button class="IosFullWidthButton forDone mb-16px" :disabled="api.add_ivr.send">
            <vb-spinner v-if="api.add_ivr.send" />
            <template v-else>Add</template>
          </button>
        </form>

      </div>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { VOIP_API } from '@/utils/index';
export default {
  name: "AddIVRModal",
  props: {
    modalName: {
      type: String,
      default: 'AddIVR',
    },
  },
  data() {
    return {
      forms: {
        ivr: this.$helperFunction.formInstance({
          data: {
            name: '',
            description: '',
          }
        }),
      },
      api: {
        add_ivr: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true }),
      },
    };
  },
  validations: {
    forms: {
      ivr: {
        name: { 
          required 
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      const {
        uniqueNamesGenerator,
        adjectives,
        colors,
        animals,
      } = require("unique-names-generator");
      const randomName = uniqueNamesGenerator({
        dictionaries: [adjectives, colors, animals],
        separator: " ",
        length: 2,
      });
      this.forms.ivr.name=event?.params?.suggested_name ?? `${randomName} IVR`
    },
    onBeforeClose(){
      this.forms.ivr.reset()
      this.api.add_ivr.reset()
    },
    addIVR() {
      let vm = this
      vm.forms.ivr.submitted = true;
      vm.$v.forms.ivr.$touch();
      if (vm.$v.forms.ivr.$invalid || vm.api.add_ivr.send) return;
      vm.api.add_ivr.send = true;
      vm.api.add_ivr.validation_errors = {};
      vm.api.add_ivr.error_message = '';
      VOIP_API.endpoints.menu.create({
        name: vm.forms.ivr.name,
      })
      .then(() => {
        vm.$modal.hide(vm.modalName)
        vm.$emit('added');
      })
      .catch(ex => {
        vm.api.add_ivr.validation_errors = ex.own_errors;
        vm.api.add_ivr.error_message = ex.own_message;
      })
      .finally(()=>{
        vm.forms.ivr.submitted = false;
        vm.api.add_ivr.send = false;
      });
    },
  },
};
</script>
