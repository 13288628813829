var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup developerOptionsModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Features")]), _c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticStyle: {
      "max-height": "calc(100vh - 120px)",
      "overflow-y": "auto",
      "height": "calc(100vh - 120px)"
    }
  }, [_c('div', {
    staticClass: "dialer-login-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 w-100"
  }, [_c('div', {
    staticClass: "wrap w-100"
  }, [_c('div', {
    staticClass: "signupLoginV2-section-2-right w-100 pr-3"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('b-check', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.logger.general,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.logger, "general", $$v);
      },
      expression: "$store.state.logger.general"
    }
  }, [_vm._v("general")]), _c('b-check', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.logger.api,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.logger, "api", $$v);
      },
      expression: "$store.state.logger.api"
    }
  }, [_vm._v("api")]), _c('b-check', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.logger.ami,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.logger, "ami", $$v);
      },
      expression: "$store.state.logger.ami"
    }
  }, [_vm._v("ami")]), _c('b-check', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.logger.mattermost_socket,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.logger, "mattermost_socket", $$v);
      },
      expression: "$store.state.logger.mattermost_socket"
    }
  }, [_vm._v("mattermost_socket")]), _c('b-check', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.logger.mattermost_api,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.logger, "mattermost_api", $$v);
      },
      expression: "$store.state.logger.mattermost_api"
    }
  }, [_vm._v("mattermost_api")]), _c('b-check', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.logger.sip,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.logger, "sip", $$v);
      },
      expression: "$store.state.logger.sip"
    }
  }, [_vm._v("sip")]), _c('b-check', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.logger.crm,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.logger, "crm", $$v);
      },
      expression: "$store.state.logger.crm"
    }
  }, [_vm._v("crm")]), _c('b-check', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.logger.integration,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.logger, "integration", $$v);
      },
      expression: "$store.state.logger.integration"
    }
  }, [_vm._v("integration")]), _c('b-check', {
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.logger.media_device_settings,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.logger, "media_device_settings", $$v);
      },
      expression: "$store.state.logger.media_device_settings"
    }
  }, [_vm._v("media_device_settings")]), _c('b-button', {
    on: {
      "click": function ($event) {
        return _vm.clearLogs();
      }
    }
  }, [_vm._v("Clear Logs")])], 1)])])])])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }