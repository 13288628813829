var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500",
    attrs: {
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.selected.screen == _vm.screens.new_email ? [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Change email")])]), _c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Current email address set on the account")]), _c('div', {
    staticClass: "currentEmailText"
  }, [_vm._v(_vm._s(_vm.userEmail))])]), _c('b-alert', {
    attrs: {
      "show": !!_vm.api.add_new_email.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_new_email.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-20px"
  }, [_c('label', [_vm._v("Enter the new email address to be set on this account")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_new_email.email,
      expression: "forms.add_new_email.email"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.add_new_email.send
    },
    domProps: {
      "value": _vm.forms.add_new_email.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_new_email, "email", $event.target.value);
      }
    }
  }), _vm.forms.add_new_email.submitted && _vm.$v.forms.add_new_email.email.$invalid || _vm.api.add_new_email.validation_errors.email ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_new_email.email.required ? _c('span', [_vm._v("Email is required")]) : !_vm.$v.forms.add_new_email.email.email ? _c('span', [_vm._v("Email should be valid email")]) : _vm._e(), _vm._l(_vm.api.add_new_email.validation_errors.email, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()])], 1), _c('div', {
    staticClass: "w-100 mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "button",
      "disabled": _vm.api.add_new_email.send
    },
    on: {
      "click": function ($event) {
        return _vm.addNewEmail();
      }
    }
  }, [_c('span', [_vm._v("Update")])])])])] : _vm._e(), _vm.selected.screen == _vm.screens.both_email_confirm_pincode ? [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-start justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column pr-3"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Verify your email")]), _c('p', [_vm._v(" A pin has been sent to your new email please enter the pin below to verify your email. ")])]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.verify_both_email.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.verify_both_email.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-20px"
  }, [_c('p', [_vm._v(_vm._s(_vm._f("get_property")(_vm.voipuser, 'user.email')))]), _c('label', [_vm._v("Enter the 6-digit code:")]), _c('vb-pin-code', {
    attrs: {
      "disabled": _vm.api.verify_both_email.send
    },
    on: {
      "change": function ($event) {
        _vm.forms.verify_both_email.prev_pincode = $event;
      }
    }
  }), _vm.forms.verify_both_email.submitted && _vm.$v.forms.verify_both_email.prev_pincode.$invalid || _vm.api.verify_both_email.validation_errors.cpincode ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.verify_both_email.prev_pincode.required ? _c('span', [_vm._v("Pincode is required")]) : !_vm.$v.forms.verify_both_email.prev_pincode.valid ? _c('span', [_vm._v("Pincode should be 6 digits")]) : _vm._e(), _vm._l(_vm.api.verify_both_email.validation_errors.cpincode, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-20px"
  }, [_c('p', [_vm._v(_vm._s(_vm.forms.add_new_email.email))]), _c('label', [_vm._v("Enter the 6-digit code:")]), _c('vb-pin-code', {
    attrs: {
      "disabled": _vm.api.verify_both_email.send
    },
    on: {
      "change": function ($event) {
        _vm.forms.verify_both_email.pincode = $event;
      }
    }
  }), _vm.forms.verify_both_email.submitted && _vm.$v.forms.verify_both_email.pincode.$invalid || _vm.api.verify_both_email.validation_errors.npincode ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.verify_both_email.pincode.required ? _c('span', [_vm._v("Pincode is required")]) : !_vm.$v.forms.verify_both_email.pincode.valid ? _c('span', [_vm._v("Pincode should be 6 digits")]) : _vm._e(), _vm._l(_vm.api.verify_both_email.validation_errors.npincode, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1)], 1), _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "button",
      "disabled": _vm.api.verify_both_email.send
    },
    on: {
      "click": function ($event) {
        return _vm.verifyBothEmail();
      }
    }
  }, [_c('span', [_vm._v("Save")])])])])] : _vm._e(), _vm.selected.screen == _vm.screens.congratulation ? [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "thumbCongrtulations-container"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/thumbCongrtulations.svg')
    }
  }), _c('h6', [_vm._v("Congratulations!")]), _c('p', [_vm._v(" Email change process has been completed Stay connected, stay productive! ")])])])] : _vm._e()], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }