import { VOIP_API } from "../index"

export const TASK_STATUS_API_ENDPOINTS = {
  create(data={}){
    return VOIP_API.axios.voip.request({
      url: "tasklist-status-create",
      method: "POST",
      data,
    })
  },
  list(params={},token){
    return VOIP_API.axios.voip.request({
      url: "tasklist-status-get",
      method: "GET",
      params,
      cancelToken: token,
    })
  },
  delete(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `tasklist-status-delete/${id}`,
      method: "DELETE",
      data,
    })
  },
  find(id='',params={}){
    return VOIP_API.axios.voip.request({
      url: `tasklist-status/${id}`,
      method: "GET",
      params,
    })
  },
  update(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `tasklist-status-update/${id}`,
      method: "POST",
      data,
    })
  },
}