<template>
  <!-- <modal :class="`dialer_animation dialer-notificationPermission-popup latestPermissionsPopupDesign ${permissions.microphone == 'denied' || permissions.camera == 'denied' || permissions.notification == 'denied'?'permissionIsDenied':''}`" height="auto" name="NotificationPermissionsModal" :clickToClose="false" @before-open="beforeOpen" @closed="onClosed">
    <div class="dialer-notificationPermission-popupBox">
      <button class="position-absolute top-10 right-10 cancel-button-modal bg-transparent" @click="$modal.hide('NotificationPermissionsModal')">
        <b-icon icon="x" scale="1.5"></b-icon>
      </button>
      <template v-if="selected == 'microphone'">
        <template v-if="permissions.microphone == 'denied'">
          <div class="permissionImage-container denied">
            <img class="permissionImage" :src="assets[selected].logo" :alt="assets[selected].name"/>
          </div>
          <div class="permissionHeading">
            Allow Microphone Access
          </div>
          <div class="permissionText">
            VoIP Business needs access to your device's microphone to 
            enable voice calls and voice messaging. Granting this permission 
            ensures clear and reliable audio communication.
          </div>
          <div class="permissionsButton" @click="getPermission(selected)">
            Enable Microphone
          </div>
        </template>
        <template v-else-if="permissions.microphone == 'granted'">
          <div class="permissionImage-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" class="addMultipleAnimationsOnCheck" height="120" viewBox="0 0 230 230">
              <g id="Group_50173" data-name="Group 50173" transform="translate(-3439 -22041)">
                <g id="Ellipse_5607" data-name="Ellipse 5607" transform="translate(3439 22041)" fill="none" stroke="#139859" stroke-width="10">
                  <circle cx="115" cy="115" r="115" stroke="none"/>
                  <circle cx="115" cy="115" r="110" fill="none"/>
                </g>
                <circle id="Ellipse_5608" data-name="Ellipse 5608" cx="106" cy="106" r="106" transform="translate(3448 22050)" fill="#139859"/>
                <path id="Icon_feather-check" data-name="Icon feather-check" d="M107.7,9,37.783,78.922,6,47.139" transform="translate(3497.5 22112.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="20"/>
              </g>
            </svg>
          </div>
          <div class="permissionHeading">
            Microphone Access Granted
          </div>
          <div class="permissionText">
            Microphone access granted! You're now ready for 
            high-quality audio calls.
          </div>
          <div class="permissionsButton" @click="onClick()">
            {{permissions.microphone== 'granted' && permissions.notification == 'granted' && permissions.camera == 'granted' ? 'Finish' : 'Continue'}}
          </div>
        </template>
        <template v-else>
          <div class="permissionImage-container">
            <img class="permissionImage" :src="assets[selected].logo" :alt="assets[selected].name"/>
          </div>
          <div class="permissionHeading">
            Allow Microphone Access
          </div>
          <div class="permissionText">
            VoIP Business needs access to your device's microphone to 
            enable voice calls and voice messaging. Granting this permission 
            ensures clear and reliable audio communication.
          </div>
          <div class="permissionsButton" @click="getPermission(selected)">
            Enable Microphone
          </div>
        </template>
      </template>
      <template v-if="selected == 'camera'">
        <template v-if="permissions.camera == 'denied'">
          <div class="permissionImage-container denied">
            <img class="permissionImage" :src="assets[selected].logo" :alt="assets[selected].name"/>
          </div>
          <div class="permissionHeading">
            Enable Video Conversations
          </div>
          <div class="permissionText">
            Give VoIP Business access to your device's camera for video calls 
            and video conferencing. This permission is essential for engaging 
            in face-to-face conversations.
          </div>
          <div class="permissionsButton" @click="getPermission(selected)">
            Enable Camera
          </div>
        </template>
        <template v-else-if="permissions.camera == 'granted'">
          <div class="permissionImage-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" class="addMultipleAnimationsOnCheck" height="120" viewBox="0 0 230 230">
              <g id="Group_50173" data-name="Group 50173" transform="translate(-3439 -22041)">
                <g id="Ellipse_5607" data-name="Ellipse 5607" transform="translate(3439 22041)" fill="none" stroke="#139859" stroke-width="10">
                  <circle cx="115" cy="115" r="115" stroke="none"/>
                  <circle cx="115" cy="115" r="110" fill="none"/>
                </g>
                <circle id="Ellipse_5608" data-name="Ellipse 5608" cx="106" cy="106" r="106" transform="translate(3448 22050)" fill="#139859"/>
                <path id="Icon_feather-check" data-name="Icon feather-check" d="M107.7,9,37.783,78.922,6,47.139" transform="translate(3497.5 22112.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="20"/>
              </g>
            </svg>
          </div>
          <div class="permissionHeading">
            Camera Access Granted
          </div>
          <div class="permissionText">
            Camera access granted! You're now ready for video 
            calls and conferencing.
          </div>
          <div class="permissionsButton" @click="onClick()">
            Continue
          </div>
        </template>
        <template v-else>
          <div class="permissionImage-container">
            <img class="permissionImage" :src="assets[selected].logo" :alt="assets[selected].name"/>
          </div>
          <div class="permissionHeading">
            Enable Video Conversations
          </div>
          <div class="permissionText">
            Give VoIP Business access to your device's camera for video calls 
            and video conferencing. This permission is essential for engaging 
            in face-to-face conversations.
          </div>
          <div class="permissionsButton" @click="getPermission(selected)">
            Enable Camera
          </div>
        </template>
      </template>
      <template v-if="selected == 'notification'">
        <template v-if="permissions.notification == 'denied'">
          <div class="permissionImage-container denied">
            <img class="permissionImage" :src="assets[selected].logo" :alt="assets[selected].name"/>
          </div>
          <div class="permissionHeading">
            Stay Connected Seamlessly
          </div>
          <div class="permissionText">
            Keep in touch effortlessly. Allow VoIP Business to send 
            notifications for incoming calls and messages.
          </div>
          <div class="permissionsButton" @click="getPermission(selected)">
            Enable Notification
          </div>
          <div @click="$modal.hide('NotificationPermissionsModal')" class="permissionText w-fit-content cursor_pointer_no_hover">
            <b>Not Now</b>
          </div>
        </template>
        <template v-else-if="permissions.notification == 'granted'">
          <div class="permissionImage-container">
            <svg xmlns="http://www.w3.org/2000/svg" width="120" class="addMultipleAnimationsOnCheck" height="120" viewBox="0 0 230 230">
              <g id="Group_50173" data-name="Group 50173" transform="translate(-3439 -22041)">
                <g id="Ellipse_5607" data-name="Ellipse 5607" transform="translate(3439 22041)" fill="none" stroke="#139859" stroke-width="10">
                  <circle cx="115" cy="115" r="115" stroke="none"/>
                  <circle cx="115" cy="115" r="110" fill="none"/>
                </g>
                <circle id="Ellipse_5608" data-name="Ellipse 5608" cx="106" cy="106" r="106" transform="translate(3448 22050)" fill="#139859"/>
                <path id="Icon_feather-check" data-name="Icon feather-check" d="M107.7,9,37.783,78.922,6,47.139" transform="translate(3497.5 22112.5)" fill="none" stroke="#fff" stroke-linecap="round" stroke-linejoin="round" stroke-width="20"/>
              </g>
            </svg>
          </div>
          <div class="permissionHeading">
            Notification Access Granted
          </div>
          <div class="permissionText">
            Great news! You've successfully enabled notification access. Get 
            ready to stay informed with instant updates on messages, calls, 
            and important events.
          </div>
          <div class="permissionsButton" @click="onClick()">
            Finish
          </div>
        </template>
        <template v-else>
          <div class="permissionImage-container">
            <img class="permissionImage" :src="assets[selected].logo" :alt="assets[selected].name"/>
          </div>
          <div class="permissionHeading">
            Stay Connected Seamlessly
          </div>
          <div class="permissionText">
            Keep in touch effortlessly. Allow VoIP Business to send 
            notifications for incoming calls and messages.
          </div>
          <div class="permissionsButton" @click="getPermission(selected)">
            Enable Notification
          </div>
          <div @click="$modal.hide('NotificationPermissionsModal')" class="permissionText w-fit-content cursor_pointer_no_hover">
            <b>Not Now</b>
          </div>
        </template>
      </template>
    </div>
  </modal> -->
  <modal class="newPermissionsModal" height="auto" name="NotificationPermissionsModal" :clickToClose="false" @before-open="beforeOpen" @closed="onClosed">
    <div class="newPermissionsModal-innerContainer">
      <button class="close-nothing-button" @click="$modal.hide('NotificationPermissionsModal')">
        <b-icon icon="x" scale="1.5"></b-icon>
      </button>

      <template v-if="selected == 'microphone'">
        <div class="permission-icon-container">
          <vb-icon icon="permission-microphone-icon" width="71.287px" height="125.075px"/>
        </div>
        <template v-if="permissions.microphone == 'denied'">
          <div class="permission-heading">
            Allow microphone access
          </div>
          <div class="permission-text">
            VoIP Business needs access to your device's microphone to 
            enable voice calls and voice messaging. Granting this permission 
            ensures clear and reliable audio communication.
          </div>
          <div class="w-100 mt-40px">
            <button class="fullWidthBlueButton" @click="getPermission(selected)">
              Enable Microphone
            </button>
          </div>
        </template>
        <template v-else-if="permissions.microphone == 'granted'">
          <div class="permission-heading">
            Allow microphone access
          </div>
          <div class="permission-text">
            VoIP Business needs access to your device's microphone to 
            enable voice calls and voice messaging. Granting this permission 
            ensures clear and reliable audio communication.
          </div>
          <div class="w-100 mt-40px">
            <button class="fullWidthBlueButton" @click="onClick()">
              {{permissions.microphone== 'granted' && permissions.notification == 'granted' && permissions.camera == 'granted' ? 'Finish' : 'Continue'}}
            </button>
          </div>
        </template>
        <template v-else>
          <div class="permission-heading">
            Allow microphone access
          </div>
          <div class="permission-text">
            VoIP Business needs access to your device's microphone to 
            enable voice calls and voice messaging. Granting this permission 
            ensures clear and reliable audio communication.
          </div>
          <div class="w-100 mt-40px">
            <button class="fullWidthBlueButton" @click="getPermission(selected)">
              Enable Microphone
            </button>
          </div>
        </template>
      </template>
      <template v-if="selected == 'camera'">
        <div class="permission-icon-container">
          <vb-icon icon="permission-camera-icon" width="150.377px" height="93.317px"/>
        </div>
        <template v-if="permissions.camera == 'denied'">
          <div class="permission-heading">
            Enable video conversations
          </div>
          <div class="permission-text">
            Give VoIP Business access to your device's camera for video calls 
            and video conferencing. This permission is essential for engaging 
            in face-to-face conversations.
          </div>
          <div class="w-100 mt-40px">
            <button class="fullWidthBlueButton" @click="getPermission(selected)">
              Enable camera
            </button>
          </div>
        </template>
        <template v-else-if="permissions.camera == 'granted'">
          <div class="permission-heading">
            Enable video conversations
          </div>
          <div class="permission-text">
            Give VoIP Business access to your device's camera for video calls 
            and video conferencing. This permission is essential for engaging 
            in face-to-face conversations.
          </div>
          <div class="w-100 mt-40px">
            <button class="fullWidthBlueButton" @click="onClick()">
              {{permissions.microphone== 'granted' && permissions.notification == 'granted' && permissions.camera == 'granted' ? 'Finish' : 'Continue'}}
            </button>
          </div>
        </template>
        <template v-else>
          <div class="permission-heading">
            Enable video conversations
          </div>
          <div class="permission-text">
            Give VoIP Business access to your device's camera for video calls 
            and video conferencing. This permission is essential for engaging 
            in face-to-face conversations.
          </div>
          <div class="w-100 mt-40px">
            <button class="fullWidthBlueButton" @click="getPermission(selected)">
              Enable camera
            </button>
          </div>
        </template>
      </template>
      <template v-if="selected == 'notification'">
        <div class="permission-icon-container">
          <vb-icon icon="permission-notification-icon" width="116.987px" height="124.012px"/>
        </div>
        <template v-if="permissions.notification == 'denied'">
          <div class="permission-heading">
            Stay connected seamlessly
          </div>
          <div class="permission-text">
            Keep in touch effortlessly. Allow VoIP Business to send notifications for incoming calls and messages.
          </div>
          <div class="w-100 mt-40px">
            <button class="fullWidthBlueButton" @click="getPermission(selected)">
              Enable notification
            </button>
          </div>
          <div class="d-flex justify-content-center w-100">
            <div @click="$modal.hide('NotificationPermissionsModal')" class="permission-notNowText">
              Not Now
            </div>
          </div>
        </template>
        <template v-else-if="permissions.notification == 'granted'">
          <div class="permission-heading">
            Stay connected seamlessly
          </div>
          <div class="permission-text">
            Keep in touch effortlessly. Allow VoIP Business to send notifications for incoming calls and messages.
          </div>
          <div class="w-100 mt-40px">
            <button class="fullWidthBlueButton" @click="onClick()">
              {{permissions.microphone== 'granted' && permissions.notification == 'granted' && permissions.camera == 'granted' ? 'Finish' : 'Continue'}}
            </button>
          </div>
        </template>
        <template v-else>
          <div class="permission-heading">
            Stay connected seamlessly
          </div>
          <div class="permission-text">
            Keep in touch effortlessly. Allow VoIP Business to send notifications for incoming calls and messages.
          </div>
          <div class="w-100 mt-40px">
            <button class="fullWidthBlueButton" @click="getPermission(selected)">
              Enable notification
            </button>
          </div>
          <div class="d-flex justify-content-center w-100">
            <div @click="$modal.hide('NotificationPermissionsModal')" class="permission-notNowText">
              Not Now
            </div>
          </div>
        </template>
      </template>
    </div>
  </modal>
</template>
<script>
import { 
  permissions, 
} from "../../utils";
import { mapGetters } from 'vuex';
export default {
  name: "NotificationPermissionsModal",
  data() {
    return {
      assets: {
        brand: {
          brandLogo: require("@/assets/images/vb-logo.svg"),
          brandName: "VOIP",
        },
        camera: {
          // logo: require("@/assets/images/notification-permission-modal/camera.svg"),
          logo: require("@/assets/images/notification-permission-modal/camera.png"),
          name: "Camera",
        },
        microphone: {
          // logo: require("@/assets/images/notification-permission-modal/microphone.svg"),
          logo: require("@/assets/images/notification-permission-modal/microphone.png"),
          name: "Microphone",
        },
        notification: {
          // logo: require("@/assets/images/notification-permission-modal/notification.svg"),
          logo: require("@/assets/images/notification-permission-modal/notification.png"),
          name: "Notifications",
        },
      },
      permissions: {
        camera: "",
        microphone: "",
        notification: "",
      },
      ask_permission: false,
      error_message: '',
      selected: "microphone",
      
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    isAllGranted() { return (this.permissions.microphone === "granted" && this.permissions.notification === "granted" && this.permissions.camera === "granted"); },
  },
  watch: {
    "permissions.microphone"(microphone_permission) {
      if (microphone_permission !== "granted") {
        this.$modal.show("NotificationPermissionsModal");
        this.selected='microphone'
        this.getPermission('microphone')
      }
    },
  },
  methods: {
    beforeOpen() {
      this.$store.state.global_conditions.blur_bg = true;
    },
    onClosed() {
      this.$store.state.global_conditions.blur_bg = false;
    },
    onClick(){
      if(this.isAllGranted){
        this.$modal.hide("NotificationPermissionsModal");
      } else if (this.selected=='microphone'){
        this.selected='camera'
        this.getPermission('camera')
      } else if (this.selected=='camera'){
        this.selected='notification'
        this.getPermission('notification')
      }
    },
    async getPermission(permission){
      if(this.ask_permission) return;
      try {
        this.ask_permission=true
        this.error_message=''
        if (permission=='microphone'){
          await permissions.getMicPermission()
        } else if (permission=='camera'){
          await permissions.getCameraPermission()
        } else if (permission=='notification'){
          await permissions.getNotificationPermission()
        }
      } catch(ex) {
        this.error_message=ex.message
      } finally {
        this.ask_permission=false
      }
    },
    // events
    onCameraChange(event){
      this.permissions.camera=event
    },
    onMicrophoneChange(event){
      this.permissions.microphone=event
    },
    onNotificationChange(event){
      this.permissions.notification=event
    },
  },
  mounted() {
    this.permissions.camera=permissions.camera
    this.permissions.notification=permissions.notification
    this.permissions.microphone=permissions.mic
    permissions.oncamera=this.onCameraChange
    permissions.onmic=this.onMicrophoneChange
    permissions.onnotification=this.onNotificationChange
  },
};
</script>

<style lang="scss" scoped>
.cancel-button-modal{
  padding:5px 8px;
  border-radius: 50%;
  border:0px;
  background-color: $primaryLight;
}
.active {
  background-color: $primary !important;
}
</style>
<style lang="scss">
.dialer-notificationPermission-popup {
  &.vm--container {
    .vm--overlay {
      background-color: rgba(0, 0, 0, 0.3);
    }
    .vm--modal {
      padding: 0px;
      min-height: 432px;
      @include border-radius(24px);
      left: 50% !important;
      top: 50% !important;
      transform: translate(-50%, -50%);
      max-width: 500px;
      min-width: 500px;
    }
  }
  .dialer-notificationPermission-popupBox {
    min-height: inherit;
    position: relative;
    padding: $dialer-default-padding 32px 72px;
    @include box-shadow-mixin(0 5px 15px rgba(0, 0, 0, 0.5));
    display: flex;
    flex-direction: column;
    align-items: center;
    .productLogo {
      margin-bottom: $dialer-default-padding;
    }
    .welcomeBox {
      span {
        color: $dialer-inner-rightSide-headingColor;
        font-family: $font-family-base;
        font-size: 24px;
        font-weight: 700;
        margin-bottom: $dialer-default-padding;
      }
    }
    .dialer-notificationPermissionInfo {
      p {
        font-size: $dialer-default-fontSize;
        color: $dialer-inner-rightSide-headingColor;
        text-align: center;
      }
      &.error {
        p {
          padding: 8px 0px;
          color: $red;
        }
      }
    }
    .dialer-notificationPermission-products {
      position: relative;
      display: flex;
      align-items: flex-start;
      justify-content: space-between;
      .permissionProduct {
        min-width: 127px;
        user-select: none;
        cursor: pointer;
        position: relative;
        background: no-repeat 50% 24px/30px;
        display: inline-flex;
        align-items: center;
        flex-direction: column;
        margin: 24px
          8px $dialer-default-padding;
        border-radius: 20px;
        border: 4px solid transparent;
        padding: $dialer-default-padding;
        font-weight: 600;
        .productLogo {
          width: 30px;
          height: 40px;
        }
        .productName {
          font-size: $dialer-default-fontSize;
          line-height: $dialer-default-fontSize;
          color: $dialerSetting-dialerBox-headingColor;
          font-family: $font-family-base;
          font-weight: 700;
          margin-bottom: 8px;
        }
        &.activeProduct {
          border-color: $primary;
          &:after {
            position: absolute;
            content: "";
            background: $primary no-repeat 50%/16px;
            background-image: url(https://app.ringover.com/img/icon/tick2.svg);
            bottom: -26px;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 25px;
            height: 25px;
          }
        }
        &.denied {
          border-color: #c12424;
          &:after {
            position: absolute;
            content: "";
            background: #c12424 no-repeat 50%/16px;
            // background-image: url(https://app.ringover.com/img/icon/tick2.svg);
            background-image: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='white' class='bi bi-x-lg' viewBox='0 0 16 16'%3E%3Cpath d='M2.146 2.854a.5.5 0 1 1 .708-.708L8 7.293l5.146-5.147a.5.5 0 0 1 .708.708L8.707 8l5.147 5.146a.5.5 0 0 1-.708.708L8 8.707l-5.146 5.147a.5.5 0 0 1-.708-.708L7.293 8 2.146 2.854Z'/%3E%3C/svg%3E");
            bottom: -26px;
            left: 50%;
            transform: translate(-50%, -50%);
            border-radius: 50%;
            width: 25px;
            height: 25px;
          }
        }
      }
    }
    .dialer-microphone-volumeSetting {
      margin: 0px 0px $dialer-default-padding;
      width: 100%;
      display: flex;
      justify-content: space-between;
      width: 315px;
      .volumeControl-icon {
        display: block;
        height: 24px;
        width: 10px;
        @include border-radius(5px);
        background-color: $dialer-soundSettingDevice-microphone-volumeSetting-Color;
        transition: 0.1s;
        &-1 {
          background-color: #005dff;
        }
        &-2 {
          background-color: #1a6eff;
        }
        &-3 {
          background-color: #2d7aff;
        }
        &-4 {
          background-color: #4387ff;
        }
        &-5 {
          background-color: #5794ff;
        }
        &-6 {
          background-color: #5794ff;
        }
        &-7 {
          background-color: #5794ff;
        }
        &-8 {
          background-color: #5794ff;
        }
        &-9 {
          background-color: #5794ff;
        }
        &-10 {
          background-color: #659dff;
        }
        &-11 {
          background-color: #71a5ff;
        }
        &-12 {
          background-color: #79a9fe;
        }
        &-13 {
          background-color: #7babff;
        }
        &-14 {
          background-color: #8bb5ff;
        }
        &-15 {
          background-color: #8bb5ff;
        }
        &-16 {
          background-color: #8bb7ff;
        }
        &-17 {
          background-color: #8bb9ff;
        }
        &-18 {
          background-color: #9bb5ff;
        }
      }
    }
    .dialer-button {
      margin: $dialer-default-padding 0px;
      padding: 8px 24px;
      text-transform: capitalize;
    }
    .dialer-notificationPermissionStart {
      text-transform: uppercase;
      margin-top: 0;
      transition: 0.2s;
      font-size: $dialer-default-fontSize;
      font-weight: 600;
      position: absolute;
      left: 0;
      right: 0;
      bottom: 0;
      height: 72px;
      line-height: 72px;
      text-align: center;
      color: $body-color;
      background-color: $primaryLight;
      cursor: pointer;
      &-active {
        color: $white;
        background-color: $primary;
      }
    }
  }
}
  // .addMultipleAnimationsOnCheck{
  //   &.active{
  //     background-color: transparent !important;
  //     .emptyCircle{
  //       stroke-dasharray: 795;
  //       stroke-dashoffset: 795;
  //       animation: dash 3s linear forwards;
  //       animation-iteration-count: infinite;
  //       @keyframes dash {
  //         from{
  //           stroke-dashoffset: 795;
  //         }
  //         to {
  //           stroke-dashoffset: 0;
  //         }
  //       }
  //     }
  //     .fullCircle{
  //       animation: forFill 1s linear forwards;
  //       animation-delay: 3s;
  //       animation-iteration-count: infinite;
  //       @keyframes forFill {
  //         from{
  //           fill: transparent;
  //         }
  //         to {
  //           fill: #139859;
  //         }
  //       }
  //     }
  //     .fullTick{
  //       animation: forTick 3s linear forwards;
  //       animation-delay: 4s;
  //       stroke-dasharray: 1050;
  //       stroke-dashoffset: 1050;
  //       animation-iteration-count: infinite;
  //       @keyframes forTick {
  //         from{
  //           stroke-dashoffset: 1050;
  //         }
  //         to {
  //           stroke-dashoffset: 0;
  //         }
  //       } 
  //     }
  //   }
  // }
</style>