<template>
  <transition name="slide-fade">
    <div id="dialpadPanel" class="position-relative panel-wide d-flex flex-column applyLeftPadding">
      <span :class="`main position-relative h-100`">
        <div 
          class="dial-pad-wrapper v2 justify-content-between flex-column d-flex h-100"
          :class="anyCall ? 'pt-0' : 'bg-white justify-content-between py-4'" 
          style="border-radius: 24px;"
        >
          <!-- v-if="conditions.show_enter_number_section" -->
          <!--<div class="w-100 d-flex justify-content-start dialer-section-1" :style="`height:calc(100vh - ${mobileNumPad_height}px - 52px)`">
            <div 
              v-if="!getIsMobile" 
              app-tour-step="0" 
              class="chat-info w-100"
              :class="[
                {
                  'p-3 bg-white': getProperty($tours, `app-tour.data.currentStep`) == 0,
                },
                !isEmpty(sipsessions) || $store.state.calls.videoCalls.length >= 1 ? '' : 'py-0'
              ]"  
            >
              <div class="d-flex flex-column position-relative">
                <div app-tour-web-dashboard-dialer-input class="d-flex align-items-center justify-content-between minHeight-50px">
                  <div class="CountryPlusFlag-onTopOfDialer">
                    <!~~ <img :src="FindFlag" /> ~~>
                    <!~~ <label>{{ $store.state.sip.number | getFullCountryName || getCurrentUser.country }}</label> ~~>
                    <label class="cursor_pointer_no_hover" @click="$modal.show(`${_uid}-callSMSpricing`,{
                      number: $store.state.sip.number,
                    })">{{ country }}</label>
                    <label v-if="isIntegration">{{ $store.state.integration_platform || 'test' }}</label>
                  </div>
                  <div class="flex-grow-1 position-relative">
                    <input 
                      ref="number-input"
                      placeholder="Enter number..." 
                      type="tel" 
                      class="d-block w-100 border-0" 
                      @focus="conditions.number_input_focused = true" 
                      v-model="$store.state.sip.number" 
                      @input="selected.contact_to_dial.reset()"
                      @keyup.enter="dial()" 
                    />

                    <div v-show="conditions.number_input_focused && !isEmpty(searchNumber)" class="dialer-dropdown position-absolute top-35 dialer-fetchData-drop-down">
                      <div class="dropdown">
                        <ul class="dropdown-menu show">
                          <!~~ <li v-for="(data, index) in searchNumber" :key="data.id || index" >
                            <a>
                              <Info
                                :is_blf="false"
                                :id="data.id" 
                                :name="data.name" 
                                :sub_info="data.number" 
                                :image="data.image" 
                              />
                            </a>
                          </li> ~~>
                          <li v-for="(data, index) in searchNumber" :key="data.id || index" :id="data.id" @click="selectContact(data)" class="mb-8px">
                            <Info  
                              :is_blf="false"
                              :name="data.name" 
                              :sub_info="data.number" 
                              :image="data.image" 
                            /> 
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="flex-shrink-1 dashboard-call-cancel-icons">
                    <ul class="list-unstyled mb-0 icon-list">
                      <li v-if="!isIntegration && numberInfo.valid && numberInfo.is_mobile" class="d-inline-block px-1">
                        <!~~ api.checksmsbalance.status==2 ? '' :  ~~>
                        <!~~ :style="`${api.checksmsbalance.status==2 ? 'opacity:0.4' : ''}`" ~~>
                        <a @click="$modal.show('SendSmsModal',{ number: $store.state.sip.number })">
                          <vb-icon icon="mainDialer-SMS-icon" class="mainDialer-SMS-icon"  />
                        </a>
                      </li>
                      <li v-if="$store.state.sip.number" class="d-inline-block px-1">
                        <a @click="$store.state.sip.number = '';conditions.number_input_focused = false;selected.contact_to_dial.reset();">
                          <vb-svg 
                            name="dialer-close-icon" 
                            width="50" 
                            height="50" 
                            viewBox="0 0 50 50" 
                            stroke-width="0" 
                            stroke="#005DFF" 
                            fill="none" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="dialerColse_icon" 
                          />
                        </a>
                      </li>
                      <li v-if="(numberInfo.valid || selected.contact_to_dial.id) && !getIsMobile" class="d-inline-block px-1 ">
                        <a @click="dial()">
                          <vb-svg 
                            name="dialer-call-icon" 
                            width="50" 
                            height="50" 
                            viewBox="0 0 50 50" 
                            stroke-width="0" 
                            stroke="#005DFF" 
                            fill="none" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="dialerCall_icon" 
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div app-tour-web-dashboard-dialer-select-cid class="dialer-dropdown dialer-selectNumber-dropdown with-copyCallerIDbutton w-100 mt-4">
                  <!~~ <button v-show="false" ref="tets" @click="copyCallerId()" type="button"></button> ~~>
                  <b-dropdown :text="$store.state.sip.caller_id ? $store.state.sip.caller_id : 'Select a Number'" id="set-design-dd" class="m-2 w-100">
                    <template #button-content>
                      <vb-svg 
                        name="selectPhone-icon" 
                        width="15.504" 
                        height="30.214" 
                        viewBox="0 0 15.504 30.214" 
                        stroke-width="0" 
                        stroke="#005DFF" 
                        fill="none" 
                        stroke-linecap="round" 
                        stroke-linejoin="round" 
                        class="selectPhone_icon" 
                      />
                      <p class="caller_id_label">{{selectedCallerId | get_property('number_labels.name') }}</p>
                      <span class="d-flex align-items-center">
                        <!~~ <span class="mr-3 text-center">{{ selectedCallerId | get_property('caller_id','Please Select a Caller ID') | number_formater }}</span> ~~>
                        <span class="mr-3 text-center">{{ selectedCallerId | get_property('caller_id',api.caller_ids.send ? 'Your Numbers' : 'No outgoing number assigned') | number_formater }}</span>
                        <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                      </span>
                      <!~~ <b-icon @click.stop="$refs.tets.click()" icon="files" /> ~~>
                    </template>
                    
                    <template v-if="numberOptions.length == 0">
                      <b-dropdown-item class="border-bottom">
                        No telephone numbers to list.
                      </b-dropdown-item>
                      <b-dropdown-item @click="showAddNumberInfoToaster()">
                        <span class="latestBlueColor text-decoration-underline">
                          Click here to get a telephone number
                        </span>
                      </b-dropdown-item>
                    </template>
                    <template v-else>
                      <h6 class="selectNumber-heading mb-0">Your number</h6>
                      <!~~ <b-form-radio-group size="lg"
                        class="radio_btn_like_checkbox" 
                        :checked="$store.state.sip.caller_id" 
                        @change="updateCallerId($event)" 
                        :disabled="api.update_caller_id.send" 
                        :options="numberOptions" 
                      /> ~~>
                      <b-form-radio-group
                        class="radio_btn_like_checkbox" 
                        @change="updateCallerId($event)" 
                        :disabled="api.update_caller_id.send"
                        :checked="$store.state.sip.caller_id"
                      >
                        <b-form-radio v-for="data in numberOptions" :key="data.id" :value="data.value" >
                          <div class="d-flex align-items-center">
                            {{data.text}}
                            <span v-if="data.label" class="OwnertaggedVersion mb-0 ml-16px">{{data.label}}</span>
                          </div>
                        </b-form-radio>
                      </b-form-radio-group>
                    </template>
                  </b-dropdown>
                  <div class="copyCallerIDbutton">
                    <b-icon @click="copyCallerId()" icon="files" />
                  </div>
                </div>

                <!~~ <template v-if="!isIntegration">
                  <transition name="slide">
                    <div v-show="numberInfo.valid && numberInfo.is_mobile" class="dialer-sms-area dialer-sms-area-updated position-relative">
                      <form @submit.prevent="sendsms()">
                        <div class="dialer-sms active">
                          <textarea
                            @input="
                              $event.target.rows = ($event.target.value.match(/(\r|\n)/g) || []).length + 1;
                              $event.target.rows = $event.target.scrollHeight > $event.target.offsetHeight ? Math.ceil($event.target.scrollHeight / 24) : $event.target.rows;
                            "
                            :disabled="api.send_sms.send"
                            v-model="forms.sms.sms"
                            onfocus="this.classList.add('focused')"
                            onblur="this.classList.remove('focused')"
                            @focus="showSMSToaster()"
                            placeholder="Enter your SMS message"
                            rows="1"
                            maxlength="160"
                            class="focused-t"
                          ></textarea>
                          <template v-if="forms.sms.is_submitted">
                            <p v-if="$v.forms.sms.sms.$invalid">
                              <span class="text-danger" v-if="!$v.forms.sms.sms.required">* SMS is required</span>
                            </p>
                          </template>
                          <div class="dialer-sms-count">
                            <span class="dialer-sms-char-count">{{ `${160 - forms.sms.sms.length}` }}</span>
                            <button type="submit" class="dialer-button dialer-button-primary" :disabled="api.send_sms.send || api.checksmsbalance.send || api.checksmsbalance.status==2">
                              <vb-spinner v-if="api.send_sms.send" />
                              <template v-else>Send SMS</template>
                            </button>
                          </div> 
                        </div>
                      </form>
                    </div>
                  </transition>
                </template> ~~>

              </div>
            </div>
            <div v-if="getIsMobile" app-tour-step="0" :class="`chat-info forMobile w-100 ${getProperty($tours, `app-tour.data.currentStep`) == 0 ? 'p-3 bg-white' : ''} ${!isEmpty(sipsessions) || $store.state.calls.videoCalls.length >= 1 ? '' : 'py-0'}`">
              <div class="d-flex flex-column position-relative">
                <div v-if="anyCall" @click="$store.state.global_conditions.tab='video-call'" class="backToCallButton text-center mb-2">
                  <template v-if="allIncomingCalls.length">{{allIncomingCalls.length}} <b-icon icon="telephone-inbound-fill" /></template>
                  <!~~ Back to Call {{sipsessions.length}} ~~>
                  {{sipsessions.length}} Active calls
                </div>
                <div class="dialerMainMobile-topBar">
                  <div class="CountryPlusFlag-onTopOfDialer">
                    <!~~ <label class="mr-1">{{ country }}</label> ~~>
                    <label >{{selectedCallerId | get_property('number_labels.name') }}</label>
                    <label class="ml-1" v-if="isIntegration">on {{ $store.state.integration_platform }}</label>
                  </div>
                  <div class="dialer-dropdown dialer-selectNumber-dropdown">
                    <b-dropdown :text="$store.state.sip.caller_id ? $store.state.sip.caller_id : 'Select a number'" id="set-design-dd" class="m-2 w-100">
                      <template #button-content>
                        <vb-icon icon="callerId-mobile" />
                        <span class="d-flex align-items-center">
                          <span class="mr-3 text-center">{{ selectedCallerId | get_property('caller_id','Please select a caller ID') | number_formater }}</span>
                          <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                        </span>
                      </template>
                      <h6 class="selectNumber-heading mb-0">Your number</h6>
                      <b-form-radio-group class="radio_btn_like_checkbox" :checked="$store.state.sip.caller_id" @change="updateCallerId($event)" :disabled="api.update_caller_id.send" :options="numberOptions" />
                    </b-dropdown>
                  </div>
                </div>
                <div app-tour-web-dashboard-dialer-input class="d-flex align-items-center justify-content-between minHeight-50px">
                  <div class="flex-grow-1 position-relative">
                    <input 
                      placeholder="Enter number..." 
                      type="tel" 
                      class="d-block w-100 border-0" 
                      @focus="conditions.number_input_focused = true" 
                      v-model="$store.state.sip.number" 
                      @input="selected.contact_to_dial.reset()"
                      @keyup.enter="dial()" 
                    />
                  </div>
                  <div class="flex-shrink-1 dashboard-call-cancel-icons">
                    <ul class="list-unstyled mb-0 icon-list">
                      <!~~ api.checksmsbalance.status==2 ? '' :  ~~>
                      <!~~ <li v-if="!isIntegration && numberInfo.valid && numberInfo.is_mobile" class="d-inline-block px-1">
                        <a @click="$modal.show('SendSmsModal',{ number: $store.state.sip.number })">
                          <vb-svg 
                            name="dialer-close-icon" 
                            width="50" 
                            height="50" 
                            viewBox="0 0 50 50" 
                            stroke-width="0" 
                            stroke="#005DFF" 
                            fill="none" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="dialerColse_icon" 
                          />
                        </a>
                      </li> ~~>
                      <li v-if="$store.state.sip.number" class="d-inline-block px-1">
                        <a @click="$store.state.sip.number = '';conditions.number_input_focused = false;selected.contact_to_dial.reset();">
                          <vb-icon icon="mobileDialerNumberReset-updated-icon" class="mobileDialerNumberReset-updated-icon" width="20" height="20"/>
                        </a>
                      </li>

                      <!~~ <li v-if="numberInfo.valid || selected.contact_to_dial.id" class="d-inline-block px-1 ">
                        <a @click="dial()">
                          <vb-svg name="dialer-call-icon" width="50" height="50" viewBox="0 0 50 50" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialerCall_icon" />
                        </a>
                      </li> ~~>
                    </ul>
                  </div>
                </div>
                <label class="countryText-inMobile">{{ country }}</label>
                <!~~ <div v-show="conditions.number_input_focused && !isEmpty(searchNumber)" class="dialer-dropdown position-absolute top-35 dialer-fetchData-drop-down">
                  <div class="dropdown">
                    <ul class="dropdown-menu show">
                      <Info 
                        v-for="(data, index) in searchNumber" 
                        :is_blf="false"
                        :key="data.id || index" :id="data.id" 
                        :name="data.name" 
                        :sub_info="data.number" 
                        :image="data.image" 
                        @click="
                          $store.state.sip.number=data.number;
                          selected.contact_to_dial.id=data.id;
                          selected.contact_to_dial.type=data.type;
                          conditions.number_input_focused = false;
                        "
                      />
                    </ul>
                  </div>
                </div> ~~>
                <ul class="searchedNumbers-list" v-show="conditions.number_input_focused && !isEmpty(searchNumber)">
                  <label class="suggestionsList-heading">Suggestions</label>
                  <div v-for="(data, index) in searchNumber" :key="data.id || index" :id="data.id"  @click="selectContact(data)">
                    <Info 
                      :is_blf="false"
                      :name="data.name" 
                      :sub_info="data.number" 
                      :image="data.image"  
                    />
                  </div>
                </ul>
                <template v-if="!isIntegration">
                  <transition name="slide">
                    <div v-show="numberInfo.valid && numberInfo.is_mobile" class="dialer-sms-area dialer-sms-area-updated position-relative">
                      <form @submit.prevent="sendsms()">
                        <div class="dialer-sms active">
                          <textarea
                            @input="
                              $event.target.rows = ($event.target.value.match(/(\r|\n)/g) || []).length + 1;
                              $event.target.rows = $event.target.scrollHeight > $event.target.offsetHeight ? Math.ceil($event.target.scrollHeight / 24) : $event.target.rows;
                            "
                            :disabled="api.send_sms.send"
                            v-model="forms.sms.sms"
                            onfocus="this.classList.add('focused')"
                            onblur="this.classList.remove('focused')"
                            placeholder="Enter your SMS message"
                            rows="1"
                            maxlength="160"
                            class="focused-t"
                          ></textarea>
                          <template v-if="forms.sms.is_submitted">
                            <p v-if="$v.forms.sms.sms.$invalid">
                              <span class="text-danger" v-if="!$v.forms.sms.sms.required">* SMS is required</span>
                            </p>
                          </template>
                          <div class="dialer-sms-count">
                            <span class="dialer-sms-char-count">{{ `${160 - forms.sms.sms.length}` }}</span>
                            <button type="submit" class="dialer-button dialer-button-primary" :disabled="api.send_sms.send || api.checksmsbalance.send || api.checksmsbalance.status==2">
                              <vb-spinner v-if="api.send_sms.send" />
                              <template v-else>Send</template>
                            </button>
                          </div> 
                        </div>
                      </form>
                    </div>
                  </transition>
                </template>

              </div>
            </div>
          </div>-->
          <div v-if="getIsMobile" class="w-100 d-flex justify-content-start dialer-section-1" :style="`height:calc(100vh - ${mobileNumPad_height}px - 52px)`">
            <div v-if="getIsMobile" app-tour-step="0" :class="`chat-info forMobile w-100 ${getProperty($tours, `app-tour.data.currentStep`) == 0 ? 'p-3 bg-white' : ''} ${!isEmpty(sipsessions) || $store.state.calls.videoCalls.length >= 1 ? '' : 'py-0'}`">
              <div class="d-flex flex-column position-relative">
                <div v-if="anyCall" @click="$store.state.global_conditions.tab='video-call'" class="backToCallButton text-center mb-2">
                  <template v-if="allIncomingCalls.length">{{allIncomingCalls.length}} <b-icon icon="telephone-inbound-fill" /></template>
                  <!-- Back to Call {{sipsessions.length}} -->
                  {{sipsessions.length}} Active calls
                </div>
                <div class="dialerMainMobile-topBar">
                  <div class="CountryPlusFlag-onTopOfDialer">
                    <!-- <label class="mr-1">{{ country }}</label> -->
                    <label >{{selectedCallerId | get_property('number_labels.name') }}</label>
                    <label class="ml-1" v-if="isIntegration">on {{ $store.state.integration_platform }}</label>
                  </div>
                  <div class="dialer-dropdown dialer-selectNumber-dropdown">
                    <b-dropdown :text="$store.state.sip.caller_id ? $store.state.sip.caller_id : 'Select a number'" id="set-design-dd" class="m-2 w-100">
                      <template #button-content>
                        <vb-icon icon="callerId-mobile" />
                        <span class="d-flex align-items-center">
                          <span class="mr-3 text-center">{{ selectedCallerId | get_property('caller_id','Please select a caller ID') | number_formater }}</span>
                          <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                        </span>
                      </template>
                      <h6 class="selectNumber-heading mb-0">Your number</h6>
                      <b-form-radio-group class="radio_btn_like_checkbox" :checked="$store.state.sip.caller_id" @change="updateCallerId($event)" :disabled="api.update_caller_id.send" :options="numberOptions" />
                    </b-dropdown>
                  </div>
                </div>
                <div app-tour-web-dashboard-dialer-input class="d-flex align-items-center justify-content-between minHeight-50px">
                  <div class="flex-grow-1 position-relative">
                    <input 
                      placeholder="Enter number..." 
                      type="tel" 
                      class="d-block w-100 border-0" 
                      @focus="conditions.number_input_focused = true" 
                      v-model="$store.state.sip.number" 
                      @input="selected.contact_to_dial.reset()"
                      @keyup.enter="dial()" 
                    />
                  </div>
                  <div class="flex-shrink-1 dashboard-call-cancel-icons">
                    <ul class="list-unstyled mb-0 icon-list">
                      <!-- api.checksmsbalance.status==2 ? '' :  -->
                      <!-- <li v-if="!isIntegration && numberInfo.valid && numberInfo.is_mobile" class="d-inline-block px-1">
                        <a @click="$modal.show('SendSmsModal',{ number: $store.state.sip.number })">
                          <vb-svg 
                            name="dialer-close-icon" 
                            width="50" 
                            height="50" 
                            viewBox="0 0 50 50" 
                            stroke-width="0" 
                            stroke="#005DFF" 
                            fill="none" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="dialerColse_icon" 
                          />
                        </a>
                      </li> -->
                      <li v-if="$store.state.sip.number" class="d-inline-block px-1">
                        <a @click="$store.state.sip.number = '';conditions.number_input_focused = false;selected.contact_to_dial.reset();">
                          <vb-icon icon="mobileDialerNumberReset-updated-icon" class="mobileDialerNumberReset-updated-icon" width="20" height="20"/>
                        </a>
                      </li>

                      <!-- <li v-if="numberInfo.valid || selected.contact_to_dial.id" class="d-inline-block px-1 ">
                        <a @click="dial()">
                          <vb-svg name="dialer-call-icon" width="50" height="50" viewBox="0 0 50 50" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialerCall_icon" />
                        </a>
                      </li> -->
                    </ul>
                  </div>
                </div>
                <label class="countryText-inMobile">{{ country }}</label>
                <!-- <div v-show="conditions.number_input_focused && !isEmpty(searchNumber)" class="dialer-dropdown position-absolute top-35 dialer-fetchData-drop-down">
                  <div class="dropdown">
                    <ul class="dropdown-menu show">
                      <Info 
                        v-for="(data, index) in searchNumber" 
                        :is_blf="false"
                        :key="data.id || index" :id="data.id" 
                        :name="data.name" 
                        :sub_info="data.number" 
                        :image="data.image" 
                        @click="
                          $store.state.sip.number=data.number;
                          selected.contact_to_dial.id=data.id;
                          selected.contact_to_dial.type=data.type;
                          conditions.number_input_focused = false;
                        "
                      />
                    </ul>
                  </div>
                </div> -->
                <ul class="searchedNumbers-list" v-show="conditions.number_input_focused && !isEmpty(searchNumber)">
                  <label class="suggestionsList-heading">Suggestions</label>
                  <div v-for="(data, index) in searchNumber" :key="data.id || index" :id="data.id"  @click="selectContact(data)">
                    <Info 
                      :is_blf="false"
                      :name="data.name" 
                      :sub_info="data.number" 
                      :image="data.image"  
                    />
                  </div>
                </ul>
                <template v-if="!isIntegration">
                  <transition name="slide">
                    <div v-show="numberInfo.valid && numberInfo.is_mobile" class="dialer-sms-area dialer-sms-area-updated position-relative">
                      <form @submit.prevent="sendsms()">
                        <div class="dialer-sms active">
                          <textarea
                            @input="
                              $event.target.rows = ($event.target.value.match(/(\r|\n)/g) || []).length + 1;
                              $event.target.rows = $event.target.scrollHeight > $event.target.offsetHeight ? Math.ceil($event.target.scrollHeight / 24) : $event.target.rows;
                            "
                            :disabled="api.send_sms.send"
                            v-model="forms.sms.sms"
                            onfocus="this.classList.add('focused')"
                            onblur="this.classList.remove('focused')"
                            placeholder="Enter your SMS message"
                            rows="1"
                            maxlength="160"
                            class="focused-t"
                          ></textarea>
                          <template v-if="forms.sms.is_submitted">
                            <p v-if="$v.forms.sms.sms.$invalid">
                              <span class="text-danger" v-if="!$v.forms.sms.sms.required">* SMS is required</span>
                            </p>
                          </template>
                          <div class="dialer-sms-count">
                            <span class="dialer-sms-char-count">{{ `${160 - forms.sms.sms.length}` }}</span>
                            <button type="submit" class="dialer-button dialer-button-primary" :disabled="api.send_sms.send || api.checksmsbalance.send || api.checksmsbalance.status==2">
                              <vb-spinner v-if="api.send_sms.send" />
                              <template v-else>Send</template>
                            </button>
                          </div> 
                        </div>
                      </form>
                    </div>
                  </transition>
                </template>
              </div>
            </div>
          </div>
          <div v-if="!getIsMobile" class="w-100 d-flex justify-content-start dialer-section-1">
            <div 
              v-if="!getIsMobile" 
              app-tour-step="0" 
              class="chat-info w-100"
              :class="[
                {
                  'p-3 bg-white': getProperty($tours, `app-tour.data.currentStep`) == 0,
                },
                !isEmpty(sipsessions) || $store.state.calls.videoCalls.length >= 1 ? '' : 'py-0'
              ]"  
            >
              <div class="d-flex flex-column position-relative">
                <div app-tour-web-dashboard-dialer-input class="d-flex align-items-center justify-content-between minHeight-50px">
                  <div class="CountryPlusFlag-onTopOfDialer">
                    <!-- <img :src="FindFlag" /> -->
                    <!-- <label>{{ $store.state.sip.number | getFullCountryName || getCurrentUser.country }}</label> -->
                    <label class="cursor_pointer_no_hover" @click="$modal.show(`${_uid}-callSMSpricing`,{
                      number: $store.state.sip.number,
                    })">{{ country }}</label>
                    <label v-if="isIntegration">{{ $store.state.integration_platform || 'test' }}</label>
                  </div>
                  <div class="flex-grow-1 position-relative">
                    <input 
                      ref="number-input"
                      placeholder="Enter number..." 
                      type="tel" 
                      class="d-block w-100 border-0" 
                      @focus="conditions.number_input_focused = true" 
                      v-model="$store.state.sip.number" 
                      @input="selected.contact_to_dial.reset()"
                      @keyup.enter="dial()" 
                    />

                    <div v-show="conditions.number_input_focused && !isEmpty(searchNumber)" class="dialer-dropdown position-absolute top-35 dialer-fetchData-drop-down">
                      <div class="dropdown">
                        <ul class="dropdown-menu show">
                          <!-- <li v-for="(data, index) in searchNumber" :key="data.id || index" >
                            <a>
                              <Info
                                :is_blf="false"
                                :id="data.id" 
                                :name="data.name" 
                                :sub_info="data.number" 
                                :image="data.image" 
                              />
                            </a>
                          </li> -->
                          <li v-for="(data, index) in searchNumber" :key="data.id || index" :id="data.id" @click="selectContact(data)" class="mb-8px">
                            <Info  
                              :is_blf="false"
                              :name="data.name" 
                              :sub_info="data.number" 
                              :image="data.image" 
                            /> 
                          </li>
                        </ul>
                      </div>
                    </div>
                  </div>
                  <div class="flex-shrink-1 dashboard-call-cancel-icons">
                    <ul class="list-unstyled mb-0 icon-list">
                      <li v-if="!isIntegration && numberInfo.valid && numberInfo.is_mobile" class="d-inline-block px-1">
                        <!-- api.checksmsbalance.status==2 ? '' :  -->
                        <!-- :style="`${api.checksmsbalance.status==2 ? 'opacity:0.4' : ''}`" -->
                        <a @click="$modal.show('SendSmsModal',{ number: $store.state.sip.number })">
                          <vb-icon icon="mainDialer-SMS-icon" class="mainDialer-SMS-icon"  />
                        </a>
                      </li>
                      <li v-if="$store.state.sip.number" class="d-inline-block px-1">
                        <a @click="$store.state.sip.number = '';conditions.number_input_focused = false;selected.contact_to_dial.reset();">
                          <vb-svg 
                            name="dialer-close-icon" 
                            width="50" 
                            height="50" 
                            viewBox="0 0 50 50" 
                            stroke-width="0" 
                            stroke="#005DFF" 
                            fill="none" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="dialerColse_icon" 
                          />
                        </a>
                      </li>
                      <li v-if="(numberInfo.valid || selected.contact_to_dial.id) && !getIsMobile" class="d-inline-block px-1 ">
                        <a @click="dial()">
                          <vb-svg 
                            name="dialer-call-icon" 
                            width="50" 
                            height="50" 
                            viewBox="0 0 50 50" 
                            stroke-width="0" 
                            stroke="#005DFF" 
                            fill="none" 
                            stroke-linecap="round" 
                            stroke-linejoin="round" 
                            class="dialerCall_icon" 
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
                <div app-tour-web-dashboard-dialer-select-cid class="dialer-dropdown dialer-selectNumber-dropdown with-copyCallerIDbutton w-100 mt-4">
                  <!-- <button v-show="false" ref="tets" @click="copyCallerId()" type="button"></button> -->
                  <b-dropdown :text="$store.state.sip.caller_id ? $store.state.sip.caller_id : 'Select a Number'" id="set-design-dd" class="m-2 w-100">
                    <template #button-content>
                      <vb-svg 
                        name="selectPhone-icon" 
                        width="15.504" 
                        height="30.214" 
                        viewBox="0 0 15.504 30.214" 
                        stroke-width="0" 
                        stroke="#005DFF" 
                        fill="none" 
                        stroke-linecap="round" 
                        stroke-linejoin="round" 
                        class="selectPhone_icon" 
                      />
                      <p class="caller_id_label">{{selectedCallerId | get_property('number_labels.name') }}</p>
                      <span class="d-flex align-items-center">
                        <!-- <span class="mr-3 text-center">{{ selectedCallerId | get_property('caller_id','Please Select a Caller ID') | number_formater }}</span> -->
                        <span class="mr-3 text-center">{{ selectedCallerId | get_property('caller_id',api.caller_ids.send ? 'Your Numbers' : 'No outgoing number assigned') | number_formater }}</span>
                        <b-icon-chevron-down scale="1.1"></b-icon-chevron-down>
                      </span>
                      <!-- <b-icon @click.stop="$refs.tets.click()" icon="files" /> -->
                    </template>
                    
                    <template v-if="numberOptions.length == 0">
                      <b-dropdown-item class="border-bottom">
                        No telephone numbers to list.
                      </b-dropdown-item>
                      <b-dropdown-item @click="showAddNumberInfoToaster()">
                        <span class="latestBlueColor text-decoration-underline">
                          Click here to get a telephone number
                        </span>
                      </b-dropdown-item>
                    </template>
                    <template v-else>
                      <h6 class="selectNumber-heading mb-0">Your number</h6>
                      <!-- <b-form-radio-group size="lg"
                        class="radio_btn_like_checkbox" 
                        :checked="$store.state.sip.caller_id" 
                        @change="updateCallerId($event)" 
                        :disabled="api.update_caller_id.send" 
                        :options="numberOptions" 
                      /> -->
                      <b-form-radio-group
                        class="radio_btn_like_checkbox" 
                        @change="updateCallerId($event)" 
                        :disabled="api.update_caller_id.send"
                        :checked="$store.state.sip.caller_id"
                      >
                        <b-form-radio v-for="data in numberOptions" :key="data.id" :value="data.value" >
                          <div class="d-flex align-items-center">
                            {{data.text}}
                            <span v-if="data.label" class="OwnertaggedVersion mb-0 ml-16px">{{data.label}}</span>
                          </div>
                        </b-form-radio>
                      </b-form-radio-group>
                    </template>
                  </b-dropdown>
                  <div class="copyCallerIDbutton">
                    <b-icon @click="copyCallerId()" icon="files" />
                  </div>
                </div>

                <!-- <template v-if="!isIntegration">
                  <transition name="slide">
                    <div v-show="numberInfo.valid && numberInfo.is_mobile" class="dialer-sms-area dialer-sms-area-updated position-relative">
                      <form @submit.prevent="sendsms()">
                        <div class="dialer-sms active">
                          <textarea
                            @input="
                              $event.target.rows = ($event.target.value.match(/(\r|\n)/g) || []).length + 1;
                              $event.target.rows = $event.target.scrollHeight > $event.target.offsetHeight ? Math.ceil($event.target.scrollHeight / 24) : $event.target.rows;
                            "
                            :disabled="api.send_sms.send"
                            v-model="forms.sms.sms"
                            onfocus="this.classList.add('focused')"
                            onblur="this.classList.remove('focused')"
                            @focus="showSMSToaster()"
                            placeholder="Enter your SMS message"
                            rows="1"
                            maxlength="160"
                            class="focused-t"
                          ></textarea>
                          <template v-if="forms.sms.is_submitted">
                            <p v-if="$v.forms.sms.sms.$invalid">
                              <span class="text-danger" v-if="!$v.forms.sms.sms.required">* SMS is required</span>
                            </p>
                          </template>
                          <div class="dialer-sms-count">
                            <span class="dialer-sms-char-count">{{ `${160 - forms.sms.sms.length}` }}</span>
                            <button type="submit" class="dialer-button dialer-button-primary" :disabled="api.send_sms.send || api.checksmsbalance.send || api.checksmsbalance.status==2">
                              <vb-spinner v-if="api.send_sms.send" />
                              <template v-else>Send SMS</template>
                            </button>
                          </div> 
                        </div>
                      </form>
                    </div>
                  </transition>
                </template> -->

              </div>
            </div>
          </div>
          <!-- <div v-if="anyCall">
            <b-icon @click="conditions.show_enter_number_section=!conditions.show_enter_number_section" icon="file-play-fill" variant="primary" />
          </div> -->

          <!-- <div v-if="!getIsMobile && !isEmpty($store.state.calls.videoCalls)">
            <VideoList
              v-for="videoCall in $store.state.calls.videoCalls"
              :key="videoCall.room"
              :data="videoCall"
              @set-isMemberOpen="videoCall.isMemberOpen = $event"
              @set-isVideo="videoCall.isVideo = $event"
              @set-isVideoMute="videoCall.isVideoMute = $event"
              @set-isAudioMute="videoCall.isAudioMute = $event"
              @set-isChat="videoCall.isChat = $event"
              @set-volume="videoCall.volume = $event"
              @set-isPlay="videoCall.isPlay = $event"
              @set-isHangout="videoCall.isHangout = $event"
            />
          </div>

          <div class="dialer_calling_area" v-if="!getIsMobile && !isEmpty(sipsessions)">
            <SipCallItem v-for="session in sipsessions" :key="session.uuid" :session="session" :phones="$store.state.sip.phone" />
          </div> -->
          <template v-if="!getIsMobile">
            <div v-show="!api.caller_ids.send && isEmpty(response.caller_ids)" class="rounded-border-error-container">
              <div class="rounded-border-error-container-inner">
                <b-icon icon="exclamation-circle-fill" />
                <div class="rounded-border-error-text flex-fill">
                  <!-- You haven't yet assigned a phone number to your account -->
                  You haven't yet been assigned an outgoing number to your account. 
                  Please request your account admin to assign a outgoing number.
                </div>
              </div>
            </div>  
            <!-- v-if="isEmpty(sipsessions) && isEmpty($store.state.calls.videoCalls)" -->
            <div app-tour-web-dashboard-dialer-dialpad app-tour-step="1" :class="`dial-icons-wrapper pt-0 mb-2 w-100 mw-100 justify-content-between ${getProperty($tours, `app-tour.data.currentStep`) == 1 ? 'p-3 bg-white' : ''}`">
              <div class="main-dialpad dialpad d-flex flex-wrap align-items-center mx-auto">
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('1');selected.contact_to_dial.reset();">
                  <vb-icon icon="dialOne-icon-v2" />
                </button>
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('2');selected.contact_to_dial.reset();">
                  <vb-icon icon="dialTwo-icon-v2" />
                </button>
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('3');selected.contact_to_dial.reset();">
                  <vb-icon icon="dialThree-icon-v2" />
                </button>
                <button class="btn-key notCallBTN four d-flex border-0" @click="$store.state.sip.number += String('4');selected.contact_to_dial.reset();">
                  <vb-icon icon="dialFour-icon-v2" />
                </button>
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('5');selected.contact_to_dial.reset();">
                  <vb-icon icon="dialFive-icon-v2" />
                </button>
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('6');selected.contact_to_dial.reset();">
                  <vb-icon icon="dialSix-icon-v2" />
                </button>
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('7');selected.contact_to_dial.reset();">
                  <vb-icon icon="dialSeven-icon-v2" />
                </button>
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('8');selected.contact_to_dial.reset();">
                  <vb-icon icon="dialEight-icon-v2" />
                </button>
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('9');selected.contact_to_dial.reset();">
                  <vb-icon icon="dialNine-icon-v2" />
                </button>
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('*');selected.contact_to_dial.reset();">
                  <vb-icon icon="asterik-icon-v3" class="asterik-icon-v3" />
                </button>
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('0');selected.contact_to_dial.reset();">
                  <vb-icon icon="dialZero-icon-v2" />
                </button>
                <button class="btn-key notCallBTN d-flex border-0" @click="$store.state.sip.number += String('#');selected.contact_to_dial.reset();">
                  <vb-icon icon="hash-icon-v3" class="hash-icon-v3" />
                </button>
                <button class="btn-key call-btn-key d-flex border-0 mb-0" @click="dial()">
                  <vb-icon icon="dialPad-call-icon" />
                </button>
                <button class="btn-key notCallBTN call-btn-key backspace-icon d-flex justify-content-end align-items-center border-0 mb-0 mr-0" @click="$store.state.sip.number = $store.state.sip.number.substr(0,$store.state.sip.number.length-1)">
                  <vb-icon icon="dialer-backspace-icon-v3-mobile" height="30.128" width="51.975" />
                </button>
              </div>
              <!-- <div v-if="isEmpty(response.caller_ids)">You haven't yet assigned a phone number to your account</div> -->
            </div>
          </template>
          <template v-if="getIsMobile">
            <!-- <div v-if="getIsMobile || isEmpty(sipsessions) && isEmpty($store.state.calls.videoCalls)" app-tour-step="1" :class="`dial-icons-wrapper pt-0 mb-2 w-100 mw-100 justify-content-between ${getProperty($tours, `app-tour.data.currentStep`) == 1 ? 'p-3 bg-white' : ''}`">
              <div class="main-dialpad dialpad d-flex flex-wrap align-items-center mx-auto">
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('1')">
                  <vb-icon icon="dialOne-icon-v3-mobile" />
                </button>
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('2')">
                  <vb-icon icon="dialTwo-icon-v3-mobile" />
                </button>
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('3')">
                  <vb-icon icon="dialThree-icon-v3-mobile" />
                </button>
                <button class="btn-key four d-flex border-0" @click="$store.state.sip.number += String('4')">
                  <vb-icon icon="dialFour-icon-v3-mobile" />
                </button>
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('5')">
                  <vb-icon icon="dialFive-icon-v3-mobile" />
                </button>
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('6')">
                  <vb-icon icon="dialSix-icon-v3-mobile" />
                </button>
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('7')">
                  <vb-icon icon="dialSeven-icon-v3-mobile" />
                </button>
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('8')">
                  <vb-icon icon="dialEight-icon-v3-mobile" />
                </button>
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('9')">
                  <vb-icon icon="dialNine-icon-v3-mobile" />
                </button>
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('*')">
                  
                  <vb-icon icon="asterik-icon-v4-mobile" class="asterik-icon-v3-mobile" />
                </button>
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('0')">
                  <vb-icon icon="dialZero-icon-v3-mobile" />
                </button>
                <button class="btn-key d-flex border-0" @click="$store.state.sip.number += String('#')">
                  
                  <vb-icon icon="hash-icon-v4-mobile" class="hash-icon-v3-mobile" />
                </button>
                <button class="btn-key call-btn-key d-flex border-0 mb-0" @click="dial()">
                  <vb-icon icon="dialPad-call-icon-mobile" />
                </button>
                <button v-if="getIsMobile" class="btn-key call-btn-key backspace-icon d-flex border-0 mb-0" @click="$store.state.sip.number = $store.state.sip.number.substr(0,$store.state.sip.number.length-1)">
                  
                  <vb-icon icon="dialer-backspace-icon-v3-mobile" height="30.128" width="51.975" />
                </button>
              </div>
            </div> -->
            <div v-if="getIsMobile || isEmpty(sipsessions) && isEmpty($store.state.calls.videoCalls)" class="mobileNumPad-likeAirCall-container" id="mobileNumPad">
              <div class="mobileNumPad-likeAirCall-innerContainer">
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('1');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">1</span>
                  <span class="mobileNumPad-likeAirCall-button-smallNumber">&nbsp;</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('2');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">2</span>
                  <span class="mobileNumPad-likeAirCall-button-smallNumber">A B C</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('3');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">3</span>
                  <span class="mobileNumPad-likeAirCall-button-smallNumber">D E F</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('4');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">4</span>
                  <span class="mobileNumPad-likeAirCall-button-smallNumber">G H I</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('5');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">5</span>
                  <span class="mobileNumPad-likeAirCall-button-smallNumber">J K L</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('6');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">6</span>
                  <span class="mobileNumPad-likeAirCall-button-smallNumber">M N O</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('7');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">7</span>
                  <span class="mobileNumPad-likeAirCall-button-smallNumber">P Q R S</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('8');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">8</span>
                  <span class="mobileNumPad-likeAirCall-button-smallNumber">T U V</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('9');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">9</span>
                  <span class="mobileNumPad-likeAirCall-button-smallNumber">W X Y Z</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('*');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">*</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('0');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">0</span>
                  <span class="mobileNumPad-likeAirCall-button-smallNumber">+</span>
                </div>
                <div class="mobileNumPad-likeAirCall-button" @click="$store.state.sip.number += String('#');selected.contact_to_dial.reset();">
                  <span class="mobileNumPad-likeAirCall-button-bigNumber">#</span>
                </div>
                <div></div>
                <div class="mobileNumPad-likeAirCall-button mb-0">
                  <vb-icon icon="mobileDialerGreenCall-updatedLikeAirCall-icon" class="mx-auto" @click="dial()" height="60px" width="60px" />
                </div>
                <div></div>
              </div>
              <!-- <div class="w-100 d-flex justify-content-center">
                <vb-icon icon="mobileDialerGreenCall-updatedLikeAirCall-icon" @click="dial()" height="60px" width="60px" />
              </div> -->
            </div>
          </template>

        </div>
      </span>
      <SendSmsModal @sms-send="$store.state.sip.number=''" />
      <PricingModal :modalName="`${_uid}-callSMSpricing`" />
    </div>
  </transition>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import _ from "lodash";
import { events, $fn, VOIP_API } from "../../utils";
// import SipCallItem from "../Lists/SipCallItem.vue";
// import VideoList from "../Lists/Videos";
import Info from "../Lists/Info.vue";
import moment from 'moment-timezone';
import parsePhoneNumber, { AsYouType } from "libphonenumber-js";
import parseMax from 'libphonenumber-js/max'
import { number_formater, getFullCountryName} from '@/filter';
// import { CountryName2 } from '@/utils/flags';
import { mapGetters } from 'vuex';
import SendSmsModal from '../Modals/SendSmsModal.vue'
import PricingModal from '../Modals/PricingModal.vue';
let search_keys = {
  // 'a': '2',
  // 'b': '2',
  // 'c': '2',
  // 'd': '3',
  // 'e': '3',
  // 'f': '3',
  // 'g': '4',
  // 'h': '4',
  // 'i': '4',
  // 'j': '5',
  // 'k': '5',
  // 'l': '5',
  // 'm': '6',
  // 'n': '6',
  // 'o': '6',
  // 'p': '7',
  // 'q': '7',
  // 'r': '7',
  // 's': '7',
  // 't': '8',
  // 'u': '8',
  // 'v': '8',
  // 'w': '9',
  // 'x': '9',
  // 'y': '9',
  // 'z': '9',

  // 'A': '2',
  // 'B': '2',
  // 'C': '2',
  // 'D': '3',
  // 'E': '3',
  // 'F': '3',
  // 'G': '4',
  // 'H': '4',
  // 'I': '4',
  // 'J': '5',
  // 'K': '5',
  // 'L': '5',
  // 'M': '6',
  // 'N': '6',
  // 'O': '6',
  // 'P': '7',
  // 'Q': '7',
  // 'R': '7',
  // 'S': '7',
  // 'T': '8',
  // 'U': '8',
  // 'V': '8',
  // 'W': '9',
  // 'X': '9',
  // 'Y': '9',
  // 'Z': '9',

  // '+': '0',
  // ' ': '1',
  
  // '0': '0',
  // '1': '1',
  // '2': '2',
  // '3': '3',
  // '4': '4',
  // '5': '5',
  // '6': '6',
  // '7': '7',
  // '8': '8',
  // '9': '9',
  
  // '#': '#',
  // '*': '*',
}
export default {
  name: "DialpadMobileLike",
  components: {
    // VideoList,
    // SipCallItem,
    Info,
    SendSmsModal,
    PricingModal,
  },
  data() {
    return {
      api: {
        checksmsbalance: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          error_message: "",
          get errorShow() {
            return this.count > 0;
          },
          increment() {
            return (this.count = this.count + 1);
          },
          reset() {
            this.count = 0;
            this.send = false;
            this.status = 0;
            this.token = null;
            this.error = {};
            this.error_message = "";
          },
        },
        send_sms: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: "",
          get errorShow() {
            return this.count > 0;
          },
          increment() {
            return (this.count = this.count + 1);
          },
          reset() {
            this.count = 0;
            this.send = false;
            this.status = false;
            this.token = null;
            this.error = {};
            this.error_message = "";
          },
        },
        caller_ids: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: "",
          get errorShow() {
            return this.count > 0;
          },
          increment() {
            return (this.count = this.count + 1);
          },
          reset() {
            this.count = 0;
            this.send = false;
            this.status = false;
            this.token = null;
            this.error = {};
            this.error_message = "";
          },
        },
        update_caller_id: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: "",
          get errorShow() {
            return this.count > 0;
          },
          increment() {
            return (this.count = this.count + 1);
          },
          reset() {
            this.count = 0;
            this.send = false;
            this.status = false;
            this.token = null;
            this.error = {};
            this.error_message = "";
          },
        },
        copy_caller_id: this.$helperFunction.apiInstance()
      },
      response: {
        caller_ids: [],
      },
      conditions: {
        number_input_focused: false,
        show_enter_number_section: true,
      },
      forms: {
        sms: {
          sms: "",
          reset() {
            this.sms = "";
            this.is_submitted = false;
          },
          is_submitted: false,
        },
      },
      selected: {
        contact_to_dial: {
          id: '',
          type: '',
          reset(){
            this.id = ''
            this.type = ''
          },
        },
      },
      num_pad_height: '',
    };
  },
  inject:['isEmpty', 'getProperty','appNotify'],
  validations: {
    forms: {
      sms: {
        sms: {
          required,
        },
      },
    },
  },
  computed: {
    ...mapGetters([
      'anyCall',
      'allIncomingCalls',
      'getAddressbooksNumbers',
      'getVoipTeams',
      'getVoipCallQueues',
      'getVoipIVRS',
      'getCurrentUser',
      'getVoipUsersAlises',
      'getVoipUsersAlisesDisplayName',
      'getIsMobile',
      'isIntegration',
    ]),
    // anyCall(){
    //   return this.sipsessions.length >= 1 || this.$store.state.calls.videoCalls.length >= 1
    // },
    numberInfo(){
      let obj = {
        valid: false,
        is_mobile: false,
        type: '',
        country_code: '',
      }
      const phoneNumber = parsePhoneNumber(`${this.$store.state.sip.number}`,this.getCurrentUser?.country || '')
      if(phoneNumber) {
        obj.valid=phoneNumber.isValid()
        const maxPhoneNumber = parseMax(phoneNumber.number)
        if(maxPhoneNumber){
          obj.type=maxPhoneNumber.getType()
        }
        if(obj.valid){
          obj.country_code=phoneNumber.formatInternational()?.replace?.('+','')?.split?.(' ')?.[0]
        }
      }
      obj.is_mobile = !!obj.type?.toLocaleLowerCase?.()?.includes?.('mobile')
      return obj
    },
    sipsessions() {
      const array = this.$store.state.sip.phone.getSessions ? this.$store.state.sip.phone.getSessions() : [];
      return array.filter((session) => session.number != "*154*");
    },
    isValidNumber() {
      return /([0-9,*,#,+]\d)/.test(this.$store.state.sip.number);
    },
    extractDigitOnnumber() {
      return (this.$store.state.sip.number.match(/\d+/g) || [])
        .toString()
        .replace(/,/g, "");
    },
    firstThreeDigit() {
      return this.extractDigitOnnumber.trim().replace(/ /g, "").slice(0, 3);
    },
    searchedList(){
      const users = Object.values(this.$store.state.common.voipusers);
      const teams = this.getVoipTeams;
      const call_queue = this.getVoipCallQueues;
      const ivr = Object.values(this.getVoipIVRS);
      let array = [
        ...users,
        ...teams,
        ...call_queue,
        ...ivr,
        ...this.getAddressbooksNumbers,
      ];
      return array.map((data) => {
        let str = ''
        let type = ''
        if (data?.object === "PhoneBook") { // addressbook
          type='addressbook'
          str = `${data?.name || ""} ${data?.main_phone?.number || ""} ${data?.main_phone?.formated_number || ""} ${data?.main_phone?.shortCode || ""} ${data?.main_phone?.dialShortCode || ""} ${data?.nickname || ""} ${data?.name?.split?.('')?.map?.(c=>search_keys[c] ?? ' ')?.join?.('') ?? ''}`
        } else if(data?.object == 'Teams'){ // team
          type='teams'
          str = `${data?.name || ""} ${data?.ringroup?.extn || ""} ${data?.name?.split?.('')?.map?.(c=>search_keys[c] ?? ' ')?.join?.('') ?? ''}`
        } else if(data?.object == 'CallQueue'){ // call_queue
          type='call_queue'
          str = `${data?.label || ""} ${data?.extn || ""} ${data?.label?.split?.('')?.map?.(c=>search_keys[c] ?? ' ')?.join?.('') ?? ''}`
        } else if(data?.schedules){ // ivr
          type='ivr'
          str = `${data?.label || ""} ${data?.extn || ""} ${data?.label?.split?.('')?.map?.(c=>search_keys[c] ?? ' ')?.join?.('') ?? ''}`
        } else if(data?.user?.display_name){ // user
          type='user'
          str = `${data?.user?.display_name || ""} ${data?.extn || ""} ${data?.user?.display_name?.split?.('')?.map?.(c=>search_keys[c] ?? ' ')?.join?.('') ?? ''}`
        }
        return {
          id: data?.main_phone?.uid ?? data?.voipaccount ?? data?.real_id ?? data?.id ?? '',
          name: data?.user?.display_name ?? data?.name ?? data?.label ?? '',
          image: data?.profile_image ?? data?.avatar?.image?.image ?? data?.profile_img ?? data?.image ?? '',
          number: data?.main_phone?.formated_number ?? data?.extn ?? data?.ringroup?.extn ?? data?.number ?? '',
          type,
          searched_text: str,
        }
      });
    },
    searchNumber() {
      const search = this.$store.state.sip.number.toLowerCase().trim();
      const user = this.getCurrentUser //this.current_user;
      if (!search) return [];
      return this.searchedList.filter(function(data) {
        if(this.count < 10 && !!(data.id !== user.account && search && data.searched_text.toLowerCase().trim().includes(search))) { this.count++;return true }
        else return false
      },{
        count: 0
      });
    },
    numberOptions() {
      const array = this.response.caller_ids.map((item) => {
        return {
          text: `${number_formater(item.caller_id)}`,
          value: item.caller_id,
          label: item.number_labels?.name ?? ''
        };
      });
      // if(this.response.caller_ids.length==0){
      //   array.push({
      //     text: "No Caller ID",
      //     value: "",
      //   });
      // }
      return array.filter((item) => item.text);
    },
    selectedCallerId(){
      const selected = this.$store.state.sip.caller_id;
      return this.response.caller_ids.find((item) => item.caller_id==selected);
    },
    country(){
      const number = this.$store.state.sip.number
      return getFullCountryName(number) /*?? CountryName2[this.getCurrentUser.country]*/ ?? ''
    },
    isInCall(){
      const sip_sessions = !_.isEmpty(this.$store.state.sip.phone) ? this.$store.state.sip.phone.getSessions() || [] : []
      const jitsi_outgoings = !_.isEmpty(this.$store.state.calls.outgoing_calls) ? this.$store.state.calls.outgoing_calls : []
      const jitsi_incomings = !_.isEmpty(this.$store.state.calls.incoming_calls) ? this.$store.state.calls.incoming_calls : []
      const jitsi_videocalls = !_.isEmpty(this.$store.state.calls.videoCalls) ? this.$store.state.calls.videoCalls : []
      const new_sip_sessions = sip_sessions.filter(session=>session.number!='*154*');
      return !_.isEmpty(new_sip_sessions) || !_.isEmpty(jitsi_outgoings) || !_.isEmpty(jitsi_incomings) || !_.isEmpty(jitsi_videocalls)
    },
    mobileNumPad_height(){
      // console.log("height",document.getElementById('mobileNumPad')?.clientHeight)
      // return document.getElementById('mobileNumPad')?.clientHeight 
      return this.num_pad_height
    }
  },
  watch: {
    anyCall(value){
      this.conditions.show_enter_number_section=!value
    },
    "$store.state.sip.number"(number) { 
      // if([/[0-9]/, /^[ 0-9*().+#-]*$/].every(item=>item.test(number.replace(/ /g,'') || ''))){
      //   this.$store.state.sip.number = new AsYouType(this.getCurrentUser.country).input(number)
      // }
      this.$nextTick()
      .then(()=>{
        // console.log('this.searchNumber.length=1',this.searchNumber.length==0,number)
        if(this.searchNumber.length==0 && [/[0-9]/, /^[ 0-9+]*$/].every(item=>item.test(number.replace(/ /g,'') || ''))){
          // console.log('this.searchNumber.length=2',this.searchNumber.length==0,number)
          this.$store.state.sip.number = new AsYouType(this.getCurrentUser.country).input(number)
        }
      })
      // this.$store.state.sip.number = new AsYouType(this.getCurrentUser.country).input(number)
      // if (this.isValidNumber && this.forms.sms.sms) {
      //   this.checksmsbalance();
      // }
    },
    // "forms.sms.sms"(new_val, old_val) {
    //   if (!old_val && new_val) {
    //     this.checksmsbalance();
    //   }
    // },
    "$store.state.global_conditions.tab"(activeTab) {
      if (activeTab === "homeCopy") {
        this.fetchCallerIds();
      }
    },
    "numberInfo": {
      deep: true,
      handler(obj){
        if(obj?.valid && obj?.is_mobile && !this.isIntegration){
          // this.checksmsbalance();
        }
      },
    },
  },
  filters: {
    getFullCountryName,
  },
  methods: {
    async copyCallerId() {
      const value = number_formater(this.getProperty(this.selectedCallerId,'caller_id'));
      if (this.api.copy_caller_id.send) return;
      try {
        this.api.copy_caller_id.send = true;
        if(!value) throw new Error('Select Caller ID first')
        await navigator.clipboard.writeText(value)
        this.appNotify({
          message: "Copied Text",
          type: 'success',
        })
        
        
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.copy_caller_id.send = false;
      }
    },
    checksmsbalance: _.debounce(function () {
      let vm = this;
      if(!vm.firstThreeDigit) return;
      if (vm.api.checksmsbalance.token) {
        vm.api.checksmsbalance.token.cancel("New Request");
        vm.api.checksmsbalance.token = null;
      }
      vm.api.checksmsbalance.token = require("axios").default.CancelToken.source();
      vm.api.checksmsbalance.send = true;
      vm.api.checksmsbalance.error = {};
      vm.api.checksmsbalance.error_message = '';
      // console.log(this.$store.state.sip.number)
      // const phoneNumber = $fn.getParsePhoneNumber('')(this.$store.state.sip.number, '')
      // console.log(phoneNumber)
      const number = $fn.getNumberFormated(this.$store.state.sip.number)
      // console.log(number)
      VOIP_API.endpoints.sms.balanceInquiry({
        uid: this.getCurrentUser?.uid,
        number: number,
      })
      // VOIP_API.endpoints.telephone_number.checksmsbalance({
      //   prefix: number.substr(0,3), // vm.numberInfo.country_code
      // })
      .then(() => {
        vm.api.checksmsbalance.status = 1;
      })
      .catch((ex) => {
        vm.api.checksmsbalance.status = 2;
        vm.api.checksmsbalance.error = _.get(ex, "response.data") || {};
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.checksmsbalance.send = false;
        vm.api.checksmsbalance.token = null;
      });
    }, 2 * 1000),
    sendsms() {
      let vm = this;
      vm.forms.sms.is_submitted = true;
      vm.$v.forms.sms.$touch();
      if (vm.$v.forms.sms.$invalid || vm.api.send_sms.send || vm.api.checksmsbalance.status!=1) return;
      vm.api.send_sms.send = true;
      VOIP_API.endpoints.users.sendsms({
        accountcode: vm.getCurrentUser?.account,//vm.current_user.account,
        uid: vm.getCurrentUser?.uid,//vm.current_user.uid,
        to: vm.$store.state.sip.number,
        from: vm.getCurrentUser?.account,//vm.current_user.account,
        text: vm.forms.sms.sms,
      })
      .then(() => {
        vm.$store.commit("pushCallActivity", {
          billSec: "00:00:00",
          callRecorded: false,
          call_date: new Date(),
          call_type: "sms",
          callerIp: "",
          date: moment().unix(),
          destinationCallerId: "",
          destinationExt: vm.getVoipUsersAlises[vm.$store.state.sip.number]?.extn, //vm.getUserInfo(vm.$store.state.sip.number).extn,
          destinationName: vm.getVoipUsersAlisesDisplayName[vm.$store.state.sip.number],//vm.showUserName(vm.$store.state.sip.number),
          body: vm.forms.sms.sms,
          duration: "00:00:00",
          soundFile: null,
          sourceCallerId: "",
          sourceExt: vm.getCurrentUser?.extn,//vm.current_user.extn,
          sourceName: vm.getCurrentUser?.firstname,//vm.current_user.firstname,
          src: vm.getCurrentUser?.extn,//vm.current_user.extn,
          status: "Dialed",
          uniqueid: $fn.makeid(50),
          dialable: "",
          is_jitsi: false,
        });
        vm.$store.state.sip.number = "";
        vm.forms.sms.sms = "";
        vm.appNotify({
          message: 'Successfully send',
          type: 'success',
        })
      })
      .catch((ex) => {
        vm.api.send_sms.error_message = ex.own_message
        vm.api.send_sms.error = ex.response_error || {};
        vm.appNotify({
          message: vm.api.send_sms.error_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.send_sms.send = false;
        vm.forms.sms.is_submitted = false;
      });
    },
    fetchCallerIds() {
      let vm = this;
      if (vm.api.caller_ids.send) return;
      vm.api.caller_ids.send = true;
      vm.api.caller_ids.error = {};
      vm.api.caller_ids.error_message = "";
      VOIP_API.endpoints.telephone_number.list({
        account: vm.getCurrentUser?.account,//vm.current_user.account,
      })
      .then(({ data: { list: caller_ids, selected: caller_id, } }) => {
        vm.response.caller_ids = caller_ids || [];
        vm.$store.state.sip.caller_id = caller_id || "";
      })
      .catch((ex) => {
        vm.api.caller_ids.error_message = ex.own_message
        vm.api.caller_ids.error = ex.response_error || {};
      })
      .finally(() => {
        vm.api.caller_ids.send = false;
      });
    },
    updateCallerId(callerid) {
      let vm = this;
      if (vm.api.update_caller_id.send) return;
      vm.api.update_caller_id.send = true;
      vm.api.update_caller_id.error = {};
      vm.api.update_caller_id.error_message = "";
      VOIP_API.endpoints.telephone_number.update(this.getCurrentUser?.account, {
        field: "callerid",
        value: callerid || "666666",
        fid: "12",
      })
      .then(() => {
        // console.log(callerid)
        vm.$store.state.sip.caller_id = callerid || "";
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.api.update_caller_id.error_message = ex.own_message
        vm.api.update_caller_id.error = ex.response_error || {};
        vm.appNotify({
          message: vm.api.update_caller_id.error_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.update_caller_id.send = false;
      });
    },
    selectContact(data){
      // console.log(data)
      this.$store.state.sip.number=number_formater(data.number,'',true);
      this.selected.contact_to_dial.id=data.id;
      this.selected.contact_to_dial.type=data.type;
      this.conditions.number_input_focused = false;
    },
    //event
    dial() {
      if(!this.$store.state.sip.number && this.$store.state.sip.last_call_number){
        this.$store.state.sip.number = this.$store.state.sip.last_call_number
        return;
      }
      this.$root.$emit(events.audio_call,{
        number: this.$store.state.sip.number,
        id: this.selected.contact_to_dial.id,
        type: this.selected.contact_to_dial.type,
      })
      this.selected.contact_to_dial.reset()
    },
    showSMSToaster(){
      this.appNotify({
        message: "Please contact support to activate SMS service",
        type: 'danger',
      })
    },
    showAddNumberInfoToaster(){
      this.$appConfirmation({
        title: "Request Caller ID",
        variant: `info`,
        alert: {
          variant: 'info',
          title: 'Note',
          message: `If you don't have a Caller ID assigned, kindly reach out to your account administrator to request a telephone number assignment.`,
        },
        button: {
          yes: "OK",
        },
        ok: true
      })
    },
    mobileNumPadHeight(){
      console.log("height",document.getElementById('mobileNumPad')?.clientHeight)
      this.num_pad_height = document.getElementById('mobileNumPad')?.clientHeight 
    }
  },
  created(){
    // console.log('this.$store.state.sip',this.$store.state.sip)
    this.mobileNumPadHeight()
    window.addEventListener('resize',this.mobileNumPadHeight())
  },
  beforeDestroy() {
    // window.removeEventListener('resize',this.mobileNumPadHeight())
  },
  mounted() {
    this.fetchCallerIds();
    this.mobileNumPadHeight()
  },
};
</script>

<style lang="scss" scoped>
.mw-50 {
  max-width: 50%;
}
.dialpad.main-dialpad {
  .btn-key {
    width: 70px;
    height: 70px;
    background: transparent;
    margin: 0 14.5px 19px;
    padding: 0;
  }
}

.new-window {
  background-color: #6b6969;
}
.dialer_calling_area {
  flex-direction: column;
  .btn-key {
    background: transparent;
    border: solid 1px #fff;
    font-size: 13px;
    color: #fff;
    padding: 3px;
    border-radius: 50%;
    height: 40px;
    width: 40px;
    display: flex;
    justify-content: center;
    align-items: center;
    margin: 5px;
  }
  .dialer-new-call-button {
    right: 0px;
    top: -16px;
    .btn-key {
      width: 35px;
      height: 35px;
      font-size: 12px;
    }
  }
}

.btn-disable {
  cursor: not-allowed;
  pointer-events: none;
  color: #c0c0c0;
  background-color: #ffffff;
}

.LeftPanelToggle-icon {
  bottom: 0px;
  .ico-chat {
    display: flex;
    align-items: center;
    justify-content: center;
    transition: 0.3s ease-in-out;
    img {
      width: 12px;
    }
    &.toggleIcon {
      transform: rotate(180deg);
    }
  }
}

// slide top to bottom &bottom to top animation
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}

// slide fade
.slide-fade-enter-active {
  transition: all 0.5s ease-in-out;
}

.slide-fade-leave-active {
  transition: all 0.5s ease-in-out;
}
</style>
