<template>
  <div class="dialer-analytics-main dialer-analytics-summary">
    <template v-if="conditions.select_tab">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.select_tab = false" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Date</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
    </template>
    <template v-else>
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$emit('back')">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
          <div class="settingsTextOnTop">Settings</div>
        </div>
        <div class="dialer-settings-title topMainHeading">Summary</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
    </template>

    <div class="dialer-box AnalyticsContainerInner">
      
      <template v-if="conditions.select_tab">
        
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item" v-for="(tab, index) in tabs" :key="index" 
            @click="(selected = tab.key), (tab_index = index), load();selected=='custom' ?  conditions.select_tab = true:conditions.select_tab = false"
          >
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <div class="headingInsideTable">{{ tab.value }}</div>
              </div>  
            </div>
            <vb-icon v-if="selected === tab.key" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
          </div>
        </div>
        <div v-if="selected=='custom'" class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2 forDateTime">
              <label>From</label>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="start_date" id="starttime" />
            </div>
          </div>
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2 forDateTime">
              <label>To</label>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class=" date-time-input" v-model="end_date" id="endtime" />
            </div>
          </div>
          
        </div>
        <a class="IosFullWidthButton forDone mb-16px" @click="load();conditions.select_tab = false">Fetch</a>
      </template>
      <template v-else>
        
        <div class="basicWhiteIOScard mt-32px mb-16px">
          
          <div class="basicWhiteIOScard-item" @click="conditions.select_tab=!conditions.select_tab">
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <div class="headingInsideTable">Date</div>
              </div>  
            </div>
            <div class="d-flex align-items-center">
              <div class="textInsideCard textAlignRight onRightSide mr-8px">{{ selectedTab.value }}</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
          </div>
        </div>
        <vb-no-record class="text-center w-100" v-if="isEmpty(response)" :text="loading ? '' : 'No record'" :design="3" />
        <div v-show="!isEmpty(response) && !loading">
          
          <div class="basicWhiteIOScard mt-16px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon circularProgressBar mr-16px">
                  <vb-circular-progressive-bar :percentage="100"/>
                </div>
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Total Calls</div>
                </div>  
              </div>
              <div class="d-flex align-items-center">
                <vb-icon icon="CallSummary-TotalCall-icon" class="rightFacingArrow mr-16px" width="16.642" height="15.275" />
                <div class="textInsideCard textAlignRight onRightSide">{{ total }}</div>
              </div>
            </div>
          </div>
          <div class="basicWhiteIOScard mt-16px mb-16px">
            <div class="basicWhiteIOScard-item dialer-missed">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon circularProgressBar mr-16px">
                  <vb-circular-progressive-bar :percentage="callsPercentage.missed"/>
                </div>
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Missed Calls</div>
                </div>  
              </div>
              <div class="d-flex align-items-center">
                <vb-icon icon="CallSummary-MissedCall-icon" class="rightFacingArrow mr-16px" width="16.642" height="15.275" />
                <div class="textInsideCard textAlignRight onRightSide">{{ missed }}</div>
              </div>
            </div>
          </div>
          <div class="basicWhiteIOScard mt-16px mb-16px">
            <div class="basicWhiteIOScard-item dialer-inbound">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon circularProgressBar mr-16px">
                  <vb-circular-progressive-bar :percentage="callsPercentage.incomming"/>
                </div>
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Inbound Calls</div>
                </div>  
              </div>
              <div class="d-flex align-items-center">
                <vb-icon icon="CallSummary-InboundCall-icon" class="rightFacingArrow mr-16px" width="16.642" height="15.275" />
                <div class="textInsideCard textAlignRight onRightSide">{{ incomming }}</div>
              </div>
            </div>
          </div>
          <div class="basicWhiteIOScard mt-16px mb-16px">
            <div class="basicWhiteIOScard-item dialer-outbound">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon circularProgressBar mr-16px">
                  <vb-circular-progressive-bar :percentage="callsPercentage.outgoing"/>
                </div>
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Outbound Calls</div>
                </div>  
              </div>
              <div class="d-flex align-items-center">
                <vb-icon icon="CallSummary-OutboundCall-icon" class="rightFacingArrow mr-16px" width="16.642" height="15.275" />
                <div class="textInsideCard textAlignRight onRightSide">{{ outgoing }}</div>
              </div>
            </div>
          </div>

          <div class="basicWhiteIOScard mt-16px mb-16px">
            <div class="chart-container basicWhiteIOScard-item">
              <canvas id="planet-chart"></canvas>
            </div>
          </div>
        </div>

        
      </template>
    
    </div>
  </div>
</template>

<script>
// import AnalyticsService from "@/services/AnalyticsService";
var moment = require("moment-timezone");

import Chart from "chart.js";
import { VOIP_API } from "../../../utils";
import { ctkDateTimePicker } from '../../../mixin';
export default {
  name: 'Summary',
  mixins: [ctkDateTimePicker],
  data() {
    return {
      conditions: {
        select_tab: false,
      },
      start_date: null,
      end_date: null,
      loading: false,
      response: null,
      format: "YYYY-MM-DD",
      disabled: false,
      type: "today",
      date: null,
      service: null,
      icon_line_graph: require("@/assets/images/icon-line-graph-blue.svg"),
      tab_index: 0,
      tabs: [
        {
          value: "Today",
          key: "today",
        },
        {
          value: "This Week",
          key: "week",
        },
        {
          value: "This Month",
          key: "month",
        },
        {
          value: "Custom",
          key: "custom",
        },
      ],
      selected: "today",
      total: 0,
      incomming: 0,
      outgoing: 0,
      missed: 0,
      selectedDate: {
        start: "",
        end: "",
      },
      analyticsChart: {}
    };
  },
  inject:['isEmpty'],
  computed: {
    selectedTab(){
      return this.tabs.find(tab=>tab.key==this.selected)
    },
    callsPercentage() {
      const incomming =
        this.incomming > 0 ? Math.ceil((this.incomming / this.total) * 100) : 0;
      const outgoing =
        this.outgoing > 0 ? Math.ceil((this.outgoing / this.total) * 100) : 0;
      const missed =
        this.missed > 0 ? Math.ceil((this.missed / this.total) * 100) : 0;
      return {
        incomming,
        outgoing,
        missed,
      };
    },
  },
  methods: {
    async load() {
      this.disabled = true;
      this.loading = true;
      await VOIP_API.endpoints.analytics
        .list({
          type: this.selected,
          start: this.start_date,
          end: this.end_date,
        })
        .then(({ data: res }) => {
          this.disabled = false;
          this.response = res;
          this.total = res.totalCalls;
          this.incomming = res.totalIncommingCalls;
          this.outgoing = res.totalOutgoingCalls;
          this.missed = res.totalMissCalls;
          this.date = res.date_label;
          const ctx = document.getElementById("planet-chart");
          this.analyticsChart?.destroy?.();
          this.analyticsChart = new Chart(ctx, {
            type: "line",
            data: {
              labels: res.labels,
              datasets: [
                {
                  label: "Missed",
                  data: res.missedhours,
                  borderColor: ["#FF0000"],
                  borderWidth: 2,
                },
                {
                  label: "Inbound",
                  data: res.inhours,
                  borderColor: ["#28a745"],
                  borderWidth: 2,
                },
                {
                  label: "Outbound",
                  data: res.outhours,
                  borderColor: ["#005dff"],
                  borderWidth: 2,
                },
              ],
            },
            options: {
              responsive: true,
              lineTension: 1,
              scales: {
                yAxes: [{
                  ticks: {
                    beginAtZero: true,
                    stepSize: 1,
                  },
                  scaleLabel: {
                    display: true,
                    labelString: 'Number of Calls'
                  },
                }],
                xAxes: [{
                  scaleLabel: {
                    display: true,
                    labelString: 'Time Slot'
                  },
                }]
              },
            },
          });
          this.analyticsChart.update();
        })
        .catch(() => {
          this.disabled = false;
        })
        .finally(() => {
          this.loading = false;
        });
    },
  },
  mounted() {
    this.date = moment().format("ddd, Do MMM");
    this.load();
  },
};
</script>

<style lang="scss">
.dialer-call-total {
  &.dialer-inbound {
    .dialer-circularProgressBar {
      .circularProgressBar {
        .circularProgressBar-fill {
          background: $dialer-inboundCalls-circularProgressBarColor;
        }
      }
      .dialer-circularProgressBar-text {
        span {
          color: $dialer-inboundCalls-circularProgressBarColor;
        }
      }
    }
  }
  &.dialer-outbound {
    .dialer-circularProgressBar {
      .circularProgressBar {
        .circularProgressBar-fill {
          background: $dialer-outboundCalls-circularProgressBarColor;
        }
      }
      .dialer-circularProgressBar-text {
        span {
          color: $dialer-outboundCalls-circularProgressBarColor;
        }
      }
    }
  }
  &.dialer-missed {
    .dialer-circularProgressBar {
      .circularProgressBar {
        .circularProgressBar-fill {
          background: $dialer-missedCalls-circularProgressBarColor;
        }
      }
      .dialer-circularProgressBar-text {
        span {
          color: $dialer-missedCalls-circularProgressBarColor;
        }
      }
    }
  }
}
</style>
