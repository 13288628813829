<template>
  <modal 
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"  
    :name="modalName" 
    @before-open="onBeforeOpen" 
    @before-close="onBeforeClose" 
    @closed="onClose"
  >
    <template v-if="conditions.screen==screens.edit">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.delete.send ? '' : conditions.screen=screens.view">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Edit Playlist Files</div>
        <div class="plus-IOSIcon-container"></div>
      </div>

      <div class="innerModalIos">

        

        <div class="">
          
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Name</label>
                <input :disabled="api.delete.send" v-model="forms.playlist.name" :placeholder="'Enter Name'" class="w-100" type="text" />
                <p v-if="forms.playlist.submitted && $v.forms.playlist.name.$invalid" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.playlist.name.required">* Name is required</span>
                </p>
              </div>
            </div>
          </div>
          <div class="basicWhiteIOScard mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Sound Files</div>
              <div class="dd-updatedIos">
                <div class="dialer-dropdown">
                  <b-dropdown>
                    <template #button-content>
                      <span class="d-flex align-items-center p-0">
                        <div class="mr-8px">{{selectedTab.text}}</div>
                        <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
                      </span>
                    </template>
                    <b-dropdown-item v-for="tab in tabs" :key="tab.value" @click="selected.tab = tab.value">
                      {{ tab.text }}
                    </b-dropdown-item>
                  </b-dropdown>
                </div>
              </div>
            </div>
          </div>
          <div v-if="selected.tab === tabs.selected.value" class="basicWhiteIOScard mb-16px">
            <Container @drop="onDrop($event)">
              <Draggable v-for="(element,index) in selectedList" :key="index">
                <div class="draggable-item">
                  <div class="basicWhiteIOScard-item withBottomBorder " :id="`${element.id}&&${element.real_id}&&${element.label}&&${element.sound}&&${element.sound_file}`">
                    <div class="headingInsideTable">{{ element.label | check_empty('None') }}</div>
                    <div class="d-flex align-items-center">
                      <vb-audio-player class="mr-16px IosAudioPlayer" :src="element.sound_file" />
                      <b-spinner v-if="api.remove.send == element.id" label="spinner" variant="primary" class="md-mar-left" />
                      <vb-icon v-else v-b-tooltip.hover title="remove to playlist" 
                        @click="api.delete.send ? '' : removeFile(element.id)" icon="trash-IOSIcon" 
                        class="rightFacingArrow fillDanger" width="16.642" height="15.275" 
                      />
                    </div>
                  </div>
                </div>
              </Draggable>
            </Container>
          </div>
          
          
          <div class="basicWhiteIOScard mb-16px" v-if="selected.tab === tabs.unselected.value">
            <div class="basicWhiteIOScard-item" v-for="(item, key) in response.available" :key="key">
              <div class="headingInsideTable">{{ item.label }}</div>
              <div class="d-flex align-items-center">
                <vb-audio-player class="mr-16px IosAudioPlayer" :src="item.sound_file" />
                <b-spinner v-if="api.add.send == item.id" label="spinner" variant="primary" />
                <a v-else v-b-tooltip.hover title="add to playlist" @click="api.delete.send ? '' : addFile(item.id)">
                  <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992"  />
                </a>
              </div>
            </div>
            <vb-no-record v-if="response.available.length==0" :design="3" :text="api.play_list.send ? '' : 'Sorry, No Data Founding list'">
              <vb-loading v-if="api.play_list.send" slot="loading" />
            </vb-no-record>
          </div>

        </div>
        <button class="IosFullWidthButton forRemove mt-16px mb-16px" :disabled="api.delete.send" @click="deletePlayList()">
          <vb-spinner v-if="api.delete.send" />
          <template v-else>Delete</template>
        </button>
        

      </div>
    </template>
    <template v-else>
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.delete.send ? '' : $modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Edit Playlist Files</div>
        <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
          <div class="textInsideCard onRightSide primaryColor">Edit</div>
        </div>
      </div>

      <div class="innerModalIos">

        <div class="">
          
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Name</div>
              <div class="textInsideCard onRightSide">{{ data.name }}</div>
            </div>
          </div>

          <div class="basicWhiteIOScard mt-16px mb-16px">
            <div v-for="(element,index) in selectedList" :key="index" class="basicWhiteIOScard-item withBottomBorder ">
              <div class="headingInsideTable">{{ element.label | check_empty('None') }}</div>
              <div class="d-flex align-items-center">
                <vb-audio-player class="mr-16px IosAudioPlayer" :src="element.sound_file" />
              </div>
            </div>
            <vb-no-record v-if="selectedList.length==0" :design="3" :text="api.playlists.send ? '' : 'Sorry, No Data Founding list'">
              <vb-loading v-if="api.playlists.send" slot="loading" />
            </vb-no-record>
          </div>

        </div>

      </div>
    </template>
  </modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { Container, Draggable } from "vue-smooth-dnd";
import { VOIP_API } from "../../../../utils";
import { mapGetters } from 'vuex';

let tabs = {
  selected: {
    text: 'Selected Files',
    value: 'selected',
  },
  unselected: {
    text: 'Add To List',
    value: 'unselected',
  },
}

let screens = {
  view: 'view',
  edit: 'edit',
}

export default {
  name: "EditPlaylist",
  inject:['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'EditPlaylist',
    },
  },
  components: {
    Container,
    Draggable
  },
  data() {
    return {
      selected: {
        tab: tabs.selected.value,
      },
      response: {
        available: [],
        playlists: [],
        selected: [],
      },
      api: {
        playlists: this.$helperFunction.apiInstance({  }),
        delete: this.$helperFunction.apiInstance({  }),
        add: this.$helperFunction.apiInstance({ send: '' }),
        remove: this.$helperFunction.apiInstance({ send: '' }),
      },
      forms: {
        playlist: this.$helperFunction.formInstance({
          data: {
            name: '',
          }
        }),
      },
      conditions: {
        dragging: false,
        screen: screens.view,
      },
      data: {
        id: '',
        type: '',
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    tabs(){ return tabs },
    selectedTab(){ return Object.values(this.tabs).find(tab=>tab.value==this.selected.tab) },
    selectedList: {
      get(){
        return this.response.playlists.find(i=>i.id==this.data.id)?.playlist?.map?.(i=>{
          return {
            id: i.sound_id ?? i.id,
            label: i.sound_file?.sound_name ?? i.label,
            real_id: i.id ?? i.real_id,
            sound: i.sound_file?.sound_file ?? i.sound,
            sound_file: i.sound_file?.sound ?? i.sound_file,
          }
        }) ?? this.response.selected ?? []
      },
      set(data){
        const playlist = this.response.playlists.find(i=>i.id==this.data.id)
        if(playlist?.playlist) playlist.playlist = data
        else this.response.selected = data
      },
    },
    screens(){ return screens },
  },
  validations: {
    forms: {
      playlist: {
        name: required,
      },
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.forms.playlist.name = event.params?.name ?? '';
      this.data.name = event.params?.name ?? '';
      this.data.id = event.params?.id || '';
      this.fetchPlayListFiles();
    },
    onBeforeClose(){
      this.forms.playlist.reset()
      this.api.playlists.reset()
      this.api.delete.reset()
      this.api.remove.reset()
      this.api.add.reset()
      this.conditions.dragging=false
      this.conditions.screen=screens.view
      this.data.id=''
      this.data.name=''
      this.selected.tab=tabs.selected.value
      this.response.playlists=[]
      this.response.selected=[]
      this.response.available=[]
    },
    onDrop(dropResult){
      let vm = this
      if(dropResult?.removedIndex == dropResult?.addedIndex) return false;
      const data = JSON.parse(JSON.stringify(vm.selectedList[dropResult.removedIndex]))
      let test = [...vm.selectedList]
      let item = test.splice(dropResult.removedIndex,1)[0]
      test.splice(dropResult.addedIndex, 0, item)
      vm.selectedList=test
      VOIP_API.endpoints.music_on_hold.updateplaylistorder({
        playlist: vm.data.id,
        element: data,
        current: dropResult?.removedIndex,
        future: dropResult?.addedIndex,
      })
    },
    fetchPlayListFiles() {
      let vm = this
      if (vm.api.playlists.send) return;
      vm.api.playlists.send = true;
      VOIP_API.endpoints.music_on_hold.playlistfiles(vm.data.id, {
        account: vm.getCurrentUser.account,
      })
      .then(({ data })=>{
        vm.response.available = data?.available?.data
        vm.response.playlists = data?.playlists?.data
        vm.response.selected = data?.selected?.data
      })
      .finally(()=>{
        vm.api.playlists.send=false
      })
    },
    addFile(id) {
      let vm = this
      if (!id || vm.api.add.send) return;
      vm.api.add.send = id;
      VOIP_API.endpoints.music_on_hold.playlistaddfile({
        playlistId: vm.data.id,
        soundId: id,
        fid: 12,
        account: vm.getCurrentUser.account,
        flag: "app",
      })
      .then(({ data }) => {
        vm.response.available = data?.available?.data
        vm.response.playlists = data?.playlists?.data
        vm.response.selected = data?.selected?.data
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.add.send = "";
      });
    },
    removeFile(id) {
      let vm = this
      if (!id || vm.api.remove.send) return;
      vm.api.remove.send = id;
      VOIP_API.endpoints.music_on_hold.playlistremovefile({
        playlistId: this.id,
        soundId: id,
        fid: 12,
        account: vm.getCurrentUser.account,
        flag: "app",
      })
      .then(({ data }) => {
        vm.response.available = data?.available?.data
        vm.response.playlists = data?.playlists?.data
        vm.response.selected = data?.selected?.data
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.remove.send = "";
      });
    },
    onClose() {
      this.$emit("close");
    },
    deletePlayList() {
      let vm = this
      if (vm.api.delete.send) return;
      vm.$appConfirmation({
        title: "Confirm",
        message: `Are you sure you want to delete this playlist?`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback({confirm}) {
          if (confirm) {
            vm.api.delete.send=true;
            VOIP_API.endpoints.music_on_hold.delete({
              id: vm.data.id
            }).then(() => {
              this.appNotify({
                message: "Playlist deleted successfully.",
                type: "success",
              })
              vm.$emit('updated')
              vm.$modal.hide(vm.modalName)
            }).catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(() => {
              vm.api.delete.send=false;
            });
          }
        },
      });
    }
  }
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
