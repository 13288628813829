
import { VOIP_API } from "../index"

export const TAGS_API_ENDPOINTS = {
  colorCombinations(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'relay',
        action: 'get_all_color_combination',
      },
    })
  },
  list(params={}){
    return VOIP_API.axios.voip.request({
      url: `tags`,
      method: "GET",
      params,
    });
  },
  update(data){
    return VOIP_API.axios.voip.request({
      url: `tags`,
      method: "PATCH",
      data,
    });
  },
  create(data){
    return VOIP_API.axios.voip.request({
      url: `tags`,
      method: "POST",
      data,
    });
  },
  delete(id,data={}){
    return VOIP_API.axios.voip.request({
      url: `tags/${id}`,
      method: "DELETE",
      data,
    });
  },
}