<template>
  <div>
    <!-- dashboard -->
    <v-tour 
      :name="dashboard.name" 
      :class="`${dashboard.started?'vm--overlay':''}`" 
      :steps="dashboard.steps" 
      :options="dashboard.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: dashboard.name, started: true });onStartTour(dashboard.name);},
        onSkip: ()=>{setTourStarted({ type: dashboard.name, started: false });onSkipTour(dashboard.name)},
        onFinish: ()=>{setTourStarted({ type: dashboard.name, started: false });onFinishTour(dashboard.name)},
        onStop: ()=>{setTourStarted({ type: dashboard.name, started: false });onStopTour(dashboard.name)},
      }"
    />
    <!-- dialer -->
    <v-tour 
      :name="dialer.name" 
      :class="`${dialer.started?'vm--overlay':''}`" 
      :steps="dialer.steps" 
      :options="dialer.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: dialer.name, started: true });onStartTour(dialer.name);},
        onSkip: ()=>{setTourStarted({ type: dialer.name, started: false });onSkipTour(dialer.name)},
        onFinish: ()=>{setTourStarted({ type: dialer.name, started: false });onFinishTour(dialer.name)},
        onStop: ()=>{setTourStarted({ type: dialer.name, started: false });onStopTour(dialer.name)},
      }"
    />
    <!-- chat -->
    <v-tour 
      :name="chat.name" 
      :class="`${chat.started?'vm--overlay':''}`" 
      :steps="chat.steps" 
      :options="chat.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: chat.name, started: true });onStartTour(chat.name);},
        onSkip: ()=>{setTourStarted({ type: chat.name, started: false });onSkipTour(chat.name)},
        onFinish: ()=>{setTourStarted({ type: chat.name, started: false });onFinishTour(chat.name)},
        onStop: ()=>{setTourStarted({ type: chat.name, started: false });onStopTour(chat.name)},
      }"
    />
    <!-- meeting -->
    <v-tour 
      :name="meeting.name" 
      :class="`${meeting.started?'vm--overlay':''}`" 
      :steps="meeting.steps" 
      :options="meeting.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: meeting.name, started: true });onStartTour(meeting.name);},
        onSkip: ()=>{setTourStarted({ type: meeting.name, started: false });onSkipTour(meeting.name)},
        onFinish: ()=>{setTourStarted({ type: meeting.name, started: false });onFinishTour(meeting.name)},
        onStop: ()=>{setTourStarted({ type: meeting.name, started: false });onStopTour(meeting.name)},
      }"
    />
    <!-- analytics -->
    <v-tour 
      :name="analytics.name" 
      :class="`${analytics.started?'vm--overlay':''}`" 
      :steps="analytics.steps" 
      :options="analytics.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: analytics.name, started: true });onStartTour(analytics.name);},
        onSkip: ()=>{setTourStarted({ type: analytics.name, started: false });onSkipTour(analytics.name)},
        onFinish: ()=>{setTourStarted({ type: analytics.name, started: false });onFinishTour(analytics.name)},
        onStop: ()=>{setTourStarted({ type: analytics.name, started: false });onStopTour(analytics.name)},
      }"
    />
    <!-- monitor -->
    <v-tour 
      :name="monitor.name" 
      :class="`${monitor.started?'vm--overlay':''}`" 
      :steps="monitor.steps" 
      :options="monitor.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: monitor.name, started: true });onStartTour(monitor.name);},
        onSkip: ()=>{setTourStarted({ type: monitor.name, started: false });onSkipTour(monitor.name)},
        onFinish: ()=>{setTourStarted({ type: monitor.name, started: false });onFinishTour(monitor.name)},
        onStop: ()=>{setTourStarted({ type: monitor.name, started: false });onStopTour(monitor.name)},
      }"
    />
    <!-- settings -->
    <v-tour 
      :name="settings.name" 
      :class="`${settings.started?'vm--overlay':''}`" 
      :steps="settings.steps" 
      :options="settings.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: settings.name, started: true });onStartTour(settings.name);},
        onSkip: ()=>{setTourStarted({ type: settings.name, started: false });onSkipTour(settings.name)},
        onFinish: ()=>{setTourStarted({ type: settings.name, started: false });onFinishTour(settings.name)},
        onStop: ()=>{setTourStarted({ type: settings.name, started: false });onStopTour(settings.name)},
      }"
    />
    <!-- media_setting -->
    <v-tour 
      :name="media_setting.name" 
      :class="`${media_setting.started?'vm--overlay':''}`" 
      :steps="media_setting.steps" 
      :options="media_setting.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: media_setting.name, started: true });onStartTour(media_setting.name);},
        onSkip: ()=>{setTourStarted({ type: media_setting.name, started: false });onSkipTour(media_setting.name)},
        onFinish: ()=>{setTourStarted({ type: media_setting.name, started: false });onFinishTour(media_setting.name)},
        onStop: ()=>{setTourStarted({ type: media_setting.name, started: false });onStopTour(media_setting.name)},
      }"
    />
    <!-- speed_dial -->
    <v-tour 
      :name="speed_dial.name" 
      :class="`${speed_dial.started?'vm--overlay':''}`" 
      :steps="speed_dial.steps" 
      :options="speed_dial.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: speed_dial.name, started: true });onStartTour(speed_dial.name);},
        onSkip: ()=>{setTourStarted({ type: speed_dial.name, started: false });onSkipTour(speed_dial.name)},
        onFinish: ()=>{setTourStarted({ type: speed_dial.name, started: false });onFinishTour(speed_dial.name)},
        onStop: ()=>{setTourStarted({ type: speed_dial.name, started: false });onStopTour(speed_dial.name)},
      }"
    />
    <!-- users -->
    <v-tour 
      :name="users.name" 
      :class="`${users.started?'vm--overlay':''}`" 
      :steps="users.steps" 
      :options="users.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: users.name, started: true });onStartTour(users.name);},
        onSkip: ()=>{setTourStarted({ type: users.name, started: false });onSkipTour(users.name)},
        onFinish: ()=>{setTourStarted({ type: users.name, started: false });onFinishTour(users.name)},
        onStop: ()=>{setTourStarted({ type: users.name, started: false });onStopTour(users.name)},
      }"
    />
    <!-- teams -->
    <v-tour 
      :name="teams.name" 
      :class="`${teams.started?'vm--overlay':''}`" 
      :steps="teams.steps" 
      :options="teams.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: teams.name, started: true });onStartTour(teams.name);},
        onSkip: ()=>{setTourStarted({ type: teams.name, started: false });onSkipTour(teams.name)},
        onFinish: ()=>{setTourStarted({ type: teams.name, started: false });onFinishTour(teams.name)},
        onStop: ()=>{setTourStarted({ type: teams.name, started: false });onStopTour(teams.name)},
      }"
    />
    <!-- address_book -->
    <v-tour 
      :name="address_book.name" 
      :class="`${address_book.started?'vm--overlay':''}`" 
      :steps="address_book.steps" 
      :options="address_book.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: address_book.name, started: true });onStartTour(address_book.name);},
        onSkip: ()=>{setTourStarted({ type: address_book.name, started: false });onSkipTour(address_book.name)},
        onFinish: ()=>{setTourStarted({ type: address_book.name, started: false });onFinishTour(address_book.name)},
        onStop: ()=>{setTourStarted({ type: address_book.name, started: false });onStopTour(address_book.name)},
      }"
    />
    <!-- help -->
    <v-tour 
      :name="help.name" 
      :class="`${help.started?'vm--overlay':''}`" 
      :steps="help.steps" 
      :options="help.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: help.name, started: true });onStartTour(help.name);},
        onSkip: ()=>{setTourStarted({ type: help.name, started: false });onSkipTour(help.name)},
        onFinish: ()=>{setTourStarted({ type: help.name, started: false });onFinishTour(help.name)},
        onStop: ()=>{setTourStarted({ type: help.name, started: false });onStopTour(help.name)},
      }"
    />
    <!-- sip_call -->
    <v-tour 
      :name="sip_call.name" 
      :class="`${sip_call.started?'vm--overlay':''}`" 
      :steps="sip_call.steps" 
      :options="sip_call.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: sip_call.name, started: true });onStartTour(sip_call.name);},
        onSkip: ()=>{setTourStarted({ type: sip_call.name, started: false });onSkipTour(sip_call.name)},
        onFinish: ()=>{setTourStarted({ type: sip_call.name, started: false });onFinishTour(sip_call.name)},
        onStop: ()=>{setTourStarted({ type: sip_call.name, started: false });onStopTour(sip_call.name)},
      }"
    />
    <!-- jitsi_call -->
    <v-tour 
      :name="jitsi_call.name" 
      :class="`${jitsi_call.started?'vm--overlay':''}`" 
      :steps="jitsi_call.steps" 
      :options="jitsi_call.options" 
      :callbacks="{
        onStart: ()=>{setTourStarted({ type: jitsi_call.name, started: true });onStartTour(jitsi_call.name);},
        onSkip: ()=>{setTourStarted({ type: jitsi_call.name, started: false });onSkipTour(jitsi_call.name)},
        onFinish: ()=>{setTourStarted({ type: jitsi_call.name, started: false });onFinishTour(jitsi_call.name)},
        onStop: ()=>{setTourStarted({ type: jitsi_call.name, started: false });onStopTour(jitsi_call.name)},
      }"
    />
  </div>
</template>

<script>
import { events, LOGGER } from '@/utils'
import { mapMutations, mapState } from 'vuex'
export default {
  name: 'AppTour',
  computed: {
    ...mapState({
      dashboard: state=>state.app_tour.web.dashboard,
      dialer: state=>state.app_tour.web.dialer,
      chat: state=>state.app_tour.web.chat,
      meeting: state=>state.app_tour.web.meeting,
      analytics: state=>state.app_tour.web.analytics,
      monitor: state=>state.app_tour.web.monitor,
      settings: state=>state.app_tour.web.setting,
      media_setting: state=>state.app_tour.web.dashboard.sub.media_setting,
      speed_dial: state=>state.app_tour.web.dashboard.sub.speed_dial,
      users: state=>state.app_tour.web.dashboard.sub.users,
      teams: state=>state.app_tour.web.dashboard.sub.teams,
      address_book: state=>state.app_tour.web.dashboard.sub.addressbook,
      help: state=>state.app_tour.web.dashboard.sub.help,
      sip_call: state=>state.app_tour.web.sip_calls,
      jitsi_call: state=>state.app_tour.web.jitsi_calls,
    }),
  },
  methods: {
    ...mapMutations([
      'setTourStarted'
    ]),
    onStartTour(name){
      LOGGER.log(`${name}-start`)
    },
    onFinishTour(name){
      let vm = this
      LOGGER.log(`${name}-finish`)
      switch (name) {
        case vm.dashboard.name:
          vm.$tours[vm.dialer.name].start()
          break;
        case vm.dialer.name:
          vm.$modal.show('AudioSettingModal')
          vm.$tours[vm.media_setting.name].start()
          break;
        case vm.media_setting.name:
          vm.$modal.hide('AudioSettingModal')
          setTimeout(()=>{
            vm.$root.$emit(events.ctrl_shift_1)
            vm.$tours[vm.speed_dial.name].start()
          },0.5 * 1000)
          break;
        default:
          break;
      }
    },
    onSkipTour(name){
      LOGGER.log(`${name}-skip`)
    },
    onStopTour(name){
      LOGGER.log(`${name}-stop`)
    },
  },
  // mounted(){
  //   let vm = this
  //   setTimeout(()=>{
  //     vm.$tours[vm.dashboard.name].start()
  //   },3 * 1000)
  // },
}
</script>

<style>

</style>