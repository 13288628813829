<template>
  <div class="crm-main-section DealsSection">
    <div class="d-flex justify-content-between align-items-center w-100 mb-5">
      <h2 class="crm-main-section-heading text-case-unset mb-0">
        Update
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </h2>
    </div>
    <div class="crm-card p-32">
      <h2 class="crm-main-section-heading text-case-unset my-0" style="color:black;">
        Purchase Key
      </h2>
      <div class="crm-input-container withoutIcon mt-3 d-flex w-100 mb-0">
        <input placeholder="Enter your purchase key" style="flex: 1;"/>
        <button class="newDoneButton smaller ml-2" type="button">
          <vb-icon icon="squared-done-icon" height="38px" width="38px" />
          <span class="newDoneButton-textPart">Save</span>
        </button>
      </div>
    </div>
    <div class="crm-card p-32">
      <h2 class="crm-main-section-heading text-case-unset my-0" style="color:black;">
        System Updates
      </h2>
      <div class="d-flex align-items-center my-3">
        <div class="crm-versionBox installed">
          <div class="crm-versionBox-text">
            Installed Version
          </div>
          <div class="crm-versionBox-text">
            1.1.6
          </div>
        </div>
        <div class="crm-versionBox latest">
          <div class="crm-versionBox-text">
            Latest Version
          </div>
          <div class="crm-versionBox-text">
            1.2.0
          </div>
        </div>
      </div>
      <button class="newDoneButton smaller disabled" type="button">
        <vb-icon icon="squared-done-icon" height="38px" width="38px" />
        <span class="newDoneButton-textPart">Save</span>
      </button>
    </div>
    <div class="crm-card p-32">
      <h2 class="crm-main-section-heading text-case-unset mb-0" style="color:black;">
        Patches
      </h2>
      <div class="d-flex justify-content-between align-items-center crm-innerCard mt-4 w-100">
        <div class="d-flex flex-column wd-70">
          <div class="crm-card-heading my-0">Fixes incorrect notification sent on owner change</div>
          <div class="d-flex align-items-center mt-3">
            <p class="circular-highlighted-text mb-0">
              19508260-5c14-4012-8932-232f890e3a83
            </p>
            <p class="crm-card-text my-0 ml-4 font-weight-600">
              January 23, 2023 15:08
            </p>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <b-icon icon="file-earmark-arrow-down-fill" font-scale="1.5" class="mr-3"/>
          <button class="newDoneButton smaller disabled" type="button">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Apply</span>
          </button>
        </div>
      </div>
      <div class="d-flex justify-content-between align-items-center crm-innerCard mt-4 w-100">
        <div class="d-flex flex-column wd-70">
          <div class="crm-card-heading my-0">Fixes issue with permissions checks</div>
          <div class="d-flex align-items-center mt-3">
            <p class="circular-highlighted-text mb-0">
              19508260-5c14-4012-8932-232f890e3a83
            </p>
            <p class="crm-card-text my-0 ml-4 font-weight-600">
              January 23, 2023 15:08
            </p>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <b-icon icon="file-earmark-arrow-down-fill" font-scale="1.5" class="mr-3"/>
          <button class="newDoneButton smaller disabled" type="button">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Apply</span>
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Update',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      },
      allowedUploadfileExtentions: 'jpg, jpeg, png, gif, svg, pdf, aac, ogg, oga, mp3, wav, mp4, m4v, mov, ogv, webm, zip, rar, doc, docx, txt, text, xml, json, xls, xlsx, odt, csv, ppt, pptx, ppsx, ics, eml'
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>