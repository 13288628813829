var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.messageType === 'sender' || _vm.messageType === 'receiver' ? _c('div', {
    staticClass: "msg-box",
    class: {
      'd-flex flex-wrap justify-content-end': _vm.messageType === 'sender',
      'ReactionTrue': !_vm.fromForwardModal && !_vm.isEmpty(_vm.reactions) && _vm.type !== 'pinned',
      'rapidly-message-next-same': _vm.rapidlyMessage == 3,
      'rapidly-message-after-time': _vm.rapidlyMessage == 2,
      'rapidly-message': _vm.rapidlyMessage == 1
    },
    attrs: {
      "id": _vm.post.id
    }
  }, [_c('div', {
    class: `w-30 mb-1 chat-msg-box ${_vm.messageType === 'sender' ? 'text-right d-flex flex-column' : 'other-user-chat-msg-box'}`
  }, [_c('div', {
    staticClass: "chat-msg-box-inner"
  }, [!_vm.fromForwardModal && _vm.messageType === 'receiver' ? _c('span', {
    staticClass: "avatar d-flex align-items-center justify-content-center md-mar-left"
  }, [_c('img', {
    staticClass: "d-block w-100",
    attrs: {
      "src": _vm.$store.getters.getVoipUsersAlisesImages[_vm.post.user_id],
      "alt": _vm.$store.getters.getVoipUsersAlisesDisplayName[_vm.post.user_id]
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.default_user_profile_photo;
      }
    }
  })]) : _vm._e(), _c('div', {
    staticClass: "dialer-user-msg-box d-flex flex-column"
  }, [!_vm.fromForwardModal ? _c('div', {
    staticClass: "dialer-user-msg-info-box",
    style: `display: flex; align-items-end; flex-direction${_vm.messageType === 'sender' ? 'row' : 'row-reverse'}`
  }, [_vm.messageType === 'receiver' || _vm.type === 'pinned' ? _c('span', {
    staticStyle: {
      "margin-right": "5px",
      "margin-left": "5px",
      "font-size": "0.8rem"
    }
  }, [_vm._v(" " + _vm._s(_vm.$store.getters.getVoipUsersAlisesDisplayName[_vm.post.user_id]) + " ")]) : _vm._e(), _c('small', {
    staticClass: "dialer-user-msg-time ml-2",
    staticStyle: {
      "margin": "0"
    }
  }, [_vm._v(_vm._s(_vm._f("time")(_vm.post.create_at)))]), _vm.post.is_pinned && _vm.type !== 'pinned' ? _c('small', {
    staticClass: "d-inline-flex align-items-end forPinned mx-3"
  }, [_c('vb-svg', {
    attrs: {
      "width": "20",
      "height": "20",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "name": "dialer-pin"
    }
  }), _vm._v(" pinned ")], 1) : _vm._e()]) : _vm._e(), _c('span', {
    class: `msg d-inline-block chat-message ${!_vm.fromForwardModal && _vm.post.props && _vm.post.props.is_important ? 'msg-important' : ''}`
  }, [!_vm.fromForwardModal && _vm.post.props && _vm.post.props.reply_post_id ? _c('span', {
    staticClass: "d-flex flex-column align-items-start border-bottom border-dark pb-1 mb-1 repliedMsg-container",
    staticStyle: {
      "word-break": "break-word"
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.commaIcon_black,
      "alt": ""
    }
  }), _vm.parent_post.message ? [_c('span', {
    staticClass: "pl-2"
  }, [_vm._v(_vm._s(_vm.parent_post.message))])] : _c('span', {
    staticClass: "pl-2"
  }, [_vm._v("This message was deleted")])], 2) : _vm._e(), _vm.post.message ? _c('div', {
    staticClass: "msg-textArea"
  }, [_vm.isForwarded ? _c('div', {
    staticClass: "forwardedMsg"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "ShortcutKeysModal-duringJitsiCall-icon",
      "height": "16px",
      "width": "18px"
    }
  }), _c('div', {
    staticClass: "forwardedMsg-text"
  }, [_vm._v("Forwarded")])], 1) : _vm._e(), _c('div', {
    staticClass: "msg-text"
  }, [_c('span', {
    attrs: {
      "id": `${_vm.post.id}-text-message`
    }
  }, [_vm._v(_vm._s(_vm.post.message))])])]) : _vm._e(), _vm.post.delete_at > 0 ? _c('div', {
    staticClass: "msg-textArea"
  }, [_c('div', {
    staticClass: "msg-text"
  }, [_c('span', {
    attrs: {
      "id": `${_vm.post.id}-text-message`
    }
  }, [_vm._v("This message was deleted")])])]) : _vm._e(), _vm.post.metadata ? _c('div', {
    class: `
                msg-media 
                ${_vm.post.metadata && (_vm.post.metadata.files || []).every(function (file) {
      return file.mime_type.includes('video');
    }) ? 'video-msg-container' : ''} 
                ${_vm.post.metadata && (_vm.post.metadata.files || []).some(function (file) {
      return file.mime_type.includes('image');
    }) ? 'msg-contain-img' : ''}  
                ${_vm.post.metadata && (_vm.post.metadata.files || []).every(function (file) {
      return file.mime_type.includes('image');
    }) ? 'allImages' : ''} 
              `
  }, _vm._l(_vm.post.metadata.files, function (file, index) {
    return _c('File', {
      key: file.id,
      ref: `file-component-${file.id}`,
      refInFor: true,
      attrs: {
        "random_id": _vm.random_id,
        "file": file,
        "index": index,
        "post_id": _vm.post.id,
        "channel_id": _vm.channel.id,
        "message_type": _vm.messageType,
        "type": _vm.type
      }
    });
  }), 1) : _vm._e(), !_vm.fromForwardModal && !_vm.isEmpty(_vm.reactions) && _vm.type !== 'pinned' ? _c('div', {
    staticClass: "d-flex reaction_emoji_box"
  }, [_vm.angryReachtions.length ? _c('div', {
    class: `reaction_emoji_box_inner ${_vm.angryReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`,
    attrs: {
      "id": `angryReachtions-${_vm.post.id}`
    }
  }, [_vm.angryReachtions.length > 1 ? _c('span', {
    staticStyle: {
      "line-height": "1"
    }
  }, [_vm._v(_vm._s(_vm.angryReachtions.length > 1 ? _vm.angryReachtions.length : ''))]) : _vm._e(), _c('span', {
    class: _vm.angryReachtions.length > 1 ? 'ml-1' : '',
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Angry.img)
    },
    on: {
      "click": function ($event) {
        return _vm.removeReaction(_vm.reactionEmojis.Angry.name);
      }
    }
  }), _c('b-popover', {
    attrs: {
      "custom-class": "emojiUserList-container",
      "target": `angryReachtions-${_vm.post.id}`,
      "placement": "top",
      "triggers": "hover focus"
    }
  }, [_c('div', {
    staticClass: "reactedEmoji"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Angry.img)
    }
  })]), _c('div', {
    staticClass: "emojiUserList"
  }, _vm._l(_vm.angryReachtions, function (react) {
    return _c('div', {
      key: react.user_id,
      staticClass: "emojiUser"
    }, [_c('img', {
      attrs: {
        "src": react.profile_image
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.local_filess.images.user_male;
        }
      }
    }), _c('p', {}, [_vm._v(_vm._s(react.username))])]);
  }), 0)])], 1) : _vm._e(), _vm.cryReachtions.length ? _c('div', {
    class: `reaction_emoji_box_inner ${_vm.cryReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`,
    attrs: {
      "id": `cryReachtions-${_vm.post.id}`
    }
  }, [_vm.cryReachtions.length > 1 ? _c('span', {
    staticStyle: {
      "line-height": "1"
    }
  }, [_vm._v(_vm._s(_vm.cryReachtions.length > 1 ? _vm.cryReachtions.length : ''))]) : _vm._e(), _c('span', {
    class: _vm.cryReachtions.length > 1 ? 'ml-1' : '',
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Cry.img)
    },
    on: {
      "click": function ($event) {
        return _vm.removeReaction(_vm.reactionEmojis.Cry.name);
      }
    }
  }), _c('b-popover', {
    attrs: {
      "custom-class": "emojiUserList-container",
      "target": `cryReachtions-${_vm.post.id}`,
      "placement": "top",
      "triggers": "hover focus"
    }
  }, [_c('div', {
    staticClass: "reactedEmoji"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Cry.img)
    }
  })]), _c('div', {
    staticClass: "emojiUserList"
  }, _vm._l(_vm.cryReachtions, function (react) {
    return _c('div', {
      key: react.user_id,
      staticClass: "emojiUser"
    }, [_c('img', {
      attrs: {
        "src": react.profile_image
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.local_filess.images.user_male;
        }
      }
    }), _c('p', [_vm._v(_vm._s(react.username))])]);
  }), 0)])], 1) : _vm._e(), _vm.thumbupReachtions.length ? _c('div', {
    class: `reaction_emoji_box_inner ${_vm.thumbupReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`,
    attrs: {
      "id": `thumbupReachtions-${_vm.post.id}`
    }
  }, [_vm.thumbupReachtions.length > 1 ? _c('span', {
    staticStyle: {
      "line-height": "1"
    }
  }, [_vm._v(_vm._s(_vm.thumbupReachtions.length > 1 ? _vm.thumbupReachtions.length : ''))]) : _vm._e(), _c('span', {
    class: _vm.thumbupReachtions.length > 1 ? 'ml-1' : '',
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Thumbup.img)
    },
    on: {
      "click": function ($event) {
        return _vm.removeReaction(_vm.reactionEmojis.Thumbup.name);
      }
    }
  }), _c('b-popover', {
    attrs: {
      "custom-class": "emojiUserList-container",
      "target": `thumbupReachtions-${_vm.post.id}`,
      "placement": "top",
      "triggers": "hover focus"
    }
  }, [_c('div', {
    staticClass: "reactedEmoji"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Thumbup.img)
    }
  })]), _c('div', {
    staticClass: "emojiUserList"
  }, _vm._l(_vm.thumbupReachtions, function (react) {
    return _c('div', {
      key: react.user_id,
      staticClass: "emojiUser"
    }, [_c('img', {
      attrs: {
        "src": react.profile_image
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.local_filess.images.user_male;
        }
      }
    }), _c('p', [_vm._v(_vm._s(react.username))])]);
  }), 0)])], 1) : _vm._e(), _vm.wowReachtions.length ? _c('div', {
    class: `reaction_emoji_box_inner ${_vm.wowReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`,
    attrs: {
      "id": `wowReachtions-${_vm.post.id}`
    }
  }, [_vm.wowReachtions.length > 1 ? _c('span', {
    staticStyle: {
      "line-height": "1"
    }
  }, [_vm._v(_vm._s(_vm.wowReachtions.length > 1 ? _vm.wowReachtions.length : ''))]) : _vm._e(), _c('span', {
    class: _vm.wowReachtions.length > 1 ? 'ml-1' : '',
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Wow.img)
    },
    on: {
      "click": function ($event) {
        return _vm.removeReaction(_vm.reactionEmojis.Wow.name);
      }
    }
  }), _c('b-popover', {
    attrs: {
      "custom-class": "emojiUserList-container",
      "target": `wowReachtions-${_vm.post.id}`,
      "placement": "top",
      "triggers": "hover focus"
    }
  }, [_c('div', {
    staticClass: "reactedEmoji"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Wow.img)
    }
  })]), _c('div', {
    staticClass: "emojiUserList"
  }, _vm._l(_vm.wowReachtions, function (react) {
    return _c('div', {
      key: react.user_id,
      staticClass: "emojiUser"
    }, [_c('img', {
      attrs: {
        "src": react.profile_image
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.local_filess.images.user_male;
        }
      }
    }), _c('p', [_vm._v(_vm._s(react.username))])]);
  }), 0)])], 1) : _vm._e(), _vm.heartReachtions.length ? _c('div', {
    class: `reaction_emoji_box_inner ${_vm.heartReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`,
    attrs: {
      "id": `heartReachtions-${_vm.post.id}`
    }
  }, [_vm.heartReachtions.length > 1 ? _c('span', {
    staticStyle: {
      "line-height": "1"
    }
  }, [_vm._v(_vm._s(_vm.heartReachtions.length > 1 ? _vm.heartReachtions.length : ''))]) : _vm._e(), _c('span', {
    class: _vm.heartReachtions.length > 1 ? 'ml-1' : '',
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Heart.img)
    },
    on: {
      "click": function ($event) {
        return _vm.removeReaction(_vm.reactionEmojis.Heart.name);
      }
    }
  }), _c('b-popover', {
    attrs: {
      "custom-class": "emojiUserList-container",
      "target": `heartReachtions-${_vm.post.id}`,
      "placement": "top",
      "triggers": "hover focus"
    }
  }, [_c('div', {
    staticClass: "reactedEmoji"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Heart.img)
    }
  })]), _c('div', {
    staticClass: "emojiUserList"
  }, _vm._l(_vm.heartReachtions, function (react) {
    return _c('div', {
      key: react.user_id,
      staticClass: "emojiUser"
    }, [_c('img', {
      attrs: {
        "src": react.profile_image
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.local_filess.images.user_male;
        }
      }
    }), _c('p', [_vm._v(_vm._s(react.username))])]);
  }), 0)])], 1) : _vm._e(), _vm.laughingReachtions.length ? _c('div', {
    class: `reaction_emoji_box_inner ${_vm.laughingReachtions.length > 1 ? 'px-2 w-auto rounded-pill d-flex align-items-center' : ''}`
  }, [_vm.laughingReachtions.length > 1 ? _c('span', {
    staticStyle: {
      "line-height": "1"
    }
  }, [_vm._v(_vm._s(_vm.laughingReachtions.length > 1 ? _vm.laughingReachtions.length : ''))]) : _vm._e(), _c('span', {
    class: _vm.laughingReachtions.length > 1 ? 'ml-1' : '',
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "id": `laughingReachtions-${_vm.post.id}`
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Laughing.img)
    },
    on: {
      "click": function ($event) {
        return _vm.removeReaction(_vm.reactionEmojis.Laughing.name);
      }
    }
  }), _c('b-popover', {
    attrs: {
      "custom-class": "emojiUserList-container",
      "target": `laughingReachtions-${_vm.post.id}`,
      "placement": "top",
      "triggers": "hover focus"
    }
  }, [_c('div', {
    staticClass: "reactedEmoji"
  }, [_c('span', {
    staticStyle: {
      "cursor": "pointer"
    },
    domProps: {
      "innerHTML": _vm._s(_vm.reactionEmojis.Laughing.img)
    }
  })]), _c('div', {
    staticClass: "emojiUserList"
  }, _vm._l(_vm.laughingReachtions, function (react) {
    return _c('div', {
      key: react.user_id,
      staticClass: "emojiUser"
    }, [_c('img', {
      attrs: {
        "src": react.profile_image
      },
      on: {
        "error": function ($event) {
          $event.target.src = _vm.local_filess.images.user_male;
        }
      }
    }), _c('p', [_vm._v(_vm._s(react.username))])]);
  }), 0)])], 1) : _vm._e()]) : _vm._e()]), !_vm.fromForwardModal && _vm.post.props && _vm.post.props.isEdited ? _c('span', {
    staticClass: "editedMsgICon"
  }, [_c('b-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "This message has been edited.",
      "icon": "pencil"
    }
  })], 1) : _vm._e()]), _vm.api.send_message.send ? _c('div', {
    staticClass: "spinner-border v2",
    attrs: {
      "role": "status"
    }
  }, [_c('span', {
    staticClass: "sr-only"
  }, [_vm._v("Loading...")])]) : _vm._e(), _vm.api.send_message.error_message ? _c('div', {
    staticClass: "ChatErrorMsgContainer"
  }, [_c('b-icon', {
    attrs: {
      "variant": "danger",
      "icon": "exclamation-circle-fill",
      "id": `${_vm.post.id}-tooltip-target`
    }
  }), _c('b-tooltip', {
    attrs: {
      "target": `${_vm.post.id}-tooltip-target`,
      "triggers": "hover"
    }
  }, [_vm._v(" " + _vm._s(_vm.api.send_message.error_message) + " "), _c('b-icon', {
    attrs: {
      "icon": "arrow-counterclockwise"
    },
    on: {
      "click": function ($event) {
        return _vm.createPost();
      }
    }
  })], 1)], 1) : _vm._e(), !_vm.fromForwardModal && _vm.type !== 'pinned' && _vm.post.delete_at === 0 ? _c('div', {
    staticClass: "dialer_show_emoji"
  }, [_vm._l(_vm.reactionEmojis, function (react) {
    return _c('span', {
      key: react.name,
      staticClass: "mr-2"
    }, [_c('span', {
      staticClass: "cursor_pointer",
      domProps: {
        "innerHTML": _vm._s(react.img)
      },
      on: {
        "click": function ($event) {
          return _vm.emojiReact(react.name);
        }
      }
    }), _vm.reactions.some(function (emoji) {
      return emoji.emoji_name === react.name && emoji.user_id === _vm.$store.getters.getMattermostCurrentUser.id;
    }) ? _c('span', {
      staticClass: "selectedEmojiDot-container"
    }, [_c('span', {
      staticClass: "selectedEmojiDot"
    })]) : _vm._e()]);
  }), _c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical",
            "scale": "1.3"
          }
        })];
      },
      proxy: true
    }], null, false, 777234123)
  }, [_vm.isDownloadFile ? _c('b-dropdown-item', [_c('span', {
    on: {
      "click": _vm.downloadAllFile
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-copy-icon",
      "width": "16",
      "stroke": "currentColor",
      "height": "16",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke-width": "1.2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Download")])], 1)]) : _vm._e(), _vm.post.message ? _c('b-dropdown-item', [_c('span', {
    on: {
      "click": _vm.copyPost
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-copy-icon",
      "width": "16",
      "stroke": "currentColor",
      "height": "16",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke-width": "1.2",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Copy")])], 1)]) : _vm._e(), _vm.messageType === 'sender' && _vm.post.message ? _c('b-dropdown-item', [_c('span', {
    on: {
      "click": _vm.edit
    }
  }, [_c('span', [_c('vb-svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "name": "dialer-edit-icon"
    }
  })], 1), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Edit")])])]) : _vm._e(), _vm.post.message ? _c('b-dropdown-item', [_c('span', {
    on: {
      "click": _vm.reply
    }
  }, [_c('span', [_c('vb-svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "name": "dialer-reply-icon"
    }
  })], 1), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Reply")])])]) : _vm._e(), !_vm.post.file_ids ? _c('b-dropdown-item', [_c('span', {
    on: {
      "click": _vm.forward
    }
  }, [_c('span', [_c('vb-svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "name": "dialer-forward-icon"
    }
  })], 1), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Forward")])])]) : _vm._e(), _vm.messageType === 'receiver' ? _c('b-dropdown-item', [_c('span', {
    on: {
      "click": _vm.markUnread
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "envelope"
    }
  }), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v("Unread from here")])], 1)]) : _vm._e(), _c('b-dropdown-item', [_c('span', {
    on: {
      "click": _vm.pinned
    }
  }, [_c('span', [_c('vb-svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "name": "dialer-pin"
    }
  })], 1), _c('span', {
    staticClass: "ml-2"
  }, [_vm._v(_vm._s(_vm.post.is_pinned ? "Unpinned" : "Pinned"))])])]), _vm.messageType === 'sender' ? _c('b-dropdown-item', [_c('span', {
    on: {
      "click": _vm.deletePost
    }
  }, [_c('span', [_c('vb-svg', {
    attrs: {
      "width": "16",
      "height": "16",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.2",
      "stroke": "currentColor",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round",
      "name": "dialer-delete-icon"
    }
  })], 1), _c('span', {
    staticClass: "ml-2 danger"
  }, [_vm._v("Delete")])])]) : _vm._e()], 1)], 2) : _vm._e()])]), _vm.viewedMembers.length != 0 ? _c('div', {
    staticClass: "readBy-container"
  }, [_vm._l(_vm.viewedMembers, function (member, index) {
    return _c('div', {
      key: member.user_id,
      staticClass: "readBy-item",
      style: `z-index: ${index + 1};`
    }, [_c('vb-avatar', {
      attrs: {
        "id": member.user_id,
        "is_blf": false,
        "showStatus": false,
        "showPopover": true
      }
    })], 1);
  }), _vm.viewedMembers.length > 3 ? _c('div', {
    staticClass: "readBy-item withPlus",
    attrs: {
      "id": `popover-ViewedByTooltipVersion-${_vm.post.id}`
    }
  }, [_vm._v(" + 3 ")]) : _vm._e(), _c('b-popover', {
    attrs: {
      "custom-class": "ViewedByTooltipVersion",
      "target": `popover-ViewedByTooltipVersion-${_vm.post.id}`,
      "placement": "top",
      "triggers": "focus click",
      "show": ""
    }
  }, [_c('div', {}, _vm._l(_vm.viewedMembers, function (member, index) {
    return _c('div', {
      key: member.user_id,
      staticClass: "ViewedByTooltipVersion-item",
      style: `z-index: ${index + 1};`
    }, [_c('Info', {
      attrs: {
        "id": member.user_id,
        "is_blf": false,
        "showStatus": false,
        "showPopover": true
      }
    })], 1);
  }), 0)])], 2) : _vm._e()]) : _c('div', {
    staticClass: "msg-box justify-content-center d-flex",
    attrs: {
      "id": _vm.post.id
    }
  }, [_c('div', {
    staticClass: "mb-1 chat-msg-box text-center channelPurpose",
    staticStyle: {
      "max-width": "calc(100% - 20%)"
    }
  }, [_c('div', {
    staticClass: "chat-msg-box-inner",
    domProps: {
      "innerHTML": _vm._s(_vm.systemMessage())
    }
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }