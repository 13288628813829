var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-area d-flex h-100 dialer_main_body"
  }, [_c('div', {
    staticClass: "dialer_main_body_inner w-100"
  }, [_vm.getCurrentUser.administrator_account ? [_c('div', {
    staticClass: "dialer_inner_left_side allow-scroll analyticsLeftSideBar d-r-768px-none h-100"
  }, [_c('h2', {
    staticClass: "dialer_main_title text-capitalize"
  }, [_vm._v("Analytics")]), _c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('li', {
      key: tab.component,
      staticClass: "dialer_tab_btn",
      class: {
        'active': _vm.GET_LOCAL_SETTING_ANALYTICS.tab === tab.component
      },
      on: {
        "click": function ($event) {
          return _vm.SET_LOCAL_SETTING_ANALYTICS_TAB(tab.component);
        }
      }
    }, [_c('a', {
      staticClass: "text-capitalize"
    }, [_c('span', [_c('b-icon', {
      staticClass: "mr-3",
      attrs: {
        "icon": tab.icon,
        "scale": "1.3"
      }
    })], 1), _c('span', [_vm._v(_vm._s(tab.label))])])]);
  }), 0)]), _c('button', {
    staticClass: "d-r-sidebar-button",
    on: {
      "click": _vm.showSidebar
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fas', 'bars'],
      "scale": "1.2"
    }
  })], 1)] : _vm._e(), _c('div', {
    staticClass: "dialer_inner_right_side"
  }, [_c(_vm.GET_LOCAL_SETTING_ANALYTICS.tab, {
    tag: "component",
    on: {
      "change-tab": function ($event) {
        return _vm.SET_LOCAL_SETTING_ANALYTICS_TAB($event);
      }
    }
  })], 1)], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }