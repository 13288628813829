var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose,
      "closed": _vm.onClose
    }
  }, [_vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.delete.send ? '' : _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Playlist Files")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {}, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.playlist.name,
      expression: "forms.playlist.name"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.delete.send,
      "placeholder": 'Enter Name',
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.playlist.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.playlist, "name", $event.target.value);
      }
    }
  }), _vm.forms.playlist.submitted && _vm.$v.forms.playlist.name.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.playlist.name.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e()]) : _vm._e()])])]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Sound Files")]), _c('div', {
    staticClass: "dd-updatedIos"
  }, [_c('div', {
    staticClass: "dialer-dropdown"
  }, [_c('b-dropdown', {
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center p-0"
        }, [_c('div', {
          staticClass: "mr-8px"
        }, [_vm._v(_vm._s(_vm.selectedTab.text))]), _c('vb-icon', {
          staticClass: "rightFacingArrow",
          attrs: {
            "icon": "rightFacingArrow-IOSIcon",
            "width": "16.642",
            "height": "15.275"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1708297017)
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('b-dropdown-item', {
      key: tab.value,
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
        }
      }
    }, [_vm._v(" " + _vm._s(tab.text) + " ")]);
  }), 1)], 1)])])]), _vm.selected.tab === _vm.tabs.selected.value ? _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('Container', {
    on: {
      "drop": function ($event) {
        return _vm.onDrop($event);
      }
    }
  }, _vm._l(_vm.selectedList, function (element, index) {
    return _c('Draggable', {
      key: index
    }, [_c('div', {
      staticClass: "draggable-item"
    }, [_c('div', {
      staticClass: "basicWhiteIOScard-item withBottomBorder",
      attrs: {
        "id": `${element.id}&&${element.real_id}&&${element.label}&&${element.sound}&&${element.sound_file}`
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("check_empty")(element.label, 'None')))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('vb-audio-player', {
      staticClass: "mr-16px IosAudioPlayer",
      attrs: {
        "src": element.sound_file
      }
    }), _vm.api.remove.send == element.id ? _c('b-spinner', {
      staticClass: "md-mar-left",
      attrs: {
        "label": "spinner",
        "variant": "primary"
      }
    }) : _c('vb-icon', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      staticClass: "rightFacingArrow fillDanger",
      attrs: {
        "title": "remove to playlist",
        "icon": "trash-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      },
      on: {
        "click": function ($event) {
          _vm.api.delete.send ? '' : _vm.removeFile(element.id);
        }
      }
    })], 1)])])]);
  }), 1)], 1) : _vm._e(), _vm.selected.tab === _vm.tabs.unselected.value ? _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_vm._l(_vm.response.available, function (item, key) {
    return _c('div', {
      key: key,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(item.label))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('vb-audio-player', {
      staticClass: "mr-16px IosAudioPlayer",
      attrs: {
        "src": item.sound_file
      }
    }), _vm.api.add.send == item.id ? _c('b-spinner', {
      attrs: {
        "label": "spinner",
        "variant": "primary"
      }
    }) : _c('a', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip.hover",
        modifiers: {
          "hover": true
        }
      }],
      attrs: {
        "title": "add to playlist"
      },
      on: {
        "click": function ($event) {
          _vm.api.delete.send ? '' : _vm.addFile(item.id);
        }
      }
    }, [_c('vb-icon', {
      staticClass: "plus-IOSIcon",
      attrs: {
        "icon": "plus-IOSIcon",
        "width": "20",
        "height": "19.992"
      }
    })], 1)], 1)]);
  }), _vm.response.available.length == 0 ? _c('vb-no-record', {
    attrs: {
      "design": 3,
      "text": _vm.api.play_list.send ? '' : 'Sorry, No Data Founding list'
    }
  }, [_vm.api.play_list.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2) : _vm._e()]), _c('button', {
    staticClass: "IosFullWidthButton forRemove mt-16px mb-16px",
    attrs: {
      "disabled": _vm.api.delete.send
    },
    on: {
      "click": function ($event) {
        return _vm.deletePlayList();
      }
    }
  }, [_vm.api.delete.send ? _c('vb-spinner') : [_vm._v("Delete")]], 2)])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.delete.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Playlist Files")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {}, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "textInsideCard onRightSide"
  }, [_vm._v(_vm._s(_vm.data.name))])])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_vm._l(_vm.selectedList, function (element, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item withBottomBorder"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("check_empty")(element.label, 'None')))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('vb-audio-player', {
      staticClass: "mr-16px IosAudioPlayer",
      attrs: {
        "src": element.sound_file
      }
    })], 1)]);
  }), _vm.selectedList.length == 0 ? _c('vb-no-record', {
    attrs: {
      "design": 3,
      "text": _vm.api.playlists.send ? '' : 'Sorry, No Data Founding list'
    }
  }, [_vm.api.playlists.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }