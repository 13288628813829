var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('h5', {
    staticClass: "d-flex align-items-center justify-content-start ml-4 emp-profile-heading"
  }, [_vm._v("General")]), _c('div', {
    staticClass: "calender-employee-top mt-5"
  }, [_c('div', {
    staticClass: "calender-hrm-height mb-4"
  }, [_c('div', [_c('label', {
    staticClass: "calender-form-labels mr-imp",
    attrs: {
      "for": "input-with-list"
    }
  }, [_vm._v("You company namme")]), _c('div', [_c('b-form-input', {
    staticClass: "form-hrm mt-1",
    attrs: {
      "list": "input-list",
      "placeholder": "Fullduplex"
    }
  })], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels",
    attrs: {
      "for": "input-with-list"
    }
  }, [_vm._v("Phone number")]), _c('div', [_c('b-form-input', {
    staticClass: "form-hrm mt-1",
    attrs: {
      "list": "input-list",
      "placeholder": "441873740607"
    }
  })], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Country")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("Mentor")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Default system language")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("Mentor")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Time zone")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("Mentor")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Date format")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("Mentor")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1)])])]), _c('div', {
    staticClass: "calender-employee-top"
  }, [_c('div', {
    staticClass: "calender-hrm-height mb-3"
  }, [_c('h5', {
    staticClass: "calender-page-subheading"
  }, [_vm._v("Time format")]), _c('div', {}, [_c('b-form-group', {
    staticClass: "gm-hrm-custom-radio"
  }, [_c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "A"
    }
  }, [_vm._v("12-hour(3:00 PM)")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "C"
    }
  }, [_vm._v("24-hour(15:00)")])], 1)], 1)])]), _c('div', {
    staticClass: "calender-employee-top"
  }, [_c('div', {
    staticClass: "calender-hrm-height mb-3"
  }, [_c('h5', {
    staticClass: "calender-page-subheading"
  }, [_vm._v("Number of digits shown after comma")]), _c('div', {
    staticClass: "mb-4"
  }, [_c('b-form-group', {
    staticClass: "gm-hrm-custom-radio"
  }, [_c('div', {
    staticClass: "test-module"
  }, [_c('b-form-radio', {
    staticClass: "tuntun",
    attrs: {
      "name": "some-radios",
      "value": "A"
    }
  }, [_vm._v("None")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "B"
    }
  }, [_vm._v("1(1.2)")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "C"
    }
  }, [_vm._v("2(1.23)")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "C"
    }
  }, [_vm._v("3(1.234)")]), _c('b-form-radio', {
    attrs: {
      "name": "some-radios",
      "value": "C"
    }
  }, [_vm._v("4(1.2345)")])], 1)]), _c('button', {
    staticClass: "btn btn-primarynewupdated1 mr-3"
  }, [_vm._v("Save")])], 1)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }