var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup GenerateTimeOffReportsModal HrmForModalScroll",
    attrs: {
      "clickToClose": true,
      "scrollable": true,
      "name": "AddNewEmployeeDataPosition"
    }
  }, [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Add position")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddNewEmployeeDataPosition');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Position *")]), _c('input', {})]), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Description")]), _c('textarea', {})]), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Position code")]), _c('input', {})]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Add ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }