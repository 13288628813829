<template>
  <div class="crm-main-section DealsSection">
    <div class="d-flex justify-content-between align-items-center w-100 mb-5">
      <h2 class="crm-main-section-heading text-case-unset mb-0">
        Zapier
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </h2>
    </div>
    <div class="crm-card p-32">
      <div class="d-flex justify-content-between align-items-center w-100">
        <h2 class="crm-main-section-heading text-case-unset my-0" style="color:black;">
          Zapier
        </h2>
      </div>
      <div class="d-flex justify-content-center mt-3">
        <vb-icon icon="crm-zapier-icon" width="350px" height="auto"/>
      </div>
      <p class="crm-card-text text-center wd-90 mx-auto my-5">
        Zapier integration is at "Invite Only" and "Testing" stage, we are inviting you to test the integration before it's available for everyone.
        Before this, we need to verify your purchase key and after that we will share the Zapier invite link with you can try it!
      </p>
      <div class="crm-input-container withoutIcon justify-content-center mx-auto d-flex wd-70 mb-0">
        <input class="" style="flex: 1;"/>
        <button class="crm-button ml-2">
          Get Integration Link 
        </button>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GoogleIntegration',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      },
      allowedUploadfileExtentions: 'jpg, jpeg, png, gif, svg, pdf, aac, ogg, oga, mp3, wav, mp4, m4v, mov, ogv, webm, zip, rar, doc, docx, txt, text, xml, json, xls, xlsx, odt, csv, ppt, pptx, ppsx, ics, eml'
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>