<template>
  <modal class="dialer_animation right_side_popup CreateNewPolicyModal-settings HrmForModalScroll" name="CreateNewPolicy"  :clickToClose="true"  :scrollable="true">
    <div class="modalHeading-container">
      <div class="modalHeading">Create New Policy</div>
      <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('CreateNewPolicy')">Close</button>
    </div>

    <div class="modalInner-container">
      <div class="grayCard borderRadius-12px flexColumn px-4">
        <div class="grayCard-smallHeading">Add Policy</div>
        <div class="d-flex mt-25px">
          <div class="innerShadowInput-container halfWidth">
            <label>Policy name</label>
            <input class=""/>
          </div>
          <div class="innerShadowInput-container halfWidth">
            
            <vb-color-picker class="color-picker-input">
            </vb-color-picker>
          </div>
        </div>
        <div class="innerShadowInput-container">
          <label>Time off policy description </label>
          <input class=""/>
        </div>
        <div class="d-flex innerShadowInput-container align-items-center flex-row">
          <label>Allocate </label>
          <input class="wd-68px ml-20px"/>
          <b-dropdown class="hrmInnerShadow-dd w-400px ml-20px" no-caret>
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">days</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All locations
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="d-flex">
          <div class="innerShadowInput-container halfWidth">
            <label>Accure</label>
            <b-dropdown class="hrmInnerShadow-dd" no-caret>
              <template #button-content>
                <div class="button-content">
                  <div class="buttonContentText"></div>
                  <b-icon class="downIcon" icon="caret-down-fill" />
                </div>
              </template>
              <b-dropdown-item>
                Accure
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="innerShadowInput-container halfWidth">
            <label>Duration</label>
            <b-dropdown class="hrmInnerShadow-dd" no-caret>
              <template #button-content>
                <div class="button-content">
                  <div class="buttonContentText"></div>
                  <b-icon class="downIcon" icon="caret-down-fill" />
                </div>
              </template>
              <b-dropdown-item>
                Duration
              </b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </div>

      <div class="grayCard borderRadius-12px flexColumn px-4 mt-32px">
        <div class="whiteSubCard p-26px mb-0">
          <div class="whiteCard-smallHeading">
            What should we consider to determine working days and daily working hours for this policy?
          </div>
          <div class="bodyText text-left mt-16px">
            This will determine days that are considered working days and thus, count against allowance, as well as daily 
            working hours, this value is used to convert days into hours for part day requests.
          </div>

          <b-form-group class="HrmRadio-container mt-32px wd-46">
            <b-form-radio class="HrmRadio" >
              Calendar settings
            </b-form-radio>
            <b-form-radio class="HrmRadio checked">
              working pattern
            </b-form-radio>
          </b-form-group>
          
          <div class="d-flex innerShadowInput-container align-items-center flex-row mt-32px">
            <label>Default working hours</label>
            <input class="wd-68px ml-20px" placeholder="20"/>
          </div>

          <b-form-checkbox class="HrmCheckBox-container mt-16px">
            Advance filters
          </b-form-checkbox>
          <b-form-checkbox class="HrmCheckBox-container ">
            Advance filters
          </b-form-checkbox>
        </div>
      </div>

      <div class="grayCard borderRadius-12px flexColumn px-4 mt-32px">
        <div class="d-flex innerShadowInput-container align-items-center mb-32px flex-row">
          <label class="font-13px">Default working hours </label>
          
          <b-dropdown class="hrmInnerShadow-dd w-220px ml-20px" no-caret>
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">days not carry over</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All locations
            </b-dropdown-item>
          </b-dropdown>
          <label class="ml-20px font-13px">(this change will be applied only on the next yearly reset)</label>
        </div>
        <div class="innerShadowInput-container mb-0">
          <label class="">Do not accrue when taking leave from one of these policies</label>
          <b-dropdown class="hrmInnerShadow-dd w-536px" no-caret>
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText"></div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All locations
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>

      <div class="collapseAble-conatiner mt-32px">
        <div :class="`collapseAble-head ${showCollapsableItems == 'first' ? 'opened' : ''}`" @click="showCollapsableItems = 'first'">
          <div class="collapseAble-headText">General</div>
          <b-icon class="downIcon" icon="chevron-down" font-scale="1.3"/>
        </div>
        <div :class="`collapseAble-body ${showCollapsableItems == 'first' ? 'opened' : ''}`">
          <div class="smallText w-100">0 days used</div>
        </div>
      </div>

      <div class="collapseAble-conatiner mt-32px">
        <div :class="`collapseAble-head ${showCollapsableItems == '3rd' ? 'opened' : ''}`" @click="showCollapsableItems = '3rd'">
          <div class="collapseAble-headText">Conditions</div>
          <b-icon class="downIcon" icon="chevron-down" font-scale="1.3"/>
        </div>
        <div :class="`collapseAble-body ${showCollapsableItems == '3rd' ? 'opened' : ''}`">
          <div class="smallText w-100">0 days used</div>
        </div>
      </div>

      <div class="collapseAble-conatiner mt-32px">
        <div :class="`collapseAble-head ${showCollapsableItems == '4th' ? 'opened' : ''}`" @click="showCollapsableItems = '4th'">
          <div class="collapseAble-headText">Allowance</div>
          <b-icon class="downIcon" icon="chevron-down" font-scale="1.3"/>
        </div>
        <div :class="`collapseAble-body ${showCollapsableItems == '4th' ? 'opened' : ''}`">
          <div class="smallText w-100">0 days used</div>
        </div>
      </div>

      <div class="collapseAble-conatiner mt-32px">
        <div :class="`collapseAble-head ${showCollapsableItems == '5th' ? 'opened' : ''}`" @click="showCollapsableItems = '5th'">
          <div class="collapseAble-headText">Workflow</div>
          <b-icon class="downIcon" icon="chevron-down" font-scale="1.3"/>
        </div>
        <div :class="`collapseAble-body ${showCollapsableItems == '5th' ? 'opened' : ''}`">
          <div class="smallText w-100">0 days used</div>
        </div>
      </div>

      <div class="collapseAble-conatiner mt-32px">
        <div :class="`collapseAble-head ${showCollapsableItems == '6th' ? 'opened' : ''}`" @click="showCollapsableItems = '6th'">
          <div class="collapseAble-headText">Additional fields</div>
          <b-icon class="downIcon" icon="chevron-down" font-scale="1.3"/>
        </div>
        <div :class="`collapseAble-body ${showCollapsableItems == '6th' ? 'opened' : ''}`">
          <div class="smallText w-100">0 days used</div>
        </div>
      </div>

      <button class="dialer-button dialer-button-primary mt-32px mr-0"> Create </button>
      
    </div>
  </modal>
</template>

<script>
export default {
  name: 'CreateNewPolicy',
  data(){
    return {
      showCollapsableItems:'first',
    }
  },
}
</script>

<style>

</style>