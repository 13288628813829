var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Global permissions ")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" These settings applies to everyone, including \"Admin\" level users ")]), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Enabled for everyone:")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Assets ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Onboarding ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Offboarding ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Tasks ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Training ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Documnets ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Emergency contacts ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Notes ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Compensation ")])], 1), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Show tabs in employee profile")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Personal ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Documents ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Emergency ")])], 1), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Show the following fields in employee profiles")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Title ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Nationality ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Employment status ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Work phone ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Driving licence ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Skype ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Martial status ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Home Phone ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Gender ")])], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Save ")])]);

}
var staticRenderFns = []

export { render, staticRenderFns }