var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup developerOptionsModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Features")]), _c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticStyle: {
      "max-height": "calc(100vh - 120px)",
      "overflow-y": "auto",
      "height": "calc(100vh - 120px)"
    }
  }, [_c('Something3')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }