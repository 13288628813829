<template>
  <modal class="dialer_animation right_side_popup EditIndividualAllowanceModal HrmForModalScroll" name="EditIndividualAllowanceModal"  :clickToClose="true"  :scrollable="true">
    <div class="modalHeading-container">
      <div class="modalHeading">Edit Individual allowances</div>
      <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('EditIndividualAllowanceModal')">Close</button>
    </div>
    <div class="modalInner-container">
      <div class="innerShadowInput-container">
        <label>Location</label>
        <b-dropdown class="hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText"></div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            All locations
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="innerShadowInput-container">
        <label>Team</label>
        <b-dropdown class="hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText"></div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            All locations
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="innerShadowInput-container">
        <label>Time off policy</label>
        <b-dropdown class="withTags-hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="cancaleAbleTags-container">
                <div class="cancaleAbleTags">
                  <div class="cancaleAbleTags-text">Holiday</div>
                  <div class="cancaleAbleTags-icon">
                    <vb-icon icon="cancaleableTags-x-hrmIcon" />
                  </div>
                </div>
              </div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item >
            Holiday
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <button class="dialer-button dialer-button-primary mt-32px mr-0"> Genrate </button>
    </div>
  </modal>
</template>

<script>
export default {
  name:'EditIndividualAllowanceModal'
}
</script>

<style>

</style>