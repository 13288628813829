var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-userInfoMenu page text-center w-100 position-relative"
  }, [_c('div', {
    staticClass: "user-info-container"
  }, [_c('div', {
    staticClass: "dialer_contact"
  }, [_c('div', {
    staticClass: "user-profile"
  }, [_c('vb-avatar', {
    attrs: {
      "image": _vm.$store.getters.getCurrentUser.profileImg,
      "status_id": _vm.$store.getters.getCurrentUser.status_id,
      "name": _vm.$store.getters.getCurrentUser.display_name
    }
  })], 1), _c('div', {
    staticClass: "user-info"
  }, [_c('span', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.$store.getters.getCurrentUser.display_name))]), _c('span', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm.$store.getters.getCurrentUser.extn))]), !_vm.$store.getters.isIntegration ? [_c('div', {
    staticClass: "position-relative dialer-organization page opacity-1 dd-opacity-1 mt-4"
  }, [_c('transition', {
    attrs: {
      "name": "slide-in"
    }
  }, [_c('b-dropdown', {
    staticClass: "organization-list",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-status', {
          staticClass: "position-relative",
          attrs: {
            "statusId": _vm.$store.getters.getCurrentUser.status_id
          }
        }), _c('span', {
          staticClass: "ml-1"
        }, [_vm._v(_vm._s(_vm.$store.getters.getCurrentUser.status))]), _c('b-icon', {
          staticClass: "arrow_icon",
          attrs: {
            "icon": "chevron-down",
            "scale": "0.7"
          }
        })];
      },
      proxy: true
    }], null, false, 3832135573)
  }, _vm._l(_vm.$store.state.common.statuses, function (status) {
    return _c('b-dropdown-item', {
      key: status.id,
      on: {
        "click": function ($event) {
          return _vm.$store.dispatch('setStatus', status.id);
        }
      }
    }, [_c('a', [_vm._v(_vm._s(status.status))]), status.id === _vm.$store.getters.getCurrentUser.status_id ? _c('b-icon', {
      style: `color:${status.colour};`,
      attrs: {
        "icon": "check-circle-fill",
        "scale": "0.8"
      }
    }) : _vm._e()], 1);
  }), 1)], 1)], 1)] : _vm._e(), !_vm.$store.getters.isIntegration && _vm.getProperty(_vm.$store.getters.getCurrentUser, 'organizations.length', 0) > 1 ? _c('div', {
    staticClass: "position-relative dialer-organization page"
  }, [_c('transition', {
    attrs: {
      "name": "slide-in"
    }
  }, [_c('b-dropdown', {
    staticClass: "organization-list",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          staticClass: "mr-2",
          attrs: {
            "icon": "building",
            "scale": "0.8"
          }
        }), _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("Select Organization")]), _c('b-icon', {
          staticClass: "arrow_icon",
          attrs: {
            "icon": "chevron-down",
            "scale": "0.7"
          }
        })];
      },
      proxy: true
    }], null, false, 1210984997)
  }, _vm._l(_vm.$store.getters.getCurrentUser.organizations, function (organization, index) {
    return _c('b-dropdown-item', {
      key: index,
      on: {
        "click": function ($event) {
          organization.id === _vm.$store.getters.getCurrentUser.selected_organization_id ? '' : _vm.switchOrganization(organization);
        }
      }
    }, [_c('a', [_vm._v(_vm._s(organization.label))]), _vm.$store.getters.getCurrentUser.selected_organization_id === organization.id ? _c('b-icon', {
      staticClass: "status-1",
      attrs: {
        "icon": "check-circle-fill",
        "scale": "0.8"
      }
    }) : _vm._e()], 1);
  }), 1)], 1)], 1) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('div', {
    staticClass: "position-relative dialer-organization page opacity-1"
  }, [_c('transition', {
    attrs: {
      "name": "slide-in"
    }
  }, [_c('b-dropdown', {
    staticClass: "organization-list",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "mr-2",
          attrs: {
            "icon": "dialer-snoozeIcon",
            "width": "20",
            "height": "20",
            "scale": "0.8"
          }
        }), _c('span', {
          staticClass: "text-capitalize"
        }, [_vm._v("Away")]), _c('b-icon', {
          staticClass: "arrow_icon",
          attrs: {
            "icon": "chevron-down",
            "scale": "0.7"
          }
        })];
      },
      proxy: true
    }], null, false, 3358824210)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.snooze('off');
      }
    }
  }, [_c('a', [_vm._v("Turn it Off")]), _vm.snooze_label == 'off' ? _c('b-icon', {
    staticClass: "status-1",
    attrs: {
      "icon": "check-circle-fill",
      "scale": "0.8"
    }
  }) : _vm._e()], 1), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.snooze('unlimited');
      }
    }
  }, [_c('a', [_vm._v("For unlimited")]), _vm.snooze_label == 'unlimited' ? _c('b-icon', {
    staticClass: "status-1",
    attrs: {
      "icon": "check-circle-fill",
      "scale": "0.8"
    }
  }) : _vm._e()], 1), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.snooze('15 min');
      }
    }
  }, [_c('a', [_vm._v("For 15 mins")]), _vm.snooze_label == '15 min' ? _c('b-icon', {
    staticClass: "status-1",
    attrs: {
      "icon": "check-circle-fill",
      "scale": "0.8"
    }
  }) : _vm._e()], 1), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.snooze('30 min');
      }
    }
  }, [_c('a', [_vm._v("For 30 mins")]), _vm.snooze_label == '30 min' ? _c('b-icon', {
    staticClass: "status-1",
    attrs: {
      "icon": "check-circle-fill",
      "scale": "0.8"
    }
  }) : _vm._e()], 1), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.snooze('45 min');
      }
    }
  }, [_c('a', [_vm._v("For 45 mins")]), _vm.snooze_label == '45 min' ? _c('b-icon', {
    staticClass: "status-1",
    attrs: {
      "icon": "check-circle-fill",
      "scale": "0.8"
    }
  }) : _vm._e()], 1), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CustomeSnoozeModal');
      }
    }
  }, [_c('a', [_vm._v("Set Custom Time")]), _vm.snooze_label == 'custom' ? _c('b-icon', {
    staticClass: "status-1",
    attrs: {
      "icon": "check-circle-fill",
      "scale": "0.8"
    }
  }) : _vm._e()], 1)], 1)], 1)], 1) : _vm._e(), !_vm.$store.getters.isIntegration ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.tab = 'mobile_settings';
      }
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main my-0"
  }, [_vm._v(" Settings ")]), _c('b-icon', {
    attrs: {
      "icon": "chevron-down",
      "rotate": "-90"
    }
  })], 1), _vm.isElectron ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row cursor_pointer_no_hover"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main my-0"
  }, [_vm._v(" Minimize ")]), _c('div', {
    staticClass: "mobileViewCheckBox bg-transparent p-0 w-auto"
  }, [_vm.isElectron ? _c('b-checkbox', {
    staticClass: "minimizedSwitch",
    attrs: {
      "switch": "",
      "value": "mob",
      "unchecked-value": "web"
    },
    model: {
      value: _vm.$store.state.electron.app_view,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.electron, "app_view", $$v);
      },
      expression: "$store.state.electron.app_view"
    }
  }) : _vm._e()], 1)]) : _vm._e()])]) : _vm._e()], 2)]), _c('div', {
    staticClass: "position-absolute fixed-bottom bg-white p-2 w-100",
    staticStyle: {
      "z-index": "1"
    }
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    on: {
      "click": function ($event) {
        return _vm.logout({
          is_router_change: false,
          is_user: true
        });
      }
    }
  }, [_vm.$store.state.logout_running ? _c('b-icon', {
    attrs: {
      "icon": "arrow-clockwise",
      "animation": "spin-pulse",
      "variant": "danger"
    }
  }) : [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "box-arrow-right",
      "scale": "0.8"
    }
  }), _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Logout")])]], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }