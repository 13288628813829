<template>
  <Fragment>
    <div class="d-flex justify-content-center position-relative" style="width: 200px;">
      <div style="position: absolute;left: 50%;top: 50%;transform: translate(-50% , -50%)">If no answer</div>
      <div class="lineWithArrow longLine-150px">
        <div class="lineWithArrow-line"></div>
        <div class="lineWithArrow-arrow"></div>
      </div>
    </div>
    <div class="nodeBasic-new" >
      <div class="d-flex justify-content-between align-items-center mb-8px">
        <div class="nodeBasic-heading mr-3">When calls aren't answered...</div>
        <b-icon 
          icon="people-fill" 
          @click="$modal.show(`${_uid}-CallForwardSettingModal`,{ 
            account, 
            setting: response.forwarding_setting,
            accountcode: forwardCallerId ? account : '',
          })" 
        />
      </div>
      <template v-if="response.forwarding_setting.forward_note == 'off'">
        <div class="selectedText-big">Keep ringing</div>
        <div class="selectedText-small">Call forwarding is off</div>
      </template>
      <template v-if="response.forwarding_setting.forward_note == 'voicemail'">
        <div class="selectedText-big">Ask to leave a voicemail</div>
        <div class="selectedText-small">If phone rings for <span class="latestBlueColor">{{response.forwarding_setting.timeout}} seconds</span> call will be forward to voicemail</div>
      </template>
      <template v-if="response.forwarding_setting.forward_note == 'extension'">
        <div class="selectedText-big">Forward to a user, team or IVR menu</div>
        <div class="selectedText-small">
          If phone rings for 
          <span class="latestBlueColor">{{response.forwarding_setting.timeout}} seconds</span> 
          call will be forward to 
          <span class="latestBlueColor">{{response.forwarding_setting.extension_name}}</span>
        </div>
      </template>
      <template v-if="response.forwarding_setting.forward_note == 'number'">
        <div class="selectedText-big">Forward to another number</div>
        <div class="selectedText-small">
          If phone rings for 
          <span class="latestBlueColor">{{response.forwarding_setting.timeout}} seconds</span> 
          call will be forward to 
          <span class="latestBlueColor">{{ response.forwarding_setting.forward_number | number_formater }}</span>
        </div>
      </template>
    </div>
    <ScheduleAssignModal 
      :modalName="`${_uid}-ScheduleAssignModal`" 
      :account="account"
      @interface="updateForwardingSettings({ 
        extension_name: $event.accountname, 
        forward_number: $event.account, 
      })" 
    />
    <DurationPickerModal :modalName="`${_uid}-DurationPickerModal`" @time="updateForwardingSettings({ timeout: $event.seconds })" />
    <CallForwardingNumberInputModal 
      :modalName="`${_uid}-CallForwardingNumberInputModal`" 
      @number="updateForwardingSettings({ forward_number: $event.ber })" 
    />
    <CallForwardSettingModal 
      :modalName="`${_uid}-CallForwardSettingModal`" 
      @updated="updateForwardingSettings($event)" 
    />
  </Fragment>
</template>

<script>
import { VOIP_API } from '@/utils';
import ScheduleAssignModal from '../../../Modals/ScheduleAssignModal.vue'
import DurationPickerModal from '../../../Modals/DurationPickerModal.vue'
import CallForwardingNumberInputModal from '../../../Modals/CallForwardingNumberInputModal.vue'
import CallForwardSettingModal from '../../../Modals/CallForwardSettingModal.vue'
import { mapGetters } from 'vuex';
import { Fragment } from 'vue-fragment'
export default {
  name: 'ForwardingSettingNode',
  components: {
    ScheduleAssignModal,
    DurationPickerModal,
    CallForwardingNumberInputModal,
    CallForwardSettingModal,
    Fragment,
  },
  props: {
    account: {
      type: String,
      default: "",
    },
    id: {
      type: String,
      default: '',
    },
    forwardCallerId: {
      type: Boolean,
      default: false
    },
  },
  data() {
    return {
      api: {
        route_call: this.$helperFunction.apiInstance(),
        update_route_call: this.$helperFunction.apiInstance({ source: true }),
        reset(){
          this.route_call?.reset?.()
          this.update_route_call?.reset?.()
        },
      },
      response: this.$helperFunction.stateInstance({
        data: {
          forwarding_setting: {
            forward_setting: "",
            forward_note: "",
            forward_number: "",
            timeout: 5,
            extension_name: "",
            number: "",
          },
          allow_recording: false,
          outofhours: false,
          recording: false,
        }
      }),
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
    ]),
  },
  methods: {
    async fetchcallRouteSetting() {
      if(this.api.route_call.send) return;
      try {
        this.api.route_call.send = true;
        const { data } = await VOIP_API.endpoints.users.callroute({
          accountcode: this.account,
        })
        this.response.outofhours = data.schedule_enable;
        this.response.forwarding_setting = data.forwarding_setting;
        this.response.recording = data.recording;
        this.response.allow_recording = data.allow_recording=='1';
      } finally {
        this.api.route_call.send = false;
      }
    },
    async updateForwardingSettings(forward_setting={}){
      this.api.update_route_call.source?.cancel?.("Requesting Again")
      this.api.update_route_call.source = require("axios").default.CancelToken.source();
      this.api.update_route_call.send = true;
      const toaster_value = this.appNotify({
        message: "Saving...",
        type: "success",
        duration: 6 * 1000,
      });
      try {
        await VOIP_API.endpoints.users.updateroutecall({
          accountcode: this.account,
          forward_setting: {
            forward_setting: forward_setting.forward_setting ?? this.response.forwarding_setting.forward_setting,
            forward_note: forward_setting.forward_note ?? this.response.forwarding_setting.forward_note,
            forward_number: forward_setting.forward_number ?? this.response.forwarding_setting.forward_number,
            timeout: forward_setting.timeout ?? this.response.forwarding_setting.timeout,
            extension_name: forward_setting.extension_name ?? this.response.forwarding_setting.extension_name,
            number: forward_setting.number ?? this.response.forwarding_setting.number,
          },
        },this.api.update_route_call.source.token)
        this.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        this.fetchcallRouteSetting()
        this.$emit('cf-updated')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        toaster_value.close();
        this.api.update_route_call.send = false;
        this.api.update_route_call.source = null;
      }
    },
  },
  mounted(){
    this.fetchcallRouteSetting();
  },
}
</script>

<style>

</style>