<template>
<div>
  <div class="mainHeading withButton">
    Employee reports
  </div>
  <div class="mainDescription mb-4">
    Generate employee-specific reports for their personal time off
  </div>
  <div class="dropdownsContainer flex-column align-items-start">
    <b-dropdown class="primaryBorderDropdown big" no-caret>
      <template #button-content>
        <div class="button-content">
          <div class="nameWithImage insideDropdown">
            <div class="imageContainer">
              <img class="circle" :src="require('@/assets/images/credit-card/1.jpeg')" />
            </div>
            <div class="nameWithImage-text">Mellissa</div>
          </div>
          <b-icon class="downIcon" icon="caret-down-fill" />
        </div>
      </template>
      <b-dropdown-item >
        <div class="nameWithImage insideDropdown">
          <div class="imageContainer">
            <img class="circle" :src="require('@/assets/images/credit-card/1.jpeg')" />
          </div>
          <div class="nameWithImage-text">Mellissa</div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item >
        <div class="nameWithImage insideDropdown">
          <div class="imageContainer">
            <img class="circle" :src="require('@/assets/images/credit-card/2.jpeg')" />
          </div>
          <div class="nameWithImage-text">Mellissa</div>
        </div>
      </b-dropdown-item>
      <b-dropdown-item >
        <div class="nameWithImage insideDropdown">
          <div class="imageContainer">
            <img class="circle" :src="require('@/assets/images/credit-card/3.jpeg')" />
          </div>
          <div class="nameWithImage-text">Mellissa</div>
        </div>
      </b-dropdown-item>
    </b-dropdown>
    <b-dropdown no-caret class="primaryBorderDropdown big mt-16px">
      <template #button-content>
        <div class="button-content">
          <div class="buttonContentText">Date Range</div>
          <b-icon class="downIcon" icon="caret-down-fill" />
        </div>
      </template>
      <b-dropdown-item >
        Date Range
      </b-dropdown-item>
    </b-dropdown>
    <button class="dialer-button dialer-button-primary mt-16px mr-0">
      Apply Date Range 
    </button>
    <div class="w-100 d-flex align-items-center justify-content-center mt-5">
      <canvas id="employee-report-chart"></canvas>
    </div>
  </div>
</div>
</template>

<script>
import { HRM } from '@/data'
import Chart from "chart.js";
export default {
  name:'EmployeeReports',
  data() {
    return {
      employeeReportChart: {}
    }
  },
  methods: {
    async barChart() {
      const ctx = document.getElementById("employee-report-chart");
      this.employeeReportChart?.destroy?.();
      this.employeeReportChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: HRM.charts.employeeReports.labels,
          datasets: [
            {
              label: 'Segment 1 (employees 1)',
              data: HRM.charts.employeeReports.data,
              borderColor: '#5576d1',
              backgroundColor: '#5576d1',
              fill: false,
            }
          ]
        },
        options: {
          lineTension: 1,
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          scales: {
            xAxes: [{
                gridLines: {
                  display:false,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Cities'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
            }],
            yAxes: [{
                gridLines: {
                  // display:false,
                  drawBorder: false,
                },
                scaleLabel: {
                  beginAtZero: true,
                  display: true,
                  labelString: 'Average Compensation'
                },
                ticks: {
                  min: 0,
                  max: 400,
                  beginAtZero: true,
                  stepSize: 100,
                },
            }]
          }
        }
      });
      this.employeeReportChart.update();
    },
  },
  mounted(){
    this.barChart()
  }
}
</script>

<style>

</style>