var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Sound File")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addSound();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.add_sound.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_sound.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_sound.name,
      expression: "forms.add_sound.name"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Name",
      "type": "text",
      "disabled": _vm.api.add_sound.send
    },
    domProps: {
      "value": _vm.forms.add_sound.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_sound, "name", $event.target.value);
      }
    }
  }), _vm.forms.add_sound.submitted && _vm.$v.forms.add_sound.name.$invalid || _vm.api.add_sound.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_sound.name.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), _vm._l(_vm.api.add_sound.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.add_sound.send ? '' : _vm.$refs.upload_audio.click();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Upload Recording")]), _vm.forms.add_sound.submitted && _vm.$v.forms.add_sound.file.$invalid || _vm.api.add_sound.validation_errors.file ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_sound.file.required ? _c('span', [_vm._v("* File is required")]) : !_vm.$v.forms.add_sound.file.maxSize ? _c('span', [_vm._v("* File is Maximum size is 5MB")]) : !_vm.$v.forms.add_sound.file.minSize ? _c('span', [_vm._v("* File is Minimum size is 5Kb")]) : _vm._e(), _vm._l(_vm.api.add_sound.validation_errors.file, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.api.add_sound.upload_progress.is_process ? _c('span', {
    staticClass: "mr-8px textInsideCard textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.api.add_sound.upload_progress.percentage) + "%")]) : _vm._e(), _vm.forms.add_sound.file ? _c('vb-audio-player', {
    staticClass: "IosAudioPlayer mr-8px",
    attrs: {
      "src": _vm._f("fileToUrl")(_vm.forms.add_sound.file)
    }
  }) : _vm._e(), _c('vb-icon', {
    attrs: {
      "icon": "uploadProfileImage-onRightSide-IOSIcon",
      "width": "21.562",
      "height": "21.562"
    }
  })], 1)]), _c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "upload_audio",
    attrs: {
      "type": "file",
      "accept": "audio/*",
      "multiple": false
    },
    on: {
      "change": function ($event) {
        _vm.forms.add_sound.file = _vm.getProperty($event, 'target.files[0]', null);
      }
    }
  })]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Shared")])])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "shared_check_box",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.add_sound.send,
      "switch": ""
    },
    model: {
      value: _vm.forms.add_sound.shared,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_sound, "shared", $$v);
      },
      expression: "forms.add_sound.shared"
    }
  })], 1)])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.add_sound.send
    }
  }, [_vm.api.add_sound.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }