var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.is_loading ? _c('loader-main') : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }