<template>
  <div class="content-area d-flex h-100 dialer_main_body">
    <div class="w-100 dialer_main_body_inner QuickStartPage flex-column">
      <div class="leftSide w-100">
        <div class="title-container">
          <div class="title-mainText">Hi {{getCurrentUser.display_name}}!</div>
        </div>
        <div class="body-container">
          <div class="inner-body-container">
            <div class="getStarted-heading">Get started</div>
            <template v-if="getCurrentUser.administrator_account">
              <div :class="`box-grey IOS-like first-child ${steps>=1 ? 'completed' : ''}`">
                <!-- completed -->
                <div class="box-row">
                  <div class="box-item" @click="steps==0 ? $modal.show('AddUserByUserNameFromQuickStart') : ''">
                    <vb-icon v-if="steps>=1" icon="stepCompleted-QuickStartIcon" />
                    <!-- steps>=1 -->
                    <vb-icon v-else icon="firstUser-QuickStartIcon" />
                    <div class="others-container">
                      <div class="bothText-container">
                        <div class="box-itemTextMain bold">Create your first user </div>
                        <div class="box-itemTextSub">Create a classic or IVR number. or port existing numbers</div>
                      </div>
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                </div>
              </div>
              <div :class="`box-grey IOS-like ${steps>=2 ? 'completed' : ''}`">
                <div class="box-row">
                  <div class="box-item" @click="steps==1 ? $modal.show('AddNumbersFromQuickStart') : ''">
                    <vb-icon v-if="steps>=2" icon="stepCompleted-QuickStartIcon" />
                    <!-- steps>=2 -->
                    <vb-icon v-else icon="firstNumber-QuickStartIcon" />
                    <div class="others-container">
                      <div class="bothText-container">
                        <div class="box-itemTextMain bold">Create the first number of your company</div>
                        <div class="box-itemTextSub">Create a classic or IVR number. or port existing numbers</div>
                      </div>
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                </div>
              </div>
              <div :class="`box-grey IOS-like ${steps>=3 ? 'completed' : ''}`">
                <div class="box-row">
                  <div class="box-item" @click="steps==2 ? $modal.show('AddTeamFromQuickStart',{ suggested_name: `team ${voip_teams.length+1}` }) : ''">
                    <vb-icon v-if="steps>=3" icon="stepCompleted-QuickStartIcon" />
                    <!-- steps>=3 -->
                    <vb-icon v-else icon="firstTeam-QuickStartIcon" />
                    <div class="others-container">
                      <div class="bothText-container">
                        <div class="box-itemTextMain bold">Create a team</div>
                        <div class="box-itemTextSub">Create teams and add them to numbers</div>
                      </div>
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                </div>
              </div>
              <div class="box-grey IOS-like">
                <div class="box-row">
                  <div class="box-item" @click="steps==3 ? '' : ''">
                    <vb-icon v-if="steps>=4" icon="stepCompleted-QuickStartIcon" />
                    <!-- steps>=4 -->
                    <vb-icon v-else icon="AddTeamMembers-QuickStartIcon" />
                    <div class="others-container">
                      <div class="bothText-container">
                        <div class="box-itemTextMain bold">Add team members</div>
                        <div class="box-itemTextSub">Invite your team members, add them to teams and numbers</div>
                      </div>
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                    </div>
                  </div>
                </div>
              </div>
            </template>
          </div>
        </div>
      </div>
      <div class="rightSide w-100">
        <div class="title-container">
          <div class="title-mainText">Finding something?</div>
          <div class="title-subText">Don't worry we got it all covered for you.</div>
        </div>
        <div class="body-container">
          <div class="inner-body-container">
            <div class="subTitle">Downloads</div>
            <div class="subText">Get anything from all of our available resources.</div>
            <div class="box-grey firstBox">
              <div class="box-row">
                <div class="box-item">
                  <vb-icon icon="android-QuickStartIcon" />
                  <div class="box-itemTextMain">Android</div>
                </div>
                <div class="box-item">
                  <vb-icon icon="IOS-QuickStartIcon" />
                  <div class="box-itemTextMain">IOS</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-item">
                  <vb-icon icon="chrome-QuickStartIcon" />
                  <div class="box-itemTextMain">Chrome</div>
                </div>
                <div class="box-item">
                  <vb-icon icon="edge-QuickStartIcon" />
                  <div class="box-itemTextMain">Edge</div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-item">
                  <vb-icon icon="windows-QuickStartIcon" />
                  <div class="box-itemTextMain">Windows</div>
                </div>
                <div class="box-item">
                  <vb-icon icon="macOS-QuickStartIcon" />
                  <div class="box-itemTextMain">MacOS</div>
                </div>
              </div>
            </div>

            <div class="subTitle">Need some help?</div>
            <div class="subText">Check out different options for support.</div>
            <div class="box-grey IOS-like">
              <div class="box-row">
                <div class="box-item" @click="$modal.show('KbInfoModal',{ kb_tab: true, })">
                  <vb-icon icon="KnowledgeBase-QuickStartIcon" />
                  <div class="others-container">
                    <div class="bothText-container">
                      <div class="box-itemTextMain">Knowledge base</div>
                      <div class="box-itemTextSub">Guides from our team</div>
                    </div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-item" @click="$modal.show('AddSupportTicketFromQuickStart',{ call_me: true })">
                  <vb-icon icon="RequestCall-QuickStartIcon" />
                  <div class="others-container">
                    <div class="bothText-container">
                      <div class="box-itemTextMain">Request a call</div>
                      <div class="box-itemTextSub">Guides from our team</div>
                    </div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
              </div>
              <div class="box-row">
                <div class="box-item" @click="$modal.show('AddSupportTicketFromQuickStart')">
                  <vb-icon icon="CreateSupportTicket-QuickStartIcon" />
                  <div class="others-container">
                    <div class="bothText-container">
                      <div class="box-itemTextMain">Create a support ticket</div>
                      <div class="box-itemTextSub">Guides from our team</div>
                    </div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddSupportTicketModal :modalName="'AddSupportTicketFromQuickStart'" />
    <AddUserByUserName :modalName="`AddUserByUserNameFromQuickStart`" @user-added="fetchUsers()" />
    <AddNumbersModal :modalName="`AddNumbersFromQuickStart`" @interface="fetchNumbers()" />
    <AddTeamModal :modalName="`AddTeamFromQuickStart`" @interface="fetchTeams()" />
  </div>
</template>

<script>
import AddNumbersModal from "../components/Modals/AddNumbersModal.vue";
import AddSupportTicketModal from "../components/Modals/AddSupportTicketModal.vue";
import AddUserByUserName from "../components/Modals/AddUserByUserName.vue";
import AddTeamModal from "../components/Modals/AddTeamModal.vue";
import { mapGetters } from 'vuex'
import _ from 'lodash';
export default {
  name: 'QuickStart',
  components: {
    AddSupportTicketModal,
    AddUserByUserName,
    AddNumbersModal,
    AddTeamModal,
  },
  inject: [
    'getVoipUsers',
    'getNumbers',
    'getVoipTeams',
  ],
  computed: {
    ...mapGetters({
      getCurrentUser: 'getCurrentUser',
      getVoipNumbers: 'getVoipNumbers',
      voip_teams: 'getVoipTeams',
      voip_users: 'getVoipUsers',
    }),
    steps(){
      if(this.voip_teams.length>1) return 3
      else if(this.getVoipNumbers.length>1) return 2
      else if(Object.values(this.voip_users).length>1) return 1
      else return 0
    }
  },
  methods: {
    fetchUsers: _.debounce(function(){
      this.getVoipUsers()
    },3*1000),
    fetchNumbers: _.debounce(function(){
      this.getNumbers()
    },3*1000),
    fetchTeams: _.debounce(function(){
      this.getVoipTeams()
    },3*1000),
  },
}
</script>

<style>

</style>