
import { VOIP_API } from "../index"

export const SERVICES_API_ENDPOINTS = {
  list(uid='',params={}){
    return VOIP_API.axios.voip.request({
      url: `services/${uid}`,
      method: "GET",
      params,
    })
  },
}