<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-800 editTeamModal" 
      width="40%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <section class="ct-user-new dialer-edit pb-4">
        <div class="dialer-edit-header d-block">
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="dialer-edit-title newer d-flex align-items-center">
              <!-- <vb-svg @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" /> -->
              <button class="newBackButton mr-16px" @click="$modal.hide(modalName)">
                <b-icon icon="arrow-left-circle" />
                <span class="newBackButton-textpart">Back</span>
              </button>
              Share caller ids
            </h2>
            <div class="dialer-edit-actions d-flex align-items-center">
              <!-- <button class="newCloseButton" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </button> -->
              <button class="newDoneButton ml-16px" @click="assignCallerids();$modal.hide(modalName)">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </button>
            </div>
          </div>
          <div class="vm--modal-text">Outgoing calls only. By selecting from the numbers below, the user can present the chosen number as the caller ID to the recipient.</div>
        </div>
        <div class="latestGreyBox-9-9-2023">
          <div class="latestGreyBox-heading-main">Available numbers</div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-12px">
            <template v-if="api.fetch_callerids.send">
              <div class="whiteBoxWithBorderInsideGreyBox-row" v-for="n in 3" :key="n.id">
                <div class="d-flex align-items-center w-50">
                  <div class="latestShimmerDesign mr-2" style="height: 35px;width: 35px;"></div>
                  <div class="latestShimmerDesign" style="height: 21px;width: 40%;"></div>
                </div>
                <div class="latestShimmerDesign" style="height: 24px;width: 24px;"></div>
              </div>
            </template>
            <template v-else>
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset" v-for="number in numbers" :key="number.id">
                <b-form-checkbox class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding "
                  :disabled="api.assign_callerids.send"
                  :checked="forms.assign_callerids.numbers[number.did] ? true : false" 
                  @change="
                    forms.assign_callerids.numbers[number.did] ? 
                      $delete(forms.assign_callerids.numbers,number.did) :
                      $set(forms.assign_callerids.numbers,number.did,number.did) 
                  "
                >
                  <div class="d-flex align-items-center">
                    <img class="country-img mr-2" style="border-radius:8px;" width="45px" :src="number.did | number_formater | flag_icon"/>
                    <span class="m-0 didNumber">{{ number.did | number_formater }}</span>
                  </div>
                </b-form-checkbox>
              </div>
            </template>
          </div>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { VOIP_API } from '@/utils'
export default {
  name: 'AssignCallerIdsModal',
  props: {
    modalName: {
      type: String,
      default: 'AssignCallerIds'
    },
  },
  data() {
    return {
      api: {
        fetch_callerids: this.$helperFunction.apiInstance(),
        assign_callerids: this.$helperFunction.apiInstance(),
      },
      forms: {
        assign_callerids: this.$helperFunction.formInstance({
          data: {
            numbers: {},
          }
        }),
      },
      response: {
        callerids: {},
      },
      data: {
        accountcode: '',
      },
    }
  },
  inject: [
    'appNotify',
  ],
  validations: {
    forms: {
      assign_callerids: {
        numbers: {
          required(value) { return !_.isEmpty(value) },
        }
      },
    },
  },
  computed: {
    numbers() {
      return [...(this.response.callerids?.available ?? []),...(this.response.callerids?.selected ?? [])] 
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.accountcode = event?.params?.accountcode ?? ''
      this.fetchCallerids()
    },
    onBeforeClose() {
      this.api.fetch_callerids.reset()
      this.api.assign_callerids.reset()
      this.forms.assign_callerids.reset()
      this.response.callerids={}
      this.data.accountcode=''
    },
    async fetchCallerids(){
      if(this.api.fetch_callerids.send) return;
      try {
        this.api.fetch_callerids.send=true
        const { data } = await VOIP_API.endpoints.callerid.getCalleridsByAccountcode({
          accountcode: this.data.accountcode,
        })
        this.response.callerids=data
        this.forms.assign_callerids.numbers=_.keyBy(_.map(data?.selected, 'did'))
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_callerids.send=false
      }
    },
    async assignCallerids(){
      this.forms.assign_callerids.submitted=true
      this.$v.forms.assign_callerids.$touch()
      if(this.api.assign_callerids.send) return;
      try {
        // if(this.$v.forms.assign_callerids.$invalid) throw new Error('Atleast select one number')
        if(this.$v.forms.assign_callerids.$invalid) throw new Error('One outgoing number has to be assigned to a user')
        this.api.assign_callerids.send=true
        await VOIP_API.endpoints.callerid.assignCallerIds({
          accountcode: this.data.accountcode,
          numbers: this.forms.assign_callerids.numbers,
        })
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success'
        })
        this.$emit('updated')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.assign_callerids.send=false
        this.forms.assign_callerids.submitted=false
      }
    },
  },
}
</script>

<style>

</style>