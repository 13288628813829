<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Availability</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="lg-mar-bottom">
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" @click="updateSchedule({ type: 'availble' })">
              <div class="headingInsideCard">Available all day</div>
              <vb-icon v-if="data.type=='availble'" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            </div>
            <div class="basicWhiteIOScard-item" @click="updateSchedule({ type: 'unavailable' })">
              <div class="headingInsideCard">Unavailable all day</div>
              <vb-icon v-if="data.type=='unavailable'" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            </div>
            <div class="basicWhiteIOScard-item" @click="$modal.show(`${randomID}-TimeSelectionModal`,{ start: data.start, end: data.end })">
              <div class="d-flex flex-column">
                <div class="headingInsideCard">Unavailable between</div>
                <div v-if="data.type=='custom'">
                  <span class="text-decoration-underline">{{ data.start }} - {{ data.end }}</span>
                </div>
              </div>
              <vb-icon v-if="data.type=='custom'" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            </div>
          </div>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideCard">Use Default</div>
              <div class="position-relative">
                <b-form-checkbox 
                  class="dialer-switch IosSwitch" 
                  ref="default-toggle" 
                  :checked="forwardSetting.is_default" 
                  @change="updateSchedule({ default: $event })"
                  name="check-button"
                  switch
                />
              </div>
            </div>
            <div 
              v-if="!forwardSetting.is_default" 
              class="basicWhiteIOScard-item" 
              @click="$modal.show(`${randomID}-CallForwardSettingModal`,{ 
                account, 
                setting: forwardSetting, 
                is_ringing_show: selectedSchedule.type=='availble', 
                is_sec_show: false 
              })"
            >
              <template v-if="forwardSetting.forward_note == 'voicemail'">
                <div class="headingInsideCard">Ask to leave a voicemail</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">leave a voicemail</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </template>
              <template v-else-if="forwardSetting.forward_note == 'extension'">
                <div class="headingInsideCard">Redirect to a user, team or IVR menu</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forwardSetting.extension_name }} </div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </template>
              <template v-else-if="forwardSetting.forward_note == 'number'">
                <div class="headingInsideCard">Redirect to another number</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forwardSetting.forward_number }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </template>
              <template v-else>
                <div class="headingInsideCard">Forwarding</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">Keep ringing </div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </template>
            </div>
          </div>
        </div>
        <CallForwardingModal :modalName="`${randomID}-CallForwardSettingModal`" @updated="updateSchedule($event)" />
        <StartAndEndTimePickerModal 
          :modalName="`${randomID}-TimeSelectionModal`" 
          @updated="
            data.start=`${$event.start.data.HH}:${$event.start.data.mm}:${'00'}`;
            data.end=`${$event.end.data.HH}:${$event.end.data.mm}:${'00'}`;
            updateSchedule({ start: $event.start, end: $event.end, type: 'custom' })
          " 
        />
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import CallForwardingModal from "../callforwarding/CallForwarding.vue";
import StartAndEndTimePickerModal from "../common/StartAndEndTimePicker.vue";
export default {
  name: 'EditOutOfHoursDayModal',
  components: {
    CallForwardingModal,
    StartAndEndTimePickerModal,
  },
  props: {
    modalName: {
      type: String,
      default: "EditOutOfHoursDay",
    },
    account: {
      type: String,
      default: '',
    },
    schedules: {
      type: Array,
      default: ()=>[],
    },
    randomID: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      data: this.$helperFunction.stateInstance({
        data: {
          day: '',
          start: '',
          end: '',
          type: '',
        }
      }),
      api: {
        update_schedule: this.$helperFunction.apiInstance({ source: true }),
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    selectedSchedule(){
      let schedule = this.schedules?.find?.(i=>i.day==this.data.day);
      return {
        day: this.data.day,
        forward_name: schedule?.forward_name ?? "",
        forward: schedule?.forward_type ?? "none",
        start: schedule?.start_time ?? "",
        end: schedule?.end_time ?? "",
        type: schedule?.type ?? "availble",
        ext_type: "",
        forward_account: schedule?.forward_account ?? "",
        extn: schedule?.extn ?? "",
        setting_type: schedule?.setting_type ?? "",
      }
    },
    forwardSetting(){
      return {
        extension_name: this.selectedSchedule.forward_name ?? '',
        forward_note: this.selectedSchedule.forward ?? 'none',
        forward_number: this.selectedSchedule.forward_account ?? '',
        forward_setting: this.selectedSchedule.forward_account ?? '',
        number: this.selectedSchedule.forward_account ?? '',
        timeout: "0",
        is_default: this.selectedSchedule.setting_type == 'default'
      }
    },
  },
  methods: {
    onBeforeOpen(event){
      let vm = this
      this.data.day=event?.params?.day ?? ''
      this.$nextTick(()=>[
        vm.data.start = vm.selectedSchedule.type=='custom' ? vm.selectedSchedule.start.displayTime || vm.selectedSchedule.start || '' : '', 
        vm.data.end = vm.selectedSchedule.type=='custom' ? vm.selectedSchedule.end.displayTime || vm.selectedSchedule.end || '' : '', 
        vm.data.type = vm.selectedSchedule.type,
      ])
    },
    onBeforeClose(){
      this.data?.reset?.()
      this.api.update_schedule.reset()
    },
    updateSchedule(setting={}) {
      let vm = this;
      vm.api.update_schedule.send = true;
      const toaster_value = vm.appNotify({
        message: "Schedule setting Saving...",
        type: "success",
        duration: 60 * 1000,
      });
      const type = setting?.type ?? vm.selectedSchedule.type
      const forward = setting?.forward_note=='off' ? 'none' : setting?.forward_note ?? vm.selectedSchedule.forward
      const update_forward = ['custom','unavailable'].includes(type) && forward=='none' ? 'voicemail' : ['availble'].includes(setting?.type) && forward!='none'? 'none' : forward
      VOIP_API.endpoints.users.udpateschedule({
        account: vm.account,
        day: vm.data.day,
        extn: setting?.forward_note ?? vm.selectedSchedule.extn,
        forward: update_forward,
        forward_account: setting?.forward_number ?? vm.selectedSchedule.forward_account,
        forward_name: setting?.extension_name ?? vm.selectedSchedule.forward_name,
        ext_type: update_forward,
        start: setting?.start?.data ?? (vm.selectedSchedule.start ? {HH:vm.selectedSchedule.start?.split?.(':')?.[0] ?? '',mm:vm.selectedSchedule.start?.split?.(':')?.[1] ?? ''} : ''),
        end: setting?.end?.data ?? (vm.selectedSchedule.end ? {HH:vm.selectedSchedule.end?.split?.(':')?.[0] ?? '',mm:vm.selectedSchedule.end?.split?.(':')?.[1] ?? ''} : ''),
        type: setting?.type ?? vm.selectedSchedule.type,
        flag: setting?.default ? 'default' : 'custom'
      })
      .then(({ data, }) => {
        vm.data.type=setting?.type ?? vm.data.type
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        vm.$emit('updated',data?.data ?? []);
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        vm.$refs['default-toggle'].localChecked=vm.forwardSetting.is_default
      })
      .finally(() => {
        toaster_value.close();
        vm.api.update_schedule.send = false;
      });
    },
  },
}
</script>

<style>

</style>