var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" General "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-voipbusiness-darkLogo-icon",
      "height": "57px",
      "width": "47px"
    }
  }), _c('button', {
    staticClass: "newButton"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [_vm._v(" Update Image ")])], 1)], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_vm._m(1), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-2"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-voipbusiness-lightLogo-icon",
      "height": "57px",
      "width": "47px"
    }
  }), _c('button', {
    staticClass: "newButton"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart position-relative d-flex justify-content-center"
  }, [_vm._v(" Update Image ")])], 1)], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('div', {
    staticClass: "crm-card-heading my-0"
  }, [_vm._v("Currency")]), _c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex justify-content-between w-100 align-items-center p-0"
        }, [_c('span', [_vm._v("PKR")]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" PKR ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" GBP ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" USD ")])])], 1)], 1), _c('div', {
    staticClass: "crm-card-heading mb-0 mt-4"
  }, [_vm._v("System Email Account")]), _c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex justify-content-between w-100 align-items-center p-0"
        }, [_c('span', [_vm._v("system@system.com")]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" system@system.com ")])], 1), _c('b-dropdown-item', [_c('span', [_vm._v(" system@system.com ")])]), _c('b-dropdown-item', [_c('span', [_vm._v(" system@system.com ")])])], 1)], 1), _c('p', {
    staticClass: "crm-card-text wd-70"
  }, [_vm._v(" Select Inbox connected email account that will be used to send system related mails like user assigned to contact, activity due reminder, user invites, etc... ")]), _c('div', {
    staticClass: "crm-card-heading mt-0"
  }, [_vm._v("Allowed upload extensions")]), _c('div', {
    staticClass: "crm-input-container mb-0"
  }, [_c('textarea', {
    staticClass: "w-100",
    attrs: {
      "placeholder": _vm.allowedUploadfileExtentions,
      "rows": "5"
    }
  })]), _c('p', {
    staticClass: "crm-card-text"
  }, [_vm._v(" Enter coma separated extensions to be whitelisted for file storage. ")])]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_vm._m(2), _c('b-form-checkbox', {
    ref: "something",
    staticClass: "newerSwitch",
    attrs: {
      "name": "check-button",
      "switch": ""
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('div', {
    staticClass: "crm-toaster-relative"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-info-icon",
      "height": "19px",
      "width": "19px"
    }
  }), _c('p', {
    staticClass: "crm-card-text w-100 my-0"
  }, [_vm._v(" Updating these settings won't affect your user account settings as these settings are general, update the same settings in your user account instead if you are looking to update these options. ")])], 1), _c('div', {
    staticClass: "crm-card-heading mb-0 mt-4"
  }, [_vm._v("Date Format")]), _c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex justify-content-between w-100 align-items-center p-0"
        }, [_c('span', [_vm._v("d-m-Y")]), _c('b-icon-chevron-down', {
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" d-m-Y ")])], 1)], 1)], 1), _c('div', {
    staticClass: "crm-card-heading mb-0 mt-4"
  }, [_vm._v("Time Format")]), _c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex justify-content-between w-100 align-items-center p-0"
        }, [_c('span', [_vm._v("H: [19:13]")]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" H: [19:13] ")])], 1)], 1)], 1), _c('div', {
    staticClass: "crm-card-heading mb-0 mt-4"
  }, [_vm._v("Week Starts On")]), _c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex justify-content-between w-100 align-items-center p-0"
        }, [_c('span', [_vm._v("Sunday")]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Sunday ")])], 1)], 1)], 1), _c('button', {
    staticClass: "newDoneButton smaller mt-20px",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Save")])], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Company Information ")]), _c('div', {
    staticClass: "crm-card-heading mb-0 mt-4"
  }, [_vm._v("Company Name")]), _c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex justify-content-between w-100 align-items-center p-0"
        }, [_c('span', [_vm._v("Company Name")]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Company Name ")])], 1)], 1)], 1), _c('div', {
    staticClass: "crm-card-heading mb-0 mt-4"
  }, [_vm._v("Country")]), _c('div', {
    staticClass: "crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck mt-12px wd-62"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex justify-content-between w-100 align-items-center p-0"
        }, [_c('span', [_vm._v("Country")]), _c('b-icon-chevron-down', {
          staticClass: "ml-2",
          attrs: {
            "scale": "1.1"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v(" Country ")])], 1)], 1)], 1), _c('button', {
    staticClass: "newDoneButton smaller mt-20px",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Save")])], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Privacy Policy ")]), _c('div', {
    staticClass: "crm-input-container mt-5 mb-0"
  }, [_c('textarea', {
    staticClass: "w-100",
    attrs: {
      "placeholder": _vm.allowedUploadfileExtentions,
      "rows": "5"
    }
  })]), _c('p', {
    staticClass: "crm-card-text"
  }, [_vm._v(" If you don't have privacy policy, you can configure one here, view the privacy policy at the following URL: https://demo.concordcrm.com/privacy-policy ")]), _c('button', {
    staticClass: "newDoneButton smaller",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Save")])], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "crm-card-heading my-0"
  }, [_vm._v("Dark Logo")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "crm-card-heading my-0"
  }, [_vm._v("Light Logo")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column wd-70"
  }, [_c('div', {
    staticClass: "crm-card-heading my-0"
  }, [_vm._v("Require calling prefix on phone numbers")]), _c('p', {
    staticClass: "crm-card-text w-100 mb-0"
  }, [_vm._v(" Most call integrations are requiring phone numbers to be in E.164 format. Enabling this option will make sure that no phone numbers are entered without a country-specific calling prefix. ")])]);

}]

export { render, staticRenderFns }