var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Menu option")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Streamline caller experience by customizing menus for efficient call handling")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox flex-column withAdialerInside mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "dialpadInsideEditIVRmodal"
  }, [_vm._l(_vm.keypad, function (key) {
    return [_c('vb-icon', {
      key: key.value,
      class: `${_vm.selectedIVROptions[key.value] ? 'active' : ''}`,
      attrs: {
        "icon": key.icon,
        "id": `id-${key.value}`
      },
      on: {
        "mouseover": function ($event) {
          _vm.$refs[`${key.value}-key-option`] ? _vm.$refs[`${key.value}-key-option`][0].classList.add('heighLightThisRow') : '';
        },
        "mouseleave": function ($event) {
          _vm.$refs[`${key.value}-key-option`] ? _vm.$refs[`${key.value}-key-option`][0].classList.remove('heighLightThisRow') : '';
        },
        "click": function ($event) {
          _vm.api.update_extension.send || _vm.api.reset_extension.send ? '' : _vm.$modal.show(`${_vm._uid}-MenuOptionExtensionAssignModal`, {
            account: _vm.getProperty(_vm.selectedIVROptions[key.value], 'ivr_cmd_args'),
            type: 'setting',
            dtmf: key.value,
            schedule_id: _vm.schedule.id,
            accountcode: _vm.accountcode,
            ringGroupMembers: _vm.getProperty(_vm.selectedIVROptions[key.value], 'ringGroupMembers') || [],
            ringgroup_id: _vm.getProperty(_vm.selectedIVROptions[key.value], 'ringgroup_id') || '',
            user_mode: _vm.getProperty(_vm.selectedIVROptions[key.value], 'user_mode') || 0
          });
        }
      }
    }), _c('b-popover', {
      key: `${key.value}-popover`,
      attrs: {
        "target": `id-${key.value}`,
        "placement": "top",
        "triggers": "hover focus"
      }
    }, [_vm.selectedIVROptions[key.value] ? [_vm.selectedIVROptions[key.value].ivr_cmd_args == _vm.selectedIVROptions[key.value].ringgroup_id ? _c('div', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.selectedIVROptions[key.value], 'ringGroupMembers.length', 0)) + " users ")]) : _c('Info', {
      attrs: {
        "is_blf": false,
        "id": _vm.selectedIVROptions[key.value][0],
        "type_show": true,
        "name": _vm.selectedIVROptions[key.value].accountname,
        "extension_type": _vm.selectedIVROptions[key.value].accounttype
      }
    })] : [_vm._v("Add tag")]], 2)];
  })], 2), _c('div', {
    staticClass: "newIVRLayout-advanceOptionsTable"
  }, [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "isListEmpty": Object.keys(_vm.selectedIVROptions).length == 0 && !_vm.conditions.add_new_menu,
      "textNoRecord": "No option selected",
      "listLength": _vm.selectedIVROptions.length,
      "conditions": {
        pagination: {
          show: false
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_vm._l(_vm.selectedIVROptions, function (option) {
          return _c('tr', {
            key: option.id,
            ref: `${option.user_dtmf}-key-option`,
            refInFor: true
          }, [_c('td', {
            staticClass: "border-0"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('span', {
            staticClass: "user_dtmf-iconType-before"
          }, [_vm._v("Press")]), _c('span', {
            staticClass: "user_dtmf-iconType"
          }, [_vm._v(_vm._s(option.user_dtmf))]), _c('span', {
            staticClass: "user_dtmf-iconType-after"
          }, [_vm._v("for")])])]), _c('td', {
            staticClass: "border-0"
          }, [_c('div', {
            staticClass: "d-flex align-items-center justify-content-between"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('vb-icon', {
            staticClass: "extentionType-icon",
            attrs: {
              "icon": _vm._f("filterExtentionTypeIcon")(option.accounttype)
            }
          }), option.ivr_cmd_args == option.ringgroup_id ? _c('div', [_vm._v(" " + _vm._s(option.ringGroupMembers.length) + " users ")]) : [_vm._v(_vm._s(option.accountname))]], 2), _c('span', {
            class: `accounttype-tag ${option.accounttype}`
          }, [_vm._v(_vm._s(option.accounttype))])])]), _c('td', {
            staticClass: "border-0"
          }, [_c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD buttonOnRight buttonHeightAuto"
          }, [_c('b-dropdown', {
            attrs: {
              "right": "",
              "disabled": _vm.api.update_extension.send || _vm.api.reset_extension.send
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical",
                    "scale": "1.5"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            on: {
              "click": function ($event) {
                return _vm.$modal.show(`${_vm._uid}-MenuOptionExtensionAssignModal`, {
                  account: option.ivr_cmd_args,
                  type: 'setting',
                  dtmf: option.user_dtmf,
                  schedule_id: _vm.schedule.id,
                  accountcode: _vm.accountcode,
                  ringGroupMembers: option.ringGroupMembers || [],
                  ringgroup_id: option.ringgroup_id || '',
                  user_mode: option.user_mode || 0
                });
              }
            }
          }, [_vm._v(" Edit ")]), option.ringgroup_id ? _c('b-dropdown-item', {
            on: {
              "click": function ($event) {
                return _vm.$modal.show(`${_vm._uid}-IVRMenuRingGroupSettingModal`, {
                  account: option.ivr_cmd_args,
                  type: 'setting',
                  dtmf: option.user_dtmf,
                  schedule_id: _vm.schedule.id,
                  accountcode: _vm.accountcode,
                  ringGroupMembers: option.ringGroupMembers || [],
                  ringgroup_id: option.ringgroup_id || '',
                  user_mode: option.user_mode || 0
                });
              }
            }
          }, [_vm._v(" Config ")]) : _vm._e(), _c('b-dropdown-item', {
            on: {
              "click": function ($event) {
                return _vm.resetIvrOption(option);
              }
            }
          }, [_vm._v("Delete")])], 1)], 1)])]);
        }), _c('tr', {
          directives: [{
            name: "show",
            rawName: "v-show",
            value: _vm.conditions.add_new_menu,
            expression: "conditions.add_new_menu"
          }]
        }, [_c('td', {
          attrs: {
            "colspan": "3"
          }
        }, [_c('div', {
          staticClass: "d-flex align-items-center justify-content-between"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('div', {
          staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion withGreyButton"
        }, [_c('vb-select', {
          staticClass: "right-0",
          attrs: {
            "disabled": _vm.api.update_extension.send || _vm.api.reset_extension.send,
            "selected": _vm.selected.option,
            "defaultSelectedText": 'Select a button',
            "options": _vm.avaliableOptions
          },
          on: {
            "change": function ($event) {
              _vm.selected.option = $event;
            }
          }
        })], 1), _c('div', {
          staticClass: "addnewMenuOptions-Text ml-32px",
          attrs: {
            "disabled": !_vm.selected.option
          },
          on: {
            "click": function ($event) {
              _vm.api.update_extension.send || _vm.api.reset_extension.send ? '' : _vm.$modal.show(`${_vm._uid}-MenuOptionExtensionAssignModal`, {
                account: '',
                type: 'setting',
                dtmf: _vm.selected.option,
                schedule_id: _vm.schedule.id,
                accountcode: _vm.accountcode,
                ringGroupMembers: [],
                ringgroup_id: '',
                user_mode: 0
              });
            }
          }
        }, [_vm._v(" Select user, team, queue or IVR ")])]), _c('vb-icon', {
          staticClass: "circularCross-icon",
          attrs: {
            "icon": "circularCross-icon",
            "width": "29.25px",
            "height": "29.25px"
          },
          on: {
            "click": function ($event) {
              _vm.api.update_extension.send || _vm.api.reset_extension.send ? '' : _vm.conditions.add_new_menu = false;
            }
          }
        })], 1)])])];
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("MENU OPTION")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("ASSIGNED TO")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_c('br')])])])]), !_vm.conditions.add_new_menu ? _c('div', {
    staticClass: "addnewMenuOptions-Text",
    on: {
      "click": function ($event) {
        _vm.api.update_extension.send || _vm.api.reset_extension.send ? '' : _vm.conditions.add_new_menu = true;
      }
    }
  }, [_vm._v(" Add new menu option ")]) : _vm._e()], 1)])])]), _c('MenuOptionExtensionAssignModal', {
    attrs: {
      "modalName": `${_vm._uid}-MenuOptionExtensionAssignModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.$emit(`updated`, {
          menu: $event.menu
        });
      }
    }
  }), _c('IVRMenuRingGroupSettingModal', {
    attrs: {
      "modalName": `${_vm._uid}-IVRMenuRingGroupSettingModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.$emit(`updated`, {
          menu: $event.menu
        });
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }