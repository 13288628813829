var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "sec-common"
  }, [_vm._m(0), _vm._m(1), _c('div', {
    staticClass: "mar-top-hrm25"
  }, [_c('div', {
    staticClass: "hrm-bg-accordian flex-column justify-content-start align-item-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Holiday")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.holidnl-1",
      modifiers: {
        "holidnl-1": true
      }
    }],
    staticClass: "togg-btn-report",
    attrs: {
      "variant": "primary"
    }
  }, [_c('svg', {
    staticClass: "svgtoggle",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.46",
      "height": "9.926",
      "viewBox": "0 0 17.46 9.926"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15893",
      "data-name": "Group 15893",
      "transform": "translate(-54.046 -5.227)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15891",
      "data-name": "Group 15891",
      "transform": "translate(55.46 6.64)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_41822",
      "data-name": "Path 41822",
      "d": "M13996.459,9709.063l7.113,6.908",
      "transform": "translate(-13996.459 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_41823",
      "data-name": "Path 41823",
      "d": "M14003.77,9709.063l-7.311,7.1",
      "transform": "translate(-13989.138 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])])])])], 1), _c('b-collapse', {
    staticClass: "w-100",
    attrs: {
      "id": "holidnl-1"
    }
  }, [_c('b-card', {
    staticClass: "report-cards"
  }, [_c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Working hours per day: 8.0")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Annually allowed: 20 days")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Currently available: 8.87 days")])]), _c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Applied transactions")]), _c('vb-table', {
    staticClass: "reports-table-width",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth emp-reports-firsttd col-left"
  }, [_vm._v("created")]), _c('h5', {
    staticClass: "commonth emp-reports-secondtd"
  }, [_vm._v("created by")]), _c('h5', {
    staticClass: "commonth emp-reports-thirdtd"
  }, [_vm._v("time off requested")]), _c('h5', {
    staticClass: "commonth emp-reports-fourtd"
  }, [_vm._v("note")]), _c('h5', {
    staticClass: "commonth emp-reports-fivetd"
  }, [_vm._v("action")]), _c('h5', {
    staticClass: "commonth emp-reports-sixtd"
  }, [_vm._v("days")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "emp-reports-firsttd"
  }, [_vm._v(" 02/03/2022 - \"Current\" ")]), _c('td', {
    staticClass: "emp-reports-secondtd"
  }, [_vm._v(" System ")]), _c('td', {
    staticClass: "emp-reports-thirdtd"
  }, [_vm._v(" N/A ")]), _c('td', {
    staticClass: "emp-reports-fourtd"
  }, [_vm._v(" Yearly accural ")]), _c('td', {
    staticClass: "emp-reports-fivetd"
  }, [_vm._v(" increase ")]), _c('td', {
    staticClass: "emp-reports-sixtd"
  }, [_vm._v(" 8.87 ")])])])], 2)], 1)], 1)]), _c('div', {
    staticClass: "mar-top-hrm25"
  }, [_c('div', {
    staticClass: "hrm-bg-accordian flex-column justify-content-start align-item-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Holiday-UK")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.Sickday-1",
      modifiers: {
        "Sickday-1": true
      }
    }],
    staticClass: "togg-btn-report",
    attrs: {
      "variant": "primary"
    }
  }, [_c('svg', {
    staticClass: "svgtoggle",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.46",
      "height": "9.926",
      "viewBox": "0 0 17.46 9.926"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15893",
      "data-name": "Group 15893",
      "transform": "translate(-54.046 -5.227)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15891",
      "data-name": "Group 15891",
      "transform": "translate(55.46 6.64)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_41822",
      "data-name": "Path 41822",
      "d": "M13996.459,9709.063l7.113,6.908",
      "transform": "translate(-13996.459 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_41823",
      "data-name": "Path 41823",
      "d": "M14003.77,9709.063l-7.311,7.1",
      "transform": "translate(-13989.138 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])])])])], 1), _c('b-collapse', {
    staticClass: "w-100",
    attrs: {
      "id": "Sickday-1"
    }
  }, [_c('b-card', {
    staticClass: "report-cards"
  }, [_c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Working hours per day: 8.0")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Annually allowed: 20 days")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Currently available: 8.87 days")])]), _c('h5', {
    staticClass: "accordian-togg-text mar-top-hrm30 mar-bottom-hrm10"
  }, [_vm._v("Applied transactions")]), _c('vb-table', {
    staticClass: "reports-table-width",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth emp-reports-firsttd col-left"
  }, [_vm._v("created")]), _c('h5', {
    staticClass: "commonth emp-reports-secondtd"
  }, [_vm._v("created by")]), _c('h5', {
    staticClass: "commonth emp-reports-thirdtd"
  }, [_vm._v("time off requested")]), _c('h5', {
    staticClass: "commonth emp-reports-fourtd"
  }, [_vm._v("note")]), _c('h5', {
    staticClass: "commonth emp-reports-fivetd"
  }, [_vm._v("action")]), _c('h5', {
    staticClass: "commonth emp-reports-sixtd"
  }, [_vm._v("days")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "emp-reports-firsttd"
  }, [_vm._v(" 02/03/2022 - \"Current\" ")]), _c('td', {
    staticClass: "emp-reports-secondtd"
  }, [_vm._v(" System ")]), _c('td', {
    staticClass: "emp-reports-thirdtd"
  }, [_vm._v(" N/A ")]), _c('td', {
    staticClass: "emp-reports-fourtd"
  }, [_vm._v(" Yearly accural ")]), _c('td', {
    staticClass: "emp-reports-fivetd"
  }, [_vm._v(" increase ")]), _c('td', {
    staticClass: "emp-reports-sixtd"
  }, [_vm._v(" 8.87 ")])])])], 2)], 1)], 1)]), _c('div', {
    staticClass: "mar-top-hrm25"
  }, [_c('div', {
    staticClass: "hrm-bg-accordian flex-column justify-content-start align-item-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Holiday-UK")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.holiduk-1",
      modifiers: {
        "holiduk-1": true
      }
    }],
    staticClass: "togg-btn-report",
    attrs: {
      "variant": "primary"
    }
  }, [_c('svg', {
    staticClass: "svgtoggle",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.46",
      "height": "9.926",
      "viewBox": "0 0 17.46 9.926"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15893",
      "data-name": "Group 15893",
      "transform": "translate(-54.046 -5.227)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15891",
      "data-name": "Group 15891",
      "transform": "translate(55.46 6.64)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_41822",
      "data-name": "Path 41822",
      "d": "M13996.459,9709.063l7.113,6.908",
      "transform": "translate(-13996.459 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_41823",
      "data-name": "Path 41823",
      "d": "M14003.77,9709.063l-7.311,7.1",
      "transform": "translate(-13989.138 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])])])])], 1), _c('b-collapse', {
    staticClass: "w-100",
    attrs: {
      "id": "holiduk-1"
    }
  }, [_c('b-card', {
    staticClass: "report-cards"
  }, [_c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Working hours per day: 8.0")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Annually allowed: 20 days")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Currently available: 8.87 days")])]), _c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Holiday")]), _c('vb-table', {
    staticClass: "reports-table-width",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth emp-reports-firsttd col-left"
  }, [_vm._v("created")]), _c('h5', {
    staticClass: "commonth emp-reports-secondtd"
  }, [_vm._v("created by")]), _c('h5', {
    staticClass: "commonth emp-reports-thirdtd"
  }, [_vm._v("time off requested")]), _c('h5', {
    staticClass: "commonth emp-reports-fourtd"
  }, [_vm._v("note")]), _c('h5', {
    staticClass: "commonth emp-reports-fivetd"
  }, [_vm._v("action")]), _c('h5', {
    staticClass: "commonth emp-reports-sixtd"
  }, [_vm._v("days")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "emp-reports-firsttd"
  }, [_vm._v(" 02/03/2022 - \"Current\" ")]), _c('td', {
    staticClass: "emp-reports-secondtd"
  }, [_vm._v(" System ")]), _c('td', {
    staticClass: "emp-reports-thirdtd"
  }, [_vm._v(" N/A ")]), _c('td', {
    staticClass: "emp-reports-fourtd"
  }, [_vm._v(" Yearly accural ")]), _c('td', {
    staticClass: "emp-reports-fivetd"
  }, [_vm._v(" increase ")]), _c('td', {
    staticClass: "emp-reports-sixtd"
  }, [_vm._v(" 8.87 ")])])])], 2)], 1)], 1)]), _c('div', {
    staticClass: "mar-top-hrm25"
  }, [_c('div', {
    staticClass: "hrm-bg-accordian flex-column justify-content-start align-item-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Business trip")]), _c('b-button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.btrip-1",
      modifiers: {
        "btrip-1": true
      }
    }],
    staticClass: "togg-btn-report",
    attrs: {
      "variant": "primary"
    }
  }, [_c('svg', {
    staticClass: "svgtoggle",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.46",
      "height": "9.926",
      "viewBox": "0 0 17.46 9.926"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15893",
      "data-name": "Group 15893",
      "transform": "translate(-54.046 -5.227)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_15891",
      "data-name": "Group 15891",
      "transform": "translate(55.46 6.64)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_41822",
      "data-name": "Path 41822",
      "d": "M13996.459,9709.063l7.113,6.908",
      "transform": "translate(-13996.459 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_41823",
      "data-name": "Path 41823",
      "d": "M14003.77,9709.063l-7.311,7.1",
      "transform": "translate(-13989.138 -9709.063)",
      "fill": "none",
      "stroke": "#828282",
      "stroke-linecap": "round",
      "stroke-width": "2"
    }
  })])])])])], 1), _c('b-collapse', {
    staticClass: "w-100",
    attrs: {
      "id": "btrip-1"
    }
  }, [_c('b-card', {
    staticClass: "report-cards"
  }, [_c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Working hours per day: 8.0")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Annually allowed: 20 days")]), _c('p', {
    staticClass: "report-card-text"
  }, [_vm._v("Currently available: 8.87 days")])]), _c('h5', {
    staticClass: "accordian-togg-text"
  }, [_vm._v("Holiday")]), _c('vb-table', {
    staticClass: "reports-table-width",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth emp-reports-firsttd col-left"
  }, [_vm._v("created")]), _c('h5', {
    staticClass: "commonth emp-reports-secondtd"
  }, [_vm._v("created by")]), _c('h5', {
    staticClass: "commonth emp-reports-thirdtd"
  }, [_vm._v("time off requested")]), _c('h5', {
    staticClass: "commonth emp-reports-fourtd"
  }, [_vm._v("note")]), _c('h5', {
    staticClass: "commonth emp-reports-fivetd"
  }, [_vm._v("action")]), _c('h5', {
    staticClass: "commonth emp-reports-sixtd"
  }, [_vm._v("days")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "emp-reports-firsttd"
  }, [_vm._v(" 02/03/2022 - \"Current\" ")]), _c('td', {
    staticClass: "emp-reports-secondtd"
  }, [_vm._v(" System ")]), _c('td', {
    staticClass: "emp-reports-thirdtd"
  }, [_vm._v(" N/A ")]), _c('td', {
    staticClass: "emp-reports-fourtd"
  }, [_vm._v(" Yearly accural ")]), _c('td', {
    staticClass: "emp-reports-fivetd"
  }, [_vm._v(" increase ")]), _c('td', {
    staticClass: "emp-reports-sixtd"
  }, [_vm._v(" 8.87 ")])])])], 2)], 1)], 1)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Reports")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mar-top-hrm60 mar-bottom-hrm70"
  }, [_c('h5', {
    staticClass: "reports-last-tweleve-month-hdng"
  }, [_vm._v("Last 12 Months")])]);

}]

export { render, staticRenderFns }