var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.check.send ? '' : _vm.cancel();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Check Slot")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_vm.api.check.send ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center"
  }, [_vm.api.check.send ? _c('vb-spinner') : _vm._e()], 1) : [_vm.slotAvailable ? _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm.isAddon ? [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm.data.service))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Allowed")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm.response.slot.PlanAddonAllowedProductCount)))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Used")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm.response.slot.PlanAddonUsedProductCount)))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Remaining")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm.response.slot.PlanAddonRemainingProductCount)))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Price")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-4px"
  }, [_vm._v(_vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response, 'product_detail.plan.plan_cost', 0))) + " ")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.response, 'product_detail.plan.plan_cost_currency')))])])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Billing Cycle")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response, 'product_detail.plan.plan_billing_period')))])])] : [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm.data.service))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Allowed")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm.response.slot.PlanAllowedProductCount)))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Used")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm.response.slot.PlanUsedProductCount)))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Remaining")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("show_positive_value")(_vm.response.slot.PlanRemainingProductCount)))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Price")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
  }, [_vm._v("Included")]), _c('vb-icon', {
    attrs: {
      "icon": "MyNumbers-tick-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Billing Cycle")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response, 'product_detail.plan.plan_billing_period')))])])]], 2) : [_c('div', {
    staticClass: "d-flex align-items-center justify-conent-center text-center headingInsideTable mt-32px mb-16px"
  }, [_vm._v(" You don't have any slot available for adding this service. Kindly upgrade your package to get it. ")])], _vm.slotAvailable ? _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    on: {
      "click": function ($event) {
        return _vm.proceed();
      }
    }
  }, [_vm._v("Proceed")]) : _vm._e()]], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }