<template>
  <div class="dialer-settings-main">
    <section class="dialer-settings-section dialer-ivr-setting TeamsSettings">
      
      <div class="dialer-flex">
        <template v-if="$store.getters.getIsMobile">
          <div class="d-flex align-items-center mb-3 calc-title-width-button">
            <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
            <h2 class="dialer-settings-title w-100 mb-0 text-center">Notes</h2>
          </div>
        </template>
        <h2 v-else class="dialer-settings-title">Notes</h2>
        
      </div>
      <template v-if="getCurrentUser.logs_super_mode">
        <vb-select v-model="selected.account" />
      </template>
      <template v-if="selected.account">
        <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom teams-settings-2nd-section">
          <span>Here you can create teams of users who need to receive calls simultaneously. For example a sales team, or an office location.</span>
          <div class="d-flex justify-content-end align-items-center w-50">
            <div class=" whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox">
              <b-input type="text" class="w-100" placeholder="Search Note" v-model="filter.notes.search" />
            </div>
          </div>
        </div>

        <vb-table id="teams-table" :isListEmpty="isEmpty(fetchNotes)" :listLength="fetchNotes.length" :perPage="5" :loading="api.notes.send">
          <template slot="header">
            <tr>
              <th class="dialer-has-sort">
                <span class="dialer-sorted-by ascending">Name</span>
              </th>
              <th class="dialer-has-sort">
                <span class="dialer-sorted-by ascending">Numbers</span>
              </th>
              <th class="dialer-has-sort dialer-col-right">
                <span>Assigned To</span>
              </th>
            </tr>
          </template>
          <template #body="{ start, end }">
            <template v-for="(team, index) in fetchNotes">
              <tr class="dialer-row-select" :key="team.id" v-show="index >= start && index <= end" @click="$modal.show('EditTeamModal', { team: team })">
                <td class="dialer-row-title">
                  <Info :image="team | get_property('avatar.image.image')" :name="team.name || ''" :sub_info="team.ringroup | filterExtension" />
                </td>
                <td class="dialer-col-left font-weight-bold">
                  <div class="d-flex align-items-center" v-if="team.ringroup && team.ringroup.did && team.ringroup.did[0]">
                    <span class="sm-mar-left font-weight-bold min-width-160px">
                      <span class="mr-1" style="height:45px;width:45px;">
                        <img class="country-img" width="45px" :src="team.ringroup.did[0].did | number_formater | flag_icon" />
                      </span>
                      {{ team.ringroup.did[0].did | number_formater }}
                    </span>
                    <div class="w-auto dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight dialer-dropDown-p-1 ml-2" v-if="team.ringroup.did.length>1" @click.stop="conditions.more=conditions.more==team.id?'':team.id">
                      <div class="px-2 rounded more-numbers-tag">
                        <small>+ {{ team.ringroup.did.length-1 }} more</small>
                      </div>
                    </div>
                  </div>
                  <template v-else>
                    <span class="text-center"> - </span>
                  </template>
                </td>
                <td class="dialer-col-right">
                  <div class="d-flex justify-content-end">
                    <a class="dialer-link-more" @click.stop="$modal.show('AssignNumbersTeam', { team: team, type: 'AssignNumbersTeam' })">{{ team.members | memberCount }} users</a>
                  </div>
                </td>
              </tr>
            </template>
          </template>
        </vb-table>
      </template>
      
    </section>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import Info from '../Lists/Info.vue';
export default {
  name: "DeveloperNotes",
  components: {
    Info,
  },
  inject:['isEmpty'],
  data() {
    return {
      conditions: {
        more: '',
      },
      filter: {
        notes: {
          search: '',
        },
      },
      response: {
        notes: [],
        users: [],
      },
      api: {
        notes: this.$helperFunction.apiInstance(),
      },
      selected: {
        account: '',
      },
    };
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
    filterNotes() {
      const search = this.filter.notes.search.toLowerCase().trim()
      return this.notes.filter(item=>`${item.name} ${item.ringroup.extn}`.toLowerCase().trim().includes(search));
    },
  },
  methods: {
    fetchNotes() {
      var vm = this;
      if (vm.api.notes.send) return;
      vm.api.notes.send = true;
      setTimeout(()=>{
        vm.api.notes.send = false;
      },2 * 1000)
    },
    fetchUsers() {
      var vm = this;
      if (vm.api.notes.send) return;
      vm.api.notes.send = true;
      setTimeout(()=>{
        vm.api.notes.send = false;
      },2 * 1000)
    },
    updateAction() {
      var vm = this;
      if (vm.api.notes.send) return;
      vm.api.notes.send = true;
      setTimeout(()=>{
        vm.api.notes.send = false;
      },2 * 1000)
    },
  },
  mounted(){
    if(this.getCurrentUser.logs_super_mode){
      this.fetchUsers()
    } else {
      this.selected.account=this.getCurrentUser.account
    }
    this.fetchNotes()
  }
};
</script>