var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup addCardModal width-60 ViewAllInvoicesModal",
    attrs: {
      "width": "80%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-box table"
  }, [_c('div', {
    staticClass: "dialer-edit-header mt-3"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("View all invoices")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])], 1)]), _c('div', {
    staticClass: "py-5"
  }, [_c('vb-table', {
    staticClass: "dialer-table p-3",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filterInvoices),
      "listLength": _vm.filterInvoices.length,
      "perPage": 5,
      "loading": _vm.api_sent.account_invoice
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterInvoices, function (invoice, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: invoice.id
          }, [_c('td', {
            staticClass: "font-weight-700 font-16"
          }, [_vm._v(" " + _vm._s(`VB-${invoice.id || ''}`) + " ")]), _c('td', {
            staticClass: "font-weight-700 font-16"
          }, [_vm._v(" " + _vm._s(`${invoice.currencyprefix} ${invoice.total}`) + " ")]), _c('td', {
            staticClass: "font-weight-700 font-16"
          }, [_vm._v(" " + _vm._s(` ${invoice.status}`) + " ")]), _c('td', {
            staticClass: "font-weight-700 font-16"
          }, [_vm._v(" " + _vm._s(`${invoice.currencyprefix} ${invoice.subtotal}`) + " ")]), _c('td', {}, [_c('div', {
            staticClass: "d-flex d-flex justify-content-end w-100"
          }, [_c('button', {
            staticClass: "dialer-button dialer-button-primary",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('InvoicePDFDownloadViewAllInvoices', {
                  id: invoice.id
                });
              }
            }
          }, [_vm._v("View invoice")])])])]);
        });
      }
    }])
  }, [_c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {}, [_c('div', {
    staticClass: "w-auto"
  }, [_c('span', {
    staticClass: "w-auto font-14 pb-2 pr-3"
  }, [_vm._v("Invoice number")])])]), _c('th', {}, [_c('div', {
    staticClass: "w-auto"
  }, [_c('span', {
    staticClass: "font-14 pb-2 pr-3"
  }, [_vm._v("Outstanding amount")])])]), _c('th', {}, [_c('div', {
    staticClass: "w-auto"
  }, [_c('span', {
    staticClass: "font-14 pb-2 pr-3"
  }, [_vm._v("Status")])])]), _c('th', {}, [_c('div', {
    staticClass: "w-auto"
  }, [_c('span', {
    staticClass: "font-14 pb-2 pr-3"
  }, [_vm._v("Subtotal")])])]), _c('th', {}, [_c('div', {
    staticClass: "w-auto"
  }, [_c('span', {
    staticClass: "pb-2"
  }, [_c('br')])])])])])], 1)]), _c('InvoicePDFDownload', {
    attrs: {
      "modalName": `InvoicePDFDownloadViewAllInvoices`
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }