var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading"
  }, [_vm._v("Documents")]), _c('div', {
    staticClass: "mainDescription"
  }, [_vm._v(" A list of all emergency contact data added in the system ")]), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown big",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("Employees with emergency contacts")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Employees with emergency contacts ")])], 1), _c('div', {
    staticClass: "EmployeeDataNotesTable mt-32px"
  }, [_vm._m(0), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title w-50"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-textContainer"
        }, [_c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")]), _c('div', {
          staticClass: "nameWithImage-textSmall"
        }, [_vm._v("8001")])])])]), _c('td', {
          staticClass: "dialer-row-title w-50"
        }, [_vm._v(" Amsterdam - production ")])]), _c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title w-50"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/2.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-textContainer"
        }, [_c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")]), _c('div', {
          staticClass: "nameWithImage-textSmall"
        }, [_vm._v("8001")])])])]), _c('td', {
          staticClass: "dialer-row-title w-50"
        }, [_vm._v(" UK - Digital production ")])])];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead w-50"
  }, [_vm._v("Added to")]), _c('div', {
    staticClass: "HrmTableHead w-50"
  }, [_vm._v("Date")])]);

}]

export { render, staticRenderFns }