<template>
  <div v-if="channel_id" class="chat-box">
    <div v-if="channelData.pinned_posts.loading">
      <span class="dialer-LoadingClass">Loading...</span>
    </div>
    <div v-else id="pinned-scroll-end" class="allow_scroll" @scroll="onScroll">
      <div class="pr-4 chat-box-bill pinned">
        <div v-for="message in messages" :key="message.id">
          <div class="message-date">
            <span>{{ message.create_at | date_current }}</span>
          </div>
          <Message ref="single_message" :post="message" :channel_id="channel_id" :channel="channel" :data="data" :channelData="channelData" :members="members" type="pinned" />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { events } from "../../../../utils";
import Message from "./Message";
import _ from "lodash";
export default {
  name: "Pinned",
  components: {
    Message,
  },
  props: {
    channel_id: {
      type: String,
      default: "",
    },
    channelData: {
      type: Object,
      default: () => ({}),
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      is_scrolled: false,
    };
  },
  computed: {
    messages() {
      if (_.isEmpty(this.channelData)) return [];
      var current_date = "";
      var current_time = "";
      const messages = this.channelData.pinned_posts.order.map(
        (order) => this.channelData.pinned_posts.posts[order]
      );
      var user_msg_counter = messages.filter((post) => !post.type).length + 1;
      return messages.map((post) => {
        const date = new Date(post.create_at);
        if (current_date !== date.toDateString()) {
          current_date = date.toDateString();
          current_time = date.getHours() + date.getMinutes();
          return {
            ...post,
            is_date_show: true,
            is_time_show: true,
            user_msg_counter: post.type ? null : --user_msg_counter,
          };
        } else {
          if (date.getHours() + date.getMinutes() !== current_time) {
            current_time = date.getHours() + date.getMinutes();
            return {
              ...post,
              is_date_show: false,
              is_time_show: true,
              user_msg_counter: post.type ? null : --user_msg_counter,
            };
          } else {
            return {
              ...post,
              is_date_show: false,
              is_time_show: false,
              user_msg_counter: post.type ? null : --user_msg_counter,
            };
          }
        }
      });
    },
    members() {
      if (!_.isEmpty(this.channelData)) return [];
      return this.channelData.members;
    },
  },
  watch: {
    channel_id() {
      this.scrollEnd();
    },
    "channelData.pinned_posts.order.length": {
      immediate: true,
      handler: function (length) {
        if (length === 0) {
          this.$root.$emit(events.get_pinned_messages_api, {
            channel_id: this.channel_id,
          });
        }
      },
    },
  },
  methods: {
    //--event--
    scrollEnd() {
      let app = this;
      const message = document.getElementById("pinned-scroll-end");
      setTimeout(() => {
        app.is_scrolled = true;
        if(message){
          message.scrollTop = message.scrollHeight;
        }
      }, 0 * 1000);
    },
    onScroll(Event) {
      let app = this;
      const el = Event.target;
      if (el.scrollTop === 0) {
        this.$root.$emit(events.get_pinned_messages_api, {
          channel_id: app.channel_id,
          el: el,
          scrollHeight: el.scrollHeight,
        });
      }
    },
  },
  mounted() {
    let app = this;
    setTimeout(() => {
      app.scrollEnd();
    }, 2 * 1000);
  },
};
</script>

<style lang="scss" scoped>
.chat-box {
  justify-content: flex-end;
}
.pinned {
  width: 100%;
}
.chat-box-bill {
  flex-direction: column;
  display: flex;
}
.chat-box-section {
  height: calc(100vh - 130px);
}
.message-date {
  position: relative;
  text-align: center;
  span {
    position: relative;
    background-color: $body-bg;
    padding: 0px 15px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    background: $gray-600;
    left: 0px;
    height: 1px;
    z-index: -5;
  }
}
.new-message {
  span {
    color: $primary;
  }
  &:before {
    background: $primary;
  }
}
.allow_scroll {
  overflow-y: auto;
}
</style>
