<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Topup Products</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      
      <div class="IosTabsSection twoTabs mt-16px mb-16px">
        <div 
          v-for="tab in tabs" 
          :key="tab.value" 
          :class="{ 'active': selected.tab==tab.value }" 
          class="IosTabButton" 
          @click="selected.tab=tab.value"
        >
          {{ tab.text }}
        </div>
      </div>

      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <div v-if="selected.tab==tabs.topup.value" class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" v-for="product in response.products" :key="product.id" @click="addTopup(product.whmcs_id)">
              <div class="headingInsideTable">{{product.label}}</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{product.price}}</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
            <vb-no-record v-if="response.products.length==0" :text="api.products.send?'':'There is no playlist'" :design="3">
              <vb-loading v-if="api.products.send" slot="loading" />
            </vb-no-record>
          </div>
          <div v-else-if="selected.tab==tabs.credits.value" class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" v-for="credit in credits" :key="credit.id">
              <div class="headingInsideTable">{{ credit.amount }}</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ credit.description }}</div>
              </div>
            </div>
            <vb-no-record v-if="credits.length==0" :text="api.account_invoice.send?'':'There is no playlist'" :design="3">
              <vb-loading v-if="api.account_invoice.send" slot="loading" />
            </vb-no-record>
          </div>

        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';

let tabs = {
  credits: {
    text: 'Credits',
    value: 'credits'
  },
  topup: {
    text: 'Add Topup',
    value: 'topup'
  },
}

export default {
  name: "TopupModal",
  inject: ['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'Topup',
    }
  },
  data() {
    return {
      api: {
        topup: this.$helperFunction.apiInstance({ send: '' }),
        products: this.$helperFunction.apiInstance(),
        account_invoice: this.$helperFunction.apiInstance(),
      },
      response: {
        products: [],
        account_invoice: {},
      },
      selected: {
        tab: tabs.topup.value,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    tabs(){ return tabs },
    credits(){ return this.response.account_invoice?.credit_logs?.credits?.credit ?? [] },
  },
  methods: {
    onBeforeOpen(){
      this.fetchAccountInvoices()
      this.fetchTopupProducts()
    },
    onBeforeClose(){
      this.api.products.reset()
      this.api.account_invoice.reset()
      this.response.products=[]
      this.response.account_invoice={}
      this.selected.tab=tabs.topup.value
    },
    fetchAccountInvoices(){
      let vm = this;
      vm.api.account_invoice.send = true;
      VOIP_API.endpoints.users.accountinvoicedetail({
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data }) => {
        vm.response.account_invoice = data
      })
      .finally(() => {
        vm.api.account_invoice.send = false
      });
    },
    fetchTopupProducts(){
      let vm=this
      if(vm.api.products.send) return;
      vm.api.products.send=true
      VOIP_API.endpoints.topup.getproducts()
      .then(({ data: { data } })=>{
        vm.response.products=data
      })
      .finally(()=>{
        vm.api.products.send=false
      })
    },
    addTopup(id){
      let vm = this
      if (vm.api.topup.send || !id) return;
      const product = vm.response.products.find(product=>product.whmcs_id==id)
      vm.$appConfirmation({
        title: "Confirmation",
        message: `You are going to add a topup of ${product.price}.Do You want to continue?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.topup.send = id
            VOIP_API.endpoints.topup.add2({
              accountcode: vm.getCurrentUser?.account,
              uid: vm.getCurrentUser?.uid,
              id: id,
              default_account: vm.getCurrentUser?.default_accountcode,
            })
            .then(()=>{
              vm.$modal.hide(vm.modalName)
              vm.$emit('added')
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.topup.send = ''
            })
          }
        },
      });
    },
  },
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
