var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('Fragment', [_c('div', {
    staticClass: "d-flex justify-content-center position-relative",
    staticStyle: {
      "width": "200px"
    }
  }, [_c('div', {
    staticStyle: {
      "position": "absolute",
      "left": "50%",
      "top": "50%",
      "transform": "translate(-50% , -50%)"
    }
  }, [_vm._v("If no answer")]), _c('div', {
    staticClass: "lineWithArrow longLine-150px"
  }, [_c('div', {
    staticClass: "lineWithArrow-line"
  }), _c('div', {
    staticClass: "lineWithArrow-arrow"
  })])]), _c('div', {
    staticClass: "nodeBasic-new"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center mb-8px"
  }, [_c('div', {
    staticClass: "nodeBasic-heading mr-3"
  }, [_vm._v("When calls aren't answered...")]), _c('b-icon', {
    attrs: {
      "icon": "people-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-CallForwardSettingModal`, {
          account: _vm.account,
          setting: _vm.response.forwarding_setting,
          accountcode: _vm.forwardCallerId ? _vm.account : ''
        });
      }
    }
  })], 1), _vm.response.forwarding_setting.forward_note == 'off' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Keep ringing")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v("Call forwarding is off")])] : _vm._e(), _vm.response.forwarding_setting.forward_note == 'voicemail' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v("If phone rings for "), _c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.timeout) + " seconds")]), _vm._v(" call will be forward to voicemail")])] : _vm._e(), _vm.response.forwarding_setting.forward_note == 'extension' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Forward to a user, team or IVR menu")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v(" If phone rings for "), _c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.timeout) + " seconds")]), _vm._v(" call will be forward to "), _c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.extension_name))])])] : _vm._e(), _vm.response.forwarding_setting.forward_note == 'number' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Forward to another number")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v(" If phone rings for "), _c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.timeout) + " seconds")]), _vm._v(" call will be forward to "), _c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.response.forwarding_setting.forward_number)))])])] : _vm._e()], 2), _c('ScheduleAssignModal', {
    attrs: {
      "modalName": `${_vm._uid}-ScheduleAssignModal`,
      "account": _vm.account
    },
    on: {
      "interface": function ($event) {
        return _vm.updateForwardingSettings({
          extension_name: $event.accountname,
          forward_number: $event.account
        });
      }
    }
  }), _c('DurationPickerModal', {
    attrs: {
      "modalName": `${_vm._uid}-DurationPickerModal`
    },
    on: {
      "time": function ($event) {
        return _vm.updateForwardingSettings({
          timeout: $event.seconds
        });
      }
    }
  }), _c('CallForwardingNumberInputModal', {
    attrs: {
      "modalName": `${_vm._uid}-CallForwardingNumberInputModal`
    },
    on: {
      "number": function ($event) {
        return _vm.updateForwardingSettings({
          forward_number: $event.ber
        });
      }
    }
  }), _c('CallForwardSettingModal', {
    attrs: {
      "modalName": `${_vm._uid}-CallForwardSettingModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.updateForwardingSettings($event);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }