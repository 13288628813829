var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "unsec-common"
  }, [_c('div', {
    staticClass: "timeoff-top-btn mar-bottom-hrm60"
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "btn btn-primarymike space4btn-when-scroll-activate mr-3",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('HRMAddNewTaskModal');
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.551",
      "height": "16.551",
      "viewBox": "0 0 16.551 16.551"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_ionic-ios-add",
      "data-name": "Icon ionic-ios-add",
      "d": "M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z",
      "transform": "translate(-8.465 -8.465)",
      "fill": "#fff",
      "stroke": "#fff",
      "stroke-width": "1"
    }
  })]), _vm._v(" Request time off ")]), _c('button', {
    staticClass: "btn btn-activity space4btn-when-scroll-activate"
  }, [_vm._v(" Set long-term leave ")])])]), _vm._m(1), _vm._m(2), _c('vb-table', {
    staticClass: "timeoff-table-width",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth todone-firsttd col-first-entryleft"
  }, [_vm._v("policy")]), _c('h5', {
    staticClass: "commonth todone-secondtd"
  }, [_vm._v("Allowance period")]), _c('h5', {
    staticClass: "commonth todone-thirdtd"
  }, [_vm._v("balance")]), _c('h5', {
    staticClass: "commonth todone-fourtd"
  }, [_vm._v("accrual")]), _c('h5', {
    staticClass: "commonth todone-fivetd"
  }, [_vm._v("carried")]), _c('h5', {
    staticClass: "commonth todone-six col-right"
  }, [_vm._v("Action")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "todone-firsttd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#D5761B"
    }
  })]), _c('span', {
    staticClass: "clr-sick"
  }, [_vm._v("Sickday")])])]), _c('td', {
    staticClass: "todone-secondtd"
  }, [_vm._v(" 01/01/2022 - 31/12/2022 ")]), _c('td', {
    staticClass: "todone-third"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 0")]), _vm._v(" days used ")]), _c('td', {
    staticClass: "todone-fourtd"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 15")]), _vm._v(" days ")]), _c('td', {
    staticClass: "todone-fivetd"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 15")]), _vm._v(" days ")]), _c('td', {
    staticClass: "todone-sixtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "todone-firsttd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#5576D1"
    }
  })]), _c('span', {
    staticClass: "clr-holiday1"
  }, [_vm._v("Holiday - UK")])])]), _c('td', {
    staticClass: "todone-secondtd"
  }, [_vm._v(" 01/01/2022 - 31/12/2022 ")]), _c('td', {
    staticClass: "todone-third"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 0")]), _vm._v(" days used ")]), _c('td', {
    staticClass: "todone-fourtd"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 15")]), _vm._v(" days ")]), _c('td', {
    staticClass: "todone-fivetd"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 15")]), _vm._v(" days ")]), _c('td', {
    staticClass: "todone-sixtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "todone-firsttd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#1A9211"
    }
  })]), _c('span', {
    staticClass: "clr-holiday2"
  }, [_vm._v("Holiday - NL")])])]), _c('td', {
    staticClass: "todone-secondtd"
  }, [_vm._v(" 01/01/2022 - 31/12/2022 ")]), _c('td', {
    staticClass: "todone-third"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 0")]), _vm._v(" days used ")]), _c('td', {
    staticClass: "todone-fourtd"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 15")]), _vm._v(" days ")]), _c('td', {
    staticClass: "todone-fivetd"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 15")]), _vm._v(" days ")]), _c('td', {
    staticClass: "todone-sixtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "todone-firsttd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#D2A657"
    }
  })]), _c('span', {
    staticClass: "clr-btrip"
  }, [_vm._v("Business trip")])])]), _c('td', {
    staticClass: "todone-secondtd"
  }, [_vm._v(" 01/01/2022 - 31/12/2022 ")]), _c('td', {
    staticClass: "todone-third"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 0")]), _vm._v(" days used ")]), _c('td', {
    staticClass: "todone-fourtd"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 15")]), _vm._v(" days ")]), _c('td', {
    staticClass: "todone-fivetd"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 15")]), _vm._v(" days ")]), _c('td', {
    staticClass: "todone-sixtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "todone-firsttd"
  }, [_c('div', {
    staticClass: "timeoff-color-table-icons"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#D5761B"
    }
  })]), _c('span', {
    staticClass: "clr-sick"
  }, [_vm._v("Sickday")])])]), _c('td', {
    staticClass: "todone-secondtd"
  }, [_vm._v(" 01/01/2022 - 31/12/2022 ")]), _c('td', {
    staticClass: "todone-third"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 0")]), _vm._v(" days used ")]), _c('td', {
    staticClass: "todone-fourtd"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 15")]), _vm._v(" days ")]), _c('td', {
    staticClass: "todone-fivetd"
  }, [_c('span', {
    staticClass: "timeoff-tbl-numeric-common-bold"
  }, [_vm._v(" 15")]), _vm._v(" days ")]), _c('td', {
    staticClass: "todone-sixtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])], 2), _vm._m(3), _vm._m(4), _c('div', {
    staticClass: "mar-top-hrm25 d-flex flex-wrap"
  }, [_c('div', {
    staticClass: "home-dd-container mr-0"
  }, [_c('b-dropdown', {
    staticClass: "home-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "home-button-content-dropi"
        }, [_c('div', {
          staticClass: "home-dropi-contenti"
        }, [_vm._v("All policies")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v("New")]), _c('b-dropdown-item', [_vm._v("To be announced (4)")])], 1)], 1), _c('div', {
    staticClass: "home-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "home-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "home-button-content-dropi"
        }, [_c('div', {
          staticClass: "home-dropi-contenti"
        }, [_vm._v("All time")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v("GMT 5+")]), _c('b-dropdown-item', [_vm._v("GMT 9+")]), _c('b-dropdown-item', [_vm._v("GMT 8+")])], 1)], 1), _c('div', {
    staticClass: "home-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "home-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "home-button-content-dropi"
        }, [_c('div', {
          staticClass: "home-dropi-contenti"
        }, [_vm._v("All statuses")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v("Approved")]), _c('b-dropdown-item', [_vm._v("Pending")]), _c('b-dropdown-item', [_vm._v("Rejected")])], 1)], 1)]), _c('vb-table', {
    staticClass: "timeofftwo-table-width",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth firsttd col-first-entryleft"
  }, [_vm._v("Dates")]), _c('h5', {
    staticClass: "commonth secondtd"
  }, [_vm._v("date requested")]), _c('h5', {
    staticClass: "commonth thirdtd"
  }, [_vm._v("policy")]), _c('h5', {
    staticClass: "commonth fourtd"
  }, [_vm._v("details")]), _c('h5', {
    staticClass: "commonth fivetd"
  }, [_vm._v("Status")]), _c('h5', {
    staticClass: "commonth sixtd col-right"
  }, [_vm._v("Action")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "firsttd"
  }, [_vm._v(" 16/07/2022"), _c('br'), _vm._v(" first part of the day, 7 hours ")]), _c('td', {
    staticClass: "secondtd"
  }, [_vm._v(" 23/07/2022"), _c('br'), _vm._v(" 14 days ago ")]), _c('td', {
    staticClass: "thirdtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-start"
  }, [_c('svg', {
    staticClass: "timeofftwotable-svg mt-1",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "14",
      "height": "14",
      "viewBox": "0 0 14 14"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_5437",
      "data-name": "Ellipse 5437",
      "cx": "7",
      "cy": "7",
      "r": "7",
      "fill": "#1a9211"
    }
  })]), _vm._v(" Holiday - NL ")])]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" Qui illum iure non. ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" Approved ")]), _c('td', {
    staticClass: "sixtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])], 2)], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Time off summary")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "timeoff-cardmain mar-top-hrm50"
  }, [_c('div', {
    staticClass: "time-off-cardinner"
  }, [_c('div', {
    staticClass: "clr-sick"
  }, [_vm._v("Sickday")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h5', {
    staticClass: "num-sick mb-imp"
  }, [_vm._v("3")]), _c('div', {
    staticClass: "sickday-prog"
  }, [_c('div', {
    staticClass: "hrm-circularProgressBar"
  }, [_c('div', {
    staticClass: "circular-ProgBar"
  }, [_c('div', {
    staticClass: "circular-ProgBar-fillhrm",
    staticStyle: {
      "transform": "rotate(180deg)"
    }
  })]), _c('div', {
    staticClass: "circular-ProgBar-text"
  }, [_c('span', [_vm._v("65%")])])])])])]), _c('div', {
    staticClass: "time-off-cardinner"
  }, [_c('div', {
    staticClass: "clr-holiday1"
  }, [_vm._v("Holiday - UK")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h5', {
    staticClass: "num-holiday1 mb-imp"
  }, [_vm._v("3")]), _c('div', {
    staticClass: "holidayuk-prog"
  }, [_c('div', {
    staticClass: "hrm-circularProgressBar"
  }, [_c('div', {
    staticClass: "circular-ProgBar"
  }, [_c('div', {
    staticClass: "circular-ProgBar-fillhrm",
    staticStyle: {
      "transform": "rotate(180deg)"
    }
  })]), _c('div', {
    staticClass: "circular-ProgBar-text"
  }, [_c('span', [_vm._v("65%")])])])])])]), _c('div', {
    staticClass: "time-off-cardinner"
  }, [_c('div', {
    staticClass: "clr-holiday2"
  }, [_vm._v("Holiday - NL")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h5', {
    staticClass: "num-holiday2 mb-imp"
  }, [_vm._v("3")]), _c('div', {
    staticClass: "holidaynl-prog"
  }, [_c('div', {
    staticClass: "hrm-circularProgressBar"
  }, [_c('div', {
    staticClass: "circular-ProgBar"
  }, [_c('div', {
    staticClass: "circular-ProgBar-fillhrm",
    staticStyle: {
      "transform": "rotate(180deg)"
    }
  })]), _c('div', {
    staticClass: "circular-ProgBar-text"
  }, [_c('span', [_vm._v("65%")])])])])])]), _c('div', {
    staticClass: "time-off-cardinner"
  }, [_c('div', {
    staticClass: "clr-btrip"
  }, [_vm._v("Business trip")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h5', {
    staticClass: "num-btrip mb-imp"
  }, [_vm._v("3")]), _c('div', {
    staticClass: "btrip-prog"
  }, [_c('div', {
    staticClass: "hrm-circularProgressBar"
  }, [_c('div', {
    staticClass: "circular-ProgBar"
  }, [_c('div', {
    staticClass: "circular-ProgBar-fillhrm",
    staticStyle: {
      "transform": "rotate(180deg)"
    }
  })]), _c('div', {
    staticClass: "circular-ProgBar-text"
  }, [_c('span', [_vm._v("65%")])])])])])]), _c('div', {
    staticClass: "time-off-cardinner"
  }, [_c('div', {
    staticClass: "clr-sick"
  }, [_vm._v("Sickday")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h5', {
    staticClass: "num-sick mb-imp"
  }, [_vm._v("3")]), _c('div', {
    staticClass: "sickday-prog"
  }, [_c('div', {
    staticClass: "hrm-circularProgressBar"
  }, [_c('div', {
    staticClass: "circular-ProgBar"
  }, [_c('div', {
    staticClass: "circular-ProgBar-fillhrm",
    staticStyle: {
      "transform": "rotate(180deg)"
    }
  })]), _c('div', {
    staticClass: "circular-ProgBar-text"
  }, [_c('span', [_vm._v("65%")])])])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "timeoff-top-btn mar-top-hrm50"
  }, [_c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Time off details ")])]), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "btn btn-activity mr-3"
  }, [_vm._v(" Change balance ")]), _c('button', {
    staticClass: "btn btn-activity"
  }, [_vm._v(" For specfic data ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "timeoff-approved-bg mar-top-hrm50"
  }, [_c('div', {
    staticClass: "calender-hrm-height mb-3"
  }, [_c('h5', {
    staticClass: "timeoff-subheading"
  }, [_vm._v("Time off approved by")]), _c('p', {
    staticClass: "timeoff-text mb-imp"
  }, [_vm._v("To change who approves time off "), _c('a', {
    staticClass: "timeoff-clickhere",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("click here")])]), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center mt-4"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")]), _c('p', {
    staticClass: "timeoff-profile-num"
  }, [_vm._v("8001")])])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mar-top-hrm50"
  }, [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Time off history ")])]);

}]

export { render, staticRenderFns }