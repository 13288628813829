var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup ticketDetailModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.api.fetch_ticket.send ? _c('div', {
    staticClass: "w-100"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      class: `latestShimmerDesign w-100 my-4`,
      staticStyle: {
        "height": "80px"
      }
    });
  }), 0) : _c('div', {
    staticClass: "SupportTicketDetail-container"
  }, [_c('div', {
    staticClass: "dialer-edit-header align-items-start pt-0"
  }, [_c('div', {
    staticClass: "customWidth-parent"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Ticket #" + _vm._s(_vm._f("get_property")(_vm.response.ticket, 'ticket.type_id')))]), _c('div', {
    staticClass: "d-flex mt-2 customWidth"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Subject:")]), _c('span', {
    staticClass: "ticket-subject ml-2"
  }, [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.response.ticket, 'ticket.subject')) + " ")])])])]), _vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer-row"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer-column one"
  }, [_vm._v("SUBMITTER")]), _c('div', {
    staticClass: "ticketTopInfoContainer-column two"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.ticket, 'ticket.user')))])]), _c('div', {
    staticClass: "ticketTopInfoContainer-row"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer-column one"
  }, [_vm._v("SUBMITTED")]), _c('div', {
    staticClass: "ticketTopInfoContainer-column two"
  }, [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm._f("get_property")(_vm.response.ticket, 'ticket.created_at'), '', {
    formate_now: true
  })))])]), _c('div', {
    staticClass: "ticketTopInfoContainer-row"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer-column one"
  }, [_vm._v("DEPARTMENT")]), _c('div', {
    staticClass: "ticketTopInfoContainer-column two"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.ticket, 'ticket.department')))])]), _c('div', {
    staticClass: "ticketTopInfoContainer-row"
  }, [_c('div', {
    staticClass: "ticketTopInfoContainer-column one"
  }, [_vm._v("STATUS/PRIORITY")]), _c('div', {
    staticClass: "ticketTopInfoContainer-column two"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.ticket, "ticket.status.name")) + " ")])])]), _vm.response.ticket.ticket ? _c('Comments', {
    attrs: {
      "ticket": _vm.response.ticket
    },
    on: {
      "status-updated": function ($event) {
        return _vm.fetchTicket();
      }
    }
  }) : _vm._e()], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }