<template>
  <div class="ticketTabContainer">
    <div class="ticketHeading-container justify-content-end">
      <!-- <div class="d-flex align-items-center justify-content-between">
        <div class="numberTickets-container">
          <div class="bigText purple">{{0}}</div>
          <div class="smallText">Open tickets</div>
        </div>
        <div class="numberTickets-container">
          <div class="bigText green">{{0}}</div>
          <div class="smallText">Closed tickets</div>
        </div>
      </div> -->
      <button @click="$modal.show(`${_uid}-AddTicketModal`)" type="button" class="newButton">
        <vb-icon icon="squared-add-icon" height="38px" width="38px" />
        <span class="newDoneButton-textPart">Create a support ticket</span>
      </button>
    </div>
      <!-- class="kbTable"  -->
    <vb-table 
      class="latestTableDesign-withBlackBorders-again"
      :isListEmpty="tickets.length==0" 
      :listLength="tickets.length" 
      :loading="api.fetch_tickets.send" 
      :noRecordWholePage="true" 
    >
      <template #header>
        <tr>
          <th class="dialer-has-sort">
            <span class="dialer-sorted-by ascending">SUBJECT</span>
          </th>
          <th class="dialer-has-sort">
            <span class="dialer-sorted-by ascending">SUBMITTED</span>
          </th>
          <th class="dialer-has-sort">
            <span class="dialer-sorted-by ascending">DEPARTMENT</span>
          </th>
          <th class="dialer-has-sort dialer-col-right">
            <span>STATUS</span>
          </th>
        </tr>
      </template>
      <template #body="{ start, end }">
        <tr 
          v-for="(ticket, index) in tickets" 
          :key="ticket.id" 
          v-show="index >= start && index <= end" 
          @click="$modal.show(`${_uid}-UpdateTicketModal`,{ ticket_id: ticket.id })"
        >
          <!-- @click="$modal.show('SupportTicketDetailFromKBModal',{ id: ticket.id })" -->
          <td>
            <div class="d-flex align-items-center">
              {{ticket.subject}}
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              {{ticket.created_at | filter_date_current('',{ formate_now: true, })}}
            </div>
          </td>
          <td>
            <div class="d-flex align-items-center">
              {{ticket.department}}
            </div>
          </td>
          <td class="dialer-col-right">{{ticket.status}}</td>
        </tr>
      </template>
      <template #no-record>
        <div class="dialer-box alt table mt-5">
          <div class="description-text-container">
            <div class="description-text">
              If you didn't find solution to your problem 
              please visit our knowledge base for more information
              we are happy to help you!
            </div>
            <button type="button" @click="$emit('go-to-knowledge-base')" class="dialer-button dialer-button-primary">Go to knowledge base</button>
          </div>
        </div>
      </template>
      <template #loading>
        <tr v-for="n in 5" :key="n">
            <td>
              <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
            </td>
            <td>
              <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
            </td>
            <td>
              <div class="latestShimmerDesign flex-1" style="height:16px;width:60px"></div>
            </td>
            <td class="dialer-col-right">
              <div class="d-flex justify-content-end align-items-center w-100">
                <div class="latestShimmerDesign" style="height:16px;width:50px"></div>
              </div>
            </td>
          </tr>
      </template>
    </vb-table>
    <AddTicketModal 
      :modalName="`${_uid}-AddTicketModal`"
      :categories="categories"
      :departments="departments"
      :types="types"
      @created="fetchTickets();"
    />
    <UpdateTicketModal 
      :modalName="`${_uid}-UpdateTicketModal`"
      @updated="fetchTickets();"
    />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import AddTicketModal from "./Modals/tickets/AddTicket.vue";
import UpdateTicketModal from "./Modals/tickets/UpdateTicket.vue";
export default {
  name: 'ContactSupport',
  components: {
    AddTicketModal,
    UpdateTicketModal,
  },
  inject: [
    'appNotify',
  ],
  data() {
    return {
      api: {
        fetch_tickets: this.$helperFunction.apiInstance({ 
          status: true,  
        }),
      },
      response: {
        tickets: {},
      },
    }
  },
  computed: {
    tickets() { return this.response.tickets?.tickets?.data ?? [] },
    categories(){ return this.response.tickets?.categories ?? [] },
    departments(){ return this.response.tickets?.departments ?? [] },
    types(){ return this.response.tickets?.types ?? [] },
  },
  methods: {
    async fetchTickets() {
      this.api.fetch_tickets.source?.cancel?.("Requesting Again");
      this.api.fetch_tickets.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_tickets.send=true
        const { data } = await VOIP_API.endpoints.tickets.get({},this.api.fetch_tickets.source.token)
        this.response.tickets=data
      } catch (ex) {
        if(!ex.cancel) {
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_tickets.send=false
      }
    },
  },
  mounted(){
    this.fetchTickets()
  },
}
</script>

<style>

</style>