var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-area d-flex h-100 dialer_main_body"
  }, [_c('div', {
    staticClass: "dialer_main_body_inner w-100"
  }, [_vm.conditions.show_side_bar && _vm.getIsMobile || !_vm.getIsMobile ? _c('div', {
    staticClass: "dialer_inner_left_side d-r-768px-none"
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "2"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.show_side_bar = false;
      }
    }
  }), _c('div', {
    staticClass: "first-section"
  }, [_c('h2', [_vm._v("Settings")]), _c('UserProfile')], 1)], 1)] : [_c('h2', {
    staticClass: "dialer_main_title text-capitalize"
  }, [_vm._v("Settings")])], _c('div', {
    staticClass: "dialer_inner_left_side_inner allow-scroll"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.tabs.user, function (tab) {
    return _c('li', {
      key: tab.component,
      class: `dialer_tab_btn ${_vm.GET_LOCAL_SETTING_SETTINGS.tab === tab.component ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          _vm.SET_LOCAL_SETTING_SETTINGS_TAB(tab.component);
          _vm.conditions.show_side_bar = false;
        }
      }
    }, [_c('a', {}, [_c('span', {
      staticClass: "dialer-settingPanel-icon"
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.icon,
        "width": tab.width,
        "height": tab.height
      }
    })], 1), _c('span', {
      staticClass: "dialer-settingPanel-text"
    }, [_vm._v(_vm._s(tab.label))])])]);
  }), 0), _vm.getCurrentUser.administrator_account ? [_c('h2', {
    staticClass: "dialer_tabs_title text-uppercase"
  }, [_vm._v("administrator")]), _c('ul', {
    staticClass: "list-unstyled"
  }, _vm._l(_vm.tabs.admin, function (tab) {
    return _c('li', {
      key: tab.component,
      staticClass: "dialer_tab_btn",
      class: {
        'active': _vm.GET_LOCAL_SETTING_SETTINGS.tab == tab.component
      },
      on: {
        "click": function ($event) {
          _vm.SET_LOCAL_SETTING_SETTINGS_TAB(tab.component);
          _vm.conditions.show_side_bar = false;
        }
      }
    }, [_c('a', {}, [_c('span', {
      staticClass: "dialer-settingPanel-icon"
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.icon,
        "width": tab.width,
        "height": tab.height
      }
    })], 1), _c('span', {
      staticClass: "dialer-settingPanel-text"
    }, [_vm._v(_vm._s(tab.label))])])]);
  }), 0)] : _vm._e()], 2)], 2) : _vm._e(), _c('button', {
    staticClass: "d-r-sidebar-button",
    on: {
      "click": function ($event) {
        return _vm.showSidebar();
      }
    }
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": ['fas', 'bars'],
      "scale": "1.2"
    }
  })], 1), _c('div', {
    staticClass: "dialer_inner_right_side"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticStyle: {
      "position": "absolute",
      "z-index": "1"
    },
    attrs: {
      "icon": "x"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.show_side_bar = true;
      }
    }
  }) : _vm._e(), _c('keep-alive', {
    attrs: {
      "include": _vm.keepAliveTabs
    }
  }, [_c(_vm.GET_LOCAL_SETTING_SETTINGS.tab, {
    tag: "component",
    on: {
      "my-numbers": function ($event) {
        return _vm.SET_LOCAL_SETTING_SETTINGS_TAB(_vm.getCurrentUser.administrator_account ? 'NumbersSettings' : _vm.GET_LOCAL_SETTING_SETTINGS.tab);
      },
      "show-side-bar": function ($event) {
        _vm.conditions.show_side_bar = true;
      }
    }
  })], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }