var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup CreateNewPolicyModal-settings HrmForModalScroll",
    attrs: {
      "name": "CreateNewPolicy",
      "clickToClose": true,
      "scrollable": true
    }
  }, [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Create New Policy")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('CreateNewPolicy');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "grayCard borderRadius-12px flexColumn px-4"
  }, [_c('div', {
    staticClass: "grayCard-smallHeading"
  }, [_vm._v("Add Policy")]), _c('div', {
    staticClass: "d-flex mt-25px"
  }, [_c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("Policy name")]), _c('input', {})]), _c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('vb-color-picker', {
    staticClass: "color-picker-input"
  })], 1)]), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Time off policy description ")]), _c('input', {})]), _c('div', {
    staticClass: "d-flex innerShadowInput-container align-items-center flex-row"
  }, [_c('label', [_vm._v("Allocate ")]), _c('input', {
    staticClass: "wd-68px ml-20px"
  }), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd w-400px ml-20px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("days")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("Accure")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Accure ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("Duration")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Duration ")])], 1)], 1)])]), _c('div', {
    staticClass: "grayCard borderRadius-12px flexColumn px-4 mt-32px"
  }, [_c('div', {
    staticClass: "whiteSubCard p-26px mb-0"
  }, [_c('div', {
    staticClass: "whiteCard-smallHeading"
  }, [_vm._v(" What should we consider to determine working days and daily working hours for this policy? ")]), _c('div', {
    staticClass: "bodyText text-left mt-16px"
  }, [_vm._v(" This will determine days that are considered working days and thus, count against allowance, as well as daily working hours, this value is used to convert days into hours for part day requests. ")]), _c('b-form-group', {
    staticClass: "HrmRadio-container mt-32px wd-46"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Calendar settings ")]), _c('b-form-radio', {
    staticClass: "HrmRadio checked"
  }, [_vm._v(" working pattern ")])], 1), _c('div', {
    staticClass: "d-flex innerShadowInput-container align-items-center flex-row mt-32px"
  }, [_c('label', [_vm._v("Default working hours")]), _c('input', {
    staticClass: "wd-68px ml-20px",
    attrs: {
      "placeholder": "20"
    }
  })]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Advance filters ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Advance filters ")])], 1)]), _c('div', {
    staticClass: "grayCard borderRadius-12px flexColumn px-4 mt-32px"
  }, [_c('div', {
    staticClass: "d-flex innerShadowInput-container align-items-center mb-32px flex-row"
  }, [_c('label', {
    staticClass: "font-13px"
  }, [_vm._v("Default working hours ")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd w-220px ml-20px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("days not carry over")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1), _c('label', {
    staticClass: "ml-20px font-13px"
  }, [_vm._v("(this change will be applied only on the next yearly reset)")])], 1), _c('div', {
    staticClass: "innerShadowInput-container mb-0"
  }, [_c('label', {}, [_vm._v("Do not accrue when taking leave from one of these policies")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd w-536px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1)]), _c('div', {
    staticClass: "collapseAble-conatiner mt-32px"
  }, [_c('div', {
    class: `collapseAble-head ${_vm.showCollapsableItems == 'first' ? 'opened' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showCollapsableItems = 'first';
      }
    }
  }, [_c('div', {
    staticClass: "collapseAble-headText"
  }, [_vm._v("General")]), _c('b-icon', {
    staticClass: "downIcon",
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.3"
    }
  })], 1), _c('div', {
    class: `collapseAble-body ${_vm.showCollapsableItems == 'first' ? 'opened' : ''}`
  }, [_c('div', {
    staticClass: "smallText w-100"
  }, [_vm._v("0 days used")])])]), _c('div', {
    staticClass: "collapseAble-conatiner mt-32px"
  }, [_c('div', {
    class: `collapseAble-head ${_vm.showCollapsableItems == '3rd' ? 'opened' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showCollapsableItems = '3rd';
      }
    }
  }, [_c('div', {
    staticClass: "collapseAble-headText"
  }, [_vm._v("Conditions")]), _c('b-icon', {
    staticClass: "downIcon",
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.3"
    }
  })], 1), _c('div', {
    class: `collapseAble-body ${_vm.showCollapsableItems == '3rd' ? 'opened' : ''}`
  }, [_c('div', {
    staticClass: "smallText w-100"
  }, [_vm._v("0 days used")])])]), _c('div', {
    staticClass: "collapseAble-conatiner mt-32px"
  }, [_c('div', {
    class: `collapseAble-head ${_vm.showCollapsableItems == '4th' ? 'opened' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showCollapsableItems = '4th';
      }
    }
  }, [_c('div', {
    staticClass: "collapseAble-headText"
  }, [_vm._v("Allowance")]), _c('b-icon', {
    staticClass: "downIcon",
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.3"
    }
  })], 1), _c('div', {
    class: `collapseAble-body ${_vm.showCollapsableItems == '4th' ? 'opened' : ''}`
  }, [_c('div', {
    staticClass: "smallText w-100"
  }, [_vm._v("0 days used")])])]), _c('div', {
    staticClass: "collapseAble-conatiner mt-32px"
  }, [_c('div', {
    class: `collapseAble-head ${_vm.showCollapsableItems == '5th' ? 'opened' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showCollapsableItems = '5th';
      }
    }
  }, [_c('div', {
    staticClass: "collapseAble-headText"
  }, [_vm._v("Workflow")]), _c('b-icon', {
    staticClass: "downIcon",
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.3"
    }
  })], 1), _c('div', {
    class: `collapseAble-body ${_vm.showCollapsableItems == '5th' ? 'opened' : ''}`
  }, [_c('div', {
    staticClass: "smallText w-100"
  }, [_vm._v("0 days used")])])]), _c('div', {
    staticClass: "collapseAble-conatiner mt-32px"
  }, [_c('div', {
    class: `collapseAble-head ${_vm.showCollapsableItems == '6th' ? 'opened' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showCollapsableItems = '6th';
      }
    }
  }, [_c('div', {
    staticClass: "collapseAble-headText"
  }, [_vm._v("Additional fields")]), _c('b-icon', {
    staticClass: "downIcon",
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.3"
    }
  })], 1), _c('div', {
    class: `collapseAble-body ${_vm.showCollapsableItems == '6th' ? 'opened' : ''}`
  }, [_c('div', {
    staticClass: "smallText w-100"
  }, [_vm._v("0 days used")])])]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Create ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }