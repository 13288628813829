<template>
  <div class="dialer-analytics-main dialer-analytics-numbers">
    <template v-if="conditions.select_tab">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.select_tab = false" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Date</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
    </template>
    <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Numbers</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div class="dialer-box AnalyticsContainerInner">
      <template v-if="conditions.select_tab">
        
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item" v-for="(tab, index) in tabs" :key="index" 
            @click="selected.tab_index = index;selectedTab.value=='custom' ?  conditions.select_tab = true : conditions.select_tab = false"
          >
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <div class="headingInsideTable">{{ tab.text }}</div>
              </div>  
            </div>
            <vb-icon v-if="selected.tab_index == index" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
          </div>
        </div>
        <div v-if="selectedTab.value=='custom'" class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2 forDateTime">
              <label>From</label>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.number_analytics.start_date" id="starttime" />
            </div>
          </div>
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2 forDateTime">
              <label>To</label>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.number_analytics.end_date" id="endtime" />
            </div>
          </div>
          
        </div>
        <a class="IosFullWidthButton forDone mb-16px" @click="fetchnumbersanalytics();conditions.select_tab = false">Fetch</a>
      </template>
      <template v-else>
        <template>
          <div class="mobileInputIOS iconOnLeft mt-32px">
            <b-input  type="text" placeholder="Search user" v-model="filter.number_analytics.search" />
            <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
          </div>
        </template>
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item" @click="conditions.select_tab=!conditions.select_tab">
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <div class="headingInsideTable">Date</div>
              </div>  
            </div>
            <div class="d-flex align-items-center">
              <div class="textInsideCard textAlignRight onRightSide mr-8px">{{ selectedTab.text }}</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
          </div>
        </div>

        <div class="IosTabsSection twoTabs mt-16px mb-16px">
          <div :class="{ 'active': !conditions.advanced }" class="IosTabButton" @click="conditions.advanced=false">Basic</div>
          <div :class="{ 'active': conditions.advanced }" class="IosTabButton" @click="conditions.advanced=true">Advance</div>
        </div>

        <div class="basicWhiteIOScard mt-16px mb-16px">
          <div :class="`basicWhiteIOScard-item ${conditions.advanced ? 'withAdvancedSection' : ''}`" v-for="number in filterNumbers" :key="number.number">
            <div class="w-100 d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <img class="country-img" width="45px" :src="number.number | number_formater | flag_icon"  />
                </div>
                <div class="headingInsideTable">{{ number.number | number_formater }}</div>
              </div>

              

              <div class="d-flex align-items-center">
                <div class="d-flex mr-6px">
                  <div class="textInsideCard onRightSide textAlignRight" style="color: #87868C;">{{ number.totalCalls }}</div>
                  <div class="textInsideCard onRightSide textAlignRight mx-1">/</div>
                  <div class="textInsideCard onRightSide textAlignRight" style="color: #FF1900;">{{ number.totalMissCalls }}</div>
                </div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
              </div>
            </div>
            
            <div class="advanceSection" v-if="conditions.advanced">

              <div class="advanceSection-inner InboundCall">
                <div class="aboveProgressBar">
                  <vb-icon icon="CallSummary-InboundCall-icon" class="rightFacingArrow mr-8px" width="16.642" height="15.275" />
                  <div class="aboveProgressBar-text">{{ number.totalIncommingCalls }}</div>
                </div>
                <vb-circular-progressive-bar 
                  :percentage="number.totalIncommingCalls > 0 ? Math.ceil((number.totalIncommingCalls / number.totalCalls) * 100) : 0"
                />
              </div>
              
              <div class="advanceSection-inner outboundCall">
                <div class="aboveProgressBar">
                  <vb-icon icon="CallSummary-OutboundCall-icon" class="rightFacingArrow mr-8px" width="16.642" height="15.275" />
                  <div class="aboveProgressBar-text">{{ number.totalOutgoingCalls }}</div>
                </div>
                <vb-circular-progressive-bar 
                  :percentage="number.totalOutgoingCalls > 0 ? Math.ceil((number.totalOutgoingCalls / number.totalCalls) * 100) : 0"
                />
              </div>

              <div class="advanceSection-inner missedCall">
                <div class="aboveProgressBar">
                  <vb-icon icon="CallSummary-MissedCall-icon" class="rightFacingArrow mr-8px" width="16.642" height="15.275" />
                  <div class="aboveProgressBar-text">{{ number.totalMissCalls }}</div>
                </div>
                <vb-circular-progressive-bar 
                  :percentage="number.totalMissCalls > 0 ? Math.ceil((number.totalMissCalls / number.totalCalls) * 100) : 0"
                />
              </div>

            </div>
          </div>
        </div>
        <vb-no-record v-if="filterNumbers.length==0" :text="api.number_analytics.send?'':'There is no Number'">
          <vb-loading v-if="api.number_analytics.send" slot="loading" />
        </vb-no-record>
      </template>

    </div>

  </div>
</template>

<script>

import { VOIP_API } from "@/utils/index";
import { ctkDateTimePicker } from '@/mixin';

var moment = require("moment-timezone");

export default {
  name: "Numbers",
  mixins: [ctkDateTimePicker],
  data() {
    return {
      conditions: {
        select_tab: false,
        advanced: false,
      },
      api: {
        number_analytics: this.$helperFunction.apiInstance(),
      },
      filter: {
        number_analytics: {
          search: '',
          date: null,
          start_date: null,
          end_date: null,
        },
      },
      selected: {
        tab_index: 0,
      },
      response: {
        number_analytics: {
          timeLineData: [],
          kpis: {},
          numbers: {},
        },
      },
    };
  },
  inject:['isEmpty'],
  filters: {
    secondsTimer(value) {
      const new_value = parseFloat(value)
      if (!new_value) return value;
      let sec = Math.floor(new_value) % 60;
      let min = Math.floor(new_value / 60) % 60;
      let hours = Math.floor(new_value / 60 / 60) % 60;
      return `${hours < 10 ? "0" + hours : hours}:${min < 10 ? "0" + min : min}:${sec < 10 ? "0" + sec : sec}`;
    },
  },
  computed: {
    filterNumbers(){
      const numbers = this.response.number_analytics.numbers
      const kpis = this.response.number_analytics.kpis
      const search = this.filter.number_analytics.search.trim().toLowerCase()
      const array = Object.values(numbers).map(item=>{
        return {
          ...item,
          kpis: kpis[item.number],
        }
      })
      return array.filter(item=>`${(item.number || '').replace(/-/g,'')}`.trim().toLowerCase().includes(search))
    },
    tabs(){
      return [
        {
          text: "Today",
          value: "today",
        },
        {
          text: "This Week",
          value: "week",
        },
        {
          text: "This Month",
          value: "month",
        },
        {
          text: "Custom",
          value: "custom",
        },
      ]
    },
    selectedTab(){
      const tab = this.selected.tab_index
      return this.tabs[tab]
    },
  },
  watch: {
    selectedTab(tab){
      if(tab && tab.value && tab.value!='custom'){
        this.filter.number_analytics.start_date=''
        this.filter.number_analytics.end_date=''
      }
      if(tab){
        this.fetchnumbersanalytics()
      }
    },
  },
  methods: {
    fetchnumbersanalytics() {
      let vm = this
      if(!vm.selectedTab && !vm.selectedTab.value) return;
      vm.api.number_analytics.send=true
      vm.filter.number_analytics.date = '';
      vm.response.number_analytics.numbers = [];
      vm.response.number_analytics.kpis = {};
      vm.response.number_analytics.timeLineData = {};
      VOIP_API.endpoints.analytics.list({
        type: vm.selectedTab.value,
        section: "numbers",
        start: vm.filter.number_analytics.start_date,
        end: vm.filter.number_analytics.end_date,
      })
      .then(({ data: { kpis, data, timeLineData } }) => {
        vm.filter.number_analytics.date = data.date_label;
        vm.response.number_analytics.numbers = data.numbers;
        vm.response.number_analytics.kpis = kpis;
        vm.response.number_analytics.timeLineData = timeLineData;
      })
      .finally(() => {
        vm.api.number_analytics.send=false
      });
    },
  },
  mounted() {
    this.filter.number_analytics.date = moment().format("ddd, Do MMM");
    this.fetchnumbersanalytics();
  },
};
</script>
