<template> 
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">{{ data.title || title }}</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">
        <div class="IosTabsSection fourTabs mt-16px mb-16px">
          <div
            v-for="tab in tabs" 
            :key="tab.value" 
            @click="selected.tab = tab" 
            :class="{ 'active': selected.tab.value === tab.value}"
            class="IosTabButton"
          >
            {{ tab.text }}
          </div>
        </div>
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-form-input :placeholder="`Search ${selected.tab.text}`" v-model="filter.list.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
        <div class="lg-mar-bottom">
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              class="basicWhiteIOScard-item" 
              v-for="extension in filterList" 
              :key="extension.accountcode" 
              @click="selected.extension=extension.accountcode;updateSelectedAccount()"
            >
              <Info class="InfoComponentInsideAnroidIOSApp" :id="extension.extn" :name="extension.accountname | check_empty('None')" :sub_info="extension.extn" />
              <vb-icon v-if="selected.extension==extension.accountcode" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
              
            </div>
            <vb-no-record v-if="filterList.length==0" :text="api.extensions.send?'':'There is no playlist'" :design="3">
              <vb-loading v-if="api.extensions.send" slot="loading" />
            </vb-no-record>
          </div>
        </div>

      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from "@/utils";
import Info from "../../../Lists/Info.vue";
let tabs = {
  team: {
    text: 'Team',
    value: 1,
  },
  ivr: {
    // text: 'Basic IVR',
    text: 'IVR',
    value: 2,
  },
  user: {
    text: 'User',
    value: 3,
  },
  queue: {
    text: 'Call Queue',
    value: 4,
  },
}
export default {
  name: "SelectExtensionModal",
  components: {
    Info,
  },
  props: {
    modalName: {
      type: String,
      default: 'SelectExtension'
    },
    title: {
      type: String,
      default: 'Select Extension'
    },
  },
  data() {
    return {
      data: {
        type: null,
        extra_data: {},
        title: '',
      },
      api: {
        extensions: this.$helperFunction.apiInstance(),
      },
      selected: {
        tab: tabs.user,
        extension: '',
      },
      response: {
        accounts: {},
        extensions: {},
      },
      filter: {
        list: {
          search: '',
        },
      },
    };
  },
  computed: {
    filterList(){
      const search = this.filter.list.search.trim().toLowerCase()
      const tab = this.selected.tab
      const accounts = this.response.accounts
      let account = ''
      if(tab.value==tabs.team.value) account = accounts.TEAM
      if(tab.value==tabs.ivr.value) account = accounts.BASICIVR
      if(tab.value==tabs.user.value) account = accounts.USER
      if(tab.value==tabs.queue.value) account = accounts.QUEUE
      return Object.values(this.response.extensions).filter(extension=>extension.account === account && `${extension.accountname} ${extension.extn}`.trim().toLowerCase().includes(search))
    },
    tabs(){ return tabs },
  },
  filters: {
    firstCharacter: function (value) {
      if (!value) return "-";
      return value.charAt(0).toUpperCase();
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.type = event?.params?.type ?? '';
      this.selected.extension = event?.params?.account ?? '';
      this.data.extra_data = event?.params?.extra_data ?? {};
      this.fetchExtensions();
    },
    onBeforeClose() {
      this.data.type = null;
      this.data.extra_data = {};
      this.api.extensions?.reset?.();
      this.selected.extension = '';
      this.selected.tab = tabs.user;
      this.response.accounts = '';
      this.response.extensions = '';
      this.filter.list.search = '';
    },
    fetchExtensions() {
      let vm = this
      vm.api.extensions.send = true;
      VOIP_API.endpoints.telephone_number.extensions()
      .then(({ data: res }) => {
        vm.response.extensions = res?.data ?? {};
        vm.response.accounts = res?.accounts ?? {};
      })
      .finally(() => {
        vm.api.extensions.send = false;
      });
    },
    updateSelectedAccount() {
      if(!this.selected.extension) return;
      let extension = Object.values(this.response.extensions).find((value) => value.accountcode == this.selected.extension);
      this.$emit("selected", {
        account: this.selected.extension,
        accountname: extension?.accountname ?? 'None',
        extn: extension.extn,
        type: this.data.type,
        extra_data: this.data.extra_data
      });
      this.$modal.hide(this.modalName);
    },
  },
};
</script>

<style lang="scss">
.slideToRigth-enter,
.slideToRigth-leave-active {
  right: -100%;
}
</style>
