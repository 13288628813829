import { AsYouType } from 'libphonenumber-js';

export const tag_color = {
  tagColor(el,binding){
    const { bg, text, border } = binding?.modifiers ?? {}
    const arg = binding?.arg
    const value = binding?.value
    const color = value?.color || (typeof value == 'string' ? value : '') || arg || 'white'
    const text_color = value?.text_color || (typeof value == 'string' ? value : '') || arg || 'black'
    if(text) el.style.color=text_color
    if(bg || (!text && !bg)) {
      el.style.backgroundColor=color
      if(border) el.style.borderColor==color
    }
  },
  bind: (el,binding)=>{binding.def.tagColor(el,binding)},
  update: (el,binding)=>{binding.def.tagColor(el,binding)},
  inserted: (el,binding)=>{binding.def.tagColor(el,binding)},
  componentUpdated: (el,binding)=>{binding.def.tagColor(el,binding)},
  unbind: (el,binding)=>{binding.def.tagColor(el,binding)},
}
export const input_number_formate = {
  oninput(event){
    const { country, /*national_format,*/ as_you_type, auto_start_plus } = this.def
    let value = event.target.value
    const prev_cursor_position = event.target.selectionStart
    if(!country && auto_start_plus && value) value = event.target.value?.startsWith?.('+') ? event.target.value : `+${event.target.value}`
    let formatted = value;
    if(as_you_type){
      formatted = as_you_type.input(value)
      if(as_you_type.country && as_you_type.defaultCountry && as_you_type.defaultCountry!=as_you_type.country) formatted=value
    }
    event.target.value = formatted
    const after_cursor_position = event.target.selectionStart
    if(after_cursor_position-1==prev_cursor_position) event.target.setSelectionRange(after_cursor_position, after_cursor_position);
    else event.target.setSelectionRange(prev_cursor_position, prev_cursor_position);
  },
  setCountry(country){
    this.def.country=country
    this.def.as_you_type= new AsYouType(country || '')
  },
  inserted(el,binding){
    const { national, not_start_plus } = binding?.modifiers ?? {}
    binding.def.national_format=!!national
    binding.def.auto_start_plus=not_start_plus ? false : true
    binding.def.setCountry.call(binding,binding.value)
  },
  bind(el,binding){
    el.addEventListener('input',binding.def.oninput.bind(binding))
  },
  update(el,binding){
    binding.def.setCountry.call(binding,binding.value)
  },
  unbind(el,binding){
    el.removeEventListener('input',binding.def.oninput.bind(binding))
  },
}