var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.user.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add User By Email")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CheckedSlot', {
          service: 'user',
          onProceed: _vm.adduser
        });
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Detail")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.adduser();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.user.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.user.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("First Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.user.firstname,
      expression: "forms.user.firstname"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter First Name",
      "maxlength": _vm.$v.forms.user.firstname.$params.maxLength.max,
      "type": "text",
      "disabled": _vm.api.user.send
    },
    domProps: {
      "value": _vm.forms.user.firstname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.user, "firstname", $event.target.value);
      }
    }
  }), _vm.forms.user.submitted && _vm.$v.forms.user.firstname.$invalid || _vm.api.user.validation_errors.username || _vm.api.user.validation_errors.firstname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.user.firstname.required ? _c('span', [_vm._v("* First Name is required")]) : _vm._e(), !_vm.$v.forms.user.firstname.alpha ? _c('span', [_vm._v("* First Name should be onlu alphabet character only")]) : !_vm.$v.forms.user.firstname.minLength ? _c('span', [_vm._v("* First Name should be minimum " + _vm._s(_vm.$v.forms.user.firstname.$params.minLength.min) + " character")]) : !_vm.$v.forms.user.firstname.maxLength ? _c('span', [_vm._v("* First Name can be maximum " + _vm._s(_vm.$v.forms.user.firstname.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.user.validation_errors.firstname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Last Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.user.lastname,
      expression: "forms.user.lastname"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Last Name",
      "type": "text",
      "maxlength": _vm.$v.forms.user.lastname.$params.maxLength.max,
      "disabled": _vm.api.user.send
    },
    domProps: {
      "value": _vm.forms.user.lastname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.user, "lastname", $event.target.value);
      }
    }
  }), _vm.forms.user.submitted && _vm.$v.forms.user.lastname.$invalid || _vm.api.user.validation_errors.username || _vm.api.user.validation_errors.lastname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.user.lastname.required ? _c('span', [_vm._v("* Last Name is required")]) : _vm._e(), !_vm.$v.forms.user.lastname.alpha ? _c('span', [_vm._v("* Last Name should be onlu alphabet character only")]) : !_vm.$v.forms.user.lastname.minLength ? _c('span', [_vm._v("* Last Name should be minimum " + _vm._s(_vm.$v.forms.user.lastname.$params.minLength.min) + " character")]) : !_vm.$v.forms.user.lastname.maxLength ? _c('span', [_vm._v("* Last Name can be maximum " + _vm._s(_vm.$v.forms.user.lastname.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.user.validation_errors.lastname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Email")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.user.email,
      expression: "forms.user.email"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Email",
      "type": "text",
      "disabled": _vm.api.user.send
    },
    domProps: {
      "value": _vm.forms.user.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.user, "email", $event.target.value);
      }
    }
  }), _vm.forms.user.submitted && _vm.$v.forms.user.email.$invalid || _vm.api.user.validation_errors.username || _vm.api.user.validation_errors.email ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.user.email.required ? _c('span', [_vm._v("* Email is Required")]) : !_vm.$v.forms.user.email.email ? _c('span', [_vm._v("* Email is not valid")]) : !_vm.$v.forms.user.email.minLength ? _c('span', [_vm._v("* Email should be minimum " + _vm._s(_vm.$v.forms.user.email.$params.minLength.min) + " character")]) : _vm._e(), _vm._l(_vm.api.user.validation_errors.username, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  }), _vm._l(_vm.api.user.validation_errors.email, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-start"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Set as admin")])])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.user.send,
      "switch": ""
    },
    model: {
      value: _vm.forms.user.role,
      callback: function ($$v) {
        _vm.$set(_vm.forms.user, "role", $$v);
      },
      expression: "forms.user.role"
    }
  })], 1), _vm.forms.user.submitted && _vm.$v.forms.user.role.$invalid || _vm.api.user.validation_errors.role ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.user.role.required ? _c('span', [_vm._v("* Email is Required")]) : _vm._e(), _vm._l(_vm.api.user.validation_errors.role, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.user.send
    }
  }, [_vm.api.user.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }