var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 min-width-50percent-modal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "closed": _vm.onClosed,
      "before-open": _vm.onBeforeOpen
    }
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Edit pickup group")]), _c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.edit || _vm.api_sent.delete
    },
    on: {
      "click": _vm.edit
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.edit ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0 d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newBackButton mr-16px",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Edit pickup group ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.edit || _vm.api_sent.delete
    },
    on: {
      "click": _vm.edit
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.edit ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]), _c('b-alert', {
    staticClass: "alert-danger",
    attrs: {
      "dismissible": true,
      "fade": true,
      "show": !!_vm.error
    },
    on: {
      "dismissed": function ($event) {
        _vm.error = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.error))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.edit.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Name")]), _c('b-form-input', {
    attrs: {
      "disabled": _vm.api_sent.edit || _vm.api_sent.delete,
      "type": "text",
      "placeholder": "Enter Name"
    },
    model: {
      value: _vm.forms.update.name,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update, "name", $$v);
      },
      expression: "forms.update.name"
    }
  }), _vm.submitted.edit ? [!_vm.$v.forms.update.name.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_vm._v("*Name is required")]) : _vm._e()] : _vm._e()], 2)])], 1), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center mt-20px"
  }, [_c('h3', {
    staticClass: "latestGreyBox-heading-main mb-0"
  }, [_vm._v("Extension assign")]), _c('div', {
    staticClass: "lg-mar-left"
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "type": "button",
      "disabled": _vm.api_sent.delete
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddMemberInPickupGroupsModal', {
          pickupgroup_id: _vm.pickupgroup.id
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add/Remove extension")])], 1)])]), _vm.api_sent.list ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "45px",
        "width": "45px"
      }
    }), _c('div', {
      staticClass: "d-flex flex-column sm-mar-left sm-mar-right"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "22px",
        "width": "200px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign mt-1",
      staticStyle: {
        "height": "22px",
        "width": "100px"
      }
    })])]);
  }), 0) : Object.keys(_vm.response.selected).length > 0 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, _vm._l(_vm.response.selected, function (data) {
    return _c('div', {
      key: data.accountcode,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-unset"
    }, [_c('Info', {
      attrs: {
        "id": data.accountcode,
        "is_blf": false
      }
    })], 1);
  }), 0) : _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v("No extension assigned")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this pickup group?")]), _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "disabled": _vm.api_sent.delete
    },
    on: {
      "click": function ($event) {
        return _vm.deletePickGroup();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.delete ? _c('vb-spinner') : _c('span', [_vm._v("Delete")])], 1)], 1)])])], 1), _c('AddMemberInPickupGroups', {
    on: {
      "updated": function ($event) {
        return _vm.getmembers();
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }