<template>
  <svg
    :width="width || size"
    :height="height || size"
    :viewBox="iconObj.viewBox"
    :fill="iconObj.fill"
    :stroke-width="iconObj['stroke-width']"
    :stroke-linecap="iconObj['stroke-linecap']"
    :stroke-linejoin="iconObj['stroke-linejoin']"
    :stroke="iconObj.stroke"
    @click="$emit('click')"
    @mouseover="$emit('mouseover')"
    @mouseleave="$emit('mouseleave')"
    v-html="iconObj.icon"
  ></svg>
</template>

<script>
import { APP_ICONS } from '@/constants'
export default {

  name: "vb-icon",
  compact: true,
  props: {
    size: {
      type: [String,Number],
      default: "10",
    },
    height: {
      type: [String,Number],
      default: "",
    },
    width: {
      type: [String,Number],
      default: "",
    },
    viewBox: {
      type: String,
    },
    icon: {
      type: String,
      default: "",
    },
  },
  computed: {
    iconObj() {
      return APP_ICONS[this.icon || ""] || {};
    },
  },
};
</script>

<style lang="scss">
svg[fill="#005dff"],
circle[fill="#005dff"],
path[fill="#005dff"],
rect[fill="#005dff"] {
  fill: $primary;
}
svg[stroke="#005dff"],
circle[stroke="#005dff"],
path[stroke="#005dff"],
rect[stroke="#005dff"] {
  stroke: $primary;
}
</style>
