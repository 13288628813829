var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Numbers")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddNumber');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    }
  })], 1)]), _c('section', {
    staticClass: "dialer-settings-section dialer_numberSetting"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search numbers"
    },
    model: {
      value: _vm.filter.numbers.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.numbers, "search", $$v);
      },
      expression: "filter.numbers.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.filterTelephoneNumbers, function (number) {
    return _c('div', {
      key: `${number.id}-${number.object}`,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          number.object != 'CallerId' ? _vm.$modal.show('EditNumber', {
            number: number
          }) : '';
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "basicIOSIcon mr-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": number.object === 'TelephoneNumbers' ? 'MyNumbers-TelephoneNumber-IOSIcon' : 'MyNumbers-CallerID-IOSIcon'
      }
    })], 1), _c('div', {
      staticClass: "d-flex flex-column"
    }, [number.object === 'TelephoneNumbers' ? [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(" " + _vm._s(_vm._f("number_formater")(number.number)) + " ")])] : [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(" " + _vm._s(_vm._f("number_formater")(number.number)) + " ")])]], 2)]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "d-flex mr-6px align-items-center"
    }, [_c('div', {
      staticClass: "InfoComponentInsideAnroidIOSApp textAlignRight mr-8px"
    }, [number.extnType ? _c('Info', {
      attrs: {
        "is_image_show": false,
        "name": _vm._f("get_property")(number, 'assignedToUsersArr.label')
      }
    }) : _vm._e()], 1)]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)]);
  }), 0)]), _c('EditNumbersModal', {
    on: {
      "latest": function ($event) {
        return _vm.afterUpdating(false);
      }
    }
  }), _c('AddNumberModal', {
    on: {
      "added": function ($event) {
        return _vm.afterUpdating(true);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }