var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "aiTranscriptModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-between mb-0 dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("AI transcription")]), _c('div', [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _vm.data.call.call_type == 'audio' && _vm.data.call.soundFile && _vm.data.call.status != 'Missed' ? _c('AudioTranscriptDummy', {
    attrs: {
      "soundFile": _vm.data.call.soundFile,
      "uniqueID": _vm.data.call.uniqueid,
      "call": _vm.data.call,
      "CallItemShow": _vm.CallItemShow,
      "twoPartsView": true
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }