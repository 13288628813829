<template>
  <div class="hrm-home-container">
    <div class="hrm-reports-container">
      <div class="reportsLeftSideBar">
        <div class="sideBarHeading-container">
          <div class="sideBarHeading">
            
            Reports
          </div>
        </div>
        <div class="sideBarList-container">
          <div class="sideBarList-inner">
            <div class="sideBarList">
              <template v-for="report in reports" >
                <div 
                  :key="report.id" 
                  @click="selected.tab=report.childrens ? report.childrens[0].component : report.component" 
                  class="sideBarList-item"
                  :class="{
                    'active': selectedTab.id == report.id
                  }"
                >
                  <div class="sideBarList-itemLeftSide">
                    <vb-icon :icon="report.icon" height="17px" width="17px" class="sideBarList-icon" />
                    <div class="sideBarList-itemText">{{report.text}}</div>
                  </div>
                  <b-icon v-if="report.childrens" class="downIcon" icon="chevron-down" font-scale="1.3"/>
                </div>
                <div 
                  :key="`children-${report.id}`"
                  v-if="report.childrens"
                  :class="{
                    'show h-auto': selectedTab.id == report.id
                  }"
                  class="sideBarSubItemsList"
                >
                  <div 
                    v-for="children in report.childrens" 
                    :key="children.component"
                    @click="selected.tab=children.component"
                    class="sideBarList-item"
                    :class="{
                      'active': children.component==selected.tab
                    }"
                  >
                    <div class="sideBarList-itemLeftSide">
                      <div class="sideBarList-itemText">{{children.text}}</div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="reportsMainBody ">
        <component :is="selected.tab" />
      </div>
    </div>
  </div>
</template>

<script>
import Overview from "@/components/HRM/reports/Hrdashboard/Overview.vue";
import Headcount from "@/components/HRM/reports/Hrdashboard/Headcount.vue";
import HeadcountTurnover from "@/components/HRM/reports/Hrdashboard/HeadcountTurnover.vue";
import Compensation from "@/components/HRM/reports/Hrdashboard/Compensation.vue";
import Gender from "@/components/HRM/reports/Hrdashboard/Gender.vue";
import Age from "@/components/HRM/reports/Hrdashboard/Age.vue";
import LengthOfService from "@/components/HRM/reports/Hrdashboard/LengthOfService.vue";
import TimeOffOverview from "@/components/HRM/reports/Timeoff/Overview.vue";
import TimeOffReports from "@/components/HRM/reports/Timeoff/TimeOffReports.vue";
import TimeOffAwaitingApproval from "@/components/HRM/reports/Timeoff/TimeOffAwaitingApproval.vue";
// import calendar from "../components/calendar.vue";
import EmployeeReports from "@/components/HRM/reports/Timeoff/EmployeeReports.vue";
import UnusedDays from "@/components/HRM/reports/Timeoff/UnusedDays.vue";
import IndividualAllowances from "@/components/HRM/reports/Timeoff/IndividualAllowances.vue";
import BirthdaysAnniversaries from "@/components/HRM/reports/Timeoff/BirthdaysAnniversaries.vue";
import LongTermLeave from "@/components/HRM/reports/Timeoff/LongTermLeave.vue";
import TimeOffLedger from "@/components/HRM/reports/Timeoff/TimeOffLedger.vue";
import EmployeeDataAssets from "@/components/HRM/reports/Employeedata/Assets.vue";
import EmployeeDataTasks from "@/components/HRM/reports/Employeedata/Tasks.vue";
import EmployeeDataNotes from "@/components/HRM/reports/Employeedata/Notes.vue";
import EmployeeTrainingEvents from "@/components/HRM/reports/Employeedata/TrainingEvents.vue";
import EmployeeDataDocuments from "@/components/HRM/reports/Employeedata/Documents.vue";
import EmployeeEmergencyContacts from "@/components/HRM/reports/Employeedata/EmergencyContacts.vue";
import TerminatedEmployees from "@/components/HRM/reports/Employeedata/TerminatedEmployees.vue";
import OnboardingOverview from "@/components/HRM/reports/Onboarding/Overview.vue";
import OnboardingTasks from "@/components/HRM/reports/Onboarding/Tasks.vue";
import AccessLogs from "@/components/HRM/reports/AccessLogs.vue";
import HrmCalender from "@/views/hrm/fahad/Calender.vue";
const reports = [
  {
    text: 'HR Dashboard',
    icon: 'ReportsSideBar-HrDashboard-hrmIcon',
    id: '1',
    childrens: [
      {
        text: 'OverView',
        component: 'Overview',
      },
      {
        text: 'Headcount',
        component: 'Headcount',
      },
      {
        text: 'Headcount turnover',
        component: 'HeadcountTurnover',
      },
      {
        text: 'Compensation',
        component: 'Compensation',
      },
      {
        text: 'Gender',
        component: 'Gender',
      },
      {
        text: 'Age',
        component: 'Age',
      },
      {
        text: 'Length of service',
        component: 'LengthOfService',
      }
    ]
  },
  {
    text: 'Time off',
    icon: 'ReportsSideBar-timeOff-hrmIcon',
    id: '2',
    childrens: [
      {
        text: 'Overview',
        component: 'timeOffOverview',
      },
      {
        text: 'Time off reports',
        component: 'TimeOffReports',
      },
      {
        text: 'Awaiting approval',
        component: 'TimeOffAwaitingApproval',
      },
      {
        text: 'Employee reports',
        component: 'EmployeeReports',
      },
      {
        text: 'Replacement persons',
        component: '',
      },
      {
        text: 'Unused days',
        component: 'UnusedDays',
      },
      {
        text: 'Individual allowances',
        component: 'IndividualAllowances',
      },
      {
        text: 'Anniversaries',
        component: 'BirthdaysAnniversaries',
      },
      {
        text: 'Long-term Leave',
        component: 'LongTermLeave',
      },
      {
        text: 'Time off ledger',
        component: 'TimeOffLedger',
      }
    ]
  },
  {
    text: 'Employee data',
    icon: 'ReportsSideBar-EmployeeData-hrmIcon',
    id: '3',
    childrens: [
      {
        text: "Assets",
        component: "EmployeeDataAssets",
      },
      {
        text: "Tasks",
        component: "EmployeeDataTasks",
      },
      {
        text: "Notes",
        component: "EmployeeDataNotes",
      },
      {
        text: "Training",
        component: "",
      },
      {
        text: "Training events",
        component: "EmployeeTrainingEvents",
      },
      {
        text: "Documents",
        component: "EmployeeDataDocuments",
      },
      {
        text: "Offboarding",
        component: "",
      },
      {
        text: "Emergency contacts",
        component: "EmployeeEmergencyContacts",
      },
      {
        text: "Terminated employees",
        component: "TerminatedEmployees",
      },
      {
        text: "Custom reports",
        component: "",
      },
    ]
  },
  {
    text: "Onboarding",
    icon: 'ReportsSideBar-Onboarding-hrmIcon',
    id: '4',
    childrens:[
      {
        text: "Overview",
        component: "OnboardingOverview",
      },
      {
        text: "Tasks",
        component: "OnboardingTasks",
      }
    ]
  },
  {
    id: '5',
    text: 'Access logs',
    component: 'AccessLogs',
    icon: 'ReportsSideBar-AccessLogs-hrmIcon',
  },
  {
    id: '6',
    text: 'Calender',
    component: 'HrmCalender',
    icon: 'ReportsSideBar-AccessLogs-hrmIcon',
  }
]
export default {
  name: 'HRMReports',
  components: {
    Overview,
    Headcount,
    HeadcountTurnover,
    Compensation,
    Gender,
    Age,
    LengthOfService,
    TimeOffOverview,
    TimeOffReports,
    TimeOffAwaitingApproval,
    // calendar,
    EmployeeReports,
    UnusedDays,
    IndividualAllowances,
    BirthdaysAnniversaries,
    LongTermLeave,
    TimeOffLedger,
    EmployeeDataAssets,
    EmployeeDataTasks,
    EmployeeDataNotes,
    EmployeeTrainingEvents,
    EmployeeDataDocuments,
    EmployeeEmergencyContacts,
    TerminatedEmployees,
    OnboardingOverview,
    OnboardingTasks,
    AccessLogs,
    HrmCalender,
  },
  data(){
    return {
      selected: {
        tab: 'Overview',
      },
    }
  },
  computed: {
    reports(){ return reports },
    selectedTab(){
      const component = this.selected.tab
      const tab = this.reports.find(tab=>tab.childrens ? !!tab.childrens.find(c=>c.component==component) : tab.component==component)
      return tab
    },
  },
}
</script>

<style>

</style>