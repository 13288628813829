<template>
  <div class="dialer-settings-main">
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Users</div>
      <div class="plus-IOSIcon-container">
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992" @click="$modal.show('AddUserANDSubUser')" />
      </div>
    </div>
    <section class="dialer-settings-section dialer-users-setting">
      <template>
        
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-input type="text" placeholder="Search user" v-model.lazy="filter.list.search"/>
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
      </template>
      
      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div 
          class="basicWhiteIOScard-item" 
          v-for="data in filterList" 
          :key="data.id || data.voipaccount" 
          @click="!data.voipaccount || data.active_status != '1' || api.delete_user.send?'':
            $modal.show('EditUser', { account: data.voipaccount })
          "
        >
          <div class="d-flex align-items-center">
            <div class="InfoComponentInsideAnroidIOSApp">
              <vb-avatar :id="data.voipaccount" :is_blf="false" />
            </div>
            <div class="d-flex flex-column">
              <template v-if="!data.voipaccount">
                <div class="textInsideCard ">
                  {{ data.email }}
                </div>
              </template>
              <template v-else>
                <div class="headingInsideTable ">
                  {{data | get_property('user.display_name') }}
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column mr-6px">
              <div v-if="data.voipaccount" class="textInsideCard textAlignRight onRightSide">
                {{["1", "3", 1].indexOf(data.user.userRole) >= 0 ? "Admin" : "User"}}
              </div>
              <div v-else class="textInsideCard textAlignRight onRightSide">
                {{ data | invitaionText }}
                <span>- {{ data.expire_time * 1000 | filter_date_current }}</span>
              </div>
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
        </div>
      </div>
      <vb-no-record v-if="filterList.length==0" :text="'There is no Users'" :design="3" />
     




      
      
      <EditUserModal @update-list="afterAdding()" />
      <AddUserAndSubUserModal @user-added="afterAdding()" />
    </section>
  </div>
</template>

<script>
import EditUserModal from "../modals/users/EditUser.vue";
import AddUserAndSubUserModal from "../modals/users/AddUserAndSubUser.vue";
import { $fn, VOIP_API } from "@/utils";
import _ from 'lodash'
import moment from "moment";
export default {
  name: "Users",
  components: {
    EditUserModal,
    AddUserAndSubUserModal,
  },
  data() {
    return {
      filter: {
        search: '',
        list: {
          search: '',
          invitations: true,
          active_users: true,
          inactive_users: true,
        }
      },
      selected: {
        table: "", //user
      },
      response: {
        invitations: [],
      },
      api: {
        update_role: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
        invitations: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
        retry_invitation: {
          count: 0,
          send: [],
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
        user_detail: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
        users: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
        delete_user: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
      },
    };
  },
  inject:['isEmpty', 'getProperty','getVoipUsers','appNotify'],
  computed: {
    filterList(){
      const search = this.filter.list.search.toLowerCase().trim();
      const filters = this.filter.list
      const invitations = this.response.invitations
      const users = Object.values(this.$store.state.common.voipusers).filter(item=>!!item.user)
      return [...users,...invitations].filter((val) =>
        `${val.email || ''} ${val.extn || ''} ${val.user ? `${val.user.display_name} ${val.user.email2 || val.user.email || val.user.ac_user}` : ''}`
        .toLowerCase()
        .trim()
        .includes(search) && (val.active_status == '0' && filters.inactive_users || val.active_status == '1' && filters.active_users || !val.active_status && filters.invitations)
      )
    },
    activeUsers(){
      const users = Object.values(this.$store.state.common.voipusers).filter(item=>!!item.user);
      const filter_users = users.filter((val) => val.active_status=='1');
      return filter_users
    },
    inActiveUsers(){
      const users = Object.values(this.$store.state.common.voipusers).filter(item=>!!item.user);
      const filter_users = users.filter((val) => val.active_status=='0');
      return filter_users
    },
    searchedUsers() {
      const search = this.filter.search.toLowerCase().trim();
      const users = this.activeUsers;
      const filter_users = users.filter((val) => `${val.extn} ${val.user.display_name} ${val.user.email2 || val.user.email || val.user.ac_user}`.toLowerCase().trim().includes(search) && val.active_status=='1');
      return filter_users
    },
    searchedInactiveUsers() {
      const search = this.filter.search.toLowerCase().trim();
      const users = this.inActiveUsers;
      const filter_users = users.filter((val) => `${val.extn}_${val.user.ac_user}_${val.user.display_name}_${val.user.email}_${val.user.email2}`.toLowerCase().trim().includes(search) && val.active_status=='0');
      return filter_users
    },
    searchedInvitation() {
      const search = this.filter.search.toLowerCase().trim();
      const invitations = this.response.invitations;
      const filter_invitations = invitations.filter((val) => `${val.email}`.toLowerCase().trim().includes(search));
      return filter_invitations
    },
    options() {
      return [
        {
          value: "user",
          text: "Active Users",
        },
        {
          value: "inactive",
          text: "Inactive Users",
        },
        {
          value: "invited",
          text: "Invited Users",
        },
      ];
    },
  },
  watch: {
    searchUsersWithFirstLastUserNameEmail: {
      immediate: true,
      handler(new_value) {
        this.pagination_data = new_value;
      },
    },
  },
  filters: {
    invitaionText(data){
      // console.log('run',data.email,moment(data.expire_time).calendar().localeCompare())
      // const current_date = moment();
      // const message_date = moment(data.expire_time);
      // const duration = moment.duration({ from: message_date, to: current_date })
      // console.log(duration)
      if(moment(data.expire_time * 1000).calendar().localeCompare()==-1) return 'Expired'
      if(data?.status=='failed' && data?.invitation_status=='expired') return 'Expired'  
      else return data.status
    },
    
    firstCharacter(value) {
      if (!value) return "";
      return value.charAt(0);
    },
    userRole: function (value) {
      if (!value) return "";
      if (value === "3") return "Admin";
      return "User";
    },
  },
  methods: {
    deleteUser(user) {
      let vm = this;
      if(vm.api.delete_user.send) return;
      vm.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to delete "${user.user.display_name}" user.`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback({confirm}) {
          if (confirm) {
            vm.api.delete_user.send=true
            VOIP_API.endpoints.users.delete({
              id: user.voipaccount,
              uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
              accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
            }).then(()=>{
              setTimeout(()=>{
                vm.getVoipUsers()
              },15 * 1000)
              vm.appNotify({
                message: 'Successfully Deleted',
                type: 'success',
              })
            }).catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(()=>{
              vm.api.delete_user.send=false
            })
          }
        },
      });
    },
    fetchUsers() {
      let vm = this
      if(vm.api.users.send) return;
      vm.api.users.send=true
      VOIP_API.endpoints.users.list().then(({ data: { data: voipusers } })=>{
        if(_.isEmpty(voipusers)) throw 'Users is Empty'
        return $fn.manipulateVoipUsers(voipusers,vm.$store.state.common.voipusers)
      }).then((voipusers)=>{
        return _.keyBy(voipusers,'voipaccount')
      }).then((voipusers)=>{
        vm.$store.commit("setVoipUsers", voipusers);
      }).catch((ex)=>{
        if(ex.own_message){
          vm.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      }).finally(()=>{
        vm.api.users.send=false
      })
    },
    afterAdding() {
      let vm = this;
      setTimeout(() => {
        vm.getVoipUsers();
      }, 5 * 1000);
    },
    userDetail(account){
      let vm = this
      vm.$modal.show("EditUserModal", { accountcode: account })
      // if(vm.api.user_detail.send) return;
      // vm.api.user_detail.send=true
      // VOIP_API.endpoints.users.detail(account).then(({ data: detail })=>{
      //   vm.$modal.show("EditUserModal", { user: detail });
      // }).catch((ex)=>{
      //   vm.appNotify({
      //     message: ex.own_message,
      //     type: 'error',
      //   })
      // }).finally(()=>{
      //   vm.api.user_detail.send=false
      // })
    },
    updateUserRole(account){
      let vm = this
      if(vm.api.update_role.send) return;
      vm.api.update_role.send=true
      VOIP_API.endpoints.users.updateuserrole(account).then(({ data: { data: voipusers } })=>{
        if(_.isEmpty(voipusers)) throw 'Users is Empty'
        return $fn.manipulateVoipUsers(voipusers,vm.$store.state.common.voipusers)
      }).then((voipusers)=>{
        return _.keyBy(voipusers,'voipaccount')
      }).then((voipusers)=>{
        vm.$store.commit("setVoipUsers", voipusers);
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.update_role.send=false
      })
    },
    fetchInvitations(){
      let vm = this
      if(vm.api.invitations.send) return;
      vm.api.invitations.send=true
      VOIP_API.endpoints.user.userinvitationslist({
        uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
      }).then(({ data, })=>{
        vm.response.invitations = data?.data ?? [];
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.invitations.send=false
      })
    },
    retryInvitation(invitation_id){
      let vm = this
      if(vm.api.retry_invitation.send.indexOf(invitation_id)>-1) return;
      vm.api.retry_invitation.send.push(invitation_id)
      VOIP_API.endpoints.user.retryuserinvitation({
        id: invitation_id,
        accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
      }).then(()=>{
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        if(vm.api.retry_invitation.send.indexOf(invitation_id)>-1){
          vm.api.retry_invitation.send.splice(vm.api.retry_invitation.send.indexOf(invitation_id),1)
        }
      })
    },
    isReInvite(data){ return (data?.status!='failed' && moment(data.expire_time * 1000).calendar().localeCompare()==-1) || data?.invitation_status=='expired' },
  },
  activated(){
    this.fetchInvitations()
    this.getVoipUsers()
  },
};
</script>