var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-musicOnHold-settings"
  }, [_c('div', {
    staticClass: "dialer-flex pt-3"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width-button"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Updates")])], 1)] : _c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("VoIP Business updates")])], 2), _vm._m(0), _c('div', {
    staticClass: "latestGreyBox-heading-main-18px text-left"
  }, [_vm._v("Current VoIP Business version is " + _vm._s(_vm.electron.app_version) + " " + _vm._s(_vm.electron.name))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('b-alert', {
    staticClass: "my-3",
    attrs: {
      "show": !!_vm.electron.update.error && _vm.electron.update.state == 6,
      "dismissible": "",
      "variant": "danger",
      "fade": ""
    },
    on: {
      "dismissed": function ($event) {
        _vm.$store.state.electron.update.error = '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.electron.update.error) + " ")]), _c('b-alert', {
    staticClass: "my-3",
    attrs: {
      "show": _vm.electron.update.state == 3,
      "dismissible": "",
      "variant": "success",
      "fade": ""
    },
    on: {
      "dismissed": function ($event) {
        _vm.$store.state.electron.update.state = 0;
      }
    }
  }, [_vm._v(" You are Already on upto date ")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm.electron.update.state == 5 && _vm.electron.app_version != _vm.latestReleaseVersion ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Updates downloaded")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText mb-20px"
  }, [_vm._v("The latest update for VoIP business is being downloaded")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText-14px"
  }, [_vm._v(" VoIP business version " + _vm._s(_vm._f("get_property")(_vm.electron, 'update.version_available')) + " is available ")])]), _c('button', {
    staticClass: "newButton mr-0",
    on: {
      "click": function ($event) {
        return _vm.restartForUpdate();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-configure-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart d-flex align-items-center justify-content-center position-relative"
  }, [_vm._v(" Update now ")])], 1)]) : _vm._e(), _vm.electron.update.state == 4 && _vm.electron.app_version != _vm.latestReleaseVersion ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Updates downloading")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" The latest updates for VoIP business are downloading " + _vm._s(_vm._f("get_property")(_vm.electron, 'update.version_available')) + " ")])]), _c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v(" " + _vm._s(_vm._f("filterDownloadPercent")(_vm._f("get_property")(_vm.electron, 'update.downloading.percentage'))) + " ")])]) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(1), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "checked": _vm.electron.update.auto,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.toggleAutoUpdate();
      }
    }
  })], 1), _vm.electron.update.state != 4 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(2), _c('button', {
    staticClass: "newButton mr-0 mwd-200px",
    attrs: {
      "disabled": _vm.electron.update.state == 1
    },
    on: {
      "click": function ($event) {
        return _vm.checkForUpdate();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-update-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart d-flex align-items-center justify-content-center position-relative"
  }, [_vm.electron.update.state == 1 ? _c('vb-spinner') : [_c('span', [_vm._v("Check for update")])]], 2)], 1)]) : _vm._e(), _vm.response.allowed_versions.stable ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v(_vm._s(_vm.response.allowed_versions.stable.version))]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(_vm._s(_vm.electron.app_version == _vm.latestReleaseVersion ? 'You are already up to date' : 'There is an update available.'))])])]) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_vm._m(3), _c('button', {
    staticClass: "newButton mr-0",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('ChangeLogsTableModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-show-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _vm._m(4)], 1)])])], 1)]), _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-900 ChangeLogsTableModal",
    attrs: {
      "name": "ChangeLogsTableModal",
      "width": "50%",
      "height": "auto"
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header flex-column pb-1 align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Changelogs")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('ChangeLogsTableModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('p', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" View the latest updates and stay informed about new features, bug fixes, and performance enhancements. ")])]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filterReleases),
      "loading": _vm.api.allowed_versions.send,
      "listLength": _vm.filterReleases.total
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.filterReleases, function (release) {
          return _c('tr', {
            key: release.version,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(release.version))])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('span', [_vm._v(_vm._s(release.desc))])])]);
        });
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "100%"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "100%"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("desc")])])])])], 2)], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "dialer-settings-intro with-button newerTextVersion"
  }, [_c('span', [_vm._v("Here you can check for updates, check what version you are at and control whether you want to receive auto updates or not.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Auto update")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Want to receive auto updates, enable the button to receive auto updates.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Check for update")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Click on a button to check if there is a update available.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Changelogs")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("View the latest updatess, features, and fixes in our app.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('span', {
    staticClass: "newButton-textPart d-flex align-items-center justify-content-center position-relative"
  }, [_c('span', [_vm._v("View")])]);

}]

export { render, staticRenderFns }