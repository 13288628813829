<template>
  <div style="width: 30%; background-color: white; border-radius: 20px; padding: 35px;">
    <vue-tree-list 
      @click="!$event.isLeaf ? $emit('select-group-id',$event.id) : ''"
      @change-name="''"
      @delete-node="''"
      @add-node="''"
      :model="groupTree"
      default-tree-node-name="new node"
      default-leaf-node-name="new leaf"
      :default-expanded="false"
    >
      <template #leafNameDisplay="slotProps">
        <span class="ml-1">{{ slotProps | get_property('model.name') }}</span>
      </template>
      <template #addTreeNodeIcon="slotProps">
        <b-icon v-if="slotProps" icon="folder-plus" />
      </template>
      <template #addLeafNodeIcon="slotProps">
        <b-icon v-if="slotProps" icon="file-earmark-plus-fill" />
      </template>
      <template #editNodeIcon="slotProps">
        <b-icon v-if="slotProps" icon="pencil-fill" />
      </template>
      <template #delNodeIcon="slotProps">
        <b-icon v-if="slotProps" icon="trash-fill" />
      </template>
      <template #leafNodeIcon="slotProps">
        <b-icon v-if="slotProps" icon="file-earmark" />
      </template>
      <template #treeNodeIcon="slotProps">
        <b-icon v-if="slotProps" icon="folder-fill" />
      </template>
    </vue-tree-list>
  </div>
</template>

<script>
import { VueTreeList, Tree } from 'vue-tree-list'
function manupulateGroups(array, parentId = null) {
  let response = [];
  for (let data of array) {
    let new_data = { 
      id: data?.id, 
      pid: parentId,
      name: '',
      isLeaf: false,
      dragDisabled: true,
      addTreeNodeDisabled: true,
      addLeafNodeDisabled: true,
      editNodeDisabled: true,
      delNodeDisabled: true,
    }
    if(data?.resource_name){
      new_data.name = data.resource_name
      new_data.isLeaf = true
    } else {
      new_data.name = data.resource_type
      new_data.isLeaf = false
      new_data.children = manupulateGroups([...data.children ?? [],...data.resources ?? []], data.id);
    }
    response.push(new_data);
  }
  return response;
}
export default {
  name: 'PamarlyFolderList',
  components: {
    VueTreeList,
  },
  props: {
    groups: {
      type: Array,
      default: ()=>[]
    },
  },
  computed: {
    groupTree(){
      return new Tree(manupulateGroups(this.groups))
    },
  },
}
</script>

<style>

</style>