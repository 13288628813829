<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-600"
      width="800px"
      height="auto"
      :scrollable="true"
      name="UserCallRecordingModal"
      @before-open="beforeOpen"
    >
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header">
          <div>
            <h2 class="dialer-edit-title">Select users to record</h2>
          </div>
          <div class="dialer-edit-actions">
            <a
              class="dialer-button dialer-button-delete"
              @click="$modal.hide('UserCallRecordingModal')"
              >Cancel</a
            >
            <button
              class="newDoneButton"
              @click="$modal.hide('UserCallRecordingModal')"
            >
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="md-mar-vertical">
          <div class="dialer-team-name mb-4">
            <div class="dialer-tab-content">
              <ul class="list-unstyled dialer-asssignee-list" v-if="show">
                <li
                  class="dialer-assignee"
                  v-for="(user, key) in userList"
                  :key="key"
                >
                  <div class="dialer-assignee-name">
                    <div class="dialer-initials colour-0">
                      {{ user.user | firstCharacter }}
                    </div>
                    <span>{{ user.user }}</span>
                    <span class="text-white small bg-primary px-2">{{
                      user.extn
                    }}</span>
                  </div>

                  <label>Off</label>
                  <toggle-button
                    :value="getSlideValue(user.off)"
                    @change="updateSetting(user.account, 'off')"
                    :labels="{ checked: 'On', unchecked: 'off' }"
                  />

                  <label>Auto Record</label>
                  <toggle-button
                    :value="getSlideValue(user.auto)"
                    @change="updateSetting(user.account, 'auto')"
                    :labels="{ checked: 'On', unchecked: 'off' }"
                  />

                  <label>On demand Record</label>
                  <toggle-button
                    :value="getSlideValue(user.ondemand)"
                    @change="updateSetting(user.account, 'ondemand')"
                    :labels="{ checked: 'On', unchecked: 'off' }"
                  />

                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
// import UsersService from "@/services/UsersService";
import { VOIP_API } from '../../utils';
export default {
  name: "UserCallRecordingModal",

  data: function () {
    return {
      abc: "auto",
      service: null,
      msg: {},
      userList: {},
      submitted: false,
      selectedTeams: [],
      show: false,
      obj: {
        auto: "0",
        ondemand: "0",
        account: null,
      },
    };
  },
  validations: {
    menu: {
      name: { required },
    },
  },
  filters: {
    firstCharacter: function (value) {
      if (!value) return "";
      return value.charAt(0);
    },
  },
  methods: {
    async updateSetting(account, value) {

      this.obj.ondemand = "0";
      this.obj.auto = "0";
      this.obj.account = account;
      if (value === "auto") {
        this.obj.auto = "1";
        this.obj.ondemand = "0";
      } else if (value === "ondemand") {
        this.obj.auto = "0";
        this.obj.ondemand = "1";
      }
        this.show = false;
      await VOIP_API.endpoints.users.updateusercallrecordingsetting(this.obj).then(({ data: res }) => {
        this.show = true;
        this.userList = {};
        this.userList = res;
      });
      // await this.service
      //   .call("updateusercallrecordingsetting", this.obj)
      //   .then((res) => {
      //       this.show = true;
      //       this.userList = {};
      //       this.userList = res;
      //   });
    },
    getSlideValue(value) {

      if (value === "1" || value === 1) {
        return true;
      } else {
        return false;
      }
    },
    beforeOpen() {
      // this.service = this.$serviceFactory(new UsersService(), this);
      this.load();
      this.show = true;
    },
    async load() {
      await VOIP_API.endpoints.users.usercallrecordingsetting().then(({ data: res }) => {
        this.userList = res;
      });
      // await this.service.call("usercallrecordingsetting").then((res) => {
      //   this.userList = res;
      // });
    },
    updateRecording(/* account */) {
      // console.log(account);
    },
    validationError(/* message */) {
      // console.log(message);
    },
  },
};
</script>
