var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('audio', {
    attrs: {
      "id": "chat-notification",
      "src": require('@/assets/sounds/notification.mp3')
    }
  }), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "send-message-ringtone",
      "src": _vm._f("get_property")(_vm.media_devices_setting.selectedSendmessageRingtone, 'file')
    }
  }), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "recieve-message-ringtone",
      "src": _vm._f("get_property")(_vm.media_devices_setting.selectedRecievemessageRingtone, 'file')
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }