export default {
  state: {
    active: {},
    outgoing: {},
    incoming: {},
    restricted: {},
    joined: {},
    activeVideoCall: {},
    outgoingVideoCalls: {},
    incomingVideoCalls: {},
    restrictedPersons: {},
    isVideoPopupOpen: false,
    // last_active_call: '',
    active_incoming_call: '',
    videoCalls: [],
    is_call_process: false,
    is_video_popup_open: false,
    calls: {},
    active_call: '',
    active_group_calls: {},
    incoming_calls: {},
    outgoing_calls: {},
    restricted_calls: {},
    phone: {},
  },
  getters: {
    isOnDemandCallRecording(state,getters,rootState){
      const setting = rootState.common.call_recording_setting
      return /*setting?.allow_recording=='1' && */setting?.ondemand=='1'
    },
    activeCall(state,getters){ return getters.sipsessions.find(item=>item.uuid==state.active_call) || state.videoCalls.find(item=>item.room==state.active_call) },
    activeIncomingCall(state,getters){ return getters.sipIncomingCalls.find(item=>item.uuid==state.active_incoming_call) || getters.jitsiIncomingCalls.find(item=>item.room==state.active_incoming_call) },
    activeSession(state,getters){ return getters.activeCall || getters.activeIncomingCall },
    sipsessions(state,getters,rootState) { return rootState.sip.phone?.sessions ?? [] },
    sipIncomingCalls(state,getters){ return getters.sipsessions.filter(session=>session.callState === 'pending' && session.direction != 'out') },
    sipOutgoingCalls(state,getters){ return getters.sipsessions.filter(session=>session.callState === 'pending' && session.direction == 'out') },
    sipRunningCalls(state,getters){ return getters.sipsessions.filter(session=>session.callState !== 'pending') },
    jitsiIncomingCalls(state){return Object.values(state?.incoming_calls ?? {})},
    jitsiOutgoingCalls(state){return Object.values(state?.outgoing_calls ?? {})},
    jitsiRunningCalls(state){return state?.videoCalls ?? []},
    allIncomingCalls(state,getters){ return [...getters.sipIncomingCalls,...getters.jitsiIncomingCalls] },
    allOutgoingCalls(state,getters){ return [...getters.sipOutgoingCalls,...getters.jitsiOutgoingCalls] },
    allRunningCalls(state,getters){ return [...getters.sipRunningCalls,...getters.jitsiRunningCalls] },
    numbersOfCalls(state,getters){ return getters.sipsessions.length + getters.jitsiRunningCalls.length + getters.jitsiIncomingCalls.length },
    isActiveSession(state,getters){ return !!getters.activeSession },
    isCallBar(state,getters){ return getters.allOutgoingCalls.length > 0 || getters.allRunningCalls.length > 0 },
    isInCall(state,getters){return !getters.isActiveCallAreSingleCall && (getters.allOutgoingCalls.length!=0 || getters.allRunningCalls.length!=0)},
    anyCall(state,getters){return !getters.isActiveCallAreSingleCall && (getters.allIncomingCalls.length!=0 || getters.isInCall)},
    isTestCall(state,getters){return getters?.activeCall?.extra_data?.call_type=='test'},
    isListenCall(state,getters){return getters?.activeCall?.extra_data?.call_type=='listen'},
    isWhisperCall(state,getters){return getters?.activeCall?.extra_data?.call_type=='whisper'},
    isBargeCall(state,getters){return getters?.activeCall?.extra_data?.call_type=='barge'},
    isActiveCallAreSingleCall(state,getters){return getters.isTestCall || getters.isListenCall || getters.isWhisperCall || getters.isBargeCall},
    isValidNumber(state,getters,rootState){return /([0-9,*,#,+]\d)/.test(rootState.sip.number)},
    isActiveSessionIncoming(state,getters){
      const active_session_id = getters.activeSession?.room || getters.activeSession?.uuid
      return !!getters.allIncomingCalls.find(i=>active_session_id==(i.uuid || i.room))
    },
    isSwitchAbleState(state,getters){
      if(getters.activeSession){
        if(getters.activeSession.is_sip){
          if(getters.activeSession.direction == 'out'){
            return getters.activeSession.switchable
          } else {
            return true
          }
        } else {
          return true
        }
      } else {
        return true
      }
    },
  },
  actions: {
    playCall({ state, getters, commit },data){
      // console.log('data',data)
      const is_sip = !!data.is_sip
      const id = is_sip ? data.uuid : data.room
      if(!getters.isSwitchAbleState) return;
      if(state.active_call==id) return;
      commit('setActiveCall',data.uuid || data.room)
      // if(data.uuid){
      //   data.hold()
      //   this.$store.state.sip.phone.markSessionActive(data.uuid)
      //   this.$store.state.calls.active_call=data.uuid
      // } else {
      //   data.isPlay = true;
      //   data.isShown = true;
      //   this.$store.state.calls.active_call=data.room
      // } 
    },
  },
  mutations: {
    setActiveCall(state,value){
      state.active_call=value
    },
  },
};