<template>
  <div class="content-area d-flex h-100 dialer_main_body">
    <div class="dialer_main_body_inner w-100">
      <div v-if="!selected.tab" class="dialer_inner_left_side d-r-768px-none">
        <div class="d-flex mainSettingsHeadingContainer">
          <div class="first-section mb-16px" >
            <div class="topMainHeading">My Settings</div>
          </div>
          <b-icon icon="x" class="cursor_pointer XiconForClosingSettingsInMobile" font-scale="2" />
        </div>
        <div class="dialer_inner_left_side_inner allow-scroll mobileSettingsPanel">
          <ul class="list-unstyled basicWhiteIOScard">
            <li v-for="tab in tabs.user" :key="tab.component" @click="selected.tab = tab.component" class="dialer_tab_btn basicWhiteIOScard-item" :class="{'active': selected.tab === tab.component}">
              <a class="text-capitalize">
                <span class="dialer-settingPanel-icon basicIOSIcon">
                  <vb-icon :icon="tab.icon" />
                </span>
                <span class="dialer-settingPanel-text headingInsideTable">{{ tab.label }}</span>
                <span>
                  
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </span>
              </a>
            </li>
          </ul>
          <template v-if="getCurrentUser.administrator_account">
            
            <div class="d-flex mainSettingsHeadingContainer">
              <div class="first-section mb-16px">
                <div class="topMainHeading">Administrator</div>
              </div>
            </div>
            <ul class="list-unstyled basicWhiteIOScard">
              <li v-for="tab in tabs.admin" :key="tab.component" @click="selected.tab = tab.component" class="dialer_tab_btn basicWhiteIOScard-item" :class="{'active' : selected.tab === tab.component}">
                <a class="text-capitalize">
                  <span class="dialer-settingPanel-icon basicIOSIcon">
                    <vb-icon :icon="tab.icon" />
                  </span>
                  <span class="dialer-settingPanel-text headingInsideTable">{{ tab.label }}</span>
                  <span>
                    
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </span>
                </a>
              </li>
            </ul>
          </template>
          <template v-if="getCurrentUser.administrator_account">
            
            <div class="d-flex mainSettingsHeadingContainer">
              <div class="first-section mb-16px">
                <div class="topMainHeading">Analytics</div>
              </div>
            </div>
            <ul class="list-unstyled basicWhiteIOScard">
              <li v-for="tab in tabs.analytics" :key="tab.component" @click="selected.tab = tab.component" class="dialer_tab_btn basicWhiteIOScard-item" :class="{'active': selected.tab === tab.component}">
                <a class="text-capitalize">
                  <span class="dialer-settingPanel-icon basicIOSIcon">
                    <vb-icon :icon="tab.icon" />
                  </span>
                  <span class="dialer-settingPanel-text headingInsideTable">{{ tab.label }}</span>
                  <span>
                    
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </span>
                </a>
              </li>
            </ul>
          </template>
        </div>
      </div>
      <div v-show="selected.tab" :class="`dialer_inner_right_side v2`">
        <keep-alive :include="keepAliveTabs">
          <component :is="selected.tab" @back="selected.tab=''" />
        </keep-alive>
        <CheckSlotModal />
      </div>
    </div>
  </div>
</template>

<script>
// components
// users--------
import MyNumbers from "../../components/mobile/settings/Numbers.vue";
import Calls from "../../components/mobile/settings/Calls.vue";
import Phones from "../../components/mobile/settings/Phones.vue";
import SoundLibrary from "../../components/mobile/settings/SoundLibrary.vue";
import Voicemail from "../../components/mobile/settings/Voicemail.vue";
import Profile from "../../components/mobile/settings/Profile.vue";
// admin--------
import Users from "../../components/mobile/admin/Users.vue";
import Teams from "../../components/mobile/admin/Teams.vue";
import Numbers from "../../components/mobile/admin/Numbers.vue";
import IVR from "../../components/mobile/admin/IVR.vue";
import MusicOnHold from "../../components/mobile/admin/MusicOnHold.vue";
import BlockAndPrivacy from "../../components/mobile/admin/BlockAndPrivacy.vue";
import CallRecording from "../../components/mobile/admin/CallRecording.vue";
import Global from "../../components/mobile/admin/Global.vue";
import Locations from "../../components/mobile/admin/Locations.vue";
import CallQueue from "../../components/mobile/admin/CallQueue.vue";
import Billing from "../../components/mobile/admin/Billing.vue";
// analytics----
import UsersAnalytics from "../../components/mobile/analytics/Users.vue";
import TeamsAnalytics from "../../components/mobile/analytics/Teams.vue";
import NumbersAnalytics from "../../components/mobile/analytics/Numbers.vue";
import SummaryAnalytics from "../../components/mobile/analytics/Summary.vue";
import ReportsAnalytics from "../../components/mobile/analytics/Reports.vue";
// main modals--
import CheckSlotModal from '../../components/mobile/modals/CheckSlot.vue'

import { mapGetters } from 'vuex';


const settings = [
  {
    icon: 'MyNumbers-IOSIcon',
    label: 'My Number',
    header: 'My Number',
    component: 'MyNumbers',
    keep_alive: true,
  },
  {
    icon: 'Calls-IOSIcon',
    label: 'Calls',
    header: 'Calls',
    component: 'Calls',
    keep_alive: true,
  },
  {
    icon: 'Phones-IOSIcon',
    label: 'Phones',
    header: 'Phones',
    component: 'Phones',
    keep_alive: true,
  },
  {
    icon: 'SoundLibrary-IOSIcon',
    label: 'Sound Library',
    header: 'Sound Library',
    component: 'SoundLibrary',
    keep_alive: true,
  },
  {
    icon: 'Voicemail-IOSIcon',
    label: 'Voicemail',
    header: 'Voicemail',
    component: 'Voicemail',
    keep_alive: true,
  },
  {
    icon: 'Profile-IOSIcon',
    label: 'Profile',
    header: 'Profile',
    component: 'Profile',
    keep_alive: true,
  },
  // {
  //   icon: 'Integrations-IOSIcon',
  //   label: 'Integrations',
  //   header: 'Integrations',
  //   component: 'Numbers',
  //   keep_alive: true,
  // },
] 
const administration = [
  {
    icon: 'Profile-IOSIcon',
    label: 'Users',
    header: 'Users',
    component: 'Users',
    keep_alive: true,
  },
  {
    icon: 'Teams-IOSIcon',
    label: 'Teams',
    header: 'Teams',
    component: 'Teams',
    keep_alive: true,
  },
  {
    icon: 'MyNumbers-IOSIcon',
    label: 'Numbers',
    header: 'Numbers',
    component: 'Numbers',
    keep_alive: true,
  },
  {
    icon: 'IVR-IOSIcon',
    label: 'IVR',
    header: 'IVR',
    component: 'IVR',
    keep_alive: true,
  },
  {
    icon: 'CallQueue-IOSIcon',
    label: 'Call Queue',
    header: 'Call Queue',
    component: 'CallQueue',
    keep_alive: true,
  },
  {
    icon: 'MusicOnHold-IOSIcon',
    label: 'Music On Hold',
    header: 'Music On Hold',
    component: 'MusicOnHold',
    keep_alive: true,
  },
  {
    icon: 'BlockingAndPrivacy-IOSIcon',
    label: 'Block And Privacy',
    header: 'Block And Privacy',
    component: 'BlockAndPrivacy',
    keep_alive: true,
  },
  {
    icon: 'CallRecording-IOSIcon',
    label: 'Call Recording',
    header: 'Call Recording',
    component: 'CallRecording',
    keep_alive: true,
  },
  {
    icon: 'Billing-IOSIcon',
    label: 'Billing',
    header: 'Billing',
    component: 'Billing',
    keep_alive: true,
  },
  {
    icon: 'GlobalSetting-IOSIcon',
    label: 'Global Settings',
    header: 'Global Settings',
    component: 'Global',
    keep_alive: true,
  },
  {
    icon: 'Locations-IOSIcon',
    label: 'Locations',
    header: 'Locations',
    component: 'Locations',
    keep_alive: true,
  },
]
const analytic = [
  {
    icon: 'Summary-analytics-IOSIcon',
    label: 'Summary',
    header: 'Summary',
    component: 'SummaryAnalytics',
    keep_alive: false,
  },
  {
    icon: 'Users-analytics-IOSIcon',
    label: 'Users',
    header: 'Users',
    component: 'UsersAnalytics',
    keep_alive: false,
  },
  {
    icon: 'MyNumbers-IOSIcon',
    label: 'Numbers',
    header: 'Numbers',
    component: 'NumbersAnalytics',
    keep_alive: false,
  },
  {
    icon: 'Teams-analytics-IOSIcon',
    label: 'Teams',
    header: 'Teams',
    component: 'TeamsAnalytics',
    keep_alive: false,
  },
  {
    icon: 'Voicemail-analytics-IOSIcon',
    label: 'Call Analytics',
    header: 'Call Analytics',
    component: 'ReportsAnalytics',
    keep_alive: false,
  }
]
export default {
  name: 'MobileSettings',
  components: {
    MyNumbers,
    Calls,
    Phones,
    SoundLibrary,
    Voicemail,
    Profile,
    Users,
    Teams,
    Numbers,
    IVR,
    MusicOnHold,
    BlockAndPrivacy,
    CallRecording,
    Global,
    Locations,
    CallQueue,
    Billing,
    UsersAnalytics,
    TeamsAnalytics,
    NumbersAnalytics,
    SummaryAnalytics,
    ReportsAnalytics,
    CheckSlotModal,
  },
  data() {
    return {
      selected: {
        tab: "Numbers",
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    tabs(){
      const user = settings
      const admin = administration
      const analytics = analytic
      return {
        user: user,
        admin: admin,
        analytics: analytics,
      }
    },
    keepAliveTabs(){
      const user = this.tabs.user
      const admin = this.tabs.admin
      const analytics = this.tabs.analytics
      const keep_alive_user_tabs = user.filter(item=>item.keep_alive)
      const keep_alive_admin_tabs = admin.filter(item=>item.keep_alive)
      const keep_alive_analytics_tabs = analytics.filter(item=>item.keep_alive)
      return [...keep_alive_user_tabs,...keep_alive_admin_tabs,...keep_alive_analytics_tabs].map(item=>item.component)
    },
  },
  activated(){
    if(this.$store.state.global_conditions.is_mobile_setting){
      this.selected.tab = ''
    }
  },
}
</script>

<style>

</style>
