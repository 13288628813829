var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.add ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add DTMF")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.avaliableOptions, function (option, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          return _vm.$modal.show('SelectExtension', {
            type: 'add',
            extra_data: {
              dtmf: option
            }
          });
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v(_vm._s(`Press ${option}`))]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1);
  }), _vm.avaliableOptions.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Option',
      "design": 3
    }
  }) : _vm._e()], 2)])])] : _vm._e(), _vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.remove.send ? '' : _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Option")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.api.remove.send ? '' : _vm.$modal.show('SelectExtension', {
          type: 'edit',
          account: _vm.selectedOption.ivr_cmd_args,
          extra_data: {
            dtmf: _vm.selectedOption.user_dtmf
          }
        });
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Label")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(" Press " + _vm._s(_vm.selectedOption.user_dtmf) + " ")])])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("For")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(" " + _vm._s(_vm.selectedOption.accountname) + " ")])])])]), _c('button', {
    staticClass: "IosFullWidthButton forRemove mb-16px",
    attrs: {
      "disabled": _vm.api.remove.send
    },
    on: {
      "click": function ($event) {
        return _vm.remove();
      }
    }
  }, [_vm.api.remove.send ? _c('vb-spinner') : _vm._e(), [_vm._v("Delete")]], 2)])])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Menu Options")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.add;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    }
  })], 1)]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.options, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.conditions.screen = _vm.screens.edit;
          _vm.selected.option_id = option.id;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v("Press " + _vm._s(option.user_dtmf))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight mr-16px"
    }, [_vm._v(" " + _vm._s(option.accountname) + " ")]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)]);
  }), _vm.options.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Option',
      "design": 3
    }
  }) : _vm._e()], 2)])])], _c('SelectExtensionModal', {
    on: {
      "selected": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
        _vm.update($event);
      }
    }
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }