var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup AwaitApprovalModal HrmForModalScroll",
    attrs: {
      "name": "AwaitApprovalModal",
      "clickToClose": true,
      "scrollable": true
    }
  }, [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Time off request")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AwaitApprovalModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "grayCard borderRadius-12px flexWrap px-4"
  }, [_c('div', {
    staticClass: "nameWithImage w-100"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/credit-card/1.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text heavy"
  }, [_vm._v("Jackson Will")])]), _c('div', {
    staticClass: "smallText pl-61px w-100"
  }, [_vm._v("Sickday on 02/09/2022 - 03/09/2022, 1 day")]), _c('div', {
    staticClass: "smallText pl-61px w-100"
  }, [_vm._v("Awaiting approval")]), _c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center mt-32px"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('button', {
    staticClass: "OutlineButton green withIcon mr-16px"
  }, [_c('b-icon', {
    attrs: {
      "icon": "check",
      "scale": "1.5",
      "font-scale": "1.2"
    }
  }), _vm._v(" Approve ")], 1), _c('button', {
    staticClass: "OutlineButton red withIcon mr-16px"
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "scale": "1.5",
      "font-scale": "1.2"
    }
  }), _vm._v(" Decline ")], 1)]), _c('vb-icon', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "icon": "pencil-hrmIcon",
      "height": "20px",
      "width": "20px"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('EditTimeOffRequestModal');
      }
    }
  })], 1)]), _c('div', {
    staticClass: "collapseAble-conatiner mt-32px"
  }, [_c('div', {
    class: `collapseAble-head ${_vm.showCollapsableItems == 'first' ? 'opened' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showCollapsableItems = 'first';
      }
    }
  }, [_c('div', {
    staticClass: "collapseAble-headText"
  }, [_vm._v("Time off balance for Sickday")]), _c('b-icon', {
    staticClass: "downIcon",
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.3"
    }
  })], 1), _c('div', {
    class: `collapseAble-body ${_vm.showCollapsableItems == 'first' ? 'opened' : ''}`
  }, [_c('div', {
    staticClass: "smallText w-100"
  }, [_vm._v("0 days used")])])]), _c('div', {
    staticClass: "collapseAble-conatiner mt-32px"
  }, [_c('div', {
    class: `collapseAble-head ${_vm.showCollapsableItems == 'second' ? 'opened' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showCollapsableItems = 'second';
      }
    }
  }, [_c('div', {
    staticClass: "collapseAble-headText"
  }, [_vm._v("Also away from Employees without team")]), _c('b-icon', {
    staticClass: "downIcon",
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.3"
    }
  })], 1), _c('div', {
    class: `collapseAble-body ${_vm.showCollapsableItems == 'second' ? 'opened' : ''}`
  }, [_c('div', {
    staticClass: "smallText w-100"
  }, [_vm._v("No one else from Employees without team has time off on these dates")])])]), _c('div', {
    staticClass: "collapseAble-conatiner mt-32px"
  }, [_c('div', {
    class: `collapseAble-head ${_vm.showCollapsableItems == 'third' ? 'opened' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showCollapsableItems = 'third';
      }
    }
  }, [_c('div', {
    staticClass: "collapseAble-headText"
  }, [_vm._v("Additional details")]), _c('b-icon', {
    staticClass: "downIcon",
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.3"
    }
  })], 1), _c('div', {
    class: `collapseAble-body ${_vm.showCollapsableItems == 'third' ? 'opened' : ''}`
  }, [_c('div', {
    staticClass: "smallText w-100"
  }, [_vm._v("details")])])]), _c('div', {
    staticClass: "collapseAble-conatiner mt-32px"
  }, [_c('div', {
    class: `collapseAble-head ${_vm.showCollapsableItems == 'fourth' ? 'opened' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showCollapsableItems = 'fourth';
      }
    }
  }, [_c('div', {
    staticClass: "collapseAble-headText"
  }, [_vm._v("Activity")]), _c('b-icon', {
    staticClass: "downIcon",
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.3"
    }
  })], 1), _c('div', {
    class: `collapseAble-body ${_vm.showCollapsableItems == 'fourth' ? 'opened' : ''}`
  }, [_c('div', {
    staticClass: "nameWithImage w-100"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/credit-card/1.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text heavy"
  }, [_vm._v("Jackson Will")])]), _c('div', {
    staticClass: "smallText pl-61px w-100"
  }, [_vm._v("Request Created on 19/09/2022 15:45")]), _c('div', {
    staticClass: "innerShadowInput-container whiteBG mt-16px"
  }, [_c('label', [_vm._v("Textarea")]), _c('textarea')]), _c('div', {
    staticClass: "smallText w-100"
  }, [_vm._v("Those with access to this time off request will receive an email with your comment")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Submit ")])])])]), _c('EditTimeOffRequestModal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }