<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.edit">

        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.update_extension.send?'':conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Extension</div>
          <div class="plus-IOSIcon-container"></div>
        </div>
        <div class="innerModalIos">

          <form class="dialer-form lg-mar-bottom" @submit.prevent="update()">
            <b-alert class="mt-32px" :show="!!api.update_extension.error_message" variant="danger">{{api.update_extension.error_message}}</b-alert>
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Extension</label>
                  <input placeholder="Enter Extension" class="w-100" type="text" :disabled="api.update_extension.send" v-model="forms.update_extension.extn" />
                  <p 
                    v-if="(forms.update_extension.submitted && $v.forms.update_extension.extn.$invalid) || api.update_extension.validation_errors.extn" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update_extension.extn.required">* Extension is required</span>
                    <span v-for="(em, i) in api.update_extension.validation_errors.extn" :key="i">* {{em}}</span>
                  </p>
                </div>
              </div>
            </div>
            <button class="IosFullWidthButton forDone mb-16px" :disabled="api.add_sound.send">
              <vb-spinner v-if="api.add_sound.send" />
              <template v-else>Submit</template>
            </button>
          </form>

        </div>

      </template>
      <template v-else>
        
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.update_extension.send?'':$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Extension</div>
          <div class="plus-IOSIcon-container">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">
          
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              Name
              {{ data.extension | extensionLabel }}
            </div>
            <div class="basicWhiteIOScard-item">
              Extension
              {{ data.extension.extn }}
            </div>
          </div>

        </div>

      </template>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils/index';
let screens = {
  view: 'view',
  edit: 'edit'
}
export default {
  name: "EditExtensionModal",
  props: {
    modalName: {
      type: String,
      default: 'EditExtension',
    },
  },
  data(){
    return {
      forms: {
        update_extension: this.$helperFunction.formInstance({
          data: {
            extn: '',
          }
        }),
      },
      api: {
        update_extension: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
      },
      data: {
        account: '',
        extnsion: {},
      },
      conditions: {
        screen: screens.view
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    screens(){ return screens },
  },
  validations: {
    forms: {
      update_extension: {
        extn: {
          required
        },
      },
    },
  },
  filters: {
    extensionLabel(value) { return value?.voipuser?.display_name ?? value?.label ?? '-' },
  },
  methods: {
    onBeforeOpen(event) {
      const extension = event.params?.data ?? {}
      this.forms.update_extension.extn = extension?.extn ?? '';
      this.data.account = extension.voipaccount ?? '';
    },
    onBeforeClose(){
      this.forms.update_extension.reset()
      this.api.update_extension.reset()
      this.data.extnsion={}
      this.data.account=''
      this.conditions.screen=screens.view
    },
    update(){
      let vm = this
      vm.forms.update_extension.submitted=true
      vm.$v.forms.update_extension.$touch();
      if (vm.$v.forms.update_extension.$invalid || vm.api.update_extension.send) return;
      vm.api.update_extension.send = true
      vm.api.update_extension.error_message=''
      vm.api.update_extension.validation_errors={}
      VOIP_API.endpoints.user.updateExtension({
        account: vm.data.account,
        extn: vm.getCurrentUser?.uid,
        extension: vm.forms.update_extension.extn,
      })
      .then(()=>{
        vm.$emit('interface')
        vm.appNotify({
          message: 'Successfully updated!',
          type: 'success',
        })
        vm.$modal.hide(vm.modalName)
      })
      .catch((ex)=>{
        vm.api.update_extension.error_message=ex.own_message
        vm.api.update_extension.validation_errors=ex.own_errors
      })
      .finally(()=>{
        vm.forms.update_extension.submitted = false
        vm.api.update_extension.send = false
      })
    },
  },
};
</script>
