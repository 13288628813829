<template>
  <div class="dialer-settings-main h-auto overflow-revert-imp">
    <section class="dialer-settings-section locationSettings p-0">
      <template v-if="$store.getters.getIsMobile">
        <div class="d-flex align-items-center mb-3 calc-title-width">
          <b-icon icon="chevron-left" font-scale="1.5" class="settings-back-icon cursor_pointer" style="" @click="$emit('back')" />
          <h2 class="dialer-settings-title w-100 mb-0 text-center">Locations</h2>
        </div>
        <div class="d-flex justify-content-end">
          <button class="dialer-button dialer-button-primary " @click="$modal.show('AddLocationModal')">
            <vb-svg name="dialer-largePlus-icon" width="15.996" height="15.996" viewBox="0 0 15.996 15.996" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span class="sm-mar-left">Add Location</span>
          </button>
        </div>
      </template>
      <div v-else class="dialer-flex">
        <h2 class="dialer-settings-title">
          Locations
          <b-icon id="ivr-info" icon="info-circle-fill" @click="$modal.show('KbInfoModal',{ kb: 'location' })" class="cursor_pointer infoKbIcon md-mar-left" />
        </h2>
        <div>
          <button class="dialer-button dialer-button-primary" @click="$modal.show('AddLocationModal')">
            <vb-svg name="dialer-largePlus-icon" width="15.996" height="15.996" viewBox="0 0 15.996 15.996" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
            <span class="sm-mar-left">Add Location</span>
          </button>
        </div>
      </div>
      
      <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom">
        <!-- <span> Here you can manage all your locations.</span> -->
        <span>Location-based settings for emergency services and auditing purposes.</span>
        <div class="whiteBGinputWithGreyRoundedBorder seachBox">
          
          <b-input type="text" placeholder="Search Location" v-model="filter.locations.search" />
        </div>
      </div>
      
      <vb-table textNoRecord=" "
        :isListEmpty="isEmpty(filterLocations)"
        :loading="api.locations.send" 
        :listLength="pagination.locations.total" 
        :perPage="pagination.locations.per_page" 
        @page-changed="pagination.locations.page=$event;fetchLocations();"
        :conditions="{ pagination:{ per_page: false } }"
        :page="pagination.locations.page"
        class="latestTableDesign-withBlackBorders-again p-0"
      >
        <template slot="header">
          <tr>
            <th class="dialer-has-sort">
              <span>Address</span>
            </th>
            <th class="dialer-has-sort">
              <span>Emergency Validation State</span>
            </th>
            <th class="dialer-has-sort">
              <span>Phone Number</span>
            </th>
            <th class="dialer-col-right">
              <span>Updated At</span>
            </th>
          </tr>
        </template>
        <template #body>
          <tr class="dialer-row-select" v-for="location in filterLocations" :key="location.real_id" @click="$modal.show('EditLocationModal', { location: location })">
            <td class="dialer-row-title">
              <!-- {{ location.address }}  -->
              <div>{{ location.house }}</div> 
              <div>{{ location.street }} </div>
              <div>{{ location.town }} </div>
              <div>{{ location.postcode }} </div>
            </td>
            <td class="dialer-row-title">
              <p v-if="location.country != 'GB'" class="text-danger bold-5">Emergency services are not available outside the UK</p>
              <p v-else>-</p>
            </td>
            
            <td class="dialer-row-title">{{ location | get_property('caller','-') | number_formater }}</td>
            <td class="dialer-col-right">Updated At</td>
            
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex flex-column w-fit-content">
                <div class="latestShimmerDesign mt-2" style="height:16px;width:150px"></div>
                <div class="latestShimmerDesign mt-2" style="height:16px;width:100px;"></div>
                <div class="latestShimmerDesign mt-2" style="height:16px;width:200px;"></div>
                <div class="latestShimmerDesign mt-2" style="height:16px;width:150px;"></div>
              </div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:150px;"></div>
            </td>
            <td>
              <div class="latestShimmerDesign" style="height:20px;width:150px;"></div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:48px;width:48px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    
    </section>

    <AddLocationModal @latest-list="pagination.locations.page=1;fetchLocations();" />
    <EditLocationModal @latest-list="pagination.locations.page=1;fetchLocations();" />
  </div>
</template>

<script>
import AddLocationModal from "../Modals/AddLocationModal.vue";
import EditLocationModal from "../Modals/EditLocationModal.vue";
import { VOIP_API } from "../../utils";
export default {
  name: "Location",
  inject:['isEmpty','appNotify'],
  components: {
    AddLocationModal,
    EditLocationModal,
  },
  data() {
    return {
      response: {
        locations: [],
      },
      api: {
        locations: this.$helperFunction.apiInstance(),
        location_delete: this.$helperFunction.apiInstance({ send: [] }),
      },
      filter: {
        locations: {
          search: "",
        },
      },
      pagination: {
        locations: {
          per_page: 10,
          page: 1,
          total: 0,
        },
      },
    };
  },
  computed: {
    filterLocations() {
      const search = this.filter.locations.search.toLowerCase().trim();
      const locations = this.response.locations
      const filter_locations = locations.filter((item) => `${item.address} ${item.country} ${item.house} ${item.town} ${item.street} ${item.label} ${item.caller} ${(item.caller || '').replace(/-/g,'')}`.toLowerCase().trim().includes(search));
      return filter_locations
    },
  },
  methods: {
    fetchLocations() {
      let vm = this;
      if(vm.api.locations.send) return;
      vm.api.locations.send = true;
      VOIP_API.endpoints.location.list({
        page: vm.pagination.locations.page,
      }).then(({ data }) => {
        vm.response.locations = data?.data ?? [];
        vm.pagination.locations.total = data?.meta?.pagination?.total ?? 0;
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        vm.api.locations.send = false;
      });
    },
    deleteLocation(location_id) {
      let vm = this;
      if (vm.api.location_delete.send.includes(location_id)) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this Location`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.location_delete.send.push(location_id);
            VOIP_API.endpoints.location.delete({
              id: location_id,
            }).then(() => {
              vm.pagination.locations.page=1
              vm.fetchLocations()
              vm.appNotify({
                message: "Successfully Deleted!",
                type: "success",
              })
            }).catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(() => {
              if (vm.api.location_delete.send.includes(location_id)) {
                vm.api.location_delete.send.splice(vm.api.location_delete.send.indexOf(location_id),1);
              }
            });
          }
        },
      });
    },
  },
  activated() {
    this.pagination.locations.page=1
    this.fetchLocations();
  },
};
</script>

