var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "NotificationsContainer",
    staticClass: "NotificationsContainer"
  }, [_c('div', {
    staticClass: "NotificationsContainer-inner"
  }, _vm._l(_vm.notifys, function (notify) {
    return _c('div', {
      key: notify.id,
      staticClass: "Notifications-row"
    }, [_c('vb-icon', {
      staticClass: "Notifications-x-circle-icon",
      attrs: {
        "icon": "Notifications-x-circle-icon"
      },
      on: {
        "click": function ($event) {
          return notify.close();
        }
      }
    }), _c('div', {
      staticClass: "Notifications-row-rightSide"
    }, [notify.title ? _c('div', {
      staticClass: "somethingLikeTag",
      class: `notification-type-${_vm.getProperty(notify, 'type', 'info')}`,
      staticStyle: {
        "background-color": "#FF7258"
      }
    }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(notify, 'title', 'Title')))])]) : _vm._e(), _c('div', {
      staticClass: "Notifications-row-mainText"
    }, [_vm._v(_vm._s(_vm._f("get_property")(notify, 'message')))]), _c('div', {
      staticClass: "Notifications-row-secondaryText"
    }, [_vm._v(" " + _vm._s(_vm._f("filter_date_current")(_vm._f("get_property")(notify, 'current_time'), false, {
      utc: true,
      formate_now: true
    })) + " ")])])], 1);
  }), 0)]);

}
var staticRenderFns = []

export { render, staticRenderFns }