var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Location name")]), _vm.isEmpty(_vm.response.emergency_service) ? _c('div', {
    staticClass: "innerRedBoxwithRoundedBorder mt-20px"
  }, [_c('vb-icon', {
    staticClass: "dialer-thunder-icon",
    attrs: {
      "icon": "emergencyLocation-error-icon",
      "width": "24.034px",
      "height": "24px"
    }
  }), _c('p', {
    staticClass: "innerRedBoxwithRoundedBorder-text mb-0"
  }, [_vm._v(" This is used for emergency services. Currently you have not set the address for the number ")])], 1) : _vm._e(), _c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-between mt-20px"
  }, [_c('p', {
    staticClass: "latestGreyBox-descriptionText mt-0 mb-0 w-75"
  }, [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.response.emergency_service, 'full_location')) + " " + _vm._s(_vm._f("get_property")(_vm.response.emergency_service, 'postcode')) + " ")]), _c('button', {
    staticClass: "newButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.isEmpty(_vm.response.emergency_service) ? _vm.$modal.show(`${_vm._uid}-AddEmergencyLocationModal`) : _vm.$modal.show(`${_vm._uid}-UpdateEmergencyLocationModal`, {
          location: _vm.response.emergency_service
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": `squared-${_vm.isEmpty(_vm.response.emergency_service) ? 'add' : 'update'}-icon`,
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.isEmpty(_vm.response.emergency_service) ? 'Add' : 'Update'))])], 1)])])])])]), _c('AddEmergencyLocationModal', {
    attrs: {
      "modalName": `${_vm._uid}-AddEmergencyLocationModal`,
      "did": _vm.did
    },
    on: {
      "added": function ($event) {
        return _vm.getEmergencyService();
      }
    }
  }), _c('UpdateEmergencyLocationModal', {
    attrs: {
      "modalName": `${_vm._uid}-UpdateEmergencyLocationModal`,
      "did": _vm.did
    },
    on: {
      "updated": function ($event) {
        return _vm.getEmergencyService();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }