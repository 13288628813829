<template>
  <modal
    class="dialer_animation center-zoomIn-popup m-w-600 switching-plan-modal dialer-recordVideo-modal"
    width="50%"
    height="80%"
    :name="modalName"
    @opened="onOpened($event)"
    @before-close="onBeforeClose()"
  >
    <div class="main">
      <div class="cross" @click="$modal.hide(modalName)">
        <b-icon icon="x" font-scale="1.5" />
      </div>
      <div class="mediaNotAvailable-container" v-if="error.message" >{{ error.message }}</div>
      <template v-else>
        <div class="video-container">
          <video v-if="!recorder.blob" ref="stream" autoplay width="100%" />
          <video v-show="recorder.blob" ref="preview" width="100%" />
        </div>
        <div class="actions">
          <div
            v-if="recorder.blob"
            class="submit-button"
            v-b-tooltip.hover
            title="Send"
            @click="submit()"
          >
            <vb-svg
              name="dialer-send"
              width="28"
              height="28"
              viewBox="0 0 24 24"
              stroke-width="1.5"
              stroke="#2c3e50"
              fill="none"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></vb-svg>
          </div>
          <div
            v-else-if="recorder.stream && recorder.recorder"
            :class="['recording', !recorder.started ? '' : 'recording_withTimer']"
            @click="!recorder.started ? recorder.start() : recorder.stop()"
          >
            <div class="recording_btn" v-if="!recorder.started" v-b-tooltip.hover title="Start">
              <b-icon icon="circle-fill" variant="currentColor" />
            </div>
            <template v-else>
              <div class="recording_btn" v-b-tooltip.hover title="Stop">
                <b-icon icon="square-fill" variant="currentColor" scale="" />
              </div>
              <p class="mb-0">{{ recorder.time | time_audio }}</p>
            </template>
            
          </div>
        </div>
        <div class="preview_action" v-if="recorder.blob" v-b-tooltip.hover title="Preview">
          <div class="preview-button" @click="conditions.play = !conditions.play;conditions.play?$refs['preview'].play():$refs['preview'].pause()">
            <vb-svg
              :name="!conditions.play ? 'dialer-play-circle' : 'dialer-pause-circle'"
              width="52"
              stroke="currentColor"
              height="52"
              viewBox="0 0 24 24"
              fill="none"
              stroke-width="1"
              stroke-linecap="round"
              stroke-linejoin="round"
            ></vb-svg>
          </div>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import { VideoRecorder } from "../../../utils/videoRecorder";
var default_time = 3 * 60 * 1000; //? 2 sec
export default {
  name: "RecordedVideoModal",
  props: {
    modalName: {
      type: String,
      default: 'RecordedVideoModal'
    },
  },
  data() {
    return {
      error: {
        message: ''
      },
      conditions: {
        play: false,
      },
      recorder: null,
    };
  },
  methods: {
    onOpened() {
      this.makeStream()
    },
    onBeforeClose(){
      this.recorder.reset()
      this.conditions.play = false;
      this.error.message = ''
    },
    toogleRecordStart(){
      try {
        if(this.recorder.started) {
          this.recorder.stop()
        } else {
          this.recorder.start()
        }
      } catch (ex) {
        console.log(ex)
      }
    },
    async makeStream(){
      try {
        const stream = await this.recorder.makeStream()
        const recording_el = this.$refs.stream;
        recording_el.srcObject = stream;
        recording_el.captureStream = recording_el.captureStream || recording_el.mozCaptureStream;
      } catch(ex){
        if(ex?.name=='NotReadableError') this.error.message = "Your camera device is in use by another application"
        else if(ex?.name=='NotAllowedError') this.error.message = "Please allow camera permissions from the browser"
        else if(ex?.name=='NotFoundError') this.error.message = "There is no camera connected"
      }
    },
    async blobready(blob){
      const url = URL.createObjectURL(blob);
      await this.$nextTick()
      this.$refs['preview'].src=url
    },
    submit() {
      this.$emit("send", this.recorder.blob);
      this.$modal.hide(this.modalName);
    },
  },
  created(){
    this.recorder = new VideoRecorder({
      max_time: default_time
    })
    this.recorder.onblobready=this.blobready.bind(this)
  },
};
</script>

<style lang="scss" scoped>
.main {
  height: inherit;
  width: inherit;
  .cross {
    position: absolute;
    top: 5px;
    right: 5px;
    z-index: 1;
    background-color: $white;
    @include border-radius(50%);
    width: 30px;
    height: 30px;
    color: $body-color;
    display: flex;
    cursor: pointer;
    align-items: center;
    justify-content: center;
    transition: 0.3s all ease-in-out;
    &:hover {
      background: $primary;
      color: $white;
    }
  }
  .video-container {
    height: 100%;
    width: 100%;
    background-color: $black;
    display: flex;
    align-items: center;
    video {
      height: 100%;
    }
  }
  .actions,
  .preview_action {
    position: absolute;
    bottom: 20px;
    display: flex;
    justify-content: center;
    width: 100%;
    .recording {
      flex: 1;
      display: flex;
      justify-content: center;
      max-width: 100px;
      align-items: center;
      &.recording_withTimer {
        justify-content: space-between;
        @include border-radius(7px);
        width: auto;
        max-width: 125px;
        .recording_btn {
          width: 40px;
          height: 40px;
          font-size: 14px;
          @include border-radius(7px);
          background-color: $white;
          color: $primary;
        }
        p {
          color: $white;
        }
      }
    }
    .recording_btn,
    .submit-button,
    .preview-button {
      @include border-radius(50%);
      height: 50px;
      width: 50px;
      display: flex;
      justify-content: center;
      align-items: center;
      background-color: $primary;
      color: $white;
      cursor: pointer;
    }
    .submit-button {
      background-color: $white;
      svg {
        transform: rotate(44deg);
        margin-left: -4px;
      }
    }
  }
  .preview_action {
    bottom: unset;
    top: 50%;
    transform: translateY(-50%);
    .preview-button {
      transition: 0.3s background-color ease-in-out;
      background-color: transparent;
      svg {
        transition: 0.3s stroke ease-in-out;
        stroke: $white;
      }
      &:hover {
        background-color: $primary;
        svg {
          stroke: $white;
        }
      }
    }
  }
}
</style>
