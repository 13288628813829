<template>
  <div>
    <loader-main v-if="is_loading" />
  </div>
</template>

<script>
import { TokenService } from '../services'
import { LOGGER, $fn, VOIP_API, /*URLS,*/ DEVICE_INFO } from '../utils'
import LoaderMain from '../components/LoaderMain.vue'
import { MOTHERSHIP } from '@/mothership'
export default {
  name: 'ForceLogin',
  components: { 
    LoaderMain 
  },
  data(){
    return {
      vb_token: '',
      account: '',
      username: '',
      is_loading: true,
      loginresponse: null,
    }
  },
  methods: {
    async login(){
      try {
        const { data: { data } } = await VOIP_API.endpoints.auth.getLoginResponseByVBToken({
          username: this.username,
          uuid: this.vb_token,
          accountcode: this.account,
        });
        // URLS.relayApi=relay_proxy_data?.server ?? '';
        // const { data: { data } } = await VOIP_API.endpoints.users.verifytoken({
        //   uuid: this.vb_token,
        //   accountcode: this.account,
        // })
        // TokenService.TOKEN.set(data.response.token)
        $fn.setUserLocally(data)
        this.$store.state.global_conditions.is_iframe=true
        this.$router.push({
          name: 'dashboard'
        })
      } catch (ex) {
        LOGGER.danger('ex',{ex},'test')
        // window.parent.postMessage({ message: "logoutoniframe", value: '' }, "*");
        // window.postMessage({ message: "logoutoniframe", value: '' }, "*");
        // this.$router.push({
        //   name: 'login'
        // })
        MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.LOGOUT)
      } finally {
        this.is_loading=false
      }
    },
  },
  created(){
    LOGGER.log('this.$route.query',this.$route)
    const { vb_token, account, integration, username, region, platform, cp_domain, device_id } = this.$route.query
    LOGGER.log('this.GET_USER_USERNAME',username)
    VOIP_API.region=region
    VOIP_API.platform=platform
    this.$store.state.integration_platform=integration
    this.vb_token=vb_token
    this.account=account
    this.username=username
    TokenService.CP_DOMAIN.set(cp_domain)
    const user = TokenService.USER.get()
    LOGGER.log('::::::::tt::::::::::',user?.vbToken,vb_token)
    if(device_id){
      DEVICE_INFO.id=device_id
    }
    if(vb_token && user && user.vbToken && user.vbToken==vb_token){
      this.$router.push({
        name: 'dashboard'
      })
    } else {
      $fn.logout({
        is_router_change: false,
        mothership_logout: false,
        cb: ()=>{
          LOGGER.log('::::::::tttt::::::::::')
          TokenService.removeStorage()
          this.login()
        }
      })
    }
    LOGGER.log('integration_platform',this.$store.state.integration_platform)
  },
}
</script>

<style>

</style>