
import { VOIP_API } from "../index"

export const VOICEMAIL_API_ENDPOINTS = {
  list:{
    default() {
      return VOIP_API.axios.voip.request({
        method: 'get',
        url: 'voicemails'
      })
    },
    servicevoicemail(account,params={}){
      return VOIP_API.axios.voip.request({
        method: 'get',
        url: `voicemails/servicevoicemail/${account}`,
        params,
      })
    },
    allvoicemails(account,params={}){
      return VOIP_API.axios.voip.request({
        method: 'get',
        url: `voicemails/allvoicemails/${account}`,
        params,
      })
    },
  },
  updatesetting: {
    emailfornotification(data={}){
      return VOIP_API.axios.voip.request({
        url: `portal-proxy`,
        method: "POST",
        data: {
          ...data,
          action: 'email_to_voicemail',
          type: 'portal'
        },
      })
    },
    default(account,data) {
      return VOIP_API.axios.voip.request({
          method: 'patch',
          url: `voicemails/${account}`,
          data,
      })
    },
    updatesharedaccounts(account,data){
      return VOIP_API.axios.voip.request({
        method: 'patch',
        url: `voicemails/updatesharedaccounts/${account}`,
        data,
      })
    }
  },
  voicemailgreeting(params={}) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: 'recordvoicemails',
      params,
    })
  },
  defaultvoicemailgreeting(){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: 'default-voice-mails',
    })
  },
  voicemailrecord(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'recordvoicemails',
      data,
    })
  },
  getpollyVoices(){
    return VOIP_API.axios.voip.request({
      url: `polly-voices`,
      method: "GET",
    })
  },
  getVoices(){
    return VOIP_API.axios.voip.request({
      url: `basicivr/text-to-speech-AIConfig`,
      method: "POST",
    })
  },
  delete(data={}){
    return VOIP_API.axios.voip.request({
      url: 'delete-voicemail',
      method: 'POST',
      data,
    })
  },
  deleteRecordVoiceMail(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `recordvoicemails/${id}`,
      method: 'DELETE',
      data,
    })
  },
  chageGreetingType(data={}){
    return VOIP_API.axios.voip.request({
      url: `change-greeting-type`,
      method: 'POST',
      data,
    })
  },
}