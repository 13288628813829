<template>
  <div class="dialer-settings-main">
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Numbers</div>
      <div class="plus-IOSIcon-container" @click="$modal.show('AddNumber')">
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992"  />
      </div>
    </div>
    <section class="dialer-settings-section dialer_numberSetting">
      <div class="mobileInputIOS iconOnLeft mt-32px">
        <b-input type="text" placeholder="Search numbers" v-model="filter.numbers.search" />
        <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
      </div>
      
      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div 
          class="basicWhiteIOScard-item" 
          v-for="number in filterTelephoneNumbers" 
          :key="`${number.id}-${number.object}`" 
          @click="number.object != 'CallerId' ? $modal.show('EditNumber', { number: number }) : ''" 
        >
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" :icon="number.object === 'TelephoneNumbers' ? 'MyNumbers-TelephoneNumber-IOSIcon' : 'MyNumbers-CallerID-IOSIcon'"/>
            </div>
            <div class="d-flex flex-column">
              <template v-if="number.object === 'TelephoneNumbers'">
                <div class="headingInsideTable">
                  {{ number.number | number_formater }}
                </div>
              </template>
              <template v-else>
                <div class="headingInsideTable">
                  {{ number.number | number_formater }}
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="d-flex mr-6px align-items-center" >
              <div class="InfoComponentInsideAnroidIOSApp textAlignRight mr-8px">
                <Info 
                  v-if="number.extnType" 
                  :is_image_show="false"
                  :name="number |  get_property('assignedToUsersArr.label')" 
                />
              </div>
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
        </div>
      </div>
      
    </section>

    <EditNumbersModal @latest="afterUpdating(false)" />

    <AddNumberModal @added="afterUpdating(true)" />
  </div>
</template>

<script>

import Info from "../../Lists/Info.vue";

import AddNumberModal from '../modals/numbers/AddNumber.vue'
import EditNumbersModal from "../modals/numbers/EditNumber.vue";

import { mapGetters } from 'vuex';

export default {
  name: "Numbers",
  inject:['isEmpty','getNumbers','appNotify'],
  components: {
    AddNumberModal,
    EditNumbersModal,
    Info,
  },
  data() {
    return {
      filter: {
        numbers:{
          search: '',
        }
      },
      api: {
        telephone_numbers: this.$helperFunction.apiInstance(),
      },
    };
  },
  filters: {
    filterType(value) {
      if (value === "CallerId") return "Caller ID";
      return "Telephone Number";
    },
  },
  computed: {
    ...mapGetters([
      'getVoipNumbers',
    ]),
    filterTelephoneNumbers() {
      const search = this.filter.numbers.search.toLowerCase().trim();
      const numbers = this.getVoipNumbers;
      const filter_numbers = numbers.filter((val) => `${val.number || ""} ${val.did || ""}`.toLowerCase().trim().replace(/\D/g,'') .includes(search) || `${val.number || ""} ${val.did || ""} ${val.label || ""}`.toLowerCase() .trim() .includes(search));
      return filter_numbers
    },
  },
  methods: {
    fetchTelephoneNumber() {
      let vm = this
      if(vm.api.telephone_numbers.send) return;
      vm.api.telephone_numbers.send=true
      this.getNumbers()
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.telephone_numbers.send = false
      });
    },
    afterUpdating(refresh) {
      if (refresh) {
        setTimeout(() => {
          this.fetchTelephoneNumber();
        }, 8 * 1000);
      } else {
        this.fetchTelephoneNumber();
      }
    },
  },
  activated(){
    this.fetchTelephoneNumber();
  },
};
</script>


<style lang="scss" scoped>
.dialer-row-title {
  height: auto;
  // min-height: 50px;
}
</style>
