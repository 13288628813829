import { TokenService } from '@/services/storage.service';
import createPersistedState from 'vuex-persistedstate';
import presistedStateStorage from './helper/storage';


export default createPersistedState({
  key: TokenService.SETTING.key,
  paths: [
    `local_setting.theme.mode`,
    // `local_setting.theme.system_mode`,
    `local_setting.settings.tab`,
    `local_setting.chat.appearence.is_icon_show`,
    `local_setting.chat.appearence.list_view`,
    `local_setting.chat.appearence.blink_unread`,
    `local_setting.chat.view.recent_type`,
    `local_setting.numbers.formating.international_formate`,
    `local_setting.calls.feedback`,
    `local_setting.analytics.tab`,
    `local_setting.dashboard.design.width`,
    `local_setting.dashboard.design.flip`,
    `local_setting.dashboard.design.call_info_width`,
    `local_setting.dashboard.design.scroll_top`,
    `local_setting.dashboard.design.tags_show`,
    `local_setting.dashboard.design.transfer_log`,
    `local_setting.mobile_settings.tab`,
  ],
  storage: presistedStateStorage(TokenService.SETTING)
});