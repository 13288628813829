var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Training events ")]), _vm._m(0), _c('div', {
    staticClass: "dropdownsContainer"
  }, [_c('div', {
    staticClass: "primaryBorderInput mr-16px"
  }, [_c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  }), _c('vue-ctk-date-time-picker', _vm._b({
    attrs: {
      "format": 'YYYY-MM-DD HH:mm'
    },
    model: {
      value: _vm.start_time,
      callback: function ($$v) {
        _vm.start_time = $$v;
      },
      expression: "start_time"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "primaryBorderInput mr-16px"
  }, [_c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  }), _c('vue-ctk-date-time-picker', _vm._b({
    attrs: {
      "format": 'YYYY-MM-DD HH:mm',
      "label": "End data & time"
    },
    model: {
      value: _vm.end_time,
      callback: function ($$v) {
        _vm.end_time = $$v;
      },
      expression: "end_time"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown w-220px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All categories")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All categories ")])], 1)], 1), _vm._m(1), _c('div', {
    staticClass: "EmployeeTrainingEventsTable mt-32px"
  }, [_vm._m(2), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('img', {
          staticClass: "BigImageInsideTable mr-32px",
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        }), _c('div', {
          staticClass: "d-flex flex-column"
        }, [_c('div', {
          staticClass: "td-heading"
        }, [_vm._v("Test")]), _c('div', {
          staticClass: "td-text mb-8px"
        }, [_vm._v("In this example from GMail, they launched a tour that announced two new features...")]), _c('div', {
          staticClass: "td-text mb-8px"
        }, [_vm._v("London")]), _c('div', {
          staticClass: "td-text mb-8px"
        }, [_vm._v("Ticket limit: 50")]), _c('div', {
          staticClass: "td-text mb-8px"
        }, [_vm._v("Used tickets: 15")])])])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v(" 27/07/2022 ")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_vm._v(" 15 ")])])];
      },
      proxy: true
    }])
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription mb-3"
  }, [_c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grayCard TrainingEventsSection-1 borderRadius-12px"
  }, [_c('div', {
    staticClass: "w-25 pr-16px"
  }, [_c('div', {
    staticClass: "smallHeading"
  }, [_vm._v("Events")]), _c('div', {
    staticClass: "smallText mt-16px"
  }, [_vm._v("1")])]), _c('div', {
    staticClass: "w-25 pr-16px"
  }, [_c('div', {
    staticClass: "smallHeading"
  }, [_vm._v("Attendants")]), _c('div', {
    staticClass: "smallText mt-16px"
  }, [_vm._v("1")])]), _c('div', {
    staticClass: "w-25 pr-16px"
  }, [_c('div', {
    staticClass: "smallHeading"
  }, [_vm._v("Actual participants")]), _c('div', {
    staticClass: "smallText mt-16px"
  }, [_vm._v("1")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Event")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("date")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("attending")])]);

}]

export { render, staticRenderFns }