var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading"
  }, [_vm._v("Employee headcount")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" This report shows up-to-date list of employees in selected time period. ")]), _c('div', {
    staticClass: "dropdownsContainer"
  }, [_c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All time")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All time ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All employees")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All employees ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All teams")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All teams ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All locations")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All positions")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All positions ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All genders")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All genders ")])], 1)], 1), _vm._m(0), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown mt-32px mr-0",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All ")])], 1), _c('div', {
    staticClass: "HeadCountTable mt-32px"
  }, [_vm._m(1), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Marie jane")])])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v("UK - Digital production")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_vm._v("10/07/2017")]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_vm._v("10/07/2022")]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_vm._v("5 years 2 months")])]), _c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/2.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Morgan")])])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v("UK - Digital production")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_vm._v("10/07/2017")]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_vm._v("10/07/2022")]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_vm._v("5 years 2 months")])]), _c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/3.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jason")])])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v("UK - Digital production")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_vm._v("10/07/2017")]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_vm._v("10/07/2022")]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_vm._v("5 years 2 months")])]), _c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/4.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Lauren German")])])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v("UK - Digital production")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_vm._v("10/07/2017")]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_vm._v("10/07/2022")]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_vm._v("5 years 2 months")])])];
      },
      proxy: true
    }])
  })], 1)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-center"
  }, [_c('div', {
    attrs: {
      "id": "container"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Employee")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("team")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("start date")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("last working day")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Length of employment")])]);

}]

export { render, staticRenderFns }