var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup",
    attrs: {
      "width": "50%",
      "height": "auto",
      "name": "CreateReportModal"
    }
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Create a report")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('CreateReportModal');
      }
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary disabled",
    attrs: {
      "disabled": ""
    }
  }, [_vm._v("Create")])])]), _c('div', {
    staticClass: "dialer-box table"
  }, [_c('div', {
    staticClass: "dialer-box-segment"
  }, [_c('div', {
    staticClass: "dialer-reports-new"
  }, [_c('div', {
    staticClass: "dialer-input-field dialer-min-height"
  }, [_c('label', {
    staticClass: "dialer-input-label"
  }, [_vm._v("Report name (optional)")]), _c('div', [_c('input', {
    staticClass: "touched",
    attrs: {
      "label": "Report name (optional)",
      "value": ""
    }
  })])]), _c('div', {
    staticClass: "dialer-input-field dialer-select-field"
  }, [_c('label', {
    staticClass: "dialer-input-label"
  }, [_vm._v("Report type")]), _c('b-form-select', {
    staticClass: "dialer-select",
    attrs: {
      "options": _vm.options
    },
    model: {
      value: _vm.selected_report,
      callback: function ($$v) {
        _vm.selected_report = $$v;
      },
      expression: "selected_report"
    }
  })], 1)])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }