
import { VOIP_API } from "../index"

export const SPEED_DIALS_API_ENDPOINTS = {
  list(params={}) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: 'userspeeddials',
      params,
    })
  },
  create(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'userspeeddials',
      data,
    })
  },
  update(id,data={}) {
    return VOIP_API.axios.voip.request({
      method: 'PATCH',
      url: `userspeeddials/${id}`,
      data,
    })
  },
  shortcodes(params={}) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: 'userspeeddials/shortcodes',
      params,
    })
  },
  delete(id){
    return VOIP_API.axios.voip.request({
      method: 'delete',
      url: `userspeeddials/${id}`,
    })
  },
  globallist(params={}){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: 'globalspeeddials',
      params,
    })
  },
  globalcreate(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'globalspeeddials',
      data,
    })
  },
  globalupdate(id,data={}){
    return VOIP_API.axios.voip.request({
      method: 'PATCH',
      url: `globalspeeddials/${id}`,
      data,
    })
  },
  globalshortcodes(params={}){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: 'shortcode-global-speeddial',
      params,
    })
  },
  globaldelete(id,data={}){
    return VOIP_API.axios.voip.request({
      method: 'DELETE',
      url: `globalspeeddials/${id}`,
      data,
    })
  },
}