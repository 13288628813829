var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Awaiting approval ")]), _vm._m(0), _c('div', {
    staticClass: "hrmTabsContainer pt-3"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.id,
      class: `hrmTabsButton ${_vm.selectedTab.id == tab.id ? 'active' : ''} mr-16px`,
      on: {
        "click": function ($event) {
          _vm.selected_tab = tab;
        }
      }
    }, [_vm._v(_vm._s(tab.text))]);
  }), 0), _c('div', {
    staticClass: "mt-32px"
  }, [_vm.selectedTab.value == 'Active' ? [_vm._m(1), _c('div', {
    staticClass: "AwaitingApproval mt-32px"
  }, [_vm._m(2), _c('vb-table', {
    staticClass: "HrmTable",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filterActiveLeave),
      "listLength": _vm.filterActiveLeave.length,
      "loading": _vm.api.active.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterActiveLeave, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.user,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "nameWithImage"
          }, [_c('div', {
            staticClass: "imageContainer"
          }, [_c('img', {
            attrs: {
              "src": require('@/assets/images/credit-card/1.jpeg')
            }
          })]), _c('div', {
            staticClass: "nameWithImage-text"
          }, [_vm._v(_vm._s(data.name))])])]), _c('td', {
            staticClass: "dialer-row-title two"
          }, [_vm._v(" " + _vm._s(data.reason) + " ")]), _c('td', {
            staticClass: "dialer-row-title three"
          }, [_vm._v(_vm._s(data.startDate))]), _c('td', {
            staticClass: "dialer-row-title four"
          }, [_vm._v("05/09/2022" + _vm._s(data.endDate))]), _c('td', {
            staticClass: "dialer-row-title five"
          }, [_c('b-dropdown', {
            staticClass: "ActionsDD",
            attrs: {
              "no-caret": "",
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('div', {
                  staticClass: "button-content"
                }, [_c('b-icon', {
                  staticClass: "downIcon",
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v(" " + _vm._s(data.actions) + " ")])], 1)], 1)]);
        });
      }
    }], null, false, 693030238)
  })], 1)] : _vm._e(), _vm.selectedTab.value == 'Previous' ? [_vm._m(3), _c('div', {
    staticClass: "AwaitingApproval mt-32px"
  }, [_vm._m(4), _c('vb-table', {
    staticClass: "HrmTable",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filterPreviousLeave),
      "listLength": _vm.filterPreviousLeave.length,
      "loading": _vm.api.previous.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref2) {
        var start = _ref2.start,
          end = _ref2.end;
        return _vm._l(_vm.filterPreviousLeave, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.user,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "nameWithImage"
          }, [_c('div', {
            staticClass: "imageContainer"
          }, [_c('img', {
            attrs: {
              "src": require('@/assets/images/credit-card/1.jpeg')
            }
          })]), _c('div', {
            staticClass: "nameWithImage-text"
          }, [_vm._v(_vm._s(data.name))])])]), _c('td', {
            staticClass: "dialer-row-title two"
          }, [_vm._v(" " + _vm._s(data.reason) + " ")]), _c('td', {
            staticClass: "dialer-row-title three"
          }, [_vm._v(_vm._s(data.startDate))]), _c('td', {
            staticClass: "dialer-row-title four"
          }, [_vm._v(_vm._s(data.endDate))]), _c('td', {
            staticClass: "dialer-row-title five"
          }, [_c('b-dropdown', {
            staticClass: "ActionsDD",
            attrs: {
              "no-caret": "",
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('div', {
                  staticClass: "button-content"
                }, [_c('b-icon', {
                  staticClass: "downIcon",
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v(" " + _vm._s(data.actions) + " ")])], 1)], 1)]);
        });
      }
    }], null, false, 2287781606)
  })], 1)] : _vm._e(), _vm.selectedTab.value == 'Future' ? [_vm._m(5), _c('div', {
    staticClass: "AwaitingApproval mt-32px"
  }, [_vm._m(6), _c('vb-table', {
    staticClass: "HrmTable",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filterFutureLeave),
      "listLength": _vm.filterFutureLeave.length,
      "loading": _vm.api.future.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref3) {
        var start = _ref3.start,
          end = _ref3.end;
        return _vm._l(_vm.filterFutureLeave, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.user,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "nameWithImage"
          }, [_c('div', {
            staticClass: "imageContainer"
          }, [_c('img', {
            attrs: {
              "src": require('@/assets/images/credit-card/1.jpeg')
            }
          })]), _c('div', {
            staticClass: "nameWithImage-text"
          }, [_vm._v(_vm._s(data.name))])])]), _c('td', {
            staticClass: "dialer-row-title two"
          }, [_vm._v(" " + _vm._s(data.reason) + " ")]), _c('td', {
            staticClass: "dialer-row-title three"
          }, [_vm._v(_vm._s(data.startDate))]), _c('td', {
            staticClass: "dialer-row-title four"
          }, [_vm._v(_vm._s(data.endDate))]), _c('td', {
            staticClass: "dialer-row-title five"
          }, [_c('b-dropdown', {
            staticClass: "ActionsDD",
            attrs: {
              "no-caret": "",
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('div', {
                  staticClass: "button-content"
                }, [_c('b-icon', {
                  staticClass: "downIcon",
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v(" " + _vm._s(data.actions) + " ")])], 1)], 1)]);
        });
      }
    }], null, false, 2594426130)
  })], 1)] : _vm._e()], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription mb-0"
  }, [_c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("reason")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Start date")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("end date")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Actions")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription mb-0"
  }, [_c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("reason")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Start date")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("end date")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Actions")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription mb-0"
  }, [_c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("reason")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Start date")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("end date")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Actions")])]);

}]

export { render, staticRenderFns }