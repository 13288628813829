var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-700 updateCaller-idModal AddMemberInPickupGroupsModal IosModal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "closed": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select User")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search Users",
      "disabled": _vm.api.update.send
    },
    model: {
      value: _vm.filter.user.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.user, "search", $$v);
      },
      expression: "filter.user.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.filterUsers, function (data) {
    return _c('div', {
      key: data.accountcode,
      staticClass: "basicWhiteIOScard-item",
      class: {
        'IosActive': _vm.selected.extension.includes(data.accountcode)
      },
      on: {
        "click": function ($event) {
          _vm.api.update.send ? '' : _vm.selected.extension.includes(data.accountcode) ? _vm.selected.extension.splice(_vm.selected.extension.indexOf(data.accountcode), 1) : _vm.selected.extension.push(data.accountcode);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex mr-6px align-items-center"
    }, [_c('div', {}, [_c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": data.accountcode,
        "name": data.label,
        "sub_info": data.extn
      }
    })], 1)]), _vm.selected.extension.includes(data.accountcode) ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), !_vm.filterUsers.length ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.members.send ? '' : 'Sorry, No Data Found',
      "design": 3
    }
  }, [_vm.api.members.send ? _c('vb-loading', {
    staticClass: "my-2",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2), _c('b-button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.update.send
    },
    on: {
      "click": function ($event) {
        return _vm.updatemembers();
      }
    }
  }, [_vm.api.update.send ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }