import { VOIP_API } from "../../index"

export const ACTIVITY_TYPES_CRM_API_ENDPOINTS = {
  list(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: 'activity-types',
      method: 'GET',
      params,
      cancelToken,
    })
  },
}