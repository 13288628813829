<template>
  <modal class="dialer_animation right_side_popup m-w-800 vm--container scrollable"  :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    
    <div class="dialer-call-notification-tray px-3">
      <div class="py-4">
        <div class="d-flex justify-content-between">
          <div class="dialer-call-notification-inner">
            <div class="dialer-call-notification-text">
              <div class="dialer-call-notification-main-text">Tag and note</div>
              <div class="dialer-call-notification-sub-text">There you can update the tags and note of this call</div>
            </div>
          </div>
          <div>
            <button @click="$modal.hide(modalName)" type="button" class="newCloseButton"> 
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </div>
        </div>
        <div class="pr-2" style="overflow: auto; height: calc(100vh - 92px - 24px - 24px);">
          <div>
            <div class="dialer-call-notification-actions-inner bg-white border-0">
              <b-form class="dialer-input-field input-consistent-inner-shadows pt-3" @submit.prevent="''">
                <b-form-input placeholder="Search here" v-model="filter.tag.search" />
              </b-form>
              <template v-if="!isEmpty(api.tag.validation_errors)">
                <p v-for="(error_message, index) in api.tag.validation_errors.log" :key="index">{{ error_message }}</p>
              </template>
              <ul class="dialer-tagList">
                <li 
                  v-for="tag in filterTags" 
                  :key="tag.real_id" 
                  @click="forms.tag.tags.indexOf(tag.real_id)>-1?forms.tag.tags.splice(forms.tag.tags.indexOf(tag.real_id),1):forms.tag.tags.push(tag.real_id)" 
                  :class="`${forms.tag.tags.indexOf(tag.real_id)>-1?'selected':''}`" 
                  v-vb-tag-color="forms.tag.tags.includes(tag.real_id) && tag.colour"
                >
                  <span v-vb-tag-color.text="forms.tag.tags.includes(tag.real_id) && tag.text_color">{{ tag.tag }}</span>
                </li>
              </ul>
              <div class="d-flex justify-content-end">
                <b-button class="mb-3 " variant="primary" @click="updatetag()">Submit</b-button>
              </div>
            </div>
          </div>

          <div class="md-mar-vertical mt-5">
            <div class="dialer-team-name mb-4">
              <div class="dialer-input-field input-consistent-inner-shadows">
                <template v-if="!isEmpty(api.note.validation_errors)">
                  <p v-for="(error_message, index) in api.note.validation_errors.note" :key="index">{{ error_message }}</p>
                </template>
                <label for="">Write some note for this call</label>
                <b-textarea  rows="10" no-resize placeholder="Enter Note" v-model="forms.note.note" class="w-100 p-3 line-height-1-imp" />
                <div class="d-flex justify-content-end mt-3">
                  <b-button variant="primary" @click="updatenote()">Submit</b-button>
                </div>
              </div>
            </div>
          </div>

         
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from '../../utils';
// import _ from 'lodash';
export default {
  name: 'TagsAndNotesUpdateModal',
  props: {
    modalName: {
      type: String,
      default: 'TagsAndNotesUpdateModal',
    },
  },
  data(){
    return {
      forms: {
        tag: {
          tags: [],
          reset(){
            this.tags=[]
            this.is_submitted=false
          },
          is_submitted: false
        },
        note: {
          note: '',
          reset(){
            this.note=''
            this.is_submitted=false
          },
          is_submitted: false
        },
      },
      data: {
        id: '',
        reset(){
          this.id=''
        },
      },
      filter: {
        tag: {
          search: '',
          reset(){
            this.search=''
          },
        },
      },
      api: {
        tag: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
        note: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
      },
    }
  },
  inject:['isEmpty','appNotify'],
  computed: {
    ...mapGetters([
      'getVoipCallTags'
    ]),
    filterTags() {
      const search = this.filter.tag.search.toLowerCase().trim()
      return this.getVoipCallTags.filter(item => `${item.tag}`.toLowerCase().trim().includes(search));
    },
    tags() {
      const tags = this.getVoipCallTags
      const tag_ids = this.tagsId
      return tags.filter(item=>tag_ids.includes(item.real_id));
    },
  },
  methods: {
    onBeforeOpen(event){
      const params = event.params || {}
      this.data.id = params.id || ''
      this.forms.tag.tags = params.tags || []
      this.forms.note.note = params.note || ''
    },
    onBeforeClose(){
      this.data.reset()
      this.filter.tag.reset()
      this.api.tag.reset()
      this.api.note.reset()
      this.forms.tag.reset()
      this.forms.note.reset()
    },
    updatetag() {
      let vm = this;
      vm.forms.tag.is_submitted=true
      if (vm.api.tag.send) return;
      vm.api.tag.send = true;
      vm.api.tag.error_message = '';
      vm.api.tag.validation_errors = {};
      vm.api.tag.status = 0;
      vm.api.tag.increment()
      VOIP_API.endpoints.call_activity
        .calltagsnotes({
          uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
          accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
          id: vm.data.id,
          log: vm.forms.tag.tags.toString(),
          type: "tag",
        })
        .then(() => {
          vm.api.tag.status = 1;
          this.appNotify({
            message: 'Successfully updated',
            type: 'success',
          })
          vm.$emit('updated')
        })
        .catch((ex) => {
          vm.api.tag.status = 2;
          vm.api.tag.error_message = ex.own_message
          vm.api.tag.validation_errors = ex.own_errors
          vm.appNotify({
            message: vm.api.tag.error_message,
            type: 'danger',
          })
        })
        .finally(() => {
          vm.api.tag.send = false;
          vm.forms.tag.is_submitted=false
        });
    },
    updatenote() {
      let vm = this;
      vm.forms.note.is_submitted=true
      if (vm.api.note.send) return;
      vm.api.note.send = true;
      vm.api.note.error_message = '';
      vm.api.note.validation_errors = {};
      vm.api.note.status = 0;
      vm.api.note.increment()
      VOIP_API.endpoints.call_activity
        .calltagsnotes({
          uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
          accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
          id: vm.data.id,
          log: vm.forms.note.note,
          type: "note",
        })
        .then(() => {
          vm.api.note.status = 1;
          vm.appNotify({
            message: 'Successfully updated',
            type: 'success',
          })
          vm.$emit('updated')
        })
        .catch((ex) => {
          vm.api.note.status = 2;
          vm.api.note.error_message = ex.own_message
          vm.api.note.validation_errors = ex.own_errors
          vm.appNotify({
            message: vm.api.note.error_message,
            type: 'danger',
          })
        })
        .finally(() => {
          vm.api.note.send = false;
          vm.forms.note.is_submitted=false
        });
    },
  },
}
</script>

<style>

</style>