var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Locations "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddNewEmployeeDataLocationsModal');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "plus",
      "font-scale": "1.3",
      "scale": "1.5"
    }
  }), _vm._v(" Add new ")], 1)]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Here you can set up job Locations in your company and then assign employees to them. ")]), _c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_vm._v(" No Locations assigned ")]), _c('td', {
            staticClass: "dialer-col-right text-left two"
          }, [_vm._v(" 0 ")]), _c('td', {
            staticClass: "dialer-col-right three"
          }, [_c('b-dropdown', {
            staticClass: "ActionsDD",
            attrs: {
              "no-caret": "",
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('div', {
                  staticClass: "button-content"
                }, [_c('b-icon', {
                  staticClass: "downIcon",
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead text-left one pt-1"
  }, [_vm._v("title")]), _c('th', {
    staticClass: "HrmTableHead text-left two pt-1"
  }, [_vm._v("Employees")]), _c('th', {
    staticClass: "HrmTableHead three pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1), _c('AddNewEmployeeDataLocationsModal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }