<template>
  <div class="content-area d-flex h-100 dialer_main_body">
    <div class="w-100 dialer_main_body_inner">
      <div class="dialer_inner_right_side ml-0">
        <div class="dialer-dashboard-main">
          <ActiveCallComponent />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ActiveCallComponent from "../components/MonitorDashboard/IndexNew.vue";

export default {
  name: "CallDashboard",
  components: {
    ActiveCallComponent,
  },
};
</script>

<style lang="scss">
.dialer-dashboard-main {
  height: 100%;
  border: $dialer-default-padding solid $white;
  overflow-y: hidden;
  .dialer-settings-intro {
    margin-bottom: 3 * $dialer-default-padding;
  }
}
</style>
