<template>
  <div class="hrm-home-container forDocumentsModule">
    <div class="hrm-home-inner">
      <div class="mainHeading withButton">
        Documents
        <button @click="$modal.show('hrmAddNewDocumentsModal')" class="dialer-button dialer-button-primary mr-0 withIcon">
          <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
          Add new
        </button>
      </div>
      <div class="hrmTabsContainer pt-3 pb-4">
        <div @click="showTabs = 'General'" :class="`hrmTabsButton ${showTabs == 'General' ? 'active' : ''} mr-16px`">General</div>
        <div @click="showTabs = 'Onboarding'" :class="`hrmTabsButton ${showTabs == 'Onboarding' ? 'active' : ''} mr-16px`">Onboarding</div>
        <div @click="showTabs = 'Offboarding'" :class="`hrmTabsButton ${showTabs == 'Offboarding' ? 'active' : ''} mr-16px`">Offboarding</div>
      </div>
      <template v-if="showTabs == 'General'">
        <!-- {{showTabs}} -->
        <div class="documentsModule-Table mt-32px">
          <!-- <div class="HrmTableHead-container">
            <div class="HrmTableHead one">Employee</div>
            <div class="HrmTableHead two">Event type</div>
            <div class="HrmTableHead three">Date</div>
            <div class="HrmTableHead four">Visibility</div>
            <div class="HrmTableHead five">Visibility</div>
          </div> -->
          <vb-table class="HrmTable">
            <template slot="header">
              <tr>
                <th class="HrmTableHead one pt-1">Notes</th>
                <th class="HrmTableHead two pt-1">Filename</th>
                <th class="HrmTableHead three pt-1">Created by</th>
                <th class="HrmTableHead four pt-1">shared with</th>
                <th class="HrmTableHead five pt-1">Actions</th>
              </tr>
            </template>
            <template #body>
              <tr class="dialer-row-select" >
                <td class="dialer-row-title one">
                  One Lenovo Laptop do not break it or I will break 
                  your legs!
                </td>
                <td class="dialer-col-right two">
                  <div class="DocumentIconWithText">
                    <div class="DocumentIcon-Container">
                      <vb-icon icon="pdfIcon-insideTable-hrmIcon" />
                    </div>
                    <div class="DocumentIconWithText-text">backupfile</div>
                  </div>
                </td>
                <td class="dialer-col-right three">Jason</td>
                <td class="dialer-col-right four">Everyone</td>
                <td class="dialer-col-right five">
                  <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
              <tr class="dialer-row-select" >
                <td class="dialer-row-title one">
                  One Lenovo Laptop do not break it or I will break 
                  your legs!
                </td>
                <td class="dialer-col-right two">
                  <div class="DocumentIconWithText">
                    <div class="DocumentIcon-Container">
                      <vb-icon icon="wordIcon-insideTable-hrmIcon" />
                    </div>
                    <div class="DocumentIconWithText-text">task.doc</div>
                  </div>
                </td>
                <td class="dialer-col-right three">Jason</td>
                <td class="dialer-col-right four">Everyone</td>
                <td class="dialer-col-right five">
                  <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
      </template>
      <template v-if="showTabs == 'Onboarding'">
        <!-- {{showTabs}} -->
        <div class="documentsModule-Table mt-32px">
          <!-- <div class="HrmTableHead-container">
            <div class="HrmTableHead one">Employee</div>
            <div class="HrmTableHead two">Event type</div>
            <div class="HrmTableHead three">Date</div>
            <div class="HrmTableHead four">Visibility</div>
            <div class="HrmTableHead five">Visibility</div>
          </div> -->
          <vb-table class="HrmTable">
            <template slot="header">
              <tr>
                <th class="HrmTableHead one pt-1">Notes</th>
                <th class="HrmTableHead two pt-1">Filename</th>
                <th class="HrmTableHead three pt-1">Created by</th>
                <th class="HrmTableHead four pt-1">shared with</th>
                <th class="HrmTableHead five pt-1">Actions</th>
              </tr>
            </template>
            <template #body>
              <tr class="dialer-row-select" >
                <td class="dialer-row-title one">
                  One Lenovo Laptop do not break it or I will break 
                  your legs!
                </td>
                <td class="dialer-col-right two">
                  <div class="DocumentIconWithText">
                    <div class="DocumentIcon-Container">
                      <vb-icon icon="pdfIcon-insideTable-hrmIcon" />
                    </div>
                    <div class="DocumentIconWithText-text">backupfile</div>
                  </div>
                </td>
                <td class="dialer-col-right three">Jason</td>
                <td class="dialer-col-right four">Everyone</td>
                <td class="dialer-col-right five">
                  <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
              <tr class="dialer-row-select" >
                <td class="dialer-row-title one">
                  One Lenovo Laptop do not break it or I will break 
                  your legs!
                </td>
                <td class="dialer-col-right two">
                  <div class="DocumentIconWithText">
                    <div class="DocumentIcon-Container">
                      <vb-icon icon="wordIcon-insideTable-hrmIcon" />
                    </div>
                    <div class="DocumentIconWithText-text">task.doc</div>
                  </div>
                </td>
                <td class="dialer-col-right three">Jason</td>
                <td class="dialer-col-right four">Everyone</td>
                <td class="dialer-col-right five">
                  <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
      </template>
      <template v-if="showTabs == 'Offboarding'">
        <!-- {{showTabs}} -->
        <div class="documentsModule-Table mt-32px">
          <!-- <div class="HrmTableHead-container">
            <div class="HrmTableHead one">Employee</div>
            <div class="HrmTableHead two">Event type</div>
            <div class="HrmTableHead three">Date</div>
            <div class="HrmTableHead four">Visibility</div>
            <div class="HrmTableHead five">Visibility</div>
          </div> -->
          <vb-table class="HrmTable">
            <template slot="header">
              <tr>
                <th class="HrmTableHead one pt-1">Notes</th>
                <th class="HrmTableHead two pt-1">Filename</th>
                <th class="HrmTableHead three pt-1">Created by</th>
                <th class="HrmTableHead four pt-1">shared with</th>
                <th class="HrmTableHead five pt-1">Actions</th>
              </tr>
            </template>
            <template #body>
              <tr class="dialer-row-select" >
                <td class="dialer-row-title one">
                  One Lenovo Laptop do not break it or I will break 
                  your legs!
                </td>
                <td class="dialer-col-right two">
                  <div class="DocumentIconWithText">
                    <div class="DocumentIcon-Container">
                      <vb-icon icon="pdfIcon-insideTable-hrmIcon" />
                    </div>
                    <div class="DocumentIconWithText-text">backupfile</div>
                  </div>
                </td>
                <td class="dialer-col-right three">Jason</td>
                <td class="dialer-col-right four">Everyone</td>
                <td class="dialer-col-right five">
                  <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
              <tr class="dialer-row-select" >
                <td class="dialer-row-title one">
                  One Lenovo Laptop do not break it or I will break 
                  your legs!
                </td>
                <td class="dialer-col-right two">
                  <div class="DocumentIconWithText">
                    <div class="DocumentIcon-Container">
                      <vb-icon icon="wordIcon-insideTable-hrmIcon" />
                    </div>
                    <div class="DocumentIconWithText-text">task.doc</div>
                  </div>
                </td>
                <td class="dialer-col-right three">Jason</td>
                <td class="dialer-col-right four">Everyone</td>
                <td class="dialer-col-right five">
                  <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
      </template>
    </div>
    <hrmAddNewDocumentsModal />
  </div>
</template>
<script>
import hrmAddNewDocumentsModal from '@/components/HRM/Documents/hrmAddNewDocumentsModal.vue'
  export default {
    name: 'HrmDocuments',
    components:{
      hrmAddNewDocumentsModal,
    },
    data(){
      return {
        showTabs: 'General',
      }
    }
  }
</script>

<style>

</style>