<template>
  <div>
    <modal
      :name="modalName"
      class="createNewSpaceModal"
      width="50%"
      height="auto"
      :scrollable="true"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-header w-100 d-flex position-relative py-0 justify-content-between align-items-center">
        <h2 class="dialer-edit-title my-0">Create new space</h2>
        <div class="dialer-edit-actions">
          <button class="newCloseButton" :disabled="api.add_task_space.send" @click="$modal.hide(modalName)" type="button" >
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      
      <b-alert variant="danger" :show="!!api.add_task_space.error_message">{{ api.add_task_space.error_message }}</b-alert>
      <div class="latestGreyBox-9-9-2023 mt-24px">
        <!-- Space Name section -->
        <div class="whiteBGinputWithGreyRoundedBorder">
          <label>Space name</label>
          <input 
            v-model="forms.add_task_space.name" 
            :disabled="api.add_task_space.send" 
            @input="api.get_is_unique.error_message=''"
            class="w-100 bg-white" 
            type="text" 
            placeholder="Space title" 
            :maxlength="$v.forms.add_task_space.name.$params.maxLength.max" 
          />
          <p class="MakeWhiteInDarkMode mb-4px">
            Space name can be maximum {{ $v.forms.add_task_space.name.$params.maxLength.max }} characters
          </p>
          <p v-if="(conditions.validation_step==1 && $v.forms.add_task_space.name.$invalid) || api.get_is_unique.error_message">
            <span class="text-danger field-error bold-4" v-if="!$v.forms.add_task_space.name.required">* Space name is required</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.name.valid">* Space name can be only alphabet or number</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.name.minLength">* Space name should be minimum {{ $v.forms.add_task_space.name.$params.minLength.min }} character</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.name.maxLength">* Space name can be maximum {{ $v.forms.add_task_space.name.$params.maxLength.max }} character</span>
            <span class="text-danger field-error bold-4" v-if="api.get_is_unique.error_message">{{ api.get_is_unique.error_message }}</span>
          </p>
        </div>
        <!-- Space color section -->
        <div class="whiteBGinputWithGreyRoundedBorder">
          <label>Space color</label>
          <div class="createSpace-selectColor-container">
            <div v-for="color in colors" :key="color.id" class="createSpace-selectColor-item-container">
              <div 
                @click="api.add_task_space.send ? '' : forms.add_task_space.color=color" 
                class="createSpace-selectColor-item"
                :class="{
                  'selected': forms.add_task_space.color==color,
                  'TransparentSelected': color == 'rgba(0,0,0,0)',
                }"
                :style="`background-color:${color};`">
                <vb-icon v-if="color == 'rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
              </div>
            </div>
          </div>
        </div>
        <!-- Space type section -->
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset">
            <div class="whiteBGinputWithGreyRoundedBorder">
              <label>Space type private</label>
            </div>
            <b-form-checkbox
              :disabled="api.add_task_space.send"
              class="newerSwitch"
              :checked="forms.add_task_space.type==types[1].value"
              @change="forms.add_task_space.type==types[1].value ? forms.add_task_space.type=types[0].value : forms.add_task_space.type=types[1].value"
              name="check-button"
              switch
            />
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-end mt-4">
        <button class="fullWidthDoneButton" :disabled="api.add_task_space.send" @click="create()">
          <vb-spinner v-if="api.add_task_space.send" />
          <template v-else>Create space</template>
        </button>
      </div>




      <!-- Space Name section -->
      <!-- <div v-if="conditions.step==1" class="createSpace-greyBox mt-24px">
        <div class="whiteBGinputWithGreyRoundedBorder">
          <label>Space name</label>
          <input 
            v-model="forms.add_task_space.name" 
            :disabled="api.add_task_space.send" 
            @input="api.get_is_unique.error_message=''"
            class="w-100 bg-white" 
            type="text" 
            placeholder="Space title" 
            :maxlength="$v.forms.add_task_space.name.$params.maxLength.max" 
          />
          <p class="MakeWhiteInDarkMode">
            Space name can be maximum {{ $v.forms.add_task_space.name.$params.maxLength.max }} characters
          </p>
          <p v-if="(conditions.validation_step==1 && $v.forms.add_task_space.name.$invalid) || api.get_is_unique.error_message">
            <span class="text-danger field-error bold-4" v-if="!$v.forms.add_task_space.name.required">* Space name is required</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.name.valid">* Space name can be only alphabet or number</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.name.minLength">* Space name should be minimum {{ $v.forms.add_task_space.name.$params.minLength.min }} character</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.name.maxLength">* Space name can be maximum {{ $v.forms.add_task_space.name.$params.maxLength.max }} character</span>
            <span class="text-danger field-error bold-4" v-if="api.get_is_unique.error_message">{{ api.get_is_unique.error_message }}</span>
          </p>
        </div>
      </div> -->

      <!-- Space color section -->
      <!-- <div v-if="conditions.step==2" class="createSpace-greyBox mt-24px">
        <div class="whiteBGinputWithGreyRoundedBorder">
          <label>Space color</label>
          <div class="createSpace-selectColor-container">
            <div v-for="color in colors" :key="color.id" class="createSpace-selectColor-item-container">
              <div 
                @click="api.add_task_space.send ? '' : forms.add_task_space.color=color" 
                class="createSpace-selectColor-item"
                :class="{
                  'selected': forms.add_task_space.color==color,
                  'TransparentSelected': color == 'rgba(0,0,0,0)',
                }"
                :style="`background-color:${color};`">
                <vb-icon v-if="color == 'rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Space prefix section -->
      <!-- <div v-if="conditions.step==3" class="createSpace-greyBox mt-24px">
        <div class="whiteBGinputWithGreyRoundedBorder">
          <label>Space prefix</label>
          <input 
            v-model="forms.add_task_space.prefix" 
            :disabled="api.add_task_space.send" 
            class="w-100 bg-white" 
            type="text" 
            placeholder="Space prefix" 
            :maxlength="$v.forms.add_task_space.prefix.$params.maxLength.max" 
          />
          <p class="MakeWhiteInDarkMode">
            Space prefix can be maximum {{ $v.forms.add_task_space.prefix.$params.maxLength.max }} characters
          </p>
          <p v-if="conditions.validation_step==3 && $v.forms.add_task_space.prefix.$invalid">
            <span class="text-danger field-error bold-4" v-if="!$v.forms.add_task_space.prefix.required">* Space prefix is required</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.prefix.alpha">* Space prefix can only be alphabet</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.prefix.minLength">* Space prefix should be minimum {{ $v.forms.add_task_space.prefix.$params.minLength.min }} character</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.prefix.maxLength">* Space prefix can be maximum {{ $v.forms.add_task_space.prefix.$params.maxLength.max }} character</span>
          </p>
        </div>
      </div> -->

      <!-- Space type section -->
      <!-- <div v-if="conditions.step==4" class="mt-24px"> -->
      <!-- <div v-if="conditions.step==3" class="mt-24px">
        <div class="whiteBGinputWithGreyRoundedBorder">
          <label>Space type</label>
        </div>
        <div class="d-flex align-items-stretch justify-content-between">
          <div 
            v-for="type in types" 
            :key="type.value" 
            class="todoList-blackBordered-box halfWidth" 
            :class="{
              'selected': forms.add_task_space.type==type.value
            }"
            @click="api.add_task_space.send ? '' : forms.add_task_space.type=type.value"
          >
            <vb-icon :icon="type.icon" width="92.998px" height="59.917px" />
            <div class="todoList-blackBordered-box-heading mt-24px">{{ type.text }}</div>
          </div>
        </div>
      </div> -->

      <!-- Space Active Status section -->
      <!-- <div v-if="conditions.step==5" class="createSpace-greyBox mt-24px">
        <div class="whiteBGinputWithGreyRoundedBorder">
          <label>Active status</label>
          <input 
            class="w-100 bg-white" 
            :disabled="api.add_task_space.send" 
            v-model="forms.add_task_space.status.active.name" 
            type="text" 
            placeholder="Active status" 
            :maxlength="$v.forms.add_task_space.status.active.name.$params.maxLength.max" 
          />
          <p v-if="conditions.validation_step==5 && $v.forms.add_task_space.status.active.name.$invalid">
            <span class="text-danger field-error bold-4" v-if="!$v.forms.add_task_space.status.active.name.required">* Active status is required</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.status.active.name.valid">* Active status can be only alphabet or number</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.status.active.name.minLength">* Active status should be minimum {{ $v.forms.add_task_space.status.active.name.$params.minLength.min }} character</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.status.active.name.maxLength">* Active status can be maximum {{ $v.forms.add_task_space.status.active.name.$params.maxLength.max }} character</span>
          </p>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder mt-24px">
          <label>Choose color</label>
          <div class="createSpace-selectColor-container">
            <div v-for="color in colors" :key="color.id" class="createSpace-selectColor-item-container">
              <div 
                @click="api.add_task_space.send ? '' : forms.add_task_space.status.active.color=color" 
                class="createSpace-selectColor-item"
                :class="{
                  'selected': forms.add_task_space.status.active.color==color,
                  'TransparentSelected': color == 'rgba(0,0,0,0)',
                }"
                :style="`background-color:${color};`">
                <vb-icon v-if="color == 'rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- Space Close Status section -->
      <!-- <div v-if="conditions.step==6" class="createSpace-greyBox mt-24px">
        <div class="whiteBGinputWithGreyRoundedBorder">
          <label>Close status</label>
          <input 
            class="w-100 bg-white" 
            :disabled="api.add_task_space.send" 
            v-model="forms.add_task_space.status.closed.name" 
            type="text" 
            placeholder="Close status" 
            :maxlength="$v.forms.add_task_space.status.closed.name.$params.maxLength.max" 
          />
          <p v-if="forms.add_task_space.submitted && $v.forms.add_task_space.status.closed.name.$invalid">
            <span class="text-danger field-error bold-4" v-if="!$v.forms.add_task_space.status.closed.name.required">* Closed status is required</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.status.closed.name.valid">* Closed status can be only alphabet or number</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.status.closed.name.minLength">* Closed status should be minimum {{ $v.forms.add_task_space.status.closed.name.$params.minLength.min }} character</span>
            <span class="text-danger field-error bold-4" v-else-if="!$v.forms.add_task_space.status.closed.name.maxLength">* Closed status can be maximum {{ $v.forms.add_task_space.status.closed.name.$params.maxLength.max }} character</span>
          </p>
        </div>
        <div class="whiteBGinputWithGreyRoundedBorder mt-24px">
          <label>Choose color</label>
          <div class="createSpace-selectColor-container">
            <div v-for="color in colors" :key="color.id" class="createSpace-selectColor-item-container">
              <div 
                @click="api.add_task_space.send ? '' : forms.add_task_space.status.closed.color=color" 
                class="createSpace-selectColor-item"
                :class="{
                  'selected': forms.add_task_space.status.closed.color==color,
                  'TransparentSelected': color == 'rgba(0,0,0,0)',
                }"
                :style="`background-color:${color};`">
                <vb-icon v-if="color == 'rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
              </div>
            </div>
          </div>
        </div>
      </div> -->

      <!-- next/previous step section -->
      <!--<div class="d-flex align-items-center justify-content-between mt-38px">
        <div>
          <div 
            v-if="conditions.step>1" 
            class="d-flex align-items-center" 
            style="cursor:pointer" 
            :disabled="api.add_task_space.send || api.get_is_unique.send"
            @click="onBack()"
          >
            <b-icon icon="chevron-left" class="darker-chevron-down mx-0" />
            <b class="darker-chevron-down ml-1">Back</b>
          </div>
        </div>
        <div>
          <!~~ <b-button v-if="conditions.step<6" :disabled="api.add_task_space.send" class="newDoneButton" variant="primary" @click="onNext()"> ~~>
          <b-button v-if="conditions.step<3" :disabled="api.add_task_space.send" class="newDoneButton" variant="primary" @click="onNext()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Next</span>
          </b-button>
          <!~~ <b-button v-if="conditions.step==6" :disabled="api.add_task_space.send" @click="create()" class="newDoneButton mwd-168px" variant="primary"> ~~>
          <b-button v-if="conditions.step==3" :disabled="api.add_task_space.send" @click="create()" class="newDoneButton mwd-168px" variant="primary">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Create space</span>
          </b-button>
        </div>
      </div>-->
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import required from 'vuelidate/lib/validators/required';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
import validateColor from "validate-color";
import { helpers } from 'vuelidate/lib/validators';
import { APP_COLORS } from '@/constants';
// import alpha from 'vuelidate/lib/validators/alpha';
const types = {
  public: {
    text: 'Voip Business workspace',
    icon: 'todo-voipbusinessWorkspace-icon',
    value: 'public',
  },
  private: {
    text: 'Private',
    icon: 'todo-private-icon',
    value: 'private',
  },
}
export default {
  name: "AddTaskSpaceModal",
  props: {
    modalName: {
      type: String,
      default: 'AddTaskSpace'
    },
  },
  data() {
    return {
      forms: {
        add_task_space: this.$helperFunction.formInstance({
          data: {
            name: '',
            prefix: '',
            color: APP_COLORS[0],
            type: types.public.value,
            status: {
              active: {
                name: '',
                color: APP_COLORS[0],
              },
              closed: {
                name: '',
                color: APP_COLORS[0],
              },
            },
          },
        }),
      },
      api: {
        add_task_space: this.$helperFunction.apiInstance({ 
          validation_errors: true, 
          error_message: true, 
        }),
        get_is_unique: this.$helperFunction.apiInstance({
          error_message: true,
        }),
      },
      conditions: {
        step: 1,
        validation_step: 0,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    types(){ return Object.values(types) },
    colors(){ return APP_COLORS },
  },
  validations: {
    forms: {
      add_task_space: {
        name: {
          required: required,
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
          minLength: minLength(3),
          maxLength: maxLength(15),
        },
        color: {
          required: required,
          validColor: (value) => { return validateColor(value) },
        },
        // prefix: {
        //   required: required,
        //   alpha: alpha,
        //   minLength: minLength(1),
        //   maxLength: maxLength(3),
        // },
        type: {
          required: required,
          valid: (value) => { return !!types[value] },
        },
        // status: {
        //   active: {
        //     name: {
        //       required: required,
        //       valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        //       minLength: minLength(3),
        //       maxLength: maxLength(15),
        //     },
        //     color: {
        //       required: required,
        //       validColor: (value) => { return validateColor(value) },
        //     },
        //   },
        //   closed: {
        //     name: {
        //       required: required,
        //       valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        //       minLength: minLength(3),
        //       maxLength: maxLength(15),
        //     },
        //     color: {
        //       required: required,
        //       validColor: (value) => { return validateColor(value) },
        //     },
        //   },
        // },
      },
    }
  },
  methods: {
    onBeforeOpen() {
      // TODO - 
    },
    onBeforeClose() {
      this.forms.add_task_space.reset()
      this.api.add_task_space.reset()
      this.api.get_is_unique.reset()
      this.conditions.step=1
      this.conditions.validation_step=0
    },
    async checkNameUnique(){
      try {
        this.$v.forms.add_task_space.name.$touch()
        if(this.api.get_is_unique.send || this.$v.forms.add_task_space.name.$invalid) return;
        this.api.get_is_unique.send=true
        this.api.get_is_unique.error_message=''
        await VOIP_API.endpoints.task_spaces.checkName({
          uid: this.getCurrentUser.uid,
          name: this.forms.add_task_space.name,
          flag: 'space',
        })
        this.conditions.step=2
      } catch (ex) {
        this.api.get_is_unique.error_message=ex.own_message
      } finally {
        this.api.get_is_unique.send=false
      }
    },
    async create(){
      // console.log("inside 1")
      this.forms.add_task_space.submitted=true
      // console.log("inside 2")
      this.$v.forms.add_task_space.$touch()
      // console.log("inside 3",this.$v.forms.add_task_space.$invalid,this.api.add_task_space.send)
      if(this.$v.forms.add_task_space.$invalid || this.api.add_task_space.send) return;
      // console.log("inside 4",this.$v.forms.add_task_space.$invalid,this.api.add_task_space.send)
      try {
        this.api.add_task_space.send=true
        // console.log("inside 5")
        this.api.add_task_space.error_message=''
        this.api.add_task_space.validation_errors={}
        // await VOIP_API.endpoints.task_spaces.create({
        //   name: this.forms.add_task_space.name,
        //   color: this.forms.add_task_space.color,
        //   type: this.forms.add_task_space.type,
        //   accountcode: this.getCurrentUser.account,
        //   uid: this.getCurrentUser.uid,
        //   prefix: this.forms.add_task_space.prefix,
        //   active_status_name: this.forms.add_task_space.status.active.name,
        //   active_status_color: this.forms.add_task_space.status.active.color,
        //   closed_status_name: this.forms.add_task_space.status.closed.name,
        //   closed_status_color: this.forms.add_task_space.status.closed.color,
        // })
        await VOIP_API.endpoints.task_spaces.create({
          name: this.forms.add_task_space.name,
          color: this.forms.add_task_space.color,
          type: this.forms.add_task_space.type,
          accountcode: this.getCurrentUser.account,
          uid: this.getCurrentUser.uid,
          prefix: null,
          active_status_name: null,
          active_status_color: null,
          closed_status_name: null,
          closed_status_color: null,
        })
        this.$modal.hide(this.modalName)
        this.$emit('created')
        this.appNotify({
          message: 'Successfully created',
          type: 'success',
        })
      } catch (ex) {
        this.api.add_task_space.error_message=ex.own_message ?? ''
        this.api.add_task_space.validation_errors=ex.own_errors ?? {}
      } finally {
        this.forms.add_task_space.submitted=false
        this.api.add_task_space.send=false
      }
    },
    onBack(){
      if(this.conditions.step==1){
        return;
      } else if(this.conditions.step==2){
        this.conditions.validation_step=1
        this.conditions.step=1
      } else if(this.conditions.step==3){
        this.conditions.validation_step=2
        this.conditions.step=2
      } else if(this.conditions.step==4){
        this.conditions.validation_step=3
        this.conditions.step=3
      } else if(this.conditions.step==5){
        this.conditions.validation_step=4
        this.conditions.step=4
      } else if(this.conditions.step==6){
        this.conditions.validation_step=5
        this.conditions.step=5
      }
    },
    onNext(){
      if(this.conditions.step==1){
        this.conditions.validation_step=1
        this.checkNameUnique()
      } else if(this.conditions.step==2){
        this.$v.forms.add_task_space.color.$touch()
        this.conditions.validation_step=2
        if(this.$v.forms.add_task_space.color.$invalid) return;
        this.conditions.step=3
      } else if(this.conditions.step==3){
        this.$v.forms.add_task_space.prefix.$touch()
        this.conditions.validation_step=3
        if(this.$v.forms.add_task_space.prefix.$invalid) return;
        this.conditions.step=4
      } else if(this.conditions.step==4){
        this.$v.forms.add_task_space.type.$touch()
        this.conditions.validation_step=4
        if(this.$v.forms.add_task_space.type.$invalid) return;
        this.conditions.step=5
      } else if(this.conditions.step==5){
        this.$v.forms.add_task_space.status.active.$touch()
        this.conditions.validation_step=5
        if(this.$v.forms.add_task_space.status.active.$invalid) return;
        this.conditions.step=6
      } else if(this.conditions.step==6){
        return;
      }
    },
  },
}
</script>

<style>

</style>