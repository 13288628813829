<template>
  <div 
    class="callActivity-Item-v2-container"
    :class="[
      call.direction=='outbound' ? 'Right' : 'Left',
      {
        'missedCall': call.status=='Missed',
        'recievedCall': call.status=='Received',
        'dialledCall': call.status=='Dialed',
      }
    ]" 
  >
    <div class="callActivity-Item-v2-inner-top">
      <div class="icon-container">
        <vb-icon v-if="call.status=='Missed'" v-b-popover.hover.top="'Missed Audio Call'" icon="CallActivityV2-missedCall-icon" height="55.922" width="55.922"/>
        <vb-icon v-else-if="call.status=='Received'" v-b-popover.hover.top="'Received Audio Call'" icon="CallActivityV2-receivedCall-icon" height="55.922" width="55.922"/>
        <vb-icon v-else v-b-popover.hover.top="'Dialed Audio Call'" icon="CallActivityV2-dialledCall-icon" height="55.922" width="55.922"/>
      </div>
      <div class="user-info">
        <template v-if="call.direction=='outbound'">
          <template v-if="call.dst_type=='Number'">
            <div class="user-name">{{ call.dst_number | number_formater }}</div>
          </template>
          <template v-else>
            <div class="user-name">{{ getVoipUsersAlisesDisplayName[call.dst_accountcode] }}</div>
            <div class="user-number">{{ call.dst_extn }}</div>
          </template>
        </template>
        <template v-else>
          <template v-if="call.src_type=='Number'">
            <div class="user-name">{{ call.src_number | number_formater }}</div>
          </template>
          <template v-else>
            <div class="user-name">{{ getVoipUsersAlisesDisplayName[call.src_accountcode] }}</div>
            <div class="user-number">{{ call.src_extn }}</div>
          </template>
        </template>
      </div>
      <div class="middle-section">
        <span class="middle-section-text">
          
        </span>
      </div>
      <div class="callLogIcons-container">
        
      </div>
      <div class="last-section">
        <div class="time">
          <span>{{ parseInt(call.date) | filter_date_current(null,{ unix: true, formate: 'h:mm a' }) }}</span>
        </div>
        <div class="duration">{{ call.bill_sec }}</div>
      </div>
    </div>
    <div class="callActivity-Item-v2-inner-2nd">
      
    </div>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_DASHBOARD } from '@/store/helper/getters';
export default {
  name: "AMILog",
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    assignedData: {
      type: Object,
    },
    type: {
      type: String,
      default: 'activity',
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  computed: {
    ...mapGetters([
      'getVoipCallTags',
      'getCurrentUser',
      'getIsMobile',
      'getVoipUsersAlisesDisplayName',
      GET_LOCAL_SETTING_DASHBOARD
    ]),
  },
};
</script>

<style>
</style>