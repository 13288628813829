<template>
  <div class="panel-inner panel-inner-callInfo z-index-1001-imp" :style="`width:${setting.call_info_width}%`">
    
    <h2 class="d-flex flex-wrap align-items-center justify-content-between">
      <span class="title d-flex align-items-center" @click="$emit('close')">
        <vb-svg name="dialer-arrow-ios-back-outline-icon" width="6.999" height="14.001" viewBox="0 0 6.999 14.001" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
        <span class="ml-2 cursor_pointer">Back</span>
      </span>
      <div id="adjust-design-call-info">
        <b-dropdown id="dropdown-form" no-caret offset="25"  ref="dropdown" class="m-2 dd-text-dark">
          <template #button-content>
            <vb-svg name="filter-icon" width="19" height="18" viewBox="0 0 19 18" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
          </template>
          <template v-if="true">
            <b-dropdown-form class="mb-0">
              <b-form-group class="mb-0 d-flex justify-content-center">
                <b-form-radio-group :checked="setting.call_info_width" class="" @change="$emit('width-change',$event)">
                  <b-form-radio class="mx-2" :value="50">50%</b-form-radio>
                  <b-form-radio class="mx-2" :value="75">75%</b-form-radio>
                  <b-form-radio class="mx-2" :value="100">100%</b-form-radio>
                </b-form-radio-group>
              </b-form-group>
            </b-dropdown-form>
          </template>
        </b-dropdown>
      </div>
      <b-popover target="adjust-design-call-info" placement="left" triggers="hover">
        <small>Adjust Design</small>
      </b-popover>
    </h2>
    
    <div class="list-area">
      <div v-if="callInfo" class="holder">
        
        <ul class="phone-list full-width-call-info-number list-unstyled">
          <li>
            <a :status="callInfo.status" :isLeft="callInfo.status === 'Dialed' ? 'Right' : 'Left'" class="d-flex align-items-start justify-content-between text-dark mb-0">
              
              <span class="ico text-white rounded-circle d-flex align-items-center justify-content-center">
                <template v-if="callInfo.call_type === 'audio'">
                  <vb-svg v-if="callInfo.status === 'Missed'" name="missedCall-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  <vb-svg v-else-if="callInfo.status === 'Received'" name="receivedCall-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  <vb-svg v-else name="dialedCall-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                </template>
                <template v-if="callInfo.call_type === 'video'">
                  <vb-svg v-if="callInfo.status === 'Missed'" name="missedVideo-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  <vb-svg v-else-if="callInfo.status === 'Received'" name="receivedVideo-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  <vb-svg v-else name="dialedVideo-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="red" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                </template>
                <template v-if="callInfo.call_type === 'voicemail'">
                  <vb-svg name="voiceMessage-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                </template>
                <template v-if="callInfo.call_type === 'sms'">
                  <vb-svg v-if="callInfo.status === 'Dialed'" name="sendMessage-icon" width="19" height="18" viewBox="0 0 19 18" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                  <vb-svg v-else name="receivedMessage-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                </template>
              </span>
              
              <span class="info-area px-2">
                
                <div class="call-date">
                  <span class="d-block">
                    <template v-if="callInfo.call_type === 'voicemail'">{{ callInfo.caller.replace(/"/g, "") }}</template>
                    <template v-if="callInfo.status=='Missed' || callInfo.status=='Received'">{{ `${callInfo.sourceName || ""}` }}</template>
                    <template v-else>{{ `${callInfo.destinationName || ""}` }}</template>
                  </span>
                  <div class="dialpad_activity_box_other">
                    <small class="duration">{{ callInfo.call_date | showTime }}</small>
                  </div>
                </div>
                
                <span class="small text-capitalize">
                  <template v-if="callInfo.call_type === 'audio' || callInfo.call_type === 'video'">
                    <span class="dialer-call-prefix">
                      <span v-if="callInfo.status=='Missed' || callInfo.status=='Received'" class="d-block">{{ `${callInfo.sourceExt || ""}` }}</span>
                      <span v-else class="d-block">{{ callInfo.destinationExt || "" }}</span>
                      <div class="dialpad_activity_box_other">
                        <small class="duration">{{ callInfo.duration }}</small>
                      </div>
                    </span>
                    <span v-if="callInfo.is_did=='1' && callInfo.call_type === 'audio' && (callInfo.status=='Missed' || callInfo.status=='Received') && callInfo.caller_extension_type && callInfo.caller_extension_detail" class="dialer-call-prefix">
                      <span class="d-block bg-primary " style="padding:2px 8px;border-radius:8px;color:white;width:auto;">
                        From: {{ typeof callInfo.caller_extension_detail == 'string' ? callInfo.caller_extension_detail : callInfo.caller_extension_detail.accountname || callInfo.caller_extension_detail.user_detail_limit.display_name }}
                      </span>
                    </span>
                    <span v-if="callInfo.is_did=='1' && callInfo.call_type === 'audio' && callInfo.status=='Dailed' && callInfo.callee_extension_type && callInfo.callee_extension_detail" class="dialer-call-prefix">
                      <span class="d-block bg-primary " style="padding:2px 8px;border-radius:8px;color:white;width:auto;">
                        To: {{ typeof callInfo.callee_extension_detail == 'string' ? callInfo.callee_extension_detail : callInfo.callee_extension_detail.accountname || callInfo.callee_extension_detail.user_detail_limit.display_name }}
                      </span>
                    </span>
                  </template>
                </span>

              </span>
            </a>
          </li>
        </ul>
        
        <div class="allow_scroll" style="overflow-y: auto;height: calc(100vh - 182px - 16px - 16px - 16px); padding-right: 16px;">
          
          <div class="dialer-callInfo-actions">
            
            <div v-if="callInfo.is_did=='1'" @click="block()" class="dialer-callInfo-action block-action">
              <vb-svg name="dialer-block-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              <span class="dialer-action-name"> Block </span>
            </div>
            
            <div v-if="callInfo.is_did=='1'" @click="sms(callInfo)" class="dialer-callInfo-action sendSms-action">
              <vb-svg name="dialer-sms-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              <span class="dialer-action-name"> SMS </span>
            </div>
            
            <div v-if="!callInfo.is_did=='1'" @click="$modal.show('ChatUserModal', { user: user });" class="dialer-callInfo-action sendSms-action">
              <vb-icon icon="dialer-chatInBox-icon" width="34" height="34" />
              <span class="dialer-action-name">Chat</span>
            </div>
            
            <div class="dialer-callInfo-action call-action" @click="calling(callInfo, callInfo.is_jitsi ? 'video' : 'audio')">
              <vb-svg name="dialer-callinfo-call-icon" width="34" height="34" viewBox="0 0 34 34" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" />
              <span class="dialer-action-name"> Call </span>
            </div>
          
          </div>
          
          <ul class="phone-list full-width-call-info-number dialor-callHistoryList  list-unstyled">
            
            <li v-if="callInfo.call_type === 'voicemail' || callInfo.call_type === 'sms' || (callInfo.call_type === 'audio' && callInfo.soundFile && callInfo.status !== 'Missed')">
              <a :status="callInfo.status" class="d-flex align-items-start justify-content-between text-dark">
                <span class="info-area">
                  
                  <div class="call-date" v-if="callInfo.call_type === 'voicemail' || callInfo.call_type === 'sms' || (callInfo.call_type === 'audio' && callInfo.soundFile && callInfo.status !== 'Missed')">
                    <span class="d-block">
                      <template v-if="callInfo.call_type === 'voicemail' || callInfo.call_type === 'sms'">Message</template>
                      <template v-else> Call Recording </template>
                    </span>
                    <div class="dialpad_activity_box_other">
                      <span class="dialer-dropdown dialpad_activity_box_menu">
                        <b-dropdown varient="link" no-caret class="ico-filter">
                          <template v-slot:button-content>
                            <b-icon icon="three-dots" scale="1" />
                          </template>
                          <b-dropdown-item v-if="callInfo.body" @click="copyText(callInfo.body)">
                            <vb-svg name="dialer-outlineCall-icon2" width="16.501" height="16.501" viewBox="0 0 16.501 16.501" stroke-width="0" stroke="#005DFF" fill="" stroke-linecap="round" stroke-linejoin="round" />
                            <span>Copy Text</span>
                          </b-dropdown-item>
                          <b-dropdown-item v-if="callInfo.soundFile || callInfo.voice" @click="download()">
                            <b-icon icon="download" ></b-icon>
                            <span>Download</span>
                          </b-dropdown-item>
                        </b-dropdown>
                      </span>
                    </div>
                  </div>
                  
                  <span class="small text-capitalize" v-if="callInfo.call_type === 'voicemail' || callInfo.call_type === 'sms' || (callInfo.call_type === 'audio' && callInfo.soundFile && callInfo.status !== 'Missed')">
                    <template v-if="callInfo.call_type === 'voicemail'">
                      <div class="dialer-audio-playback">
                        <vb-audio-player :src="callInfo.voice | base64_to_url" />
                      </div>
                    </template>
                    <template v-else-if="callInfo.call_type === 'sms'">
                      <span class="dialer-call-prefix">{{ callInfo.body || "" }}</span>
                    </template>
                    <template v-else-if="callInfo.call_type === 'audio' && callInfo.soundFile && callInfo.status !== 'Missed'">
                      <div class="dialer-audio-playback">
                        <vb-audio-player :src="callInfo.soundFile" />
                      </div>
                    </template>
                  </span>
                
                </span>
              </a>
            </li>
            
            <div class="" >
              
              <li v-if="!isEmpty(tags)">
                <div class="tags">
                  <span class="info-area w-100">
                    <div class="call-date">
                      <span class="d-block">Call Tags</span>
                    </div>
                    <span class="small text-capitalize sm-mar-top">
                      <span v-for="tag in tags" :key="tag.id" class="dialer-call-prefix tag">
                        <small v-vb-tag-color="tag.colour">{{ tag.tag }}</small>
                      </span>
                    </span>
                  </span>
                </div>
              </li>
              
              <li v-if="note">
                <div class="note">
                  <span class="info-area w-100">
                    <div class="call-date">
                      <span class="d-block">Call Note</span>
                    </div>
                    <span class="small text-capitalize">
                      {{ note }}
                    </span>
                  </span>
                </div>
              </li>
              
              <li>
                <span class="info-area">
                  <div class="call-date">
                    <span class="d-block">History</span>
                  </div>
                </span>
              </li>
              <vb-no-record v-if="isEmpty(data.history)" :text="api_sent.history ? '' : 'Sorry, There is no History'" :design="3">
                <vb-loading v-if="api_sent.history" slot="loading" />
              </vb-no-record>
              <Item v-for="(call_history, index) in data.history" :key="index" :call="call_history" :type="'history'" />
              
            </div>
          </ul>

        </div>
        
      </div>
    </div>
  </div>
</template>

<script>
import { showTime } from '@/filter'
// import { LOGGER, events, $fn, VOIP_API } from "../../../utils";
import { LOGGER, events, VOIP_API } from "../../../utils";
import Item from './Item.vue'
import { mapGetters } from 'vuex';
export default {
  name: "CallInfo",
  components: {
    Item,
  },
  props: {
    callInfo: {
      type: Object,
    },
    setting: {
      type: Object,
      default: ()=>({
        call_info_width: 25,
      })
    },
  },
  data() {
    return {
      api_sent: {
        text_copy: false,
        history: false,
      },
      data: {
        history: [],
      },
    };
  },
  inject:['isEmpty','appNotify'],
  computed: {
    ...mapGetters([
      'getVoipCallTags',
    ]),
    note() {
      if (!this.callInfo.notes) return "";
      const [note] = this.callInfo.notes.filter((item) => item.log !== null && item.type === "note");
      return note ? note.log : "";
    },
    tags() {
      const tags = this.getVoipCallTags
      const tag_ids = this.tagsId
      return tags.filter(item=>tag_ids.includes(item.real_id))
    },
    tagsId() {
      if (!this.call.notes) return [];
      return this.call.notes.filter((item) => item.type === "tag" && !!item.tag).map(tag=>tag.tag.id);
    },
    user() {
      if (this.callInfo.dialable) {
        return this.$store.getters.getVoipUsersAlises[this.callInfo.dialable];
      } else {
        return {};
      }
    },
  },
  watch: {
    callInfo(value){
      if(value){
        this.fetchHistory()
      }
    },
  },
  filters: {
    showTime,
  },
  methods: {
    block(){
      let vm = this
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to block this number`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.appNotify({
              message: 'This feature is in process',
              type: 'info',
            })
          }
        },
      });
    },
    download() {
      let vm = this
      let url = ''
      if(this.callInfo.voice){
        const base64 = window.atob(vm.callInfo.voice);
        const arrayBuffer = new ArrayBuffer(base64.length);
        const typedArray = new Uint8Array(arrayBuffer);

        for (let i = 0; i < base64.length; i++) {
          typedArray[i] = base64.charCodeAt(i);
        }

        url = window.URL.createObjectURL(
          new Blob([arrayBuffer], {
            type: "audio/mp3",
          })
        );
      } else {
        url = vm.callInfo.soundFile
      }
      LOGGER.log('url',url)
      fetch(url)
      .then(response => response.blob())
      .then(blob => {
        const link = document.createElement("a");
        link.href = URL.createObjectURL(blob);
        link.download = 'download';
        link.click();
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      });
    },
    calling(call, call_type) {
      const { dialable, is_jitsi } = call;
      if (is_jitsi) {
        const accounts = typeof dialable === "string" ? [dialable] : dialable;
        this.$root.$emit(events.video_call, { accounts, call_type });
      } else {
        this.$root.$emit(events.audio_call, { number: dialable });
      }
    },
    copyText() {
      let vm = this
      const value = vm.callInfo.body || "";
      if (vm.api_sent.text_copy) return;
      vm.api_sent.text_copy = true;
      navigator.clipboard.writeText(value)
      .then(() => {
        vm.appNotify({
          message: "Copied Text",
          type: "success",
        })
      })
      .finally(() => {
        vm.api_sent.text_copy = false;
      });
    },
    fetchHistory() {
      let vm = this
      vm.api_sent.history = true;
      vm.data.history = []
      VOIP_API.endpoints.call_activity.callinfo({
        accountcode: vm.$store.getters.getCurrentUser?.account,
        number: vm.callInfo.dialable,
      })
      .then(({ data: { data: history } }) => {
        vm.data.history = history;
      })
      .finally(() => {
        vm.api_sent.history = false;
      });
    },
    sms(call) {
      this.$root.$emit(events.send_sms, { dialable: call.dialable });
      this.$emit("close")
    },
  },
  mounted() {
    this.fetchHistory();
  },
};
</script>

<style>
</style>