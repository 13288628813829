<template>
  <div>
    <modal
      class="dialer_animation right_side_popup"
      width="50%"
      height="auto"
      name="CreateReportModal"
    >
      <div class="dialer-edit">
        <form @submit.prevent="''" class="dialer-form">
          <div class="dialer-edit-header">
            <h2 class="dialer-edit-title">Create a report</h2>
            <div class="dialer-edit-actions">
              <button class="dialer-button dialer-button-delete" @click="$modal.hide('CreateReportModal')" type="button">Cancel</button>
              <button class="dialer-button dialer-button-primary disabled" disabled>Create</button>
            </div>
          </div>
          <div class="dialer-box table">
            <div class="dialer-box-segment">
              <div class="dialer-reports-new">
                <div class="dialer-input-field dialer-min-height">
                  <label class="dialer-input-label">Report name (optional)</label>
                  <div>
                    <input class="touched" label="Report name (optional)" value />
                  </div>
                </div>
                <div class="dialer-input-field dialer-select-field">
                  <label class="dialer-input-label">Report type</label>
                  <b-form-select
                    class="dialer-select"
                    v-model="selected_report"
                    :options="options"
                  ></b-form-select>
                </div>
              </div>
            </div>
          </div>
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: "CreateReportModal",
  data() {
    return {
      selected_report: null,
      options: [
        { value: null, text: "SMS" },
        { value: "calls", text: "Calls" },
      ],
    };
  },
};
</script>
