<template>
  <div>
    <div :class="`chatMe ${message.user_id==userId? 'right':'left'} `" v-if="false">
      <div class="chatMe-inner">
        <div class="msgInfo-container">
          <div class="sm-text">{{ message.user.first_name }} {{ message.user.last_name }}</div>
        </div>
        <div class="msg-container">
          <div class="msg-itself ">{{ message.details }}</div>
        </div>
        <div class="msgInfo-container">
          <div class="sm-text">{{ message.created_at | filter_date_current('',{ formate: 'hh:mm a' }) }}</div>
        </div>
      </div>
    </div>
    <div :class="`ticketConversation-item ${message.user_id==userId? 'sender':'receiver'}`">
      <div class="ticketConversation-item-top">
        <div class="ticketConversation-item-top-left">
          {{ message.user.first_name }} {{ message.user.last_name }}
        </div>
        <div class="ticketConversation-item-top-right">
          <b-icon icon="clock" ></b-icon>
          At {{ message.created_at | filter_date_current('',{ formate: 'hh:mm a , DD MMM YYYY' }) }}
        </div>
      </div>
      <div class="ticketConversation-item-bottom">
        <p class="mb-0">
          {{ message.details }}
        </p>
      </div>
    </div>
  </div>
</template>

<script>
// import moment from 'moment'
export default {
  name: 'TicketCommentMessage',
  props: {
    message: {
      type: Object,
      default: ()=>({}),
    },
    prevMessage: {
      type: Object,
      default: ()=>({}),
    },
    nextMessage: {
      type: Object,
      default: ()=>({}),
    },
    userId: {
      type: [String,Number],
      default: '',
    },
  },
  computed: {
    // sameTimeAndUserAsPrevMessage() {
    //   if(this.prevMessage.user_id) {
    //     if(this.prevMessage.user_id==this.message.user_id) {
    //       if(moment(this.prevMessage.created_at).format('YYYY MM DD hh:mm a')==moment(this.message.created_at).format('YYYY MM DD hh:mm a')) {
    //         return true
    //       }
    //     }
    //   }
    //   return false 
    // },
    // sameTimeAndUserAsNextMessage() {
    //   if(this.nextMessage.user_id) {
    //     if(this.nextMessage.user_id==this.message.user_id) {
    //       if(moment(this.nextMessage.created_at).format('YYYY MM DD hh:mm a')==moment(this.message.created_at).format('YYYY MM DD hh:mm a')) {
    //         return true
    //       }
    //     }
    //   }
    //   return false 
    // }
  },
}
</script>

<style>

</style>