import { VOIP_API } from "../../index"
import qs from 'qs';


export const ACTIVITIES_CRM_API_ENDPOINTS = {
  create(data={}){
    return VOIP_API.axios.crm.request({
      url: 'activities',
      method: 'POST',
      data,
    })
  },
  list(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: 'activities',
      method: 'GET',
      params,
      cancelToken,
    })
  },
  table(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: 'activities/table',
      method: 'GET',
      params,
      paramsSerializer: (params) => {
        return qs.stringify(params, { encode: true });
      },
      cancelToken,
    })
  },
  action(action='',data={}){
    return VOIP_API.axios.crm.request({
      url: `activities/actions/${action}/run`,
      method: 'POST',
      data,
    })
  },
  remove(activity_id='',data={}){
    return VOIP_API.axios.crm.request({
      url: `activities/${activity_id}`,
      method: 'DELETE',
      data,
    })
  },
  export(data={}){
    return VOIP_API.axios.crm.request({
      url: `activities/export`,
      method: 'POST',
      data,
      responseType: 'blob', 
    })
  },
  tableSettings(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: 'activities/table/settings',
      method: 'GET',
      params,
      cancelToken,
    })
  },
}