var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Time off Reports "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('GenerateTimeOffReportsModal');
      }
    }
  }, [_vm._v(" Genrate ")])]), _vm._m(0), _c('div', {
    staticClass: "TimeOffReportsTable mt-32px"
  }, [_vm._m(1), _c('vb-table', {
    staticClass: "HrmTable",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filterTimeOffReports),
      "listLength": _vm.filterTimeOffReports.length,
      "loading": _vm.api.time_off_reports.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterTimeOffReports, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.user,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "nameWithImage"
          }, [_c('div', {
            staticClass: "imageContainer"
          }, [_c('img', {
            attrs: {
              "src": require('@/assets/images/credit-card/1.jpeg')
            }
          })]), _c('div', {
            staticClass: "nameWithImage-text"
          }, [_vm._v(_vm._s(data.name))])])]), _c('td', {
            staticClass: "dialer-row-title two"
          }, [_vm._v(_vm._s(data.employee))]), _c('td', {
            staticClass: "dialer-row-title three"
          }, [_vm._v(_vm._s(data.user))]), _c('td', {
            staticClass: "dialer-row-title four"
          }, [_c('div', {
            staticClass: "circleWithText"
          }, [_c('div', {
            staticClass: "circleContainer",
            staticStyle: {
              "background-color": "#D5761B"
            }
          }), _c('div', {
            staticClass: "circleWithText-text",
            staticStyle: {
              "color": "#D5761B"
            }
          }, [_vm._v(_vm._s(data.policy))])])]), _c('td', {
            staticClass: "dialer-row-title five"
          }, [_vm._v(_vm._s(data.startDate))]), _c('td', {
            staticClass: "dialer-row-title six"
          }, [_vm._v(_vm._s(data.endDate))]), _c('td', {
            staticClass: "dialer-row-title seven"
          }, [_vm._v(_vm._s(data.hoursUsed))]), _c('td', {
            staticClass: "dialer-row-title eight"
          }, [_c('b-dropdown', {
            staticClass: "ActionsDD",
            attrs: {
              "no-caret": "",
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('div', {
                  staticClass: "button-content"
                }, [_c('b-icon', {
                  staticClass: "downIcon",
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v(" " + _vm._s(data.actions) + " ")])], 1)], 1)]);
        });
      }
    }])
  })], 1), _c('GenerateTimeOffReportsModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("employee #")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("user #")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("policy")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Start date")]), _c('div', {
    staticClass: "HrmTableHead six"
  }, [_vm._v("end date")]), _c('div', {
    staticClass: "HrmTableHead seven"
  }, [_vm._v("Hours used")]), _c('div', {
    staticClass: "HrmTableHead eight"
  }, [_vm._v("Actions")])]);

}]

export { render, staticRenderFns }