<template>
  <div>
    <modal 
      height="auto" 
      :name="modalName" 
      class="center-zoomIn-transition v2 m-w-500 addUserModalV2" 
      :scrollable="true" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-user">
        <div class="dialer-edit">
          <div class="dialer-form"> 
            <div class="dialer-edit-header pt-0 pb-0 d-block">
              <div class="d-flex justify-content-between align-items-center">
                <h2 class="dialer-edit-title my-0">Add user </h2>
                <div class="dialer-edit-actions">
                  <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
                  <a v-else class="newCloseButton" @click="$modal.hide(modalName)">
                    <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                    <span class="newCloseButton-textPart">Close</span>
                  </a>
                </div>
              </div>
              <div class="vm--modal-text">How would you like to add your new user?</div>
            </div>
            <div class="addUserBox-container">
              <div class="addUserBox" @click="$modal.show('AddUserByEmail')">
                <vb-icon icon="InviteUser-icon" />
                <h2>Invite user</h2>
                <p>Add a new user by inviting them through their email address</p>
              </div>
              <div class="addUserBox" @click="$modal.show('AddUserByUserName')">
                <vb-icon icon="AddUser-icon" class="AddUser-icon" />
                <h2>Add user</h2>
                <p>Add a new user by creating a unique username with your organization name</p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
    <AddUserByEmail @user-added="$emit('user-added');$modal.hide(modalName)" />
    <AddUserByUserName @user-added="$emit('user-added');$modal.hide(modalName)" />
  </div>
</template>

<script>

import AddUserByEmail from "./AddUserByEmail.vue";
import AddUserByUserName from "./AddUserByUserName.vue";

import { mapGetters } from 'vuex';

export default {
  name: "AddUserANDSubUserModal",
  props: {
    modalName: {
      type: String,
      default: 'AddUserANDSubUserModal',
    },
  },
  components: {
    AddUserByEmail,
    AddUserByUserName
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
  },
  methods: {
    onBeforeOpen(){
      // TODO - 
    },
    onBeforeClose(){
      // TODO - 
    },
  },
};
</script>


<style>
.slide-ltr-enter-active {
  animation: slide-ltr 0.5s reverse;
}
.slide-ltr-leave-active {
  animation: slide-ltr 0.5s;
}
@keyframes slide-ltr {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>