<template>
  <modal class="dialer_animation right_side_popup FeedbackModal m-w-500 min-width-50percent-modal" width="500px" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose" @closed="onClosed">
    <section class="ct-user-new dialer-edit">
      <div class="dialer-edit-header">
        <div>
          <h2 class="dialer-edit-title newer">Feedback</h2>
        </div>
        <div class="dialer-edit-actions">
          <button :disabled="api.feedback.send" class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        
        <b-alert :show="!!api.feedback.error_message" variant="danger">
          {{ api.feedback.error_message }}
        </b-alert>
        
        <div class="latestGreyBox-heading-main">We won't reply to your message.</div>
        <div class="latestGreyBox-descriptionText">Don't include any personal or financial information.</div>
        <div class="whiteBGinputWithGreyRoundedBorder mt-8px">
          <textarea :disabled="api.feedback.send" class="w-100 feedback-textarea" v-model="forms.feedback.value" maxlength="500"></textarea>
        </div>
        <template v-if="forms.feedback.submitted">
          <p v-if="$v.forms.feedback.value.$invalid" class="mb-1 text-small text-danger animated bounceIntop">
            <span v-if="!$v.forms.feedback.value.required">* Feedback is required</span>
            <span v-else-if="!$v.forms.feedback.value.maxLength">* Feedback can be maximum 500 character</span>
          </p>
        </template>
        <template v-if="api.feedback.error.errors">
          <p v-if="api.feedback.error.errors.feedback" class="mb-1 text-small text-danger animated bounceIntop">
            <span v-for="(error_message, index) in api.feedback.error.errors.feedback" :key="index">* {{ error_message }}</span>
          </p>
        </template>
        
        <div class="latestGreyBox-descriptionText">{{ 500 - forms.feedback.value.length }} characters remaining</div>
        <div class="d-flex justify-content-start align-items-center mb-3">
          <b-icon icon="caret-right-fill" variant="black"/>
          <p class="ml-2 mb-0 text-underline latestGreyBox-descriptionText">I need help with my application</p>
        </div>
      </div>
      <div class="d-flex justify-content-end mt-20px">
        <button :disabled="api.feedback.send" @click="sendfeedback()" class="newDoneButton">
          <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
          <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
            <vb-spinner v-if="api.feedback.send" />
            <template v-else>Submit feedback</template>
          </span>
        </button>
      </div>
    </section>
  </modal>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { VOIP_API } from '../../utils';

export default {
  name: 'FeedbackModal',
  props: {
    modalName: {
      type: String,
      default: 'FeedbackModal',
    },
  },
  data(){
    return {
      forms: {
        feedback: {
          value: '',
          reset(){
            this.value = ''
            this.is_submitted = false
          },
          is_submitted: false,
        },
      },
      api: {
        feedback: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.error_message = ''
          },
        },
      },
    }
  },
  validations: {
    forms: {
      feedback: {
        value: {
          required,
          maxLength: maxLength(500),
        },
      },
    },
  },
  inject: [
    'appNotify',
  ],
  methods: {
    onBeforeOpen(){
      //TODO
    },
    onBeforeClose(){
      this.forms.feedback.reset()
      this.api.feedback.reset()
    },
    onClosed(){
      this.$emit('closed')
    },
    sendfeedback(){
      let vm = this
      vm.forms.feedback.is_submitted=true
      vm.$v.forms.feedback.$touch()
      if(vm.api.feedback.send || vm.$v.forms.feedback.$invalid) return;
      vm.api.feedback.error={}
      vm.api.feedback.error_message=''
      vm.api.feedback.send=true
      VOIP_API.endpoints.feed_back.customerfeedback({
        account: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
        feedback: vm.forms.feedback.value,
      }).then(()=>{
        vm.$modal.hide(vm.modalName)
        vm.$emit('submitted')
        vm.appNotify({
          message: 'Successfully Send Feedback',
          type: 'success',
        })
      }).catch((ex)=>{
        vm.api.feedback.error_message=ex.own_message
        vm.api.feedback.error=ex.response_error || {}
      }).finally(()=>{
        vm.api.feedback.send=false
        vm.forms.feedback.is_submitted=false
      })
    },
  },
}
</script>

<style>

</style>