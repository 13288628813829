<template>
  <div class="w-100 mt-20px">
    <div class="latestGreyBox-heading-main">Menu option</div>
    <div class="latestGreyBox-descriptionText">Streamline caller experience by customizing menus for efficient call handling</div>
    <div class="whiteBoxWithBorderInsideGreyBox flex-column withAdialerInside mt-20px">
      <div class="whiteBoxWithBorderInsideGreyBox-row">
        <div class="latestGreyBox-9-9-2023 d-flex justify-content-between align-items-center w-100">
          <div class="dialpadInsideEditIVRmodal">
            <template v-for="key in keypad">
              <vb-icon 
                :key="key.value"
                :icon="key.icon"
                :class="`${selectedIVROptions[key.value]?'active':''}`" 
                @mouseover="$refs[`${key.value}-key-option`] ? $refs[`${key.value}-key-option`][0].classList.add('heighLightThisRow') : ''"
                @mouseleave="$refs[`${key.value}-key-option`] ? $refs[`${key.value}-key-option`][0].classList.remove('heighLightThisRow') : ''"
                @click="api.update_extension.send || api.reset_extension.send ? '' : $modal.show(`${_uid}-MenuOptionExtensionAssignModal`, {
                  account: getProperty(selectedIVROptions[key.value],'ivr_cmd_args'),
                  type: 'setting',
                  dtmf: key.value,
                  schedule_id: schedule.id,
                  accountcode: accountcode,
                  ringGroupMembers: getProperty(selectedIVROptions[key.value],'ringGroupMembers') || [],
                  ringgroup_id: getProperty(selectedIVROptions[key.value],'ringgroup_id') || '',
                  user_mode: getProperty(selectedIVROptions[key.value],'user_mode') || 0,
                })"
                :id="`id-${key.value}`"
              />
              <b-popover :key="`${key.value}-popover`" :target="`id-${key.value}`" placement="top" triggers="hover focus">
                <template v-if="selectedIVROptions[key.value]">
                  <div v-if="selectedIVROptions[key.value].ivr_cmd_args==selectedIVROptions[key.value].ringgroup_id">
                    {{selectedIVROptions[key.value] | get_property('ringGroupMembers.length',0)}} users
                  </div>
                  <Info 
                    v-else
                    :is_blf="false" 
                    :id="selectedIVROptions[key.value][0]" 
                    :type_show="true" 
                    :name="selectedIVROptions[key.value].accountname" 
                    :extension_type="selectedIVROptions[key.value].accounttype" 
                  />
                </template>
                <template v-else>Add tag</template>
              </b-popover>
            </template>
          </div>
          <div class="newIVRLayout-advanceOptionsTable">
            <vb-table 
              class="latestTableDesign-withBlackBorders-again mt-0"
              :isListEmpty="Object.keys(selectedIVROptions).length==0 && !conditions.add_new_menu" 
              textNoRecord="No option selected"
              :listLength="selectedIVROptions.length" 
              :conditions="{
                pagination: {
                  show: false,
                }
              }"
            >
              <tr slot="header" class="w-100">
                <th class="border-0"> 
                  <span class="">MENU OPTION</span>
                </th> 
                <th class="border-0 ">
                  <span class="">ASSIGNED TO</span>
                </th>
                <th class="border-0 ">
                  <span class=""><br/> </span>
                </th>
              </tr>
              <template #body>
                <tr v-for="option in selectedIVROptions" :key="option.id" :ref="`${option.user_dtmf}-key-option`">
                  <td class=" border-0 ">
                    <div class="d-flex align-items-center">
                      <span class="user_dtmf-iconType-before">Press</span>
                      <span class="user_dtmf-iconType">{{option.user_dtmf}}</span>
                      <span class="user_dtmf-iconType-after">for</span>
                    </div>
                  </td>
                  <td class="border-0">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <vb-icon class="extentionType-icon" :icon="option.accounttype | filterExtentionTypeIcon" />
                        <div v-if="option.ivr_cmd_args==option.ringgroup_id">
                          {{option.ringGroupMembers.length}} users
                        </div>
                        <template v-else>{{ option.accountname }}</template>
                      </div>
                      <span :class="`accounttype-tag ${option.accounttype}`">{{ option.accounttype }}</span>
                    </div>
                  </td>
                  <td class=" border-0 ">
                    <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD buttonOnRight buttonHeightAuto">
                      <b-dropdown right :disabled="api.update_extension.send || api.reset_extension.send">
                        <template #button-content>
                          <b-icon icon="three-dots-vertical" scale="1.5"></b-icon>
                        </template>
                        <b-dropdown-item
                          @click="
                            $modal.show(`${_uid}-MenuOptionExtensionAssignModal`, {
                              account: option.ivr_cmd_args,
                              type: 'setting',
                              dtmf: option.user_dtmf,
                              schedule_id: schedule.id,
                              accountcode: accountcode,
                              ringGroupMembers: option.ringGroupMembers || [],
                              ringgroup_id: option.ringgroup_id || '',
                              user_mode: option.user_mode || 0,
                            })
                          "
                        >
                          Edit
                        </b-dropdown-item>
                        <b-dropdown-item
                          v-if="option.ringgroup_id"
                          @click="
                            $modal.show(`${_uid}-IVRMenuRingGroupSettingModal`, {
                              account: option.ivr_cmd_args,
                              type: 'setting',
                              dtmf: option.user_dtmf,
                              schedule_id: schedule.id,
                              accountcode: accountcode,
                              ringGroupMembers: option.ringGroupMembers || [],
                              ringgroup_id: option.ringgroup_id || '',
                              user_mode: option.user_mode || 0,
                            })
                          "
                        >
                          Config
                        </b-dropdown-item>
                        <b-dropdown-item @click="resetIvrOption(option)">Delete</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </td>
                </tr>
                <tr v-show="conditions.add_new_menu">
                  <td colspan="3">
                    <div class="d-flex align-items-center justify-content-between">
                      <div class="d-flex align-items-center">
                        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion withGreyButton">
                          <vb-select
                            :disabled="api.update_extension.send || api.reset_extension.send"
                            :selected="selected.option"
                            @change="selected.option = $event"
                            class="right-0"
                            :defaultSelectedText="'Select a button'"
                            :options="avaliableOptions"
                          >
                          </vb-select>
                        </div>
                        <div :disabled="!selected.option" class="addnewMenuOptions-Text ml-32px"
                          @click="
                            api.update_extension.send || api.reset_extension.send ? '' : $modal.show(`${_uid}-MenuOptionExtensionAssignModal`, {
                              account: '',
                              type: 'setting',
                              dtmf: selected.option,
                              schedule_id: schedule.id,
                              accountcode: accountcode,
                              ringGroupMembers: [],
                              ringgroup_id: '',
                              user_mode: 0,
                            })
                          " 
                        >
                          Select user, team, queue or IVR
                        </div>
                      </div>
                      <vb-icon 
                        icon="circularCross-icon" 
                        class="circularCross-icon" 
                        @click="api.update_extension.send || api.reset_extension.send ? '' : conditions.add_new_menu=false" 
                        width="29.25px" 
                        height="29.25px"
                      />
                    </div>
                  </td>
                </tr>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td class="dialer-col-right">
                    <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
                  </td>
                </tr>
              </template>
            </vb-table>
            <div 
              v-if="!conditions.add_new_menu" 
              @click="api.update_extension.send || api.reset_extension.send ? '' : conditions.add_new_menu=true" 
              class="addnewMenuOptions-Text"
            >
              Add new menu option
            </div>
          </div>
        </div>
      </div>
    </div>
    <MenuOptionExtensionAssignModal :modalName="`${_uid}-MenuOptionExtensionAssignModal`" @updated="$emit(`updated`,{ menu: $event.menu })" />
    <IVRMenuRingGroupSettingModal :modalName="`${_uid}-IVRMenuRingGroupSettingModal`" @updated="$emit(`updated`,{ menu: $event.menu })" />
  </div>
</template>

<script>
import _ from 'lodash';
import { VOIP_API } from '@/utils';
import MenuOptionExtensionAssignModal from "../../Modals/MenuOptionExtensionAssignModal.vue";
import IVRMenuRingGroupSettingModal from "../../Modals/IVRMenuRingGroupSetting.vue";
import Info from '@/components/Lists/Info.vue';
const keypad = [
  {
    icon: 'insideEditIVRmodal-dial-1',
    value: '1'
  },
  {
    icon: 'insideEditIVRmodal-dial-2',
    value: '2'
  },
  {
    icon: 'insideEditIVRmodal-dial-3',
    value: '3'
  },
  {
    icon: 'insideEditIVRmodal-dial-4',
    value: '4'
  },
  {
    icon: 'insideEditIVRmodal-dial-5',
    value: '5'
  },
  {
    icon: 'insideEditIVRmodal-dial-6',
    value: '6'
  },
  {
    icon: 'insideEditIVRmodal-dial-7',
    value: '7'
  },
  {
    icon: 'insideEditIVRmodal-dial-8',
    value: '8'
  },
  {
    icon: 'insideEditIVRmodal-dial-9',
    value: '9'
  },
  {
    icon: 'insideEditIVRmodal-dial-steric',
    value: '*'
  },
  {
    icon: 'insideEditIVRmodal-dial-0',
    value: '0'
  },
]
export default {
  name: 'MenuOptionsWidget',
  components: {
    MenuOptionExtensionAssignModal,
    IVRMenuRingGroupSettingModal,
    Info,
  },
  inject: [
    'getProperty',
    'appNotify'
  ],
  props: {
    menu: {
      type: Object,
      default: ()=>({}),
    },
  },
  data() {
    return {
      api: {
        update_extension: this.$helperFunction.apiInstance(),
        reset_extension: this.$helperFunction.apiInstance(),
      },
      conditions: {
        add_new_menu: false,
      },
      selected: {
        option: '',
      },
    }
  },
  computed: {
    keypad(){ return keypad },
    accountcode(){ return this.menu?.voipaccount ?? '' },
    schedule(){ return this.menu?.schedules?.[0] ?? null },
    avaliableOptions(){ return Object.values(this.schedule?.avaliableOptions ?? {})?.map?.(i=>({ text: `Press ${i}`, value: i, })) ?? [] },
    selectedIVROptions(){ return _.keyBy(this.schedule?.options ?? [], 'user_dtmf'); },
  },
  filters: {
    filterExtentionTypeIcon(value){
      if(value=='USER') return 'insideEditIVRmodal-userIcon'
      else if(value == 'TEAM') return 'insideEditIVRmodal-temsIcon'
      else if(value == 'number') return 'insideEditIVRmodal-callIcon'
      else return 'insideEditIVRmodal-temsIcon'
    },
  },
  methods: {
    async extensionUpdated(event) {
      if(this.api.update_extension.send) return;
      const toaster = this.appNotify({
        message: "Saving...",
        type: "info",
      });
      try {
        this.api.update_extension.send=true
        const { data } = await VOIP_API.endpoints.menu.addoption({
          account: event.account,
          id: this.accountcode,
          option: event.dtmf,
          schedule_id: this.schedule.id,
          schedule_no: 1,
          type: "extension",
          command: "callfwd",
          args: event.account,
        })
        this.selected.option=''
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        this.$emit("updated",{
          menu: data?.data
        });
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        toaster.close()
        this.api.update_extension.send=false
      }
    },
    async resetIvrOption(option) {
      if(this.api.reset_extension.send) return;
      let toaster;
      try {
        this.api.reset_extension.send=true
        const { confirm } = await this.$appConfirmation({
          title: "warning",
          message: `Are you sure you want to delete this`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        toaster = this.appNotify({
          message: "Saving...",
          type: "info",
        });
        const { data } = await VOIP_API.endpoints.menu.resetoption(this.accountcode, {
          option: option?.user_dtmf == 10 ? "*" : option?.user_dtmf,
          schedule: this.schedule.id,
        })
        this.appNotify({
          message: 'Successfully Removed',
          type: 'success',
        })
        this.$emit("updated",{
          menu: data?.data,
        });
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        toaster?.close?.()
        this.api.reset_extension.send=false
      }
    },
  },
}
</script>

<style>

</style>