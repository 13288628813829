
import { VOIP_API } from "../index"

export const LOCATION_API_ENDPOINTS = {
  list(params={}){
    return VOIP_API.axios.voip.request({
      url: `locations`,
      method: 'GET',
      params,
    })
  },
  listcountriesandextensions(){
    return VOIP_API.axios.voip.request({
      url: `location-caller-id`,
      method: 'GET',
    })
  },
  getAddress(postcode='',params={}){
    return VOIP_API.axios.address.request({
      url: `find/${postcode}`,
      params: {
        ...params,
      },
    })
  },
  getcountries(){
    return VOIP_API.axios.rest_countries.request({
      url: `v3.1/all`,
      method: 'GET',
    })
  },
  add(data={}){
    return VOIP_API.axios.voip.request({
      url: 'locations',
      method: 'POST',
      data,
    })
  },
  addEmergencyAddress(data={}){
    return VOIP_API.axios.voip.request({
      url: 'emergency/address',
      method: 'POST',
      data,
    })
  },
  getEmergencyAddress(params={}){
    return VOIP_API.axios.voip.request({
      url: 'emergency/address',
      method: 'GET',
      params,
    })
  },
  updateEmergencyLocation(data={}){
    return VOIP_API.axios.voip.request({
      url: 'extention/emergency/address',
      method: 'POST',
      data,
    })
  },
  getSingleEmergencyAddress(number='',params={}){
    return VOIP_API.axios.voip.request({
      url: `emergency/address/${number}`,
      method: 'GET',
      params,
    })
  },
  updateEmergencyAddressLabel(data={}){
    return VOIP_API.axios.voip.request({
      url: 'emergency/address/label',
      method: 'POST',
      data,
    })
  },
  edit(data={}){
    return VOIP_API.axios.voip.request({
      url: 'locations',
      method: 'PATCH',
      data,
    })
  },
  delete(data={}){
    return VOIP_API.axios.voip.request({
      url: 'locations',
      method: 'DELETE',
      data,
    })
  },
}