<template>
  <div class="dialer-settings-main">
    <template>
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$emit('back')">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
          <div class="settingsTextOnTop">Settings</div>
        </div>
        <div class="dialer-settings-title topMainHeading">Billing</div>
      </div>
    </template>
    <section class="dialer-settings-section dialer-account-setting">
      
      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div class="basicWhiteIOScard-item" @click="$modal.show('SubscriptionPlanDetail')">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-PackagePlan-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable">
                {{ response.subscription | get_property('userCurrentSubscription.plan_detail.plan_label') }}
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
        <div class="basicWhiteIOScard-item">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-CallsThisMonth-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable">
                Calls this month
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="textInsideCard textAlignRight onRightSide mr-6px">£0.00</div>
            
          </div>
        </div>
      </div>
      <div class="basicWhiteIOScard">
        <div class="basicWhiteIOScard-item" @click="$modal.show('UsersDetail')">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-AllUsers-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable">
                All Users
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="textInsideCard textAlignRight onRightSide mr-6px">
              {{ response.account | get_property('users_count',0) }}
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
        <div class="basicWhiteIOScard-item" @click="$modal.show('NumbersSetting')">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-AllNumbers-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable">
                All Numbers
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="textInsideCard textAlignRight onRightSide mr-6px">
              {{ response.account | get_property('numbers_count',0) }}
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
        <div class="basicWhiteIOScard-item">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-AccountBalance-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable">
                Account Balance
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="textInsideCard textAlignRight onRightSide mr-6px">
              £{{ accountBalance }}
            </div>
            
          </div>
        </div>
        <div class="basicWhiteIOScard-item" @click="$modal.show('Topup')">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-TopUpProducts-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable">
                Top up Products
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
        <div class="basicWhiteIOScard-item" @click="$modal.show('TopupSetting')">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-TopUpSettings-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable">
                Top up Settings
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
      </div>
      <div class="textAboveCard">My Invoice Details</div>
      <div class="basicWhiteIOScard">
        <div class="basicWhiteIOScard-item" @click="$modal.show('InvoiceSetting')">
          <div class="d-flex align-items-center">
            
            <div class="d-flex flex-column">
              <div class="headingInsideTable">
                {{ response.account | get_property('user_detail.firstname') }} {{ response.account | get_property('user_detail.lastname') }}
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="textInsideCard textAlignRight onRightSide mr-6px">
              {{ response.account | get_property('user_detail.address') }}
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
      </div>
      <div class="textAboveCard">Estimated Invoices</div>
      <div class="basicWhiteIOScard">
        <div class="basicWhiteIOScard-item" @click="$modal.show('Invoices')">
          <div class="d-flex align-items-center">
            
            <div class="d-flex flex-column">
              <div class="headingInsideTable">
                Jul 31 to Aug 31
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="textInsideCard textAlignRight onRightSide mr-6px">
              £{{ response.account_invoice | get_property('call_cost.cost',0) | to_fixed }}
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
      </div>
      <div class="textAboveCard">Payment Method</div>
      <div class="basicWhiteIOScard">
        <div class="basicWhiteIOScard-item" v-if="primaryCard" @click="$modal.show('SetCardsPriority')">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-MasterCard-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable d-flex">
                Mastercard 
                <div class="s1-c-container">
                  <div class="s1-c" v-for="i in 4" :key="i" /> 
                </div>
                {{ primaryCard | get_property('digits','0000') }}
              </div>
              <div class="textInsideCard primaryColor">
                Primary
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="textInsideCard textAlignRight onRightSide mr-6px">
              Expires {{ primaryCard | get_property('expiry_month','00') }}/{{ primaryCard | get_property('expiry_year','00') }}
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
        <div class="basicWhiteIOScard-item" v-else @click="$modal.show('SetCardsPriority')">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-CreditCard-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable d-flex">
                Add a Primary payment method
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
        <div class="basicWhiteIOScard-item" v-if="secondaryCard" @click="$modal.show('SetCardsPriority',{ is_secondary: true })">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-MasterCard-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable d-flex">
                Mastercard 
                <div class="s1-c-container">
                  <div class="s1-c" v-for="i in 4" :key="i" /> 
                </div>
                {{ secondaryCard | get_property('digits','0000') }}
              </div>
              <div class="textInsideCard primaryColor">
                Seconday
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="textInsideCard textAlignRight onRightSide mr-6px">
              Expires {{ secondaryCard | get_property('expiry_month','00') }}/{{ secondaryCard | get_property('expiry_year','00') }}
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
        <div class="basicWhiteIOScard-item" v-else @click="$modal.show('SetCardsPriority',{ is_secondary: true })">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" icon="Billing-CreditCard-IOSIcon"/>
            </div>
            <div class="d-flex flex-column">
              <div class="headingInsideTable d-flex">
                Add a Secondary payment method
              </div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
      </div>

















      

      <SubscriptionPlanDetailModal @update-plan="fetchAccountDetail();fetchSubscriptionDetail()" />
      <SetCardsPriorityModal @priority-set="fetchCards()" @cards-updated="fetchCards()" />
      <InvoicesModal />
      <InvoiceSettingModal @updated="fetchAccountDetail()" />
      <TopupSettingModal />
      <UsersDetailModal />
      <NumbersSettingModal />
      <TopupModal />
    </section>
  </div>
</template>

<script>

import UsersDetailModal from '../modals/users/UsersDetail.vue'
import NumbersSettingModal from '../modals/NumbersSetting.vue'
import TopupModal from '../modals/billing/Topup.vue'
import TopupSettingModal from '../modals/billing/TopupSetting.vue'
import InvoiceSettingModal from '../modals/billing/InvoiceSetting.vue'
import InvoicesModal from '../modals/billing/Invoices.vue'
import SetCardsPriorityModal from '../modals/billing/SetCardsPriority.vue'
import SubscriptionPlanDetailModal from '../modals/billing/SubscriptionPlanDetail.vue'

import { VOIP_API, } from "@/utils";
// import { centrifugo } from '@/Centrifuge';

import { mapGetters } from 'vuex';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami'

export default {
  name: "Billing",
  inject:['isEmpty'],
  components: {
    UsersDetailModal,
    NumbersSettingModal,
    TopupModal,
    TopupSettingModal,
    InvoiceSettingModal,
    InvoicesModal,
    SetCardsPriorityModal,
    SubscriptionPlanDetailModal,
  },
  data() {
    return {
      api_sent: {
        account: false,
        account_invoice: false,
        cards: false,
        subscription: false,
      },
      response: {
        account: {},
        account_invoice: {},
        cards: [],
        subscription: {},
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile'
    ]),
    creditLogs(){ return this.response.account_invoice?.credit_logs?.credits?.credit ?? [] },
    primaryCard(){ return this.response?.cards?.find?.(item=>item.primary=='yes') },
    secondaryCard(){ return this.response?.cards?.find?.(item=>item.secondary=='yes') },
    filterInvoices(){ return this.response?.account_invoice?.invoices?.invoices?.invoice?.filter?.(item=>item.status=='Unpaid' && item.total>0) ?? [] },
    accountBalance(){ return parseFloat(parseFloat(this.response?.account_invoice?.user_balance) + parseFloat(this.response?.account_invoice?.voip_balance?.accountState)) || 0 },
  },
  methods: {
    fetchCards(){
      let vm = this;
      this.api_sent.cards = true;
      VOIP_API.endpoints.billing_api.getcreditcards({ 
        uid: vm.getCurrentUser?.uid 
      })
      .then(({ data: { data: cards } }) => {
        vm.response.cards = cards;
      })
      .finally(() => {
        vm.api_sent.cards = false;
      });
    },
    fetchAccountDetail(){
      let vm = this;
      this.api_sent.account = true;
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data }) => {
        vm.response.account = data
      })
      .finally(() => {
        vm.api_sent.account = false
      });
    },
    fetchAccountInvoices(){
      let vm = this;
      this.api_sent.account_invoice = true;
      VOIP_API.endpoints.users.accountinvoicedetail({
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data }) => {
        vm.response.account_invoice = data
      })
      .finally(() => {
        vm.api_sent.account_invoice = false
      });
    },
    fetchSubscriptionDetail(){
      let vm = this
      if(vm.api_sent.subscription) return;
      vm.api_sent.subscription = true;
      VOIP_API.endpoints.billing.getsubscriptiondetail({ 
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data }) => {
        vm.response.subscription = data;
      })
      .finally(() => {
        vm.api_sent.subscription = false;
      });
    },
    onNotification(message) {
      if (message.data.action == "add" && message.data.section == "plan") {
        this.fetchCards();
        this.fetchAccountDetail();
        this.fetchAccountInvoices();
      }
      if(message.data.action == "new_card_add" && message.data.section == "card" && message.data.flag == "new_card"){
        this.fetchCards();
      }
    },
  },
  activated() {
    this.fetchCards();
    this.fetchAccountDetail();
    this.fetchAccountInvoices();
    this.fetchSubscriptionDetail();
  },
  created() {
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
    // centrifugo.mothership.on(centrifugo.mothership.events.message,this.onNotification)
  },
  beforeDestroy() {
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
    // centrifugo.mothership.off(centrifugo.mothership.events.message,this.onNotification)
  },
};
</script>

<style lang="scss">
#credit-slip {
  width: 100%;
  height: 100%;
  background-color: #eee;
}
.add-topUpBtn {
  position: absolute;
  top: -80px;
  right: 0px;
}
</style>