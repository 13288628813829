
import { VOIP_API } from "../index"

export const MENU_API_ENDPOINTS = {
  deleteivr(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'billing_service_action',
      },
    })
  },
  list(params={}) {
    return VOIP_API.axios.voip.request({
      url: "basicivrs",
      method: "get",
      params,
    })
  },
  updateUserMode(data={}){
    return VOIP_API.axios.voip.request({
      url: `ivrs/user-mode`,
      method: "PATCH",
      data,
    })
  },
  resetoption(account,data={}) {
    return VOIP_API.axios.voip.request({
      url: `ivrs/reset-schedule-option/${account}`,
      method: "delete",
      data,
    })
  },
  addoption(data={}){
    return VOIP_API.axios.voip.request({
      url: "basicivr/basic-ivr-schedule-option",
      method: "post",
      data,
    })
  },
  getcommonvalues(){
    return VOIP_API.axios.voip.request({
      url: `ivrs/common-values/ivr`,
      method: "GET",
    })
  },
  getivradvanceoption(account=''){
    return VOIP_API.axios.voip.request({
      url: `ivrs/advance_setting/${account}`,
      method: "GET",
    })
  },
  create(data={}){
    return VOIP_API.axios.voip.request({
      url: "billingcons",
      method: "post",
      data: {
        service: 'ivrs',
        ...data
      },
    })
  },
  delete(account){
    return VOIP_API.axios.voip.request({
      url: `basicivrs/${account}`,
      method: "delete"
    })
  },
  convert(data){
    return VOIP_API.axios.voip.request({
      url: `basicivr/text-to-audio`,
      method: "post",
      data
    })
  },
  convertTextToAudio(data){
    return VOIP_API.axios.voip.request({
      url: `basicivr/text-to-audio`,
      method: "POST",
      data
    })
  },
  convertTextToAudioPreview(data){
    return VOIP_API.axios.voip.request({
      url: `basicivr/text-to-audio/preview`,
      method: "POST",
      data
    })
  },
  advancesetting(data={}){
    return VOIP_API.axios.voip.request({
      url: `basicivr/advancesetting`,
      method: 'POST',
      data,
    })
  },
  labelUpdate(id='',data={},CancelToken=''){
    return VOIP_API.axios.voip.request({
      url: `basicivrs/${id}`,
      method: 'PATCH',
      data,
      CancelToken,
    })
  },
  removeWelcomeGreeting(uid='',did='',data={}){
    return VOIP_API.axios.voip.request({
      url: `delete-did-greetings/${uid}/${did}`,
      method: 'DELETE',
      data,
    })
  },
  updateivrtext(data={}){
    return VOIP_API.axios.voip.request({
      url: `update-ivr-text`,
      method: 'POST',
      data,
    })
  },
  uploadivrimage(data={},onUploadProgress=null){
    return VOIP_API.axios.voip.request({
      url: `upload-sound-ivr-file`,
      method: 'POST',
      data,
      onUploadProgress,
    })
  },
  // added by frank start
  uploadivrimagefromDID(data={},onUploadProgress=null){
    return VOIP_API.axios.voip.request({
      url: `upload-did-greetings`,
      method: 'POST',
      data,
      onUploadProgress,
    })
  },
  // added by frank end
  updateWrongIVRInput(data={}){
    return VOIP_API.axios.voip.request({
      url: `update-ivr-wrong-input`,
      method: 'POST',
      data,
    })
  },
}