<template>
  <modal  class="dialer_animation right_side_popup GenerateTimeOffReportsModal HrmForModalScroll" :clickToClose="true"  :scrollable="true" name="ExportOrgChartModal">
    <div class="modalHeading-container">
      <div class="modalHeading">Paul Jackson</div>
      <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('ExportOrgChartModal')">Close</button>
    </div>
    <div class="modalInner-container">
      <div class="innerShadowInput-container">
        <label>Direct manager</label>
        <b-dropdown class="hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="nameWithImage insideDropdown">
                <div class="imageContainer">
                  <img class="circle" :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">Mellissa</div>
              </div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item v-for="n in 6" :key="n">
            <div class="nameWithImage insideDropdown">
              <div class="imageContainer">
                <img class="circle" :src="require('@/assets/images/credit-card/1.jpeg')" />
              </div>
              <div class="nameWithImage-text">Mellissa</div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <button class="dialer-button dialer-button-primary mt-32px mr-0"> Save </button>
    </div>
  </modal>
</template>

<script>
export default {
  name : 'ExportOrgChartModal',
}
</script>

<style>

</style>