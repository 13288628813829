var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup switching-plan-modal ExtraPlansModal",
    staticStyle: {
      "z-index": "9999"
    },
    attrs: {
      "width": "70%",
      "height": "auto",
      "name": "ExtraResourcesAlertModal",
      "clickToClose": true
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Extra Plans")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('ExtraResourcesAlertModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "dialer-package-plan-area md-mar-vertical"
  }, [_c('div', {
    staticClass: "dialer-package-detail"
  }, [_c('div', {
    staticClass: "dialer-package-detail-inner",
    staticStyle: {
      "flex-direction": "column"
    }
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_c('div', {
    staticClass: "dialer-box alt table"
  }, [_c('table', {
    staticClass: "dialer-table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Label")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("message")])])])]), _c('tbody', _vm._l(_vm.array, function (data, index) {
    return _c('tr', {
      key: index,
      staticClass: "dialer-row-select"
    }, [_c('td', {
      staticClass: "dialer-row-title"
    }, [_vm._v(_vm._s(data.product_label))]), _c('td', {
      staticClass: "dialer-row-title"
    }, [_vm._v(_vm._s(data.Message))])]);
  }), 0)])])])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }