var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 AddLocationModal max-height-100vh min-width-50percent-modal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "name": _vm.modalName,
      "scrollable": true
    },
    on: {
      "closed": _vm.onClose,
      "before-open": _vm.beforeOpen
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addLocation.apply(null, arguments);
      }
    }
  }, [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Number location")]), _vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "dialer-edit-actions w-100 d-flex justify-content-end mt-2"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.add
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.add ? _c('vb-spinner') : [_vm._v("Save")]], 2)], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header pb-0 flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Number location")]), _c('div', {
    staticClass: "dialer-edit-actions d-flex"
  }, [_c('a', {
    staticClass: "newCloseButton mr-8px",
    attrs: {
      "id": "edit-location-modal-close"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('p', [_vm._v(" Add or update loction for a number ")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mb-4 mt-20px"
  }, [_vm.data.flag == 'emergency' ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mb-0"
  }, [_c('label', [_vm._v("Name of location")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_location.label,
      expression: "forms.add_location.label"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "maxlength": _vm.$v.forms.add_location.label.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.add_location.label
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_location, "label", $event.target.value);
      }
    }
  }), _vm.submitted.add && _vm.$v.forms.add_location.label.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_location.label.required ? _c('span', [_vm._v("* Name of location is required")]) : !_vm.$v.forms.add_location.label.alphaNum ? _c('span', [_vm._v("* Name of location can be only alphabet, number and space")]) : !_vm.$v.forms.add_location.label.minLength ? _c('span', [_vm._v("* Name of location should be minimum " + _vm._s(_vm.$v.forms.add_location.label.$params.minLength.min) + " characters")]) : !_vm.$v.forms.add_location.label.maxLength ? _c('span', [_vm._v("* Name of location can be maximum " + _vm._s(_vm.$v.forms.add_location.label.$params.maxLength.max) + " characters")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.data.flag == 'purchased' ? [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("User home address")]), _c('b-check', {
    staticClass: "newerSwitch",
    attrs: {
      "id": "user-home-address-check",
      "disabled": _vm.api_sent.add,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        $event ? _vm.forms.add_location.callerId = '' : '';
      }
    },
    model: {
      value: _vm.is_user_home_address,
      callback: function ($$v) {
        _vm.is_user_home_address = $$v;
      },
      expression: "is_user_home_address"
    }
  })], 1), _vm.is_user_home_address ? [_vm.add_caller_id_screen == 'number' ? [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Caller ID")]), _c('input', {
    directives: [{
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.forms.adding_caller_id.number,
      expression: "forms.adding_caller_id.number"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "caller-id-input-field",
      "type": "text",
      "disabled": _vm.api_sent.check
    },
    domProps: {
      "value": _vm.forms.adding_caller_id.number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.adding_caller_id, "number", $event.target.value);
      }
    }
  }), _vm.submitted.check ? [!_vm.$v.forms.adding_caller_id.number.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Number is required")])]) : !_vm.$v.forms.adding_caller_id.number.validNumber ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Number is invalid")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "w-100"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary mt-20px",
    attrs: {
      "id": "check-button",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        if ($event.target !== $event.currentTarget) return null;
        return _vm.checkValidate();
      }
    }
  }, [_vm.api_sent.check ? _c('vb-spinner') : [_vm._v("check")]], 2)])] : _vm._e(), _vm.add_caller_id_screen == 'pin' ? [_c('b-icon', {
    staticClass: "mt-20px",
    attrs: {
      "id": "back-icon",
      "icon": "arrow-left"
    },
    on: {
      "click": function ($event) {
        _vm.add_caller_id_screen = 'number';
      }
    }
  }), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-12px w-100 mb-0"
  }, [_c('label', [_vm._v("Enter pin code")]), _c('div', {
    staticClass: "enterPinSection"
  }, [_c('vb-pin-code', {
    attrs: {
      "id": "pin-code",
      "numberslength": 6,
      "value": _vm.forms.verify_caller_id_pin.pin,
      "disabled": _vm.api_sent.verify
    },
    on: {
      "change": function ($event) {
        _vm.forms.verify_caller_id_pin.pin = $event;
      }
    }
  }), _vm.submitted.verify ? [_c('span', [_vm._v("*")]), !_vm.$v.forms.verify_caller_id_pin.pin.required ? _c('p', [_vm._v("Pin is required")]) : _vm._e(), !_vm.$v.forms.verify_caller_id_pin.pin.minLength ? _c('p', [_vm._v("Pin should be 6 digit")]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "w-100 mt-20px"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "id": "verify-button",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        if ($event.target !== $event.currentTarget) return null;
        return _vm.validatePin();
      }
    }
  }, [_vm.api_sent.verify ? _c('vb-spinner') : [_vm._v("verify")]], 2)])] : _vm._e(), _vm.add_caller_id_screen == 'verified' ? [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Caller ID")]), _c('input', {
    staticClass: "w-100",
    attrs: {
      "id": "caller-id-input-field",
      "type": "text",
      "disabled": true
    },
    domProps: {
      "value": _vm.forms.adding_caller_id.number
    }
  }), _vm.submitted.check ? [!_vm.$v.forms.adding_caller_id.number.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Number is required")])]) : !_vm.$v.forms.adding_caller_id.number.validNumber ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Number is invalid")])]) : _vm._e()] : _vm._e()], 2)] : _vm._e()] : [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Select caller ID/ DID")]), _c('vb-select', {
    staticClass: "w-100 custom-dd-select",
    attrs: {
      "id": "caller-id-did",
      "listClass": "select-caller-id-options",
      "defaultSelectedText": 'Select extensions',
      "selected": _vm.forms.add_location.callerId,
      "disabled": _vm.api_sent.add || _vm.api_sent.list,
      "options": _vm.callerIdsOptions
    },
    on: {
      "change": function ($event) {
        _vm.forms.add_location.callerId = $event;
      }
    }
  })], 1)], _vm.submitted.add ? [!_vm.$v.forms.add_location.callerId.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mt-2"
  }, [_c('span', [_vm._v("* Extensions is required")])]) : _vm._e()] : _vm._e()] : _vm._e(), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Select Country")]), _c('vb-select', {
    staticClass: "w-100 custom-dd-select",
    attrs: {
      "id": "select-country",
      "listClass": "select-country-options",
      "selected": _vm.forms.add_location.country,
      "defaultSelectedText": 'Select country',
      "options": _vm.countriesOptions
    },
    on: {
      "change": function ($event) {
        _vm.forms.add_location.country = $event;
      }
    }
  }), _vm.submitted.add ? [!_vm.$v.forms.add_location.country.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Country is required")])]) : _vm._e()] : _vm._e()], 2), _vm.forms.add_location.country === 'GB' ? _c('div', {
    staticClass: "d-flex justify-content-between align-items-center whiteBGinputWithGreyRoundedBorder mb-0 mt-20px w-100"
  }, [_c('label', [_vm._v("Manually enter the address")]), _c('b-check', {
    staticClass: "newerSwitch",
    attrs: {
      "id": "manually-enter-address-ckeck",
      "disabled": _vm.api_sent.add,
      "switch": ""
    },
    model: {
      value: _vm.is_manual_address,
      callback: function ($$v) {
        _vm.is_manual_address = $$v;
      },
      expression: "is_manual_address"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 w-50per-15px"
  }, [_c('label', [_vm._v("First name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_location.forename,
      expression: "forms.add_location.forename"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "first-name",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.add_location.forename
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_location, "forename", $event.target.value);
      }
    }
  }), _vm.submitted.add ? [!_vm.$v.forms.add_location.forename.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* First name is required")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 w-50per-15px"
  }, [_c('label', [_vm._v("Last name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_location.surname,
      expression: "forms.add_location.surname"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "last-name",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.add_location.surname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_location, "surname", $event.target.value);
      }
    }
  }), _vm.submitted.add ? [!_vm.$v.forms.add_location.surname.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Last name is required")])]) : _vm._e()] : _vm._e()], 2)]), _vm.is_manual_address ? [_c('div', {
    staticClass: "d-flex w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-30per-15px mb-0"
  }, [_c('label', [_vm._v("House number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.house_name,
      expression: "forms.manual_address.house_name"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "manual-house-number-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.manual_address.house_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "house_name", $event.target.value);
      }
    }
  }), _vm.submitted.add ? [!_vm.$v.forms.manual_address.house_name.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* House number is required")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-70per-15px mb-0"
  }, [_c('label', [_vm._v("Street")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.street,
      expression: "forms.manual_address.street"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "manual-street-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.manual_address.street
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "street", $event.target.value);
      }
    }
  }), _vm.submitted.add ? [!_vm.$v.forms.manual_address.street.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Street is required")])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "d-flex w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0"
  }, [_c('label', [_vm._v("City")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.city,
      expression: "forms.manual_address.city"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "manual-city-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.manual_address.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "city", $event.target.value);
      }
    }
  }), _vm.submitted.add ? [!_vm.$v.forms.manual_address.city.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* City is required")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0"
  }, [_c('label', [_vm._v("Postal code ")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.postal_code,
      expression: "forms.manual_address.postal_code"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "manual-post-code-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.manual_address.postal_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "postal_code", $event.target.value);
      }
    }
  }), _vm.submitted.add ? [!_vm.$v.forms.manual_address.postal_code.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Postal code is required")])]) : _vm._e()] : _vm._e()], 2)])] : [_c('div', {
    staticClass: "d-flex w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0"
  }, [_c('label', [_vm._v("House number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.uk_address.house_name,
      expression: "forms.uk_address.house_name"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "auto-house-number-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.uk_address.house_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.uk_address, "house_name", $event.target.value);
      }
    }
  }), _vm.submitted.add ? [!_vm.$v.forms.uk_address.house_name.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* House number is required")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0"
  }, [_c('label', [_vm._v("Postal code")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.uk_address.postal_code,
      expression: "forms.uk_address.postal_code"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "auto-postal-code-input-field",
      "type": "text",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.uk_address.postal_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.uk_address, "postal_code", $event.target.value);
      }
    }
  }), _vm.submitted.add ? [!_vm.$v.forms.uk_address.postal_code.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Postal code is required")])]) : _vm._e()] : _vm._e(), _vm.$v.forms.uk_address.postal_code.required ? [!_vm.$v.forms.uk_address.postal_code.valid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Postal code is not valid")])]) : _vm._e()] : _vm._e(), _vm.api_sent.addresses ? _c('vb-spinner', {
    attrs: {
      "variant": "primary"
    }
  }) : [_vm.api_status.addresses == 'success' ? _c('b-icon', {
    attrs: {
      "icon": "check2",
      "variant": "success"
    }
  }) : _vm._e(), _vm.api_status.addresses == 'fail' ? _c('b-icon', {
    attrs: {
      "icon": "x",
      "variant": "danger"
    }
  }) : _vm._e()], !_vm.isEmpty(_vm.errors.addresses) ? [_vm.errors.addresses.message ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* " + _vm._s(_vm.errors.addresses.message))])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Select address")]), _c('b-select', {
    attrs: {
      "id": "auto-select-address-select",
      "disabled": _vm.api_sent.add
    },
    model: {
      value: _vm.forms.uk_address.address,
      callback: function ($$v) {
        _vm.$set(_vm.forms.uk_address, "address", $$v);
      },
      expression: "forms.uk_address.address"
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select address")]), _vm._l(_vm.response.address.addresses, function (address) {
    return _c('option', {
      key: address,
      domProps: {
        "value": address
      }
    }, [_vm._v(_vm._s(address.replace(/,/g, '').replace(/\s+/g, ' ').trim()))]);
  })], 2), _vm.submitted.add ? [!_vm.$v.forms.uk_address.address.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Address is required")])]) : _vm._e()] : _vm._e()], 2)]], 2), _c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.add
    }
  }, [_vm.api_sent.add ? _c('vb-spinner') : [_vm._v("Save")]], 2)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }