
import { VOIP_API } from "../index"

export const ELECTRON_API_ENDPOINTS = {
  appVersionCP(platform,version,params={}){
    return VOIP_API.axios.voip_centeral_point.v2.request({
      method: "GET",
      url: `desktop-versions/${platform}/${version}`,
      params,
    })
  },
  allowVersions(platform,params={}){
    return VOIP_API.axios.voip_centeral_point.v2.request({
      method: "GET",
      url: `desktop-versions/${platform}`,
      params,
    })
  },
  appVersion(params={}){
    return VOIP_API.axios.voip_relay.request({
      method: "GET",
      url: `app-version`,
      params,
    })
  },
}