
import { TokenService } from "@/services/storage.service"
import { VOIP_API } from "../index"

export const DOMAIN_API_ENDPOINTS = {
  set(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'relay',
        action: 'domain_add',
        root_service_id: 1, //3
        node_service_id: 2, //1
        uid: TokenService.USER.get().uid
      },
    })
  },
  check(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        root_service_id: 1, //3
        type: 'relay',
        action: 'validate_domain',
      },
    })
  },
  list(){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        uid: TokenService.USER.get().uid,
        type: 'relay',
        action: 'list_domain',
      },
    })
  },
}