<template>
  <modal 
    class="dialer_animation right_side_popup m-w-500 CallQueueSoundFilesModal" 
    width="50%" 
    height="auto" 
    :scrollable="true" 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <div class="dialer-assign-to dialer-edit">
      <div v-if="getIsMobile" class="dialer-edit-header flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center mb-2">
          <h2 class="dialer-edit-title">
            Sound files
          </h2>
          <div class="d-flex">
            <b-icon class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            <button :disabled="!selectedFile" class="newDoneButton" @click="onSelect()">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
      </div>
      <div v-else class="dialer-edit-header">
        <div>
          <h2 class="dialer-settings-title newer mb-0 d-flex align-items-center">
            <!-- <vb-svg @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" /> -->
            <button class="newBackButton mr-16px" @click="$modal.hide(modalName)">
              <b-icon icon="arrow-left-circle" />
              <span class="newBackButton-textpart">Back</span>
            </button>
            Sound files
          </h2>
        </div>
        <div class="dialer-edit-actions">
          <!-- <button class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button> -->
          <button :disabled="!selectedFile" class="newDoneButton" @click="onSelect()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <template v-if="api.files.send">
          <div class="whiteBoxWithBorderInsideGreyBox withOutPadding">
            <div class="whiteBoxWithBorderInsideGreyBox-row py-3 flex-column align-items-start" v-for="n in 7" :key="n.id">
              <div class="latestShimmerDesign" style="height: 27px;width: 50%;"></div>
              <div class="d-flex align-items-center w-100 mt-12px">
                <div class="latestShimmerDesign mr-4" style="height: 24px;width: 24px;min-height: 24px;min-width: 24px;"></div>
                <div class="latestShimmerDesign flex-fill" style="height: 64px;width: 100%;"></div>
              </div>
            </div>
          </div>
        </template>
        <div class="whiteBoxWithBorderInsideGreyBox withOutPadding" v-else>
          <div :class="`whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start p-3 ${selected.file==file.id ? 'active' : ''}`" v-for="file in filterSoundFiles" :key="file.id">
            <div class="latestGreyBox-heading-main">{{ file.label }}</div>
            <div class="w-100 d-flex justify-content-between align-items-center mt-12px">
              <div class="rememberMeCheckbox-container">
                <b-form-checkbox :checked="selected.file==file.id" @change="selected.file=file.id" />
              </div>
              <div class="flex-fill">
                <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again w-100" :src="file.sound_file" />
              </div>
            </div>
          </div>
          <vb-no-record class="m-5" v-if="isEmpty(filterSoundFiles)" :text="api.files.send ? '' : 'Sorry, No data founding list'" :design="3">
            <!-- <vb-loading v-if="api.files.send" slot="loading" /> -->
          </vb-no-record>
        </div>
      </div>
      <!--<div v-if="false" class="dialer-tabs">
        <div class="dialer-tab-content">
          <ul class="list-unstyled dialer-asssignee-list">
            <li class="dialer-assignee" v-for="file in filterSoundFiles" :key="file.id">
              <div :class="`checkBoxAudioContainer ${selected.file==file.id ? 'active' : ''}`">
                <span class="dialer-assignee-name">{{ file.label }}</span>
                <div class="checkBoxAudioContainer-inner">
                  <b-form-checkbox :checked="selected.file==file.id" @change="selected.file=file.id" />
                  <vb-audio-player class="audioContainer" :src="file.sound_file" />
                </div>
              </div>
            </li>
            <vb-no-record v-if="isEmpty(filterSoundFiles)" :text="api.files.send ? '' : 'Sorry, no Data founding list'" :design="3">
              <vb-loading v-if="api.files.send" slot="loading" />
            </vb-no-record>
          </ul>
        </div>
      </div>-->
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { $fn, VOIP_API } from '../../utils';
export default {
  name: "CallQueueSoundFilesModal",
  inject: ['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'CallQueueSoundFilesModal',
    },
    sound_files: {
      type: Array,
      default: ()=>[],
    }
  },
  data() {
    return {
      selected: {
        file: '',
        reset(){
          this.file = ''
        },
      },
      api: {
        files: this.$helperFunction.apiInstance(),
      },
      data: {
        type: '',
        reset(){
          this.type = ''
        },
      },
      response: {
        files: [],
        shared: [],
        system: [],
        reset(){
          this.files = [];
          this.shared = [];
          this.system = [];
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: 'getCurrentUser',
      getIsMobile: 'getIsMobile',
    }),
    filterSoundFiles(){ return [...this.response.files,...this.response.shared,...this.response.system].filter(i=>i.group=='announcement') },
    selectedFile(){ return this.filterSoundFiles.find(i=>i.id==this.selected.file) },
  },
  methods: {
    onBeforeOpen(event){
      this.selected.file=event?.params?.value
      this.data.type=event?.params?.type
      this.fetchSoundsFile()
    },
    onBeforeClose(){
      this.selected.reset()
      this.data.reset()
      this.response.reset()
    },
    onSelect(){
      this.$emit('select-file',{
        file: this.selectedFile,
        type: this.data.type 
      })
      this.$modal.hide(this.modalName)
    },
    fetchSoundsFile() {
      let vm = this;
      vm.api.files.send = true;
      VOIP_API.endpoints.recording.list({
        uid: vm.getCurrentUser.uid,
        accountcode: vm.getCurrentUser.account,
      })
      .then(({ data }) => {
        vm.response.files = data?.files?.data ?? data?.files ?? [];
        vm.response.shared = data?.shared?.data ?? data?.shared ?? [];
        vm.response.system = data?.global?.data ?? data?.global ?? [];
        return $fn.sleep(1*1000)
      })
      .then(()=>{
        return vm.filterSoundFiles.find(i=>vm.selected.file==i.sound_file?.split?.('/')[i.sound_file?.split?.('/').length-1])
      })
      .then((selected_file)=>{
        if(selected_file)vm.selected.file=selected_file.id
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.files.send = false;
      });
    },
  },
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
