var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "CreateChanneFromUserslModal ForwardMessageModal",
    attrs: {
      "name": _vm.modalName,
      "width": 400
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('div', {
    staticClass: "topBar-withCloseIcon mt-0"
  }, [_c('h3', {
    staticClass: "dialer-box-header w-100 mb-0"
  }, [_vm._v("Forward Message")]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "searchBox"
  }, [_c('b-form', {
    staticClass: "position-relative",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search User"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('b-icon', {
    staticClass: "position-absolute cursor_pointer searchIcon",
    attrs: {
      "icon": "search",
      "scale": "1"
    }
  })], 1)], 1), _c('div', {
    staticClass: "addable-memberLisit"
  }, [!_vm.isEmpty(_vm.post) ? _c('div', {
    staticClass: "px-2"
  }, [_c('Message', {
    ref: "forward_modal_single_message",
    staticClass: "forward_modal_single_message",
    attrs: {
      "fromForwardModal": true,
      "post": _vm.post,
      "channel_id": _vm.channel_id,
      "channel": _vm.channel,
      "data": _vm.data,
      "channelData": _vm.channelData,
      "members": _vm.members,
      "type": _vm.type,
      "name": _vm.name,
      "image": _vm.image
    }
  })], 1) : _vm._e()]), _c('ul', {
    staticClass: "AvailableUsersList"
  }, [!_vm.isEmpty(_vm.searchedUsers) ? [_c('li', {
    staticClass: "AvailableUsersList-item"
  }, [_c('h6', {
    staticClass: "m-0"
  }, [_vm._v("Users")])]), _vm._l(_vm.searchedUsers, function (user) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: user.channel_id !== _vm.channel_id,
        expression: "user.channel_id !== channel_id"
      }],
      key: user.voipaccount,
      staticClass: "AvailableUsersList-item",
      on: {
        "click": function ($event) {
          return _vm.sent(user, 'user');
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": user.voipaccount,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _c('div', {
      staticClass: "actions"
    }, [!_vm.sentChannels[user.channel_id] ? _c('a', {
      staticClass: "d-flex dialer-button dialer-button-primary"
    }, [_vm._v("Send")]) : _vm._e(), _vm.sentChannels[user.channel_id] ? _c('a', {
      staticClass: "d-flex align-items-center dialer-button dialer-button-primary message-sent"
    }, [_c('b-icon', {
      attrs: {
        "icon": "check2",
        "scale": "1.2",
        "variant": "success"
      }
    }), _vm._v(" Sent ")], 1) : _vm._e()])], 1);
  })] : _vm._e(), !_vm.isEmpty(_vm.searchedTeams) ? [_c('li', {
    staticClass: "AvailableUsersList-item"
  }, [_c('h6', {
    staticClass: "m-0"
  }, [_vm._v("Teams")])]), _vm._l(_vm.searchedTeams, function (team) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: team.channel_id !== _vm.channel_id,
        expression: "team.channel_id !== channel_id"
      }],
      key: team.channel_id,
      staticClass: "AvailableUsersList-item",
      on: {
        "click": function ($event) {
          return _vm.sent(team, 'team');
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": team.real_id,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _c('div', {
      staticClass: "actions"
    }, [!_vm.sentChannels[team.channel_id] ? _c('a', {
      staticClass: "d-flex dialer-button dialer-button-primary"
    }, [_vm._v("Send")]) : _vm._e(), _vm.sentChannels[team.channel_id] ? _c('a', {
      staticClass: "d-flex align-items-center dialer-button dialer-button-primary message-sent"
    }, [_c('b-icon', {
      attrs: {
        "icon": "check2",
        "scale": "1.2",
        "variant": "success"
      }
    }), _vm._v(" Sent ")], 1) : _vm._e()])], 1);
  })] : _vm._e(), !_vm.isEmpty(_vm.searchedChannels) ? [_c('li', {
    staticClass: "AvailableUsersList-item"
  }, [_c('h6', {
    staticClass: "m-0"
  }, [_vm._v(" Groups ")])]), _vm._l(_vm.searchedChannels, function (channel) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: channel.channel.id !== _vm.channel_id,
        expression: "channel.channel.id !== channel_id"
      }],
      key: channel.channel.id,
      staticClass: "AvailableUsersList-item",
      on: {
        "click": function ($event) {
          return _vm.sent(channel, 'channel');
        }
      }
    }, [_c('Info', {
      attrs: {
        "name": _vm._f("get_property")(channel, 'channel.display_name'),
        "image": _vm.channelImg
      }
    }), _c('div', {
      staticClass: "actions"
    }, [!_vm.sentChannels[channel.channel.id] ? _c('a', {
      staticClass: "d-flex dialer-button dialer-button-primary"
    }, [_vm._v("Send")]) : _vm._e(), _vm.sentChannels[channel.channel.id] ? _c('a', {
      staticClass: "d-flex align-items-center dialer-button dialer-button-primary message-sent"
    }, [_c('b-icon', {
      attrs: {
        "icon": "check2",
        "scale": "1.2",
        "variant": "success"
      }
    }), _vm._v(" Sent ")], 1) : _vm._e()])], 1);
  })] : _vm._e(), _vm.isEmpty(_vm.searchedChannels) && _vm.isEmpty(_vm.searchedTeams) && _vm.isEmpty(_vm.searchedUsers) ? _c('vb-no-record', {
    attrs: {
      "text": "There is no record",
      "design": 3
    }
  }) : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }