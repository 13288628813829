var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup new-call-info-modal new-call-info-modal-newer v2",
    attrs: {
      "width": "50%",
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "panel-inner panel-inner-callInfo new-call-info-inner"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-between py-0 mb-0 dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Call info")]), _c('div', [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Below you will see details about this call.")]), _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_c('div', {
    staticClass: "list-area tab-info"
  }, [_c('div', {
    staticClass: "holder"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('ul', {
    staticClass: "phone-list full-width-call-info-number list-unstyled pr-0 pb-0 overlowX-visible-imp"
  }, [_c('li', {
    staticClass: "p-0"
  }, [_c('Item1', {
    staticClass: "m-0 w-100",
    attrs: {
      "call": _vm.call,
      "conditions": {
        actions: false
      }
    },
    scopedSlots: _vm._u([{
      key: "more",
      fn: function () {
        return [_c('b-dropdown', {
          staticClass: "ico-filter",
          attrs: {
            "varient": "link",
            "no-caret": "",
            "menu-class": "w-fit-content-imp",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots",
                  "variant": "#393939",
                  "font-scale": "1.4"
                }
              })];
            },
            proxy: true
          }])
        }, [!_vm.call.contact_name && _vm.call.is_did ? _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              _vm.selected.tab = _vm.tabs.AddContacts;
            }
          }
        }, [_c('vb-icon', {
          staticClass: "mr-16px",
          attrs: {
            "icon": "callInfoModal-addContacts-icon",
            "width": "17.004px",
            "height": "17.72px"
          }
        }), _vm._v(" Add contact ")], 1) : _vm._e(), _vm.callID && _vm.call.call_type == 'audio' && !_vm.call.isTaskAssigned ? _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.addTodoTask();
            }
          }
        }, [_c('vb-icon', {
          staticClass: "mr-16px",
          attrs: {
            "icon": "callInfoModal-assignTo-icon",
            "width": "20.875px",
            "height": "17.436px"
          }
        }), _vm._v(" Assign to ")], 1) : _vm._e(), !_vm.api.history.send && _vm.isDialableNumber && _vm.call.is_did && _vm.getCurrentUser.administrator_account && !_vm.conditions.block_number ? _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              _vm.selected.tab = _vm.tabs.block;
            }
          }
        }, [_c('vb-icon', {
          staticClass: "mr-16px",
          attrs: {
            "icon": "callInfoModal-blockNumber-icon",
            "width": "17.002px",
            "height": "17.002px"
          }
        }), _vm._v(" Block ")], 1) : _vm._e(), _c('b-dropdown-item', {
          on: {
            "click": function ($event) {
              return _vm.calling(_vm.call);
            }
          }
        }, [_c('vb-icon', {
          staticClass: "mr-16px",
          attrs: {
            "icon": "callInfoModal-callNumber-icon",
            "width": "17.17px",
            "height": "17.164px"
          }
        }), _vm._v(" Call ")], 1)], 1)];
      },
      proxy: true
    }])
  })], 1)])]), _c('div', [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-32px mb-32px"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-container"
  }, [(_vm.call.call_type !== 'voicemail' || _vm.call.call_type !== 'sms') && _vm.callID ? _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.tags_and_note
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.tags_and_note;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-tags-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("Call info")])], 1) : _vm._e(), _vm.call.isTaskAssigned ? _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.task
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.task;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-tags-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("Task")])], 1) : _vm._e(), _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.history
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.history;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-history-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("History")])], 1), _vm.call.call_type == 'audio' || _vm.call.call_type == 'voicemail' ? _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.recordings
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.recordings;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-brain-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v(" AI transcript ")])], 1) : _vm._e(), (_vm.call.is_did || _vm.call.call_type == 'sms') && _vm.isMobileNumber ? _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.sms
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.sms;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-sms-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("SMS")])], 1) : _vm._e(), !_vm.call.contact_name && _vm.call.is_did ? _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.AddContacts
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.AddContacts;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-sms-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("Add contact")])], 1) : _vm._e(), !_vm.api.history.send && _vm.isDialableNumber && _vm.call.is_did && _vm.getCurrentUser.administrator_account && !_vm.conditions.block_number ? _c('div', {
    staticClass: "TabsDesignWithIcon-item",
    class: {
      selected: _vm.selected.tab == _vm.tabs.block
    },
    on: {
      "click": function ($event) {
        _vm.selected.tab = _vm.tabs.block;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "newbilling-tab-sms-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "TabsDesignWithIcon-item-textPart"
  }, [_vm._v("Block")])], 1) : _vm._e()]), _vm.selected.tab == _vm.tabs.history ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD"
  }, [_c('b-dropdown', {
    staticClass: "dd-text-dark dd-with-checkbox",
    attrs: {
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          attrs: {
            "icon": "callInfoModal-funnel-icon",
            "width": "17.892px",
            "height": "17.896px"
          }
        })];
      },
      proxy: true
    }], null, false, 513752452)
  }, [_c('div', {
    staticClass: "w-100 position-relative pl-4"
  }, [_c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    model: {
      value: _vm.setting.recording,
      callback: function ($$v) {
        _vm.$set(_vm.setting, "recording", $$v);
      },
      expression: "setting.recording"
    }
  }, [_vm._v("Recording")]), _c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    model: {
      value: _vm.setting.message,
      callback: function ($$v) {
        _vm.$set(_vm.setting, "message", $$v);
      },
      expression: "setting.message"
    }
  }, [_vm._v("SMS and messages")]), _c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    model: {
      value: _vm.setting.tags,
      callback: function ($$v) {
        _vm.$set(_vm.setting, "tags", $$v);
      },
      expression: "setting.tags"
    }
  }, [_vm._v("Tags")]), _c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    model: {
      value: _vm.setting.note,
      callback: function ($$v) {
        _vm.$set(_vm.setting, "note", $$v);
      },
      expression: "setting.note"
    }
  }, [_vm._v("Notes")])], 1)])], 1) : _vm._e()]), _c('ul', {
    staticClass: "allow_scroll phone-list full-width-call-info-number dialor-callHistoryList list-unstyled pl-0 pr-2",
    class: {
      'tags_and_note_tab': _vm.selected.tab == _vm.tabs.tags_and_note
    },
    staticStyle: {
      "overflow-y": "unset",
      "height": "auto !important"
    }
  }, [_vm.selected.tab == _vm.tabs.tags_and_note ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Recording")]), _vm.call.soundFile ? _c('vb-audio-player', {
    staticClass: "w-100 mt-20px",
    attrs: {
      "src": _vm.call.soundFile,
      "design": 'NoTranscript'
    },
    scopedSlots: _vm._u([{
      key: "player-end",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "cursor_pointer_no_hover",
          attrs: {
            "icon": "audioTranscript-download-icon",
            "width": "14.2px",
            "height": "14.2px"
          },
          on: {
            "click": function ($event) {
              return _vm.download();
            }
          }
        })];
      },
      proxy: true
    }], null, false, 992160004)
  }) : _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 flex-wrap d-flex mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v("There is no recording")])])], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call tags")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "callInfoEditNotesTagsBoxTypeButton",
    attrs: {
      "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
    },
    on: {
      "click": function ($event) {
        _vm.conditions.edit_tags = !_vm.conditions.edit_tags;
      }
    }
  }, [_vm.isEmpty(_vm.callTags) ? [_vm.conditions.edit_tags ? _c('b-icon', {
    attrs: {
      "icon": "x-lg",
      "variant": "dark",
      "font-scale": "2"
    }
  }) : _c('b-icon', {
    attrs: {
      "font-scale": "1.3",
      "icon": "plus-lg",
      "variant": "dark"
    }
  }), _vm._v(" " + _vm._s(_vm.conditions.edit_tags ? 'Cancel' : 'Add') + " ")] : [_vm.conditions.edit_tags ? _c('b-icon', {
    attrs: {
      "icon": "x-lg",
      "variant": "dark",
      "font-scale": "2"
    }
  }) : _c('b-icon', {
    attrs: {
      "font-scale": "1.3",
      "icon": "pencil-fill",
      "variant": "primary"
    }
  }), _vm._v(" " + _vm._s(_vm.conditions.edit_tags ? 'Cancel' : 'Edit') + " ")]], 2)]), !_vm.conditions.edit_tags ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 flex-wrap d-flex mt-20px"
  }, [_vm._l(_vm.callTags, function (tag) {
    return _c('span', {
      key: tag.id,
      staticClass: "latestTagDesignLayout"
    }, [_c('small', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color",
        value: tag.colour,
        expression: "tag.colour"
      }]
    }, [_c('span', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text",
        value: tag.text_color,
        expression: "tag.text_color",
        modifiers: {
          "text": true
        }
      }]
    }, [_vm._v(_vm._s(tag.tag))])])]);
  }), _vm.isEmpty(_vm.callTags) ? _c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v("There is no tags")]) : _vm._e()], 2) : _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 mt-20px"
  }, [_c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search tags"
    },
    model: {
      value: _vm.filter.tag.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tag, "search", $$v);
      },
      expression: "filter.tag.search"
    }
  })], 1), _vm._l(_vm.api.tag.validation_errors.log, function (em, i) {
    return _c('p', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), _c('div', {
    staticClass: "d-flex flex-wrap w-100 mt-20px"
  }, [_vm._l(_vm.filterTags, function (tag) {
    return _c('div', {
      key: tag.real_id,
      class: `${_vm.forms.tag.tags.includes(tag.real_id) ? 'selected' : ''} latestTagDesignLayout`,
      on: {
        "click": function ($event) {
          _vm.api.tag.send ? '' : _vm.forms.tag.tags.includes(tag.real_id) ? _vm.forms.tag.tags.splice(_vm.forms.tag.tags.indexOf(tag.real_id), 1) : _vm.forms.tag.tags.push(tag.real_id);
        }
      }
    }, [_c('small', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color",
        value: tag.colour,
        expression: "tag.colour"
      }],
      style: `border-color: ${_vm.forms.tag.tags.includes(tag.real_id) ? '#3699FF' : ''}`
    }, [_c('span', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text",
        value: tag.text_color,
        expression: "tag.text_color",
        modifiers: {
          "text": true
        }
      }]
    }, [_vm._v(" " + _vm._s(tag.tag) + " ")])])]);
  }), _vm.isEmpty(_vm.filterTags) ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("There is no tags")]) : _vm._e()], 2), _vm._l(_vm.api.tag.validation_errors.log, function (em, i) {
    return _c('p', {
      key: i,
      staticClass: "latestGreyBox-descriptionText"
    }, [_vm._v(_vm._s(em))]);
  }), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.tag.send,
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updatetag();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.tag.send ? _c('vb-spinner') : [_vm._v(" Submit ")]], 2)], 1)], 1)], 2)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call notes")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "callInfoEditNotesTagsBoxTypeButton",
    attrs: {
      "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
    },
    on: {
      "click": function ($event) {
        _vm.conditions.edit_notes = !_vm.conditions.edit_notes;
      }
    }
  }, [_vm.data.note ? [_vm.conditions.edit_notes ? _c('b-icon', {
    attrs: {
      "icon": "x-lg",
      "variant": "dark",
      "font-scale": "2"
    }
  }) : _c('b-icon', {
    attrs: {
      "font-scale": "1.3",
      "icon": "pencil-fill",
      "variant": "primary"
    }
  }), _vm._v(" " + _vm._s(_vm.conditions.edit_notes ? 'Cancel' : 'Edit') + " ")] : [_vm.conditions.edit_notes ? _c('b-icon', {
    attrs: {
      "icon": "x-lg",
      "variant": "dark",
      "font-scale": "2"
    }
  }) : _c('b-icon', {
    attrs: {
      "font-scale": "1.3",
      "icon": "plus-lg",
      "variant": "dark"
    }
  }), _vm._v(" " + _vm._s(_vm.conditions.edit_notes ? 'Cancel' : 'Add') + " ")]], 2)]), !_vm.conditions.edit_notes ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v(_vm._s(_vm.data.note || 'There is no note'))])]) : [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Write some note for this call")]), _c('b-textarea', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.note.send,
      "rows": "10",
      "no-resize": "",
      "placeholder": "Enter note"
    },
    model: {
      value: _vm.forms.note.note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.note, "note", $$v);
      },
      expression: "forms.note.note"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.note.send,
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updatenote();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.note.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1)], 1)])]], 2), _vm.call.call_type == 'audio' ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Task")]), _vm.callID && _vm.call.call_type == 'audio' && !_vm.call.isTaskAssigned ? _c('div', {
    staticClass: "callInfoEditNotesTagsBoxTypeButton",
    on: {
      "click": function ($event) {
        return _vm.addTodoTask();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "mr-16px",
    attrs: {
      "icon": "callInfoModal-assignTo-icon",
      "width": "23.875px",
      "height": "20.436px"
    }
  }), _vm._v(" Assign to ")], 1) : _vm._e()]), !_vm.call.isTaskAssigned ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v("No task has been created")])]) : _c('TaskItem', {
    attrs: {
      "task": _vm.call.isTaskAssigned,
      "isMore": false
    },
    on: {
      "taskClick": function ($event) {
        return _vm.$modal.show('GlobalEditTask', {
          id: _vm.call.isTaskAssigned.id
        });
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("How was the quality of your call?")]), _c('b-form-rating', {
    staticClass: "latestCallFeedback",
    attrs: {
      "no-border": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateFeedback();
      }
    },
    model: {
      value: _vm.forms.feedback.rating,
      callback: function ($$v) {
        _vm.$set(_vm.forms.feedback, "rating", $$v);
      },
      expression: "forms.feedback.rating"
    }
  })], 1)])])] : _vm._e(), _vm.selected.tab == _vm.tabs.recordings ? [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center my-3"
  }, [_c('h2', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.call.call_type == 'voicemail' ? 'Message' : 'Call recording'))])]), _vm.call.call_type == 'voicemail' ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 h-100"
  }, [_c('a', {
    staticClass: "d-flex align-items-start justify-content-between text-dark p-0 mb-0"
  }, [_c('span', {
    staticClass: "info-area"
  }, [_c('div', {
    staticClass: "call-date",
    staticStyle: {
      "justify-content": "flex-end",
      "display": "flex",
      "height": "20px"
    }
  }, [_c('div', {
    staticClass: "dialpad_activity_box_other"
  }, [_c('span', {
    staticClass: "dialer-dropdown dialpad_activity_box_menu whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute w-100"
  }, [_vm.call.voice ? _c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots",
            "scale": "1"
          }
        })];
      },
      proxy: true
    }], null, false, 2567964649)
  }, [_vm.call.voice ? _c('b-dropdown-item', {
    staticClass: "mt-0",
    on: {
      "click": function ($event) {
        return _vm.download();
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "download"
    }
  }), _c('span', [_vm._v("Download")])], 1) : _vm._e()], 1) : _vm._e()], 1)])]), _c('span', {
    staticClass: "small text-capitalize w-100-60 mt-8px"
  }, [_c('div', {
    staticClass: "dialer-audio-playback"
  }, [_c('vb-audio-player', {
    staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100 mr-16px fullWidth backgroundWhite",
    attrs: {
      "design": 'whiteBgBlackBorder',
      "src": _vm._f("base64_to_url")(_vm.call.voice)
    }
  })], 1)])])])])] : _vm.call.call_type == 'audio' && _vm.call.soundFile && _vm.call.status != 'Missed' ? _c('AudioTranscriptDummy', {
    attrs: {
      "soundFile": _vm.call.soundFile,
      "uniqueID": _vm.uniqueID
    },
    scopedSlots: _vm._u([{
      key: "transcript-end",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mt-20px mb-20px"
        }, [_c('div', {
          staticClass: "latestGreyBox-heading-main w-100 d-flex align-items-center justify-content-between"
        }, [_c('span', [_vm._v("Call context")]), _c('button', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          staticClass: "newButton",
          attrs: {
            "title": _vm.getUserPermissions.call_tagging_and_notes ? '' : 'Your package does not support this feature'
          },
          on: {
            "click": function ($event) {
              !_vm.getUserPermissions.call_tagging_and_notes ? '' : _vm.$modal.show(`${_vm._uid}-UpdateCallNotesAndTags`, {
                note: _vm.data.note,
                tags: _vm.callTags
              });
            }
          }
        }, [_c('vb-icon', {
          attrs: {
            "icon": "squared-update-icon",
            "height": "38px",
            "width": "38px"
          }
        }), _c('span', {
          staticClass: "newButton-textPart"
        }, [_vm._v("Update")])], 1)]), _c('div', {
          staticClass: "AudioTranscript-whiteBox mt-20px"
        }, [_c('div', {
          staticClass: "latestGreyBox-heading-main-16px"
        }, [_vm._v("Notes")]), _c('div', {
          staticClass: "latestGreyBox-descriptionText-13px mt-10px"
        }, [_vm._v(_vm._s(_vm.data.note))]), _c('div', {
          staticClass: "latestGreyBox-heading-main-16px mt-24px"
        }, [_vm._v("tags")]), _c('div', {
          staticClass: "AI-tags-container"
        }, _vm._l(_vm.callTags, function (tag) {
          return _c('div', {
            directives: [{
              name: "vb-tag-color",
              rawName: "v-vb-tag-color.bg",
              value: tag.colour,
              expression: "tag.colour",
              modifiers: {
                "bg": true
              }
            }],
            key: tag.id,
            staticClass: "AI-tag"
          }, [_vm._v(_vm._s(tag.tag))]);
        }), 0)])])];
      },
      proxy: true
    }], null, false, 1909273509)
  }) : _c('div', [_c('h2', {
    staticClass: "w-100 text-center my-3"
  }, [_vm._v("No recordings available ")])])] : _vm._e(), _vm.selected.tab == _vm.tabs.sms ? [_c('SMS', {
    attrs: {
      "otherNumber": _vm.call.receiver || _vm.call.dialable
    }
  })] : _vm._e(), _vm.selected.tab == _vm.tabs.history ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "phone-list full-width-call-info-number dialor-callHistoryList history_tab list-unstyled p-0 h-100",
    staticStyle: {
      "overflow": "auto"
    },
    on: {
      "scroll": function ($event) {
        return _vm.onScroll($event);
      }
    }
  }, [_vm._l(_vm.filteredCallHistory, function (call_history, index) {
    return [call_history.isDateShow ? _c('h3', {
      key: `${index}-date-call-activity`,
      staticClass: "pb-1 ddd"
    }, [_c('span', {
      staticStyle: {
        "color": "black",
        "font-size": "12px"
      }
    }, [_vm._v(" " + _vm._s(_vm._f("dateFormate")(call_history.call_date || call_history.time)) + " ")])]) : _vm._e(), _c('li', {
      key: index
    }, [_c('Item1', {
      key: index,
      attrs: {
        "call": call_history,
        "conditions": {
          tag: _vm.setting.tags,
          note: _vm.setting.note,
          recording: _vm.setting.recording,
          message: _vm.setting.message
        },
        "type": 'history'
      },
      scopedSlots: _vm._u([{
        key: "more",
        fn: function () {
          return [_c('b-dropdown', {
            staticClass: "ico-filter",
            attrs: {
              "varient": "link",
              "no-caret": "",
              "menu-class": "w-fit-content-imp",
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots",
                    "variant": "#393939",
                    "font-scale": "1.4"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            on: {
              "click": function ($event) {
                return _vm.load({
                  callInfo: call_history
                }, true);
              }
            }
          }, [_c('vb-icon', {
            staticClass: "mr-16px",
            attrs: {
              "icon": "callInfoModal-addContacts-icon",
              "width": "17.004px",
              "height": "17.72px"
            }
          }), _vm._v(" Call info ")], 1)], 1)];
        },
        proxy: true
      }], null, true)
    })], 1)];
  }), _vm.api.history.send || _vm.isEmpty(_vm.response.history) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.history.send ? '' : 'There is no history',
      "design": 3
    }
  }, [_vm.api.history.send ? _c('template', {
    slot: "loading"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      class: `latestShimmerDesign w-75 mb-4 ${n % 2 == 1 ? 'ml-auto' : ''}`,
      staticStyle: {
        "height": "80px"
      }
    });
  }), 0) : _vm._e()], 2) : _vm._e()], 2)])] : _vm.selected.tab == _vm.tabs.block ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        !_vm.getUserPermissions.block_and_privacy ? '' : _vm.blocknumber();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 w-100"
  }, [_c('label', [_vm._v("Reason for blocking")]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.block_and_privacy
    },
    attrs: {
      "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-input', {
    attrs: {
      "disabled": _vm.api.block_number.send || !_vm.getUserPermissions.block_and_privacy
    },
    model: {
      value: _vm.forms.block_number.displayname,
      callback: function ($$v) {
        _vm.$set(_vm.forms.block_number, "displayname", $$v);
      },
      expression: "forms.block_number.displayname"
    }
  })], 1), _vm.forms.block_number.submitted && _vm.$v.forms.block_number.displayname.$invalid || _vm.api.block_number.validation_errors.displayname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.block_number.displayname.required ? _c('span', [_vm._v("* First name is required")]) : _vm._e(), _vm._l(_vm.api.block_number.validation_errors.displayname, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  })], 2) : _vm._e()])], 1)]), _c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px"
  }, [_vm._v("Direction of number block")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "align-items-center justify-content-between d-flex w-100 bigSquareCheckbox",
    class: {
      'for-disable w-fit-content': !_vm.getUserPermissions.block_and_privacy
    },
    attrs: {
      "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Block inbound calls")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.block_number.send || !_vm.getUserPermissions.block_and_privacy
    },
    model: {
      value: _vm.forms.block_number.inbound,
      callback: function ($$v) {
        _vm.$set(_vm.forms.block_number, "inbound", $$v);
      },
      expression: "forms.block_number.inbound"
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "align-items-center justify-content-between d-flex w-100 bigSquareCheckbox",
    class: {
      'for-disable w-fit-content': !_vm.getUserPermissions.block_and_privacy
    },
    attrs: {
      "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Block otbound calls")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "variant": "black",
      "disabled": _vm.api.block_number.send || !_vm.getUserPermissions.block_and_privacy
    },
    model: {
      value: _vm.forms.block_number.outbound,
      callback: function ($$v) {
        _vm.$set(_vm.forms.block_number, "outbound", $$v);
      },
      expression: "forms.block_number.outbound"
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable w-fit-content': !_vm.getUserPermissions.block_and_privacy
    },
    attrs: {
      "title": _vm.getUserPermissions.block_and_privacy ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.block_number.send || !_vm.getUserPermissions.block_and_privacy
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.block_number.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1)], 1)])], 1)])], 1)] : _vm.selected.tab == _vm.tabs.AddContacts ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_vm.api.add_contact.status == 1 ? _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updatecontact();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 pr-2 mb-0"
  }, [_c('label', [_vm._v("First Name")]), _c('b-input', {
    attrs: {
      "placeholder": "First name",
      "disabled": _vm.api.edit_contact.send,
      "maxlength": _vm.$v.forms.edit_contact.firstname.$params.maxLength.max
    },
    model: {
      value: _vm.forms.edit_contact.firstname,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_contact, "firstname", $$v);
      },
      expression: "forms.edit_contact.firstname"
    }
  }), _vm.forms.edit_contact.submitted && _vm.$v.forms.edit_contact.firstname.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.edit_contact.firstname.required ? _c('span', [_vm._v("* First name is required")]) : !_vm.$v.forms.edit_contact.firstname.maxLength ? _c('span', [_vm._v("* First name should be minimum " + _vm._s(_vm.$v.forms.edit_contact.firstname.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm.api.edit_contact.validation_errors.firstname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.edit_contact.validation_errors.firstname, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 pl-2 mb-0"
  }, [_c('label', [_vm._v("Last name")]), _c('b-input', {
    attrs: {
      "placeholder": "Last name",
      "disabled": _vm.api.edit_contact.send,
      "maxlength": _vm.$v.forms.edit_contact.lastname.$params.maxLength.max
    },
    model: {
      value: _vm.forms.edit_contact.lastname,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_contact, "lastname", $$v);
      },
      expression: "forms.edit_contact.lastname"
    }
  }), _vm.forms.edit_contact.submitted && _vm.$v.forms.edit_contact.lastname.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.edit_contact.lastname.maxLength ? _c('span', [_vm._v("* Last name can be maximum " + _vm._s(_vm.$v.forms.edit_contact.lastname.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm.api.edit_contact.validation_errors.lastname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.edit_contact.validation_errors.lastname, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1)], 1), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px mb-0"
  }, [_c('label', [_vm._v("Company")]), _c('b-input', {
    attrs: {
      "placeholder": "Company",
      "disabled": _vm.api.edit_contact.send
    },
    model: {
      value: _vm.forms.edit_contact.company,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_contact, "company", $$v);
      },
      expression: "forms.edit_contact.company"
    }
  }), _vm.forms.edit_contact.submitted && _vm.$v.forms.edit_contact.company.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.edit_contact.company.minLength ? _c('span', [_vm._v("* Company should be minimum " + _vm._s(_vm.$v.forms.edit_contact.company.$params.minLength.min) + " character")]) : _vm._e()]) : _vm.api.edit_contact.validation_errors.company ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.edit_contact.validation_errors.company, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1), _c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px"
  }, [_vm._v("Phones")]), _vm._l(_vm.forms.edit_contact.phones, function (phone, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex align-items-end"
    }, [_c('div', {
      staticClass: "flex-fill"
    }, [_c('div', {
      staticClass: "d-flex w-100 mt-20px"
    }, [_c('b-form-group', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2 mb-0"
    }, [_c('label', [_vm._v("Type")]), _c('b-select', {
      attrs: {
        "disabled": _vm.api.edit_contact.send
      },
      model: {
        value: phone.phone,
        callback: function ($$v) {
          _vm.$set(phone, "phone", $$v);
        },
        expression: "phone.phone"
      }
    }, _vm._l(_vm.phoneTypeOptions, function (option) {
      return _c('b-select-option', {
        key: option.value,
        attrs: {
          "value": option.value
        }
      }, [_vm._v(_vm._s(option.text))]);
    }), 1), _vm.forms.edit_contact.submitted && _vm.$v.forms.edit_contact.phones.$each[index].phone.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop mb-0"
    }, [!_vm.$v.forms.edit_contact.phones.$each[index].phone.required ? _c('span', [_vm._v("* Type is required")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2 mb-0"
    }, [_c('label', [_vm._v("Short code")]), _c('b-select', {
      attrs: {
        "disabled": _vm.api.edit_contact.send,
        "options": phone.shortcode ? [phone.shortcode].concat(_vm.filterShortCodes) : _vm.filterShortCodes
      },
      model: {
        value: phone.shortcode,
        callback: function ($$v) {
          _vm.$set(phone, "shortcode", $$v);
        },
        expression: "phone.shortcode"
      }
    }), _vm.forms.edit_contact.submitted && _vm.$v.forms.edit_contact.phones.$each[index].shortcode.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop mb-0"
    }, [!_vm.$v.forms.edit_contact.phones.$each[index].shortcode.required ? _c('span', [_vm._v("* Short code is required")]) : _vm._e()]) : _vm._e()], 1)], 1), _c('b-form-group', {
      staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-20px mb-0"
    }, [_c('label', [_vm._v("Number")]), _c('b-input', {
      directives: [{
        name: "vb-input-number-formate",
        rawName: "v-vb-input-number-formate"
      }],
      attrs: {
        "disabled": _vm.api.edit_contact.send
      },
      model: {
        value: phone.number,
        callback: function ($$v) {
          _vm.$set(phone, "number", $$v);
        },
        expression: "phone.number"
      }
    }), _vm.forms.edit_contact.submitted && _vm.$v.forms.edit_contact.phones.$each[index].number.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop mb-0"
    }, [!_vm.$v.forms.edit_contact.phones.$each[index].number.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.edit_contact.phones.$each[index].number.validNumber ? _c('span', [_vm._v("* Number is invalid")]) : _vm._e()]) : _vm._e()], 1)], 1), _vm.forms.edit_contact.phones.length > 1 ? _c('div', {
      staticClass: "ml-3 mb-2",
      on: {
        "click": function ($event) {
          _vm.forms.edit_contact.phones.length > 1 ? _vm.forms.edit_contact.phones.splice(index, 1) : '';
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "trash",
        "font-scale": "1.8",
        "variant": "danger"
      }
    })], 1) : _vm._e()]);
  }), _vm.forms.edit_contact.submitted && _vm.$v.forms.edit_contact.phones.$invalid ? _c('p', {
    staticClass: "latestGreyBox-descriptionText"
  }, [!_vm.$v.forms.edit_contact.phones.required ? _c('span', [_vm._v("* Phones is required")]) : !_vm.$v.forms.edit_contact.phones.minLength ? _c('span', [_vm._v("* Phones should be minimum " + _vm._s(_vm.$v.forms.edit_contact.phones.$params.minLength.min) + " required")]) : _vm._e()]) : _vm.api.edit_contact.validation_errors.phones ? _c('p', {
    staticClass: "latestGreyBox-descriptionText"
  }, _vm._l(_vm.api.edit_contact.validation_errors.phones, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-20px"
  }, [_vm.forms.edit_contact.phones.length <= 10 ? _c('div', {
    staticClass: "dialer-button-primary dialer-button",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        _vm.api.shortcode.send ? '' : _vm.forms.edit_contact.phones.push({
          phone: '',
          shortcode: _vm.firstOption,
          number: ''
        });
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "plus-circle-fill",
      "variant": "white"
    }
  }), _c('span', [_vm._v("Add phone")])], 1) : _vm._e()]), _vm.conditions.show_more_edit_contact ? [_c('div', {
    staticClass: "d-flex mt-20px w-100"
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 mb-0 pr-2"
  }, [_c('label', [_vm._v("Job title")]), _c('b-input', {
    attrs: {
      "disabled": _vm.api.edit_contact.send
    },
    model: {
      value: _vm.forms.edit_contact.jobtitle,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_contact, "jobtitle", $$v);
      },
      expression: "forms.edit_contact.jobtitle"
    }
  }), _vm.forms.edit_contact.submitted && _vm.$v.forms.edit_contact.jobtitle.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.edit_contact.jobtitle.minLength ? _c('span', [_vm._v("* jobtitle should be minimum " + _vm._s(_vm.$v.forms.edit_contact.jobtitle.$params.minLength.min) + " character")]) : _vm._e()]) : _vm.api.edit_contact.validation_errors.jobtitle ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.edit_contact.validation_errors.jobtitle, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 mb-0 pl-2"
  }, [_c('label', [_vm._v("Department")]), _c('b-input', {
    attrs: {
      "disabled": _vm.api.edit_contact.send
    },
    model: {
      value: _vm.forms.edit_contact.department,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_contact, "department", $$v);
      },
      expression: "forms.edit_contact.department"
    }
  }), _vm.forms.edit_contact.submitted && _vm.$v.forms.edit_contact.department.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.edit_contact.department.minLength ? _c('span', [_vm._v("* department should be minimum " + _vm._s(_vm.$v.forms.edit_contact.department.$params.minLength.min) + " character")]) : _vm._e()]) : _vm.api.edit_contact.validation_errors.department ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.edit_contact.validation_errors.department, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1)], 1), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px mb-0 dropdownVersion"
  }, [_c('label', [_vm._v("Suffix")]), _c('b-select', {
    attrs: {
      "disabled": _vm.api.edit_contact.send
    },
    model: {
      value: _vm.forms.edit_contact.suffix,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_contact, "suffix", $$v);
      },
      expression: "forms.edit_contact.suffix"
    }
  }, _vm._l(_vm.sufixOptions, function (option) {
    return _c('b-select-option', {
      key: option.value,
      attrs: {
        "value": option.value
      }
    }, [_vm._v(_vm._s(option.text))]);
  }), 1), _vm.api.edit_contact.validation_errors.suffix ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.edit_contact.validation_errors.suffix, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1), _c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px"
  }, [_vm._v("Emails")]), _c('b-form-group', {
    staticClass: "mb-0"
  }, [_vm._l(_vm.forms.edit_contact.emails, function (emails, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex align-items-end"
    }, [_c('b-form-group', {
      staticClass: "whiteBGinputWithGreyRoundedBorder flex-fill mt-20px mb-0"
    }, [_c('label', [_vm._v("Email")]), _c('b-input', {
      attrs: {
        "disabled": _vm.api.edit_contact.send,
        "type": "email"
      },
      model: {
        value: emails.emails,
        callback: function ($$v) {
          _vm.$set(emails, "emails", $$v);
        },
        expression: "emails.emails"
      }
    }), _vm.forms.edit_contact.submitted && _vm.$v.forms.edit_contact.emails.$each[index].emails.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop mb-0"
    }, [!_vm.$v.forms.edit_contact.emails.$each[index].emails.email ? _c('span', [_vm._v("* Email is invalid")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
      staticClass: "ml-3 mb-2",
      on: {
        "click": function ($event) {
          _vm.forms.edit_contact.emails.length > 1 ? _vm.forms.edit_contact.emails.splice(index, 1) : '';
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "trash",
        "font-scale": "1.8",
        "variant": "danger"
      }
    })], 1)], 1);
  }), _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-20px"
  }, [_vm.forms.edit_contact.emails.length <= 10 ? _c('div', {
    staticClass: "dialer-button-primary dialer-button",
    staticStyle: {
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.forms.edit_contact.emails.push({
          emails: ''
        });
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "plus-circle-fill",
      "variant": "white"
    }
  }), _c('span', [_vm._v("Add email")])], 1) : _vm._e()])], 2), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px mb-0"
  }, [_c('label', [_vm._v("Note")]), _c('b-textarea', {
    attrs: {
      "disabled": _vm.api.edit_contact.send
    },
    model: {
      value: _vm.forms.edit_contact.note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_contact, "note", $$v);
      },
      expression: "forms.edit_contact.note"
    }
  }), _vm.api.edit_contact.validation_errors.note ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.edit_contact.validation_errors.note, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1)] : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-center mt-20px",
    on: {
      "click": function ($event) {
        _vm.conditions.show_more_edit_contact = !_vm.conditions.show_more_edit_contact;
      }
    }
  }, [_c('div', {
    staticClass: "font-weight-700 dialer-button-primary dialer-button"
  }, [_vm._v(_vm._s(_vm.conditions.show_more_edit_contact ? 'Hide' : 'Show'))])])], 2) : _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addcontact();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder pr-2 w-50 mb-0"
  }, [_c('label', [_vm._v("First name")]), _c('b-input', {
    attrs: {
      "placeholder": "First name",
      "disabled": _vm.api.add_contact.send,
      "maxlength": _vm.$v.forms.add_contact.firstname.$params.maxLength.max
    },
    model: {
      value: _vm.forms.add_contact.firstname,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_contact, "firstname", $$v);
      },
      expression: "forms.add_contact.firstname"
    }
  }), _vm.forms.add_contact.submitted && _vm.$v.forms.add_contact.firstname.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_contact.firstname.required ? _c('span', [_vm._v("* First name is required")]) : !_vm.$v.forms.add_contact.firstname.maxLength ? _c('span', [_vm._v("* First name should be minimum " + _vm._s(_vm.$v.forms.add_contact.firstname.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm.api.add_contact.validation_errors.firstname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.add_contact.validation_errors.firstname, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder pl-2 w-50 mb-0"
  }, [_c('label', [_vm._v("Last name")]), _c('b-input', {
    attrs: {
      "placeholder": "Last name",
      "disabled": _vm.api.add_contact.send,
      "maxlength": _vm.$v.forms.add_contact.lastname.$params.maxLength.max
    },
    model: {
      value: _vm.forms.add_contact.lastname,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_contact, "lastname", $$v);
      },
      expression: "forms.add_contact.lastname"
    }
  }), _vm.forms.add_contact.submitted && _vm.$v.forms.add_contact.lastname.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_contact.lastname.maxLength ? _c('span', [_vm._v("* Last name can be maximum " + _vm._s(_vm.$v.forms.add_contact.lastname.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm.api.add_contact.validation_errors.lastname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.add_contact.validation_errors.lastname, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1)], 1), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px mb-0"
  }, [_c('label', [_vm._v("Company")]), _c('b-input', {
    attrs: {
      "placeholder": "Company",
      "disabled": _vm.api.add_contact.send
    },
    model: {
      value: _vm.forms.add_contact.company,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_contact, "company", $$v);
      },
      expression: "forms.add_contact.company"
    }
  }), _vm.forms.add_contact.submitted && _vm.$v.forms.add_contact.company.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_contact.company.minLength ? _c('span', [_vm._v("* Company should be minimum " + _vm._s(_vm.$v.forms.add_contact.company.$params.minLength.min) + " character")]) : _vm._e()]) : _vm.api.add_contact.validation_errors.company ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.add_contact.validation_errors.company, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1), _c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px"
  }, [_vm._v("Phones")]), _c('b-form-group', [_vm._l(_vm.forms.add_contact.phones, function (phone, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex align-items-end"
    }, [_c('div', {
      staticClass: "flex-fill"
    }, [_c('div', {
      staticClass: "d-flex w-100 mt-20px"
    }, [_c('b-form-group', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-50 pr-2"
    }, [_c('label', [_vm._v("Type")]), _c('b-select', {
      attrs: {
        "disabled": _vm.api.add_contact.send
      },
      model: {
        value: phone.phone,
        callback: function ($$v) {
          _vm.$set(phone, "phone", $$v);
        },
        expression: "phone.phone"
      }
    }, _vm._l(_vm.phoneTypeOptions, function (option) {
      return _c('b-select-option', {
        key: option.value,
        attrs: {
          "value": option.value
        }
      }, [_vm._v(_vm._s(option.text))]);
    }), 1), _vm.forms.add_contact.submitted && _vm.$v.forms.add_contact.phones.$each[index].phone.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop mb-0"
    }, [!_vm.$v.forms.add_contact.phones.$each[index].phone.required ? _c('span', [_vm._v("* Type is required")]) : _vm._e()]) : _vm._e()], 1), _c('b-form-group', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-50 pl-2"
    }, [_c('label', [_vm._v("Short code")]), _c('b-select', {
      attrs: {
        "disabled": _vm.api.add_contact.send,
        "options": phone.shortcode ? [phone.shortcode].concat(_vm.filterShortCodes) : _vm.filterShortCodes
      },
      model: {
        value: phone.shortcode,
        callback: function ($$v) {
          _vm.$set(phone, "shortcode", $$v);
        },
        expression: "phone.shortcode"
      }
    }), _vm.forms.add_contact.submitted && _vm.$v.forms.add_contact.phones.$each[index].shortcode.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop mb-0"
    }, [!_vm.$v.forms.add_contact.phones.$each[index].shortcode.required ? _c('span', [_vm._v("* Short Code is required")]) : _vm._e()]) : _vm._e()], 1)], 1), _c('b-form-group', {
      staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 mt-20px"
    }, [_c('label', [_vm._v("Number")]), _c('b-input', {
      directives: [{
        name: "vb-input-number-formate",
        rawName: "v-vb-input-number-formate"
      }],
      attrs: {
        "disabled": _vm.api.add_contact.send
      },
      model: {
        value: phone.number,
        callback: function ($$v) {
          _vm.$set(phone, "number", $$v);
        },
        expression: "phone.number"
      }
    }), _vm.forms.add_contact.submitted && _vm.$v.forms.add_contact.phones.$each[index].number.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop mb-0"
    }, [!_vm.$v.forms.add_contact.phones.$each[index].number.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.add_contact.phones.$each[index].number.validNumber ? _c('span', [_vm._v("* Number is invalid")]) : _vm._e()]) : _vm._e()], 1)], 1), _vm.forms.add_contact.phones.length > 1 ? _c('div', {
      staticClass: "ml-3 mb-2",
      on: {
        "click": function ($event) {
          _vm.forms.add_contact.phones.length > 1 ? _vm.forms.add_contact.phones.splice(index, 1) : '';
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "trash",
        "font-scale": "1.8",
        "variant": "danger"
      }
    })], 1) : _vm._e()]);
  }), _vm.forms.add_contact.submitted && _vm.$v.forms.add_contact.phones.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_contact.phones.required ? _c('span', [_vm._v("* Phones is required")]) : !_vm.$v.forms.add_contact.phones.minLength ? _c('span', [_vm._v("* Phones should be minimum " + _vm._s(_vm.$v.forms.add_contact.phones.$params.minLength.min) + " required")]) : _vm._e()]) : _vm.api.add_contact.validation_errors.phones ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.add_contact.validation_errors.phones, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-20px"
  }, [_vm.forms.add_contact.phones.length <= 10 ? _c('div', {
    staticClass: "dialer-button-primary dialer-button",
    on: {
      "click": function ($event) {
        _vm.api.shortcode.send ? '' : _vm.forms.add_contact.phones.push({
          phone: '',
          shortcode: _vm.firstOption,
          number: ''
        });
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "plus-circle-fill",
      "variant": "white"
    }
  }), _c('span', [_vm._v("Add phone")])], 1) : _vm._e()])], 2), _vm.conditions.show_more_add_contact ? [_c('div', {
    staticClass: "d-flex w-100 mt-20px"
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder pr-2 w-50 mb-0"
  }, [_c('label', [_vm._v("Job title")]), _c('b-input', {
    attrs: {
      "disabled": _vm.api.add_contact.send
    },
    model: {
      value: _vm.forms.add_contact.jobtitle,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_contact, "jobtitle", $$v);
      },
      expression: "forms.add_contact.jobtitle"
    }
  }), _vm.forms.add_contact.submitted && _vm.$v.forms.add_contact.jobtitle.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_contact.jobtitle.minLength ? _c('span', [_vm._v("* jobtitle should be minimum " + _vm._s(_vm.$v.forms.add_contact.jobtitle.$params.minLength.min) + " character")]) : _vm._e()]) : _vm.api.add_contact.validation_errors.jobtitle ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.add_contact.validation_errors.jobtitle, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder pl-2 w-50 mb-0"
  }, [_c('label', [_vm._v("Department")]), _c('b-input', {
    attrs: {
      "disabled": _vm.api.add_contact.send
    },
    model: {
      value: _vm.forms.add_contact.department,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_contact, "department", $$v);
      },
      expression: "forms.add_contact.department"
    }
  }), _vm.forms.add_contact.submitted && _vm.$v.forms.add_contact.department.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_contact.department.minLength ? _c('span', [_vm._v("* department should be minimum " + _vm._s(_vm.$v.forms.add_contact.department.$params.minLength.min) + " character")]) : _vm._e()]) : _vm.api.add_contact.validation_errors.department ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.add_contact.validation_errors.department, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1)], 1), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 mt-20px dropdownVersion"
  }, [_c('label', [_vm._v("Suffix")]), _c('b-select', {
    attrs: {
      "disabled": _vm.api.add_contact.send
    },
    model: {
      value: _vm.forms.add_contact.suffix,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_contact, "suffix", $$v);
      },
      expression: "forms.add_contact.suffix"
    }
  }, _vm._l(_vm.sufixOptions, function (option) {
    return _c('b-select-option', {
      key: option.value,
      attrs: {
        "value": option.value
      }
    }, [_vm._v(_vm._s(option.text))]);
  }), 1), _vm.api.add_contact.validation_errors.suffix ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.add_contact.validation_errors.suffix, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1), _c('div', {
    staticClass: "latestGreyBox-heading-main mt-20px"
  }, [_vm._v("Emails")]), _c('b-form-group', [_vm._l(_vm.forms.add_contact.emails, function (emails, index) {
    return _c('div', {
      key: index,
      staticClass: "d-flex align-items-end"
    }, [_c('b-form-group', {
      staticClass: "whiteBGinputWithGreyRoundedBorder flex-fill mb-0 mt-20px"
    }, [_c('label', [_vm._v("Email")]), _c('b-input', {
      attrs: {
        "disabled": _vm.api.add_contact.send,
        "type": "email"
      },
      model: {
        value: emails.emails,
        callback: function ($$v) {
          _vm.$set(emails, "emails", $$v);
        },
        expression: "emails.emails"
      }
    }), _vm.forms.add_contact.submitted && _vm.$v.forms.add_contact.emails.$each[index].emails.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop mb-0"
    }, [!_vm.$v.forms.add_contact.emails.$each[index].emails.email ? _c('span', [_vm._v("* Email is invalid")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
      staticClass: "ml-3 mb-2",
      on: {
        "click": function ($event) {
          _vm.forms.add_contact.emails.length > 1 ? _vm.forms.add_contact.emails.splice(index, 1) : '';
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "trash",
        "font-scale": "1.8",
        "variant": "danger"
      }
    })], 1)], 1);
  }), _vm.api.add_contact.validation_errors.emails ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.add_contact.validation_errors.emails, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-20px"
  }, [_vm.forms.add_contact.emails.length <= 10 ? _c('div', {
    staticClass: "dialer-button-primary dialer-button",
    on: {
      "click": function ($event) {
        return _vm.forms.add_contact.emails.push({
          emails: ''
        });
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "plus-circle-fill",
      "variant": "white"
    }
  }), _c('span', [_vm._v("Add email")])], 1) : _vm._e()])], 2), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 mt-20px"
  }, [_c('label', [_vm._v("Note")]), _c('b-textarea', {
    attrs: {
      "disabled": _vm.api.add_contact.send
    },
    model: {
      value: _vm.forms.add_contact.note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_contact, "note", $$v);
      },
      expression: "forms.add_contact.note"
    }
  }), _vm.api.add_contact.validation_errors.note ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.add_contact.validation_errors.note, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()], 1)] : _vm._e(), _c('b-form-group', {
    staticClass: "mb-0 mt-20px"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Save as global")]), _c('b-check', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.add_contact.send
    },
    model: {
      value: _vm.forms.add_contact.is_global,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_contact, "is_global", $$v);
      },
      expression: "forms.add_contact.is_global"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex justify-content-center mt-20px",
    on: {
      "click": function ($event) {
        _vm.conditions.show_more_add_contact = !_vm.conditions.show_more_add_contact;
      }
    }
  }, [_c('div', {
    staticClass: "font-weight-700 dialer-button-primary dialer-button"
  }, [_vm._v(_vm._s(_vm.conditions.show_more_add_contact ? 'Hide' : 'Show'))])]), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('b-form-group', {
    staticClass: "w-auto"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.add_contact.send,
      "type": "submit"
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.add_contact.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1)], 1)], 1)], 2)], 1)] : _vm.selected.tab == _vm.tabs.task ? [_c('EditTasksModal', {
    attrs: {
      "propTaskID": _vm.call.isTaskAssigned.id
    }
  })] : _vm._e()], 2)]), _c('UpdateCallNotesAndTags', {
    attrs: {
      "modalName": `${_vm._uid}-UpdateCallNotesAndTags`,
      "callID": _vm.callID
    },
    on: {
      "note-updated": function ($event) {
        _vm.data.note = $event.note;
        _vm.$emit('latest-record');
        _vm.$emit('updated');
      },
      "tags-updated": function ($event) {
        _vm.data.tag_ids = $event.tag_ids;
        _vm.$emit('latest-record');
        _vm.$emit('updated');
      }
    }
  })], 1)])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }