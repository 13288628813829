<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.edit">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.delete_sound.send ? '' : conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Sound File</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">

          <b-alert class="mt-32px" :show="!!api.edit_sound.error_message" variant="danger">{{api.edit_sound.error_message}}</b-alert>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Name</label>
                <input placeholder="Enter Name" class="w-100" type="text" :disabled="api.edit_sound.send || api.delete_sound.send" v-model="forms.edit_sound.name" @input="editSound()" />
                <p v-if="(forms.edit_sound.submitted && $v.forms.edit_sound.name.$invalid) || api.edit_sound.validation_errors.name" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.edit_sound.name.required">* Name is required</span>
                  <span v-for="(em, i) in api.edit_sound.validation_errors.name" :key="i">* {{em}}</span>
                </p>
              </div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.delete_sound.send ? '' : $modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Sound File</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">
          
          <div class="basicWhiteIOScard mt-32px mb-16px">
            
            <div class="basicWhiteIOScard-item d-flex justify-content-between">
              <div class="headingInsideTable">Name</div> 
              <div class="textInsideCard onRightSide">{{ data.sound.label }}</div>
            </div>
            
            <div class="basicWhiteIOScard-item d-flex justify-content-between">
              <div class="headingInsideTable">Audio</div>
              <vb-audio-player class="IosAudioPlayer" :src="data.sound.sound_file" />
            </div>

            <div class="basicWhiteIOScard-item d-flex justify-content-between">
              <div class="headingInsideTable">Status</div>
              <div class="textInsideCard onRightSide">{{ data.sound.status }}</div>
            </div>

            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Shared</div>
                </div>
              </div>
              <div class="position-relative">
                <b-form-checkbox 
                  ref="shared_check_box"
                  class="dialer-switch IosSwitch" 
                  :disabled="api.shared_sound.send || api.delete_sound.send" 
                  :checked="forms.edit_sound_shared.shared"
                  @change="editSharedSound()"
                  switch 
                />  
              </div>
            </div>

          </div>

          
          <button class="IosFullWidthButton forRemove mb-16px" :disabled="api.delete_sound.send" @click="deleteSound()">
            <vb-spinner v-if="api.delete_sound.send" />
            <template v-else>Delete</template>
          </button>

        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import _ from 'lodash';

import { VOIP_API } from '@/utils';

let screens = {
  edit: 'edit',
  view: 'view',
}

export default {
  name: "EditSoundFileModal",
  props:{
    modalName: {
      type: String,
      default: 'EditSoundFile'
    },
  },
  data() {
    return {
      forms: {
        edit_sound: this.$helperFunction.formInstance({
          data: {
            name: '',
          },
        }),
        edit_sound_shared: this.$helperFunction.formInstance({
          data: {
            shared: '',
          },
        }),
      },
      api: {
        edit_sound: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true,  }),
        shared_sound: this.$helperFunction.apiInstance({  }),
        delete_sound: this.$helperFunction.apiInstance({  }),
      },
      data: {
        sound: {},
      },
      conditions: {
        screen: screens.view
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser'
    ]),
    screens(){ return screens },
  },
  validations: {
    forms: {
      edit_sound: {
        name: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event) {
      const sound = event?.params?.sound ?? {}
      this.data.sound = sound
      this.forms.edit_sound.name=sound?.label ?? ''
      this.forms.edit_sound_shared.shared=!!sound?.shared
    },
    onBeforeClose() {
      this.forms.edit_sound.reset()
      this.forms.edit_sound_shared.reset()
      this.api.edit_sound.reset()
      this.api.shared_sound.reset()
      this.api.delete_sound.reset()
      this.data.sound={}
      this.conditions.screen=screens.view
    },
    deleteSound(){
      let vm = this;
      if (vm.api.delete_sound.send) return;
      vm.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to delete this file.`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback({confirm}) {
          if (confirm) {
            vm.api.delete_sound.send=true;
            VOIP_API.endpoints.recording.delete(vm.data.sound.id)
            .then(() => {
              vm.appNotify({
                message: "Successfully Deleted",
                type: "success",
              })
              vm.$emit('update-list')
              vm.$modal.hide(vm.modalName)
            })
            .catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(() => {
              vm.api.delete_sound.send=false
            });
          }
        },
      });
    },
    editSound: _.debounce(function (){
      let vm = this
      vm.forms.edit_sound.submitted=true
      vm.$v.forms.edit_sound.$touch();
      if (vm.$v.forms.edit_sound.$invalid || vm.api.edit_sound.send) return;
      vm.api.edit_sound.send = true
      vm.api.edit_sound.error_message = ''
      VOIP_API.endpoints.recording.updateName(vm.data.sound.id,{
        accountcode: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
        name: vm.forms.edit_sound.name,
        flag: 'portal_sound_update'
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully updated!',
          type: 'success',
        })
        vm.$emit('update-list')
      })
      .catch((ex)=>{
        vm.api.edit_sound.error_message = ex.own_message
      })
      .finally(()=>{
        vm.forms.edit_sound.submitted = false
        vm.api.edit_sound.send = false
      })
    }, 2 * 1000),
    editSharedSound(){
      let vm = this;
      if (vm.api.shared_sound.send) return;
      vm.api.shared_sound.send = true;
      VOIP_API.endpoints.recording.update({
        id: vm.data.sound.id,
      })
      .then(() => {
        vm.forms.edit_sound_shared.shared=!vm.forms.edit_sound_shared.shared
        vm.$emit('update-list')
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.$refs['shared_check_box'].localChecked=!!vm.forms.edit_sound_shared.shared
        vm.api.shared_sound.send = false;
      });
    },
  },
};
</script>

<style>
/**
 * FilePond Custom Styles
 */
.filepond--drop-label {
  color: #4c4e53;
}

.filepond--label-action {
  text-decoration-color: #babdc0;
}

.filepond--panel-root {
  border-radius: 2em;
  background-color: #edf0f4;
  height: 1em;
}

.filepond--item-panel {
  background-color: #595e68;
}

.filepond--drip-blob {
  background-color: #7f8a9a;
}
/* [data-filepond-item-state="processing-complete"] .filepond--file-status-sub, */
.filepond--file-status .filepond--file-status-sub,
button.filepond--file-action-button.filepond--action-revert-item-processing {
  display: none;
}
</style>
