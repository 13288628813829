var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup new-call-info-modal new-call-info-modal-newer v2",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "panel-inner panel-inner-callInfo new-call-info-inner"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-between py-0 mb-0 dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Audio transcript")]), _c('div', [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _vm.data.sound_file && _vm.data.uniq_id ? _c('AudioTranscriptDummy', {
    staticClass: "mt-20px",
    attrs: {
      "soundFile": _vm.data.sound_file,
      "uniqueID": _vm.data.uniq_id
    }
  }) : _vm._e()], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }