<template>
  <modal 
    :name="modalName" 
    class="center-zoomIn-transition v2 m-w-500 addTagsModal-centerVersion onlyTagsModal scrollable"
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()" 
  >
    <div class="w-100">
      <div class="dialer-edit-header position-relative d-block pb-0">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h2 class="dialer-settings-title newer mb-0">Add status</h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            <a v-else class="newCloseButton" type="button" @click="$modal.hide(modalName)" >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <div class="vm--modal-text mb-0">
          Add status name and details.
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <b-form @submit.prevent="create()" class="dialer-form w-100">
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
              <label>Status name</label>
              <b-input class="" type="text" v-model="forms.create.text" :disabled="api.create.send" placeholder="Enter name" />
              <p 
                v-if="(forms.create.submitted && $v.forms.create.text.$invalid) || api.create.validation_errors.text" 
                class="mb-1 text-small text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.create.text.required">* Name is required</span>
                <span v-for="em in api.create.validation_errors.text" :key="em">* {{ em }}</span>
              </p>
            </div>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder mt-20px">
            <label>Status color</label>
            <div class="createSpace-selectColor-container">
              <template v-if="api.fetch_color_combination.send">
                <div v-for="n in 32" :key="n.id" class="createSpace-selectColor-item-container">
                  <div class="latestShimmerDesign" style="height: 30px;width: 30px;"></div>
                </div>
              </template>
              <template v-else>
                <div v-for="color in colorComboOptions" :key="color.id" class="createSpace-selectColor-item-container">
                  <div 
                    @click="api.create.send ? '' : forms.create.color_combo_id = color.value;" 
                    class="createSpace-selectColor-item"
                    :class="{
                      'selected': forms.create.color_combo_id==color.value,
                      'TransparentSelected': color.color == 'rgba(0,0,0,0)',
                    }"
                    :style="`background-color:${color.color};`">
                    <vb-icon v-if="color.color == 'rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
                  </div>
                </div>
              </template>
            </div>
            <p v-if="forms.create.submitted && $v.forms.create.color_combo_id.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
              <span v-if="!$v.forms.create.color_combo_id.required">* Status color is required</span>
            </p>
          </div>
          <!-- <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
              <label>Select color combination</label>
              <vb-select
                :defaultSelectedText="'Select color combination'"
                class="colorCombinationDD"
                :disabled="api.create.send || api.fetch_color_combination.send"
                :selected="forms.create.color_combo_id"
                @change="forms.create.color_combo_id = $event;"
                :options="colorComboOptions"
              />
              <p v-if="forms.create.submitted && $v.forms.create.color_combo_id.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                <span v-if="!$v.forms.create.color_combo_id.required">* Select color is required</span>
              </p>
            </div>
          </div> -->
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset">
              <div class="latestGreyBox-heading-main">Allow incoming calls</div>
              <b-form-checkbox class="newerSwitch" :disabled="api.create.send" :checked="forms.create.allow_call" @change="forms.create.allow_call=!forms.create.allow_call" switch>
                <!-- <span>Disallow Call</span> -->
              </b-form-checkbox>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end mt-4">
            <button class="fullWidthDoneButton" variant="success" :disabled="api.create.send">
              <vb-spinner v-if="api.create.send" />
              <template v-else>Add</template>
            </button>
          </div>
        </b-form>
      </div>











      <!--<div class="w-100">
        <div class="dialer-tab-content w-100">
          <!~~ <h2 class="dialer-edit-title">Add Status</h2> ~~>
          <b-form @submit.prevent="create()" class="dialer-form w-100">
            <div class="dialer-input-field input-consistent-inner-shadows w-100">
              <label>Status Name</label>
              <b-input class="" type="text" v-model="forms.create.text" :disabled="api.create.send" placeholder="Enter name" />
              <p 
                v-if="(forms.create.submitted && $v.forms.create.text.$invalid) || api.create.validation_errors.text" 
                class="mb-1 text-small text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.create.text.required">* Name is required</span>
                <span v-for="em in api.create.validation_errors.text" :key="em">* {{ em }}</span>
              </p>
            </div>
            <div class="dialer-input-field input-consistent-inner-shadows w-100 mt-3">
              <label>Select Color Combination</label>
              <vb-select
                :defaultSelectedText="'Select Color Combination'"
                class="colorCombinationDD"
                :disabled="api.create.send || api.fetch_color_combination.send"
                :selected="forms.create.color_combo_id"
                @change="forms.create.color_combo_id = $event;"
                :options="colorComboOptions"
              />
              
              <p v-if="forms.create.submitted && $v.forms.create.color_combo_id.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                <span v-if="!$v.forms.create.color_combo_id.required">* Select Color is required</span>
              </p>
            </div>
            <!~~<div class="dialer-input-group-2 align-items-start w-100">
              <vb-color-picker class="dialer-input-field input-consistent-inner-shadows color-picker-input" :value="forms.create.color" @change="forms.create.color = $event">
                <p slot="error" v-if="(is_submitted.create && $v.forms.create.color.$invalid) || errors.create.color || errors.create.colour" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.create.color.required">* Color is required</span>
                  <span v-else-if="!$v.forms.create.color.validColor">* Color is invalid</span>
                  <span v-for="em in errors.create.color" :key="em">* {{ em }}</span>
                  <span v-for="em in errors.create.colour" :key="em">* {{ em }}</span>
                </p>
              </vb-color-picker> 
            </div>~~>
            <div class="d-flex flex-column justify-content-start md-mar-top">
              <div class="allowCall">
                <b-form-checkbox class="dialer-switch" :disabled="api.create.send" :checked="!forms.create.allow_call" @change="forms.create.allow_call=!$event" switch>
                  <span>Disallow Call</span>
                </b-form-checkbox>
              </div>
              <div class="w-100 d-flex justify-content-end mt-3">
                <button class="dialer-button dialer-button-primary" variant="success" :disabled="api.create.send">
                  <vb-spinner v-if="api.create.send" />
                  <template v-else>Add</template>
                </button>
              </div>
            </div>
          </b-form>
        </div>
      </div>-->
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from "@/utils";
import { required } from "vuelidate/lib/validators";
// import validateColor from "validate-color";
import { mapGetters } from 'vuex';

export default {
  name: "AddStatusModal",
  props: {
    modalName: {
      type: String,
      default: 'AddStatus'
    },
  },
  data() {
    return {
      forms: {
        create: this.$helperFunction.formInstance({
          data: {
            text: "",
            color_combo_id: '',
            // color: "rgba(0,0,0,1)",
            allow_call: true,
          }
        }),
      },
      api: {
        create: this.$helperFunction.apiInstance({ 
          error_message: true,
          validation_errors: true,
        }),
        fetch_color_combination: this.$helperFunction.apiInstance(),
      },
      response: {
        color_combinations: [],
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
    colorComboOptions(){
      return this.response.color_combinations.map(i=>({
        text: i.label,
        value: i.id,
        color: i.color
      }))
    },
    selectedColorCombo(){ return this.response.color_combinations.find(i=>i.id==this.forms.create.color_combo_id) },
  },
  validations: {
    forms: {
      create: {
        text: {
          required,
        },
        color_combo_id: {
          required,
        },
        // color: {
        //   required,
        //   validColor: (value) => { return validateColor(value) },
        // },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      // console.log(this)
      this.colorCombinations()
    },
    onBeforeClose(){
      this.forms.create.reset()
      this.api.create.reset()
      this.response.color_combinations=[]
    },
    async create() {
      this.forms.create.submitted = true;
      this.$v.forms.create.$touch();
      if (this.$v.forms.create.$invalid || this.api.create.send) return;
      try {
        this.api.create.send = true;
        this.api.create.error_message=''
        this.api.create.validation_errors={}
        const { data: status } = await VOIP_API.endpoints.statuses.create({
          status: this.forms.create.text,
          color_combination_id: this.forms.create.color_combo_id,
          color: this.selectedColorCombo.color,
          colour: this.selectedColorCombo.color,
          allow_call: this.forms.create.allow_call,
          uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
        })
        this.$store.state.common.statuses.push({
          ...status,
          uid: null,
          allow_call: this.forms.create.allow_call,
        });
        this.$emit('created');
        this.$modal.hide(this.modalName)
        this.appNotify({
          message: 'Successfully created',
          type: 'success',
        })
      } catch (ex) {
        this.api.create.error_message=ex.own_message ?? ''
        this.api.create.validation_errors=ex.own_errors ?? {}
      } finally {
        this.forms.create.submitted = false;
        this.api.create.send = false;
      }
    },
    async colorCombinations(){
      if(this.api.fetch_color_combination.send) return;
      try {
        this.api.fetch_color_combination.send=true
        const { data: color_combinations } = await VOIP_API.endpoints.tags.colorCombinations()
        this.response.color_combinations=color_combinations ?? []
      } finally {
        this.api.fetch_color_combination.send=false
      }
    },
  },
}
</script>

<style>

</style>