var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.condition.screen == _vm.screens.number ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.condition.screen = _vm.screens.furtur_setting;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Seconds")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.forwarding_setting.forward_number,
      expression: "forms.forwarding_setting.forward_number"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Number",
      "onkeypress": "return /[0-9]/i.test(event.key)",
      "type": "text",
      "maxlength": _vm.$v.forms.forwarding_setting.forward_number.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.forwarding_setting.forward_number
    },
    on: {
      "change": function ($event) {
        return _vm.updateForwardingSetting();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.forwarding_setting, "forward_number", $event.target.value);
      }
    }
  }), _vm.forms.forwarding_setting.forward_note === 'number' && _vm.forms.forwarding_setting.submitted && _vm.$v.forms.forwarding_setting.forward_number.$invalid ? _c('p', {
    staticClass: "error-red"
  }, [!_vm.$v.forms.forwarding_setting.forward_number.required ? _c('span', [_vm._v("* number is required")]) : _vm._e(), !_vm.$v.forms.forwarding_setting.forward_number.minLength ? _c('span', [_vm._v(" * number should be minimum " + _vm._s(_vm.$v.forms.forwarding_setting.forward_number.$params.minLength.min) + " character ")]) : _vm._e(), !_vm.$v.forms.forwarding_setting.forward_number.maxLength ? _c('span', [_vm._v(" * number can be maximum " + _vm._s(_vm.$v.forms.forwarding_setting.forward_number.$params.maxLength.max) + " character ")]) : _vm._e()]) : _vm._e()])])])])])] : _vm.condition.screen == _vm.screens.select_seconds ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.condition.screen = _vm.screens.furtur_setting;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Seconds")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.seconds_options, function (second) {
    return _c('div', {
      key: second.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.forwarding_setting.timeout = second.value;
          _vm.condition.screen = _vm.screens.furtur_setting;
          _vm.updateForwardingSetting();
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v(_vm._s(second.text))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.forms.forwarding_setting.timeout == second.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1)]);
  }), 0)])])] : _vm.condition.screen == _vm.screens.furtur_setting ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.condition.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Call Forwarding")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm.condition.is_sec_show && _vm.forms.forwarding_setting.forward_note != 'off' ? _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.condition.screen = _vm.screens.select_seconds;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Seconds")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-16px"
  }, [_vm._v(_vm._s(_vm.forms.forwarding_setting.timeout) + " Seconds ")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]) : _vm._e(), _vm.forms.forwarding_setting.forward_note == 'number' ? _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.condition.screen = _vm.screens.number;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Number")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-16px"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.forms.forwarding_setting.forward_number)))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]) : _vm._e(), _vm.forms.forwarding_setting.forward_note == 'extension' ? _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.randomID}-CallForwarding`, {
          account: _vm.forms.forwarding_setting.forward_number,
          type: 'setting'
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 lign-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Extension")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-16px"
  }, [_vm._v(_vm._s(_vm.forms.forwarding_setting.extension_name))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.forwarding_setting.forward_note === 'extension' && _vm.forms.forwarding_setting.submitted && _vm.$v.forms.forwarding_setting.extension_name.$invalid ? _c('p', {
    staticClass: "error-red w-100 mb-0"
  }, [!_vm.$v.forms.forwarding_setting.extension_name.required ? _c('span', [_vm._v("* extension is required")]) : _vm._e()]) : _vm._e()]) : _vm._e()]), _c('SelectExtensionModal', {
    attrs: {
      "modalName": `${_vm.randomID}-CallForwarding`
    },
    on: {
      "selected": function ($event) {
        _vm.forms.forwarding_setting.extension_name = $event.accountname;
        _vm.forms.forwarding_setting.forward_number = $event.account;
        _vm.updateForwardingSetting();
      }
    }
  })], 1)])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Call Forwarding")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_vm.forms.forwarding_setting.forward_note == 'voicemail' ? [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm.condition.is_sec_show ? [_vm._v(" If phone rings for " + _vm._s(_vm.forms.forwarding_setting.timeout || 60) + " Seconds ")] : _vm._e(), _vm._v(" call will be forward to voicemail ")], 2)])] : _vm.forms.forwarding_setting.forward_note == 'extension' ? [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Redirect to a user, team or IVR menu")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm.condition.is_sec_show ? [_vm._v(" If phone rings for " + _vm._s(_vm.forms.forwarding_setting.timeout || 60) + " Seconds ")] : _vm._e(), _vm._v(" call will be forwarded to " + _vm._s(_vm.forms.forwarding_setting.extension_name || 'Select User') + " ")], 2), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)] : _vm.forms.forwarding_setting.forward_note == 'number' ? [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Redirect to another number")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm.condition.is_sec_show ? [_vm._v(" If phone rings for " + _vm._s(_vm.forms.forwarding_setting.timeout || 60) + " Seconds ")] : _vm._e(), _vm._v(" call will be forwarded to " + _vm._s(_vm._f("number_formater")(_vm.forms.forwarding_setting.forward_number || 'Add Number')) + " ")], 2)])] : [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Forwarding")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v("The phone will keep ringing")])])]], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm.condition.is_ringing_show ? _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.forms.forwarding_setting.forward_note = 'off';
        _vm.updateForwardingSetting();
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("OFF")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.condition.is_sec_show ? _vm.condition.screen = _vm.screens.furtur_setting : '';
        _vm.forms.forwarding_setting.forward_note = 'voicemail';
        _vm.updateForwardingSetting();
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.condition.screen = _vm.screens.furtur_setting;
        _vm.forms.forwarding_setting.forward_note = 'extension';
        _vm.updateForwardingSetting();
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Redirect to a user, team or IVR menu")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.condition.screen = _vm.screens.furtur_setting;
        _vm.forms.forwarding_setting.forward_note = 'number';
        _vm.updateForwardingSetting();
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Redirect to another number")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])])])])]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }