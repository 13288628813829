var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "observe-visibility",
      rawName: "v-observe-visibility",
      value: {
        callback: _vm.visibilityChanged,
        intersection: {
          threshold: 1
        }
      },
      expression: "{\n    callback: visibilityChanged,\n    intersection: {\n      threshold: 1,\n    },\n  }"
    }],
    staticClass: "callActivity-Item-v2-container",
    class: [_vm.call.status === 'Dialed' ? 'Right' : 'Left', {
      'visibleTransition': _vm.isVisible,
      'missedCall': _vm.call.status === 'Missed',
      'recievedCall': _vm.call.status === 'Received',
      'dialledCall': _vm.call.status === 'Dialed',
      'voicemailMessage': _vm.call.call_type === 'voicemail',
      'itsSMS': _vm.call.call_type === 'sms'
    }]
  }, [_c('div', {
    staticClass: "callActivity-Item-v2-inner-top"
  }, [_c('div', {
    staticClass: "icon-container"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: _vm.callInfo.text,
      expression: "callInfo.text",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": _vm.callInfo.icon,
      "height": "55.922",
      "width": "55.922"
    }
  })], 1), _c('div', {
    staticClass: "user-info"
  }, [_vm.call.call_type === 'voicemail' && _vm.call.is_shared == 'yes' ? _c('small', [_vm._v("Shared")]) : _vm._e(), _vm.call.is_out_of_hours ? _c('small', [_vm._v("Business hour")]) : _vm._e(), _c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callInfo.user_name)))]), _vm.callInfo.user_extn ? _c('div', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callInfo.user_extn)))]) : _vm._e(), !_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "via-text"
  }, [_vm.call.is_team && _vm.call.team_name ? [_vm._v(" called "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.team_name)))])] : _vm.call.is_ivr && _vm.call.ivr_details && _vm.call.ivr_details.accountname ? [_vm._v(" called "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(_vm.call, 'ivr_details.accountname'))))])] : _vm.call.queue_detail && _vm.call.queue_detail.accountname ? [_vm._v(" called "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(_vm.call, 'queue_detail.accountname'))))])] : _vm.callInfo.is_external && _vm.callInfo.via ? [_vm._v(" " + _vm._s(_vm.call.status === 'Dialed' ? 'via' : 'called') + " "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callInfo.via)))])] : _vm.call.call_type == 'sms' && _vm.callInfo.via ? [_vm._v(" via "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callInfo.via)))])] : _vm.call.is_out_of_hours && _vm.callInfo.via ? [_vm._v(" called "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callInfo.via)))])] : _vm.call.destinationDidNumber ? [_vm._v(" called "), _c('b', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.destinationDidNumber)))])] : _vm._e()], 2) : _vm._e()]), _c('div', {
    staticClass: "middle-section"
  }, [_c('span', {
    staticClass: "middle-section-text"
  }, [_vm.condition.recording && _vm.call.call_type === 'voicemail' && _vm.GET_LOCAL_SETTING_DASHBOARD.design.width != 25 && _vm.call.voicemail ? _c('div', {
    staticClass: "dialer-audio-playback"
  }, [_c('vb-audio-player', {
    attrs: {
      "design": 'minimum',
      "src": _vm.call.voicemail
    }
  })], 1) : _vm._e()])]), !(_vm.call.call_type == 'sms' && _vm.call.sms_type == 'custom') ? _c('div', {
    staticClass: "callLogIcons-container"
  }, [_vm.condition.actions ? [!_vm.assignedData && _vm.call.call_type !== 'voicemail' ? [_c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Audio call",
      "icon": "call-callLog-icon",
      "width": "35px",
      "height": "35px"
    },
    on: {
      "click": function ($event) {
        return _vm.calling();
      }
    }
  })], 1), _vm.call.call_type == 'video' ? _c('span', {
    staticClass: "mr-8px"
  }, [_vm.call.call_type == 'video' ? _c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Video call",
      "icon": "videoCall-callLog-icon",
      "width": "35px",
      "height": "35px"
    },
    on: {
      "click": function ($event) {
        return _vm.calling('video');
      }
    }
  }) : _vm._e()], 1) : _vm._e()] : _vm._e(), !_vm.assignedData ? _c('span', {
    staticClass: "mr-8px"
  }, [!_vm.assignedData ? _c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "callInfo-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": `${_vm.call.call_type === 'voicemail' || _vm.call.call_type === 'sms' ? 'Message' : 'Call'} info`
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('onClickInfo1');
      }
    }
  }) : _vm._e()], 1) : _vm._e(), !_vm.assignedData && _vm.call.is_did && !_vm.call.contact_name ? _c('span', {
    staticClass: "mr-8px"
  }, [!_vm.assignedData && _vm.call.is_did && !_vm.call.contact_name ? _c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "title": "Add to contacts",
      "icon": "addContact-callLog-icon",
      "width": "35px",
      "height": "35px"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.add_addressbooks = _vm.callInfo.user_name;
      }
    }
  }) : _vm._e()], 1) : _vm._e(), !_vm.isTodoAdded && !_vm.call.isTaskAssigned && _vm.call.call_type == 'audio' ? _c('span', {
    staticClass: "mr-8px"
  }, [!_vm.isTodoAdded && !_vm.call.isTaskAssigned && _vm.call.call_type == 'audio' ? _c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "assignTask-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": "Add to tasks"
    },
    on: {
      "click": function ($event) {
        return _vm.addTodoTask();
      }
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.call.soundFile && _vm.call.call_type == 'audio' ? _c('span', [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "playSound-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": "Call recording"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click-play', {
          soundFile: _vm.call.soundFile
        });
      }
    }
  })], 1) : _vm._e(), _vm.call.soundFile && _vm.call.call_type == 'audio' && _vm.call.status != 'Missed' ? _c('span', [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "aiTranscript",
    attrs: {
      "icon": "ai-audio-icon",
      "width": "20px",
      "height": "18px",
      "title": "AI transcript"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('openOnlyTranscriptModal');
      }
    }
  })], 1) : _vm._e()] : _vm._e()], 2) : _vm._e(), !(_vm.call.call_type == 'sms' && _vm.call.sms_type == 'custom') ? _c('div', {
    staticClass: "last-section"
  }, [_c('div', {
    staticClass: "time"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("showTime")(_vm.call.date)))]), _vm.condition.assign ? _c('b-button', {
    on: {
      "click": function ($event) {
        return _vm.$emit('assign');
      }
    }
  }, [_vm._v(" Assign ")]) : _vm._e(), _vm._t("more", function () {
    return [_vm.condition.actions ? _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": "",
        "menu-class": "w-fit-content-imp"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "variant": "#393939",
              "font-scale": "1.4"
            }
          })];
        },
        proxy: true
      }], null, false, 764454405)
    }, [!_vm.assignedData && _vm.call.is_did && !_vm.call.contact_name ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          _vm.$store.state.global_conditions.add_addressbooks = _vm.callInfo.user_name;
        }
      }
    }, [_c('vb-icon', {
      staticClass: "dialer-addToContact-icon",
      attrs: {
        "icon": "dialer-addToContact-icon",
        "width": "16.245",
        "height": "15.004"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Add contact")])], 1) : _vm._e(), !_vm.assignedData && _vm.call.call_type !== 'voicemail' ? [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.calling();
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone",
        "font-scale": "0.96"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Call")])], 1), _vm.call.call_type == 'video' ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.calling('video');
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone",
        "font-scale": "0.96"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Video call")])], 1) : _vm._e()] : _vm._e(), !_vm.assignedData ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('onClickInfo1');
        }
      }
    }, [_c('vb-icon', {
      staticClass: "callInfoIcon",
      attrs: {
        "icon": "dialer-callinfo-icon",
        "width": "15.7",
        "height": "15.701"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v(_vm._s(_vm.call.call_type === "voicemail" || _vm.call.call_type === "sms" ? "Message" : "Call") + " info")])], 1) : _vm._e(), _vm.assignedData && _vm.call.call_type == 'audio' && _vm.call.status == 'Missed' ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('clickAssignDetail');
        }
      }
    }, [_c('vb-icon', {
      staticClass: "callInfoIcon",
      attrs: {
        "icon": "dialer-callinfo-icon",
        "width": "15.7",
        "height": "15.701"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Assigned detail")])], 1) : _vm._e(), !_vm.isTodoAdded && !_vm.call.isTaskAssigned && _vm.call.call_type == 'audio' ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.addTodoTask();
        }
      }
    }, [_c('vb-icon', {
      staticClass: "callInfoIcon",
      attrs: {
        "icon": "dialer-callinfo-icon",
        "width": "15.7",
        "height": "15.701"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Add to tasks")])], 1) : _vm._e()], 2) : _vm._e()];
  })], 2), _vm.call.call_type != 'sms' && _vm.call.call_type != 'voicemail' ? _c('div', {
    staticClass: "duration"
  }, [_vm._v(_vm._s(_vm._f("secondsTimer")(_vm.duration)))]) : _vm._e()]) : _vm._e()]), _vm.call.call_type === 'audio' || _vm.call.call_type === 'sms' ? _c('div', {
    staticClass: "callActivity-Item-v2-inner-2nd"
  }, [_vm.tags_note_show ? [_vm.condition.tag ? _c('div', {
    staticClass: "tags-container"
  }, _vm._l(_vm.tags, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "dialer-call-prefix tag"
    }, [_c('small', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color",
        value: tag.tag.colour,
        expression: "tag.tag.colour"
      }]
    }, [_c('span', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text",
        value: tag.tag.text_color,
        expression: "tag.tag.text_color",
        modifiers: {
          "text": true
        }
      }]
    }, [_vm._v(_vm._s(tag.tag.tag))])])]);
  }), 0) : _vm._e(), _vm.condition.note && _vm.note ? _c('span', {
    staticClass: "dialer-call-prefix note"
  }, [_vm._v(_vm._s(_vm.note))]) : _vm._e()] : _vm._e(), _vm.condition.message && _vm.call.call_type === 'sms' ? _c('span', {
    staticClass: "dialer-call-prefix messageText"
  }, [_vm._v(_vm._s(_vm.call.body || ""))]) : _vm._e()], 2) : _vm._e(), _vm.condition.recording && _vm.call.call_type === 'voicemail' && _vm.GET_LOCAL_SETTING_DASHBOARD.design.width == 25 && _vm.call.voicemail ? _c('div', {
    staticClass: "callActivity-Item-v2-inner-2nd"
  }, [_c('div', {
    staticClass: "dialer-audio-playback"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm.call.voicemail
    }
  })], 1)]) : _vm._e(), _vm.call.transcript ? _c('p', {
    staticClass: "extraInfoInCallLogs callTranscriptText mb-0 w-100"
  }, [_c('span', {
    staticStyle: {
      "font-weight": "800"
    }
  }, [_vm._v(" " + _vm._s(_vm.call.call_type === 'voicemail' ? 'Voicemail' : 'Call') + " transcript : ")]), _vm._v(" " + _vm._s(_vm.call.transcript) + " ")]) : _vm._e(), _vm.GET_LOCAL_SETTING_DASHBOARD.design.transfer_log ? _c('p', {
    staticClass: "extraInfoInCallLogs mb-0 w-100"
  }, [_vm.callInfo.forward.enabled ? [_c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Forward',
      expression: "'Forward'",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "callLogs-forward-icon",
      "width": "14.61px",
      "height": "15.595px"
    }
  }), _vm.callInfo.forward.from && _vm.call.forward_from ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', [_vm._v("from")]), _vm.call.forward_from.accounttype == 'RINGGROUP' ? [_vm.call.forward_from.accountname ? _c('div', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.forward_from.accountname)))]) : _vm._e()] : [_vm.callInfo.forward.from.number ? _c('div', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callInfo.forward.from.number)))]) : _c('Info', {
    attrs: {
      "id": _vm.callInfo.forward.from.accountcode,
      "only_name": true
    }
  })]], 2) : _vm._e(), _vm.callInfo.forward.to && _vm.call.forward_to ? [_c('span', [_vm._v("to")]), _vm.call.forward_to.accounttype == 'RINGGROUP' ? [_vm.call.forward_to.accountname ? _c('div', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.forward_to.accountname)))]) : _vm._e()] : [_vm.callInfo.forward.to.number ? _c('span', {
    staticClass: "extraInfoInCallLogs-active"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callInfo.forward.to.number)))]) : _c('Info', {
    attrs: {
      "id": _vm.callInfo.forward.to.accountcode,
      "only_name": true
    }
  })]] : _vm._e()] : _vm._e(), (_vm.call.is_team || _vm.call.queue_detail || _vm.call.is_ivr || _vm.call.is_forward) && _vm.call.answer_accountcode ? [_vm._v(" answered by: "), _c('Info', {
    attrs: {
      "id": _vm.call.answer_accountcode,
      "name": _vm._f("number_formater")(_vm.call.answer_accountcode),
      "only_name": true
    }
  }), _vm.call.answer_accountcode_details ? _c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: _vm.call.answer_accountcode_details.accountname || '',
      expression: "call.answer_accountcode_details.accountname || ''",
      modifiers: {
        "hover": true
      }
    }],
    class: _vm._f("extensionIcon")(_vm.call.answer_accountcode_details.accounttype),
    attrs: {
      "icon": _vm._f("extensionIcon")(_vm.call.answer_accountcode_details.accounttype),
      "width": "25px",
      "height": "25px"
    }
  }) : _vm._e()] : _vm._e(), _vm.callInfo.transfer.enabled ? [_c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Transfer',
      expression: "'Transfer'",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "callLogs-transfer-icon",
      "width": "16.448px",
      "height": "15.595px"
    }
  }), _vm.callInfo.forward.to ? [_c('span', [_vm._v("to")]), _vm.callInfo.transfer.to.number ? _c('span', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callInfo.transfer.to.number)))]) : _c('Info', {
    attrs: {
      "id": _vm.callInfo.transfer.to.accountcode,
      "only_name": true
    }
  })] : _vm._e()] : _vm._e()], 2) : _vm._e(), _vm.call.isTaskAssigned ? _c('p', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "extraInfoInCallLogs forTasks mt-1 mb-0 w-100",
    attrs: {
      "title": `${_vm.call.isTaskAssigned.spaces.prefix}#${_vm.call.isTaskAssigned.id}`
    },
    on: {
      "click": function ($event) {
        !_vm.assignedData && _vm.condition.actions ? _vm.$emit('onClickInfo1', {
          task_modal: true
        }) : '';
      }
    }
  }, [_c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover",
      value: 'Task',
      expression: "'Task'",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "mr-2",
    attrs: {
      "icon": "callLogs-task-icon",
      "width": "18.1px",
      "height": "18.11px"
    }
  }), [_c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm.call.isTaskAssigned.title))])], _vm.call.isTaskAssigned.due_date ? _c('span', [_vm._v("Due: " + _vm._s(_vm.call.isTaskAssigned.due_date))]) : _vm._e(), [_vm.call.isTaskAssigned.status ? _c('div', {
    staticClass: "categoryText mr-16px ml-4",
    style: `background-color: ${_vm.call.isTaskAssigned.status.color};`
  }, [_vm._v(" " + _vm._s(_vm.call.isTaskAssigned.status.title) + " ")]) : _vm._e(), _vm.call.isTaskAssigned.is_important == 1 ? _c('div', {
    staticClass: "categoryText mx-2 px-1",
    style: `background-color: #F2F2F2;`
  }, [_c('b-icon', {
    attrs: {
      "icon": "exclamation-lg",
      "variant": "danger"
    }
  })], 1) : _vm._e()], _vm.call.isTaskAssigned.assigned.length != 0 ? [_c('vb-icon', {
    attrs: {
      "icon": "callLogs-task-assignedTo-icon",
      "width": "11.851px",
      "height": "14.227px"
    }
  }), _vm._v(" Assigned to "), _c('Info', {
    attrs: {
      "id": _vm.call.isTaskAssigned.assigned[0].assigned_to,
      "only_name": true
    }
  })] : _vm._e()], 2) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }