
import { VOIP_API } from "../index"

export const DEVICES_API_ENDPOINTS = {
  list() {
    return VOIP_API.axios.voip.request({
      url: "mobileextension",
      method: "GET",
    })
  },
  deviceslogs(account,params) {
    return VOIP_API.axios.voip.request({
      url: `device-logs/${account}`,
      method: "GET",
      params,
    })
  },
  checkpurchaselimit(data) {
    return VOIP_API.axios.voip.request({
      url: "user-product-limit",
      method: "POST",
      data,
    })
  },
  create(data={}){
    return VOIP_API.axios.voip.request({
      url: "adding-product-from-app",
      method: "POST",
      data,
    })
  }
}