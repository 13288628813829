<template>
  <modal
    class="dialer_animation right_side_popup"
    width="40%"
    height="auto"
    :scrollable="true"
    :name="modalName"
    @before-open="onBeforeOpen"
    @before-close="onBeforeClose"
  >
    <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header">
          <h2 class="dialer-edit-title mb-0">
            <div class="d-flex align-items-center">
              <vb-svg
                class="cursor_pointer"
                name="leftLongArrow-icon"
                width="20"
                height="12"
                viewBox="0 0 30 16"
                stroke-width="1"
                stroke=""
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                @click="$modal.hide(modalName)"
              />
              <span class="dialer-settings-title newer md-mar-left">Select sound</span>
            </div>
          </h2>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="onDone()">
              <!-- Apply -->
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Apply</span>
            </button>
          </div>
        </div>
        <div class="dialer-tabs">
          <div class="dialer-tab-content">
            <div class="dialer-box grayBox">
              <div class="w-100 md-mar-bottom whiteBGinputWithGreyRoundedBorder">
                <b-input type="text" placeholder="Search sound" v-model="filter.sound.search" />
              </div>
              <div class="innerWhiteBoxwithRoundedBorder">
                <div class="mb-3" v-for="sound in filterSounds" :key="sound.real_id">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <span class="dialer-assignee-name font-weight-700 mr-4 minWidth25minus16px">{{ sound.label }}</span>
                    <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again calc-100-32px w-50" :src="sound.sound_file" />
                    <b-icon :icon="selected.sound.real_id === sound.real_id? 'check-square-fill' : 'square'" variant="black" font-scale="1.5" @click="selected.sound = sound" />
                  </div>
                </div>
              </div>
            </div>













            <!-- <div class="w-100 md-mar-bottom dialer-input-field input-consistent-inner-shadows">
              <b-input type="text" placeholder="Search Sound" v-model="filter.sound.search" />
            </div>
            <ul class="dialer-asssignee-list">
              <li v-for="sound in filterSounds" :key="sound.real_id" class="dialer-assignee">
                <span class="dialer-assignee-name">{{ sound.label }}</span>
                <div class="d-flex align-items-center justify-content-between w-100">
                  <vb-audio-player :src="sound.sound_file" />
                  <b-icon :icon="selected.sound.real_id === sound.real_id? 'check-square-fill' : 'square'" variant="primary" font-scale="1.5" @click="selected.sound = sound" />
                </div>
              </li>
            </ul> -->
          </div>
        </div>
      </section>
  </modal>
</template>

<script>
import _ from 'lodash'
export default {
  name: 'SelectSounds',
  props: {
    modalName: {
      type: String,
      default: 'SelectSoundsModal',
    },
  },
  data(){
    return {
      data: {
        sounds: {},
        reset(){
          this.sounds={}
        },
      },
      selected: {
        sound: {},
        reset(){
          this.sound={}
        },
      },
      filter: {
        sound: {
          search: '',
          reset(){
            this.search=''
          },
        },
      },
    }
  },
  computed: {
    filterSounds(){
      const search = this.filter.sound.search.trim().toLowerCase()
      const array = Object.values(this.data.sounds)
      return array.filter(sound=>`${sound.label}`.trim().toLowerCase().includes(search))
    },
  },
  methods: {
    onBeforeOpen(events){
      this.data.sounds = events.params.sounds || {}
      this.selected.sound = events.params.selected || {}
    },
    onBeforeClose(){
      this.data.reset()
      this.filter.sound.reset()
      this.selected.reset()
    },
    onDone(){
      // console.log(_.isEmpty(this.selected.sound))
      if(_.isEmpty(this.selected.sound)) return;
      this.$emit('selected-sound',this.selected.sound)
      this.$modal.hide(this.modalName)
    },
  },
}
</script>

<style>

</style>