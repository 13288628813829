var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Time Range")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onDone();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": _vm.submitted.done && !_vm.isInvalid,
      "variant": "danger"
    }
  }, [_vm._v("Start time should be greater than end time")]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Start Time")]), _c('VueTimepicker', {
    staticClass: "dialer-outofhours-timeslot input-consistent-VueTimepicker",
    attrs: {
      "placeholder": "select start Time",
      "format": "HH:mm",
      "close-on-complete": ""
    },
    on: {
      "change": function ($event) {
        _vm.form.start = $event;
      }
    },
    model: {
      value: _vm.form.start.displayTime,
      callback: function ($$v) {
        _vm.$set(_vm.form.start, "displayTime", $$v);
      },
      expression: "form.start.displayTime"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("End Time")]), _c('VueTimepicker', {
    staticClass: "dialer-outofhours-timeslot input-consistent-VueTimepicker",
    attrs: {
      "placeholder": "select end Time",
      "format": "HH:mm",
      "close-on-complete": ""
    },
    on: {
      "change": function ($event) {
        _vm.form.end = $event;
      }
    },
    model: {
      value: _vm.form.end.displayTime,
      callback: function ($$v) {
        _vm.$set(_vm.form.end, "displayTime", $$v);
      },
      expression: "form.end.displayTime"
    }
  })], 1)])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px"
  }, [_vm._v("Done")])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }