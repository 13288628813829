var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 addExtension-voicemailModal addRemove_userModal bigSquareCheckbox CallQueueAddRemoveUsersModal min-width-50percent-modal fixScrollIssue",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "opened": function ($event) {
        return _vm.onOpened($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Assign list to")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.fetch_extensions.send
    },
    on: {
      "click": function ($event) {
        return _vm.update();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-container mt-20px"
  }, _vm._l(_vm.filterTabs, function (tab) {
    return _c('div', {
      key: tab.value,
      staticClass: "TabsDesignWithIcon-item",
      class: {
        'active': _vm.selected.tab == tab.value
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.icon,
        "height": "38px",
        "width": "38px"
      }
    }), _c('span', {
      staticClass: "TabsDesignWithIcon-item-textPart"
    }, [_vm._v(_vm._s(tab.text))])], 1);
  }), 0), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100 mt-20px"
  }, [_c('div', {
    staticClass: "w-50 whiteBGinputWithGreyRoundedBorder"
  }, [_vm.selected.tab == _vm.tabs.USER.value ? _c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search user"
    },
    model: {
      value: _vm.filter.user.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.user, "search", $$v);
      },
      expression: "filter.user.search"
    }
  }) : _vm.selected.tab == _vm.tabs.TEAM.value ? _c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search team"
    },
    model: {
      value: _vm.filter.team.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.team, "search", $$v);
      },
      expression: "filter.team.search"
    }
  }) : _vm.selected.tab == _vm.tabs.BASICIVR.value ? _c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search IVR"
    },
    model: {
      value: _vm.filter.ivr.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.ivr, "search", $$v);
      },
      expression: "filter.ivr.search"
    }
  }) : _vm.selected.tab == _vm.tabs.QUEUE.value ? _c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search call queue"
    },
    model: {
      value: _vm.filter.call_queue.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.call_queue, "search", $$v);
      },
      expression: "filter.call_queue.search"
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_vm.api.fetch_extensions.send ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-2",
      staticStyle: {
        "height": "45px",
        "width": "45px"
      }
    }), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "21px",
        "width": "100px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign mt-1",
      staticStyle: {
        "height": "21px",
        "width": "70%"
      }
    })])]), _c('div', {
      staticClass: "latestShimmerDesign mr-2",
      staticStyle: {
        "height": "24px",
        "width": "24px"
      }
    })]);
  }), 0) : _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm._l(_vm.filterExtensions, function (extension) {
    return _c('div', {
      key: extension.accountcode,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox p-0 makeCheckBoxesSquare less-padding leastPadding checkBoxOnRightSide",
      attrs: {
        "checked": !!_vm.selected.accountcodes[extension.accountcode]
      },
      on: {
        "change": function ($event) {
          return _vm.onSelect(extension);
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": extension.accountcode,
        "type": extension.account,
        "is_blf": false
      }
    })], 1)], 1);
  }), _vm.isEmpty(_vm.filterExtensions) ? _c('vb-no-record', {
    staticClass: "my-5",
    attrs: {
      "text": _vm.api.fetch_extensions.send ? '' : 'Sorry, no data found',
      "design": 3
    }
  }) : _vm._e()], 2)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }