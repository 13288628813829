var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "top-main-hrm"
  }, [_c('section', [_c('div', {
    staticClass: "bg-hrm-mike scrollow1"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-start"
  }, [_c('div', {
    staticClass: "w-75 for-home-dd-width d-flex flex-wrap"
  }, [_c('div', {
    staticClass: "home-dd-container"
  }, [_c('Multiselect', {
    attrs: {
      "options": _vm.response.teams,
      "disabled": _vm.api.fetch_teams.send,
      "multiple": true,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "placeholder": "Select Teams",
      "label": "teamName",
      "track-by": "teamName",
      "preselect-first": true
    },
    model: {
      value: _vm.filter.permissions.teams,
      callback: function ($$v) {
        _vm.$set(_vm.filter.permissions, "teams", $$v);
      },
      expression: "filter.permissions.teams"
    }
  })], 1), _c('div', {
    staticClass: "home-dd-container"
  }, [_c('Multiselect', {
    attrs: {
      "options": _vm.response.resources_groups,
      "disabled": _vm.api.fetch_resources_groups.send,
      "multiple": true,
      "close-on-select": false,
      "clear-on-select": false,
      "preserve-search": true,
      "placeholder": "Select Recourse Groups",
      "label": "resource_type",
      "track-by": "resource_type",
      "preselect-first": true
    },
    model: {
      value: _vm.filter.permissions.resources_groups,
      callback: function ($$v) {
        _vm.$set(_vm.filter.permissions, "resources_groups", $$v);
      },
      expression: "filter.permissions.resources_groups"
    }
  })], 1)]), _c('div', {
    staticClass: "w-25 top-btn-align"
  })]), _c('div', {
    staticClass: "card-container-main-box mt-5"
  }, [[_c('vb-table', {
    attrs: {
      "isListEmpty": _vm.filterResources.length == 0,
      "listLength": _vm.filterResources.length,
      "loading": _vm.api.fetch_permissions.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterResources, function (resource, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: resource.id
          }, [_c('td', {
            staticClass: "dialer-row-title dialer-col-name-width wd-75"
          }, [_c('div', {
            staticClass: "d-flex dialer-numberWithCountry"
          }, [_c('span', {
            staticClass: "d-flex flex-column ml-1 dialer-numberInfo justify-content-center"
          }, [_c('span', {
            staticClass: "m-0 didNumber"
          }, [_vm._v(_vm._s(resource.resource_name))])])])]), _c('td', {
            staticClass: "dialer-row-title dialer-col-name-width wd-75"
          }, [_c('div', {
            staticClass: "d-flex dialer-numberWithCountry"
          }, [_c('span', {
            staticClass: "d-flex flex-column ml-1 dialer-numberInfo justify-content-center"
          }, [_c('span', {
            staticClass: "m-0 didNumber"
          }, [_vm._v(_vm._s(_vm._f("get_property")(resource, 'resource_group.resource_type')))])])])]), _vm._l(_vm.filterUsers, function (user) {
            return _c('td', {
              key: user.id,
              staticClass: "dialer-col-center wd-25"
            }, [_c('b-icon', {
              attrs: {
                "icon": _vm._f("permissionAllow")(user, resource)
              }
            })], 1);
          })], 2);
        });
      }
    }])
  }, [_c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Resource")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Type")])]), _vm._l(_vm.filterUsers, function (user) {
    return _c('th', {
      key: user.id,
      staticClass: "dialer-has-sort dialer-col-center"
    }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(user, 'subuser.firstName')) + " " + _vm._s(_vm._f("get_property")(user, 'subuser.lastName')))])]);
  })], 2)])]], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }