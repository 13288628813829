<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen" 
      @before-close="onBeforeClose"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add Playlist</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      
      <div class="innerModalIos">

        

        <form class="dialer-form lg-mar-bottom" @submit.prevent="addPlaylist()">
          <b-alert class="mt-32px" :show="!!api.add_playlist.error_message" variant="danger">{{api.add_playlist.error_message}}</b-alert>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Name</label>
                <input placeholder="My Playlist etc..." class="w-100" type="text" :disabled="api.add_playlist.send" v-model="forms.add_playlist.name" />
                <p v-if="(forms.add_playlist.submitted && $v.forms.add_playlist.name.$invalid) || api.add_playlist.validation_errors.name" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.add_playlist.name.required">* Name is required</span>
                  <span v-for="(em, i) in api.add_playlist.validation_errors.name" :key="i">* {{em}}</span>
                </p>
              </div>
            </div>
          </div>
          <button class="IosFullWidthButton forDone mb-16px" :disabled="api.add_playlist.send">
            <vb-spinner v-if="api.add_playlist.send" />
            <template v-else>Done</template>
          </button>
        </form>

      </div>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { VOIP_API } from '../../../../utils';
export default {
  name: "AddPlayListModal",
  props: {
    modalName: {
      type: String,
      default: 'AddPlayListModal'
    },
  },
  data() {
    return {
      forms: {
        add_playlist: this.$helperFunction.formInstance({
          data: {
            name: ''
          }
        }),
        reset(){
          this.add_playlist?.reset?.()
        },
      },
      api: {
        add_playlist: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true }),
        reset(){
          this.add_playlist?.reset?.()
        },
      },
    };
  },
  validations: {
    forms: {
      add_playlist: {
        name: {
          required,
        },
      },
    },
  },
  inject: [
    'appNotify',
  ],
  methods: {
    onBeforeOpen(event) {
      this.forms.add_playlist.name = event?.params?.suggested_name ?? '';
    },
    onBeforeClose() {
      this.forms.reset()
      this.api.reset()
    },
    addPlaylist() {
      let vm = this
      vm.forms.add_playlist.submitted = true;
      vm.$v.forms.add_playlist.$touch();
      if (vm.$v.forms.add_playlist.$invalid || vm.api.add_playlist.send) return;
      vm.api.add_playlist.send=true
      vm.api.add_playlist.validation_errors = {};
      vm.api.add_playlist.error_message = '';
      VOIP_API.endpoints.music_on_hold.create({
        playlistname: vm.forms.add_playlist.name
      },{
        fid: 12
      })
      .then(() => {
        vm.$modal.hide(vm.modalName);
        vm.appNotify({
          message: "Successfully Added!",
          type: "success",
        })
        vm.$emit("interface");
      })
      .catch((ex) => {
        vm.api.add_playlist.validation_errors = ex.own_errors ?? {};
        vm.api.add_playlist.error_message = ex.own_message ?? '';
      }).finally(()=>{
        vm.forms.add_playlist.submitted = false;
        vm.api.add_playlist.send = false;
      });
    },
  },
};
</script>
