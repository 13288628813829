<template>
  <div 
    :class="{
      'whiteBoxWithBorderInsideGreyBox-row flex-column': location == 'whiteBoxWithBorderInsideGreyBox',
      'dialer-box alt': location != 'whiteBoxWithBorderInsideGreyBox',
    }"
  >
    <template v-if="location == 'whiteBoxWithBorderInsideGreyBox'">
      <div class="d-flex w-100 justify-content-between align-items-center">
        <div class="d-flex flex-column">
          <div class="latestGreyBox-heading-main">Custom music on hold</div>
          <!-- <div class="latestGreyBox-descriptionText">Elevate caller experience with hold music</div> -->
          <div class="latestGreyBox-descriptionText">Elevate caller experience with music on hold</div>
        </div>
        <div 
          v-b-tooltip :title="getUserPermissions.custom_moh?'':'Your package does not support this feature'"
          :class="{
            'for-disable': !getUserPermissions.custom_moh
          }"
        >
          <!-- <b-spinner v-if="api.call_route.send" small variant="primary" type="grow" /> -->
          <template v-if="api.call_route.send">
            <div class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
          </template>
          <b-form-checkbox 
            v-else
            :disabled="!getUserPermissions.custom_moh || api.set_to_default.send" 
            class="newerSwitch" 
            :checked="conditions.music_on_hold_enable"
            @change="$event?conditions.music_on_hold_enable=true:setToDefault()"
            name="check-button" 
            switch 
            ref="music_on_hold_enable"
          />
        </div>
      </div>
      <div class="d-flex w-100 justify-content-between align-items-center mt-20px" v-if="conditions.music_on_hold_enable">
        <span class="latestGreyBox-descriptionText mt-0">{{ playlist.name || (defaultPlaylist ? defaultPlaylist.label : '') }}</span>
        <div 
          v-b-tooltip :title="getUserPermissions.custom_moh?'':'Your package does not support this feature'"
          :class="{
            'for-disable': !getUserPermissions.custom_moh
          }"
        >
          <button 
            class="newButton" 
            :disabled="!getUserPermissions.custom_moh || api.set_to_default.send" 
            @click="$modal.show(modalNames.music_on_hold, {
                id: playlist.id || '',
                name: playlist.name || '',
                section: 'user',
                account: accountcode,
              })
            "
          >
            <vb-icon icon="squared-change-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Change playlist</span>
          </button>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="dialer-flex">
        <div class="dialer-box-header">Custom music on hold</div>
        <div 
          v-b-tooltip :title="getUserPermissions.custom_moh?'':'Your package does not support this feature'"
          :class="{
            'for-disable': !getUserPermissions.custom_moh
          }"
        >
          <!-- <b-spinner v-if="api.call_route.send" small variant="primary" type="grow" /> -->
          <template v-if="api.call_route.send">
            <div class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
          </template>
          <b-form-checkbox 
            v-else
            :disabled="!getUserPermissions.custom_moh || api.set_to_default.send" 
            class="newerSwitch" 
            :checked="conditions.music_on_hold_enable"
            @change="$event?conditions.music_on_hold_enable=true:setToDefault()"
            name="check-button" 
            switch 
            ref="music_on_hold_enable"
          />
        </div>
      </div>
      <div class="dialer-flex">
        <div class="dialer-audio-options w-100">
          <div class="dialer-box-text">Elevate caller experience with music on hold</div>
          <div class="dialer-audio-options-actions d-flex justify-content-between w-100" v-if="conditions.music_on_hold_enable">
            <span class="sm-mar-right">{{ playlist.name || (defaultPlaylist ? defaultPlaylist.label : '') }}</span>
            <div 
              v-b-tooltip :title="getUserPermissions.custom_moh?'':'Your package does not support this feature'"
              :class="{
                'for-disable': !getUserPermissions.custom_moh
              }"
            >
              <button 
                class="newButton" 
                :disabled="!getUserPermissions.custom_moh || api.set_to_default.send" 
                @click="$modal.show(modalNames.music_on_hold, {
                    id: playlist.id || '',
                    name: playlist.name || '',
                    section: 'user',
                    account: accountcode,
                  })
                "
              >
                <!-- <vb-svg 
                  class="dialer-soundLibrary-icon" 
                  name="dialer-soundLibrary-icon" 
                  width="11" 
                  height="15.996" 
                  viewBox="0 0 11 16" 
                  stroke-width="0" 
                  stroke="" 
                  fill="none" 
                  stroke-linecap="round" 
                  stroke-linejoin="round" 
                />
                <span class="sm-mar-left">Change playlist</span> -->
                <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Change playlist</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </template>
    <ChangePlaylistMusicOnHoldModal :modalName="modalNames.music_on_hold" @updated="callRouteSetting()"  />
  </div>
</template>

<script>
import { VOIP_API, $fn } from '@/utils';
import { mapGetters } from 'vuex';
import ChangePlaylistMusicOnHoldModal from '../Modals/ChangePlaylistMusicOnHoldModal.vue';
export default {
  name: 'MusicOnHoldWidget',
  components: {
    ChangePlaylistMusicOnHoldModal,
  },
  inject:['isEmpty','appNotify'],
  props: {
    accountcode: {
      type: String,
      default: ''
    },
    extraParams: {
      type: Object,
      default: ()=>({})
    },
    fromGlobal: {
      type: Boolean,
      default: false,
    },
    location: {
      type: String,
      default: ''
    },
  },
  data(){
    return {
      api: {
        call_route: this.$helperFunction.apiInstance({ source: true }),
        playlists: this.$helperFunction.apiInstance({ source: true }),
        set_to_default: this.$helperFunction.apiInstance({  }),
      },
      response: {
        call_route: {},
        playlists: {},
      },
      conditions: {
        music_on_hold_enable: false,
      },
      data: {
        random_id: ''
      },
    }
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser'
    ]),
    modalNames(){
      return {
        music_on_hold: `${this.data.random_id}MusicOnHold`
      }
    },
    playlist(){
      return {
        id: this.response.call_route?.playlistid ?? '',
        name: this.response.call_route?.playlistname ?? '',
      }
    },
    defaultPlaylist(){ return this.response.playlists?.playlists?.data?.find?.(i=>i.default==1) },
  },
  methods: {
    async callRouteSetting() {
      try {
        this.api.call_route.send = true;
        this.api.call_route.source?.cancel?.("Requesting Again");
        this.api.call_route.source = require("axios").default.CancelToken.source()
        const { data: call_route } = await VOIP_API.endpoints.users.callroute({
          accountcode: this.accountcode,
          ...this.extraParams,
        },this.api.call_route.source.token)
        this.conditions.music_on_hold_enable = !!call_route?.playlistid;
        this.response.call_route=call_route
      } finally {
        this.api.call_route.send = false;
        this.api.call_route.source = null;
      }
    },
    async setToDefault() {
      if(this.api.set_to_default.send || !this.conditions.music_on_hold_enable) return;
      try {
        this.api.set_to_default.send = true;
        await VOIP_API.endpoints.music_on_hold.unsetplaylist({
          flag: "changedefault",
          type: this.fromGlobal?'admin':"user",
          fid: 12,
          account: this.accountcode,
        })
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success'
        })
        this.callRouteSetting()
      } catch(ex) {
        if(this.$refs['music_on_hold_enable']) this.$refs['music_on_hold_enable'].localChecked = true;
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.set_to_default.send = false;
      }
    },
    async fetchPlaylists(){
      try {
        this.api.playlists.send=true
        this.api.playlists.source?.cancel?.("Requesting Again");
        this.api.playlists.source = require("axios").default.CancelToken.source()
        const { data } = await VOIP_API.endpoints.music_on_hold.playlistfiles(this.getCurrentUser.account,{
          account: this.accountcode,
          accountcode: this.accountcode,
        },this.api.playlists.source.token);
        this.response.playlists=data ?? {}
      } finally {
        this.api.playlists.send=false
      }
    },
  },
  created(){
    this.data.random_id=$fn.makeid()
  },
  mounted(){
    this.callRouteSetting()
    this.fetchPlaylists()
  },
  activated(){
    this.callRouteSetting()
    this.fetchPlaylists()
  },
}
</script>

<style>

</style>