var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup billingAllNumbersModal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-box table"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Users")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])], 1)]), _c('div', {
    staticClass: "mt-5 mb-3"
  }, [_c('vb-table', {
    staticClass: "plan-license-table callPrefixTable dialer-table dialer-table-bg-f3f3f3 px-3 pb-3",
    attrs: {
      "isListEmpty": !(_vm.response.account.users || false),
      "listLength": Object.values(_vm.response.account.users || {}).length,
      "perPage": 5,
      "loading": _vm.api_sent.account_detail
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.response.account.users || {}, function (user, key, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: key,
            staticClass: "dialer-row-select mx-0 px-0"
          }, [_c('td', {
            staticClass: "dialer-row-title pl-0"
          }, [_c('Info', {
            attrs: {
              "id": key
            }
          })], 1), _c('td', {
            staticClass: "dialer-has-sort px-0"
          }, [_c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion whiteBg-select simple-image"
          }, [_c('vb-select', {
            staticClass: "dialer-menu-filterOptions md-mar-left",
            attrs: {
              "disabled": _vm.api_sent.update_prefixes,
              "selected": user.plan_prefix || '',
              "defaultSelectedText": `Select call plan`,
              "options": _vm.callPlans
            },
            on: {
              "change": function ($event) {
                return _vm.updatePrefix(key, $event);
              }
            }
          })], 1)]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-right pr-0"
          }, [_c('button', {
            staticClass: "dialer-button dialer-button-primary"
          }, [_vm._v("Change")])])]);
        });
      }
    }])
  }, [_c('tr', {
    staticClass: "border-bottom-0",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort pt-3 pb-3 pl-0 border-radius-16"
  }, [_c('span', {
    staticClass: "border-bottom pb-3 pr-4"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort pt-3 pb-3 border-radius-16"
  }, [_c('span', {
    staticClass: "border-bottom pb-3 pr-4"
  }, [_vm._v("Call plan")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right pt-3 pr-0 border-radius-16 pb-3"
  }, [_c('span', {
    staticClass: "border-bottom pb-3 pl-4"
  }, [_vm._v("Action")])])])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }