<template>
  <div>
    <div class="flow__graph designed designedAgain scaleDown-0-6" ref="app_designed" id="app_designed">
      <div v-if="callQueue" class="w-100 d-flex justify-content-center align-items-center flex-column">
        <div class="w-auto d-flex justify-content-center align-items-start flex-column">
          <NumbersNode :dids="callQueue.userDid" />
          <InfoNode :voipaccount="callQueue.voipaccount" @edit="$modal.show('EditCallQueueGraphModal',{ call_queue: callQueue, })" />
          <div class="d-flex">
            <div class="nodeBasic-new" >
              <div class="d-flex justify-content-between align-items-center">
                <div class="nodeBasic-heading">Members</div>
                <b-icon icon="people-fill" />
              </div>
              <div class="w-100 selectedText-big">Agents Online</div>
              <div class="w-100 selectedText-small">{{onlinemembers.length}}/{{members.length}}</div>
              <div class="w-100 d-flex justify-content-end">
                <span style="position: relative;">
                  <b-icon 
                    icon="pencil-fill" 
                    @click="$modal.show('EditCallQueueMembersGraphModal',{
                      call_queue: callQueue, 
                      type: 'CallQueueMembers'
                    })" 
                  />
                </span>
              </div>
            </div>
            <div class="lineWithArrow right">
              <div class="lineWithArrow-line"></div>
              <div class="lineWithArrow-arrow"></div>
            </div>
            <div class="nodeBasic-new" >
              <div v-for="member in firstFourMembers" :key="member.membername"  class="mb-2">
                <Info :id="member.membername" :type="'USER'" :is_blf="false" />
              </div>
              <div v-if="members.length>4" class="d-flex justify-content-end w-100 mt-4">
                <div style="color: rgb(85, 118, 209); font-weight: 600; font-size: 16px;"> {{members.length - 4}} more members </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddRemoveFromList :modalName="'EditCallQueueMembersGraphModal'" @update-list="getCallQueue()" />
    <EditCallQueueModal :modalName="`EditCallQueueGraphModal`" />
  </div>
</template>

<script>
import NumbersNode from '../common/custom/NumbersNode.vue'
import InfoNode from '../common/custom/InfoNode.vue'
import AddRemoveFromList from '../../Modals/AddRemoveFromList.vue'
import EditCallQueueModal from '../../Modals/EditCallQueueModal.vue'
import Info from '../../Lists/Info.vue';
import _ from 'lodash'
import { mapGetters } from 'vuex'
export default {
  name: 'TeamGraph',
  components: {
    NumbersNode,
    InfoNode,
    AddRemoveFromList,
    EditCallQueueModal,
    Info
  },
  inject: [
    'getCallQueue'
  ],
  props:{
    accountcode:{
      type: String
    }
  },
  computed:{
    ...mapGetters({
      getCurrentUser: 'getCurrentUser',
      getVoipCallQueues: 'getVoipCallQueues',
      getVoipCallQueuesAlises: 'getVoipCallQueuesAlises',
    }),
    callQueue(){ return this.getVoipCallQueuesAlises[this.accountcode] },
    members(){ return this.callQueue?.members ?? [] },
    onlinemembers(){ return this.callQueue?.members?.filter?.(i=>!!this.callqueue?.call_queue_status?.[i.membername]?.logged_in) ?? [] },
    firstFourMembers(){
      return _.take(this.members ?? [],4)
    },
  },
}
</script>

<style lang="scss">
.nodeBasic-new{
  background-color: #F8F8F8;
  border-width: 0px;
  border-radius: 6px;
  min-width: 50px;
  padding: 12px 16px;
  // -ms-box-shadow: 0px 1px 6px rgba(35, 35, 35, 0.27);
  // -o-box-shadow: 0px 1px 6px rgba(35, 35, 35, 0.27);
  // box-shadow: 0px 1px 6px rgba(35, 35, 35, 0.27);
  min-width: 200px;
  height: fit-content;
  width: fit-content;
  .selectedText-big{
    font-size: 16px;
    line-height: 1.3;
    font-weight: 600;
    color: #5B5B5B;
    margin-bottom: 4px;
  }
  .selectedText-small{
    font-size: 14px;
    line-height: 1.3;
    font-weight: 500;
    color: #8a8989;
  }
}
.lineWithArrow{
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  min-height: 50px;
  .lineWithArrow-line{
    width: 2px;
    min-height: 50px;
    background-color: black;
  }
  .lineWithArrow-arrow{
    height: 15px;
    width: 15px;
    min-width: 10px;
    border-color: black;
    border-style: solid;
    border-top-width: 0px;
    border-left-width: 0px;
    border-right-width: 3px;
    border-bottom-width: 3px;
    border-radius: 4px;
    margin-top: -15px;
    transform: rotate(45deg);
  }
  &.longLine-150px{
    height: 150px;
    .lineWithArrow-line{
      height: 150px;
    }
  }
  &.right {
    padding-top: 50px;
    height: auto;
    width: 50px;
    flex-direction: row;
    align-items: flex-start;
    .lineWithArrow-line{
      height: 2px;
      min-height: 2px;
      width: 50px;
    }
    .lineWithArrow-arrow{
      margin-left: -15px;
      margin-top: -6px;
      transform: rotate(310deg);
    }
    &.longLine-150px{
      width: 150px;
      .lineWithArrow-line{
        width: 150px;
      }
    }
  }
}
.lineOnlyForIVR{
  display: flex;
  justify-content: center;
  position: relative;
  // height: -webkit-fill-available;
  .lineOnlyForIVR-y{
    width: 2px;
    // height: -webkit-fill-available;
    background-color: black;
    height: calc(100% + 16px);
  }
  .lineOnlyForIVR-x{
    width: 50%;
    position: absolute;
    right: 0px;
    top: 50%;
    transform: translateY(-50%);
    background-color: black;
    height: 2px;

  }
  &.last{
    .lineOnlyForIVR-y{
      height: calc(50% + 1px);
    } 
  }
}
.OHgraphLeftSide{
  display: flex;
  flex-direction: column;
  align-items: center;
  position: absolute;
  right: -150%
}
.lineOnlyForOH{
  display: flex;
  justify-content: center;
  position: relative;
  padding-top: 35px;
  .lineOnlyForOH-y{
    width: 2px;
    background-color: black;
    height: calc(100px + 1px);
  }
  .lineOnlyForOH-x{
    width: 50%;
    position: absolute;
    left: 0px;
    top: 35px;
    background-color: black;
    height: 2px;
    &::before{
      content: 'No';
      position: absolute;
      left: 50%;
      transform: translateX(-50%);
      bottom: calc(50% + 0px);
    }

  }
  &.last{
    .lineOnlyForIVR-y{
      height: calc(50% + 1px);
    } 
  }
}
.textAboveHorizontalLine{
  position: absolute;
  left: calc(110px + 5%);
  top: calc(50% - 24px);
  max-width: 200px;
  // transform: translateX(-25%);
}
.textLeftVerticalLine{
  position: absolute;
  top: calc(50%);
  transform: translateY(-50%);
  left: calc(60px);
}
.ExtensionsIcon{
  display: flex;
  .ExtentionItself{
    display:flex;
    justify-content: center;
    align-items: center;
    border-radius: 12px;
    margin:0px 16px;
    padding: 8px;
    border-width: 3px;
    border-style:solid;
    &:first-child{
      margin-left: 0px;
    }
    &:last-child{
      margin-right: 0px;
    }
    &.statusOffline{
      border-color: #8a8989;
    }
    &.statusOnline{
      border-color: #29C941;
    }
  }
}
.nodeBasic-heading{
  font-size: 18px;
  font-weight: 600;
  line-height: 27px;
  color: black;
}
</style>