var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "participant-container",
    class: {
      'speaker': _vm.speaker
    },
    attrs: {
      "id": _vm.participant._id
    }
  }, [_vm.isVideoNotShown || !_vm.performanceSetting.show_video ? _c('div', {
    staticClass: "video-not-shown-8569"
  }, [_c('div', {
    staticClass: "position-relative",
    style: `${_vm.isHold ? 'overflow:visible' : ''}`
  }, [_c('img', {
    attrs: {
      "src": _vm.image,
      "alt": _vm.name
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.user_male;
      }
    }
  }), _vm.isHold ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-center isHold-container"
  }, [_c('div', {
    staticClass: "isHold-innerOverlay"
  }), _c('p', {
    staticClass: "p-2 mb-0 isHold-text"
  }, [_vm._v("On Hold")])]) : _vm._e()]), _c('span', {
    staticClass: "callParticipantsName"
  }, [_vm._v(_vm._s(_vm.name))])]) : _vm._e(), !!_vm.emoji ? _c('div', {
    staticClass: "hand-rise v2"
  }, [_c('div', {
    staticStyle: {
      "font-size": "25px"
    }
  }, [_vm._v("🖐")])]) : _vm._e(), _c('div', {
    class: `DuringJitsiCallMICmutedUnMutedNotification alwaysPresent ${_vm.muted ? 'active' : ''}`
  }, [_vm._m(0)]), _c('RemoteTracks', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: !_vm.isVideoNotShown,
      expression: "!isVideoNotShown"
    }],
    attrs: {
      "tracks": _vm.participant._tracks,
      "participant": _vm.participant,
      "JitsiConference": _vm.JitsiConference,
      "speaker_device_id": _vm.speaker_device_id,
      "videoCall": _vm.videoCall
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "DuringJitsiCallMICmutedUnMutedNotification-inner"
  }, [_c('span', [_vm._v("Microphone muted")])]);

}]

export { render, staticRenderFns }