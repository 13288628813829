var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Admin permissions ")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Employees in this group can see all fields, edit all fields, and do everything the system offers. Be careful with granting this access level! ")]), _c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "nameWithImage"
          }, [_c('div', {
            staticClass: "imageContainer"
          }, [_c('img', {
            attrs: {
              "src": "/img/1.dd976805.jpeg"
            }
          })]), _c('div', {
            staticClass: "nameWithImage-text"
          }, [_vm._v("Marie jane")])])]), _c('td', {
            staticClass: "dialer-col-right text-left two"
          }, [_c('b-form-checkbox', {
            staticClass: "HrmCheckBox-container"
          })], 1)]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead one pt-1"
  }, [_vm._v("Current employees with admin rights")]), _c('th', {
    staticClass: "HrmTableHead text-left two pt-1"
  }, [_vm._v("Employees")])])])], 2)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }