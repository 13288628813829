<template>
  <div>
    <modal v-if="true" class="dialer_animation right_side_popup AddNewBillingCard AddNewTicket" width="40%" height="auto" @before-open="onBeforeOpen" @before-close="onBeforeClose" :scrollable="true" :name="modalName" :clickToClose="false">
      <section class="ct-user-new dialer-edit">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="createsupportticket()">
          
          <div class="dialer-edit-header mt-3 mb-5">
            <h2 class="dialer-settings-title newer mb-0">Add New Ticket</h2>
            <div class="dialer-edit-actions">
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
              <a v-else class="newCloseButton" :disabled="api.ticket.send" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          
          <div class="md-mar-vertical">
            
            <b-alert :show="!!api.ticket.error_message" variant="danger">
              {{ api.ticket.error_message }}
            </b-alert>
            <div class="latestGreyBox-9-9-2023">
              <div class="whiteBGinputWithGreyRoundedBorder w-100">
                <label>Subject <span style="color:red;">*</span></label>
                <input id="add-support-ticket-subject" class="w-100" type="text"  v-model="forms.ticket.subject" maxlength="256" onkeypress="return /([0-9A-Za-z \s])/i.test(event.key)" :disabled="api.ticket.send" />
                <template v-if="forms.ticket.is_submitted">
                  <p v-if="$v.forms.ticket.subject.$invalid" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.ticket.subject.required">* subject is required</span>
                    <span v-else-if="!$v.forms.ticket.subject.minLength">* subject should be minimum 3 character</span>
                    <span v-else-if="!$v.forms.ticket.subject.maxLength">* subject can be maximum 256 character</span>
                  </p>
                </template>
                <template v-if="api.ticket.error.errors">
                  <p v-if="api.ticket.error.errors.subject" class="text text-danger animated bounceIntop">
                    <span v-for="(error_message, index) in api.ticket.error.errors.subject" :key="index">* {{ error_message }}</span>
                  </p>
                </template>
              </div>
              <div class="d-flex mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2">
                  <label>Department <span style="color:red;">*</span></label>
                  <vb-select id="add-support-ticket-department" v-model="forms.ticket.department" :defaultSelectedText="'Select Department'" :options="departmentsOptions" :disabled="api.departments.send || api.ticket.send" />
                  <template v-if="forms.ticket.is_submitted">
                    <p v-if="$v.forms.ticket.department.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.ticket.department.required">* Department is required</span>
                    </p>
                  </template>
                  <template v-if="api.ticket.error.errors">
                    <p v-if="api.ticket.error.errors.department" class="text text-danger animated bounceIntop">
                      <span v-for="(error_message, index) in api.ticket.error.errors.department" :key="index">* {{ error_message }}</span>
                    </p>
                  </template>
                </div>
                <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2">
                  <label class="mb-1">Priority <span style="color:red;">*</span></label>
                  <vb-select id="add-support-ticket-priority" v-model="forms.ticket.priority" :defaultSelectedText="'Select Priority'" :options="priorityOptions" :disabled="api.ticket.send" />
                  <template v-if="forms.ticket.is_submitted">
                    <p v-if="$v.forms.ticket.priority.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.ticket.priority.required">* Priority is required</span>
                    </p>
                  </template>
                  <template v-if="api.ticket.error.errors">
                    <p v-if="api.ticket.error.errors.priority" class="text text-danger animated bounceIntop">
                      <span v-for="(error_message, index) in api.ticket.error.errors.priority" :key="index">* {{ error_message }}</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder w-100 mt-20px">
                <label>Message <span style="color:red;">*</span></label>
                <form class="w-100" @submit.prevent="''">
                  <textarea placeholder="Write your message" rows="11" id="add-support-ticket-message" class="ticket-textArea-text w-suto p-3"
                  style="height: auto;width: -webkit-fill-available;" :disabled="api.ticket.send" v-model="forms.ticket.message" >
                    Hi, hope you are doing great today. Please check the assigned tasks. 
                    
                    Thanks
                  </textarea>
                </form>
                <template v-if="forms.ticket.is_submitted">
                  <p v-if="$v.forms.ticket.message.$invalid" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.ticket.message.required">* message is required</span>
                    
                  </p>
                </template>
                <template v-if="api.ticket.error.errors">
                  <p v-if="api.ticket.error.errors.message" class="text text-danger animated bounceIntop">
                    <span v-for="(error_message, index) in api.ticket.error.errors.message" :key="index">* {{ error_message }}</span>
                  </p>
                </template>
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
                  <div class="d-flex align-items-center justify-content-between w-100">
                    <div class="latestGreyBox-heading-main">Request a callback</div>
                    <b-check switch v-model="forms.ticket.call_me" :disabled="api.ticket.send" class="newerSwitch"/>
                  </div>
                  <template v-if="api.ticket.error.errors">
                    <p v-if="api.ticket.error.errors.call_me" class="text text-danger animated bounceIntop">
                      <span v-for="(error_message, index) in api.ticket.error.errors.call_me" :key="index">* {{ error_message }}</span>
                    </p>
                  </template>
                  <div v-if="forms.ticket.call_me" class="latestGreyBox-9-9-2023 mt-20px w-100">
                    <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion">
                      <label>Phone number<span style="color:red"> *</span></label>
                      <div class="problemDDInputContainer">
                        <vb-select :disabled="api.ticket.send" v-model="forms.ticket.country_code" class="problemDD" :options="numbersOption" />
                        <div class="d-flex flex-column w-100">
                          <b-input class="w-100" :disabled="api.ticket.send" v-model="forms.ticket.number" type="text" onkeypress="return /([0-9])/i.test(event.key)" placeholder="Add your phone number"  />
                          <template v-if="forms.ticket.is_submitted">
                            <p v-if="$v.forms.ticket.number.$invalid" class="text text-danger animated bounceIntop">
                              <span v-if="!$v.forms.ticket.number.required">* Phone Number is required</span>
                            </p>
                          </template>
                          <template v-if="api.ticket.error.errors">
                            <p v-if="api.ticket.error.errors.number" class="text text-danger animated bounceIntop">
                              <span v-for="(error_message, index) in api.ticket.error.errors.number" :key="index">* {{ error_message }}</span>
                            </p>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            

            <!--<div class="dialer-team-name mb-4 pb-2">
              <div class="dialer-input-field input-consistent-inner-shadows">
                <div class="w-100">
                  <label class="mb-1">Subject <span style="color:red;">*</span></label>
                  <input id="add-support-ticket-subject" class="w-100 input-billing" type="text"  v-model="forms.ticket.subject" maxlength="256" onkeypress="return /([0-9A-Za-z \s])/i.test(event.key)" :disabled="api.ticket.send" />
                </div>
                <template v-if="forms.ticket.is_submitted">
                  <p v-if="$v.forms.ticket.subject.$invalid" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.ticket.subject.required">* subject is required</span>
                    <span v-else-if="!$v.forms.ticket.subject.minLength">* subject should be minimum 3 character</span>
                    <span v-else-if="!$v.forms.ticket.subject.maxLength">* subject can be maximum 256 character</span>
                  </p>
                </template>
                <template v-if="api.ticket.error.errors">
                  <p v-if="api.ticket.error.errors.subject" class="text text-danger animated bounceIntop">
                    <span v-for="(error_message, index) in api.ticket.error.errors.subject" :key="index">* {{ error_message }}</span>
                  </p>
                </template>
              </div>
            </div>

            <div class="dialer-team-name mb-4 pb-2 d-flex">
              
              <div class="dialer-dropdown dialer-input-field input-consistent-inner-shadows dialer-dropdown-billing bg-white w-50 pr-2">
                <div class="w-100">
                  <label class="mb-1">Department <span style="color:red;">*</span></label>
                  <vb-select id="add-support-ticket-department" v-model="forms.ticket.department" :defaultSelectedText="'Select Department'" :options="departmentsOptions" :disabled="api.departments.send || api.ticket.send" />
                </div>
                <template v-if="forms.ticket.is_submitted">
                  <p v-if="$v.forms.ticket.department.$invalid" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.ticket.department.required">* Department is required</span>
                  </p>
                </template>
                <template v-if="api.ticket.error.errors">
                  <p v-if="api.ticket.error.errors.department" class="text text-danger animated bounceIntop">
                    <span v-for="(error_message, index) in api.ticket.error.errors.department" :key="index">* {{ error_message }}</span>
                  </p>
                </template>
              </div>

              <div class=" dialer-input-field input-consistent-inner-shadows  w-50 pl-2">
                
                <div class="w-100">
                  <label class="mb-1">Priority <span style="color:red;">*</span></label>
                  <vb-select id="add-support-ticket-priority" v-model="forms.ticket.priority" :defaultSelectedText="'Select Priority'" :options="priorityOptions" :disabled="api.ticket.send" />
                </div>
                <template v-if="forms.ticket.is_submitted">
                  <p v-if="$v.forms.ticket.priority.$invalid" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.ticket.priority.required">* Priority is required</span>
                  </p>
                </template>
                <template v-if="api.ticket.error.errors">
                  <p v-if="api.ticket.error.errors.priority" class="text text-danger animated bounceIntop">
                    <span v-for="(error_message, index) in api.ticket.error.errors.priority" :key="index">* {{ error_message }}</span>
                  </p>
                </template>
              </div>
            
            </div>

            <div class="dialer-input-field mb-0">
              <label class="mb-1">Message <span style="color:red;">*</span></label>
            </div>
            <div class="ticket-msg-textarea-container mb-5">
              <form class="w-100" @submit.prevent="''">
                <textarea placeholder="Write your message" rows="11" id="add-support-ticket-message" class="ticket-textArea-text w-suto p-3"
                 style="height: auto;width: -webkit-fill-available;" :disabled="api.ticket.send" v-model="forms.ticket.message" >
                  Hi, hope you are doing great today. Please check the assigned tasks. 
                  
                  Thanks
                </textarea>
              </form>
              <template v-if="forms.ticket.is_submitted">
                <p v-if="$v.forms.ticket.message.$invalid" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.ticket.message.required">* message is required</span>
                  
                </p>
              </template>
              <template v-if="api.ticket.error.errors">
                <p v-if="api.ticket.error.errors.message" class="text text-danger animated bounceIntop">
                  <span v-for="(error_message, index) in api.ticket.error.errors.message" :key="index">* {{ error_message }}</span>
                </p>
              </template>
            </div>

            

            <div class="callMeSwitch-container">
              <b-check switch v-model="forms.ticket.call_me" :disabled="api.ticket.send" class="dialer-switch"/>
              <div class="dialer-box-header">Request a callback</div>
              <template v-if="api.ticket.error.errors">
                <p v-if="api.ticket.error.errors.call_me" class="text text-danger animated bounceIntop">
                  <span v-for="(error_message, index) in api.ticket.error.errors.call_me" :key="index">* {{ error_message }}</span>
                </p>
              </template>
            </div>

            <div v-if="forms.ticket.call_me" class="dialer-input-field input-consistent-inner-shadows">
              <label>Phone number<span style="color:red"> *</span></label>
              <div class="problemDDInputContainer">
                <vb-select :disabled="api.ticket.send" v-model="forms.ticket.country_code" class="problemDD" :options="numbersOption" />
                <div class="d-flex flex-column w-100">
                  <b-input class="w-100" :disabled="api.ticket.send" v-model="forms.ticket.number" type="text" onkeypress="return /([0-9])/i.test(event.key)" placeholder="Add your phone number"  />
                  <template v-if="forms.ticket.is_submitted">
                    <p v-if="$v.forms.ticket.number.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.ticket.number.required">* Phone Number is required</span>
                    </p>
                  </template>
                  <template v-if="api.ticket.error.errors">
                    <p v-if="api.ticket.error.errors.number" class="text text-danger animated bounceIntop">
                      <span v-for="(error_message, index) in api.ticket.error.errors.number" :key="index">* {{ error_message }}</span>
                    </p>
                  </template>
                </div>
              </div>
            </div>-->
              
          </div>

          
          <div class="d-flex justify-content-end mt-4">
            <button id="add-support-ticket-submit" :disabled="api.ticket.send" class="newDoneButton">
              <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
              <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                <vb-spinner v-if="api.ticket.send" />
                <template v-else>Submit</template>
              </span>
            </button>
            <button id="add-support-ticket-cancel" type="button" :disabled="api.ticket.send" class="dialer-button dialer-button-transparent ml-3" @click="$modal.hide(modalName)">
              Cancel
            </button>
          </div>

        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { 
  required, 
  requiredIf, 
  // email, 
  minLength, 
  maxLength, 
} from "vuelidate/lib/validators";
import { VOIP_API } from '../../utils';
let number_options = {
  pk: {
    text: "PK(+92)",
    value: '+92',
  },
  uk: {
    text: "UK(+44)",
    value: '+44',
  },
  usa: {
    text: "USA(+1)",
    value: '+1',
  },
}
export default {
  name: "AddSupportTicketModal",
  props: {
    modalName: {
      type: String,
      default: 'AddSupportTicketModal',
    },
  },
  data() {
    return {
      forms: {
        ticket: {
          name: '',
          email: '',
          cc_recipents: '',
          subject: '',
          message: '',
          department: '',
          priority: 'Low',
          call_me: false,
          number: '',
          country_code: number_options.usa.value,
          reset(){
            this.name = ''
            this.email = ''
            this.cc_recipents = ''
            this.subject = ''
            this.message = ''
            this.department = ''
            this.priority = ''
            this.number = ''
            this.call_me = false
            this.country_code = number_options.usa.value
            this.is_submitted = false
          },
          is_submitted: false,
        },
      },
      response: {
        departments: [],
      },
      api: {
        ticket: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.error_message = ''
          },
        },
        departments: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.error_message = ''
          },
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    numbersOption(){ return Object.values(number_options) },
    priorityOptions(){
      return [
        {
          text: 'Low',
          value: 'Low',
        },
        {
          text: 'Medium',
          value: 'Medium',
        },
        {
          text: 'High',
          value: 'High',
        },
      ]
    },
    departmentsOptions(){
      return this.response.departments.map(item=>{
        return {
          text: item.name,
          value: item.id,
        }
      })
    },
    selectedDepartmentText(){
      const departmentid = this.forms.ticket.department
      const [departmentOption] = this.departmentsOptions.filter(item=>item.value==departmentid)
      return departmentOption ? departmentOption.text : ''
    },
    selectedPriorityText(){
      const priorityid = this.forms.ticket.priority
      const [priorityOption] = this.priorityOptions.filter(item=>item.value==priorityid)
      return priorityOption ? priorityOption.text : ''
    },
  },
  validations: {
    forms: {
      ticket: {
        // name: { 
        //   required,
        //   minLength: minLength(5),
        //   maxLength: maxLength(20),
        // },
        // email: { 
        //   required,
        //   email,
        //   maxLength: maxLength(25),
        // },
        subject: { 
          required,
          minLength: minLength(3),
          maxLength: maxLength(256),
        },
        message: { 
          required,
          // maxLength: maxLength(500),
        },
        department: { 
          required,
        },
        priority: { 
          required,
        },
        number: {
          required : requiredIf(function(){ return this.forms.ticket.call_me }),
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      this.forms.ticket.call_me=!!event?.params?.call_me
      this.fetchDepartments();
    },
    onBeforeClose(){
      this.forms.ticket.reset()
      this.api.ticket.reset()
      this.api.departments.reset()
      this.response.departments=[]
    },
    fetchDepartments(){
      let vm = this
      if(vm.api.departments.send) return;
      vm.api.departments.error={}
      vm.api.departments.error_message=''
      vm.api.departments.send=true
      VOIP_API.endpoints.supportticket.departments().then(({ data: { departments: { department } } })=>{
        vm.response.departments=department
      }).catch((ex)=>{
        vm.api.departments.error_message=ex.own_message
        vm.api.departments.error=ex.response_error || {}
      }).finally(()=>{
        vm.api.departments.send=false
      })
    },
    createsupportticket(){
      let vm = this
      vm.forms.ticket.is_submitted=true
      vm.$v.forms.ticket.$touch()
      if(vm.api.ticket.send || vm.$v.forms.ticket.$invalid) return;
      vm.api.ticket.error={}
      vm.api.ticket.error_message=''
      vm.api.ticket.send=true
      VOIP_API.endpoints.supportticket.create({
        accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
        uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
        name: vm.forms.ticket.name,
        email: vm.forms.ticket.email,
        cc_recipents: vm.forms.ticket.cc_recipents,
        subject: vm.forms.ticket.subject,
        message: vm.forms.ticket.message,
        department: vm.forms.ticket.department,
        priority: vm.forms.ticket.priority,
      }).then(({ data: { id } })=>{
        vm.$emit('added',{
          id: id,
        })
        vm.appNotify({
          message: 'Successfully Added',
          type: 'success',
        })
        vm.$modal.hide(vm.modalName)
      }).catch((ex)=>{
        vm.api.ticket.error_message=ex.own_message
        vm.api.ticket.error=ex.response_error || {}
      }).finally(()=>{
        vm.api.ticket.send=false
        vm.forms.ticket.is_submitted=false
      })
    },
  },
};
</script>
