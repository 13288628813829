var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Configure call recording")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Advance options for call recordings")])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "allow_recording_ref",
    staticClass: "dialer-switch",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_recording,
      "switch": "",
      "checked": _vm.selected.allow_recording
    },
    on: {
      "change": function ($event) {
        return _vm.updateAllowRecording($event);
      }
    }
  })], 1)]), _vm.selected.allow_recording ? _vm._l(_vm.recordingOptions, function (recordingOption) {
    return _c('div', {
      key: recordingOption.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          return _vm.updateSetting(recordingOption.value);
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(recordingOption.text))]), _vm.selected.setting == recordingOption.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }) : _vm._e()], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }