var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" System Info "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card"
  }, [_c('div', {
    staticClass: "crm-card-heading"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('div', [_vm._v(" Logs ")]), _c('button', {
    staticClass: "newButton onlyIcon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-exportFile-icon",
      "height": "38px",
      "width": "38px"
    }
  })], 1)])]), _vm._l(_vm.tableData, function (data) {
    return _c('div', {
      key: data.id,
      staticClass: "d-flex justify-content-between align-items-center crm-innerCard mt-4 w-100"
    }, [_c('div', {
      staticClass: "crm-card-heading my-0 w-50"
    }, [_vm._v(_vm._s(data.first))]), _c('p', {
      staticClass: "crm-card-text my-0 w-50",
      staticStyle: {
        "white-space": "normal",
        "word-break": "break-word"
      }
    }, [_vm._v(" " + _vm._s(data.last) + " ")])]);
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }