<template>
  <div>
    <div class="nodeBasic-new" >
      <Info :id="voipaccount" :is_blf="false" />
      <div class="w-100 d-flex justify-content-end">
        <span style="position: relative;">
          <b-icon 
            icon="pencil-fill" 
            @click="$emit('edit')"
          />
        </span>
      </div>
    </div>
    <div class="d-flex justify-content-center" style="width: 200px;">
      <div class="lineWithArrow">
        <div class="lineWithArrow-line"></div>
        <div class="lineWithArrow-arrow"></div>
      </div>
    </div>
  </div>
</template>

<script>
import Info from '../../../Lists/Info.vue';
export default {
  name: 'InfoNode',
  components: {
    Info,
  },
  props: {
    voipaccount: {
      type: String,
      default: ''
    },
  },
}
</script>

<style>

</style>