var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 min-width-50percent-modal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "name": "EditLocationModal",
      "scrollable": true
    },
    on: {
      "closed": _vm.onClose,
      "before-open": _vm.beforeOpen
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.editLocation.apply(null, arguments);
      }
    }
  }, [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Edit Location")]), _vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('EditLocationModal');
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "dialer-edit-actions w-100 d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.edit
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.edit ? _c('vb-spinner') : [_vm._v("Save")]], 2)], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Edit Location")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "id": "edit-location-modal-close"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('EditLocationModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1), _c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.edit
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.edit ? _c('vb-spinner') : [_vm._v("Save")]], 2)], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-0"
  }, [_c('label', [_vm._v("Select Extension")]), _c('b-select', {
    attrs: {
      "id": "edit-select-extension",
      "disabled": _vm.api_sent.edit || _vm.api_sent.list
    },
    model: {
      value: _vm.forms.edit_location.callerId,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_location, "callerId", $$v);
      },
      expression: "forms.edit_location.callerId"
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select Extensions")]), _vm._l(_vm.response.list.callerid, function (callerid, index) {
    return _c('option', {
      key: index,
      domProps: {
        "value": callerid
      }
    }, [_vm._v(_vm._s(callerid))]);
  })], 2), _vm.submitted.edit ? [!_vm.$v.forms.edit_location.callerId.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Extenion is required")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Select Country")]), _c('b-select', {
    attrs: {
      "id": "edit-select-country",
      "disabled": _vm.api_sent.edit || _vm.api_sent.list
    },
    model: {
      value: _vm.forms.edit_location.country,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_location, "country", $$v);
      },
      expression: "forms.edit_location.country"
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select Country")]), _vm._l(_vm.response.list.countries, function (country) {
    return _c('option', {
      key: country.Code2,
      domProps: {
        "value": country.Code2
      }
    }, [_vm._v(_vm._s(country.Name))]);
  })], 2), _vm.submitted.edit ? [!_vm.$v.forms.edit_location.country.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Country is required")])]) : _vm._e()] : _vm._e()], 2), _vm.forms.edit_location.country === 'GB' ? _c('div', {
    staticClass: "d-flex align-items-center justify-content-between whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Manually Enter the Address")]), _c('b-check', {
    staticClass: "newerSwitch custom-control custom-switch",
    attrs: {
      "id": "edit-manually-check",
      "disabled": _vm.api_sent.edit,
      "switch": ""
    },
    model: {
      value: _vm.is_manual_address,
      callback: function ($$v) {
        _vm.is_manual_address = $$v;
      },
      expression: "is_manual_address"
    }
  })], 1) : _vm._e(), _vm.is_manual_address ? [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Postal Code")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.postal_code,
      expression: "forms.manual_address.postal_code"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "edit-manual-postal-code",
      "type": "text",
      "disabled": _vm.api_sent.edit
    },
    domProps: {
      "value": _vm.forms.manual_address.postal_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "postal_code", $event.target.value);
      }
    }
  }), _vm.submitted.edit ? [!_vm.$v.forms.manual_address.postal_code.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Postal Code is required")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("City")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.city,
      expression: "forms.manual_address.city"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "edit-manual-city",
      "type": "text",
      "disabled": _vm.api_sent.edit
    },
    domProps: {
      "value": _vm.forms.manual_address.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "city", $event.target.value);
      }
    }
  }), _vm.submitted.edit ? [!_vm.$v.forms.manual_address.city.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* City is required")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Street")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.street,
      expression: "forms.manual_address.street"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "edit-manual-street",
      "type": "text",
      "disabled": _vm.api_sent.edit
    },
    domProps: {
      "value": _vm.forms.manual_address.street
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "street", $event.target.value);
      }
    }
  }), _vm.submitted.edit ? [!_vm.$v.forms.manual_address.street.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Street is required")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("House Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.manual_address.house_name,
      expression: "forms.manual_address.house_name"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "edit-manual-house-name",
      "type": "text",
      "disabled": _vm.api_sent.edit
    },
    domProps: {
      "value": _vm.forms.manual_address.house_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.manual_address, "house_name", $event.target.value);
      }
    }
  }), _vm.submitted.edit ? [!_vm.$v.forms.manual_address.house_name.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* House Name is required")])]) : _vm._e()] : _vm._e()], 2)] : [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Postal Code")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.uk_address.postal_code,
      expression: "forms.uk_address.postal_code"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "edit-auto-postal-code",
      "type": "text",
      "disabled": _vm.api_sent.edit
    },
    domProps: {
      "value": _vm.forms.uk_address.postal_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.uk_address, "postal_code", $event.target.value);
      }
    }
  }), _vm.submitted.edit ? [!_vm.$v.forms.uk_address.postal_code.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Postal Code is required")])]) : !_vm.$v.forms.uk_address.postal_code.valid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Postal Code is invalid")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("House Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.uk_address.house_name,
      expression: "forms.uk_address.house_name"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "edit-auto-house-no",
      "type": "text",
      "disabled": _vm.api_sent.edit
    },
    domProps: {
      "value": _vm.forms.uk_address.house_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.uk_address, "house_name", $event.target.value);
      }
    }
  }), _vm.submitted.edit ? [!_vm.$v.forms.uk_address.house_name.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* House Name is required")])]) : _vm._e()] : _vm._e()], 2), _vm.forms.uk_address.address && _vm.response.address.addresses.indexOf(_vm.forms.uk_address.address) == -1 ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder d-flex align-items-center justify-content-between mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Select Address")]), _c('b-check', {
    staticClass: "newerSwitch custom-control custom-switch",
    attrs: {
      "id": "edit-auto-select-address-check",
      "disabled": _vm.api_sent.edit,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        _vm.forms.uk_address.address = '';
        _vm.get_address_from_select = !_vm.get_address_from_select;
      }
    }
  })], 1) : _vm._e(), _vm.forms.uk_address.address && _vm.response.address.addresses.indexOf(_vm.forms.uk_address.address) == -1 ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Address")]), _c('input', {
    staticClass: "w-100",
    attrs: {
      "id": "edit-auto-address",
      "type": "text",
      "disabled": true
    },
    domProps: {
      "value": _vm.forms.uk_address.address
    }
  })]) : _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Select Address")]), _c('b-select', {
    attrs: {
      "id": "edit-auto-seelct-address",
      "disabled": _vm.api_sent.edit
    },
    model: {
      value: _vm.forms.uk_address.address,
      callback: function ($$v) {
        _vm.$set(_vm.forms.uk_address, "address", $$v);
      },
      expression: "forms.uk_address.address"
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select Address")]), _vm._l(_vm.response.address.addresses, function (address) {
    return _c('option', {
      key: address,
      domProps: {
        "value": address
      }
    }, [_vm._v(_vm._s(address.replace(/,/g, '').replace(/\s+/g, ' ').trim()))]);
  })], 2), _vm.submitted.edit ? [!_vm.$v.forms.uk_address.address.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Address is required")])]) : _vm._e()] : _vm._e()], 2)], _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("First Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_location.forename,
      expression: "forms.edit_location.forename"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "edit-first-name",
      "type": "text",
      "disabled": _vm.api_sent.edit
    },
    domProps: {
      "value": _vm.forms.edit_location.forename
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_location, "forename", $event.target.value);
      }
    }
  }), _vm.submitted.edit ? [!_vm.$v.forms.edit_location.forename.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Forename is required")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Last Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_location.surname,
      expression: "forms.edit_location.surname"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "edit-last-name",
      "type": "text",
      "disabled": _vm.api_sent.edit
    },
    domProps: {
      "value": _vm.forms.edit_location.surname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_location, "surname", $event.target.value);
      }
    }
  }), _vm.submitted.edit ? [!_vm.$v.forms.edit_location.surname.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* Surname is required")])]) : _vm._e()] : _vm._e()], 2)], 2), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this Address")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete-alt",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.deleteLocation(_vm.location.real_id);
      }
    }
  }, [[_vm._v("Delete")]], 2)])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }