var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "addressBookContainer"
  }, [_vm.conditions.screen == _vm.screens.add ? _c('div', {
    staticClass: "dialer_contact_list allow_scroll add"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createAddressbook();
      }
    }
  }, [_c('div', {
    staticClass: "dialer_activity_box_header"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('a', {
    staticClass: "goToPreviousScreen",
    on: {
      "click": function ($event) {
        _vm.api.create_addressbook.send ? '' : _vm.conditions.screen = _vm.screens.list;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1), _c('h4', {
    staticClass: "w-100 text-center"
  }, [_vm._v("Add contact")])])]), _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 mt-0"
  }, [_c('vb-select', {
    staticClass: "right-0 selectVoice-dropdown",
    attrs: {
      "disabled": _vm.api.create_addressbook.send,
      "defaultSelectedText": 'Select Type',
      "options": _vm.typeOptions
    },
    on: {
      "change": function ($event) {
        _vm.forms.create_addressbook.firstname = '';
        _vm.forms.create_addressbook.lastname = '';
        _vm.forms.create_addressbook.company = '';
        _vm.forms.create_addressbook.jobtitle = '';
        _vm.forms.create_addressbook.nickname = 'nickname';
        _vm.forms.create_addressbook.department = '';
        _vm.forms.create_addressbook.suffix = '';
        _vm.forms.create_addressbook.note = '';
        _vm.forms.create_addressbook.emails = _vm.formAllowFields.emails.required ? [{
          emails: ''
        }] : [];
        _vm.forms.create_addressbook.phones = [{
          country: 'GB',
          phone: 'Main',
          number: '',
          shortcode: ''
        }];
      }
    },
    model: {
      value: _vm.forms.create_addressbook.type,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_addressbook, "type", $$v);
      },
      expression: "forms.create_addressbook.type"
    }
  }), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.type.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_addressbook.type.required ? _c('span', [_vm._v("* Type is required")]) : _vm._e()]) : _vm._e()], 1), _vm.formAllowFields.firstname ? _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px mb-0",
    attrs: {
      "label": "First name *"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.create_addressbook.send,
      "maxlength": _vm.$v.forms.create_addressbook.firstname.$params.maxLength.max,
      "placeholder": "First name"
    },
    model: {
      value: _vm.forms.create_addressbook.firstname,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_addressbook, "firstname", $$v);
      },
      expression: "forms.create_addressbook.firstname"
    }
  }), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.firstname.$invalid || _vm.api.create_addressbook.validation_errors.firstname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_addressbook.firstname.required ? _c('span', [_vm._v("*First name is required")]) : _vm._e(), !_vm.$v.forms.create_addressbook.firstname.valid ? _c('span', [_vm._v("*First name is invalid")]) : _vm._e(), !_vm.$v.forms.create_addressbook.firstname.maxLength ? _c('span', [_vm._v(" *First name should be more than " + _vm._s(_vm.$v.forms.create_addressbook.firstname.$params.maxLength.max) + " characters ")]) : _vm._e(), _vm._l(_vm.api.create_addressbook.validation_errors.firstname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.lastname ? _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 mt-20px",
    attrs: {
      "label": "Last name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.create_addressbook.send,
      "placeholder": "Last name",
      "maxlength": _vm.$v.forms.create_addressbook.lastname.$params.maxLength.max
    },
    model: {
      value: _vm.forms.create_addressbook.lastname,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_addressbook, "lastname", $$v);
      },
      expression: "forms.create_addressbook.lastname"
    }
  }), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.lastname.$invalid || _vm.api.create_addressbook.validation_errors.lastname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_addressbook.lastname.maxLength ? _c('span', [_vm._v("*Last name should be more than " + _vm._s(_vm.$v.forms.create_addressbook.lastname.$params.maxLength.max) + " characters")]) : _vm._e(), !_vm.$v.forms.create_addressbook.lastname.valid ? _c('span', [_vm._v("*Last name is invalid")]) : _vm._e(), _vm._l(_vm.api.create_addressbook.validation_errors.lastname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.company ? _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 mt-20px",
    attrs: {
      "label": "Company"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "placeholder": "Company",
      "disabled": _vm.api.create_addressbook.send
    },
    model: {
      value: _vm.forms.create_addressbook.company,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_addressbook, "company", $$v);
      },
      expression: "forms.create_addressbook.company"
    }
  }), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.company.$invalid || _vm.api.create_addressbook.validation_errors.company ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_addressbook.company.minLength ? _c('span', [_vm._v(" *Company should be more than " + _vm._s(_vm.$v.forms.create_addressbook.company.$params.minLength.min) + " characters ")]) : _vm._e(), !_vm.$v.forms.create_addressbook.company.valid ? _c('span', [_vm._v("*Company is invalid")]) : _vm._e(), _vm._l(_vm.api.create_addressbook.validation_errors.company, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.phones.allow ? _c('div', {
    staticClass: "add_fields add_phone_box mt-20px"
  }, [_vm._l(_vm.forms.create_addressbook.phones, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "fullWidthLessHeightGreyButton pl-3 pr-2 py-2 mt-2 flex-column align-items-start w-100 h-auto"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center w-100"
    }, [_c('p', {
      staticClass: "mb-0 d-flex align-items-center"
    }, [_c('span', {
      staticClass: "mr-1"
    }, [_vm._v(_vm._s(data.phone))]), _c('span', {
      staticClass: "mx-1"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(data.number, data.country)))]), _c('span', {
      staticClass: "mx-1 font-14"
    }, [_vm._v(_vm._s(data.shortcode))])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('b-button', {
      staticClass: "p-0 border-0 d-flex align-items-center",
      attrs: {
        "variant": "link",
        "disabled": _vm.api.create_addressbook.send
      },
      on: {
        "click": function ($event) {
          return _vm.$modal.show('UpdatePhoneNumber', {
            phone: data.phone,
            shortcode: data.shortcode,
            country: data.country,
            number: data.number,
            fields: {
              type: _vm.formAllowFields.phones.type,
              short_code: _vm.formAllowFields.phones.short_code
            },
            index: index,
            create: true
          });
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "pencil-fill",
        "variant": "black",
        "font-scale": "0.99"
      }
    })], 1), (_vm.formAllowFields.phones.required ? _vm.forms.create_addressbook.phones.length > 1 : true) ? _c('b-button', {
      staticClass: "p-0 ml-2 border-0 d-flex align-items-center",
      attrs: {
        "variant": "link",
        "disabled": _vm.api.create_addressbook.send
      },
      on: {
        "click": function ($event) {
          return _vm.forms.create_addressbook.phones.splice(index, 1);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "variant": "danger",
        "font-scale": "0.99",
        "icon": "trash-fill"
      }
    })], 1) : _vm._e()], 1)]), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.phones.$each[index].phone.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop font-14 mt-1"
    }, [!_vm.$v.forms.create_addressbook.phones.$each[index].phone.required ? _c('span', [_vm._v("* Type is required")]) : _vm._e()]) : _vm._e(), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.phones.$each[index].number.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop mb-0 font-14 mt-1"
    }, [!_vm.$v.forms.create_addressbook.phones.$each[index].number.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.create_addressbook.phones.$each[index].number.validNumber ? _c('span', [_vm._v("* Number is invalid")]) : _vm._e()]) : _vm._e()]);
  }), _vm.forms.create_addressbook.phones.length < _vm.formAllowFields.phones.length ? _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-2"
  }, [_c('a', {
    staticClass: "fullWidthLessHeightGreyButton",
    on: {
      "click": function ($event) {
        _vm.api.create_addressbook.send ? '' : _vm.forms.create_addressbook.phones.push({
          country: 'GB',
          number: '',
          phone: _vm.formAllowFields.phones.type ? 'Main' : '',
          shortcode: _vm.formAllowFields.phones.short_code ? _vm.filterShortCodes[0] : ''
        });
      }
    }
  }, [_vm._v(" Add phone ")])])])], 1) : _vm._e(), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.phones.$invalid || _vm.api.create_addressbook.validation_errors.phones ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.create_addressbook.validation_errors.phones, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 2) : _vm._e(), _vm.formAllowFields.emails.allow ? _c('div', {
    staticClass: "add_fields add_email_box mt-20px"
  }, [_vm._l(_vm.forms.create_addressbook.emails, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "fullWidthLessHeightGreyButton mt-2 pl-3 pr-2 py-2 mt-2 flex-column align-items-start w-100 h-auto"
    }, [_c('div', {
      staticClass: "d-flex justify-content-between align-items-center w-100"
    }, [_c('p', {
      staticClass: "mb-0 d-flex align-items-center"
    }, [_c('span', {
      staticClass: "mr-1"
    }, [_vm._v(_vm._s(data.emails))])]), _c('div', {
      staticClass: "d-flex"
    }, [_c('b-button', {
      staticClass: "p-0 border-0 d-flex align-items-center",
      attrs: {
        "variant": "link",
        "disabled": _vm.api.create_addressbook.send
      },
      on: {
        "click": function ($event) {
          return _vm.$modal.show('UpdateEmail', {
            email: data.emails,
            index: index,
            create: true
          });
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "pencil-fill",
        "variant": "black",
        "font-scale": "0.99"
      }
    })], 1), (_vm.formAllowFields.emails.required ? _vm.forms.create_addressbook.emails.length > 1 : true) ? _c('b-button', {
      staticClass: "p-0 border-0 d-flex align-items-center ml-2",
      attrs: {
        "variant": "link",
        "disabled": _vm.api.create_addressbook.send
      },
      on: {
        "click": function ($event) {
          _vm.api.create_addressbook.send ? '' : _vm.forms.create_addressbook.emails.splice(index, 1);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "variant": "danger",
        "font-scale": "0.99",
        "icon": "trash-fill"
      }
    })], 1) : _vm._e()], 1)]), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.emails.$each[index].emails.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop font-14 mt-1"
    }, [!_vm.$v.forms.create_addressbook.emails.$each[index].emails.required ? _c('span', [_vm._v("* email is required")]) : _vm._e(), !_vm.$v.forms.create_addressbook.emails.$each[index].emails.email ? _c('span', [_vm._v("* email is not valid")]) : _vm._e()]) : _vm._e()]);
  }), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-2 mb-20px"
  }, [_vm.forms.create_addressbook.emails.length < _vm.formAllowFields.emails.length ? _c('a', {
    staticClass: "fullWidthLessHeightGreyButton",
    on: {
      "click": function ($event) {
        _vm.api.create_addressbook.send ? '' : _vm.forms.create_addressbook.emails.unshift({
          emails: ''
        });
      }
    }
  }, [_vm._v(" Add email ")]) : _vm._e()])]), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.emails.$invalid || _vm.api.create_addressbook.validation_errors.emails ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.create_addressbook.validation_errors.emails, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1)], 2) : _vm._e(), _vm.formAllowFields.jobtitle ? _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0",
    attrs: {
      "label": "job title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.create_addressbook.send,
      "placeholder": "Job title"
    },
    model: {
      value: _vm.forms.create_addressbook.jobtitle,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_addressbook, "jobtitle", $$v);
      },
      expression: "forms.create_addressbook.jobtitle"
    }
  }), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.jobtitle.$invalid || _vm.api.create_addressbook.validation_errors.jobtitle ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_addressbook.jobtitle.minLength ? _c('span', [_vm._v(" Job title should be more than " + _vm._s(_vm.$v.forms.create_addressbook.jobtitle.$params.minLength.min) + " characters ")]) : _vm._e(), !_vm.$v.forms.create_addressbook.jobtitle.valid ? _c('span', [_vm._v("Job title is invalid")]) : _vm._e(), _vm._l(_vm.api.create_addressbook.validation_errors.jobtitle, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.department ? _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 mt-20px",
    attrs: {
      "label": "Department"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.create_addressbook.send,
      "placeholder": "Department"
    },
    model: {
      value: _vm.forms.create_addressbook.department,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_addressbook, "department", $$v);
      },
      expression: "forms.create_addressbook.department"
    }
  }), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.department.$invalid || _vm.api.create_addressbook.validation_errors.department ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_addressbook.department.minLength ? _c('span', [_vm._v(" *Department should be more than " + _vm._s(_vm.$v.forms.create_addressbook.department.$params.minLength.min) + " characters ")]) : _vm._e(), !_vm.$v.forms.create_addressbook.department.invalid ? _c('span', [_vm._v("*Department is invalid")]) : _vm._e(), _vm._l(_vm.api.create_addressbook.validation_errors.department, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.suffix ? _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 mt-12px"
  }, [_c('vb-select', {
    staticClass: "right-0 selectVoice-dropdown",
    attrs: {
      "disabled": _vm.api.create_addressbook.send,
      "defaultSelectedText": 'Select suffix',
      "options": _vm.suffixs
    },
    model: {
      value: _vm.forms.create_addressbook.suffix,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_addressbook, "suffix", $$v);
      },
      expression: "forms.create_addressbook.suffix"
    }
  }), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.suffix.$invalid || _vm.api.create_addressbook.validation_errors.suffix ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, _vm._l(_vm.api.create_addressbook.validation_errors.suffix, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.note ? _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 mt-20px"
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.create_addressbook.send,
      "placeholder": "Note"
    },
    model: {
      value: _vm.forms.create_addressbook.note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_addressbook, "note", $$v);
      },
      expression: "forms.create_addressbook.note"
    }
  }), _vm.forms.create_addressbook.submitted && _vm.$v.forms.create_addressbook.note.$invalid || _vm.api.create_addressbook.validation_errors.note ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.create_addressbook.note.invalid ? _c('span', [_vm._v("*Note is invalid")]) : _vm._e(), _vm._l(_vm.api.create_addressbook.validation_errors.note, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.create_addressbook.send,
      "type": "submit"
    }
  }, [_vm.api.create_addressbook.send ? _c('vb-spinner') : [_vm._v("Save")]], 2)])], 1)], 1) : _vm.conditions.screen == _vm.screens.list ? _c('div', [_c('div', {
    staticClass: "tab-pane active"
  }, [_c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_vm.getIsMobile ? _c('div', [_c('div', {
    staticClass: "inContacts-contacts-searchBar-container"
  }, [_c('b-form', {
    staticClass: "updatingContactsAgain-contacts-searchBar-container",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "updatingContactsAgain-contacts-searchBar"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "mobile-searchBar-icon-likeAirCall-icon",
      "width": "12.68px",
      "height": "12.67px"
    }
  }), _c('b-input', {
    attrs: {
      "placeholder": "Search"
    },
    model: {
      value: _vm.filter.addressbooks.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.addressbooks, "search", $$v);
      },
      expression: "filter.addressbooks.search"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "filterDD noPaddingInButton",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          attrs: {
            "icon": "mobile-funnel-icon-likeAirCall-icon",
            "width": "18.61px",
            "height": "18.61px"
          }
        })];
      },
      proxy: true
    }], null, false, 1712499967)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'all';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'all' ? _c('b-icon', {
    staticClass: "mr-2",
    style: `opacity:1;`,
    attrs: {
      "icon": "check"
    }
  }) : _vm._e(), _c('div', [_vm._v("All")])], 1)]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'local';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'local' ? _c('b-icon', {
    staticClass: "mr-2",
    style: `opacity:1;`,
    attrs: {
      "icon": "check"
    }
  }) : _vm._e(), _c('div', [_vm._v("Local")])], 1)]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'org';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'org' ? _c('b-icon', {
    staticClass: "mr-2",
    style: `opacity:1;`,
    attrs: {
      "icon": "check"
    }
  }) : _vm._e(), _c('div', [_vm._v("Organization")])], 1)]), _vm._l(_vm.response.integrateds, function (platform) {
    return _c('b-dropdown-item', {
      key: platform.platform,
      on: {
        "click": function ($event) {
          _vm.filter.addressbooks.type = platform.platform;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.filter.addressbooks.type == platform.platform ? _c('b-icon', {
      staticClass: "mr-2",
      style: `opacity:1;`,
      attrs: {
        "icon": "check"
      }
    }) : _vm._e(), _c('div', [_vm._v(_vm._s(platform.platform))])], 1)]);
  })], 2), _c('vb-icon', {
    staticStyle: {
      "cursor": "pointer"
    },
    attrs: {
      "icon": "mobile-plus-icon-likeAirCall-icon",
      "width": "20.38px",
      "height": "20.38px"
    },
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.add_addressbooks = true;
      }
    }
  })], 1)], 1), _c('div', {
    staticClass: "dialer_contact_list addressbook_list allow_scroll"
  }, [_vm._l(_vm.filterAddressBooks, function (addressbook) {
    return _c('div', {
      key: addressbook.real_id,
      staticClass: "dialer_contact_row"
    }, [_c('div', {
      staticClass: "updatingContactsAgain-contact-item"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('vb-avatar', {
      staticClass: "sm-mar-right",
      attrs: {
        "image": _vm._f("get_property")(addressbook, 'profile_image')
      }
    }), _c('div', {
      staticClass: "user-info"
    }, [_c('div', {
      staticClass: "user-name-box"
    }, [_c('span', {
      staticClass: "user-name"
    }, [_c('span', [_vm._v(_vm._s(`${addressbook.firstName} ${addressbook.lastName || ''}`))])])])])], 1), _vm.api.delete_addressbook.send == addressbook.real_id ? _c('vb-spinner', {
      attrs: {
        "variant": "primary"
      }
    }) : _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('vb-icon', {
            staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
            attrs: {
              "icon": "mobile-topBar-threeDots-likeAirCall-icon",
              "height": "27.06px",
              "width": "6px"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.audioCall(addressbook);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "telephone-fill"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.setUpdate(addressbook);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "pencil-square"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.deleteAddressBook(addressbook);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "trash-fill"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Delete")])], 1), _c('b-dropdown-item', {
      attrs: {
        "disabled": _vm.api.toggle_favourite.send.includes(addressbook.real_id)
      },
      on: {
        "click": function ($event) {
          return _vm.toggleFavourite(addressbook);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "star-fill"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(addressbook.is_favourite ? 'Remove from favourites' : 'Add to favourites'))])], 1)], 1)], 1)]);
  }), _vm.api.fetch_addressbooks.send ? _c('vb-loading') : _vm.isEmpty(_vm.filterAddressBooks) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.fetch_addressbooks.send ? '' : 'There is no addressbook',
      "design": 3
    }
  }) : _vm._e()], 2)]) : _c('div', [_c('div', {
    staticClass: "integrationFilterWithSearchBar-container mb-3"
  }, [_c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder flex-fill",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Search here"
    },
    model: {
      value: _vm.filter.addressbooks.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.addressbooks, "search", $$v);
      },
      expression: "filter.addressbooks.search"
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD withCustomSvgCheck"
  }, [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical",
            "font-scale": "1.7"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'all';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'all' ? _c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _vm._e(), _c('div', [_vm._v("All")])], 1)]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'local';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'local' ? _c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _vm._e(), _c('div', [_vm._v("Local")])], 1)]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.addressbooks.type = 'org';
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.filter.addressbooks.type == 'org' ? _c('vb-svg', {
    attrs: {
      "name": "dialer-tick",
      "width": "10.5",
      "height": "7.584",
      "viewBox": "0 0 10.5 7.584",
      "stroke-width": "0",
      "stroke": "#005DFF",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _vm._e(), _c('div', [_vm._v("Organization")])], 1)]), _vm._l(_vm.response.integrateds, function (platform) {
    return _c('b-dropdown-item', {
      key: platform.platform,
      on: {
        "click": function ($event) {
          _vm.filter.addressbooks.type = platform.platform;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.filter.addressbooks.type == platform.platform ? _c('vb-svg', {
      attrs: {
        "name": "dialer-tick",
        "width": "10.5",
        "height": "7.584",
        "viewBox": "0 0 10.5 7.584",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }) : _vm._e(), _c('div', [_vm._v(_vm._s(platform.platform))])], 1)]);
  })], 2)], 1)], 1), _c('div', {
    staticClass: "dialer-addContact cursor_pointer",
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.add_addressbooks = true;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-addUser-icon",
      "width": "23.401",
      "height": "18.295",
      "viewBox": "0 0 23.401 18.295",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', [_vm._v("New contact")])], 1), _c('div', {
    staticClass: "dialer_contact_list addressbook_list allow_scroll"
  }, [_vm._l(_vm.filterAddressBooks, function (addressbook) {
    return _c('div', {
      key: addressbook.real_id,
      staticClass: "dialer_contact_row"
    }, [_c('div', {
      staticClass: "dialer_contact"
    }, [_c('div', {
      staticClass: "dialer_contact-imageContainer"
    }, [_c('vb-avatar', {
      attrs: {
        "image": _vm._f("get_property")(addressbook, 'profile_image')
      }
    }), addressbook.tags && addressbook.integration_ref_id ? _c('vb-icon', {
      staticClass: "integrationType-icon",
      attrs: {
        "icon": _vm._f("integrationIcons")(addressbook.tags)
      },
      on: {
        "click": function ($event) {
          return _vm.openIntegrationContactDetail(addressbook);
        }
      }
    }) : _vm._e()], 1), _c('div', {
      staticClass: "user-info"
    }, [_c('div', {
      staticClass: "user-name-box"
    }, [_c('span', {
      staticClass: "user-name"
    }, [_c('span', [_vm._v(_vm._s(`${addressbook.firstName} ${addressbook.lastName || ''}`))]), addressbook.is_favourite == 1 ? _c('span', [_c('b-icon', {
      attrs: {
        "icon": "star-fill",
        "variant": "primary"
      }
    })], 1) : _vm._e()]), _c('div', {
      staticClass: "dialer_contact_hidden_area"
    }, [_c('div', {
      staticClass: "dialer_contact_connection_box"
    }, [_c('span', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute thereIsAnotherDropdownInside"
    }, [_vm.api.delete_addressbook.send == addressbook.real_id ? _c('vb-spinner', {
      attrs: {
        "variant": "primary"
      }
    }) : _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.audioCall(addressbook);
        }
      }
    }, [_c('vb-svg', {
      staticClass: "mr-2",
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', [_vm._v("Call")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.setUpdate(addressbook);
        }
      }
    }, [_c('vb-svg', {
      staticClass: "mr-2",
      attrs: {
        "name": "dialer-edit-icon",
        "width": "16",
        "height": "16",
        "viewBox": "0 0 24 24",
        "stroke-width": "1.2",
        "stroke": "currentColor",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', [_vm._v("Edit")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.deleteAddressBook(addressbook);
        }
      }
    }, [_c('vb-svg', {
      staticClass: "dialer-icon dialer-delete-icon mr-2",
      attrs: {
        "name": "dialer-delete-icon",
        "width": "16",
        "height": "16",
        "viewBox": "0 0 24 24",
        "stroke-width": "1.2",
        "stroke": "currentColor",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', [_vm._v("Delete")])], 1), addressbook.global == 0 && !addressbook.tags ? _c('b-dropdown-form', {
      on: {
        "submit": function ($event) {
          $event.stopPropagation();
          $event.preventDefault();
          '';
        }
      }
    }, [_c('b-form-group', {
      attrs: {
        "label-for": "dropdown-form-email"
      }
    }, [_c('b-form-select', {
      attrs: {
        "disabled": _vm.api.sync_contact.send,
        "size": "sm"
      },
      on: {
        "change": function ($event) {
          return _vm.syncContact(addressbook, $event);
        }
      },
      scopedSlots: _vm._u([{
        key: "first",
        fn: function () {
          return [_c('b-form-select-option', {
            attrs: {
              "value": null,
              "disabled": ""
            }
          }, [_vm._v("Sync integration")])];
        },
        proxy: true
      }], null, true),
      model: {
        value: addressbook.tags,
        callback: function ($$v) {
          _vm.$set(addressbook, "tags", $$v);
        },
        expression: "addressbook.tags"
      }
    }, _vm._l(_vm.response.integrateds, function (data) {
      return _c('b-form-select-option', {
        key: data.platform,
        attrs: {
          "value": data.platform
        }
      }, [_c('span', {
        staticStyle: {
          "width": "16px",
          "display": "inline-block"
        }
      }), _vm._v(_vm._s(data.platform) + " ")]);
    }), 1)], 1)], 1) : _vm._e(), _c('b-dropdown-item', {
      attrs: {
        "disabled": _vm.api.toggle_favourite.send.includes(addressbook.real_id)
      },
      on: {
        "click": function ($event) {
          return _vm.toggleFavourite(addressbook);
        }
      }
    }, [_c('b-icon', {
      staticClass: "mr-2",
      attrs: {
        "icon": addressbook.is_favourite ? 'star-fill' : 'star'
      }
    }), _c('span', [_vm._v(_vm._s(addressbook.is_favourite ? 'Remove from favourites' : 'Add to favourites'))])], 1)], 1)], 1)])])]), _c('div', {
      staticClass: "user-number"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(addressbook, 'main_phone.number'))))])])])]);
  }), _vm.api.fetch_addressbooks.send ? _vm._l(3, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "d-flex mt-20px align-items-center w-100"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-3",
      staticStyle: {
        "height": "40px",
        "width": "40px"
      }
    }), _vm._m(0, true)]);
  }) : _vm.isEmpty(_vm.filterAddressBooks) ? _c('div', {
    staticClass: "emptyScreenContainer mt-32px b-0 p-0"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')
    }
  }) : _c('img', {
    attrs: {
      "width": "50%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/addressBook.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading mt-32px"
  }, [_vm._v("Explore address book")])]) : _vm._e()], 2)])])])])]) : _vm.conditions.screen == _vm.screens.update ? _c('div', {
    staticClass: "dialer_contact_list allow_scroll add"
  }, [_c('b-form', {
    staticClass: "dialer_form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateAddressbook();
      }
    }
  }, [_c('div', {
    staticClass: "dialer_activity_box_header"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('a', {
    staticClass: "goToPreviousScreen",
    on: {
      "click": function ($event) {
        _vm.api.update_addressbook.send ? '' : _vm.conditions.screen = _vm.screens.list;
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "leftLongArrow-icon",
      "width": "20",
      "height": "12",
      "viewBox": "0 0 30 16",
      "stroke-width": "1",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1), _c('h4', {
    staticClass: "w-100 text-center"
  }, [_vm._v("Edit contact")])])]), _c('div', {
    staticClass: "profile-image-section-container",
    on: {
      "click": function ($event) {
        _vm.$modal.show('AddressBookProfileImageUploader', {
          type: 'ADDRESS_BOOK',
          accountcode: _vm.forms.update_addressbook.id,
          current_image: _vm.getProperty(_vm.editAddressBook, 'profile_image')
        });
      }
    }
  }, [_c('div', {
    staticClass: "profile-image-section"
  }, [_c('div', {
    staticClass: "profile-img"
  }, [_c('img', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    },
    attrs: {
      "src": _vm._f("get_property")(_vm.editAddressBook, 'profile_image')
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _vm.formAllowFields.firstname ? _c('b-form-group', {
    staticClass: "mb-0 mt-20px whiteBGinputWithGreyRoundedBorder",
    attrs: {
      "label": "First name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.update_addressbook.send,
      "maxlength": _vm.$v.forms.update_addressbook.firstname.$params.maxLength.max,
      "placeholder": "First name"
    },
    model: {
      value: _vm.forms.update_addressbook.firstname,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_addressbook, "firstname", $$v);
      },
      expression: "forms.update_addressbook.firstname"
    }
  }), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.firstname.$invalid || _vm.api.update_addressbook.validation_errors.firstname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_addressbook.firstname.required ? _c('span', [_vm._v("*First name is required")]) : _vm._e(), !_vm.$v.forms.update_addressbook.firstname.valid ? _c('span', [_vm._v("*First name is invalid")]) : _vm._e(), !_vm.$v.forms.update_addressbook.firstname.maxLength ? _c('span', [_vm._v(" First name should be more than " + _vm._s(_vm.$v.forms.update_addressbook.firstname.$params.maxLength.max) + " characters ")]) : _vm._e(), _vm._l(_vm.api.update_addressbook.validation_errors.firstname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.lastname ? _c('b-form-group', {
    staticClass: "mb-0 mt-20px whiteBGinputWithGreyRoundedBorder",
    attrs: {
      "label": "Last name"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.update_addressbook.send,
      "maxlength": _vm.$v.forms.update_addressbook.lastname.$params.maxLength.max,
      "placeholder": "Last name"
    },
    model: {
      value: _vm.forms.update_addressbook.lastname,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_addressbook, "lastname", $$v);
      },
      expression: "forms.update_addressbook.lastname"
    }
  }), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.lastname.$invalid || _vm.api.update_addressbook.validation_errors.lastname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_addressbook.lastname.valid ? _c('span', [_vm._v("Last name is invalid")]) : _vm._e(), !_vm.$v.forms.update_addressbook.lastname.maxLength ? _c('span', [_vm._v(" Last name should be more than " + _vm._s(_vm.$v.forms.update_addressbook.lastname.$params.maxLength.max) + " characters ")]) : _vm._e(), _vm._l(_vm.api.update_addressbook.validation_errors.lastname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.company ? _c('b-form-group', {
    staticClass: "mb-0 mt-20px whiteBGinputWithGreyRoundedBorder",
    attrs: {
      "label": " Company"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.update_addressbook.send,
      "placeholder": "Company"
    },
    model: {
      value: _vm.forms.update_addressbook.company,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_addressbook, "company", $$v);
      },
      expression: "forms.update_addressbook.company"
    }
  }), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.company.$invalid || _vm.api.update_addressbook.validation_errors.company ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_addressbook.company.valid ? _c('span', [_vm._v("*Company is invalid")]) : _vm._e(), !_vm.$v.forms.update_addressbook.company.minLength ? _c('span', [_vm._v(" *Company should be more than " + _vm._s(_vm.$v.forms.update_addressbook.company.$params.minLength.min) + " characters ")]) : _vm._e(), _vm._l(_vm.api.update_addressbook.validation_errors.company, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.phones.allow ? _c('div', {
    staticClass: "add_fields add_phone_box"
  }, [_vm._l(_vm.forms.update_addressbook.phones, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "fullWidthLessHeightGreyButton mt-2 justify-content-between"
    }, [_c('p', [_vm._v(_vm._s(data.phone) + " " + _vm._s(_vm._f("number_formater")(data.number, data.country)) + " " + _vm._s(data.shortcode))]), _c('div', {
      staticClass: "d-flex"
    }, [_c('b-button', {
      attrs: {
        "variant": "link",
        "disabled": _vm.api.update_addressbook.send
      },
      on: {
        "click": function ($event) {
          return _vm.$modal.show('UpdatePhoneNumber', {
            phone: data.phone,
            shortcode: data.shortcode,
            country: data.country,
            number: data.number,
            fields: {
              type: _vm.formAllowFields.phones.type,
              short_code: _vm.formAllowFields.phones.short_code
            },
            index: index,
            create: false
          });
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "pencil"
      }
    })], 1), (_vm.formAllowFields.phones.required ? _vm.forms.update_addressbook.phones.length > 1 : true) ? _c('b-button', {
      attrs: {
        "variant": "link",
        "disabled": _vm.api.update_addressbook.send
      },
      on: {
        "click": function ($event) {
          return _vm.forms.update_addressbook.phones.splice(index, 1);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "variant": "danger",
        "icon": "trash"
      }
    })], 1) : _vm._e()], 1), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.phones.$each[index].phone.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop"
    }, [!_vm.$v.forms.update_addressbook.phones.$each[index].phone.required ? _c('span', [_vm._v("* Type is required")]) : _vm._e()]) : _vm._e(), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.phones.$each[index].number.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop mb-0"
    }, [!_vm.$v.forms.update_addressbook.phones.$each[index].number.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.update_addressbook.phones.$each[index].number.validNumber ? _c('span', [_vm._v("* Number is invalid")]) : _vm._e()]) : _vm._e()]);
  }), _vm.forms.update_addressbook.phones.length < _vm.formAllowFields.phones.length ? _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-20px"
  }, [_c('a', {
    staticClass: "fullWidthLessHeightGreyButton",
    on: {
      "click": function ($event) {
        _vm.api.update_addressbook.send ? '' : _vm.forms.update_addressbook.phones.push({
          country: 'GB',
          number: '',
          phone: _vm.formAllowFields.phones.type ? 'Main' : '',
          shortcode: _vm.formAllowFields.phones.short_code ? _vm.filterShortCodes[0] : ''
        });
      }
    }
  }, [_vm._v(" Add phone ")])])])], 1) : _vm._e(), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.phones.$invalid || _vm.api.update_addressbook.validation_errors.phones ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.update_addressbook.validation_errors.phones, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 2) : _vm._e(), _vm.formAllowFields.emails.allow ? _c('div', {
    staticClass: "add_fields add_email_box"
  }, [_vm._l(_vm.forms.update_addressbook.emails, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "fullWidthLessHeightGreyButton mt-2 justify-content-between"
    }, [_c('p', [_vm._v(_vm._s(data.emails))]), _c('div', {
      staticClass: "d-flex"
    }, [_c('b-button', {
      attrs: {
        "variant": "link",
        "disabled": _vm.api.update_addressbook.send
      },
      on: {
        "click": function ($event) {
          return _vm.$modal.show('UpdateEmail', {
            email: data.emails,
            index: index,
            create: false
          });
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "pencil"
      }
    })], 1), (_vm.formAllowFields.emails.required ? _vm.forms.update_addressbook.emails.length > 1 : true) ? _c('b-button', {
      attrs: {
        "variant": "link",
        "disabled": _vm.api.update_addressbook.send
      },
      on: {
        "click": function ($event) {
          _vm.api.update_addressbook.send ? '' : _vm.forms.update_addressbook.emails.splice(index, 1);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "variant": "danger",
        "icon": "trash"
      }
    })], 1) : _vm._e()], 1), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.emails.$each[index].emails.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop"
    }, [!_vm.$v.forms.update_addressbook.emails.$each[index].emails.required ? _c('span', [_vm._v("* email is required")]) : _vm._e(), !_vm.$v.forms.update_addressbook.emails.$each[index].emails.email ? _c('span', [_vm._v("* email is not valid")]) : _vm._e()]) : _vm._e()]);
  }), _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-20px mb-20px"
  }, [_vm.forms.update_addressbook.emails.length < _vm.formAllowFields.emails.length ? _c('a', {
    staticClass: "fullWidthLessHeightGreyButton",
    on: {
      "click": function ($event) {
        _vm.api.update_addressbook.send ? '' : _vm.forms.update_addressbook.emails.unshift({
          emails: ''
        });
      }
    }
  }, [_vm._v(" Add email ")]) : _vm._e()])]), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.emails.$invalid || _vm.api.update_addressbook.validation_errors.emails ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.update_addressbook.validation_errors.emails, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1)], 2) : _vm._e(), _vm.formAllowFields.jobtitle ? _c('b-form-group', {
    staticClass: "mb-0 mt-20px whiteBGinputWithGreyRoundedBorder",
    attrs: {
      "label": "job title"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.update_addressbook.send,
      "placeholder": "Job Title"
    },
    model: {
      value: _vm.forms.update_addressbook.jobtitle,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_addressbook, "jobtitle", $$v);
      },
      expression: "forms.update_addressbook.jobtitle"
    }
  }), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.jobtitle.$invalid || _vm.api.update_addressbook.validation_errors.jobtitle ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_addressbook.jobtitle.valid ? _c('span', [_vm._v("Job title is invalid")]) : _vm._e(), !_vm.$v.forms.update_addressbook.jobtitle.minLength ? _c('span', [_vm._v(" Job title should be more than " + _vm._s(_vm.$v.forms.update_addressbook.jobtitle.$params.minLength.min) + " characters ")]) : _vm._e(), _vm._l(_vm.api.update_addressbook.validation_errors.jobtitle, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.department ? _c('b-form-group', {
    staticClass: "mb-0 mt-20px whiteBGinputWithGreyRoundedBorder",
    attrs: {
      "label": " Department"
    }
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.update_addressbook.send,
      "placeholder": "Department"
    },
    model: {
      value: _vm.forms.update_addressbook.department,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_addressbook, "department", $$v);
      },
      expression: "forms.update_addressbook.department"
    }
  }), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.department.$invalid || _vm.api.update_addressbook.validation_errors.department ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_addressbook.department.valid ? _c('span', [_vm._v("*Department is invalid")]) : _vm._e(), !_vm.$v.forms.update_addressbook.department.minLength ? _c('span', [_vm._v(" *Department should be more than " + _vm._s(_vm.$v.forms.update_addressbook.department.$params.minLength.min) + " characters ")]) : _vm._e(), _vm._l(_vm.api.update_addressbook.validation_errors.department, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.suffix ? _c('b-form-group', {
    staticClass: "mb-0 mt-20px whiteBGinputWithGreyRoundedBorder dropdownVersion"
  }, [_c('vb-select', {
    staticClass: "right-0 selectVoice-dropdown",
    attrs: {
      "disabled": _vm.api.update_addressbook.send,
      "defaultSelectedText": 'Select suffix',
      "options": _vm.suffixs
    },
    model: {
      value: _vm.forms.update_addressbook.suffix,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_addressbook, "suffix", $$v);
      },
      expression: "forms.update_addressbook.suffix"
    }
  }), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.suffix.$invalid || _vm.api.update_addressbook.validation_errors.suffix ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_addressbook.suffix.minLength ? _c('span', [_vm._v(" *suffix should be more than " + _vm._s(_vm.$v.forms.update_addressbook.suffix.$params.minLength.min) + " characters ")]) : _vm._e(), _vm._l(_vm.api.update_addressbook.validation_errors.suffix, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.formAllowFields.note ? _c('b-form-group', {
    staticClass: "mb-0 mt-20px whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-form-input', {
    attrs: {
      "type": "text",
      "oninput": "this.value = this.value.replace(/[^a-zA-Z0-9\\s]*$/gi, '')",
      "disabled": _vm.api.update_addressbook.send,
      "placeholder": "Note"
    },
    model: {
      value: _vm.forms.update_addressbook.note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_addressbook, "note", $$v);
      },
      expression: "forms.update_addressbook.note"
    }
  }), _vm.forms.update_addressbook.submitted && _vm.$v.forms.update_addressbook.note.$invalid || _vm.api.update_addressbook.validation_errors.note ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_addressbook.note.valid ? _c('span', [_vm._v("*Note is invalid")]) : _vm._e(), _vm._l(_vm.api.update_addressbook.validation_errors.note, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.update_addressbook.send,
      "type": "submit"
    }
  }, [_vm.api.update_addressbook.send ? _c('vb-spinner') : [_vm._v("Update")]], 2)])], 1)], 1) : _vm._e(), _c('SelectAddressBookNumberModal'), _c('ProfileImageUploader', {
    attrs: {
      "modalName": "AddressBookProfileImageUploader"
    },
    on: {
      "image-uploaded": function ($event) {
        return _vm.onImageUploaded($event);
      }
    }
  }), _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 AddTeamModal AddPhoneNumberFromContactsModal",
    attrs: {
      "name": "UpdatePhoneNumber",
      "width": "500px",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        _vm.forms.update_phone_input.phone = $event.params.phone;
        _vm.forms.update_phone_input.number = $event.params.number;
        _vm.forms.update_phone_input.country = $event.params.country;
        _vm.forms.update_phone_input.shortcode = $event.params.shortcode;
        _vm.forms.update_phone_input.fields.type = $event.params.fields.type;
        _vm.forms.update_phone_input.fields.short_code = $event.params.fields.short_code;
        _vm.forms.update_phone_input.index = $event.params.index;
        _vm.forms.update_phone_input.create = $event.params.create;
      },
      "before-close": function ($event) {
        _vm.$v.forms.update_phone_input.$reset();
        _vm.conditions.collapse = false;
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit s1"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Add phone")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('UpdatePhoneNumber');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Enter the phone details below.")])]), _c('form', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmitPhoneInput();
      }
    }
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('div', [_c('div', {
    staticClass: "d-flex w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder wd-60 pr-2"
  }, [_c('legend', {
    staticClass: "form-control-label small"
  }, [_vm._v("Number")]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder joinWithInput"
  }, [_c('b-dropdown', {
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm._v(_vm._s(_vm.forms.update_phone_input.country))];
      },
      proxy: true
    }])
  }, _vm._l(_vm.countries, function (country) {
    return _c('b-dropdown-item', {
      key: country.value,
      on: {
        "click": function ($event) {
          _vm.forms.update_phone_input.country = country.value;
        }
      }
    }, [_vm._v(" " + _vm._s(country.value) + " " + _vm._s(country.text) + " ")]);
  }), 1), _c('b-form-input', {
    directives: [{
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate",
      value: _vm.forms.update_phone_input.country,
      expression: "forms.update_phone_input.country"
    }],
    attrs: {
      "type": "text",
      "placeholder": "Number"
    },
    model: {
      value: _vm.forms.update_phone_input.number,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_phone_input, "number", $$v);
      },
      expression: "forms.update_phone_input.number"
    }
  })], 1)]), _vm.forms.update_phone_input.fields.type ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-40 pl-2 mb-0"
  }, [_c('legend', {
    staticClass: "form-control-label small"
  }, [_vm._v("Type")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_phone_input.phone,
      expression: "forms.update_phone_input.phone"
    }],
    staticClass: "form-control custom-select",
    attrs: {
      "placeholder": "Phone"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.forms.update_phone_input, "phone", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "value": "",
      "disabled": ""
    }
  }, [_vm._v("Select")]), _c('option', {
    attrs: {
      "value": "Home"
    }
  }, [_vm._v("Home")]), _c('option', {
    attrs: {
      "value": "Office"
    }
  }, [_vm._v("Office")]), _c('option', {
    attrs: {
      "value": "Cell"
    }
  }, [_vm._v("Cell")]), _c('option', {
    attrs: {
      "value": "Work"
    }
  }, [_vm._v("Work")]), _c('option', {
    attrs: {
      "value": "Main"
    }
  }, [_vm._v("Main")]), _c('option', {
    attrs: {
      "value": "HOME FAX"
    }
  }, [_vm._v("Home fax")]), _c('option', {
    attrs: {
      "value": "Office FAX"
    }
  }, [_vm._v("Office fax")]), _c('option', {
    attrs: {
      "value": "Others"
    }
  }, [_vm._v("Others")])]), _vm.$v.forms.update_phone_input.phone.$dirty && _vm.$v.forms.update_phone_input.phone.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_phone_input.phone.required ? _c('span', [_vm._v("* Type is required")]) : _vm._e()]) : _vm._e()]) : _vm._e()]), _vm.$v.forms.update_phone_input.number.$dirty && _vm.$v.forms.update_phone_input.number.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.update_phone_input.number.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.update_phone_input.number.validNumber ? _c('span', [_vm._v("* Number is invalid")]) : _vm._e()]) : _vm._e(), _vm.forms.update_phone_input.fields.short_code ? _c('div', [_c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.my-collapse",
      modifiers: {
        "my-collapse": true
      }
    }],
    staticClass: "addShortCodeCollaseableButton",
    class: _vm.conditions.collapse ? null : 'collapsed',
    attrs: {
      "type": "button",
      "aria-expanded": _vm.conditions.collapse ? 'true' : 'false',
      "aria-controls": "collapse-4"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.collapse = !_vm.conditions.collapse;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": `${_vm.conditions.collapse ? 'dash-circle-fill' : 'plus-circle-fill'}`
    }
  }), _vm._v(" Add shortcode ")], 1)]), _c('b-collapse', {
    attrs: {
      "id": "collapse-4"
    },
    model: {
      value: _vm.conditions.collapse,
      callback: function ($$v) {
        _vm.$set(_vm.conditions, "collapse", $$v);
      },
      expression: "conditions.collapse"
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-60 pr-2 mb-0"
  }, [_c('legend', {
    staticClass: "form-control-label small"
  }, [_vm._v("Shortcode")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_phone_input.shortcode,
      expression: "forms.update_phone_input.shortcode"
    }],
    staticClass: "form-control custom-select",
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.forms.update_phone_input, "shortcode", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, [_c('option', {
    attrs: {
      "disabled": "",
      "value": ""
    }
  }, [_vm._v("Select")]), _c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("not select")]), _vm._l(_vm.forms.update_phone_input.shortcode ? [_vm.forms.update_phone_input.shortcode].concat(_vm.filterShortCodes) : _vm.filterShortCodes, function (shortcode) {
    return _c('option', {
      key: shortcode,
      domProps: {
        "value": shortcode
      }
    }, [_vm._v(" " + _vm._s(shortcode) + " ")]);
  })], 2), _vm.$v.forms.update_phone_input.shortcode.$dirty && _vm.$v.forms.update_phone_input.shortcode.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }) : _vm._e()])])], 1) : _vm._e()])]), _c('b-button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Submit")])], 1)])]), _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 AddTeamModal",
    attrs: {
      "name": "UpdateEmail",
      "width": "500px",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        _vm.forms.update_email_input.email = $event.params.email;
        _vm.forms.update_email_input.index = $event.params.index;
        _vm.forms.update_email_input.create = $event.params.create;
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit s1"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Add email")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('UpdateEmail');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Enter the email below.")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmitEmailInput();
      }
    }
  }, [_c('b-form-group', {
    class: `mb-0`
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder flex-fill"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_email_input.email,
      expression: "forms.update_email_input.email"
    }],
    staticClass: "d-inline-flex flex-grow-1 adjust-width add_new_email_row form-control",
    attrs: {
      "type": "email",
      "placeholder": "Email"
    },
    domProps: {
      "value": _vm.forms.update_email_input.email
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_email_input, "email", $event.target.value);
      }
    }
  }), _vm.$v.forms.update_email_input.email.$dirty && _vm.$v.forms.update_email_input.email.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_email_input.email.required ? _c('span', [_vm._v("* email is required")]) : _vm._e(), !_vm.$v.forms.update_email_input.email.email ? _c('span', [_vm._v("* email is not valid")]) : _vm._e()]) : _vm._e()])])]), _c('b-button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v("Submit")])], 1)])])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column flex-fill"
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "18px",
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "18px",
      "width": "80%"
    }
  })]);

}]

export { render, staticRenderFns }