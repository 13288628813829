<template>
  <div>
    <div class="mainHeading withButton">
      Training
      <button @click="$modal.show('AddTrainingType')" class="dialer-button dialer-button-primary mr-0 withIcon">
        <!-- <vb-icon icon="pencil-hrmIcon" class="mr-16px fillWhite" height="14px" width="14px" /> -->
        <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
        Add training type
      </button>
    </div>
    <div class="mainDescription mb-4">
      <br/>
    </div>
    <div class="mainHeading withButton">
      Training Types
    </div>
    <div class="documentsModule-Table mt-32px">
      <vb-table class="HrmTable">
        <template slot="header">
          <tr>
            <th class="HrmTableHead text-left one pt-1">name</th>
            <th class="HrmTableHead text-left two pt-1">Allowed payments types</th>
            <th class="HrmTableHead text-left three pt-1">Trainings</th>
            <th class="HrmTableHead four pt-1">Actions</th>
          </tr>
        </template>
        <template #body>
          <tr v-for="n in 6" :key="n" class="dialer-row-select" >
            <td class="dialer-row-title one">Employee handbook</td>
            <td class="dialer-col-right text-left two">Invoice</td>
            <td class="dialer-col-right text-left three">0</td>
            <td class="dialer-col-right text-left four">
              <b-dropdown no-caret right class="ActionsDD">
                <template #button-content>
                  <div class="button-content">
                    <b-icon class="downIcon" icon="three-dots-vertical" />
                  </div>
                </template>
                <b-dropdown-item >
                  Actions
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    <b-form-checkbox class="HrmCheckBox-container mt-32px">
      Allow employees to add & edit trainings
    </b-form-checkbox>
    <div class="innerShadowInput-container wd-46 mt-8px">
      <label>Training adminstrators</label>
      <b-dropdown class="hrmInnerShadow-dd" no-caret>
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">Training adminstrators</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item>
          Training adminstrators
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <button class="dialer-button dialer-button-primary mr-0"> Save </button>
    <div class="mainHeading withButton mt-32px">
      Payment types
      <button @click="$modal.show('AddPaymentType')" class="dialer-button dialer-button-primary mr-0 withIcon">
        <!-- <vb-icon icon="pencil-hrmIcon" class="mr-16px fillWhite" height="14px" width="14px" /> -->
        <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
        Add Payment type
      </button>
    </div>
    <div class="mainDescription mb-4">
      Set up different options how employees can pay for the training
    </div>
    <div class="documentsModule-Table mt-32px">
      <vb-table class="HrmTable">
        <template slot="header">
          <tr>
            <th class="HrmTableHead text-left one pt-1">Payment type</th>
            <th class="HrmTableHead two pt-1">Actions</th>
          </tr>
        </template>
        <template #body>
          <tr v-for="n in 6" :key="n" class="dialer-row-select" >
            <td class="dialer-row-title one">
              <div class="td-heading">Invoice</div>
              <div class="td-text">Approved by direct manager</div>
            </td>
            <td class="dialer-col-right two">
              <b-dropdown no-caret right class="ActionsDD">
                <template #button-content>
                  <div class="button-content">
                    <b-icon class="downIcon" icon="three-dots-vertical" />
                  </div>
                </template>
                <b-dropdown-item >
                  Actions
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    <AddTrainingType />
    <AddPaymentType />
  </div>
</template>

<script>
import AddTrainingType from '@/components/Modals/HRM/settings/EmployeeData/AddTrainingType.vue';
import AddPaymentType from '@/components/Modals/HRM/settings/EmployeeData/AddPaymentType.vue';
export default {
  name: 'EmployeeDataTraining',
  components: {
    AddTrainingType,
    AddPaymentType,
  },
}
</script>

<style>

</style>