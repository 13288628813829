var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-area d-flex h-100 dialer_main_body Schedule"
  }, [_c('div', {
    staticClass: "dialer_main_body_inner w-100"
  }, [_c('div', {
    staticClass: "dialer_inner_right_side ml-0"
  }, [_c('Schedule')], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }