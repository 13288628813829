<template>
  <div>
    <modal 
      class="center-zoomIn-transition v2 m-w-500 AddLocationModal AddNumberLocationModal max-height-100vh min-width-50percent-modal" 
      width="500px" 
      height="auto" 
      :name="modalName" 
      :scrollable="true" 
      @before-close="onBeforeClose()" 
      @before-open="onBeforeOpen($event)"
    >
      <section class="ct-user-new dialer-edit">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="addEmergencyLocation()">
          <div v-if="getIsMobile" class="dialer-edit-header flex-column">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <h2 class="dialer-edit-title">Number location</h2>
              <b-icon class="cursor_pointer" icon="x" font-scale="1.8" @click="api.add_emergency_location.send ? '' : $modal.hide(modalName)" />
            </div>
            <!-- <div class="dialer-edit-actions w-100 d-flex justify-content-end mt-2">
              <button class="newDoneButton" type="submit" :disabled="api.add_emergency_location.send">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api.add_emergency_location.send" />
                  <template v-else>Save</template>
                </span>
              </button>
            </div> -->
          </div>
          <div v-else class="dialer-edit-header pb-0 flex-column align-items-start">
            <div class="d-flex justify-content-between w-100 align-items-center">
              <h2 class="dialer-settings-title newer mb-0">Number location</h2>
              <div class="dialer-edit-actions d-flex">
                <a class="newCloseButton mr-8px" @click="api.add_emergency_location.send ? '' : $modal.hide(modalName)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <p>Add emergency location for a number</p>
          </div>
          <div class="latestGreyBox-9-9-2023 mb-4 mt-20px">
            <b-alert variant="danger" :show="!!api.add_emergency_location.error_message">{{api.add_emergency_location.error_message}}</b-alert>
            <!-- name of location -->
            <div class="whiteBGinputWithGreyRoundedBorder w-100 mb-0">
              <label>Name of location</label>
              <input class="w-100" type="text" v-model="forms.add_emergency_location.label" :maxlength="$v.forms.add_emergency_location.label.$params.maxLength.max" />
              <p v-if="$v.forms.add_emergency_location.label.$error" class="text text-danger animated bounceIntop mb-0">
                <span v-if="!$v.forms.add_emergency_location.label.required">* Name of location is required</span>
                <span v-else-if="!$v.forms.add_emergency_location.label.alphaNum">* Name of location can be only alphabet, number and space</span>
                <span v-else-if="!$v.forms.add_emergency_location.label.minLength">* Name of location should be minimum {{$v.forms.add_emergency_location.label.$params.minLength.min}} characters</span>
                <span v-else-if="!$v.forms.add_emergency_location.label.maxLength">* Name of location can be maximum {{$v.forms.add_emergency_location.label.$params.maxLength.max}} characters</span>
              </p>
            </div>
            <!-- country -->
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0">
              <label>Select country</label>
              <vb-select listClass="select-country-options" :selected="forms.add_emergency_location.country" @change="forms.add_emergency_location.country=$event" class="w-100 custom-dd-select" :defaultSelectedText="'Select country'" :options="countriesOptions" />
              <p v-if="$v.forms.add_emergency_location.country.$error" class="text text-danger animated bounceIntop mb-0">
                <span v-if="!$v.forms.add_emergency_location.country.required">* Country is required</span>
              </p>
            </div>
            <!-- manually toggle -->
            <div 
              v-if="forms.add_emergency_location.country=='GB'" 
              class="d-flex justify-content-between align-items-center whiteBGinputWithGreyRoundedBorder mb-0 mt-20px w-100"
            >
              <label>Manually enter the address</label>
              <b-check class="newerSwitch" v-model="forms.add_emergency_location.is_manual_address" :disabled="api.add_emergency_location.send" switch />
            </div>
            <!-- first and last name -->
            <div class="d-flex w-100 justify-content-between mt-20px">
              <!-- first name -->
              <div class="whiteBGinputWithGreyRoundedBorder mb-0 w-50per-15px">
                <label>First name</label>
                <input class="w-100" type="text" v-model="forms.add_emergency_location.forename" :disabled="api.add_emergency_location.send" />
                <p v-if="$v.forms.add_emergency_location.forename.$error" class="text text-danger animated bounceIntop mb-0">
                  <span v-if="!$v.forms.add_emergency_location.forename.required">* First name is required</span>
                </p>
              </div>
              <!-- last name -->
              <div class="whiteBGinputWithGreyRoundedBorder mb-0 w-50per-15px">
                <label>Last name</label>
                <input class="w-100" type="text" v-model="forms.add_emergency_location.surname" :disabled="api.add_emergency_location.send" />
                <p v-if="$v.forms.add_emergency_location.surname.$error" class="text text-danger animated bounceIntop mb-0">
                  <span v-if="!$v.forms.add_emergency_location.surname.required">* Last name is required</span>
                </p>
              </div>
            </div>
            <!-- house number & postal code -->
            <div class="d-flex w-100 justify-content-between mt-20px">
              <!-- house number -->
              <div class="whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0">
                <label>House number</label>
                <input class="w-100" type="text" v-model="forms.add_emergency_location.house_name" :disabled="api.add_emergency_location.send" />
                <p v-if="$v.forms.add_emergency_location.house_name.$error" class="text text-danger animated bounceIntop mb-0">
                  <span v-if="!$v.forms.add_emergency_location.house_name.required">* House number is required</span>
                </p>
              </div>
              <!-- postal code -->
              <div class="whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0">
                <label>Postal code</label>
                <input class="w-100" type="text" v-model="forms.add_emergency_location.postal_code" :disabled="api.add_emergency_location.send" />
                <p v-if="$v.forms.add_emergency_location.postal_code.$error" class="text text-danger animated bounceIntop mb-0">
                  <span v-if="!$v.forms.add_emergency_location.postal_code.required">* Postal code is required</span>
                  <span v-else-if="!$v.forms.add_emergency_location.postal_code.valid">* Postal code is not valid</span>
                </p>
                <p v-else-if="api.fetch_addresses.error_message" class="text text-danger animated bounceIntop mb-0">
                  <span>* {{ api.fetch_addresses.error_message }}</span>
                </p>
                <vb-spinner v-if="api.fetch_addresses.send" variant="primary" />
                <b-icon v-else-if="api.fetch_addresses.status==1" icon="check2" variant="success" />
                <b-icon v-if="api.fetch_addresses.status==2" icon="x" variant="danger" />
              </div>
            </div>
            <!-- street address -->
            <div v-if="!forms.add_emergency_location.is_manual_address" class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0">
              <label>Select address</label>
              <b-select v-model="forms.add_emergency_location.address" :disabled="api.add_emergency_location.send">
                <option value="">Select address</option>
                <option v-for="address in addresses" :key="address" :value="address">{{ address.replace(/,/g,'').replace(/\s+/g,' ').trim() }}</option>
              </b-select>
              <p v-if="$v.forms.add_emergency_location.address.$error" class="text text-danger animated bounceIntop mb-0">
                <span v-if="!$v.forms.add_emergency_location.address.required">* Address is required</span>
              </p>
            </div>
            <!-- city & street -->
            <div v-if="forms.add_emergency_location.is_manual_address" class="d-flex w-100 justify-content-between mt-20px">
              <!-- city -->
              <div class="whiteBGinputWithGreyRoundedBorder w-30per-15px mb-0">
                <label>City</label>
                <input class="w-100" type="text" v-model="forms.add_emergency_location.city" :disabled="api.add_emergency_location.send" />
                <p v-if="$v.forms.add_emergency_location.city.$error" class="text text-danger animated bounceIntop mb-0">
                  <span v-if="!$v.forms.add_emergency_location.city.required">* City is required</span>
                </p>
              </div>
              <!-- street -->
              <div class="whiteBGinputWithGreyRoundedBorder w-70per-15px mb-0">
                <label>Street</label>
                <input class="w-100" type="text" v-model="forms.add_emergency_location.street" :disabled="api.add_emergency_location.send" />
                <p v-if="$v.forms.add_emergency_location.street.$error" class="text text-danger animated bounceIntop mb-0">
                  <span v-if="!$v.forms.add_emergency_location.street.required">* Street is required</span>
                </p>
              </div>
            </div>
          </div>
          <button class="fullWidthDoneButton" type="submit" :disabled="api.add_emergency_location.send">
            <vb-spinner v-if="api.add_emergency_location.send" />
            <template v-else>Save</template>
          </button>
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { $fn, VOIP_API } from "../../utils";
import required from "vuelidate/lib/validators/required";
import minLength from "vuelidate/lib/validators/minLength";
import { helpers } from "vuelidate/lib/validators";
import maxLength from 'vuelidate/lib/validators/maxLength';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import { mapGetters } from 'vuex';
export default {
  name: "AddEmergencyLocationModal",
  inject:[
    'isEmpty','appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'AddEmergencyLocationModal'
    },
    did: {
      type: String,
      require: true,
    },
  },
  data() {
    return {
      forms: {
        add_emergency_location: this.$helperFunction.formInstance({
          data: {
            country: '',
            surname: "",
            forename: "",
            label: '',
            is_manual_address: true,
            postal_code: "",
            city: "",
            street: "",
            house_name: "",
            address: "",
          }
        }),
      },
      api: {
        fetch_common_values: this.$helperFunction.apiInstance(),
        fetch_addresses: this.$helperFunction.apiInstance({
          status: true,
          error_message: true,
        }),
        add_emergency_location: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        }),
      },
      response: {
        common_values: {},
        addresses: {},
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile'
    ]),
    countriesOptions(){
      return this.response.common_values?.countries?.map?.((country) => ({
        text: ` ${country.Name} `,
        value: country.Code2,
      })) ?? []
    },
    addresses(){ return this.response.addresses?.addresses ?? [] },
  },
  validations: {
    forms: {
      add_emergency_location: {
        country: {
          required: required,
        },
        surname: {
          required: required,
        },
        forename: {
          required: required,
        },
        label: {
          required: required,
          alphaNum: helpers.regex('alphaNum',/^[a-zA-Z0-9 ]*$/i),
          maxLength: maxLength(15),
          minLength: minLength(3),
        },
        is_manual_address: {

        },
        postal_code: {
          required: required,
          valid: function(...args){ 
            return this.forms.add_emergency_location.is_manual_address ? true : $fn.isValidUKPostcode(...args) 
          },
        },
        city: {
          required: requiredIf(function(){ 
            return this.forms.add_emergency_location.is_manual_address 
          }),
        },
        street: {
          required: requiredIf(function(){ 
            return this.forms.add_emergency_location.is_manual_address 
          }),
        },
        house_name: {
          required: required,
        },
        address: {
          required: requiredIf(function(){ 
            return !this.forms.add_emergency_location.is_manual_address 
          }),
        },
      },
    },
  },
  watch: {
    "forms.add_emergency_location.country"(country) {
      this.forms.add_emergency_location.is_manual_address = country!="GB";
    },
    "forms.add_emergency_location.postal_code"(postal_code) {
      if(!this.forms.add_emergency_location.is_manual_address) {
        this.response.addresses={}
        this.forms.add_emergency_location.address=''
        if(!this.$v.forms.add_emergency_location.postal_code.valid) return;
        this.fetchAddresses(postal_code);
      }
    },
  },
  methods: {
    onBeforeOpen() {
      this.fetchCommonValues();
    },
    onBeforeClose() {
      this.$v.forms.add_emergency_location.$reset()
      this.forms.add_emergency_location.reset()
      this.api.fetch_addresses.reset()
      this.api.fetch_common_values.reset()
      this.api.add_emergency_location.reset()
      this.response.common_values={}
      this.response.addresses={}
    },
    async fetchAddresses(value) {
      try {
        this.api.fetch_addresses.send = true;
        this.api.fetch_addresses.status = 0;
        this.api.fetch_addresses.error_message = '';
        const { data } = await VOIP_API.endpoints.location.getAddress(value)
        this.api.fetch_addresses.status = 1;
        this.response.addresses = data;
      } catch (ex) {
        this.api.fetch_addresses.status = 2;
        this.api.fetch_addresses.error_message = 'Invalid postal code';
      } finally {
        this.api.fetch_addresses.send = false;
      }
    },
    async fetchCommonValues() {
      if (this.api.fetch_common_values.send) return;
      try {
        this.api.fetch_common_values.send=true
        const { data } = await VOIP_API.endpoints.location.listcountriesandextensions()
        this.response.common_values = data;
      } finally {
        this.api.fetch_common_values.send=false
      }
    },
    async addEmergencyLocation() {
      this.$v.forms.add_emergency_location.$touch()
      if(this.$v.forms.add_emergency_location.$invalid) return;
      if(this.api.add_emergency_location.send) return;
      try {
        this.api.add_emergency_location.send=true
        this.api.add_emergency_location.error_message=''
        this.api.add_emergency_location.validation_errors={}
        const address = this.forms.add_emergency_location.address?.split?.(",") ?? [];
        let street = this.forms.add_emergency_location.street
        if(!this.forms.add_emergency_location.is_manual_address) {
          street=address?.[0]?.trim?.() ?? ''
        }
        let town = this.forms.add_emergency_location.city
        if(!this.forms.add_emergency_location.is_manual_address) {
          town=address?.[5]?.trim?.() ?? ''
        }
        await VOIP_API.endpoints.location.addEmergencyAddress({
          number: this.did,
          postcode: this.forms.add_emergency_location.postal_code,
          surname: this.forms.add_emergency_location.surname,
          forename: this.forms.add_emergency_location.forename,
          house: this.forms.add_emergency_location.house_name,
          street: street,
          town: town,
          accountcode: this.getCurrentUser.account,
          uid: this.getCurrentUser.uid,
          title: '',
          label: this.forms.add_emergency_location.label,
        })
        this.$modal.hide(this.modalName);
        this.appNotify({
          message: "Successfully Added",
          type: "success",
        })
        this.$emit("added");
      } catch (ex) {
        this.api.add_emergency_location.error_message=ex.own_message || ex.message || ''
        this.api.add_emergency_location.validation_errors=ex.own_errors || {}
      } finally {
        this.$v.forms.add_emergency_location.$reset()
        this.api.add_emergency_location.send = false;
      }
    },
  },
};
</script>
