<template>

	<div class="right-calender">
		<div class="top-main-calender scrollow1">
			TrainingHRM
		</div>
	</div>
</template>

<script>

export default {
  name: 'TrainingHRM',
}
</script>

<style>

</style>