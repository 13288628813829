var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup new-call-info-modal new-call-info-modal-newer v2",
    attrs: {
      "width": "50%",
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "panel-inner panel-inner-callInfo new-call-info-inner"
  }, [_c('div', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-between py-0 mb-0 dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Call info")]), _c('div', [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Below you will see details about this call.")]), _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_c('div', {
    staticClass: "list-area tab-info"
  }, [_c(_vm.component, {
    tag: "component",
    staticClass: "holder",
    attrs: {
      "log": _vm.data.call,
      "params": _vm.data.params
    },
    scopedSlots: _vm._u([{
      key: "history-filter",
      fn: function () {
        return [_c('div', {
          staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD"
        }, [_c('b-dropdown', {
          staticClass: "dd-text-dark dd-with-checkbox",
          attrs: {
            "no-caret": "",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('vb-icon', {
                attrs: {
                  "icon": "callInfoModal-funnel-icon",
                  "width": "17.892px",
                  "height": "17.896px"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('div', {
          staticClass: "w-100 position-relative pl-4"
        }, [_c('b-form-checkbox', {
          staticClass: "makeCheckBoxesSquare",
          model: {
            value: _vm.setting.recording,
            callback: function ($$v) {
              _vm.$set(_vm.setting, "recording", $$v);
            },
            expression: "setting.recording"
          }
        }, [_vm._v("Recording")]), _c('b-form-checkbox', {
          staticClass: "makeCheckBoxesSquare",
          model: {
            value: _vm.setting.message,
            callback: function ($$v) {
              _vm.$set(_vm.setting, "message", $$v);
            },
            expression: "setting.message"
          }
        }, [_vm._v("SMS and messages")]), _c('b-form-checkbox', {
          staticClass: "makeCheckBoxesSquare",
          model: {
            value: _vm.setting.tags,
            callback: function ($$v) {
              _vm.$set(_vm.setting, "tags", $$v);
            },
            expression: "setting.tags"
          }
        }, [_vm._v("Tags")]), _c('b-form-checkbox', {
          staticClass: "makeCheckBoxesSquare",
          model: {
            value: _vm.setting.note,
            callback: function ($$v) {
              _vm.$set(_vm.setting, "note", $$v);
            },
            expression: "setting.note"
          }
        }, [_vm._v("Notes")])], 1)])], 1)];
      },
      proxy: true
    }, {
      key: "history",
      fn: function () {
        return [_c('div', {
          staticClass: "phone-list full-width-call-info-number dialor-callHistoryList history_tab list-unstyled p-0 h-100",
          staticStyle: {
            "overflow": "auto"
          },
          on: {
            "scroll": function ($event) {
              return _vm.onScroll($event);
            }
          }
        }, [_vm._l(_vm.filteredCallHistory, function (call_history, index) {
          return [call_history.isDateShow ? _c('h3', {
            key: `${index}-date-call-activity`,
            staticClass: "pb-1 ddd"
          }, [_c('span', {
            staticStyle: {
              "color": "black",
              "font-size": "12px"
            }
          }, [_vm._v(" " + _vm._s(_vm._f("filter_date_current")(call_history.call_date || call_history.time)) + " ")])]) : _vm._e(), _c('li', {
            key: index
          }, [_c('CallActivityTestItem', {
            key: index,
            attrs: {
              "call": call_history,
              "conditions": {
                tag: _vm.setting.tags,
                note: _vm.setting.note,
                recording: _vm.setting.recording,
                message: _vm.setting.message,
                actions: false
              },
              "type": 'history'
            },
            scopedSlots: _vm._u([{
              key: "more",
              fn: function () {
                return [_c('b-dropdown', {
                  staticClass: "ico-filter",
                  attrs: {
                    "varient": "link",
                    "no-caret": "",
                    "menu-class": "w-fit-content-imp",
                    "right": ""
                  },
                  scopedSlots: _vm._u([{
                    key: "button-content",
                    fn: function () {
                      return [_c('b-icon', {
                        attrs: {
                          "icon": "three-dots",
                          "variant": "#393939",
                          "font-scale": "1.4"
                        }
                      })];
                    },
                    proxy: true
                  }], null, true)
                }, [_c('b-dropdown-item', {
                  on: {
                    "click": function ($event) {
                      '';
                    }
                  }
                }, [_c('vb-icon', {
                  staticClass: "mr-16px",
                  attrs: {
                    "icon": "callInfoModal-addContacts-icon",
                    "width": "17.004px",
                    "height": "17.72px"
                  }
                }), _vm._v(" Call info ")], 1)], 1)];
              },
              proxy: true
            }], null, true)
          })], 1)];
        }), _vm.api.history.send || _vm.isEmpty(_vm.response.history) ? _c('vb-no-record', {
          attrs: {
            "text": _vm.api.history.send ? '' : 'There is no history',
            "design": 3
          }
        }, [_vm.api.history.send ? _c('template', {
          slot: "loading"
        }, _vm._l(4, function (n) {
          return _c('div', {
            key: n.id,
            class: `latestShimmerDesign w-75 mb-4 ${n % 2 == 1 ? 'ml-auto' : ''}`,
            staticStyle: {
              "height": "80px"
            }
          });
        }), 0) : _vm._e()], 2) : _vm._e()], 2)];
      },
      proxy: true
    }])
  })], 1)])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }