
// const BILLING_TOKEN = 'billing_token';
/**
 * Manage the how Access Tokens are being stored and retreived from storage.
 *
 * Current implementation stores to localStorage. Local Storage should always be
 * accessed through this instance.
 **/

import SecureLS from "secure-ls";
const ls = new SecureLS({ 
  encodingType: 'rc4',
  isCompression: true,
  encryptionSecret: '@#$@#$@^#@$%'
});
class StorageStore {
  #key = ''
  #encrypted = false
  get key(){ 
    return this.#key
  }
  #callBacks = {
    onSet: null,
    onRemove: null,
  }
  onset = null;
  onremove = null;
  constructor(key,options={}){
    this.#key = key
    this.#encrypted = !!options.encrypted
    this.#callBacks.onSet=options?.onSet ?? null
    this.#callBacks.onRemove=options?.onRemove ?? null
  }
  set(data,cb){
    let payload = JSON.stringify(data ?? '')
    if(this.#encrypted){
      ls.set(this.#key,payload)
    } else {
      localStorage.setItem(this.#key, payload)  
    }
    this.#callBacks.onSet?.(data)
    this.onset?.(data)
    cb?.(data)
  }
  get(){
    try {
      let res;
      if(this.#encrypted){
        res = ls.get(this.#key)
      } else {
        res = localStorage.getItem(this.#key)
      }
      return JSON.parse(res || null)
    } catch {
      return null
    }
  }
  remove(){
    if(this.#encrypted){
      ls.remove(this.#key)
    } else {
      localStorage.removeItem(this.#key)
    }
    this.#callBacks.onRemove?.()
    this.onremove?.()
  }
}
const TokenService = {
  MATTERMOST_TOKEN: new StorageStore('MATTERMOST_TOKEN'),
  MATTERMOST_USER: new StorageStore('MATTERMOST_USER'),
  DEVICES_SETTING: new StorageStore('DEVICES_SETTING'),
  USER: new StorageStore('USER',{
    onSet(user){
      if(user){
        TokenService.LAST_LOGIN.set({
          name: `${user.firstname} ${user.lastname}`,
          image: `${user.profileImg}`
        })
      }
    },
  }),
  TOKEN: new StorageStore('TOKEN'),
  REFRESH_TOKEN: new StorageStore('REFRESH_TOKEN'),
  BILLING_TOKEN: new StorageStore('billing_token'),
  STORED_POSTS: new StorageStore('stored_posts'),
  SECONDARY_SERVER: new StorageStore('SECONDARY_SERVER'),
  CP_DOMAIN: new StorageStore('CP_DOMAIN'),
  SETTING: new StorageStore('SETTING',{
    encrypted: true,
  }),
  LAST_LOGIN: new StorageStore('LAST_LOGIN'),
  URLS: new StorageStore('URLS'),
  USER_STATUSES: new StorageStore('USER_STATUSES'),
  CHAT: new StorageStore('CHAT'),
  APP_DATA: new StorageStore('APP_DATA'),
  SNOOZE_LABEL: new StorageStore('SNOOZE_LABEL'),
  REGION_PLATFORM: new StorageStore('REGION_PLATFORM'),
  REMEMBER_ME: new StorageStore('REMEMBER_ME',{
    encrypted: true,
  }),
  PAMARLY_DATA: new StorageStore('PAMARLY_DATA',{
    encrypted: true,
  }),
  CRM_DATA: new StorageStore('CRM_DATA',{
    encrypted: true,
  }),
  removeStorage(){
    this.PAMARLY_DATA.remove()
    this.CRM_DATA.remove()
    this.SNOOZE_LABEL.remove()
    this.APP_DATA.remove()
    this.CHAT.remove()
    this.URLS.remove()
    this.STORED_POSTS.remove()
    this.BILLING_TOKEN.remove()
    this.MATTERMOST_TOKEN.remove()
    this.MATTERMOST_USER.remove()
    this.USER.remove()
    this.TOKEN.remove()
    this.REFRESH_TOKEN.remove()
  },
};

export { TokenService };