var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "sec-common"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('button', {
    staticClass: "btn btn-primarymike space4btn-when-scroll-activate",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('ExportTimeoffApproverModal');
      }
    }
  }, [_vm._v(" Export ")])]), _vm._m(1), _c('div', {
    staticClass: "mar-top-hrm25 d-flex flex-wrap"
  }, [_c('div', {
    staticClass: "home-dd-container mr-0"
  }, [_c('b-dropdown', {
    staticClass: "home-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "home-button-content-dropi"
        }, [_c('div', {
          staticClass: "home-dropi-contenti"
        }, [_vm._v("All teams")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v("New")]), _c('b-dropdown-item', [_vm._v("To be announced (4)")])], 1)], 1), _c('div', {
    staticClass: "home-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "home-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "home-button-content-dropi"
        }, [_c('div', {
          staticClass: "home-dropi-contenti"
        }, [_vm._v("All locations")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v("GMT 5+")]), _c('b-dropdown-item', [_vm._v("GMT 9+")]), _c('b-dropdown-item', [_vm._v("GMT 8+")])], 1)], 1), _c('div', {
    staticClass: "home-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "home-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "home-button-content-dropi"
        }, [_c('div', {
          staticClass: "home-dropi-contenti"
        }, [_vm._v("All employment statuses")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v("Approved")]), _c('b-dropdown-item', [_vm._v("Pending")]), _c('b-dropdown-item', [_vm._v("Rejected")])], 1)], 1)]), _c('vb-table', {
    staticClass: "timeoffapprover-Setting-table",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('div', {
    staticClass: "commonth onetd col-left"
  }, [_c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox",
    attrs: {
      "b-form-checkbox": ""
    }
  })], 1), _c('h5', {
    staticClass: "commonth secondtd"
  }, [_vm._v("Employee")]), _c('h5', {
    staticClass: "commonth thirdtd"
  }, [_vm._v("Admin")]), _c('h5', {
    staticClass: "commonth fourtd"
  }, [_vm._v("Direct Manager")]), _c('h5', {
    staticClass: "commonth fivetd"
  }, [_vm._v("Team Manager")]), _c('h5', {
    staticClass: "commonth sixtd"
  }, [_vm._v("Indivisual Approvers")]), _c('h5', {
    staticClass: "commonth seventd col-right"
  }, [_vm._v("Action")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "firsttd col-left"
  }, [_c('b-form-checkbox', {
    staticClass: "SettingPageCheckbox",
    attrs: {
      "b-form-checkbox": ""
    }
  })], 1), _c('td', {
    staticClass: "secondtd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "timeoff-img-avtar",
    attrs: {
      "src": require("../../../assets/images/credit-card/deftime.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "timeoff-profile"
  }, [_c('h5', {
    staticClass: "timeoff-profile-name"
  }, [_vm._v("Jackson")])])])]), _c('td', {
    staticClass: "thirdtd"
  }, [_vm._v(" yes ")]), _c('td', {
    staticClass: "fourtd"
  }, [_vm._v(" No ")]), _c('td', {
    staticClass: "fivetd"
  }, [_vm._v(" No ")]), _c('td', {
    staticClass: "sixtd"
  }, [_vm._v(" - ")]), _c('td', {
    staticClass: "seventd col-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])], 2)], 1)]), _c('ExportTimeoffApproverModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Time off approvers")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "notes-top-text space4text-when-scroll-activate"
  }, [_vm._v(" Here you can set up who will receive time off requests for approval for your employees ")])]);

}]

export { render, staticRenderFns }