<template>
  <modal class="dialer_animation right_side_popup full-width-big-modal" height="auto" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    <div class="dialer-assign-to my-4 dialer-edit">
      <div class="dialer-edit-actions d-flex justify-content-end">
        <button class="newCloseButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    <WelcomeGreeting class="latestGreyBox-9-9-2023 newIVRLayout-WelcomeGreetingsSection mt-20px" @fileChange="$emit('file-change');" :menu="ivr" />
  </modal>
</template>

<script>
import WelcomeGreeting from "../IVR/WelcomeGreeting.vue";
import { mapGetters } from 'vuex';
export default {
  name: 'IVRWelcomeGreetingmodal',
  components: {
    WelcomeGreeting,
  },
  inject: ['getIvrs'],
  props: {
    modalName: {
      type: String,
      default: 'IVRWelcomeGreetingmodal',
    },
  },
  data(){
    return {
      data: {
        accountcode: '',
      },
    }
  },
  computed: {
    ...mapGetters({
      getVoipIVRSAlises: 'getVoipIVRSAlises',
    }),
    ivr() { return this.getVoipIVRSAlises[this.data.accountcode] },
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode = event?.params?.accountcode || ''
    },
    onBeforeClose(){
      this.data.accountcode = ''
    },
  },
}
</script>

<style>

</style>