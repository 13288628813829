<template>
  <modal 
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"  
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Add User</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div class="innerModalIos">

      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div class="basicWhiteIOScard-item" @click="$modal.show('AddUserByEmail')">
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <div class="headingInsideTable">Add User By Email</div>
            </div>  
          </div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
        </div>
        <div class="basicWhiteIOScard-item" @click="$modal.show('AddUserByUserName')">
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <div class="headingInsideTable">Add User By Username</div>
            </div>  
          </div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
        </div>
      </div>
      <AddUserByEmail />
      <AddUserByUserName />
    </div>
  </modal>
</template>

<script>
import AddUserByEmail from "./AddUserByEmail.vue";
import AddUserByUserName from "./AddUserByUserName.vue";

export default {
  name: "AddUserANDSubUserModal",
  props: {
    modalName: {
      type: String,
      default: 'AddUserANDSubUser',
    },
  },
  components: {
    AddUserByEmail,
    AddUserByUserName
  },
  methods: {
    onBeforeOpen(){
      // TODO
    },
    onBeforeClose(){
      // TODO
    },
  },
};
</script>


<style>
.slide-ltr-enter-active {
  animation: slide-ltr 0.5s reverse;
}
.slide-ltr-leave-active {
  animation: slide-ltr 0.5s;
}
@keyframes slide-ltr {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>