<template>
  <div>
    <div class="flow__graph designed designedAgain responsiveGraph scaleDown-0-6" ref="app_designed" id="app_designed">
      <div v-if="team" class="w-100 d-flex justify-content-center align-items-center flex-column">
        <div class="w-auto d-flex justify-content-center align-items-start flex-column" >
          <NumbersNode :dids="team.ringroup.did" />
          <InfoNode :voipaccount="team.ringroup.accountcode" @edit="$modal.show('EditTeamGraphModal',{ team: team })" />
          <OutOfHoursNode ref="ofh" :voipaccount="team.ringroup.accountcode" />
          <div v-if="team.members.length>0">
            <div style="width: 200px;">
              <div class="nodeBasic-new " style="min-width: auto;margin-left: auto;margin-right: auto;" >
                <vb-icon icon="teamsInsideGraph-icon" height="50px" width="50px"/>
              </div>
            </div>
            <div class="d-flex justify-content-center" style="width: 200px;">
              <div class="lineWithArrow">
                <div class="lineWithArrow-line"></div>
              </div>
            </div>
            <div class="d-flex">
              <div>
                <div v-for="member in team.members" :key="member.accountcode" :class="`mb-3 d-flex align-items-center position-relative`">
                  <div :class="`lineOnlyForIVR `" style="align-self: stretch;width: 200px">
                    <div class="lineOnlyForIVR-y"></div>
                    <div class="lineOnlyForIVR-x"></div>
                  </div>
                  <div class="nodeBasic-new" >
                    <Info :id="member.accountcode" :type="'USER'" :is_blf="false"  />
                  </div>
                </div>
              </div>
            </div>
            <div class="d-flex justify-content-center" style="width: 200px;">
              <div class="lineWithArrow">
                <div class="lineWithArrow-line"></div>
                <div class="lineWithArrow-arrow"></div>
              </div>
            </div>
          </div>
          <div style="width: 200px;">
            <div class="nodeBasic-new " style="min-width: auto;margin-left: auto;margin-right: auto;" >
              <b-icon icon="telephone-fill" variant="primary"/>
            </div>
          </div>
          <ForwardSettingNode :openedFromTeams="true" :account="team.ringroup.accountcode" />
        </div>
      </div>
    </div>
    <EditTeamModal 
      :modalName="'EditTeamGraphModal'"
      @ofh-days-updated="$refs.ofh.fetchSchedule();"
      @ofh-default-updated="$refs.ofh.fetchDefaultSetting();"
      @ofh-toggle-updated="$refs.ofh.fetchcallRouteSetting();"
      @cf-updated="$refs.ofh.fetchcallRouteSetting()"
    />
  </div>
</template>

<script>
import NumbersNode from '../common/custom/NumbersNode.vue'
import InfoNode from '../common/custom/InfoNode.vue'
import OutOfHoursNode from '../common/custom/OutOfHoursNode.vue'
import ForwardSettingNode from '../common/custom/ForwardSettingNode.vue'
import EditTeamModal from '../../Modals/EditTeamModal.vue'
import Info from '../../Lists/Info.vue'
import { mapGetters } from 'vuex'
export default {
  name: 'TeamGraph',
  components:{
    NumbersNode,
    InfoNode,
    OutOfHoursNode,
    ForwardSettingNode,
    EditTeamModal,
    Info,
  },
  props:{
    accountcode:{
      type: String
    }
  },
  computed:{
    ...mapGetters({
      getVoipTeamsAlises: 'getVoipTeamsAlises',
    }),
    team(){ return this.getVoipTeamsAlises[this.accountcode] },
  },
}
</script>

<style>

</style>