var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Accountant permissions ")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Employees in this group can access time off reports ")]), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-description mt-16px"
  }, [_vm._v(" Current employees with accountant access ")]), _c('div', {
    staticClass: "innerShadowInput-container mt-32px"
  }, [_c('label', {}, [_vm._v("Accountants")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd w-536px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All Accountants")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All Accountants ")])], 1)], 1), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Give access to employee time off data ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Give access to employee compensation ")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary w-fit-content mt-32px mr-0"
  }, [_vm._v(" Save ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }