<template>
  <div id="user_detail_exten">
    <template>
      <!-- <h2 class="dialer-edit-title mb-20px">Extension limits</h2> -->
      <vb-table class="latestTableDesign-withBlackBorders-again mt-0"
        :isListEmpty="isEmpty(extensionlimit)" 
        :loading="api.fetch_extension_detail.send"  
        :listLength="extensionlimit.length" 
      >
        <tr slot="header">
          <th class="dialer-has-sort">
            <span>Label</span>
          </th>
          <th class="dialer-has-sort">
            <span>Allowed</span>
          </th>
          <th class="dialer-has-sort">
            <span>Used</span>
          </th>
          <th class="dialer-has-sort dialer-col-right">
            <span>Remaining</span>
          </th>
        </tr>
        <template #body>
          <tr v-for="(limit, type) in extensionlimit" :key="type">
            <td class="dialer-row-title">{{ type | extensionText }}</td>
            <td class="dialer-row-title">{{ limit.allowed }}</td>
            <td class="dialer-row-title">{{ limit.used }}</td>
            <td class="dialer-row-title dialer-col-right">{{ limit.remaining }}</td>
          </tr>
        </template>
        <template #loading>
          <tr class v-for="n in 3" :key="n.id">
            <td class="dialer-row-title"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
            <td class="dialer-row-title extentionSecretContainer-td">
              <div class="latestShimmerDesign w-100" style="height:24px;"></div>
            </td>
            <td class="dialer-row-title"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
            <td class="dialer-col-left"><div class="latestShimmerDesign w-100" style="height:24px;"></div></td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign w-100" style="height:24px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
    </template>
  </div>
</template>

<script>
import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex'

export default {
  name: 'UserExtensionDetail',
  inject: ['getProperty','isEmpty','appNotify'],
  props: {
    voipaccount: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      api: {
        fetch_extension_detail: this.$helperFunction.apiInstance({  }),
      },
      response: {
        extension_detail: {},
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
    ]),
    extensions(){ return this.response.extension_detail?.extensions ?? [] },
    extensionlimit(){ return this.response.extension_detail?.extensionlimit ?? {} },
  },
  filters: {
    extensionText(value){
      if(value=='WEBEXT' || value=='web') return 'Web app'
      else if(value=='DESKTOPEXT' || value=='desktop') return 'Desktop app'
      else if(value=='MOBILEEXT' || value=='mobile') return 'Mobile app'
      else if(value=='handset') return 'Handset'
      else if(value=='external') return 'External'
      else if(value=='integration') return 'Integration'
      else return value
    },
  },
  methods: {
    async fetchExtensionDetail(){
      if(this.api.fetch_extension_detail.send) return;
      try {
        this.api.fetch_extension_detail.send=true
        const { data: { data: extensonDetail } } = await VOIP_API.endpoints.users.userextensiondetail(this.voipaccount);
        this.response.extension_detail=extensonDetail ?? {}
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_extension_detail.send=false
      }
    },
  },
  created() {
    this.fetchExtensionDetail();
  },
};
</script>
<style>
.showloading {
  display: block;
}
.hideloading {
  display: none;
}

.limit {
  font-size: 30px;
  font: -webkit-control;
}
</style>