<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-600 scrollable EditPictureModal IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"   
      :name="modalName" 
      @before-close="onBeforeClose()" 
      @before-open="onBeforeOpen($event)"
    >
      <div v-if="conditions.screen==1" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="conditions.screen=0">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Category</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Update Image</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">
        <template v-if="conditions.screen==1">
          

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" v-for="category in response.categories" :key="category.id" @click="selected.category=category;conditions.screen=0;">
              <div class="headingInsideCard mr-8px">{{ category.label }}</div>
              
              <vb-icon v-if="selected.category.id==category.id" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            </div>
            <vb-no-record v-if="isEmpty(response.categories)" :text="api.fetch_categories.send ? '' : 'There is no Category'" :design="3">
              <vb-loading v-if="api.fetch_categories.send" slot="loading" />
            </vb-no-record>
          </div>

        </template>
        <template v-else>
          

          <div class="mt-32px currentImageContainerIos" :aria-disabled="api.upload.send">
            <div class="loader-icon" v-if="api.upload.send">
              <vb-loading />
              <div class="loaderPercentageText w-100 d-flex justify-content-center">
              {{ api.upload.upload_progress.percentage }}%
              </div>
            </div>
            <div class="current-image" v-else>
              <img id="profile-image" :src="data.current_image || ''" @error="$event.target.src=local_filess.images.user_male" :alt="'Profile Image'" />
            </div>
          </div>

          <div class="textAboveCard mt-8px">Update <span style="text-transform:lowercase">{{ data.type }}</span> profile</div>
          <template v-if="['USER','COMPANY'].includes(data.type)">
            <div class="basicWhiteIOScard mb-16px">
              <div class="basicWhiteIOScard-item" @click="$refs.image_uploader.click()">
                <div class="d-flex align-items-center">
                  <div class="basicIOSIcon mr-16px">
                    <vb-icon class="" icon="uploadProfileImage-IOSIcon"/>
                  </div>
                  <div class="headingInsideCard mr-8px">Upload Image</div>
                  <input 
                    ref="image_uploader"
                    v-show="false"
                    class="hide_input cursor_pointer_no_hover" 
                    style="" 
                    type="file" 
                    accept="image/*" 
                    :disabled="api.upload.send" 
                    :multiple="false" 
                    @change="uploadImage({ type: 'upload', event: $event })" 
                    v-b-tooltip.hover 
                    title="Upload image" 
                  />
                </div>
                <div class="d-flex align-items-center">
                  <vb-icon icon="uploadProfileImage-onRightSide-IOSIcon" class="" width="21.562" height="21.562" />
                </div>
              </div>
            </div>
          </template>

          <div class="basicWhiteIOScard mb-16px">
            <div class="basicWhiteIOScard-item" @click="conditions.screen=1">
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <vb-icon class="" icon="uploadProfileImage-IOSIcon"/>
                </div>
                <div class="headingInsideCard mr-8px">Category</div>
              </div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard textAlignRight onRightSide mr-6px">
                  {{ selected.category ? selected.category.label : 'Select Category'}}
                </div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
          </div>

          
        </template>
      </div>
      <!-- done by frank -->
      <div :class="`all-images-container Ios ${conditions.show_upload ? 'small' : ''}`" v-if="conditions.screen!=1">
        <div id="profile-image-uploader-avatars-div" class="all-images-container-inner">
          <vb-loading v-if="isEmpty(response.avatars) && (api.fetch_avatars.send || api.fetch_categories.send )" />
          <div v-for="avatar in response.avatars" class="image-container" :key="avatar.id" @click="uploadImage({ type: 'avatar', avatar: avatar })">
            <img :id="`avatar-${avatar.id}`" :src="avatar.image" @error="$event.target.src=require('@/assets/images/user-male.png')" />
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils/index'
let types = {
  USER: 'USER',
  COMPANY: 'COMPANY',
  IVR: 'IVR',
  TEAM: 'TEAM',
  CONFERENCE: 'CONFERENCE',
  ADDRESS_BOOK: 'ADDRESS_BOOK',
  SPEED_DIAL: 'SPEED_DIAL',
  CALL_QUEUE: 'CALL_QUEUE',
}
export default {
  name: 'ProfileImageUploaderModal',
  inject: ['local_filess','isEmpty','getVoipUsers','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'ProfileImageUploader',
    },
  },
  data(){
    return {
      api: {
        upload: this.$helperFunction.apiInstance({ source: true, upload_progress: true, }),
        fetch_avatars: this.$helperFunction.apiInstance(),
        fetch_categories: this.$helperFunction.apiInstance(),
        organizations: this.$helperFunction.apiInstance(),
        reset(){
          this.upload?.reset?.({ cancel_message: 'Cancel Request' })
          this.fetch_avatars?.reset?.()
          this.fetch_categories?.reset?.()
          this.organizations?.reset?.()
        },
      },
      selected: {
        category: null,
        reset(){
          this.category = null
        },
      },
      data: {
        accountcode: '',
        type: '',
        current_image: '',
        reset(){
          this.accountcode = ''
          this.type = ''
          this.current_image = ''
        },
      },
      response: {
        avatars: [],
        categories: [],
        reset(){
          this.avatars=[]
          this.categories=[]
        },
      },
      conditions: {
        show_upload: false,
        screen: 0,
        toggleShowUpload(){
          this.show_upload=!this.show_upload
        },
        reset(){
          this.show_upload=false
          this.screen=0
        },
      },
    }
  },
  computed: {
    organizationimagespath(){ return `${this.$store.getters.getCurrentUser?.apiServer.split("/").slice(0, -1).join("/")}/storage/defaults/logos/` },
    userimagepath(){ return this.$store.getters.getCurrentUser?.profileImg.split("/").slice(0, -1).join("/") },
  },
  watch: {
    "selected.category"(category){
      this.response.avatars=[]
      if(category){
        this.fetchAvatars()
      }
    }
  },
  methods: {
    onBeforeOpen(event){
      this.data.type = event?.params?.type ?? ''
      this.data.accountcode = event?.params?.accountcode ?? ''
      this.data.current_image = event?.params?.current_image ?? ''
      if(!this.data.accountcode || !types[this.data.type]){
        this.$modal.hide(this.modalName)
      }
      this.fetchCategories()
    },
    onBeforeClose(){
      this.data.reset()
      this.selected.reset()
      this.api.reset()
      this.response.reset()
      this.conditions.reset()
    },
    async uploadImage(data = {}) {
      let vm = this
      try {
        const upload_type = data?.type ?? ''
        const Event = data?.event
        const avatar = data?.avatar
        const formData = new FormData();
        this.api.upload.source?.cancel?.("Requesting Again");
        if (this.api.upload.send || !upload_type) return;
        this.api.upload.send=true
        this.api.upload.source = require("axios").default.CancelToken.source();
        if(upload_type=='upload'){
          const [file] = Event.target.files;
          const [type, extension] = file.type.split("/");
          formData.append("image", file, file.name);
          formData.append("extension", extension);
          formData.append("type", type);
          formData.append("is_upload", "yes");
          formData.append("avatar_id", 0);
        } else {
          // const src = document.getElementById(`avatar-${avatar.id}`).src
          // if (!src) return;
          // const res = await fetch(src)
          // const blob = await res.blob()
          // formData.append("image", blob, avatar.id);
          // formData.append("extension", 'png');
          // formData.append("type", 'image');
          formData.append("is_upload", "no");
          formData.append("avatar_id", avatar.id);
        }
        formData.append("accountcode", this.data.accountcode);
        formData.append("uid", this.$store.getters.getCurrentUser?.uid);

        if(this.data.type==types.USER){
          formData.append("change_image_type", "profile");
        } else if(this.data.type==types.COMPANY){
          formData.append("change_image_type", "company");
        }
        if(this.data.type==types.USER){
          formData.append("type", 'USER');
        } else if(this.data.type==types.COMPANY){
          formData.append("type", 'COMPANY');
        } else if(this.data.type==types.IVR){
          formData.append("type", 'IVR');
        } else if(this.data.type==types.TEAM){
          formData.append("type", 'TEAM');
        } else if(this.data.type==types.CONFERENCE){
          formData.append("type", 'CONFERENCE');
        } else if(this.data.type==types.ADDRESS_BOOK){
          formData.append("type", 'ADDRESSBOOK');
        } else if(this.data.type==types.SPEED_DIAL){
          formData.append("type", 'SPEEDDIAL');
        } else if(this.data.type==types.CALL_QUEUE){
          formData.append("type", 'CALLQUEUE');
        } else {
          formData.append("type", '');
        }
        formData.append("id", this.data.accountcode);
        const res = await VOIP_API.endpoints.user.updateprofileimage(formData,this.api.upload.upload_progress.onProgress.bind(this.api.upload.upload_progress),this.api.upload.source.token)
        let image_path = ''
        if(upload_type=='upload'){
          if(this.data.type==types.USER) image_path = `${this.userimagepath}/${res?.data?.profile_img}`
          if(this.data.type==types.COMPANY) image_path = `${this.organizationimagespath}${res?.data?.company_logo}`
        } else {
          image_path=avatar?.image ?? ''
        }
        
        
        if(this.data.type==types.USER){ // current user
          if(this.data.accountcode==this.$store.getters.getCurrentUser?.account){
            this.$store.state.common.user.profileImg = image_path;
          }
          this.getVoipUsers()
        } else if(this.data.type==types.COMPANY){ // organizzation
          this.$store.state.common.user.companyLogo = image_path;
          setTimeout(()=>{
            vm.fetchOrganizations()
          },5 * 1000)
        } else if(this.data.type==types.TEAM){ // team
          //TODO
        } else if(this.data.type==types.IVR){ // ivr
          //TODO
        } else if(this.data.type==types.CONFERENCE){ // conference
          //TODO
        } else if(this.data.type==types.ADDRESS_BOOK){ // address book
          //TODO
        } else if(this.data.type==types.SPEED_DIAL){ // speed dial
          //TODO
        } else if(this.data.type==types.CALL_QUEUE){ // call queue
          //TODO
        }
        this.data.current_image=image_path
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        this.$emit('image-uploaded',this.data.current_image)
      } catch(ex){
        this.appNotify({
          message: ex.own_message ?? ex.message ?? '',
          type: 'danger',
        })
      } finally {
        this.api.upload.send = false;
        this.api.upload.source = null;
      }
    },
    fetchAvatars(){
      let vm = this
      if(vm.api.fetch_avatars.send) return;
      vm.api.fetch_avatars.send=true
      VOIP_API.endpoints.default_avatars.getdefaultavatarsbycategoryid({
        category_id: this.selected.category.id
      }).then(({ data })=>{
        const avatars = data?.data ?? data
        vm.response.avatars=avatars
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.fetch_avatars.send=false
      })
    },
    fetchCategories(){
      let vm = this
      if(vm.api.fetch_categories.send) return;
      vm.api.fetch_categories.send=true
      VOIP_API.endpoints.default_avatars.getcategories().then(({ data })=>{
        const categories = data?.data ?? data
        vm.response.categories=categories
        vm.selected.category=categories?.[0]
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.fetch_categories.send=false
      })
    },
    fetchOrganizations(){
      let vm = this
      if(vm.api.organizations.send) return;
      vm.api.organizations.send=true
      VOIP_API.endpoints.user.organizationlist({
        accountcode: vm.$store.getters.getCurrentUser?.account, //vm.current_user.account,
        uid: vm.$store.getters.getCurrentUser?.uid, //vm.current_user.uid
      }).then(({ data: { data: organizations } })=>{
        const new_organizations_array = organizations.map(organization=>{
          return {
            id: organization.origanization.origanization_id,
            uid: organization.origanization.origanization_uid,
            label: organization.origanization.origanization_label,
            logo: organization.origanization.origanization_logo,
          }
        })
        vm.$store.state.common.user.organizations = new_organizations_array;
        vm.$store.state.common.user.selected_organization_id = vm.$store.state.common.user.selected_organization_id || new_organizations_array[0] ? new_organizations_array[0].id : '';
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.organizations.send=false
      })
    },
  },
}
</script>

<style lang="scss" scoped>
.profile-image-section {
  position: relative;
  width: 180px;
  height: 180px;
  display: flex;
  overflow: hidden;
  // @include border-radius(50%);
  @include border-radius(12%);
  .loader-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .profile-img-upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: none;
    justify-content: center;
    .upload-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      opacity: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(50%);
    }
  }
  &:hover {
    .profile-img-upload-btn {
      display: block;
      cursor: pointer;
    }
  }
}
</style>