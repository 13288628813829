import { MEDIA_DEVICE_SETTINGS_LOGGER } from './console'
import { TokenService } from '../services/index'
import { permissions } from './permissions'
const Emitter = require('component-emitter')
const ringtone_types = {
  receive_message: 'receive_message',
  send_message: 'send_message',
  message: 'message',
  alert: 'alert',
  incoming: 'incoming',
}
const ringtones = [
  {
    label: 'Alert 1',
    file: require('../assets/sounds/ringtons/alert/Alert 1.mp3'),
    type: [ringtone_types.alert],
    default: [ringtone_types.alert],
    id: 1,
  },
  {
    label: 'Alert 2',
    file: require('../assets/sounds/ringtons/alert/Alert 2.mp3'),
    type: [ringtone_types.alert],
    default: [],
    id: 2,
  },
  {
    label: 'Alert 3',
    file: require('../assets/sounds/ringtons/alert/Alert 3.mp3'),
    type: [ringtone_types.alert],
    default: [],
    id: 3,
  },
  {
    label: 'Alert 4',
    file: require('../assets/sounds/ringtons/alert/Alert 4.mp3'),
    type: [ringtone_types.alert],
    default: [],
    id: 4,
  },
  {
    label: 'Alert 5',
    file: require('../assets/sounds/ringtons/alert/Alert 5.mp3'),
    type: [ringtone_types.alert],
    default: [],
    id: 5,
  },
  {
    label: 'Ring 1',
    file: require('../assets/sounds/ringtons/ringer/Ring 1.mp3'),
    type: [ringtone_types.incoming],
    default: [ringtone_types.incoming],
    id: 6,
  },
  {
    label: 'Ring 2',
    file: require('../assets/sounds/ringtons/ringer/Ring 2.mp3'),
    type: [ringtone_types.incoming],
    default: [],
    id: 7,
  },
  {
    label: 'Ring 3',
    file: require('../assets/sounds/ringtons/ringer/Ring 3.mp3'),
    type: [ringtone_types.incoming],
    default: [],
    id: 8,
  },
  {
    label: 'Ring 4',
    file: require('../assets/sounds/ringtons/ringer/Ring 4.mp3'),
    type: [ringtone_types.incoming],
    default: [],
    id: 9,
  },
  {
    label: 'Ring 5',
    file: require('../assets/sounds/ringtons/ringer/Ring 5.mp3'),
    type: [ringtone_types.incoming],
    default: [],
    id: 10,
  },
  {
    label: 'Ring 6',
    file: require('../assets/sounds/ringtons/ringer/Ring 6.mp3'),
    type: [ringtone_types.incoming],
    default: [],
    id: 11,
  },
  {
    label: 'Ring 7',
    file: require('../assets/sounds/ringtons/ringer/Ring 7.mp3'),
    type: [ringtone_types.incoming],
    default: [],
    id: 12,
  },
  {
    label: 'Ring 8',
    file: require('../assets/sounds/ringtons/ringer/Ring 8.mp3'),
    type: [ringtone_types.incoming],
    default: [],
    id: 13,
  },
  {
    label: 'Ring 9',
    file: require('../assets/sounds/ringtons/ringer/Ring 9.mp3'),
    type: [ringtone_types.incoming],
    default: [],
    id: 14,
  },
  {
    label: 'Ring 10',
    file: require('../assets/sounds/ringtons/ringer/Ring 10.mp3'),
    type: [ringtone_types.incoming],
    default: [],
    id: 15,
  },
  {
    label: 'message 1',
    file: require('../assets/sounds/ringtons/messages/message 1.mp3'),
    type: [ringtone_types.receive_message],
    default: [ringtone_types.receive_message],
    id: 16,
  },
  {
    label: 'message 2',
    file: require('../assets/sounds/ringtons/messages/message 2.mp3'),
    type: [ringtone_types.send_message],
    default: [ringtone_types.send_message],
    id: 17,
  },
  {
    label: 'message 3',
    file: require('../assets/sounds/ringtons/messages/message 3.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 18,
  },
  {
    label: 'message 4',
    file: require('../assets/sounds/ringtons/messages/message 4.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 19,
  },
  {
    label: 'message 5',
    file: require('../assets/sounds/ringtons/messages/message 5.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 20,
  },
  {
    label: 'message 6',
    file: require('../assets/sounds/ringtons/messages/message 6.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 21,
  },
  {
    label: 'message 7',
    file: require('../assets/sounds/ringtons/messages/message 7.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 22,
  },
  {
    label: 'message 8',
    file: require('../assets/sounds/ringtons/messages/message 8.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 23,
  },
  {
    label: 'message 9',
    file: require('../assets/sounds/ringtons/messages/message 9.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 24,
  },
  {
    label: 'message 10',
    file: require('../assets/sounds/ringtons/messages/message 10.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 25,
  },
  {
    label: 'message 11',
    file: require('../assets/sounds/ringtons/messages/message 11.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 26,
  },
  {
    label: 'message 12',
    file: require('../assets/sounds/ringtons/messages/message 12.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 27,
  },
  {
    label: 'message 13',
    file: require('../assets/sounds/ringtons/messages/message 13.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 28,
  },
  {
    label: 'message 14',
    file: require('../assets/sounds/ringtons/messages/message 14.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 29,
  },
  {
    label: 'message 15',
    file: require('../assets/sounds/ringtons/messages/message 15.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 30,
  },
  {
    label: 'message 16',
    file: require('../assets/sounds/ringtons/messages/message 16.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 31,
  },
  {
    label: 'message 17',
    file: require('../assets/sounds/ringtons/messages/message 17.mp3'),
    type: [ringtone_types.receive_message,ringtone_types.send_message],
    default: [],
    id: 32,
  },
]
const events = {
  devices_change: 'devices_change',
  videoinput_change: 'videoinput_change',
  video_input_device_remove: 'video_input_device_remove',
  audio_input_device_remove: 'audio_input_device_remove',
  audio_output_device_remove: 'audio_output_device_remove',
  audioinput_change: 'audioinput_change',
  audiooutput_change: 'audiooutput_change',
  camera_id_change: 'camera_id_change',
  mic_id_change: 'mic_id_change',
  speaker_id_change: 'speaker_id_change',
  ringing_speaker_id_change: 'ringing_speaker_id_change',
  speaker_volume_change: 'speaker_volume_change',
  ringing_speaker_volume_change: 'ringing_speaker_volume_change',
  ringtone_audio_change: 'ringtone_audio_change',
  incoming_ringtone_audio_change: 'incoming_ringtone_audio_change',
  outgoing_ringtone_audio_change: 'outgoing_ringtone_audio_change',
  send_message_ringtone_change: 'send_message_ringtone_change',
  recieve_message_ringtone_change: 'recieve_message_ringtone_change',
  alert_ringtone_change: 'alert_ringtone_change',
  incoming_ringtone_change: 'incoming_ringtone_change',
}
const audio_devices_local_storage = TokenService.DEVICES_SETTING.get() ?? {}
export const MEDIA_DEVICES_SETTING = {
  list: [],
  camera_id: audio_devices_local_storage.video ?? '',
  speaker_id: audio_devices_local_storage.output ?? '',
  ringing_speaker_id: audio_devices_local_storage.ringing ?? '',
  mic_id: audio_devices_local_storage.microphone ?? '',
  speaker_volume: audio_devices_local_storage.volume ?? 100,
  ringing_speaker_volume: audio_devices_local_storage.ringing_volume ?? 100,
  
  ringtone: audio_devices_local_storage.ringtone ?? 'incoming.mp3',

  incoming_ringtone: audio_devices_local_storage.incoming_ringtone ?? ringtones?.find?.(i=>i.default?.includes?.(ringtone_types.incoming))?.id ?? '',
  send_message_ringtone: audio_devices_local_storage.send_message_ringtone ?? ringtones?.find?.(i=>i.default?.includes?.(ringtone_types.send_message))?.id ?? '',
  recieve_message_ringtone: audio_devices_local_storage.recieve_message_ringtone ?? ringtones?.find?.(i=>i.default?.includes?.(ringtone_types.receive_message))?.id ?? '',
  alert_ringtone: audio_devices_local_storage.alert_ringtone ?? ringtones?.find?.(i=>i.default?.includes?.(ringtone_types.alert))?.id ?? '',
  
  set cameraId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(this.videoinputs.length>0 && !this.videoinputs.map(item=>item.deviceId).includes(value)) throw `id not exist in list ${value} cameraId`
    this.camera_id=value
    MEDIA_DEVICE_SETTINGS_LOGGER.log('cameraId',this.camera_id)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.video = value
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.camera_id_change,this.camera_id);
  },
  set micId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(this.audioinputs.length>0 && !this.audioinputs.map(item=>item.deviceId).includes(value)) throw `id not exist in list ${value} micId`
    this.mic_id=value
    MEDIA_DEVICE_SETTINGS_LOGGER.log('micId',this.mic_id)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.microphone = value
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.mic_id_change,this.mic_id);
  },
  set speakerId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(this.audiooutputs.length>0 && !this.audiooutputs.map(item=>item.deviceId).includes(value)) throw `id not exist in list ${value} speakerId`
    this.speaker_id=value
    MEDIA_DEVICE_SETTINGS_LOGGER.log('speakerId',this.speaker_id)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.output = value
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.speaker_id_change,this.speaker_id);
  },
  set ringingSpeakerId(value){
    if(typeof value != 'string') throw 'value must be string'
    if(this.audiooutputs.length>0 && !this.audiooutputs.map(item=>item.deviceId).includes(value)) throw `id not exist in list ${value} ringingSpeakerId`
    this.ringing_speaker_id=value
    MEDIA_DEVICE_SETTINGS_LOGGER.log('ringingSpeakerId',this.ringing_speaker_id)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.ringing = value
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.ringing_speaker_id_change,this.ringing_speaker_id);
  },

  set speakerVolume(value){
    const new_volume = parseInt(value)
    MEDIA_DEVICE_SETTINGS_LOGGER.log('speakerVolume',value)
    if(typeof new_volume != 'number') throw 'value must be number'
    if((new_volume>100 && new_volume<0) || isNaN(new_volume)) throw 'value must be between 0 to 100'
    this.speaker_volume=new_volume
    MEDIA_DEVICE_SETTINGS_LOGGER.log('speakerVolume',this.speaker_volume)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.volume = new_volume
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.speaker_volume_change,this.speaker_volume);
  },
  set ringingSpeakerVolume(value){
    const new_volume = parseInt(value)
    if(typeof new_volume != 'number') throw 'value must be number'
    if((new_volume>100 && new_volume<0) || isNaN(new_volume)) throw 'value must be between 0 to 100'
    this.ringing_speaker_volume=new_volume
    MEDIA_DEVICE_SETTINGS_LOGGER.log('ringingSpeakerVolume',this.ringing_speaker_volume)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.ringing_volume = new_volume
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.ringing_speaker_volume_change,this.ringing_speaker_volume);
  },

  set ringtoneAudio(value){
    this.ringtone=value
    MEDIA_DEVICE_SETTINGS_LOGGER.log('ringtoneAudio',this.ringtone)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.ringtone = value
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.ringtone_audio_change,this.ringtone);
  },

  set sendMessageRingtoneId(value){
    if(typeof value != 'number') throw 'value must be number'
    if(!this.sendMessageRingtons.find(i=>i.id==value)) throw 'value must be between 0 to 100'
    this.send_message_ringtone=value
    MEDIA_DEVICE_SETTINGS_LOGGER.log('sendMessageRingtoneId',this.send_message_ringtone)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.send_message_ringtone = value
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.send_message_ringtone_change,this.send_message_ringtone);
  },
  set recieveMessageRingtoneId(value){
    if(typeof value != 'number') throw 'value must be number'
    if(!this.recieveMessageRingtons.find(i=>i.id==value)) throw 'value must be between 0 to 100'
    this.recieve_message_ringtone=value
    MEDIA_DEVICE_SETTINGS_LOGGER.log('recieveMessageRingtoneId',this.recieve_message_ringtone)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.recieve_message_ringtone = value
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.recieve_message_ringtone_change,this.recieve_message_ringtone);
  },
  set alertRingtoneId(value){
    if(typeof value != 'number') throw 'value must be number'
    if(!this.alertRingtons.find(i=>i.id==value)) throw 'value must be between 0 to 100'
    this.alert_ringtone=value
    MEDIA_DEVICE_SETTINGS_LOGGER.log('alertRingtoneId',this.alert_ringtone)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.alert_ringtone = value
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.alert_ringtone_change,this.alert_ringtone);
  },
  set incomingRingtoneId(value){
    if(typeof value != 'number') throw 'value must be number'
    if(!this.incomingRingtons.find(i=>i.id==value)) throw 'value must be between 0 to 100'
    this.incoming_ringtone=value
    MEDIA_DEVICE_SETTINGS_LOGGER.log('incomingRingtoneId',this.incoming_ringtone)
    const storage = TokenService.DEVICES_SETTING.get() ?? {}
    storage.incoming_ringtone = value
    TokenService.DEVICES_SETTING.set(storage)
    this.emitting(this.events.incoming_ringtone_change,this.incoming_ringtone);
  },
  
  get videoinputs(){ return this.list.filter((device) => device.kind === "videoinput"); },
  get audioinputs(){ return this.list.filter((device) => device.kind === "audioinput"); },
  get audiooutputs(){ return this.list.filter((device) => device.kind === "audiooutput"); },
  
  get isJabra(){ return this.isJabraSpeaker && this.isJabraMic },
  get isJabraSpeaker(){ return !!this.selectedSpeakerDevice?.label?.toLowerCase?.()?.includes?.('jabra') },
  get isJabraMic(){ return !!this.selectedMicDevice?.label?.toLowerCase?.()?.includes?.('jabra') },
  get isLenovo(){ return this.isLenovoSpeaker && this.isLenovoMic },
  get isLenovoSpeaker(){ return !!this.selectedSpeakerDevice?.label?.toLowerCase?.()?.includes?.('lenovo') },
  get isLenovoMic(){ return !!this.selectedMicDevice?.label?.toLowerCase?.()?.includes?.('lenovo') },
  
  get selectedCameraDevice(){ return this.videoinputs.find(item=>item.deviceId==this.camera_id) },
  get selectedSpeakerDevice(){ return this.audiooutputs.find(item=>item.deviceId==this.speaker_id) },
  get selectedRingingSpeakerDevice(){ return this.audiooutputs.find(item=>item.deviceId==this.ringing_speaker_id) },
  get selectedMicDevice(){ return this.audioinputs.find(item=>item.deviceId==this.mic_id) },
  
  get sendMessageRingtons(){ return ringtones.filter(i=>i.type?.includes?.(ringtone_types.send_message)) },
  get recieveMessageRingtons(){ return ringtones.filter(i=>i.type?.includes?.(ringtone_types.receive_message)) },
  get alertRingtons(){ return ringtones.filter(i=>i.type?.includes?.(ringtone_types.alert)) },
  get incomingRingtons(){ return ringtones.filter(i=>i.type?.includes?.(ringtone_types.incoming)) },

  get selectedSendmessageRingtone(){ return this.sendMessageRingtons?.find?.(i=>i.id==this.send_message_ringtone) },
  get selectedRecievemessageRingtone(){ return this.recieveMessageRingtons?.find?.(i=>i.id==this.recieve_message_ringtone) },
  get selectedAlertRingtone(){ return this.alertRingtons?.find?.(i=>i.id==this.alert_ringtone) },
  get selectedIncomingRingtone(){ return this.incomingRingtons?.find?.(i=>i.id==this.incoming_ringtone) },

  get events(){ return events },
  
  init(){
    try {
      Emitter(this)
      if(navigator?.mediaDevices?.ondevicechange !== undefined){
        navigator.mediaDevices.ondevicechange = this.devicesChanged.bind(this)
      }
      this.devicesChanged()
      permissions?.on?.(permissions.events.change_camera_permission,this.devicesChanged.bind(this))
      permissions?.on?.(permissions.events.change_mic_permission,this.devicesChanged.bind(this))
      if(process.env.NODE_ENV!='production'){ 
        window.MEDIA_DEVICES_SETTING = this 
      }
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  async devicesChanged(){
    try {
      const devices = await navigator?.mediaDevices?.enumerateDevices?.() ?? []
      // filter empty device id
      const filtered_devices_empty_device_id = devices.filter(i=>i.deviceId)
      // get default and communication devices
      const default_camera_group_id = filtered_devices_empty_device_id.find(i=>i.deviceId=='default' && i.kind=="videoinput")?.groupId
      const default_mic_group_id = filtered_devices_empty_device_id.find(i=>i.deviceId=='default' && i.kind=="audioinput")?.groupId
      const default_speaker_group_id = filtered_devices_empty_device_id.find(i=>i.deviceId=='default' && i.kind=="audiooutput")?.groupId
      const communication_camera_group_id = filtered_devices_empty_device_id.find(i=>i.deviceId=='communications' && i.kind=="videoinput")?.groupId
      const communication_mic_group_id = filtered_devices_empty_device_id.find(i=>i.deviceId=='communications' && i.kind=="audioinput")?.groupId
      const communication_speaker_group_id = filtered_devices_empty_device_id.find(i=>i.deviceId=='communications' && i.kind=="audiooutput")?.groupId
      // filter dublicat devices
      const filtered_devices = filtered_devices_empty_device_id.filter((device) => !['default','communications'].includes(device.deviceId) )
      // group previous list by type
      const videoinputs = this.list.filter((device) => device.kind === "videoinput");
      const audioinputs = this.list.filter((device) => device.kind === "audioinput");
      const audiooutputs = this.list.filter((device) => device.kind === "audiooutput");
      // group new list by type
      const filtered_devices_videoinputs = filtered_devices.filter((device) => device.kind === "videoinput").map(i=>i.deviceId);
      const filtered_devices_audioinputs = filtered_devices.filter((device) => device.kind === "audioinput").map(i=>i.deviceId);
      const filtered_devices_audiooutputs = filtered_devices.filter((device) => device.kind === "audiooutput").map(i=>i.deviceId);
      // check is there a list change
      if(filtered_devices.length!=this.list.length){ 
        this.emitting(this.events.devices_change,this.list); 
      }
      // check the existing device is now exits
      if(!filtered_devices_videoinputs.includes(this.camera_id) && this.selectedCameraDevice) {
        this.emitting(this.events.video_input_device_remove,JSON.parse(JSON.stringify(this.selectedCameraDevice)));
      }
      MEDIA_DEVICE_SETTINGS_LOGGER.log(filtered_devices_audioinputs, this.mic_id)
      if(!filtered_devices_audioinputs.includes(this.mic_id) && this.selectedMicDevice) {
        this.emitting(this.events.audio_input_device_remove,JSON.parse(JSON.stringify(this.selectedMicDevice)));
      }
      if(!filtered_devices_audiooutputs.includes(this.speaker_id) && this.selectedSpeakerDevice) {
        this.emitting(this.events.audio_output_device_remove,JSON.parse(JSON.stringify(this.selectedSpeakerDevice)));
      }
      // 
      this.list=filtered_devices
      if(videoinputs.length!=this.videoinputs.length){ 
        this.emitting(this.events.videoinput_change,this.videoinputs); 
      }
      if(audioinputs.length!=this.audioinputs.length){ 
        this.emitting(this.events.audioinput_change,this.audioinputs); 
      }
      if(audiooutputs.length!=this.audiooutputs.length){ 
        this.emitting(this.events.audiooutput_change,this.audiooutputs); 
      }
      if(!this.selectedCameraDevice){ 
        this.cameraId = this.videoinputs?.find(i=>i.groupId==default_camera_group_id)?.deviceId ?? this.videoinputs?.find(i=>i.groupId==communication_camera_group_id)?.deviceId ?? this.videoinputs?.[0]?.deviceId ?? '';
      }
      // if(!this.selectedMicDevice){ 
        this.micId = this.audioinputs?.find(i=>i.groupId==default_mic_group_id)?.deviceId ?? this.audioinputs?.find(i=>i.groupId==communication_mic_group_id)?.deviceId ?? this.audioinputs?.[0]?.deviceId ?? '';
      // }
      // if(!this.selectedSpeakerDevice){ 
        this.speakerId = this.audiooutputs?.find(i=>i.groupId==default_speaker_group_id)?.deviceId ?? this.audiooutputs?.find(i=>i.groupId==communication_speaker_group_id)?.deviceId ?? this.audiooutputs?.[0]?.deviceId ?? '';
      // }
      // if(!this.selectedRingingSpeakerDevice){ 
        this.ringingSpeakerId = this.audiooutputs?.find(i=>i.groupId==default_speaker_group_id)?.deviceId ?? this.audiooutputs?.find(i=>i.groupId==communication_speaker_group_id)?.deviceId ?? this.audiooutputs?.[0]?.deviceId ?? '';
      // }
    } catch (ex) {
      MEDIA_DEVICE_SETTINGS_LOGGER.danger('devicesChanged',{ex})
    }
  },
  makeOnFunctionText(event){
    return 'on'+event.split('_').join('')
  },
  emitting(event,payload){
    if(!this.events[event]) return;
    this.emit(event,payload)
    this[this.makeOnFunctionText(event)]?.(payload)
  }
}