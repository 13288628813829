var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading"
  }, [_vm._v("Your HR dashboard")]), _c('div', {
    staticClass: "mainDescription"
  }, [_vm._v(" The key metrics from your current data to give you real-time insights into your company ")]), _c('div', {
    staticClass: "grayCard flexWrap"
  }, [_c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Hired in last 30 days ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("24")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Annual turnover ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("0%")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Hired in August ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("2")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Turnover in last 12 months ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("0%")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Left in last 30 days ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("24")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Monthly turnover ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("0%")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Left in August ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("24")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" First year turnover ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("0%")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Turnover in 2022 ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("0%")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Current retention ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("100%")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Average age of employees ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("45")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Average length of service ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("8y 5m")])])]), _c('div', {
    staticClass: "whiteSubCard"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Headcount movements ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-center"
  }, [_c('canvas', {
    attrs: {
      "id": "headcount-movements-chart"
    }
  })])])]), _c('div', {
    staticClass: "whiteSubCard"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Headcount "), _c('div', {
    staticClass: "HeadcountNumber"
  }, [_vm._v("11")])]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-center"
  }, [_c('canvas', {
    attrs: {
      "id": "doughnut-chart"
    }
  })])])]), _c('div', {
    staticClass: "whiteSubCard"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Age profile ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-center"
  }, [_c('canvas', {
    attrs: {
      "id": "age-profile-chart"
    }
  })])])])])]);

}]

export { render, staticRenderFns }