var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "basicWhiteIOScard-item noBottomBorder",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.out_of_hours, function () {
          return _vm.$modal.show(`${_vm.randomID}-OutOfHour`, {
            account: _vm.accountcode
          });
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Business hours")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.out_of_hours ? 'On' : 'Off'))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('OutOfHourModal', {
    attrs: {
      "modalName": `${_vm.randomID}-OutOfHour`,
      "randomID": _vm.randomID
    },
    on: {
      "ofh-toggle-updated": function ($event) {
        return _vm.fetchcallRouteSetting();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }