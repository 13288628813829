
import { VOIP_API } from "../index"

export const CALL_RECORDING_API_ENDPOINTS = {
  list: {
    auto(account) {
      return VOIP_API.axios.voip.request({
        url: `getcallrecording/auto/${account}`,
        method: "GET",
      })
    },
    ondemand(account) {
      return VOIP_API.axios.voip.request({
        url: `getcallrecording/ondemand/${account}`,
        method: "GET",
      })
    },
    voicememo(account) {
      return VOIP_API.axios.voip.request({
        url: `getcallrecording/voicememo/${account}`,
        method: "GET",
      })
    },
  },
  getrecordingsetting(account,params) {
    return VOIP_API.axios.voip.request({
      url: `getrecordingsetting/${account}`,
      method: "GET",
      params
    })
  },
  getUserCallRecordingSetting(accountcode='',params={},){
    return VOIP_API.axios.voip.request({
      url: `users-call-recording/${accountcode}`,
      method: "GET",
      params
    })
  },
}