export const files = {
  images: {
    attachments: {
      audio_file: require("@/assets/images/files-icon/audio-icon.svg"),
      video_file: require("@/assets/images/files-icon/video-icon.svg"),
      image_file: require("@/assets/images/files-icon/image-icon.svg"),
      pdf_file: require("@/assets/images/files-icon/pdf-icon.svg"),
      txt_file: require("@/assets/images/files-icon/text-icon.svg"),
      zip_file: require("@/assets/images/files-icon/zip-icon.svg"),
      other_file: require("@/assets/images/files-icon/other-icon.svg"),
    },
    user_male: require("@/assets/images/default.png"),
  },
};
