<template>
    <modal class="dialer_animation right_side_popup global-modal-hrm Scrollable-hrm " name="HRMAddNewNotesModal"  :clickToClose="true"  @before-open="beforeOpen" :scrollable="true">
      <form @submit.prevent="''">
          <div class="dialer-hrm-modal-header mar-top-hrm20">
            <h2 class="dialer-modal-hrm-title">Add new note</h2>
            <button class="dialer-hrm-button-modal" @click="$modal.hide('HRMAddNewNotesModal')">Close</button>
          </div>
          <div class="sp-gm-tn mar-top-hrm30">
            <label for="Street" class="gm-hrm-label">Note</label>
            <b-form-textarea
              id="textarea-small"
              size="sm"
              placeholder=""
              class="gm-hrm-textnote">
            </b-form-textarea>
          </div>
          <div class="mar-top-hrm20">
            <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Visible to managers</span>
            </b-form-checkbox>
          </div>
          <div>
            <p class="gm-hrm-file-size-text mar-top-hrm20">Max file size: 25 MB</p>
          </div>
          <div>
            <button class="btn-activity mar-top-hrm25">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.551" height="16.551" viewBox="0 0 16.551 16.551" class="grm-svg">
                <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z" transform="translate(-8.465 -8.465)" fill="#5576d1" stroke="#5576d1" stroke-width="1"/>
              </svg>
              Add attachment
            </button>
            <button class="btn-primarynewupdated1 mar-top-hrm30 mb-4">Save</button>
          </div>
      </form>
    </modal>
</template>
<script>
export default {
  name: 'HRMAddNewNotesModal',
}
</script>

<style>

</style>