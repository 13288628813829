var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [[_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Calls")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })])], _c('section', {
    staticClass: "dialer-settings-section dialer-call-setting"
  }, [_c('div', {
    staticClass: "textAboveCard mt-32px"
  }, [_vm._v("CALL FORWARDING")]), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, [_c('CallForwardingWidget', {
    attrs: {
      "accountcode": _vm.voipaccount
    }
  })], 1), _c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Business hours")]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('OutOfHoursWidget', {
    attrs: {
      "accountcode": _vm.voipaccount
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Call Recording")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "recording",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_recording || _vm.api.update_recording.send,
      "checked": _vm.conditions.recording,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateRecording($event);
      }
    }
  })], 1)]), _c('MusicOnHoldWidget', {
    attrs: {
      "accountcode": _vm.voipaccount
    }
  }), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Call Waiting")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "call_waiting",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": !_vm.getUserPermissions.call_waiting || _vm.api.update_call_waiting.send || _vm.api.user_detail.send,
      "checked": !_vm.conditions.call_waiting
    },
    on: {
      "change": function ($event) {
        return _vm.updateCallWaiting($event);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Missed Calls Notification")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "missed_call_notification",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "id": "checkbox-1",
      "disabled": !_vm.getUserPermissions.missed_call_notification || _vm.api.update_missed_call_notification.send,
      "checked": _vm.conditions.missed_call_notification,
      "name": "checkbox-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateMissedCallNotification($event);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Desktop Notifications")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "desktop_notification",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.desktop_notification || _vm.api.update_desktop_notification.send,
      "checked": _vm.conditions.desktop_notification,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateDesktopNotification($event);
      }
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('IncommingcallDisplaySetting', {
          account: _vm.voipaccount
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Incoming Call Presentation")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Forward Source Caller Id")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "forward_source_caller_id",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.forward_source_caller_id || _vm.api.update_forward_source_caller_id.send || _vm.api.user_detail.send,
      "checked": _vm.conditions.forward_source_caller_id,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateForwardSourceCallerID($event);
      }
    }
  })], 1)])], 1), _c('IncommingcallDisplaySettingModal')], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }