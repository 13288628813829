<template>
  <div>
    <modal 
      v-if="true" 
      class="center-zoomIn-transition v2 m-w-500 BuyNumberModal" 
      :class="`${screens=='numbers'?'BuyNumber-SelectNumber':'BuyNumber-SelectCountry'}`" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen"
      @before-close="onBeforeClose"
    >
      <section class="dialer-number-add dialer-edit w-100">
        <form class="dialer-form w-100" @submit.prevent="''">
          <div class="dialer-edit-user w-100">
            <div class="dialer-edit">
              <div class="dialer-edit-header mt-8px pb-0 d-block">
                <div class="d-flex justify-content-between align-items-center">
                  <div class="d-flex align-items-center">
                    <h2 class="dialer-edit-title mb-0">Add number </h2>
                    <vb-icon 
                      icon="checkSlotsModal-icon" class="ml-2 cursor_pointer checkSlotsModal-icon" height="22px" width="22px"
                      @click="$modal.show('CheckedServiceAvailableModal',{service: `aql`, number_category: numberCategory})"
                    />
                  </div>
                  <div class="dialer-edit-actions">
                    <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
                    <a v-else class="newCloseButton" @click="$modal.hide(modalName)">
                      <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                      <span class="newCloseButton-textPart">Close</span>
                    </a>
                  </div>
                </div>
                <div class="vm--modal-text">
                  How would you like to add your new number?
                </div>
              </div>
              <template v-if="screens=='country'">
                <div class="BuyNumber-subHeading">Select country</div>
                <div class="BuyNumber-boxes-container">
                  <template v-if="api.countries.send || api.fetch_service.send">
                    <div class="BuyNumber-box" v-for="n in 4" :key="n.id">
                      <div class="d-flex justify-content-center w-100">
                        <div class="latestShimmerDesign" style="height: 49px;width: 80px;"></div>
                      </div>
                      <div class="latestShimmerDesign mt-4" style="height: 27px;width: 100%;"></div>
                    </div>
                  </template>
                  <!-- <div v-if="api.countries.send" class="d-flex align-items-center justify-content-center w-100">
                    <vb-loading />
                  </div> -->
                  <template v-else>
                    <div class="BuyNumber-box" 
                      v-for="(country, key) in countries" :key="key" :value="country.value" 
                      @click="selected.country=country.value;selected.country == 'US' ? fetchRegions() : ''"
                    >
                      <div class="d-flex justify-content-center w-100">
                        <img :src="country.value | CountryImg"/>
                      </div>
                      <div class="BuyNumber-box-heading">{{ country.label }}</div>
                    </div>

                  </template>
                </div>
                <div v-if="!api.countries.send && !api.fetch_service.send && !conditions.add_user" class="addNumber-boxes-container">
                  <div class="addNumber-box" @click="$modal.show(`${_uid}-AddNumberExistingModal`)">
                    <vb-icon icon="squared-addCallerID-icon" height="38px" width="38px" />
                    <div class="addNumber-box-textPart">Add caller ID</div>
                  </div>
                  <!-- <div class="addNumber-box" @click="$modal.show(`${_uid}-AddPortNumberModal`)">
                    <vb-icon icon="squared-numberPorting-icon" height="38px" width="38px" />
                    <div class="addNumber-box-textPart">Number porting</div>
                  </div> -->
                  <div class="addNumber-box" v-if="$store.getters.getCurrentUser.appMode === 'standalone'" @click="$modal.show(`${_uid}-AddManualNumberModal`)">
                    <vb-icon icon="squared-addManualNumber-icon" height="38px" width="38px" />
                    <div class="addNumber-box-textPart">Add number(manual)</div>
                  </div>
                </div>
              </template>
              <div v-if="screens=='region'" class=" whiteBGinputWithGreyRoundedBorder mar-top mt-4" >
                <div class="dialer-input-field dialer-select-field">
                  <label class="dialer-input-label">Select region</label>
                  <ModelSelect 
                    v-model="selected.region" 
                    :options="regionsOptions" 
                    :isDisabled="api.regions.send" 
                    @input="fetchRegionsGroups()" 
                    placeholder="select region" 
                    class="form-control custom-select" 
                  />
                </div>
                <!-- <select v-model="selected.region" :disabled="api.regions.send" class="form-control custom-select" @change="fetchRegionsGroups();">
                  <option value="Select Number Type" disabled>Select</option>
                  <option v-for="(type, key) in response.regions" :key="key" :value="type.code">{{ type.label }}</option>
                </select> -->
              </div>
              <div v-if="screens=='location'" class=" whiteBGinputWithGreyRoundedBorder mar-top mt-4">
                <div class="dialer-input-field dialer-select-field">
                  <label class="dialer-input-label">Select location</label>
                  <ModelSelect 
                    v-model="selected.group" 
                    :options="regionGroupsOptions" 
                    :isDisabled="api.regions_group.send" 
                    @input="fetchUSNumbers()" 
                    placeholder="Select group" 
                    class="form-control custom-select" 
                  />
                </div>
                <!-- <select v-model="selected.group" :disabled="api.regions_group.send" class="form-control custom-select" @change="fetchUSNumbers()">
                  <option value="Select Number Type" disabled>Select</option>
                  <option v-for="(type, key) in response.regions_group" :key="key" :value="type.group_id">{{ type.label }}</option>
                </select> -->
              </div>
              <template v-if="screens=='number_type'">
                <div class="BuyNumber-subHeading" >Select number type</div>
                <div class="BuyNumber-boxes-container">
                  <div class="BuyNumber-box" 
                    v-for="(type, key) in numberTypes" :key="key" :value="type.value"
                    @click="selected.type=type.value;selected.type=='ukc'?fetchCities():fetchCityNumbers()"
                  >
                    <div class="d-flex justify-content-center w-100">
                      <vb-icon :icon="type.value | UkIconOption" />
                    </div>
                    <div class="BuyNumber-box-heading">{{ type.label }}</div>
                  </div>
                </div>
              </template>
              <div v-if="screens=='city'" class=" whiteBGinputWithGreyRoundedBorder">
                <div class="dialer-input-field dialer-select-field mt-4">
                  <label >Select city to proceed</label>
                  <ModelSelect 
                    v-model="selected.city" 
                    :options="citiesOptions" 
                    :isDisabled="api.cities.send" 
                    @input="fetchCityNumbers()" 
                    placeholder="Select city" 
                    class="form-control custom-select" 
                  />
                  <!-- <select v-model="selected.city" :disabled="api.cities.send" class="form-control custom-select" @change="fetchCityNumbers()" placeholder="ddd">
                    <option value="" disabled>Select City</option>
                    <option v-for="(city, key) in cities" :key="key" :value="city.citycode">{{ city.city }}</option>
                  </select> -->
                </div>
              </div>
              <template v-if="screens=='category'">
                <div class="BuyNumber-subHeading" >Select number type</div>
                <div class="BuyNumber-boxes-container">
                  <div class="BuyNumber-box twoIcons" 
                    v-for="(type, key) in numberCategories" :key="key" :value="type.value"
                    @click="selected.category=type.value;fetchCityNumbers()"
                  >
                    <div class="d-flex justify-content-center w-100">
                      <vb-icon :icon="type.icon" />
                    </div>
                    <div class="BuyNumber-box-heading">{{ type.label }}</div>
                  </div>
                </div>
              </template>
              <div v-if="screens=='numbers'" class="dialer-pick-number mt-0" ref="numberContainer">
                <div class="dialer-available-numbers mt-0">
                  <div class="dialer-number-picker mt-0">
                    <div class="dialer-tabs mt-5">
                      <div class="dialer-tab-content">
                        <template v-if="api.numbers.send">
                          <div class="d-flex flex-wrap">
                            <div v-for="n in 24" :key="n.id"
                              class="latestShimmerDesign mb-3"
                              style="height: 50px;width: 23%;margin-right: 2%"
                            >
                            </div>
                          </div>
                        </template>
                        <!-- <vb-loading v-if="api.numbers.send" /> -->
                        <div v-else class="dialer-premium-number-section">
                          <div class="mx-auto" v-if="filterNumber.length == 0">
                            <vb-no-record class="mt-3" text="There is no record" :design="3" />
                          </div>
                          <template v-else>
                            <div class="dialer-number-picker">
                              <a 
                                v-for="num in filterNumber" 
                                :key="num.msisdn" 
                                @click="selected.number=(num.msisdn || num.number)" 
                                class="dialer-button dialer-button-primary-outline dialer-number-button mb-3" 
                                :class="{'dialer-button-primary-alt':selected.number==(num.msisdn || num.number),}" 
                                style="padding:8px 16px;"
                              >
                                {{ (num.msisdn || num.number) | number_formater(selected.country) }}
                                <!-- {{ num.pid }}
                                {{ num.address_requirement }} -->
                                <img alt class="dialer-number-badge" />
                              </a>
                            </div>
                          </template>
                          <template>
                            <div v-if="numberPages>1" class="latestPagination-container mt-2">
                              <b-icon v-if="pagination.page!=1" icon="chevron-left" @click="pagination.page=pagination.page-1" />
                              <div v-for="page in numberPages-1" :key="page" @click="pagination.page=page" class="buyNumberCircle" :class="[{'active':pagination.page==page},`page-${page}`]" />
                              <b-icon v-if="numberPages!=pagination.page" icon="chevron-right" @click="pagination.page=pagination.page+1" />
                            </div>
                            <div class="mt-4 d-flex align-items-center justify-content-between w-100">
                              <div class="backForward-container w-auto">
                                <div class="backForward-container-inner" @click="back()">
                                  <b-icon icon="chevron-left" />
                                  <div class="backForward-text ml-2">Back</div>
                                </div>
                              </div>
                              <button 
                                type="button"
                                v-if="response.numbers.length > 0" 
                                class="dialer-button dialer-button-primary" 
                                style="width:53%;" 
                                @click="conditions.add_user?selectNumber():purchaseNumber()"
                                :disabled="!selected.number || api.purchase.send"
                              >
                                {{conditions.add_user?'Next':'Purchase number'}}
                              </button>
                              <div style="opacity:0;">wsdsfsdgf</div>
                            </div>
                          </template>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div v-show="screens!='numbers' && screens!='country'" class="backForward-container mt-3">
                <div class="backForward-container-inner" @click="back()">
                  <b-icon icon="chevron-left" />
                  <div class="backForward-text ml-2">Back</div>
                </div>
              </div>
            </div>
          </div> 
        </form>
      </section>
    </modal>
    <AddNumberExistingModal :modalName="`${_uid}-AddNumberExistingModal`" @interface="$emit('existing-number-added');$modal.hide(modalName);" />
    <AddPortNumberModal :modalName="`${_uid}-AddPortNumberModal`" @added="$emit('port-number-added');$modal.hide(modalName);" />
    <AddManualNumberModal :modalName="`${_uid}-AddManualNumberModal`" @load="$emit('manual-number-added');$modal.hide(modalName);" />
    <NumberAddressModal :modalName="`${modalName}-AddAddressModal`" @interface="purchase($event,addressid)" />
  </div>
</template>

<script>
import NumberAddressModal from "../Modals/NumberAddressModal.vue";
import AddNumberExistingModal from "../Modals/AddNumberExistingModal.vue";
import AddPortNumberModal from "../Modals/AddPortNumberModal.vue";
import AddManualNumberModal from "../Modals/AddManualNumberModal.vue";
import { /*events,*/ VOIP_API } from "../../utils";
import { check_service } from '@/mixin';
import { mapGetters } from 'vuex';
import _ from 'lodash'
import { ModelSelect } from 'vue-search-select'

let number_types = [
  {
    label: "UK national number",
    value: "ukn",
    national: true,
  },
  {
    label: "UK town / city number",
    value: "ukc",
    national: true,
  },
  {
    label: "UK mobile number",
    value: "ukm",
    national: false,
  },
]
let number_categories = [
  {
    label: "Local numbers",
    value: "1",
    icon: "UkLocalNumber-icon"
  },
  {
    label: "Mobile numbers",
    value: "3",
    icon: "UkMobileNumber-icon"
  },
]
// let screens = {
//   country: 'country',
//   region: 'region',
//   location: 'location',
//   number_type: 'number_type',
//   category: 'category',
//   city: 'city',
//   numbers: 'numbers',
// }
// let screens_switcher = {
//   country: {
//     value: screens.country,
//   },
//   region: {
//     value: screens.region,
//     prev_value: screens.country,
//   },
//   location: {
//     value: screens.location,
//     prev_value: screens.region,
//   },
//   number_type: {
//     value: screens.number_type,
//     prev_value: screens.country,
//   },
//   city: {
//     value: screens.city,
//     prev_value: screens.number_type,
//   },
//   category: {
//     value: screens.category,
//     prev_value: screens.country,
//   },
//   numbers: {
//     value: screens.numbers,
//     prev_value: screens.category,
//   },
//   us_numbers: {
//     value: screens.numbers,
//     prev_value: screens.region,
//   },
//   gb_city_numbers: {
//     value: screens.numbers,
//     prev_value: screens.city,
//   },
//   gb_numbers: {
//     value: screens.numbers,
//     prev_value: screens.number_type,
//   },
// }
export default {
  name: "AddNumbersModal",
  mixins: [
    check_service
  ],
  components: {
    NumberAddressModal,
    ModelSelect,
    AddNumberExistingModal,
    AddPortNumberModal,
    AddManualNumberModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'AddNumbersModal',
    },
  },
  data() {
    return {
      selected: {
        country: '',
        region: '',
        group: '',
        city: '',
        number: '',
        type: '',
        category: '',
      },
      api: {
        countries: this.$helperFunction.apiInstance({  }),
        fetch_service: this.$helperFunction.apiInstance({  }),
        regions: this.$helperFunction.apiInstance({  }),
        regions_group: this.$helperFunction.apiInstance({  }),
        cities: this.$helperFunction.apiInstance({  }),
        numbers: this.$helperFunction.apiInstance({  }),
        purchase: this.$helperFunction.apiInstance({  }),
      },
      response: {
        regions: [],
        regions_group: [],
        countries: [],
        cities: {},
        numbers: [],
        service: {},
      },
      pagination: {
        page: 1,
      },
      conditions: {
        add_user: false,
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    countries(){ return this.conditions.add_user ? this.response.countries.filter(country=>['US','GB'].includes(country.value)) : this.response.countries },
    numberTypes(){ return this.conditions.add_user/* || this.isFreeNumber*/ ? number_types.filter(number_type=>number_type.national) : number_types },
    numberCategories(){ return number_categories },
    screens(){
      if(!this.selected.country) return 'country'
      else if(this.selected.country === 'US' && !this.selected.region) return 'region'
      else if(this.selected.country === 'US' && this.selected.region && !this.selected.group) return 'location'
      else if(this.selected.country === 'GB' && !this.selected.type) return 'number_type'
      else if(this.selected.country === 'GB' && this.selected.type === 'ukc' && !this.selected.city) return 'city'
      else if(!['GB','US'].includes(this.selected.country) && !this.selected.category) return 'category'
      return 'numbers'
    },
    addressRequired(){ return this.selectedNumber?.address_requirement ?? 'none' },
    selectedNumber(){ return this.response.numbers.find(i=>this.selected.number==(i.msisdn || i.number)) },
    pid(){ return this.selected.country=='US' ? 'idt' : this.selectedNumber?.pid ?? "vonage" },
    sku(){ return this.selectedNumber?.sku ?? ''},
    selectedCountry(){ return this.response.countries.find(i=>i.value==this.selected.country) },
    numberCategory(){
      if(this.selected.country === 'US') return 'usa'
      else if(this.selected.country === 'CA') return 'ca'
      else return this.selected.type
    },
    isFreeNumber(){ return this.response.service?.is_user_free_number==1 },
    citiesOptions(){ return this.response.cities?.map?.(i=>({ text:i.city, value:i.citycode })) ?? [] },
    regionsOptions(){ return this.response.regions?.map?.(i=>({ text:i.label, value:i.code })) ?? [] },
    regionGroupsOptions(){ return this.response.regions_group?.map?.(i=>({ text:i.label, value:i.group_id })) ?? [] },
    cunkNumbers(){ return _.chunk(this.response.numbers, 20) },
    numberPages(){ return this.cunkNumbers.length },
    filterNumber(){ return this.cunkNumbers[this.pagination.page] ?? [] },
  },
  filters: {
    CountryImg(value){
      if(value=='AU') return require("@/assets/images/login/Australia.png");
      else if(value=='NZ') return require("@/assets/images/login/NewZealand.png");
      else if(value=='US') return require("@/assets/images/login/UnitedStates.png");
      else if(value=='CA') return 'https://flagcdn.com/w320/ca.png';
      else return require("@/assets/images/login/UnitedKingdom.png");
    },
    UkIconOption(value){
      if(value=='ukn') return 'UkNationalNumber-icon';
      else if(value=='ukc') return 'UkTownCityNumber-icon';
      else return 'UkMobileNumber-icon';
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.conditions.add_user=!!event?.params?.add_user
      if(!this.conditions.add_user) {
        this.fetchService()
      }
      this.fetchCountries();
    },
    onBeforeClose(){
      this.selected.country = ''
      this.selected.region = ''
      this.selected.group = ''
      this.selected.city = ''
      this.selected.number = ''
      this.selected.type = ''
      this.selected.category = ''
      this.api.countries.reset();
      this.api.regions.reset();
      this.api.regions_group.reset();
      this.api.cities.reset();
      this.api.numbers.reset();
      this.api.purchase.reset();
      this.response.regions = []
      this.response.regions_group = []
      this.response.countries = []
      this.response.cities = {}
      this.response.numbers = []
      this.conditions.add_user=false
    },
    async fetchService(){
      try {
        this.api.fetch_service.send = true;
        const { data } = await VOIP_API.endpoints.billing.checkservice({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          service: 'aql',
          // number_category: number_category,
          email: this.getCurrentUser.email,
        })
        this.response.service=data
      } finally {
        this.api.fetch_service.send = false;
      }
    },
    fetchCountries(){
      let vm = this
      vm.api.countries.send = true;
      VOIP_API.endpoints.telephone_number.countries()
      .then(({ data: countries } )=>{
        vm.response.countries=countries
      })
      .finally(()=>{
        vm.api.countries.send = false;
      })
    },
    fetchRegions(){
      let vm = this
      vm.api.regions.send = true;
      VOIP_API.endpoints.telephone_number.fetchregions()
      .then(({ data: { data: { regions } } })=>{
        vm.response.regions=regions
      })
      .finally(()=>{
        vm.api.regions.send = false;
      })
    },
    fetchRegionsGroups(){
      let vm = this
      vm.api.regions_group.send = true;
      VOIP_API.endpoints.telephone_number.fetchregiongroups({
        region: vm.selected.region,
      })
      .then(({ data: { data: { regions } } })=>{
        vm.response.regions_group=regions
      })
      .finally(()=>{
        vm.api.regions_group.send = false;
      })
    },
    fetchUSNumbers(){
      let vm = this
      vm.api.numbers.send = true;
      VOIP_API.endpoints.telephone_number.fetchnumbers({
        type: 'usa',
        region: vm.selected.region,
        id: vm.selected.group,
      })
      .then(({ data: { data: { numbers } } })=>{
        vm.response.numbers = numbers;
      })
      .finally(()=>{
        vm.api.numbers.send = false;
      })
    },
    fetchCities() {
      let vm = this
      vm.api.cities.send = true
      VOIP_API.endpoints.telephone_number.cities(this.selectedCountry.value)
      .then(({ data: res }) => {
        vm.response.cities = res;
      })
      .finally(()=>{
        vm.api.cities.send = false
      });
    },
    fetchCityNumbers() {
      let vm = this;
      vm.api.numbers.send = true;
      VOIP_API.endpoints.telephone_number.fetchCitiesNumber({
        provider: "aql",
        number_type: vm.selected.type,
        country: vm.selected.country,
        category: vm.selected.category,
        city: vm.selected.city,
        uid: vm.getCurrentUser.uid
      })
      .then((res) => {
        vm.response.numbers = res.data;
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.numbers.send = false;
      });
    },
    selectNumber(){
      this.$emit('select-number',{
        country: this.selected.country,
        number_type: this.selected.type,
        city: this.selected.city,
        number: this.selected.number,
        product: "aql",
        category: this.selected.category,
        service: "number",
        pid: this.pid,
        group_id: this.selected.group ?? '',
        number_category: this.numberCategory,
        sku: this.sku,
      })
      this.$modal.hide(this.modalName);
    },
    purchaseNumber(){
      // console.log('run')
      this.purchase();
      // if(this.selected.country=='US'){
      //   this.purchase();
      // } 
      // else if(this.addressRequired=='none') {
      //   this.purchase();
      // }
      // else {
      //   this.$modal.show(`${this.modalName}-AddAddressModal`, {
      //     data: {
      //       country: this.selected.country,
      //       number_type: this.selected.type,
      //       city: this.selected.city,
      //       number: this.selected.number,
      //       product: "aql",
      //       uid: this.getCurrentUser.uid,
      //       category: this.selected.category,
      //       service: "number",
      //       pid: this.pid,
      //       useraccountcode: this.getCurrentUser.account,
      //     },
      //     countries: this.response.countries,
      //     address_required: this.addressRequired,
      //   });
      // }
    },
    purchase(event){
      const addressid = event?.location?.real_id
      let vm = this;
      // if(vm.selected.country!='US' && !addressid) return;
      vm.api.purchase.send=true
      vm.checkService({
        service: 'aql',
        number_category: vm.numberCategory,
        number_type: vm.selected.type,
        async then(){
          await VOIP_API.endpoints.telephone_number.purchasenumber({
            country: vm.selected.country,
            number_type: vm.selected.type,
            city: vm.selected.city,
            number: vm.selected.number,
            product: "aql",
            uid: vm.getCurrentUser.uid,
            category: vm.selected.category,
            service: "number",
            pid: vm.pid,
            useraccountcode: vm.getCurrentUser.account,
            default_account: vm.getCurrentUser?.default_accountcode,
            group_id: vm.selected.group ?? '',
            number_category: vm.numberCategory,
            sku: vm.sku,
            addressid: addressid ?? '',
          })
          // vm.$store.commit('setAddServiceQueue',{
          //   type: 'number',
          //   name: `${vm.selected.number}`,
          // })
          vm.$modal.hide(vm.modalName);
          vm.appNotify({
            message: "Request for adding number is pending",
            type: "success",
          })
          if(vm.selected.country=='US'){
            vm.$store.state.us_number_progress=vm.selected.number
          }
          vm.$emit("interface");
          // vm.$root.$emit(events.check_pending_services)
        },
        catch(ex){
          // console.log(ex)
          vm.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        },
        finally(){
          vm.api.purchase.send=false
        },
      })
    },
    back(){
      if(this.screens=='numbers'){
        if(this.api.numbers.send || this.api.purchase.send) return;
        this.selected.number=''
        if(!['GB','US'].includes(this.selected.country)){
          this.selected.category=''
        } else if(this.selected.country === 'GB'){
          if(this.selected.type === 'ukc') {
            this.selected.city=''
          } else {
            this.selected.type=''
          }
        } else if(this.selected.country === 'US'){
          this.selected.group=''
        }
      } else if(this.screens=='category'){
        this.selected.category=''
        this.selected.country=''
      } else if(this.screens=='city'){
        if(this.api.cities.send) return;
        this.selected.city=''
        this.selected.type=''
      } else if(this.screens=='number_type'){
        this.selected.type=''
        this.selected.country=''
      } else if(this.screens=='location'){
        if(this.api.regions_group.send) return;
        this.selected.group=''
        this.selected.region=''
      } else if(this.screens=='region'){
        if(this.api.regions.send) return;
        this.selected.region=''
        this.selected.country=''
      }
    },
  },
};
</script>
