var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500",
    attrs: {
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('form', {
    staticClass: "w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.changePassword();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-start justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Change password")]), _c('p', [_vm._v("Enter a strong and memorable password")])]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withRightIconOnly w-100"
  }, [_c('label', [_vm._v("Set new password")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [`${_vm.forms.change_password.show_new_password ? 'text' : 'password'}` === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.new_password,
      expression: "forms.change_password.new_password"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.change_password.send,
      "maxlength": _vm.$v.forms.change_password.new_password.$params.maxLength.max,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.forms.change_password.new_password) ? _vm._i(_vm.forms.change_password.new_password, null) > -1 : _vm.forms.change_password.new_password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.forms.change_password.new_password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.forms.change_password, "new_password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.forms.change_password, "new_password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.forms.change_password, "new_password", $$c);
        }
      }
    }
  }) : `${_vm.forms.change_password.show_new_password ? 'text' : 'password'}` === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.new_password,
      expression: "forms.change_password.new_password"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.change_password.send,
      "maxlength": _vm.$v.forms.change_password.new_password.$params.maxLength.max,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.forms.change_password.new_password, null)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.forms.change_password, "new_password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.new_password,
      expression: "forms.change_password.new_password"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.change_password.send,
      "maxlength": _vm.$v.forms.change_password.new_password.$params.maxLength.max,
      "type": `${_vm.forms.change_password.show_new_password ? 'text' : 'password'}`
    },
    domProps: {
      "value": _vm.forms.change_password.new_password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.change_password, "new_password", $event.target.value);
      }
    }
  }), _c('b-icon', {
    attrs: {
      "disabled": _vm.api.change_password.send,
      "icon": `${_vm.forms.change_password.show_new_password ? 'eye-slash-fill' : 'eye-fill'}`
    },
    on: {
      "click": function ($event) {
        _vm.forms.change_password.show_new_password = !_vm.forms.change_password.show_new_password;
      }
    }
  })], 1), _vm.forms.change_password.submitted && _vm.$v.forms.change_password.new_password.$invalid || _vm.api.change_password.validation_errors.password ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* ")]), !_vm.$v.forms.change_password.new_password.required ? _c('span', [_vm._v("New password is required")]) : !_vm.$v.forms.change_password.new_password.capitalalphabet ? _c('span', [_vm._v("New password required at least one capital character")]) : !_vm.$v.forms.change_password.new_password.smallalphabet ? _c('span', [_vm._v("New password required at least one small character")]) : !_vm.$v.forms.change_password.new_password.numbers ? _c('span', [_vm._v("New password required at least one integer character")]) : !_vm.$v.forms.change_password.new_password.specialcharacter ? _c('span', [_vm._v("New password required at least one special character")]) : !_vm.$v.forms.change_password.new_password.minLength ? _c('span', [_vm._v("New password is minimum " + _vm._s(_vm.$v.forms.change_password.new_password.$params.minLength.min) + " is required")]) : !_vm.$v.forms.change_password.new_password.maxLength ? _c('span', [_vm._v("New password is maximum " + _vm._s(_vm.$v.forms.change_password.new_password.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.change_password.validation_errors.password, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withRightIconOnly w-100 mt-20px"
  }, [_c('label', [_vm._v("Confirm password")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [`${_vm.forms.change_password.show_confirm_password ? 'text' : 'password'}` === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.confirm_password,
      expression: "forms.change_password.confirm_password"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.change_password.send,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.forms.change_password.confirm_password) ? _vm._i(_vm.forms.change_password.confirm_password, null) > -1 : _vm.forms.change_password.confirm_password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.forms.change_password.confirm_password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.forms.change_password, "confirm_password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.forms.change_password, "confirm_password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.forms.change_password, "confirm_password", $$c);
        }
      }
    }
  }) : `${_vm.forms.change_password.show_confirm_password ? 'text' : 'password'}` === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.confirm_password,
      expression: "forms.change_password.confirm_password"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.change_password.send,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.forms.change_password.confirm_password, null)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.forms.change_password, "confirm_password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.confirm_password,
      expression: "forms.change_password.confirm_password"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.change_password.send,
      "type": `${_vm.forms.change_password.show_confirm_password ? 'text' : 'password'}`
    },
    domProps: {
      "value": _vm.forms.change_password.confirm_password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.change_password, "confirm_password", $event.target.value);
      }
    }
  }), _c('b-icon', {
    attrs: {
      "icon": `${_vm.forms.change_password.show_confirm_password ? 'eye-slash-fill' : 'eye-fill'}`,
      "disabled": _vm.api.change_password.send
    },
    on: {
      "click": function ($event) {
        _vm.forms.change_password.show_confirm_password = !_vm.forms.change_password.show_confirm_password;
      }
    }
  })], 1), _vm.forms.change_password.submitted && _vm.$v.forms.change_password.confirm_password.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* ")]), !_vm.$v.forms.change_password.confirm_password.required ? _c('span', [_vm._v("Confirm password is required")]) : !_vm.$v.forms.change_password.confirm_password.capitalalphabet ? _c('span', [_vm._v("Confirm password should be same as new password")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "PasswordLength-LineContainer mt-20px"
  }, [_c('label', {
    staticClass: "mb-0"
  }, [_vm._v("Password strength")]), _c('div', {
    staticClass: "line-wrap"
  }, [_c('div', {
    class: `line-itself ${_vm.passwordStrength / 5 * 100 > 60 ? 'strong' : ''} `,
    style: `width: calc(${_vm.passwordStrength / 5 * 100}% + ${_vm.passwordStrength > 0 ? '2' : '0'}px);`
  })]), _c('small', {
    staticClass: "line-text"
  }, [_vm._v(_vm._s(_vm._f("filterPasswordStrengthText")(_vm.passwordStrength)))])])]), _c('div', {
    staticClass: "w-100 mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "submit"
    }
  }, [_vm.api.change_password.send ? _c('vb-spinner') : _c('span', [_vm._v("Update")])], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }