<template> 
  <div v-if="status" :id="id" :style="`background-color:${status.colour}`" class="dialer-userStatus">
    <div class="dialer-userStatus-box" v-if="!status.accountcode">
      
      <svg v-if="status.status === 'Available'" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g id="userOnline-icon" transform="translate(0.17 -5)">
          <circle id="Ellipse_948" data-name="Ellipse 948" cx="14" cy="14" r="14" transform="translate(-0.17 5)" :fill="status.colour" />
          <g id="check_1_" data-name="check (1)" transform="translate(6.971 13.942)">
            <path
              id="Path_12151"
              data-name="Path 12151"
              d="M7.61,14.951a.72.72,0,0,1-1.019,0L2.524,10.883a1.08,1.08,0,0,1,0-1.528l.509-.509a1.08,1.08,0,0,1,1.528,0L7.1,11.385l6.862-6.862a1.08,1.08,0,0,1,1.528,0L16,5.032A1.08,1.08,0,0,1,16,6.56Z"
              transform="translate(-2.208 -4.207)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
      
      <svg v-if="status.status === 'Do not disturb'" xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27">
        <g id="userDoNotDisturb-icon" transform="translate(0.17 -0.442)">
          <ellipse id="Ellipse_948" data-name="Ellipse 948" cx="14" cy="13.5" rx="14" ry="13.5" transform="translate(-0.17 0.442)" :fill="status.colour" /> 
          <rect id="Rectangle_2999" data-name="Rectangle 2999" width="14" height="3" rx="1.5" transform="translate(6.83 12.442)" fill="#fff" />
        </g>
      </svg>
      
      <svg v-if="status.status === 'Offline'" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g id="userOffline-icon" transform="translate(0.17 -0.277)">
          <g id="Ellipse_948" data-name="Ellipse 948" transform="translate(-0.17 0.277)" fill="#fff" :stroke="status.colour" stroke-width="1.5">
            <circle cx="14" cy="14" r="14" stroke="none" />
            <circle cx="14" cy="14" r="13.25" fill="none" />
          </g>
        </g>
      </svg>
      
      <svg v-if="status.status === 'Busy'" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g id="userAway-icon" transform="translate(0.17 0.393)">
          <circle id="Ellipse_948" data-name="Ellipse 948" cx="14" cy="14" r="14" transform="translate(-0.17 -0.393)" :fill="status.colour" />
          <g id="Group_12515" data-name="Group 12515" transform="translate(11.328 7.026)">
            <rect id="Rectangle_3000" data-name="Rectangle 3000" width="9.294" height="2.614" rx="1.307" transform="translate(1.346 6.567) rotate(31)" fill="#fff" />
            <rect id="Rectangle_3001" data-name="Rectangle 3001" width="9.294" height="2.614" rx="1.307" transform="translate(0.29 9.294) rotate(-90)" fill="#fff" />
          </g>
        </g>
      </svg>
      
      <!-- <svg v-if="status.status === 'Idle'" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g id="userAway-icon" transform="translate(0.17 0.393)">
          <circle id="Ellipse_948" data-name="Ellipse 948" cx="14" cy="14" r="14" transform="translate(-0.17 -0.393)" :fill="status.colour" />
          <g id="Group_12515" data-name="Group 12515" transform="translate(11.328 7.026)">
            <rect id="Rectangle_3000" data-name="Rectangle 3000" width="9.294" height="2.614" rx="1.307" transform="translate(1.346 6.567) rotate(31)" fill="#fff" />
            <rect id="Rectangle_3001" data-name="Rectangle 3001" width="9.294" height="2.614" rx="1.307" transform="translate(0.29 9.294) rotate(-90)" fill="#fff" />
          </g>
        </g>
      </svg> -->
      <svg v-if="status.status === 'Idle'" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g id="userAway-icon" transform="translate(0.17 0.393)">
          <circle id="Ellipse_948" data-name="Ellipse 948" cx="14" cy="14" r="14" transform="translate(-0.17 -0.393)" :fill="status.fillColor" :stroke="status.colour" stroke-width="4px" />
          <g id="Group_12515" data-name="Group 12515" transform="translate(11.328 7.026)">
            <rect id="Rectangle_3000" data-name="Rectangle 3000" width="9.294" height="2.614" rx="1.307" transform="translate(1.346 6.567) rotate(31)" fill="black" />
            <rect id="Rectangle_3001" data-name="Rectangle 3001" width="9.294" height="2.614" rx="1.307" transform="translate(0.29 9.294) rotate(-90)" fill="black" />
          </g>
        </g>
      </svg>
      
      <svg height="20" width="20" xmlns="http://www.w3.org/2000/svg" viewBox="0 0 20 20" class="dialerUserStatusRing">
        <!-- <circle cx="10" cy="10" r="8" stroke="white" style="stroke:white !important" stroke-width="3" fill="transparent" /> -->
        <circle cx="10" cy="10" r="8" stroke="white" style="stroke:transparent !important" stroke-width="2" fill="transparent" />
      </svg>
    </div>
    
    <b-tooltip v-if="!$store.getters.getIsMobile"
      custom-class="tooltip-arrow-align"
      :target="id"
      :title="status.status || ''"
      placement="top"
    />
    
  </div>
  <div v-else-if="callQueueStatus" :id="id" :style="`background-color:${callQueueStatusInfo.logged_in?'green':'black'};`" class="dialer-userStatus">
    <div class="dialer-userStatus-box" v-if="callQueueStatusInfo.adminforce">
      <svg xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g id="userOffline-icon" transform="translate(0.17 -0.277)">
          <g id="Ellipse_948" data-name="Ellipse 948" transform="translate(-0.17 0.277)" fill="#fff" :stroke="'green'" stroke-width="1.5">
            <circle cx="14" cy="14" r="14" stroke="none" />
            <circle cx="14" cy="14" r="13.25" fill="none" />
          </g>
        </g>
      </svg>
    </div>
    <div class="dialer-userStatus-box" v-else-if="callQueueStatusInfo.logged_in">
      <svg v-if="callQueueStatusInfo.is_paused" xmlns="http://www.w3.org/2000/svg" width="28" height="28" viewBox="0 0 28 28">
        <g id="userOnline-icon" transform="translate(0.17 -5)">
          <circle id="Ellipse_948" data-name="Ellipse 948" cx="14" cy="14" r="14" transform="translate(-0.17 5)" :fill="'green'" />
          <g id="check_1_" data-name="check (1)" transform="translate(6.971 13.942)">
            <path
              id="Path_12151"
              data-name="Path 12151"
              d="M7.61,14.951a.72.72,0,0,1-1.019,0L2.524,10.883a1.08,1.08,0,0,1,0-1.528l.509-.509a1.08,1.08,0,0,1,1.528,0L7.1,11.385l6.862-6.862a1.08,1.08,0,0,1,1.528,0L16,5.032A1.08,1.08,0,0,1,16,6.56Z"
              transform="translate(-2.208 -4.207)"
              fill="#fff"
            />
          </g>
        </g>
      </svg>
      <svg v-else xmlns="http://www.w3.org/2000/svg" width="28" height="27" viewBox="0 0 28 27">
        <g id="userDoNotDisturb-icon" transform="translate(0.17 -0.442)">
          <ellipse id="Ellipse_948" data-name="Ellipse 948" cx="14" cy="13.5" rx="14" ry="13.5" transform="translate(-0.17 0.442)" :fill="'green'" />
          <rect id="Rectangle_2999" data-name="Rectangle 2999" width="14" height="3" rx="1.5" transform="translate(6.83 12.442)" fill="#fff" />
        </g>
      </svg>
    </div>
    <b-tooltip v-if="!$store.getters.getIsMobile"
      custom-class="tooltip-arrow-align"
      :target="id"
      :title="`Logged ${callQueueStatusInfo.logged_in ? `in  and ${callQueueStatusInfo.is_paused ? 'paused' : 'play'}` : 'out'} `"
      placement="top"
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { $fn } from '../../utils';
export default {
  name: "vb-status",
  props: {
    statusId: {
      type: [String,Number,Object],
    },
    callQueueStatus: {
      type: Object,
    },
  },
  data(){
    return {
      id: `${Math.ceil(Math.random()*10)}_${new Date().getTime()}_${$fn.makeid(36)}`
    }
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
    status() {
      if(typeof this.statusId == 'undefined') return;
      const key = this.statusId ? typeof this.statusId == "string" ? this.statusId.toLowerCase().trim() : typeof this.statusId == "number" ? this.statusId : this.statusId.id || 'offline' : 'offline';
      if(key=='idle') {
        return {
          status: 'Idle',
          colour: '#5bb334',
          fillColor: 'white'
        }
      }
      const status = this.$store.state.common.statuses.find((status) => status.id == key || status.status.toLowerCase().trim() == key);
      return status;
    },
    callQueueStatusInfo(){
      const current_member = this.callQueueStatus?.[this.getCurrentUser?.account]
      return {
        logged_in: current_member?.logged_in,
        is_paused: current_member?.is_paused=='1',
        adminforce: current_member?.adminforce=='yes',
      }
    },
    callQueueStatusLoggedIn(){
      return !!this.callQueueStatus?.[this.getCurrentUser?.account]?.logged_in
    },
    callQueueStatusIsPaused(){
      return this.callQueueStatus?.[this.getCurrentUser?.account]?.is_paused=='1'
    },
  },
};
</script>

<style lang="scss">
// User status
.dialer-userStatus {
  position: absolute;
  width: 14px;
  height: 14px;
  // font-size: 18px;
  border: 0px solid $white;
  // border: 2px solid transparent;
  // background-color: transparent !important;
  // @include border-radius(5px);
  @include border-radius(50%);
  right: -3px;
  bottom: -3px;
  display: flex;
  align-items: center;
  justify-content: center;
  color: $white;
  .dialer-userStatus-box {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
  }
  svg {
    width: 12px;
  }
  &.online-status {
    background-color: $teal;
  }

  &.offline-status {
    background-color: $secondary;
  }

  &.away-status {
    background-color: $warning;
  }

  &.busy-status {
    background-color: $danger;
  }
}
// end User status


.tooltip{
  &.tooltip-arrow-align{
    &.b-tooltip{
      &.bs-tooltip-right{
        left: 5px !important;
         .arrow{
            margin: 0.2rem 1px;
            top: 5px !important;
          }
      }
    }
  } 
}
</style>