var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup EditMenuModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.beforeOpen,
      "before-close": _vm.onBeforeClose,
      "closed": function ($event) {
        return _vm.$emit('interface');
      }
    }
  }, [_vm.data.menu ? _c('EditMenu', {
    attrs: {
      "propMenu": _vm.data.menu
    },
    on: {
      "interface": function ($event) {
        return _vm.$emit('interface');
      },
      "close-modal": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }