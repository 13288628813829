<template>
  <div :class="`dialer-resizerArea content-area content-area-new ${getCurrentUser.suspended ? 'accountSuspended' : ''}`" >
    <div  :class="['content-area-body d-flex w-100 h-100 position-relative', GET_LOCAL_SETTING_DASHBOARD.design.flip ? 'flex-row-reverse' : '']">
      <DialPanel 
        v-if="logs"
        class="DialPanelMobileLike" 
        ref="ActivityLog" 
        @click-play="playAudio($event)"
        @make-call="makeCall()"
        :style="`width:${GET_LOCAL_SETTING_DASHBOARD.design.width}%`" 
        @call-info="call_info=$event" 
      />
      <template v-if="sound_file" >
        <div :style="`width: ${GET_LOCAL_SETTING_DASHBOARD.design.width}%`" class="AudioPlayerShownInsideCallLogsList">
          <div class="AudioPlayerShownInsideCallLogsList-inner">
            <vb-audio-player :src="sound_file" :autoPlay="true" :design="'NoTranscript'" />
            <b-button @click="sound_file=null" class="p-0 bg-transparent border-0">
              <!-- <b-icon icon="x" variant="danger" /> -->
              <vb-icon icon="squared-close-icon" class="squared-close-icon" height="38px" width="38px" />
            </b-button>
          </div>
        </div>
      </template>
      <div class="panel-new" :style="`width:calc(100% - ${GET_LOCAL_SETTING_DASHBOARD.design.width}%)`">
        <transition name="slide-fade">
          <CallInfo 
            v-if="call_info" 
            @close="call_info = null" 
            :callInfo="call_info" 
            :class="[GET_LOCAL_SETTING_DASHBOARD.design.width == 75 ? 'min-width-357' : '']"
          />
        </transition>
        <Dialpad v-if="dialer" ref="audiocall" />
      </div>
    </div>
    <div v-if="getCurrentUser.suspended" class="accountSuspended_container">
      <div class="accountSuspended_overlay"></div>
      <div class="accountSuspended_inner">
        <div class="accountSuspended_heading"> Suspended Account </div>
        <div class="accountSuspended_text"> Outbound calls are currently blocked </div>
        <div class="accountSuspended_button">
          <button class="dialer-button dialer-button-primary">
            Please Contact Support
          </button>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import DialPanel from "../components/Home/CallActivity/List.vue";
import Dialpad from "../components/Home/DialpadMobileLikeCopy.vue";
import CallInfo from "../components/Home/CallActivity/Info.vue";
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_DASHBOARD } from '@/store/helper/getters';

export default {
  name: "Home",
  components: {
    Dialpad,
    DialPanel,
    CallInfo,
  },
  props: {
    dialer: {
      type: Boolean,
      default: true,
    },
    logs: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      flip: false,
      width: 25,
      call_info: null,
      sound_file: null
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      GET_LOCAL_SETTING_DASHBOARD
    ]),
  },
  methods: {
    async playAudio(event){
      const sound_file = event?.soundFile
      try {
        await fetch(sound_file)
        // console.log('run')
        this.sound_file=sound_file
      } catch (ex) {
        this.appNotify({
          message: 'Please wait for 30 seconds',
          type: 'info'
        })
      }
    },
    makeCall() {
      this.$refs?.['audiocall']?.$refs?.['number-input']?.focus?.()
    }
  },
};
</script>


<style lang="scss">
.dialer-resizerArea {
  &.content-area {
    height: 100%;
    .content-area-body {
      height: 100%;
      .dialer-resizerPane {
        height: 100%;
        &.multipane {
          &.dialer-resizerPane {
            &.layout-v,
            &.layout-h {
              .multipane-resizer {
                height: 100%;
                margin: 0 0 0 10px;
                left: 0;
                width: 4px;
                background: $primaryLight;
                position: relative;
                &:before {
                  display: block;
                  content: "";
                  width: 3px;
                  height: calc(100vh - 32px);
                  position: absolute;
                  top: 16px;
                  left: 50%;
                  margin-top: -20px;
                  margin-left: -1.5px;
                  border-left: 1px solid #ccc;
                  border-right: 1px solid #ccc;
                }
                &:hover {
                  &:before {
                    border-color: #999;
                  }
                }
              }
            }
            .panel {
              max-width: 100%;
              min-width: 316px;
              width: 100%;
            }
            .panel-wide {
              max-width: unset;
              width: unset;
              min-width: 400px;
              flex-grow: 1;
            }
          }
        }
      }
      &.flippedScreens {
        .dialer-resizerPane {
          &.multipane {
            &.dialer-resizerPane {
              &.layout-v,
              &.layout-h {
                .multipane-resizer {
                  margin: 0 10px 0 10px;
                }
              }
              .panel-wide {
                padding-left: 0px;
                max-width: 100%;
                width: 100%;
                min-width: 400px;
                flex-grow: unset;
              }
              .panel {
                max-width: unset;
                min-width: 316px;
                width: unset;
                flex-grow: 1;
              }
            }
          }
        }
      }
    }
  }
}
</style>
