<template>
  <div class="remote-tracks-conatiner">
    
    <RemoteTrack
      v-for="(track, index) in tracks"
      :key="`${participant._id}_${track.type}_${track.videoType}_${track.isMuted()}_${track.getId()}_${index}_`"
      :remoteTrack="track"
      :participant="participant"
      :index="index"
      :JitsiConference="JitsiConference"
      :speaker_device_id="speaker_device_id"
      :videoCall="videoCall"
    />
  </div>
</template>

<script>
import RemoteTrack from "./RemoteTrack";
export default {
  name: "RemoteTracks",
  components: {
    RemoteTrack,
  },
  props: {
    tracks: {
      type: Array,
    },
    participant: {
      type: Object,
    },
    JitsiConference: {
      type: Object,
    },
    speaker_device_id: {
      type: String,
      default: ''
    },
    
    videoCall:{
      type: Object
    },
  },
};
</script>

<style lang="scss" scoped>
.remote-tracks-conatiner {
  height: inherit;
  width: 100%;
}

</style>