import { TokenService } from "@/services/storage.service";
import { VOIP_API } from "@/utils/index";
import { PAMARLY_DATA_RESET, PAMARLY_LOGIN } from "../helper/actions";
import { GET_PAMARLY_ORGANIZATION_ID, GET_PAMARLY_TOKEN } from "../helper/getters";
import { SET_PAMARLY_REFRESH_TOKEN, SET_PAMARLY_TOKEN, SET_PAMARLY_USER } from "../helper/mutations";

export default {
  state: {
    user: {},
    token: '',
    refresh_token: '',
  },
  getters: {
    [GET_PAMARLY_ORGANIZATION_ID](state,getters,rootState){ return rootState?.common?.user?.other_system?.pamarly?.organization_id },
    [GET_PAMARLY_TOKEN](state){ return state.token },
  },
  actions: {
    async [PAMARLY_LOGIN](context){
      try {
        const user = TokenService.USER.get()
        const { data: { user: pamarly_user } } = await VOIP_API.endpoints.pamarly.auth.login({
          email: user?.other_system?.pamarly?.username,
          password: user?.other_system?.pamarly?.password,
        })
        context.commit(SET_PAMARLY_USER,pamarly_user)
        context.commit(SET_PAMARLY_TOKEN,pamarly_user.token)
        context.commit(SET_PAMARLY_REFRESH_TOKEN,pamarly_user.refresh_token)
      } catch {
        // TODO
      }
    },
    [PAMARLY_DATA_RESET](context){
      context.commit(SET_PAMARLY_USER)
      context.commit(SET_PAMARLY_TOKEN)
      context.commit(SET_PAMARLY_REFRESH_TOKEN)
    },
  },
  mutations: {
    [SET_PAMARLY_USER](state,data){ 
      state.user=data ?? {}
    },
    [SET_PAMARLY_TOKEN](state,data){ 
      state.token=data ?? ''
    },
    [SET_PAMARLY_REFRESH_TOKEN](state,data){ 
      state.refresh_token=data ?? '' 
    },
  },
};