var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 editTeamModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit pb-4"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Assign User")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.assign.send
    },
    on: {
      "click": function ($event) {
        return _vm.assignCall();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _vm._l(_vm.getVoipUsers, function (user) {
    return _c('div', {
      key: user.voipaccount
    }, [_c('b-form-checkbox', {
      attrs: {
        "disabled": _vm.api.assign.send,
        "checked": _vm.selected.accountcode == user.voipaccount
      },
      on: {
        "change": function ($event) {
          _vm.selected.accountcode = $event ? user.voipaccount : '';
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": user.voipaccount
      }
    })], 1)], 1);
  })], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }