var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row UserCallRecordingWidget"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call Recording")]), _vm.userCallRecording ? _c('div', {
    staticClass: "latestGreyBox-descriptionText d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "noRect svgTextFill-5757575",
    attrs: {
      "icon": _vm._f("filterIcon")(_vm.userCallRecording),
      "height": "30",
      "width": "20"
    }
  }), _c('span', {
    staticClass: "ml-16px"
  }, [_vm._v(_vm._s(_vm._f("filterIconText")(_vm.userCallRecording)))])], 1) : _vm._e()]), _c('div', {
    staticClass: "lg-mar-left"
  }, [_c('a', {
    staticClass: "newButton sm-mar-left",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallRecordingEditModal', {
          account: _vm.userCallRecording.account,
          allow_recording: _vm.userCallRecording.allow_recording,
          setting: _vm.userCallRecording.auto == 1 ? 'auto' : _vm.userCallRecording.ondemand == 1 ? 'ondemand' : 'off',
          allUserData: _vm.allUserData,
          editUserModal: _vm.editUserModal
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _c('CallRecordingEditModal', {
    attrs: {
      "allUserData": _vm.allUserData,
      "editUserModal": _vm.editUserModal
    },
    on: {
      "latest": function ($event) {
        return _vm.fetchCallRecordings();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }