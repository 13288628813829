var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.select_ring_strategy ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main_option;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Ring Strategy")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.ringStartegyOptions, function (data) {
    return _c('div', {
      key: data.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.main_options.strategy = data.value;
          _vm.updateMenuOptions();
          _vm.conditions.screen = _vm.screens.main_option;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(data.text))]), _vm.forms.main_options.strategy == data.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])])] : _vm.conditions.screen == _vm.screens.select_music_on_hold ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main_option;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Music On Hold Class")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.musicOnHoldClassOptions, function (data) {
    return _c('div', {
      key: data.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.main_options.musiconhold = data.value;
          _vm.updateMenuOptions();
          _vm.conditions.screen = _vm.screens.main_option;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(data.text))]), _vm.forms.main_options.musiconhold == data.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])])] : _vm.conditions.screen == _vm.screens.select_join_empty ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main_option;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Join Empty")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.joinEmptyOptions, function (data) {
    return _c('div', {
      key: data.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.main_options.joinempty = data.value;
          _vm.updateMenuOptions();
          _vm.conditions.screen = _vm.screens.main_option;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(data.text))]), _vm.forms.main_options.joinempty == data.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])])] : _vm.conditions.screen == _vm.screens.select_leave_empty ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main_option;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Leave an Empty")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.leaveAnEmptyOptions, function (data) {
    return _c('div', {
      key: data.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.main_options.leavewhenempty = data.value;
          _vm.updateMenuOptions();
          _vm.conditions.screen = _vm.screens.main_option;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(data.text))]), _vm.forms.main_options.leavewhenempty == data.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])])] : _vm.conditions.screen == _vm.screens.select_announce_position ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.advance_option;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Announce Position")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.announcePositionOptions, function (data) {
    return _c('div', {
      key: data.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.advance_options.announce_position = data.value;
          _vm.updateAdvanceOptions();
          _vm.conditions.screen = _vm.screens.advance_option;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(data.text))]), _vm.forms.advance_options.announce_position == data.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])])] : _vm.conditions.screen == _vm.screens.select_announce_hold_time ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.agent_option;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Announce Hold time")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.announceHoldTimeOptions, function (data) {
    return _c('div', {
      key: data.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.agent_options.announce_holdtime = data.value;
          _vm.updateAgentOptions();
          _vm.conditions.screen = _vm.screens.agent_option;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(data.text))]), _vm.forms.agent_options.announce_holdtime == data.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])])] : _vm.conditions.screen == _vm.screens.select_announce_round_seconds ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.agent_option;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Announce Round Seconds")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.announceRoundSecondsOptions, function (data) {
    return _c('div', {
      key: data.value,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.forms.agent_options.announce_round_seconds = data.value;
          _vm.updateAgentOptions();
          _vm.conditions.screen = _vm.screens.agent_option;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(data.text))]), _vm.forms.agent_options.announce_round_seconds == data.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])])] : _vm.conditions.screen == _vm.screens.main_option ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Main Options")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.main_options.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.main_options.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Select Retry " + _vm._s(_vm.forms.main_options.retry))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.main_options.retry,
      expression: "forms.main_options.retry"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.main_options.send,
      "type": "range",
      "min": "5",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.main_options.retry
    },
    on: {
      "change": _vm.updateMenuOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.main_options, "retry", $event.target.value);
      }
    }
  }), _vm.forms.main_options.submitted && _vm.$v.forms.main_options.retry.$invalid || _vm.api.main_options.validation_errors.retry ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.main_options.retry.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.main_options.validation_errors.retry, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.api.main_options.send ? '' : _vm.conditions.screen = _vm.screens.select_ring_strategy;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Ring Strategy")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forms.main_options.strategy))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.main_options.submitted && _vm.$v.forms.main_options.strategy.$invalid || _vm.api.main_options.validation_errors.strategy ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.main_options.strategy.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.main_options.validation_errors.strategy, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Select Wrap Up " + _vm._s(_vm.forms.main_options.wrapuptime))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.main_options.wrapuptime,
      expression: "forms.main_options.wrapuptime"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.main_options.send,
      "type": "range",
      "min": "0",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.main_options.wrapuptime
    },
    on: {
      "change": _vm.updateMenuOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.main_options, "wrapuptime", $event.target.value);
      }
    }
  }), _vm.forms.main_options.submitted && _vm.$v.forms.main_options.wrapuptime.$invalid || _vm.api.main_options.validation_errors.wrapuptime ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.main_options.wrapuptime.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.main_options.validation_errors.wrapuptime, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.api.main_options.send ? '' : _vm.conditions.screen = _vm.screens.select_music_on_hold;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Music On Hold Class")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forms.main_options.musiconhold))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.main_options.submitted && _vm.$v.forms.main_options.musiconhold.$invalid || _vm.api.main_options.validation_errors.musiconhold ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.main_options.musiconhold.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.main_options.validation_errors.musiconhold, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.api.main_options.send ? '' : _vm.conditions.screen = _vm.screens.select_join_empty;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Join Empty")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forms.main_options.joinempty))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.main_options.submitted && _vm.$v.forms.main_options.joinempty.$invalid || _vm.api.main_options.validation_errors.joinempty ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.main_options.joinempty.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.main_options.validation_errors.joinempty, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.api.main_options.send ? '' : _vm.conditions.screen = _vm.screens.select_leave_empty;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Leave an Empty")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forms.main_options.leavewhenempty))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.forms.main_options.submitted && _vm.$v.forms.main_options.leavewhenempty.$invalid || _vm.api.main_options.validation_errors.leavewhenempty ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.main_options.leavewhenempty.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.main_options.validation_errors.leavewhenempty, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])], 1)])] : _vm.conditions.screen == _vm.screens.advance_option ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Advance Options")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.advance_options.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.advance_options.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Call Recording")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.monitor_join,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "monitor_join", $$v);
      },
      expression: "forms.advance_options.monitor_join"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Ring in Use")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.ringinuse,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "ringinuse", $$v);
      },
      expression: "forms.advance_options.ringinuse"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Announce to First User")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.announce_to_first_user,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "announce_to_first_user", $$v);
      },
      expression: "forms.advance_options.announce_to_first_user"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Relative Periodic Announce")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.relative_periodic_announce,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "relative_periodic_announce", $$v);
      },
      expression: "forms.advance_options.relative_periodic_announce"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Random Periodic Announce")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.random_periodic_announce,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "random_periodic_announce", $$v);
      },
      expression: "forms.advance_options.random_periodic_announce"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Auto Pause")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.autopause,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "autopause", $$v);
      },
      expression: "forms.advance_options.autopause"
    }
  })], 1)]), _vm.forms.advance_options.autopause ? [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Auto Pause Busy")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.autopausebusy,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "autopausebusy", $$v);
      },
      expression: "forms.advance_options.autopausebusy"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Auto Pause Unavailable")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.autopauseunavail,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "autopauseunavail", $$v);
      },
      expression: "forms.advance_options.autopauseunavail"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Auto Pause Delay " + _vm._s(_vm.forms.advance_options.autopausedelay))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.advance_options.autopausedelay,
      expression: "forms.advance_options.autopausedelay"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.advance_options.send,
      "type": "range",
      "min": "0",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.advance_options.autopausedelay
    },
    on: {
      "change": _vm.updateAdvanceOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.advance_options, "autopausedelay", $event.target.value);
      }
    }
  }), _vm.forms.advance_options.submitted && _vm.$v.forms.advance_options.autopausedelay.$invalid || _vm.api.advance_options.validation_errors.autopausedelay ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.advance_options.autopausedelay.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.advance_options.validation_errors.autopausedelay, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])] : _vm._e(), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Timeout Restart")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.advance_options.send
    },
    on: {
      "change": _vm.updateAdvanceOptions
    },
    model: {
      value: _vm.forms.advance_options.timeoutrestart,
      callback: function ($$v) {
        _vm.$set(_vm.forms.advance_options, "timeoutrestart", $$v);
      },
      expression: "forms.advance_options.timeoutrestart"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Select Max Length " + _vm._s(_vm.forms.advance_options.maxlen))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.advance_options.maxlen,
      expression: "forms.advance_options.maxlen"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.advance_options.send,
      "type": "range",
      "min": "0",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.advance_options.maxlen
    },
    on: {
      "change": _vm.updateAdvanceOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.advance_options, "maxlen", $event.target.value);
      }
    }
  }), _vm.forms.advance_options.submitted && _vm.$v.forms.advance_options.maxlen.$invalid || _vm.api.advance_options.validation_errors.maxlen ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.advance_options.maxlen.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.advance_options.validation_errors.maxlen, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Select Weight " + _vm._s(_vm.forms.advance_options.weight))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.advance_options.weight,
      expression: "forms.advance_options.weight"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.advance_options.send,
      "type": "range",
      "min": "0",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.advance_options.weight
    },
    on: {
      "change": _vm.updateAdvanceOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.advance_options, "weight", $event.target.value);
      }
    }
  }), _vm.forms.advance_options.submitted && _vm.$v.forms.advance_options.weight.$invalid || _vm.api.advance_options.validation_errors.weight ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.advance_options.weight.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.advance_options.validation_errors.weight, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Select Time out " + _vm._s(_vm.forms.advance_options.timeout))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.advance_options.timeout,
      expression: "forms.advance_options.timeout"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.advance_options.send,
      "type": "range",
      "min": "0",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.advance_options.timeout
    },
    on: {
      "change": _vm.updateAdvanceOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.advance_options, "timeout", $event.target.value);
      }
    }
  }), _vm.forms.advance_options.submitted && _vm.$v.forms.advance_options.timeout.$invalid || _vm.api.advance_options.validation_errors.timeout ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.advance_options.timeout.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.advance_options.validation_errors.timeout, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.api.advance_options.send ? '' : _vm.conditions.screen = _vm.screens.select_announce_position;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Select Announce Position")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forms.advance_options.announce_position))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.api.advance_options.submitted && _vm.forms.advance_options.announce_position.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.advance_options.announce_position.required ? _c('span', [_vm._v("*Required")]) : _vm._e()]) : _vm._e()]), ['limit', 'more'].includes(_vm.forms.advance_options.announce_position) ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Announce Position Limit " + _vm._s(_vm.forms.advance_options.announce_position_limit))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.advance_options.announce_position_limit,
      expression: "forms.advance_options.announce_position_limit"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.advance_options.send,
      "type": "range",
      "min": "0",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.advance_options.announce_position_limit
    },
    on: {
      "change": _vm.updateAdvanceOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.advance_options, "announce_position_limit", $event.target.value);
      }
    }
  }), _vm.forms.advance_options.submitted && _vm.$v.forms.advance_options.announce_position_limit.$invalid || _vm.api.advance_options.validation_errors.announce_position_limit ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.advance_options.announce_position_limit.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.advance_options.validation_errors.announce_position_limit, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]) : _vm._e()], 2)], 1)])] : _vm.conditions.screen == _vm.screens.agent_option ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Agent Options")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.agent_options.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.agent_options.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Report Hold Time")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "name": "check-button",
      "switch": "",
      "disabled": _vm.api.agent_options.send
    },
    on: {
      "change": _vm.updateAgentOptions
    },
    model: {
      value: _vm.forms.agent_options.reportholdtime,
      callback: function ($$v) {
        _vm.$set(_vm.forms.agent_options, "reportholdtime", $$v);
      },
      expression: "forms.agent_options.reportholdtime"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Select Members Delay " + _vm._s(_vm.forms.agent_options.memberdelay))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.agent_options.memberdelay,
      expression: "forms.agent_options.memberdelay"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.agent_options.send,
      "type": "range",
      "min": "0",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.agent_options.memberdelay
    },
    on: {
      "change": _vm.updateAgentOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.agent_options, "memberdelay", $event.target.value);
      }
    }
  }), _vm.api.agent_options.submitted && _vm.forms.agent_options.memberdelay.$invalid || _vm.api.agent_options.validation_errors.memberdelay ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.agent_options.memberdelay.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.agent_options.validation_errors.memberdelay, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.api.agent_options.send ? '' : _vm.conditions.screen = _vm.screens.select_announce_hold_time;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Select Announce Hold time")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forms.agent_options.announce_holdtime))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.api.agent_options.submitted && _vm.forms.agent_options.announce_holdtime.$invalid || _vm.api.agent_options.validation_errors.announce_holdtime ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.agent_options.announce_holdtime.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.agent_options.validation_errors.announce_holdtime, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column",
    on: {
      "click": function ($event) {
        _vm.api.agent_options.send ? '' : _vm.conditions.screen = _vm.screens.select_announce_round_seconds;
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Select Announce Round Seconds")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forms.agent_options.announce_round_seconds))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.api.agent_options.submitted && _vm.forms.agent_options.announce_round_seconds.$invalid || _vm.api.agent_options.validation_errors.announce_round_seconds ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.agent_options.announce_round_seconds.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.agent_options.validation_errors.announce_round_seconds, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])], 1)])] : _vm.conditions.screen == _vm.screens.announcement_option ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Announcement Options")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.announcement_options.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.announcement_options.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Select Priodic Frequency " + _vm._s(_vm.forms.announcement_options.periodic_announce_frequency))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.announcement_options.periodic_announce_frequency,
      expression: "forms.announcement_options.periodic_announce_frequency"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.announcement_options.send,
      "type": "range",
      "min": "0",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.announcement_options.periodic_announce_frequency
    },
    on: {
      "change": _vm.updateAnnouncementOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.announcement_options, "periodic_announce_frequency", $event.target.value);
      }
    }
  }), _vm.api.announcement_options.submitted && _vm.forms.announcement_options.periodic_announce_frequency.$invalid || _vm.api.announcement_options.validation_errors.periodic_announce_frequency ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.announcement_options.periodic_announce_frequency.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.announcement_options.validation_errors.periodic_announce_frequency, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Select Announce Frequency " + _vm._s(_vm.forms.announcement_options.announce_frequency))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.announcement_options.announce_frequency,
      expression: "forms.announcement_options.announce_frequency"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.announcement_options.send,
      "type": "range",
      "min": "0",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.announcement_options.announce_frequency
    },
    on: {
      "change": _vm.updateAnnouncementOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.announcement_options, "announce_frequency", $event.target.value);
      }
    }
  }), _vm.api.announcement_options.submitted && _vm.forms.announcement_options.announce_frequency.$invalid || _vm.api.announcement_options.validation_errors.announce_frequency ? _c('p', [!_vm.$v.forms.announcement_options.announce_frequency.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.announcement_options.validation_errors.announce_frequency, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS progressBarInput"
  }, [_c('label', [_vm._v("Select Minimum Announce Frequecy " + _vm._s(_vm.forms.announcement_options.min_announce_frequency))]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.announcement_options.min_announce_frequency,
      expression: "forms.announcement_options.min_announce_frequency"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.announcement_options.send,
      "type": "range",
      "min": "0",
      "max": "180"
    },
    domProps: {
      "value": _vm.forms.announcement_options.min_announce_frequency
    },
    on: {
      "change": _vm.updateAnnouncementOptions,
      "__r": function ($event) {
        return _vm.$set(_vm.forms.announcement_options, "min_announce_frequency", $event.target.value);
      }
    }
  }), _vm.api.announcement_options.submitted && _vm.forms.announcement_options.min_announce_frequency.$invalid || _vm.api.announcement_options.validation_errors.min_announce_frequency ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.announcement_options.min_announce_frequency.required ? _c('span', [_vm._v("*Required")]) : _vm._e(), _vm._l(_vm.api.announcement_options.validation_errors.min_announce_frequency, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectSoundFile', {
          type: 'queue_reporthold',
          value: _vm.forms.announcement_options.queue_reporthold.value
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Queue Report Hold")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.forms.announcement_options.queue_reporthold.file ? _c('vb-audio-player', {
    staticClass: "mr-6px IosAudioPlayer",
    attrs: {
      "src": _vm.forms.announcement_options.queue_reporthold.file
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectSoundFile', {
          type: 'queue_thankyou',
          value: _vm.forms.announcement_options.queue_thankyou.value
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Queue Thank you")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.forms.announcement_options.queue_thankyou.file ? _c('vb-audio-player', {
    staticClass: "mr-6px IosAudioPlayer",
    attrs: {
      "src": _vm.forms.announcement_options.queue_thankyou.file
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectSoundFile', {
          type: 'queue_holdtime',
          value: _vm.forms.announcement_options.queue_holdtime.value
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Queue Hold Time")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.forms.announcement_options.queue_holdtime.file ? _c('vb-audio-player', {
    staticClass: "mr-6px IosAudioPlayer",
    attrs: {
      "src": _vm.forms.announcement_options.queue_holdtime.file
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectSoundFile', {
          type: 'queue_callswaiting',
          value: _vm.forms.announcement_options.queue_callswaiting.value
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Queue Calls Waiting")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.forms.announcement_options.queue_callswaiting.file ? _c('vb-audio-player', {
    staticClass: "mr-6px IosAudioPlayer",
    attrs: {
      "src": _vm.forms.announcement_options.queue_callswaiting.file
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectSoundFile', {
          type: 'queue_thereare',
          value: _vm.forms.announcement_options.queue_thereare.value
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Queue There Are")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.forms.announcement_options.queue_thereare.file ? _c('vb-audio-player', {
    staticClass: "mr-6px IosAudioPlayer",
    attrs: {
      "src": _vm.forms.announcement_options.queue_thereare.file
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectSoundFile', {
          type: 'queue_youarenext',
          value: _vm.forms.announcement_options.queue_youarenext.value
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Queue You Are Next")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.forms.announcement_options.queue_youarenext.file ? _c('vb-audio-player', {
    staticClass: "mr-6px IosAudioPlayer",
    attrs: {
      "src": _vm.forms.announcement_options.queue_youarenext.file
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectSoundFile', {
          type: 'queue_callerannounce',
          value: _vm.forms.announcement_options.queue_callerannounce.value
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Queue Caller Announce")])]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.forms.announcement_options.queue_callerannounce.file ? _c('vb-audio-player', {
    staticClass: "mr-6px IosAudioPlayer",
    attrs: {
      "src": _vm.forms.announcement_options.queue_callerannounce.file
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])])], 1)])] : _vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Call Queue")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.update_label.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.update_label.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_label.label,
      expression: "forms.update_label.label"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Name",
      "type": "text",
      "disabled": _vm.api.update_label.send
    },
    domProps: {
      "value": _vm.forms.update_label.label
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_label, "label", $event.target.value);
      }, _vm.updateLabel]
    }
  }), _vm.forms.update_label.submitted && _vm.$v.forms.update_label.label.$invalid || _vm.api.update_label.validation_errors.label ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_label.label.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), _vm._l(_vm.api.update_label.validation_errors.label, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])])], 1)])])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Call Queue")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "profile-image-section-container forIos mt-32px"
  }, [_c('div', {
    staticClass: "profile-image-section",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CallQueueProfileImageUploader', {
          type: 'CALL_QUEUE',
          accountcode: _vm.callQueueAccountcode,
          current_image: _vm.data.profile_image
        });
      }
    }
  }, [_c('div', {
    staticClass: "profile-img"
  }, [_c('img', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    },
    attrs: {
      "id": "user-profile-image-updated",
      "src": _vm.data.profile_image,
      "alt": _vm.data.call_queue.label
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _c('div', {
    staticClass: "BiggestMainHeading mt-22px"
  }, [_vm._v(_vm._s(_vm.data.call_queue.label))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('EditCallQueueAssignNumberModal', {
          accountcode: _vm.callQueueAccountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Call Queue Numbers")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.callQueue, 'userDid.length', 0)))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('EditCallQueueMembersModal1', {
          accountcode: _vm.callQueueAccountcode
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Call Queue Members")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.callQueue, 'members.length', 0)))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main_option;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Main Options")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.advance_option;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Advance Options")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.agent_option;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Agent Options")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.announcement_option;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Announcement Options")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])])])], _c('ProfileImageUploaderModal', {
    attrs: {
      "modalName": 'CallQueueProfileImageUploader'
    },
    on: {
      "image-uploaded": function ($event) {
        _vm.data.profile_image = $event;
        _vm.getCallQueue();
      }
    }
  }), _c('SelectSoundFileModal', {
    on: {
      "select-file": function ($event) {
        return _vm.onSelectFile($event);
      }
    }
  }), _c('AssignNumbersModal', {
    attrs: {
      "modalName": 'EditCallQueueAssignNumberModal'
    },
    on: {
      "added": function ($event) {
        return _vm.getCallQueue();
      }
    }
  }), _c('AddRemoveUsersModal', {
    attrs: {
      "modalName": `EditCallQueueMembersModal1`,
      "type": 'assign_call_queue'
    },
    on: {
      "update-list": function ($event) {
        return _vm.getCallQueue();
      }
    }
  })], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }