
import { VOIP_API } from "../index"

export const BILLING_API_API_ENDPOINTS = {
  countries(){
    return VOIP_API.axios.voip_billing.request({
      method: "get",
      url: "aqlcountries"
    })
  },
  cities(){
    return VOIP_API.axios.voip_billing.request({
      method: "post",
      url: "aql-cities-by-country"
    })
  },
  callcost(){
    return VOIP_API.axios.voip_billing.request({
      method: "get",
      url: "get-monthly-cost"
    })
  },
  create(data){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'payment',
        action: 'add_card',
      },
    })
  },
  checkBillingMode(data){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'billing_mode',
      },
    })
  },
  getSetupIntentClientSecret(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'payment',
        action: 'setup_intent',
      },
    })
  },
  checkPriceOfUnlimitedCallPlan(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'unlimited_call_plan',
      },
    })
  },
  getcreditcards(data){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'payment',
        action: 'get_user_cards',
      },
    })
  },
  getReserveBalance(data){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'user_reserved_amount',
      },
    })
  },
  makedefaultcreditcard(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'payment',
        action: 'mark_credit_card_default',
      },
    })
  },
  deletecreditcard(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'payment',
        action: 'delete_credit_card',
      },
    })
  },
  // added by 
  payOutstandingAmount(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'pay_invoice_manually',
      },
    })
  },
}