<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-800 editTeamModal" 
      width="40%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <section class="ct-user-new dialer-edit pb-4">
        <Info v-if="data.call.accountcode" :id="data.call.accountcode" />
        <Info v-if="data.call.assigned_accountcode" :id="data.call.assigned_accountcode" />
        <p class="color-white">{{ data.call.assigned_date }}</p>
        <p v-if="data.call.status" class="color-white">{{ data.call.status }}</p>
        <b-button>
          Done
        </b-button>
        <b-button>
          Archive
        </b-button>
        <b-button 
          @click="$modal.show('AssignTodo',{
            call_id: data.call.cdr_id,
          })"
        >
          Assign
        </b-button>
        <CallActivityItem1 style="background-color: black;" :call="data.call.todoData" />
      </section>
    </modal>
    <AssignTodoModal @assigned="$emit('assigned')" />
  </div>
</template>

<script>
import Info from '../Lists/Info.vue';
import AssignTodoModal from './AssignTodoModal.vue';
import CallActivityItem1 from '../Home/CallActivity/Item1.vue'
export default {
  name: "AssignedTodoDetailModal",
  components: { 
    Info,
    AssignTodoModal,
    CallActivityItem1,
  },
  props: {
    modalName: {
      type: String,
      default: 'AssignedTodoDetail'
    },
  },
  data() {
    return {
      data: this.$helperFunction.stateInstance({
        data: {
          call: {},
        }
      }),
    };
  },
  methods: {
    onBeforeOpen(event) {
      this.data.call = event?.params?.call ?? {};
    },
    onBeforeClose() {
      this.data.reset()
    },
  },
};
</script>
