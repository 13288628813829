var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('a', {
    staticClass: "d-flex align-items-start justify-content-between text-dark callLogItself",
    attrs: {
      "status": _vm.call.status,
      "isLeft": _vm.call.status === 'Dialed' ? 'Right' : 'Left'
    }
  }, [_c('span', {
    staticClass: "ico text-white rounded-circle d-flex align-items-center justify-content-center",
    attrs: {
      "id": `boundToolTip-${_vm.random_id}`
    }
  }, [_vm.call.call_type === 'audio' ? [_vm.call.status === 'Missed' ? _c('vb-svg', {
    attrs: {
      "name": "missedCall-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _vm.call.status === 'Received' ? _c('vb-svg', {
    attrs: {
      "name": "receivedCall-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _c('vb-svg', {
    attrs: {
      "name": "dialedCall-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })] : _vm._e(), _vm.call.call_type === 'video' ? [_vm.call.status === 'Missed' ? _c('vb-svg', {
    attrs: {
      "name": "missedVideo-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _vm.call.status === 'Received' ? _c('vb-svg', {
    attrs: {
      "name": "receivedVideo-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _c('vb-svg', {
    attrs: {
      "name": "dialedVideo-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "red",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })] : _vm._e(), _vm.call.call_type === 'voicemail' ? [_c('vb-svg', {
    attrs: {
      "name": "voiceMessage-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })] : _vm._e(), _vm.call.call_type === 'sms' ? [_vm.call.status === 'Dialed' ? _c('vb-svg', {
    attrs: {
      "name": "sendMessage-icon",
      "width": "19",
      "height": "18",
      "viewBox": "0 0 19 18",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }) : _c('vb-svg', {
    attrs: {
      "name": "receivedMessage-icon",
      "width": "34",
      "height": "34",
      "viewBox": "0 0 34 34",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })] : _vm._e()], 2), _c('b-popover', {
    attrs: {
      "target": `boundToolTip-${_vm.random_id}`,
      "placement": "top",
      "triggers": "hover"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm.call.status === 'Dialed' ? 'Outbound' : 'Inbound'))])]), _c('span', {
    staticClass: "info-area px-2"
  }, [_c('div', {
    staticClass: "call-date position-relative"
  }, [_c('div', {
    staticClass: "d-flex flex-column justify-content-between",
    staticStyle: {
      "width": "max-content"
    }
  }, [_c('div', [_c('span', {
    staticClass: "d-block callLogTitle"
  }, [_vm.call.call_type === 'voicemail' ? [_vm._v(_vm._s(_vm.call.caller.replace(/"/g, "")))] : _vm._e(), _vm.call.status == 'Missed' || _vm.call.status == 'Received' ? [_vm._v(_vm._s(`${_vm.call.sourceName || ""}`))] : [_vm._v(_vm._s(`${_vm.call.destinationName || ""}`))]], 2), _vm.call.call_type === 'voicemail' && _vm.call.is_shared == 'yes' ? [_c('span', {
    staticClass: "sharedText ml-2",
    staticStyle: {
      "left": "0px"
    },
    attrs: {
      "id": `sharedText-${_vm.random_id}`
    }
  }, [_vm._v("shared")]), _c('b-popover', {
    attrs: {
      "target": `sharedText-${_vm.random_id}`,
      "placement": "top",
      "triggers": "hover focus"
    }
  }, [_c('small', [_vm._v(_vm._s(_vm._f("get_property")(_vm.call, 'sharedByDetail.label', '')) + " "), _c('b', [_vm._v(_vm._s(_vm._f("get_property")(_vm.call, 'sharedByDetail.extn', '')))])])])] : _vm._e()], 2), _c('span', {
    staticClass: "small text-capitalize mb-2"
  }, [_vm.call.call_type === 'audio' || _vm.call.call_type === 'video' ? [_c('span', {
    staticClass: "dialer-call-prefix 1"
  }, [(_vm.call.status == 'Missed' || _vm.call.status == 'Received') && _vm.call.sourceExt ? _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(`${_vm.call.sourceExt || ""}`))]) : (_vm.call.status != 'Missed' || _vm.call.status != 'Received') && _vm.call.destinationExt ? _c('span', {
    staticClass: "d-block"
  }, [_vm._v(_vm._s(_vm.call.destinationExt || ""))]) : _vm._e()]), _vm.call.remote_caller_id ? _c('span', {
    staticClass: "dialer-call-prefix 2"
  }, [_c('span', {
    staticClass: "d-block",
    class: (_vm.call.status == 'Missed' || _vm.call.status == 'Received') && _vm.call.sourceExt || (_vm.call.status != 'Missed' || _vm.call.status != 'Received') && _vm.call.destinationExt ? '' : 'mt-17c',
    staticStyle: {
      "padding": "2px 0px",
      "border-radius": "8px",
      "color": "#393939",
      "width": "auto",
      "font-size": "12px"
    }
  }, [_c('b', {
    staticClass: "position-absolute",
    staticStyle: {
      "margin-top": "0px",
      "left": "-35px",
      "color": "#5576d1"
    }
  }, [_vm._v(" via: ")]), _vm._v(" " + _vm._s(_vm.call.remote_caller_id) + " ")])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "dialpad_activity_box_other justify-content-start flex-column"
  }, [_c('small', {
    staticClass: "duration ml-0 text-right"
  }, [_vm._v(_vm._s(_vm._f("showTime")(_vm.call.call_date)))]), _c('div', {
    staticClass: "dialpad_activity_box_other mt-2"
  }, [_c('small', {
    staticClass: "duration ml-0"
  }, [_vm._v(_vm._s(_vm._f("secondsTimer")(_vm.duration)))])])])]), _c('span', {
    class: ['small text-capitalize', _vm.condition.message && _vm.call.call_type === 'sms' ? 'messageText-container' : '']
  }, [_vm.call.call_type === 'audio' || _vm.call.call_type === 'video' ? [_vm.condition.tag ? _vm._l(_vm.tags, function (tag) {
    return _c('span', {
      key: tag.id,
      staticClass: "dialer-call-prefix tag"
    }, [_c('small', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color",
        value: tag.colour,
        expression: "tag.colour"
      }]
    }, [_c('span', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text",
        value: tag.text_color,
        expression: "tag.text_color",
        modifiers: {
          "text": true
        }
      }]
    }, [_vm._v(" " + _vm._s(tag.tag) + " ")])])]);
  }) : _vm._e(), _vm.condition.note && _vm.note ? _c('span', {
    staticClass: "dialer-call-prefix note"
  }, [_vm._v(" " + _vm._s(_vm.note) + " ")]) : _vm._e()] : _vm.condition.recording && _vm.call.call_type === 'voicemail' ? [_c('div', {
    staticClass: "dialer-audio-playback"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm._f("base64_to_url")(_vm.call.voice)
    }
  })], 1)] : _vm.condition.message && _vm.call.call_type === 'sms' ? [_c('span', {
    staticClass: "dialer-call-prefix messageText"
  }, [_vm._v(_vm._s(_vm.call.body || ""))])] : _vm._e()], 2)]), _vm.type == 'activity' ? _c('span', {
    staticClass: "dialer-dropdown dialpad_activity_box_menu d-flex flex-column align-items-center justify-content-between"
  }, [_c('span', {
    staticClass: "dialpad_activity_box_menu_list"
  }, [_vm.call.call_type !== 'voicemail' ? _c('a', {
    staticClass: "m-0 w-auto p-0",
    on: {
      "click": function ($event) {
        return _vm.calling(_vm.call, _vm.call.is_jitsi ? 'video' : 'audio');
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-call-smallIcon",
      "width": "26",
      "height": "26",
      "viewBox": "0 0 26 26",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1) : _vm._e()]), _c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          staticStyle: {
            "margin-top": "5px"
          },
          attrs: {
            "icon": "three-dots",
            "variant": "#393939",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }], null, false, 3965320346)
  }, [_vm.call.is_did == '1' ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.add_addressbooks = _vm.call.status == 'Missed' || _vm.call.status == 'Received' ? _vm.call.sourceName || '' : _vm.call.destinationName || '';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-addToContact-icon",
      "width": "16.245",
      "height": "15.004"
    }
  }), _c('span', {
    staticClass: "ml-3"
  }, [_vm._v("Add Contact")])], 1) : _vm._e(), _vm.call.call_type !== 'voicemail' ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.calling(_vm.call, _vm.call.is_jitsi ? 'video' : 'audio');
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "telephone",
      "font-scale": "0.96"
    }
  }), _c('span', {
    staticClass: "ml-3"
  }, [_vm._v("Call")])], 1) : _vm._e(), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$emit('onClickInfo1');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-callinfo-icon",
      "width": "15.7",
      "height": "15.701"
    }
  }), _c('span', {
    staticClass: "ml-3"
  }, [_vm._v(_vm._s(_vm.call.call_type === "voicemail" || _vm.call.call_type === "sms" ? "Message" : "Call") + " Info New")])], 1)], 1)], 1) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }