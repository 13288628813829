var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 AddTeamModal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit s1"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addTeam();
      }
    }
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Add team")]), _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        _vm.api.add_team.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1)]) : _c('div', {
    staticClass: "dialer-edit-header d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Add team")]), _c('vb-icon', {
    staticClass: "ml-2 cursor_pointer checkSlotsModal-icon",
    attrs: {
      "icon": "checkSlotsModal-icon",
      "height": "22px",
      "width": "22px",
      "disabled": _vm.api.add_team.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CheckedServiceAvailableModal', {
          service: 'team',
          onProceed: _vm.addTeam
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        _vm.api.add_team.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Enter the name of the new team below. ")])]), _vm.api.check_service.send ? [_c('div', {
    staticClass: "md-mar-top"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  })]), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })])])]), _c('div', {
    staticClass: "latestShimmerDesign mt-4",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })] : _c('div', {
    staticClass: "md-mar-top"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.add_team.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_team.error_message))]), _c('b-alert', {
    staticClass: "alertCustom",
    attrs: {
      "show": _vm.checkServiceAlert.enable,
      "variant": _vm.checkServiceAlert.variant
    }
  }, [_vm._v(_vm._s(_vm.checkServiceAlert.message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.team.name,
      expression: "forms.team.name"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Main office, sales etc...",
      "type": "text",
      "maxlength": _vm.$v.forms.team.name.$params.maxLength.max,
      "disabled": _vm.api.add_team.send
    },
    domProps: {
      "value": _vm.forms.team.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.team, "name", $event.target.value);
      }
    }
  }), _vm.forms.team.submitted && _vm.$v.forms.team.name.$invalid || _vm.api.add_team.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.team.name.required ? _c('span', [_vm._v("* Name is required")]) : !_vm.$v.forms.team.name.maxLength ? _c('span', [_vm._v("* Name can be maximum " + _vm._s(_vm.$v.forms.team.name.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.add_team.validation_errors.name, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "mt-4 w-100"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.add_team.send
    }
  }, [_vm.api.add_team.send ? _c('vb-spinner') : [_vm._v("Create team")]], 2)])], 1)], 2)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }