
import { VOIP_API } from "../index"

export const ADDRESS_BOOKS_API_ENDPOINTS = {
  list(params={}){
    return VOIP_API.axios.voip.request({
      url: "phonebooks",
      method: "GET",
      params,
    })
  },
  create(data){
    return VOIP_API.axios.voip.request({
      url: "phonebooks",
      method: "POST",
      data,
    })
  },
  delete(data={}){
    return VOIP_API.axios.voip.request({
      url: "phonebooks",
      method: "DELETE",
      data,
    })
  },
  shortcodes(params={}){
    return VOIP_API.axios.voip.request({
      url: "addressbook-available-shortcodes",
      method: "GET",
      params,
    })
  },
  update(data={}){
    return VOIP_API.axios.voip.request({
      url: "update-phonebook-app",
      method: "POST",
      data,
    })
  },
  syncContact(data={}){
    return VOIP_API.axios.voip.request({
      url: "update-phonebook-sync",
      method: "POST",
      data,
    })
  },
  createPortNumber(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'relay',
        action: 'add_port_number',
      },
    })
  },
  toggleFavourite(data={}){
    return VOIP_API.axios.voip.request({
      method: "POST",
      url: "toggle-addressbook-favourite",
      data,
    })
  },
}