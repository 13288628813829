var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-billing"
  }, [_c('div', {
    staticClass: "dialer-box"
  }, [_c('header', {
    staticClass: "dialer-analytics-titleBar"
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "dialer-analytics-title calc-title-width-analytics"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h1', {}, [_vm._v("Billed calls")])], 1)] : _c('div', {
    staticClass: "dialer-analytics-title"
  }, [_c('b-icon', {
    attrs: {
      "icon": "wallet",
      "variant": "primary"
    }
  }), _c('h1', {
    staticClass: "md-mar-left"
  }, [_vm._v("Billed calls")])], 1)], 2), _c('div', {
    staticClass: "dialer-analytics-reporting-filter mt-4"
  }, [_c('div', {
    staticClass: "dialer-analyticsReportingFilter-inner dialer-flex align-items-end"
  }, [_c('div', {
    staticClass: "dialer-analyticsReportingFilter-inner-leftSide"
  }, [_c('div', {
    staticClass: "mb-0 column col2 whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', {
    staticClass: "dialer-input-label pl-2"
  }, [_vm._v("From number")]), _c('b-form-input', {
    directives: [{
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate"
    }],
    attrs: {
      "type": "text",
      "placeholder": "From number"
    },
    model: {
      value: _vm.filter.billing.callerid,
      callback: function ($$v) {
        _vm.$set(_vm.filter.billing, "callerid", $$v);
      },
      expression: "filter.billing.callerid"
    }
  }), _vm.$v.filter.billing.callerid.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.filter.billing.callerid.validNumber ? _c('span', [_vm._v("* Number is invalid")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "mb-0 column col1 whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', {
    staticClass: "pl-2"
  }, [_vm._v("To number")]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-form-input', {
    directives: [{
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate"
    }],
    attrs: {
      "type": "text",
      "placeholder": "To number"
    },
    model: {
      value: _vm.filter.billing.callednumber,
      callback: function ($$v) {
        _vm.$set(_vm.filter.billing, "callednumber", $$v);
      },
      expression: "filter.billing.callednumber"
    }
  }), _vm.$v.filter.billing.callednumber.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.filter.billing.callednumber.validNumber ? _c('span', [_vm._v("* Number is invalid")]) : _vm._e()]) : _vm._e()], 1)]), _c('div', {
    staticClass: "mb-0 column col3 whiteBGinputWithGreyRoundedBorder dropdownVersion"
  }, [_c('label', {
    staticClass: "dialer-input-label pl-2"
  }, [_vm._v("Select billed")]), _c('b-select', {
    attrs: {
      "options": _vm.billedOptions
    },
    model: {
      value: _vm.filter.billing.billed,
      callback: function ($$v) {
        _vm.$set(_vm.filter.billing, "billed", $$v);
      },
      expression: "filter.billing.billed"
    }
  }, [_vm._v("Select billed")])], 1)]), _c('b-button', {
    staticClass: "newButton ml-auto",
    on: {
      "click": function ($event) {
        return _vm.fetchBilling();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-browse-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Fetch")])], 1)], 1)]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again pt-3",
    attrs: {
      "noRecordClass": `noDataInTable`,
      "textNoRecord": " ",
      "isListEmpty": _vm.response.billing.length == 0,
      "loading": _vm.api.fetch_billing.send,
      "listLength": _vm.pagination.billing.total,
      "perPage": _vm.pagination.billing.per_page,
      "conditions": {
        pagination: {
          per_page: false
        }
      },
      "page": _vm.pagination.billing.page
    },
    on: {
      "page-changed": function ($event) {
        _vm.pagination.billing.page = $event;
        _vm.fetchBilling();
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.response.billing, function (data) {
          return _c('tr', {
            key: data.id,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(data.callerid)))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(data.called_number)))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.call_start))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(_vm._f("duration_format")(data.duration)))]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(data.client_cost))])]);
        });
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("From")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("To")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Time")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Duration")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Cost")])])])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }