<template>
  
  <modal height="auto" :name="modalName" class="center-zoomIn-transition v2 m-w-500 addCallQueueModal SmallAddModal" @before-open="onBeforeOpen" :scrollable="true" @before-close="onBeforeClose">
    <div class="dialer-edit-user s1">
      <div class="dialer-edit">
        <div class="dialer-form">
          <div class="dialer-edit-header d-block pb-0">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center addCallQueueModal-namePart">
                <h2 class="dialer-settings-title newer mb-0">Add call queue</h2>
                <vb-icon 
                  icon="checkSlotsModal-icon" class="ml-2 cursor_pointer checkSlotsModal-icon" height="22px" width="22px"
                  :disabled="api.add_call_queue.send" @click="$modal.show('CheckedServiceAvailableModal',{service: 'queue',onProceed: addCallQueue,})"
                />
              </div>
              <div class="dialer-edit-actions d-flex align-items-center">
                <!-- <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" /> -->
                <!-- v-else  -->
                <a class="newCloseButton" @click="$modal.hide(modalName)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <div class="vm--modal-text">
              Enter the name of the new call queue below.
            </div>
          </div>
          <div class="md-mar-vertical">
            <template v-if="api.check_service.send">
              <div class="md-mar-top">
                <div class="latestGreyBox-9-9-2023 mt-20px">
                  <div class="whiteBGinputWithGreyRoundedBorder">
                    <label>
                      <div class="latestShimmerDesign" style="height: 21px;width: 200px;"></div>
                    </label>
                    <div class="latestShimmerDesign" style="height: 50px;width: 100%;"></div>
                  </div>
                </div>
              </div>
              <div class="latestShimmerDesign mt-4" style="height: 50px;width: 100%;"></div>
            </template>
            <form v-else @submit.prevent="addCallQueue()">
            <b-alert :show="!!api.add_call_queue.error_message" variant="danger">{{ api.add_call_queue.error_message }}</b-alert>
              <b-alert class="alertCustom" :show="checkServiceAlert.enable" :variant="checkServiceAlert.variant">{{ checkServiceAlert.message }}</b-alert>
              <div class="latestGreyBox-9-9-2023 mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder">
                  <label>Enter the name</label>
                  <input :disabled="api.add_call_queue.send" v-model="forms.call_queue.name" placeholder="" :maxlength="$v.forms.call_queue.name.$params.maxLength.max" class="w-100 bg-white" type="text" />
                  <p v-if="(forms.call_queue.submitted && $v.forms.call_queue.name.$invalid) || api.add_call_queue.validation_errors.name" class="text text-small text-danger animated bounceIntop mb-0">
                    <span v-if="!$v.forms.call_queue.name.required">* Name is Required</span>
                    <span v-else-if="!$v.forms.call_queue.name.minLength">* Name is should be minimum {{$v.forms.call_queue.name.$params.minLength.min}} character</span>
                    <span v-else-if="!$v.forms.call_queue.name.maxLength">* Name is can be maximum {{$v.forms.call_queue.name.$params.maxLength.max}} character</span>
                    <span v-else-if="!$v.forms.call_queue.name.valid">* Name can be number and alphabet</span>
                    <span v-for="(error_message, index) in api.add_call_queue.validation_errors.name" :key="index">* {{ error_message }}</span>
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center justify-content-end mt-4">
                <button type="submit" class="fullWidthDoneButton" :disabled="api.add_call_queue.send" @click="addCallQueue()">
                  <vb-spinner v-if="api.add_call_queue.send" />
                  <template v-else>Create call queue</template>
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { check_service } from '@/mixin';
import { mapGetters } from 'vuex';
import { /*events,*/ VOIP_API } from "../../utils";
import required from "vuelidate/lib/validators/required";
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
import { helpers } from 'vuelidate/lib/validators';
export default {
  name: "AddCallQueueModal",
  mixins: [
    check_service
  ],
  props: {
    modalName: {
      type: String,
      default: 'AddCallQueueModal',
    },
  },
  data() {
    return {
      forms: {
        call_queue: {
          name: '',
          reset() {
            this.name = ''
            this.submitted = false
          },
          submitted: false,
        },
        reset(){
          this.call_queue.reset()
        },
      },
      api: {
        add_call_queue: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true, }),
        check_service: this.$helperFunction.apiInstance(),
        reset(){
          this.add_call_queue?.reset?.()
        },
      },
      response: {
        check_service: {},
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    checkServiceAlert(){
      const type = this.response.check_service?.type
      const message = this.response.check_service?.message || ''
      return {
        enable: !!this.response.check_service?.type,
        title: ['ADDON'].includes(type) ? 'Attention' : 'Warning',
        message: message,
        variant: ['ADDON'].includes(type) ? 'info' : 'danger',
      }
    },
  },
  validations: {
    forms: {
      call_queue: {
        name: { 
          required: required,
          minLength: minLength(3), 
          maxLength: maxLength(20),
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      this.forms.call_queue.name=event?.params?.suggested_name ?? ''
      this.checkservice()
    },
    onBeforeClose(){
      this.forms.reset()
      this.api.reset()
      this.api.check_service.reset()
      this.response.check_service={}
    },
    checkservice(){
      let vm = this
      if(vm.api.check_service.send) return;
      vm.api.check_service.send=true
      vm.checkService({
        service: 'queue',
        no_confirmation: true,
        then(data){
          vm.response.check_service=data || {}
        },
        catch(ex){
          vm.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        },
        finally(){
          vm.api.check_service.send=false
        }
      })
    },
    addCallQueue(){
      let vm = this
      vm.forms.call_queue.submitted=true
      vm.$v.forms.call_queue.$touch()
      if(vm.api.add_call_queue.send || vm.$v.forms.call_queue.$invalid) return;
      vm.api.add_call_queue.send=true
      vm.api.add_call_queue.validation_errors={}
      vm.api.add_call_queue.error_message=''
      vm.checkService({
        service: 'queue',
        async then(){
          await VOIP_API.endpoints.call_queues.create({
            name: vm.forms.call_queue.name,
            accountcode: vm.getCurrentUser?.account,
            default_account: vm.getCurrentUser?.default_accountcode,
            service: "queue",
            uid: vm.getCurrentUser?.uid,
          })
          // vm.$store.commit('setAddServiceQueue',{
          //   type: 'call_queue',
          //   name: vm.forms.call_queue.name,
          // })
          vm.$emit("call-queue-added");
          vm.$modal.hide(vm.modalName)
          // vm.$root.$emit(events.check_pending_services)
        },
        catch(ex){
          vm.api.add_call_queue.validation_errors=ex.own_errors || {}
          vm.api.add_call_queue.error_message=ex.own_message || ''
        },
        finally(){
          vm.api.add_call_queue.send=false
          vm.forms.call_queue.submitted=false
        },
      })
    },
  },
};
</script>


<style>
.slide-ltr-enter-active {
  animation: slide-ltr 0.5s reverse;
}
.slide-ltr-leave-active {
  animation: slide-ltr 0.5s;
}
@keyframes slide-ltr {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>