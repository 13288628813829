<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.check.send?'':cancel()">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Check Slot</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">
        <div v-if="api.check.send" class="d-flex justify-content-center align-items-center">
          <vb-spinner v-if="api.check.send" />
        </div>
        <template v-else>
          <div v-if="slotAvailable" class="basicWhiteIOScard mt-32px mb-16px">
            <template v-if="isAddon">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Name</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ data.service }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Allowed</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ response.slot.PlanAddonAllowedProductCount | show_positive_value }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Used</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ response.slot.PlanAddonUsedProductCount | show_positive_value }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Remaining</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ response.slot.PlanAddonRemainingProductCount | show_positive_value }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Price</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard onRightSide textAlignRight mr-4px">{{ response | get_property('product_detail.plan.plan_cost',0) | to_fixed }} </div>
                  <div class="textInsideCard onRightSide textAlignRight"> {{ response | get_property('product_detail.plan.plan_cost_currency') }}</div>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Billing Cycle</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ response | get_property('product_detail.plan.plan_billing_period') }}</div>
              </div>
            </template>
            <template v-else>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Name</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ data.service }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Allowed</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ response.slot.PlanAllowedProductCount | show_positive_value }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Used</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ response.slot.PlanUsedProductCount | show_positive_value }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Remaining</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ response.slot.PlanRemainingProductCount | show_positive_value }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Price</div>
                
                <div class="d-flex align-items-center">
                  <div class="textInsideCard onRightSide textAlignRight mr-8px">Included</div>
                  <vb-icon icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Billing Cycle</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ response | get_property('product_detail.plan.plan_billing_period') }}</div>
              </div>
            </template>
          </div>
          <template v-else>
            <div class="d-flex align-items-center justify-conent-center text-center headingInsideTable mt-32px mb-16px">
              You don't have any slot available for adding this service. Kindly upgrade your package to get it.
            </div>
          </template>
          <button v-if="slotAvailable" class="IosFullWidthButton forDone mb-16px" @click="proceed()">Proceed</button>
        </template>

      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { VOIP_API } from '@/utils/index'
import { show_positive_value } from '@/filter'
export default {
  name: 'CheckedSlotModal',
  props: {
    modalName: {
      type: String,
      default: 'CheckedSlot',
    },
  },
  data() {
    return {
      response: {
        slot: {},
        product_detail: {},
      },
      api: {
        check: this.$helperFunction.apiInstance()
      },
      data: {
        service: '',
      },
      call_backs: {
        proceed: null,
        cancel: null,
        ok: null,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    slotAvailable(){ return this.response.slot.PlanRemainingProductCount>0 || this.response.slot.PlanAddonRemainingProductCount>0 },
    isAddon(){ return this.response.slot.PlanRemainingProductCount<=0 && this.response.slot.PlanAddonRemainingProductCount>0 },
  },
  filters: {
    show_positive_value,
  },
  methods: {
    onBeforeOpen(event) {
      this.data.service=event?.params?.service
      this.call_backs.proceed=event?.params?.onProceed
      this.call_backs.cancel=event?.params?.onCancel
      this.call_backs.ok=event?.params?.onOK
      this.check()
    },
    onBeforeClose() {
      this.data.service=''
      this.response.slot={}
      this.response.product_detail={}
      this.api.check.reset();
      this.call_backs.proceed=null
      this.call_backs.cancel=null
      this.call_backs.ok=null
    },
    check(){
      let vm = this
      vm.api.check.send=true
      VOIP_API.endpoints.billing.checkservice({
        uid: vm.getCurrentUser.uid,
        accountcode: vm.getCurrentUser.account,
        service: vm.data.service,
        email: vm.getCurrentUser.email,
      })
      .then(({ data: res })=>{
        vm.response.slot=res?.slots
        vm.response.product_detail=res?.product_detail
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.check.send=false
      })
    },
    proceed(){
      this.$emit('proceed');
      this.$modal.hide(this.modalName)
      this.call_backs?.proceed?.()
    },
    cancel(){
      this.$emit('cancel');
      this.$modal.hide(this.modalName)
      this.call_backs?.cancel?.()
    },
    ok(){
      this.$emit('ok');
      this.$modal.hide(this.modalName)
      this.call_backs?.ok?.()
    },
  },
}
</script>

<style>

</style>