var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_vm.getIsMobile ? _c('section', {
    staticClass: "dialer-settings-section locationSettings"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('openSideBar');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center flex-1"
  }, [_vm._v("My tasks")]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion userTeamsFilter optionIsdotted filterWithText ml-auto"
  }, [_c('b-dropdown', {
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "d-flex align-items-center"
        }, [_c('b-icon', {
          staticClass: "fillBlack sm-mar-left",
          attrs: {
            "icon": "three-dots-vertical",
            "variant": "dark",
            "scale": "1.5"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 4006862602)
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.exportTasks();
      }
    }
  }, [_vm._v("Export")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.api.import_tasks.send ? '' : _vm.$refs['upload_file'].click();
      }
    }
  }, [_c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "upload_file",
    attrs: {
      "type": "file",
      "multiple": false,
      "disabled": _vm.api.import_tasks.send,
      "accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    },
    on: {
      "change": function ($event) {
        _vm.importTasks(_vm.getProperty($event, 'target.files[0]'));
      }
    }
  }), _vm.api.import_tasks.upload_progress.is_process ? [_c('b-popover', {
    attrs: {
      "show": "",
      "top": "",
      "target": "popover-button-open"
    }
  }, [_vm._v(" " + _vm._s(`${_vm.api.import_tasks.upload_progress.percentage}%`) + " imported ")])] : [_vm._v("Import")]], 2), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectFilterOfTasks', {
          assignees: _vm.getTasksFilters.assignees,
          statuses: _vm.getTasksFilters.statuses,
          groups: _vm.getTasksFilters.groups,
          priorities: _vm.getTasksFilters.priorities,
          space_ids: _vm.spaceIds
        });
      }
    }
  }, [_vm._v(" Filter ")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        _vm.filter.tasks.assignees = [];
        _vm.filter.tasks.statuses = [];
        _vm.filter.tasks.groups = [];
        _vm.filter.tasks.priorities = [];
        _vm.filter.tasks.filter_id = '';
        _vm.getTasks(true);
      }
    }
  }, [_vm._v(" Clear filters ")])], 1)], 1)], 1), _c('div', {
    staticClass: "users-settings-2nd-section d-flex align-items-center justify-content-between md-mar-top"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder seachBox"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "mobile-searchBar-icon-likeAirCall-icon",
      "width": "12.68px",
      "height": "12.67px"
    }
  }), _c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search tasks"
    },
    on: {
      "input": function ($event) {
        return _vm.getTasksDebounce();
      }
    },
    model: {
      value: _vm.filter.tasks.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tasks, "search", $$v);
      },
      expression: "filter.tasks.search"
    }
  })], 1)]), _vm._l(_vm.taskGroup, function (data) {
    return _c('div', {
      key: data.space.id,
      staticClass: "bill-task-list"
    }, [_c('div', {
      staticClass: "statusDevider d-flex justify-content-between bill-space-container"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [data.space.color ? _c('div', {
      staticClass: "statusColorBox mr-2 ml-0",
      style: `background-color: ${data.space.color}`
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(data.space.name))])])]), _c('div', {
      staticClass: "allTasksList-container bill-task-list-view"
    }, [_vm.isEmpty(data.result) ? _c('AddTask', {
      attrs: {
        "space_id": data.space.id,
        "spaces": _vm.spaces
      },
      on: {
        "created": function ($event) {
          return _vm.getTasksDebounce();
        }
      }
    }) : _vm._e(), _vm._l(data.result, function (column) {
      return _c('div', {
        key: column.filter.key,
        staticClass: "bill-column"
      }, [_c('div', {
        staticClass: "statusDevider d-flex justify-content-between"
      }, [_c('div', {
        staticClass: "d-flex align-items-center"
      }, [_c('span', [_vm._v(_vm._s(column.text))]), column.color ? _c('div', {
        staticClass: "statusColorBox",
        style: `background-color: ${column.color}`
      }) : _vm._e()])]), _c('AddTask', {
        key: `add-task-${column.filter.grouping_by}-${column.filter.key}`,
        attrs: {
          "space_id": data.space.id,
          "spaces": _vm.spaces,
          "id": column.filter.key,
          "id_type": column.filter.grouping_by
        },
        on: {
          "created": function ($event) {
            return _vm.getTasksDebounce();
          }
        }
      }), _vm._l(column.tasks, function (task) {
        return _c('TaskItem', {
          key: task.id,
          attrs: {
            "task": task
          },
          on: {
            "taskClick": function ($event) {
              return _vm.$modal.show(`${_vm._uid}-EditTasks`, {
                id: task.id
              });
            },
            "deleted": function ($event) {
              return _vm.$delete(_vm.response.tasks, $event.task_id);
            }
          }
        });
      })], 2);
    })], 2)]);
  })], 2) : _c('section', {
    staticClass: "dialer-settings-section locationSettings newTasksModule"
  }, [_c('div', {
    staticClass: "taskHub-firstSection-grid"
  }, [_c('h2', {
    staticClass: "dialer-settings-title d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px",
    attrs: {
      "icon": "latest-sideBarOpenIconForTodoInMobileView-icon",
      "width": "19.979px",
      "height": "16px"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('openSideBar');
      }
    }
  }), _vm._v(" My tasks ")], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder ml-auto seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search tasks"
    },
    on: {
      "input": function ($event) {
        return _vm.getTasksDebounce();
      }
    },
    model: {
      value: _vm.filter.tasks.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tasks, "search", $$v);
      },
      expression: "filter.tasks.search"
    }
  })], 1), _c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mb-0"
  }, [_vm._v(" Seamlessly manage tasks, assign call-related actions, and track priorities within our task module. Streamline your workflow for enhanced productivity. ")])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100 mt-45px mb-10px"
  }, [_c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Export',
      expression: "'Export'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "mr-12px cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        return _vm.exportTasks();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "taskHub-export-icon",
      "width": "50px",
      "height": "50px"
    }
  })], 1), _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Import',
      expression: "'Import'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "mr-12px cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        _vm.api.import_tasks.send ? '' : _vm.$refs['upload_file'].click();
      }
    }
  }, [_c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "upload_file",
    attrs: {
      "type": "file",
      "multiple": false,
      "disabled": _vm.api.import_tasks.send,
      "accept": "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet, application/vnd.ms-excel"
    },
    on: {
      "change": function ($event) {
        _vm.importTasks(_vm.getProperty($event, 'target.files[0]'));
      }
    }
  }), _c('vb-icon', {
    attrs: {
      "id": "popover-button-open",
      "icon": "taskHub-import-icon",
      "width": "50px",
      "height": "50px"
    }
  }), _vm.api.import_tasks.upload_progress.is_process ? [_c('b-popover', {
    attrs: {
      "show": "",
      "top": "",
      "target": "popover-button-open"
    }
  }, [_vm._v(" " + _vm._s(`${_vm.api.import_tasks.upload_progress.percentage}%`) + " ")])] : _vm._e()], 2), _vm.conditions.board ? _c('div', [_vm.api.sync_task_updates.send ? _c('b-icon', {
    attrs: {
      "animation": "throb",
      "icon": "circle-fill"
    }
  }) : _vm.forms.sync_task_updates.activity.length ? _c('div', [_vm._v("not synced")]) : _vm.api.sync_task_updates.status == 1 ? _c('div', [_vm._v("synced")]) : _vm.api.sync_task_updates.status == 2 ? _c('div', [_vm._v(_vm._s(_vm.api.sync_task_updates.error_message))]) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "mr-12px cursor_pointer_no_hover compactViewContainer",
    on: {
      "click": function ($event) {
        _vm.conditions.board ? '' : _vm.filter.tasks.group_by = _vm.groupBy.status.value;
        _vm.conditions.board = !_vm.conditions.board;
        _vm.conditions.compact = true;
      }
    }
  }, [_vm.conditions.board ? _c('vb-icon', {
    attrs: {
      "icon": "todo-CanbanView-icon",
      "height": "50px",
      "width": "50px"
    }
  }) : _c('vb-icon', {
    attrs: {
      "icon": "todo-compact-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1), !_vm.conditions.board ? _c('div', {
    staticClass: "mr-12px cursor_pointer_no_hover compactViewContainer",
    on: {
      "click": function ($event) {
        _vm.conditions.compact = !_vm.conditions.compact;
      }
    }
  }, [_vm.conditions.compact ? _c('vb-icon', {
    attrs: {
      "icon": "todo-comapactUPDATED-icon",
      "height": "50px",
      "width": "50px"
    }
  }) : _c('vb-icon', {
    attrs: {
      "icon": "todo-NOTcomapactUPDATED-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion ul-width-fit-content mr-12px"
  }, [_c('vb-select', {
    attrs: {
      "disabled": false,
      "options": _vm.response.filters,
      "textField": "label",
      "valueField": "id",
      "isCheckShow": true,
      "defaultSelectedText": 'Select filter'
    },
    on: {
      "change": function ($event) {
        return _vm.getTasks(true);
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (_ref) {
        var option = _ref.option;
        return [_c('div', [_vm.filter.tasks.filter_id == option.id ? _c('b-icon', {
          attrs: {
            "icon": "check",
            "variant": "success"
          }
        }) : _vm._e(), _vm._v(" " + _vm._s(option.label) + " ")], 1), _c('div', {
          staticClass: "d-flex"
        }, [_vm.api.delete_filter.send.includes(option.id) || _vm.api.toggle_default_filter.send.includes(option.id) ? _c('b-spinner', {
          attrs: {
            "type": "grow",
            "label": "Spinning"
          }
        }) : [_c('b-button', {
          staticClass: "p-0 ml-8px",
          attrs: {
            "disabled": option.status == 1,
            "variant": "link"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.markDefaultFilter(option);
            }
          }
        }, [option.status == 1 ? _c('b-icon', {
          attrs: {
            "icon": "circle-fill",
            "variant": "success"
          }
        }) : _c('b-icon', {
          attrs: {
            "icon": "circle"
          }
        })], 1), _c('b-button', {
          staticClass: "p-0 ml-8px",
          attrs: {
            "disabled": _vm.filter.tasks.filter_id == option.id,
            "variant": "link"
          },
          on: {
            "click": function ($event) {
              $event.stopPropagation();
              return _vm.deleteFilter(option);
            }
          }
        }, [_c('b-icon', {
          attrs: {
            "icon": "trash-fill",
            "variant": "danger"
          }
        })], 1)]], 2)];
      }
    }]),
    model: {
      value: _vm.filter.tasks.filter_id,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tasks, "filter_id", $$v);
      },
      expression: "filter.tasks.filter_id"
    }
  })], 1), _c('div', {
    staticClass: "mr-12px cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectFilterOfTasks', {
          assignees: _vm.getTasksFilters.assignees,
          statuses: _vm.getTasksFilters.statuses,
          groups: _vm.getTasksFilters.groups,
          priorities: _vm.getTasksFilters.priorities,
          space_ids: _vm.spaceIds
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "todo-filter-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1), _c('div', {
    staticClass: "mr-12px cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        _vm.filter.tasks.assignees = [];
        _vm.filter.tasks.statuses = [];
        _vm.filter.tasks.groups = [];
        _vm.filter.tasks.priorities = [];
        _vm.filter.tasks.filter_id = '';
        _vm.getTasks(true);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "clearFilters-icon",
      "height": "50px",
      "width": "50px"
    }
  })], 1), _c('b-dropdown', {
    staticClass: "filterDropdownForTaskHub",
    attrs: {
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-icon', {
          staticClass: "funnel-icon",
          attrs: {
            "icon": "callInfoModal-funnel-icon",
            "height": "16px",
            "width": "16px"
          }
        }), _vm._v(" Group by: "), _c('span', [_vm._v(_vm._s(_vm.filter.tasks.group_by))])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-form', {
    staticClass: "inputItemContainer",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-100"
  }, [_c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('vb-icon', {
    staticClass: "greySerchIcon",
    attrs: {
      "icon": "callActivity-search",
      "height": "18px",
      "width": "17.75px"
    }
  }), _c('b-input', {
    attrs: {
      "placeholder": "Search filters..."
    },
    model: {
      value: _vm.filter.group_by.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.group_by, "search", $$v);
      },
      expression: "filter.group_by.search"
    }
  })], 1)])]), _vm._l(_vm.filterGroupBy, function (group) {
    return _c('b-dropdown-item', {
      key: group.value,
      class: `${_vm.filter.tasks.group_by == group.value ? 'active' : ''}`,
      on: {
        "click": function ($event) {
          _vm.filter.tasks.group_by = group.value;
          _vm.filter.group_by.search = '';
        }
      }
    }, [_vm._v(" " + _vm._s(group.text) + " "), _vm.filter.tasks.group_by == group.value ? _c('b-icon', {
      staticClass: "darker-chevron-down withoutPath",
      attrs: {
        "icon": "check-lg"
      }
    }) : _vm._e()], 1);
  })], 2)], 1), _c('div', {
    staticClass: "bill-task-container"
  }, [_c('div', {
    class: `${_vm.conditions.compact ? 'TODO-compactView' : ''}`
  }, _vm._l(_vm.taskGroup, function (data) {
    return _c('div', {
      key: data.space.id,
      staticClass: "bill-task-list"
    }, [_c('div', {
      staticClass: "statusDevider d-flex justify-content-between bill-space-container"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [data.space.color ? _c('div', {
      staticClass: "statusColorBox mr-2 ml-0",
      style: `background-color: ${data.space.color}`
    }) : _vm._e(), _c('span', [_vm._v(_vm._s(data.space.name))])]), _c('b-button', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: `collapse-${_vm._uid}-${data.space.id}`,
        expression: "`collapse-${_uid}-${data.space.id}`"
      }],
      staticClass: "bill-button",
      attrs: {
        "variant": "link"
      }
    }, [_c('span', {
      staticClass: "bill-down"
    }, [_c('b-icon', {
      attrs: {
        "icon": "chevron-down"
      }
    })], 1), _c('span', {
      staticClass: "bill-up"
    }, [_c('b-icon', {
      attrs: {
        "icon": "chevron-up"
      }
    })], 1)])], 1), _c('b-collapse', {
      attrs: {
        "visible": "",
        "id": `collapse-${_vm._uid}-${data.space.id}`
      }
    }, [_vm.conditions.board ? _c('div', {
      staticClass: "bill-board"
    }, _vm._l(data.result, function (column) {
      return _c('div', {
        key: column.filter.key,
        staticClass: "bill-bill-board-group"
      }, [_c('div', {
        staticClass: "statusDevider"
      }, [column.color ? _c('div', {
        staticClass: "statusColorBox mr-2",
        style: `background-color: ${column.color};`
      }) : _vm._e(), _c('span', [_vm._v(_vm._s(column.text) + " (" + _vm._s(column.tasks.length) + " tasks)")])]), _vm.flag != 'archived' ? _c('AddTask', {
        key: `add-task-${column.filter.grouping_by}-${column.filter.key}`,
        attrs: {
          "space_id": data.space.id,
          "spaces": _vm.spaces,
          "id": column.filter.key,
          "id_type": column.filter.grouping_by
        },
        on: {
          "created": function ($event) {
            return _vm.getTasksDebounce();
          }
        }
      }) : _vm._e(), _c('div', {
        staticClass: "bill-board-group-container",
        on: {
          "dragenter": function ($event) {
            _vm.$refs[`column-${data.space.id}-${column.filter.key}`][0].style.display = 'flex';
          }
        }
      }, [_c('div', {
        ref: `column-${data.space.id}-${column.filter.key}`,
        refInFor: true,
        staticClass: "drop-here",
        on: {
          "dragover": function ($event) {
            $event.preventDefault();
            !_vm.response.tasks[_vm.drag.task_id] || _vm.response.tasks[_vm.drag.task_id].space_id != column.space.id ? $event.dataTransfer.dropEffect = 'none' : '';
          },
          "dragleave": function ($event) {
            _vm.$refs[`column-${data.space.id}-${column.filter.key}`][0].style.display = 'none';
          },
          "drop": function ($event) {
            _vm.$refs[`column-${data.space.id}-${column.filter.key}`][0].style.display = 'none';
            _vm.drop($event, column);
          }
        }
      }, [_vm._v(" Drop Here ")]), column.tasks.length == 0 ? _c('div', {
        staticClass: "d-flex justify-content-center"
      }, [_vm._v("No Tasks")]) : _vm._e(), _vm._l(column.tasks, function (task) {
        return _c('TaskItem', {
          key: task.id,
          attrs: {
            "id": `task-id-${task.id}`,
            "isBoardView": true,
            "task": task
          },
          on: {
            "dragstart": function ($event) {
              _vm.drag.task_id = task.id;
              $event.dataTransfer.dropEffect = 'move';
            },
            "dragend": function ($event) {
              _vm.drag.task_id = '';
            },
            "taskClick": function ($event) {
              return _vm.$modal.show(`${_vm._uid}-EditTasks`, {
                id: task.id
              });
            },
            "deleted": function ($event) {
              return _vm.$delete(_vm.response.tasks, $event.task_id);
            }
          }
        });
      })], 2)], 1);
    }), 0) : [_c('div', {
      staticClass: "TODO-taksTable-titleRow mt-38px"
    }, [_c('div', {
      staticClass: "TODO-taksTable-titleRow-column first"
    }, [_c('div', {
      staticClass: "TODO-taksTable-titleRow-column-text"
    }, [_vm._v("TASK TITLE")])]), _c('div', {
      staticClass: "TODO-taksTable-titleRow-column second"
    }, [_c('div', {
      staticClass: "TODO-taksTable-titleRow-column-text"
    }, [_vm._v("ASSIGNEES")])]), _c('div', {
      staticClass: "TODO-taksTable-titleRow-column third"
    }, [_c('div', {
      staticClass: "TODO-taksTable-titleRow-column-text"
    }, [_vm._v("STATUS")])]), _c('div', {
      staticClass: "TODO-taksTable-titleRow-column fourth"
    })]), _c('div', {
      staticClass: "allTasksList-container bill-task-list-view"
    }, [_vm.isEmpty(data.result) && _vm.flag != 'archived' ? _c('AddTask', {
      attrs: {
        "space_id": data.space.id,
        "spaces": _vm.spaces
      },
      on: {
        "created": function ($event) {
          return _vm.getTasksDebounce();
        }
      }
    }) : _vm._e(), _vm._l(data.result, function (column) {
      return _c('div', {
        key: column.filter.key,
        staticClass: "bill-column"
      }, [_c('div', {
        staticClass: "statusDevider d-flex justify-content-between"
      }, [_c('div', {
        staticClass: "d-flex align-items-center"
      }, [_c('span', [_vm._v(_vm._s(column.text))]), column.color ? _c('div', {
        staticClass: "statusColorBox",
        style: `background-color: ${column.color}`
      }) : _vm._e()]), _c('b-button', {
        directives: [{
          name: "b-toggle",
          rawName: "v-b-toggle",
          value: `collapse-${_vm._uid}-${data.space.id}-${column.filter.key}`,
          expression: "`collapse-${_uid}-${data.space.id}-${column.filter.key}`"
        }],
        staticClass: "bill-button",
        attrs: {
          "variant": "link"
        }
      }, [_c('span', {
        staticClass: "bill-down"
      }, [_c('b-icon', {
        attrs: {
          "icon": "chevron-down"
        }
      })], 1), _c('span', {
        staticClass: "bill-up"
      }, [_c('b-icon', {
        attrs: {
          "icon": "chevron-up"
        }
      })], 1)])], 1), _c('b-collapse', {
        attrs: {
          "visible": "",
          "id": `collapse-${_vm._uid}-${data.space.id}-${column.filter.key}`
        }
      }, [_vm.flag != 'archived' ? _c('AddTask', {
        key: `add-task-${column.filter.grouping_by}-${column.filter.key}`,
        attrs: {
          "space_id": data.space.id,
          "spaces": _vm.spaces,
          "id": column.filter.key,
          "id_type": column.filter.grouping_by
        },
        on: {
          "created": function ($event) {
            return _vm.getTasksDebounce();
          }
        }
      }) : _vm._e(), _vm._l(column.tasks, function (task) {
        return _c('TaskItem', {
          key: task.id,
          attrs: {
            "task": task
          },
          on: {
            "taskClick": function ($event) {
              return _vm.$modal.show(`${_vm._uid}-EditTasks`, {
                id: task.id
              });
            },
            "deleted": function ($event) {
              return _vm.$delete(_vm.response.tasks, $event.task_id);
            }
          }
        });
      })], 2)], 1);
    }), _c('div', {
      staticStyle: {
        "height": "50px"
      }
    })], 2)]], 2)], 1);
  }), 0)])]), _c('EditTasksModal', {
    attrs: {
      "modalName": `${_vm._uid}-EditTasks`
    },
    on: {
      "update-list": function ($event) {
        return _vm.getTasks(true);
      }
    }
  }), _c('SelectFilterOfTasksModal', {
    on: {
      "apply-filter": function ($event) {
        _vm.filter.tasks.assignees = $event.assignees;
        _vm.filter.tasks.statuses = $event.statuses;
        _vm.filter.tasks.groups = $event.groups;
        _vm.filter.tasks.priorities = $event.priorities;
        _vm.filter.tasks.filter_id = $event.filter_id;
        _vm.getTasks(true);
      },
      "added": function ($event) {
        return _vm.getFilters(_vm.filter.tasks.filter_id);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }