<template>
  <div>
    <div class="mainHeading withButton">
      Time Off Policies
      <button @click="$modal.show('CreateNewPolicy')" class="dialer-button dialer-button-primary mr-0 withIcon">
        <!-- <vb-icon icon="pencil-hrmIcon" class="mr-16px fillWhite" height="14px" width="14px" /> -->
        <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
        Add new policy
      </button>
    </div>
    <div class="mainDescription mb-0">
      Create and manage time-off policies.
    </div>
    <div class="mainDescription mb-4">
      You can create as many policies as you need (e.g. "Work from home") and edit the default ones
    </div>

    <div class="settings-TimeOffPoliciesTable mt-16px">
      <div class="HrmTableHead-container">
        <div class="HrmTableHead one">Name</div>
        <div class="HrmTableHead two">Default allowance</div>
        <div class="HrmTableHead three">Accrual type</div>
        <div class="HrmTableHead four">Action</div>
      </div>
      <vb-table class="HrmTable"
      >
        <!-- <template slot="head er">
          <tr></tr>
        </template> -->
        <template #body>
          <tr class="dialer-row-select" >
            <td class="dialer-row-title one">
              <div class="circleWithText">
                <div class="circleContainer" style="background-color:#D5761B"></div>
                <div class="circleWithText-text" style="color:#D5761B">Sickday</div>
              </div>
            </td>
            <td class="dialer-col-right two">
              <div class="td-heading">20 days</div>
              <div class="td-subText">0 days max carryover</div>
            </td>
            <td class="dialer-col-right three">
              Accrue annually
            </td>
            <td class="dialer-col-right four">
              <b-dropdown no-caret right class="ActionsDD">
                <template #button-content>
                  <div class="button-content">
                    <b-icon class="downIcon" icon="three-dots-vertical" />
                  </div>
                </template>
                <b-dropdown-item >
                  Actions
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    <CreateNewPolicy />
  </div>
</template>

<script>
import CreateNewPolicy from '@/components/Modals/HRM/settings/createNewPolicy.vue'
export default {
  name: 'TimeOffPolicies',
  components:{
    CreateNewPolicy
  },
}
</script>

<style>

</style>