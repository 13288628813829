var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.activeTab === 'pamarly-home' ? _c('Home', {
    ref: "pamarly-home"
  }) : _vm._e(), _vm.activeTab === 'pamarly-teams' ? _c('Teams', {
    ref: "pamarly-teams"
  }) : _vm._e(), _vm.activeTab === 'pamarly-resources' ? _c('Resources', {
    ref: "pamarly-resources"
  }) : _vm._e(), _vm.activeTab === 'pamarly-permissions' ? _c('Permissions', {
    ref: "pamarly-permissions"
  }) : _vm._e(), _vm.activeTab === 'pamarly-folder' ? _c('Folder', {
    ref: "pamarly-folder"
  }) : _vm._e(), _vm.activeTab === 'pamarly-deleted-resources' ? _c('DeletedResources', {
    ref: "pamarly-deleted-resources"
  }) : _vm._e(), _vm.activeTab === 'pamarly-history' ? _c('History', {
    ref: "pamarly-history"
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }