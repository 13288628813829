var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500",
    attrs: {
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Edit user details")]), _c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('form', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateInfo();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.update_info.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.update_info.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("First name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_info.first_name,
      expression: "forms.update_info.first_name"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "onkeypress": "return /^[A-Za-z]+$/.test(event.key)",
      "disabled": _vm.api.update_info.send,
      "maxlength": _vm.$v.forms.update_info.first_name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_info.first_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_info, "first_name", $event.target.value);
      }
    }
  }), _vm.forms.update_info.submitted && _vm.$v.forms.update_info.first_name.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.update_info.first_name.required ? _c('span', [_vm._v("First name is required")]) : !_vm.$v.forms.update_info.first_name.alpha ? _c('span', [_vm._v("First name should be alphabet")]) : !_vm.$v.forms.update_info.first_name.maxLength ? _c('span', [_vm._v("First name can be maximum " + _vm._s(_vm.$v.forms.update_info.first_name.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-20px"
  }, [_c('label', [_vm._v("Last name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_info.last_name,
      expression: "forms.update_info.last_name"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "onkeypress": "return /^[A-Za-z]+$/.test(event.key)",
      "disabled": _vm.api.update_info.send,
      "maxlength": _vm.$v.forms.update_info.last_name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_info.last_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_info, "last_name", $event.target.value);
      }
    }
  }), _vm.forms.update_info.submitted && _vm.$v.forms.update_info.last_name.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.update_info.last_name.alpha ? _c('span', [_vm._v("Last name should be alphabet")]) : !_vm.$v.forms.update_info.last_name.maxLength ? _c('span', [_vm._v("Last name can be maximum " + _vm._s(_vm.$v.forms.update_info.last_name.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm._e()]), _vm.userType == 'user' ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-20px"
  }, [_c('label', [_vm._v("Username")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_info.username,
      expression: "forms.update_info.username"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.update_info.send,
      "maxlength": _vm.$v.forms.update_info.username.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_info.username
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_info, "username", $event.target.value);
      }
    }
  }), _vm.forms.update_info.submitted && _vm.$v.forms.update_info.username.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.update_info.username.required ? _c('span', [_vm._v("Username is required")]) : !_vm.$v.forms.update_info.username.alphaStart ? _c('span', [_vm._v("Username should start with an alphabet")]) : !_vm.$v.forms.update_info.username.alphaNum ? _c('span', [_vm._v("Username should be alphabet or numeric")]) : !_vm.$v.forms.update_info.username.minLength ? _c('span', [_vm._v("Username should be minimum " + _vm._s(_vm.$v.forms.update_info.username.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_info.username.maxLength ? _c('span', [_vm._v("Username can be maximum " + _vm._s(_vm.$v.forms.update_info.username.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.userType == 'user' ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withTextOnRightSideOnly w-100 mt-20px"
  }, [_c('label', [_vm._v("Email for notification")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_info.email_for_notification,
      expression: "forms.update_info.email_for_notification"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.update_info.send
    },
    domProps: {
      "value": _vm.forms.update_info.email_for_notification
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_info, "email_for_notification", $event.target.value);
      }
    }
  }), _vm.forms.update_info.submitted && _vm.$v.forms.update_info.email_for_notification.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.update_info.email_for_notification.email ? _c('span', [_vm._v("Email for Notification should be valid email")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.userType == 'main' ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withTextOnRightSideOnly w-100 mt-20px"
  }, [_c('label', [_vm._v("Email")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "disabled": "",
      "type": "text"
    },
    domProps: {
      "value": _vm.userEmail
    }
  }), _c('div', {
    staticClass: "withTextOnRightSideOnly-text",
    on: {
      "click": function ($event) {
        _vm.api.update_info.send ? '' : _vm.$modal.show(`${_vm.modalName}-ChangeEmail`, {
          accountcode: _vm.data.accountcode
        });
      }
    }
  }, [_vm._v(" Change email ")])])]) : _vm._e()], 1), _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.update_info.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.updateInfo();
      }
    }
  }, [_vm._v(" Update ")])])])]), _c('ChangeEmailModal', {
    attrs: {
      "modalName": `${_vm.modalName}-ChangeEmail`
    },
    on: {
      "updated": function ($event) {
        return _vm.$emit('updated');
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }