
import { VOIP_API } from "../index"

export const TOPUP_API_ENDPOINTS = {
  add(data={}){
    return VOIP_API.axios.voip.request({
      url: 'portal-proxy',
      method: 'POST',
      data: {
        type: 'payment',
        action: 'user_topup',
        ...data,
      },
    })
  },
  add2(data={}){
    return VOIP_API.axios.voip.request({
      url: 'portal-proxy',
      method: 'POST',
      data: {
        type: 'billing',
        action: 'add_topup_product',
        ...data,
      },
    })
  },
  getproducts(data={}){
    return VOIP_API.axios.voip.request({
      url: 'portal-proxy',
      method: 'POST',
      data: {
        type: 'billing',
        action: 'topup_products',
        ...data,
      },
    })
  },
  gethistory(data={}){
    return VOIP_API.axios.voip.request({
      url: 'portal-proxy',
      method: 'POST',
      data: {
        type: 'billing',
        action: 'topup_history',
        ...data,
      },
    })
  },
  getsetting(data={}){
    return VOIP_API.axios.voip.request({
      url: 'portal-proxy',
      method: 'POST',
      data: {
        type: 'billing',
        action: 'get_topup_setting',
        ...data,
      },
    })
  },
  updatesetting(data={}){
    return VOIP_API.axios.voip.request({
      url: 'portal-proxy',
      method: 'POST',
      data: {
        type: 'billing',
        action: 'update_topup_setting',
        ...data,
      },
    })
  },
}