var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation center-zoomIn-popup mediaFullscreen-modal",
    attrs: {
      "name": _vm.modalName,
      "height": "100%",
      "width": "100%"
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "mediaFullscreen-container"
  }, [_c('div', {
    staticClass: "mediaFullscreen-header"
  }, [_c('div', {
    staticStyle: {
      "color": "white",
      "width": "calc(100% - 45px)",
      "text-align": "center"
    }
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.user, 'user.display_name')))]), !_vm.is_action ? _c('div', {
    on: {
      "click": function ($event) {
        return _vm.downloadFile();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "whiteDownloadIcon",
    attrs: {
      "height": "15px",
      "width": "15px",
      "icon": "callAnalytics-reporting-download-icon"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "close-popup cursor_pointer ml-2",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "font-scale": "2"
    }
  })], 1)]), _c('div', {
    staticClass: "mediaFullscreen-body"
  }, [_vm.is_action ? [_vm.actionFileNavigation.previous_id ? _c('div', {
    staticClass: "pre-btn",
    on: {
      "click": function ($event) {
        _vm.file_id = _vm.actionFileNavigation.previous_id;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-left",
      "font-scale": "4"
    }
  })], 1) : _vm._e(), _vm.getProperty(_vm.selectedActionFile, 'file_type') === 'image' ? _c('div', {
    staticClass: "media-itself",
    style: `background-image:url(${_vm.getProperty(_vm.selectedActionFile, 'src')})`
  }) : _c('div', {
    staticClass: "media-itself"
  }, [_c('video', {
    attrs: {
      "width": "320",
      "height": "240",
      "controls": ""
    }
  }, [_c('source', {
    attrs: {
      "src": _vm.getProperty(_vm.selectedActionFile, 'src')
    }
  })])]), _vm.actionFileNavigation.next_id ? _c('div', {
    staticClass: "next-btn",
    on: {
      "click": function ($event) {
        _vm.file_id = _vm.actionFileNavigation.next_id;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-right",
      "font-scale": "4"
    }
  })], 1) : _vm._e()] : [_vm.fileNavigation.previous_id ? _c('div', {
    staticClass: "pre-btn",
    on: {
      "click": function ($event) {
        _vm.file_id = _vm.fileNavigation.previous_id;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-left",
      "font-scale": "4"
    }
  })], 1) : _vm._e(), _vm.getProperty(_vm.selectedFile, 'extra_data.file_type') === 'image' ? _c('div', {
    staticClass: "media-itself",
    style: `background-image:url(${_vm.getProperty(_vm.selectedFile, 'extra_data.src')})`
  }) : _c('div', {
    staticClass: "media-itself"
  }, [_c('video', {
    attrs: {
      "width": "320",
      "height": "240",
      "controls": ""
    }
  }, [_c('source', {
    attrs: {
      "src": _vm.getProperty(_vm.selectedFile, 'extra_data.src')
    }
  })])]), _vm.fileNavigation.next_id ? _c('div', {
    staticClass: "next-btn",
    on: {
      "click": function ($event) {
        _vm.file_id = _vm.fileNavigation.next_id;
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-right",
      "font-scale": "4"
    }
  })], 1) : _vm._e()]], 2), _c('div', {
    staticClass: "mediaFullscreen-footer"
  }, [_c('div', {
    staticClass: "mediaFullscreen-slider"
  }, [_vm.is_action ? _vm._l(_vm.filteredActionFiles, function (file) {
    return _c('div', {
      key: file.uid,
      staticClass: "media-container",
      class: {
        selected: _vm.file_id == file.uid
      }
    }, [_vm.getProperty(file, 'file_type') == 'video' ? _c('div', {
      staticClass: "media-itself",
      on: {
        "click": function ($event) {
          _vm.file_id = file.uid;
        }
      }
    }, [_c('VueVideoThumbnail', {
      attrs: {
        "video-src": _vm.getProperty(file, 'src'),
        "snapshot-at-duration-percent": parseInt(0, 10),
        "width": 500,
        "height": 300,
        "chunks-quantity": 4
      }
    })], 1) : _c('div', {
      staticClass: "media-itself",
      style: `background-image:url(${_vm.getProperty(file, 'src')})`,
      on: {
        "click": function ($event) {
          _vm.file_id = file.uid;
        }
      }
    })]);
  }) : _vm._l(_vm.filteredFiles, function (file) {
    return _c('div', {
      key: file.id,
      staticClass: "media-container",
      class: {
        selected: _vm.file_id == file.id
      }
    }, [_vm.getProperty(file, 'extra_data.file_type') == 'video' ? _c('div', {
      staticClass: "media-itself",
      on: {
        "click": function ($event) {
          _vm.file_id = file.id;
        }
      }
    }, [_c('VueVideoThumbnail', {
      attrs: {
        "video-src": _vm.getProperty(file, 'extra_data.src'),
        "snapshot-at-duration-percent": parseInt(0, 10),
        "width": 500,
        "height": 300,
        "chunks-quantity": 4
      }
    })], 1) : _c('div', {
      staticClass: "media-itself",
      style: `background-image:url(${_vm.getProperty(file, 'extra_data.src')})`,
      on: {
        "click": function ($event) {
          _vm.file_id = file.id;
        }
      }
    })]);
  })], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }