<template>
  <div>
    <!-- feedback -->
    <div v-if="status.slug == 'closed'" class="ticketFeedback-container">
      <div class="ticketFeedback-heading">Please rate your experience with us</div>
      <div class="ticketFeedback-items">
        <div 
          class="ticketFeedback-item"
          :class="{
            'active' : selected.feedback == feedback.value
          }" 
          v-for="feedback in feedbacks" 
          :key="feedback.value" 
          @click="updateFeedback(feedback.value);"
        >
          <div class="ticketFeedback-item-emoji">{{feedback.emoji}}</div>
          <div class="ticketFeedback-item-text">{{feedback.text}}</div>
        </div>
      </div>
    </div>
    <!-- comment -->
    <div class="ticketConversation-actions">
      <template v-if="status.slug == 'active'">
        <div class="ticketActions-heading">To update this ticket with more information please enter the details below:-</div>
        <div class="ticketActions-textArea">
          <textarea v-model="forms.create_comment.comment" rows="7" placeholder="Write your message"></textarea>
          <p v-if="$v.forms.create_comment.$error" class="errorRed">
            <span v-if="!$v.forms.create_comment.required">Message is required</span>
          </p>
        </div>
      </template>
      <div class="d-flex mt-16px">
        <button v-if="status.slug == 'active'" class="newButton mr-20px" @click="createComment()">
          <vb-icon icon="squared-updateTicket-icon" height="38px" width="38px" />
          <span class="newButton-textPart d-flex justify-content-center position-relative">
            Update ticket
          </span>
        </button>
        <a class="newCloseButton" @click="toggleStatus()">
          <template v-if="status.slug == 'closed'">
            <vb-icon icon="squared-openTicket-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Reopen ticket</span>
          </template>
          <template v-else>
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close ticket</span>
          </template>
        </a>
      </div>
    </div>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import required from 'vuelidate/lib/validators/required';
const feedbacks = [
  {
    emoji: '😭',
    text: 'Very poor',
    value: 1
  },
  {
    emoji: '😞',
    text: 'Poor',
    value: 2
  },
  // quite_poor: {
  //   emoji: '😔',
  //   text: 'Quite Poor',
  //   value: 'quite poor'
  // },
  {
    emoji: '🤔',
    text: 'Average',
    value: 3
  },
  // {
  //   emoji: '🙂',
  //   text: 'Quite Good',
  //   value: 'quite good'
  // },
  {
    emoji: '😊',
    text: 'Good',
    value: 4
  },
  {
    emoji: '🤩',
    text: 'Excellent',
    value: 5
  },
]
export default {
  name: 'TicketCommentAction',
  props: {
    ticketId: {
      type: [String,Number],
      default: '',
    },
    status: {
      type: Object,
      default: ()=>({})
    },
    feedback: {
      type: Object,
      default: ()=>({})
    }
  },
  data() {
    return {
      forms: {
        create_comment: this.$helperFunction.formInstance({
          data: {
            comment: '',
          }
        }),
      },
      api: {
        create_comment: this.$helperFunction.apiInstance(),
        toggle_status: this.$helperFunction.apiInstance(),
        update_feedback: this.$helperFunction.apiInstance(),
      },
      selected: {
        feedback: '',
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    feedbacks(){ return feedbacks },
  },
  validations: {
    forms: {
      create_comment: {
        comment: {
          required: required,
        },
      },
    },
  },
  methods: {
    async createComment() {
      if(this.api.create_comment.send) return;
      this.$v.forms.create_comment.$touch();
      if(this.$v.forms.create_comment.$invalid) return;
      try {
        this.api.create_comment.send=true
        await VOIP_API.endpoints.tickets.createTicket({
          ticket_id: this.ticketId,
          comment: this.forms.create_comment.comment,
        })
        this.forms.create_comment.reset();
        this.$emit('comment-created')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.create_comment.send=false
        this.$v.forms.create_comment.$reset()
      }
    },
    async toggleStatus(){
      if(this.api.toggle_status.send) return;
      try {
        this.api.toggle_status.send=true
        await VOIP_API.endpoints.tickets.toggleStatus(this.ticketId,{
          ticket: this.ticketId,
        })
        this.$emit('status-updated');
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.toggle_status.send=false
      }
    },
    async updateFeedback(feedback){
      if(this.api.update_feedback.send) return;
      try {
        this.api.update_feedback.send=true
        await VOIP_API.endpoints.tickets.updateFeedback(this.ticketId,{
          ticket: this.ticketId,
          feedback: feedback,
        })
        this.selected.feedback=feedback
        this.$emit('feedback-updated');
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_feedback.send=false
      }
    },
  },
  mounted(){
    this.selected.feedback=this.feedback?.feedback ?? ''
  },
}
</script>

<style>

</style>