var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 AddManualNumberModal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.add.apply(null, arguments);
      }
    }
  }, [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Add Manual Number")]), _vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "dialer-edit-actions w-100 d-flex justify-content-end mt-2"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api_sent.add
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.add ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Add Manual Number")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "dialer-button dialer-button-delete",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api_sent.add
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.add ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('div', {
    staticClass: "dialer-team-name mb-4"
  }, [_c('div', {
    staticClass: "dialer-input-field"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('label', [_vm._v("Number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_manual_number.did,
      expression: "forms.add_manual_number.did"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Number",
      "disabled": _vm.api_sent.add,
      "min": "1",
      "type": "number",
      "onkeypress": "return /([0-9])/i.test(event.key)"
    },
    domProps: {
      "value": _vm.forms.add_manual_number.did
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_manual_number, "did", $event.target.value);
      }
    }
  })]), _vm.is_submitted.add ? [!_vm.$v.forms.add_manual_number.did.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Number is required")])]) : _vm._e(), !_vm.$v.forms.add_manual_number.did.minValue ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Number is minimum 1")])]) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "dialer-input-field"
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('label', [_vm._v("Description (Optional)")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_manual_number.label,
      expression: "forms.add_manual_number.label"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter Description",
      "disabled": _vm.api_sent.add
    },
    domProps: {
      "value": _vm.forms.add_manual_number.label
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_manual_number, "label", $event.target.value);
      }
    }
  })])])])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }