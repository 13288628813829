var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer-addMemberModal editChanelChatInfo",
    attrs: {
      "width": "300",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Edit group info")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "edit-channel-header-popup p-2 mt-3"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.onSubmit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('b-form-group', {
    attrs: {
      "id": "input-group-1"
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Group name:")]), _c('b-form-input', {
    attrs: {
      "type": "text",
      "placeholder": "Group name",
      "required": ""
    },
    model: {
      value: _vm.name,
      callback: function ($$v) {
        _vm.name = $$v;
      },
      expression: "name"
    }
  })], 1)]), _c('b-form-group', {
    attrs: {
      "id": "input-group-2"
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Group description:")]), _c('b-form-textarea', {
    staticClass: "line-height-1-7-imp py-2",
    attrs: {
      "placeholder": "Group description",
      "rows": "5",
      "onkeypress": "return event.target.value.length+1<=200"
    },
    model: {
      value: _vm.purpose,
      callback: function ($$v) {
        _vm.purpose = $$v;
      },
      expression: "purpose"
    }
  })], 1)])], 1), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-20px"
  }, [_c('button', {
    staticClass: "ml-2 newDoneButton",
    attrs: {
      "type": "submit",
      "variant": "primary"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm._v(" Save ")])], 1)])])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }