var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticStyle: {
      "height": "100vh",
      "width": "100vw"
    }
  }, [_c('loaderMain')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }