<template>
  <div class="dialer-microphone-volumeSetting">
    
    <span class="volumeControl-icon" :class="{'volumeControl-icon-1': microphone_level >= 5}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-2': microphone_level >= 10}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-3': microphone_level >= 15}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-4': microphone_level >= 20}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-5': microphone_level >= 25}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-6': microphone_level >= 30}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-7': microphone_level >= 35}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-8': microphone_level >= 40}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-9': microphone_level >= 45}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-10': microphone_level >= 50}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-11': microphone_level >= 55}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-12': microphone_level >= 60}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-13': microphone_level >= 65}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-14': microphone_level >= 70}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-15': microphone_level >= 75}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-16': microphone_level >= 80}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-17': microphone_level >= 85}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-18': microphone_level >= 90}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-19': microphone_level >= 95}" />
    <span class="volumeControl-icon" :class="{'volumeControl-icon-20': microphone_level >= 100}" />
  </div>
</template>

<script>
export default {
  name: 'MicroPhoneTesting',
  props: {
    deviceId: {
      type: String,
      default: 'default',
    },
  },
  data(){
    return {
      streams: null,
      microphone_level: 100,
    }
  },
  watch: {
    deviceId: {
      immediate: true,
      handler(id,old_id){
        if(!id) return;
        if(old_id){
          this.stopStreams()
        }
        let vm = this
        navigator.getUserMedia({
          audio: {
            deviceId: {
              exact: id
            }
          },
        },(streams)=>{
          if(vm._isDestroyed){
            streams?.getTracks?.()?.forEach?.(track=>track.stop())
            return;
          }
          vm.streams=streams
          window.persistAudioStream = vm.streams;
          var audioContent = new AudioContext();
          var audioStream = audioContent.createMediaStreamSource(vm.streams);
          var analyser = audioContent.createAnalyser();
          audioStream.connect(analyser);
          analyser.fftSize = 1024;
          var frequencyArray = new Uint8Array(analyser.frequencyBinCount);
          var doDraw = function () {
            requestAnimationFrame(doDraw);
            analyser.getByteFrequencyData(frequencyArray);
            var new_frequency_array = Object.values(frequencyArray).splice(0,255)
            vm.microphone_level = Math.ceil((new_frequency_array.reduce(i=>Math.floor(i)-(Math.floor(i)%5))/new_frequency_array.length)*100)
          };
          doDraw();
        });
      }
    },
  },
  methods: {
    stopStreams(){
      this.streams?.getTracks?.()?.forEach?.(track=>track.stop())
      // this.streams=null
    },
  },
  beforeDestroy(){
    this.stopStreams()
  },
}
</script>

<style lang="scss">
.dialer-microphone-volumeSetting {
  // margin: $dialer-default-padding * 2 -
  //   $dialer-default-padding/2 0px
  //   $dialer-default-padding/2;
  width: 100%;
  display: flex;
  justify-content: space-between;
  .volumeControl-icon {
    display: block;
    width: 12px;
    height: 12px;
    @include border-radius(50%);
    background-color: $dialer-soundSettingDevice-microphone-volumeSetting-Color;
    // &-1 {
    //   background-color: rgba(0, 93, 255, 1.00) !important;
    // }
    // &-2 {
    //   background-color: rgba(0, 93, 255, 0.95) !important;
    // }
    // &-3 {
    //   background-color: rgba(0, 93, 255, 0.90) !important;
    // }
    // &-4 {
    //   background-color: rgba(0, 93, 255, 0.85) !important;
    // }
    // &-5 {
    //   background-color: rgba(0, 93, 255, 0.80) !important;
    // }
    // &-6 {
    //   background-color: rgba(0, 93, 255, 0.75) !important;
    // }
    // &-7 {
    //   background-color: rgba(0, 93, 255, 0.70) !important;
    // }
    // &-8 {
    //   background-color: rgba(0, 93, 255, 0.65) !important;
    // }
    // &-9 {
    //   background-color: rgba(0, 93, 255, 0.60) !important;
    // }
    // &-10 {
    //   background-color: rgba(0, 93, 255, 0.55) !important;
    // }
    // &-11 {
    //   background-color: rgba(0, 93, 255, 0.50) !important;
    // }
    // &-12 {
    //   background-color: rgba(0, 93, 255, 0.45) !important;
    // }
    // &-13 {
    //   background-color: rgba(0, 93, 255, 0.40) !important;
    // }
    // &-14 {
    //   background-color: rgba(0, 93, 255, 0.35) !important;
    // }
    // &-15 {
    //   background-color: rgba(0, 93, 255, 0.30) !important;
    // }
    // &-16 {
    //   background-color: rgba(0, 93, 255, 0.25) !important;
    // }
    // &-17 {
    //   background-color: rgba(0, 93, 255, 0.20) !important;
    // }
    // &-18 {
    //   background-color: rgba(0, 93, 255, 0.15) !important;
    // }
    // &-19 {
    //   background-color: rgba(0, 93, 255, 0.10) !important;
    // }
    // &-20 {
    //   background-color: rgba(0, 93, 255, 0.05) !important;
    // }
    &-1 {
      background-color: rgba(0, 0, 0, 1.00) !important;
    }
    &-2 {
      background-color: rgba(0, 0, 0, 0.95) !important;
    }
    &-3 {
      background-color: rgba(0, 0, 0, 0.90) !important;
    }
    &-4 {
      background-color: rgba(0, 0, 0, 0.85) !important;
    }
    &-5 {
      background-color: rgba(0, 0, 0, 0.80) !important;
    }
    &-6 {
      background-color: rgba(0, 0, 0, 0.75) !important;
    }
    &-7 {
      background-color: rgba(0, 0, 0, 0.70) !important;
    }
    &-8 {
      background-color: rgba(0, 0, 0, 0.65) !important;
    }
    &-9 {
      background-color: rgba(0, 0, 0, 0.60) !important;
    }
    &-10 {
      background-color: rgba(0, 0, 0, 0.55) !important;
    }
    &-11 {
      background-color: rgba(0, 0, 0, 0.50) !important;
    }
    &-12 {
      background-color: rgba(0, 0, 0, 0.45) !important;
    }
    &-13 {
      background-color: rgba(0, 0, 0, 0.40) !important;
    }
    &-14 {
      background-color: rgba(0, 0, 0, 0.35) !important;
    }
    &-15 {
      background-color: rgba(0, 0, 0, 0.30) !important;
    }
    &-16 {
      background-color: rgba(0, 0, 0, 0.25) !important;
    }
    &-17 {
      background-color: rgba(0, 0, 0, 0.20) !important;
    }
    &-18 {
      background-color: rgba(0, 0, 0, 0.15) !important;
    }
    &-19 {
      background-color: rgba(0, 0, 0, 0.10) !important;
    }
    &-20 {
      background-color: rgba(0, 0, 0, 0.05) !important;
    }
  }
}
</style>