var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup NumbersNumberAddressModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.handleSubmit.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Address")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('h5', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v(" Address type required: " + _vm._s(_vm.address_required) + " ")]), _c('p', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v("None")]), _vm._v(" means no address is required.")]), _c('p', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v("Any")]), _vm._v(" means an address is required, but it can be anywhere in the world. ")]), _c('p', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v("Local")]), _vm._v(" means an address in the phone number's country is required. ")]), _c('p', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v("Foreign")]), _vm._v(" means an address outside of the phone number's country is required. ")])]), _c('div', {
    staticClass: "mt-20px"
  }, [true ? _c('div', {
    staticClass: "latestTableDesign-withBlackBorders-again mt-0 dialer-box alt table"
  }, [_c('table', {
    staticClass: "dialer-table"
  }, [_c('thead', [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort one"
  }, [_c('span', [_vm._v("Street")])]), _c('th', {
    staticClass: "dialer-has-sort two"
  }, [_c('span', [_vm._v("City")])]), _c('th', {
    staticClass: "dialer-has-sort three"
  }, [_c('span', [_vm._v("Postcode")])]), _c('th', {
    staticClass: "dialer-has-sort w-auto four"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Country")])]), _c('th', {
    staticClass: "dialer-col-right five"
  }, [_c('span', [_vm._v("Action")])])])]), _c('tbody', _vm._l(_vm.locations, function (location) {
    return _c('tr', {
      key: location.real_id,
      staticClass: "dialer-row-select"
    }, [_c('td', {
      staticClass: "one"
    }, [_vm._v(" " + _vm._s(location.street) + " ")]), _c('td', {
      staticClass: "two"
    }, [_vm._v(" " + _vm._s(location.town) + " ")]), _c('td', {
      staticClass: "three"
    }, [_vm._v(" " + _vm._s(location.postcode) + " ")]), _c('td', {
      staticClass: "dialer-row-title w-auto four"
    }, [_vm._v(" " + _vm._s(location.country) + " ")]), _c('td', {
      staticClass: "dialer-col-right five"
    }, [_c('a', {
      staticClass: "dialer-button dialer-button-primary",
      on: {
        "click": function ($event) {
          return _vm.selectLocation(location);
        }
      }
    }, [_vm._v("use")])])]);
  }), 0)]), _vm.locations.length == 0 ? _c('div', {
    staticClass: "dialer-table no-data tun"
  }, [_c('span', {
    staticClass: "dialer-no-table-data"
  }, [_vm._v("No address found ")])]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Stored addresses")]), _c('a', {
    staticClass: "dialer-button dialer-button-primary",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.modalName}-AddLocationModal`, {
          flag: 'unpurchased',
          callerId: _vm.data.new_number.number
        });
      }
    }
  }, [_vm._v(" Add new ")])])])])])])]), _c('AddLocationModal', {
    attrs: {
      "modalName": `${_vm.modalName}-AddLocationModal`
    },
    on: {
      "latest-list": function ($event) {
        return _vm.fetchLocations();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }