var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.conditions.maintenance ? _c('div', {
    staticClass: "bill-over-lay maintainaceScreen-container"
  }, [_c('div', {
    staticClass: "maintainaceScreen-inner"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.check_maintenance.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.check_maintenance.error_message))]), _c('img', {
    staticClass: "maintainaceScreen-img",
    attrs: {
      "src": require('@/assets/images/maintenanceScreen-gif.gif'),
      "alt": "maintainance "
    }
  }), _c('div', {
    staticClass: "maintainaceScreen-heading"
  }, [_vm._v("Maintenance in progress")]), _vm._m(0), _c('button', {
    staticClass: "maintainaceScreen-button",
    attrs: {
      "disabled": _vm.api.check_maintenance.send
    },
    on: {
      "click": function ($event) {
        return _vm.checkMaintenance();
      }
    }
  }, [_vm.api.check_maintenance.send ? _c('b-spinner', {
    attrs: {
      "variant": "primary"
    }
  }) : [_vm._v(" Try Again ")]], 2)], 1)]) : _vm._e(), _vm.version_response ? _c('div', {
    staticClass: "bill-over-lay maintainaceScreen-container"
  }, [_c('div', {
    staticClass: "maintainaceScreen-inner"
  }, [_c('img', {
    staticClass: "maintainaceScreen-img",
    attrs: {
      "src": require('@/assets/images/maintenanceScreen-gif.gif'),
      "alt": "maintainance "
    }
  }), _vm.updateState.state == 1 ? _c('b-spinner') : [_c('b-alert', {
    attrs: {
      "show": _vm.updateState.state == 6 && _vm.updateState.error,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.updateState.error))]), _vm.updateState.version_available ? _c('div', {
    staticClass: "maintainaceScreen-heading"
  }, [_vm._v(_vm._s(_vm.updateState.version_available))]) : _vm._e(), _vm.updateState.state == 4 ? [_c('div', {
    staticClass: "maintainaceScreen-text"
  }, [_vm._v(_vm._s(_vm._f("filterDownloadPercent")(_vm.updateState.downloading.percentage)))]), _c('div', {
    staticClass: "maintainaceScreen-text"
  }, [_vm._v(_vm._s(_vm._f("format_bytes")(_vm.updateState.downloading.per_second)))]), _c('div', {
    staticClass: "maintainaceScreen-text"
  }, [_vm._v(_vm._s(_vm._f("format_bytes")(_vm.updateState.downloading.download)))]), _c('div', {
    staticClass: "maintainaceScreen-text"
  }, [_vm._v(_vm._s(_vm._f("format_bytes")(_vm.updateState.downloading.total)))])] : _vm.updateState.state != 5 ? _c('button', {
    staticClass: "maintainaceScreen-button",
    on: {
      "click": function ($event) {
        return _vm.download();
      }
    }
  }, [_vm._v("Download")]) : _vm._e()]], 2)]) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "maintainaceScreen-text"
  }, [_vm._v(" Sorry for the inconvenience! Our system is taking a short break to come back stronger than ever. "), _c('br'), _c('br'), _vm._v(" Stay tuned for updates! ")]);

}]

export { render, staticRenderFns }