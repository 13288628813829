var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Storage")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Update Setting")])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.update.send || _vm.api.update_status.send || _vm.api.get.send
    },
    on: {
      "change": function ($event) {
        return _vm.updatestatus();
      }
    },
    model: {
      value: _vm.forms.update.status,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update, "status", $$v);
      },
      expression: "forms.update.status"
    }
  })], 1)])]), _vm.forms.update.status ? _c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update();
      }
    }
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Type")])]), _c('div', {
    staticClass: "dd-updatedIos"
  }, [_c('vb-select', {
    attrs: {
      "options": _vm.typeOptions
    },
    model: {
      value: _vm.forms.update.type,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update, "type", $$v);
      },
      expression: "forms.update.type"
    }
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "slot": "right-icon",
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    },
    slot: "right-icon"
  })], 1)], 1)]), _c('div', {
    staticClass: "w-100 d-flex flex-column"
  }, [_vm.forms.update.submitted ? [!_vm.$v.forms.update.type.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Type is required")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.provider ? _vm._l(_vm.api.update.validation_errors.provider, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)])]), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Key")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.key,
      expression: "forms.update.key"
    }],
    attrs: {
      "type": "text",
      "label": "Key",
      "disabled": _vm.api.update.send || _vm.api.get.send
    },
    domProps: {
      "value": _vm.forms.update.key
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "key", $event.target.value);
      }
    }
  })]), _vm.forms.update.submitted ? [!_vm.$v.forms.update.key.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* key is required")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.key ? _vm._l(_vm.api.update.validation_errors.key, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("SECRET")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.secret,
      expression: "forms.update.secret"
    }],
    attrs: {
      "type": "text",
      "label": "SECRET",
      "disabled": _vm.api.update.send || _vm.api.get.send
    },
    domProps: {
      "value": _vm.forms.update.secret
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "secret", $event.target.value);
      }
    }
  })]), _vm.forms.update.submitted ? [!_vm.$v.forms.update.secret.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* secret is required")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.secret ? _vm._l(_vm.api.update.validation_errors.secret, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("bucket")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.bucket,
      expression: "forms.update.bucket"
    }],
    attrs: {
      "type": "text",
      "label": "bucket",
      "disabled": _vm.api.update.send || _vm.api.get.send
    },
    domProps: {
      "value": _vm.forms.update.bucket
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "bucket", $event.target.value);
      }
    }
  })]), _vm.forms.update.submitted ? [!_vm.$v.forms.update.bucket.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* bucket is required")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.bucket ? _vm._l(_vm.api.update.validation_errors.bucket, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]), _vm.forms.update.type == 's3' ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("region")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.region,
      expression: "forms.update.region"
    }],
    attrs: {
      "type": "text",
      "label": "region",
      "disabled": _vm.api.update.send || _vm.api.get.send
    },
    domProps: {
      "value": _vm.forms.update.region
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "region", $event.target.value);
      }
    }
  })]), _vm.forms.update.submitted ? [!_vm.$v.forms.update.region.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* region is required")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.region ? _vm._l(_vm.api.update.validation_errors.region, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]) : _vm._e(), _vm.forms.update.type == 'other' ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("URL")]), _c('div', [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.url,
      expression: "forms.update.url"
    }],
    attrs: {
      "type": "text",
      "label": "URL",
      "disabled": _vm.api.update.send || _vm.api.get.send
    },
    domProps: {
      "value": _vm.forms.update.url
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "url", $event.target.value);
      }
    }
  })]), _vm.forms.update.submitted ? [!_vm.$v.forms.update.url.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Type is required")])]) : _vm._e(), !_vm.$v.forms.update.url.url ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Invalid URL")])]) : _vm._e()] : _vm._e(), _vm.api.update.validation_errors.url ? _vm._l(_vm.api.update.validation_errors.url, function (error_message, index) {
    return _c('p', {
      key: index,
      staticClass: "mb-1 text-small text text-danger animated bounceIntop"
    }, [_c('span', [_vm._v("* " + _vm._s(error_message))])]);
  }) : _vm._e()], 2)]) : _vm._e()]), _c('button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "disabled": _vm.api.update.send || _vm.api.get.send
    }
  }, [_vm.api.update.send ? _c('vb-spinner', {
    staticClass: "sm-mar-right"
  }) : [_vm._v("Update")]], 2)]) : _vm._e()])]);

}
var staticRenderFns = []

export { render, staticRenderFns }