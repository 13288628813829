var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-900",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "dialer-settings-section pickupGroups"
  }, [_c('div', {
    staticClass: "dialer-flex"
  }, [_c('h2', {
    staticClass: "dialer-settings-title"
  }, [_vm._v("All extensions")]), _c('a', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between md-mar-top md-mar-bottom"
  }, [_c('span', [_vm._v("Manage all your extensions.")]), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center w-50"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search extensions"
    },
    model: {
      value: _vm.filter.extensions.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.extensions, "search", $$v);
      },
      expression: "filter.extensions.search"
    }
  })], 1)])]), _c('vb-table', {
    staticClass: "table2 latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.filterExtensions.length == 0,
      "listLength": _vm.filterExtensions.length,
      "loading": _vm.api.fetch_extensions.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterExtensions, function (extension, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: index,
            staticClass: "dialer-row-select",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('EditExtensionModal', {
                  data: extension
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('info', {
            attrs: {
              "id": extension.voipaccount,
              "name": _vm._f("filterLabel")(extension),
              "sub_info": extension.extn,
              "is_blf": false
            }
          })], 1), _c('td', {
            staticClass: "dialer-col-left"
          }, [_vm._v(_vm._s(extension.type))])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "31px",
              "width": "55px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "180px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "21px",
              "width": "180px"
            }
          })])])]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', {
    staticClass: "cursor_auto"
  }, [_c('th', {
    staticClass: "dialer-has-sort cursor_auto"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending dialer-has-sort"
  }, [_vm._v("Label")])]), _c('th', {
    staticClass: "dialer-has-sort cursor_auto"
  }, [_c('span', {
    staticClass: "dialer-has-sort"
  }, [_vm._v("Type")])])])])], 2)], 1)]), _c('EditExtensionModal', {
    on: {
      "interface": function ($event) {
        return _vm.fetchExtensions();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }