var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation center-zoomIn-popup m-w-600 switching-plan-modal dialer-recordVideo-modal",
    attrs: {
      "width": "50%",
      "height": "80%",
      "name": _vm.modalName
    },
    on: {
      "opened": function ($event) {
        return _vm.onOpened($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "main"
  }, [_c('div', {
    staticClass: "cross",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "font-scale": "1.5"
    }
  })], 1), _vm.error.message ? _c('div', {
    staticClass: "mediaNotAvailable-container"
  }, [_vm._v(_vm._s(_vm.error.message))]) : [_c('div', {
    staticClass: "video-container"
  }, [!_vm.recorder.blob ? _c('video', {
    ref: "stream",
    attrs: {
      "autoplay": "",
      "width": "100%"
    }
  }) : _vm._e(), _c('video', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.recorder.blob,
      expression: "recorder.blob"
    }],
    ref: "preview",
    attrs: {
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "actions"
  }, [_vm.recorder.blob ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "submit-button",
    attrs: {
      "title": "Send"
    },
    on: {
      "click": function ($event) {
        return _vm.submit();
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-send",
      "width": "28",
      "height": "28",
      "viewBox": "0 0 24 24",
      "stroke-width": "1.5",
      "stroke": "#2c3e50",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1) : _vm.recorder.stream && _vm.recorder.recorder ? _c('div', {
    class: ['recording', !_vm.recorder.started ? '' : 'recording_withTimer'],
    on: {
      "click": function ($event) {
        !_vm.recorder.started ? _vm.recorder.start() : _vm.recorder.stop();
      }
    }
  }, [!_vm.recorder.started ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "recording_btn",
    attrs: {
      "title": "Start"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "circle-fill",
      "variant": "currentColor"
    }
  })], 1) : [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "recording_btn",
    attrs: {
      "title": "Stop"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "square-fill",
      "variant": "currentColor",
      "scale": ""
    }
  })], 1), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.recorder.time)))])]], 2) : _vm._e()]), _vm.recorder.blob ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    staticClass: "preview_action",
    attrs: {
      "title": "Preview"
    }
  }, [_c('div', {
    staticClass: "preview-button",
    on: {
      "click": function ($event) {
        _vm.conditions.play = !_vm.conditions.play;
        _vm.conditions.play ? _vm.$refs['preview'].play() : _vm.$refs['preview'].pause();
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": !_vm.conditions.play ? 'dialer-play-circle' : 'dialer-pause-circle',
      "width": "52",
      "stroke": "currentColor",
      "height": "52",
      "viewBox": "0 0 24 24",
      "fill": "none",
      "stroke-width": "1",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1)]) : _vm._e()]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }