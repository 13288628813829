var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.status.slug == 'closed' ? _c('div', {
    staticClass: "ticketFeedback-container"
  }, [_c('div', {
    staticClass: "ticketFeedback-heading"
  }, [_vm._v("Please rate your experience with us")]), _c('div', {
    staticClass: "ticketFeedback-items"
  }, _vm._l(_vm.feedbacks, function (feedback) {
    return _c('div', {
      key: feedback.value,
      staticClass: "ticketFeedback-item",
      class: {
        'active': _vm.selected.feedback == feedback.value
      },
      on: {
        "click": function ($event) {
          return _vm.updateFeedback(feedback.value);
        }
      }
    }, [_c('div', {
      staticClass: "ticketFeedback-item-emoji"
    }, [_vm._v(_vm._s(feedback.emoji))]), _c('div', {
      staticClass: "ticketFeedback-item-text"
    }, [_vm._v(_vm._s(feedback.text))])]);
  }), 0)]) : _vm._e(), _c('div', {
    staticClass: "ticketConversation-actions"
  }, [_vm.status.slug == 'active' ? [_c('div', {
    staticClass: "ticketActions-heading"
  }, [_vm._v("To update this ticket with more information please enter the details below:-")]), _c('div', {
    staticClass: "ticketActions-textArea"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_comment.comment,
      expression: "forms.create_comment.comment"
    }],
    attrs: {
      "rows": "7",
      "placeholder": "Write your message"
    },
    domProps: {
      "value": _vm.forms.create_comment.comment
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_comment, "comment", $event.target.value);
      }
    }
  }), _vm.$v.forms.create_comment.$error ? _c('p', {
    staticClass: "errorRed"
  }, [!_vm.$v.forms.create_comment.required ? _c('span', [_vm._v("Message is required")]) : _vm._e()]) : _vm._e()])] : _vm._e(), _c('div', {
    staticClass: "d-flex mt-16px"
  }, [_vm.status.slug == 'active' ? _c('button', {
    staticClass: "newButton mr-20px",
    on: {
      "click": function ($event) {
        return _vm.createComment();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-updateTicket-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart d-flex justify-content-center position-relative"
  }, [_vm._v(" Update ticket ")])], 1) : _vm._e(), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.toggleStatus();
      }
    }
  }, [_vm.status.slug == 'closed' ? [_c('vb-icon', {
    attrs: {
      "icon": "squared-openTicket-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Reopen ticket")])] : [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close ticket")])]], 2)])], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }