<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.select_country">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Country</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="country in response.countries" 
                :key="country.value"
                @click="
                  forms.purchase_number.country=country.value;
                  isUS ? fetchRegions() : '';fetchNumbers();
                  conditions.screen = screens.main;
                "
              >
                <div class="headingInsideTable">{{ country.label }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.purchase_number.country==country.value" width="16.642" height="15.275" />
              </div>
              <vb-no-record v-if="response.countries.length==0" :text="api.countries.send?'':'There is no Country'" :design="3">
                <vb-loading v-if="api.countries.send" slot="loading" />
              </vb-no-record>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_number_type">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Type Of Number</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="number_type in numberTypes" 
                :key="number_type.value" 
                @click="
                  forms.purchase_number.number_type=number_type.value;
                  forms.purchase_number.city='';
                  response.cities=[];
                  isUKC ? fetchCities() : fetchNumbers();
                  conditions.screen = screens.main;
                "
              >
                <div class="headingInsideTable">{{ number_type.label }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.purchase_number.number_type==number_type.value" width="16.642" height="15.275" />
              </div>
              <vb-no-record v-if="numberTypes.length==0" :text="'There is no Type Of Number'" :design="3" />
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_city">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select City</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="city in response.cities" 
                :key="city.citycode" 
                :value="city.citycode"
                @click="
                  forms.purchase_number.city=city.citycode;
                  fetchNumbers();
                  conditions.screen = screens.main;
                "
              >
                <div class="headingInsideTable">{{ city.city }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.purchase_number.city==city.citycode" width="16.642" height="15.275" />
              </div>
              <vb-no-record v-if="response.cities.length==0" :text="api.cities.send?'':'There is no Country'" :design="3">
                <vb-loading v-if="api.cities.send" slot="loading" />
              </vb-no-record>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_region">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Region</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="region in response.regions" 
                :key="region.id" 
                :value="region.code"
                @click="
                  forms.purchase_number.region=region.code;
                  forms.purchase_number.group='';
                  response.groups=[];
                  fetchGroups();
                  conditions.screen = screens.main;
                "
              >
                <div class="headingInsideTable">{{ region.label }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.purchase_number.region==region.code" width="16.642" height="15.275" />
              </div>
              <vb-no-record v-if="response.regions.length==0" :text="api.regions.send?'':'There is no Country'" :design="3">
                <vb-loading v-if="api.regions.send" slot="loading" />
              </vb-no-record>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_group">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Location</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="group in response.groups" 
                :key="group.id" 
                :value="group.group_id"
                @click="
                  forms.purchase_number.group=group.group_id;
                  fetchNumbers();
                  conditions.screen = screens.main;
                "
              >
                <div class="headingInsideTable">{{ group.label }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.purchase_number.group==group.group_id" width="16.642" height="15.275" />
              </div>
              <vb-no-record v-if="response.groups.length==0" :text="api.groups.send?'':'There is no Country'" :design="3">
                <vb-loading v-if="api.groups.send" slot="loading" />
              </vb-no-record>
            </div>
          </div>
        </div>
      </template>
      <template v-else-if="conditions.screen==screens.select_category">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Number Type</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="category in numberCategories" 
                :key="category.value" 
                :value="category.value"
                @click="
                  forms.purchase_number.category=category.value;
                  fetchNumbers();
                  conditions.screen = screens.main;
                "
              >
                <div class="headingInsideTable">{{ category.label }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.purchase_number.category==category.value" width="16.642" height="15.275" />
              </div>
              <vb-no-record v-if="numberCategories.length==0" :text="'There is no Country'" :design="3" />
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Purchase Number</div>
          <div class="plus-IOSIcon-container" @click="$modal.show('CheckedSlot',{ service: 'aql' })">
            <div class="textInsideCard onRightSide primaryColor">Detail</div>
          </div>
        </div>
        <div class="innerModalIos">

          <div class="lg-mar-bottom">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.select_country">
                <div class="headingInsideCard">Country</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ selectedCountry | get_property('label') }}</div>
              </div>
              <template v-if="isUK">
                <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.select_number_type">
                  <div class="headingInsideCard">Number Type</div>
                  <div class="textInsideCard onRightSide textAlignRight">{{ selectedNumberType | get_property('label') }}</div>
                </div>
                <div v-if="isUKC" class="basicWhiteIOScard-item" @click="conditions.screen=screens.select_city">
                  <div class="headingInsideCard">City</div>
                  <div class="textInsideCard onRightSide textAlignRight">{{ selectedCity | get_property('city') }}</div>
                </div>
              </template>
              <template v-else-if="isUS">
                <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.select_region">
                  <div class="headingInsideCard">Region</div>
                  <div class="textInsideCard onRightSide textAlignRight">{{ selectedRegion | get_property('label') }}</div>
                </div>
                <div v-if="!!forms.purchase_number.region" class="basicWhiteIOScard-item" @click="conditions.screen=screens.select_group">
                  <div class="headingInsideCard">Location</div>
                  <div class="textInsideCard onRightSide textAlignRight">{{ selectedGroup | get_property('label') }}</div>
                </div>
              </template>
              <template v-else>
                <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.select_category">
                  
                  <div class="headingInsideCard">Number Type</div>
                  <div class="textInsideCard onRightSide textAlignRight">{{ selectedCategory | get_property('label') }}</div>
                </div>
              </template>
            </div>

            <div :class="`PurchaseNumberList mt-16px mb-16px ${response.numbers.length==0 ? 'bg-white p-2' : ''}`">
              
              <div 
                v-for="number in response.numbers" 
                :key="number.msisdn || number.number" 
                @click="api.purchase_number.send ? '' : forms.purchase_number.number=(number.msisdn || number.number);isUS ? purchaseNumber() : $modal.show('SelectLocation',{ 
                  number: (number.msisdn || number.number),
                  address_required: number.address_required,
                })" class="PurchaseNumberList-item" 
                :class="`${forms.purchase_number.number==(number.msisdn || number.number) ? 'selected' : ''} `"
              >
                {{ number.msisdn || number.number }}
                
                <vb-icon icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" v-if="forms.purchase_number.number == (number.msisdn || number.number)" />
              </div>
              <vb-no-record v-if="response.numbers.length==0" :text="api.numbers.send?'':'There is no Number'" :design="3">
                <vb-loading v-if="api.numbers.send" slot="loading" />
              </vb-no-record>
            </div>
          </div>
          <SelectLocationModal @select="forms.purchase_number.address_id=$event.location.real_id;purchaseNumber()" />
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import { required, requiredIf } from "vuelidate/lib/validators";
import SelectLocationModal from '../locations/SelectLocation.vue'
let number_types = [
  {
    label: "UK National Number",
    value: "ukn",
  },
  {
    label: "UK Town / City Number",
    value: "ukc",
  },
  {
    label: "UK Mobile Number",
    value: "ukm",
  },
]
let number_categories = [
  {
    label: "Local Numbers",
    value: "1",
  },
  {
    label: "Mobile Numbers",
    value: "3",
  },
]
let screens = {
  main: 'main',
  select_country: 'select_country',
  select_number_type: 'select_number_type',
  select_city: 'select_city',
  select_region: 'select_region',
  select_group: 'select_group',
  select_category: 'select_category',
}
export default {
  name: "PurchaseNumberModal",
  components: {
    SelectLocationModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'PurchaseNumber',
    },
  },
  data(){
    return {
      forms: {
        purchase_number: this.$helperFunction.formInstance({
          data: {
            country: 'GB',
            number_type: '',
            category: '',
            city: '',
            region: '',
            group: '',
            number: '',
            address_id: '',
          },
        }),
      },
      api: {
        numbers: this.$helperFunction.apiInstance({  }),
        cities: this.$helperFunction.apiInstance({  }),
        regions: this.$helperFunction.apiInstance({  }),
        groups: this.$helperFunction.apiInstance({  }),
        countries: this.$helperFunction.apiInstance({  }),
        purchase_number: this.$helperFunction.apiInstance({  }),
      },
      response: {
        numbers: [],
        cities: [],
        regions: [],
        groups: [],
        countries: [],
      },
      conditions: {
        screen: screens.main
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    numberTypes(){ return number_types },
    numberCategories(){ return number_categories },
    selectedNumber(){ return this.response.numbers.find(number=>this.forms.purchase_number.number=(number.msisdn || number.number)) },
    isUK(){ return this.forms.purchase_number.country=='GB' },
    isUS(){ return this.forms.purchase_number.country=='US' },
    isUKC(){ return this.forms.purchase_number.country=='GB' && this.forms.purchase_number.number_type=='ukc' },
    screens(){ return screens },
    selectedCountry(){ return this.response.countries.find(country=>country.value==this.forms.purchase_number.country) },
    selectedNumberType(){ return this.numberTypes.find(number_type=>number_type.value==this.forms.purchase_number.number_type) },
    selectedCity(){ return this.response.cities.find(city=>city.citycode==this.forms.purchase_number.city) },
    selectedRegion(){ return this.response.regions.find(region=>region.code==this.forms.purchase_number.region) },
    selectedGroup(){ return this.response.groups.find(group=>group.group_id==this.forms.purchase_number.group) },
    selectedCategory(){ return this.numberCategories.find(category=>category.value==this.forms.purchase_number.category) },
  },
  validations: {
    forms: {
      purchase_number: {
        country: { 
          required 
        },
        number: { 
          required 
        },
        city: { 
          required: requiredIf(function(){
            return this.isUKC
          }) 
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchCountries()
    },
    onBeforeClose(){
      this.api.numbers.reset()
      this.api.cities.reset()
      this.api.regions.reset()
      this.api.groups.reset()
      this.api.countries.reset()
      this.api.purchase_number.reset()
      this.forms.purchase_number.reset()
      this.response.numbers = []
      this.response.cities = []
      this.response.regions = []
      this.response.groups = []
      this.response.countries = []
    },
    fetchCountries(){
      let vm = this
      vm.api.countries.send=true
      VOIP_API.endpoints.telephone_number.countries()
      .then(({ data: countries }) => {
        vm.response.countries = countries;
      })
      .finally(()=>{
        vm.api.countries.send=false
      });
    },
    fetchCities() {
      let vm = this
      vm.api.cities.send=true
      VOIP_API.endpoints.telephone_number.cities(vm.forms.purchase_number.country)
      .then(({ data: cities })=>{
        vm.response.cities=cities
      })
      .finally(()=>{
        vm.api.cities.send=false
      })
    },
    fetchRegions(){
      let vm = this
      vm.api.regions.send=true
      VOIP_API.endpoints.telephone_number.fetchregions()
      .then(({ data })=>{
        vm.response.regions=data?.data?.regions ?? []
      })
      .finally(()=>{
        vm.api.regions.send=false
      })
    },
    fetchGroups(){
      let vm = this
      vm.api.groups.send=true
      VOIP_API.endpoints.telephone_number.fetchregiongroups({
        region: vm.forms.purchase_number.region,
      })
      .then(({ data })=>{
        vm.response.groups=data?.data?.regions ?? []
      })
      .finally(()=>{
        vm.api.groups.send=true
      })
    },
    async fetchNumbers(){
      try {
        this.response.numbers=[]
        this.forms.purchase_number.number=''
        const isUKC = this.isUKC && this.forms.purchase_number.city
        const isUK = this.isUK && this.forms.purchase_number.number_type
        const isUS = this.isUS && this.forms.purchase_number.region && this.forms.purchase_number.group
        const isOTHER = this.forms.purchase_number.country && this.forms.purchase_number.category
        if(isUKC || isUK || isUS || isOTHER){
          this.api.numbers.send=true
          if(this.forms.purchase_number.country=='US'){
            const { data } = await VOIP_API.endpoints.telephone_number.fetchnumbers({
              type: 'usa',
              region: this.forms.purchase_number.region,
              id: this.forms.purchase_number.group,
            })
            this.response.numbers = data?.data?.numbers
          } else {
            const { data } = await VOIP_API.endpoints.telephone_number.fetchCitiesNumber({
              provider: "aql",
              number_type: this.forms.purchase_number.number_type,
              country: this.forms.purchase_number.country,
              category: this.forms.purchase_number.category,
              city: this.forms.purchase_number.city,
              uid: this.getCurrentUser.uid
            })
            this.response.numbers = data
          }
        }
      } catch(ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.numbers.send=false
      }
    },
    purchaseNumber(){
      let vm = this
      vm.api.purchase_number.send=true
      VOIP_API.endpoints.telephone_number.purchasenumber({
        country: vm.forms.purchase_number.country,
        number_type: vm.forms.purchase_number.number_type,
        city: vm.forms.purchase_number.city,
        number: vm.forms.purchase_number.number,
        product: "aql",
        category: vm.forms.purchase_number.category,
        service: "number",
        pid: vm.isUS ? 'idt' : vm.selectedNumber?.pid ?? "vonage",
        addressid: vm.forms.purchase_number.address_id,
        uid: vm.getCurrentUser?.uid,
        useraccountcode: vm.getCurrentUser?.account,
        default_account: vm.getCurrentUser?.default_accountcode,
        group_id: vm.forms.purchase_number.group,
        sku: vm.selectedNumber?.sku ?? '',
      })
      .then(()=>{
        vm.$modal.hide(vm.modalName)
        vm.appNotify({
          message: "Request for adding number is pending",
          type: "success",
        })
        vm.$emit('added')
      })
      .finally(()=>{
        vm.api.purchase_number.send=false
      })
    },
  },
};
</script>
