<template>
  <div>
    <div class="mainHeading">Your HR dashboard</div>
    <div class="mainDescription">
      The key metrics from your current data to give you real-time insights into your company
    </div>
    <div class="grayCard flexWrap">
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Hired in last 30 days
        </div>
        <div class="whiteSubCard-body">
          <div>24</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Annual turnover
        </div>
        <div class="whiteSubCard-body">
          <div>0%</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Hired in August
        </div>
        <div class="whiteSubCard-body">
          <div>2</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Turnover in last 12 months
        </div>
        <div class="whiteSubCard-body">
          <div>0%</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Left in last 30 days
        </div>
        <div class="whiteSubCard-body">
          <div>24</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Monthly turnover
        </div>
        <div class="whiteSubCard-body">
          <div>0%</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Left in August
        </div>
        <div class="whiteSubCard-body">
          <div>24</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          First year turnover
        </div>
        <div class="whiteSubCard-body">
          <div>0%</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Turnover in 2022
        </div>
        <div class="whiteSubCard-body">
          <div>0%</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Current retention
        </div>
        <div class="whiteSubCard-body">
          <div>100%</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Average age of employees
        </div>
        <div class="whiteSubCard-body">
          <div>45</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          Average length of service
        </div>
        <div class="whiteSubCard-body">
          <div>8y 5m</div>
        </div>
      </div>
      <div class="whiteSubCard">
        <div class="whiteSubCard-head">
          Headcount movements
        </div>
        <div class="whiteSubCard-body">
          <div class="w-100 d-flex align-items-center justify-content-center">
            <canvas id="headcount-movements-chart"></canvas>
          </div>
        </div>
      </div>
      <div class="whiteSubCard">
        <div class="whiteSubCard-head">
          Headcount
          <div class="HeadcountNumber">11</div>
        </div>
        <div class="whiteSubCard-body">
          <div class="w-100 d-flex align-items-center justify-content-center">
            <canvas id="doughnut-chart"></canvas>
          </div>
        </div>
      </div>
      <div class="whiteSubCard">
        <div class="whiteSubCard-head">
          Age profile
        </div>
        <div class="whiteSubCard-body">
          <div class="w-100 d-flex align-items-center justify-content-center">
            <canvas id="age-profile-chart"></canvas>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  name: 'Overview',
  data(){
    return {
      headCountMovementsChart: {},
      doughnutChart:{},
      ageChart:{}
    }
  },
  methods: {
    async barChart() {
      const ctx = document.getElementById("headcount-movements-chart");
      this.headCountMovementsChart?.destroy?.();
      this.headCountMovementsChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: ['lahore', 'Islamabad', 'Karachi', 'Faisalabad', 'Quetta', 'Peshawar'],
            datasets: [
              {
                label: '# of Votes',
                data: [14, 12],
                backgroundColor: [
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1'
                ],
                hoverBackgroundColor: [
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1'
                ],
                borderColor: [
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1'
                ],
                borderWidth: 1
            },
            {
                label: '# of Votes',
                data: [13, 13],
                backgroundColor: [
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)'
                ],
                hoverBackgroundColor:[
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)'
                ],
                borderColor: [
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)',
                  'rgb(255, 205, 86)'
                ],
                borderWidth: 1
            }
          ]
        },
        options: {
          lineTension: 1,
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          scales: {
            xAxes: [{
                gridLines: {
                  display:false,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Cities'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
            }],
            yAxes: [{
                gridLines: {
                  // display:false,
                  drawBorder: false,
                },
                scaleLabel: {
                  beginAtZero: true,
                  display: true,
                  labelString: 'Time Slot'
                },
            }]
          }
        }
      });
      this.headCountMovementsChart.update();
    },
    doughnut(){
      const ctx = document.getElementById("doughnut-chart");
      this.doughnutChart?.destroy?.();
      const data = {
        labels: [
          'Blue',
          'Yellow'
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [300, 100],
          backgroundColor: [
            '#5576d1',
            'rgb(255, 205, 86)'
          ],
          hoverBackgroundColor:[
            '#5576d1',
            'rgb(255, 205, 86)'
          ],
          // borderWidth: 1,
          hoverOffset: 4
        }]
      };
      this.doughnutChart = new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options:{
          radius: '50%',
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          responsive:true,
          cutoutPercentage: 70,
        }
      });
      // this.doughnutChart.defaults.global.elements.arc.borderWidth = 1
      this.doughnutChart.update();
    },
    ageProfileChart(){
      const ctx = document.getElementById("age-profile-chart");
      this.ageChart?.destroy?.();
      this.ageChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: ['lahore', 'Islamabad', 'Karachi', 'Faisalabad', 'Quetta', 'Peshawar'],
          datasets: [{
              label: '# of Votes',
              data: [12, 19, 3, 5, 5, 3],
              backgroundColor: [
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1'
              ],
              hoverBackgroundColor:[
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1'
              ],
              borderColor: [
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1'
              ],
              borderWidth: 1
          }]
        },
        options: {
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          scales: {
            xAxes: [{
              gridLines: {
                display:false
              }
            }],
            yAxes: [{
              gridLines: {
                // display:false
                drawBorder: false,
              }   
            }]
          }
        }
      });
      this.ageChart.update();
    }
  },
  mounted(){
    this.barChart()
    this.doughnut()
    this.ageProfileChart()
  }
}
</script>

<style>

</style>