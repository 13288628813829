var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "basicWhiteIOScard-item withBottomBorder",
    on: {
      "click": function ($event) {
        return _vm.checkPermission(_vm.getUserPermissions.custom_moh, _vm.test);
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Music on hold")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.music_on_hold.name))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('SelectPlaylistModal', {
    attrs: {
      "modalName": `${_vm.randomID}-SelectPlaylist`
    },
    on: {
      "updated": function ($event) {
        return _vm.callRouteSetting();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }