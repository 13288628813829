var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 TopUpSettings",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {}, [_c('div', {
    staticClass: "dialer-edit-header pt-0 mt-0"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Topup setting")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])], 1)]), _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', [!_vm.isEmpty(_vm.response.topup_settings) ? [_vm._v(" Credit per day limit: " + _vm._s(_vm._f("get_property")(_vm.response.topup_settings, 'per_day_auto_topup_limit', 0)) + " Credit per month limit: " + _vm._s(_vm._f("get_property")(_vm.response.topup_settings, 'per_month_auto_topup_limit', 0)) + " ")] : _vm._e(), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateTopupSetting();
      }
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Auto top up")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText mr-16px"
  }, [_vm._v(" Automatically recharge your account through your default payment method anytime your balance runs below the listed amount. ")])]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.forms.update_topup_setting.voip_enable,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update_topup_setting, "voip_enable", $$v);
      },
      expression: "forms.update_topup_setting.voip_enable"
    }
  })], 1), _vm.forms.update_topup_setting.voip_enable ? [_c('div', {
    staticClass: "latestGreyBox-heading-main-18px mt-20px text-left"
  }, [_vm._v("My balance falls below " + _vm._s(_vm.forms.update_topup_setting.voip_balance_limit ? _vm.forms.update_topup_setting.voip_balance_limit : ''))]), _c('b-form-group', {
    staticClass: "whiteBoxWithBorderInsideGreyBox withoutBorder p-4 mt-16px topUpCheckbox-container",
    attrs: {
      "id": "phone-numbers-radio"
    }
  }, [_vm.api.fetch_topup_setting.send ? _vm._l(8, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "latestShimmerDesign m-2",
      staticStyle: {
        "height": "40px",
        "width": "calc(25% - 24px)"
      }
    });
  }) : _vm._l(_vm.topupThreshold, function (n) {
    return _c('b-form-radio', {
      key: n,
      staticClass: "topUpCheckbox",
      attrs: {
        "disabled": _vm.api.update_topup_setting.send || _vm.api.fetch_topup_setting.send,
        "name": `${n}`,
        "value": n
      },
      on: {
        "change": function ($event) {
          _vm.forms.update_topup_setting.voip_balance_limit = n;
        }
      },
      model: {
        value: _vm.selectedSetting.limit,
        callback: function ($$v) {
          _vm.$set(_vm.selectedSetting, "limit", $$v);
        },
        expression: "selectedSetting.limit"
      }
    }, [_c('span', {
      staticClass: "topUpCheckbox-textPart"
    }, [_vm._v("£" + _vm._s(n))])]);
  })], 2), _vm.forms.update_topup_setting.submitted && _vm.$v.forms.update_topup_setting.voip_balance_limit.$invalid || _vm.api.update_topup_setting.validation_errors.voip_balance_limit ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_topup_setting.voip_balance_limit.required ? _c('span', [_vm._v("* Credit limit field is required")]) : !_vm.$v.forms.update_topup_setting.voip_balance_limit.maxValue ? _c('span', [_vm._v("* Credit limit maximum " + _vm._s(_vm.$v.forms.update_topup_setting.voip_balance_limit.$params.maxValue.max) + " only")]) : _vm._e(), _vm._l(_vm.api.update_topup_setting.validation_errors.voip_balance_limit, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e(), _c('div', {
    staticClass: "latestGreyBox-heading-main-18px mt-20px text-left"
  }, [_vm._v("Automatically recharge for")]), _c('b-form-group', {
    staticClass: "whiteBoxWithBorderInsideGreyBox withoutBorder p-4 mt-16px topUpCheckbox-container",
    attrs: {
      "id": "phone-numbers-radio"
    }
  }, [_vm.api.fetch_topup_setting.send || _vm.api.fetch_products.send ? _vm._l(8, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "latestShimmerDesign m-2",
      staticStyle: {
        "height": "40px",
        "width": "calc(25% - 24px)"
      }
    });
  }) : _vm._l(_vm.response.products, function (product) {
    return _c('b-form-radio', {
      key: product.id,
      staticClass: "topUpCheckbox",
      attrs: {
        "disabled": _vm.api.update_topup_setting.send || _vm.api.fetch_topup_setting.send || _vm.api.fetch_products.send,
        "name": product.id,
        "value": product.id
      },
      on: {
        "change": function ($event) {
          _vm.forms.update_topup_setting.voip_amount = product.price;
          _vm.forms.update_topup_setting.topup_product_id = product.real_id;
        }
      },
      model: {
        value: _vm.selectedSetting.autoRecharge,
        callback: function ($$v) {
          _vm.$set(_vm.selectedSetting, "autoRecharge", $$v);
        },
        expression: "selectedSetting.autoRecharge"
      }
    }, [_c('span', {
      staticClass: "topUpCheckbox-textPart"
    }, [_vm._v("£" + _vm._s(product.price))])]);
  })], 2), _vm.forms.update_topup_setting.submitted && _vm.$v.forms.update_topup_setting.voip_amount.$invalid || _vm.api.update_topup_setting.validation_errors.voip_amount ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_topup_setting.voip_amount.required ? _c('span', [_vm._v("* Amount to topup field is required")]) : _vm._e(), _vm._l(_vm.api.update_topup_setting.validation_errors.voip_amount, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "d-flex justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "newDoneButton mt-4",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.api.update_topup_setting.send || _vm.api.fetch_topup_setting.send
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.update_topup_setting.send ? _c('vb-spinner') : [_vm._v("Save")]], 2)], 1)], 1)])], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }