<template>
  <div class="dialer-settings-main">
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Teams</div>
      <div class="plus-IOSIcon-container" @click="$modal.show('AddTeam',{ suggested_name: `team ${voip_teams.length+1}` })">
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992"  />
      </div>
    </div>
    <section class="dialer-settings-section dialer-ivr-setting TeamsSettings">
      <template>
        
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-input type="text" placeholder="Search teams" v-model="filter.teams.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
      </template>
      
      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div class="basicWhiteIOScard-item" v-for="team in filterTeams" :key="team.id" @click="$modal.show('EditTeam', { real_id: team.real_id })">
          <div class="d-flex align-items-center">
            <div class="InfoComponentInsideAnroidIOSApp">
              <vb-avatar :id="team.real_id" :is_blf="false" />
            </div>
            <div class="d-flex flex-column">
              <template >
                <div class="headingInsideTable">
                  {{ team.name || '' }}
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="d-flex mr-6px align-items-center" v-if="team.ringroup && team.ringroup.did && team.ringroup.did[0]">
              
              <div class="textInsideCard textAlignRight onRightSide">{{ team.ringroup.did[0].did | number_formater }}</div>
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
        </div>
      </div>
      
      <EditTeamModal @updated="afterAdding()" />
    </section>
    <AddTeamModal @interface="afterAdding()" />
     
  </div>
</template>

<script>
import EditTeamModal from "../modals/teams/EditTeam.vue";
import AddTeamModal from "../modals/teams/AddTeam.vue";

import { VOIP_API, } from "@/utils";
import { mapGetters } from 'vuex';
export default {
  name: "Teams",
  components: {
    EditTeamModal,
    AddTeamModal,
  },
  data() {
    return {
      conditions: {
        more: '',
      },
      filter: {
        teams: {
          search: '',
        },
      },
      api: {
        teams: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
      },
    };
  },
  inject:['isEmpty','getVoipTeams'],
  computed: {
    ...mapGetters({
      voip_teams: 'getVoipTeams',
    }),
    filterTeams() {
      const search = this.filter.teams.search.toLowerCase().trim()
      const teams = this.$store.getters.getVoipTeams
      const filter_teams = teams.filter(item=>`${item.name} ${item.ringroup.extn}`.toLowerCase().trim().includes(search));
      return filter_teams
    },
  },
  filters: {
    memberCount(value) {
      if (typeof value == "number") return value;
      if (!value) return "";
      return value.length;
    },
    filterExtension(value) {
      if (!value) return "-";
      return value.extn;
    },
  },
  methods: {
    fetchTeams() {
      var vm = this;
      if (vm.api.teams.send) return;
      vm.api.teams.send = true;
      VOIP_API.endpoints.teams.list({
        fid: 12,
      })
      .then(({ data: { data: teams } }) => {
        vm.$store.commit("setVoipTeams", teams);
      })
      .finally(() => {
        vm.api.teams.send = false;
      });
    },
    afterAdding() {
      let vm = this;
      setTimeout(() => {
        vm.getVoipTeams(true);
      }, 2 * 1000);
    },
  },
  activated(){
    this.getVoipTeams()
  }
};
</script>