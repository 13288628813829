
import { VOIP_API } from "../index"

export const CONFERENCES_API_ENDPOINTS = {
  delete(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'billing',
        action: 'billing_service_action',
      },
    })
  },
  list(params={}){
    return VOIP_API.axios.voip.request({
      url: `conferences`,
      method: 'GET',
      params
    })
  },
  create(data={}){
    return VOIP_API.axios.voip.request({
      url: `billingcons`,
      method: 'POST',
      data,
    })
  },
}