var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "participant-container bill-track-1",
    class: {
      'speaker': _vm.speaker
    },
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "id": `local-track-container-${_vm.videoCall.room}-2`
    },
    on: {
      "click": function ($event) {
        _vm.swithView = !_vm.swithView;
        _vm.$emit(`swithView`);
      }
    }
  }, [_vm.error ? _c('div', {
    staticClass: "p-3 duringVideoCallErrorMsg-container"
  }, [_c('b-icon', {
    staticClass: "duringVideoCallErrorMsg-closeIcon",
    attrs: {
      "icon": "x-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('error-close');
      }
    }
  }), _c('div', {
    staticClass: "duringVideoCallErrorMsg-inner"
  }, [_c('p', [_vm._v(_vm._s(_vm.error.message))]), _c('div', {
    staticClass: "duringVideoCallErrorMsg-retry",
    on: {
      "click": function ($event) {
        _vm.error.track_failed == 'audio' ? _vm.$emit('audio') : _vm.error.track_failed == 'video' ? _vm.$emit('camera') : '';
      }
    }
  }, [_c('p', [_vm._v("Retry")]), _c('b-icon', {
    attrs: {
      "icon": "arrow-clockwise"
    },
    on: {
      "click": function ($event) {
        _vm.error.track_failed == 'audio' ? _vm.$emit('audio') : _vm.error.track_failed == 'video' ? _vm.$emit('camera') : '';
      }
    }
  })], 1)])], 1) : _vm._e(), _vm.isVideoNotShow || !_vm.performanceSetting.show_video ? _c('div', {
    staticClass: "video-not-shown-8569"
  }, [_c('div', [_c('img', {
    attrs: {
      "src": _vm.$store.getters.getCurrentUser.profileImg,
      "alt": _vm.$store.getters.getCurrentUser.display_name
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.user_male;
      }
    }
  })]), _c('span', {
    staticClass: "callParticipantsName"
  }, [_vm._v(_vm._s(_vm.$store.getters.getCurrentUser.display_name))])]) : _vm._e(), _c('div', {
    staticClass: "remote-tracks-conatiner forLocalTrack"
  }, _vm._l(_vm.localTracks, function (localTrack, index) {
    return _c('LocalTrack', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: !_vm.isVideoNotShow || _vm.performanceSetting.show_video,
        expression: "!isVideoNotShow || performanceSetting.show_video"
      }],
      key: `${localTrack.type}_${localTrack.videoType}_${localTrack.isMuted()}_${localTrack.getId()}_${index}`,
      attrs: {
        "localTrack": localTrack,
        "index": index,
        "JitsiConference": _vm.JitsiConference,
        "isJoined": _vm.isJoined,
        "isVideoMuted": _vm.isVideoMuted,
        "payload": _vm.payload,
        "listIndex": _vm.listIndex
      }
    });
  }), 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }