<template>
  <div>
    <div class="mainHeading withButton">
      Public holiday groups
      <button @click="$modal.show('hrmAddNewPublicHolidayGroupModal')" class="dialer-button dialer-button-primary mr-0 withIcon">
        <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
        Add new
      </button>
    </div>
    <div class="mainDescription mb-4">
      Here you can set up public holiday groups.
    </div>
    <div class="documentsModule-Table mt-32px">
      <!-- <div class="HrmTableHead-container">
        <div class="HrmTableHead one">Employee</div>
        <div class="HrmTableHead two">Event type</div>
      </div> -->
      <vb-table class="HrmTable">
        <template slot="header">
          <tr>
            <th class="HrmTableHead one pt-1">Group</th>
            <th class="HrmTableHead text-left two pt-1">Employees</th>
                <th class="HrmTableHead five pt-1">Actions</th>
          </tr>
        </template>
        <template #body>
          <tr class="dialer-row-select" >
            <td class="dialer-row-title one">
              General
            </td>
            <td class="dialer-row-title two text-left">
              10
            </td>
            <td class="dialer-col-right three">
              <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                <template #button-content>
                  <b-icon icon="three-dots-vertical"></b-icon>
                </template>
                <b-dropdown-item href="#">Action</b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    <modal class="dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll" name="hrmAddNewPublicHolidayGroupModal">
      <div>
        <div class="modalHeading-container">
          <div class="modalHeading">Public holiday group</div>
          <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('hrmAddNewPublicHolidayGroupModal')">Close</button>
        </div>
        <div class="modalInner-container">
          <div class="innerShadowInput-container mt-25px">
            <label>Name</label>
            <input />
          </div>
        <button class="dialer-button dialer-button-primary mt-32px mr-0"> Add </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'CalendarPublicHolidayGroups',
}
</script>

<style>

</style>