<template>
  <div>
    <div class="mainHeading withButton">
      Birthdays & Anniversaries
      <button class="dialer-button dialer-button-primary mr-0">
        Export
      </button>
    </div>
    <div class="mainDescription mb-4">
      Employees who chose to hide their full birthdate details will not appear in the report
    </div>
    <div class="dropdownsContainer">
      <b-dropdown no-caret class="primaryBorderDropdown w-300px">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">Birthday & anniversaries</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          Birthday & anniversaries
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown w-220px">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">Next year</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          Next year
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div v-for="anniversary in filterAnniversaries" :key="anniversary.id">
      <div class="mainHeading withButton mt-32px">{{ anniversary.title }}</div>
      <div class="BirthdaysAnniversariesTable mt-16px">
        <div class="HrmTableHead-container">
          <div class="HrmTableHead one">Employee</div>
          <div class="HrmTableHead two">Event type</div>
          <div class="HrmTableHead three">Date</div>
          <div class="HrmTableHead four">Visibility</div>
        </div>
        <vb-table class="HrmTable"
          :isListEmpty="isEmpty(anniversary.data)" 
          :listLength="anniversary.data.length" 
          :loading="api.anniversaries.send" 
        >
          
          <template #body="{ start, end }">
            <tr class="dialer-row-select" v-for="(data, index) in anniversary.data" v-show="index >= start && index <= end" :key="data.user">
              <td class="dialer-row-title one"><div class="nameWithImage">
                  <div class="imageContainer">
                    <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                  </div>
                  <div class="nameWithImage-text">{{ data.employee }}</div>
                </div>
              </td>
              <td class="dialer-col-right two">
                <div class="circleWithText">
                  <div class="circleContainer" style="background-color:#D5761B"></div>
                  <div class="circleWithText-text" style="color:#D5761B">{{ data.eventType }}</div>
                </div>
              </td>
              <td class="dialer-col-right three">{{ data.date }} </td>
              <td class="dialer-col-right four">{{ data.visibility }} </td>
            </tr>
          </template>
        </vb-table>
      </div>
    </div>
    
  </div>
</template>

<script>
import { $fn } from "@/utils";
import { HRM } from '@/data'
export default {
  name: 'BirthdaysAnniversaries',
  inject:['isEmpty'],
  data(){
    return {
      response: {
        anniversaries: [],
      },
      filter: {
        anniversaries: {
          search: '',
          direction: '',
        }
      },
      api: {
        anniversaries: this.$helperFunction.apiInstance({ error_message: true }),
      },
    }
  },
  computed:{
    filterAnniversaries(){
      return this.response.anniversaries
    }
  },
  methods: {
    fetchAnniversaries(){
      // console.log("awaiting is called")
      let vm = this;
      if(vm.api.anniversaries.send) return;
      vm.api.anniversaries.send=true
      $fn.sleep(2 * 1000)
      .then(()=>{
        // console.log("HRM Data", HRM.anniversaries)
        vm.response.anniversaries = HRM.anniversaries
      })
      .catch((/* err */)=>{
        // console.log("error", err)
      }).finally(()=> { vm.api.anniversaries.send = false })
    }
  },
  mounted(){
    this.fetchAnniversaries();
  }
}
</script>

<style>

</style>