<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Invoices</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>

      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item" v-for="invoice in invoices" :key="invoice.id" @click="$modal.show('InvoicePDF',{id:invoice.id})">
              <div class="headingInsideTable">{{ `VB-${invoice.id || ''}` }}</div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ `${invoice.currencyprefix} ${invoice.total}` }}</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
            <vb-no-record v-if="invoices.length==0" :text="api.account_invoice.send?'':'There is no playlist'" :design="3">
              <vb-loading v-if="api.account_invoice.send" slot="loading" />
            </vb-no-record>
          </div>

        </div>
      </div>
    </modal>
    <InvoicePDFModal />
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils';
import InvoicePDFModal from './InvoicePDF.vue'
export default {
  name: 'InvoicesModal',
  components: {
    InvoicePDFModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'Invoices',
    },
  },
  data(){
    return {
      response: {
        account_invoice: []
      },
      api: {
        account_invoice: this.$helperFunction.apiInstance({  }),
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    invoices(){ return this.response.account_invoice?.invoices?.invoices?.invoice ?? [] },
  },
  methods: {
    onBeforeOpen(){
      this.fetchAccountInvoices()
    },
    onBeforeClose(){
      this.response.account_invoice=[]
      this.api.account_invoice.reset()
    },
    fetchAccountInvoices(){
      let vm = this;
      vm.api.account_invoice.send = true;
      VOIP_API.endpoints.users.accountinvoicedetail({
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data }) => {
        vm.response.account_invoice = data
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.account_invoice.send = false
      });
    },
  },
}
</script>

<style>

</style>