var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition sipActiveCall-Modal NotesModal",
    attrs: {
      "transition": !_vm.$store.getters.getIsMobile ? 'center-zoomIn-transition' : '',
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.beforeOpen,
      "before-close": _vm.beforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-call-notification-tray"
  }, [_c('div', {
    staticClass: "dialer-call-notification"
  }, [_c('div', {
    staticClass: "dialer-call-notification-inner justify-content-between w-100"
  }, [_c('div', {
    staticClass: "dialer-call-notification-text"
  }, [_c('div', {
    staticClass: "dialer-call-notification-main-text"
  }, [_vm._v("Note")]), _c('div', {
    staticClass: "dialer-call-notification-sub-text"
  }, [_vm._v("Write some note for this call")])]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "dialer-call-notification-actions"
  }, [_c('div', {
    staticClass: "dialer-call-notification-actions-inner"
  }, [_c('form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.note,
      expression: "form.note"
    }],
    domProps: {
      "value": _vm.form.note
    },
    on: {
      "input": [function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "note", $event.target.value);
      }, _vm.sendNote]
    }
  })])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }