import { ACTIVITIES_CRM_API_ENDPOINTS } from "./activities"
import { ACTIVITY_TYPES_CRM_API_ENDPOINTS } from "./activity_type"
import { AUTH_CRM_API_ENDPOINTS } from "./auth"
import { CARDS_CRM_API_ENDPOINTS } from "./cards"
import { COMPANIES_CRM_API_ENDPOINTS } from "./companies"
import { CONTACTS_CRM_API_ENDPOINTS } from "./contacts"
import { DEALS_CRM_API_ENDPOINTS } from "./deals"
import { PIPELINES_STAGES_CRM_API_ENDPOINTS } from "./pipeline-stages"
import { PIPELINES_CRM_API_ENDPOINTS } from "./piplines"
import { USERS_CRM_API_ENDPOINTS } from "./users"


export const CRM_API_ENDPOINTS = {
  auth: AUTH_CRM_API_ENDPOINTS,
  deals: DEALS_CRM_API_ENDPOINTS,
  contacts: CONTACTS_CRM_API_ENDPOINTS,
  companies: COMPANIES_CRM_API_ENDPOINTS,
  pipelines: PIPELINES_CRM_API_ENDPOINTS,
  pipeline_stages: PIPELINES_STAGES_CRM_API_ENDPOINTS,
  cards: CARDS_CRM_API_ENDPOINTS,
  activities: ACTIVITIES_CRM_API_ENDPOINTS,
  activity_type: ACTIVITY_TYPES_CRM_API_ENDPOINTS,
  users: USERS_CRM_API_ENDPOINTS,
}