<template>
  <div>
    <div class="flow__graph designed designedAgain scaleDown-0-6" ref="app_designed" id="app_designed">
      <div v-if="user" class="w-100 d-flex justify-content-center align-items-center flex-column">
        <div class="w-auto d-flex justify-content-center align-items-center flex-column" >
          <NumbersNode :dids="user.userDid" />
          <InfoNode :voipaccount="user.voipaccount" @edit="$modal.show('EditUserGraphModal',{ accountcode: user.voipaccount })" />
          <OutOfHoursNode ref="ofh" :voipaccount="user.voipaccount" />
          <div class="nodeBasic-new" >
            <div class="ExtensionsIcon position-relative">
              <div :class="`ExtentionItself ${extension.device_status.status == 'online' ? 'statusOnline' : 'statusOffline'}`" v-for="(extension, index) in response.extension_detail.extensions" :key="index" >
                <vb-icon :id="extension | get_property('accountcode')" :icon="extension | get_property('type') | extensionIcon" width="25px" height="25px"/>
                <b-popover :target="extension | get_property('accountcode')" triggers="hover" placement="top">
                  <small>{{extension | get_property('accountname')}} {{extension.device_status.status}}</small>
                </b-popover>
              </div>
            </div>
          </div>
          <ForwardSettingNode :account="user.voipaccount" />
        </div>
      </div>
    </div>
    <EditUserModal 
      :modalName="'EditUserGraphModal'" 
      @ofh-days-updated="$refs.ofh.fetchSchedule();"
      @ofh-toggle-updated="$refs.ofh.fetchcallRouteSetting();"
      @ofh-default-updated="$refs.ofh.fetchDefaultSetting();"
      @extension-updated="fetchExtensionDetail()" 
      @cf-updated="$refs.ofh.fetchcallRouteSetting()"
    />
  </div>
</template>

<script>
import NumbersNode from '../common/custom/NumbersNode.vue'
import InfoNode from '../common/custom/InfoNode.vue'
import OutOfHoursNode from '../common/custom/OutOfHoursNode.vue'
import ForwardSettingNode from '../common/custom/ForwardSettingNode.vue'
import EditUserModal from "../../Modals/EditUserModal.vue";
import { mapGetters } from 'vuex'
import { VOIP_API } from '@/utils';
export default {
  name: 'UserGraph',
  components: {
    NumbersNode,
    InfoNode,
    OutOfHoursNode,
    ForwardSettingNode,
    EditUserModal,
  },
  inject: ['getVoipUsers','appNotify'],
  props:{
    accountcode:{
      type: String
    }
  },
  data(){
    return{
      api: {
        extension_detail: this.$helperFunction.apiInstance(),
      },
      response: {
        extension_detail: {},
      },
    }
  },
  computed:{
    ...mapGetters({
      getCurrentUser: 'getCurrentUser',
      getVoipUsersAlises: 'getVoipUsersAlises'
    }),
    user(){ return this.getVoipUsersAlises[this.accountcode] },
    voipaccount(){ return this.user.voipaccount },
  },
  filters: {
    extensionIcon(value){
      if(value=='WEBEXT') return 'devices-webExtension-icon'
      else if(value=='DESKTOPEXT') return 'devices-desktopExtension-icon'
      else if(value=='MOBILEEXT') return 'devices-mobileExtension-icon'
      else if(value=='HEADEXT') return 'devices-headExtension-icon'
      else return 'devices-webExtension-icon'
    },
    extensionText(value){
      if(value=='WEBEXT') return 'Web Extension'
      else if(value=='DESKTOPEXT') return 'Desktop Extension'
      else if(value=='MOBILEEXT') return 'Mobile Extension'
      else if(value=='HEADEXT') return 'Handset Extension'
      else return value
    },
    extensionStatus(value){
      if(value=='online') return 'online'
      else return 'offline'
    },
  },
  methods: {
    fetchExtensionDetail() {
      var vm = this;
      vm.api.extension_detail.send = true;
      VOIP_API.endpoints.extensions.mobileextensions(vm.voipaccount)
      .then((res) => {
        vm.response.extension_detail = res?.data?.data ?? {};
      })
      .finally(() => {
        vm.api.extension_detail.send = false;
      });
    },
  },
  mounted(){
    this.fetchExtensionDetail()
  }
}
</script>

<style>

</style>