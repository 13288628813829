export default [
	{
		id: 1,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 2,
		name: "Jackson Will",
		policy: "Sickday",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 3,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 4,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 5,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 6,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 7,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 8,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 9,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 10,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 11,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 12,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		id: 13,
		name: "Jackson Will",
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	}
]