<template>

	<div class="right-calender">
		<div class="top-main-calender scrollow1">
			<section class="sec-common">
				<div class="d-flex justify-content-between align-items-center">
					<div>
						<h5 class=" emp-profile-heading">Compensation</h5>
					</div>
				</div>
				<p class="compensate-top-text space4text-when-scroll-activate">
					Manage onboarding tasks for Ahsen van der
				</p>
				<vb-table :tableDesign="1" class="compensate-table-width mt-5">
					<template slot="header">
						<h5 class="commonth compensate-one col-left">Dates</h5>
						<h5 class="commonth compensate-two">amount</h5>
						<h5 class="commonth compensate-third">type</h5>
						<h5 class="commonth compensate-four">reason</h5>
						<h5 class="commonth compensate-five">Comments</h5>
						<h5 class="commonth compensate-six col-right">Action</h5>
					</template>
					<template slot="body">
						<tr>
							<td class="compensate-one">
								02/03/2022 - "Current"
							</td>
							<td class="compensate-two">
								£100.00
							</td>
							
							<td class="compensate-third ">
								Salary
							</td>
							<td class="compensate-four">
								Promotion
							</td>
							<td class="compensate-five">
								No comments
							</td>
							<td class="compensate-six">
								<div class="d-flex justify-content-end commonTableMain">
									<b-dropdown  no-caret class="common-table-dd">
										<template #button-content>
											<b-icon icon="three-dots-vertical"></b-icon>
										</template>
										<b-dropdown-item href="#">Action</b-dropdown-item>
									</b-dropdown>
								</div>
							</td>
						</tr>
					</template>
				</vb-table>
				
			</section>
		</div>
	
	</div>
</template>

<script>

export default {
  name: 'CompensationHRM',
}
</script>

<style>

</style>