var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-teams"
  }, [_vm.conditions.select_tab ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.select_tab = false;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Date")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })])] : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Teams")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "dialer-box AnalyticsContainerInner"
  }, [_vm.conditions.select_tab ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected = tab.key, _vm.tab_index = index, _vm.load();
          _vm.selected == 'custom' ? _vm.conditions.select_tab = true : _vm.conditions.select_tab = false;
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(tab.value))])])]), _vm.selected === tab.key ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0), _vm.selected == 'custom' ? _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2 forDateTime"
  }, [_c('label', [_vm._v("From")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "starttime"
    },
    model: {
      value: _vm.start_date,
      callback: function ($$v) {
        _vm.start_date = $$v;
      },
      expression: "start_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2 forDateTime"
  }, [_c('label', [_vm._v("To")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "endtime"
    },
    model: {
      value: _vm.end_date,
      callback: function ($$v) {
        _vm.end_date = $$v;
      },
      expression: "end_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)])]) : _vm._e(), _c('a', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    on: {
      "click": function ($event) {
        _vm.load();
        _vm.conditions.select_tab = false;
      }
    }
  }, [_vm._v("Fetch")])] : [[_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search user"
    },
    model: {
      value: _vm.filter.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "search", $$v);
      },
      expression: "filter.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1)], _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.select_tab = !_vm.conditions.select_tab;
      }
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-8px"
  }, [_vm._v(_vm._s(_vm.selectedTab.value))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)])]), _c('div', {
    staticClass: "IosTabsSection twoTabs mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "IosTabButton",
    class: {
      'active': !_vm.conditions.advanced
    },
    on: {
      "click": function ($event) {
        _vm.conditions.advanced = false;
      }
    }
  }, [_vm._v("Basic")]), _c('div', {
    staticClass: "IosTabButton",
    class: {
      'active': _vm.conditions.advanced
    },
    on: {
      "click": function ($event) {
        _vm.conditions.advanced = true;
      }
    }
  }, [_vm._v("Advance")])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, _vm._l(_vm.filterTimeLine, function (time_line) {
    return _c('div', {
      key: time_line.measure,
      class: `basicWhiteIOScard-item ${_vm.conditions.advanced ? 'withAdvancedSection' : ''}`
    }, [_c('div', {
      staticClass: "w-100 d-flex align-items-center justify-content-between"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "is_blf": false,
        "id": time_line.accountcode,
        "name": time_line.team.name
      }
    })], 1), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "d-flex mr-6px"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight",
      staticStyle: {
        "color": "#87868C"
      }
    }, [_vm._v(_vm._s(_vm._f("get_property")(time_line, 'user.totalCalls', 0)))]), _c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight mx-1"
    }, [_vm._v("/")]), _c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight",
      staticStyle: {
        "color": "#FF1900"
      }
    }, [_vm._v(_vm._s(_vm._f("get_property")(time_line, 'user.totalMissCalls', 0)))])]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)]), _vm.conditions.advanced ? _c('div', {
      staticClass: "advanceSection"
    }, [_c('div', {
      staticClass: "advanceSection-inner InboundCall"
    }, [_c('div', {
      staticClass: "aboveProgressBar"
    }, [_c('vb-icon', {
      staticClass: "rightFacingArrow mr-8px",
      attrs: {
        "icon": "CallSummary-InboundCall-icon",
        "width": "16.642",
        "height": "15.275"
      }
    }), _c('div', {
      staticClass: "aboveProgressBar-text"
    }, [_vm._v(_vm._s(time_line.team.totalIncommingCalls))])], 1), _c('vb-circular-progressive-bar', {
      attrs: {
        "percentage": time_line.team.totalIncommingCalls > 0 ? Math.ceil(time_line.team.totalIncommingCalls / time_line.team.totalCalls * 100) : 0
      }
    })], 1), _c('div', {
      staticClass: "advanceSection-inner outboundCall"
    }, [_c('div', {
      staticClass: "aboveProgressBar"
    }, [_c('vb-icon', {
      staticClass: "rightFacingArrow mr-8px",
      attrs: {
        "icon": "CallSummary-OutboundCall-icon",
        "width": "16.642",
        "height": "15.275"
      }
    }), _c('div', {
      staticClass: "aboveProgressBar-text"
    }, [_vm._v(_vm._s(time_line.team.totalOutgoingCalls))])], 1), _c('vb-circular-progressive-bar', {
      attrs: {
        "percentage": time_line.team.totalOutgoingCalls > 0 ? Math.ceil(time_line.team.totalOutgoingCalls / time_line.team.totalCalls * 100) : 0
      }
    })], 1), _c('div', {
      staticClass: "advanceSection-inner missedCall"
    }, [_c('div', {
      staticClass: "aboveProgressBar"
    }, [_c('vb-icon', {
      staticClass: "rightFacingArrow mr-8px",
      attrs: {
        "icon": "CallSummary-MissedCall-icon",
        "width": "16.642",
        "height": "15.275"
      }
    }), _c('div', {
      staticClass: "aboveProgressBar-text"
    }, [_vm._v(_vm._s(time_line.team.totalMissCalls))])], 1), _c('vb-circular-progressive-bar', {
      attrs: {
        "percentage": time_line.team.totalMissCalls > 0 ? Math.ceil(time_line.team.totalMissCalls / time_line.team.totalCalls * 100) : 0
      }
    })], 1)]) : _vm._e()]);
  }), 0), _vm.filterTimeLine.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api_sent.list ? '' : 'There is no Number'
    }
  }, [_vm.api_sent.list ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()]], 2)], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Date")])])]);

}]

export { render, staticRenderFns }