var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section-topBar"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading mb-0"
  }, [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "section-topBar-middlePart"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-inputSearch-icon"
    }
  }), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": "Search"
    }
  })], 1), _c('div', {
    staticClass: "section-topBar-lastPart"
  }, [_c('div', [_c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "variant": "link"
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "plus"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CRMCreateDeal');
      }
    }
  }, [_vm._v("Deal")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CRMCreateActivity');
      }
    }
  }, [_vm._v("Activity")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        '';
      }
    }
  }, [_vm._v("Document")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        '';
      }
    }
  }, [_vm._v("Contact")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        '';
      }
    }
  }, [_vm._v("Company")]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        '';
      }
    }
  }, [_vm._v("Product")])], 1)], 1), _vm._t("default")], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }