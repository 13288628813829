var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.channel_id && !_vm.isEmpty(_vm.channelData) ? _c('div', {
    staticClass: "chat-info"
  }, [_c('div', {
    staticClass: "chat-top-bar d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "info-area d-flex align-items-start"
  }, [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "cursor_pointer"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer mx-1",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.7"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "user-avatar d-block avatar border border-0 border-dark rounded-circle overflow-hidden"
  }, [_c('vb-avatar', {
    attrs: {
      "id": _vm.dataIdentity,
      "is_blf": false,
      "image": _vm.image
    }
  })], 1), _c('div', {
    staticClass: "pr-3 d-flex align-items-end w-100"
  }, [_c('div', {
    staticClass: "chat-topBar-header w-100"
  }, [_c('div', {
    staticClass: "name mb-1 w-100"
  }, [_c('h2', [_vm._v(_vm._s(_vm.name))]), _vm.isMuted ? _c('vb-icon', {
    staticClass: "mx-2 chatBoxHeader-chatMuteNotification-icon",
    attrs: {
      "icon": "chatMuteNotification-icon",
      "height": "15px",
      "width": "15px"
    }
  }) : _vm._e(), !_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "mx-2 cursor_pointer",
    attrs: {
      "height": "15px",
      "width": "15px",
      "icon": "gear-fill",
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.random_id}_ChannelSettingModal`);
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "description"
  }, [_vm.channelData.type === 'channel' ? _c('h6', {
    staticClass: "m-0 channelDescription"
  }, [_vm._v(" " + _vm._s(_vm.channel.purpose || `Add a group description`) + " ")]) : _vm.data ? _c('h6', {
    staticClass: "m-0 channelDescription"
  }, [_vm.channelData.type == 'team' ? [_vm._v(_vm._s(_vm._f("get_property")(_vm.data, 'ringroup.extn')))] : [_vm._v(_vm._s(_vm._f("get_property")(_vm.data, 'extn')))]], 2) : _vm.channelData.type === 'user' && _vm.status ? _c('small', {
    staticClass: "text-primary"
  }, [_vm._v(_vm._s(_vm.status.status))]) : _vm._e(), _c('a', {
    staticClass: "d-flex align-items-center ml-2",
    on: {
      "click": function ($event) {
        _vm.isSearchBox = !_vm.isSearchBox;
      }
    }
  }, [_c('b-icon', {
    class: `${_vm.channelData.type !== 'team' ? 'border-left border-dark' : ''} pl-2`,
    attrs: {
      "icon": "search",
      "scale": "1.9"
    }
  }), _c('h6', {
    staticClass: "ml-2 mb-0"
  }, [_vm._v("Find")])], 1)])])])]), _c('div', [_c('ul', {
    staticClass: "list-unstyled mb-0 d-flex"
  }, [!_vm.$store.getters.getIsMobile ? [_vm.channelData.type === 'channel' && !_vm.isEmpty(_vm.currentUserMember) && _vm.currentUserMember.roles.indexOf('channel_admin') >= 0 ? _c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon channel-members-icon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('MembersModal');
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "person",
      "scale": "1.2"
    }
  }), _c('span', {
    staticClass: "member_count ml-1"
  }, [_vm._v(_vm._s(_vm.members.length))])], 1)]) : _vm._e()] : _vm._e(), !_vm.$store.getters.getIsMobile ? [_vm.type === 'main' ? _c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon",
    on: {
      "click": _vm.setFavorite
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.isFavorite ? 'chat-favouriteIcon' : 'chat-notFavouriteIcon',
      "width": "40",
      "height": "40"
    }
  })], 1)]) : _vm._e()] : _vm._e(), _vm.isGroupCallActive && !_vm.isInCall && !_vm.isCallIncoming ? [_c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon",
    staticStyle: {
      "width": "100px !important"
    },
    on: {
      "click": function ($event) {
        return _vm.joinCall();
      }
    }
  }, [_vm._v("Join call")])])] : !_vm.isInCall && !_vm.isCallIncoming ? [!_vm.call_sent ? [_c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon",
    on: {
      "click": function ($event) {
        return _vm.call('video');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-chatVideoIcon",
      "width": "40",
      "height": "40"
    }
  })], 1)]), _c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon",
    on: {
      "click": function ($event) {
        return _vm.call('audio');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-audioCallIcon",
      "width": "40",
      "height": "40"
    }
  })], 1)])] : _c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon"
  }, [_c('b-icon', {
    attrs: {
      "icon": "geo"
    }
  })], 1)])] : _vm._e(), _vm.type === 'main' ? _c('li', {
    staticClass: "d-inline-flex pr-1 pl-3"
  }, [_c('a', {
    staticClass: "dialer-icon d-inline-flex align-content-center"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD ul-width-fit-content buttonHeightAuto buttonWidthAuto buttonOnRight withPadding0px"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter chat-header-more-dots",
    attrs: {
      "varient": "link",
      "offset": "25",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "dialer-icon"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "chat-viewMoreDotsIcon",
            "width": "5",
            "height": "40"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 1485667373)
  }, [_c('b-dropdown-item', [_vm.channelData.type === 'user' && _vm.type === 'main' ? _c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CreateChannelModal', {
          member: _vm.getCurrentlyUSerTypeMember
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-plus",
      "width": "40",
      "height": "40"
    }
  }), _c('span', {
    staticClass: "pl-3"
  }, [_vm._v(" Create group ")])], 1)]) : _vm._e()]), _vm.type === 'main' ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.pin('pin');
      }
    }
  }, [_c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chat-pinIcon",
      "width": "40",
      "height": "40"
    }
  }), _c('span', {
    staticClass: "pl-3"
  }, [_vm._v("Pinned messages")])], 1)])]) : _vm._e(), _vm.type === 'main' ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.pin('members');
      }
    }
  }, [_c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "openMembersModule-ChatIcon",
      "width": "40",
      "height": "40"
    }
  }), _c('span', {
    staticClass: "pl-3"
  }, [_vm._v("Participants")])], 1)])]) : _vm._e(), _vm.type === 'main' ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.pin('files');
      }
    }
  }, [_c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "openMediaModule-ChatIcon",
      "width": "40",
      "height": "40"
    }
  }), _c('span', {
    staticClass: "pl-3"
  }, [_vm._v("Files")])], 1)])]) : _vm._e(), _vm.$store.getters.getIsMobile ? [_vm.type === 'main' ? _c('b-dropdown-item', [_vm.type === 'main' ? _c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon",
    on: {
      "click": _vm.setFavorite
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.isFavorite ? 'chat-favouriteIcon' : 'chat-notFavouriteIcon',
      "width": "40",
      "height": "40"
    }
  }), _c('span', {
    staticClass: "pl-3"
  }, [_vm._v(_vm._s(_vm.isFavorite ? 'Remove' : 'Make') + " Favourite")])], 1)]) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.$store.getters.getIsMobile ? [_vm.channelData.type === 'channel' && !_vm.isEmpty(_vm.currentUserMember) && _vm.currentUserMember.roles.indexOf('channel_admin') >= 0 ? _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show('MembersModal');
      }
    }
  }, [_vm.channelData.type === 'channel' && !_vm.isEmpty(_vm.currentUserMember) && _vm.currentUserMember.roles.indexOf('channel_admin') >= 0 ? _c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon d-flex align-items-center"
  }, [_c('b-icon', {
    staticClass: "channel-members-icon",
    staticStyle: {
      "border-radius": "9px"
    },
    attrs: {
      "icon": "person",
      "scale": "1"
    }
  }), _c('span', {
    staticClass: "member_count ml-2"
  }, [_vm._v(_vm._s(_vm.members.length) + " Members")])], 1)]) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.$store.getters.getIsMobile ? [_c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.random_id}_ChannelSettingModal`);
      }
    }
  }, [_c('li', {
    staticClass: "d-inline-flex px-1"
  }, [_c('a', {
    staticClass: "dialer-icon d-flex align-items-center"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "39.998",
      "height": "39.998",
      "viewBox": "0 0 39.998 39.998"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_45664",
      "data-name": "Group 45664",
      "transform": "translate(7700 8039)"
    }
  }, [_c('g', {
    attrs: {
      "id": "Group_45663",
      "data-name": "Group 45663",
      "transform": "translate(-7700.001 -8039.001)"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_12118",
      "data-name": "Path 12118",
      "d": "M28.122,39.9a49.11,49.11,0,0,1-15.259,0A14.129,14.129,0,0,1,1.1,28.122a48.684,48.684,0,0,1,0-15.259A14.122,14.122,0,0,1,12.864,1.1a48.684,48.684,0,0,1,15.259,0A14.129,14.129,0,0,1,39.9,12.864a49.11,49.11,0,0,1,0,15.259A14.136,14.136,0,0,1,28.122,39.9Z",
      "transform": "translate(-0.497 -0.497)",
      "fill": "#ececec"
    }
  })]), _c('path', {
    attrs: {
      "id": "Icon_ionic-md-settings",
      "data-name": "Icon ionic-md-settings",
      "d": "M19.21,13.275a5.628,5.628,0,0,0,.046-.9c0-.315-.046-.585-.046-.9L21.143,9.99a.413.413,0,0,0,.092-.585L19.394,6.3a.448.448,0,0,0-.552-.18l-2.3.9a6.7,6.7,0,0,0-1.565-.9l-.322-2.385a.493.493,0,0,0-.46-.36H10.511a.493.493,0,0,0-.46.36L9.682,6.12a7.794,7.794,0,0,0-1.565.9l-2.3-.9a.431.431,0,0,0-.552.18L3.423,9.405a.543.543,0,0,0,.092.585l1.979,1.485c0,.315-.046.585-.046.9s.046.585.046.9L3.561,14.76a.413.413,0,0,0-.092.585L5.31,18.45a.448.448,0,0,0,.552.18l2.3-.9a6.7,6.7,0,0,0,1.565.9l.368,2.385a.446.446,0,0,0,.46.36h3.682a.493.493,0,0,0,.46-.36l.369-2.385a7.789,7.789,0,0,0,1.565-.9l2.3.9a.431.431,0,0,0,.552-.18l1.841-3.105a.543.543,0,0,0-.092-.585Zm-6.858,2.25a3.151,3.151,0,1,1,3.222-3.15A3.167,3.167,0,0,1,12.352,15.525Z",
      "transform": "translate(-7692.376 -8031.376)",
      "fill": "#8a8a8a"
    }
  })])]), _c('span', {
    staticClass: "member_count ml-2"
  }, [_vm._v("Settings")])])])])] : _vm._e()], 2)], 1)])]) : _vm._e()], 2)])]), _c('transition', {
    attrs: {
      "name": "slide"
    }
  }, [_vm.isSearchBox ? _c('div', {
    staticClass: "searchBox mt-3 pt-3 border-top border-light"
  }, [_c('b-form', {
    staticClass: "w-75 mx-auto d-flex align-items-center position-relative",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.jump.apply(null, arguments);
      }
    }
  }, [_c('b-form-input', {
    staticClass: "w-100 searchBoxInput",
    attrs: {
      "autofocus": ""
    },
    on: {
      "load": _vm.loadedSearchInput,
      "keyup": [function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "up", 38, $event.key, ["Up", "ArrowUp"])) return null;
        $event.preventDefault();
        return _vm.jump('up');
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "down", 40, $event.key, ["Down", "ArrowDown"])) return null;
        $event.preventDefault();
        return _vm.jump('down');
      }, function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "esc", 27, $event.key, ["Esc", "Escape"])) return null;
        $event.preventDefault();
        return _vm.close.apply(null, arguments);
      }],
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "f", undefined, $event.key, undefined)) return null;
        if (!$event.ctrlKey) return null;
        if ($event.shiftKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        return _vm.close.apply(null, arguments);
      }
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('b-icon', {
    staticClass: "position-absolute top-0 left-0 ml-1 cursor_pointer",
    attrs: {
      "icon": "search",
      "scale": "1.1"
    }
  }), _c('div', {
    staticClass: "right-icons position-absolute right-10 h-100 top-0 d-flex align-items-center justify-content-between"
  }, [_c('p', {
    staticClass: "mb-0",
    staticStyle: {
      "min-width": "25px"
    }
  }, [_vm.search ? _c('span', [_vm._v(_vm._s(`${_vm.matched.length === 0 ? 0 : _vm.current_index + 1}/${_vm.matched.length}`))]) : _vm._e()]), _c('div', {
    staticClass: "arrows"
  }, [_c('b-icon', {
    staticClass: "cursor_pointer mx-1",
    attrs: {
      "icon": "chevron-up",
      "disabled": _vm.current_index == 0
    },
    on: {
      "click": function ($event) {
        return _vm.jump('up');
      }
    }
  }), _c('b-icon', {
    staticClass: "cursor_pointer mx-1",
    attrs: {
      "icon": "chevron-down",
      "disabled": _vm.current_index == _vm.filterSearchArray.length - 1
    },
    on: {
      "click": function ($event) {
        return _vm.jump('down');
      }
    }
  })], 1), _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x-circle"
    },
    on: {
      "click": _vm.close
    }
  })], 1), _c('b-icon', {
    staticClass: "closeIconOfChatSearch",
    attrs: {
      "icon": "x-circle-fill"
    },
    on: {
      "click": function ($event) {
        _vm.isSearchBox = !_vm.isSearchBox;
      }
    }
  })], 1)], 1) : _vm._e()]), _c('MembersModal', {
    attrs: {
      "channel_id": _vm.channel_id,
      "members": _vm.members,
      "channel": _vm.channel,
      "channelData": _vm.channelData,
      "currentUserMember": _vm.currentUserMember
    }
  }), _c('AddMembersInChatModal', {
    attrs: {
      "members": _vm.members,
      "channel": _vm.channel,
      "channelData": _vm.channelData
    }
  }), _c('CreateChannelModal', {
    on: {
      "complete": _vm.onCompleteCreateChannel
    }
  }), _c('ChannelSettingModal', {
    attrs: {
      "modalName": `${_vm.random_id}_ChannelSettingModal`,
      "channel": _vm.channel,
      "channel_id": _vm.channel_id,
      "channelData": _vm.channelData,
      "currentUserMember": _vm.currentUserMember,
      "name": _vm.name,
      "type": _vm.type,
      "image": _vm.image
    },
    on: {
      "members": function ($event) {
        return _vm.pin('members');
      }
    }
  })], 1) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }