var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-likeSomeThing"
  }, [_c('div', {
    staticClass: "dialer-box"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v(" Call activity ")]), _vm._m(0), _c('div', {
    staticClass: "d-flex w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mr-20px wd-20"
  }, [_c('label', [_vm._v("Date")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "selected": 5,
      "options": [{
        text: 'Last 14 days',
        value: 5
      }, {
        text: 'Last 14 days',
        value: 10
      }, {
        text: 'Last 14 days',
        value: 15
      }, {
        text: 'Last 14 days',
        value: 20
      }, {
        text: 'Last 14 days',
        value: 25
      }]
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mr-20px wd-20"
  }, [_c('label', [_vm._v("Date breakdown")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "selected": 5,
      "options": [{
        text: 'is daily',
        value: 5
      }, {
        text: 'is daily',
        value: 10
      }, {
        text: 'is daily',
        value: 15
      }, {
        text: 'is daily',
        value: 20
      }, {
        text: 'is daily',
        value: 25
      }]
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mr-20px wd-20"
  }, [_c('label', [_vm._v("Numbers")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "selected": 5,
      "options": [{
        text: 'is any value',
        value: 5
      }, {
        text: 'is any value',
        value: 10
      }, {
        text: 'is any value',
        value: 15
      }, {
        text: 'is any value',
        value: 20
      }, {
        text: 'is any value',
        value: 25
      }]
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mr-20px wd-20"
  }, [_c('label', [_vm._v("SLA (in sec)")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "selected": 5,
      "options": [{
        text: 'is 30',
        value: 5
      }, {
        text: 'is 30',
        value: 10
      }, {
        text: 'is 30',
        value: 15
      }, {
        text: 'is 30',
        value: 20
      }, {
        text: 'is 30',
        value: 25
      }]
    }
  })], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion wd-20"
  }, [_c('label', [_vm._v("Timezone")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "selected": 5,
      "options": [{
        text: 'UTC',
        value: 5
      }, {
        text: 'UTC',
        value: 10
      }, {
        text: 'UTC',
        value: 15
      }, {
        text: 'UTC',
        value: 20
      }, {
        text: 'UTC',
        value: 25
      }]
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex w-100 mt-32px"
  }, [_c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading"
  }, [_vm._v("178")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Total calls")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleSuccess-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller success"
  }, [_vm._v("235.8%")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)]), _c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading newPurple"
  }, [_vm._v("8")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Total inbound calls")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleDanger-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller danger"
  }, [_vm._v("-75.0%")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)]), _c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading basicPurple"
  }, [_vm._v("170")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Total outbound calls")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleSuccess-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller success"
  }, [_vm._v("709.5%")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)]), _c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading"
  }, [_vm._v("00:00:32")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Average call talk time")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleSuccess-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller success"
  }, [_vm._v("50.8%")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)])]), _c('div', {
    staticClass: "mt-15px likeSomething-box w-100"
  }), _c('div', {
    staticClass: "mt-45px likeSomething-sectionHeading"
  }, [_vm._v("Inbound calls")]), _c('div', {
    staticClass: "d-flex w-100 mt-45px"
  }, [_c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading redSomething"
  }, [_vm._v("75.0%")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Missed calls")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleDanger-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller danger"
  }, [_vm._v("40.6%")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)]), _c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading"
  }, [_vm._v("100.0%")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Service level")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleNormal-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("0.0")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)]), _c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading"
  }, [_vm._v("00:00:04")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Average waiting duration")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleSuccess-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller success"
  }, [_vm._v(" -00:00:04")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)]), _c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading"
  }, [_vm._v("00:00:12")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Average inbound talk time")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleDanger-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller danger"
  }, [_vm._v("-71.7%")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)])]), _vm._m(1), _c('div', {
    staticClass: "mt-15px likeSomething-box w-100"
  }), _c('div', {
    staticClass: "mt-45px likeSomething-sectionHeading"
  }, [_vm._v("Inbound calls")]), _c('div', {
    staticClass: "d-flex w-100 mt-45px"
  }, [_c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading success"
  }, [_vm._v("90.6%")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Connected rate")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleSuccess-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller success"
  }, [_vm._v("9.6")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)]), _c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading"
  }, [_vm._v("00:00:32")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Average outbound talk time")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleDanger-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller danger"
  }, [_vm._v("-00:00:51")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)])]), _c('div', {
    staticClass: "mt-15px likeSomething-box w-100"
  }), _c('div', {
    staticClass: "mt-45px likeSomething-sectionHeading"
  }, [_vm._v("Tags")]), _c('div', {
    staticClass: "d-flex w-100 mt-45px"
  }, [_c('div', {
    staticClass: "wd-33-imp likeSomething-box align-items-center justify-content-center"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading success"
  }, [_vm._v("90.6%")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Connected rate")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1)]), _c('div', {
    staticClass: "likeSomething-box flex-fill"
  })]), _c('div', {
    staticClass: "mt-45px likeSomething-sectionHeading"
  }, [_vm._v("Calls by main participant")]), _c('div', {
    staticClass: "likeSomething-box-text mt-24px mb-32px text-left"
  }, [_vm._v(" The main participant of a call is whoever finalizes it. A call may have more than one participant, but on this chart only the last one gets credited for the call. Missed inbound calls and transferred outbound calls don’t have main users associated to them. ")]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "isListEmpty": _vm.sampleData.length == 0,
      "listLength": _vm.sampleData.length,
      "perPage": 5
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.sampleData, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.id
          }, [_c('td', [_vm._v(_vm._s(data.one))]), _c('td', [_vm._v(_vm._s(data.two))]), _c('td', [_vm._v(_vm._s(data.three))]), _c('td', [_vm._v(_vm._s(data.four))])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("User")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("Total calls finalized by agent")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("Inbound calls finalized by agent")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("Outbound calls finalized by agent ")])])])]), _c('div', {
    staticClass: "mt-45px likeSomething-sectionHeading"
  }, [_vm._v("SMS")]), _c('div', {
    staticClass: "d-flex mt-45px w-100"
  }, [_c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading"
  }, [_vm._v("0")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Total SMS")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleNormal-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("0%")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)]), _c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading"
  }, [_vm._v("0")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Inbound SMS")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleNormal-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("0%")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)]), _c('div', {
    staticClass: "likeSomething-box flex-fill"
  }, [_c('div', {
    staticClass: "likeSomething-box-heading"
  }, [_vm._v("0")]), _c('div', {
    staticClass: "likeSomething-box-text"
  }, [_c('span', [_vm._v("Outbound SMS")]), _c('vb-icon', {
    attrs: {
      "icon": "latest-infoWithCircle-icon"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center mt-24px"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "latest-triangleNormal-icon",
      "width": "18px",
      "height": "11.617px"
    }
  }), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("0%")]), _c('div', {
    staticClass: "likeSomething-box-text-smaller"
  }, [_vm._v("vs previous period")])], 1)])]), _c('div', {
    staticClass: "mt-15px likeSomething-box w-100"
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "dialer-settings-intro newerTextVersion"
  }, [_c('span', [_vm._v(" Here’s where you can monitor calls and users. ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex w-100 mt-15px justify-content-between"
  }, [_c('div', {
    staticClass: "wd-62-imp likeSomething-box"
  }), _c('div', {
    staticClass: "likeSomething-box-withHeader"
  }, [_c('div', {
    staticClass: "withHeader"
  }, [_vm._v(" The inbound calls breakdown by missed reason is: ")]), _c('div', {
    staticClass: "withBody"
  }, [_c('div', {
    staticClass: "with-tag-row"
  }, [_c('div', {
    staticClass: "with-tag-row-tagItself"
  }, [_vm._v(" 25.0% ")]), _c('div', {
    staticClass: "with-tag-row-text lighter"
  }, [_vm._v(" of calls were not missed. ")])]), _c('div', {
    staticClass: "with-tag-row"
  }, [_c('div', {
    staticClass: "with-tag-row-tagItself lightOrange"
  }, [_vm._v(" 62.5% ")]), _c('div', {
    staticClass: "with-tag-row-text"
  }, [_vm._v(" of calls were not answered by an agent because no user was available. ")])]), _c('div', {
    staticClass: "with-tag-row"
  }, [_c('div', {
    staticClass: "with-tag-row-text regular"
  }, [_vm._v(" To reduce this, consider adding more users. ")])]), _c('div', {
    staticClass: "with-tag-row"
  }, [_c('div', {
    staticClass: "with-tag-row-tagItself orange"
  }, [_vm._v(" 12.5% ")]), _c('div', {
    staticClass: "with-tag-row-text"
  }, [_vm._v(" of calls were not answered by an agent because no available user picked up the call. ")])]), _c('div', {
    staticClass: "with-tag-row"
  }, [_c('div', {
    staticClass: "with-tag-row-text regular"
  }, [_vm._v(" To reduce this communication a clear strategy to your users. ")])])])])]);

}]

export { render, staticRenderFns }