<template>
  <div>
    <modal
      :name="modalName"
      class="EditSpaceModal"
      width="50%"
      height="auto"
      :scrollable="true"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
    >
      <div class="dialer-edit-header w-100 d-flex position-relative py-0 justify-content-between align-items-center">
        <h2 class="dialer-edit-title my-0">Edit space</h2>
        <div class="dialer-edit-actions">
          <button class="newCloseButton" @click="$modal.hide(modalName)" type="button" >
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <!-- <vb-loading v-if="api.get_space.send && isEmpty(response.space)" /> -->
      <template v-if="api.get_space.send && isEmpty(response.space)">
        <div class="createSpace-greyBox mt-50px">
          <div class="createSpace-whiteBorderedBox">
            <div class="createSpace-whiteBorderedBox-row" v-for="n in 5" :key="n.id">
              <div class="latestShimmerDesign w-25" style="height:24px;"></div>
              <div class="latestShimmerDesign w-25" style="height:24px;"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div v-if="conditions.screen==screens.select.value" class="createSpace-greyBox mt-50px">
          <div class="createSpace-whiteBorderedBox">
            <div @click="api.update_space.send ? '' : conditions.screen=screens.name.value" class="createSpace-whiteBorderedBox-row">
              <div class="TODO-smallInnerHeading">Space name</div>
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="createSpace-editableText">{{ forms.update_space.name }}</div>
                  <p 
                    v-if="(forms.update_space.submitted && $v.forms.update_space.name.$invalid) || api.update_space.validation_errors.name"
                    class="mb-1 text-small text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update_space.name.required">Space name is required</span>
                    <span v-else-if="!$v.forms.update_space.name.valid">Space name can be only alphabet or number</span>
                    <span v-else-if="!$v.forms.update_space.name.minLength">Space name should be minimum {{$v.forms.update_space.name.$params.minLength.min}} character</span>
                    <span v-else-if="!$v.forms.update_space.name.maxLength">Space name can be maximum {{$v.forms.update_space.name.$params.maxLength.max}} character</span>
                    <span v-for="(em,i) in api.update_space.validation_errors.name" :key="i">{{ em }}</span>
                  </p>
                </div>
                <b-icon icon="chevron-right" class="darker-chevron-down" />
              </div>
            </div>
            <div @click="api.update_space.send ? '' : conditions.screen=screens.prefix.value" class="createSpace-whiteBorderedBox-row">
              <div class="TODO-smallInnerHeading">Space prefix</div>
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="createSpace-editableText">{{ forms.update_space.prefix }}</div>
                  <p 
                    v-if="(forms.update_space.submitted && $v.forms.update_space.prefix.$invalid) || api.update_space.validation_errors.prefix"
                    class="mb-1 text-small text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update_space.prefix.required">Space prefix is required</span>
                    <span v-else-if="!$v.forms.update_space.prefix.alpha">Space prefix can only be alphabet</span>
                    <span v-else-if="!$v.forms.update_space.prefix.minLength">Space prefix should be minimum {{$v.forms.update_space.prefix.$params.minLength.min}} character</span>
                    <span v-else-if="!$v.forms.update_space.prefix.maxLength">Space prefix can be maximum {{$v.forms.update_space.prefix.$params.maxLength.max}} character</span>
                    <span v-for="(em,i) in api.update_space.validation_errors.prefix" :key="i">{{ em }}</span>
                  </p>
                </div>
                <b-icon icon="chevron-right" class="darker-chevron-down" />
              </div>
            </div>
            <div @click="api.update_space.send ? '' : conditions.screen=screens.color.value" class="createSpace-whiteBorderedBox-row">
              <div class="TODO-smallInnerHeading">Space color</div>
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="createSpace-selectColor-container">
                    <div 
                      class="createSpace-selectColor-item smaller selected"
                      :class="{
                        'TransparentSelected': forms.update_space.color == 'rgba(0,0,0,0)'
                      }"
                      :style="`background-color:${forms.update_space.color};`"
                    />
                  </div>
                  <p 
                    v-if="(forms.update_space.submitted && $v.forms.update_space.color.$invalid) || api.update_space.validation_errors.color"
                    class="mb-1 text-small text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update_space.color.required">Color is required</span>
                    <span v-else-if="!$v.forms.update_space.color.validColor">Color is invalid</span>
                    <span v-for="(em,i) in api.update_space.validation_errors.color" :key="i">{{ em }}</span>
                  </p>
                </div>
                <b-icon icon="chevron-right" class="darker-chevron-down" />
              </div>
            </div>
            <div 
              v-if="response.space.status!='private'" 
              @click="api.update_space.send ? '' : conditions.screen=screens.type.value" 
              class="createSpace-whiteBorderedBox-row"
            >
              <div class="TODO-smallInnerHeading">Type</div>
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column">
                  <div class="createSpace-editableText">{{ types | get_property(`${forms.update_space.type}.text`) }}</div>
                  <p 
                    v-if="(forms.update_space.submitted && $v.forms.update_space.type.$invalid) || api.update_space.validation_errors.type"
                    class="mb-1 text-small text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.update_space.type.required">Type is required</span>
                    <span v-else-if="!$v.forms.update_space.type.valid">Type is invalid</span>
                    <span v-for="(em,i) in api.update_space.validation_errors.type" :key="i">{{ em }}</span>
                  </p>
                </div>
                <b-icon icon="chevron-right" class="darker-chevron-down" />
              </div>
            </div>
            <div v-if="response.space.type=='private'" @click="api.update_space.send ? '' : $modal.show('AddRemoveSpaceMembers',{ space_id: data.space_id, type: 'space_members' })" class="createSpace-whiteBorderedBox-row">
              <div class="TODO-smallInnerHeading">Members</div>
              <div class="d-flex align-items-center">
                <div class="createSpace-editableText">
                  {{ response.space | get_property('members.length',0) }}
                </div>
                <b-icon icon="chevron-right" class="darker-chevron-down" />
              </div>
            </div>
            <div @click="api.update_space.send ? '' : conditions.screen=screens.status.value" class="createSpace-whiteBorderedBox-row">
              <div class="TODO-smallInnerHeading">Task statuses</div>
              <div class="d-flex align-items-center">
                <div class="createSpace-editableText">
                  {{ data.status_count }}
                </div>
                <b-icon icon="chevron-right" class="darker-chevron-down" />
              </div>
            </div>
            <div @click="api.update_space.send ? '' : conditions.screen=screens.group.value" class="createSpace-whiteBorderedBox-row">
              <div class="TODO-smallInnerHeading">Groups</div>
              <div class="d-flex align-items-center">
                <div class="createSpace-editableText">
                  {{ data.group_count }}
                </div>
                <b-icon icon="chevron-right" class="darker-chevron-down" />
              </div>
            </div>
            <div 
              @click="api.update_space.send ? '' : $modal.show('TodoTaskMove',{ 
                moved: true, 
                payload: { 
                  space_id: data.space_id, 
                  flag: 'space' 
                }
              })" 
              class="createSpace-whiteBorderedBox-row"
            >
              <div class="TODO-smallInnerHeading">Move tasks</div>
              <div class="d-flex align-items-center">
                <div class="createSpace-editableText">
                  
                </div>
                <b-icon icon="chevron-right" class="darker-chevron-down" />
              </div>
            </div>
          </div>
        </div>
        <div v-if="conditions.screen==screens.name.value" class="createSpace-greyBox mt-24px">
          <div class="whiteBGinputWithGreyRoundedBorder">
            <label>Space name</label>
            <input 
              class="w-100 bg-white" 
              type="text" 
              placeholder="Space title"
              @input="api.get_is_unique.error_message=''"
              v-model="forms.update_space.name"
              :maxlength="$v.forms.update_space.name.$params.maxLength.max"
            />
            <p 
              v-if="$v.forms.update_space.name.$invalid || api.update_space.validation_errors.name || api.get_is_unique.error_message"
              class="mb-1 text-small text text-danger animated bounceIntop"
            >
              <span v-if="api.get_is_unique.error_message">{{api.get_is_unique.error_message}}</span>
              <span v-if="!$v.forms.update_space.name.required">Name is required</span>
              <span v-else-if="!$v.forms.update_space.name.minLength">Name should be minimum {{$v.forms.update_space.name.$params.minLength.min}} character</span>
              <span v-else-if="!$v.forms.update_space.name.maxLength">Name can be maximum {{$v.forms.update_space.name.$params.maxLength.max}} character</span>
              <span v-else-if="!$v.forms.update_space.name.valid">Name can not use special character</span>
              <span v-for="(em,i) in api.update_space.validation_errors.name" :key="i">{{ em }}</span>
            </p>
          </div>
        </div>
        <div v-if="conditions.screen==screens.prefix.value" class="createSpace-greyBox mt-24px">
          <div class="whiteBGinputWithGreyRoundedBorder">
            <label>Space prefix</label>
            <input 
              class="w-100 bg-white" 
              type="text" 
              placeholder="Space prefix"
              v-model="forms.update_space.prefix"
              :maxlength="$v.forms.update_space.prefix.$params.maxLength.max"
            />
            <p 
              v-if="$v.forms.update_space.prefix.$invalid || api.update_space.validation_errors.prefix"
              class="mb-1 text-small text text-danger animated bounceIntop"
            >
              <span v-if="!$v.forms.update_space.prefix.required">Prefix is required</span>
              <span v-else-if="!$v.forms.update_space.prefix.minLength">Prefix should be minimum {{$v.forms.update_space.prefix.$params.minLength.min}} character</span>
              <span v-else-if="!$v.forms.update_space.prefix.maxLength">Prefix can be maximum {{$v.forms.update_space.prefix.$params.maxLength.max}} character</span>
              <span v-else-if="!$v.forms.update_space.prefix.alpha">Prefix can only be alphabet</span>
              <span v-for="(em,i) in api.update_space.validation_errors.prefix" :key="i">{{ em }}</span>
            </p>
          </div>
        </div>
        <div v-if="conditions.screen==screens.color.value" class="createSpace-greyBox mt-24px">
          <div class="whiteBGinputWithGreyRoundedBorder">
            <label>Space color</label>
            <div class="createSpace-selectColor-container">
              <div v-for="color in colors" :key="color.id" @click="api.update_space.send ? '' : forms.update_space.color=color" class="createSpace-selectColor-item-container">
                <div 
                  class="createSpace-selectColor-item"
                  :class="{
                    'selected': forms.update_space.color==color,
                    'TransparentSelected': color == 'rgba(0,0,0,0)',
                  }"
                  :style="`background-color:${color};`"
                >
                  <vb-icon v-if="color=='rgba(0,0,0,0)'" icon="todo-transparentColored-icon" height="28px" width="30px" />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div v-if="conditions.screen==screens.type.value" class="mt-24px">
          <div class="whiteBGinputWithGreyRoundedBorder">
            <label>Space type</label>
          </div>
          <div class="d-flex align-items-stretch justify-content-between">
            <div 
              v-for="type in types" 
              :key="type.value" 
              class="todoList-blackBordered-box halfWidth" 
              :class="{
                'selected': forms.update_space.type==type.value
              }"
              @click="api.update_space.send ? '' : forms.update_space.type=type.value"
            >
              <vb-icon :icon="type.icon" width="92.998px" height="59.917px" />
              <div class="todoList-blackBordered-box-heading mt-24px">{{ type.text }}</div>
            </div>
          </div>
        </div>
        <Status 
          ref="status" 
          v-show="conditions.screen==screens.status.value" 
          :space_id="data.space_id" 
          @back="conditions.screen=screens.select.value" 
          @status-count="data.status_count=$event"
          @update="$emit('status-update')"
          @create="$emit('status-create')"
          @remove="$emit('status-remove')"
        />
        <Groups 
          ref="group" 
          v-show="conditions.screen==screens.group.value" 
          :space_id="data.space_id" 
          @back="conditions.screen=screens.select.value" 
          @group-count="data.group_count=$event"
          @update="$emit('group-update')"
          @create="$emit('group-create')"
          @remove="$emit('group-remove')"
        />
        <div class="d-flex align-items-center justify-content-between mt-38px">
          <div>
            <div 
              v-if="conditions.screen!=screens.select.value" 
              class="d-flex align-items-center" 
              style="cursor:pointer"
              @click="back()"
            >
              <b-icon icon="chevron-left" class="darker-chevron-down mx-0" />
              <b class="darker-chevron-down ml-1">Back</b>
            </div>
            <!-- <div 
              v-if="conditions.screen==screens.select.value && isAnyChangings" 
              class="d-flex align-items-center SaveChanges" 
              style="cursor:pointer"
              @click="update()"
            >
              Save changes
            </div> -->
          </div>
          <button class="fullWidthDoneButton w-100"
            v-if="conditions.screen==screens.select.value && isAnyChangings" 
            @click="update()"
          >
            Save changes
          </button>
        </div>
      </template>
    </modal>
    <AddRemoveFromList :modalName="'AddRemoveSpaceMembers'" @update-list="getSpace();$emit('update-list')" />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import required from 'vuelidate/lib/validators/required';
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
import validateColor from "validate-color";
import Status from '@/components/widgets/todo/Status.vue';
import Groups from '@/components/widgets/todo/Groups.vue';
import AddRemoveFromList from '../AddRemoveFromList.vue';
import { APP_COLORS } from '@/constants';
import { helpers } from 'vuelidate/lib/validators';
import alpha from 'vuelidate/lib/validators/alpha';
const types = {
  public: {
    text: 'Voip Business Workspace',
    icon: 'todo-voipbusinessWorkspace-icon',
    value: 'public',
  },
  private: {
    text: 'Private',
    icon: 'todo-private-icon',
    value: 'private',
  },
}
const screens = {
  select: {
    text: 'select',
    value: 'select',
  },
  name: {
    text: 'name',
    value: 'name',
  },
  prefix: {
    text: 'Prefix',
    value: 'prefix',
  },
  color: {
    text: 'color',
    value: 'color',
  },
  type: {
    text: 'type',
    value: 'type',
  },
  status: {
    text: 'status',
    value: 'status',
  },
  group: {
    text: 'group',
    value: 'group',
  },
}
export default {
  name: "EditSpaceModal",
  components: { 
    Status, 
    Groups,
    AddRemoveFromList,
  },
  inject:[
    'isEmpty','appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'EditSpace'
    },
  },
  data() {
    return {
      forms: {
        update_space: this.$helperFunction.formInstance({
          data: {
            name: '',
            prefix: '',
            color: '',
            type: '',
          },
        }),
      },
      api: {
        update_space: this.$helperFunction.apiInstance({ 
          validation_errors: true,
        }),
        get_space: this.$helperFunction.apiInstance({  }),
        get_is_unique: this.$helperFunction.apiInstance({
          error_message: true,
        }),
      },
      response: {
        space: {},
      },
      data: {
        space_id: '',
        status_count: 0,
        group_count: 0,
      },
      conditions: {
        screen: screens.select.value,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    types(){ return types },
    colors(){ return APP_COLORS },
    screens() { return screens },
    isAnyChangings(){ 
      if(this.forms.update_space.name!=this.response.space.name) return true 
      else if(this.forms.update_space.color!=this.response.space.color) return true 
      else if(this.forms.update_space.type!=this.response.space.type) return true 
      else if(this.forms.update_space.prefix!=this.response.space.prefix) return true 
      else return false 
    },
  },
  validations: {
    forms: {
      update_space: {
        name: {
          required: required,
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
          minLength: minLength(3),
          maxLength: maxLength(15),
        },
        prefix: {
          required: required,
          alpha: alpha,
          minLength: minLength(1),
          maxLength: maxLength(3),
        },
        color: {
          required: required,
          validColor: (value) => { return validateColor(value) },
        },
        type: {
          required: required,
          valid: (value) => { return !!types[value] },
        },
      },
    }
  },
  methods: {
    onBeforeOpen(event) {
      this.data.space_id=event?.params?.space_id ?? ''
      this.getSpace()
    },
    onBeforeClose() {
      this.forms.update_space.reset()
      this.api.update_space.reset()
      this.api.get_space.reset()
      this.api.get_is_unique.reset()
      this.response.space={}
      this.data.space_id=''
      this.data.status_count=0
      this.data.group_count=0
      this.conditions.screen=screens.select.value
    },
    
    async checkNameUnique(){
      try {
        this.$v.forms.update_space.name.$touch()
        if(this.api.get_is_unique.send || this.$v.forms.update_space.name.$invalid) return;
        this.api.get_is_unique.send=true
        this.api.get_is_unique.error_message=''
        await VOIP_API.endpoints.task_spaces.checkName({
          uid: this.getCurrentUser.uid,
          space_id: this.data.space_id,
          name: this.forms.update_space.name,
          flag: 'space',
        })
        this.conditions.screen=screens.select.value
      } catch (ex) {
        this.api.get_is_unique.error_message=ex.own_message
      } finally {
        this.api.get_is_unique.send=false
      }
    },
    async update(){
      this.forms.update_space.submitted=true
      this.$v.forms.update_space.$touch()
      if(this.$v.forms.update_space.$invalid || this.api.update_space.send) return;
      try {
        this.api.update_space.send=true
        this.api.update_space.validation_errors={}
        await VOIP_API.endpoints.task_spaces.update(this.data.space_id,{
          name: this.forms.update_space.name,
          prefix: this.forms.update_space.prefix,
          color: this.forms.update_space.color,
          type: this.forms.update_space.type,
          uid: this.getCurrentUser.uid,
        })
        this.getSpace()
        this.$emit('updated')
        this.$emit('update-list')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
        this.api.update_space.validation_errors=ex.own_errors ?? {}
      } finally {
        this.forms.update_space.submitted=false
        this.api.update_space.send=false
      }
    },
    async getSpace(){
      if(this.api.get_space.send) return;
      try {
        this.api.get_space.send=true
        const { data } = await VOIP_API.endpoints.task_spaces.get(this.data.space_id,{
          uid: this.getCurrentUser.uid
        })
        this.response.space=data
        this.forms.update_space.name=data.name ?? ''
        this.forms.update_space.prefix=data.prefix ?? ''
        this.forms.update_space.color=data.color ?? ''
        this.forms.update_space.type=data.type ?? ''
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.get_space.send=false
      }
    },
    back(){
      if(this.conditions.screen==screens.status.value) this.$refs.status?.back?.()
      else if(this.conditions.screen==screens.group.value) this.$refs.group?.back?.()
      else if(this.conditions.screen==screens.name.value) {
        this.checkNameUnique()
      }
      else if(this.conditions.screen==screens.color.value) this.conditions.screen=screens.select.value
      else if(this.conditions.screen==screens.type.value) this.conditions.screen=screens.select.value
      else if(this.conditions.screen==screens.prefix.value) this.conditions.screen=screens.select.value
    },
  },
}
</script>

<style>

</style>