<template>
  <modal :name="modalName" class="dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 min-width-50percent-modal" :scrollable="true" height="auto" @before-open="onBeforeOpen" @before-close="onBeforeClose" >
    <section class="">
      <div class="dialer-edit-header">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Incoming call presentation setting</h2>
        </div>
        <div class="dialer-edit-actions">
          <!-- <button class="dialer-button dialer-button-delete"  type="button" @click="$modal.hide(modalName)">Close</button> -->
          <button class="newDoneButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="whiteBoxWithBorderInsideGreyBox withOutPadding"> 
          <b-form-group class="dialer-call-options-list mb-0 " style="position: relative;pointer-events:auto;">
            <b-form-radio-group>
              <b-form-radio v-for="cldOption in cldOptions" @change="updateSetting();" class="whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare" :class="{active: selected.clid_display == cldOption.value}" :key="cldOption.value" :value="cldOption.value" v-model="selected.clid_display">
                {{cldOption.text}}
              </b-form-radio> 
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div>
      <!-- <div id="calls-setting-call-settings" class="dialer-box alt position-relative">
        <div class="dialer-call-options options">
          <b-form-group class="dialer-call-options-list mb-0 " style="position: relative;pointer-events:auto;">
            <b-form-radio-group>
              <b-form-radio v-for="cldOption in cldOptions" @change="updateSetting();" class="radio_design makeCheckBoxesSquare" :class="{active: selected.clid_display == cldOption.value}" :key="cldOption.value" :value="cldOption.value" v-model="selected.clid_display">
                {{cldOption.text}}
              </b-form-radio> 
            </b-form-radio-group>
          </b-form-group>
        </div>
      </div> -->
    </section>
  </modal>
</template>

<script>
// import { VOIP_API } from '../../utils';
// import { minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { VOIP_API } from '../../utils';
// import _ from 'lodash'

export default {
  name: "IncommingcallDisplaySettingModal",
  props: {
    modalName: {
      type: String,
      default: "IncommingcallDisplaySettingModal",
    },
  },
  data() {
    return {
      selected: {
        clid_display: '',
      },
      data: {
        account: '',
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getCurrentUser: 'getCurrentUser',
    }),
    cldOptions(){
      return [
        { text: "Display number only", value: "0" },
        // { text: "Display DID name", value: "1" },
        // { text: "Display DID number", value: "2" },
        // { text: "Display Account number", value: "3" },
        // { text: "Display Account name", value: "4" },
        { text: "Display speed dial reference name", value: "5" },
      ]
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.account = event?.params?.account
      this.fetchUserDetail()
    },
    onBeforeClose(){
      this.selected.clid_display=''
    },
    fetchUserDetail() {
      let vm = this
      VOIP_API.endpoints.users.detail(this.data.account)
      .then(({ data: detail }) => {
        vm.selected.clid_display = detail?.select_clid_display ?? '';
      })
    },
    
    updateSetting() {
      // console.log('run')
      let vm = this;
      VOIP_API.endpoints.users.updateuserdetail({
        field: "select_clid_display",
        accountcode: vm.data.account,
        uid: vm.getCurrentUser.uid,
        value: vm.selected.clid_display,
      })
      .then(() => {
        vm.$emit('latest')
        this.appNotify({
          message: 'Successfully updated!',
          type: 'success',
        })
      })
      .catch((ex) => {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      });
    },
  },
};
</script>

