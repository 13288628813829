<template>
  <div>
    <div class="mt-20px">
      <div class="whiteBoxWithBorderInsideGreyBox">
        <div class="whiteBoxWithBorderInsideGreyBox-row">
          <div class="w-100">
            <div class="latestGreyBox-heading-main">Location name</div>
            <div v-if="isEmpty(response.emergency_service)" class="innerRedBoxwithRoundedBorder mt-20px">
              <vb-icon icon="emergencyLocation-error-icon" width="24.034px" height="24px" class="dialer-thunder-icon"/>
              <p class="innerRedBoxwithRoundedBorder-text mb-0">
                This is used  for emergency services. Currently you have not set the address for the number
              </p>
            </div>
            <div class="w-100 d-flex align-items-center justify-content-between mt-20px">
              <p class="latestGreyBox-descriptionText mt-0 mb-0 w-75">
                {{response.emergency_service | get_property('full_location')}} {{ response.emergency_service | get_property('postcode') }}
              </p>
              <button class="newButton" type="button" @click="isEmpty(response.emergency_service) ? $modal.show(`${_uid}-AddEmergencyLocationModal`) : $modal.show(`${_uid}-UpdateEmergencyLocationModal`,{ location: response.emergency_service })">
                <vb-icon :icon="`squared-${isEmpty(response.emergency_service) ? 'add' : 'update'}-icon`" height="38px" width="38px" />
                <span class="newButton-textPart">{{isEmpty(response.emergency_service) ? 'Add' : 'Update'}}</span>
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
    <AddEmergencyLocationModal :modalName="`${_uid}-AddEmergencyLocationModal`" :did="did" @added="getEmergencyService()" />
    <UpdateEmergencyLocationModal :modalName="`${_uid}-UpdateEmergencyLocationModal`" :did="did" @updated="getEmergencyService()" />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import AddEmergencyLocationModal from '@/components/Modals/AddEmergencyLocationModal.vue';
import UpdateEmergencyLocationModal from '@/components/Modals/UpdateEmergencyLocationModal.vue';
import moment from 'moment-timezone';
import _ from 'lodash';
import { mapGetters } from 'vuex';
import Timer from "timer.js";
export default {
  name: 'EmergencyServiceWidget',
  components: { 
    AddEmergencyLocationModal,
    UpdateEmergencyLocationModal,
  },
  inject: [
    'isEmpty','appNotify'
  ],
  props: {
    did: {
      type: String,
      default: '',
      require: true,
    },
  },
  data() {
    return {
      api: {
        get_emergeny_service: this.$helperFunction.apiInstance(),
      },
      response: {
        emergency_service: {},
      },
      conditions: {
        can_update: true,
      },
      timer: {
        check_24_hours: null,
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
  },
  methods: {
    async getEmergencyService(){
      if(this.api.get_emergeny_service.send) return;
      try {
        this.api.get_emergeny_service.send=true
        const { data } = await VOIP_API.endpoints.location.getSingleEmergencyAddress(this.did,{
          number: this.did,
          uid: this.getCurrentUser.uid,
        })
        this.response.emergency_service=data ?? {}
        this.check24Hour()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.get_emergeny_service.send=false
      }
    },
    check24Hour: _.debounce(function (){
      let vm = this
      const address_updated_at = this.response.emergency_service?.address_updated_at
      if(address_updated_at){
        const deleted_date = moment.utc(address_updated_at,'YYYY-MM-DD hh:mm:ss')
        const allow = deleted_date.add(moment.duration(24, 'hours'));
        const current_date = moment.utc()
        const diff = allow.diff(current_date,'seconds',true)
        this.timer.check_24_hours?.stop()
        if(diff>0){
          this.timer.check_24_hours = new Timer({
            tick: 1,
            onend() {
              if(vm._isDestroyed){
                vm.timer.check_24_hours?.stop()
              } else {
                vm.conditions.can_update=true
              }
            },
          });
          this.timer.check_24_hours.start(diff);
          vm.conditions.can_update=false
        }
      }
    }, 1*1000),
  },
  mounted(){
    this.getEmergencyService()
  },
  beforeDestroy(){
    this.timer.check_24_hours?.stop()
  },
}
</script>

<style>

</style>