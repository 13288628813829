var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading"
  }, [_vm._v("Documents")]), _c('div', {
    staticClass: "mainDescription"
  }, [_c('br')]), _c('div', {
    staticClass: "grayCard flexWrap"
  }, [_c('div', {
    staticClass: "whiteSubCard halfWidth mb-0"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Documents in last 30 days ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("24")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth mb-0"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" Total uploaded ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("50")])])])])]);

}]

export { render, staticRenderFns }