
import { VOIP_API } from "../index"

export const CALLER_ID_API_ENDPOINTS = {
  checkBeforeAdd(data={}){
    return VOIP_API.axios.voip.request({
      url: `verifycalleridExist`,
      method: 'POST',
      data,
    })
  },
  callOtp(data={}){
    return VOIP_API.axios.voip.request({
      url: `add-phone-caller-id`,
      method: 'POST',
      data,
    })
  },
  extensions(data){
    return VOIP_API.axios.voip.request({
      url: `get-callerid-extensions`,
      method: 'POST',
      data
    })
  },
  updateextensions(data){
    return VOIP_API.axios.voip.request({
      url: `set-callerid-extensions`,
      method: 'POST',
      data
    })
  },
  addCallerId(data={}){
    return VOIP_API.axios.voip.request({
      url: `add-phone-caller-id`,
      method: 'POST',
      data
    })
  },
  getCalleridsByAccountcode(params={}){
    return VOIP_API.axios.voip.request({
      url: `account-callerids`,
      method: 'GET',
      params
    })
  },
  assignCallerIds(data={}){
    return VOIP_API.axios.voip.request({
      url: `update-callerid-numbers`,
      method: 'POST',
      data
    })
  },
  changeAllowAddCallerID(data={}){
    return VOIP_API.axios.voip.request({
      url: `allowAddCallerId`,
      method: 'POST',
      data
    })
  },
  verifypin(data={}){
    return VOIP_API.axios.voip.request({
      url: `verify-phone-caller-id`,
      method: 'POST',
      data
    })
  },
}