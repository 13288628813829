// import { centrifugo } from "../Centrifuge";
import { TokenService } from "../services";
import { LOGGER, VOIP_API } from "../utils";
var Emitter = require('component-emitter');

let events = {
  receive: {
    DIAL_NUMBER: 'DIAL_NUMBER',
    DESKTOP_PERMISSION_CHANGE: 'DESKTOP_PERMISSION_CHANGE',
    NOTIFICATION_CLICKED: 'NOTIFICATION_CLICKED',
    READY: 'READY',
    USER_DATA: 'USER_DATA',
  },
  send: {
    LOGOUT: 'LOGOUT',
    IFRAME_LOADED: 'IFRAME_LOADED',
    CHAT_DESKTOP_NOTIFICATION: 'CHAT_DESKTOP_NOTIFICATION',
    TEST_DESKTOP_NOTIFICATION: 'TEST_DESKTOP_NOTIFICATION',
    DESKTOP_NOTIFICATION: 'DESKTOP_NOTIFICATION',
    INCOMING_SIP_CALL_NOTIFICATION: 'INCOMING_SIP_CALL_NOTIFICATION',
    MISSED_SIP_CALL_NOTIFICATION: 'MISSED_SIP_CALL_NOTIFICATION',
    GET_DESKTOP_NOTIFICATION_PERMISSION: 'GET_DESKTOP_NOTIFICATION_PERMISSION',
    CHECK_DESKTOP_NOTIFICATION_PERMISSION: 'CHECK_DESKTOP_NOTIFICATION_PERMISSION',
    SWITCH_ORGANIZATION: 'SWITCH_ORGANIZATION',
    CALL_ENDED: 'CALL_ENDED',
    CALL_COMPLETED: 'CALL_COMPLETED',
    INCOMING_CALL: 'INCOMING_CALL',
    OUTGOING_CALL: 'OUTGOING_CALL',
    CALL_ANSWERED: 'CALL_ANSWERED',
  },
}
export const MOTHERSHIP = {
  is_ready: false,
  iframe: process.env.VUE_APP_IS_IFRAM ? true : false,
  get events(){ return events },
  init(){
    try {
      Emitter(this)
      if(process.env.VUE_APP_INTEGRATION!='true'){
        // if(this.iframe){
          window.addEventListener('message',this.onWindowMessage.bind(this),false)
        // } else {
        //   centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification.bind(this))
        //   centrifugo.notification_admin.on(centrifugo.notification_admin.events.message,this.onNotification.bind(this))
        //   // centrifugo.mothershipadmin.on(centrifugo.mothershipadmin.events.message,this.onNotification.bind(this))
        //   // centrifugo.mothership.on(centrifugo.mothership.events.message,this.onNotification.bind(this))
        // }
      }
      if(process.env.NODE_ENV !== 'production'){
        window.mothership = this
      }
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  onWindowMessage(MessageEvent){
    const servers = process.env.VUE_APP_ALLOWED_DIALER_LOGINS?.split?.(' ') ?? []
    if (servers.includes(MessageEvent.origin) && typeof MessageEvent?.data == 'string'){
      const data = JSON.parse(MessageEvent?.data ?? `{}`)
      const event = data?.event ?? '';
      const payload = data?.payload ?? {};
      LOGGER.event('Message From Mothership',data)
      switch (event) {
        case this.events.receive.DIAL_NUMBER:
          this.emit?.(this.events.receive.DIAL_NUMBER,payload)
          break;
        case this.events.receive.DESKTOP_PERMISSION_CHANGE:
          this.emit?.(this.events.receive.DESKTOP_PERMISSION_CHANGE,payload)
          break;
        case this.events.receive.NOTIFICATION_CLICKED:
          this.emit?.(this.events.receive.NOTIFICATION_CLICKED,payload)
          break;
        case this.events.receive.READY:
          this.emit?.(this.events.receive.READY,payload)
          this.is_ready=true
          break;
        case this.events.receive.USER_DATA:
          this.emit?.(this.events.receive.USER_DATA,payload)
          break;
        default:
          break;
      }
    }
  },
  onNotification(event){
    const { data: { section, type } } = event
    if (window.self !== window.top && section=='desktop_notification') {
      let data = type
      const { event, payload } = data || {}
      LOGGER.event('Message From Mothership',data)
      switch (event) {
        case this.events.receive.DESKTOP_PERMISSION_CHANGE:
          this?.emit?.(this.events.receive.DESKTOP_PERMISSION_CHANGE,payload)
          break;
        case this.events.receive.NOTIFICATION_CLICKED:
          this?.emit?.(this.events.receive.NOTIFICATION_CLICKED,payload)
          break;
        case this.events.receive.DIAL_NUMBER:
          this?.emit?.(this.events.receive.DIAL_NUMBER,payload)
          break;
      
        default:
          break;
      }
    }
  },
  sendData(event,payload={}){
    if(!this.events.send[event]) return;
    const data = { event, payload, }
    if(window.self !== window.top){
      if(this.iframe){
        window.top.postMessage(JSON.stringify(data), '*');
      } else {
        VOIP_API.endpoints.centrifuge.handledesktopevent({ data, account: TokenService.USER.get()?.account })
      }
    }
  },
}