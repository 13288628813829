<template>
  <modal 
    class="center-zoomIn-transition v2 m-w-500"
    :name="modalName" 
    width="500px" 
    height="auto" 
    :scrollable="true"
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <div class="w-100">
      <div class="dialer-edit-header w-100 pt-0 d-block">
        <div class="d-flex justify-content-between align-items-center">
          <h2 class="dialer-settings-title newer mb-0">Update label</h2>
          <div class="dialer-edit-actions">
            <a class="newCloseButton" @click="$modal.hide(modalName)">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <div class="vm--modal-text">Enter the name of the number below.</div>
      </div>
      <b-form class="w-100" @submit.prevent="updateLabel()">
        <div class="latestGreyBox-9-9-2023">
          <div class="whiteBGinputWithGreyRoundedBorder">
            <label>Label</label>
            <b-input v-model="forms.update_label.label" :disabled="api.update_label.send" />
            <p v-if="forms.update_label.submitted && $v.forms.update_label.label.$invalid" class="text text-danger animated bounceIntop">
              <!-- <span v-if="!$v.forms.update_label.label.required">Label is required</span> -->
              <span v-if="!$v.forms.update_label.label.alphaNumSpace">Label can be only alphabet and number</span>
            </p>
          </div>
        </div>
        <div class="w-100">
          <b-button :disabled="api.update_label.send" class="fullWidthDoneButton mt-20px" type="submit">
            <vb-spinner v-if="api.update_label.send" />
            Update
          </b-button>
        </div>
      </b-form>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils'
// import required from 'vuelidate/lib/validators/required'
import { helpers } from 'vuelidate/lib/validators'
export default {
  name: 'ChangeNumberLabelModal',
  props: {
    modalName: {
      type: String,
      default: 'ChangeNumberLabel',
    },
    numberId: {
      type: [Number,String],
      default: '',
    },
  },
  data() {
    return {
      api: {
        update_label: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        }),
      },
      forms: {
        update_label: this.$helperFunction.formInstance({
          data: {
            label: '',
          },
        })
      },
    }
  },
  inject: [
    'appNotify',
  ],
  validations: {
    forms: {
      update_label: {
        label: {
          // required: required,
          alphaNumSpace: helpers.regex('alphaNumSpace',/^[a-z0-9 ]*$/i),
        },
      },
    }
  },
  methods: {
    onBeforeOpen(event){
      this.forms.update_label.label=event?.params?.label
    },
    onBeforeClose(){
      this.api.update_label.reset()
      this.forms.update_label.reset()
    },
    async updateLabel() {
      this.forms.update_label.submitted=true
      this.$v.forms.update_label.$touch()
      if(this.api.update_label.send || this.$v.forms.update_label.$invalid) return;
      try {
        this.api.update_label.send=true
        this.api.update_label.error_message=''
        this.api.update_label.validation_errors={}
        await VOIP_API.endpoints.telephone_number.updateLabel({
          field: "name",
          name: this.forms.update_label.label,
        },this.numberId)
        this.$emit("updated",{
          label: this.forms.update_label.label,
        });
        this.$modal.hide(this.modalName);
        this.appNotify({
          message: 'Successfully Send',
          type: 'success',
        })
      } catch (ex) {
        this.api.update_label.error_message=ex.own_message
        this.api.update_label.validation_errors=ex.own_errors
      } finally {
        this.forms.update_label.submitted=false
        this.api.update_label.send=false
      }
    },
  },
}
</script>

<style>

</style>