var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.type == 'circular' ? _c('span', {
    staticClass: "loader-something mt-3"
  }) : _vm.type == 'loader' ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center h-80"
  }, [_vm._m(0)]) : _vm.type == 'ellipseV2' ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center ellipseV2 h-80"
  }, [_vm._m(1)]) : _vm.type == 'mobileTypeV1' ? _c('div', {
    staticClass: "d-flex justify-content-center align-items-center mobileTypeV1 h-80"
  }, [_vm._m(2)]) : _vm.type == 'something' ? _c('div', {
    staticClass: "position-relative w-100 h-100 mr-16px",
    staticStyle: {
      "min-width": "22px"
    }
  }, [_c('div', {
    staticClass: "message636 loadingMessage"
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 19 17"
    }
  }, [_c('circle', {
    staticClass: "loadingCircle",
    attrs: {
      "cx": "2.2",
      "cy": "10",
      "r": "1.6"
    }
  }), _c('circle', {
    staticClass: "loadingCircle",
    attrs: {
      "cx": "9.5",
      "cy": "10",
      "r": "1.6"
    }
  }), _c('circle', {
    staticClass: "loadingCircle",
    attrs: {
      "cx": "16.8",
      "cy": "10",
      "r": "1.6"
    }
  })])])]) : _vm._e();

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-loader m-4"
  }, [_c('div', {
    staticClass: "dot"
  }), _c('div', {
    staticClass: "dot"
  }), _c('div', {
    staticClass: "dot"
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lds-ellipsis"
  }, [_c('div'), _c('div'), _c('div'), _c('div')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lds-spinner"
  }, [_c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div'), _c('div')]);

}]

export { render, staticRenderFns }