var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.channel_id ? _c('div', {
    staticClass: "chat-box"
  }, [_vm.channelData.pinned_posts.loading ? _c('div', [_c('span', {
    staticClass: "dialer-LoadingClass"
  }, [_vm._v("Loading...")])]) : _c('div', {
    staticClass: "allow_scroll",
    attrs: {
      "id": "pinned-scroll-end"
    },
    on: {
      "scroll": _vm.onScroll
    }
  }, [_c('div', {
    staticClass: "pr-4 chat-box-bill pinned"
  }, _vm._l(_vm.messages, function (message) {
    return _c('div', {
      key: message.id
    }, [_c('div', {
      staticClass: "message-date"
    }, [_c('span', [_vm._v(_vm._s(_vm._f("date_current")(message.create_at)))])]), _c('Message', {
      ref: "single_message",
      refInFor: true,
      attrs: {
        "post": message,
        "channel_id": _vm.channel_id,
        "channel": _vm.channel,
        "data": _vm.data,
        "channelData": _vm.channelData,
        "members": _vm.members,
        "type": "pinned"
      }
    })], 1);
  }), 0)])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }