<template>
  <div>
    <modal
      class="center-zoomIn-transition v2 AddNumberExistingModal"
      width="50%"
      height="auto"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @opened="onOpened()"
      @before-close="onBeforeClose()"
    >
      <section class="dialer-edit">
        <div class="dialer-edit-header pt-0">
          <div>
            <h2 class="dialer-settings-title newer">Add an existing caller id</h2>
          </div>
          <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            <button 
              v-else
              class="newCloseButton"
              type="button"
              @click="$modal.hide(modalName)"
            >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </div>
        </div>
        <p class="dialer-segment-caption sm-mar-bottom w-100">
          If you have a telephone number from another telephone or mobile service provider like BT or virgin media,
          You can use that same number as caller id on VoIP Business Dialer.
        </p>
        <div class="dialer-tabs">
          <div class="dialer-tab-content">
            <div v-show="api.send_otp.status==1">
              <form class="dialer-form" @submit.prevent="verfiyOTP()">
                <b-alert :show="!!api.verify_otp.error_message" variant="danger">{{ api.verify_otp.error_message }}</b-alert>
                <div class="latestGreyBox-9-9-2023 mt-20px mb-20px">
                  <div class="whiteBGinputWithGreyRoundedBorder">
                    <label>Please enter the 6-digit verification code we sent via a {{sendingCode}}</label>
                    <vb-pin-code class="w-100" v-model="forms.verify_otp.pincode" :disabled="api.verify_otp.send" />
                    <p v-if="forms.verify_otp.submitted && $v.forms.verify_otp.pincode.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.verify_otp.pincode.required">* Pincode is required</span>
                      <span v-else-if="!$v.forms.verify_otp.pincode.validpincode">* Pincode is invalid</span>
                      <span v-else-if="!$v.forms.verify_otp.pincode.minLength">* Pincode is must be 6 character</span>
                      <span v-else-if="!$v.forms.verify_otp.pincode.maxLength">* Pincode is must be 6 character</span>
                      <span v-else-if="!$v.forms.verify_otp.pincode.numeric">* Pincode is invalid</span>
                    </p>
                  </div>
                </div>
                <a
                  class="dialer-box-text mb-0 highlight font-weight-bold text-underline-imp"
                  :disabled="api.verify_otp.send"
                  @click="api.send_otp.reset();forms.check_number_exits.reset();api.check_number_exits.reset();api.verify_otp.reset()"
                >
                  Change number
                </a>
                <button 
                  :disabled="api.verify_otp.send" 
                  class="fullWidthDoneButton mt-20px" 
                  type="submit" 
                >
                  Verify
                </button>
              </form>
            </div>
            <form v-show="api.send_otp.status!=1" class="dialer-form" @submit.prevent="''">
              <div class="latestGreyBox-9-9-2023 mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder mb-4">
                  <label>Enter the number for caller id</label>
                  <input 
                    class="touched w-100" 
                    type="text" 
                    v-vb-input-number-formate 
                    v-model="forms.check_number_exits.number" 
                    :disabled="api.check_number_exits.send || api.send_otp.send" 
                    @input="checkNumberExitsDebounce()"
                  />
                  <p 
                    v-if="(forms.check_number_exits.submitted && $v.forms.check_number_exits.number.$invalid) || api.check_number_exits.status!=0" 
                    class="text animated bounceIntop"
                    :class="api.check_number_exits.status==1 && !$v.forms.check_number_exits.number.$invalid?'text-success':'text-danger'"
                  >
                    <span v-if="!$v.forms.check_number_exits.number.required">* Number is required</span>
                    <span v-else-if="!$v.forms.check_number_exits.number.validNumber">Number is invalid</span>
                    <span v-else-if="api.check_number_exits.status==1">Number is valid</span>
                    <span v-else-if="api.check_number_exits.status==2">* {{ api.check_number_exits.error_message }}</span>
                  </p>
                </div>
                <div id="recaptcha-firebase-add-caller-id"></div>
              </div>
              <div class="d-flex justify-content-between mt-20px w-100">
                <button 
                  :disabled="api.check_number_exits.status!=1" 
                  class="fullWidthDoneButton w-50 mr-2" 
                  type="button"
                  @click="sendOTP();sendingCode='sms'" 
                >
                  SMS
                </button>
                <button 
                  :disabled="api.check_number_exits.status!=1" 
                  class="fullWidthDoneButton w-50 ml-2" 
                  type="button"
                  @click="sendOTP('call');sendingCode='call'" 
                >
                  Call
                </button>
              </div>
            </form>
          </div>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators";
import { $fn, VOIP_API } from '../../utils';
import { FIREBASE } from '@/firebase';
import { number_formater } from '@/filter';
import { mapGetters } from 'vuex';
import _ from 'lodash';

export default {
  name: "AddNumberExistingModal",
  props: {
    modalName: {
      type: String,
      default: 'AddNumberExistingModal',
    },
  },
  data() {
    return {
      api: {
        check_number_exits: this.$helperFunction.apiInstance({
          status: true,
          error_message: true, 
        }),
        send_otp: this.$helperFunction.apiInstance({
          status: true,
        }),
        verify_otp: this.$helperFunction.apiInstance({ 
          error_message: true, 
        }),
      },
      forms: {
        check_number_exits: this.$helperFunction.formInstance({
          data: {
            number: '',
          }
        }),
        verify_otp: this.$helperFunction.formInstance({
          data: {
            pincode: '',
          }
        }),
      },
      conditions: {
        send_otp_type: ''
      },
      firbase: {
        recaptcha: null,
        coderesult: null,
      },
      sendingCode: ''
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser'
    ]),
  },
  validations: {
    forms: {
      check_number_exits: {
        number: { 
          required,
          validNumber(value){ return $fn.validPhoneNumber(value,'','mobile') }, 
        },
      },
      verify_otp: {
        pincode: { 
          required,
          minLength: minLength(6), 
          maxLength: maxLength(6), 
          numeric,
        },
      },
    },
  },
  methods: {
    onBeforeOpen() {
      // TODO - onBeforeOpen
    },
    onOpened(){
      this.firbase.recaptcha = FIREBASE.recaptchaRender('recaptcha-firebase-add-caller-id')
    },
    onBeforeClose() {
      this.api.send_otp.reset()
      this.api.verify_otp.reset()
      this.api.check_number_exits.reset()
      this.forms.verify_otp.reset()
      this.forms.check_number_exits.reset()
      this.conditions.send_otp_type=''
    },
    checkNumberExitsDebounce: _.debounce(function(){
      this.checkNumberExits()
    }, 3*1000),
    async checkNumberExits(){
      this.forms.check_number_exits.submitted=true
      this.$v.forms.check_number_exits.$touch();
      if (this.$v.forms.check_number_exits.$invalid || this.api.check_number_exits.send) return;
      try {
        this.api.check_number_exits.send=true
        this.api.check_number_exits.status=0
        this.api.check_number_exits.error_message=''
        const number = number_formater(this.forms.check_number_exits.number,'',true)
        await VOIP_API.endpoints.callerid.checkBeforeAdd({
          number: number,
          uid: this.getCurrentUser.uid,
        })
        this.api.check_number_exits.status=1
      } catch (ex) {
        this.api.check_number_exits.status=2
        this.api.check_number_exits.error_message=ex.own_message
      } finally {
        this.forms.check_number_exits.submitted=false
        this.api.check_number_exits.send=false
      }
    },
    async sendOTP(type='sms') {
      if (this.api.send_otp.send || this.api.check_number_exits.status!=1) return;
      try {
        this.api.send_otp.send=true
        this.api.send_otp.status=0
        const number = number_formater(this.forms.check_number_exits.number,'',true)
        if(type=='call'){
          await VOIP_API.endpoints.callerid.callOtp({
            number: number,
            uid: this.getCurrentUser.uid,
          })
        } else {
          const coderesult = await FIREBASE.sendOTP(number,this.firbase.recaptcha)
          this.firbase.coderesult=coderesult
        }
        this.conditions.send_otp_type=type
        this.api.send_otp.status=1
        this.appNotify({
          message: `Successfully ${type=='Call for OTP'?'':'Send SMS'}`,
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: FIREBASE.exceptionErrorMessage(ex) || ex.own_message,
          type: 'danger',
        })
        this.api.send_otp.status=2
      } finally {
        this.api.send_otp.send=false
      }
    },
    async verfiyOTP(){
      this.forms.verify_otp.submitted=true
      this.$v.forms.verify_otp.$touch();
      if (this.$v.forms.verify_otp.$invalid || this.api.verify_otp.send || this.api.send_otp.status!=1) return;
      try {
        this.api.verify_otp.send=true
        this.api.verify_otp.error_message=''
        const number = number_formater(this.forms.check_number_exits.number,'',true)
        if(this.conditions.send_otp_type=='call') {
          await VOIP_API.endpoints.telephone_number.verifycalleridnumber({
            number: number,
            pincode: this.forms.verify_otp.pincode,
            uid: this.getCurrentUser.uid,
          })
        } else {
          const result = await FIREBASE.verify(this.forms.verify_otp.pincode,this.firbase.coderesult)
          await VOIP_API.endpoints.telephone_number.verifycalleridnumber({
            number: number,
            firebase_uid: result?.user?.uid,
          })
        }
        this.$modal.hide(this.modalName);
        this.appNotify({
          message: "Your Caller ID has been verified.",
          type: "success"
        })
        this.$emit('interface');
      } catch (ex) {
        this.api.verify_otp.error_message=FIREBASE.exceptionErrorMessage(ex) || ex.own_message
      } finally {
        this.forms.verify_otp.submitted=false
        this.api.verify_otp.send=false
      }
    },
  },
};
</script>
