var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "latestGreyBox-9-9-2023 firstSectionEditUserModal"
  }, [_c('div', {
    staticClass: "leftSide"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withRightIconOnly widthCalc50-10px"
  }, [_c('label', [_vm._v("First name")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.generalInfo.first_name
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "pencil-fill",
      "width": "14.153px",
      "height": "14.152px"
    },
    on: {
      "click": function ($event) {
        _vm.mainOrAdminAccess ? _vm.updatedInfoModal() : '';
      }
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withRightIconOnly widthCalc50-10px"
  }, [_c('label', [_vm._v("Last name")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.generalInfo.last_name
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "pencil-fill",
      "width": "14.153px",
      "height": "14.152px"
    },
    on: {
      "click": function ($event) {
        _vm.mainOrAdminAccess ? _vm.updatedInfoModal() : '';
      }
    }
  })], 1)])]), _c('div', {
    staticClass: "d-flex justify-content-between w-100 mt-38px"
  }, [_vm.userType == 'user' ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withRightIconOnly withTextOnRightSideOnly widthCalc50-10px"
  }, [_c('label', [_vm._v("Username")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.generalInfo.username
    }
  }), _c('vb-icon', {
    staticStyle: {
      "z-index": "2"
    },
    attrs: {
      "icon": "pencil-fill",
      "width": "14.153px",
      "height": "14.152px"
    },
    on: {
      "click": function ($event) {
        _vm.mainOrAdminAccess ? _vm.updatedInfoModal() : '';
      }
    }
  }), _c('span', {
    staticClass: "withTextOnRightSideOnly-text smaller iconIsThereToo",
    staticStyle: {
      "z-index": "1"
    }
  }, [_vm._v("@" + _vm._s(_vm.organization))])], 1)]) : _vm._e(), _vm.userType == 'user' ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withRightIconOnly widthCalc50-10px"
  }, [_c('label', [_vm._v("Email for notification")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.generalInfo.email_for_notification
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "pencil-fill",
      "width": "14.153px",
      "height": "14.152px"
    },
    on: {
      "click": function ($event) {
        _vm.mainOrAdminAccess ? _vm.updatedInfoModal() : '';
      }
    }
  })], 1)]) : _vm._e(), _vm.userType != 'user' ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withRightIconOnly widthCalc50-10px"
  }, [_c('label', [_vm._v("Email")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": ""
    },
    domProps: {
      "value": _vm.generalInfo.email
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "pencil-fill",
      "width": "14.153px",
      "height": "14.152px"
    },
    on: {
      "click": function ($event) {
        _vm.mainOrAdminAccess ? _vm.updatedInfoModal() : '';
      }
    }
  })], 1)]) : _vm._e()])]), _c('div', {
    staticClass: "rightSide d-flex"
  }, [_c('div', {
    staticClass: "settingsGridView-container align-items-stretch p-0 w-100"
  }, [_c('div', {
    staticClass: "settingsGridView-item justify-content-between w-100 m-0 bg-transparent border-0 p-0"
  }, [_c('div', {
    staticClass: "profileImg-section-latest-container"
  }, [_c('div', {
    staticClass: "profileImg-section-latest",
    on: {
      "click": function ($event) {
        _vm.mainOrAdminAccess ? _vm.$modal.show(`UserGeneralInfoUpdateWidget-${_vm._uid}-ProfileImageUploader`, {
          type: 'USER',
          accountcode: _vm.accountcode,
          current_image: _vm.generalInfo.image
        }) : '';
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.generalInfo.image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])]), _c('div', {
    staticClass: "firstSectionEditUserModal-heading"
  }, [_vm._v(_vm._s(_vm.generalInfo.first_name) + " " + _vm._s(_vm.generalInfo.last_name))]), _c('div', {
    staticClass: "firstSectionEditUserModal-text text-underline cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        _vm.mainOrAdminAccess ? _vm.$modal.show(`UserGeneralInfoUpdateWidget-${_vm._uid}-UpdateUserExtension`, {
          accountcode: _vm.accountcode,
          extn: _vm.generalInfo.extn
        }) : '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.generalInfo.extn) + " ")]), _c('span', {
    staticClass: "settingsGridView-email text-underline cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        _vm.mainOrAdminAccess ? _vm.$modal.show(`UserGeneralInfoUpdateWidget-${_vm._uid}-ChangeJobTitle`) : '';
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.generalInfo.job_title || 'Job title') + " ")]), _vm.userType != 'invite' ? _c('span', {
    staticClass: "settingsGridView-email text-underline cursor_pointer_no_hover",
    on: {
      "click": function ($event) {
        _vm.mainOrAdminAccess ? _vm.$modal.show(`UserGeneralInfoUpdateWidget-${_vm._uid}-ChangeUserPassword`, {
          accountcode: _vm.accountcode
        }) : '';
      }
    }
  }, [_vm._v(" Change password ")]) : _vm._e(), _vm.userType == 'main' ? _c('div', {
    staticClass: "OwnertaggedVersion cursor_pointer_no_hover mt-12px",
    on: {
      "click": function ($event) {
        _vm.mainUserAccessOnly ? _vm.$modal.show(`UserGeneralInfoUpdateWidget-${_vm._uid}-ChangeOwnerShip`, {
          accountcode: _vm.accountcode
        }) : '';
      }
    }
  }, [_vm._v(" Owner ")]) : _vm._e()])])]), _c('ChangeUserPasswordModal', {
    attrs: {
      "modalName": `UserGeneralInfoUpdateWidget-${_vm._uid}-ChangeUserPassword`
    }
  }), _c('UpdateUserExtensionModal', {
    attrs: {
      "modalName": `UserGeneralInfoUpdateWidget-${_vm._uid}-UpdateUserExtension`
    },
    on: {
      "updated": function ($event) {
        return _vm.getVoipUsers();
      }
    }
  }), _c('ProfileImageUploader', {
    attrs: {
      "modalName": `UserGeneralInfoUpdateWidget-${_vm._uid}-ProfileImageUploader`
    },
    on: {
      "image-uploaded": function ($event) {
        return _vm.getVoipUsers();
      }
    }
  }), _c('UpdateUserGeneralInfoModal', {
    attrs: {
      "modalName": `UserGeneralInfoUpdateWidget-${_vm._uid}-UpdateUserGeneralInfo`
    },
    on: {
      "updated": function ($event) {
        _vm.getVoipUsers();
        _vm.fetchVoicemailSettings();
      }
    }
  }), _c('ChangeOwnerShipModal', {
    attrs: {
      "modalName": `UserGeneralInfoUpdateWidget-${_vm._uid}-ChangeOwnerShip`
    },
    on: {
      "changed": function ($event) {
        return _vm.getVoipUsers();
      }
    }
  }), _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500",
    attrs: {
      "name": `UserGeneralInfoUpdateWidget-${_vm._uid}-ChangeJobTitle`,
      "height": "auto"
    },
    on: {
      "before-open": function ($event) {
        _vm.forms.update_jobtitle.title = _vm.generalInfo.job_title || '';
      },
      "before-close": function ($event) {
        _vm.api.update_jobtitle.reset();
        _vm.forms.update_jobtitle.reset();
      }
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-start justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Change job title")]), _c('p', [_vm._v("Update job title for user.")])]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`UserGeneralInfoUpdateWidget-${_vm._uid}-ChangeJobTitle`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('form', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateJobTittle();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.update_jobtitle.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.update_jobtitle.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Set job title")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_jobtitle.title,
      expression: "forms.update_jobtitle.title"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.update_jobtitle.send,
      "maxlength": _vm.$v.forms.update_jobtitle.title.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_jobtitle.title
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_jobtitle, "title", $event.target.value);
      }
    }
  }), _vm.forms.update_jobtitle.submitted && _vm.$v.forms.update_jobtitle.title.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_jobtitle.title.required ? _c('span', [_vm._v("job title is required")]) : !_vm.$v.forms.update_jobtitle.title.minLength ? _c('span', [_vm._v("job title should be minimum " + _vm._s(_vm.$v.forms.update_jobtitle.title.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_jobtitle.title.maxLength ? _c('span', [_vm._v("job title can be maximum " + _vm._s(_vm.$v.forms.update_jobtitle.title.$params.maxLength.max) + " character")]) : !_vm.$v.forms.update_jobtitle.title.valid ? _c('span', [_vm._v("job title can only be alphabet character")]) : _vm._e()]) : _vm.api.update_jobtitle.validation_errors.job_tittle ? _c('p', _vm._l(_vm.api.update_jobtitle.validation_errors.job_tittle, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()])], 1), _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.updateJobTittle();
      }
    }
  }, [_vm.api.update_jobtitle.send ? _c('vb-spinner') : _c('span', [_vm._v("Update")])], 1)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }