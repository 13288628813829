var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "addStripeCard",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "opened": function ($event) {
        return _vm.onOpened($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header w-100 pt-0 flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v(" Add card ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Securely link your payment method with VoIP Business by adding your stripe card. Enjoy seamless transactions and manage your subscriptions effortlessly. ")])]), _vm.api.check_billing_mode.send ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-20px",
    staticStyle: {
      "height": "78px",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mb-8px",
    staticStyle: {
      "height": "21px",
      "width": "30%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mb-20px",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mb-8px",
    staticStyle: {
      "height": "21px",
      "width": "30%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mb-20px",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })]) : _c('div', {
    staticClass: "cell example example3 updated",
    attrs: {
      "id": "example-3"
    }
  }, [_c('div', {
    staticClass: "innerBlueBoxwithRoundedBorder mb-20px pl-2"
  }, [_c('vb-icon', {
    staticClass: "AccountBalance-newBilling-icon",
    attrs: {
      "icon": "AccountBalance-newBilling-icon",
      "height": "25px",
      "width": "25px"
    }
  }), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between flex-fill"
  }, [_c('p', {
    staticClass: "innerRedBoxwithRoundedBorder-text mb-0"
  }, [_vm._v(" To verify your account, deducting £2 which will be refunded immediately after ")])])], 1), _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-20px"
  }, [_c('label', [_vm._v("Card holder name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_card.card_name,
      expression: "forms.add_card.card_name"
    }],
    staticClass: "w-100",
    staticStyle: {
      "color": "#307FE2"
    },
    attrs: {
      "disabled": _vm.api.pay.send
    },
    domProps: {
      "value": _vm.forms.add_card.card_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_card, "card_name", $event.target.value);
      }
    }
  }), _vm.$v.forms.add_card.card_name.$error ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_card.card_name.required ? _c('span', [_vm._v("Card name is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "fieldset"
  }, [_c('div', {
    staticClass: "first"
  }, [_c('label', [_vm._v("Card number")]), _c('div', {
    ref: "card-number",
    staticClass: "field empty",
    staticStyle: {
      "height": "50px"
    },
    attrs: {
      "id": "example3-card-number"
    }
  })]), _c('div', {
    staticClass: "second"
  }, [_c('label', [_vm._v("Expiration date")]), _c('div', {
    ref: "card-expiry",
    staticClass: "field empty third-width",
    staticStyle: {
      "height": "50px"
    },
    attrs: {
      "id": "example3-card-expiry"
    }
  })]), _c('div', {
    staticClass: "third"
  }, [_c('label', [_vm._v("CVC")]), _c('div', {
    ref: "card-cvc",
    staticClass: "field empty third-width",
    staticStyle: {
      "height": "50px"
    },
    attrs: {
      "id": "example3-card-cvc"
    }
  })])]), _c('button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "type": "button",
      "data-tid": "elements_examples.form.pay_button"
    },
    on: {
      "click": function ($event) {
        return _vm.pay();
      }
    }
  }, [_vm.api.pay.send ? _c('vb-spinner') : [_vm._v(" Add card ")]], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }