var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.verify ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.resend.send || _vm.api.verify.send ? '' : _vm.conditions.screen = _vm.screens.add;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Caller ID")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.verify();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.verify.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.verify.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Number")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm.sendNumber))])])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item flex-column"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Pincode")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.verify.pincode,
      expression: "forms.verify.pincode"
    }],
    attrs: {
      "type": "text",
      "maxlength": "6",
      "oninput": "this.value = this.value.replace(/[^0-9]/gi, '')",
      "disabled": _vm.api.verify.send
    },
    domProps: {
      "value": _vm.forms.verify.pincode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.verify, "pincode", $event.target.value);
      }
    }
  }), _vm.forms.verify.submitted && _vm.$v.forms.verify.pincode.$invalid || _vm.api.verify.validation_errors.pin ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.verify.pincode.required ? _c('span', [_vm._v("* Pincode is required")]) : _vm._e(), _vm._l(_vm.api.verify.validation_errors.pin, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.resend.send || _vm.api.verify.send ? '' : _vm.forms.caller_id.reset();
        _vm.forms.verify.reset();
        _vm.conditions.screen = _vm.screens.add;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Change Number")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.resend();
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Resend Pincode")]), _c('vb-icon', {
    class: `rightFacingArrow reSendIcon ${_vm.api.resend.send ? 'rotateIt' : ''}`,
    attrs: {
      "icon": "reSend-onRightSide-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.resend.send || _vm.api.verify.send
    }
  }, [_vm.api.verify.send ? _c('vb-spinner') : [_vm._v("Verify")]], 2)], 1)])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Caller ID")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addCallerId();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.caller_id.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.caller_id.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Country Code")]), _c('b-input-group', [_c('b-input-group-prepend', [_c('b-select', {
    model: {
      value: _vm.forms.caller_id.country_code,
      callback: function ($$v) {
        _vm.$set(_vm.forms.caller_id, "country_code", $$v);
      },
      expression: "forms.caller_id.country_code"
    }
  }, _vm._l(_vm.response.countries, function (country) {
    return _c('b-select-option', {
      key: country.cca2,
      attrs: {
        "value": `${country.idd.root}${country.idd.suffixes ? country.idd.suffixes[0] || '' : ''}`
      }
    }, [_vm._v(" " + _vm._s(_vm._f("get_property")(country, 'idd.root')) + _vm._s(_vm._f("get_property")(country, 'idd.suffixes[0]')) + " ")]);
  }), 1)], 1)], 1)], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Number")]), _c('b-input-group', [_c('b-form-input', {
    attrs: {
      "placeholder": "Enter Number",
      "type": "number",
      "disabled": _vm.api.caller_id.send
    },
    model: {
      value: _vm.forms.caller_id.number,
      callback: function ($$v) {
        _vm.$set(_vm.forms.caller_id, "number", $$v);
      },
      expression: "forms.caller_id.number"
    }
  })], 1)], 1)]), _vm.forms.caller_id.submitted && (_vm.$v.forms.caller_id.number.$invalid || _vm.$v.forms.caller_id.country_code.$invalid) || _vm.api.caller_id.validation_errors.number ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_vm.forms.caller_id.submitted && (_vm.$v.forms.caller_id.number.$invalid || _vm.$v.forms.caller_id.country_code.$invalid) || _vm.api.caller_id.validation_errors.number ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.caller_id.number.required ? _c('span', [_vm._v("* Number is required")]) : _vm._e(), !_vm.$v.forms.caller_id.country_code.required ? _c('span', [_vm._v("* Country Code is required")]) : _vm._e(), _vm._l(_vm.api.caller_id.validation_errors.number, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]) : _vm._e()]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.caller_id.send
    }
  }, [_vm.api.caller_id.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)], 1)])]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }