<template>
  <div class="dialer-loader">
    <div class="dot"></div>
    <div class="dot"></div>
    <div class="dot"></div>
    
  </div>
</template>

<script>
import SCSSVariables from "../../assets/scss/_export.scss";
import { LOGGER } from "../../utils";
export default {
  name: "vb-spinner",
  props: ["color", "variant"],
  data() {
    return {
      variantTha: "",
      assets: {
        spinnerCicular: require("@/assets/images/spinner.svg"),
        spinnerWhite: require("@/assets/images/spinnerWhiteHorizontal.svg"),
      },
    };
  },
  watch: {
    variant: {
      immediate: true,
      handler(variant) {
        if (!variant) return;
        LOGGER.log(SCSSVariables);
        this.variantTha = SCSSVariables[variant];
      },
    },
  },
};
</script>

<style lang="scss" scoped>
.dialer-loader {
  // svg {
  //   animation: spin 1s linear infinite;
  // }
  display: flex;
  .dot {
    width: $dialer-default-padding;
    height: $dialer-default-padding;
    background: $white;
    @include border-radius(50%);
    display: inline-block;
    animation: 1.3s slide infinite ease-in-out both;
    margin: 0px 2px;
    &:nth-child(1) {
      animation-delay: 0.2s;
    }
    &:nth-child(2) {
      animation-delay: 0.4s;
    }
    &:nth-child(3) {
      animation-delay: 0.6s;
    }
  }
  @keyframes slide {
    0%,
    80%,
    100% {
      transform: scale(0);
    }
    40% {
      transform: scale(1);
    }
  }
  
  &.primary-color-loader{
    .dot{
      background: $primary;
    }
  }
}
</style>