var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Pickup Groups")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update();
      }
    }
  }, [_c('b-alert', {
    staticClass: "alert-danger mt-32px",
    attrs: {
      "show": !!_vm.api.update.error_message
    }
  }, [_vm._v(_vm._s(_vm.api.update.error_message))]), _c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Name")]), _c('b-form-input', {
    attrs: {
      "disabled": _vm.api.update.send || _vm.api.delete.send,
      "type": "text",
      "placeholder": "Enter Name"
    },
    model: {
      value: _vm.forms.update.name,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update, "name", $$v);
      },
      expression: "forms.update.name"
    }
  }), _vm.forms.update.submitted && _vm.$v.forms.update.name.$invalid ? _c('p', [!_vm.$v.forms.update.name.required ? _c('span', [_vm._v("*Name is Required")]) : _vm._e()]) : _vm._e()], 1)])])]), _c('b-button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.update.send || _vm.api.delete.send
    }
  }, [_vm.api.update.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1)], 1)] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Pickup Groups")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Name")]), _c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm.data.pickupgroup.name))])])]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    attrs: {
      "disabled": _vm.api.delete.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('SelectUserExtensionModal', {
          pickupgroup_id: _vm.pickupGroupId
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Add/Remove Extension")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_vm._l(_vm.response.selected, function (data) {
    return _c('div', {
      key: data.accountcode,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "d-flex mr-6px align-items-center"
    }, [_c('div', {}, [_c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": data.accountcode,
        "name": data.label,
        "sub_info": data.extn
      }
    })], 1)])]);
  }), _vm.isEmpty(_vm.response.selected) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.members.send ? '' : 'Sorry, No Data Found',
      "design": 3
    }
  }, [_vm.api.members.send ? _c('vb-loading', {
    staticClass: "my-2",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2), _c('b-button', {
    staticClass: "IosFullWidthButton forRemove mb-16px",
    attrs: {
      "type": "submit"
    },
    on: {
      "click": function ($event) {
        _vm.api.update.send || _vm.api.delete.send ? '' : _vm.deletePickGroup();
      }
    }
  }, [[_vm._v("Delete")]], 2)], 1)], _c('SelectUserExtension', {
    on: {
      "updated": function ($event) {
        return _vm.fetchMembers();
      }
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }