<template>
  <modal class="dialer_animation right_side_popup billingAllNumbersModal" width="50%" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose" :clickToClose="false">
    <div class="dialer-box table">

      <div class="dialer-edit-header">
        <h2 class="dialer-edit-title mb-0">Numbers</h2>
        <div class="dialer-edit-actions">
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else class="" @click="$modal.hide(modalName)">
            
            <button type="button" class="newCloseButton"> 
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </button>
          </a>
        </div>
      </div>

      <div class="mt-5">
        <vb-table 
          class="plan-license-table dialer-table dialer-table-bg-f3f3f3 px-3" 
          :isListEmpty="isEmpty(filterNumbers)" 
          :listLength="filterNumbers.length" 
          :perPage="5" 
          :loading="api.numbers.send"
        >
          <tr slot="header" class="border-bottom-0">
            <th class="dialer-has-sort pt-3 pb-3 pl-0 border-radius-16">
              <span class=" border-bottom pb-3 pr-4">DID Number</span>
            </th>
            <th class="dialer-has-sort pt-3 pb-3 pl-0 border-radius-16">
              <span class=" border-bottom pb-3 pr-4">Assigned</span>
            </th>
            <th class="dialer-has-sort dialer-col-right pt-3 pr-0 border-radius-16 pb-3">
              <span class="border-bottom pb-3 pl-4">Action</span>
            </th>
          </tr>
          <template #body="{ start, end }">
            <tr class="dialer-row-select mx-0 px-0" v-for="(number, index) in filterNumbers" :key="`${number.id}-${number.object}`" v-show="index >= start && index <= end">
              <td class="dialer-row-title pl-0">
                <span class="display-responsive mr-2">DID Number :</span>
                <div class="d-flex dialer-numberWithCountry align-items-center">
                  <span >
                    <img class="country-img" width="45px" :src="number.number | number_formater | flag_icon" />
                  </span>
                  <span class="ml-2 d-flex flex-column dialer-numberInfo" v-if="number.object === 'TelephoneNumbers'">
                    <span class="m-0 didNumber">{{ number.number | number_formater }}</span>
                    <small class="didNumber-text" v-if="number.label">({{ number.label }})</small>
                    <div v-if="number.status!=1" class="suspendedTag">Suspended</div>
                  </span>
                  <span class="d-flex flex-column ml-1 dialer-numberInfo" v-else>
                    <span class="m-0 didNumber">{{ number.number | number_formater }}</span>
                  </span>
                </div>
              </td>
              <td>
                <Info 
                  v-if="number.extnType" 
                  :id="number | get_property('assignedToUsersArr.extn')" 
                  :name="number | get_property('assignedToUsersArr.label')" 
                  :sub_info="number | get_property('assignedToUsersArr.extn')" 
                />
              </td>
              <td class="dialer-has-sort dialer-col-right pr-0">
                <button 
                  @click="deletenumber(number.real_id)" 
                  :disabled="!!api.delete.send" 
                  v-if="number.assignedToUsers && number.assignedToUsers=='None' && number.status==1" 
                  class="dialer-button dialer-button-primary"
                >
                  <vb-spinner v-if="api.delete.send==number.real_id" />
                  <template v-else>Remove</template>
                </button>
              </td>
            </tr>
          </template>
        </vb-table>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '../../utils';
import Info from '../Lists/Info.vue'
export default {
  name: 'NumbersListModal',
  components: {
    Info,
  },
  props: {
    modalName: {
      type: String,
      default: 'NumbersListModal',
    },
  },
  data(){
    return {
      response: {
        numbers: [],
      },
      api: {
        delete: {
          count: 0,
          send: '',
          status: false,
          token: null,
          error: {},
          error_message: '',
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.error_message = ''
          },
        },
        numbers: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.error_message = ''
          },
        },
      },
    }
  },
  inject:['isEmpty','appNotify'],
  computed: {
    filterNumbers(){
      const numbers = this.response.numbers
      return numbers.filter(item=>item.object=='TelephoneNumbers')
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchNumbers()
    },
    onBeforeClose(){
      this.response.numbers=[]
      this.api.delete.reset()
      this.api.numbers.reset()
    },
    fetchNumbers(){
      let vm = this
      if(vm.api.numbers.send) return;
      vm.api.numbers.send=true
      VOIP_API.endpoints.telephone_number.numbers().then(({ data: res }) => {
        vm.response.numbers = res.data || res
      }).catch((ex)=>{
        vm.api.numbers.error_message=ex.own_message
        vm.api.numbers.error=ex.response_error || {}
      }).finally(() => {
        vm.api.numbers.send = false
      });
    },
    deletenumber(id){
      let vm = this
      if(vm.api.delete.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this Number`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if(confirm){
            vm.api.delete.send=id
            VOIP_API.endpoints.telephone_number.deleteNumber({
              id: id,
              uid: vm.$store.getters.getCurrentUser?.uid,
              accountcode: vm.$store.getters.getCurrentUser?.account,
            }).then(() => {
              vm.fetchNumbers()
            }).catch((ex)=>{
              vm.api.delete.error_message=ex.own_message
              vm.api.delete.error=ex.response_error || {}
              vm.appNotify({
                message: vm.api.delete.error_message,
                type: 'danger',
              })
            }).finally(() => {
              vm.api.delete.send=''
            });
          }
        }
      })
    },
  },
}
</script>

<style>

</style>