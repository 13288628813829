var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "insideMobileApp"
  }, [_c('div', {
    staticClass: "d-flex flex-column dialer-parent dialer-light-mode insideAnroidIOSApp"
  }, [_c('div', {
    staticClass: "dialer-parent-inner",
    staticStyle: {
      "height": "100vh"
    },
    attrs: {
      "id": "dialer-parent-inner"
    }
  }, [_c('div', {
    staticClass: "d-flex h-100"
  }, [_c('div', {
    staticClass: "dialer-main-center-area d-flex flex-column h-100 settingsIsOpened insideAnroidIOSApp"
  }, [_c('CommonCalls', {
    attrs: {
      "id": "videocomon"
    }
  }), _vm.is_loading ? _c('loader-main') : [_c('CommonChat', {
    ref: "common_chat"
  }), _vm.page == 'call-logs' ? _c('HomeCopy4', {
    attrs: {
      "dialer": false
    }
  }) : _vm.page == 'video-call' ? _c('VideoCall') : _c('keep-alive', [_c('MobileSettings', {
    staticClass: "insideAnroidIOSApp"
  })], 1)]], 2)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }