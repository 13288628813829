var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "backToSettingsList-IOSIcon",
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Number Blocking")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    },
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.block_and_privacy, function () {
          return _vm.$modal.show('AddBlockAndPrivacy');
        });
      }
    }
  })], 1)]), _c('section', {
    staticClass: "dialer-settings-section PrivacySettings"
  }, [[_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search numbers"
    },
    model: {
      value: _vm.filter.rules.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.rules, "search", $$v);
      },
      expression: "filter.rules.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1)], _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.filterRules, function (rule) {
    return _c('div', {
      key: rule.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.checkPermission(_vm.getUserPermissions.block_and_privacy, function () {
            return _vm.$modal.show('EditBlockAndPrivacy', {
              number: rule
            });
          });
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "basicIOSIcon mr-16px"
    }, [rule.type == 'both' ? _c('vb-icon', {
      staticClass: "redC",
      attrs: {
        "icon": "NumberBlocking-incommingOutgoing-IOSIcon",
        "height": "13.922",
        "width": "13.922"
      }
    }) : _vm._e(), rule.type == 'in' ? _c('vb-icon', {
      attrs: {
        "icon": "NumberBlocking-incomming-IOSIcon",
        "height": "13.922",
        "width": "13.922"
      }
    }) : _vm._e(), rule.type == 'out' ? _c('vb-icon', {
      attrs: {
        "icon": "NumberBlocking-outgoing-IOSIcon",
        "height": "13.922",
        "width": "13.922"
      }
    }) : _vm._e()], 1), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable",
      attrs: {
        "id": `Rule-${rule.id}`
      }
    }, [_vm._v(_vm._s(_vm._f("number_formater")(rule.callerid || rule.callerid_format)))])])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
    }, [_vm._v(_vm._s(rule.displayname))]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)]);
  }), 0), _c('InfiniteLoading', {
    ref: "rules_infinity_loader",
    on: {
      "infinite": _vm.fetchRules
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (_ref) {
        var trigger = _ref.trigger;
        return [_c('div', [_c('span', [_vm._v(_vm._s(_vm.api.rules.error_message))]), _c('b-icon', {
          staticClass: "ml-3",
          attrs: {
            "icon": "arrow-repeat",
            "variant": "primary",
            "font-scale": "1.5"
          },
          on: {
            "click": function ($event) {
              return trigger();
            }
          }
        })], 1)];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "spinner"
    },
    slot: "spinner"
  }, [_c('vb-loading', {
    attrs: {
      "type": "loader"
    }
  })], 1), _c('div', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }), _c('div', {
    attrs: {
      "slot": "no-results"
    },
    slot: "no-results"
  }, [_c('vb-no-record', {
    attrs: {
      "text": 'There is no Numbers',
      "design": 3
    }
  })], 1)]), _c('AddBlockAndPrivacyModal', {
    on: {
      "interface": _vm.afterAdding
    }
  }), _c('EditBlockAndPrivacyModal', {
    on: {
      "interface": _vm.afterAdding
    }
  })], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }