
<template>
  <div>
    <div class="mainHeading withButton">
      Compensation
      <button @click="$modal.show('EditIndividualAllowanceModal')" class="dialer-button dialer-button-primary mr-0 withIcon">
        <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
        Add segment
      </button>
    </div>
    <div class="mainDescription mb-4">
      Here is the list of employee compensation
    </div>
    <div class="grayCard flexColumn">
      <div class="circleWithText">
        <div class="circleContainer" style="background-color:#5576d1"></div>
        <div class="circleWithText-text" style="color:#5576d1">Segment 1</div>
      </div>
      <div class="innerShadowInput-container mt-16px">
        <label>Criteria</label>
        <b-dropdown class="hrmInnerShadow-dd bgWhite w-536px" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText">All</div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            All
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-center mt-5">
      <canvas id="compensation-chart"></canvas>
    </div>
    <div class="mainHeading withButton mt-32px">
      Segment compensation
    </div>
    <div class="CompensationTable mt-32px">
      <div class="HrmTableHead-container">
        <div class="HrmTableHead one">Employee</div>
        <div class="HrmTableHead two">DATES</div>
        <div class="HrmTableHead three">AMOUNT</div>
        <div class="HrmTableHead four">TYPE</div>
        <div class="HrmTableHead five">REASON</div>
        <div class="HrmTableHead six">COMMENTS</div>
        <div class="HrmTableHead seven">ACTIONS</div>
      </div>
      <vb-table class="HrmTable">
        <template #body>
          <tr class="dialer-row-select">
            <td class="dialer-row-title one">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">Jackson Will</div>
              </div>
            </td>
            <td class="dialer-row-title two">02/03/2022 - "Current"</td>
            <td class="dialer-row-title three">£100.00</td>
            <td class="dialer-row-title four">Salary</td>
            <td class="dialer-row-title five">Promotion</td>
            <td class="dialer-row-title six">No comments</td>
            <td class="dialer-row-title seven">
              <b-dropdown no-caret right class="ActionsDD">
                <template #button-content>
                  <div class="button-content">
                    <b-icon class="downIcon" icon="three-dots-vertical" />
                  </div>
                </template>
                <b-dropdown-item >
                  All teams
                </b-dropdown-item>
              </b-dropdown></td>
          </tr>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
import { HRM } from '@/data'
import Chart from "chart.js";
export default {
  name: 'Compensation',
  data() {
    return {
      compensationChart: {}
    }
  },
  methods: {
    async barChart() {
      const ctx = document.getElementById("compensation-chart");
      this.compensationChart?.destroy?.();
      this.compensationChart = new Chart(ctx, {
        type: 'line',
        data: {
          labels: HRM.charts.compensation.labels,
          datasets: [
            {
              label: 'Segment 1 (employees 1)',
              data: HRM.charts.compensation.data,
              borderColor: '#5576d1',
              backgroundColor: '#5576d1',
              fill: false,
            }
          ]
        },
        options: {
          lineTension: 1,
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          scales: {
            xAxes: [{
                gridLines: {
                  display:false,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Cities'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
            }],
            yAxes: [{
                gridLines: {
                  // display:false,
                  drawBorder: false,
                },
                scaleLabel: {
                  beginAtZero: true,
                  display: true,
                  labelString: 'Average Compensation'
                },
                ticks: {
                  min: 0,
                  max: 400,
                  beginAtZero: true,
                  stepSize: 100,
                },
            }]
          }
        }
      });
      this.compensationChart.update();
    },
  },
  mounted(){
    this.barChart()
  }
}
</script>

<style>

</style>