var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup global-modal-hrm Scrollable-hrm",
    attrs: {
      "name": "HRMAddNewNotesModal",
      "clickToClose": true,
      "scrollable": true
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-hrm-modal-header mar-top-hrm20"
  }, [_c('h2', {
    staticClass: "dialer-modal-hrm-title"
  }, [_vm._v("Add new note")]), _c('button', {
    staticClass: "dialer-hrm-button-modal",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('HRMAddNewNotesModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "sp-gm-tn mar-top-hrm30"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Note")]), _c('b-form-textarea', {
    staticClass: "gm-hrm-textnote",
    attrs: {
      "id": "textarea-small",
      "size": "sm",
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "mar-top-hrm20"
  }, [_c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Visible to managers")])])], 1), _c('div', [_c('p', {
    staticClass: "gm-hrm-file-size-text mar-top-hrm20"
  }, [_vm._v("Max file size: 25 MB")])]), _c('div', [_c('button', {
    staticClass: "btn-activity mar-top-hrm25"
  }, [_c('svg', {
    staticClass: "grm-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.551",
      "height": "16.551",
      "viewBox": "0 0 16.551 16.551"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_ionic-ios-add",
      "data-name": "Icon ionic-ios-add",
      "d": "M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z",
      "transform": "translate(-8.465 -8.465)",
      "fill": "#5576d1",
      "stroke": "#5576d1",
      "stroke-width": "1"
    }
  })]), _vm._v(" Add attachment ")]), _c('button', {
    staticClass: "btn-primarynewupdated1 mar-top-hrm30 mb-4"
  }, [_vm._v("Save")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }