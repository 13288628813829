var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Playlist")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addPlaylist();
      }
    }
  }, [_c('b-alert', {
    staticClass: "mt-32px",
    attrs: {
      "show": !!_vm.api.add_playlist.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_playlist.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_playlist.name,
      expression: "forms.add_playlist.name"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "My Playlist etc...",
      "type": "text",
      "disabled": _vm.api.add_playlist.send
    },
    domProps: {
      "value": _vm.forms.add_playlist.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_playlist, "name", $event.target.value);
      }
    }
  }), _vm.forms.add_playlist.submitted && _vm.$v.forms.add_playlist.name.$invalid || _vm.api.add_playlist.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.add_playlist.name.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e(), _vm._l(_vm.api.add_playlist.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.add_playlist.send
    }
  }, [_vm.api.add_playlist.send ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }