
import { VOIP_API } from "../index"

export const FEEDBACK_API_ENDPOINTS = {
  customerfeedback(data={}){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'customer_feedback',
        type: 'relay',
      },
    })
  },
  kblink(data={}){
    return VOIP_API.axios.voip.request({
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'fetch_kb_links',
        type: 'relay',
        flag: 'all',
      },
    })
  },
}