<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"   
      :name="modalName" 
      @before-close="onBeforeClose()" 
      @before-open="onBeforeOpen($event)"
    >
      <div v-if="conditions.screen==screens.select" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Extension</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.select">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add Extension</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">

        <template v-if="conditions.screen==screens.select">
          

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              v-for="extn in extnOptions" 
              :key="extn.value" 
              class="basicWhiteIOScard-item d-flex justify-content-between" 
              @click="onSelectExtension(extn)"
            >
              <div class="headingInsideCard">{{ extn.text }}</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
          </div>

        </template>
        <template v-else>
          

          <vb-loading v-if="api.checkpurchaselimit.send" />
          <template v-else>
            <template v-if="api.checkpurchaselimit.status==2">
              <div class="d-flex align-items-center justify-conent-center text-center headingInsideTable mt-32px mb-16px">
                Sorry you have reached your purchase limit.Please contact to your administrator.
              </div>
            </template>
            <template v-else>
              <form class="dialer-form " @submit.prevent="addExtension()">
                <b-alert :show="!!api.add_extension.error_message" variant="danger" class="mt-32px">{{ api.add_extension.error_message }}</b-alert>
                <div class="basicWhiteIOScard mt-32px mb-16px">
                  <div class="basicWhiteIOScard-item">
                    <div class="mobileInputIOS">
                      <label>Extension Name</label>
                      <div>
                        <input class="w-100" type="text" placeholder="extension name" :disabled="api.add_extension.send" v-model="forms.extension.name" />
                      </div>
                      <p 
                        v-if="(forms.extension.submitted && $v.forms.extension.name.$invalid) || api.add_extension.validation_errors.name" 
                        class="text text-danger animated bounceIntop mb-0"
                      >
                        <span v-if="!$v.forms.extension.name.required">* Extension Name is required</span>
                        <span v-for="error_message in (api.add_extension.validation_errors.name || [])" :key="error_message">* {{ error_message }}</span>
                      </p>
                    </div>
                  </div>
                </div>
                <button :disabled="api.add_extension.send" class="IosFullWidthButton forDone mt-16px mb-16px" type="button">
                  <vb-spinner v-if="api.add_extension.send" />
                  <template v-else>Check</template>
                </button>
              </form>
            </template>
          </template>

        </template>

      </div>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { VOIP_API } from '@/utils/index';
import { mapGetters } from 'vuex';
import { permission_handling } from '@/mixin';
let screens = {
  add: 'add',
  select: 'select',
}
let extn_options = {
  external: {
    text: 'External',
    value: 'external',
    product: 'eex',
  },
  handset: {
    text: 'Handset',
    value: 'handset',
    product: 'hs',
  },
  // mobile: {
  //   text: 'Mobile',
  //   value: 'mobile',
  //   product: 'mobile',
  // },
  // desktop: {
  //   text: 'Desktop',
  //   value: 'desktop',
  //   product: 'de',
  // },
}
export default {
  name: "PurchaseExtensionModal",
  mixins: [permission_handling],
  props: {
    modalName: {
      type: String,
      default: 'PurchaseExtension'
    },
  },
  data() {
    return {
      forms: {
        extension: this.$helperFunction.formInstance({
          data: {
            name: '',
          },
        }),
      },
      response: {
        checkpurchaselimit: {},
      },
      conditions: {
        screen: screens.select,
      },
      api: {
        checkpurchaselimit: this.$helperFunction.apiInstance({ status: true }),
        add_extension: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true }),
      },
      selected: {
        extension: '',
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getUserPermissions',
    ]),
    screens(){ return screens },
    extnOptions(){ return extn_options },
    selectedExtn(){ return Object.values(extn_options).find(extn=>extn.value==this.selected.extension) },
  },
  validations: {
    forms: {
      extension: {
        name: { 
          required 
        },
      },
    }
  },
  methods: {
    onBeforeOpen() {
      // TODO
    },
    onBeforeClose() {
      this.conditions.screen=screens.select
      this.selected.extension=''
      this.response.checkpurchaselimit={}
      this.api.checkpurchaselimit.reset()
      this.api.add_extension.reset()
      this.forms.extension.reset()
    },
    onSelectExtension(extension){
      let vm = this
      const afterDone = () => {
        vm.selected.extension=extension.value;
        vm.conditions.screen=screens.add;
        vm.checkPurchaseLimit()
      }
      if(extension.value==extn_options.external.value){
        vm.checkPermission(vm.getUserPermissions.external_extension,afterDone)
      } else {
        afterDone()
      }
    },
    checkPurchaseLimit(){
      let vm = this
      if(vm.api.checkpurchaselimit.send) return;
      vm.api.checkpurchaselimit.send=true
      vm.api.checkpurchaselimit.status=0
      VOIP_API.endpoints.devices.checkpurchaselimit({
        type: vm.selectedExtn.value,
      })
      .then(({ data: res }) => {
        vm.response.checkpurchaselimit=res
        if(res.extensionAvaliable>=0 || res.extensionAvaliable=='') throw new Error('You reached Your limit')
        vm.api.checkpurchaselimit.status=1
      })
      .catch(() => {
        vm.api.checkpurchaselimit.status=2
      })
      .finally(()=>{
        vm.api.checkpurchaselimit.send=false
      });
    },
    addExtension(){
      let vm = this
      vm.forms.extension.submitted=true
      vm.$v.forms.extension.$touch()
      if(vm.api.add_extension.send || vm.$v.forms.extension.$invalid) return;
      vm.api.add_extension.send=true
      vm.api.add_extension.validation_errors={}
      vm.api.add_extension.error_message=''
      VOIP_API.endpoints.devices.create({
        name: vm.forms.extension.name,
        product: vm.selectedExtn.product,
        action: "adduserproduct",
        uid: vm.getCurrentUser?.uid,
        purchase_by: "USER",
        purchase_account: vm.getCurrentUser?.account,
        service: vm.selectedExtn.product,
      })
      .then(() => {
        vm.$modal.hide(vm.modalName);
        vm.appNotify({
          message: "Request for adding extension is pending",
          type: "info",
        });
        vm.$emit("load");
      })
      .catch((ex) => {
        vm.api.add_extension.validation_errors=ex.own_errors
        vm.api.add_extension.error_message=ex.own_message
      })
      .finally(()=>{
        vm.forms.extension.submitted=false
        vm.api.add_extension.send=false
      });
    },
  },
};
</script>
