<template>
  <div>
    <h4>Deleted Resources</h4>

    <vb-table
      :isListEmpty="response.deleted_resources.length == 0"
      :listLength="response.deleted_resources.length"
      :loading="api.fetch_deleted_resources.send"
    >
      <tr slot="header">
        <th class="dialer-has-sort">
          <span>Name</span>
        </th>
        <th class="dialer-has-sort dialer-col-center">
          <span>Original Location</span>
        </th>
        <th class="dialer-has-sort">
          <span>Created Date</span>
        </th>
      </tr>
      <template #body="{ start, end }">
        <tr
          v-for="(deleted_resource, index) in response.deleted_resources"
          :key="deleted_resource.id"
          v-show="index >= start && index <= end"
        >
          <td td class="dialer-col-center wd-25">
            {{ deleted_resource.resource_type }}
          </td>
          <td td class="dialer-col-center wd-25">Original Loction</td>
          <td td class="dialer-col-center wd-25">
            {{ deleted_resource.createdAt }}
          </td>
        </tr>
      </template>
    </vb-table>
  </div>
</template>

<script>
import { VOIP_API } from "@/utils";
import { mapGetters } from "vuex";
import { GET_PAMARLY_ORGANIZATION_ID } from "@/store/helper/getters";
export default {
  name: "PamarlyDeletedResources",
  data() {
    return {
      api: {
        fetch_deleted_resources: this.$helperFunction.apiInstance(),
      },
      response: {
        deleted_resources: [],
      },
      design: {
        grid: true,
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([GET_PAMARLY_ORGANIZATION_ID]),
  },
  methods: {
    fetchDeletedResources() {
      let vm = this;
      vm.api.fetch_deleted_resources.send = true;
      VOIP_API.endpoints.pamarly.resources
        .deletedresources({
          organizationId: vm.GET_PAMARLY_ORGANIZATION_ID,
        })
        .then(({ data: { result: deleted_resources } }) => {
          vm.response.deleted_resources = deleted_resources;
        })
        .catch((ex) => {
          vm.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(() => {
          vm.api.fetch_deleted_resources.send = false;
        });
    },
  },
  mounted() {
    this.fetchDeletedResources();
  },
};
</script>

<style></style>
