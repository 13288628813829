<template>
  <div>
    
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.verify">
        
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.resend.send || api.verify.send ? '' : conditions.screen=screens.add">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Add Caller ID</div>
          <div class="plus-IOSIcon-container"></div>
        </div>
        
        <div class="innerModalIos">

          <form class="dialer-form lg-mar-bottom" @submit.prevent="verify()">
            <b-alert class="mt-32px" :show="!!api.verify.error_message" variant="danger">{{api.verify.error_message}}</b-alert>
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Number</div>
                <div class="textInsideCard onRightSide textAlignRight">{{ sendNumber }}</div>
              </div>
            </div>
            <div class="basicWhiteIOScard mt-16px mb-16px">
              <div class="basicWhiteIOScard-item flex-column">
                <div class="mobileInputIOS InputIosV2">
                  <label>Pincode</label>
                  <input 
                    type="text"
                    maxlength="6"
                    oninput="this.value = this.value.replace(/[^0-9]/gi, '')"
                    v-model="forms.verify.pincode" 
                    :disabled="api.verify.send" 
                  />
                  <p 
                    v-if="(forms.verify.submitted && $v.forms.verify.pincode.$invalid) || api.verify.validation_errors.pin" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.verify.pincode.required">* Pincode is required</span>
                    <span v-for="(em,i) in api.verify.validation_errors.pin" :key="i">* {{ em }}</span>
                  </p>
                </div>
              </div>
              <div class="basicWhiteIOScard-item" @click="api.resend.send || api.verify.send ? '' : 
                  forms.caller_id.reset();forms.verify.reset();conditions.screen=screens.add;
                "
              >
                <div class="headingInsideTable">Change Number</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
              </div>
              <div class="basicWhiteIOScard-item" @click="resend()">
                <div class="headingInsideTable">Resend Pincode</div>
                <vb-icon icon="reSend-onRightSide-IOSIcon" :class="`rightFacingArrow reSendIcon ${api.resend.send ? 'rotateIt' : ''}`" width="16.642" height="15.275" />
              </div>
            </div>
            <button class="IosFullWidthButton forDone mb-16px" :disabled="api.resend.send || api.verify.send">
              <vb-spinner v-if="api.verify.send" />
              <template v-else>Verify</template>
            </button>
          </form>

        </div>

      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Add Caller ID</div>
          <div class="plus-IOSIcon-container"></div>
        </div>

        <div class="innerModalIos">

          <form class="dialer-form lg-mar-bottom" @submit.prevent="addCallerId()">
            <b-alert class="mt-32px" :show="!!api.caller_id.error_message" variant="danger">{{api.caller_id.error_message}}</b-alert>
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Country Code</label>
                  <b-input-group>
                    <b-input-group-prepend>
                      <b-select v-model="forms.caller_id.country_code">
                        <b-select-option
                          v-for="country in response.countries" 
                          :key="country.cca2" 
                          :value="`${ country.idd.root }${ country.idd.suffixes ? country.idd.suffixes[0] || '' : '' }`"
                        >
                          {{ country | get_property('idd.root') }}{{ country | get_property('idd.suffixes[0]') }}
                        </b-select-option>
                      </b-select>
                    </b-input-group-prepend>
                  </b-input-group>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Number</label>
                  <b-input-group>
                    <b-form-input placeholder="Enter Number" type="number" :disabled="api.caller_id.send" v-model="forms.caller_id.number" />
                  </b-input-group>
                </div>
              </div>
              <div class="basicWhiteIOScard-item" v-if="(forms.caller_id.submitted && ($v.forms.caller_id.number.$invalid || $v.forms.caller_id.country_code.$invalid)) || 
                    api.caller_id.validation_errors.number
                  " 
              >
                <p 
                  v-if="(forms.caller_id.submitted && ($v.forms.caller_id.number.$invalid || $v.forms.caller_id.country_code.$invalid)) || 
                    api.caller_id.validation_errors.number
                  " 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.caller_id.number.required">* Number is required</span>
                  <span v-if="!$v.forms.caller_id.country_code.required">* Country Code is required</span>
                  <span v-for="(em,i) in api.caller_id.validation_errors.number" :key="i">* {{ em }}</span>
                </p>
              </div>
            </div>
            <button class="IosFullWidthButton forDone mb-16px" :disabled="api.caller_id.send">
              <vb-spinner v-if="api.caller_id.send" />
              <template v-else>Add</template>
            </button>
          </form>

        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { VOIP_API } from '@/utils/index';
let screens = {
  add: 'add',
  verify: 'verify',
}
export default {
  name: "AddCallerIdModal",
  props: {
    modalName: {
      type: String,
      default: 'AddCallerId'
    },
  },
  data() {
    return {
      forms: {
        caller_id: this.$helperFunction.formInstance({
          data: {
            number: '',
            country_code: '+92',
          },
        }),
        verify: this.$helperFunction.formInstance({
          data: {
            pincode: '',
          },
        }),
      },
      api: {
        countries: this.$helperFunction.apiInstance({  }),
        caller_id: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        verify: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        resend: this.$helperFunction.apiInstance({  }),
      },
      response: {
        countries: [],
      },
      conditions: {
        screen: screens.add,
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    screens(){ return screens },
    sendNumber(){ return `${this.forms.caller_id.country_code}${this.forms.caller_id.number}`.replace(/["+"]/g,'') },
  },
  validations: {
    forms: {
      caller_id: {
        number: {
          required,
        },
        country_code: {
          required,
        },
      },
      verify: {
        pincode: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchCountries()
    },
    onBeforeClose(){
      this.response.countries=[]
      this.conditions.screen=screens.add
      this.api.countries.reset()
      this.api.caller_id.reset()
      this.api.verify.reset()
      this.api.resend.reset()
      this.forms.caller_id.reset()
      this.forms.verify.reset()
    },
    fetchCountries(){
      let vm = this
      if(vm.api.countries.send) return;
      vm.api.countries.send=true
      VOIP_API.endpoints.location.getcountries()
      .then(({ data: countries })=>{
        vm.response.countries=countries
      })
      .finally(()=>{
        vm.api.countries.send=false
      })
    },
    addCallerId(){
      let vm = this
      vm.forms.caller_id.submitted=true
      vm.$v.forms.caller_id.$touch()
      if(vm.$v.forms.caller_id.$invalid || vm.api.caller_id.send) return;
      vm.api.caller_id.send=true
      vm.api.caller_id.error_message=''
      vm.api.caller_id.validation_errors={}
      VOIP_API.endpoints.telephone_number.create({
        number: vm.sendNumber,
      })
      .then(()=>{
        vm.conditions.screen=screens.verify
      })
      .catch((ex)=>{
        vm.api.caller_id.error_message=ex.own_message
        vm.api.caller_id.validation_errors=ex.own_errors
      })
      .finally(()=>{
        vm.forms.caller_id.submitted=false
        vm.api.caller_id.send=false
      })
    },
    verify(){
      let vm = this
      vm.forms.verify.submitted=true
      vm.$v.forms.verify.$touch()
      if(vm.$v.forms.verify.$invalid || vm.api.verify.send) return;
      vm.api.verify.send=true
      vm.api.verify.error_message=''
      vm.api.verify.validation_errors={}
      VOIP_API.endpoints.telephone_number.verfiypincode({
        number: vm.sendNumber,
        pin: vm.forms.verify.pincode,
      })
      .then(()=>{
        vm.$modal.hide(vm.modalName);
        vm.appNotify({
          message: "Your Caller ID has been verified.",
          type: "success",
        })
        vm.$emit('added');
      })
      .catch((ex)=>{
        vm.api.verify.error_message=ex.own_message
        vm.api.verify.validation_errors=ex.own_errors
      })
      .finally(()=>{
        vm.forms.verify.submitted=false
        vm.api.verify.send=false
      })
    },
    resend(){
      let vm = this
      if(vm.api.resend.send) return;
      vm.api.resend.send=true
      VOIP_API.endpoints.telephone_number.resendpincode({
        number: vm.sendNumber,
      })
      .then(()=>{
        vm.appNotify({
          message: "Pincode has been re-sended successfully",
          type: "success",
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: "danger",
        })
      })
      .finally(()=>{
        vm.api.resend.send=false
      })
    },
  },
};
</script>
