var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_vm.conditions.screen == 1 ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.create.send ? '' : _vm.conditions.screen = 0;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Tag")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]) : _vm.conditions.screen == 2 ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.delete.send || _vm.api.update.send ? '' : _vm.conditions.screen = 0;
        _vm.selected.tag_id = '';
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Tags")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.delete.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Tags")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 1;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    }
  })], 1)]), _c('div', {
    staticClass: "innerModalIos"
  }, [_vm.conditions.screen == 1 ? [_c('div', {}, [_c('b-form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.create();
      }
    }
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px mt-32px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Tag Name")]), _c('b-input', {
    attrs: {
      "type": "text",
      "disabled": _vm.api.create.send,
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.forms.create.tag,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create, "tag", $$v);
      },
      expression: "forms.create.tag"
    }
  }), _vm.forms.create.submitted && _vm.$v.forms.create.tag.$invalid ? [!_vm.$v.forms.create.tag.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Name is required")])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('vb-color-picker', {
    staticClass: "color-picker-input mobileIos",
    attrs: {
      "title": "Color",
      "disabled": _vm.api.create.send,
      "value": _vm.forms.create.color
    },
    on: {
      "change": function ($event) {
        _vm.forms.create.color = $event;
      }
    }
  }, [_vm.forms.create.submitted && _vm.$v.forms.create.color.$invalid ? _c('template', {
    slot: "error"
  }, [!_vm.$v.forms.create.color.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Color is required")])]) : _vm._e()]) : _vm._e()], 2)], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('vb-color-picker', {
    staticClass: "color-picker-input mobileIos",
    attrs: {
      "title": "Text Color",
      "disabled": _vm.api.create.send,
      "value": _vm.forms.create.text_color
    },
    on: {
      "change": function ($event) {
        _vm.forms.create.text_color = $event;
      }
    }
  }, [_vm.forms.create.submitted && _vm.$v.forms.create.text_color.$invalid ? _c('template', {
    slot: "error"
  }, [!_vm.$v.forms.create.text_color.required ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Text Color is required")])]) : _vm._e()]) : _vm._e()], 2)], 1)]), _c('button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "variant": "success",
      "disabled": _vm.api.create.send
    }
  }, [_vm.api.create.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)])], 1)] : _vm.conditions.screen == 2 ? [_c('div', {
    staticClass: "basicWhiteIOScard mb-16px mt-32px"
  }, [_c('button', {
    staticClass: "IosFullWidthButton forRemove mb-16px",
    on: {
      "click": function ($event) {
        _vm.api.delete.send || _vm.api.update.send ? '' : _vm.remove();
      }
    }
  }, [[_vm._v("Delete")]], 2)])] : [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.getVoipCallTags, function (tag) {
    return _c('div', {
      key: tag.id,
      staticClass: "basicWhiteIOScard-item d-flex justify-content-between",
      on: {
        "click": function ($event) {
          return _vm.setUpdateId(tag);
        }
      }
    }, [_c('div', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text.bg",
        value: {
          color: tag.colour,
          text_color: tag.text_color
        },
        expression: "{color:tag.colour,text_color:tag.text_color}",
        modifiers: {
          "text": true,
          "bg": true
        }
      }],
      staticClass: "IosTags headingInsideTable"
    }, [_vm._v(_vm._s(tag.tag))]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1);
  }), !_vm.getVoipCallTags.length ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.tags.send ? '' : 'There is no Tags',
      "design": 3
    }
  }, [_vm.api.tags.send ? _c('vb-loading', {
    staticClass: "text-center w-100",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }