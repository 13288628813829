var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [true ? _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 BuyNumberModal",
    class: `${_vm.screens == 'numbers' ? 'BuyNumber-SelectNumber' : 'BuyNumber-SelectCountry'}`,
    attrs: {
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', {
    staticClass: "dialer-number-add dialer-edit w-100"
  }, [_c('form', {
    staticClass: "dialer-form w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-user w-100"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header mt-8px pb-0 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Add number ")]), _c('vb-icon', {
    staticClass: "ml-2 cursor_pointer checkSlotsModal-icon",
    attrs: {
      "icon": "checkSlotsModal-icon",
      "height": "22px",
      "width": "22px"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CheckedServiceAvailableModal', {
          service: `aql`,
          number_category: _vm.numberCategory
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" How would you like to add your new number? ")])]), _vm.screens == 'country' ? [_c('div', {
    staticClass: "BuyNumber-subHeading"
  }, [_vm._v("Select country")]), _c('div', {
    staticClass: "BuyNumber-boxes-container"
  }, [_vm.api.countries.send || _vm.api.fetch_service.send ? _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "BuyNumber-box"
    }, [_c('div', {
      staticClass: "d-flex justify-content-center w-100"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "49px",
        "width": "80px"
      }
    })]), _c('div', {
      staticClass: "latestShimmerDesign mt-4",
      staticStyle: {
        "height": "27px",
        "width": "100%"
      }
    })]);
  }) : _vm._l(_vm.countries, function (country, key) {
    return _c('div', {
      key: key,
      staticClass: "BuyNumber-box",
      attrs: {
        "value": country.value
      },
      on: {
        "click": function ($event) {
          _vm.selected.country = country.value;
          _vm.selected.country == 'US' ? _vm.fetchRegions() : '';
        }
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-center w-100"
    }, [_c('img', {
      attrs: {
        "src": _vm._f("CountryImg")(country.value)
      }
    })]), _c('div', {
      staticClass: "BuyNumber-box-heading"
    }, [_vm._v(_vm._s(country.label))])]);
  })], 2), !_vm.api.countries.send && !_vm.api.fetch_service.send && !_vm.conditions.add_user ? _c('div', {
    staticClass: "addNumber-boxes-container"
  }, [_c('div', {
    staticClass: "addNumber-box",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AddNumberExistingModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-addCallerID-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('div', {
    staticClass: "addNumber-box-textPart"
  }, [_vm._v("Add caller ID")])], 1), _vm.$store.getters.getCurrentUser.appMode === 'standalone' ? _c('div', {
    staticClass: "addNumber-box",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AddManualNumberModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-addManualNumber-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('div', {
    staticClass: "addNumber-box-textPart"
  }, [_vm._v("Add number(manual)")])], 1) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.screens == 'region' ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mar-top mt-4"
  }, [_c('div', {
    staticClass: "dialer-input-field dialer-select-field"
  }, [_c('label', {
    staticClass: "dialer-input-label"
  }, [_vm._v("Select region")]), _c('ModelSelect', {
    staticClass: "form-control custom-select",
    attrs: {
      "options": _vm.regionsOptions,
      "isDisabled": _vm.api.regions.send,
      "placeholder": "select region"
    },
    on: {
      "input": function ($event) {
        return _vm.fetchRegionsGroups();
      }
    },
    model: {
      value: _vm.selected.region,
      callback: function ($$v) {
        _vm.$set(_vm.selected, "region", $$v);
      },
      expression: "selected.region"
    }
  })], 1)]) : _vm._e(), _vm.screens == 'location' ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mar-top mt-4"
  }, [_c('div', {
    staticClass: "dialer-input-field dialer-select-field"
  }, [_c('label', {
    staticClass: "dialer-input-label"
  }, [_vm._v("Select location")]), _c('ModelSelect', {
    staticClass: "form-control custom-select",
    attrs: {
      "options": _vm.regionGroupsOptions,
      "isDisabled": _vm.api.regions_group.send,
      "placeholder": "Select group"
    },
    on: {
      "input": function ($event) {
        return _vm.fetchUSNumbers();
      }
    },
    model: {
      value: _vm.selected.group,
      callback: function ($$v) {
        _vm.$set(_vm.selected, "group", $$v);
      },
      expression: "selected.group"
    }
  })], 1)]) : _vm._e(), _vm.screens == 'number_type' ? [_c('div', {
    staticClass: "BuyNumber-subHeading"
  }, [_vm._v("Select number type")]), _c('div', {
    staticClass: "BuyNumber-boxes-container"
  }, _vm._l(_vm.numberTypes, function (type, key) {
    return _c('div', {
      key: key,
      staticClass: "BuyNumber-box",
      attrs: {
        "value": type.value
      },
      on: {
        "click": function ($event) {
          _vm.selected.type = type.value;
          _vm.selected.type == 'ukc' ? _vm.fetchCities() : _vm.fetchCityNumbers();
        }
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-center w-100"
    }, [_c('vb-icon', {
      attrs: {
        "icon": _vm._f("UkIconOption")(type.value)
      }
    })], 1), _c('div', {
      staticClass: "BuyNumber-box-heading"
    }, [_vm._v(_vm._s(type.label))])]);
  }), 0)] : _vm._e(), _vm.screens == 'city' ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('div', {
    staticClass: "dialer-input-field dialer-select-field mt-4"
  }, [_c('label', [_vm._v("Select city to proceed")]), _c('ModelSelect', {
    staticClass: "form-control custom-select",
    attrs: {
      "options": _vm.citiesOptions,
      "isDisabled": _vm.api.cities.send,
      "placeholder": "Select city"
    },
    on: {
      "input": function ($event) {
        return _vm.fetchCityNumbers();
      }
    },
    model: {
      value: _vm.selected.city,
      callback: function ($$v) {
        _vm.$set(_vm.selected, "city", $$v);
      },
      expression: "selected.city"
    }
  })], 1)]) : _vm._e(), _vm.screens == 'category' ? [_c('div', {
    staticClass: "BuyNumber-subHeading"
  }, [_vm._v("Select number type")]), _c('div', {
    staticClass: "BuyNumber-boxes-container"
  }, _vm._l(_vm.numberCategories, function (type, key) {
    return _c('div', {
      key: key,
      staticClass: "BuyNumber-box twoIcons",
      attrs: {
        "value": type.value
      },
      on: {
        "click": function ($event) {
          _vm.selected.category = type.value;
          _vm.fetchCityNumbers();
        }
      }
    }, [_c('div', {
      staticClass: "d-flex justify-content-center w-100"
    }, [_c('vb-icon', {
      attrs: {
        "icon": type.icon
      }
    })], 1), _c('div', {
      staticClass: "BuyNumber-box-heading"
    }, [_vm._v(_vm._s(type.label))])]);
  }), 0)] : _vm._e(), _vm.screens == 'numbers' ? _c('div', {
    ref: "numberContainer",
    staticClass: "dialer-pick-number mt-0"
  }, [_c('div', {
    staticClass: "dialer-available-numbers mt-0"
  }, [_c('div', {
    staticClass: "dialer-number-picker mt-0"
  }, [_c('div', {
    staticClass: "dialer-tabs mt-5"
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_vm.api.numbers.send ? [_c('div', {
    staticClass: "d-flex flex-wrap"
  }, _vm._l(24, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "latestShimmerDesign mb-3",
      staticStyle: {
        "height": "50px",
        "width": "23%",
        "margin-right": "2%"
      }
    });
  }), 0)] : _c('div', {
    staticClass: "dialer-premium-number-section"
  }, [_vm.filterNumber.length == 0 ? _c('div', {
    staticClass: "mx-auto"
  }, [_c('vb-no-record', {
    staticClass: "mt-3",
    attrs: {
      "text": "There is no record",
      "design": 3
    }
  })], 1) : [_c('div', {
    staticClass: "dialer-number-picker"
  }, _vm._l(_vm.filterNumber, function (num) {
    return _c('a', {
      key: num.msisdn,
      staticClass: "dialer-button dialer-button-primary-outline dialer-number-button mb-3",
      class: {
        'dialer-button-primary-alt': _vm.selected.number == (num.msisdn || num.number)
      },
      staticStyle: {
        "padding": "8px 16px"
      },
      on: {
        "click": function ($event) {
          _vm.selected.number = num.msisdn || num.number;
        }
      }
    }, [_vm._v(" " + _vm._s(_vm._f("number_formater")(num.msisdn || num.number, _vm.selected.country)) + " "), _c('img', {
      staticClass: "dialer-number-badge",
      attrs: {
        "alt": ""
      }
    })]);
  }), 0)], [_vm.numberPages > 1 ? _c('div', {
    staticClass: "latestPagination-container mt-2"
  }, [_vm.pagination.page != 1 ? _c('b-icon', {
    attrs: {
      "icon": "chevron-left"
    },
    on: {
      "click": function ($event) {
        _vm.pagination.page = _vm.pagination.page - 1;
      }
    }
  }) : _vm._e(), _vm._l(_vm.numberPages - 1, function (page) {
    return _c('div', {
      key: page,
      staticClass: "buyNumberCircle",
      class: [{
        'active': _vm.pagination.page == page
      }, `page-${page}`],
      on: {
        "click": function ($event) {
          _vm.pagination.page = page;
        }
      }
    });
  }), _vm.numberPages != _vm.pagination.page ? _c('b-icon', {
    attrs: {
      "icon": "chevron-right"
    },
    on: {
      "click": function ($event) {
        _vm.pagination.page = _vm.pagination.page + 1;
      }
    }
  }) : _vm._e()], 2) : _vm._e(), _c('div', {
    staticClass: "mt-4 d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "backForward-container w-auto"
  }, [_c('div', {
    staticClass: "backForward-container-inner",
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-left"
    }
  }), _c('div', {
    staticClass: "backForward-text ml-2"
  }, [_vm._v("Back")])], 1)]), _vm.response.numbers.length > 0 ? _c('button', {
    staticClass: "dialer-button dialer-button-primary",
    staticStyle: {
      "width": "53%"
    },
    attrs: {
      "type": "button",
      "disabled": !_vm.selected.number || _vm.api.purchase.send
    },
    on: {
      "click": function ($event) {
        _vm.conditions.add_user ? _vm.selectNumber() : _vm.purchaseNumber();
      }
    }
  }, [_vm._v(" " + _vm._s(_vm.conditions.add_user ? 'Next' : 'Purchase number') + " ")]) : _vm._e(), _c('div', {
    staticStyle: {
      "opacity": "0"
    }
  }, [_vm._v("wsdsfsdgf")])])]], 2)], 2)])])])]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.screens != 'numbers' && _vm.screens != 'country',
      expression: "screens!='numbers' && screens!='country'"
    }],
    staticClass: "backForward-container mt-3"
  }, [_c('div', {
    staticClass: "backForward-container-inner",
    on: {
      "click": function ($event) {
        return _vm.back();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "chevron-left"
    }
  }), _c('div', {
    staticClass: "backForward-text ml-2"
  }, [_vm._v("Back")])], 1)])], 2)])])])]) : _vm._e(), _c('AddNumberExistingModal', {
    attrs: {
      "modalName": `${_vm._uid}-AddNumberExistingModal`
    },
    on: {
      "interface": function ($event) {
        _vm.$emit('existing-number-added');
        _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _c('AddPortNumberModal', {
    attrs: {
      "modalName": `${_vm._uid}-AddPortNumberModal`
    },
    on: {
      "added": function ($event) {
        _vm.$emit('port-number-added');
        _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _c('AddManualNumberModal', {
    attrs: {
      "modalName": `${_vm._uid}-AddManualNumberModal`
    },
    on: {
      "load": function ($event) {
        _vm.$emit('manual-number-added');
        _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _c('NumberAddressModal', {
    attrs: {
      "modalName": `${_vm.modalName}-AddAddressModal`
    },
    on: {
      "interface": function ($event) {
        return _vm.purchase($event, _vm.addressid);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }