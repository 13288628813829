var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup scrollable dialer-analytics-user-modal",
    attrs: {
      "width": "60%",
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "opened": function ($event) {
        return _vm.onOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pb-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("User analytics")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "dialer-analytics-user dialer-analytics-tray"
  }, [_c('div', {
    staticClass: "analytics-newHeader mb-20px"
  }, [_c('div', {
    staticClass: "analytics-newHeader-leftSide"
  }, [_c('h1', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v(_vm._s(_vm.data.user.extn) + " " + _vm._s(_vm.data.user.name))])]), _c('div', {
    staticClass: "analytics-newHeader-rightSide"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px"
  }, [_c('b-dropdown', {
    staticClass: "ico-filter",
    attrs: {
      "varient": "link",
      "no-caret": "",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm.filter.stat.date ? _c('span', {
          staticClass: "mr-12px"
        }, [_vm._v(_vm._s(_vm.filter.stat.date))]) : _vm._e(), _c('b-icon', {
          staticClass: "fillBlack",
          attrs: {
            "icon": "three-dots-vertical",
            "variant": "dark",
            "scale": "1.5"
          }
        })];
      },
      proxy: true
    }])
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('b-dropdown-item', {
      key: tab.value,
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
          _vm.selected.tab == 'custom' ? _vm.$modal.show(`${_vm._uid}-dateFilterModal_insideModal`) : _vm.fetchstat();
        }
      }
    }, [_c('vb-icon', {
      staticClass: "mr-12px",
      style: `opacity:${_vm.selected.tab === tab.value ? '1' : '0'}`,
      attrs: {
        "icon": "markAsComplete-icon",
        "width": "14.49px",
        "height": "10.431px"
      }
    }), _c('span', [_vm._v(_vm._s(tab.text))])], 1);
  }), 1)], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between flex-wrap w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column mb-3"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v(_vm._s(_vm.filter.stat.date))]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Success RATE: " + _vm._s(_vm.response.stat.successRate) + " %")])])]), _c('div', {
    staticClass: "dialer-analytics-totals v2 updatedAgain mt-20px"
  }, [_c('div', {
    staticClass: "dialer-call-total dialer-total"
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-TotalCall-icon"
    }
  }), _c('div', [_vm._v("total Calls")])], 1)]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm.callpercentage.total))])])])]), _c('div', {
    staticClass: "dialer-call-total dialer-missed"
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-MissedCall-icon"
    }
  }), _c('div', [_vm._v("missed Calls")])], 1), _c('span', {
    staticClass: "dialer-call-total-time"
  })]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm.callpercentage.missed))]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.callpercentage.missed_percentage};--fg:#dc3545`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.callpercentage.missed_percentage) + "% ")])])])])])]), _c('div', {
    staticClass: "dialer-call-total dialer-inbound"
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-InboundCall-icon"
    }
  }), _c('div', [_vm._v("Receive Calls")])], 1), _c('span', {
    staticClass: "dialer-call-total-time"
  })]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm.callpercentage.received))]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.callpercentage.received_percentage};--fg:#28a745`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.callpercentage.received_percentage) + "% ")])])])])])]), _c('div', {
    staticClass: "dialer-call-total dialer-outbound"
  }, [_c('div', {
    staticClass: "dialer-call-total-inner"
  }, [_c('div', {
    staticClass: "dialer-call-total-top"
  }, [_c('div', {
    staticClass: "dialer-call-type"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CallSummary-OutboundCall-icon"
    }
  }), _c('div', [_vm._v("outbound Calls")])], 1)]), _c('div', {
    staticClass: "dialer-call-figures"
  }, [_c('h4', {
    staticClass: "dialer-call-number"
  }, [_vm._v(_vm._s(_vm.callpercentage.outgoing))]), _c('div', {
    staticClass: "newProgressBar"
  }, [_c('div', {
    style: `--value:${_vm.callpercentage.outgoing_percentage};--fg:#5576d1`,
    attrs: {
      "role": "progressbar",
      "aria-valuenow": "65",
      "aria-valuemin": "0",
      "aria-valuemax": "100"
    }
  }, [_c('div', {
    staticClass: "newProgressBar-inner"
  }, [_vm._v(" " + _vm._s(_vm.callpercentage.outgoing_percentage) + "% ")])])])])])])]), _c('div', {
    staticClass: "visavail mt-20px",
    staticStyle: {
      "width": "100%",
      "overflow": "hidden"
    },
    attrs: {
      "id": `conatiner-measure-${_vm.data.accountcode}`
    }
  }, [_c('p', {
    staticStyle: {
      "overflow": "hidden"
    },
    attrs: {
      "id": `p-measure-${_vm.data.accountcode}`
    }
  })]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v(_vm._s(_vm.filter.stat.date))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-25"
  }, [_c('b-dropdown', {
    attrs: {
      "varient": "link",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "mr-3"
        }, [_vm._v("Filter")]), _c('b-icon', {
          attrs: {
            "icon": "chevron-down"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-text', [_c('ul', {
    staticClass: "d-flex flex-column list-unstyled mb-0"
  }, _vm._l(_vm.calltypes, function (calltype) {
    return _c('li', {
      key: calltype.value,
      staticClass: "d-inline-flex px-1 justify-content-between align-items-center"
    }, [_c('label', {
      staticClass: "mb-0"
    }, [_vm._v(_vm._s(calltype.text))]), _c('b-form-radio', {
      staticClass: "before-after-checkbox-align-center",
      attrs: {
        "name": "some-radios",
        "value": calltype.value
      },
      model: {
        value: _vm.filter.stat.call_type,
        callback: function ($$v) {
          _vm.$set(_vm.filter.stat, "call_type", $$v);
        },
        expression: "filter.stat.call_type"
      }
    })], 1);
  }), 0)])], 1)], 1)]), _c('div', {
    staticClass: "mt-20px whiteBoxWithBorderInsideGreyBox"
  }, [_vm.api.stat.send ? _vm._l(4, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-2",
      staticStyle: {
        "height": "40px",
        "width": "40px"
      }
    }), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "22px",
        "width": "150px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign mt-1",
      staticStyle: {
        "height": "19px",
        "width": "100px"
      }
    })])]), _c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "22px",
        "width": "100px"
      }
    })]);
  }) : _vm.isEmpty(_vm.filterStat) ? _c('vb-no-record', {
    staticClass: "my-5",
    attrs: {
      "text": 'There is no Call Logs',
      "design": 3
    }
  }) : _vm._l(_vm.filterStat, function (call, key) {
    return _c('div', {
      key: key,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('img', {
      staticClass: "mr-3",
      attrs: {
        "width": "25px",
        "height": "25px",
        "src": _vm._f("callImage")(call, _vm.getCurrentUser.account),
        "alt": ""
      }
    }), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "latestGreyBox-descriptionText mt-0 text-black"
    }, [_vm._v(_vm._s(_vm._f("callName")(call)))]), _c('div', {
      staticStyle: {
        "font-size": "14px"
      }
    }, [_vm._v(_vm._s(call.calldate))])])]), _c('div', {
      staticClass: "latestGreyBox-descriptionText mt-0"
    }, [_vm._v(_vm._s(_vm._f("time_audio")(call.duration)))])]);
  })], 2)])])]), _c('modal', {
    staticClass: "callReportingFilterModal",
    attrs: {
      "name": `${_vm._uid}-dateFilterModal_insideModal`
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 position-relative"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Filters")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-dateFilterModal_insideModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "analytics-newHeader-text"
  }, [_c('div', {
    staticClass: "d-flex customDate"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-50 pr-2"
  }, [_c('span', [_vm._v("From:")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "starttime"
    },
    model: {
      value: _vm.filter.stat.start_date,
      callback: function ($$v) {
        _vm.$set(_vm.filter.stat, "start_date", $$v);
      },
      expression: "filter.stat.start_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned w-50 pl-2"
  }, [_c('span', [_vm._v("To:")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input",
    attrs: {
      "id": "endtime"
    },
    model: {
      value: _vm.filter.stat.end_date,
      callback: function ($$v) {
        _vm.$set(_vm.filter.stat, "end_date", $$v);
      },
      expression: "filter.stat.end_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        _vm.fetchstat();
        _vm.$modal.hide(`${_vm._uid}-dateFilterModal_insideModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-browse-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Fetch")])], 1)])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }