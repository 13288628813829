var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-area d-flex h-100 dialer_main_body"
  }, [_c('div', {
    staticClass: "dialer_main_body_inner w-100"
  }, [!_vm.selected.tab ? _c('div', {
    staticClass: "dialer_inner_left_side d-r-768px-none"
  }, [_c('div', {
    staticClass: "d-flex mainSettingsHeadingContainer"
  }, [_vm._m(0), _c('b-icon', {
    staticClass: "cursor_pointer XiconForClosingSettingsInMobile",
    attrs: {
      "icon": "x",
      "font-scale": "2"
    }
  })], 1), _c('div', {
    staticClass: "dialer_inner_left_side_inner allow-scroll mobileSettingsPanel"
  }, [_c('ul', {
    staticClass: "list-unstyled basicWhiteIOScard"
  }, _vm._l(_vm.tabs.user, function (tab) {
    return _c('li', {
      key: tab.component,
      staticClass: "dialer_tab_btn basicWhiteIOScard-item",
      class: {
        'active': _vm.selected.tab === tab.component
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.component;
        }
      }
    }, [_c('a', {
      staticClass: "text-capitalize"
    }, [_c('span', {
      staticClass: "dialer-settingPanel-icon basicIOSIcon"
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.icon
      }
    })], 1), _c('span', {
      staticClass: "dialer-settingPanel-text headingInsideTable"
    }, [_vm._v(_vm._s(tab.label))]), _c('span', [_c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)])]);
  }), 0), _vm.getCurrentUser.administrator_account ? [_vm._m(1), _c('ul', {
    staticClass: "list-unstyled basicWhiteIOScard"
  }, _vm._l(_vm.tabs.admin, function (tab) {
    return _c('li', {
      key: tab.component,
      staticClass: "dialer_tab_btn basicWhiteIOScard-item",
      class: {
        'active': _vm.selected.tab === tab.component
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.component;
        }
      }
    }, [_c('a', {
      staticClass: "text-capitalize"
    }, [_c('span', {
      staticClass: "dialer-settingPanel-icon basicIOSIcon"
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.icon
      }
    })], 1), _c('span', {
      staticClass: "dialer-settingPanel-text headingInsideTable"
    }, [_vm._v(_vm._s(tab.label))]), _c('span', [_c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)])]);
  }), 0)] : _vm._e(), _vm.getCurrentUser.administrator_account ? [_vm._m(2), _c('ul', {
    staticClass: "list-unstyled basicWhiteIOScard"
  }, _vm._l(_vm.tabs.analytics, function (tab) {
    return _c('li', {
      key: tab.component,
      staticClass: "dialer_tab_btn basicWhiteIOScard-item",
      class: {
        'active': _vm.selected.tab === tab.component
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.component;
        }
      }
    }, [_c('a', {
      staticClass: "text-capitalize"
    }, [_c('span', {
      staticClass: "dialer-settingPanel-icon basicIOSIcon"
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.icon
      }
    })], 1), _c('span', {
      staticClass: "dialer-settingPanel-text headingInsideTable"
    }, [_vm._v(_vm._s(tab.label))]), _c('span', [_c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)])]);
  }), 0)] : _vm._e()], 2)]) : _vm._e(), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.selected.tab,
      expression: "selected.tab"
    }],
    class: `dialer_inner_right_side v2`
  }, [_c('keep-alive', {
    attrs: {
      "include": _vm.keepAliveTabs
    }
  }, [_c(_vm.selected.tab, {
    tag: "component",
    on: {
      "back": function ($event) {
        _vm.selected.tab = '';
      }
    }
  })], 1), _c('CheckSlotModal')], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "first-section mb-16px"
  }, [_c('div', {
    staticClass: "topMainHeading"
  }, [_vm._v("My Settings")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex mainSettingsHeadingContainer"
  }, [_c('div', {
    staticClass: "first-section mb-16px"
  }, [_c('div', {
    staticClass: "topMainHeading"
  }, [_vm._v("Administrator")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex mainSettingsHeadingContainer"
  }, [_c('div', {
    staticClass: "first-section mb-16px"
  }, [_c('div', {
    staticClass: "topMainHeading"
  }, [_vm._v("Analytics")])])]);

}]

export { render, staticRenderFns }