<template>
  <div class="bill-file-main ml-2 mt-2">
    <div class="bill-file-sub-main">
      <!--  -->
      <div v-if="file.progress && file.progress.is_process" class="bill-file-progress">
        <div class="ml-2">
          <small>{{ file.progress.loaded }} / {{ file.progress.total }}</small>
          <small>{{ file.progress.percentage }} %</small>
        </div>
        <b-progress :max="100" :value="file.progress.percentage" />
      </div>
      <span class="dialer-close-icon" @click="onCancel()">
        <b-icon icon="x" />
      </span>
      <div class="bill-file-content">
        <div class="bill-file-image-content" v-if="fileDisplayType=='image'" @click="$modal.show(`${random_id}-MediaFilesModal`, {
          channel_id, 
          file_id: file.uid,
          is_action: true,
        })">
          <img :src="src" />
        </div>
        <div class="bill-file-video-content" v-else-if="fileDisplayType=='video'" @click="$modal.show(`${random_id}-MediaFilesModal`, {
          channel_id, 
          file_id: file.uid,
          is_action: true,
        })">
          <VueVideoThumbnail
            :video-src="src"
            :snapshot-at-duration-percent="parseInt(0, 10)"
            :width="500"
            :height="300"
            :chunks-quantity="4"
          />
        </div>
        <div class="bill-file-audio-content" v-else-if="fileDisplayType=='audio'">
          <vb-audio-player :src="src" :design="'insideChatAudioRecord'" />
        </div>
        <div class="bill-file-card-content" v-else>
          <div class="d-flex mt-3 topPart">
            <span class="bill-file-card-icon">
              <img :src="file.file_image" />
            </span>
            <span class="bill-file-card-name ml-2 mt-2">{{ file.name }}</span>
          </div>
          <div class="d-flex mt-2">
            <small class="bill-file-card-size mr-2">{{ file.file_size }}</small>
            <small class="bill-file-card-type">{{ file.file_type }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import VueVideoThumbnail from 'vue-video-thumbnail'
export default {
  name: "File",
  components: {
    VueVideoThumbnail
  },
  props: {
    file: {
      type: Object,
      deafult: () => {
        return {};
      },
    },
    channel_id: {
      type: String,
    },
    random_id: {
      type: String,
      default: '',
    },
  },
  computed: {
    fileDisplayType(){
      if(this.file?.file_type=='image' && ['png','jpg','jpeg','gif'].includes(this.file?.ext)) return 'image'
      else if(this.file?.file_type=='video') return 'video'
      else if(this.file?.file_type=='audio') return 'audio'
      else return 'card'
    },
    src(){
      return this.file?.src ?? ''
    },
  },
  methods: {
    onCancel(){
      this.$delete(this.$store.state.mattermost.channels[this.channel_id].actions.files,this.file.uid);
      this.$delete(this.$store.state.mattermost.channels[this.channel_id].actions.uploaded_files,this.file.file_id);
      this.file?.source?.cancel?.();
    },
  },
};
</script>

<style lang="scss">

</style>