<template>
  <div class="dialer-settings-main">
    <div v-if="conditions.screen==2" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="conditions.screen=0">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Pin for voicemail</div>
    </div>
    <div v-else-if="conditions.screen==1" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="conditions.screen=0">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">{{selected.greeting_type ? 'Basic' : 'Advanced'}}</div>
    </div>
    <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Voicemail Settings</div>
    </div>

    <section class="dialer-settings-section dialer-voicemail-settings">
      <div>
        
        <div v-if="conditions.screen==2">
          
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Pin</label>
                <input placeholder="6 digit Pin"
                  type="text"
                  maxlength="6"
                  oninput="this.value = this.value.replace(/[^0-9]/gi, '')"
                  v-model="forms.update_voicemail_setting.pin" 
                  :disabled="api.update_voicemail_setting.send || !forms.update_voicemail_setting.password_enable || api.load.send"
                />
                
                <template v-if="(forms.update_voicemail_setting.submitted && forms.update_voicemail_setting.pin.$invalid) || api.update_voicemail_setting.validation_errors.mailboxpin">
                  <small class="danger field-error bold-4" v-for="(em,i) in api.update_voicemail_setting.validation_errors.mailboxpin" :key="i">{{em}}</small>
                  <small class="danger sm-mar-top" v-if="!$v.forms.update_voicemail_setting.pin.required">*Pin is Required</small>
                  <small 
                    class="danger sm-mar-top" 
                    v-if="!$v.forms.update_voicemail_setting.pin.minLength"
                  >
                    *Pin should be {{ $v.forms.update_voicemail_setting.pin.$params.minLength.max }} digit
                  </small>
                </template>
              </div>
            </div>
          </div>
        </div>

        <div v-else-if="conditions.screen==1">
          
          <vb-loading type="loader" v-if="api.list.send" slot="loading" />
          <template v-else>
            <template v-if="selected.greeting_type">
              <div class="basicWhiteIOScard mt-32px">
                <div class="basicWhiteIOScard-item justify-content-between d-flex">
                  <div class="d-flex align-items-start">
                    <div class="d-flex flex-column">
                      <div class="headingInsideTable">Temporary</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-start">
                    <div class="mr-8px">
                      <vb-audio-player class="IosAudioPlayer" v-if="temp.voice" :disabled="api.change_greeting_type.send" :src="temp.voice | base64_to_url" />
                      <div class="textInsideCard mt-5px" v-else>No Audio</div>
                    </div>
                    <div class="dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight">
                      <vb-loading type="loader" v-if="api.delete_record_voice_mail.send.includes('temp')" />
                      <b-dropdown v-else>
                        <template #button-content>
                          <b-icon icon="three-dots-vertical" scale="1.5"></b-icon>
                        </template>
                        <b-dropdown-item @click="greetingOptionSelect('record','temp')">Record a new Greeting Message</b-dropdown-item>
                        <b-dropdown-item @click="greetingOptionSelect('upload','temp')">Upload a Greeting Message</b-dropdown-item>
                        <b-dropdown-item @click="greetingOptionSelect('shift','temp')">Select file from sound library</b-dropdown-item>
                      </b-dropdown>
                    </div>
                  </div>
                </div>
              </div>
            </template>
            <template v-else>
              <div class="basicWhiteIOScard mt-32px">
                <div class="basicWhiteIOScard-item">
                  <div class="d-flex align-items-start">
                    <div class="d-flex flex-column">
                      <div class="headingInsideTable">Busy</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <vb-loading type="loader" v-if="api.delete_record_voice_mail.send.includes('busy')" />
                    <template v-else>
                      <div class="mr-6px">
                        <vb-audio-player class=" IosAudioPlayer" v-if="busy.voice" :disabled="api.change_greeting_type.send" :src="busy.voice | base64_to_url" />
                        <div class="textInsideCard onRightSide textAlignRight" v-else>No Audio</div>
                      </div>
                      <div class="dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight">
                        <b-dropdown>
                          <template #button-content>
                            <b-icon icon="three-dots-vertical" scale="1.5"></b-icon>
                          </template>
                          <b-dropdown-item @click="greetingOptionSelect('record','busy')">Record a new Greeting Message</b-dropdown-item>
                          <b-dropdown-item @click="greetingOptionSelect('upload','busy')">Upload a Greeting Message</b-dropdown-item>
                          <b-dropdown-item @click="greetingOptionSelect('shift','busy')">Select file from sound library</b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="d-flex align-items-center">
                    <div class="d-flex flex-column">
                      <div class="headingInsideTable">Greet</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <vb-loading type="loader" v-if="api.delete_record_voice_mail.send.includes('greet')" />
                    <template v-else>
                      <div class="mr-6px">
                        <vb-audio-player class=" IosAudioPlayer" v-if="greet.voice" :disabled="api.change_greeting_type.send" :src="greet.voice | base64_to_url" />
                        <div class="textInsideCard onRightSide textAlignRight" v-else>No Audio</div>
                      </div>
                      <div class="dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight">
                        <b-dropdown>
                          <template #button-content>
                            <b-icon icon="three-dots-vertical" scale="1.5"></b-icon>
                          </template>
                          <b-dropdown-item @click="greetingOptionSelect('record','greet')">Record a new Greeting Message</b-dropdown-item>
                          <b-dropdown-item @click="greetingOptionSelect('upload','greet')">Upload a Greeting Message</b-dropdown-item>
                          <b-dropdown-item @click="greetingOptionSelect('shift','greet')">Select file from sound library</b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="d-flex align-items-center">
                    <div class="d-flex flex-column">
                      <div class="headingInsideTable">Unavailable</div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center">
                    <vb-loading type="loader" v-if="api.delete_record_voice_mail.send.includes('unavail')" />
                    <template v-else>
                      <div class="mr-6px">
                        <vb-audio-player class=" IosAudioPlayer" v-if="unavail.voice" :disabled="api.change_greeting_type.send" :src="unavail.voice | base64_to_url" />
                        <div class="textInsideCard onRightSide textAlignRight" v-else>No Audio</div>
                      </div>
                      <div class="dialer-dropdown dialer-dropDown-latest dialer-dropDown-onRight">
                        <b-dropdown >
                          <template #button-content>
                            <b-icon icon="three-dots-vertical" scale="1.5"></b-icon>
                          </template>
                          <b-dropdown-item @click="greetingOptionSelect('record','unavail')">Record a new Greeting Message</b-dropdown-item>
                          <b-dropdown-item @click="greetingOptionSelect('upload','unavail')">Upload a Greeting Message</b-dropdown-item>
                          <b-dropdown-item @click="greetingOptionSelect('shift','unavail')">Select file from sound library</b-dropdown-item>
                        </b-dropdown>
                      </div>
                    </template>
                  </div>
                </div>
              </div>
            </template>
          </template>
        </div>

        <template v-else>
          

          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Voicemail Pin Enabled</div>
                </div>
              </div>
              <div class="position-relative">
                <b-form-checkbox 
                  ref="password_enable"
                  class="dialer-switch IosSwitch" 
                  :disabled="api.update_voicemail_setting.send || api.load.send" 
                  v-model="forms.update_voicemail_setting.password_enable" 
                  @change="checkPermission(getUserPermissions.voice_mailbox_sharing,()=>updateVoicemailSetting())" 
                  name="check-button" 
                  switch 
                />  
              </div>
            </div>
            <div v-if="forms.update_voicemail_setting.password_enable" class="basicWhiteIOScard-item" @click="conditions.screen=2">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Pin for voicemail</div>
                </div>
              </div>
              <div class="d-flex align-items-start" @click="checkPermission(getUserPermissions.voice_mailbox_sharing,()=>conditions.screen=2)">
                <div class="mr-8px textInsideCard textAlignRight onRightSide">{{forms.update_voicemail_setting.pin}}</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Send voicemail alerts to email</div>
                </div>
              </div>
              <div class="position-relative">
                <b-form-checkbox 
                  ref="email_enable"
                  class="dialer-switch IosSwitch"
                  :disabled="api.update_voicemail_setting.send || api.load.send" 
                  v-model="forms.update_voicemail_setting.email_enable" 
                  @change="updateVoicemailSetting()" 
                  name="check-button" 
                  switch 
                />
              </div>
            </div>
            <template v-if="forms.update_voicemail_setting.email_enable">
              <div class="basicWhiteIOScard-item" v-if="userType=='user'">
                
                <div class="d-flex flex-column w-100">
                  <div class="headingInsideTable">Email for voicemail</div>
                  <div class="mobileInputIOS w-100 mt-8px">
                    <input class="w-100" label="Company name" :disabled="api.update_voicemail_setting.send || api.load.send" v-model="forms.update_voicemail_setting.email" @input="updateVoicemailSetting()" />
                    <template v-if="(forms.update_voicemail_setting.submitted && $v.forms.update_voicemail_setting.email.$invalid) || api.update_voicemail_setting.validation_errors.mailboxemail">
                      <small class="danger field-error bold-4" v-for="(em,i) in api.update_voicemail_setting.validation_errors.mailboxemail" :key="i">{{em}}</small>
                      <small class="danger" v-if="!$v.forms.update_voicemail_setting.email.required">*Email is Required</small>
                      <small class="danger" v-if="!$v.forms.update_voicemail_setting.email.email">*Invalid Email</small>
                    </template>
                  </div>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="d-flex align-items-start">
                  <div class="d-flex flex-column">
                    <div class="headingInsideTable">Voicemail attachment via email</div>
                  </div>
                </div>
                <div class="position-relative">
                  <b-form-checkbox 
                    ref="voicemail_attachment"
                    class="dialer-switch IosSwitch" 
                    :disabled="api.update_voicemail_setting.send || !getUserPermissions.voicemail_attachment || api.load.send" 
                    v-model="forms.update_voicemail_setting.voicemail_attachment" 
                    @change="updateVoicemailSetting()" 
                    name="check-button" 
                    switch 
                  />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="d-flex align-items-start">
                  <div class="d-flex flex-column">
                    <div class="headingInsideTable">Voicemail transcript via email</div>
                  </div>
                </div>
                <div class="position-relative">
                  <b-form-checkbox 
                    ref="voicemail_transcript"
                    class="dialer-switch IosSwitch" 
                    :disabled="api.update_voicemail_setting.send || !getUserPermissions.voice_mail_transcript || api.load.send" 
                    v-model="forms.update_voicemail_setting.voicemail_transcript" 
                    @change="updateVoicemailSetting()" 
                    name="check-button" 
                    switch 
                  />
                </div>
              </div>
            </template>
          </div>

          <div class="basicWhiteIOScard mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Voicemail Greetings</div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard textAlignRight onRightSide mr-16px">
                  {{selected.greeting_type ? 'Basic' : 'Advanced'}}
                </div>
                <div :class="`${conditions.change_greeting_type ? 'rotate-svg IosVersion' : ''}`">
                  <b-form-checkbox 
                    ref="greeting_switch"
                    class="dialer-switch IosSwitch" 
                    :disabled="api.change_greeting_type.send || api.load.send" 
                    :checked="!selected.greeting_type" 
                    @change="changeGreetingType()" 
                    switch 
                  />  
                </div>
              </div>
            </div>
          </div>
          
          <div class="basicWhiteIOScard">
            <template v-if="conditions.change_greeting_type">
              <div class="basicWhiteIOScard-item" @click="changeGreetingType()">
                <div class="headingInsideTable">Basic</div>
                <div>

                </div>
                <vb-icon v-if="selected.greeting_type" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
              </div>
              <div class="basicWhiteIOScard-item" @click="changeGreetingType()">
                <div class="headingInsideTable">Advanced</div>
                <vb-icon v-if="!selected.greeting_type" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
              </div>
            </template>

            <div class="basicWhiteIOScard-item" @click="conditions.screen=1">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Greetings Type</div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard mr-8px textAlignRight onRightSide">{{ selected.greeting_type ? 'Basic' : 'Advanced' }}</div>
                <div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
            </div>
            
          </div>
          
          <div class="textAboveCard">Mailbox Shared Accounts Settings</div>
          <div class="basicWhiteIOScard mb-16px">
            <div class="basicWhiteIOScard-item" 
              @click.stop="checkPermission(getUserPermissions.voice_mailbox_sharing,()=>$modal.show('MailboxShared', {
                accountcode: voipaccount,
              }))"
            >
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Mailbox Shared With</div>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard primaryColor mr-6px textAlignRight onRightSide">
                  <template v-if="selectedMemebersLength > 0">{{ selectedMemebersLength }}</template>
                  <template v-else> Add </template> users
                </div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
          </div>

        </template>

      </div>
    </section>
    <AddRemoveUsersModal :modalName="'MailboxShared'" @update-list="load()" :type="'share_voice_mail_box'" />
    <ShiftSoundFileModal @update-list="afterAdding()" />
    <UploadAudioModal title="Upload voicemail greetings" @audio="uploadAudio($event)" />
    <AudioRecordingModal @audio="uploadAudio($event)" />
  </div>
</template>

<script>
import AddRemoveUsersModal from "../modals/users/AddRemoveUsers.vue";
import AudioRecordingModal from "../modals/common/AudioRecording.vue";
import ShiftSoundFileModal from '../modals/voicemail/ShiftSoundFile.vue';

import UploadAudioModal from "../modals/common/UploadAudio.vue";

import _ from "lodash";
import { email, minLength, requiredIf } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { $fn, VOIP_API, } from "@/utils/index";
import { permission_handling } from '@/mixin';
export default {
  name: "Voicemail",
  mixins: [permission_handling],
  components: {
    AddRemoveUsersModal,
    AudioRecordingModal,
    UploadAudioModal,
    ShiftSoundFileModal,
  },
  inject:['isEmpty','getProperty','appNotify'],
  props: {
    accountcode: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      response: {
        selected_members: {},
        available_members: {},
        voicemail: {},
      },
      api: {
        load: this.$helperFunction.apiInstance(),
        update_voicemail_setting: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true }),
        delete_record_voice_mail: this.$helperFunction.apiInstance({ send: [] }),
        change_greeting_type: this.$helperFunction.apiInstance(),
        list: this.$helperFunction.apiInstance(),
      },
      selected: {
        greeting_type: false,
      },
      forms: {
        update_voicemail_setting: this.$helperFunction.formInstance({
          data: {
            email: '',
            pin: '',
            voicemail_attachment: false,
            voicemail_transcript: false,
            email_enable: false,
            password_enable: false,
          }
        }),
      },
      conditions: {
        change_greeting_type: false,
        screen: 0,
      },
    };
  },
  computed:{
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getCurrentUser: 'getCurrentUser',
      getVoipUsersAlises: 'getVoipUsersAlises',
    }),
    user(){ return this.getVoipUsersAlises[this.accountcode] ?? this.getVoipUsersAlises[this.getCurrentUser?.account] },
    voipaccount(){ return this.user?.voipaccount },
    userType(){ return this.user?.user?.user_type },
    selectedMemebersLength(){ return Object.values(this.response.selected_members ?? {}).length },
    busy() { return Object.values(this.response.voicemail).find((item) => item.type === "busy") ?? {}; },
    greet() { return Object.values(this.response.voicemail).find((item) => item.type === "greet") ?? {}; },
    temp() { return Object.values(this.response.voicemail).find((item) => item.type === "temp") ?? {}; },
    unavail() { return Object.values(this.response.voicemail).find((item) => item.type === "unavail") ?? {}; },
  },
  validations: {
    forms: {
      update_voicemail_setting: {
        email: {
          required: requiredIf(function () {
            return this.userType=='user' && this.forms.update_voicemail_setting.email_enable;
          }),
          email,
        },
        pin: {
          required: requiredIf(function () {
            return this.forms.update_voicemail_setting.password_enable;
          }),
          minLength: minLength(6),
        },
      },
    },
  },
  methods: {
    load() {
      let vm = this
      if (vm.api.load.send) return;
      vm.api.load.send = true;
      VOIP_API.endpoints.voicemail.list.servicevoicemail(vm.voipaccount, {
        fid: 12,
        id: vm.voipaccount
      })
      .then(({ data: res }) => {
        vm.response.selected_members = _.isPlainObject(res.detail.members.selected) ? res.detail.members.selected : {};
        vm.response.available_members = _.isPlainObject(res.detail.members.available) ? res.detail.members.available : {};
        const setting = res.detail.settings;
        if(!setting) return;
        vm.forms.update_voicemail_setting.email = setting.mailboxemail
        vm.forms.update_voicemail_setting.pin = setting.mailboxpin || $fn.makeid(6,'number')
        vm.forms.update_voicemail_setting.voicemail_attachment = setting.voicemail_attachment != "No"
        vm.forms.update_voicemail_setting.voicemail_transcript = setting.voicemail_transcript != "No"
        vm.forms.update_voicemail_setting.email_enable = ['1',1].includes(setting.emailvoicemail)
        vm.forms.update_voicemail_setting.password_enable = setting.mailbox_password_enable!='No'
      })
      .finally(() => {
        if(vm.$refs['voicemail_attachment']) vm.$refs['voicemail_attachment'].localChecked=vm.forms.update_voicemail_setting.voicemail_attachment
        if(vm.$refs['voicemail_transcript']) vm.$refs['voicemail_transcript'].localChecked=vm.forms.update_voicemail_setting.voicemail_transcript
        if(vm.$refs['email_enable']) vm.$refs['email_enable'].localChecked=vm.forms.update_voicemail_setting.email_enable
        if(vm.$refs['password_enable']) vm.$refs['password_enable'].localChecked=vm.forms.update_voicemail_setting.password_enable
        vm.api.load.send = false;
      });
    },
    updateVoicemailSetting: _.debounce(function () {
      let vm = this
      vm.forms.update_voicemail_setting.submitted=true
      vm.$v.forms.update_voicemail_setting.$touch()
      if (vm.$v.forms.update_voicemail_setting.$invalid || vm.api.update_voicemail_setting.send) return;
      vm.api.update_voicemail_setting.send=true
      vm.api.update_voicemail_setting.validation_errors={}
      const toaster = vm.appNotify({
        message: "Saving...",
        type: "info",
        duration: 60 * 1000,
      });
      VOIP_API.endpoints.voicemail.updatesetting.default(vm.voipaccount, {
        mailboxpin: vm.forms.update_voicemail_setting.pin,
        mailbox: vm.forms.update_voicemail_setting.password_enable ? "Y" : "N",
        voicemail: vm.forms.update_voicemail_setting.email_enable ? "Y" : "N",
        mailboxemail: vm.userType=='user' ? vm.forms.update_voicemail_setting.email : vm.user?.user?.email ?? '',
        voicemail_transcript: vm.forms.update_voicemail_setting.voicemail_transcript ? "Yes" : "No",
        voicemail_attachment: vm.forms.update_voicemail_setting.voicemail_attachment ? "Yes" : "No",
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully updated",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.api.update_voicemail_setting.validation_errors=ex.own_errors
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        vm.load()
      })
      .finally(() => {
        toaster.close();
        vm.api.update_voicemail_setting.send=false
        vm.forms.update_voicemail_setting.submitted=false
      });
    }, 2 * 1000),
    changeGreetingType() {
      let vm = this
      if (vm.api.change_greeting_type.send) return;
      vm.api.change_greeting_type.send = true;
      VOIP_API.endpoints.voicemail.chageGreetingType({
        account: this.user?.voipaccount,
        accountcode: this.user?.voipaccount,
        type: vm.selected.greeting_type ? 'advanced' : 'basic',
      })
      .then(() => {
        vm.selected.greeting_type=!vm.selected.greeting_type
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if(vm.$refs.greeting_switch) vm.$refs.greeting_switch.localChecked=!vm.selected.greeting_type
        vm.api.change_greeting_type.send = false;
      });
    },
    afterAdding() {
      let vm = this;
      $fn.sleep(2*1000)
      .then(()=>{
        vm.getVoicemails();
      })
    },
    getVoicemails() {
      let vm = this;
      vm.api.list.send = true;
      VOIP_API.endpoints.voicemail.voicemailgreeting({
        accountcode: vm.user?.voipaccount,
        type: 'busy',
      })
      .then(({ data: res }) => {
        vm.response.voicemail = res.prompts;
        vm.$nextTick(()=>{
          if(vm.getUserPermissions.advanced_greetings_in_voice_mail){
            vm.selected.greeting_type = !vm.temp.voice;
          }
        })
      })
      .finally(() => {
        vm.api.list.send = false;
      });
    },
    deleteRecordVoiceMail(id) {
      let vm = this
      if (vm.api.delete_record_voice_mail.send.includes(id)) return;
      vm.api.delete_record_voice_mail.send.push(id);
      VOIP_API.endpoints.voicemail.deleteRecordVoiceMail(id, {
        account: vm.user?.voipaccount,
      })
      .then(() => {
        vm.appNotify({
          message: 'Successfully Deleted',
          type: 'success',
        })
        vm.afterAdding();
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if (vm.api.delete_record_voice_mail.send.includes(id)) vm.api.delete_record_voice_mail.send.splice(vm.api.delete_record_voice_mail.send.indexOf(id),1);
      });
    },
    uploadAudio(event){
      let vm = this
      $fn.blobToBase64(event?.blob || event?.file)
      .then((base64)=>{
        return VOIP_API.endpoints.voicemail.voicemailrecord({
          account: vm.user?.voipaccount,
          file: base64,
          type: event?.extra_data?.sound_type,
        })
      })
      .then(() => {
        event?.load?.()
        vm.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        vm.afterAdding()
      })
      .catch((ex) => {
        const message = ex.own_message ?? VOIP_API.errorMessages.default
        event.error(message)
        vm.appNotify({
          message: message,
          type: 'danger',
        })
      })
      .finally(()=>{
        event.hide()
      })
    },
    greetingOptionSelect(option,type){
      let vm = this
      this.checkPermission(this.getUserPermissions.advanced_greetings_in_voice_mail,()=>{
        if(option=='record') vm.$modal.show('AudioRecording', { extra_data: { sound_type: type, }})
        if(option=='upload') vm.$modal.show('UploadAudioModal', { extra_data: { sound_type: type, }})
        if(option=='shift') vm.$modal.show('ShiftSoundFile', { type: type, accountcode: vm.getCurrentUser.account })
        if(option=='delete') vm.deleteRecordVoiceMail(type)
      })
    },
  },
  mounted() {
    this.load();
    this.getVoicemails();
  },
  activated() {
    this.load();
    this.getVoicemails();
  },
};
</script>

<style lang="scss">
.enterPinSection {
  flex-direction: column;
  .react-code-input {
    display: flex;
    input {
      margin-right: 8px;
      text-align: center;
    }
  }
}
</style>
