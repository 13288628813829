<template>
  <div class="crm-main-section DealsSection">
    <div class="d-flex justify-content-between align-items-center w-100 mb-5">
      <h2 class="crm-main-section-heading text-case-unset mb-0">
        Security
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </h2>
      <!-- <button class="crm-button">create Activities</button> -->
    </div>
    <div class="crm-card p-32">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex flex-column wd-70">
          <div class="crm-card-heading my-0">Disable password forgot feature</div>
          <p class="crm-card-text w-100 mb-0" >
            When enabled, the password forgot feature will be disabled.
          </p>
        </div>
        <b-form-checkbox
          ref="something"
          class="newerSwitch"
          name="check-button"
          switch
        />
      </div>
    </div>
    <div class="crm-card p-32">
      <div class="d-flex justify-content-between align-items-center w-100">
        <div class="d-flex flex-column wd-70">
          <div class="crm-card-heading my-0">Block bad visitors</div>
          <p class="crm-card-text w-100 mb-0" >
            If enabled, a list of bad user agents, ip addresses and referrers will be checked for each guest visitor.
          </p>
        </div>
        <b-form-checkbox
          ref="something"
          class="newerSwitch"
          name="check-button"
          switch
        />
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'GeneralSecurity',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      },
      allowedUploadfileExtentions: 'jpg, jpeg, png, gif, svg, pdf, aac, ogg, oga, mp3, wav, mp4, m4v, mov, ogv, webm, zip, rar, doc, docx, txt, text, xml, json, xls, xlsx, odt, csv, ppt, pptx, ppsx, ics, eml'
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>