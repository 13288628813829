<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"   
      :name="modalName" 
      @before-close="onBeforeClose()" 
      @before-open="onBeforeOpen($event)"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add Number</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div 
            v-for="(numberType, key) in numberTypes" 
            :key="key" 
            class="basicWhiteIOScard-item d-flex justify-content-between" 
            @click="$modal.show(numberType.modal_name)"
          >
            <div class="headingInsideCard">{{ numberType.text }}</div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
        </div>
        <AddPortNumberModal @added="$emit('port-number-added',$event);$emit('added')" />
        <AddCallerIdModal @added="$emit('caller-id-added',$event);$emit('added')" />
        <AddManualNumberModal @added="$emit('manual-number-added',$event);$emit('added')" />
        <PurchaseNumberModal />
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';

import AddPortNumberModal from './AddPortNumber.vue'
import AddCallerIdModal from './AddCallerId.vue'
import AddManualNumberModal from './AddManualNumber.vue'
import PurchaseNumberModal from "./PurchaseNumber.vue";

let number_types = {
  purchase_number: {
    key: 'purchase_number',
    text: 'Purchase Number',
    modal_name: 'PurchaseNumber',
    is_standalone: false,
  },
  add_caller_id: {
    key: 'add_caller_id',
    text: 'Add Caller Id',
    modal_name: 'AddCallerId',
    is_standalone: false,
  },
  number_porting: {
    key: 'number_porting',
    text: 'Number Porting',
    modal_name: 'AddPortNumber',
    is_standalone: false,
  },
  add_number_manual: {
    key: 'add_number_manual',
    text: 'Add Number(Manual)',
    modal_name: 'AddManualNumber',
    is_standalone: true,
  },
}

export default {
  name: "AddNumberModal",
  components: {
    AddPortNumberModal,
    AddCallerIdModal,
    AddManualNumberModal,
    PurchaseNumberModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'AddNumber'
    },
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    isStandAlone(){ return this.getCurrentUser.appMode === 'standalone' },
    numberTypes(){ return Object.values(number_types).filter((num_type)=>num_type.is_standalone ? this.isStandAlone : true) },
  },
  methods: {
    onBeforeOpen() {
      // TODO
    },
    onBeforeClose() {
      // TODO
    },
  },
};
</script>
