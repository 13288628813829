<template>
  <div class=" dialer-userInfoMenu page text-center w-100 position-relative">
    
    <div class="user-info-container">
      <div class="dialer_contact">
        <div class="user-profile">
          <vb-avatar :image="$store.getters.getCurrentUser.profileImg" :status_id="$store.getters.getCurrentUser.status_id" :name="$store.getters.getCurrentUser.display_name" />
        </div>
        <div class="user-info">
          
          <span class="user-name">{{ $store.getters.getCurrentUser.display_name }}</span>
          
          <span class="user-number">{{ $store.getters.getCurrentUser.extn }}</span>
          <template v-if="!$store.getters.isIntegration">
            <div class="position-relative dialer-organization page opacity-1 dd-opacity-1 mt-4">
              
              <transition name="slide-in">
                <b-dropdown class="organization-list" no-caret>
                  <template v-slot:button-content>
                    
                    <vb-status :statusId="$store.getters.getCurrentUser.status_id" class="position-relative" />
                    
                    <span class="ml-1">{{ $store.getters.getCurrentUser.status }}</span>
                    <b-icon class="arrow_icon" icon="chevron-down" scale="0.7" />
                  </template>
                  <b-dropdown-item v-for="status in $store.state.common.statuses" :key="status.id" @click="$store.dispatch('setStatus',status.id)">
                    <a>{{ status.status }}</a>
                    
                    <b-icon v-if="status.id === $store.getters.getCurrentUser.status_id" icon="check-circle-fill" scale="0.8" :style="`color:${status.colour};`" />
                  </b-dropdown-item>
                </b-dropdown>
              </transition>
            </div>
          </template>
          <div v-if="!$store.getters.isIntegration && getProperty($store.getters.getCurrentUser,'organizations.length',0)>1" class="position-relative dialer-organization page">
            <transition name="slide-in">
              <b-dropdown class="organization-list" no-caret>
                <template v-slot:button-content>
                  <b-icon icon="building" class="mr-2" scale="0.8" />
                  <span class="text-capitalize">Select Organization</span>
                  <b-icon class="arrow_icon" icon="chevron-down" scale="0.7" />
                </template>
                <b-dropdown-item v-for="(organization, index) in $store.getters.getCurrentUser.organizations" :key="index" @click="organization.id === $store.getters.getCurrentUser.selected_organization_id ? '' : switchOrganization(organization)">
                  <a>{{ organization.label }}</a>
                  
                  <b-icon v-if="$store.getters.getCurrentUser.selected_organization_id === organization.id" icon="check-circle-fill" scale="0.8" class="status-1" />
                </b-dropdown-item>
              </b-dropdown>
            </transition>
          </div>
          <div v-if="!$store.getters.isIntegration" class="position-relative dialer-organization page opacity-1">
            <transition name="slide-in">
              <b-dropdown class="organization-list" no-caret>
                <template v-slot:button-content>
                  <vb-icon icon="dialer-snoozeIcon" width="20" height="20" class="mr-2" scale="0.8" />
                  <span class="text-capitalize">Away</span>
                  <b-icon class="arrow_icon" icon="chevron-down" scale="0.7" />
                </template>
                <b-dropdown-item @click="snooze('off')">
                  <a>Turn it Off</a>
                  <b-icon v-if="snooze_label=='off'" icon="check-circle-fill" scale="0.8" class="status-1" />
                </b-dropdown-item>
                <b-dropdown-item @click="snooze('unlimited')">
                  <a>For unlimited</a>
                  <b-icon v-if="snooze_label=='unlimited'" icon="check-circle-fill" scale="0.8" class="status-1" />
                </b-dropdown-item>
                <b-dropdown-item @click="snooze('15 min')">
                  <a>For 15 mins</a>
                  <b-icon v-if="snooze_label=='15 min'" icon="check-circle-fill" scale="0.8" class="status-1" />
                </b-dropdown-item>
                <b-dropdown-item @click="snooze('30 min')">
                  <a>For 30 mins</a>
                  <b-icon v-if="snooze_label=='30 min'" icon="check-circle-fill" scale="0.8" class="status-1" />
                </b-dropdown-item>
                <b-dropdown-item @click="snooze('45 min')">
                  <a>For 45 mins</a>
                  <b-icon v-if="snooze_label=='45 min'" icon="check-circle-fill" scale="0.8" class="status-1" />
                </b-dropdown-item>
                <b-dropdown-item @click="$modal.show('CustomeSnoozeModal')">
                  <a>Set Custom Time</a>
                  <b-icon v-if="snooze_label=='custom'" icon="check-circle-fill" scale="0.8" class="status-1" />
                </b-dropdown-item>
              </b-dropdown>
            </transition>
          </div>
          
          <div v-if="!$store.getters.isIntegration" class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row cursor_pointer_no_hover" @click="$store.state.global_conditions.tab='mobile_settings'">
                <div class="latestGreyBox-heading-main my-0">
                  Settings
                </div>
                <b-icon icon="chevron-down" rotate="-90" />
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox-row cursor_pointer_no_hover" v-if="isElectron">
                <div class="latestGreyBox-heading-main my-0">
                  Minimize
                </div>
                <div class="mobileViewCheckBox bg-transparent p-0 w-auto" >
                  <b-checkbox class="minimizedSwitch" v-if="isElectron" switch v-model="$store.state.electron.app_view" value="mob" unchecked-value="web" />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="position-absolute fixed-bottom bg-white p-2 w-100" style="z-index:1;">
        <button class="dialer-button dialer-button-primary" @click="logout({is_router_change: false, is_user: true})">
          <b-icon v-if="$store.state.logout_running" icon="arrow-clockwise" animation="spin-pulse" variant="danger" />
          <template v-else>
            <b-icon icon="box-arrow-right" class="mr-2" scale="0.8" />
            <span class="text-capitalize">Logout</span>
          </template>
        </button>
      </div>
    </div>
  
    
  </div>
</template>

<script>
import { mapState } from 'vuex';
import { events } from '../utils';
export default {
  name: 'UserProfilePage',
  inject:['logout', 'getProperty','snooze'],
  computed: {
    ...mapState({
      snooze_label: (state)=>state.sip.snooze
    }),
    isElectron(){ return process.env.IS_ELECTRON ? true : false },
  },
  watch: {
  },
  methods: {
    //--events--
    async switchOrganization(organization) {
      this.$root.$emit(events.switch_organization, {
        organization,
      });
    },
  },
}
</script>


<style>

</style>