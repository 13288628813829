var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', _vm._l(_vm.messages, function (message, index) {
    return _c('div', {
      key: index
    }, [_vm._v(" " + _vm._s(message.text) + " ")]);
  }), 0);

}
var staticRenderFns = []

export { render, staticRenderFns }