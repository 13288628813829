<template>
  <div>
    <div class="basicWhiteIOScard-item noBottomBorder" @click="$modal.show(`${randomID}-CallForwardingSettingModal`,{ account: voipaccount, setting: response.forwarding_setting })">
      <template v-if="response.forwarding_setting.forward_note == 'voicemail'">
        <div class="headingInsideCard">Ask to leave a voicemail</div>
        <div class="d-flex align-items-center">
          <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.forwarding_setting.timeout }} Seconds </div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
        </div>
      </template>
      <template v-else-if="response.forwarding_setting.forward_note == 'extension'">
        <div class="headingInsideCard">Redirect to a user, team or IVR menu</div>
        <div class="d-flex align-items-center">
          <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.forwarding_setting.extension_name }} </div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
        </div>
      </template>
      <template v-else-if="response.forwarding_setting.forward_note == 'number'">
        <div class="headingInsideCard">Redirect to another number</div>
        <div class="d-flex align-items-center">
          <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.forwarding_setting.forward_number }}</div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
        </div>
      </template>
      <template v-else>
        <div class="headingInsideCard">Forwarding</div>
        <div class="d-flex align-items-center">
          <div class="textInsideCard textAlignRight onRightSide mr-6px">Keep ringing </div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
        </div>
      </template>
    </div>
    <CallForwardingModal :modalName="`${randomID}-CallForwardingSettingModal`" :randomID="randomID" @updated="updateForwardingSettings($event)" />
  </div>
</template>

<script>
import { $fn, VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import CallForwardingModal from '../modals/callforwarding/CallForwarding.vue'
// import { Fragment } from 'vue-fragment'
export default {
  name: 'CallForwardingWidget',
  components: {
    // Fragment,
    CallForwardingModal,
  },
  props: {
    accountcode: {
      type: [Number,String],
      default: '',
    },
  },
  data(){
    return {
      random_id: '',
      api: {
        route_call: this.$helperFunction.apiInstance(),
        update_route_call: this.$helperFunction.apiInstance({ source: true }),
      },
      response: {
        forwarding_setting: {
          forward_setting: "",
          forward_note: "",
          forward_number: "",
          timeout: 5,
          extension_name: "",
          number: "",
        },
        allow_recording: false,
        outofhours: false,
        recording: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    voipaccount(){ return this.accountcode || this.getCurrentUser.account },
    randomID(){ return this.random_id },
  },
  methods: {
    fetchcallRouteSetting() {
      let vm = this
      if(vm.api.route_call.send) return;
      vm.api.route_call.send = true;
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.voipaccount,
      })
      .then(({ data }) => {
        vm.response.outofhours = data.schedule_enable;
        vm.response.forwarding_setting = data.forwarding_setting;
        vm.response.recording = data.recording;
        vm.response.allow_recording = data.allow_recording=='1';
      })
      .finally(() => {
        vm.api.route_call.send = false;
      });
    },
    updateForwardingSettings(forward_setting={}){
      let vm = this;
      vm.api.update_route_call.source?.cancel?.("Requesting Again")
      vm.api.update_route_call.source = require("axios").default.CancelToken.source();
      vm.api.update_route_call.send = true;
      const toaster_value = vm.appNotify({
        message: "Saving...",
        type: "success",
        duration: 6 * 1000,
      });
      VOIP_API.endpoints.users.updateroutecall({
        accountcode: vm.voipaccount,
        forward_setting: {
          forward_setting: forward_setting.forward_setting ?? vm.response.forwarding_setting.forward_setting,
          forward_note: forward_setting.forward_note ?? vm.response.forwarding_setting.forward_note,
          forward_number: forward_setting.forward_number ?? vm.response.forwarding_setting.forward_number,
          timeout: forward_setting.timeout ?? vm.response.forwarding_setting.timeout,
          extension_name: forward_setting.extension_name ?? vm.response.forwarding_setting.extension_name,
          number: forward_setting.number ?? vm.response.forwarding_setting.number,
        },
        call_recording: vm.response.recording,
        schedule_enable: vm.response.outofhours,
      },vm.api.update_route_call.source.token)
      .then(() => {
        this.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        vm.fetchcallRouteSetting()
        vm.$emit('cf-updated')
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        toaster_value.close();
        vm.api.update_route_call.send = false;
        vm.api.update_route_call.source = null;
      });
    },
  },
  created(){
    this.random_id = $fn.makeid(10)
  },
  mounted(){
    this.fetchcallRouteSetting()
  },
  activated(){
    this.fetchcallRouteSetting()
  },
}
</script>

<style>

</style>