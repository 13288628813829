<template>
  <div class="dialer-settings-main">
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Call recording</div>
      <div class="plus-IOSIcon-container">
        
      </div>
    </div>
    <section class="dialer-settings-section dialer-callRecording-settings">
      <template>
        
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-input :disabled="!getUserPermissions.call_recording" type="text" placeholder="Search numbers" v-model.lazy="filter.call_recordings.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
      </template>
      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div class="basicWhiteIOScard-item" v-for="user in filterCallRecording" 
          :key="user.account" 
          @click="checkPermission(getUserPermissions.call_recording,()=>$modal.show('CallRecordingEditModal',{ 
            account: user.account, 
            allow_recording: user.allow_recording, 
            setting: user.auto == 1 ? 'auto' : user.ondemand == 1 ? 'ondemand' : 'off' 
          }))"
        >
          <div class="d-flex">
            <Info class="InfoComponentInsideAnroidIOSApp"  :name="user.user"  :is_blf="false" />
          </div>
          <div class="d-flex align-items-center">
            <div class="textInsideCard onRightSide mr-6px">
              <vb-icon :icon="user | filterIcon" height="20" :width="user | filterIconWidth" class="noRect"/>
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
      </div>
      <InfiniteLoading ref="call_recordings_infinity_loader" @infinite="fetchCallRecordings()">
        <div slot="spinner">
          <vb-loading type="loader" />
        </div>
        <div slot="no-more"></div>
        <div slot="no-results">
          <vb-no-record :text="'There is no Numbers'" :design="3" />
        </div>
        <template #error="{ trigger }">
          <div>
            <span>{{ api.call_recordings.error_message }}</span>
            <b-icon icon="arrow-repeat" class="ml-3" variant="primary" font-scale="1.5" @click="trigger()" />
          </div>
        </template>
      </InfiniteLoading>
    </section>
    <StorageModal />
    <CallRecordingEditModal @latest="fetchCallRecordings(true)" />

  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from "../../../utils";
import Info from "../../Lists/Info.vue";
import StorageModal from "../modals/callrecording/StorageCallRecording.vue";
import CallRecordingEditModal from "../modals/callrecording/EditCallRecording.vue";
import InfiniteLoading from 'vue-infinite-loading';
import { permission_handling } from '@/mixin';

export default {
  name: "CallRecording",
  mixins: [permission_handling],
  components: {
    StorageModal,
    CallRecordingEditModal,
    Info,
    InfiniteLoading,
  },
  inject:['isEmpty'],
  data() {
    return {
      response: {
        call_recordings: {},
      },
      api: {
        call_recordings: this.$helperFunction.apiInstance({ error_message: true, }),
      },
      filter: {
        call_recordings: {
          search: "",
        },
      },
    };
  },
  filters: {
    filterIcon(user){
      if(user?.allow_recording != '1' || (user?.auto != 1 && user?.ondemand != 1)) return 'CallRecordingOff-inSettings-icon'
      else if(user?.auto == 1) return 'CallRecordingAuto-inSettings-icon'
      else if(user?.ondemand == 1) return 'CallRecordingOnDemand-inSettings-icon'
      else if(user?.allow_recording == '1') return 'CallRecordingOn-inSettings-icon'
      else return 'CallRecordingOff-inSettings-icon'
    },
    filterIconWidth(user){
      if(user?.allow_recording != '1' || (user?.auto != 1 && user?.ondemand != 1)) return 98
      else if(user?.auto == 1) return 93
      else if(user?.ondemand == 1) return 93
      else if(user?.allow_recording == '1') return 93
      else return 98
    },
  },
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
    }),
    filterCallRecording() {
      const search = this.filter.call_recordings.search.trim().toLowerCase();
      const call_recordings = this.response.call_recordings
      const filter_call_recordings = Object.values(call_recordings).filter((val) =>`${val.extn} ${val.user}`.trim().toLowerCase().includes(search));
      return filter_call_recordings
    },
  },
  methods: {
    fetchCallRecordings(reset) {
      let vm = this
      if(vm.api.call_recordings.send) return;
      if(reset==true){
        vm.response.call_recordings = {}
        vm.$refs.call_recordings_infinity_loader?.stateChanger?.reset?.()
      }
      vm.api.call_recordings.send=true
      vm.api.call_recordings.error_message=''
      VOIP_API.endpoints.users.usercallrecordingsetting()
      .then(({ data: call_recordings }) => {
        vm.response.call_recordings = call_recordings
        if(Object.values(vm.response.call_recordings).length>=1) vm.$refs.call_recordings_infinity_loader?.stateChanger?.loaded?.()
        vm.$refs.call_recordings_infinity_loader?.stateChanger?.complete?.()
      })
      .catch((ex)=>{
        vm.$refs.call_recordings_infinity_loader?.stateChanger?.error?.()
        vm.api.call_recordings.error_message=ex.own_message || ex.message
      })
      .finally(() => {
        vm.api.call_recordings.send=false
      });
    },
  },
  activated() {
    setTimeout(()=>this.fetchCallRecordings(true),0.5*1000)
  },
};
</script>

<style lang="scss">
.dialer-tabs-switch-version {
  display: flex;
  justify-content: center;
  max-width: 205px;
  margin-top: 20px;

  .dialer-tab-headers {
    display: inline-flex;
    justify-content: center;
    border: none;

    .dialer-tab {
      cursor: pointer;
      font-size: 15px;
      color: $body-color;
      padding: 8px 10px;
      width: 85px;
      text-align: center;

      &.selected {
        color: $white;
        background-color: $primary;
        box-shadow: none;
        @include border-radius(7px);
      }
    }
  }
}
.can-toggle {
  position: relative;
  display: inline-block;
  vertical-align: top;
  input {
    &[type="checkbox"] {
      opacity: 0;
      position: absolute;
      top: 0;
      left: 0;
      &:checked {
        & ~ label {
          .can-toggle__switch {
            background-color: $primary;
            &:before {
              content: attr(data-unchecked);
              left: 0;
              color: $white;
            }
            &:after {
              background-color: $white;
              content: attr(data-checked);
              transform: translate3d(65px, 0, 0);
              color: $primary;
              left: 23px;
              z-index: auto;
            }
          }
        }
      }
    }
    & + label {
      padding-left: 26px;
      height: 25px;
      line-height: 21px;
      font-weight: normal;
      position: relative;
      cursor: pointer;
      display: inline-block;
      font-size: 1rem;
    }
  }
  label {
    .can-toggle__switch {
      position: relative;
      transition: background-color 0.3s cubic-bezier(0, 1, 0.5, 1);
      background: $gray-400;
      height: 42px;
      width: 160px;
      flex: 0 0 134px;
      @include border-radius(12px);
      &:before {
        content: attr(data-checked);
        position: absolute;
        top: 2;
        left: 83px;
        text-transform: uppercase;
        text-align: center;
        color: $body-color;
        font-size: 12px;
        line-height: 40px;
        width: 80px;
        padding: 0 12px;
        @include border-radius(12px);
      }
      &:after {
        background: $primary;
        color: $white;
        content: attr(data-unchecked);
        position: absolute;
        left: 2px;
        top: 2px;
        @include border-radius(12px);
        width: 70px;
        height: 38px;
        display: flex;
        justify-content: center;
        align-items: center;
        font-size: 12px;
        z-index: auto;
        text-transform: uppercase;
        text-align: center;
        transition: -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
        transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1);
        transition: transform 0.3s cubic-bezier(0, 1, 0.5, 1),
          -webkit-transform 0.3s cubic-bezier(0, 1, 0.5, 1);
      }
    }
  }
}
</style>