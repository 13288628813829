<template>
  <div>
    <div class="ticketConversation-container">
      <template v-if="api.fetch_comments.send">
        <div v-for="n in 6" :key="n.id" :class="`latestShimmerDesign w-25 ${n%2==1 ? 'ml-auto' : ''}`" style="height:52px;"></div>
      </template>
      <template v-else>
        <template v-if="comments.length == 0">
          <div class="h-100 d-flex d-flex justify-content-center align-items-center my-5">
            <div class="w-75 text-center mt-20px">
              <div class="latestGreyBox-heading-main">No message to see here</div>
              <div class="latestGreyBox-heading-description mt-10px">You can send one by writing a message below</div>
            </div>
          </div>
        </template>
        <template v-else>
          <div v-for="(message, index) in comments" :key="message.id">
            <TicketMessage :message="message" :prevMessage="comments[index-1]" :nextMessage="comments[index+1]" :userId="userId" />
          </div>
        </template>
        <TicketAction :status="status" :ticketId="ticketId" :feedback="feedback" @comment-created="fetchComments()" @status-updated="$emit('status-updated')" />
      </template>
    </div>
  </div>
</template>

<script>
// import { VOIP_API } from '@/utils';
import _ from 'lodash'
import TicketMessage from './Message.vue';
import TicketAction from './Action.vue';
import { VOIP_API } from '@/utils';
export default {
  name: 'TicketComments',
  components: {
    TicketMessage,
    TicketAction,
  },
  props: {
    ticket: {
      type: Object,
      default: ()=>({}),
    }
  },
  data() {
    return {
      api: {
        fetch_comments: this.$helperFunction.apiInstance({
          source: true,
        })
      },
      response: {
        comments: [],
      },
      conditions: {
        scroll_bottom: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    comments(){ return _.orderBy(this.response.comments, ['created_at'], ['asc']);  },
    ticketId(){ return this.ticket?.ticket?.id ?? '' },
    userId(){ return this.ticket?.ticket?.user_id ?? '' },
    status(){ return this.ticket?.ticket?.status ?? {} },
    feedback(){ return this.ticket?.ticket?.feedback ?? {} },
  },
  methods: {
    scrollEnd() {
      let vm = this;
      if(this.$el){
        const el = this.$refs[`${this._uid}-comments-container`];
        setTimeout(() => {
          if(el) el.scrollTop = el.scrollHeight;
          vm.conditions.scroll_bottom = false
        }, 0 * 1000);
      }
    },
    onScroll(Event) {
      const height = Event.target.scrollHeight;
      const top = Event.target.scrollTop;
      const offset_height = Event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      this.conditions.scroll_bottom = scroll_bottom >= 200
    },
    async fetchComments(){
      this.api.fetch_comments.source?.cancel?.("Requesting Again");
      this.api.fetch_comments.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_comments.send=true
        const { data } = await VOIP_API.endpoints.tickets.getById(this.ticketId)
        this.response.comments=data?.comments ?? []
        await this.$nextTick()
        this.scrollEnd()
      } catch (ex) {
        if(!ex.cancel) {
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_comments.send=false
      }
    },
  },
  mounted(){
    this.fetchComments()
  },
}
</script>

<style>

</style>