<template>
  <!--<div v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :class="`dialer-main-loader ${GET_LOCAL_SETTING_IS_DARK_MODE ? 'darkModeV2 v2': ''}`">
    <div v-if="GET_LOCAL_SETTING_IS_DARK_MODE" ref="test" class="lottieLoader">
        <!~~ style="width:450px;height:450px;" ~~>
    </div>
  </div>-->
  <div :class="`dialer-main-loader ${GET_LOCAL_SETTING_IS_DARK_MODE && !$store.getters.getIsMobile ? 'darkModeV2 v2': ''}`">
    <!-- withAnimation -->
    <!-- <img :src="`${GET_LOCAL_SETTING_IS_DARK_MODE ? loaderV_5_darkMode_5: loaderV_5}`" alt /> -->
    <img :src="`${GET_LOCAL_SETTING_IS_DARK_MODE && !$store.getters.getIsMobile ?loaderV_5_darkMode_5 :loaderV_5}`" alt />
  </div>
  <!-- <div class="customLottieLoader">
    <vb-icon class="logoIcon" icon="crm-voipbusiness-lightLogo-icon" height="55px" width="55px"/>
    <div class="customLottieLoader-circle-1"></div>
    <div class="customLottieLoader-circle-2"></div>
    <div class="customLottieLoader-circle-3"></div>
    <div class="customLottieLoader-circle-4"></div>
  </div> -->
  <!-- <div class="Loader-like-withHold">
    <div class="logoIcon-container">
      <vb-icon class="logoIcon" icon="crm-voipbusiness-lightLogo-icon-forLoader" height="55px" width="55px"/>
    </div>
    <div class="Loader-like-withHold-circle-1"></div>
  </div> -->
</template>

<script>
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import { mapGetters } from 'vuex';
// import lottie from 'lottie-web'
export default {
  data() {
    return {
      loader:require("@/assets/images/loader.gif"),
      loaderV_2:require("@/assets/images/loaderV3.gif"),
      loaderV_5:require("@/assets/images/loaderV5.gif"),
      // loaderV_2:require("@/assets/images/loaderV4.gif"),
      loaderV_2_darkMode:require("@/assets/images/loaderV3_darkMode.gif"),
      loaderV_2_darkMode_1:require("@/assets/images/loaderV3_darkMode_1.gif"),
      loaderV_2_darkMode_2:require("@/assets/images/loaderV3_darkMode_2.gif"),
      // loaderV_5_darkMode_5:require("@/assets/images/loaderV5_darkMode.gif"),
      loaderV_5_darkMode_5:require("@/assets/images/loaderV6_darkMode.gif"),
    };
  },
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
  },
  mounted(){
    // try {
    //   console.log(typeof this.$refs['test'])
    //   const a = lottie.loadAnimation({
    //     container: this.$refs['test'], // the dom element that will contain the animation
    //     renderer: 'svg',
    //     loop: true,
    //     autoplay: true,
    //     // path: './assets/images/Comp 1 VB loader.json', // the path to the animation json
    //     path: './assets/images/Comp1VBloaderwhite.json', // the path to the animation json
    //   });
    //   console.log(a)
    //   // a.play()
    // } catch(ex) {
    //   console.log({ex})
    // }
  },
};
</script>

<style lang="scss" scoped>
.dialer-main-loader {
  position: fixed;
  top: 0;
  left: 0;
  height: 100%;
  // background: #fbfbfb;
  background: white;
  width: 100%;
  z-index: 1;
  display: flex;
  align-items: center;
  justify-content: center;

  .loader-animator {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
  }

  &.withAnimation{
    img{
      animation-name: for-dialerMainLoader;
      animation-duration: 3s;
      // animation-delay: 1s;
      animation-iteration-count: infinite;
      opacity: 1;
    }
    @keyframes for-dialerMainLoader {
      0%{
        opacity: 0;
      }
      50%{
        opacity: 1;
      }
      100%{
        opacity: 0;
      }
    }
  }
}

.lds-ripple {
  display: inline-block;
  position: relative;
  width: 80px;
  height: 80px;
}

.lds-ripple div {
  position: absolute;
  border: 4px solid #fff;
  opacity: 1;
  border-radius: 50%;
  animation: lds-ripple 1s cubic-bezier(0, 0.2, 0.8, 1) infinite;
}

.lds-ripple div:nth-child(2) {
  animation-delay: -0.5s;
}

@keyframes lds-ripple {
  0% {
    top: 36px;
    left: 36px;
    width: 0;
    height: 0;
    opacity: 1;
  }

  100% {
    top: 0px;
    left: 0px;
    width: 72px;
    height: 72px;
    opacity: 0;
  }
}



.lottieLoader > svg{
  height: 100vh !important;
  width: 100vw !important;
}



.customLottieLoader{
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: white;
  .logoIcon{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 119px;
    height: 112px;
    animation: forScaleLogo 5s ease-in-out;
    animation-iteration-count: infinite;
    animation-delay: 0.5s
  }
  & > div{
    @include border-radius(50%);
    // @include box-shadow-mixin(inset -3px -3px 14px rgba(191, 200, 220, 1));
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    animation: forScale 5s ease-in-out;
    animation-iteration-count: infinite;
    border: 40px solid linear-gradient(145deg, #ffffff, #BFC8DC) !important;
    &::before{
      content: '';
      @include border-radius(50%);
      @include box-shadow-mixin( -3px -3px 14px rgba(191, 200, 220, 1));
      height: 100%;
      width: 100%;
      position: absolute;
      left: 0px;
      top: 0px;
    }
    &::after{
      content: '';
      @include border-radius(50%);
      @include box-shadow-mixin(inset -3px -3px 14px rgba(191, 200, 220, 1));
      height: calc(100% + 40px);
      width: calc(100% + 40px);
      position: absolute;
      left: -20px;
      top: -20px;
    }
  }
  .customLottieLoader-circle-1{
    width: 292px;
    height: 292px;
    border: 20px solid white;
  }
  .customLottieLoader-circle-2{
    width: 685px;
    height: 685px;
    border: 40px solid white;
    animation-delay: 0.4s;
  }
  .customLottieLoader-circle-3{
    width: 1240px;
    height: 1240px;
    border: 60px solid white;
    animation-delay: 0.8s;
  }
  .customLottieLoader-circle-4{
    width: 1817px;
    height: 1817px;
    border: 80px solid white;
    animation-delay: 1.2s;
  }
  @keyframes forScale {
    0% {
      transform: translate(-50% , -50%) scale(1,1) ;
    }

    50% {
      transform: translate(-50% , -50%) scale(1.5,1.5) ;
    }

    100% {
      transform: translate(-50% , -50%) scale(1,1) ;
    }
  }
  @keyframes forScaleLogo {
    0% {
      transform: translate(-50% , -50%) scale(1,1) ;
    }

    50% {
      transform: translate(-50% , -50%) scale(1.5,1.5) ;
    }

    100% {
      transform: translate(-50% , -50%) scale(1,1) ;
    }
  }
}



.Loader-like-withHold{
  width: 100vw;
  height: 100vh;
  max-width: 100vw;
  max-height: 100vh;
  min-width: 100vw;
  min-height: 100vh;
  position: relative;
  overflow: hidden;
  background-color: white;
  display: flex;
  justify-content: center;
  align-items: center;
  .logoIcon-container{
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
    width: 189px;
    height: 182px;
    background-color: #F3F3F3;
    z-index: 1;
    animation: forScaleLogowithHold 1.3s ease-in-out;
    animation-iteration-count: infinite;
    // @include box-shadow-mixin( 0px 0px 3px rgba(191, 200, 220, 1));
    @include border-radius(50%);
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .logoIcon{
    // position: absolute;
    // top: 50%;
    // left: 50%;
    // transform: translate(-50% , -50%);
    // width: 189px;
    // height: 182px;
    // z-index: 1;
    // animation: forScaleLogowithHold 1.3s ease-in-out;
    // animation-iteration-count: infinite;
    // @include box-shadow-mixin( 0px 0px 3px rgba(191, 200, 220, 1));
    // @include border-radius(50%);


    height: 80%;
    width: 80%;
  }
  .Loader-like-withHold-circle-1{
    width: 120px;
    height: 120px;
    z-index: 0;
    @include border-radius(50%);
    border: 2px solid black;
    animation: forScaleBorder 1.3s ease-in-out;
    animation-iteration-count: infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
  }
  .Loader-like-withHold-circle-2{
    width: 140px;
    height: 140px;
    z-index: 0;
    @include border-radius(50%);
    border: 2px solid black;
    animation: forScaleBorder2 1.3s ease-in-out;
    // animation-delay: 0.1s;
    animation-iteration-count: infinite;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50% , -50%);
  }
  @keyframes forScaleBorder {
    0% {
      transform: translate(-50% , -50%) scale(2.2,2.2) ;
    }

    40% {
      transform: translate(-50% , -50%) scale(1,1) ;
    }
    60% {
      transform: translate(-50% , -50%) scale(1,1) ;
    }

    100% {
      transform: translate(-50% , -50%) scale(2.2,2.2) ;
    }
  }
  @keyframes forScaleBorder2 {
    0% {
      transform: translate(-50% , -50%) scale(1.7,1.7) ;
    }

    40% {
      transform: translate(-50% , -50%) scale(1,1) ;
    }
    60% {
      transform: translate(-50% , -50%) scale(1,1) ;
    }

    100% {
      transform: translate(-50% , -50%) scale(1.7,1.7) ;
    }
  }
  @keyframes forScaleLogowithHold {
    0% {
      transform: translate(-50% , -50%) scale(1,1) ;
    }

    40% {
      transform: translate(-50% , -50%) scale(1.3,1.3) ;
    }
    60% {
      transform: translate(-50% , -50%) scale(1.3,1.3) ;
    }

    100% {
      transform: translate(-50% , -50%) scale(1,1) ;
    }
  }
}


</style>
