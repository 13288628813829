var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Compensation "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('EditIndividualAllowanceModal');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "plus",
      "font-scale": "1.3",
      "scale": "1.5"
    }
  }), _vm._v(" Add segment ")], 1)]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Here is the list of employee compensation ")]), _c('div', {
    staticClass: "grayCard flexColumn"
  }, [_vm._m(0), _c('div', {
    staticClass: "innerShadowInput-container mt-16px"
  }, [_c('label', [_vm._v("Criteria")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd bgWhite w-536px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All ")])], 1)], 1)]), _vm._m(1), _c('div', {
    staticClass: "mainHeading withButton mt-32px"
  }, [_vm._v(" Segment compensation ")]), _c('div', {
    staticClass: "CompensationTable mt-32px"
  }, [_vm._m(2), _c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_c('div', {
          staticClass: "nameWithImage"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Jackson Will")])])]), _c('td', {
          staticClass: "dialer-row-title two"
        }, [_vm._v("02/03/2022 - \"Current\"")]), _c('td', {
          staticClass: "dialer-row-title three"
        }, [_vm._v("£100.00")]), _c('td', {
          staticClass: "dialer-row-title four"
        }, [_vm._v("Salary")]), _c('td', {
          staticClass: "dialer-row-title five"
        }, [_vm._v("Promotion")]), _c('td', {
          staticClass: "dialer-row-title six"
        }, [_vm._v("No comments")]), _c('td', {
          staticClass: "dialer-row-title seven"
        }, [_c('b-dropdown', {
          staticClass: "ActionsDD",
          attrs: {
            "no-caret": "",
            "right": ""
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('div', {
                staticClass: "button-content"
              }, [_c('b-icon', {
                staticClass: "downIcon",
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })], 1)];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', [_vm._v(" All teams ")])], 1)], 1)])];
      },
      proxy: true
    }])
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "circleWithText"
  }, [_c('div', {
    staticClass: "circleContainer",
    staticStyle: {
      "background-color": "#5576d1"
    }
  }), _c('div', {
    staticClass: "circleWithText-text",
    staticStyle: {
      "color": "#5576d1"
    }
  }, [_vm._v("Segment 1")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-center mt-5"
  }, [_c('canvas', {
    attrs: {
      "id": "compensation-chart"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Employee")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("DATES")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("AMOUNT")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("TYPE")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("REASON")]), _c('div', {
    staticClass: "HrmTableHead six"
  }, [_vm._v("COMMENTS")]), _c('div', {
    staticClass: "HrmTableHead seven"
  }, [_vm._v("ACTIONS")])]);

}]

export { render, staticRenderFns }