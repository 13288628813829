<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Assign Team</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-form-input placeholder="Search Team" v-model="filter.teams.search" onkeypress="return /([0-9])/i.test(event.key)" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
        <div class="basicWhiteIOScard mt-16px mb-16px">
          <div 
            class="basicWhiteIOScard-item" 
            v-for="team in filterTeams" 
            :key="team.id" 
            @click="
              api.add.send ? '' :
              selected.teams.includes(team.id) ? 
              selected.teams.splice(selected.teams.indexOf(team.id), 1) : 
              selected.teams.push(team.id)
            "
          >
            <div class="d-flex">
              <Info class="InfoComponentInsideAnroidIOSApp"  :id="team.id" :is_blf="false" />
            </div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center forRadio-container">
                <vb-icon v-if="selected.teams.includes(team.id)" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />   
              </div>
            </div>
          </div>
          <vb-no-record v-if="!filterTeams.length" :text="api.list.send?'':'There is no Numbers'" :design="3">
            <vb-loading v-if="api.list.send" slot="loading" />
          </vb-no-record>
        </div>
        <b-button 
          class="IosFullWidthButton forDone mt-16px mb-16px" 
          v-if="selected.teams.length" 
          :disabled="api.add.send" 
          @click="add()"
        >
          Add Teams
        </b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API, } from "@/utils";
import Info from "../../../Lists/Info.vue";
export default {
  name: "AssignTeamModal",
  components: {
    Info,
  },
  props: {
    modalName: {
      type: String,
      default: "AssignTeam",
    },
  },
  data() {
    return {
      api: {
        add: this.$helperFunction.apiInstance({  }),
        list: this.$helperFunction.apiInstance({  }),
      },
      filter: {
        teams: {
          search: "",
          reset() {
            this.search = "";
          },
        },
      },
      response: {
        teams: [],
        reset() {
          this.teams = [];
        },
      },
      selected: {
        teams: [],
        reset() {
          this.teams = [];
        },
      },
      data: {
        accountcode: "",
        reset() {
          this.accountcode = "";
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    filterTeams() {
      const search = `${this.filter.teams.search}`.trim().toLowerCase();
      return this.response.teams.filter((team) => `${team}`.replace(/-/g,'').trim().toLowerCase().includes(search));
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.accountcode = event?.params?.accountcode || "";
      this.fetchTeams();
    },
    onBeforeClose() {
      this.response.reset();
      this.selected.reset();
      this.filter.teams.reset();
      this.data.reset();
    },
    add() {
      let vm = this
      if (vm.api.add.send) return;
      vm.api.add.send = true;
      VOIP_API.endpoints.teams.extensionTeams({ 
        extension: vm.data.accountcode, 
        fid: 12 
      })
      .then(({ data: res }) => {
        return VOIP_API.endpoints.teams.updateTeams({
          extension: vm.data.accountcode,
          fid: 12,
          teams: vm.selected.teams.join(","),
          new_selected: vm.selected.teams.filter(i=>!res.selected.includes(i)).join(","),
          old_deleted: res.selected.filter(i=>!vm.selected.teams.includes(i)).join(","),
        })
      })
      .then(() => {
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.$emit('added'),{
          selected_list: vm.selected.teams,
        };
        vm.$modal.hide(vm.modalName);
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.add.send=false
      });
    },
    fetchTeams() {
      let vm = this
      vm.api.list.send = true;
      VOIP_API.endpoints.teams.extensionTeams({ 
        extension: vm.data.accountcode, 
        fid: 12 
      })
      .then(({ data: res }) => {
        vm.response.teams = Object.values(res.list);
        vm.selected.teams = res.selected;
      })
      .finally(()=>{
        vm.api.list.send = false;
      });
    },
  },
};
</script>
