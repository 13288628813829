var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Pickup Groups")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.create();
      }
    }
  }, [_c('b-alert', {
    staticClass: "alert-danger mt-32px",
    attrs: {
      "show": !!_vm.api.create.error_message
    }
  }, [_vm._v(_vm._s(_vm.api.create.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px mt-32px"
  }, [_c('b-form-group', {
    staticClass: "mb-0"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item w-100"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2 w-100"
  }, [_c('label', [_vm._v("Name")]), _c('b-form-input', {
    attrs: {
      "disabled": _vm.api.create.send,
      "type": "text",
      "placeholder": "Enter Name"
    },
    model: {
      value: _vm.forms.create.name,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create, "name", $$v);
      },
      expression: "forms.create.name"
    }
  }), _vm.forms.create.submitted && _vm.$v.forms.create.name.$invalid ? _c('p', [!_vm.$v.forms.create.name.required ? _c('span', [_vm._v("*Name is Required")]) : _vm._e()]) : _vm._e()], 1)])])], 1), _c('b-button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.create.send
    }
  }, [_vm.api.create.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }