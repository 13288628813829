<template>
  <div :id="id" class="react-code-input v2">
      <!-- @keyup="onKeyUp($event,index)" -->
    <input
      v-for="(item, index) in numberslength"
      :key="item"
      :id="`${random_id}-pin-code-${index}`"
      :data-index="index"
      type="number"
      @keydown="onKeyDown($event,index)"
      onkeypress="return /^[0-9]+$/.test(event.key)"
      @paste.prevent="onPaste($event)"
      max="9"
      min="0"
      maxlength="1"
      minlength="1"
      :disabled="disabled"
      :readonly="true"
      onfocus="this.removeAttribute('readonly')"
      onblur="this.setAttribute('readonly','readonly')"
    />
  </div>
</template>

<script>
import { $fn, LOGGER } from "../../utils";
export default {
  name: "vb-pin-code",
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    numberslength: {
      type: Number,
      default: 6,
    },
    value: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      random_id: "",
      pin: [],
    };
  },
  computed: {
    pinValue() { return Object.values(this.pin).join(""); },
  },
  watch: {
    numberslength: {
      immediate: true,
      handler(length) {
        this.pin = { ...Array.from({length},()=>'') };
      },
    },
    pinValue(pin_val) {
      if(pin_val=='') this.$emit("change", pin_val);
      if (pin_val == this.value) return;
      this.$emit("change", pin_val);
      if(pin_val.length!=this.numberslength) return;
      this.$emit("complete", pin_val);
    },
    value: {
      immediate: true,
      handler(value) {
        let vm = this
        if (!value) return;
        if (value?.length != this.numberslength) return;
        this.$nextTick(()=>{
          for (let index = 0; index < value.length; index++) {
            const el = document.getElementById(`${vm.random_id}-pin-code-${index}`)
            if (el) el.value = value[index] ?? ''
          }
        })
        this.pin = { ...value.split("") };
      },
    },
  },
  methods: {
    // onKeyUp(event,index){
    //   console.log('onKeyUp',event,index)
    //   const key = event.key=='Unidentified' ? event.target.value : event.key
    //   if(event.ctrlKey && key=='v') return;
    //   event.preventDefault()
    //   if (key == "ArrowLeft"){
    //     document.getElementById(`${this.random_id}-pin-code-${index-1}`)?.focus();
    //   } else if (key == "ArrowRight"){
    //     document.getElementById(`${this.random_id}-pin-code-${index+1}`)?.focus();
    //   } else if (key == "Backspace"){
    //     if(this.pin[index]==''){
    //       document.getElementById(`${this.random_id}-pin-code-${index-1}`)?.focus();
    //     }
    //     this.$set(this.pin,index,'')
    //     document.getElementById(`${this.random_id}-pin-code-${index}`).value='';
    //   } else if (/^\d+$/.test(key)){
    //     this.$set(this.pin,index,key)
    //     document.getElementById(`${this.random_id}-pin-code-${index}`).value=key;
    //     document.getElementById(`${this.random_id}-pin-code-${index+1}`)?.focus();
    //   } else if(!/^\d+$/.test(event.target.value)) {
    //     document.getElementById(`${this.random_id}-pin-code-${index}`).value='';
    //   }
    // },
    onKeyDown(event,index){
      // console.log('onKeyDown',event,index)
      const key = event.key=='Unidentified' ? event.target.value : event.key
      // if(event.ctrlKey && key=='z') {
      //   event.preventDefault()
      // }
      if(event.ctrlKey && key=='v') return;
      event.preventDefault()
      if (key == "ArrowLeft"){
        document.getElementById(`${this.random_id}-pin-code-${index-1}`)?.focus();
      } else if (key == "ArrowRight"){
        document.getElementById(`${this.random_id}-pin-code-${index+1}`)?.focus();
      } else if (key == "Backspace"){
        if(this.pin[index]==''){
          document.getElementById(`${this.random_id}-pin-code-${index-1}`)?.focus();
        }
        this.$set(this.pin,index,'')
        document.getElementById(`${this.random_id}-pin-code-${index}`).value='';
      } else if (/^\d+$/.test(key)){
        this.$set(this.pin,index,key)
        document.getElementById(`${this.random_id}-pin-code-${index}`).value=key;
        document.getElementById(`${this.random_id}-pin-code-${index+1}`)?.focus();
      } else if(!/^\d+$/.test(event.target.value)) {
        document.getElementById(`${this.random_id}-pin-code-${index}`).value='';
      }
    },
    onPaste(Event){
      const copy_text = Event.clipboardData.getData('text/plain').replace(/ /g, "")
      if (copy_text.length!=this.numberslength) return;
      if (!/^[0-9]+$/.test(copy_text)) return;
      const array = copy_text.split('')
      LOGGER.log('copy_text',array)
      for (let index = 0; index < array.length; index++) {
        const el = document.getElementById(`${this.random_id}-pin-code-${index}`)
        if (el) el.value = array[index] ?? ''
      }
      this.pin = { ...copy_text.split('') }
      document.getElementById(`${this.random_id}-pin-code-${Object.values(this.pin).length-1}`)?.focus();
    },
    focus(){
      document.getElementById(`${this.random_id}-pin-code-${0}`)?.focus();
    },
  },
  created() {
    this.random_id = $fn.makeid();
  },
};
</script>

<style lang="scss">
.dialer-settings-main{
  .vm--modal{
    .enterPinSection {
      .react-code-input {
        input {
          width:45px;
          height:48px;
          padding: 5px;
          font-size: 32px;
        }
      }
    }
  }
}

</style>