var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-input-field colorPicker-section",
    attrs: {
      "id": `color-picker-${_vm.random_id}`
    }
  }, [_c('label', [_vm._v(_vm._s(_vm.title))]), _c('div', {
    staticClass: "position-relative w-100"
  }, [_c('input', {
    attrs: {
      "type": "text",
      "disabled": true,
      "placeholder": _vm.placeHolder
    },
    domProps: {
      "value": _vm.value
    },
    on: {
      "input": function ($event) {
        return _vm.onChange($event.target.value);
      }
    }
  }), _c('span', {
    staticClass: "color-circle"
  }, [_c('span', {
    staticClass: "color-swatch",
    style: `background-color:${_vm.value || '#000'};`,
    on: {
      "click": function ($event) {
        _vm.show = !_vm.show;
      }
    }
  })]), _vm.show ? _c('color-picker', {
    attrs: {
      "disabled": _vm.disabled,
      "theme": "light",
      "color": _vm.value || '#000'
    },
    on: {
      "changeColor": function ($event) {
        _vm.onChange(`rgba(${$event.rgba.r},${$event.rgba.g},${$event.rgba.b},${$event.rgba.a})`);
      }
    }
  }) : _vm._e()], 1), _vm._t("error")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }