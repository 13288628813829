<template>
  <div ref="usersContainer">
    
    <b-form v-if="!$store.getters.getIsMobile" class="whiteBGinputWithGreyRoundedBorder mb-3" @submit.prevent="''">
      <b-input type="text" class="w-100" placeholder="Search here" v-model="search" :disabled="isEmpty($store.state.common.voipusers)"/>
    </b-form>
    <b-form v-else class="updatingContactsAgain-contacts-searchBar-container" @submit.prevent="''">
      <div class="updatingContactsAgain-contacts-searchBar">
        <vb-icon icon="mobile-searchBar-icon-likeAirCall-icon" width="12.68px" height="12.67px"/>
        <b-input type="text" class="w-100" placeholder="Search" v-model="search" :disabled="isEmpty($store.state.common.voipusers)"/>
      </div>
    </b-form>
    <div id="user_contact_tab" class="tab-pane active">
      <div class="dialer_contact_list users_list allow_scroll">
        <div
          class="dialer_contact_row"
          v-for="user in searchedUsers"
          :key="user.voipaccount"
          v-show="$store.getters.getCurrentUser.account != user.voipaccount"
        >
          <div v-if="!$store.getters.getIsMobile" class="dialer_contact">
            <Info :id="user.voipaccount" :devices="true" :is_unread_show="true" />
            <div class="user-info">
              <div class="user-name-box">
                <!-- <span class="user-name">{{ user | get_property('user.display_name') }}</span> -->
                <div class="dialer_contact_hidden_area">
                  <div class="dialer_contact_connection_box">
                    <span class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute">
                      <b-dropdown varient="link" no-caret class="ico-filter"> 
                        <template v-slot:button-content>
                          <b-icon icon="three-dots" scale="1.5" />
                        </template>
                        <b-dropdown-item @click="!getUserPermissions.blf?appNotify.open({ message: 'Your package does not support this feature', type: 'danger' }):dialinringing(user)" v-if="user.state=='Ringing'">
                          <vb-svg
                            name="dialer-outlineCall-icon2"
                            width="16.501"
                            height="16.501"
                            viewBox="0 0 16.501 16.501"
                            stroke-width="0"
                            stroke="#005DFF"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></vb-svg>
                          <span class="ml-2">Pick Call</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="dial(user)" v-else>
                          <vb-svg
                            name="dialer-outlineCall-icon2"
                            width="16.501"
                            height="16.501"
                            viewBox="0 0 16.501 16.501"
                            stroke-width="0"
                            stroke="#005DFF"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></vb-svg>
                          <span class="ml-2">Call</span>
                        </b-dropdown-item>
                        <div :id="`RightSideUsersChatOption-${user.voipaccount}`" v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'" :class="['' , !getUserPermissions.chat ? 'for-disable' : '']">
                          <b-dropdown-item :class="!getUserPermissions.chat ? 'for-disable-disable' : ''"  @click="!getUserPermissions.chat ? '' :chatUser(user)">
                            <vb-svg
                              name="dialer-outlineChat-icon"
                              width="16.501"
                              height="16.501"
                              viewBox="0 0 16.501 16.501"
                              stroke-width="0"
                              stroke="#005DFF"
                              fill="none"
                              stroke-linecap="round"
                              stroke-linejoin="round"
                            ></vb-svg>
                            <span class="ml-2">Chat</span>
                          </b-dropdown-item>
                        </div>
                        <b-dropdown-item @click="videoCall(user)">
                          <vb-svg
                            name="dialer-outlineVideo-icon"
                            width="20.6"
                            height="13.6"
                            viewBox="0 0 20.6 13.6"
                            stroke-width="0"
                            stroke="#005DFF"
                            fill="none"
                            stroke-linecap="round"
                            stroke-linejoin="round"
                          ></vb-svg>
                          <span class="ml-2">Video</span>
                        </b-dropdown-item>
                        <b-dropdown-item @click="toggleUserFavourite(user)">
                          <b-icon :icon="user.is_favourites ? 'star-fill' : 'star'"/>
                          <span class="ml-2">{{ user.is_favourites ? 'Remove from Favourite' : 'Add To Favourite' }}</span>
                        </b-dropdown-item>
                      </b-dropdown>
                    </span>
                  </div>
                </div> 
                <span v-if="user.is_favourites" class="inTeamsFavouriteIcon">
                  <b-icon icon="star-fill" variant="primary"/>
                </span>
              </div>
              <!-- <div class="user-number">{{ user.extn }}</div> -->
            </div>
          </div>
          <!-- mobile view design START here -->
          <div class="updatingContactsAgain-contact-item" v-else>
            <Info :id="user.voipaccount" :devices="true" :is_unread_show="false" :is_suspended_show="false" :is_blf="false" />
            <b-dropdown varient="link" no-caret class="ico-filter"> 
              <template v-slot:button-content>
                <vb-icon icon="mobile-topBar-threeDots-likeAirCall-icon" class="mobile-topBar-threeDots-likeAirCall-icon" height="27.06px" width="6px" />
              </template>
              <b-dropdown-item @click="!getUserPermissions.blf?appNotify.open({ message: 'Your package does not support this feature', type: 'danger' }):dialinringing(user)" v-if="user.state=='Ringing'">
                <vb-svg
                  name="dialer-outlineCall-icon2"
                  width="16.501"
                  height="16.501"
                  viewBox="0 0 16.501 16.501"
                  stroke-width="0"
                  stroke="#005DFF"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></vb-svg>
                <span class="ml-2">Pick Call</span>
              </b-dropdown-item>
              <b-dropdown-item @click="dial(user)" v-else>
                <vb-svg
                  name="dialer-outlineCall-icon2"
                  width="16.501"
                  height="16.501"
                  viewBox="0 0 16.501 16.501"
                  stroke-width="0"
                  stroke="#005DFF"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></vb-svg>
                <span class="ml-2">Call</span>
              </b-dropdown-item>
              <div :id="`RightSideUsersChatOption-${user.voipaccount}`" v-b-tooltip :title="getUserPermissions.chat?'':'Your package does not support this feature'" :class="['' , !getUserPermissions.chat ? 'for-disable' : '']">
                <b-dropdown-item :class="!getUserPermissions.chat ? 'for-disable-disable' : ''"  @click="!getUserPermissions.chat ? '' :chatUser(user)">
                  <vb-svg
                    name="dialer-outlineChat-icon"
                    width="16.501"
                    height="16.501"
                    viewBox="0 0 16.501 16.501"
                    stroke-width="0"
                    stroke="#005DFF"
                    fill="none"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  ></vb-svg>
                  <span class="ml-2">Chat</span>
                </b-dropdown-item>
              </div>
              <b-dropdown-item @click="videoCall(user)">
                <vb-svg
                  name="dialer-outlineVideo-icon"
                  width="20.6"
                  height="13.6"
                  viewBox="0 0 20.6 13.6"
                  stroke-width="0"
                  stroke="#005DFF"
                  fill="none"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                ></vb-svg>
                <span class="ml-2">Video</span>
              </b-dropdown-item>
              <b-dropdown-item @click="toggleUserFavourite(user)">
                <b-icon :icon="user.is_favourites ? 'star-fill' : 'star'"/>
                <span class="ml-2">{{ user.is_favourites ? 'Remove from Favourite' : 'Add To Favourite' }}</span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <!-- mobile view design END here -->
        </div>
      </div>
    </div>
    <DirectChatModal />
  </div>
</template>

<script>
import DirectChatModal from "@/components/Modals/chat/DirectChatModal.vue";
import { events, VOIP_API } from "../../utils";
import Info from "../Lists/Info.vue";
import { mapGetters } from 'vuex';
import { MATTERMOST_API, mattermost } from '@/MatterMost';

export default {
  name: "Users",
  components: {
    DirectChatModal,
    Info,
  },
  data() {
    return {
      api: {
        toggle_user_fav: this.$helperFunction.apiInstance({ send: [] }),
        chat_user: this.$helperFunction.apiInstance({  }),
      },
      assets: {
        user_male: require("@/assets/images/Male.png"),
      },
      search: "",
    };
  },
  inject:['isEmpty','appNotify'],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getMattermostCurrentUser',
    ]),
    searchedUsers() {
      const search = this.search.toLowerCase().trim();
      if (!search) return Object.values(this.$store.state.common.voipusers);
      var array = Object.values(this.$store.state.common.voipusers);
      return array.filter((event) =>
        `${event.extn || ""} ${event?.user?.display_name || ""}`
          .toLowerCase()
          .trim()
          .includes(search)
      );
    },
  },
  methods: {
    async chatUser(user) {
      if(!user?.user?.user_chat_id || this.api.chat_user.send) return;
      let channel_id = user.channel_id
      try {
        this.api.chat_user.send=true
        if(!channel_id){
          const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([
            user.user.user_chat_id,
            this.getMattermostCurrentUser?.id
          ]);
          channel_id=channel.id
          mattermost.emitOnUserLevel(mattermost.events.direct_added,{
            channel_id: channel_id
          })
          this.$set(this.$store.state.common.voipusers[user.voipaccount],'channel_id',channel_id)
        }
        this.$store.state.global_conditions.tab='chat'
        this.$root.$emit(events.pinned_post_changed,{
          channel_id: channel_id
        })
        await this.$nextTick()
        this.$root.$emit(events.open_chat,{
          channel_id: channel_id,
        })
        this.$emit('close-side-bar')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.chat_user.send=false
      }
    },
    // chatUser(user) {

    //   this.$modal.show("ChatUserModal", { user: user });
    // },
    videoCall(user) {
      this.$root.$emit(events.video_call, {
        accounts: [user.voipaccount],
        call_type: "video",
      });
    },
    dial(user) {
      this.$root.$emit(events.audio_call, {
        number: user.extn,
      });
    },
    dialinringing(user){
      this.$root.$emit(events.audio_call, { 
        number: user.extn,
        call_type: 'blf_call',
        id: user.voipaccount,
      });
    },
    toggleUserFavourite(user){
      let vm = this
      const voipaccount = user.voipaccount
      const is_favourites = user.is_favourites
      if(vm.api.toggle_user_fav.send.includes(voipaccount)) return;
      vm.api.toggle_user_fav.send.push(voipaccount)
      VOIP_API.endpoints.user.toggleFavourite({
        user_accountcode: voipaccount, 
      })
      .then(()=>{
        vm.$store.state.common.voipusers[voipaccount].is_favourites = !vm.$store.state.common.voipusers[voipaccount].is_favourites
        vm.appNotify({
          message: `Successfully ${is_favourites ? 'Remove from Favourite' : 'Add to Favourite'}`,
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        if(vm.api.toggle_user_fav.send.includes(voipaccount)) vm.api.toggle_user_fav.send.splice(vm.api.toggle_user_fav.send.indexOf(voipaccount),1)
      })
    },
  },
};
</script>
