var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_vm.conditions.screen == _vm.screens.plan_license ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.plans;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Plan License")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.planLicenses, function (service) {
    return _c('div', {
      key: service.product_id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.service_id = service.product_id;
          _vm.selected.service_type = _vm.serviceTypes.free;
          _vm.conditions.screen = _vm.screens.service_detail;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("get_property")(service, "product_label")))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
    }, [_vm._v(" " + _vm._s(_vm._f("get_property")(service, "product_total_free_allowed", 0)) + "/" + _vm._s(_vm._f("get_property")(service, "product_already_used", 0)) + "/" + _vm._s(_vm._f("get_property")(service, "product_free_remaining", 0)) + " ")]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)]);
  }), _vm.planLicenses.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Products',
      "design": 3
    }
  }) : _vm._e()], 2)])])] : _vm.conditions.screen == _vm.screens.addon_subscription ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.plans;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Addon Subscription")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.call_packages;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Call Packages")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1), _vm._l(_vm.serviceAddons, function (service) {
    return _c('div', {
      key: service.product_id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.service_id = service.product_id;
          _vm.selected.service_type = _vm.serviceTypes.addon;
          _vm.conditions.screen = _vm.screens.service_detail;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("get_property")(service, "label")))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
    }, [_vm._v(" " + _vm._s(_vm._f("get_property")(service, "allowed", 0)) + "/" + _vm._s(_vm._f("get_property")(service, "used", 0)) + "/" + _vm._s(_vm._f("get_property")(service, "remaining", 0)) + " ")]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)]);
  }), _vm.serviceAddons.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Products',
      "design": 3
    }
  }) : _vm._e()], 2)])])] : _vm.conditions.screen == _vm.screens.service_detail ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.selected.service_type == _vm.serviceTypes.addon ? _vm.screens.addon_subscription : _vm.selected.service_type == _vm.serviceTypes.addon_call ? _vm.screens.call_packages : _vm.screens.plan_license;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Detail")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.selectedServices, function (data) {
    return _c('div', {
      key: data.free_resource_subscription_id || data.subscription_id,
      staticClass: "basicWhiteIOScard-item"
    }, [data.free_product_detail ? [['USER', 'Team', 'Call Queue'].includes(data.free_product_detail.product.product_label) ? _c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": data.service_internal_accountcode
      }
    }) : _c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'free_product_detail.product.product_label')))])] : [['USER', 'Team', 'Call Queue'].includes(data.label) ? _c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": data.service_internal_accountcode
      }
    }) : _c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("get_property")(data, 'label')))])], _c('span', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.unsubscribe(data);
        }
      }
    }, [_c('vb-icon', {
      staticClass: "rightFacingArrow fillDanger",
      attrs: {
        "icon": "trash-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)], 2);
  }), _vm.selectedServices.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Products',
      "design": 3
    }
  }) : _vm._e()], 2)])])] : _vm.conditions.screen == _vm.screens.call_packages ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.addon_subscription;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Call Packages")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.callAddons, function (service) {
    return _c('div', {
      key: service.product_id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.service_id = service.product_id;
          _vm.selected.service_type = _vm.serviceTypes.addon_call;
          _vm.conditions.screen = _vm.screens.service_detail;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("get_property")(service, "label")))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
    }, [_vm._v(" " + _vm._s(_vm._f("get_property")(service, "allowed", 0)) + "/" + _vm._s(_vm._f("get_property")(service, "used", 0)) + "/" + _vm._s(_vm._f("get_property")(service, "remaining", 0)) + " ")]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)]);
  }), _vm.callAddons.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Products',
      "design": 3
    }
  }) : _vm._e()], 2)])])] : _vm.conditions.screen == _vm.screens.plan ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.update_plan.send ? '' : _vm.conditions.screen = _vm.screens.plans;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Plan")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(_vm._s(_vm.selectedPlan.name) + " " + _vm._s(_vm.selectedPlan.real_id == _vm.currentPlanId ? 'Current' : ''))]), _c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.selectedPlan, 'currency.currency_code', '')) + " " + _vm._s(_vm._f("floatFixed2")(_vm.selectedPlan.price)) + "/" + _vm._s(_vm.selectedPlan.interval) + "/user ")])]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.update_plan.send ? '' : _vm.conditions.screen = _vm.screens.plan_products;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Products")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.api.update_plan.send ? '' : _vm.conditions.screen = _vm.screens.plan_features;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Features")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _vm.selectedPlan.real_id != _vm.currentPlanId ? _c('button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.update_plan.send
    },
    on: {
      "click": function ($event) {
        return _vm.updatePlan();
      }
    }
  }, [_vm.api.update_plan.send ? _c('vb-spinner') : [_vm._v("Choose Plan")]], 2) : _vm._e()])])] : _vm.conditions.screen == _vm.screens.plan_products ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.plan;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Plan Products")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.selectedPlanProducts, function (product) {
    return _c('div', {
      key: product.product_id,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("get_property")(product, 'product.product_label')))]), _c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
    }, [_vm._v(_vm._s(_vm._f("get_property")(product, 'plan_free_product_allowed')))])]);
  }), _vm.selectedPlanProducts.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Products',
      "design": 3
    }
  }) : _vm._e()], 2)])])] : _vm.conditions.screen == _vm.screens.plan_features ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.plan;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Plan Features")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.selectedPlanFeatures, function (feature) {
    return _c('div', {
      key: feature.id,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("get_property")(feature, 'feature.label')))])]);
  }), _vm.selectedPlanFeatures.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Products',
      "design": 3
    }
  }) : _vm._e()], 2)])])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Plans")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.plan_license;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Plan License")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.addon_subscription;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Addon Subscription")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Monthly")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-check', {
    staticClass: "dialer-switch custom-control custom-switch IosSwitch",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.filters.plans.monthly,
      callback: function ($$v) {
        _vm.$set(_vm.filters.plans, "monthly", $$v);
      },
      expression: "filters.plans.monthly"
    }
  })], 1)]), _vm._l(_vm.plans, function (plan) {
    return _c('div', {
      key: plan.real_id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.plan_id = plan.real_id;
          _vm.conditions.screen = _vm.screens.plan;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(plan.name) + " " + _vm._s(plan.real_id == _vm.currentPlanId ? 'Current' : ''))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
    }, [_vm._v(" " + _vm._s(_vm._f("get_property")(plan, 'currency.currency_code', '')) + " " + _vm._s(_vm._f("floatFixed2")(plan.price)) + "/" + _vm._s(plan.interval) + "/user ")]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)]);
  }), _vm.plans.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.packages.send ? '' : 'There is no playlist',
      "design": 3
    }
  }, [_vm.api.packages.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])])]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }