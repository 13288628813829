var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "TODO-taksTable-bodyRow thisIsAddTaskRow mt-12px",
    staticStyle: {
      "min-height": "auto"
    }
  }, [_vm.conditions.add_task ? _c('b-form', {
    staticClass: "w-100 p-2",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createTask();
      }
    }
  }, [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column align-items-start py-2 w-auto"
  }, [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text d-flex justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "ml-3",
    attrs: {
      "disabled": _vm.api.create_task.send,
      "variant": "link",
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.add_task = false;
        _vm.forms.create_task.reset();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x-lg",
      "variant": "danger"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "align-items-start py-2 justify-content-start flex-1"
  }, [_c('div', {
    staticClass: "d-flex flex-column whiteBGinputWithGreyRoundedBorder mb-2"
  }, [_c('b-form-input', {
    staticClass: "bg-white w-100",
    attrs: {
      "type": "text",
      "placeholder": "Add title",
      "disabled": _vm.api.create_task.send,
      "maxLength": _vm.$v.forms.create_task.title.$params.maxLength.max
    },
    model: {
      value: _vm.forms.create_task.title,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_task, "title", $$v);
      },
      expression: "forms.create_task.title"
    }
  }), _vm.forms.create_task.submitted && _vm.$v.forms.create_task.title.$invalid || _vm.api.create_task.validation_errors.title ? _c('p', {
    staticClass: "error-red text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_task.title.required ? _c('span', [_vm._v("Title is required")]) : !_vm.$v.forms.create_task.title.minLength ? _c('span', [_vm._v(" Title should be minimum " + _vm._s(_vm.$v.forms.create_task.title.$params.minLength.min) + " character")]) : !_vm.$v.forms.create_task.title.maxLength ? _c('span', [_vm._v(" Title can be maximum " + _vm._s(_vm.$v.forms.create_task.title.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.create_task.validation_errors.title, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "d-flex flex-column whiteBGinputWithGreyRoundedBorder flex-fill dropdownVersion ml-4"
  }, [_c('b-select', {
    attrs: {
      "placeholder": "Select space",
      "disabled": _vm.api.create_task.send,
      "options": Object.values(_vm.spaces),
      "value-field": "id",
      "text-field": "name"
    },
    model: {
      value: _vm.forms.create_task.space_id,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_task, "space_id", $$v);
      },
      expression: "forms.create_task.space_id"
    }
  }), _vm.forms.create_task.submitted && _vm.$v.forms.create_task.space_id.$invalid || _vm.api.create_task.validation_errors.space_id ? _c('p', {
    staticClass: "error-red"
  }, [!_vm.$v.forms.create_task.space_id.required ? _c('span', [_vm._v("Space is required")]) : _vm._e(), _vm._l(_vm.api.create_task.validation_errors.space_id, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column align-items-start py-2 w-auto"
  }, [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text d-flex justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "submit",
      "variant": "primary",
      "disabled": _vm.api.create_task.send
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm._v(" Submit ")])], 1)], 1)])]) : _c('div', {
    staticClass: "TODO-taksTable-bodyRow-column onlyOne justify-content-start",
    on: {
      "click": function ($event) {
        _vm.conditions.add_task = true;
        _vm.forms.create_task.space_id = _vm.space_id || '';
      }
    }
  }, [_c('div', {
    staticClass: "TODO-taksTable-bodyRow-column-text"
  }, [_c('b-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "plus-lg"
    }
  }), _vm._v(" Add task ")], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }