var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "top-main-hrm"
  }, [_c('section', [_c('div', {
    staticClass: "bg-hrm-mike scrollow1"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-start"
  }, [_c('div', {
    staticClass: "w-75 for-home-dd-width d-flex flex-wrap"
  }, [_c('div', {
    staticClass: "home-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "home-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "home-button-content-dropi"
        }, [_c('div', {
          staticClass: "home-dropi-contenti"
        }, [_vm._v("All teams")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-form-checkbox', {
    staticClass: "new-checkcheck",
    attrs: {
      "id": "checkbox-1",
      "name": "checkbox-1"
    }
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Voip (4)")])]), _c('b-form-checkbox', {
    staticClass: "new-checkcheck",
    attrs: {
      "id": "checkbox-2",
      "name": "checkbox-2"
    }
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Wanio (10)")])]), _c('b-form-checkbox', {
    staticClass: "new-checkcheck",
    attrs: {
      "id": "checkbox-3",
      "name": "checkbox-3"
    }
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Kuflink (16)")])])], 1)], 1), _c('div', {
    staticClass: "home-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "home-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "home-button-content-dropi"
        }, [_c('div', {
          staticClass: "home-dropi-contenti"
        }, [_vm._v("All locations")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "drop-dropdown-toggle"
  }, [_vm._v("Amsterdam (4)")]), _c('b-dropdown-item', {
    staticClass: "drop-dropdown-toggle"
  }, [_vm._v("London (4)")]), _c('b-dropdown-item', {
    staticClass: "drop-dropdown-toggle"
  }, [_vm._v("New York (1)")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("No location (1)")])], 1)], 1), _c('div', {
    staticClass: "home-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "home-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "home-button-content-dropi"
        }, [_c('div', {
          staticClass: "home-dropi-contenti"
        }, [_vm._v("All employment statuses")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "drop-dropdown-toggle"
  }, [_vm._v("Second Item")]), _c('b-dropdown-item', {
    staticClass: "drop-dropdown-toggle"
  }, [_vm._v("Second Item ")])], 1)], 1), _c('div', {
    staticClass: "home-dd-container"
  }, [_c('b-dropdown', {
    staticClass: "home-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "home-button-content-dropi"
        }, [_c('div', {
          staticClass: "home-dropi-contenti"
        }, [_vm._v("Everyone")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "drop-dropdown-toggle"
  }, [_vm._v("Second Item")]), _c('b-dropdown-item', {
    staticClass: "drop-dropdown-toggle"
  }, [_vm._v("Second Item ")])], 1)], 1)]), _c('div', {
    staticClass: "w-25 top-btn-align"
  }, [_c('button', {
    staticClass: "bt btn-primarymike mt-2 mr-top-sidebsvg"
  }, [_c('svg', {
    staticClass: "primarymike-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.551",
      "height": "16.551",
      "viewBox": "0 0 16.551 16.551"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_ionic-ios-add",
      "data-name": "Icon ionic-ios-add",
      "d": "M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z",
      "transform": "translate(-8.465 -8.465)",
      "fill": "#fff",
      "stroke": "#fff",
      "stroke-width": "1"
    }
  })]), _vm._v(" Add ")]), _c('button', {
    staticClass: "btn-svg-none"
  }, [_c('svg', {
    staticClass: "mr-top-sidebsvg",
    attrs: {
      "id": "Group_16231",
      "data-name": "Group 16231",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "12.033",
      "height": "15.016",
      "viewBox": "0 0 12.033 15.016"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_ionic-md-arrow-dropright",
      "data-name": "Icon ionic-md-arrow-dropright",
      "d": "M0,0,6.016,6.016,0,12.033Z",
      "transform": "translate(12.033 9) rotate(90)",
      "fill": "#5576d1"
    }
  }), _c('path', {
    attrs: {
      "id": "Icon_ionic-md-arrow-dropright-2",
      "data-name": "Icon ionic-md-arrow-dropright",
      "d": "M0,0,6.016,6.016,0,12.033Z",
      "transform": "translate(0 6.016) rotate(-90)",
      "fill": "#5576d1"
    }
  })])]), _c('button', {
    staticClass: "btn-svg-none"
  }, [_c('svg', {
    staticClass: "mr-top-sidebsvg",
    attrs: {
      "id": "Icon_ionic-ios-menu",
      "data-name": "Icon ionic-ios-menu",
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "21.607",
      "height": "15.75",
      "viewBox": "0 0 21.607 15.75"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_16",
      "data-name": "Path 16",
      "d": "M25.207,12.375H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,12.375Z",
      "transform": "translate(-4.5 -10.125)",
      "fill": "#5576d1"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_17",
      "data-name": "Path 17",
      "d": "M25.207,19.125H5.4A1.035,1.035,0,0,1,4.5,18h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,19.125Z",
      "transform": "translate(-4.5 -10.125)",
      "fill": "#5576d1"
    }
  }), _c('path', {
    attrs: {
      "id": "Path_18",
      "data-name": "Path 18",
      "d": "M25.207,25.875H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,25.875Z",
      "transform": "translate(-4.5 -10.125)",
      "fill": "#5576d1"
    }
  })])])])]), _c('div', {
    staticClass: "card-container-main-box mt-5"
  }, _vm._l(11, function (n) {
    return _c('div', {
      key: n,
      staticClass: "card-contain"
    }, [_vm._m(0, true), _c('div', {
      staticClass: "decription-img-box-home"
    }, [_vm._v(" " + _vm._s(n) + " ")])]);
  }), 0)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "imgify-home-box"
  }, [_c('img', {
    staticClass: "imgify-heiwid",
    attrs: {
      "src": require("../../assets/images/credit-card/hrmprofiletest.png")
    }
  })]);

}]

export { render, staticRenderFns }