<template>
  <modal 
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto" 
    :name="modalName" 
    @before-open="onBeforeOpen" 
    @before-close="onBeforeClose"
  >
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$modal.hide(modalName)" />
      </div>
      <div class="dialer-settings-title topMainHeading">Configure call recording</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div class="innerModalIos">
      <div class="basicWhiteIOScard mt-32px">
        <div class="basicWhiteIOScard-item">
          <div class="d-flex align-items-center">
            <div class="headingInsideTable">Advance options for call recordings</div>
          </div>
          <div class="position-relative">
            <b-form-checkbox 
              ref="allow_recording_ref" 
              class="dialer-switch" 
              :disabled="!getUserPermissions.call_recording"
              switch 
              :checked="selected.allow_recording" 
              @change="updateAllowRecording($event)" 
            />
          </div>
        </div>
        <template v-if="selected.allow_recording">
          <div 
            class="basicWhiteIOScard-item" 
            v-for="recordingOption in recordingOptions" 
            :key="recordingOption.value" 
            @click="updateSetting(recordingOption.value);"
          >
            <div class="headingInsideTable">{{ recordingOption.text }}</div>
            <vb-icon v-if="selected.setting == recordingOption.value" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
          </div>
        </template>
      </div>
    </div>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex'
import { VOIP_API } from '@/utils/index'
export default {
  name: 'CallRecordingEditModal',
  props: {
    modalName: {
      type: String,
      default: 'CallRecordingEditModal',
    },
  },
  data(){
    return {
      api: {
        update_recording_allow: this.$helperFunction.apiInstance(),
        update: this.$helperFunction.apiInstance(),
        reset(){
          this.update_recording_allow?.reset?.()
          this.update?.reset?.()
        },
      },
      selected: {
        allow_recording: false,
        setting: '',
      },
      data: {
        account: '',
        reset(){
          this.account = ''
        },
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
    }),
    recordingOptions(){
      return [
        {
          text: 'off',
          value: 'off',
        },
        {
          text: 'Auto call recording',
          value: 'auto',
        },
        {
          text: 'call recording ondemand',
          value: 'ondemand',
        },
      ]
    }
  },
  methods: {
    onBeforeOpen(event){
      this.data.account = event?.params?.account ?? ''
      this.selected.allow_recording = event?.params?.allow_recording=='1'
      this.selected.setting = event?.params?.setting ?? ''
    },
    onBeforeClose(){
      this.data.reset()
      this.api.reset()
    },
    
    updateAllowRecording(value) {
      let vm = this
      if (vm.api.update_recording_allow.send) return;
      vm.api.update_recording_allow.send=true;
      VOIP_API.endpoints.recording.updateAllowRecording({
        account: vm.data.account,
        accountcode: vm.$store.getters.getCurrentUser?.account,
        value: value ? "1" : "0",
        field: "allow_recording",
      }).then(() => {
        if(vm.$refs['allow_recording_ref']) vm.$refs['allow_recording_ref'].localChecked = value;
        vm.selected.allow_recording=value
      vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        vm.$emit('latest');
      }).catch((ex) => {
        if(vm.$refs['allow_recording_ref']) vm.$refs['allow_recording_ref'].localChecked = !value;
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        vm.api.update_recording_allow.send=false;
      });
    },
    updateSetting(value) {
      let vm = this
      if(vm.api.update.send) return;
      vm.api.update.send = true;
      VOIP_API.endpoints.users.updateusercallrecordingsetting({
        ondemand: value === "ondemand" ? "1" : "0",
        auto: value === "auto" ? "1" : "0",
        off: value === "off" ? "1" : "0",
        account: vm.data.account,
      }).then(() => {
        vm.selected.setting=value
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
        vm.$emit('latest');
      }).catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        vm.api.update.send = false;
      });
    },
  },
}
</script>

<style>

</style>