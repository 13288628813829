var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 addTagsModal-centerVersion onlyTagsModal scrollable",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "dialer-edit-header position-relative d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Add tags")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text mb-0"
  }, [_vm._v(" Add tags name and details. ")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100"
  }, [_c('b-form', {
    staticClass: "dialer-form w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.create();
      }
    }
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100"
  }, [_c('label', [_vm._v("Tag name")]), _c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.create.send,
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.forms.create.tag,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create, "tag", $$v);
      },
      expression: "forms.create.tag"
    }
  }), _vm.forms.create.submitted && _vm.$v.forms.create.tag.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create.tag.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px"
  }, [_c('label', [_vm._v("Tag color")]), _c('div', {
    staticClass: "createSpace-selectColor-container"
  }, [_vm.api.fetch_color_combination.send ? _vm._l(32, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "createSpace-selectColor-item-container"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "30px",
        "width": "30px"
      }
    })]);
  }) : _vm._l(_vm.colorComboOptions, function (color) {
    return _c('div', {
      key: color.id,
      staticClass: "createSpace-selectColor-item-container"
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item",
      class: {
        'selected': _vm.forms.create.color_combo_id == color.value,
        'TransparentSelected': color.color == 'rgba(0,0,0,0)'
      },
      style: `background-color:${color.color};`,
      on: {
        "click": function ($event) {
          _vm.api.create.send ? '' : _vm.forms.create.color_combo_id = color.value;
        }
      }
    }, [color.color == 'rgba(0,0,0,0)' ? _c('vb-icon', {
      attrs: {
        "icon": "todo-transparentColored-icon",
        "height": "28px",
        "width": "30px"
      }
    }) : _vm._e()], 1)]);
  })], 2), _vm.forms.create.submitted && _vm.$v.forms.create.color_combo_id.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create.color_combo_id.required ? _c('span', [_vm._v("* Tag color is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "variant": "success",
      "disabled": _vm.api.create.send
    }
  }, [_vm.api.create.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }