<template>
  <modal class="center-zoomIn-transition sipActiveCall-Modal TagsModal" :transition="!$store.getters.getIsMobile ? 'center-zoomIn-transition' : ''" :name="modalName" @before-open="beforeOpen" @before-close="beforeClose">
    <div class="dialer-call-notification-tray d-block">
      <div class="dialer-call-notification">
        <div class="dialer-call-notification-inner justify-content-between w-100">
          <div class="dialer-call-notification-text">
            <div class="dialer-call-notification-main-text">Tag</div>
            <div class="dialer-call-notification-sub-text">Here you can Select the tags for this call</div>
          </div>
          <a class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
        <div class="dialer-call-notification-actions">
          <div class="dialer-call-notification-actions-inner">
            <b-form class="dialer-searchBox" @submit.prevent="''">
              <div class="whiteBGinputWithGreyRoundedBorder">
                <b-form-input placeholder="Search here" v-model="filter.tag.search" />
              </div>
            </b-form>
            <template v-if="api.tag.error.errors">
              <p v-for="error_log in api.tag.error.errors.log" :key="error_log">{{ error_log }}</p>
            </template>
            <ul class="dialer-tagList mt-20px">
              <li v-for="tag in filterTags" :key="tag.real_id" @click="handleTags(tag)" v-vb-tag-color.bg.text.border="form.tag.tags.includes(tag.real_id) && {color:tag.colour,text_color:tag.text_color}" :class="{selected: form.tag.tags.indexOf(tag.real_id)>-1}">
                {{ tag.tag }}
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '../../utils';
import _ from 'lodash';
import { mapGetters } from 'vuex';
export default {
  name: 'TagsModal',
  props: {
    modalName: {
      type: String,
      default: 'TagsModal'
    },
  },
  data(){
    return {
      form: {
        tag: {
          tags: [],
          reset(){
            this.tags=[]
          },
        },
      },
      data: {
        id: '',
        reset(){
          this.id=''
        },
      },
      filter: {
        tag: {
          search: '',
          reset(){
            this.search=''
          },
        },
      },
      api: {
        tag: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
          },
        },
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getVoipCallTags'
    ]),
    filterTags() {
      const search = this.filter.tag.search.toLowerCase().trim()
      return this.getVoipCallTags.filter(item => `${item.tag}`.toLowerCase().trim().includes(search));
    },
  },
  methods: {
    beforeOpen(event){
      // console.log(event.params)
      this.data.id = event.params.id
      this.form.tag.tags = event.params.tags || []
    },
    beforeClose(){
      // this.data.reset()
      this.filter.tag.reset()
      // this.form.tag.reset()
    },
    handleTags(tag){
      if(this.form.tag.tags.indexOf(tag.real_id)>-1){
        this.form.tag.tags.splice(this.form.tag.tags.indexOf(tag.real_id),1)
      } else {
        this.form.tag.tags.push(tag.real_id)
      }
      this.sendTag()
    },
    sendTag: _.debounce(function () {
      let vm = this;
      if (vm.api.tag.send) return;
      vm.api.tag.send = true;
      vm.api.tag.increment()
      VOIP_API.endpoints.call_activity.calltagsnotes({
        uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
        accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
        id: vm.data.id,
        log: vm.form.tag.tags.toString(),
        type: "tag",
      })
      .then(() => {
        vm.api.tag.status = true;
        vm.api.tag.error = {};
        this.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
        vm.$emit('updated')
        vm.$emit('updated-tags',JSON.parse(JSON.stringify(vm.form.tag.tags)))
      })
      .catch((ex) => {
        vm.api.tag.status = false;
        vm.api.tag.error = ex.response_error;
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        vm.form.tag.reset()
      })
      .finally(() => {
        vm.api.tag.send = false;
      });
    }, 2 * 1000),
  },
}
</script>

<style>

</style>