var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 editTeamModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit pb-4"
  }, [_vm.data.call.accountcode ? _c('Info', {
    attrs: {
      "id": _vm.data.call.accountcode
    }
  }) : _vm._e(), _vm.data.call.assigned_accountcode ? _c('Info', {
    attrs: {
      "id": _vm.data.call.assigned_accountcode
    }
  }) : _vm._e(), _c('p', {
    staticClass: "color-white"
  }, [_vm._v(_vm._s(_vm.data.call.assigned_date))]), _vm.data.call.status ? _c('p', {
    staticClass: "color-white"
  }, [_vm._v(_vm._s(_vm.data.call.status))]) : _vm._e(), _c('b-button', [_vm._v(" Done ")]), _c('b-button', [_vm._v(" Archive ")]), _c('b-button', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AssignTodo', {
          call_id: _vm.data.call.cdr_id
        });
      }
    }
  }, [_vm._v(" Assign ")]), _c('CallActivityItem1', {
    staticStyle: {
      "background-color": "black"
    },
    attrs: {
      "call": _vm.data.call.todoData
    }
  })], 1)]), _c('AssignTodoModal', {
    on: {
      "assigned": function ($event) {
        return _vm.$emit('assigned');
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }