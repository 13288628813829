var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    staticClass: "dialer-flex alt px-4 py-2"
  }, [_c('div', {
    staticClass: "dialer-incommingVideoCall-info d-flex align-items-center"
  }, [_c('div', {
    staticClass: "dialer-user-dp mr-2"
  }, [_c('img', {
    staticClass: "rounded",
    attrs: {
      "width": "40",
      "height": "40",
      "src": _vm.display_image,
      "alt": _vm.display_name
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })]), _c('div', {
    staticClass: "dialer-user-bio"
  }, [_c('h6', {
    staticClass: "text-capitalize mb-0"
  }, [_vm._v(_vm._s(_vm.display_name))]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-2"
  }, [_vm._v("Time Left: " + _vm._s(_vm._f("time_audio")(_vm.time)))]), _c('b-icon', {
    attrs: {
      "icon": "camera-video"
    }
  })], 1), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-2"
  }, [_vm._v("Status: " + _vm._s(_vm.status))])])])]), _c('div', {
    staticClass: "d-flex"
  }, [_c('a', {
    staticClass: "d-flex flex-column align-items-center mx-3",
    on: {
      "click": _vm.cancel
    }
  }, [_c('span', {
    staticClass: "ico-chat bg-danger text-white rotate-135"
  }, [_c('font-awesome-icon', {
    attrs: {
      "icon": "phone-alt",
      "scale": "0.9"
    }
  })], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }