import { VOIP_API } from "../../index"


export const CONTACTS_CRM_API_ENDPOINTS = {
  list(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: `contacts`,
      method: 'GET',
      params,
      cancelToken
    })
  },
  search(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: `contacts/search`,
      method: 'GET',
      params,
      cancelToken
    })
  },
}