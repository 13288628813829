var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "sec-common"
  }, [_c('h5', {
    staticClass: "d-flex align-items-center justify-content-start emp-profile-heading"
  }, [_vm._v("Personal")]), _c('div', {
    staticClass: "personal-top-box"
  }, [_c('div', {
    staticClass: "calender-hrm-height mb-3"
  }, [_c('h5', {
    staticClass: "calender-page-subheading mt-2"
  }, [_vm._v("Personal Information")]), _c('div', {
    staticClass: "personal-info-box"
  }, [_c('div', {
    staticClass: "d-flex w-100 flex-wrap"
  }, [_vm._m(0), _vm._m(1), _vm._m(2), _vm._m(3), _vm._m(4), _vm._m(5), _vm._m(6), _vm._m(7), _vm._m(8), _vm._m(9), _vm._m(10), _vm._m(11), _c('div', {
    staticClass: "p-info-width"
  }), _c('div', {
    staticClass: "p-info-width"
  }), _c('div', {
    staticClass: "p-info-button"
  }, [_c('button', {
    staticClass: "btn-svg-none",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('HRMEditPersonInfoModal');
      }
    }
  }, [_c('svg', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "20",
      "height": "20",
      "viewBox": "0 0 20 20"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_material-edit",
      "data-name": "Icon material-edit",
      "d": "M4.5,20.33V24.5H8.666L20.953,12.209,16.787,8.043ZM24.175,8.987a1.106,1.106,0,0,0,0-1.566l-2.6-2.6a1.106,1.106,0,0,0-1.566,0L17.976,6.854l4.166,4.166,2.033-2.033Z",
      "transform": "translate(-4.5 -4.496)",
      "fill": "#5576d1"
    }
  })])])])])])])])])]), _c('HRMEditPersonInfoModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("Date of birth")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("15/07/1992")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("Street")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("Mariotteplein 199")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("City")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("Amsterdam")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("State")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("Amsterdam")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("Postcoded")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("1090")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("Country")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("London")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("Nationality")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("British")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("Home phone ")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("441873740607")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("Home phone")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("441873740607")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("Personal e-mail")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("amber@vavoip.com")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("Gender")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("Female")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "p-info-width"
  }, [_c('h5', {
    staticClass: "p-info-head"
  }, [_vm._v("Martial status")]), _c('p', {
    staticClass: "p-info-text"
  }, [_vm._v("Single")])]);

}]

export { render, staticRenderFns }