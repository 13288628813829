<template>
  <div class="crm-main-section CompanySection">
    <div class="crm-main-section-topBar">
      <h2 class="crm-main-section-heading mb-0">
        Companies
      </h2>
      <div class="section-topBar-middlePart">
        <vb-icon icon="crm-inputSearch-icon" />
        <input type="text" placeholder="Search">
      </div>
      <div class="section-topBar-lastPart">
        <!-- <button class="crm-button listGridView-btn centerBtn ml-0">
          <vb-icon class="crm-threeDotsHorizontal-icon" icon="crm-threeDotsHorizontal-icon" />
        </button> -->
        <button class="newButton" @click="$modal.show('CreateCompanyModal')">
          <vb-icon icon="squared-add-icon" height="38px" width="38px" />
          <span class="newButton-textPart position-relative d-flex justify-content-center">
            Create Companies
          </span>
        </button>
      </div>
    </div>
    <div class="crm-main-section-body">
      <div class="d-flex justify-content-between">
        <div class="crm-card halfWidth">
          <div class="crm-card-heading">
            <div>
              Companies by day
              <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
            </div>
            
            <div class="d-flex align-items-center">
              <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck">
                <b-dropdown class="" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1" class="ml-2"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <div class="crm-graph-container">
            put graph here
          </div>
        </div>
        <div class="crm-card halfWidth">
          <div class="crm-card-heading">
            <div>
              Companies by source
              <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
            </div>
            <div class="d-flex align-items-center">
              <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck">
                <b-dropdown class="" id="sound-setting-micro-devices" no-caret text="Select Device">
                  <template #button-content>
                    <span class="d-flex align-items-center p-0">
                      <span>This week</span>
                      <b-icon-chevron-down scale="1.1" class="ml-2"></b-icon-chevron-down>
                    </span>
                  </template>
                  <b-dropdown-item >
                    <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                    <span> This week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> This Month </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Week </span>
                  </b-dropdown-item>
                  <b-dropdown-item >
                    <span> Next Month </span>
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </div>
          <div class="crm-graph-container">
            put graph here
          </div>
        </div>
      </div>
      <div class="w-100 d-flex justify-content-between align-items-center mb-3">
        <div class="w-100 d-flex">
          <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck">
            <b-dropdown class="" no-caret  >
              <template #button-content>
                <span class="d-flex align-items-center p-0">
                  <vb-icon icon="crm-filterOptions-icon" class="crm-filterOptions-icon"/>
                  <span>Filter</span>
                  <b-icon icon="chevron-down" class="ml-2" />
                </span>
              </template>
              <b-dropdown-item >
                <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span> This week </span>
              </b-dropdown-item>
              <b-dropdown-item >
                <span> This Month </span>
              </b-dropdown-item>
              <b-dropdown-item >
                <span> Next Week </span>
              </b-dropdown-item>
              <b-dropdown-item >
                <span> Next Month </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <button class="newButton onlyIcon ml-8px" >
            <vb-icon icon="squared-pencil-icon" height="38px" width="38px" />
          </button>
        </div>
        <div class="d-flex align-items-center">
          <div class="crm-input-container mb-0 mr-3">
            <input placeholder="Search" class="w-100"/>
            <vb-icon icon="crm-inputSearch-icon" />
          </div>
        </div>
        <!-- <div class="totalsTextOnMain">
          1 Companies
        </div> -->
      </div>
      <vb-table class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
        <template slot="header">
          <tr>
            <th>
              <b-form-checkbox class="crm-checkbox" id="checkbox-1"/>
            </th>
            <th class="dialer-has-sort">
              <span class="dialer-sorted-by ascending">Name</span>
            </th>
            <th class="dialer-has-sort">
              <span>E-mail address</span>
            </th>
            <th class="dialer-has-sort">
              <span>Source</span>
            </th>
            <th class="dialer-has-sort">
              <span>owner</span>
            </th>
            <th class="dialer-col-right cursor_pointer">
              <span>Source</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
            <td>
              <b-form-checkbox class="crm-checkbox" v-model="data.chk" id="checkbox-1"/>
            </td>
            <td class="dialer-row-title">
              <span>{{ data.m1 }}</span>
            </td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-row-title">{{ data.m1 }}</td>
            <td class="dialer-col-right">
              {{data.m2}}
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    
    <modal
      class="dialer_animation right_side_popup scrollable bigSquareCheckbox IosModal CRM-modal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      name="CreateCompanyModal"
    >
      <div class="CRM-modal-header">
        <div class="CRM-modal-header-text">Create Company</div>
        <a class="newCloseButton" @click="$modal.hide('CreateCompanyModal')">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </a>
      </div>
      <b-form class="latestGreyBox-9-9-2023">
        <div class="crm-input-container withoutIcon">
          <label>Name</label>
          <b-form-input placeholder="Name" />
        </div>
        <div class="crm-input-container withoutIcon">
          <label>Company domain name</label>
          <b-form-input placeholder="Company domain name" />
        </div>
        <div class="crm-input-container withoutIcon">
          <label>E-mail address</label>
          <b-form-input placeholder="E-mail address" />
        </div>

        <div class="crm-input-container CallType">
          <label>Select user</label>
          <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Select User">
            <template slot="no-options">There is no user</template>
            <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
            <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
          </v-select>
        </div>
        <div class="crm-input-container CallType">
          <label>Deals</label>
          <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Deals">
            <template slot="no-options">There is no user</template>
            <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
            <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
          </v-select>
        </div>
        <div class="crm-input-container CallType">
          <label>Contacts</label>
          <v-select ref="users" :options="voipusers" label="user.display_name" placeholder="Contacts">
            <template slot="no-options">There is no user</template>
            <template slot="option" slot-scope="option">{{ option.user.display_name }}</template>
            <template slot="selected-option" slot-scope="option">{{ option.user.display_name }}</template>
          </v-select>
        </div>

        <b-button type="submit" class="fullWidthDoneButton mt-20px">
          Submit
        </b-button>
      </b-form>
    </modal>

  </div>
</template>

<script>
// import axios from 'axios'
// import { VOIP_API } from '@/utils'
import { mapGetters, mapState } from 'vuex'
import { GET_CRM_USER } from '@/store/helper/getters'
// import required from 'vuelidate/lib/validators/required'
// import numeric from 'vuelidate/lib/validators/numeric'
import vSelect from "vue-select";
import "vue-select/dist/vue-select.css";
import { ctkDateTimePicker } from '@/mixin'

export default {
  name: 'Companies',
  mixins: [ctkDateTimePicker],
  components: {
    vSelect,
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      }
    }
  },
  computed: {
    ...mapGetters([
      GET_CRM_USER,
    ]),
    ...mapState({
      voipusers: (state) => Object.values(state.common.voipusers),
    }),
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>