var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 AddLocationModal AddNumberLocationModal max-height-100vh min-width-50percent-modal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "name": _vm.modalName,
      "scrollable": true
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addEmergencyLocation();
      }
    }
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Number location")]), _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        _vm.api.add_emergency_location.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1)]) : _c('div', {
    staticClass: "dialer-edit-header pb-0 flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between w-100 align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Number location")]), _c('div', {
    staticClass: "dialer-edit-actions d-flex"
  }, [_c('a', {
    staticClass: "newCloseButton mr-8px",
    on: {
      "click": function ($event) {
        _vm.api.add_emergency_location.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('p', [_vm._v("Add emergency location for a number")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mb-4 mt-20px"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": !!_vm.api.add_emergency_location.error_message
    }
  }, [_vm._v(_vm._s(_vm.api.add_emergency_location.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mb-0"
  }, [_c('label', [_vm._v("Name of location")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_emergency_location.label,
      expression: "forms.add_emergency_location.label"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "maxlength": _vm.$v.forms.add_emergency_location.label.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.add_emergency_location.label
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_emergency_location, "label", $event.target.value);
      }
    }
  }), _vm.$v.forms.add_emergency_location.label.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_emergency_location.label.required ? _c('span', [_vm._v("* Name of location is required")]) : !_vm.$v.forms.add_emergency_location.label.alphaNum ? _c('span', [_vm._v("* Name of location can be only alphabet, number and space")]) : !_vm.$v.forms.add_emergency_location.label.minLength ? _c('span', [_vm._v("* Name of location should be minimum " + _vm._s(_vm.$v.forms.add_emergency_location.label.$params.minLength.min) + " characters")]) : !_vm.$v.forms.add_emergency_location.label.maxLength ? _c('span', [_vm._v("* Name of location can be maximum " + _vm._s(_vm.$v.forms.add_emergency_location.label.$params.maxLength.max) + " characters")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Select country")]), _c('vb-select', {
    staticClass: "w-100 custom-dd-select",
    attrs: {
      "listClass": "select-country-options",
      "selected": _vm.forms.add_emergency_location.country,
      "defaultSelectedText": 'Select country',
      "options": _vm.countriesOptions
    },
    on: {
      "change": function ($event) {
        _vm.forms.add_emergency_location.country = $event;
      }
    }
  }), _vm.$v.forms.add_emergency_location.country.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_emergency_location.country.required ? _c('span', [_vm._v("* Country is required")]) : _vm._e()]) : _vm._e()], 1), _vm.forms.add_emergency_location.country == 'GB' ? _c('div', {
    staticClass: "d-flex justify-content-between align-items-center whiteBGinputWithGreyRoundedBorder mb-0 mt-20px w-100"
  }, [_c('label', [_vm._v("Manually enter the address")]), _c('b-check', {
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.add_emergency_location.send,
      "switch": ""
    },
    model: {
      value: _vm.forms.add_emergency_location.is_manual_address,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_emergency_location, "is_manual_address", $$v);
      },
      expression: "forms.add_emergency_location.is_manual_address"
    }
  })], 1) : _vm._e(), _c('div', {
    staticClass: "d-flex w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 w-50per-15px"
  }, [_c('label', [_vm._v("First name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_emergency_location.forename,
      expression: "forms.add_emergency_location.forename"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.add_emergency_location.send
    },
    domProps: {
      "value": _vm.forms.add_emergency_location.forename
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_emergency_location, "forename", $event.target.value);
      }
    }
  }), _vm.$v.forms.add_emergency_location.forename.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_emergency_location.forename.required ? _c('span', [_vm._v("* First name is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-0 w-50per-15px"
  }, [_c('label', [_vm._v("Last name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_emergency_location.surname,
      expression: "forms.add_emergency_location.surname"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.add_emergency_location.send
    },
    domProps: {
      "value": _vm.forms.add_emergency_location.surname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_emergency_location, "surname", $event.target.value);
      }
    }
  }), _vm.$v.forms.add_emergency_location.surname.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_emergency_location.surname.required ? _c('span', [_vm._v("* Last name is required")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    staticClass: "d-flex w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0"
  }, [_c('label', [_vm._v("House number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_emergency_location.house_name,
      expression: "forms.add_emergency_location.house_name"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.add_emergency_location.send
    },
    domProps: {
      "value": _vm.forms.add_emergency_location.house_name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_emergency_location, "house_name", $event.target.value);
      }
    }
  }), _vm.$v.forms.add_emergency_location.house_name.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_emergency_location.house_name.required ? _c('span', [_vm._v("* House number is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50per-15px mb-0"
  }, [_c('label', [_vm._v("Postal code")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_emergency_location.postal_code,
      expression: "forms.add_emergency_location.postal_code"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.add_emergency_location.send
    },
    domProps: {
      "value": _vm.forms.add_emergency_location.postal_code
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_emergency_location, "postal_code", $event.target.value);
      }
    }
  }), _vm.$v.forms.add_emergency_location.postal_code.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_emergency_location.postal_code.required ? _c('span', [_vm._v("* Postal code is required")]) : !_vm.$v.forms.add_emergency_location.postal_code.valid ? _c('span', [_vm._v("* Postal code is not valid")]) : _vm._e()]) : _vm.api.fetch_addresses.error_message ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* " + _vm._s(_vm.api.fetch_addresses.error_message))])]) : _vm._e(), _vm.api.fetch_addresses.send ? _c('vb-spinner', {
    attrs: {
      "variant": "primary"
    }
  }) : _vm.api.fetch_addresses.status == 1 ? _c('b-icon', {
    attrs: {
      "icon": "check2",
      "variant": "success"
    }
  }) : _vm._e(), _vm.api.fetch_addresses.status == 2 ? _c('b-icon', {
    attrs: {
      "icon": "x",
      "variant": "danger"
    }
  }) : _vm._e()], 1)]), !_vm.forms.add_emergency_location.is_manual_address ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0"
  }, [_c('label', [_vm._v("Select address")]), _c('b-select', {
    attrs: {
      "disabled": _vm.api.add_emergency_location.send
    },
    model: {
      value: _vm.forms.add_emergency_location.address,
      callback: function ($$v) {
        _vm.$set(_vm.forms.add_emergency_location, "address", $$v);
      },
      expression: "forms.add_emergency_location.address"
    }
  }, [_c('option', {
    attrs: {
      "value": ""
    }
  }, [_vm._v("Select address")]), _vm._l(_vm.addresses, function (address) {
    return _c('option', {
      key: address,
      domProps: {
        "value": address
      }
    }, [_vm._v(_vm._s(address.replace(/,/g, '').replace(/\s+/g, ' ').trim()))]);
  })], 2), _vm.$v.forms.add_emergency_location.address.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_emergency_location.address.required ? _c('span', [_vm._v("* Address is required")]) : _vm._e()]) : _vm._e()], 1) : _vm._e(), _vm.forms.add_emergency_location.is_manual_address ? _c('div', {
    staticClass: "d-flex w-100 justify-content-between mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-30per-15px mb-0"
  }, [_c('label', [_vm._v("City")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_emergency_location.city,
      expression: "forms.add_emergency_location.city"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.add_emergency_location.send
    },
    domProps: {
      "value": _vm.forms.add_emergency_location.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_emergency_location, "city", $event.target.value);
      }
    }
  }), _vm.$v.forms.add_emergency_location.city.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_emergency_location.city.required ? _c('span', [_vm._v("* City is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-70per-15px mb-0"
  }, [_c('label', [_vm._v("Street")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.add_emergency_location.street,
      expression: "forms.add_emergency_location.street"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.add_emergency_location.send
    },
    domProps: {
      "value": _vm.forms.add_emergency_location.street
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.add_emergency_location, "street", $event.target.value);
      }
    }
  }), _vm.$v.forms.add_emergency_location.street.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.add_emergency_location.street.required ? _c('span', [_vm._v("* Street is required")]) : _vm._e()]) : _vm._e()])]) : _vm._e()], 1), _c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.add_emergency_location.send
    }
  }, [_vm.api.add_emergency_location.send ? _c('vb-spinner') : [_vm._v("Save")]], 2)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }