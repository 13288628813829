var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_vm.conditions.change_pass ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.change_pass.send ? '' : _vm.conditions.change_pass = false;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Profile")]), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center",
    staticStyle: {
      "width": "74px"
    }
  })]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Profile")]), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center",
    staticStyle: {
      "width": "74px"
    }
  })]), _c('section', {
    staticClass: "dialer-profile-setting v2 dialer-settings-section"
  }, [_vm.conditions.change_pass ? [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.changepassword();
      }
    }
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnRight InputIosV2"
  }, [_c('label', [_vm._v("Current password")]), _c('div', {
    staticClass: "position-relative"
  }, [(_vm.password_show.current ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_pass.cpassword,
      expression: "forms.change_pass.cpassword"
    }],
    staticClass: "touched py-0",
    attrs: {
      "name": "currentpassword",
      "disabled": _vm.api.change_pass.send,
      "label": "Current password",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.forms.change_pass.cpassword) ? _vm._i(_vm.forms.change_pass.cpassword, null) > -1 : _vm.forms.change_pass.cpassword
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.forms.change_pass.cpassword,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.forms.change_pass, "cpassword", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.forms.change_pass, "cpassword", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.forms.change_pass, "cpassword", $$c);
        }
      }
    }
  }) : (_vm.password_show.current ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_pass.cpassword,
      expression: "forms.change_pass.cpassword"
    }],
    staticClass: "touched py-0",
    attrs: {
      "name": "currentpassword",
      "disabled": _vm.api.change_pass.send,
      "label": "Current password",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.forms.change_pass.cpassword, null)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.forms.change_pass, "cpassword", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_pass.cpassword,
      expression: "forms.change_pass.cpassword"
    }],
    staticClass: "touched py-0",
    attrs: {
      "name": "currentpassword",
      "disabled": _vm.api.change_pass.send,
      "label": "Current password",
      "type": _vm.password_show.current ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.forms.change_pass.cpassword
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.change_pass, "cpassword", $event.target.value);
      }
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "16.33px",
      "height": "14px",
      "icon": _vm.password_show.current ? 'profileSettings-eyeOff-icon' : 'profileSettings-eye-icon'
    },
    on: {
      "click": function ($event) {
        _vm.password_show.current = !_vm.password_show.current;
      }
    }
  })], 1), _vm.forms.change_pass.submitted && _vm.$v.forms.change_pass.cpassword.$invalid ? _c('small', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("*")]), !_vm.$v.forms.change_pass.cpassword.required ? _c('span', [_vm._v("Current Password is required")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnRight InputIosV2"
  }, [_c('label', [_vm._v("New password")]), _c('div', {
    staticClass: "position-relative"
  }, [(_vm.password_show.new ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_pass.password,
      expression: "forms.change_pass.password"
    }],
    staticClass: "py-0",
    attrs: {
      "disabled": _vm.api.change_pass.send,
      "name": "password",
      "label": "New password",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.forms.change_pass.password) ? _vm._i(_vm.forms.change_pass.password, null) > -1 : _vm.forms.change_pass.password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.forms.change_pass.password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.forms.change_pass, "password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.forms.change_pass, "password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.forms.change_pass, "password", $$c);
        }
      }
    }
  }) : (_vm.password_show.new ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_pass.password,
      expression: "forms.change_pass.password"
    }],
    staticClass: "py-0",
    attrs: {
      "disabled": _vm.api.change_pass.send,
      "name": "password",
      "label": "New password",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.forms.change_pass.password, null)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.forms.change_pass, "password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_pass.password,
      expression: "forms.change_pass.password"
    }],
    staticClass: "py-0",
    attrs: {
      "disabled": _vm.api.change_pass.send,
      "name": "password",
      "label": "New password",
      "type": _vm.password_show.new ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.forms.change_pass.password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.change_pass, "password", $event.target.value);
      }
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "16.33px",
      "height": "14px",
      "icon": _vm.password_show.new ? 'profileSettings-eyeOff-icon' : 'profileSettings-eye-icon'
    },
    on: {
      "click": function ($event) {
        _vm.password_show.new = !_vm.password_show.new;
      }
    }
  })], 1), _vm.forms.change_pass.submitted && _vm.$v.forms.change_pass.password.$invalid ? _c('small', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("*")]), !_vm.$v.forms.change_pass.password.required ? _c('span', [_vm._v("New Password is required")]) : !_vm.$v.forms.change_pass.password.minLength ? _c('span', [_vm._v("New Password is minimum 8 character required")]) : !_vm.$v.forms.change_pass.password.alalphabet ? _c('span', [_vm._v("New Password must contain alphabet")]) : !_vm.$v.forms.change_pass.password.numbers ? _c('span', [_vm._v("New Password must contain numbers")]) : _vm._e()]) : _vm._e()])])]), _c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v(" Must be least 8 characters and contain letters and numbers ")]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "disabled": _vm.api.change_pass.send
    }
  }, [_vm.api.change_pass.send ? _c('vb-spinner') : [_vm._v("Save Changes")]], 2)])] : [_c('div', {
    staticClass: "profile-image-section-container forIos mt-32px"
  }, [_c('div', {
    staticClass: "profile-image-section",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('UserProfileImageUploader', {
          type: 'USER',
          accountcode: _vm.getCurrentUser.account,
          current_image: _vm.getCurrentUser.profileImg
        });
      }
    }
  }, [_c('div', {
    staticClass: "profile-img"
  }, [_c('img', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    },
    attrs: {
      "id": "user-profile-image-updated",
      "src": _vm.getCurrentUser.profileImg,
      "alt": _vm.getCurrentUser.display_name
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _c('div', {
    staticClass: "BiggestMainHeading mt-22px"
  }, [_vm._v(_vm._s(_vm.getCurrentUser.display_name))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Email address")]), _c('div', [_c('input', {
    staticClass: "py-0",
    attrs: {
      "type": "email",
      "disabled": "",
      "name": "email",
      "label": "Email address"
    },
    domProps: {
      "value": _vm.getCurrentUser.email
    }
  })])])]), _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnRight InputIosV2"
  }, [_c('label', [_vm._v("Password")]), _c('div', [_c('input', {
    staticClass: "py-0",
    attrs: {
      "type": "password",
      "name": "displayPassword",
      "label": "Password",
      "placeholder": "••••••••"
    },
    on: {
      "focus": function ($event) {
        _vm.conditions.change_pass = true;
      }
    }
  })])])])])]), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Two Factor Authentication")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-check', {
    ref: "two_factor_auth_checkbox",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.update_two_factor_auth.send,
      "switch": "",
      "checked": _vm.tfaEnabled
    },
    on: {
      "change": function ($event) {
        return _vm.changeTwoFactorAuth($event ? 'yes' : 'no');
      }
    }
  })], 1)]), _vm.tfaEnabled ? [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Select")]), _c('b-dropdown', {
    ref: "dropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "d-flex align-items-center justify-content-between p-0 w-100"
        }, [_c('span', [_vm.selected.two_fact_auth === 'yes' ? [_vm._v("All Devices")] : _vm.selected.two_fact_auth === 'non_trusted' ? [_vm._v("Un-Trusted Devices")] : [_vm._v("Select Options")]], 2), _c('vb-icon', {
          staticClass: "rightFacingArrow down",
          attrs: {
            "icon": "rightFacingArrow-IOSIcon",
            "width": "16.642",
            "height": "15.275"
          }
        })], 1)];
      },
      proxy: true
    }], null, false, 3550405846)
  }, [_c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.api.update_two_factor_auth.send
    },
    on: {
      "click": function ($event) {
        return _vm.changeTwoFactorAuth('yes');
      }
    }
  }, [_vm._v(" All Devices ")]), _c('b-dropdown-item', {
    attrs: {
      "disabled": _vm.api.update_two_factor_auth.send
    },
    on: {
      "click": function ($event) {
        return _vm.changeTwoFactorAuth('non_trusted');
      }
    }
  }, [_vm._v(" Un-Trusted Devices ")])], 1)], 1)])] : _vm._e()], 2), _c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Teams")]), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, _vm._l(_vm.teams, function (team) {
    return _c('div', {
      key: team.real_id,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": team.real_id
      }
    })], 1);
  }), 0), _vm.callQueues.length > 0 ? [_c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Call Queues")]), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, _vm._l(_vm.callQueues, function (call_queue) {
    return _c('div', {
      key: call_queue.voipaccount,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": call_queue.voipaccount
      }
    })], 1);
  }), 0)] : _vm._e(), _c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Organizations")]), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, _vm._l(_vm.filterOrganizations, function (organization) {
    return _c('div', {
      key: organization.id,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "basicIOSIcon mr-16px"
    }, [_c('img', {
      attrs: {
        "src": organization.id === _vm.$store.getters.getCurrentUser.selected_organization_id ? _vm.$store.getters.getCurrentUser.companyLogo : `${_vm.organizationimagespath}${organization.logo}` || ''
      },
      on: {
        "error": function ($event) {
          $event.target.src = require('@/assets/images/user-male-icon.svg');
        }
      }
    })]), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(" " + _vm._s(`${organization.label} (${organization.uid})`) + " ")])])]), organization.id === _vm.$store.getters.getCurrentUser.selected_organization_id ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0), _c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Get a free credit")]), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" Reference Code ")]), _c('div', {
    staticClass: "linkTypeText"
  }, [_vm._v(" " + _vm._s(_vm.link) + " ")])])]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)])]], 2), _c('ProfileImageUploaderModal', {
    attrs: {
      "modalName": 'UserProfileImageUploader'
    },
    on: {
      "image-uploaded": function ($event) {
        '';
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }