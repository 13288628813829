<template>
  <modal class="dialer_animation center-zoomIn-popup  dialer-notificationModal KeyboardShortcutsModal" name="KeyboardShortcutsModal">
    <div class="dialer-edit-header pt-0 d-flex align-items-center justify-content-between w-100">
      <h2 class="dialer-edit-title newer mb-0">Keyboard shortcuts modal</h2>
      <div class="dialer-edit-actions">
        <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('KeyboardShortcutsModal')" />
        <a v-else class="newDoneButton" @click="$modal.hide('KeyboardShortcutsModal')">
          <vb-icon icon="squared-done-icon" height="38px" width="38px" />
          <span class="newDoneButton-textPart">Done</span>
        </a>
      </div>
    </div>
    <div class="mt-20px latestGreyBox-9-9-2023">
      <ul class="list-unstyled whiteBoxWithBorderInsideGreyBox">
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">Open shortcuts modal</div>
          <div class="latestGreyBox-heading-main">Ctrl + /</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">home</div>
          <div class="latestGreyBox-heading-main">Alt + 1</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">chat</div>
          <div class="latestGreyBox-heading-main">Alt + 2</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">analytics</div>
          <div class="latestGreyBox-heading-main">Alt + 3</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">call Dashboard</div>
          <div class="latestGreyBox-heading-main">Alt + 4</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">schedule</div>
          <div class="latestGreyBox-heading-main">Alt + 5</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">settings</div>
          <div class="latestGreyBox-heading-main">Alt + 6</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">timeline</div>
          <div class="latestGreyBox-heading-main">Alt + 7</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">Open SpeedDials</div>
          <div class="latestGreyBox-heading-main">Ctrl + shift + 1</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">Close SpeedDials</div>
          <div class="latestGreyBox-heading-main">Ctrl + shift + 1</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">Open Users</div>
          <div class="latestGreyBox-heading-main">Ctrl + shift + 2</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">Close Users</div>
          <div class="latestGreyBox-heading-main">Ctrl + shift + 2</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">Open Departments</div>
          <div class="latestGreyBox-heading-main">Ctrl + shift + 3</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">Close Departments</div>
          <div class="latestGreyBox-heading-main">Ctrl + shift + 3</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">Open Addressbooks</div>
          <div class="latestGreyBox-heading-main">Ctrl + shift + 4</div>
        </li>
        <li class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
          <div class="latestGreyBox-heading-main">Close Addressbooks</div>
          <div class="latestGreyBox-heading-main">Ctrl + shift + 4</div>
        </li>
      </ul>
    </div>
  </modal>
</template>

<script>
export default {
  name: "KeyboardShortcutsModal",
};
</script>

<style lang="scss">
.KeyboardShortcutsModal {
  // background: red;
  z-index: 20000;
}
</style>
