<template>
  <modal 
    :name="modalName" 
    class="changeOwnershipModal center-zoomIn-transition v2 m-w-500 "
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <div class="w-100">
      <!-- <button :disabled="api.change_owner.send || api.verify_otp.send" type="button" @click="back()">back</button> -->
      <template v-if="conditions.screen==screens.email.value">
        <div class="d-flex align-items-start justify-content-between w-100">
          <div class="d-flex flex-column">
            <h2 class="dialer-edit-title">Owner details</h2>
            <p>You are the owner of this organization.</p>
          </div>
          <button @click="$modal.hide(modalName)" class="newCloseButton" type="button">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px w-100">
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <label>Email</label>
            <input class="w-100" disabled :value="email" />
          </div>
        </div>
        <div class="mt-20px w-100">
          <button @click="conditions.screen=screens.select_owner.value" class="fullWidthDoneButton" type="button">
            Change ownership
          </button>
        </div>
      </template>
      <template v-if="conditions.screen==screens.select_owner.value">
        <div class="d-flex align-items-start justify-content-between w-100">
          <div class="d-flex flex-column">
            <h2 class="dialer-edit-title">Transfer ownership</h2>
            <p>Current ownership belongs to you</p>
          </div>
          <button @click="$modal.hide(modalName)" class="newCloseButton" type="button">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px w-100">
          <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
            <label>Select a user to transfer ownership to</label>
            <div>you can only transfer your ownership to a user with a valid email</div>
            <b-dropdown >
              <template #button-content>
                <span class="d-flex align-items-center justify-content-between w-100 p-0">
                  <div class="mr-8px">
                    <Info 
                      v-if="forms.change_owner.account" 
                      :id="forms.change_owner.account" 
                      :is_blf="false" 
                    />
                    <template v-else>Select an account</template>
                  </div>
                  <b-icon icon="chevron-down" />
                </span>
              </template>
              <b-dropdown-item 
                v-for="user in invitedUsers" 
                :key="user.voipaccount" 
                @click="forms.change_owner.account = user.voipaccount">
                <div class="d-flex w-fit-content">
                  <Info :id="user.voipaccount" :is_blf="false" />
                </div>
              </b-dropdown-item>
              <b-dropdown-item v-if="invitedUsers.length==0">
                <div class="d-flex w-fit-content">
                  There is no record
                </div>
              </b-dropdown-item>
            </b-dropdown>
            <p v-if="$v.forms.change_owner.account.$invalid" class="text text-danger animated bounceIntop">
              <span v-if="!$v.forms.change_owner.account.required">Required</span>
            </p>
          </div>
        </div>
        <div class="mt-20px w-100 d-flex justify-content-between">
          <button @click="$modal.hide(modalName)" class="darkGreyButton mr-1 w-50" type="button">Cancel</button>
          <button 
            @click="
              $v.forms.change_owner.account.$touch();
              $v.forms.change_owner.account.$invalid ? 
                '' :
                conditions.screen=screens.password.value
            " 
            class="newButton ml-1 w-50" 
            type="button"
          >
            <vb-icon icon="squared-singleUser-icon" height="38px" width="38px" />
            <span class="newButton-textPart">Transfer ownership</span>
          </button>
        </div>
      </template>
      <template v-if="conditions.screen==screens.password.value">
        <div class="d-flex align-items-start justify-content-between w-100">
          <div class="d-flex flex-column">
            <h2 class="dialer-edit-title">Owner details</h2>
            <p>You are the owner of this organization.</p>
          </div>
          <button :disabled="api.change_owner.send" @click="$modal.hide(modalName)" class="newCloseButton" type="button">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px w-100">
          <div class="whiteBGinputWithGreyRoundedBorder withRightIconOnly w-100">
            <label>Enter Password</label>
            <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
              <input 
                class="w-100" 
                :type="forms.change_owner.password_show ? 'text' : 'password'"
                v-model="forms.change_owner.password"
                :disabled="api.change_owner.send"
              />
              <b-icon 
                @click="api.change_owner.send ? '' : forms.change_owner.password_show=!forms.change_owner.password_show" 
                :icon="`${forms.change_owner.password_show?'eye-slash-fill':'eye-fill'}`" 
              />
            </div>
          </div>
          <p v-if="$v.forms.change_owner.password.$invalid" class="text text-danger animated bounceIntop">
            <span v-if="!$v.forms.change_owner.password.required">Required</span>
          </p>
        </div>
        <div class="mt-20px w-100">
          <button :disabled="api.change_owner.send" @click="changePassword()" class="fullWidthDoneButton" type="button">
            <vb-spinner v-if="api.change_owner.send" />
            <template v-else>Change ownership</template>
          </button>
        </div>
      </template>
      <template v-if="conditions.screen==screens.otp.value">
        <div class="d-flex align-items-start justify-content-between w-100">
          <div class="d-flex flex-column pr-2">
            <h2 class="dialer-edit-title">OTP verification - current & new owners</h2>
            <p>
              To securely transfer ownership, enter the one-time passwords (OTPs) sent 
              to the emails associated with the current and new owner's accounts. Verify 
              both identities to proceed.
            </p>
          </div>
          <button :disabled="api.verify_otp.send" @click="$modal.hide(modalName)" class="newCloseButton" type="button">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px w-100">
          <div class="latestGreyBox-descriptionText-14px w-100 mt-0">
            Enter OTP sent to <Info :id="getCurrentUser.account" :is_blf="false" :only_name="true" class="newBlue" />
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-10px">
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column">
              <div class="whiteBGinputWithGreyRoundedBorder w-100">
                <label>Enter the 6-digit code:</label>
                <vb-pin-code 
                  :numberslength="$v.forms.verify_otp.cowner_pincode.$params.maxLength.max"
                  :value="forms.verify_otp.cowner_pincode" 
                  @change="forms.verify_otp.cowner_pincode = $event" 
                  :disabled="api.verify_otp.send"
                />
                <p v-if="forms.verify_otp.submitted && $v.forms.verify_otp.cowner_pincode.$invalid" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.verify_otp.cowner_pincode.required">Pincode is required</span>
                  <span v-else-if="!$v.forms.verify_otp.cowner_pincode.minLength">Pincode can be minimum {{$v.forms.verify_otp.cowner_pincode.$params.minLength.min}} character</span>
                  <span v-else-if="!$v.forms.verify_otp.cowner_pincode.maxLength">Pincode should be maximum {{$v.forms.verify_otp.cowner_pincode.$params.maxLength.max}} character</span>
                </p>
              </div>
            </div>
          </div>
          <div class="latestGreyBox-descriptionText-14px w-100 mt-4">
            Enter OTP sent to <Info :id="forms.change_owner.account" :is_blf="false" :only_name="true" class="newBlue" />
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox mt-10px">
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column">
              <div class="whiteBGinputWithGreyRoundedBorder w-100">
                <label>Enter the 6-digit code:</label>
                <vb-pin-code 
                  :numberslength="$v.forms.verify_otp.new_owner_pincode.$params.maxLength.max"
                  :value="forms.verify_otp.new_owner_pincode" 
                  @change="forms.verify_otp.new_owner_pincode = $event" 
                  :disabled="api.verify_otp.send"
                />
                <p v-if="forms.verify_otp.submitted && $v.forms.verify_otp.new_owner_pincode.$invalid" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.verify_otp.new_owner_pincode.required">Pincode is required</span>
                  <span v-else-if="!$v.forms.verify_otp.new_owner_pincode.minLength">Pincode can be minimum {{$v.forms.verify_otp.new_owner_pincode.$params.minLength.min}} character</span>
                  <span v-else-if="!$v.forms.verify_otp.new_owner_pincode.maxLength">Pincode should be maximum {{$v.forms.verify_otp.new_owner_pincode.$params.maxLength.max}} character</span>
                </p>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-20px w-100">
          <button :disabled="api.verify_otp.send" @click="verifyPincode()" class="fullWidthDoneButton" type="button">
            <vb-spinner v-if="api.verify_otp.send" />
            <template v-else>Verify</template>
          </button>
        </div>
      </template>
      <template v-if="conditions.screen==screens.success.value">
        <div class="w-100">
          <div class="thumbCongrtulations-container">
            <img :src="require('@/assets/images/thumbCongrtulations.svg')" />
            <h6>Ownership successfully transferred</h6>
            <p>
              Congratulations! The ownership of the organization has been 
              successfully transferred to Frank Fahad.
            </p>
          </div>
        </div>
      </template>
    </div>
  </modal>
</template>

<script>
import { $fn, VOIP_API } from '@/utils'
import required from 'vuelidate/lib/validators/required'
import { mapGetters, mapState } from 'vuex'
import minLength from 'vuelidate/lib/validators/minLength'
import maxLength from 'vuelidate/lib/validators/maxLength'
import Info from '../Lists/Info.vue'
const screens = {
  email: {
    value: 'email',
  },
  select_owner: {
    value: 'select_owner',
  },
  password: {
    value: 'password',
  },
  otp: {
    value: 'otp',
  },
  success: {
    value: 'success',
  },
}
export default {
  components: { Info },
  name: 'ChangeOwnerShipModal',
  props: {
    modalName: {
      type: String,
      default: 'ChangeOwnerShip',
    },
  },
  data() {
    return {
      forms: {
        change_owner: this.$helperFunction.formInstance({
          data: {
            account: '',
            password: '',
            password_show: false,
          }
        }),
        verify_otp: this.$helperFunction.formInstance({
          data: {
            cowner_pincode: '',
            new_owner_pincode: '',
          }
        }),
      },
      api: {
        change_owner: this.$helperFunction.apiInstance(),
        verify_otp: this.$helperFunction.apiInstance(),
      },
      conditions: {
        screen: screens.email.value,
      },
      data: {
        accountcode: '',
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapState({
      voipusers: (state) => state.common.voipusers
    }),
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsersAlises',
      'getVoipUsers',
    ]),
    screens(){ return screens },
    voipuser(){ return this.getVoipUsersAlises[this.data.accountcode] },
    email(){
      const email = this.voipuser?.user?.email
      const email2 = this.voipuser?.user?.email2
      const ac_user = this.voipuser?.user?.ac_user
      const current_user_email = this.getCurrentUser.email
      return ((email2 || email) === current_user_email ? ac_user : (ac_user != email) ? ac_user : email2 || email || ac_user) ?? ''
    },
    selectedAccountVoipuser(){ return this.getVoipUsersAlises[this.forms.change_owner.account] },
    selectedAccountEmail(){
      const email = this.selectedAccountVoipuser?.user?.email
      const email2 = this.selectedAccountVoipuser?.user?.email2
      const ac_user = this.selectedAccountVoipuser?.user?.ac_user
      const current_user_email = this.getCurrentUser.email
      return ((email2 || email) === current_user_email ? ac_user : (ac_user != email) ? ac_user : email2 || email || ac_user) ?? ''
    },
    invitedUsers(){
      const users = Object.values(this.voipusers)
      return users.filter(i=>i?.user?.user_type=='invite')
    },
  },
  validations: {
    forms: {
      change_owner: {
        account: {
          required: required,
        },
        password: {
          required: required,
        },
      },
      verify_otp: {
        cowner_pincode: {
          required: required,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
        new_owner_pincode: {
          required: required,
          minLength: minLength(6),
          maxLength: maxLength(6),
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode=event?.params?.accountcode??''
    },
    onBeforeClose(){
      // TODO - on close modal
      this.data.accountcode=''
      this.conditions.screen=screens.email.value
      this.api.change_owner.reset()
      this.api.verify_otp.reset()
      this.forms.change_owner.reset()
      this.forms.verify_otp.reset()
    },
    back() {
      if(this.conditions.screen==screens.email.value) return;
      else if(this.conditions.screen==screens.select_owner.value) this.conditions.screen=screens.email.value
      else if(this.conditions.screen==screens.password.value) this.conditions.screen=screens.select_owner.value
      else if(this.conditions.screen==screens.otp.value) this.conditions.screen=screens.password.value
      else if(this.conditions.screen==screens.success.value) this.conditions.screen=screens.otp.value
    },
    async changePassword(){
      this.forms.change_owner.submitted=true
      this.$v.forms.change_owner.$touch()
      if(this.$v.forms.change_owner.$invalid || this.api.change_owner.send) return;
      try {
        this.api.change_owner.send=true
        await VOIP_API.endpoints.users.changeOwner({
          cowner: this.email,
          new_owner: this.selectedAccountEmail,
          password: this.forms.change_owner.password,
        })
        this.conditions.screen=screens.otp.value;
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.forms.change_owner.submitted=false
        this.api.change_owner.send=false
      }
    },
    async verifyPincode(){
      this.forms.verify_otp.submitted=true
      this.$v.forms.verify_otp.$touch()
      if(this.$v.forms.verify_otp.$invalid || this.api.verify_otp.send) return;
      try {
        this.api.verify_otp.send=true
        await VOIP_API.endpoints.users.changeOwner({
          cowner: this.email,
          new_owner: this.selectedAccountEmail,
          password: this.forms.change_owner.password,
          cpincode: this.forms.verify_otp.cowner_pincode,
          npincode: this.forms.verify_otp.new_owner_pincode,
        })
        this.conditions.screen=screens.success.value;
        await this.$nextTick()
        await $fn.sleep(3*1000)
        this.$modal.hide(this.modalName)
        this.$emit('changed')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.forms.verify_otp.submitted=false
        this.api.verify_otp.send=false
      }
    },
  },
}
</script>

<style>

</style>