var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.channel_id ? _c('div', {
    staticClass: "chat-box",
    attrs: {
      "tha": _vm.type
    }
  }, [_c('div', {
    staticClass: "allow_scroll",
    style: {
      scrollBehavior: ' auto'
    },
    attrs: {
      "id": "scroll-end"
    },
    on: {
      "scroll": _vm.onScroll
    }
  }, [_c('div', {
    class: ['pr-4 chat-box-bill', {
      pinned: _vm.type === 'pinned'
    }, _vm.messages.length == 1 ? 'onlyOne' : '']
  }, [_vm._l(_vm.messages, function (message, index) {
    return _c('div', {
      key: message.id
    }, [_vm.channelData.unread === message.user_msg_counter && _vm.type !== 'pinned' ? _c('div', {
      staticClass: "message-date new-message"
    }, [_c('span', [_vm._v("Unread Message")])]) : _vm._e(), _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: message.is_date_show || _vm.type === 'pinned',
        expression: "message.is_date_show || type === 'pinned'"
      }],
      staticClass: "message-date"
    }, [_c('span', [_vm._v(_vm._s(_vm._f("date_current")(message.create_at)))])]), _c('Message', {
      ref: "single_message",
      refInFor: true,
      attrs: {
        "rapidlyMessage": _vm._f("rapidlyMessage")(index, _vm.messages),
        "random_id": _vm.random_id,
        "post": message,
        "channel_id": _vm.channel_id,
        "channel": _vm.channel,
        "data": _vm.data,
        "channelData": _vm.channelData,
        "members": _vm.members,
        "type": _vm.type,
        "name": _vm.name,
        "image": _vm.image
      }
    })], 1);
  }), _c('div', {
    staticStyle: {
      "height": "10px"
    }
  })], 2), _vm.scroll_bottom ? _c('div', {
    staticClass: "scrollToBottomButton",
    on: {
      "click": function ($event) {
        return _vm.scrollEnd();
      }
    }
  }, [_c('vb-svg', {
    staticClass: "dialer-downArrow",
    attrs: {
      "name": "dialer-downArrow-icon",
      "width": "14",
      "height": "8.001",
      "viewBox": "0 0 14 8.001",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1) : _vm._e()])]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }