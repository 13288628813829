<template>
		<div class="right-calender">
		<div class="top-main-calender scrollow1">
			<section class="sec-common">
				<div class="doc-top-button">
					<h5 class="emp-profile-heading">Documents</h5>
					<div class="d-flex justify-content-end">
						<button class="btn btn-primarymike  mr-3" @click="$modal.show('HRMAddDocumentModal')" >
              <svg xmlns="http://www.w3.org/2000/svg" width="13.089" height="16.536" viewBox="0 0 13.089 16.536" class="primarymike-svg">
                <path id="Path_42470" data-name="Path 42470" d="M9.665,0h.291a.76.76,0,0,1,.551.855c-.014.229,0,.458,0,.7h.635A1.9,1.9,0,0,1,13.089,3.5q0,5.546,0,11.093a1.893,1.893,0,0,1-1.951,1.945q-4.594,0-9.187,0A1.886,1.886,0,0,1,0,14.592Q0,9.046,0,3.5A1.889,1.889,0,0,1,1.961,1.55h.624V.7A.649.649,0,0,1,2.849.149,2.26,2.26,0,0,1,3.109,0h.324A2.131,2.131,0,0,1,3.68.139a.662.662,0,0,1,.277.57c0,.279,0,.556,0,.83h1.9c0-.235.011-.461,0-.686A.759.759,0,0,1,6.4,0h.291a.782.782,0,0,1,.543.876c-.014.218,0,.438,0,.666h1.89c0-.228.011-.447,0-.666A.781.781,0,0,1,9.665,0M6.886,8.268q-1.7,0-3.4,0a.69.69,0,1,0,0,1.378c.587.006,1.174,0,1.76,0q2.5,0,5,0a.743.743,0,0,0,.585-.22.689.689,0,0,0-.506-1.161c-1.147,0-2.293,0-3.439,0m0,4.134h.935c.834,0,1.667,0,2.5,0a.682.682,0,0,0,.629-.975.7.7,0,0,0-.7-.4q-3.372,0-6.746,0a1.051,1.051,0,0,0-.209.02.652.652,0,0,0-.516.458.685.685,0,0,0,.678.9c1.139,0,2.281,0,3.422,0M5.169,6.885c.587,0,1.172.008,1.759,0a.682.682,0,0,0,0-1.362c-1.174-.008-2.346-.009-3.518,0a.782.782,0,0,0-.421.169.647.647,0,0,0-.194.745.666.666,0,0,0,.615.446c.587.006,1.172,0,1.759,0" transform="translate(0)" fill="#fff"/>
              </svg>
              Add Notes
						</button>
						<button class="btn btn-activity  mr-3">
              Create from template
						</button>
						<button class="btn btn-activity">
              Create signable document
						</button>
					</div>
				</div>
        <vb-table :tableDesign="1" class="doc-table-width mar-top-hrm120">
          <template slot="header">
            <h5 class="commonth docs-firsttd doc-filename">File Name</h5>
            <h5 class="commonth docs-secondtd">Date</h5>
            <h5 class="commonth docs-thirdtd">Created By</h5>
            <h5 class="commonth docs-fourtd">Shared With</h5>
            <h5 class="commonth docs-fivetd col-last-notes">Action</h5>
          </template>
          <template slot="body">
            <tr>
              <td class="docs-firsttd col-first-notes">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/Group 18124.png" alt="logo"  class=""/>
                  <h5 class="doc-table-comoanyprofile-head">Company profile.pdf</h5>
                </div>
              </td>
              <td class="docs-secondtd">
                23/07/2022
              </td>
              <td class="docs-thirdtd">
                <div class=" d-flex justify-content-start align-items-center ">
                  <img src="../../../assets/images/credit-card/hrmprofiletest.png" alt="logo"  class="dom-img-share"/>
                  <h5 class="right-side-calender-avatar-text">Amber West</h5>
                </div>
              </td>
              <td class="docs-fourtd">
                Everyone
              </td>
              <td class="docs-fivetd">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
          </template>
        </vb-table>
			</section>
		</div>
		<HRMAddDocumentModal/>
	</div>
</template>
<script>
import HRMAddDocumentModal from "../Modals/HRMAddDocumentModal.vue"

export default {
  name: 'DocumentsHRM',
   components:{
	HRMAddDocumentModal,
  },
}
</script>

<style>

</style>