<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.account_detail.send || api.invoice_detail.send ? '' : $modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Invoice</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>

      <div class="innerModalIos">
        <div class="lg-mar-bottom">

          <vb-loading v-if="api.account_detail.send || api.invoice_detail.send" />
          <template v-else>
            <div class="d-flex justify-content-end pb-4">
            </div>
            <div class=" allow_scroll_2" style="height:calc(100vh - 141px)">
              <div class="px-4 py-4">
              <!-- Printable PDF Code Start -->
              <div :id="`invoice-download`" style="padding:0mm !important;margin:0mm !important;box-shadow: 0 2px 10px -2px rgb(27 33 58 / 40%);">
                <div class="pdf-page-container">
                  <div class="pdf-page-header">
                    <div class="d-flex justify-content-between align-items-center">
                      <div class="invoice-title">
                        <div class="invoice-title-icon">
                          <b-icon icon="cpu"/>
                        </div>
                        <h4 class="invoice-title-heading">Invoice</h4>
                      </div>
                      <div class="our-company-detail-invoice">
                        <h4 class="company-name">{{ response.account_detail | get_property('companyname') }}</h4>
                        <span class="header-small-text">{{ response.account_detail | get_property('address') }}</span>
                        <span class="header-small-text">{{ response.account_detail | get_property('city') }}</span>
                        <span class="header-small-text">{{ response.account_detail | get_property('countryname') }}</span>
                        <span class="header-small-text">{{ response.account_detail | get_property('postcode') }}</span>
                      </div>
                    </div>
                  </div>
                  <div class="pdf-page-body">
                    <div class="w-100 d-flex justify-content-between align-items-center">
                      <div class="bill-to-container">
                        <span class="bill-to-heading">Bill To:</span>
                        <span class="bill-to-company">{{ response.account_detail | get_property('companyname') }}</span>
                        <span class="bill-to-text mt-2">{{ response.account_detail | get_property('address') }}</span>
                        <span class="bill-to-text">{{ response.account_detail | get_property('city') }}</span>
                        <span class="bill-to-text">{{ response.account_detail | get_property('countryname') }}</span>
                        <span class="bill-to-text">{{ response.account_detail | get_property('postcode') }}</span>
                      </div>
                      <div class="bill-to-container align-items-end">
                        <span class="bill-to-heading">Invoice #</span>
                        <span class="bill-to-text">{{ response.invoice | get_property('invoiceid') | invoicename }}</span>
                        <span class="bill-to-heading mt-1">Date</span>
                        <span class="bill-to-text">{{ response.invoice | get_property('date') }}</span>
                        <span class="bill-to-heading mt-1">Invoice Due Date</span>
                        <span class="bill-to-text">{{ response.invoice | get_property('duedate') }}</span>
                      </div>
                    </div>
                    <div class="dialer-invoice-preview mt-4">
                      <table class="dialer-invoice-table">
                        <tbody>
                          <tr v-for="item in items" :key="item.id" class="dialer-line-item">
                            <td  class="bold">{{ item.description }}</td>
                            <td class="price">£{{ item.amount }}</td>
                          </tr>
                        </tbody>
                      </table>
                    </div>
                    <div class="d-flex mt-4 justify-content-end">
                      <span class="mr-5 color-black font-weight-700 font-18">Subtotal : </span>
                      <span class="color-black font-weight-700 font-18">£{{ response.invoice | get_property('subtotal',0) | to_fixed }}</span>
                    </div>
                  </div>
                  <div class="pdf-page-footer d-flex">
                    <div class="d-flex flex-column pdf-page-footer-left">
                      <span class="mr-5 color-black font-weight-600 font-16">Notice :- </span>
                      <span class="color-black font-weight-600 font-14 opacity-0-8">
                        {{ response.invoice | get_property('notes') }}
                      </span>
                    </div>
                    <div class="d-flex flex-column align-items-end pdf-page-footer-right">
                      <span class="header-small-text text-uppercase ">Total</span>
                      <span class="color-white font-weight-600 font-28">£{{ response.invoice | get_property('total',0) | to_fixed }}</span>
                    </div>
                  </div>
                </div>
              </div>
              <!-- Printable PDF Code End -->
            </div>
            </div>
          </template>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
// import html2pdf from 'html2pdf.js'
import { $fn, VOIP_API } from '@/utils'
// LOGGER,
export default {
  name: 'InvoicePDFModal',
  props: {
    modalName: {
      type: String,
      default: 'InvoicePDF',
    },
    id: {
      type: String,
      default: ()=>{return $fn.makeid(10)},
    },
  },
  data(){
    return {
      data: {
        invoiceid: '',
      },
      response: {
        invoice: {},
        account_detail: {},
      },
      api: {
        account_detail: this.$helperFunction.apiInstance(),
        invoice_detail: this.$helperFunction.apiInstance(),
        download: this.$helperFunction.apiInstance(),
      },
    }
  },
  computed: {
    items(){ return this.response.invoice?.items?.item ?? [] },
  },
  filters: {
    invoicename(value){ return `VB-${value}` },
  },
  methods: {
    onBeforeOpen(event){
      this.data.invoiceid = event?.params?.id || ''
      this.fetchaccountdetail()
      this.fetchinvoicedetail()
    },
    onBeforeClose(){
      this.data.invoiceid = ''
      this.response.invoice = {}
      this.response.account_detail = {}
      this.api.download.reset()
      this.api.account_detail.reset()
      this.api.invoice_detail.reset()
    },
    downloadPDF(){
      // let vm = this
      // console.log('run')
      // if(vm.api.download.send) return;
      // vm.api.download.send=true
      // document.querySelectorAll('[data-html2canvas-ignore]').forEach(b=>b.removeAttribute('data-html2canvas-ignore'));
      // var element = document.getElementById(`invoice-download`);
      // html2pdf()
      // .from(element)
      // .set({
      //   margin: 0,
      //   filename: `VB-${vm.data.invoiceid}`,
      //   image: { 
      //     type: 'jpeg', 
      //     quality: 0.98,
      //   },
      //   enableLinks: true,
      //   html2canvas: { 
      //     scale: 2, 
      //   },
      //   jsPDF: { 
      //     unit: 'in', 
      //     format: 'letter', 
      //     orientation: 'portrait', 
      //   },
      //   pagebreak: { 
      //     mode: ['avoid-all', 'css', 'legacy'], 
      //   },
      // })
      // .toContainer()
      // .toCanvas()
      // .toImg()
      // .toPdf()
      // .save()
      // .then(()=>{
      //   vm.$modal.hide(vm.modalName)
      // })
      // .catch((ex)=>{
      //   LOGGER.log('ex',ex)
      //   vm.appNotify({
      //     message: VOIP_API.defaultErrorMessage,
      //     type: 'error'
      //   })
      //   const html_to_pdf_overlay_els = document.getElementsByClassName('html2pdf__overlay') ?? []
      //   for (let index = 0; index < html_to_pdf_overlay_els.length; index++) {
      //     const element = html_to_pdf_overlay_els[index];
      //     if(element) element.parentNode.removeChild(element);
      //   }
      // })
      // .finally(()=>{
      //   vm.api.download.send=false
      // });
    },
    fetchaccountdetail(){
      let vm = this
      vm.api.account_detail.send=true
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data }) => {
        vm.response.account_detail = data?.user_detail ?? {}
      })
      .finally(()=>{
        vm.api.account_detail.send=false
      })
    },
    fetchinvoicedetail(){
      let vm = this;
      vm.api.invoice_detail.send=true
      VOIP_API.endpoints.users.invoicedetail({
        id: vm.data.invoiceid,
        uid: vm.$store.getters.getCurrentUser?.id,
      })
      .then(({ data })=>{
        vm.response.invoice=data?.detail ?? {}
      })
      .finally(()=>{
        vm.api.invoice_detail.send=false
      });
    },
  },
}
</script>

<style>

</style>