<template>
  <modal
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"   
    :name="modalName" 
    @before-close="onBeforeClose()" 
    @before-open="onBeforeOpen($event)"
  >
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Pickup Groups</div>
      <div class="plus-IOSIcon-container" @click="checkPermission(getUserPermissions.call_pickup,()=>$modal.show('AddPickUpGroupModal'))">
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992"/>
      </div>
    </div>
    <div class="innerModalIos">
      <div class="mobileInputIOS iconOnLeft mt-32px">
        <b-input type="text" placeholder="Search Pickup groups" v-model.lazy="filter.pickup.search" />
        <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
      </div>

      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div class="basicWhiteIOScard-item" @click="$modal.show('ExtensionsModal')">
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <div class="headingInsideTable">All Extensions</div>
            </div>  
          </div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
        </div>
      </div>

      <div class="basicWhiteIOScard mb-16px">
        <div class="basicWhiteIOScard-item" 
          v-for="pickupgroup in filterPickupGroups" :key="pickupgroup.id" 
          @click="$modal.show('EditPickUpGroupModal',{ pickupgroup: pickupgroup })"
        >
          <div class="d-flex align-items-center">
            <div class="d-flex flex-column">
              <div class="headingInsideTable">{{ pickupgroup.name }}</div>
            </div>  
          </div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
        </div>
        <vb-no-record v-if="!filterPickupGroups.length" :text="api.pickupgroups.send?'':'There is no Tags'" :design="3">
          <vb-loading v-if="api.pickupgroups.send" class="text-center w-100" slot="loading" />
        </vb-no-record>
      </div>

      <AddPickUpGroup @load="fetchPickupGroups()" />
      <EditPickUpGroupModal @load="fetchPickupGroups()" />
      <ExtensionsModal />
    </div>
  </modal>
</template>

<script>
import AddPickUpGroup from "./AddPickupGroups.vue";
import EditPickUpGroupModal from "./EditPickupGroups.vue";
import ExtensionsModal from "../extensions/Extensions.vue";
import { VOIP_API } from "@/utils/index";
import { permission_handling } from '@/mixin';
import { mapGetters } from 'vuex';
export default {
  name: 'PickupGroupsModal',
  mixins: [permission_handling],
  components: {
    AddPickUpGroup,
    EditPickUpGroupModal,
    ExtensionsModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'PickupGroupsModal',
    },
  },
  data() {
    return {
      response: {
        pickupgroups: [],
      },
      api: {
        pickupgroups: this.$helperFunction.apiInstance({  }),
      },
      filter: {
        pickup: {
          search: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
    ]),
    filterPickupGroups(){
      const search = this.filter.pickup.search.trim().toLowerCase()
      return this.response.pickupgroups.filter(item=>`${item.name}`.trim().toLowerCase().includes(search))
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchPickupGroups();
    },
    onBeforeClose(){
      this.api.pickupgroups.reset()
      this.response.pickupgroups = []
      this.filter.pickup.search = ''
    },
    fetchPickupGroups() {
      let vm = this
      if(vm.api.pickupgroups.send) return;
      vm.api.pickupgroups.send = true;
      VOIP_API.endpoints.pickupgroup.list({
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data: { data: pickupgroups }, })=>{
        vm.response.pickupgroups = pickupgroups;
      })
      .finally(()=>{
        vm.api.pickupgroups.send = false;
      });
    },
  },
}
</script>

<style>

</style>