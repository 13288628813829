<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-800 EditExtensionDetailModal bigSquareCheckbox"
      width="500px"
      height="auto"
      :scrollable="true"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose($event)"
    >
      <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header d-block">
          <div class="d-flex justify-content-between align-items-center">
            <!-- <h2 class="dialer-edit-title">Edit Extention Detail</h2> -->
            <!-- <h2 class="dialer-edit-title">Telephone Extension</h2> -->
            <h2 class="dialer-edit-title">{{data.extension.type | extensionText}}</h2>
            <div class="dialer-edit-actions">
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
              <!-- <a v-else class="dialer-button dialer-button-delete" @click="$modal.hide(modalName)">Close</a> -->
              <a v-else class="newDoneButton" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </a>
            </div>
          </div>
          <div class="vm--modal-text">Below are the settings and details associated with this device.</div>
        </div>
        <div class="latestGreyBox-9-9-2023">
          <!-- <div class="latestGreyBox-heading-main">Extension limits</div>
          <div class="whiteBoxWithBorderInsideGreyBox mb-20px mt-10px">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="latestGreyBox-9-9-2023 wd-30 text-center">
                <div class="latestGreyBox-heading-main">Allowed</div>
                <div class="latestGreyBox-heading-main">{{ data.limit | get_property('allowed',0) }}</div>
              </div>
              <div class="latestGreyBox-9-9-2023 wd-30 text-center">
                <div class="latestGreyBox-heading-main">Used</div>
                <div class="latestGreyBox-heading-main">{{ data.limit | get_property('used',0) }}</div>
              </div>
              <div class="latestGreyBox-9-9-2023 wd-30 text-center">
                <div class="latestGreyBox-heading-main">Remaining</div>
                <div class="latestGreyBox-heading-main">{{ data.limit | get_property('remaining',0) }}</div>
              </div>
            </div>
          </div> -->
          <!-- <span>allowed: {{ data.limit | get_property('allowed',0) }}</span>
          <span>remaining: {{ data.limit | get_property('remaining',0) }}</span>
          <span>used: {{ data.limit | get_property('used',0) }}</span> -->
          <div>
            <!-- device name -->
            <form v-if="isHeadset" @submit.prevent="updateLabel()" class="mb-20px">
              <div class="whiteBGinputWithGreyRoundedBorder w-50">
                <div>
                  <label>SIP handset label</label>
                  <input class="w-100" type="text" :disabled="api.update_label.send || api.delete_extension.send" v-model="forms.update_label.label" placeholder="Enter a name here"/>
                  <p v-if="forms.update_label.submitted && $v.forms.update_label.label.$invalid" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.update_label.label.required">* Device name is required</span>
                    <span v-else-if="!$v.forms.update_label.label.maxLength">* Device name is can be maximum {{ $v.forms.update_label.label.$params.maxLength.max }} character</span>
                  </p>
                </div>
              </div>
            </form>
            <form v-if="isExternal" @submit.prevent="updateLabel()" class="mb-20px">
              <label>Select Country</label>
              <b-dropdown class="filterDropdownForTaskHub forNumberForwardingModal w-100" left>
                <template #button-content>
                  {{forms.update_label.country ? CountryName2[forms.update_label.country] : 'Select Country'}}
                </template>
                <b-dropdown-form @submit.prevent="''" class="inputItemContainer">
                  <div class="whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-100">
                    <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
                      <vb-icon icon="callActivity-search" class="greySerchIcon" height="18px" width="17.75px" />
                      <b-input v-model="filters.countries.search" placeholder="Search Country" />
                    </div>
                  </div>
                </b-dropdown-form>
                <b-dropdown-item :class="`${forms.update_label.country==data.value?'active':''}`"
                  v-for="data in countries" 
                  :key="data.id" 
                  @click="forms.update_label.country=data.value;filters.countries.search='';"
                >
                  {{ data.text }}
                  <b-icon v-if="forms.update_label.country==data.value" class="darker-chevron-down withoutPath" icon="check-lg" />
                </b-dropdown-item>
              </b-dropdown>
              <div class="whiteBGinputWithGreyRoundedBorder w-50">
                <div>
                  <label>Enter extension number</label>
                  <input class="w-100" type="text" :disabled="api.update_label.send || api.delete_extension.send" v-model="forms.update_label.number" v-vb-input-number-formate="forms.update_label.country" placeholder="Enter a number here"/>
                  <p v-if="forms.update_label.submitted && $v.forms.update_label.number.$invalid" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.update_label.number.required">* Number is required</span>
                    <span v-else-if="!$v.forms.update_label.number.validNumber">* Number is not valid</span>
                  </p>
                </div>
              </div>
            </form>
            <!-- use mobile data -->
            <!-- <div v-if="isMobile" class="latestGreyBox-9-9-2023 mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row">
                  <div class="d-flex flex-column">
                    <div class="latestGreyBox-heading-main">Use mobile data for calls?</div>
                    <div class="latestGreyBox-descriptionText">Here you can decide whether our mobile apps should use your mobile data when making and recieving calls.</div>
                  </div>
                  <div class="ml-4" :class="['w-fit-content']">
                    <b-form-checkbox
                      class="newerSwitch"
                      name="check-button"
                      switch
                    />
                  </div>
                </div>
              </div>
            </div> -->
            <!-- recieve call -->
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="d-flex flex-column">
                  <!-- <div class="latestGreyBox-heading-main">Receive calls on this device?</div>
                  <div class="latestGreyBox-descriptionText">Here you can decide whether you'd like to receive calls on this device or not.</div> -->
                  <div class="latestGreyBox-heading-main">Would you like to receive calls on this device?</div>
                  <div class="latestGreyBox-descriptionText">You can choose whether you want to enable call reception on this device.</div>
                </div>
                <div class="ml-4" :class="['w-fit-content']">
                  <b-form-checkbox
                    class="newerSwitch"
                    name="check-button"
                    :disabled="api.change_recieve_call.send || api.delete_extension.send"
                    :checked="conditions.recieve_call"
                    @change="toggleRecieveCalls($event)"
                    ref="recieve-call"
                    switch
                  />
                </div>
              </div>
            </div>
          </div>

          <!-- Desktop app version -->
          <div v-if="isDesktop" class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="d-flex flex-column">
                <div class="latestGreyBox-heading-main">Desktop app version</div>
              </div>
              <div class="ml-4">
                <div class="latestGreyBox-heading-main">{{ DesktopAppVersion }}</div>
              </div>
            </div>
          </div>

          <!-- forward call -->
          <!--<div class="dialer-box">
            <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
              <div>
                <label>Forward your calls if this app is offline</label>
                <input class="w-100" type="text" placeholder="Redirect number"/>
              </div>
            </div>
            <div class="dialer-box-text">Announce calls allows you to screen a call before accepting it. Present dialled number displays the dialled number instead of the Caller’s number.</div>
            <div class="d-flex flex-column">
              <b-form-checkbox class="makeCheckBoxesSquare less-padding">Announce calls</b-form-checkbox>
              <b-form-checkbox class="makeCheckBoxesSquare less-padding">Present dialled number</b-form-checkbox>
            </div>
          </div>-->
          <!-- SIP phone Info -->
          <template v-if="isHeadset">
            <!-- <div class="dialer-box-header-outsideBox color-black mb-0">Extra information you may need</div>
            <div class="dialer-box-text mt-3 mb-4">Click on each field below to copy it to your clipboard.</div> -->
            
            <div class="mt-20px">
              <div class="dialer-box p-0">
                <div class="dialer-box-header color-black">SIP device configuration</div>
                <div class="dialer-box-text mt-3 mb-4">Click on each field below to copy it to your clipboard.</div>
                <div class="innerWhiteBoxwithRoundedBorder">
                  <div class="d-flex align-items-center mb-26px">
                    <div class="sipInfoLabel">SIP username</div>
                    <div class="sipInfoData ml-29px">{{ data.extension.accountcode | check_empty('-') }}</div>
                  </div>
                  <div class="d-flex align-items-center mb-26px">
                    <div class="sipInfoLabel">SIP password</div>
                    <!-- <div class="sipInfoData ml-29px">wq8pxQD73vSJedPpCghMfWxpxqCRTAvC</div> -->
                    <div class="ml-29px d-flex justify-content-between align-items-center flex-grow-1">
                      <input :type="conditions.show_password?'text':'password'" disabled :value="data.extension.secret || ''" class="password-input-with-icon sipInfoData ">
                      <div class="d-flex align-items-center">
                        <vb-icon :icon="conditions.show_password?'eye-slash-fill':'eye-fill'" @click="conditions.show_password=!conditions.show_password" height="19px" width="21px" />
                        <!-- <button type="button" :disabled="api.update_secret.send" @click="updateSecret()">Change</button> -->
                        <vb-icon icon="dialer-regenratePassword-icon" :disabled="api.update_secret.send" class="mx-3" @click="updateSecret()" width="20.061px" height="21px"/>
                        <!-- <button type="button" :disabled="api.copy_secret.send"  @click="copySecret()">Copy</button> -->
                        <vb-icon width="17.512px" height="17.494px" icon="handsetSettings-copy-icon" :disabled="api.copy_secret.send" @click="copySecret()"/>
                      </div>
                    </div>
                  </div>
                  <div class="d-flex align-items-center mb-26px">
                    <div class="sipInfoLabel">SIP servers</div>
                    <div class="sipInfoData ml-29px">sip.voipbusiness.com</div>
                  </div>
                  <div class="d-flex align-items-center">
                    <div class="sipInfoLabel">SIP port</div>
                    <div class="sipInfoData ml-29px">5060 or 5061</div>
                  </div>
                </div>
              </div>
            
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
                  <div class="d-flex justify-content-between align-items-center">
                    <div class="d-flex w-75 flex-column">
                      <div class="latestGreyBox-heading-main">Device location</div>
                      <div class="latestGreyBox-descriptionText">
                        Select your handset location for emergency services. In the event of an emergency, 
                        first responders will need to know your location.
                      </div>
                    </div>
                    <button 
                      :disabled="api.get_emergency_addresses.send || api.change_location.send || api.delete_extension.send"
                      type="button" 
                      class="newButton mwd-175px ml-4" 
                      @click="changeLocation()"
                    >
                      <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                      <span class="newButton-textPart">Change location</span>
                    </button>
                  </div>
                  <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mt-12px mb-0">
                    <vb-select
                      v-model="forms.change_location.did"
                      :disabled="api.get_emergency_addresses.send || api.change_location.send"
                      :options="response.emergency_addresses"
                      :valueField="'did_number'"
                      :textField="'address_label'"
                      :onEmptyListText="'There is no location'"
                    >
                      <template #selected-option="{ option }">
                        <template v-if="option">
                          <div class="OwnertaggedVersion mb-0 mr-16px d-inline-flex w-auto">{{option.address_label}}</div>
                          <span>{{ `${option.house} ${option.street} ${option.town} ${option.postcode}` }}</span>
                        </template>
                        <template v-else>
                          <div class="mr-auto">Select a option</div>
                        </template>
                      </template>
                      <template #option="{ option }">
                        <b-icon :icon="forms.change_location.did==option.did_number?'check2':'blank'" class="mr-2" />
                        <div class="OwnertaggedVersion mb-0 mr-16px d-inline-flex w-auto">{{option.address_label}}</div>
                        <span>{{ `${option.house} ${option.street} ${option.town} ${option.postcode}` }}</span>
                      </template>
                    </vb-select>
                    <p v-if="forms.change_location.submitted && $v.forms.change_location.did.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.change_location.did.required">Location is required</span>
                    </p>
                  </div>
                </div>
              </div>
            </div>
          </template>
          <!-- logout -->
          <div v-if="isLoggedIn" class="mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
                <div class="d-flex flex-column pr-4">
                  <div class="latestGreyBox-heading-main">Logout this device?</div>
                  <!-- <div class="latestGreyBox-descriptionText">This will remove from your account. You can add it back to your account later by simply logging into the app on this device.</div> -->
                  <div class="latestGreyBox-descriptionText">It will be removed from your device. You can easily add it back later by logging into the app on this device. </div>
                </div>
                <button type="button" :disabled="api.logout_extension.send || api.delete_extension.send" @click="extensionLogout()" class="newDeleteButton">
                  <!-- <template v-if="api.logout_extension.send">
                    <vb-loading type="something" />
                    Loading
                  </template>
                  <span v-else>Logout</span> -->
                  <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
                  <span class="newDeleteButton-textPart d-flex justify-content-center position-relative">
                    <vb-spinner class="sm-mar-right" v-if="api.logout_extension.send" />
                    <template v-else>
                      <span>Logout</span>
                    </template>
                  </span>
                </button>
              </div>
            </div>
          </div>
          <!-- Incoming call display -->
          <div class="mt-20px" v-if="isHeadset && data.voipaccount" :accountcode="data.voipaccount">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
                <IncomingCallPresentationWidget v-if="isHeadset && data.voipaccount" :disabled="api.delete_extension.send" :accountcode="data.voipaccount" />
              </div>
            </div>
          </div>
        
          <!-- history -->
          <!-- <div>
            <div v-for="(history,index) in response.history" :key="index">
              {{history.status}}
              {{history | extensionDeviceInfo('ip') }}
              {{history | extensionDeviceInfo('app-name') }}
              {{history | extensionDeviceInfo('location') }}
              {{history | extensionDeviceInfo('location') }}
              {{history.updated_at | filter_date_current}}
            </div>
            <vb-no-record v-if="response.history.length==0" :text="api.get_history.send ? '' : 'Sorry, There is no History'" :design="3">
              <vb-loading v-if="api.get_history.send" slot="loading" />
            </vb-no-record>
          </div> -->
          <div v-if="!isExternal" class="mt-20px">
            <vb-table 
              class="latestTableDesign-withBlackBorders-again p-0 smallerFontSizes mt-0"
              :isListEmpty="response.history.length==0" 
              :listLength="response.history.length" 
              :perPage="5"
              :loading="api.get_history.send" 
            >
              <tr slot="header" v-if="!$store.getters.getIsMobile">
                <th class="border-0"> 
                  <span class="">status</span>
                </th>
                <th class=" border-0 ">
                  <span class="">IP</span>
                </th>
                <th class=" border-0 ">
                  <span class="">name</span>
                </th>
                <th class=" border-0 ">
                  <span class="">location </span>
                </th>
                <th class=" border-0 ">
                  <span class="">Date </span>
                </th>
              </tr>
              <template #body="{ start, end }">
                <template v-if="$store.getters.getIsMobile">
                  <tr class="" v-for="(history,index) in response.history" :key="index" v-show="index >= start && index <= end">
                    <td class="left">
                      <div >
                        <div class="headingInsideTable">{{history | extensionDeviceInfo('app-name') }} </div>
                        <div class="textInsideCard">{{history.status}}</div>
                      </div>
                    </td>
                    <td class="right dialer-col-right">
                      <div class="textInsideCard">{{history.updated_at | filter_date_current}}</div>
                      <div class="textInsideCard">{{history | extensionDeviceInfo('ip') }}</div>
                      <div class="textInsideCard">{{history | extensionDeviceInfo('location') }}</div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr class="" v-for="(history,index) in response.history" :key="index" v-show="index >= start && index <= end">
                    <td style="max-width: 80px;width: 10%">{{history.status}}</td>
                    <td style="max-width: 120px;width: 20%">
                      <p class="myDevicesIpV6">{{history | extensionDeviceInfo('ip') }}</p>
                      <!-- 2001:0000:130F:0000:0000:09C0:876A:130B -->
                    </td>
                    <td style="max-width: 120px;width: 20%">{{history | extensionDeviceInfo('app-name') }}</td>
                    <td style="max-width: 250px;width: 30%">{{history | extensionDeviceInfo('location') }}</td>
                    <td style="max-width: 120px;width: 20%">{{history.updated_at | filter_date_current}}</td>
                  </tr>
                </template>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex w-fit-content">
                      <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
                    </div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </div>
          <!-- Delete this Device -->
          <div v-if="isCanDelete" class="mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main">Delete this device</div>
                  <div class="latestGreyBox-descriptionText">This will remove <span style="color:black;">{{forms.update_label.label}}</span> from your account.</div>
                </div>
                <!-- <button type="button" class="dialer-button dialer-button-delete-alt">Delete</button> -->
                <button :disabled="api.delete_extension.send" type="button" @click="deleteExtension()" class="newDeleteButton">
                  <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
                  <span class="newDeleteButton-textPart">
                    <span>Delete</span>
                  </span>
                </button>
              </div>
            </div>
          </div>
        </div>
        <div style="color: transparent;">{{ data.extension | get_property('prefix.call_prefix.label') }}</div>
      </section>
    </modal>
  </div>
</template>

<script>
import { $fn, VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import { required, maxLength } from "vuelidate/lib/validators";
import IncomingCallPresentationWidget from '../widgets/IncomingCallPresentation.vue'
import { INTEGRATIONS } from '@/integrations';
import requiredIf from 'vuelidate/lib/validators/requiredIf';
import { CountryName2, flagsCountryCode } from '@/utils/flags';
import moment from 'moment-timezone';
import _ from 'lodash'
import { validNumberChecker } from '@/filter';

export default {
  name: "EditExtensionDetailModal",
  components: {
    IncomingCallPresentationWidget
  },
  props: {
    modalName: {
      type: String,
      default: 'EditExtensionDetail',
    },
  },
  data(){
    return {
      forms: {
        update_label: this.$helperFunction.formInstance({
          data: {
            label: '',
            number: '',
            country: 'GB',
          }
        }),
        change_location: this.$helperFunction.formInstance({
          data: {
            did: '',
          }
        }),
      },
      api: {
        delete_extension: this.$helperFunction.apiInstance({  }),
        get_history: this.$helperFunction.apiInstance({  }),
        update_label: this.$helperFunction.apiInstance({  }),
        logout_extension: this.$helperFunction.apiInstance({  }),
        change_recieve_call: this.$helperFunction.apiInstance({  }),
        update_secret: this.$helperFunction.apiInstance({  }),
        copy_secret: this.$helperFunction.apiInstance({  }),
        get_emergency_addresses: this.$helperFunction.apiInstance({  }),
        change_location: this.$helperFunction.apiInstance({  }),
      },
      conditions: {
        recieve_call: false,
        show_password: false,
      },
      data: {
        extension: {},
        voipaccount: '',
        limit: {},
      },
      response: {
        history: [],
        emergency_addresses: [],
      },
      filters: {
        countries: {
          search: ''
        },
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getIsMobile', 
      'getCurrentUser'
    ]),
    isLoggedIn(){ return !!this.data.extension?.device_status?.device_detail?.extra_data && this.data.extension.accountcode!=this.getCurrentUser.sip.user },
    // deviceId(){ 
    //   const extra_data = this.data.extension?.device_status?.device_detail?.extra_data
    //   if(extra_data){
    //     const extra_data1 = JSON.parse(extra_data)
    //     const device_id = extra_data1?.fp_data?.device_id
    //     if(device_id) return device_id
    //   }
    //   return '';
    // },
    isMobile(){ return this.data.extension?.type=='MOBILEEXT' },
    isExternal(){ return this.data.extension?.type=='EXTERNALEXT' },
    isHeadset(){ return this.data.extension?.type=='HEADEXT' },
    isCanDelete(){
      if(this.isExternal) return true
      if(this.data.limit.used > 1 && this.data.extension.accountcode!=this.getCurrentUser?.sip?.user) return true
      return false
    },
    isDesktop(){ return this.data.extension?.type=='DESKTOPEXT' },
    DesktopAppVersion(){
      const extra_data = this.data.extension?.device_status?.device_detail?.extra_data
      if(extra_data){
        const extra_data1 = JSON.parse(extra_data)
        const platform_version = extra_data1?.fp_data?.platform_version
        if(platform_version) return platform_version
      }
      return '';
    },
    countries(){ 
      const search = this.filters.countries.search.toLowerCase().trim()
      return _.orderBy(moment.tz.countries().map((i)=>({
        value: i,
        text: CountryName2[i],
        flag: flagsCountryCode[i],
      })).filter(i=>i.text.toLowerCase().trim().includes(search)) , ['text'], ['asc']);
    },
    CountryName2(){ return CountryName2 },
  },
  validations: {
    forms: {
      update_label: {
        label: {
          required: requiredIf(function(){
            return this.isHeadset
          }),
          maxLength: maxLength(15),
        },
        number: { 
          required: requiredIf(function(){
            return this.isExternal
          }),
          validNumber(value){ return !value || $fn.validPhoneNumber(value,this.forms.update_label.country) },
        },
      },
      change_location: {
        did: {
          required: required,
        },
      },
    },
  },
  filters: {
    extensionText(value){
      if(value=='HEADEXT') return 'Handset'
      else if(value=='INTEGRATIONEXT') return 'Integration'
      else if(value=='DESKTOPEXT') return 'Desktop app'
      else if(value=='WEBEXT') return 'Web app'
      else if(value=='MOBILEEXT') return 'Mobile app'
      else if(value=='EXTERNALEXT') return 'External'
      else return value
    },
    
    extensionDeviceInfo(value,value_type=''){
      if(!value) return ''
      let device = value;
      if(value.length){
        device = value[value.length-1]
      }
      const fp_data = JSON.parse(device.extra_data)
      const data = fp_data.fp_data
      const is_desktop = data?.platform=='desktop'
      const is_web = data?.platform=='web'
      const is_mobile = data?.platform=='mobile'
      const is_postman = data?.platform=='postman'
      const is_extension = data?.platform=='extension'
      const is_integration = data?.platform=='integration'

      const browser = data?.browser ?? ''
      const name = data?.name ?? ''
      const os = data?.os ?? ''
      const os_version = data?.os_version ?? ''
      if(value_type=='icon'){
        if(is_desktop) return 'trustedDevice-desktop-icon'
        else if(is_mobile) {
          if(os=='android') return 'trustedDevice-android-OSIcon'
          if(os=='ios') return 'trustedDevice-apple-OSIcon'
        } else if(is_web) {
          if(browser=='Chrome') return 'trustedDevice-chrome-icon'
          else if(browser=='Opera') return 'trustedDevice-opera-icon'
          else if(browser=='Edge') return 'trustedDevice-microsoft-icon'
          else if(browser=='Firefox') return 'trustedDevice-firefox-icon'
          else if(browser=='Safari') return 'trustedDevice-safari-icon'
        } else if (is_integration){
          if(name==INTEGRATIONS.platform_instance.hubspot) return 'trustedDevice-chrome-icon'
          else if(name==INTEGRATIONS.platform_instance.zoho) return 'trustedDevice-chrome-icon'
          else if(name==INTEGRATIONS.platform_instance.salesforce) return 'trustedDevice-chrome-icon'
        } else if (is_postman) return 'trustedDevice-chrome-icon'
        else if(is_extension) return 'trustedDevice-chrome-icon'
      } else if (value_type=='osicon') {
        if(os=='android') return 'trustedDevice-androidPhone-icon'
        else if(os=='ios') return 'trustedDevice-IPhone-icon'
        else if(os=='Windows' && os_version=='11') return 'trustedDevice-window11-OSIcon'
        else if(os=='Windows') return 'trustedDevice-window10-OSIcon'
        else if(os=='Linux') return 'trustedDevice-linux-OSIcon'
        else if(os?.includes?.('MAC')) return 'trustedDevice-appleMac-OSIcon'
        else return 'trustedDevice-window10-OSIcon'
      } else if(value_type=='ip'){
        return data?.ip ?? ''
      } else if(value_type=='location'){
        return `${data?.city ?? ''} ${data?.country ?? ''}`
      } else if(value_type=='app-name'){
        if(name) return name
        else if(is_desktop) return 'Desktop app'
        else if(is_mobile) return 'Mobile app'
        else if(is_web) return 'Web app'
        else if(is_postman) return 'Postman app'
        else if(is_extension) return 'Browser extension app'
      }
      return ''
    },
  },
  methods: {
    onBeforeOpen(event) {
      // TODO - on before open
      const extension = event?.params?.extension ?? {}
      this.data.extension = extension
      this.data.voipaccount = event?.params?.voipaccount ?? ''
      this.data.limit = event?.params?.limit ?? {}
      this.conditions.recieve_call = extension.extension_call_group === 'yes'
      if(this.isExternal) {
        const phoneNumber = validNumberChecker(extension?.accountname ?? '')
        this.forms.update_label.number = phoneNumber?.format?.("NATIONAL") ?? extension?.accountname ?? ''
        console.log(phoneNumber)
        this.forms.update_label.country = phoneNumber?.country ?? 'GB'
      } else {
        this.forms.update_label.label = extension?.accountname ?? ''
      }
      this.forms.change_location.did = extension?.es_callerid ?? ''
      this.getHistory()
      this.getEmergencyServices()
    },
    onBeforeClose(){
      this.forms.update_label.reset()
      this.forms.change_location.reset()
      this.api.get_history.reset()
      this.api.update_label.reset()
      this.api.change_recieve_call.reset()
      this.api.logout_extension.reset()
      this.api.update_secret.reset()
      this.api.change_location.reset()
      this.data.extension={}
      this.data.voipaccount=''
      this.data.limit={}
      this.response.history=[]
      this.response.emergency_addresses=[]
    },
    async changeLocation(){
      this.forms.change_location.submitted=true
      this.$v.forms.change_location.$touch()
      if(this.api.change_location.send || this.$v.forms.change_location.$invalid) return;
      try {
        this.api.change_location.send=true
        await VOIP_API.endpoints.location.updateEmergencyLocation({
          accountcode: this.data.extension.accountcode,
          number: this.forms.change_location.did,
        })
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.change_location.send=false
      }
    },
    async getEmergencyServices(){
      if(this.api.get_emergency_addresses.send) return;
      try {
        this.api.get_emergency_addresses.send=true
        const { data } = await VOIP_API.endpoints.location.getEmergencyAddress({
          uid: this.getCurrentUser.uid,
        })
        this.response.emergency_addresses=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.get_emergency_addresses.send=false
      }
    },
    async getHistory(){
      if(this.api.get_history.send) return;
      try {
        this.api.get_history.send=true
        const { data } = await VOIP_API.endpoints.extensions.history({
          extension_account: this.data.extension.accountcode,
        })
        this.response.history=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.get_history.send=false
      }
    },
    async updateLabel(){
      this.forms.update_label.submitted=true
      this.$v.forms.update_label.$touch()
      if(this.api.update_label.send || this.$v.forms.update_label.$invalid) return;
      try {
        this.api.update_label.send=true
        await VOIP_API.endpoints.extensions.updatelabel({
          accountcode: this.data.extension.accountcode,
          accountname: this.isExternal ? $fn.getNumberFormated(this.forms.update_label.number,this.forms.update_label.country) : this.forms.update_label.label,
        })
        this.$emit('update-list')
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.update_label.send=false
        this.forms.update_label.submitted=false
      }
    },
    async extensionLogout(){
      if(this.api.logout_extension.send) return;
      try {
        this.api.logout_extension.send=true
        await VOIP_API.endpoints.extensions.extensionLogout(this.data.extension.accountcode,{
          extension_accountcode: this.data.extension.accountcode,
          // device_id: this.deviceId
        })
        this.$emit('update-list')
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.logout_extension.send=false
      }
    },
    async toggleRecieveCalls(value){
      if(this.api.change_recieve_call.send) return;
      try {
        this.api.change_recieve_call.send=true
        await VOIP_API.endpoints.users.updateuserdetail({
          field: "extension",
          accountcode: this.data.extension.accountcode,
          account: this.data.voipaccount,
          value: value ? "yes" : "no",
        })
        this.conditions.recieve_call=value
        this.$emit('update-list')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.$refs['recieve-call'].localChecked = this.conditions.recieve_call
        this.api.change_recieve_call.send=false
      }
    },
    updateSecret(){
      let vm = this
      if(vm.api.update_secret.send) return;
      vm.$appConfirmation({
        title: "Waning",
        message: `Are you sure you want to change password`,
        button: {
          no: "No",
          yes: "Yes",
        },
        async callback({confirm}) {
          try {
            if(!confirm) return;
            vm.api.update_secret.send=true
            /*const { data } =*/ await VOIP_API.endpoints.extensions.updateSecret({
              id: vm.data.extension.accountcode,
              secret: '',
            })
            vm.$emit('update-list')
          } catch (ex) {
            this.appNotify({
              message: ex.own_message,
              type: 'danger',
            })
          } finally {
            vm.api.update_secret.send=false
          }
        },
      });
    },
    onListUpdate(extension_detail){
      const extension = extension_detail?.extensions?.find?.(i=>i.accountcode==this.data.extension.accountcode)
      if(extension) this.data.extension=extension
    },
    async copySecret(){
      if(this.api.copy_secret.send) return;
      try {
        this.api.copy_secret.send=true
        await navigator.clipboard.writeText(this.data.extension.secret || '')
        this.appNotify({
          message: 'Successfully Copied',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.message || 'Allow the Clipboard',
          type: 'danger',
        })
      } finally {
        this.api.copy_secret.send=false
      }
    },
    async deleteExtension(){
      if(this.api.delete_extension.send) return;
      try {
        this.api.delete_extension.send=true
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to remove this extension`,
          button: {
            no: "no",
            yes: "yes",
          },
        });
        if(!confirm) return;
        await VOIP_API.endpoints.extensions.deleteExtension({
          id: this.data.extension.accountcode,
        })
        this.$modal.hide(this.modalName)
        this.$emit('update-list')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.delete_extension.send=false
      }
    },
  },
};
</script>
