<template>
  <modal 
    class="dialer_animation center-zoomIn-popup credit-card-modal credit-card-modal-w-100" 
    width="100%" 
    height="auto" 
    :name="modalName" 
    :clickToClose="false" 
    @before-open="onBeforeOpen" 
    @opened="onOpen" 
    @before-close="onBeforeClose"
  >
    <div :class="['wrapper',!showDifferentForm ? '' : 'vh-100']">
      <div v-if="!showDifferentForm" class="card-form">
        
        <div class="card-list">
          <div class="card-item" :class="{ '-active': isCardFlipped }">
            <div class="card-item__side -front">
              <div class="card-item__focus" :class="{ '-active': focusElementStyle }" :style="focusElementStyle" ref="focusElement"></div>
              <div class="card-item__cover">
                <img :src="currentCardBackground" class="card-item__bg" />
              </div>

              <div class="card-item__wrapper">
                <div class="card-item__top">
                  <img :src="files.chip" class="card-item__chip" />
                  <div class="card-item__type">
                    <transition name="slide-fade-up">
                      <img v-if="getCardType" :key="getCardType" :src="getCardTypeImage" class="card-item__typeImg" />
                    </transition>
                  </div>
                </div>
                <label for="cardNumber" class="card-item__number" ref="cardNumber">
                  <template v-if="getCardType === 'amex'">
                    <span v-for="(n, $index) in amexCardMask" :key="$index">
                      <transition name="slide-fade-up">
                        <div v-if="$index > 4 && $index < 14 && card.card_number.length > $index && n.trim() !== ''" class="card-item__numberItem">*</div>
                        <div v-else-if="card.card_number.length > $index" :key="$index" class="card-item__numberItem" :class="{ '-active': n.trim() === '' }">
                          {{ card.card_number[$index] }}
                        </div>
                        <div v-else class="card-item__numberItem" :class="{ '-active': n.trim() === '' }" :key="$index + 1">{{ n }}</div>
                      </transition>
                    </span>
                  </template>

                  <template v-else>
                    <span v-for="(n, $index) in otherCardMask" :key="$index">
                      <transition name="slide-fade-up">
                        <div v-if="$index > 4 && $index < 15 && card.card_number.length > $index && n.trim() !== ''" class="card-item__numberItem">*</div>
                        <div v-else-if="card.card_number.length > $index" :key="$index" class="card-item__numberItem" :class="{ '-active': n.trim() === '' }">
                          {{ card.card_number[$index] }}
                        </div>
                        <div v-else class="card-item__numberItem" :class="{ '-active': n.trim() === '' }" :key="$index + 1">{{ n }}</div>
                      </transition>
                    </span>
                  </template>
                </label>
                <div class="card-item__content">
                  <label for="cardName" class="card-item__info" ref="cardName">
                    <div class="card-item__holder">Card Holder</div>
                    <transition name="slide-fade-up">
                      <div v-if="card.card_holder_name.length" class="card-item__name" key="1">
                        <transition-group name="slide-fade-right">
                          <span v-for="(n, $index) in card.card_holder_name.replace(/\s\s+/g,' ')" :key="$index + 1" class="card-item__nameItem">
                            <template v-if="$index === $index">{{ n }}</template>
                          </span>
                        </transition-group>
                      </div>
                      <div class="card-item__name" v-else key="2">Full Name</div>
                    </transition>
                  </label>
                  <div class="card-item__date" ref="cardDate">
                    <label for="cardMonth" class="card-item__dateTitle">Expires</label>
                    <label for="cardMonth" class="card-item__dateItem">
                      <transition name="slide-fade-up">
                        <span v-if="card.card_expiry_month" :key="card.card_expiry_month">{{ card.card_expiry_month }}</span>
                        <span v-else key="2">MM</span>
                      </transition>
                    </label>
                    /
                    <label for="cardYear" class="card-item__dateItem">
                      <transition name="slide-fade-up">
                        <span v-if="card.card_expiry_year" :key="card.card_expiry_year">{{ String(card.card_expiry_year).slice(2, 4) }}</span>
                        <span v-else key="2">YY</span>
                      </transition>
                    </label>
                  </div>
                </div>
              </div>
            </div>
            <div class="card-item__side -back">
              <div class="card-item__cover">
                <img v-bind:src="currentCardBackground" class="card-item__bg" />
              </div>
              <div class="card-item__band"></div>
              <div class="card-item__cvv">
                <div class="card-item__cvvTitle">CVV</div>
                <div class="card-item__cvvBand">
                  <span v-for="(n, $index) in card.card_cvv" :key="$index">*</span>
                </div>
                <div class="card-item__type">
                  <img :src="getCardTypeImage" v-if="getCardType" class="card-item__typeImg" />
                </div>
              </div>
            </div>
          </div>
        </div>

        <div class="card-form__inner">
          
          <div v-if="!isConfirmScreen" class="card-form__innerBox">
            <b-alert :show="!!error.create.message" variant="danger">{{error.create.message}}</b-alert>
            <div class="dialer-input-field input-consistent-inner-shadows mt-3">
              
              <label for="cardNumber" class="card-input__label">Card Number</label>
              <input
                type="text"
                id="cardNumber"
                class="w-100"
                v-mask="generateCardNumberMask"
                v-model="card.card_number"
                @focus="focusInput"
                @blur="blurInput"
                data-ref="cardNumber"
              />
              
              <p v-if="submitted && !$v.card.card_number.required" class="text text-danger animated bounceIntop">
                <span>* Card number is required</span>
              </p>
            </div>
            <div class="dialer-input-field input-consistent-inner-shadows mt-3">
              
              <label for="cardName" class="card-input__label">Card Holders</label>
              <input
                type="text"
                id="cardName"
                class="w-100"
                :maxlength="$v.card.card_holder_name.$params.maxLength.max" 
                onkeypress="return /^[A-Za-z]+$/.test(event.key) || event.key==' '" 
                v-model="card.card_holder_name"
                v-on:focus="focusInput"
                v-on:blur="blurInput" 
                data-ref="cardName"
              />
              
              <p v-if="submitted && $v.card.card_holder_name.$invalid" class="text text-danger animated bounceIntop">
                <span v-if="!$v.card.card_holder_name.required">* Card holder name is Required</span>
                <span v-else-if="!$v.card.card_holder_name.maxLength">* Card holder name can be maximum {{ $v.card.card_holder_name.$params.maxLength.max }} character</span>
                <span v-else-if="!$v.card.card_holder_name.alpha">* Card holder name must be only alphabets</span>
                <span v-else-if="!$v.card.card_holder_name.valid">* Card holder name must have a space character</span>
              </p>
            </div>
            <div class="card-form__row mt-3">
              <div class="card-form__col mr-3">
                <div class="card-form__group dialer-input-field input-consistent-inner-shadows">
                  
                  <label for="cardMonth" class="card-input__label">Expiration Date</label>
                  <select
                    class="card-input__input -select"
                    id="cardMonth"
                    v-model="card.card_expiry_month"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="cardDate"
                  >
                    <option value="" disabled>Month</option>
                    <option v-for="n in 12" :key="n" :value="n < 10 ? '0' + n : n" :disabled="n < minCardMonth">{{ n }}</option>
                  </select>
                  <p v-if="submitted && !$v.card.card_expiry_month.required" class="text text-danger animated bounceIntop">
                    <span>* Card expiry month is required</span>
                  </p>
                  <select
                    class="card-input__input -select"
                    id="cardYear"
                    v-model="card.card_expiry_year"
                    v-on:focus="focusInput"
                    v-on:blur="blurInput"
                    data-ref="cardDate"
                  >
                    <option value="" disabled>Year</option>
                    <option v-for="(n, $index) in 12" :key="n" :value="$index + minCardYear">{{ $index + minCardYear }}</option>
                  </select>
                  <p v-if="submitted && !$v.card.card_expiry_year.required" class="text text-danger animated bounceIntop">
                    <span>* Card expiry year is required</span>
                  </p>
                </div>
              </div>
              <div class="card-form__col -cvv">
                <div class=" dialer-input-field input-consistent-inner-shadows">
                  
                  <label for="cardCvv" class="card-input__label">CVV</label>
                  <input
                    type="text"
                    class="card-input__input"
                    id="cardCvv"
                    v-mask="'###'"
                    maxlength="3"
                    v-model="card.card_cvv"
                    v-on:focus="flipCard(true)"
                    v-on:blur="flipCard(false)"
                  />
                  <p v-if="submitted && !$v.card.card_cvv.required" class="text text-danger animated bounceIntop">
                    <span>* Card cvv is required</span>
                  </p>
                </div>
              </div>
            </div>
            <div v-if="error.create.message">{{ error.create.message }}</div>
            <button class="card-form__button" :disabled="api_sent.create" @click="handleSubmit(true)">
              <b-icon v-if="api_sent.create" icon="arrow-clockwise" animation="spin-pulse" font-scale="1.0" />Done
            </button>
          </div>
          
          <div v-else class="card-form__innerBox">
            
            <div class="h-100 d-flex flex-column justify-content-center">
              <div class="mx-5">
                <p style="text-transform:capitalize">please look at your statement - verify amount</p>
                <p style="text-transform:capitalize;font-weight:bold;">charge 1 [ 77 ]</p>
                <p style="text-transform:capitalize;font-weight:bold;">charge 2 [ 82 ]</p>
              </div>
              <button class="card-form__button" :disabled="api_sent.create" @click="handleSubmit(false)">yes</button>
            </div>
          </div>

          <div class="card-form__closeIcon" @click="$modal.hide(modalName)">
            <b-icon class="ico-chat blue-icon" icon="x-circle" scale="1" />
          </div>

        </div>

      </div>

      <div v-else class="card-authentication-section mx-auto my-auto position-relative" style="width: 567px; height: 567px; justify-content: center; align-items: center; display: flex;">
        <div v-if="!show_loading" style="position:absolute;top:20px;right:20px;z-index:3">
          <a class="newCloseButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
        <form v-if="paymentResponse.FLAG=='PAY360'" @submit.prevent="''" v-show="false" id="PayzonePaReqForm" name="PayzonePaReqForm" method="post" :action="paymentResponse.URL" target="ACSFrame" ref="form" >
          
          <input type="hidden" name="transactionId" :value="paymentResponse.TRANSID" />
          <input type="hidden" name="notificationUrl" :value="url" />
          <input type="hidden" name="MD" :value="paymentResponse.MD" />
          <a @click="submit">DONE</a>
        </form>
        <form v-else @submit.prevent="''" v-show="false" id="PayzonePaReqForm" name="PayzonePaReqForm" method="post" :action="paymentResponse.ACSURL" target="ACSFrame" ref="form">
          <input type="hidden" name="PaReq" :value="paymentResponse.PaREQ" />
          <input type="hidden" name="MD" :value="paymentResponse.MD" />
          <input type="hidden" name="TermUrl" :value="url" />
          <a @click="submit">DONE</a>
        </form>
        <vb-loading v-if="show_loading" />

        <iframe
          v-show="!show_loading"
          @load="submit"
          src="/incs/payzone/loading.svg"
          id="ACSFrame"
          name="ACSFrame"
          width="100%"
          height="100%"
          frameborder="0"
          scrolling="no"
          :style="`z-index:2`"
        ></iframe>
      </div>

    </div>
  </modal>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { TokenService } from "../../services/storage.service";
import { 
  VOIP_API 
} from "../../utils";
// import { centrifugo } from '../../Centrifuge';
import moment from 'moment-timezone';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
const random = Math.floor(Math.random() * 25 + 1);

export default {
  name: "PaymentDetailModal",
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'PaymentDetailModal',
    },
  },
  data() {
    return {
      show_loading: false,
      files: {
        1: require("@/assets/images/credit-card/1.jpeg"),
        2: require("@/assets/images/credit-card/2.jpeg"),
        3: require("@/assets/images/credit-card/3.jpeg"),
        4: require("@/assets/images/credit-card/4.jpeg"),
        5: require("@/assets/images/credit-card/5.jpeg"),
        6: require("@/assets/images/credit-card/6.jpeg"),
        7: require("@/assets/images/credit-card/7.jpeg"),
        8: require("@/assets/images/credit-card/8.jpeg"),
        9: require("@/assets/images/credit-card/9.jpeg"),
        10: require("@/assets/images/credit-card/10.jpeg"),
        11: require("@/assets/images/credit-card/11.jpeg"),
        12: require("@/assets/images/credit-card/12.jpeg"),
        13: require("@/assets/images/credit-card/13.jpeg"),
        14: require("@/assets/images/credit-card/14.jpeg"),
        15: require("@/assets/images/credit-card/15.jpeg"),
        16: require("@/assets/images/credit-card/16.jpeg"),
        17: require("@/assets/images/credit-card/17.jpeg"),
        18: require("@/assets/images/credit-card/18.jpeg"),
        19: require("@/assets/images/credit-card/19.jpeg"),
        20: require("@/assets/images/credit-card/20.jpeg"),
        21: require("@/assets/images/credit-card/21.jpeg"),
        22: require("@/assets/images/credit-card/22.jpeg"),
        23: require("@/assets/images/credit-card/23.jpeg"),
        24: require("@/assets/images/credit-card/24.jpeg"),
        25: require("@/assets/images/credit-card/25.jpeg"),
        amex: require("@/assets/images/credit-card/amex.png"),
        chip: require("@/assets/images/credit-card/chip.png"),
        dinersclub: require("@/assets/images/credit-card/dinersclub.png"),
        discover: require("@/assets/images/credit-card/discover.png"),
        jcb: require("@/assets/images/credit-card/jcb.png"),
        mastercard: require("@/assets/images/credit-card/mastercard.png"),
        troy: require("@/assets/images/credit-card/troy.png"),
        unionpay: require("@/assets/images/credit-card/unionpay.png"),
        visa: require("@/assets/images/credit-card/visa.png"),
      },
      minCardYear: new Date().getFullYear(),
      amexCardMask: "#### ###### #####",
      otherCardMask: "#### #### #### ####",
      cardNumberTemp: "",
      isCardFlipped: false,
      focusElementStyle: null,
      isInputFocused: false,
      card: {
        uid: TokenService.USER.get().uid,
        card_number: [],
        card_expiry_month: "",
        card_expiry_year: "",
        card_cvv: null,
        card_holder_name: '',
      },
      showDifferentForm: false,
      paymentResponse: {},
      months: [
        "01",
        "02",
        "03",
        "04",
        "05",
        "06",
        "07",
        "08",
        "09",
        "10",
        "11",
        "12",
      ],
      years: [
        "20",
        "21",
        "22",
        "23",
        "24",
        "25",
        "26",
        "27",
        "28",
        "29",
        "30",
        "31",
      ],
      service: null,
      url: null,
      msg: {},
      submitted: false,
      payment_loaded: false,
      api_sent: {
        create: false,
      },
      error: {
        create: {
          message: '',
        }
      },
      isConfirmScreen: false,
    };
  },
  mounted() {
    this.cardNumberTemp = this.otherCardMask;
    const cardNumber = document.getElementById("cardNumber");
    if (cardNumber) {
      cardNumber.focus();
    }
  },
  computed: {
    currentCardBackground() { return this.files[this.getCardType || random.toString()]; },
    getCardTypeImage() { return this.files[this.getCardType]; },
    getCardType() {
      let number = this.card.card_number;
      let re = new RegExp("^4");
      if (re.test(number)) return "visa";

      re = new RegExp("^(34|37)");
      if (re.test(number)) return "amex";

      re = new RegExp("^5[1-5]");
      if (re.test(number)) return "mastercard";

      re = new RegExp("^6011");
      if (re.test(number)) return "discover";

      re = new RegExp("^9792");
      if (re.test(number)) return "troy";

      return ""; // default type
    },
    generateCardNumberMask() {
      return this.getCardType === "amex"
        ? this.amexCardMask
        : this.otherCardMask;
    },
    minCardMonth() {
      if (this.card.card_expiry_year === this.minCardYear) return new Date().getMonth() + 4;
      return 1;
    },
  },
  watch: {
    "card.card_expiry_year"() {
      if (this.card.card_expiry_month < this.minCardMonth) {
        this.card.card_expiry_month = "";
      }
    },
  },
  methods: {
    onBeforeOpen(){
      const current = moment().local()
      current.add(4, 'months');
      const month = current.month()
      const year = current.year()
      this.showDifferentForm = false;
      this.isConfirmScreen = false;
      this.error.create.message=''
      this.payment_loaded = false;
      this.card.card_expiry_month=`${month<10?`0${month}`:month}`
      this.card.card_expiry_year=year
    },
    onOpen(){
      this.cardNumberTemp = this.otherCardMask;
      const cardNumber = document.getElementById("cardNumber");
      if (cardNumber) {
        cardNumber.focus();
      }
    },
    onBeforeClose(){
      this.card.card_number = []
      this.card.card_expiry_month = ""
      this.card.card_expiry_year = ""
      this.card.card_cvv = null
      this.card.card_holder_name = ''
      this.submitted=false
    },
    beforeOpen() {
      this.showDifferentForm = false;
      this.payment_loaded = false;
    },
    submit() {
      if (!this.payment_loaded) {
        this.$refs.form.submit();
        this.payment_loaded = true;
      } else {
        this.show_loading = false;
      }
    },
    async handleSubmit(isConfirmed) {
      let vm = this
      // console.log('run',isConfirmed,vm.$v.$invalid || vm.api_sent.create)
      vm.submitted = true;
      vm.$v.$touch();
      if (vm.$v.$invalid || vm.api_sent.create) return;
      if(isConfirmed){
        vm.isConfirmScreen=true
        return;
      }
      vm.isConfirmScreen=false
      vm.api_sent.create=true
      vm.error.create.message=''
      let accountdetail = {}
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data })=>{
        accountdetail=data
      })
      .finally(()=>{
        VOIP_API.endpoints.billing_api.create({
          ...vm.card,
          card_expiry_year: parseInt(vm.card.card_expiry_year.toString().slice(2)),
          uid: vm.$store.getters.getCurrentUser?.uid,
          accountcode: vm.$store.getters.getCurrentUser?.account,
          email: vm.$store.getters.getCurrentUser?.billingEmail,
          name: vm.$store.getters.getCurrentUser?.display_name,
          city: accountdetail?.user_detail?.city ?? '',
          postcode: accountdetail?.user_detail?.postcode ?? '',
        })
        .then(({ data: res }) => {
          // console.log(res);
          vm.paymentResponse = res;
          if(res.THREEDSCURE_CHALLENGE){
            // vm.url = `https://centralpayment.duplex.network/api/v1/${vm.paymentResponse.ENDPOINT}/${vm.paymentResponse.FLAG=='TAKEPAYMENT'?vm.paymentResponse.HASH:vm.paymentResponse.MD}`;
            vm.url = `${vm.paymentResponse.PAYMENT_URL}/api/v1/${vm.paymentResponse.ENDPOINT}/${vm.paymentResponse.FLAG=='TAKEPAYMENT'?vm.paymentResponse.HASH:vm.paymentResponse.MD}`;
            vm.showDifferentForm = true;
            vm.show_loading = true;
            vm.resetForm()
            // 
          } else {
            if(res.FLAG=='STRIPE'){
              vm.appNotify({
                title: 'Card',
                message: 'Card has been Added Successfully',
                type: 'success',
                notify_type: 'alert',
              })
            }
            vm.$modal.hide(vm.modalName)
          }
        })
        .catch((ex) => {
          vm.error.create.message=ex.own_message=='Request failed with status code 422' ? 'A card with the same information is already been added' : ex.own_message
        })
        .finally(() => {
          vm.submitted = false;
          vm.api_sent.create=false;
        });
      })
    },
    resetForm(){
      this.card = {
        uid: TokenService.USER.get().uid,
        card_number: [],
        card_expiry_month: "",
        card_expiry_year: "",
        card_cvv: null,
        card_holder_name: '',
      }
    },
    onNotification(message){
      if (message.data.action == "new_card_add" && message.data.section == "card" && message.data.flag == "new_card") {
        this.$modal.hide(this.modalName)
      } else if(message.data.flag == "CLOSE_PAYMENT_MODAL") {
        this.$modal.hide(this.modalName)
      }
    },
    //added by Tahseen
    flipCard(status) {
      this.isCardFlipped = status;
    },
    focusInput(e) {
      this.isInputFocused = true;
      let targetRef = e.target.dataset.ref;
      let target = this.$refs[targetRef];
      this.focusElementStyle = {
        width: `${target.offsetWidth}px`,
        height: `${target.offsetHeight}px`,
        transform: `translateX(${target.offsetLeft}px) translateY(${target.offsetTop}px)`,
      };
    },
    blurInput() {
      let vm = this;
      setTimeout(() => {
        if (!vm.isInputFocused) {
          vm.focusElementStyle = null;
        }
      }, 300);
      vm.isInputFocused = false;
    },
  },
  validations: {
    card: {
      card_number: { required },
      card_expiry_month: { required },
      card_expiry_year: { required },
      card_cvv: { required },
      card_holder_name: { 
        required,
        maxLength: maxLength(33),
        alpha: function(value){ return /^[a-zA-Z ]*$/.test(value) },
        valid: function(value){
          const value1 = value.split(' ')
          return !!value1[0] && !!value1[1]
        }, 
      },
    },
  },
  created(){
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
    // centrifugo.mothership.on(centrifugo.mothership.events.message,this.onNotification)
  },
  beforeDestroy(){
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
    // centrifugo.mothership.off(centrifugo.mothership.events.message,this.onNotification)
  },
};
</script>

<style lang="scss" scoped>
.wrapper {
  display: flex;
  padding: 50px 15px;
  @media screen and (max-width: 700px), (max-height: 500px) {
    flex-wrap: wrap;
    flex-direction: column;
  }
}

.card-form {
  max-width: 600px;
  margin: auto;
  width: 100%;

  @media screen and (max-width: 576px) {
    margin: 0 auto;
  }

  &__inner {
    background: #fff;
    box-shadow: 0 30px 60px 0 rgba(90, 116, 148, 0.4);
    border-radius: 10px;
    padding: 35px 20px 15px 35px;
    padding-top: 180px;
    position: relative;

    @media screen and (max-width: 480px) {
      padding: 25px;
      padding-top: 165px;
    }
    @media screen and (max-width: 360px) {
      padding: 15px;
      padding-top: 165px;
    }
  }

  &__innerBox {
    height: calc(54vh - 100px);
    overflow-y: auto;
    padding-right: 30px;
  }

  &__row {
    display: flex;
    align-items: flex-start;
    @media screen and (max-width: 480px) {
      flex-wrap: wrap;
    }
  }

  &__col {
    flex: auto;
    margin-right: 35px;

    &:last-child {
      margin-right: 0;
    }

    @media screen and (max-width: 480px) {
      margin-right: 0;
      flex: unset;
      width: 100%;
      margin-bottom: 20px;

      &:last-child {
        margin-bottom: 0;
      }
    }

    &.-cvv {
      max-width: 150px;
      @media screen and (max-width: 480px) {
        max-width: initial;
      }
    }
  }

  &__group {
    display: flex;
    align-items: flex-start;
    flex-wrap: wrap;

    .card-input__input {
      flex: 1;
      margin-right: 15px;

      &:last-child {
        margin-right: 0;
      }
    }
  }

  &__button {
    width: 100%;
    height: 55px;
    background: #2364d2;
    border: none;
    border-radius: 5px;
    font-size: 22px;
    font-weight: 500;
    font-family: "Source Sans Pro", sans-serif;
    box-shadow: 3px 10px 20px 0px rgba(35, 100, 210, 0.3);
    color: #fff;
    margin-top: 20px;
    margin-bottom: 20px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      margin-top: 10px;
    }
  }
  &__closeIcon {
    cursor: pointer;
    position: absolute;
    right: -20px;
    top: -20px;
    font-size: 14px;
  }
}

.card-item {
  max-width: 430px;
  height: 270px;
  margin-left: auto;
  margin-right: auto;
  position: relative;
  z-index: 2;
  width: 100%;

  @media screen and (max-width: 480px) {
    max-width: 310px;
    height: 220px;
    width: 90%;
  }

  @media screen and (max-width: 360px) {
    height: 180px;
  }

  &.-active {
    .card-item__side {
      &.-front {
        transform: perspective(1000px) rotateY(180deg) rotateX(0deg)
          rotateZ(0deg);
      }
      &.-back {
        transform: perspective(1000px) rotateY(0) rotateX(0deg) rotateZ(0deg);
      }
    }
  }

  &__focus {
    position: absolute;
    z-index: 3;
    border-radius: 5px;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    transition: all 0.35s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    opacity: 0;
    pointer-events: none;
    overflow: hidden;
    border: 2px solid rgba(255, 255, 255, 0.65);

    &:after {
      content: "";
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      background: rgb(8, 20, 47);
      height: 100%;
      border-radius: 5px;
      filter: blur(25px);
      opacity: 0.5;
    }

    &.-active {
      opacity: 1;
    }
  }

  &__side {
    border-radius: 15px;
    overflow: hidden;
    box-shadow: 0 20px 60px 0 rgba(14, 42, 90, 0.55);
    transform: perspective(2000px) rotateY(0deg) rotateX(0deg) rotate(0deg);
    transform-style: preserve-3d;
    transition: all 0.8s cubic-bezier(0.71, 0.03, 0.56, 0.85);
    backface-visibility: hidden;
    height: 100%;

    &.-back {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      transform: perspective(2000px) rotateY(-180deg) rotateX(0deg) rotate(0deg);
      z-index: 2;
      padding: 0;
      height: 100%;

      .card-item__cover {
        transform: rotateY(-180deg);
      }
    }
  }
  &__bg {
    max-width: 100%;
    display: block;
    max-height: 100%;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
  &__cover {
    height: 100%;
    background-color: #1c1d27;
    position: absolute;
    height: 100%;
    background-color: #1c1d27;
    left: 0;
    top: 0;
    width: 100%;
    border-radius: 15px;
    overflow: hidden;
    &:after {
      content: "";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgba(6, 2, 29, 0.45);
    }
  }

  &__top {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
    margin-bottom: 40px;
    padding: 0 10px;

    @media screen and (max-width: 480px) {
      margin-bottom: 25px;
    }
    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }

  &__chip {
    width: 60px;
    @media screen and (max-width: 480px) {
      width: 50px;
    }
    @media screen and (max-width: 360px) {
      width: 40px;
    }
  }

  &__type {
    height: 45px;
    position: relative;
    display: flex;
    justify-content: flex-end;
    max-width: 100px;
    margin-left: auto;
    width: 100%;

    @media screen and (max-width: 480px) {
      height: 40px;
      max-width: 90px;
    }
    @media screen and (max-width: 360px) {
      height: 30px;
    }
  }

  &__typeImg {
    max-width: 100%;
    object-fit: contain;
    max-height: 100%;
    object-position: top right;
  }

  &__info {
    color: #fff;
    width: 100%;
    max-width: calc(100% - 85px);
    padding: 10px 15px;
    font-weight: 500;
    display: block;

    cursor: pointer;

    @media screen and (max-width: 480px) {
      padding: 10px;
    }
  }

  &__holder {
    opacity: 0.7;
    font-size: 13px;
    margin-bottom: 6px;
    @media screen and (max-width: 480px) {
      font-size: 12px;
      margin-bottom: 5px;
    }
  }

  &__wrapper {
    font-family: "Source Code Pro", monospace;
    padding: 25px 15px;
    position: relative;
    z-index: 4;
    height: 100%;
    text-shadow: 7px 6px 10px rgba(14, 42, 90, 0.8);
    user-select: none;
    @media screen and (max-width: 480px) {
      padding: 20px 10px;
    }
  }

  &__name {
    font-size: 18px;
    line-height: 1;
    white-space: nowrap;
    max-width: 100%;
    overflow: hidden;
    text-overflow: ellipsis;
    text-transform: uppercase;
    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }
  &__nameItem {
    display: inline-block;
    min-width: 8px;
    position: relative;
  }

  &__number {
    font-weight: 500;
    line-height: 1;
    color: #fff;
    font-size: 27px;
    margin-bottom: 35px;
    display: inline-block;
    padding: 10px 15px;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 21px;
      margin-bottom: 15px;
      padding: 10px 10px;
    }

    @media screen and (max-width: 360px) {
      font-size: 19px;
      margin-bottom: 10px;
      padding: 10px 10px;
    }
  }

  &__numberItem {
    width: 16px;
    display: inline-block;
    &.-active {
      width: 30px;
    }

    @media screen and (max-width: 480px) {
      width: 13px;

      &.-active {
        width: 16px;
      }
    }

    @media screen and (max-width: 360px) {
      width: 12px;

      &.-active {
        width: 8px;
      }
    }
  }

  &__content {
    color: #fff;
    display: flex;
    align-items: flex-start;
  }

  &__date {
    flex-wrap: wrap;
    font-size: 18px;
    margin-left: auto;
    padding: 10px;
    display: inline-flex;
    width: 80px;
    white-space: nowrap;
    flex-shrink: 0;
    cursor: pointer;

    @media screen and (max-width: 480px) {
      font-size: 16px;
    }
  }

  &__dateItem {
    position: relative;
    margin-bottom: 0px;
    span {
      width: 22px;
      display: inline-block;
    }
  }

  &__dateTitle {
    opacity: 0.7;
    font-size: 13px;
    padding-bottom: 0px;
    width: 100%;
    margin-bottom: 0px;

    @media screen and (max-width: 480px) {
      font-size: 12px;
      padding-bottom: 5px;
    }
  }
  &__band {
    background: rgba(0, 0, 19, 0.8);
    width: 100%;
    height: 50px;
    margin-top: 30px;
    position: relative;
    z-index: 2;
    @media screen and (max-width: 480px) {
      margin-top: 20px;
    }
    @media screen and (max-width: 360px) {
      height: 40px;
      margin-top: 10px;
    }
  }

  &__cvv {
    text-align: right;
    position: relative;
    z-index: 2;
    padding: 15px;
    .card-item__type {
      opacity: 0.7;
    }

    @media screen and (max-width: 360px) {
      padding: 10px 15px;
    }
  }
  &__cvvTitle {
    padding-right: 10px;
    font-size: 15px;
    font-weight: 500;
    color: #fff;
    margin-bottom: 5px;
  }
  &__cvvBand {
    height: 45px;
    background: #fff;
    margin-bottom: 30px;
    text-align: right;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    padding-right: 10px;
    color: #1a3b5d;
    font-size: 18px;
    border-radius: 4px;
    box-shadow: 0px 10px 20px -7px rgba(32, 56, 117, 0.35);

    @media screen and (max-width: 480px) {
      height: 40px;
      margin-bottom: 20px;
    }

    @media screen and (max-width: 360px) {
      margin-bottom: 15px;
    }
  }
}

.card-list {
  margin-bottom: -130px;

  @media screen and (max-width: 480px) {
    margin-bottom: -120px;
  }
}

.card-input {
  margin-bottom: 20px;
  &__label {
    font-size: 14px;
    margin-bottom: 5px;
    font-weight: 500;
    color: #1a3b5d;
    width: 100%;
    display: block;
    user-select: none;
  }
  &__input {
    width: 100%;
    height: 50px;
    border-radius: 5px;
    box-shadow: none;
    border: 1px solid #ced6e0;
    transition: all 0.3s ease-in-out;
    font-size: 18px;
    padding: 5px 15px;
    background: none;
    color: #1a3b5d;
    font-family: "Source Sans Pro", sans-serif;

    &:hover,
    &:focus {
      border-color: #3d9cff;
    }

    &:focus {
      box-shadow: 0px 10px 20px -13px rgba(32, 56, 117, 0.35);
    }
    &.-select {
      -webkit-appearance: none;
      background-image: url("data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAADIAAAAeCAYAAABuUU38AAAAGXRFWHRTb2Z0d2FyZQBBZG9iZSBJbWFnZVJlYWR5ccllPAAAAUxJREFUeNrM1sEJwkAQBdCsngXPHsQO9O5FS7AAMVYgdqAd2IGCDWgFnryLFQiCZ8EGnJUNimiyM/tnk4HNEAg/8y6ZmMRVqz9eUJvRaSbvutCZ347bXVJy/ZnvTmdJ862Me+hAbZCTs6GHpyUi1tTSvPnqTpoWZPUa7W7ncT3vK4h4zVejy8QzM3WhVUO8ykI6jOxoGA4ig3BLHcNFSCGqGAkig2yqgpEiMsjSfY9LxYQg7L6r0X6wS29YJiYQYecemY+wHrXD1+bklGhpAhBDeu/JfIVGxaAQ9sb8CI+CQSJ+QmJg0Ii/EE2MBiIXooHRQhRCkBhNhBcEhLkwf05ZCG8ICCOpk0MULmvDSY2M8UawIRExLIQIEgHDRoghihgRIgiigBEjgiFATBACAgFgghEwSAAGgoBCBBgYAg5hYKAIFYgHBo6w9RRgAFfy160QuV8NAAAAAElFTkSuQmCC");
      background-size: 12px;
      background-position: 90% center;
      background-repeat: no-repeat;
      padding-right: 30px;
    }
  }
}

.card-authentication-section {
  background: $gray-600;
  padding: 20px;
  text-align: center;
  @include border-radius(7px);
}

.slide-fade-up-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-up-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-up-enter {
  opacity: 0;
  transform: translateY(15px);
  pointer-events: none;
}
.slide-fade-up-leave-to {
  opacity: 0;
  transform: translateY(-15px);
  pointer-events: none;
}

.slide-fade-right-enter-active {
  transition: all 0.25s ease-in-out;
  transition-delay: 0.1s;
  position: relative;
}
.slide-fade-right-leave-active {
  transition: all 0.25s ease-in-out;
  position: absolute;
}
.slide-fade-right-enter {
  opacity: 0;
  transform: translateX(10px) rotate(45deg);
  pointer-events: none;
}
.slide-fade-right-leave-to {
  opacity: 0;
  transform: translateX(-10px) rotate(45deg);
  pointer-events: none;
}
</style>


