var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.conditions.screen == _vm.screens.list.value ? _c('div', {
    staticClass: "mt-24px w-100"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Statuses")]), _c('b-button', {
    staticClass: "newButton",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.add.value;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add status")])], 1)], 1), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again hasSoundFiles w-100",
    attrs: {
      "isListEmpty": _vm.response.status.length == 0,
      "listLength": _vm.response.status.length,
      "loading": _vm.api.get_status.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.response.status, function (status, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: status.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(status.title))]), _c('td', {
            staticClass: "dialer-has-sort text-center dialer-col-center"
          }, [_c('div', {
            staticClass: "createSpace-selectColor-container justify-content-center"
          }, [_c('div', {
            staticClass: "createSpace-selectColor-item selected",
            class: {
              'TransparentSelected': status.color == 'rgba(0,0,0,0)'
            },
            style: `background-color:${status.color};`
          })])]), _c('td', {
            staticClass: "dialer-has-sort text-center dialer-col-center"
          }, [_vm._v(_vm._s(status.type))]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-right"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end filterDD dropdownVersion whiteBGinputWithGreyRoundedBorder buttonHeightAuto buttonWidthAuto"
          }, [_c('b-dropdown', {
            staticClass: "w-auto",
            attrs: {
              "toggle-class": "p-0",
              "right": "",
              "no-caret": "",
              "disabled": !!_vm.api.remove_status.send
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('vb-icon', {
                  staticClass: "mx-auto todo-three-dots-vertical-icon",
                  attrs: {
                    "icon": "todo-three-dots-vertical-icon",
                    "variant": "dark",
                    "width": "6px",
                    "height": "18px"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            on: {
              "click": function ($event) {
                return _vm.setUpdate(status);
              }
            }
          }, [_vm._v("Edit")]), _c('b-dropdown-item', {
            on: {
              "click": function ($event) {
                return _vm.$modal.show('TodoTaskMove', {
                  moved: true,
                  select: 'status',
                  space_id: _vm.space_id,
                  payload: {
                    status_id: status.id,
                    flag: 'status'
                  }
                });
              }
            }
          }, [_vm._v("Move task")]), status.is_global != 'yes' ? _c('b-dropdown-item', {
            on: {
              "click": function ($event) {
                return _vm.remove(status);
              }
            }
          }, [_vm._v("Delete")]) : _vm._e()], 1)], 1)])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "80px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content mx-auto"
          }, [_c('div', {
            staticClass: "md-mar-left latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "21px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content mx-auto"
          }, [_c('div', {
            staticClass: "latestShimmerDesign md-mar-left",
            staticStyle: {
              "height": "21px",
              "width": "21px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center justify-sontent-end w-fit-content ml-auto"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "16px",
              "width": "70px"
            }
          })])])]);
        });
      },
      proxy: true
    }], null, false, 2922107670)
  }, [_c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort py-3 border-0"
  }, [_c('span', [_vm._v("Title")])]), _c('th', {
    staticClass: "dialer-has-sort py-3 text-center dialer-col-center"
  }, [_c('span', [_vm._v("Color")])]), _c('th', {
    staticClass: "dialer-has-sort py-3 text-center dialer-col-center"
  }, [_c('span', [_vm._v("Type")])]), _c('th', {
    staticClass: "dialer-has-sort py-3 dialer-col-right"
  })])])], 1) : _vm._e(), _vm.conditions.screen == _vm.screens.add.value ? _c('form', {
    staticClass: "createSpace-greyBox mt-24px",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.create();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.create_status.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.create_status.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Status title")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_status.name,
      expression: "forms.create_status.name"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "type": "text",
      "placeholder": "Status Title",
      "disabled": _vm.api.create_status.send,
      "maxlength": _vm.$v.forms.create_status.name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.create_status.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_status, "name", $event.target.value);
      }
    }
  }), _vm.forms.create_status.submitted && _vm.$v.forms.create_status.name.$invalid || _vm.api.create_status.validation_errors.name ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_status.name.required ? _c('span', [_vm._v("* Status title is required")]) : !_vm.$v.forms.create_status.name.valid ? _c('span', [_vm._v("* Status title can be only alphabet or number")]) : !_vm.$v.forms.create_status.name.minLength ? _c('span', [_vm._v("* Status title should be minimum " + _vm._s(_vm.$v.forms.create_status.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.create_status.name.maxLength ? _c('span', [_vm._v("* Status title can be maximum " + _vm._s(_vm.$v.forms.create_status.name.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.create_status.validation_errors.name, function (em, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px"
  }, [_c('label', [_vm._v("Type")]), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "disabled": _vm.api.create_status.send
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.types, `${_vm.forms.create_status.type}.text`, 'Select type')) + " ")]), _c('b-icon', {
          staticClass: "darker-chevron-down",
          attrs: {
            "icon": "chevron-down"
          }
        })];
      },
      proxy: true
    }], null, false, 1763821570)
  }, _vm._l(_vm.types, function (type) {
    return _c('b-dropdown-item', {
      key: type.value,
      on: {
        "click": function ($event) {
          _vm.forms.create_status.type = type.value;
        }
      }
    }, [_vm._v(_vm._s(type.text))]);
  }), 1), _vm.forms.create_status.submitted && _vm.$v.forms.create_status.type.$invalid || _vm.api.create_status.validation_errors.type ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_status.type.required ? _c('span', [_vm._v("* Type is required")]) : !_vm.$v.forms.create_status.type.valid ? _c('span', [_vm._v("* Type is invalid")]) : _vm._e(), _vm._l(_vm.api.create_status.validation_errors.type, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px"
  }, [_c('label', [_vm._v("Choose color")]), _c('div', {
    staticClass: "createSpace-selectColor-container"
  }, _vm._l(_vm.colors, function (color, i) {
    return _c('div', {
      key: i,
      staticClass: "createSpace-selectColor-item-container",
      on: {
        "click": function ($event) {
          _vm.api.create_status.send ? '' : _vm.forms.create_status.color = color;
        }
      }
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item",
      class: {
        'selected': _vm.forms.create_status.color == color,
        'TransparentSelected': color == 'rgba(0,0,0,0)'
      },
      style: `background-color:${color};`
    }, [color == 'rgba(0,0,0,0)' ? _c('vb-icon', {
      attrs: {
        "icon": "todo-transparentColored-icon",
        "height": "28px",
        "width": "30px"
      }
    }) : _vm._e()], 1)]);
  }), 0), _vm.forms.create_status.submitted && _vm.$v.forms.create_status.color.$invalid || _vm.api.create_status.validation_errors.color ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_status.color.required ? _c('span', [_vm._v("* Color is required")]) : !_vm.$v.forms.create_status.color.validColor ? _c('span', [_vm._v("* Color is invalid")]) : _vm._e(), _vm._l(_vm.api.create_status.validation_errors.color, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _vm._m(0)], 1) : _vm._e(), _vm.conditions.screen == _vm.screens.update.value ? _c('form', {
    staticClass: "createSpace-greyBox mt-24px",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.update_status.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.update_status.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Status title")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_status.name,
      expression: "forms.update_status.name"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "type": "text",
      "placeholder": "Status title",
      "disabled": _vm.api.update_status.send,
      "maxlength": _vm.$v.forms.update_status.name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_status.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_status, "name", $event.target.value);
      }
    }
  }), _vm.forms.update_status.submitted && _vm.$v.forms.update_status.name.$invalid || _vm.api.update_status.validation_errors.name ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_status.name.required ? _c('span', [_vm._v("* Status title is required")]) : !_vm.$v.forms.update_status.name.valid ? _c('span', [_vm._v("* Status title can be only alphabet or number")]) : !_vm.$v.forms.update_status.name.minLength ? _c('span', [_vm._v("* Status title should be minimum " + _vm._s(_vm.$v.forms.update_status.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.update_status.name.maxLength ? _c('span', [_vm._v("* Status title can be maximum " + _vm._s(_vm.$v.forms.update_status.name.$params.maxLength.max) + " character")]) : _vm._e(), _vm._l(_vm.api.update_status.validation_errors.name, function (em, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _vm.conditions.type_update ? _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px"
  }, [_c('label', [_vm._v("Type")]), _c('b-dropdown', {
    attrs: {
      "no-caret": "",
      "disabled": _vm.api.update_status.send
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', [_vm._v(" " + _vm._s(_vm._f("get_property")(_vm.types, `${_vm.forms.update_status.type}.text`, 'Select type')) + " ")]), _c('b-icon', {
          staticClass: "darker-chevron-down",
          attrs: {
            "icon": "chevron-down"
          }
        })];
      },
      proxy: true
    }], null, false, 2927741559)
  }, _vm._l(_vm.types, function (type) {
    return _c('b-dropdown-item', {
      key: type.id,
      on: {
        "click": function ($event) {
          _vm.forms.update_status.type = type.value;
        }
      }
    }, [_vm._v(_vm._s(type.text))]);
  }), 1), _vm.forms.update_status.submitted && _vm.$v.forms.update_status.type.$invalid || _vm.api.update_status.validation_errors.type ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_status.type.required ? _c('span', [_vm._v("* Type is required")]) : !_vm.$v.forms.update_status.type.valid ? _c('span', [_vm._v("* Type is invalid")]) : _vm._e(), _vm._l(_vm.api.update_status.validation_errors.type, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1) : _vm._e(), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px"
  }, [_c('label', [_vm._v("Choose color")]), _c('div', {
    staticClass: "createSpace-selectColor-container"
  }, _vm._l(_vm.colors, function (color, i) {
    return _c('div', {
      key: i,
      staticClass: "createSpace-selectColor-item-container",
      on: {
        "click": function ($event) {
          _vm.api.update_status.send ? '' : _vm.forms.update_status.color = color;
        }
      }
    }, [_c('div', {
      staticClass: "createSpace-selectColor-item",
      class: {
        'selected': _vm.forms.update_status.color == color,
        'TransparentSelected': color == 'rgba(0,0,0,0)'
      },
      style: `background-color:${color};`
    }, [color == 'rgba(0,0,0,0)' ? _c('vb-icon', {
      attrs: {
        "icon": "todo-transparentColored-icon",
        "height": "28px",
        "width": "30px"
      }
    }) : _vm._e()], 1)]);
  }), 0), _vm.forms.update_status.submitted && _vm.$v.forms.update_status.color.$invalid || _vm.api.update_status.validation_errors.color ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_status.color.required ? _c('span', [_vm._v("* Color is required")]) : !_vm.$v.forms.update_status.color.validColor ? _c('span', [_vm._v("* Color is invalid")]) : _vm._e(), _vm._l(_vm.api.update_status.validation_errors.color, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _vm._m(1)], 1) : _vm._e()]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Add ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 d-flex justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Update ")])]);

}]

export { render, staticRenderFns }