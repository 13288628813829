<template>
  <modal class="ManageMembersFromGroupslModal" name="MembersModal">
    <!-- dialer_animation center-zoomIn-popup m-w-600 dialer-addMemberModal ChatChannelMembersModal -->
    <div class="topBar-withCloseIcon mt-0">
      <h3 class="dialer-box-header w-100 mb-0">Manage Participants</h3>
      <!-- <vb-icon icon="dialer-close-icon" width="14" height="14" @click="$modal.hide('MembersModal')" /> -->
        <button type="button" class="newCloseButton" @click="$modal.hide('MembersModal')">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
    </div>
    <div class="searchBox">
      <b-form class="position-relative" @submit.prevent="''">
        <b-form-input v-model="search" placeholder="Search People" class="w-100" />
        <b-icon icon="search" scale="1" class="position-absolute cursor_pointer searchIcon" />
      </b-form>
    </div>
    <div class="forTabsContainer">
      <div :class="{'active':tabIndex===1}" class="dialer-button dialer-button-primary-outline border-0 v2" @click="tabIndex=1">Participants</div>
      <div :class="{'active':tabIndex===0}" class="dialer-button dialer-button-primary-outline border-0 v2" @click="tabIndex=0">Add Participants</div>
    </div>
    <template v-if="tabIndex===0">
      <ul class="AvailableUsersList">
        <li v-for="user in otherMembers" :key="user.voipaccount" v-show="$store.getters.getCurrentUser.account !== user.voipaccount" class="AvailableUsersList-item">
          <Info :id="user.voipaccount" :is_suspended_show="false" :is_blf="false" />
          <div class="actions">
            <div @click="add(user)">
              <b-icon icon="plus-circle-fill" font-scale="1.75" variant="primary" />
            </div>
          </div>
        </li>
        <vb-no-record v-if="isEmpty(otherMembers)" text="There in no user" :design="3" />
      </ul>
    </template>
    <template v-if="tabIndex===1">
      <ul class="AvailableUsersList">
        <li v-for="user in searchedAddedMembers" :key="user.voipaccount" class="AvailableUsersList-item">
          <Info :id="user.voipaccount" :is_suspended_show="false" :is_blf="false" />
          <template v-if="getProperty(user,'user.user_chat_id')">
            <div class="actions d-flex" v-if="user.user.user_chat_id!==$store.getters.getMattermostCurrentUser.id">
              <template v-if="!isEmpty(currentUserMember) && currentUserMember.roles.indexOf('channel_admin') >= 0">
                <div v-if="user.roles.indexOf('channel_admin') >= 0" @click="roleChange(user.user.user_chat_id,user.members_index,'user')">
                  <!-- <b-icon icon="arrow-down" scale="1.2" style="color:black;" /> -->
                  <vb-icon icon="adminUser-ChatAddMembersModalIcon" height="28px" width="28px"/>
                </div>
                <div v-else @click="roleChange(user.user.user_chat_id,user.members_index,'admin')">
                  <!-- <b-icon icon="arrow-up" scale="1.2" style="color:black;" /> -->
                  <vb-icon icon="onlyUser-ChatAddMembersModalIcon" height="28px" width="28px"/>
                </div>
              </template>
              <div class="ml-2" @click="remove(user)">
                <b-icon icon="dash-circle-fill" font-scale="1.75" variant="danger" />
              </div>
            </div>
          </template>
        </li>
        <vb-no-record v-if="isEmpty(searchedAddedMembers)" text="There in no user" :design="3" />
      </ul>
    </template>
    
    
    
    
    
    
    
    
    
    
    
    <div class="d-flex justify-content-end w-100">
      <button type="button" class="newDoneButton" @click="$modal.hide('MembersModal')">
        <vb-icon icon="squared-done-icon" height="38px" width="38px" />
        <span class="newDoneButton-textPart">Done</span>
      </button>
    </div>
    
    
    
    
    
    <!-- <div class="dialer-chatBox-inVideo"> -->
      <!-- <div class="dialer-videoCall-addMember mb-0 pt-0 position-relative"> -->
        <!-- <div class="px-3 py-3 border-bottom sticky-top"> -->
          <!-- <div class="d-flex align-items-center justify-content-between">
            <h4 class="mb-0">Manage Members</h4>
            <button type="button" class="d-flex dialer-button dialer-button-primary px-4 py-2" style="line-height:1;" @click="$modal.hide('MembersModal')">Done</button>
          </div>
          <div class="pt-3 px-2">
            <div class="searchBox">
              <b-form class="position-relative" @submit.prevent="''">
                <b-form-input v-model="search" class="w-100 p-l-36px" />
                <b-icon icon="search" scale="1" class="position-absolute cursor_pointer" />
                <b-icon icon="x-circle" scale="1" class="position-absolute cursor_pointer" @click="search=''" />
              </b-form>
            </div>
          </div> -->
          <!-- <div class="pt-3 px-2">
            <div class="custome-tabs">
              <div :class="{'active-custome-tab':tabIndex===0}" @click="tabIndex=0">Add Members</div>
              <div :class="{'active-custome-tab':tabIndex===1}" @click="tabIndex=1">
                Group Members
              </div>
            </div>
          </div> -->
        <!-- </div> -->
        <!-- <template v-if="tabIndex===0">
          <ul class="items">
            <li v-for="user in otherMembers" :key="user.voipaccount" v-show="$store.getters.getCurrentUser.account !== user.voipaccount" class="item">
              <div class="info manage-members-channel-chat">
                <vb-avatar :id="user.voipaccount" :image="user.profile_img" />
                <div>
                  <h5 class="text-capitalize">{{ user | get_property('user.display_name') }}</h5>
                  <span class="pl-4">{{ user.extn }}</span>
                </div>
              </div>
              <div class="actions">
                <template v-if="getProperty(user,'user.user_chat_id')">
                  <div @click="call('audio',user.user.user_chat_id)">
                    <font-awesome-icon icon="phone-alt" scale="1.2" style="color:black;" />
                  </div>
                  <div @click="call('video',user.user.user_chat_id)">
                    <b-icon icon="camera-video" scale="1.2" style="color:black;" />
                  </div>
                  <div @click="chat(user.user.user_chat_id)">
                    <b-icon icon="chat" scale="1.2" style="color:black;" />
                  </div>
                </template>
                <div @click="add(user)">
                  <b-icon icon="check2" scale="1.2" variant="success" />
                </div>
              </div>
            </li>
            <vb-no-record v-if="isEmpty(otherMembers)" text="There in no user" :design="3" />
          </ul>
        </template> -->
        <!-- <template v-if="tabIndex===1">
          <ul class="items">
            <li v-for="user in searchedAddedMembers" :key="user.voipaccount" class="item">
              <div class="info manage-members-channel-chat">
                <vb-avatar :id="user.voipaccount" :image="user.profile_img" />
                
                <div>
                  <h5 class="text-capitalize">{{ user | get_property('user.display_name') }}</h5>
                  <span class="pl-4">{{ user.extn }}</span>
                  <span class="pl-4" v-if="user.roles.indexOf('channel_admin') >= 0" style="color:black;">admin</span>
                </div>
              </div>
              
              <template v-if="getProperty(user,'user.user_chat_id')">
                <div class="actions" v-if="user.user.user_chat_id!==$store.getters.getMattermostCurrentUser.id">
                  <div @click="call('audio',user.user.user_chat_id)">
                    <font-awesome-icon icon="phone-alt" scale="1.2" style="color:black;" />
                  </div>
                  <div @click="call('video',user.user.user_chat_id)">
                    <b-icon icon="camera-video" scale="1.2" style="color:black;" />
                  </div>
                  <div @click="chat(user.user.user_chat_id)">
                    <b-icon icon="chat" scale="1.2" style="color:black;" />
                  </div>
                  <div @click="remove(user)">
                    <b-icon icon="x" scale="1.2" variant="danger" />
                  </div>
                  <template v-if="!isEmpty(currentUserMember) && currentUserMember.roles.indexOf('channel_admin') >= 0">
                    <div v-if="user.roles.indexOf('channel_admin') >= 0" @click="roleChange(user.user.user_chat_id,user.members_index,'user')">
                      <b-icon icon="arrow-down" scale="1.2" style="color:black;" />
                    </div>
                    <div v-else @click="roleChange(user.user.user_chat_id,user.members_index,'admin')">
                      <b-icon icon="arrow-up" scale="1.2" style="color:black;" />
                    </div>
                  </template>
                </div>
              </template>
            </li>
            <vb-no-record v-if="isEmpty(searchedAddedMembers)" text="There in no user" :design="3" />
          </ul>
        </template> -->
      <!-- </div> -->
    <!-- </div> -->
  </modal>
</template>

<script>
import { mattermost, MATTERMOST_API } from "@/MatterMost";
import { LOGGER, events } from "@/utils";
import Info from '@/components/Lists/Info.vue'
import _ from 'lodash';
export default {
  name: "MembersModal",
  components: {
    Info,
  },
  props: {
    channel: {
      type: Object,
      default: () => ({}),
    },
    members: {
      type: Array,
      default: () => [],
    },
    currentUserMember: {
      type: Object,
      default: () => ({}),
    },
    channel_id:{
      type: String,
      default: ''
    },
  },
  data() {
    return {
      tabIndex: 1,
      search: "",
      user_male: require("@/assets/images/user-male.png"),
    };
  },
  inject:['isEmpty','getProperty'],
  computed: {
    addedMembers() {
      let added_members = []
      for (let index = 0; index < this.members.length; index++) {
        const member = this.members[index];
        const user = this.$store.getters.getVoipUsersAlises[member.user_id];
        if(!_.isEmpty(user)){
          added_members.push({
            ...user,
            roles: member.roles,
            members_index: index
          })
        }
      }
      return added_members
    },
    searchedAddedMembers(){
      const search = this.search.toLowerCase().trim()
      return this.addedMembers.filter((user) => `${user.extn} ${user?.user?.display_name}`.trim().toLowerCase().includes(search));
    },
    otherMembers() {
      const user_ids = this.members.map((member) => member.user_id);
      const other_members = Object.values(this.$store.state.common.voipusers).filter((user) => user_ids.indexOf(user?.user?.user_chat_id) === -1);
      const search = this.search.toLowerCase().trim()
      return other_members.filter((user) => `${user.extn} ${user?.user?.display_name}`.trim().toLowerCase().includes(search));
    },
  },
  methods: {
    async add(user) {
      try {
        const { data } = await MATTERMOST_API.endpoints.channels.addChannelMember(this.channel.id, {
          user_id: user?.user?.user_chat_id,
        });
        mattermost.emitOnUserLevel(mattermost.events.user_added,{
          user_id: data?.user_id,
          channel_id: data?.channel_id,
        })
      } catch (error) {
        LOGGER.danger("error in add function", error);
      }
    },
    async remove(user) {
      try {
        await MATTERMOST_API.endpoints.channels.removeChannelMember(this.channel.id,user?.user?.user_chat_id);
        mattermost.emitOnUserLevel(mattermost.events.user_removed,{
          user_id: user?.user?.user_chat_id,
          channel_id: this.channel.id,
        })
      } catch (error) {
        LOGGER.danger("error in add function", error);
      }
    },
    roleChange(id,index,action){
      let vm=this
      if(action==='admin'){
        MATTERMOST_API.endpoints.channels.updateMemberRole(this.channel_id,id,{
          scheme_admin: true,
          scheme_user: true
        }).then(()=>{
          vm.$store.state.mattermost.channels[vm.channel_id].members[index].roles='channel_user channel_admin'
          vm.$store.state.mattermost.channels[vm.channel_id].members[index].scheme_admin=true
        })
      } else {
        MATTERMOST_API.endpoints.channels.updateMemberRole(this.channel_id,id,{
          scheme_admin: false,
          scheme_user: true
        }).then(()=>{
          vm.$store.state.mattermost.channels[vm.channel_id].members[index].roles='channel_user'
          vm.$store.state.mattermost.channels[vm.channel_id].members[index].scheme_admin=false
        })
      }
    },
    async chat(id) {
      try {
        const user = this.$store.getters.getVoipUsersAlises[id];
        if (_.isEmpty(user)) return;
        this.$root.$emit(events.change_chat_tab, 2);
        let channel_id = user.channel_id
        if(!channel_id){
          // const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([user?.user?.user_chat_id, this.mattermost_current_user.id]);
          const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([user?.user?.user_chat_id, this.$store.getters.getMattermostCurrentUser?.id]);
          mattermost.emitOnUserLevel(mattermost.events.direct_added,{
            channel_id: channel.id
          })
          this.$set(this.$store.state.common.voipusers[user.voipaccount],'channel_id',channel.id)
          // this.$root.$emit(events.adding_channel,{
          //   channel_id: channel.id,
          //   data: {
          //     channel: channel,
          //     type: 'user',
          //     // data: user,
          //     id: user?.voipaccount,
          //   }
          // })
          channel_id = channel.id
        }
        this.$parent.$parent.channel_id = channel_id;
        this.$modal.hide('MembersModal')
      } catch (error) {
        LOGGER.danger("error in chat function", error);
      }
    },
    call(call_type,id){
      try {
        const accounts = [];
        const user = this.$store.getters.getVoipUsersAlises[id];
        if(_.isEmpty(user)) throw new Error('User is Empty')
        accounts.push(user.voipaccount);
        this.$root.$emit(events.video_call, {
          accounts,
          call_type,
        });
        this.$modal.hide('MembersModal')
      } catch (error) {
        LOGGER.danger("error in call function", error);
      }
    },
  },
};
</script>

<style lang="scss">
.dialer-chatBox-inVideo {
  .dialer-videoCall-addMember {
    padding: 0px 0px 20px;
    h5 {
      font-size: 1rem;
      font-weight: 600;
      padding: 0px 20px;
    }
    .items {
      padding: 0px;
      margin: 0px;
      .item {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info {
          display: flex;
          max-width: calc(100% - 30px);
          align-items: center;
          img {
            height: inherit;
            width: 40px;
            border-radius: 50%;
          }
          div {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            height: 100%;
            h5 {
              margin: 0;
              color: $black;
            }
            p {
              margin: 0;
            }
          }
        }
        .actions {
          display: flex;
          justify-content: center;
          align-items: center;
          div {
            cursor: pointer;
            border-radius: 50%;
            color: white;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  .dialer-addAble-users-array {
    .dialer_contact_row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow-x: auto;
      margin: 0px 10px;
      max-width: calc(100% - 20px);
      .dialer_contact {
        padding: 7px 5px;
        width: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
        .dialer_contact_img {
          position: relative;
          margin-right: 10px;
          cursor: auto;
          img {
            width: 41px;
            height: 41px;
            @include border-radius(50%);
            object-fit: cover;
          }
        }
      }
      &:hover {
        background: transparent;
      }
    }
    .close-popup {
      top: -2px;
      right: -2px;
    }
  }
  .dialer-button {
    &:not(.dialer-button-blank) {
      height: 35px;
      line-height: 35px;
      @include border-radius(50px);
      background: $purple;
    }
  }
  .sticky-top {
    background: $white;
  }
}
.custome-tabs {
  display: flex;
  justify-content: space-between;
  div {
    height: inherit;
    flex: 1;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    padding: 10px;
  }
  .active-custome-tab {
    color: $primary;
    border-bottom: 2px solid $primary;
  }
}
// slide top to bottom &bottom to top animation
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
