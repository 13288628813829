<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="condition.screen==screens.number">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="condition.screen=screens.furtur_setting">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Seconds</div>
          <div class="plus-IOSIcon-container">
            
          </div>
        </div>
        <div class="innerModalIos">
          <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Number</label>
                  <input 
                    placeholder="Enter Number" 
                    class="w-100" 
                    onkeypress="return /[0-9]/i.test(event.key)" 
                    type="text" 
                    :maxlength="$v.forms.forwarding_setting.forward_number.$params.maxLength.max"
                    v-model="forms.forwarding_setting.forward_number" 
                    @change="updateForwardingSetting()"
                  />
                  <p 
                    v-if="forms.forwarding_setting.forward_note === 'number' && forms.forwarding_setting.submitted && $v.forms.forwarding_setting.forward_number.$invalid"
                    class="error-red" 
                  >
                    <span v-if="!$v.forms.forwarding_setting.forward_number.required">* number is required</span>
                    <span 
                      v-if="!$v.forms.forwarding_setting.forward_number.minLength"
                    >
                      * number should be minimum {{$v.forms.forwarding_setting.forward_number.$params.minLength.min}} character
                    </span>
                    <span 
                      v-if="!$v.forms.forwarding_setting.forward_number.maxLength"
                    >
                      * number can be maximum {{$v.forms.forwarding_setting.forward_number.$params.maxLength.max}} character
                    </span>
                  </p>
                </div>
              </div>
            </div>
          </form>
        </div>
      </template>
      <template v-else-if="condition.screen==screens.select_seconds">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="condition.screen=screens.furtur_setting">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Seconds</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="second in seconds_options"
                :key="second.value"
                @click="forms.forwarding_setting.timeout=second.value;condition.screen=screens.furtur_setting;updateForwardingSetting();"
              >
                <div class="headingInsideCard">{{ second.text }}</div>
                <div class="d-flex align-items-center">
                  <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.forwarding_setting.timeout==second.value" width="16.642" height="15.275" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>
      <template v-else-if="condition.screen==screens.furtur_setting">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="condition.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Call Forwarding</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <!-- seconds -->
              <div 
                v-if="condition.is_sec_show && forms.forwarding_setting.forward_note!='off'" 
                class="basicWhiteIOScard-item" 
                @click="condition.screen=screens.select_seconds"
              >
                <div class="headingInsideCard">Seconds</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-16px">{{ forms.forwarding_setting.timeout }} Seconds </div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <!-- number -->
              <div 
                v-if="forms.forwarding_setting.forward_note=='number'"
                class="basicWhiteIOScard-item" 
                @click="condition.screen=screens.number"
              >
                <div class="headingInsideCard">Number</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-16px">{{ forms.forwarding_setting.forward_number | number_formater }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <!-- extension -->
              <div 
                v-if="forms.forwarding_setting.forward_note=='extension'"
                class="basicWhiteIOScard-item flex-column" 
                @click="$modal.show(`${randomID}-CallForwarding`, { 
                  account: forms.forwarding_setting.forward_number, 
                  type: 'setting', 
                })"
              >
                <div class="d-flex w-100 lign-items-center justify-content-between">
                  <div class="headingInsideCard">Extension</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-16px">{{ forms.forwarding_setting.extension_name }}</div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
                <p 
                  v-if="forms.forwarding_setting.forward_note === 'extension' && forms.forwarding_setting.submitted && $v.forms.forwarding_setting.extension_name.$invalid"
                  class="error-red w-100 mb-0" 
                >
                  <span v-if="!$v.forms.forwarding_setting.extension_name.required">* extension is required</span>
                </p>
              </div>

            </div>
            <SelectExtensionModal 
              :modalName="`${randomID}-CallForwarding`" 
              @selected="forms.forwarding_setting.extension_name=$event.accountname;forms.forwarding_setting.forward_number=$event.account;updateForwardingSetting();" 
            />
          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Call Forwarding</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              
              <div class="basicWhiteIOScard-item">
                <template v-if="forms.forwarding_setting.forward_note == 'voicemail'">
                  <div class="headingInsideCard">Ask to leave a voicemail</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">
                      <template v-if="condition.is_sec_show">
                        If phone rings for {{ forms.forwarding_setting.timeout || 60 }} Seconds
                      </template> 
                      call will be forward to voicemail
                    </div>
                  </div>
                </template>
                <template v-else-if="forms.forwarding_setting.forward_note == 'extension'">
                  <div class="headingInsideCard">Redirect to a user, team or IVR menu</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">
                      <template v-if="condition.is_sec_show">
                        If phone rings for {{ forms.forwarding_setting.timeout || 60 }} Seconds
                      </template> 
                      call will be forwarded to {{ forms.forwarding_setting.extension_name || 'Select User' }}
                    </div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </template>
                <template v-else-if="forms.forwarding_setting.forward_note == 'number'">
                  <div class="headingInsideCard">Redirect to another number</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">
                      <template v-if="condition.is_sec_show">
                        If phone rings for {{ forms.forwarding_setting.timeout || 60 }} Seconds
                      </template> 
                      call will be forwarded to {{ (forms.forwarding_setting.forward_number || 'Add Number') | number_formater }}
                    </div>
                  </div>
                </template>
                <template v-else>
                  <div class="headingInsideCard">Forwarding</div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">The phone will keep ringing</div>
                  </div>
                </template>
              </div>

            </div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              
              <div v-if="condition.is_ringing_show" class="basicWhiteIOScard-item" @click="forms.forwarding_setting.forward_note='off';updateForwardingSetting();">
                <div class="headingInsideCard">OFF</div>
                <div class="d-flex align-items-center">
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>

              <div 
                class="basicWhiteIOScard-item" 
                @click="
                  condition.is_sec_show ? condition.screen=screens.furtur_setting : '';
                  forms.forwarding_setting.forward_note='voicemail';
                  updateForwardingSetting();
                "
              >
                <div class="headingInsideCard">Ask to leave a voicemail</div>
                <div class="d-flex align-items-center">
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>

              <div 
                class="basicWhiteIOScard-item" 
                @click="condition.screen=screens.furtur_setting;forms.forwarding_setting.forward_note='extension';updateForwardingSetting();"
              >
                <div class="headingInsideCard">Redirect to a user, team or IVR menu</div>
                <div class="d-flex align-items-center">
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>

              <div 
                class="basicWhiteIOScard-item" 
                @click="condition.screen=screens.furtur_setting;forms.forwarding_setting.forward_note='number';updateForwardingSetting();"
              >
                <div class="headingInsideCard">Redirect to another number</div>
                <div class="d-flex align-items-center">
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>

            </div>
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { minLength, maxLength, requiredIf } from "vuelidate/lib/validators";
import SelectExtensionModal from "../extensions/SelectExtension.vue";
let screens = {
  main: 'main',
  furtur_setting: 'furtur_setting',
  select_seconds: 'select_seconds',
  number: 'number',
}
export default {
  name: "CallForwardSettingModal",
  components: {
    SelectExtensionModal,
  },
  props: {
    modalName: {
      type: String,
      default: "CallForwardingSettingModal",
    },
    randomID: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      forms: {
        forwarding_setting: this.$helperFunction.formInstance({
          data: {
            forward_setting: "",
            forward_note: "",
            forward_number: "",
            timeout: 0,
            extension_name: "",
            number: "",
          },
        }),
        reset(){
          this.forwarding_setting?.reset?.()
        },
      },
      data: this.$helperFunction.stateInstance({
        data: {
          account: '',
        },
      }),
      condition: this.$helperFunction.stateInstance({
        data: {
          is_ringing_show: true,
          is_sec_show: true,
          screen: screens.main,
        },
      }),
    };
  },
  computed: {
    screens(){ return screens },
    seconds_options(){ 
      const limit = 90
      const interval = 5
      return new Array(limit/interval).fill().map((v,i)=>{ const s=(i+1)*interval; return { text: `${s} seconds`, value: s } }) 
    },
  },
  validations: {
    forms: {
      forwarding_setting: {
        forward_number: {
          required: requiredIf(function(){
            return ['extension','number'].includes(this.forms.forwarding_setting.forward_note)
          }),
          minLength: minLength(8),
          maxLength: maxLength(15),
        },
        extension_name: {
          required: requiredIf(function(){
            return ['extension'].includes(this.forms.forwarding_setting.forward_note)
          }),
        }
      },
    },
  },
  watch: {
    "forms.forwarding_setting.forward_note"(new_val,old_val){
      if(old_val){
        this.forms.forwarding_setting.forward_number = '';
        this.forms.forwarding_setting.extension_name = '';
      }
      if(old_val=='off'){
        this.forms.forwarding_setting.timeout = 60;
      }
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.account=event?.params?.account ?? ''
      this.condition.is_ringing_show=event?.params?.is_ringing_show ?? true;
      this.condition.is_sec_show=event?.params?.is_sec_show ?? true;
      const setting = event?.params?.setting ?? {}
      this.forms.forwarding_setting.forward_setting = setting?.forward_setting ?? "";
      this.forms.forwarding_setting.forward_note = setting?.forward_note=='none' ? 'off' : setting?.forward_note ?? "";
      this.forms.forwarding_setting.forward_number = setting?.forward_number ?? "";
      this.forms.forwarding_setting.timeout = setting?.timeout ?? 0;
      this.forms.forwarding_setting.extension_name = setting?.extension_name ?? "";
      this.forms.forwarding_setting.number = setting?.number ?? "";
    },
    onBeforeClose(){
      this.forms?.reset?.()
      this.data?.reset?.()
      this.condition?.reset?.()
    },
    updateForwardingSetting() {
      let vm = this;
      vm.forms.forwarding_setting.submitted=true
      vm.$v.forms.forwarding_setting.$touch()
      if(vm.$v.forms.forwarding_setting.$invalid) return;
      vm.$emit('updated',vm.forms.forwarding_setting)
      vm.forms.forwarding_setting.submitted=false
    },
  },
};
</script>

