<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.organization_user.send ? '' : $modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add User By Username</div>
        <div class="plus-IOSIcon-container" @click="$modal.show('CheckedSlot',{ service: 'user', onProceed: adduser })">
          <div class="textInsideCard onRightSide primaryColor">Detail</div>
        </div>
      </div>
      <div class="innerModalIos">

        <form class="dialer-form lg-mar-bottom" @submit.prevent="addorganizationuser()">
          <b-alert class="mt-32px" :show="!!api.organization_user.error_message" variant="danger">{{api.organization_user.error_message}}</b-alert>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Username</label>
                <b-input-group>
                  <b-form-input class="withTextOnRight"
                    placeholder="Enter Username" 
                    :maxlength="$v.forms.organization_user.name.$params.maxLength.max" 
                    type="text" 
                    :disabled="api.organization_user.send" 
                    v-model="forms.organization_user.name" 
                  />
                  <b-input-group-append>
                    <span>@{{ getCurrentUser.company }}</span>
                  </b-input-group-append>
                </b-input-group>
                
                <p 
                  v-if="(forms.organization_user.submitted && $v.forms.organization_user.name.$invalid) || api.organization_user.validation_errors.name" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.organization_user.name.required">* Username is Required</span>
                  <span 
                    v-else-if="!$v.forms.organization_user.name.minLength"
                  >
                    * Username should be minimum {{ $v.forms.organization_user.name.$params.minLength.max }} character
                  </span>
                  <span 
                    v-else-if="!$v.forms.organization_user.name.maxLength"
                  >
                    * Username can be maximum {{ $v.forms.organization_user.name.$params.maxLength.max }} character
                  </span>
                  <span v-else-if="!$v.forms.organization_user.name.spaceInclude">* Spaces are not allowed in username</span>
                  <span v-else-if="!$v.forms.organization_user.name.alphaNum">* Username should be an alphanumeric</span>
                  <span v-for="(em, i) in api.organization_user.validation_errors.name" :key="i">* {{em}}</span>
                </p>
              </div>
            </div>
            
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Full Name</label>
                <input 
                  placeholder="Enter Full Name" 
                  :maxlength="$v.forms.organization_user.display_name.$params.maxLength.max" 
                  class="w-100" 
                  type="text" 
                  onkeypress="return /^[A-Za-z]+$/.test(event.key) || event.key==' '"
                  :disabled="api.organization_user.send" 
                  v-model="forms.organization_user.display_name" 
                />
                <p 
                  v-if="(forms.organization_user.submitted && $v.forms.organization_user.display_name.$invalid) || api.organization_user.validation_errors.display_name" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.organization_user.display_name.required">* Full Name is Required</span>
                  <span 
                    v-else-if="!$v.forms.organization_user.display_name.minLength"
                  >
                    * Full Name should be minimum {{ $v.forms.organization_user.display_name.$params.minLength.min }} character
                  </span>
                  <span 
                    v-else-if="!$v.forms.organization_user.display_name.maxLength"
                  >
                    * Full Name can be maximum {{ $v.forms.organization_user.display_name.$params.maxLength.max }} character
                  </span>
                  <span v-else-if="!$v.forms.organization_user.display_name.alpha">* Full Name must be only alphabets</span>
                  <span v-else-if="!$v.forms.organization_user.display_name.valid">* Full Name must have a space character</span>
                  <span v-for="(em, i) in api.organization_user.validation_errors.display_name" :key="i">* {{em}}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="basicWhiteIOScard mt-16px">  
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Automatically create a password</div>
                </div>
              </div>
              <div class="position-relative">
                <b-form-checkbox 
                  class="dialer-switch IosSwitch" 
                  :disabled="api.organization_user.send"
                  v-model="forms.organization_user.is_passwword_manual" 
                  @input="forms.organization_user.is_send_email=false" 
                  switch 
                />
              </div>
            </div>

            <template v-if="!forms.organization_user.is_passwword_manual">
              
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Password</label>
                  <input 
                    class="w-100" 
                    :type="conditions.organization_user_password_show ? 'text' : 'password'"
                    placeholder="Enter password"
                    :disabled="api.organization_user.send"
                    readonly
                    onfocus="this.removeAttribute('readonly');"
                    v-model="forms.organization_user.password"
                    :maxlength="$v.forms.organization_user.password.$params.maxLength.max"
                  />
                  <b-icon
                    class="dialer-showPassword-icon cursor_pointer"
                    :icon="conditions.organization_user_password_show ? 'eye-slash' : 'eye'"
                    @click="api.organization_user.send ? '' : conditions.organization_user_password_show = !conditions.organization_user_password_show"
                  />
                  <p 
                    v-if="(forms.organization_user.submitted && $v.forms.organization_user.password.$invalid) || api.organization_user.validation_errors.password" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.organization_user.password.required">* Password is required</span>
                    <span v-else-if="!$v.forms.organization_user.password.minLength">* Password should be minimum 3 character</span>
                    <span v-else-if="!$v.forms.organization_user.password.maxLength">* Password can be maximum 20 character</span>
                    <span v-for="(em, i) in api.organization_user.validation_errors.password" :key="i">* {{em}}</span>
                  </p>
                </div>
              </div>

              <div class="basicWhiteIOScard-item">
                <div class="d-flex align-items-start">
                  <div class="d-flex flex-column">
                    <div class="headingInsideTable">Send password in email upon completion</div>
                  </div>
                </div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    :disabled="api.organization_user.send"
                    v-model="forms.organization_user.is_send_email" 
                    switch 
                  />
                </div>
              </div>

            </template>
          </div>
          <template v-if="forms.organization_user.is_send_email">
            <div class="textAboveCard">Email the new password to the following recepients</div>
            <div class="basicWhiteIOScard mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Email</label>
                  <input 
                    class="w-100" 
                    type="text"
                    placeholder="Enter Email"
                    :disabled="api.organization_user.send"  
                    v-model="forms.organization_user.email"
                  />
                  <p 
                    v-if="(forms.organization_user.submitted && $v.forms.organization_user.email.$invalid) || api.organization_user.validation_errors.email" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.organization_user.email.required">* email is required</span>
                    <span v-else-if="!$v.forms.organization_user.email.email">* email is invalid</span>
                    <span v-for="(em, i) in api.organization_user.validation_errors.email" :key="i">* {{em}}</span>
                  </p>
                </div>
              </div>
            </div>
          </template>
          <div class="basicWhiteIOScard mt-16px mb-16px">  
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Set as admin</div>
                </div>
              </div>
              <div class="position-relative">
                <b-form-checkbox class="dialer-switch IosSwitch" :disabled="api.organization_user.send" v-model="forms.organization_user.role" switch />
              </div>
              <p 
                v-if="(forms.organization_user.submitted && $v.forms.organization_user.role.$invalid) || api.organization_user.validation_errors.role" 
                class="text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.organization_user.role.required">* Email is Required</span>
                <span v-for="(em, i) in api.organization_user.validation_errors.role" :key="i">* {{em}}</span>
              </p>
            </div>

          </div>
          <button class="IosFullWidthButton forDone mb-16px" :disabled="api.organization_user.send">
            <vb-spinner v-if="api.organization_user.send" />
            <template v-else>Add</template>
          </button>
        </form>

      </div>
    </modal>
  </div>
</template>

<script>
import { required, minLength, maxLength, requiredIf, email, alphaNum, } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';

import { VOIP_API } from "@/utils/index";

export default {
  name: "AddUserByUserNameModal",
  props: {
    modalName: {
      type: String,
      default: 'AddUserByUserName',
    },
  },
  data() {
    return {
      forms: {
        organization_user: this.$helperFunction.formInstance({
          data: {
            name: "",
            display_name: "",
            password: "",
            is_passwword_manual: true,
            is_send_email: false,
            email: '',
            role: false,
          },
        }),
      },
      api: {
        organization_user: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
      },
      conditions: {
        organization_user_password_show: false,
        organization_user_show_more: false,
        reset(){
          this.organization_user_password_show = false
          this.organization_user_show_more = false
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
  },
  validations: {
    forms: {
      organization_user: {
        name: {
          required,
          alphaNum,
          minLength: minLength(4), 
          maxLength: maxLength(40),
          spaceInclude(value){
            return !value.includes(' ')
          },
        },
        display_name: {
          required,
          minLength: minLength(3), 
          maxLength: maxLength(40),
          alpha: function(value){ return /^[a-zA-Z ]*$/.test(value) },
          valid: function(value){
            const value1 = value.split(' ')
            return !!value1[0] && !!value1[1]
          },
        },
        password: {
          required: requiredIf(function () {
            return !this.forms.organization_user.is_passwword_manual
          }),
          minLength: minLength(3), 
          maxLength: maxLength(20),
        },
        email: {
          required: requiredIf(function () {
            return this.forms.organization_user.is_send_email
          }),
          email,
        },
        role: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      //TODO
    },
    onBeforeClose(){
      this.forms.organization_user.reset()
      this.api.organization_user.reset()
      this.conditions.reset()
    },
    addorganizationuser(){
      let vm = this
      vm.forms.organization_user.submitted=true
      vm.$v.forms.organization_user.$touch()
      if(vm.api.organization_user.send || vm.$v.forms.organization_user.$invalid) return;
      vm.api.organization_user.validation_errors={}
      vm.api.organization_user.error_message=''
      vm.api.organization_user.send=true
      VOIP_API.endpoints.user.addorganizationuser({
        username: `${vm.forms.organization_user.name}`,
        display_name: vm.forms.organization_user.display_name,
        password: !vm.forms.organization_user.is_passwword_manual ? vm.forms.organization_user.password : null,
        email: vm.forms.organization_user.is_send_email ? vm.forms.organization_user.email : `${vm.forms.organization_user.name}@${vm.getCurrentUser?.company.replace(/ /g, "")}.com`,
        is_passwword_manual: !vm.forms.organization_user.is_passwword_manual,
        is_send_email: vm.forms.organization_user.is_send_email,
        role: vm.forms.organization_user.role ? 1 : 0,
        uid: vm.getCurrentUser?.uid,
        accountcode: vm.getCurrentUser?.account,
      })
      .then(()=>{
        vm.$emit("user-added");
        vm.$modal.hide(vm.modalName)
      })
      .catch((ex)=>{
        vm.api.organization_user.error_message=ex.own_message
        vm.api.organization_user.validation_errors=ex.own_errors || {}
      })
      .finally(()=>{
        vm.api.organization_user.send=false
        vm.forms.organization_user.submitted=false
      })
    },
  },
};
</script>


<style>
.slide-ltr-enter-active {
  animation: slide-ltr 0.5s reverse;
}
.slide-ltr-leave-active {
  animation: slide-ltr 0.5s;
}
@keyframes slide-ltr {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>