var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll",
    attrs: {
      "name": "AddPaymentType",
      "clickToClose": true,
      "scrollable": true
    }
  }, [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("New payment type")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('AddPaymentType');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Title")]), _c('input', {})]), _c('div', {
    staticClass: "grayCard borderRadius-12px flexColumn px-4 mt-32px"
  }, [_c('div', {
    staticClass: "innerShadowInput-container mb-0"
  }, [_c('label', {}, [_vm._v("Training requests with this payment type:")])]), _c('b-form-group', {
    staticClass: "HrmRadio-container mt-16px w-100"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Does not require approval ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Requires approval from direct manager ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Requires approval from specific employee ")])], 1)], 1), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Send email to specific person when training is approved ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Create a task for employee when training is approved ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Make \"Costs\" field mandatory ")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Add ")])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }