var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "ManageMembersFromGroupslModal",
    attrs: {
      "name": "MembersModal"
    }
  }, [_c('div', {
    staticClass: "topBar-withCloseIcon mt-0"
  }, [_c('h3', {
    staticClass: "dialer-box-header w-100 mb-0"
  }, [_vm._v("Manage Participants")]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('MembersModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "searchBox"
  }, [_c('b-form', {
    staticClass: "position-relative",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "placeholder": "Search People"
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _c('b-icon', {
    staticClass: "position-absolute cursor_pointer searchIcon",
    attrs: {
      "icon": "search",
      "scale": "1"
    }
  })], 1)], 1), _c('div', {
    staticClass: "forTabsContainer"
  }, [_c('div', {
    staticClass: "dialer-button dialer-button-primary-outline border-0 v2",
    class: {
      'active': _vm.tabIndex === 1
    },
    on: {
      "click": function ($event) {
        _vm.tabIndex = 1;
      }
    }
  }, [_vm._v("Participants")]), _c('div', {
    staticClass: "dialer-button dialer-button-primary-outline border-0 v2",
    class: {
      'active': _vm.tabIndex === 0
    },
    on: {
      "click": function ($event) {
        _vm.tabIndex = 0;
      }
    }
  }, [_vm._v("Add Participants")])]), _vm.tabIndex === 0 ? [_c('ul', {
    staticClass: "AvailableUsersList"
  }, [_vm._l(_vm.otherMembers, function (user) {
    return _c('li', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account !== user.voipaccount,
        expression: "$store.getters.getCurrentUser.account !== user.voipaccount"
      }],
      key: user.voipaccount,
      staticClass: "AvailableUsersList-item"
    }, [_c('Info', {
      attrs: {
        "id": user.voipaccount,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _c('div', {
      staticClass: "actions"
    }, [_c('div', {
      on: {
        "click": function ($event) {
          return _vm.add(user);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "plus-circle-fill",
        "font-scale": "1.75",
        "variant": "primary"
      }
    })], 1)])], 1);
  }), _vm.isEmpty(_vm.otherMembers) ? _c('vb-no-record', {
    attrs: {
      "text": "There in no user",
      "design": 3
    }
  }) : _vm._e()], 2)] : _vm._e(), _vm.tabIndex === 1 ? [_c('ul', {
    staticClass: "AvailableUsersList"
  }, [_vm._l(_vm.searchedAddedMembers, function (user) {
    return _c('li', {
      key: user.voipaccount,
      staticClass: "AvailableUsersList-item"
    }, [_c('Info', {
      attrs: {
        "id": user.voipaccount,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _vm.getProperty(user, 'user.user_chat_id') ? [user.user.user_chat_id !== _vm.$store.getters.getMattermostCurrentUser.id ? _c('div', {
      staticClass: "actions d-flex"
    }, [!_vm.isEmpty(_vm.currentUserMember) && _vm.currentUserMember.roles.indexOf('channel_admin') >= 0 ? [user.roles.indexOf('channel_admin') >= 0 ? _c('div', {
      on: {
        "click": function ($event) {
          return _vm.roleChange(user.user.user_chat_id, user.members_index, 'user');
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "adminUser-ChatAddMembersModalIcon",
        "height": "28px",
        "width": "28px"
      }
    })], 1) : _c('div', {
      on: {
        "click": function ($event) {
          return _vm.roleChange(user.user.user_chat_id, user.members_index, 'admin');
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "onlyUser-ChatAddMembersModalIcon",
        "height": "28px",
        "width": "28px"
      }
    })], 1)] : _vm._e(), _c('div', {
      staticClass: "ml-2",
      on: {
        "click": function ($event) {
          return _vm.remove(user);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "dash-circle-fill",
        "font-scale": "1.75",
        "variant": "danger"
      }
    })], 1)], 2) : _vm._e()] : _vm._e()], 2);
  }), _vm.isEmpty(_vm.searchedAddedMembers) ? _c('vb-no-record', {
    attrs: {
      "text": "There in no user",
      "design": 3
    }
  }) : _vm._e()], 2)] : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-end w-100"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('MembersModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }