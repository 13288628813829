var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_vm.conditions.screen == _vm.screens.change_tab ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Music On Hold")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('section', {
    staticClass: "dialer-settings-section dialer-musicOnHold-settings"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.id;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v(_vm._s(tab.text))]), _vm.selected.tab == tab.id ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Music On Hold")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('section', {
    staticClass: "dialer-settings-section dialer-musicOnHold-settings"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('MusicOnHoldWidget', {
    staticClass: "noBottomBorder",
    attrs: {
      "accountcode": _vm.getCurrentUser.default_accountcode
    }
  })], 1), _c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-22px"
  }, [_vm.selected.tab == _vm.tabs.system.id ? _c('b-input', {
    attrs: {
      "placeholder": "Search Playlists",
      "type": "text"
    },
    model: {
      value: _vm.filter.system_playlist.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.system_playlist, "search", $$v);
      },
      expression: "filter.system_playlist.search"
    }
  }) : _vm.selected.tab == _vm.tabs.custom.id ? _c('b-input', {
    attrs: {
      "placeholder": "Search Playlists",
      "type": "text"
    },
    model: {
      value: _vm.filter.playlist.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.playlist, "search", $$v);
      },
      expression: "filter.playlist.search"
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Playlists")]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.change_tab;
      }
    }
  }, [_vm._m(0), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard mr-8px textAlignRight onRightSide"
  }, [_vm._v(_vm._s(_vm.selectedTab.text))]), _c('div', {
    class: {
      'rotate-svg IosVersion': _vm.conditions.select_tab
    }
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])])]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_vm.conditions.select_tab ? _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.id;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideCard"
    }, [_vm._v(_vm._s(tab.text))]), _vm.selected.tab == tab.id ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }) : _vm._e(), _vm.selected.tab == _vm.tabs.system.id ? [_vm._l(_vm.filterSystemPlaylist, function (playlist) {
    return _c('div', {
      key: playlist.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.checkPermission(_vm.getUserPermissions.multi_moh, function () {
            return _vm.$modal.show('SystemPlaylist', {
              id: playlist.id,
              name: playlist.label
            });
          });
        }
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(" " + _vm._s(playlist.label) + " ")])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [playlist.playlist.length > 0 ? _c('vb-audio-player', {
      staticClass: "mr-6px IosAudioPlayer",
      attrs: {
        "src": _vm._f("get_property")(playlist, 'playlist[0].sound_file.sound')
      }
    }) : _vm._e(), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)]);
  }), _vm.filterSystemPlaylist.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.playlists.send ? '' : 'There is no playlist',
      "design": 3
    }
  }, [_vm.api.playlists.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()] : _vm.selected.tab == _vm.tabs.custom.id ? [_vm._l(_vm.filterCustomPlaylist, function (playlist) {
    return _c('div', {
      key: playlist.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.checkPermission(_vm.getUserPermissions.multi_moh, function () {
            return _vm.$modal.show('EditPlaylist', {
              id: playlist.id,
              name: playlist.label
            });
          });
        }
      }
    }, [_c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(playlist.label))])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [playlist.playlist.length > 0 ? _c('vb-audio-player', {
      staticClass: "mr-6px IosAudioPlayer",
      attrs: {
        "src": _vm._f("get_property")(playlist, 'playlist[0].sound_file.sound')
      }
    }) : _vm._e(), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "7.066",
        "height": "11.57"
      }
    })], 1)]);
  }), _vm.filterCustomPlaylist.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.playlists.send ? '' : 'There is no playlist',
      "design": 3
    }
  }, [_vm.api.playlists.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()] : _vm._e()], 2), _c('AddPlayListModal', {
    on: {
      "interface": _vm.afterAdding
    }
  }), _c('SelectPlaylistModal', {
    on: {
      "updated": function ($event) {
        return _vm.callRouteSetting();
      },
      "close": function ($event) {
        return _vm.afterAdding();
      }
    }
  }), _c('SystemPlaylistModal'), _c('EditPlaylistModal', {
    on: {
      "close": function ($event) {
        return _vm.afterAdding();
      },
      "updated": function ($event) {
        return _vm.callRouteSetting();
      }
    }
  })], 1)]], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Playlist type")])]);

}]

export { render, staticRenderFns }