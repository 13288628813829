<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add Porting Number</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">

        <form class="dialer-form lg-mar-bottom" @submit.prevent="addPortNumber()">
          <b-alert class="mt-32px" :show="!!api.add_port_number.error_message" variant="danger">{{api.add_port_number.error_message}}</b-alert>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Name</label>
                <input 
                  placeholder="Enter Number" 
                  class="w-100" 
                  :disabled="api.add_port_number.send" 
                  min="1" 
                  type="number" 
                  onkeypress="return /([0-9])/i.test(event.key)" 
                  v-model="forms.add_port_number.number" 
                />
                <p 
                  v-if="(forms.add_port_number.submitted && $v.forms.add_port_number.number.$invalid) || api.add_port_number.validation_errors.number" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.add_port_number.number.required">* Number is required</span>
                  <span v-else-if="!$v.forms.add_port_number.number.minValue">* Number is minimum 1</span>
                  <span v-for="error_number in api.add_port_number.validation_errors.number" :key="error_number">* {{ error_number }}</span>
                </p>
              </div>
            </div>
          </div>
          <button class="IosFullWidthButton forDone mb-16px" :disabled="api.add_port_number.send">
            <vb-spinner v-if="api.add_port_number.send" />
            <template v-else>Add</template>
          </button>
        </form>

      </div>
    </modal>
  </div>
</template>

<script>
import { required, minValue, maxLength } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils/index';
export default {
  name: "AddPortNumberModal",
  props: {
    modalName: {
      type: String,
      default: 'AddPortNumber',
    },
  },
  data(){
    return {
      forms: {
        add_port_number: this.$helperFunction.formInstance({
          data: {
            number: '',
          },
        }),
      },
      api: {
        add_port_number: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true, })
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ])
  },
  validations: {
    forms: {
      add_port_number: {
        number: {
          required,
          minValue: minValue(1),
          maxLength: maxLength(12),
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      // TODO
    },
    onBeforeClose(){
      // TODO
    },
    addPortNumber(){
      let vm = this
      vm.forms.add_port_number.submitted=true
      vm.$v.forms.add_port_number.$touch();
      if (vm.$v.forms.add_port_number.$invalid || vm.api.add_port_number.send) return;
      vm.api.add_port_number.send = true
      vm.api.add_port_number.validation_errors={}
      vm.api.add_port_number.error_message=''
      VOIP_API.endpoints.address_books.createPortNumber({
        accountcode: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
        number: vm.forms.add_port_number.number,
      })
      .then(()=>{
        vm.forms.add_port_number.reset()
        vm.$modal.hide(vm.modalName)
        vm.$emit('added')
        vm.appNotify({
          message:  'Successfully Added',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.add_port_number.validation_errors=ex.own_errors
        vm.api.add_port_number.error_message=ex.own_message
      })
      .finally(()=>{
        vm.forms.add_port_number.submitted = false
        vm.api.add_port_number.send = false
      })
    },
  },
};
</script>
