var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 addUserModalV2",
    attrs: {
      "height": "auto",
      "name": _vm.modalName,
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-user"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 pb-0 d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title my-0"
  }, [_vm._v("Add user ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("How would you like to add your new user?")])]), _c('div', {
    staticClass: "addUserBox-container"
  }, [_c('div', {
    staticClass: "addUserBox",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddUserByEmail');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "InviteUser-icon"
    }
  }), _c('h2', [_vm._v("Invite user")]), _c('p', [_vm._v("Add a new user by inviting them through their email address")])], 1), _c('div', {
    staticClass: "addUserBox",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddUserByUserName');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "AddUser-icon",
    attrs: {
      "icon": "AddUser-icon"
    }
  }), _c('h2', [_vm._v("Add user")]), _c('p', [_vm._v("Add a new user by creating a unique username with your organization name")])], 1)])])])])]), _c('AddUserByEmail', {
    on: {
      "user-added": function ($event) {
        _vm.$emit('user-added');
        _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _c('AddUserByUserName', {
    on: {
      "user-added": function ($event) {
        _vm.$emit('user-added');
        _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }