import { VOIP_API } from "../index"

export const ERROR_CAPTURE_API_ENDPOINTS = {
  sync(data={}){
    return VOIP_API.axios.voip_error_capture.v1.request({
      url: 'error-capture/sync',
      method: 'POST',
      data,
    })
  },
  wbeSocketSync(data={}){
    return VOIP_API.axios.voip_error_capture.v1.request({
      url: 'error-capture/web-socket/sync',
      method: 'POST',
      data,
    })
  },
}