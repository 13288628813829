<template>
  <div>
    <modal class="center-zoomIn-transition v2 m-w-500 AddTeamModal" width="500px" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
      <section class="ct-user-new dialer-edit s1">
        <form class="dialer-form " @submit.prevent="addTeam()">
          <div v-if="getIsMobile" class="dialer-edit-header flex-column">
            <div class="d-flex justify-content-between align-items-center w-100">
              <h2 class="dialer-edit-title">Add team</h2>
              <b-icon class="cursor_pointer" icon="x" font-scale="1.8" @click="api.add_team.send ? '' : $modal.hide(modalName)" />
            </div>
            <!-- <div class="dialer-edit-actions w-100 d-flex justify-content-end mt-2">
              <button class="newDoneButton" :disabled="api.add_team.send">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api.add_team.send" />
                  <template v-else>Done</template>
                </span>
              </button>
              <button type="button" class="dialer-button dialer-button-primary" :disabled="api.add_team.send" @click="$modal.show('CheckedServiceAvailableModal',{service: 'team',onProceed: addTeam,})">
                <template>Detail</template>
              </button>
            </div> -->
          </div>
          <div v-else class="dialer-edit-header d-block pb-0">
            <div class="d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <h2 class="dialer-settings-title newer mb-0">Add team</h2>
                <vb-icon 
                  icon="checkSlotsModal-icon" class="ml-2 cursor_pointer checkSlotsModal-icon" height="22px" width="22px"
                  :disabled="api.add_team.send" @click="$modal.show('CheckedServiceAvailableModal',{service: 'team',onProceed: addTeam,})"
                />
              </div>
              <div class="dialer-edit-actions">
                <a class="newCloseButton" @click="api.add_team.send ? '' : $modal.hide(modalName)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <div class="vm--modal-text">
              Enter the name of the new team below.
            </div>
          </div>
          <template v-if="api.check_service.send">
            <div class="md-mar-top">
              <div class="latestGreyBox-9-9-2023 mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder">
                  <label>
                    <div class="latestShimmerDesign" style="height: 21px;width: 200px;"></div>
                  </label>
                  <div class="latestShimmerDesign" style="height: 50px;width: 100%;"></div>
                </div>
              </div>
            </div>
            <div class="latestShimmerDesign mt-4" style="height: 50px;width: 100%;"></div>
          </template>
          <div v-else class="md-mar-top">
            <b-alert :show="!!api.add_team.error_message" variant="danger">{{ api.add_team.error_message }}</b-alert>
            <b-alert class="alertCustom" :show="checkServiceAlert.enable" :variant="checkServiceAlert.variant">{{ checkServiceAlert.message }}</b-alert>
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <div class="whiteBGinputWithGreyRoundedBorder">
                <label>Name</label>
                <input placeholder="Main office, sales etc..." class="w-100" type="text" :maxlength="$v.forms.team.name.$params.maxLength.max" :disabled="api.add_team.send" v-model="forms.team.name" />
                <p v-if="(forms.team.submitted && $v.forms.team.name.$invalid) || api.add_team.validation_errors.name" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.team.name.required">* Name is required</span>
                  <span v-else-if="!$v.forms.team.name.maxLength">* Name can be maximum {{ $v.forms.team.name.$params.maxLength.max }} character</span>
                  <span v-for="em in api.add_team.validation_errors.name" :key="em">* {{ em }}</span>
                </p>
              </div>
            </div>
            <div class="mt-4 w-100">
              <button class="fullWidthDoneButton" :disabled="api.add_team.send">
                <vb-spinner v-if="api.add_team.send" />
                <template v-else>Create team</template>
              </button>
            </div>
          </div>
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { check_service } from '@/mixin';
import { maxLength, required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { /*events,*/ VOIP_API } from '../../utils';
export default {
  name: "AddTeamModal",
  mixins: [
    check_service
  ],
  props: {
    modalName: {
      type: String,
      default: 'AddTeamModal',
    },
  },
  data() {
    return {
      forms: {
        team: this.$helperFunction.formInstance({
          data: {
            name: '',
            description: '',
          }
        }),
        reset(){
          this.team?.reset?.()
        },
      },
      api: {
        add_team: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true }),
        check_service: this.$helperFunction.apiInstance(),
        reset(){
          this.add_team?.reset?.()
          this.check_service?.reset?.()
        },
      },
      response: {
        check_service: {},
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile'
    ]),
    checkServiceAlert(){
      const type = this.response.check_service?.type
      const message = this.response.check_service?.message || ''
      return {
        enable: !!this.response.check_service?.type,
        title: ['ADDON'].includes(type) ? 'Attention' : 'Warning',
        message: message,
        variant: ['ADDON'].includes(type) ? 'info' : 'danger',
      }
    },
  },
  validations: {
    forms: {
      team: {
        name: { 
          required,
          maxLength: maxLength(20) 
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      this.forms.team.name=event?.params?.suggested_name ?? ''
      this.checkservice()
    },
    onBeforeClose(){
      this.forms.reset()
      this.api.reset()
      this.response.check_service={}
    },
    checkservice(){
      let vm = this
      if(vm.api.check_service.send) return;
      vm.api.check_service.send=true
      vm.checkService({
        service: 'team',
        no_confirmation: true,
        then(data){
          vm.response.check_service=data || {}
        },
        catch(ex){
          vm.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          }) 
        },
        finally(){
          vm.api.check_service.send=false
        }
      })
    },
    addTeam() {
      let vm = this
      vm.forms.team.submitted = true;
      vm.$v.forms.team.$touch();
      if (vm.$v.forms.team.$invalid || vm.api.add_team.send) return;
      vm.api.add_team.send = true;
      vm.api.add_team.validation_errors = {};
      vm.api.add_team.error_message = '';
      vm.checkService({
        service: 'team',
        async then(){
          await VOIP_API.endpoints.teams.create({
            name: vm.forms.team.name,
            description: vm.forms.team.description,
            service: 'team',
            default_account: vm.getCurrentUser.default_accountcode,
            accountcode: vm.getCurrentUser.account,
            uid: vm.getCurrentUser.uid,
          })
          // vm.$store.commit('setAddServiceQueue',{
          //   type: 'team',
          //   name: vm.forms.team.name,
          // })
          vm.$modal.hide(vm.modalName)
          vm.$emit('interface');
          // vm.$root.$emit(events.check_pending_services)
        },
        catch(ex){
          vm.api.add_team.validation_errors = ex.own_errors;
          vm.api.add_team.error_message = ex.own_message;
        },
        finally(){
          vm.forms.team.submitted = false;
          vm.api.add_team.send = false;
        },
      })
    },
  },
};
</script>
