<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Users</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item" @click="$modal.show('UserPrefix',{ vs_packages_only: true, })">
                <div class="headingInsideTable">VS Packages</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <div class="basicWhiteIOScard-item" v-for="(user, key) in users" :key="key" @click="$modal.show('UserPrefix',{ accountcode: key, call_plan_only: true, })">
                <Info class="InfoComponentInsideAnroidIOSApp" :id="key" :is_blf="false" />
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <vb-no-record v-if="Object.keys(users).length==0" :text="api.account_detail.send?'':'There is no playlist'" :design="3">
                <vb-loading v-if="api.account_detail.send" slot="loading" />
              </vb-no-record>
            </div>

          </div>
        </div>
      </template>
    </modal>
    <UserPrefixModal />
  </div>
</template>

<script>
import Info from '../../../Lists/Info.vue'

import UserPrefixModal from './UserPrefix.vue'

import { $fn, VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';

let screens = {
  vs_packages: 'vs_packages',
  select_prefix: 'select_prefix',
  main: 'main',
}

export default {
  name: "UsersDetailModal",
  components: {
    Info,
    UserPrefixModal,
  },
  inject: ['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'UsersDetail',
    }
  },
  data() {
    return {
      api: {
        account_detail: this.$helperFunction.apiInstance(),
        get_prefixes: this.$helperFunction.apiInstance(),
        update_prefixes: this.$helperFunction.apiInstance(),
        update_addon_prefixes: this.$helperFunction.apiInstance(),
      },
      response: {
        account: {},
        prefixes: {},
      },
      selected: {
        user_id: '',
      },
      conditions: {
        screen: screens.main
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    screens(){ return screens },
    users(){ return this.response?.account?.users ?? {} },
    callPlans(){ return (this.response.prefixes.callPrefixes || []).map(item=>( { text: `${item.prefix.label} ${item.prefix.prefix}`, value: item.prefix.prefix, } ))
    },
    addonPrefixes(){ return this.response?.prefixes?.addonPrefixes ?? [] }
  },
  methods: {
    onBeforeOpen(){
      this.fetchAccountDetail()
      this.fetchPrefixes()
    },
    onBeforeClose(){
      this.response.account={}
    },
    fetchAccountDetail(){
      let vm = this
      if(vm.api.account_detail.send) return;
      vm.api.account_detail.send=true
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data }) => {
        vm.response.account = data
      })
      .finally(() => {
        vm.api.account_detail.send = false
      });
    },
    fetchPrefixes(){
      let vm = this
      vm.api.get_prefixes.send=true
      VOIP_API.endpoints.techprifex.get({
        uid: vm.getCurrentUser?.uid,
        accountcode: vm.getCurrentUser?.account,
      })
      .then(({ data: prefixes })=>{
        vm.response.prefixes = prefixes || {}
      })
      .finally(()=>{
        vm.api.get_prefixes.send=false
      })
    },
    updatePrefix(accountcode,prefix){
      let vm = this
      const prefix_obj = (vm.response.prefixes.callPrefixes || []).find(item=>item.prefix.prefix==prefix)
      if(!prefix_obj || vm.api.update_prefixes.send) return;
      vm.api.update_prefixes.send=true
      VOIP_API.endpoints.techprifex.update(prefix_obj.product_id,{
        id: prefix_obj.product_id,
        prefix: prefix,
        useraccount: accountcode,
        accountcode: accountcode,
        account: vm.getCurrentUser?.account,
        uid: vm.getCurrentUser?.uid,
      })
      .then(()=>{
        return $fn.sleep(2 * 1000)
      })
      .then(()=>{
        vm.fetchAccountDetail()
        vm.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.update_prefixes.send=false
      })
    },
    updateAddonPrefix(product_id){
      let vm = this
      const prefix_obj = (vm.response.prefixes.addonPrefixes || []).find(item=>item.product_id==product_id)
      if(!prefix_obj || vm.api.update_addon_prefixes.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to purchase this`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.update_addon_prefixes.send=true
            VOIP_API.endpoints.techprifex.update(prefix_obj.product_id,{
              id: prefix_obj.product_id,
              prefix: product_id,
              useraccount: vm.getCurrentUser?.account,
              accountcode: vm.getCurrentUser?.account,
              account: vm.getCurrentUser?.account,
              uid: vm.getCurrentUser?.uid,
            })
            .then(()=>{
              vm.appNotify({
                message: 'Successfully Updated',
                type: 'success',
              })
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.update_addon_prefixes.send=false
            })
          }
        },
      });
    },
  },
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
