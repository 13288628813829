<template>
  <modal class="dialer_animation right_side_popup full-width-big-modal" height="auto" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    <div class="dialer-assign-to my-4 dialer-edit">
      <div class="dialer-edit-actions d-flex justify-content-end">
        <button class="newCloseButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    <div class="latestGreyBox-9-9-2023 mt-24px">
      <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
        <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0">
          <ScheduleSetting 
            @ofh-default-updated="$emit('ofh-default-updated')"
            :condition="{toggle:false,days:false}" 
            :account="data.accountcode" 
          />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import ScheduleSetting from "../Common/ScheduleSetting.vue";
export default {
  name: 'DefaultOutOfHoursModal',
  components: {
    ScheduleSetting,
  },
  props: {
    modalName: {
      type: String,
      default: 'DefaultOutOfHoursModal',
    },
  },
  data(){
    return {
      data: {
        accountcode: '',
      },
    }
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode = event?.params?.accountcode || ''
      this.data.updated = event?.params?.updated || null
    },
    onBeforeClose(){
      this.data.accountcode = ''
      this.data.updated = null
    },
    updated(){
      this.$emit('updated')
      this.data.updated?.()
    },
  },
}
</script>

<style>

</style>