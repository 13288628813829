
import { VOIP_API } from "../index"

export const SUPPORT_TICKET_API_ENDPOINTS = {
  list(params={}) {
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: 'supporttickets',
      params,
    })
  },
  detail(id,params={}){
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: `supporttickets/${id}`,
      params,
    })
  },
  delete(id,data={}){
    return VOIP_API.axios.voip.request({
      method: 'DELETE',
      url: `supporttickets/${id}`,
      data,
    })
  },
  reply(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'add-ticket-reply',
      data,
    })
  },
  create(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: 'supporttickets',
      data,
    })
  },
  departments() {
    return VOIP_API.axios.voip.request({
      method: 'GET',
      url: 'get-whmcs-departments',
    })
  },
}