var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Availability")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.updateSchedule({
          type: 'availble'
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Available all day")]), _vm.data.type == 'availble' ? _c('vb-icon', {
    attrs: {
      "icon": "MyNumbers-tick-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.updateSchedule({
          type: 'unavailable'
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Unavailable all day")]), _vm.data.type == 'unavailable' ? _c('vb-icon', {
    attrs: {
      "icon": "MyNumbers-tick-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.randomID}-TimeSelectionModal`, {
          start: _vm.data.start,
          end: _vm.data.end
        });
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Unavailable between")]), _vm.data.type == 'custom' ? _c('div', [_c('span', {
    staticClass: "text-decoration-underline"
  }, [_vm._v(_vm._s(_vm.data.start) + " - " + _vm._s(_vm.data.end))])]) : _vm._e()]), _vm.data.type == 'custom' ? _c('vb-icon', {
    attrs: {
      "icon": "MyNumbers-tick-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  }) : _vm._e()], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Use Default")]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    ref: "default-toggle",
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "checked": _vm.forwardSetting.is_default,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateSchedule({
          default: $event
        });
      }
    }
  })], 1)]), !_vm.forwardSetting.is_default ? _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.randomID}-CallForwardSettingModal`, {
          account: _vm.account,
          setting: _vm.forwardSetting,
          is_ringing_show: _vm.selectedSchedule.type == 'availble',
          is_sec_show: false
        });
      }
    }
  }, [_vm.forwardSetting.forward_note == 'voicemail' ? [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v("leave a voicemail")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)] : _vm.forwardSetting.forward_note == 'extension' ? [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Redirect to a user, team or IVR menu")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forwardSetting.extension_name) + " ")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)] : _vm.forwardSetting.forward_note == 'number' ? [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Redirect to another number")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.forwardSetting.forward_number))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)] : [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Forwarding")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v("Keep ringing ")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]], 2) : _vm._e()])]), _c('CallForwardingModal', {
    attrs: {
      "modalName": `${_vm.randomID}-CallForwardSettingModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.updateSchedule($event);
      }
    }
  }), _c('StartAndEndTimePickerModal', {
    attrs: {
      "modalName": `${_vm.randomID}-TimeSelectionModal`
    },
    on: {
      "updated": function ($event) {
        _vm.data.start = `${$event.start.data.HH}:${$event.start.data.mm}:${'00'}`;
        _vm.data.end = `${$event.end.data.HH}:${$event.end.data.mm}:${'00'}`;
        _vm.updateSchedule({
          start: $event.start,
          end: $event.end,
          type: 'custom'
        });
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }