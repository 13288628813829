<template>
  <modal class="dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll" name="createNewTeamEmployeeData" :clickToClose="true" :scrollable="true">
    <div class="modalHeading-container">
      <div class="modalHeading">Create a new team</div>
      <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('createNewTeamEmployeeData')">Close</button>
    </div>

    <div class="modalInner-container">
      <div class="innerShadowInput-container">
        <label>Team name</label>
        <input class=""/>
      </div>
      <div class="innerShadowInput-container">
        <label>Country</label>
        <b-dropdown class="hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText"></div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            Country
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="innerShadowInput-container">
        <label>Time Zone</label>
        <b-dropdown class="hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText"></div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            Time Zone
          </b-dropdown-item>
        </b-dropdown>
      </div>

      <div class="grayCard borderRadius-12px flexColumn px-4 mt-32px">
        <div class="innerShadowInput-container mb-0">
          <label class="">Time Format</label>
        </div>
        <b-form-group class="HrmRadio-container mt-16px w-100">
          <b-form-radio class="HrmRadio" >
            12-hour (3:00PM)
          </b-form-radio>
          <b-form-radio class="HrmRadio">
            24-hour (15:00)
          </b-form-radio>
        </b-form-group>
      </div>

      <div class="grayCard borderRadius-12px flexColumn px-4 mt-32px">
        <div class="innerShadowInput-container mb-0">
          <label class="">First day of the week for calendars</label>
        </div>
        <b-form-group class="HrmRadio-container mt-16px w-100">
          <b-form-radio class="HrmRadio" >
            Monday
          </b-form-radio>
          <b-form-radio class="HrmRadio">
            Sunday
          </b-form-radio>
        </b-form-group>
      </div>

      <button class="dialer-button dialer-button-primary mt-32px mr-0"> Create </button>
      
    </div>
  </modal>
</template>

<script>
export default {
  name: 'createNewTeamEmployeeData',
  data(){
    return {
      showCollapsableItems:'first',
    }
  },
}
</script>

<style>

</style>