<template>
  <modal
    class="dialer_animation right_side_popup m-w-500"
    width="400px"
    height="auto"
    :scrollable="true"
    name="InvitePeopleModal"
    @closed="onClosed"
  >
    <div class="dialer-form">
      <div class="dialer-edit-header">
        <h2 class="dialer-edit-title">Invite people</h2>
        <div class="dialer-edit-actions">
          <a class="newCloseButton" @click="$modal.hide('InvitePeopleModal')">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
          <button @click="invite()" class="newDoneButton" :disabled="api_sent.invite">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
              <vb-spinner v-if="api_sent.invite" />
              <template v-else>Done</template>
            </span>
          </button>
        </div>
      </div>
      <div class="md-mar-vertical">
        <b-alert :dismissible="true" :fade="true" :show="!!error" @dismissed="error=''" class="alert-danger">{{ error }}</b-alert>
        <b-form @submit.prevent="invite">
          <b-form-group class=" whiteBGinputWithGreyRoundedBorder">
            <label>Email</label>
            <b-form-input :disabled="api_sent.invite" type="email" placeholder="Enter Email" class="w-100" v-model="email" />
            <template v-if="submitted && !$v.email.required">
              <p>*Email is required</p>
            </template>
            <template v-if="submitted && !$v.email.email">
              <p>*Enter a valid Email</p>
            </template>
          </b-form-group>
        </b-form>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '../../../utils';
import { required, email } from "vuelidate/lib/validators";
import _ from 'lodash'
export default {
  name: "InvitePeopleModal",
  data(){
    return {
      email: "",
      invitation: {},
      error: "",
      submitted: false,
      api_sent: {
        invite: false,
      },
      assets: {
        spinner: require("@/assets/images/spinner.svg"),
      },
    }
  },
  inject: [
    'appNotify',
  ],
  validations: {
    email: {
      required,
      email,
    },
  },
  methods: {
    async invite(){
      var app = this
      this.submitted=true
      this.$v.$touch();
      if (this.$v.email.$invalid || this.api_sent.invite) return;
      this.api_sent.invite=true
      VOIP_API.endpoints.user.userinvitations({
        email: this.email,
        uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
      }).then(({ data: { data: invitation } })=>{
        app.invitation = invitation
        app.email=""
        app.appNotify({
          message: "Successfully Invited",
          type: 'success',
        })
        app.$emit('load')
        app.$modal.hide('InvitePeopleModal')
      }).catch((error)=>{
        app.appNotify({
          message: _.get(error,'response.data.errors') || "Something went wrong",
          type: 'danger',
        })
      }).finally(()=>{
        app.api_sent.invite=false
        app.submitted=false
      })
    },
    onClosed(){
      this.email=''
      this.submitted=false
      this.error=''
      this.invitation={}
    },
  },
}
</script>

<style>

</style>