<template>
  <div>
    <modal class="dialer_animation right_side_popup m-w-700 updateCaller-idModal AddMemberInPickupGroupsModal min-width-50percent-modal" width="500px" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @closed="onBeforeClose">
      <div class="dialer-edit">
        <form @submit.prevent="''" class="dialer-form">
          <div v-if="$store.getters.getIsMobile" class="dialer-edit-header flex-column">
            <div class="d-flex w-100 justify-content-between align-items-center">
              <h2 class="dialer-edit-title">Assign extensions </h2>
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            </div>
            <div class="dialer-edit-actions d-flex w-100 justify-content-end mt-2"> 
              <button :disabled="api_sent.update" class="newDoneButton" @click="updatemembers()">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <b-spinner v-if="api_sent.update" label="spinner" />
                  <template v-else>Done</template>
                </span>
              </button>
            </div>
          </div>
          <div v-else class="dialer-edit-header">
            <h2 class="dialer-edit-title d-flex align-items-center">
              <!-- <vb-svg @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" /> -->
              <a type="button" class="newBackButton mr-16px" @click="$modal.hide(modalName)">
                <b-icon icon="arrow-left-circle" />
                <span class="newBackButton-textpart">Back</span>
              </a>
              Assign extensions
            </h2>
            <div class="dialer-edit-actions"> 
              <!-- <a class="newCloseButton" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a> -->
              <button :disabled="api_sent.update" class="newDoneButton" @click="updatemembers()">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">
                  <vb-spinner small v-if="api_sent.update" label="spinner" />
                  <template v-else>Done</template>
                </span>  
              </button>
            </div>
          </div>
          <div>
            <!-- <div class="TabsDesignWithIcon-container mt-20px">
              <div class="TabsDesignWithIcon-item" :class="{ selected: selected.tab==tab.value }" v-for="tab in tabs" :key="tab.id" @click="selected.tab=tab.value">
                <vb-icon :icon="tab.icon" height="38px" width="38px" />
                <span class="TabsDesignWithIcon-item-textPart">{{tab.text}}</span>
              </div>
            </div> -->
            <div class="latestGreyBox-9-9-2023 mt-20px" v-if="selected.tab=='user'">
              <div class="whiteBGinputWithGreyRoundedBorder">
                <b-input type="text" placeholder="Search users" v-model="filter.user.search" />
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="data in filterUsers" :key="data.accountcode" >
                  <div :class="`actLikeSquareRadio checkboxVersion mr-20px ${selected.extension.includes(data.accountcode) ? 'checked' : ''}`" @click="selected.extension.includes(data.accountcode) ? selected.extension.splice(selected.extension.indexOf(data.accountcode),1) : selected.extension.push(data.accountcode)"></div>
                  <info :id="data.accountcode" :name="data.label" :sub_info="data.extn" :is_blf="false" />
                </div>
                <vb-no-record v-if="isEmpty(filterUsers)" :text="api_sent.extensions ? '' : 'Sorry, no data found'" :design="3">
                  <!-- <vb-loading class="my-2" :type="'loader'" v-if="api_sent.extensions" slot="loading" /> -->
                  <!-- <vb-loading class="my-2" v-if="api_sent.extensions" slot="loading" /> -->
                  <template v-if="api_sent.extensions" slot="loading">
                    <div v-for="n in 5" :key="n.id" class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
                      <div class="latestShimmerDesign" style="height:24px;width:160px"></div>
                    </div>
                  </template>
                </vb-no-record>
              </div>
            </div>
            <div class="latestGreyBox-9-9-2023 mt-20px" v-if="selected.tab=='team'">
              <div class="whiteBGinputWithGreyRoundedBorder">
                <b-input type="text" placeholder="Search teams" v-model="filter.team.search" />
              </div>
              <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none" v-for="data in filterTeams" :key="data.accountcode" >
                  <div :class="`actLikeSquareRadio mr-20px ${selected.extension.includes(data.accountcode) ? 'checked' : ''}`" @click="selected.extension.includes(data.accountcode) ? selected.extension.splice(selected.extension.indexOf(data.accountcode),1) : selected.extension.push(data.accountcode)"></div>
                  <info :id="data.accountcode" :name="data.label" :sub_info="data.extn" :is_blf="false" />
                </div>
                <vb-no-record v-if="isEmpty(filterTeams)" :text="api_sent.extensions ? '' : 'Sorry, no data found'" :design="3">
                  <!-- <vb-loading class="my-2" :type="'loader'" v-if="api_sent.extensions" slot="loading" /> -->
                  <!-- <vb-loading class="my-2" v-if="api_sent.extensions" slot="loading" /> -->
                  <template v-if="api_sent.extensions" slot="loading">
                    <div v-for="n in 5" :key="n.id" class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
                      <div class="latestShimmerDesign" style="height:24px;width:160px"></div>
                    </div>
                  </template>
                </vb-no-record>
              </div>
            </div>
          </div>








          <!--<div class="dialer-available-extensions">
            <div class="dialer-tabs">
              <div class="dialer-tab-headers">
                <div v-for="tab in tabs" :class="[ 'dialer-tab text-capitalize',{ selected: selected.tab==tab.value },]" :key="tab.value"  @click="selected.tab=tab.value">
                  {{ tab.text }} 
                </div>
              </div>
            </div>
            <div v-if="selected.tab=='user'">
              <div class="whiteBGinputWithGreyRoundedBorder my-4">
                <b-input type="text" placeholder="Search Users" v-model="filter.user.search" />
              </div>
              <div 
                v-for="data in filterUsers" 
                :key="data.accountcode" 
                @click="selected.extension.includes(data.accountcode) ? selected.extension.splice(selected.extension.indexOf(data.accountcode),1) : selected.extension.push(data.accountcode)" 
                :class="[ 'd-flex cursor_pointer mb-3 pt-2 pb-2 pl-2 pr-4 full-ext',{ 'full-ext-active': selected.extension.indexOf(data.accountcode) > -1 },]"
              >
                <info :id="data.accountcode" :name="data.label" :sub_info="data.extn" :is_blf="false" />
              </div>
              <vb-no-record v-if="isEmpty(filterUsers)" :text="api_sent.extensions ? '' : 'Sorry, No Data Found'" :design="3">
                <!~~ <vb-loading class="my-2" :type="'loader'" v-if="api_sent.extensions" slot="loading" /> ~~>
                <vb-loading class="my-2" v-if="api_sent.extensions" slot="loading" />
              </vb-no-record>
            </div>
            <div v-if="selected.tab=='team'">
              <div class="whiteBGinputWithGreyRoundedBorder my-4">
                <b-input type="text" placeholder="Search Teams" v-model="filter.team.search" />
              </div>
              <div 
                v-for="data in filterTeams" 
                :key="data.accountcode"
                @click="
                  selected.extension.indexOf(data.accountcode) > -1 ? 
                    selected.extension.splice(selected.extension.indexOf(data.accountcode),1) : 
                    selected.extension.push(data.accountcode)
                "
                :class="[ 'd-flex cursor_pointer mb-3 pt-2 pb-2 pl-2 pr-4 full-ext',{ 'full-ext-active': selected.extension.indexOf(data.accountcode) > -1 },]"
              >
                <info :id="data.accountcode" :name="data.label" :sub_info="data.extn" :is_blf="false" />
              </div>
              <vb-no-record v-if="isEmpty(filterTeams)" :text="api_sent.extensions ? '' : 'Sorry, No Data Found'" :design="3">
                <!~~ <vb-loading class="my-2" :type="'loader'" v-if="api_sent.extensions" slot="loading" /> ~~>
                <vb-loading class="my-2" v-if="api_sent.extensions" slot="loading" />
              </vb-no-record>
            </div>
          </div>-->
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import _ from 'lodash'
import { VOIP_API } from '../../utils';
import Info from '../Lists/Info.vue';
export default {
  components: { Info },
  name: "AddMemberInPickupGroupsModal",
  props: {
    modalName: {
      type: String,
      default: 'AddMemberInPickupGroupsModal',
    },
    conditions: {
      type: Object,
      default: ()=>({}),
    },
  },
  data() {
    return {
      response: {
        extensions: {
          available: {},
          selected: {},
        },
      },
      api_sent: {
        extensions: false,
        update: false,
      },
      filter: {
        user: {
          search: '',
        },
        team: {
          search: '',
        },
      },
      selected: {
        extension: [],
        tab: 'user',
      },
      data: {
        pickupgroup_id: '',
      },
    };
  },
  inject:['isEmpty','appNotify'],
  computed: {
    condition(){
      return {
        single_selected: _.get(this.conditions,'single_selected') != undefined ? _.get(this.conditions,'single_selected') : false,
      }
    },
    tabs(){
      return [
        // {
        //   text: 'Teams',
        //   value: 'team',
        //   icon: 'squared-multipleUsers-icon'
        // },
        {
          text: 'Users',
          value: 'user',
          icon: 'squared-singleUser-icon'
        },
      ]
    },
    filterUsers(){
      const search = this.filter.user.search.trim().toLowerCase()
      const array = _.concat(Object.values(this.response.extensions.available),Object.values(this.response.extensions.selected))
      return array.filter(item=>`${item.label} ${item.extn}`.trim().toLowerCase().includes(search) && item.type=='user')
    },
    filterTeams(){
      const search = this.filter.team.search.trim().toLowerCase()
      const array = _.concat(Object.values(this.response.extensions.available),Object.values(this.response.extensions.selected))
      return array.filter(item=>`${item.label} ${item.extn}`.trim().toLowerCase().includes(search) && item.type=='team')
    },
  },
  methods: {
    onBeforeClose() {
      this.data.pickupgroup_id=''
      this.filter.user.search=''
      this.filter.team.search=''
      this.response.extensions.available={}
      this.response.extensions.selected={}
      this.selected.extension=[]
      this.selected.tab='user'
    },
    onBeforeOpen(event) {
      this.data.pickupgroup_id=_.get(event,'params.pickupgroup_id') || ''
      this.getmembers()
    },
    getmembers(){
      var app = this
      if (this.api_sent.extensions) return;
      this.api_sent.extensions=true
      VOIP_API.endpoints.pickupgroup.members(this.data.pickupgroup_id)
      .then(({ data: { selected, available } })=>{
        app.response.extensions.available=_.isEmpty(available) ? {} : available
        app.response.extensions.selected=_.isEmpty(selected) ? {} : selected
        app.selected.extension=Object.keys(app.response.extensions.selected)
      })
      .finally(()=>{
        app.api_sent.extensions=false
      })
    },
    updatemembers(){
      let vm = this
      if(vm.api_sent.update) return;
      vm.api_sent.update=true
      VOIP_API.endpoints.pickupgroup.updatemembers(this.data.pickupgroup_id,{
        members: this.selected.extension.join(',')
      }).then(()=>{
        vm.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        vm.$modal.hide(vm.modalName)
        vm.$emit('updated',vm.selected.extension)
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api_sent.update=false
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.updateCaller-idModal {
  .custom-control-label {
    cursor: pointer;
    & > span {
      display: flex;
      flex-direction: row-reverse;
      align-items: center;
    }

    &::before,
    &::after {
      top: 9px;
    }
  }
}
</style>
