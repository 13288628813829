var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('li', [_c('a', {
    class: {
      'd-flex align-items-center position-relative': true,
      'teamChat-info': _vm.type === 'team',
      'channelChat-info': _vm.type === 'channel',
      'unreadMessage_blinking': _vm.channelData && _vm.channelData.unread > 0 && _vm.GET_LOCAL_SETTING_CHAT.appearence.blink_unread === 'accepted',
      'active': _vm.$store.state.mattermost.main_open && _vm.$store.state.mattermost.main_open == _vm.channel_id,
      'unreadMessages-present': _vm.channelData && _vm.channelData.unread > 0,
      'notificationMuted': _vm.isMuted
    },
    attrs: {
      "type": _vm.channelData && _vm.channelData.unread > 0 ? 'unreadMessages' : ''
    },
    on: {
      "click": _vm.select
    }
  }, [_c('span', {
    staticClass: "avatar d-flex align-items-center justify-content-center"
  }, [_c('vb-avatar', {
    attrs: {
      "id": _vm.iddd,
      "image": _vm.image,
      "is_blf": false
    }
  })], 1), _c('div', {
    class: ['info-area', _vm.type === 'team' ? 'ofTeam' : '']
  }, [_c('div', {
    staticClass: "name"
  }, [_c('h5', {
    staticClass: "m-0 text-dotted"
  }, [_vm._v(_vm._s(_vm.name))]), _vm.tabType !== 'searched' ? _c('span', {
    staticClass: "text-lowercase"
  }, [_vm._v(_vm._s(_vm._f("date_current")(_vm.lastPost.update_at, true)))]) : _vm._e()]), _vm.typingMessage ? _c('p', {
    staticClass: "m-0 message text-dotted typing-text isTypingText"
  }, [_c('span', [_vm._v(_vm._s(_vm.typingMessage))]), _vm._v(" is typing ... ")]) : [_vm.lastMessage && _vm.tabType !== 'searched' ? _c('p', {
    staticClass: "m-0 message text-dotted text-truncate"
  }, [_vm.channelData && _vm.channelDraft && _vm.tabType !== 'searched' ? _c('span', [_vm._v("[" + _vm._s(_vm.draftMessage.message || "Draft") + "]")]) : _vm._e(), _vm._v(" " + _vm._s(_vm.lastMessage) + " ")]) : _vm._e(), _vm.isMuted ? _c('span', {
    staticClass: "m-0 count chatMuteNotification-icon text-center text-white overflow-hidden position-absolute"
  }, [_vm.isMuted ? _c('vb-icon', {
    attrs: {
      "icon": "chatMuteNotification-icon",
      "height": "15px",
      "width": "15px"
    }
  }) : _vm._e()], 1) : _vm._e(), _vm.channelData && _vm.channelData.unread > 0 && _vm.tabType !== 'searched' ? _c('span', {
    staticClass: "m-0 count text-center text-white bg-primary overflow-hidden position-absolute"
  }, [_vm._v(_vm._s(_vm.channelData.unread))]) : _vm._e()]], 2), _vm.type === 'team' ? _c('div', {
    class: `${_vm.expand ? 'rotate-collapsed' : ''} teamsArrow`,
    staticStyle: {
      "position": "absolute",
      "right": "11px",
      "top": "28px"
    },
    on: {
      "click": function ($event) {
        $event.stopPropagation();
        _vm.expand = !_vm.expand;
      }
    }
  }, [_c('vb-svg', {
    staticClass: "dialer-downArrow",
    attrs: {
      "name": "dialer-downArrow-icon",
      "width": "14",
      "height": "8.001",
      "viewBox": "0 0 14 8.001",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  })], 1) : _vm._e()]), _vm.expand && _vm.getProperty(_vm.channelData, 'type', '') === 'team' ? _c('div', {
    staticClass: "pl-4 teamsMembers-container"
  }, _vm._l(_vm.data.members, function (member) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: member.accountcode !== _vm.$store.getters.getCurrentUser.account,
        expression: "member.accountcode!==$store.getters.getCurrentUser.account"
      }],
      key: member.id
    }, [_vm.api_sent.direct_chat === member.accountcode ? _c('b-icon', {
      attrs: {
        "icon": "circle-fill",
        "animation": "throb"
      }
    }) : _c('div', {
      staticClass: "d-flex my-3 align-items-center",
      on: {
        "click": function ($event) {
          return _vm.directChat(member.accountcode);
        }
      }
    }, [_c('vb-avatar', {
      attrs: {
        "id": member.accountcode,
        "is_blf": false
      }
    }), _c('p', {
      staticClass: "ml-3 mb-0",
      attrs: {
        "aria-disabled": _vm.api_sent.direct_chat
      }
    }, [_vm._v(" " + _vm._s(_vm.$store.getters.getVoipUsersAlisesDisplayName[member.accountcode]) + " ")])], 1)], 1);
  }), 0) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }