<template>
  <div v-if="isBoardView" class="w-100" style="cursor: move;" @dragend="$emit('dragend',$event)" @dragstart="$emit('dragstart',$event)" draggable="true">
    <div v-if="isBoardView" class="KanbanView-task" :style="`border-left-color: ${priorityColor}`" @click="$emit('taskClick')">
      <div class="KanbanView-task-heading">{{ task | get_property('title') }}</div>
      <div class="KanbanView-task-text mt-10px" v-if="task.created_by">
        Created by <Info class="mx-1" :only_name="true" :id="task.created_by" /> on {{ task.created_at | filter_date_current }}
      </div>
      <div class="categoryText w-fit-content mt-16px" :style="`background-color: ${task.group ? task.group.color : ''};`">{{task | get_property('group.name')}}</div>
      <div class="KanbanView-task-lastLine mt-20px">
        <div class="KanbanView-task-lastLine-leftPart">
          <b-icon v-if="myDay" class="mr-8px" icon="sun-fill" variant="dark" width="auto" height="19px"/>
          <b-icon v-if="task.is_important=='1'" class="mr-8px" icon="exclamation-lg" variant="dark" width="auto" height="19px"/>
          <b-icon v-if="task.note" icon="file-earmark-text-fill" variant="dark" width="16px" height="19px"/>
        </div>
        <div class="KanbanView-task-lastLine-rightPart ml-auto" :class="`${assigned.count>0?'moreThanThree':assigned.array.length==3?'exactlyThree':''}`">
          <vb-avatar :showPopover="true" v-for="assigned in assigned.array" :is_blf="false" :key="assigned.id" :id="assigned.assigned_to" />
          <div class="countPlus" v-if="assigned.count">+{{assigned.count}}</div>
        </div>
      </div>
    </div>
    <div v-else class="TODO-taksTable-bodyRow mt-12px" @click="$emit('taskClick')">
      <div class="TODO-taksTable-bodyRow-column first" :style="`border-left: 13px solid ${priorityColor}`">
        <div class="d-flex flex-column w-100">
          <div class="mainInfo-section">
            <b-icon v-if="task.spaces" icon="circle-fill" :style="`color:${task.spaces.color};`" v-b-tooltip.hover :title="task.spaces.name"></b-icon>
            <div class="TODO-taksTable-bodyRow-column-text ml-2">{{ task | get_property('title') }}</div>
          </div>
          <div class="d-flex align-items-center justify-content-between">
            <div v-if="task.created_by" class="subInfo-section">
              <span class="d-flex firstPart align-items-center">
                <span class="mr-8px">{{ task | get_property('spaces.prefix') }}#{{ task | get_property('id') }}</span>
                <span class="mr-8px d-flex align-items-center putSmallDotInBefore">
                  created by <Info class="mx-1" :only_name="true" :id="task.created_by" /> on {{ task.created_at | filter_date_current }}
                </span> 
                <template v-if="task.due_date">
                  <span class="mr-8px putSmallDotInBefore">
                    Due 
                    <span class="selectedActive">{{ task.due_date | filter_date_current }}</span>
                  </span>
                </template>
                <span class="mr-16px putSmallDotInBefore">
                  Last updated
                  <span class="selectedActive">{{ task.updated_at | filter_date_current }}</span>
                </span>
              </span>
              <span class="d-flex secondPart">
                <span class="mr-16px d-flex align-items-center">
                  <b-icon v-if="myDay" class="mr-8px" icon="sun-fill" variant="dark" width="auto" height="19px"/>
                  <b-icon v-if="task.is_important=='1'" class="mr-8px" icon="exclamation-lg" variant="dark" width="auto" height="19px"/>
                  <b-icon v-if="task.note" icon="file-earmark-text-fill" variant="dark" width="16px" height="19px"/>
                </span>
                <div class="categoryText" :style="`background-color: ${task.group ? task.group.color : ''};`">{{task | get_property('group.name')}}</div>
              </span>
            </div>
          </div>
        </div>
      </div>
      <div class="TODO-taksTable-bodyRow-column second" :class="`${assigned.count>0?'moreThanThree':assigned.array.length==3?'exactlyThree':''}`">
        <vb-avatar :showPopover="true" v-for="assigned in assigned.array" :is_blf="false" :key="assigned.id" :id="assigned.assigned_to" />
        <div class="countPlus" v-if="assigned.count">+ {{assigned.count}}</div>
      </div>
      <div class="TODO-taksTable-bodyRow-column third" :style="`background-color: ${task.status ? task.status.color : 'transparent'}`">
        <template v-if="task.status">
          <div class="TODO-taksTable-bodyRow-column-text colorWhite">
            {{ task.status.title }}
          </div>
        </template>
      </div>
      <div class="TODO-taksTable-bodyRow-column fourth" >
        <div v-if="isMore" class="filterDD dropdownVersion whiteBGinputWithGreyRoundedBorder">
          <b-dropdown no-caret :disabled="api.remove_task.send" class="" right>
            <template #button-content>
              <vb-icon icon="todo-three-dots-vertical-icon" variant="dark" class="mx-auto" width="6px" height="27px"/>
            </template>
            <b-dropdown-item @click.stop="removeTask()">{{task.deleted_status=='yes'?'Unarchived':'Archived'}}</b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>
  </div>
  <div v-else class="w-100">
    <div class="TODO-taksTable-bodyRow mt-12px" @click="$emit('taskClick')">
      <div v-if="getIsMobile" class="TODO-taksTable-bodyRow-column onlyOne justify-content-start" :style="`border-left: 9px solid ${priorityColor}`">
        <b-icon v-if="task.spaces" icon="circle-fill" :style="`color:${task.spaces.color};`" v-b-tooltip.hover :title="task.spaces.name"></b-icon>
        <div class="TODO-taksTable-bodyRow-column-text ml-2">{{ task | get_property('title') }}</div>
      </div>
      <template v-else>
        <div class="TODO-taksTable-bodyRow-column first" :style="`border-left: 13px solid ${priorityColor}`">
          <div class="d-flex flex-column w-100">
            <div class="mainInfo-section">
              <b-icon v-if="task.spaces" icon="circle-fill" :style="`color:${task.spaces.color};`" v-b-tooltip.hover :title="task.spaces.name"></b-icon>
              <div class="TODO-taksTable-bodyRow-column-text ml-2">{{ task | get_property('title') }}</div>
            </div>
            <div class="d-flex align-items-center justify-content-between">
              <div v-if="task.created_by" class="subInfo-section">
                <span class="d-flex firstPart align-items-center">
                  <span class="mr-8px">{{ task | get_property('spaces.prefix') }}#{{ task | get_property('id') }}</span>
                  <span class="mr-8px d-flex align-items-center putSmallDotInBefore">
                    created by <Info class="mx-1" :only_name="true" :id="task.created_by" /> on {{ task.created_at | filter_date_current }}
                  </span> 
                  <template v-if="task.due_date">
                    <span class="mr-8px putSmallDotInBefore">
                      Due 
                      <span class="selectedActive">{{ task.due_date | filter_date_current }}</span>
                    </span>
                  </template>
                  <span class="mr-16px putSmallDotInBefore">
                    Last updated
                    <span class="selectedActive">{{ task.updated_at | filter_date_current }}</span>
                  </span>
                </span>
                <span class="d-flex secondPart">
                  <span class="mr-16px d-flex align-items-center">
                    <b-icon v-if="myDay" class="mr-8px" icon="sun-fill" variant="dark" width="auto" height="19px"/>
                    <b-icon v-if="task.is_important=='1'" class="mr-8px" icon="exclamation-lg" variant="dark" width="auto" height="19px"/>
                    <b-icon v-if="task.note" icon="file-earmark-text-fill" variant="dark" width="16px" height="19px"/>
                  </span>
                  <div class="categoryText" :style="`background-color: ${task.group ? task.group.color : ''};`">{{task | get_property('group.name')}}</div>
                </span>
              </div>
            </div>
          </div>
        </div>
        <div class="TODO-taksTable-bodyRow-column second" :class="`${assigned.count>0?'moreThanThree':assigned.array.length==3?'exactlyThree':''}`">
          <vb-avatar :showPopover="true" v-for="assigned in assigned.array" :is_blf="false" :key="assigned.id" :id="assigned.assigned_to" />
          <div class="countPlus" v-if="assigned.count">+ {{assigned.count}}</div>
        </div>
        <div class="TODO-taksTable-bodyRow-column third" :style="`background-color: ${task.status ? task.status.color : 'transparent'}`">
          <div v-if="task.status" class="TODO-taksTable-bodyRow-column-text colorWhite">{{ task.status.title }}</div>
        </div>
        <div class="TODO-taksTable-bodyRow-column fourth" >
          <div v-if="isMore" class="filterDD dropdownVersion whiteBGinputWithGreyRoundedBorder">
            <b-dropdown no-caret :disabled="api.remove_task.send" class="" right>
              <template #button-content>
                <vb-icon icon="todo-three-dots-vertical-icon" variant="dark" class="mx-auto" width="6px" height="27px"/>
              </template>
              <b-dropdown-item @click.stop="removeTask()">{{task.deleted_status=='yes'?'Unarchived':'Archived'}}</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import Info from '../Lists/Info.vue';
export default {
  name: 'TaskItem',
  components: { 
    Info 
  },
  props: {
    task: {
      type: Object,
      default: ()=>({})
    },
    isBoardView: {
      type: Boolean,
      default: false,
    },
    isMore: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      api: {
        remove_task: this.$helperFunction.apiInstance(),
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile'
    ]),
    priorityColor(){
      const priority = this.task?.priority
      if(priority=='high') return '#ff403b'
      else if(priority=='mid') return '#ffba39'
      else return '#8ad129'
    },
    myDay(){ return (this.task?.my_day ?? '').includes(this.getCurrentUser.account)},
    assigned(){
      let array = [], count = 0, assigned = this.task.assigned ?? []
      if(assigned?.length>3) {
        array = assigned.slice(0,3)
      } else {
        array = assigned
      }
      count = assigned.length-3
      return {
        array: array ?? [],
        count: count > 0 ? count : 0
      }
    },
  },
  methods: {
    async removeTask(){
      if(this.api.remove_task.send) return;
      try {
        this.api.remove_task.send=true;
        const { confirm } = await this.$appConfirmation({
          title: "WARNING",
          message: `Are you sure you want to ${this.task.deleted_status=='yes'?'unarchived' : 'archived'}?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.tasklist.delete(this.task.id,{
          ...this.task.deleted_status=='yes' ? {
            flag: 'unarchived'
          } : {}
        })
        this.appNotify({
          message: 'Successfully Deleted',
          type: 'success',
        })
        this.$emit('deleted',{
          task_id: this.task.id
        })
        this.$emit('update-list')
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.remove_task.send=false;
      }
    },
  },
}
</script>

<style>

</style>