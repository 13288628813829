<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen" 
      @before-close="onBeforeClose"
    >
      <template v-if="conditions.screen==screens.edit">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.delete_number.send ? '' : conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20"  />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Blocked Number</div>
          <div class="plus-IOSIcon-container"></div>
        </div>
        <div class="innerModalIos">
          
          <form novalidate @submit.prevent="editnumber()">
            
            <b-alert :show="!!api.edit_number.error_message" variant="danger">{{ api.edit_number.error_message }}</b-alert>
            <div class="basicWhiteIOScard mt-32px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Number</label>
                  <input type="text" onkeypress="return /[0-9]/i.test(event.key)" maxlength="12" :disabled="api.edit_number.send || api.delete_number.send" v-model="forms.edit_number.callerid" label="Number" />
                  <template v-if="forms.edit_number.submitted">
                    <p v-if="$v.forms.edit_number.callerid.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.edit_number.callerid.required">* Number is required</span>
                      <span v-else-if="!$v.forms.edit_number.callerid.numeric">* Number is can only be numeric character</span>
                      <span v-else-if="!$v.forms.edit_number.callerid.minLength">
                        * Number is should be minimum {{ $v.forms.edit_number.callerid.$params.minLength.min }} character
                      </span>
                      <span v-else-if="!$v.forms.edit_number.callerid.maxLength">* Number is can be maximum {{ $v.forms.edit_number.callerid.$params.maxLength.max }} character</span>
                    </p>
                  </template>
                  <template v-if="api.edit_number.validation_errors">
                    <p v-if="api.edit_number.validation_errors.callerid" class="text text-danger animated bounceIntop">
                      <span v-for="(error_message,index) in api.edit_number.validation_errors.callerid" :key="index">* {{ error_message }}</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Label</label>
                  <input class type="text" :disabled="api.edit_number.send || api.delete_number.send" v-model="forms.edit_number.displayname" label="Label " value />
                  <template v-if="forms.edit_number.submitted">
                    <p v-if="$v.forms.edit_number.displayname.$invalid" class="text text-danger animated bounceIntop">
                      <span v-if="!$v.forms.edit_number.displayname.required">* Label is required</span>
                    </p>
                  </template>
                  <template v-if="api.edit_number.validation_errors">
                    <p v-if="api.edit_number.validation_errors.displayname" class="text text-danger animated bounceIntop">
                      <span v-for="(error_message,index) in api.edit_number.validation_errors.displayname" :key="index">* {{ error_message }}</span>
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <div class="textAboveCard">Direction Of Number Block</div>
            <div class="basicWhiteIOScard">
              <div class="basicWhiteIOScard-item">
                <div class="d-flex align-items-center">
                  <div class="headingInsideTable">Block Inbound Calls</div>
                </div>
                <div class="position-relative">
                  <b-form-checkbox 
                    :disabled="api.edit_number.send || api.delete_number.send" 
                    v-model="forms.edit_number.inbound" 
                    class="dialer-switch IosSwitch" switch 
                  />
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="d-flex align-items-center">
                  <div class="headingInsideTable">Block Outbound Calls</div>
                </div>
                <div class="position-relative">
                  <b-form-checkbox 
                    :disabled="api.edit_number.send || api.delete_number.send" 
                    v-model="forms.edit_number.outbound" 
                    class="dialer-switch IosSwitch" switch 
                  />
                </div>
              </div>
            </div>
            <button class="IosFullWidthButton forDone mt-16px mb-16px" :disabled="api.edit_number.send || api.delete_number.send">
              <vb-spinner v-if="api.edit_number.send" />
              <template v-else> Done </template>
            </button>
            <button class="IosFullWidthButton forRemove mt-16px mb-16px" :disabled="api.delete_number.send" @click="deleteInbound()">
              <template > Delete </template>
            </button>
            
            <div class="textBelowCard">
              You can choose which direction you'd like to block this number.
              Inbound will
              <br />prevent calls coming in whereas,
            </div>
            <div class="textBelowCard">
              Outbound will prevent
              calls being made to that <br />number. 
            </div>
            <div class="textBelowCard">
              Selecting both In and
              Outbound will prevent calls to or from this number.
            </div>
          </form>

        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20"  />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Blocked Number</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">
          
          <div class="basicWhiteIOScard mt-32px">
            
            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Label</div>
              <div class="textInsideCard onRightSide">{{ data.number.displayname }}</div>
            </div>

            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Number</div>
              <div class="textInsideCard onRightSide">{{ data.number.callerid }}</div>
            </div>

            <div class="basicWhiteIOScard-item">
              <div class="headingInsideTable">Block</div>
              <div class="textInsideCard onRightSide">{{ data.number.type }}</div>
            </div>
            
            
          </div>

        </div>
      </template>

    </modal>
  </div>
</template>

<script>
import { required, minLength, maxLength, numeric } from "vuelidate/lib/validators";
import { VOIP_API } from "@/utils/index";
let screens = {
  edit: 'edit',
  view: 'view',
}
export default {
  name: "EditBlockAndPrivacy",
  props: {
    modalName: {
      type: String,
      default: 'EditBlockAndPrivacy',
    },
  },
  data() {
    return {
      data: {
        id: '',
        number: {},
      },
      forms: {
        edit_number: this.$helperFunction.formInstance({ 
          data: {
            callerid: '',
            displayname: '',
            inbound: true,
            outbound: true,
          } 
        }),
      },
      api: {
        edit_number: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true, }),
        delete_number: this.$helperFunction.apiInstance({  }),
      },
      conditions: {
        screen: screens.view,
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed:{
    screens(){ return screens },
  },
  validations: {
    forms: {
      edit_number: {
        callerid: { 
          required,
          numeric,
          minLength: minLength(8),
          maxLength: maxLength(12),
        },
        displayname: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      const number = event.params?.number || {}
      this.data.number = number;
      this.data.id = number?.id;
      this.forms.edit_number.callerid = number.callerid;
      this.forms.edit_number.displayname = number.displayname;
      this.forms.edit_number.inbound = ['both','in'].includes(number.type);
      this.forms.edit_number.outbound = ['both','out'].includes(number.type);
    },
    onBeforeClose(){
      this.forms.edit_number.reset()
      this.api.edit_number.reset()
      this.api.delete_number.reset()
      this.data.id=''
      this.data.number={}
      this.conditions.screen=screens.view
    },
    editnumber(){
      let vm = this
      vm.forms.edit_number.submitted=true
      vm.$v.forms.edit_number.$touch();
      if(vm.api.edit_number.send || vm.$v.forms.edit_number.$invalid) return;
      vm.api.edit_number.send=true
      vm.api.edit_number.validation_errors={}
      vm.api.edit_number.error_message=''
      VOIP_API.endpoints.inbound_rule.create({
        callerid: vm.forms.edit_number.callerid,
        displayname: vm.forms.edit_number.displayname,
        type: vm.forms.edit_number.inbound && vm.forms.edit_number.outbound ? 'both' : vm.forms.edit_number.inbound ? 'in' : vm.forms.edit_number.outbound ? 'out' : '',
        id: vm.data.id,
        action: "update",
        enable: "Y",
        command: "busy",
        fid: 12,
        args: "fsdfsd",
        source: "app",
      }).then(() => {
        vm.appNotify({
          message: "Successfully Updated",
          type: "success",
        })
        vm.$modal.hide(vm.modalName);
        vm.$emit("interface");
      }).catch((ex) => {
        vm.api.edit_number.validation_errors=ex.own_errors
        vm.api.edit_number.error_message=ex.own_message
      }).finally(() => {
        vm.api.edit_number.send=false
        vm.forms.edit_number.submitted=false
      });
    },
    deleteInbound(){
      let vm = this
      if(vm.api.delete_number.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this number`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete_number.send=true
            VOIP_API.endpoints.inbound_rule.delete(vm.data.id)
            .then(()=>{
              vm.appNotify({
                message: "Successfully Deleted!",
                type: "success",
              })
              vm.$modal.hide(vm.modalName);
              vm.$emit("interface");
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.delete_number.send=false
            })
          }
        },
      });
    },
  },
};
</script>
