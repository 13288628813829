var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup AddNewBillingCard AddNewTicket",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createTicket();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header mt-3 mb-5"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Add new ticket")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        _vm.api.create_ticket.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        _vm.api.create_ticket.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.create_ticket.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.create_ticket.error_message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "d-flex mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 pr-2"
  }, [_c('label', [_vm._v("Subject "), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_ticket.subject,
      expression: "forms.create_ticket.subject"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "maxlength": _vm.$v.forms.create_ticket.subject.$params.maxLength.max,
      "disabled": _vm.api.create_ticket.send
    },
    domProps: {
      "value": _vm.forms.create_ticket.subject
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_ticket, "subject", $event.target.value);
      }
    }
  }), _vm.forms.create_ticket.submitted && _vm.$v.forms.create_ticket.subject.$invalid || _vm.api.create_ticket.validation_errors.subject ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_ticket.subject.required ? _c('span', [_vm._v("* subject is required")]) : !_vm.$v.forms.create_ticket.subject.minLength ? _c('span', [_vm._v("* subject should be minimum " + _vm._s(_vm.$v.forms.create_ticket.subject.$params.minLength.min) + " character")]) : !_vm.$v.forms.create_ticket.subject.maxLength ? _c('span', [_vm._v("* subject can be maximum " + _vm._s(_vm.$v.forms.create_ticket.subject.$params.maxLength.max) + " character")]) : _vm.api.create_ticket.validation_errors.subject ? _vm._l(_vm.api.create_ticket.validation_errors.subject, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }) : _vm._e()], 2) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2"
  }, [_c('label', [_vm._v("Department")]), _c('vb-select', {
    attrs: {
      "valueField": 'id',
      "textField": 'name',
      "defaultSelectedText": 'Select department',
      "options": _vm.departments,
      "disabled": _vm.api.create_ticket.send
    },
    model: {
      value: _vm.forms.create_ticket.department_id,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_ticket, "department_id", $$v);
      },
      expression: "forms.create_ticket.department_id"
    }
  }), _vm.api.create_ticket.validation_errors.department_id ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_vm.api.create_ticket.validation_errors.department_id ? _vm._l(_vm.api.create_ticket.validation_errors.department_id, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }) : _vm._e()], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "d-flex mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pr-2"
  }, [_c('label', [_vm._v("Category")]), _c('vb-select', {
    attrs: {
      "valueField": 'id',
      "textField": 'name',
      "defaultSelectedText": 'Select category',
      "options": _vm.categories,
      "disabled": _vm.api.create_ticket.send
    },
    model: {
      value: _vm.forms.create_ticket.category_id,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_ticket, "category_id", $$v);
      },
      expression: "forms.create_ticket.category_id"
    }
  }), _vm.api.create_ticket.validation_errors.category_id ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_vm.api.create_ticket.validation_errors.category_id ? _vm._l(_vm.api.create_ticket.validation_errors.category_id, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }) : _vm._e()], 2) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-50 pl-2"
  }, [_c('label', [_vm._v("Type")]), _c('vb-select', {
    attrs: {
      "valueField": 'id',
      "textField": 'name',
      "defaultSelectedText": 'Select type',
      "options": _vm.types,
      "disabled": _vm.api.create_ticket.send
    },
    model: {
      value: _vm.forms.create_ticket.type_id,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_ticket, "type_id", $$v);
      },
      expression: "forms.create_ticket.type_id"
    }
  }), _vm.api.create_ticket.validation_errors.type_id ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_vm.api.create_ticket.validation_errors.type_id ? _vm._l(_vm.api.create_ticket.validation_errors.type_id, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }) : _vm._e()], 2) : _vm._e()], 1)]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100 mt-20px"
  }, [_c('label', [_vm._v("Message "), _c('span', {
    staticStyle: {
      "color": "red"
    }
  }, [_vm._v("*")])]), _c('form', {
    staticClass: "w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_ticket.details,
      expression: "forms.create_ticket.details"
    }],
    staticClass: "ticket-textArea-text w-suto p-3",
    staticStyle: {
      "height": "auto",
      "width": "-webkit-fill-available"
    },
    attrs: {
      "placeholder": "Write your message",
      "rows": "11",
      "disabled": _vm.api.create_ticket.send
    },
    domProps: {
      "value": _vm.forms.create_ticket.details
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_ticket, "details", $event.target.value);
      }
    }
  }, [_vm._v(" Hi, hope you are doing great today. Please check the assigned tasks. Thanks ")])]), _vm.forms.create_ticket.submitted && _vm.$v.forms.create_ticket.details.$invalid || _vm.api.create_ticket.validation_errors.details ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_ticket.details.required ? _c('span', [_vm._v("* Message is required")]) : !_vm.$v.forms.create_ticket.details.minLength ? _c('span', [_vm._v("* Message should be minimum " + _vm._s(_vm.$v.forms.create_ticket.details.$params.minLength.min) + " character")]) : _vm.api.create_ticket.validation_errors.details ? _vm._l(_vm.api.create_ticket.validation_errors.details, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }) : _vm._e()], 2) : _vm._e()])])], 1), _c('div', {
    staticClass: "d-flex justify-content-end mt-4"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.create_ticket.send
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.create_ticket.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1), _c('button', {
    staticClass: "dialer-button dialer-button-transparent ml-3",
    attrs: {
      "type": "button",
      "disabled": _vm.api.create_ticket.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_vm._v("Cancel")])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }