<template>
  <div>
    <div class="mainHeading">Documents</div>
    <div class="mainDescription">
      A list of all emergency contact data added in the system
    </div>
    <b-dropdown no-caret class="primaryBorderDropdown big">
      <template #button-content>
        <div class="button-content">
          <div class="buttonContentText">Employees with emergency contacts</div>
          <b-icon class="downIcon" icon="caret-down-fill" />
        </div>
      </template>
      <b-dropdown-item>
        Employees with emergency contacts
      </b-dropdown-item>
    </b-dropdown>
    <div class="EmployeeDataNotesTable mt-32px">
      <div class="HrmTableHead-container">
        <div class="HrmTableHead w-50">Added to</div>
        <div class="HrmTableHead w-50">Date</div>
      </div>
      <vb-table class="HrmTable">
        <template #body>
          <tr class="dialer-row-select">
            <td class="dialer-row-title w-50">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-textContainer">
                  <div class="nameWithImage-text">Jackson Will</div>
                  <div class="nameWithImage-textSmall">8001</div>
                </div>
              </div>
            </td>
            <td class="dialer-row-title w-50">
              Amsterdam - production
            </td>
          </tr>
          <tr class="dialer-row-select">
            <td class="dialer-row-title w-50">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/2.jpeg')" />
                </div>
                <div class="nameWithImage-textContainer">
                  <div class="nameWithImage-text">Jackson Will</div>
                  <div class="nameWithImage-textSmall">8001</div>
                </div>
              </div>
            </td>
            <td class="dialer-row-title w-50">
              UK - Digital production
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeEmergencyContacts',
}
</script>

<style>

</style>