var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup developerOptionsModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm.api.check.status == 1 ? [_vm._v("Select Region and Platform")] : [_vm._v("Select Setup")]], 2), _c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticStyle: {
      "max-height": "calc(100vh - 120px)",
      "overflow-y": "auto",
      "height": "calc(100vh - 120px)"
    }
  }, [_c('div', {
    staticClass: "dialer-login-form"
  }, [_vm.api.verify_pincode.status != 1 ? _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.verifyPincode();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 w-100"
  }, [_c('div', {
    staticClass: "wrap w-100"
  }, [_c('div', {
    staticClass: "signupLoginV2-section-2-right w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('b-alert', {
    attrs: {
      "variant": "danger",
      "show": !!_vm.api.verify_pincode.error_message
    }
  }, [_vm._v(_vm._s(_vm.api.verify_pincode.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder text-left mt-20px"
  }, [_c('div', {
    staticClass: "w-75 text-left d-flex mx-auto flex-column justify-content-center"
  }, [_c('label', {
    staticClass: "w-100"
  }, [_vm._v("Enter PIN")]), _c('vb-pin-code', {
    staticClass: "input",
    attrs: {
      "disabled": _vm.api.verify_pincode.send,
      "numberslength": _vm.$v.forms.verify_pincode.pincode.$params.maxLength.max
    },
    on: {
      "change": function ($event) {
        _vm.forms.verify_pincode.pincode = $event;
      }
    }
  })], 1), _vm.forms.verify_pincode.submitted && _vm.$v.forms.verify_pincode.pincode.$invalid ? [_c('b-icon', {
    staticClass: "danger-info-icon",
    attrs: {
      "id": "su-email",
      "icon": "info-circle-fill",
      "variant": "danger"
    }
  }), _c('div', {
    staticClass: "errorTemplate-v2"
  }, [_c('p', {
    staticClass: "animated bounceIntop"
  }, [!_vm.$v.forms.verify_pincode.pincode.required ? _c('span', [_vm._v("* pincode is required")]) : !_vm.$v.forms.verify_pincode.pincode.maxLength ? _c('span', [_vm._v("* pincode can be maximum " + _vm._s(_vm.$v.forms.verify_pincode.pincode.$params.maxLength.max) + " character")]) : !_vm.$v.forms.verify_pincode.pincode.minLength ? _c('span', [_vm._v("* pincode should be minimum " + _vm._s(_vm.$v.forms.verify_pincode.pincode.$params.minLength.min) + " character")]) : _vm._e()])])] : _vm._e()], 2), _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-20px"
  }, [_c('b-button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "disabled": _vm.api.verify_pincode.send,
      "type": "submit",
      "variant": "primary"
    }
  }, [_vm.api.verify_pincode.send ? _c('vb-spinner') : [_vm._v("Check")]], 2)], 1)], 1)])])])]) : _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.check();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 w-100"
  }, [_c('div', {
    staticClass: "wrap w-100"
  }, [_c('div', {
    staticClass: "signupLoginV2-section-2-right w-100 pr-3"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [!!_vm.api.check.error_message ? _c('b-alert', {
    attrs: {
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.check.error_message))]) : _vm._e(), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder text-left dropdownVersion w-100"
  }, [_c('label', {
    staticClass: "text-left"
  }, [_vm._v("Select Setup")]), _c('vb-select', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.cp_list.send,
      "selected": _vm.forms.cp_list.point,
      "options": _vm.cpListOptions
    },
    on: {
      "change": function ($event) {
        _vm.forms.cp_list.point = $event;
        _vm.changeCP();
      }
    }
  })], 1), _vm.isElectron ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Developer Options")]), _c('b-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.electron.dev_mode,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.electron, "dev_mode", $$v);
      },
      expression: "$store.state.electron.dev_mode"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Disable Desktop App Update")]), _c('b-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": ""
    },
    model: {
      value: _vm.$store.state.electron.update.disabled,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.electron.update, "disabled", $$v);
      },
      expression: "$store.state.electron.update.disabled"
    }
  })], 1)]) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Console Log")]), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-DeveloperConsoleLog`);
      }
    }
  }, [_vm._v("View")])])])], 1), _vm.isLoggedIn ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Test Mode")]), _c('b-form-checkbox', {
    ref: "test_mode",
    staticClass: "newerSwitch",
    attrs: {
      "disabled": _vm.api.update_test_mode.send || _vm.api.get_test_mode.send,
      "checked": _vm.forms.update_test_mode.test_mode,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateTestMode();
      }
    }
  })], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Features")]), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-DeveloperFeaturesModal`);
      }
    }
  }, [_vm._v("View")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Team Pickup Groups")]), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-PickupGroupsModal`);
      }
    }
  }, [_vm._v("View")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Sound Library")]), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-SoundLibraryModal`, {
          ivr_show: true
        });
      }
    }
  }, [_vm._v("View")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Errors")]), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-DeveloperErrorsStoreModal`);
      }
    }
  }, [_vm._v("View")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call Activity Comparison")]), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-DeveloperCallActivityComperison`);
      }
    }
  }, [_vm._v("View")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Analytics Reporting")]), _c('button', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-DeveloperAnalyticsReporting`);
      }
    }
  }, [_vm._v("View")])])])]) : _vm._e()])])])])], 1)]), _c('PickupGroupsModal', {
    attrs: {
      "modalName": `${_vm._uid}-PickupGroupsModal`,
      "teamPickupGroup": true
    }
  }), _c('SoundLibraryModal', {
    attrs: {
      "modalName": `${_vm._uid}-SoundLibraryModal`
    }
  }), _c('DeveloperFeaturesModal', {
    attrs: {
      "modalName": `${_vm._uid}-DeveloperFeaturesModal`
    }
  }), _c('DeveloperErrorsStoreModal', {
    attrs: {
      "modalName": `${_vm._uid}-DeveloperErrorsStoreModal`
    }
  }), _c('DeveloperCallActivityComparisonModal', {
    attrs: {
      "modalName": `${_vm._uid}-DeveloperCallActivityComperison`
    }
  }), _c('DeveloperConsoleLog', {
    attrs: {
      "modalName": `${_vm._uid}-DeveloperConsoleLog`
    }
  }), _c('DeveloperAnalyticsReporting', {
    attrs: {
      "modalName": `${_vm._uid}-DeveloperAnalyticsReporting`
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }