<template>
  <div class="top-main-hrm">
    <section>
      <div class="bg-hrm-mike scrollow1">
        <div class="d-flex justify-content-between align-items-start">
          <div class="w-75 for-home-dd-width d-flex flex-wrap">     
            <div class="home-dd-container">
              <Multiselect 
                v-model="filter.permissions.teams" 
                :options="response.teams" 
                :disabled="api.fetch_teams.send"
                :multiple="true" 
                :close-on-select="false" 
                :clear-on-select="false" 
                :preserve-search="true" 
                placeholder="Select Teams" 
                label="teamName" 
                track-by="teamName" 
                :preselect-first="true"
              />
            </div>
            <div class="home-dd-container">
              <Multiselect 
                v-model="filter.permissions.resources_groups" 
                :options="response.resources_groups" 
                :disabled="api.fetch_resources_groups.send"
                :multiple="true" 
                :close-on-select="false" 
                :clear-on-select="false" 
                :preserve-search="true" 
                placeholder="Select Recourse Groups" 
                label="resource_type" 
                track-by="resource_type" 
                :preselect-first="true"
              />
            </div>
          </div> 

          <div class="w-25 top-btn-align">
            <!-- <button class="btn-svg-none" @click="design.grid=false">
              <svg id="Group_16231" data-name="Group 16231" xmlns="http://www.w3.org/2000/svg" class ="mr-top-sidebsvg" width="12.033" height="15.016" viewBox="0 0 12.033 15.016">
                  <path id="Icon_ionic-md-arrow-dropright" data-name="Icon ionic-md-arrow-dropright" d="M0,0,6.016,6.016,0,12.033Z" transform="translate(12.033 9) rotate(90)" fill="#5576d1"/>
                  <path id="Icon_ionic-md-arrow-dropright-2" data-name="Icon ionic-md-arrow-dropright" d="M0,0,6.016,6.016,0,12.033Z" transform="translate(0 6.016) rotate(-90)" fill="#5576d1"/>
              </svg>
            </button>
            <button class="btn-svg-none" @click="design.grid=true">
              <svg id="Icon_ionic-ios-menu" data-name="Icon ionic-ios-menu" xmlns="http://www.w3.org/2000/svg" class ="mr-top-sidebsvg" width="21.607" height="15.75" viewBox="0 0 21.607 15.75">
                  <path id="Path_16" data-name="Path 16" d="M25.207,12.375H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,12.375Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
                  <path id="Path_17" data-name="Path 17" d="M25.207,19.125H5.4A1.035,1.035,0,0,1,4.5,18h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,19.125Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
                  <path id="Path_18" data-name="Path 18" d="M25.207,25.875H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,25.875Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
              </svg>
            </button> -->
          </div>

        </div>
        
        <div class="card-container-main-box mt-5">
          <template>
            <vb-table 
              :isListEmpty="filterResources.length==0" 
              :listLength="filterResources.length" 
              :loading="api.fetch_permissions.send" 
            >
              <tr slot="header">
                <th class="dialer-has-sort">
                  <span>Resource</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Type</span>
                </th>
                <th v-for="user in filterUsers" :key="user.id" class="dialer-has-sort dialer-col-center">
                  <span>{{ user | get_property('subuser.firstName') }} {{ user | get_property('subuser.lastName') }}</span>
                </th>
              </tr>
              <template #body="{ start, end }">
                <tr v-for="(resource, index) in filterResources" :key="resource.id" v-show="index >= start && index <= end">
                  <td class="dialer-row-title dialer-col-name-width wd-75">
                    <div class="d-flex dialer-numberWithCountry">
                      <span class="d-flex flex-column ml-1 dialer-numberInfo justify-content-center">
                        <span class="m-0 didNumber">{{ resource.resource_name }}</span>
                      </span>
                    </div>
                  </td>
                  <td class="dialer-row-title dialer-col-name-width wd-75">
                    <div class="d-flex dialer-numberWithCountry">
                      <span class="d-flex flex-column ml-1 dialer-numberInfo justify-content-center">
                        <span class="m-0 didNumber">{{ resource | get_property('resource_group.resource_type') }}</span>
                      </span>
                    </div>
                  </td>
                  <td v-for="user in filterUsers" :key="user.id" class="dialer-col-center wd-25">
                    <b-icon :icon="user | permissionAllow(resource)" />
                  </td>
                </tr>
              </template>
            </vb-table>
          </template>
        </div>
      
      </div>
    </section>
  </div>
</template>

<script>

import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import { GET_PAMARLY_ORGANIZATION_ID } from '@/store/helper/getters'
import Multiselect from 'vue-multiselect'
export default {
  name: 'PamarlyPermissions',
  components: {
    Multiselect,
  },
  inject:['local_filess','appNotify'],
  data(){
    return {
      api: {
        fetch_resources_groups: this.$helperFunction.apiInstance(),
        fetch_teams: this.$helperFunction.apiInstance(),
        fetch_permissions: this.$helperFunction.apiInstance(),
      },
      filter: {
        permissions: {
          teams: [],
          resources_groups: [],
        },
      },
      response: {
        resources_groups: [],
        teams: [],
        permissions: {
          resources: [],
          users: [],
        },
      },
      design: {
        grid: true,
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_PAMARLY_ORGANIZATION_ID
    ]),
    filterPermissionsTeams(){ return this.filter.permissions.teams.map(i=>i.id) },
    filterPermissionsResourcesGroups(){ return this.filter.permissions.resources_groups.map(i=>i.id) },
    filterResources(){ 
      if(this.filterPermissionsResourcesGroups.length){
        return this.response.permissions.resources.filter(i=>this.filterPermissionsResourcesGroups.includes(i.resource_group.id)) 
      } else {
        return this.response.permissions.resources
      }
    },
    filterUsers(){ 
      if(this.filterPermissionsTeams.length){
        return this.response.permissions.users.filter(i=>i.subuser.teams_users.findIndex(t=>this.filterPermissionsTeams.includes(t.teamId))>-1) 
      } else {
        return this.response.permissions.users
      }
    },
  },
  filters: {
    permissionAllow(user,resource){
      const resourceGroupId = resource?.resource_group?.id
      const index = user?.subuser?.assignedUser?.findIndex?.(i=>i.resourceGroupId==resourceGroupId)
      return index>-1 ? 'check2' : 'x'
    },
  },
  methods: {
    fetchResourcesGroups(){
      let vm = this 
      vm.api.fetch_resources_groups.send=true
      VOIP_API.endpoints.pamarly.resources.groupslist(vm.GET_PAMARLY_ORGANIZATION_ID)
      .then(({ data: { result: resources_groups } })=>{
        vm.response.resources_groups=resources_groups
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.fetch_resources_groups.send=false
      })
    },
    fetchTeams(){
      let vm = this 
      vm.api.fetch_teams.send=true
      VOIP_API.endpoints.pamarly.teams.list(vm.GET_PAMARLY_ORGANIZATION_ID)
      .then(({ data: { result: teams } })=>{
        vm.response.teams=teams
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.fetch_teams.send=false
      })
    },
    fetchPermissions(){
      let vm = this 
      vm.api.fetch_permissions.send=true
      VOIP_API.endpoints.pamarly.permissions.list(vm.GET_PAMARLY_ORGANIZATION_ID)
      .then(({ data: { data: { resources, usersData: users } } })=>{
        vm.response.permissions.resources=resources
        vm.response.permissions.users=users
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.fetch_permissions.send=false
      })
    },
  },
  mounted(){
    this.fetchResourcesGroups()
    this.fetchTeams()
    this.fetchPermissions()
  },
}
</script>

<style>

</style>