var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('section', {
    staticClass: "dialer-settings-section dialer-organizations-settings"
  }, [_vm._m(0), _vm._m(1), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filterOrganizations),
      "listLength": _vm.filterOrganizations.length,
      "perPage": 5,
      "conditions": {
        loading: {
          show: false
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterOrganizations, function (organization, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: organization.id,
            staticClass: "w-100"
          }, [_c('td', {
            class: ['dialer-row-title', organization.id !== _vm.$store.getters.getCurrentUser.selected_organization_id ? 'w-50' : 'w-75']
          }, [_c('div', {
            staticClass: "d-flex w-100 align-items-center"
          }, [_c('div', {
            staticClass: "img-container mr-3 d-flex justify-content-center align-items-center"
          }, [_c('img', {
            staticStyle: {
              "height": "35px",
              "width": "35px",
              "border-radius": "40% 40% 40% 40% / 40% 40% 40% 40%"
            },
            attrs: {
              "src": organization.id === _vm.$store.getters.getCurrentUser.selected_organization_id ? _vm.$store.getters.getCurrentUser.companyLogo : `${_vm.organizationimagespath}${organization.logo}` || ''
            },
            on: {
              "error": function ($event) {
                $event.target.src = require('@/assets/images/user-male-icon.svg');
              }
            }
          })]), _vm._v(" " + _vm._s(`${organization.label} (${organization.uid})`) + " ")])]), organization.id === _vm.$store.getters.getCurrentUser.selected_organization_id ? _c('td', {
            class: ['dialer-row-title', _vm.isActionTabShow ? '' : 'dialer-col-right']
          }, [_c('b-icon', {
            attrs: {
              "icon": "check-circle-fill",
              "variant": "success",
              "scale": "1.5"
            }
          })], 1) : _vm._e(), organization.id !== _vm.$store.getters.getCurrentUser.selected_organization_id ? _c('td', {
            staticClass: "dialer-col-right w-25"
          }, [organization.id !== _vm.$store.getters.getCurrentUser.selected_organization_id ? _c('b-button', {
            attrs: {
              "squared": "",
              "variant": "outline-danger"
            },
            on: {
              "click": function ($event) {
                $event.stopPropagation();
                return _vm.leave(organization);
              }
            }
          }, [_vm._v("Leave")]) : _vm._e()], 1) : _vm._e()]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    class: ['dialer-has-sort', _vm.isActionTabShow ? '' : 'dialer-col-right']
  }, [_c('span', [_vm._v("Active")])]), _vm.isActionTabShow ? _c('th', {
    staticClass: "dialer-has-sort dialer-col-right"
  }, [_c('span', [_vm._v("Action")])]) : _vm._e()])])], 2)], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-flex"
  }, [_c('h2', {
    staticClass: "dialer-settings-title mb-0"
  }, [_vm._v("Organizations")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('p', {
    staticClass: "dialer-settings-intro with-button"
  }, [_c('span', [_vm._v("Organizations associated with your email address.")])]);

}]

export { render, staticRenderFns }