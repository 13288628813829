<template>
  <modal class="dialer_animation right_side_popup AwaitApprovalModal HrmForModalScroll" name="AwaitApprovalModal"  :clickToClose="true"  :scrollable="true">
    <div class="modalHeading-container">
      <div class="modalHeading">Time off request</div>
      <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('AwaitApprovalModal')">Close</button>
    </div>
    <div class="modalInner-container">
      <div class="grayCard borderRadius-12px flexWrap px-4">
        <div class="nameWithImage w-100">
          <div class="imageContainer">
            <img :src="require('@/assets/images/credit-card/1.jpeg')" />
          </div>
          <div class="nameWithImage-text heavy">Jackson Will</div>
        </div>
        <div class="smallText pl-61px w-100">Sickday on 02/09/2022 - 03/09/2022, 1 day</div>
        <div class="smallText pl-61px w-100">Awaiting approval</div>
        <div class="d-flex justify-content-between w-100 align-items-center mt-32px">
          <div class="d-flex">
            <button class="OutlineButton green withIcon mr-16px"> 
              <b-icon icon="check" scale="1.5" font-scale="1.2"/>
              Approve 
            </button>
            <button class="OutlineButton red withIcon mr-16px"> 
              <b-icon icon="x" scale="1.5" font-scale="1.2"/>
              Decline
            </button>
          </div>
          <vb-icon icon="pencil-hrmIcon" @click="$modal.show('EditTimeOffRequestModal')" style="cursor:pointer;" height="20px" width="20px"/>
        </div>
      </div>
      <div class="collapseAble-conatiner mt-32px">
        <div :class="`collapseAble-head ${showCollapsableItems == 'first' ? 'opened' : ''}`" @click="showCollapsableItems = 'first'">
          <div class="collapseAble-headText">Time off balance for Sickday</div>
          <b-icon class="downIcon" icon="chevron-down" font-scale="1.3"/>
        </div>
        <div :class="`collapseAble-body ${showCollapsableItems == 'first' ? 'opened' : ''}`">
          <div class="smallText w-100">0 days used</div>
        </div>
      </div>
      <div class="collapseAble-conatiner mt-32px">
        <div :class="`collapseAble-head ${showCollapsableItems == 'second' ? 'opened' : ''}`" @click="showCollapsableItems = 'second'">
          <div class="collapseAble-headText">Also away from Employees without team</div>
          <b-icon class="downIcon" icon="chevron-down" font-scale="1.3"/>
        </div>
        <div :class="`collapseAble-body ${showCollapsableItems == 'second' ? 'opened' : ''}`">
          <div class="smallText w-100">No one else from Employees without team has time off on these dates</div>
        </div>
      </div>
      <div class="collapseAble-conatiner mt-32px">
        <div :class="`collapseAble-head ${showCollapsableItems == 'third' ? 'opened' : ''}`" @click="showCollapsableItems = 'third'">
          <div class="collapseAble-headText">Additional details</div>
          <b-icon class="downIcon" icon="chevron-down" font-scale="1.3"/>
        </div>
        <div :class="`collapseAble-body ${showCollapsableItems == 'third' ? 'opened' : ''}`">
          <div class="smallText w-100">details</div>
        </div>
      </div>
      <div class="collapseAble-conatiner mt-32px">
        <div :class="`collapseAble-head ${showCollapsableItems == 'fourth' ? 'opened' : ''}`" @click="showCollapsableItems = 'fourth'">
          <div class="collapseAble-headText">Activity</div>
          <b-icon class="downIcon" icon="chevron-down" font-scale="1.3"/>
        </div>
        <div :class="`collapseAble-body ${showCollapsableItems == 'fourth' ? 'opened' : ''}`">
          <div class="nameWithImage w-100">
            <div class="imageContainer">
              <img :src="require('@/assets/images/credit-card/1.jpeg')" />
            </div>
            <div class="nameWithImage-text heavy">Jackson Will</div>
          </div>
          <div class="smallText pl-61px w-100">Request Created on 19/09/2022 15:45</div>
          <div class="innerShadowInput-container whiteBG mt-16px">
            <label>Textarea</label>
            <textarea />
          </div>
          <div class="smallText w-100">Those with access to this time off request will receive an email with your comment</div>
          <button class="dialer-button dialer-button-primary mt-32px mr-0"> Submit </button>
        </div>
      </div>
    </div>
    <EditTimeOffRequestModal />
  </modal>
</template>

<script>
import EditTimeOffRequestModal from './EditTimeOffRequestModal.vue'
export default {
  name:'AwaitApprovalModal',
  components: {
    EditTimeOffRequestModal,
  },
  data(){
    return {
      showCollapsableItems:'first',
    }
  },
}
</script>

<style>

</style>