import { TokenService } from '@/services/storage.service';
import createPersistedState from 'vuex-persistedstate';
import presistedStateStorage from './helper/storage';


export default createPersistedState({
  key: TokenService.PAMARLY_DATA.key,
  paths: [
    `pamarly.user`,
    `pamarly.token`,
    `pamarly.refresh_token`,
  ],
  storage: presistedStateStorage(TokenService.PAMARLY_DATA)
});