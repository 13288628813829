var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "sec-common"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('button', {
    staticClass: "btn btn-primarymike",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('HRMAddNewTaskModal');
      }
    }
  }, [_c('svg', {
    staticStyle: {
      "margin-right": "10px"
    },
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "16.551",
      "height": "16.551",
      "viewBox": "0 0 16.551 16.551"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_ionic-ios-add",
      "data-name": "Icon ionic-ios-add",
      "d": "M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z",
      "transform": "translate(-8.465 -8.465)",
      "fill": "#fff",
      "stroke": "#fff",
      "stroke-width": "1"
    }
  })]), _vm._v(" Add Task ")])]), _vm._m(1), _c('vb-table', {
    staticClass: "tasktablewidth",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth task-firsttd"
  }, [_vm._v("Name")]), _c('h5', {
    staticClass: "commonth task-secondtd"
  }, [_vm._v("Assigned By")]), _c('h5', {
    staticClass: "commonth task-thirdtd"
  }, [_vm._v("Attachement required")]), _c('h5', {
    staticClass: "commonth task-fourtd tbl-tskdeadline"
  }, [_vm._v("deadline")]), _c('h5', {
    staticClass: "commonth task-fivetd col-right"
  }, [_vm._v("Action")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "task-firsttd"
  }, [_vm._v(" Design Model ")]), _c('td', {
    staticClass: "task-secondtd"
  }, [_vm._v(" Jackson ")]), _c('td', {
    staticClass: "task-thirdtd"
  }, [_vm._v(" No ")]), _c('td', {
    staticClass: "task-fourtd"
  }, [_vm._v(" 27/07/2022 ")]), _c('td', {
    staticClass: "task-fivetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "size": "lg",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])]), _c('tr', [_c('td', {
    staticClass: "task-firsttd"
  }, [_vm._v(" Design Model ")]), _c('td', {
    staticClass: "task-secondtd"
  }, [_vm._v(" Jackson ")]), _c('td', {
    staticClass: "task-thirdtd"
  }, [_vm._v(" No ")]), _c('td', {
    staticClass: "task-fourtd"
  }, [_vm._v(" 27/07/2022 ")]), _c('td', {
    staticClass: "task-fivetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])], 2)], 1)]), _c('HRMAddNewTaskModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Tasks")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mt-5"
  }, [_c('button', {
    staticClass: "tabs-hrm mr-3"
  }, [_vm._v("Outstanding")]), _c('button', {
    staticClass: "tabs-hrm"
  }, [_vm._v("Completed")]), _c('h5', {
    staticClass: "emp-profile-heading mar-top-hrm25"
  }, [_vm._v("My Tasks")])]);

}]

export { render, staticRenderFns }