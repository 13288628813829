var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hrm-home-container"
  }, [_c('div', {
    staticClass: "hrm-home-inner twoParts"
  }, [_c('div', {
    staticClass: "defaultPart left"
  }, [_c('div', {
    staticClass: "hrmCard"
  }, [_c('div', {
    staticClass: "hrmCard-head"
  }, [_c('div', {
    staticClass: "hrmCard-heading"
  }, [_c('div', {
    staticClass: "headIcon-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "myTasks-hrmIcon",
      "height": "17.85px",
      "width": "15.06px"
    }
  })], 1), _vm._v(" My tasks ")])]), _c('div', {
    staticClass: "hrmCard-body"
  }, [_c('div', {
    staticClass: "userInfo-container"
  }, [_c('div', {
    staticClass: "userInfo-container-inner"
  }, [_c('div', {
    staticClass: "userInfo-img"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/default.png'),
      "alt": "logo"
    }
  })]), _vm._m(0)]), _c('div', {
    staticClass: "userInfo-actions"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "tick-hrmIcon",
      "height": "20px",
      "width": "15.5px"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "cross-hrmIcon",
      "height": "15.5px",
      "width": "15.5px"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "eye-hrmIcon",
      "height": "15px",
      "width": "22px"
    }
  })], 1)]), _c('div', {
    staticClass: "userInfo-container"
  }, [_c('div', {
    staticClass: "userInfo-container-inner"
  }, [_c('div', {
    staticClass: "userInfo-img"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/default.png'),
      "alt": "logo"
    }
  })]), _vm._m(1)]), _c('div', {
    staticClass: "userInfo-actions"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "tick-hrmIcon",
      "height": "20px",
      "width": "15.5px"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "cross-hrmIcon",
      "height": "15.5px",
      "width": "15.5px"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "eye-hrmIcon",
      "height": "15px",
      "width": "22px"
    }
  })], 1)])])]), _c('div', {
    staticClass: "hrmCard"
  }, [_c('div', {
    staticClass: "hrmCard-head"
  }, [_c('div', {
    staticClass: "hrmCard-heading"
  }, [_c('div', {
    staticClass: "headIcon-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "calenderLeave-hrmIcon",
      "height": "17.85px",
      "width": "15.06px"
    }
  })], 1), _vm._v(" Upcoming time off ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary hrmButton"
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "plus",
      "scale": "1.7"
    }
  }), _vm._v(" Request time off ")], 1), _c('button', {
    staticClass: "dialer-button ml-3 outline hrmButton"
  }, [_c('vb-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "history-hrmIcon",
      "height": "20px",
      "width": "15.5px"
    }
  }), _vm._v(" History ")], 1)])]), _c('div', {
    staticClass: "hrmCard-body"
  }, [_c('div', {
    staticClass: "userInfo-container"
  }, [_c('div', {
    staticClass: "userInfo-container-inner"
  }, [_c('div', {
    staticClass: "userInfo-img"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/default.png'),
      "alt": "logo"
    }
  })]), _vm._m(2)]), _c('div', {
    staticClass: "userInfo-actions"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "tick-hrmIcon",
      "height": "20px",
      "width": "15.5px"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "cross-hrmIcon",
      "height": "15.5px",
      "width": "15.5px"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "pencil-hrmIcon",
      "height": "15.5px",
      "width": "15.5px"
    }
  }), _c('vb-icon', {
    attrs: {
      "icon": "eye-hrmIcon",
      "height": "15px",
      "width": "22px"
    }
  })], 1)])])]), _c('div', {
    staticClass: "hrmCard"
  }, [_c('div', {
    staticClass: "hrmCard-head"
  }, [_c('div', {
    staticClass: "hrmCard-heading"
  }, [_c('div', {
    staticClass: "headIcon-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "calender-hrmIcon",
      "height": "20px",
      "width": "26px"
    }
  })], 1), _vm._v(" Employees without team - Next two weeks ")])]), _c('div', {
    staticClass: "hrmCard-body"
  }, [_c('div', {
    staticClass: "userInfo-withCalender-container"
  }, [_vm._m(3), _c('div', {
    staticClass: "dayBar withDate"
  }, [_c('div', {
    staticClass: "userInfo-container"
  }, [_c('div', {
    staticClass: "userInfo-container-inner"
  }, [_c('div', {
    staticClass: "userInfo-img"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/default.png'),
      "alt": "logo"
    }
  })]), _vm._m(4)])]), _vm._m(5)])])])])]), _c('div', {
    staticClass: "defaultPart right"
  }, [_c('div', {
    staticClass: "hrmCard"
  }, [_c('div', {
    staticClass: "hrmCard-head"
  }, [_c('div', {
    staticClass: "hrmCard-heading"
  }, [_c('div', {
    staticClass: "headIcon-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "cakeWithCandle-hrmIcon",
      "height": "20px",
      "width": "17.06px"
    }
  })], 1), _vm._v(" Anniversaries ")])]), _c('div', {
    staticClass: "hrmCard-body"
  }, [_c('div', {
    staticClass: "userInfo-container"
  }, [_c('div', {
    staticClass: "userInfo-img"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/default.png'),
      "alt": "logo"
    }
  })]), _vm._m(6)])])]), _c('div', {
    staticClass: "hrmCard"
  }, [_c('div', {
    staticClass: "hrmCard-head"
  }, [_c('div', {
    staticClass: "hrmCard-heading"
  }, [_c('div', {
    staticClass: "headIcon-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "myEmployees-hrmIcon",
      "height": "20px",
      "width": "26px"
    }
  })], 1), _vm._v(" My employees ")])]), _c('div', {
    staticClass: "hrmCard-body"
  }, [_c('div', {
    staticClass: "userInfo-container"
  }, [_c('div', {
    staticClass: "userInfo-img"
  }, [_c('img', {
    attrs: {
      "src": require('@/assets/images/default.png'),
      "alt": "logo"
    }
  })]), _vm._m(7)])])]), _c('div', {
    staticClass: "hrmCard"
  }, [_c('div', {
    staticClass: "hrmCard-head"
  }, [_c('div', {
    staticClass: "hrmCard-heading"
  }, [_c('div', {
    staticClass: "headIcon-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "calenderTick-hrmIcon",
      "height": "20px",
      "width": "26px"
    }
  })], 1), _vm._v(" My Balance ")])]), _vm._m(8)])])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "userInfo"
  }, [_c('div', {
    staticClass: "userInfo-heading"
  }, [_vm._v("Amber West")]), _c('div', {
    staticClass: "userInfo-content"
  }, [_vm._v("Sickday on 07/07/2022, second part of the day, 6 hours (awaiting approval)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "userInfo"
  }, [_c('div', {
    staticClass: "userInfo-heading"
  }, [_vm._v("Amber West")]), _c('div', {
    staticClass: "userInfo-content"
  }, [_vm._v("Sickday on 07/07/2022, second part of the day, 6 hours (awaiting approval)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "userInfo"
  }, [_c('div', {
    staticClass: "userInfo-heading"
  }, [_vm._v("Amber West")]), _c('div', {
    staticClass: "userInfo-content"
  }, [_vm._v("Sickday on 07/07/2022, second part of the day, 6 hours (awaiting approval)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dayBar"
  }, [_c('div', {
    staticClass: "dayBar-inner"
  }, [_c('div', {
    staticClass: "dayName currentDay"
  }, [_vm._v("Mo")]), _c('div', {
    staticClass: "dayName"
  }, [_vm._v("Tu")]), _c('div', {
    staticClass: "dayName"
  }, [_vm._v("We")]), _c('div', {
    staticClass: "dayName"
  }, [_vm._v("Th")]), _c('div', {
    staticClass: "dayName"
  }, [_vm._v("Fr")]), _c('div', {
    staticClass: "dayName offDay"
  }, [_vm._v("Sa")]), _c('div', {
    staticClass: "dayName offDay"
  }, [_vm._v("Su")]), _c('div', {
    staticClass: "dayName"
  }, [_vm._v("Mo")]), _c('div', {
    staticClass: "dayName"
  }, [_vm._v("Tu")]), _c('div', {
    staticClass: "dayName"
  }, [_vm._v("We")]), _c('div', {
    staticClass: "dayName"
  }, [_vm._v("Th")]), _c('div', {
    staticClass: "dayName"
  }, [_vm._v("Fr")]), _c('div', {
    staticClass: "dayName offDay"
  }, [_vm._v("Sa")]), _c('div', {
    staticClass: "dayName offDay"
  }, [_vm._v("Su")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "userInfo"
  }, [_c('div', {
    staticClass: "userInfo-heading"
  }, [_vm._v("Amber West")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dayBar-inner"
  }, [_c('div', {
    staticClass: "dayName selectedDate"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("25")])]), _c('div', {
    staticClass: "dayName"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("26")])]), _c('div', {
    staticClass: "dayName"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("27")])]), _c('div', {
    staticClass: "dayName"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("28")])]), _c('div', {
    staticClass: "dayName"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("29")])]), _c('div', {
    staticClass: "dayName offDay"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("30")])]), _c('div', {
    staticClass: "dayName offDay"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("31")])]), _c('div', {
    staticClass: "dayName"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("01")])]), _c('div', {
    staticClass: "dayName"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("02")])]), _c('div', {
    staticClass: "dayName"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("03")])]), _c('div', {
    staticClass: "dayName"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("04")])]), _c('div', {
    staticClass: "dayName"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("05")])]), _c('div', {
    staticClass: "dayName offDay"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("06")])]), _c('div', {
    staticClass: "dayName offDay"
  }, [_c('div', {
    staticClass: "dateItself"
  }, [_vm._v("07")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "userInfo"
  }, [_c('div', {
    staticClass: "userInfo-heading"
  }, [_vm._v("Amber West")]), _c('div', {
    staticClass: "userInfo-content"
  }, [_vm._v("has 9 year work anniversary tomorrow (27/07/2022)")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "userInfo"
  }, [_c('div', {
    staticClass: "userInfo-heading"
  }, [_vm._v("Amber West")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hrmCard-body"
  }, [_c('div', {
    staticClass: "myBalance-container"
  }, [_c('div', {
    staticClass: "bigNumber"
  }, [_vm._v("8")]), _c('div', {
    staticClass: "mediumNumber"
  }, [_vm._v("Holiday")]), _c('div', {
    staticClass: "smallNumber"
  }, [_vm._v("8.87 days left · updated at 23/07/2022 · 0 days used")])]), _c('div', {
    staticClass: "myBalance-container"
  }, [_c('div', {
    staticClass: "bigNumber sickday"
  }, [_vm._v("2")]), _c('div', {
    staticClass: "mediumNumber"
  }, [_vm._v("Sickday")]), _c('div', {
    staticClass: "smallNumber"
  }, [_vm._v("updated at 23/07/2022 · 2 days used")])]), _c('div', {
    staticClass: "myBalance-container"
  }, [_c('div', {
    staticClass: "bigNumber"
  }, [_vm._v("0")]), _c('div', {
    staticClass: "mediumNumber"
  }, [_vm._v("Holiday - PK")]), _c('div', {
    staticClass: "smallNumber"
  }, [_vm._v("0 days left · updated at 23/07/2022 · 0 days used · 10 days to be accrued")])]), _c('div', {
    staticClass: "myBalance-container"
  }, [_c('div', {
    staticClass: "bigNumber sickday"
  }, [_vm._v("0")]), _c('div', {
    staticClass: "mediumNumber"
  }, [_vm._v("Sickday")]), _c('div', {
    staticClass: "smallNumber"
  }, [_vm._v("updated at 23/07/2022 · 0 days used")])])]);

}]

export { render, staticRenderFns }