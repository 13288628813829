
import { VOIP_API } from "../index"

export const STORAGE_SETTING_API_ENDPOINTS = {
  get(params={}){
    return VOIP_API.axios.voip.request({
      url: 'get-storage-setting',
      method: 'GET',
      params,
    })
  },
  update(data={}){
    return VOIP_API.axios.voip.request({
      url: 'update-storage-setting',
      method: 'POST',
      data,
    })
  },
  statusupdate(data={}){
    return VOIP_API.axios.voip.request({
      url: 'update-storage-sync-status',
      method: 'POST',
      data,
    })
  }
}