<template>
  <div class="dialer-settings-main">
    <template v-if="conditions.screen==screens.change_tab">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          <div class="settingsTextOnTop">Settings</div>
        </div>
        <div class="dialer-settings-title topMainHeading">Music On Hold</div>
        <div class="plus-IOSIcon-container">
          
        </div>
      </div>
      <section class="dialer-settings-section dialer-musicOnHold-settings">
        

        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item" v-for="tab in tabs" :key="tab.id" @click="selected.tab=tab.id">
            <div class="headingInsideCard">{{ tab.text }}</div>
            <vb-icon v-if="selected.tab==tab.id" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
          </div>
        </div>

      </section>
    </template>
    <template v-else>
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$emit('back')">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          <div class="settingsTextOnTop">Settings</div>
        </div>
        <div class="dialer-settings-title topMainHeading">Music On Hold</div>
        <div class="plus-IOSIcon-container">
          
        </div>
      </div>
      <section class="dialer-settings-section dialer-musicOnHold-settings">
        


        <div class="basicWhiteIOScard mt-32px mb-16px">
          
          <MusicOnHoldWidget class="noBottomBorder" :accountcode="getCurrentUser.default_accountcode" />
        </div>
        <div class="mobileInputIOS iconOnLeft mt-22px">
          <b-input v-if="selected.tab==tabs.system.id" placeholder="Search Playlists" type="text" v-model.lazy="filter.system_playlist.search" />
          <b-input v-else-if="selected.tab==tabs.custom.id" placeholder="Search Playlists" type="text" v-model.lazy="filter.playlist.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
        <div class="textAboveCard">Playlists</div>
        <div class="basicWhiteIOScard mb-16px">
          <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.change_tab" >
            
            <div class="d-flex flex-column">
              <div class="headingInsideCard">Playlist type</div>
            </div>
            <div class="d-flex align-items-center">
              <div class="textInsideCard mr-8px textAlignRight onRightSide">{{selectedTab.text}}</div>
              <div :class="{'rotate-svg IosVersion': conditions.select_tab}">
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
          </div>
        </div>
        <div class="basicWhiteIOScard mb-16px">
          <template v-if="conditions.select_tab">
            <div class="basicWhiteIOScard-item"
              v-for="tab in tabs" 
              :key="tab.id" 
              @click="selected.tab=tab.id"
            >
              <div class="headingInsideCard">{{ tab.text }}</div>
              <vb-icon v-if="selected.tab==tab.id" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            </div>
          </template>
          <template v-if="selected.tab==tabs.system.id">
            <div class="basicWhiteIOScard-item"
              v-for="playlist in filterSystemPlaylist" 
              :key="playlist.id" 
              @click="checkPermission(getUserPermissions.multi_moh,()=>$modal.show('SystemPlaylist', {
                id: playlist.id,
                name: playlist.label,
              }))"
            >
              <div class="d-flex flex-column">
                <div class="headingInsideTable">
                  {{ playlist.label }}
                </div>
                
              </div>
              <div class="d-flex align-items-center">
                <vb-audio-player v-if="playlist.playlist.length > 0" class="mr-6px IosAudioPlayer" :src="playlist | get_property('playlist[0].sound_file.sound')" />
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
            <vb-no-record v-if="filterSystemPlaylist.length==0" :text="api.playlists.send?'':'There is no playlist'" :design="3">
              <vb-loading v-if="api.playlists.send" slot="loading" />
            </vb-no-record>
          </template>
          <template v-else-if="selected.tab==tabs.custom.id">
            <div class="basicWhiteIOScard-item"
              v-for="playlist in filterCustomPlaylist" 
              :key="playlist.id" 
              @click="checkPermission(getUserPermissions.multi_moh,()=>$modal.show('EditPlaylist', {
                id: playlist.id,
                name: playlist.label,
              }))"
            >
              <div class="d-flex flex-column">
                <div class="headingInsideTable">{{ playlist.label }}</div>
                
              </div>
              <div class="d-flex align-items-center">
                <vb-audio-player v-if="playlist.playlist.length > 0" class="mr-6px IosAudioPlayer" :src="playlist | get_property('playlist[0].sound_file.sound')" />
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>
            <vb-no-record v-if="filterCustomPlaylist.length==0" :text="api.playlists.send?'':'There is no playlist'" :design="3">
              <vb-loading v-if="api.playlists.send" slot="loading" />
            </vb-no-record>
          </template>
        </div>
        
        <AddPlayListModal @interface="afterAdding" />
        <SelectPlaylistModal @updated="callRouteSetting()" @close="afterAdding()" />
        <SystemPlaylistModal />
        <EditPlaylistModal @close="afterAdding()" @updated="callRouteSetting()" />
      </section>
    </template>
  </div>
</template>

<script>
import AddPlayListModal from "../modals/playlist/AddPlaylist.vue";
import EditPlaylistModal from "../modals/playlist/EditPlaylist.vue";
import SystemPlaylistModal from "../modals/playlist/SystemPlaylist.vue";
import SelectPlaylistModal from "../modals/playlist/SelectPlaylist.vue";
import MusicOnHoldWidget from '../widgets/MusicOnHold.vue'
import { $fn, VOIP_API } from "../../../utils";
import { mapGetters } from 'vuex';
import { permission_handling } from '@/mixin';

let tabs = {
  system: {
    id: 1,
    text: 'Library',
  },
  custom: {
    id: 2,
    text: 'Custom',
  },
}
let screens = {
  change_tab: 'change_tab',
  main: 'main',
}
export default {
  name: "MusicOnHold",
  mixins: [permission_handling],
  components: {
    MusicOnHoldWidget,
    AddPlayListModal,
    EditPlaylistModal,
    SelectPlaylistModal,
    SystemPlaylistModal,
  },
  data() {
    return {
      api: {
        delete: {
          count: 0,
          send: [],
          status: false,
          token: null,
          error: {},
          error_message: '',
          validation_errors: {},
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = []
            this.status = false
            this.token = null
            this.error = {}
            this.validation_errors = {}
            this.error_message = ''
          },
        },
        playlists: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          validation_errors: {},
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.validation_errors = {}
            this.error_message = ''
          },
        },
        call_route: {
          count: 0,
          send: false,
          status: false,
          token: null,
          error: {},
          error_message: '',
          validation_errors: {},
          get errorShow(){return this.count>0},
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = false
            this.token = null
            this.error = {}
            this.validation_errors = {}
            this.error_message = ''
          },
        },
      },
      response: {
        playlists: [],
        call_route: {},
      },
      conditions: {
        expended_row: {},
        select_tab: false,
        screen: screens.main,
      },
      music_on_hold: {
        enable: false,
        id: '',
        name: '',
      },
      filter: {
        playlist: {
          search: '',
        },
        system_playlist: {
          search: '',
        },
      },
      selected: {
        tab: tabs.system.id
      },
      data: {
        random_id: '',
      },
    };
  },
  inject:['isEmpty','appNotify'],
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getCurrentUser: 'getCurrentUser',
    }),
    modalNames(){
      return {
        music_on_hold: `${this.data.random_id}MusicOnHold`
      }
    },
    selectedTab(){ return Object.values(tabs).find(tab=>tab.id==this.selected.tab) },
    tabs() { return tabs },
    customPlaylistLength(){ return this.response.playlists.reduce((a, b)=>b.system_default!='yes' ? a + 1 : a,0) },
    filterCustomPlaylist(){
      return this.response.playlists.filter(item=>`${item.label}`.trim().toLowerCase().includes(this.filter.playlist.search.trim().toLowerCase()))
    },
    filterSystemPlaylist(){
      return [].filter(item=>`${item.label}`.trim().toLowerCase().includes(this.filter.system_playlist.search.trim().toLowerCase()))
    },
    screens(){ return screens },
  },
  methods: {
    callRouteSetting() {
      let vm = this
      vm.api.call_route.send = true;
      vm.api.call_route.source?.cancel?.("Requesting Again");
      vm.api.call_route.source = require("axios").default.CancelToken.source()
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.getCurrentUser.default_accountcode,
      },vm.api.call_route.source.token)
      .then(({ data: call_route }) => {
        vm.music_on_hold.enable = !!call_route?.playlistid;
        vm.music_on_hold.id = call_route?.playlistid ?? '';
        vm.music_on_hold.name = call_route?.playlistname ?? '';
      })
      .finally(() => {
        vm.api.call_route.send = false;
        vm.api.call_route.source = null;
      });
    },
    afterAdding() {
      let vm = this
      setTimeout(() => {
        vm.fetchPlaylist();
        // vm.callRouteSetting();
      }, 2 * 1000);
    },
    // callRouteSetting() {
    //   let vm = this
    //   if (vm.api.call_route.send) return;
    //   vm.api.call_route.send = true;
    //   VOIP_API.endpoints.users.callroute({
    //     // accountcode: vm.current_user.default_accountcode,
    //     accountcode: vm.$store.getters.getCurrentUser?.default_accountcode,
    //     flag: "globalmoh",
    //   }).then(({ data: call_route }) => {
    //     vm.response.call_route=call_route
    //     vm.music_on_hold.enable = !!call_route?.playlistid;
    //     vm.music_on_hold.id = call_route?.playlistid ?? '';
    //     vm.music_on_hold.name = call_route?.playlistname ?? '';
    //   }).finally(() => {
    //     vm.api.call_route.send = false;
    //   });
    // },
    fetchPlaylist() {
      let vm = this
      vm.api.playlists.send = true;
      VOIP_API.endpoints.music_on_hold.list({
        fid: 12,
      }).then(({ data: res }) => {
        vm.response.playlists = res?.musiconholds?.data ?? [];
      }).catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        vm.api.playlists.send = false;
      });
    },
    deletePlayList(playlist) {
      let vm = this
      if (vm.api.delete.send.includes(playlist.id)) return;
      vm.$appConfirmation({
        title: "Confirm",
        message: `Are you sure you want to delete this playlist?`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback({confirm}) {
          if (confirm) {
            vm.api.delete.send.push(playlist.id);
            VOIP_API.endpoints.music_on_hold.delete(playlist).then(() => {
              vm.fetchPlaylist();
              vm.appNotify({
                message: "Playlist deleted successfully.",
                type: "success",
              })
            }).catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(() => {
              if (vm.api.delete.send.includes(playlist.id)) vm.api.delete.send.splice(vm.api.delete.send.indexOf(playlist.id),1)
            });
          }
        },
      });
    },
  },
  created(){
    this.data.random_id=$fn.makeid()
  },
  activated() {
    this.fetchPlaylist();
    this.callRouteSetting()
  },
  deactivated(){
    this.conditions.screen=screens.main
  },
};
</script>
