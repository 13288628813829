<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.edit">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Team</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">

            <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
              <b-alert class="mt-32px" :show="!!api.update_label.error_message" variant="danger">{{api.update_label.error_message}}</b-alert>
              <div class="basicWhiteIOScard mt-32px mb-16px">
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Name</label>
                    <input 
                      placeholder="Enter Name" 
                      class="w-100" 
                      type="text" 
                      :disabled="api.update_label.send" 
                      v-model="forms.update_label.name" 
                      @input="updateLabel"
                    />
                    <p 
                      v-if="(forms.update_label.submitted && $v.forms.update_label.name.$invalid) || api.update_label.validation_errors.name" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_label.name.required">* Name is required</span>
                      <span v-for="em in api.update_label.validation_errors.name" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
              </div>
            </form>

          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Team</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="profile-image-section-container forIos mt-32px">
              <div 
                class="profile-image-section"
                @click="$modal.show('TeamProfileImageUploader',{ 
                  type: 'TEAM', 
                  accountcode: team.real_id, 
                  current_image: teamImage
                })"
              >
                <div class="profile-img">
                  <img 
                    style="height: 100%; width: 100%" 
                    id="user-profile-image-updated" 
                    :src="teamImage" 
                    @error="$event.target.src=local_filess.images.user_male" 
                    :alt="team.name" 
                  />
                </div>
              </div>
            </div>
            <div class="BiggestMainHeading mt-22px">{{ team.name }}</div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item" @click="$modal.show('EditTeamAssignNumberModal', { accountcode, })">
                <div class="headingInsideTable">Team Numbers</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ dids.length }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div class="basicWhiteIOScard-item" @click="$modal.show('AssignMembersTeam',{ real_id: data.real_id })">
                <div class="headingInsideTable">Team Members</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ team | get_property('members.length',0) }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
            </div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Pickup Groups</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    ref="pickup_groups"
                    :disabled="!getUserPermissions.call_pickup || api.delete_team.send || api.update_pickgroup.send" 
                    :checked="conditions.pickup_droup" 
                    @change="updatePickupGroup($event);"
                  />
                </div>
              </div>
              <OutOfHourWidget class="withBottomBorder" :accountcode="accountcode" />
              <CallForwardingWidget class="withBottomBorder" :accountcode="accountcode" />
              <MusicOnHoldWidget :accountcode="accountcode" />
            </div>

          </div>
        </div>
      </template>
      <ProfileImageUploaderModal :modalName="'TeamProfileImageUploader'" @image-uploaded="$emit('updated')" />
      <AssignNumbersModal :modalName="'EditTeamAssignNumberModal'" @added="$emit('updated')" />
      <AddRemoveUsersModal :modalName="'AssignMembersTeam'" :type="'assign_team'" @update-list="$emit('updated')" />
    </modal>
  </div>
</template>

<script>
// AssignMembersTeam
import OutOfHourWidget from '../../widgets/OutOfHours.vue'
import CallForwardingWidget from '../../widgets/CallForwarding.vue'
import MusicOnHoldWidget from '../../widgets/MusicOnHold.vue'

import ProfileImageUploaderModal from '../common/ProfileImageUploader.vue'
import AssignNumbersModal from '../numbers/AssignNumbers.vue'
import AddRemoveUsersModal from '../users/AddRemoveUsers.vue'

import { VOIP_API, } from "@/utils";

import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import _ from 'lodash';

let screens = {
  edit: 'edit',
  view: 'view',
}
export default {
  name: "EditTeamModal",
  inject: ['local_filess', 'getProperty','appNotify'],
  components: {
    OutOfHourWidget,
    CallForwardingWidget,
    MusicOnHoldWidget,

    ProfileImageUploaderModal,
    AssignNumbersModal,
    AddRemoveUsersModal,

  },
  props: {
    modalName: {
      type: String,
      default: 'EditTeam',
    },
  },
  data() {
    return {
      api: {
        delete_team: this.$helperFunction.apiInstance(),
        call_route: this.$helperFunction.apiInstance(),
        update_pickgroup: this.$helperFunction.apiInstance(),
        update_label: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        reset(){
          this.delete_team?.reset?.()
          this.call_route?.reset?.()
          this.update_pickgroup?.reset?.()
          this.update_label?.reset?.()
        },
      },
      data: this.$helperFunction.stateInstance({
        data: {
          real_id: '',
          accountcode: '',
          team: null,
        }
      }),
      forms: {
        update_label: this.$helperFunction.formInstance({
          data: {
            name: '',
          }
        }),
        reset(){
          this.update_label?.reset?.()
        },
      },
      conditions: this.$helperFunction.stateInstance({
        data: {
          screen: screens.view,
          pickup_droup: false,
        }
      }),
    };
  },
  validations: {
    forms: {
      update_label: {
        name: { 
          required,
        }
      },
    },
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getVoipTeams',
      'getCurrentUser',
    ]),
    screens(){ return screens },
    team(){ return this.getVoipTeams?.find?.(team=>team.real_id==this.data.real_id) ?? {} },
    dids(){ return this.team?.ringroup?.did ?? [] },
    accountcode(){ return this.team?.ringroup?.accountcode ?? '' },
    realID(){ return this.team?.real_id ?? '' },
    teamImage(){ return this.team?.avatar?.image?.image ?? '' },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.real_id = event?.params?.real_id ?? ''
      this.$nextTick()
      .then(()=>{
        this.forms.update_label.name=this.team.name
        this.conditions.pickup_droup=this.team.pickupgroup_enable=='yes'
      })
    },
    onBeforeClose() {
      this.api.reset()
      this.data.reset()
      this.forms.reset()
      this.conditions.reset()
    },
    updatePickupGroup(value){
      let vm = this
      if(vm.api.update_pickgroup.send) return;
      vm.api.update_pickgroup.send=true
      VOIP_API.endpoints.teams.updatepickupgroupoption(vm.data.real_id,{
        pickupgroup: value ? 'yes' : 'no',
      })
      .then(()=>{
        vm.conditions.pickup_droup=value
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.$emit('updated')
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.$refs.pickup_groups.localChecked=vm.conditions.pickup_droup
        vm.api.update_pickgroup.send=false
      })
    },
    updateLabel: _.debounce(function(){
      let vm = this
      vm.forms.update_label.submitted=true
      vm.$v.forms.update_label.$touch()
      if(vm.api.update_label.send || vm.$v.forms.update_label.$invalid) return;
      vm.api.update_label.send=true
      vm.api.update_label.error_message=''
      vm.api.update_label.validation_errors={}
      VOIP_API.endpoints.teams.update(this.data.real_id, {
        name: vm.forms.update_label.name,
      })
      .then(()=>{
        vm.$emit('updated')
        vm.appNotify({
          message: 'Successfully Updated',
          type: 'success'
        })
      })
      .catch((ex)=>{
        vm.api.update_label.error_message=ex.own_message
        vm.api.update_label.validation_errors=ex.own_errors
      })
      .finally(()=>{
        vm.forms.update_label.submitted=false
        vm.api.update_label.send=false
      });
    },2 * 1000),
    deleteTeam() {
      let vm = this
      if(vm.api.delete_team.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this?`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete_team.send=true
            VOIP_API.endpoints.teams.delete({
              id: vm.data.real_id,
              uid: vm.getCurrentUser?.uid,
              accountcode: vm.getCurrentUser?.account,
            })
            .then(()=>{
              return vm.$store.getters.getVoipTeams.filter(i=>i.real_id!=vm.team.real_id)
            })
            .then((teams)=>{
              vm.$store.commit("setVoipTeams", {
                data: teams,
                total: vm.$store.state.common.teams.total-1
              });
              vm.$modal.hide(vm.modalName)
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.delete_team.send=false
            })
          }
        },
      });
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-image-section {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto;
  overflow: hidden;
  @include border-radius(50%);
  margin-bottom: 3 * $dialer-default-padding;
  .loader-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .profile-img-upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: none;
    justify-content: center;
    .upload-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      opacity: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(50%);
    }
  }
  &:hover {
    .profile-img-upload-btn {
      display: block;
      cursor: pointer;
    }
  }
}
</style>
