var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "NotesAndTagsModalInsideCallInfoModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', [_c('div', {
    staticClass: "dialer-edit-header position-relative py-0 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title my-0"
  }, [_vm._v(" Update notes and tags ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call tags")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 mt-20px"
  }, [_c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search Tags"
    },
    model: {
      value: _vm.filter.tag.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.tag, "search", $$v);
      },
      expression: "filter.tag.search"
    }
  })], 1), _vm._l(_vm.api.tag.validation_errors.log, function (em, i) {
    return _c('p', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), _c('div', {
    staticClass: "d-flex flex-wrap w-100 mt-20px"
  }, [_vm._l(_vm.filterTags, function (tag) {
    return _c('div', {
      key: tag.real_id,
      class: `${_vm.forms.tag.tags.includes(tag.real_id) ? 'selected' : ''} latestTagDesignLayout`,
      on: {
        "click": function ($event) {
          _vm.api.tag.send ? '' : _vm.forms.tag.tags.includes(tag.real_id) ? _vm.forms.tag.tags.splice(_vm.forms.tag.tags.indexOf(tag.real_id), 1) : _vm.forms.tag.tags.push(tag.real_id);
        }
      }
    }, [_c('small', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color",
        value: tag.colour,
        expression: "tag.colour"
      }],
      style: `border-color: ${_vm.forms.tag.tags.includes(tag.real_id) ? '#3699FF' : ''}`
    }, [_c('span', {
      directives: [{
        name: "vb-tag-color",
        rawName: "v-vb-tag-color.text",
        value: tag.text_color,
        expression: "tag.text_color",
        modifiers: {
          "text": true
        }
      }]
    }, [_vm._v(" " + _vm._s(tag.tag) + " ")])])]);
  }), _vm.isEmpty(_vm.filterTags) ? _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("There is no tags")]) : _vm._e()], 2), _vm._l(_vm.api.tag.validation_errors.log, function (em, i) {
    return _c('p', {
      key: i,
      staticClass: "latestGreyBox-descriptionText"
    }, [_vm._v(_vm._s(em))]);
  }), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.tag.send,
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updatetag();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.tag.send ? _c('vb-spinner') : [_vm._v(" Submit ")]], 2)], 1)], 1)], 2)])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call notes")]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 w-100 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Write some note for this call")]), _c('b-textarea', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.note.send,
      "rows": "10",
      "no-resize": "",
      "placeholder": "Enter Note"
    },
    model: {
      value: _vm.forms.note.note,
      callback: function ($$v) {
        _vm.$set(_vm.forms.note, "note", $$v);
      },
      expression: "forms.note.note"
    }
  })], 1), _c('div', {
    staticClass: "d-flex justify-content-end mt-20px"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.note.send,
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.updatenote();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.note.send ? _c('vb-spinner') : [_vm._v(" Submit ")]], 2)], 1)], 1)])])])]), _c('div')])]);

}
var staticRenderFns = []

export { render, staticRenderFns }