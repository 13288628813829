<template>
  <div class="dialer-analytics-main dialer-analytics-users">
    <div v-if="conditions.screen==1" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" >
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.screen = 0" />
      </div>
      <div class="dialer-settings-title topMainHeading">Select Date</div>
    </div>
    <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Users</div>
    </div>

    <div class="dialer-box AnalyticsContainerInner">
      
      <template v-if="conditions.screen==1">
        
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div 
            class="basicWhiteIOScard-item" 
            v-for="(tab, index) in tabs" 
            :key="index" 
            @click="selected.tab = tab.key; selected.tab!='custom' ? fetchUsersAnalytics(true) : ''; selected.tab!='custom' ? conditions.screen = 0 : '';"
          >
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <div class="headingInsideTable">{{ tab.value }}</div>
              </div>  
            </div>
            <vb-icon v-if="selected.tab === tab.key" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
          </div>
        </div>
        
        <div v-if="selected.tab=='custom'" class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2 forDateTime">
              <label>From</label>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.users_analytics.start_date" id="starttime" />
            </div>
          </div>
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2 forDateTime">
              <label>To</label>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class=" date-time-input" v-model="filter.users_analytics.end_date" id="endtime" />
            </div>
          </div>
          
        </div>
        <a class="IosFullWidthButton forDone mb-16px" @click="fetchUsersAnalytics(true);conditions.screen = 0">Fetch</a>
      </template>
      <template v-else>
        
        
        
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-input  type="text" placeholder="Search user" v-model="filter.users_analytics.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
        
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item" @click="conditions.screen=1">
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <div class="headingInsideTable">Date</div>
              </div>  
            </div>
            <div class="d-flex align-items-center">
              <div class="textInsideCard textAlignRight onRightSide mr-8px">{{ selectedTab.value }}</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
          </div>
        </div>

        <div class="IosTabsSection twoTabs mt-16px mb-16px">
          <div :class="{ 'active': !conditions.advanced }" class="IosTabButton" @click="conditions.advanced=false">Basic</div>
          <div :class="{ 'active': conditions.advanced }" class="IosTabButton" @click="conditions.advanced=true">Advance</div>
        </div>
        
        <div class="basicWhiteIOScard mt-16px mb-16px">
          <div>
            <div 
              :class="`basicWhiteIOScard-item ${conditions.advanced ? 'withAdvancedSection' : ''}`" 
              v-for="time_line in filterTimeLine"
              :key="time_line.measure"
              @click="$modal.show('UserAnalyticsModal', { user: time_line.user, time_line: time_line, })"
            >
              <div class="w-100 d-flex align-items-center justify-content-between">
                <div class="d-flex align-items-center">
                  <Info class="InfoComponentInsideAnroidIOSApp" :is_blf="false" :id="time_line.kpis.accountcode" :name="time_line.user.name" :sub_info="time_line.user.extn" /> 
                </div>
                <div class="d-flex align-items-center">
                  <div class="d-flex mr-6px">
                    <div class="textInsideCard onRightSide textAlignRight" style="color: #87868C;">{{ time_line | get_property('user.totalCalls',0) }}</div>
                    <div class="textInsideCard onRightSide textAlignRight mx-1">/</div>
                    <div class="textInsideCard onRightSide textAlignRight" style="color: #FF1900;">{{ time_line | get_property('user.totalMissCalls',0) }}</div>
                  </div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
                </div>
              </div>
              
              <div class="advanceSection" v-if="conditions.advanced">

                <div class="advanceSection-inner InboundCall">
                  <div class="aboveProgressBar">
                    <vb-icon icon="CallSummary-InboundCall-icon" class="rightFacingArrow mr-8px" width="16.642" height="15.275" />
                    <div class="aboveProgressBar-text">{{ time_line | get_property('user.totalIncommingCalls',0) }}</div>
                  </div>
                  <vb-circular-progressive-bar 
                   :percentage="time_line.user.totalIncommingCalls > 0 ? Math.ceil((time_line.user.totalIncommingCalls / time_line.user.totalCalls) * 100) : 0"
                  />
                </div>
                
                <div class="advanceSection-inner outboundCall">
                  <div class="aboveProgressBar">
                    <vb-icon icon="CallSummary-OutboundCall-icon" class="rightFacingArrow mr-8px" width="16.642" height="15.275" />
                    <div class="aboveProgressBar-text">{{ time_line | get_property('user.totalOutgoingCalls',0) }}</div>
                  </div>
                  <vb-circular-progressive-bar 
                   :percentage="time_line.user.totalOutgoingCalls > 0 ? Math.ceil((time_line.user.totalOutgoingCalls / time_line.user.totalCalls) * 100) : 0"
                  />
                </div>

                <div class="advanceSection-inner missedCall">
                  <div class="aboveProgressBar">
                    <vb-icon icon="CallSummary-MissedCall-icon" class="rightFacingArrow mr-8px" width="16.642" height="15.275" />
                    <div class="aboveProgressBar-text">{{ time_line | get_property('user.totalMissCalls',0) }}</div>
                  </div>
                  <vb-circular-progressive-bar 
                   :percentage="time_line.user.totalMissCalls > 0 ? Math.ceil((time_line.user.totalMissCalls / time_line.user.totalCalls) * 100) : 0"
                  />
                </div>

              </div>
            </div>
          </div>
          <InfiniteLoading ref="users_analytics_infinity_loader" @infinite="fetchUsersAnalytics()">
            <div slot="spinner">
              <vb-loading type="loader" />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results">
              <vb-no-record :text="'There is no Numbers'" :design="3" />
            </div>
            <template #error="{ trigger }">
              <div>
                <span>{{ api.users_analytics.error_message }}</span>
                <b-icon icon="arrow-repeat" class="ml-3" variant="primary" font-scale="1.5" @click="trigger()" />
              </div>
            </template>
          </InfiniteLoading>

        </div>
      </template>
      
    </div>

    <UserAnalyticsModal />
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';
import UserAnalyticsModal from "../modals/analytics/User.vue";
import { VOIP_API } from '../../../utils';
import { ctkDateTimePicker } from '../../../mixin';
import Info from "../../Lists/Info.vue";
let tabs = [
  {
    value: "Today",
    key: "today",
  },
  {
    value: "This Week",
    key: "week",
  },
  {
    value: "This Month",
    key: "month",
  },
  {
    value: "Custom",
    key: "custom",
  },
]
export default {
  name: 'UsersAnalytics',
  mixins: [ctkDateTimePicker],
  components: {
    UserAnalyticsModal,
    Info,
    InfiniteLoading,
  },
  inject:['isEmpty'],
  data() {
    return {
      conditions: {
        select_tab: false,
        advanced: false,
        screen: 0,
      },
      response: {
        users_analytics: {
          users: {},
          kpis: {},
          time_line: [],
        },
      },
      selected: {
        tab: tabs[0].key,
      },
      filter: {
        users_analytics: {
          start_date: null,
          end_date: null,
          search: '',
        },
      },
      api: {
        users_analytics: this.$helperFunction.apiInstance({ error_message: true, })
      },
    };
  },
  computed: {
    tabs(){ return tabs },
    selectedTab(){ return this.tabs.find(tab=>tab.key==this.selected.tab) },
    usersTimeLine(){
      const time_line = this.response.users_analytics.time_line
      const users = this.response.users_analytics.users
      const kpis = this.response.users_analytics.kpis
      return time_line.map(item=>{
        return {
          ...item,
          user: users[item.measure],
          kpis: kpis[item.measure],
        }
      })
    },
    filterTimeLine(){
      const search = this.filter.users_analytics.search.trim().toLowerCase()
      return this.usersTimeLine.filter(item=>`${item.user.name} ${item.user.extn}`.trim().toLowerCase().includes(search))
    },
    
  },
  methods: {
    fetchUsersAnalytics(reset) {
      let vm = this
      if(vm.api.users_analytics.send) return;
      if(reset==true){
        vm.response.users_analytics.users = {};
        vm.response.users_analytics.kpis = {};
        vm.response.users_analytics.time_line = [];
        vm.$refs.users_analytics_infinity_loader?.stateChanger?.reset?.()
      }
      vm.api.users_analytics.send = true;
      vm.api.users_analytics.error_message=''
      VOIP_API.endpoints.analytics.list({
        type: vm.selected.tab,
        section: 'users',
        start: vm.filter.users_analytics.start_date,
        end: vm.filter.users_analytics.end_date,
      })
      .then(({ data }) => {
        const kpis = data?.kpis ?? {}
        const time_line = data?.timeLineData ?? []
        const users = data?.data?.users ?? {}
        vm.response.users_analytics.users = users;
        vm.response.users_analytics.kpis = kpis;
        vm.response.users_analytics.time_line = time_line;
        if(time_line?.length) vm.$refs.users_analytics_infinity_loader?.stateChanger?.loaded()
        vm.$refs.users_analytics_infinity_loader?.stateChanger?.complete()
      })
      .catch((ex)=>{
        vm.$refs.users_analytics_infinity_loader?.stateChanger?.error?.()
        vm.api.users_analytics.error_message=ex.own_message || ex.message
      })
      .finally(() => {
        vm.api.users_analytics.send = false;
      });
    },
  },
  mounted() {
    setTimeout(()=>this.fetchUsersAnalytics(true),0.5*1000)
  },
};
</script>
