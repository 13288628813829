var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 writeNoteModal",
    attrs: {
      "name": "WriteCallNoteModal"
    },
    on: {
      "closed": _vm.onClosed,
      "before-open": _vm.beforeOpen
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.sendNote.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Write note")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "dialer-button dialer-button-delete",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('WriteCallNoteModal');
      }
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "newDoneButton d-inline-flex justify-content-center",
    attrs: {
      "type": "submit",
      "disabled": _vm.api_sent.note
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.note ? _c('vb-spinner', {
    staticClass: "sm-mar-right"
  }) : [_vm._v("Done")]], 2)], 1)])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('div', {
    staticClass: "dialer-team-name mb-4"
  }, [_c('div', {
    staticClass: "dialer-input-field"
  }, [_c('label', {
    attrs: {
      "for": ""
    }
  }, [_vm._v("Write some note for this call")]), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.form.note,
      expression: "form.note"
    }],
    staticClass: "w-100",
    attrs: {
      "placeholder": "Enter note",
      "disabled": _vm.api_sent.note
    },
    domProps: {
      "value": _vm.form.note
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.form, "note", $event.target.value);
      }
    }
  })])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }