var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll",
    attrs: {
      "name": "hrmAddNewDocumentsModal"
    }
  }, [_c('div', [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Add document")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('hrmAddNewDocumentsModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "uploadFile-container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "uploadFile-insideModal-hrmIcon"
    }
  }), _c('button', {
    staticClass: "OutlineButton",
    attrs: {
      "type": "button"
    }
  }, [_vm._v("Upload File")])], 1), _c('div', {
    staticClass: "innerShadowInput-container mt-25px"
  }, [_c('label', [_vm._v("Policy name")]), _c('textarea')]), _c('div', {
    staticClass: "innerShadowInput-container forDiv mt-25px"
  }, [_c('div', {
    staticClass: "DocumentIconWithText"
  }, [_c('div', {
    staticClass: "DocumentIcon-Container"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "pdfIcon-insideTable-hrmIcon"
    }
  })], 1), _c('div', {
    staticClass: "DocumentIconWithText-text"
  }, [_vm._v("backupfile")])]), _c('vb-icon', {
    staticClass: "trashIcon",
    attrs: {
      "icon": "trash-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Category")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Accure ")])], 1)], 1), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Number of digits shown after comma")]), _c('b-form-group', {
    staticClass: "HrmRadio-container w-100 mt-32px"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Everyone ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Multiple employees ")])], 1)], 1), _c('div', {
    staticClass: "d-flex mt-32px"
  }, [_c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("Team")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Accure ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("Position")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Accure ")])], 1)], 1)]), _c('div', {
    staticClass: "usersList-withCheckBox-container"
  }, [_c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Select All ")]), _vm._l(5, function (n) {
    return _c('b-form-checkbox', {
      key: n,
      staticClass: "HrmCheckBox-container"
    }, [_c('div', {
      staticClass: "nameWithImage"
    }, [_c('div', {
      staticClass: "imageContainer"
    }, [_c('img', {
      attrs: {
        "src": "/img/1.dd976805.jpeg"
      }
    })]), _c('div', {
      staticClass: "nameWithImage-text"
    }, [_vm._v("Marie jane")])])]);
  })], 2), _c('div', {
    staticClass: "innerShadowInput-container mt-32px"
  }, [_c('label', [_vm._v("Also shared with:")])]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Team manager ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Direct manager ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-32px"
  }, [_vm._v(" Ask to accept the document ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Notify employee by e-mail ")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Upload ")])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }