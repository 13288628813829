
import { VOIP_API } from "../index"

export const FEATURES_API_ENDPOINTS = {
  list(){
    return VOIP_API.axios.voip.request({
      url: "user-feature-list",
      method: "post",
    })
  },
  userAllowedFeatures(data={}){
    return VOIP_API.axios.voip.request({
      method: "post",
      url: "portal-proxy",
      data:{
        ...data,
        type: 'relay',
        action: 'user-allowed-features',
      },
    })
    // return VOIP_API.axios.voip.request({
    //   url: "users-allowed-features",
    //   method: "get",
    //   params,
    // })
  },
}