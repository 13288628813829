<template>
  <modal 
    class="dialer_animation right_side_popup m-w-900" 
    width="50%" 
    height="auto" 
    :scrollable="true" 
    :name="modalName"
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <SoundLibrarySettings :openedFromModal="true" :ivrShow="data.ivr_show" @closeModal="$modal.hide(modalName)" /> 
  </modal>
</template>

<script>
import SoundLibrarySettings from '@/components/Setting/Recordings.vue';
export default {
  name: 'SoundLibraryModal',
  components: {
    SoundLibrarySettings,
  },
  props: {
    modalName: {
      type: String,
      default: 'SoundLibrary'
    },
  },
  data() {
    return {
      data: {
        ivr_show: false,
      }
    }
  },
  methods: {
    onBeforeOpen(event){
      this.data.ivr_show=!!event?.params?.ivr_show
    },
    onBeforeClose(){
      this.data.ivr_show=false
    },
  },
}
</script>

<style>

</style>