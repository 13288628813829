<template> 
  <div class="dialer-settings-main">
    <div v-if="conditions.emergency_services" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="conditions.emergency_services=null">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Emergeny Services</div>
    </div>
    <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Phones</div>
      <div class="plus-IOSIcon-container" @click="$modal.show('PurchaseExtension')">
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992" />
      </div>
    </div>

    <section class="dialer-settings-section dialer-devices-settings">
      <template v-if="conditions.emergency_services">
        
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item">
            <div class="headingInsideCard">Emergency service location</div>
            <div class="textInsideCard textAlignRight onRightSide">{{ conditions.emergency_services.location_house | check_empty('-') }}</div>
          </div>
          <div class="basicWhiteIOScard-item">
            <div class="headingInsideCard">Emergency caller ID</div>
            <div class="textInsideCard textAlignRight onRightSide">{{ conditions.emergency_services.location_callerid | check_empty('-') }}</div>
          </div>
          <div class="basicWhiteIOScard-item">
            <div class="headingInsideCard">Emergency addresses</div>
            <div class="textInsideCard textAlignRight onRightSide">{{ conditions.emergency_services.location | check_empty('-') }}</div>
          </div>
          <div class="basicWhiteIOScard-item">
            <div class="d-flex flex-column">
              <div class="headingInsideCard">Manage handset location</div>
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
      </template>
      <template v-else>
        
        <div class="position-relative">
          <vb-no-record v-if="extensions.length==0" :text="api.extension_detail.send ? `` : 'No record found'" :design="3">
            <vb-loading v-if="api.extension_detail.send" slot="loading" />
          </vb-no-record>
          <template v-else>
            <!-- others -->
            <div class="basicWhiteIOScard mt-32px">
              <div class="basicWhiteIOScard-item" v-for="(extension, index) in extensionslist" :key="index">
                <div class="d-flex align-items-start">
                  <div class="basicIOSIcon mr-16px">
                    <vb-icon class="" :icon="extension.type | extensionIcon"/>
                  </div>
                  <div class="d-flex flex-column">
                    <div class="headingInsideTable">
                      {{ extension.type | extensionText }}
                    </div>
                  </div>
                </div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ extension | get_property('device_status.status') }}</div>
                  <div class="position-relative">
                    <div class="position-relative" 
                      :id="`ext-${index}`" 
                      v-b-tooltip :title="(extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension)?'Your package does not support this feature':''"
                      :class="{
                        'for-disable': (extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || 
                        (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension)
                      }"
                    >
                      <b-form-checkbox 
                        :disabled="
                          api.update_setting.send.includes(extension.accountcode) || 
                          (extension.type=='MOBILEEXT' && !getUserPermissions.mobile_extension) || 
                          (extension.type=='EXTERNALEXT' && !getUserPermissions.external_extension)
                        " 
                        :checked="extension.extension_call_group === 'yes'" 
                        class="dialer-switch IosSwitch" 
                        @change="updateSetting(extension.extension_call_group,extension.accountcode)" 
                        name="check-button" 
                        switch 
                      />
                    </div>  
                  </div>
                </div>
              </div>
            </div>

            <!-- headsets -->
            <div class="textAboveCard">Endpoints Handset</div>
            <div v-for="(data, key) in headExtensions" :key="key">
              <div class="basicWhiteIOScard mb-16px">
                <div class="basicWhiteIOScard-item">
                  <div class="d-flex flex-column">
                    <div class="headingInsideCard">Handset {{ key + 1 }}</div>
                  </div>
                  <div class="position-relative" >
                    <b-form-checkbox 
                      class="dialer-switch IosSwitch" 
                      :checked="data.extension_call_group === 'yes'" 
                      @change="updateSetting(data.extension_call_group,data.accountcode)" 
                      name="check-button" 
                      switch 
                    />
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideCard">Handset location</div>
                  <div class="textInsideCard textAlignRight onRightSide">N/A</div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideCard">Handset status</div>
                  <div class="textInsideCard textAlignRight onRightSide">{{ data.device_status.status | check_empty('-') }}</div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideCard">SIP username</div>
                  <div class="textInsideCard textAlignRight onRightSide">{{ data.accountcode | check_empty('-') }}</div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="d-flex flex-column w-100">
                    <div v-if="data.secret">
                      <div class="mobileInputIOS iconOnRight InputIosV2">
                        <label>SIP password</label>
                        <input 
                          class="password-input-with-icon" 
                          :type="`${conditions.sip_password_shows.includes(data.accountcode)?'text':'password'}`"  
                          :value="data.secret || ''" 
                          :disabled="true"
                        />
                        <div>
                          <b-icon class="mobileInputIOS-icon"
                            :icon="`${conditions.sip_password_shows.includes(data.accountcode)?'eye-fill':'eye-slash-fill'}`" 
                            @click="
                              conditions.sip_password_shows.includes(data.accountcode) ? 
                                conditions.sip_password_shows.splice(conditions.sip_password_shows.indexOf(data.accountcode),1) :
                                conditions.sip_password_shows.push(data.accountcode)
                            " 
                          />
                        </div>
                      </div>
                    </div>
                    <div class="d-flex mt-8px">
                      <div class="linkTypeText mr-16px"  @click="updateSecret(data.accountcode)">Change</div>
                      <div class="linkTypeText" @click="copyText(data.secret)">Copy</div>
                    </div>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideCard">SIP Servers</div>
                  <div class="textInsideCard textAlignRight onRightSide">sip.voipbusiness.com</div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="headingInsideCard">SIP port</div>
                  <div class="textInsideCard textAlignRight onRightSide">5060 or 5061</div>
                </div>
              </div>
              <div class="basicWhiteIOScard mb-16px">
                <div class="basicWhiteIOScard-item" @click="conditions.emergency_services=data">
                  <div class="d-flex flex-column">
                    <div class="headingInsideCard">Emergency Services</div>
                  </div>
                  <div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                </div>
              </div>
            </div>

          </template>

          <PurchaseExtensionModal @load="afterAdding()" />
        </div>
      </template>
    </section>
  </div>
</template>

<script>
import { $fn, VOIP_API } from "../../../utils";
import PurchaseExtensionModal from "../modals/extensions/PurchaseExtension.vue";
import moment from "moment-timezone";
import { mapGetters } from 'vuex';

export default {
  name: "Phones",
  components: {
    PurchaseExtensionModal,
  },
  props: {
    accountcode: {
      type: String,
    },
  },
  data() {
    return {
      conditions: {
        sip_password_shows: [],
        collapse: [],
        emergency_services: null,
      },
      response: {
        extension_detail: {},
      },
      api: {
        extension_detail: this.$helperFunction.apiInstance(),
        update_setting: this.$helperFunction.apiInstance({ send: [] }),
        update_secret: this.$helperFunction.apiInstance({ send: [] }),
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser',
      'getIsMobile',
    ]),
    remainings(){
      const desktop = this.response?.extension_detail?.extensionlimit?.desktop?.remaining ?? 0
      const web = this.response?.extension_detail?.extensionlimit?.web?.remaining ?? 0
      const handset = this.response?.extension_detail?.extensionlimit?.handset?.remaining ?? 0
      const external = this.response?.extension_detail?.extensionlimit?.external?.remaining ?? 0
      const mobile = this.response?.extension_detail?.extensionlimit?.mobile?.remaining ?? 0
      return {
        desktop: desktop<0 ? 0 : desktop,
        web: web<0 ? 0 : web,
        handset: handset<0 ? 0 : handset,
        external: external<0 ? 0 : external,
        mobile: mobile<0 ? 0 : mobile,
      }
    },
    extensions(){ return this.response?.extension_detail?.extensions ?? [] },
    extensionsCount(){ return this.response?.extension_detail?.count ?? {} },
    externalExtensions() { return this.extensions.filter((data) => ["EXTERNALEXT",'external'].includes(data.type)); },
    mobileExtensions() { return this.extensions.filter((data) => ["MOBILEEXT",'mobile'].includes(data.type)); },
    desktopExtensions() { return this.extensions.filter((data) => ["DESKTOPEXT",'desktop'].includes(data.type)); },
    webExtensions() { return this.extensions.filter((data) => ["WEBEXT",'web'].includes(data.type)); },
    headExtensions() { return this.extensions.filter((data) => ["HEADEXT",'headset'].includes(data.type)); },
    extensionslist(){ return [...this.webExtensions,...this.desktopExtensions,...this.mobileExtensions,...this.externalExtensions] },
    voipaccount(){ return this.accountcode || this.getCurrentUser.account },
  },
  filters: {
    dateFormate(value){
      return moment(value).calendar(null, {
        sameDay: '[Today] hh:mm:ss',
        nextDay: '[Tomorrow] hh:mm:ss',
        nextWeek: 'dddd  hh:mm:ss',
        lastDay: '[Yesterday] hh:mm:ss',
        lastWeek: '[Last] dddd',
        sameElse: 'DD MMM YYYY'
      });
    },
    extensionIcon(value){
      if(value=='WEBEXT') return 'Phones-webExtension-IOSIcon'
      else if(value=='DESKTOPEXT') return 'Phones-desktopExtension-IOSIcon'
      else if(value=='MOBILEEXT') return 'Phones-mobileExtension-IOSIcon'
      else if(value=='HEADEXT') return 'Phones-headExtension-IOSIcon'
      else return 'Phones-webExtension-IOSIcon'
    },
    extensionText(value){
      if(value=='WEBEXT') return 'Web Extension'
      else if(value=='DESKTOPEXT') return 'Desktop Extension'
      else if(value=='MOBILEEXT') return 'Mobile Extension'
      else return value
    },
  },
  methods: {
    updateSetting(value, account) {
      let vm = this
      vm.api.update_setting.send.push(account);
      VOIP_API.endpoints.users.updateuserdetail({
        field: "extension",
        accountcode: account,
        account: vm.voipaccount,
        value: value == "yes" ? "no" : "yes",
      })
      .then(() => {
        vm.fetchExtensionDetail();
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.$emit('updated')
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        if (vm.api.update_setting.send.includes(account)) vm.api.update_setting.send.splice(vm.api.update_setting.send.indexOf(account),1);
      });
    },
    afterAdding() {
      let vm = this
      $fn.sleep(5*1000)
      .then(()=>{
        vm.$emit('updated')
        vm.fetchExtensionDetail();
      })
    },
    fetchExtensionDetail() {
      var vm = this;
      vm.api.extension_detail.send = true;
      VOIP_API.endpoints.extensions.mobileextensions(vm.voipaccount)
      .then((res) => {
        vm.response.extension_detail = res?.data?.data ?? {};
      })
      .finally(() => {
        vm.api.extension_detail.send = false;
      });
    },
    updateSecret(account){
      let vm = this
      vm.$appConfirmation({
        title: "Waning",
        message: `Are you sure you want to change password`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.update_secret.send.push(account)
            VOIP_API.endpoints.extensions.updateSecret({
              id: account,
              secret: '',
            })
            .then(() => {
              vm.appNotify({
                message: 'Successfully Updated!',
                type: 'success',
              })
              vm.fetchExtensionDetail();
            })
            .catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              if(vm.api.update_secret.send.includes(account)) vm.api.update_secret.send.splice(vm.api.update_secret.send.indexOf(account),1)
            })
          }
        },
      });
    },
    copyText(text){
      let vm = this
      navigator.clipboard.writeText(text)
      .then(()=>{
        vm.appNotify({
          message: 'Successfully Copied',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.message || 'Allow the Clipboard',
          type: 'danger',
        })
      })
    },
  },
  mounted() {
    if(this.accountcode){
      this.fetchExtensionDetail();
    }
  },
  activated(){
    this.fetchExtensionDetail();
  },
};
</script>





<style lang="scss">
  // .dd-popover{
    // left: -220px !important;
    // top: -10px !important;
  // }
</style>