var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `dialer-circularProgressBar`
  }, [_c('div', {
    class: `circularProgressBar ${_vm.percentage > 50 ? 'gt-50' : ''}`
  }, [_c('div', {
    staticClass: "circularProgressBar-fill"
  })]), _c('div', {
    staticClass: "dialer-circularProgressBar-text"
  }, [_c('span', [_vm._v(_vm._s(_vm.percentage) + "%")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }