<template>
  <div>
    <template v-if="conditions.can_show_toaster">
      <div v-if="permissions.microphone!='granted'" class="dialer-offline-notification v2 forTrialPackage microphoneNotAllowed fixed-top position-absolute ">
        <div class="d-flex align-items-center">
          <vb-icon icon="trialPackageWarning-icon" style="margin:-5px" width="25px" height="20px" class="mr-4"/>
          <span style="line-height:1.1;font-size:15px;">You won't be able to make or receive calls without a microphone</span>
        </div>
      </div>
      <div v-if="conditions.trail_toaster" class="dialer-offline-notification v2 forTrialPackage forTrialPackageReallu fixed-top position-absolute ">
        <div class="d-flex align-items-center">
          <vb-icon icon="trialPackageWarning-icon" style="margin:-5px" width="25px" height="20px" class="mr-4"/>
          <!-- <span style="line-height:1.1;font-size:15px;">Your Package is on trial {{ $store.state.common.current_subscription | get_property('userCurrentSubscription.plan_detail.plan_label') }}</span> -->
          <span style="line-height:1.1;font-size:15px;">Your trial plan is ending on {{getTrialPlanDetails.trial_expiry_date | filter_date_current('',{ formate: 'DD MMM YYYY' })}} in {{ trailEndDays }} days </span>
        </div>
        <b-icon icon="x-circle-fill" variant="black" font-scale="1.5" @click="conditions.trail_toaster=false" />
        <!-- {{$store.state.common.current_subscription}} -->
      </div>
      <div v-if="conditions.grace_period" class="dialer-offline-notification v2 forTrialPackage fixed-top position-absolute gracePeriodRemainingDays">
        <div class="d-flex align-items-center">
          <vb-icon icon="trialPackageWarning-icon" style="margin:-5px" width="25px" height="20px" class="mr-4"/>
          <span style="line-height:1.1;font-size:15px;">Paid your invoice otherwise your account will be suspended after {{ $store.state.common.current_subscription | get_property('grace_period_remaining_days') }} days</span>
        </div>
        <b-icon icon="x-circle-fill" variant="black" font-scale="1.5" @click="conditions.grace_period=false" />
      </div>
      <!-- <div v-if="conditions.plan_toaster" class="dialer-offline-notification v2 forTrialPackage fixed-top position-absolute ">
        <div class="d-flex align-items-center">
          <div class="single-chart mr-3">
            <svg viewBox="0 0 36 36" class="circular-chart orange">
              <path class="circle-bg"
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
              />
              <path class="circle"
                :stroke-dasharray="`${planDowngradeDayLeft*10}, 100`"
                d="M18 2.0845
                  a 15.9155 15.9155 0 0 1 0 31.831
                  a 15.9155 15.9155 0 0 1 0 -31.831"
              />
            </svg>
            <p class="percentage">{{planDowngradeDayLeft}}</p>
          </div>
          <span style="line-height:1.1;font-size:15px;">You have {{ planDowngradeDayLeft }} days left for downgrade your plan</span>
        </div>
        <b-icon icon="x-circle-fill" variant="black" font-scale="1.5" @click="conditions.plan_toaster=false" />
      </div> -->
      <div v-if="conditions.socket_not_connected" :class="`dialer-offline-notification v2 fixed-top position-absolute ${!$online ? 'socketNotConnected-small' : 'socketNotConnected-large'}`">
        <template v-if="!$online">
          <div class="d-flex align-items-center">
            <vb-icon icon="offline-icon" style="margin-top:-3px" width="20px" height="15px" class="mr-4"/>
            <span style="line-height:1.1;font-size:15px;">Oops! You are offline!</span>
          </div>
          <b-icon icon="x-circle-fill" font-scale="1.5" />
        </template>
        <template v-else-if="!$store.state.sip.state.is_connected">
          <div class="d-flex align-items-center justify-content-between w-100" v-if="!$store.state.sip.state.is_connected">
            <div class="d-flex align-items-center">
              <vb-icon icon="offline-icon" style="margin-top:-3px" width="20px" height="15px" class="mr-4"/>
              <span style="line-height:1.1;font-size:15px;">Oh no! we have lost server connection. It will reconnect automatically, but you can refresh by clicking here.</span>
              <div id="indicator-light-tooltip" class="indicator-light v2 ml-4">
                <!-- <b-icon icon="arrow-clockwise" @click="reload()" /> -->
                <vb-icon icon="latest-reload-icon" width="16px" height="16px" @click="reload()" />
              </div>
            </div>
            <b-icon @click="conditions.socket_not_connected=false" icon="x-circle-fill" font-scale="1.5" />
          </div>
        </template>
        <!--<template v-else-if="!conditions.centrifugo_connected">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center">
              <vb-icon icon="offline-icon" style="margin-top:-3px" width="20px" height="15px" class="mr-4"/>
              <span style="line-height:1.1;font-size:15px;">Oh no! we have lost server connection. It will reconnect automatically, but you can refresh by clicking here.</span>
              <!~~ <b-icon @click="onRefreshCentrifugoToken()" class="ml-16px cursor_pointer_no_hover" icon="arrow-clockwise" font-scale="1.5" /> ~~>
              <vb-icon icon="latest-reload-icon" width="16px" height="16px" class="ml-16px cursor_pointer_no_hover" @click="onRefreshCentrifugoToken()" />
            </div>
            <b-icon @click="conditions.socket_not_connected=false" icon="x-circle-fill" font-scale="1.5" />
          </div>
        </template>-->
        <template v-else-if="!conditions.ami_connected">
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="d-flex align-items-center">
              <vb-icon icon="offline-icon" style="margin-top:-3px" width="20px" height="15px" class="mr-4"/>
              <span style="line-height:1.1;font-size:15px;">Oh no! we have lost server connection. It will reconnect automatically, but you can refresh by clicking here.</span>
              <!-- <b-icon @click="onRefreshCentrifugoToken()" class="ml-16px cursor_pointer_no_hover" icon="arrow-clockwise" font-scale="1.5" /> -->
              <vb-icon icon="latest-reload-icon" width="16px" height="16px" class="ml-16px cursor_pointer_no_hover" @click="AMIReconnect()" />
            </div>
            <b-icon @click="conditions.socket_not_connected=false" icon="x-circle-fill" font-scale="1.5" />
          </div>
        </template>
      </div>
    </template>
    
    <div v-if="conditions.beta_banner" class="dialer-offline-notification v2 forTrialPackage fixed-top position-absolute ">
      <div class="d-flex align-items-center">
        <!-- <vb-icon icon="offline-icon" style="margin-top:-3px" width="20px" height="15px" class="mr-4"/> -->
        <span class="betaInBetaBanner">Beta</span>
        <span style="line-height:1.1;font-size:15px;">This is the beta version</span>
      </div>
      <!-- <b-icon @click="conditions.beta_banner=false" icon="x-circle-fill" variant="black" font-scale="1.5" /> -->
      <span @click="conditions.beta_banner=false" class="betaInBetaBanner cursor_pointer_no_hover">Close</span>
    </div>
    <DetectedSpeed @network-type="network.type=$event" @network-speed="network.downlink=$event" />
    
    <div v-if="!getCurrentUser.is_number_purchased" class="toast custome-toast-class opacity-1" >
      <div class="toast-body custome-toast-body-class">
        <div class="new-error-template-2-1 v2">
          <div class="error-message-new">
            <div class="beta-container  z-index-0-imp">
              <div class="beta-text">
                <div class="d-flex align-items-center">
                  <div class="i-container">
                    <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="bell fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-bell-fill b-icon bi text-primary" style="font-size: 200%;"><g><path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"></path></g></svg>
                  </div>
                  <div class="pr-3 word-break">Assigning number to your account is in progress</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>

    <div v-if="!!$store.state.us_number_progress" class="toast custome-toast-class opacity-1" >
      <div class="toast-body custome-toast-body-class">
        <div class="new-error-template-2-1 v2">
          <div class="error-message-new">
            <div class="beta-container  z-index-0-imp">
              <div class="beta-text">
                <div class="d-flex align-items-center">
                  <div class="i-container">
                    <svg viewBox="0 0 16 16" width="1em" height="1em" focusable="false" role="img" aria-label="bell fill" xmlns="http://www.w3.org/2000/svg" fill="currentColor" class="bi-bell-fill b-icon bi text-primary" style="font-size: 200%;"><g><path d="M8 16a2 2 0 0 0 2-2H6a2 2 0 0 0 2 2zm.995-14.901a1 1 0 1 0-1.99 0A5.002 5.002 0 0 0 3 6c0 1.098-.5 6-2 7h14c-1.5-1-2-5.902-2-7 0-2.42-1.72-4.44-4.005-4.901z"></path></g></svg>
                  </div>
                  <div class="pr-3 word-break">{{ $store.state.us_number_progress | number_formater }} is in progress</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
    <audio id="alert-ringtone" v-show="false" :src="media_devices_setting.selectedAlertRingtone | get_property('file')" />
    <ExtraResourcesAlertModal />
    <FeedbackModal modalName="FeedBackCommonComponentModal" @submitted="conditions.feed_back=false" @closed="''" />
  </div>
</template>

<script>
import { TokenService } from "@/services/storage.service";
import FeedbackModal from './Modals/FeedbackModal.vue'
import DetectedSpeed from "vue-identify-network";
import { 
  $fn,
  LOGGER,
  events,
  permissions,
  MEDIA_DEVICES_SETTING,
  VOIP_API,
  // CENTRIFUGO_LOGGER, 
} from "../utils/index.js";
import ExtraResourcesAlertModal from "./Modals/ExtraResourcesAlertModal.vue";
// import { centrifugo } from '../Centrifuge/index.js';
import { electron_events } from '../electron/events';
import { MOTHERSHIP } from '../mothership';
import { mapGetters, mapMutations, mapState } from 'vuex'; 
import moment from 'moment';
import { INTEGRATIONS } from '@/integrations';
import _ from 'lodash';
import { GET_LOCAL_SETTING_CALLS, GET_LOCAL_SETTING_CHAT, GET_LOCAL_SETTING_DASHBOARD, GET_LOCAL_SETTING_NUMBERS, GET_LOCAL_SETTING_THEME } from '@/store/helper/getters';
import { SET_LOCAL_SETTING_CALLS_FEEDBACK, SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD, SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW, SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW, SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE, SET_LOCAL_SETTING_DASHBOARD_DESIGN_CALL_INFO_WIDTH, SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP, SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP, SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW, SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS, SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH, SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE, SET_LOCAL_SETTING_THEME_MODE } from '@/store/helper/mutations';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';

export default {
  name: "",
  components: {
    DetectedSpeed,
    ExtraResourcesAlertModal,
    FeedbackModal,
  },
  inject:[
    'logout',
    'getNumbers',
    'getVoipUsers',
    'getVoipTeams',
    'getCallQueue',
    'getIvrs',
    'getFeatures',
    'appNotify',
  ],
  data() {
    return {
      last_register: '',
      network: {
        type: null,
        downlink: null,
      },
      permissions: {
        microphone: '',
      },
      api: {
        sync_local_setting: this.$helperFunction.apiInstance({ 
          status: true,  
        }),
        get_local_setting: this.$helperFunction.apiInstance(),
      },
      response: {
        local_setting: {},
      },
      conditions: {
        beta_banner: true,
        // centrifugo_connected: true,
        ami_connected: true,
        can_show_toaster: false,
        plan_toaster: false,
        trail_toaster: false,
        grace_period: false,
        socket_not_connected: false,
        feed_back: true,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsersAlisesDisplayName',
      'getVoipTeamsAlises',
      'sipsessions',
      'fpData',
      'getVoipUserAccountcode',
      'getVoipUsersAlises',
      GET_LOCAL_SETTING_THEME,
      GET_LOCAL_SETTING_CHAT,
      GET_LOCAL_SETTING_NUMBERS,
      GET_LOCAL_SETTING_CALLS,
      GET_LOCAL_SETTING_DASHBOARD,
      'getTrialPlanDetails',
    ]),
    trailEndDays(){
      const trial_expiry_date = this.getTrialPlanDetails.trial_expiry_date
      if(trial_expiry_date) {
        const current_time = moment.utc();
        const expire_time = moment.utc(trial_expiry_date);
        return expire_time.diff(current_time, 'days');
      }
      return 0 
    },
    isLocalSettingChanged(){
      const response_setting = this.response.local_setting
      if(response_setting?.theme?.mode!=this.GET_LOCAL_SETTING_THEME?.mode) return true
      else if(response_setting?.chat?.appearence?.is_icon_show!=this.GET_LOCAL_SETTING_CHAT?.appearence?.is_icon_show) return true
      else if(response_setting?.chat?.appearence?.list_view!=this.GET_LOCAL_SETTING_CHAT?.appearence?.list_view) return true
      else if(response_setting?.chat?.appearence?.blink_unread!=this.GET_LOCAL_SETTING_CHAT?.appearence?.blink_unread) return true
      else if(response_setting?.chat?.view?.recent_type!=this.GET_LOCAL_SETTING_CHAT?.view?.recent_type) return true
      else if(response_setting?.numbers?.formating?.international_formate!=this.GET_LOCAL_SETTING_NUMBERS?.formating?.international_formate) return true
      else if(response_setting?.calls?.feedback!=this.GET_LOCAL_SETTING_CALLS?.feedback) return true
      else if(response_setting?.dashboard?.design?.width!=this.GET_LOCAL_SETTING_DASHBOARD?.design?.width) return true
      else if(response_setting?.dashboard?.design?.flip!=this.GET_LOCAL_SETTING_DASHBOARD?.design?.flip) return true
      else if(response_setting?.dashboard?.design?.call_info_width!=this.GET_LOCAL_SETTING_DASHBOARD?.design?.call_info_width) return true
      else if(response_setting?.dashboard?.design?.scroll_top!=this.GET_LOCAL_SETTING_DASHBOARD?.design?.scroll_top) return true
      else if(response_setting?.dashboard?.design?.tags_show!=this.GET_LOCAL_SETTING_DASHBOARD?.design?.tags_show) return true
      else if(response_setting?.dashboard?.design?.transfer_log!=this.GET_LOCAL_SETTING_DASHBOARD?.design?.transfer_log) return true
      else return false
    },
    ...mapState({
      media_devices_setting: state=>state.media_devices_setting,
    }),
    planDowngradeDayLeft(){
      const date = this.$store.state.common.plan_logs?.actionable_date
      if(!date) return undefined
      const today = moment.utc();
      const acctionableDay = moment.utc(date);
      const diff = acctionableDay.diff(today);
      const duration = moment.duration(diff);
      return Math.floor(duration.asDays());
    }
  },
  watch: {
    "$store.state.sip.state.is_connected": {
      immediate: true,
      handler(){
        this.checkSocketConnect()
      }
    },
    "$online": {
      immediate: true,
      handler(){
        this.checkSocketConnect()
      },
    },
    // "conditions.centrifugo_connected": {
    //   immediate: true,
    //   handler(){
    //     this.checkSocketConnect()
    //   },
    // },
    "conditions.ami_connected": {
      immediate: true,
      handler(){
        this.checkSocketConnect()
      },
    },
    "$store.getters.isTrailPlan": {
      immediate: true,
      handler(value){
        this.conditions.trail_toaster=!!value
      }
    },
    "$store.getters.isGracePeriod": {
      immediate: true,
      handler(value){
        this.conditions.grace_period=!!value
      }
    },
    "planDowngradeDayLeft": {
      immediate: true,
      handler(value){
        this.conditions.plan_toaster=value>=0 && value<=10
      }
    },
    "media_devices_setting.alert_ringtone": "mediaDeviceSettingAlertRingtone",
    "media_devices_setting.ringing_speaker_id": "mediaDeviceSettingRingingSpeakerId",
    "media_devices_setting.ringing_speaker_volume": "mediaDeviceSettingRingingSpeakerVolume",
    [GET_LOCAL_SETTING_THEME]: {
      deep: true,
      handler(){
        this.syncLocalSettingDebounce()
      },
    },
    [GET_LOCAL_SETTING_CHAT]: {
      deep: true,
      handler(){
        this.syncLocalSettingDebounce()
      },
    },
    [GET_LOCAL_SETTING_NUMBERS]: {
      deep: true,
      handler(){
        this.syncLocalSettingDebounce()
      },
    },
    [GET_LOCAL_SETTING_CALLS]: {
      deep: true,
      handler(){
        this.syncLocalSettingDebounce()
      },
    },
    [GET_LOCAL_SETTING_DASHBOARD]: {
      deep: true,
      handler(){
        this.syncLocalSettingDebounce()
      },
    },
  },
  filters: {
    formatDate(value) { return new Date(value)?.toDateString?.() ?? '-'; },
  },
  methods: {
    ...mapMutations([
      SET_LOCAL_SETTING_THEME_MODE,
      SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD,
      SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW,
      SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW,
      SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE,
      SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE,
      SET_LOCAL_SETTING_CALLS_FEEDBACK,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_CALL_INFO_WIDTH,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS,
      SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH,
    ]),
    mediaDeviceSettingRingingSpeakerId(ringing_speaker_id){
      const audio = document.getElementById("alert-ringtone"); 
      if(audio) audio.setSinkId(ringing_speaker_id);
    },
    mediaDeviceSettingRingingSpeakerVolume(ringing_speaker_volume){
      const audio = document.getElementById("alert-ringtone"); 
      if(audio) audio.volume = ringing_speaker_volume / 100;
    },
    mediaDeviceSettingAlertRingtone(){
      const audio = document.getElementById("alert-ringtone");  
      if(audio){
        audio.load();
      }
    },
    checkSocketConnect(){
      this.conditions.socket_not_connected = !![this.$store.state.sip.state.is_connected,this.$online,this.conditions.ami_connected/*,this.conditions.centrifugo_connected*/].some(i=>!i)
    },
    mediaDevicesSettingSink(){
      if(this.media_devices_setting?.ringing_speaker_id !== undefined){ this.mediaDeviceSettingRingingSpeakerId(this.media_devices_setting.ringing_speaker_id) }
      if(this.media_devices_setting?.ringing_speaker_volume !== undefined){ this.mediaDeviceSettingRingingSpeakerVolume(this.media_devices_setting.ringing_speaker_volume) }
    },
    onNotification(event) {
      let vm = this
      // console.log('event',event)
      const sip = vm.$store.state.sip.phone || {}
      const user = TokenService.USER.get()
      const { 
        data: { 
          flag, 
          action, 
          section, 
          array, 
          message, 
          type,
          accountcode,
          webExtension,
          
        },
        event: event_string 
      } = event
      if(type=='PD_STATUS') return;
      else if(type=='EXTN_DELETED') {
        setTimeout(() => {
          vm.logout({
            is_user: true
          });
        }, 10 * 1000);
        vm.appNotify({
          title: 'Notification',
          message: "Your this extension is deleted from admin side",
          type: "danger",
          notify_type: 'alert',
        })
        return;
      }
      if(event_string){
        switch (event_string) {
          case 'get_ivr':
            this.getIvrs()
            vm.$store.commit('setAddServiceQueue')
            // vm.checkPendingServices()
            break;
          default:
            break;
        }
      }
      if(flag=='voip-packages'){
        vm.$store.commit('setAddServiceQueue')
        // vm.checkPendingServices()
      }
      if(flag=='FEATURE_LINKED'){
        vm.getFeatures()
        VOIP_API.endpoints.users.accountdetail()
        .then(({ data: accountdetail }) => {
          vm.$store.state.common.user.blf = accountdetail?.user_detail?.blf=='enable';
        })
      }
      if(flag=='NUMBER_ADDED'){
        $fn.sleep(5 * 1000)
        .then(()=>{
          vm.getNumbers()
          vm.$store.state.us_number_progress=''
          vm.$store.commit('setAddServiceQueue')
          // vm.checkPendingServices()
        })
      } else if(flag=='USER_ADDED'){
        vm.getVoipUsers()
        vm.$store.commit('setAddServiceQueue')
        // vm.checkPendingServices()
      } else if(flag=='delete-user-service'){
        vm.logout({
          is_user: true
        });
      } else if(flag=='TEAM_ADDED'){
        vm.getVoipTeams()
        vm.$store.commit('setAddServiceQueue')
        // vm.checkPendingServices()
      } else if(flag=='CALL_QUEUE_ADDED'){
        vm.getCallQueue()
        vm.$store.commit('setAddServiceQueue')
        // vm.checkPendingServices()
      } else if(flag=='IVR_ADDED'){
        vm.getIvrs()
        vm.$store.commit('setAddServiceQueue')
        // vm.checkPendingServices()
      } else if(flag=='NUMBER_DELETED'){
        vm.getNumbers()
      } else if(flag=='USER_DELETED'){
        vm.getVoipUsers()
      } else if(flag=='TEAM_DELETED'){
        vm.getVoipTeams()
      } else if(flag=='CALL_QUEUE_DELETED'){
        vm.getCallQueue()
      } else if(flag=='IVR_DELETED'){
        vm.getIvrs()
      } else if(flag=="LOCAL_SETTING_CHANGED") {
        this.getLocalSetting()
      } 
      // if (flag === "file_uploaded_successfully") {
      //   vm.appNotify({
      //     message: "Your file is successfully uploaded",
      //     notify_type: 'alert',
      //   })
      // }
      if(flag=='EXT_LOGGED_IN') {
        const data = JSON.parse(action)
        if(this.$store.state.common.voipusers[data.accountcode]) this.$set(this.$store.state.common.voipusers[data.accountcode],['devices',data.extension_accountcode],{
          type: data.type,
          accountname: data.accountname,
        })
      } else if(flag=='EXT_LOGGED_OUT') {
        const data = JSON.parse(action)
        if(this.$store.state.common.voipusers[data.accountcode]) this.$delete(this.$store.state.common.voipusers[data.accountcode],['devices',data.extension_accountcode])
      } else if(flag=='SMS_RECIEVED') {
        const sms = JSON.parse(message)
        this.appNotify({
          title: 'SMS',
          message: `You received a sms from ${sms.from_number} "${sms.message}"`,
          type: "info",
          notify_type: 'alert',
          position: 'top-right',
        })
        this.$root.$emit(events.fetch_call_activity)
      } else if(type=="SIP_BUSY_EVENT") {
        const user_name = this.getVoipUsersAlisesDisplayName[event?.data?.accountcode] ?? ''
        const extension_name = event?.data?.detail?.accountname ?? ''
        vm.appNotify({
          message: `${user_name} reject a call on ${extension_name}`,
          type: "success",
          notify_type: 'alert',
          position: 'top-right',
        })
      } else if(type=="SIP_ANSWER_EVENT") {
        const answer_account_details = event?.data?.answer_account_details
        const answer_accountcode = event?.data?.answer_accountcode
        const detail = event?.data?.detail
        const accountcode = answer_account_details?.user_ext
        const user_name = this.getVoipUsersAlisesDisplayName[accountcode] ?? ''
        const team_name = this.getVoipTeamsAlises[detail?.accountcode]?.name ?? this.getVoipUsersAlisesDisplayName[detail?.accountcode] ?? ''
        const extension_name = answer_account_details?.accountname ?? ''
        if(answer_accountcode!=this.getCurrentUser?.sip?.user) {
          vm.appNotify({
            title: 'Call',
            message: `${team_name} call picked by ${user_name} from ${extension_name}`,
            type: "success",
            notify_type: 'alert',
            position: 'top-right',
          })
        }
      } else if(type=="SIP_MISSCALL_EVENT") {
        const caller = event?.data?.caller
        const user_name = this.getVoipUsersAlisesDisplayName[caller] ?? caller
        vm.appNotify({
          title: 'Call',
          message: `Missed Call by ${user_name}`,
          type: "info",
          notify_type: 'alert',
          position: 'top-right',
        })
      } else if(type=="USER") {
        const accountcode = event?.data?.accountcode
        const name = this.getVoipTeamsAlises[accountcode]?.name ?? this.getVoipUsersAlisesDisplayName[accountcode] ?? ''
        vm.appNotify({
          title: 'Voicemail',
          message: `${name} Received a voicemail`,
          type: "info",
          notify_type: 'alert',
        })
      } else if(action=='EXTENION_LOGOUT'){
        if(flag == user.sip.user){
          setTimeout(() => {
            vm.logout({
              is_user: true
            });
          }, 10 * 1000);
          vm.appNotify({
            title: 'Notification',
            message: "It's seen you forcefully logout this extension",
            type: "danger",
            notify_type: 'alert',
          })
        }
      } else if(type=='SIP_REREGISTRATION'){
        // console.log(user.account,accountcode,user?.sip?.user,webExtension,webExtension==user?.sip?.user,accountcode==user.account)
        if(webExtension==user?.sip?.user && accountcode==user.account){
          this.$store.state.sip.state.sip_register_notification = this.$store.state.sip.state.sip_register_notification+1
          if(!this.sipsessions.length/* && (this.last_register ? Math.floor((new Date().getTime()-this.last_register)/1000/60) >= 3: true)*/){
            this.last_register = new Date().getTime()
            this.$store.state.sip.phone?.registerer?.register?.()
          }
        }
      } else if(flag=='NUMBER_PURCHASE'){
        setTimeout(()=>{
          vm.getNumbers()
          vm.$store.commit('setAddServiceQueue')
        },6 * 1000)
        vm.$store.state.us_number_progress=''
        vm.appNotify({
          title: 'Services',
          message,
          type,
          notify_type: 'alert',
        })
      } else if(flag=='PLAN_SUSPENDED' || flag=='PLAN_TERMINATE'){
        this.$store.state.common.user.suspended=true
        this.getNumbers()
        this.getVoipUsers()
        this.getVoipTeams()
        this.getCallQueue()
        this.getIvrs()
        vm.appNotify({
          title: 'Services',
          message: 'You suspended',
          type: 'danger',
          notify_type: 'alert',
        })
      } else if (flag==true && action=='number_assigned' && section=='number_assigned') {
        vm.$store.state.common.user.is_number_purchased = true;
        vm.appNotify({
          title: 'Services',
          message: 'You number is purchased',
          type: 'success',
          notify_type: 'alert',
        })
      } else if (section=='desktop_notification') {
        // let data = JSON.parse(type)
        // const { event, payload } = data || {}
        // if(event=='DESKTOP_PERMISSION_CHANGE'){
        //   LOGGER.log(payload.permission)
        // } else if(event=='NOTIFICATION_CLICKED'){
        //   LOGGER.log('payload',payload)
        //   const { channel_id } = payload || {}
        //   if(typeof channel_id == 'string'){
        //     LOGGER.log('run1')
        //     vm.$store.state.global_conditions.tab='chat'
        //     setTimeout(()=>{
        //       LOGGER.log('run2')
        //       vm.$root.$emit(events.open_chat,{
        //         channel_id,
        //       })
        //     },1 *1000)
        //   }
        // } else if(event=='DIAL_NUMBER'){
        //   LOGGER.log('payload',payload)
        //   const { number } = payload || {}
        //   if(typeof number == 'string'){
        //     this.$root.$emit(events.audio_call,{
        //       number: number
        //     })
        //   }
        // }
      } else if (action=='logout' && section=='logout') {
        //TODO
      } else if (section != "registration" && section != "unregistration") {
        if (action == "change" && section == "changeplan") {
          vm.$modal.show("ExtraResourcesAlertModal", {
            array: array,
          });
        }
        vm.appNotify({
          title: 'Notification',
          message,
          type,
          notify_type: 'alert',
        })
      } else if (section == "registration" && !!sip && action != sip.uuid && flag == user.sip.user) {
        LOGGER.log('action,sip.uuid,flag,user.sip.user',action,sip.uuid,flag,user.sip.user)
        setTimeout(() => {
          vm.logout({
            is_user: true,
          });
        }, 10 * 1000);
        vm.appNotify({
          title: 'Notification',
          message: "Oh it seems you have logged in from another device.",
          type: "danger",
          notify_type: 'alert',
        })
        vm.appNotify({
          title: 'Notification',
          message: "We will now log you out from this device.",
          type: "danger",
          notify_type: 'alert',
        })
      } else if (section == "unregistration" && action == sip.uuid && flag == user.sip.user) {
        vm.$store.state.sip.phone.userAgent.reconnect();
      }
    },
    reload(){
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.RELOAD)
      } else {
        window.location.reload();
      }
    },
    toast(data={}){
      let vm = this
      const sound = data?.sound ?? false
      const type = ['info','danger','success','warning'].includes(data?.type) ? data?.type : 'info'
      const message = data?.message ?? ''
      
      const duration = data?.duration ?? 3 * 1000
      const position = ['top-right','top-left','top-center','top-full','bottom-right','bottom-left','bottom-center','bottom-full'].includes(data?.position) ? data?.position : 'bottom-right'
      const id = $fn.makeid(30)
      const hide = () => {
        vm.$bvToast.hide(id)
      }
      const h = vm.$createElement
      const vNodesInfo = 
      h('div',{ class: 'i-container' },[
        h('b-icon',{ props: { icon: 'bell-fill', variant: 'primary', fontScale: '2' }, class: '' },[]),
      ])
      const vNodesDanger = 
      h('div',{ class: 'i-container' },[
        h('b-icon',{ props: { icon: 'bell-fill', variant: 'danger', fontScale: '2' }, class: '' },[]),
      ])
      const vNodesSuccess = 
      h('div',{ class: 'i-container' },[
        h('b-icon',{ props: { icon: 'bell-fill', variant: 'success', fontScale: '2' }, class: '' },[]),
      ])
      const vNodesWarning = 
      h('div',{ class: 'i-container' },[
        h('b-icon',{ props: { icon: 'bell-fill', variant: 'warning', fontScale: '2' }, class: '' },[]),
      ])
      const node_type = {
        info: vNodesInfo,
        danger: vNodesDanger,
        success: vNodesSuccess,
        warning: vNodesWarning,
      }
      const vNodesType = node_type[type] ?? vNodesInfo
      const vNodesMsg = 
      h('div',{ class: 'new-error-template-2-1 v2' },[
        h('div',{ class: 'error-message-new' }, [
          h('b-icon',{ props: { icon: "x" }, on: { click: hide }, class: 'cross-icon custom-toaster-close-button-9900' },[]),
          h('div',{ class: 'beta-container  z-index-0-imp' },[
            h('div',{ class: 'beta-text' },[
              h('div',{ class: 'd-flex align-items-center' },[
                vNodesType,
                h('div',{ class: 'pr-3 word-break' },[`${message}`])
              ]),
            ]),
          ]),
        ]),
      ])
      const vNodesTitle = h('div',{style: {'display': 'none'}},[])
      vm.$bvToast.toast([vNodesMsg], {
        id: id,
        title: [vNodesTitle],
        toaster: `b-toaster-${position}`,
        noCloseButton: true,
        solid: false,
        autoHideDelay: duration,
        toastClass: 'custome-toast-class',
        bodyClass: 'custome-toast-body-class',
        headerClass: 'custome-toast-header-class',
      })
      const notify_instance = {
        sound,
        type,
        message,
        id,
        hide,
      }
      return notify_instance
    },
    DIAL_NUMBER(message){
      this.$root.$emit(events.audio_call,message)
    },
    DESKTOP_PERMISSION_CHANGE(){},
    NOTIFICATION_CLICKED(message){
      let vm = this
      const { channel_id } = message || {}
      if(typeof channel_id == 'string'){
        LOGGER.log('run1')
        vm.$store.state.global_conditions.tab='chat'
        setTimeout(()=>{
          LOGGER.log('run2')
          vm.$root.$emit(events.open_chat,{
            channel_id,
          })
        },1 *1000)
      }
    },
    READY(){},
    USER_DATA(){},
    onMicrophoneChange(event){
      this.permissions.microphone=event
    },
    onVideoInputDeviceRemove(device){
      this.appNotify({
        title: 'Media Device',
        message: `Camera Device Remove ${device?.label ?? ''}`,
        type: "danger",
        notify_type: 'alert',
      })
    },
    onAudioInputDeviceRemove(device){
      this.appNotify({
        title: 'Media Device',
        message: `Mic Device Remove ${device?.label ?? ''}`,
        type: "danger",
        notify_type: 'alert',
      })
    },
    onAudioOutputDeviceRemove(device){
      this.appNotify({
        title: 'Media Device',
        message: `Speaker Device Remove ${device?.label ?? ''}`,
        type: "danger",
        notify_type: 'alert',
      })
    },
    checkLogin: _.debounce(function(){
      let vm = this
      VOIP_API.endpoints.users.checkLogin({
        extension_accountcode: TokenService.USER.get()?.sip?.user,
        device_id: vm.fpData.device_id,
      })
      .then(({ data: status })=>{
        if(status=='logout' || status?.status=='logout'){
          vm.logout({is_user: true})
        }
      })
    }, 2 * 1000),
    // onCentrifugoConnected(){
    //   this.checkConnectedCentrifugo()
    //   this.checkLogin()
    // },
    // onCentrifugoDisconnected(){
    //   this.checkConnectedCentrifugo()
    // },
    // checkConnectedCentrifugo(){
    //   const connected_list = [
    //     centrifugo.calls.is_connected,
    //     // centrifugo.notification.is_connected,
    //     // centrifugo.mothership.is_connected,
    //     // centrifugo.notification_admin.is_connected,
    //     // ...this.getCurrentUser.administrator_account ? [
    //     //   centrifugo.mothershipadmin.is_connected,
    //     // ] : []
    //   ]
    //   const not_connected = connected_list.some(i=>!i)
    //   this.conditions.centrifugo_connected = !not_connected
    // },
    // checkPendingServices: _.debounce(async function (){
    //   const { data } = await VOIP_API.endpoints.services.list(this.getCurrentUser.uid)
    //   this.$store.state.common.pending_services=data??[]
    // }, 2*1000),
    fetchCallRecordingSetting: _.debounce(async function (){
      const { data } = await VOIP_API.endpoints.call_recording.getUserCallRecordingSetting(this.getCurrentUser.account)
      const call_recording_setting = data?.[this.getCurrentUser.account] ?? {}
      this.$store.commit('setCallRecordingSetting',call_recording_setting)
    }, 2*1000),
    // onRefreshCentrifugoToken: _.debounce(function(){
    //   CENTRIFUGO_LOGGER.log('Before Refresh Api hit')
    //   VOIP_API.endpoints.centrifuge.gettoken()
    //   .then(({ data: { token, /*default_token*/ } })=>{
    //     // centrifugo.mothership.setToken(token);
    //     // centrifugo.mothershipadmin.setToken(default_token);
    //     // centrifugo.notification_admin.setToken(default_token);
    //     // centrifugo.notification.setToken(token);
    //     centrifugo.calls.setToken(token);
    //   })
    // }, 2 * 1000),
    syncLocalSettingDebounce: _.debounce(function(){
      this.syncLocalSetting()
    }, 10*1000),
    async syncLocalSetting(){
      if(this.api.sync_local_setting.send || !this.isLocalSettingChanged) return;
      try {
        this.api.sync_local_setting.send=true
        this.api.sync_local_setting.status=0
        await VOIP_API.endpoints.local_setting.update({
          accountcode: this.getCurrentUser.account,
          settings: {
            theme: {
              mode: this.GET_LOCAL_SETTING_THEME.mode,
            },
            chat: {
              appearence: {
                is_icon_show: this.GET_LOCAL_SETTING_CHAT.appearence.is_icon_show,
                list_view: this.GET_LOCAL_SETTING_CHAT.appearence.list_view,
                blink_unread: this.GET_LOCAL_SETTING_CHAT.appearence.blink_unread,
              },
              view: {
                recent_type: this.GET_LOCAL_SETTING_CHAT.view.recent_type,
              },
            },
            numbers: {
              formating: {
                international_formate: this.GET_LOCAL_SETTING_NUMBERS.formating.international_formate,
              },
            },
            calls: {
              feedback: this.GET_LOCAL_SETTING_CALLS.feedback,
            },
            dashboard: {
              design: {
                width: this.GET_LOCAL_SETTING_DASHBOARD.design.width,
                flip: this.GET_LOCAL_SETTING_DASHBOARD.design.flip,
                call_info_width: this.GET_LOCAL_SETTING_DASHBOARD.design.call_info_width,
                scroll_top: this.GET_LOCAL_SETTING_DASHBOARD.design.scroll_top,
                tags_show: this.GET_LOCAL_SETTING_DASHBOARD.design.tags_show,
                transfer_log: this.GET_LOCAL_SETTING_DASHBOARD.design.transfer_log,
              },
            },
          }
        })
        this.api.sync_local_setting.status=1
        this.getLocalSetting()
      } catch (ex) {
        this.api.sync_local_setting.status=2
      } finally {
        this.syncLocalSettingDebounce()
        this.api.sync_local_setting.send=false
      }
    },
    async getLocalSetting(){
      if(this.api.get_local_setting.send) return;
      try {
        this.api.get_local_setting.send=true
        const { data } = await VOIP_API.endpoints.local_setting.get({
          accountcode: this.getCurrentUser.account
        })
        const local_storage = JSON.parse(data?.local_storage ?? JSON.stringify({}))
        this.response.local_setting={
          theme: {
            mode: local_storage?.theme?.mode ?? this.GET_LOCAL_SETTING_THEME.mode,
          },
          chat: {
            appearence: {
              is_icon_show: local_storage?.chat?.appearence?.is_icon_show ?? this.GET_LOCAL_SETTING_CHAT.appearence.is_icon_show,
              list_view: local_storage?.chat?.appearence?.list_view ?? this.GET_LOCAL_SETTING_CHAT.appearence.list_view,
              blink_unread: local_storage?.chat?.appearence?.blink_unread ?? this.GET_LOCAL_SETTING_CHAT.appearence.blink_unread,
            },
            view: {
              recent_type: local_storage?.chat?.view?.recent_type ?? this.GET_LOCAL_SETTING_CHAT.view.recent_type,
            },
          },
          numbers: {
            formating: {
              international_formate: local_storage?.numbers?.formating?.international_formate ?? this.GET_LOCAL_SETTING_NUMBERS.formating.international_formate,
            },
          },
          calls: {
            feedback: local_storage?.calls?.feedback ?? this.GET_LOCAL_SETTING_CALLS.feedback,
          },
          dashboard: {
            design: {
              width: local_storage?.dashboard?.design?.width ?? this.GET_LOCAL_SETTING_DASHBOARD.design.width,
              flip: local_storage?.dashboard?.design?.flip ?? this.GET_LOCAL_SETTING_DASHBOARD.design.flip,
              call_info_width: local_storage?.dashboard?.design?.call_info_width ?? this.GET_LOCAL_SETTING_DASHBOARD.design.call_info_width,
              scroll_top: local_storage?.dashboard?.design?.scroll_top ?? this.GET_LOCAL_SETTING_DASHBOARD.design.scroll_top,
              tags_show: local_storage?.dashboard?.design?.tags_show ?? this.GET_LOCAL_SETTING_DASHBOARD.design.tags_show,
              transfer_log: local_storage?.dashboard?.design?.transfer_log ?? this.GET_LOCAL_SETTING_DASHBOARD.design.transfer_log,
            },
          },
        }
        this.SET_LOCAL_SETTING_THEME_MODE(this.response.local_setting.theme.mode)
        this.SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD(this.response.local_setting.chat.appearence.blink_unread)
        this.SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW(this.response.local_setting.chat.appearence.is_icon_show)
        this.SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW(this.response.local_setting.chat.appearence.list_view)
        this.SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE(this.response.local_setting.chat.view.recent_type)
        this.SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE(this.response.local_setting.numbers.formating.international_formate)
        this.SET_LOCAL_SETTING_CALLS_FEEDBACK(this.response.local_setting.calls.feedback)
        this.SET_LOCAL_SETTING_DASHBOARD_DESIGN_CALL_INFO_WIDTH(this.response.local_setting.dashboard.design.call_info_width)
        this.SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP(this.response.local_setting.dashboard.design.flip)
        this.SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP(this.response.local_setting.dashboard.design.scroll_top)
        this.SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW(this.response.local_setting.dashboard.design.tags_show)
        this.SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS(this.response.local_setting.dashboard.design.transfer_log)
        this.SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH(this.response.local_setting.dashboard.design.width)
      } finally {
        this.api.get_local_setting.send=false
      }
    },
    onUpdateLogs(payload){
      const index = this.$store.state.common.call_activity.data.findIndex(call=>call.uniqueid==payload.uniqueid)
      if(index>-1) {
        this.$set(this.$store.state.common.call_activity.data,index,payload)
      } else {
        this.$store.state.common.call_activity.data.unshift(payload)
      }
    },
    onUpdateUserActive(payload){
      const { accountcode, status } = payload
      if(this.$store.state.common.voipusers[accountcode]) {
        this.$set(this.$store.state.common.voipusers[accountcode],'ami_status',status)
      }
    },
    onUpdateIdleState(payload){
      const { accountcode, state, seconds/*, extension_accountcode*/ } = payload
      this.$set(this.$store.state.common.voipusers[accountcode],'idle_state',{
        state: state,
        seconds: seconds,
      })
    },
    onUpdateBLF(payload){
      const { accountcode, blf } = payload
      const voipaccount = this.getVoipUserAccountcode[accountcode]
      if(this.getVoipUsersAlises[voipaccount]){
        this.$set(this.$store.state.common.voipusers[voipaccount],'state',blf || 'Not online')
      }
    },
    AMIReconnect(){
      AMI_SOCKET.connect()
    },
    onAMIConnect(){
      this.conditions.ami_connected=true
      this.checkLogin()
      VOIP_API.endpoints.ami.getBLFStatuses({
        uid: this.getCurrentUser.uid
      })
      .then(({ data })=>{
        const blfs = Object.keys(data.blfs ?? {})
        blfs.forEach(accountcode=>{
          const blf = data.blfs[accountcode].blf
          const voipaccount = this.getVoipUserAccountcode[accountcode]
          if(this.getVoipUsersAlises[voipaccount]){
            this.$set(this.$store.state.common.voipusers[voipaccount],'state',blf || 'Not online')
          }
        })
      })
      VOIP_API.endpoints.ami.getUserActiveState({
        uid: this.getCurrentUser.uid
      })
      .then(({ data })=>{
        const statuses = data.statuses
        statuses.forEach(value=>{
          const { accountcode, status } = value
          const voipaccount = this.getVoipUserAccountcode[accountcode]
          if(this.getVoipUsersAlises[voipaccount]){
            this.$set(this.$store.state.common.voipusers[voipaccount],'ami_status',status)
          }
        })
      })
      VOIP_API.endpoints.ami.getUserLoginDevices({
        uid: this.getCurrentUser.uid
      })
      .then(({ data })=>{
        const devices = data ?? {}
        // console.log('Object.keys(devices)',Object.keys(devices),Object.keys(this.$store.state.common.voipusers))
        Object.keys(devices).forEach(accountcode=>{
          this.$set(this.$store.state.common.voipusers[accountcode],'devices',devices[accountcode])
        })
      })
      VOIP_API.endpoints.ami.getIdleStates({
        uid: this.getCurrentUser.uid
      })
      .then(({ data })=>{
        const idlestates = data?.idlestate ?? {}
        // console.log('Object.keys(idlestates)',Object.keys(idlestates),Object.keys(this.$store.state.common.voipusers))
        Object.keys(idlestates).forEach(accountcode=>{
          this.$set(this.$store.state.common.voipusers[accountcode],'idle_state',idlestates[accountcode])
        })
      })
    },
    onAMIDisconnect(){
      this.conditions.ami_connected=false
    },
  },
  mounted(){
    let vm = this
    // centrifugo
    this.conditions.ami_connected=AMI_SOCKET.connected
    AMI_SOCKET?.on?.('connect',this.onAMIConnect)
    AMI_SOCKET?.on?.('disconnect',this.onAMIDisconnect)
    AMI_SOCKET?.on?.(AMI_EVENTS.update_logs,this.onUpdateLogs)
    AMI_SOCKET?.on?.(AMI_EVENTS.update_user_active,this.onUpdateUserActive)
    AMI_SOCKET?.on?.(AMI_EVENTS.update_idle_state,this.onUpdateIdleState)
    AMI_SOCKET?.on?.(AMI_EVENTS.update_blf,this.onUpdateBLF)
    AMI_SOCKET?.on?.(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.mothership.onrefresh=this.onRefreshCentrifugoToken.bind(this)
    // centrifugo.mothershipadmin.onrefresh=this.onRefreshCentrifugoToken.bind(this)
    // centrifugo.notification_admin.onrefresh=this.onRefreshCentrifugoToken.bind(this)
    // centrifugo.notification.onrefresh=this.onRefreshCentrifugoToken.bind(this)
    // centrifugo.calls.onrefresh=this.onRefreshCentrifugoToken.bind(this)
    // centrifugo.notification.onmessage=this.onNotification.bind(this)
    // centrifugo.notification_admin.onmessage=this.onNotification.bind(this)
    // centrifugo.mothership.onmessage=this.onNotification.bind(this)
    // centrifugo.mothershipadmin.onmessage=this.onNotification.bind(this)
    // centrifugo.mothership.onconnect=this.onCentrifugoConnected.bind(this,'mothership')
    // centrifugo.mothershipadmin.onconnect=this.onCentrifugoConnected.bind(this,'mothershipadmin')
    // centrifugo.notification_admin.onconnect=this.onCentrifugoConnected.bind(this,'notification_admin')
    // centrifugo.notification.onconnect=this.onCentrifugoConnected.bind(this,'notification')
    // centrifugo.calls.onconnect=this.onCentrifugoConnected.bind(this,'calls')
    // centrifugo.mothership.ondisconnect=this.onCentrifugoDisconnected.bind(this,'mothership')
    // centrifugo.mothershipadmin.ondisconnect=this.onCentrifugoDisconnected.bind(this,'mothershipadmin')
    // centrifugo.notification_admin.ondisconnect=this.onCentrifugoDisconnected.bind(this,'notification_admin')
    // centrifugo.notification.ondisconnect=this.onCentrifugoDisconnected.bind(this,'notification')
    // centrifugo.calls.ondisconnect=this.onCentrifugoDisconnected.bind(this,'calls')
    // permissions
    this.permissions.microphone=permissions.mic
    permissions.onmic1=this.onMicrophoneChange
    // media device settings
    this.$store.state.media_devices_setting[MEDIA_DEVICES_SETTING.makeOnFunctionText(MEDIA_DEVICES_SETTING.events.video_input_device_remove)] = this.onVideoInputDeviceRemove.bind(this)
    this.$store.state.media_devices_setting[MEDIA_DEVICES_SETTING.makeOnFunctionText(MEDIA_DEVICES_SETTING.events.audio_input_device_remove)] = this.onAudioInputDeviceRemove.bind(this)
    this.$store.state.media_devices_setting[MEDIA_DEVICES_SETTING.makeOnFunctionText(MEDIA_DEVICES_SETTING.events.audio_output_device_remove)] = this.onAudioOutputDeviceRemove.bind(this)
    // mothership
    MOTHERSHIP?.on?.(MOTHERSHIP.events.receive.DIAL_NUMBER,this.DIAL_NUMBER)
    MOTHERSHIP?.on?.(MOTHERSHIP.events.receive.DESKTOP_PERMISSION_CHANGE,this.DESKTOP_PERMISSION_CHANGE)
    MOTHERSHIP?.on?.(MOTHERSHIP.events.receive.NOTIFICATION_CLICKED,this.NOTIFICATION_CLICKED)
    MOTHERSHIP?.on?.(MOTHERSHIP.events.receive.READY,this.READY)
    MOTHERSHIP?.on?.(MOTHERSHIP.events.receive.USER_DATA,this.USER_DATA)
    // integrations
    INTEGRATIONS?.on?.(INTEGRATIONS.events.dial_number,this.DIAL_NUMBER)
    // root
    // this.$root.$on(events.check_pending_services,this.checkPendingServices)
    this.$root.$on(events.fetch_call_recording_setting,this.fetchCallRecordingSetting)
    // others
    this.$store.state.sip.state.sip_register_notification=0
    this.conditions.trail_toaster=!!this.$store.getters.isTrailPlan
    this.conditions.grace_period=!!this.$store.getters.isGracePeriod
    this.mediaDevicesSettingSink()
    setTimeout(()=>{
      vm.conditions.can_show_toaster=true
    },30*1000)
    // this.checkPendingServices()
    this.fetchCallRecordingSetting()
    this.getLocalSetting()
  },
  beforeDestroy() {
    AMI_SOCKET?.off?.('connect',this.onAMIConnect)
    AMI_SOCKET?.off?.('disconnect',this.onAMIDisconnect)
    AMI_SOCKET?.off?.(AMI_EVENTS.update_logs,this.onUpdateLogs)
    AMI_SOCKET?.off?.(AMI_EVENTS.update_user_active,this.onUpdateUserActive)
    AMI_SOCKET?.off?.(AMI_EVENTS.update_user_active,this.onUpdateIdleState)
    AMI_SOCKET?.off?.(AMI_EVENTS.update_blf,this.onUpdateBLF)
    AMI_SOCKET?.off?.(AMI_EVENTS.broadcast,this.onNotification)
    MOTHERSHIP?.off?.(MOTHERSHIP.events.receive.DIAL_NUMBER,this.DIAL_NUMBER)
    INTEGRATIONS?.off?.(INTEGRATIONS.events.dial_number,this.DIAL_NUMBER)
    MOTHERSHIP?.off?.(MOTHERSHIP.events.receive.DESKTOP_PERMISSION_CHANGE,this.DESKTOP_PERMISSION_CHANGE)
    MOTHERSHIP?.off?.(MOTHERSHIP.events.receive.NOTIFICATION_CLICKED,this.NOTIFICATION_CLICKED)
    MOTHERSHIP?.off?.(MOTHERSHIP.events.receive.READY,this.READY)
    MOTHERSHIP?.off?.(MOTHERSHIP.events.receive.USER_DATA,this.USER_DATA)
    // this.$root.$off(events.check_pending_services,this.checkPendingServices)
    this.$root.$off(events.fetch_call_recording_setting,this.fetchCallRecordingSetting)
  },
};
</script>

<style>
.custome-toast-class {
  background-color: transparent !important;
  border: none !important;
  box-shadow: none !important;
}
.custome-toast-header-class {
  padding: 0;
}
.custome-toast-body-class {
  padding: 0 !important;
}
.custome-own-toast-div {
  display: block;
  background-color: white;
  -ms-box-shadow: 0 0px 12px 0px rgba(0, 0, 0, 0.16);
  -o-box-shadow: 0 0px 12px 0px rgba(0, 0, 0, 0.16);
  box-shadow: 0 0px 12px 0px rgb(0 0 0 / 16%);
  border-radius: 16px;
  /* position: absolute; */
  /* z-index: 998; */
  /* right: 20px; */
  /* top: 30px; */
  width: auto;
  min-width: 350px;
  padding: 14px 16px;
}
.custome-error-message-new {
  display: flex;
  justify-content: space-between;
}
</style>