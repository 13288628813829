<template>
  <modal 
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"   
    :name="modalName" 
    @before-close="onBeforeClose()" 
    @before-open="onBeforeOpen($event)"
  >
    <div v-if="conditions.screen==1" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="api.create.send ? '' : conditions.screen=0">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Add Tag</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div v-else-if="conditions.screen==2" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="api.delete.send || api.update.send ? '' : conditions.screen=0;selected.tag_id=''">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Edit Tags</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="api.delete.send ? '' : $modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20"  />
      </div>
      <div class="dialer-settings-title topMainHeading">Tags</div>
      <div class="plus-IOSIcon-container" @click="conditions.screen=1">
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon"  width="20" height="19.992"/>
      </div>
    </div>

    <div class="innerModalIos">
      
      <template v-if="conditions.screen==1">
        <div class="">
          <b-form @submit.prevent="create()" class="dialer-form">
            <div class="basicWhiteIOScard mb-16px mt-32px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Tag Name</label>
                  <b-input type="text" v-model="forms.create.tag" :disabled="api.create.send" placeholder="Enter name" />
                  <template v-if="forms.create.submitted && $v.forms.create.tag.$invalid">
                    <p v-if="!$v.forms.create.tag.required" class="mb-1 text-small text text-danger animated bounceIntop">
                      <span>* Name is required</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <vb-color-picker 
                  class="color-picker-input mobileIos" 
                  title="Color" 
                  :disabled="api.create.send"
                  :value="forms.create.color" 
                  @change="forms.create.color = $event"
                >
                  <template slot="error" v-if="forms.create.submitted && $v.forms.create.color.$invalid">
                    <p v-if="!$v.forms.create.color.required" class="mb-1 text-small text text-danger animated bounceIntop">
                      <span>* Color is required</span>
                    </p>
                  </template>
                </vb-color-picker>
              </div>
              <div class="basicWhiteIOScard-item">
                <vb-color-picker 
                  class="color-picker-input mobileIos" 
                  title="Text Color" 
                  :disabled="api.create.send"
                  :value="forms.create.text_color" 
                  @change="forms.create.text_color = $event"
                >
                  <template slot="error" v-if="forms.create.submitted && $v.forms.create.text_color.$invalid">
                    <p v-if="!$v.forms.create.text_color.required" class="mb-1 text-small text text-danger animated bounceIntop">
                      <span>* Text Color is required</span>
                    </p>
                  </template>
                </vb-color-picker>
              </div>
            </div>
            <button class="IosFullWidthButton forDone mt-16px mb-16px" variant="success" :disabled="api.create.send">
              <vb-spinner v-if="api.create.send" />
              <template v-else>Add</template>
            </button>
          </b-form>
        </div>
      </template>

      <template v-else-if="conditions.screen==2">

        <div class="basicWhiteIOScard mb-16px mt-32px">
          
          
          <button class="IosFullWidthButton forRemove mb-16px" @click="api.delete.send || api.update.send ? '' : remove()">
            <template>Delete</template>
          </button>

        </div>
      </template>

      <template v-else>
        
       

        <div class="basicWhiteIOScard mt-32px mb-16px">
          
          <div v-for="tag in getVoipCallTags" :key="tag.id" class="basicWhiteIOScard-item d-flex justify-content-between" @click="setUpdateId(tag)">
            <div class="IosTags headingInsideTable" v-vb-tag-color.text.bg="{color:tag.colour,text_color:tag.text_color}">{{tag.tag}}</div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
          <vb-no-record v-if="!getVoipCallTags.length" :text="api.tags.send?'':'There is no Tags'" :design="3">
            <vb-loading v-if="api.tags.send" class="text-center w-100" slot="loading" />
          </vb-no-record>

        </div>

      </template>

    </div>

  </modal>
</template>

<script>
import { VOIP_API } from "../../../utils";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';

export default {
  name: "TagsModal",
  inject: ['getCallTags','isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'TagsModal',
    },
  },
  data() {
    return {
      conditions: {
        screen: 0,
      },
      selected: {
        tag_id: '',
      },
      api: {
        create: this.$helperFunction.apiInstance({  }),
        update: this.$helperFunction.apiInstance({  }),
        delete: this.$helperFunction.apiInstance({  }),
        tags: this.$helperFunction.apiInstance({  }),
      },
      forms: {
        create: this.$helperFunction.formInstance({ 
          data: {
            tag: "",
            color: "",
            text_color: "",
          },
        }),
        update: this.$helperFunction.formInstance({ 
          data: {
            tag: "",
            color: "",
            text_color: "",
          },
        })
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVoipCallTags',
      'getCurrentUser',
    ]),
  },
  validations: {
    forms: {
      create: {
        tag: {
          required,
        },
        color: {
          required,
        },
        text_color: {
          required,
        },
      },
      update: {
        tag: {
          required,
        },
        color: {
          required,
        },
        text_color: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen() {
      this.fetchTags();
    },
    onBeforeClose() {
      this.selected.tag_id = "";
      this.forms.create.reset();
      this.forms.update.reset();
      this.api.create.reset()
      this.api.update.reset()
      this.api.delete.reset()
      this.api.tags.reset()
    },
    fetchTags() {
      let vm = this;
      if (vm.api.tags.send) return;
      vm.api.tags.send = true;
      vm.getCallTags()
      .finally(() => {
        vm.api.tags.send = false;
      });
    },
    create() {
      let vm = this;
      vm.forms.create.submitted = true;
      vm.$v.forms.create.$touch();
      if (vm.$v.forms.create.$invalid || vm.api.create.send) return;
      vm.api.create.send = true;
      VOIP_API.endpoints.tags.create({
        tag: vm.forms.create.tag,
        color: vm.forms.create.color,
        colour: vm.forms.create.color,
        text_color: vm.forms.create.text_color,
        uid: vm.getCurrentUser?.uid,
        accountcode: vm.getCurrentUser?.account,
      })
      .then(() => {
        vm.fetchTags()
        vm.forms.create.reset();
        vm.conditions.screen=0
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.forms.create.submitted = false;
        vm.api.create.send = false;
      });
    },
    remove() {
      let vm = this;
      if (vm.api.delete.send) return;
      this.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to delete.`,
        variant: 'danger',
        button: {
          no: "no",
          yes: "yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete.send = true;
            VOIP_API.endpoints.tags.delete(vm.selected.tag_id)
            .then(() => {
              vm.appNotify({
                message: 'Successfully Deleted',
                type: 'success',
              })
              vm.fetchTags()
              vm.selected.tag_id=''
              vm.conditions.screen=0
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message || ex.message,
                type: 'danger',
              })
            })
            .finally(() => {
              vm.api.delete.send = false;
            });
          }
        },
      });
    },
    update() {
      let vm = this;
      vm.forms.update.submitted = true;
      vm.$v.forms.update.$touch();
      if (vm.$v.forms.update.$invalid || vm.api.update.send) return;
      vm.api.update.send = true;
      VOIP_API.endpoints.tags.update({
        id: vm.selected.tag_id,
        tag: vm.forms.update.tag,
        color: vm.forms.update.color,
        colour: vm.forms.update.color,
        text_color: vm.forms.update.text_color,
        allow_call: vm.forms.update.allow_call,
      }).then(() => {
        vm.fetchTags()
        vm.conditions.screen=0
        vm.selected.tag_id = "";
        vm.forms.update.reset();
      }).finally(() => {
        vm.forms.update.submitted = false;
        vm.api.update.send = false;
      });
    },
    setUpdateId(data) {
      this.selected.tag_id = data.id;
      this.forms.update.tag = data.tag;
      this.forms.update.color = data.colour;
      this.forms.update.text_color = data.colour;
      this.conditions.screen=2
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.dialer-table {
  .dialer-col-center {
    span {
      .custom-control {
        padding-left: 0px;
      }
    }
  }
}
</style>
