var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 CallQueueSoundFilesModal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to dialer-edit"
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center mb-2"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v(" Sound files ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }), _c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": !_vm.selectedFile
    },
    on: {
      "click": function ($event) {
        return _vm.onSelect();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0 d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newBackButton mr-16px",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Sound files ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": !_vm.selectedFile
    },
    on: {
      "click": function ($event) {
        return _vm.onSelect();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_vm.api.files.send ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox withOutPadding"
  }, _vm._l(7, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row py-3 flex-column align-items-start"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "27px",
        "width": "50%"
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center w-100 mt-12px"
    }, [_c('div', {
      staticClass: "latestShimmerDesign mr-4",
      staticStyle: {
        "height": "24px",
        "width": "24px",
        "min-height": "24px",
        "min-width": "24px"
      }
    }), _c('div', {
      staticClass: "latestShimmerDesign flex-fill",
      staticStyle: {
        "height": "64px",
        "width": "100%"
      }
    })])]);
  }), 0)] : _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox withOutPadding"
  }, [_vm._l(_vm.filterSoundFiles, function (file) {
    return _c('div', {
      key: file.id,
      class: `whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start p-3 ${_vm.selected.file == file.id ? 'active' : ''}`
    }, [_c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(file.label))]), _c('div', {
      staticClass: "w-100 d-flex justify-content-between align-items-center mt-12px"
    }, [_c('div', {
      staticClass: "rememberMeCheckbox-container"
    }, [_c('b-form-checkbox', {
      attrs: {
        "checked": _vm.selected.file == file.id
      },
      on: {
        "change": function ($event) {
          _vm.selected.file = file.id;
        }
      }
    })], 1), _c('div', {
      staticClass: "flex-fill"
    }, [_c('vb-audio-player', {
      staticClass: "whiteBGaudioWithGreyRoundedBorder-again w-100",
      attrs: {
        "src": file.sound_file
      }
    })], 1)])]);
  }), _vm.isEmpty(_vm.filterSoundFiles) ? _c('vb-no-record', {
    staticClass: "m-5",
    attrs: {
      "text": _vm.api.files.send ? '' : 'Sorry, No data founding list',
      "design": 3
    }
  }) : _vm._e()], 2)], 2)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }