var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup EditTimeOffRequestModal HrmForModalScroll",
    attrs: {
      "name": "EditTimeOffRequestModal",
      "clickToClose": true,
      "scrollable": true
    }
  }, [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Generate time off reports")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('EditTimeOffRequestModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Time off policy")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1), _c('div', {
    staticClass: "daysLeftText"
  }, [_vm._v("6 days left")]), _c('div', {
    staticClass: "hrmTabsContainer mt-32px"
  }, [_c('div', {
    class: `hrmTabsButton mr-16px ${_vm.showTabs == 'single' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'single';
      }
    }
  }, [_vm._v("Single day")]), _c('div', {
    class: `hrmTabsButton ml-16px ${_vm.showTabs == 'multi' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'multi';
      }
    }
  }, [_vm._v("Multi - date")])]), _vm.showTabs == 'single' ? [_c('div', {
    staticClass: "d-flex mt-32px"
  }, [_c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("Date")]), _c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('input', {
    staticClass: "withIconOnRight primaryBorder noInnerBoxShadow"
  }), _c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  })], 1)])]), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Textarea")]), _c('textarea')])] : _vm._e(), _vm.showTabs == 'multi' ? [_c('div', {
    staticClass: "d-flex mt-32px"
  }, [_c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("From")]), _c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('input', {
    staticClass: "withIconOnRight primaryBorder noInnerBoxShadow"
  }), _c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  })], 1)]), _c('div', {
    staticClass: "innerShadowInput-container halfWidth"
  }, [_c('label', [_vm._v("To")]), _c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('input', {
    staticClass: "withIconOnRight primaryBorder noInnerBoxShadow"
  }), _c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  })], 1)])]), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Textarea")]), _c('textarea')])] : _vm._e(), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-32px"
  }, [_vm._v(" Notify employee by email ")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Update ")])], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }