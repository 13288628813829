<template>
  <div>
    <div v-if="!isGlobal && !team" class="whiteBoxWithBorderInsideGreyBox-row">
      <div>
        <div class="latestGreyBox-heading-main">Voicemail greetings</div>
        <div class="latestGreyBox-descriptionText">Your custom voicemail messages – enable or disable the switch for basic or advanced voicemail greetings.</div>
      </div>
      <div>
        <div v-if="api.change_greeting_type.send || api.fetch_voicemails.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
        <div v-else class="w-auto" >
          <div
            v-b-tooltip :title="getUserPermissions.advanced_greetings_in_voice_mail?'':'Your package does not support this feature'"
          >
            <b-form-checkbox 
              v-if="!team"
              :disabled="!getUserPermissions.advanced_greetings_in_voice_mail"
              class="newerSwitch" 
              switch
              @change="changeGreetingType()"
              :checked="selected.greeting_type"
              offText="Basic"
              onText="Advanced"
              sync
            />
          </div>
        </div>
      </div>
    </div>
    <div class="whiteBoxWithBorderInsideGreyBox-row flex-column justify-content-center align-items-start">
      <div v-if="!isGlobal" class="latestGreyBox-heading-main">Voicemail greetings message</div>
      <div v-if="!isGlobal" class="latestGreyBox-descriptionText">Create your custom voicemail messages for basic or advanced needs.</div>
      <div :class="`VoicemailGreetingsNewDesign-container ${!isGlobal ? 'mt-20px' : ''}`">
        <div v-for="data in voicemails" :key="data.value">
          <div class="VoicemailGreetingsNewDesign-item">
            <div :class="`VoicemailGreetingsNewDesign-item-text ${voicemailValues[data.value] && voicemailValues[data.value].file_type!='system' ? 'itsCustom' : ''}`">
              <span class="VoicemailGreetingsNewDesign-item-text-itself">{{ data.text2 }}</span>
              <span v-if="voicemailValues[data.value] && voicemailValues[data.value].file_type!='system'" class="newBlueColorTagLike">Custom</span>
            </div>
            <div class="VoicemailGreetingsNewDesign-item-rightSide">
              <vb-audio-player :design="`${$store.getters.getIsMobile ? 'minimum' : ''}`"
                v-if="!api.fetch_voicemails.send" 
                class="whiteBGaudioWithGreyRoundedBorder-again" 
                :disabled="api.change_greeting_type.send" 
                :src="voicemailValues | get_property(`${data.value}.url`)" 
              />
              <div class="VoicemailGreetingsNewDesign-item-buttonSection">
                <vb-icon 
                  class="cursor_pointer ml-16px latestPencilIcon" 
                  icon="latestPencilIcon"
                  height="38px" width="38px" 
                  @click="(data.advanced && !getUserPermissions.advanced_greetings_in_voice_mail) || api.upload_audio.send ? '' : conditions.collapse=conditions.collapse==data.value?'':data.value"
                  v-b-tooltip :title="!data.advanced || getUserPermissions.advanced_greetings_in_voice_mail?'':'Your package does not support this feature'"
                />
                <vb-icon 
                  v-if="voicemailValues[data.value] && voicemailValues[data.value].file_type!='system'"
                  class="cursor_pointer ml-16px" 
                  height="22px" 
                  width="22px" 
                  icon="trash-IOSIcon" 
                  :disabled="data.advanced && !getUserPermissions.advanced_greetings_in_voice_mail" 
                  @click="deleteRecordVoiceMail(data)"
                  v-b-tooltip :title="!data.advanced || getUserPermissions.advanced_greetings_in_voice_mail?'':'Your package does not support this feature'"
                />
              </div>
            </div>
          </div>
          <div class="mt-12px max-width-600px" v-if="conditions.collapse==data.value">
            <AudioFileInput 
              :disabled="api.upload_audio.send==data.value"
              :show_Text="false"
              :allow_sound_library="true"
              @change="uploadAudio({
                sound_type: data.value,
                file: $event.file,
                type: $event.type,
                complete: $event.complete,
                reset: $event.reset,
              })" 
            />
          </div>
        </div>
      </div>
    </div>
    <div>
      <modal 
        class="RecordingModalLikeSomething"
        :name="`${_uid}-EditVoicemailGreetingModal`"
        @before-open="selected.sound_type=$event.params.sound_type"
        @before-close="selected.sound_type=''"
      >
        <div class="dialer-edit">
          <div class="dialer-edit-header pt-0">
            <h2 class="dialer-settings-title newer mb-0">Update</h2>
            <div class="dialer-edit-actions">
              <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(`${_uid}-EditVoicemailGreetingModal`)" />
              <a id="CloseModalButton" v-else class="newCloseButton" @click="$modal.hide(`${_uid}-EditVoicemailGreetingModal`)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <AudioFileInput 
              :disabled="api.upload_audio.send==selected.sound_type"
              :show_Text="false"
              @change="uploadAudio({
                sound_type: selected.sound_type,
                file: $event.file,
                type: $event.type,
                complete: $event.complete,
                reset: $event.reset,
              })" 
            />
          </div>
        </div>
      </modal>
    </div>
  </div>
</template>

<script>
import { $fn, VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import _ from 'lodash';
import AudioFileInput from '../widgets/AudioFileInput.vue';
const voicemail_type = {
  temp: {
    text: 'Greeting message',
    // text2: 'will play greeting message',
    text2: 'Standard greeting',
    value: 'temp',
    type: 'temporary_file_mp3',
    advanced: false,
    record_text: 'Record a new greeting message',
    upload_text: 'Upload a Greeting Message',
    select_text: 'Select file from sound library',
    delete_text: 'Delete Sound',
  },
  greet: {
    text: 'Phone is offline',
    // text2: 'will play if phone is offline',
    text2: 'If phone is offline',
    value: 'greet',
    type: 'greeting_file_mp3',
    advanced: true,
    record_text: 'Record a new greeting message',
    upload_text: 'Upload a greeting message',
    select_text: 'Select file from sound library',
    delete_text: 'Delete sound',
  },
  unavail: {
    text: 'Unavailable to take calls',
    // text2: 'will play if  you are unavailable to take calls',
    text2: 'If you are unavailable to take calls',
    value: 'unavail',
    type: 'unavailable_file_mp3',
    advanced: true,
    record_text: 'Record a new Greeting Message',
    upload_text: 'Upload a Greeting Message',
    select_text: 'Select file from sound library',
    delete_text: 'Delete Sound',
  },
  busy: {
    text: 'Busy on the phone',
    // text2: 'will play if you are busy on a call',
    text2: 'If you are busy on a call',
    value: 'busy',
    type: 'busy_file_mp3',
    advanced: true,
    record_text: 'Record a new Greeting Message',
    upload_text: 'Upload a Greeting Message',
    select_text: 'Select file from sound library',
    delete_text: 'Delete Sound',
  },
}
export default {
  name: "UserRecordedVoicemail",
  components: {
    AudioFileInput,
  },
  props: {
    accountcode: {
      type: String,
      default: '',
    },
    newDesign: {
      type: Boolean,
      default: false,
    }
  },
  data() {
    return {
      api: {
        delete_voicemail: this.$helperFunction.apiInstance({ 
          send: [] 
        }),
        change_greeting_type: this.$helperFunction.apiInstance(),
        fetch_voicemails: this.$helperFunction.apiInstance(),
        upload_audio: this.$helperFunction.apiInstance({
          send: '',
        }),
      },
      selected: {
        greeting_type: false,
        sound_type: '',
      },
      conditions: {
        collapse: '',
      },
      response: {
        voicemails: {},
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getVoipUsersAlises',
      'getVoipTeamsAlises',
      'getCurrentUser',
      'getIsMobile',
    ]),
    user(){ return this.getVoipUsersAlises[this.accountcode] ?? this.getVoipUsersAlises[this.getCurrentUser.account] },
    team(){ return this.getVoipTeamsAlises[this.accountcode] },
    voipaccountcode(){ return this.accountcode || this.getCurrentUser.account },
    isGlobal(){ return this.accountcode==this.getCurrentUser.default_accountcode },
    voicemails(){ return Object.values(voicemail_type).filter(i=>this.isGlobal ? this.getUserPermissions.advanced_greetings_in_voice_mail ? true : !i.advanced : this.selected.greeting_type ? i.advanced : !i.advanced) 
    },
    voicemailValues(){
      return {
        busy: this.response.voicemails?.prompts?.find(i=>i.type==voicemail_type.busy.type),
        greet: this.response.voicemails?.prompts?.find(i=>i.type==voicemail_type.greet.type),
        temp: this.response.voicemails?.prompts?.find(i=>i.type==voicemail_type.temp.type),
        unavail: this.response.voicemails?.prompts?.find(i=>i.type==voicemail_type.unavail.type),
      }
    },
  },
  methods: {
    getVoicemailsDebounce: _.debounce(function(){
      this.getVoicemails()
    }, 2*1000),
    async getVoicemails() {
      try {
        this.api.fetch_voicemails.send = true;
        const { data: res } = await VOIP_API.endpoints.voicemail.voicemailgreeting({
          accountcode: this.voipaccountcode,
          type: 'busy',
        })
        this.response.voicemails = res;
        this.selected.greeting_type = res.greetings_type=='advance'
      } finally {
        this.api.fetch_voicemails.send = false;
      }
    },
    async changeGreetingType() {
      if (this.api.change_greeting_type.send) return;
      try {
        this.api.change_greeting_type.send = true;
        await VOIP_API.endpoints.voicemail.chageGreetingType({
          account: this.voipaccountcode,
          accountcode: this.voipaccountcode,
          type: !this.selected.greeting_type ? 'advanced' : 'basic',
        })
        this.getVoicemails()
        this.selected.greeting_type=!this.selected.greeting_type
        this.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.change_greeting_type.send = false;
      }
    },
    async deleteRecordVoiceMail(data) {
      const type = data.type
      const value = data.value
      if (this.api.delete_voicemail.send.includes(value)) return;
      try {
        this.api.delete_voicemail.send.push(value);
        await VOIP_API.endpoints.voicemail.deleteRecordVoiceMail(value, {
          account: this.voipaccountcode,
          type: type
        })
        this.appNotify({
          message: 'Successfully Deleted',
          type: 'success',
        })
        this.getVoicemailsDebounce();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if (this.api.delete_voicemail.send.includes(value)) {
          this.api.delete_voicemail.send.splice(this.api.delete_voicemail.send.indexOf(value),1);
        }
      }
    },
    async uploadAudio(event){
      if(this.api.upload_audio.send) return;
      try {
        this.api.upload_audio.send=event.sound_type
        let file = null
        if(event.type=='library') {
          file=event.file.id
        } else {
          const base64 = await $fn.blobToBase64(event.file)
          file = base64
        }
        await VOIP_API.endpoints.voicemail.voicemailrecord({
          account: this.voipaccountcode,
          file: file,
          type: event.sound_type,
          flag: event.type=='recorded'?'recording':event.type,
        })
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        this.getVoicemailsDebounce()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.upload_audio.send=''
        event.reset()
      }
    },
  },
  mounted() {
    this.getVoicemails();
  },
  activated(){
    this.getVoicemails();
  },
};
</script>

<style scoped>
.vrshowloading {
  display: block;
}

.vrhideloading {
  display: none;
}

.v-switch-core {
  width: 50px !important;
}
</style>
