<template>
  <modal :name="modalName" class="leaveFeedmackModal PerformanceSetting" :scrollable="false" @before-open="onBeforeOpen" @before-close="onBeforeClose" >
   
    <div class="dialer-assign-to dialer-edit w-100">
      <div class="dialer-edit-header d-flex aling-items-center justify-content-between w-100">
        <h2 class="dialer-edit-title mb-0">Performance Setting</h2>
        <div class="dialer-edit-actions ">
          <a class="newCloseButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <b-form @submit.prevent="''" class=" whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
              <label>Set performance settings</label>
              <b-form-select class="" :value="forms.performance.value" @change="changePerformance($event)">
                <b-form-select-option v-for="performanceSetting in performanceSettings" :key="performanceSetting.value" :value="performanceSetting.value">
                  {{performanceSetting.value}}
                </b-form-select-option>
              </b-form-select> 
            </b-form>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'PerformanceSetting',
  props: {
    modalName: {
      type: String,
      default: 'PerformanceSettingModal'
    },
    performanceSettings: {
      type: Object,
      default: ()=>({})
    },
  },
  data() {
    return {
      forms: {
        performance: {
          value: '',
        },
      },
    };
  },
  methods: {
    onBeforeOpen(event) {
      this.forms.performance.value = event?.params?.value || ''
    },
    onBeforeClose() {
      this.forms.performance.value=''
    },
    changePerformance(value){
      this.forms.performance.value=value
      this.$emit('update',value)
    },
  },
}
</script>

<style>

</style>