
import { VOIP_API } from "../index"

export const CALL_QUEUES_API_ENDPOINTS = {
  toggleFavourite(data={}){
    return VOIP_API.axios.voip.request({
      url: `toggle/callqueue/favourite`,
      method: 'POST',
      data,
    })
  },
  create(data={}){
    return VOIP_API.axios.voip.request({
      url: `billingcons`,
      method: 'POST',
      data,
    })
  },
  list(params={}){
    return VOIP_API.axios.voip.request({
      url: "callqueues",
      method: "GET",
      params,
    })
  },
  members(accountcode='',params={}){
    return VOIP_API.axios.voip.request({
      url: `callqueues/queue-members/${accountcode}`,
      method: "GET",
      params,
    })
  },
  updatemembers(accountcode='',data={}){
    return VOIP_API.axios.voip.request({
      url: `callqueues/queue-members/${accountcode}`,
      method: "PATCH",
      data,
    })
  },
  detail(accountcode='',params={}){
    return VOIP_API.axios.voip.request({
      url: `callqueues/${accountcode}`,
      method: "GET",
      params,
    })
  },
  updateMainOptions(accountcode='',data={},CancelToken=''){
    return VOIP_API.axios.voip.request({
      url: `callqueues/mainoption/${accountcode}`,
      method: "PATCH",
      data,
      CancelToken,
    })
  },
  updateAdvanceOptions(accountcode='',data={},CancelToken=''){
    return VOIP_API.axios.voip.request({
      url: `callqueues/advanceoption/${accountcode}`,
      method: "PATCH",
      data,
      CancelToken,
    })
  },
  updateAgentOptions(accountcode='',data={},CancelToken=''){
    return VOIP_API.axios.voip.request({
      url: `callqueues/agentoption/${accountcode}`,
      method: "PATCH",
      data,
      CancelToken,
    })
  },
  updateAnnounceOptions(accountcode='',data={},CancelToken=''){
    return VOIP_API.axios.voip.request({
      url: `callqueues/announceoption/${accountcode}`,
      method: "PATCH",
      data,
      CancelToken,
    })
  },
  addMembers(accountcode='',data={}){
    return VOIP_API.axios.voip.request({
      url: `callqueues/add-member/${accountcode}`,
      method: "PATCH",
      data,
    })
  },
  removeMembers(accountcode='',data={}){
    return VOIP_API.axios.voip.request({
      url: `callqueues/remove-member/${accountcode}`,
      method: "PATCH",
      data,
    })
  },
  memberForceAdmin(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `callqueues/membersetting/${id}`,
      method: "PATCH",
      data,
    })
  },
  memberSetting(accountcode='',data={}){
    return VOIP_API.axios.voip.request({
      url: `callqueues/membersetting/${accountcode}`,
      method: "GET",
      data,
    })
  },
  getstatus(accountcode=''){
    return VOIP_API.axios.voip.request({
      url: `get-queueu-agent-status/${accountcode}`,
      method: 'GET',
    })
  },
  getstatuses(){
    return VOIP_API.axios.voip.request({
      url: `call-queues-status`,
      method: 'GET',
    })
  },
  togglelogin(accountcode='',data={}){
    return VOIP_API.axios.voip.request({
      url: `toggle-agent-login-queue/${accountcode}`,
      method: 'POST',
      data,
    })
  },
  togglepaused(accountcode='',data={}){
    return VOIP_API.axios.voip.request({
      url: `toggle-queue-memeber-pause-status/${accountcode}`,
      method: 'POST',
      data,
    })
  },
}