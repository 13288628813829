var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Employees who chose to hide their full birthdate details will not appear in the report ")]), _c('div', {
    staticClass: "dropdownsContainer"
  }, [_c('b-dropdown', {
    staticClass: "primaryBorderDropdown w-300px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("Birthday & anniversaries")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Birthday & anniversaries ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown w-220px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("Next year")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Next year ")])], 1)], 1), _vm._l(_vm.filterAnniversaries, function (anniversary) {
    return _c('div', {
      key: anniversary.id
    }, [_c('div', {
      staticClass: "mainHeading withButton mt-32px"
    }, [_vm._v(_vm._s(anniversary.title))]), _c('div', {
      staticClass: "BirthdaysAnniversariesTable mt-16px"
    }, [_vm._m(1, true), _c('vb-table', {
      staticClass: "HrmTable",
      attrs: {
        "isListEmpty": _vm.isEmpty(anniversary.data),
        "listLength": anniversary.data.length,
        "loading": _vm.api.anniversaries.send
      },
      scopedSlots: _vm._u([{
        key: "body",
        fn: function (_ref) {
          var start = _ref.start,
            end = _ref.end;
          return _vm._l(anniversary.data, function (data, index) {
            return _c('tr', {
              directives: [{
                name: "show",
                rawName: "v-show",
                value: index >= start && index <= end,
                expression: "index >= start && index <= end"
              }],
              key: data.user,
              staticClass: "dialer-row-select"
            }, [_c('td', {
              staticClass: "dialer-row-title one"
            }, [_c('div', {
              staticClass: "nameWithImage"
            }, [_c('div', {
              staticClass: "imageContainer"
            }, [_c('img', {
              attrs: {
                "src": require('@/assets/images/credit-card/1.jpeg')
              }
            })]), _c('div', {
              staticClass: "nameWithImage-text"
            }, [_vm._v(_vm._s(data.employee))])])]), _c('td', {
              staticClass: "dialer-col-right two"
            }, [_c('div', {
              staticClass: "circleWithText"
            }, [_c('div', {
              staticClass: "circleContainer",
              staticStyle: {
                "background-color": "#D5761B"
              }
            }), _c('div', {
              staticClass: "circleWithText-text",
              staticStyle: {
                "color": "#D5761B"
              }
            }, [_vm._v(_vm._s(data.eventType))])])]), _c('td', {
              staticClass: "dialer-col-right three"
            }, [_vm._v(_vm._s(data.date) + " ")]), _c('td', {
              staticClass: "dialer-col-right four"
            }, [_vm._v(_vm._s(data.visibility) + " ")])]);
          });
        }
      }], null, true)
    })], 1)]);
  })], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Birthdays & Anniversaries "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0"
  }, [_vm._v(" Export ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Employee")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("Event type")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Date")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("Visibility")])]);

}]

export { render, staticRenderFns }