var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "usersContainer"
  }, [!_vm.$store.getters.getIsMobile ? _c('b-form', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mb-3",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Search here",
      "disabled": _vm.isEmpty(_vm.$store.state.common.voipusers)
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1) : _c('b-form', {
    staticClass: "updatingContactsAgain-contacts-searchBar-container",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "updatingContactsAgain-contacts-searchBar"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "mobile-searchBar-icon-likeAirCall-icon",
      "width": "12.68px",
      "height": "12.67px"
    }
  }), _c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Search",
      "disabled": _vm.isEmpty(_vm.$store.state.common.voipusers)
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  })], 1)]), _c('div', {
    staticClass: "tab-pane active",
    attrs: {
      "id": "user_contact_tab"
    }
  }, [_c('div', {
    staticClass: "dialer_contact_list users_list allow_scroll"
  }, _vm._l(_vm.searchedUsers, function (user) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account != user.voipaccount,
        expression: "$store.getters.getCurrentUser.account != user.voipaccount"
      }],
      key: user.voipaccount,
      staticClass: "dialer_contact_row"
    }, [!_vm.$store.getters.getIsMobile ? _c('div', {
      staticClass: "dialer_contact"
    }, [_c('Info', {
      attrs: {
        "id": user.voipaccount,
        "devices": true,
        "is_unread_show": true
      }
    }), _c('div', {
      staticClass: "user-info"
    }, [_c('div', {
      staticClass: "user-name-box"
    }, [_c('div', {
      staticClass: "dialer_contact_hidden_area"
    }, [_c('div', {
      staticClass: "dialer_contact_connection_box"
    }, [_c('span', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
    }, [_c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [user.state == 'Ringing' ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.blf ? _vm.appNotify.open({
            message: 'Your package does not support this feature',
            type: 'danger'
          }) : _vm.dialinringing(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Pick Call")])], 1) : _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.dial(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      class: ['', !_vm.getUserPermissions.chat ? 'for-disable' : ''],
      attrs: {
        "id": `RightSideUsersChatOption-${user.voipaccount}`,
        "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
      }
    }, [_c('b-dropdown-item', {
      class: !_vm.getUserPermissions.chat ? 'for-disable-disable' : '',
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.chat ? '' : _vm.chatUser(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineChat-icon",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Chat")])], 1)], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.videoCall(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineVideo-icon",
        "width": "20.6",
        "height": "13.6",
        "viewBox": "0 0 20.6 13.6",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Video")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.toggleUserFavourite(user);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": user.is_favourites ? 'star-fill' : 'star'
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(user.is_favourites ? 'Remove from Favourite' : 'Add To Favourite'))])], 1)], 1)], 1)])]), user.is_favourites ? _c('span', {
      staticClass: "inTeamsFavouriteIcon"
    }, [_c('b-icon', {
      attrs: {
        "icon": "star-fill",
        "variant": "primary"
      }
    })], 1) : _vm._e()])])], 1) : _c('div', {
      staticClass: "updatingContactsAgain-contact-item"
    }, [_c('Info', {
      attrs: {
        "id": user.voipaccount,
        "devices": true,
        "is_unread_show": false,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('vb-icon', {
            staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
            attrs: {
              "icon": "mobile-topBar-threeDots-likeAirCall-icon",
              "height": "27.06px",
              "width": "6px"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [user.state == 'Ringing' ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.blf ? _vm.appNotify.open({
            message: 'Your package does not support this feature',
            type: 'danger'
          }) : _vm.dialinringing(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Pick Call")])], 1) : _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.dial(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      class: ['', !_vm.getUserPermissions.chat ? 'for-disable' : ''],
      attrs: {
        "id": `RightSideUsersChatOption-${user.voipaccount}`,
        "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
      }
    }, [_c('b-dropdown-item', {
      class: !_vm.getUserPermissions.chat ? 'for-disable-disable' : '',
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.chat ? '' : _vm.chatUser(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineChat-icon",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Chat")])], 1)], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.videoCall(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineVideo-icon",
        "width": "20.6",
        "height": "13.6",
        "viewBox": "0 0 20.6 13.6",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Video")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.toggleUserFavourite(user);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": user.is_favourites ? 'star-fill' : 'star'
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(user.is_favourites ? 'Remove from Favourite' : 'Add To Favourite'))])], 1)], 1)], 1)]);
  }), 0)]), _c('DirectChatModal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }