<template>
  <div>
    <div class="mainHeading">Onboarding</div>
    <div class="mainDescription">
      <br/>
    </div>
    <div class="grayCard flexWrap">
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          ACTIVE TASKS
        </div>
        <div class="whiteSubCard-body">
          <div>3</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          EMPLOYEES IN ONBOARDING
        </div>
        <div class="whiteSubCard-body">
          <div>3</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          UPCOMING TASKS
        </div>
        <div class="whiteSubCard-body">
          <div>3</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth">
        <div class="whiteSubCard-head">
          COMPLETED IN LAST 30 DAYS
        </div>
        <div class="whiteSubCard-body">
          <div>3</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth mb-0">
        <div class="whiteSubCard-head">
          STARTERS IN LAST 30 DAYS WITH NO TASKS
        </div>
        <div class="whiteSubCard-body">
          <div>3</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingOverview',
}
</script>

<style>

</style>