var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm._m(0);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading"
  }, [_vm._v("Access Logs")]), _c('div', {
    staticClass: "mainDescription"
  }, [_c('br')]), _c('div', {
    staticClass: "grayCard flexWrap"
  }, [_c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" LAST 24 HOURS ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("3")]), _c('div', {
    staticClass: "bodyText"
  }, [_vm._v("users")]), _c('div', {
    staticClass: "bodyPrimaryLinkText"
  }, [_vm._v("View details")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" LAST 30 DAYS "), _c('div', {
    staticClass: "bodyText"
  })]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("3")]), _c('div', {
    staticClass: "bodyText"
  }, [_vm._v("users")]), _c('div', {
    staticClass: "bodyPrimaryLinkText"
  }, [_vm._v("View details")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth mb-0"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" MOBILE APP USAGE ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("3")]), _c('div', {
    staticClass: "bodyPrimaryLinkText mt-25px"
  }, [_vm._v("View active sessions")])])]), _c('div', {
    staticClass: "whiteSubCard halfWidth mb-0"
  }, [_c('div', {
    staticClass: "whiteSubCard-head"
  }, [_vm._v(" NEVER LOGGED IN ")]), _c('div', {
    staticClass: "whiteSubCard-body"
  }, [_c('div', [_vm._v("9")]), _c('div', {
    staticClass: "bodyText"
  }, [_vm._v("employees")]), _c('div', {
    staticClass: "bodyPrimaryLinkText"
  }, [_vm._v("Re-send welcome email")])])])])]);

}]

export { render, staticRenderFns }