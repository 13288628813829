var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between ivrAdvanceOptions-1stRow"
  }, [_vm._m(0), _c('button', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.advanceOptions-collapse",
      modifiers: {
        "advanceOptions-collapse": true
      }
    }],
    staticClass: "newButton ml-5",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.conditions.is_view ? 'squared-hide-icon' : 'squared-show-icon',
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_c('span', [_vm._v(_vm._s(_vm.conditions.is_view ? 'Hide' : 'Show'))])])], 1)]), _c('b-collapse', {
    attrs: {
      "id": "advanceOptions-collapse"
    }
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-0"
  }, [_c('label', [_vm._v("Repeat menu sound file if no action is pressed after")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v(_vm._s(_vm.forms.limits.input_seconds ? `${_vm.forms.limits.input_seconds} seconds` : 'Select seconds limit'))]), _vm.$v.forms.limits.input_seconds.$error ? _c('p', [!_vm.$v.forms.limits.input_seconds.required ? _c('span', [_vm._v("* Input Seconds is required")]) : _vm._e()]) : _vm._e(), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: !_vm.getUserPermissions.ivr_advance_options ? 'for-disable' : '',
    attrs: {
      "title": _vm.getUserPermissions.ivr_advance_options ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.ivr_advance_options ? '' : _vm.$modal.show(`${_vm._uid}-selectSecondsModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])])], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-100"
  }, [_c('label', [_vm._v("Allow the sound file to repeat")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v(_vm._s(_vm.forms.limits.ivr_nodtmf_repeat_limit ? `${_vm.forms.limits.ivr_nodtmf_repeat_limit} times` : 'Select repeat limit'))]), _vm.$v.forms.limits.ivr_nodtmf_repeat_limit.$error ? _c('p', [!_vm.$v.forms.limits.ivr_nodtmf_repeat_limit.required ? _c('span', [_vm._v("* Sound Repeats is required")]) : _vm._e()]) : _vm._e(), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: !_vm.getUserPermissions.ivr_advance_options ? 'for-disable' : '',
    attrs: {
      "title": _vm.getUserPermissions.ivr_advance_options ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.ivr_advance_options ? '' : _vm.$modal.show(`${_vm._uid}-selectRepeatModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])])], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion optionIsdotted mb-0 w-100"
  }, [_c('label', [_vm._v("If no action has been pressed after playing sound file repeatedly")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.selectedCMDOption, 'text', 'Select option')))]), _vm.$v.forms.limits.cmd.$error ? _c('p', [!_vm.$v.forms.limits.cmd.required ? _c('span', [_vm._v("* Sound Repeats is required")]) : _vm._e()]) : _vm._e(), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: !_vm.getUserPermissions.ivr_advance_options ? 'for-disable' : '',
    attrs: {
      "title": _vm.getUserPermissions.ivr_advance_options ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.ivr_advance_options ? '' : _vm.$modal.show(`${_vm._uid}-selectNoActionOptionModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])]), _vm.cmdArgs.account || _vm.cmdArgs.dail_number ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_vm.cmdArgs.account ? _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-100"
  }, [_c('label', [_vm._v("Assign IVR")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_c('Info', {
    attrs: {
      "id": _vm.forms.limits.account,
      "is_blf": false,
      "not_show_on_empty": true,
      "name": 'Select option',
      "type": 'ivr'
    }
  })], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: !_vm.getUserPermissions.ivr_advance_options ? 'for-disable' : '',
    attrs: {
      "title": _vm.getUserPermissions.ivr_advance_options ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.ivr_advance_options ? '' : _vm.$modal.show(`${_vm._uid}-selectAssignIVROptionModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])]), _vm.$v.forms.limits.account.$error ? _c('p', {
    staticClass: "mb-0",
    staticStyle: {
      "color": "red"
    }
  }, [!_vm.$v.forms.limits.account.required ? _c('span', [_vm._v("* Atleast one option needs to be selected")]) : _vm._e()]) : _vm._e()]) : _vm._e(), _vm.cmdArgs.dail_number ? _c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mb-0 w-100"
  }, [_c('label', [_vm._v("Forward the call to user/operator")]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-descriptionText mt-0"
  }, [_c('Info', {
    attrs: {
      "id": _vm.forms.limits.dail_number,
      "is_blf": false,
      "not_show_on_empty": true,
      "name": 'Select option'
    }
  })], 1), _vm.$v.forms.limits.dail_number.$error ? _c('p', [!_vm.$v.forms.limits.dail_number.required ? _c('span', [_vm._v("* Sound Repeats is required")]) : _vm._e()]) : _vm._e(), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: !_vm.getUserPermissions.ivr_advance_options ? 'for-disable' : '',
    attrs: {
      "title": _vm.getUserPermissions.ivr_advance_options ? '' : 'Your package does not support this feature'
    }
  }, [_c('a', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        !_vm.getUserPermissions.ivr_advance_options ? '' : _vm.$modal.show(`${_vm._uid}-selectForwardCallOptionModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])]) : _vm._e()], 1)])]) : _vm._e()], 1), _vm.cmdArgs.sound ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex align-items-end justify-content-between w-100"
  }, [_c('div', {
    staticClass: "w-75"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Forward sound")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Played before forwarding call")]), _c('div', {
    staticClass: "w-75 mt-20px"
  }, [_vm.selectedSound.sound_file ? _c('vb-audio-player', {
    staticClass: "w-100 whiteBGaudioWithGreyRoundedBorder-again greyer",
    attrs: {
      "src": _vm.selectedSound.sound_file,
      "design": `${_vm.$store.getters.getIsMobile ? 'minimum' : ''}`
    }
  }) : _vm._e()], 1), _vm.$v.forms.limits.sound_id.$error ? _c('p', [!_vm.$v.forms.limits.sound_id.required ? _c('span', [_vm._v("* Sound Repeats is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: !_vm.getUserPermissions.ivr_advance_options ? 'for-disable' : '',
    attrs: {
      "title": _vm.getUserPermissions.ivr_advance_options ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton md-mar-left",
    attrs: {
      "disabled": !_vm.getUserPermissions.ivr_advance_options || _vm.api.limits.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.api.limits.send ? '' : _vm.$modal.show('RecordingListSelecForForward', {
          group: 'announcement',
          sounds: _vm.response.common_values.sounds,
          selected: _vm.selectedSound
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.limits.sound_id ? "Change" : "Assign"))])], 1)])])]) : _vm._e(), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "d-flex align-items-end justify-content-between w-100"
  }, [_c('div', {
    staticClass: "w-75"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v(" Invalid input sound ")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" When a wrong menu option is pressed, this sound file will be played ")]), _c('div', {
    staticClass: "w-75 mt-20px"
  }, [_vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit ? _c('vb-audio-player', {
    staticClass: "w-100 whiteBGaudioWithGreyRoundedBorder-again greyer",
    attrs: {
      "src": _vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit,
      "design": `${_vm.$store.getters.getIsMobile ? 'minimum' : ''}`
    }
  }) : _vm._e()], 1), _vm.forms.update_wrong_ivr_input.submitted && _vm.$v.forms.update_wrong_ivr_input.$invalid ? [!_vm.$v.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit.required ? _c('small', [_vm._v("* Required")]) : _vm._e()] : _vm._e()], 2), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: !_vm.getUserPermissions.ivr_advance_options ? 'for-disable' : '',
    attrs: {
      "title": _vm.getUserPermissions.ivr_advance_options ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton md-mar-left",
    attrs: {
      "disabled": !_vm.getUserPermissions.ivr_advance_options || _vm.api.update_wrong_ivr_input.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.api.update_wrong_ivr_input.send ? '' : _vm.$modal.show('RecordingListSelecForWrongInput', {
          group: 'announcement'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v(_vm._s(_vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit ? "Change" : "Assign"))])], 1)])])])]), _vm.cmdArgs.code_dtmf ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('b-form-group', {
    staticClass: "m-0"
  }, [_c('div', [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Code DTMF")])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    attrs: {
      "title": _vm.getUserPermissions.ivr_advance_options ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-input', {
    attrs: {
      "type": "number",
      "disabled": !_vm.getUserPermissions.ivr_advance_options
    },
    on: {
      "change": function ($event) {
        return _vm.updateAdvanceOptionsDebounce();
      }
    },
    model: {
      value: _vm.forms.limits.code_dtmf,
      callback: function ($$v) {
        _vm.$set(_vm.forms.limits, "code_dtmf", $$v);
      },
      expression: "forms.limits.code_dtmf"
    }
  })], 1), _vm.$v.forms.limits.code_dtmf.$error ? _c('p', [!_vm.$v.forms.limits.code_dtmf.required ? _c('span', [_vm._v("* Sound Repeats is required")]) : _vm._e()]) : _vm._e()])])], 1)]) : _vm._e()])], 1), _c('RecordingListSelect', {
    attrs: {
      "is_single_select": true,
      "modalName": 'RecordingListSelecOnAdvance'
    },
    on: {
      "selectedRecordings": function ($event) {
        _vm.forms.limits.ivr_wrongdtmf_repeat_limit = $event;
        _vm.updateAdvanceOptionsDebounce();
      }
    }
  }), _c('RecordingListSelect', {
    attrs: {
      "is_single_select": true,
      "modalName": 'RecordingListSelecForWrongInput'
    },
    on: {
      "selectedRecordings": function ($event) {
        return _vm.updateWrongIVRInput($event);
      }
    }
  }), _c('RecordingListSelect', {
    attrs: {
      "is_single_select": true,
      "modalName": 'RecordingListSelecForForward'
    },
    on: {
      "selectedRecordings": function ($event) {
        _vm.forms.limits.sound_id = $event.sound;
        _vm.updateAdvanceOptionsDebounce('forward_sound');
      }
    }
  }), _c('SelectSounds', {
    on: {
      "selected-sound": function ($event) {
        _vm.forms.limits.sound_id = $event.sound;
        _vm.updateAdvanceOptionsDebounce('forward_sound');
      }
    }
  }), _c('SelectAccounts', {
    on: {
      "selected-account": function ($event) {
        _vm.forms.limits.account = $event.accountcode;
        _vm.updateAdvanceOptionsDebounce('assign_ivr');
      }
    }
  }), _c('SelectExtensionModal', {
    on: {
      "select": function ($event) {
        _vm.forms.limits.dail_number = $event.extn;
        _vm.updateAdvanceOptionsDebounce();
      }
    }
  }), _c('IVRselectActionsModal'), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-selectSecondsModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Repeat menu sound file if no action is pressed after")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-selectSecondsModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.secondsOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none",
      on: {
        "click": function ($event) {
          _vm.forms.limits.input_seconds = option.value;
          _vm.updateAdvanceOptionsDebounce('repeat_sound_seconds');
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.limits.input_seconds == option.value ? 'checked' : ''}`
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option.text))])])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-selectRepeatModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Allow the sound file to repeat")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-75 mb-0"
  })]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-selectRepeatModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.repeatTimeOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none",
      on: {
        "click": function ($event) {
          _vm.forms.limits.ivr_nodtmf_repeat_limit = option.value;
          _vm.updateAdvanceOptionsDebounce('repeat_sound_time');
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.limits.ivr_nodtmf_repeat_limit == option.value ? 'checked' : ''}`
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option.text))])])]);
  }), 0)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-selectNoActionOptionModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("If no action has been pressed")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-75 mb-0"
  })]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-selectNoActionOptionModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm.cmdOptions.length == 0 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("No record found")])])]) : _vm._l(_vm.cmdOptions, function (option) {
    return _c('div', {
      key: option.value,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none",
      on: {
        "click": function ($event) {
          return _vm.onChangeCMD(option.value);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.limits.cmd == option.value ? 'checked' : ''}`
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(option.text))])])]);
  })], 2)])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-selectAssignIVROptionModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Assign IVR")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-75 mb-0"
  })]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-selectAssignIVROptionModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm.ivrOptions.length == 0 ? _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("No record found")])])]) : _vm._l(_vm.ivrOptions, function (option) {
    return _c('div', {
      key: option.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none",
      on: {
        "click": function ($event) {
          _vm.forms.limits.account = option.value;
          _vm.updateAdvanceOptionsDebounce('assign_ivr');
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.forms.limits.account == option.value ? 'checked' : ''}`
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_c('Info', {
      attrs: {
        "id": option.value,
        "not_show_on_empty": true,
        "name": option.text,
        "type": 'ivr'
      }
    })], 1)])]);
  })], 2)])])]), _c('modal', {
    staticClass: "MenuOptionExtensionAssignModal dialer_animation right_side_popup m-w-500 bigSquareCheckbox min-width-50percent-modal",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-selectForwardCallOptionModal`
    },
    on: {
      "before-close": function ($event) {
        _vm.filters.forward_exten.tab = _vm.forwardExtenTabs.USER.value;
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0 mt-16px"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Assign to")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-75 mb-0"
  })]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-selectForwardCallOptionModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "TabsDesignWithIcon-container mt-20px"
  }, _vm._l(_vm.forwardExtenTabs, function (tab) {
    return _c('div', {
      key: tab.value,
      staticClass: "TabsDesignWithIcon-item",
      class: {
        'active': _vm.filters.forward_exten.tab == tab.value
      },
      on: {
        "click": function ($event) {
          _vm.filters.forward_exten.tab = tab.value;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.icon,
        "height": "38px",
        "width": "38px"
      }
    }), _c('span', {
      staticClass: "TabsDesignWithIcon-item-textPart"
    }, [_vm._v(_vm._s(tab.text))])], 1);
  }), 0), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end w-100 mt-20px"
  }, [_c('div', {
    staticClass: "w-50 whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search user, extension"
    },
    model: {
      value: _vm.filters.forward_exten.search,
      callback: function ($$v) {
        _vm.$set(_vm.filters.forward_exten, "search", $$v);
      },
      expression: "filters.forward_exten.search"
    }
  })], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm._l(_vm.extensions, function (extension) {
    return _c('div', {
      key: extension.extn,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none",
      on: {
        "click": function ($event) {
          _vm.forms.limits.dail_number = extension.extn;
          _vm.updateAdvanceOptionsDebounce('forward_to');
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "actLikeSquareRadio mr-20px",
      class: {
        'checked': _vm.forms.limits.dail_number == extension.extn
      }
    }), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_c('Info', {
      attrs: {
        "id": extension.extn,
        "type": extension.account,
        "is_blf": false
      }
    })], 1)])]);
  }), _vm.extensions.length == 0 ? _c('vb-no-record', {
    staticClass: "my-5",
    attrs: {
      "text": 'Sorry, no Data Found',
      "design": 3
    }
  }) : _vm._e()], 2)])])])], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mr-5"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Advance options")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" Customise advanced settings like menu repetition and post-menu actions for precise call routing and enhanced caller interaction. ")])]);

}]

export { render, staticRenderFns }