<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.edit">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.invoice_setting.send ? '' : conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Invoice Setting</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <form class="dialer-form lg-mar-bottom" @submit.prevent="updateInvoiceSetting()">

            <b-alert class="mt-32px" :show="!!api.invoice_setting.error_message" variant="danger">{{api.invoice_setting.error_message}}</b-alert>
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Company Name</label>
                  <input 
                    class="w-100" 
                    type="text" 
                    v-model="forms.invoice_setting.companyname" 
                    :disabled="api.invoice_setting.send"
                  />
                  <p 
                    v-if="(forms.invoice_setting.submitted && $v.forms.invoice_setting.companyname.$invalid) || api.invoice_setting.validation_errors.companyname" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.invoice_setting.companyname.required">* Company Name field is required</span>
                    <span v-for="em in api.invoice_setting.validation_errors.companyname" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>
              
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>First Name</label>
                  <input 
                    class="w-100" 
                    type="text" 
                    v-model="forms.invoice_setting.firstname" 
                    :disabled="api.invoice_setting.send"
                  />
                  <p 
                    v-if="(forms.invoice_setting.submitted && $v.forms.invoice_setting.firstname.$invalid) || api.invoice_setting.validation_errors.firstname" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.invoice_setting.firstname.required">* First Name field is required</span>
                    <span v-for="em in api.invoice_setting.validation_errors.firstname" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>

              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Last Name</label>
                  <input 
                    class="w-100" 
                    type="text" 
                    v-model="forms.invoice_setting.lastname" 
                    :disabled="api.invoice_setting.send"
                  />
                  <p 
                    v-if="(forms.invoice_setting.submitted && $v.forms.invoice_setting.lastname.$invalid) || api.invoice_setting.validation_errors.lastname" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.invoice_setting.lastname.required">* Last Name field is required</span>
                    <span v-for="em in api.invoice_setting.validation_errors.lastname" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>

              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Address</label>
                  <input 
                    class="w-100" 
                    type="text" 
                    v-model="forms.invoice_setting.address" 
                    :disabled="api.invoice_setting.send"
                  />
                  <p 
                    v-if="(forms.invoice_setting.submitted && $v.forms.invoice_setting.address.$invalid) || api.invoice_setting.validation_errors.address" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.invoice_setting.address.required">* Address field is required</span>
                    <span v-for="em in api.invoice_setting.validation_errors.address" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>

              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Town/City</label>
                  <input 
                    class="w-100" 
                    type="text" 
                    v-model="forms.invoice_setting.city" 
                    :disabled="api.invoice_setting.send"
                  />
                  <p 
                    v-if="(forms.invoice_setting.submitted && $v.forms.invoice_setting.city.$invalid) || api.invoice_setting.validation_errors.city" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.invoice_setting.city.required">* Town/City field is required</span>
                    <span v-for="em in api.invoice_setting.validation_errors.city" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>

              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Postcode</label>
                  <input 
                    class="w-100" 
                    type="text" 
                    v-model="forms.invoice_setting.postcode" 
                    :disabled="api.invoice_setting.send"
                  />
                  <p 
                    v-if="(forms.invoice_setting.submitted && $v.forms.invoice_setting.postcode.$invalid) || api.invoice_setting.validation_errors.postcode" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.invoice_setting.postcode.required">* Postcode field is required</span>
                    <span v-for="em in api.invoice_setting.validation_errors.postcode" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>

              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Country</label>
                  <b-form-select 
                    class="w-100" 
                    :disabled="api.invoice_setting.send" 
                    v-model="forms.invoice_setting.country" 
                    :options="countryOptions"
                  />
                  <p 
                    v-if="(forms.invoice_setting.submitted && $v.forms.invoice_setting.country.$invalid) || api.invoice_setting.validation_errors.country" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.invoice_setting.country.required">* Country field is required</span>
                    <span v-for="em in api.invoice_setting.validation_errors.country" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>

              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Email</label>
                  <input 
                    class="w-100" 
                    type="text" 
                    v-model="forms.invoice_setting.email" 
                    :disabled="api.invoice_setting.send"
                  />
                  <p 
                    v-if="(forms.invoice_setting.submitted && $v.forms.invoice_setting.email.$invalid) || api.invoice_setting.validation_errors.email" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.invoice_setting.email.required">* Email field is required</span>
                    <span v-for="em in api.invoice_setting.validation_errors.email" :key="em">* {{ em }}</span>
                  </p>
                </div>
              </div>

            </div>

            <button class="IosFullWidthButton forDone mb-16px" :disabled="api.invoice_setting.send">
              <vb-spinner v-if="api.invoice_setting.send" />
              <template v-else>Update</template>
            </button>

          </form>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Invoice Setting</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">

            <div class="basicWhiteIOScard mt-32px mb-16px">
              
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">First Name</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.invoice_setting.firstname }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Last Name</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.invoice_setting.lastname }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Company Name</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.invoice_setting.companyname }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Address</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.invoice_setting.address }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">City</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.invoice_setting.city }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Country</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.invoice_setting.country }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Email</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.invoice_setting.email }}</div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Post Code</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.invoice_setting.postcode }}</div>
              </div>

            </div>
            
          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { required } from 'vuelidate/lib/validators';

import { VOIP_API } from '@/utils';

let screens = {
  edit: 'edit',
  view: 'view',
}
let country_options = [
  { 
    value: "GB", 
    text: "United Kingdom" 
  },
  { 
    value: "PK", 
    text: "Pakistan" 
  },
]

export default {
  name: "InvoiceSettingModal",
  inject: ['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'InvoiceSetting',
    }
  },
  data() {
    return {
      forms: {
        invoice_setting: this.$helperFunction.formInstance({
          data: {
            firstname: '',
            lastname: '',
            companyname: '',
            address: '',
            city: '',
            country: '',
            email: '',
            postcode: '',
          },
        }),
      },
      api: {
        invoice_setting: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        get_invoice_setting: this.$helperFunction.apiInstance({  }),
      },
      conditions: {
        screen: screens.view
      },
      response: {
        invoice_setting: {},
      },
    };
  },
  computed: {
    screens(){ return screens },
    countryOptions(){ return country_options },
  },
  validations: {
    forms: {
      invoice_setting: {
        firstname: { 
          required, 
        },
        lastname: { 
          required, 
        },
        companyname: { 
          required, 
        },
        address: { 
          required, 
        },
        city: { 
          required, 
        },
        country: { 
          required, 
        },
        postcode: { 
          required, 
        },
        email: { 
          // required, 
        },
      },
    }
  },
  methods: {
    onBeforeOpen(){
      this.fetchInvoiceSetting()
    },
    onBeforeClose(){
      this.conditions.screen=screens.view
      this.response.invoice_setting={}
      this.forms.invoice_setting.reset()
      this.api.invoice_setting.reset()
      this.api.get_invoice_setting.reset()
    },
    updateInvoiceSetting() {
      let vm = this
      vm.forms.invoice_setting.submitted=true
      vm.$v.forms.invoice_setting.$touch();
      if (vm.$v.forms.invoice_setting.$invalid || vm.api.invoice_setting.send) return;
      vm.api.invoice_setting.send = true;
      vm.api.invoice_setting.error_messsage = '';
      vm.api.invoice_setting.validation_errors = {};
      VOIP_API.endpoints.users.updateinvoicedetail({
        firstname: vm.forms.invoice_setting.firstname,
        lastname: vm.forms.invoice_setting.lastname,
        companyname: vm.forms.invoice_setting.companyname,
        address: vm.forms.invoice_setting.address,
        city: vm.forms.invoice_setting.city,
        country: vm.forms.invoice_setting.country,
        email: vm.forms.invoice_setting.email,
        postcode: vm.forms.invoice_setting.postcode,
      })
      .then(() => {
        vm.appNotify({
          message: "Invoice information updated successfully",
          type: "success",
        })
        vm.$emit('updated')
        vm.conditions.screen=screens.view
        vm.fetchInvoiceSetting()
      })
      .catch((ex) => {
        vm.api.invoice_setting.error_messsage = ex.own_message ?? ''
        vm.api.invoice_setting.validation_errors = ex.own_errors ?? {};
      })
      .finally(() => {
        vm.forms.invoice_setting.submitted=false
        vm.api.invoice_setting.send=false
      });
    },
    fetchInvoiceSetting(){
      let vm = this
      vm.api.get_invoice_setting.send=true
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data }) => {
        const detail = data?.user_detail ?? {}
        vm.response.invoice_setting = detail
        vm.forms.invoice_setting.firstname = detail?.firstname ?? '';
        vm.forms.invoice_setting.lastname = detail?.lastname ?? '';
        vm.forms.invoice_setting.companyname = detail?.companyname ?? '';
        vm.forms.invoice_setting.address = detail?.address ?? '';
        vm.forms.invoice_setting.city = detail?.city ?? '';
        vm.forms.invoice_setting.country = detail?.country ?? '';
        vm.forms.invoice_setting.email = detail?.email ?? '';
        vm.forms.invoice_setting.postcode = detail?.postcode ?? '';
      })
      .catch((ex)=>{
        // console.log(ex)
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.get_invoice_setting.send=false
      });
    },
  },
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
