var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-reports"
  }, [_c('div', {
    staticClass: "dialer-box"
  }, [_c('header', {
    staticClass: "dialer-analytics-titleBar"
  }, [_vm.getIsMobile ? [_c('div', {
    staticClass: "dialer-analytics-title calc-title-width-analytics"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h1', [_vm._v("Reporting")])], 1)] : _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_vm._m(0), _c('a', {
    staticClass: "w-auto billedCallsLink",
    staticStyle: {
      "text-decoration": "underline",
      "font-size": "16px",
      "color": "black",
      "cursor": "pointer"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('change-tab', 'Billing');
      }
    }
  }, [_vm._v("Billed calls")])])], 2), _c('div', {
    staticClass: "my-4 latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder wd-33-imp px-2"
  }, [_c('label', [_vm._v("Search number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.reports.number,
      expression: "filters.reports.number"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.reports.send,
      "type": "text",
      "placeholder": "Source or destination number"
    },
    domProps: {
      "value": _vm.filters.reports.number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.reports, "number", $event.target.value);
      }
    }
  })]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker wd-33-imp px-2"
  }, [_c('label', [_vm._v("Select date range")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "/",
    attrs: {
      "label": "Show current date",
      "format": "YYYY-MM-DD",
      "disabled": _vm.api.reports.send,
      "noButton": false,
      "range": true
    },
    model: {
      value: _vm.filters.reports.date_range,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "date_range", $$v);
      },
      expression: "filters.reports.date_range"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder CallType wd-33-imp px-2"
  }, [_c('label', [_vm._v("Call type")]), _c('v-select', {
    staticClass: "cursor_pointer2",
    attrs: {
      "disabled": _vm.api.reports.send,
      "options": _vm.response.types,
      "placeholder": "Received, dialed and missed"
    },
    model: {
      value: _vm.filters.reports.type,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "type", $$v);
      },
      expression: "filters.reports.type"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex w-100 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder CallType w-100 px-2"
  }, [_c('label', {
    staticClass: "dialer-input-label pl-2"
  }, [_vm._v("User & extension")]), _c('vSelect', {
    staticClass: "cursor_pointer2 lighter-placeholder",
    attrs: {
      "multiple": "",
      "disabled": _vm.api.reports.send,
      "options": _vm.response.extensions,
      "placeholder": "One user with multiple extensions, limit."
    },
    model: {
      value: _vm.filters.reports.agents,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "agents", $$v);
      },
      expression: "filters.reports.agents"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex w-100 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder CallType w-100 px-2"
  }, [_c('label', {
    staticClass: "dialer-input-label pl-2"
  }, [_vm._v("Tags")]), _c('vSelect', {
    staticClass: "cursor_pointer2 lighter-placeholder",
    attrs: {
      "multiple": "",
      "disabled": _vm.api.reports.send,
      "options": _vm.tagOptions,
      "placeholder": "Select tags"
    },
    model: {
      value: _vm.filters.reports.tags,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "tags", $$v);
      },
      expression: "filters.reports.tags"
    }
  })], 1)]), _c('div', {
    staticClass: "d-flex w-100 justify-content-end mt-20px"
  }, [_c('button', {
    staticClass: "newButton ml-auto",
    attrs: {
      "disabled": _vm.api.reports.send
    },
    on: {
      "click": function ($event) {
        _vm.paginations.reports.page = 1;
        _vm.fetchReports();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-browse-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Result")])], 1)])]), _c('div', {
    staticClass: "md-mar-top dialer-box latestTableDesign-withBlackBorders-again pt-3 alt table"
  }, [_c('table', {
    staticClass: "dialer-table"
  }, [_vm._m(1), _c('tbody', [_vm.api.reports.send ? _vm._l(5, function (n) {
    return _c('tr', {
      key: n
    }, [_vm._m(2, true), _vm._m(3, true), _vm._m(4, true), _vm._m(5, true)]);
  }) : _vm.response.reports.length == 0 ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "6"
    }
  }, [_c('div', {
    staticClass: "emptyScreenContainer b-0"
  }, [_vm.GET_LOCAL_SETTING_IS_DARK_MODE ? _c('img', {
    attrs: {
      "width": "210px",
      "height": "196px",
      "src": require('@/assets/images/emptyScreenImages/darkMode/emptyReports.png')
    }
  }) : _c('img', {
    attrs: {
      "width": "210px",
      "height": "196px",
      "src": require('@/assets/images/emptyScreenImages/emptyReports.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading"
  }, [_vm._v("You haven't generated any reports yet. Get started now!")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-75"
  }, [_vm._v("Your report library is empty at the moment. Start by creating custom reports based on your preferences and analyze your call data effectively.")]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px"
  }, [_vm._v("Generate a report")])])])]) : _vm._l(_vm.response.reports, function (data, key) {
    return _c('ReportItem', {
      key: key,
      attrs: {
        "index": key,
        "data": data
      },
      on: {
        "transcript-click": function ($event) {
          return _vm.$modal.show(`${_vm._uid}-RecordingTranscript`, {
            uniq_id: data.uniqueid,
            sound_file: data.soundFile
          });
        }
      }
    });
  })], 2)]), _vm.response.reports.length != 0 ? _c('div', {
    staticClass: "d-flex justify-content-center w-100"
  }, [_c('vb-pagination', {
    attrs: {
      "listLength": _vm.paginations.reports.total,
      "conditions": {
        pagination: {
          per_page: false
        }
      },
      "perPage": _vm.paginations.reports.per_page,
      "page": _vm.paginations.reports.page,
      "isListEmpty": false
    },
    on: {
      "latest-pagination-state": function ($event) {
        $event.page != _vm.paginations.reports.page ? (_vm.paginations.reports.page = $event.page, _vm.fetchReports()) : '';
      }
    }
  })], 1) : _vm._e()])]), _c('CreateReportModal'), _c('RecordingTranscriptModal', {
    attrs: {
      "modalName": `${_vm._uid}-RecordingTranscript`
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h1', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Reporting")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort two"
  }, [_c('span', [_vm._v("Test")])]), _c('th', {
    staticClass: "dialer-has-sort four"
  }, [_c('span', [_vm._v("TIME & DATE")])]), _c('th', {
    staticClass: "dialer-has-sort five"
  }, [_c('span', {
    attrs: {
      "id": "timeAndDuration"
    }
  }, [_vm._v("DURATION")])]), _c('th', {
    staticClass: "dialer-has-sort six dialer-col-right"
  }, [_c('span', [_vm._v("Call Recorded")])])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "20px",
      "width": "-webkit-fill-available"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mr-2",
    staticStyle: {
      "height": "20px",
      "min-width": "60px"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "20px",
      "width": "-webkit-fill-available"
    }
  })]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('td', {
    staticClass: "dialer-col-right"
  }, [_c('div', {
    staticClass: "latestShimmerDesign ml-auto",
    staticStyle: {
      "height": "40px",
      "width": "70px"
    }
  })]);

}]

export { render, staticRenderFns }