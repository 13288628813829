<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add Sound File</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">

        <form class="dialer-form lg-mar-bottom" @submit.prevent="addSound()">
          <b-alert class="mt-32px" :show="!!api.add_sound.error_message" variant="danger">{{api.add_sound.error_message}}</b-alert>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Name</label>
                <input placeholder="Enter Name" class="w-100" type="text" :disabled="api.add_sound.send" v-model="forms.add_sound.name" />
                <p v-if="(forms.add_sound.submitted && $v.forms.add_sound.name.$invalid) || api.add_sound.validation_errors.name" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.add_sound.name.required">* Name is required</span>
                  <span v-for="(em, i) in api.add_sound.validation_errors.name" :key="i">* {{em}}</span>
                </p>
              </div>
            </div>
            <div class="basicWhiteIOScard-item" @click="api.add_sound.send?'':$refs.upload_audio.click()">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Upload Recording</div>
                  <p v-if="(forms.add_sound.submitted && $v.forms.add_sound.file.$invalid) || api.add_sound.validation_errors.file" class="text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.add_sound.file.required">* File is required</span>
                    <span v-else-if="!$v.forms.add_sound.file.maxSize">* File is Maximum size is 5MB</span>
                    <span v-else-if="!$v.forms.add_sound.file.minSize">* File is Minimum size is 5Kb</span>
                    <span v-for="(em, i) in api.add_sound.validation_errors.file" :key="i">* {{em}}</span>
                  </p>
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center">
                  <span class="mr-8px textInsideCard textAlignRight onRightSide" v-if="api.add_sound.upload_progress.is_process">{{ api.add_sound.upload_progress.percentage }}%</span>
                  <vb-audio-player class="IosAudioPlayer mr-8px" v-if="forms.add_sound.file" :src="forms.add_sound.file | fileToUrl" />
                  <vb-icon icon="uploadProfileImage-onRightSide-IOSIcon" width="21.562" height="21.562" />
                </div>
              </div>
              <input v-show="false" ref="upload_audio" type="file" accept="audio/*" :multiple="false" @change="forms.add_sound.file=getProperty($event,'target.files[0]',null)" />
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Shared</div>
                </div>
              </div>
              <div class="position-relative">
                <b-form-checkbox 
                  ref="shared_check_box"
                  class="dialer-switch IosSwitch" 
                  :disabled="api.add_sound.send" 
                  v-model="forms.add_sound.shared"
                  switch 
                />  
              </div>
            </div>
          </div>
          <button class="IosFullWidthButton forDone mb-16px" :disabled="api.add_sound.send">
            <vb-spinner v-if="api.add_sound.send" />
            <template v-else>Submit</template>
          </button>
        </form>

      </div>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';

import { VOIP_API } from '@/utils';

export default {
  name: "AddSoundFileModal",
  inject: ['getProperty','appNotify'],
  props:{
    modalName: {
      type: String,
      default: 'AddSoundFile'
    },
  },
  data() {
    return {
      forms: {
        add_sound: this.$helperFunction.formInstance({
          data: {
            name: '',
            file: null,
            shared: false,
          },
        })
      },
      api: {
        add_sound: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, upload_progress: true, })
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser'
    ]),
  },
  validations: {
    forms: {
      add_sound: {
        name: {
          required,
        },
        file: {
          required,
          maxSize(file){ return !(file?.size>5 * 1024 * 1024) },
          minSize(file){ return !(file?.size<=5 * 1024) },
        },
      },
    },
  },
  filters: {
    fileToUrl(file){
      if(file instanceof File){
        return URL.createObjectURL(file)
      } else {
        return ''
      }
    }
  },
  methods: {
    onBeforeOpen(event) {
      this.forms.add_sound.name=event?.params?.suggested_name ?? ''
    },
    onBeforeClose() {
      this.forms.add_sound.reset()
      this.api.add_sound.reset()
    },
    addSound(){
      let vm = this
      vm.forms.add_sound.submitted=true
      vm.$v.forms.add_sound.$touch()
      if(vm.$v.forms.add_sound.$invalid || vm.api.add_sound.send) return;
      vm.api.add_sound.send=true
      vm.api.add_sound.error_message=''
      const formData = new FormData();
      formData.append("file", vm.forms.add_sound.file, vm.forms.add_sound.file.name);
      formData.append("accountcode", vm.getCurrentUser.default_accountcode);
      formData.append("share", vm.forms.add_sound.shared ? 'Y' : 'N');
      formData.append("uid", vm.getCurrentUser.uid);
      formData.append("useraccount", vm.getCurrentUser.account);
      formData.append("display_name", vm.forms.add_sound.name);
      VOIP_API.endpoints.recording.create(formData,vm.api.add_sound.upload_progress.onProgress.bind(vm.api.add_sound.upload_progress))
      .then(()=>{
        vm.$emit('update-list');
        vm.$modal.hide(vm.modalName);
        this.appNotify({
          message: 'We are processing your file it may take couple of minutes once it is ready we will notify you.',
          type: 'success',
        })
      })
      .catch((ex)=>{
        vm.api.add_sound.error_message=ex.own_message
      })
      .finally(()=>{
        vm.forms.add_sound.submitted=false
        vm.api.add_sound.send=false
      })
    },
  },
};
</script>

<style>
/**
 * FilePond Custom Styles
 */
.filepond--drop-label {
  color: #4c4e53;
}

.filepond--label-action {
  text-decoration-color: #babdc0;
}

.filepond--panel-root {
  border-radius: 2em;
  background-color: #edf0f4;
  height: 1em;
}

.filepond--item-panel {
  background-color: #595e68;
}

.filepond--drip-blob {
  background-color: #7f8a9a;
}
/* [data-filepond-item-state="processing-complete"] .filepond--file-status-sub, */
.filepond--file-status .filepond--file-status-sub,
button.filepond--file-action-button.filepond--action-revert-item-processing {
  display: none;
}
</style>
