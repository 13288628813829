var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Public Holiday/Event "), _c('div', {
    staticClass: "d-flex"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary mr-16px withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('hrmAddNewPublicHolidayModal');
      }
    }
  }, [_c('vb-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "calender-hrmIcon",
      "height": "18.8px",
      "width": "18.8px"
    }
  }), _vm._v(" Add public holiday ")], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('hrmImportPublicHolidayModal');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "plus",
      "font-scale": "1.3",
      "scale": "1.5"
    }
  }), _vm._v(" Import ")], 1)])]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Select whether or not these days are counted against employee holiday allowance ")]), _c('div', {
    staticClass: "hrmTabsContainer pt-3 pb-4"
  }, [_c('div', {
    class: `hrmTabsButton ${_vm.showTabs == 'All holidays' ? 'active' : ''} mr-16px`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'All holidays';
      }
    }
  }, [_vm._v("All holidays")]), _c('div', {
    class: `hrmTabsButton ${_vm.showTabs == 'General' ? 'active' : ''} mr-16px`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'General';
      }
    }
  }, [_vm._v("General")]), _c('div', {
    class: `hrmTabsButton ${_vm.showTabs == 'Sick' ? 'active' : ''} mr-16px`,
    on: {
      "click": function ($event) {
        _vm.showTabs = 'Sick';
      }
    }
  }, [_vm._v("Sick")])]), _vm.showTabs == 'All holidays' ? [_c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_vm._v(" Boxing Day ")]), _c('td', {
            staticClass: "dialer-col-right two"
          }, [_vm._v(" 26-12-2023 ")]), _c('td', {
            staticClass: "dialer-col-right three"
          }, [_vm._v("General")]), _c('td', {
            staticClass: "dialer-col-right four"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link",
              "toggle-class": "text-decoration-none tb-notes-dd",
              "no-caret": "",
              "menu-class": "notes-table-dd"
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#"
            }
          }, [_vm._v("Action")])], 1)], 1)]);
        });
      },
      proxy: true
    }], null, false, 1180729636)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead one pt-1"
  }, [_vm._v("Name")]), _c('th', {
    staticClass: "HrmTableHead two pt-1"
  }, [_vm._v("date")]), _c('th', {
    staticClass: "HrmTableHead three pt-1"
  }, [_vm._v("groups")]), _c('th', {
    staticClass: "HrmTableHead four pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1)] : _vm._e(), _vm.showTabs == 'General' ? [_c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_vm._v(" Boxing Day ")]), _c('td', {
            staticClass: "dialer-col-right two"
          }, [_vm._v(" 26-12-2023 ")]), _c('td', {
            staticClass: "dialer-col-right three"
          }, [_vm._v("General")]), _c('td', {
            staticClass: "dialer-col-right four"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link",
              "toggle-class": "text-decoration-none tb-notes-dd",
              "no-caret": "",
              "menu-class": "notes-table-dd"
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#"
            }
          }, [_vm._v("Action")])], 1)], 1)]);
        });
      },
      proxy: true
    }], null, false, 1180729636)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead one pt-1"
  }, [_vm._v("Name")]), _c('th', {
    staticClass: "HrmTableHead two pt-1"
  }, [_vm._v("date")]), _c('th', {
    staticClass: "HrmTableHead three pt-1"
  }, [_vm._v("groups")]), _c('th', {
    staticClass: "HrmTableHead four pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1)] : _vm._e(), _vm.showTabs == 'Sick' ? [_c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_vm._v(" Boxing Day ")]), _c('td', {
            staticClass: "dialer-col-right two"
          }, [_vm._v(" 26-12-2023 ")]), _c('td', {
            staticClass: "dialer-col-right three"
          }, [_vm._v("General")]), _c('td', {
            staticClass: "dialer-col-right four"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link",
              "toggle-class": "text-decoration-none tb-notes-dd",
              "no-caret": "",
              "menu-class": "notes-table-dd"
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#"
            }
          }, [_vm._v("Action")])], 1)], 1)]);
        });
      },
      proxy: true
    }], null, false, 1180729636)
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead one pt-1"
  }, [_vm._v("Name")]), _c('th', {
    staticClass: "HrmTableHead two pt-1"
  }, [_vm._v("date")]), _c('th', {
    staticClass: "HrmTableHead three pt-1"
  }, [_vm._v("groups")]), _c('th', {
    staticClass: "HrmTableHead four pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1)] : _vm._e(), _c('modal', {
    staticClass: "dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll",
    attrs: {
      "name": "hrmAddNewPublicHolidayModal"
    }
  }, [_c('div', [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Add public holiday")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('hrmAddNewPublicHolidayModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container mt-25px"
  }, [_c('label', [_vm._v("Date")]), _c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('input', {
    staticClass: "withIconOnRight primaryBorder noInnerBoxShadow"
  }), _c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  })], 1)]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Repeat every year ")]), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Name")]), _c('input')]), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Allowance count setting")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Allowance count setting ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Allowance count setting")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" General ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Sick ")])], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Create ")])], 1)])]), _c('modal', {
    staticClass: "dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll",
    attrs: {
      "name": "hrmImportPublicHolidayModal"
    }
  }, [_c('div', [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Import public holidays")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('hrmImportPublicHolidayModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Country")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Country ")])], 1)], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Import ")])])])])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }