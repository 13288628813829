var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup EditIndividualAllowanceModal HrmForModalScroll",
    attrs: {
      "name": "EditIndividualAllowanceModal",
      "clickToClose": true,
      "scrollable": true
    }
  }, [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Edit Individual allowances")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('EditIndividualAllowanceModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Location")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Team")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Time off policy")]), _c('b-dropdown', {
    staticClass: "withTags-hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "cancaleAbleTags-container"
        }, [_c('div', {
          staticClass: "cancaleAbleTags"
        }, [_c('div', {
          staticClass: "cancaleAbleTags-text"
        }, [_vm._v("Holiday")]), _c('div', {
          staticClass: "cancaleAbleTags-icon"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "cancaleableTags-x-hrmIcon"
          }
        })], 1)])]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Holiday ")])], 1)], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Genrate ")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }