<template>
  <modal 
    class="dialer_animation right_side_popup m-w-500 MOHPlaylistFilesModal min-width-50percent-modal" 
    width="50%" 
    height="auto" 
    :scrollable="true" 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose($event)" 
    @closed="onClosed($event)"
  >
    <div class="dialer-assign-to dialer-edit">
      <div v-if="getIsMobile" class="dialer-edit-header flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center mb-2">
          <!-- <h2 class="dialer-edit-title">Playlist Files</h2> -->
          <h2 class="dialer-edit-title">{{forms.update_label.label}}</h2>
          <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="api.delete_playlist.send ? '' : $modal.hide(modalName)" />
        </div>
      </div>
      <div v-else class="dialer-edit-header d-block">
        <div class="d-flex align-items-center justify-content-between flex-fill">
          <!-- <h2 class="dialer-settings-title newer mb-0">Playlist Files</h2> -->
          <template v-if="api.playlist.send && !playlist">
            <div class="latestShimmerDesign" style="height: 32px;width: 50%"></div>
          </template>
          <h2 v-else class="dialer-settings-title newer mb-0">{{forms.update_label.label}}</h2>
          <div class="dialer-edit-actions">
            <button 
              :disabled="api.delete_playlist.send" 
              class="newDoneButton" 
              @click="$modal.hide(modalName)"
            >
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <div class="vm--modal-text">
          <!--Select the sound files you would like to have in this playlist. You can change the order of selected sound file
          by dragging the sound file name in desired order. If you want to add a sound file you can do this at the 
          <!~~ <span class="text-primary">sound file library</span> ~~>
          sound file library-->
          Below is a list of all the audio files within this playlist. You can modify the order, add, or remove music files.
        </div>
      </div>
      <!-- <vb-loading v-if="api.playlist.send && !playlist" /> -->
      <template v-if="api.playlist.send && !playlist">
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="latestShimmerDesign" style="height: 27px;width: calc(25% - 16px);"></div>
          <div class="latestShimmerDesign mt-2" style="height: 50px;width: 50%;"></div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row py-3 justify-content-start" v-for="n in 2" :key="n.id">
              <div class="latestShimmerDesign mr-4" style="height: 27px;width: calc(25% - 16px);"></div>
              <div class="latestShimmerDesign" style="height: 64px;width: 50%;"></div>
            </div>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="latestShimmerDesign mr-4" style="height: 27px;width: calc(25% - 16px);"></div>
              <div class="latestShimmerDesign" style="height: 34px;width: 60px;border-radius: 30px"></div>
            </div>
          </div>
        </div>
      </template>
      <template v-else>
        <div class="latestGreyBox-9-9-2023">
          <div class="latestGreyBox-heading-main">
            {{conditions.change_order ? 'Change order' : 'Sound files'}}
          </div>
          <div class="latestGreyBox-descriptionText" v-if="conditions.change_order">
            You can change the order of selected sound file by dragging the sound file name in desired order
          </div>
          <div class="d-flex justify-content-between align-items-end mt-20px customMOHeditButtonsRow">
            <div class="whiteBGinputWithGreyRoundedBorder wd-33-imp position-relative">
              <label>Name</label>
              <input 
                :disabled="api.delete_playlist.send || api.update_label.send || isSystem || conditions.change_order" 
                v-model="forms.update_label.label" 
                :placeholder="'Enter Name'" 
                class="w-100" 
                type="text" 
                @input="updatePlaylistLabel()" 
              />
              <p 
                v-if="(forms.update_label.submitted && $v.forms.update_label.label.$invalid) || api.update_label.validation_errors.text" 
                class="text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.update_label.label.required">* Name is required</span>
                <span v-for="(em,i) in api.update_label.validation_errors.text" :key="i">{{em}}</span>
              </p>
            </div>
            <div class="d-flex">
              <button 
                v-if="conditions.change_order" 
                class="newDoneButton"
                :disabled="api.delete_playlist.send || api.change_order.send"  
                @click="conditions.change_order=!conditions.change_order" 
                type="button"
              >
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart">Done</span>
              </button>
              <template v-else>
                <button 
                  v-if="!isSystem && selectedFiles.length!=0" 
                  :disabled="api.delete_playlist.send || api.change_order.send" 
                  @click="conditions.change_order=!conditions.change_order" 
                  class="newCloseButton mr-12px"
                  :class="{
                    'chnaging-order': conditions.change_order,
                  }"
                >
                  <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Change order</span>
                </button>
              </template>
              <button 
                v-if="!isSystem && !conditions.change_order" 
                class="newButton"
                :disabled="api.delete_playlist.send"  
                @click="$modal.show(`${modalName}-Sounds`,{
                  id: data.playlist_id,
                  account: data.accountcode
                })" 
                type="button"
              > 
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Add/Remove music</span>
              </button>
            </div>
          </div>
          <div v-if="selectedFiles.length==0" class="latestGreyBox-9-9-2023 mb-32px">
            <div class="emptyScreenContainer">
              <img class="mt-50px w-50 h-auto" :src="require('@/assets/images/emptyScreenImages/playlistFilesInMOH.png')"/>
              <div class="emptyScreenContainer-heading">
                Start creating your playlist
              </div>
              <div class="emptyScreenContainer-text w-75">
                Our playlist is empty, but it's easy to get started. Add your favorite tracks and create the perfect soundtrack for your day.
              </div>
              <button class="dialer-button dialer-button-primary mt-20px">
                <!-- <b-icon icon="plus-lg" class="mr-3" /> -->
                Add tracks
              </button>
            </div>
          </div>
          <Container class="whiteBoxWithBorderInsideGreyBox mt-20px" v-if="conditions.change_order" @drop="changeOrder($event.removedIndex,$event.addedIndex)">
            <Draggable v-for="(element,index) in selectedFiles" :key="index">
              <div class="draggable-item">
                <li
                  class="list-group-item dialer-assignee bg-transparent d-flex"
                  :id="`${element.id}&&${element.real_id}&&${element.label}&&${element.sound}&&${element.sound_file}`"
                >
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <span class="dialer-assignee-name font-weight-700 wd-30" style="">{{ element.label | check_empty('None') }}</span>
                    <vb-audio-player class="calc-100-32px w-50" :src="element.sound_file" />
                    <div class="d-flex align-items-center">
                      <b-icon icon="arrows-move" class="mr-16px" font-scale="1.5" />
                      <b-spinner v-if="api.remove_file.send == element.id" label="spinner" variant="primary" class="md-mar-left" />
                      <a
                        v-else-if="selectedFiles.length>1"
                        v-b-tooltip.hover
                        title="remove to playlist"
                        @click="api.delete_playlist.send ? '' : removeFile(element.id)"
                        class="md-mar-left"
                      >
                        <vb-icon icon="dialer-delete-icon-filled" class="dialer-delete-icon-filled" height="19.995" width="16" />
                      </a>
                    </div>
                  </div>
                </li>
              </div>
            </Draggable>
          </Container>
          <template v-else>
            <div v-if="selectedFiles.length > 0" class="whiteBoxWithBorderInsideGreyBox mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4" v-for="file in selectedFiles" :key="file.real_id">
                <div class="d-flex flex-column">
                  <div class="latestGreyBox-heading-main">{{ file.label }}</div>
                  <!-- <div class="latestGreyBox-descriptionText-subText">From library</div> -->
                  <!-- <div class="latestGreyBox-descriptionText-subText">Shared with you</div> -->
                  <!-- <div class="latestGreyBox-descriptionText-subText">Uploaded by you</div> -->
                </div>
                <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again Width30minus8px minWidth200px" :src="file.sound_file" />
              </div>
            </div>
          </template>
          <template>
            <div v-if="!forms.change_default.default" :class="`${!forms.change_default.default && !conditions.change_order ? 'whiteBoxWithBorderInsideGreyBox' : ''} mt-20px`">
              <div v-if="!conditions.change_order && selectedFiles.length>0" class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
                <div class="latestGreyBox-heading-main">Set this playlist as default music on hold for the organization</div>
                <b-checkbox class="newerSwitch ml-3"
                  switch
                  v-if="selectedFiles.length>0"
                  :disabled="api.change_default.send"
                  :checked="forms.change_default.default" 
                  @change="changeDefault()"
                  ref="change-default"
                ></b-checkbox>
              </div>
              <div v-if="!conditions.change_order && !isSystem" class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3">
                <div class="latestGreyBox-heading-main">Delete this playlist?</div>
                <button class="newDeleteButton" :disabled="api.delete_playlist.send" @click="deletePlayList()">
                  <vb-icon icon="squared-delete-icon" height="38px" width="38px" />
                  <span class="newDeleteButton-textPart">
                    <vb-spinner v-if="api.delete_playlist.send" />
                    <span v-else>Delete</span>
                  </span>
                </button>
              </div>
            </div>
          </template>
        </div>
        <!-- <div v-if="!conditions.change_order && selectedFiles.length>0" class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4">
              <div class="latestGreyBox-heading-main">Set this playlist as default music on hold for the organization</div>
              <b-checkbox class="newerSwitch ml-3"
                switch
                v-if="selectedFiles.length>0"
                :disabled="api.change_default.send"
                :checked="forms.change_default.default" 
                @change="changeDefault()"
                ref="change-default"
              ></b-checkbox>
            </div>
          </div>
        </div>
        <div v-if="!conditions.change_order && !isSystem && !forms.change_default.default" class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset py-3">
              <div class="latestGreyBox-heading-main">Delete this Playlist?</div>
              <button class="dialer-button dialer-button-delete-alt" :disabled="api.delete_playlist.send" @click="deletePlayList()">
                <vb-spinner v-if="api.delete_playlist.send" />
                Delete
              </button>
            </div>
          </div>
        </div> -->
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        
        <!-- label -->
        <!--<div class="dialer-box grayBox dialer-assigned flex-column lg-mar-top">
          <div class="mt-0 d-flex align-items-center">
            <div class="whiteBGinputWithGreyRoundedBorder w-50 position-relative">
              <label>Name</label>
              <input :disabled="api.delete_playlist.send || api.update_label.send || isSystem" v-model="forms.update_label.label" :placeholder="'Enter Name'" class="w-100" type="text" @input="updatePlaylistLabel()" />
              <p 
                v-if="(forms.update_label.submitted && $v.forms.update_label.label.$invalid) || api.update_label.validation_errors.text" 
                class="text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.update_label.label.required">* Name is required</span>
                <span v-for="(em,i) in api.update_label.validation_errors.text" :key="i">{{em}}</span>
              </p>
            </div>
            <div v-if="selectedFiles.length>0" class="whiteBGinputWithGreyRoundedBorder d-flex justify-content-end align-items-center w-50 position-relative">
              <label>Set as default</label>
              <b-checkbox class="newerSwitch ml-3"
                switch
                v-if="selectedFiles.length>0"
                :disabled="api.change_default.send"
                :checked="forms.change_default.default" 
                @change="changeDefault()"
                ref="change-default"
              ></b-checkbox>
            </div>
          </div>
        </div>-->
        <!--<template v-if="selectedFiles.length==0">
          <div class="latestGreyBox-9-9-2023 mb-32px">
            <div class="emptyScreenContainer">
              <img class="mt-50px w-50 h-auto" :src="require('@/assets/images/emptyScreenImages/playlistFilesInMOH.png')"/>
              <div class="emptyScreenContainer-heading">
                Start Creating Your Playlist
              </div>
              <div class="emptyScreenContainer-text w-75">
                Our playlist is empty, but it's easy to get started. Add your favorite tracks and create the perfect soundtrack for your day.
              </div>
              <button class="dialer-button dialer-button-primary mt-20px">
                <!~~ <b-icon icon="plus-lg" class="mr-3" /> ~~>
                Add Tracks
              </button>
            </div>
          </div>
        </template>
        <div v-else class="dialer-box grayBox dialer-assigned flex-column lg-mar-top">
          <h3 class="dialer-box-header mb-0">Following are the audio files present in the current MOH playlist.</h3>
          <div class="d-flex justify-content-end mb-4 mt-5 w-100" v-if="!isSystem">
            <button class="dialer-button dialer-button-primary" v-if="!isSystem" @click="$modal.show(`${modalName}-Sounds`,{id:data.playlist_id,account:data.accountcode})" type="button">
              Add/Remove Sound Files
            </button>
          </div>
          
          <div class="innerWhiteBoxwithRoundedBorder mt-20px">
            <div class="mb-3" v-for="file in selectedFiles" :key="file.real_id">
              <div class="d-flex align-items-center w-100 justify-content-between">
                <div class="dialer-assignee-name font-weight-700 mr-4 flex-fill">{{ file.label }}</div>
                <vb-audio-player class="whiteBGaudioWithGreyRoundedBorder-again Width30minus8px minWidth200px" :src="file.sound_file" />
              </div>
            </div>
            <vb-no-record v-if="isEmpty(selectedFiles)" :text="api.playlist.send ? '' : 'there is no selected recording list'" :design="3">
              <vb-loading v-if="api.playlist.send" slot="loading" />
            </vb-no-record>
          </div>
        </div>-->

        <!--<div class="dialer-tabs dialer-box grayBox"> 
          <div class="md-mar-vertical">
            <div class="dialer-team-name mb-4">
              <div class="dialer-input-field input-consistent-inner-shadows">
                <div class="w-100">
                  <label>Name</label>
                  <input :disabled="api.delete_playlist.send || isSystem" v-model="forms.update_label.label" :placeholder="'Enter Name'" class="w-100" type="text" />
                </div>
                <p v-if="forms.update_label.submitted && $v.forms.update_label.label.$invalid" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.update_label.label.required">* Name is required</span>
                </p>
              </div>
            </div>
          </div>
          <div class="dialer-tab-content dialer-box grayBox">
            <ul class="list-unstyled dialer-asssignee-list tun">
              <div>Following are the audio files present in the current MOH playlist.</div>
              <div class="w-100 d-flex justify-content-end align-items-center my-3" v-if="!isSystem">
                <button class="dialer-button dialer-button-primary ml-auto" v-if="!isSystem" @click="$modal.show(`${modalName}-Sounds`,{id:data.playlist_id,account:data.accountcode})" type="button">
                  Add/Remove Sound Files
                </button>
              </div>
              <li class="dialer-assignee" v-for="file in selectedFiles" :key="file.real_id">
                <div class="d-flex align-items-center justify-content-between w-100">
                  <span class="dialer-assignee-name font-weight-700 wd-30">{{ file.label }}</span>
                  <vb-audio-player class="calc-100-32px w-50" :src="file.sound_file" />
                </div>
              </li>
            </ul>
            <vb-no-record v-if="isEmpty(selectedFiles)" :text="api.playlist.send ? '' : 'there is no selected recording list'" :design="3">
              <vb-loading v-if="api.playlist.send" slot="loading" />
            </vb-no-record>
          </div>
          <!~~<template v-else>
            <div class="dialer-tab-headers">
              <div
                class="dialer-tab text-capitalize"
                :class="{
                  'selected': selected.tab==tab.value
                }"
                v-for="tab in tabs"
                :key="tab.value"
                @click="selected.tab = tab.value">
                {{ tab.text }}
              </div>
            </div>
            <div class="simple-page" v-if="selected.tab==tabs.playlistfiles.value">
              <Container @drop="changeOrder($event.removedIndex,$event.addedIndex)">
                <Draggable v-for="(element,index) in selectedFiles" :key="index">
                  <div class="draggable-item">
                    <li
                      class="list-group-item dialer-assignee bg-transparent d-flex"
                      :id="`${element.id}&&${element.real_id}&&${element.label}&&${element.sound}&&${element.sound_file}`"
                    >
                      <div class="d-flex justify-content-between align-items-center w-100">
                        <span class="dialer-assignee-name font-weight-700 wd-30" style="">{{ element.label | check_empty('None') }}</span>
                        <vb-audio-player class="calc-100-32px w-50" :src="element.sound_file" />
                        <b-spinner v-if="api.remove_file.send == element.id" label="spinner" variant="primary" class="md-mar-left" />
                        <a
                          v-else-if="selectedFiles.length>1"
                          v-b-tooltip.hover
                          title="remove to playlist"
                          @click="api.delete_playlist.send ? '' : removeFile(element.id)"
                          class="md-mar-left"
                        >
                          <vb-icon icon="dialer-delete-icon-filled" class="dialer-delete-icon-filled" height="19.995" width="16" />
                        </a>
                      </div>
                    </li>
                  </div>
                </Draggable>
              </Container>
              <vb-no-record v-if="selectedFiles.length==0" :text="api.playlist.send?'':'There is no playlist'" :design="3">
                <vb-loading v-if="api.playlist.send" slot="loading" />
              </vb-no-record>
            </div>
            <div class="dialer-tab-content " v-if="selected.tab==tabs.editplaylist.value">
              <ul class="list-unstyled dialer-asssignee-list">
                <li class="dialer-assignee" v-for="(item, key) in availableFiles" :key="key">
                  <div class="d-flex justify-content-between align-items-center w-100">
                    <span class="dialer-assignee-name font-weight-700 wd-30">{{ item.label }}</span>
                      <vb-audio-player class="calc-100-32px w-50" :src="item.sound_file" />
                      <b-spinner v-if="api.add_file.send == item.id" label="spinner" variant="primary" class="md-mar-left" />
                      <a v-else v-b-tooltip.hover title="add to playlist" class="md-mar-left" @click="api.delete_playlist.send ? '' : addFile(item.id)">
                        <b-icon icon="plus" variant="primary" scale="1.5" />
                      </a>
                    <!~~ </div> ~~>
                  </div>
                </li>
                <vb-no-record v-if="isEmpty(availableFiles)" :design="3" :text="api.playlist.send ? '' : 'Sorry, No Data Founding list'">
                  <vb-loading v-if="api.playlist.send" slot="loading" />
                </vb-no-record>
              </ul>
            </div>
          </template>~~>
          
        </div>-->
        
        
        
        <!-- <div v-if="selectedFiles.length>0" class="latestGreyBox-9-9-2023 mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row">
              <div class="latestGreyBox-heading-main">Set as default</div>
              <b-checkbox class="newerSwitch ml-3"
                switch
                v-if="selectedFiles.length>0"
                :disabled="api.change_default.send"
                :checked="forms.change_default.default" 
                @change="changeDefault()"
                ref="change-default"
              ></b-checkbox>
            </div>
          </div>
        </div> -->
        
      </template>
      <EditPlaylistSoundsModal :modalName="`${modalName}-Sounds`" @order-changed="fetchPlaylists()" @files-updated="fetchPlaylists()" />
    </div>
  </modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import { Container, Draggable } from "vue-smooth-dnd";
import EditPlaylistSoundsModal from './EditPlaylistSoundsModal.vue';
import _ from 'lodash';
const tabs = {
  playlistfiles: {
    text: 'Selected Files',
    value: 'playlistfiles',
  },
  editplaylist: {
    text: 'Add to list',
    value: 'editplaylist',
  },
}
export default {
  name: "EditPlaylistModal",
  components: {
    Container,
    Draggable,
    EditPlaylistSoundsModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'EditPlaylist',
    },
  },
  inject:['isEmpty','appNotify'],
  data() {
    return {
      forms: {
        update_label: this.$helperFunction.formInstance({
          data: {
            label: '',
          },
        }),
        change_default: this.$helperFunction.formInstance({
          data: {
            default: false,
          },
        }),
      },
      api: {
        delete_playlist: this.$helperFunction.apiInstance({  }),
        playlist: this.$helperFunction.apiInstance({
          source: true,
        }),
        update_label: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        }),
        change_default: this.$helperFunction.apiInstance({  }),
        add_file: this.$helperFunction.apiInstance({  }),
        remove_file: this.$helperFunction.apiInstance({  }),
        change_order: this.$helperFunction.apiInstance({  }),
      },
      response: {
        playlists: {},
      },
      data: {
        playlist_id: '',
        accountcode: '',
      },
      selected: {
        tab: tabs.playlistfiles.value
      },
      conditions: {
        change_order: false
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
    ]),
    tabs(){ return tabs },
    playlist(){ return this.response.playlists?.playlists?.data?.find(i=>i.id==this.data.playlist_id); },
    isSystem(){ return this.playlist?.type=='system' },
    selectedFiles(){ 
      // return this.playlist?.playlist?.map?.(i=>({
      //   id: i?.sound_file.id,
      //   label: i.sound_file?.sound_name,
      //   real_id: i.id,
      //   sound: i.sound_file?.sound_file,
      //   sound_file: i.sound_file?.sound
      // })) ?? [] 
      return Object.values(this.response.playlists?.selected?.data ?? {}).map?.(i=>({
        id: i?.id,
        label: i?.label,
        real_id: i.real_id,
        sound: i?.sound,
        sound_file: i.sound_file
      })) ?? []
    },
    // availableFiles(){ return Object.values(this.response.playlists?.available?.data ?? {}) },
  },
  validations: {
    forms: {
      update_label: {
        label: {
          required,
        },
      },
    },
  },
  watch: {
    playlist(playlist){
      this.forms.change_default.default=playlist?.default==1
      this.forms.update_label.label=playlist?.label ?? ''
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.playlist_id = event?.params?.id
      this.data.accountcode = event?.params?.account;
      this.response.playlists={}
      this.fetchPlaylists()
    },
    onBeforeClose(){
      this.forms.update_label.reset()
      this.api.delete_playlist.reset()
      this.api.update_label.reset()
      this.api.change_default.reset()
      this.api.add_file.reset()
      this.api.remove_file.reset()
      this.api.change_order.reset()
      this.response.playlists={}
      this.data.playlist_id=''
      this.data.accountcode=''
      this.selected.tab=tabs.playlistfiles.value
      this.conditions.change_order=false
    },
    onClosed() {
      this.$emit("close");
    },
    updatePlaylistLabel: _.debounce(async function () {
      this.forms.update_label.submitted=true
      this.$v.forms.update_label.$touch()
      if(this.api.update_label.send || this.$v.forms.update_label.$invalid) return;
      try {
        this.api.update_label.send=true
        this.api.update_label.validation_errors={}
        await VOIP_API.endpoints.music_on_hold.changeLabel(this.data.playlist_id,{
          playlist_id: this.data.playlist_id,
          text: this.forms.update_label.label,
        })
        this.appNotify({
          message: 'Successfully Updated',
          type: 'succcess',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        this.api.update_label.validation_errors=ex.own_errors
      } finally {
        this.forms.update_label.submitted=false
        this.api.update_label.send=false
      }
    }, 2*1000),
    async changeDefault() {
      let vm = this
      if(vm.api.change_default.send) return;
      vm.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to set this playlist as default.`,
        button: {
          no: "no",
          yes: "yes",
        },
        async callback({confirm}) {
          try {
            if(!confirm) return;
            vm.api.change_default.send = true;
            await VOIP_API.endpoints.music_on_hold.updateplaylist(vm.data.playlist_id, {
              flag: "changedefault",
              type: 'admin',
              fid: 12,
              account: vm.data.accountcode,
            });
            vm.forms.change_default.default=!vm.forms.change_default.default
            vm.appNotify({
              message: 'Successfully Updated!',
              type: 'success',
            })
          } catch(ex) {
            vm.appNotify({
              message: ex.own_message,
              type: 'danger',
            })
          } finally {
            vm.$refs['change-default'].localChecked = vm.forms.change_default.default
            vm.api.change_default.send = false;
          }
        },
      });
    },
    async fetchPlaylists() {
      this.api.playlist.source?.cancel?.("Requesting Again");
      this.api.playlist.source = require("axios").default.CancelToken.source()
      try {
        this.api.playlist.send = true;
        const response = await VOIP_API.endpoints.music_on_hold.playlistfiles(this.data.playlist_id, {
          account: this.data.accountcode,
        },this.api.playlist.source.token);
        this.response.playlists=response.data
      } finally {
        this.api.playlist.send = false;
      }
    },
    async addFile(id) {
      if (!id || this.api.add_file.send) return;
      try {
        this.api.add_file.send=true
        const { data } = await VOIP_API.endpoints.music_on_hold.playlistaddfile({
          playlistId: this.data.playlist_id,
          soundId: id,
          fid: 12,
          account: this.data.accountcode,
          flag: "app",
        })
        this.response.playlists=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.add_file.send=false
      }
    },
    async removeFile(id) {
      if (!id || this.api.remove_file.send) return;
      try {
        this.api.remove_file.send=true
        const { data } = await VOIP_API.endpoints.music_on_hold.playlistremovefile({
          playlistId: this.data.playlist_id,
          soundId: id,
          fid: 12,
          account: this.data.accountcode,
          flag: "app",
        })
        this.response.playlists=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.remove_file.send=false
      }
    },
    async changeOrder(removedIndex,addedIndex){
      if(removedIndex == addedIndex) return false;
      const element = JSON.parse(JSON.stringify(this.selectedFiles[removedIndex]))
      const toaster = this.appNotify({
        message: 'Saving...',
        type: 'info',
      })
      try {
        this.api.change_order.send=true
        const { data } = await VOIP_API.endpoints.music_on_hold.updateplaylistorder({
          playlist: this.data.playlist_id,
          element: element,
          current: removedIndex,
          future: addedIndex,
        })
        this.response.playlists.selected.data=data?.selected?.data ?? []
        this.appNotify({
          message: 'Successfully Order Changed',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        toaster.close()
        this.api.change_order.send=false
      }
    },
    deletePlayList() {
      let vm = this
      if (vm.api.delete_playlist.send) return;
      vm.$appConfirmation({
        title: "Confirm",
        message: `Are you sure you want to delete this playlist?`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback({confirm}) {
          if (confirm) {
            vm.api.delete_playlist.send=true;
            VOIP_API.endpoints.music_on_hold.delete({
              id: vm.data.playlist_id
            })
            .then(() => {
              vm.appNotify({
                message: "Playlist Deleted Successfully.",
                type: "success",
              })
              vm.$emit('updated')
              vm.$modal.hide(vm.modalName)
            })
            .catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(() => {
              vm.api.delete_playlist.send=false;
            });
          }
        },
      
      });
    },
  }
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
