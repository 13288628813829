<template>
  <div class="content-area d-flex h-100 dialer_main_body forTODOList-main">
    <div class="dialer_main_body_inner w-100">
      <div :class="`dialer_inner_left_side forTODOList-sidebar d-r-768px-none ${conditions.show_side_bar?'open':''}`">
        <h2 v-if="getIsMobile" class="dialer_main_title d-flex justify-content-between align-items-center">
          Task hub
          <div @click="conditions.show_side_bar=false">
            <b-icon icon="x-lg" class="cursor_pointer" />
          </div>
        </h2>
        <h2 v-else class="dialer_main_title d-flex justify-content-between align-items-center">
          Task hub
          <div class="showOnlyOnSmallerScreens" @click="conditions.show_side_bar=false">
            <b-icon icon="x" font-scale="1.5"  class="cursor_pointer" />
          </div>
        </h2>
        <div class="dialer_inner_left_side_inner allow-scroll">
          <ul class="list-unstyled">
            <li 
              v-for="flag in topFlags" 
              :key="flag.value" 
              :class="`dialer_tab_btn ${selected.flag == flag.value?'active':''}`" 
              @click="selected.flag = flag.value"
            >
              <a>
                <span class="dialer-settingPanel-icon">
                  <vb-icon :icon="flag.icon" width="19.996" height="20" />
                </span>
                <span class="dialer-settingPanel-text"> {{ flag.text }} </span>
              </a>
            </li>
          </ul>
          <h2 class="dialer_main_title d-flex align-items-center">
            <vb-icon icon="todo-allSpaces-icon" class="mr-2" width="23.725px" height="22.076px" />
            Spaces
          </h2>
          <ul class="list-unstyled TODOListVersion">
            <li 
              v-for="(space, space_id) in response.spaces" 
              :key="space_id" 
              :class="`dialer_tab_btn ${selected.space_ids.includes(space_id)?'active':''}`" 
              @click="
                selected.space_ids.includes(space_id) 
                  ? selected.space_ids.splice(selected.space_ids.indexOf(space_id),1) 
                  : selected.space_ids.push(space_id)
              "
            >
              <a>
                <span class="dialer-settingPanel-icon">
                  <div class="smallColoredTag twentyPX ml-0" :style="`background-color: ${space.color};`"></div>
                </span>
                <span class="dialer-settingPanel-text"> {{ space.name }} </span>
                <div class="d-flex align-items-center color-white">
                  <b-icon 
                    v-if="space.status!='private' && space.created_by==getCurrentUser.account" 
                    icon="trash" variant="danger"
                    class="ml-2 darker-chevron-down withoutPath makeItRed showThisSpecificallyOnlyOnHover" 
                    @click.stop="removeSpaces(space)" 
                  />
                  <b-icon 
                    v-if="space.status=='private' && space.type!='public' ? getCurrentUser.administrator_account : space.created_by==getCurrentUser.account" 
                    icon="pencil-square" 
                    class="ml-2 darker-chevron-down withoutPath showThisSpecificallyOnlyOnHover" 
                    @click.stop="$modal.show('EditSpace',{
                      space_id: space_id
                    })" 
                  />
                  <b-icon class="darker-chevron-down withoutPath" :style="`opacity: ${selected.space_ids.includes(space_id) ? 1 : 0}`" icon="check-lg" />
                </div>
              </a>
            </li>
            <vb-no-record v-if="response.spaces.length==0" :text="api.spaces.send ? '' : 'There is no category'" :design="3" />
            <template v-if="response.spaces.length==0 && api.spaces.send">
              <li class="w-100 mb-3 px-4" v-for="n in 3" :key="n.id">
                <div class="latestShimmerDesign mt-2" style="height:32px;width:100%;"></div>
              </li>
            </template>
            <li class="dialer_tab_btn" @click="$modal.show('AddTaskSpace')">
              <a class="">
                <span class="dialer-settingPanel-icon">
                  <b-icon icon="plus-lg"  />
                </span>
                <span class="dialer-settingPanel-text TODO-smallInnerHeading"> New space </span>
              </a>
            </li>
          </ul>
        </div>
        <ul class="list-unstyled archivedList-container">
          <li 
            v-for="flag in bottomFlags" 
            :key="flag.value" 
            :class="`dialer_tab_btn ${selected.flag == flag.value?'active':''}`" 
            @click="selected.flag == flag.value ? selected.flag = 'all' : selected.flag = flag.value"
          >
            <!-- @click="selected.flag = flag.value" -->
            <a>
              <span class="dialer-settingPanel-icon">
                <vb-icon :icon="flag.icon" width="19.996" height="20" />
              </span>
              <span class="dialer-settingPanel-text"> {{ flag.text }} </span>
            </a>
          </li>
        </ul>
      </div>
      <div class="dialer_inner_right_side">
        <div v-if="selected.space_ids.length==0" class="dialer-settings-main">
          <section v-if="getIsMobile" class="dialer-settings-section locationSettings">
            <div class="d-flex align-items-center mb-3 calc-title-width">
              <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer" @click="conditions.show_side_bar=true" />
              <h2 class="dialer-settings-title w-100 mb-0 text-center flex-1">My tasks</h2>
            </div>
            <div>Please select a space in order to see your list of tasks</div>
          </section>
          <section v-else class="dialer-settings-section locationSettings newTasksModule">
            <div>
              <div class="dialer-flex">
                <h2 class="dialer-settings-title d-flex align-items-center">
                  <vb-icon icon="latest-sideBarOpenIconForTodoInMobileView-icon" width="19.979px" height="16px" class="settings-back-icon cursor_pointer showOnlyOnSmallerScreens mr-12px" style="" @click="$emit('openSideBar')" />
                  My tasks
                </h2>
              </div>
              <div class="latestGreyBox-9-9-2023 mt-32px">
                <div class="emptyScreenContainer">
                  <img class="mt-12px wd-30 h-auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/chat.png')"/>
                  <img class="mt-12px wd-30 h-auto" v-else :src="require('@/assets/images/emptyScreenImages/chat.png')"/>
                  <div class="emptyScreenContainer-heading">
                    Select a space to view tasks
                  </div>
                  <div class="emptyScreenContainer-text w-75">
                    Seamlessly manage tasks, assign call-related actions, and track priorities within our task module. Streamline your workflow for enhanced productivity.
                  </div>
                </div>
              </div>
            </div>
          </section>
        </div>
        <NewTasks :ref="`${_uid}-tasks`" v-else :flag="selected.flag" @openSideBar="conditions.show_side_bar=true" :spaces="selectedSpaces" @imported="getSpaces()" />
      </div>
    </div>
    <AddTaskSpaceModal @created="getSpaces()" />
    <EditSpaceModal 
      @update-list="getSpaces()" 
      @group-update="$refs[`${_uid}-tasks`].fetchGroupsAndStatuses()"
      @group-create="$refs[`${_uid}-tasks`].fetchGroupsAndStatuses()"
      @group-remove="$refs[`${_uid}-tasks`].fetchGroupsAndStatuses()"
      @status-update="$refs[`${_uid}-tasks`].fetchGroupsAndStatuses()"
      @status-create="$refs[`${_uid}-tasks`].fetchGroupsAndStatuses()"
      @status-remove="$refs[`${_uid}-tasks`].fetchGroupsAndStatuses()"
    />
    <SelectSpaceGroupAndStatusModal :modalName="`TodoTaskMove`" @moved="$refs[`${_uid}-tasks`].getTasks(true)" />
  </div>
</template>

<script>  
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils';
import AddTaskSpaceModal from '@/components/Modals/Todo/AddTaskSpaceModal.vue';
import EditSpaceModal from '@/components/Modals/Todo/EditSpaceModal.vue';
import NewTasks from '@/components/Todo/NewTasks.vue';
import SelectSpaceGroupAndStatusModal from '@/components/Modals/Todo/SelectSpaceGroupAndStatusModal.vue';
import _ from 'lodash'
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
let flags = {
  all: {
    text: 'Tasks',
    icon: 'todo-tasks-icon',
    value: 'all',
    position: 'top',
  },
  my_day: {
    text: 'My day',
    icon: 'todo-myday-icon',
    value: 'my_day',
    position: 'top',
  },
  important: {
    text: 'Important',
    icon: 'todo-important-icon',
    value: 'important',
    position: 'top',
  },
  planned: {
    text: 'Planned',
    icon: 'todo-planned-icon',
    value: 'planned',
    position: 'top',
  },
  assign_to_me: {
    text: 'Assign to me',
    icon: 'todo-assignedtome-icon',
    value: 'assign_to_me',
    position: 'top',
  },
  task_viewer: {
    text: 'Task viewer',
    icon: 'eye-fill',
    value: 'task_viewer',
    position: 'top',
  },
  archived: {
    text: 'Archived',
    icon: 'eye-fill',
    value: 'archived',
    position: 'bottom',
  },
}
export default {
  name: "Todo",
  components: {
    AddTaskSpaceModal,
    NewTasks,
    EditSpaceModal,
    SelectSpaceGroupAndStatusModal,
  },
  data() {
    return {
      selected: {
        space_ids: [],
        flag: flags.all.value,
      },
      api: {
        spaces: this.$helperFunction.apiInstance({  }),
        remove_space: this.$helperFunction.apiInstance({  }),
      },
      response: {
        spaces: {},
      },
      conditions: {
        show_side_bar: false,
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
    topFlags(){ return Object.values(flags).filter(i=>i.position=='top') },
    bottomFlags(){ return Object.values(flags).filter(i=>i.position=='bottom') },
    selectedFlags(){ return flags[this.selected.flag] },
    selectedSpaces(){ return _.keyBy(this.selected.space_ids.map(id=>this.response.spaces[id]),'id') },
  },
  methods: {
    async getSpaces() {
      if(this.api.spaces.send) return;
      try {
        this.api.spaces.send=true
        const { data=[] } = await VOIP_API.endpoints.task_spaces.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        })
        this.response.spaces=_.keyBy(data,'id')
        const space_ids = await Object.keys(this.response.spaces)
        const difference = _.difference(this.selected.space_ids, space_ids)
        this.selected.space_ids = _.difference(this.selected.space_ids, difference)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.spaces.send=false  
      }
    },
    async removeSpaces(space) {
      const space_id = space.id
      if(!space_id || this.api.remove_space.send) return;
      try {
        this.api.remove_space.send=space_id
        const { confirm } = await this.$appConfirmation({
          title: "WARNING",
          message: `Are you sure you want to delete?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.task_spaces.remove(space_id,{
          uid: this.getCurrentUser.uid
        })
        this.getSpaces()
        this.appNotify({
          message: 'Successfully Deleted',
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.remove_space.send=''
      }
    },
  },
  mounted () {
    this.getSpaces();
  },
  activated () {
    this.getSpaces();
  },
}
</script>

<style>

</style>