var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup viewPDFmodal full-width-big-modal",
    attrs: {
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex align-items-center justify-content-between px-0 pt-3"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newBackButton mr-16px",
    attrs: {
      "disabled": _vm.api.download.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Invoice details ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('b-button', {
    staticClass: "newButton mw-168px",
    attrs: {
      "disabled": _vm.api.download.send || _vm.api.invoice_detail.send
    },
    on: {
      "click": function ($event) {
        return _vm.downloadPDF();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-exportFile-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart d-flex justify-content-center position-relative align-items-center"
  }, [_vm.api.download.send ? _c('vb-spinner') : [_vm._v("Download invoice")]], 2)], 1)], 1)]), _vm.api.invoice_detail.send ? _c('div', [_c('div', {
    staticClass: "w-100 mb-32px p-4 invoiceContainer"
  }, [_c('table', {
    staticClass: "w-100",
    staticStyle: {
      "border": "0px solid black",
      "margin-bottom": "40px !important"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "border": "0px solid black"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "44.66px",
      "width": "196.08px"
    }
  })])])])]), _c('table', {
    staticClass: "w-100"
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "50%",
      "colspan": "2"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#000000"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "16px",
      "width": "80px"
    }
  })]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "16px",
      "width": "100px"
    }
  })]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "16px",
      "width": "100px"
    }
  })]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "16px",
      "width": "100px"
    }
  })]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "16px",
      "width": "100px"
    }
  })]), _vm._v(" "), _c('br')]), _c('td', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "width": "50%",
      "colspan": "2"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#000000"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign ml-auto",
    staticStyle: {
      "height": "16px",
      "width": "80px"
    }
  })]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign ml-auto",
    staticStyle: {
      "height": "16px",
      "width": "100px"
    }
  })]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign ml-auto",
    staticStyle: {
      "height": "16px",
      "width": "100px"
    }
  })]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign ml-auto",
    staticStyle: {
      "height": "16px",
      "width": "100px"
    }
  })]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign ml-auto",
    staticStyle: {
      "height": "16px",
      "width": "100px"
    }
  })]), _vm._v(" "), _c('br')])])])]), _c('table', {
    staticClass: "w-100",
    staticStyle: {
      "margin-top": "30px",
      "border-bottom": "2px solid #3699FF"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "padding-bottom": "7px"
    },
    attrs: {
      "width": "50%",
      "colspan": "2"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold",
      "line-height": "1.3",
      "color": "#000000"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "24px",
      "width": "200px"
    }
  })]), _c('br')]), _c('td', {
    staticStyle: {
      "text-align": "right",
      "padding-bottom": "7px"
    },
    attrs: {
      "width": "50%",
      "colspan": "2"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold",
      "line-height": "1.3",
      "color": "#000000"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign ml-auto",
    staticStyle: {
      "height": "16px",
      "width": "150px"
    }
  })]), _vm._v(" "), _c('br')])])])]), _c('table', {
    staticClass: "w-100",
    staticStyle: {
      "margin-top": "25px"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "18px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "50%"
    }
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_vm._v(" Subscription "), _c('div', {
    staticClass: "latestShimmerDesign ml-3",
    staticStyle: {
      "height": "16px",
      "width": "80px"
    }
  })])]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "18px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "16%"
    }
  }, [_vm._v("QUANTITY")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "18px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "16%"
    }
  }, [_vm._v("UNIT PRICE")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "18px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("TOTAL AMOUNT")])])]), _c('tbody', _vm._l(2, function (n) {
    return _c('tr', {
      key: n.id
    }, [_c('td', {
      staticStyle: {
        "text-align": "left",
        "font-size": "14px",
        "font-weight": "600",
        "line-height": "1.3",
        "color": "#000000",
        "padding-bottom": "16px",
        "padding-top": "12px",
        "border-bottom": "1px solid #E2E2E2"
      },
      attrs: {
        "width": "50%"
      }
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "16px",
        "width": "50%"
      }
    })]), _c('td', {
      staticStyle: {
        "text-align": "right",
        "font-size": "14px",
        "font-weight": "600",
        "line-height": "1.3",
        "color": "#000000",
        "padding-bottom": "16px",
        "padding-top": "12px",
        "border-bottom": "1px solid #E2E2E2"
      },
      attrs: {
        "width": "16%"
      }
    }, [_c('div', {
      staticClass: "latestShimmerDesign ml-auto",
      staticStyle: {
        "height": "16px",
        "width": "50%"
      }
    })]), _c('td', {
      staticStyle: {
        "text-align": "right",
        "font-size": "14px",
        "font-weight": "600",
        "line-height": "1.3",
        "color": "#000000",
        "padding-bottom": "16px",
        "padding-top": "12px",
        "border-bottom": "1px solid #E2E2E2"
      },
      attrs: {
        "width": "16%"
      }
    }, [_c('div', {
      staticClass: "latestShimmerDesign ml-auto",
      staticStyle: {
        "height": "16px",
        "width": "50%"
      }
    })]), _c('td', {
      staticStyle: {
        "text-align": "right",
        "font-size": "14px",
        "font-weight": "600",
        "line-height": "1.3",
        "color": "#000000",
        "padding-bottom": "16px",
        "padding-top": "12px",
        "border-bottom": "1px solid #E2E2E2"
      },
      attrs: {
        "width": "18%"
      }
    }, [_c('div', {
      staticClass: "latestShimmerDesign ml-auto",
      staticStyle: {
        "height": "16px",
        "width": "50%"
      }
    })])]);
  }), 0)]), _c('table', {
    staticClass: "w-100",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px"
    },
    attrs: {
      "width": "60%"
    }
  }), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Subtotal")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign ml-auto",
    staticStyle: {
      "height": "16px",
      "width": "50%"
    }
  })])]), _c('tr', [_c('th', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "9px",
      "padding-top": "9px"
    },
    attrs: {
      "width": "60%"
    }
  }), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "9px",
      "padding-top": "9px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-end align-items-center"
  }, [_vm._v(" Plus VAT "), _c('div', {
    staticClass: "latestShimmerDesign my-auto",
    staticStyle: {
      "height": "20px",
      "width": "40px"
    }
  })])]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "9px",
      "padding-top": "9px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign ml-auto",
    staticStyle: {
      "height": "16px",
      "width": "50%"
    }
  })])])]), _c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "font-size": "12px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#3699FF",
      "padding-bottom": "9px",
      "padding-top": "9px"
    },
    attrs: {
      "width": "60%"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-size": "12px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#3699FF",
      "padding-bottom": "9px",
      "padding-top": "9px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Total")]), _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-size": "12px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#3699FF",
      "padding-bottom": "9px",
      "padding-top": "9px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_c('div', {
    staticClass: "latestShimmerDesign ml-auto",
    staticStyle: {
      "height": "16px",
      "width": "50%"
    }
  })])])])]), _c('table', {
    staticClass: "w-100",
    staticStyle: {
      "margin-top": "40px"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "padding": "20px",
      "background-color": "#F8F8F8"
    }
  }, [_c('span', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000"
    }
  }, [_vm._v("Notes")]), _c('br'), _c('br'), _c('span', {
    staticStyle: {
      "text-align": "left",
      "font-size": "12px",
      "font-weight": "500",
      "line-height": "1.3",
      "color": "#000000",
      "padding-top": "16px"
    }
  })])])])])])]) : _c('div', {
    staticClass: "w-100 mb-32px p-4 invoiceContainer"
  }, [_c('table', {
    staticClass: "w-100",
    staticStyle: {
      "border": "0px solid black",
      "margin-bottom": "40px !important"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "border": "0px solid black"
    }
  }, [_c('img', {
    attrs: {
      "width": "196.08px",
      "height": "44.66px",
      "src": "https://www.voipbusiness.com/wp-content/uploads/2023/11/Group-46115.svg"
    }
  })])])])]), _c('table', {
    staticClass: "w-100"
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left"
    },
    attrs: {
      "width": "50%",
      "colspan": "2"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#000000"
    }
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoice_details.user_detail.companyname')))]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoice_details.user_detail.address')))]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoice_details.user_detail.city')))]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoice_details.user_detail.postcode')))]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoice_details.user_detail.countryname')))]), _vm._v(" "), _c('br')]), _c('td', {
    staticStyle: {
      "text-align": "right"
    },
    attrs: {
      "width": "50%",
      "colspan": "2"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#000000"
    }
  }, [_vm._v("VoIP Business")]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_vm._v("Suite 6, Beaufort Court, Admirals Way,")]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_vm._v("Date: 24 / 09 / 2022")]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_vm._v("E14 9XL London")]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_vm._v("United Kingdom")]), _vm._v(" "), _c('br'), _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoices.status')))]), _vm._v(" "), _c('br'), _vm.isUnPaid ? _c('span', {
    staticStyle: {
      "font-size": "12px",
      "font-weight": "bold",
      "line-height": "2",
      "color": "#7E7E7E"
    }
  }, [_vm._v("Due Date: " + _vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoices.invoice_due_date')))]) : _vm._e(), _vm._v(" "), _c('br')])])])]), _c('table', {
    staticClass: "w-100",
    staticStyle: {
      "margin-top": "30px",
      "border-bottom": "2px solid #3699FF"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "padding-bottom": "7px"
    },
    attrs: {
      "width": "50%",
      "colspan": "2"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold",
      "line-height": "1.3",
      "color": "#000000"
    }
  }, [_vm._v(" Invoice "), _c('span', {
    staticStyle: {
      "color": "#3699FF"
    }
  }, [_vm._v("VB-" + _vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoices.invoice_id')))])]), _c('br')]), _c('td', {
    staticStyle: {
      "text-align": "right",
      "padding-bottom": "7px"
    },
    attrs: {
      "width": "50%",
      "colspan": "2"
    }
  }, [_c('span', {
    staticStyle: {
      "font-size": "18px",
      "font-weight": "bold",
      "line-height": "1.3",
      "color": "#000000"
    }
  }, [_vm._v(_vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoices.invoice_due_date')))]), _vm._v(" "), _c('br')])])])]), _c('table', {
    staticClass: "w-100",
    staticStyle: {
      "margin-top": "25px"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "18px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "50%"
    }
  }, [_vm._v("Subscription")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "18px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "16%"
    }
  }, [_vm._v("QUANTITY")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "18px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "16%"
    }
  }, [_vm._v("UNIT PRICE")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "18px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "18%"
    }
  }, [_vm._v("TOTAL AMOUNT")])])]), _c('tbody', _vm._l(_vm.productDetail, function (item) {
    return _c('tr', {
      key: item.id
    }, [_c('td', {
      staticStyle: {
        "text-align": "left",
        "font-size": "14px",
        "font-weight": "600",
        "line-height": "1.3",
        "color": "#000000",
        "padding-bottom": "16px",
        "padding-top": "12px",
        "border-bottom": "1px solid #E2E2E2"
      },
      attrs: {
        "width": "50%"
      }
    }, [_c('span', [_vm._v(_vm._s(item.description))]), _c('br'), item.is_date_to != 'no' ? _c('span', [_vm._v(" (" + _vm._s(item.from_date_readable) + " to " + _vm._s(item.to_date_readable) + ")")]) : _vm._e()]), _c('td', {
      staticStyle: {
        "text-align": "right",
        "font-size": "14px",
        "font-weight": "600",
        "line-height": "1.3",
        "color": "#000000",
        "padding-bottom": "16px",
        "padding-top": "12px",
        "border-bottom": "1px solid #E2E2E2"
      },
      attrs: {
        "width": "16%"
      }
    }, [_vm._v(_vm._s(item.quantity))]), _c('td', {
      staticStyle: {
        "text-align": "right",
        "font-size": "14px",
        "font-weight": "600",
        "line-height": "1.3",
        "color": "#000000",
        "padding-bottom": "16px",
        "padding-top": "12px",
        "border-bottom": "1px solid #E2E2E2"
      },
      attrs: {
        "width": "16%"
      }
    }, [_vm._v(_vm._s(_vm._f("to_fixed")(item.unit_price)))]), _c('td', {
      staticStyle: {
        "text-align": "right",
        "font-size": "14px",
        "font-weight": "600",
        "line-height": "1.3",
        "color": "#000000",
        "padding-bottom": "16px",
        "padding-top": "12px",
        "border-bottom": "1px solid #E2E2E2"
      },
      attrs: {
        "width": "18%"
      }
    }, [_vm._v(_vm._s(_vm._f("to_fixed")(item.total_price)) + " ")])]);
  }), 0)]), _c('table', {
    staticClass: "w-100",
    staticStyle: {
      "margin-top": "32px"
    }
  }, [_c('thead', [_c('tr', [_c('th', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px"
    },
    attrs: {
      "width": "60%"
    }
  }), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Subtotal")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v(_vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.invoice, 'invoices.sub_total', 0))))])]), _c('tr', [_c('th', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "9px",
      "padding-top": "9px"
    },
    attrs: {
      "width": "60%"
    }
  }), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "9px",
      "padding-top": "9px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Plus VAT (" + _vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.invoice, 'invoices.vat', 0))) + "%)")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "9px",
      "padding-top": "9px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("$0.00")])])]), _c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "font-size": "12px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#3699FF",
      "padding-bottom": "9px",
      "padding-top": "9px"
    },
    attrs: {
      "width": "60%"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-size": "12px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#3699FF",
      "padding-bottom": "9px",
      "padding-top": "9px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Total")]), _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-size": "12px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#3699FF",
      "padding-bottom": "9px",
      "padding-top": "9px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v(_vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.invoice, 'invoices.total_amount', 0))))])])])]), !_vm.isUnPaid ? _c('table', {
    staticClass: "w-100",
    staticStyle: {
      "margin-top": "5px"
    }
  }, [_c('thead', [_vm.cardPayment != '0.00000000' ? _c('tr', [_c('th', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px"
    },
    attrs: {
      "width": "60%"
    }
  }), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Card Payment")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("-" + _vm._s(_vm._f("to_fixed")(_vm.cardPayment)))])]) : _vm._e(), _vm.balancePayment != '0.00000000' ? _c('tr', [_c('th', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px"
    },
    attrs: {
      "width": "60%"
    }
  }), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Balance Payment")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("-" + _vm._s(_vm._f("to_fixed")(_vm.balancePayment)))])]) : _vm._e(), _vm.reservePayment != '0.00000000' ? _c('tr', [_c('th', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px"
    },
    attrs: {
      "width": "60%"
    }
  }), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Reserve Payment")]), _c('th', {
    staticStyle: {
      "text-align": "right",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000",
      "padding-bottom": "11px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("-" + _vm._s(_vm._f("to_fixed")(_vm.reservePayment)))])]) : _vm._e()]), _c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "font-size": "12px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#3699FF",
      "padding-bottom": "9px",
      "padding-top": "9px"
    },
    attrs: {
      "width": "60%"
    }
  }), _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-size": "12px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#3699FF",
      "padding-bottom": "9px",
      "padding-top": "9px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v("Amount Due")]), _c('td', {
    staticStyle: {
      "text-align": "right",
      "font-size": "12px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#3699FF",
      "padding-bottom": "9px",
      "padding-top": "9px",
      "border-bottom": "1px solid #E2E2E2"
    },
    attrs: {
      "width": "20%"
    }
  }, [_vm._v(_vm._s(_vm._f("to_fixed")(_vm._f("get_property")(_vm.response.invoice, 'invoices.ammount_due', 0))))])])])]) : _vm._e(), _c('table', {
    staticClass: "w-100",
    staticStyle: {
      "margin-top": "40px"
    }
  }, [_c('tbody', [_c('tr', [_c('td', {
    staticStyle: {
      "text-align": "left",
      "padding": "20px",
      "background-color": "#F8F8F8"
    }
  }, [_c('span', {
    staticStyle: {
      "text-align": "left",
      "font-size": "13px",
      "font-weight": "800",
      "line-height": "1.3",
      "color": "#000000"
    }
  }, [_vm._v("Notes")]), _vm.isUnPaid ? [_c('br'), _c('span', {
    staticStyle: {
      "text-align": "left",
      "font-size": "12px",
      "font-weight": "500",
      "line-height": "1.3",
      "color": "#000000",
      "padding-top": "16px"
    }
  }, [_vm._v("- If payment not recieve by the due date the account will be at risk of suspention")])] : _vm._e(), _vm.cardPayment > 0 && _vm.getProperty(_vm.response.invoice, 'invoices.card_type_last_digits') ? [_c('br'), _c('span', {
    staticStyle: {
      "text-align": "left",
      "font-size": "12px",
      "font-weight": "500",
      "line-height": "1.3",
      "color": "#000000",
      "padding-top": "16px"
    }
  }, [_vm._v("- £" + _vm._s(_vm._f("to_fixed")(_vm.cardPayment)) + " was paid on " + _vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoices.invoice_due_date')) + " by " + _vm._s(_vm._f("get_property")(_vm.response.invoice, 'invoices.card_type_last_digits')) + ".")])] : _vm._e(), _vm.reservePayment > 0 ? [_c('br'), _c('span', {
    staticStyle: {
      "text-align": "left",
      "font-size": "12px",
      "font-weight": "500",
      "line-height": "1.3",
      "color": "#000000",
      "padding-top": "16px"
    }
  }, [_vm._v("- £" + _vm._s(_vm._f("to_fixed")(_vm.reservePayment)) + " was paid from reserved.")])] : _vm._e(), _vm.balancePayment > 0 ? [_c('br'), _c('span', {
    staticStyle: {
      "text-align": "left",
      "font-size": "12px",
      "font-weight": "500",
      "line-height": "1.3",
      "color": "#000000",
      "padding-top": "16px"
    }
  }, [_vm._v("- £" + _vm._s(_vm._f("to_fixed")(_vm.balancePayment)) + " was paid from account balance.")])] : _vm._e()], 2)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }