<template>
  <div>
    <div class="mainHeading withButton">
      Custom working days
      <div class="d-flex">
        <button @click="$modal.show('hrmAddNewCustomWorkingDayModal')" class="dialer-button dialer-button-primary mr-0 withIcon">
          <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
          Add working day
        </button>
      </div>
    </div>
    <div class="mainDescription mb-4">
      Have a weekend as a working day in your country? You can mark specific day in the weekend as a "working day" instead. 
      Any holidays taken during these days will be counted against the remaining balance.
    </div>
    <div class="documentsModule-Table mt-32px">
      <vb-table class="HrmTable">
        <template slot="header">
          <tr>
            <th class="HrmTableHead one pt-1">Name</th>
            <th class="HrmTableHead two pt-1">date</th>
            <th class="HrmTableHead three pt-1">Actions</th>
          </tr>
        </template>
        <template #body>
          <tr v-for="n in 6" :key="n" class="dialer-row-select" >
            <td class="dialer-row-title one">
              Work to be done
            </td>
            <td class="dialer-col-right two">
              26-12-2023
            </td>
            <td class="dialer-col-right three">
              <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                <template #button-content>
                  <b-icon icon="three-dots-vertical"></b-icon>
                </template>
                <b-dropdown-item href="#">Action</b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    <modal class="dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll" name="hrmAddNewCustomWorkingDayModal">
      <div>
        <div class="modalHeading-container">
          <div class="modalHeading">Custom working days</div>
          <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('hrmAddNewCustomWorkingDayModal')">Close</button>
        </div>
        <div class="modalInner-container">
          <div class="innerShadowInput-container ">
            <label>Name</label>
            <input />
          </div>
          <div class="innerShadowInput-container mt-25px">
            <label>Date</label>
            <div class="inputWithIconContainer">
              <input class="withIconOnRight primaryBorder noInnerBoxShadow"/>
              <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
            </div>
          </div>
          <b-form-checkbox class="HrmCheckBox-container">
            Repeat every year
          </b-form-checkbox>
          <div class="innerShadowInput-container mt-24px">
            <label>Employees assigned:</label>
            <input placeholder="Search by name"/>
          </div>
          <div class="usersList-withCheckBox-container">
            <b-form-checkbox class="HrmCheckBox-container mt-16px">
              Select All
            </b-form-checkbox>
            <b-form-checkbox v-for="n in 5" :key="n" class="HrmCheckBox-container">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img src="/img/1.dd976805.jpeg">
                </div>
                <div class="nameWithImage-text">Marie jane</div>
              </div>
            </b-form-checkbox>
          </div>
          <button class="dialer-button dialer-button-primary mt-32px mr-0"> Create </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'CalendarCustomWorkingDays',
}
</script>

<style>

</style>