<template> 
  <div class="dialer-settings-main">
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <!-- <div class="dialer-settings-title topMainHeading">My Numbers</div> -->
      <div class="dialer-settings-title topMainHeading">Telephone numbers</div>
    </div>
    <section class="dialer-settings-section MyNumbers">
      
      <div class="mobileInputIOS iconOnLeft mt-32px">
        <b-input type="text" placeholder="Search" onkeypress="return /[0-9\-]/i.test(event.key)" v-model.lazy="filter.numbers.search" />
        <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
      </div>
      <div class="basicWhiteIOScard mt-32px">
        <div class="basicWhiteIOScard-item" v-for="data in filternumbers" :key="data.id">
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon class="" :icon="data.isdid ? 'MyNumbers-TelephoneNumber-IOSIcon' : 'MyNumbers-CallerID-IOSIcon'"/>
            </div>
            <div class="headingInsideTable">{{ data.number | number_formater }}</div>
          </div>
          <div v-if="!data.isdid" class="rightAlignIosCheckbox">
            <b-form-checkbox 
              :checked="data.number==selected.caller_id" 
              v-model="selected.caller_id" 
              :value="data.number" 
              @change="updateCallerID(data.number)"
              class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight users-lists-item-999 makeCheckBoxesSquare"
            >
              <vb-icon icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            </b-form-checkbox>
          </div>
        </div>
      </div>
      <InfiniteLoading ref="numbers_infinity_loading" @infinite="fetchCIDAndDID()">
        <div slot="spinner">
          <vb-loading type="loader" />
        </div>
        <div slot="no-more"></div>
        <div slot="no-results">
          <vb-no-record :text="'There is no Numbers'" :design="3" />
        </div>
        <template #error="{ trigger }">
          <div>
            <span>{{ api.numbers.error_message }}</span>
            <b-icon icon="arrow-repeat" class="ml-3" variant="primary" font-scale="1.5" @click="trigger()" />
          </div>
        </template>
      </InfiniteLoading>
    </section>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

import { VOIP_API } from "@/utils";
import _ from "lodash";

export default {
  name: 'Numbers',
  components: {
    InfiniteLoading,
  },
  inject:['isEmpty','appNotify'],
  data() {
    return {
      response: {
        numbers: [],
        did: {},
      },
      api: {
        numbers: this.$helperFunction.apiInstance({ error_message: true, }),
        update: this.$helperFunction.apiInstance({  }),
      },
      selected: {
        caller_id: '',
      },
      filter: {
        numbers: {
          search: '',
          type: '',
        },
      },
    };
  },
  computed: {
    numbers(){
      const cids = this.response.numbers.map((number) => {
        return {
          number: number,
          isdid: false,
          type: 'callerId',
          id: `${number}-cid`,
        };
      });
      const dids = Object.values(this.response.did).map((did) => {
        const number =  did.number || did.numberWithCode || ''
        return {
          number,
          full_number: did.numberWithCode,
          isdid: true,
          type: 'telephone number',
          id: `${number}-did`,
        };
      });
      return _.orderBy([...cids,...dids])
    },
    filternumbers() {
      const search = this.filter.numbers.search.trim().toLowerCase()
      return this.numbers.filter(item=>`${item.number.replace(/-/g,'')} ${item.number}`.trim().toLowerCase().includes(search))
    },
  },
  methods: {
    updateCallerID(number) {
      if(this.api.update.send) return;
      this.api.update.send=true
      VOIP_API.endpoints.telephone_number.update(this.$store.getters.getCurrentUser?.account, {
        field: "callerid",
        value: number,
        fid: "12",
      })
      .then(()=>{
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        this.selected.caller_id = number;
      })
      .catch((ex)=>{
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        this.api.update.send=false
      });
    },
    async fetchCIDAndDID(reset){
      if(this.api.numbers.send) return;
      if(reset==true){
        this.response.numbers = []
        this.selected.caller_id = ''
        this.response.did = {}
        this.$refs.numbers_infinity_loading?.stateChanger?.reset?.()
      }
      try {
        this.api.numbers.send=true
        this.api.numbers.error_message=''
        const { data: did_res } = await VOIP_API.endpoints.telephone_number.assigned_did()
        const { data: cid_res } = await VOIP_API.endpoints.telephone_number.list({
          account: this.$store.getters.getCurrentUser?.account,
        })
        const cids = cid_res?.list ?? []
        const cid = cid_res?.selected ?? '';
        const dids = did_res?.data?.did ?? {};
        this.response.numbers = cids;
        this.selected.caller_id = cid;
        this.response.did = dids;
        if(Object.values(dids).length>0 || cids.length>0) this.$refs.numbers_infinity_loading?.stateChanger?.loaded()
        this.$refs.numbers_infinity_loading?.stateChanger?.complete()
      } catch(ex) {
        this.$refs.numbers_infinity_loading?.stateChanger?.error?.()
        this.api.numbers.error_message=ex.own_message || ex.message
      } finally {
        this.api.numbers.send=false
      }
    },
  },
  activated(){
    setTimeout(()=>this.fetchCIDAndDID(true),0.5*1000)
  },
};
</script>

