import { VOIP_API } from "../index"

export const SMS_API_ENDPOINTS = {
  fetchConversation(data={},token){
    return VOIP_API.axios.voip.request({
      url: `check-sms-conversation`,
      method: "POST",
      data,
      cancelToken: token,
    })
  },
  sendsms(data={}){
    return VOIP_API.axios.voip.request({
      url: `send-sms`,
      method: 'POST',
      data,
    })
  },
  balanceInquiry(data){
    return VOIP_API.axios.voip.request({ 
      url: "portal-proxy",
      method: "POST",
      data: {
        ...data,
        action: 'user_sms_current_balance',
        type: 'billing',
      },
    })
  },
  getvonagenumbers(params={}){
    return VOIP_API.axios.voip.request({ 
      url: "get-vonage-number",
      method: "GET",
      params,
    })
  },
}