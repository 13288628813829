<template>
  <div>
    <modal 
      class="AddNewBillingCard SubscriptionPlanDetailModal newBilling-changePlan-modal UpdatePlanComparisonsListModal" 
      width="40%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      :clickToClose="false" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <!-- <div class="dialer-box  table  mb-4">
        <div v-for="comparison in comparisons" :key="comparison.product_id">
          {{comparison.product_label}}
          {{comparison.product_total_free_allowed}}
          {{comparison.product_already_used}}
          {{comparison.new_plan_free_product_allowed}}
          {{comparison.qty_difference}}
          {{comparison.message}}
        </div>
      </div> -->
      <div class="dialer-edit-header pt-0 mb-4">
        <h2 class="dialer-edit-title mb-0">Slot comparison details </h2>
        <div class="dialer-edit-actions">
          <a class="newCloseButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <vb-table 
        class="latestTableDesign-withBlackBorders-again mt-0"
        :isListEmpty="comparisons.length==0" 
        :listLength="comparisons.length" 
        :perPage="5" 
      >
        <tr slot="header" class="w-100">
          <th class="border-0"> 
            <span class="">Label</span>
          </th>
          <th class=" border-0 ">
            <span class="">available services</span>
          </th>
          <th class=" border-0 ">
            <span class="">current package used slots </span>
          </th>
          <th class=" border-0 ">
            <span class="">new package available slot</span>
          </th>
          <th class=" border-0 ">
            <span class="">quantity Difference </span>
          </th>
          <th class=" border-0 ">
            <span class="">Message </span>
          </th>
          <th class=" border-0 ">
            <span class="">mismatch </span>
          </th>
        </tr>
        <template #body="{ start, end }">
          <tr 
            v-for="(comparison,index) in comparisons" 
            :key="comparison.product_id" 
            v-show="index >= start && index <= end" 
            :class="{
              'mismatch': comparison.is_mismatch=='yes'
            }"
            @click="comparison.is_mismatch=='yes'?$modal.show(`${_uid}-ShowDetailsBilling`,{ id: comparison.product_id, is_addon: true }):''"
          >
            <td>{{comparison.product_label}}</td>
            <td>{{comparison.product_total_free_allowed}}</td>
            <td>{{comparison.product_already_used}}</td>
            <td>{{comparison.new_plan_free_product_allowed}}</td>
            <td>{{comparison.qty_difference}}</td>
            <td>{{comparison.message}}</td>
            <td>{{comparison.is_mismatch}}</td>
          </tr>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex w-fit-content">
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex w-fit-content">
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </div>
            </td>
            <td>
              <div class="d-flex w-fit-content">
                <div class="latestShimmerDesign flex-1" style="height:19px;width:60px"></div>
              </div>
            </td>
            <td class="dialer-col-right">
              <div class="latestShimmerDesign ml-auto" style="height:50px;width:135px;"></div>
            </td>
          </tr>
        </template>
      </vb-table>
      <div class="d-flex justify-content-end w-100 mt-4">
        <b-button class="dialer-button dialer-button-primary" @click="$modal.hide(modalName)" variant="primary">OK</b-button>
      </div>
    </modal>
    <ShowDetailsBilling
      :modalName="`${_uid}-ShowDetailsBilling`"
      :subscription_detail="subscription_detail" 
      :allAddons="allAddons" 
      @delete-service="$emit('delete-service')" 
    />
  </div>
</template>

<script>
import ShowDetailsBilling from './ShowDetailsBilling.vue'
export default {
  name: 'UpdatePlanComparisonsListModal',
  components: {
    ShowDetailsBilling,
  },
  props: {
    modalName: {
      type: String,
      default: 'UpdatePlanComparisonsList'
    },
    subscription_detail: {
      type: Object,
      default: ()=>({})
    },
    allAddons: {
      type: Array,
      default: ()=>[]
    },
  },
  data() {
    return {
      data: {
        comparisons: [],
      }
    }
  },
  computed: {
    comparisons(){ return this.data.comparisons/*?.filter?.(i=>i.is_mismatch=='yes') ?? []*/ },
  },
  methods: {
    onBeforeOpen(event){
      this.data.comparisons=event?.params?.comparisons ?? []
    },
    onBeforeClose(){
      this.data.comparisons=[]
    },
  },
}
</script>

<style>

</style>