export default {
  state: {
    general: false,
    api: false,
    // centrifugo: false,
    ami: false,
    mattermost_socket: false,
    mattermost_api: false,
    sip: false,
    crm: false,
    integration: false,
    media_device_settings: false,
  },
  mutations: {
    setLoggerValue(state,{ key, value }){
      state[key]=value
    },
  },
};