<template>
  <div :id="id" class="dialer-box alt position-relative">
    <div class="dialer-outofhours v2">
      <div :class="`whiteBoxWithBorderInsideGreyBox-row pb-0 EnableBusinessHoursSwitchRow min-height-unset ${conditions.toggle?'b-0':''}`">
        <div class="d-flex flex-column">
          <div class="dialer-box-header mb-0">Enable business hours</div>
          <div class="dialer-box-text">Select how you will like to manage calls outside office/working hours</div>
        </div>
        <div 
          v-if="conditions.toggle"
          v-b-tooltip :title="getUserPermissions.out_of_hours?'':'Your package does not support this feature'"
          :class="{ 
            'for-disable': !getUserPermissions.out_of_hours 
          }"
        >
          <!-- <b-spinner v-if="api.get_setting.send" small variant="primary" type="grow" /> -->
          <template v-if="api.get_setting.send">
            <div class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
          </template>
          <b-form-checkbox 
            v-else
            :disabled="!getUserPermissions.out_of_hours" 
            class="newerSwitch" 
            :checked="response.out_of_hours" 
            @change="updateOutOfHours($event)" 
            switch  
          />
        </div>
      </div>
      <template v-if="api.update_out_of_hours.send">
        <div class="whiteBoxWithBorderInsideGreyBox-row b-0 pb-0 align-items-start">
          <div class="d-flex flex-column flex-fill">
            <div class="latestShimmerDesign" style="height:24px;width:50%;"></div>
            <div class="latestShimmerDesign mt-3" style="height:19px;width:40%;"></div>
            <div class="latestShimmerDesign mt-1" style="height:17px;width:30%;"></div>
          </div>
          <div class="latestShimmerDesign" style="height:50px;width:100px;"></div>
        </div>
        <div class="latestShimmerDesign md-mar-top" style="height:24px;width:50%;"></div>  
        <div class="whiteBoxWithBorderInsideGreyBox withBG-F9F9F9 withOutPadding mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row withHover" v-for="n in 7" :key="n.id" >
            <div class="latestShimmerDesign" style="height:23px;width:150px;"></div>
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column align-items-end text-right mr-2">
                <div class="latestShimmerDesign" style="height:22px;width:150px;"></div>
                <div class="latestShimmerDesign mt-2" style="height:20px;width:160px;"></div>
                <div class="latestShimmerDesign mt-1" style="height:17px;width:180px;"></div>
              </div>
              <b-icon class="iconIconArrow" icon="chevron-right" style="" variant="dark" font-scale="1.5"/>
            </div>
          </div>
        </div>
      </template>
      <!-- <vb-loading v-if="api.update_out_of_hours.send" /> -->
      <template v-else>
        <template v-if="response.out_of_hours && conditions.default">
          <!-- <div class="whiteBoxWithBorderInsideGreyBox mt-20px"> -->
            <div class="whiteBoxWithBorderInsideGreyBox-row b-0 pb-0 align-items-start">
              <div class="d-flex flex-column">
                <!-- <div class="latestGreyBox-heading-main">When calls aren't answered...</div> -->
                <div class="latestGreyBox-heading-main mb-0">What happens to calls after business hours ?</div>
                <template v-if="forwardSetting.forward_note == 'none'">
                  <div class="latestGreyBox-descriptionText-18px">Keep ringing</div>
                  <div class="latestGreyBox-descriptionText-14px">Call forwarding is off</div>
                </template>
                <template v-if="forwardSetting.forward_note == 'voicemail'">
                  <div class="latestGreyBox-descriptionText-18px">Ask to leave a voicemail</div>
                  <div class="latestGreyBox-descriptionText-14px">Call will be forward to voicemail</div>
                </template>
                <template v-if="forwardSetting.forward_note == 'extension'">
                  <div class="latestGreyBox-descriptionText-18px">Forward to a user, team or IVR menu</div>
                  <div class="latestGreyBox-descriptionText-14px">
                    Call will be forward to
                    {{forwardSetting.extension_name || ''}}
                  </div>
                </template>
                <template v-if="forwardSetting.forward_note == 'number'">
                  <div class="latestGreyBox-descriptionText-18px">Forward to another number</div>
                  <div class="latestGreyBox-descriptionText-14px">
                    Call will be forward to 
                    {{forwardSetting.forward_number || ''}}
                  </div>
                </template>
              </div>
              <div class="w-fit-content">
                <div 
                  v-b-tooltip :title="getUserPermissions.out_of_hours?'':'Your package does not support this feature'"
                  :class="!getUserPermissions.out_of_hours ? 'for-disable' : ''"
                >
                  <button 
                    :disabled="!getUserPermissions.out_of_hours"
                    class="newButton" 
                    @click="$modal.show(`${randomId}-CallForwardSettingModal`,{ 
                      account, 
                      accountcode: account, 
                      setting: forwardSetting, 
                      is_ringing_show: false, 
                      is_sec_show: false 
                    })"
                  >
                    <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                    <span class="newButton-textPart">Change</span>
                  </button>
                </div>
              </div>
            </div>
        </template>
        <template v-if="response.out_of_hours && conditions.days">
          <div class="dialer-box-header md-mar-top">Set your business hours</div>
          <div class="whiteBoxWithBorderInsideGreyBox withBG-F9F9F9 withOutPadding mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row withHover"
              v-for="day in weekDays"
              :key="day.value" 
              v-b-tooltip :title="getUserPermissions.out_of_hours?'':'Your package does not support this feature'"
              @click="!getUserPermissions.out_of_hours ? '' : $modal.show(`${randomId}-EditOutOfHoursDayModal`,{ day: day.value })"
            >
              <div class="d-flex flex-column">
                <div class="day-text"> {{ day.text }} </div>
                <div 
                  v-if="days[day.value] && days[day.value].setting_type=='custom'" 
                  class="defaultCustomSelected"
                >
                  custom
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="d-flex flex-column align-items-end text-right mr-2">
                  <div class="selectedValue" :class="[`${day.value}`,{'unavailable': days[day.value] && days[day.value].type!='availble' }]">{{ days[day.value] | filterTypeText }}</div>
                  <template v-if="days[day.value] && days[day.value].type!='availble' && days[day.value].setting_type!='default'">
                    <div class="selectedText-big">{{ days | get_property(`${day.value}.forward_type`) | filterHeaderText }}</div>
                    <div class="selectedText-small">{{ days | get_property(`${day.value}`) | filterInfoText }}</div>
                  </template>
                </div>
                <b-icon class="iconIconArrow" icon="chevron-right" style="" variant="dark" font-scale="1.5"/>
              </div>
            </div>
          </div>
        </template>
      </template>
    </div>
    
    <CallForwardSettingModal 
      :modalName="`${randomId}-CallForwardSettingModal`" 
      @updated="updateDefaultSetting($event)" 
    />
    <ScheduleAssignModal 
      :modalName="`${randomId}-ScheduleAssignModal`" 
      :account="account"
      @interface="updateDefaultSetting({ 
        extension_name: $event.accountname, 
        forward_number: $event.account, 
      })" 
    />
    <CallForwardingNumberInputModal :modalName="`${randomId}-CallForwardingNumberInputModal`" @number="updateDefaultSetting({ forward_number: $event.ber })" />
    <EditOutOfHoursDayModal 
      :modalName="`${randomId}-EditOutOfHoursDayModal`" 
      :defaultForwardSetting="forwardSetting"
      :account="account" 
      :schedules="response.schedules" 
      @updated="response.schedules=$event;$emit('ofh-days-updated')" 
    />
  </div>
</template>

<script>
import { mapGetters } from 'vuex'
import { $fn, VOIP_API } from '../../utils'
import EditOutOfHoursDayModal from '../Modals/EditOutOfHoursDayModal.vue'
import CallForwardSettingModal from "../Modals/CallForwardSettingModal.vue";
import ScheduleAssignModal from "../Modals/ScheduleAssignModal.vue";
import CallForwardingNumberInputModal from "../Modals/CallForwardingNumberInputModal.vue";
let week_days = [
  {
    value: "mon",
    text: "Monday",
  },
  {
    value: "tue",
    text: "Tuesday",
  },
  {
    value: "wed",
    text: "Wednesday",
  },
  {
    value: "thu",
    text: "Thursday",
  },
  {
    value: "fri",
    text: "Friday",
  },
  {
    value: "sat",
    text: "Saturday",
  },
  {
    value: "sun",
    text: "Sunday",
  },
]
export default {
  name: 'ScheduleSetting',
  components: {
    EditOutOfHoursDayModal,
    CallForwardSettingModal,
    ScheduleAssignModal,
    CallForwardingNumberInputModal,
  },
  inject: [
    'getProperty','appNotify'
  ],
  props: {
    account: {
      type: String,
      default: '',
    },
    id: {
      type: String,
      default: '',
    },
    condition: {
      type: Object,
    },
  },
  data(){
    return {
      random_id: '',
      api: {
        route_call: this.$helperFunction.apiInstance(),
        schedules: this.$helperFunction.apiInstance(),
        update_out_of_hours: this.$helperFunction.apiInstance(),
        update_schedule: this.$helperFunction.apiInstance({ source: true }),
        get_setting: this.$helperFunction.apiInstance(),
      },
      response: this.$helperFunction.stateInstance({
        data: {
          schedules: [],
          out_of_hours: false,
          default_setting: {},
        }
      }),
      selected: this.$helperFunction.stateInstance({
        data: {
          day: 'mon',
        }
      }),
    }
  },
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getCurrentUser: 'getCurrentUser',
    }),
    randomId(){ return this.random_id },
    weekDays() { return week_days; },
    days() {
      return {
        mon: this.response.schedules?.find?.(i=>i.day=='mon'),
        tue: this.response.schedules?.find?.(i=>i.day=='tue'),
        wed: this.response.schedules?.find?.(i=>i.day=='wed'),
        thu: this.response.schedules?.find?.(i=>i.day=='thu'),
        fri: this.response.schedules?.find?.(i=>i.day=='fri'),
        sat: this.response.schedules?.find?.(i=>i.day=='sat'),
        sun: this.response.schedules?.find?.(i=>i.day=='sun'),
      };
    },
    forwardSetting(){
      return {
        extension_name: this.response?.default_setting?.forward_name ?? '',
        forward_note: this.response?.default_setting?.extension_type || 'voicemail' || 'none',
        forward_number: this.response?.default_setting?.forward_to ?? '',
        number: this.response?.default_setting?.forward_to ?? '',
      }
    },
    conditions(){
      return {
        toggle: !!(this.condition?.toggle ?? true),
        days: !!(this.condition?.days ?? true),
        default: !!(this.condition?.default ?? true),
      }
    },
  },
  filters: {
    filterTypeText(day){ return day?.type === "availble" || !day?.type ? "available all day" : day?.type === "unavailable" ? "unavailable all day" : `${day.start_time} - ${day.end_time}` },
    filterHeaderText(value){
      if(value=='voicemail') return 'Ask to leave a voicemail'
      else if(value=='extension') return 'Forward to a user, team or IVR menu'
      else if(value=='number') return 'Forward to another number'
      else return 'Keep ringing'
    },
    filterInfoText(day){
      const value = day?.forward_type ?? ''
      const name = day?.forward_name ?? ''
      const number = day?.forward_account ?? ''
      if(value=='voicemail') return 'Call will be forward to voicemail'
      else if(value=='extension') return `Call will be forward to ${name}`
      else if(value=='number') return `Call will be forward to ${number}`
      else return 'Call forwarding is off'
    },
  },
  methods: {
    fetchSchedule() {
      let vm = this
      if(vm.api.schedules.send) return;
      vm.api.schedules.send = true;
      VOIP_API.endpoints.users.schedules(vm.account, {
        fid: 12,
      })
      .then(({ data: res }) => {
        vm.response.schedules = res?.data ?? [];
      })
      .finally(() => {
        if(!vm.selected.day) vm.selected.day = "mon";
        vm.api.schedules.send = false;
      });
    },
    fetchcallRouteSetting() {
      let vm = this
      if(vm.api.route_call.send) return;
      vm.api.route_call.send = true;
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.account,
      })
      .then(({ data }) => {
        vm.response.out_of_hours = !!data.schedule_enable;
      })
      .finally(() => {
        vm.api.route_call.send = false;
      });
    },
    fetchDefaultSetting(){
      let vm = this
      if(vm.api.get_setting.send) return;
      vm.api.get_setting.send = true;
      VOIP_API.endpoints.users.getscheduledefault(vm.account)
      .then(({ data }) => {
        vm.response.default_setting = data;
      })
      .finally(() => {
        vm.api.get_setting.send = false;
      });
    },
    updateOutOfHours(value){
      let vm = this
      if(vm.api.update_out_of_hours.send) return;
      vm.api.update_out_of_hours.send=true
      VOIP_API.endpoints.users.updateoutofhours({
        field: 'schedule',
        uid: vm.getCurrentUser?.uid,
        value: value?'1':'0',
        accountcode: vm.account
      })
      .then(()=>{
        vm.appNotify({
          message: 'Successfully updated',
          type: "success",
        })
        vm.fetchcallRouteSetting()
        vm.$emit('ofh-toggle-updated')
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update_out_of_hours.send=false
      })
    },
    updateDefaultSetting(setting={}){
      let vm = this;
      vm.api.update_schedule.send = true;
      const toaster_value = vm.appNotify({
        message: "Schedule setting saving...",
        type: "success",
        duration: 60 * 1000,
      });
      const forward = setting?.forward_note=='off' ? 'none' : setting?.forward_note ?? vm.response.default_setting.extension_type
      VOIP_API.endpoints.users.updatescheduledefault({
        account: vm.account,
        extn: setting?.forward_note ?? vm.response.default_setting.extn,
        forward: forward,
        forward_account: setting?.forward_number ?? vm.response.default_setting.forward_account,
        forward_name: setting?.extension_name ?? vm.response.default_setting.forward_name,
        ext_type: forward,
        type: setting?.type ?? vm.response.default_setting.type,
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully updated!",
          type: "success",
        })
        vm.fetchDefaultSetting()
        vm.fetchSchedule()
        vm.$emit('ofh-default-updated')
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(() => {
        toaster_value.close();
        vm.api.update_schedule.send = false;
      });
    },
  },
  created(){
    this.random_id = $fn.makeid(10)
  },
  mounted(){
    this.fetchSchedule()
    this.fetchcallRouteSetting()
    this.fetchDefaultSetting()
  },
  activated(){
    this.fetchSchedule()
    this.fetchcallRouteSetting()
    this.fetchDefaultSetting()
  },
}
</script>

<style>

</style>