var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section"
  }, [_vm._m(0), _c('p', {
    staticClass: "dialer-settings-intro with-button"
  }, [_vm._v("Manage all your extensions.")]), _c('vb-table', {
    attrs: {
      "isListEmpty": _vm.response.extensions.length == 0,
      "listLength": _vm.extensions.length,
      "loading": _vm.api.fetch_extensions.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.response.extensions, function (extension, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: index
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            staticClass: "nameWithExtension"
          }, [_c('span', {
            staticClass: "extension_tag"
          }, [_vm._v(_vm._s(extension.extn))]), _vm._v(" " + _vm._s(_vm._f("filterLabel")(extension)) + " ")])]), _c('td', {
            staticClass: "dialer-col-left"
          }, [_vm._v(_vm._s(extension.type))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('a', {
            staticClass: "d-flex dialer-button dialer-button-primary ml-auto",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('EditExtensionModal', {
                  data: extension
                });
              }
            }
          }, [_vm._v("Change")])])]);
        });
      }
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', {
    staticClass: "dialer-sorted-by ascending"
  }, [_vm._v("Label")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Type")])]), _c('th', {
    staticClass: "dialer-col-right"
  }, [_c('span', [_vm._v("Action")])])])])], 2), _c('EditExtensionModal', {
    on: {
      "interface": function ($event) {
        return _vm.fetchAllExtensions();
      }
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-flex"
  }, [_c('h2', {
    staticClass: "dialer-settings-title"
  }, [_vm._v("All Extensions")])]);

}]

export { render, staticRenderFns }