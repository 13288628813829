var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation center-zoomIn-popup dialer-notificationModal KeyboardShortcutsModal",
    attrs: {
      "transition": "center-zoomIn-transition",
      "name": _vm.modalName,
      "scrollable": false
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to dialer-edit w-100"
  }, [_c('div', {
    staticClass: "pb-3 dialer-edit-header d-flex aling-items-center justify-content-between w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Shortcut Keys")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "mt-20px latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.shortcuts, function (data) {
    return _c('div', {
      key: data.shortcut,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset py-4"
    }, [_c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(data.text))]), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(data.shortcut))])]);
  }), 0)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }