<template>
  <div v-if="channel_id" class="chat-box" :tha="type">
    <div id="scroll-end" :style="{scrollBehavior: ' auto'}" class="allow_scroll" @scroll="onScroll">
    
      <div :class="['pr-4 chat-box-bill', { pinned: type === 'pinned' }, messages.length == 1 ? 'onlyOne' : '']">
        <div v-for="(message,index) in messages" :key="message.id">
          <div v-if="channelData.unread === message.user_msg_counter && type !== 'pinned'" class="message-date new-message">
            <span>Unread Message</span>
          </div>
          <div class="message-date" v-show="message.is_date_show || type === 'pinned'">
            <span>{{ message.create_at | date_current }}</span>
          </div>
          <Message 
            ref="single_message"
            :rapidlyMessage="index | rapidlyMessage(messages)" 
            :random_id="random_id"
            :post="message" 
            :channel_id="channel_id" 
            :channel="channel" 
            :data="data" 
            :channelData="channelData" 
            :members="members" 
            :type="type" 
            :name="name" 
            :image="image" 
          />
        </div>
        <div style="height: 10px"></div>
      </div>
      <div class="scrollToBottomButton" v-if="scroll_bottom" @click="scrollEnd()" style="">
        
        <vb-svg name="dialer-downArrow-icon" width="14" height="8.001" viewBox="0 0 14 8.001" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialer-downArrow" />
      </div>
    </div>
  </div>
</template>

<script>
import { LOGGER, events } from "../../../../utils";
import Message from "./Message.vue";
import _ from "lodash";
import moment from 'moment';
export default {
  name: "Messages",
  components: {
    Message,
  },
  props: {
    channel_id: {
      type: String,
      default: "",
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    channelData: {
      type: Object,
      default: () => ({}),
    },
    members: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
    },
    name: {
      type: String,
      deault: "",
    },
    image: {
      type: String,
      deault: "",
    },
    currentUserMember: {
      type: Object,
      default: () => ({}),
    },
    random_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      is_scrolled: false,
      scroll_bottom: false,
      scrolled_property: {
        always: false,
        enabled: true,
        smooth: false,
        notSmoothOnInit: true,
        scrollonremoved: false,
        smoothonremoved: false,
      },
    };
  },
  computed: {
    messages() {
      var current_date = "";
      var current_time = "";
      const messages = (this.channelData?.posts?.order ?? []).map((order) => this.channelData.posts.posts[order]);
      var user_msg_counter = messages.filter((post) => !post.type).length + 1;
      return messages.map((post) => {
        const date = new Date(post.create_at);
        if (current_date !== date.toDateString()) {
          current_date = date.toDateString();
          current_time = date.getHours() + date.getMinutes();
          return {
            ...post,
            is_date_show: true,
            is_time_show: true,
            user_msg_counter: post.type ? null : --user_msg_counter,
          };
        } else {
          if (date.getHours() + date.getMinutes() !== current_time) {
            current_time = date.getHours() + date.getMinutes();
            return {
              ...post,
              is_date_show: false,
              is_time_show: true,
              user_msg_counter: post.type ? null : --user_msg_counter,
            };
          } else {
            return {
              ...post,
              is_date_show: false,
              is_time_show: false,
              user_msg_counter: post.type ? null : --user_msg_counter,
            };
          }
        }
      });
    },
    pinnedMessages() {
      return this.messages.filter((message) => message.is_pinned);
    },
    importantMessages() {
      return this.messages.filter(
        (message) => message.props && message.props.is_important
      );
    },
    multimediaMessages() {
      return this.messages.filter(
        (message) => message.metadata && message.metadata.files
      );
    },
    files() {
      let files = [];
      this.multimediaMessages.forEach((message) => {
        files = [...files, ...message.metadata.files];
      });
      return files;
    },
    previewFiles() {
      let preview_file_type = ["image", "video"];
      return this.files.filter(
        (file) => preview_file_type.indexOf(file.mime_type.split("/")[0]) >= 0
      );
    },
    lastPost() {
      if (_.isEmpty(this.channelData)) return {};
      if ((this.channelData?.posts?.order ?? []).length === 0) return {};
      const order = this.channelData.posts.order;
      const id = order[order.length - 1];
      return this.channelData.posts.posts[id];
    },
  },
  watch: {
    "$store.state.global_conditions.tab": {
      immediate: true,
      handler(tab){
        let vm = this
        if(tab=='chat'){
          setTimeout(()=>{
            vm.scrollEnd()
          },0.5 * 1000)
        }
      },
    },
    channel_id: {
      immediate: true,
      handler() {
        this.scrollEnd();
      }
    },
    lastPost:{
      immediate: true,
      deep: true,
      handler(new_post, old_post) {
        if(!new_post) return;
        if (new_post.id === old_post?.id) return;
        if (new_post.type) return;
        this.scrollEnd();
      }
    },
  },
  filters: {
    date(value) {
      const current_date = new Date();
      const message_date = new Date(value);
      if (current_date.getFullYear() === message_date.getFullYear()) {
        if (current_date.getMonth() === message_date.getMonth()) {
          if (current_date.getDate() === message_date.getDate()) {
            return "Today";
          } else if (current_date.getDate() === message_date.getDate() - 1) {
            return "Yesterday";
          } else {
            return message_date.getDate() + " Date";
          }
        } else {
          return message_date.getMonth() + 1 + " Month";
        }
      } else {
        return message_date.getFullYear() + " Year";
      }
    },
    rapidlyMessage(index,mesages){
      let status = 0
      let message = mesages?.[index]
      let prev_message = mesages?.[index-1]
      let next_message = mesages?.[index+1]
      if(message && prev_message && !message.is_date_show && message.user_id == prev_message.user_id){
        let start = moment(prev_message.create_at)
        let end = moment(message.create_at)
        let duration_mins = moment.duration(end.diff(start)).asMinutes();
        if(duration_mins <= 5 && duration_mins >= 0) {
          status = 1
        }
        else {
          status = 2
        } 

      } else if(message && next_message && message.user_id == next_message.user_id && !next_message.is_date_show) {
        status = 3
      }
      return status
    },
  },
  methods: {
    //--event--
    scrollEnd() {
      let app = this;
      if(this.$el){
        const message = this.$el.querySelector("#scroll-end");
        setTimeout(() => {
          app.is_scrolled = true;
          message.scrollTop = message.scrollHeight;
          app.scroll_bottom = false
        }, 0 * 1000);
      }
    },
    onScroll(Event) {
      let app = this;
      const el = Event.target;
      
      const height = Event.target.scrollHeight;
      const top = Event.target.scrollTop;
      const offset_height = Event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      app.scroll_bottom = scroll_bottom >= 200
      // const height = el.scrollHeight
      // const top = el.scrollTop
      // const offset_height = el.offsetHeight
      // const scroll_bar_height = height - (height - offset_height);
      // const scroll_botm = height - Math.ceil(top + offset_height);
      // app.scroll_bottom = scroll_bottom >= 200
      // if( scroll_botm <= 200 && app.scroll_bottom ){
      //   console.log('inside if')
      //   app.scroll_bottom = false
      // }else if( scroll_botm >= 200 && !app.scroll_bottom ){
      //   console.log('inside else')
      //   app.scroll_bottom = true
      // }
      if (el.scrollTop === 0) {
        try {
          this.$root.$emit(events.get_messages_api, {
            channel_id: app.channel_id,
            el: el,
            scrollHeight: el.scrollHeight,
          });
        } catch (error) {
          LOGGER.danger(error);
        }
      }
    },
    messagesContainerObserver() {
      if (!this.$parent.$refs && !this.$parent.$refs.header) return;
      this.$parent.$refs.header.matched =
        document.querySelectorAll(".chat-box mark");
      if (
        this.$parent.$refs.header.matched &&
        this.$parent.$refs.header.matched.length > 0
      )
        return;
      this.$parent.$refs.header.current_index = 0;
    },
  },
  mounted() {
    const observer = new MutationObserver(this.messagesContainerObserver);
    observer.observe(this.$el, {
      childList: true,
      subtree: true,
    });
  },
};
</script>

<style lang="scss" scoped>
.chat-box {
  justify-content: flex-end;
}
.pinned {
  width: 100%;
}
.chat-box-bill {
  flex-direction: column;
  display: flex;
}

.message-date {
  position: relative;
  text-align: center;
  span {
    position: relative;
    background-color: $body-bg;
    padding: 0px 15px;
  }
  &:before {
    content: "";
    position: absolute;
    width: 100%;
    top: 50%;
    transform: translateY(-50%);
    background: $gray-600;
    left: 0px;
    height: 1px;
    z-index: -5;
  }
}
.new-message {
  span {
    color: $primary;
  }
  &:before {
    background: $primary;
  }
}
.allow_scroll {
  overflow-y: auto;
}
</style>
