var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup global-modal-hrm Scrollable-hrm",
    attrs: {
      "name": "HRMAddNewAssetModal",
      "clickToClose": true,
      "scrollable": true
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Add new asset")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('HRMAddNewAssetModal');
      }
    }
  }) : _c('a', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "id": "CloseModalButton"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('HRMAddNewAssetModal');
      }
    }
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "sp-gm-if mt-5"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Asset name")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "placeholder": "25090"
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Category")]), _c('div', {
    staticClass: "dd-gm-container"
  }, [_c('b-dropdown', {
    staticClass: "gm-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "gm-dropi-contenti"
        }, [_vm._v("Category")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("A")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("B")])], 1)], 1)]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Description")]), _c('b-form-textarea', {
    staticClass: "gm-hrm-textnote",
    attrs: {
      "id": "textarea-small",
      "size": "sm",
      "placeholder": "Small textarea"
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Asset number")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "placeholder": "25090"
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Serial number")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "placeholder": "25090"
    }
  })], 1), _c('div', {}, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Date of birth"
    }
  }, [_vm._v("Date of Birth")]), _c('b-form-datepicker', {
    staticClass: "gm-hrm-date",
    attrs: {
      "id": "datepicker-buttons",
      "locale": "en"
    }
  }), _c('svg', {
    staticClass: "svg-img-calender",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.5",
      "height": "20",
      "viewBox": "0 0 17.5 20"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_awesome-calendar-alt",
      "data-name": "Icon awesome-calendar-alt",
      "d": "M0,18.125A1.875,1.875,0,0,0,1.875,20h13.75A1.875,1.875,0,0,0,17.5,18.125V7.5H0Zm12.5-7.656A.47.47,0,0,1,12.969,10h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,12.969,15h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,7.969,10H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,7.969,15H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,2.969,10H4.531A.47.47,0,0,1,5,10.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,2.969,15H4.531A.47.47,0,0,1,5,15.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469ZM15.625,2.5H13.75V.625A.627.627,0,0,0,13.125,0h-1.25a.627.627,0,0,0-.625.625V2.5h-5V.625A.627.627,0,0,0,5.625,0H4.375A.627.627,0,0,0,3.75.625V2.5H1.875A1.875,1.875,0,0,0,0,4.375V6.25H17.5V4.375A1.875,1.875,0,0,0,15.625,2.5Z",
      "fill": "#5576d1"
    }
  })])], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Comments")]), _c('b-form-textarea', {
    staticClass: "gm-hrm-textnote",
    attrs: {
      "id": "textarea-small",
      "size": "sm",
      "placeholder": "Small textarea"
    }
  })], 1), _c('button', {
    staticClass: "btn-primarynewupdated1 mt-4 mb-4"
  }, [_vm._v("Save")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }