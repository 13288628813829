<template>
  <modal
    class="center-zoomIn-transition feedbackDuringJitsiCallModal"
    transition="center-zoomIn-transition"
    :name="modalName" 
    :scrollable="false"
    @before-open="onBeforeOpen"
    @before-close="onBeforeClose"
  >
    <div class="dialer-assign-to dialer-edit w-100">
      <div class="p-3 dialer-edit-header d-flex aling-items-center justify-content-between w-100">
        <h2 class="dialer-edit-title mb-0">Feedback</h2>
        <div class="dialer-edit-actions ">
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else class="newCloseButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <b-form @submit.prevent="submit()" class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-center">
            <h4 class="latestGreyBox-heading-main text-center mt-20px mb-0">
              How was the quality of your call?
            </h4>
            <b-form-rating size="lg" class="latestCallFeedback" no-border variant="primary" v-model="forms.feed_back.rate" stars="5"></b-form-rating>
            <div class="whiteBGinputWithGreyRoundedBorder w-100 mt-20px">
              <b-form-textarea class="w-100" v-model="forms.feed_back.detail" />
            </div>
            <div class="d-flex aling-items-center justify-content-end w-100 mt-4">
              <!-- <b-button class="dialer-button dialer-button-delete mr-3" type="button" @click="$modal.hide(modalName)">Cancel</b-button> -->
              <b-button class="newDoneButton" type="submit">
                <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  Submit
                </span>
              </b-button>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: "FeedBack",
  props: {
    modalName: {
      type: String,
      default: 'FeedBack'
    },
  },
  data() {
    return {
      forms: {
        feed_back: this.$helperFunction.formInstance({
          data: {
            rate: null,
            detail: '',
          }
        }),
      },
      data: {
        onSubmit: null
      },
    };
  },
  methods: {
    onBeforeOpen(event) {
      this.data.onSubmit = event.params.onSubmit || null
    },
    onBeforeClose() {
      this.forms.feed_back.reset()
      this.data.onSubmit = null
    },
    submit(){
      const data = {
        rate: this.forms.feed_back.rate,
        detail: this.forms.feed_back.detail,
      }
      this.data?.onSubmit?.(data)
      this.$emit('submitted',data)
      this.$modal.hide(this.modalName)
    },
  },
};
</script>

<style>
</style>