<template>
  <modal class="dialer_animation right_side_popup full-width-big-modal" :scrollable="true" height="auto" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    <div class="dialer-assign-to my-4 dialer-edit">
      <div class="dialer-edit-actions d-flex justify-content-end">
        <button class="newCloseButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    
    <div class="latestGreyBox-9-9-2023 mt-20px">
      <div class="whiteBoxWithBorderInsideGreyBox mt-20px mb-20px">
        <div class="whiteBoxWithBorderInsideGreyBox-row">
          <div class="latestGreyBox-heading-main mb-0">Business hours</div>
          <b-form-checkbox
            class="newerSwitch"
            v-b-tooltip :title="getUserPermissions.out_of_hours?'':'Your package does not support this feature'"
            :disabled="!getUserPermissions.out_of_hours || api.update_out_of_hours.send || api.route_call.send"
            :checked="response.out_of_hours"
            name="check-button"
            switch
            @change="updateOutOfHours($event)"
          />
        </div>
      </div>
      <template v-if="info">
        <AdvanceOptions v-if="info.type=='ivr'" :accountcode="data.accountcode" @updated="$emit('interface', {
          from: 'info', 
          event: 'update_advance_option', 
        })" />
        <!-- members assign -->
        <div v-if="info.type=='team'" class="whiteBoxWithBorderInsideGreyBox mt-20px mb-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div>
              <div class="latestGreyBox-heading-main">Team members</div>
              <div class="latestGreyBox-descriptionText">{{ info.data.members | memberCount }}</div>
            </div>
            <button class="newButton" type="button" @click="$modal.show('AddRemoveMembersTeams', { team: info.data, type: 'AssignNumbersTeam' })">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add/remove users</span>
            </button>
          </div>
        </div>
        <AddRemoveFromList :modalName="`AddRemoveMembersTeams`" v-if="info.type=='team'" @update-list="getVoipTeams(true)" />
      </template>
    </div>




    <!--<div class="pl-2 pr-5 d-flex align-items-center ">
      <b-form-checkbox
        class="dialer-switch ml-0"
        :disabled="api.update_out_of_hours.send || api.route_call.send"
        :checked="response.out_of_hours"
        name="check-button"
        switch
        @change="updateOutOfHours($event)"
      />
      <h3 class="dialer-box-header ml-5 mt-2">Business hours</h3>
    </div>
    <template v-if="info">
      <AdvanceOptions v-if="info.type=='ivr'" :accountcode="data.accountcode" />
      <!~~ members assign ~~>
      <div v-if="info.type=='team'" class="dialer-box dialer-assigned grayBox">
        <div>
          <h3 class="dialer-box-header">Team members</h3>
          <p class="dialer-box-text mb-0">{{ info.data.members | memberCount }}</p>
        </div>
      
        <div class="lg-mar-left">
          <button class="dialer-button dialer-button-primary md-mar-left" type="button" @click="$modal.show('AddRemoveMembersTeams', { team: info.data, type: 'AssignNumbersTeam' })">
            <span>Add/Remove User</span>
          </button>
        </div>
      </div>
      <AddRemoveFromList :modalName="`AddRemoveMembersTeams`" v-if="info.type=='team'" @update-list="getVoipTeams(true)" />
    </template>-->
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils';
import AdvanceOptions from "../IVR/AdvanceOptions.vue";
import AddRemoveFromList from "./AddRemoveFromList.vue";
import { mapGetters } from 'vuex';
export default {
  name: 'GraphInfoEditModal',
  components: {
    AdvanceOptions,
    AddRemoveFromList,
  },
  inject: ['getVoipTeams','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'GraphInfoEditModal',
    },
  },
  data(){
    return {
      data: {
        accountcode: '',
      },
      response: {
        out_of_hours: false,
      },
      api: {
        route_call: this.$helperFunction.apiInstance(),
        update_out_of_hours: this.$helperFunction.apiInstance(),
      },
    }
  },
  computed: {
    ...mapGetters({
      getVoipUsersAlises: 'getVoipUsersAlises',
      getVoipTeamss: 'getVoipTeams',
      getVoipCallQueues: 'getVoipCallQueues',
      getVoipIVRSAlises: 'getVoipIVRSAlises',
      getVoipNumbers: 'getVoipNumbers',
      getUserPermissions: 'getUserPermissions',
    }),
    info() {
      const key = this.data.accountcode ?? ''
      const user = this.getVoipUsersAlises[key];
      const team = this.getVoipTeamss.find(team=>key==team?.ringroup?.accountcode || key==team?.real_id || key==team?.ringroup?.extn)
      const call_queue = this.getVoipCallQueues.find(call_queue=>key==call_queue?.voipaccount || key==call_queue?.extn)
      const number = this.getVoipNumbers.find(number=>key==number?.real_id)
      const ivr = this.getVoipIVRSAlises[key]
      if(team) return { data: team, type: 'team' }
      else if(call_queue) return { data: call_queue, type: 'call_queue' }
      else if(ivr) return { data: ivr, type: 'ivr' }
      else if(number) return { data: number, type: 'number' }
      else if(user) return { data: user, type: 'user' }
      else return null;
    },
  },
  filters: {
    memberCount: function (value) {
      if (typeof value == "number") {
        return value + " user";
      }

      if (!value) return "No users selected";
      return value.length + " user";
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode = event?.params?.accountcode || ''
      this.fetchcallRouteSetting()
    },
    onBeforeClose(){
      this.data.accountcode = ''
      this.response.out_of_hours=false
      this.api.route_call.reset()
      this.api.update_out_of_hours.reset()
    },
    fetchcallRouteSetting() {
      let vm = this
      if(vm.api.route_call.send) return;
      vm.api.route_call.send = true;
      VOIP_API.endpoints.users.callroute({
        accountcode: vm.data.accountcode,
      })
      .then(({ data }) => {
        vm.response.out_of_hours = !!data.schedule_enable;
      })
      .finally(() => {
        vm.api.route_call.send = false;
      });
    },
    updateOutOfHours(value){
      let vm = this
      if(vm.api.update_out_of_hours.send) return;
      vm.api.update_out_of_hours.send=true
      VOIP_API.endpoints.users.updateoutofhours({
        field: 'schedule',
        uid: vm.getCurrentUser?.uid,
        value: value?'1':'0',
        accountcode: vm.data.accountcode
      })
      .then(()=>{
        this.appNotify({
          message: 'Successfully Updated',
          type: "success",
        })
        vm.$emit('updateOutOfHourse')
        vm.$emit('interface', {
          from: 'info', 
          event: 'update_out_of_hours', 
          payload: { 
            out_of_hours: value
          },
        })
        vm.fetchcallRouteSetting()
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update_out_of_hours.send=false
      })
    },
  },
}
</script>

<style>

</style>