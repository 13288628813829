<template>
  <li>
    <a 
      @click="select" 
      :class="{
        'd-flex align-items-center position-relative':true,
        'teamChat-info':type === 'team',
        'channelChat-info':type === 'channel',
        'unreadMessage_blinking':channelData && channelData.unread > 0 && GET_LOCAL_SETTING_CHAT.appearence.blink_unread === 'accepted',
        'active':$store.state.mattermost.main_open && $store.state.mattermost.main_open==channel_id,
        'unreadMessages-present':channelData && channelData.unread > 0,
        'notificationMuted': isMuted,
      }" 
      :type="channelData && channelData.unread > 0 ? 'unreadMessages' : ''" 
    >
      
        <!-- <template v-if="type === 'team'">
          
            <template v-if="typeof teamMembersImages == 'string'">
              <span class="avatar d-flex align-items-center justify-content-center p-1">
                <img :src="teamMembersImages" />
              </span>
            </template>
            <template v-else>
              <template v-if="isEmpty(teamMembersImages)">
                <span class="avatar d-flex align-items-center justify-content-center p-1">
                  <img :src="image" class="channel-icon" alt="image description" />
                </span>
              </template>
              <div v-else class="avatar2-container">
                <span class="avatar d-flex align-items-center justify-content-center p-1">
                  <img :src="image" class="channel-icon" alt="image description" />
                </span>
              </div>
              
            </template>
          
        </template>
        <template v-else-if="type === 'channel'">
          <span class="avatar d-flex align-items-center justify-content-center">
            <img :src="require('@/assets/images/groupsDefaultImg.png')" width="120" height="120" />
          </span>
        </template>
        <template v-else-if="type === 'user'"> -->
          <span class="avatar d-flex align-items-center justify-content-center">
            <vb-avatar :id="iddd" :image="image" :is_blf="false" />
          </span>
        <!-- </template> -->
      
      <div :class="['info-area',type === 'team' ? 'ofTeam' : '']">
        <div class="name">
          <h5 class="m-0 text-dotted">{{ name }}</h5>
          <span v-if="tabType!=='searched'" class="text-lowercase">{{ lastPost.update_at | date_current(true) }}</span>
        </div>
        <p v-if="typingMessage" class="m-0 message text-dotted typing-text isTypingText">
          <span>{{typingMessage}}</span> is typing ...
        </p>
        <template v-else>
          <p v-if="lastMessage && tabType!=='searched'" class="m-0 message text-dotted text-truncate">
            <!-- <span v-if="channelData && channelDraft && tabType!=='searched'">[Draft]</span> -->
            <span v-if="channelData && channelDraft && tabType!=='searched'">[{{ draftMessage.message || "Draft" }}]</span>
            {{lastMessage}}
          </p>
          <span v-if="isMuted" class="m-0 count chatMuteNotification-icon text-center text-white overflow-hidden position-absolute" > 
            <!-- muted  -->
            <vb-icon v-if="isMuted" icon="chatMuteNotification-icon" height="15px" width="15px" class=""/>
          </span>
          <span v-if="channelData && channelData.unread > 0 && tabType!=='searched'" class="m-0 count text-center text-white bg-primary overflow-hidden position-absolute" >{{ channelData.unread }}</span>
        </template>
      </div>
      <div v-if="type === 'team'" @click.stop="expand=!expand" style="position: absolute;right: 11px;top: 28px;" :class="`${expand ? 'rotate-collapsed' : ''} teamsArrow`">
        <vb-svg name="dialer-downArrow-icon" width="14" height="8.001" viewBox="0 0 14 8.001" stroke-width="0" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" class="dialer-downArrow" />
      </div>
    </a>
    <div v-if="expand && getProperty(channelData,'type','')==='team'" class="pl-4 teamsMembers-container">
      <div v-for="member in data.members" :key="member.id" v-show="member.accountcode!==$store.getters.getCurrentUser.account">
        <b-icon v-if="api_sent.direct_chat===member.accountcode" icon="circle-fill" animation="throb"></b-icon>
        <div class="d-flex my-3 align-items-center" v-else @click="directChat(member.accountcode)">
          <vb-avatar :id="member.accountcode" :is_blf="false" />
          <p class="ml-3 mb-0" :aria-disabled="api_sent.direct_chat">
            {{ $store.getters.getVoipUsersAlisesDisplayName[member.accountcode] }}
          </p>
        </div>
      </div>
    </div>
  </li>
</template>

<script>
import { mattermost, MATTERMOST_API } from "@/MatterMost";
import { events } from "@/utils";
import _ from "lodash";
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_CHAT } from '@/store/helper/getters';
export default {
  name: "Item",
  props: {
    type: {
      type: String,
    },
    tabType: {
      type: String,
    },
    data: {
      type: Object,
    },
  },
  data() {
    return {
      channel_id: '',
      loaded: false,
      user_male: require("@/assets/images/user-male.png"),
      api_sent: {
        direct_chat: '',
      },
      expand: false,
    };
  },
  inject:['isEmpty', 'getProperty'],
  computed: {
    ...mapGetters([
      GET_LOCAL_SETTING_CHAT
    ]),
    typingMessage(){
      const users = Object.values(this.channelData?.typing_users ?? {})
      if(users.length>2) return `${users.length} people`
      else if(users.length==1) return `${this.$store.getters.getVoipUsersAlisesDisplayName[users[0]]}`
      else if(users.length==2) return `${this.$store.getters.getVoipUsersAlisesDisplayName[users[0]]} and ${this.$store.getters.getVoipUsersAlisesDisplayName[users[1]]}`
      else return ''
    },
    isMuted(){
      return this.$store.state.mattermost.channels?.[this.channel_id]?.notification_prference?.is_mute ?? false
    },
    channelData(){return this.$store.state.mattermost.channels[this.channel_id] || {}},
    channelDraft(){
      return (!_.isEmpty(this.channelData?.actions?.message) || !_.isEmpty(this.channelData?.actions?.files)) && !this.$store.state.mattermost.channels_view.includes(this.channel_id)
    },
    draftMessage(){
      return (!_.isEmpty(this.channelData?.actions?.message) || !_.isEmpty(this.channelData?.actions?.files)) && !this.$store.state.mattermost.channels_view.includes(this.channel_id) && this.channelData?.actions
    },
    lastPost(){
      if(_.isEmpty(this.channelData)) return {}
      if(this.channelData.posts.order.length===0) return {}
      const order = this.channelData.posts.order
      const id = order[order.length-1]
      return this.channelData.posts.posts[id]
    },
    lastMessage() {
      var msg = "";
      if (!_.isEmpty(this.lastPost)) {
        const { props, type, message, metadata } = this.lastPost;
        if (!type) {
          if (message) {
            msg = message;
          } else {
            if (metadata && metadata.files) {
              if (metadata.files.length > 1) {
                msg = "files";
              } else {
                msg = metadata.files[0].mime_type.split("/")[0] + " message";
              }
            }
          }
        } else {
          const t_type =  this.type?.toLowerCase?.()=='channel' ? 'group' : this.type
          switch (type) {
            case "system_displayname_change":
              if (props.old_displayname) {
                 msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} updated the ${t_type} display name from ${props.old_displayname} to ${props.new_displayname}.`;
              } else {
                msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} set the ${t_type} display name ${props.new_displayname}.`;
              }
              break;
            case "system_header_change":
              if (props.old_header) {
                msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} updated the ${t_type} header from ${props.old_header} to ${props.new_header}`;
              } else {
                msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} set the ${t_type} header ${props.new_header}`;
              }
              break;
            case "system_purpose_change":
              if (props.old_purpose) {
                msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} updated the ${t_type} purpose from ${props.old_purpose} to ${props.new_purpose}.`;
              } else {
                msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} set the ${t_type} purpose ${props.new_purpose}.`;
              }
              break;
            case "system_add_to_channel":
              msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.addedUsername]} added to the ${t_type} by ${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]}.`;
              break;
            case "system_remove_from_channel":
              msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.removedUsername]} removed from the ${t_type}`;
              break;
            case "system_channel_deleted":
              msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} delete the ${t_type}.`;
              break;
            case "system_leave_channel":
              msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} leave the ${t_type}.`;
              break;
            case "system_join_channel":
              msg = `${this.$store.getters.getVoipUsersAlisesDisplayName[props.username]} created the ${t_type}.`;
              break;
            default:
              msg = this.last_post.message;
              break;
          }
        }
      }
      return msg;
    },
    name(){
      if(_.isEmpty(this.data)) return ''
      return this.type === "channel" && this.channelData.channel ? this.channelData.channel?.display_name ?? '' : this.type === "user" ? this.data?.user?.display_name ?? '' : this.type === "team" ? this.data?.name ?? '' : '';
    },
    image(){
      return (this.type === "user" && this.data?.profile_img) || (this.type === "team" && this.data.avatar?.image?.image) || require("@/assets/images/channel-image.svg")
    },
    iddd(){ return (this.type === "user" && this.data?.voipaccount) || (this.type === "team" && this.data?.real_id) || '' },
    // teamMembersImages(){
    //   if(this.type=='team'){
    //     const length = this.data.members.length
    //     if(length==1){
    //       return this.$store.getters.getVoipUsersAlisesImages[this.data.members[0].accountcode]
    //     } else if(length<4&&length>1){
    //       let array = []
    //       for (let index = 0; index < this.data.members.length; index++) {
    //         const member = this.data.members[index];
    //         const image = this.$store.getters.getVoipUsersAlisesImages[member.accountcode]
    //         array.push({
    //           image: image
    //         })
    //       }
    //       return array
    //     } else if(length>=4){
    //       let array = []
    //       for (let index = 0; index < 2; index++) {
    //         const member = this.data.members[index];
    //         const image = this.$store.getters.getVoipUsersAlisesImages[member.accountcode]
    //         array.push({
    //           image:image
    //         })
    //       }
    //       array.push({
    //         numbers: this.data.members.length-2
    //       })
    //       return array

    //     }
    //     return []
    //   } else {
    //     return []
    //   }
    // },
  },
  watch: {
    "data.channel_id"(channel_id){
      this.channel_id=channel_id
    },
    channel_id(channel_id){
      if(this.tabType==='recent' || this.tabType==='searched') return;
      if(!this.loaded && channel_id){
        this.$root.$emit(events.check_unread_count_api,{
          channel_id: channel_id
        })
        this.loaded=true
      }
    },
    "channelData.posts.order"(order){
      if(this.tabType==='recent' || this.tabType==='searched') return;
      if(order?.length===0 && !(this.channelData?.channel?.total_msg_count===0 && this.channelData?.type==='user')){
        this.$root.$emit(events.get_messages_api,{
          channel_id: this.channel_id
        })
      }
    }
  },
  methods: {
    async select() {
      try {
        if(!this.channel_id){
          // const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([this.data.user.user_chat_id,this.mattermost_current_user.id]);
          const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([this.data.user.user_chat_id,this.$store.getters.getMattermostCurrentUser?.id]);
          // this.$root.$emit(events.adding_channel,{
          //   channel_id: channel.id,
          //   data: {
          //     channel: channel,
          //     type: 'user'
          //   }
          // })
          mattermost.emitOnUserLevel(mattermost.events.direct_added,{
            channel_id: channel.id
          })
          this.$set(this.$store.state.common.voipusers[this.data.voipaccount],'channel_id',channel.id)
          this.channel_id = channel.id
        }
        // this.$store.state.mattermost.channels[this.channel_id].data=this.data
        // this.$parent.$parent.$parent.$parent.$parent.$refs.box.channel_id=this.channel_id
        this.$emit('change-channel-id',this.channel_id)
        this.$root.$emit(events.pinned_post_changed,{
          channel_id: this.channel_id
        })
      } catch (error) {
        // console.log("error in select function", error);
      }
    },
    load(){
      this.channel_id = this.type === "channel" ? this.data.id : this.data.channel_id;
    },
    async directChat(account){

      const user = this.$store.getters.getVoipUsersAlises[account]; //this.getUserInfo(account)
      if(!user || this.api_sent.direct_chat) return;
      this.api_sent.direct_chat=account
      try {
        var channel_id = user.channel_id || '';
        if(!channel_id){
          // const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([user.user.user_chat_id,this.mattermost_current_user.id]);
          const { data: channel } = await MATTERMOST_API.endpoints.channels.createDirectChannel([user.user.user_chat_id,this.$store.getters.getMattermostCurrentUser?.id]);
          // this.$root.$emit(events.adding_channel,{
          //   channel_id: channel.id,
          //   data: {
          //     channel: channel,
          //     // data: user,
          //     id: user?.voipaccount,
          //     type: 'user'
          //   }
          // })
          mattermost.emitOnUserLevel(mattermost.events.direct_added,{
            channel_id: channel.id
          })
          this.$set(this.$store.state.common.voipusers[user.voipaccount],'channel_id',channel.id)
          channel_id = channel.id
        }
        this.$store.state.mattermost.channels[channel_id].data=user
        // this.$parent.$parent.$parent.$parent.$parent.$refs.box.channel_id=channel_id
        this.$emit('change-channel-id',channel_id)
        this.$root.$emit(events.pinned_post_changed,{
          channel_id: channel_id
        })
      } catch (error) {
        // console.log("error in directChat function", error);
      } finally {
        this.api_sent.direct_chat = ''
      }
    },
  },
  created() {
    this.load()
  },
};
</script>

<style lang="scss">
.typing-text {
  color: $primary !important;
}
.info-area {
  .name {
    display: flex;
    justify-content: space-between;
    align-items: center;
    h5 {
      width: 75%;
    }
    span {
      width: 25%;
      font-size: 14px;
    }
  }
  p {
    color: $body-color;
    font-size: 14px;
  }
}
.text-dotted {
  white-space: nowrap;
  text-overflow: ellipsis;
  overflow: hidden;
  display: inline-block;
  width: 90%;
}
</style>
<style lang="scss" scoped>

.status-1{
  background-color: $green;
}
.status-2{
  background-color: $yellow;
}
.status-3{
  background-color: $red;
}
.status-4{
  background-color: $black;
}
.status-18{
  background-color: $orange;
}
</style>
