var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Teams")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddTeam', {
          suggested_name: `team ${_vm.voip_teams.length + 1}`
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    }
  })], 1)]), _c('section', {
    staticClass: "dialer-settings-section dialer-ivr-setting TeamsSettings"
  }, [[_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search teams"
    },
    model: {
      value: _vm.filter.teams.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.teams, "search", $$v);
      },
      expression: "filter.teams.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1)], _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, _vm._l(_vm.filterTeams, function (team) {
    return _c('div', {
      key: team.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          return _vm.$modal.show('EditTeam', {
            real_id: team.real_id
          });
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "InfoComponentInsideAnroidIOSApp"
    }, [_c('vb-avatar', {
      attrs: {
        "id": team.real_id,
        "is_blf": false
      }
    })], 1), _c('div', {
      staticClass: "d-flex flex-column"
    }, [[_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(" " + _vm._s(team.name || '') + " ")])]], 2)]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [team.ringroup && team.ringroup.did && team.ringroup.did[0] ? _c('div', {
      staticClass: "d-flex mr-6px align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(team.ringroup.did[0].did)))])]) : _vm._e(), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)]);
  }), 0), _c('EditTeamModal', {
    on: {
      "updated": function ($event) {
        return _vm.afterAdding();
      }
    }
  })], 2), _c('AddTeamModal', {
    on: {
      "interface": function ($event) {
        return _vm.afterAdding();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }