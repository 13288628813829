var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition sipActiveCall-Modal SelectSnoozeTimeModal SelectSnoozeTimeModal-fromLeftSideBar",
    attrs: {
      "transition": "center-zoomIn-transition",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-call-notification-tray"
  }, [_c('div', {
    staticClass: "dialer-call-notification dialer-keypadNotification"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Select time for snooze")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])])]), _c('div', {
    staticClass: "dialer-call-notification-actions mt-20px"
  }, [_c('div', {
    staticClass: "TabsDesignWithIcon-container mb-20px"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.value,
      staticClass: "TabsDesignWithIcon-item",
      class: {
        'active': _vm.selected.tab == tab.value
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": tab.icon,
        "height": "38px",
        "width": "38px"
      }
    }), _c('span', {
      staticClass: "TabsDesignWithIcon-item-textPart"
    }, [_vm._v(_vm._s(tab.text))])], 1);
  }), 0), _vm.selected.tab == _vm.tabs.time.value ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between mb-20px"
  }, [_c('span', [_vm._v(_vm._s(_vm.current_date.time))]), _c('span', [_vm._v(_vm._s(_vm._f("time_audio")(_vm.duration)))])]), _c('b-time', {
    staticClass: "newlyDesignedTimeSection",
    attrs: {
      "show-seconds": "",
      "locale": "en"
    },
    model: {
      value: _vm.forms.time.time,
      callback: function ($$v) {
        _vm.$set(_vm.forms.time, "time", $$v);
      },
      expression: "forms.time.time"
    }
  }), _vm.forms.time.submitted ? [_vm.$v.forms.time.time.$invalid ? _c('p', {
    staticClass: "w-100 text-left error-red"
  }, [!_vm.$v.forms.time.time.required ? _c('span', [_vm._v("* Time is required")]) : !_vm.$v.forms.time.time.valid ? _c('span', [_vm._v("* Time is invalid")]) : !_vm.$v.forms.time.time.invalid ? _c('span', [_vm._v("* Time is should be after current time")]) : _vm._e()]) : _vm._e()] : _vm._e()], 2)] : _vm.selected.tab == _vm.tabs.timer.value ? [_c('div', {
    staticClass: "mainWrapper dialer-box grayBox"
  }, [_c('div', {
    staticClass: "hoursWrapper"
  }, [_c('div', {
    staticClass: "heading-container"
  }, [_vm._v("HH")]), _c('div', {
    staticClass: "inputButton-container"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "disabled": parseInt(_vm.forms.timer.hours) <= '0'
    },
    on: {
      "click": function ($event) {
        _vm.forms.timer.hours = parseInt(_vm.forms.timer.hours) - 1;
      }
    }
  }, [_vm._v("-")]), _c('div', {
    staticClass: "dialer-input-field input-consistent-inner-shadows whiteBg-input"
  }, [_c('input', {
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.forms.timer.hours
    }
  })]), _c('button', {
    staticClass: "dialer-button dialer-button-primary",
    on: {
      "click": function ($event) {
        _vm.forms.timer.hours = parseInt(_vm.forms.timer.hours) + 1;
      }
    }
  }, [_vm._v("+")])])]), _c('div', {
    staticClass: "mintsWrapper"
  }, [_c('div', {
    staticClass: "heading-container"
  }, [_vm._v("MM")]), _c('div', {
    staticClass: "inputButton-container"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "disabled": parseInt(_vm.forms.timer.mints) <= '0'
    },
    on: {
      "click": function ($event) {
        _vm.forms.timer.mints = parseInt(_vm.forms.timer.mints) - 1;
      }
    }
  }, [_vm._v("-")]), _c('div', {
    staticClass: "dialer-input-field input-consistent-inner-shadows whiteBg-input"
  }, [_c('input', {
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.forms.timer.mints
    }
  })]), _c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "disabled": parseInt(_vm.forms.timer.mints) >= '60'
    },
    on: {
      "click": function ($event) {
        _vm.forms.timer.mints = parseInt(_vm.forms.timer.mints) + 1;
      }
    }
  }, [_vm._v("+")])])]), _c('div', {
    staticClass: "scndsWrapper"
  }, [_c('div', {
    staticClass: "heading-container"
  }, [_vm._v("SS")]), _c('div', {
    staticClass: "inputButton-container"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "disabled": parseInt(_vm.forms.timer.seconds) <= '0'
    },
    on: {
      "click": function ($event) {
        _vm.forms.timer.seconds = parseInt(_vm.forms.timer.seconds) - 1;
      }
    }
  }, [_vm._v("-")]), _c('div', {
    staticClass: "dialer-input-field input-consistent-inner-shadows whiteBg-input"
  }, [_c('input', {
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.forms.timer.seconds
    }
  })]), _c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "disabled": parseInt(_vm.forms.timer.seconds) >= '60'
    },
    on: {
      "click": function ($event) {
        _vm.forms.timer.seconds = parseInt(_vm.forms.timer.seconds) + 1;
      }
    }
  }, [_vm._v("+")])])])]), _vm.forms.timer.submitted && _vm.$v.forms.timer.$invalid ? _c('div', {
    staticClass: "w-100 text-left error-red"
  }, [_vm._v("* time is required")]) : _vm._e()] : _vm._e(), _c('button', {
    staticClass: "fullWidthDoneButton mt-20px",
    on: {
      "click": _vm.onDone
    }
  }, [_vm._v(" Done ")])], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }