var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "basicWhiteIOScard-item noBottomBorder",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.randomID}-CallForwardingSettingModal`, {
          account: _vm.voipaccount,
          setting: _vm.response.forwarding_setting
        });
      }
    }
  }, [_vm.response.forwarding_setting.forward_note == 'voicemail' ? [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.timeout) + " Seconds ")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)] : _vm.response.forwarding_setting.forward_note == 'extension' ? [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Redirect to a user, team or IVR menu")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.extension_name) + " ")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)] : _vm.response.forwarding_setting.forward_note == 'number' ? [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Redirect to another number")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.forward_number))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)] : [_c('div', {
    staticClass: "headingInsideCard"
  }, [_vm._v("Forwarding")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
  }, [_vm._v("Keep ringing ")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)]], 2), _c('CallForwardingModal', {
    attrs: {
      "modalName": `${_vm.randomID}-CallForwardingSettingModal`,
      "randomID": _vm.randomID
    },
    on: {
      "updated": function ($event) {
        return _vm.updateForwardingSettings($event);
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }