<template>
  <modal
    class="dialer_animation right_side_popup"
    width="40%"
    height="auto"
    :scrollable="true"
    :name="modalName"
    @before-open="onBeforeOpen"
    @before-close="onBeforeClose"
  >
    <section class="ct-user-new dialer-edit">
        <div class="dialer-edit-header">
          <h2 class="dialer-edit-title">
            <div class="d-flex align-items-center">
              <vb-svg
                class="cursor_pointer"
                name="leftLongArrow-icon"
                width="20"
                height="12"
                viewBox="0 0 30 16"
                stroke-width="1"
                stroke=""
                fill="none"
                stroke-linecap="round"
                stroke-linejoin="round"
                @click="$modal.hide(modalName)"
              />
              <span class="dialer-edit-title md-mar-left">Select account</span>
            </div>
          </h2>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="onDone()">
              <!-- Apply -->
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Apply</span>
            </button>
          </div>
        </div>
        <div class="dialer-tabs">
          <div class="dialer-tab-content">
            <div class="w-100 md-mar-bottom  whiteBGinputWithGreyRoundedBorder">
              
              <b-input type="text" class="w-100" placeholder="Search sound" v-model="filter.accounts.search" />
            </div>
            <ul class="dialer-asssignee-list">
              <li v-for="account in filterAccounts" :key="account.accountcode" class="dialer-assignee dialer-assignee-new">
                <b-icon 
                  :icon="selected.account.accountcode === account.accountcode? 'check-circle-fill' : 'circle'" 
                  variant="primary" 
                  class="mr-3 ml-0" 
                  font-scale="1.3" 
                  @click="selected.account = account" 
                />
                <Info :id="account.accountcode" :image="''" :name="account.accountname" :sub_info="account.extn" />
              </li>
            </ul>
          </div>
        </div>
      </section>
  </modal>
</template>

<script>
import _ from 'lodash'
import Info from '../Lists/Info.vue'
export default {
  components: { Info },
  name: 'SelectAccounts',
  props: {
    modalName: {
      type: String,
      default: 'SelectAccountsModal',
    },
  },
  data(){
    return {
      data: {
        accounts: [],
        reset(){
          this.accounts=[]
        },
      },
      selected: {
        account: {},
        reset(){
          this.accounts={}
        },
      },
      filter: {
        accounts: {
          search: '',
          reset(){
            this.search=''
          },
        },
      },
    }
  },
  computed: {
    filterAccounts(){
      const search = this.filter.accounts.search.trim().toLowerCase()
      const array = this.data.accounts
      return array.filter(account=>`${account.accountname} ${account.extn}`.trim().toLowerCase().includes(search))
    },
  },
  methods: {
    onBeforeOpen(events){
      this.data.accounts = events.params.accounts || []
      this.selected.account = events.params.selected || {}
    },
    onBeforeClose(){
      this.data.reset()
      this.filter.accounts.reset()
      this.selected.reset()
    },
    onDone(){
      if(_.isEmpty(this.selected.account)) return;
      this.$emit('selected-account',this.selected.account)
      this.$modal.hide(this.modalName)
    },
  },
}
</script>

