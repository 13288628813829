<template>
  <div class="dialer-settings-main">
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">IVR</div>
      <div class="plus-IOSIcon-container" @click="api.add_ivr.send ? '' : $modal.show('AddIVR',{ suggested_name: `IVR MENU ${Object.values(getVoipIVRS).length+1}` })">
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992"  />
      </div>
    </div>
    <section class="dialer-settings-section dialer-ivr-setting">
      <div class="mobileInputIOS iconOnLeft mt-32px">
        <b-input type="text" placeholder="Search numbers" v-model="filter.ivrs.search" />
        <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
      </div>
      

      
      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div class="basicWhiteIOScard-item" @click="$modal.show('EditIVR', { accountcode: menu.voipaccount })" v-for="menu in filterIVR" :key="menu.voipaccount">
          <div class="d-flex align-items-center">
            <div class="InfoComponentInsideAnroidIOSApp">
              <vb-avatar :id="menu.voipaccount" />
            </div>
            <div class="d-flex flex-column">
              <template >
                <div class="headingInsideTable">
                  {{ menu.label || '' }}
                </div>
              </template>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <vb-audio-player class="IosAudioPlayer mr-8px" :src="menu | welcomgreetingsrc" />
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
        </div>
      </div>
      
      
      <EditIVRModal @update-list="afterAdding()" />
      <AddIVRModal @added="afterAdding()" />
    </section>
  </div>
</template>

<script>
import AddIVRModal from "../modals/ivr/AddIVR.vue";
import EditIVRModal from "../modals/ivr/EditIVR.vue";

import { VOIP_API } from "../../../utils";
import { mapGetters, mapState } from 'vuex';
import { welcomgreetingsrc } from '@/filter';
import { permission_handling } from '@/mixin';
const {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} = require("unique-names-generator");

export default {
  name: "Menus",
  mixins: [permission_handling],
  components: {
    AddIVRModal,
    EditIVRModal,
  },
  inject:['isEmpty','getProperty','getIvrs','appNotify'],
  data() {
    return {
      filter: {
        ivrs: {
          search: '',
        },
      },
      api: {
        ivrs: this.$helperFunction.apiInstance(),
        add_ivr: this.$helperFunction.apiInstance(),
      },
    };
  },
  computed: {
    ...mapState({
      total_ivrs: (state) => state.common.ivr.total
    }),
    ...mapGetters([
      'getVoipIVRS',
      'getUserPermissions'
    ]),
    filterIVR(){
      const search = this.filter.ivrs.search.trim().toLowerCase()
      return Object.values(this.getVoipIVRS).filter(item=>`${item.label} ${item.extn}`.trim().toLowerCase().includes(search))
    },
  },
  filters: {
    welcomgreetingsrc: welcomgreetingsrc,
  },
  methods: {
    fetchIvrs() {
      let vm = this
      vm.api.ivrs.send=true
      vm.getIvrs()
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.ivrs.send=false
      })
    },
    afterAdding() {
      let vm = this
      setTimeout(() => {
        vm.fetchIvrs();
      }, 2 * 1000);
    },
    addivr() {
      let vm = this;
      if (vm.api.add_ivr.send) return;
      const randomName = uniqueNamesGenerator({
        dictionaries: [adjectives, colors, animals],
        separator: " ",
        length: 2,
      });
      vm.$appConfirmation({
        title: "Confirmation",
        message: `Are you sure you want to add IVR`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback({confirm}) {
          if (confirm) {
            vm.api.add_ivr.send = true;
            VOIP_API.endpoints.menu.create({
              name: `${randomName} IVR`,
            }).then(() => {
              vm.appNotify({
                message: "Successfully Added!",
                type: "success",
              })
              vm.afterAdding();
            }).catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(() => {
              vm.api.add_ivr.send = false;
            });
          }
        },
      });
    },
  },
  mounted(){
    this.fetchIvrs();
  },
  activated() {
  },
};
</script>
