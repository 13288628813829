<template>
  <modal 
    class="center-zoomIn-transition v2 SendSmsModal" 
    width="400px" 
    height="auto" 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()" 
  >
    <div class="dialer-edit-user w-100">
      <div class="dialer-edit">
        <div class="dialer-edit-header w-100 d-flex justify-content-between align-items-center">
          <h2 class="dialer-edit-title mb-0 newer">Pricing</h2>
          <div class="dialer-edit-actions">
            <a class="newCloseButton" @click="$modal.hide(modalName)">
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div>
        </div>
        <div class="dialer-edit-step-block">
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <template v-if="api.fetch_call_balance.send">
                <!-- Loading... -->
                <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset">
                  <div class="latestShimmerDesign mt-1" style="height:24px;width:50%"></div>
                  <div class="latestShimmerDesign mt-1" style="height:24px;width:40px"></div>
                </div>
              </template>
              <div v-else class="whiteBoxWithBorderInsideGreyBox-row min-height-unset">
                <div class="latestGreyBox-heading-main">Call price per destination</div>
                <div class="latestGreyBox-heading-main-24px color-343434">£{{response.call_balance | get_property('callRate',0)}}</div>
              </div>
              <template v-if="api.fetch_sms_balance.send">
                <!-- Loading... -->
                <div class="whiteBoxWithBorderInsideGreyBox-row min-height-unset">
                  <div class="latestShimmerDesign mt-1" style="height:24px;width:50%"></div>
                  <div class="latestShimmerDesign mt-1" style="height:24px;width:40px"></div>
                </div>
              </template>
              <div v-else class="whiteBoxWithBorderInsideGreyBox-row min-height-unset">
                <div class="latestGreyBox-heading-main">SMS price per destination</div>
                <div class="latestGreyBox-heading-main-24px color-343434">£{{response.sms_balance | get_property('smsRate',0)}}</div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { $fn, VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
export default {
  name: 'PricingModal',
  props: {
    modalName: {
      type: String,
      default: 'Pricing'
    },
  },
  data() {
    return {
      api: {
        fetch_call_balance: this.$helperFunction.apiInstance(),
        fetch_sms_balance: this.$helperFunction.apiInstance(),
      },
      response: {
        sms_balance: {},
        call_balance: {},
      },
      data: {
        number: '',
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ])
  },
  methods: {
    onBeforeOpen(event) {
      this.data.number=$fn.getNumberFormated(event?.params?.number,'')
      this.fetchSMSBalance()
      this.fetchCallBalance()
    },
    onBeforeClose() {
      this.data.number=''
      this.response.sms_balance={}
      this.response.call_balance={}
      this.api.fetch_call_balance.reset()
      this.api.fetch_sms_balance.reset()
    },
    async fetchSMSBalance(){
      if(this.api.fetch_sms_balance.send) return;
      try {
        this.api.fetch_sms_balance.send=true
        const { data } = await VOIP_API.endpoints.sms.balanceInquiry({
          uid: this.getCurrentUser?.uid,
          number: this.data.number,
        })
        this.response.sms_balance=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message ?? ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_sms_balance.send=false
      }
    },
    async fetchCallBalance(){
      if(this.api.fetch_call_balance.send) return;
      try {
        this.api.fetch_call_balance.send=true
        const { data } = await VOIP_API.endpoints.billing.callBalance({
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
          number: this.data.number,
        })
        this.response.call_balance=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message ?? ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_call_balance.send=false
      }
    },
  },
}
</script>

<style>

</style>