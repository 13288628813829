<template>
  <modal class="dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll" name="AddTrainingEventsCategoryModal" :clickToClose="true" :scrollable="true">
    <div class="modalHeading-container">
      <div class="modalHeading">Add category</div>
      <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('AddTrainingEventsCategoryModal')">Close</button>
    </div>
    <div class="modalInner-container">
      <div class="innerShadowInput-container">
        <label>Name</label>
        <input class=""/>
      </div>
      <div class="d-flex flex-wrap w-100 mt-32px align-items-center">
        <b-form-checkbox class="HrmCheckBox-container">
          Notify employees by email
        </b-form-checkbox>
        <div class="innerShadowInput-container my-0" style="width: 68px;">
          <!-- <label>Name</label> -->
          <input placeholder="2" class=""/>
        </div>
        <div class="innerShadowInput-container ml-3 my-0 w-auto">
          <label>( days before due date )</label>
          <!-- <input placeholder="2" class=""/> -->
        </div>
      </div>
      <button class="dialer-button dialer-button-primary mt-32px mr-0"> Add </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'AddTrainingEventsCategoryModal',
}
</script>

<style>

</style>