<template>
  <div>
    <div class="mainHeading">Documents</div>
    <div class="mainDescription">
      <br/>
    </div>
    <div class="grayCard flexWrap">
      <div class="whiteSubCard halfWidth mb-0">
        <div class="whiteSubCard-head">
          Documents in last 30 days
        </div>
        <div class="whiteSubCard-body">
          <div>24</div>
        </div>
      </div>
      <div class="whiteSubCard halfWidth mb-0">
        <div class="whiteSubCard-head">
          Total uploaded
        </div>
        <div class="whiteSubCard-body">
          <div>50</div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'EmployeeDataDocuments'
}
</script>

<style>

</style>