<template>
  <component
    :is="component"
    :class="{
      'visibleTransition': isVisible,
    }"
    v-observe-visibility="{
      callback: visibilityChanged,
      intersection: {
        threshold: 1,
      },
    }"
    :call="call"
    :assignedData="assignedData"
    :type="type"
    :conditions="condition"
    @call="dial(call,$event)"
    @sms="sms(call,$event)"
    @info="$emit('onClickInfo1',$event)"
    @assign="$emit('clickAssignDetail')"
    @transcription="$emit('openOnlyTranscriptModal')"
    @play-audio="$emit('click-play',$event)"
  >
    <template v-for="(slot,key) in $scopedSlots" v-slot:[key]>
      <slot :name="key"></slot>
    </template>
  </component>
</template>

<script>
import SipCallLog from './SipCallLog.vue'
import JitsiCallLog from './JitsiCallLog.vue'
import VoicemailLog from './VoicemailLog.vue'
import SMSLog from './SMSLog.vue'
import AMILog from './AMILog.vue'
import { events } from '@/utils'
import { number_formater } from '@/filter'
import _ from "lodash";
export default {
  name: "CallActivityItem",
  components: {
    SipCallLog,
    JitsiCallLog,
    VoicemailLog,
    SMSLog,
    AMILog,
  },
  props: {
    call: {
      type: Object,
      default: () => ({}),
      required: true,
    },
    assignedData: {
      type: Object,
    },
    type: {
      type: String,
      default: 'activity',
    },
    conditions: {
      type: Object,
      default: () => ({})
    },
  },
  data() {
    return {
      isVisible: false
    }
  },
  computed: {
    component() {
      if(this.call.call_type=='audio') return 'SipCallLog'
      else if(this.call.call_type=='video') return 'JitsiCallLog'
      else if(this.call.call_type=='voicemail') return 'VoicemailLog'
      else if(this.call.call_type=='sms') return 'SMSLog'
      else if(this.call.ami) return 'AMILog'
      return '' 
    },
    condition(){
      return {
        tag: _.isUndefined(_.get(this.conditions,'tag')) ? true : _.get(this.conditions,'tag'),
        note: _.isUndefined(_.get(this.conditions,'note')) ? true : _.get(this.conditions,'note'),
        recording: _.isUndefined(_.get(this.conditions,'recording')) ? true : _.get(this.conditions,'recording'),
        message: _.isUndefined(_.get(this.conditions,'message')) ? true : _.get(this.conditions,'message'),
        assign: _.isUndefined(_.get(this.conditions,'assign')) ? false : _.get(this.conditions,'assign'),
        actions: _.isUndefined(_.get(this.conditions,'actions')) ? true : _.get(this.conditions,'actions'),
      }
    },
  },
  methods: {
    visibilityChanged (isVisible) { // , entry
      this.isVisible = isVisible
    },
    dial(call,event){
      const { dialable, call_type, type } = event
      if(type=='jitsi') {
        this.$root.$emit(events.video_call, { 
          accounts: typeof dialable=="string" ? [dialable] : dialable, 
          call_type: call_type ?? 'audio' 
        });
      } else {
        this.$root.$emit(events.audio_call, { 
          number: number_formater(dialable) 
        });
      }
    },
    sms(call,event){
      const { number } = event
      this.$root.$emit(events.send_sms, { dialable: number });
    },
  },
};
</script>

<style>
</style>