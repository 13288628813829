var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-userInfoMenu v2 mobile text-center w-100 position-absolute py-3"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    on: {
      "click": function ($event) {
        _vm.$store.state.global_conditions.tab = 'user_profile';
      }
    }
  }, [_c('vb-avatar', {
    attrs: {
      "is_blf": false,
      "image": _vm.$store.getters.getCurrentUser.profileImg,
      "status_id": _vm.$store.getters.getCurrentUser.status_id,
      "name": _vm.$store.getters.getCurrentUser.display_name
    }
  }), _vm.snooze_label != 'off' ? _c('span', {
    staticClass: "with-border"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "dialer-snoozeIcon-light",
      "width": "14",
      "height": "14"
    }
  })], 1) : _vm._e()], 1)] : _vm.$store.getters.isIntegration ? _c('b-dropdown', {
    staticClass: "user-thumbnail side_bar_profile_dropdown",
    attrs: {
      "id": "dropdown-1",
      "varient": "link",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-avatar', {
          attrs: {
            "is_blf": false,
            "image": _vm.$store.getters.getCurrentUser.profileImg,
            "status_id": _vm.$store.getters.getCurrentUser.status_id,
            "name": _vm.$store.getters.getCurrentUser.display_name
          }
        }), _vm.$store.getters.getCurrentUser.status_id && _vm.snooze_label ? [_vm.snooze_label != 'off' && _vm.snooze_label != 'unlimited' ? _c('span', {
          staticStyle: {
            "color": "#ffffff"
          }
        }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.$store.state.sip.snooze_time)))]) : _vm._e(), _vm.snooze_label != 'off' ? _c('span', {
          staticClass: "with-border"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "dialer-snoozeIcon-light",
            "width": "14",
            "height": "14"
          }
        })], 1) : _vm._e()] : _vm._e(), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("User Menu")])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "border-bottom"
  }, [_c('div', {
    staticClass: "dialer_contact"
  }, [_c('div', {
    staticClass: "user-profile"
  }, [_c('vb-avatar', {
    attrs: {
      "is_blf": false,
      "image": _vm.$store.getters.getCurrentUser.profileImg,
      "status_id": _vm.$store.getters.getCurrentUser.status_id,
      "name": _vm.$store.getters.getCurrentUser.display_name
    }
  })], 1), _c('div', {
    staticClass: "user-info"
  }, [_c('span', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm.$store.getters.getCurrentUser.display_name))]), _c('span', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm.$store.getters.getCurrentUser.extn))])])])]), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.logout({
          is_user: true
        });
      }
    }
  }, [_vm.$store.state.logout_running ? _c('b-icon', {
    attrs: {
      "icon": "arrow-clockwise",
      "animation": "spin-pulse",
      "variant": "danger"
    }
  }) : [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "box-arrow-right",
      "scale": "0.8"
    }
  }), _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Logout")])]], 2)], 1) : _c('b-dropdown', {
    staticClass: "user-thumbnail desktopVersion side_bar_profile_dropdown",
    attrs: {
      "id": "dropdown-1",
      "varient": "link",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-avatar', {
          attrs: {
            "is_blf": false,
            "loading": _vm.$store.state.logout_running,
            "image": _vm.$store.getters.getCurrentUser.profileImg,
            "status_id": _vm.$store.getters.getCurrentUser.status_id,
            "name": _vm.$store.getters.getCurrentUser.display_name
          }
        }), _vm.$store.getters.getCurrentUser.status_id && _vm.snooze_label ? [_vm.snooze_label != 'off' && _vm.snooze_label != 'unlimited' ? _c('span', {
          staticStyle: {
            "color": "#ffffff",
            "font-size": "11px"
          }
        }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.$store.state.sip.snooze_time)))]) : _vm._e(), _vm.snooze_label != 'off' ? _c('span', {
          staticClass: "with-border"
        }, [_c('vb-icon', {
          attrs: {
            "icon": "dialer-snoozeIcon-light",
            "width": "14",
            "height": "14"
          }
        })], 1) : _vm._e()] : _vm._e(), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("User Menu")])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "border-bottom"
  }, [_c('div', {
    staticClass: "dialer_contact"
  }, [_c('div', {
    staticClass: "user-profile"
  }, [_c('vb-avatar', {
    attrs: {
      "is_blf": false,
      "image": _vm.$store.getters.getCurrentUser.profileImg,
      "status_id": _vm.$store.getters.getCurrentUser.status_id,
      "name": _vm.$store.getters.getCurrentUser.display_name
    },
    on: {
      "click": function ($event) {
        return _vm.onClickAvatar();
      }
    }
  })], 1), _c('div', {
    staticClass: "user-info",
    on: {
      "click": function ($event) {
        return _vm.onClickAvatar();
      }
    }
  }, [_c('span', {
    staticClass: "user-name",
    on: {
      "click": function ($event) {
        return _vm.onClickAvatar();
      }
    }
  }, [_vm._v(_vm._s(_vm.$store.getters.getCurrentUser.display_name))]), _c('span', {
    staticClass: "user-number",
    on: {
      "click": function ($event) {
        return _vm.onClickAvatar();
      }
    }
  }, [_vm._v(_vm._s(_vm.$store.getters.getCurrentUser.extn))]), !_vm.$store.getters.isIntegration ? [_c('b-dropdown-item', {
    staticClass: "position-relative dialer-organization opacity-1 dd-opacity-1",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-userProfileStatusModal`);
      }
    }
  }, [_c('vb-status', {
    staticClass: "position-relative top-0 left-0 bottom-0",
    attrs: {
      "statusId": _vm.$store.getters.getCurrentUser.status_id
    }
  }), _c('span', {
    staticClass: "ml-1"
  }, [_vm._v(_vm._s(_vm.$store.getters.getCurrentUser.status))])], 1)] : _vm._e()], 2)])]), !_vm.$store.getters.isIntegration && _vm.getProperty(_vm.$store.getters.getCurrentUser, 'organizations.length', 0) > 1 ? _c('b-dropdown-item', {
    staticClass: "position-relative dialer-organization"
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "building",
      "scale": "0.8"
    }
  }), _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Select Organization")]), _c('b-icon', {
    staticClass: "arrow_icon",
    attrs: {
      "icon": "chevron-down",
      "scale": "0.7"
    }
  }), _c('transition', {
    attrs: {
      "name": "slide-in"
    }
  }, [_c('ul', {
    staticClass: "organization-list forStatusList"
  }, _vm._l(_vm.$store.getters.getCurrentUser.organizations, function (organization, index) {
    return _c('li', {
      key: index,
      on: {
        "click": function ($event) {
          organization.id === _vm.$store.getters.getCurrentUser.selected_organization_id ? '' : _vm.switchOrganization(organization);
        }
      }
    }, [_c('a', [_vm._v(_vm._s(organization.label))]), _vm.$store.getters.getCurrentUser.selected_organization_id === organization.id ? _c('b-icon', {
      staticClass: "status-1",
      attrs: {
        "icon": "check-circle-fill",
        "scale": "0.8"
      }
    }) : _vm._e()], 1);
  }), 0)])], 1) : _vm._e(), _c('b-dropdown-item', {
    staticClass: "position-relative dialer-organization second opacity-1",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-snoozeModal`);
      }
    }
  }, [_c('vb-icon', {
    staticClass: "mr-2 dialer-snoozeIcon",
    attrs: {
      "icon": "dialer-snoozeIcon",
      "width": "20",
      "height": "20",
      "scale": "0.8"
    }
  }), _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Away")])], 1), _c('b-dropdown-item', {
    on: {
      "click": function ($event) {
        return _vm.logout({
          is_user: true
        });
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "box-arrow-right",
      "scale": "0.8"
    }
  }), _c('span', {
    staticClass: "text-capitalize"
  }, [_vm._v("Logout")])], 1)], 1), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-snoozeModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0"
  }, [_c('div', {
    staticClass: "text-left w-75"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v(" Away")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-75 mb-0"
  }, [_vm._v(" Choose your preferred away time here ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-snoozeModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox greenBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-none"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.snooze_label == 'off' ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        return _vm.snooze('off');
      }
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Turn off away ")]), _c('div', {
    staticClass: "latestGreyBox-description-main"
  }, [_vm._v("I'm available to receive calls")])])]), _c('span', {
    staticClass: "newBlueColorTagLike"
  }, [_vm._v("Default")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox redBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.snooze_label == 'unlimited' ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        return _vm.snooze('unlimited');
      }
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Turn on away ")]), _c('div', {
    staticClass: "latestGreyBox-description-main"
  }, [_vm._v("I'm unavailable to receive calls")])])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.snooze_label == '5 min' ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        return _vm.snooze('5 min');
      }
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Turn on away for 5 minutes ")]), _c('div', {
    staticClass: "latestGreyBox-description-main"
  }, [_vm._v("This will turn off away in 5 minutes")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.snooze_label == '10 min' ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        return _vm.snooze('10 min');
      }
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Turn on for 10 minutes")]), _c('div', {
    staticClass: "latestGreyBox-description-main"
  }, [_vm._v("This will turn off away in 10 minutes")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.snooze_label == '15 min' ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        return _vm.snooze('15 min');
      }
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Turn on for 15 minutes")]), _c('div', {
    staticClass: "latestGreyBox-description-main"
  }, [_vm._v("This will turn off away in 15 minutes")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.snooze_label == '20 min' ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        return _vm.snooze('20 min');
      }
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Turn on for 20 minutes")]), _c('div', {
    staticClass: "latestGreyBox-description-main"
  }, [_vm._v("This will turn off away in 20 minutes")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.snooze_label == '30 min' ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        return _vm.snooze('30 min');
      }
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Turn on for 30 minutes")]), _c('div', {
    staticClass: "latestGreyBox-description-main"
  }, [_vm._v("This will turn off away in 30 minutes")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.snooze_label == '45 min' ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        return _vm.snooze('45 min');
      }
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Turn on for 45 minutes")]), _c('div', {
    staticClass: "latestGreyBox-description-main"
  }, [_vm._v("This will turn off away in 45 minutes")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.snooze_label == '60 min' ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        return _vm.snooze('60 min');
      }
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Turn on for 1 hour")]), _c('div', {
    staticClass: "latestGreyBox-description-main"
  }, [_vm._v("This will turn off away in 1 hour")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
  }, [_c('div', {
    class: `actLikeSquareRadio mr-20px ${_vm.snooze_label == 'custom' ? 'checked' : ''}`,
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CustomeSnoozeModal');
      }
    }
  }), _c('div', {
    staticClass: "text-left"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Set Custom away time")]), _c('div', {
    staticClass: "latestGreyBox-description-main"
  }, [_vm._v("This will turn off away after the selected time")])])])])])])]), _c('modal', {
    staticClass: "modalForDropdown",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": `${_vm._uid}-userProfileStatusModal`
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-flex w-100 align-items-start justify-content-between pt-0"
  }, [_c('div', {
    staticClass: "text-left w-75"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Status")]), _c('div', {
    staticClass: "dialer-settings-intro newerTextVersion w-75 mb-0"
  }, [_vm._v(" Choose your preferred status here ")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(`${_vm._uid}-userProfileStatusModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px",
    staticStyle: {
      "--total": "90vh",
      "--minusValue": "75px"
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, _vm._l(_vm.$store.state.common.statuses, function (status) {
    return _c('div', {
      key: status.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px withoutAfter ${status.id === _vm.$store.getters.getCurrentUser.status_id ? 'checked' : ''}`,
      style: `border-color: ${status.colour}`,
      on: {
        "click": function ($event) {
          _vm.$store.dispatch('setStatus', status.id);
          _vm.showNotificationForStatusCahnge(status.id);
        }
      }
    }, [_c('div', {
      staticClass: "insteadOfAfter",
      style: `background-color: ${status.colour}`
    })]), _c('div', {
      staticClass: "latestGreyBox-heading-main"
    }, [_vm._v(_vm._s(status.status))])]);
  }), 0)])])]), _c('CustomeSnoozeModal', {
    on: {
      "time": function ($event) {
        return _vm.snooze({
          label: 'custom',
          sec: $event.time_seconds
        });
      }
    }
  })], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }