
import CallingExtensions from "@hubspot/calling-extensions-sdk";
import { TokenService } from "@/services/index";
import { LOGGER } from "@/utils/index";

export const HUBSPOT = {
  cti: {},
  call_backs: {
    onDialNumber: null,
    onReady: null,
  },
  init(props){
    this.call_backs.onDialNumber = props.onDialNumber
    this.call_backs.onReady = props.onReady
    this.cti = new CallingExtensions({
      debugMode: true,
      eventHandlers: {
        onReady: this.onready.bind(this),
        onDialNumber: this.ondialnumber.bind(this),
        onEngagementCreated: this.onengagementcreated.bind(this),
        onEndCall: this.onendcall.bind(this),
        onVisibilityChanged: this.onvisibilitychanged.bind(this)
      }
    });
    return this
  },
  onready(data, rawEvent){
    LOGGER.event('onReady',data, rawEvent)
    this.cti.initialized({
      isLoggedIn: !!TokenService.TOKEN.get() && !!TokenService.USER.get(),
      sizeInfo: {
        height: 700,
        width: 500,
      }
    })
    this.call_backs.onReady?.()
  },
  ondialnumber(data, rawEvent){
    LOGGER.event('onDialNumber',data, rawEvent)
    const { phone_number, ownerId, objectId, objectType } = data
    this.call_backs.onDialNumber?.({
      number: phone_number,
      ownerId, 
      objectId, 
      objectType,
      extraHeader: `HUBSPOT: ownerId/${ownerId},phone_number/${phone_number},objectId/${objectId},objectType/${objectType}`,
    })
  },
  onengagementcreated(data, rawEvent) {
    LOGGER.event('onEngagementCreated',data, rawEvent)
  },
  onendcall(data, rawEvent) {
    LOGGER.event('onEndCall',data, rawEvent)
  },
  onvisibilitychanged(data, rawEvent) {
    LOGGER.event('onVisibilityChanged',data, rawEvent)
  },
  loggedin(){
    this.cti?.userLoggedIn?.()
  },
  loggedout(){
    this.cti?.userLoggedOut?.()
  },
  callended(){
    this.cti?.callEnded?.()
  },
  callcompleted(){
    this.cti?.callCompleted?.()
  },
  incomingcall(){
    this.cti?.incomingCall?.()
  },
  outgoingcall(data={}){
    this.cti?.outgoingCall?.({
      createEngagement: false,
      phoneNumber: data.number
    })
  },
  callanswered(){
    this.cti?.callAnswered?.()
  },
}