var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `${_vm.activeCall ? _vm.activeCall.is_sip ? 'sip-call-active' : 'jitsi-call-active' : ''}`
  }, [!_vm.$store.getters.getIsMobile ? [_vm.activeIncomingCall ? _c('div', {
    class: _vm.activeCall ? 'expand-c' : 'dagarda'
  }, [_vm.activeIncomingCall.room ? _c('IncomingCalls', {
    attrs: {
      "data": _vm.activeIncomingCall
    },
    on: {
      "join": function ($event) {
        return _vm.joinRoomNow($event);
      },
      "removeSipcall": function ($event) {
        return _vm.removeSipcallAfterSwitched($event);
      }
    }
  }, [_vm.allIncomingCalls.length > 1 ? _c('b-dropdown', {
    staticClass: "incommingCallDropDown accordingToNewDesign",
    attrs: {
      "slot": "switchCalls"
    },
    slot: "switchCalls",
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm._v(_vm._s(_vm.allIncomingCalls.length) + " incoming calls")];
      },
      proxy: true
    }], null, false, 1411588154)
  }, [_vm._l(_vm.allIncomingCalls, function (incoming_call) {
    return [_vm.$store.state.calls.active_incoming_call != (incoming_call.room || incoming_call.uuid) ? _c('SwitchIncomingCall', {
      key: incoming_call.room || incoming_call.uuid,
      attrs: {
        "incoming_call": incoming_call,
        "is_sip": !!incoming_call.uuid
      },
      on: {
        "click": function ($event) {
          _vm.$store.state.calls.active_incoming_call = incoming_call.room || incoming_call.uuid;
        }
      }
    }) : _vm._e()];
  })], 2) : _vm._e()], 1) : _vm._e(), _vm.activeIncomingCall.uuid ? _c('SipSession', {
    attrs: {
      "session": _vm.activeIncomingCall
    }
  }, [_vm.allIncomingCalls.length > 1 ? _c('b-dropdown', {
    staticClass: "incommingCallDropDown accordingToNewDesign",
    attrs: {
      "slot": "switchCalls"
    },
    slot: "switchCalls",
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm._v(_vm._s(_vm.allIncomingCalls.length) + " incoming calls")];
      },
      proxy: true
    }], null, false, 1411588154)
  }, [_vm._l(_vm.allIncomingCalls, function (incoming_call) {
    return [_vm.$store.state.calls.active_incoming_call != (incoming_call.room || incoming_call.uuid) ? _c('SwitchIncomingCall', {
      key: incoming_call.room || incoming_call.uuid,
      attrs: {
        "incoming_call": incoming_call,
        "is_sip": !!incoming_call.uuid
      },
      on: {
        "click": function ($event) {
          _vm.$store.state.calls.active_incoming_call = incoming_call.room || incoming_call.uuid;
        }
      }
    }) : _vm._e()];
  })], 2) : _vm._e()], 1) : _vm._e()], 1) : _vm._e(), _vm.activeCall ? _c('div', [_vm.activeCall.is_sip ? _c('SipSession', {
    attrs: {
      "session": _vm.activeCall
    }
  }, [_c('SwitchCall', {
    attrs: {
      "slot": "switchCalls",
      "activeCall": _vm.activeCall
    },
    slot: "switchCalls"
  })], 1) : !_vm.$store.getters.isIntegration ? _c('ActiveCalls', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.activeCall.isShown,
      expression: "activeCall.isShown"
    }],
    attrs: {
      "videoCall": _vm.activeCall
    }
  }, [_c('SwitchCall', {
    attrs: {
      "slot": "switchCalls",
      "activeCall": _vm.activeCall
    },
    slot: "switchCalls"
  })], 1) : _vm._e()], 1) : _vm._e()] : _vm._e(), !_vm.$store.getters.isIntegration ? _vm._l(_vm.$store.state.calls.outgoing_calls, function (outgoing_call) {
    return _c('OutgoingCalls', {
      key: `${outgoing_call.room}-outgoing`,
      attrs: {
        "data": outgoing_call
      },
      on: {
        "removeSipcall": function ($event) {
          return _vm.removeSipcallAfterSwitched($event);
        }
      }
    });
  }) : _vm._e(), _c('audio', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "outgoing-call-738905",
      "src": require('@/assets/sounds/outgoing.mp3'),
      "loop": ""
    }
  }), _c('audio', {
    staticStyle: {
      "display": "none"
    },
    attrs: {
      "id": "incoming-call-738905",
      "src": require('@/assets/sounds/incoming.mp3'),
      "loop": ""
    }
  }), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "videoringtone",
      "src": _vm._f("get_property")(_vm.media_devices_setting.selectedIncomingRingtone, 'file'),
      "loop": "",
      "media-player": "audioPlayer",
      "controls": "controls",
      "preload": "auto",
      "crossorigin": "anonymous"
    }
  }), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "ringtone",
      "src": _vm._f("get_property")(_vm.media_devices_setting.selectedIncomingRingtone, 'file'),
      "loop": "",
      "media-player": "audioPlayer",
      "controls": "controls",
      "preload": "auto",
      "crossorigin": "anonymous"
    }
  }), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "dtmfTone",
      "src": require('@/assets/sounds/dtmf.mp3'),
      "media-player": "audioPlayer",
      "controls": "controls",
      "preload": "auto",
      "crossorigin": "anonymous"
    }
  }), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "ringbacktone",
      "src": require('@/assets/sounds/outgoing.mp3'),
      "loop": "",
      "media-player": "audioPlayer",
      "controls": "controls",
      "preload": "auto",
      "crossorigin": "anonymous"
    }
  }), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "audioRemote",
      "media-player": "audioPlayer",
      "controls": "controls",
      "preload": "auto",
      "crossorigin": "anonymous"
    }
  }), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "dail_beep",
      "src": require('@/assets/sounds/dail_beep.mp3')
    }
  }), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "hangup_sound",
      "src": require('@/assets/sounds/ringtons/alert/Alert 6.mp3')
    }
  }), _c('audio', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    attrs: {
      "id": "dial_sound",
      "src": require('@/assets/sounds/ringtons/alert/Alert 7.mp3')
    }
  }), _c('div', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }]
  }, [_c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.media_devices_setting.camera_id,
      expression: "media_devices_setting.camera_id"
    }],
    attrs: {
      "id": "videoSource"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.media_devices_setting, "camera_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.media_devices_setting.videoinputs, function (device) {
    return _c('option', {
      key: device.deviceId,
      domProps: {
        "value": device.deviceId
      }
    }, [_vm._v(_vm._s(device.label))]);
  }), 0), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.media_devices_setting.mic_id,
      expression: "media_devices_setting.mic_id"
    }],
    attrs: {
      "id": "audioSource"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.media_devices_setting, "mic_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.media_devices_setting.audioinputs, function (device) {
    return _c('option', {
      key: device.deviceId,
      domProps: {
        "value": device.deviceId
      }
    }, [_vm._v(_vm._s(device.label))]);
  }), 0), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.media_devices_setting.speaker_id,
      expression: "media_devices_setting.speaker_id"
    }],
    attrs: {
      "id": "audioOutput"
    },
    on: {
      "change": function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.$set(_vm.media_devices_setting, "speaker_id", $event.target.multiple ? $$selectedVal : $$selectedVal[0]);
      }
    }
  }, _vm._l(_vm.media_devices_setting.audiooutputs, function (device) {
    return _c('option', {
      key: device.deviceId,
      domProps: {
        "value": device.deviceId
      }
    }, [_vm._v(_vm._s(device.label))]);
  }), 0)])], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }