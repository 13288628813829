var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-main-section DealsSection"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mb-5"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset mb-0"
  }, [_vm._v(" Microsoft "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon sm-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-card p-32"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "crm-main-section-heading text-case-unset my-0",
    staticStyle: {
      "color": "black"
    }
  }, [_vm._v(" Microsoft ")]), _c('a', {
    staticClass: "crm-link withIcon",
    attrs: {
      "target": "blank"
    }
  }, [_vm._v(" App Registration "), _c('b-icon', {
    attrs: {
      "icon": "box-arrow-up-right"
    }
  })], 1)]), _c('div', {
    staticClass: "crm-toaster-relative mt-3"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-info-icon",
      "height": "19px",
      "width": "19px"
    }
  }), _c('p', {
    staticClass: "crm-card-text w-100 my-0"
  }, [_vm._v(" Redirect Url:http://crm.voip.develop/microsoft/callback ")])], 1), _vm._m(0), _c('button', {
    staticClass: "newDoneButton smaller mt-20px",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Save")])], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "w-50 pr-3"
  }, [_c('div', {
    staticClass: "crm-card-heading mb-1 mt-4"
  }, [_vm._v("App (client) ID")]), _c('div', {
    staticClass: "crm-input-container withoutIcon mb-0"
  }, [_c('input', {
    staticClass: "w-100"
  })])]), _c('div', {
    staticClass: "w-50 pl-3"
  }, [_c('div', {
    staticClass: "crm-card-heading mb-1 mt-4"
  }, [_vm._v("Client Secret")]), _c('div', {
    staticClass: "crm-input-container withoutIcon mb-0"
  }, [_c('input', {
    staticClass: "w-100"
  })])])]);

}]

export { render, staticRenderFns }