<template>
  <div class="crm-main-section DealsSection">
    <div class="d-flex justify-content-between align-items-center w-100 mb-5">
      <h2 class="crm-main-section-heading text-case-unset mb-0">
        Users
        <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
      </h2>
    </div>
    <div class="crm-tabs-header">
      <div @click="tabSelected = 'Users'" :class="`crm-tabs-btn ${tabSelected == 'Users' ? 'active' : ''}`">
        <b-icon icon="person-fill" height="22px" width="22px" />
        Users
      </div>
      <div @click="tabSelected = 'Roles'" :class="`crm-tabs-btn ${tabSelected == 'Roles' ? 'active' : ''}`">
        <b-icon icon="shield-fill-exclamation" height="22px" width="22px" />
        Roles
      </div>
      <div @click="tabSelected = 'Teams'" :class="`crm-tabs-btn ${tabSelected == 'Teams' ? 'active' : ''}`">
        <vb-icon icon="crm-users-settings-icon" height="22px" width="22px" />
        Teams
      </div>
    </div>
    <div v-if="tabSelected == 'Users'" class="crm-card withSeprateHeader">
      <div class="crm-card-heading">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <div>
            Users
            <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
          </div>
          <div class="d-flex">
            <button class="newButton mr-3">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart position-relative d-flex justify-content-center">
                Invite Users
              </span>
            </button>
            <button class="newButton">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart position-relative d-flex justify-content-center">
                Create Users
              </span>
            </button>
          </div>
        </div>
        <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
          <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck">
            <b-dropdown class="" no-caret>
              <template #button-content>
                <span class="d-flex align-items-center p-0">
                  <span>This week</span>
                  <b-icon-chevron-down class="ml-2" scale="1.1"></b-icon-chevron-down>
                </span>
              </template>
              <b-dropdown-item >
                <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span> This week </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="crm-input-container mb-0">
            <vb-icon icon="crm-inputSearch-icon" />
            <input placeholder="Search" class="w-100"/>
          </div>
        </div>
      </div>
      <div  class="crm-card-body">
        <vb-table class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
          <template slot="header">
            <tr>
              <th>
                ID
              </th>
              <th>
                Label
              </th>
              <th class="dialer-col-right cursor_pointer">
                <span>Actions </span>
              </th>
            </tr>
          </template>
          <template #body="{ start, end }">
            <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
              <td class="dialer-row-title">
                <span>{{ data.m1 }}</span>
              </td>
              <td class="dialer-row-title">
                {{ data.m1 }}
              </td>
              <td class="dialer-col-right">
                <b-dropdown class="crm-insideTable-3dotsVertical-dd" no-caret>
                  <template #button-content>
                    <b-icon icon="three-dots-vertical" height="16px" width="16px"/>
                  </template>
                  <b-dropdown-item>
                    Edit
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Delete
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </template>
        </vb-table>     
      </div>
    </div>
    <div v-if="tabSelected == 'Roles'" class="crm-card withSeprateHeader">
      <div class="crm-card-heading">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <div>
            Roles
            <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
          </div>
          <button class="newButton">
            <vb-icon icon="squared-add-icon" height="38px" width="38px" />
            <span class="newButton-textPart position-relative d-flex justify-content-center">
              Create Roles
            </span>
          </button>
        </div>
        <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
          <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck">
            <b-dropdown class="" no-caret>
              <template #button-content>
                <span class="d-flex align-items-center p-0">
                  <span>This week</span>
                  <b-icon-chevron-down class="ml-2" scale="1.1"></b-icon-chevron-down>
                </span>
              </template>
              <b-dropdown-item >
                <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span> This week </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="crm-input-container mb-0">
            <vb-icon icon="crm-inputSearch-icon" />
            <input placeholder="Search" class="w-100"/>
          </div>
        </div>
      </div>
      <div  class="crm-card-body">
        <vb-table class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
          <template slot="header">
            <tr>
              <th>
                ID
              </th>
              <th>
                Label
              </th>
              <th class="dialer-col-right cursor_pointer">
                <span>Actions </span>
              </th>
            </tr>
          </template>
          <template #body="{ start, end }">
            <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
              <td class="dialer-row-title">
                <span>{{ data.m1 }}</span>
              </td>
              <td class="dialer-row-title">
                {{ data.m1 }}
              </td>
              <td class="dialer-col-right">
                <b-dropdown class="crm-insideTable-3dotsVertical-dd" no-caret>
                  <template #button-content>
                    <b-icon icon="three-dots-vertical" height="16px" width="16px"/>
                  </template>
                  <b-dropdown-item>
                    Edit
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Delete
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </template>
        </vb-table>     
      </div>
    </div>
    <div v-if="tabSelected == 'Teams'" class="crm-card withSeprateHeader">
      <div class="crm-card-heading">
        <div class="w-100 d-flex justify-content-between align-items-center">
          <div>
            Teams
            <b-icon icon="info-circle-fill" class="cursor_pointer infoKbIcon sm-mar-left" />
          </div>
          <button class="newButton">
            <vb-icon icon="squared-add-icon" height="38px" width="38px" />
            <span class="newButton-textPart position-relative d-flex justify-content-center">
              Create Teams
            </span>
          </button>
        </div>
        <div class="w-100 d-flex justify-content-between align-items-center mt-38px">
          <div class="crm-main-dd dropdownVersion ul-width-fit-content withCustomSvgCheck">
            <b-dropdown class="" no-caret>
              <template #button-content>
                <span class="d-flex align-items-center p-0">
                  <span>This week</span>
                  <b-icon-chevron-down class="ml-2" scale="1.1"></b-icon-chevron-down>
                </span>
              </template>
              <b-dropdown-item >
                <vb-svg  name="dialer-tick" width="10.5" height="7.584" viewBox="0 0 10.5 7.584" stroke-width="0" stroke="#005DFF" fill="none" stroke-linecap="round" stroke-linejoin="round" />
                <span> This week </span>
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="crm-input-container mb-0">
            <vb-icon icon="crm-inputSearch-icon" />
            <input placeholder="Search" class="w-100"/>
          </div>
        </div>
      </div>
      <div  class="crm-card-body">
        <vb-table class="latestTableDesign-withBlackBorders-again showGraphIconOnlyOnHover" :isListEmpty="isEmpty(tableData)" :listLength="tableData.length" textNoRecord=" ">
          <template slot="header">
            <tr>
              <th>
                ID
              </th>
              <th>
                Label
              </th>
              <th class="dialer-col-right cursor_pointer">
                <span>Actions </span>
              </th>
            </tr>
          </template>
          <template #body="{ start, end }">
            <tr v-for="(data, index) in tableData" :key="data.id" v-show="index >= start && index <= end">
              <td class="dialer-row-title">
                <span>{{ data.m1 }}</span>
              </td>
              <td class="dialer-row-title">
                {{ data.m1 }}
              </td>
              <td class="dialer-col-right">
                <b-dropdown class="crm-insideTable-3dotsVertical-dd" no-caret>
                  <template #button-content>
                    <b-icon icon="three-dots-vertical" height="16px" width="16px"/>
                  </template>
                  <b-dropdown-item>
                    Edit
                  </b-dropdown-item>
                  <b-dropdown-item>
                    Delete
                  </b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </template>
        </vb-table>     
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'Users',
  components: {
    
  },
  inject:['isEmpty'],
  data(){
    return {
      tableData : {
        0: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        1: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        2: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        3: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        4: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        5: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        6: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        7: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        8: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
        9: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:true},
        10: {m1: '123',m2 : '123',m3 : '123',m4 : '123',m5 : '123',chk:false},
      },
      tabSelected : 'Users',
    }
  },
  computed: {
    
  },
  methods: {
    
  },
  mounted(){
    
  },
}
</script>

<style>

</style>