<template>
  <div class="w-100">
    <input 
      v-show="false" 
      type="file" 
      :disabled="api.add_attachment.send || disabled" 
      @change="attachmentUpload($event.target.files)" 
      ref="file_upload" 
      accept=".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .pdf, .rtf, .txt, .xml, .csv" 
    />
    <!-- mobile web version -->
    <template v-if="$store.getters.getIsMobile">
      <div class="d-flex align-items-center justify-content-between w-100">
        <div class="d-flex align-items-center">
          <div class="basicIOSIcon d-flex justify-content-center align-items-center mr-16px">
            <vb-icon style="width:14.87px;height:17px;" icon="latest-mobileFileAttachments-icon"/>
          </div>
          <div class="d-flex flex-column">
            <div class="headingInsideTable">Attached files</div>
          </div>
        </div>
        <div class="d-flex align-items-center">
          <span style="font-size:12px" v-if="api.add_attachment.send">Uploading {{ api.add_attachment.upload_progress.percentage }}%</span>
          <span style="font-size:14px" v-else>{{attachments.length}}</span>
          <vb-icon v-if="attachments.length<5" icon="mobile-plus-icon-likeAirCall-icon" @click="$refs.file_upload.click()" :style="`pointer-events:${api.add_attachment.send || disabled?'none':'auto'};`" width="15px" height="15px" class="rightFacingArrow ml-2"/>
        </div>
      </div>
    </template>
    <!-- desktop web version -->
    <template v-else>
      <div class="d-flex flex-column align-items-start mt-45px">
        <div class="TODO-innerHeading">Attachments</div>
        <div v-for="(attachment, index) in attachments" :key="attachment.id" class="d-flex align-items-center w-100 mt-12px">
          <img 
            @click="attachment.image ? $emit('media-gallery-modal-show',{
              images: images,
              index: index,
            }) : ''" 
            class="TODO-attachmentImg" 
            :src="attachment | attachmentSrc" 
            @error="$event.target.src=require('@/assets/images/cameraPlaceholder.png')" 
          />
          <div class="d-flex flex-column align-items-start">
            <div class="TODO-smallInnerHeading">{{ attachment.src }}</div>
            <div class="d-flex align-items-center">
              <p class="todo-smallGreyBox-text mb-0">{{ attachment.created_at | filter_date_current(null,{ formate_now: true }) }}</p>
              <!-- <p class="todo-smallGreyBox-text mb-0 textUnderline">Comment</p> -->
              <p class="todo-smallGreyBox-text mb-0 textUnderline" @click="disabled ? '' : attachmentRemove(attachment)">Delete</p>
              <p class="todo-smallGreyBox-text mb-0 textUnderline" @click="disabled ? '' : attachmentDownload(attachment)">Download</p>
              <!-- <p class="todo-smallGreyBox-text mb-0 textUnderline">Make Cover</p> -->
              <!-- <p class="todo-smallGreyBox-text mb-0 textUnderline">Edit</p> -->
            </div>
            <!-- <p class="todo-smallGreyBox-text mb-0 textUnderline withoutBefore">Make Cover</p> -->
          </div>
        </div>
      </div>
      <!-- <button v-if="response.attachments.length<5" :disabled="api.add_attachment.send || disabled" class="dialer-button dialer-button-primary mt-45px" @click="$refs.file_upload.click()">
        <template v-if="api.add_attachment.send">Uploading {{ api.add_attachment.upload_progress.percentage }}%</template>
        <template v-else>Add an attachment</template>
      </button> -->
      <button v-if="response.attachments.length<5"
        class="newButton mt-45px mwd-200px" 
        @click="$refs.file_upload.click()"
        :disabled="api.add_attachment.send || disabled" 
      >
        <vb-icon icon="squared-folders-icon" height="38px" width="38px" />
        <span class="newButton-textPart">
          <template v-if="api.add_attachment.send">Uploading {{ api.add_attachment.upload_progress.percentage }}%</template>
          <template v-else>Add an attachment</template>
        </span>
      </button>
    </template>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
export default {
  name: 'TaskAttachments',
  props: {
    task_id: {
      type: [String,Number],
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      api: {
        add_attachment: this.$helperFunction.apiInstance({
          upload_progress: true,
        }),
        remove_attachment: this.$helperFunction.apiInstance({
          send: ''
        }),
        download_attachment: this.$helperFunction.apiInstance({
          send: ''
        }),
        attachments: this.$helperFunction.apiInstance(),
      },
      response: {
        attachments: [],
      },
    }
  },
  computed: {
    attachments(){
      return this.response.attachments.map(attachment=>{
        let image = false
        if(attachment.src?.endsWith?.('.jpg') || attachment.src?.endsWith?.('.png')) {
          image=true
        }
        return {
          ...attachment,
          image: image,
        }
      })
    },
    images(){ return this.attachments.filter(attachment=>attachment.image).map(i=>i.url) },
  },
  inject: [
    'appNotify',
  ],
  filters: {
    attachmentSrc(attachment){
      if(attachment.src.endsWith('.pdf')) {
        return require('@/assets/images/extensionImages/pdf.png')
      } else if(attachment.src.endsWith('.ppt')) {
        return require('@/assets/images/extensionImages/ppt.png')
      } else if(attachment.src.endsWith('.pptx')) {
        return require('@/assets/images/extensionImages/pptx.png')
      } else if(attachment.src.endsWith('.doc')) {
        return require('@/assets/images/extensionImages/doc.png')
      } else if(attachment.src.endsWith('.docx')) {
        return require('@/assets/images/extensionImages/docx.png')
      } else if(attachment.src.endsWith('.xls')) {
        return require('@/assets/images/extensionImages/xls.png')
      } else if(attachment.src.endsWith('.xlsx')) {
        return require('@/assets/images/extensionImages/xlsx.png')
      } else if(attachment.src.endsWith('.rtf')) {
        return require('@/assets/images/extensionImages/rtf.png')
      } else if(attachment.src.endsWith('.txt')) {
        return require('@/assets/images/extensionImages/txt.png')
      } else if(attachment.src.endsWith('.xml')) {
        return require('@/assets/images/extensionImages/xml.png')
      } else if(attachment.src.endsWith('.csv')) {
        return require('@/assets/images/extensionImages/csv.png')
      } else {
        return attachment.url
      }
    },
  },
  methods: {
    async attachmentUpload(files){
      const file = files[0]
      if(!file || this.api.add_attachment.send) return;
      try {
        this.api.add_attachment.send=true
        const max_size = 1024*1024*1024
        if(file.size > max_size) {
          this.appNotify({
            message: 'File size cannot be maximum to 2MB',
            type: 'danger',
          })
          return;
        }
        const formData = new FormData();
        formData.append("image", file, file.name);
        formData.append("task_id", this.task_id);
        await VOIP_API.endpoints.tasklist.attachments.create(formData,this.api.add_attachment.upload_progress.onProgress.bind(this.api.add_attachment.upload_progress))
        this.$emit('attachment-uploaded')
        this.appNotify({
          message: 'Successfully uploaded',
          type: 'success',
        })
        this.fetchAttachment();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.$refs['file_upload'].value=''
        this.api.add_attachment.send=false
      }
    },
    async attachmentRemove(attachment){
      if(this.api.remove_attachment.send) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "WARNING",
          message: `Are you sure you want to delete?`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.remove_attachment.send=attachment.id
        await VOIP_API.endpoints.tasklist.attachments.delete(attachment.id,{
          task_id: this.task_id
        })
        this.$emit('attachment-removed')
        this.appNotify({
          message: 'Successfully deleted',
          type: 'success',
        })
        this.fetchAttachment();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.remove_attachment.send=''
      }
    },
    async attachmentDownload(attachment){
      if(this.api.download_attachment.send) return;
      try {
        this.api.download_attachment.send=attachment.id
        const response = await fetch(attachment.url)
        const blob = await response.blob()
        let blobUrl = window.URL.createObjectURL(blob);
        const link = document.createElement("a");
        link.href = blobUrl;
        link.target = '_blank';
        link.download = 'download';
        document.body.appendChild(link);
        link.click();
        link.remove();
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.download_attachment.send=''
      }
    },
    async fetchAttachment(){
      if(this.api.attachments.send) return;
      try {
        this.api.attachments.send=true
        const { data } = await VOIP_API.endpoints.tasklist.attachments.list({
          task_id: this.task_id,
        })
        this.response.attachments=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.attachments.send=false
      }
    },
  },
  mounted () {
    this.fetchAttachment();
  },
}
</script>

<style>

</style>