<template>
  <b-form @submit.prevent="''">
    <div v-if="otherNumber" class="latestGreyBox-9-9-2023">
      <div class="latestSMS-container">
        <div @scroll="onScroll($event)" :ref="`${_uid}-sms-container`" :style="{scrollBehavior: ' auto'}" class="latestSMS-conversation-container">
          <template v-if="api.fetch_conversation.send">
            <div v-for="n in 6" :key="n.id" :class="`latestShimmerDesign w-25 ${n%2==1 ? 'ml-auto' : ''}`" style="height:52px;"></div>
          </template>
          <template v-else-if="conversation.length == 0">
            <div class="h-100 d-flex d-flex justify-content-center align-items-center my-auto">
              <div class="w-75 text-center mt-20px">
                <div class="latestGreyBox-heading-main">No messages to see here</div>
                <div class="latestGreyBox-heading-description mt-10px">You can send one by writing a message below</div>
              </div>
            </div>
          </template>
          <div v-for="(message, index) in conversation" :key="message.id">
            <div class="d-flex justify-content-center">
              <template v-if="conversation[index-1]">
                <span>
                  {{ 
                    message.date | filter_date_current(false,{ 
                      is_same: true, 
                      other_date: conversation[index-1].date, 
                      type: 'day' 
                    }) | filter_date_current 
                  }}
                </span>
              </template>
              <span v-else>{{ message.date | filter_date_current }}</span>
            </div>
            <SMSMessage :message="message" :otherNumber="otherNumber" />
          </div>
        </div>
        <SMSAction :otherNumber="otherNumber" :vonageNumbers="vonageNumbers" @sms-send="response.conversation.chat.unshift($event);scrollEnd();" />
      </div>
    </div>
  </b-form>
</template>

<script>
import SMSMessage from './Message.vue'
import SMSAction from './Action.vue'
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import _ from 'lodash'
import { AMI_EVENTS, AMI_SOCKET } from '@/ami'
// import { centrifugo } from '@/Centrifuge'
export default {
  name: 'SMS',
  components: {
    SMSMessage,
    SMSAction,
  },
  props: {
    otherNumber: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      api: {
        fetch_conversation: this.$helperFunction.apiInstance(),
      },
      response: {
        conversation: {
          chat: [],
          vonage_number: [],
        },
      },
      conditions: {
        scroll_bottom: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    conversation(){ return _.orderBy(this.response.conversation.chat, ['date'], ['asc']); },
    vonageNumbers(){ return this.response.conversation?.vonage_number ?? [] },
  },
  watch: {
    "otherNumber": {
      immediate: true,
      handler(){
        this.fetchConversation()
      },
    },
  },
  methods: {
    scrollEnd() {
      let vm = this;
      if(this.$el){
        const el = this.$refs[`${this._uid}-sms-container`];
        // console.log(el)
        setTimeout(() => {
          if(el) el.scrollTop = el.scrollHeight;
          vm.conditions.scroll_bottom = false
        }, 0 * 1000);
      }
    },
    onScroll(Event) {
      const height = Event.target.scrollHeight;
      const top = Event.target.scrollTop;
      const offset_height = Event.target.offsetHeight;
      const scroll_bar_height = height - (height - offset_height);
      const scroll_bottom = Math.floor(height - (scroll_bar_height + top));
      this.conditions.scroll_bottom = scroll_bottom >= 200
    },
    async fetchConversation() {
      try {
        this.api.fetch_conversation.send=true
        const { data } = await VOIP_API.endpoints.sms.fetchConversation({
          from_number: this.otherNumber,
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
        })
        this.response.conversation.chat=Object.values(data?.chatsAll) ?? []
        this.response.conversation.vonage_number=data?.vonageNumbers ?? []
        await this.$nextTick()
        this.scrollEnd()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_conversation.send=false
      }
    },
    onNotification(event){
      const { data: { flag, message, } } = event
      if(flag=='SMS_RECIEVED') {
        const sms = JSON.parse(message)
        if((sms.to_number==this.otherNumber || sms.from_number==this.otherNumber) && !this.api.fetch_conversation.send) {
          this.response.conversation.chat.unshift(sms)
          this.scrollEnd()
        }
      }
    },
  },
  created(){
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
  },
  beforeDestroy(){
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
  },
}
</script>

<style>

</style>