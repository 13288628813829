var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('h5', {
    staticClass: "d-flex align-items-center justify-content-start ml-4 emp-profile-heading"
  }, [_vm._v("Employee Profle")]), _c('div', {
    staticClass: "calender-employee-top mt-5"
  }, [_c('div', {
    staticClass: "calender-hrm-height mb-4"
  }, [_vm._m(0), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Title")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("All time high BTC")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("1 to a 4")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("1 to a 4")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("1 to a 4")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("First Name")]), _c('div', [_c('b-form-input', {
    staticClass: "form-hrm mt-1",
    attrs: {
      "placeholder": "kendal"
    }
  })], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Last Name")]), _c('div', [_c('b-form-input', {
    staticClass: "form-hrm mt-1",
    attrs: {
      "placeholder": "kendal"
    }
  })], 1)])])]), _c('div', {
    staticClass: "calender-employee-top"
  }, [_c('div', {
    staticClass: "calender-hrm-height mb-3"
  }, [_c('h5', {
    staticClass: "calender-page-subheading"
  }, [_vm._v("Positions")]), _c('div', {
    staticClass: "calender-large-position-box"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('h4', {
    staticClass: "ofc-admin"
  }, [_c('svg', {
    staticClass: "calender-ofc-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "8",
      "height": "8",
      "viewBox": "0 0 8 8"
    }
  }, [_c('circle', {
    attrs: {
      "id": "Ellipse_21",
      "data-name": "Ellipse 21",
      "cx": "4",
      "cy": "4",
      "r": "4",
      "fill": "#5576d1"
    }
  })]), _vm._v(" Office administrator ")]), _c('button', {
    staticClass: "custom-hrm-badge"
  }, [_vm._v("Most recent")])]), _vm._m(1)]), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "btn btn-primarymike mt-3"
  }, [_c('b-icon', {
    staticClass: "primarymike-svg",
    attrs: {
      "icon": "plus"
    }
  }), _vm._v(" New Position ")], 1)])])]), _c('div', {
    staticClass: "calender-employee-top"
  }, [_c('div', {
    staticClass: "calender-hrm-height mb-5"
  }, [_c('h5', {
    staticClass: "calender-page-subheading"
  }, [_vm._v("Job Information")]), _c('div', [_c('label', {
    staticClass: "calender-form-labels",
    attrs: {
      "for": "input-with-list"
    }
  }, [_vm._v("Work Email")]), _c('div', [_c('b-form-input', {
    staticClass: "work-emailphone-hrm mt-1",
    attrs: {
      "list": "input-list",
      "placeholder": "Alisha@vavoip.com"
    }
  }), _c('div', {
    staticClass: "svgblock"
  }, [_c('svg', {
    staticClass: "svginput",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.966",
      "height": "14.373",
      "viewBox": "0 0 17.966 14.373"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_material-email",
      "data-name": "Icon material-email",
      "d": "M19.17,6H4.8A1.794,1.794,0,0,0,3.009,7.8L3,18.576a1.8,1.8,0,0,0,1.8,1.8H19.17a1.8,1.8,0,0,0,1.8-1.8V7.8A1.8,1.8,0,0,0,19.17,6Zm0,3.593-7.187,4.492L4.8,9.593V7.8l7.187,4.492L19.17,7.8Z",
      "transform": "translate(-3 -6)",
      "fill": "#5576d1"
    }
  })])])], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels",
    attrs: {
      "for": "input-with-list"
    }
  }, [_vm._v("Work Phone")]), _c('div', [_c('b-form-input', {
    staticClass: "work-emailphone-hrm mt-1",
    attrs: {
      "list": "input-list",
      "placeholder": "03033728896"
    }
  }), _c('div', {
    staticClass: "svgblock"
  }, [_c('svg', {
    staticClass: "svginput",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13.5",
      "height": "13.5",
      "viewBox": "0 0 13.5 13.5"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_material-call",
      "data-name": "Icon material-call",
      "d": "M4.965,8.092a11.361,11.361,0,0,0,4.943,4.943l1.65-1.65a.746.746,0,0,1,.765-.18A8.555,8.555,0,0,0,15,11.632a.752.752,0,0,1,.75.75V15a.752.752,0,0,1-.75.75A12.749,12.749,0,0,1,2.25,3,.752.752,0,0,1,3,2.25H5.625a.752.752,0,0,1,.75.75A8.52,8.52,0,0,0,6.8,5.677a.753.753,0,0,1-.187.765l-1.65,1.65Z",
      "transform": "translate(-2.25 -2.25)",
      "fill": "#5576d1"
    }
  })])])], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels",
    attrs: {
      "for": "datepicker-buttons"
    }
  }, [_vm._v("Employment start date")]), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('b-form-datepicker', {
    staticClass: "dataformcontrol",
    attrs: {
      "id": "datepicker-buttons",
      "date-format-options": {
        year: 'numeric',
        month: 'short',
        day: '2-digit',
        weekday: 'short'
      },
      "locale": "en"
    }
  }), _c('svg', {
    staticClass: "svg-img-calender-emp",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "17.5",
      "height": "20",
      "viewBox": "0 0 17.5 20"
    }
  }, [_c('path', {
    attrs: {
      "id": "Icon_awesome-calendar-alt",
      "data-name": "Icon awesome-calendar-alt",
      "d": "M0,18.125A1.875,1.875,0,0,0,1.875,20h13.75A1.875,1.875,0,0,0,17.5,18.125V7.5H0Zm12.5-7.656A.47.47,0,0,1,12.969,10h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,12.969,15h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,7.969,10H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,7.969,15H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,2.969,10H4.531A.47.47,0,0,1,5,10.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,2.969,15H4.531A.47.47,0,0,1,5,15.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469ZM15.625,2.5H13.75V.625A.627.627,0,0,0,13.125,0h-1.25a.627.627,0,0,0-.625.625V2.5h-5V.625A.627.627,0,0,0,5.625,0H4.375A.627.627,0,0,0,3.75.625V2.5H1.875A1.875,1.875,0,0,0,0,4.375V6.25H17.5V4.375A1.875,1.875,0,0,0,15.625,2.5Z",
      "fill": "#5576d1"
    }
  })]), _c('h5', {
    staticClass: "date-return-date"
  }, [_vm._v("7 years 11 days")])], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Employment Status")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("Full-time")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Onboarding mentor")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("Mentor")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Full-time")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Part-time")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Direct Manager")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "d-flex justify-content-between align-items-center"
        }, [_c('img', {
          staticClass: "img-direct-manager-ddshow",
          attrs: {
            "src": require("../../../assets/images/credit-card/hrmprofiletest.png"),
            "alt": "logo"
          }
        }), _c('h5', {
          staticClass: "text-direct-manager-ddshow"
        }, [_vm._v("Melissa")])]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item-button', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "img-direct-manager",
    attrs: {
      "src": require("../../../assets/images/credit-card/hrmprofiletest.png"),
      "alt": "logo"
    }
  }), _c('h5', {
    staticClass: "text-direct-manager-ddshow"
  }, [_vm._v("Melissa")])])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Team")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("Teams")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Voip Business")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Wanio")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Location")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("London")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("London")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Wales")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Public holiday group")]), _c('div', {
    staticClass: "dd-container"
  }, [_c('b-dropdown', {
    staticClass: "mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content-dropi"
        }, [_c('div', {
          staticClass: "dropi-contenti"
        }, [_vm._v("Genral")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Genral")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Casual")])], 1)], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Employee #")]), _c('div', [_c('b-form-input', {
    staticClass: "form-hrm mt-1",
    attrs: {
      "placeholder": "25090"
    }
  })], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Linkelln")]), _c('div', [_c('b-form-input', {
    staticClass: "form-hrm mt-1",
    attrs: {
      "placeholder": "25090"
    }
  })], 1)]), _c('div', [_c('label', {
    staticClass: "calender-form-labels"
  }, [_vm._v("Skype")]), _c('div', [_c('b-form-input', {
    staticClass: "form-hrm mt-1",
    attrs: {
      "placeholder": "25090"
    }
  })], 1)])])]), _vm._m(2)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "calender-large-profile-box"
  }, [_c('div', {
    staticClass: "img-wrap-calender"
  }, [_c('img', {
    staticClass: "calender-side-large-img mx-auto d-block",
    attrs: {
      "src": require("../../../assets/images/credit-card/hrmprofiletest.png"),
      "alt": "logo"
    }
  })])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "pos-since-date"
  }, [_c('p', {
    staticClass: "positions-date-since"
  }, [_vm._v("Since 15/07/2015")]), _c('p', {
    staticClass: "positions-date-past"
  }, [_vm._v("7 years 12 days")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "calender-employee-top"
  }, [_c('div', {
    staticClass: "calender-hrm-height mb-3"
  }, [_c('h5', {
    staticClass: "calender-page-subheading"
  }, [_vm._v("Daily Reports")]), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center mt-4"
  }, [_c('img', {
    staticClass: "right-side-calender-avatar-img",
    attrs: {
      "src": require("../../../assets/images/credit-card/hrmprofiletest.png"),
      "alt": "logo"
    }
  }), _c('h5', {
    staticClass: "right-side-calender-avatar-text"
  }, [_vm._v("Amber West")])]), _c('div', {
    staticClass: "mar-top-hrm40 mar-bottom-hrm40"
  }, [_c('button', {
    staticClass: "btn btn-primarynewupdated mr-3 space-4-button"
  }, [_vm._v("Personal Setting")]), _c('button', {
    staticClass: "btn btn-primarynewupdated mr-3"
  }, [_vm._v("Access log")]), _c('button', {
    staticClass: "btn btn-primarynewupdated"
  }, [_vm._v("Permissions")])]), _c('div', {
    staticClass: "d-flex mar-top-hrm40 mar-bottom-hrm40"
  }, [_c('button', {
    staticClass: "btn btn-primarynewupdated1 mr-3"
  }, [_vm._v("Save")]), _c('button', {
    staticClass: "btn btn-primarymikedel"
  }, [_vm._v("Terminate")])]), _c('div', [_c('a', {
    staticClass: "direct-report-htm-viewlink",
    attrs: {
      "href": "#"
    }
  }, [_vm._v("View as this employee")])])])]);

}]

export { render, staticRenderFns }