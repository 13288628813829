<template>
  <div class="right-calender">
    <div class="top-main-calender scrollow1">
      <section class="unsec-common">
        <div class="d-flex justify-content-between align-items-center">
          <div>
            <h5 class="emp-profile-heading">General</h5>
          </div>
          <div class="d-flex flex-wrap">
            <button class="btn btn-primarymike space4btn-when-scroll-activate mr-3" @click="$modal.show('CreateNewPolicyTimeoffModal')">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.551" height="16.551" viewBox="0 0 16.551 16.551" style="margin-right:10px;">
                <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z" transform="translate(-8.465 -8.465)" fill="#fff" stroke="#fff" stroke-width="1"/>
              </svg>
              Add new policy
            </button>
          </div>
        </div>
        
        <vb-table :tableDesign="1" class="setting-timeoff-policy-table">
          <template slot="header">
            <h5 class="commonth onetd p-l24 ">Name</h5>
            <h5 class="commonth secondtd ">default Allowance</h5>
            <h5 class="commonth thirdtd">accrual type</h5>
            <h5 class="commonth fourtd col-right">Action </h5>
          </template>
          <template slot="body">
            <tr>
              <td class="onetd ">
                <div class="timeoff-color-table-icons"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
                    <circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#D5761B"/>
                  </svg>
                  <span class="clr-sick">Sickday</span>
                </div>
              </td>	
              <td class="secondtd">
                <span>20days</span>
                <span>0 days max carryover</span>
              </td>
              <td class="thirdtd">
                Accrue annualay
              </td>
              <td class="fourtd col-right">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <tr>
              <td class="onetd ">
                <div class="timeoff-color-table-icons"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
                    <circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#5576D1"/>
                  </svg>
                  <span class="clr-sick">Holiday- UK</span>
                </div>
              </td>	
              <td class="secondtd">
                <span>∞</span>
                <span></span>
              </td>
              <td class="thirdtd">
                Do not accrue, just track usage
              </td>
              <td class="fourtd col-right">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <tr>
              <td class="onetd ">
                <div class="timeoff-color-table-icons"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
                    <circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#5576D1"/>
                  </svg>
                  <span class="clr-sick">Holiday- UK</span>
                </div>
              </td>	
              <td class="secondtd">
                <span>∞</span>
                <span></span>
              </td>
              <td class="thirdtd">
                Do not accrue, just track usage
              </td>
              <td class="fourtd col-right">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <tr>
              <td class="onetd ">
                <div class="timeoff-color-table-icons"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
                    <circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#5576D1"/>
                  </svg>
                  <span class="clr-sick">Holiday- UK</span>
                </div>
              </td>	
              <td class="secondtd">
                <span>∞</span>
                <span></span>
              </td>
              <td class="thirdtd">
                Do not accrue, just track usage
              </td>
              <td class="fourtd col-right">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <tr>
              <td class="onetd ">
                <div class="timeoff-color-table-icons"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
                    <circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#5576D1"/>
                  </svg>
                  <span class="clr-sick">Holiday- UK</span>
                </div>
              </td>	
              <td class="secondtd">
                <span>∞</span>
                <span></span>
              </td>
              <td class="thirdtd">
                Do not accrue, just track usage
              </td>
              <td class="fourtd col-right">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
             </td>
            </tr>
            <tr>
              <td class="onetd ">
                <div class="timeoff-color-table-icons"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
                    <circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#5576D1"/>
                  </svg>
                  <span class="clr-sick">Holiday- UK</span>
                </div>
              </td>	
              <td class="secondtd">
                <span>∞</span>
                <span></span>
              </td>
              <td class="thirdtd">
                Do not accrue, just track usage
              </td>
              <td class="fourtd col-right">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <tr>
              <td class="onetd ">
                <div class="timeoff-color-table-icons"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
                    <circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#C7384F"/>
                  </svg>
                  <span class="clr-wfh">Work from </span>
                </div>
              </td>	
              <td class="secondtd">
                <span>20</span>
                <br>
                <span class="policy-da-span">0 days max carryover</span>
              </td>
              <td class="thirdtd">
                Accrue monthly (at end of month)
              </td>
              <td class="fourtd col-right">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
               </td>
            </tr>
            <tr>
              <td class="onetd ">
                <div class="timeoff-color-table-icons"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
                    <circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#22B100"/>
                  </svg>
                  <span class="clr-holiday-usa">Holiday- USA</span>
                </div>
              </td>	
              <td class="secondtd">
                <span>12 days</span>
                <br>
                <span class="policy-da-span">0 days max carryover</span>
              </td>
              <td class="thirdtd">
                Accrue monthly (at end of month)
              </td>
              <td class="fourtd col-right">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <tr>
              <td class="onetd ">
                <div class="timeoff-color-table-icons"> 
                  <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
                    <circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#D5761B"/>
                  </svg>
                  <span class="clr-sick">Sickday</span>
                </div>
              </td>	
              <td class="secondtd">
                <span>12 days</span>
                <br>
                <span class="policy-da-span">0 days max carryover</span>
              </td>
              <td class="thirdtd">
              Accrue monthly (at end of month)
              </td>
              <td class="fourtd col-right">
                <div class="d-flex justify-content-end commonTableMain">
                  <b-dropdown no-caret class="common-table-dd">
                    <template #button-content>
                      <b-icon icon="three-dots-vertical"></b-icon>
                    </template>
                    <b-dropdown-item href="#">Action</b-dropdown-item>
                  </b-dropdown>
                </div>
              </td>
            </tr>
            <tr>
            <td class="onetd ">
              <div class="timeoff-color-table-icons"> 
                <svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
                  <circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#5576D1"/>
                </svg>
                <span class="clr-holiday1">Holiday</span>
              </div>
            </td>	
            <td class="secondtd">
              <span>12 days</span>
              <br>
              <span class="policy-da-span">0 days max carryover</span>
            </td>
            <td class="thirdtd">
              Do not accrue, just track usage
            </td>
            <td class="fourtd col-right">
            <div class="d-flex justify-content-end commonTableMain">
              <b-dropdown no-caret class="common-table-dd">
                <template #button-content>
                  <b-icon icon="three-dots-vertical"></b-icon>
                </template>
                <b-dropdown-item href="#">Action</b-dropdown-item>
              </b-dropdown>
            </div>
            </td>
            </tr>
            
        
          </template>
        </vb-table>
      </section>
      
    </div>
    <CreateNewPolicyTimeoffModal/>
  </div>
</template>

<script>
import CreateNewPolicyTimeoffModal from "../Modals/CreateNewPolicyTimeoffModal.vue"
export default {
  name: 'PoliciesTimeoffSettingHRM',
  components:{
	CreateNewPolicyTimeoffModal,
  }
}

</script>

<style>

</style>