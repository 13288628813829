
import { VOIP_API } from "../index"

export const USER_STATUSES_API_ENDPOINTS = {
  usersStatus(){
    return VOIP_API.axios.voip.request({
      url: `users-status`,
      method: "GET",
    });
  },
  set(data){
    return VOIP_API.axios.voip.request({
      url: `user-status`,
      method: "POST",
      data,
    });
  },
}