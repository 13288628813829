var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "MenuOptionExtensionAssignModal dialer_animation right_side_popup m-w-500 bigSquareCheckbox min-width-50percent-modal",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Press " + _vm._s(_vm.data.dtmf) + " for")]), _c('div', {
    staticClass: "dialer-edit-actions"
  })]), _c('p', {
    staticClass: "vm--modal-text mb-0"
  }, [_vm._v("Select where the call will be forwarded if '" + _vm._s(_vm.data.dtmf) + "' is pressed in the IVR menu.")])]), _vm.data.ringgroup_id ? [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "mr-16px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Advance options")])]), _c('button', {
    staticClass: "newButton ml-5",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.is_view = !_vm.conditions.is_view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.conditions.is_view ? 'squared-hide-icon' : 'squared-show-icon',
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_c('span', [_vm._v(_vm._s(_vm.conditions.is_view ? 'Hide' : 'View'))])])], 1)]), _c('b-collapse', {
    staticClass: "mt-20px",
    attrs: {
      "visible": _vm.conditions.is_view
    }
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-75"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Dedicate this number to a IVR")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(" This option assigns a number to a IVR, allowing custom call routing, forwarding, and voicemail settings in the user profile section. ")])]), _c('div', {
    staticClass: "md-mar-left"
  }, [_vm.api.update_user_mode.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "34px",
      "width": "60px",
      "border-radius": "25px"
    }
  }) : _c('b-form-checkbox', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    ref: "user-mode",
    staticClass: "newerSwitch",
    attrs: {
      "title": _vm.isUser || _vm.data.ringGroupMembers.length == 1 ? '' : 'Please select only one user to switch this toggle button',
      "disabled": _vm.api.update_user_mode.send || !(_vm.isUser || _vm.data.ringGroupMembers.length == 1),
      "checked": _vm.data.user_mode,
      "switch": ""
    },
    on: {
      "change": function ($event) {
        return _vm.updateUserMode();
      }
    }
  })], 1)]), _c('div', [!_vm.data.user_mode ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside"
  }, [_c('CallForwardingSetting', {
    attrs: {
      "account": _vm.data.ringgroup_id,
      "forwardCallerId": true
    },
    on: {
      "cf-updated": function ($event) {
        return _vm.$emit('cf-updated');
      }
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside dialer_box_text_my-0"
  }, [_c('MusicOnHoldWidget', {
    attrs: {
      "accountcode": _vm.data.ringgroup_id
    }
  })], 1)] : _vm._e()], 2)])], 1)])] : _vm._e()], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }