export const AMI_EVENTS = {
  broadcast: 'broadcast',
  update_logs: 'update_logs',
  update_call_info: 'update_call_info',
  update_calls: 'update_calls',
  update_dtmf: 'update_dtmf',
  update_blf: 'update_blf',
  update_user_active: 'update_user_active',
  update_idle_state: 'update_idle_state',
  
  // calls
  INVITE: 'INVITE',
  PROGRESS: 'PROGRESS',
  BYE: 'BYE',
  ACCEPT: 'ACCEPT',
  BUSY: 'BUSY',
  CANCEL: 'CANCEL',
  NOANSWER: 'NOANSWER',
  ANSWERED: 'ANSWERED',
  REJECTED: 'REJECTED',
  FORWARD: 'FORWARD',
  AVAILABLESIP: 'AVAILABLESIP',
  ACTIVE_GROUP_CALLS: 'ACTIVE_GROUP_CALLS',
  NOT_ACTIVE_GROUP_CALLS: 'NOT_ACTIVE_GROUP_CALLS',
  STATUS_BROADCAST: 'STATUS_BROADCAST', 
  CENTRIFUGE_OTHER: "CENTRIFUGE_OTHER",
  presence_message: 'presence_message',
}