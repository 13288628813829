<template>
  <div ref="PowerDialerContainer" class="PowerDialerContainer">
    <h2 class="d-flex flex-wrap align-items-center justify-content-between">
      <div class="goToPreviousScreen" v-if="selected.sheet_id">
        <vb-svg @click="selected.sheet_id='';response.numbers=[];" class="cursor_pointer_no_hover"
          name="leftLongArrow-icon" 
          width="20" 
          height="12" 
          viewBox="0 0 30 16" 
          stroke-width="1" 
          stroke="" 
          fill="none" 
          stroke-linecap="round" 
          stroke-linejoin="round" 
        />
      </div>
      <span class="d-block title">Power dialer</span>
      <div v-if="selected.sheet_id">
        <div v-if="$store.state.power_dialer.start_sheet_id==selected.sheet_id" @click="$store.state.power_dialer.start_sheet_id=''" class="startStopDialing">
          <vb-icon icon="PowerDialer-stop-icon" width="14px" height="14px" />
          <div class="startStopDialing-text">Stop call</div>
        </div>
        <div v-else @click="$store.state.power_dialer.start_sheet_id ? '' : $store.state.power_dialer.start_sheet_id=selected.sheet_id;" class="startStopDialing">
          <vb-icon icon="PowerDialer-start-icon" width="11.79px" height="13.482px" />
          <div class="startStopDialing-text">Auto call</div>
        </div>
      </div>
    </h2>
    <div v-if="!selected.sheet_id" class="PowerDialerContainer-inner">
      <div class="PowerDialer-row" style="cursor: pointer;" v-for="sheet in response.sheets" :key="sheet.id" @click="selectSheet(sheet)">
        <div class="PowerDialer-row-leftSide">
          <vb-icon icon="PowerDialer-contact-icon" class="PowerDialer-contact-icon"  />
          <div>
            <div class="PowerDialer-row-mainText">{{sheet.name}}</div>
          </div>
        </div>
        <div class="PowerDialer-row-rightSide">
          <vb-icon icon="PowerDialer-rightArrow-icon" class="PowerDialer-rightArrow-icon" width="8.526" height="14.113" />
        </div>
      </div>
      <template v-if="api.fetch_sheets.send">
        <div class="PowerDialer-row" v-for="n in 5" :key="n.id">
          <div class="PowerDialer-row-leftSide" style="width:calc(100% - 40px)">
            <vb-icon icon="PowerDialer-contact-icon" class="PowerDialer-contact-icon"  />
            <div class="" style="width:calc(100% - 40px)">
              <div class="PowerDialer-row-mainText name-chip w-100 mb-1" ><div class="latestShimmerDesign" style="height: 15px;width: 100%;"></div></div>
              <div class="PowerDialer-row-secondaryText w-100"><div class="latestShimmerDesign" style="height: 13px;width: 100%;"></div></div>
            </div>
          </div>
          <div class="PowerDialer-row-rightSide">
            <vb-icon icon="PowerDialer-phone-icon" class="PowerDialer-phone-icon" width="15.983px" height="16.002px" />
          </div>
        </div>
      </template>
      <div v-else-if="isEmpty(response.sheets)" class="emptyScreenContainer mt-32px b-0 p-0">
        <img width="50%" height="auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')"/>
        <img width="50%" height="auto" v-else :src="require('@/assets/images/emptyScreenImages/addressBook.png')"/>
        <div class="emptyScreenContainer-heading mt-32px">No record</div>
      </div>
    </div>
    <div v-else class="PowerDialerContainer-inner">
      <div class="PowerDialer-row" v-for="number in response.numbers" :key="number.id">
        <div class="PowerDialer-row-leftSide">
          <vb-icon icon="PowerDialer-contact-icon" class="PowerDialer-contact-icon"  />
          <div>
            <div class="PowerDialer-row-mainText name-chip" v-b-tooltip="number.name">{{number.name}}</div>
            <div :class="`PowerDialer-row-secondaryText ${number.state=='dialing' ? 'highlightIt' : '' }`">{{number.number | number_formater}}</div>
            <div v-if="number.state" class="PowerDialer-row-verySmallText">
              <span>{{number.state}}</span>
              <div v-if="number.state=='dialing'" class="d-flex">
                <div v-if="number.dialed_by!=getCurrentUser.account" class="d-flex mr-2">
                  <span class="mr-1">by</span>
                  <Info v-if="number.dialed_by" :id="number.dialed_by" :only_name="true" />
                </div>
                <div class="dialingCircles">
                  <div class="dialingCircle"></div>
                  <div class="dialingCircle"></div>
                  <div class="dialingCircle"></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="PowerDialer-row-rightSide">
          <vb-icon icon="PowerDialer-phone-icon" @click="$store.state.power_dialer.start_sheet_id || number.state=='dialing' ? '' : dialPowerDialerNumber(number)" class="PowerDialer-phone-icon" :class="`${number.state=='dialing' || $store.state.power_dialer.start_sheet_id?'disabled':''}`" width="15.983px" height="16.002px" />
        </div>
      </div>
      <template v-if="api.fetch_sheet_numbers.send">
        <div class="PowerDialer-row" v-for="n in 5" :key="n.id">
          <div class="PowerDialer-row-leftSide" style="width:calc(100% - 40px)">
            <vb-icon icon="PowerDialer-contact-icon" class="PowerDialer-contact-icon"  />
            <div class="" style="width:calc(100% - 40px)">
              <div class="PowerDialer-row-mainText name-chip w-100 mb-1" ><div class="latestShimmerDesign" style="height: 15px;width: 100%;"></div></div>
              <div class="PowerDialer-row-secondaryText w-100"><div class="latestShimmerDesign" style="height: 13px;width: 100%;"></div></div>
            </div>
          </div>
          <div class="PowerDialer-row-rightSide">
            <vb-icon icon="PowerDialer-phone-icon" class="PowerDialer-phone-icon" width="15.983px" height="16.002px" />
          </div>
        </div>
      </template>
      <div v-else-if="isEmpty(response.numbers)" class="emptyScreenContainer mt-32px b-0 p-0">
        <img width="50%" height="auto" v-if="GET_LOCAL_SETTING_IS_DARK_MODE" :src="require('@/assets/images/emptyScreenImages/darkMode/addressBook.png')"/>
        <img width="50%" height="auto" v-else :src="require('@/assets/images/emptyScreenImages/addressBook.png')"/>
        <div class="emptyScreenContainer-heading mt-32px">No record</div>
      </div>
    </div>
  </div>
</template>

<script>
import { events, VOIP_API } from '@/utils';
import Info from '@/components/Lists/Info.vue';
import { mapGetters } from 'vuex';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
export default {
  name: 'PowerDialer',
  components: {
    Info,
  },
  inject: [
    'appNotify',
    'isEmpty', 
  ],
  data() {
    return {
      api: {
        fetch_sheets: this.$helperFunction.apiInstance(),
        fetch_sheet_numbers: this.$helperFunction.apiInstance(),
      },
      response: {
        sheets: [],
        numbers: {},
      },
      selected: {
        sheet_id: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      GET_LOCAL_SETTING_IS_DARK_MODE,
    ]),
  },
  methods: {
    async selectSheet(sheet){
      this.selected.sheet_id = sheet.id
      try {
        this.api.fetch_sheet_numbers.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getSheetNumbers(sheet.id)
        this.response.numbers=data ?? []
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_sheet_numbers.send=false
      }
    },
    async fetchSheets() {
      if(this.api.fetch_sheets.send) return;
      try {
        this.api.fetch_sheets.send=true
        const { data } = await VOIP_API.endpoints.power_dialer.getSheets()
        this.response.sheets = data
      } catch (ex) {
        this.appNotify({
          message: ex.message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_sheets.send=false
      }
    },
    dialPowerDialerNumber(number){
      const row_no = number.row_id
      const sheet_id = this.selected.sheet_id
      if(!this.$store.state.power_dialer.start_sheet_id) {
        console.log(row_no,sheet_id)
        this.$root.$emit(events.audio_call,{
          call_type: 'power_dialer',
          row_no: row_no,
          sheet_id: sheet_id
        })
      }
    },
    onNotification({ data }){
      const { type, sheet_id, row_no, value, published_by } = data
      if(type=='PD_STATUS' && sheet_id==this.selected.sheet_id && this.response.numbers[row_no]) {
        this.response.numbers[row_no].state=value
        this.response.numbers[row_no].dialed_by=published_by
      }
    },
  },
  created(){
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification.bind(this))
  },
  mounted(){
    this.fetchSheets()
  },
  beforeDestroy(){
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification.bind(this))
  }
}
</script>

<style lang="scss" scoped>
.name-chip {
  max-width: 200px;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
}
</style>