var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_vm.otherNumber ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "latestSMS-container"
  }, [_c('div', {
    ref: `${_vm._uid}-sms-container`,
    staticClass: "latestSMS-conversation-container",
    style: {
      scrollBehavior: ' auto'
    },
    on: {
      "scroll": function ($event) {
        return _vm.onScroll($event);
      }
    }
  }, [_vm.api.fetch_conversation.send ? _vm._l(6, function (n) {
    return _c('div', {
      key: n.id,
      class: `latestShimmerDesign w-25 ${n % 2 == 1 ? 'ml-auto' : ''}`,
      staticStyle: {
        "height": "52px"
      }
    });
  }) : _vm.conversation.length == 0 ? [_c('div', {
    staticClass: "h-100 d-flex d-flex justify-content-center align-items-center my-auto"
  }, [_c('div', {
    staticClass: "w-75 text-center mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("No messages to see here")]), _c('div', {
    staticClass: "latestGreyBox-heading-description mt-10px"
  }, [_vm._v("You can send one by writing a message below")])])])] : _vm._e(), _vm._l(_vm.conversation, function (message, index) {
    return _c('div', {
      key: message.id
    }, [_c('div', {
      staticClass: "d-flex justify-content-center"
    }, [_vm.conversation[index - 1] ? [_c('span', [_vm._v(" " + _vm._s(_vm._f("filter_date_current")(_vm._f("filter_date_current")(message.date, false, {
      is_same: true,
      other_date: _vm.conversation[index - 1].date,
      type: 'day'
    }))) + " ")])] : _c('span', [_vm._v(_vm._s(_vm._f("filter_date_current")(message.date)))])], 2), _c('SMSMessage', {
      attrs: {
        "message": message,
        "otherNumber": _vm.otherNumber
      }
    })], 1);
  })], 2), _c('SMSAction', {
    attrs: {
      "otherNumber": _vm.otherNumber,
      "vonageNumbers": _vm.vonageNumbers
    },
    on: {
      "sms-send": function ($event) {
        _vm.response.conversation.chat.unshift($event);
        _vm.scrollEnd();
      }
    }
  })], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }