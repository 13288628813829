<template>
  <div ref="NotificationsContainer" class="NotificationsContainer">
    <div class="NotificationsContainer-inner">
      <div class="Notifications-row" v-for="notify in notifys" :key="notify.id">
        <vb-icon icon="Notifications-x-circle-icon" class="Notifications-x-circle-icon" @click="notify.close()" />
        <div class="Notifications-row-rightSide">
          <div v-if="notify.title" class="somethingLikeTag" :class="`notification-type-${getProperty(notify,'type','info')}`" style="background-color: #FF7258">
            <span>{{notify | get_property('title','Title')}}</span>
          </div>
          <div class="Notifications-row-mainText">{{notify | get_property('message')}}</div>
          <div class="Notifications-row-secondaryText">
            {{
              notify | get_property('current_time') | filter_date_current(false,{
                utc: true,
                formate_now: true,
              })
            }}
          </div>
        </div>
      </div>
      <!-- <div class="Notifications-row">
        <vb-icon icon="Notifications-x-circle-icon" class="Notifications-x-circle-icon" />
        <div class="Notifications-row-rightSide">
          <div class="somethingLikeTag" style="background-color: #FF7258">
            <span>Missed Call</span>
          </div>
          <div class="Notifications-row-mainText">
            You missed a call from 07397903380 
          </div>
          <div class="Notifications-row-secondaryText">
            Jul 20 at 6:48 pm
          </div>
        </div>
      </div>
      <div class="Notifications-row">
        <vb-icon icon="Notifications-x-circle-icon" class="Notifications-x-circle-icon" />
        <div class="Notifications-row-rightSide">
          <div class="somethingLikeTag" style="background-color: #00BC5E">
            <span>Service</span>
          </div>
          <div class="Notifications-row-mainText">
            <span class="lesserColor">New user added:</span>
            A new user has been added as free resource
          </div>
          <div class="Notifications-row-secondaryText">
            Jul 20 at 6:48 pm
          </div>
        </div>
      </div>
      <div class="Notifications-row">
        <vb-icon icon="Notifications-x-circle-icon" class="Notifications-x-circle-icon" />
        <div class="Notifications-row-rightSide">
          <div class="somethingLikeTag" style="background-color: #6ACA4F">
            <span>Incoming call</span>
          </div>
          <div class="Notifications-row-mainText">
            <span class="lesserColor">Inbound call from</span> 
            Frank Fahad
          </div>
          <div class="Notifications-row-secondaryText">
            Jul 20 at 6:48 pm
          </div>
        </div>
      </div>
      <div class="Notifications-row">
        <vb-icon icon="Notifications-x-circle-icon" class="Notifications-x-circle-icon" />
        <div class="Notifications-row-rightSide">
          <div class="somethingLikeTag" style="background-color: #1401C1">
            <span>Request</span>
          </div>
          <div class="Notifications-row-mainText">
            Please wait your request for adding service 
            Telephone IVR is in progress
          </div>
          <div class="Notifications-row-secondaryText">
            Jul 20 at 6:48 pm
          </div>
        </div>
      </div> -->
    </div>
  </div>
</template>

<script>
import _ from 'lodash'
export default {
  name: "Notifications",
  inject: [
    'getProperty'
  ],
  computed: {
    notifys() {
      return _.orderBy(this.$store.state.notifys, ['current_time'], ['desc'])
    }
  },
}
</script>

<style>

</style>