var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-900",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "dialer-settings-section pickupGroups"
  }, [_c('div', {
    staticClass: "dialer-flex"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newBackButton mr-16px",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Custom pickup groups ")]), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.call_pickup
    },
    attrs: {
      "title": _vm.getUserPermissions.call_pickup ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_pickup
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddPickUpGroupModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add new")])], 1)])])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between md-mar-top md-mar-bottom"
  }, [_c('span', [_vm._v("Manage all your pickup groups.")]), _c('div', {
    staticClass: "d-flex justify-content-end align-items-center w-50"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox",
    class: {
      'for-disable': !_vm.getUserPermissions.call_pickup
    },
    attrs: {
      "title": _vm.getUserPermissions.call_pickup ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-input', {
    attrs: {
      "disabled": !_vm.getUserPermissions.call_pickup,
      "type": "text",
      "placeholder": "Search pickup groups"
    },
    model: {
      value: _vm.filter.pickup.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.pickup, "search", $$v);
      },
      expression: "filter.pickup.search"
    }
  })], 1)])]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.pickupGroups.length == 0,
      "listLength": _vm.pickupGroups.length,
      "loading": _vm.api.fetch_pickup_groups.send,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [!_vm.$store.getters.getIsMobile ? _c('tr', {
          staticClass: "cursor_auto"
        }, [_c('th', {
          staticClass: "dialer-has-sort cursor_auto"
        }, [_c('span', {
          staticClass: "dialer-sorted-by ascending dialer-has-sort"
        }, [_vm._v("Label")])]), _c('th', {
          staticClass: "dialer-has-sort cursor_auto"
        }, [_c('span', {
          staticClass: "dialer-sorted-by ascending dialer-has-sort"
        }, [_vm._v("Members")])]), _c('th', {
          staticClass: "dialer-has-sort dialer-col-right",
          staticStyle: {
            "max-width": "115px",
            "width": "115px"
          }
        })]) : _vm._e()];
      },
      proxy: true
    }, {
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [!_vm.$store.getters.getIsMobile ? _vm._l(_vm.pickupGroups, function (pickupgroup, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: pickupgroup.id,
            staticClass: "dialer-row-select",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('EditPickUpGroupModal', {
                  pickupgroup: pickupgroup
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title w-50"
          }, [_vm._v(_vm._s(pickupgroup.name))]), _c('td', {
            staticClass: "dialer-row-title three w-25"
          }, [_c('a', {
            staticClass: "dialer-link-more text-decoration-underline"
          }, [_vm._v(" " + _vm._s(Object.keys(pickupgroup.members).length > 1 ? Object.keys(pickupgroup.members).length : 'Add/remove') + " users ")])]), _c('td', {
            staticClass: "dialer-row-title dialer-col-right",
            staticStyle: {
              "max-width": "115px",
              "width": "115px"
            }
          }, [_c('span', {
            staticClass: "newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content w-fit-content ml-0 mr-0 showOnly-OnHover-insideTable"
          }, [_vm._v(" Configure ")])])]);
        }) : _vm._l(_vm.pickupGroups, function (pickupgroup, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: pickupgroup.id,
            staticClass: "dialer-row-select",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('EditPickUpGroupModal', {
                  pickupgroup: pickupgroup
                });
              }
            }
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(pickupgroup.name))])]), _c('td', {
            staticClass: "right dialer-col-right"
          }, [_c('div', {
            staticClass: "textInsideCard text-decoration-underline"
          }, [_vm._v(" " + _vm._s(Object.keys(pickupgroup.members).length > 1 ? Object.keys(pickupgroup.members).length : 'Add/remove') + " users ")])])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "21px",
              "width": "180px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mr-2",
            staticStyle: {
              "height": "21px",
              "width": "180px"
            }
          })])])]);
        });
      },
      proxy: true
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "latestGreyBox-9-9-2023 mb-32px"
        }, [_c('div', {
          staticClass: "emptyScreenContainer"
        }, [_c('img', {
          staticClass: "mt-12px wd-30 h-auto",
          attrs: {
            "src": require('@/assets/images/emptyScreenImages/pickupGroups.png')
          }
        }), _c('div', {
          staticClass: "emptyScreenContainer-heading mt-32px"
        }, [_vm._v("Add a new pickup group")]), _c('div', {
          staticClass: "emptyScreenContainer-text w-75"
        }, [_vm._v("Easily create and manage pickup groups for efficient call routing")]), _c('div', {
          directives: [{
            name: "b-tooltip",
            rawName: "v-b-tooltip"
          }],
          class: {
            'for-disable': !_vm.getUserPermissions.call_pickup
          },
          attrs: {
            "title": _vm.getUserPermissions.call_pickup ? '' : 'Your package does not support this feature'
          }
        }, [_c('button', {
          staticClass: "newButton mt-20px",
          attrs: {
            "disabled": !_vm.getUserPermissions.call_pickup
          },
          on: {
            "click": function ($event) {
              return _vm.$modal.show('AddPickUpGroupModal');
            }
          }
        }, [_c('vb-icon', {
          attrs: {
            "icon": "squared-add-icon",
            "height": "38px",
            "width": "38px"
          }
        }), _c('span', {
          staticClass: "newButton-textPart"
        }, [_vm._v("Add pickup group")])], 1)])])])];
      },
      proxy: true
    }])
  }), _c('AddPickUpGroup', {
    on: {
      "load": function ($event) {
        return _vm.fetchPickupGroups();
      }
    }
  }), _c('EditPickUpGroupModal', {
    on: {
      "load": function ($event) {
        return _vm.fetchPickupGroups();
      }
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }