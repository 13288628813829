
import { VOIP_API } from "../index"

export const INBOUND_RULE_API_ENDPOINTS = {
  list(params={}) {
    return VOIP_API.axios.voip.request({
      url: "inboundrules",
      method: "GET",
      params,
    })
  },
  create(data={}) {
    return VOIP_API.axios.voip.request({
      url: "inboundrules",
      method: "POST",
      data,
    })
  },
  delete(id='',data={}) {
    return VOIP_API.axios.voip.request({
      url: `inboundrules/${id}`,
      method: "DELETE",
      data,
    })
  },
}