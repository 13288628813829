var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-callListing-area ml-auto mr-2 mb-2"
  }, [_vm.totalCalls.length > 1 ? _c('div', {
    staticClass: "dialer-dropdown sm-mar-left whiteBGinputWithGreyRoundedBorder dropdownVersion duringCallDropdown"
  }, [_c('b-dropdown', {
    staticClass: "m-2 w-350px during-video-call",
    attrs: {
      "dropleft": "",
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_vm._v(" " + _vm._s(`${_vm.totalCalls.length} Calls`) + " "), _c('b-icon-chevron-down', {
          staticClass: "sm-mar-left"
        })];
      },
      proxy: true
    }], null, false, 3917775809)
  }, [_c('h6', {
    staticClass: "m-0 pt-2"
  }, [_vm._v("Switch Call")]), _vm._l(_vm.totalCalls, function (call) {
    return _c('b-dropdown-item', {
      key: `${call.room || call.uuid}-nested`,
      on: {
        "click": function ($event) {
          return _vm.playCall(call);
        }
      }
    }, [_c('Item', {
      attrs: {
        "activeCall": _vm.activeCall,
        "data": call,
        "is_sip": call.is_sip
      }
    })], 1);
  }), _vm.isEmpty(_vm.totalCalls) ? _c('vb-no-record', {
    attrs: {
      "text": "No calls on Hold",
      "design": 3
    }
  }) : _vm._e()], 2)], 1) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }