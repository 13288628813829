<template>
    <div v-if="!isEmpty(data)" class="msg-media-inner rounded-2 overflow-hidden" :class="[ data.file_type === 'video' ? 'msg-video' : ' ' , data.file_type === 'image' && !data.is_card? 'msg-image' : ' ' , index > 3 ? 'morethan4' : '' ]">
    <!-- file is not card -->
    <template v-if="!data.is_card">
      <!-- image -->
      <div v-if="data.file_type === 'image'" @click="$modal.show(`${random_id}-MediaFilesModal`, {channel_id, file_id: file.id})">
        <div class="dialer-image-container">
          <!-- :style="`width: ${data.width}px;height: ${data.height}px`" -->
          <img :src="data.src" @error="setData" :alt="data.alt" style="max-width: 100%" />
        </div>
      </div>

      <!-- audio -->
      <div v-else-if="data.file_type === 'audio'" class="dialer-audio-container">
        <vb-audio-player :src="data.src" />
      </div>
      
      <div v-else-if="data.file_type === 'video'"  @click="$modal.show(`${random_id}-MediaFilesModal`, {channel_id, file_id: file.id})">
        <VueVideoThumbnail
          :video-src="data.src"
          :snapshot-at-duration-percent="parseInt(0, 10)"
          :width="500"
          :height="300"
          :chunks-quantity="4"
        />
      </div>
    </template>

    <!-- file card -->
    <div v-else class="main-card">
      <div class="file-type-icon" @click="publicLink(data)">
        <b-icon class="file-icon" :icon="data.print_file_icon" width="40px" height="40px" />
      </div>
      <div class="file-info-text" @click="publicLink(data)">
        <h6 class="font-weight-bold mb-1 text-left">{{ data.print_name }}</h6>
        <p>{{ data.print_size }}</p>
      </div>
      <!-- <div class="file-download-icon" @click="download(data)">
        <b-icon class="download-icon" icon="download" />
      </div> -->
    </div>
    <!-- ----------- -->
  </div>
</template>

<script>
import { MATTERMOST_API } from "@/MatterMost";
import { TokenService } from "@/services";
import { events, $fn, URLS } from "@/utils";
import VueVideoThumbnail from 'vue-video-thumbnail'
export default {
  name: "File",
  components:{
    VueVideoThumbnail
  },
  props: {
    file: {
      type: Object,
      default: () => ({}),
    },
    channel_id: {
      type: String,
    },
    index: {
      type: Number,
    },
    post_id: {
      type: String,
    },
    message_type: {
      type: String,
      default: "sender",
    },
    type: {
      type: String,
      default: "post",
    },
    design: {
      type: String,
      default: "main",
    },
    random_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      is_play: false,
      progress: 0,
      duration: 0,
    };
  },
  inject:['isEmpty'],
  computed: {
    data() {
      return this.file.extra_data;
    },
  },
  filters: {
    durationShow(value) {
      if (value < 60) return `00:${value > 10 ? value : `0${value}`}`;
      return value;
    },
    header(value) {
      let size = 20;
      return !value
        ? ""
        : value.toString().length < size
        ? value
        : value.substring(0, size - 1) + "...";
    },
  },
  watch: {
    is_play(is_play) {
      if (is_play) {
        document.getElementById(this.file.id)?.play();
        this.$root.$emit(events.playing_audio, {
          id: this.file?.id,
        });
      } else {
        document.getElementById(this.file.id)?.pause();
      }
    },
    data: {
      immediate: true,
      async handler(data) {
        if (data !== undefined) return;
        this.setData()
      },
    },
  },
  methods: {
    onFileLoadError(){

    },
    onAudioLoad(Event) {
      this.duration = Math.ceil(Event.target.duration);
    },
    onTimeChange(Event) {
      if (Event.target.duration === Event.target.currentTime) {
        this.is_play = false;
        this.progress = 0;
        this.duration = Math.ceil(Event.target.duration);
        return;
      }
      this.duration = Math.ceil(
        Event.target.duration - Event.target.currentTime
      );
      this.progress = Math.ceil(
        (Event.target.currentTime / Event.target.duration) * 100
      );
    },
    download(file) {
      fetch(
        `${URLS.chatApi}/files/${file.id}?download=1`,
        {
          headers: {
            "X-Requested-With": "XMLHttpRequest",
            Authorization: `Bearer ${TokenService.MATTERMOST_TOKEN.get()}`,
          },
        }
      )
        .then((resp) => resp.blob())
        .then((blob) => {
          const url = window.URL.createObjectURL(blob);
          const a = document.createElement("a");
          a.style.display = "none";
          a.href = url;
          a.download = file.name || "download";
          document.body.appendChild(a);
          a.click();
          a.remove();
          window.URL.revokeObjectURL(url);
        })
        // .catch((error) => console.log("error in downloading file", error));
    },
    async publicLink(file) {
      MATTERMOST_API.endpoints.files.getPublicLink(file.id).then(({ data: { link } }) => {
        window.open(link);
      });
    },
    async load() {
      const typeIcon = function (type) {
        if (type === "image") {
          return "file-earmark-image-fill";
        } else if (type === "video") {
          return "file-earmark-play-fill";
        } else if (type === "audio") {
          return "file-earmark-music-fill";
        } else {
          return "file-earmark-fill";
        }
      };
      const acceptable_extensions = ["jpg", "png", "jpeg"];
      const { extension } = this.file;
      const [type] = this.file.mime_type.split("/");
      if (type === "image" && acceptable_extensions.indexOf(extension) >= 0) {
        const res = await fetch(
          `${URLS.chatApi}/files/${this.file.id}/preview`,
          {
            headers: {
              "X-Requested-With": "XMLHttpRequest",
              Authorization: `Bearer ${TokenService.MATTERMOST_TOKEN.get()}`,
            },
          }
        );
        const d = await res.blob();
        return {
          ...this.file,
          is_card: false,
          file_type: type,
          extension: extension,
          src: window.URL.createObjectURL(d),
          alt: this.file.name,
        };
      } else if (type === "audio" || type === "video") {
        const {
          data: { link },
        } = await MATTERMOST_API.endpoints.files.getPublicLink(this.file.id);
        return {
          ...this.file,
          is_card: false,
          file_type: type,
          extension: extension,
          src: link,
          alt: this.file.name,
        };
      } else {
        return {
          ...this.file,
          file_type: type,
          is_card: true,
          print_name: this.file.name,
          print_size: this.file.extension.toUpperCase() + " " + $fn.fileSize(this.file.size),
          print_file_icon: typeIcon(type),
        };
      }
    },
    async setData(){
      const data = await this.load();
      if (this.type !== "pinned") {
        if(this.$store.state.mattermost.channels[this.channel_id] && this.$store.state.mattermost.channels[this.channel_id].posts.posts[this.post_id]){
          this.$set(this.$store.state.mattermost.channels[this.channel_id].posts.posts[this.post_id].metadata.files[this.index],"extra_data",data);
        }
      } else {
        this.$set(this.$store.state.mattermost.channels[this.channel_id].pinned_posts.posts[this.post_id].metadata.files[this.index],"extra_data",data);
      }
    },
    onPlayingAudio(message) {
      if (message?.id !== this?.file?.id && this.is_play) {
        this.is_play = false;
      }
    },
    cap() {
      // console.log("capture")
      setTimeout(()=> {
        let canvas = document.getElementById(`${this.post_id}-canvas`);
        let video = document.getElementById(`${this.post_id}-video`);
        canvas.width = video.videoWidth;
        canvas.height = video.videoHeight;
        canvas.getContext('2d').drawImage(video, 4, 6, video.videoWidth, video.videoHeight); // for drawing the video element on the canvas
        // console.log("hiiii", canvas, video, video.videoWidth, video.videoHeight)
      }, 3000)
    }
  },
  async created() {
    this.$root.$on(events.playing_audio, this.onPlayingAudio);
  },
  mounted(){
    // this.cap()
  },
  beforeDestroy(){
    this.$root.$off(events.playing_audio, this.onPlayingAudio);
  },
};
</script>

<style lang="scss">
video{
  --aspect-ratio: 315 / 560;
}
.msg-media {
  // display:flex;
  .msg-media-inner {
    display: flex;
    min-width: 100px;
    flex: 1;
    margin: 5px;
    margin-right:0px;
    .bg-white {
      @include  box-shadow-mixin(0 0px 12px rgba(0, 0, 0, 0.07));
    }
    .dialer-image-container {
      // max-width: 320px;
      max-width:100px;
      max-height:100px;
      width:100px;
      height:100px;
      img{
        object-fit: cover;
        height:100px;
        // transform: scale(1.5);
      }
    }
    &:last-child {
      margin-bottom: 0px;
      margin-right: 0px;
    }
    &.msg-video{
      // height:300px;
      // width:400px;
      height: 185px;
      width: 304px;
      .dialer-video-container{
        // height: 300px;
        // width: 400px;
        height: 227px;
        width: 304px;
        video{
          height: 100%;
          width:304px;
        }
      }
    }
    &.msg-image{
      max-width: 100px;
      // max-height: 100px;
      // width: 100px;
      // height: 100px;
      // display: flex;
      display: inline-flex;
      justify-content: flex-end;
      align-content: flex-end;
      align-items: flex-end;
      flex-direction: row;
      background-color: transparent !important;

      margin: 5px;
      & > div{
        max-width: 100px;
        max-height: 100px;
        width: 100px;
        height: 100px;
        @include border-radius($border-radius $border-radius $border-radius $border-radius);
        .dialer-image-container{
          @include border-radius($border-radius $border-radius $border-radius $border-radius);
          img{
            @include border-radius($border-radius $border-radius $border-radius $border-radius);
          }
        }
      }
      // float:right;
      // margin-left: auto;
      // order: 2
    }
    // &.rounded{
    //   @include border-radius(
    //   $border-radius $border-radius $border-radius - $border-radius
    //     $border-radius
    //    !important);
    // }
  }
}
.msg-media{
  &.video-msg-container{
    display: flex;
    justify-content: flex-end;
    align-content: flex-end;
    align-items: flex-end;
    flex-direction: column;
  }

}
.chat-msg-box{
  &.other-user-chat-msg-box{
    .msg-media{
      &.video-msg-container{
        display: flex;
        justify-content: flex-start;
        align-content: flex-start;
        align-items: flex-start;
        flex-direction: column;
      }
      .main-card{
        background-color: $chat_secondary_user;
        @include border-radius($border-radius 0 0 $border-radius);
      }
    }

  }
}


.main-card {
  display: flex;
  min-width: 210px;
  max-width: 300px;
  width: 100%;
  // background: $white;
  background-color: $chat_primary_user;
  position: relative;
  padding: 10px 10px 10px 5px;
  .file-icon {
    color: $primary;
  }
  .file-type-icon {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  .file-info-text {
    // width: calc(100% - 40px);
    width: calc(90% - 40px);
    padding: 0px 3px 3px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: flex-start;
    h6 {
      width: 100%;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      margin: 0px;
      font-size: 15px;
    }
    p {
      margin: 0px;
      font-size: 14px;
    }
  }

  .file-download-icon {
    position: absolute;
    right: 10px;
    bottom: 10px;
    cursor: pointer;
    display: flex;
    justify-content: center;
    align-items: center;
    color: $body-color;
  }
}

.dialer-audio-message {
  width: 200px;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  .dialer-audio-progress {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    margin-top: 10px;
    width: calc(100% - 36px);
    .progress {
      background-color: $primary;
      width: 100%;
      height: 4px;
      margin: 0px !important;
      .progress-bar {
        background-color: $gray-500;
      }
    }
    small {
      font-size: 13px;
      color: $gray-600;
    }
  }
  svg {
    color: $primary;
    background-color: $white;
    @include border-radius(50%);
    cursor: pointer;
    transition: 0.3s all ease-in-out;
    &:hover {
      color: $white;
      background-color: $primary;
    }
  }
}
</style>
