var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup global-modal-hrm Scrollable-hrm",
    attrs: {
      "name": "HRMAddEmergencyContactModal",
      "clickToClose": true,
      "scrollable": true
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Add Contact")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('HRMAddEmergencyContactModal');
      }
    }
  }) : _c('a', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "id": "CloseModalButton"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('HRMAddEmergencyContactModal');
      }
    }
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Full name")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Relationship")]), _c('div', {
    staticClass: "dd-gm-container"
  }, [_c('b-dropdown', {
    staticClass: "gm-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "gm-dropi-contenti"
        }, [_vm._v("Relationship")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Brother")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("Sister")])], 1)], 1)]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Home phone")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Mobile phone")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("E-mail")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("City")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "list": "input-list",
      "placeholder": "25090"
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Country")]), _c('div', {
    staticClass: "dd-gm-container"
  }, [_c('b-dropdown', {
    staticClass: "gm-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "gm-dropi-contenti"
        }, [_vm._v("Country")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("USA")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("UK")])], 1)], 1)]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street "
    }
  }, [_vm._v("Address 1")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "list": "input-list",
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Address 2")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "list": "input-list",
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("City")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "list": "input-list",
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Region")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "list": "input-list",
      "placeholder": ""
    }
  })], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label",
    attrs: {
      "for": "Street"
    }
  }, [_vm._v("Postcode")]), _c('b-form-input', {
    staticClass: "gm-hrm-fields",
    attrs: {
      "list": "input-list",
      "placeholder": ""
    }
  })], 1), _c('button', {
    staticClass: "btn-primarynewupdated1 mt-4 mb-4"
  }, [_vm._v("Add")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }