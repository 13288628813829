var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center mb-16px"
  }, [_vm._l(_vm.GET_CRM_ACTIVITY_TYPES, function (activity_type) {
    return _c('div', {
      key: activity_type.id,
      staticClass: "newCloseButton onlyIcon mr-2",
      class: {
        active: activity_type.id == _vm.value
      },
      on: {
        "click": function ($event) {
          _vm.disabled ? '' : _vm.$emit('change', activity_type.id);
        }
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": _vm._f("filterIcon")(activity_type.icon),
        "height": "18px",
        "width": "18px"
      }
    })], 1);
  }), _vm._t("errors")], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }