var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 editTeamModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit pb-4"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Edit team")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.update_label.send || _vm.api.delete_team.send
    },
    on: {
      "click": function ($event) {
        return _vm.updateTeam();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between editTeamModal-firstLine"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withLeftIconOnly w-50"
  }, [_c('label', [_vm._v("Team name")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('img', {
    attrs: {
      "src": _vm._f("get_property")(_vm.team, 'avatar.image.image')
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  }), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_label.name,
      expression: "forms.update_label.name"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "disabled": _vm.api.update_label.send || _vm.api.delete_team.send,
      "type": "text",
      "maxlength": _vm.$v.forms.update_label.name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.update_label.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_label, "name", $event.target.value);
      }
    }
  })]), _vm.forms.update_label.submitted && _vm.$v.forms.update_label.name.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.update_label.name.required ? _c('span', [_vm._v("Name is required")]) : _vm._e(), !_vm.$v.forms.update_label.name.maxLength ? _c('span', [_vm._v("Name can be maximum " + _vm._s(_vm.$v.forms.update_label.name.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "profileImg-section-latest-container"
  }, [_c('div', {
    staticClass: "profileImg-section-latest",
    on: {
      "click": function ($event) {
        _vm.api.delete_team.send ? '' : _vm.$modal.show('ProfileImageUploader', {
          type: 'TEAM',
          accountcode: _vm.team.real_id,
          current_image: _vm.getProperty(_vm.team, 'avatar.image.image', '')
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm._f("get_property")(_vm.team, 'avatar.image.image')
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Telephone numbers")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Direct incoming calls to the team from your available numbers")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row TelephoneNumbersRow"
  }, [_vm.dids.length > 0 ? _c('div', {
    staticClass: "d-flex IVR-numbers-container flex-wrap"
  }, _vm._l(_vm.dids, function (phone, index) {
    return _c('div', {
      key: index,
      staticClass: "newIVRLayout-number-item"
    }, [_c('img', {
      staticClass: "country-img",
      attrs: {
        "width": "45px",
        "src": _vm._f("flag_icon")(_vm._f("number_formater")(phone.did))
      }
    }), _c('span', {
      staticClass: "newIVRLayout-number-item-text"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(phone.did)))])]);
  }), 0) : [_c('p', {
    staticClass: "dialer-box-text highlight mb-0"
  }, [_vm._v("No numbers assigned")])], _c('div', {
    staticClass: "lg-mar-left TelephoneNumbersRow-button-container"
  }, [_c('button', {
    staticClass: "newButton mwd-265px md-mar-left",
    attrs: {
      "type": "button",
      "disabled": _vm.api.delete_team.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AssignNumberModal`, {
          accountcode: _vm.accountcode
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add/Remove telephone number")])], 1)])], 2), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Team members")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v(_vm._s(_vm._f("memberCount")(_vm.team.members)))])]), _c('button', {
    staticClass: "newButton md-mar-left",
    attrs: {
      "type": "button",
      "disabled": _vm.api.delete_team.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AssignNumbersTeam', {
          team: _vm.team,
          type: 'AssignNumbersTeam'
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-singleUser-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add/Remove user")])], 1)]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Pickup groups")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("Enable or disable pickup groups.")])]), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: ['', !_vm.getUserPermissions.call_pickup ? 'for-disable' : ''],
    attrs: {
      "id": "PickupGroupSwitch",
      "title": _vm.getUserPermissions.call_pickup ? '' : 'Your package does not support this feature'
    }
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_pickup || _vm.api.delete_team.send || _vm.api.update_pickgroup.send,
      "name": "check-button",
      "switch": "",
      "checked": _vm.forms.update_pickup_group.pickup_group
    },
    on: {
      "change": function ($event) {
        return _vm.updatePickupGroup($event);
      }
    }
  })], 1)])])]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call routing")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside"
  }, [!!_vm.accountcode ? _c('CallForwardingSetting', {
    attrs: {
      "forwardCallerId": true,
      "account": _vm.accountcode
    },
    on: {
      "cf-updated": function ($event) {
        return _vm.$emit('cf-updated');
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0"
  }, [!!_vm.accountcode ? _c('ScheduleSetting', {
    attrs: {
      "account": _vm.accountcode
    },
    on: {
      "ofh-days-updated": function ($event) {
        return _vm.$emit('ofh-days-updated');
      },
      "ofh-default-updated": function ($event) {
        return _vm.$emit('ofh-default-updated');
      },
      "ofh-toggle-updated": function ($event) {
        return _vm.$emit('ofh-toggle-updated');
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside"
  }, [_vm.accountcode ? _c('MusicOnHoldWidget', {
    attrs: {
      "accountcode": _vm.accountcode
    }
  }) : _vm._e()], 1), _vm.data.accountcode ? _c('MissedCallNotificationWidget', {
    attrs: {
      "accountcode": _vm.data.accountcode
    }
  }) : _vm._e()], 1)]), _vm.data.accountcode ? _c('VoicemailSetting', {
    staticClass: "VoicemailSetting-insideTeamsModal",
    attrs: {
      "accountcode": _vm.data.accountcode
    }
  }) : _vm._e(), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Delete this team?")])]), _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "disabled": _vm.api.delete_team.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.deleteTeam();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart"
  }, [_vm._v("Delete")])], 1)])])])], 1)])]), _c('AssignNumberModal', {
    attrs: {
      "modalName": `${_vm._uid}-AssignNumberModal`
    },
    on: {
      "added": function ($event) {
        return _vm.$emit('interface');
      }
    }
  }), _c('ProfileImageUploader', {
    on: {
      "image-uploaded": function ($event) {
        return _vm.$emit('interface');
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }