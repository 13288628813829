<template>
  <div>
    <modal class="dialer_animation right_side_popup m-w-500 min-width-50percent-modal" width="500px" height="auto" name="EditLocationModal" :scrollable="true" @closed="onClose" @before-open="beforeOpen">
      <section class="ct-user-new dialer-edit">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="editLocation">
          <div v-if="$store.getters.getIsMobile" class="dialer-edit-header flex-column">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <h2 class="dialer-edit-title">Edit Location</h2>
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('EditLocationModal')" />
            </div>
            <div class="dialer-edit-actions w-100 d-flex justify-content-end">
              <button class="newDoneButton" type="submit" :disabled="api_sent.edit">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api_sent.edit" />
                  <!-- <template v-else>Done</template> -->
                  <template v-else>Save</template>
                </span>
              </button>
            </div>
          </div>
          <div v-else class="dialer-edit-header">
            <div>
              <h2 class="dialer-settings-title newer mb-0">Edit Location</h2>
            </div>
            <div class="dialer-edit-actions">
              <a id="edit-location-modal-close" class="newCloseButton" @click="$modal.hide('EditLocationModal')">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
              <button class="newDoneButton" type="submit" :disabled="api_sent.edit">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api_sent.edit" />
                  <!-- <template v-else>Done</template> -->
                  <template v-else>Save</template>
                </span>
              </button>
            </div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <!-- Select Extension -->
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100 mb-0">
              <label>Select Extension</label>
              <b-select id="edit-select-extension" v-model="forms.edit_location.callerId" :disabled="api_sent.edit || api_sent.list">
                <option value="">Select Extensions</option>
                <option v-for="(callerid, index) in response.list.callerid" :key="index" :value="callerid">{{ callerid}}</option>
              </b-select>
              <template v-if="submitted.edit">
                <p v-if="!$v.forms.edit_location.callerId.required" class="text text-danger animated bounceIntop mb-0">
                  <span>* Extenion is required</span>
                </p>
              </template>
            </div>
            <!-- Select Country -->
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0">
              <label>Select Country</label>
              <b-select id="edit-select-country" v-model="forms.edit_location.country" :disabled="api_sent.edit || api_sent.list">
                <option value="">Select Country</option>
                <option v-for="country in response.list.countries" :key="country.Code2" :value="country.Code2">{{ country.Name }}</option>
              </b-select>
              <template v-if="submitted.edit">
                <p v-if="!$v.forms.edit_location.country.required" class="text text-danger animated bounceIntop mb-0">
                  <span>* Country is required</span>
                </p>
              </template>
            </div>
            <!-- Manually Enter the Address switch -->
            <div v-if="forms.edit_location.country === 'GB'" class="d-flex align-items-center justify-content-between whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0">
              <label>Manually Enter the Address</label>
              <b-check id="edit-manually-check" class="newerSwitch custom-control custom-switch" v-model="is_manual_address" :disabled="api_sent.edit" switch />
            </div>
            <template v-if="is_manual_address">
              <div class="whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0">
                <label>Postal Code</label>
                <input id="edit-manual-postal-code" class="w-100" type="text" v-model="forms.manual_address.postal_code" :disabled="api_sent.edit" />
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.manual_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Postal Code is required</span>
                  </p>
                </template>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0">
                <label>City</label>
                <input id="edit-manual-city" class="w-100" type="text" v-model="forms.manual_address.city" :disabled="api_sent.edit" />
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.manual_address.city.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* City is required</span>
                  </p>
                </template>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0">
                <label>Street</label>
                <input id="edit-manual-street" class="w-100" type="text" v-model="forms.manual_address.street" :disabled="api_sent.edit" />
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.manual_address.street.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Street is required</span>
                  </p>
                </template>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0">
                <label>House Name</label>
                <input id="edit-manual-house-name" class="w-100" type="text" v-model="forms.manual_address.house_name" :disabled="api_sent.edit" />
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.manual_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* House Name is required</span>
                  </p>
                </template>
              </div>
            </template>
            <template v-else>
              <div class="whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0">
                <label>Postal Code</label>
                <input id="edit-auto-postal-code" class="w-100" type="text" v-model="forms.uk_address.postal_code" :disabled="api_sent.edit" />
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.uk_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Postal Code is required</span>
                  </p>
                  <p v-else-if="!$v.forms.uk_address.postal_code.valid" class="text text-danger animated bounceIntop mb-0">
                    <span>* Postal Code is invalid</span>
                  </p>
                </template>
              </div>
              <div class="whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0">
                <label>House Name</label>
                <input id="edit-auto-house-no" class="w-100" type="text" v-model="forms.uk_address.house_name" :disabled="api_sent.edit" />
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.uk_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* House Name is required</span>
                  </p>
                </template>
              </div>
              <div v-if="forms.uk_address.address && response.address.addresses.indexOf(forms.uk_address.address)==-1"
                class="whiteBGinputWithGreyRoundedBorder d-flex align-items-center justify-content-between mt-20px w-100 mb-0"
              >
                <label>Select Address</label>
                <b-check id="edit-auto-select-address-check" class="newerSwitch custom-control custom-switch"  @change="forms.uk_address.address='';get_address_from_select=!get_address_from_select;" :disabled="api_sent.edit" switch />
              </div>
              <div v-if="forms.uk_address.address && response.address.addresses.indexOf(forms.uk_address.address)==-1"
                class="whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0"
              >
                <label>Address</label>
                <input id="edit-auto-address" class="w-100" type="text" :value="forms.uk_address.address" :disabled="true" />
              </div>
              <div v-else class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100 mb-0">
                <label>Select Address</label>
                <b-select id="edit-auto-seelct-address" v-model="forms.uk_address.address" :disabled="api_sent.edit">
                  <option value="">Select Address</option>
                  <option v-for="address in response.address.addresses" :key="address" :value="address">{{ address.replace(/,/g,'').replace(/\s+/g,' ').trim() }}</option>
                </b-select>
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.uk_address.address.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Address is required</span>
                  </p>
                </template>
              </div>
            </template>
            <div class="whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0">
              <label>First Name</label>
              <input id="edit-first-name" class="w-100" type="text" v-model="forms.edit_location.forename" :disabled="api_sent.edit" />
              <template v-if="submitted.edit">
                <p v-if="!$v.forms.edit_location.forename.required" class="text text-danger animated bounceIntop mb-0">
                  <span>* Forename is required</span>
                </p>
              </template>
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder mt-20px w-100 mb-0">
              <label>Last Name</label>
              <input id="edit-last-name" class="w-100" type="text" v-model="forms.edit_location.surname" :disabled="api_sent.edit" />
              <template v-if="submitted.edit">
                <p v-if="!$v.forms.edit_location.surname.required" class="text text-danger animated bounceIntop mb-0">
                  <span>* Surname is required</span>
                </p>
              </template>
            </div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="latestGreyBox-heading-main">Delete this Address</div>
                <button class="dialer-button dialer-button-delete-alt" type="button"  @click="deleteLocation(location.real_id)">
                  <template>Delete</template>
                </button>  
              </div>
            </div>
          </div>




          <!-- <div v-if="false" class="md-mar-vertical">
            <div class="dialer-team-name mb-4">

              <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                <div class="w-100">
                  <label>Select Extension</label>
                  <b-select id="edit-select-extension" v-model="forms.edit_location.callerId" :disabled="api_sent.edit || api_sent.list">
                    <option value="">Select Extensions</option>
                    <option v-for="(callerid, index) in response.list.callerid" :key="index" :value="callerid">{{ callerid}}</option>
                  </b-select>
                </div>
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.edit_location.callerId.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Extenion is required</span>
                  </p>
                </template>
              </div>

              <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                <div class="w-100">
                  <label>Select Country</label>
                  <b-select id="edit-select-country" v-model="forms.edit_location.country" :disabled="api_sent.edit || api_sent.list">
                    <option value="">Select Country</option>
                    <option v-for="country in response.list.countries" :key="country.Code2" :value="country.Code2">{{ country.Name }}</option>
                  </b-select>
                </div>
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.edit_location.country.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Country is required</span>
                  </p>
                </template>
              </div>

              <div v-if="forms.edit_location.country === 'GB'" class="dialer-input-field md-mar-bottom">
                <div class="w-100" style=" display: flex;justify-content: space-between;align-items: center;">
                  <span>Manually Enter the Address</span>
                  <b-check id="edit-manually-check" class="dialer-switch custom-control custom-switch wd-50px" v-model="is_manual_address" :disabled="api_sent.edit" switch />
                </div>
              </div>

              <template v-if="is_manual_address">
                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>Postal Code</label>
                    <input id="edit-manual-postal-code" class="w-100" type="text" v-model="forms.manual_address.postal_code" :disabled="api_sent.edit" />
                  </div>
                  <template v-if="submitted.edit">
                    <p v-if="!$v.forms.manual_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal Code is required</span>
                    </p>
                  </template>
                </div>

                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>City</label>
                    <input id="edit-manual-city" class="w-100" type="text" v-model="forms.manual_address.city" :disabled="api_sent.edit" />
                  </div>
                  <template v-if="submitted.edit">
                    <p v-if="!$v.forms.manual_address.city.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* City is required</span>
                    </p>
                  </template>
                </div>

                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>Street</label>
                    <input id="edit-manual-street" class="w-100" type="text" v-model="forms.manual_address.street" :disabled="api_sent.edit" />
                  </div>
                  <template v-if="submitted.edit">
                    <p v-if="!$v.forms.manual_address.street.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Street is required</span>
                    </p>
                  </template>
                </div>

                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>House Name</label>
                    <input id="edit-manual-house-name" class="w-100" type="text" v-model="forms.manual_address.house_name" :disabled="api_sent.edit" />
                  </div>
                  <template v-if="submitted.edit">
                    <p v-if="!$v.forms.manual_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* House Name is required</span>
                    </p>
                  </template>
                </div>
              </template>

              <template v-else>
                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>Postal Code</label>
                    <input id="edit-auto-postal-code" class="w-100" type="text" v-model="forms.uk_address.postal_code" :disabled="api_sent.edit" />
                  </div>
                  <template v-if="submitted.edit">
                    <p v-if="!$v.forms.uk_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal Code is required</span>
                    </p>
                    <p v-else-if="!$v.forms.uk_address.postal_code.valid" class="text text-danger animated bounceIntop mb-0">
                      <span>* Postal Code is invalid</span>
                    </p>
                  </template>
                </div>

                <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>House Name</label>
                    <input id="edit-auto-house-no" class="w-100" type="text" v-model="forms.uk_address.house_name" :disabled="api_sent.edit" />
                  </div>
                  <template v-if="submitted.edit">
                    <p v-if="!$v.forms.uk_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* House Name is required</span>
                    </p>
                  </template>
                </div>

                <div v-if="forms.uk_address.address && response.address.addresses.indexOf(forms.uk_address.address)==-1" class="dialer-input-field md-mar-bottom">
                  <div class="w-100" style=" display: flex;justify-content: space-between;align-items: center;">
                    <span>Select Address</span>
                    <b-check id="edit-auto-select-address-check" class="dialer-switch custom-control custom-switch wd-50px"  @change="forms.uk_address.address='';get_address_from_select=!get_address_from_select;" :disabled="api_sent.edit" switch />
                  </div>
                </div>
                <div v-if="forms.uk_address.address && response.address.addresses.indexOf(forms.uk_address.address)==-1" class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>Address</label>
                    <input id="edit-auto-address" class="w-100" type="text" :value="forms.uk_address.address" :disabled="true" />
                  </div>
                </div>

                <div v-else class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                  <div class="w-100">
                    <label>Select Address</label>
                    <b-select id="edit-auto-seelct-address" v-model="forms.uk_address.address" :disabled="api_sent.edit">
                      <option value="">Select Address</option>
                      <option v-for="address in response.address.addresses" :key="address" :value="address">{{ address.replace(/,/g,'').replace(/\s+/g,' ').trim() }}</option>
                    </b-select>
                  </div>
                  <template v-if="submitted.edit">
                    <p v-if="!$v.forms.uk_address.address.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Address is required</span>
                    </p>
                  </template>
                </div>
              </template>

              <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                <div class="w-100">
                  <label>First Name</label>
                  <input id="edit-first-name" class="w-100" type="text" v-model="forms.edit_location.forename" :disabled="api_sent.edit" />
                </div>
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.edit_location.forename.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Forename is required</span>
                  </p>
                </template>
              </div>

              <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom">
                <div class="w-100">
                  <label>Last Name</label>
                  <input id="edit-last-name" class="w-100" type="text" v-model="forms.edit_location.surname" :disabled="api_sent.edit" />
                </div>
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.edit_location.surname.required" class="text text-danger animated bounceIntop mb-0">
                    <span>* Surname is required</span>
                  </p>
                </template>
              </div>

              <div class="dialer-input-field input-consistent-inner-shadows md-mar-bottom mt-4">
                <div class="w-100 d-flex justify-content-between align-items-center">
                  <label>Delete this Address</label>
                  <button class="dialer-button dialer-button-delete-alt" type="button"  @click="deleteLocation(location.real_id)">
                    
                    <template>Delete </template>
                  </button>  
                </div>
              </div>

            </div>
          </div> -->
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { $fn, LOGGER, VOIP_API } from "../../utils";
export default {
  name: "EditLocationModal",
  data() {
    return {
      forms: {
        edit_location: {
          callerId: "",
          country: "",
          surname: "",
          forename: "",
          reset() {
            this.callerId = "";
            this.country = "";
            this.surname = "";
            this.forename = "";
          },
        },
        manual_address: {
          postal_code: "",
          city: "",
          street: "",
          house_name: "",
          reset() {
            this.postal_code = "";
            this.city = "";
            this.street = "";
            this.house_name = "";
          },
        },
        uk_address: {
          postal_code: "",
          house_name: "",
          address: "",
          reset() {
            this.postal_code = "";
            this.house_name = "";
            this.address = "";
          },
        },
      },
      is_manual_address: true,
      location: {},
      get_address_from_select: false,
      response: {
        list: {
          callerId: [],
          countries: [],
          extensionCount: 0,
          extensions: {},
        },
        address: {
          addresses: [],
          latitude: 0,
          longitude: 0,
        },
      },
      submitted: {
        edit: false,
      },
      api_sent: {
        edit: false,
        list: false,
      },
      assets: {
        spinner: require("@/assets/images/spinner.svg"),
      },
      api: {
        location_delete: this.$helperFunction.apiInstance({ send: [] }),
      },
    };
  },
  computed: {
    editLocationForm() {
      const address = this.forms.uk_address.address.split(",");
      return {
        street_address: this.is_manual_address ? this.forms.manual_address.street : this.get_address_from_select ? address[0].trim() : this.location.address,
        locality: this.is_manual_address ? "" : "",
        county: this.is_manual_address ? "" : this.get_address_from_select ? address[6].trim() : this.location.country,
        is_manual: this.is_manual_address,
        town: this.is_manual_address ? this.forms.manual_address.city : this.get_address_from_select ? address[5].trim() : this.location.town,
        city: this.is_manual_address ? this.forms.manual_address.city : this.get_address_from_select ? address[5].trim() : this.location.town,
        postcode: this.is_manual_address ? this.forms.manual_address.postal_code : this.forms.uk_address.postal_code,
        country: this.forms.edit_location.country,
        house: this.is_manual_address ? this.forms.manual_address.house_name : this.forms.uk_address.house_name,
        caller: this.forms.edit_location.callerId,
        forename: this.forms.edit_location.forename,
        surname: this.forms.edit_location.surname,
      };
    },
  },
  validations: {
    forms: {
      edit_location: {
        callerId: {
          required,
        },
        country: {
          required,
        },
        surname: {
          required,
        },
        forename: {
          required,
        },
      },
      manual_address: {
        postal_code: {
          required,
        },
        city: {
          required,
        },
        street: {
          required,
        },
        house_name: {
          required,
        },
      },
      uk_address: {
        postal_code: {
          required,
          valid: function(...args){ return $fn.isValidUKPostcode(...args) },
        },
        house_name: {
          required,
        },
        address: {
          required,
        },
      },
    },
  },
  inject: [
    'appNotify',
  ],
  watch: {
    "forms.edit_location.country"(country) {
      this.is_manual_address = country !== "GB";
    },
    "forms.uk_address.postal_code"(postal_code) {
      this.resetresponse("address");
      if (this.$v.forms.uk_address.postal_code.valid) {
        this.getAddress(postal_code);
      }
    },
  },
  methods: {
    getAddress(value) {
      let vm = this;
      VOIP_API.endpoints.location.getAddress(value).then(({ data }) => {
        vm.response.address = data;
      });
    },
    resetresponse(type) {
      if (type === "address") {
        this.response.address.addresses = [];
        this.response.address.latitude = 0;
        this.response.address.longitude = 0;
      }
    },
    beforeOpen(event) {
      this.location = event.params.location
      this.setLocationForm(this.location)
      this.load();
    },
    load() {
      let vm = this
      if (this.api_sent.list) return;
      vm.api_sent.list = true;
      VOIP_API.endpoints.location.listcountriesandextensions()
        .then(({ data }) => {
          vm.response.list = data;
          if(vm.location.caller){
            vm.response.list.callerid.push(vm.location.caller)
          }
          vm.$nextTick(()=>{
            vm.setLocationForm(vm.location)
          })
        })
        .catch((error) => {
          LOGGER.danger(error);
        })
        .finally(() => {
          vm.api_sent.list = false;
        });
    },
    editLocation() {
      this.submitted.edit = true;
      this.$v.$touch();
      if (
        this.$v.forms.edit_location.$invalid ||
        this.api_sent.edit ||
        (this.is_manual_address
          ? this.$v.forms.manual_address.$invalid
          : this.$v.forms.uk_address.$invalid)
      )
        return;
      this.api_sent.edit = true;
      VOIP_API.endpoints.location
        .edit({
          ...this.editLocationForm,
          accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
          uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
          id: this.location.id,
          source: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
        })
        .then(({ data: { data: list } }) => {
          this.$modal.hide("EditLocationModal");
          this.appNotify({
            message: "Successfully Updated!",
            type: "success",
          })
          this.forms.edit_location.reset();
          this.forms.manual_address.reset();
          this.forms.uk_address.reset();
          this.$emit("latest-list", {
            list,
          });
        })
        .catch((ex) => {
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(() => {
          this.submitted.edit = false;
          this.api_sent.edit = false;
        });
    },
    setLocationForm(location){
      LOGGER.log(location)
      // if(location.caller){
      //   this.response.list.callerid.push(location.caller)
      // }
      this.forms.edit_location.forename=location.forename
      this.forms.edit_location.surname=location.surname
      this.forms.edit_location.country=location.country
      this.forms.edit_location.callerId=location.caller ?? ''
      this.forms.uk_address.postal_code = location.postcode
      this.forms.uk_address.house_name = location.house
      this.forms.uk_address.address = location.address
      this.is_manual_address=location.country!='GB'
      this.forms.manual_address.postal_code = location.postcode
      this.forms.manual_address.city = location.town
      this.forms.manual_address.street = location.street
      this.forms.manual_address.house_name = location.house
    },
    onClose() {
      this.location={}
      this.forms.edit_location.reset()
      this.forms.manual_address.reset()
      this.forms.uk_address.reset()
      this.submitted.edit=false
    },
    deleteLocation(location_id) {
      let vm = this;
      if (vm.api.location_delete.send.includes(location_id)) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this Location`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.location_delete.send.push(location_id);
            VOIP_API.endpoints.location.delete({
              id: location_id,
            }).then(() => {
              vm.$emit('latest-list')
              vm.$modal.hide('EditLocationModal')
              this.appNotify({
                message: "Successfully Deleted!",
                type: "success",
              })
            }).catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(() => {
              if (vm.api.location_delete.send.includes(location_id)) {
                vm.api.location_delete.send.splice(vm.api.location_delete.send.indexOf(location_id),1);
              }
            });
          }
        },
      });
    },
  },
};
</script>
