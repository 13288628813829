import { MOTHERSHIP } from '@/mothership/index'
import { INTEGRATION_LOGGER } from '@/utils/index'
import Emitter from 'component-emitter'
import { HUBSPOT } from './hubspot/index'
import { SALESFORCE } from './salesforce/index'
import { ZOHO } from './zoho/index'
let events = {
  dial_number: 'dial_number'
}
let platforms = {
  hubspot: 'hubspot',
  zoho: 'zoho',
  salesforce: 'salesforce',
}
export const INTEGRATIONS = {
  platform: '',
  platform_instance: {
    hubspot: null,
    zoho: null,
    salesforce: null,
  },
  callBacks: null,
  get isReady(){ return !!this.platform },
  get events(){ return events },
  get platforms(){ return platforms },
  is_mothership: false,
  init(props){
    try {
      Emitter(this)
      this.callBacks = props.callBacks
      if(process.env.VUE_APP_INTEGRATION=='true'){
        this.platformInit()
      }
      if(process.env.NODE_ENV !== 'production'){
        window.integrations = this
      }
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  platformInit(){
    this.platform_instance.hubspot=HUBSPOT.init({
      onDialNumber: this.onDialNumber.bind(this),
      onReady: this.onReady.bind(this,platforms.hubspot),
    })
    this.platform_instance.zoho=ZOHO.init({
      onDialNumber: this.onDialNumber.bind(this),
      onReady: this.onReady.bind(this,platforms.zoho),
    })
    this.platform_instance.salesforce=SALESFORCE.init({
      onDialNumber: this.onDialNumber.bind(this),
      onReady: this.onReady.bind(this,platforms.salesforce),
    })
  },
  onDialNumber(data){
    this.emit(this.events.dial_number,{
      platform: this.platform,
      ...data,
    })
  },
  onReady(platform){
    INTEGRATION_LOGGER.log('INTEGRATIONS:onReady',platform)
    this.platform=platform
    this.callBacks?.onReady?.(platform)
  },
  loggedin(...args){
    if(this.platform==this.platforms.hubspot){
      this.platform_instance.hubspot.loggedin(...args)
    }
  },
  loggedout(...args){
    if(this.platform==this.platforms.hubspot){
      this.platform_instance.hubspot.loggedout(...args)
    }
  },
  callended(...args){
    if(this.platform==this.platforms.hubspot){
      this.platform_instance.hubspot.callended(...args)
    }
    if(this.is_mothership){
      MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.CALL_ENDED)
    }
  },
  callcompleted(...args){
    INTEGRATION_LOGGER.log('callcompleted')
    if(this.platform==this.platforms.hubspot){
      this.platform_instance.hubspot.callcompleted(...args)
    }
    if(this.is_mothership){
      MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.CALL_COMPLETED)
    }
  },
  incomingcall(...args){
    if(this.platform==this.platforms.hubspot){
      this.platform_instance.hubspot.incomingcall(...args)
    }
    if(this.is_mothership){
      MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.INCOMING_CALL)
    }
  },
  outgoingcall(data){
    if(this.platform==this.platforms.hubspot){
      this.platform_instance.hubspot.outgoingcall(data)
    }
    if(this.is_mothership){
      MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.OUTGOING_CALL,data)
    }
  },
  callanswered(...args){
    if(this.platform==this.platforms.hubspot){
      this.platform_instance.hubspot.callanswered(...args)
    }
    if(this.is_mothership){
      MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.CALL_ANSWERED)
    }
  },
  minimize(...args){
    if(this.platform==this.platforms.zoho){
      this.platform_instance.zoho.minimize(...args)
    }
  },
  maximize(...args){
    if(this.platform==this.platforms.zoho){
      this.platform_instance.zoho.maximize(...args)
    }
  },
}