<template>
<div class="latestDuringCallDesign-parentContainer">
  <template v-if="!getIsMobile">
    <div class="transferCallBar-container" v-if="callObjInfo.is_transfer">
      <div class="transferCallBar-heading">
        Transfer
      </div>
      <div class="d-flex">
        <!-- after transfer -->
        <div 
          :class="{
            'disabled': !callObjInfo.incall,
          }"
          @click="!callObjInfo.incall ? '' : phones.customAfterTransfer();" 
          class="latestDuringCallDesign-iconCloumn afterTransfer-Grid"
        >
          <vb-icon class="makePathBlack" icon="duringCall-afterTransfer-icon" width="63" height="63"/>
          <div class="latestDuringCallDesign-iconCloumn-text">After Transfer</div>
        </div>
        <!-- cancel transfer -->
        <div 
          :class="{
            'disabled': !callObjInfo.incall,
          }"
          @click="!callObjInfo.incall ? '' : phones.cutomCancelTransfer();" 
          class="latestDuringCallDesign-iconCloumn cancelTransfer-Grid"
        >
          <vb-icon class="makePathBlack" icon="duringCall-cancelTransfer-icon" width="63" height="63"/>
          <div class="latestDuringCallDesign-iconCloumn-text">Cancel Transfer</div>
        </div>
      </div>
    </div>
    <div v-else @click="$store.state.global_conditions.tab='homeCopy'" class="recordingDIV-duringCall-container topRightBTN">
      <div class="recordingDIV-duringCall-text">New Call</div>
    </div>
    <!-- <p style="color: white;">{{ session.speech_to_text.text }}</p> -->
    <div v-for="data in session.speech_to_text.streams" :key="data.stream_id">
      <p>{{data.text}}</p>
    </div>
    <!-- <p style="color: white;">{{  }}</p> -->
  </template>

  <div class="latestDuringCallDesign">
    <div 
      v-if="!getIsMobile" 
      :class="!callObjInfo.incall ? 'mobile-incomingCall-container' : 'mobile-duringCall-container'"
      class="latestDuringCallDesign-inner d-flex flex-column align-items-center"
    >
      <div v-if="session.feedback_screen_active">
        
        
      </div>
      <div v-else class="d-flex flex-column align-items-center justify-content-between h-100 w-100">
        <div class="d-flex flex-column align-items-center w-100">
          <h6 v-if="!callObjInfo.incall" class="color-white">{{ callObjInfo.incoming ? 'Incoming call' : 'Outgoing call' }}</h6>
          <p v-if="!callObjInfo.incall" class="color-white">{{ session.callStatus }}</p>
          <div class="call-user-info-container d-flex flex-column align-items-center">
            <div v-if="callObjInfo.test_call" class="color-white latestDuringCallDesign-text">Test Call</div>
            <template v-else>
              <div class="color-white latestDuringCallDesign-text">From: {{callObjInfo.from_name}} {{ callObjInfo.from_number | number_formater }}</div>
              <div class="color-white latestDuringCallDesign-text">To: {{callObjInfo.to_name}} {{ callObjInfo.to_number | number_formater }}</div>
              <div v-if="callObjInfo.call_type" class="color-white latestDuringCallDesign-text">{{ callObjInfo.call_type }}</div>
            </template>
          </div>
          <div v-if="callObjInfo.incall" class="call-time-container d-flex align-items-center">
            <div class="color-white latestDuringCallDesign-text">{{ session.time | incrementFilter }}</div>
          </div>
        </div>
        <div 
          class="w-100"
          :class="{
            'mobile-incomingCall-actions': !callObjInfo.incall,
            'mobile-duringCall-actions': callObjInfo.incall,
            'mobile-transfer-actions': callObjInfo.is_transfer,
          }"
        >
          <div 
            class="latestDuringCallDesign-gridLayout"
            :class="{
              'incomminCallCase': !callObjInfo.incall && callObjInfo.incoming,
            }"
          >
              <!-- 'inTransferCase': callObjInfo.is_transfer && callObjInfo.incall, -->
            <!-- notes -->
            <div 
              v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
              class="latestDuringCallDesign-iconCloumn forNotes-Grid"
              :class="{
                'for-disable': !getUserPermissions.call_tagging_and_notes,
                'disabled': callObjInfo.call_type || !getUserPermissions.call_tagging_and_notes || !(callObjInfo.incall && !callObjInfo.is_transfer)
              }"
              @click="callObjInfo.call_type || !getUserPermissions.call_tagging_and_notes || !(callObjInfo.incall && !callObjInfo.is_transfer) ? '' :
                $modal.show(`${callObjInfo.uuid}NoteModal`, { 
                  id: session.extra_data.id, 
                  note: session.note
                })
              " 
            >
              <vb-icon 
                icon="latestDuringCallDesign-notes-icon" 
                id="NotesIcon" 
                width="63" 
                height="63"
              />
              <div class="latestDuringCallDesign-iconCloumn-text">Notes</div>
            </div>
            <!-- hold -->
            <div 
              class="latestDuringCallDesign-iconCloumn hold-Grid"
              :class="{
                'disabled': !(callObjInfo.incall)
              }"
              @click="!(callObjInfo.incall) ? '' : 
                session.hold()
              " 
            >
              <vb-icon 
                :icon="callObjInfo.hold ? 'dialer-playInBox-icon' : 'latestDuringCallDesign-hold-icon'" 
                :class="{
                  'makePathBlack':callObjInfo.hold
                }" 
                width="63" 
                height="63"
              />
              <div class="latestDuringCallDesign-iconCloumn-text">{{session.held ? 'Resume' : 'Hold'}}</div>
            </div>
            <!-- transfer -->
            <div 
              class="latestDuringCallDesign-iconCloumn transfer-Grid"
              :class="{
                'disabled': callObjInfo.call_type || !(callObjInfo.incall && !callObjInfo.is_transfer && !callObjInfo.call_transfered)
              }"
              @click="callObjInfo.call_type || !(callObjInfo.incall && !callObjInfo.is_transfer && !callObjInfo.call_transfered) ? '' : 
                $modal.show(`${callObjInfo.uuid}TransferCallModal`)
              " 
            >
              <vb-icon icon="latestDuringCallDesign-transfer-icon" width="63" height="63"/>
              <div class="latestDuringCallDesign-iconCloumn-text">Transfer</div>
            </div>
            <!-- tags -->
            <div 
              v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
              class="latestDuringCallDesign-iconCloumn tags-Grid"
              :class="{
                'for-disable': !getUserPermissions.call_tagging_and_notes,
                'disabled': callObjInfo.call_type || !getUserPermissions.call_tagging_and_notes || !(callObjInfo.incall && !callObjInfo.is_transfer)
              }"
              @click="callObjInfo.call_type || !getUserPermissions.call_tagging_and_notes || !(callObjInfo.incall && !callObjInfo.is_transfer) ? '' : 
                $modal.show(`${callObjInfo.uuid}TagsModal`, { 
                  id: session.extra_data.id, 
                  tags: session.tags, 
                })
              " 
            >
              <vb-icon  
                icon="latestDuringCallDesign-tags-icon" 
                id="tagsIcon" 
                width="63" 
                height="63"
              />
              <div class="latestDuringCallDesign-iconCloumn-text">Tags</div>
            </div>
            <!-- mute -->
            <div 
              class="latestDuringCallDesign-iconCloumn mute-Grid"
              :class="{
                'disabled': !(callObjInfo.incall && !callObjInfo.hold)
              }"
              @click="!(callObjInfo.incall && !callObjInfo.hold) ? '' : 
                session.toggelMute()
              " 
            >
              <vb-icon 
                :icon="callObjInfo.is_mute?'latestDuringCallDesign-mute-icon':'latestDuringCallDesign-unMute-icon'" 
                width="63" 
                height="63"
              />
              <div class="latestDuringCallDesign-iconCloumn-text">{{callObjInfo.is_mute?'Mute':'Unmute'}}</div>
            </div>
            <!-- keypad -->
            <div 
              class="latestDuringCallDesign-iconCloumn keypad-Grid"
              :class="{
                'disabled': callObjInfo.call_type || !(callObjInfo.incall && !callObjInfo.is_transfer)
              }"
              @click="callObjInfo.call_type || !(callObjInfo.incall && !callObjInfo.is_transfer) ? '' : 
                $modal.show(`${callObjInfo.uuid}SipCallKeypadModal`)
              " 
            >
              <vb-icon icon="latestDuringCallDesign-keypad-icon" width="63" height="63"/>
              <div class="latestDuringCallDesign-iconCloumn-text">Keypad</div>
            </div>
            <!-- accept -->
            <div 
              v-if="!callObjInfo.incall && callObjInfo.incoming"
              class="latestDuringCallDesign-iconCloumn accept-Grid"
              @click="session.accepts()" 
            >
              <!-- mobile-incomingCall-hangup -->
              <vb-icon icon="mobile-pickup-icon" width="63" height="63"/>
              <div class="latestDuringCallDesign-iconCloumn-text">Accept</div>
            </div>
            <!-- hangup -->
            <div 
              class="latestDuringCallDesign-iconCloumn hangup-icon hangup-Grid"
              @click="session.hangup()" 
            >
              <span :class="`circularanimationContainer ${callObjInfo.call_type || !(callObjInfo.incall && !callObjInfo.is_transfer) ? 'notInCall' : ''}`">
                <vb-icon icon="latestDuringCallDesign-hangUp-icon" width="63" height="63"/>
              </span>
              <div class="latestDuringCallDesign-iconCloumn-text">Hangup</div>
            </div>
            <!-- switch call -->
            <!-- <div 
              v-if="callObjInfo.incall && callObjInfo.is_internal_call"
              class="latestDuringCallDesign-iconCloumn accept-Grid"
              @click="switchCall()" 
            >
              <vb-icon icon="mobile-pickup-icon" width="63" height="63"/>
              <div class="latestDuringCallDesign-iconCloumn-text">Switch Video Call</div>
            </div> -->
          </div>
        </div>
      </div>
    </div>
    <div v-if="getIsMobile" :class="!callObjInfo.incall ? 'mobile-incomingCall-container' : 'mobile-duringCall-container'">
      <h6 v-if="!callObjInfo.incall" class="mobile-incomingCall-heading">{{ callObjInfo.incoming ? 'Incoming call' : 'Outgoing call' }}</h6>
      <p v-if="!callObjInfo.incall" class="mobile-incomingCall-heading">{{ session.callStatus }}</p>
      <div class="mobile-duringCall-top">
        <b-icon icon="chevron-left" font-scale="1.3" class="settings-back-icon cursor_pointer" style="" @click="$emit('to-dashboard')" />
        <div v-if="callObjInfo.incall" class="call-time-container">
          <vb-icon icon="mobile-duringCall-phone-icon" width="14.784" height="14.784"/>
          <div class="call-time">{{ session.time | incrementFilter }}</div>
        </div>
        <vb-icon icon="mobile-duringCall-switchCall-icon" @click="$emit('calls-list')" width="19.461" height="19.492"/>
      </div>
      <div class="call-user-info-container">
        <div class="user-image">
          <img :src="callObjInfo.image" @error="$event.target.src=userDp" />
        </div>
        <div class="user-name">{{ callObjInfo.incoming ? `${callObjInfo.from_name}` : `${callObjInfo.to_name}` }}</div>
        <div class="user-number"> 
          <template v-if="callObjInfo.incoming">{{ callObjInfo.from_number | number_formater }}</template>
          <template v-else>{{ callObjInfo.to_number | number_formater }}</template>
        </div>
      </div>
      <div 
        class="fixed-bottom"
        :class="{
          'mobile-incomingCall-actions': !callObjInfo.incall,
          'mobile-duringCall-actions': callObjInfo.incall,
          'mobile-transfer-actions': callObjInfo.is_transfer,
          'mobile-outGoingCall-actions' : !callObjInfo.incoming,
        }"
      >
        <div class="transferCallCas-container" v-if="callObjInfo.is_transfer">
          <!-- after transfer -->
          <div  
            class="latestDuringCallDesign-iconCloumn afterTransfer-Grid"
            :class="{
              'disabled': !(callObjInfo.incall)
            }"
            @click="!(callObjInfo.incall) ? '' : phones.customAfterTransfer();" 
          >
            <vb-icon class="makePathBlack" icon="duringCall-afterTransfer-icon" width="63" height="63"/>
            <div class="latestDuringCallDesign-iconCloumn-text">After Transfer</div>
          </div>
          <!-- cancel transfer -->
          <div 
            class="latestDuringCallDesign-iconCloumn cancelTransfer-Grid"
            :class="{
              'disabled': !(callObjInfo.incall)
            }"
            @click="!(callObjInfo.incall) ? '' : phones.cutomCancelTransfer()"  
          >
            <vb-icon class="makePathBlack" icon="duringCall-cancelTransfer-icon" width="63" height="63"/>
            <div class="latestDuringCallDesign-iconCloumn-text">Cancel Transfer</div>
          </div>
        </div>
        <div 
          class="latestDuringCallDesign-gridLayout"
          :class="{
            'inTransferCase': callObjInfo.is_transfer && callObjInfo.incall,
          }"
        >
            <!-- 'incomminCallCase': !callObjInfo.incall && callObjInfo.incoming, -->
          <!-- notes -->
          <div 
            v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
            class="latestDuringCallDesign-iconCloumn forNotes-Grid"
            :class="{
              'for-disable': !getUserPermissions.call_tagging_and_notes,
              'disabled': !getUserPermissions.call_tagging_and_notes || !(callObjInfo.incall && !callObjInfo.is_transfer)
            }"
            @click="!getUserPermissions.call_tagging_and_notes || !(callObjInfo.incall && !callObjInfo.is_transfer) ? '' :  $modal.show(`${callObjInfo.uuid}NoteModal`, { 
              id: session.extra_data.id, 
              note: session.note
            })" 
          >
            <vb-icon 
              icon="latestDuringCallDesign-notes-icon" 
              id="NotesIcon" 
              width="63" 
              height="63"
            />
            <div class="latestDuringCallDesign-iconCloumn-text">Notes</div>
          </div>
          <!-- hold -->
          <div 
            class="latestDuringCallDesign-iconCloumn hold-Grid"
            :class="{
              'disabled': !(callObjInfo.incall)
            }"
            @click="!(callObjInfo.incall) ? '' : session.hold()" 
          >
            <vb-icon 
              :icon="callObjInfo.hold ? 'dialer-playInBox-icon' : 'latestDuringCallDesign-hold-icon'" 
              :class="{
                'makePathBlack':callObjInfo.hold
              }" 
              width="63" 
              height="63"
            />
            <div class="latestDuringCallDesign-iconCloumn-text">{{session.held ? 'Resume' : 'Hold'}}</div>
          </div>
          <!-- transfer -->
          <div 
            class="latestDuringCallDesign-iconCloumn transfer-Grid"
            :class="{
              'disabled': !(callObjInfo.incall && !callObjInfo.is_transfer && !callObjInfo.call_transfered)
            }"
            @click="!(callObjInfo.incall && !callObjInfo.is_transfer && !callObjInfo.call_transfered) ? '' : $modal.show(`${callObjInfo.uuid}TransferCallModal`)" 
          >
            <vb-icon icon="latestDuringCallDesign-transfer-icon" width="63" height="63"/>
            <div class="latestDuringCallDesign-iconCloumn-text">Transfer</div>
          </div>
          <!-- tags -->
          <div 
            class="latestDuringCallDesign-iconCloumn tags-Grid"
            :class="{
              'for-disable': !getUserPermissions.call_tagging_and_notes,
              'disabled': !getUserPermissions.call_tagging_and_notes || !(callObjInfo.incall && !callObjInfo.is_transfer)
            }"
            @click="!getUserPermissions.call_tagging_and_notes || !(callObjInfo.incall && !callObjInfo.is_transfer) ? '' : $modal.show(`${callObjInfo.uuid}TagsModal`, { 
              id: session.extra_data.id, 
              tags: session.tags 
            })"
          >
            <vb-icon  
              id="tagsIcon" 
              v-b-tooltip :title="getUserPermissions.call_tagging_and_notes?'':'Your package does not support this feature'"
              icon="latestDuringCallDesign-tags-icon" 
              width="63" 
              height="63"
            />
            <div class="latestDuringCallDesign-iconCloumn-text">Tags</div>
          </div>
          <!-- mute -->
          <div 
            class="latestDuringCallDesign-iconCloumn mute-Grid"
            :class="{
              'disabled': !(callObjInfo.incall && !callObjInfo.hold)
            }"
            @click="!(callObjInfo.incall && !callObjInfo.hold) ? '' : session.toggelMute()" 
          >
            <vb-icon 
              :icon="callObjInfo.is_mute?'latestDuringCallDesign-mute-icon':'latestDuringCallDesign-unMute-icon'" 
              width="63" 
              height="63"
            />
            <div class="latestDuringCallDesign-iconCloumn-text">{{callObjInfo.is_mute?'Mute':'Unmute'}}</div>
          </div>
          <!-- keypad -->
          <div 
            class="latestDuringCallDesign-iconCloumn keypad-Grid"
            :class="{
              'disabled': !(callObjInfo.incall && !callObjInfo.is_transfer)
            }"
            @click="!(callObjInfo.incall && !callObjInfo.is_transfer) ? '' : $modal.show(`${callObjInfo.uuid}SipCallKeypadModal`)" 
          >
            <vb-icon icon="latestDuringCallDesign-keypad-icon" width="63" height="63"/>
            <div class="latestDuringCallDesign-iconCloumn-text">Keypad</div>
          </div>
          <!-- accept -->
          <div 
            v-if="!callObjInfo.incall && callObjInfo.incoming"
            class=" latestDuringCallDesign-iconCloumn accept-Grid"
            @click="onAccept()" 
          >
            <vb-icon icon="mobile-pickup-icon" width="63" height="63"/>
            <div class="latestDuringCallDesign-iconCloumn-text">Accept</div>
          </div>
          <!-- hangup -->
          <div 
            @click="session.hangup()" 
            class="latestDuringCallDesign-iconCloumn hangup-icon hangup-Grid"
          >
            <vb-icon icon="latestDuringCallDesign-hangUp-icon" width="63" height="63"/>
            <div class="latestDuringCallDesign-iconCloumn-text">Hangup</div>
          </div>
          <!-- recording -->
          <div 
            v-if="callObjInfo.incall && !callObjInfo.is_transfer && isOnDemandCallRecording" 
            @click="session.record()" 
            class="latestDuringCallDesign-iconCloumn d-flex forRecording recording-Grid"
          >
            <div class="forRecording-iconContainer">
              <vb-icon class="makePathBlack" :icon="session.recording?'callRecording-duringCall-icon':'callRecording-duringCall-icon'" width="63" height="63"/>
            </div>
            <div class="latestDuringCallDesign-iconCloumn-text">{{session.recording? 'Stop':'Record'}}</div>
          </div>
        </div>
      </div>

    </div>
    <SipCallKeypadModal :modalName="`${callObjInfo.uuid}SipCallKeypadModal`" :phones="phones" />
    <TransferCallModal :modalName="`${callObjInfo.uuid}TransferCallModal`" :phones="phones" :session="session" />
    <NoteModal :modalName="`${callObjInfo.uuid}NoteModal`" @updated-note="session.note = $event" />
    <TagsModal :modalName="`${callObjInfo.uuid}TagsModal`" @updated-tags="session.tags = $event" />
  </div>
  <!-- recording -->
  <template v-if="!getIsMobile">
    <div v-if="!session.feedback_screen_active && callObjInfo.incall && !callObjInfo.is_transfer && isOnDemandCallRecording" @click="session.record()"  class="recordingDIV-duringCall-container">
      <vb-icon class="makePathBlack" :icon="session.recording?'callRecording-duringCall-icon':'callRecording-duringCall-icon'" width="63" height="63"/>
      <div class="recordingDIV-duringCall-text">{{session.recording? 'Stop Recording':'Record Call'}}</div>
    </div>
    <div v-if="callObjInfo.incall && callObjInfo.is_internal_call" @click="switchCall()" class="recordingDIV-duringCall-container rightSide">
      <vb-icon class="makePathBlack" icon="switchToVideoCall-icon" width="63" height="63"/>
      <div class="recordingDIV-duringCall-text">Switch Video Call</div>
    </div>
    <!-- <div 
      v-if="callObjInfo.incall && callObjInfo.is_internal_call"
      class="latestDuringCallDesign-iconCloumn accept-Grid"
      @click="switchCall()" 
    >
      <vb-icon icon="mobile-pickup-icon" width="63" height="63"/>
      <div class="latestDuringCallDesign-iconCloumn-text">Switch Video Call</div>
    </div> -->
  </template>
</div>
</template>

<script>
import getStats from "getstats";
import SipCallKeypadModal from "../../Modals/SipCallKeypadModal.vue";
import TransferCallModal from "../../Modals/TransferCallModal.vue";
import NoteModal from "../../Modals/NoteModal.vue";
import TagsModal from "../../Modals/TagsModal.vue";
import { 
  LOGGER, 
  events, 
  // VOIP_API 
} from "../../../utils";
import _ from "lodash";
import { electron_events } from '../../../electron/events';
import { mapGetters } from 'vuex';
import { call_info } from '@/mixin';
export default {
  name: "SipSession",
  mixins: [
    call_info
  ],
  props: ["session","phones"],
  components: {
    SipCallKeypadModal,
    TransferCallModal,
    NoteModal,
    TagsModal,
  },
  data(){
    return {
      info: {
        localIPs: "",
        remoteIPs: "",
        bandwidth: "",
        audioLatency: "",
        packetsLost: "",
        sendCodes: "",
        recCodes: "",
        byteSend: "",
        byteRec: "",
      },
      form: {
        search: "",
        dtmf: "",
        note: "",
        tag: "",
        tags: [],
      },
      api_sent: {
        note: false,
        tag: false,
      },
      api_status: {
        note: "",
        tag: "",
      },
      conditions: {
        show_transfer: false,
        show_status: false,
        mute: true,
        show_dialer: false,
        transfer_call: false,
        note: false,
        tag: false,
      },
    }
  },
  filters: {
    incrementFilter(value) {
      if (!value) return '';
      else if (value.substring(0, 2) == "00") return value.substring(3);
      else return value;
    },
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getIsMobile',
      'isOnDemandCallRecording',
    ]),
    userDp(){ return require("@/assets/images/user-male.png") },
    callObj(){ return this.session },
  },
  methods: {
    switchCall(){
      this.$root.$emit(events.video_call,{
        accounts: [this.callObjInfo.accountcode],
        is_switch_call: true,
        sip_id: this.session?.extra_data?.bridgeuniqueid,
      })
    },
    checkIn(type) {
      // console.log(type);
      if (type == "addressbook") {
        if (this.name[4]) {
          return "yes";
        }
        return "no";
      } else if (type == "speed") {
        if (this.name[3]) {
          return "yes";
        }
        return "no";
      } else if (type == "ringroup") {
        if (this.name[0] == "RINGGROUP") {
          return "yes";
        }
        return "no";
      }
    },
    getWebphoneStats() {
      let app = this;
      getStats(
        this.phones.activeSession.sessionDescriptionHandler.peerConnection,
        function (result) {
          app.info.localIPs = result.connectionType.local.ipAddress.join(", ");
          app.info.remoteIPs =
            result.connectionType.remote.ipAddress.join(", ");
          app.info.bandwidth = app.bytesToSize(result.bandwidth.speed);
          app.info.audioLatency = result.audio.latency;
          app.info.packetsLost = result.audio.packetsLost;
          app.info.sendCodes = result.audio.send.codecs.join(", ");
          app.info.recCodes = result.audio.recv.codecs.join(", ");
          app.info.byteSend = app.bytesToSize(result.audio.bytesSent);
          app.info.byteRec = app.bytesToSize(result.audio.bytesReceived);

          // console.log("localIP", app.localIPs);
          // console.log("remoteIPs", app.remoteIPs);
          // console.log("bandwidth", app.bandwidth);
          // console.log("audioLatency", app.audioLatency);
          // console.log("packetsLost", app.packetsLost);
          // console.log("sendCodes", app.sendCodes);
          // console.log("recCodes", app.recCodes);
          // console.log("byteSend", app.byteSend);
          // console.log("byteRec", app.byteRec);
        },
        2000
      );
    },
    onAccept() {
      this.session.accepts();
      // this.$root.$emit(events.hold_video_call)
      this.$store.state.calls.active_call=this.session.uuid
      this.$store.state.global_conditions.tab='video-call'
      // this.session.accepts();
      // this.$root.$emit(events.hold_video_call)
    },
    test() {
      for (var x = 0; x < this.$store.state.calls.videoCalls.length; x++) {
        this.$store.state.calls.videoCalls[x].isPlay = false;
      }
      // console.log("yes im on right function");
      // this.$root.$refs.dialpad.getIsUpgradeable(session.number);
      if (window.api) {
        window.api.executeCommand("toggleAudio");
        window.api.executeCommand("toggleVideo");
      }
    },
    onReject() {
      this.session.hangup();
    },
    onHangup() {
      this.session.hangup();
    },
    endTimer() {
      const uid = _.get(this.session, "extra_data.uid");
      if (!uid) return;
      this.$set(this.$store.state.call_activity_timer, uid, {
        ...this.$store.state.call_activity_timer[uid],
        end_time: new Date(),
      });
    },
    muteActiveSession() {
      let app = this;
      this.phones.activeSession.toggelMute((mute) => { app.conditions.mute = mute; });
    },
    reCallActivity() {
      let vm = this
      vm.$root.$emit(events.fetch_call_activity_interval,true)
    },
    onMissedCall(event){
      // console.log('onMissedCall')
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.INCOMING_CALL_HIDE,{id:event.session.uuid})
      }
    },
    onRejectCall(event){
      // console.log('onRejectCall')
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.INCOMING_CALL_HIDE,{id:event.session.uuid})
      }
    },
    onAcceptedCall(event){
      // console.log('onAcceptedCall')
      if(process.env.IS_ELECTRON){
        window.electron.send(electron_events.INCOMING_CALL_HIDE,{id:event.session.uuid})
      }
    },
    onSipCallRejectedByModal(message){
      LOGGER.log('onSipCallRejectedByModal',message)
      const { id } = message || {}
      if(this.session.uuid==id){
        this.onReject()
      }
    },
    onSipCallAcceptedByModal(message){
      LOGGER.log('onSipCallAcceptedByModal',message)
      const { id } = message || {}
      if(this.session.uuid==id){
        this.onAccept()
      }
    },
    holdActiveSession() {
      // this.$root.$emit(events.hold_audio_call);
    },
    onSwitch(){
      this.phones.markSessionActive(this.session.uuid)
      // this.$root.$emit(events.hold_video_call)
    },
  },
  created(){
    this.$root.$emit(events.fetch_call_recording_setting)
    // this.session.on(this.session.events.call_missed,this.onMissedCall)
    // this.session.on(this.session.events.call_rejected,this.onRejectCall)
    // this.session.on(this.session.events.call_accepted,this.onAcceptedCall)
    // if(process.env.IS_ELECTRON){
    //   window.electron.receive(electron_events.recieve.CALL_REJECTED,this.onSipCallRejectedByModal)
    //   window.electron.receive(electron_events.recieve.CALL_ACCEPTED,this.onSipCallAcceptedByModal)
    // }
  },
  beforeDestroy() {
    this.endTimer();
    this.reCallActivity();
    // this.session.off(this.session.events.call_missed,this.onMissedCall)
    // this.session.off(this.session.events.call_rejected,this.onRejectCall)
    // this.session.off(this.session.events.call_accepted,this.onAcceptedCall)
  },
};
</script>

<style>
</style>