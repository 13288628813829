var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isEmpty(_vm.data) ? _c('div', {
    staticClass: "msg-media-inner rounded-2 overflow-hidden",
    class: [_vm.data.file_type === 'video' ? 'msg-video' : ' ', _vm.data.file_type === 'image' && !_vm.data.is_card ? 'msg-image' : ' ', _vm.index > 3 ? 'morethan4' : '']
  }, [!_vm.data.is_card ? [_vm.data.file_type === 'image' ? _c('div', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.random_id}-MediaFilesModal`, {
          channel_id: _vm.channel_id,
          file_id: _vm.file.id
        });
      }
    }
  }, [_c('div', {
    staticClass: "dialer-image-container"
  }, [_c('img', {
    staticStyle: {
      "max-width": "100%"
    },
    attrs: {
      "src": _vm.data.src,
      "alt": _vm.data.alt
    },
    on: {
      "error": _vm.setData
    }
  })])]) : _vm.data.file_type === 'audio' ? _c('div', {
    staticClass: "dialer-audio-container"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm.data.src
    }
  })], 1) : _vm.data.file_type === 'video' ? _c('div', {
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.random_id}-MediaFilesModal`, {
          channel_id: _vm.channel_id,
          file_id: _vm.file.id
        });
      }
    }
  }, [_c('VueVideoThumbnail', {
    attrs: {
      "video-src": _vm.data.src,
      "snapshot-at-duration-percent": parseInt(0, 10),
      "width": 500,
      "height": 300,
      "chunks-quantity": 4
    }
  })], 1) : _vm._e()] : _c('div', {
    staticClass: "main-card"
  }, [_c('div', {
    staticClass: "file-type-icon",
    on: {
      "click": function ($event) {
        return _vm.publicLink(_vm.data);
      }
    }
  }, [_c('b-icon', {
    staticClass: "file-icon",
    attrs: {
      "icon": _vm.data.print_file_icon,
      "width": "40px",
      "height": "40px"
    }
  })], 1), _c('div', {
    staticClass: "file-info-text",
    on: {
      "click": function ($event) {
        return _vm.publicLink(_vm.data);
      }
    }
  }, [_c('h6', {
    staticClass: "font-weight-bold mb-1 text-left"
  }, [_vm._v(_vm._s(_vm.data.print_name))]), _c('p', [_vm._v(_vm._s(_vm.data.print_size))])])])], 2) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }