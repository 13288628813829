import Axios from "axios";
import { TokenService } from "@/services/storage.service";
import { MATTERMOST_API_LOGGER, URLS } from "../utils/index";
import _ from 'lodash';
import store from '../store';

const timeout = 1 * 60 * 1000
export const MATTERMOST_API = {
  MatterMostAxios: Axios.create({
    name: 'mattermost',
    headers: {
      "X-Requested-With": "XMLHttpRequest",
    },
    timeout: timeout,
  }),
  LinkPreviewAxios: Axios.create({
    name: 'linkpreview',
    baseURL: `http://api.linkpreview.net/`,
    timeout: timeout,
  }),
  init(){
    try {
      this.MatterMostAxios.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      this.LinkPreviewAxios.interceptors.response.use(this.onSuccess.bind(this),this.onError.bind(this))
      
      this.MatterMostAxios.interceptors.request.use(this.onRequest.bind(this))
      this.LinkPreviewAxios.interceptors.request.use(this.onRequest.bind(this))
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  setTimeOut(time){
    this.MatterMostAxios.defaults.timeout = time
    this.LinkPreviewAxios.defaults.timeout = time
  },
  onRequest(config){
    MATTERMOST_API_LOGGER.info(config.url,config)
    if(['mattermost'].includes(config.name)){
      config.baseURL=URLS?.chatApi ?? ''
      config.headers.Authorization =  `Bearer ${TokenService.MATTERMOST_TOKEN.get()}`;
    }
    if(['linkpreview'].includes(config.name) && config.method.toLowerCase()=='get'){
      _.set(config,'params.key',process.env.VUE_APP_LINK_PREVIEW_KEY)
    }
    return config;
  },
  async onError(ex){
    MATTERMOST_API_LOGGER.danger(ex?.config?.url,{ex})
    const api_url = ex?.config?.url
    let response_error = ex?.response?.data ?? {}
    const response_message = response_error?.message ?? ''
    const status_code = ex?.response?.status ?? ''
    const default_message = 'Action not perform successfully'
    let message = ''
    if(ex.message == "Network Error"){
      if(!window?.navigator?.onLine){
        message = `Check your Internet connection`
      } else {
        message = `Check your Internet connection`
      }
    } else if(ex.message.includes('timeout')){
      message = `It might be because of network or server error, Please contact our support team`
    } else if(status_code==500){
      message = default_message
    } else {
      message = response_message || ex.message || default_message
    }
    if(['mattermost'].includes(ex?.config?.name) && api_url!='users/login' && status_code===401){
      const user = TokenService.USER.get()
      const { data: mattermost_user, headers: { token } } = await MATTERMOST_API.endpoints.users.login({
        login_id: user?.jid_account,
        password: user?.jid_pass,
      })
      TokenService.MATTERMOST_USER.set(mattermost_user);
      TokenService.MATTERMOST_TOKEN.set(token);
      store.commit("setToken", token);
      store.commit("setMatterMostUser", mattermost_user);
      return MATTERMOST_API.MatterMostAxios(ex?.config)
    }
    const response = {
      own_message: message ?? '',
      response_error: response_error,
      ...ex,
      message: ex.message ?? '',
      stack: ex.stack ?? '',
    }
    throw response
  },
  onSuccess(response){
    MATTERMOST_API_LOGGER.success(response.config.url,response)
    return response
  },
  endpoints: {
    users: {
      getUserByUsername(username) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/username/${username}`,
        });
      },
      getUsersKnown() {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/known`,
        });
      },
      getUser(id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${id}`,
        });
      },
      getUsers(data) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users`,
          data,
        });
      },
      searchUser(term) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `search`,
          data: {
            term,
          }
        });
      },
      createUser(data) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `users`,
          data,
        });
      },
      getUserByEmail(email) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/email/${email}`,
        });
      },
      getUserSessions(id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${id}/sessions`,
        });
      },
      getUsersByIds(ids) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `users/ids`,
          data: ids,
        });
      },
      updateUserRoles(id,roles) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "PUT",
          url: `users/${id}/roles`,
          data: {
            roles: roles
          },
        });
      },
      login(data) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: "users/login",
          data,
        });
      },
      autoLoginCWSToken(data) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: "users/login/cws",
          data,
        });
      },
      patchUser(user_id,data){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "PUT",
          url: `users/${user_id}/patch`,
          data,
        });
      },
      logout() {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: "users/logout",
        });
      },
    },
    preferences: {
      getUsersPreferences(user_id){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${user_id}/preferences`,
        });
      },
      saveUsersPreferences(user_id,data){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "PUT",
          url: `users/${user_id}/preferences`,
          data,
        });
      },
      deleteUsersPreferences(user_id,data){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `users/${user_id}/preferences/delete`,
          data,
        });
      },
      listUsersPreferences(user_id,category){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${user_id}/preferences/${category}`,
        });
      },
      getSpecificUserPreference(user_id,category,preference_name){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${user_id}/preferences/${category}/name/${preference_name}`,
        });
      },
    },
    channels: {
      createGroupChannel(ids) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `channels/group`,
          data: ids,
        });
      },
      getChannelMembers(channel_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `channels/${channel_id}/members`,
        });
      },
      getUnreadCount(user_id, channel_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${user_id}/channels/${channel_id}/unread`,
        });
      },
      addChannelMember(channel_id, data) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `channels/${channel_id}/members`,
          data,
        });
      },
      removeChannelMember(channel_id, user_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "DELETE",
          url: `channels/${channel_id}/members/${user_id}`,
        });
      },
      createDirectChannel(ids) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `channels/direct`,
          data: ids,
        });
      },
      getChannel(channel_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `channels/${channel_id}`,
        });
      },
      getChannelByName(team_id, name) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `teams/${team_id}/channels/name/${name}`,
        });
      },
      deleteChannel(channel_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "DELETE",
          url: `channels/${channel_id}`,
        });
      },
      create(data) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `channels`,
          data,
        });
      },
      getForUser(user_id, team_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${user_id}/teams/${team_id}/channels`,
        });
      },
      viewChannel(user_id, channel_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `channels/members/${user_id}/view`,
          data: {
            channel_id,
          },
        });
      },
      getPrivateChannels(team_id){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `teams/${team_id}/channels/private`,
        });
      },
      patchChannel(channel_id,data){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "PUT",
          url: `channels/${channel_id}/patch`,
          data,
        });
      },
      getChannelPinnedPosts(channel_id){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `channels/${channel_id}/pinned`,
        });
      },
      getUserSidebarCategory(user_id,team_id,category_id){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${user_id}/teams/${team_id}/channels/categories/${category_id}`,
        });
      },
      createUserSidebarCategory(user_id,team_id,data){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `users/${user_id}/teams/${team_id}/channels/categories`,
          data,
        });
      },
      updateUserSidebarCategory(user_id,team_id,category_id,data){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "PUT",
          url: `users/${user_id}/teams/${team_id}/channels/categories/${category_id}`,
          data,
        });
      },
      deleteUserSidebarCategory(user_id,team_id,category_id){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "DELETE",
          url: `users/${user_id}/teams/${team_id}/channels/categories/${category_id}`,
        });
      },
      getUserSidebarCategories(user_id,team_id){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${user_id}/teams/${team_id}/channels/categories`,
        });
      },
      updateUserSidebarCategories(user_id,team_id,data){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "PUT",
          url: `users/${user_id}/teams/${team_id}/channels/categories`,
          data,
        });
      },
      getUserSidebarCategoryOrder(user_id,team_id){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${user_id}/teams/${team_id}/channels/categories/order`,
        });
      },
      updateUserSidebarCategoryOrder(user_id,team_id,data){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "PUT",
          url: `users/${user_id}/teams/${team_id}/channels/categories/order`,
          data,
        });
      },
      updateMemberRole(channel_id,user_id,data){
        return MATTERMOST_API.MatterMostAxios.request({
          method: 'PUT',
          url: `channels/${channel_id}/members/${user_id}/schemeRoles`,
          data,
        })
      },
    },
    teams: {
      create(data) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `teams`,
          data: data,
        });
      },
      add(team_id, users) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `teams/${team_id}/members/batch`,
          data: users,
        });
      },
      get() {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `teams`,
        });
      },
      getByUserId(user_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${user_id}/teams`,
        });
      },
      getMembers(team_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `teams/${team_id}/members`,
        });
      },
    },
    files: {
      upload(formData,params,onUploadProgress,timeout=timeout,cancelToken) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `files`,
          headers: {
            "Content-Type": "multipart/form-data",
          },
          timeout,
          params,
          data: formData,
          onUploadProgress,
          cancelToken,
        });
      },
      get(file_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `files/${file_id}`,
        });
      },
      getThumbnail(file_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `files/${file_id}/thumbnail`,
        });
      },
      getPreview(file_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `files/${file_id}/preview`,
        });
      },
      getPublicLink(file_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `files/${file_id}/link`,
        });
      },
      getMetaData(file_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `files/${file_id}/info`,
        });
      },
      getPublic(file_id, h) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `files/${file_id}/public`,
          params: {
            h,
          },
        });
      },
    },
    uploads: {
      create(channel_id, filename, fille_size) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `uploads`,
          data: {
            channel_id,
            filename,
            fille_size,
          },
        });
      },
      getSession(upload_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `uploads/${upload_id}`,
        });
      },
      performUpload(upload_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `uploads/${upload_id}`,
        });
      },
    },
    emoji: {
      create(image, name, creator_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `emoji`,
          data: {
            image,
            emoji: {
              name: name,
              creator_id: creator_id,
            },
          },
        });
      },
      getList() {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `emoji`,
        });
      },
      getCustome(emoji_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `emoji/${emoji_id}`,
        });
      },
      deleteCustome(emoji_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "DELETE",
          url: `emoji/${emoji_id}`,
        });
      },
    },
    posts: {
      pin(post_id){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `posts/${post_id}/pin`,
        });
      },
      unpin(post_id){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `posts/${post_id}/unpin`,
        });
      },
      createPosts(data) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `posts`,
          data,
        });
      },
      getPosts(channel_id,params={}) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `channels/${channel_id}/posts`,
          params,
        });
      },
      getPost(post_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `posts/${post_id}`,
        });
      },
      deletePost(post_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "DELETE",
          url: `posts/${post_id}`,
        });
      },
      patchPost(post_id, data) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "PUT",
          url: `posts/${post_id}/patch`,
          data,
        });
      },
      updatePost(post_id, data) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "PUT",
          url: `posts/${post_id}`,
          data,
        });
      },
      markUnread(user_id,post_id){
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `users/${user_id}/posts/${post_id}/set_unread`,
        });
      },
    },
    reactions: {
      createReaction(user_id, post_id, emoji_name, create_at = 0) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `reactions`,
          data: {
            user_id,
            post_id,
            emoji_name,
            create_at,
          },
        });
      },
      getReactionList(post_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `posts/${post_id}/reactions`,
        });
      },
      deleteReaction(user_id, post_id, emoji_name) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "DELETE",
          url: `users/${user_id}/posts/${post_id}/reactions/${emoji_name}`,
        });
      },
      getBulkReaction(ids) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `posts/ids/reactions`,
          data: ids,
        });
      },
    },
    status: {
      getUserStatus(user_id) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "GET",
          url: `users/${user_id}/status`,
        });
      },
      updateUserStatus(user_id, status) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "PUT",
          url: `users/${user_id}/status`,
          data: {
            user_id,
            status,
          },
        });
      },
      getUserStatusesById(ids) {
        return MATTERMOST_API.MatterMostAxios.request({
          method: "POST",
          url: `users/status/ids`,
          data: ids,
        });
      },
    },
    //link preview
    linkpreview: {
      get(params={}){
        return MATTERMOST_API.LinkPreviewAxios.request({
          method: 'GET',
          params,
        })
      },
    },
  }
}
