var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-record-sound-modal2"
  }, [_c('modal', {
    staticClass: "dialer_animation center-zoomIn-popup v2 m-w-400 IosModal",
    attrs: {
      "height": "auto",
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [[_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_start || _vm.api.upload.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_start || _vm.api.upload.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Voice Recorder")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })])], _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "d-flex justify-content-center w-100 mt-64px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "voiceRecorderMic-IOSIcon",
      "width": "148px",
      "height": "148px"
    }
  })], 1), _vm.recorded.audio.is_start ? _c('div', {
    staticClass: "recorderTimeHeading mt-32px"
  }, [_vm._v(" " + _vm._s(_vm._f("time_audio")(_vm._f("get_property")(_vm.recorded, 'audio.time', 0), true)) + " ")]) : _vm._e(), _c('div', {
    class: `bill-waves mt-32px ${_vm.recorded.audio.is_start ? '' : 'originalState'}`,
    attrs: {
      "id": "test-wave-889"
    }
  }, _vm._l(_vm.waves, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "bill-wave-item",
      attrs: {
        "id": `test-wave-889-${index}`,
        "data-value": data
      }
    });
  }), 0), _c('div', {
    staticClass: "headingInsideTable text-center mt-32px"
  }, [_vm._v(_vm._s(!_vm.recorded.audio.is_start ? 'Record Your Voice' : 'Recording...'))]), _c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100 mt-32px childWidth60px py-4px"
  }, [_c('vb-audio-player', {
    class: `IosAudioPlayer biggerIcon ${_vm.recorded.audio.is_start ? 'disabled' : ''}`,
    attrs: {
      "disabled": _vm.recorded.audio.is_start,
      "src": _vm._f("get_property")(_vm.recorded, 'audio.audio.audioUrl')
    }
  }), _c('div', {
    staticClass: "IosRecordIcon-container"
  }, [!_vm.recorded.audio.is_start ? _c('b-icon', {
    staticClass: "circle-fill",
    class: {
      'disabled': _vm.api.upload.send
    },
    attrs: {
      "icon": "circle-fill"
    },
    on: {
      "click": function ($event) {
        _vm.api.upload.send ? '' : _vm.recorded.audio.is_start = true;
      }
    }
  }) : _c('b-icon', {
    staticClass: "pause-fill",
    attrs: {
      "icon": "pause-fill"
    },
    on: {
      "click": function ($event) {
        _vm.recorded.audio.is_start = false;
      }
    }
  })], 1), _c('div', [!_vm.recorded.audio.is_start && _vm.recorded.audio.audio.audioUrl ? [!_vm.api.upload.send ? _c('div', {
    staticClass: "saveText",
    on: {
      "click": function ($event) {
        return _vm.onSave();
      }
    }
  }, [_vm._v("Save")]) : _c('span', [_c('b-icon', {
    staticClass: "arrow-clockwise",
    attrs: {
      "icon": "arrow-clockwise",
      "animation": "spin-pulse"
    }
  }), _vm.api.upload.upload_progress.is_process ? _c('p', [_vm._v(_vm._s(`${_vm.api.upload.upload_progress.percentage}%`))]) : _vm._e()], 1)] : _vm._e()], 2)], 1)])], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }