<template>
  <div>
    <div class="mainHeading withButton">
      Tasks
    </div>
    <div class="mainDescription mb-3">
      <br/>
    </div>
    <div class="hrmTabsContainer">
      <div :class="`hrmTabsButton ${showTabs == 'Active' ? 'active' : ''} mr-16px`" @click="showTabs = 'Active'">Active</div>
      <div :class="`hrmTabsButton ${showTabs == 'Upcoming' ? 'active' : ''} mr-16px`" @click="showTabs = 'Upcoming'">Upcoming</div>
      <div :class="`hrmTabsButton ${showTabs == 'Completed' ? 'active' : ''} mr-16px`" @click="showTabs = 'Completed'">Completed</div>
    </div>
    <div class="mt-32px">
      <template v-if="showTabs == 'Active'">
        <div class="dropdownsContainer">
          <b-dropdown no-caret class="primaryBorderDropdown">
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">All teams</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All teams
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown no-caret class="primaryBorderDropdown">
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">All locations</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All locations
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="EmployeeDataTasksTable mt-32px">
          <div class="HrmTableHead-container">
            <div class="HrmTableHead one">task</div>
            <div class="HrmTableHead two">DUE DATE</div>
            <div class="HrmTableHead three">Assigned to</div>
            <div class="HrmTableHead four">EMPLOYEE</div>
            <div class="HrmTableHead five">Actions</div>
          </div>
          <vb-table class="HrmTable">
            <template #body>
              <tr class="dialer-row-select">
                <td class="dialer-row-title one">
                  <div class="td-text">this is test task description lorem ipsum</div>
                </td>
                <td class="dialer-row-title two">
                  13/09/2022
                </td>
                <td class="dialer-row-title three">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title four">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item>
                      Actions
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
              <tr class="dialer-row-select">
                <td class="dialer-row-title one">
                  <div class="td-text">this is test task description lorem ipsum</div>
                </td>
                <td class="dialer-row-title two">
                  13/09/2022
                </td>
                <td class="dialer-row-title three">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title four">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item>
                      Actions
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
      </template>
      <template v-if="showTabs == 'Upcoming'">
        <div class="dropdownsContainer">
          <b-dropdown no-caret class="primaryBorderDropdown">
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">All teams</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All teams
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown no-caret class="primaryBorderDropdown">
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">All locations</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All locations
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="EmployeeDataTasksTable mt-32px">
          <div class="HrmTableHead-container">
            <div class="HrmTableHead one">task</div>
            <div class="HrmTableHead two">DUE DATE</div>
            <div class="HrmTableHead three">Assigned to</div>
            <div class="HrmTableHead four">EMPLOYEE</div>
            <div class="HrmTableHead five">Actions</div>
          </div>
          <vb-table class="HrmTable">
            <template #body>
              <tr class="dialer-row-select">
                <td class="dialer-row-title one">
                  <div class="td-text">this is test task description lorem ipsum</div>
                </td>
                <td class="dialer-row-title two">
                  13/09/2022
                </td>
                <td class="dialer-row-title three">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title four">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item>
                      Actions
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
              <tr class="dialer-row-select">
                <td class="dialer-row-title one">
                  <div class="td-text">this is test task description lorem ipsum</div>
                </td>
                <td class="dialer-row-title two">
                  13/09/2022
                </td>
                <td class="dialer-row-title three">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title four">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item>
                      Actions
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
      </template>
      <template v-if="showTabs == 'Completed'">
        <div class="dropdownsContainer">
          <b-dropdown no-caret class="primaryBorderDropdown">
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">All teams</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All teams
            </b-dropdown-item>
          </b-dropdown>
          <b-dropdown no-caret class="primaryBorderDropdown">
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">All locations</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All locations
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="EmployeeDataTasksTable mt-32px">
          <div class="HrmTableHead-container">
            <div class="HrmTableHead one">task</div>
            <div class="HrmTableHead two">DUE DATE</div>
            <div class="HrmTableHead three">Assigned to</div>
            <div class="HrmTableHead four">EMPLOYEE</div>
            <div class="HrmTableHead five">Actions</div>
          </div>
          <vb-table class="HrmTable">
            <template #body>
              <tr class="dialer-row-select">
                <td class="dialer-row-title one">
                  <div class="td-text">this is test task description lorem ipsum</div>
                </td>
                <td class="dialer-row-title two">
                  13/09/2022
                </td>
                <td class="dialer-row-title three">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title four">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item>
                      Actions
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
              <tr class="dialer-row-select">
                <td class="dialer-row-title one">
                  <div class="td-text">this is test task description lorem ipsum</div>
                </td>
                <td class="dialer-row-title two">
                  13/09/2022
                </td>
                <td class="dialer-row-title three">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title four">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">Jackson Will</div>
                  </div>
                </td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item>
                      Actions
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
export default {
  name: 'OnboardingTasks',
  components: {},
  data(){
    return {
      showTabs: 'Active'
    }
  },
}
</script>

<style>

</style>