var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "sec-common"
  }, [_vm._m(0), _vm._m(1), _c('div', [_c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Send a reminder email o approvers who have unapproved time off requests")])]), _c('div', {
    staticClass: "mar-left-30hrm"
  }, [_c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Monday")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Tuesday")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Wednesday")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Thursday")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Friday")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Saturday")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Sunday")])]), _c('div', {
    staticClass: "d-flex justify-content-start align-items-center mar-top-hrm25"
  }, [_c('p', {
    staticClass: "smallFont mb-imp"
  }, [_vm._v("at")]), _c('div', {
    staticClass: "dd-gm-container mar-left-hrm20"
  }, [_c('b-dropdown', {
    staticClass: "gm-mainBorderfordropi width-one",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "gm-dropi-contenti"
        }, [_vm._v("08:00")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("A")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("B")])], 1)], 1)]), _c('div', {
    staticClass: "mar-top-hrm30"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Don’t send reminders while the approver is away on following policies")]), _c('div', {
    staticClass: "dd-gm-container"
  }, [_c('b-dropdown', {
    staticClass: "gm-mainBorderfordropi width-two",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "gm-dropi-contenti"
        }, [_vm._v("Indivisual approvers")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("A")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("B")])], 1)], 1)])], 1)], 1)])])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', [_c('h5', {
    staticClass: "emp-profile-heading"
  }, [_vm._v("Reminders")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('p', {
    staticClass: "notes-top-text"
  }, [_vm._v(" Set up custom email notifications to employees who have time off requests pending on them ")])]);

}]

export { render, staticRenderFns }