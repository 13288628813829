var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-memberList-area"
  }, [_c('div', {
    staticClass: "swiper-container"
  }, [_c('div', {
    staticClass: "swiper-wrapper"
  }, _vm._l(_vm.members, function (member) {
    return _c('div', {
      key: member.voipaccount,
      staticClass: "swiper-slide"
    }, [_c('div', {
      staticClass: "item"
    }, [_c('vb-avatar', {
      attrs: {
        "id": `avatar-video-call-${member.account}-${_vm.random_id}`,
        "name": `${member.user.profile.first_name} ${member.user.profile.last_name}`,
        "status_id": member.status.id,
        "image": member.profile_img,
        "size": '60px'
      }
    }), _c('b-tooltip', {
      attrs: {
        "target": `avatar-video-call-${member.account}-${_vm.random_id}`
      }
    }, [_vm._v(_vm._s(member.user.display_name))])], 1)]);
  }), 0), _c('div', {
    staticClass: "swiper-button swiper-button-next"
  }), _c('div', {
    staticClass: "swiper-button swiper-button-prev"
  })])]);

}
var staticRenderFns = []

export { render, staticRenderFns }