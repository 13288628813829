<template>
  <modal
    class="
      dialer_animation
      center-zoomIn-popup
      m-w-600
      dialer-addMemberModal dialer-addMemberModalDesign2 SelectAddressBookNumberModal
    "
    name="SelectAddressBookNumberModal"
    @before-open="beforeOpen"
    @closed="onClose"
  >
    <div class="position-relative">
      <div class="sticky-top">
        <div class="dialer-modal-header">
          <h4 class="mb-0">User numbers</h4>
          <button type="button" class="newCloseButton" @click="$modal.hide('SelectAddressBookNumberModal')">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
          <!-- <span
            class="close-popup cursor_pointer"
            @click="$modal.hide('SelectAddressBookNumberModal')"
          >
            <b-icon icon="x" scale="1.8"></b-icon>
          </span> -->
          
        </div>
        <div class="searchBox dialer-input-field input-consistent-inner-shadows seachBox">
          <b-form class="position-relative" @submit.prevent="''">
            <b-form-input class="w-100" v-model="search"></b-form-input>
            <b-icon
              icon="search"
              scale="1"
              class="position-absolute cursor_pointer"
            />
            <b-icon
              icon="x-circle"
              scale="1"
              class="position-absolute cursor_pointer"
            />
          </b-form>
        </div>
      </div>
      <ul class="items list-unstyled">
        <li
          v-for="number in searchedNumber"
          :key="number.number"
          class="item cursor_pointer"
          @click="dail(number)"
        >
          <p>
            <b> number: </b> <span> {{ number.number | number_formater }}</span>
          </p>
          <div class="d-flex align-items-center justify-content-between w-100">
            <p>
              <b> short code:</b> <span> {{ number.dialShortCode }} </span>
            </p>
            <p>
              <b> type: </b> <span> {{ number.type }} </span>
            </p>
          </div>
        </li>
        <vb-no-record
          v-if="isEmpty(searchedNumber)"
          text="There in no number"
          :design="3"
        />
      </ul>
    </div>
  </modal>
</template>

<script>
import { events } from "../../utils";
export default {
  name: "SelectAddressBookNumberModal",
  data() {
    return {
      search: "",
      user_male: require("@/assets/images/user-male.png"),
      addressbook: null,
    };
  },
  inject:['isEmpty'],
  computed: {
    searchedNumber() {
      const search = this.search.toLowerCase().trim();
      if (!this.addressbook) return [];
      return this.addressbook.phones.filter((data) =>
        `${data.number} ${data.shortCode} ${data.type}`
          .toLowerCase()
          .trim()
          .includes(search)
      );
    },
  },
  methods: {
    beforeOpen(event) {
      this.addressbook = event.params.addressbook;
    },
    dail(number) {
      this.$root.$emit(events.audio_call, {
        id: number.uid,
        type: 'addressbook',
        number: number.number,
      });
      this.$modal.hide("SelectAddressBookNumberModal");
    },
    onClose() {
      this.addressbook = null;
    },
  },
};
</script>

<style lang="scss">
.dialer-chatBox-inVideo {
  .dialer-videoCall-addMember {
    padding: 0px 0px 20px;
    h5 {
      font-size: 1rem;
      font-weight: 600;
      padding: 0px 20px;
    }
    .items {
      padding: 0px;
      margin: 0px;
      .item {
        list-style: none;
        display: flex;
        justify-content: space-between;
        align-items: center;
        .info {
          display: flex;
          max-width: calc(100% - 30px);
          align-items: center;
          img {
            height: inherit;
            width: 40px;
            border-radius: 50%;
          }
          div {
            display: flex;
            flex-direction: column;
            justify-content: space-evenly;
            align-items: flex-start;
            height: 100%;
            h5 {
              margin: 0;
              color: $black;
            }
            p {
              margin: 0;
            }
          }
        }
        .actions {
          display: flex;
          justify-content: center;
          align-items: center;
          p {
            margin: 0px;
            position: relative;
            top: -15px;
            font-size: 13px;
          }
          div {
            cursor: pointer;
            border-radius: 50%;
            color: white;
            height: 30px;
            width: 30px;
            display: flex;
            justify-content: center;
            align-items: center;
          }
          div.success {
            background-color: var(--success);
          }
          div.danger {
            background-color: var(--danger);
          }
          .action {
            color: $purple;
          }
          .dialer-button {
            &:not(.dialer-button-blank) {
              display: flex;
              align-items: center;
              background-color: $white;
              border: 2px solid $primary;
              color: $primary;
              font-weight: 700;
              &.message-sent {
                svg {
                  margin-right: 10px;
                  fill: $white;
                }
              }
              &:hover,
              &.message-sent {
                background: $primary;
                border-color: $primary;
                color: $white;
              }
            }
          }
        }
        &:hover {
          background-color: #eee;
        }
      }
    }
  }
  .dialer-addAble-users-array {
    .dialer_contact_row {
      width: 100%;
      display: flex;
      justify-content: flex-start;
      align-items: center;
      overflow-x: auto;
      margin: 0px 10px;
      max-width: calc(100% - 20px);
      .dialer_contact {
        padding: 7px 5px;
        width: auto;
        display: flex;
        align-items: center;
        cursor: pointer;
        .dialer_contact_img {
          position: relative;
          margin-right: 10px;
          cursor: auto;
          img {
            width: 41px;
            height: 41px;
            @include border-radius(50%);
            object-fit: cover;
          }
        }
      }
      &:hover {
        background: transparent;
      }
    }
    .close-popup {
      top: -2px;
      right: -2px;
    }
  }
  .dialer-button {
    &:not(.dialer-button-blank) {
      height: 35px;
      line-height: 35px;
      @include border-radius(50px);
      background: $purple;
    }
  }
  .sticky-top {
    background: $white;
  }
}

// slide top to bottom &bottom to top animation
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -webkit-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  -o-transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
  transition-timing-function: cubic-bezier(0, 1, 0.5, 1);
}

.slide-enter-to,
.slide-leave {
  max-height: 100px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
