var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Custom working days "), _c('div', {
    staticClass: "d-flex"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('hrmAddNewCustomWorkingDayModal');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "plus",
      "font-scale": "1.3",
      "scale": "1.5"
    }
  }), _vm._v(" Add working day ")], 1)])]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Have a weekend as a working day in your country? You can mark specific day in the weekend as a \"working day\" instead. Any holidays taken during these days will be counted against the remaining balance. ")]), _c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_vm._v(" Work to be done ")]), _c('td', {
            staticClass: "dialer-col-right two"
          }, [_vm._v(" 26-12-2023 ")]), _c('td', {
            staticClass: "dialer-col-right three"
          }, [_c('b-dropdown', {
            attrs: {
              "size": "lg",
              "variant": "link",
              "toggle-class": "text-decoration-none tb-notes-dd",
              "no-caret": "",
              "menu-class": "notes-table-dd"
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', {
            attrs: {
              "href": "#"
            }
          }, [_vm._v("Action")])], 1)], 1)]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead one pt-1"
  }, [_vm._v("Name")]), _c('th', {
    staticClass: "HrmTableHead two pt-1"
  }, [_vm._v("date")]), _c('th', {
    staticClass: "HrmTableHead three pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1), _c('modal', {
    staticClass: "dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll",
    attrs: {
      "name": "hrmAddNewCustomWorkingDayModal"
    }
  }, [_c('div', [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Custom working days")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('hrmAddNewCustomWorkingDayModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container"
  }, [_c('label', [_vm._v("Name")]), _c('input')]), _c('div', {
    staticClass: "innerShadowInput-container mt-25px"
  }, [_c('label', [_vm._v("Date")]), _c('div', {
    staticClass: "inputWithIconContainer"
  }, [_c('input', {
    staticClass: "withIconOnRight primaryBorder noInnerBoxShadow"
  }), _c('vb-icon', {
    staticClass: "svgInInput fillPrimary",
    attrs: {
      "icon": "calender-hrmIcon",
      "width": "18px",
      "height": "20px"
    }
  })], 1)]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Repeat every year ")]), _c('div', {
    staticClass: "innerShadowInput-container mt-24px"
  }, [_c('label', [_vm._v("Employees assigned:")]), _c('input', {
    attrs: {
      "placeholder": "Search by name"
    }
  })]), _c('div', {
    staticClass: "usersList-withCheckBox-container"
  }, [_c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-16px"
  }, [_vm._v(" Select All ")]), _vm._l(5, function (n) {
    return _c('b-form-checkbox', {
      key: n,
      staticClass: "HrmCheckBox-container"
    }, [_c('div', {
      staticClass: "nameWithImage"
    }, [_c('div', {
      staticClass: "imageContainer"
    }, [_c('img', {
      attrs: {
        "src": "/img/1.dd976805.jpeg"
      }
    })]), _c('div', {
      staticClass: "nameWithImage-text"
    }, [_vm._v("Marie jane")])])]);
  })], 2), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Create ")])], 1)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }