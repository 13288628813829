var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return !_vm.isEmpty(_vm.track) ? _c('div', {
    staticClass: "remote-track-container"
  }, [_vm.track.type === 'video' ? _c('div', {
    staticClass: "video-container"
  }, [_c('video', {
    class: {
      'share-screen': _vm.track.videoType == 'desktop',
      'mirror-video': _vm.participant._properties.isMirrorVideo
    },
    attrs: {
      "playsinline": "",
      "id": _vm.id,
      "autoplay": "1"
    }
  })]) : _vm._e(), _vm.track.type === 'audio' ? _c('div', {
    staticClass: "audio-container"
  }, [_c('audio', {
    directives: [{
      name: "mute",
      rawName: "v-mute",
      value: _vm.isAudioMuted,
      expression: "isAudioMuted"
    }],
    attrs: {
      "id": _vm.id,
      "autoplay": "1"
    }
  })]) : _vm._e()]) : _vm._e();

}
var staticRenderFns = []

export { render, staticRenderFns }