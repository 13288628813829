<template>
  <div class="dialer-settings-main">
    <div v-if="conditions.tab" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" >
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.tab=false" />
      </div>
      <div class="dialer-settings-title topMainHeading">Select Playlist</div>
    </div>
    <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">My Sound Files</div>
      <div 
        class="plus-IOSIcon-container" 
        @click="checkPermission(getUserPermissions.block_and_privacy,()=>$modal.show('AddSoundFile',{ 
          suggested_name: `sound library ${response.files.length+1}` 
        }))"
      >
        <vb-icon icon="plus-IOSIcon" class="plus-IOSIcon" width="20" height="19.992" />
      </div>
    </div>

    <section class="dialer-settings-section dialer-callRecording-settings MySoundFiles">
      <div v-if="conditions.tab">
        
        <div class="basicWhiteIOScard mt-32px">
          <div class="basicWhiteIOScard-item" v-for="tab in tabs" :key="tab.id"  @click="selected.tab=tab.id">
            <div class="headingInsideTable">{{tab.text}}</div>
            <vb-icon v-if="selected.tab==tab.id" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
          </div>
        </div>
      </div>
      <div v-else>
        <template>
          
          <div class="mobileInputIOS iconOnLeft mt-32px">
            
            <b-form-input placeholder="Search" v-if="selected.tab==tabs.system.id" v-model="filter.system.search" />
            <b-form-input placeholder="Search" v-else-if="selected.tab==tabs.files.id" v-model="filter.files.search" />
            <b-form-input placeholder="Search" v-else-if="selected.tab==tabs.shared.id" v-model="filter.shared.search" />
            <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
          </div>
        </template>
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item" @click="conditions.tab=true">
            <div class="headingInsideTable">Sound Library</div>
            <div class="d-flex align-items-center">
              <div class="textInsideCard onRightSide mr-8px">{{ selectedTab.text }}</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
            </div>
          </div>
        </div>
        <div class="basicWhiteIOScard mb-16px">
          
          <div>
            <template v-if="selected.tab==tabs.system.id">
              <div class="basicWhiteIOScard-item" v-for="(file, index) in filterSystem" :key="index">
                <div class="headingInsideTable">{{ file.label }}</div>
                <vb-audio-player class="mt-5px IosAudioPlayer" :src="file.sound_file" />
              </div>
              <vb-no-record v-if="!filterSystem.length && !api.files.send" :text="'There is no Numbers'" :design="3" />
            </template>
            <template v-else-if="selected.tab==tabs.files.id">
              <div class="basicWhiteIOScard-item" v-for="(file, index) in filterFiles" :key="index" @click="$modal.show('EditSoundFile', { sound: file })">
                <div class="headingInsideTable">{{ file.label }}</div>
                <vb-audio-player class="mt-5px IosAudioPlayer" :src="file.sound_file" />
              </div>
              <vb-no-record v-if="!filterFiles.length && !api.files.send" :text="'There is no Numbers'" :design="3" />
            </template>
            <template v-else-if="selected.tab==tabs.shared.id">
              <div class="basicWhiteIOScard-item" v-for="(file, index) in filterShared" :key="index">
                <div class="headingInsideTable">{{ file.label }}</div>
                <vb-audio-player class="mt-5px IosAudioPlayer" :src="file.sound_file" />
              </div>
              <vb-no-record v-if="!filterShared.length && !api.files.send" :text="'There is no Numbers'" :design="3" />
            </template>
          </div>
          <InfiniteLoading ref="files_infinity_loading" @infinite="fetchFiles()">
            <div slot="spinner">
              <vb-loading type="loader" />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results"></div>
            <template #error="{ trigger }">
              <div>
                <span>{{ api.files.error_message }}</span>
                <b-icon icon="arrow-repeat" class="ml-3" variant="primary" font-scale="1.5" @click="trigger()" />
              </div>
            </template>
          </InfiniteLoading>

        </div>
      </div>
      <AddSoundFileModal @update-list="fetchFiles(true)" />
      <EditSoundFileModal @update-list="fetchFiles(true)" />
    </section>
  </div>
</template>

<script>
import InfiniteLoading from 'vue-infinite-loading';

import AddSoundFileModal from '../modals/sounds/AddSound.vue'
import EditSoundFileModal from '../modals/sounds/EditSound.vue'

import { mapGetters } from 'vuex';

// import { centrifugo } from '@/Centrifuge/index';
import { VOIP_API } from "@/utils/index";
import { permission_handling } from '@/mixin';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';

const tabs = {
  files: {
    id: 1,
    text: "Mine",
  },
  shared: {
    id: 2,
    text: "Shared with me",
  },
  system: {
    id: 3,
    text: "System",
  },
}
export default {
  name: "SoundLibrary",
  mixins: [permission_handling],
  components: {
    InfiniteLoading,
    AddSoundFileModal,
    EditSoundFileModal,
  },
  data() {
    return {
      api: {
        files: this.$helperFunction.apiInstance({ error_message: true, }),
      },
      response: {
        files: [],
        shared: [],
        system: [],
      },
      filter: {
        shared: {
          search: '',
        },
        files: {
          search: '',
        },
        system: {
          search: '',
        },
      },
      selected: {
        tab: tabs.files.id
      },
      conditions: {
        tab: false,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
      'getUserPermissions',
    ]),
    tabs(){ return tabs },
    selectedTab(){ return Object.values(this.tabs).find(tab=>tab.id==this.selected.tab) },
    filterFiles(){ return this.response.files.filter(file=>`${file.label}`.trim().toLowerCase().includes(this.filter.files.search)) },
    filterShared(){ return this.response.shared.filter(shared=>`${shared.label}`.trim().toLowerCase().includes(this.filter.shared.search)) },
    filterSystem(){ return this.response.system.filter(system=>`${system.label}`.trim().toLowerCase().includes(this.filter.system.search)) },
  },
  methods: {
    fetchFiles(reset) {
      let vm = this;
      if(vm.api.files.send) return;
      vm.api.files.send = true;
      if(reset==true){
        vm.response.files = [];
        vm.response.shared = [];
        vm.response.system = [];
        vm.$refs.files_infinity_loading?.stateChanger?.reset?.()
      }
      VOIP_API.endpoints.recording.list({
        uid: vm.getCurrentUser.uid,
        accountcode: vm.getCurrentUser.account,
        type: 'ivr',
      })
      .then(({ data }) => {
        const files = data?.files?.data ?? data?.files ?? []
        const shared = data?.shared?.data ?? data?.shared ?? [];
        const system = data?.global?.data ?? data?.global ?? [];
        vm.response.files = files;
        vm.response.shared = shared;
        vm.response.system = system;
        if(files.length>0 || shared.length>0 || system.length>0) vm.$refs.files_infinity_loading?.stateChanger?.loaded()
        vm.$refs.files_infinity_loading?.stateChanger?.complete()
      })
      .catch((ex) => {
        vm.$refs.files_infinity_loading?.stateChanger?.error?.()
        vm.api.files.error_message=ex.own_message || ex.message
      })
      .finally(() => {
        vm.api.files.send = false;
      });
    },
    onNotification(message) {
      if (message.data.flag === "file_uploaded_successfully") {
        this.fetchFiles(true);
      }
    },
  },
  created() {
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
  },
  activated(){
    setTimeout(()=>this.fetchFiles(true),0.5*1000)
  },
  beforeDestroy() {
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
  },
};
</script>
