<template>
  <div class="hrm-home-container">
    <div class="hrm-home-inner twoParts">
      <div class="defaultPart left">
        <div class="hrmCard">
          <div class="hrmCard-head">
            <div class="hrmCard-heading">
              <div class="headIcon-container">
                <vb-icon icon="myTasks-hrmIcon" height="17.85px" width="15.06px"/>
              </div>
              My tasks
            </div>
          </div>
          <div class="hrmCard-body">
            <div class="userInfo-container">
              <div class="userInfo-container-inner">
                <div class="userInfo-img">
                  <img :src="require('@/assets/images/default.png')" alt="logo" />
                </div>
                <div class="userInfo">
                  <div class="userInfo-heading">Amber West</div>
                  <div class="userInfo-content">Sickday on 07/07/2022, second part of the day, 6 hours (awaiting approval)</div>
                </div>
              </div>
              <div class="userInfo-actions">
                <vb-icon icon="tick-hrmIcon" height="20px" width="15.5px"/>
                <vb-icon icon="cross-hrmIcon" height="15.5px" width="15.5px"/>
                <vb-icon icon="eye-hrmIcon" height="15px" width="22px"/>
              </div>
            </div>
            <div class="userInfo-container">
              <div class="userInfo-container-inner">
                <div class="userInfo-img">
                  <img :src="require('@/assets/images/default.png')" alt="logo" />
                </div>
                <div class="userInfo">
                  <div class="userInfo-heading">Amber West</div>
                  <div class="userInfo-content">Sickday on 07/07/2022, second part of the day, 6 hours (awaiting approval)</div>
                </div>
              </div>
              <div class="userInfo-actions">
                <vb-icon icon="tick-hrmIcon" height="20px" width="15.5px"/>
                <vb-icon icon="cross-hrmIcon" height="15.5px" width="15.5px"/>
                <vb-icon icon="eye-hrmIcon" height="15px" width="22px"/>
              </div>
            </div>
          </div>
        </div>
        <div class="hrmCard">
          <div class="hrmCard-head">
            <div class="hrmCard-heading">
              <div class="headIcon-container">
                <vb-icon icon="calenderLeave-hrmIcon" height="17.85px" width="15.06px"/>
              </div>
              Upcoming time off
            </div>
            <div class="d-flex">
              <button class="dialer-button dialer-button-primary hrmButton">
                <b-icon icon="plus" scale="1.7" class="mr-2" />
                Request time off
              </button>
              <button class="dialer-button ml-3 outline hrmButton">
                <vb-icon icon="history-hrmIcon" class="mr-2" height="20px" width="15.5px"/>
                <!-- <b-icon icon="plus" scale="1.7" class="mr-2" /> -->
                History
              </button>
            </div>
          </div>
          <div class="hrmCard-body">
            <div class="userInfo-container">
              <div class="userInfo-container-inner">
                <div class="userInfo-img">
                  <img :src="require('@/assets/images/default.png')" alt="logo" />
                </div>
                <div class="userInfo">
                  <div class="userInfo-heading">Amber West</div>
                  <div class="userInfo-content">Sickday on 07/07/2022, second part of the day, 6 hours (awaiting approval)</div>
                </div>
              </div>
              <div class="userInfo-actions">
                <vb-icon icon="tick-hrmIcon" height="20px" width="15.5px"/>
                <vb-icon icon="cross-hrmIcon" height="15.5px" width="15.5px"/>
                <vb-icon icon="pencil-hrmIcon" height="15.5px" width="15.5px"/>
                <vb-icon icon="eye-hrmIcon" height="15px" width="22px"/>
              </div>
            </div>
          </div>
        </div>
        <div class="hrmCard">
          <div class="hrmCard-head">
            <div class="hrmCard-heading">
              <div class="headIcon-container">
                <vb-icon icon="calender-hrmIcon" height="20px" width="26px"/>
              </div>
              Employees without team - Next two weeks
            </div>
          </div>
          <div class="hrmCard-body">
            <div class="userInfo-withCalender-container">
              <div class="dayBar">
                <div class="dayBar-inner">
                  <div class="dayName currentDay">Mo</div>
                  <div class="dayName">Tu</div>
                  <div class="dayName">We</div>
                  <div class="dayName">Th</div>
                  <div class="dayName">Fr</div>
                  <div class="dayName offDay">Sa</div>
                  <div class="dayName offDay">Su</div>
                  <div class="dayName">Mo</div>
                  <div class="dayName">Tu</div>
                  <div class="dayName">We</div>
                  <div class="dayName">Th</div>
                  <div class="dayName">Fr</div>
                  <div class="dayName offDay">Sa</div>
                  <div class="dayName offDay">Su</div>
                </div>
              </div>
              <div class="dayBar withDate">
                <div class="userInfo-container">
                  <div class="userInfo-container-inner">
                    <div class="userInfo-img">
                      <img :src="require('@/assets/images/default.png')" alt="logo" />
                    </div>
                    <div class="userInfo">
                      <div class="userInfo-heading">Amber West</div>
                    </div>
                  </div>
                </div>
                <div class="dayBar-inner">
                  <div class="dayName selectedDate">
                    <div class="dateItself">25</div>
                  </div>
                  <div class="dayName">
                    <div class="dateItself">26</div>
                  </div>
                  <div class="dayName">
                    <div class="dateItself">27</div>
                  </div>
                  <div class="dayName">
                    <div class="dateItself">28</div>
                  </div>
                  <div class="dayName">
                    <div class="dateItself">29</div>
                  </div>
                  <div class="dayName offDay">
                    <div class="dateItself">30</div>
                  </div>
                  <div class="dayName offDay">
                    <div class="dateItself">31</div>
                  </div>
                  <div class="dayName">
                    <div class="dateItself">01</div>
                  </div>
                  <div class="dayName">
                    <div class="dateItself">02</div>
                  </div>
                  <div class="dayName">
                    <div class="dateItself">03</div>
                  </div>
                  <div class="dayName">
                    <div class="dateItself">04</div>
                  </div>
                  <div class="dayName">
                    <div class="dateItself">05</div>
                  </div>
                  <div class="dayName offDay">
                    <div class="dateItself">06</div>
                  </div>
                  <div class="dayName offDay">
                    <div class="dateItself">07</div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="defaultPart right">
        <div class="hrmCard">
          <div class="hrmCard-head">
            <div class="hrmCard-heading">
              <div class="headIcon-container">
                <vb-icon icon="cakeWithCandle-hrmIcon" height="20px" width="17.06px"/>
              </div>
              Anniversaries
            </div>
          </div>
          <div class="hrmCard-body">
            <div class="userInfo-container">
              <div class="userInfo-img">
                <img :src="require('@/assets/images/default.png')" alt="logo" />
              </div>
              <div class="userInfo">
                <div class="userInfo-heading">Amber West</div>
                <div class="userInfo-content">has 9 year work anniversary tomorrow (27/07/2022)</div>
              </div>
            </div>
          </div>
        </div>
        <div class="hrmCard">
          <div class="hrmCard-head">
            <div class="hrmCard-heading">
              <div class="headIcon-container">
                <vb-icon icon="myEmployees-hrmIcon" height="20px" width="26px"/>
              </div>
              My employees
            </div>
          </div>
          <div class="hrmCard-body">
            <div class="userInfo-container">
              <div class="userInfo-img">
                <img :src="require('@/assets/images/default.png')" alt="logo" />
              </div>
              <div class="userInfo">
                <div class="userInfo-heading">Amber West</div>
                <!-- <div class="userInfo-content">has 9 year work anniversary tomorrow (27/07/2022)</div> -->
              </div>
            </div>
          </div>
        </div>
        <div class="hrmCard">
          <div class="hrmCard-head">
            <div class="hrmCard-heading">
              <div class="headIcon-container">
                <vb-icon icon="calenderTick-hrmIcon" height="20px" width="26px"/>
              </div>
              My Balance
            </div>
          </div>
          <div class="hrmCard-body">
            <div class="myBalance-container">
              <div class="bigNumber">8</div>
              <div class="mediumNumber">Holiday</div>
              <div class="smallNumber">8.87 days left     · updated at 23/07/2022 · 0 days used</div>
            </div>
            <div class="myBalance-container">
              <div class="bigNumber sickday">2</div>
              <div class="mediumNumber">Sickday</div>
              <div class="smallNumber">updated at 23/07/2022 · 2 days used</div>
            </div>
            <div class="myBalance-container">
              <div class="bigNumber">0</div>
              <div class="mediumNumber">Holiday - PK</div>
              <div class="smallNumber">0 days left     · updated at 23/07/2022 · 0 days used · 10 days to be accrued</div>
            </div>
            <div class="myBalance-container">
              <div class="bigNumber sickday">0</div>
              <div class="mediumNumber">Sickday</div>
              <div class="smallNumber">updated at 23/07/2022 · 0 days used</div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'HRMHome',
}
</script>

<style>

</style>