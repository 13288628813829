import store from "@/store/index"

const levels = {
  warn: {
    name: 'warn', 
    bg_color: 'yellow', 
    text_color: 'black',
  },
  danger: {
    name: 'danger', 
    bg_color: 'red', 
    text_color: 'white'
  },
  success: {
    name: 'success', 
    bg_color: 'green', 
    text_color: 'white'
  },
  info: {
    name: 'info', 
    bg_color: 'blue', 
    text_color: 'white',
  },
  event: {
    name: 'event', 
    bg_color: 'purple', 
    text_color: 'white',
  },
  watch: {
    name: 'watch', 
    bg_color: 'wheat', 
    text_color: 'black',
  },
  hook: {
    name: 'hook', 
    bg_color: 'black', 
    text_color: 'white',
  },
  log: { 
    name: 'info', 
    bg_color: '#F28B54', 
    text_color: 'white',
  },
  connected: { 
    name: 'connected', 
    bg_color: '#57bbac', 
    text_color: 'black',
  },
  disconnected: { 
    name: 'disconnected', 
    bg_color: '#290000', 
    text_color: 'white',
  },
}
class LOGGING {
  name=''
  console_type=''
  constructor(name,type){
    this.name=name || ''
    this.console_type=type
  }
  #consoling(level_text, ...args) {
    const consoling = store.state.logger[this.console_type]
    if(consoling){
      const level = levels[level_text]
      const styling = `background-color:${level.bg_color};color:${level.text_color};padding:5px;font-weight:bold;`
      const default_styling = `background-color:#5576D1;color:white;padding:5px;font-weight:bold;`
      const name_styling = `background-color:#113a1b;color:white;padding:5px;font-weight:bold;`
      if(this.name){
        console.log(`%cDIALER`+`%c${this.name}`+`%c${level.name.toUpperCase()}`,default_styling,name_styling,styling,...args)
      } else {
        console.log(`%cDIALER`+`%c${level.name.toUpperCase()}`,default_styling,styling,...args)
      }
    }
  }
  warn(...args){ this.#consoling('warn',...args) }
  danger(...args){ this.#consoling('danger',...args) }
  success(...args){ this.#consoling('success',...args) }
  info(...args){ this.#consoling('info',...args) }
  event(...args){ this.#consoling('event',...args) }
  watch(...args){ this.#consoling('watch',...args) }
  hook(...args){ this.#consoling('hook',...args) }
  log(...args){ this.#consoling('log',...args) }
  connected(...args){ this.#consoling('connected',...args) }
  disconnected(...args){ this.#consoling('disconnected',...args) }
}
export const LOGGER = new LOGGING('','general')
export const API_LOGGER = new LOGGING('API','api')
// export const CENTRIFUGO_LOGGER = new LOGGING('CENTRIFUGO','centrifugo')
export const AMI_LOGGER = new LOGGING('AMI','ami')
export const MATTERMOST_SOCKET_LOGGER = new LOGGING('MATTERMOST_SOCKET','mattermost_socket')
export const MATTERMOST_API_LOGGER = new LOGGING('MATTERMOST_API','mattermost_api')
export const SIP_LOGGER = new LOGGING('SIP','sip')
export const CRM_LOGGER = new LOGGING('CRM','crm')
export const INTEGRATION_LOGGER = new LOGGING('INTEGRATION','integration')
export const MEDIA_DEVICE_SETTINGS_LOGGER = new LOGGING('MEDIA_DEVICE_SETTINGS','media_device_settings')