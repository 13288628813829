var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "ticketTabContainer"
  }, [_c('div', {
    staticClass: "ticketHeading-container justify-content-end"
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-AddTicketModal`);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Create a support ticket")])], 1)]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.tickets.length == 0,
      "listLength": _vm.tickets.length,
      "loading": _vm.api.fetch_tickets.send,
      "noRecordWholePage": true
    },
    scopedSlots: _vm._u([{
      key: "header",
      fn: function () {
        return [_c('tr', [_c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', {
          staticClass: "dialer-sorted-by ascending"
        }, [_vm._v("SUBJECT")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', {
          staticClass: "dialer-sorted-by ascending"
        }, [_vm._v("SUBMITTED")])]), _c('th', {
          staticClass: "dialer-has-sort"
        }, [_c('span', {
          staticClass: "dialer-sorted-by ascending"
        }, [_vm._v("DEPARTMENT")])]), _c('th', {
          staticClass: "dialer-has-sort dialer-col-right"
        }, [_c('span', [_vm._v("STATUS")])])])];
      },
      proxy: true
    }, {
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.tickets, function (ticket, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: ticket.id,
            on: {
              "click": function ($event) {
                return _vm.$modal.show(`${_vm._uid}-UpdateTicketModal`, {
                  ticket_id: ticket.id
                });
              }
            }
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_vm._v(" " + _vm._s(ticket.subject) + " ")])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_vm._v(" " + _vm._s(_vm._f("filter_date_current")(ticket.created_at, '', {
            formate_now: true
          })) + " ")])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_vm._v(" " + _vm._s(ticket.department) + " ")])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v(_vm._s(ticket.status))])]);
        });
      }
    }, {
      key: "no-record",
      fn: function () {
        return [_c('div', {
          staticClass: "dialer-box alt table mt-5"
        }, [_c('div', {
          staticClass: "description-text-container"
        }, [_c('div', {
          staticClass: "description-text"
        }, [_vm._v(" If you didn't find solution to your problem please visit our knowledge base for more information we are happy to help you! ")]), _c('button', {
          staticClass: "dialer-button dialer-button-primary",
          attrs: {
            "type": "button"
          },
          on: {
            "click": function ($event) {
              return _vm.$emit('go-to-knowledge-base');
            }
          }
        }, [_vm._v("Go to knowledge base")])])])];
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "16px",
              "width": "60px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end align-items-center w-100"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "16px",
              "width": "50px"
            }
          })])])]);
        });
      },
      proxy: true
    }])
  }), _c('AddTicketModal', {
    attrs: {
      "modalName": `${_vm._uid}-AddTicketModal`,
      "categories": _vm.categories,
      "departments": _vm.departments,
      "types": _vm.types
    },
    on: {
      "created": function ($event) {
        return _vm.fetchTickets();
      }
    }
  }), _c('UpdateTicketModal', {
    attrs: {
      "modalName": `${_vm._uid}-UpdateTicketModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.fetchTickets();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }