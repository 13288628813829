export const events = {
  join_room_now: 'join_room_now', // root
  // mark_unread: "mark_unread", 
  video_call: "video_call", // root
  audio_call: "audio_call", // root
  send_sms: "send_sms", // root
  cti_audio_call: "cti_audio_call", // root
  setting_tab: "setting_tab", // root
  // current_chat: "current_chat",
  // chat_tabs: "chat_tabs",
  change_chat_tab: "change_chat_tab", //convert to (provider/inject)
  // change_background: "change_background",
  // un_read_messages: "un_read_messages",
  // centrifuge_notification_message: "centrifuge_notification_message",
  // centrifuge_notification_connected: "centrifuge_notification_connected",
  // centrifuge_notification_disconnect: "centrifuge_notification_disconnect",
  // mattermost_user_login: "mattermost_user_login",
  pin_posts: "pin_posts", // root
  notify: "notify", // root
  // check_pending_services: "check_pending_services", // root
  fetch_call_recording_setting: "fetch_call_recording_setting", // root
  // edit_post: "edit_post",
  // reply_post: "reply_post",
  // show_more_posts: "show_more_posts",
  switch_organization: "switch_organization", // root
  // CENTRIFUGE_OTHER: "CENTRIFUGE_OTHER",
  // presence_message: "presence_message",
  hold_audio_call: "hold_audio_call", // root
  // hangup_audio_calls: "hangup_audio_calls", 
  hold_video_call: "hold_video_call", // root
  // pinned_post: "pinned_post",
  // unpinned_post: "unpinned_post",
  call_ended: "call_ended", // root
  join_call: "join_call", // root
  playing_audio: "playing_audio", // root
  open_chat: 'open_chat', // root

  check_unread_count_api: "check_unread_count_api", // root
  uploading_file_on_channel: 'uploading_file_on_channel', // root
  get_messages_api: "get_messages_api", // root
  get_pinned_messages_api: "get_pinned_messages_api", // root
  adding_channel: "adding_channel", // root
  channel_view: "channel_view", // root
  pinned_post_changed: "pinned_post_changed", // root
  get_all_channels: 'get_all_channels', // root
  //keyboard
  ctrl_backslash: "ctrl_backslash", // root
  ctrl_w: "ctrl_w", // root
  ctrl_coma: "ctrl_coma", // root
  ctrl_h: "ctrl_h", // root
  ctrl_o: "ctrl_o", // root
  ctrl_t: "ctrl_t", // root
  ctrl_i: "ctrl_i", // root
  ctrl_tab: "ctrl_tab", // root
  ctrl_minus: "ctrl_minus", // root
  ctrl_0: "ctrl_0", // root
  ctrl_q: "ctrl_q", // root
  ctrl_n: "ctrl_n", // root
  ctrl_g: "ctrl_g", // root
  ctrl_p: "ctrl_p", // root
  ctrl_f: "ctrl_f", // root
  ctrl_m: "ctrl_m", // root
  ctrl_d: "ctrl_d", // root
  ctrl_s: "ctrl_s", // root
  ctrl_shift_a: "ctrl_shift_a", // root
  ctrl_shift_p: "ctrl_shift_p", // root
  ctrl_shift_j: "ctrl_shift_j", // root
  ctrl_shift_h: "ctrl_shift_h", // root
  ctrl_shift_k: "ctrl_shift_k", // root
  ctrl_shift_f: "ctrl_shift_f", // root
  ctrl_shift_g: "ctrl_shift_g", // root
  ctrl_shift_u: "ctrl_shift_u", // root
  ctrl_shift_l: "ctrl_shift_l", // root
  ctrl_shift_e: "ctrl_shift_e", // root
  ctrl_shift_plus: "ctrl_shift_plus", // root
  ctrl_shift_s: "ctrl_shift_s", // root
  ctrl_shift_t: "ctrl_shift_t", // root
  ctrl_shift_c: "ctrl_shift_c", // root
  ctrl_shift_m: "ctrl_shift_m", // root
  ctrl_alt_m: "ctrl_alt_m", // root
  ctrl_alt_h: "ctrl_alt_h", // root
  ctrl_alt_shift_k: "ctrl_alt_shift_k", // root
  ctrl_shift_1: "ctrl_shift_1", // root
  ctrl_shift_2: "ctrl_shift_2", // root
  ctrl_shift_3: "ctrl_shift_3", // root
  ctrl_shift_4: "ctrl_shift_4", // root
  alt_1: "alt_1", // root
  alt_2: "alt_2", // root
  alt_3: "alt_3", // root
  alt_4: "alt_4", // root
  alt_5: "alt_5", // root
  alt_6: "alt_6", // root
  alt_7: "alt_7", // root
  alt_shift_e: "alt_shift_e", // root
  // add_address_book: 'add_address_book',
  fetch_call_activity: 'fetch_call_activity', // root
  fetch_call_activity_interval: '  fetch_call_activity_interval', // root
};
