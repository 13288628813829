import { VOIP_API } from "../../index"


export const PIPELINES_CRM_API_ENDPOINTS = {
  list(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: 'pipelines',
      method: 'GET',
      params,
      cancelToken,
    })
  },
  findBy(id='',params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: `pipelines/${id}`,
      method: 'GET',
      params,
      cancelToken,
    })
  },
}