<template>
  <modal
    name="CreateChannelModal"
    @before-open="beforeOpen"
    @closed="closed"
    class="CreateChanneFromUserslModal"
  >
    <!-- dialer_animation center-zoomIn-popup dialer-addMemberModal dialer-createChannelModal -->
    <div class="topBar-withCloseIcon mt-0">
      <h3 class="dialer-box-header w-100 mb-0">Create Group</h3>
      <!-- <vb-icon icon="dialer-close-icon" width="14" height="14" @click="$modal.hide('CreateChannelModal')" /> -->
      <button type="button" class="newCloseButton" @click="$modal.hide('CreateChannelModal')">
        <vb-icon icon="squared-close-icon" height="38px" width="38px" />
        <span class="newCloseButton-textPart">Close</span>
      </button>
    </div>
    <div class="searchBox">
      <b-form @submit.prevent="''" class="position-relative">
        <b-form-input placeholder="Search People" v-model="search" class="w-100"></b-form-input>
        <b-icon icon="search" scale="1" class="position-absolute cursor_pointer searchIcon"/>
        <!-- <b-icon icon="x-circle" scale="1" class="position-absolute cursor_pointer xCircleIcon" /> -->
      </b-form>
    </div>
    <div class="addable-memberLisit">
      <ul class="d-flex list-unstyled">
        <li v-for="user in selectedUsers" :key="user.voipaccount" v-show="$store.getters.getCurrentUser.account !== user.voipaccount"
          class="d-inline-flex flex-column align-items-center pt-1 px-2"
        >
          <div class="user-avatar d-block avatar rounded-circle position-relative">
            <span v-if="getProperty(user,'user.user_chat_id')" class="member-close position-absolute right-0 top-0"
              @click=" member.user_id !== user.user.user_chat_id ? $delete(selected_users, user.user.user_chat_id) : '' "
            >
              <b-icon icon="x-circle-fill"></b-icon>
            </span>
            <img :src="user.profile_img" @error="$event.target.src = userDPMale"/>
          </div>
          <small class="member-name mt-1">{{ user | get_property('user.display_name') | selectedUserDisplayName }}</small>
        </li>
      </ul>
    </div>
    <ul class="AvailableUsersList">
      <li
        v-for="user in searchedNotSelectedUsers"
        :key="user.voipaccount"
        v-show="$store.getters.getCurrentUser.account !== user.voipaccount"
        class="AvailableUsersList-item"
        @click="getProperty(user,'user.user_chat_id')?$set(selected_users, user.user.user_chat_id, true):''"
      >
        <Info :id="user.voipaccount" :is_suspended_show="false" :is_blf="false" />
        <!-- <div class="info">
          <div class="image-container">
            <img :src="user.profile_img" @error="$event.target.src = userDPMale" />
          </div>
          <h5 class="text-capitalize">{{ user | get_property('user.display_name') }}</h5>
        </div> -->
        <b-icon icon="plus-circle-fill" font-scale="1.75" variant="primary" />
      </li>
      <vb-no-record
        v-if="isEmpty(searchedNotSelectedUsers)"
        text="There in no user"
        :design="3"
      />
    </ul>


    <div class="d-flex justify-content-end w-100">
      <button type="button" class="newDoneButton" @click="onDone">
        <vb-icon icon="squared-done-icon" height="38px" width="38px" />
        <span class="newDoneButton-textPart">Done</span>
      </button>
    </div>
    <!-- <div class="dialer-chatBox-inVideo"> -->
      <!-- <div class="dialer-videoCall-addMember mb-0 pt-0 position-relative"> -->
        <!-- <div class="border-bottom sticky-top"> -->
          <!-- <div class="d-flex align-items-center justify-content-between pt-3 px-3">
            
            <h4 class="mb-0">
              Create Group
            </h4>
            <button
              type="button"
              class="d-flex dialer-button dialer-button-primary px-4 py-2" style="line-height:1;"
              @click="onDone"
            >
              Done
            </button>
          </div> -->
          <!-- <div class="pt-3 px-3">
            <div class="searchBox">
              <b-form @submit.prevent="''" class="position-relative">
                <b-form-input v-model="search" class="w-100 p-l-36px"></b-form-input>
                <b-icon
                  icon="search"
                  scale="1"
                  class="position-absolute cursor_pointer"
                />
                <b-icon
                  icon="x-circle"
                  scale="1"
                  class="position-absolute cursor_pointer"
                />
              </b-form>
            </div>
          </div> -->
          <!-- <div class="addable-memberLisit pt-3 px-2">
            <ul class="d-flex list-unstyled">
              
              <li
                v-for="user in selectedUsers"
                :key="user.voipaccount"
                v-show="$store.getters.getCurrentUser.account !== user.voipaccount"
                class="d-inline-flex flex-column align-items-center pt-1 px-2"
              >
                <div
                  class="user-avatar d-block avatar border border-dark rounded-circle position-relative"
                >
                  <span
                    v-if="getProperty(user,'user.user_chat_id')"
                    class="member-close position-absolute right-0 top-0"
                    @click="
                      member.user_id !== user.user.user_chat_id
                        ? $delete(selected_users, user.user.user_chat_id)
                        : ''
                    "
                  >
                    <b-icon icon="x-circle-fill"></b-icon>
                  </span>
                  <img 
                    :src="user.profile_img"
                    @error="$event.target.src = userDPMale"
                  />
                </div>
                <small class="member-name mt-1">{{ user | get_property('user.display_name') | selectedUserDisplayName }}</small>
              </li>
            </ul>
          </div> -->
        <!-- </div> -->
        
      <!-- </div> -->
    <!-- </div> -->
  </modal>
</template>

<script>
import { mattermost, MATTERMOST_API } from "@/MatterMost";
import Info from '@/components/Lists/Info.vue'
import { local_files } from "@/mixin";
import { $fn } from "@/utils";
export default {
  name: "CreateChannelModal",
  components: {
    Info,
  },
  mixins: [local_files],
  data() {
    return {
      search: "",
      selected_users: {},
      member: {},
      loading: false,
    };
  },
  inject:['isEmpty','getProperty'],
  computed: {
    searchedNotSelectedUsers() {
      if (!this.search) return this.notSelectedUsers;
      var searchRegex = new RegExp(this.search.toLowerCase().trim(), "i");
      return this.notSelectedUsers.filter(
        (user) => searchRegex.test(user.extn) || searchRegex.test(user?.user?.display_name)
      );
    },
    selectedUsers() {
      return Object.values(this.$store.state.common.voipusers).filter(
        (user) => this.selected_users[user?.user?.user_chat_id]
      );
    },
    channelName() {
      let names = [];
      this.selectedUsers.forEach((user) => {
        names.push(user?.user?.display_name);
      });
      if (names.length > 2) {
        return `${names[0]}, ${names[1]}, ${names.length - 2} Others`;
      } else if (names.length == 2) {
        return `${names[0]}, ${names[1]}`;
      } else if (names.length == 1) {
        return `${names[0]}`;
      }
      return "";
    },
    notSelectedUsers() {
      return Object.values(this.$store.state.common.voipusers).filter(
        (user) => !this.selected_users[user?.user?.user_chat_id]
      );
    },
  },
  filters: {
    selectedUserDisplayName(value){ return value?.split?.(' ')?.[0] ?? '' },
  },
  methods: {
    beforeOpen(event) {
      this.member = event.params.member;
      this.selected_users = {};
      this.$set(this.selected_users, this.member.user_id, true);
    },
    async onDone() {
      try {
        this.loading = true;
        const ids = Object.keys(this.selected_users);
        const { data: channel } = await MATTERMOST_API.endpoints.channels.create({
          team_id: this.$store.getters.getCurrentUser?.mm_team_id,//this.current_user.mm_team_id,
          type: "P",
          display_name: this.channelName,
          name: ($fn.getUniqueID() + this.channelName + $fn.getUniqueID())
            .replace(/[^a-zA-Z0-9 ]/g, "")
            .replace(/ /g, "-")
            .toLowerCase(),
        });
        mattermost.emitOnUserLevel(mattermost.events.channel_created,{
          channel_id: channel.id
        })
        for (let index = 0; index < ids.length; index++) {
          const id = ids[index];
          const { data } = await MATTERMOST_API.endpoints.channels.addChannelMember(channel.id, {
            user_id: id,
          });
          mattermost.emitOnUserLevel(mattermost.events.user_added,{
            user_id: data?.user_id,
            channel_id: data?.channel_id,
          })
        }
        this.$emit("complete", channel);
      } finally {
        this.loading = false;
        this.$modal.hide("CreateChannelModal");
      }
    },
    closed() {
      this.selected_users = {};
    },
  },
};
</script>

<style lang="scss">
.addable-memberLisit {
  ul {
    /* width */
    ::-webkit-scrollbar {
      width: 4px;
      height: 4px;
    }
  }
  ul {
    overflow-x: auto;
  }
  .avatar {
    &.user-avatar {
      overflow: visible;
      .member-close {
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        top: -3px;
        right: -3px;
      }
    }
  }
  .member-name {
    width: 18px;
    overflow: hidden;
    text-overflow: unset;
  }
}
</style>
