var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "remote-tracks-conatiner"
  }, _vm._l(_vm.tracks, function (track, index) {
    return _c('RemoteTrack', {
      key: `${_vm.participant._id}_${track.type}_${track.videoType}_${track.isMuted()}_${track.getId()}_${index}_`,
      attrs: {
        "remoteTrack": track,
        "participant": _vm.participant,
        "index": index,
        "JitsiConference": _vm.JitsiConference,
        "speaker_device_id": _vm.speaker_device_id,
        "videoCall": _vm.videoCall
      }
    });
  }), 1);

}
var staticRenderFns = []

export { render, staticRenderFns }