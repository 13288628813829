
<template>
  <modal
    class="addStripeCard"
    width="40%"
    height="auto"
    :scrollable="true"
    :name="modalName"
    @before-open="onBeforeOpen($event)"
    @opened="onOpened($event)"
    @before-close="onBeforeClose($event)"
    :clickToClose="false"
  >
    <!-- dialer_animation right_side_popup  -->
    <div class="dialer-edit-header w-100 pt-0 flex-column">
      <div class="w-100 d-flex justify-content-between align-items-center">
        <h2 class="dialer-edit-title mb-0 ">
          Add card
        </h2>
        <div class="dialer-edit-actions">
          <button class="newCloseButton" @click="$modal.hide(modalName)" type="button" >
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div class="vm--modal-text">
        Securely link your payment method with VoIP Business by adding your stripe card. Enjoy seamless transactions and manage your subscriptions effortlessly.
      </div>
    </div>
    <!-- <div v-if="api.check_billing_mode.send" >
      Loading...
    </div> -->
    <div v-if="api.check_billing_mode.send" class="latestGreyBox-9-9-2023 mt-20px">
      <div class="latestShimmerDesign mb-20px" style="height:78px;width:100%;"></div>
      <div class="latestShimmerDesign mb-8px" style="height:21px;width:30%;"></div>
      <div class="latestShimmerDesign mb-20px" style="height:50px;width:100%;"></div>
      <div class="latestShimmerDesign mb-8px" style="height:21px;width:30%;"></div>
      <div class="latestShimmerDesign mb-20px" style="height:50px;width:100%;"></div>
      <div class="latestShimmerDesign" style="height:50px;width:100%;"></div>
    </div>
    <div v-else class="cell example example3 updated" id="example-3">
      <div class="innerBlueBoxwithRoundedBorder mb-20px pl-2">
        <vb-icon icon="AccountBalance-newBilling-icon" height="25px" width="25px" class="AccountBalance-newBilling-icon"/>
        <div class="d-flex align-items-center justify-content-between flex-fill">
          <p class="innerRedBoxwithRoundedBorder-text mb-0">
            To verify your account, deducting £2 which will be refunded immediately after
          </p>
        </div>
      </div>
      <form @submit.prevent="''">
        <div class="whiteBGinputWithGreyRoundedBorder mb-20px">
          <label>Card holder name</label>
          <input class="w-100" style="color:#307FE2;" v-model="forms.add_card.card_name" :disabled="api.pay.send" />
          <p class="text text-small text-danger animated bounceIntop mb-0" v-if="$v.forms.add_card.card_name.$error">
            <span v-if="!$v.forms.add_card.card_name.required">Card name is required</span>
          </p>
        </div>
        <div class="fieldset">
          <div class="first">
            <label>Card number</label>
            <div id="example3-card-number" ref="card-number" style="height: 50px" class="field empty"></div>
          </div>
          <div class="second">
            <label>Expiration date</label>
            <div id="example3-card-expiry" ref="card-expiry" style="height: 50px" class="field empty third-width"></div>
          </div>
          <div class="third">
            <label>CVC</label>
            <div id="example3-card-cvc" ref="card-cvc" style="height: 50px" class="field empty third-width"></div>
          </div>
        </div>
        <button class="fullWidthDoneButton mt-20px" type="button" @click="pay()" data-tid="elements_examples.form.pay_button">
          <vb-spinner v-if="api.pay.send" />
          <template v-else>
            Add card
          </template>
        </button>
      </form>
    </div>
  </modal>
</template>

<script>
import { /*$fn, */VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import { loadStripe } from '@stripe/stripe-js';
import { GET_LOCAL_SETTING_IS_DARK_MODE } from '@/store/helper/getters';
import required from 'vuelidate/lib/validators/required';
export default {
  name: 'AddCardStripeModal',
  props: {
    modalName: {
      type: String,
      default: 'AddCardStripe',
    },
  },
  data() {
    return {
      api: {
        pay: this.$helperFunction.apiInstance(),
        check_billing_mode: this.$helperFunction.apiInstance(),
      },
      stripe: {
        card: {
          stripe: null,
          element: null,
          elements: {
            card_number: null,
            card_expiry: null,
            card_cvc: null,
          },
        },
      },
      forms: {
        add_card: this.$helperFunction.formInstance({
          data: {
            card_name: '',
          }
        }),
      },
      response: {
        billing_mode: {}
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    stripePublishKey(){ 
      const live = this.response?.billing_mode?.billing_mode==0
      if(live) {
        return process.env.VUE_APP_STRIPE_LIVE_PUBLISH_KEY || '' 
      }
      return process.env.VUE_APP_STRIPE_TEST_PUBLISH_KEY || '' 
    },
    ...mapGetters([
      'getCurrentUser',
      GET_LOCAL_SETTING_IS_DARK_MODE
    ]),
    instanceOptions(){ return {} },
    elementsOptions(){ return {} },
    cardOptions(){ return {} },
  },
  validations: {
    forms: {
      add_card: {
        card_name: {
          required: required,
        }
      },
    },
  },
  methods: {
    onBeforeOpen(){
      // TODO - 
    },
    onOpened(){
      this.checkBillingMode()
    },
    onBeforeClose(){
      this.stripe.card.stripe=null
      this.stripe.card.element=null
      this.stripe.card.elements.card_number=null
      this.stripe.card.elements.card_expiry=null
      this.stripe.card.elements.card_cvc=null
      this.forms.add_card.reset()
      this.api.pay.reset()
      this.api.check_billing_mode.reset()
      this.response.billing_mode={}
    },
    async checkBillingMode(){
      if(this.api.check_billing_mode.send) return;
      try {
        this.api.check_billing_mode.send=true
        const { data } = await VOIP_API.endpoints.billing_api.checkBillingMode({
          uid: this.getCurrentUser.uid,
        })
        this.response.billing_mode=data
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger'
        })
      } finally {
        this.api.check_billing_mode.send=false
        this.stripeLoad()
      }
    },
    async stripeLoad(){
      this.stripe.card.stripe = await loadStripe(this.stripePublishKey);
      this.stripe.card.element = this.stripe.card.stripe.elements({
        fonts: [
          {
            cssSrc: 'https://fonts.googleapis.com/css?family=Nunito',
          },
        ],
      });
      const elementStyles = {
        base: {
          color: '#307FE2',
          fontWeight: 400,
          fontFamily: '"Nunito Sans", sans-serif',
          fontSize: '16px',
          fontSmoothing: 'antialiased',
          ':focus': {
            color: '#307FE2',
          },
          '::placeholder': {
            color: '#CFD7DF',
          },
          ':focus::placeholder': {
            color: '#CFD7DF',
          },
        },
        invalid: {
          color: '#FA755A',
          ':focus': {
            color: '#FA755A',
          },
          '::placeholder': {
            color: '#FFCCA5',
          },
        },
      };
      const elementClasses = {
        focus: 'focus',
        empty: 'empty',
        invalid: 'invalid',
      };
      this.stripe.card.elements.card_number = this.stripe.card.element.create('cardNumber', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.stripe.card.elements.card_number.mount(this.$refs['card-number']);
      this.stripe.card.elements.card_expiry = this.stripe.card.element.create('cardExpiry', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.stripe.card.elements.card_expiry.mount(this.$refs['card-expiry']);
      this.stripe.card.elements.card_cvc = this.stripe.card.element.create('cardCvc', {
        style: elementStyles,
        classes: elementClasses,
      });
      this.stripe.card.elements.card_cvc.mount(this.$refs['card-cvc']);
    },
    async pay(){
      this.$v.forms.add_card.$touch()
      if(this.api.pay.send || this.$v.forms.add_card.$invalid) return;
      try {
        this.api.pay.send=true
        const { data } = await VOIP_API.endpoints.billing_api.getSetupIntentClientSecret({
          uid: this.getCurrentUser?.uid
        })
        const setupIntentClientSecret = data.client_secret
        // const { key: setupIntentClientSecret } = await $fn.sleep(1*1000,{
        //   key: 'seti_1ONxSHEE3PH7VQzWw36xSfRH_secret_PCMAOlygSFRiishdwSvucIOEHBsm6rw'
        // })
        let confirmCardSetup = await this.stripe.card.stripe.confirmCardSetup(
          setupIntentClientSecret,
          {
            payment_method: {
              card: this.stripe.card.elements.card_number,
            },
          }
        );
        // console.log('confirmCardSetup',confirmCardSetup)
        if (confirmCardSetup.error) throw new Error(confirmCardSetup.error.message,{ cause: confirmCardSetup.error }) 
        else if (['requires_source_action','requires_action'].includes(confirmCardSetup.setupIntent.status)) {
          confirmCardSetup = await this.stripe.card.stripe.confirmCardSetup(confirmCardSetup.setupIntent.client_secret);
          // console.log('confirmCardSetup',confirmCardSetup)
          if (confirmCardSetup.error) throw new Error(confirmCardSetup.error.message,{ cause: confirmCardSetup.error }) 
        }
        const token = await this.stripe.card.stripe.createToken(this.stripe.card.elements.card_number)
        // console.log('token',token)
        if(token.error?.message) throw new Error(token.error?.message, { cause: token.error })
        const { data: accountdetail } = await VOIP_API.endpoints.users.accountdetail()
        await VOIP_API.endpoints.billing_api.create({
          stripe: {
            id: token?.token?.id ?? '',
            setupintent_Id : confirmCardSetup?.setupIntent?.id ?? '',
            payment_method: confirmCardSetup?.setupIntent?.payment_method ?? '',
          },
          name: this.forms.add_card.card_name,
          uid: this.getCurrentUser.uid,
          email: this.getCurrentUser?.billingEmail,
          // name: this.getCurrentUser?.display_name,
          accountcode: this.getCurrentUser?.account,
          city: accountdetail?.user_detail?.city ?? '',
          postcode: accountdetail?.user_detail?.postcode ?? '',
        })
        this.appNotify({
          message: 'Successfully Added',
          type: 'success'
        })
        this.$modal.hide(this.modalName)
      } catch (ex) {
        const code = ex?.cause?.code
        let message = ex?.own_message || ex?.message
        if(code=='resource_missing') message='Please add an authentic card'
        this.appNotify({
          message: message,
          type: 'danger'
        })
      } finally {
        this.api.pay.send=false
        this.$v.forms.add_card.$reset()
      }
    },
  },
}
</script>

<style>
  .example.example3 {
  background-color: #525f7f;
}
.example.example3 .field.half-width {
  width: calc(50% - (5px / 2));
}

.example.example3 .field.third-width {
  width: calc(33% - (5px / 3));
}

.example.example3 .field + .field {
  margin-top: 6px;
}

.example.example3 .field.focus,
.example.example3 .field:focus {
  color: #424770;
  background-color: #f6f9fc;
}

.example.example3 .field.invalid {
  background-color: #fa755a;
}

.example.example3 .field.invalid.focus {
  background-color: #f6f9fc;
}

.example.example3 .field.focus::-webkit-input-placeholder,
.example.example3 .field:focus::-webkit-input-placeholder {
  color: #cfd7df;
}

.example.example3 .field.focus::-moz-placeholder,
.example.example3 .field:focus::-moz-placeholder {
  color: #cfd7df;
}

.example.example3 .field.focus:-ms-input-placeholder,
.example.example3 .field:focus:-ms-input-placeholder {
  color: #cfd7df;
}

.example.example3 input, .example.example3 button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  outline: none;
  border-style: none;
}

.example.example3 input {
  color: #fff;
}

.example.example3 input::-webkit-input-placeholder {
  color: #9bacc8;
}

.example.example3 input::-moz-placeholder {
  color: #9bacc8;
}

.example.example3 input:-ms-input-placeholder {
  color: #9bacc8;
}

/*.example.example3 button {
  display: block;
  width: calc(100% - 30px);
  height: 40px;
  margin: 0 15px;
  background-color: #fcd669;
  border-radius: 20px;
  color: #525f7f;
  font-weight: 600;
  text-transform: uppercase;
  cursor: pointer;
}*/

.example.example3 button:active {
  background-color: #f5be58;
}

.example.example3 .error svg .base {
  fill: #fa755a;
}

.example.example3 .error svg .glyph {
  fill: #fff;
}

.example.example3 .error .message {
  color: #fff;
}

.example.example3 .success .icon .border {
  stroke: #fcd669;
}

.example.example3 .success .icon .checkmark {
  stroke: #fff;
}

.example.example3 .success .title {
  color: #fff;
}

.example.example3 .success .message {
  color: #9cabc8;
}

.example.example3 .success .reset path {
  fill: #fff;
}
</style>