export default [
  {
    id: 1,
    title: 'October 2022',
    data: [
      {
        id: 20,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 21,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 22,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 23,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
    ]
  },
  {
    id: 2,
    title: 'September 2022',
    data: [
      {
        id: 20,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 21,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 22,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 23,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
    ]
  },
  {
    id: 3,
    title: 'November 2022',
    data: [
      {
        id: 20,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 21,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 22,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 23,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
    ]
  },
  {
    id: 4,
    title: 'December 2022',
    data: [
      {
        id: 20,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 21,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 22,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
      {
        id: 23,
        employee: 'Jack William',
        eventType: 'Birthday',
        date: '2022-09-05',
        visibility: 'Visible to colleagues',
        imgSrc: '@/assets/images/credit-card/1.jpeg'
      },
    ]
  }
]