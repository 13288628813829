<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-500 EditNumbersModal min-width-50percent-modal" 
      width="50%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose($event)"
    >
      <section class="dialer-number-edit">
        <div class="dialer-edit">
          <div class="dialer-edit-header flex-column pb-1 align-items-start">
            <div class="d-flex justify-content-between align-items-center w-100">
              <div>
                <h2 class="dialer-settings-title newer">User based call routing</h2>
              </div>
              <div class="dialer-edit-actions">
                <button 
                  class="newDoneButton" 
                  type="button" 
                  @click="$modal.hide(modalName)"
                >
                  <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart">Done</span>
                </button>
              </div>
            </div>
            <p class="vm--modal-text">Customise your profile preferences on this page</p>
          </div>
          <!-- <div>
            <Info v-for="member in data.members" :key="member.id" :id="member.cg_item" />
          </div> -->
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div v-if="ringgroupAccountcode">
              <!-- MOH and Pickup Groups Switch section -->
              <div>
                <div class="whiteBoxWithBorderInsideGreyBox">
                  <div class="whiteBoxWithBorderInsideGreyBox-row">
                    <div class="d-flex flex-column w-100">
                      <div class="latestGreyBox-heading-main">Incoming calls</div>
                      <div class="latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase">
                        When {{ data.number | number_formater }}  is called it will ring to the following Users...
                      </div>
                      <!-- <div class="d-flex align-items-center flex-wrap mt-20px mb-20px">
                        <Info v-for="member in data.members" :key="member.id" :is_blf="false" :id="member.cg_item" class="m-2" />
                      </div> -->
                      <div class="IVR-numbers-container d-flex align-items-center flex-wrap mt-20px mb-20px">
                        <div v-for="member in data.members" :key="member.id" class="newIVRLayout-number-item hasUuersInIt">
                          <Info :is_blf="false" :id="member.cg_item" />
                        </div>
                      </div>
                      <div class="latestGreyBox-descriptionText">
                        <b>Note:</b> The above users have access to the settings below
                      </div>
                    </div>
                  </div>
                  <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside">
                    <CallForwardingSetting 
                      v-if="!!ringgroupAccountcode" 
                      @cf-updated="$emit('cf-updated')"
                      :account="ringgroupAccountcode"
                      :forwardCallerId="true"
                    />
                  </div>
                  <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0 dialer_box_text_my-0">
                    <ScheduleSetting 
                      v-if="!!ringgroupAccountcode" 
                      @ofh-days-updated="$emit('ofh-days-updated')"
                      @ofh-default-updated="$emit('ofh-default-updated')"
                      @ofh-toggle-updated="$emit('ofh-toggle-updated')"
                      :account="ringgroupAccountcode" 
                    />
                  </div>
                  <div class="whiteBoxWithBorderInsideGreyBox-row somethingInside dialer_box_text_my-0">
                    <MusicOnHoldWidget v-if="ringgroupAccountcode" :accountcode="ringgroupAccountcode"  />
                  </div>
                  <!-- <MissedCallNotificationWidget v-if="ringgroupAccountcode" :accountcode="ringgroupAccountcode" /> -->
                </div>
              </div>
              <VoicemailSetting class="VoicemailSetting-insideTeamsModal" v-if="ringgroupAccountcode" :accountcode="ringgroupAccountcode" />
            </div>
          </div>
        </div>
      </section>
    </modal>
  </div>
</template>

<script>
import MusicOnHoldWidget from '../widgets/MusicOnHold.vue'
import ScheduleSetting from '../Common/ScheduleSetting.vue';
import CallForwardingSetting from '../Common/CallForwardingSetting.vue';
import VoicemailSetting from "../Voicemail/Setting.vue";
import Info from "../Lists/Info.vue";


export default {
  name: "NumberSettingForUserModal",
  components: { 
    MusicOnHoldWidget,
    ScheduleSetting,
    CallForwardingSetting,
    VoicemailSetting,
    Info
  },
  props: {
    modalName: {
      type: String,
      default: 'NumberSettingForUserModal'
    },
  },
  data() {
    return {
      data: this.$helperFunction.stateInstance({
        data: {
          ringgroupid: '',
          members: [],
          number : ''
        }
      }),
    };
  },
  computed: {
    ringgroupAccountcode(){ return this.data?.ringgroupid },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.ringgroupid = event?.params?.ringgroupid || '';
      this.data.members = event?.params?.members || [];
      this.data.number = event?.params?.number || '';
    },
    onBeforeClose(){
      this.data.reset()
    },
  },
};
</script>
