<template>
  <div 
    :class="{
      'recording-started': recorded.audio.is_start,
      'not-hover': conditions.screen!=screens.select,
    }"
  >
    <input ref="upload-input" type="file" accept="audio/mpeg, mp3/wav" @change="selectFile(getProperty($event,'target.files[0]'))" v-show="false" />
    <!-- first step -->
    <div v-if="conditions.screen==screens.select">
      <div  class="d-flex align-items-center">
        <b-button :disabled="disabled" v-if="allow_recording" class="blueRecordingButton mr-12px" @click="recorded.audio.is_start=true;">
          <vb-icon icon="recordModal-record-icon" class="recordIcon" width="38px" height="38px"/>
          Record
        </b-button>
        <b-button :disabled="disabled" v-if="allow_sound_library" class="whiteRecordingButton mr-12px" @click="$modal.show(`${_uid}-AudioInputComponent`,{
          group: 'announcement'
        })">
          <vb-icon icon="recordModal-library-icon" class="uploadIcon" width="38px" height="38px"/>
          Sound library
        </b-button>
        <b-button :disabled="disabled" class="whiteRecordingButton" @click="$refs['upload-input'].click()">
          <vb-icon icon="recordModal-upload-icon" class="uploadIcon" width="38px" height="38px"/>
          Upload
        </b-button>
      </div>
      <div class="RecordingLikeSomething-descriptionText mt-16px">
        <span v-if="allow_recording && show_Text">Maximum length for recording is 60 seconds.</span> Maximum file size 5MB
      </div>
      <!-- <div 
        class="uploadSoundFileDesign-latest mt-20px" 
        @dragenter.prevent="disabled ? '' : conditions.drag_and_drop=true"
      >
        <div 
          v-if="conditions.drag_and_drop" 
          class="chat-drag-and-drop" 
          @dragover.prevent="conditions.drag_and_drop=true" 
          @dragenter.prevent="conditions.drag_and_drop=true" 
          @dragleave.prevent="conditions.drag_and_drop=false" 
          @drop.prevent="conditions.drag_and_drop=false;selectFile(getProperty($event,'dataTransfer.files[0]'))"
        >
          Drag and Drop
        </div>
        <template v-else>
          <div class="d-flex justify-content-center w-100">
            <vb-icon 
              icon="uploadSoundFileWithDragAndDrop-icon" 
              width="89.326px" 
              height="102.189px" 
            />
          </div>
          <div class="uploadSoundFileDesign-heading mt-20px">
            Drop your file here, or 
            <span class="bluer">Browse</span>
          </div>
          <div class="uploadSoundFileDesign-text mt-10px">
            5 KB-5 MB, MP3 / WAV
          </div>
        </template>
      </div> -->
    </div>
    <!-- second step -->
    <div v-if="conditions.screen==screens.before_recording">
      <div class="d-flex align-items-center">
        <b-button :disabled="disabled" class="blueRecordingButton mr-12px wd-40" @click="recorded.audio.is_start=true;">
          <vb-icon icon="recordModal-record-icon" class="recordIcon" width="38px" height="38px"/>
        </b-button>
        <b-button :disabled="disabled" class="whiteCancelButton" @click="conditions.screen=screens.select;">
          <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
          Cancel
        </b-button>
      </div>
      <div v-if="show_Text" class="RecordingLikeSomething-descriptionText mt-16px">
        Press the microphone button when ready to record
      </div>
      <!-- <b-button @click="recorded.audio.is_start=true;">Start Recording</b-button>
      <b-button @click="conditions.screen=screens.select;">Cancel</b-button> -->
    </div>
    <!-- third step -->
    <div v-if="conditions.screen==screens.recording">
      <div class="d-flex align-items-center">
        <div class="divLike-blueRecordingButton mr-12px wd-40">
          <vb-icon icon="recordModal-stopRecording-icon" class="recordIcon" width="38px" height="38px" @click="recorded.audio.is_start=false"/>
          <div :id="`${_uid}-frequency-wave`" class="frequency-wave">
            <div v-for="(data,index) in waves" :key="index" :id="`${_uid}-frequency-wave-${index}`" :data-value="data" />
          </div>
        </div>
        <b-button :disabled="disabled" class="whiteCancelButton" @click="conditions.screen=screens.select;recorded.audio.is_start=false;">
          <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
          Cancel
        </b-button>
      </div>
      <div v-if="show_Text" class="RecordingLikeSomething-descriptionText mt-16px">
        Maximum length for recording is 60 seconds. Maximum file size 5MB
      </div>
      <!-- <b-button @click="recorded.audio.is_start=false">Stop Recording</b-button>
      <div :id="`${_uid}-frequency-wave`" class="frequency-wave">
        <div v-for="(data,index) in waves" :key="index" :id="`${_uid}-frequency-wave-${index}`" :data-value="data" />
      </div>
      <b-button @click="conditions.screen=screens.select;recorded.audio.is_start=false;">Cancel</b-button> -->
    </div>
    <!-- fourth step -->
    <div v-if="conditions.screen==screens.after_select_file">
      <div class="d-flex align-items-center">
        <vb-audio-player class="mr-12px wd-40" :src="audio.file | fileToSrc" :design="'blueAudio'" />
        <!-- <div class="divLike-blueRecordingButton mr-12px wd-40">
          <vb-audio-player :src="audio.file | fileToSrc" />
        </div> -->
        <b-button :disabled="disabled" class="GreenSaveButton mr-12px" @click="save()">
          <vb-icon icon="recordModal-save-icon" class="recordIcon" width="38px" height="38px"/>
          Save
        </b-button>
        <b-button :disabled="disabled" class="whiteCancelButton" @click="audio.file=null;conditions.screen=screens.select;">
          <vb-icon icon="recordModal-cancel-icon" class="uploadIcon" width="38px" height="38px"/>
          Cancel
        </b-button>
      </div>
      <div v-if="show_Text" class="RecordingLikeSomething-descriptionText mt-16px">
        Review your recorded audio
      </div>
    </div>
    <!-- fifth step -->
    <div v-if="conditions.screen==screens.after_save">
      <div class="d-flex align-items-center">
        <vb-audio-player class="mr-12px w-auto" :src="audio.file | fileToSrc" :design="'blueAudio'" />
        <!-- <div class="divLike-blueRecordingButton mr-12px w-auto">
          <vb-audio-player :src="audio.file | fileToSrc" />
        </div> -->
        <b-button :disabled="disabled" v-if="allow_recording" class="redRedoButton mr-12px" @click="recorded.audio.is_start=true;">
          <vb-icon icon="recordModal-redo-icon" class="recordIcon" width="38px" height="38px"/>
          redo
        </b-button>
        <b-button :disabled="disabled" class="whiteRecordingButton" @click="$refs['upload-input'].click();">
          <vb-icon icon="recordModal-upload-icon" class="uploadIcon" width="38px" height="38px"/>
          Upload
        </b-button>
      </div>
      <div v-if="show_Text" class="RecordingLikeSomething-descriptionText mt-16px">
        Maximum length for recording is 60 seconds. Maximum file size 5MB
      </div>
      <!-- <div 
        class="uploadSoundFileDesign-latest mt-20px" 
        @dragenter.prevent="disabled ? '' : conditions.drag_and_drop=true"
      >
        <div 
          v-if="conditions.drag_and_drop" 
          class="chat-drag-and-drop" 
          @dragover.prevent="conditions.drag_and_drop=true" 
          @dragenter.prevent="conditions.drag_and_drop=true" 
          @dragleave.prevent="conditions.drag_and_drop=false" 
          @drop.prevent="conditions.drag_and_drop=false;selectFile(getProperty($event,'dataTransfer.files[0]'))"
        >
          Drag and Drop
        </div>
        <template v-else>
          <div class="d-flex justify-content-center w-100">
            <vb-icon 
              icon="uploadSoundFileWithDragAndDrop-icon" 
              width="89.326px" 
              height="102.189px" 
            />
          </div>
          <div class="uploadSoundFileDesign-heading mt-20px">
            Drop your file here, or 
            <span class="bluer">Browse</span>
          </div>
          <div class="uploadSoundFileDesign-text mt-10px">
            5 KB-5 MB, MP3 / WAV
          </div>
        </template>
      </div> -->
      <!-- <vb-audio-player :src="audio.file | fileToSrc" />
      <b-button @click="conditions.screen=screens.before_recording;">Redo</b-button>
      <b-button @click="$refs['upload-input'].click();">Upload</b-button> -->
    </div>
    <RecordingListSelect :modalName="`${_uid}-AudioInputComponent`" @selectedRecordings="onSelectedLibrary($event)" :is_single_select="true" />
  </div>
</template>

<script>
import { $fn } from '@/utils';
import RecordingListSelect from "../Modals/RecordingListSelect.vue";
let audio_recording_interval_id;
const frequency_data_map = [15,13,10,8,5,2,1,0,1,2,5,8,10,13,15];
const screens = {
  after_save: 'after_save',
  after_select_file: 'after_select_file',
  recording: 'recording',
  before_recording: 'before_recording',
  select: 'select',
}
export default {
  name: 'AudioFileInput',
  components: {
    RecordingListSelect,
  },
  inject: [
    'getProperty','appNotify'
  ],
  props: {
    disabled: {
      type: Boolean,
      default: false,
    },
    allow_recording: {
      type: Boolean,
      default: true,
    },
    allow_sound_library: {
      type: Boolean,
      default: false,
    },
    show_Text: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {
      recorded: {
        audio: {
          is_start: false,
          time: 0,
          recorder: null,
          audio: {},
          reset(){
            this.is_start=false
            this.time=0
            this.recorder=null
            this.audio={}
          },
        },
      },
      audio: {
        file: null,
        type: 'upload',
      },
      conditions: {
        screen: screens.select,
        drag_and_drop: false,
      },
    }
  },
  computed: {
    waves(){ return frequency_data_map },
    screens(){ return screens },
  },
  watch: {
    async "recorded.audio.is_start"(is_start) {
      let vm = this;
      function visulizing(frequency_array){
        const values = Object.values(frequency_array);
        frequency_data_map.forEach((val,i)=>{
          // const value = (values[val] || 15)/255;
          const el = document.getElementById(`${vm._uid}-frequency-wave-${i}`)
          if(el){
            // el.style.transform = `scaleY(${value})`;
            // el.style.opacity = Math.max(.25,value);
            // el.style.height = `calc(${25 * value}px + 2px)`;
            el.style.height = `calc(${values[val]/4}px - 16px)`;
          }
        })
      }
      if (is_start) {
        this.recorded.audio.recorder = await $fn.recordAudio({
          visulizing,
          blob: {
            type: 'audio/mp3',
            name: 'Recording.mp3',
          },
        });
        this.recorded.audio.time = 0;
        this.recorded.audio.recorder.start();
        audio_recording_interval_id = setInterval(() => {
          if(vm.recorded.audio.time>60){
            vm.recorded.audio.is_start=false
          } else {
            vm.recorded.audio.time = vm.recorded.audio.time + 1;
          }
        }, 1 * 1000);
        this.conditions.screen=screens.recording
      } else if(this.recorded.audio.recorder) {
        const audio = await this.recorded.audio.recorder.stop();
        visulizing({})
        clearInterval(audio_recording_interval_id);
        if(this.conditions.screen!=screens.select){
          this.audio.file = audio.audioBlob
          this.audio.type='recorded'
          this.conditions.screen=screens.after_select_file
        }
      }
    },
    "conditions.screen"(screen){
      if(screen==screens.select) {
        // console.log({
        //   el: this.$refs['upload-input']
        // })
        this.$refs['upload-input'].value=''
      }
    },
    "allow_recording"(){
      this.conditions.screen=screens.select
      this.recorded.audio.is_start=false
      this.audio.file = null
      this.audio.type='upload'
    },
  },
  filters: {
    fileToSrc(file){
      if(file.sound_file) return file.sound_file
      return URL.createObjectURL(file);
    },
  },
  methods: {
    save(){
      let vm = this
      this.$emit('change',{
        file: this.audio.file,
        type: this.audio.type,
        complete(){
          vm.conditions.screen=screens.after_save;
        },
        reset(){
          vm.conditions.screen=screens.select;
        },
      });
    },
    onSelectedLibrary(Event){
      this.audio.file=Event
      this.audio.type='library'
      this.conditions.screen=screens.after_select_file
    },
    selectFile(file) {
      const max = 5 * 1024 * 1024; // 5 MB
      const min = 5 * 1024; // 5 kb
      if(!file) return;
      if(!file.type.includes('audio')){
        this.appNotify({
          message: 'File should be audio',
          type: 'danger',
        })
        return;
      } else if(!['audio/mpeg','audio/wav'].includes(file.type)){
        this.appNotify({
          message: 'File can be mpeg or wav',
          type: 'danger',
        })
        return;
      } else if(file?.size > max){
        this.appNotify({
          message: 'File can be maximum 5MB',
          type: 'danger',
        })
        return;
      } else if(file?.size < min){
        this.appNotify({
          message: 'File should be minimum 5kb',
          type: 'danger',
        })
        return;
      }
      this.audio.file=file
      this.audio.type='upload'
      this.conditions.screen=screens.after_select_file
    },
  },
  beforeDestroy(){
    if(this.recorded.audio.is_start) this.recorded.audio.recorder?.stop?.();
  },
}
</script>

<style>

</style>