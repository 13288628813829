var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('svg', {
    attrs: {
      "width": _vm.width || _vm.size,
      "height": _vm.height || _vm.size,
      "viewBox": _vm.iconObj.viewBox,
      "fill": _vm.iconObj.fill,
      "stroke-width": _vm.iconObj['stroke-width'],
      "stroke-linecap": _vm.iconObj['stroke-linecap'],
      "stroke-linejoin": _vm.iconObj['stroke-linejoin'],
      "stroke": _vm.iconObj.stroke
    },
    domProps: {
      "innerHTML": _vm._s(_vm.iconObj.icon)
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      },
      "mouseover": function ($event) {
        return _vm.$emit('mouseover');
      },
      "mouseleave": function ($event) {
        return _vm.$emit('mouseleave');
      }
    }
  });

}
var staticRenderFns = []

export { render, staticRenderFns }