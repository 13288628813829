<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.user.send?'':$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Add User By Email</div>
        <div class="plus-IOSIcon-container" @click="$modal.show('CheckedSlot',{ service: 'user', onProceed: adduser })">
          <div class="textInsideCard onRightSide primaryColor">Detail</div>
        </div>
      </div>
      <div class="innerModalIos">

        <form class="dialer-form lg-mar-bottom" @submit.prevent="adduser()">
          <b-alert class="mt-32px" :show="!!api.user.error_message" variant="danger">{{api.user.error_message}}</b-alert>
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>First Name</label>
                <input 
                  placeholder="Enter First Name" 
                  :maxlength="$v.forms.user.firstname.$params.maxLength.max" 
                  class="w-100" 
                  type="text" 
                  :disabled="api.user.send" 
                  v-model="forms.user.firstname" 
                />
                <p 
                  v-if="(forms.user.submitted && $v.forms.user.firstname.$invalid) || api.user.validation_errors.username || api.user.validation_errors.firstname" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.user.firstname.required">* First Name is required</span>
                  <span v-if="!$v.forms.user.firstname.alpha">* First Name should be onlu alphabet character only</span>
                  <span v-else-if="!$v.forms.user.firstname.minLength">* First Name should be minimum {{ $v.forms.user.firstname.$params.minLength.min }} character</span>
                  <span v-else-if="!$v.forms.user.firstname.maxLength">* First Name can be maximum {{ $v.forms.user.firstname.$params.maxLength.max }} character</span>
                  <span v-for="(em, i) in api.user.validation_errors.firstname" :key="i">* {{em}}</span>
                </p>
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Last Name</label>
                <input 
                  placeholder="Enter Last Name" 
                  class="w-100" 
                  type="text" 
                  :maxlength="$v.forms.user.lastname.$params.maxLength.max" 
                  :disabled="api.user.send" 
                  v-model="forms.user.lastname" 
                />
                <p 
                  v-if="(forms.user.submitted && $v.forms.user.lastname.$invalid) || api.user.validation_errors.username || api.user.validation_errors.lastname" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.user.lastname.required">* Last Name is required</span>
                  <span v-if="!$v.forms.user.lastname.alpha">* Last Name should be onlu alphabet character only</span>
                  <span v-else-if="!$v.forms.user.lastname.minLength">* Last Name should be minimum {{ $v.forms.user.lastname.$params.minLength.min }} character</span>
                  <span v-else-if="!$v.forms.user.lastname.maxLength">* Last Name can be maximum {{ $v.forms.user.lastname.$params.maxLength.max }} character</span>
                  <span v-for="(em, i) in api.user.validation_errors.lastname" :key="i">* {{em}}</span>
                </p>
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Email</label>
                <input placeholder="Enter Email" class="w-100" type="text" :disabled="api.user.send" v-model="forms.user.email" />
                <p 
                  v-if="(forms.user.submitted && $v.forms.user.email.$invalid) || api.user.validation_errors.username || api.user.validation_errors.email" 
                  class="text text-danger animated bounceIntop"
                >
                  <span v-if="!$v.forms.user.email.required">* Email is Required</span>
                  <span v-else-if="!$v.forms.user.email.email">* Email is not valid</span>
                  <span v-else-if="!$v.forms.user.email.minLength">* Email should be minimum {{ $v.forms.user.email.$params.minLength.min }} character</span>
                  <span v-for="(em, i) in api.user.validation_errors.username" :key="i">* {{em}}</span>
                  <span v-for="(em, i) in api.user.validation_errors.email" :key="i">* {{em}}</span>
                </p>
              </div>
            </div>
            <div class="basicWhiteIOScard-item">
              <div class="d-flex align-items-start">
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">Set as admin</div>
                </div>
              </div>
              <div class="position-relative">
                <b-form-checkbox class="dialer-switch IosSwitch" :disabled="api.user.send" v-model="forms.user.role" switch />
              </div>
              <p 
                v-if="(forms.user.submitted && $v.forms.user.role.$invalid) || api.user.validation_errors.role" 
                class="text text-danger animated bounceIntop"
              >
                <span v-if="!$v.forms.user.role.required">* Email is Required</span>
                <span v-for="(em, i) in api.user.validation_errors.role" :key="i">* {{em}}</span>
              </p>
            </div>
          </div>
          <button class="IosFullWidthButton forDone mb-16px" :disabled="api.user.send">
            <vb-spinner v-if="api.user.send" />
            <template v-else>Add</template>
          </button>
        </form>

      </div>
    </modal>
  </div>
</template>

<script>
import { required, minLength, maxLength, email, alpha } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { VOIP_API } from "@/utils/index";

export default {
  name: "AddUserByEmailModal",
  props: {
    modalName: {
      type: String,
      default: 'AddUserByEmail',
    },
  },
  data() {
    return {
      forms: {
        user: this.$helperFunction.formInstance({
          data: {
            username: '',
            email: '',
            lastname: '',
            firstname: '',
            number: '',
            password: '',
            role: false,
          },
        }),
      },
      api: {
        user: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
      },
      conditions: {
        user_password_show: false,
        reset(){
          this.user_password_show = false
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
  },
  validations: {
    forms: {
      user: {
        email: {
          required, 
          email,
          minLength: minLength(4), 
        },
        lastname: { 
          required,
          minLength: minLength(3), 
          maxLength: maxLength(10),
          alpha,
        },
        firstname: {
          required, 
          minLength: minLength(3), 
          maxLength: maxLength(10),
          alpha,
        },
        role: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      //TODO
    },
    onBeforeClose(){
      this.forms.user.reset()
      this.api.user.reset()
      this.conditions.reset()
    },
    adduser(){
      let vm = this
      vm.forms.user.submitted=true
      vm.$v.forms.user.$touch()
      if(vm.api.user.send || vm.$v.forms.user.$invalid) return;
      vm.api.user.validation_errors={}
      vm.api.user.error_message=''
      vm.api.user.send=true
      VOIP_API.endpoints.users.create({
        username: vm.forms.user.email,
        email: vm.forms.user.email,
        lastname: vm.forms.user.lastname,
        firstname: vm.forms.user.firstname,
        role: vm.forms.user.role ? 1 : 0,
        accountcode: vm.getCurrentUser?.account,
        service: "user",
        action: 'invite'
      })
      .then(()=>{
        vm.$emit("user-added");
        vm.$modal.hide(vm.modalName)
      })
      .catch((ex)=>{
        vm.api.user.validation_errors=ex.own_errors || {}
        vm.api.user.error_message=ex.own_message || ''
      })
      .finally(()=>{
        vm.api.user.send=false
        vm.forms.user.submitted=false
      })
    },
  },
};
</script>


<style>
.slide-ltr-enter-active {
  animation: slide-ltr 0.5s reverse;
}
.slide-ltr-leave-active {
  animation: slide-ltr 0.5s;
}
@keyframes slide-ltr {
  0% {
    transform: translateX(0%);
  }
  100% {
    transform: translateX(100%);
  }
}
</style>