var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm._m(0), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" This page lists the transactions for time off that you have submitted. ")]), _c('div', {
    staticClass: "dropdownsContainer"
  }, [_c('div', {
    staticClass: "innerShadowInput-container w-auto mr-32px"
  }, [_c('label', [_vm._v("Employee")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd big",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "nameWithImage insideDropdown"
        }, [_c('div', {
          staticClass: "imageContainer"
        }, [_c('img', {
          staticClass: "circle",
          attrs: {
            "src": require('@/assets/images/credit-card/1.jpeg')
          }
        })]), _c('div', {
          staticClass: "nameWithImage-text"
        }, [_vm._v("Mellissa")])]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_c('div', {
    staticClass: "nameWithImage insideDropdown"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/1.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Mellissa")])])]), _c('b-dropdown-item', [_c('div', {
    staticClass: "nameWithImage insideDropdown"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/2.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Mellissa")])])]), _c('b-dropdown-item', [_c('div', {
    staticClass: "nameWithImage insideDropdown"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/3.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Mellissa")])])])], 1)], 1), _c('div', {
    staticClass: "innerShadowInput-container w-auto"
  }, [_c('label', [_vm._v("Export time unit")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd big",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("days")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" days ")])], 1)], 1)]), _c('div', {
    staticClass: "TimeOffLedgerTable mt-16px"
  }, [_vm._m(1), _c('vb-table', {
    staticClass: "HrmTable",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filterTimeOffLedger),
      "listLength": _vm.filterTimeOffLedger.length,
      "loading": _vm.api.time_off_ledger.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterTimeOffLedger, function (data, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: data.user,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "nameWithImage"
          }, [_c('div', {
            staticClass: "imageContainer"
          }, [_c('img', {
            attrs: {
              "src": require('@/assets/images/credit-card/1.jpeg')
            }
          })]), _c('div', {
            staticClass: "nameWithImage-text"
          }, [_vm._v(_vm._s(data.name))])])]), _c('td', {
            staticClass: "dialer-row-title two"
          }, [_vm._v(_vm._s(data.policy))]), _c('td', {
            staticClass: "dialer-row-title three"
          }, [_vm._v(_vm._s(data.action))]), _c('td', {
            staticClass: "dialer-row-title four"
          }, [_vm._v(" " + _vm._s(data.days) + " ")]), _c('td', {
            staticClass: "dialer-row-title five"
          }, [_vm._v(" " + _vm._s(data.note) + " ")]), _c('td', {
            staticClass: "dialer-row-title six"
          }, [_vm._v(" " + _vm._s(data.created_at) + " ")])]);
        });
      }
    }])
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Time Off Ledger Report "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0"
  }, [_vm._v(" Record new transaction ")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "HrmTableHead-container"
  }, [_c('div', {
    staticClass: "HrmTableHead one"
  }, [_vm._v("Employee")]), _c('div', {
    staticClass: "HrmTableHead two"
  }, [_vm._v("Policy")]), _c('div', {
    staticClass: "HrmTableHead three"
  }, [_vm._v("Action")]), _c('div', {
    staticClass: "HrmTableHead four"
  }, [_vm._v("Days")]), _c('div', {
    staticClass: "HrmTableHead five"
  }, [_vm._v("Note")]), _c('div', {
    staticClass: "HrmTableHead six"
  }, [_vm._v("Created at")])]);

}]

export { render, staticRenderFns }