var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      }
    }
  }, [_vm.conditions.screen == 1 ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.create.send ? '' : _vm.conditions.screen = 0;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Add Domains")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Domains")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = 1;
      }
    }
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    }
  })], 1)]), _c('div', {
    staticClass: "innerModalIos"
  }, [_vm.conditions.screen == 1 ? [_c('div', {}, [_c('b-form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.create();
      }
    }
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Domain")]), _c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "url",
      "placeholder": "Enter URL",
      "disabled": _vm.api.create.send || _vm.api.check.send
    },
    model: {
      value: _vm.forms.create.url,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create, "url", $$v);
      },
      expression: "forms.create.url"
    }
  }), _vm.forms.create.submitted && _vm.$v.forms.create.url.$invalid || _vm.api.check.validation_errors.domain || _vm.api.check.validation_errors.root_service_id ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create.url.required ? _c('span', [_vm._v("* URL is required")]) : !_vm.$v.forms.create.url.url ? _c('span', [_vm._v("* URL is invalid")]) : _vm._e(), _vm._l(_vm.api.check.validation_errors.domain, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  }), _vm._l(_vm.api.check.validation_errors.root_service_id, function (em) {
    return _c('span', {
      key: em
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()], 1)])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "type": "button",
      "variant": "success",
      "disabled": _vm.api.create.send || _vm.api.check.send
    },
    on: {
      "click": function ($event) {
        return _vm.check();
      }
    }
  }, [_vm.api.check.send ? _c('vb-spinner') : [_vm._v("Check")]], 2), _c('button', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    attrs: {
      "variant": "success",
      "disabled": _vm.api.create.send || _vm.api.check.send
    }
  }, [_vm.api.create.send ? _c('vb-spinner') : [_vm._v("Add")]], 2)])], 1)] : [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.response.domains, function (domain, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item d-flex justify-content-between",
      on: {
        "click": function ($event) {
          return _vm.setUpdateId(_vm.tag);
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(domain.route))])]);
  }), !_vm.response.domains.length ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.domains.send ? '' : 'There is no Tags',
      "design": 3
    }
  }, [_vm.api.domains.send ? _c('vb-loading', {
    staticClass: "text-center w-100",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }