<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
        </div>
        <div class="dialer-settings-title topMainHeading">Email Groups</div>
        <div class="plus-IOSIcon-container">
        </div>
      </div>
      <div class="innerModalIos">
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-form-input placeholder="Search Group" v-model="filter.groups.search" onkeypress="return /([0-9])/i.test(event.key)" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
        <div class="basicWhiteIOScard mt-16px mb-16px">
          <div 
            class="basicWhiteIOScard-item" 
            v-for="group in filterGroups" 
            :key="group.id" 
            @click="
              api.add.send ? '' :
              selected.groups.includes(group.id) ? 
              selected.groups.splice(selected.groups.indexOf(group.id), 1) : 
              selected.groups.push(group.id)
            "
          >
            <div class="headingInsideTable">{{ group.label }}</div>
            <div class="d-flex align-items-center">
              <div class="d-flex align-items-center forRadio-container">
                <vb-icon v-if="selected.groups.includes(group.id)" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />   
              </div>
            </div>
          </div>
          <vb-no-record v-if="!filterGroups.length" :text="api.list.send?'':'There is no Numbers'" :design="3">
            <vb-loading v-if="api.list.send" slot="loading" />
          </vb-no-record>
        </div>
        <b-button 
          class="IosFullWidthButton forDone mt-16px mb-16px" 
          v-if="selected.groups.length" 
          :disabled="api.add.send" 
          @click="add()"
        >
          Add Groups
        </b-button>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API, } from "@/utils";
import { mapGetters } from 'vuex';
export default {
  name: "EmailGroupsModal",
  props: {
    modalName: {
      type: String,
      default: "EmailGroups",
    },
  },
  data() {
    return {
      api: {
        add: this.$helperFunction.apiInstance({  }),
        list: this.$helperFunction.apiInstance({  }),
      },
      filter: {
        groups: {
          search: "",
          reset() {
            this.search = "";
          },
        },
      },
      response: {
        groups: [],
        reset() {
          this.groups = [];
        },
      },
      selected: {
        groups: [],
        reset() {
          this.groups = [];
        },
      },
      data: {
        accountcode: "",
        reset() {
          this.accountcode = "";
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
    filterGroups() {
      const search = `${this.filter.groups.search}`.trim().toLowerCase();
      return this.response.groups.filter((group) => `${group.label}`.replace(/-/g,'').trim().toLowerCase().includes(search));
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.accountcode = event?.params?.accountcode || "";
      this.fetchGroups();
    },
    onBeforeClose() {
      this.response.reset();
      this.selected.reset();
      this.filter.groups.reset();
      this.data.reset();
    },
    add() {
      let vm = this
      if (vm.api.add.send) return;
      vm.api.add.send = true;
      VOIP_API.endpoints.user.updateUserEmailGroups({
        uid: vm.getCurrentUser?.uid,
        accountcode: vm.data.accountcode,
        groups: vm.selected.groups,
      })
      .then(() => {
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        vm.$emit('added'),{
          selected_list: vm.selected.groups,
        };
        vm.$modal.hide(vm.modalName);
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.add.send=false
      });
    },
    fetchGroups() {
      let vm = this
      vm.api.list.send = true;
      VOIP_API.endpoints.user.groups()
      .then(({ data: { data: groups } })=>{
        vm.response.groups=groups
        return VOIP_API.endpoints.user.usergroups({
          id: vm.data.accountcode,
          uid: vm.getCurrentUser?.uid,
        })
      })
      .then(({ data: groups }) => {
        vm.selected.groups = groups?.map?.(group=>group.id);
      })
      .finally(()=>{
        vm.api.list.send = false;
      });
    },
  },
};
</script>
