<template>
  <div class="dialer-settings-main">
    <div v-if="conditions.change_pass" class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="api.change_pass.send ? '' : conditions.change_pass=false">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Profile</div>
      <div class="d-flex justify-content-end align-items-center" style="width:74px;"></div>
    </div>
    <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Profile</div>
      <div class="d-flex justify-content-end align-items-center" style="width:74px;"></div>
    </div>

    
    <section class="dialer-profile-setting v2 dialer-settings-section">
      
      <template v-if="conditions.change_pass">
        

        <form @submit.prevent="changepassword()" >
          <div class="basicWhiteIOScard mt-32px">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS iconOnRight InputIosV2">
                <label>Current password</label>
                <div class="position-relative">
                  <input 
                    class="touched py-0" 
                    :type="password_show.current ? 'text' : 'password'" 
                    name="currentpassword" 
                    :disabled="api.change_pass.send" 
                    label="Current password" 
                    v-model="forms.change_pass.cpassword" 
                  />
                  <vb-icon 
                    width="16.33px" 
                    height="14px" 
                    class="mobileInputIOS-icon" 
                    :icon="password_show.current ? 'profileSettings-eyeOff-icon' : 'profileSettings-eye-icon'" 
                    @click="password_show.current = !password_show.current" 
                  />
                </div>
                <small v-if="forms.change_pass.submitted && $v.forms.change_pass.cpassword.$invalid" class="text text-danger animated bounceIntop">
                  <span>*</span>
                  <span v-if="!$v.forms.change_pass.cpassword.required">Current Password is required</span>
                </small>
              </div>
            </div>
            <div class="basicWhiteIOScard-item flex-column">
              <div class="mobileInputIOS iconOnRight InputIosV2">
                <label>New password</label>
                <div class="position-relative">
                  <input 
                    class="py-0" 
                    :type="password_show.new ? 'text' : 'password'" 
                    :disabled="api.change_pass.send" 
                    v-model="forms.change_pass.password" 
                    name="password" 
                    label="New password" 
                  />
                  <vb-icon 
                    class="mobileInputIOS-icon" 
                    width="16.33px" 
                    height="14px" 
                    :icon="password_show.new ? 'profileSettings-eyeOff-icon' : 'profileSettings-eye-icon'" 
                    @click="password_show.new = !password_show.new" 
                  />
                </div>
                <small v-if="forms.change_pass.submitted && $v.forms.change_pass.password.$invalid" class="text text-danger animated bounceIntop">
                  <span>*</span>
                  <span v-if="!$v.forms.change_pass.password.required">New Password is required</span>
                  <span v-else-if="!$v.forms.change_pass.password.minLength">New Password is minimum 8 character required</span>
                  <span v-else-if="!$v.forms.change_pass.password.alalphabet">New Password must contain alphabet</span>
                  <span v-else-if="!$v.forms.change_pass.password.numbers">New Password must contain numbers</span>
                </small>
              </div>
            </div>
          </div>
          <div class="textAboveCard">
            Must be least 8 characters and contain letters and numbers
          </div>
          <button :disabled="api.change_pass.send" class="IosFullWidthButton forDone mb-16px">
            <vb-spinner v-if="api.change_pass.send" />
            <template v-else>Save Changes</template>
          </button>
        </form>

      </template>
      <template v-else>

        <!-- image uploader -->
        <div class="profile-image-section-container forIos mt-32px">
          <div 
            class="profile-image-section" 
            @click="$modal.show('UserProfileImageUploader', { type: 'USER', accountcode: getCurrentUser.account, current_image: getCurrentUser.profileImg })"
          >
            <div class="profile-img">
              <img style="height: 100%; width: 100%" id="user-profile-image-updated" :src="getCurrentUser.profileImg" @error="$event.target.src=local_filess.images.user_male" :alt="getCurrentUser.display_name" />
            </div>
          </div>
        </div>
        <div class="BiggestMainHeading mt-22px">{{getCurrentUser.display_name}}</div>
        <!-- general -->
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2">
              <label >Email address</label>
              <div>
                <input type="email" class="py-0" disabled name="email" label="Email address" :value="getCurrentUser.email" />
              </div>
            </div>
          </div>
          <form @submit.prevent="''">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS iconOnRight InputIosV2">
                <label>Password</label>
                <div>
                  <input class="py-0" type="password" name="displayPassword" label="Password" placeholder="••••••••" @focus="conditions.change_pass=true" />
                </div>
              </div>
            </div>
          </form>
        </div>
        <!-- tfa -->
        <div class="basicWhiteIOScard">
          <div class="basicWhiteIOScard-item">
            <div class="headingInsideTable">Two Factor Authentication</div>
            <div class="position-relative">
              <b-check ref="two_factor_auth_checkbox" class="dialer-switch IosSwitch" :disabled="api.update_two_factor_auth.send" switch :checked="tfaEnabled" @change="changeTwoFactorAuth($event?'yes':'no');" />
            </div>
          </div>
          <template v-if="tfaEnabled">
            <div class="basicWhiteIOScard-item">
              <div class="mobileInputIOS InputIosV2">
                <label>Select</label>
                <b-dropdown no-caret ref="dropdown" class="">
                  <template #button-content>
                    <span class="d-flex align-items-center justify-content-between p-0 w-100">
                      <span>
                        <template v-if="selected.two_fact_auth === 'yes'">All Devices</template>
                        <template v-else-if="selected.two_fact_auth === 'non_trusted'">Un-Trusted Devices</template>
                        <template v-else>Select Options</template>
                      </span>
                      <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow down" width="16.642" height="15.275" />
                    </span>
                  </template>
                  <b-dropdown-item :disabled="api.update_two_factor_auth.send" @click="changeTwoFactorAuth('yes')">
                    All Devices
                  </b-dropdown-item>
                  <b-dropdown-item :disabled="api.update_two_factor_auth.send" @click="changeTwoFactorAuth('non_trusted')">
                    Un-Trusted Devices
                  </b-dropdown-item>
                </b-dropdown>
              </div>
            </div>
          </template>
        </div>
        <!-- teams -->
        <div class="textAboveCard">Teams</div>
        <div class="basicWhiteIOScard">
          <div class="basicWhiteIOScard-item" v-for="team in teams" :key="team.real_id">
            <Info class="InfoComponentInsideAnroidIOSApp" :id="team.real_id" />
          </div>
        </div>
        <!-- call queues -->
        <template v-if="callQueues.length > 0">
          <div class="textAboveCard">Call Queues</div>
          <div class="basicWhiteIOScard">
            <div class="basicWhiteIOScard-item" v-for="call_queue in callQueues" :key="call_queue.voipaccount">
              <Info class="InfoComponentInsideAnroidIOSApp" :id="call_queue.voipaccount" />
            </div>
          </div>
        </template>
        <!-- organizations -->
        <div class="textAboveCard">Organizations</div>
        <div class="basicWhiteIOScard">
          <div class="basicWhiteIOScard-item" v-for="organization in filterOrganizations" :key="organization.id">
            <div class="d-flex align-items-center">
              <div class="basicIOSIcon mr-16px">
                <img 
                  :src="
                    organization.id === $store.getters.getCurrentUser.selected_organization_id ? 
                      $store.getters.getCurrentUser.companyLogo : 
                      `${organizationimagespath}${organization.logo}` || ''" 
                    @error="$event.target.src=require('@/assets/images/user-male-icon.svg')"
                />
              </div>
              <div class="d-flex flex-column">
                <div class="headingInsideTable">
                  {{ `${organization.label} (${organization.uid})` }}
                </div>
              </div>
            </div>
            <vb-icon v-if="organization.id === $store.getters.getCurrentUser.selected_organization_id" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
          </div>
        </div>
        <!-- get free credit -->
        <div class="textAboveCard">Get a free credit</div>
        <div class="basicWhiteIOScard">
          <div class="basicWhiteIOScard-item" >
            <div class="d-flex">
              <div class="d-flex flex-column">
                <div class="headingInsideTable">
                  Reference Code
                </div>
                <div class="linkTypeText">
                  {{ link }}
                </div>
              </div>
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
        </div>
      </template>
      
    </section>
    
    <ProfileImageUploaderModal :modalName="'UserProfileImageUploader'" @image-uploaded="''" />
  </div>
</template>

<script>
import ProfileImageUploaderModal from '../modals/common/ProfileImageUploader.vue'
import Info from '../../Lists/Info.vue';
import { required, minLength,  } from "vuelidate/lib/validators";
import { events, VOIP_API } from "../../../utils";
import { mapGetters } from 'vuex';
let expected_tfa = ['no','yes','non_trusted']
export default {
  name: 'Profile',
  components: {
    ProfileImageUploaderModal,
    Info,
  },
  inject:['isEmpty','local_filess','appNotify'],
  data() {
    return {
      filter: {
        organizations: {
          search: '',
        },
      },
      password_show: {
        current: false,
        new: false,
      },
      forms: {
        change_pass: this.$helperFunction.formInstance({
          data: {
            cpassword: '',
            password: '',
          }
        }),
      },
      api: {
        trusted_devices: this.$helperFunction.apiInstance(),
        delete_trusted_device: this.$helperFunction.apiInstance({ send: [] }),
        change_pass: this.$helperFunction.apiInstance({ validation_errors: true }),
        update_two_factor_auth: this.$helperFunction.apiInstance(),
      },
      response: {
        trusted_devices: [],
      },
      conditions: {
        change_pass: false,
      },
      selected: {
        // time_zone: 'Europe/London',
        // country: 'GB',
        two_fact_auth: 'no',
      },
    };
  },
  computed: {
    ...mapGetters({
      teams: 'getCurrentUserVoipTeams',
      callQueues: 'getCurrentUserVoipCallQueues',
      getCurrentUser: 'getCurrentUser',
      getIsMobile: 'getIsMobile',
    }),
    isDevelopment(){ return process.env.NODE_ENV!='production' },
    tfaEnabled(){ return expected_tfa.filter(i=>i!='no').includes(this.selected.two_fact_auth) },
    link(){ return `${process.env.NODE_ENV==='production'?`https://${process.env.VUE_APP_URL_MOTHERSHIP}`:`${window.location.protocol}//${window.location.hostname}:8093`}/login/?r=${this.$store.getters.getCurrentUser?.referCode}` },
    // countryOptions(){
    //   return [
    //     { value: 'GB', text: "United Kingdom" },
    //     { value: "AF", text: "Afghanistan" },
    //     { value: "AL", text: "Albania" },
    //     { value: "DZ", text: "Algeria" },
    //     { value: "AS", text: "American Samoa" },
    //     { value: "AD", text: "Andorra" },
    //     { value: "AO", text: "Angola" },
    //   ]
    // },
    // timeZones(){ return moment.tz.names(); },
    organizationimagespath(){
      // return `${this.current_user.apiServer.split("/").slice(0, -1).join("/")}/storage/defaults/logos/`
      return `${this.$store.getters.getCurrentUser?.apiServer.split("/").slice(0, -1).join("/")}/storage/defaults/logos/`
    },
    filterOrganizations(){
      const search = this.filter.organizations.search.trim().toLowerCase()
      // const organizations = this.current_user.organizations || []
      const organizations = this.$store.getters.getCurrentUser?.organizations || []
      const filter_organizations = organizations.filter(item=>`${item.label} (${item.uid})`.trim().toLowerCase().includes(search))
      return filter_organizations
    },
  },
  validations: {
    forms: {
      change_pass: {
        cpassword: { required },
        password: {
          required,
          minLength: minLength(8),
          alalphabet: function(value){
            return /[A-Za-z]/.test(value)
          },
          numbers: function(value){
            return /[0-9]/.test(value)
          },
        },
      },
    },
  },
  filters: {
    jsonParse(value){ return JSON.parse(value) },
    trustedDeviceBrowserName(value){
      if(value?.browser=='Electron') return `voipBusiness desktop ${value?.desktop_version ?? ''}`
      else return `${value?.browser ?? ''} ${parseFloat(value?.browserVersion) || ''}`
    },
    trustedDeviceBrowserNameIcon(value){
      if(value?.browser=='Chrome') return 'trustedDevice-chrome-icon'
      else if(value?.browser=='Opera') return 'trustedDevice-opera-icon'
      else if(value?.browser=='Edge') return 'trustedDevice-microsoft-icon'
      else if(value?.browser=='Firefox') return 'trustedDevice-firefox-icon'
      else if(value?.browser=='Safari') return 'trustedDevice-safari-icon'
      else if(value?.browser=='Electron') return 'trustedDevice-desktop-icon'
      else return 'trustedDevice-chrome-icon'
    },
    trustedDeviceOSIcon(value){
      if(value?.os=='Windows' && value?.osVersion=='10') return 'trustedDevice-window10-OSIcon'
      else if(value?.os=='Windows' && value?.osVersion=='11') return 'trustedDevice-window11-OSIcon'
      else if(value?.os=='Linux') return 'trustedDevice-linux-OSIcon'
      else if(value?.os?.includes?.('MAC')) return 'trustedDevice-appleMac-OSIcon'
      else return 'trustedDevice-window10-OSIcon'
    },
  },
  watch: {
    // "selected.country"(country){
    //   if(!country) return;
    //   this.selected.time_zone=moment.tz.zonesForCountry(country)[0]
    // },
  },
  methods: {
    switchOrganization(organization) {
      this.$root.$emit(events.switch_organization, {
        organization,
      });
    },
    copyLink() {
      navigator.clipboard.writeText(this.link).then(()=>{
        this.appNotify({
          message: 'Copied!',
          type: 'success',
        })
      }).catch((ex)=>{
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      });
    },
    leave(organization) {
      // if (organization.id === this.current_user.selected_organization_id)
      if (organization.id === this.$store.getters.getCurrentUser?.selected_organization_id) return;
      let vm = this;
      this.$appConfirmation({
        title: "Confirmation",
        message: `Are you sure you want to leave "${organization.label}" organization ?`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async ({confirm}) => {
          if (confirm) {
            // await VOIP_API.endpoints.user.leaveoriganization(vm.current_user.account, {
            await VOIP_API.endpoints.user.leaveoriganization(vm.$store.getters.getCurrentUser?.account, {
              uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
            });
          }
        },
      });
    },
    changepassword() {
      let vm = this
      vm.forms.change_pass.submitted = true;
      vm.$v.forms.change_pass.$touch();
      if (vm.$v.forms.change_pass.$invalid || vm.api.change_pass.send) return;
      vm.api.change_pass.send = true;
      VOIP_API.endpoints.users.updatepassword({
        cpassword: vm.forms.change_pass.cpassword,
        password: vm.forms.change_pass.password,
        accountcode: vm.getCurrentUser.account,
        id: vm.getCurrentUser.account,
        section: 'profile',
      })
      .then(() => {
        vm.appNotify({
          message: "Password updated successfully",
          type: "success",
        })
        vm.conditions.change_pass=false
      })
      .catch((ex) => {
        this.appNotify({
          message: ex.own_message || "",
          type: 'danger',
        })
      })
      .finally(() => {
        vm.api.change_pass.send = false;
        vm.forms.change_pass.submitted = false;
      });
    },
    changeTwoFactorAuth(two_factor_auth='no'){
      let vm = this
      if(two_factor_auth==vm.selected.two_fact_auth || !expected_tfa.includes(two_factor_auth) || vm.api.update_two_factor_auth.send) return;
      vm.api.update_two_factor_auth.send=true
      vm.$appConfirmation({
        title: "warning",
        message: two_factor_auth=='no' ? `Are you sure you want to turn off the two factor authentication` : two_factor_auth=='yes' ? `Are you sure you want to send the pin code on ${vm.getCurrentUser.email} email` : `Are you sure you want to send the pin code on ${vm.getCurrentUser.email} email when device is not on the trusted devices list`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            VOIP_API.endpoints.users.updatetwofactorauth({
              field: 'two_factor_auth',
              accountcode: vm.getCurrentUser?.account,
              value: two_factor_auth
            }).then(() => {
              vm.selected.two_fact_auth=two_factor_auth
              this.$store.state.common.user.two_factor_auth = vm.selected.two_fact_auth;
              vm.appNotify({
                message: "successfully Updated!",
                type: "success",
              })
            }).catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(() => {
              if(vm.$refs['two_factor_auth_checkbox']) vm.$refs['two_factor_auth_checkbox'].localChecked=vm.tfaEnabled
              vm.api.update_two_factor_auth.send = false;
            });
          } else {
            if(vm.$refs['two_factor_auth_checkbox']) vm.$refs['two_factor_auth_checkbox'].localChecked=vm.tfaEnabled
            vm.api.update_two_factor_auth.send=false
          }
        },
      });
    },
    getTrustedDevices(){
      let vm = this
      if(vm.api.trusted_devices.send) return;
      vm.api.trusted_devices.send=true
      VOIP_API.endpoints.trusted_devices.list({
        accountcode: vm.getCurrentUser?.account
      })
      .then(({ data })=>{
        vm.response.trusted_devices= data ?? []
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.trusted_devices.send=false
      })
    },
    deleteTrustedDevice(device){
      let vm = this
      if(vm.api.delete_trusted_device.send.includes(device.id)) return;
      this.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.delete_trusted_device.send.push(device.id);
            VOIP_API.endpoints.trusted_devices.delete({
              id: device.id,
              accountcode: vm.getCurrentUser?.account,
            })
            .then(() => {
              vm.getTrustedDevices()
              vm.appNotify({
                message: "successfully deleted!",
                type: "success",
              })
            })
            .catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(() => {
              if (vm.api.delete_trusted_device.send.includes(device.id)) vm.api.delete_trusted_device.send.splice(vm.api.delete_trusted_device.send.indexOf(device.id),1);
            });
          }
        },
      });
    },
  },
  activated() {
    this.conditions.change_pass=false
    this.selected.two_fact_auth=expected_tfa.includes(this.getCurrentUser?.two_factor_auth) ? this.getCurrentUser?.two_factor_auth : 'no'
    this.getTrustedDevices()
  },
};
</script>

<style lang="scss" scoped>
.profile-image-section {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto;
  overflow: hidden;
  @include border-radius(50%);
  margin-bottom: 3 * $dialer-default-padding;
  .loader-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .profile-img-upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: none;
    justify-content: center;
    .upload-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      opacity: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(50%);
    }
  }
  &:hover {
    .profile-img-upload-btn {
      display: block;
      cursor: pointer;
    }
  }
}
</style>