var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal CRM-modal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "opened": function ($event) {
        return _vm.onOpened();
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "CRM-modal-header"
  }, [_c('div', {
    staticClass: "CRM-modal-header-text"
  }, [_vm._v("Create deal")]), _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('b-form', {
    staticClass: "latestGreyBox-9-9-2023",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createDeal();
      }
    }
  }, [_c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Enter name")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Enter Name"
    },
    model: {
      value: _vm.forms.create_deal.name,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_deal, "name", $$v);
      },
      expression: "forms.create_deal.name"
    }
  }), _vm.forms.create_deal.submitted && _vm.$v.forms.create_deal.name.$invalid || _vm.api.create_deal.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_deal.name.required ? _c('span', [_vm._v("Name is required")]) : _vm._e(), _vm._l(_vm.api.create_deal.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Select pipeline")]), _c('v-select', {
    ref: "pipelines",
    attrs: {
      "placeholder": "Select Pipeline",
      "options": _vm.response.pipelines,
      "label": "name",
      "filterable": false
    },
    on: {
      "search": function ($event) {
        return _vm.fetchPiplines();
      },
      "option:selected": function ($event) {
        return _vm.fetchPipline();
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.name))];
      }
    }]),
    model: {
      value: _vm.forms.create_deal.pipeline,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_deal, "pipeline", $$v);
      },
      expression: "forms.create_deal.pipeline"
    }
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("type to search pipeline")])], 2), _vm.forms.create_deal.submitted && _vm.$v.forms.create_deal.pipeline.$invalid || _vm.api.create_deal.validation_errors.pipeline_id ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_deal.pipeline.required ? _c('span', [_vm._v("Pipeline is required")]) : _vm._e(), _vm._l(_vm.api.create_deal.validation_errors.pipeline_id, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Select stage")]), _c('v-select', {
    ref: "stages",
    attrs: {
      "options": _vm.stages,
      "label": "name",
      "placeholder": "Select Stage"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.name))];
      }
    }]),
    model: {
      value: _vm.forms.create_deal.stage,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_deal, "stage", $$v);
      },
      expression: "forms.create_deal.stage"
    }
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no stage")])], 2), _vm.forms.create_deal.submitted && _vm.$v.forms.create_deal.stage.$invalid || _vm.api.create_deal.validation_errors.stage_id ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_deal.stage.required ? _c('span', [_vm._v("Stage is required")]) : _vm._e(), _vm._l(_vm.api.create_deal.validation_errors.stage_id, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "crm-input-container withoutIcon"
  }, [_c('label', [_vm._v("Enter amount")]), _c('b-form-input', {
    attrs: {
      "placeholder": "Enter Amount"
    },
    model: {
      value: _vm.forms.create_deal.amount,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_deal, "amount", $$v);
      },
      expression: "forms.create_deal.amount"
    }
  }), _vm.forms.create_deal.submitted && _vm.$v.forms.create_deal.amount.$invalid || _vm.api.create_deal.validation_errors.amount ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_deal.amount.numeric ? _c('span', [_vm._v("Amount should be numeric")]) : _vm._e(), _vm._l(_vm.api.create_deal.validation_errors.amount, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()], 1), _c('div', {
    staticClass: "crm-input-container VueCtkDateTimePicker"
  }, [_c('label', [_vm._v("Select date & time")]), _c('vue-ctk-date-time-picker', _vm._b({
    attrs: {
      "disabled": _vm.api.create_deal.send,
      "format": 'YYYY-MM-DD HH:mm'
    },
    model: {
      value: _vm.forms.create_deal.expected_close_date,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_deal, "expected_close_date", $$v);
      },
      expression: "forms.create_deal.expected_close_date"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false)), _vm.api.create_deal.validation_errors.expected_close_date ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.create_deal.validation_errors.expected_close_date, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Select user")]), _c('v-select', {
    ref: "users",
    attrs: {
      "options": _vm.voipusers,
      "label": "user.display_name",
      "placeholder": "Select User"
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.user.display_name))];
      }
    }]),
    model: {
      value: _vm.forms.create_deal.user,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_deal, "user", $$v);
      },
      expression: "forms.create_deal.user"
    }
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no user")])], 2), _vm.api.create_deal.validation_errors.user_id ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.create_deal.validation_errors.user_id, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Select companies")]), _c('v-select', {
    ref: "companies",
    attrs: {
      "placeholder": "Select Companies",
      "options": _vm.response.companies,
      "label": "name",
      "multiple": "",
      "filterable": false
    },
    on: {
      "search": function ($event) {
        return _vm.fetchCompanies();
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.name))];
      }
    }]),
    model: {
      value: _vm.forms.create_deal.companies,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_deal, "companies", $$v);
      },
      expression: "forms.create_deal.companies"
    }
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no company")])], 2), _vm.api.create_deal.validation_errors.companies ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.create_deal.validation_errors.companies, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1), _c('div', {
    staticClass: "crm-input-container CallType"
  }, [_c('label', [_vm._v("Select contacts")]), _c('v-select', {
    ref: "contacts",
    attrs: {
      "placeholder": "Select Contacts",
      "options": _vm.response.contacts,
      "label": "name",
      "multiple": "",
      "filterable": false
    },
    on: {
      "search": function ($event) {
        return _vm.fetchContacts();
      }
    },
    scopedSlots: _vm._u([{
      key: "option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.name))];
      }
    }, {
      key: "selected-option",
      fn: function (option) {
        return [_vm._v(_vm._s(option.name))];
      }
    }]),
    model: {
      value: _vm.forms.create_deal.contacts,
      callback: function ($$v) {
        _vm.$set(_vm.forms.create_deal, "contacts", $$v);
      },
      expression: "forms.create_deal.contacts"
    }
  }, [_c('template', {
    slot: "no-options"
  }, [_vm._v("There is no contact")])], 2), _vm.api.create_deal.validation_errors.contacts ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.create_deal.validation_errors.contacts, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  }), 0) : _vm._e()], 1), _c('b-button', {
    staticClass: "fullWidthDoneButton mt-20px",
    attrs: {
      "type": "submit"
    }
  }, [_vm._v(" Submit ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }