<template>
    <modal class="dialer_animation right_side_popup global-modal-hrm Scrollable-hrm" name="HRMAddDocumentModal"  :clickToClose="true"  @before-open="beforeOpen" :scrollable="true">
      <form  @submit.prevent="''">
        <div class="dialer-hrm-modal-header mar-top-hrm20">
          <h2 class="dialer-modal-hrm-title ">Add document</h2>
          <div>
            <button class="dialer-hrm-button-modal" @click="$modal.hide('HRMAddDocumentModal')">Close</button>
          </div>
        </div>
        <div class="bg-modal-doc-grey mar-top-hrm30 sp-gm-if">
          <div class="upload-img-doc">
            <svg xmlns="http://www.w3.org/2000/svg" width="89.921" height="65.389" viewBox="0 0 89.921 65.389">
              <path id="Path_55915" data-name="Path 55915" d="M89.921,45.666a56.31,56.31,0,0,1-1.409,5.67c-2.933,8.214-10.987,14.182-19.7,14.051-5.559-.084-11.121-.016-16.681-.016H51.021v-19.5h5.1c1.134,0,2.347.188,2.993-1.107.623-1.249-.207-2.108-.853-3Q52.586,33.9,46.9,26.049c-1.28-1.769-2.626-1.769-3.9,0Q37.162,34.116,31.33,42.191a7.4,7.4,0,0,0-.442.653,1.93,1.93,0,0,0,1.647,3.011c1.784.054,3.57.017,5.355.02h1v19.5H37.861c-6.38,0-12.76-.032-19.14.009-6.336.041-11.2-2.754-14.87-7.731A18.7,18.7,0,0,1,.2,43.575,17.9,17.9,0,0,1,7.7,31.179a6,6,0,0,1,.73-.486.8.8,0,0,0,.437-1.126A12.428,12.428,0,0,1,21.686,13.56,30.7,30.7,0,0,1,25,14.139c.755-1.209,1.458-2.561,2.368-3.756C33.452,2.393,41.508-1.148,51.471.327a24.5,24.5,0,0,1,20.58,19.954,3,3,0,0,0,2.5,2.788c8.782,2.827,13.679,9.052,15.186,18.064.047.284.122.563.183.844Z" transform="translate(0 0)" fill="#5576d1"/>
            </svg>
          </div>
          <div class="upload-btn-doc">
            <button class="btn-activity">
                Upload File</button>
          </div>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Description (optional)</label>
          <b-form-textarea
            id="textarea-small"
            size="sm"
            placeholder="Small textarea"
            class="gm-hrm-textnote">
          </b-form-textarea>
        </div>
        <div class="custom-if-doc-file-upload sp-gm-if-doc-modal">
          <div class="d-flex justify-content-center align-items-center">
            <img src="../../../assets/images/credit-card/Group 18124.png" alt="logo"  class="doc-modal-img"/>
            <p class="mb-imp gm-hrm-prezi">Presentation.pdf</p>
          </div>
          <svg xmlns="http://www.w3.org/2000/svg" width="17.984" height="23.123" viewBox="0 0 17.984 23.123">
            <path id="Icon_material-delete" data-name="Icon material-delete" d="M8.785,25.054a2.577,2.577,0,0,0,2.569,2.569H21.631A2.577,2.577,0,0,0,24.2,25.054V9.638H8.785Zm16.7-19.269h-4.5L19.7,4.5H13.281L12,5.785H7.5V8.354H25.484Z" transform="translate(-7.5 -4.5)" fill="#c7384f"/>
          </svg>
        </div>
        <div class="sp-gm-if">
            <label class="gm-hrm-label">Category</label>
             <div class="dd-gm-container">
              <b-dropdown no-caret class="gm-mainBorderfordropi">
                  <template #button-content>
                    <div class="gm-button-content-dropi">
                      <div class="gm-dropi-contenti">Category</div>
                      <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item class="dropi-dropdown-toggle">A</b-dropdown-item>
                  <b-dropdown-item class="dropi-dropdown-toggle">B</b-dropdown-item>
              </b-dropdown>
            </div>
        </div>
        <div class="bg-modal-doc-grey-share-with sp-gm-if">
          <h5 class="gm-hrm-label">Share with</h5>
            <b-form-group  class="gm-hrm-custom-radio">
            <b-form-radio name="some-radios" value="A">Everyone</b-form-radio>
            <b-form-radio  name="some-radios" value="B">Sasha Banks</b-form-radio>
            <b-form-radio  name="some-radios" value="C">Jenifer</b-form-radio>
          </b-form-group>
        </div>
         <div class="sp-gm-if">
          <p class="gm-hrm-file-size-text">Also share with:</p>
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Team manager</span>
          </b-form-checkbox>
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Direct manager</span>
          </b-form-checkbox>
        </div>
        <div>
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Ask to accept the document</span>
          </b-form-checkbox>
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Notify employee by e-mail</span>
          </b-form-checkbox>
        </div>
        <button class="btn-primarynewupdated1 mt-4 mar-bottom-hrm50">Save</button>
      </form>
    </modal>
</template>
<script>
export default {
  name: 'HRMAddDocumentModal',
}
</script>

<style>

</style>