<template>
  <div v-show="false" class="dialer-flex alt px-4 py-2">
    <div class="dialer-incommingVideoCall-info d-flex align-items-center">
      <div class="dialer-user-dp mr-2">
        <img width="40" height="40" class="rounded" :src="display_image" @error="$event.target.src=userDp" :alt="display_name" />
      </div>
      <div class="dialer-user-bio">
        <h6 class="text-capitalize mb-0">{{ display_name }}</h6>
        <div class="d-flex align-items-center">
          <p class="m-0 pr-2">Time Left: {{ time | time_audio }}</p>
          <b-icon icon="camera-video" />
        </div>
        <div class="d-flex align-items-center">
          <p class="m-0 pr-2">Status: {{ status }}</p>
        </div>
      </div>
    </div>
    <div class="d-flex">
      <a @click="cancel" class="d-flex flex-column align-items-center mx-3">
        <span class="ico-chat bg-danger text-white rotate-135">
          <font-awesome-icon icon="phone-alt" scale="0.9" />
        </span>
      </a>
    </div>
  </div>
</template>

<script>
// import _ from 'lodash';
// import {  
//   centrifugo,
// } from '../../Centrifuge';
import { events, VOIP_API } from '../../utils';
import Timer from "timer.js";
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
import { MOBILE_APP } from '@/mobile-app';
import { MOBILE_APP_SEND_EVENTS } from '@/mobile-app/events';
var timeout = 30
export default {
  name: "OutgoingCalls",
  props: {
    data: {
      type: Object,
      default: ()=>({}),
    },
  },
  data(){
    return {
      display_name: '',
      display_image: '',
      timer: null,
      time: timeout,
      userDp: require("@/assets/images/user-male.png"),
      status: "calling"
    }
  },
  computed: {
    isInVideoCall(){
      let room = this.data.room
      let index = this.$store.state.calls.videoCalls?.findIndex(i=>i.room==room)
      return { 
        data: this.$store.state.calls.videoCalls[index], 
        ind: index, 
      }
    },
  },
  watch: {
    // "$store.state.centrifuge.message": "watchingCentrifugeMessage",
    "time": "watchingTime",
    "status": "watchingStatus",
    "isInVideoCall": "watchingIsInVideoCall",
  },
  methods: {
    //--own--
    async notactivegroupcall(channels){
      await VOIP_API.endpoints.users.groupPublish({
        method: "broadcast",
        params: {
          channels: channels,
          data: {
            ...this.data,
            type: AMI_EVENTS.NOT_ACTIVE_GROUP_CALLS,
          },
        },
      });
      this.$delete(this.$store.state.calls.active_group_calls,this.data.room)
    },
    async cancel(){
      const { callee, room, is_group } = this.data
      if(is_group){
        const channels = callee.map(account=>`${account}-in`)
        await VOIP_API.endpoints.users.groupPublish({
          method: "broadcast",
          params: {
            channels: channels,
            data: {
              ...this.data,
              type: AMI_EVENTS.CANCEL,
            },
          },
        });
        this.notactivegroupcall(channels)
      } else {
        await VOIP_API.endpoints.users.publish({
          method: "publish",
          params: {
            channel: `${callee}-in`,
            data: {
              ...this.data,
              type: AMI_EVENTS.CANCEL,
            },
          },
        });
      }
      this.removeFromList()
      this.$store.state.calls.videoCalls = this.$store.state.calls.videoCalls.filter(call=>call.room!==room)
      MOBILE_APP.sendMessage(MOBILE_APP_SEND_EVENTS.MISSED)
    },
    
    removeFromList(restricted=true){
      const { restricted_key_caller, room } = this.data;
      if(restricted) this.$delete(this.$store.state.calls.restricted_calls,restricted_key_caller);
      this.$delete(this.$store.state.calls.outgoing_calls,room);
    },
    //--watching--
    // watchingCentrifugeMessage(message){
    //   switch (_.get(message, "data.type")) {
    //     case centrifugeEvents.PROGRESS:
    //       this.PROGRESS(message)
    //       break;
    //     case centrifugeEvents.REJECTED:
    //       this.REJECTED(message)
    //       break;
    //     case centrifugeEvents.BYE:
    //       this.BYE(message)
    //       break;
    //     case centrifugeEvents.ACCEPT:
    //       this.ACCEPT(message)
    //       break;
    //     case centrifugeEvents.BUSY:
    //       this.BUSY(message)
    //       break;
    //   }
    // },
    watchingTime(time){
      if(!this.isInVideoCall.data) return;
      this.$store.state.calls.videoCalls[this.isInVideoCall.ind].outgoing.time=time
    },
    watchingStatus(status){
      if(!this.isInVideoCall.data) return;
      this.$store.state.calls.videoCalls[this.isInVideoCall.ind].outgoing.status=status
    },
    watchingIsInVideoCall(data){
      if(!data.data) return;
      this.$store.state.calls.videoCalls[data.ind].outgoing.status=this.status
    },
    //--events--
    BYE(message){
      const { data: { room } } = message
      if(room!==this.data.room) return;
      this.removeFromList()
      this.$store.state.calls.videoCalls = this.$store.state.calls.videoCalls.filter(call=>call.room!==room)
    },
    ACCEPT(message){
      const { data: { room } } = message
      if(room!==this.data.room) return;
      if(this.data.extra_data.is_switch_call) {
        this.$emit('removeSipcall',{
          sip_id: this.data.extra_data.sip_id
        })
      }
      this.removeFromList(false)
    },
    REJECTED(message){
      const { data } = message
      if(data){
        const { room, is_group } = data
        if(room!==this.data.room) return;
        if(is_group){
          //TODO
        } else {
          this.removeFromList()
          this.$store.state.calls.videoCalls = this.$store.state.calls.videoCalls.filter(call=>call.room!==room)
        }
      }
    },
    PROGRESS(message){
      const { data: { room } } = message
      if(room!==this.data.room) return;
      this.status="ringing"
    },
    BUSY(message){
      const { data: { room } } = message
      if(this.data.room!==room) return;
      this.removeFromList()
    },
    callEnded(message){
      const { data: { room } } = message
      if(room!==this.data.room) return;
      this.cancel()
    },
  },
  created(){
    this.$root.$on(events.call_ended,this.callEnded)
    AMI_SOCKET.on(AMI_EVENTS.PROGRESS,this.PROGRESS)
    AMI_SOCKET.on(AMI_EVENTS.REJECTED,this.REJECTED)
    AMI_SOCKET.on(AMI_EVENTS.BYE,this.BYE)
    AMI_SOCKET.on(AMI_EVENTS.ACCEPT,this.ACCEPT)
    AMI_SOCKET.on(AMI_EVENTS.BUSY,this.BUSY)
    MOBILE_APP?.on?.(AMI_EVENTS.PROGRESS,this.PROGRESS)
    MOBILE_APP?.on?.(AMI_EVENTS.REJECTED,this.REJECTED)
    MOBILE_APP?.on?.(AMI_EVENTS.BYE,this.BYE)
    MOBILE_APP?.on?.(AMI_EVENTS.ACCEPT,this.ACCEPT)
    MOBILE_APP?.on?.(AMI_EVENTS.BUSY,this.BUSY)
    // centrifugo.calls.on(centrifugo.calls.events.PROGRESS,this.PROGRESS)
    // centrifugo.calls.on(centrifugo.calls.events.REJECTED,this.REJECTED)
    // centrifugo.calls.on(centrifugo.calls.events.BYE,this.BYE)
    // centrifugo.calls.on(centrifugo.calls.events.ACCEPT,this.ACCEPT)
    // centrifugo.calls.on(centrifugo.calls.events.BUSY,this.BUSY)
    let vm = this
    const { is_group, display_name, callee } = this.data
    this.display_name = is_group ? display_name : this.$store.getters.getVoipUsersAlisesDisplayName[callee] //this.showUserName(callee)
    // this.display_image = is_group ? this.userDp : this.showUserImage(callee)
    this.display_image = is_group ? this.userDp : this.$store.getters.getVoipUsersAlisesImages[callee]
    this.timer = new Timer({
      tick: 1,
      ontick() {
        vm.time=vm.time-1
      },
      onend() {
        if(vm.timer){
          const user = vm.$store.getters.getVoipUsersAlises[vm.data.callee]; //vm.getUserInfo(vm.data.callee)
          if(user && user.extn){
            // setTimeout(()=>{
            //   if(vm.status=="ringing"){
            //     vm.$root.$emit(events.audio_call,{
            //       number: user?.extn,
            //       id: user?.voipaccount,
            //     })
            //   } else {
            //     vm.$root.$emit(events.audio_call,{
            //       number: user?.extn,
            //       id: user?.voipaccount,
            //       call_type: 'after_jitsi',
            //     })
            //   }
            // },1 * 1000)
          }
          vm.cancel()
        }
      },
    });
  },
  mounted(){
    this.timer.start(timeout)
  },
  beforeDestroy(){
    this.$root.$off(events.call_ended,this.callEnded)
    AMI_SOCKET.off(AMI_EVENTS.PROGRESS,this.PROGRESS)
    AMI_SOCKET.off(AMI_EVENTS.REJECTED,this.REJECTED)
    AMI_SOCKET.off(AMI_EVENTS.BYE,this.BYE)
    AMI_SOCKET.off(AMI_EVENTS.ACCEPT,this.ACCEPT)
    AMI_SOCKET.off(AMI_EVENTS.BUSY,this.BUSY)
    MOBILE_APP?.off?.(AMI_EVENTS.PROGRESS,this.PROGRESS)
    MOBILE_APP?.off?.(AMI_EVENTS.REJECTED,this.REJECTED)
    MOBILE_APP?.off?.(AMI_EVENTS.BYE,this.BYE)
    MOBILE_APP?.off?.(AMI_EVENTS.ACCEPT,this.ACCEPT)
    MOBILE_APP?.off?.(AMI_EVENTS.BUSY,this.BUSY)
    // centrifugo.calls.off(centrifugo.calls.events.PROGRESS,this.PROGRESS)
    // centrifugo.calls.off(centrifugo.calls.events.REJECTED,this.REJECTED)
    // centrifugo.calls.off(centrifugo.calls.events.BYE,this.BYE)
    // centrifugo.calls.off(centrifugo.calls.events.ACCEPT,this.ACCEPT)
    // centrifugo.calls.off(centrifugo.calls.events.BUSY,this.BUSY)
  },
  destroyed(){
    this.timer=null
  }
}
</script>

<style lang="scss" scoped>

</style>