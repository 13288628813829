var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 addCallQueueModal SmallAddModal",
    attrs: {
      "height": "auto",
      "name": _vm.modalName,
      "scrollable": true
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-edit-user s1"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between"
  }, [_c('div', {
    staticClass: "d-flex align-items-center addCallQueueModal-namePart"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Add call queue")]), _c('vb-icon', {
    staticClass: "ml-2 cursor_pointer checkSlotsModal-icon",
    attrs: {
      "icon": "checkSlotsModal-icon",
      "height": "22px",
      "width": "22px",
      "disabled": _vm.api.add_call_queue.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CheckedServiceAvailableModal', {
          service: 'queue',
          onProceed: _vm.addCallQueue
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-edit-actions d-flex align-items-center"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Enter the name of the new call queue below. ")])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_vm.api.check_service.send ? [_c('div', {
    staticClass: "md-mar-top"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  })]), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })])])]), _c('div', {
    staticClass: "latestShimmerDesign mt-4",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })] : _c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addCallQueue();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.add_call_queue.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_call_queue.error_message))]), _c('b-alert', {
    staticClass: "alertCustom",
    attrs: {
      "show": _vm.checkServiceAlert.enable,
      "variant": _vm.checkServiceAlert.variant
    }
  }, [_vm._v(_vm._s(_vm.checkServiceAlert.message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Enter the name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.call_queue.name,
      expression: "forms.call_queue.name"
    }],
    staticClass: "w-100 bg-white",
    attrs: {
      "disabled": _vm.api.add_call_queue.send,
      "placeholder": "",
      "maxlength": _vm.$v.forms.call_queue.name.$params.maxLength.max,
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.call_queue.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.call_queue, "name", $event.target.value);
      }
    }
  }), _vm.forms.call_queue.submitted && _vm.$v.forms.call_queue.name.$invalid || _vm.api.add_call_queue.validation_errors.name ? _c('p', {
    staticClass: "text text-small text-danger animated bounceIntop mb-0"
  }, [!_vm.$v.forms.call_queue.name.required ? _c('span', [_vm._v("* Name is Required")]) : !_vm.$v.forms.call_queue.name.minLength ? _c('span', [_vm._v("* Name is should be minimum " + _vm._s(_vm.$v.forms.call_queue.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.call_queue.name.maxLength ? _c('span', [_vm._v("* Name is can be maximum " + _vm._s(_vm.$v.forms.call_queue.name.$params.maxLength.max) + " character")]) : !_vm.$v.forms.call_queue.name.valid ? _c('span', [_vm._v("* Name can be number and alphabet")]) : _vm._e(), _vm._l(_vm.api.add_call_queue.validation_errors.name, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-end mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "type": "submit",
      "disabled": _vm.api.add_call_queue.send
    },
    on: {
      "click": function ($event) {
        return _vm.addCallQueue();
      }
    }
  }, [_vm.api.add_call_queue.send ? _c('vb-spinner') : [_vm._v("Create call queue")]], 2)])], 1)], 2)])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }