var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-reports"
  }, [_vm.conditions.screen == _vm.screens.select_call_type ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.filter;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Call Type")])]), _c('div', {
    staticClass: "dialer-box AnalyticsContainerInner"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.response.types, function (type, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.filters.reports.type == type.value ? _vm.filters.reports.type = '' : _vm.filters.reports.type = type.value;
          _vm.conditions.screen = _vm.screens.filter;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(type.label))]), _vm.filters.reports.type == type.value ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.response.types.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.filters.send ? '' : 'There is no Number',
      "design": 3
    }
  }, [_vm.api.filters.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])] : _vm.conditions.screen == _vm.screens.select_extension ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.filter;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Extension")])]), _c('div', {
    staticClass: "dialer-box AnalyticsContainerInner"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search"
    },
    model: {
      value: _vm.filters.extensions.search,
      callback: function ($$v) {
        _vm.$set(_vm.filters.extensions, "search", $$v);
      },
      expression: "filters.extensions.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.filterExtentions, function (extn, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.filters.reports.agents.findIndex(function (r) {
            return r.value == extn.value;
          }) > -1 ? _vm.filters.reports.agents.splice(_vm.filters.reports.agents.findIndex(function (r) {
            return r.value == extn.value;
          }), 1) : _vm.filters.reports.agents.push(extn);
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(extn.label))]), _vm.filters.reports.agents.findIndex(function (r) {
      return r.value == extn.value;
    }) > -1 ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.filterExtentions.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.filters.send ? '' : 'There is no Number',
      "design": 3
    }
  }, [_vm.api.filters.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])] : _vm.conditions.screen == _vm.screens.select_tags ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.filter;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Tags")])]), _c('div', {
    staticClass: "dialer-box AnalyticsContainerInner"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search"
    },
    model: {
      value: _vm.filters.tags.search,
      callback: function ($$v) {
        _vm.$set(_vm.filters.tags, "search", $$v);
      },
      expression: "filters.tags.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.filterTags, function (tag, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.filters.reports.tags.findIndex(function (r) {
            return r.value == tag.value;
          }) > -1 ? _vm.filters.reports.tags.splice(_vm.filters.reports.tags.findIndex(function (r) {
            return r.value == tag.value;
          }), 1) : _vm.filters.reports.tags.push(tag);
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(tag.label))]), _vm.filters.reports.tags.findIndex(function (r) {
      return r.value == tag.value;
    }) > -1 ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), _vm.filterTags.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.filters.send ? '' : 'There is no Number',
      "design": 3
    }
  }, [_vm.api.filters.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])] : _vm.conditions.screen == _vm.screens.filter ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Filters")])]), _c('div', {
    staticClass: "dialer-box AnalyticsContainerInner"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.filters.reports.number,
      expression: "filters.reports.number"
    }],
    attrs: {
      "disabled": _vm.api.reports.send,
      "type": "text",
      "placeholder": "Source or Destination Number"
    },
    domProps: {
      "value": _vm.filters.reports.number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.filters.reports, "number", $event.target.value);
      }
    }
  })])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Date Range")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "date-time-input mt-0",
    attrs: {
      "format": "YYYY-MM-DD",
      "disabled": _vm.api.reports.send,
      "noButton": false,
      "range": true
    },
    model: {
      value: _vm.filters.reports.date_range,
      callback: function ($$v) {
        _vm.$set(_vm.filters.reports, "date_range", $$v);
      },
      expression: "filters.reports.date_range"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_call_type;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Call Type")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
  }, [_vm._v(_vm._s(_vm.filters.reports.type))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_extension;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("User & Extension")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
  }, [_vm._v(_vm._s(_vm.filters.reports.agents.length) + " Selected")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.select_tags;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Tags")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
  }, [_vm._v(_vm._s(_vm.filters.reports.tags.length) + " Selected")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)])]), _c('a', {
    staticClass: "IosFullWidthButton forDone mb-16px",
    on: {
      "click": function ($event) {
        _vm.api.reports.send ? '' : _vm.fetchReports(true);
        _vm.conditions.screen = _vm.screens.main;
      }
    }
  }, [_vm._v("Result")])])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Reports")])]), _c('div', {
    staticClass: "dialer-box AnalyticsContainerInner"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.filter;
      }
    }
  }, [_vm._m(0), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', _vm._l(_vm.response.reports, function (data, key) {
    return _c('div', {
      key: key,
      staticClass: "basicWhiteIOScard-item",
      attrs: {
        "index": key
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "basicIOSIcon mr-16px"
    }, [data.status === 'Missed Call' ? _c('vb-icon', {
      attrs: {
        "icon": "NumberBlocking-missed-IOSIcon",
        "height": "13.922",
        "width": "13.922"
      }
    }) : data.status === 'Received Call' ? _c('vb-icon', {
      attrs: {
        "icon": "NumberBlocking-incomming-IOSIcon",
        "height": "13.922",
        "width": "13.922"
      }
    }) : _c('vb-icon', {
      attrs: {
        "icon": "NumberBlocking-outgoing-IOSIcon",
        "height": "13.922",
        "width": "13.922"
      }
    })], 1), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(data.sourceCallerId)))]), data.soundFile ? _c('vb-audio-player', {
      staticClass: "IosAudioPlayer mt-8px",
      attrs: {
        "disabled": !_vm.getUserPermissions.call_recording,
        "src": data.soundFile
      }
    }) : _vm._e()], 1)]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_vm.getVoipUsersAlises[data.destinationExt] ? _c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
    }, [_vm._v(_vm._s(_vm.getVoipUsersAlises[data.destinationExt].extn))]) : _c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
    }, [_vm._v(_vm._s(data.destinationName))]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)]);
  }), 0), _c('InfiniteLoading', {
    ref: "reports_infinity_loader",
    on: {
      "infinite": function ($event) {
        return _vm.fetchReports();
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (_ref) {
        var trigger = _ref.trigger;
        return [_c('div', [_c('span', [_vm._v(_vm._s(_vm.api.reports.error_message))]), _c('b-icon', {
          staticClass: "ml-3",
          attrs: {
            "icon": "arrow-repeat",
            "variant": "primary",
            "font-scale": "1.5"
          },
          on: {
            "click": function ($event) {
              return trigger();
            }
          }
        })], 1)];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "spinner"
    },
    slot: "spinner"
  }, [_c('vb-loading', {
    attrs: {
      "type": "loader"
    }
  })], 1), _c('div', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }), _c('div', {
    attrs: {
      "slot": "no-results"
    },
    slot: "no-results"
  }, [_c('vb-no-record', {
    attrs: {
      "text": 'There is no Numbers',
      "design": 3
    }
  })], 1)])], 1)])]], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Filter")])])]);

}]

export { render, staticRenderFns }