
import { VOIP_API } from "../index"

export const STATUSES_API_ENDPOINTS = {
  list(params={}){
    return VOIP_API.axios.voip.request({
      url: `status`,
      method: "GET",
      params,
    });
  },
  update(data){
    return VOIP_API.axios.voip.request({
      url: `status`,
      method: "PATCH",
      data,
    });
  },
  create(data){
    return VOIP_API.axios.voip.request({
      url: `status`,
      method: "POST",
      data,
    });
  },
  delete(id,params){
    return VOIP_API.axios.voip.request({
      url: `status/${id}`,
      method: "DELETE",
      params,
    });
  },
  allowcall(data={}){
    return VOIP_API.axios.voip.request({
      url: 'update-status-allow-call',
      method: 'POST',
      data,
    })
  },
}