var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    ref: "speedDialContainer",
    staticClass: "speedDialContainer"
  }, [_c('div', {
    staticClass: "tab-pane active"
  }, [_c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_c('div', {
    staticClass: "tab-pane active"
  }, [!_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer_contact_list speed_dial_list afterFavourites allow_scroll"
  }, [_vm._l(_vm.favouriteAddressBook, function (addressbook) {
    return _c('div', {
      key: addressbook.id,
      staticClass: "dialer_contact_row forContacts"
    }, [_c('div', {
      staticClass: "dialer_contact"
    }, [_c('vb-avatar', {
      attrs: {
        "image": _vm._f("get_property")(addressbook, 'profile_image')
      }
    }), _c('div', {
      staticClass: "user-info"
    }, [_c('div', {
      staticClass: "user-name-box"
    }, [_c('span', {
      staticClass: "user-name"
    }, [_c('span', [_vm._v(_vm._s(`${addressbook.firstName} ${addressbook.lastName || ''}`))])]), _c('div', {
      staticClass: "dialer_contact_hidden_area"
    }, [_c('div', {
      staticClass: "dialer_contact_connection_box"
    }, [_c('span', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
    }, [_c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.audioCall(addressbook);
        }
      }
    }, [_c('vb-svg', {
      staticClass: "mr-2",
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', [_vm._v("Call")])], 1)], 1)], 1)])])]), _c('div', {
      staticClass: "user-number"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(addressbook, 'main_phone.number'))))])])], 1)]);
  }), _vm._l(_vm.favouriteTeams, function (team) {
    return _c('div', {
      key: team.id,
      staticClass: "dialer_contact_department_box"
    }, [_c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordion-' + team.id,
        expression: "'accordion-' + team.id"
      }],
      staticClass: "dialer_contact_department_box_header justify-content-between show-on-hover",
      attrs: {
        "block": "",
        "variant": "info"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center",
      staticStyle: {
        "width": "inherit"
      }
    }, [_c('info', {
      attrs: {
        "id": team.real_id,
        "is_unread_show": true
      }
    })], 1), _c('div', {
      staticClass: "dialer_contact_hidden_area hidden-for-scroll mr-4"
    }, [_c('div', {
      staticClass: "dialer_contact_connection_box"
    }, [_c('span', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
    }, [_c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.dialTeam(team);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.chatTeam(team);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineChat-icon",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Chat")])], 1)], 1)], 1)])]), _c('vb-svg', {
      staticClass: "dialer-downArrow",
      attrs: {
        "name": "dialer-downArrow-icon",
        "width": "14",
        "height": "8.001",
        "viewBox": "0 0 14 8.001",
        "stroke-width": "0",
        "stroke": "",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    })], 1), _c('b-collapse', {
      staticClass: "dialer_contact_department_box_hidden_area",
      attrs: {
        "id": 'accordion-' + team.id,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(team.members) ? _c('div', {
      staticClass: "dialer_contact_row flex-column"
    }, _vm._l(team.members, function (member, key) {
      return _c('div', {
        key: key,
        staticClass: "dialer_contact"
      }, [_c('div', {
        staticClass: "d-flex align-items-center",
        staticStyle: {
          "width": "inherit"
        }
      }, [_c('info', {
        attrs: {
          "id": member.accountcode,
          "is_unread_show": true
        }
      })], 1), _c('div', {
        staticClass: "dialer_contact_hidden_area"
      }, [_c('div', {
        staticClass: "dialer_contact_connection_box"
      }, [_c('span', {
        staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
      }, [_vm.getCurrentUser.account != member.accountcode ? _c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('b-icon', {
              attrs: {
                "icon": "three-dots",
                "scale": "1.5"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Call")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        staticClass: "mr-2",
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Video")])], 1)], 1) : _vm._e()], 1)])])]);
    }), 0) : _vm._e(), _vm.isEmpty(team.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'Sorry, No Data Found',
        "design": 3
      }
    }) : _vm._e()], 1)], 1);
  }), _vm._l(_vm.favouriteCallQueue, function (call_queue) {
    return _c('div', {
      key: call_queue.voipaccount,
      staticClass: "dialer_contact_department_box"
    }, [_c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordion-' + call_queue.voipaccount,
        expression: "'accordion-' + call_queue.voipaccount"
      }],
      staticClass: "dialer_contact_department_box_header justify-content-between show-on-hover",
      attrs: {
        "block": "",
        "variant": "info"
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center",
      staticStyle: {
        "width": "inherit"
      }
    }, [_c('info', {
      attrs: {
        "id": call_queue.voipaccount
      }
    })], 1), _c('div', {
      staticClass: "dialer_contact_hidden_area hidden-for-scroll mr-4"
    }, [_c('div', {
      staticClass: "dialer_contact_connection_box"
    }, [_c('span', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
    }, [_c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.dialCallQueue(call_queue);
        }
      }
    }, [_c('vb-svg', {
      staticClass: "mr-2",
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', [_vm._v("Call")])], 1), _vm.getProperty(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.adminforce`, 'no') == 'no' ? [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.callQueueToggleLogin(call_queue);
        }
      }
    }, [_c('b-icon', {
      staticClass: "mr-2",
      attrs: {
        "icon": "box-arrow-in-left"
      }
    }), _c('span', [_vm._v(_vm._s(_vm._f("is_logged_in")(_vm._f("get_property")(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.logged_in`, false))))])], 1), _vm.getProperty(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.logged_in`, false) ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.callQueueTogglePaused(call_queue);
        }
      }
    }, [_c('b-icon', {
      staticClass: "mr-2",
      attrs: {
        "icon": _vm.getProperty(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.is_paused`, false) ? 'play-fill' : 'pause-fill'
      }
    }), _c('span', [_vm._v(_vm._s(_vm._f("is_paused")(_vm._f("get_property")(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.is_paused`, false))))])], 1) : _vm._e()] : _vm._e()], 2)], 1)])]), _c('vb-svg', {
      staticClass: "dialer-downArrow",
      attrs: {
        "name": "dialer-downArrow-icon",
        "width": "14",
        "height": "8.001",
        "viewBox": "0 0 14 8.001",
        "stroke-width": "0",
        "stroke": "",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    })], 1), _c('b-collapse', {
      staticClass: "dialer_contact_department_box_hidden_area",
      attrs: {
        "id": 'accordion-' + call_queue.voipaccount,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(call_queue.members) ? _c('div', {
      staticClass: "dialer_contact_row"
    }, _vm._l(call_queue.members, function (member) {
      return _c('div', {
        key: member.membername,
        staticClass: "dialer_contact"
      }, [_c('div', {
        staticClass: "d-flex align-items-center",
        staticStyle: {
          "width": "inherit"
        }
      }, [_c('info', {
        attrs: {
          "id": member.membername,
          "is_unread_show": true
        }
      })], 1), _c('div', {
        staticClass: "dialer_contact_hidden_area"
      }, [_c('div', {
        staticClass: "dialer_contact_connection_box"
      }, [_c('span', {
        staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
      }, [_vm.getCurrentUser.account != member.membername ? _c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('b-icon', {
              attrs: {
                "icon": "three-dots",
                "scale": "1.5"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Call")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', [_vm._v("Video")])], 1), _vm.getCurrentUser.administrator_account ? [_vm.getProperty(call_queue, `call_queue_status.${member.membername}.adminforce`, 'no') == 'no' ? [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.callQueueToggleLogin(call_queue, member.membername);
          }
        }
      }, [_c('b-icon', {
        attrs: {
          "icon": "box-arrow-in-left"
        }
      }), _c('span', [_vm._v(_vm._s(_vm._f("is_logged_in")(_vm._f("get_property")(call_queue, `call_queue_status.${member.membername}.logged_in`, false))))])], 1), _vm.getProperty(call_queue, `call_queue_status.${member.membername}.logged_in`, false) ? _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.callQueueTogglePaused(call_queue, member.membername);
          }
        }
      }, [_c('b-icon', {
        attrs: {
          "icon": "pause-fill"
        }
      }), _c('span', [_vm._v(_vm._s(_vm._f("is_paused")(_vm._f("get_property")(call_queue, `call_queue_status.${member.membername}.is_paused`, false))))])], 1) : _vm._e()] : _vm._e()] : _vm._e()], 2) : _vm._e()], 1)])])]);
    }), 0) : _vm._e(), _vm.isEmpty(call_queue.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'Sorry, No Data Found',
        "design": 3
      }
    }) : _vm._e()], 1)], 1);
  }), _vm._l(_vm.favouriteUsers, function (user) {
    return _c('div', {
      key: user.voipaccount,
      staticClass: "dialer_contact_row"
    }, [_c('div', {
      staticClass: "dialer_contact"
    }, [_c('info', {
      attrs: {
        "id": user.voipaccount,
        "is_unread_show": true
      }
    }), _c('div', {
      staticClass: "user-info"
    }, [_c('div', {
      staticClass: "user-name-box"
    }, [_c('div', {
      staticClass: "dialer_contact_hidden_area"
    }, [_c('div', {
      staticClass: "dialer_contact_connection_box"
    }, [_c('span', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute"
    }, [_c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [user.state == 'Ringing' ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.blf ? _vm.appNotify({
            message: 'Your package does not support this feature',
            type: 'danger'
          }) : _vm.dialinringing(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Pick Call")])], 1) : _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.dial(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      class: ['', !_vm.getUserPermissions.chat ? 'for-disable' : ''],
      attrs: {
        "id": `RightSideUsersChatOption-${user.voipaccount}`,
        "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
      }
    }, [_c('b-dropdown-item', {
      class: !_vm.getUserPermissions.chat ? 'for-disable-disable' : '',
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.chat ? '' : _vm.chatUser(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineChat-icon",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Chat")])], 1)], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.videoCall(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineVideo-icon",
        "width": "20.6",
        "height": "13.6",
        "viewBox": "0 0 20.6 13.6",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Video")])], 1)], 1)], 1)])])])])], 1)]);
  }), _vm.isEmpty(_vm.favouriteAddressBook) && _vm.isEmpty(_vm.favouriteTeams) && _vm.isEmpty(_vm.favouriteCallQueue) && _vm.isEmpty(_vm.favouriteUsers) ? _c('div', {
    staticClass: "emptyScreenContainer b-0 p-0"
  }, [_c('img', {
    attrs: {
      "width": "100%",
      "height": "auto",
      "src": require('@/assets/images/emptyScreenImages/rightSideBarFavourites.png')
    }
  }), _c('div', {
    staticClass: "emptyScreenContainer-heading"
  }, [_vm._v(" No favourite contacts yet ")]), _c('div', {
    staticClass: "emptyScreenContainer-text w-100"
  }, [_vm._v(" You can add your most frequently contacted people to this list for quick access. ")])]) : _vm._e()], 2) : _c('div', {
    staticClass: "dialer_contact_list speed_dial_list afterFavourites allow_scroll"
  }, [_vm._l(_vm.favouriteAddressBook, function (addressbook) {
    return _c('div', {
      key: addressbook.id,
      staticClass: "dialer_contact_row forContacts b-0"
    }, [_c('div', {
      staticClass: "updatingContactsAgain-contact-item"
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('vb-avatar', {
      staticClass: "sm-mar-right",
      attrs: {
        "image": _vm._f("get_property")(addressbook, 'profile_image')
      }
    }), _c('div', {
      staticClass: "user-info"
    }, [_c('div', {
      staticClass: "user-name-box"
    }, [_c('span', {
      staticClass: "user-name"
    }, [_c('span', [_vm._v(_vm._s(`${addressbook.firstName} ${addressbook.lastName || ''}`))])])])])], 1), _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('vb-icon', {
            staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
            attrs: {
              "icon": "mobile-topBar-threeDots-likeAirCall-icon",
              "height": "27.06px",
              "width": "6px"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.audioCall(addressbook);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1)], 1)], 1)]);
  }), _vm._l(_vm.favouriteTeams, function (team) {
    return _c('div', {
      key: team.id,
      staticClass: "dialer_contact_department_box b-0"
    }, [_c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordion-' + team.id,
        expression: "'accordion-' + team.id"
      }],
      staticClass: "updatingContactsAgain-contact-item",
      attrs: {
        "block": "",
        "variant": "info"
      }
    }, [_c('info', {
      attrs: {
        "id": team.real_id,
        "is_unread_show": false,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _c('div', {
      staticClass: "showOnlyOnHover"
    }, [_c('vb-svg', {
      staticClass: "dialer-downArrow mr-3",
      attrs: {
        "name": "dialer-downArrow-icon",
        "width": "16px",
        "height": "10px",
        "viewBox": "0 0 14 8.001",
        "stroke-width": "0",
        "stroke": "",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('vb-icon', {
            staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
            attrs: {
              "icon": "mobile-topBar-threeDots-likeAirCall-icon",
              "height": "27.06px",
              "width": "6px"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.dialTeam(team);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.chatTeam(team);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineChat-icon",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Chat")])], 1)], 1)], 1)], 1), _c('b-collapse', {
      attrs: {
        "id": 'accordion-' + team.id,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(team.members) ? _c('div', {
      staticClass: "dialer_contact_row pl-2 pr-0 py-0"
    }, _vm._l(team.members, function (member, key) {
      return _c('div', {
        key: key,
        staticClass: "updatingContactsAgain-contact-item"
      }, [_c('info', {
        attrs: {
          "id": member.accountcode,
          "is_unread_show": true,
          "is_suspended_show": false,
          "is_blf": false
        }
      }), _vm.getCurrentUser.account != member.accountcode ? _c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('vb-icon', {
              staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
              attrs: {
                "icon": "mobile-topBar-threeDots-likeAirCall-icon",
                "height": "27.06px",
                "width": "6px"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v("Call")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v("Video")])], 1)], 1) : _vm._e()], 1);
    }), 0) : _vm._e(), _vm.isEmpty(team.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'Sorry, No Data Found',
        "design": 3
      }
    }) : _vm._e()], 1)], 1);
  }), _vm._l(_vm.favouriteCallQueue, function (call_queue) {
    return _c('div', {
      key: call_queue.voipaccount,
      staticClass: "dialer_contact_department_box b-0"
    }, [_c('div', {
      directives: [{
        name: "b-toggle",
        rawName: "v-b-toggle",
        value: 'accordion-' + call_queue.voipaccount,
        expression: "'accordion-' + call_queue.voipaccount"
      }],
      staticClass: "updatingContactsAgain-contact-item",
      attrs: {
        "block": "",
        "variant": "info"
      }
    }, [_c('info', {
      attrs: {
        "id": call_queue.voipaccount,
        "is_unread_show": false,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _c('div', {
      staticClass: "showOnlyOnHover"
    }, [_c('vb-svg', {
      staticClass: "dialer-downArrow mr-3",
      attrs: {
        "name": "dialer-downArrow-icon",
        "width": "16px",
        "height": "10px",
        "viewBox": "0 0 14 8.001",
        "stroke-width": "0",
        "stroke": "",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('vb-icon', {
            staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
            attrs: {
              "icon": "mobile-topBar-threeDots-likeAirCall-icon",
              "height": "27.06px",
              "width": "6px"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.dialCallQueue(call_queue);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), _vm.getProperty(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.adminforce`, 'no') == 'no' ? [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.callQueueToggleLogin(call_queue);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "box-arrow-in-left"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(_vm._f("is_logged_in")(_vm._f("get_property")(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.logged_in`, false))))])], 1), _vm.getProperty(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.logged_in`, false) ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.callQueueTogglePaused(call_queue);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": _vm.getProperty(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.is_paused`, false) ? 'play-fill' : 'pause-fill'
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v(_vm._s(_vm._f("is_paused")(_vm._f("get_property")(call_queue, `call_queue_status.${_vm.getCurrentUser.account}.is_paused`, false))))])], 1) : _vm._e()] : _vm._e()], 2)], 1)], 1), _c('b-collapse', {
      attrs: {
        "id": 'accordion-' + call_queue.voipaccount,
        "accordion": "my-accordion"
      }
    }, [!_vm.isEmpty(call_queue.members) ? _c('div', {
      staticClass: "dialer_contact_row b-0 pl-2 pr-0 py-0"
    }, _vm._l(call_queue.members, function (member) {
      return _c('div', {
        key: member.membername,
        staticClass: "updatingContactsAgain-contact-item"
      }, [_c('info', {
        attrs: {
          "id": member.membername,
          "is_unread_show": true,
          "is_suspended_show": false,
          "is_blf": false
        }
      }), _vm.getCurrentUser.account != member.membername ? _c('b-dropdown', {
        staticClass: "ico-filter",
        attrs: {
          "varient": "link",
          "no-caret": ""
        },
        scopedSlots: _vm._u([{
          key: "button-content",
          fn: function () {
            return [_c('vb-icon', {
              staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
              attrs: {
                "icon": "mobile-topBar-threeDots-likeAirCall-icon",
                "height": "27.06px",
                "width": "6px"
              }
            })];
          },
          proxy: true
        }], null, true)
      }, [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.dial(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineCall-icon2",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v("Call")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.chatUser(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineChat-icon",
          "width": "16.501",
          "height": "16.501",
          "viewBox": "0 0 16.501 16.501",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v("Chat")])], 1), _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            return _vm.videoCall(member);
          }
        }
      }, [_c('vb-svg', {
        attrs: {
          "name": "dialer-outlineVideo-icon",
          "width": "20.6",
          "height": "13.6",
          "viewBox": "0 0 20.6 13.6",
          "stroke-width": "0",
          "stroke": "#005DFF",
          "fill": "none",
          "stroke-linecap": "round",
          "stroke-linejoin": "round"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v("Video")])], 1), _vm.getCurrentUser.administrator_account ? [_vm.getProperty(call_queue, `call_queue_status.${member.membername}.adminforce`, 'no') == 'no' ? [_c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.callQueueToggleLogin(call_queue, member.membername);
          }
        }
      }, [_c('b-icon', {
        attrs: {
          "icon": "box-arrow-in-left"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v(_vm._s(_vm._f("is_logged_in")(_vm._f("get_property")(call_queue, `call_queue_status.${member.membername}.logged_in`, false))))])], 1), _vm.getProperty(call_queue, `call_queue_status.${member.membername}.logged_in`, false) ? _c('b-dropdown-item', {
        on: {
          "click": function ($event) {
            $event.stopPropagation();
            return _vm.callQueueTogglePaused(call_queue, member.membername);
          }
        }
      }, [_c('b-icon', {
        attrs: {
          "icon": "pause-fill"
        }
      }), _c('span', {
        staticClass: "ml-2"
      }, [_vm._v(_vm._s(_vm._f("is_paused")(_vm._f("get_property")(call_queue, `call_queue_status.${member.membername}.is_paused`, false))))])], 1) : _vm._e()] : _vm._e()] : _vm._e()], 2) : _vm._e()], 1);
    }), 0) : _vm._e(), _vm.isEmpty(call_queue.members) ? _c('vb-no-record', {
      attrs: {
        "text": 'Sorry, No Data Found',
        "design": 3
      }
    }) : _vm._e()], 1)], 1);
  }), _vm._l(_vm.favouriteUsers, function (user) {
    return _c('div', {
      key: user.voipaccount,
      staticClass: "dialer_contact_row b-0"
    }, [_c('div', {
      staticClass: "updatingContactsAgain-contact-item"
    }, [_c('info', {
      attrs: {
        "id": user.voipaccount,
        "is_unread_show": false,
        "is_suspended_show": false,
        "is_blf": false
      }
    }), _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('vb-icon', {
            staticClass: "mobile-topBar-threeDots-likeAirCall-icon",
            attrs: {
              "icon": "mobile-topBar-threeDots-likeAirCall-icon",
              "height": "27.06px",
              "width": "6px"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [user.state == 'Ringing' ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.blf ? _vm.appNotify({
            message: 'Your package does not support this feature',
            type: 'danger'
          }) : _vm.dialinringing(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Pick Call")])], 1) : _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.dial(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineCall-icon2",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), _c('div', {
      directives: [{
        name: "b-tooltip",
        rawName: "v-b-tooltip"
      }],
      class: ['', !_vm.getUserPermissions.chat ? 'for-disable' : ''],
      attrs: {
        "id": `RightSideUsersChatOption-${user.voipaccount}`,
        "title": _vm.getUserPermissions.chat ? '' : 'Your package does not support this feature'
      }
    }, [_c('b-dropdown-item', {
      class: !_vm.getUserPermissions.chat ? 'for-disable-disable' : '',
      on: {
        "click": function ($event) {
          !_vm.getUserPermissions.chat ? '' : _vm.chatUser(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineChat-icon",
        "width": "16.501",
        "height": "16.501",
        "viewBox": "0 0 16.501 16.501",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Chat")])], 1)], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.videoCall(user);
        }
      }
    }, [_c('vb-svg', {
      attrs: {
        "name": "dialer-outlineVideo-icon",
        "width": "20.6",
        "height": "13.6",
        "viewBox": "0 0 20.6 13.6",
        "stroke-width": "0",
        "stroke": "#005DFF",
        "fill": "none",
        "stroke-linecap": "round",
        "stroke-linejoin": "round"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Video")])], 1)], 1)], 1)]);
  }), _vm.isEmpty(_vm.favouriteAddressBook) && _vm.isEmpty(_vm.favouriteTeams) && _vm.isEmpty(_vm.favouriteCallQueue) && _vm.isEmpty(_vm.favouriteUsers) ? _c('vb-no-record', {
    attrs: {
      "text": 'Sorry, No Data Found',
      "design": 3
    }
  }) : _vm._e()], 2)])])])]), _c('SelectAddressBookNumberModal'), _c('DirectChatModal'), _c('TeamChatModal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }