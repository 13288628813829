
import { VOIP_API } from "../index"

export const RECORDING_API_ENDPOINTS = {
  list(params) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: 'app-sound-files',
      params,
    })
  },
  create(data,onUploadProgress=null) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'upload-file-app',
      data,
      onUploadProgress,
    })
  },
  update(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'update-sound-share-app',
      data,
    })
  },
  delete(id){
    return VOIP_API.axios.voip.request({
      method: "DELETE",
      url: `soundlibraries/${id}`,
    })
  },
  updateName(id='',data={}){
    return VOIP_API.axios.voip.request({
      method: "PATCH",
      url: `soundlibraries/${id}`,
      data,
    })
  },
  updateAllowRecording(data={}){
    return VOIP_API.axios.voip.request({
      method: "POST",
      url: `update-user-detail`,
      data,
    })
  },
  shift(data={}){
    return VOIP_API.axios.voip.request({
      method: "POST",
      url: `shift-sound-to-greeting`,
      data,
    })
  },
}