<template>
  <div class="dialer-settings-main">
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" class="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Number Blocking</div>
      <div class="plus-IOSIcon-container">
        <vb-icon 
          icon="plus-IOSIcon" 
          class="plus-IOSIcon" 
          width="20" 
          height="19.992" 
          @click="checkPermission(getUserPermissions.block_and_privacy,()=>$modal.show('AddBlockAndPrivacy'))" 
        />
      </div>
    </div>
    <section class="dialer-settings-section PrivacySettings">
      
      <template>
        
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-input type="text" placeholder="Search numbers" v-model.lazy="filter.rules.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
      </template>
      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div class="basicWhiteIOScard-item" v-for="rule in filterRules" :key="rule.id" 
          @click="checkPermission(getUserPermissions.block_and_privacy,()=>$modal.show('EditBlockAndPrivacy', { number: rule }))"
        >
          <div class="d-flex align-items-center">
            <div class="basicIOSIcon mr-16px">
              <vb-icon v-if="rule.type == 'both'" icon="NumberBlocking-incommingOutgoing-IOSIcon" class="redC" height="13.922" width="13.922"/>
              <vb-icon v-if="rule.type == 'in'" icon="NumberBlocking-incomming-IOSIcon" height="13.922" width="13.922"/>
              <vb-icon v-if="rule.type == 'out'" icon="NumberBlocking-outgoing-IOSIcon" height="13.922" width="13.922"/>
            </div>
            <div class="d-flex flex-column">
              <div :id="`Rule-${rule.id}`" class="headingInsideTable">{{ (rule.callerid || rule.callerid_format) | number_formater }}</div>
            </div>
          </div>
          <div class="d-flex align-items-center">
            <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ rule.displayname }}</div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
          </div>
        </div>
      </div>
      <InfiniteLoading ref="rules_infinity_loader" @infinite="fetchRules">
        <div slot="spinner">
          <vb-loading type="loader" />
        </div>
        <div slot="no-more"></div>
        <div slot="no-results">
          <vb-no-record :text="'There is no Numbers'" :design="3" />
        </div>
        <template #error="{ trigger }">
          <div>
            <span>{{ api.rules.error_message }}</span>
            <b-icon icon="arrow-repeat" class="ml-3" variant="primary" font-scale="1.5" @click="trigger()" />
          </div>
        </template>
      </InfiniteLoading>

      
      <AddBlockAndPrivacyModal @interface="afterAdding" />
      <EditBlockAndPrivacyModal @interface="afterAdding" />

    </section>
  </div>
</template>

<script>
import AddBlockAndPrivacyModal from "../modals/blockandprivacy/AddBlockAndPrivacy.vue";
import EditBlockAndPrivacyModal from "../modals/blockandprivacy/EditBlockAndPrivacy.vue";
import { VOIP_API } from "../../../utils";
import { mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
import { permission_handling } from '@/mixin';
export default {
  name: "BlockAndPrivacy",
  mixins: [permission_handling],
  components: {
    AddBlockAndPrivacyModal,
    EditBlockAndPrivacyModal,
    InfiniteLoading,
  },
  data() {
    return {
      response: {
        rules: [],
      },
      pagination: {
        rules: {
          per_page: 10,
          page: 1,
          total: 0,
        },
      },
      filter: {
        rules: {
          search: '',
          direction: '',
        }
      },
      api: {
        rules: this.$helperFunction.apiInstance({ error_message: true }),
      },
    };
  },
  inject:['isEmpty'],
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
    }),
    filterRules(){
      const search = this.filter.rules.search.trim().toLowerCase()
      const direction = this.filter.rules.direction
      const rules = this.response.rules
      const filter_rules = rules.filter(item=>
        `${(item.callerid || '').replace(/-/g,'')} ${item.displayname}`.trim().toLowerCase().includes(search) &&
        (!direction || item.type == direction)
      )
      return filter_rules
    },
    directionOptions(){
      return [
        {
          text: 'All',
          value: '',
        },
        {
          text: 'Inbound',
          value: 'in',
        },
        {
          text: 'Outbound',
          value: 'out',
        },
        {
          text: 'in and out bound',
          value: 'both',
        },
      ]
    },
  },
  filters: {
    direction(value) {
      if (value === "in") return "Inbound";
      else if (value === "out") return "Outbound";
      else if (value === "both") return "in and out bound";
      return "";
    },
  },
  methods: {
    fetchRules(reset) {
      let vm = this
      if(vm.api.rules.send) return;
      if(reset==true){
        vm.response.rules = []
        vm.pagination.rules.page=1
        vm.$refs.rules_infinity_loader?.stateChanger?.reset?.()
      }
      vm.api.rules.send=true
      vm.api.rules.error_message=''
      VOIP_API.endpoints.inbound_rule.list({
        fid: 12,
        page: vm.pagination.rules.page,
      })
      .then(({ data, no_record }) => {
        const rules = data?.data?.list ?? []
        const total = data?.total ?? 0
        vm.pagination.rules.total = total
        if(vm.pagination.rules.page==1) vm.response.rules = rules
        else vm.response.rules = [...vm.response.rules,...rules]
        if(no_record) vm.$refs.rules_infinity_loader?.stateChanger?.complete?.()
        else {
          vm.$refs.rules_infinity_loader?.stateChanger?.loaded?.()
          vm.pagination.rules.page=vm.pagination.rules.page+1
          if(vm.response.rules.length<vm.pagination.rules.total) setTimeout(()=>vm.$refs.rules_infinity_loader.attemptLoad(true),2 * 1000)
          else vm.$refs.rules_infinity_loader?.stateChanger?.complete?.()
        }
      })
      .catch((ex)=>{
        vm.$refs.rules_infinity_loader?.stateChanger?.error?.()
        vm.api.rules.error_message=ex.own_message || ex.message
      })
      .finally(() => {
        vm.api.rules.send=false
      });
    },
    afterAdding() {
      let vm = this
      setTimeout(() => vm.fetchRules(true), 2 * 1000);
    },
  },
  activated() {
    setTimeout(()=>this.fetchRules(true),0.5*1000)
  },
};
</script>