<template>
  <div>
    <modal
      class="dialer_animation right_side_popup NumbersNumberAddressModal"
      width="40%"
      height="auto"
      :scrollable="true"
      :name="modalName"
      @before-open="beforeOpen"
    >
      <section class="ct-user-new dialer-edit">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="handleSubmit">
          <div class="dialer-edit-header">
            <div>
              <h2 class="dialer-settings-title newer mb-0">Address</h2>
            </div>
            <div class="dialer-edit-actions">
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
              <a v-else
                class="newCloseButton"
                @click="$modal.hide(modalName)"
              >
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>

          <div class="latestGreyBox-9-9-2023 mt-20px">
            <h5 class="latestGreyBox-heading-main">
              Address type required: {{ address_required }}
            </h5>

            <p class="latestGreyBox-descriptionText"><b>None</b> means no address is required.</p>
            <p class="latestGreyBox-descriptionText">
              <b>Any</b> means an address is required, but it can be anywhere in
              the world.
            </p>
            <p class="latestGreyBox-descriptionText">
              <b>Local</b> means an address in the phone number's country is
              required.
            </p>
            <p class="latestGreyBox-descriptionText">
              <b>Foreign</b> means an address outside of the phone number's
              country is required.
            </p>
          </div>
          <div class="mt-20px">
            <!-- <div v-if="true" class="dialer-box grayBox table"> -->
            <div v-if="true" class="latestTableDesign-withBlackBorders-again mt-0 dialer-box alt table ">
              <table class="dialer-table">
                <thead>
                  <tr>
                    <th class="dialer-has-sort one">
                      <span>Street</span>
                    </th>
                    <th class="dialer-has-sort two">
                      <span>City</span>
                    </th>
                    <th class="dialer-has-sort three">
                      <span>Postcode</span>
                    </th>
                    <th class="dialer-has-sort w-auto four">
                      <span class="dialer-sorted-by ascending">Country</span>
                    </th>
                    <th class="dialer-col-right five">
                      <span>Action</span>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  <tr class="dialer-row-select" v-for="location in locations" :key="location.real_id">
                    <td class="one">
                      {{ location.street }}
                    </td>
                    <td class="two">
                      {{ location.town }}
                    </td>
                    <td class="three">
                      {{ location.postcode }}
                    </td>
                    <td class="dialer-row-title w-auto four">
                      {{ location.country }}
                    </td>
                    <td class="dialer-col-right five">
                      <a class="dialer-button dialer-button-primary" @click="selectLocation(location)">use</a>
                    </td>
                  </tr>
                </tbody>
              </table>
              <div class="dialer-table no-data tun" v-if="locations.length == 0">
                <span class="dialer-no-table-data">No address found </span>
              </div>
            </div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox">
              <div class="whiteBoxWithBorderInsideGreyBox-row">
                <div class="latestGreyBox-heading-main">Stored addresses</div>
                <a
                  class="dialer-button dialer-button-primary"
                  @click="$modal.show(`${modalName}-AddLocationModal`,{
                    flag: 'unpurchased',
                    callerId: data.new_number.number
                  })"
                >
                  Add new
                </a>
              </div>
            </div>
          </div>
          <!-- <div
            v-if="true"
            class="
              md-mar-vertical
              d-flex
              justify-content-between
              align-items-center
            "
          >
            <h4 class="mb-0">Stored Addresses</h4>
            <a
              class="dialer-button dialer-button-primary"
              @click="$modal.show(`${modalName}-AddLocationModal`,{
                flag: 'unpurchased',
                callerId: data.new_number.number
              })"
            >
              Add New
            </a>
          </div> -->
        </form>
      </section>
    </modal>
    <AddLocationModal :modalName="`${modalName}-AddLocationModal`" @latest-list="fetchLocations()" />
  </div>
</template>

<script>
// import TelephoneNumberService from "@/services/TelephoneNumberService";
import { required, minLength, maxLength } from "vuelidate/lib/validators";
// import AddressService from "@/services/AddressService";
import { VOIP_API, LOGGER, $fn } from "../../utils";
import AddLocationModal from "./AddLocationModal.vue";
import _ from "lodash";
export default {
  name: "AddAddressModal",
  components: {
    AddLocationModal,
  },
  props: {
    modalName: {
      type: String,
      default: 'AddAddressModal'
    },
  },
  data() {
    return {
      addresses: [],
      countries: [],
      cities: [],
      numberservice: null,
      msg: null,
      address_required: "none",
      addAddress: false,

      forms: {
        address: {
          customer_name: null,
          street: null,
          city: null,
          postal_code: null,
          country: null,
          provider: null,
          house_name: null,
          reset() {
            this.customer_name = null;
            this.street = null;
            this.city = null;
            this.postal_code = null;
            this.country = null;
            this.provider = null;
            this.house_name = null;
          },
        },
        add_location: {
          callerId: "99998888",
          country: "",
          surname: "",
          forename: "",
          reset() {
            this.callerId = "99998888";
            this.country = "";
            this.surname = "";
            this.forename = "";
          },
        },
        manual_address: {
          postal_code: "",
          city: "",
          street: "",
          house_name: "",
          reset() {
            this.postal_code = "";
            this.city = "";
            this.street = "";
            this.house_name = "";
          },
        },
        uk_address: {
          postal_code: "",
          house_name: "",
          address: "",
          reset() {
            this.postal_code = "";
            this.house_name = "";
            this.address = "";
          },
        },
        adding_caller_id: {
          number: "",
          reset() {
            this.number = "";
          },
        },
        verify_caller_id_pin: {
          pin: "",
          reset() {
            this.pin = "";
          },
        },
      },
      is_manual_address: true,
      is_user_home_address: false,
      add_caller_id_screen: "number",
      response: {
        list: {
          callerId: [],
          countries: [],
          extensionCount: 0,
          extensions: {},
        },
        address: {
          addresses: [],
          latitude: 0,
          longitude: 0,
        },
        locations: [],
      },
      submitted: {
        add: false,
        check: false,
        verify: false,
        address: false,
      },
      api_sent: {
        add: false,
        list: false,
        addresses: false,
        check: false,
        verify: false,
        locations: false,
      },
      api_status: {
        addresses: "",
      },
      errors: {
        addresses: {},
      },
      data: {
        new_number: {},
      },
    };
  },
  inject:['isEmpty','appNotify'],
  computed: {
    addLocationForm() {
      const address = this.forms.uk_address.address.split(",");
      return {
        street_address: this.is_manual_address
          ? this.forms.manual_address.street
          : address[0].trim(),
        locality: this.is_manual_address ? "" : "",
        county: this.is_manual_address ? "" : address[6].trim(),
        is_manual: this.is_manual_address,
        town: this.is_manual_address
          ? this.forms.manual_address.city
          : address[5].trim(),
        city: this.is_manual_address
          ? this.forms.manual_address.city
          : address[5].trim(),
        postcode: this.is_manual_address
          ? this.forms.manual_address.postal_code
          : this.forms.uk_address.postal_code,
        country: this.forms.add_location.country,
        house: this.is_manual_address
          ? this.forms.manual_address.house_name
          : this.forms.uk_address.house_name,
        caller: this.forms.add_location.callerId || "99998888",
        forename: this.forms.add_location.forename,
        surname: this.forms.add_location.surname,
      };
    },
    locations(){
      return this.response.locations.filter(item=>item.address_id=='0')
    },
  },
  validations: {
    forms: {
      address: {
        customer_name: { required },
        street: { required },
        city: { required },
        postal_code: {
          required,
          valid: function(...args){ return $fn.isValidUKPostcode(...args) },
        },
        country: { required },
        house_name: { required },
      },
      add_location: {
        callerId: {
          required,
        },
        country: {
          required,
        },
        surname: {
          required,
        },
        forename: {
          required,
        },
      },
      manual_address: {
        postal_code: {
          required,
        },
        city: {
          required,
        },
        street: {
          required,
        },
        house_name: {
          required,
        },
      },
      uk_address: {
        postal_code: {
          required,
          valid: function(...args){ return $fn.isValidUKPostcode(...args) },
        },
        house_name: {
          required,
        },
        address: {
          required,
        },
      },
      adding_caller_id: {
        number: {
          required,
          minLength: minLength(8),
          maxLength: maxLength(12),
        },
      },
      verify_caller_id_pin: {
        pin: {
          required,
          minLength: minLength(6),
        },
      },
    },
  },
  watch: {
    "forms.add_location.country"(country) {
      this.is_manual_address = country !== "GB";
    },
    "forms.uk_address.postal_code"(postal_code) {
      this.resetresponse("address");
      this.forms.uk_address.address = "";
      if (this.$v.forms.uk_address.postal_code.valid) {
        this.getAddress(postal_code);
      }
    },
    "forms.address.postal_code"(postal_code) {
      this.resetresponse("address");
      this.forms.address.street = "";
      if (this.$v.forms.address.postal_code.valid) {
        this.getAddress(postal_code);
      }
    },
  },
  methods: {
    getAddress(value) {
      let vm = this;
      VOIP_API.endpoints.location.getAddress(value).then(({ data }) => {
        vm.response.address = data;
      });
    },
    resetresponse(type) {
      if (type === "address") {
        this.response.address.addresses = [];
        this.response.address.latitude = 0;
        this.response.address.longitude = 0;
      }
    },
    load() {
      if (this.api_sent.list) return;
      this.api_sent.list = true;
      VOIP_API.endpoints.location
        .listcountriesandextensions()
        .then(({ data }) => {
          this.response.list = data;
        })
        .catch((error) => {
          LOGGER.danger(error);
        })
        .finally(() => {
          this.api_sent.list = false;
        });
    },
    addLocation() {
      this.submitted.add = true;
      this.$v.$touch();
      // console.log(
      //   this.$v.forms.add_location.$invalid ||
      //     this.api_sent.add ||
      //     (this.is_manual_address
      //       ? this.$v.forms.manual_address.$invalid
      //       : this.$v.forms.uk_address.$invalid)
      // );
      if (
        this.$v.forms.add_location.$invalid ||
        this.api_sent.add ||
        (this.is_manual_address
          ? this.$v.forms.manual_address.$invalid
          : this.$v.forms.uk_address.$invalid)
      )
        return;
      this.api_sent.add = true;
      VOIP_API.endpoints.location
        .add({
          ...this.addLocationForm,
          accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
          uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
        })
        .then(() => {
          this.$modal.hide("AddLocationModal");
          this.appNotify({
            message: "Successfully Added!",
            type: "success",
          })
          this.forms.add_location.reset();
          this.forms.manual_address.reset();
          this.forms.uk_address.reset();
          this.addAddress = false;
          this.getAddress();
        })
        .catch((ex) => {
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(() => {
          this.submitted.add = false;
          this.api_sent.add = false;
        });
    },

    beforeOpen(event) {
      this.load();
      this.fetchLocations();
      // this.service = this.$serviceFactory(new AddressService(), this);
      // this.numberservice  = this.$serviceFactory(new TelephoneNumberService(), this);
      // console.log("event", event);
      this.msg = null;
      this.addresses = [];
      this.cities = [];
      this.data.new_number=_.get(event, "params.data") || {};
      this.countries = _.get(event, "params.countries") || "";
      this.forms.address.country = _.get(event, "params.data.country") || "";
      this.forms.address.provider = _.get(event, "params.data.provider") || "";
      this.address_required = _.get(event, "params.address_required") || "none";

      this.fetchUserAddress();
      this.fetchCities();
    },
    selectLocation(location){
      this.$emit("interface", { location: location });
      this.$modal.hide(this.modalName);
    },
    selectAddress(address) {
      this.$emit("interface", { address: address });
      this.$modal.hide(this.modalName);
    },
    fetchLocations() {
      let vm = this;
      vm.api_sent.locations = true;
      VOIP_API.endpoints.location
        .list({
          flag: "numbers",
        })
        .then(({ data: response }) => {
          vm.response.locations = response.data;
        })
        .finally(() => {
          vm.api_sent.locations = false;
        });
    },
    async fetchCities() {
      await VOIP_API.endpoints.telephone_number
        .cities(this.forms.address.country)
        .then(({ data: res }) => {
          this.cities = res;
        });
      // let id = '/'+this.address.country;
      // await this.numberservice.append(id).call("cities").then((res) => {
      //     this.cities = res;
      // });
    },
    async fetchUserAddress() {
      // let obj = "?country="+this.address.country;
      await VOIP_API.endpoints.address
        .list({
          country: this.forms.address.country,
        })
        .then(({ data: res }) => {
          this.addresses = res.data;
        })
        .catch((/* error */) => {
          // console.log(error);
        });
      // await this.service
      //   .append(obj)
      //   .call("list")
      //   .then((res) => {
      //       this.addresses = res.data;
      //   })
      //   .catch((error) => {
      //     console.log(error);
      //   });
    },
    async handleSubmit() {
      this.submitted.address = true;
      this.$v.forms.address.$touch();
      if (this.$v.forms.address.$invalid) return;
      this.api_sent.address = true;
      VOIP_API.endpoints.address
        .create(this.forms.address)
        .then(({ data: res }) => {
          this.addresses = res.data;
          this.forms.address.street = null;
          this.forms.address.house_name = null;
          this.forms.address.customer_name = null;
          this.forms.address.postal_code = null;
          this.appNotify({
            message: "Address Added Successfully",
            type: "success",
          })

          this.addAddress = false;
        })
        .catch((ex) => {
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(() => {
          this.submitted.address = false;
          this.api_sent.address = false;
        });
    },
  },
};
</script>
