<template>
  <div>
    
    <modal class="center-zoomIn-transition v2 m-w-500 SmallAddModal" width="500px" height="auto" :scrollable="true" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
      <section class="ct-user-new dialer-edit s1">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="addPlaylist()">
          <div v-if="$store.getters.getIsMobile" class="dialer-edit-header flex-column">
            <div class="d-flex w-100 justify-content-between align-items-center">
              <h2 class="dialer-edit-title">Add playlist</h2>
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            </div>
            <!-- <div class="dialer-edit-actions w-100 d-flex justify-content-end mt-2">
              <button class="dialer-button dialer-button-primary" :disabled="api.add_playlist.send">
                <vb-spinner v-if="api.add_playlist.send" />
                <template v-else>Done</template>
              </button>
            </div> -->
          </div>
          <div v-else class="dialer-edit-header d-block pb-0">
            <div class="d-flex justify-content-between aling-items-center">
              <div>
                <h2 class="dialer-settings-title newer mb-0">Add playlist</h2>
              </div>
              <div class="dialer-edit-actions">
                <a class="newCloseButton" @click="$modal.hide(modalName)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <div class="vm--modal-text">
              Enter the name of the new playlist below.
            </div>
          </div>
          <div class="md-mar-vertical">
            <b-alert :show="!!api.add_playlist.error_message" variant="danger">{{api.add_playlist.error_message}}</b-alert>
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <div class="whiteBGinputWithGreyRoundedBorder">
                <label>Name</label>
                <input placeholder="My playlist etc..." class="w-100" type="text" :disabled="api.add_playlist.send" v-model="forms.add_playlist.name" />
                <p v-if="(forms.add_playlist.submitted && $v.forms.add_playlist.name.$invalid) || api.add_playlist.validation_errors.name" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.add_playlist.name.required">* Name is required</span>
                  <span v-for="(em, i) in api.add_playlist.validation_errors.name" :key="i">* {{em}}</span>
                </p>
              </div>
            </div>
          </div>
          <div class="mt-4">
            <button class="fullWidthDoneButton" :disabled="api.add_playlist.send">
              <vb-spinner v-if="api.add_playlist.send" />
              <template v-else>Create playlist</template>
            </button>
          </div>










          <!--<div class="md-mar-vertical">
            <div class="dialer-team-name mb-4">
              <b-alert :show="!!api.add_playlist.error_message" variant="danger">{{api.add_playlist.error_message}}</b-alert>
              <div class="dialer-input-field input-consistent-inner-shadows">
                <div class="w-100">
                  <label>Name</label>
                  <input placeholder="My Playlist etc..." class="w-100" type="text" :disabled="api.add_playlist.send" v-model="forms.add_playlist.name" />
                </div>
                <p v-if="(forms.add_playlist.submitted && $v.forms.add_playlist.name.$invalid) || api.add_playlist.validation_errors.name" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.add_playlist.name.required">* Name is required</span>
                  <span v-for="(em, i) in api.add_playlist.validation_errors.name" :key="i">* {{em}}</span>
                </p>
              </div>
            </div>
          </div>-->
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { VOIP_API } from '../../utils';
export default {
  name: "AddPlayListModal",
  props: {
    modalName: {
      type: String,
      default: 'AddPlayListModal'
    },
  },
  data() {
    return {
      forms: {
        add_playlist: this.$helperFunction.formInstance({
          data: {
            name: ''
          }
        }),
        reset(){
          this.add_playlist?.reset?.()
        },
      },
      api: {
        add_playlist: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true }),
        reset(){
          this.add_playlist?.reset?.()
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  validations: {
    forms: {
      add_playlist: {
        name: {
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.forms.add_playlist.name = event?.params?.suggested_name ?? '';
    },
    onBeforeClose() {
      this.forms.reset()
      this.api.reset()
    },
    addPlaylist() {
      let vm = this
      vm.forms.add_playlist.submitted = true;
      vm.$v.forms.add_playlist.$touch();
      if (vm.$v.forms.add_playlist.$invalid || vm.api.add_playlist.send) return;
      vm.api.add_playlist.send=true
      vm.api.add_playlist.validation_errors = {};
      vm.api.add_playlist.error_message = '';
      VOIP_API.endpoints.music_on_hold.create({
        playlistname: vm.forms.add_playlist.name
      },{
        fid: 12
      })
      .then(() => {
        vm.$modal.hide(vm.modalName);
        vm.appNotify({
          message: "Successfully Added!",
          type: "success",
        })
        vm.$emit("interface");
      })
      .catch((ex) => {
        vm.api.add_playlist.validation_errors = ex.own_errors ?? {};
        vm.api.add_playlist.error_message = ex.own_message ?? '';
      }).finally(()=>{
        vm.forms.add_playlist.submitted = false;
        vm.api.add_playlist.send = false;
      });
    },
  },
};
</script>
