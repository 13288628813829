var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.delete.send || _vm.api.set.send ? '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v(_vm._s(_vm.conditions.is_primary ? 'Primary' : 'Secondary') + " Cards")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        '';
      }
    }
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    }
  })], 1)]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_vm._l(_vm.cards, function (card) {
    return _c('div', {
      key: card.real_id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          return _vm.setPriority(card.real_id);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "basicIOSIcon mr-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "Billing-MasterCard-IOSIcon"
      }
    })], 1), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "headingInsideTable d-flex"
    }, [_vm._v(" Mastercard "), _c('div', {
      staticClass: "s1-c-container"
    }, _vm._l(4, function (i) {
      return _c('div', {
        key: i,
        staticClass: "s1-c"
      });
    }), 0), _vm._v(" " + _vm._s(_vm._f("get_property")(card, 'digits', '0000')) + " ")]), card.primary == 'yes' ? _c('div', {
      staticClass: "textInsideCard primaryColor"
    }, [_vm._v("Primary")]) : card.secondary == 'yes' ? _c('div', {
      staticClass: "textInsideCard primaryColor"
    }, [_vm._v("Seconday")]) : _vm._e()])]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard textAlignRight onRightSide mr-6px"
    }, [_vm._v(" Expires " + _vm._s(_vm._f("get_property")(card, 'expiry_month', '00')) + "/" + _vm._s(_vm._f("get_property")(card, 'expiry_year', '00')) + " ")]), _c('span', {
      on: {
        "click": function ($event) {
          $event.stopPropagation();
          return _vm.deleteCard(card.real_id);
        }
      }
    }, [_c('vb-icon', {
      staticClass: "rightFacingArrow fillDanger",
      attrs: {
        "icon": "trash-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)])]);
  }), _vm.cards.length == 0 ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.cards.send ? '' : 'There is no playlist',
      "design": 3
    }
  }, [_vm.api.cards.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }