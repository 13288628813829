<template>
  <modal class="center-zoomIn-transition sipActiveCall-Modal SelectSnoozeTimeModal SelectSnoozeTimeModal-fromLeftSideBar" transition="center-zoomIn-transition" :name="modalName" @before-open="onBeforeOpen($event)" @before-close="onBeforeClose()">
    <div class="dialer-call-notification-tray">
      <div class="dialer-call-notification dialer-keypadNotification">
        <!-- <div class="dialer-call-notification-close" @click="$modal.hide(modalName)">
          <vb-icon icon="dialer-close-icon" width="14" height="14" />
        </div>
        <div class="dialer-call-notification-inner">
          <div class="dialer-call-notification-text">
            <div class="dialer-call-notification-main-text">Select Time for Snooze</div>
          </div>
        </div> -->
        <div class="dialer-edit-header d-block pb-0">
          <div class="d-flex justify-content-between align-items-center">
            <div class="d-flex align-items-center">
              <h2 class="dialer-settings-title newer">Select time for snooze</h2>
            </div>
            <div class="dialer-edit-actions">
              <a class="newCloseButton" @click="$modal.hide(modalName)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
        </div>
        <div class="dialer-call-notification-actions mt-20px">
          <div class="TabsDesignWithIcon-container mb-20px">
            <div class="TabsDesignWithIcon-item" v-for="tab in tabs" :key="tab.value" @click="selected.tab=tab.value" :class="{'active': selected.tab==tab.value}">
              <vb-icon :icon="tab.icon" height="38px" width="38px" />
              <span class="TabsDesignWithIcon-item-textPart">{{tab.text}}</span>
            </div>
          </div>
          <template v-if="selected.tab==tabs.time.value">
            <div class="latestGreyBox-9-9-2023">
              <div class="d-flex justify-content-between mb-20px">
                <span >{{ current_date.time }}</span>
                <span >{{ duration | time_audio }}</span>
              </div>
              <b-time class="newlyDesignedTimeSection" v-model="forms.time.time" show-seconds locale="en"></b-time>
              <template v-if="forms.time.submitted">
                <p v-if="$v.forms.time.time.$invalid" class="w-100 text-left error-red">
                  <span v-if="!$v.forms.time.time.required">* Time is required</span>
                  <span v-else-if="!$v.forms.time.time.valid">* Time is invalid</span>
                  <span v-else-if="!$v.forms.time.time.invalid">* Time is should be after current time</span>
                </p>
              </template>
            </div>
          </template>
          <template v-else-if="selected.tab==tabs.timer.value">
            <div class="mainWrapper dialer-box grayBox">
              <div class="hoursWrapper">
                <div class="heading-container">HH</div>
                <div class="inputButton-container">
                  <button class="dialer-button dialer-button-primary" :disabled="parseInt(forms.timer.hours) <= '0'" @click="forms.timer.hours = parseInt(forms.timer.hours)-1">-</button>
                  <div class="dialer-input-field input-consistent-inner-shadows whiteBg-input">
                    <input disabled :value="forms.timer.hours" />
                  </div>
                  <button class="dialer-button dialer-button-primary" @click="forms.timer.hours = parseInt(forms.timer.hours)+1">+</button>
                </div>
              </div>
              <div class="mintsWrapper">
                <div class="heading-container">MM</div>
                <div class="inputButton-container">
                  <button class="dialer-button dialer-button-primary" :disabled="parseInt(forms.timer.mints) <= '0'" @click="forms.timer.mints = parseInt(forms.timer.mints)-1">-</button>
                  <div class="dialer-input-field input-consistent-inner-shadows whiteBg-input">
                    <input disabled :value="forms.timer.mints" />
                  </div>
                  <button class="dialer-button dialer-button-primary" :disabled="parseInt(forms.timer.mints) >= '60'" @click="forms.timer.mints = parseInt(forms.timer.mints)+1">+</button>
                </div>
              </div>
              <div class="scndsWrapper">
                <div class="heading-container">SS</div>
                <div class="inputButton-container">
                  <button class="dialer-button dialer-button-primary" :disabled="parseInt(forms.timer.seconds) <= '0'" @click="forms.timer.seconds = parseInt(forms.timer.seconds)-1">-</button>
                  <div class="dialer-input-field input-consistent-inner-shadows whiteBg-input">
                    <input disabled :value="forms.timer.seconds" />
                  </div>
                  <button class="dialer-button dialer-button-primary" :disabled="parseInt(forms.timer.seconds) >= '60'" @click="forms.timer.seconds = parseInt(forms.timer.seconds)+1">+</button>
                </div>
              </div>
            </div>
            <div v-if="forms.timer.submitted && $v.forms.timer.$invalid" class="w-100 text-left error-red">* time is required</div>
          </template>
          <button class="fullWidthDoneButton mt-20px" @click="onDone">
            Done
          </button>
        </div>
        <!-- <div class="container">
          <div class="clock">
            <label style="--i: 1"><span>1</span></label>
            <label style="--i: 2"><span>2</span></label>
            <label style="--i: 3"><span>3</span></label>
            <label style="--i: 4"><span>4</span></label>
            <label style="--i: 5"><span>5</span></label>
            <label style="--i: 6"><span>6</span></label>
            <label style="--i: 7"><span>7</span></label>
            <label style="--i: 8"><span>8</span></label>
            <label style="--i: 9"><span>9</span></label>
            <label style="--i: 10"><span>10</span></label>
            <label style="--i: 11"><span>11</span></label>
            <label style="--i: 12"><span>12</span></label>
            <div class="indicator">
              <span class="hand hour"></span>
              <span class="hand minute"></span>
              <span class="hand second"></span>
            </div>
          </div>
        </div> -->
        <!--<h1>
          Selected Time <br/> hh:{{forms.timer.hours}} mm:{{forms.timer.mints}}
        </h1>
        <div class="customClockContainer">
          <div class="customClockContainer-inner">
            <!~~ <label 
              v-for="n in 12" :key="n.id" 
              @click="forms.timer.hours = n"
              :style="`transform: rotate(calc(${n} * (360deg / 12)));`"
            >
              {{n}}
            </label> ~~>
            <label 
              v-for="n in clockHours" :key="n.id" 
              @click="forms.timer.hours = n.value"
              :style="`top: ${n.top}px;left: ${n.left}px;right:${n.right}px`"
            >
              {{n.value}}
            </label>
          </div>
        </div>-->
      </div>
    </div>
  </modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import moment from 'moment-timezone'
let interval_id;
let tabs = {
  time: {
    text: 'Time',
    value: 'time',
    icon: 'squared-configure-icon'
  },
  timer: {
    text: 'Timer',
    value: 'timer',
    icon: 'squared-configure-icon'
  },
}
export default {
  name: "CustomeSnoozeModal",
  props: {
    modalName: {
      type: String,
      default: "CustomeSnoozeModal",
    },
  },
  data() {
    return {
      forms: {
        time: this.$helperFunction.formInstance({
          data: {
            time: "",
          }
        }),
				timer: this.$helperFunction.formInstance({
          data: {
            hours:"00",
            mints:"00",
            seconds:"00"
          }
        }),
      },
      selected: {
        tab: tabs.timer.value,
      },
      current_date: {
        time: '',
      },
    };
  },
  computed:{
    tabs(){ return tabs },
    duration(){ 
      const current_time = this.current_date.time
      const selected_time = this.forms.time.time
      const startTime = moment(current_time, 'h:mm:ss A')
      const endTime = moment(selected_time, 'h:mm:ss A')
      const duration = moment?.duration?.(endTime?.diff?.(startTime))
      return this.$v.forms.time.time.invalid ? (duration.hours() * 60 * 60)+(duration.minutes() * 60)+duration.seconds() : 0; 
    },
		isTimer(){ return this.selected.tab==tabs.timer.value },

    // clockHours(){
    //   let array = []
    //   let count = 40
    //   let leftValue = 0
    //   let rightValue = 30
    //   for(let i =1;i<=12;i++){
    //     if(i<=6){
    //       if(i<=3){
    //         rightValue = 120 - (i * 30)
    //       }
    //       else{
    //         rightValue = (i * 30) - 120 +30
    //       }
    //       array.push({
    //         value: i,
    //         top: i * 40,
    //         left: 'unset',
    //         right: rightValue < 0 ? 0 : rightValue 
    //         // right: rightValue 
    //       })
    //       count = count + 40
    //       if(i==6){
    //         count = 240
    //       }
    //     }
    //     else{
    //       count = count - 40
    //       if(i<=9){
    //         leftValue = leftValue * i 
    //       }
    //       else{
    //         leftValue = 120 - (i * 30)
    //       }
    //       array.push({
    //         value: i,
    //         top: count >= 0 ? count : 0,
    //         left: leftValue < 0 ? 0 : leftValue ,
    //         right: 'unset'
    //       })
    //     }
    //   }
    //   return array
    // }
	},
  validations: {
    forms: {
      timer: {
        valid: (value)=>parseInt(value.mints) !== 0 || parseInt(value.seconds) !== 0 || parseInt(value.hours) !== 0,
      },
      time: {
        time: {
          required: required,
          valid: (value)=>/[0-9]{2}:[0-9]{2}:[0-9]{2}/i.test(value),
          invalid: function(value){
            let startTime = moment(moment().format('h:mm:ss A'), 'h:mm:ss A');
            let endTime = moment(value, 'h:mm:ss A');
            let duration = moment.duration(endTime.diff(startTime));
            let _seconds = (duration.hours() * 60 * 60)+(duration.minutes() * 60)+duration.seconds()
            return _seconds > 0
          }
        }
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      let vm = this
      this.forms.time.time = event?.params?.value || ''
      interval_id = setInterval(() => {
        vm.current_date.time = moment().format("h:mm:ss A");
      }, 1 * 1000);
    },
    onBeforeClose(){
      clearInterval(interval_id)
      this.selected.tab=tabs.timer.value
      this.forms.time.reset()
      this.forms.timer.reset()
    },
    onDone() {
      if(this.isTimer) {
        this.forms.timer.submitted = true
        this.$v.forms.timer.$touch()
        if(this.$v.forms.timer.$invalid) return;
        var _seconds = (parseInt(this.forms.timer.hours) * 60 * 60) + (parseInt(this.forms.timer.mints) * 60) + parseInt(this.forms.timer.seconds)
        // console.log(_seconds)
        this.$emit("time", {
          time_seconds: _seconds
        });
        this.$modal.hide(this.modalName);
        this.forms.timer.submitted = false
      } else {
        this.forms.time.submitted = true
        this.$v.forms.time.$touch()
        if(this.$v.forms.time.$invalid) return;
        let startTime = moment(moment().format('h:mm:ss A'), 'h:mm:ss A');
        let endTime = moment(this.forms.time.time, 'h:mm:ss A');
        let duration = moment.duration(endTime.diff(startTime));
        let _seconds = (duration.hours() * 60 * 60)+(duration.minutes() * 60)+duration.seconds()
        // console.log(_seconds);
        this.$emit("time", {
          time_seconds: _seconds
        });
        this.$modal.hide(this.modalName);
        this.forms.time.submitted = false
      }
    },
  },
};
</script>

<style lang="scss">

.custom-snooze-time {
  padding: $dialer-default-padding;
  .b-time {
    width: 100%;
    .b-time-header {
      output {
        border-radius: 12px;
        border: 1px solid #f0f0f0;
        // background-color: #f0f0f0;
        background-color: $chat_primary_user;
        display: block;
        width: 100%;
        height: calc(1.5em + 0.75rem + 2px);
        padding: 0.375rem 0.75rem;
        font-size: 1rem;
        font-weight: 400;
        line-height: 1.5;
        color: #495057;
      }
    }
    & > div {
      margin: $dialer-default-padding 0px;
      .b-form-spinbutton {
        margin: 0px 8px;
      }
    }
  }
}

.cancel-button-modal {
  padding: 5px 8px;
  border-radius: 50%;
  border: 0px;
  background-color: $primaryLight;
}



.customClockContainer{
  background-color: #f8f8f8;
  @include border-radius(50%);
  padding: 10px;
  height: 260px;
  width: 260px;
  margin: 16px auto;
  .customClockContainer-inner{
    background-color: white;
    @include border-radius(50%);
    padding: 0px;
    height: 240px;
    width: 240px;
    position: relative;
    label{
      position: absolute;
      // inset: 8px;
      text-align: center;
      margin-bottom: 0px;
      height: 16px;
      width: 16px;
      cursor: pointer;
      &.smaller{
        font-size: 8px;
        height: 8px;
        width: 8px;
      }
    }
  }
}











</style>





<style scoped>
:root {
  --primary-color: #f6f7fb;
  --white-color: #fff;
  --black-color: #18191a;
  --red-color: #e74c3c;
}
body {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  background: var(--primary-color);
}
body.dark {
  --primary-color: #242526;
  --white-color: #18191a;
  --black-color: #fff;
  --red-color: #e74c3c;
}
.container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 60px;
}
.container .clock {
  display: flex;
  height: 400px;
  width: 400px;
  border-radius: 50%;
  align-items: center;
  justify-content: center;
  background: var(--white-color);
  box-shadow: 0 15px 25px rgba(0, 0, 0, 0.1), 0 25px 45px rgba(0, 0, 0, 0.1);
  position: relative;
}
.clock label {
  position: absolute;
  inset: 20px;
  text-align: center;
  transform: rotate(calc(var(--i) * (360deg / 12)));
}
.clock label span {
  display: inline-block;
  font-size: 30px;
  font-weight: 600;
  color: var(--black-color);
  transform: rotate(calc(var(--i) * (-360deg / 12)));
}
.container .indicator {
  position: absolute;
  height: 10px;
  width: 10px;
  display: flex;
  justify-content: center;
}
.indicator::before {
  content: "";
  position: absolute;
  height: 100%;
  width: 100%;
  border-radius: 50%;
  z-index: 100;
  background: var(--black-color);
  border: 4px solid var(--red-color);
}
.indicator .hand {
  position: absolute;
  height: 130px;
  width: 4px;
  bottom: 0;
  border-radius: 25px;
  transform-origin: bottom;
  background: var(--red-color);
}
.hand.minute {
  height: 120px;
  width: 5px;
  background: var(--black-color);
}
.hand.hour {
  height: 100px;
  width: 8px;
  background: var(--black-color);
}
.mode-switch {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 22px;
  font-weight: 400;
  display: inline-block;
  color: var(--white-color);
  background: var(--black-color);
  box-shadow: 0 5px 10px rgba(0, 0, 0, 0.1);
  cursor: pointer;
}
</style>










