var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading"
  }, [_vm._v("Time off overview")]), _vm._m(0), _c('div', {
    staticClass: "dropdownsContainer"
  }, [_c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All teams")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All teams ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All locations")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All locations ")])], 1), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("All positions")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" All positions ")])], 1)], 1), _c('div', {
    staticClass: "grayCard withHeader"
  }, [_c('div', {
    staticClass: "withHeader-head"
  }, [_vm._v(" Heatmap "), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown mr-0",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("Sickday")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Sickday ")])], 1)], 1), _vm._m(1)]), _c('div', {
    staticClass: "grayCard withHeader mt-32px"
  }, [_c('div', {
    staticClass: "withHeader-head"
  }, [_vm._v(" Average % away "), _c('b-dropdown', {
    staticClass: "primaryBorderDropdown mr-0",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("Sickday")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Sickday ")])], 1)], 1), _vm._m(2)]), _c('div', {
    staticClass: "d-flex mt-32px"
  }, [_c('div', {
    staticClass: "grayCard withHeader heavyBorder halfWidth"
  }, [_c('div', {
    staticClass: "withHeader-head"
  }, [_vm._v(" Most days away ")]), _c('div', {
    staticClass: "withHeader-body"
  }, [_c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "nameWithImage"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/1.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Marie jane")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 days")])]), _c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "nameWithImage"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/2.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Marie jane")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 days")])]), _c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "nameWithImage"
  }, [_c('div', {
    staticClass: "imageContainer"
  }, [_c('img', {
    staticClass: "circle",
    attrs: {
      "src": require('@/assets/images/credit-card/3.jpeg')
    }
  })]), _c('div', {
    staticClass: "nameWithImage-text"
  }, [_vm._v("Marie jane")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 days")])])])]), _vm._m(3)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_c('br')]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "withHeader-body"
  }, [_c('div', {
    staticClass: "popularDay-container"
  }, [_c('div', {
    staticClass: "popularDay-inner"
  }, [_c('div', {
    staticClass: "popularDay"
  }, [_vm._v("Mo")]), _c('div', {
    staticClass: "popularDay"
  }, [_vm._v("Tu")]), _c('div', {
    staticClass: "popularDay"
  }, [_vm._v("We")]), _c('div', {
    staticClass: "popularDay"
  }, [_vm._v("Th")]), _c('div', {
    staticClass: "popularDay"
  }, [_vm._v("Fr")]), _c('div', {
    staticClass: "popularDay active"
  }, [_vm._v("Sa")]), _c('div', {
    staticClass: "popularDay"
  }, [_vm._v("Su")])]), _c('div', {
    staticClass: "popularDay-headingText"
  }, [_vm._v(" Most popular day: Saturday ")])])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "withHeader-body"
  }, [_c('div', {
    staticClass: "my-4"
  }, [_vm._v("0%")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "grayCard withHeader heavyBorder halfWidth"
  }, [_c('div', {
    staticClass: "withHeader-head"
  }, [_vm._v(" Most days away ")]), _c('div', {
    staticClass: "withHeader-body"
  }, [_c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "circleWithText"
  }, [_c('div', {
    staticClass: "circleContainer",
    staticStyle: {
      "background-color": "#D5761B"
    }
  }), _c('div', {
    staticClass: "circleWithText-text",
    staticStyle: {
      "color": "#D5761B"
    }
  }, [_vm._v("Sickday")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 x")])]), _c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "circleWithText"
  }, [_c('div', {
    staticClass: "circleContainer",
    staticStyle: {
      "background-color": "#5576D1"
    }
  }), _c('div', {
    staticClass: "circleWithText-text",
    staticStyle: {
      "color": "#5576D1"
    }
  }, [_vm._v("Holiday - UK")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 x")])]), _c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "circleWithText"
  }, [_c('div', {
    staticClass: "circleContainer",
    staticStyle: {
      "background-color": "#1A9211"
    }
  }), _c('div', {
    staticClass: "circleWithText-text",
    staticStyle: {
      "color": "#1A9211"
    }
  }, [_vm._v("Holiday - NL")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 x")])]), _c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "circleWithText"
  }, [_c('div', {
    staticClass: "circleContainer",
    staticStyle: {
      "background-color": "#D2A657"
    }
  }), _c('div', {
    staticClass: "circleWithText-text",
    staticStyle: {
      "color": "#D2A657"
    }
  }, [_vm._v("Business trip")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 x")])]), _c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "circleWithText"
  }, [_c('div', {
    staticClass: "circleContainer",
    staticStyle: {
      "background-color": "#5576D1"
    }
  }), _c('div', {
    staticClass: "circleWithText-text",
    staticStyle: {
      "color": "#5576D1"
    }
  }, [_vm._v("Holiday - Pak")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 x")])]), _c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "circleWithText"
  }, [_c('div', {
    staticClass: "circleContainer",
    staticStyle: {
      "background-color": "#7B67FF"
    }
  }), _c('div', {
    staticClass: "circleWithText-text",
    staticStyle: {
      "color": "#7B67FF"
    }
  }, [_vm._v("Conference")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 x")])]), _c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "circleWithText"
  }, [_c('div', {
    staticClass: "circleContainer",
    staticStyle: {
      "background-color": "#C7384F"
    }
  }), _c('div', {
    staticClass: "circleWithText-text",
    staticStyle: {
      "color": "#C7384F"
    }
  }, [_vm._v("Work from home (UK)")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 x")])]), _c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "circleWithText"
  }, [_c('div', {
    staticClass: "circleContainer",
    staticStyle: {
      "background-color": "#22B100"
    }
  }), _c('div', {
    staticClass: "circleWithText-text",
    staticStyle: {
      "color": "#22B100"
    }
  }, [_vm._v("Holiday USA")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 x")])]), _c('div', {
    staticClass: "withHeader-bodyRow"
  }, [_c('div', {
    staticClass: "circleWithText"
  }, [_c('div', {
    staticClass: "circleContainer",
    staticStyle: {
      "background-color": "#5576D1"
    }
  }), _c('div', {
    staticClass: "circleWithText-text",
    staticStyle: {
      "color": "#5576D1"
    }
  }, [_vm._v("Holiday")])]), _c('div', {
    staticClass: "bodyRow-text"
  }, [_vm._v("1 x")])])])]);

}]

export { render, staticRenderFns }