var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Unused days ")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Currently available days from selected policy, shown by the number of employees & split by teams. ")]), _c('div', {
    staticClass: "dropdownsContainer flex-column align-items-start"
  }, [_c('b-dropdown', {
    staticClass: "primaryBorderDropdown mt-16px",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("Holiday UK")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Holiday UK ")])], 1)], 1), _vm._m(0)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100 d-flex align-items-center justify-content-center mt-5"
  }, [_c('canvas', {
    attrs: {
      "id": "unused-days-chart"
    }
  })]);

}]

export { render, staticRenderFns }