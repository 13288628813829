<template>
  <div class="dialer-dropdown">
    <b-dropdown 
      :id="id" 
      :disabled="disabled" 
      ref="dropdown" 
      :text="Text ? Text.toString() : defaultSelectedText" 
      class="m-2"
    >
      <template #button-content>
        <span class="d-flex align-items-center p-0">
          <template v-if="isTextShow">
            <slot name="selected-option" :option="selectedOption" :onSelect="onSelect" >
              <span :style="textElStyle" :id="`${id}-text`">{{ Text ? Text : defaultSelectedText }}</span>
            </slot>
          </template>
          <span style="display:none;"  :id="`${id}-value`">{{ Value }}</span>
          <slot name="right-icon">
            <b-icon-chevron-down scale="1.1" />
          </slot>
        </span>
      </template>
      <b-dropdown-item v-if="options.length==0" class="noRecordItem">
        <slot name="no-record">{{ onEmptyListText }}</slot>
      </b-dropdown-item>
      <b-dropdown-item 
        :class="`${listClass} ${isMarkShow?'vb-select-item-lessPadding':''}`" 
        :disabled="disabled" 
        v-for="(option, index) in options" 
        :key="index" 
        @click="onSelect(option)"
      >
        <slot name="option" :option="option">
          <b-icon v-if="isCheckShow" :icon="selected==getProperty(option,`${valueField}`,option)?'check2':'blank'" class="mr-2" />
          {{ option | get_property(`${textField}`,option) }}
          <span v-if="isMarkShow && option.mark" class="checkMarkIconAtEnd">Default</span>
        </slot>
      </b-dropdown-item>
      <b-dropdown-item v-if="isSelectedOnBottom && Text" :class="listClass" disabled>
        <slot name="bottom-selected-option" :option="selectedOption">
          <b-icon icon="check2" class="mr-2" /> {{ Text }}
        </slot>
      </b-dropdown-item>
    </b-dropdown>
  </div>
</template>

<script>
import _ from 'lodash';
export default {
  name: "vb-select",
  model: {
    prop: 'selected',
    event: 'change'
  },
  inject: [
    'getProperty'
  ],
  props: {
    options: {
      type: Array,
      default: () => [],
    },
    selected: {
      type: [String, Number],
      default: "",
    },
    defaultSelectedText: {
      type: String,
      default: "",
    },
    onEmptyListText: {
      type: String,
      default: "There is nothing to show",
    },
    textElStyle: {
      type: String,
      default: "",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    id: {
      type: String,
      default: '',
    },
    listClass: {
      type: String,
      default: '',
    },
    isSelectedOnBottom: {
      type: Boolean,
      default: false,
    },
    isCheckShow: {
      type: Boolean,
      default: false,
    },
    isMarkShow: {
      type: Boolean,
      default: false,
    },
    isTextShow: {
      type: Boolean,
      default: true,
    },
    textField: {
      type: String,
      default: 'text',
    },
    valueField: {
      type: String,
      default: 'value',
    },
  },
  computed: {
    selectedOption(){ return this.options.find((option) => this.selected == this.getProperty(option,`${this.valueField}`,option)); },
    Text() { return this.getProperty(this.selectedOption,`${this.textField}`,this.selectedOption) || ""; },
    Value() { return this.getProperty(this.selectedOption,`${this.valueField}`,this.selectedOption)  || ""; },
  },
  methods: {
    onSelect(option){
      const value = _.get(option,`${this.valueField}`) ?? option
      if(value !== this.selected){
        this.$emit("change", value);
      }
    },
  },
};
</script>

<style lang="scss">
.dialer-dropdown {
  .dropdown {
    margin: 0px !important;

    .dropdown-menu {
      width: 240px;
      min-width: 220px;
      padding: 4px 4px
        4px 0px;
      @include box-shadow-mixin($box-shadow-dropdown);
      border: 12px solid $white;
      max-height: 500px;
      overflow-y: auto;
      li {
        border: none;

        .dropdown-header {
          text-align: left;
        }

        button {
          padding: 7px 20px;
          font-size: 14px;
        }

        .dropdown-header {
          color: $heading-dropdown-inner-color;
          font-weight: 600;
          display: inline-flex;
          background: transparent;
          padding-left: 0px;
          padding-right: 0px;
          font-size: $dialer-default-fontSize;
          padding-bottom: 0px;
          margin-bottom: 10px;
          margin-top: 10px;
        }

        ul {
          li {
            button {
              padding-left: 30px;
            }
          }
        }
        .dropdown-item {
          width: 100%;
          @include border-radius(8px);
          margin-bottom: 0px;
          font-size: $dialer-default-fontSize;
          &:hover {
            background-color: $receiverActivity-bg-color;
          }
        }
      }

      .b-dropdown-form {
        padding: 0px;

        .custom-control {
          padding-left: 0;
          padding-right: 1.5rem;
          display: flex;
          align-items: center;
          padding-top: 7px;
          padding-bottom: 7px;
          color: $text-color-secondary;
          font-weight: 400;
          border: none;

          .custom-control-input {
            left: unset;
            width: 20px;
            right: 4px;
          }

          .custom-control-label {
            cursor: pointer;
            font-size: 14px;
            width: 100%;

            &:before {
              top: 0;
              left: unset;
              right: -1.3rem;
              height: 16px;
              width: 16px;
            }

            &:after {
              top: 0;
              left: unset;
              right: -1.3rem;
              height: 16px;
              width: 16px;
            }
          }
        }
      }
    }

    .btn-secondary {
      color: $gray-800;
      background-color: $gray-950;
      border-color: $gray-950;
      @include border-radius(6px);

      &.dropdown-toggle {
        width: 100%;
        display: flex;
        justify-content: flex-end;
        align-items: center;
        color: $dialer-soundSettingDevice-selectBox-text-color;
        background-color: transparent;
        border-color: $dialer-soundSettingDevice-selectBox-text-color;
        font-size: 14px;
        font-family: $font-family-base;
        font-weight: 600;
        padding: 8px $dialer-default-padding;
        @include border-radius($border-radius);
        span {
          width: 100%;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 14px;
          font-family: $font-family-base;
          font-weight: 700;
          padding-right: $dialer-default-padding;
          text-align: right;
        }
        .b-icon {
          &.bi {
            font-size: 14px;
          }
        }

        &:after {
          display: none;
        }
      }
    }
  }
  &.selectVoice-dropdown {
    .dropdown {
      .dropdown-menu {
        width: auto;
        min-width: unset;
      }
    }
  }
}
</style>