<template>

	<div class="right-calender">
		<div class="top-main-calender scrollow1">
				<h5 class="d-flex align-items-center justify-content-start ml-4 emp-profile-heading">General</h5>
				<div class="calender-employee-top mt-5">
					<div class="calender-hrm-height mb-4">
						<div>
							<label class="calender-form-labels mr-imp" for="input-with-list">You company namme</label>
							<div>
								<b-form-input list="input-list"  placeholder="Fullduplex" class="form-hrm  mt-1"></b-form-input>
							</div>
						</div>
						<div>
							<label class="calender-form-labels" for="input-with-list">Phone number</label>
							<div>
								<b-form-input list="input-list"  placeholder="441873740607" class="form-hrm  mt-1"></b-form-input>
							</div>
						</div>
						<div>
							<label class="calender-form-labels" >Country</label>
							<div class="dd-container">
                <b-dropdown no-caret class="mainBorderfordropi">
                  <template #button-content>
                    <div class="button-content-dropi">
                      <div class="dropi-contenti">Mentor</div>
                      <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                  <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
                </b-dropdown>
							</div>
						</div>
						<div>
							<label class="calender-form-labels" >Default system language</label>
							<div class="dd-container">
								<b-dropdown no-caret class="mainBorderfordropi">
                  <template #button-content>
                    <div class="button-content-dropi">
                      <div class="dropi-contenti">Mentor</div>
                      <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                  <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
                </b-dropdown>
							</div>
						</div>
						<div>
							<label class="calender-form-labels" >Time zone</label>
							<div class="dd-container">
								<b-dropdown no-caret class="mainBorderfordropi">
                  <template #button-content>
                    <div class="button-content-dropi">
                      <div class="dropi-contenti">Mentor</div>
                      <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                  <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
                </b-dropdown>
							</div>
						</div>
						<div>
							<label class="calender-form-labels" >Date format</label>
							<div class="dd-container">
                <b-dropdown no-caret class="mainBorderfordropi">
                  <template #button-content>
                    <div class="button-content-dropi">
                      <div class="dropi-contenti">Mentor</div>
                      <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                    </div>
                  </template>
                  <b-dropdown-item class="dropi-dropdown-toggle">Full-time</b-dropdown-item>
                  <b-dropdown-item class="dropi-dropdown-toggle">Part-time</b-dropdown-item>
                </b-dropdown>
							</div>
						</div>
					</div>
				</div>
				<div class="calender-employee-top">
					<div class="calender-hrm-height mb-3">
						<h5 class="calender-page-subheading">Time format</h5>
							<div class="">
								<b-form-group class="gm-hrm-custom-radio">
									<b-form-radio name="some-radios" value="A">12-hour(3:00 PM)</b-form-radio>
									<b-form-radio name="some-radios" value="C">24-hour(15:00)</b-form-radio>
								</b-form-group>
							</div>
					</div>
				</div>
				<div class="calender-employee-top">
					<div class="calender-hrm-height mb-3">
            <h5 class="calender-page-subheading">Number of digits shown after comma</h5>
            <div class="mb-4 ">
              <b-form-group  class="gm-hrm-custom-radio">
                <div class="test-module">
                  <b-form-radio  name="some-radios" value="A" class="tuntun">None</b-form-radio>
                  <b-form-radio  name="some-radios" value="B">1(1.2)</b-form-radio>
                  <b-form-radio  name="some-radios" value="C">2(1.23)</b-form-radio>
                  <b-form-radio  name="some-radios" value="C">3(1.234)</b-form-radio>
                  <b-form-radio  name="some-radios" value="C">4(1.2345)</b-form-radio>
                </div>
              </b-form-group>
              <button class="btn btn-primarynewupdated1 mr-3">Save</button>
            </div>
					</div>
				</div>
		</div>
	</div>
</template>

<script>

export default {
  name: 'GeneralSettingHRM',
}
</script>

<style>

</style>