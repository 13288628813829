
import { VOIP_API } from "../index"

export const TELEPHONE_NUMBER_API_ENDPOINTS = {
  verifycalleridnumber(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `verifycalleridpincode`,
      data,
    })
  },
  list(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'user-callerid',
      data,
    })
  },
  update(account,data={}) {
    return VOIP_API.axios.voip.request({
      method: 'patch',
      url: `services/inline/${account}`,
      data,
    })
  },
  getdidassigned(account,params={}) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: `callerid/assigned/${account}`,
      params,
    })
  },
  delete(id='',data={}){
    return VOIP_API.axios.voip.request({
      method: 'patch',
      url: `telephonenumbers/action/${id}`,
      data,
    })
  },
  deleteNumber(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `delete-number`,
      data,
    })
  },
  deleteCallerID(data={}){
    return VOIP_API.axios.voip.request({
      method: 'POST',
      url: `delete-callerid`,
      data,
    })
  },
  numbers() {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: 'callerids'
    })
  },
  assigned_did(){
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: 'user-assigned-telephones'
    })
  },
  extensions(params={}) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: 'telephonenumbers/shortextensions',
      params,
    })
  },
  updateExtn(id,data={}) {
    return VOIP_API.axios.voip.request({
      method: 'patch',
      url: `telephonenumbers/${id}`,
      data,
    })
  },
  updateUserMode(id,data={}) {
    return VOIP_API.axios.voip.request({
      method: 'patch',
      url: `telephonenumbers/${id}/user-mode`,
      data,
    })
  },
  availablenumbers() {
    return VOIP_API.axios.voip.request({
      methods: 'post',
      url: '/telephonenumbers/numbers'
    })
  },
  create(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'callerids',
      data,
    })
  },
  verfiypincode(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'verifycalleridpincode',
      data,
    })
  },
  resendpincode(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'resendcalleridpincode',
      data,
    })
  },
  updateLabel(data,id) {
    return VOIP_API.axios.voip.request({
      method: 'PATCH',
      url: 'telephonenumbers/'+id,
      data,
    })
  },
  countries() {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: 'number-countries-list'
    })
  },
  fetchnumbers(data={}){
    return VOIP_API.axios.voip.request({
      url: "idt-number-list",
      method: "POST",
      data,
    })
  },
  fetchregions(){
    return VOIP_API.axios.voip.request({
      url: "get-usa-regions",
      method: "GET",
    })
  },
  fetchregiongroups(data={}){
    return VOIP_API.axios.voip.request({
      url: "region-did-group",
      method: "POST",
      data,
    })
  },
  cities(id) {
    return VOIP_API.axios.voip.request({
      method: 'get',
      url: `number-cities-by-country-list/${id}`,
    })
  },
  sendsms(data={}) {
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'app-send-sms',
      data,
    })
  },
  checksmsbalance(data={},CancelToken=''){
    return VOIP_API.axios.voip.request({
      method: 'post',
      url: 'check-sms-balance',
      data,
      CancelToken,
    })
  },
  availablelist(account='',params={}){
    return VOIP_API.axios.voip.request({
      url: `telephonenumbers/availble/${account}`,
      method: 'GET',
      params,
    })
  },
  assign(account='',data={}){
    return VOIP_API.axios.voip.request({
      url: `telephonenumbers/update_did/${account}`,
      method: 'PATCH',
      data,
    })
  },
  fetchCitiesNumber(data={}){
    return VOIP_API.axios.voip.request({
      url: `telephonenumbers/numbers`,
      method: 'POST',
      data,
    })
  },
  purchasenumber(data={}){
    return VOIP_API.axios.voip.request({
      url: `billingcons`,
      method: 'POST',
      data,
    })
  },
  numberSettings(data={}){
    return VOIP_API.axios.voip.request({
      url: `number-settings`,
      method: 'POST',
      data,
    })
  },
  getNumberSettings(params={}){
    return VOIP_API.axios.voip.request({
      url: `number-settings`,
      method: 'GET',
      params,
    })
  },
  manualnumber: {
    create(data){
      return VOIP_API.axios.voip.request({
        method: 'post',
        url: 'add-direct-number',
        data,
      })
    },
  },
}