<template>
  <modal 
    :name="modalName"
    class="dialer_animation right_side_popup scrollable bigSquareCheckbox IosModal CRM-modal" 
    width="50%" 
    :scrollable="true" 
    height="auto"
    @before-close="onBeforeClose()" 
    @before-open="onBeforeOpen($event)"
  >
    <div class="CRM-modal-header">
      <div class="CRM-modal-header-text">Create activity</div>
      <a class="newCloseButton" @click="api.create_activity.send ? '' : $modal.hide(modalName)">
        <vb-icon icon="squared-close-icon" height="38px" width="38px" />
        <span class="newCloseButton-textPart">Close</span>
      </a>
    </div>
    <b-form class="latestGreyBox-9-9-2023" @submit.prevent="createActivity()">
      <b-alert variant="danger" :show="!!api.create_activity.error_message">{{ api.create_activity.error_message }}</b-alert>
      <!-- title -->
      <div class="crm-input-container withoutIcon">
        <label>Title</label>
        <b-form-input :disabled="api.create_activity.send" v-model="forms.create_activity.title" placeholder="Title" />
        <p v-if="($v.forms.create_activity.title.$dirty && $v.forms.create_activity.title.$invalid) || api.create_activity.validation_errors.title">
          <span v-if="!$v.forms.create_activity.title.required">Title is required</span>
          <span v-for="em in api.create_activity.validation_errors.title" :key="em">* {{ em }}</span>
        </p>
      </div>
      <!-- activity type -->
      <SelectActivityTypeInput :disabled="api.create_activity.send" v-model="forms.create_activity.activity_type_id">
        <template #errors>
          <p v-if="($v.forms.create_activity.activity_type_id.$dirty && $v.forms.create_activity.activity_type_id.$invalid) || api.create_activity.validation_errors.activity_type_id">
            <span v-if="!$v.forms.create_activity.activity_type_id.required">activity type is required</span>
            <span v-for="em in api.create_activity.validation_errors.activity_type_id" :key="em">* {{ em }}</span>
          </p>
        </template>
      </SelectActivityTypeInput>
      <!-- due date -->
      <div class="d-flex align-items-center">
        <div class="crm-input-container VueCtkDateTimePicker subSectionLeftAligned">
          <vue-ctk-date-time-picker  
            :noClearButton="true"
            :disabled="api.create_activity.send"
            v-model="forms.create_activity.due.start_date"
            v-bind="ctkDateTimePickerOptions" 
            :format="'YYYY-MM-DD HH:mm'" 
          />
          <p v-if="($v.forms.create_activity.due.start_date.$dirty && $v.forms.create_activity.due.start_date.$invalid) || api.create_activity.validation_errors.due_date || api.create_activity.validation_errors.due_time">
            <span v-if="!$v.forms.create_activity.due.start_date.required">Start Date is required</span>
            <span v-for="em in api.create_activity.validation_errors.due_date" :key="em">* {{ em }}</span>
            <span v-for="em in api.create_activity.validation_errors.due_time" :key="em">* {{ em }}</span>
          </p>
        </div>
        <span class="mx-2 mb-16px"> - </span>
        <div class="crm-input-container VueCtkDateTimePicker subSectionRightAligned">
          <vue-ctk-date-time-picker 
            :noClearButton="true"
            :disabled="api.create_activity.send"
            :min-date="forms.create_activity.due.start_date"
            v-model="forms.create_activity.due.end_date"
            v-bind="ctkDateTimePickerOptions" 
            :format="'YYYY-MM-DD HH:mm'" 
          />
          <p v-if="($v.forms.create_activity.due.end_date.$dirty && $v.forms.create_activity.due.end_date.$invalid) || api.create_activity.validation_errors.end_date || api.create_activity.validation_errors.end_time">
            <span v-if="!$v.forms.create_activity.due.end_date.required">End Date is required</span>
            <span v-for="em in api.create_activity.validation_errors.end_date" :key="em">* {{ em }}</span>
            <span v-for="em in api.create_activity.validation_errors.end_time" :key="em">* {{ em }}</span>
          </p>
        </div>
      </div>
      <!-- reminder -->
      <div class="w-100 d-flex align-items-end">
        <div class="crm-input-container w-25 min-width-250px mr-3">
          <label>Reminder</label>
          <div class="crm-input-inner w-100 position-relative">
            <b-button :disabled="api.create_activity.send" @click="forms.create_activity.reminder.enable=!forms.create_activity.reminder.enable" variant="link">
              <b-icon v-if="forms.create_activity.reminder.enable" icon="x" variant="danger" class="crm-input-icon" />
              <b-icon v-else icon="x" variant="danger" class="crm-input-icon" />
            </b-button>
            <b-form-input :disabled="!forms.create_activity.reminder.enable" v-model="forms.create_activity.reminder.before" placeholder="minutes" type="number" />
            <p v-if="($v.forms.create_activity.due.end_date.$dirty && $v.forms.create_activity.due.end_date.$invalid) || api.create_activity.validation_errors.end_date || api.create_activity.validation_errors.end_time">
              <span v-if="!$v.forms.create_activity.due.end_date.required">Reminder is required</span>
              <span v-for="em in api.create_activity.validation_errors.end_date" :key="em">* {{ em }}</span>
              <span v-for="em in api.create_activity.validation_errors.end_time" :key="em">* {{ em }}</span>
            </p>
          </div>
        </div>
        <div class="crm-main-dd dropdownVersion mr-3 mb-16px">
          <b-dropdown no-caret :disabled="!forms.create_activity.reminder.enable || api.create_activity.send">
            <template #button-content>
              <span class="d-flex align-items-center p-0">
                <span class="mr-2">{{forms.create_activity.reminder.type}}</span>
                <b-icon-chevron-down scale="1.1" />
              </span>
            </template>
            <b-dropdown-item v-for="type in ['minutes','hours','days','weeks']" :key="type" @click="forms.create_activity.reminder.type=type" >
              <span>{{type}}</span>
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="d-flex align-items-center h-40px mb-16px">
          <div>before due</div>
        </div>
        <p v-if="api.create_activity.validation_errors.reminder_minutes_before">
          <span v-for="em in api.create_activity.validation_errors.reminder_minutes_before" :key="em">* {{ em }}</span>
        </p>
      </div>
      <!-- owner -->
      <div class="crm-input-container CallType">
        <label>Select user</label>
        <vb-select :disabled="api.create_activity.send" v-model="forms.create_activity.owner" :options="GET_CRM_USERS" valueField="id" textField="name" placeholder="Select User" />
        <p v-if="($v.forms.create_activity.owner.$dirty && $v.forms.create_activity.owner.$invalid) || api.create_activity.validation_errors.user_id">
          <span v-if="!$v.forms.create_activity.owner.required">User is required</span>
          <span v-for="em in api.create_activity.validation_errors.user_id" :key="em">* {{ em }}</span>
        </p>
      </div>
      <!-- description -->
      <div class="crm-input-container withoutIcon">
        <label>Description</label>
        <textarea :disabled="api.create_activity.send" v-model="forms.create_activity.description" class="w-100" placeholder="Description" />
        <p v-if="($v.forms.create_activity.description.$dirty && $v.forms.create_activity.description.$invalid) || api.create_activity.validation_errors.description">
          <span v-for="em in api.create_activity.validation_errors.description" :key="em">* {{ em }}</span>
        </p>
      </div>
      <!-- notes -->
      <div class="crm-input-container withoutIcon">
        <label>Notes</label>
        <textarea :disabled="api.create_activity.send" v-model="forms.create_activity.note" class="w-100" placeholder="Notes" />
        <p v-if="($v.forms.create_activity.note.$dirty && $v.forms.create_activity.note.$invalid) || api.create_activity.validation_errors.note">
          <span v-for="em in api.create_activity.validation_errors.note" :key="em">* {{ em }}</span>
        </p>
      </div>
      <!-- guests -->
      <div class="crm-input-container CallType">
        <label>Guests</label>
        <vb-select :disabled="api.create_activity.send" :options="GET_CRM_USERS" placeholder="Guests">
          
        </vb-select>
        <p v-if="($v.forms.create_activity.guests.$dirty && $v.forms.create_activity.guests.$invalid) || api.create_activity.validation_errors.guests">
          <span v-for="em in api.create_activity.validation_errors.guests" :key="em">* {{ em }}</span>
        </p>
      </div>
      <!-- deals -->
      <DealSearchInput :disabled="api.create_activity.send" v-model="forms.create_activity.deals">
        <template #errors>
          <p v-if="($v.forms.create_activity.deals.$dirty && $v.forms.create_activity.deals.$invalid) || api.create_activity.validation_errors.deals">
            <span v-for="em in api.create_activity.validation_errors.deals" :key="em">* {{ em }}</span>
          </p>
        </template>
      </DealSearchInput>
      <!-- contacts -->
      <ContactSearchInput :disabled="api.create_activity.send" v-model="forms.create_activity.contacts">
        <template #errors>
          <p v-if="($v.forms.create_activity.deals.$dirty && $v.forms.create_activity.deals.$invalid) || api.create_activity.validation_errors.deals">
            <span v-for="em in api.create_activity.validation_errors.deals" :key="em">* {{ em }}</span>
          </p>
        </template>
      </ContactSearchInput>
      <!-- companies -->
      <CompanySearchInput :disabled="api.create_activity.send" v-model="forms.create_activity.companies">
        <template #errors>
          <p v-if="($v.forms.create_activity.deals.$dirty && $v.forms.create_activity.deals.$invalid) || api.create_activity.validation_errors.deals">
            <span v-for="em in api.create_activity.validation_errors.deals" :key="em">* {{ em }}</span>
          </p>
        </template>
      </CompanySearchInput>
      <b-button type="submit" :disabled="api.create_activity.send" class="fullWidthDoneButton mt-20px">Submit</b-button>
    </b-form>
  </modal>
</template>

<script>
import required from 'vuelidate/lib/validators/required'
import DealSearchInput from '@/components/CRM/widgets/deals/SearchInput.vue'
import CompanySearchInput from '@/components/CRM/widgets/companies/SearchInput.vue'
import ContactSearchInput from '@/components/CRM/widgets/contacts/SearchInput.vue'
import SelectActivityTypeInput from '@/components/CRM/widgets/activity-types/SelectActivityTypeInput.vue'
import { ctkDateTimePicker } from '@/mixin';
import { mapGetters } from 'vuex';
import { GET_CRM_USERS } from '@/store/helper/getters';
import moment from 'moment-timezone';
import { VOIP_API } from '@/utils';
export default {
  name: 'CRMCreateActivityModal',
  mixins: [ctkDateTimePicker],
  components: {
    DealSearchInput,
    CompanySearchInput,
    ContactSearchInput,
    SelectActivityTypeInput,
  },
  props: {
    modalName: {
      type: String,
      default: 'CRMCreateActivity'
    },
  },
  data() {
    return {
      api: {
        create_activity: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true }),
      },
      forms: {
        create_activity: this.$helperFunction.formInstance({
          data: {
            title: '',
            activity_type_id: '',
            due: {
              start_date: '',
              end_date: '',
            },
            reminder: {
              enable: true,
              before: 30,
              type: 'minutes',
            },
            owner: '',
            guests: [],
            description: '',
            note: '',
            deals: [],
            companies: [],
            contacts: [],
          },
        }),
      },
    }
  },
  computed: {
    ...mapGetters([
      GET_CRM_USERS
    ])
  },
  validations: {
    forms: {
      create_activity: {
        title: {
          required: required,
        },
        activity_type_id: {
          required: required,
        },
        due: {
          start_date: {
            required: required,
          },
          end_date: {
            required: required,
          },
        },
        reminder: {
          enable: {
            
          },
          before: {
            
          },
          type: {
            
          },
        },
        owner: {
          required: required,
        },
        guests: {
          
        },
        description: {
          
        },
        note: {
          
        },
        deals: {
          
        },
        companies: {
          
        },
        contacts: {
          
        },
      }
    }
  },
  methods: {
    onBeforeOpen(){
      this.forms.create_activity.due.start_date=moment().format('YYYY-MM-DD HH:mm')
      this.forms.create_activity.due.end_date=moment().add(1,'day').format('YYYY-MM-DD HH:mm')
    },
    onBeforeClose(){
      this.api.create_activity.reset()
      this.forms.create_activity.reset()
      this.$v.forms.create_activity.$reset()
    },
    async createActivity() {
      this.$v.forms.create_activity.$touch()
      if(this.$v.forms.create_activity.$invalid || this.api.create_activity.send) return;
      try {
        this.api.create_activity.send=true
        this.api.create_activity.validation_errors={}
        this.api.create_activity.error_message=''
        let reminder_minutes_before = null
        if(this.forms.create_activity.reminder.enable) {
          switch (this.forms.create_activity.reminder.type) {
            case 'minutes':
              reminder_minutes_before = this.forms.create_activity.reminder.before
              break;
            case 'hours':
              reminder_minutes_before = this.forms.create_activity.reminder.before*60
              break;
            case 'days':
              reminder_minutes_before = this.forms.create_activity.reminder.before*1440
              break;
            case 'weeks':
              reminder_minutes_before = this.forms.create_activity.reminder.before*10080
              break;
            default:
              break;
          }
        }
        await VOIP_API.endpoints.crm.activities.create({
          title: this.forms.create_activity.title,
          activity_type_id: this.forms.create_activity.activity_type_id,
          description: this.forms.create_activity.description,
          note: this.forms.create_activity.note,
          user_id: this.forms.create_activity.owner,
          due_date: moment(this.forms.create_activity.due.start_date).format('YYYY-MM-DD'),
          due_time: moment(this.forms.create_activity.due.start_date).format('HH:mm'),
          end_date: moment(this.forms.create_activity.due.end_date).format('YYYY-MM-DD'),
          end_time: moment(this.forms.create_activity.due.end_date).format('HH:mm'),
          reminder_minutes_before: reminder_minutes_before,
          guests: {
            contacts: [],
            users: this.forms.create_activity.guests.map(i=>i.id),
          },
          deals: this.forms.create_activity.deals.map(i=>i.id),
          contacts: this.forms.create_activity.contacts.map(i=>i.id),
          companies: this.forms.create_activity.companies.map(i=>i.id),
        })
        this.$modal.hide(this.modalName)
        this.$v.forms.create_activity.$reset()
        this.$emit('created')
      } catch (ex) {
        this.api.create_activity.validation_errors=ex.own_errors
        this.api.create_activity.error_message=ex.own_message || ex.message
      } finally {
        this.api.create_activity.send=false
      }
    }
  },
}
</script>

<style>

</style>