var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "hrm-home-container"
  }, [_c('div', {
    staticClass: "hrm-reports-container"
  }, [_c('div', {
    staticClass: "reportsLeftSideBar"
  }, [_vm._m(0), _c('div', {
    staticClass: "sideBarList-container"
  }, [_c('div', {
    staticClass: "sideBarList-inner"
  }, [_c('div', {
    staticClass: "sideBarList"
  }, [_vm._l(_vm.reports, function (report) {
    return [_c('div', {
      key: report.id,
      staticClass: "sideBarList-item",
      class: {
        'active': _vm.selectedTab.id == report.id
      },
      on: {
        "click": function ($event) {
          _vm.selected.tab = report.childrens ? report.childrens[0].component : report.component;
        }
      }
    }, [_c('div', {
      staticClass: "sideBarList-itemLeftSide"
    }, [_c('vb-icon', {
      staticClass: "sideBarList-icon",
      attrs: {
        "icon": report.icon,
        "height": "17px",
        "width": "17px"
      }
    }), _c('div', {
      staticClass: "sideBarList-itemText"
    }, [_vm._v(_vm._s(report.text))])], 1), report.childrens ? _c('b-icon', {
      staticClass: "downIcon",
      attrs: {
        "icon": "chevron-down",
        "font-scale": "1.3"
      }
    }) : _vm._e()], 1), report.childrens ? _c('div', {
      key: `children-${report.id}`,
      staticClass: "sideBarSubItemsList",
      class: {
        'show h-auto': _vm.selectedTab.id == report.id
      }
    }, _vm._l(report.childrens, function (children) {
      return _c('div', {
        key: children.component,
        staticClass: "sideBarList-item",
        class: {
          'active': children.component == _vm.selected.tab
        },
        on: {
          "click": function ($event) {
            _vm.selected.tab = children.component;
          }
        }
      }, [_c('div', {
        staticClass: "sideBarList-itemLeftSide"
      }, [_c('div', {
        staticClass: "sideBarList-itemText"
      }, [_vm._v(_vm._s(children.text))])])]);
    }), 0) : _vm._e()];
  })], 2)])])]), _c('div', {
    staticClass: "reportsMainBody"
  }, [_c(_vm.selected.tab, {
    tag: "component"
  })], 1)])]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "sideBarHeading-container"
  }, [_c('div', {
    staticClass: "sideBarHeading"
  }, [_vm._v(" Reports ")])]);

}]

export { render, staticRenderFns }