<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto" 
      name="EditLocationModal" 
      @closed="onClose" 
      @before-open="beforeOpen"
    >
      <template v-if="conditions.screen==screens.edit">

        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Location</div>
          <div class="plus-IOSIcon-container"></div>
        </div> 

        <div class="innerModalIos">
          

          <form class="dialer-form lg-mar-bottom" @submit.prevent="editLocation()">
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item flex-column">
                <div class="w-100 d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="headingInsideTable">Extension</div>
                  </div>
                  <div class="dd-updatedIos">
                    <b-select id="edit-select-extension" v-model="forms.edit_location.callerId" :disabled="api_sent.edit || api_sent.list">
                      <option value="">Select Extensions</option>
                      <option v-for="(callerid, index) in response.list.callerid" :key="index" :value="callerid">{{ callerid | number_formater }}</option>
                    </b-select>
                  </div>
                </div>
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.edit_location.callerId.required" class="text w-100 text-danger animated text-left bounceIntop mb-0">
                    <span>* Extenion is required</span>
                  </p>
                </template>
              </div>
              <div class="basicWhiteIOScard-item flex-column">
                <div class="w-100 d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="headingInsideTable">Country</div>
                  </div>
                  <div class="dd-updatedIos">
                    <b-select id="edit-select-country" v-model="forms.edit_location.country" :disabled="api_sent.edit || api_sent.list">
                      <option value="">Select Country</option>
                      <option v-for="country in response.list.countries" :key="country.Code2" :value="country.Code2">{{ country.Name }}</option>
                    </b-select>
                  </div>
                </div>
                <template v-if="submitted.edit">
                  <p v-if="!$v.forms.edit_location.country.required" class="text w-100 text-danger animated text-left bounceIntop mb-0">
                    <span>* Country is required</span>
                  </p>
                </template>
              </div>
            </div>
            <div class="basicWhiteIOScard mb-16px" v-if="forms.edit_location.country === 'GB'">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Enter Address Manually</div>
                <div class="position-relative">
                  <b-check id="edit-manually-check" 
                    class="dialer-switch custom-control custom-switch IosSwitch" v-model="is_manual_address" 
                    :disabled="api_sent.edit" switch 
                  />
                </div>
              </div>
              <template v-if="is_manual_address">
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Postal Code</label>
                    <div>
                      <input id="edit-manual-postal-code" class="w-100" type="text" v-model="forms.manual_address.postal_code" :disabled="api_sent.edit" />
                    </div>
                    <template v-if="submitted.edit">
                      <p v-if="!$v.forms.manual_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                        <span>* Postal Code is required</span>
                      </p>
                    </template>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>City</label>
                    <div>
                      <input id="edit-manual-city" class="w-100" type="text" v-model="forms.manual_address.city" :disabled="api_sent.edit" />
                    </div>
                    <template v-if="submitted.edit">
                      <p v-if="!$v.forms.manual_address.city.required" class="text text-danger animated bounceIntop mb-0">
                        <span>* City is required</span>
                      </p>
                    </template>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Street</label>
                    <div>
                      <input id="edit-manual-street" class="w-100" type="text" v-model="forms.manual_address.street" :disabled="api_sent.edit" />
                    </div>
                    <template v-if="submitted.edit">
                      <p v-if="!$v.forms.manual_address.street.required" class="text text-danger animated bounceIntop mb-0">
                        <span>* Street is required</span>
                      </p>
                    </template>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>House Name</label>
                    <div>
                      <input id="edit-manual-house-name" class="w-100" type="text" v-model="forms.manual_address.house_name" :disabled="api_sent.edit" />
                    </div>
                    <template v-if="submitted.edit">
                      <p v-if="!$v.forms.manual_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                        <span>* House Name is required</span>
                      </p>
                    </template>
                  </div>
                </div>
              </template>
              <template v-else>
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Postal Code</label>
                    <div>
                      <input id="edit-auto-postal-code" class="w-100" type="text" v-model="forms.uk_address.postal_code" :disabled="api_sent.edit" />
                    </div>
                    <template v-if="submitted.edit">
                      <p v-if="!$v.forms.uk_address.postal_code.required" class="text text-danger animated bounceIntop mb-0">
                        <span>* Postal Code is required</span>
                      </p>
                    </template>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>House Name</label>
                    <div>
                      <input id="edit-auto-house-no" class="w-100" type="text" v-model="forms.uk_address.house_name" :disabled="api_sent.edit" />
                    </div>
                    <template v-if="submitted.edit">
                      <p v-if="!$v.forms.uk_address.house_name.required" class="text text-danger animated bounceIntop mb-0">
                        <span>* House Name is required</span>
                      </p>
                    </template>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item" v-if="forms.uk_address.address && response.address.addresses.indexOf(forms.uk_address.address)==-1">
                  <div class="headingInsideTable">Address</div>
                  <div class="position-relative">
                    <b-check id="edit-auto-select-address-check" 
                      class="dialer-switch custom-control custom-switch IosSwitch"  
                      @change="forms.uk_address.address='';get_address_from_select=!get_address_from_select;" 
                      :disabled="api_sent.edit" switch 
                    />
                  </div>
                </div>
                <div class="basicWhiteIOScard-item" v-if="forms.uk_address.address && response.address.addresses.indexOf(forms.uk_address.address)==-1">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Address</label>
                    <div>
                      <input id="edit-auto-address" class="w-100" type="text" :value="forms.uk_address.address" :disabled="true" />
                    </div>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item flex-column" v-else>
                  <div class="w-100 d-flex justify-content-between">
                    <div class="d-flex align-items-center">
                      <div class="headingInsideTable">Address</div>
                    </div>
                    <div class="dd-updatedIos">
                      <b-select id="edit-auto-seelct-address" v-model="forms.uk_address.address" :disabled="api_sent.edit">
                        <option value="">Select Address</option>
                        <option v-for="address in response.address.addresses" :key="address" :value="address">{{ address.replace(/,/g,'').replace(/\s+/g,' ').trim() }}</option>
                      </b-select>
                    </div>
                  </div>
                  <div class="w-100 d-flex flex-column">
                    <template v-if="submitted.edit">
                      <p v-if="!$v.forms.uk_address.address.required" class="text text-danger animated bounceIntop mb-0">
                        <span>* Address is required</span>
                      </p>
                    </template>
                  </div>
                </div>
              </template>
            </div>
            <div class="basicWhiteIOScard mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>First Name</label>
                  <div>
                    <input id="edit-first-name" class="w-100" type="text" v-model="forms.edit_location.forename" :disabled="api_sent.edit" />
                  </div>
                  <template v-if="submitted.edit">
                    <p v-if="!$v.forms.edit_location.forename.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Forename is required</span>
                    </p>
                  </template>
                </div>
              </div>
              <div class="basicWhiteIOScard-item">
                <div class="mobileInputIOS InputIosV2">
                  <label>Last Name</label>
                  <div>
                    <input id="edit-last-name" class="w-100" type="text" v-model="forms.edit_location.surname" :disabled="api_sent.edit" />
                  </div>
                  <template v-if="submitted.edit">
                    <p v-if="!$v.forms.edit_location.surname.required" class="text text-danger animated bounceIntop mb-0">
                      <span>* Surname is required</span>
                    </p>
                  </template>
                </div>
              </div>
            </div>
            <button class="IosFullWidthButton forDone mt-16px mb-16px" type="submit" :disabled="api_sent.edit">
              <vb-spinner v-if="api_sent.edit" />
              <template v-else>Done</template>
            </button>
            <button class="IosFullWidthButton forRemove mt-16px mb-16px" type="submit" @click="deleteLocation(location.real_id)">
              <template>Delete</template>
            </button>
            
          </form>

        </div>

      </template>
      <template v-else>
        
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide('EditLocationModal')">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit Location</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>

        <div class="innerModalIos">
          <div class="lg-mar-bottom">

            <div class="basicWhiteIOScard mt-32px mb-16px">
              
              <div class="basicWhiteIOScard-item flex-column">
                <div class="w-100 d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="headingInsideTable">Extension</div>
                  </div>
                  <div class="textInsideCard onRightSide ">
                    {{ location.caller | number_formater }}
                  </div>
                </div>
              </div>

              <div class="basicWhiteIOScard-item flex-column">
                <div class="w-100 d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="headingInsideTable">Country</div>
                  </div>
                  <div class="textInsideCard onRightSide ">
                    {{ country | get_property('Name') }}
                  </div>
                </div>
              </div>

            </div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              
              <div class="basicWhiteIOScard-item flex-column">
                <div class="w-100 d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="headingInsideTable">First Name</div>
                  </div>
                  <div class="textInsideCard onRightSide ">
                    {{ location.forename }}
                  </div>
                </div>
              </div>

              <div class="basicWhiteIOScard-item flex-column">
                <div class="w-100 d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="headingInsideTable">Last Name</div>
                  </div>
                  <div class="textInsideCard onRightSide ">
                    {{ location.surname }}
                  </div>
                </div>
              </div>

            </div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              
              <div class="basicWhiteIOScard-item flex-column">
                <div class="w-100 d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="headingInsideTable">Postal Code</div>
                  </div>
                  <div class="textInsideCard onRightSide ">
                    {{ location.postcode }}
                  </div>
                </div>
              </div>

              <div class="basicWhiteIOScard-item flex-column">
                <div class="w-100 d-flex justify-content-between">
                  <div class="d-flex align-items-center">
                    <div class="headingInsideTable">Address</div>
                  </div>
                  <div class="textInsideCard onRightSide ">
                    {{ location.address }}
                  </div>
                </div>
              </div>

            </div>

          </div>

        </div>

      </template>

    </modal>
  </div>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { LOGGER, VOIP_API } from "@/utils/index";
let screens = {
  edit: 'edit',
  view: 'view',
}
export default {
  name: "EditLocationModal",
  data() {
    return {
      forms: {
        edit_location: {
          callerId: "",
          country: "",
          surname: "",
          forename: "",
          reset() {
            this.callerId = "";
            this.country = "";
            this.surname = "";
            this.forename = "";
          },
        },
        manual_address: {
          postal_code: "",
          city: "",
          street: "",
          house_name: "",
          reset() {
            this.postal_code = "";
            this.city = "";
            this.street = "";
            this.house_name = "";
          },
        },
        uk_address: {
          postal_code: "",
          house_name: "",
          address: "",
          reset() {
            this.postal_code = "";
            this.house_name = "";
            this.address = "";
          },
        },
      },
      is_manual_address: true,
      location: {},
      get_address_from_select: false,
      response: {
        list: {
          callerId: [],
          countries: [],
          extensionCount: 0,
          extensions: {},
        },
        address: {
          addresses: [],
          latitude: 0,
          longitude: 0,
        },
      },
      submitted: {
        edit: false,
      },
      api_sent: {
        edit: false,
        list: false,
      },
      api: {
        location_delete: this.$helperFunction.apiInstance({ send: [] }),
      },
      conditions: {
        screen: screens.view
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    editLocationForm() {
      const address = this.forms.uk_address.address.split(",");
      return {
        street_address: this.is_manual_address ? this.forms.manual_address.street : this.get_address_from_select ? address[0].trim() : this.location.address,
        locality: this.is_manual_address ? "" : "",
        county: this.is_manual_address ? "" : this.get_address_from_select ? address[6].trim() : this.location.country,
        is_manual: this.is_manual_address,
        town: this.is_manual_address ? this.forms.manual_address.city : this.get_address_from_select ? address[5].trim() : this.location.town,
        city: this.is_manual_address ? this.forms.manual_address.city : this.get_address_from_select ? address[5].trim() : this.location.town,
        postcode: this.is_manual_address ? this.forms.manual_address.postal_code : this.forms.uk_address.postal_code,
        country: this.forms.edit_location.country,
        house: this.is_manual_address ? this.forms.manual_address.house_name : this.forms.uk_address.house_name,
        caller: this.forms.edit_location.callerId,
        forename: this.forms.edit_location.forename,
        surname: this.forms.edit_location.surname,
      };
    },
    screens(){ return screens },
    country(){ return this.response.list.countries?.find?.(country=>country.Code2==this.location.country) },
  },
  validations: {
    forms: {
      edit_location: {
        callerId: {
          required,
        },
        country: {
          required,
        },
        surname: {
          required,
        },
        forename: {
          required,
        },
      },
      manual_address: {
        postal_code: {
          required,
        },
        city: {
          required,
        },
        street: {
          required,
        },
        house_name: {
          required,
        },
      },
      uk_address: {
        postal_code: {
          required,
        },
        house_name: {
          required,
        },
        address: {
          required,
        },
      },
    },
  },
  watch: {
    "forms.edit_location.country"(country) {
      this.is_manual_address = country !== "GB";
    },
    "forms.uk_address.postal_code"(postal_code) {
      this.resetresponse("address");
      var new_postal_code = postal_code?.replace?.(/ /g, "")?.toUpperCase?.() ?? '';
      if (new_postal_code.length == 7) {
        this.getAddress(`${new_postal_code.slice(0, 4)} ${new_postal_code.slice(4, 7)}`);
      }
    },
  },
  methods: {
    getAddress(value) {
      let vm = this;
      VOIP_API.endpoints.location.getAddress(value).then(({ data }) => {
        vm.response.address = data;
      });
    },
    resetresponse(type) {
      if (type === "address") {
        this.response.address.addresses = [];
        this.response.address.latitude = 0;
        this.response.address.longitude = 0;
      }
    },
    beforeOpen(event) {
      this.location = event.params.location
      this.setLocationForm(this.location)
      this.load();
    },
    load() {
      let vm = this
      if (this.api_sent.list) return;
      vm.api_sent.list = true;
      VOIP_API.endpoints.location.listcountriesandextensions()
        .then(({ data }) => {
          vm.response.list = data;
          if(vm.location.caller){
            vm.response.list.callerid.push(vm.location.caller)
          }
          vm.$nextTick(()=>{
            vm.setLocationForm(vm.location)
          })
        })
        .catch((error) => {
          LOGGER.danger(error);
        })
        .finally(() => {
          vm.api_sent.list = false;
        });
    },
    editLocation() {
      this.submitted.edit = true;
      this.$v.$touch();
      if (
        this.$v.forms.edit_location.$invalid ||
        this.api_sent.edit ||
        (this.is_manual_address
          ? this.$v.forms.manual_address.$invalid
          : this.$v.forms.uk_address.$invalid)
      )
        return;
      this.api_sent.edit = true;
      VOIP_API.endpoints.location
        .edit({
          ...this.editLocationForm,
          accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
          uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
          id: this.location.id,
          source: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
        })
        .then(({ data: { data: list } }) => {
          this.$modal.hide("EditLocationModal");
          this.appNotify({
            message: "Request for adding menu is pending",
            type: "success",
          })
          this.forms.edit_location.reset();
          this.forms.manual_address.reset();
          this.forms.uk_address.reset();
          this.$emit("latest-list", {
            list,
          });
        })
        .catch((ex) => {
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(() => {
          this.submitted.edit = false;
          this.api_sent.edit = false;
        });
    },
    setLocationForm(location){
      LOGGER.log(location)
      // if(location.caller){
      //   this.response.list.callerid.push(location.caller)
      // }
      this.forms.edit_location.forename=location.forename
      this.forms.edit_location.surname=location.surname
      this.forms.edit_location.country=location.country
      this.forms.edit_location.callerId=location.caller ?? ''
      this.forms.uk_address.postal_code = location.postcode
      this.forms.uk_address.house_name = location.house
      this.forms.uk_address.address = location.address
      this.is_manual_address=location.country!='GB'
      this.forms.manual_address.postal_code = location.postcode
      this.forms.manual_address.city = location.town
      this.forms.manual_address.street = location.street
      this.forms.manual_address.house_name = location.house
    },
    onClose() {
      this.location={}
      this.forms.edit_location.reset()
      this.forms.manual_address.reset()
      this.forms.uk_address.reset()
      this.submitted.edit=false
      this.conditions.screen=screens.view
    },
    deleteLocation(location_id) {
      let vm = this;
      if (vm.api.location_delete.send.includes(location_id)) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to delete this Location`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.location_delete.send.push(location_id);
            VOIP_API.endpoints.location.delete({
              id: location_id,
            }).then(() => {
              vm.$emit('latest-list')
              vm.$modal.hide('EditLocationModal')
              vm.appNotify({
                message: "Successfully Deleted!",
                type: "success",
              })
            }).catch((ex) => {
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(() => {
              if (vm.api.location_delete.send.includes(location_id)) {
                vm.api.location_delete.send.splice(vm.api.location_delete.send.indexOf(location_id),1);
              }
            });
          }
        },
      });
    },
  },
};
</script>
