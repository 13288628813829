<template>
  <modal class="dialer_animation right_side_popup switching-plan-modal ExtraPlansModal" width="70%" height="auto" name="ExtraResourcesAlertModal" :clickToClose="true" @before-open="beforeOpen" style="z-index:9999;">
    <div class="dialer-form">
      <div class="dialer-edit-header">
        <h2 class="dialer-edit-title">Extra Plans</h2>
        <div class="dialer-edit-actions">
          <a class="newCloseButton" @click="$modal.hide('ExtraResourcesAlertModal')">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="dialer-package-plan-area md-mar-vertical">
        <div class="dialer-package-detail"> 
          <div class="dialer-package-detail-inner" style="flex-direction:column;">
            <div class="dialer-tab-content">
              
              <div class="dialer-box alt table">
                <table class="dialer-table">
                  <thead>
                    <tr>
                      <th class="dialer-has-sort">
                        <span class="dialer-sorted-by ascending">Label</span>
                      </th>
                      <th class="dialer-has-sort">
                        <span class="dialer-sorted-by ascending">message</span>
                      </th>
                    </tr>
                  </thead>
                  <tbody>
                    <tr class="dialer-row-select" v-for="(data,index) in array" :key="index">
                      <td class="dialer-row-title" >{{ data.product_label }}</td>
                      <td class="dialer-row-title" >{{ data.Message }}</td>
                    </tr>
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'ExtraResourcesAlertModal',
  data(){
    return {
      array: [],
    }
  },
  methods: {
    beforeOpen(event){
      this.array = event.params.array
    },
  },
}
</script>

<style>

</style>