var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup tagStatus-popup dialer-setting-call-tag",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newBackButton mr-16px",
    on: {
      "click": function ($event) {
        !!_vm.conditions.updated_id ? _vm.conditions.updated_id = '' : _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Tags ")]), !_vm.conditions.updated_id ? _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: !_vm.getUserPermissions.call_tags ? 'for-disable' : '',
    attrs: {
      "title": _vm.getUserPermissions.call_tags ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.call_tags,
      "variant": "success"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddTag');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add tag")])], 1)]) : _vm._e()]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Call tagging helps organise and analyse call data, enabling easy reporting and customer identification. ")])]), _c('div', {
    staticClass: "dialer-tabs"
  }, [!!_vm.conditions.updated_id ? _c('div', {
    staticClass: "dialer-tab-content m-0"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_c('b-icon', {
    attrs: {
      "icon": "x"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.updated_id = '';
      }
    }
  }), _vm._v(" Update tag ")], 1), _c('b-form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-input-group-2 align-items-start"
  }, [_c('div', {
    staticClass: "dialer-input-field input-consistent-inner-shadows mx-2"
  }, [_c('label', [_vm._v("Tag name")]), _c('b-input', {
    staticClass: "bg-white",
    attrs: {
      "type": "text",
      "disabled": _vm.api.update.send,
      "placeholder": "Enter name"
    },
    model: {
      value: _vm.forms.update.tag,
      callback: function ($$v) {
        _vm.$set(_vm.forms.update, "tag", $$v);
      },
      expression: "forms.update.tag"
    }
  }), _vm.forms.update.submitted && _vm.$v.forms.update.tag.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update.tag.required ? _c('span', [_vm._v("* Name is required")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "dialer-input-field input-consistent-inner-shadows color-picker-input colorPicker-section mx-2"
  }, [_c('div', {
    staticClass: "dialer-input-field"
  }, [_c('label', [_vm._v("Pick a color")]), _c('input', {
    attrs: {
      "type": "text",
      "placeholder": "Enter color"
    }
  })]), _c('span', {
    staticClass: "color-circle"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update.color,
      expression: "forms.update.color"
    }],
    staticClass: "color-swatch",
    attrs: {
      "type": "color",
      "disabled": _vm.api.update.send
    },
    domProps: {
      "value": _vm.forms.update.color
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update, "color", $event.target.value);
      }
    }
  })]), _vm.forms.update.submitted && _vm.$v.forms.update.color.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update.color.required ? _c('span', [_vm._v("* Color is required")]) : !_vm.$v.forms.update.color.validColor ? _c('span', [_vm._v("* Color is invalid")]) : _vm._e()]) : _vm._e()]), _c('vb-color-picker', {
    staticClass: "dialer-input-field input-consistent-inner-shadows color-picker-input mx-2",
    attrs: {
      "title": "Text Color",
      "disabled": _vm.api.update.send,
      "value": _vm.forms.update.text_color
    },
    on: {
      "change": function ($event) {
        _vm.forms.update.text_color = $event;
      }
    }
  }, [_vm.forms.update.submitted && _vm.$v.forms.update.text_color.$invalid ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop",
    attrs: {
      "slot": "error"
    },
    slot: "error"
  }, [!_vm.$v.forms.update.text_color.required ? _c('span', [_vm._v("* Text color is required")]) : !_vm.$v.forms.update.text_color.validColor ? _c('span', [_vm._v("* Text color is invalid")]) : _vm._e()]) : _vm._e()])], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "variant": "success",
      "disabled": _vm.api.update.send
    }
  }, [_vm.api.update.send ? _c('vb-spinner') : [_vm._v("update")]], 2)])])], 1) : [_c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again",
    attrs: {
      "isListEmpty": _vm.response.tags.length == 0,
      "listLength": _vm.response.tags.length,
      "perPage": 5,
      "loading": _vm.api.list.send,
      "conditions": {
        pagination: {
          last: true,
          first: true,
          show: true
        },
        is_no_record: {
          show: true
        },
        loading: {
          show: true
        }
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return [_vm.$store.getters.getIsMobile ? _vm._l(_vm.response.tags, function (tag, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: tag.id
          }, [_c('td', {
            staticClass: "left"
          }, [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            directives: [{
              name: "vb-tag-color",
              rawName: "v-vb-tag-color",
              value: tag.colour,
              expression: "tag.colour"
            }],
            staticClass: "mr-8px",
            style: `border-radius:50%;height:25px;width:25px;min-height:25px;min-width:25px;`
          }), _c('div', [_c('div', {
            staticClass: "headingInsideTable"
          }, [_vm._v(_vm._s(tag.tag))]), tag.accountcode != '0' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0"
          }, [_vm._v("Custom")]) : _vm._e(), _c('div', {
            directives: [{
              name: "vb-tag-color",
              rawName: "v-vb-tag-color.text.bg",
              value: {
                color: tag.colour,
                text_color: tag.text_color
              },
              expression: "{\n                            color:tag.colour,\n                            text_color:tag.text_color\n                          }",
              modifiers: {
                "text": true,
                "bg": true
              }
            }],
            style: `border-radius:3px;height:auto;width:fit-content;padding:2px 6px;font-size: 8px;font-weight: 600;line-height: 1.2;text-align: center;vertical-align: initial;white-space: nowrap;`
          }, [_vm._v(" " + _vm._s(tag.tag) + " ")])])])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end align-items-center"
          }, [_c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "switchWidth",
            attrs: {
              "title": _vm.getUserPermissions.call_tags ? '' : 'Your package does not support this feature'
            }
          }, [_vm.api.toggle_hide.send == tag.id ? _c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "50px"
            }
          })]) : _c('b-checkbox', {
            staticClass: "newerSwitch",
            attrs: {
              "disabled": !_vm.getUserPermissions.call_tags || !!_vm.api.toggle_hide.send,
              "checked": tag.deleted_tags == 1 ? true : false,
              "switch": ""
            },
            on: {
              "change": function ($event) {
                return _vm.toggleHide(tag);
              }
            }
          })], 1), _c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD"
          }, [tag.accountcode != '0' ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            attrs: {
              "title": _vm.getUserPermissions.call_tags ? '' : 'Your package does not support this feature'
            }
          }, [_c('b-dropdown', {
            attrs: {
              "right": "",
              "disabled": !_vm.getUserPermissions.call_tags
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots-vertical",
                    "scale": "1.5"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_vm.api.remove.send == tag.id ? _c('b-dropdown-item') : _c('b-dropdown-item', {
            attrs: {
              "disabled": !!_vm.api.remove.send
            },
            on: {
              "click": function ($event) {
                return _vm.remove(tag);
              }
            }
          }, [_vm._v("Delete")])], 1)], 1) : _vm._e()])])])]);
        }) : _vm._l(_vm.response.tags, function (tag, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: tag.id
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(" " + _vm._s(tag.tag) + " "), tag.accountcode != '0' ? _c('span', {
            staticClass: "OwnertaggedVersion mb-0 ml-16px"
          }, [_vm._v("Custom")]) : _vm._e()]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            directives: [{
              name: "vb-tag-color",
              rawName: "v-vb-tag-color",
              value: tag.colour,
              expression: "tag.colour"
            }],
            staticClass: "md-mar-left",
            style: `border-radius:50%;height:16px;width:16px;`
          })]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            directives: [{
              name: "vb-tag-color",
              rawName: "v-vb-tag-color.text.bg",
              value: {
                color: tag.colour,
                text_color: tag.text_color
              },
              expression: "{\n                        color:tag.colour,\n                        text_color:tag.text_color\n                      }",
              modifiers: {
                "text": true,
                "bg": true
              }
            }],
            staticClass: "md-mar-left",
            style: `border-radius:4px;height:auto;width:fit-content;padding:0.2rem 0.4rem;font-size: 10.5px;font-weight: 500;line-height: 1.3;text-align: center;vertical-align: initial;white-space: nowrap;`
          }, [_vm._v(" " + _vm._s(tag.tag) + " ")])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            staticClass: "switchWidth",
            attrs: {
              "title": _vm.getUserPermissions.call_tags ? '' : 'Your package does not support this feature'
            }
          }, [_vm.api.toggle_hide.send == tag.id ? _c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "50px"
            }
          })]) : _c('b-checkbox', {
            staticClass: "newerSwitch",
            attrs: {
              "disabled": !_vm.getUserPermissions.call_tags || !!_vm.api.toggle_hide.send,
              "checked": tag.deleted_tags == 1 ? true : false,
              "switch": ""
            },
            on: {
              "change": function ($event) {
                return _vm.toggleHide(tag);
              }
            }
          })], 1)]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "d-flex justify-content-end"
          }, [_c('div', {
            staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD"
          }, [tag.accountcode != '0' ? _c('div', {
            directives: [{
              name: "b-tooltip",
              rawName: "v-b-tooltip"
            }],
            attrs: {
              "title": _vm.getUserPermissions.call_tags ? '' : 'Your package does not support this feature'
            }
          }, [_c('b-dropdown', {
            attrs: {
              "right": "",
              "disabled": !_vm.getUserPermissions.call_tags
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('b-icon', {
                  attrs: {
                    "icon": "three-dots",
                    "scale": "1.5"
                  }
                })];
              },
              proxy: true
            }], null, true)
          }, [_vm.api.remove.send == tag.id ? _c('b-dropdown-item') : _c('b-dropdown-item', {
            attrs: {
              "disabled": !!_vm.api.remove.send
            },
            on: {
              "click": function ($event) {
                return _vm.remove(tag);
              }
            }
          }, [_vm._v("Delete")])], 1)], 1) : _vm._e()])])])]);
        })];
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "80px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "md-mar-left latestShimmerDesign",
            staticStyle: {
              "height": "21px",
              "width": "21px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign md-mar-left",
            staticStyle: {
              "height": "21px",
              "width": "21px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign md-mar-left",
            staticStyle: {
              "height": "21px",
              "width": "50px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center justify-sontent-end w-fit-content ml-auto"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "16px",
              "width": "35px"
            }
          })])])]);
        });
      },
      proxy: true
    }])
  }, [!_vm.$store.getters.getIsMobile ? _c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Tag color")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Sample tag")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Hide")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-center"
  }, [_c('span')])]) : _vm._e()])]], 2)])]), _c('AddTagModal', {
    on: {
      "created": function ($event) {
        return _vm.load();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }