var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-box alt position-relative",
    attrs: {
      "id": _vm.id
    }
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('div', {
    staticClass: "w-fit-content",
    attrs: {
      "id": `${_vm.randomId}-ForwardSettingsButtonLock`
    }
  }, [_vm.api.route_call.send ? _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100px"
    }
  }) : _c('div', [_c('button', {
    staticClass: "newButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.randomId}-CallForwardSettingModal`, {
          account: _vm.account,
          setting: _vm.response.forwarding_setting,
          accountcode: _vm.forwardCallerId ? _vm.account : ''
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])]), _c('div', {
    staticClass: "d-flex justify-content-between"
  }, [_c('div', {
    staticClass: "dialer-call-options v3 w-75"
  }, [_c('div', {
    staticClass: "selectedContainer"
  }, [_vm.response.forwarding_setting.forward_note == 'off' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Keep ringing")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v("Call forwarding is off")])] : _vm._e(), _vm.response.forwarding_setting.forward_note == 'voicemail' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Ask to leave a voicemail")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v("If phone rings for "), _c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.timeout) + " seconds")]), _vm._v(" call will be forward to voicemail")])] : _vm._e(), _vm.response.forwarding_setting.forward_note == 'extension' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Forward to a user, team or IVR menu")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v(" If phone rings for "), _c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.timeout) + " seconds")]), _vm._v(" call will be forward to "), _c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.extension_name))])])] : _vm._e(), _vm.response.forwarding_setting.forward_note == 'number' ? [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v("Forward to another number")]), _c('div', {
    staticClass: "selectedText-small"
  }, [_vm._v(" If phone rings for "), _c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm.response.forwarding_setting.timeout) + " seconds")]), _vm._v(" call will be forward to "), _c('span', {
    staticClass: "latestBlueColor"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.response.forwarding_setting.forward_number)))])])] : _vm._e()], 2)])]), _c('ScheduleAssignModal', {
    attrs: {
      "modalName": `${_vm.randomId}-ScheduleAssignModal`,
      "account": _vm.account
    },
    on: {
      "interface": function ($event) {
        return _vm.updateForwardingSettings({
          extension_name: $event.accountname,
          forward_number: $event.account
        });
      }
    }
  }), _c('DurationPickerModal', {
    attrs: {
      "modalName": `${_vm.randomId}-DurationPickerModal`
    },
    on: {
      "time": function ($event) {
        return _vm.updateForwardingSettings({
          timeout: $event.seconds
        });
      }
    }
  }), _c('CallForwardingNumberInputModal', {
    attrs: {
      "modalName": `${_vm.randomId}-CallForwardingNumberInputModal`
    },
    on: {
      "number": function ($event) {
        return _vm.updateForwardingSettings({
          forward_number: $event.ber
        });
      }
    }
  }), _c('CallForwardSettingModal', {
    attrs: {
      "modalName": `${_vm.randomId}-CallForwardSettingModal`
    },
    on: {
      "updated": function ($event) {
        return _vm.updateForwardingSettings($event);
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "dialer-box-header mb-0"
  }, [_vm._v("When calls aren't answered...")])]);

}]

export { render, staticRenderFns }