<template>
  <div class="CallNotification-inner-container">
    <div class="CallNotification-inner">
      <div class="CallNotification-left">
        <div class="user-image">
          <img :src="callObjInfo.image" @error="$event.target.src=userDp" class=""/>
        </div>
        <div class="user-info">
          <div 
            class="call-type"
          >
            {{ 
              !callObjInfo.incall ? 
                callObjInfo.incoming ? 'Incoming Audio Call....' : 'Outgoing Audio Call....' : 
                isActiveCall ? 'Active Call' : 'Call on Hold'
            }}
          </div>
          <div class="user-name">{{ callObjInfo.name | number_formater }}</div>
          <div class="user-number">{{ callObjInfo.sub_info | number_formater }}</div>
        </div>
      </div>
      <template v-if="!callObjInfo.incall && callObjInfo.incoming">
        <div @click="onAccept()" class="CallNotification-right">
          <vb-icon class="cursor_pointer" icon="video-IncomingCall-audio-icon" width="40" height="40"/>
        </div>
        <div @click="session.hangup()" class="CallNotification-right">
          <vb-icon class="cursor_pointer" icon="video-call-hangupIcon-icon" width="40" height="40"/>
        </div>
      </template>
      <template v-else>
        <div @click="$emit('call-play')" class="CallNotification-right">
          <vb-icon :icon="isActiveCall?'mobile-currentCall-play-icon':'mobile-currentCall-pause-icon'" width="30" height="30"/>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { call_info } from '@/mixin';
import { mapGetters } from 'vuex';
// import { events } from '../../../utils';
export default {
  name: 'SipSessionCallsList',
  mixins: [
    call_info
  ],
  props: ["session","phones"],
  
  computed: {
    ...mapGetters([
      'activeCall',
      'isSwitchAbleState',
    ]),
    callObj(){ return this.session },
    userDp(){ return require("@/assets/images/user-male.png") },
    isActiveCall(){
      const identity = this.session.room || this.session.uuid
      const active_identity = this.activeCall?.room || this.activeCall?.uuid
      return active_identity==identity
    },
  },
  methods: {
    onAccept() {
      if(!this.isSwitchAbleState) return;
      this.session.accepts();
      this.$store.state.calls.active_call=this.session.uuid
      this.$store.state.global_conditions.tab='video-call'
      this.$emit('call-list-hide')
    },
    onReject() {
      this.session.hangup();
    },
    holdActiveSession() {
      // this.$root.$emit(events.hold_audio_call);
    },
    onSwitch(){
      this.phones.markSessionActive(this.session.uuid)
      // this.$root.$emit(events.hold_video_call)
      this.$emit('on-play')
    },
  }
}
</script>

<style>

</style>