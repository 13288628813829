var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Reminders ")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Set up custom email notifications to employees who have time off requests pending on them ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-32px"
  }, [_vm._v(" Send a reminder email o approvers who have unapproved time off requests ")]), _c('div', {
    staticClass: "pl-32px mt-16px"
  }, [_c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Monday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Tuesday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Wedensday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Thursday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Friday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Saturday ")]), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container"
  }, [_vm._v(" Sunday ")]), _c('div', {
    staticClass: "d-flex align-items-center pl-2"
  }, [_c('div', {
    staticClass: "mr-16px"
  }, [_vm._v("at")]), _c('div', {
    staticClass: "innerShadowInput-container wd-200px mt-8px mb-0"
  }, [_c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("08:00")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" 08:00 ")])], 1)], 1)]), _c('div', {
    staticClass: "innerShadowInput-container mt-24px"
  }, [_c('label', [_vm._v("Don’t send reminders while the approver is away on following policies")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" 08:00 ")])], 1)], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0"
  }, [_vm._v(" Save ")])], 1)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }