var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-soundSetting-device border-0 main-container-bill-22"
  }, [_c('video', {
    attrs: {
      "height": "160pxx",
      "width": "100%",
      "id": `testing-video-${_vm.random_id}`,
      "autoplay": ""
    }
  }), !!_vm.error ? _c('div', {
    staticClass: "error-message-bill-22"
  }, [_vm._v(" " + _vm._s(_vm.error) + " ")]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }