
<template>
  <div>
    <div class="mainHeading">Age report</div>
    <div class="mainDescription mb-4">
      <br/>
    </div>
    <div class="dropdownsContainer">
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All teams</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item>All teams</b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All locations</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item>All locations</b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All positions</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item>All positions</b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-center mt-5">
      <canvas id="age-chart"></canvas>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
import { HRM } from '@/data'
export default {
  name: 'Age',
  data() {
    return {
      ageChart: {}
    }
  },
  methods: {
    async barChart() {
      const ctx = document.getElementById("age-chart");
      this.ageChart?.destroy?.();
      this.ageChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: HRM.charts.ageChart.labels,
          datasets: [
            {
              label: 'Employees',
              data: HRM.charts.ageChart.data,
              backgroundColor: [
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1'
              ],
              hoverBackgroundColor: [
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1'
              ],
              borderColor: [
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1'
              ],
              borderWidth: 1
            },
          ]
        },
        options: {
          lineTension: 1,
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          scales: {
            xAxes: [
              {
                gridLines: {
                  display:false,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Employees'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
              }
            ],
            yAxes: [
              {
                gridLines: {
                  drawBorder: false,
                },
                scaleLabel: {
                  beginAtZero: true,
                  display: true,
                  labelString: 'Time Slot'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 0.5,
                },
              }
            ]
          }
        }
      });
      this.ageChart.update();
    },
  },
  mounted(){
    this.barChart()
  }
}
</script>

<style>

</style>