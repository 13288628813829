var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup addExtension-voicemailModal addRemove_userModal m-w-700 min-width-50percent-modal",
    attrs: {
      "name": _vm.modalName,
      "scrollable": true,
      "height": "auto"
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('section', {}, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Incoming call presentation setting")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox withOutPadding"
  }, [_c('b-form-group', {
    staticClass: "dialer-call-options-list mb-0",
    staticStyle: {
      "position": "relative",
      "pointer-events": "auto"
    }
  }, [_c('b-form-radio-group', _vm._l(_vm.cldOptions, function (cldOption) {
    return _c('b-form-radio', {
      key: cldOption.value,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row withHover checkBoxOrRadioInside position-relative makeCheckBoxesSquare",
      class: {
        active: _vm.selected.clid_display == cldOption.value
      },
      attrs: {
        "value": cldOption.value
      },
      on: {
        "change": function ($event) {
          return _vm.updateSetting();
        }
      },
      model: {
        value: _vm.selected.clid_display,
        callback: function ($$v) {
          _vm.$set(_vm.selected, "clid_display", $$v);
        },
        expression: "selected.clid_display"
      }
    }, [_vm._v(" " + _vm._s(cldOption.text) + " ")]);
  }), 1)], 1)], 1)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }