<template>
  <modal 
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto" 
    :name="modalName" 
    @before-open="onBeforeOpen" 
    @before-close="onBeforeClose"
  >
    <div class="d-flex align-items-center calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Storage</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div class="innerModalIos">
      
      <div class="basicWhiteIOScard mt-32px mb-16px">
        <div class="basicWhiteIOScard-item">
          <div class="d-flex align-items-center">
            <div class="headingInsideTable">Update Setting</div>
          </div>
          <div class="position-relative">
            <b-form-checkbox  
              class="dialer-switch IosSwitch" 
              switch 
              :disabled="api.update.send || api.update_status.send || api.get.send" 
              v-model="forms.update.status" 
              @change="updatestatus();"
            />
          </div>
        </div>
      </div>
      
      <form v-if="forms.update.status" class="dialer-form" @submit.prevent="update()">

        <div class="basicWhiteIOScard mb-16px">
          <div class="basicWhiteIOScard-item flex-column">
            <div class="w-100 d-flex justify-content-between">
              <div class="d-flex align-items-center">
                <div class="headingInsideTable">Type</div>
              </div>
              <div class="dd-updatedIos">
                <vb-select v-model="forms.update.type" :options="typeOptions">
                  <vb-icon slot="right-icon" icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </vb-select>
              </div>
            </div>
            <div class="w-100 d-flex flex-column">
              <template v-if="forms.update.submitted">
                <p v-if="!$v.forms.update.type.required" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* Type is required</span>
                </p>
              </template>
              <template v-if="api.update.validation_errors.provider">
                <p v-for="(error_message, index) in api.update.validation_errors.provider" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* {{ error_message }}</span>
                </p>
              </template>
            </div>
          </div>
        </div>

        <div class="basicWhiteIOScard">
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2">
              <label>Key</label>
              <div>
                <input type="text" label="Key" :disabled="api.update.send || api.get.send" v-model="forms.update.key" />
              </div>
              <template v-if="forms.update.submitted">
                <p v-if="!$v.forms.update.key.required" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* key is required</span>
                </p>
              </template>
              <template v-if="api.update.validation_errors.key">
                <p v-for="(error_message, index) in api.update.validation_errors.key" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* {{ error_message }}</span>
                </p>
              </template>
            </div>
          </div>
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2">
              <label>SECRET</label>
              <div>
                <input type="text" label="SECRET" :disabled="api.update.send || api.get.send" v-model="forms.update.secret" />
              </div>
              <template v-if="forms.update.submitted">
                <p v-if="!$v.forms.update.secret.required" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* secret is required</span>
                </p>
              </template>
              <template v-if="api.update.validation_errors.secret">
                <p v-for="(error_message, index) in api.update.validation_errors.secret" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* {{ error_message }}</span>
                </p>
              </template>
            </div>
          </div>
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2">
              <label>bucket</label>
              <div>
                <input type="text" label="bucket" :disabled="api.update.send || api.get.send" v-model="forms.update.bucket" />
              </div>
              <template v-if="forms.update.submitted">
                <p v-if="!$v.forms.update.bucket.required" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* bucket is required</span>
                </p>
              </template>
              <template v-if="api.update.validation_errors.bucket">
                <p v-for="(error_message, index) in api.update.validation_errors.bucket" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* {{ error_message }}</span>
                </p>
              </template>
            </div>
          </div>
          <div v-if="forms.update.type == 's3'" class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2">
              <label>region</label>
              <div>
                <input type="text" label="region" :disabled="api.update.send || api.get.send" v-model="forms.update.region" />
              </div>
              <template v-if="forms.update.submitted">
                <p v-if="!$v.forms.update.region.required" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* region is required</span>
                </p>
              </template>
              <template v-if="api.update.validation_errors.region">
                <p v-for="(error_message, index) in api.update.validation_errors.region" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* {{ error_message }}</span>
                </p>
              </template>
            </div>
          </div>
          <div v-if="forms.update.type == 'other'" class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2">
              <label>URL</label>
              <div>
                <input type="text" label="URL" :disabled="api.update.send || api.get.send" v-model="forms.update.url" />
              </div>
              <template v-if="forms.update.submitted">
                <p v-if="!$v.forms.update.url.required" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* Type is required</span>
                </p>
                <p v-if="!$v.forms.update.url.url" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* Invalid URL</span>
                </p>
              </template>
              <template v-if="api.update.validation_errors.url">
                <p v-for="(error_message, index) in api.update.validation_errors.url" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                  <span>* {{ error_message }}</span>
                </p>
              </template>
            </div>
          </div>
        </div>
        <button class="IosFullWidthButton forDone mt-16px mb-16px" :disabled="api.update.send || api.get.send">
          <vb-spinner class="sm-mar-right" v-if="api.update.send" />
          <template v-else>Update</template>
        </button>


      </form>

    </div>
  </modal>
</template>

<script>
import { requiredIf, url } from "vuelidate/lib/validators";
import { VOIP_API } from "../../../../utils";

var type_options = [
  {
    text: 'Other',
    value: "other",
  },
  {
    text: "S3",
    value: "s3"
  }
];
export default {
  name: 'StorageModal',
  props: {
    modalName: {
      type: String,
      default: 'StorageModal',
    },
  },
  data() {
    return {
      forms: {
        update: {
          type: "",
          url: "",
          key: "",
          secret: "",
          region: "",
          bucket: "",
          status: false,
          reset() {
            this.type = "";
            this.url = "";
            this.key = "";
            this.secret = "";
            this.region = "";
            this.bucket = "";
            this.status = false;
            this.submitted = false;
          },
          submitted: false,
        },
      },
      api: {
        update: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
        update_status: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
        get: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    typeOptions() { return type_options; },
    updateFormData(){
      let update_form = this.forms.update
      let obj1 = {
        provider: update_form.type,
        key: update_form.key,
        bucket: update_form.bucket,
        secret: update_form.secret,
        status: update_form.status ? "on" : "off",
      }
      let obj2 = update_form.type=='other' ? {
        url: update_form.url,
      } : {}
      let obj3 = update_form.type=='s3' ? {
        region: update_form.region,
      } : {}
      return {
        ...obj1,
        ...obj2,
        ...obj3
      }
    },
  },
  validations: {
    forms: {
      update: {
        type: {
          required: requiredIf(function () {
            return this.forms.update.status;
          }),
        },
        url: {
          required: requiredIf(function () {
            return (
              this.forms.update.status && this.forms.update.type == "other"
            );
          }),
          url,
        },
        key: {
          required: requiredIf(function () {
            return this.forms.update.status;
          }),
        },
        secret: {
          required: requiredIf(function () {
            return this.forms.update.status;
          }),
        },
        region: {
          required: requiredIf(function () {
            return this.forms.update.status && this.forms.update.type == "s3";
          }),
        },
        bucket: {
          required: requiredIf(function () {
            return this.forms.update.status;
          }),
        },
      },
    },
  },
  methods: {
    onBeforeClose(){
      this.forms.update.reset()
      this.api.update.reset()
      this.api.get.reset()
    },
    onBeforeOpen(){
      this.get()
    },
    update() {
      let vm = this;
      vm.forms.update.submitted = true;
      vm.$v.forms.update.$touch();
      if (vm.$v.forms.update.$invalid || vm.api.update.send) return;
      vm.api.update.send = true;
      vm.api.update.validation_errors={}
      vm.api.update.error_message=''
      vm.api.update.increment();
      VOIP_API.endpoints.storage_setting.update({
        ...vm.updateFormData
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully Added!",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.api.update.validation_errors=ex.own_errors
        vm.api.update.error_message=ex.own_message
      })
      .finally(() => {
        vm.forms.update.submitted = false;
        vm.api.update.send = false;
      });
    },
    updatestatus(){
      let vm = this;
      if (vm.api.update_status.send) return;
      vm.api.update_status.send = true;
      vm.api.update.reset()
      VOIP_API.endpoints.storage_setting.statusupdate({
        uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
        status: vm.forms.update.status ? "on" : "off",
      }).then(() => {
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
      }).catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        vm.api.update_status.send = false;
      });
    },
    get() {
      let vm = this;
      vm.api.get.send = true;
      vm.api.get.increment();
      VOIP_API.endpoints.storage_setting
        .get({
          uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
        })
        .then(({ data }) => {
          // console.log(data);
          vm.forms.update.type = data.provider || "s3";
          vm.forms.update.url = data.url || "";
          vm.forms.update.key = data.key || "";
          vm.forms.update.secret = data.secret || "";
          vm.forms.update.region = data.region || "";
          vm.forms.update.bucket = data.bucket || "";
          vm.forms.update.status = data.status == "on" ? true : false;
        })
        .finally(() => {
          vm.api.get.send = false;
        });
    },
  },
}
</script>

<style>

</style>