<template>
  <modal 
    class="center-zoomIn-transition v2 SelectUnavailableTime" 
    :scrollable="true" 
    width="500px" 
    height="auto" 
    :clickToClose="false" 
    :name="modalName" 
    @before-close="onBeforeClose($event)" 
    @before-open="onBeforeOpen($event)"
  >
    
    <div class="dialer-edit w-100">
      <div v-if="$store.getters.getIsMobile" class="dialer-edit-header mt-0 flex-column">
        <div class="d-flex w-100 justify-content-between align-items-center mb-2">
          <h2 class="dialer-edit-title">Select unavailable time</h2>
          <!-- <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" /> -->
        </div>
        <div class="dialer-edit-actions d-flex justify-content-end w-100">
          <button class="newDoneButton" @click="onDone()">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div v-else class="dialer-edit-header b-0">
        <h2 class="dialer-edit-title">Select unavailable time</h2>
        <div class="dialer-edit-actions d-flex">
          <!-- <a class="dialer-button dialer-button-delete" type="button" @click="$modal.hide(modalName)">Close</a> -->
          <a class="newCloseButton" type="button" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>

      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="latestGreyBox-heading-main">Start time</div>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50">
              <VueTimepicker
                placeholder="Select start time"
                format="HH:mm"
                close-on-complete
                v-model="form.start.displayTime"
                @change="form.start = $event"
              />
            </div>
          </div>
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="latestGreyBox-heading-main">End time</div>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-50">
              <VueTimepicker
                placeholder="Select end time"
                format="HH:mm"
                close-on-complete
                v-model="form.end.displayTime"
                @change="form.end = $event"
              />
              <template v-if="submitted.done">
                <p v-if="!isInvalid" class="text text-danger animated bounceIntop">Start time should be greater than end time</p>
              </template>
            </div>
          </div>
        </div>
      </div>

      <div class="d-flex justify-content-end w-100 mt-20px">
        <!-- <button class="dialer-button dialer-button-primary ml-auto" @click="onDone()">
          Done
        </button> -->
        <button class="fullWidthDoneButton" @click="onDone()">
          Update
        </button>
      </div>
    </div>
  </modal>
</template>

<script>
import VueTimepicker from "vue2-timepicker";
import "vue2-timepicker/dist/VueTimepicker.css";
import { LOGGER } from "../../utils";
export default {
  name: "TimeSelectionModal",
  components: {
    VueTimepicker,
  },
  props: {
    modalName: {
      type: String,
      default: "TimeSelectionModal",
    },
  },
  data() {
    return {
      form: {
        start: {
          data: {},
          displayTime: "",
        },
        end: {
          data: {},
          displayTime: "",
        },
      },
      submitted: {
        done: false,
      },
    };
  },
  computed: {
    startTimeFormate() {
      return {
        HH: this.form.start.displayTime.slice(0, 2) == "HH" ? "" : this.form.start.displayTime.slice(0, 2),
        mm: this.form.start.displayTime.slice(3, 5) == "mm" ? "" : this.form.start.displayTime.slice(3, 5),
        // a:
        //   this.form.start.displayTime.slice(6, 8) == "a"
        //     ? ""
        //     : this.form.start.displayTime.slice(6, 8),
      };
    },
    endTimeFormate() {
      return {
        HH: this.form.end.displayTime.slice(0, 2) == "HH" ? "" : this.form.end.displayTime.slice(0, 2),
        mm: this.form.end.displayTime.slice(3, 5) == "mm" ? "" : this.form.end.displayTime.slice(3, 5),
        // a:
        //   this.form.end.displayTime.slice(6, 8) == "a"
        //     ? ""
        //     : this.form.end.displayTime.slice(6, 8),
      };
    },
    isInvalid(){
      if (
        this.startTimeFormate.HH == "" ||
        this.startTimeFormate.mm == "" ||
        this.startTimeFormate.ss == "" ||
        // this.startTimeFormate.a == "" ||
        // this.endTimeFormate.a == "" ||
        this.endTimeFormate.HH == "" ||
        this.endTimeFormate.mm == "" ||
        this.endTimeFormate.ss == ""
      ) {
        return false;
      } else if (
        // this.startTimeFormate.a == this.endTimeFormate.a &&
        this.startTimeFormate.HH == this.endTimeFormate.HH &&
        this.startTimeFormate.mm == this.endTimeFormate.mm
      ) {
        return false;
      } else if (
        // this.startTimeFormate.a == this.endTimeFormate.a &&
        this.startTimeFormate.HH > this.endTimeFormate.HH
      ) {
        return false;
      } else if (
        this.startTimeFormate.HH == this.endTimeFormate.HH &&
        this.startTimeFormate.mm > this.endTimeFormate.mm
      ) {
        return false;
      } else if (
        this.startTimeFormate.HH == this.endTimeFormate.HH &&
        this.startTimeFormate.mm == this.endTimeFormate.mm &&
        this.startTimeFormate.ss > this.endTimeFormate.ss
      ) {
        return false;
      } else {
        return true;
      }
    },
    isSame() {
      if (
        this.startTimeFormate.HH == "" ||
        this.startTimeFormate.mm == "" ||
        // this.startTimeFormate.a == "" ||
        // this.endTimeFormate.a == "" ||
        this.endTimeFormate.HH == "" ||
        this.endTimeFormate.mm == ""
      ) {
        return false;
      } else if (
        // this.startTimeFormate.a == this.endTimeFormate.a &&
        this.startTimeFormate.HH == this.endTimeFormate.HH &&
        this.startTimeFormate.mm == this.endTimeFormate.mm
      ) {
        return false;
      } else {
        return true;
      }
    },
  },
  methods: {
    onDone() {
      this.submitted.done = true;
      if (!this.isInvalid) return;
      this.$emit("updated", {
        start: this.form.start,
        end: this.form.end,
      });
      this.$modal.hide(this.modalName);
    },
    onBeforeOpen(event) {
      LOGGER.log("event.params", event.params);
      this.form.start.displayTime = event.params.start || "";
      this.form.end.displayTime = event.params.end || "";
    },
    onBeforeClose() {
      this.submitted.done = false;
      this.$emit('before-close')
    },
  },
};
</script>

<style>
</style>