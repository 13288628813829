var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600",
    attrs: {
      "width": "800px",
      "height": "auto",
      "scrollable": true,
      "name": "UserCallRecordingModal"
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Select users to record")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "dialer-button dialer-button-delete",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('UserCallRecordingModal');
      }
    }
  }, [_vm._v("Cancel")]), _c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('UserCallRecordingModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('div', {
    staticClass: "dialer-team-name mb-4"
  }, [_c('div', {
    staticClass: "dialer-tab-content"
  }, [_vm.show ? _c('ul', {
    staticClass: "list-unstyled dialer-asssignee-list"
  }, _vm._l(_vm.userList, function (user, key) {
    return _c('li', {
      key: key,
      staticClass: "dialer-assignee"
    }, [_c('div', {
      staticClass: "dialer-assignee-name"
    }, [_c('div', {
      staticClass: "dialer-initials colour-0"
    }, [_vm._v(" " + _vm._s(_vm._f("firstCharacter")(user.user)) + " ")]), _c('span', [_vm._v(_vm._s(user.user))]), _c('span', {
      staticClass: "text-white small bg-primary px-2"
    }, [_vm._v(_vm._s(user.extn))])]), _c('label', [_vm._v("Off")]), _c('toggle-button', {
      attrs: {
        "value": _vm.getSlideValue(user.off),
        "labels": {
          checked: 'On',
          unchecked: 'off'
        }
      },
      on: {
        "change": function ($event) {
          return _vm.updateSetting(user.account, 'off');
        }
      }
    }), _c('label', [_vm._v("Auto Record")]), _c('toggle-button', {
      attrs: {
        "value": _vm.getSlideValue(user.auto),
        "labels": {
          checked: 'On',
          unchecked: 'off'
        }
      },
      on: {
        "change": function ($event) {
          return _vm.updateSetting(user.account, 'auto');
        }
      }
    }), _c('label', [_vm._v("On demand Record")]), _c('toggle-button', {
      attrs: {
        "value": _vm.getSlideValue(user.ondemand),
        "labels": {
          checked: 'On',
          unchecked: 'off'
        }
      },
      on: {
        "change": function ($event) {
          return _vm.updateSetting(user.account, 'ondemand');
        }
      }
    })], 1);
  }), 0) : _vm._e()])])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }