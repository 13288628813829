<template>
  <div>
    <div class="mainHeading withButton">
      Public Holiday/Event
      <div class="d-flex">
        <button @click="$modal.show('hrmAddNewPublicHolidayModal')" class="dialer-button dialer-button-primary mr-16px withIcon">
          <vb-icon icon="calender-hrmIcon" class="mr-16px fillWhite" height="18.8px" width="18.8px"/>
          Add public holiday
        </button>
        <button @click="$modal.show('hrmImportPublicHolidayModal')" class="dialer-button dialer-button-primary mr-0 withIcon">
          <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
          Import
        </button>
      </div>
    </div>
    <div class="mainDescription mb-4">
      Select whether or not these days are counted against employee holiday allowance
    </div>
    <div class="hrmTabsContainer pt-3 pb-4">
      <div @click="showTabs = 'All holidays'" :class="`hrmTabsButton ${showTabs == 'All holidays' ? 'active' : ''} mr-16px`">All holidays</div>
      <div @click="showTabs = 'General'" :class="`hrmTabsButton ${showTabs == 'General' ? 'active' : ''} mr-16px`">General</div>
      <div @click="showTabs = 'Sick'" :class="`hrmTabsButton ${showTabs == 'Sick' ? 'active' : ''} mr-16px`">Sick</div>
    </div>
    <template v-if="showTabs == 'All holidays'">
      <!-- {{showTabs}} -->
      <div class="documentsModule-Table mt-32px">
        <!-- <div class="HrmTableHead-container">
          <div class="HrmTableHead one">Employee</div>
          <div class="HrmTableHead two">Event type</div>
          <div class="HrmTableHead three">Date</div>
          <div class="HrmTableHead four">Visibility</div>
          <div class="HrmTableHead five">Visibility</div>
        </div> -->
        <vb-table class="HrmTable">
          <template slot="header">
            <tr>
              <th class="HrmTableHead one pt-1">Name</th>
              <th class="HrmTableHead two pt-1">date</th>
              <th class="HrmTableHead three pt-1">groups</th>
              <th class="HrmTableHead four pt-1">Actions</th>
            </tr>
          </template>
          <template #body>
            <tr v-for="n in 6" :key="n" class="dialer-row-select" >
              <td class="dialer-row-title one">
                Boxing Day
              </td>
              <td class="dialer-col-right two">
                26-12-2023
              </td>
              <td class="dialer-col-right three">General</td>
              <td class="dialer-col-right four">
                <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                  <template #button-content>
                    <b-icon icon="three-dots-vertical"></b-icon>
                  </template>
                  <b-dropdown-item href="#">Action</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </template>
        </vb-table>
      </div>
    </template>
    <template v-if="showTabs == 'General'">
      <!-- {{showTabs}} -->
      <div class="documentsModule-Table mt-32px">
        <!-- <div class="HrmTableHead-container">
          <div class="HrmTableHead one">Employee</div>
          <div class="HrmTableHead two">Event type</div>
          <div class="HrmTableHead three">Date</div>
          <div class="HrmTableHead four">Visibility</div>
          <div class="HrmTableHead five">Visibility</div>
        </div> -->
        <vb-table class="HrmTable">
          <template slot="header">
            <tr>
              <th class="HrmTableHead one pt-1">Name</th>
              <th class="HrmTableHead two pt-1">date</th>
              <th class="HrmTableHead three pt-1">groups</th>
              <th class="HrmTableHead four pt-1">Actions</th>
            </tr>
          </template>
          <template #body>
            <tr v-for="n in 6" :key="n" class="dialer-row-select" >
              <td class="dialer-row-title one">
                Boxing Day
              </td>
              <td class="dialer-col-right two">
                26-12-2023
              </td>
              <td class="dialer-col-right three">General</td>
              <td class="dialer-col-right four">
                <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                  <template #button-content>
                    <b-icon icon="three-dots-vertical"></b-icon>
                  </template>
                  <b-dropdown-item href="#">Action</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </template>
        </vb-table>
      </div>
    </template>
    <template v-if="showTabs == 'Sick'">
      <!-- {{showTabs}} -->
      <div class="documentsModule-Table mt-32px">
        <!-- <div class="HrmTableHead-container">
          <div class="HrmTableHead one">Employee</div>
          <div class="HrmTableHead two">Event type</div>
          <div class="HrmTableHead three">Date</div>
          <div class="HrmTableHead four">Visibility</div>
          <div class="HrmTableHead five">Visibility</div>
        </div> -->
        <vb-table class="HrmTable">
          <template slot="header">
            <tr>
              <th class="HrmTableHead one pt-1">Name</th>
              <th class="HrmTableHead two pt-1">date</th>
              <th class="HrmTableHead three pt-1">groups</th>
              <th class="HrmTableHead four pt-1">Actions</th>
            </tr>
          </template>
          <template #body>
            <tr v-for="n in 6" :key="n" class="dialer-row-select" >
              <td class="dialer-row-title one">
                Boxing Day
              </td>
              <td class="dialer-col-right two">
                26-12-2023
              </td>
              <td class="dialer-col-right three">General</td>
              <td class="dialer-col-right four">
                <b-dropdown size="lg"  variant="link" toggle-class="text-decoration-none tb-notes-dd" no-caret menu-class="notes-table-dd">
                  <template #button-content>
                    <b-icon icon="three-dots-vertical"></b-icon>
                  </template>
                  <b-dropdown-item href="#">Action</b-dropdown-item>
                </b-dropdown>
              </td>
            </tr>
          </template>
        </vb-table>
      </div>
    </template>
    <modal class="dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll" name="hrmAddNewPublicHolidayModal">
      <div>
        <div class="modalHeading-container">
          <div class="modalHeading">Add public holiday</div>
          <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('hrmAddNewPublicHolidayModal')">Close</button>
        </div>
        <div class="modalInner-container">
          <div class="innerShadowInput-container mt-25px">
            <label>Date</label>
            <div class="inputWithIconContainer">
              <input class="withIconOnRight primaryBorder noInnerBoxShadow"/>
              <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
            </div>
          </div>
          <b-form-checkbox class="HrmCheckBox-container">
            Repeat every year
          </b-form-checkbox>
          <div class="innerShadowInput-container ">
            <label>Name</label>
            <input />
          </div>
          <div class="innerShadowInput-container">
            <label>Allowance count setting</label>
            <b-dropdown class="hrmInnerShadow-dd" no-caret>
              <template #button-content>
                <div class="button-content">
                  <div class="buttonContentText"></div>
                  <b-icon class="downIcon" icon="caret-down-fill" />
                </div>
              </template>
              <b-dropdown-item>
                Allowance count setting
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="innerShadowInput-container">
            <label>Allowance count setting</label>
            <b-form-checkbox class="HrmCheckBox-container">
              General
            </b-form-checkbox>
            <b-form-checkbox class="HrmCheckBox-container">
              Sick
            </b-form-checkbox>
          </div>
          <button class="dialer-button dialer-button-primary mt-32px mr-0"> Create </button>
        </div>
      </div>
    </modal>
    <modal class="dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll" name="hrmImportPublicHolidayModal">
      <div>
        <div class="modalHeading-container">
          <div class="modalHeading">Import public holidays</div>
          <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('hrmImportPublicHolidayModal')">Close</button>
        </div>
        <div class="modalInner-container">
          <div class="innerShadowInput-container">
            <label>Country</label>
            <b-dropdown class="hrmInnerShadow-dd" no-caret>
              <template #button-content>
                <div class="button-content">
                  <div class="buttonContentText"></div>
                  <b-icon class="downIcon" icon="caret-down-fill" />
                </div>
              </template>
              <b-dropdown-item>
                Country
              </b-dropdown-item>
            </b-dropdown>
          </div>
          <button class="dialer-button dialer-button-primary mt-32px mr-0"> Import </button>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
export default {
  name: 'CalendarPublicHolidays',
  data(){
    return {
      showTabs: 'All holidays',
    }
  }
}
</script>

<style>

</style>