<template>
  <div class="dialer-settings-main">
    <template>
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$emit('back')">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
          <div class="settingsTextOnTop">Settings</div>
        </div>
        <div class="dialer-settings-title topMainHeading">Global Settings</div>
      </div>
    </template>
    <section class="dialer-settings-section GlobalSettings v2">
      
      <form class="GlobalSettings-box1 mt-32px forIos" @submit.prevent="update">
        <div class="firstSection">
          <span class="">
            <b-icon icon="clock" class="sm-mar-right" />
            {{ current_date.time }}
          </span>
          <span class="">
            <b-icon icon="calendar2" class="sm-mar-right" />
            {{ current_date.date }}
          </span>
        </div>
        <div class="secondSection mt-22px">
          <div 
            class="profile-image-section mx-auto" 
            @click="$modal.show('GlobalSettingProfileImageUploader', { 
              type: 'COMPANY', 
              accountcode: $store.getters.getCurrentUser.default_accountcode, 
              current_image: $store.getters.getCurrentUser.companyLogo 
            })" 
            :aria-disabled="api_sent.update_company_dp"
          >
            <div class="loader-icon" v-if="api_sent.update_company_dp">
              <vb-loading />
            </div>
            <div class="profile-img">
              <img style="height: 100%; width: 100%" id="company-profile-image-updated" :src="$store.getters.getCurrentUser.companyLogo" @error="$event.target.src=local_filess.images.user_male" :alt="'Company Image'" />
            </div>
          </div>
        </div>
        <div class="thirdSection mt-16px">
          <h1 class="mb-0">{{ `${getCurrentUser.company}` }}</h1>
          <h4>{{` (${getCurrentUser.uid})`}}</h4>
        </div>
      </form>
      <div class="textAboveCard">Country</div>
      <div class="basicWhiteIOScard mb-16px">
        <div class="basicWhiteIOScard-item flex-column">
          
          <div class="mobileInputIOS InputIosV2">
            <label class="headingInsideTable">Country</label>
            <b-form-select :disabled="true" v-model="selected" :options="options"></b-form-select>
          </div>
        </div>
        <div class="basicWhiteIOScard-item flex-column">
          
          <div class="mobileInputIOS InputIosV2">
            <label class="headingInsideTable">Timezone</label>
            <select :disabled="api_sent.account_detail || api_sent.time_zone" v-model="userTimezone" @change="updateTimeZone()" class="custom-select">
              <option v-for="(timeZone, key) in timeZones" :key="key">{{ timeZone }}</option>
            </select>
          </div>
        </div>
      </div>
      <div class="basicWhiteIOScard">
        <div class="basicWhiteIOScard-item">
          <div class="headingInsideTable w-100">Busy Lamp Field</div>
          <div class="position-relative">
            <vb-spinner v-if="api_sent.blf" />
            <div v-else id="BLFSwitchLock" :class="['w-fit-content',!getUserPermissions.blf ? 'for-disable' : '']">
              <b-form-checkbox 
                :disabled="!getUserPermissions.blf"
                class="dialer-switch IosSwitch"
                name="check-button"
                switch
                :checked="getCurrentUser.blf"
                @change="updateBLF();"
              />
            </div>
          </div>
        </div>
        <div class="basicWhiteIOScard-item" @click="checkPermission(getUserPermissions.call_pickup,()=>$modal.show('PickupGroupsModal'))">
          <div class="headingInsideTable w-100">Pickup Groups</div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
        </div>
        <div class="basicWhiteIOScard-item" @click="$modal.show('DomainModal')">
          <div class="headingInsideTable w-100">Domain</div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
        </div>
        <div class="basicWhiteIOScard-item" @click="checkPermission(getUserPermissions.unlimited_custom_status,()=>$modal.show('StatusesModal'))">
          <div class="headingInsideTable w-100">Status</div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
        </div>
        <div class="basicWhiteIOScard-item" @click="checkPermission(getUserPermissions.call_tagging_and_notes,()=>$modal.show('TagsModal'))">
          <div class="headingInsideTable w-100">Tags</div>
          <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
        </div>
      </div>
      
      <StatusesModal />
      <TagsModal />
      <DomainModal />
      <ProfileImageUploaderModal :modalName="`GlobalSettingProfileImageUploader`" @image-uploaded="''" />
      <PickupGroupsModal />
    </section>
  </div>
</template>

<script>
import StatusesModal from "../modals/Statuses.vue";
import DomainModal from "../modals/Domain.vue";
import TagsModal from "../modals/Tags.vue";
import PickupGroupsModal from "../modals/pickupgroups/PickupGroups.vue";
import ProfileImageUploaderModal from "../modals/common/ProfileImageUploader.vue";

import { LOGGER, VOIP_API } from "../../../utils";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { permission_handling } from '@/mixin';

var moment = require("moment-timezone");
var interval_id;
export default {
  name: "Global",
  mixins: [permission_handling],
  components: {
    ProfileImageUploaderModal,
    StatusesModal,
    TagsModal,
    DomainModal,
    PickupGroupsModal,
    // Organizations,
  },
  data() {
    return {
      userTimezone: null,
      timeZones: [],
      musiconhold: false,
      musiconholdname: null,
      musiconholdid: null,
      accountdetail: null,
      blf: false,
      selected: '',
      domains: [],
      api_sent: {
        update_company_dp: false,
        call_route: false,
        account_detail: false,
        update: false,
        list: false,
        blf: false,
        time_zone: false,
      },
      is_submitted: {
        update: false,
      },
      forms: {
        update: {
          company_name: "",
          reset() {
            this.company_name = "";
          },
        },
      },
      assets: {
        spinner: require("@/assets/images/spinner.svg"),
      },
      options: [],
      current_date: {
        date: "",
        time: "",
      },
      filter: {
        organizations: {
          search: '',
        },
      },
    };
  },
  inject:['isEmpty','local_filess','appNotify'],
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getCurrentUser: 'getCurrentUser',
    }),
    organizationimagespath(){
      // return `${this.current_user.apiServer.split("/").slice(0, -1).join("/")}/storage/defaults/logos/`
      return `${this.$store.getters.getCurrentUser?.apiServer.split("/").slice(0, -1).join("/")}/storage/defaults/logos/`
    },
    filterOrganizations(){
      const search = this.filter.organizations.search.trim().toLowerCase()
      // const organizations = this.current_user.organizations || []
      const organizations = this.$store.getters.getCurrentUser?.organizations || []
      const filter_organizations = organizations.filter(item=>`${item.label} (${item.uid})`.trim().toLowerCase().includes(search))
      return filter_organizations
    },
    currentOrganization(){ 
      // const selected_organization_id = this.current_user.selected_organization_id
      const selected_organization_id = this.$store.getters.getCurrentUser?.selected_organization_id
      // const organizations = this.current_user?.organizations ?? []
      const organizations = this.$store.getters.getCurrentUser?.organizations ?? []
      return organizations.find(item=>item.id==selected_organization_id)
     },
  },
  validations: {
    forms: {
      update: {
        company_name: {
          required,
        },
      },
    },
  },
  methods: {
    uploadImage(Event) {
      let app = this;
      if (this.api_sent.update_company_dp) return;
      const [file] = Event.target.files;
      const [type, extension] = file.type.split("/");
      const formData = new FormData();
      formData.append("image", file, file.name);
      // formData.append("accountcode", this.current_user.account);
      formData.append("accountcode", this.$store.getters.getCurrentUser?.account);
      // formData.append("uid", this.current_user.uid);
      formData.append("uid", this.$store.getters.getCurrentUser?.uid);
      formData.append("extension", extension);
      formData.append("type", type);
      formData.append("change_image_type", "company");
      this.api_sent.update_company_dp = true;
      VOIP_API.endpoints.user
        .updateprofileimage(formData)
        .then(
          ({
            data: {
              response: { imageLink },
            },
          }) => {
            // let img =
            //   app.current_user.companyLogo.split("/").slice(0, -1).join("/") +
            //   `/${imageLink}`;
            // let img = `${app.current_user.apiServer
            let img = `${app.$store.getters.getCurrentUser?.apiServer
              .split("/")
              .slice(0, -1)
              .join("/")}/storage/defaults/logos/${imageLink}`;
            app.$store.state.common.user.companyLogo = img;
            setTimeout(()=>{
              app.fetchOrganizations()
            },1 * 1000)
          }
        )
        .finally(() => {
          this.api_sent.update_company_dp = false;
        });
    },
    async uploadAvatar(image){
      LOGGER.log({image})
      const src = document.getElementById(`image-to-upload-${image}`).src
      let app = this;
      if (app.api_sent.update_company_dp) return;
      const res = await fetch(src)
      const blob = await res.blob()
      const formData = new FormData();
      formData.append("image", blob, image);
      // formData.append("accountcode", app.current_user.account);
      formData.append("accountcode", app.$store.getters.getCurrentUser?.account);
      // formData.append("uid", app.current_user.uid);
      formData.append("uid", app.$store.getters.getCurrentUser?.uid);
      formData.append("extension", 'png');
      formData.append("type", 'image');
      formData.append("change_image_type", "company");
      app.api_sent.update_company_dp = true;
      VOIP_API.endpoints.user
        .updateprofileimage(formData)
        .then(
          ({
            data: {
              response: { imageLink },
            },
          }) => {
            let img = `${app.organizationimagespath}${imageLink}`;
            app.$store.state.common.user.companyLogo = img;
            setTimeout(()=>{
              app.fetchOrganizations()
            },1 * 1000)
          }
        )
        .finally(() => {
          app.api_sent.update_company_dp = false;
        });
    },
    // callRouteSetting() {
    //   if (this.api_sent.call_route) return;
    //   this.api_sent.call_route = true;
    //   VOIP_API.endpoints.users
    //     .callroute({
    //       accountcode: this.current_user.default_accountcode,
    //       flag: "globalmoh",
    //     })
    //     .then(({ data: res }) => {
    //       if (res.playlistid) {
    //         this.musiconhold = true;
    //         this.musiconholdid = res.playlistid;
    //         this.musiconholdname = res.playlistname;
    //       }
    //     })
    //     .finally(() => {
    //       this.api_sent.call_route = false;
    //     });
    // },
    fetchOrganizations(){
      let vm = this
      VOIP_API.endpoints.user.organizationlist({
        accountcode: vm.$store.getters.getCurrentUser?.account,//vm.current_user.account,
        uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid
      }).then(({ data: { data: organizations } })=>{
        const new_organizations_array = organizations.map(organization=>{
          return {
            id: organization.origanization.origanization_id,
            uid: organization.origanization.origanization_uid,
            label: organization.origanization.origanization_label,
            logo: organization.origanization.origanization_logo,
          }
        })
        vm.$store.state.common.user.organizations = new_organizations_array
        vm.$store.state.common.user.selected_organization_id = vm.$store.state.common.user.selected_organization_id || new_organizations_array[0] ? new_organizations_array[0].id : ''
      })
    },
    load() {
      if (this.api_sent.account_detail) return;
      this.api_sent.account_detail = true;
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data: accountdetail }) => {
        this.accountdetail = accountdetail;
        // if (this.accountdetail.user_detail) {
        //   this.forms.update.company_name =
        //     this.accountdetail.user_detail.companyname;
        // }
        // moment.tz('Antarctica/Palmer')._z.countries()?.[0],
        // this.timeZones = moment.tz.zonesForCountry(accountdetail?.user_detail?.country) // 'AQ' ??
        this.timeZones = moment.tz.names(); 
        this.userTimezone = accountdetail?.user_detail?.timezone ?? ''; 
        this.selected = accountdetail?.user_detail?.country ?? ''
        this.options = [{text:accountdetail?.user_detail?.countryname ?? '',value:accountdetail?.user_detail?.country ?? ''}]
        moment.tz.setDefault(this.userTimezone);
      })
      .finally(() => {
        this.api_sent.account_detail = false;
      });
    },
    update() {
      this.is_submitted.update = true;
      this.$v.$touch();
      if (this.$v.forms.update.$invalid || this.api_sent.update) return;
      this.api_sent.update = true;
      setTimeout(() => {
        //then
        this.appNotify({
          message: "Successfully updated",
          type: "success",
        })
        //finally
        this.api_sent.update = false;
        this.is_submitted.update = false;
      }, 5 * 1000);
    },
    afterAdded() {
      var app = this;
      setTimeout(() => {
        app.list();
      }, 5 * 1000);
    },
    list() {
      this.api_sent.list = true;
      VOIP_API.endpoints.domain.list()
      .then(({ data: domains }) => {
        this.domains = domains;
      })
      .finally(() => {
        this.api_sent.list = false;
      });
    },
    setDate() {
      let vm = this;
      interval_id = setInterval(() => {
        vm.current_date.date = moment().format("D/M/YYYY");
        vm.current_date.time = moment().format("h:mm:ss A");
      }, 1 * 1000);
    },
    updateBLF(){
      let vm = this
      if(this.api_sent.blf) return;
      this.api_sent.blf=true
      VOIP_API.endpoints.users.updateblf({
        field: 'blf',
        uid: vm.getCurrentUser?.uid,//vm.current_user.uid,
        value: `${vm.getCurrentUser.blf ? 'disable' : 'enable'}`
      }).then(()=>{
        vm.$store.state.common.user.blf = !vm.getCurrentUser.blf;
        vm.appNotify({
          message: "Successfully updated",
          type: "success",
        })
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api_sent.blf=false
      })
    },
    updateTimeZone(){
      let vm = this
      if(this.api_sent.time_zone || this.api_sent.account_detail) return;
      this.api_sent.time_zone=true
      // updateTimeZone
      VOIP_API.endpoints.users.updateblf({
        field: 'timezone',
        uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
        value: this.userTimezone
      }).then(()=>{
        moment.tz.setDefault(this.userTimezone);
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      }).catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api_sent.time_zone=false
      })
    },
  },
  created(){
    // this.forms.update.company_name = this.current_user.company;
    this.forms.update.company_name = this.$store.getters.getCurrentUser?.company;
  },
  // mounted() {
  //   this.forms.update.company_name = this.current_user.company;
  //   this.userTimezone = moment.tz.guess();
  //   this.timeZones = moment.tz.names();
  //   // this.callRouteSetting();
  //   this.load();
  //   this.list();
  //   this.setDate();
  // },
  activated() {
    // this.userTimezone = moment.tz.guess();
    // this.timeZones = moment.tz.names();
    // console.log(
    //   moment.tz('Antarctica/Palmer')._z.countries()?.[0],
    //   moment.tz.zonesForCountry('GB')
    // )
    // this.callRouteSetting();
    this.load();
    this.list();
    this.setDate();
  },
  beforeDestroy() {
    clearInterval(interval_id);
  },
};
</script>

<style lang="scss" scoped>
.profile-image-section {
  position: relative;
  width: 180px;
  height: 180px;
  display: flex;
  overflow: hidden;
  @include border-radius(50%);
  .loader-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .profile-img-upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: none;
    justify-content: center;
    .upload-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      opacity: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(50%);
    }
  }
  &:hover {
    .profile-img-upload-btn {
      display: block;
      cursor: pointer;
    }
  }
}
</style>