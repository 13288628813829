export default {
  "items": [{
    "height": 30,
    "id": "team368562",
    "team_id": 368562,
    "type": "team",
    "team": {
      "id": 368562,
      "name": "Amsterdam - production",
      "is_default": false
    }
  },
  {
    "indexSpecial": 0,
    "height": 60,
    "id": "employee2788495",
    "team_id": 368562,
    "type": "employee",
    "employee": {
      "id": 2788495,
      "full_name": "Ahsen van der Burgt",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28154.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273565,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788495/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {
        "5384": [{
          "leave_type_id": null,
          "calendar_id": 5384,
          "calendar_event_id": 35806989,
          "company_user_id": 2788495,
          "company_user_full_name": "Ahsen van der Burgt",
          "replacement_id": null,
          "type": "birthday",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": null,
          "replacement": null,
          "policy_name": null,
          "policy_color": null,
          "hide_name": null,
          "is_multi_day": null,
          "is_first_day": null,
          "is_last_day": null
        }]
      },
      "replacing_events": {},
      "unpaid_absences": []
    }
  },
  {
    "indexSpecial": 1,
    "height": 60,
    "id": "employee2788496",
    "team_id": 368562,
    "type": "employee",
    "employee": {
      "id": 2788496,
      "full_name": "Amber West",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28155.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273565,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788496/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {},
      "replacing_events": {},
      "unpaid_absences": []
    }
  },
  {
    "indexSpecial": 2,
    "height": 60,
    "id": "employee2788498",
    "team_id": 368562,
    "type": "employee",
    "employee": {
      "id": 2788498,
      "full_name": "Isabella Singh",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28166.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273565,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788498/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {
        "5389": [{
          "leave_type_id": 477241,
          "calendar_id": 5389,
          "calendar_event_id": 35807121,
          "company_user_id": 2788498,
          "company_user_full_name": "Isabella Singh",
          "replacement_id": null,
          "type": "leave_event",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "Nemo vel nobis quam.",
          "replacement": null,
          "policy_name": "Holiday - NL",
          "policy_color": "#1A9211",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": true,
          "is_last_day": false
        }],
        "5390": [{
          "leave_type_id": 477241,
          "calendar_id": 5390,
          "calendar_event_id": 35807121,
          "company_user_id": 2788498,
          "company_user_full_name": "Isabella Singh",
          "replacement_id": null,
          "type": "leave_event",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "Nemo vel nobis quam.",
          "replacement": null,
          "policy_name": "Holiday - NL",
          "policy_color": "#1A9211",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5391": [{
          "leave_type_id": 477241,
          "calendar_id": 5391,
          "calendar_event_id": 35807121,
          "company_user_id": 2788498,
          "company_user_full_name": "Isabella Singh",
          "replacement_id": null,
          "type": "leave_event",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "Nemo vel nobis quam.",
          "replacement": null,
          "policy_name": "Holiday - NL",
          "policy_color": "#1A9211",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5392": [{
          "leave_type_id": 477241,
          "calendar_id": 5392,
          "calendar_event_id": 35807121,
          "company_user_id": 2788498,
          "company_user_full_name": "Isabella Singh",
          "replacement_id": null,
          "type": "leave_event",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "Nemo vel nobis quam.",
          "replacement": null,
          "policy_name": "Holiday - NL",
          "policy_color": "#1A9211",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": true
        }]
      },
      "replacing_events": {},
      "unpaid_absences": []
    }
  },
  {
    "indexSpecial": 3,
    "height": 60,
    "id": "employee2788502",
    "team_id": 368562,
    "type": "employee",
    "employee": {
      "id": 2788502,
      "full_name": "Miles van Maaren",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28147.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273568,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788502/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {},
      "replacing_events": {},
      "unpaid_absences": []
    }
  },
  {
    "indexSpecial": 4,
    "height": 60,
    "id": "employee2788503",
    "team_id": 368562,
    "type": "employee",
    "employee": {
      "id": 2788503,
      "full_name": "Scarlett Hartley",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28157.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273565,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788503/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {
        "5376": [{
          "leave_type_id": 477242,
          "calendar_id": 5376,
          "calendar_event_id": 35807244,
          "company_user_id": 2788503,
          "company_user_full_name": "Scarlett Hartley",
          "replacement_id": null,
          "type": "leave_event",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "Sit ut magnam aliquid.",
          "replacement": null,
          "policy_name": "Business trip",
          "policy_color": "#d2a657",
          "hide_name": false,
          "is_multi_day": false,
          "is_first_day": true,
          "is_last_day": true
        }]
      },
      "replacing_events": {},
      "unpaid_absences": []
    }
  },
  {
    "indexSpecial": 5,
    "height": 60,
    "id": "employee2788505",
    "team_id": 368562,
    "type": "employee",
    "employee": {
      "id": 2788505,
      "full_name": "Tyler Owens",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28093.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273567,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788505/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {
        "5362": [{
          "leave_type_id": null,
          "calendar_id": 5362,
          "calendar_event_id": 35807313,
          "company_user_id": 2788505,
          "company_user_full_name": "Tyler Owens",
          "replacement_id": null,
          "type": "birthday",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": null,
          "replacement": null,
          "policy_name": null,
          "policy_color": null,
          "hide_name": null,
          "is_multi_day": null,
          "is_first_day": null,
          "is_last_day": null
        }]
      },
      "replacing_events": {},
      "unpaid_absences": []
    }
  }, {
    "height": 36,
    "id": "break368562",
    "team_id": 368562,
    "type": "break"
  }, {
    "height": 30,
    "id": "team368563",
    "team_id": 368563,
    "type": "team",
    "team": {
      "id": 368563,
      "name": "UK - Digital production",
      "is_default": false
    }
  },
  {
    "indexSpecial": 0,
    "height": 60,
    "id": "employee2788497",
    "team_id": 368563,
    "type": "employee",
    "employee": {
      "id": 2788497,
      "full_name": "Corey Holt",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28101.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273567,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788497/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {},
      "replacing_events": {},
      "unpaid_absences": []
    }
  },
  {
    "indexSpecial": 1,
    "height": 60,
    "id": "employee2788499",
    "team_id": 368563,
    "type": "employee",
    "employee": {
      "id": 2788499,
      "full_name": "Kapila Nadig",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28114.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273566,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788499/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {
        "5383": [{
          "leave_type_id": 477240,
          "calendar_id": 5383,
          "calendar_event_id": 35807155,
          "company_user_id": 2788499,
          "company_user_full_name": "Kapila Nadig",
          "replacement_id": null,
          "type": "leave_event",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "Qui eius fugiat rerum.",
          "replacement": null,
          "policy_name": "Holiday - India",
          "policy_color": "#26AFE7",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": true,
          "is_last_day": false
        }],
        "5384": [{
          "leave_type_id": 477240,
          "calendar_id": 5384,
          "calendar_event_id": 35807155,
          "company_user_id": 2788499,
          "company_user_full_name": "Kapila Nadig",
          "replacement_id": null,
          "type": "leave_event",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "Qui eius fugiat rerum.",
          "replacement": null,
          "policy_name": "Holiday - India",
          "policy_color": "#26AFE7",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5385": [{
          "leave_type_id": 477240,
          "calendar_id": 5385,
          "calendar_event_id": 35807155,
          "company_user_id": 2788499,
          "company_user_full_name": "Kapila Nadig",
          "replacement_id": null,
          "type": "leave_event",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "Qui eius fugiat rerum.",
          "replacement": null,
          "policy_name": "Holiday - India",
          "policy_color": "#26AFE7",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5386": [{
          "leave_type_id": 477240,
          "calendar_id": 5386,
          "calendar_event_id": 35807155,
          "company_user_id": 2788499,
          "company_user_full_name": "Kapila Nadig",
          "replacement_id": null,
          "type": "leave_event",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "Qui eius fugiat rerum.",
          "replacement": null,
          "policy_name": "Holiday - India",
          "policy_color": "#26AFE7",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5387": [{
          "leave_type_id": 477240,
          "calendar_id": 5387,
          "calendar_event_id": 35807155,
          "company_user_id": 2788499,
          "company_user_full_name": "Kapila Nadig",
          "replacement_id": null,
          "type": "leave_event",
          "status": "approved",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "Qui eius fugiat rerum.",
          "replacement": null,
          "policy_name": "Holiday - India",
          "policy_color": "#26AFE7",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": true
        }]
      },
      "replacing_events": {},
      "unpaid_absences": []
    }
  }, {
    "height": 36,
    "id": "break368563",
    "team_id": 368563,
    "type": "break"
  }, {
    "height": 30,
    "id": "team368564",
    "team_id": 368564,
    "type": "team",
    "team": {
      "id": 368564,
      "name": "UK - Programming",
      "is_default": false
    }
  },
  {
    "indexSpecial": 0,
    "height": 60,
    "id": "employee2788500",
    "team_id": 368564,
    "type": "employee",
    "employee": {
      "id": 2788500,
      "full_name": "Max Savage",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28104.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273567,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788500/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {},
      "replacing_events": {},
      "unpaid_absences": []
    }
  },
  {
    "indexSpecial": 1,
    "height": 60,
    "id": "employee2788504",
    "team_id": 368564,
    "type": "employee",
    "employee": {
      "id": 2788504,
      "full_name": "Tom Hamilton",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28105.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273567,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788504/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {},
      "replacing_events": {},
      "unpaid_absences": []
    }
  }, {
    "height": 36,
    "id": "break368564",
    "team_id": 368564,
    "type": "break"
  }, {
    "height": 30,
    "id": "team368560",
    "team_id": 368560,
    "type": "team",
    "team": {
      "id": 368560,
      "name": "Employees without team",
      "is_default": true
    }
  },
  {
    "indexSpecial": 0,
    "height": 60,
    "id": "employee2788494",
    "team_id": 368560,
    "type": "employee",
    "employee": {
      "id": 2788494,
      "full_name": "leoafshal",
      "position_title": null,
      "thumb_url": "https://cdn.sage.hr/cupcake/images/53h_28105.jpg",
      "holiday_group_id": 110623,
      "custom_fields": {},
      "location_id": 273564,
      "weekends": ["Sat", "Sun"],
      "permissions": {
        "read": "/directory/2788494/time-off",
        "read_employee_card": null,
        "see_details": true,
        "see_tooltip_details": true
      },
      "events": {
        "5370": [{
          "leave_type_id": 477236,
          "calendar_id": 5370,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": true,
          "is_last_day": false
        }],
        "5371": [{
          "leave_type_id": 477236,
          "calendar_id": 5371,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5372": [{
          "leave_type_id": 477236,
          "calendar_id": 5372,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5373": [{
          "leave_type_id": 477236,
          "calendar_id": 5373,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5374": [{
          "leave_type_id": 477236,
          "calendar_id": 5374,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5375": [{
          "leave_type_id": 477236,
          "calendar_id": 5375,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5376": [{
          "leave_type_id": 477236,
          "calendar_id": 5376,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5377": [{
          "leave_type_id": 477236,
          "calendar_id": 5377,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5378": [{
          "leave_type_id": 477236,
          "calendar_id": 5378,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5379": [{
          "leave_type_id": 477236,
          "calendar_id": 5379,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": false
        }],
        "5380": [{
          "leave_type_id": 477236,
          "calendar_id": 5380,
          "calendar_event_id": 36016427,
          "company_user_id": 2788494,
          "company_user_full_name": "leoafshal",
          "replacement_id": null,
          "type": "leave_event",
          "status": "awaiting_approval",
          "day_part": "all_day",
          "day_part_order": 0,
          "hours": "0",
          "time_from": "00:00",
          "time_to": "00:00",
          "details": "",
          "replacement": null,
          "policy_name": "Holiday",
          "policy_color": "#3a7dd8",
          "hide_name": false,
          "is_multi_day": true,
          "is_first_day": false,
          "is_last_day": true
        }]
      },
      "replacing_events": {},
      "unpaid_absences": []
    }
  }, {
    "height": 36,
    "id": "break368560",
    "team_id": 368560,
    "type": "break"
  }
  ],
  "days": [{
    "id": 5362,
    "initial": "Mo",
    "day_of_week_short": "Mon",
    "date": "05/09/2022",
    "date_iso": "2022-09-05",
    "day_of_month": "05",
    "weekend": false
  }, {
    "id": 5363,
    "initial": "Tu",
    "day_of_week_short": "Tue",
    "date": "06/09/2022",
    "date_iso": "2022-09-06",
    "day_of_month": "06",
    "weekend": false
  }, {
    "id": 5364,
    "initial": "We",
    "day_of_week_short": "Wed",
    "date": "07/09/2022",
    "date_iso": "2022-09-07",
    "day_of_month": "07",
    "weekend": false
  }, {
    "id": 5365,
    "initial": "Th",
    "day_of_week_short": "Thu",
    "date": "08/09/2022",
    "date_iso": "2022-09-08",
    "day_of_month": "08",
    "weekend": false
  }, {
    "id": 5366,
    "initial": "Fr",
    "day_of_week_short": "Fri",
    "date": "09/09/2022",
    "date_iso": "2022-09-09",
    "day_of_month": "09",
    "weekend": false
  }, {
    "id": 5367,
    "initial": "Sa",
    "day_of_week_short": "Sat",
    "date": "10/09/2022",
    "date_iso": "2022-09-10",
    "day_of_month": "10",
    "weekend": true
  }, {
    "id": 5368,
    "initial": "Su",
    "day_of_week_short": "Sun",
    "date": "11/09/2022",
    "date_iso": "2022-09-11",
    "day_of_month": "11",
    "weekend": true
  }, {
    "id": 5369,
    "initial": "Mo",
    "day_of_week_short": "Mon",
    "date": "12/09/2022",
    "date_iso": "2022-09-12",
    "day_of_month": "12",
    "weekend": false
  }, {
    "id": 5370,
    "initial": "Tu",
    "day_of_week_short": "Tue",
    "date": "13/09/2022",
    "date_iso": "2022-09-13",
    "day_of_month": "13",
    "weekend": false
  }, {
    "id": 5371,
    "initial": "We",
    "day_of_week_short": "Wed",
    "date": "14/09/2022",
    "date_iso": "2022-09-14",
    "day_of_month": "14",
    "weekend": false
  }, {
    "id": 5372,
    "initial": "Th",
    "day_of_week_short": "Thu",
    "date": "15/09/2022",
    "date_iso": "2022-09-15",
    "day_of_month": "15",
    "weekend": false
  }, {
    "id": 5373,
    "initial": "Fr",
    "day_of_week_short": "Fri",
    "date": "16/09/2022",
    "date_iso": "2022-09-16",
    "day_of_month": "16",
    "weekend": false
  }, {
    "id": 5374,
    "initial": "Sa",
    "day_of_week_short": "Sat",
    "date": "17/09/2022",
    "date_iso": "2022-09-17",
    "day_of_month": "17",
    "weekend": true
  }, {
    "id": 5375,
    "initial": "Su",
    "day_of_week_short": "Sun",
    "date": "18/09/2022",
    "date_iso": "2022-09-18",
    "day_of_month": "18",
    "weekend": true
  }, {
    "id": 5376,
    "initial": "Mo",
    "day_of_week_short": "Mon",
    "date": "19/09/2022",
    "date_iso": "2022-09-19",
    "day_of_month": "19",
    "weekend": false
  }, {
    "id": 5377,
    "initial": "Tu",
    "day_of_week_short": "Tue",
    "date": "20/09/2022",
    "date_iso": "2022-09-20",
    "day_of_month": "20",
    "weekend": false
  }, {
    "id": 5378,
    "initial": "We",
    "day_of_week_short": "Wed",
    "date": "21/09/2022",
    "date_iso": "2022-09-21",
    "day_of_month": "21",
    "weekend": false
  }, {
    "id": 5379,
    "initial": "Th",
    "day_of_week_short": "Thu",
    "date": "22/09/2022",
    "date_iso": "2022-09-22",
    "day_of_month": "22",
    "weekend": false
  }, {
    "id": 5380,
    "initial": "Fr",
    "day_of_week_short": "Fri",
    "date": "23/09/2022",
    "date_iso": "2022-09-23",
    "day_of_month": "23",
    "weekend": false
  }, {
    "id": 5381,
    "initial": "Sa",
    "day_of_week_short": "Sat",
    "date": "24/09/2022",
    "date_iso": "2022-09-24",
    "day_of_month": "24",
    "weekend": true
  }, {
    "id": 5382,
    "initial": "Su",
    "day_of_week_short": "Sun",
    "date": "25/09/2022",
    "date_iso": "2022-09-25",
    "day_of_month": "25",
    "weekend": true
  }, {
    "id": 5383,
    "initial": "Mo",
    "day_of_week_short": "Mon",
    "date": "26/09/2022",
    "date_iso": "2022-09-26",
    "day_of_month": "26",
    "weekend": false
  }, {
    "id": 5384,
    "initial": "Tu",
    "day_of_week_short": "Tue",
    "date": "27/09/2022",
    "date_iso": "2022-09-27",
    "day_of_month": "27",
    "weekend": false
  }, {
    "id": 5385,
    "initial": "We",
    "day_of_week_short": "Wed",
    "date": "28/09/2022",
    "date_iso": "2022-09-28",
    "day_of_month": "28",
    "weekend": false
  }, {
    "id": 5386,
    "initial": "Th",
    "day_of_week_short": "Thu",
    "date": "29/09/2022",
    "date_iso": "2022-09-29",
    "day_of_month": "29",
    "weekend": false
  }, {
    "id": 5387,
    "initial": "Fr",
    "day_of_week_short": "Fri",
    "date": "30/09/2022",
    "date_iso": "2022-09-30",
    "day_of_month": "30",
    "weekend": false
  }, {
    "id": 5388,
    "initial": "Sa",
    "day_of_week_short": "Sat",
    "date": "01/10/2022",
    "date_iso": "2022-10-01",
    "day_of_month": "01",
    "weekend": true
  }, {
    "id": 5389,
    "initial": "Su",
    "day_of_week_short": "Sun",
    "date": "02/10/2022",
    "date_iso": "2022-10-02",
    "day_of_month": "02",
    "weekend": true
  }, {
    "id": 5390,
    "initial": "Mo",
    "day_of_week_short": "Mon",
    "date": "03/10/2022",
    "date_iso": "2022-10-03",
    "day_of_month": "03",
    "weekend": false
  }, {
    "id": 5391,
    "initial": "Tu",
    "day_of_week_short": "Tue",
    "date": "04/10/2022",
    "date_iso": "2022-10-04",
    "day_of_month": "04",
    "weekend": false
  }, {
    "id": 5392,
    "initial": "We",
    "day_of_week_short": "Wed",
    "date": "05/10/2022",
    "date_iso": "2022-10-05",
    "day_of_month": "05",
    "weekend": false
  }],
  "leave_types": [{
    "id": 477236,
    "color_template": "#3a7dd8",
    "name": "Holiday",
    "legend": true
  }, {
    "id": 477237,
    "color_template": "#d5761b",
    "name": "Sickday",
    "legend": true
  }, {
    "id": 477238,
    "color_template": "#3a7dd8",
    "name": "Holiday - UK",
    "legend": true
  }, {
    "id": 477239,
    "color_template": "#AC0B0B",
    "name": "Holiday - USA",
    "legend": true
  }, {
    "id": 477240,
    "color_template": "#26AFE7",
    "name": "Holiday - India",
    "legend": true
  }, {
    "id": 477241,
    "color_template": "#1A9211",
    "name": "Holiday - NL",
    "legend": true
  }, {
    "id": 477242,
    "color_template": "#d2a657",
    "name": "Business trip",
    "legend": true
  }, {
    "id": 477243,
    "color_template": "#d5761b",
    "name": "Sickday",
    "legend": true
  }, {
    "id": 477244,
    "color_template": "#931df0",
    "name": "Conference",
    "legend": true
  }, {
    "id": 477245,
    "color_template": "#98231B",
    "name": "Work from home (UK)",
    "legend": true
  }],
  "custom_events": {
    "calendar_ids": [],
    "events": [],
    "holidays_visible": true,
    "has_blackout": false
  },
  "filters": {
    "selected": "next_four_weeks",
    "previous": "2022-08-01",
    "next": "2022-10-01",
    "options": [{
      "id": "2022-03-01",
      "text": "March 2022"
    }, {
      "id": "2022-04-01",
      "text": "April 2022"
    }, {
      "id": "2022-05-01",
      "text": "May 2022"
    }, {
      "id": "2022-06-01",
      "text": "June 2022"
    }, {
      "id": "2022-07-01",
      "text": "July 2022"
    }, {
      "id": "2022-08-01",
      "text": "August 2022"
    }, {
      "id": "2022-09-01",
      "text": "September 2022"
    }, {
      "id": "next_four_weeks",
      "text": "Next 4 weeks"
    }, {
      "id": "2022-10-01",
      "text": "October 2022"
    }, {
      "id": "2022-11-01",
      "text": "November 2022"
    }, {
      "id": "2022-12-01",
      "text": "December 2022"
    }, {
      "id": "2023-01-01",
      "text": "January 2023"
    }, {
      "id": "2023-02-01",
      "text": "February 2023"
    }]
  },
  "visible_types": ["leave_event", "birthday", "anniversary"],
  "training_events": [],
  "trainings": []
}