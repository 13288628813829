var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Team manager permissions ")]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Employees in this group have access to their team member information. You can assign managers in TEAMS section. ")]), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('b-form-group', {
    staticClass: "HrmRadio-container w-fit-content"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Team managers can see all company employees in the shared calendar and directory. ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" Team managers can only see employees from their own team(s) ")])], 1)], 1), _c('div', {
    staticClass: "grayCard flexColumn mt-32px"
  }, [_c('div', {
    staticClass: "grayCard-heading"
  }, [_vm._v("Time off requests for employees in manager teams")]), _c('div', {
    staticClass: "grayCard-description mt-16px"
  }, [_vm._v(" Team manager can add & edit time off requests for their employees: ")]), _c('b-form-group', {
    staticClass: "HrmRadio-container w-fit-content mt-16px"
  }, [_c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" For any date, including past dates ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" For any date starting from the current month ")]), _c('b-form-radio', {
    staticClass: "HrmRadio"
  }, [_vm._v(" For any date in the future ")])], 1)], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }