import { 
  GET_LOCAL_SETTING_ANALYTICS,
  GET_LOCAL_SETTING_CALLS, 
  GET_LOCAL_SETTING_CHAT, 
  GET_LOCAL_SETTING_DASHBOARD, 
  GET_LOCAL_SETTING_MOBILE_SETTINGS, 
  GET_LOCAL_SETTING_NUMBERS, 
  GET_LOCAL_SETTING_SETTINGS, 
  GET_LOCAL_SETTING_THEME,  
  GET_LOCAL_SETTING_IS_DARK_MODE,
} from "../helper/getters";
import { 
  SET_LOCAL_SETTING_ANALYTICS_TAB,
  SET_LOCAL_SETTING_CALLS_FEEDBACK,
  SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW,
  SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW,
  SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD,
  SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE,
  SET_LOCAL_SETTING_DASHBOARD_DESIGN_CALL_INFO_WIDTH,
  SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP,
  SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP,
  SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW,
  SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS,
  SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH,
  SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB,
  SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE,
  SET_LOCAL_SETTING_SETTINGS_TAB,
  SET_LOCAL_SETTING_THEME_MODE,
  SET_LOCAL_SETTING_THEME_SYSTEM_MODE, 
} from "../helper/mutations";

export default {
  state: {
    theme: {
      mode: 'colored3',
      system_mode: '',
    },
    settings: {
      tab: 'Numbers',
    },
    chat: {
      appearence: {
        is_icon_show: 'accepted',
        list_view: 'not_accepted',
        blink_unread: 'not_accepted',
      },
      view: {
        recent_type: 'mixed',
      },
    },
    numbers: {
      formating: {
        international_formate: false,
      },
    },
    calls: {
      feedback: true,
    },
    analytics: {
      tab: 'Summary',
    },
    dashboard: {
      design: {
        width: 50,
        flip: false,
        call_info_width: 50,
        scroll_top: false,
        tags_show: true,
        transfer_log: true,
      },
    },
    mobile_settings: {
      tab: '',
    },
  },
  getters: {
    [GET_LOCAL_SETTING_THEME](state){ return state.theme },
    [GET_LOCAL_SETTING_ANALYTICS](state){ return state.analytics },
    [GET_LOCAL_SETTING_CALLS](state){ return state.calls },
    [GET_LOCAL_SETTING_CHAT](state){ return state.chat },
    [GET_LOCAL_SETTING_DASHBOARD](state){ return state.dashboard },
    [GET_LOCAL_SETTING_MOBILE_SETTINGS](state){ return state.mobile_settings },
    [GET_LOCAL_SETTING_NUMBERS](state){ return state.numbers },
    [GET_LOCAL_SETTING_SETTINGS](state){ return state.settings },
    [GET_LOCAL_SETTING_IS_DARK_MODE](state,getters,rootState){ 
      return !rootState.global_conditions.is_mobile_setting && (state.theme.mode!='system' ? state.theme.mode=='dark' : state.theme.system_mode=='dark') 
    },
  },
  actions: {
    
  },
  mutations: {
    [SET_LOCAL_SETTING_THEME_MODE](state,data){ state.theme.mode = data ?? 'colored3' },
    [SET_LOCAL_SETTING_THEME_SYSTEM_MODE](state,data){ state.theme.system_mode = data ?? '' },
    [SET_LOCAL_SETTING_ANALYTICS_TAB](state,data){ state.analytics.tab = data ?? 'Summary' },
    [SET_LOCAL_SETTING_CALLS_FEEDBACK](state,data){ state.calls.feedback = data ?? true },
    [SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW](state,data){ state.chat.appearence.is_icon_show = data ?? 'accepted' },
    [SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW](state,data){ state.chat.appearence.list_view = data ?? 'not_accepted' },
    [SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD](state,data){ state.chat.appearence.blink_unread = data ?? 'not_accepted' },
    [SET_LOCAL_SETTING_CHAT_VIEW_RECENT_TYPE](state,data){ state.chat.view.recent_type = data ?? 'mixed' },
    [SET_LOCAL_SETTING_DASHBOARD_DESIGN_CALL_INFO_WIDTH](state,data){ state.dashboard.design.call_info_width = data ?? 50 },
    [SET_LOCAL_SETTING_DASHBOARD_DESIGN_FLIP](state,data){ state.dashboard.design.flip = data ?? false },
    [SET_LOCAL_SETTING_DASHBOARD_DESIGN_SCROLL_TOP](state,data){ state.dashboard.design.scroll_top = data ?? false },
    [SET_LOCAL_SETTING_DASHBOARD_DESIGN_TAGS_SHOW](state,data){ state.dashboard.design.tags_show = data ?? true },
    [SET_LOCAL_SETTING_DASHBOARD_DESIGN_TRANSFER_LOGS](state,data){ state.dashboard.design.transfer_log = data ?? true },
    [SET_LOCAL_SETTING_DASHBOARD_DESIGN_WIDTH](state,data){ state.dashboard.design.width = data ?? 50 },
    [SET_LOCAL_SETTING_MOBILE_SETTINGS_TAB](state,data){ state.mobile_settings.tab = data ?? '' },
    [SET_LOCAL_SETTING_NUMBERS_FORMATING_INTERNATIONAL_FORMATE](state,data){ state.numbers.formating.international_formate = data ?? false },
    [SET_LOCAL_SETTING_SETTINGS_TAB](state,data){ state.settings.tab = data ?? 'Numbers' },
  },
};