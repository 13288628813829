<template>
  <div class="d-flex">
    <b v-if="not_show_on_empty && !info.name">
      {{ name }}
    </b>
    <b v-else-if="only_name">{{ info.name || name || "" }}</b>
    <template v-else>
      <vb-avatar 
        v-if="is_image_show"
        class="sm-mar-right mr-3"
        :image="info.image || image || ''"
        :name="info.name || name || ''"
        :status_id="info.status_id"
        :is_blf="is_blf"
        :id="id"
      />
      <b-popover v-if="!hide_popover"
        custom-class="thisDeviceCustomPopOver autoWidth" 
        :target="`${_uid}-popover`" triggers="hover"
        placement="topright"
      >
        <div class="d-flex flex-column">
          <span class="mb-2">{{info.sub_info || sub_info || ''}} {{username}} </span> 
          <!-- <div v-if="devices && info.devices" class="d-flex align-items-center">
            <vb-icon 
              v-for="(device, accountcode) in info.devices" 
              :key="accountcode" 
              :class="`mr-12px statusWithRespectToDevice`" 
              :icon="device.type | accountType" 
              width="20.219px" 
              height="24.997px" 
            />
          </div> -->
        </div>
      </b-popover>
      <div class="user-info d-flex align-items-center">
        <div class="user-name-box">
          <!-- v-b-popover.hover.top="{
            content: `${info.sub_info || sub_info || ''} ${username}`,
            disabled: hide_popover
          }" -->
          <span :id="`${_uid}-popover`" class="user-name"> {{ info.name || name || "" }} </span>
          <!-- <div :id="`${_uid}-popover`">test</div> -->
          <div v-if="is_suspended_show && (suspended || info.suspended)" class="d-flex align-items-center">
            <!-- <div class="user-number h-fit-content mr-2 w-auto">{{ info.sub_info || sub_info || "" }}</div> -->
            <div v-if="is_suspended_show && (suspended || info.suspended)" class="suspendedTag">Suspended</div>
          </div>
          <div v-if="sub_text" class="user-number">{{ sub_text || "" }}</div>
          <div v-if="devices && info.devices" class="d-flex align-items-center">
            <vb-icon 
              v-for="(device, accountcode) in info.devices" 
              :key="accountcode" 
              :class="`mr-12px statusWithRespectToDevice`" 
              :icon="device.type | accountType" 
              width="20.219px" 
              height="24.997px" 
            />
          </div>
          <slot name="sub-info"></slot>
        </div>
      </div>
      <div v-if="is_unread_show && info.unread" class="unreadMessageInRightSideModuleInUsersTab" v-b-popover.hover.top="'Unread Messages'">{{ info.unread || "" }}</div>
      <div v-if="(info.type || extension_type) && type_show" class="ml-1 accountTypeInfo my-auto font-12">
        (<b>{{ info.type || extension_type || '' }}</b>)
      </div>
    </template>
  </div>
</template>

<script>
// import { $fn, } from "../../utils";
import _ from 'lodash';
import { mapGetters, mapState } from 'vuex';
import { flag_icon, number_formater } from '@/filter';
export default {
  name: "Info",
  props: {
    id: {
      type: [String,Number],
      default: "",
    },
    image: {
      type: String,
      default: "",
    },
    name: {
      type: String,
      default: "",
    },
    sub_info: {
      type: String,
      default: "",
    },
    sub_text: {
      type: String,
      default: "",
    },
    is_blf: {
      type: Boolean,
      default: true,
    },
    is_image_show: {
      type: Boolean,
      default: true,
    },
    is_suspended_show: {
      type: Boolean,
      default: true,
    },
    is_unread_show: {
      type: Boolean,
      default: false,
    },
    suspended: {
      type: Boolean,
      default: false,
    },
    only_name: {
      type: Boolean,
      default: false,
    },
    type_show: {
      type: Boolean,
      default: false,
    },
    not_show_on_empty: {
      type: Boolean,
      default: false,
    },
    extension_type: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'team,user,call_queue,ivr,number',
    },
    username: {
      type: String,
      default: '',
    },
    hide_popover: {
      type: Boolean,
      default: false,
    },
    devices: {
      type: Boolean,
      default: false,
    },
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
      'getVoipUserAccountcode',
      'getVoipTeams',
      'getVoipCallQueuesAlises',
      'getVoipIVRSAlises',
      'getVoipNumbers',
      'addServiceQueue'
    ]),
    ...mapState({
      mattermost_channels: state=>state.mattermost.channels
    }),
    info() {
      const key = this.id;
      if (!key) return {};
      if(this.addServiceQueue && this.addServiceQueue.id==key){
        return {
          name: this.addServiceQueue?.name ?? '',
          sub_info: '',
          image: '',
          unread: 0,
          suspended: false,
        }
      }
      const user = this.getVoipUsersAlises[key] || this.getVoipUsersAlises[this.getVoipUserAccountcode[key]];
      const team = this.getVoipTeams.find(team=>key==team?.ringroup?.accountcode || key==team?.real_id || key==team?.ringroup?.extn)
      const call_queue = this.getVoipCallQueuesAlises[key]
      const number = this.getVoipNumbers.find(number=>key==number?.real_id || (key?.replace?.(/[^0-9]/g,'') ? key?.replace?.(/[^0-9]/g,'')==number?.did?.replace?.(/[^0-9]/g,'') : false))
      const ivr = this.getVoipIVRSAlises[key]
      if (!_.isEmpty(team) && (this.type.includes('team') || this.type.includes('TEAM'))) {
        return {
          type: 'TEAM',
          name: team?.name ?? '',
          sub_info: team?.ringroup?.extn ?? '',
          image: team?.avatar?.image?.image ?? '',
          unread: this.mattermost_channels?.[team.channel_id]?.unread ?? 0,
          // suspended: team?.status == 'suspended',
          suspended: team?.status !='active',
          
        };
      } else if (!_.isEmpty(call_queue) && (this.type.includes('call_queue') || this.type.includes('QUEUE'))) {
        return {
          type: 'QUEUE',
          name: call_queue?.label ?? '',
          sub_info: call_queue?.extn ?? '',
          image: call_queue?.avatar?.image?.image ?? '',
          suspended: call_queue?.status=='0',
        };
      } else if (!_.isEmpty(ivr) && (this.type.includes('ivr') || this.type.includes('BASICIVR') || this.type.includes('IVR'))) {
        return {
          type: 'BASICIVR',
          name: ivr?.label ?? '',
          sub_info: ivr?.extn ?? '',
          image: ivr?.avatar?.image?.image ?? '',
          suspended: ivr?.status == '0',
        };
      } else if (!_.isEmpty(number) && this.type.includes('number')) {
        return {
          type: 'NUMBER',
          name: number_formater(number?.number ?? ''),
          sub_info: number?.label ?? '',
          image: flag_icon(number?.number ?? ''),
          suspended: number?.status==0,
        };
      } else if (!_.isEmpty(user) && (this.type.includes('user') || this.type.includes('USER'))) {
        return {
          type: 'USER',
          name: user?.user?.display_name ?? '',
          sub_info: user.extn,
          image: user.profile_img,
          status_id: user.ami_status=='inactive' ? 'offline' : user.idle_state?.state=='idle' ? 'idle' : user.ami_status=='active' && user.status ? user.status.id || user.status : '',
          // status_id: user.ami_status=='active' && user.status ? user.status.id || user.status : '',
          unread: this.mattermost_channels?.[user.channel_id]?.unread ?? 0,
          suspended: user?.active_status=='0',
          devices: user?.devices ?? null,
          idle_state: user?.idle_state ?? null,
        };
      }
      return {};
    },
    allIcons(){
      let arr = [
        {icon: 'WEB' , state: 'active'},
        {icon: 'USER' , state: 'active'},
        {icon: 'DESKTOPEXT' , state: 'active'},
        {icon: 'MOBILEEXT' , state: 'active'},
        {icon: 'HEADEXT' , state: 'active'},
      ]
      return arr
    }
  },
  filters: {
    accountType(val) {
      if(val=='WEBEXT') return 'devices-webExtension-icon'
      else if(val === "USER") return "monitorDashboard-table-user";
      else if(val=='DESKTOPEXT') return 'devices-desktopExtension-icon'
      else if(val=='MOBILEEXT') return 'devices-mobileExtension-icon'
      else if(val=='HEADEXT') return 'devices-headExtension-icon'
      else if(val=='INTEGRATIONEXT') return 'devices-headExtension-icon'
      else if(val=='EXTERNALEXT') return 'devices-headExtension-icon'
      else return 'devices-webExtension-icon'
    },
  },
};
</script>

<style lang="scss" scoped>
.user-info {
  .user-name-box {
    display: flex;
    align-items: flex-start;
    justify-content: center;
    flex-direction: column;
    .user-name {
      font-size: 14px;
      font-weight: 700;
      color: $dialer-addRemoveModal-userNameColor;
      text-transform: capitalize;
    }
    .user-number {
      color: $dialer-addRemoveModal-userExtensionColor;
      font-weight: 500;
    }
  }
}
</style>