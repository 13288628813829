<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup tagStatus-popup dialer-setting-call-tag" 
      width="50%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()" 
    >
      <div class="dialer-assign-to md-pad-bottom dialer-edit">
        <div class="dialer-edit-header d-block">
          <div class="d-flex justify-content-between align-items-center">
            <h2 class="dialer-edit-title d-flex align-items-center">
              <!-- <vb-svg @click="!!conditions.updated_id ? conditions.updated_id = '' : $modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" /> -->
              <button class="newBackButton mr-16px" @click="!!conditions.updated_id ? conditions.updated_id = '' : $modal.hide(modalName)">
                <b-icon icon="arrow-left-circle" />
                <span class="newBackButton-textpart">Back</span>
              </button>
              Tags
            </h2>
            <div 
              v-if="!conditions.updated_id"
              v-b-tooltip :title="getUserPermissions.call_tags?'':'Your package does not support this feature'"
              :class="!getUserPermissions.call_tags ? 'for-disable' : ''"
            >
              <button :disabled="!getUserPermissions.call_tags" @click="$modal.show('AddTag')" class="newButton" variant="success" >
                <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                <span class="newButton-textPart">Add tag</span>
              </button>
            </div>
          </div>
          <div class="vm--modal-text">
            Call tagging helps organise and analyse call data, enabling easy reporting and customer identification.
          </div>
          <!-- <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            <a v-else class="newCloseButton" type="button" @click="$modal.hide(modalName)" >
              <vb-icon icon="squared-close-icon" height="38px" width="38px" />
              <span class="newCloseButton-textPart">Close</span>
            </a>
          </div> -->
        </div>
        <div class="dialer-tabs">
          <div v-if="!!conditions.updated_id" class="dialer-tab-content m-0">
            <h2 class="dialer-edit-title">
              <b-icon icon="x" @click="conditions.updated_id = ''" />
              Update tag
            </h2>
            <b-form @submit.prevent="update()" class="dialer-form">
              <div class="dialer-input-group-2 align-items-start">
                <div class="dialer-input-field input-consistent-inner-shadows mx-2">
                  <label>Tag name</label>
                  <b-input class="bg-white" type="text" v-model="forms.update.tag" :disabled="api.update.send" placeholder="Enter name" />
                  <p v-if="forms.update.submitted && $v.forms.update.tag.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.update.tag.required">* Name is required</span>
                  </p>
                </div>
                <div class=" dialer-input-field input-consistent-inner-shadows color-picker-input colorPicker-section mx-2">
                  <div class="dialer-input-field">
                    <label>Pick a color</label>
                    <input type="text" placeholder="Enter color" />
                  </div>
                  <span class="color-circle">
                    <input class="color-swatch" type="color" :disabled="api.update.send" v-model="forms.update.color" />
                  </span>
                  <p v-if="forms.update.submitted && $v.forms.update.color.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.update.color.required">* Color is required</span>
                    <span v-else-if="!$v.forms.update.color.validColor">* Color is invalid</span>
                  </p>
                </div>
                <vb-color-picker 
                  class="dialer-input-field input-consistent-inner-shadows color-picker-input mx-2" 
                  title="Text Color" 
                  :disabled="api.update.send" 
                  :value="forms.update.text_color" 
                  @change="forms.update.text_color = $event"
                >
                  <p slot="error" v-if="forms.update.submitted && $v.forms.update.text_color.$invalid" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span v-if="!$v.forms.update.text_color.required">* Text color is required</span>
                    <span v-else-if="!$v.forms.update.text_color.validColor">* Text color is invalid</span>
                  </p>
                </vb-color-picker>

              </div>
              <div class="d-flex justify-content-end">
                <button class="dialer-button dialer-button-primary" variant="success" :disabled="api.update.send">
                  <vb-spinner v-if="api.update.send" />
                  <template v-else>update</template>
                </button>
              </div>
            </b-form>
          </div>
          <template v-else>
            <vb-table class="latestTableDesign-withBlackBorders-again"
              :isListEmpty="response.tags.length==0"
              :listLength="response.tags.length"
              :perPage="5"
              :loading="api.list.send"
              :conditions="{
                pagination: {
                  last: true,
                  first: true,
                  show: true,
                },
                is_no_record: {
                  show: true,
                },
                loading: {
                  show: true,
                },
              }"
            >
              <!-- design="grayTable" -->
              <tr slot="header" v-if="!$store.getters.getIsMobile">
                <th class="dialer-has-sort">
                  <span>Name</span>
                </th>
                <th class="dialer-has-sort">
                  <!-- <span>Background Color</span> -->
                  <span>Tag color</span>
                </th>
                <!-- <th class="dialer-has-sort">
                  <span>Text Color</span>
                </th> -->
                <th class="dialer-has-sort">
                  <span>Sample tag</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Hide</span>
                </th>
                <th class="dialer-has-sort dialer-col-center">
                  <span></span>
                </th>
              </tr>
              <template #body="{ start, end }">
                <template v-if="$store.getters.getIsMobile">
                  <tr v-for="(tag, index) in response.tags" :key="tag.id" v-show="index >= start && index <= end">
                    <td class="left">
                      <div class="d-flex align-items-center">
                        <div v-vb-tag-color="tag.colour" class="mr-8px" :style="`border-radius:50%;height:25px;width:25px;min-height:25px;min-width:25px;`" />
                        <div>
                          <div class="headingInsideTable">{{ tag.tag }}</div>
                          <span v-if="tag.accountcode!='0'" class="OwnertaggedVersion mb-0">Custom</span>
                          <div 
                            v-vb-tag-color.text.bg="{
                              color:tag.colour,
                              text_color:tag.text_color
                            }" 
                            :style="`border-radius:3px;height:auto;width:fit-content;padding:2px 6px;font-size: 8px;font-weight: 600;line-height: 1.2;text-align: center;vertical-align: initial;white-space: nowrap;`" >
                            {{tag.tag}}
                          </div>
                        </div>
                      </div>
                    </td>
                    <td class="dialer-col-right">
                      <div class="d-flex justify-content-end align-items-center">
                        <div class="switchWidth"
                          v-b-tooltip :title="getUserPermissions.call_tags?'':'Your package does not support this feature'"
                        >
                          <div v-if="api.toggle_hide.send==tag.id" class="d-flex align-items-center w-fit-content">
                            <div class="latestShimmerDesign" style="height:21px;width:50px;"></div>
                          </div>
                          <b-checkbox class="newerSwitch"
                            v-else
                            :disabled="!getUserPermissions.call_tags || !!api.toggle_hide.send"
                            :checked="tag.deleted_tags==1 ? true : false"
                            @change="toggleHide(tag)"
                            switch
                          />
                        </div>
                        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
                          <div
                            v-if="tag.accountcode!='0'"
                            v-b-tooltip :title="getUserPermissions.call_tags?'':'Your package does not support this feature'"
                          >
                            <b-dropdown right :disabled="!getUserPermissions.call_tags">
                              <template #button-content>
                                <b-icon icon="three-dots-vertical" scale="1.5"></b-icon>
                              </template>
                              <b-dropdown-item v-if="api.remove.send==tag.id"></b-dropdown-item>
                              <b-dropdown-item v-else :disabled="!!api.remove.send" @click="remove(tag)">Delete</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
                <template v-else>
                  <tr v-for="(tag, index) in response.tags" :key="tag.id" v-show="index >= start && index <= end">
                    <td class="dialer-row-title">
                      {{ tag.tag }}
                      <span v-if="tag.accountcode!='0'" class="OwnertaggedVersion mb-0 ml-16px">Custom</span>
                    </td>
                    <td class="dialer-row-title">
                      <div class="md-mar-left" v-vb-tag-color="tag.colour" :style="`border-radius:50%;height:16px;width:16px;`" />
                    </td>
                    <!-- <td class="dialer-row-title">
                      <div class="md-mar-left" v-vb-tag-color:black="tag.text_color" :style="`border-radius:50%;height:16px;width:16px;`" />
                    </td> -->
                    <td class="dialer-row-title">
                      <div 
                        class="md-mar-left" 
                        v-vb-tag-color.text.bg="{
                          color:tag.colour,
                          text_color:tag.text_color
                        }" 
                        :style="`border-radius:4px;height:auto;width:fit-content;padding:0.2rem 0.4rem;font-size: 10.5px;font-weight: 500;line-height: 1.3;text-align: center;vertical-align: initial;white-space: nowrap;`" >
                        {{tag.tag}}
                      </div>
                    </td>
                    <td class="dialer-row-title">
                      <div class="switchWidth"
                        v-b-tooltip :title="getUserPermissions.call_tags?'':'Your package does not support this feature'"
                      >
                        <div v-if="api.toggle_hide.send==tag.id" class="d-flex align-items-center w-fit-content">
                          <div class="latestShimmerDesign" style="height:21px;width:50px;"></div>
                        </div>
                        <b-checkbox class="newerSwitch"
                          v-else
                          :disabled="!getUserPermissions.call_tags || !!api.toggle_hide.send"
                          :checked="tag.deleted_tags==1 ? true : false"
                          @change="toggleHide(tag)"
                          switch
                        />
                      </div>
                    </td>
                    <td class="dialer-col-right">
                      <div class="d-flex justify-content-end">
                        <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD">
                          <div
                            v-if="tag.accountcode!='0'"
                            v-b-tooltip :title="getUserPermissions.call_tags?'':'Your package does not support this feature'"
                          >
                            <b-dropdown right :disabled="!getUserPermissions.call_tags">
                              <template #button-content>
                                <b-icon icon="three-dots" scale="1.5"></b-icon>
                              </template>
                              <b-dropdown-item v-if="api.remove.send==tag.id"></b-dropdown-item>
                              <b-dropdown-item v-else :disabled="!!api.remove.send" @click="remove(tag)">Delete</b-dropdown-item>
                            </b-dropdown>
                          </div>
                        </div>
                      </div>
                    </td>
                  </tr>
                </template>
              </template>
              <template #loading>
                <tr v-for="n in 5" :key="n">
                  <td>
                    <div class="d-flex align-items-center w-fit-content">
                      <div class="latestShimmerDesign" style="height:21px;width:80px;"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center w-fit-content">
                      <div class="md-mar-left latestShimmerDesign" style="height:21px;width:21px;"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center w-fit-content">
                      <div class="latestShimmerDesign md-mar-left" style="height:21px;width:21px;"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center w-fit-content">
                      <div class="latestShimmerDesign md-mar-left" style="height:21px;width:50px;"></div>
                    </div>
                  </td>
                  <td>
                    <div class="d-flex align-items-center justify-sontent-end w-fit-content ml-auto">
                      <div class="latestShimmerDesign" style="height:16px;width:35px;"></div>
                    </div>
                  </td>
                </tr>
              </template>
            </vb-table>
          </template>
        </div>
      </div>
    </modal>
    <AddTagModal @created="load()" />
  </div>
</template>

<script>
import { VOIP_API } from "../../../utils";
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import validateColor from "validate-color";
import AddTagModal from './AddTagModal.vue';

export default {
  name: "TagsModal",
  components: { 
    AddTagModal 
  },
  inject: [
    'getCallTags',
    'isEmpty',
    'appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'TagsModal',
    },
  },
  data() {
    return {
      conditions: {
        create_color: false,
        updated_id: '',
      },
      forms: {
        create: this.$helperFunction.formInstance({
          data: {
            tag: "",
            color_combo_id: '',
            // color: "rgba(0,0,0,1)",
            // text_color: "rgba(0,0,0,1)",
          }
        }),
        update: this.$helperFunction.formInstance({
          data: {
            tag: "",
            color: "rgba(0,0,0,1)",
            text_color: "rgba(0,0,0,1)",
          }
        }),
      },
      api: {
        update: this.$helperFunction.apiInstance({  }),
        toggle_hide: this.$helperFunction.apiInstance({ send: '' }),
        remove: this.$helperFunction.apiInstance({ send: '' }),
        create: this.$helperFunction.apiInstance({  }),
        list: this.$helperFunction.apiInstance({  }),
        fetch_color_combination: this.$helperFunction.apiInstance({  }),
      },
      response: {
        color_combinations: [],
        tags: [],
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVoipCallTags',
      'getCurrentUser',
      'getIsMobile',
      'getUserPermissions',
    ]),
    colorComboOptions(){
      return this.response.color_combinations.map(i=>({
        text: i.label,
        value: i.id,
      }))
    },
    selectedColorCombo(){ return this.response.color_combinations.find(i=>i.id==this.forms.create.color_combo_id) },
  },
  validations: {
    forms: {
      create: {
        tag: {
          required,
        },
        color_combo_id: {
          required,
        },
        // color: {
        //   required,
        //   validColor: (value) => { return validateColor(value) },
        // },
        // text_color: {
        //   required,
        //   validColor: (value) => { return validateColor(value) },
        // },
      },
      update: {
        tag: {
          required,
        },
        color: {
          required,
          validColor: (value) => { return validateColor(value) },
        },
        text_color: {
          required,
          validColor: (value) => { return validateColor(value) },
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      this.load();
      this.colorCombinations()
    },
    onBeforeClose(){
      this.conditions.create_color=false
      this.conditions.updated_id=''
      this.forms.create.reset()
      this.forms.update.reset()
      this.api.update.reset()
      this.api.toggle_hide.reset()
      this.api.create.reset()
      this.api.list.reset()
      this.api.fetch_color_combination.reset()
      this.response.color_combinations=[]
      this.response.tags=[]
    },
    async create() {
      this.forms.create.submitted = true;
      this.$v.forms.create.$touch();
      if (this.$v.forms.create.$invalid || this.api.create.send) return;
      try {
        this.api.create.send = true;
        await VOIP_API.endpoints.tags.create({
          tag: this.forms.create.tag,
          color_combo_id: this.forms.create.color_combo_id,
          color: this.selectedColorCombo.color,
          colour: this.selectedColorCombo.color,
          text_color: this.selectedColorCombo.text_color,
          allow_call: this.forms.create.allow_call,
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
        })
        this.load()
        this.forms.create.reset();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.forms.create.submitted = false;
        this.api.create.send = false;
      }
    },
    async toggleHide(tag) {
      const id = tag.id
      if (!id || this.api.toggle_hide.send) return;
      try {
        this.api.toggle_hide.send = id;
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to ${tag.deleted_tags==1?'show':'hide'}.`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.tags.delete(id,{
          action: tag.deleted_tags==1 ? 'unhide' : 'hide'
        })
        this.appNotify({
          message: `Successfully ${tag.deleted_tags==1?'show':'hide'}`,
          type: 'success',
        })
        this.load()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message || '',
          type: 'danger',
        })
      } finally {
        this.api.toggle_hide.send = "";
      }
    },
    async remove(tag) {
      const id = tag.id
      if (!id || this.api.remove.send) return;
      try {
        this.api.remove.send = id;
        const { confirm } = await this.$appConfirmation({
          title: "Warning",
          message: `Are you sure you want to delete.`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        await VOIP_API.endpoints.tags.delete(id,{
          tag_type: 'custom_delete',
        })
        this.appNotify({
          message: `Successfully Deleted`,
          type: 'success',
        })
        this.load()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message || '',
          type: 'danger',
        })
      } finally {
        this.api.remove.send = "";
      }
    },
    async update() {
      this.forms.update.submitted = true;
      this.$v.forms.update.$touch();
      if (this.$v.forms.update.$invalid || this.api.update.send) return;
      try {
        this.api.update.send = true;
        await VOIP_API.endpoints.tags.update({
          id: this.conditions.updated_id,
          tag: this.forms.update.tag,
          color: this.forms.update.color,
          colour: this.forms.update.color,
          text_color: this.forms.update.text_color,
          allow_call: this.forms.update.allow_call,
        })
        this.load()
        this.conditions.updated_id = "";
        this.forms.update.reset();
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.forms.update.submitted = false;
        this.api.update.send = false;
      }
    },
    async load() {
      let vm = this;
      if (this.api.list.send) return;
      try {
        this.api.list.send = true;
        await this.getCallTags()
        const { data } = await VOIP_API.endpoints.tags.list({
          uid: this.getCurrentUser.uid,
          accountcode: this.getCurrentUser.account,
          type: 'all'
        })
        const tags = data?.data ?? []
        // console.log(tags)
        this.response.tags=tags
      } catch(ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        vm.api.list.send = false;
      }
    },
    setUpdateId(data) {
      if (this.api.remove.send == data.id) return;
      this.conditions.updated_id = data.id;
      this.forms.update.tag = data.tag;
      this.forms.update.color = data.colour;
      this.forms.update.text_color = data.colour;
    },
    async colorCombinations(){
      if(this.api.fetch_color_combination.send) return;
      try {
        this.api.fetch_color_combination.send=true
        const { data: color_combinations } = await VOIP_API.endpoints.tags.colorCombinations()
        this.response.color_combinations=color_combinations ?? []
      } finally {
        this.api.fetch_color_combination.send=false
      }
    },
  },
};
</script>

<style lang="scss" scoped>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}

.dialer-table {
  .dialer-col-center {
    span {
      .custom-control {
        padding-left: 0px;
      }
    }
  }
}
</style>
