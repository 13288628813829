var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500",
    attrs: {
      "width": "400px",
      "height": "auto",
      "scrollable": true,
      "name": "InvitePeopleModal"
    },
    on: {
      "closed": _vm.onClosed
    }
  }, [_c('div', {
    staticClass: "dialer-form"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Invite people")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('InvitePeopleModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1), _c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api_sent.invite
    },
    on: {
      "click": function ($event) {
        return _vm.invite();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.invite ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]), _c('div', {
    staticClass: "md-mar-vertical"
  }, [_c('b-alert', {
    staticClass: "alert-danger",
    attrs: {
      "dismissible": true,
      "fade": true,
      "show": !!_vm.error
    },
    on: {
      "dismissed": function ($event) {
        _vm.error = '';
      }
    }
  }, [_vm._v(_vm._s(_vm.error))]), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.invite.apply(null, arguments);
      }
    }
  }, [_c('b-form-group', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Email")]), _c('b-form-input', {
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api_sent.invite,
      "type": "email",
      "placeholder": "Enter Email"
    },
    model: {
      value: _vm.email,
      callback: function ($$v) {
        _vm.email = $$v;
      },
      expression: "email"
    }
  }), _vm.submitted && !_vm.$v.email.required ? [_c('p', [_vm._v("*Email is required")])] : _vm._e(), _vm.submitted && !_vm.$v.email.email ? [_c('p', [_vm._v("*Enter a valid Email")])] : _vm._e()], 2)], 1)], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }