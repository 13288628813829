<template>
  <div class="dialer-analytics-main dialer-analytics-teams">
    <template v-if="conditions.select_tab">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.select_tab = false" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Date</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
    </template>
    <div v-else class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$emit('back')">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
        <div class="settingsTextOnTop">Settings</div>
      </div>
      <div class="dialer-settings-title topMainHeading">Teams</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div class="dialer-box AnalyticsContainerInner"> 
      <template v-if="conditions.select_tab">
        
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item" v-for="(tab, index) in tabs" :key="index" 
            @click="(selected = tab.key), (tab_index = index), load();selected=='custom' ?  conditions.select_tab = true:conditions.select_tab = false"
          >
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <div class="headingInsideTable">{{ tab.value }}</div>
              </div>  
            </div>
            <vb-icon v-if="selected === tab.key" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
          </div>
        </div>
        <div v-if="selected=='custom'" class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2 forDateTime">
              <label>From</label>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="start_date" id="starttime" />
            </div>
          </div>
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2 forDateTime">
              <label>To</label>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class=" date-time-input" v-model="end_date" id="endtime" />
            </div>
          </div>
         
        </div>
        <a class="IosFullWidthButton forDone mb-16px" @click="load();conditions.select_tab = false">Fetch</a>
      </template>
      <template v-else>
        <template>
          <div class="mobileInputIOS iconOnLeft mt-32px">
            <b-input  type="text" placeholder="Search user" v-model="filter.search" />
            <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
          </div>
        </template>
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div class="basicWhiteIOScard-item" @click="conditions.select_tab=!conditions.select_tab">
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <div class="headingInsideTable">Date</div>
              </div>  
            </div>
            <div class="d-flex align-items-center">
              <div class="textInsideCard textAlignRight onRightSide mr-8px">{{ selectedTab.value }}</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
          </div>
        </div>
        <div class="IosTabsSection twoTabs mt-16px mb-16px">
          <div :class="{ 'active': !conditions.advanced }" class="IosTabButton" @click="conditions.advanced=false">Basic</div>
          <div :class="{ 'active': conditions.advanced }" class="IosTabButton" @click="conditions.advanced=true">Advance</div>
        </div>
        <div class="basicWhiteIOScard mt-16px mb-16px">
          <div :class="`basicWhiteIOScard-item ${conditions.advanced ? 'withAdvancedSection' : ''}`" v-for="time_line in filterTimeLine" :key="time_line.measure">
            <div class="w-100 d-flex align-items-center justify-content-between">
              <div class="d-flex align-items-center">
                <Info class="InfoComponentInsideAnroidIOSApp" :is_blf="false" :id="time_line.accountcode" :name="time_line.team.name" /> 
              </div>
              
              <div class="d-flex align-items-center">
                <div class="d-flex mr-6px">
                  <div class="textInsideCard onRightSide textAlignRight" style="color: #87868C;">{{ time_line | get_property('user.totalCalls',0) }}</div>
                  <div class="textInsideCard onRightSide textAlignRight mx-1">/</div>
                  <div class="textInsideCard onRightSide textAlignRight" style="color: #FF1900;">{{ time_line | get_property('user.totalMissCalls',0) }}</div>
                </div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
              </div>
            </div>
            <div class="advanceSection" v-if="conditions.advanced">
              
              <div class="advanceSection-inner InboundCall">
                <div class="aboveProgressBar">
                  <vb-icon icon="CallSummary-InboundCall-icon" class="rightFacingArrow mr-8px" width="16.642" height="15.275" />
                  <div class="aboveProgressBar-text">{{ time_line.team.totalIncommingCalls }}</div>
                </div>
                <vb-circular-progressive-bar 
                  :percentage="time_line.team.totalIncommingCalls > 0 ? Math.ceil((time_line.team.totalIncommingCalls / time_line.team.totalCalls) * 100) : 0"
                />
              </div>
              
              <div class="advanceSection-inner outboundCall">
                <div class="aboveProgressBar">
                  <vb-icon icon="CallSummary-OutboundCall-icon" class="rightFacingArrow mr-8px" width="16.642" height="15.275" />
                  <div class="aboveProgressBar-text">{{ time_line.team.totalOutgoingCalls }}</div>
                </div>
                <vb-circular-progressive-bar 
                  :percentage="time_line.team.totalOutgoingCalls > 0 ? Math.ceil((time_line.team.totalOutgoingCalls / time_line.team.totalCalls) * 100) : 0"
                />
              </div>

              <div class="advanceSection-inner missedCall">
                <div class="aboveProgressBar">
                  <vb-icon icon="CallSummary-MissedCall-icon" class="rightFacingArrow mr-8px" width="16.642" height="15.275" />
                  <div class="aboveProgressBar-text">{{ time_line.team.totalMissCalls }}</div>
                </div>
                <vb-circular-progressive-bar 
                  :percentage="time_line.team.totalMissCalls > 0 ? Math.ceil((time_line.team.totalMissCalls / time_line.team.totalCalls) * 100) : 0"
                />
              </div>

            </div>
          </div>
        </div>
        <vb-no-record v-if="filterTimeLine.length==0" :text="api_sent.list?'':'There is no Number'">
          <vb-loading v-if="api_sent.list" slot="loading" />
        </vb-no-record>
      </template>

    </div>
  </div>
</template>

<script>
import { VOIP_API } from "../../../utils";
import Info from '../../Lists/Info.vue';
import { ctkDateTimePicker } from '../../../mixin';
var moment = require("moment-timezone");
export default {
  name: 'Teams',
  mixins: [ctkDateTimePicker],
  components: {
    Info,
  },
  data() {
    return {
      conditions: {
        select_tab: false,
        advanced: false,
      },
      tabs: [
        {
          value: "Today",
          key: "today",
        },
        {
          value: "This Week",
          key: "week",
        },
        {
          value: "This Month",
          key: "month",
        },
        {
          value: "Custom",
          key: "custom",
        },
      ],
      tab_index: 0,
      selected: "today",
      start_date: null,
      end_date: null,
      api_sent: {
        list: false,
      },
      date: null,
      teams: {},
      kpis: {},
      timeLineData: [],
      filter: {
        search: '',
      },
    };
  },
  inject:['isEmpty'],
  computed: {
    selectedTab(){
      return this.tabs.find(tab=>tab.key==this.selected)
    },
    filterTimeLine(){
      // const time_line = this.timeLineData
      const teams = this.teams
      const teams_keys = Object.keys(this.teams)
      const kpis = this.kpis
      const search = this.filter.search.trim().toLowerCase()
      var array = teams_keys.map(accountcode=>{
        return {
          accountcode: accountcode,
          team: teams[accountcode],
          kpis: kpis[accountcode],
        }
      })
      return array.filter(item=>`${item.team.name}`.trim().toLowerCase().includes(search))
    },
  },
  mounted() {
    this.date = moment().format("ddd, Do MMM");
    this.load();
  },
  methods: {
    load() {
      this.api_sent.list = true;
      VOIP_API.endpoints.analytics.list({
        type: this.selected,
        section: "teams",
        start: this.start_date,
        end: this.end_date,
      })
      .then(({ data: { data, kpis, timeLineData } }) => {
        this.date = data.date_label;
        this.teams = data.teams;
        this.kpis = kpis;
        this.timeLineData = timeLineData;
      })
      .finally(() => {
        this.api_sent.list = false;
      });
    },
  },
};
</script>
