<template>
  <div class="w-100 h-100 d-flex calender-top">
      <div class="left-calender">
          <div class="side-calender-top-bg d-flex justify-content-start align-items-center">
            <img src="../../assets/images/credit-card/hrmprofiletest.png" alt="logo"  class="side-calender-top-small-img"/>
            <h5 class="side-calender-top-head-text">Amber West</h5>
          </div>
          <div class="wrappercalender scrollow-side">
            <div class="sidebarhrmcalender">
                <ul class="hrm-ul-calender-page">
                    <li :class="`${selectedTab == tab.component ? 'selected-active' : ''}`" @click="selectedTab = tab.component"  v-for="tab in tabs" :key="tab.id"><a href="#" >
                      <vb-icon :icon="tab.icon" height="15.23" width="16.25" class="svgiconmenu svgicsize"/>
                     
                      <span class="padding-calender-menu">{{ tab.text }}</span></a>
                    </li>
                   
                </ul> 
            </div>
          </div>
      </div>
      <component :is="selectedTab" />
  </div>
</template>

<script>
import EmployessProfile from "./components/EmployessProfile.vue"
import PersonalHRM from "./components/PersonalHRM.vue"
import NotesHRM from "./components/NotesHRM.vue"
import DocumentsHRM from "./components/DocumentsHRM.vue"
import AssetHRM from "./components/AssetHRM.vue"
import TrainingHRM from "./components/TrainingHRM.vue"
import EmergencycontactsHRM from "./components/EmergencycontactsHRM.vue"
import OnboardHRM from "./components/OnboardHRM.vue"
import TasksHRM from "./components/TasksHRM.vue"
import ReportsHRM from "./components/ReportsHRM.vue"
import TimeoffHRM from "./components/TimeoffHRM.vue"
import CompensationHRM from "./components/CompensationHRM.vue"

const reports = [
  { 
    id: 1,
    text: "Employee",
    component: "EmployessProfile",
    icon: "employee-hrmIcon"
  },
  {
    id: 2,
    text: "Personal",
    icon: "personal-hrmIcon",
    component: "PersonalHRM"
  },
  {
    id: 3,
    text: "Notes",
    icon: "notes-hrmIcon",
    component: "NotesHRM"
  },
  {
    id: 4,
    text: "Documents",
    icon: "documents-hrmIcon",
    component: "DocumentsHRM"
  },
  {
    id: 5,
    text: "Asset",
    icon: "asset-hrmIcon",
    component: "AssetHRM"
  },
  {
    id: 6,
    text: "Training",
    icon: "training-hrmIcon",
    component: "TrainingHRM"
  },
  {
    id: 7,
    text: "Emergency contacts",
    icon: "tasks-hrmIcon",
    component: "EmergencycontactsHRM"
  },
  {
    id: 8,
    text: "Onboarding",
    icon: "onboarding-hrmIcon",
    component: "OnboardHRM"
  },
  {
    id: 9,
    text: "Tasks",
    icon: "reports-hrmIcon",
    component: "TasksHRM"
  },
  {
    id: 10,
    text: "Reports",
    icon: "reports-hrmIcon",
    component: "ReportsHRM"
  },
  {
    id: 11,
    text: "TimeOff",
    icon: "timeoff-hrmIcon",
    component: "TimeoffHRM"
  },
  {
    id: 12,
    text: "compensation",
    icon: "compensation-hrmIcon",
    component: "CompensationHRM"
  }
]
// NotesHRM
export default {
  name: 'HRMCalender',
  components: {
    EmployessProfile,
    PersonalHRM,
    NotesHRM,
    DocumentsHRM,
    AssetHRM,
    TrainingHRM,
    EmergencycontactsHRM,
    OnboardHRM,
    TasksHRM,
    ReportsHRM,
    TimeoffHRM,
    CompensationHRM,
  },
  data() {
    return {
      selectedTab: 'EmployessProfile',
    };
  },
  computed:{
    tabs(){ return reports },
    tab(){
      let component = this.selectedTab;
      let tab = this.tabs.filter(v => v.component === component)
      return tab
    }
  }
}
</script>

