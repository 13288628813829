<template>
  <div class="dialer-tabs dialer-tabs-switch-version">
    <div class="dialer-tab-headers">
      <div class="can-toggle">
        <input
          :id="random_id"
          :disabled="disabled"
          type="checkbox"
          :checked="value"
          :value="value"
          @change="onChange"
        />
        <label :for="random_id">
          <div
            class="can-toggle__switch"
            :data-checked="onText"
            :data-unchecked="offText"
          />
          <div class="can-toggle__label-text"></div>
        </label>
      </div>
    </div>
  </div>
</template>

<script>
import { $fn } from "../../utils";
export default {
  name: "vb-toggle-button",
  props: {
    value: {
      type: Boolean,
      default: false,
    },
    offText: {
      type: String,
      default: "No",
    },
    onText: {
      type: String,
      default: "Yes",
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      random_id: "",
      checked: true,
    };
  },
  computed: {
    isChecked() {
      return this.value;
    },
  },
  methods: {
    onChange(Event) {
      this.$emit("change", Event);
    },
  },
  mounted() {
    this.random_id = $fn.makeid(10);
  },
};
</script>
