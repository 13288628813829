var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-800 editTeamModal bigSquareCheckbox editTaskModal-selectUserModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit pb-4"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Select user")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        _vm.$emit('select', {
          accountcode: _vm.selected.accountcode,
          extra_data: _vm.data.extra_data
        });
        _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-input', {
    model: {
      value: _vm.filter.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter, "search", $$v);
      },
      expression: "filter.search"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, _vm._l(_vm.users, function (user) {
    return _c('div', {
      key: user.accountcode,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
    }, [_c('b-form-checkbox', {
      staticClass: "putBeforeAfterOnLeft16px p-0",
      attrs: {
        "checked": _vm.selected.accountcode == user.accountcode
      },
      on: {
        "change": function ($event) {
          _vm.selected.accountcode = $event ? user.accountcode : '';
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": user.accountcode,
        "is_blf": false
      }
    })], 1)], 1);
  }), 0)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }