var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main h-auto overflow-revert-imp"
  }, [_c('section', {
    staticClass: "dialer-settings-section locationSettings p-0"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-width"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Locations")])], 1), _c('div', {
    staticClass: "d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddLocationModal');
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-largePlus-icon",
      "width": "15.996",
      "height": "15.996",
      "viewBox": "0 0 15.996 15.996",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "sm-mar-left"
  }, [_vm._v("Add Location")])], 1)])] : _c('div', {
    staticClass: "dialer-flex"
  }, [_c('h2', {
    staticClass: "dialer-settings-title"
  }, [_vm._v(" Locations "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "id": "ivr-info",
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'location'
        });
      }
    }
  })], 1), _c('div', [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddLocationModal');
      }
    }
  }, [_c('vb-svg', {
    attrs: {
      "name": "dialer-largePlus-icon",
      "width": "15.996",
      "height": "15.996",
      "viewBox": "0 0 15.996 15.996",
      "stroke-width": "0",
      "stroke": "",
      "fill": "none",
      "stroke-linecap": "round",
      "stroke-linejoin": "round"
    }
  }), _c('span', {
    staticClass: "sm-mar-left"
  }, [_vm._v("Add Location")])], 1)])]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between md-mar-top md-mar-bottom"
  }, [_c('span', [_vm._v("Location-based settings for emergency services and auditing purposes.")]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder seachBox"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search Location"
    },
    model: {
      value: _vm.filter.locations.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.locations, "search", $$v);
      },
      expression: "filter.locations.search"
    }
  })], 1)]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again p-0",
    attrs: {
      "textNoRecord": " ",
      "isListEmpty": _vm.isEmpty(_vm.filterLocations),
      "loading": _vm.api.locations.send,
      "listLength": _vm.pagination.locations.total,
      "perPage": _vm.pagination.locations.per_page,
      "conditions": {
        pagination: {
          per_page: false
        }
      },
      "page": _vm.pagination.locations.page
    },
    on: {
      "page-changed": function ($event) {
        _vm.pagination.locations.page = $event;
        _vm.fetchLocations();
      }
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(_vm.filterLocations, function (location) {
          return _c('tr', {
            key: location.real_id,
            staticClass: "dialer-row-select",
            on: {
              "click": function ($event) {
                return _vm.$modal.show('EditLocationModal', {
                  location: location
                });
              }
            }
          }, [_c('td', {
            staticClass: "dialer-row-title"
          }, [_c('div', [_vm._v(_vm._s(location.house))]), _c('div', [_vm._v(_vm._s(location.street) + " ")]), _c('div', [_vm._v(_vm._s(location.town) + " ")]), _c('div', [_vm._v(_vm._s(location.postcode) + " ")])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [location.country != 'GB' ? _c('p', {
            staticClass: "text-danger bold-5"
          }, [_vm._v("Emergency services are not available outside the UK")]) : _c('p', [_vm._v("-")])]), _c('td', {
            staticClass: "dialer-row-title"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm._f("get_property")(location, 'caller', '-'))))]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_vm._v("Updated At")])]);
        });
      },
      proxy: true
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex flex-column w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "150px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "100px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "200px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign mt-2",
            staticStyle: {
              "height": "16px",
              "width": "150px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "150px"
            }
          })]), _c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "150px"
            }
          })]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Address")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Emergency Validation State")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Phone Number")])]), _c('th', {
    staticClass: "dialer-col-right"
  }, [_c('span', [_vm._v("Updated At")])])])])], 2)], 2), _c('AddLocationModal', {
    on: {
      "latest-list": function ($event) {
        _vm.pagination.locations.page = 1;
        _vm.fetchLocations();
      }
    }
  }), _c('EditLocationModal', {
    on: {
      "latest-list": function ($event) {
        _vm.pagination.locations.page = 1;
        _vm.fetchLocations();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }