var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "ChatSettingsModal",
    attrs: {
      "name": _vm.modalName
    }
  }, [_c('div', {
    staticClass: "ChatSettingsModal-innerContainer"
  }, [_c('div', {
    staticClass: "firstSection-empty"
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "imgContainer"
  }, [_c('img', {
    attrs: {
      "src": _vm.image || ''
    },
    on: {
      "error": function ($event) {
        $event.target.src = require('@/assets/images/userImgForChatSettings.png');
      }
    }
  })]), _c('div', {
    staticClass: "nameAndDescription-container"
  }, [_c('div', {
    staticClass: "nameAndDescription-title"
  }, [_vm._v(" " + _vm._s(_vm.name) + " ")]), _vm.channelData.type === 'channel' ? _c('div', {
    staticClass: "nameAndDescription-Description"
  }, [_vm._v(" " + _vm._s(_vm.channel.purpose || `Add a ${_vm.channelData && _vm.channelData.type == 'team' ? 'team' : 'group'} description`) + " ")]) : _vm._e(), _vm.channelData.type === 'channel' ? _c('vb-icon', {
    staticClass: "svg-primary cursor_pointer",
    attrs: {
      "icon": "pencil-hrmIcon"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.channel_id}_EditChannelInfoModal`, {
          name: _vm.name,
          purpose: _vm.channel.purpose
        });
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "chatSettings-options-container"
  }, [_c('div', {
    staticClass: "chatSettings-options-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "itemIcon"
  }, [_c('vb-icon', {
    staticClass: "svg-primary cursor_pointer",
    attrs: {
      "height": "22px",
      "width": "22px",
      "icon": "chatNotification-ChatSettingsIcon"
    }
  })], 1), _c('div', {
    staticClass: "itemTitle"
  }, [_vm._v(" Chat notification ")])]), _c('div', {
    staticClass: "wd-50px"
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "checked": !_vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.is_mute,
      "name": "check-button",
      "switch": ""
    },
    on: {
      "change": function ($event) {
        _vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.is_mute = !_vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.is_mute;
      }
    }
  })], 1)]), _c('div', {
    staticClass: "chatSettings-options-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "itemIcon"
  }, [_c('vb-icon', {
    staticClass: "svg-primary cursor_pointer",
    attrs: {
      "height": "22px",
      "width": "22px",
      "icon": "ReactionsNotification-ChatSettingsIcon"
    }
  })], 1), _c('div', {
    staticClass: "itemTitle"
  }, [_vm._v(" Reactions notification ")])]), _c('div', {
    staticClass: "wd-50px"
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    class: {
      'disabled': _vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.is_mute
    },
    attrs: {
      "disabled": _vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.is_mute,
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.reaction,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference, "reaction", $$v);
      },
      expression: "$store.state.mattermost.channels[channel_id].notification_prference.reaction"
    }
  })], 1)]), _c('div', {
    staticClass: "chatSettings-options-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "itemIcon"
  }, [_c('vb-icon', {
    staticClass: "svg-primary cursor_pointer",
    attrs: {
      "height": "22px",
      "width": "22px",
      "icon": "NotificationSound-ChatSettingsIcon"
    }
  })], 1), _c('div', {
    staticClass: "itemTitle"
  }, [_vm._v(" Notification sounds ")])]), _c('div', {
    staticClass: "wd-50px"
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    class: {
      'disabled': _vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.is_mute
    },
    attrs: {
      "disabled": _vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.is_mute,
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.sound,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference, "sound", $$v);
      },
      expression: "$store.state.mattermost.channels[channel_id].notification_prference.sound"
    }
  })], 1)]), _c('div', {
    staticClass: "chatSettings-options-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "itemIcon"
  }, [_c('vb-icon', {
    staticClass: "svg-primary cursor_pointer",
    attrs: {
      "height": "22px",
      "width": "22px",
      "icon": "scheduleNotification-ChatSettingsIcon"
    }
  })], 1), _c('div', {
    staticClass: "itemTitle"
  }, [_vm._v(" Scheduled call notifications ")])]), _c('div', {
    staticClass: "wd-50px"
  }, [_c('b-form-checkbox', {
    staticClass: "newerSwitch",
    class: {
      'disabled': _vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.is_mute
    },
    attrs: {
      "disabled": _vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.is_mute,
      "name": "check-button",
      "switch": ""
    },
    model: {
      value: _vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference.schedule_call,
      callback: function ($$v) {
        _vm.$set(_vm.$store.state.mattermost.channels[_vm.channel_id].notification_prference, "schedule_call", $$v);
      },
      expression: "$store.state.mattermost.channels[channel_id].notification_prference.schedule_call"
    }
  })], 1)]), _vm.type === 'main' ? _c('div', {
    staticClass: "chatSettings-options-item withCursorPointer",
    on: {
      "click": function ($event) {
        _vm.$modal.hide(_vm.modalName);
        _vm.$emit('members');
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "itemIcon"
  }, [_c('vb-icon', {
    staticClass: "svg-primary cursor_pointer",
    attrs: {
      "height": "22px",
      "width": "22px",
      "icon": "showParticipants-ChatSettingsIcon"
    }
  })], 1), _c('div', {
    staticClass: "itemTitle"
  }, [_vm._v(" Show participants ")])]), _c('b-icon', {
    staticClass: "forRightIcon",
    attrs: {
      "icon": "chevron-right"
    }
  })], 1) : _vm._e(), _vm.channelData.type === 'channel' ? _c('div', {
    staticClass: "chatSettings-options-item withCursorPointer",
    on: {
      "click": function ($event) {
        return _vm.leave();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "itemIcon forRed"
  }, [_c('vb-icon', {
    staticClass: "svg-primary cursor_pointer",
    attrs: {
      "height": "22px",
      "width": "22px",
      "icon": "chatLeave-ChatSettingsIcon"
    }
  })], 1), _c('div', {
    staticClass: "itemTitle forRed"
  }, [_vm._v(" Leave ")])])]) : _vm._e(), _vm.channelData.type === 'channel' && !_vm.isEmpty(_vm.currentUserMember) && _vm.currentUserMember.roles.indexOf('channel_admin') >= 0 ? _c('div', {
    staticClass: "chatSettings-options-item withCursorPointer",
    on: {
      "click": function ($event) {
        return _vm.deleteChannel();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "itemIcon forRed"
  }, [_c('vb-icon', {
    staticClass: "svg-primary cursor_pointer",
    attrs: {
      "height": "22px",
      "width": "22px",
      "icon": "trash-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "itemTitle forRed"
  }, [_vm._v(" Delete ")])])]) : _vm._e()]), _c('div', {
    staticClass: "dialer-modal-body md-mar-vertical px-3 mx-2"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  })])])]), _c('EditChannelInfo', {
    attrs: {
      "modalName": `${_vm.channel_id}_EditChannelInfoModal`,
      "channel": _vm.channel
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }