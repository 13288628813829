var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "right-calender"
  }, [_c('div', {
    staticClass: "top-main-calender scrollow1"
  }, [_c('section', {
    staticClass: "sec-common"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_vm._m(0), _c('button', {
    staticClass: "btn btn-primarymike",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('HRMAddNewNotesModal');
      }
    }
  }, [_c('svg', {
    staticClass: "primarymike-svg",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "13.089",
      "height": "16.536",
      "viewBox": "0 0 13.089 16.536"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_42470",
      "data-name": "Path 42470",
      "d": "M9.665,0h.291a.76.76,0,0,1,.551.855c-.014.229,0,.458,0,.7h.635A1.9,1.9,0,0,1,13.089,3.5q0,5.546,0,11.093a1.893,1.893,0,0,1-1.951,1.945q-4.594,0-9.187,0A1.886,1.886,0,0,1,0,14.592Q0,9.046,0,3.5A1.889,1.889,0,0,1,1.961,1.55h.624V.7A.649.649,0,0,1,2.849.149,2.26,2.26,0,0,1,3.109,0h.324A2.131,2.131,0,0,1,3.68.139a.662.662,0,0,1,.277.57c0,.279,0,.556,0,.83h1.9c0-.235.011-.461,0-.686A.759.759,0,0,1,6.4,0h.291a.782.782,0,0,1,.543.876c-.014.218,0,.438,0,.666h1.89c0-.228.011-.447,0-.666A.781.781,0,0,1,9.665,0M6.886,8.268q-1.7,0-3.4,0a.69.69,0,1,0,0,1.378c.587.006,1.174,0,1.76,0q2.5,0,5,0a.743.743,0,0,0,.585-.22.689.689,0,0,0-.506-1.161c-1.147,0-2.293,0-3.439,0m0,4.134h.935c.834,0,1.667,0,2.5,0a.682.682,0,0,0,.629-.975.7.7,0,0,0-.7-.4q-3.372,0-6.746,0a1.051,1.051,0,0,0-.209.02.652.652,0,0,0-.516.458.685.685,0,0,0,.678.9c1.139,0,2.281,0,3.422,0M5.169,6.885c.587,0,1.172.008,1.759,0a.682.682,0,0,0,0-1.362c-1.174-.008-2.346-.009-3.518,0a.782.782,0,0,0-.421.169.647.647,0,0,0-.194.745.666.666,0,0,0,.615.446c.587.006,1.172,0,1.759,0",
      "transform": "translate(0)",
      "fill": "#fff"
    }
  })]), _vm._v(" Add Notes ")])]), _vm._m(1), _c('vb-table', {
    staticClass: "notes-table-width mar-top-hrm60",
    attrs: {
      "tableDesign": 1
    }
  }, [_c('template', {
    slot: "header"
  }, [_c('h5', {
    staticClass: "commonth notes-firsttd col-first-notes"
  }, [_vm._v("Created By")]), _c('h5', {
    staticClass: "commonth notes-secondtd"
  }, [_vm._v("Date")]), _c('h5', {
    staticClass: "commonth notes-thirdtd notes-noteth"
  }, [_vm._v("Note")]), _c('h5', {
    staticClass: "commonth notes-fourtd notes-center"
  }, [_vm._v("Documents")]), _c('h5', {
    staticClass: "commonth notes-fivetd col-last-notes"
  }, [_vm._v("Action")])]), _c('template', {
    slot: "body"
  }, [_c('tr', [_c('td', {
    staticClass: "notes-firsttd col-first-notes"
  }, [_c('div', {
    staticClass: "d-flex justify-content-start align-items-center"
  }, [_c('img', {
    staticClass: "profleimg-notes",
    attrs: {
      "src": require("../../../assets/images/credit-card/defp.png"),
      "alt": "logo"
    }
  }), _c('div', {
    staticClass: "notes-profile-content"
  }, [_c('h5', {
    staticClass: "profleimg-text1"
  }, [_vm._v("Amber West")]), _c('span', {
    staticClass: "profleimg-text2"
  }, [_vm._v("8001")])])])]), _c('td', {
    staticClass: "notes-secondtd"
  }, [_vm._v(" 23/07/2022 ")]), _c('td', {
    staticClass: "notes-thirdtd"
  }, [_vm._v(" In this example from GMail, they launched a tour that announced two new features... ")]), _c('td', {
    staticClass: "notes-fourtd notes-t-center"
  }), _c('td', {
    staticClass: "notes-fivetd"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end commonTableMain"
  }, [_c('b-dropdown', {
    staticClass: "common-table-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('b-icon', {
          attrs: {
            "icon": "three-dots-vertical"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Action")])], 1)], 1)])])])], 2)], 1)]), _c('HRMAddNewNotesModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h5', {
    staticClass: "emp-profile-heading mb-imp"
  }, [_vm._v("Notes")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "notes-upper-text-width"
  }, [_c('p', {
    staticClass: "notes-top-text"
  }, [_vm._v(" “This section allows keeping notes & attachments related to employees. Employees by default don’t have access to this section, unless explicitly given the permission” ")])]);

}]

export { render, staticRenderFns }