var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    ref: "app_designed",
    staticClass: "flow__graph designed designedAgain scaleDown-0-6",
    attrs: {
      "id": "app_designed"
    }
  }, [_vm.callQueue ? _c('div', {
    staticClass: "w-100 d-flex justify-content-center align-items-center flex-column"
  }, [_c('div', {
    staticClass: "w-auto d-flex justify-content-center align-items-start flex-column"
  }, [_c('NumbersNode', {
    attrs: {
      "dids": _vm.callQueue.userDid
    }
  }), _c('InfoNode', {
    attrs: {
      "voipaccount": _vm.callQueue.voipaccount
    },
    on: {
      "edit": function ($event) {
        return _vm.$modal.show('EditCallQueueGraphModal', {
          call_queue: _vm.callQueue
        });
      }
    }
  }), _c('div', {
    staticClass: "d-flex"
  }, [_c('div', {
    staticClass: "nodeBasic-new"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "nodeBasic-heading"
  }, [_vm._v("Members")]), _c('b-icon', {
    attrs: {
      "icon": "people-fill"
    }
  })], 1), _c('div', {
    staticClass: "w-100 selectedText-big"
  }, [_vm._v("Agents Online")]), _c('div', {
    staticClass: "w-100 selectedText-small"
  }, [_vm._v(_vm._s(_vm.onlinemembers.length) + "/" + _vm._s(_vm.members.length))]), _c('div', {
    staticClass: "w-100 d-flex justify-content-end"
  }, [_c('span', {
    staticStyle: {
      "position": "relative"
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "pencil-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('EditCallQueueMembersGraphModal', {
          call_queue: _vm.callQueue,
          type: 'CallQueueMembers'
        });
      }
    }
  })], 1)])]), _vm._m(0), _c('div', {
    staticClass: "nodeBasic-new"
  }, [_vm._l(_vm.firstFourMembers, function (member) {
    return _c('div', {
      key: member.membername,
      staticClass: "mb-2"
    }, [_c('Info', {
      attrs: {
        "id": member.membername,
        "type": 'USER',
        "is_blf": false
      }
    })], 1);
  }), _vm.members.length > 4 ? _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-4"
  }, [_c('div', {
    staticStyle: {
      "color": "rgb(85, 118, 209)",
      "font-weight": "600",
      "font-size": "16px"
    }
  }, [_vm._v(" " + _vm._s(_vm.members.length - 4) + " more members ")])]) : _vm._e()], 2)])], 1)]) : _vm._e()]), _c('AddRemoveFromList', {
    attrs: {
      "modalName": 'EditCallQueueMembersGraphModal'
    },
    on: {
      "update-list": function ($event) {
        return _vm.getCallQueue();
      }
    }
  }), _c('EditCallQueueModal', {
    attrs: {
      "modalName": `EditCallQueueGraphModal`
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "lineWithArrow right"
  }, [_c('div', {
    staticClass: "lineWithArrow-line"
  }), _c('div', {
    staticClass: "lineWithArrow-arrow"
  })]);

}]

export { render, staticRenderFns }