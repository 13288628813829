<template>
  <modal
    class="dialer_animation right_side_popup m-w-500"
    width="500px"
    height="auto"
    :scrollable="true"
    name="TagCallNoteModal"
    @closed="onClosed"
    @before-open="beforeOpen"
  >
    <section class="ct-user-new dialer-edit">
      <form class="dialer-form lg-mar-bottom" @submit.prevent="sendTag()">
        <div class="dialer-edit-header">
          <div>
            <h2 class="dialer-edit-title">Tags</h2>
          </div>
          <div class="dialer-edit-actions">
            <a class="dialer-button dialer-button-delete" @click="$modal.hide('TagCallNoteModal')">Cancel</a>
            <button type="submit" class="newDoneButton" :disabled="api_sent.tag">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                <vb-spinner v-if="api_sent.tag" />
                <template v-else>Done</template>
              </span>
            </button>
          </div>
        </div>
        <div class="dialer-call-notification">
          <div class="dialer-call-notification-inner">
            <div class="dialer-call-notification-text">
              <div class="dialer-call-notification-sub-text">
                There you can select the tags of this call
              </div>
            </div>
          </div>
          <div class="dialer-call-notification-actions">
            <div class="dialer-call-notification-actions-inner">
              
              <b-form @submit.prevent="''" class="dialer-searchBox-now-default my-3 mx-3 w-auto">
                <b-input placeholder="Search here" class="w-100" v-model="filter.tag.search"/>
              </b-form>
              <ul class="dialer-tagList">
                <li 
                  v-for="tag in searchedTags" 
                  :key="tag.real_id" 
                  :class="{selected: form.tags.indexOf(tag.real_id)>-1}" 
                  v-vb-tag-color.bg.text.border="form.tags.includes(tag.real_id) && {color:tag.colour,text_color:tag.text_color}"
                  @click="handleTags(tag)"
                >
                  {{ tag.tag }}
                </li>
              </ul>
            </div>
          </div>
        </div>
      </form>
    </section>
  </modal>
</template>

<script>
import { mapGetters } from 'vuex';
import { LOGGER, VOIP_API } from "../../utils";
export default {
  name: "TagCallNoteModal",
  data() {
    return {
      call_id: "",
      api_sent: {
        tag: false,
      },
      form: {
        tags: [],
      },
      filter: {
        tag: {
          search: '',
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getVoipCallTags',
    ]),
    searchedTags() {
      const search = this.filter.tag.search.toLowerCase().trim()
      return this.getVoipCallTags.filter(tag => `${tag.tag}`.toLowerCase().trim().includes(search));
    },
  },
  methods: {
    handleTags(tag){
      if(this.form.tags.indexOf(tag.real_id)>-1){
        this.form.tags.splice(this.form.tags.indexOf(tag.real_id),1)
      } else {
        this.form.tags.push(tag.real_id)
      }
    },
    beforeOpen(event) {
      LOGGER.log(event);
      this.call_id = event.params.call_id || '';
      let tags = event.params.tags?event.params.tags:[];
      let filterTags = tags.filter(tag=>tag.tag && tag.tag.id)
      this.form.tags = filterTags.map(tag=>tag.tag.id)
    },
    onClosed() {
      this.call_id = "";
      this.form.tags = [];
    },
    sendTag() {
      if (this.api_sent.tag) return;
      this.api_sent.tag = true;
      VOIP_API.endpoints.call_activity
        .calltagsnotes({
          uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
          accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
          id: this.call_id,
          log: this.form.tags.toString(),
          type: "tag",
        })
        .then(() => {
          this.appNotify({
            message: 'Successfully Updated!',
            type: 'success',
          })
          this.$emit('updated',this.form.tags)
          this.$modal.hide("TagCallNoteModal");
        })
        .catch((ex)=>{
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(() => {
          this.api_sent.tag = false;
        });
    },
  },
};
</script>

<style>
</style>