var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('fragment', [_c('ChatPanel', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$store.getters.getIsMobile ? !_vm.channel_id : true,
      expression: "$store.getters.getIsMobile ? !channel_id : true"
    }],
    ref: "panel",
    attrs: {
      "channelsTab": _vm.channelsTab,
      "channel_id": _vm.channel_id
    },
    on: {
      "change-channel-id": function ($event) {
        _vm.channel_id = $event;
      }
    }
  }), _c('Chatbox', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: _vm.$store.getters.getIsMobile ? _vm.channel_id : true,
      expression: "$store.getters.getIsMobile ? channel_id : true"
    }],
    ref: "box",
    attrs: {
      "channel_id": _vm.channel_id
    },
    on: {
      "change-channel-id": function ($event) {
        _vm.channel_id = $event;
      },
      "back": function ($event) {
        _vm.channel_id = '';
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }