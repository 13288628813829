var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup full-width-big-modal",
    attrs: {
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to my-4 dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('WelcomeGreeting', {
    staticClass: "latestGreyBox-9-9-2023 newIVRLayout-WelcomeGreetingsSection mt-20px",
    attrs: {
      "menu": _vm.ivr
    },
    on: {
      "fileChange": function ($event) {
        return _vm.$emit('file-change');
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }