import { VOIP_API } from "../index"

export const TASK_LIST_API_ENDPOINTS = {
  logs: {
    list(params={}){
      return VOIP_API.axios.voip.request({
        url: `get-tsaklist-logs`,
        method: "GET",
        params,
      })
    },
  },
  notes: {
    create(data={}){
      return VOIP_API.axios.voip.request({
        url: `create-notes`,
        method: "POST",
        data,
      })
    },
    list(params={}){
      return VOIP_API.axios.voip.request({
        url: `notes`,
        method: "GET",
        params,
      })
    },
    delete(id='',data={}){
      return VOIP_API.axios.voip.request({
        url: `notes/${id}`,
        method: "DELETE",
        data,
      })
    },
    update(id='',data={}){
      return VOIP_API.axios.voip.request({
        url: `update-notes/${id}`,
        method: "POST",
        data,
      })
    },
  },
  attachments: {
    create(data={},onUploadProgress=null,cancelToken=null){
      return VOIP_API.axios.voip.request({
        url: `attachment`,
        method: "POST",
        onUploadProgress,
        cancelToken,
        data,
      })
    },
    delete(id='',data={}){
      return VOIP_API.axios.voip.request({
        url: `attachment/${id}`,
        method: "DELETE",
        data,
      })
    },
    list(params={}){
      return VOIP_API.axios.voip.request({
        url: `attachment`,
        method: "GET",
        params,
      })
    },
  },
  checklist: {
    create(data={}){
      return VOIP_API.axios.voip.request({
        url: `checklist`,
        method: "POST",
        data,
      })
    },
    delete(id='',data={}){
      return VOIP_API.axios.voip.request({
        url: `checklist/${id}`,
        method: "DELETE",
        data,
      })
    },
    update(id='',data={}){
      return VOIP_API.axios.voip.request({
        url: `checklist/${id}`,
        method: "POST",
        data,
      })
    },
    list(params={}){
      return VOIP_API.axios.voip.request({
        url: `checklist`,
        method: "GET",
        params,
      })
    },
    assignUser(data={}){
      return VOIP_API.axios.voip.request({
        url: `checklist-assigned-user`,
        method: "POST",
        data,
      })
    },
  },
  call_log: {
    getCallLog(params={}){
      return VOIP_API.axios.voip.request({
        url: `get-cdr`,
        method: "GET",
        params,
      })
    },
    updateCallLog(data={}){
      return VOIP_API.axios.voip.request({
        url: `update-cdr-id`,
        method: "POST",
        data,
      })
    },
  },
  filters: {
    list(params={},cancelToken=null){
      return VOIP_API.axios.voip.request({
        url: `get-tasklist-filters`,
        method: "GET",
        params,
        cancelToken,
      })
    },
    add(data={}){
      return VOIP_API.axios.voip.request({
        url: `create-tasklist-filters`,
        method: "POST",
        data,
      })
    },
    remove(id='',data={}){
      return VOIP_API.axios.voip.request({
        url: `delete-tasklist-filters/${id}`,
        method: "DELETE",
        data,
      })
    },
    makeDefault(id='',data={}){
      return VOIP_API.axios.voip.request({
        url: `default-tasklist-filters/${id}`,
        method: "POST",
        data,
      })
    },
  },
  availableMembers(params={}){
    return VOIP_API.axios.voip.request({
      url: `space-private`,
      method: "GET",
      params,
    })
  },
  updateViewer(data){
    return VOIP_API.axios.voip.request({
      url: `viewers`,
      method: "POST",
      data,
    })
  },
  updateAssignedTo(data){
    return VOIP_API.axios.voip.request({
      url: `multiple-assigned-task`,
      method: "POST",
      data,
    })
  },
  moveTask(data){
    return VOIP_API.axios.voip.request({
      url: `task-transfer`,
      method: "POST",
      data,
    })
  },
  create(data={}){
    return VOIP_API.axios.voip.request({
      url: "tasklist",
      method: "POST",
      data,
    })
  },
  list(params={},token){
    return VOIP_API.axios.voip.request({
      url: "tasklist",
      method: "GET",
      params,
      cancelToken: token
    })
  },
  syncBoard(data={}){
    return VOIP_API.axios.voip.request({
      url: "tasklist/status",
      method: "POST",
      data,
    })
  },
  export(data={}){
    return VOIP_API.axios.voip.request({
      url: "import-and-export-task-data",
      method: "POST",
      data,
      responseType: 'blob', 
    })
  },
  import(data={},onUploadProgress=null){
    return VOIP_API.axios.voip.request({
      url: "import-and-export-task-data",
      method: "POST",
      data, 
      onUploadProgress,
      headers: {
        'Content-Type': 'application/form-data'
      },
      timeout: 1000 * 60 * 60 * 24 * 1,
    })
  },
  linkTasks(data={}){
    return VOIP_API.axios.voip.request({
      url: "task-linked",
      method: "POST",
      data,
    })
  },
  delete(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `tasklist/${id}`,
      method: "DELETE",
      data,
    })
  },
  addToMyDay(data={}){
    return VOIP_API.axios.voip.request({
      url: `add/my-day`,
      method: "POST",
      data,
    })
  },
  removeToMyDay(data={}){
    return VOIP_API.axios.voip.request({
      url: `remove/my-day`,
      method: "POST",
      data,
    })
  },
  find(id='',params={}){
    return VOIP_API.axios.voip.request({
      url: `tasklist/${id}`,
      method: "GET",
      params,
    })
  },
  timerUpdate(data={}){
    return VOIP_API.axios.voip.request({
      url: `update-timer`,
      method: "POST",
      data,
    })
  },
  update(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `tasklist/${id}`,
      method: "PATCH",
      data,
    })
  },
  callAssign(data={}){
    return VOIP_API.axios.voip.request({
      url: `call-assigned/task`,
      method: "POST",
      data,
    })
  },
}