<template>
  <modal class="dialer_animation right_side_popup developerOptionsModal" :name="modalName" @before-open="onBeforeOpen($event)" @before-close="onBeforeClose()">
    <div class="dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between">
      <h2 class="dialer-edit-title newer mb-0">Features</h2>
      <div class="dialer-edit-actions d-flex justify-content-end">
        <button class="newCloseButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-close-icon" height="38px" width="38px" />
          <span class="newCloseButton-textPart">Close</span>
        </button>
      </div>
    </div>
    <div style="max-height: calc(100vh - 120px);overflow-y: auto;height: calc(100vh - 120px);">
      <div class="dialer-login-form">
        <b-form @submit.prevent="''">
          <div class="dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 w-100">
            <div class="wrap w-100">
              <div class="signupLoginV2-section-2-right w-100 pr-3">
                <div class="latestGreyBox-9-9-2023 mt-20px">
                  <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none" v-for="(data, index) in GET_API_ERRORS" :key="index">
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">{{data.status}}</div>
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">{{data.url}}</div>
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">not sync</div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none" v-for="(data, index) in GET_STORE_API_ERRORS" :key="index">
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">{{data.status}}</div>
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">{{data.url}}</div>
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">sync</div>
                    </div>
                    <!-- <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none" v-for="(data, index) in GET_CENTRIFUGO_ERRORS" :key="index">
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">{{data.type}}</div>
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">{{data.reason}}</div>
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">not sync</div>
                    </div>
                    <div class="whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none" v-for="(data, index) in GET_STORE_CENTRIFUGO_ERRORS" :key="index">
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">{{data.type}}</div>
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">{{data.reason}}</div>
                      <div class="latestGreyBox-heading-main text-left wd-33-imp">sync</div>
                    </div> -->
                  </div>
                </div>
              </div>
            </div>
          </div>
        </b-form>
      </div>
    </div>
  </modal>
</template>

<script>
import { GET_API_ERRORS, /*GET_CENTRIFUGO_ERRORS,*/ GET_STORE_API_ERRORS/*, GET_STORE_CENTRIFUGO_ERRORS*/ } from '@/store/helper/getters';
import { mapGetters } from 'vuex';
export default {
  name: 'DeveloperErrorsStoreModal',
  props: {
    modalName: {
      type: String,
      default: 'DeveloperErrorsStore',
    },
  },
  data(){
    return {
      
    }
  },
  computed: {
    ...mapGetters([
      GET_API_ERRORS,
      GET_STORE_API_ERRORS,
      // GET_CENTRIFUGO_ERRORS,
      // GET_STORE_CENTRIFUGO_ERRORS
    ]),
  },
  methods: {
    onBeforeOpen(){
      // TODO - 
    },
    onBeforeClose(){
      // TODO - 
    },
  },
}
</script>

<style>

</style>