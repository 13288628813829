<template>
  <div>
    <modal
      class="center-zoomIn-transition v2 m-w-500 SmallAddModal" 
      width="500px" 
      height="auto"
      :scrollable="true"
      :name="modalName"
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
      @closed="onClose()"
    >
      <section class="ct-user-new dialer-edit s1">
        <form class="dialer-form " @submit.prevent="addIvr()">
          <!-- <div v-if="getIsMobile" class="dialer-edit-header flex-column">
            <div class="d-flex justify-content-between align-items-center w-100">
              <h2 class="dialer-edit-title">Add IVR</h2>
              <b-icon class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            </div>
            <div class="dialer-edit-actions w-100 d-flex justify-content-end mt-2">
              <button class="newDoneButton" :disabled="api.add_ivr.send">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api.add_ivr.send" />
                  <template v-else>Done</template>
                </span>
              </button>
              <button type="button" class="dialer-button dialer-button-primary" :disabled="api.add_ivr.send" @click="$modal.show('CheckedServiceAvailableModal',{service: 'ivrs',onProceed: addIvr,})">
                <template>Detail</template>
              </button>
            </div>
          </div> -->
           <!-- v-else -->
          <div class="dialer-edit-header d-block pb-0">
            <div class="d-flex justify-content-between align-items-center">
              <div class="d-flex align-items-center">
                <h2 class="dialer-settings-title newer">Add IVR</h2>
                <vb-icon 
                  icon="checkSlotsModal-icon" class="ml-2 cursor_pointer checkSlotsModal-icon" height="22px" width="22px"
                  :disabled="api.add_ivr.send" @click="$modal.show('CheckedServiceAvailableModal',{service: 'ivrs',onProceed: addIvr,})"
                />
              </div>
              <div class="dialer-edit-actions">
                <a class="newCloseButton" @click="$modal.hide(modalName)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <div class="vm--modal-text">
              Enter the name of the new IVR below.
            </div>
          </div>
          <template v-if="api.check_service.send">
            <div class="md-mar-top">
              <div class="latestGreyBox-9-9-2023 mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder">
                  <label>
                    <div class="latestShimmerDesign" style="height: 21px;width: 200px;"></div>
                  </label>
                  <div class="latestShimmerDesign" style="height: 50px;width: 100%;"></div>
                </div>
              </div>
            </div>
            <div class="latestShimmerDesign mt-4" style="height: 50px;width: 100%;"></div>
          </template>
          <div v-else class="md-mar-top">
            <b-alert :show="!!api.add_ivr.error_message" variant="danger">{{ api.add_ivr.error_message }}</b-alert>
            <b-alert class="alertCustom" :show="checkServiceAlert.enable" :variant="checkServiceAlert.variant">{{ checkServiceAlert.message }}</b-alert>
            <div class="latestGreyBox-9-9-2023 mt-20px">
              <div class="whiteBGinputWithGreyRoundedBorder">
                <label>Name</label>
                <input :disabled="api.add_ivr.send" class="w-100" type="text" v-model="forms.ivr.name" :maxlength="$v.forms.ivr.name.$params.maxLength.max" />
                <p v-if="(forms.ivr.submitted && $v.forms.ivr.name.$invalid) || api.add_ivr.validation_errors.name" class="text text-danger animated bounceIntop">
                  <span v-if="!$v.forms.ivr.name.required">* Name is required</span>
                  <span v-else-if="!$v.forms.ivr.name.minLength">* Name is should be minimum {{$v.forms.ivr.name.$params.minLength.min}} character</span>
                  <span v-else-if="!$v.forms.ivr.name.maxLength">* Name is can be maximum {{$v.forms.ivr.name.$params.maxLength.max}} character</span>
                  <span v-else-if="!$v.forms.ivr.name.valid">* Name can be number and alphabet</span>
                  <span v-for="(em,i) in api.add_ivr.validation_errors.name" :key="i">* {{ em }}</span>
                </p>
              </div>
            </div>
            <div class="w-100 mt-4">
              <button class="fullWidthDoneButton" :disabled="api.add_ivr.send">
                <vb-spinner v-if="api.add_ivr.send" />
                <template v-else>Create IVR</template>
              </button>
            </div>
          </div>
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { /*events,*/ VOIP_API } from '../../utils';
import { mapGetters } from 'vuex';
import { check_service } from '@/mixin';
import { helpers } from "vuelidate/lib/validators";
import required from "vuelidate/lib/validators/required";
import minLength from 'vuelidate/lib/validators/minLength';
import maxLength from 'vuelidate/lib/validators/maxLength';
const {
  uniqueNamesGenerator,
  adjectives,
  colors,
  animals,
} = require("unique-names-generator");

export default {
  name: "AddMenuModal",
  mixins: [
    check_service
  ],
  props: {
    modalName: {
      type: String,
      default: 'AddMenuModal',
    },
  },
  data() {
    return {
      forms: {
        ivr: this.$helperFunction.formInstance({
          data: {
            name: '',
          }
        }),
      },
      api: {
        add_ivr: this.$helperFunction.apiInstance({ error_message: true, validation_errors: true, }),
        check_service: this.$helperFunction.apiInstance(),
      },
      response: {
        check_service: {},
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile'
    ]),
    checkServiceAlert(){
      const type = this.response.check_service?.type
      const message = this.response.check_service?.message || ''
      return {
        enable: !!this.response.check_service?.type,
        title: ['ADDON'].includes(type) ? 'Attention' : 'Warning',
        message: message,
        variant: ['ADDON'].includes(type) ? 'info' : 'danger',
      }
    },
  },
  validations: {
    forms: {
      ivr: {
        name: { 
          required: required,
          minLength: minLength(3), 
          maxLength: maxLength(20),
          valid: helpers.regex('valid',/^[a-z0-9 ]*$/i),
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      const randomName = uniqueNamesGenerator({
        dictionaries: [adjectives, colors, animals],
        separator: " ",
        length: 2,
      });
      this.forms.ivr.name=event?.params?.suggested_name ?? `${randomName} IVR`
      this.checkservice()
    },
    onBeforeClose(){
      this.forms.ivr.reset()
      this.api.add_ivr.reset()
      this.api.check_service.reset()
      this.response.check_service={}
    },
    
    checkservice(){
      let vm = this
      if(vm.api.check_service.send) return;
      vm.api.check_service.send=true
      vm.checkService({
        service: 'ivrs',
        no_confirmation: true,
        then(data){
          vm.response.check_service=data || {}
        },
        catch(ex){
          vm.appNotify({
            message: ex.own_message || ex.message,
            type: 'danger',
          })
        },
        finally(){
          vm.api.check_service.send=false
        }
      })
    },
    addIvr() {
      let vm = this
      vm.forms.ivr.submitted = true;
      vm.$v.forms.ivr.$touch();
      if (vm.$v.forms.ivr.$invalid || vm.api.add_ivr.send) return;
      vm.api.add_ivr.send = true;
      vm.api.add_ivr.validation_errors = {};
      vm.api.add_ivr.error_message = '';
      vm.checkService({
        service: 'ivrs',
        async then(){
          await VOIP_API.endpoints.menu.create({
            name: vm.forms.ivr.name,
            service: 'ivrs',
            accountcode: vm.getCurrentUser.account,
            uid: vm.getCurrentUser.uid,
            default_account: vm.getCurrentUser.default_accountcode,
          })
          // vm.$store.commit('setAddServiceQueue',{
          //   type: 'ivr',
          //   name: vm.forms.ivr.name,
          // })
          vm.$modal.hide(vm.modalName)
          vm.$emit('interface');
          // vm.$root.$emit(events.check_pending_services)
        },
        catch(ex){
          vm.api.add_ivr.validation_errors = ex.own_errors;
          vm.api.add_ivr.error_message = ex.own_message;
        },
        finally(){
          vm.forms.ivr.submitted = false;
          vm.api.add_ivr.send = false;
        },
      })
    },
    onClose(){
      this.$emit('close')
    },
  }
};
</script>
