var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "ticketConversation-container"
  }, [_vm.api.fetch_comments.send ? _vm._l(6, function (n) {
    return _c('div', {
      key: n.id,
      class: `latestShimmerDesign w-25 ${n % 2 == 1 ? 'ml-auto' : ''}`,
      staticStyle: {
        "height": "52px"
      }
    });
  }) : [_vm.comments.length == 0 ? [_vm._m(0)] : _vm._l(_vm.comments, function (message, index) {
    return _c('div', {
      key: message.id
    }, [_c('TicketMessage', {
      attrs: {
        "message": message,
        "prevMessage": _vm.comments[index - 1],
        "nextMessage": _vm.comments[index + 1],
        "userId": _vm.userId
      }
    })], 1);
  }), _c('TicketAction', {
    attrs: {
      "status": _vm.status,
      "ticketId": _vm.ticketId,
      "feedback": _vm.feedback
    },
    on: {
      "comment-created": function ($event) {
        return _vm.fetchComments();
      },
      "status-updated": function ($event) {
        return _vm.$emit('status-updated');
      }
    }
  })]], 2)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "h-100 d-flex d-flex justify-content-center align-items-center my-5"
  }, [_c('div', {
    staticClass: "w-75 text-center mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("No message to see here")]), _c('div', {
    staticClass: "latestGreyBox-heading-description mt-10px"
  }, [_vm._v("You can send one by writing a message below")])])]);

}]

export { render, staticRenderFns }