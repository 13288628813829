var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Training "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddTrainingType');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "plus",
      "font-scale": "1.3",
      "scale": "1.5"
    }
  }), _vm._v(" Add training type ")], 1)]), _vm._m(0), _c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Training Types ")]), _c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_vm._v("Employee handbook")]), _c('td', {
            staticClass: "dialer-col-right text-left two"
          }, [_vm._v("Invoice")]), _c('td', {
            staticClass: "dialer-col-right text-left three"
          }, [_vm._v("0")]), _c('td', {
            staticClass: "dialer-col-right text-left four"
          }, [_c('b-dropdown', {
            staticClass: "ActionsDD",
            attrs: {
              "no-caret": "",
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('div', {
                  staticClass: "button-content"
                }, [_c('b-icon', {
                  staticClass: "downIcon",
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead text-left one pt-1"
  }, [_vm._v("name")]), _c('th', {
    staticClass: "HrmTableHead text-left two pt-1"
  }, [_vm._v("Allowed payments types")]), _c('th', {
    staticClass: "HrmTableHead text-left three pt-1"
  }, [_vm._v("Trainings")]), _c('th', {
    staticClass: "HrmTableHead four pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1), _c('b-form-checkbox', {
    staticClass: "HrmCheckBox-container mt-32px"
  }, [_vm._v(" Allow employees to add & edit trainings ")]), _c('div', {
    staticClass: "innerShadowInput-container wd-46 mt-8px"
  }, [_c('label', [_vm._v("Training adminstrators")]), _c('b-dropdown', {
    staticClass: "hrmInnerShadow-dd",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "button-content"
        }, [_c('div', {
          staticClass: "buttonContentText"
        }, [_vm._v("Training adminstrators")]), _c('b-icon', {
          staticClass: "downIcon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', [_vm._v(" Training adminstrators ")])], 1)], 1), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0"
  }, [_vm._v(" Save ")]), _c('div', {
    staticClass: "mainHeading withButton mt-32px"
  }, [_vm._v(" Payment types "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddPaymentType');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "plus",
      "font-scale": "1.3",
      "scale": "1.5"
    }
  }), _vm._v(" Add Payment type ")], 1)]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Set up different options how employees can pay for the training ")]), _c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return _vm._l(6, function (n) {
          return _c('tr', {
            key: n,
            staticClass: "dialer-row-select"
          }, [_c('td', {
            staticClass: "dialer-row-title one"
          }, [_c('div', {
            staticClass: "td-heading"
          }, [_vm._v("Invoice")]), _c('div', {
            staticClass: "td-text"
          }, [_vm._v("Approved by direct manager")])]), _c('td', {
            staticClass: "dialer-col-right two"
          }, [_c('b-dropdown', {
            staticClass: "ActionsDD",
            attrs: {
              "no-caret": "",
              "right": ""
            },
            scopedSlots: _vm._u([{
              key: "button-content",
              fn: function () {
                return [_c('div', {
                  staticClass: "button-content"
                }, [_c('b-icon', {
                  staticClass: "downIcon",
                  attrs: {
                    "icon": "three-dots-vertical"
                  }
                })], 1)];
              },
              proxy: true
            }], null, true)
          }, [_c('b-dropdown-item', [_vm._v(" Actions ")])], 1)], 1)]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead text-left one pt-1"
  }, [_vm._v("Payment type")]), _c('th', {
    staticClass: "HrmTableHead two pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1), _c('AddTrainingType'), _c('AddPaymentType')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_c('br')]);

}]

export { render, staticRenderFns }