<template>
  <modal class="dialer_animation right_side_popup global-modal-hrm Scrollable-hrm" name="HRMAddNewTaskModal"  :clickToClose="true"  @before-open="beforeOpen" :scrollable="true">
    <form @submit.prevent="''">
        <div class="dialer-hrm-modal-header mar-top-hrm20">
           <h2 class="dialer-modal-hrm-title">Add new task</h2>
           <button class="dialer-hrm-button-modal" @click="$modal.hide('HRMAddNewTaskModal')">Close</button>
        </div>
        <div class="bg-modal-doc-grey-share-with sp-gm-if mar-top-hrm30">
          <div class="test-mode">
            <b-form-group class="gm-hrm-custom-radio">
              <b-form-radio name="some-radios" value="A">Specific employee</b-form-radio>
              <b-form-radio   name="some-radios" value="B">Multiple employee</b-form-radio>
            </b-form-group>
          </div>
        </div>
        <div class="sp-gm-if">
          <label class="gm-hrm-label">Assigned to</label>
          <div class="dd-gm-container">
            <b-dropdown no-caret class="gm-mainBorderfordropi">
              <template #button-content>
                <div class="gm-button-content-dropi">
                  <div class="gm-dropi-contenti">Assigned to</div>
                  <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                </div>
              </template>
              <b-dropdown-item class="dropi-dropdown-toggle">All</b-dropdown-item>
              <b-dropdown-item class="dropi-dropdown-toggle">Single</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Title</label>
          <b-form-input list="input-list"  placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Description (optional)</label>
          <b-form-textarea
            id="textarea-small"
            size="sm"
            placeholder="Small textarea"
            class="gm-hrm-textnote">
          </b-form-textarea>
        </div>
        <div class="">
          <label for="Date of birth" class="gm-hrm-label">Daedline (optional)</label>
          <b-form-datepicker
            id="datepicker-buttons"
            class="gm-hrm-date"
            locale="en">
          </b-form-datepicker>
          <svg xmlns="http://www.w3.org/2000/svg" width="17.5" height="20" viewBox="0 0 17.5 20" class="svg-img-calender">
            <path id="Icon_awesome-calendar-alt" data-name="Icon awesome-calendar-alt" d="M0,18.125A1.875,1.875,0,0,0,1.875,20h13.75A1.875,1.875,0,0,0,17.5,18.125V7.5H0Zm12.5-7.656A.47.47,0,0,1,12.969,10h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,12.969,15h1.563a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H12.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,7.969,10H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,7.969,15H9.531a.47.47,0,0,1,.469.469v1.563a.47.47,0,0,1-.469.469H7.969a.47.47,0,0,1-.469-.469Zm-5-5A.47.47,0,0,1,2.969,10H4.531A.47.47,0,0,1,5,10.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469Zm0,5A.47.47,0,0,1,2.969,15H4.531A.47.47,0,0,1,5,15.469v1.563a.47.47,0,0,1-.469.469H2.969a.47.47,0,0,1-.469-.469ZM15.625,2.5H13.75V.625A.627.627,0,0,0,13.125,0h-1.25a.627.627,0,0,0-.625.625V2.5h-5V.625A.627.627,0,0,0,5.625,0H4.375A.627.627,0,0,0,3.75.625V2.5H1.875A1.875,1.875,0,0,0,0,4.375V6.25H17.5V4.375A1.875,1.875,0,0,0,15.625,2.5Z" fill="#5576d1"/>
          </svg>
        </div>
        <div class="sp-gm-if mar-top-hrm20">
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Require attachment</span>
          </b-form-checkbox>
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Notify me by email when this task is completed</span>
          </b-form-checkbox>
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Notify employee by e-mail</span>
          </b-form-checkbox>
        </div>
        <button class="btn-primarynewupdated1 mt-4 mar-bottom-hrm50">Add</button>
      </form>
    </modal>
</template>
<script>
export default {
  name: 'HRMAddNewTaskModal',
}
</script>

<style>

</style>