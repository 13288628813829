export const colors = [
  'rgba(0,0,0,0)',
  'rgba(220,38,38,1)',
  'rgba(245,158,11,1)',
  'rgba(16,185,129,1)',
  'rgba(37,99,235,1)',
  'rgba(79,70,229,1)',
  'rgba(124,58,237,1)',
  // 'rgba(255,255,255,1)',
  'rgba(0,0,0,1)',
  'rgba(110,131,165,1)',
  'rgba(255,114,88,1)',
  'rgba(20,190,185,1)',
  'rgba(85,118,209,1)',
  'rgba(0,194,103,1)',
  'rgba(230,76,60,1)'
]