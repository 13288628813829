var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": "EditExtensionModal"
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form lg-mar-bottom",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Edit extension")]), _vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('EditExtensionModal');
      }
    }
  }) : _vm._e()], 1)]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Edit extension")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('EditExtensionModal');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Extension")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_extension.extn,
      expression: "forms.update_extension.extn"
    }],
    staticClass: "w-100",
    attrs: {
      "id": "edit-extension-name-input-field",
      "disabled": _vm.api_sent.update,
      "type": "text"
    },
    domProps: {
      "value": _vm.forms.update_extension.extn
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_extension, "extn", $event.target.value);
      }
    }
  }), _vm.is_submitted.update ? [!_vm.$v.forms.update_extension.extn.required ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [_c('span', [_vm._v("* Extn is required")])]) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "w-100 mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api_sent.update
    }
  }, [_vm.api_sent.update ? _c('vb-spinner') : [_vm._v("Update")]], 2)])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }