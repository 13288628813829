<template>
  <div>
    <div class="mainHeading withButton">
      Awaiting approval
    </div>
    <div class="mainDescription mb-4">
      <br/>
    </div>
    <div class="hrmTabsContainer pt-3">
      <div v-for="tab in tabs" :key="tab.id" :class="`hrmTabsButton ${selectedTab.id == tab.id ? 'active' : ''} mr-16px`" @click="selected_tab = tab">{{ tab.text }}</div>
      
    </div>
    <div class="mt-32px">
      <template v-if="selectedTab.value == 'Active'">
        <div class="mainDescription mb-0">
          <br/>
        </div>
        <div class="AwaitingApproval mt-32px">
          <div class="HrmTableHead-container">
            <div class="HrmTableHead one">Name</div>
            <div class="HrmTableHead two">reason</div>
            <div class="HrmTableHead three">Start date</div>
            <div class="HrmTableHead four">end date</div>
            <div class="HrmTableHead five">Actions</div>
          </div>
          <vb-table class="HrmTable" :isListEmpty="isEmpty(filterActiveLeave)" 
            :listLength="filterActiveLeave.length" 
            :loading="api.active.send" 
          >
            
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterActiveLeave" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title one">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-row-title two">
                  {{ data.reason }}
                </td>
                <td class="dialer-row-title three">{{ data.startDate }}</td>
                <td class="dialer-row-title four">05/09/2022{{ data.endDate }}</td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      {{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
          
        </div>
      </template>
      <template v-if="selectedTab.value == 'Previous'">
        <div class="mainDescription mb-0">
          <br/>
        </div>
        <div class="AwaitingApproval mt-32px">
          <div class="HrmTableHead-container">
            <div class="HrmTableHead one">Name</div>
            <div class="HrmTableHead two">reason</div>
            <div class="HrmTableHead three">Start date</div>
            <div class="HrmTableHead four">end date</div>
            <div class="HrmTableHead five">Actions</div>
          </div>
          <vb-table class="HrmTable" :isListEmpty="isEmpty(filterPreviousLeave)" 
            :listLength="filterPreviousLeave.length" 
            :loading="api.previous.send" 
          >
            
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterPreviousLeave" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title one">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-row-title two">
                  {{ data.reason }}
                </td>
                <td class="dialer-row-title three">{{ data.startDate }}</td>
                <td class="dialer-row-title four">{{ data.endDate }}</td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      {{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
          <!-- <div class="dialer-input-field input-consistent-inner-shadows sm-mar-left seachBox">
            <b-input type="text" placeholder="Search user" v-model.lazy="filter.previous.search"/>
          </div> -->
          <!-- <vb-table
            :isListEmpty="isEmpty(filterPreviousLeave)" 
            :listLength="filterPreviousLeave.length" 
            :loading="api.previous.send" 
          >
            <template slot="header">
              <tr>
                <th class="dialer-has-sort">
                  <span>Name</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Reason</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Start date</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>end date</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Actions</span>
                </th>
              </tr>
            </template>
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterPreviousLeave" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title"><div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-col-right">
                  <div class="circleWithText">
                    <div class="circleContainer" style="background-color:#D5761B"></div>
                    <div class="circleWithText-text" style="color:#D5761B">{{ data.reason }}</div>
                  </div>
                </td>
                <td class="dialer-col-right">{{ data.startDate }} </td>
                <td class="dialer-col-right">{{ data.endDate }} </td>
                <td class="dialer-row-title eight">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      {{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table> -->
        </div>
      </template>
      <template v-if="selectedTab.value == 'Future'">
        <div class="mainDescription mb-0">
          <br/>
        </div>
        <div class="AwaitingApproval mt-32px">
          <div class="HrmTableHead-container">
            <div class="HrmTableHead one">Name</div>
            <div class="HrmTableHead two">reason</div>
            <div class="HrmTableHead three">Start date</div>
            <div class="HrmTableHead four">end date</div>
            <div class="HrmTableHead five">Actions</div>
          </div>
          <vb-table class="HrmTable" :isListEmpty="isEmpty(filterFutureLeave)" 
            :listLength="filterFutureLeave.length" 
            :loading="api.future.send" 
          >
            <!-- <template slot="header">
              <tr><th class="dialer-has-sort"></th></tr>
            </template> -->
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterFutureLeave" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title one">
                  <div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-row-title two">
                  {{ data.reason }}
                </td>
                <td class="dialer-row-title three">{{ data.startDate }}</td>
                <td class="dialer-row-title four">{{ data.endDate }}</td>
                <td class="dialer-row-title five">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      {{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table>
          <!-- <div class="dialer-input-field input-consistent-inner-shadows sm-mar-left seachBox">
            <b-input type="text" placeholder="Search user" v-model.lazy="filter.future.search"/>
          </div>
          <vb-table
            :isListEmpty="isEmpty(filterFutureLeave)" 
            :listLength="filterFutureLeave.length" 
            :loading="api.future.send" 
          >
            <template slot="header">
              <tr>
                <th class="dialer-has-sort">
                  <span>Name</span>
                </th>
                <th class="dialer-has-sort">
                  <span>Reason</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Start date</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>end date</span>
                </th>
                <th class="dialer-has-sort dialer-col-right">
                  <span>Actions</span>
                </th>
              </tr>
            </template>
            <template #body="{ start, end }">
              <tr class="dialer-row-select" v-for="(data, index) in filterFutureLeave" v-show="index >= start && index <= end" :key="data.user">
                <td class="dialer-row-title"><div class="nameWithImage">
                    <div class="imageContainer">
                      <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                    </div>
                    <div class="nameWithImage-text">{{ data.name }}</div>
                  </div>
                </td>
                <td class="dialer-col-right">
                  <div class="circleWithText">
                    <div class="circleContainer" style="background-color:#D5761B"></div>
                    <div class="circleWithText-text" style="color:#D5761B">{{ data.reason }}</div>
                  </div>
                </td>
                <td class="dialer-col-right">{{ data.startDate }} </td>
                <td class="dialer-col-right">{{ data.endDate }} </td>
                <td class="dialer-row-title eight">
                  <b-dropdown no-caret right class="ActionsDD">
                    <template #button-content>
                      <div class="button-content">
                        <b-icon class="downIcon" icon="three-dots-vertical" />
                      </div>
                    </template>
                    <b-dropdown-item >
                      {{ data.actions }}
                    </b-dropdown-item>
                  </b-dropdown>
                </td>
              </tr>
            </template>
          </vb-table> -->
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { $fn } from "@/utils";
import { HRM } from '@/data'
let tabs = [
  {
    id: 1,
    text: 'Active',
    value: 'Active'
  },
  {
    id: 2,
    text: 'Previous',
    value: 'Previous'
  },
  {
    id: 3,
    text: 'Future',
    value: 'Future'
  }
]
export default {
  name: 'LongTermLeave',
  components: {
  },
  data(){
    return {
      selected_tab: tabs[0],
      response: {
        active: [],
        previous: [],
        future: []
      },
      filter: {
        active: {
          search: '',
          direction: '',
        },
        previous: {
          search: '',
          direction: '',
        },
        future: {
          search: '',
          direction: '',
        }
      },
      api: {
        active: this.$helperFunction.apiInstance({ error_message: true }),
        previous: this.$helperFunction.apiInstance({ error_message: true }),
        future: this.$helperFunction.apiInstance({ error_message: true }),
      },
    }
  },
  inject:['isEmpty'],
  computed: {
    tabs() { return tabs },
    selectedTab(){ return tabs.find(tab=>tab.id==this.selected_tab.id) },
    filterActiveLeave(){
      const search = this.filter.active.search.trim().toLowerCase()
      // const direction = this.filter.rules.direction
      const rules = this.response.active
      const filter_active = rules.filter(item=>
        `${(item.name || '').replace(/-/g,'')} ${item.reason}`.trim().toLowerCase().includes(search)
      )
      return filter_active
    },
    filterPreviousLeave(){
      const search = this.filter.previous.search.trim().toLowerCase()
      // const direction = this.filter.rules.direction
      const rules = this.response.previous
      const filter_previous = rules.filter(item=>
        `${(item.name || '').replace(/-/g,'')} ${item.policy}`.trim().toLowerCase().includes(search)
      )
      return filter_previous
    },
    filterFutureLeave(){
      const search = this.filter.future.search.trim().toLowerCase()
      // const direction = this.filter.rules.direction
      const rules = this.response.future
      const filter_future = rules.filter(item=>
        `${(item.name || '').replace(/-/g,'')} ${item.policy}`.trim().toLowerCase().includes(search)
      )
      return filter_future
    }
  },
  methods: {
    fetchActiveLeave(){
      // console.log("awaiting is called")
      let vm = this;
      if(vm.api.active.send) return;
      vm.api.active.send=true
      $fn.sleep(2 * 1000)
      .then(()=>{
        // console.log("HRM Data", HRM.activelongtermleave)
        vm.response.active = HRM.activelongtermleave
      })
      .catch((/* err */)=>{
        // console.log("error", err)
      }).finally(()=> { vm.api.active.send = false })
    },
    fetchPreviousLeave(){
      // console.log("resubmission is called")
      let vm = this;
      if(vm.api.previous.send) return;
      vm.api.previous.send=true
      $fn.sleep(2 * 1000)
      .then(()=>{
        // console.log("HRM Data", HRM.previouslongtermleave)
        vm.response.previous = HRM.previouslongtermleave
      })
      .catch((/* err */)=>{
        // console.log("error", err)
      }).finally(()=> { vm.api.previous.send = false })
    },
    fetchFutureLeave(){
      // console.log("replacement is called")
      let vm = this;
      if(vm.api.future.send) return;
      vm.api.future.send=true
      $fn.sleep(2 * 1000)
      .then(()=>{
        // console.log("HRM Data", HRM.futurelongtermleave)
        vm.response.future = HRM.futurelongtermleave
      })
      .catch((/* err */)=>{
        // console.log("error", err)
      }).finally(()=> { vm.api.future.send = false })
    }
  },
  mounted(){
    this.fetchActiveLeave();
    this.fetchPreviousLeave();
    this.fetchFutureLeave();
  }
}
</script>

<style>

</style>