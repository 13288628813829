import { VOIP_API } from "../index"

export const TASK_SPACES_API_ENDPOINTS = {
  checkName(params={}){
    return VOIP_API.axios.voip.request({
      url: `check-exit-title`,
      method: 'GET',
      params,
    })
  },
  updateMember(data={}){
    return VOIP_API.axios.voip.request({
      url: "space-member",
      method: "POST",
      data,
    })
  },
  list(params={},token){
    return VOIP_API.axios.voip.request({
      url: "space",
      method: "GET",
      params,
      cancelToken: token,
    })
  },
  remove(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `space/${id}`,
      method: "DELETE",
      data,
    })
  },
  get(id='',params={}){
    return VOIP_API.axios.voip.request({
      url: `space/${id}`,
      method: "GET",
      params,
    })
  },
  create(data={}){
    return VOIP_API.axios.voip.request({
      url: "create-space",
      method: "POST",
      data,
    })
  },
  update(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `update-space/${id}`,
      method: "POST",
      data,
    })
  },
}