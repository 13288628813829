
import { VOIP_API } from "../index"

export const DEPARTMENTS_API_ENDPOINTS = {
  list(params){
    return VOIP_API.axios.voip.request({
      url: "departments",
      method: "GET",
      params,
    })
  },
}