var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup addCardModal SetCardPriorityModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-box mb-4 table"
  }, [_c('div', {
    staticClass: "dialer-edit-header mt-3"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Select card")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])], 1)]), _c('div', {
    staticClass: "d-flex justify-content-end mt-4 mb-3"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('PaymentDetailModal');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-3",
    attrs: {
      "icon": "plus",
      "scale": "2"
    }
  }), _vm._v(" Add new card ")], 1)]), [1, 0].includes(_vm.conditions.card_select_show) ? _c('div', {
    staticClass: "dialer-box alt p-4"
  }, [_c('div', {
    staticClass: "dialer-box-title"
  }, [_vm._v("Select primary card")]), _vm._l(_vm.availableprimarycards, function (card) {
    return [_c('b-form-checkbox', {
      key: card.real_id,
      ref: `make_primary_${card.real_id}`,
      refInFor: true,
      staticClass: "dialer_full_width_checkbox border-b circle_btn_checkbox circle_btn_onRight mt-4 big-circle-checkbox3 makeCheckBoxesSquare",
      attrs: {
        "aria-disabled": _vm.api_sent.set || !!_vm.api_sent.primary,
        "disabled": _vm.api_sent.set || !!_vm.api_sent.primary || _vm.api_sent.secondary == card.real_id || _vm.selected.primary == card.real_id,
        "checked": _vm.selected.primary == card.real_id
      },
      on: {
        "change": function ($event) {
          $event ? _vm.makePrimary(card.real_id) : '';
        }
      }
    }, [_c('div', {
      staticClass: "dialer-flex justify-content-start align-items-start mr-3 cursor_pointer"
    }, [_c('img', {
      staticClass: "dialer-payment-logo",
      attrs: {
        "src": require('@/assets/images/visa.svg')
      }
    }), _c('div', {
      staticClass: "ml-2"
    }, [_c('div', {
      staticClass: "dialer-box-header d-flex align-items-center mb-0"
    }, [_vm._v(" " + _vm._s(card.label) + " "), _vm.selected.primary == card.real_id ? _c('div', {
      staticClass: "ml-2 py-1 px-3 card-tag bg-primary"
    }, [_vm._v("Primary")]) : _vm._e()]), _c('div', {
      staticClass: "dialer-box-text d-flex flex-column"
    }, [_c('span', {
      staticClass: "line-height-1",
      staticStyle: {
        "letter-spacing": "1px"
      }
    }, [_vm._v("xxxx xxxx xxxx " + _vm._s(card.digits))]), _c('span', {
      staticClass: "line-height-1 mt-1"
    }, [_vm._v("Expiration date: " + _vm._s(`${card.expiry_month}/${card.expiry_year}`))])])])])]), _c('div', {
      key: `delete_${card.real_id}`,
      staticClass: "trashButtonContainer"
    }, [_vm.api_sent.delete == card.real_id ? _c('vb-spinner') : _c('button', [_c('b-icon', {
      attrs: {
        "icon": "trash",
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteCard(card.real_id);
        }
      }
    })], 1)], 1)];
  }), _vm.isEmpty(_vm.availableprimarycards) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api_sent.cards ? '' : 'Sorry, No Data Found',
      "design": 3
    }
  }, [_vm.api_sent.cards ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2) : _vm._e(), [2, 0].includes(_vm.conditions.card_select_show) ? _c('div', {
    staticClass: "dialer-box alt p-4"
  }, [_c('div', {
    staticClass: "dialer-box-title"
  }, [_vm._v("Select secondary card")]), _vm._l(_vm.availablesecondarycards, function (card) {
    return [_c('b-form-checkbox', {
      key: card.real_id,
      ref: `make_secondary_${card.real_id}`,
      refInFor: true,
      staticClass: "dialer_full_width_checkbox border-b circle_btn_checkbox circle_btn_onRight mt-4 big-circle-checkbox3 makeCheckBoxesSquare",
      attrs: {
        "aria-disabled": _vm.api_sent.set || !!_vm.api_sent.secondary,
        "disabled": _vm.api_sent.set || !!_vm.api_sent.secondary || _vm.api_sent.primary == card.real_id || _vm.selected.secondary == card.real_id,
        "checked": _vm.selected.secondary == card.real_id
      },
      on: {
        "change": function ($event) {
          $event ? _vm.makeSecondary(card.real_id) : '';
        }
      }
    }, [_c('div', {
      staticClass: "dialer-flex justify-content-start align-items-start mr-3 cursor_pointer"
    }, [_c('img', {
      staticClass: "dialer-payment-logo",
      attrs: {
        "src": require('@/assets/images/visa.svg')
      }
    }), _c('div', {
      staticClass: "ml-2"
    }, [_c('div', {
      staticClass: "dialer-box-header d-flex align-items-center mb-0"
    }, [_vm._v(" " + _vm._s(card.label) + " "), _vm.selected.secondary == card.real_id ? _c('div', {
      staticClass: "ml-2 py-1 px-3 card-tag bg-primary"
    }, [_vm._v("Secondary")]) : _vm._e()]), _c('div', {
      staticClass: "dialer-box-text d-flex flex-column"
    }, [_c('span', {
      staticClass: "line-height-1",
      staticStyle: {
        "letter-spacing": "1px"
      }
    }, [_vm._v("xxxx xxxx xxxx " + _vm._s(card.digits))]), _c('span', {
      staticClass: "line-height-1 mt-1"
    }, [_vm._v("Expiration date: " + _vm._s(`${card.expiry_month}/${card.expiry_year}`))])])])])]), _c('div', {
      key: `delete_${card.real_id}`,
      staticClass: "trashButtonContainer"
    }, [_vm.api_sent.delete == card.real_id ? _c('vb-spinner') : _c('button', [_c('b-icon', {
      attrs: {
        "icon": "trash",
        "variant": "primary"
      },
      on: {
        "click": function ($event) {
          return _vm.deleteCard(card.real_id);
        }
      }
    })], 1)], 1)];
  }), _vm.isEmpty(_vm.availablesecondarycards) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api_sent.cards ? '' : 'Sorry, no data found',
      "design": 3
    }
  }, [_vm.api_sent.cards ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2) : _vm._e(), _c('PaymentDetailModal')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }