<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup scrollable dialer-analytics-user-modal" 
      width="60%" 
      height="auto" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @opened="onOpen($event)" 
      @before-close="onBeforeClose($event)"
    >
      <div class="dialer-edit-header pb-0">
        <div>
          <h2 class="dialer-settings-title newer mb-0">User analytics</h2>
        </div>
        <div class="dialer-edit-actions">
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
          <a v-else class="newCloseButton" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="dialer-analytics-user dialer-analytics-tray">
        <div class="analytics-newHeader mb-20px">
          <div class="analytics-newHeader-leftSide">
            <h1 class="dialer-settings-title newer mb-0">{{ data.user.extn }} {{ data.user.name }}</h1>
          </div>
          <div class="analytics-newHeader-rightSide">
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterWithText mr-16px">
              <b-dropdown varient="link" no-caret class="ico-filter" right> 
                <template #button-content>
                  <span class="mr-12px" v-if="filter.stat.date">{{ filter.stat.date }}</span>
                  <b-icon icon="three-dots-vertical" variant="dark" class="fillBlack" scale="1.5" />
                </template>
                <b-dropdown-item v-for="tab in tabs" :key="tab.value" @click="selected.tab = tab.value;selected.tab == 'custom' ? $modal.show(`${_uid}-dateFilterModal_insideModal`) : fetchstat()" >
                  <vb-icon icon="markAsComplete-icon" :style="`opacity:${selected.tab === tab.value ? '1' : '0'}`" class="mr-12px" width="14.49px" height="10.431px"/>
                  <span >{{tab.text}}</span>
                </b-dropdown-item>
              </b-dropdown>
            </div>
          </div>
        </div>
        <div class="latestGreyBox-9-9-2023">
          <div class="d-flex align-items-center justify-content-between flex-wrap w-100">
            <div class="d-flex flex-column mb-3">
              <div class="latestGreyBox-heading-main">{{ filter.stat.date }}</div>
              <div class="latestGreyBox-descriptionText">Success RATE: {{ response.stat.successRate }} %</div>
            </div>
          </div>
          <div class="dialer-analytics-totals v2 updatedAgain mt-20px">
            <div class="dialer-call-total dialer-total">
              <div class="dialer-call-total-inner">
                <div class="dialer-call-total-top">
                  <div class="dialer-call-type">
                    <vb-icon icon="CallSummary-TotalCall-icon"/>
                    <div>total Calls</div>
                  </div>
                </div>
                <div class="dialer-call-figures">
                  <h4 class="dialer-call-number">{{ callpercentage.total }}</h4>
                </div>
              </div>
            </div>
            <div class="dialer-call-total dialer-missed">
              <div class="dialer-call-total-inner">
                <div class="dialer-call-total-top">
                  <div class="dialer-call-type">
                    <vb-icon icon="CallSummary-MissedCall-icon"/>
                    <div>missed Calls</div>
                  </div>
                  <span class="dialer-call-total-time"></span>
                </div>
                <div class="dialer-call-figures">
                  <h4 class="dialer-call-number">{{ callpercentage.missed }}</h4>
                  <div class="newProgressBar">
                    <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${callpercentage.missed_percentage};--fg:#dc3545`">
                      <div class="newProgressBar-inner">
                        {{callpercentage.missed_percentage}}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dialer-call-total dialer-inbound">
              <div class="dialer-call-total-inner">
                <div class="dialer-call-total-top">
                  <div class="dialer-call-type">
                    <vb-icon icon="CallSummary-InboundCall-icon"/>
                    <div>Receive Calls</div>
                  </div>
                  <span class="dialer-call-total-time"></span>
                </div>
                <div class="dialer-call-figures">
                  <h4 class="dialer-call-number">{{ callpercentage.received }}</h4>
                  <div class="newProgressBar">
                    <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${callpercentage.received_percentage};--fg:#28a745`">
                      <div class="newProgressBar-inner">
                        {{callpercentage.received_percentage}}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div class="dialer-call-total dialer-outbound">
              <div class="dialer-call-total-inner">
                <div class="dialer-call-total-top">
                  <div class="dialer-call-type">
                    <vb-icon icon="CallSummary-OutboundCall-icon"/>
                    <div>outbound Calls</div>
                  </div>
                </div>
                <div class="dialer-call-figures">
                  <h4 class="dialer-call-number">{{ callpercentage.outgoing }}</h4>
                  <div class="newProgressBar">
                    <div role="progressbar" aria-valuenow="65" aria-valuemin="0" aria-valuemax="100" :style="`--value:${callpercentage.outgoing_percentage};--fg:#5576d1`">
                      <div class="newProgressBar-inner">
                        {{callpercentage.outgoing_percentage}}%
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div style="width: 100%; overflow: hidden" class="visavail mt-20px" :id="`conatiner-measure-${data.accountcode}`">
            <p :id="`p-measure-${data.accountcode}`" style="overflow: hidden"></p>
          </div>
          <div class="d-flex align-items-center justify-content-between w-100">
            <div class="latestGreyBox-heading-main">{{ filter.stat.date }}</div>
            <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-25">
              <b-dropdown varient="link" no-caret>
                <template v-slot:button-content>
                  <span class="mr-3">Filter</span>
                  <b-icon icon="chevron-down" />
                </template>
                <b-dropdown-text>
                  <ul class="d-flex flex-column list-unstyled mb-0">
                    <li v-for="calltype in calltypes" :key="calltype.value" class="d-inline-flex px-1 justify-content-between align-items-center">
                      <label class="mb-0">{{calltype.text}}</label>
                      <b-form-radio class="before-after-checkbox-align-center" v-model="filter.stat.call_type" name="some-radios" :value="calltype.value"></b-form-radio>
                    </li>
                  </ul>
                </b-dropdown-text>
              </b-dropdown>
            </div>
          </div>
          <div class="mt-20px whiteBoxWithBorderInsideGreyBox">
            <template v-if="api.stat.send">
              <div class="whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset" v-for="n in 4" :key="n.id">
                <div class="d-flex align-items-center">
                  <div class="latestShimmerDesign mr-2" style="height:40px;width:40px;"></div>
                  <div class="d-flex flex-column">
                    <div class="latestShimmerDesign" style="height:22px;width:150px;"></div>
                    <div class="latestShimmerDesign mt-1" style="height:19px;width:100px;"></div>
                  </div>
                </div>
                <div class="latestShimmerDesign" style="height:22px;width:100px;"></div>
              </div>
            </template>
            <vb-no-record v-else-if="isEmpty(filterStat)" class="my-5" :text="'There is no Call Logs'" :design="3" />
            <template v-else>
              <div class="whiteBoxWithBorderInsideGreyBox-row py-3 min-height-unset" v-for="(call, key) in filterStat" :key="key">
                <div class="d-flex align-items-center">
                  <img class="mr-3" width="25px" height="25px" :src="call | callImage(getCurrentUser.account)" alt />
                  <div class="d-flex flex-column">
                    <div class="latestGreyBox-descriptionText mt-0 text-black">{{ call | callName }}</div>
                    <div class="" style="font-size:14px;">{{ call.calldate }}</div>
                  </div>
                </div>
                <div class="latestGreyBox-descriptionText mt-0">{{ call.duration | time_audio }}</div>
              </div>
            </template>
          </div>
        </div>
      </div>
    </modal>
    <modal :name="`${_uid}-dateFilterModal_insideModal`" class="callReportingFilterModal">
      <div class="dialer-edit-header pt-0 position-relative">
        <div>
          <h2 class="dialer-settings-title newer mb-0">Filters</h2>
        </div>
        <div class="dialer-edit-actions">
          <button class="newDoneButton" type="button" @click="$modal.hide(`${_uid}-dateFilterModal_insideModal`)">
            <vb-icon icon="squared-done-icon" height="38px" width="38px" />
            <span class="newDoneButton-textPart">Done</span>
          </button>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023">
        <div class="analytics-newHeader-text">
          <div class="d-flex customDate">
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionLeftAligned w-50 pr-2">
              <span>From:</span>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.stat.start_date" id="starttime" />
            </div>
            <div class="whiteBGinputWithGreyRoundedBorder VueCtkDateTimePicker subSectionRightAligned w-50 pl-2">
              <span>To:</span>
              <vue-ctk-date-time-picker v-bind="ctkDateTimePickerOptions" class="date-time-input" v-model="filter.stat.end_date" id="endtime" />
            </div>
          </div>
          <div class="w-100 d-flex justify-content-end mt-20px">
            <a class="newButton" @click="fetchstat();$modal.hide(`${_uid}-dateFilterModal_insideModal`)">
              <vb-icon icon="squared-browse-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Fetch</span>
            </a>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import * as visavail from "visavail";
import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
import { ctkDateTimePicker } from '../../mixin';
let tabs = {
  today: {
    text: "Today",
    value: "today",
    icon: 'squared-today-icon'
  },
  week: {
    text: "This week",
    value: "week",
    icon: 'squared-thisWeek-icon'
  },
  month: {
    text: "This month",
    value: "month",
    icon: 'squared-thisMonth-icon'
  },
  custom: {
    text: "Custom",
    value: "custom",
    icon: 'squared-customDate-icon'
  },
}
let call_types = [
  {
    text: "All",
    value: "all",
  },
  {
    text: "Outgoing calls",
    value: "outgoing",
  },
  {
    text: "Incoming calls",
    value: "incoming",
  },
  {
    text: "Missed calls",
    value: "missed",
  },
]
export default {
  name: "NumberAnalyticsModal",
  mixins: [ctkDateTimePicker],
  components: {
  },
  inject:['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'NumberAnalyticsModal',
    },
  },
  data() {
    return {
      response: this.$helperFunction.stateInstance({
        data : {
          stat: {},
        }
      }),
      selected: this.$helperFunction.stateInstance({
        data: {
          tab: 'today'
        }
      }),
      filter: {
        stat: this.$helperFunction.stateInstance({
          data: {
            call_type: 'all',
            start_time: null,
            end_date: null,
            date: null,
          }
        }),
        reset(){
          this.stat?.reset?.()
        },
      },
      data: this.$helperFunction.stateInstance({
        data: {
          time_line: {},
          user: {},
          accountcode: '',
        }
      }),
      api: {
        stat: this.$helperFunction.apiInstance(),
        reset(){
          this.stat?.reset?.()
        },
      },
    };
  },
  filters: {
    callImage(value){
      if(value.type=='Missed') return require("@/assets/images/missed_call_icon.svg");
      else if(value.type=='Received') return require("@/assets/images/received_call_icon.svg");
      else return require("@/assets/images/dialed_call_icon.svg");
    },
    callName(value){
      if(value.type=='Missed') return value.caller;
      else if(value.type=='Received') return value.caller;
      else return value.callee;
    },
    formatDate(value) {
      if (!value) return "-";
      var ts = new Date(value);
      return ts.toDateString();
    },
    name(value) {
      if (!value) return "-";
      return value.extn;
    },
    timecode(value) {
      var seconds = Math.floor(value % 60).toString();
      var minutes = Math.floor(value / 60).toString();
      if (seconds.length === 1) {
        seconds = "0" + seconds;
      }
      return minutes + ":" + seconds;
    },
  },
  computed: {
    ...mapGetters(['getCurrentUser']),
    tabs(){ return tabs },
    calltypes(){ return call_types },
    filterStat() {
      const call_type = this.filter.stat.call_type
      const array = this.response.stat.callLogs || []
      return array.filter(item=>{
        if(call_type=='all') return true;
        else if(call_type=='outgoing' && item.type=='Outgoing') return true;
        else if(call_type=='incoming' && item.type=='Received') return true;
        else if(call_type=='missed' && item.type=='Missed') return true;
        return false
      })
    },
    callpercentage(){
      const total = this.response.stat.totalCalls || 0 //  || this.data.user.totalCalls
      const received = this.response.stat.totalReceivedCalls || 0 //  || this.data.user.totalReceivedCalls
      const missed = this.response.stat.totalMissCalls || 0 //  || this.data.user.totalMissCalls
      const outgoing = this.response.stat.totalOutgoingCalls || 0 //  || this.data.user.totalOutgoingCalls
      const received_percentage = received > 0 ? Math.ceil((received / total) * 100) : 0;
      const outgoing_percentage = outgoing > 0 ? Math.ceil((outgoing / total) * 100) : 0;
      const missed_percentage = missed > 0 ? Math.ceil((missed / total) * 100) : 0;
      return {
        total,
        received,
        outgoing,
        missed,
        received_percentage,
        outgoing_percentage,
        missed_percentage,
      };
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.user = event.params.user;
      this.data.time_line = event.params.time_line;
      this.data.accountcode = event.params.accountcode;
      this.fetchstat();
    },
    onOpen(){
      const extensions = this.data.time_line?.exten?.map?.(item=>{
        return {
          ...item,
          measure: item.accountname.includes('extension') ? item.accountname.replace('extension','app') : item.accountname=='WEBEXT' ? 'web app' : item.accountname=='DESKTOPEXT' ? 'desktop app' : item.accountname=='MOBILEEXT' ? 'mobile app' : item.accountname=='HEADEXT' ? 'headset app' : 'web app'
        }
      }) ?? []
      if(this.data.time_line){
        extensions.unshift({
          accountname: this.data.accountcode,
          data: this.data.time_line.data,
          interval_s: this.data.time_line.interval_s,
          measure: "User",
        })
      }
      visavail.generate({
        id_div_container: `conatiner-measure-${this.data.accountcode}`,
        id_div_graph: `p-measure-${this.data.accountcode}`,
        date_in_utc: false,
        width: document.getElementById(`p-measure-${this.data.accountcode}`).offsetWidth,
        line_spacing: 24,
        title: {
          // text: "Phone Status Timeline",
          text: "Phone Status",
        },
        icon: {
          class_has_data: "fas fa-fw fa-check",
          class_has_no_data: "fas fa-fw fa-exclamation-circle",
        },
        tooltip: {
          height: 18,
          position: "overlay",
          left_spacing: 20,
          only_first_date: true,
          date_plus_time: true,
        },
        responsive: {
          enabled: true,
        },
      },extensions);
    },
    onBeforeClose(){
      this.response.reset()
      this.selected.reset()
      this.filter.reset()
      this.data.reset()
      this.api.reset()
    },
    fetchstat(){
      let vm = this
      if(vm.api.stat.send) return;
      if(vm.selected.tab=='custom' && (!vm.filter.stat.start_date || !vm.filter.stat.end_date)){
        vm.response.stat={}
        return;
      }
      vm.filter.stat.call_type='all'
      vm.api.stat.send=true
      VOIP_API.endpoints.analytics.singlestat({
        type: vm.selected.tab,
        accountcode: vm.data.accountcode,
        start: vm.filter.stat.start_date,
        end: vm.filter.stat.end_date,
      }).then(({ data: { response: { data: stat, date_label } } }) => {
        vm.response.stat = stat;
        vm.filter.stat.date = date_label
      }).catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.stat.send=false
      });
    },
  },
};
</script>

<style scoped>
@import "../../../node_modules/visavail/visavail.min.css";
.allow_scroll {
  height: calc(100vh - 460px);
  overflow-y: auto;
  margin: 10px 0px;
}
.dialer-dropdown .dropdown .dropdown-menu {
  border: 0;
  box-shadow: 0 2px 20px 0 rgba(0, 0, 0, 0.21);
  width: 100%;
  min-width: 580px;
  line-height: normal;
  padding: 10px;
  transform: none;
  left: unset;
  right: 0;
  top: 100%;
}
.dialer-dropdown .dropdown .dropdown-menu li ul {
  box-shadow: none;
}
.dialer-dropdown .dropdown .b-dropdown-text {
  padding: 0px;
}
</style>
