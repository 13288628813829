<template>
  <div>
    <div class="mainHeading withButton">
      Accountant permissions
    </div>
    <div class="mainDescription mb-4">
      Employees in this group can access time off reports
    </div>
    <div class="grayCard flexColumn mt-32px">
      <!-- <div class="grayCard-heading">Enabled for everyone:</div> -->
      <div class="grayCard-description mt-16px">
        Current employees with accountant access
      </div>
      <div class="innerShadowInput-container mt-32px">
        <label class="">Accountants</label>
        <b-dropdown class="hrmInnerShadow-dd w-536px" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText">All Accountants</div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            All Accountants
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <b-form-checkbox class="HrmCheckBox-container mt-16px">
        Give access to employee time off data
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Give access to employee compensation
      </b-form-checkbox>
      <button class="dialer-button dialer-button-primary w-fit-content mt-32px mr-0"> Save </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'PermissionsAccountants',
}
</script>

<style>

</style>