var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup billingAllNumbersModal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-box table"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Numbers")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])], 1)]), _c('div', {
    staticClass: "mt-5"
  }, [_c('vb-table', {
    staticClass: "plan-license-table dialer-table dialer-table-bg-f3f3f3 px-3",
    attrs: {
      "isListEmpty": _vm.isEmpty(_vm.filterNumbers),
      "listLength": _vm.filterNumbers.length,
      "perPage": 5,
      "loading": _vm.api.numbers.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterNumbers, function (number, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: `${number.id}-${number.object}`,
            staticClass: "dialer-row-select mx-0 px-0"
          }, [_c('td', {
            staticClass: "dialer-row-title pl-0"
          }, [_c('span', {
            staticClass: "display-responsive mr-2"
          }, [_vm._v("DID Number :")]), _c('div', {
            staticClass: "d-flex dialer-numberWithCountry align-items-center"
          }, [_c('span', [_c('img', {
            staticClass: "country-img",
            attrs: {
              "width": "45px",
              "src": _vm._f("flag_icon")(_vm._f("number_formater")(number.number))
            }
          })]), number.object === 'TelephoneNumbers' ? _c('span', {
            staticClass: "ml-2 d-flex flex-column dialer-numberInfo"
          }, [_c('span', {
            staticClass: "m-0 didNumber"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.number)))]), number.label ? _c('small', {
            staticClass: "didNumber-text"
          }, [_vm._v("(" + _vm._s(number.label) + ")")]) : _vm._e(), number.status != 1 ? _c('div', {
            staticClass: "suspendedTag"
          }, [_vm._v("Suspended")]) : _vm._e()]) : _c('span', {
            staticClass: "d-flex flex-column ml-1 dialer-numberInfo"
          }, [_c('span', {
            staticClass: "m-0 didNumber"
          }, [_vm._v(_vm._s(_vm._f("number_formater")(number.number)))])])])]), _c('td', [number.extnType ? _c('Info', {
            attrs: {
              "id": _vm._f("get_property")(number, 'assignedToUsersArr.extn'),
              "name": _vm._f("get_property")(number, 'assignedToUsersArr.label'),
              "sub_info": _vm._f("get_property")(number, 'assignedToUsersArr.extn')
            }
          }) : _vm._e()], 1), _c('td', {
            staticClass: "dialer-has-sort dialer-col-right pr-0"
          }, [number.assignedToUsers && number.assignedToUsers == 'None' && number.status == 1 ? _c('button', {
            staticClass: "dialer-button dialer-button-primary",
            attrs: {
              "disabled": !!_vm.api.delete.send
            },
            on: {
              "click": function ($event) {
                return _vm.deletenumber(number.real_id);
              }
            }
          }, [_vm.api.delete.send == number.real_id ? _c('vb-spinner') : [_vm._v("Remove")]], 2) : _vm._e()])]);
        });
      }
    }])
  }, [_c('tr', {
    staticClass: "border-bottom-0",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort pt-3 pb-3 pl-0 border-radius-16"
  }, [_c('span', {
    staticClass: "border-bottom pb-3 pr-4"
  }, [_vm._v("DID Number")])]), _c('th', {
    staticClass: "dialer-has-sort pt-3 pb-3 pl-0 border-radius-16"
  }, [_c('span', {
    staticClass: "border-bottom pb-3 pr-4"
  }, [_vm._v("Assigned")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right pt-3 pr-0 border-radius-16 pb-3"
  }, [_c('span', {
    staticClass: "border-bottom pb-3 pl-4"
  }, [_vm._v("Action")])])])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }