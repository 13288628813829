
<template>
  <div>
    <div class="mainHeading">Gender report</div>
    <div class="mainDescription mb-4">
      <br/>
    </div>
    <div class="dropdownsContainer">
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All time</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All time
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All teams</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All teams
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All locations</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All locations
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All positions</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All positions
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-center mt-5">
      <canvas id="gender-chart"></canvas>
    </div>
  </div>
</template>

<script>
import { HRM } from '@/data'
import Chart from "chart.js";
export default {
  name: 'Gender',
  data() {
    return {
      genderChart: {}
    }
  },
  methods: {
    async barChart() {
      const ctx = document.getElementById("gender-chart");
      this.genderChart?.destroy?.();
      this.genderChart = new Chart(ctx, {
        type: 'bar',
        data: {
          labels: HRM.charts.gender.labels,
          datasets: [
            {
              label: 'male',
              data: HRM.charts.gender.male,
              backgroundColor: [
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1'
              ],
              hoverBackgroundColor: [
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1'
              ],
              borderColor: [
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1',
                '#5576d1'
              ],
              borderWidth: 1
            },
            {
                label: 'female',
                data: HRM.charts.gender.female,
                backgroundColor: [
                  '#d5761a',
                  '#d5761a',
                  '#d5761a',
                  '#d5761a',
                  '#d5761a',
                  '#d5761a'
                ],
                hoverBackgroundColor:[
                  '#d5761a',
                  '#d5761a',
                  '#d5761a',
                  '#d5761a',
                  '#d5761a',
                  '#d5761a'
                ],
                borderColor: [
                  '#d5761a',
                  '#d5761a',
                  '#d5761a',
                  '#d5761a',
                  '#d5761a',
                  '#d5761a'
                ],
                borderWidth: 1
            },
            {
              label: 'Other',
              backgroundColor: [
                '#a2ace2',
              ],
            }
          ]
        },
        options: {
          lineTension: 1,
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          scales: {
            xAxes: [{
                gridLines: {
                  display:false,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Cities'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
            }],
            yAxes: [{
                gridLines: {
                  // display:false,
                  drawBorder: false,
                },
                scaleLabel: {
                  beginAtZero: true,
                  display: true,
                  labelString: 'Employess'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 4
                }
            }]
          }
        }
      });
      this.genderChart.update();
    },
  },
  mounted(){
    this.barChart()
  }
}
</script>

<style>

</style>