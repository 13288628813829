<template>
  <div v-if="!isEmpty(videoCall)" class="dialer-flex alt dialer-incommingVideoCall-active" @dblclick="$store.state.global_conditions.tab='video-call'">
    <!-- info -->
    <div class="dialer-incommingVideoCall-info d-flex align-items-center">
      <div class=" dialer-caller-dp d-flex align-items-center border-right pr-3 mr-3 " >
        <div>
          <vb-avatar :image="callObjInfo.image" />
        </div>
      </div>
      <div class="dialer-user-bio">
        <h6 class="text-capitalize mb-0"> {{ callObjInfo.name }} </h6>
        <p class="text-capitalize mb-0"> {{ callObjInfo.sub_info }} </p>
        <template v-if="$store.state.calls.outgoing_calls[videoCall.room]">
          <div class="d-flex align-items-center" v-if="videoCall.outgoing && videoCall.outgoing.time" >
            <p class="m-0 pr-3"> Time Left: {{ videoCall.outgoing.time | time_audio }} </p>
          </div>
          <div class="d-flex align-items-center" v-if="videoCall.outgoing && videoCall.outgoing.status" >
            <p class="m-0 pr-3">Status: {{ videoCall.outgoing.status }}</p>
          </div>
        </template>
        <div class="d-flex align-items-center">
          <p class="m-0 pr-3">{{ videoCall.time | time_audio }}</p>
          <b-icon icon="camera-video" />
        </div>
      </div>
    </div>
    <!-- ---- -->
    <!-- acions -->
    <div class="d-flex dialer-incommingVideoCall-close">
      <slot name="switchCalls" />
      <div class="actions-info-container">
        <!-- <div v-if="videoCall.isJoined" class="dialer-dropdown">
          <b-dropdown varient="link" no-caret class="ico-filter during-video-call">
            <template v-slot:button-content>
              <b-icon icon="three-dots" scale="1.3" />
              <span class="sr-only">menu</span>
            </template>
            <b-dropdown-item v-if="!videoCall.is_conference" @click="videoCall.toggleIsMemberOpen()">
              <div class="d-flex align-items-center w-100">
                <span class="icon">
                  <b-icon class="sm-mar-right" scale="0.8" icon="person" />
                </span>
                <span>Add Members</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="videoCall.toggleIsVideo()" >
              <div class="d-flex align-items-center w-100">
                <span class="icon">
                  <b-icon class="sm-mar-right" scale="0.8" :icon="videoCall.isVideo ? 'display' : 'stop-fill'" />
                </span>
                <span>{{ videoCall.isVideo ? "Share Screen" : "Stop Sharing" }}</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item @click="videoCall.toggleIsAudioMute()" >
              <div class="d-flex align-items-center w-100">
                <span class="icon">
                  <b-icon class="sm-mar-right" scale="0.8" :icon="videoCall.isAudioMute ? 'mic-fill' : 'mic-mute-fill'" />
                </span>
                <span>{{ videoCall.isAudioMute ? "Enable Mic" : "Disable Mic" }}</span>
              </div>
            </b-dropdown-item>
            <b-dropdown-item v-if="!videoCall.is_conference" @click="videoCall.toggleIsChat(); $store.state.calls.isVideoPopupOpen = true; " >
              <div class="d-flex align-items-center w-100">
                <span class="icon">
                  <b-icon class="sm-mar-right" scale="0.8" icon="chat-dots-fill" />
                </span>
                <span>{{ videoCall.isChat ? "Close Chat" : "Open Chat" }}</span>
              </div>
            </b-dropdown-item>
            
          </b-dropdown>
        </div> -->
        <div class="actions">
          
          <span class="md-mar-left">
            <vb-icon class="cursor_pointer" @click="videoCall.toggleIsHangout();" v-b-popover.hover.bottom.html="'<small>Hang Up</small>'" icon="dialer-callRejectInbox-icon" width="40" height="40" id="callRejectInbox"/>
          </span>
        </div>
      </div>
    </div>
    <!-- ------- -->
  </div>
</template>

<script>
import { call_info } from '@/mixin';
export default {
  name: "ActiveCalls",
  mixins: [
    call_info
  ],
  props: {
    videoCall: {
      type: Object,
      default: () => ({}),
    },
  },
  data() {
    return {
      userDp: require("@/assets/images/user-male-icon.svg"),
    };
  },
  inject:['isEmpty'],
  computed: {
    callObj(){ return this.videoCall },
  },
};
</script>

<style lang="scss" scoped>
.model-controller {
  background-color: #b5b5c3;
  color: var(--white);
  border: 0 !important;
}
.dialer-callListing-area {
  display: flex;
  align-items: center;
  .dialer-expand-btn {
    cursor: pointer;
    border: 1px solid $white;
    @include border-radius(6px);
    padding: 5px;
    width: 100px;
    color: $white;
    min-height: 38px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .dialer-dropdown {
    .dropdown {
      ul {
        min-width: 480px;
        li {
          a {
            padding: 10px;
          }
        }
      }
      .dropdown-toggle {
        svg {
          transition: 0.3s ease-in-out;
        }
      }
      &.show {
        .dropdown-toggle {
          svg {
            transform: rotate(180deg);
          }
        }
      }
    }
    .dropdown-toggle {
      &::after {
        display: none;
      }
    }
  }
}
.vm--container {
  padding: 0px;
  position: fixed;
  box-sizing: border-box;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
  height: 80vh;
  min-height: 50vh;
  max-height: 100vh;
  width: 70vw;
  min-width: 50vw;
  max-width: 100vw;
  z-index: 999;
  &.video_popup_area {
    .vm--modal {
      &.popup-videos {
        background-color: #6b6969;
        padding: 0px;
        @include border-radius(30px);
        width: 100%;
        height: 100%;
        min-height: 100%;
        &:hover {
          .minimize-icon {
            opacity: 1;
            margin-top: 0px;
            top: 10px;
            z-index: 99;
          }
        }
      }
    }
    .in-active-call-bar {
      height: auto;
    }
  }
}
.openPopup-onClick {
  position: absolute;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
  cursor: pointer;
}

</style>