<template>
  <div>
    <div class="mainHeading withButton">
      First day of the week for calendars
    </div>
    <div class="mainDescription mb-4">
      Calendars through Sage HR can start on Sunday or Monday, depending on your preference.
      Once set, this will be reflected anywhere a calendar appears.
    </div>
    <div class="grayCard flexColumn mt-32px">
      <b-form-group class="HrmRadio-container wd-32">
        <b-form-radio class="HrmRadio" >
          Monday
        </b-form-radio>
        <b-form-radio class="HrmRadio">
          Sunday
        </b-form-radio>
      </b-form-group>
    </div>
    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Weekend days</div>
      <div class="grayCard-description mt-16px">
        Mark the days which are weekends in your country.
      </div>
      <b-form-checkbox class="HrmCheckBox-container mt-16px">
        Monday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Tuesday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Wedensday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Thursday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Friday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Saturday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Sunday
      </b-form-checkbox>
    </div>
    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Dashboard widgets</div>
      <b-form-checkbox class="HrmCheckBox-container mt-16px">
        Show job anniversaries in "Anniversaries" widget
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Show birthdays in "Anniversaries" widget 
      </b-form-checkbox>
    </div>
    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Calendar feed</div>
      <b-form-checkbox class="HrmCheckBox-container mt-16px">
        Show anniversaries
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Show bithdays
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Show custom holidays
      </b-form-checkbox>
    </div>
    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Calendar ordering</div>
      <b-form-group class="HrmRadio-container wd-32 mt-24px">
        <b-form-radio class="HrmRadio" >
          By teams & employee first name
        </b-form-radio>
        <b-form-radio class="HrmRadio">
          By teams & employee last name
        </b-form-radio>
        <b-form-radio class="HrmRadio" >
          By teams & org chart hierarchy
        </b-form-radio>
        <b-form-radio class="HrmRadio">
          By employee first name
        </b-form-radio>
      </b-form-group>
    </div>
    <button class="dialer-button dialer-button-primary w-fit-content mt-32px mr-0"> Save </button>
  </div>
</template>

<script>
export default {
  name: 'CalendarGenral',
}
</script>

<style>

</style>