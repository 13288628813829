export default [
	{
		name: "Jackson Will",
		employee: 657885,
		user: 2788039,
		policy: "Sickday",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657884,
		user: 2788036,
		policy: "Leave",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657886,
		user: 2788037,
		policy: "Sickday",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657887,
		user: 2788038,
		policy: "Sickday",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657888,
		user: 2788039,
		policy: "Sickday",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 65788888,
		user: 27880391,
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657888882,
		user: 278803912,
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657888882,
		user: 278803912,
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657888882,
		user: 278803912,
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657888882,
		user: 278803912,
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657888882,
		user: 278803912,
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657888882,
		user: 278803912,
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657888882,
		user: 278803912,
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	},
	{
		name: "Jackson Will",
		employee: 657888882,
		user: 278803912,
		policy: "Half day",
		startDate: "05/09/2022 All day",
		endDate: "05/09/2022",
		hoursUsed: 8,
		actions: "All teams",
		imgSrc: '@/assets/images/credit-card/1.jpeg'
	}
]
