var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup global-modal-hrm Scrollable-hrm",
    attrs: {
      "name": "ExportTimeoffApproverModal",
      "clickToClose": true,
      "scrollable": true
    },
    on: {
      "before-open": _vm.beforeOpen
    }
  }, [_c('form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-hrm-modal-header mar-top-hrm20"
  }, [_c('h2', {
    staticClass: "dialer-modal-hrm-title"
  }, [_vm._v("Time off approvers")]), _c('div', [_c('button', {
    staticClass: "dialer-hrm-button-modal",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('ExportTimeoffApproverModal');
      }
    }
  }, [_vm._v("Close")])])]), _c('div', {
    staticClass: "mar-top-hrm30 sp-gm-if"
  }, [_c('p', {
    staticClass: "common-modal-text"
  }, [_vm._v("Each time off approver will receive an email of each request and the ability to accept and decline requests. ")]), _c('p', {
    staticClass: "common-modal-text"
  }, [_vm._v("Team Managers & Administrators, even if they are not approvers, will still be able to edit requests. ")])]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Administrators")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Direct Manager")])]), _c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Team manager")])])], 1), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('label', {
    staticClass: "gm-hrm-label"
  }, [_vm._v("Indivisual approvers")]), _c('div', {
    staticClass: "dd-gm-container"
  }, [_c('b-dropdown', {
    staticClass: "gm-mainBorderfordropi",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('div', {
          staticClass: "gm-button-content-dropi"
        }, [_c('div', {
          staticClass: "gm-dropi-contenti"
        }, [_vm._v("Indivisual approvers")]), _c('b-icon', {
          staticClass: "dropi-icon",
          attrs: {
            "icon": "caret-down-fill"
          }
        })], 1)];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("A")]), _c('b-dropdown-item', {
    staticClass: "dropi-dropdown-toggle"
  }, [_vm._v("B")])], 1)], 1)]), _c('div', {
    staticClass: "sp-gm-if"
  }, [_c('b-form-checkbox', {
    staticClass: "gm-hrm-checkbox"
  }, [_c('span', {
    staticClass: "home-checkbox-space"
  }, [_vm._v("Automatically approve time off requests")])])], 1), _c('button', {
    staticClass: "btn-primarynewupdated1 mt-4 mar-bottom-hrm50"
  }, [_vm._v("Save")])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }