var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup",
    attrs: {
      "width": "600px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to my-4 dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Menu option")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm._l(_vm.menuOptions.schedules, function (sc, key) {
    return _c('div', {
      key: key,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row"
    }, [_c('div', {
      staticClass: "d-flex align-items-center justify-content-between"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion withGreyButton"
    }, [_c('vb-select', {
      staticClass: "right-0",
      attrs: {
        "selected": _vm.scheduleOption.option,
        "defaultSelectedText": 'Select a Button',
        "options": (Object.values(sc.avaliableOptions) || []).map(function (item) {
          return {
            text: `Press ${item}`,
            value: item
          };
        })
      },
      on: {
        "change": function ($event) {
          _vm.scheduleOption.option = $event;
        }
      }
    })], 1), _c('div', {
      staticClass: "addnewMenuOptions-Text ml-32px",
      attrs: {
        "disabled": !_vm.scheduleOption.option
      },
      on: {
        "click": function ($event) {
          return _vm.$modal.show('MenuOptionNewExtensionAssignModal', {
            account: '',
            type: 'setting'
          });
        }
      }
    }, [_vm._v(" Select user, team, queue or IVR ")]), _c('input', {
      ref: "scheduleid",
      refInFor: true,
      attrs: {
        "type": "hidden"
      },
      domProps: {
        "value": sc.id
      }
    }), _c('input', {
      ref: "schedulekey",
      refInFor: true,
      attrs: {
        "type": "hidden"
      },
      domProps: {
        "value": key + 1
      }
    })])])]);
  }), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Invalid Input sound")]), _c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100 mt-20px"
  }, [_vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit ? _c('vb-audio-player', {
    staticClass: "w-auto min-w-250px",
    attrs: {
      "src": _vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit
    }
  }) : _vm._e(), _c('button', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "dialer-button dialer-button-primary md-mar-left w-auto d-inline-block",
    attrs: {
      "title": _vm.getUserPermissions.ivr_advance_options ? '' : 'Your package does not support this feature',
      "disabled": !_vm.getUserPermissions.ivr_advance_options || _vm.api_sent.update_wrong_ivr_input,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.api_sent.update_wrong_ivr_input ? '' : _vm.$modal.show('IvrGraphRecordingListSelect', {
          group: 'announcement'
        });
      }
    }
  }, [_c('span', [_vm._v(_vm._s(_vm.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit ? "Change" : "Assign"))])])], 1), _vm.forms.update_wrong_ivr_input.submitted ? [_vm.$v.forms.update_wrong_ivr_input.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_wrong_ivr_input.ivr_wrongdtmf_repeat_limit.required ? _c('span', [_vm._v("* Required")]) : _vm._e()]) : _vm._e()] : _vm._e()], 2)], 2)])]), _c('RecordingListSelect', {
    attrs: {
      "is_single_select": true,
      "modalName": `IvrGraphRecordingListSelect`
    },
    on: {
      "selectedRecordings": function ($event) {
        return _vm.updateWrongIVRInput($event);
      }
    }
  }), _c('MenuOptionExtensionAssignModal', {
    attrs: {
      "modalName": "MenuOptionNewExtensionAssignModal"
    },
    on: {
      "interface": _vm.assignExtension
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }