<template>
  <div>
    <modal class=" center-zoomIn-transition v2 m-w-500" height="auto" :name="modalName" @before-open="onBeforeOpen" @before-close="onBeforeClose">
      <!-- dialer_animation right_side_popup -->
      <div class="dialer-edit w-100">
        <div class="dialer-form w-100">
          <div class="dialer-edit-header d-flex justify-content-between align-items-center w-100 mb-5"> 
            <h2 class="dialer-edit-title mb-0 pl-0">
              <!-- Add Channel -->
              Add group
            </h2>
            <div class="dialer-edit-actions">
              <a class="newCloseButton text-center" :aria-disabled="api.add_channel.send" @click="api.add_channel.send?'':$modal.hide(modalName)">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </a>
            </div>
          </div>
          <div class="mt-20px">
            <b-alert :show="!!api.add_channel.error_message" variant="danger">{{ api.add_channel.error_message }}</b-alert>
            <b-form @submit.prevent="addChannel()" class="latestGreyBox-9-9-2023">
              <b-form-group class="whiteBGinputWithGreyRoundedBorder">
                <label>Name</label>
                <b-form-input :disabled="api.add_channel.send" :maxlength="$v.forms.add_channel.name.$params.maxLength.max" type="text" placeholder="Enter name" v-model="forms.add_channel.name" />
                <p v-if="forms.add_channel.submitted && $v.forms.add_channel.name.$invalid" class="mb-1 text-small text-danger animated bounceIntop">
                  <span v-if="!$v.forms.add_channel.name.required">* Name is required</span>
                  <span v-if="!$v.forms.add_channel.name.maxLength">* Name can be maximum {{$v.forms.add_channel.name.$params.maxLength.max}} character</span>
                </p>
              </b-form-group>
            </b-form>
          </div>
          <div class="d-flex justify-content-end w-100 mt-20px">
            <!-- <a class="newDoneButton text-center ml-auto mt-20px" :aria-disabled="api.add_channel.send" type="button" @click="addChannel()">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </a> -->
            <a class="fullWidthDoneButton" :aria-disabled="api.add_channel.send" type="button" @click="addChannel()">
              <vb-spinner v-if="api.add_channel.send" />
              <template v-else>Add group</template>
            </a>
          </div>
        </div> 
      </div>
    </modal>
  </div>
</template>

<script>
import { required, maxLength } from "vuelidate/lib/validators";
import { $fn } from "@/utils";
import { mattermost, MATTERMOST_API } from "@/MatterMost";
import { mapGetters } from 'vuex';

export default {
  name: "AddChannelModal",
  props: {
    team_id: {
      type: String,
    },
    modalName: {
      type: String,
      default: 'AddChannelModal'
    },
  },
  data() {
    return {
      forms: {
        add_channel: this.$helperFunction.formInstance({
          data: {
            name: '',
          }
        }),
        reset(){
          this.add_channel?.reset?.()
        },
      },
      api: {
        add_channel: this.$helperFunction.apiInstance({ error_message: true }),
        reset(){
          this.add_channel?.reset?.()
        },
      },
    };
  },
  computed: {
    ...mapGetters({
      getCurrentUser: 'getCurrentUser',
    })
  },
  validations: {
    forms: {
      add_channel: {
        name: {
          required,
          maxLength: maxLength(33),
        },
      },
    },
  },
  methods: {
    onBeforeOpen(){
      //TODO
    },
    onBeforeClose(){
      this.forms.reset()
      this.api.reset()
    },
    addChannel(){
      let vm = this
      vm.forms.add_channel.submitted=true
      vm.$v.forms.add_channel.$touch()
      if(vm.api.add_channel.send || vm.$v.forms.add_channel.$invalid) return;
      vm.api.add_channel.send=true
      vm.api.add_channel.error_message=''
      MATTERMOST_API.endpoints.channels.create({
        team_id: vm.getCurrentUser.mm_team_id,
        type: "P",
        display_name: vm.forms.add_channel.name,
        name: $fn.makeid(52).toLowerCase(),
      })
      .then(({ data: channel })=>{
        // this.$store.state.mattermost.list_channels.push(channel)
        mattermost.emitOnUserLevel(mattermost.events.channel_created,{
          channel_id: channel.id
        })
        vm.$emit("complete", channel);
        vm.$modal.hide(vm.modalName);
      })
      .catch((ex)=>{
        vm.api.add_channel.error_message=ex?.response?.data?.message || ex.message
      })
      .finally(()=>{
        vm.api.add_channel.send=false
        vm.forms.add_channel.submitted=false
      })
    },
  },
};
</script>
