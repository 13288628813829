var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "actions-info-container"
  }, [_vm.is_sip ? [_c('div', {
    staticClass: "active-caller-box py-3 w-100"
  }, [_c('div', {
    staticClass: "active-caller"
  }, [_c('vb-avatar', {
    attrs: {
      "image": _vm.callObjInfo.image
    }
  }), _c('div', {
    staticClass: "active-caller-info"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('h6', [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callObjInfo.name)) + " " + _vm._s(_vm._f("number_formater")(_vm.callObjInfo.sub_info)))])]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm._f("incrementFilter")(_vm.data.time)))])])], 1), _c('div', {
    staticClass: "callsHoldButton"
  }, [_c('span', {
    staticClass: "dialer-button text-capitalize py-1 px-3",
    class: _vm.isActiveCall ? 'dialer-button-primary' : 'dialer-button-secondary'
  }, [_vm._v(" " + _vm._s(_vm.isActiveCall ? "Active" : "On Hold") + " ")])])])] : [_c('div', {
    staticClass: "active-caller-box py-3 w-100"
  }, [_c('div', {
    staticClass: "active-caller"
  }, [_c('vb-avatar', {
    attrs: {
      "image": _vm.callObjInfo.image
    }
  }), _c('div', {
    staticClass: "active-caller-info"
  }, [_c('div', {
    staticClass: "d-flex"
  }, [_c('h6', [_vm._v(_vm._s(_vm.callObjInfo.name))])]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm.callObjInfo.sub_info))]), _c('p', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(_vm._f("time_audio")(_vm.data.time)))]), _vm.$store.state.calls.outgoing_calls[_vm.data.room] ? [_vm.data.outgoing && _vm.data.outgoing.time ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-3"
  }, [_vm._v(" Time Left: " + _vm._s(_vm._f("time_audio")(_vm.data.outgoing.time)) + " ")])]) : _vm._e(), _vm.data.outgoing && _vm.data.outgoing.status ? _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('p', {
    staticClass: "m-0 pr-3"
  }, [_vm._v("Status: " + _vm._s(_vm.data.outgoing.status))])]) : _vm._e()] : _vm._e()], 2)], 1), _c('div', {
    staticClass: "callsHoldButton"
  }, [_c('span', {
    staticClass: "dialer-button text-capitalize py-1 px-3",
    class: _vm.isActiveCall ? 'dialer-button-primary' : 'dialer-button-secondary'
  }, [_vm._v(" " + _vm._s(_vm.isActiveCall ? "Active" : "On Hold") + " ")])])])]], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }