var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [[_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Global Settings")])])], _c('section', {
    staticClass: "dialer-settings-section GlobalSettings v2"
  }, [_c('form', {
    staticClass: "GlobalSettings-box1 mt-32px forIos",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.update.apply(null, arguments);
      }
    }
  }, [_c('div', {
    staticClass: "firstSection"
  }, [_c('span', {}, [_c('b-icon', {
    staticClass: "sm-mar-right",
    attrs: {
      "icon": "clock"
    }
  }), _vm._v(" " + _vm._s(_vm.current_date.time) + " ")], 1), _c('span', {}, [_c('b-icon', {
    staticClass: "sm-mar-right",
    attrs: {
      "icon": "calendar2"
    }
  }), _vm._v(" " + _vm._s(_vm.current_date.date) + " ")], 1)]), _c('div', {
    staticClass: "secondSection mt-22px"
  }, [_c('div', {
    staticClass: "profile-image-section mx-auto",
    attrs: {
      "aria-disabled": _vm.api_sent.update_company_dp
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('GlobalSettingProfileImageUploader', {
          type: 'COMPANY',
          accountcode: _vm.$store.getters.getCurrentUser.default_accountcode,
          current_image: _vm.$store.getters.getCurrentUser.companyLogo
        });
      }
    }
  }, [_vm.api_sent.update_company_dp ? _c('div', {
    staticClass: "loader-icon"
  }, [_c('vb-loading')], 1) : _vm._e(), _c('div', {
    staticClass: "profile-img"
  }, [_c('img', {
    staticStyle: {
      "height": "100%",
      "width": "100%"
    },
    attrs: {
      "id": "company-profile-image-updated",
      "src": _vm.$store.getters.getCurrentUser.companyLogo,
      "alt": 'Company Image'
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.local_filess.images.user_male;
      }
    }
  })])])]), _c('div', {
    staticClass: "thirdSection mt-16px"
  }, [_c('h1', {
    staticClass: "mb-0"
  }, [_vm._v(_vm._s(`${_vm.getCurrentUser.company}`))]), _c('h4', [_vm._v(_vm._s(` (${_vm.getCurrentUser.uid})`))])])]), _c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Country")]), _c('div', {
    staticClass: "basicWhiteIOScard mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item flex-column"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Country")]), _c('b-form-select', {
    attrs: {
      "disabled": true,
      "options": _vm.options
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item flex-column"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Timezone")]), _c('select', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.userTimezone,
      expression: "userTimezone"
    }],
    staticClass: "custom-select",
    attrs: {
      "disabled": _vm.api_sent.account_detail || _vm.api_sent.time_zone
    },
    on: {
      "change": [function ($event) {
        var $$selectedVal = Array.prototype.filter.call($event.target.options, function (o) {
          return o.selected;
        }).map(function (o) {
          var val = "_value" in o ? o._value : o.value;
          return val;
        });
        _vm.userTimezone = $event.target.multiple ? $$selectedVal : $$selectedVal[0];
      }, function ($event) {
        return _vm.updateTimeZone();
      }]
    }
  }, _vm._l(_vm.timeZones, function (timeZone, key) {
    return _c('option', {
      key: key
    }, [_vm._v(_vm._s(timeZone))]);
  }), 0)])])]), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable w-100"
  }, [_vm._v("Busy Lamp Field")]), _c('div', {
    staticClass: "position-relative"
  }, [_vm.api_sent.blf ? _c('vb-spinner') : _c('div', {
    class: ['w-fit-content', !_vm.getUserPermissions.blf ? 'for-disable' : ''],
    attrs: {
      "id": "BLFSwitchLock"
    }
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": !_vm.getUserPermissions.blf,
      "name": "check-button",
      "switch": "",
      "checked": _vm.getCurrentUser.blf
    },
    on: {
      "change": function ($event) {
        return _vm.updateBLF();
      }
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.call_pickup, function () {
          return _vm.$modal.show('PickupGroupsModal');
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable w-100"
  }, [_vm._v("Pickup Groups")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('DomainModal');
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable w-100"
  }, [_vm._v("Domain")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.unlimited_custom_status, function () {
          return _vm.$modal.show('StatusesModal');
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable w-100"
  }, [_vm._v("Status")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.call_tagging_and_notes, function () {
          return _vm.$modal.show('TagsModal');
        });
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable w-100"
  }, [_vm._v("Tags")]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "16.642",
      "height": "15.275"
    }
  })], 1)]), _c('StatusesModal'), _c('TagsModal'), _c('DomainModal'), _c('ProfileImageUploaderModal', {
    attrs: {
      "modalName": `GlobalSettingProfileImageUploader`
    },
    on: {
      "image-uploaded": function ($event) {
        '';
      }
    }
  }), _c('PickupGroupsModal')], 1)], 2);

}
var staticRenderFns = []

export { render, staticRenderFns }