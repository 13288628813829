var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: {
        content: _vm.info.name,
        disabled: !_vm.showPopover
      },
      expression: "{\n    content: info.name,\n    disabled: !showPopover\n  }",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    staticClass: "main v2",
    class: {
      'block': !_vm.image,
      'is_ringing': _vm.info.is_ringing,
      'is-config': _vm.info.is_blf_config,
      'notOnline': _vm.info.is_not_online,
      'busy': _vm.info.is_on_the_phone,
      'ready': _vm.info.is_ready
    },
    style: `height:${_vm.size || '45px'};width:${_vm.size || '45px'};`
  }, [_c('div', {
    staticClass: "imgBox"
  }, [_vm.loading ? _c('b-icon', {
    attrs: {
      "icon": "arrow-clockwise",
      "animation": "spin-pulse",
      "variant": "danger"
    }
  }) : _c('img', {
    staticClass: "clip-svg",
    attrs: {
      "src": _vm.info.image || _vm.image || '',
      "alt": "name"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('click');
      },
      "error": function ($event) {
        $event.target.src = _vm.user_male;
      }
    }
  })], 1), _c('svg', {
    staticClass: "for-desktop forClipPathSVG",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "35",
      "height": "35",
      "viewBox": "0 0 35 35"
    }
  }, [_c('defs', [_c('clipPath', {
    attrs: {
      "id": "avatar-icon"
    }
  }, [_c('path', {
    attrs: {
      "id": "Path_12116",
      "data-name": "Path 12116",
      "d": "M24.668,34.975a42.971,42.971,0,0,1-13.351,0,12.363,12.363,0,0,1-10.3-10.306,42.6,42.6,0,0,1,0-13.351,12.354,12.354,0,0,1,10.3-10.3,42.6,42.6,0,0,1,13.351,0,12.363,12.363,0,0,1,10.306,10.3,42.97,42.97,0,0,1,0,13.351A12.369,12.369,0,0,1,24.668,34.975Z",
      "transform": "translate(-0.496 -0.497)",
      "fill": "none"
    }
  })])])]), _c('svg', {
    staticClass: "rect_status_svg",
    attrs: {
      "width": "57",
      "height": "57",
      "xmlns": "http://www.w3.org/2000/svg",
      "viewBox": "0 0 57 57"
    }
  }, [_c('rect', {
    staticStyle: {
      "fill": "transparent"
    },
    attrs: {
      "rx": "20",
      "ry": "20",
      "x": "1.75",
      "y": "1.75",
      "height": "53",
      "width": "53",
      "stroke-width": "3.5",
      "stroke": "#DFDFDF"
    }
  })]), _c('svg', {
    staticClass: "for-mobile",
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "width": "29.005",
      "height": "29.005",
      "viewBox": "0 0 29.005 29.005"
    }
  }, [_c('defs', [_c('clipPath', {
    attrs: {
      "id": "avatar-icon-mobile"
    }
  }, [_c('path', {
    attrs: {
      "xmlns": "http://www.w3.org/2000/svg",
      "id": "avatar-icon-mobile",
      "data-name": "avatar-icon-mobile",
      "d": "M19.835,28.08a34.377,34.377,0,0,1-10.681,0A9.89,9.89,0,0,1,.919,19.835a34.079,34.079,0,0,1,0-10.681A9.885,9.885,0,0,1,9.154.919a34.079,34.079,0,0,1,10.681,0A9.89,9.89,0,0,1,28.08,9.154a34.377,34.377,0,0,1,0,10.681A9.9,9.9,0,0,1,19.835,28.08Z",
      "transform": "translate(0.005 0.005)",
      "fill": "none"
    }
  })])])]), _vm.showStatus && !_vm.isStatusIdUndefined ? _c('Status', {
    attrs: {
      "statusId": _vm.status_id || _vm.info.status_id,
      "callQueueStatus": _vm.info.call_queue_status
    }
  }) : _vm._e()], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }