var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    ref: "app_designed",
    staticClass: "flow__graph designed designedAgain scaleDown-0-6",
    attrs: {
      "id": "app_designed"
    }
  }, [_vm.user ? _c('div', {
    staticClass: "w-100 d-flex justify-content-center align-items-center flex-column"
  }, [_c('div', {
    staticClass: "w-auto d-flex justify-content-center align-items-center flex-column"
  }, [_c('NumbersNode', {
    attrs: {
      "dids": _vm.user.userDid
    }
  }), _c('InfoNode', {
    attrs: {
      "voipaccount": _vm.user.voipaccount
    },
    on: {
      "edit": function ($event) {
        return _vm.$modal.show('EditUserGraphModal', {
          accountcode: _vm.user.voipaccount
        });
      }
    }
  }), _c('OutOfHoursNode', {
    ref: "ofh",
    attrs: {
      "voipaccount": _vm.user.voipaccount
    }
  }), _c('div', {
    staticClass: "nodeBasic-new"
  }, [_c('div', {
    staticClass: "ExtensionsIcon position-relative"
  }, _vm._l(_vm.response.extension_detail.extensions, function (extension, index) {
    return _c('div', {
      key: index,
      class: `ExtentionItself ${extension.device_status.status == 'online' ? 'statusOnline' : 'statusOffline'}`
    }, [_c('vb-icon', {
      attrs: {
        "id": _vm._f("get_property")(extension, 'accountcode'),
        "icon": _vm._f("extensionIcon")(_vm._f("get_property")(extension, 'type')),
        "width": "25px",
        "height": "25px"
      }
    }), _c('b-popover', {
      attrs: {
        "target": _vm._f("get_property")(extension, 'accountcode'),
        "triggers": "hover",
        "placement": "top"
      }
    }, [_c('small', [_vm._v(_vm._s(_vm._f("get_property")(extension, 'accountname')) + " " + _vm._s(extension.device_status.status))])])], 1);
  }), 0)]), _c('ForwardSettingNode', {
    attrs: {
      "account": _vm.user.voipaccount
    }
  })], 1)]) : _vm._e()]), _c('EditUserModal', {
    attrs: {
      "modalName": 'EditUserGraphModal'
    },
    on: {
      "ofh-days-updated": function ($event) {
        return _vm.$refs.ofh.fetchSchedule();
      },
      "ofh-toggle-updated": function ($event) {
        return _vm.$refs.ofh.fetchcallRouteSetting();
      },
      "ofh-default-updated": function ($event) {
        return _vm.$refs.ofh.fetchDefaultSetting();
      },
      "extension-updated": function ($event) {
        return _vm.fetchExtensionDetail();
      },
      "cf-updated": function ($event) {
        return _vm.$refs.ofh.fetchcallRouteSetting();
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }