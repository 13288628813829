var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup developerOptionsModal",
    attrs: {
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to my-4 dialer-edit d-flex align-items-center justify-content-between"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0"
  }, [_vm._v("Features")]), _c('div', {
    staticClass: "dialer-edit-actions d-flex justify-content-end"
  }, [_c('button', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticStyle: {
      "max-height": "calc(100vh - 120px)",
      "overflow-y": "auto",
      "height": "calc(100vh - 120px)"
    }
  }, [_c('div', {
    staticClass: "dialer-login-form"
  }, [_c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    staticClass: "dialer-form-inner signupLoginV2 d-flex bg-transparent p-0 w-100"
  }, [_c('div', {
    staticClass: "wrap w-100"
  }, [_c('div', {
    staticClass: "signupLoginV2-section-2-right w-100 pr-3"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm._l(_vm.GET_API_ERRORS, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
    }, [_c('div', {
      staticClass: "latestGreyBox-heading-main text-left wd-33-imp"
    }, [_vm._v(_vm._s(data.status))]), _c('div', {
      staticClass: "latestGreyBox-heading-main text-left wd-33-imp"
    }, [_vm._v(_vm._s(data.url))]), _c('div', {
      staticClass: "latestGreyBox-heading-main text-left wd-33-imp"
    }, [_vm._v("not sync")])]);
  }), _vm._l(_vm.GET_STORE_API_ERRORS, function (data, index) {
    return _c('div', {
      key: index,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row py-4 min-height-none"
    }, [_c('div', {
      staticClass: "latestGreyBox-heading-main text-left wd-33-imp"
    }, [_vm._v(_vm._s(data.status))]), _c('div', {
      staticClass: "latestGreyBox-heading-main text-left wd-33-imp"
    }, [_vm._v(_vm._s(data.url))]), _c('div', {
      staticClass: "latestGreyBox-heading-main text-left wd-33-imp"
    }, [_vm._v("sync")])]);
  })], 2)])])])])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }