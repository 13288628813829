<template>
  <modal class="center-zoomIn-transition sipActiveCall-Modal sip-call-keypad keypadModalDurongCall" :transition="!$store.getters.getIsMobile ? 'center-zoomIn-transition ': ''" :name="modalName">
    <div class="dialer-call-notification-tray">
      <div class="dialer-call-notification dialer-keypadNotification">
        <div class="dialer-call-notification-close" @click="$modal.hide(modalName)">
          <vb-icon icon="dialer-close-icon" width="14" height="14" />
        </div>
        <div class="dialer-call-notification-inner">
          <div class="dialer-call-notification-text">
            <div class="dialer-call-notification-main-text">Keypad</div>
          </div>
        </div>
        <div class="main ivr_dial_pad latestGreyBox-9-9-2023">
          <div class="dialpad mw-365px">
            <!-- <div class="w-100 d-flex justify-content-between input_number">  -->
            <div class="whiteBGinputWithGreyRoundedBorder w-100"> 
              <input type="text" class="w-100" name id v-model="form.dtmf" onkeypress="return /([0-9])/i.test(event.key)" />
            </div>
            
            <div class="main-dialpad dialpad justify-content-between flex-row d-flex flex-wrap mw-100 w-100 mt-20px">
              <button class="btn-key d-flex border-0" @click="sendDtmf('1')">
                <vb-icon icon="dialOne-icon-v2" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('2')">
                <vb-icon icon="dialTwo-icon-v2" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('3')">
                <vb-icon icon="dialThree-icon-v2" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('4')">
                <vb-icon icon="dialFour-icon-v2" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('5')">
                <vb-icon icon="dialFive-icon-v2" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('6')">
                <vb-icon icon="dialSix-icon-v2" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('7')">
                <vb-icon icon="dialSeven-icon-v2" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('8')">
                <vb-icon icon="dialEight-icon-v2" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('9')">
                <vb-icon icon="dialNine-icon-v2" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('*')">
                <!-- <vb-icon icon="asterik-icon-v2" /> -->
                <vb-icon icon="asterik-icon-v3" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('0')">
                <vb-icon icon="dialZero-icon-v2" />
              </button>
              <button class="btn-key d-flex border-0" @click="sendDtmf('#')">
                <!-- <vb-icon icon="hash-icon-v2" /> -->
                <vb-icon icon="hash-icon-v3" />
              </button>
            </div>
          </div>
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'SipCallKeypadModal',
  props: {
    phones: {
      type: Object,
      default: ()=>({}),
      require: true,
    },
    modalName: {
      type: String,
      default: 'SipCallKeypadModal'
    },
  },
  data(){
    return {
      form: {
        dtmf: '',
      },
    }
  },
  methods: {
    sendDtmf(number) {
      this.form.dtmf += String(number);
      this.phones.sendDTMF(number);
    },
  },
}
</script>

<style>

</style>