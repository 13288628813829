var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "w-100"
  }, [_c('input', {
    directives: [{
      name: "show",
      rawName: "v-show",
      value: false,
      expression: "false"
    }],
    ref: "file_upload",
    attrs: {
      "type": "file",
      "disabled": _vm.api.add_attachment.send || _vm.disabled,
      "accept": ".xlsx, .xls, image/*, .doc, .docx, .ppt, .pptx, .pdf, .rtf, .txt, .xml, .csv"
    },
    on: {
      "change": function ($event) {
        return _vm.attachmentUpload($event.target.files);
      }
    }
  }), _vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon d-flex justify-content-center align-items-center mr-16px"
  }, [_c('vb-icon', {
    staticStyle: {
      "width": "14.87px",
      "height": "17px"
    },
    attrs: {
      "icon": "latest-mobileFileAttachments-icon"
    }
  })], 1), _vm._m(0)]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_vm.api.add_attachment.send ? _c('span', {
    staticStyle: {
      "font-size": "12px"
    }
  }, [_vm._v("Uploading " + _vm._s(_vm.api.add_attachment.upload_progress.percentage) + "%")]) : _c('span', {
    staticStyle: {
      "font-size": "14px"
    }
  }, [_vm._v(_vm._s(_vm.attachments.length))]), _vm.attachments.length < 5 ? _c('vb-icon', {
    staticClass: "rightFacingArrow ml-2",
    style: `pointer-events:${_vm.api.add_attachment.send || _vm.disabled ? 'none' : 'auto'};`,
    attrs: {
      "icon": "mobile-plus-icon-likeAirCall-icon",
      "width": "15px",
      "height": "15px"
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.file_upload.click();
      }
    }
  }) : _vm._e()], 1)])] : [_c('div', {
    staticClass: "d-flex flex-column align-items-start mt-45px"
  }, [_c('div', {
    staticClass: "TODO-innerHeading"
  }, [_vm._v("Attachments")]), _vm._l(_vm.attachments, function (attachment, index) {
    return _c('div', {
      key: attachment.id,
      staticClass: "d-flex align-items-center w-100 mt-12px"
    }, [_c('img', {
      staticClass: "TODO-attachmentImg",
      attrs: {
        "src": _vm._f("attachmentSrc")(attachment)
      },
      on: {
        "click": function ($event) {
          attachment.image ? _vm.$emit('media-gallery-modal-show', {
            images: _vm.images,
            index: index
          }) : '';
        },
        "error": function ($event) {
          $event.target.src = require('@/assets/images/cameraPlaceholder.png');
        }
      }
    }), _c('div', {
      staticClass: "d-flex flex-column align-items-start"
    }, [_c('div', {
      staticClass: "TODO-smallInnerHeading"
    }, [_vm._v(_vm._s(attachment.src))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('p', {
      staticClass: "todo-smallGreyBox-text mb-0"
    }, [_vm._v(_vm._s(_vm._f("filter_date_current")(attachment.created_at, null, {
      formate_now: true
    })))]), _c('p', {
      staticClass: "todo-smallGreyBox-text mb-0 textUnderline",
      on: {
        "click": function ($event) {
          _vm.disabled ? '' : _vm.attachmentRemove(attachment);
        }
      }
    }, [_vm._v("Delete")]), _c('p', {
      staticClass: "todo-smallGreyBox-text mb-0 textUnderline",
      on: {
        "click": function ($event) {
          _vm.disabled ? '' : _vm.attachmentDownload(attachment);
        }
      }
    }, [_vm._v("Download")])])])]);
  })], 2), _vm.response.attachments.length < 5 ? _c('button', {
    staticClass: "newButton mt-45px mwd-200px",
    attrs: {
      "disabled": _vm.api.add_attachment.send || _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$refs.file_upload.click();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-folders-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm.api.add_attachment.send ? [_vm._v("Uploading " + _vm._s(_vm.api.add_attachment.upload_progress.percentage) + "%")] : [_vm._v("Add an attachment")]], 2)], 1) : _vm._e()]], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Attached files")])]);

}]

export { render, staticRenderFns }