<template>
  <modal class="dialer_animation right_side_popup m-w-600 min-width-50percent-modal" height="auto" :name="modalName" :scrollable="true" @before-open="onBeforeOpen" @before-close="onBeforeClose">
    <div v-if="$store.getters.getIsMobile" class="d-flex w-100 justify-content-between align-items-center">
      <h2 class="dialer-settings-title">Storage</h2>
      <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
    </div>
    <div v-else class="dialer-edit-header">
      <div>
        <h2 class="dialer-settings-title newer mb-0">Storage</h2>
      </div>
      <div class="dialer-edit-actions">
        <a class="newDoneButton" @click="$modal.hide(modalName)">
          <vb-icon icon="squared-done-icon" height="38px" width="38px" />
          <span class="newDoneButton-textPart">Done</span>
        </a>
      </div>
    </div>
    <div class="vm--modal-text">
      Integrate with Amazon S3: Store your call recordings securely on a remote service like Amazon S3 or a compatible platform. 
      <!-- This is often utilized by enterprise organizations for enhanced compliance and security. -->
      It is often used by enterprise organisations for better compliance and security.
    </div>
    <div class="md-mar-bottom md-mar-top">
      <b-alert :show="!!api.update.error_message" variant="danger">
        {{ api.update.error_message }}
      </b-alert>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="latestGreyBox-heading-main">Update setting</div>
            <b-form-checkbox class="newerSwitch" :disabled="api.update.send || api.update_status.send || api.get.send" v-model="forms.update.status" @change="updatestatus();" switch />
          </div>
        </div>
        <form v-if="forms.update.status" class="dialer-form" @submit.prevent="update()">
          <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
                <label>Type</label>
                <vb-select class="w-100" v-model="forms.update.type" :options="typeOptions" />
                <template v-if="forms.update.submitted">
                  <p v-if="!$v.forms.update.type.required" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* Type is required</span>
                  </p>
                </template>
                <template v-if="api.update.validation_errors.provider">
                  <p v-for="(error_message, index) in api.update.validation_errors.provider" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* {{ error_message }}</span>
                  </p>
                </template>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
                <label>Key</label>
                <input class="w-100" type="text" label="Key" :disabled="api.update.send || api.get.send" v-model="forms.update.key" />
                <template v-if="forms.update.submitted">
                  <p v-if="!$v.forms.update.key.required" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* key is required</span>
                  </p>
                </template>
                <template v-if="api.update.validation_errors.key">
                  <p v-for="(error_message, index) in api.update.validation_errors.key" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* {{ error_message }}</span>
                  </p>
                </template>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
                <label>SECRET</label>
                <input type="text" label="SECRET" class="w-100" :disabled="api.update.send || api.get.send" v-model="forms.update.secret" />
                <template v-if="forms.update.submitted">
                  <p v-if="!$v.forms.update.secret.required" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* secret is required</span>
                  </p>
                </template>
                <template v-if="api.update.validation_errors.secret">
                  <p v-for="(error_message, index) in api.update.validation_errors.secret" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* {{ error_message }}</span>
                  </p>
                </template>
              </div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
                <label>Bucket</label>
                <input type="text" label="bucket" class="w-100" :disabled="api.update.send || api.get.send" v-model="forms.update.bucket" />
                <template v-if="forms.update.submitted">
                  <p v-if="!$v.forms.update.bucket.required" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* bucket is required</span>
                  </p>
                </template>
                <template v-if="api.update.validation_errors.bucket">
                  <p v-for="(error_message, index) in api.update.validation_errors.bucket" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* {{ error_message }}</span>
                  </p>
                </template>
              </div>
            </div>
            <div v-if="forms.update.type == 's3'" class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
                <label>region</label>
                <input type="text" label="region" class="w-100" :disabled="api.update.send || api.get.send" v-model="forms.update.region" />
                <template v-if="forms.update.submitted">
                  <p v-if="!$v.forms.update.region.required" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* region is required</span>
                  </p>
                </template>
                <template v-if="api.update.validation_errors.region">
                  <p v-for="(error_message, index) in api.update.validation_errors.region" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* {{ error_message }}</span>
                  </p>
                </template>
              </div>
            </div>
            <div v-if="forms.update.type == 'other'" class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start">
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion w-100">
                <label>URL</label>
                <input type="text" label="URL" class="w-100" :disabled="api.update.send || api.get.send" v-model="forms.update.url" />
                <template v-if="forms.update.submitted">
                  <p v-if="!$v.forms.update.url.required" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* Type is required</span>
                  </p>
                  <p v-if="!$v.forms.update.url.url" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* Invalid URL</span>
                  </p>
                </template>
                <template v-if="api.update.validation_errors.url">
                  <p v-for="(error_message, index) in api.update.validation_errors.url" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                    <span>* {{ error_message }}</span>
                  </p>
                </template>
              </div>
            </div>
          </div>
          <div class="d-flex justify-content-end mt-4">
            <button :disabled="api.update.send || api.get.send" class="newDoneButton">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                <vb-spinner v-if="api.update.send" />
                <template v-else>Update</template>
              </span>
            </button>
          </div>
        </form>
      </div>
    </div>

    
    <AccountRetentionWidget />
    
    
    
    
    
    
    
    
    
    
    
    
    
    <!-- <div class="dialer-settings-main">
      <section class="dialer-settings-section">
        <div v-if="$store.getters.getIsMobile" class="d-flex w-100 justify-content-between align-items-center">
          <h2 class="dialer-settings-title">Storage</h2>
          <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
        </div>
        <div v-else class="d-flex w-100 justify-content-between mb-3 align-items-center">
          <h2 class="dialer-settings-title newer mb-0">Storage</h2>
          <button class="dialer-button dialer-button-delete" type="button" @click="$modal.hide(modalName)">Close</button>
        </div>
        <p class="dialer-settings-intro">
          <span>There is a description of the storage.</span>
        </p>

        <div class="md-mar-bottom md-mar-top">
          <div class="dialer-box grayBox">
            
            <b-alert :show="!!api.update.error_message" variant="danger">
              {{ api.update.error_message }}
            </b-alert>

            <div class="d-flex justify-content-between">
              <h3 class="dialer-box-header">Update Setting</h3>
              <b-form-checkbox class="dialer-switch" :disabled="api.update.send || api.update_status.send || api.get.send" v-model="forms.update.status" @change="updatestatus();" switch />
            </div>
            
            <form v-if="forms.update.status" class="dialer-form" @submit.prevent="update()">
              
              <div class="dialer-input-group mb-3 mt-3">
                <div class="dialer-input-field d-flex align-items-center">
                  <label class="dialer-input-label mr-5 mb-1">Type</label>
                  <div style="display: flex; flex-direction: column">
                    <div class="dialer-input-field input-consistent-inner-shadows whiteBg-select simple-image">
                      <vb-select :selected="forms.update.type" @change="forms.update.type = $event" :options="typeOptions" />
                    </div>
                    <template v-if="forms.update.submitted">
                      <p v-if="!$v.forms.update.type.required" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* Type is required</span>
                      </p>
                    </template>
                    <template v-if="api.update.validation_errors.provider">
                      <p v-for="(error_message, index) in api.update.validation_errors.provider" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* {{ error_message }}</span>
                      </p>
                    </template>
                  </div>
                </div>
              </div>

              <div class="dialer-input-group mb-3">
                <div class="dialer-input-field input-consistent-inner-shadows">
                  <label class="dialer-input-label mb-1">Key</label>
                  <div style="display: flex; flex-direction: column">
                    <input type="text" label="Key" class="bg-white" :disabled="api.update.send || api.get.send" v-model="forms.update.key" />
                    <template v-if="forms.update.submitted">
                      <p v-if="!$v.forms.update.key.required" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* key is required</span>
                      </p>
                    </template>
                    <template v-if="api.update.validation_errors.key">
                      <p v-for="(error_message, index) in api.update.validation_errors.key" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* {{ error_message }}</span>
                      </p>
                    </template>
                  </div>
                </div>
              </div>

              <div class="dialer-input-group mb-3">
                <div class="dialer-input-field input-consistent-inner-shadows">
                  <label class="dialer-input-label mb-1">SECRET</label>
                  <div style="display: flex; flex-direction: column">
                    <input type="text" label="SECRET" class="bg-white" :disabled="api.update.send || api.get.send" v-model="forms.update.secret" />
                    <template v-if="forms.update.submitted">
                      <p v-if="!$v.forms.update.secret.required" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* secret is required</span>
                      </p>
                    </template>
                    <template v-if="api.update.validation_errors.secret">
                      <p v-for="(error_message, index) in api.update.validation_errors.secret" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* {{ error_message }}</span>
                      </p>
                    </template>
                  </div>
                </div>
              </div>

              <div class="dialer-input-group mb-3">
                <div class="dialer-input-field input-consistent-inner-shadows">
                  <label class="dialer-input-label mb-1">bucket</label>
                  <div style="display: flex; flex-direction: column">
                    <input type="text" label="bucket" class="bg-white" :disabled="api.update.send || api.get.send" v-model="forms.update.bucket" />
                    <template v-if="forms.update.submitted">
                      <p v-if="!$v.forms.update.bucket.required" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* bucket is required</span>
                      </p>
                    </template>
                    <template v-if="api.update.validation_errors.bucket">
                      <p v-for="(error_message, index) in api.update.validation_errors.bucket" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* {{ error_message }}</span>
                      </p>
                    </template>
                  </div>
                </div>
              </div>

              <div v-if="forms.update.type == 's3'" class="dialer-input-group mb-3">
                <div class="dialer-input-field input-consistent-inner-shadows">
                  <label class="dialer-input-label mb-1">region</label>
                  <div style="display: flex; flex-direction: column">
                    <input type="text" label="region" class="bg-white" :disabled="api.update.send || api.get.send" v-model="forms.update.region" />
                    <template v-if="forms.update.submitted">
                      <p v-if="!$v.forms.update.region.required" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* region is required</span>
                      </p>
                    </template>
                    <template v-if="api.update.validation_errors.region">
                      <p v-for="(error_message, index) in api.update.validation_errors.region" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* {{ error_message }}</span>
                      </p>
                    </template>
                  </div>
                </div>
              </div>

              <div v-if="forms.update.type == 'other'" class="dialer-input-group mb-3">
                <div class="dialer-input-field input-consistent-inner-shadows">
                  <label class="dialer-input-label mb-1">URL</label>
                  <div style="display: flex; flex-direction: column">
                    <input type="text" label="URL" class="bg-white" :disabled="api.update.send || api.get.send" v-model="forms.update.url" />
                    <template v-if="forms.update.submitted">
                      <p v-if="!$v.forms.update.url.required" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* Type is required</span>
                      </p>
                      <p v-if="!$v.forms.update.url.url" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* Invalid URL</span>
                      </p>
                    </template>
                    <template v-if="api.update.validation_errors.url">
                      <p v-for="(error_message, index) in api.update.validation_errors.url" :key="index" class="mb-1 text-small text text-danger animated bounceIntop">
                        <span>* {{ error_message }}</span>
                      </p>
                    </template>
                  </div>
                </div>
              </div>

              <div class="d-flex justify-content-end">
                <button :disabled="api.update.send || api.get.send" class="dialer-button dialer-button-primary md-mar-top">
                  <vb-spinner class="sm-mar-right" v-if="api.update.send" />
                  <template v-else>Update</template>
                </button>
              </div>

            </form>

          </div>
        </div>
      </section>
    </div> -->
  </modal>
</template>

<script>
import { requiredIf, url } from "vuelidate/lib/validators";
import { VOIP_API } from "../../utils";
import AccountRetentionWidget from "../widgets/call-recordings/AccountRetention.vue";

var type_options = [
  {
    text: 'Other',
    value: "other",
  },
  {
    text: "S3",
    value: "s3"
  }
];
export default {
  name: 'StorageModal',
  components: {
    AccountRetentionWidget,
  },
  props: {
    modalName: {
      type: String,
      default: 'StorageModal',
    },
  },
  data() {
    return {
      forms: {
        update: {
          type: "",
          url: "",
          key: "",
          secret: "",
          region: "",
          bucket: "",
          status: false,
          reset() {
            this.type = "";
            this.url = "";
            this.key = "";
            this.secret = "";
            this.region = "";
            this.bucket = "";
            this.status = false;
            this.submitted = false;
          },
          submitted: false,
        },
      },
      api: {
        update: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
        update_status: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
        get: {
          count: 0,
          send: false,
          status: 0,
          token: null,
          error: {},
          validation_errors: {},
          error_message: '',
          increment(){return this.count=this.count+1},
          reset(){
            this.count = 0
            this.send = false
            this.status = 0
            this.token = null
            this.error = {}
            this.validation_errors= {}
            this.error_message=''
          },
        },
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    typeOptions() { return type_options; },
    updateFormData(){
      let update_form = this.forms.update
      let obj1 = {
        provider: update_form.type,
        key: update_form.key,
        bucket: update_form.bucket,
        secret: update_form.secret,
        status: update_form.status ? "on" : "off",
      }
      let obj2 = update_form.type=='other' ? {
        url: update_form.url,
      } : {}
      let obj3 = update_form.type=='s3' ? {
        region: update_form.region,
      } : {}
      return {
        ...obj1,
        ...obj2,
        ...obj3
      }
    },
  },
  validations: {
    forms: {
      update: {
        type: {
          required: requiredIf(function () {
            return this.forms.update.status;
          }),
        },
        url: {
          required: requiredIf(function () {
            return (
              this.forms.update.status && this.forms.update.type == "other"
            );
          }),
          url,
        },
        key: {
          required: requiredIf(function () {
            return this.forms.update.status;
          }),
        },
        secret: {
          required: requiredIf(function () {
            return this.forms.update.status;
          }),
        },
        region: {
          required: requiredIf(function () {
            return this.forms.update.status && this.forms.update.type == "s3";
          }),
        },
        bucket: {
          required: requiredIf(function () {
            return this.forms.update.status;
          }),
        },
      },
    },
  },
  methods: {
    onBeforeClose(){
      this.forms.update.reset()
      this.api.update.reset()
      this.api.get.reset()
    },
    onBeforeOpen(){
      this.get()
    },
    update() {
      let vm = this;
      vm.forms.update.submitted = true;
      vm.$v.forms.update.$touch();
      if (vm.$v.forms.update.$invalid || vm.api.update.send) return;
      vm.api.update.send = true;
      vm.api.update.validation_errors={}
      vm.api.update.error_message=''
      vm.api.update.increment();
      VOIP_API.endpoints.storage_setting.update({
        ...vm.updateFormData
      })
      .then(() => {
        vm.appNotify({
          message: "Successfully Added!",
          type: "success",
        })
      })
      .catch((ex) => {
        vm.api.update.validation_errors=ex.own_errors
        vm.api.update.error_message=ex.own_message
      })
      .finally(() => {
        vm.forms.update.submitted = false;
        vm.api.update.send = false;
      });
    },
    updatestatus(){
      let vm = this;
      if (vm.api.update_status.send) return;
      vm.api.update_status.send = true;
      vm.api.update.reset()
      VOIP_API.endpoints.storage_setting.statusupdate({
        uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
        status: vm.forms.update.status ? "on" : "off",
      }).then(() => {
        vm.appNotify({
          message: "Successfully Updated!",
          type: "success",
        })
      }).catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(() => {
        vm.api.update_status.send = false;
      });
    },
    get() {
      let vm = this;
      vm.api.get.send = true;
      vm.api.get.increment();
      VOIP_API.endpoints.storage_setting
        .get({
          uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
        })
        .then(({ data }) => {
          // console.log(data);
          vm.forms.update.type = data.provider || "s3";
          vm.forms.update.url = data.url || "";
          vm.forms.update.key = data.key || "";
          vm.forms.update.secret = data.secret || "";
          vm.forms.update.region = data.region || "";
          vm.forms.update.bucket = data.bucket || "";
          vm.forms.update.status = data.status == "on" ? true : false;
        })
        .finally(() => {
          vm.api.get.send = false;
        });
    },
  },
}
</script>

<style>

</style>