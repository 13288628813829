var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "AddNewBillingCard SubscriptionPlanDetailModal newBilling-changePlan-modal UpdatePlanComparisonsListModal",
    attrs: {
      "width": "40%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0 mb-4"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Slot comparison details ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again mt-0",
    attrs: {
      "isListEmpty": _vm.comparisons.length == 0,
      "listLength": _vm.comparisons.length,
      "perPage": 5
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.comparisons, function (comparison, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: comparison.product_id,
            class: {
              'mismatch': comparison.is_mismatch == 'yes'
            },
            on: {
              "click": function ($event) {
                comparison.is_mismatch == 'yes' ? _vm.$modal.show(`${_vm._uid}-ShowDetailsBilling`, {
                  id: comparison.product_id,
                  is_addon: true
                }) : '';
              }
            }
          }, [_c('td', [_vm._v(_vm._s(comparison.product_label))]), _c('td', [_vm._v(_vm._s(comparison.product_total_free_allowed))]), _c('td', [_vm._v(_vm._s(comparison.product_already_used))]), _c('td', [_vm._v(_vm._s(comparison.new_plan_free_product_allowed))]), _c('td', [_vm._v(_vm._s(comparison.qty_difference))]), _c('td', [_vm._v(_vm._s(comparison.message))]), _c('td', [_vm._v(_vm._s(comparison.is_mismatch))])]);
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "19px",
              "width": "60px"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "50px",
              "width": "135px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('tr', {
    staticClass: "w-100",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("Label")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("available services")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("current package used slots ")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("new package available slot")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("quantity Difference ")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("Message ")])]), _c('th', {
    staticClass: "border-0"
  }, [_c('span', {}, [_vm._v("mismatch ")])])])]), _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-4"
  }, [_c('b-button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "variant": "primary"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_vm._v("OK")])], 1)], 1), _c('ShowDetailsBilling', {
    attrs: {
      "modalName": `${_vm._uid}-ShowDetailsBilling`,
      "subscription_detail": _vm.subscription_detail,
      "allAddons": _vm.allAddons
    },
    on: {
      "delete-service": function ($event) {
        return _vm.$emit('delete-service');
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }