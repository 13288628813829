<template>
  <div>
    <div class="mainHeading">Assets</div>
    <div class="mainDescription">
      <br/>
    </div>
    <div class="grayCard">
      <div class="leftSide halfWidth">
        <div class="whiteSubCard">
          <div class="whiteSubCard-head">
            Total assets
          </div>
          <div class="whiteSubCard-body">
            <div>24</div>
          </div>
        </div>
        <div class="whiteSubCard">
          <div class="whiteSubCard-head">
            Assets given out
          </div>
          <div class="whiteSubCard-body">
            <div>2</div>
          </div>
        </div>
        <div class="innerShadowInput-container">
          <label>Employees</label>
          <b-dropdown class="hrmInnerShadow-dd bgWhite" no-caret>
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">All</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All Employees
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="innerShadowInput-container">
          <label>Category</label>
          <b-dropdown class="hrmInnerShadow-dd bgWhite" no-caret>
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">All</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All Category
            </b-dropdown-item>
          </b-dropdown>
        </div>
        <div class="innerShadowInput-container">
          <label>Location</label>
          <b-dropdown class="hrmInnerShadow-dd bgWhite" no-caret>
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">Test</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              All Test
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="rightSide halfWidth">
        <div class="whiteSubCard ">
          <div class="whiteSubCard-head">
            Assets available
          </div>
          <div class="whiteSubCard-body">
            <div>50</div>
          </div>
        </div>
        <div class="whiteSubCard">
          <div class="whiteSubCard-head">
            Asset categories
          </div>
          <div class="whiteSubCard-body">
            <div class="w-100 d-flex align-items-center justify-content-center">
              <canvas id="doughnut-chart"></canvas>
            </div>
          </div>
        </div>
      </div>
    </div>
    <div class="nameWithImage mt-32px">
      <div class="imageContainer">
        <img class="circle" :src="require('@/assets/images/credit-card/1.jpeg')" />
      </div>
      <div class="nameWithImage-text">Mellissa</div>
    </div>
    <div class="EmployeeDataAssetsTable mt-32px">
      <div class="HrmTableHead-container">
        <div class="HrmTableHead one">NAme</div>
        <div class="HrmTableHead two">Category</div>
        <div class="HrmTableHead three">Description</div>
        <div class="HrmTableHead four">Serial number</div>
      </div>
      <vb-table class="HrmTable">
        <template #body>
          <tr class="dialer-row-select">
            <td class="dialer-row-title one">
              Laptop
            </td>
            <td class="dialer-row-title two">
              Computer
            </td>
            <td class="dialer-row-title three">
              One Lenovo Laptop do not break it or I will break your legs!
            </td>
            <td class="dialer-row-title four">
              445566
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
  </div>
</template>

<script>
import Chart from "chart.js";
export default {
  name: 'EmployeeDataAssets',
  data(){
    return {
      doughnutChart:{},
    }
  },
  methods: {
    doughnut(){
      const ctx = document.getElementById("doughnut-chart");
      this.doughnutChart?.destroy?.();
      const data = {
        labels: [
          'Male',
          'Female'
        ],
        datasets: [{
          label: 'My First Dataset',
          data: [300, 100],
          backgroundColor: [
            '#5576d1',
            'rgb(255, 205, 86)'
          ],
          hoverBackgroundColor:[
            '#5576d1',
            'rgb(255, 205, 86)'
          ],
          // borderWidth: 1,
          hoverOffset: 4
        }]
      };
      this.doughnutChart = new Chart(ctx, {
        type: 'doughnut',
        data: data,
        options:{
          radius: '50%',
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          responsive:true,
          cutoutPercentage: 70,
        }
      });
      // this.doughnutChart.defaults.global.elements.arc.borderWidth = 1
      this.doughnutChart.update();
    }
  },
  mounted(){
    this.doughnut()
  }
}
</script>

<style>

</style>