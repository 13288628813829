
import { VOIP_API } from "../index"

export const CALL_ACTIVITY_API_ENDPOINTS = {
  list(account,params,token) {
    return VOIP_API.axios.voip.request({
      url: `videocallcdrs/${account}`,
      method: "GET",
      params,
      cancelToken: token,
    })
  },
  newList(params,token) {
    return VOIP_API.axios.voip.request({
      url: `call/logs/activities`,
      method: "GET",
      params,
      cancelToken: token,
    })
  },
  makeCallLog(data={}){
    return VOIP_API.axios.voip.request({
      url: "call-logs-activity",
      method: "POST",
      data,
    })
  },
  makeTranscript(data={}){
    return VOIP_API.axios.voip.request({
      url: "recording-to-text",
      method: "POST",
      data,
    })
  },
  fetchTranscript(params={},token){
    return VOIP_API.axios.voip.request({
      url: "get-recording-transcription",
      method: "POST",
      params,
      cancelToken: token,
    })
  },
  getRecordings(params={},token) {
    return VOIP_API.axios.voip.request({
      url: "getallaccountcodecall",
      method: "GET",
      params,
      cancelToken: token,
    })
  },
  getStorage(params={},token) {
    return VOIP_API.axios.voip.request({
      url: "useraccountstorage",
      method: "GET",
      params,
      cancelToken: token,
    })
  },
  reportingfilterdata(params={},token) {
    return VOIP_API.axios.voip.request({
      url: "app-reporting",
      method: "GET",
      params,
      cancelToken: token,
    })
  },
  reportingfilterdataNew(params={},token) {
    return VOIP_API.axios.voip.request({
      url: "app-new-reporting",
      method: "GET",
      params,
      cancelToken: token,
    })
  },
  reportingfilterdataDownload(params={}) {
    return VOIP_API.axios.voip.request({
      url: "app-reporting",
      method: "GET",
      params,
      responseType: 'blob', 
    })
  },
  reportingVoicemail(params={},token){
    return VOIP_API.axios.voip.request({
      url: "reporting-voicemail",
      method: "GET",
      params,
      cancelToken: token,
    })
  },
  moniterstates(params={},cancelToken=''){
    return VOIP_API.axios.voip.request({
      url: `get-moniter-states`,
      method: "GET",
      params,
      cancelToken,
    })
  },
  teamsmoniterstates(cancelToken=''){
    return VOIP_API.axios.voip.request({
      url: `team-moniter`,
      method: "GET",
      cancelToken,
    })
  },
  callstates(params={},cancelToken=''){
    return VOIP_API.axios.voip.request({
      url: `call-states`,
      method: "GET",
      params,
      cancelToken,
    })
  },
  calltagsnotes(data={}){
    return VOIP_API.axios.voip.request({
      url: `calltagsnotes`,
      method: "POST",
      data,
    })
  },
  callinfo(data={}){
    return VOIP_API.axios.voip.request({
      url: `user-video-cdr`, // cdr-call-history
      method: "POST",
      data,
    })
  },
  searchcalllogs(data={}){
    return VOIP_API.axios.voip.request({
      url: `activity-search`,
      method: "POST",
      data,
    })
  },
  getCDR(params){
    return VOIP_API.axios.voip.request({
      url: `get-cdr`,
      method: "GET",
      params,
    })
  },
  callFeedback(data){
    return VOIP_API.axios.voip.request({
      url: `call-feedback`,
      method: "POST",
      data,
    })
  },
}