var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Assign Team")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Search Team",
      "onkeypress": "return /([0-9])/i.test(event.key)"
    },
    model: {
      value: _vm.filter.teams.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.teams, "search", $$v);
      },
      expression: "filter.teams.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_vm._l(_vm.filterTeams, function (team) {
    return _c('div', {
      key: team.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.api.add.send ? '' : _vm.selected.teams.includes(team.id) ? _vm.selected.teams.splice(_vm.selected.teams.indexOf(team.id), 1) : _vm.selected.teams.push(team.id);
        }
      }
    }, [_c('div', {
      staticClass: "d-flex"
    }, [_c('Info', {
      staticClass: "InfoComponentInsideAnroidIOSApp",
      attrs: {
        "id": team.id,
        "is_blf": false
      }
    })], 1), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "d-flex align-items-center forRadio-container"
    }, [_vm.selected.teams.includes(team.id) ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1)])]);
  }), !_vm.filterTeams.length ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api.list.send ? '' : 'There is no Numbers',
      "design": 3
    }
  }, [_vm.api.list.send ? _c('vb-loading', {
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2), _vm.selected.teams.length ? _c('b-button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "disabled": _vm.api.add.send
    },
    on: {
      "click": function ($event) {
        return _vm.add();
      }
    }
  }, [_vm._v(" Add Teams ")]) : _vm._e()], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }