<template>
  <modal class="dialer_animation right_side_popup EditTimeOffRequestModal HrmForModalScroll" name="EditTimeOffRequestModal"  :clickToClose="true"  :scrollable="true">
    <div class="modalHeading-container">
      <div class="modalHeading">Generate time off reports</div>
      <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('EditTimeOffRequestModal')">Close</button>
    </div>
    <div class="modalInner-container">
      <div class="innerShadowInput-container">
        <label>Time off policy</label>
        <b-dropdown class="hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText"></div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            All locations
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="daysLeftText">6 days left</div>
      <div class="hrmTabsContainer mt-32px">
        <div :class="`hrmTabsButton mr-16px ${showTabs == 'single' ? 'active' : ''}`" @click="showTabs = 'single'">Single day</div>
        <div :class="`hrmTabsButton ml-16px ${showTabs == 'multi' ? 'active' : ''}`" @click="showTabs = 'multi'">Multi - date</div>
      </div>
      <template v-if="showTabs== 'single'">
        <div class="d-flex mt-32px">
          <div class="innerShadowInput-container halfWidth">
            <label>Date</label>
            <div class="inputWithIconContainer">
              <input class="withIconOnRight primaryBorder noInnerBoxShadow"/>
              <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
            </div>
          </div>
        </div>
        <div class="innerShadowInput-container">
          <label>Textarea</label>
          <textarea />
        </div>
      </template>
      <template v-if="showTabs== 'multi'">
        <div class="d-flex mt-32px">
          <div class="innerShadowInput-container halfWidth">
            <label>From</label>
            <div class="inputWithIconContainer">
              <input class="withIconOnRight primaryBorder noInnerBoxShadow"/>
              <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
            </div>
          </div>
          <div class="innerShadowInput-container halfWidth">
            <label>To</label>
            <div class="inputWithIconContainer">
              <input class="withIconOnRight primaryBorder noInnerBoxShadow"/>
              <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
            </div>
          </div>
        </div>
        <div class="innerShadowInput-container">
          <label>Textarea</label>
          <textarea />
        </div>
      </template>
      <b-form-checkbox class="HrmCheckBox-container mt-32px">
        Notify employee by email
      </b-form-checkbox>
      <button class="dialer-button dialer-button-primary mt-32px mr-0"> Update </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'EditTimeOffRequestModal',
  data(){
    return {
      showTabs: 'single'
    }
  },
}
</script>

<style>

</style>