import { TokenService } from "../services/storage.service";
import { MATTERMOST_SOCKET_LOGGER, URLS } from '../utils';
var Emitter = require('component-emitter');
const events = {
  added_to_team: "added_to_team",
  authentication_challenge: 'authentication_challenge',
  channel_converted: "channel_converted",
  channel_created: "channel_created",
  channel_deleted: "channel_deleted",
  channel_member_updated: "channel_member_updated",
  channel_updated: "channel_updated",
  channel_viewed: "channel_viewed",
  config_changed: "config_changed",
  delete_team: "delete_team",
  direct_added: "direct_added",
  emoji_added: "emoji_added",
  ephemeral_message: "ephemeral_message",
  group_added: "group_added",
  hello: "hello",
  leave_team: "leave_team",
  license_changed: "license_changed",
  memberrole_updated: "memberrole_updated",
  new_user: "new_user",
  plugin_disabled: "plugin_disabled",
  plugin_enabled: "plugin_enabled",
  plugin_statuses_changed: "plugin_statuses_changed",
  post_deleted: "post_deleted",
  post_edited: "post_edited",
  post_unread: "post_unread",
  posted: "posted",
  preference_changed: "preference_changed",
  preferences_changed: "preferences_changed",
  preferences_deleted: "preferences_deleted",
  reaction_added: "reaction_added",
  reaction_removed: "reaction_removed",
  response: "response",
  role_updated: "role_updated",
  status_change: "status_change",
  typing: "typing",
  update_team: "update_team",
  user_added: "user_added",
  user_removed: "user_removed",
  user_role_updated: "user_role_updated",
  user_updated: "user_updated",
  dialog_opened: "dialog_opened",
  status: 'status',

  //own
  open: 'open',
  close: 'close',
  error: 'error',
}
const actions = {
  user_typing: 'user_typing',
  get_statuses: 'get_statuses',
  get_statuses_by_ids: 'get_statuses_by_ids',
  authentication_challenge: 'authentication_challenge',
}
export const mattermost = {
  socket: null,
  is_connected: false,
  is_init: false,
  connect_attempted: 0,
  get events(){ return events },
  callBacks: null,
  // onAddedToTeam,
  // onAuthenticationChallenge,
  // onChannelConverted,
  // onChannelCreated,
  // onChannelDeleted,
  // onChannelMemberUpdated,
  // onChannelUpdated,
  // onChannelViewed,
  // onConfigChanged,
  // onDeleteTeam,
  // onDirectAdded,
  // onEmojiAdded,
  // onEphemeralMessage,
  // onGroupAdded,
  // onHello,
  // onLeaveTeam,
  // onLicenseChanged,
  // onMemberroleUpdated,
  // onNewUser,
  // onPluginDisabled,
  // onPluginEnabled,
  // onPluginStatusesChanged,
  // onPostDeleted,
  // onPostEdited,
  // onPostUnread,
  // onPosted,
  // onPreferenceChanged,
  // onPreferencesChanged,
  // onPreferencesDeleted,
  // onReactionAdded,
  // onReactionRemoved,
  // onResponse,
  // onRoleUpdated,
  // onStatusChange,
  // onTyping,
  // onUpdateTeam,
  // onUserAdded,
  // onUserRemoved,
  // onUserRoleUpdated,
  // onUserUpdated,
  // onDialogOpened,
  // onStatus,
  // onOpen,
  // onClose,
  // onError,
  call_backs: {
    onaddedtoteam: null,
    onauthenticationchallenge: null,
    onchannelconverted: null,
    onchannelcreated: null,
    onchanneldeleted: null,
    onchannelmemberupdated: null,
    onchannelupdated: null,
    onchannelviewed: null,
    onconfigchanged: null,
    ondeleteteam: null,
    ondirectadded: null,
    onemojiadded: null,
    onephemeralmessage: null,
    ongroupadded: null,
    onhello: null,
    onleaveteam: null,
    onlicensechanged: null,
    onmemberroleupdated: null,
    onnewuser: null,
    onplugindisabled: null,
    onpluginenabled: null,
    onpluginstatuseschanged: null,
    onpostdeleted: null,
    onpostedited: null,
    onpostunread: null,
    onposted: null,
    onpreferencechanged: null,
    onpreferenceschanged: null,
    onpreferencesdeleted: null,
    onreactionadded: null,
    onreactionremoved: null,
    onresponse: null,
    onroleupdated: null,
    onstatuschange: null,
    ontyping: null,
    onupdateteam: null,
    onuseradded: null,
    onuserremoved: null,
    onuserroleupdated: null,
    onuserupdated: null,
    ondialogopened: null,
    onstatus: null,
    onopen: null,
    onclose: null,
    onerror: null,
  },
  init(){
    try {
      MATTERMOST_SOCKET_LOGGER.log('mattermost:init')
      this.is_init=true
      Emitter(this)
      if(process.env.NODE_ENV !== 'production'){
        window.mattermost = this
      }
    } catch(ex){
      MATTERMOST_SOCKET_LOGGER.danger('mattermost:init',ex)
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  connect(){
    try {
      MATTERMOST_SOCKET_LOGGER.log('mattermost:connect')
      const token = TokenService.MATTERMOST_TOKEN.get()
      if(!token) throw 'token are not available'
      if(this.socket) throw 'socket are not null'
      if(this.is_connected) throw 'already connected'
      this.socket = new WebSocket(URLS.chatSocket);
      this.socket.onopen = this.onopen.bind(this)
      this.socket.onclose = this.onclose.bind(this)
      this.socket.onerror = this.onerror.bind(this)
      this.socket.onmessage = this.onmessage.bind(this)
    } catch(error){
      MATTERMOST_SOCKET_LOGGER.danger('mattermost:connect',error)
    }
  },
  disconnect(){
    try {
      MATTERMOST_SOCKET_LOGGER.log('mattermost:disconnect')
      if(!this.socket || !this.socket.close) throw 'socket are null'
      if(!this.is_connected) throw 'already disconneted'
      this.socket.close()
      this.callBacks=null
      for (const key of Object.keys(this.call_backs)) {
        if(this.call_backs[key]) this.call_backs[key] = null
      }
      this.socket=null
    } catch(error) {
      MATTERMOST_SOCKET_LOGGER.danger('mattermost:disconnect',{error})
    }
  },
  reconnect(delay=5 * 1000){
    if(this.connect_attempted<6){
      setTimeout(()=>{
        this.connect()
        this.connect_attempted++
      }, delay)
    }
  },
  ononline(){
    this.connect_attempted=0
    this.reconnect(0)
  },
  onopen(Event){
    try {
      MATTERMOST_SOCKET_LOGGER.connected('mattermost:onopen',Event)
      const token = TokenService.MATTERMOST_TOKEN.get();
      this.actions.authentication(token);
      this.is_connected=true
      this.connect_attempted=0
      this.actions.sequence=0
      this.emitting(this.events.open,Event)
      window.addEventListener("beforeunload", this.disconnect.bind(this));
      window.addEventListener("online", this.ononline.bind(this));
    } catch(error){
      MATTERMOST_SOCKET_LOGGER.danger('mattermost:onopen',error)
    }
  },
  onclose(CloseEvent){
    try {
      MATTERMOST_SOCKET_LOGGER.connected("mattermost:onclose", CloseEvent);
      this.is_connected=false
      this.emitting(this.events.close,CloseEvent)
      this.socket = null
      if(TokenService.MATTERMOST_TOKEN.get()){
        this.reconnect()
      } else {
        window.removeEventListener("online", this.ononline.bind(this));
      }
      window.removeEventListener("beforeunload", this.disconnect.bind(this));
    } catch(error){
      MATTERMOST_SOCKET_LOGGER.danger('mattermost:onclose',error)
    }
  },
  onerror(ErrorEvent){
    try {
      MATTERMOST_SOCKET_LOGGER.connected("mattermost:onerror", ErrorEvent);
      this.emitting(this.events.error,ErrorEvent)
    } catch(error){
      MATTERMOST_SOCKET_LOGGER.danger('mattermost:onerror',error)
    }
  },
  onmessage(Event){
    try {
      const data = JSON.parse(Event.data);
      const event = data?.event ?? ''
      let new_data = null
      MATTERMOST_SOCKET_LOGGER.event('mattermost:onmessage',event || this.events.status,data)
      switch (event) {
        case this.events.added_to_team:
          // TODO
          break;
        case this.events.authentication_challenge:
          // TODO
          break;
        case this.events.channel_converted:
          // TODO
          break;
        case this.events.channel_created:
          new_data = {
            channel_id: data?.data?.channel_id
          }
          break;
        case this.events.channel_deleted:
          new_data = {
            channel_id: data?.data?.channel_id
          }
          break;
        case this.events.channel_member_updated:
          new_data = {
            channel_member: JSON.parse(data?.data?.channelMember)
          }
          break;
        case this.events.channel_updated:
          new_data = {
            channel_id: data?.broadcast?.channel_id,
            channel: JSON.parse(data?.data?.channel)
          }
          break;
        case this.events.channel_viewed:
          new_data = {
            channel_id: data?.data?.channel_id,
          }
          break;
        case this.events.config_changed:
          // TODO
          break;
        case this.events.delete_team:
          // TODO
          break;
        case this.events.direct_added:
          new_data = {
            channel_id: data?.broadcast?.channel_id
          }
          break;
        case this.events.emoji_added:
          // TODO
          break;
        case this.events.ephemeral_message:
          // TODO
          break;
        case this.events.group_added:
          // TODO
          break;
        case this.events.hello:
          // TODO
          break;
        case this.events.leave_team:
          // TODO
          break;
        case this.events.license_changed:
          // TODO
          break;
        case this.events.memberrole_updated:
          // TODO
          break;
        case this.events.new_user:
          // TODO
          break;
        case this.events.plugin_disabled:
          // TODO
          break;
        case this.events.plugin_enabled:
          // TODO
          break;
        case this.events.plugin_statuses_changed:
          // TODO
          break;
        case this.events.post_deleted:
          new_data = {
            channel_id: data?.broadcast?.channel_id,
            post: JSON.parse(data?.data?.post),
          }
          break;
        case this.events.post_edited:
          new_data = {
            channel_id: data?.broadcast?.channel_id,
            post: JSON.parse(data?.data?.post),
          }
          break;
        case this.events.post_unread:
          // TODO
          break;
        case this.events.posted:
          new_data = {
            channel_id: data?.broadcast?.channel_id,
            channel_type: data?.data?.channel_type,
            channel_display_name: data?.data?.channel_display_name,
            post: JSON.parse(data?.data?.post),
          }
          break;
        case this.events.preference_changed:
          // TODO
          break;
        case this.events.preferences_changed:
          new_data = {
            user_id: data?.broadcast?.user_id,
            preferences: JSON.parse(data?.data?.preferences),
          }
          break;
        case this.events.preferences_deleted:
          new_data = {
            user_id: data?.broadcast?.user_id,
            preferences: JSON.parse(data?.data?.preferences),
          }
          break;
        case this.events.reaction_added:
          new_data = {
            channel_id: data?.broadcast?.channel_id,
            reaction: JSON.parse(data?.data?.reaction),
          }
          break;
        case this.events.reaction_removed:
          new_data = {
            channel_id: data?.broadcast?.channel_id,
            reaction: JSON.parse(data?.data?.reaction),
          }
          break;
        case this.events.response:
          // TODO
          break;
        case this.events.role_updated:
          // TODO
          break;
        case this.events.status_change:
          new_data = {
            status: data?.data?.status
          }
          break;
        case this.events.typing:
          new_data = {
            user_id: data?.data?.user_id,
            channel_id: data?.broadcast?.channel_id,
          }
          break;
        case this.events.update_team:
          // TODO
          break;
        case this.events.user_added:
          new_data = {
            user_id: data?.data?.user_id,
            channel_id: data?.broadcast?.channel_id,
          }
          break;
        case this.events.user_removed:
          new_data = {
            user_id: data?.data?.user_id,
            removed_channel_id: data?.data?.channel_id,
            channel_id: data?.broadcast?.channel_id,
          }
          break;
        case this.events.user_role_updated:
          // TODO
          break;
        case this.events.user_updated:
          // TODO
          break;
        case this.events.dialog_opened:
          // TODO
          break;
        case this.events.status:
          // TODO
          break;
        default:
          break;
      }
      this.emitting(event || this.events.status,new_data || data)
    } catch(error){
      MATTERMOST_SOCKET_LOGGER.danger('mattermost:onmessage',error)
    }
  },
  emitOnUserLevel(event,data){
    if(!this.is_connected){
      this.emitting(event,data)
    }
  },
  emitting(event,payload){
    const callBack = function (fun,data) { if(typeof fun == 'function') fun(data) }
    switch (event) {
      case this.events.added_to_team:
        this.emit(event,payload)
        callBack(this.callBacks?.onAddedToTeam,payload)
        callBack(this.call_backs.onaddedtoteam,payload)
        break;
      case this.events.authentication_challenge:
        this.emit(event,payload)
        callBack(this.callBacks?.onAuthenticationChallenge,payload)
        callBack(this.call_backs.onauthenticationchallenge,payload)
        break;
      case this.events.channel_converted:
        this.emit(event,payload)
        callBack(this.callBacks?.onChannelConverted,payload)
        callBack(this.call_backs.onchannelconverted,payload)
        break;
      case this.events.channel_created:
        this.emit(event,payload)
        callBack(this.callBacks?.onChannelCreated,payload)
        callBack(this.call_backs.onchannelcreated,payload)
        break;
      case this.events.channel_deleted:
        this.emit(event,payload)
        callBack(this.callBacks?.onChannelDeleted,payload)
        callBack(this.call_backs.onchanneldeleted,payload)
        break;
      case this.events.channel_member_updated:
        this.emit(event,payload)
        callBack(this.callBacks?.onChannelMemberUpdated,payload)
        callBack(this.call_backs.onchannelmemberupdated,payload)
        break;
      case this.events.channel_updated:
        this.emit(event,payload)
        callBack(this.callBacks?.onChannelUpdated,payload)
        callBack(this.call_backs.onchannelupdated,payload)
        break;
      case this.events.channel_viewed:
        this.emit(event,payload)
        callBack(this.callBacks?.onChannelViewed,payload)
        callBack(this.call_backs.onchannelviewed,payload)
        break;
      case this.events.config_changed:
        this.emit(event,payload)
        callBack(this.callBacks?.onConfigChanged,payload)
        callBack(this.call_backs.onconfigchanged,payload)
        break;
      case this.events.delete_team:
        this.emit(event,payload)
        callBack(this.callBacks?.onDeleteTeam,payload)
        callBack(this.call_backs.ondeleteteam,payload)
        break;
      case this.events.direct_added:
        this.emit(event,payload)
        callBack(this.callBacks?.onDirectAdded,payload)
        callBack(this.call_backs.ondirectadded,payload)
        break;
      case this.events.emoji_added:
        this.emit(event,payload)
        callBack(this.callBacks?.onEmojiAdded,payload)
        callBack(this.call_backs.onemojiadded,payload)
        break;
      case this.events.ephemeral_message:
        this.emit(event,payload)
        callBack(this.callBacks?.onEphemeralMessage,payload)
        callBack(this.call_backs.onephemeralmessage,payload)
        break;
      case this.events.group_added:
        this.emit(event,payload)
        callBack(this.callBacks?.onGroupAdded,payload)
        callBack(this.call_backs.ongroupadded,payload)
        break;
      case this.events.hello:
        this.emit(event,payload)
        callBack(this.callBacks?.onHello,payload)
        callBack(this.call_backs.onhello,payload)
        break;
      case this.events.leave_team:
        this.emit(event,payload)
        callBack(this.callBacks?.onLeaveTeam,payload)
        callBack(this.call_backs.onleaveteam,payload)
        break;
      case this.events.license_changed:
        this.emit(event,payload)
        callBack(this.callBacks?.onLicenseChanged,payload)
        callBack(this.call_backs.onlicensechanged,payload)
        break;
      case this.events.memberrole_updated:
        this.emit(event,payload)
        callBack(this.callBacks?.onMemberroleUpdated,payload)
        callBack(this.call_backs.onmemberroleupdated,payload)
        break;
      case this.events.new_user:
        this.emit(event,payload)
        callBack(this.callBacks?.onNewUser,payload)
        callBack(this.call_backs.onnewuser,payload)
        break;
      case this.events.plugin_disabled:
        this.emit(event,payload)
        callBack(this.callBacks?.onPluginDisabled,payload)
        callBack(this.call_backs.onplugindisabled,payload)
        break;
      case this.events.plugin_enabled:
        this.emit(event,payload)
        callBack(this.callBacks?.onPluginEnabled,payload)
        callBack(this.call_backs.onpluginenabled,payload)
        break;
      case this.events.plugin_statuses_changed:
        this.emit(event,payload)
        callBack(this.callBacks?.onPluginStatusesChanged,payload)
        callBack(this.call_backs.onpluginstatuseschanged,payload)
        break;
      case this.events.post_deleted:
        this.emit(event,payload)
        callBack(this.callBacks?.onPostDeleted,payload)
        callBack(this.call_backs.onpostdeleted,payload)
        break;
      case this.events.post_edited:
        this.emit(event,payload)
        callBack(this.callBacks?.onPostEdited,payload)
        callBack(this.call_backs.onpostedited,payload)
        break;
      case this.events.post_unread:
        this.emit(event,payload)
        callBack(this.callBacks?.onPostUnread,payload)
        callBack(this.call_backs.onpostunread,payload)
        break;
      case this.events.posted:
        this.emit(event,payload)
        callBack(this.callBacks?.onPosted,payload)
        callBack(this.call_backs.onposted,payload)
        break;
      case this.events.preference_changed:
        this.emit(event,payload)
        callBack(this.callBacks?.onPreferenceChanged,payload)
        callBack(this.call_backs.onpreferencechanged,payload)
        break;
      case this.events.preferences_changed:
        this.emit(event,payload)
        callBack(this.callBacks?.onPreferencesChanged,payload)
        callBack(this.call_backs.onpreferenceschanged,payload)
        break;
      case this.events.preferences_deleted:
        this.emit(event,payload)
        callBack(this.callBacks?.onPreferencesDeleted,payload)
        callBack(this.call_backs.onpreferencesdeleted,payload)
        break;
      case this.events.reaction_added:
        this.emit(event,payload)
        callBack(this.callBacks?.onReactionAdded,payload)
        callBack(this.call_backs.onreactionadded,payload)
        break;
      case this.events.reaction_removed:
        this.emit(event,payload)
        callBack(this.callBacks?.onReactionRemoved,payload)
        callBack(this.call_backs.onreactionremoved,payload)
        break;
      case this.events.response:
        this.emit(event,payload)
        callBack(this.callBacks?.onResponse,payload)
        callBack(this.call_backs.onresponse,payload)
        break;
      case this.events.role_updated:
        this.emit(event,payload)
        callBack(this.callBacks?.onRoleUpdated,payload)
        callBack(this.call_backs.onroleupdated,payload)
        break;
      case this.events.status_change:
        this.emit(event,payload)
        callBack(this.callBacks?.onStatusChange,payload)
        callBack(this.call_backs.onstatuschange,payload)
        break;
      case this.events.typing:
        this.emit(event,payload)
        callBack(this.callBacks?.onTyping,payload)
        callBack(this.call_backs.ontyping,payload)
        break;
      case this.events.update_team:
        this.emit(event,payload)
        callBack(this.callBacks?.onUpdateTeam,payload)
        callBack(this.call_backs.onupdateteam,payload)
        break;
      case this.events.user_added:
        this.emit(event,payload)
        callBack(this.callBacks?.onUserAdded,payload)
        callBack(this.call_backs.onuseradded,payload)
        break;
      case this.events.user_removed:
        this.emit(event,payload)
        callBack(this.callBacks?.onUserRemoved,payload)
        callBack(this.call_backs.onuserremoved,payload)
        break;
      case this.events.user_role_updated:
        this.emit(event,payload)
        callBack(this.callBacks?.onUserRoleUpdated,payload)
        callBack(this.call_backs.onuserroleupdated,payload)
        break;
      case this.events.user_updated:
        this.emit(event,payload)
        callBack(this.callBacks?.onUserUpdated,payload)
        callBack(this.call_backs.onuserupdated,payload)
        break;
      case this.events.dialog_opened:
        this.emit(event,payload)
        callBack(this.callBacks?.onDialogOpened,payload)
        callBack(this.call_backs.ondialogopened,payload)
        break;
      case this.events.status:
        this.emit(event,payload)
        callBack(this.callBacks?.onStatus,payload)
        callBack(this.call_backs.onstatus,payload)
        break;
      case this.events.open:
        this.emit(event,payload)
        callBack(this.callBacks?.onOpen,payload)
        callBack(this.call_backs.onopen,payload)
        break;
      case this.events.close:
        this.emit(event,payload)
        callBack(this.callBacks?.onClose,payload)
        callBack(this.call_backs.onclose,payload)
        break;
      case this.events.error:
        this.emit(event,payload)
        callBack(this.callBacks?.onError,payload)
        callBack(this.call_backs.onerror,payload)
        break;
      default:
        break;
    }
  },
  actions: {
    get actions(){ return actions },
    sequence: 0,
    actionPayload(action, data) {
      this.sequence++;
      const action_payload = {
        seq: this.sequence,
        action: action,
        data: data,
      };
      return JSON.stringify(action_payload);
    },
    authentication(token=''){
      MATTERMOST_SOCKET_LOGGER.log(this)
      const payload = this.actionPayload(this.actions.authentication_challenge, {
        token: token,
      })
      mattermost.socket.send(payload);
    },
    usertyping(channel_id='',parent_id='') {
      const payload = this.actionPayload(this.actions.user_typing, {
        channel_id: channel_id,
        parent_id: parent_id,
      })
      mattermost.socket.send(payload);
    },
    getstatuses() {
      const payload = this.actionPayload(this.actions.get_statuses, null)
      mattermost.socket.send(payload);
    },
    getstatusesbyids(user_ids=[]) {
      const payload = this.actions.actionPayload(this.actions.get_statuses_by_ids, {
        user_ids: user_ids,
      })
      mattermost.socket.send(payload);
    },
  },
}