<template>
  <modal class="dialer_animation right_side_popup GenerateTimeOffReportsModal HrmForModalScroll" name="GenerateTimeOffReportsModal"  :clickToClose="true"  :scrollable="true">
    <div class="modalHeading-container">
      <div class="modalHeading">Generate time off reports</div>
      <button type="button" class="dialer-button dialer-button-delete" @click="$modal.hide('GenerateTimeOffReportsModal')">Close</button>
    </div>
    <div class="modalInner-container">
      <div class="innerShadowInput-container">
        <label>Employee</label>
        <b-dropdown class="hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="nameWithImage insideDropdown">
                <div class="imageContainer">
                  <img class="circle" :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">Mellissa</div>
              </div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item >
            <div class="nameWithImage insideDropdown">
              <div class="imageContainer">
                <img class="circle" :src="require('@/assets/images/credit-card/1.jpeg')" />
              </div>
              <div class="nameWithImage-text">Mellissa</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item >
            <div class="nameWithImage insideDropdown">
              <div class="imageContainer">
                <img class="circle" :src="require('@/assets/images/credit-card/2.jpeg')" />
              </div>
              <div class="nameWithImage-text">Mellissa</div>
            </div>
          </b-dropdown-item>
          <b-dropdown-item >
            <div class="nameWithImage insideDropdown">
              <div class="imageContainer">
                <img class="circle" :src="require('@/assets/images/credit-card/3.jpeg')" />
              </div>
              <div class="nameWithImage-text">Mellissa</div>
            </div>
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="innerShadowInput-container">
        <label>Time off policy</label>
        <b-dropdown class="withTags-hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="cancaleAbleTags-container">
                <div class="cancaleAbleTags">
                  <div class="cancaleAbleTags-text">Holiday</div>
                  <div class="cancaleAbleTags-icon">
                    <vb-icon icon="cancaleableTags-x-hrmIcon" />
                  </div>
                </div>
              </div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item >
            Holiday
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="d-flex">
        <div class="innerShadowInput-container halfWidth">
          <label>From</label>
          <div class="inputWithIconContainer">
            <input class="withIconOnRight primaryBorder noInnerBoxShadow"/>
            <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
          </div>
        </div>
        <div class="innerShadowInput-container halfWidth">
          <label>To</label>
          <div class="inputWithIconContainer">
            <input class="withIconOnRight primaryBorder noInnerBoxShadow"/>
            <vb-icon icon="calender-hrmIcon" width="18px" height="20px" class="svgInInput fillPrimary"/>
          </div>
        </div>
      </div>
      <div class="innerShadowInput-container">
        <label>Export time unit</label>
        <b-dropdown class="hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText"></div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            All locations
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="innerShadowInput-container">
        <label>Status</label>
        <b-dropdown class="hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText"></div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            All locations
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="grayCard px-4">
        <b-form-group class="HrmRadio-container">
          <b-form-radio class="HrmRadio" >
            Include full time off request if part of the request 
            falls in the selected date range
          </b-form-radio>
          <b-form-radio class="HrmRadio">
            Cut multi-date time off requests to show only the 
            days which fall in the selected date range
          </b-form-radio>
        </b-form-group>
      </div>
      <b-form-checkbox class="HrmCheckBox-container mt-32px">
        Advance filters
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Include additional custom fields in excel export
      </b-form-checkbox>
      <button class="dialer-button dialer-button-primary mt-32px mr-0"> Genrate </button>
    </div>
  </modal>
</template>

<script>
export default {
  name: 'GenerateTimeOffReportsModal',
}
</script>

<style>

</style>