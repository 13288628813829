
<template>
  <modal 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()" 
    class="MenuOptionExtensionAssignModal dialer_animation right_side_popup m-w-500 bigSquareCheckbox min-width-50percent-modal" 
    width="50%" 
    height="auto" 
    :scrollable="true"
  >
    <div class="dialer-assign-to md-pad-bottom dialer-edit">
      <div class="dialer-edit-header d-block">
        <div class="d-flex justify-content-between align-items-center w-100">
          <h2 class="dialer-settings-title newer">Press {{data.dtmf}} for</h2>
          <div class="dialer-edit-actions">
            <button class="newDoneButton" @click="extensionUpdated()">
              <vb-icon icon="squared-done-icon" height="38px" width="38px" />
              <span class="newDoneButton-textPart">Done</span>
            </button>
          </div>
        </div>
        <p class="vm--modal-text mb-0">Select where the call will be forwarded if '{{data.dtmf}}' is pressed in the IVR menu.</p>
      </div>
      <!-- tabs -->
      <div class="TabsDesignWithIcon-container mt-20px">
        <div 
          class="TabsDesignWithIcon-item" 
          v-for="(tab, key) in tabs" 
          :key="key" 
          @click="selected.tab = key" 
          :class="{
            'active': selected.tab == key,
          }"
        >
          <vb-icon :icon="tab.icon" height="38px" width="38px" />
          <span class="TabsDesignWithIcon-item-textPart">{{tab.text}}</span>
        </div>
      </div>
      <!-- search -->
      <div class="d-flex align-items-center justify-content-between w-100 mt-20px">
        <div v-if="selected.tab==tabs.USER.value" class="latestGreyBox-heading-main-24px">Users</div>
        <div v-else-if="selected.tab==tabs.TEAM.value" class="latestGreyBox-heading-main-24px">Teams</div>
        <div v-else-if="selected.tab==tabs.BASICIVR.value" class="latestGreyBox-heading-main-24px">IVR</div>
        <div v-else-if="selected.tab==tabs.QUEUE.value" class="latestGreyBox-heading-main-24px">Call queue</div>
        <div class="w-50 whiteBGinputWithGreyRoundedBorder">
          <b-input v-if="selected.tab==tabs.USER.value"  type="text" v-model="filter.user.search" placeholder="Search user" />
          <b-input v-else-if="selected.tab==tabs.TEAM.value" type="text" v-model="filter.team.search" placeholder="Search team" />
          <b-input v-else-if="selected.tab==tabs.BASICIVR.value" type="text" v-model="filter.ivr.search" placeholder="Search IVR" />
          <b-input v-else-if="selected.tab==tabs.QUEUE.value" type="text" v-model="filter.call_queue.search" placeholder="Search call queue" />
        </div>
      </div>
      <!-- list -->
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <b-alert :show="data.user_mode"  variant="info">
          <b-icon icon="info-circle-fill" variant="info" />
          <span class="ml-2">To ring multiple user you will need to turn off "dedicated number mode" or assign this number to a team where multiple user based on groups or departments can be added.</span>
        </b-alert>
        <div v-if="api.extensions_list.send" class="whiteBoxWithBorderInsideGreyBox">
          <div v-for="n in 4" :key="n.id" class="whiteBoxWithBorderInsideGreyBox-row">
            <div class="d-flex align-items-center">
              <div class="latestShimmerDesign mr-2" style="height:45px;width:45px"></div>
              <div class="d-flex flex-column">
                <div class="latestShimmerDesign" style="height:21px;width:100px"></div>
                <div class="latestShimmerDesign mt-1" style="height:21px;width:70%"></div>
              </div>
            </div>
            <div class="latestShimmerDesign mr-2" style="height:24px;width:24px"></div>
          </div>
        </div>
        <div v-else class="whiteBoxWithBorderInsideGreyBox">
          <div v-for="extension in filterExtensions" :key="extension.accountcode" class="whiteBoxWithBorderInsideGreyBox-row min-height-unset">
            <b-form-checkbox 
              :id="'checkbox-' + extension.accountcode" 
              :disabled="api.extension_update.send"
              :checked="selected.extensions.includes(extension.accountcode)" 
              @change="onChange(extension)"
              class="dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare less-padding leastPadding"
            >
              <Info :id="extension.accountcode" :name="extension.accountname" :sub_info="extension.extn" :is_blf="false" />
            </b-form-checkbox>
          </div>
          <vb-no-record class="my-5" v-if="isEmpty(filterExtensions)" :text="api.extensions_list.send ? '' : 'Sorry, No Data Found'" :design="3" />
        </div>
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from "../../utils";
import Info from '../Lists/Info.vue';
import _ from 'lodash'
import { mapGetters } from 'vuex';
const tabs = {
  USER: {
    text: "User",
    value: 'USER',
    icon: 'squared-singleUser-icon'
  },
  TEAM: {
    text: "Team",
    value: 'TEAM',
    icon: 'squared-multipleUsers-icon'
  },
  BASICIVR: {
    text: "IVR",
    value: 'BASICIVR',
    icon: 'squared-ivr-icon'
  },
  QUEUE: {
    text: "Call queue",
    value: 'QUEUE',
    icon: 'squared-callQueue-icon'
  },
}
export default {
  name: "MenuOptionExtensionAssignModal",
  components: { 
    Info,
  },
  inject:[
    'isEmpty',
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'MenuOptionExtensionAssignModal'
    },
  },
  data() {
    return {
      response: {
        extensions: {},
      },
      selected: {
        tab: tabs.USER.value,
        extensions: '',
      },
      api: {
        extensions_list: this.$helperFunction.apiInstance(),
        extension_update: this.$helperFunction.apiInstance(),
        update_user_mode: this.$helperFunction.apiInstance(),
      },
      filter: {
        user: {
          search: '',
        },
        team: {
          search: '',
        },
        ivr: {
          search: '',
        },
        call_queue: {
          search: '',
        },
      },
      data: {
        type: '',
        dtmf: '',
        accountcode: '',
        schedule_id: '',
        account: '',
        ringGroupMembers: [],
        ringgroup_id: '',
        user_mode: false
        ,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVoipUsersAlises',
    ]),
    tabs(){ return tabs },
    filterExtensions(){
      const tab = this.selected.tab
      let search = ''
      if(tab==tabs.USER.value) search = this.filter.user.search.trim().toLowerCase()
      else if(tab==tabs.TEAM.value) search = this.filter.team.search.trim().toLowerCase()
      else if(tab==tabs.BASICIVR.value) search = this.filter.ivr.search.trim().toLowerCase()
      else if(tab==tabs.QUEUE.value) search = this.filter.call_queue.search.trim().toLowerCase()
      return Object.values(this.response.extensions ?? {})?.filter?.(i=>i.account==tab && `${i.extn} ${i.accountname}`.trim().toLowerCase().includes(search)) ?? []
    },
    isUser(){ return !!this.getVoipUsersAlises[this.data.account] },
  },
  methods: {
    onBeforeOpen(event){
      console.log(event.params)
      const { account='', type='', dtmf='', schedule_id='', accountcode='', ringgroup_id='', user_mode=0, ringGroupMembers=[] } = event.params ?? {}
      const members = ringGroupMembers.map(i=>i.cg_item)
      this.data.type = type;
      this.data.dtmf = dtmf;
      this.data.accountcode = accountcode;
      this.data.schedule_id = schedule_id;
      this.data.account = account;
      this.data.ringgroup_id = ringgroup_id;
      this.data.user_mode = user_mode==1;
      this.data.ringGroupMembers = _.cloneDeep(members);
      this.selected.extensions = account==ringgroup_id ? members : [account];
      this.fetchExtensions();
    },
    onBeforeClose(){
      this.data.type = '';
      this.data.dtmf = '';
      this.data.accountcode = '';
      this.data.schedule_id = '';
      this.data.account = '';
      this.data.ringgroup_id = '';
      this.data.user_mode = false;
      this.data.ringGroupMembers = [];
      this.selected.extensions = [];
      this.selected.tab = tabs.USER.value;
      this.api.extensions_list.reset()
      this.api.extension_update.reset()
      this.filter.user.search=''
      this.filter.team.search=''
      this.filter.ivr.search=''
      this.filter.call_queue.search=''
      this.response.extensions={}
    },
    onChange(extension){
      const extensions = this.response.extensions
      const { accountcode } = extension ?? {}
      const index = this.selected.extensions.indexOf(accountcode)
      if(index>-1) {
        this.selected.extensions.splice(index,1)
      } else {
        if(!this.data.user_mode && extension.account=='USER' && this.selected.extensions.every(i=>extensions?.[i]?.account=='USER')) {
          this.selected.extensions.push(extension.accountcode)
        } else {
          this.selected.extensions=[extension.accountcode]
        }
      }
    },
    async fetchExtensions() {
      try {
        this.api.extensions_list.send=true
        const { data: { data=[] } } = await VOIP_API.endpoints.telephone_number.extensions()
        this.response.extensions = data;
      } finally {
        this.api.extensions_list.send=false
      }
    },
    async extensionUpdated() {
      if(this.api.extension_update.send) return;
      const toaster = this.appNotify({
        message: "Saving...",
        type: "info",
      });
      try {
        this.api.extension_update.send=true
        const { data } = await VOIP_API.endpoints.menu.addoption({
          account: this.selected.extensions,
          id: this.data.accountcode,
          option: this.data.dtmf,
          schedule_id: this.data.schedule_id,
          schedule_no: 1,
          type: "extension",
          command: "callfwd",
          args: this.selected.extensions.join(','),
        })
        this.appNotify({
          message: 'Successfully Updated',
          type: 'success',
        })
        this.$emit("updated",{
          menu: data?.data
        });
        this.$modal.hide(this.modalName)
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        toaster.close()
        this.api.extension_update.send=false
      }
    },
  },
};
</script>
