var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bill-file-main ml-2 mt-2"
  }, [_c('div', {
    staticClass: "bill-file-sub-main"
  }, [_vm.file.progress && _vm.file.progress.is_process ? _c('div', {
    staticClass: "bill-file-progress"
  }, [_c('div', {
    staticClass: "ml-2"
  }, [_c('small', [_vm._v(_vm._s(_vm.file.progress.loaded) + " / " + _vm._s(_vm.file.progress.total))]), _c('small', [_vm._v(_vm._s(_vm.file.progress.percentage) + " %")])]), _c('b-progress', {
    attrs: {
      "max": 100,
      "value": _vm.file.progress.percentage
    }
  })], 1) : _vm._e(), _c('span', {
    staticClass: "dialer-close-icon",
    on: {
      "click": function ($event) {
        return _vm.onCancel();
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x"
    }
  })], 1), _c('div', {
    staticClass: "bill-file-content"
  }, [_vm.fileDisplayType == 'image' ? _c('div', {
    staticClass: "bill-file-image-content",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.random_id}-MediaFilesModal`, {
          channel_id: _vm.channel_id,
          file_id: _vm.file.uid,
          is_action: true
        });
      }
    }
  }, [_c('img', {
    attrs: {
      "src": _vm.src
    }
  })]) : _vm.fileDisplayType == 'video' ? _c('div', {
    staticClass: "bill-file-video-content",
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.random_id}-MediaFilesModal`, {
          channel_id: _vm.channel_id,
          file_id: _vm.file.uid,
          is_action: true
        });
      }
    }
  }, [_c('VueVideoThumbnail', {
    attrs: {
      "video-src": _vm.src,
      "snapshot-at-duration-percent": parseInt(0, 10),
      "width": 500,
      "height": 300,
      "chunks-quantity": 4
    }
  })], 1) : _vm.fileDisplayType == 'audio' ? _c('div', {
    staticClass: "bill-file-audio-content"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm.src,
      "design": 'insideChatAudioRecord'
    }
  })], 1) : _c('div', {
    staticClass: "bill-file-card-content"
  }, [_c('div', {
    staticClass: "d-flex mt-3 topPart"
  }, [_c('span', {
    staticClass: "bill-file-card-icon"
  }, [_c('img', {
    attrs: {
      "src": _vm.file.file_image
    }
  })]), _c('span', {
    staticClass: "bill-file-card-name ml-2 mt-2"
  }, [_vm._v(_vm._s(_vm.file.name))])]), _c('div', {
    staticClass: "d-flex mt-2"
  }, [_c('small', {
    staticClass: "bill-file-card-size mr-2"
  }, [_vm._v(_vm._s(_vm.file.file_size))]), _c('small', {
    staticClass: "bill-file-card-type"
  }, [_vm._v(_vm._s(_vm.file.file_type))])])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }