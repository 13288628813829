<template>
  <div>
    <!-- class="center-zoomIn-transition v2 m-w-500 AddSoundFile"  -->
    <modal
      class="RecordingModalLikeSomething" 
      width="50%" 
      height="auto" 
      :name="modalName" 
      @before-open="onBeforeOpen($event)"
      @before-close="onBeforeClose()"
      :scrollable="true"
    >
      <div class="dialer-edit">
        <form @submit.prevent="addSound()" class="dialer-form">
          <div class="dialer-edit-header pt-0 d-block">
            <div class="d-flex justify-content-between align-items-center w-100">
              <!-- <h2 class="dialer-settings-title newer mb-0">Add recording</h2> -->
              <h2 class="dialer-settings-title newer mb-0">Upload your custom sound file </h2>
              <div class="dialer-edit-actions">
                <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
                <a id="CloseModalButton" v-else class="newCloseButton" @click="$modal.hide(modalName)">
                  <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                  <span class="newCloseButton-textPart">Close</span>
                </a>
              </div>
            </div>
            <div class="vm--modal-text">
              Enter the name of the music to be uploaded below, and select the MP3 audio file.
            </div>
          </div>
          <div class="latestGreyBox-9-9-2023 mt-20px">
            <div class="whiteBGinputWithGreyRoundedBorder">
              <div class="latestGreyBox-heading-main">Name</div>
              <input 
                class="w-100 mt-8px" 
                type="text" 
                v-model="forms.add_sound.name" 
                :disabled="api.add_sound.send" 
                :maxlength="$v.forms.add_sound.name.$params.maxLength.max"
              />
              <p v-if="(forms.add_sound.submitted && $v.forms.add_sound.name.$invalid) || api.add_sound.validation_errors.name" class="text text-danger animated bounceIntop mb-0">
                <span v-if="!$v.forms.add_sound.name.required">* Name is required</span>
                <span v-else-if="!$v.forms.add_sound.name.alphaNum">* Name can be only alphabet, number and space</span>
                <span v-else-if="!$v.forms.add_sound.name.minLength">* Name should be minimum {{$v.forms.add_sound.name.$params.minLength.min}} character</span>
                <span v-else-if="!$v.forms.add_sound.name.maxLength">* Name can be maximum {{$v.forms.add_sound.name.$params.maxLength.max}} character</span>
                <span v-for="(em,i) in api.add_sound.validation_errors.name" :key="i">{{em}}</span>
              </p>
            </div>
            <div v-if="conditions.select_category" class="whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px">
              <label>Category</label>
              <b-dropdown left>
                <template #button-content>
                  <span>{{ selectedSoundCategory | get_property('text','Select category')}}</span>
                  <b-icon icon="chevron-down" />
                </template>
                <b-dropdown-item v-for="category in soundCategory" :key="category.value" @click="forms.add_sound.category = category.value">
                  <span class="sm-mar-left">{{category.text}}</span>
                </b-dropdown-item>
              </b-dropdown>
              <p 
                v-if="(forms.add_sound.submitted && $v.forms.add_sound.category.$invalid) || api.add_sound.validation_errors.group" 
                class="text text-danger animated bounceIntop mb-0"
              >
                <span v-if="!$v.forms.add_sound.category.required">* Category is required</span>
                <span v-for="(em,i) in api.add_sound.validation_errors.group" :key="i">{{em}}</span>
              </p>
            </div>
            <div class="latestGreyBox-heading-main mt-32px">Your recording</div>
            <div class="latestUploadAndRecord-container mt-20px">
              <AudioFileInput 
                :disabled="api.add_sound.send" 
                @change="addSound($event)" 
                :allow_recording="allow_recording && forms.add_sound.category!=soundCategory.moh.value"
              />
              <p 
                v-if="(forms.add_sound.submitted && $v.forms.add_sound.file.$invalid) || api.add_sound.validation_errors.file" 
                class="text text-danger animated bounceIntop mb-0"
              >
                <span v-if="!$v.forms.add_sound.file.required">* File is required</span>
                <span v-for="(em,i) in api.add_sound.validation_errors.file" :key="i">{{em}}</span>
              </p>
            </div>
          </div>
          <!-- <div class="latestGreyBox-9-9-2023 mt-20px"> -->
            <!--<div class="latest-file-pond-container">
              <AudioFileInput 
                :disabled="api.add_sound.send" 
                @change="forms.add_sound.file=$event.file;forms.add_sound.file_type=$event.type" 
              />
              <!~~ <input 
                ref="upload-audio-input" 
                type="file" 
                accept="audio/mpeg, audio/wav" 
                v-show="false" 
                @change="onFileSelect(getProperty($event,'target.files',[]))" 
              />
              <div style="position: relative;" @dragenter.prevent="api.add_sound.send ? '' : conditions.drag_and_drop=true">
                <div 
                  v-if="conditions.drag_and_drop" 
                  class="chat-drag-and-drop" 
                  @dragover.prevent="conditions.drag_and_drop=true" 
                  @dragenter.prevent="conditions.drag_and_drop=true" 
                  @dragleave.prevent="conditions.drag_and_drop=false" 
                  @drop.prevent="conditions.drag_and_drop=false;onFileSelect(getProperty($event,'dataTransfer.files',[]))"
                >
                  Drag and Drop
                </div>
                <div v-else-if="!forms.add_sound.file" @click="api.add_sound.send ? '' : $refs['upload-audio-input'].click()">
                  File Size: Minimum 5 KB, maximum 5 MB. <br/>
                  Formats: MP3 and WAV.
                </div>
                <div v-else>
                  <vb-audio-player :src="forms.add_sound.file | fileToSrc" />
                  <b-button variant="link" :disabled="api.add_sound.send" @click="forms.add_sound.file=null;$refs['upload-audio-input'].value=null;">
                    <b-icon icon="x" variant="danger" />
                  </b-button>
                </div>
              </div> ~~>
              <p 
                v-if="(forms.add_sound.submitted && $v.forms.add_sound.file.$invalid) || api.add_sound.validation_errors.file" 
                class="text text-danger animated bounceIntop mb-0"
              >
                <span v-if="!$v.forms.add_sound.file.required">* File is required</span>
                <span v-for="(em,i) in api.add_sound.validation_errors.file" :key="i">{{em}}</span>
              </p>
            </div>-->
            <!--<div class="w-100 d-flex justify-content-end mt-20px">
              <b-button type="submit" :disabled="api.add_sound.send" class="dialer-button dialer-button-primary" variant="primary">
                <span v-if="api.add_sound.send">
                  {{ api.add_sound.upload_progress.percentage }}% Uploading
                </span>
                <span v-else>Submit</span>
              </b-button>
            </div>-->
          <!-- </div> -->
        </form>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '../../utils';
import { mapGetters } from 'vuex';
import required from "vuelidate/lib/validators/required";
import maxLength from 'vuelidate/lib/validators/maxLength';
import minLength from 'vuelidate/lib/validators/minLength';
import { helpers } from 'vuelidate/lib/validators';
import AudioFileInput from '../widgets/AudioFileInput.vue';
const sound_category = {
  moh: {
    text: "Music on hold", 
    value: 'moh'
  },
  announcement: {
    text: "Announcements", 
    value: 'announcement'
  },
  // ivr: {
  //   text: "IVR", 
  //   value: 'ivr'
  // },
}
export default {
  name: "AddRecordingModal",
  components: {
    AudioFileInput,
  },
  props: {
    modalName: {
      type: String,
      default: 'AddRecordingModal'
    },
    allow_recording:{
      type: Boolean,
      default: true
    }
  },
  inject: [
    'getProperty'
  ],
  data() {
    return {
      forms: {
        add_sound: this.$helperFunction.formInstance({
          data: {
            name: '',
            file: null,
            file_type: 'upload',
            shared: false,
            category: sound_category.moh.value,
          }
        }),
      },
      api: {
        add_sound: this.$helperFunction.apiInstance({ 
          error_message: true, 
          validation_errors: true, 
          upload_progress: true,
        }),
      },
      conditions: {
        drag_and_drop: false,
        select_category: true,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getIsMobile',
      'getCurrentUser',
      'getUserPermissions',
    ]),
    soundCategory(){ return sound_category },
    selectedSoundCategory(){ return sound_category[this.forms.add_sound.category] },
  },
  validations: {
    forms: {
      add_sound: {
        name: {
          required: required,
          alphaNum: helpers.regex('alphaNum',/^[a-zA-Z0-9 ]*$/i),
          maxLength: maxLength(20),
          minLength: minLength(3),
        },
        file: {
          required: required,
        },
        shared: {

        },
        category: {
          required: required,
        },
      },
    },
  },
  filters: {
    fileToSrc(file){
      return URL.createObjectURL(file);
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.forms.add_sound.name=event?.params?.suggested_name ?? ''
      this.forms.add_sound.category=event?.params?.group ?? sound_category.moh.value
      this.conditions.select_category=event?.params?.select_category ?? true
    },
    onBeforeClose(){
      this.forms.add_sound.reset()
      this.api.add_sound.reset()
      this.conditions.drag_and_drop=false
      this.conditions.select_category=true
    },
    async addSound(event){
      if(event) {
        this.forms.add_sound.file=event.file;
        this.forms.add_sound.file_type=event.type;
      }
      this.forms.add_sound.submitted=true
      this.$v.forms.add_sound.$touch()
      if(this.api.add_sound.send || this.$v.forms.add_sound.$invalid) return;
      try {
        this.api.add_sound.send=true
        this.api.add_sound.error_message=''
        this.api.add_sound.validation_errors={}
        const formData = new FormData();
        formData.append('file', this.forms.add_sound.file, this.forms.add_sound.file.name);
        formData.append('file_type', this.forms.add_sound.file_type);
        formData.append("accountcode", this.getCurrentUser.default_accountcode);
        formData.append("share", this.forms.add_sound.shared ? 'Y' : 'N');
        formData.append("uid", this.getCurrentUser.uid);
        formData.append("useraccount", this.getCurrentUser.account);
        formData.append("display_name", this.forms.add_sound.name);
        formData.append("group", this.forms.add_sound.category);
        await VOIP_API.endpoints.recording.create(formData,this.api.add_sound.upload_progress.onProgress.bind(this.api.add_sound.upload_progress))
        this.$emit('update-list');
        this.$modal.hide(this.modalName);
      } catch (ex) {
        this.api.add_sound.error_message=ex.own_message
        this.api.add_sound.validation_errors=ex.own_errors
      } finally {
        event?.reset()
        this.forms.add_sound.submitted=false
        this.api.add_sound.send=false
      }
    },
  },
};
</script>

<style>
/**
 * FilePond Custom Styles
 */
.filepond--drop-label {
  color: #4c4e53;
}

.filepond--label-action {
  text-decoration-color: #babdc0;
}

.filepond--panel-root {
  border-radius: 2em;
  background-color: #edf0f4;
  height: 1em;
}

.filepond--item-panel {
  background-color: #595e68;
}

.filepond--drip-blob {
  background-color: #7f8a9a;
}
/* [data-filepond-item-state="processing-complete"] .filepond--file-status-sub, */
.filepond--file-status .filepond--file-status-sub,
button.filepond--file-action-button.filepond--action-revert-item-processing {
  display: none;
}
</style>
