
import { VOIP_API } from "../index"

export const TODOS_API_ENDPOINTS = {
  list(params={}){
    return VOIP_API.axios.voip.request({
      url: "get/todo/call-history",
      method: "GET",
      params,
    })
  },
  assign(data={}){
    return VOIP_API.axios.voip.request({
      url: "assigned-todo-calls",
      method: "POST",
      data,
    })
  },
  addTodo(data={}){
    return VOIP_API.axios.voip.request({
      url: "add-calls/to/todo-list",
      method: "POST",
      data,
    })
  },
}