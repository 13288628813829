import { render, staticRenderFns } from "./ProfileImageUploader.vue?vue&type=template&id=35b71530&scoped=true&"
import script from "./ProfileImageUploader.vue?vue&type=script&lang=js&"
export * from "./ProfileImageUploader.vue?vue&type=script&lang=js&"
import style0 from "./ProfileImageUploader.vue?vue&type=style&index=0&id=35b71530&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "35b71530",
  null
  
)

export default component.exports