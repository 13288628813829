
<template>
  <div>
    <div class="mainHeading">Length of service report</div>
    <div class="mainDescription mb-4">
      <br/>
    </div>
    <div class="dropdownsContainer">
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All teams</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All teams
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All locations</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All locations
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All positions</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All positions
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-center mt-5">
      <canvas id="service-length-chart"></canvas>
    </div>
  </div>
</template>

<script>
import { HRM } from '@/data'
import Chart from "chart.js";
export default {
  name: 'LengthOfService',
  data() {
    return {
      serviceLengthChart: {}
    }
  },
  methods: {
    async barChart() {
      const ctx = document.getElementById("service-length-chart");
      this.serviceLengthChart?.destroy?.();
      this.serviceLengthChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: HRM.charts.lengthOfService.labels,
            datasets: [
              {
                label: 'Employees',
                data: HRM.charts.lengthOfService.Employees,
                backgroundColor: [
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1'
                ],
                hoverBackgroundColor: [
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1'
                ],
                borderColor: [
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1'
                ],
                borderWidth: 1
            },
          ]
        },
        options: {
          lineTension: 1,
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          scales: {
            xAxes: [{
                gridLines: {
                  display:false,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Employees'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
            }],
            yAxes: [{
                gridLines: {
                  // display:false,
                  drawBorder: false,
                },
                scaleLabel: {
                  beginAtZero: true,
                  display: true,
                  labelString: 'Time Slot'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
            }]
          }
        }
      });
      this.serviceLengthChart.update();
    },
  },
  mounted(){
    this.barChart()
  }
}
</script>

<style>

</style>