var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit s1"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.changePassword();
      }
    }
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Change password")]), _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  })], 1)]) : _c('div', {
    staticClass: "dialer-edit-header d-block"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v("Change password")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Update your login account password.")])]), _c('div', {
    staticClass: "md-mar-top"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.change_password.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.change_password.error_message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withIconsOnBothSides w-100"
  }, [_c('label', [_vm._v("Current password")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('vb-icon', {
    staticClass: "leftSideIcon",
    attrs: {
      "icon": "latest-lockInsideInput-icon",
      "width": "17.609px",
      "height": "20.124px"
    }
  }), (_vm.conditions.password_show.current ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.current_password,
      expression: "forms.change_password.current_password"
    }],
    staticClass: "touched w-100 py-0",
    attrs: {
      "name": "currentpassword",
      "disabled": _vm.api.change_password.send,
      "label": "Current password",
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.forms.change_password.current_password) ? _vm._i(_vm.forms.change_password.current_password, null) > -1 : _vm.forms.change_password.current_password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.forms.change_password.current_password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.forms.change_password, "current_password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.forms.change_password, "current_password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.forms.change_password, "current_password", $$c);
        }
      }
    }
  }) : (_vm.conditions.password_show.current ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.current_password,
      expression: "forms.change_password.current_password"
    }],
    staticClass: "touched w-100 py-0",
    attrs: {
      "name": "currentpassword",
      "disabled": _vm.api.change_password.send,
      "label": "Current password",
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.forms.change_password.current_password, null)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.forms.change_password, "current_password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.current_password,
      expression: "forms.change_password.current_password"
    }],
    staticClass: "touched w-100 py-0",
    attrs: {
      "name": "currentpassword",
      "disabled": _vm.api.change_password.send,
      "label": "Current password",
      "type": _vm.conditions.password_show.current ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.forms.change_password.current_password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.change_password, "current_password", $event.target.value);
      }
    }
  }), _c('vb-icon', {
    staticClass: "rightSideIcon fill-primary",
    attrs: {
      "width": "24px",
      "height": "24px",
      "icon": _vm.conditions.password_show.current ? 'profileSettings-eyeOff-icon' : 'profileSettings-eye-icon'
    },
    on: {
      "click": function ($event) {
        _vm.conditions.password_show.current = !_vm.conditions.password_show.current;
      }
    }
  })], 1), _vm.forms.change_password.submitted && _vm.$v.forms.change_password.current_password.$invalid || _vm.api.change_password.validation_errors.cpassword ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.change_password.current_password.required ? _c('span', [_vm._v("* Current password is required")]) : _vm._e(), _vm._l(_vm.api.change_password.validation_errors.cpassword, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder withIconsOnBothSides w-100 mt-20px"
  }, [_c('label', [_vm._v("New password")]), _c('div', {
    staticClass: "inputContainer-inside-whiteBGinputWithGreyRoundedBorder"
  }, [_c('vb-icon', {
    staticClass: "leftSideIcon",
    attrs: {
      "icon": "latest-lockInsideInput-icon",
      "width": "17.609px",
      "height": "20.124px"
    }
  }), (_vm.conditions.password_show.new ? 'text' : 'password') === 'checkbox' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.new_password,
      expression: "forms.change_password.new_password"
    }],
    staticClass: "touched w-100 py-0",
    attrs: {
      "name": "currentpassword",
      "disabled": _vm.api.change_password.send,
      "label": "Current password",
      "maxlength": _vm.$v.forms.change_password.new_password.$params.maxLength.max,
      "type": "checkbox"
    },
    domProps: {
      "checked": Array.isArray(_vm.forms.change_password.new_password) ? _vm._i(_vm.forms.change_password.new_password, null) > -1 : _vm.forms.change_password.new_password
    },
    on: {
      "change": function ($event) {
        var $$a = _vm.forms.change_password.new_password,
          $$el = $event.target,
          $$c = $$el.checked ? true : false;
        if (Array.isArray($$a)) {
          var $$v = null,
            $$i = _vm._i($$a, $$v);
          if ($$el.checked) {
            $$i < 0 && _vm.$set(_vm.forms.change_password, "new_password", $$a.concat([$$v]));
          } else {
            $$i > -1 && _vm.$set(_vm.forms.change_password, "new_password", $$a.slice(0, $$i).concat($$a.slice($$i + 1)));
          }
        } else {
          _vm.$set(_vm.forms.change_password, "new_password", $$c);
        }
      }
    }
  }) : (_vm.conditions.password_show.new ? 'text' : 'password') === 'radio' ? _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.new_password,
      expression: "forms.change_password.new_password"
    }],
    staticClass: "touched w-100 py-0",
    attrs: {
      "name": "currentpassword",
      "disabled": _vm.api.change_password.send,
      "label": "Current password",
      "maxlength": _vm.$v.forms.change_password.new_password.$params.maxLength.max,
      "type": "radio"
    },
    domProps: {
      "checked": _vm._q(_vm.forms.change_password.new_password, null)
    },
    on: {
      "change": function ($event) {
        return _vm.$set(_vm.forms.change_password, "new_password", null);
      }
    }
  }) : _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_password.new_password,
      expression: "forms.change_password.new_password"
    }],
    staticClass: "touched w-100 py-0",
    attrs: {
      "name": "currentpassword",
      "disabled": _vm.api.change_password.send,
      "label": "Current password",
      "maxlength": _vm.$v.forms.change_password.new_password.$params.maxLength.max,
      "type": _vm.conditions.password_show.new ? 'text' : 'password'
    },
    domProps: {
      "value": _vm.forms.change_password.new_password
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.change_password, "new_password", $event.target.value);
      }
    }
  }), _c('vb-icon', {
    staticClass: "rightSideIcon fill-primary",
    attrs: {
      "width": "24px",
      "height": "24px",
      "icon": _vm.conditions.password_show.new ? 'profileSettings-eyeOff-icon' : 'profileSettings-eye-icon'
    },
    on: {
      "click": function ($event) {
        _vm.conditions.password_show.new = !_vm.conditions.password_show.new;
      }
    }
  })], 1), _vm.forms.change_password.submitted && _vm.$v.forms.change_password.new_password.$invalid || _vm.api.change_password.validation_errors.password ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.change_password.new_password.required ? _c('span', [_vm._v("* password is required")]) : !_vm.$v.forms.change_password.new_password.validpassword ? _c('span', [_vm._v("* password is invalid")]) : !_vm.$v.forms.change_password.new_password.capitalalphabet ? _c('span', [_vm._v("* password required at least one capital character")]) : !_vm.$v.forms.change_password.new_password.smallalphabet ? _c('span', [_vm._v("* password required at least one small character")]) : !_vm.$v.forms.change_password.new_password.numbers ? _c('span', [_vm._v("* password required at least one integer character")]) : !_vm.$v.forms.change_password.new_password.specialcharacter ? _c('span', [_vm._v("* password required at least one special character")]) : !_vm.$v.forms.change_password.new_password.minLength ? _c('span', [_vm._v("* password is minimum " + _vm._s(_vm.$v.forms.change_password.new_password.$params.minLength.min) + " is required")]) : !_vm.$v.forms.change_password.new_password.maxLength ? _c('span', [_vm._v("* password is maximum " + _vm._s(_vm.$v.forms.change_password.new_password.$params.maxLength.max) + " character")]) : !_vm.$v.forms.change_password.new_password.notSameCurrentPassword ? _c('span', [_vm._v("password should not be same as your current password")]) : _vm._e(), _vm._l(_vm.api.change_password.validation_errors.password, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "d-flex justify-content-end w-100 mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.change_password.send
    }
  }, [_c('span', {
    staticClass: "d-flex justify-content-center position-relative"
  }, [_vm.api.change_password.send ? _c('vb-spinner') : [_vm._v("Update")]], 2)])])], 1)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }