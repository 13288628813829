<template>
    <section class="dialer-settings-section dialer-organizations-settings">
      <div class="dialer-flex">
        <h2 class="dialer-settings-title mb-0">Organizations</h2>
      </div>
      <p class="dialer-settings-intro with-button">
        <!-- <span>Here you can view your organizations.</span> -->
        <span>Organizations associated with your email address.</span>
      </p>
      
      <vb-table class="latestTableDesign-withBlackBorders-again"
        :isListEmpty="isEmpty(filterOrganizations)"
        :listLength="filterOrganizations.length"
        :perPage="5"
        :conditions="{
          loading: {
            show: false,
          },
        }"
      >
        <template slot="header">
          <tr>
            <th class="dialer-has-sort">
              <span>Name</span>
            </th>
            <th :class="['dialer-has-sort',isActionTabShow ? '' : 'dialer-col-right',]">
              <span>Active</span>
            </th>
            <th v-if="isActionTabShow" class="dialer-has-sort dialer-col-right">
              <span>Action</span>
            </th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <tr class="w-100" v-for="(organization, index) in filterOrganizations" :key="organization.id" v-show="index >= start && index <= end">
          <!-- @click="organization.id === $store.getters.getCurrentUser.selected_organization_id ? '' : switchOrganization(organization)" -->
            <td :class="['dialer-row-title',organization.id !== $store.getters.getCurrentUser.selected_organization_id ? 'w-50' : 'w-75']">
              <div class="d-flex w-100 align-items-center ">
                <div class="img-container mr-3 d-flex justify-content-center align-items-center">
                  <img :src="organization.id === $store.getters.getCurrentUser.selected_organization_id ? $store.getters.getCurrentUser.companyLogo : `${organizationimagespath}${organization.logo}` || ''" @error="$event.target.src=require('@/assets/images/user-male-icon.svg')" class="" style="height:35px;width:35px;border-radius: 40% 40% 40% 40% / 40% 40% 40% 40%;" />
                </div>
                {{ `${organization.label} (${organization.uid})` }}
              </div>
              
            </td>
            <td :class="['dialer-row-title',isActionTabShow ? '' : 'dialer-col-right',]" v-if="organization.id === $store.getters.getCurrentUser.selected_organization_id">
              <b-icon icon="check-circle-fill" variant="success" scale="1.5" />
            </td>
            <td v-if="organization.id !== $store.getters.getCurrentUser.selected_organization_id" class="dialer-col-right w-25">
              <b-button v-if="organization.id !== $store.getters.getCurrentUser.selected_organization_id" @click.stop="leave(organization)" squared variant="outline-danger">Leave</b-button>
            </td>
          </tr>

          
        </template>
      </vb-table>
    </section>
  
</template>

<script>
import { events, VOIP_API } from "../../utils";
export default {
  name: "Organizations",
  data(){
    return {
      filter: {
        organizations: {
          search: '',
        },
      },
    }
  },
  inject:['isEmpty'],
  computed: {
    isActionTabShow() {
      var isShow = false;
      var selected = this.$store.getters.getCurrentUser?.selected_organization_id;//this.current_user.selected_organization_id;
      // this.current_user.organizations.forEach((organization) => {
      this.$store.getters.getCurrentUser?.organizations.forEach((organization) => {
        if (organization.id !== selected) {
          isShow = true;
        }
      });
      return isShow;
    },
    organizationimagespath(){
      // return `${this.current_user.apiServer.split("/").slice(0, -1).join("/")}/storage/defaults/logos/`
      return `${this.$store.getters.getCurrentUser?.apiServer.split("/").slice(0, -1).join("/")}/storage/defaults/logos/`
    },
    filterOrganizations(){
      const search = this.filter.organizations.search.trim().toLowerCase()
      // const organizations = this.current_user.organizations || []
      const organizations = this.$store.getters.getCurrentUser?.organizations || []
      const filter_organizations = organizations.filter(item=>`${item.label} (${item.uid})`.trim().toLowerCase().includes(search))
      return filter_organizations
    },
  },
  methods: {
    switchOrganization(organization) {
      this.$root.$emit(events.switch_organization, {
        organization,
      });
    },
    leave(organization) {
      // if (organization.id === this.current_user.selected_organization_id)
      if (organization.id === this.$store.getters.getCurrentUser?.selected_organization_id) return;
      let vm = this;
      this.$appConfirmation({
        title: "Confirmation",
        message: `Are you sure you want to leave "${organization.label}" organization ?`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: async ({confirm}) => {
          if (confirm) {
            // await VOIP_API.endpoints.user.leaveoriganization(vm.current_user.account, {
            await VOIP_API.endpoints.user.leaveoriganization(vm.$store.getters.getCurrentUser?.account, {
              uid: vm.$store.getters.getCurrentUser?.uid,//vm.current_user.uid,
            });
          }
        },
      });
    },
  },
};
</script>