<template>
    <modal class="dialer_animation right_side_popup global-modal-hrm Scrollable-hrm" name="HRMAddNewTaskOnboardModal"  :clickToClose="true"  @before-open="beforeOpen" :scrollable="true">
      <form @submit.prevent="''">
        <div class="dialer-hrm-modal-header mar-top-hrm20 mar-bottom-hrm30">
          <h2 class="dialer-modal-hrm-title">Add new task</h2>
          <button class="dialer-hrm-button-modal" @click="$modal.hide('HRMAddNewTaskOnboardModal')">Close</button>
        </div>
        <div class="sp-gm-if">
          <label class="gm-hrm-label">Task</label>
          <div class="dd-gm-container">
            <b-dropdown no-caret class="gm-mainBorderfordropi">
              <template #button-content>
                <div class="gm-button-content-dropi">
                  <div class="gm-dropi-contenti"></div>
                  <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                </div>
              </template>
              <b-dropdown-item class="dropi-dropdown-toggle">All</b-dropdown-item>
              <b-dropdown-item class="dropi-dropdown-toggle">Single</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="sp-gm-if">
              <label for="Street" class="gm-hrm-label">Title</label>
              <b-form-input   placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Description (optional)</label>
          <b-form-textarea id="textarea-small" size="sm" placeholder="" class="gm-hrm-textnote"></b-form-textarea>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Link name</label>
          <b-form-input   placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="mar-bottom-hrm15">
          <label for="Street" class="gm-hrm-label">Link URL</label>
          <b-form-input   placeholder="" class="gm-hrm-fields"></b-form-input>
       </div>
        <div class="mar-bottom-hrm10">
          <b-form-checkbox class="gm-hrm-checkbox">
              <span class="home-checkbox-space">Require attachment</span>
          </b-form-checkbox>
        </div>
        <div class="sp-gm-if">
          <label class="gm-hrm-label">Assigned to</label>
          <div class="dd-gm-container">
            <b-dropdown no-caret class="gm-mainBorderfordropi">
              <template #button-content>
                <div class="gm-button-content-dropi">
                  <div class="gm-dropi-contenti"></div>
                  <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                </div>
              </template>
              <b-dropdown-item class="dropi-dropdown-toggle">All</b-dropdown-item>
              <b-dropdown-item class="dropi-dropdown-toggle">Single</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
        <div class="d-flex justify-content-start align-items-center">
          <h5 class="onboard-inline-text mb-imp mr-3">Task to be completed</h5>
          <b-form-input list="input-list"  placeholder="" class="gm-hrm-sm-fields"></b-form-input>
          <h5 class="onboard-inline-text mb-imp ml-3">days after employee start date</h5>
        </div>
        <button class="btn-primarynewupdated1 mt-4 mar-bottom-hrm50">Add task</button>
      </form>
    </modal>
</template>
<script>

export default {
  name: 'HRMAddNewTaskOnboardModal',
}
</script>

<style>

</style>