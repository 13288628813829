<template>
  <div>
    <div class="mainHeading">Your HR dashboard</div>

    <div class="grayCard flexColumn mt-32px">
      <div class="innerShadowInput-container whiteBG wd-46">
        <label>Your company name</label>
        <input class="noInnerBoxShadow"/>
      </div>
      <div class="innerShadowInput-container whiteBG wd-46">
        <label>Phone number</label>
        <input class="noInnerBoxShadow"/>
      </div>
      <div class="innerShadowInput-container wd-46">
        <label>Country </label>
        <b-dropdown class="hrmInnerShadow-dd bgWhite noShadow" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText">United Kingdom</div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            United Kingdom
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="innerShadowInput-container wd-46">
        <label>Default system language </label>
        <b-dropdown class="hrmInnerShadow-dd bgWhite noShadow" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText">English</div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            English
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="innerShadowInput-container wd-46">
        <label>Time zone</label>
        <b-dropdown class="hrmInnerShadow-dd bgWhite noShadow" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText">(GMT+00:00) London</div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            (GMT+00:00) London
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div class="innerShadowInput-container wd-46 mb-0">
        <label>Date format</label>
        <b-dropdown class="hrmInnerShadow-dd bgWhite noShadow" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText">All</div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            10 Aug 2022
          </b-dropdown-item>
        </b-dropdown>
      </div>
    </div>

    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Time format</div>
      <b-form-group class="HrmRadio-container wd-32  mt-32px">
        <b-form-radio class="HrmRadio" >
          12-hour (3:00 PM)
        </b-form-radio>
        <b-form-radio class="HrmRadio">
          24-hour (15:00)
        </b-form-radio>
      </b-form-group>
    </div>

    <div class="grayCard flexColumn mt-32px">
      <div class="grayCard-heading">Number of digits shown after comma</div>
      <b-form-group class="HrmRadio-container wd-32  mt-32px">
        <b-form-radio class="HrmRadio" >
          None
        </b-form-radio>
        <b-form-radio class="HrmRadio">
          1 (1.2)
        </b-form-radio>
        <b-form-radio class="HrmRadio" >
          2 (1.23)
        </b-form-radio>
        <b-form-radio class="HrmRadio">
          3 (1.234)
        </b-form-radio>
        <b-form-radio class="HrmRadio" >
          4 (1.2345)
        </b-form-radio>
      </b-form-group>
      <button class="dialer-button dialer-button-primary w-fit-content mt-32px mr-0"> Save </button>
    </div>

  </div>
</template>

<script>
export default {
  name: 'General'
}
</script>

<style>

</style>