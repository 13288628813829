<template>
  <div>
    <div class="mainHeading withButton">
      Time Off Approvers
    </div>
    <div class="mainDescription mb-4">
      Here you can set up who will receive time off requests for approval for your employees
    </div>

    <div class="dropdownsContainer">
      <b-dropdown no-caret class="primaryBorderDropdown w-240px">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All teams</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All teams
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown w-240px">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All locations</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All locations
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown w-240px">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All employment statuses</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All employment statuses
        </b-dropdown-item>
      </b-dropdown>
    </div>

    <div class="settings-TimeOffApproversTable mt-16px ">
      <div class="HrmTableHead-container">
        <div class="HrmTableHead one">Employee</div>
        <div class="HrmTableHead two">Employee</div>
        <div class="HrmTableHead three">Admin</div>
        <div class="HrmTableHead four">Direct manager</div>
        <div class="HrmTableHead five">Team Manager</div>
        <div class="HrmTableHead six">Individual approvers</div>
        <div class="HrmTableHead seven">Actions</div>
      </div>
      <vb-table class="HrmTable" >
        <!-- <template slot="header">
          <tr><th class="dialer-has-sort"></th></tr>
        </template> -->
        <template #body>
          <tr class="dialer-row-select">
            <td class="dialer-row-title one">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">Jackson</div>
              </div>
            </td>
            <td class="dialer-row-title two">20 days</td>
            <td class="dialer-row-title three">02 days</td>
            <td class="dialer-row-title four">-</td>
            <td class="dialer-row-title five">-</td>
            <td class="dialer-row-title six">20 days</td>
            <td class="dialer-row-title seven">02 days</td>
          </tr>
        </template>
      </vb-table>
    </div>

  </div>
</template>

<script>
export default {
  name: 'TimeOffApprovers',
}
</script>

<style>

</style>