<template>
  <div>
    <loaderMain />
  </div>
</template>

<script>
import LoaderMain from '../components/LoaderMain.vue';
// import { TIDIO } from '../tidio';
export default {
  components: { LoaderMain },
  name: 'MothershipNotListen',
  mounted(){
    // TIDIO.hide()
    // window.location.href = `https://${process.env.VUE_APP_URL_MOTHERSHIP}/`;
  },
}
</script>

<style>

</style>