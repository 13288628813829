<template>
  <div class="header">
    <h3>History</h3>
    <div>
      <b-button @click="downloadFile()">Download Logs</b-button>
    </div>

    <vb-table
      :isListEmpty="response.history.length == 0"
      :listLength="response.history.length"
      :loading="api.fetch_history.send"
    >
      <tr slot="header">
        <th class="dialer-has-sort">
          <span>Name</span>
        </th>
        <th class="dialer-has-sort dialer-col-center">
          <span>Action</span>
        </th>
        <th class="dialer-has-sort">
          <span>Performed On</span>
        </th>
        <th class="dialer-has-sort">
          <span>Performed At</span>
        </th>
      </tr>
      <template #body="{ start, end }">
        <tr
          v-for="(histo, index) in response.history"
          :key="histo.id"
          v-show="index >= start && index <= end"
        >
          <td td class="dialer-col-center wd-25">
            {{ histo.createdBy.lastName }}
          </td>
          <td>{{ histo.description }}</td>
          <td>{{ histo.performedOn }}</td>
          <td td class="dialer-col-center wd-25">{{ histo.createdAt }}</td>
        </tr>
      </template>
    </vb-table>
  </div>
</template>

<script>
import { VOIP_API } from "@/utils";
import { mapGetters } from "vuex";
import { GET_PAMARLY_ORGANIZATION_ID } from "@/store/helper/getters";

import * as XLSX from "xlsx";

export default {
  name: "PamarlyHistory",
  data() {
    return {
      api: {
        fetch_history: this.$helperFunction.apiInstance(),
        fetch_download: this.$helperFunction.apiInstance(),
      },
      response: {
        history: [],
      },
      design: {
        grid: true,
      },
      filters: {
        deleted_resources: {
          start_date: "",
          end_date: "",
        },
      },
      pagination: {
        deleted_resources: {
          per_page: 5,
          page: 1,
        },
      },
      dateInfo: {
        startDate: "",
        endDate: "",
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: { ...mapGetters([GET_PAMARLY_ORGANIZATION_ID]) },
  methods: {
    fetchHistory() {
      let vm = this;
      vm.api.fetch_history.send = true;
      VOIP_API.endpoints.pamarly.history
        .logs({
          organizationId: vm.GET_PAMARLY_ORGANIZATION_ID,
          pageNo: 1,
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then(({ data: { result: history } }) => {
          vm.response.history = history.rows;
        })
        .catch((ex) => {
          vm.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        })
        .finally(() => {
          vm.api.fetch_history.send = false;
        });
    },
    downloadFile() {
      let vm = this;
      vm.api.fetch_download.send = true;
      VOIP_API.endpoints.pamarly.download
        .logs({
          organizationId: vm.GET_PAMARLY_ORGANIZATION_ID,
          startDate: this.startDate,
          endDate: this.endDate,
        })
        .then((response) => {
          const data = response.data.result.rows;
          const newWorkbook = XLSX.utils.book_new();
          const newWorksheet = XLSX.utils.json_to_sheet(data);
          XLSX.utils.book_append_sheet(newWorkbook, newWorksheet, "data");
          XLSX.writeFile(newWorkbook, "Presidents.xlsx");
        })
        .catch((/* error */) => {
          // console.error(error);
        });
    },
  },
  mounted() {
    let today = new Date();
    let yyyy = today.getFullYear();
    this.startDate = yyyy + "-" + "01" + "-" + "01";
    this.endDate = yyyy + "-" + "12" + "-" + "31";

    this.fetchHistory();
  },
};
</script>

<style></style>
