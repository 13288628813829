var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500",
    attrs: {
      "name": _vm.modalName,
      "width": "50%",
      "height": "auto",
      "scrollable": true
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Assign to")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    on: {
      "click": function ($event) {
        return _vm.updateSelectedAccount();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-headers"
  }, _vm._l(_vm.tabs, function (tab, index) {
    return _c('div', {
      key: index,
      class: `dialer-tab text-capitalize ${_vm.selected.tab === tab.value ? 'selected' : ''}`,
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.value;
        }
      }
    }, [_vm._v(" " + _vm._s(tab.text) + " ")]);
  }), 0), _c('div', {
    staticClass: "dialer-tab-content"
  }, [_vm.selected.tab === 'USER' ? [_c('div', {
    staticClass: "w-100 whiteBGinputWithGreyRoundedBorder md-mar-bottom"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search user, extension"
    },
    model: {
      value: _vm.filter.user.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.user, "search", $$v);
      },
      expression: "filter.user.search"
    }
  })], 1), _c('ul', {
    staticClass: "list-unstyled dialer-asssignee-list"
  }, [_vm._l(_vm.filterUsers, function (extension) {
    return _c('div', {
      key: extension.accountcode
    }, [_c('li', {
      staticClass: "dialer-assignee cursor_auto"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare",
      attrs: {
        "id": 'checkbox-' + extension.accountcode,
        "checked": _vm.selected.extension_accountcode == extension.accountcode
      },
      on: {
        "change": function ($event) {
          _vm.selected.extension_accountcode = _vm.selected.extension_accountcode == extension.accountcode ? '' : extension.accountcode;
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": extension.accountcode,
        "name": extension.accountname,
        "sub_info": extension.extn
      }
    })], 1)], 1)]);
  }), _vm.isEmpty(_vm.filterUsers) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api_sent.extensions_list ? '' : 'Sorry, no data found',
      "design": 3
    }
  }, [_vm.api_sent.extensions_list ? _c('vb-loading', {
    staticClass: "my-2",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)] : _vm._e(), _vm.selected.tab === 'TEAM' ? [_c('div', {
    staticClass: "w-100 whiteBGinputWithGreyRoundedBorder md-mar-bottom"
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Search team, extension"
    },
    model: {
      value: _vm.filter.team.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.team, "search", $$v);
      },
      expression: "filter.team.search"
    }
  })], 1), _c('ul', {
    staticClass: "list-unstyled dialer-asssignee-list"
  }, [_vm._l(_vm.filterTeams, function (extension) {
    return _c('div', {
      key: extension.accountcode
    }, [_c('li', {
      staticClass: "dialer-assignee cursor_auto"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare",
      attrs: {
        "id": 'checkbox-' + extension.accountcode,
        "checked": _vm.selected.extension_accountcode == extension.accountcode
      },
      on: {
        "change": function ($event) {
          _vm.selected.extension_accountcode = _vm.selected.extension_accountcode == extension.accountcode ? '' : extension.accountcode;
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": extension.accountcode,
        "name": extension.accountname,
        "sub_info": extension.extn
      }
    })], 1)], 1)]);
  }), _vm.isEmpty(_vm.filterTeams) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api_sent.extensions_list ? '' : 'Sorry, no data found',
      "design": 3
    }
  }, [_vm.api_sent.extensions_list ? _c('vb-loading', {
    staticClass: "my-2",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)] : _vm._e(), _vm.selected.tab === 'BASICIVR' ? [_c('div', {
    staticClass: "w-100 whiteBGinputWithGreyRoundedBorder md-mar-bottom"
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Search user, extension"
    },
    model: {
      value: _vm.filter.ivr.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.ivr, "search", $$v);
      },
      expression: "filter.ivr.search"
    }
  })], 1), _c('ul', {
    staticClass: "list-unstyled dialer-asssignee-list"
  }, [_vm._l(_vm.filterIVRs, function (extension) {
    return _c('div', {
      key: extension.accountcode
    }, [_c('li', {
      staticClass: "dialer-assignee cursor_auto"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare",
      attrs: {
        "id": 'checkbox-' + extension.accountcode,
        "checked": _vm.selected.extension_accountcode == extension.accountcode
      },
      on: {
        "change": function ($event) {
          _vm.selected.extension_accountcode = _vm.selected.extension_accountcode == extension.accountcode ? '' : extension.accountcode;
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": extension.accountcode,
        "name": extension.accountname,
        "sub_info": extension.extn
      }
    })], 1)], 1)]);
  }), _vm.isEmpty(_vm.filterIVRs) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api_sent.extensions_list ? '' : 'Sorry, no data found',
      "design": 3
    }
  }, [_vm.api_sent.extensions_list ? _c('vb-loading', {
    staticClass: "my-2",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)] : _vm._e(), _vm.selected.tab === 'QUEUE' ? [_c('div', {
    staticClass: "w-100 whiteBGinputWithGreyRoundedBorder md-mar-bottom"
  }, [_c('b-input', {
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "placeholder": "Search user, extension"
    },
    model: {
      value: _vm.filter.call_queue.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.call_queue, "search", $$v);
      },
      expression: "filter.call_queue.search"
    }
  })], 1), _c('ul', {
    staticClass: "list-unstyled dialer-asssignee-list"
  }, [_vm._l(_vm.filterCallQueue, function (extension) {
    return _c('div', {
      key: extension.accountcode
    }, [_c('li', {
      staticClass: "dialer-assignee cursor_auto"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight p-0 makeCheckBoxesSquare",
      attrs: {
        "id": 'checkbox-' + extension.accountcode,
        "checked": _vm.selected.extension_accountcode == extension.accountcode
      },
      on: {
        "change": function ($event) {
          _vm.selected.extension_accountcode = _vm.selected.extension_accountcode == extension.accountcode ? '' : extension.accountcode;
        }
      }
    }, [_c('Info', {
      attrs: {
        "id": extension.accountcode,
        "name": extension.accountname,
        "sub_info": extension.extn
      }
    })], 1)], 1)]);
  }), _vm.isEmpty(_vm.filterCallQueue) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api_sent.extensions_list ? '' : 'Sorry, no data found',
      "design": 3
    }
  }, [_vm.api_sent.extensions_list ? _c('vb-loading', {
    staticClass: "my-2",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  }) : _vm._e()], 1) : _vm._e()], 2)] : _vm._e()], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }