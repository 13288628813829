<template>
  <modal
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"   
    :name="modalName" 
    @before-close="onBeforeClose()" 
    @before-open="onBeforeOpen($event)"
  >
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Add Pickup Groups</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div class="innerModalIos">
      

      <b-form @submit.prevent="create()">
        <b-alert :show="!!api.create.error_message" class="alert-danger mt-32px">{{ api.create.error_message }}</b-alert>
        <div class="basicWhiteIOScard mb-16px mt-32px">
          <b-form-group class="mb-0">
            <div class="basicWhiteIOScard-item w-100">
              <div class="mobileInputIOS InputIosV2 w-100">
                <label>Name</label>
                <b-form-input :disabled="api.create.send"  type="text" placeholder="Enter Name" v-model="forms.create.name" />
                <p v-if="forms.create.submitted && $v.forms.create.name.$invalid">
                  <span v-if="!$v.forms.create.name.required">*Name is Required</span>
                </p>
              </div>
            </div>
          </b-form-group>
        </div>
        <b-button type="submit" class="IosFullWidthButton forDone mb-16px" :disabled="api.create.send">
          <vb-spinner v-if="api.create.send" />
          <template v-else>Submit</template>
        </b-button>
      </b-form>

    </div>
  </modal>
</template>

<script>
import { required } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils/index';
export default {
  name: "AddPickUpGroupModal",
  props: {
    modalName: {
      type: String,
      default: 'AddPickUpGroupModal',
    },
  },
  data(){
    return {
      forms: {
        create: this.$helperFunction.formInstance({
          data: {
            name: '',
          }
        }),
      },
      api: {
        create: this.$helperFunction.apiInstance({ error_message: true }),
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
    ]),
  },
  validations: {
    forms: {
      create: {
        name: {
          required,
        }
      }
    }
  },
  methods: {
    onBeforeOpen(){
      // TODO
    },
    onBeforeClose(){
      this.forms.create.reset()
      this.api.create.reset()
    },
    create(){
      var vm = this
      vm.forms.create.submitted=true
      vm.$v.forms.create.$touch();
      // console.log(vm.$v.forms.create.$invalid || vm.api.create.send)
      if (vm.$v.forms.create.$invalid || vm.api.create.send) return;
      vm.api.create.send=true
      vm.api.create.error_message = ''
      VOIP_API.endpoints.pickupgroup.create({
        name: vm.forms.create.name,
        uid: vm.getCurrentUser?.uid,
        accountcode: vm.getCurrentUser?.account,
      })
      .then(()=>{
        vm.appNotify({
          message: "successfully added",
          type: 'success',
        })
        vm.$emit('load')
        vm.$modal.hide(vm.modalName)
      })
      .catch((ex)=>{
        vm.api.create.error_message = ex.own_message
      })
      .finally(()=>{
        vm.api.create.send=false
        vm.forms.create.submitted=false
      })
    },
  },
}
</script>

<style>

</style>