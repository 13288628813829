<template>
  <div>
    <modal
      class="dialer_animation right_side_popup m-w-500 AddManualNumberModal"
      width="500px"
      height="auto"
      :scrollable="true"
      :name="modalName"
    >
      <section class="ct-user-new dialer-edit">
        <form class="dialer-form lg-mar-bottom" @submit.prevent="add">
          <div v-if="$store.getters.getIsMobile" class="dialer-edit-header flex-column">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <h2 class="dialer-edit-title">Add Manual Number</h2>
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            </div>
            <div class="dialer-edit-actions  w-100 d-flex justify-content-end mt-2">
              <button class="newDoneButton" :disabled="api_sent.add">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api_sent.add" />
                  <template v-else>Done</template>
                </span>
              </button>
            </div>
          </div>
          <div v-else class="dialer-edit-header">
            <div>
              <h2 class="dialer-edit-title">Add Manual Number</h2>
            </div>
            <div class="dialer-edit-actions">
              <a class="dialer-button dialer-button-delete" @click="$modal.hide(modalName)">Cancel</a>
              <button class="newDoneButton" :disabled="api_sent.add">
                <vb-icon icon="squared-done-icon" height="38px" width="38px" />
                <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                  <vb-spinner v-if="api_sent.add" />
                  <template v-else>Done</template>
                </span>
              </button>
            </div>
          </div>
          <div class="md-mar-vertical">
            <div class="dialer-team-name mb-4">

              <div class="dialer-input-field">
                <div class="w-100">
                  <label>Number</label>
                  <input placeholder="Enter Number" class="w-100" :disabled="api_sent.add" min="1" type="number" onkeypress="return /([0-9])/i.test(event.key)" v-model="forms.add_manual_number.did" />
                </div>
                <template v-if="is_submitted.add">
                  <p v-if="!$v.forms.add_manual_number.did.required" class="text text-danger animated bounceIntop">
                    <span>* Number is required</span>
                  </p>
                  <p v-if="!$v.forms.add_manual_number.did.minValue" class="text text-danger animated bounceIntop">
                    <span>* Number is minimum 1</span>
                  </p>
                </template>
              </div>

              <div class="dialer-input-field">
                <div class="w-100">
                  <label>Description (Optional)</label>
                  <textarea placeholder="Enter Description" class="w-100" :disabled="api_sent.add" v-model="forms.add_manual_number.label"></textarea>
                </div>
              </div>

            </div>
          </div>
        </form>
      </section>
    </modal>
  </div>
</template>

<script>
import { required, minValue } from "vuelidate/lib/validators";
import { VOIP_API } from '../../utils';
import _ from 'lodash'
export default {
  name: "AddManualNumberModal",
  props: {
    modalName: {
      type: String,
      default: 'AddManualNumberModal',
    },
  },
  data(){
    return {
      forms: {
        add_manual_number: {
          did: '',
          label: '',
          reset(){
            this.did=''
            this.label=''
          },
        },
      },
      api_sent: {
        add: false,
      },
      is_submitted: {
        add: false,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  validations: {
    forms: {
      add_manual_number: {
        did: {
          required,
          minValue: minValue(1),
        },
      },
    },
  },
  methods: {
    add(){
      let vm = this
      this.is_submitted.add=true
      this.$v.$touch();
      if (this.$v.forms.add_manual_number.$invalid || this.api_sent.add) return;
      this.api_sent.add = true
      setTimeout(()=>{
        vm.is_submitted.add = false
        vm.api_sent.add = false
      },4*1000)
      VOIP_API.endpoints.telephone_number.manualnumber.create({
        accountcode: this.$store.getters.getCurrentUser?.account,//this.current_user.account,
        uid: this.$store.getters.getCurrentUser?.uid,//this.current_user.uid,
        did: this.forms.add_manual_number.did,
        label: this.forms.add_manual_number.label,
      }).then(()=>{
        vm.forms.add_manual_number.reset()
        vm.$modal.hide(vm.modalName)
        vm.$emit('load')
      }).catch((error)=>{
        vm.appNotify({
          message:  _.get(error,'response.data.message') || 'There is something wrong',
          type: 'danger',
        })
      }).finally(()=>{
        vm.is_submitted.add = false
        vm.api_sent.add = false
      })
    },
  },
};
</script>
