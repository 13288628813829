<template>
  <div v-if="!api.fetch_call_route.send && response.out_of_hours" class="d-flex justify-content-center">
    <div>
      <div :class="`d-flex align-items-start position-relative`">
        <div>
          <div class="nodeBasic-new" >
            <div class="nodeBasic-heading">In Active Hours?</div>
            <div class="d-flex justify-content-end mt-8px">
              <b-icon icon="pencil-fill" @click="$modal.show('DayOutOfHoursModal',{ accountcode: voipaccount })" />
            </div>
          </div>
          <div class="lineWithArrow longLine-150px pt-0 d-flex align-items-center">
            <div class="lineWithArrow-line "></div>
          </div>
          <div class="d-flex justify-content-center" style="width: 200px;">
            <div class="lineWithArrow">
              <div class="textLeftVerticalLine">Yes</div>
              <div class="lineWithArrow-line"></div>
              <div class="lineWithArrow-arrow"></div>
            </div>
          </div>
        </div>
        <!-- out of ours right section -->
        <div class="OHgraphLeftSide">
          <div :class="`lineOnlyForOH`" style="align-self: stretch;width: 300px">
            <div class="lineOnlyForOH-y"></div>
            <div class="lineOnlyForOH-x"></div>
          </div>
          <div class="nodeBasic-new">
            <template v-if="isAllDefaulted">
              <template v-if="defaultForwardSetting.forward_note == 'voicemail'">
                <div class="selectedText-big">Ask to leave a voicemail</div>
                <div class="selectedText-small">Call will be forward to voicemail</div>
              </template>
              <template v-else-if="defaultForwardSetting.forward_note == 'extension'">
                <div class="selectedText-big">Redirect to a user, team or IVR menu</div>
                <div class="selectedText-small">
                  Call will be forward to
                  <span 
                    class="color-primary cursor_pointer" 
                    :class="{
                      'text-underline': !defaultForwardSetting.extension_name
                    }"
                  >{{defaultForwardSetting.extension_name || defaultForwardSetting.forward_number || 'Select extension'}}</span>
                </div>
              </template>
              <template v-else-if="defaultForwardSetting.forward_note == 'number'">
                <div class="selectedText-big">Redirect to another number</div>
                <div class="selectedText-small">
                  Call will be forward to 
                  <span 
                    class="color-primary cursor_pointer" 
                    :class="{
                      'text-underline': !defaultForwardSetting.forward_number
                    }"
                  >{{defaultForwardSetting.forward_number || 'Select Number'}}</span>
                </div>
              </template>
              <template v-else>
                <div class="selectedText-big">Keep ringing</div>
                <div class="selectedText-small">Call forwarding is off</div>
              </template>
            </template>
            <template v-else>
              <div class="nodeBasic-heading">View Your Default Settings</div>
            </template>
            <span class="d-flex w-100 justify-content-end mt-8px" style="position: relative;" @click="$modal.show('DefaultOutOfHoursModal',{ accountcode: voipaccount, })">
              <b-icon icon="pencil-fill" variant="primary" />
            </span>
          </div>
        </div>
      </div>
    </div>
    <DefaultOutOfHoursModal @ofh-default-updated="fetchDefaultSetting();" />
    <DayOutOfHoursModal @ofh-days-updated="fetchSchedule();" />
  </div>
</template>

<script>
import DayOutOfHoursModal from '../../../Modals/DayOutOfHoursModal.vue'
import DefaultOutOfHoursModal from '../../../Modals/DefaultOutOfHoursModal.vue'
import { VOIP_API } from '@/utils';
export default {
  name: 'OutOfHoursNode',
  components: {
    DayOutOfHoursModal,
    DefaultOutOfHoursModal,
  },
  props: {
    voipaccount: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      api: {
        fetch_call_route: this.$helperFunction.apiInstance(),
        fetch_schedule: this.$helperFunction.apiInstance(),
        fetch_default_setting: this.$helperFunction.apiInstance(),
      },
      response: {
        schedules: [],
        out_of_hours: false,
        default_setting: {},
      },
    }
  },
  computed: {
    days() {
      return {
        mon: this.response.schedules?.find?.(i=>i.day=='mon'),
        tue: this.response.schedules?.find?.(i=>i.day=='tue'),
        wed: this.response.schedules?.find?.(i=>i.day=='wed'),
        thu: this.response.schedules?.find?.(i=>i.day=='thu'),
        fri: this.response.schedules?.find?.(i=>i.day=='fri'),
        sat: this.response.schedules?.find?.(i=>i.day=='sat'),
        sun: this.response.schedules?.find?.(i=>i.day=='sun'),
      };
    },
    isAllDefaulted(){
      return [
        this.days.mon?.setting_type,
        this.days.tue?.setting_type,
        this.days.wed?.setting_type,
        this.days.thu?.setting_type,
        this.days.fri?.setting_type,
        this.days.sat?.setting_type,
        this.days.sun?.setting_type
      ].every(i=>i=='default')
    },
    defaultForwardSetting(){
      return {
        extension_name: this.response.default_setting?.forward_name ?? '',
        forward_note: this.response.default_setting?.extension_type || 'none',
        forward_number: this.response.default_setting?.forward_to ?? '',
        number: this.response.default_setting?.forward_to ?? '',
      }
    },
  },
  methods: {
    async fetchcallRouteSetting() {
      try {
        this.api.fetch_call_route.send=true
        const { data } = await VOIP_API.endpoints.users.callroute({
          accountcode: this.voipaccount,
        })
        this.response.out_of_hours = !!data.schedule_enable;
      } finally {
        this.api.fetch_call_route.send=false
      }
    },
    async fetchSchedule() {
      if(this.api.fetch_schedule.send) return;
      try {
        this.api.fetch_schedule.send = true;
        const { data } = await VOIP_API.endpoints.users.schedules(this.voipaccount, {
          fid: 12,
        })
        this.response.schedules = data?.data ?? [];
      } finally {
        this.api.fetch_schedule.send = false;
      }
    },
    async fetchDefaultSetting(){
      if(this.api.fetch_default_setting.send) return;
      try {
        this.api.fetch_default_setting.send = true;
        const { data } = await VOIP_API.endpoints.users.getscheduledefault(this.voipaccount)
        this.response.default_setting = data || {};
      } finally {
        this.api.fetch_default_setting.send = false;
      }
    },
  },
  mounted(){
    this.fetchcallRouteSetting()
    this.fetchSchedule()
    this.fetchDefaultSetting()
  },
}
</script>

<style>

</style>