var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('div', {
    staticClass: "mainHeading withButton"
  }, [_vm._v(" Public holiday groups "), _c('button', {
    staticClass: "dialer-button dialer-button-primary mr-0 withIcon",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('hrmAddNewPublicHolidayGroupModal');
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-16px fillWhite",
    attrs: {
      "icon": "plus",
      "font-scale": "1.3",
      "scale": "1.5"
    }
  }), _vm._v(" Add new ")], 1)]), _c('div', {
    staticClass: "mainDescription mb-4"
  }, [_vm._v(" Here you can set up public holiday groups. ")]), _c('div', {
    staticClass: "documentsModule-Table mt-32px"
  }, [_c('vb-table', {
    staticClass: "HrmTable",
    scopedSlots: _vm._u([{
      key: "body",
      fn: function () {
        return [_c('tr', {
          staticClass: "dialer-row-select"
        }, [_c('td', {
          staticClass: "dialer-row-title one"
        }, [_vm._v(" General ")]), _c('td', {
          staticClass: "dialer-row-title two text-left"
        }, [_vm._v(" 10 ")]), _c('td', {
          staticClass: "dialer-col-right three"
        }, [_c('b-dropdown', {
          attrs: {
            "size": "lg",
            "variant": "link",
            "toggle-class": "text-decoration-none tb-notes-dd",
            "no-caret": "",
            "menu-class": "notes-table-dd"
          },
          scopedSlots: _vm._u([{
            key: "button-content",
            fn: function () {
              return [_c('b-icon', {
                attrs: {
                  "icon": "three-dots-vertical"
                }
              })];
            },
            proxy: true
          }])
        }, [_c('b-dropdown-item', {
          attrs: {
            "href": "#"
          }
        }, [_vm._v("Action")])], 1)], 1)])];
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [_c('tr', [_c('th', {
    staticClass: "HrmTableHead one pt-1"
  }, [_vm._v("Group")]), _c('th', {
    staticClass: "HrmTableHead text-left two pt-1"
  }, [_vm._v("Employees")]), _c('th', {
    staticClass: "HrmTableHead five pt-1"
  }, [_vm._v("Actions")])])])], 2)], 1), _c('modal', {
    staticClass: "dialer_animation right_side_popup hrmAddNewDocumentsModal HrmForModalScroll",
    attrs: {
      "name": "hrmAddNewPublicHolidayGroupModal"
    }
  }, [_c('div', [_c('div', {
    staticClass: "modalHeading-container"
  }, [_c('div', {
    staticClass: "modalHeading"
  }, [_vm._v("Public holiday group")]), _c('button', {
    staticClass: "dialer-button dialer-button-delete",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide('hrmAddNewPublicHolidayGroupModal');
      }
    }
  }, [_vm._v("Close")])]), _c('div', {
    staticClass: "modalInner-container"
  }, [_c('div', {
    staticClass: "innerShadowInput-container mt-25px"
  }, [_c('label', [_vm._v("Name")]), _c('input')]), _c('button', {
    staticClass: "dialer-button dialer-button-primary mt-32px mr-0"
  }, [_vm._v(" Add ")])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }