var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup billingAllNumbersModal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName,
      "clickToClose": false
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-box table"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0"
  }, [_vm._v("Addon packages ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])], 1)]), _c('div', {
    staticClass: "mt-5 mb-3"
  }, [_c('vb-table', {
    staticClass: "plan-license-table dialer-table dialer-table-bg-f3f3f3 VSpackagesTable mt-3 px-3 pb-3",
    attrs: {
      "isListEmpty": _vm.addonPrefixes.length == 0,
      "listLength": _vm.addonPrefixes.length,
      "perPage": 5,
      "loading": _vm.api_sent.get_prefixes
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.addonPrefixes, function (prefixe, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: prefixe.product_id,
            staticClass: "dialer-row-select mx-0 px-0"
          }, [_c('td', {
            directives: [{
              name: "b-toggle",
              rawName: "v-b-toggle",
              value: `prefix-bundles-${prefixe.id}`,
              expression: "`prefix-bundles-${prefixe.id}`"
            }],
            staticClass: "dialer-row-title pl-0"
          }, [_c('span', [_vm._v(" " + _vm._s(_vm._f("get_property")(prefixe, 'product.product_label')) + " "), _c('b-collapse', {
            attrs: {
              "id": `prefix-bundles-${prefixe.id}`
            }
          }, _vm._l(_vm.getProperty(prefixe, 'product.plan.vspackage.bundles', []), function (bundle) {
            return _c('div', {
              key: bundle.id
            }, [_vm._v(" " + _vm._s(_vm._f("get_property")(bundle, 'bundle.label')) + " (" + _vm._s(_vm._f("get_property")(bundle, 'balance')) + ") ")]);
          }), 0)], 1)]), _c('td', {
            staticClass: "dialer-has-sort pr-0"
          }, [_c('span', [_vm._v(_vm._s(_vm._f("to_fixed")(_vm._f("get_property")(prefixe, 'product.plan.plan_cost'))))])]), _c('td', {
            staticClass: "dialer-has-sort pr-0"
          }, [_c('span', [_vm._v(_vm._s(_vm._f("get_property")(prefixe, 'product.plan.plan_billing_period')))])]), _c('td', {
            staticClass: "dialer-has-sort dialer-col-right pr-0"
          }, [_c('button', {
            staticClass: "dialer-button dialer-button-primary",
            attrs: {
              "disabled": !!_vm.api_sent.update_addon_prefixes
            },
            on: {
              "click": function ($event) {
                return _vm.updateAddonPrefix(prefixe.product_id);
              }
            }
          }, [_vm.api_sent.update_addon_prefixes == prefixe.product_id ? _c('vb-spinner') : [_vm._v("Purchase")]], 2)])]);
        });
      }
    }])
  }, [_c('tr', {
    staticClass: "border-bottom-0",
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort pt-3 pb-3 pl-0 border-radius-16"
  }, [_c('span', {
    staticClass: "border-bottom pb-3 pr-4"
  }, [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort pt-3 pr-0 border-radius-16 pb-3"
  }, [_c('span', {
    staticClass: "border-bottom pb-3 pl-4"
  }, [_vm._v("Price")])]), _c('th', {
    staticClass: "dialer-has-sort pt-3 pr-0 border-radius-16 pb-3"
  }, [_c('span', {
    staticClass: "border-bottom pb-3 pl-4"
  }, [_vm._v("Billing Period")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-right pt-3 pr-0 border-radius-16 pb-3"
  }, [_c('span', {
    staticClass: "border-bottom pb-3 pl-4"
  }, [_vm._v("Action")])])])])], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }