var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-box alt"
  }, [_c('div', {
    staticClass: "dialer-tabs"
  }, [_c('div', {
    staticClass: "dialer-tab-headers justify-content-between"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.key,
      class: ['dialer-tab text-capitalize', {
        selected: _vm.selectedtab === tab
      }],
      on: {
        "click": function ($event) {
          _vm.selectedtab = tab;
        }
      }
    }, [_vm._v(" " + _vm._s(tab) + " ")]);
  }), 0)]), _c('div', {
    staticClass: "dialer-tab-content"
  }, [_vm.selectedtab === 'Participants' ? _c('div', {
    staticClass: "dialer_contact_list users_list allow_scroll"
  }, _vm._l(_vm.channelData.members, function (member) {
    return _c('div', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getVoipUsersAlises[member.user_id],
        expression: "$store.getters.getVoipUsersAlises[member.user_id]"
      }],
      key: member.user_id,
      staticClass: "dialer_contact_row"
    }, [_c('div', {
      staticClass: "dialer_contact justify-content-between"
    }, [_c('div', {
      staticClass: "dialer_contact_img"
    }, [_c('Info', {
      attrs: {
        "id": member.user_id
      }
    })], 1), _vm.$store.getters.getVoipUsersAlises[member.user_id].voipaccount !== _vm.$store.getters.getCurrentUser.account ? _c('div', {
      staticClass: "dialer_contact_hidden_area"
    }, [_c('div', {
      staticClass: "dialer_contact_connection_box"
    }, [_c('span', {
      staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD btnMute mr-1"
    }, [_c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": ""
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "scale": "1.5"
            }
          })];
        },
        proxy: true
      }], null, true)
    }, [_c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.dial(_vm.$store.getters.getVoipUsersAlises[member.user_id]);
        }
      }
    }, [_c('font-awesome-icon', {
      attrs: {
        "icon": "phone-alt"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Call")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.videoCall(_vm.$store.getters.getVoipUsersAlises[member.user_id]);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "camera-video"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Video")])], 1), _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.chatUser(_vm.$store.getters.getVoipUsersAlises[member.user_id]);
        }
      }
    }, [_c('b-icon', {
      attrs: {
        "icon": "chat"
      }
    }), _c('span', {
      staticClass: "ml-2"
    }, [_vm._v("Chat")])], 1)], 1)], 1)])]) : _vm._e()])]);
  }), 0) : _vm._e(), _vm.selectedtab === 'Pinned Messages' ? _c('div', {
    staticClass: "dialer_contact_list users_list allow_scroll",
    staticStyle: {
      "height": "calc(100vh - 243px)"
    }
  }, [_c('Pinned', {
    ref: "pinned",
    attrs: {
      "channel_id": _vm.$parent.$parent.pin_post.channel_id,
      "channelData": _vm.channelData,
      "channel": _vm.channel,
      "data": _vm.data
    }
  })], 1) : _vm._e(), _vm.selectedtab === 'Files' ? _c('div', {
    staticClass: "dialer_contact_list users_list allow_scroll user_files"
  }, _vm._l(_vm.files, function (file) {
    return _c('File', {
      key: file.id,
      attrs: {
        "file": file,
        "design": "files"
      }
    });
  }), 1) : _vm._e(), _c('DirectChatModal')], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }