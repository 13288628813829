var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 SmallAddModal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      },
      "closed": function ($event) {
        return _vm.onClose();
      }
    }
  }, [_c('section', {
    staticClass: "ct-user-new dialer-edit s1"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.addIvr();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-header d-block pb-0"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("Add IVR")]), _c('vb-icon', {
    staticClass: "ml-2 cursor_pointer checkSlotsModal-icon",
    attrs: {
      "icon": "checkSlotsModal-icon",
      "height": "22px",
      "width": "22px",
      "disabled": _vm.api.add_ivr.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('CheckedServiceAvailableModal', {
          service: 'ivrs',
          onProceed: _vm.addIvr
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "vm--modal-text"
  }, [_vm._v(" Enter the name of the new IVR below. ")])]), _vm.api.check_service.send ? [_c('div', {
    staticClass: "md-mar-top"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  })]), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })])])]), _c('div', {
    staticClass: "latestShimmerDesign mt-4",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })] : _c('div', {
    staticClass: "md-mar-top"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.add_ivr.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.add_ivr.error_message))]), _c('b-alert', {
    staticClass: "alertCustom",
    attrs: {
      "show": _vm.checkServiceAlert.enable,
      "variant": _vm.checkServiceAlert.variant
    }
  }, [_vm._v(_vm._s(_vm.checkServiceAlert.message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('label', [_vm._v("Name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.ivr.name,
      expression: "forms.ivr.name"
    }],
    staticClass: "w-100",
    attrs: {
      "disabled": _vm.api.add_ivr.send,
      "type": "text",
      "maxlength": _vm.$v.forms.ivr.name.$params.maxLength.max
    },
    domProps: {
      "value": _vm.forms.ivr.name
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.ivr, "name", $event.target.value);
      }
    }
  }), _vm.forms.ivr.submitted && _vm.$v.forms.ivr.name.$invalid || _vm.api.add_ivr.validation_errors.name ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.ivr.name.required ? _c('span', [_vm._v("* Name is required")]) : !_vm.$v.forms.ivr.name.minLength ? _c('span', [_vm._v("* Name is should be minimum " + _vm._s(_vm.$v.forms.ivr.name.$params.minLength.min) + " character")]) : !_vm.$v.forms.ivr.name.maxLength ? _c('span', [_vm._v("* Name is can be maximum " + _vm._s(_vm.$v.forms.ivr.name.$params.maxLength.max) + " character")]) : !_vm.$v.forms.ivr.name.valid ? _c('span', [_vm._v("* Name can be number and alphabet")]) : _vm._e(), _vm._l(_vm.api.add_ivr.validation_errors.name, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  })], 2) : _vm._e()])]), _c('div', {
    staticClass: "w-100 mt-4"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.add_ivr.send
    }
  }, [_vm.api.add_ivr.send ? _c('vb-spinner') : [_vm._v("Create IVR")]], 2)])], 1)], 2)])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }