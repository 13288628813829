<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.edit">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.remove.send ? '' : conditions.screen=screens.view">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit User</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">

            <form class="dialer-form lg-mar-bottom" @submit.prevent="''">
              <div class="basicWhiteIOScard mt-32px mb-16px">
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Extension</label>
                    <input 
                      placeholder="Enter Name" 
                      class="w-100" 
                      type="text" 
                      maxlength="4"
                      oninput="this.value = this.value.replace(/[^0-9]/gi, '')"
                      :disabled="api.remove.send || api.update_extension.send" 
                      v-model="forms.update_extension.value" 
                      @change="updateExtension()"
                    />
                    <p 
                      v-if="(forms.update_extension.submitted && $v.forms.update_extension.value.$invalid) || api.update_extension.validation_errors.value" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_extension.value.required">* Extension is required</span>
                      <span v-else-if="!$v.forms.update_extension.value.numeric">* Extension is should be numeric</span>
                      <span v-else-if="!$v.forms.update_extension.value.valid">* Extension is valid</span>
                      <span v-if="api.update_extension.error_message">{{ api.update_extension.error_message }}</span>
                      <span v-for="em in api.update_extension.validation_errors.value" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Display Name</label>
                    <input 
                      placeholder="Enter Name" 
                      class="w-100" 
                      type="text" 
                      :disabled="api.remove.send || api.update_displayname.send" 
                      v-model="forms.update_displayname.value" 
                      @change="updateDisplayName()"
                    />
                    <p 
                      v-if="(forms.update_displayname.submitted && $v.forms.update_displayname.value.$invalid) || api.update_displayname.validation_errors.value" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_displayname.value.required">* Display Name is required</span>
                      <span v-if="api.update_displayname.error_message">{{ api.update_displayname.error_message }}</span>
                      <span v-for="em in api.update_displayname.validation_errors.value" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <div v-if="isInvitedUser" class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Email</label>
                    <input 
                      placeholder="Enter Name" 
                      class="w-100" 
                      type="text" 
                      :disabled="api.remove.send || api.update_email.send" 
                      v-model="forms.update_email.value" 
                      @change="updateEmail()"
                    />
                    <p 
                      v-if="(forms.update_email.submitted && $v.forms.update_email.value.$invalid) || api.update_email.validation_errors.value" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_email.value.email">* Email is invalid</span>
                      <span v-if="api.update_email.error_message">{{ api.update_email.error_message }}</span>
                      <span v-for="em in api.update_email.validation_errors.value" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <div class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>{{ isMainUser ? 'Email' : 'Username' }}</label>
                    <input 
                      placeholder="Enter Name" 
                      class="w-100" 
                      type="text" 
                      :disabled="api.remove.send || api.update_username.send" 
                      v-model="forms.update_username.value" 
                      @change="updateUsername()"
                    />
                    <p 
                      v-if="(forms.update_username.submitted && $v.forms.update_username.value.$invalid) || api.update_username.validation_errors.value || api.update_username.error_message" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_username.value.required">* {{ isMainUser ? 'Email' : 'Username' }} is required</span>
                      <span v-else-if="!$v.forms.update_username.value.invalid">* {{ isMainUser ? 'Email' : 'Username' }} is invalid</span>
                      <span v-else-if="!$v.forms.update_username.value.maxLength">
                        * {{ isMainUser ? 'Email' : 'Username' }} is maximum {{ $v.forms.update_username.value.$params.maxLength.max }} character
                      </span>
                      <span v-if="api.update_username.error_message">{{ api.update_username.error_message }}</span>
                      <span v-for="em in api.update_username.validation_errors.value" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <div v-if="!isInvitedUser" class="basicWhiteIOScard-item">
                  <div class="mobileInputIOS InputIosV2">
                    <label>Password</label>
                    <input 
                      placeholder="********" 
                      class="w-100" 
                      :type="conditions.password_show?'text':'password'" 
                      :disabled="api.remove.send || api.update_password.send" 
                      v-model="forms.update_password.value" 
                      @change="updatePassword()"
                      :readonly="true" 
                      onfocus="this.removeAttribute('readonly')"
                    />
                    <b-icon
                      class="dialer-showPassword-icon cursor_pointer"
                      :icon="conditions.password_show ? 'eye-slash' : 'eye'"
                      @click="api.update_password.send ? '' : conditions.password_show = !conditions.password_show"
                    />
                    <p 
                      v-if="(forms.update_password.submitted && $v.forms.update_password.value.$invalid) || api.update_password.validation_errors.value" 
                      class="text text-danger animated bounceIntop"
                    >
                      <span v-if="!$v.forms.update_password.value.required">* Password is required</span>
                      <span 
                        v-else-if="!$v.forms.update_password.value.minLength"
                      >
                        * Password is minimum {{ $v.forms.update_password.value.$params.minLength.min }}
                      </span>
                      <span v-if="api.update_password.error_message">{{ api.update_password.error_message }}</span>
                      <span v-for="em in api.update_password.validation_errors.value" :key="em">* {{ em }}</span>
                    </p>
                  </div>
                </div>
                <div v-else class="basicWhiteIOScard-item" @click="api.remove.send ? '' : requestForResetPassword()">
                  Request For Update Password
                  <p v-if="api.request_reset.error_message" class="text text-danger animated bounceIntop">
                    <span>{{ api.request_reset.error_message }}</span>
                  </p>
                </div>
              </div>
            </form>

          </div>
        </div>
      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Edit User</div>
          <div class="plus-IOSIcon-container" @click="conditions.screen=screens.edit">
            <div class="textInsideCard onRightSide primaryColor">Edit</div>
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">

            <div class="profile-image-section-container forIos mt-32px">
              <div 
                class="profile-image-section"
                @click="$modal.show('EditUserProfileImageUploader',{ 
                  type: 'USER', 
                  accountcode: accountcode, 
                  current_image: userImage
                })"
              >
                <div class="profile-img">
                  <img 
                    style="height: 100%; width: 100%" 
                    id="user-profile-image-updated" 
                    :src="userImage" 
                    @error="$event.target.src=local_filess.images.user_male" 
                    :alt="userDisplayName" 
                  />
                </div>
              </div>
            </div>
            <div class="BiggestMainHeading mt-22px">{{ userDisplayName }} ({{ user?.extn || '' }})</div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item" @click="api.remove.send ? '' : $modal.show(`${_uid}-AssignNumberModal`, { accountcode, })">
                <div class="headingInsideTable">User Numbers</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ dids.length }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div class="basicWhiteIOScard-item" @click="api.remove.send ? '' : $modal.show('EditUserAssignTeamModal', { accountcode, })">
                <div class="headingInsideTable">User Teams</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ teams.length }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
            </div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item" @click="$modal.show('CallsSetting',{ accountcode, user: response.user_detail })">
                <div class="headingInsideTable">Calls Setting</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <div class="basicWhiteIOScard-item" @click="$modal.show('PhoneSetting',{ accountcode, })">
                <div class="headingInsideTable">Phone Setting</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <div class="basicWhiteIOScard-item" @click="$modal.show('VoicemailSetting',{ accountcode, })">
                <div class="headingInsideTable">Voicemail Setting</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
            </div>

            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div v-if="subscriptionPlan" class="basicWhiteIOScard-item" @click="api.remove.send ? '' : $modal.show('UserPrefix', { accountcode, })">
                <div class="headingInsideTable">Call Prefix</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ response.user_detail | prefixLabel }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <div class="basicWhiteIOScard-item" @click="api.remove.send ? '' : $modal.show('EditUserEmailGroupsModal', { accountcode, })">
                <div class="headingInsideTable">Email Groups</div>
                <div class="d-flex align-items-center">
                  <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ groups.length }}</div>
                  <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                </div>
              </div>
              <!-- @click="api.remove.send ? '' : $modal.show('UserExtensionDetail', { accountcode, })" -->
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideTable">Extension Limits</div>
                <!-- <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" /> -->
              </div>
            </div>

            <div v-if="!isMainUser" class="basicWhiteIOScard mt-32px mb-16px">
              <div class="basicWhiteIOScard-item">
                <div class="headingInsideCard">Set as Admin</div>
                <div class="position-relative">
                  <b-form-checkbox 
                    class="dialer-switch IosSwitch" 
                    name="check-button"
                    switch
                    ref="role"
                    :disabled="api.update_role.send"
                    :checked="isAdmin"
                    @change="updateRole($event)"
                  />
                </div>
              </div>
            </div>

            <button v-if="!isMainUser" :disabled="api.remove.send" class="IosFullWidthButton forRemove mb-16px" @click="remove()">
              <vb-spinner v-if="api.remove.send" />
              <template>Delete</template>
            </button>

          </div>
        </div>
      </template>
      <ProfileImageUploaderModal :modalName="'EditUserProfileImageUploader'" @image-uploaded="$emit('update-list')" />
      <AssignNumbersModal :modalName="`${_uid}-AssignNumberModal`" @added="$emit('update-list');" />
      <AssignTeamsModal :modalName="'EditUserAssignTeamModal'" @added="$emit('update-list');fetchUserDetail(false);" />
      <EmailGroupsModal :modalName="'EditUserEmailGroupsModal'" @added="$emit('update-list');" />
      <UserPrefixModal />
      <!-- <UserExtensionDetailModal /> -->
      <CallsSettingModal />
      <PhoneSettingModal />
      <VoicemailSettingModal />
    </modal>
  </div>
</template>

<script>
import ProfileImageUploaderModal from '../common/ProfileImageUploader.vue'
import AssignNumbersModal from '../numbers/AssignNumbers.vue'
import AssignTeamsModal from '../teams/AssignTeams.vue'
import EmailGroupsModal from './EmailGroups.vue'
import UserPrefixModal from './UserPrefix.vue'
// import UserExtensionDetailModal from '../extensions/UserExtensionDetail.vue'


import CallsSettingModal from '../CallsSetting.vue'
import PhoneSettingModal from '../PhoneSetting.vue'
import VoicemailSettingModal from '../VoicemailSetting.vue'

import { VOIP_API } from "@/utils";

import { mapGetters } from 'vuex';
import { email, maxLength, minLength, numeric, required } from 'vuelidate/lib/validators';

let screens = {
  edit: 'edit',
  main: 'main',
}
export default {
  name: "EditUserModal",
  components: {
    ProfileImageUploaderModal,
    AssignNumbersModal,
    AssignTeamsModal,
    EmailGroupsModal,
    UserPrefixModal,
    // UserExtensionDetailModal,

    CallsSettingModal,
    PhoneSettingModal,
    VoicemailSettingModal,
  },
  inject: ['local_filess', 'getVoipUsers','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'EditUser'
    },
  },
  data() {
    return {
      forms: {
        update_password: this.$helperFunction.formInstance({
          data: {
            value: '',
          },
        }),
        update_extension: this.$helperFunction.formInstance({
          data: {
            value: '',
          },
        }),
        update_displayname: this.$helperFunction.formInstance({
          data: {
            value: '',
          },
        }),
        update_username: this.$helperFunction.formInstance({
          data: {
            value: '',
          },
        }),
        update_email: this.$helperFunction.formInstance({
          data: {
            value: '',
          },
        }),
      },
      api: {
        user_detail: this.$helperFunction.apiInstance({  }),
        subscription: this.$helperFunction.apiInstance({  }),
        remove: this.$helperFunction.apiInstance({  }),
        update_role: this.$helperFunction.apiInstance({  }),
        update_password: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true, status: true, }),
        update_extension: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true, status: true, }),
        update_displayname: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true, status: true, }),
        update_username: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true, status: true, }),
        update_email: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true, status: true, }),
        request_reset: this.$helperFunction.apiInstance({ validation_errors: true, error_message: true, status: true, }),
      },
      response: {
        user_detail: {},
        subscription: {},
      },
      data: {
        accountcode: '',
      },
      conditions: {
        screen: screens.main,
        password_show: false,
      },
    };
  },
  computed:{
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsersAlises',
      'getVoipUsersAlisesImages',
      'getVoipUsersAlisesDisplayName',
    ]),
    screens(){ return screens },
    accountcode(){ return this.data.accountcode },
    user(){ return this.getVoipUsersAlises[this.accountcode] },
    isAdmin(){ return ["1", "3", 1].includes(this.user?.user?.userRole) },
    isMainUser(){ return this.user?.user?.user_type=='main' },
    isInvitedUser(){ return this.user?.user?.user_type=='invite' },
    isSubUser(){ return this.user?.user?.user_type=='user' },
    isCurrentUser(){ return this.accountcode==this.getCurrentUser.account },
    dids(){ return this.user?.userDid ?? [] },
    teams(){ return Object.values(this.response.user_detail?.account_teams ?? {}) },
    groups(){ return this.user?.userGroups ?? [] },
    userImage(){ return this.getVoipUsersAlisesImages[this.accountcode] },
    userDisplayName(){ return this.getVoipUsersAlisesDisplayName[this.accountcode] },
    username(){ return this.user?.user?.ac_user ?? '' },
    subscriptionPlan(){ return !!this.response.subscription?.userCurrentSubscription },
  },
  validations: {
    forms: {
      update_password: {
        value: {
          required: required,
          minLength: minLength(8),
        },
      },
      update_email: {
        value: {
          email: email,
        },
      },
      update_username: {
        value: {
          required: required,
          invalid: function(value){
            return this.isMainUser ? /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(value) : !/[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>?~]/.test(value)
          },
          maxLength: maxLength(40),
        }
      },
      update_displayname: {
        value: {
          required: required,
        }
      },
      update_extension: {
        value: {
          required: required,
          numeric: numeric,
          valid: function (value){
            return value.length==4
          },
        }
      },
    },
  },
  filters: {
    prefixLabel(user){ return user?.route ? `${user?.route?.label ?? ''} (${user?.route?.prefix ?? ''})` : '' },
  },
  methods: {
    onBeforeOpen(event){
      this.data.accountcode=event?.params?.account ?? ''
      this.fetchUserDetail()
      this.fecthSubscription()
    },
    onBeforeClose(){
      this.data.accountcode=''
      this.response.user_detail={}
      this.response.subscription={}
      this.conditions.screen=screens.main
      this.api.user_detail.reset()
      this.api.subscription.reset()
    },
    fetchUserDetail(set_value=true){
      let vm = this
      vm.api.user_detail.send=true
      VOIP_API.endpoints.users.detail(vm.accountcode)
      .then(({ data: detail })=>{
        vm.response.user_detail = detail;
        if(set_value){
          vm.forms.update_displayname.value = detail?.display_name ?? '';
          vm.forms.update_username.value = detail?.userLabel ?? '';
          vm.forms.update_email.value = detail?.userLabel ?? '';
          vm.forms.update_extension.value = detail.extn ?? ''
          vm.forms.update_password.value = ''
        }
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
        
      })
      .finally(()=>{
        vm.api.user_detail.send=false
      })
    },
    fecthSubscription(){
      let vm = this
      if(vm.api.subscription.send) return;
      vm.api.subscription.send = true;
      VOIP_API.endpoints.billing.getsubscriptiondetail({ 
        uid: vm.getCurrentUser.uid,
      })
      .then(({ data }) => {
        vm.response.subscription = data;
      })
      .finally(() => {
        vm.api.subscription.send = false;
      });
    },

    updateExtension() {
      let vm = this;
      vm.forms.update_extension.submitted=true
      vm.$v.forms.update_extension.$touch()
      if(vm.api.update_extension.send || vm.$v.forms.update_extension.$invalid) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to change the user info ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if(confirm){
            vm.api.update_extension.send = true;
            vm.api.update_extension.error_message=''
            vm.api.update_extension.validation_errors={}
            vm.api.update_extension.status=0
            VOIP_API.endpoints.user.updateExtension({
              extn: vm.forms.update_extension.value,
              extension: vm.forms.update_extension.value,
              account: vm.accountcode,
            })
            .then(() => {
              vm.$emit('update-list');
              vm.api.update_extension.status=1
              vm.appNotify({
                message: 'Extension updated successfully',
                type: 'success',
              })
              if(vm.isCurrentUser){
                vm.$store.state.common.user.extn = vm.forms.update_extension.value;
              }
            })
            .catch((ex) => {
              vm.api.update_extension.status=2
              vm.api.update_extension.error_message=ex.own_message
              vm.api.update_extension.validation_errors=ex.own_errors
            })
            .finally(() => {
              vm.api.update_extension.send = false;
            });
          }
        },
      });
    },
    updateDisplayName() {
      let vm = this;
      vm.forms.update_displayname.submitted=true
      vm.$v.forms.update_displayname.$touch()
      if(vm.api.update_displayname.send || vm.$v.forms.update_displayname.$invalid) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to change the user info ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if(confirm){
            vm.api.update_displayname.send = true;
            vm.api.update_displayname.error_message=''
            vm.api.update_displayname.validation_errors={}
            vm.api.update_displayname.status=0
            VOIP_API.endpoints.user.account(vm.accountcode, {
              uid: vm.getCurrentUser?.uid,
              action: "user_displayname",
              displayname: vm.forms.update_displayname.value,
            })
            .then(() => {
              vm.$emit('update-list');
              vm.api.update_displayname.status=1
              vm.appNotify({
                message: 'Display Name updated successfully',
                type: 'success',
              })
              if(vm.isCurrentUser){
                vm.$store.state.common.user.display_name = vm.forms.update_displayname.value;
              }
            })
            .catch((ex) => {
              vm.api.update_displayname.status=2
              vm.api.update_displayname.error_message=ex.own_message
              vm.api.update_displayname.validation_errors=ex.own_errors
            })
            .finally(() => {
              vm.api.update_displayname.send = false;
            });
          }
        },
      });
    },
    updateUsername() {
      let vm = this;
      vm.forms.update_username.submitted=true
      vm.$v.forms.update_username.$touch()
      if(vm.api.update_username.send || vm.$v.forms.update_username.$invalid) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to change the user info ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if(confirm){
            vm.api.update_username.send = true;
            vm.api.update_username.error_message=''
            vm.api.update_username.validation_errors={}
            vm.api.update_username.status=0
            VOIP_API.endpoints.user.account(vm.accountcode, {
              uid: vm.getCurrentUser?.uid,
              action: "user_username",
              username: vm.forms.update_username.name,
            })
            .then(() => {
              vm.$emit('update-list');
              vm.api.update_username.status=1
              vm.appNotify({
                message: `${vm.isMainUser ? 'Email' : 'Username'} updated successfully`,
                type: 'success',
              })
              if(vm.isCurrentUser){
                vm.$store.state.common.user.email = vm.forms.update_username.value;
              }
            })
            .catch((ex) => {
              vm.api.update_username.status=2
              vm.api.update_username.error_message=ex.own_message
              vm.api.update_username.validation_errors=ex.own_errors
            })
            .finally(() => {
              vm.api.update_username.send = false;
            });
          }
        },
      });
    },
    updateEmail() {
      let vm = this;
      vm.forms.update_email.submitted=true
      vm.$v.forms.update_email.$touch()
      if(vm.api.update_email.send || vm.$v.forms.update_email.$invalid) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to change the user info ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if(confirm){
            vm.api.update_email.send = true;
            vm.api.update_email.error_message=''
            vm.api.update_email.validation_errors={}
            vm.api.update_email.status=0
            VOIP_API.endpoints.user.account(vm.accountcode, {
              uid: vm.getCurrentUser?.uid,
              action: "user_username",
              username: vm.forms.update_email.value,
            })
            .then(() => {
              vm.$emit('update-list');
              vm.api.update_email.status=1
              vm.appNotify({
                message: 'Email updated successfully',
                type: 'success',
              })
              if(vm.isCurrentUser){
                vm.$store.state.common.user.email = vm.forms.update_email.value;
              }
            })
            .catch((ex) => {
              vm.api.update_email.status=2
              vm.api.update_email.error_message=ex.own_message
              vm.api.update_email.validation_errors=ex.own_errors
            })
            .finally(() => {
              vm.api.update_email.send = false;
            });
          }
        },
      });
    },
    updatePassword() {
      let vm = this;
      vm.forms.update_password.submitted=true
      vm.$v.forms.update_password.$touch()
      if(vm.api.update_password.send || vm.$v.forms.update_password.$invalid) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to change the user info ?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if(confirm){
            vm.api.update_password.send = true;
            vm.api.update_password.error_message=''
            vm.api.update_password.validation_errors={}
            vm.api.update_password.status=0
            VOIP_API.endpoints.users.updatepassword({
              uid: vm.getCurrentUser?.uid,
              action: "user_password",
              password: vm.forms.update_password.value,
              id: vm.accountcode,
              type: 'relay'
            })
            .then(() => {
              vm.$emit('update-list');
              vm.api.update_password.status=1
              vm.appNotify({
                message: 'Password updated successfully',
                type: 'success',
              })
            })
            .catch((ex) => {
              vm.api.update_password.status=2
              vm.api.update_password.error_message=ex.own_message
              vm.api.update_password.validation_errors=ex.own_errors
            })
            .finally(() => {
              vm.api.update_password.send = false;
            });
          }
        },
      });
    },
    requestForResetPassword(){
      let vm = this
      if(vm.api.request_reset.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to request for reset password?`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if(confirm){
            vm.api.request_reset.send = true;
            vm.api.request_reset.status=0
            VOIP_API.endpoints.user.account(vm.accountcode, {
              uid: vm.getCurrentUser?.uid,
              action: "user_password",
            })
            .then(() => {
              vm.api.request_reset.status=1
              vm.appNotify({
                message: 'Successfully Send the request',
                type: 'success',
              })
            })
            .catch((ex) => {
              vm.api.request_reset.status=2
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(() => {
              vm.api.request_reset.send = false;
            });
          }
        },
      });
    },

    remove() {
      let vm = this;
      if(vm.api.remove.send) return;
      vm.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to delete "${vm.userDisplayName}" user.`,
        variant: 'danger',
        button: {
          no: "No",
          yes: "Yes",
        },
        callback({confirm}) {
          if (confirm) {
            vm.api.remove.send=true
            VOIP_API.endpoints.users.delete({
              id: vm.accountcode,
              uid: vm.getCurrentUser?.uid,
              accountcode: vm.getCurrentUser?.account,
            })
            .then(()=>{
              vm.$emit('update-list');
              vm.$modal.hide(vm.modalName)
              vm.appNotify({
                message: 'Successfully Deleted',
                type: 'success',
              })
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.remove.send=false
            })
          }
        },
      });
    },
    updateRole(value){
      let vm = this
      if(vm.api.update_role.send) return;
      vm.api.update_role.send=true
      VOIP_API.endpoints.users.updateuserrole(vm.accountcode)
      .then(()=>{
        vm.$emit('update-list');
        vm.$refs.role.localChecked=value
      })
      .catch((ex)=>{
        vm.$refs.role.localChecked=!value
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      }).finally(()=>{
        vm.api.update_role.send=false
      })
    },
  },
};
</script>

<style lang="scss" scoped>
.profile-image-section {
  position: relative;
  width: 180px;
  height: 180px;
  margin: 0 auto;
  overflow: hidden;
  @include border-radius(50%);
  margin-bottom: 3 * $dialer-default-padding;
  .loader-icon {
    position: absolute;
    top: 0px;
    left: 0px;
    z-index: 1;
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: rgba(0, 0, 0, 0.1);
  }
  .profile-img-upload-btn {
    position: absolute;
    top: 0px;
    left: 0px;
    height: 100%;
    width: 100%;
    align-items: center;
    display: none;
    justify-content: center;
    .upload-icon {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 1;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
      background-color: rgba(0, 0, 0, 0.6);
    }
    input {
      position: absolute;
      top: 0px;
      left: 0px;
      z-index: 2;
      opacity: 0;
      height: 100%;
      width: 100%;
      display: flex;
      align-items: center;
      justify-content: center;
    }
  }
  .profile-img {
    width: 100%;
    height: 100%;
    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
      @include border-radius(50%);
    }
  }
  &:hover {
    .profile-img-upload-btn {
      display: block;
      cursor: pointer;
    }
  }
}
</style>