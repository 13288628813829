<template>
  <div>
    <div class="mainHeading withButton">
      Reminders
    </div>
    <div class="mainDescription mb-4">
      Set up custom email notifications to employees who have time off requests pending on them
    </div>
    <b-form-checkbox class="HrmCheckBox-container mt-32px">
      Send a reminder email o approvers who have unapproved time off requests
    </b-form-checkbox>
    <div class="pl-32px mt-16px">
      <b-form-checkbox class="HrmCheckBox-container">
        Monday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Tuesday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Wedensday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Thursday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Friday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Saturday
      </b-form-checkbox>
      <b-form-checkbox class="HrmCheckBox-container">
        Sunday
      </b-form-checkbox>
      <div class="d-flex align-items-center pl-2">
        <div class="mr-16px">at</div>
        <div class="innerShadowInput-container wd-200px mt-8px mb-0">
          <!-- <label>Category</label> -->
          <b-dropdown class="hrmInnerShadow-dd" no-caret>
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">08:00</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              08:00
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div class="innerShadowInput-container mt-24px">
        <label>Don’t send reminders while the approver is away on following policies</label>
        <b-dropdown class="hrmInnerShadow-dd" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText"></div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            08:00
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <button class="dialer-button dialer-button-primary mr-0"> Save </button>
    </div>
  </div>
</template>

<script>
export default {
  name: 'TimeOffReminders',
}
</script>

<style>

</style>