var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500",
    attrs: {
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('form', {
    staticClass: "w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.changeExtension();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-start justify-content-between w-100"
  }, [_c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Change extension")]), _c('p', [_vm._v("Enter a strong and memorable extension")])]), _c('button', {
    staticClass: "newCloseButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px w-100"
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.change_extn.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.change_extn.error_message))]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Set extension")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.change_extn.extn,
      expression: "forms.change_extn.extn"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "onkeypress": "return /[0-9]/i.test(event.key)",
      "maxlength": "4",
      "disabled": _vm.api.change_extn.send
    },
    domProps: {
      "value": _vm.forms.change_extn.extn
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.change_extn, "extn", $event.target.value);
      }
    }
  }), _vm.forms.change_extn.submitted && _vm.$v.forms.change_extn.extn.$invalid || _vm.api.change_extn.validation_errors.extension ? _c('p', {
    staticClass: "text text-danger animated bounceIntop mb-0"
  }, [_c('span', [_vm._v("* ")]), !_vm.$v.forms.change_extn.extn.required ? _c('span', [_vm._v("Extension is required")]) : !_vm.$v.forms.change_extn.extn.number ? _c('span', [_vm._v("Extension should be only number")]) : !_vm.$v.forms.change_extn.extn.valid ? _c('span', [_vm._v("Extension should be only 4 digits")]) : _vm._e(), _vm._l(_vm.api.change_extn.validation_errors.extension, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()])], 1), _c('div', {
    staticClass: "w-100 mt-20px"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton",
    attrs: {
      "disabled": _vm.api.change_extn.send,
      "type": "submit"
    }
  }, [_vm._v(" Update ")])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }