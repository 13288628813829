var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.tableDesign == 0 ? _c('div', {
    class: _vm.classes.tableClasses
  }, [_vm.noRecordWholePage && _vm.condition.is_no_record.show && _vm.isListEmpty && !_vm.loading ? [_vm._t("no-record", function () {
    return [_c('vb-no-record', {
      attrs: {
        "text": _vm.loading ? '' : _vm.textNoRecord,
        "design": _vm.designNoRecord
      }
    })];
  })] : [_vm.horizontalScrollVersion ? [_c('div', {
    staticClass: "horizontalScrollVersion-container"
  }, [_c('table', {
    staticClass: "dialer-table",
    attrs: {
      "id": _vm.id
    }
  }, [_c('thead', [_vm._t("header")], 2), _c('tbody', [_vm._t("prepend-row"), _vm._t("body", null, {
    "start": _vm.pagination.start,
    "end": _vm.pagination.end
  }), _vm.condition.loading.show ? [_vm.isListEmpty && _vm.loading ? [_vm._t("loading", function () {
    return [_c('vb-loading', {
      staticClass: "my-2",
      attrs: {
        "type": 'circular'
      }
    })];
  })] : _vm._e()] : _vm._e()], 2)]), _vm.condition.is_no_record.show && _vm.isListEmpty && !_vm.loading ? [_vm._t("no-record", function () {
    return [_c('vb-no-record', {
      attrs: {
        "text": _vm.loading ? '' : _vm.textNoRecord,
        "design": _vm.designNoRecord
      }
    })];
  })] : _vm._e()], 2), _c('vb-pagination', {
    attrs: {
      "listLength": _vm.listLength,
      "page": _vm.page,
      "conditions": _vm.conditions,
      "perPage": _vm.perPage,
      "isListEmpty": _vm.isListEmpty
    },
    on: {
      "per-page-changed": function ($event) {
        return _vm.$emit('per-page-changed', $event);
      },
      "page-changed": function ($event) {
        return _vm.$emit('page-changed', $event);
      },
      "latest-pagination-state": function ($event) {
        _vm.pagination = $event;
      }
    }
  })] : [_c('table', {
    staticClass: "dialer-table",
    attrs: {
      "id": _vm.id
    }
  }, [_c('thead', [_vm._t("header")], 2), _c('tbody', [_vm._t("prepend-row"), _vm._t("body", null, {
    "start": _vm.pagination.start,
    "end": _vm.pagination.end
  }), _vm.condition.loading.show ? [_vm.isListEmpty && _vm.loading ? [_vm._t("loading", function () {
    return [_c('vb-loading', {
      staticClass: "my-2",
      attrs: {
        "type": 'circular'
      }
    })];
  })] : _vm._e()] : _vm._e()], 2)]), _vm.condition.is_no_record.show && _vm.isListEmpty && !_vm.loading ? [_vm._t("no-record", function () {
    return [_c('vb-no-record', {
      attrs: {
        "text": _vm.loading ? '' : _vm.textNoRecord,
        "design": _vm.designNoRecord
      }
    })];
  })] : _vm._e(), _c('vb-pagination', {
    attrs: {
      "listLength": _vm.listLength,
      "page": _vm.page,
      "conditions": _vm.conditions,
      "perPage": _vm.perPage,
      "isListEmpty": _vm.isListEmpty
    },
    on: {
      "per-page-changed": function ($event) {
        return _vm.$emit('per-page-changed', $event);
      },
      "page-changed": function ($event) {
        return _vm.$emit('page-changed', $event);
      },
      "latest-pagination-state": function ($event) {
        _vm.pagination = $event;
      }
    }
  })]]], 2) : _c('div', {
    staticClass: "mt-5 mb-4 table-hrm-main"
  }, [_c('div', {
    staticClass: "table-overall-header"
  }, [_vm._t("header")], 2), _c('div', {
    staticClass: "tab-doc-bg-border"
  }, [_c('table', {}, [_c('thead'), _c('tbody', [_vm._t("body", null, {
    "start": _vm.pagination.start,
    "end": _vm.pagination.end
  })], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }