<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox increasingModalIndexForIOS IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"   
      :name="modalName" 
      @before-close="onBeforeClose()" 
      @before-open="onBeforeOpen($event)"
    >
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="api.update.send?'':$modal.hide(modalName)">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20"  />
        </div>
        <!-- <div class="dialer-settings-title topMainHeading">Incoming Call Display Setting</div> -->
        <div class="dialer-settings-title topMainHeading">Incoming Call Presentation Setting</div>
        <div class="plus-IOSIcon-container"></div>
      </div>
      <div class="innerModalIos">
        <div class="lg-mar-bottom">
          <div class="basicWhiteIOScard mt-32px mb-16px">
            <div 
              v-for="cldOption in cldOptions" 
              :key="cldOption.value" 
              class="basicWhiteIOScard-item" 
              @click="api.update.send?'':selected.clid_display=cldOption.value;updateSetting()"
            >
              <div class="headingInsideTable">{{ cldOption.text }}</div>
              <div class="d-flex align-items-center">
                <div class="d-flex align-items-center forRadio-container">
                  
                  <vb-icon v-if="selected.clid_display==cldOption.value" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />  
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils';

export default {
  name: "IncommingcallDisplaySettingModal",
  props: {
    modalName: {
      type: String,
      default: "IncommingcallDisplaySetting",
    },
  },
  data() {
    return {
      selected: {
        clid_display: '',
      },
      api: {
        update: this.$helperFunction.apiInstance(),
      },
      data: {
        account: '',
      },
    };
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters({
      getUserPermissions: 'getUserPermissions',
      getCurrentUser: 'getCurrentUser',
    }),
    cldOptions(){
      return [
        { text: "Display Number only", value: "0" },
        { text: "Display DID name", value: "1" },
        { text: "Display DID number", value: "2" },
        // { text: "Display Account number", value: "3" },
        // { text: "Display Account name", value: "4" },
        { text: "Display Speed dial reference name", value: "5" },
      ]
    },
  },
  methods: {
    onBeforeOpen(event){
      this.data.account = event?.params?.account ?? ''
      this.fetchUserDetail()
    },
    onBeforeClose(){
      this.selected.clid_display=''
    },
    fetchUserDetail() {
      let vm = this
      VOIP_API.endpoints.users.detail(this.data.account)
      .then(({ data: detail }) => {
        vm.selected.clid_display = detail?.select_clid_display ?? '';
      })
    },
    updateSetting() {
      let vm = this;
      if(vm.api.update.send) return;
      vm.api.update.send=true
      VOIP_API.endpoints.users.updateuserdetail({
        field: "select_clid_display",
        accountcode: vm.data.account,
        uid: vm.getCurrentUser.uid,
        value: vm.selected.clid_display,
      })
      .then(() => {
        vm.$emit('update')
        vm.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
      })
      .catch((ex) => {
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.update.send=false
      });
    },
  },
};
</script>

