<template>
  <div class="whiteBoxWithBorderInsideGreyBox-row flex-column justify-content-center align-items-start"> 
    <div class="w-100 d-flex justify-content-between align-items-center">
      <div>
        <div class="latestGreyBox-heading-main">Missed calls</div>
        <div class="latestGreyBox-descriptionText">You will receive a notification for every missed call.</div>
      </div>
      <div class="w-fit-content">
        <div v-if="api.fetch_notification_settings.send" class="latestShimmerDesign" style="height:34px;width:60px;border-radius: 25px"></div>
        <b-form-checkbox
          v-else
          class="newerSwitch"
          ref="misscall_notification"
          v-b-tooltip :title="getUserPermissions.missed_call_notification?'':'Your package does not support this feature'"
          :disabled="!getUserPermissions.missed_call_notification || api.change_misscall_notification.send || api.fetch_notification_settings.send"
          :checked="data.misscall_notification"
          @change="changeMissedCallNotification($event)"
          name="check-button"
          switch
        />
      </div>
    </div>
    <div v-if="data.misscall_notification" class="latestGreyBox-9-9-2023 mt-20px w-100">
      <div class="whiteBoxWithBorderInsideGreyBox">
        <!-- Missed Call Email Notification -->
        <div v-if="!isTeam" class="whiteBoxWithBorderInsideGreyBox-row">
          <div>
            <div class="latestGreyBox-heading-main">Missed calls notifications via email</div>
            <div class="latestGreyBox-descriptionText">Receive an email alert notifying you of missed calls.</div>
          </div>
          <b-form-checkbox
            class="newerSwitch"
            ref="missed_call_notification"
            v-b-tooltip :title="getUserPermissions.missed_call_notification?'':'Your package does not support this feature'"
            :disabled="!getUserPermissions.missed_call_notification || api.change_missed_call_notification.send || api.fetch_notification_settings.send"
            :checked="data.missed_call_notification"
            @change="changeMissedCallEmail($event)"
            name="check-button"
            switch
          />
        </div>
        <!-- Missed Call Task Notification -->
        <div class="whiteBoxWithBorderInsideGreyBox-row  flex-column justify-content-center align-items-start">
          <div class="w-100 d-flex justify-content-between align-items-center">
            <div>
              <div class="latestGreyBox-heading-main">Add miss call as a task</div>
              <div class="latestGreyBox-descriptionText">Turn missed calls into tasks to remind you of calls requiring attention later.</div>
            </div>
            <b-form-checkbox
              class="newerSwitch"
              ref="misscall_task"
              v-b-tooltip :title="getUserPermissions.missed_call_notification?'':'Your package does not support this feature'"
              :disabled="!getUserPermissions.missed_call_notification || api.change_misscall_task.send || api.fetch_notification_settings.send"
              :checked="data.misscall_task!=0"
              @change="changeMissedCallTask($event?1:0)"
              name="check-button"
              switch
            />
          </div>
          <template v-if="!isTeam && data.misscall_task!=0">
            <div v-if="data.misscall_task!=0" class="d-flex flex-column align-items-start w-100 mt-20px">
              <div class="d-flex justify-content-between align-items-center w-100">
                <div class="latestGreyBox-descriptionText mt-0">Missed call as a task will be added to the <span class="color-3699FF" style="color: #3699FF">{{data.misscall_task==2 ? 'my space' : 'global space'}}</span>.</div>
                <button 
                  class="newButton" 
                  :disabled="!getUserPermissions.missed_call_notification || api.change_misscall_task.send || api.fetch_notification_settings.send"
                  @click="$modal.show('taskSpaceSelectorModal')"
                >
                  <vb-icon icon="squared-change-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Change space</span>
                </button>
              </div>
            </div>
          </template>
        </div>
      </div>
    </div>
    <modal name="taskSpaceSelectorModal" class="EditSpaceModal min-height-400px">
      <div class="dialer-edit-header w-100 d-flex position-relative py-0 justify-content-between align-items-center">
        <h2 class="dialer-edit-title newer my-0">Select space</h2>
        <div class="dialer-edit-actions">
          <button class="newCloseButton" @click="$modal.hide('taskSpaceSelectorModal')" type="button" >
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
      </div>
      <div class="mt-24px">
        <div class="latestGreyBox-9-9-2023 hasScrollBarInsideIt mt-20px" style="--total: 90vh; --minusValue: 75px;">
          <div class="whiteBoxWithBorderInsideGreyBox">
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
              <div :class="`actLikeSquareRadio mr-20px ${forTempEvent == 1 ? 'checked' : '' }`" @click="forTempEvent = 1;changeMissedCallTask(forTempEvent)"></div>
              <div class="latestGreyBox-heading-main">Global space</div>
            </div>
            <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none">
              <div :class="`actLikeSquareRadio mr-20px ${forTempEvent == 2 ? 'checked' : '' }`" @click="forTempEvent = 2;changeMissedCallTask(forTempEvent)"></div>
              <div class="latestGreyBox-heading-main">My space</div>
            </div>
          </div>
        </div>
      </div>
    </modal>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
export default {
name: 'MissedCallNotificationWidget',
  props: {
    accountcode: {
      type: [String,Number],
      default: ''
    },
  },
  data() {
    return {
      api: {
        fetch_notification_settings: this.$helperFunction.apiInstance(),
        change_misscall_notification: this.$helperFunction.apiInstance(),
        change_missed_call_notification: this.$helperFunction.apiInstance(),
        change_misscall_task: this.$helperFunction.apiInstance(),
      },
      data: {
        misscall_notification: false,
        misscall_task: false,
        missed_call_notification: false,
      },
      forTempEvent: 1
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getUserPermissions',
      'getVoipTeamsAlises',
    ]),
    voipaccountcode(){ return this.accountcode },
    isCurrentUser(){ return this.voipaccountcode == this.getCurrentUser.account },
    isTeam(){ return !!this.getVoipTeamsAlises[this.accountcode] },
  },
  methods: {
    async fetchNotificationSettings() {
      if(this.api.fetch_notification_settings.send) return;
      try {
        this.api.fetch_notification_settings.send=true
        const { data } = await VOIP_API.endpoints.call.getNotificationSettings(this.voipaccountcode,{
          type: this.isTeam ? 'team' : 'user'
        })
        if(this.isTeam) {
          this.data.misscall_notification=data.missed_call_notification=='1'
          this.data.misscall_task=data.team_misscall_task
          this.data.missed_call_notification=data.missed_call_notification=='1'
        } else {
          this.data.misscall_notification=data.misscall_notification==1
          this.data.misscall_task=data.misscall_task
          this.data.missed_call_notification=data.missed_call_notification=='1'
          this.forTempEvent = this.data.misscall_task
        }
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        this.api.fetch_notification_settings.send=false
      }
    },
    async changeMissedCallNotification(value) {
      if(this.api.change_misscall_notification.send) return;
      try {
        this.api.change_misscall_notification.send=true
        await VOIP_API.endpoints.call.setNotificationSettings({
          field: this.isTeam ? 'missed_call_notification' : 'misscall_notification',
          value: value ? 1 : 0,
          accountcode: this.voipaccountcode,
          team_misscall_task: this.isTeam ? 1 : 0, 
        })
        this.data.misscall_notification=value
        this.appNotify({
          message: 'Successfully updated',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['misscall_notification']) this.$refs['misscall_notification'].localChecked = this.data.misscall_notification;
        this.api.change_misscall_notification.send=false
      }
    },
    async changeMissedCallTask(value) {
      if(this.api.change_misscall_task.send) return;
      try {
        this.api.change_misscall_task.send=true
        await VOIP_API.endpoints.call.setNotificationSettings({
          field: this.isTeam ? 'team_misscall_task' : 'misscall_task',
          value: value,
          accountcode: this.voipaccountcode,
          team_misscall_task: this.isTeam ? 1 : 0,
        })
        this.data.misscall_task=value
        this.appNotify({
          message: 'Successfully updated',
          type: 'success'
        })
        this.$modal.hide('taskSpaceSelectorModal')
        this.fetchNotificationSettings()
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['misscall_task']) this.$refs['misscall_task'].localChecked = this.data.misscall_task!=0;
        if(this.$refs['misscall_task_space']) this.$refs['misscall_task_space'].localChecked = this.data.misscall_task==2;
        this.api.change_misscall_task.send=false
      }
    },
    async changeMissedCallEmail(value) {
      if(this.api.change_missed_call_notification.send) return;
      try {
        this.api.change_missed_call_notification.send=true
        await VOIP_API.endpoints.call.setNotificationSettings({
          field: 'missed_call_notification',
          value: value ? '1' : '0',
          accountcode: this.voipaccountcode,
          team_misscall_task: this.isTeam ? 1 : 0,
        })
        this.data.missed_call_notification=value
        this.appNotify({
          message: 'Successfully updated',
          type: 'success'
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      } finally {
        if(this.$refs['missed_call_notification']) this.$refs['missed_call_notification'].localChecked = this.data.missed_call_notification;
        this.api.change_missed_call_notification.send=false
      }
    },
  },
  mounted(){
    this.fetchNotificationSettings()
  },
  activated(){
    this.fetchNotificationSettings()
  },
}
</script>

<style>

</style>