<template>

<div class="right-calender">
	<div class="top-main-calender scrollow1">
		<section class="unsec-common">
				<div class="timeoff-top-btn mar-bottom-hrm60">
          <div>
            <h5 class="emp-profile-heading">Time off summary</h5>
          </div>
          <div class="d-flex justify-content-end">
            <button class="btn btn-primarymike space4btn-when-scroll-activate mr-3" @click="$modal.show('HRMAddNewTaskModal')">
              <svg xmlns="http://www.w3.org/2000/svg" width="16.551" height="16.551" viewBox="0 0 16.551 16.551" style="margin-right:10px;">
                <path id="Icon_ionic-ios-add" data-name="Icon ionic-ios-add" d="M23.547,15.772H17.708V9.933a.968.968,0,0,0-1.936,0v5.839H9.933a.968.968,0,0,0,0,1.936h5.839v5.839a.968.968,0,1,0,1.936,0V17.708h5.839a.968.968,0,1,0,0-1.936Z" transform="translate(-8.465 -8.465)" fill="#fff" stroke="#fff" stroke-width="1"/>
              </svg>
              Request time off
            </button>
            <button class="btn btn-activity space4btn-when-scroll-activate ">
              Set long-term leave
            </button>
          </div>
				</div>
				<div class="timeoff-cardmain mar-top-hrm50">
					<div class="time-off-cardinner">
							<div class="clr-sick">Sickday</div>
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="num-sick mb-imp">3</h5>
								<div class="sickday-prog">
									<div class="hrm-circularProgressBar">
										<div class="circular-ProgBar">
											<div class="circular-ProgBar-fillhrm" style="transform: rotate(180deg);"></div>
										</div>
										<div class="circular-ProgBar-text">
											<span>65%</span>
										</div>
									</div>
								</div>
							</div>
					</div>
					<div class="time-off-cardinner">
							<div class="clr-holiday1">Holiday - UK</div>
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="num-holiday1 mb-imp ">3</h5>
								<div class="holidayuk-prog">
									<div class="hrm-circularProgressBar">
										<div class="circular-ProgBar">
											<div class="circular-ProgBar-fillhrm" style="transform: rotate(180deg);"></div>
										</div>
										<div class="circular-ProgBar-text">
											<span>65%</span>
										</div>
									</div>
								</div>
							</div>
					</div>
					<div class="time-off-cardinner">
            <div class="clr-holiday2">Holiday - NL</div>
            <div class="d-flex justify-content-between align-items-center">
              <h5 class="num-holiday2 mb-imp">3</h5>
              <div class="holidaynl-prog">
                <div class="hrm-circularProgressBar">
                  <div class="circular-ProgBar">
                    <div class="circular-ProgBar-fillhrm" style="transform: rotate(180deg);"></div>
                  </div>
                  <div class="circular-ProgBar-text">
                    <span>65%</span>
                  </div>
                </div>
              </div>
            </div>
					</div>
					<div class="time-off-cardinner">
							<div class="clr-btrip">Business trip</div>
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="num-btrip mb-imp">3</h5>
								<div class="btrip-prog">
									<div class="hrm-circularProgressBar">
										<div class="circular-ProgBar">
											<div class="circular-ProgBar-fillhrm" style="transform: rotate(180deg);"></div>
										</div>
										<div class="circular-ProgBar-text">
											<span>65%</span>
										</div>
									</div>
								</div>
							</div>
					</div>
					<div class="time-off-cardinner">
							<div class="clr-sick">Sickday</div>
							<div class="d-flex justify-content-between align-items-center">
								<h5 class="num-sick mb-imp">3</h5>
								<div class="sickday-prog">
									<div class="hrm-circularProgressBar">
										<div class="circular-ProgBar">
											<div class="circular-ProgBar-fillhrm" style="transform: rotate(180deg);"></div>
										</div>
										<div class="circular-ProgBar-text">
											<span>65%</span>
										</div>
									</div>
								</div>
							</div>
					</div>
				</div>
				<div class="timeoff-top-btn mar-top-hrm50">
					<div>
						<h5 class="emp-profile-heading">Time off details </h5>
					</div>
					<div class="d-flex justify-content-end">
						<button class="btn btn-activity  mr-3 ">
								Change balance
						</button>
						<button class="btn btn-activity ">
								For specfic data
						</button>
					</div>
				</div>
				<vb-table :tableDesign="1" class="timeoff-table-width">
					<template slot="header">
						<h5 class="commonth todone-firsttd col-first-entryleft ">policy</h5>
						<h5 class="commonth todone-secondtd ">Allowance period</h5>
						<h5 class="commonth todone-thirdtd">balance</h5>
						<h5 class="commonth todone-fourtd">accrual</h5>
						<h5 class="commonth todone-fivetd">carried</h5>
						<h5 class="commonth todone-six col-right">Action</h5>
					</template>
					<template slot="body">
						<tr>
							<td class="todone-firsttd ">
								<div class="timeoff-color-table-icons"> 
									<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
										<circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#D5761B"/>
									</svg>
									<span class="clr-sick">Sickday</span>
								</div>
							</td>	
							<td class="todone-secondtd">
								01/01/2022 - 31/12/2022
							</td>
							<td class="todone-third">
								<span class="timeoff-tbl-numeric-common-bold"> 0</span> days used
							</td>
							<td class="todone-fourtd">
							<span class="timeoff-tbl-numeric-common-bold"> 15</span> days
							</td>
							<td class="todone-fivetd">
							<span class="timeoff-tbl-numeric-common-bold"> 15</span> days
							</td>
							<td class="todone-sixtd">
								<div class="d-flex justify-content-end commonTableMain">
									<b-dropdown no-caret class="common-table-dd">
										<template #button-content>
											<b-icon icon="three-dots-vertical"></b-icon>
										</template>
										<b-dropdown-item href="#">Action</b-dropdown-item>
									</b-dropdown>
								</div>
							</td>
						</tr>
						<tr>
							<td class="todone-firsttd ">
								<div class="timeoff-color-table-icons"> 
									<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
										<circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#5576D1"/>
									</svg>
									<span class="clr-holiday1">Holiday - UK</span>
								</div>
							</td>	
							<td class="todone-secondtd">
								01/01/2022 - 31/12/2022
							</td>
							<td class="todone-third">
								<span class="timeoff-tbl-numeric-common-bold"> 0</span> days used
							</td>
							<td class="todone-fourtd">
								<span class="timeoff-tbl-numeric-common-bold"> 15</span> days
							</td>
							<td class="todone-fivetd">
								<span class="timeoff-tbl-numeric-common-bold"> 15</span> days
							</td>
							<td class="todone-sixtd">
								<div class="d-flex justify-content-end commonTableMain">
									<b-dropdown no-caret class="common-table-dd">
										<template #button-content>
											<b-icon icon="three-dots-vertical"></b-icon>
										</template>
										<b-dropdown-item href="#">Action</b-dropdown-item>
									</b-dropdown>
								</div>
							</td>
						</tr>
						<tr>
							<td class="todone-firsttd ">
								<div class="timeoff-color-table-icons"> 
									<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
										<circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#1A9211"/>
									</svg>
									<span class="clr-holiday2">Holiday - NL</span>
								</div>
							</td>	
							<td class="todone-secondtd">
								01/01/2022 - 31/12/2022
							</td>
							<td class="todone-third">
								<span class="timeoff-tbl-numeric-common-bold"> 0</span> days used
							</td>
							<td class="todone-fourtd">
								<span class="timeoff-tbl-numeric-common-bold"> 15</span> days
							</td>
							<td class="todone-fivetd">
								<span class="timeoff-tbl-numeric-common-bold"> 15</span> days
							</td>
							<td class="todone-sixtd">
								<div class="d-flex justify-content-end commonTableMain">
									<b-dropdown no-caret class="common-table-dd">
										<template #button-content>
											<b-icon icon="three-dots-vertical"></b-icon>
										</template>
										<b-dropdown-item href="#">Action</b-dropdown-item>
									</b-dropdown>
								</div>
							</td>
						</tr>
						<tr>
							<td class="todone-firsttd ">
								<div class="timeoff-color-table-icons"> 
									<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
										<circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#D2A657"/>
									</svg>
									<span class="clr-btrip">Business trip</span>
								</div>
							</td>	
							<td class="todone-secondtd">
								01/01/2022 - 31/12/2022
							</td>
							<td class="todone-third">
								<span class="timeoff-tbl-numeric-common-bold"> 0</span> days used
							</td>
							<td class="todone-fourtd">
								<span class="timeoff-tbl-numeric-common-bold"> 15</span> days
							</td>
							<td class="todone-fivetd">
								<span class="timeoff-tbl-numeric-common-bold"> 15</span> days
							</td>
							<td class="todone-sixtd">
								<div class="d-flex justify-content-end commonTableMain">
									<b-dropdown no-caret class="common-table-dd">
										<template #button-content>
											<b-icon icon="three-dots-vertical"></b-icon>
										</template>
										<b-dropdown-item href="#">Action</b-dropdown-item>
									</b-dropdown>
								</div>
							</td>
						</tr>
						<tr>
							<td class="todone-firsttd ">
								<div class="timeoff-color-table-icons"> 
									<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg">
										<circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#D5761B"/>
									</svg>
									<span class="clr-sick">Sickday</span>
								</div>
							</td>	
							<td class="todone-secondtd">
								01/01/2022 - 31/12/2022
							</td>
							<td class="todone-third">
								<span class="timeoff-tbl-numeric-common-bold"> 0</span> days used
							</td>
							<td class="todone-fourtd">
								<span class="timeoff-tbl-numeric-common-bold"> 15</span> days
							</td>
							<td class="todone-fivetd">
								<span class="timeoff-tbl-numeric-common-bold"> 15</span> days
							</td>
							<td class="todone-sixtd">
								<div class="d-flex justify-content-end commonTableMain">
									<b-dropdown no-caret class="common-table-dd">
										<template #button-content>
											<b-icon icon="three-dots-vertical"></b-icon>
										</template>
										<b-dropdown-item href="#">Action</b-dropdown-item>
									</b-dropdown>
								</div>
							</td>
						</tr>
					</template>
				</vb-table>
				<div class="timeoff-approved-bg mar-top-hrm50">
						<div class="calender-hrm-height mb-3">
							<h5 class="timeoff-subheading">Time off approved by</h5>
							<p class="timeoff-text mb-imp">To change who approves time off <a href="#" class="timeoff-clickhere">click here</a></p>
							<div class=" d-flex justify-content-start align-items-center mt-4">
								<img src="../../../assets/images/credit-card/deftime.png" alt="logo"  class="timeoff-img-avtar"/>
								<div class="timeoff-profile">
									<h5 class="timeoff-profile-name">Jackson</h5>
									<p class="timeoff-profile-num">8001</p>
								</div>
							</div>
						</div>
				</div>
				<div class="mar-top-hrm50">
						<h5 class=" emp-profile-heading">Time off history </h5>
				</div>
				
        <div class="mar-top-hrm25 d-flex flex-wrap">
          <div class="home-dd-container mr-0">
            <b-dropdown no-caret class="home-mainBorderfordropi">
              <template #button-content>
                <div class="home-button-content-dropi">
                  <div class="home-dropi-contenti">All policies</div>
                  <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                </div>
              </template>
              <b-dropdown-item>New</b-dropdown-item>
              <b-dropdown-item>To be announced (4)</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="home-dd-container">
            <b-dropdown no-caret class="home-mainBorderfordropi">
              <template #button-content>
                <div class="home-button-content-dropi">
                  <div class="home-dropi-contenti">All time</div>
                  <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                </div>
              </template>
              <b-dropdown-item>GMT 5+</b-dropdown-item>
							<b-dropdown-item>GMT 9+</b-dropdown-item>
							<b-dropdown-item>GMT 8+</b-dropdown-item>
            </b-dropdown>
          </div>
          <div class="home-dd-container">
            <b-dropdown no-caret class="home-mainBorderfordropi">
              <template #button-content>
                <div class="home-button-content-dropi">
                  <div class="home-dropi-contenti">All statuses</div>
                  <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                </div>
              </template>
              <b-dropdown-item>Approved</b-dropdown-item>
              <b-dropdown-item>Pending</b-dropdown-item>
							<b-dropdown-item>Rejected</b-dropdown-item>
            </b-dropdown>
          </div>
        </div>
				<vb-table :tableDesign="1" class="timeofftwo-table-width">
					<template slot="header">
						<h5 class="commonth firsttd col-first-entryleft ">Dates</h5>
						<h5 class="commonth secondtd ">date requested</h5>
						<h5 class="commonth thirdtd">policy</h5>
						<h5 class="commonth fourtd">details</h5>
						<h5 class="commonth fivetd">Status</h5>
						<h5 class="commonth  sixtd col-right">Action</h5>
					</template>
					<template slot="body">
						<tr>
							<td class="firsttd">
								16/07/2022<br>
								first part of the day, 7 hours
							</td>
							<td class="secondtd">
								23/07/2022<br>
								14 days ago
							</td>
							<td class="thirdtd">
								<div class="d-flex justify-content-start align-items-start"> 
									<svg xmlns="http://www.w3.org/2000/svg" width="14" height="14" viewBox="0 0 14 14" class="timeofftwotable-svg mt-1">
										<circle id="Ellipse_5437" data-name="Ellipse 5437" cx="7" cy="7" r="7" fill="#1a9211"/>
									</svg>
									Holiday - NL
								</div>
							</td>
							<td class="fourtd">
								Qui illum iure non.
							</td>
							<td class="fivetd">
								Approved
							</td>
							<td class="sixtd">
								<div class="d-flex justify-content-end commonTableMain">
									<b-dropdown no-caret class="common-table-dd">
										<template #button-content>
											<b-icon icon="three-dots-vertical"></b-icon>
										</template>
										<b-dropdown-item href="#">Action</b-dropdown-item>
									</b-dropdown>
								</div>
							</td>
						</tr>
					</template>
				</vb-table>
		</section>
		
	</div>
	
</div>
</template>

<script>

export default {
  name: 'TimeoffHRM',
}
</script>

<style>

</style>