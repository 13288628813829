var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-mobileActivityComments-icon"
    }
  })], 1), _vm._m(0)]), _c('button', {
    staticClass: "dialer-button dialer-button-primary",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.add_note = !_vm.conditions.add_note;
      }
    }
  }, [!_vm.conditions.add_note ? [_vm._v("Add note")] : [_vm._v("Close note")]], 2)]), !!_vm.selected.update_id ? _c('b-form', {
    staticClass: "d-flex align-items-center mt-12px w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateNote();
      }
    }
  }, [_c('vb-avatar', {
    staticClass: "mr-3",
    attrs: {
      "is_blf": false,
      "id": _vm.getCurrentUser.account
    }
  }), _c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_note.note,
      expression: "forms.update_note.note"
    }],
    staticClass: "TODO-textArea",
    attrs: {
      "rows": "auto",
      "disabled": _vm.api.update_note.send || _vm.disabled,
      "placeholder": "Write a comment..."
    },
    domProps: {
      "value": _vm.forms.update_note.note
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.updateNote();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_note, "note", $event.target.value);
      }
    }
  }), _vm._v(" "), _vm.forms.update_note.submitted && _vm.$v.forms.update_note.note.$invalid || _vm.api.update_note.validation_errors.note ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_note.note.required ? _c('span', [_vm._v("Note is required")]) : _vm._e(), _vm._l(_vm.api.update_note.validation_errors.note, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e(), _c('b-button', {
    attrs: {
      "disabled": _vm.api.update_note.send || _vm.disabled,
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.selected.update_id = '';
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "variant": "danger"
    }
  })], 1)], 1) : _vm.conditions.add_note ? _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center flex-fill"
  }, [_c('div', {
    staticClass: "InfoComponentInsideAnroidIOSApp mr-16px"
  }, [_c('vb-avatar', {
    attrs: {
      "is_blf": false,
      "id": _vm.getCurrentUser.account
    }
  })], 1), _c('b-form', {
    staticClass: "d-flex align-items-center w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createNote();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex flex-fill align-items-center w-100"
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_note.note,
      expression: "forms.create_note.note"
    }],
    staticClass: "w-100 border px-2 py-1",
    attrs: {
      "rows": "auto",
      "disabled": _vm.api.create_note.send || _vm.disabled,
      "placeholder": "Write a comment..."
    },
    domProps: {
      "value": _vm.forms.create_note.note
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        return _vm.createNote();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_note, "note", $event.target.value);
      }
    }
  })])])], 1)]) : _vm._e(), _vm._l(_vm.response.notes, function (note) {
    return _c('div', {
      key: note.id,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "d-flex align-items-center flex-fill"
    }, [_c('div', {
      staticClass: "InfoComponentInsideAnroidIOSApp mr-16px"
    }, [_c('vb-avatar', {
      attrs: {
        "is_blf": false,
        "id": _vm.getCurrentUser.account
      }
    })], 1), _c('div', {
      staticClass: "d-flex flex-column"
    }, [_c('div', {
      staticClass: "TODO-comment-name"
    }, [_vm._v(" " + _vm._s(_vm.getVoipUsersAlisesDisplayName[note.created_by]) + " "), _c('div', {
      staticClass: "TODO-comment-commentedTime ml-2"
    }, [_vm._v(_vm._s(_vm._f("filter_date_current")(note.created_at, null, {
      formate_now: true
    })))])]), _c('div', [_vm._v(_vm._s(note.note))]), note.created_by == _vm.getCurrentUser.account ? _c('div', {
      staticClass: "todo-comment-actions"
    }, [_vm.api.remove_note.send == note.id ? _c('vb-spinner') : [_c('p', {
      staticClass: "todo-smallGreyBox-text mb-0 textUnderline",
      on: {
        "click": function ($event) {
          _vm.disabled ? '' : _vm.setUpdate(note);
        }
      }
    }, [_vm._v("Edit")]), _c('p', {
      staticClass: "todo-smallGreyBox-text mb-0 textUnderline",
      on: {
        "click": function ($event) {
          _vm.disabled ? '' : _vm.removeNote(note);
        }
      }
    }, [_vm._v("Delete")])]], 2) : _vm._e()])])]);
  })] : [_c('div', {
    staticClass: "TODO-innerHeading"
  }, [_vm._v("Activity")]), _c('div', {
    staticClass: "TODO-comment-scrollSection"
  }, [_vm._l(_vm.response.notes, function (note) {
    return _c('div', {
      key: note.id,
      staticClass: "w-100 todo-activity-item"
    }, [_c('div', {
      staticClass: "todo-activity-item-topSection"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('vb-avatar', {
      staticClass: "mr-3",
      attrs: {
        "is_blf": false,
        "id": note.created_by
      }
    }), _c('p', {
      staticClass: "TODO-comment-name mb-0"
    }, [_vm._v(" " + _vm._s(_vm.getVoipUsersAlisesDisplayName[note.created_by]) + " ")])], 1), _c('div', {
      staticClass: "todo-comment-Text"
    }, [_vm._v(_vm._s(note.note))])]), _c('div', {
      staticClass: "todo-activity-item-bottomSection"
    }, [_c('div', {
      staticClass: "TODO-comment-commentedTime ml-2"
    }, [_vm._v(_vm._s(_vm._f("filter_date_current")(note.created_at, null, {
      formate_now: true
    })))])])]);
  }), _vm._l(_vm.response.activities.logs, function (activity) {
    return _c('ActivityItem', {
      key: activity.id,
      staticClass: "todo-activity-item",
      attrs: {
        "activity": activity
      }
    });
  })], 2), _c('div', {
    staticClass: "mt-12px todo-textarea-container"
  }, [!!_vm.selected.update_id ? _c('b-form', {
    staticClass: "d-flex align-items-center w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updateNote();
      }
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.update_note.note,
      expression: "forms.update_note.note"
    }],
    staticClass: "TODO-textArea",
    attrs: {
      "rows": "auto",
      "disabled": _vm.api.update_note.send || _vm.disabled,
      "placeholder": "Write a comment..."
    },
    domProps: {
      "value": _vm.forms.update_note.note
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        $event.preventDefault();
        return _vm.updateNote();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.update_note, "note", $event.target.value);
      }
    }
  }), _vm._v(" "), _vm.forms.update_note.submitted && _vm.$v.forms.update_note.note.$invalid || _vm.api.update_note.validation_errors.note ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.update_note.note.required ? _c('span', [_vm._v("Note is required")]) : _vm._e(), _vm._l(_vm.api.update_note.validation_errors.note, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e(), _c('b-button', {
    attrs: {
      "disabled": _vm.api.update_note.send || _vm.disabled,
      "variant": "link"
    },
    on: {
      "click": function ($event) {
        _vm.selected.update_id = '';
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "x",
      "variant": "danger"
    }
  })], 1)], 1) : _c('b-form', {
    staticClass: "d-flex align-items-center w-100",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.createNote();
      }
    }
  }, [_c('textarea', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.create_note.note,
      expression: "forms.create_note.note"
    }],
    staticClass: "TODO-textArea",
    attrs: {
      "rows": "auto",
      "disabled": _vm.api.create_note.send || _vm.disabled,
      "placeholder": "Write a comment..."
    },
    domProps: {
      "value": _vm.forms.create_note.note
    },
    on: {
      "keydown": function ($event) {
        if (!$event.type.indexOf('key') && _vm._k($event.keyCode, "enter", 13, $event.key, "Enter")) return null;
        if ($event.ctrlKey || $event.shiftKey || $event.altKey || $event.metaKey) return null;
        $event.preventDefault();
        return _vm.createNote();
      },
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.create_note, "note", $event.target.value);
      }
    }
  }), _vm._v(" "), _c('div', {
    staticClass: "TODO-textArea-button-container"
  }, [_c('button', {
    staticClass: "smallBlueButton"
  }, [_vm._v(" Send ")])]), _vm.forms.create_note.submitted && _vm.$v.forms.create_note.note.$invalid || _vm.api.create_note.validation_errors.note ? _c('p', {
    staticClass: "mb-1 text-small text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.create_note.note.required ? _c('span', [_vm._v("Note is required")]) : _vm._e(), _vm._l(_vm.api.create_note.validation_errors.note, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v(_vm._s(em))]);
  })], 2) : _vm._e()])], 1)]], 2);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" Activity ")])]);

}]

export { render, staticRenderFns }