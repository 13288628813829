var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition sipActiveCall-Modal SelectSnoozeTimeModal DurationPickerModal",
    attrs: {
      "transition": "center-zoomIn-transition",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-call-notification-tray"
  }, [_c('div', {
    staticClass: "dialer-call-notification dialer-keypadNotification"
  }, [_c('div', {
    staticClass: "dialer-edit-header position-relative py-0"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0"
  }, [_vm._v(_vm._s(_vm.modalTitle))])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)], 1)]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row mainWrapper"
  }, [_c('div', {
    staticClass: "d-flex flex-column flex-fill"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Select seconds")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_vm._v("The call will be forwarded after the phone rings for " + _vm._s(_vm.forms.time.seconds) + " seconds")])]), _c('div', {
    staticClass: "d-flex align-items-center ml-3"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    staticStyle: {
      "min-width": "36px",
      "max-width": "36px",
      "padding": "0px 12px !important",
      "height": "50px"
    },
    attrs: {
      "disabled": _vm.forms.time.seconds <= 5
    },
    on: {
      "click": function ($event) {
        _vm.forms.time.seconds = _vm.forms.time.seconds - 5;
      }
    }
  }, [_vm._v("-")]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mx-2",
    staticStyle: {
      "max-width": "60px",
      "min-width": "60px"
    }
  }, [_c('input', {
    staticClass: "w-100 text-center",
    attrs: {
      "disabled": ""
    },
    domProps: {
      "value": _vm.forms.time.seconds
    }
  })]), _c('button', {
    staticClass: "dialer-button dialer-button-primary",
    staticStyle: {
      "min-width": "36px",
      "max-width": "36px",
      "padding": "0px 12px !important",
      "height": "50px"
    },
    attrs: {
      "disabled": _vm.forms.time.seconds >= 50
    },
    on: {
      "click": function ($event) {
        _vm.forms.time.seconds = _vm.forms.time.seconds + 5;
      }
    }
  }, [_vm._v("+")])])])])]), _c('div', {
    staticClass: "d-flex justify-content-end w-100"
  }, [_c('button', {
    staticClass: "fullWidthDoneButton mt-20px",
    on: {
      "click": function ($event) {
        return _vm.onDone();
      }
    }
  }, [_vm._v(" Update ")]), _vm.forms.time.submitted && _vm.$v.forms.time.$invalid ? [!_vm.$v.forms.time.required ? _c('span', [_vm._v("* time is required")]) : _vm._e()] : _vm._e()], 2)])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }