<template>
  <div>
    <!--<modal 
      class="AddNewBillingCard SubscriptionPlanDetailModal newBilling-changePlan-modal" 
      :class="conditions.full_screen? 'fullSceenMode' : ''"
      width="40%" 
      height="auto" 
      :scrollable="true" 
      :name="modalName" 
      :clickToClose="false" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="dialer-box  table  mb-4">
        
        <div class="dialer-edit-header mt-3">
          <h2 class="dialer-settings-title newer mb-0">Subscription plan details </h2>
          <div class="dialer-edit-actions">
            <b-icon v-if="getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide(modalName)" />
            <a v-else class="d-flex align-items-center">
              <b-icon :icon="conditions.full_screen? 'fullscreen-exit' : 'arrows-fullscreen'" class="fullScreenHalfScreenIcon mr-16px" @click="conditions.full_screen=!conditions.full_screen" font-scale="1.3" />
              <button type="button" @click="$modal.hide(modalName)" class="newCloseButton">
                <vb-icon icon="squared-close-icon" height="38px" width="38px" />
                <span class="newCloseButton-textPart">Close</span>
              </button>
            </a>
          </div>
        </div>
        <div v-if="api.packages.send || api.subscription_detail.send || api.update_plan.send" class="newPlans-conatiner-v2">
          <div class="newPlans-feature-column">
            <div class="newPlans-header d-flex align-items-end">
              <div class="latestShimmerDesign mb-5" style="height: 32px;width: 50%"></div>
            </div>
            <div class="newPlans-body">
              <div v-for="n in 15" :key="n.id" class="planText d-flex align-items-center">
                <div class="latestShimmerDesign" style="height: 22px;width: 50%"></div>
              </div>
            </div>
          </div>
          <div v-for="n in 3" :key="n.id" :class="{'currentPlan':n.id==1}" class="newPlans-column">
            <div class="newPlans-header d-flex flex-column align-items-center">
              <div class="latestShimmerDesign mb-1" style="height: 22px;width: 50px"></div>
              <div class="latestShimmerDesign mb-1" style="height: 37px;width: 105px"></div>
              <div class="latestShimmerDesign" style="height: 17px;width: 50%"></div>
              <div class="latestShimmerDesign mt-2" style="height: 36px;width: 50%"></div>
            </div>
            <div class="newPlans-body">
              <div v-for="n in 15" :key="n.id" class="planText d-flex align-items-center justify-content-center">
                <div class="latestShimmerDesign" style="height: 22px;width: 50%"></div>
              </div>
            </div>
          </div>
        </div>
        <!~~ <vb-loading v-if="api.packages.send || api.subscription_detail.send || api.update_plan.send" /> ~~>
        <div v-else class="newPlans-conatiner-v2">
          <div class="newPlans-feature-column ">
            <div class="newPlans-header d-flex align-items-end">
              <div class="newPlans-switchContainer h-100 align-items-end">
                <div class="d-flex align-items-center mb-5">
                  <div class="newPlans-switch-text">Billed monthly</div>
                  <b-form-checkbox
                    class="dialer-switch ml-0"
                    v-model="selected.plan_type"
                    name="check-button"
                    switch
                  />
                  <div class="newPlans-switch-text">Anually</div>
                </div>
              </div>
            </div>
            <div class="newPlans-body">
              <div v-for="product in products" :key="product.value" class="planText">{{ product.label }}</div>
              <div v-for="feature in features" :key="feature.value" class="planText">{{ feature.label }}</div>
            </div>
            <div class="newPlans-footer"></div>
          </div>
          <div v-for="(plan , index) in plans" :key="plan.real_id" :class="{'currentPlan':plan.real_id==currentPlanId}" class="newPlans-column">
            <div class="newPlans-header d-flex flex-column align-items-center">
              <!~~ <div class="planName">{{ plan.name=="Elite"?'Complete':plan.name }}</div> ~~>
              <div class="planName">{{ plan.name }}</div>
              <!~~ <div class="planPrice">{{ plan | get_property('currency.currency_code','') }} {{plan.price | floatFixed2}}</div> ~~>
              <div class="planPrice">{{ index | hardCodedPricing }}</div>
              <div class="planPerUserText">per {{ plan.interval }} per user</div>
              <button 
                class="w-fit-content mx-auto" 
                :class="`${plan.real_id==currentPlanId?'fullBlackBTN':'BlackOutlineBTN'}`" 
                :disabled="plan.real_id==currentPlanId" 
                @click="updatePlan(plan,index)"
              >
                <template v-if="plan.real_id==currentPlanId">Current plan</template>
                <template v-else>Choose plan</template>
              </button>
            </div>
            <div class="newPlans-body">
              <div v-for="product in products" :key="product.value" class="planText text-center">{{ plan | checkProductAllowed(product.value) }}</div>
              <div v-for="feature in features" :key="feature.value" class="planText text-center">
                <b-icon class="checkFeatureIcon" :icon="plan | checkFeatureAllowed(feature.value)" variant="primary" />
              </div>
            </div>
            <div class="newPlans-footer"></div>
          </div>
        </div>
      </div>
    </modal>-->

















    <div>
      <modal
        class="newPackagesModal" 
        width="40%" 
        height="auto" 
        :scrollable="true" 
        :name="modalName" 
        :clickToClose="true" 
        @before-open="onBeforeOpen" 
        @before-close="onBeforeClose"
      >
        <div class="newPackagesModal-header">
          <div class="newPackagesModal-heading">Subscription plan details</div>
          <!-- <div class="newPackagesModal-switch-container">
            <div class="newPackagesModal-switch-text mr-16px">Billed Monthly</div>
            <b-form-checkbox
              v-model="selected.plan_type"
              class="newerSwitch"
              switch
            />
            <div class="newPackagesModal-switch-text ml-16px">Annually</div>
          </div> -->
          <b-icon icon="x-lg" class="newPackagesModal-closeIcon" @click="$modal.hide(modalName)" />
        </div>
        <template v-if="api.packages.send || api.subscription_detail.send || api.update_plan.send">
          <div class="newPackagesModal-packages">
            <div v-for="(n,index) in 2" :key="index" class="newPackagesModal-package">
              <div>
                <div class="newPackagesModal-package-heading">
                  <div class="latestShimmerDesign" style="height: 32px;width: 50%"></div>
                </div>
                <div class="newPackagesModal-package-price-row">
                  <div class="newPackagesModal-package-price-column-1 w-50">
                    <div class="newPackagesModal-package-price-column-1 w-100">
                      <div class="newPackagesModal-package-price-bigNumer" style="width: 48%">
                        <div class="latestShimmerDesign" style="height: 46.8px;width: 100%"></div>
                      </div>
                      <div class="newPackagesModal-package-price-column-1-text" style="width: 48%">
                        <div class="latestShimmerDesign" style="height: 46.8px;width: 100%"></div>
                      </div>
                    </div>
                  </div>
                  <div class="newPackagesModal-package-price-column-2 w-25">
                    <div class="latestShimmerDesign" style="height: 46.8px;width: 100%"></div>
                  </div>
                </div>
                <div class="newPackagesModal-package-features mb-20px">
                  <div class="package-features-row mt-16px" v-for="n in 8" :key="n.id">
                    <div class="latestShimmerDesign" style="height: 23.39px;width: 100%"></div>
                  </div>
                </div>
              </div>
              <div class="latestShimmerDesign" style="height: 50px;width: 100%"></div>
            </div>
          </div>
          <div class="newPackagesModal-freePackage">
            <div class="newPackagesModal-freePackage-leftSide" style="width: 100px;">
              <div class="latestShimmerDesign" style="height: 30px;width: 100%"></div>
              <div class="latestShimmerDesign" style="height: 42px;width: 100%;margin-top: 6px"></div>
            </div>
            <div class="newPackagesModal-freePackage-rightSide w-75">
              <div class="latestShimmerDesign" style="height: 23.39px;width: 100%"></div>
              <div class="newPackagesModal-package-features d-flex align-items-center">
                <div v-for="n in 3" :key="n.id" class="latestShimmerDesign mt-16px mr-32px flex-1" style="height: 23.39px;width: 100%"></div>
              </div>
            </div>
          </div>
        </template>
        <template v-else-if="response.packages.length==0">
          <div class="latestGreyBox-9-9-2023 mt-20px">
              <div class="whiteBoxWithBorderInsideGreyBox">
                <div class="whiteBoxWithBorderInsideGreyBox-row justify-content-center">
                  <div class="w-100 d-flex flex-column align-items-center px-4">
                    <img
                      class="mx-auto" 
                      width="50%" 
                      height="auto" 
                      :src="require('@/assets/images/emptyScreenImages/serverConnection.png')"
                    />
                    <h4 class="latestGreyBox-heading-main text-center mt-20px mb-0">
                      It's taking longer than usual to connect to our servers. Please check your internet connection and try again.
                    </h4>
                  </div>
                </div>
              </div>
          </div>
        </template>
        <template v-else>
          <div v-if="isTrailPlan" class="innerBlueBoxwithRoundedBorder mt-20px">
            <vb-icon icon="AccountBalance-newBilling-icon" height="25px" width="25px" class="AccountBalance-newBilling-icon"/>
            <div class="d-flex align-items-center justify-content-between flex-fill">
              <p class="innerRedBoxwithRoundedBorder-text mb-0">
                Cancel your trial plan first or wait for trial period
              </p>
            </div>
          </div>
          <div class="newPackagesModal-packages">
            <div v-for="(plan,index) in allPaidPlans" :key="index" class="newPackagesModal-package" :class="{ active: plan.real_id==currentPlanId }">
              <div>
                <div class="newPackagesModal-package-heading">{{index==0?'Professional':'Complete'}}</div>
                <div class="newPackagesModal-package-price-row">
                  <div class="newPackagesModal-package-price-column-1">
                    <div class="newPackagesModal-package-price-column-1">
                      <div class="newPackagesModal-package-price-bigNumer">
                        {{index==0?'£4.99':'£9.99'}}
                      </div>
                      <div class="newPackagesModal-package-price-column-1-text">
                        PER <br/>MONTH /USER
                      </div>
                    </div>
                  </div>
                  <!-- <div class="newPackagesModal-package-price-column-2">
                    <div class="newPackagesModal-package-price-column-2-number">{{index==0?'£59.88':'£119.88'}}</div>
                    <div class="newPackagesModal-package-price-column-2-text">Billed annaully</div>
                  </div> -->
                </div>
                <div v-if="index==0" class="newPackagesModal-package-features mb-20px">
                  <div class="package-features-row">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Call analytics</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <!-- <div class="package-features-row-text">Unlimited calls</div> -->
                    <div class="package-features-row-text">Unlimited calling options available</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Business hours setting</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Call notes and tags</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Integrations to other systems</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Call recording for numbers</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Custom sound files</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Number blocking and privacy</div>
                  </div>
                </div>
                <div v-else class="newPackagesModal-package-features mb-20px">
                  <div class="package-features-row">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Smart call queueing</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Live call monitoring</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">AI transcription</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">BLF( Busy lamp field )</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Unlimited custom statuses</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Advanced IVR options</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Custom MOH</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Advance greetings in voicemails</div>
                  </div>
                </div>
              </div>
              <button :disabled="plan.real_id==currentPlanId" @click="updatePlan(plan)" class="newPackagesModal-package-button mt-auto">
                <template v-if="plan.real_id==currentPlanId">
                  Current plan
                </template>
                <template v-else>
                  Choose plan
                </template>
              </button>
            </div>
            <!-- Professional -->
            <!--<div class="newPackagesModal-package active">
              <div>
                <div class="newPackagesModal-package-heading">Professional</div>
                <div class="newPackagesModal-package-price-row">
                  <div class="newPackagesModal-package-price-column-1">
                    <div class="newPackagesModal-package-price-column-1">
                      <div class="newPackagesModal-package-price-bigNumer">
                        £4.99
                      </div>
                      <div class="newPackagesModal-package-price-column-1-text">
                        PER <br/>MONTH /USER
                      </div>
                    </div>
                  </div>
                  <div class="newPackagesModal-package-price-column-2">
                    <div class="newPackagesModal-package-price-column-2-number">£59.88</div>
                    <div class="newPackagesModal-package-price-column-2-text">Billed annaully</div>
                  </div>
                </div>
                <div class="newPackagesModal-package-features mb-20px">
                  <div class="package-features-row">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Smart queueing</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">SMS / Text messages</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Call Monitoring</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Unlimited concurrent calls</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Integrations to other systems</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">AI Transcription</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Task Management</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Power Dialer, Smart Dialer</div>
                  </div>
                </div>
              </div>
              <button :disabled="isTrailPlan" class="newPackagesModal-package-button mt-auto">
                Choose plan
              </button>
            </div>-->
            <!-- Complete -->
            <!--<div class="newPackagesModal-package">
              <div>
                <div class="newPackagesModal-package-heading">Complete</div>
                <div class="newPackagesModal-package-price-row">
                  <div class="newPackagesModal-package-price-column-1">
                    <div class="newPackagesModal-package-price-column-1">
                      <div class="newPackagesModal-package-price-bigNumer">
                        £9.99
                      </div>
                      <div class="newPackagesModal-package-price-column-1-text">
                        PER <br/>MONTH /USER
                      </div>
                    </div>
                  </div>
                  <div class="newPackagesModal-package-price-column-2">
                    <div class="newPackagesModal-package-price-column-2-number">£119.88</div>
                    <div class="newPackagesModal-package-price-column-2-text">Billed annaully</div>
                  </div>
                </div>
                <div class="newPackagesModal-package-features mb-20px">
                  <div class="package-features-row">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Custom onboarding</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Enterprise-level security</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Custom reporting</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">Developer support</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">USA Number</div>
                  </div>
                  <div class="package-features-row mt-16px">
                    <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                    <div class="package-features-row-text">IVR</div>
                  </div>
                </div>
              </div>
              <button :disabled="isTrailPlan" class="newPackagesModal-package-button">
                Choose plan
              </button>
            </div>-->
          </div>
          <div v-if="users.length>3" class="innerBlueBoxwithRoundedBorder mt-20px">
            <vb-icon icon="AccountBalance-newBilling-icon" height="25px" width="25px" class="AccountBalance-newBilling-icon"/>
            <div class="d-flex align-items-center justify-content-between flex-fill">
              <p class="innerRedBoxwithRoundedBorder-text mb-0">
                You are not eligible for free package as your account have more than two users!
              </p>
            </div>
          </div>
          <!-- freePlan -->
          <div v-else-if="freePlan" :class="`newPackagesModal-freePackage ${freePlan.real_id==currentPlanId ? 'active' : ''}`" :disabled="freePlan.real_id==currentPlanId || isTrailPlan" @click="updatePlan(freePlan)">
            <!-- {{freePlan}} -->
            <div class="newPackagesModal-freePackage-leftSide" style="pointer-events: none">
              <div class="newPackagesModal-freePackage-smallHeading">Free</div>
              <div class="newPackagesModal-freePackage-bigHeading">£0.00</div>
            </div>
            <div class="newPackagesModal-freePackage-rightSide" style="pointer-events: none">
              <div class="newPackagesModal-freePackage-rightSide-text">
                Introductory package to get you going
              </div>
              <div class="newPackagesModal-package-features d-flex align-items-center">
                <div class="package-features-row mt-16px mr-32px">
                  <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                  <div class="package-features-row-text">Realtime notifications</div>
                </div>
                <div class="package-features-row mt-16px mr-32px">
                  <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                  <div class="package-features-row-text">Chat and video calling</div>
                </div>
                <div class="package-features-row mt-16px">
                  <vb-icon icon="packages-feature-roundTick-icon" width="20px" height="20px"/>
                  <div class="package-features-row-text">Task Management</div>
                </div>
              </div>
            </div>
          </div>
          <div class="w-100 d-flex justify-content-center mt-20px">
            <div class="newPackagesModal-viewDetails-button" @click="$modal.show(`${_uid}-newPackagesComparisonModal`)">View more details</div>
          </div>
        </template>
      </modal>
    </div>
    <div>
      <modal
        class="newPackagesComparisonModal" 
        width="40%" 
        height="auto" 
        :scrollable="true" 
        :name="`${_uid}-newPackagesComparisonModal`" 
        :clickToClose="true" 
      >
        <div v-if="api.packages.send || api.subscription_detail.send || api.update_plan.send" class="newPackagesComparisonModal-container">
          <b-icon icon="x-lg" class="newPackagesComparisonModal-closeIcon" @click="$modal.hide(`${_uid}-newPackagesComparisonModal`)" />
          <div class="newPackagesComparisonModal-feature-column">
            <div class="newPackagesComparisonModal-column-header">
              <div class="newPackagesModal-heading">Subscription plan details</div>
              <div class="newPackagesComparisonModal-features-heading">Detailed pricing overview</div>
            </div>
            <div class="newPackagesComparisonModal-column-body">
              <div v-for="(product , index) in products" :key="product.value" :class="`newPackagesComparisonModal-item ${index%2==1?'withStripeBg':''}`">
                <div class="latestShimmerDesign" style="height: 22px;width: 50%"></div>
              </div>
              <div v-for="(feature,index) in features" :key="feature.value" :class="`newPackagesComparisonModal-item ${index%2==1?'withStripeBg':''}`">
                <div class="latestShimmerDesign" style="height: 22px;width: 50%"></div>
              </div>
            </div>
          </div>
          <div v-for="n in 3" :key="n.id" class="newPackagesComparisonModal-package-column">
            <div class="newPackagesComparisonModal-package-column-header">
              <div class="newPackagesComparisonModal-package-name w-100 d-flex justify-content-center">
                <div class="latestShimmerDesign" style="height: 41.59px;width: 50%"></div>
              </div>
              <div class="newPackagesComparisonModal-package-price w-100 d-flex justify-content-center">
                <div class="latestShimmerDesign" style="height: 40px;width: 50%"></div>
              </div>
              <div class="newPackagesComparisonModal-package-subText w-100 d-flex justify-content-center">
                <div class="latestShimmerDesign" style="height: 26px;width: 50%;margin-top:5.5px"></div>
              </div>
              <div class="latestShimmerDesign mt-15px" style="height: 50px;width: 100%"></div>
            </div>
            <div class="newPackagesComparisonModal-column-body">
              <div v-for="(product , index) in products" :key="product.value" :class="`newPackagesComparisonModal-item ${index%2==1?'withStripeBg':''}`">
                <div class="latestShimmerDesign" style="height: 22px;width: 50%"></div>
              </div>
              <div v-for="(feature,index) in features" :key="feature.value" :class="`newPackagesComparisonModal-item ${index%2==1?'withStripeBg':''}`">
                <div class="latestShimmerDesign" style="height: 22px;width: 50%"></div>
              </div>
            </div>
          </div>
        </div>
        <div v-else class="newPackagesComparisonModal-container">
          <b-icon icon="x-lg" class="newPackagesComparisonModal-closeIcon" @click="$modal.hide(`${_uid}-newPackagesComparisonModal`)" />
          <div class="newPackagesComparisonModal-feature-column">
            <div class="newPackagesComparisonModal-column-header">
              <div class="newPackagesModal-heading">Subscription plan details</div>
              <!-- <div class="newPackagesModal-switch-container">
                <div class="newPackagesModal-switch-text mr-16px">Billed Monthly</div>
                <b-form-checkbox
                  v-model="selected.plan_type"
                  class="newerSwitch"
                  switch
                />
                <div class="newPackagesModal-switch-text ml-16px">Annually</div>
              </div> -->
              <div class="newPackagesComparisonModal-features-heading">Detailed pricing overview</div>
            </div>
            <div class="newPackagesComparisonModal-column-body">
              <div v-for="(product , index) in products" :key="product.value" :class="`newPackagesComparisonModal-item ${index%2==1?'withStripeBg':''}`">{{ product.label }}</div>
              <div v-for="(feature,index) in features" :key="feature.value" :class="`newPackagesComparisonModal-item ${index%2==1?'withStripeBg':''}`">{{ feature.label }}</div>
            </div>
          </div>
          <div v-for="(plan , index) in plans" :key="plan.real_id" :class="{'isCurrentPlan':plan.real_id==currentPlanId}" class="newPackagesComparisonModal-package-column">
            <div class="newPackagesComparisonModal-package-column-header">
              <div class="newPackagesComparisonModal-package-name">{{ plan.name }}</div>
              <div class="newPackagesComparisonModal-package-price">{{ index | hardCodedPricing }}</div>
              <div class="newPackagesComparisonModal-package-subText">per {{ plan.interval }} per user</div>
              <button 
                :class="`newPackagesModal-package-button mt-15px ${plan.real_id==currentPlanId?'active' : ''}`"
                :disabled="plan.real_id==currentPlanId" 
                @click="updatePlan(plan)"
              >
                <template v-if="plan.real_id==currentPlanId">Current plan</template>
                <template v-else>Choose plan</template>
              </button>
            </div>
            <div class="newPackagesComparisonModal-column-body">
              <div v-for="(product,index) in products" :key="product.value" :class="`newPackagesComparisonModal-item ${index%2==1?'withStripeBg':''}`">{{ plan | checkProductAllowed(product.value) }}</div>
              <div v-for="(feature,index) in features" :key="feature.value" :class="`newPackagesComparisonModal-item ${index%2==1?'withStripeBg':''}`">
                <b-icon :icon="plan | checkFeatureAllowed(feature.value)" variant="primary" />
              </div>
            </div>
          </div>
        </div>
      </modal>
    </div>
    <UpdatePlanComparisonsListModal 
      :subscription_detail="response.subscription_detail" 
      :allAddons="allAddons" 
      @delete-service="$emit('delete-service')" 
    />
  </div>
</template>

<script>
import { $fn, VOIP_API } from "../../../utils";
// import { centrifugo } from '../../../Centrifuge';
import { mapGetters, mapState } from 'vuex';
import UpdatePlanComparisonsListModal from './UpdatePlanComparisonsListModal.vue';
import { AMI_EVENTS, AMI_SOCKET } from '@/ami';
const products = [
  {
    label: 'User',
    value: 'us'
  },
  {
    label: 'Team',
    value: 'tm'
  },
  {
    label: 'Call Queue',
    value: 'cq'
  },
  {
    label: 'UK Geographic Number',
    value: 'ukc'
  },
  {
    label: 'UK Mobile Number',
    value: 'ukm'
  },
  {
    label: 'USA Number',
    value: 'usa'
  },
  {
    label: 'UK National Number',
    value: 'ukn'
  },
  {
    label: 'IVR',
    value: 'ivrs'
  },
]
const features = [
  {
    label: 'Desktop notification',
    value: 'desktop-notification'
  },
  {
    label: 'Chat',
    value: 'chat'
  },
  {
    label: 'Meeting',
    value: 'meeting'
  },
  {
    label: 'Analytics',
    value: 'analytics'
  },
  {
    label: 'Share sound file',
    value: 'share-sound-file'
  },
  {
    label: 'Add sound file',
    value: 'add-sound-file'
  },
  {
    label: 'Block and privacy',
    value: 'block-and-privacy'
  },
  {
    label: 'Forward source caller id',
    value: 'forward-source-caller-id'
  },
  {
    label: 'Status',
    value: 'status'
  },
  {
    label: 'Call waiting',
    value: 'call-waiting'
  },
  {
    label: 'Missed call notification',
    value: 'missed-call-notification'
  },
  {
    label: 'Out of hours',
    value: 'out-of-hours'
  },
  {
    label: 'Monitor dashboard',
    value: 'monitor-dashboard'
  },
  {
    label: 'Mobile extension',
    value: 'mobile-extension'
  },
  {
    label: 'Incoming call display',
    value: 'incoming-call-display'
  },
  {
    label: 'External extension',
    value: 'external-extension'
  },
  {
    label: 'Call tagging and notes',
    value: 'call-tagging-and-notes'
  },
  {
    label: 'Multi moh',
    value: 'multi-moh'
  },
  {
    label: 'Blf',
    value: 'blf'
  },
  {
    label: 'Unlimited custom status',
    value: 'unlimited-custom-status'
  },
  {
    label: 'Call pickup',
    value: 'call-pickup'
  },
  {
    label: 'Advanced greetings in voice mail',
    value: 'advanced-greetings-in-voice-mail'
  },
  {
    label: 'Voice mailbox sharing',
    value: 'voice-mailbox-sharing'
  },
  {
    label: 'Voicemail attachment',
    value: 'voicemail-attachment'
  },
  {
    label: 'Voice mail transcript',
    value: 'voice-mail-transcript'
  },
  {
    label: 'Advanced call handling',
    value: 'advanced-call-handling'
  },
  {
    label: 'Call recordings backup',
    value: 'call-recordings-backup'
  },
  {
    label: 'Call recording',
    value: 'call-recording'
  },
  {
    label: 'Call queue',
    value: 'call-queue'
  },
  {
    label: 'Integrations',
    value: 'integrations'
  },
  {
    label: 'Custom moh',
    value: 'custom-moh'
  },
  {
    label: 'Enabling pin for voicemail',
    value: 'enabling-pin-for-voicemail'
  },
  {
    label: 'After call feedback',
    value: 'after-call-feedback'
  },
  {
    label: 'Redirecting to another number',
    value: 'redirecting-to-another-number'
  },
  {
    label: 'Call recording transcriptions',
    value: 'call-recording-transcriptions'
  },
  {
    label: 'Ivr advance options',
    value: 'ivr-advance-options'
  },
  {
    label: 'Call tags',
    value: 'call-tags'
  },
  {
    label: 'Sound library add sound files',
    value: 'sound-library-add-sound-files'
  },
  {
    label: 'Task hub',
    value: 'task-hub'
  },
  {
    label: 'Enable push notifications',
    value: 'enable-push-notifications'
  },
  {
    label: 'Call recordings for numbers',
    value: 'call-recording-did'
  },
]
export default {
  name: "SubscriptionPlanDetailModal",
  components: { 
    UpdatePlanComparisonsListModal 
  },
  props: {
    modalName: {
      type: String,
      default: 'SubscriptionPlanDetailModal'
    },
    allAddons: {
      type: Array,
      default: ()=>[]
    },
  },
  data() {
    return {
      api: {
        packages: this.$helperFunction.apiInstance({  }),
        update_plan: this.$helperFunction.apiInstance({ send: '' }),
        subscription_detail: this.$helperFunction.apiInstance({  }),
      },
      selected: {
        plan_type: false,
        reset(){
          this.plan_type = false
        },
      },
      response: {
        subscription_detail: {},
        packages: [],
        reset(){
          this.subscription_detail = {}
          this.packages = []
        },
      },
      conditions: {
        full_screen: false,
        reset(){
          this.full_screen = false
        },
      },
      mainPlans:[
        {
          name: 'Free',
          price: '£0.00',
          interval: 'month',
          real_id:79,
          products: [
            {
              plan_free_product_allowed: '2',
              product:{
                product_label: 'USER',
                product_billing_prefix: 'us',
              },
            },
            {
              plan_free_product_allowed: '3',
              product:{
                product_label: 'Team',
                product_billing_prefix: 'tm',
              },
            },
            {
              plan_free_product_allowed: '1',
              product:{
                product_label: 'Call Queue',
                product_billing_prefix: 'cq',
              },
            },
            {
              plan_free_product_allowed: '0',
              product:{
                product_label: 'UK Geographic Number',
                product_billing_prefix: 'ukc',
              },
            },
            {
              plan_free_product_allowed: '0',
              product:{
                product_label: 'UK Mobile Number',
                product_billing_prefix: 'ukm',
              },
            },
            {
              plan_free_product_allowed: '0',
              product:{
                product_label: 'USA Number',
                product_billing_prefix: 'usa',
              },
            },
            {
              plan_free_product_allowed: '0',
              product:{
                product_label: 'UK National Number',
                product_billing_prefix: 'ukn',
              },
            },
            {
              plan_free_product_allowed: '2',
              product:{
                product_label: 'IVR',
                product_billing_prefix: 'ivrs',
              },
            },
          ],
          featureGroup: {
            label: 'full features',
            features: [
              {
                label: 'Desktop notification',
                value: 'desktop-notification',
                feature: {
                  label:"desktop-notification",
                  slug:"desktop-notification",
                }
              },
              {
                label: 'Chat',
                value: 'chat',
                feature: {
                  label:"chat",
                  slug:"chat",
                }
              },
              {
                label: 'Meeting',
                value: 'meeting',
                feature: {
                  label:"meeting",
                  slug:"meeting",
                }
              },
              {
                label: 'Analytics',
                value: 'analytics',
                feature: {
                  label:"analytics",
                  slug:"analytics",
                }
              },
              {
                label: 'Share sound file',
                value: 'share-sound-file',
                feature: {
                  label:"share-sound-file",
                  slug:"share-sound-file",
                }
              },
              {
                label: 'Add sound file',
                value: 'add-sound-file',
                feature: {
                  label:"add-sound-file",
                  slug:"add-sound-file",
                }
              },
              {
                label: 'Block and privacy',
                value: 'block-and-privacy',
                feature: {
                  label:"block-and-privacy",
                  slug:"block-and-privacy",
                }
              },
              {
                label: 'Forward source caller id',
                value: 'forward-source-caller-id',
                feature: {
                  label:"forward-source-caller-id",
                  slug:"forward-source-caller-id",
                }
              },
              {
                label: 'Status',
                value: 'status',
                feature: {
                  label:"status",
                  slug:"status",
                }
              },
              {
                label: 'Missed call notification',
                value: 'missed-call-notification',
                feature: {
                  label:"missed-call-notification",
                  slug:"missed-call-notification",
                }
              },
              {
                label: 'Call tagging and notes',
                value: 'call-tagging-and-notes',
                feature: {
                  label:"call-tagging-and-notes",
                  slug:"call-tagging-and-notes",
                }
              },
              {
                label: 'Unlimited custom status',
                value: 'unlimited-custom-status',
                feature: {
                  label:"unlimited-custom-status",
                  slug:"unlimited-custom-status",
                }
              },
              {
                label: 'Advanced call handling',
                value: 'advanced-call-handling',
                feature: {
                  label:"advanced-call-handling",
                  slug:"advanced-call-handling",
                }
              },
            ]
          }
        },
        {
          name: 'Professional',
          price: '£4.99',
          interval: 'month',
          real_id:80,
          products: [
            {
              product:{
                product_label: 'USER',
                product_billing_prefix: 'us',
              },
                plan_free_product_allowed: '0',
            },
            {
              product:{
                product_label: 'Team',
                product_billing_prefix: 'tm',
              },
                plan_free_product_allowed: '50',
            },
            {
              product:{
                product_label: 'Call Queue',
                product_billing_prefix: 'cq',
              },
                plan_free_product_allowed: '50',
            },
            {
              product:{
                product_label: 'UK Geographic Number',
                product_billing_prefix: 'ukc',
              },
                plan_free_product_allowed: '0',
            },
            {
              product:{
                product_label: 'UK Mobile Number',
                product_billing_prefix: 'ukm',
              },
                plan_free_product_allowed: '0',
            },
            {
              product:{
                product_label: 'USA Number',
                product_billing_prefix: 'usa',
              },
                plan_free_product_allowed: '0',
            },
            {
              product:{
                product_label: 'UK National Number',
                product_billing_prefix: 'ukn',
              },
                plan_free_product_allowed: '0',
            },
            {
              product:{
                product_label: 'IVR',
                product_billing_prefix: 'ivrs',
              },
                plan_free_product_allowed: '50',
            },
          ],
          featureGroup: {
            label: 'full features',
            features: [
              {
                label: 'Desktop notification',
                value: 'desktop-notification',
                feature: {
                  label:"desktop-notification",
                  slug:"desktop-notification",
                }
              },
              {
                label: 'Chat',
                value: 'chat',
                feature: {
                  label:"chat",
                  slug:"chat",
                }
              },
              {
                label: 'Meeting',
                value: 'meeting',
                feature: {
                  label:"meeting",
                  slug:"meeting",
                }
              },
              {
                label: 'Analytics',
                value: 'analytics',
                feature: {
                  label:"analytics",
                  slug:"analytics",
                }
              },
              {
                label: 'Share sound file',
                value: 'share-sound-file',
                feature: {
                  label:"share-sound-file",
                  slug:"share-sound-file",
                }
              },
              {
                label: 'Add sound file',
                value: 'add-sound-file',
                feature: {
                  label:"add-sound-file",
                  slug:"add-sound-file",
                }
              },
              {
                label: 'Block and privacy',
                value: 'block-and-privacy',
                feature: {
                  label:"block-and-privacy",
                  slug:"block-and-privacy",
                }
              },
              {
                label: 'Forward source caller id',
                value: 'forward-source-caller-id',
                feature: {
                  label:"forward-source-caller-id",
                  slug:"forward-source-caller-id",
                }
              },
              {
                label: 'Status',
                value: 'status',
                feature: {
                  label:"status",
                  slug:"status",
                }
              },
              {
                label: 'Call waiting',
                value: 'call-waiting',
                feature: {
                  label:"call-waiting",
                  slug:"call-waiting",
                }
              },
              {
                label: 'Missed call notification',
                value: 'missed-call-notification',
                feature: {
                  label:"missed-call-notification",
                  slug:"missed-call-notification",
                }
              },
              {
                label: 'Out of hours',
                value: 'out-of-hours',
                feature: {
                  label:"out-of-hours",
                  slug:"out-of-hours",
                }
              },
              {
                label: 'Incoming call display',
                value: 'incoming-call-display',
                feature: {
                  label:"incoming-call-display",
                  slug:"incoming-call-display",
                }
              },
              {
                label: 'Call tagging and notes',
                value: 'call-tagging-and-notes',
                feature: {
                  label:"call-tagging-and-notes",
                  slug:"call-tagging-and-notes",
                }
              },
              {
                label: 'Multi moh',
                value: 'multi-moh',
                feature: {
                  label:"multi-moh",
                  slug:"multi-moh",
                }
              },
              {
                label: 'Voice mailbox sharing',
                value: 'voice-mailbox-sharing',
                feature: {
                  label:"voice-mailbox-sharing",
                  slug:"voice-mailbox-sharing",
                }
              },
              {
                label: 'Voicemail attachment',
                value: 'voicemail-attachment',
                feature: {
                  label:"voicemail-attachment",
                  slug:"voicemail-attachment",
                }
              },
              {
                label: 'Advanced call handling',
                value: 'advanced-call-handling',
                feature: {
                  label:"advanced-call-handling",
                  slug:"advanced-call-handling",
                }
              },
              {
                label: 'Call recording',
                value: 'call-recording',
                feature: {
                  label:"call-recording",
                  slug:"call-recording",
                }
              },
            ]
          }
        },
        {
          name: 'Complete',
          price: '£9.99',
          interval: 'month',
          real_id:81,
          products: [
            {
              product:{
                product_label: 'USER',
                product_billing_prefix: 'us',
              },
              plan_free_product_allowed: '0',
            },
            {
              product:{
                product_label: 'Team',
                product_billing_prefix: 'tm',
              },
              plan_free_product_allowed: '50',
            },
            {
              product:{
                product_label: 'Call Queue',
                product_billing_prefix: 'cq',
              },
              plan_free_product_allowed: '50',
            },
            {
              product:{
                product_label: 'UK Geographic Number',
                product_billing_prefix: 'ukc',
              },
              plan_free_product_allowed: '0',
            },
            {
              product:{
                product_label: 'UK Mobile Number',
                product_billing_prefix: 'ukm',
              },
              plan_free_product_allowed: '0',
            },
            {
              product:{
                product_label: 'USA Number',
                product_billing_prefix: 'usa',
              },
              plan_free_product_allowed: '0',
            },
            {
              product:{
                product_label: 'UK National Number',
                product_billing_prefix: 'ukn',
              },
              plan_free_product_allowed: '0',
            },
            {
              product:{
                product_label: 'IVR',
                product_billing_prefix: 'ivrs',
              },
              plan_free_product_allowed: '50',
            },
          ],
          featureGroup: {
            label: 'full features',
            features: [
              {
                label: 'Desktop notification',
                value: 'desktop-notification',
                feature: {
                  label:"desktop-notification",
                  slug:"desktop-notification",
                }
              },
              {
                label: 'Chat',
                value: 'chat',
                feature: {
                  label:"chat",
                  slug:"chat",
                }
              },
              {
                label: 'Meeting',
                value: 'meeting',
                feature: {
                  label:"meeting",
                  slug:"meeting",
                }
              },
              {
                label: 'Analytics',
                value: 'analytics',
                feature: {
                  label:"analytics",
                  slug:"analytics",
                }
              },
              {
                label: 'Share sound file',
                value: 'share-sound-file',
                feature: {
                  label:"share-sound-file",
                  slug:"share-sound-file",
                }
              },
              {
                label: 'Add sound file',
                value: 'add-sound-file',
                feature: {
                  label:"add-sound-file",
                  slug:"add-sound-file",
                }
              },
              {
                label: 'Block and privacy',
                value: 'block-and-privacy',
                feature: {
                  label:"block-and-privacy",
                  slug:"block-and-privacy",
                }
              },
              {
                label: 'Forward source caller id',
                value: 'forward-source-caller-id',
                feature: {
                  label:"forward-source-caller-id",
                  slug:"forward-source-caller-id",
                }
              },
              {
                label: 'Status',
                value: 'status',
                feature: {
                  label:"status",
                  slug:"status",
                }
              },
              {
                label: 'Call waiting',
                value: 'call-waiting',
                feature: {
                  label:"call-waiting",
                  slug:"call-waiting",
                }
              },
              {
                label: 'Missed call notification',
                value: 'missed-call-notification',
                feature: {
                  label:"missed-call-notification",
                  slug:"missed-call-notification",
                }
              },
              {
                label: 'Out of hours',
                value: 'out-of-hours',
                feature: {
                  label:"out-of-hours",
                  slug:"out-of-hours",
                }
              },
              {
                label: 'Monitor dashboard',
                value: 'monitor-dashboard',
                feature: {
                  label:"monitor-dashboard",
                  slug:"monitor-dashboard",
                }
              },
              {
                label: 'Mobile extension',
                value: 'mobile-extension',
                feature: {
                  label:"mobile-extension",
                  slug:"mobile-extension",
                }
              },
              {
                label: 'Incoming call display',
                value: 'incoming-call-display',
                feature: {
                  label:"incoming-call-display",
                  slug:"incoming-call-display",
                }
              },
              {
                label: 'External extension',
                value: 'external-extension',
                feature: {
                  label:"external-extension",
                  slug:"external-extension",
                }
              },
              {
                label: 'Call tagging and notes',
                value: 'call-tagging-and-notes',
                feature: {
                  label:"call-tagging-and-notes",
                  slug:"call-tagging-and-notes",
                }
              },
              {
                label: 'Multi moh',
                value: 'multi-moh',
                feature: {
                  label:"multi-moh",
                  slug:"multi-moh",
                }
              },
              {
                label: 'Blf',
                value: 'blf',
                feature: {
                  label:"blf",
                  slug:"blf",
                }
              },
              {
                label: 'Unlimited custom status',
                value: 'unlimited-custom-status',
                feature: {
                  label:"unlimited-custom-status",
                  slug:"unlimited-custom-status",
                }
              },
              {
                label: 'Call pickup',
                value: 'call-pickup',
                feature: {
                  label:"call-pickup",
                  slug:"call-pickup",
                }
              },
              {
                label: 'Advanced greetings in voice mail',
                value: 'advanced-greetings-in-voice-mail',
                feature: {
                  label:"advanced-greetings-in-voice-mail",
                  slug:"advanced-greetings-in-voice-mail",
                }
              },
              {
                label: 'Voice mailbox sharing',
                value: 'voice-mailbox-sharing',
                feature: {
                  label:"voice-mailbox-sharing",
                  slug:"voice-mailbox-sharing",
                }
              },
              {
                label: 'Voicemail attachment',
                value: 'voicemail-attachment',
                feature: {
                  label:"voicemail-attachment",
                  slug:"voicemail-attachment",
                }
              },
              {
                label: 'Voice mail transcript',
                value: 'voice-mail-transcript',
                feature: {
                  label:"voice-mail-transcript",
                  slug:"voice-mail-transcript",
                }
              },
              {
                label: 'Advanced call handling',
                value: 'advanced-call-handling',
                feature: {
                  label:"advanced-call-handling",
                  slug:"advanced-call-handling",
                }
              },
              {
                label: 'Call recordings backup',
                value: 'call-recordings-backup',
                feature: {
                  label:"call-recordings-backup",
                  slug:"call-recordings-backup",
                }
              },
              {
                label: 'Call recording',
                value: 'call-recording',
                feature: {
                  label:"call-recording",
                  slug:"call-recording",
                }
              },
              {
                label: 'Call queue',
                value: 'call-queue',
                feature: {
                  label:"call-queue",
                  slug:"call-queue",
                }
              },
              {
                label: 'Integrations',
                value: 'integrations',
                feature: {
                  label:"integrations",
                  slug:"integrations",
                }
              },
              {
                label: 'Custom moh',
                value: 'custom-moh',
                feature: {
                  label:"custom-moh",
                  slug:"custom-moh",
                }
              },
              {
                label: 'Enabling pin for voicemail',
                value: 'enabling-pin-for-voicemail',
                feature: {
                  label:"enabling-pin-for-voicemail",
                  slug:"enabling-pin-for-voicemail",
                }
              },
              {
                label: 'After call feedback',
                value: 'after-call-feedback',
                feature: {
                  label:"after-call-feedback",
                  slug:"after-call-feedback",
                }
              },
              {
                label: 'Redirecting to another number',
                value: 'redirecting-to-another-number',
                feature: {
                  label:"redirecting-to-another-number",
                  slug:"redirecting-to-another-number",
                }
              },
              {
                label: 'Call recording transcriptions',
                value: 'call-recording-transcriptions',
                feature: {
                  label:"call-recording-transcriptions",
                  slug:"call-recording-transcriptions",
                }
              },
              {
                label: 'Ivr advance options',
                value: 'ivr-advance-options',
                feature: {
                  label:"ivr-advance-options",
                  slug:"ivr-advance-options",
                }
              },
              {
                label: 'Call tags',
                value: 'call-tags',
                feature: {
                  label:"call-tags",
                  slug:"call-tags",
                }
              },
              {
                label: 'Sound library add sound files',
                value: 'sound-library-add-sound-files',
                feature: {
                  label:"sound-library-add-sound-files",
                  slug:"sound-library-add-sound-files",
                }
              },
              {
                label: 'Task hub',
                value: 'task-hub',
                feature: {
                  label:"task-hub",
                  slug:"task-hub",
                }
              },
              {
                label: 'Enable push notifications',
                value: 'enable-push-notifications',
                feature: {
                  label:"enable-push-notifications",
                  slug:"enable-push-notifications",
                }
              },
            ]
          }
        }
      ]
    };
  },
  inject:[
    'getChangePlanLog',
    'getFeatures',
    'appFetchSubscriptionDetail',
    'appNotify'
  ],
  filters: {
    checkProductAllowed(data,type){ return data?.products?.find(item=>item?.product?.product_billing_prefix==type)?.plan_free_product_allowed ?? 0 },
    checkFeatureAllowed(data,type){ return data?.featureGroup?.features?.find(item=>item?.feature?.label==type) ? 'check-circle-fill' : 'dash-lg' },
    floatFixed2(value){ return value ? parseFloat(value).toFixed(2) : 0 },
    hardCodedPricing(value){
      // if(value == '1'){
      //   return '5 GBP'
      // }
      // else if(value == '2'){
      //   return '10 GBP'
      // }
      // else {return '0 GBP'}
      if(value == '1'){
        return '£4.99'
      }
      else if(value == '2'){
        return '£9.99'
      }
      else {return '£0.00'}
    },
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
    ]),
    ...mapState({
      users: state=>Object.values(state.common.voipusers)
    }),
    products(){ return products },
    features(){ return features },
    plans(){
      const interval = this.selected.plan_type ? 'year':'month'
      const packages = this.response.packages
      return packages.filter(item=>item.interval==interval)
    },
    allPaidPlans(){ return this.plans.filter(i=>i.isFreePlan!=1) },
    freePlan(){ return this.plans.find(i=>i.isFreePlan==1) },
    // AllFreePlan(){ return this.plans.find(i=>i.isFreePlan==1) },
    currentPlanId(){ return this.response?.subscription_detail?.userCurrentPlanFeatures?.plan_id },
    isTrailPlan(){ return /*true ?? */this.response?.subscription_detail?.usercheckTrial?.trial?.trial_plan_status=='active' },
  },
  methods: {
    onBeforeOpen(){
      this.getChangePlanLog()
      this.fetchPackages()
      this.fetchSubscriptionDetail()
    },
    onBeforeClose(){
      this.selected.reset()
      this.response.reset()
      this.api.packages.reset()
      this.api.update_plan.reset()
      this.api.subscription_detail.reset()
      this.conditions.reset()
    },
    fetchPackages(){
      let vm = this
      if(vm.api.packages.send) return;
      vm.api.packages.send=true
      VOIP_API.endpoints.billing.packages({
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data: { data: packages } }) => {
        vm.response.packages = packages ?? [];
      })
      .finally(() => {
        vm.api.packages.send = false;
      });
    },
    fetchSubscriptionDetail(){
      let vm = this
      if(vm.api.subscription_detail.send) return;
      vm.api.subscription_detail.send = true;
      VOIP_API.endpoints.billing.getsubscriptiondetail({ 
        uid: vm.getCurrentUser?.uid,
      })
      .then(({ data }) => {
        vm.response.subscription_detail = data;
      })
      .finally(() => {
        vm.api.subscription_detail.send = false;
      });
    },
    async updatePlan(plan) {
      const { real_id } = plan
      if (!!this.api.update_plan.send || !real_id) return;
      try {
        const index = this.plans.findIndex(i=>i.real_id==real_id)
        this.api.update_plan.send = real_id;
        // has primary card
        const { data: { data: cards } } = await VOIP_API.endpoints.billing_api.getcreditcards({ 
          uid: this.getCurrentUser?.uid 
        })
        const primary_card = await cards?.find?.(item=>item.primary=='yes')
        if(!primary_card) throw new Error('Please Add Credit Card First')
        // check upgrade or downgrade
        let price = '0 GBP'
        if(index == '1') price = '5 GBP'
        else if(index == '2') price = '10 GBP'
        const currentPlanIndex = this.response.packages.findIndex(i=>i.real_id==this.currentPlanId)
        let confirm_change_plan = null
        if(index<currentPlanIndex) {
          const free_plan = plan.isFreePlan==1
          if(free_plan) {
            const { confirm } = await this.$appConfirmation({
              title: `Downgrade to ${plan?.name}`,
              message: `Are you sure you want to downgrade your plan?`,
              alert: {
                variant: 'info',
                title: 'Note',
                message: `Downgrading to the ${plan?.name} plan will cost you ${price} per user. You will have access to the features listed in the plan details.`,
              },
              button: {
                no: "No",
                yes: "Yes",
              },
            })
            confirm_change_plan=confirm
            const { confirm: confirm_free_number_remove } = await this.$appConfirmation({
              title: `Warning`,
              message: `Are you sure you want to remove all your free numbers`,
              alert: {
                variant: 'info',
                title: 'Note',
                message: `if you have free numbers on current plan it should be deleted on free plan`,
              },
              button: {
                no: "No",
                yes: "Yes",
              },
            })
            confirm_change_plan=confirm_free_number_remove
          } else {
            const { confirm } = await this.$appConfirmation({
              title: `Downgrade to ${plan?.name}`,
              message: `Are you sure you want to downgrade your plan?`,
              alert: {
                variant: 'info',
                title: 'Note',
                message: `Downgrading to the ${plan?.name} plan will cost you ${price} per user. This charge will be prorated based on your billing cycle, and you will have access to the features listed in the plan details.`,
              },
              button: {
                no: "No",
                yes: "Yes",
              },
            })
            confirm_change_plan=confirm
          }
        } else {
          const { confirm } = await this.$appConfirmation({
            title: `Upgrade to ${plan?.name}`,
            message: `Are you sure you want to upgrade your plan?`,
            alert: {
              variant: 'info',
              title: 'Note',
              message: `Upgrading to the ${plan?.name} plan will cost you ${price} per user. This charge will be prorated based on your billing cycle, and you will have access to the enhanced features listed in the plan details.`,
            },
            button: {
              no: "No",
              yes: "Yes",
            },
          })
          confirm_change_plan=confirm
        }
        if(!confirm_change_plan) return;
        // skip trail
        const { data: trial } = await VOIP_API.endpoints.billing.checkTrail({
          uid: this.getCurrentUser?.uid
        })
        // console.log(trial.is_trial)
        let skip_trial = '0'
        if(trial?.is_trial==0){
          const { confirm: confirm_skip_trial } = await this.$appConfirmation({
            title: "Skip trial",
            message: `Are you sure you want to skip the trial and upgrade to the ${plan?.name} plan directly?`,
            alert: {
              variant: 'info',
              title: 'Note',
              message: `By confirming, your plan will be upgraded to paid plan immediately.`,
            },
            button: {
              no: "No",
              yes: "Yes",
            },
          })
          if(confirm_skip_trial==null) return;
          else if(confirm_skip_trial) skip_trial='1'
        }
        // cancel trail
        // if(this.isTrailPlan) {
        //   const { confirm } = await this.$appConfirmation({
        //     title: `Cancel trial`,
        //     message: `Are you sure you want to cancel your trial plan`,
        //     alert: {
        //       variant: 'info',
        //       title: 'Note',
        //       message: `You are in trial plan so if you want to change plan so you need to cancel the trial plan.`,
        //     },
        //     button: {
        //       no: "No",
        //       yes: "Yes",
        //     },
        //   })
        //   if(!confirm) return;
        //   await VOIP_API.endpoints.billing.cancelTrail({
        //     uid: this.getCurrentUser.uid,
        //     accountcode: this.getCurrentUser.account,
        //   })
        // }
        await VOIP_API.endpoints.billing.updateplan({
          new_plan_id: real_id,
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
          default_account: this.getCurrentUser?.default_accountcode,
          skip_trial,
        },{
          change_plan_api: true,
        })
        await $fn.sleep(3*1000)
        this.fetchPackages()
        this.fetchSubscriptionDetail()
        this.appFetchSubscriptionDetail()
        this.getChangePlanLog()
        this.getFeatures()
        this.$emit('update-plan')
      } catch (ex) {
        const change_plan_api = ex?.config?.change_plan_api
        if(change_plan_api){
          const resources_mismatch = ex?.response?.data?.data?.resources_mismatch=='yes'
          if(resources_mismatch){
            const comparisons = Object.values(ex?.response?.data?.data?.comparison ?? {})
            // console.log(comparisons)
            this.$modal.show('UpdatePlanComparisonsList',{
              comparisons: comparisons
            })
            return;
          }
        }
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        this.api.update_plan.send = '';
      }
    },
    onNotification(message) {
      let vm = this
      if (message.data.action == "add" && message.data.section == "plan") {
        this.$modal.hide(this.modalName);
        this.fetchPackages()
        this.fetchSubscriptionDetail()
        this.appFetchSubscriptionDetail()
        this.getFeatures()
      }
      if (message.data.flag=="PLAN_CHANGED") {
        $fn.sleep(3 * 1000)
        .then(()=>{
          vm.fetchPackages()
          vm.fetchSubscriptionDetail()
          vm.appFetchSubscriptionDetail()
          vm.getFeatures()
        })
      }
    },
  },
  created() {
    AMI_SOCKET.on(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.on(centrifugo.notification.events.message,this.onNotification)
    // centrifugo.mothership.on(centrifugo.mothership.events.message,this.onNotification)
  },
  beforeDestroy() {
    AMI_SOCKET.off(AMI_EVENTS.broadcast,this.onNotification)
    // centrifugo.notification.off(centrifugo.notification.events.message,this.onNotification)
    // centrifugo.mothership.off(centrifugo.mothership.events.message,this.onNotification)
  },
};
</script>

<style>
</style>