var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 m-w-500 UpdateInvoiceAddress max-height-100vh min-width-50percent-modal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "w-100"
  }, [_c('div', {
    staticClass: "dialer-edit-header pt-0"
  }, [_c('h2', {
    staticClass: "dialer-edit-title newer mb-0 d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newBackButton mr-16px",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Update invoice address ")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.update.send
    },
    on: {
      "click": function ($event) {
        return _vm.updatedetail();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.update.send ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]), _vm.api.account_detail.send ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "w-100 d-flex flex-column"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "d-flex mt-20px"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-50 pr-2"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "d-flex flex-column w-50 pl-2"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })])]), _c('div', {
    staticClass: "w-100 d-flex flex-column mt-20px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "d-flex mt-20px"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-50 pr-2"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "d-flex flex-column w-50 pl-2"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })])]), _c('div', {
    staticClass: "w-100 d-flex flex-column mt-20px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })]), _c('div', {
    staticClass: "w-100 d-flex flex-column mt-20px"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mb-2",
    staticStyle: {
      "height": "21px",
      "width": "200px"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign",
    staticStyle: {
      "height": "50px",
      "width": "100%"
    }
  })])]) : _c('form', {
    staticClass: "dialer-form mt-20px",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.updatedetail();
      }
    }
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', [_vm._v("Company name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.detail.companyname,
      expression: "forms.detail.companyname"
    }],
    staticClass: "w-100",
    attrs: {
      "name": "company",
      "label": "Company name",
      "disabled": _vm.api.update.send
    },
    domProps: {
      "value": _vm.forms.detail.companyname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.detail, "companyname", $event.target.value);
      }
    }
  }), _vm.forms.detail.submitted && _vm.$v.forms.detail.companyname.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.detail.companyname.required ? _c('span', [_vm._v("* Company is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "d-flex mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 pr-2"
  }, [_c('label', [_vm._v("First name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.detail.firstname,
      expression: "forms.detail.firstname"
    }],
    staticClass: "w-100",
    attrs: {
      "name": "first_name",
      "label": "First name",
      "disabled": _vm.api.update.send
    },
    domProps: {
      "value": _vm.forms.detail.firstname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.detail, "firstname", $event.target.value);
      }
    }
  }), _vm.forms.detail.submitted && _vm.$v.forms.detail.firstname.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.detail.firstname.required ? _c('span', [_vm._v("* Firstname is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 pl-2"
  }, [_c('label', [_vm._v("Last name")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.detail.lastname,
      expression: "forms.detail.lastname"
    }],
    staticClass: "w-100",
    attrs: {
      "name": "last_name",
      "label": "Last name",
      "disabled": _vm.api.update.send
    },
    domProps: {
      "value": _vm.forms.detail.lastname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.detail, "lastname", $event.target.value);
      }
    }
  }), _vm.forms.detail.submitted && _vm.$v.forms.detail.lastname.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.detail.lastname.required ? _c('span', [_vm._v("* Lastname is required")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100"
  }, [_c('label', [_vm._v("Address")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.detail.address,
      expression: "forms.detail.address"
    }],
    staticClass: "w-100",
    attrs: {
      "name": "line1",
      "label": "Address",
      "disabled": _vm.api.update.send
    },
    domProps: {
      "value": _vm.forms.detail.address
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.detail, "address", $event.target.value);
      }
    }
  }), _vm.forms.detail.submitted && _vm.$v.forms.detail.address.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.detail.address.required ? _c('span', [_vm._v("* Address is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "d-flex mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 pr-2"
  }, [_c('label', [_vm._v("Town / City")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.detail.city,
      expression: "forms.detail.city"
    }],
    staticClass: "w-100",
    attrs: {
      "name": "town",
      "label": "Town / City",
      "disabled": _vm.api.update.send
    },
    domProps: {
      "value": _vm.forms.detail.city
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.detail, "city", $event.target.value);
      }
    }
  }), _vm.forms.detail.submitted && _vm.$v.forms.detail.city.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.detail.city.required ? _c('span', [_vm._v("* City is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-50 pl-2"
  }, [_c('label', [_vm._v("Postcode")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.detail.postcode,
      expression: "forms.detail.postcode"
    }],
    staticClass: "w-100",
    attrs: {
      "name": "postcode",
      "label": "Postcode",
      "disabled": _vm.api.update.send
    },
    domProps: {
      "value": _vm.forms.detail.postcode
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.detail, "postcode", $event.target.value);
      }
    }
  }), _vm.forms.detail.submitted && _vm.$v.forms.detail.postcode.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.detail.postcode.required ? _c('span', [_vm._v("* postcode is required")]) : _vm._e()]) : _vm._e()])]), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion mt-20px w-100"
  }, [_c('label', [_vm._v("Country")]), _c('b-form-select', {
    staticClass: "dialer-select",
    attrs: {
      "disabled": _vm.api.update.send,
      "options": _vm.countryOptions
    },
    model: {
      value: _vm.forms.detail.country,
      callback: function ($$v) {
        _vm.$set(_vm.forms.detail, "country", $$v);
      },
      expression: "forms.detail.country"
    }
  }), _vm.forms.detail.submitted && _vm.$v.forms.detail.country.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.detail.country.required ? _c('span', [_vm._v("* Country is required")]) : _vm._e()]) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder mt-20px w-100"
  }, [_c('label', [_vm._v("Email")]), _c('input', {
    staticClass: "w-100",
    attrs: {
      "name": "email",
      "label": "Email",
      "disabled": ""
    },
    domProps: {
      "value": _vm.forms.detail.email
    }
  })])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }