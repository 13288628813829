<template>
  <modal 
    class="dialer_animation right_side_popup m-w-900" 
    width="50%" 
    height="auto" 
    :scrollable="true" 
    :name="modalName" 
    @before-open="onBeforeOpen($event)" 
    @before-close="onBeforeClose()"
  >
    <section class="dialer-settings-section pickupGroups">
      <div class="dialer-flex">
        <h2 class="dialer-settings-title newer d-flex align-items-center">
          <!-- <vb-svg @click="$modal.hide(modalName)" class="cursor_pointer_no_hover" name="leftLongArrow-icon" width="20" height="12" viewBox="0 0 30 16" stroke-width="1" stroke="" fill="none" stroke-linecap="round" stroke-linejoin="round" /> -->
          <button class="newBackButton mr-16px" @click="$modal.hide(modalName)">
            <b-icon icon="arrow-left-circle" />
            <span class="newBackButton-textpart">Back</span>
          </button>
          Custom pickup groups
        </h2>
        <div class="d-flex">
          <!-- <button class="newCloseButton mr-2" @click="$modal.hide(modalName)">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button> -->
          <div 
            v-b-tooltip :title="getUserPermissions.call_pickup?'':'Your package does not support this feature'"
            :class="{
              'for-disable': !getUserPermissions.call_pickup
            }"
          >
            <button :disabled="!getUserPermissions.call_pickup" class="newButton" @click="$modal.show('AddPickUpGroupModal')">
              <vb-icon icon="squared-add-icon" height="38px" width="38px" />
              <span class="newButton-textPart">Add new</span>
            </button>
          </div>
        </div>
      </div>
      <div class="d-flex align-items-center justify-content-between md-mar-top md-mar-bottom">
        <span>Manage all your pickup groups.</span>
        <div class="d-flex justify-content-end align-items-center w-50">
          <div 
            class="whiteBGinputWithGreyRoundedBorder sm-mar-left seachBox"
            :class="{
              'for-disable': !getUserPermissions.call_pickup
            }" 
            v-b-tooltip :title="getUserPermissions.call_pickup?'':'Your package does not support this feature'"
          >
            <b-input :disabled="!getUserPermissions.call_pickup" type="text" placeholder="Search pickup groups" v-model.lazy="filter.pickup.search" />
          </div>
        </div>
      </div>
      <vb-table 
        class="latestTableDesign-withBlackBorders-again" 
        :isListEmpty="pickupGroups.length==0" 
        :listLength="pickupGroups.length" 
        :loading="api.fetch_pickup_groups.send" 
        :noRecordWholePage="true"
      >
        <template #header>
          <tr class="cursor_auto" v-if="!$store.getters.getIsMobile">
            <th class="dialer-has-sort cursor_auto">
              <span class="dialer-sorted-by ascending dialer-has-sort">Label</span>
            </th>
            <th class="dialer-has-sort cursor_auto">
              <span class="dialer-sorted-by ascending dialer-has-sort">Members</span>
            </th>
            <th class="dialer-has-sort dialer-col-right" style="max-width: 115px;width: 115px;"></th>
          </tr>
        </template>
        <template #body="{ start, end }">
          <template v-if="!$store.getters.getIsMobile">
            <tr 
              v-for="(pickupgroup, index) in pickupGroups" 
              :key="pickupgroup.id" 
              v-show="index >= start && index <= end" 
              class="dialer-row-select" 
              @click="$modal.show('EditPickUpGroupModal',{
                pickupgroup: pickupgroup
              })"
            >
              <td class="dialer-row-title w-50">{{ pickupgroup.name }}</td>
              <td class="dialer-row-title three w-25">
                <a class="dialer-link-more text-decoration-underline">
                  {{Object.keys(pickupgroup.members).length>1?Object.keys(pickupgroup.members).length:'Add/remove'}} users
                </a>
              </td>
              <td class="dialer-row-title dialer-col-right" style="max-width: 115px;width: 115px;">
                <span class="newThisDeviceIndicaterTextBox-25-11-2023 h-fit-content w-fit-content ml-0 mr-0 showOnly-OnHover-insideTable" > 
                  Configure
                </span>
              </td>
            </tr>
          </template>
          <template v-else>
            <tr 
              v-for="(pickupgroup, index) in pickupGroups" 
              :key="pickupgroup.id" 
              v-show="index >= start && index <= end" 
              class="dialer-row-select" 
              @click="$modal.show('EditPickUpGroupModal',{
                pickupgroup: pickupgroup
              })"
            >
              <td class="left">
                <div class="headingInsideTable">{{ pickupgroup.name }}</div>
              </td>
              <!-- <td class="dialer-row-title three w-25">
                <a class="dialer-link-more text-decoration-underline">
                  {{Object.keys(pickupgroup.members).length>1?Object.keys(pickupgroup.members).length:'Add/remove'}} users
                </a>
              </td> -->
              <td class="right dialer-col-right">
                <div class="textInsideCard text-decoration-underline">
                  {{Object.keys(pickupgroup.members).length>1?Object.keys(pickupgroup.members).length:'Add/remove'}} users
                </div>
              </td>
            </tr>
          </template>
        </template>
        <template #loading>
          <tr v-for="n in 5" :key="n">
            <td>
              <div class="d-flex align-items-center w-fit-content">
                <div class="latestShimmerDesign mr-2" style="height:21px;width:180px;"></div>
              </div>
            </td>
            <td>
              <div class="d-flex align-items-center w-fit-content">
                <div class="latestShimmerDesign mr-2" style="height:21px;width:180px;"></div>
              </div>
            </td>
          </tr>
        </template>
        <template #no-record>
          <div class="latestGreyBox-9-9-2023 mb-32px">
            <div class="emptyScreenContainer">
              <img class="mt-12px wd-30 h-auto" :src="require('@/assets/images/emptyScreenImages/pickupGroups.png')"/>
              <div class="emptyScreenContainer-heading mt-32px">Add a new pickup group</div>
              <div class="emptyScreenContainer-text w-75">Easily create and manage pickup groups for efficient call routing</div>
              <div 
                v-b-tooltip :title="getUserPermissions.call_pickup?'':'Your package does not support this feature'"
                :class="{
                  'for-disable': !getUserPermissions.call_pickup
                }"
              >
                <!-- <button 
                  class="dialer-button dialer-button-primary mt-20px" 
                  :disabled="!getUserPermissions.call_pickup" 
                  @click="$modal.show('AddPickUpGroupModal')"
                >
                  Add pickup group
                </button> -->
                <button :disabled="!getUserPermissions.call_pickup" class="newButton mt-20px" @click="$modal.show('AddPickUpGroupModal')">
                  <vb-icon icon="squared-add-icon" height="38px" width="38px" />
                  <span class="newButton-textPart">Add pickup group</span>
                </button>
              </div>
            </div>
          </div>
        </template>
      </vb-table>
      <AddPickUpGroup @load="fetchPickupGroups()" />
      <EditPickUpGroupModal @load="fetchPickupGroups()" />
    </section>
  </modal>
</template>

<script>
import AddPickUpGroup from "./settings/AddPickUpGroup.vue";
import EditPickUpGroupModal from "./settings/EditPickUpGroupModal.vue";
import { VOIP_API } from "../../utils";
import { mapGetters } from 'vuex';
export default {
  name: 'PickupGroupsModal',
  components: {
    AddPickUpGroup,
    EditPickUpGroupModal,
  },
  inject: ['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'PickupGroupsModal',
    },
    teamPickupGroup: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      response: {
        pickup_groups: [],
      },
      api: {
        fetch_pickup_groups: this.$helperFunction.apiInstance({}),
        delete_pickup_groups: this.$helperFunction.apiInstance({
          send: []
        }),
      },
      filter: {
        pickup: {
          search: '',
        },
      },
    };
  },
  computed: {
    ...mapGetters([
      'getUserPermissions',
      'getCurrentUser'
    ]),
    pickupGroups(){
      const search = this.filter.pickup.search.trim().toLowerCase()
      return this.response.pickup_groups?.filter?.(i=>this.teamPickupGroup ? i.team=='1' : i.team!='1')?.filter?.(i=>`${i.name}`.trim().toLowerCase().includes(search)) ?? []
    },
  },
  methods: {
    onBeforeOpen(){
      this.fetchPickupGroups();
    },
    onBeforeClose(){
      this.response.pickup_groups=[]
      this.api.fetch_pickup_groups.reset()
      this.api.delete_pickup_groups.reset()
      this.filter.pickup.search=''
    },
    async fetchPickupGroups() {
      if(this.api.fetch_pickup_groups.send) return;
      try {
        this.api.fetch_pickup_groups.send = true;
        const { data } = await VOIP_API.endpoints.pickupgroup.list({
          uid: this.getCurrentUser.uid,
        })
        this.response.pickup_groups = data?.data ?? [];
      } finally {
        this.api.fetch_pickup_groups.send = false;
      }
    },
    async deletePickGroup(pickupgroup){
      if(this.api.delete_pickup_groups.send.includes(pickupgroup.id)) return;
      try {
        const { confirm } = await this.$appConfirmation({
          title: "warning",
          message: `Are you sure you want to delete this ${pickupgroup.name} Pickup group`,
          variant: 'danger',
          button: {
            no: "No",
            yes: "Yes",
          },
        })
        if(!confirm) return;
        this.api.delete_pickup_groups.send.push(pickupgroup.id)
        await VOIP_API.endpoints.pickupgroup.delete(pickupgroup.id)
        this.response.pickupgroups = await this.response.pickupgroups.filter(item=>item.id!=pickupgroup.id)
        this.appNotify({
          message: `Successfully Deleted ${pickupgroup.name} Pickup Group`,
          type: 'success',
        })
      } catch (ex) {
        this.appNotify({
          message: ex.own_message || ex.message,
          type: 'danger',
        })
      } finally {
        if(this.api.delete_pickup_groups.send.includes(pickupgroup.id)){
          this.api.delete_pickup_groups.send.splice(this.api.delete_pickup_groups.send.indexOf(pickupgroup.id),1)
        }
      }
    },
  },
}
</script>

<style>

</style>