import { MOTHERSHIP } from '@/mothership/index';
import { DEVICE_INFO } from './deviceInfo';

var Emitter = require('component-emitter');
const events = {
  change_camera_permission: 'change_hamera_permission',
  change_mic_permission: 'change_mic_permission',
  change_notification_permission: 'change_notification_permission',
}
export const permissions = {
  mic: '',
  notification: '',
  camera: '',
  onmic: null,
  onmic1: null,
  oncamera: null,
  onnotification: null,
  get events(){ return events },
  init(){
    try {
      Emitter(this)
      this.getmicrophone()
      this.getnotification()
      this.getcamera()
      MOTHERSHIP.on(MOTHERSHIP.events.receive.DESKTOP_PERMISSION_CHANGE,this.onnotificationchange.bind(this))
      window.permissions=this
    } finally {
      // eslint-disable-next-line no-unsafe-finally
      return this
    }
  },
  setMicPermission(permission){
    const prev_permission = this.mic
    if(prev_permission!=permission) {
      this.mic=permission
      if(prev_permission!='') {
        this.emit(events.change_mic_permission,permission)
        this.onmic?.(permission)
        this.onmic1?.(permission)
      }
    }
  },
  getmicrophone(){
    let that = this
    navigator?.permissions?.query?.({
      name: "microphone",
    }).then((permission)=>{
      that.setMicPermission(permission.state)
      permission.onchange = that.onmicrophonechange.bind(that);
    });
  },
  onmicrophonechange(Event){
    this.setMicPermission(Event.currentTarget.state)
  },
  async getMicPermission(){
    let that = this
    if(this.mic=='denied') throw new Error('permissions is blocked')
    if(this.mic=='granted') throw new Error('already have permission')
    const stream = await navigator.mediaDevices.getUserMedia({
      audio: true,
    })
    stream.getTracks().forEach((track) => track.stop());
    if(DEVICE_INFO.os.mac) {
      navigator?.permissions?.query?.({
        name: "microphone",
      }).then((permission)=>{
        that.setMicPermission(permission.state)
      });
    }
  },
  setNotificationPermission(permission){
    const prev_permission = this.notification
    if(prev_permission!=permission) {
      this.notification=permission
      if(prev_permission!='') {
        this.emit(events.change_notification_permission,permission)
        this.onnotification?.(permission)
      }
    }
  },
  getnotification(){
    let that = this
    if(window.self !== window.top){
      MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.CHECK_DESKTOP_NOTIFICATION_PERMISSION)
    } else {
      navigator?.permissions?.query?.({
        name: "notifications",
      }).then((permission)=>{
        that.setNotificationPermission(permission.state)
        permission.onchange = that.onnotificationchange.bind(that);
      });
    }
  },
  onnotificationchange(Event){
    if(window.self !== window.top){
      this.setNotificationPermission(Event.permission);
    } else {
      this.setNotificationPermission(Event.currentTarget.state);
    }
  },
  getNotificationPermission(){
    let that = this
    if(this.notification=='denied') throw new Error('permissions is blocked')
    if(this.notification=='granted') throw new Error('already have permission')
    if(window.self !== window.top) {
      MOTHERSHIP?.sendData?.(MOTHERSHIP.events.send.GET_DESKTOP_NOTIFICATION_PERMISSION)
    } else {
      if(!("Notification" in window)) throw new Error('notification object are not in window')
      Notification.requestPermission();
      if(DEVICE_INFO.os.mac) {
        navigator?.permissions?.query?.({
          name: "notifications",
        }).then(permission=>{
          that.setNotificationPermission(permission.state)
        });
      }
    }
  },
  setCameraPermission(permission){
    const prev_permission = this.camera
    if(prev_permission!=permission) {
      this.camera=permission
      if(prev_permission!='') {
        this.emit(events.change_camera_permission,permission)
        this.oncamera?.(permission)
      }
    }
  },
  getcamera(){
    let that = this
    navigator?.permissions?.query?.({
      name: "camera",
    }).then((permission)=>{
      that.setCameraPermission(permission.state)
      permission.onchange = that.oncamerachange.bind(that);
    });
  },
  oncamerachange(Event){
    this.setCameraPermission(Event.currentTarget.state);
  },
  async getCameraPermission(){
    let that = this
    if(this.camera=='denied') throw new Error('permissions is blocked')
    if(this.camera=='granted') throw new Error('already have permission')
    const stream = await navigator.mediaDevices.getUserMedia({
      video: true,
    })
    stream.getTracks().forEach((track) => track.stop());
    if(DEVICE_INFO.os.mac) {
      navigator?.permissions?.query?.({
        name: "camera",
      }).then(permission=>{
        that.setCameraPermission(permission.state);
      });
    }
  },

  
  // location: '',
  // onlocation: null,
  // async getlocation(){
  //   let that = this
  //   navigator?.permissions?.query?.({
  //     name: "geolocation",
  //   }).then((permission)=>{
  //     that.location=permission.state
  //     permission.onchange = that.onlocationchange.bind(that);
  //   });
  // },
  // onlocationchange(Event){
  //   this.location = Event.currentTarget.state;
  //   this.onlocation?.(this.location)
  // },
  // async getLocationPermission(){
  //   if(this.location=='denied') throw new Error('permissions is blocked')
  //   if(this.location=='granted') throw new Error('already have permission')
  //   return new Promise((resolve,reject)=>navigator.geolocation.getCurrentPosition((result)=>{resolve(result)},(err)=>{reject(err)},{}))
  // },
}