var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "content-area d-flex h-100 dialer_main_body"
  }, [_c('div', {
    staticClass: "w-100 dialer_main_body_inner QuickStartPage flex-column"
  }, [_c('div', {
    staticClass: "leftSide w-100"
  }, [_c('div', {
    staticClass: "title-container"
  }, [_c('div', {
    staticClass: "title-mainText"
  }, [_vm._v("Hi " + _vm._s(_vm.getCurrentUser.display_name) + "!")])]), _c('div', {
    staticClass: "body-container"
  }, [_c('div', {
    staticClass: "inner-body-container"
  }, [_c('div', {
    staticClass: "getStarted-heading"
  }, [_vm._v("Get started")]), _vm.getCurrentUser.administrator_account ? [_c('div', {
    class: `box-grey IOS-like first-child ${_vm.steps >= 1 ? 'completed' : ''}`
  }, [_c('div', {
    staticClass: "box-row"
  }, [_c('div', {
    staticClass: "box-item",
    on: {
      "click": function ($event) {
        _vm.steps == 0 ? _vm.$modal.show('AddUserByUserNameFromQuickStart') : '';
      }
    }
  }, [_vm.steps >= 1 ? _c('vb-icon', {
    attrs: {
      "icon": "stepCompleted-QuickStartIcon"
    }
  }) : _c('vb-icon', {
    attrs: {
      "icon": "firstUser-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "others-container"
  }, [_vm._m(0), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)], 1)])]), _c('div', {
    class: `box-grey IOS-like ${_vm.steps >= 2 ? 'completed' : ''}`
  }, [_c('div', {
    staticClass: "box-row"
  }, [_c('div', {
    staticClass: "box-item",
    on: {
      "click": function ($event) {
        _vm.steps == 1 ? _vm.$modal.show('AddNumbersFromQuickStart') : '';
      }
    }
  }, [_vm.steps >= 2 ? _c('vb-icon', {
    attrs: {
      "icon": "stepCompleted-QuickStartIcon"
    }
  }) : _c('vb-icon', {
    attrs: {
      "icon": "firstNumber-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "others-container"
  }, [_vm._m(1), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)], 1)])]), _c('div', {
    class: `box-grey IOS-like ${_vm.steps >= 3 ? 'completed' : ''}`
  }, [_c('div', {
    staticClass: "box-row"
  }, [_c('div', {
    staticClass: "box-item",
    on: {
      "click": function ($event) {
        _vm.steps == 2 ? _vm.$modal.show('AddTeamFromQuickStart', {
          suggested_name: `team ${_vm.voip_teams.length + 1}`
        }) : '';
      }
    }
  }, [_vm.steps >= 3 ? _c('vb-icon', {
    attrs: {
      "icon": "stepCompleted-QuickStartIcon"
    }
  }) : _c('vb-icon', {
    attrs: {
      "icon": "firstTeam-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "others-container"
  }, [_vm._m(2), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)], 1)])]), _c('div', {
    staticClass: "box-grey IOS-like"
  }, [_c('div', {
    staticClass: "box-row"
  }, [_c('div', {
    staticClass: "box-item",
    on: {
      "click": function ($event) {
        _vm.steps == 3 ? '' : '';
      }
    }
  }, [_vm.steps >= 4 ? _c('vb-icon', {
    attrs: {
      "icon": "stepCompleted-QuickStartIcon"
    }
  }) : _c('vb-icon', {
    attrs: {
      "icon": "AddTeamMembers-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "others-container"
  }, [_vm._m(3), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)], 1)])])] : _vm._e()], 2)])]), _c('div', {
    staticClass: "rightSide w-100"
  }, [_vm._m(4), _c('div', {
    staticClass: "body-container"
  }, [_c('div', {
    staticClass: "inner-body-container"
  }, [_c('div', {
    staticClass: "subTitle"
  }, [_vm._v("Downloads")]), _c('div', {
    staticClass: "subText"
  }, [_vm._v("Get anything from all of our available resources.")]), _c('div', {
    staticClass: "box-grey firstBox"
  }, [_c('div', {
    staticClass: "box-row"
  }, [_c('div', {
    staticClass: "box-item"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "android-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "box-itemTextMain"
  }, [_vm._v("Android")])], 1), _c('div', {
    staticClass: "box-item"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "IOS-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "box-itemTextMain"
  }, [_vm._v("IOS")])], 1)]), _c('div', {
    staticClass: "box-row"
  }, [_c('div', {
    staticClass: "box-item"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "chrome-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "box-itemTextMain"
  }, [_vm._v("Chrome")])], 1), _c('div', {
    staticClass: "box-item"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "edge-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "box-itemTextMain"
  }, [_vm._v("Edge")])], 1)]), _c('div', {
    staticClass: "box-row"
  }, [_c('div', {
    staticClass: "box-item"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "windows-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "box-itemTextMain"
  }, [_vm._v("Windows")])], 1), _c('div', {
    staticClass: "box-item"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "macOS-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "box-itemTextMain"
  }, [_vm._v("MacOS")])], 1)])]), _c('div', {
    staticClass: "subTitle"
  }, [_vm._v("Need some help?")]), _c('div', {
    staticClass: "subText"
  }, [_vm._v("Check out different options for support.")]), _c('div', {
    staticClass: "box-grey IOS-like"
  }, [_c('div', {
    staticClass: "box-row"
  }, [_c('div', {
    staticClass: "box-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb_tab: true
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "KnowledgeBase-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "others-container"
  }, [_vm._m(5), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "box-row"
  }, [_c('div', {
    staticClass: "box-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddSupportTicketFromQuickStart', {
          call_me: true
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "RequestCall-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "others-container"
  }, [_vm._m(6), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)], 1)]), _c('div', {
    staticClass: "box-row"
  }, [_c('div', {
    staticClass: "box-item",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddSupportTicketFromQuickStart');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "CreateSupportTicket-QuickStartIcon"
    }
  }), _c('div', {
    staticClass: "others-container"
  }, [_vm._m(7), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)], 1)])])])])])]), _c('AddSupportTicketModal', {
    attrs: {
      "modalName": 'AddSupportTicketFromQuickStart'
    }
  }), _c('AddUserByUserName', {
    attrs: {
      "modalName": `AddUserByUserNameFromQuickStart`
    },
    on: {
      "user-added": function ($event) {
        return _vm.fetchUsers();
      }
    }
  }), _c('AddNumbersModal', {
    attrs: {
      "modalName": `AddNumbersFromQuickStart`
    },
    on: {
      "interface": function ($event) {
        return _vm.fetchNumbers();
      }
    }
  }), _c('AddTeamModal', {
    attrs: {
      "modalName": `AddTeamFromQuickStart`
    },
    on: {
      "interface": function ($event) {
        return _vm.fetchTeams();
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bothText-container"
  }, [_c('div', {
    staticClass: "box-itemTextMain bold"
  }, [_vm._v("Create your first user ")]), _c('div', {
    staticClass: "box-itemTextSub"
  }, [_vm._v("Create a classic or IVR number. or port existing numbers")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bothText-container"
  }, [_c('div', {
    staticClass: "box-itemTextMain bold"
  }, [_vm._v("Create the first number of your company")]), _c('div', {
    staticClass: "box-itemTextSub"
  }, [_vm._v("Create a classic or IVR number. or port existing numbers")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bothText-container"
  }, [_c('div', {
    staticClass: "box-itemTextMain bold"
  }, [_vm._v("Create a team")]), _c('div', {
    staticClass: "box-itemTextSub"
  }, [_vm._v("Create teams and add them to numbers")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bothText-container"
  }, [_c('div', {
    staticClass: "box-itemTextMain bold"
  }, [_vm._v("Add team members")]), _c('div', {
    staticClass: "box-itemTextSub"
  }, [_vm._v("Invite your team members, add them to teams and numbers")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "title-container"
  }, [_c('div', {
    staticClass: "title-mainText"
  }, [_vm._v("Finding something?")]), _c('div', {
    staticClass: "title-subText"
  }, [_vm._v("Don't worry we got it all covered for you.")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bothText-container"
  }, [_c('div', {
    staticClass: "box-itemTextMain"
  }, [_vm._v("Knowledge base")]), _c('div', {
    staticClass: "box-itemTextSub"
  }, [_vm._v("Guides from our team")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bothText-container"
  }, [_c('div', {
    staticClass: "box-itemTextMain"
  }, [_vm._v("Request a call")]), _c('div', {
    staticClass: "box-itemTextSub"
  }, [_vm._v("Guides from our team")])]);
} /* staticRenderFnsSpliter */, function () {
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "bothText-container"
  }, [_c('div', {
    staticClass: "box-itemTextMain"
  }, [_vm._v("Create a support ticket")]), _c('div', {
    staticClass: "box-itemTextSub"
  }, [_vm._v("Guides from our team")])]);

}]

export { render, staticRenderFns }