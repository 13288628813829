import { VOIP_API } from "../../index"


export const CARDS_CRM_API_ENDPOINTS = {
  dealsByStage(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: 'cards/deals-by-stage',
      method: 'GET',
      params,
      cancelToken,
    })
  },
  wonDealsByDay(params={},cancelToken=null){
    return VOIP_API.axios.crm.request({
      url: 'cards/won-deals-by-day',
      method: 'GET',
      params,
      cancelToken,
    })
  },
}