<template>
  <div :id="`color-picker-${random_id}`" class="dialer-input-field colorPicker-section">
    <label>{{ title }}</label>
    <div class="position-relative w-100">
      <input type="text" :value="value" :disabled="true" @input="onChange($event.target.value)" :placeholder="placeHolder" />
      <span class="color-circle">
        <span
          @click="show = !show"
          class="color-swatch"
          :style="`background-color:${value || '#000'};`"
        />
      </span>
      <color-picker
        :disabled="disabled"
        theme="light"
        v-if="show"
        :color="value || '#000'"
        @changeColor="onChange(`rgba(${$event.rgba.r},${$event.rgba.g},${$event.rgba.b},${$event.rgba.a})`)"
      />
    </div>
    <slot name="error"></slot>
  </div>
</template>

<script>
import { $fn } from '../../utils';
import colorPicker from "@caohenghu/vue-colorpicker";
export default {
  name: 'vb-color-picker',
  components: {
    colorPicker,
  },
  props: {
    title: {
      type: String,
      default: 'Pick a color',
    },
    placeHolder: {
      type: String,
      default: 'Enter Color',
    },
    value: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data(){
    return {
      random_id: '',
      show: false,
    }
  },
  methods: {
    onChange(value){
      this.$emit('change',value)
    },
    onClickListener(Event) {
      this.show = $fn.pointerEventPath(Event)?.reverse?.()?.some?.(i=>i.id == `color-picker-${this.random_id}`) ? this.show : false
    },
  },
  created() {
    document.addEventListener("click", this.onClickListener);
  },
  mounted(){
    this.random_id = $fn.makeid(10)
  },
  beforeDestroy() {
    document.removeEventListener("click", this.onClickListener);
  },
};
</script>

<style>
</style>