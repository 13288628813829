<template>
  <div class="dialer-analytics-main dialer-analytics-reports">
    <template v-if="conditions.screen==screens.select_call_type">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.screen = screens.filter" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Call Type</div>
      </div>
      <div class="dialer-box AnalyticsContainerInner">
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div 
            class="basicWhiteIOScard-item" 
            v-for="(type, index) in response.types" 
            :key="index" 
            @click="filters.reports.type == type.value ? filters.reports.type = '' : filters.reports.type = type.value; conditions.screen = screens.filter;"
          >
            <div class="headingInsideTable">{{ type.label }}</div>
            
            <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="filters.reports.type == type.value" width="16.642" height="15.275" />
          </div>
          <vb-no-record v-if="response.types.length==0" :text="api.filters.send?'':'There is no Number'" :design="3">
            <vb-loading v-if="api.filters.send" slot="loading" />
          </vb-no-record>
        </div>
      </div>
    </template>
    <template v-else-if="conditions.screen==screens.select_extension">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.screen = screens.filter" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Extension</div>
      </div>
      <div class="dialer-box AnalyticsContainerInner">
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-form-input placeholder="Search" v-model="filters.extensions.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div 
            class="basicWhiteIOScard-item" 
            v-for="(extn, index) in filterExtentions" 
            :key="index" 
            @click="
              filters.reports.agents.findIndex(r=>r.value==extn.value) > -1 
              ? 
              filters.reports.agents.splice(filters.reports.agents.findIndex(r=>r.value==extn.value),1) 
              : 
              filters.reports.agents.push(extn);
            "
          >
            <div class="headingInsideTable">{{ extn.label }}</div>
            <vb-icon v-if="filters.reports.agents.findIndex(r=>r.value==extn.value) > -1" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            
          </div>
          <vb-no-record v-if="filterExtentions.length==0" :text="api.filters.send?'':'There is no Number'" :design="3">
            <vb-loading v-if="api.filters.send" slot="loading" />
          </vb-no-record>
        </div>
      </div>
    </template>
    <template v-else-if="conditions.screen==screens.select_tags">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.screen = screens.filter" />
        </div>
        <div class="dialer-settings-title topMainHeading">Select Tags</div>
      </div>
      <div class="dialer-box AnalyticsContainerInner">
        <div class="mobileInputIOS iconOnLeft mt-32px">
          <b-form-input placeholder="Search" v-model="filters.tags.search" />
          <vb-icon width="13.785" height="13.726" class="mobileInputIOS-icon" icon="Search-IOSIcon" />
        </div>
        <div class="basicWhiteIOScard mt-32px mb-16px">
          <div 
            class="basicWhiteIOScard-item" 
            v-for="(tag, index) in filterTags" 
            :key="index" 
            @click="
              filters.reports.tags.findIndex(r=>r.value==tag.value) > -1 
              ? 
              filters.reports.tags.splice(filters.reports.tags.findIndex(r=>r.value==tag.value),1) 
              : 
              filters.reports.tags.push(tag);
            "
          >
            <div class="headingInsideTable">{{ tag.label }}</div>
            <vb-icon v-if="filters.reports.tags.findIndex(r=>r.value==tag.value) > -1" icon="MyNumbers-tick-IOSIcon" width="16.642" height="15.275" />
            
          </div>
          <vb-no-record v-if="filterTags.length==0" :text="api.filters.send?'':'There is no Number'" :design="3">
            <vb-loading v-if="api.filters.send" slot="loading" />
          </vb-no-record>
        </div>
      </div>
    </template>
    <template v-else-if="conditions.screen==screens.filter">
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" >
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="conditions.screen = screens.main" />
        </div>
        <div class="dialer-settings-title topMainHeading">Filters</div>
      </div>
      <div class="dialer-box AnalyticsContainerInner">

        <div class="basicWhiteIOScard mt-32px mb-16px">
          
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2">
              
              <label>Number</label>
              <input :disabled="api.reports.send" v-model="filters.reports.number" type="text" placeholder="Source or Destination Number" />
            </div>
          </div>
          
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS InputIosV2">
              
              <label>Date Range</label>
              <vue-ctk-date-time-picker 
                v-bind="ctkDateTimePickerOptions" 
                format="YYYY-MM-DD" 
                :disabled="api.reports.send"
                class="date-time-input mt-0" 
                :noButton="false" 
                :range="true" 
                v-model="filters.reports.date_range"  
              />
            </div>
          </div>

          <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.select_call_type">
            
            <div class="headingInsideTable">Call Type</div>
            <div class="d-flex align-items-center">
              <div class="textInsideCard onRightSide textAlignRight mr-8px">{{ filters.reports.type }}</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
          </div>

          <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.select_extension">
            
            <div class="headingInsideTable">User & Extension</div>
            <div class="d-flex align-items-center">
              <div class="textInsideCard onRightSide textAlignRight mr-8px">{{ filters.reports.agents.length }} Selected</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
          </div>

          <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.select_tags">
            
            <div class="headingInsideTable">Tags</div>
            <div class="d-flex align-items-center">
              <div class="textInsideCard onRightSide textAlignRight mr-8px">{{ filters.reports.tags.length }} Selected</div>
              <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
            </div>
          </div>

        </div>
        <a class="IosFullWidthButton forDone mb-16px" @click="api.reports.send ? '' : fetchReports(true);conditions.screen = screens.main">Result</a>
      </div>
    </template>
    <template v-else>
      <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
        <div class="settingsTextAndIconOnTop" @click="$emit('back')">
          <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="$emit('back')" />
          <div class="settingsTextOnTop">Settings</div>
        </div>
        <div class="dialer-settings-title topMainHeading">Reports</div>
      </div>
      <div class="dialer-box AnalyticsContainerInner">
        <div class="basicWhiteIOScard mt-32px mb-16px">
          
          <div class="basicWhiteIOScard-item" @click="conditions.screen=screens.filter">
            <div class="d-flex align-items-center">
              <div class="d-flex flex-column">
                <div class="headingInsideTable">Filter</div>
              </div>  
            </div>
            <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
          </div>
          
        </div>
        <div class="basicWhiteIOScard mt-16px mb-16px">
          <div>
            <div class="basicWhiteIOScard-item"  v-for="(data, key,) in response.reports" :key="key" :index="key">
              
              <div class="d-flex align-items-center">
                <div class="basicIOSIcon mr-16px">
                  <vb-icon v-if="data.status === 'Missed Call'" icon="NumberBlocking-missed-IOSIcon" height="13.922" width="13.922"/>
                  <vb-icon v-else-if="data.status === 'Received Call'" icon="NumberBlocking-incomming-IOSIcon" height="13.922" width="13.922"/>
                  <vb-icon v-else icon="NumberBlocking-outgoing-IOSIcon" height="13.922" width="13.922"/>
                </div>
                <div class="d-flex flex-column">
                  <div class="headingInsideTable">{{ data.sourceCallerId | number_formater }}</div>
                  <vb-audio-player class="IosAudioPlayer mt-8px"
                    v-if="data.soundFile" 
                    :disabled="!getUserPermissions.call_recording" 
                    :src="data.soundFile" 
                  />
                </div>
              </div>
              <div class="d-flex align-items-center">
                <div class="textInsideCard onRightSide textAlignRight mr-8px" v-if="getVoipUsersAlises[data.destinationExt]">{{ getVoipUsersAlises[data.destinationExt].extn }}</div>
                <div class="textInsideCard onRightSide textAlignRight mr-8px" v-else>{{ data.destinationName }}</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="16.642" height="15.275" />
              </div>
            </div>
          </div>
          <InfiniteLoading ref="reports_infinity_loader" @infinite="fetchReports()">
            <div slot="spinner">
              <vb-loading type="loader" />
            </div>
            <div slot="no-more"></div>
            <div slot="no-results">
              <vb-no-record :text="'There is no Numbers'" :design="3" />
            </div>
            <template #error="{ trigger }">
              <div>
                <span>{{ api.reports.error_message }}</span>
                <b-icon icon="arrow-repeat" class="ml-3" variant="primary" font-scale="1.5" @click="trigger()" />
              </div>
            </template>
          </InfiniteLoading>
        </div>
      </div>
    </template>
  </div>
</template>

<script>
import { VOIP_API } from "@/utils/index";
import { ctkDateTimePicker } from '@/mixin';
import { mapGetters } from 'vuex';
import InfiniteLoading from 'vue-infinite-loading';
let screens = {
  main: 'main',
  filter: 'filter',
  select_call_type: 'select_call_type',
  select_extension: 'select_extension',
  select_tags: 'select_tags',
}
export default {
  name: "Report",
  mixins: [ctkDateTimePicker],
  inject:['isEmpty'],
  components: {
    InfiniteLoading,
  },
  data() {
    return {
      response: this.$helperFunction.stateInstance({
        data: {
          reports: [],
          extensions: [],
          types: [],
        }
      }),
      api: {
        reports: this.$helperFunction.apiInstance({ error_message: true }),
        filters: this.$helperFunction.apiInstance({  }),
        reset(){
          this.reports.reset()
          this.filters.reset()
        },
      },
      filters: {
        reports: this.$helperFunction.stateInstance({
          data: {
            number: '',
            date_range: null,
            type: '',
            agents: [],
            tags: [],
          }
        }),
        extensions: this.$helperFunction.stateInstance({
          data: {
            search: '',
          }
        }),
        tags: this.$helperFunction.stateInstance({
          data: {
            search: '',
          }
        }),
        reset(){
          this.reports.reset()
          this.extensions.reset()
          this.tags.reset()
        },
      },
      pagination: {
        reports: this.$helperFunction.stateInstance({
          data: {
            per_page: 10,
            page: 1,
            total: 0,
          }
        }),
        reset(){
          this.reports.reset()
        },
      },
      conditions: {
        screen: screens.main,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getVoipCallTags',
      'getVoipUsersAlises',
      'getUserPermissions',
    ]),
    tagOptions(){
      return this.getVoipCallTags.map((item) => {
        return {
          label: item.tag,
          value: item.real_id,
        };
      });
    },
    filterTags(){ return this.tagOptions.filter(tag=>`${tag.label}`.toLowerCase().trim().includes(`${this.filters.tags.search}`.toLowerCase().trim())) },
    filterExtentions(){ return this.response.extensions.filter(extn=>`${extn.label}`.toLowerCase().trim().includes(`${this.filters.extensions.search}`.toLowerCase().trim())) },
    screens(){ return screens },
  },
  methods: {
    fetchFilters(){
      let vm = this
      vm.api.filters.send=true
      VOIP_API.endpoints.call_activity.reportingfilterdata({
        type: "filter",
      })
      .then(({ data: filters }) => {
        vm.response.extensions = filters?.extensions ?? [];
        vm.response.types = filters?.typeOptions ?? [];
      })
      .finally(() => {
        vm.api.filters.send=false
      });
    },
    fetchReports(reset) {
      let vm = this
      if(vm.api.reports.send) {
        // console.log('run')
        return;
      }
      if(reset==true){
        vm.response.reports = []
        vm.pagination.reports.page=1
        vm.$refs.rules_infinity_loader?.stateChanger?.reset?.()
      }
      vm.api.reports.send=true
      vm.api.reports.error_message=''
      VOIP_API.endpoints.call_activity.reportingfilterdata({
        type: "data",
        page: vm.pagination.reports.page,
        number: vm.filters.reports.number,
        from: vm.filters.reports.date_range?.start ?? '',
        to: vm.filters.reports.date_range?.end ?? '',
        agent: vm.filters.reports.agents?.map?.(i=>i.value)?.join?.(',') ?? '',
        tags: vm.filters.reports.tags?.map?.(i=>i.value)?.join?.(',') ?? '',
        callType: vm.filters.reports?.type?.value,
      })
      .then(({ data, no_record }) => {
        const reports = data?.data ?? []
        const total = data?.total ?? 0
        vm.pagination.reports.total = total
        if(vm.pagination.reports.page==1) vm.response.reports = reports
        else vm.response.reports = [...vm.response.reports,...reports]
        if(no_record || vm.pagination.reports.total==0) vm.$refs.reports_infinity_loader?.stateChanger?.complete?.()
        else {
          vm.$refs.reports_infinity_loader?.stateChanger?.loaded?.()
          vm.pagination.reports.page=vm.pagination.reports.page+1
          if(vm.response.reports.length<vm.pagination.reports.total) setTimeout(()=>vm.$refs.reports_infinity_loader.attemptLoad(true),2 * 1000)
          else vm.$refs.reports_infinity_loader?.stateChanger?.complete?.()
        }
      })
      .catch((ex)=>{
        vm.$refs.reports_infinity_loader?.stateChanger?.error?.()
        vm.api.reports.error_message=ex.own_message || ex.message
      })
      .finally(() => {
        vm.api.reports.send=false
      });
    },
  },
  mounted() {
    this.fetchFilters();
    setTimeout(()=>this.fetchReports(true),0.5*1000)
  },
};
</script>
