var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('section', {
    staticClass: "dialer-settings-section dialer-musicOnHold-settings"
  }, [_c('div', {
    staticClass: "settingsFirstSection"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "d-flex align-items-center calc-title-width-button w-100"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h2', {
    staticClass: "dialer-settings-title w-100 mb-0 text-center"
  }, [_vm._v("Music on hold")])], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.multi_moh
    },
    attrs: {
      "title": _vm.getUserPermissions.multi_moh ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton ml-auto",
    attrs: {
      "disabled": !_vm.getUserPermissions.multi_moh
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddPlayListModal', {
          suggested_name: `music on hold ${_vm.response.playlists.length + 1}`
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add playlist")])], 1)])] : [_c('h2', {
    staticClass: "dialer-settings-title settingsFirstSection-left mb-0 newer"
  }, [_vm._v(" Music on hold "), _c('b-icon', {
    staticClass: "cursor_pointer infoKbIcon md-mar-left",
    attrs: {
      "icon": "info-circle-fill"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('KbInfoModal', {
          kb: 'musicOnHold'
        });
      }
    }
  })], 1), _c('div', {
    staticClass: "settingsFirstSection-right"
  }, [_c('div', {
    staticClass: "d-flex justify-content-end align-items-center mt-0"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD"
  }, [_c('b-dropdown', {
    staticClass: "dd-text-dark dd-with-checkbox",
    attrs: {
      "no-caret": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('vb-svg', {
          staticClass: "filterDD-icon",
          attrs: {
            "name": "filter-icon",
            "width": "19",
            "height": "18",
            "viewBox": "0 0 19 18",
            "stroke-width": "0",
            "stroke": "",
            "fill": "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        })];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-form', [_c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare fixVerticalAlign",
    attrs: {
      "checked": _vm.selectAll
    },
    on: {
      "change": function ($event) {
        _vm.filter.playlist.types = $event ? Object.values(_vm.types).map(function (i) {
          return i.key;
        }) : [];
      }
    }
  }, [_c('span', {
    staticClass: "ml-4"
  }, [_vm._v("All")])]), _vm._l(_vm.types, function (type) {
    return _c('b-form-checkbox', {
      key: type.key,
      staticClass: "makeCheckBoxesSquare fixVerticalAlign",
      attrs: {
        "checked": _vm.filter.playlist.types.includes(type.key)
      },
      on: {
        "change": function ($event) {
          _vm.filter.playlist.types.includes(type.key) ? _vm.filter.playlist.types.splice(_vm.filter.playlist.types.indexOf(type.key), 1) : _vm.filter.playlist.types.push(type.key);
        }
      }
    }, [_c('span', {
      staticClass: "ml-4"
    }, [_vm._v(_vm._s(type.text))])]);
  })], 2)], 1)], 1), _c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder sm-mar-left sm-mar-right seachBox"
  }, [_c('b-input', {
    attrs: {
      "placeholder": "Search playlist",
      "type": "text"
    },
    model: {
      value: _vm.filter.playlist.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.playlist, "search", $$v);
      },
      expression: "filter.playlist.search"
    }
  })], 1), _c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    class: {
      'for-disable': !_vm.getUserPermissions.multi_moh
    },
    attrs: {
      "title": _vm.getUserPermissions.multi_moh ? '' : 'Your package does not support this feature'
    }
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.multi_moh
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddPlayListModal', {
          suggested_name: `music on hold ${_vm.response.playlists.length + 1}`
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-add-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Add playlist")])], 1)])])])]], 2), _vm._m(0), _c('vb-table', {
    staticClass: "latestTableDesign-withBlackBorders-again hasSoundFiles",
    attrs: {
      "textNoRecord": " ",
      "isListEmpty": _vm.filterPlaylists.length == 0,
      "listLength": _vm.filterPlaylists.length,
      "loading": _vm.api.playlists.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.filterPlaylists, function (playlist, index) {
          return _c('MOHItem', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: playlist.id,
            class: {
              'for-disable-disable': !_vm.getUserPermissions.multi_moh
            },
            attrs: {
              "playlist": playlist
            },
            on: {
              "clicked": function ($event) {
                !_vm.getUserPermissions.multi_moh ? _vm.appNotify({
                  message: 'Your package does not support this feature',
                  type: 'danger'
                }) : _vm.$modal.show('MainMusicOnHoldFileModal', {
                  id: playlist.id,
                  section: playlist.type == _vm.types.custom.key ? 'admin' : 'system',
                  name: playlist.label,
                  account: _vm.$store.getters.getCurrentUser.account,
                  type: playlist.order
                });
              }
            }
          });
        });
      }
    }, {
      key: "loading",
      fn: function () {
        return _vm._l(5, function (n) {
          return _c('tr', {
            key: n
          }, [_c('td', [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center w-fit-content"
          }, [_c('div', {
            staticClass: "latestShimmerDesign flex-1",
            staticStyle: {
              "height": "64px",
              "width": "250px"
            }
          }), _c('div', {
            staticClass: "latestShimmerDesign flex-1 ml-2",
            staticStyle: {
              "height": "21px",
              "width": "40px"
            }
          })])]), _c('td', [_c('div', {
            staticClass: "d-flex align-items-center"
          }, [_c('div', {
            staticClass: "latestShimmerDesign",
            staticStyle: {
              "height": "20px",
              "width": "-webkit-fill-available"
            }
          })])]), _c('td', {
            staticClass: "dialer-col-right"
          }, [_c('div', {
            staticClass: "latestShimmerDesign ml-auto",
            staticStyle: {
              "height": "48px",
              "width": "48px"
            }
          })])]);
        });
      },
      proxy: true
    }])
  }, [_c('template', {
    slot: "header"
  }, [!_vm.$store.getters.getIsMobile ? _c('tr', [_c('th', {
    staticClass: "dialer-has-sort wd-30"
  }, [_vm._v("Playlist")]), _c('th', {
    staticClass: "dialer-has-sort w-50"
  })]) : _vm._e()])], 2), _c('EditPlaylistModal', {
    attrs: {
      "modalName": 'MainMusicOnHoldFileModal'
    },
    on: {
      "close": function ($event) {
        return _vm.fetchPlaylistDebounce();
      }
    }
  }), _c('AddPlayListModal', {
    on: {
      "interface": function ($event) {
        return _vm.fetchPlaylistDebounce();
      }
    }
  })], 1)]);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "settingsSecondSection"
  }, [_c('span', {
    staticClass: "dialer-settings-intro newerTextVersion mt-0 mb-0"
  }, [_vm._v("Manage calls with virtual waiting areas. Apply fair distribution, music/messages for callers.")])]);

}]

export { render, staticRenderFns }