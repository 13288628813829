<template>
  <div class="crm-main-section-topBar">
    <h2 class="crm-main-section-heading mb-0">{{title}}</h2>
    <div class="section-topBar-middlePart">
      <vb-icon icon="crm-inputSearch-icon" />
      <input type="text" placeholder="Search">
    </div>
    <div class="section-topBar-lastPart">
      <div>
        <b-dropdown no-caret variant="link">
          <template #button-content>
            <b-icon icon="plus" />
          </template>
          <b-dropdown-item @click="$modal.show('CRMCreateDeal')">Deal</b-dropdown-item>
          <b-dropdown-item @click="$modal.show('CRMCreateActivity')">Activity</b-dropdown-item>
          <b-dropdown-item @click="''">Document</b-dropdown-item>
          <b-dropdown-item @click="''">Contact</b-dropdown-item>
          <b-dropdown-item @click="''">Company</b-dropdown-item>
          <b-dropdown-item @click="''">Product</b-dropdown-item>
        </b-dropdown>
      </div>
      <slot />
    </div>
  </div>
</template>

<script>
export default {
  name: 'TopBarCRM',
  props: {
    title: {
      type: String,
      default: '',
    },
  },
}
</script>

<style>

</style>