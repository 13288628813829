<template>
  <div>
    <div class="mainHeading withButton">
      Individual allowances
    </div>
    <div class="mainDescription mb-4">
      You can edit individual allowance for specific employees. The edited days will be applied to all future accrual periods.
    </div>

    <div class="grayCard borderRadius-12px flexColumn px-4">
      <div class="innerShadowInput-container halfWidth">
        <label>Location</label>
        <b-dropdown class="hrmInnerShadow-dd bgWhite noShadow" no-caret>
          <template #button-content>
            <div class="button-content">
              <div class="buttonContentText">All</div>
              <b-icon class="downIcon" icon="caret-down-fill" />
            </div>
          </template>
          <b-dropdown-item>
            Location
          </b-dropdown-item>
        </b-dropdown>
      </div>
      <div>
        <div class="innerShadowInput-container halfWidth">
          <label>Team</label>
          <b-dropdown class="hrmInnerShadow-dd bgWhite noShadow" no-caret>
            <template #button-content>
              <div class="button-content">
                <div class="buttonContentText">All</div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item>
              Team
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
      <div>
        <div class="innerShadowInput-container halfWidth">
          <label>Time off policy</label>
          <b-dropdown class="withTags-hrmInnerShadow-dd bgWhite noShadow" no-caret>
            <template #button-content>
              <div class="button-content">
                <div class="cancaleAbleTags-container addMarginTopTo5th">
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                  <div class="cancaleAbleTags">
                    <div class="cancaleAbleTags-text">Holiday</div>
                    <div class="cancaleAbleTags-icon">
                      <vb-icon icon="cancaleableTags-x-hrmIcon" />
                    </div>
                  </div>
                </div>
                <b-icon class="downIcon" icon="caret-down-fill" />
              </div>
            </template>
            <b-dropdown-item >
              Holiday
            </b-dropdown-item>
          </b-dropdown>
        </div>
      </div>
    </div>

    <div class="settings-IndividualAllowancesTable mt-16px IndividualAllowancesTable">
      <div class="HrmTableHead-container">
        <div class="HrmTableHead one">Employee</div>
        <div class="HrmTableHead two">Holiday</div>
        <div class="HrmTableHead three">Sickday</div>
        <div class="HrmTableHead four">Holiday-UK</div>
        <div class="HrmTableHead five">Holiday-USA</div>
        <div class="HrmTableHead six">Holiday-pAK</div>
        <div class="HrmTableHead seven">Business trip</div>
      </div>
      <vb-table class="HrmTable" >
        <!-- <template slot="header">
          <tr><th class="dialer-has-sort"></th></tr>
        </template> -->
        <template #body>
          <tr class="dialer-row-select">
            <td class="dialer-row-title one">
              <div class="nameWithImage">
                <div class="imageContainer">
                  <img :src="require('@/assets/images/credit-card/1.jpeg')" />
                </div>
                <div class="nameWithImage-text">Jackson</div>
              </div>
            </td>
            <td class="dialer-row-title two">20 days</td>
            <td class="dialer-row-title three">02 days</td>
            <td class="dialer-row-title four">-</td>
            <td class="dialer-row-title five">-</td>
            <td class="dialer-row-title six">20 days</td>
            <td class="dialer-row-title seven">02 days</td>
          </tr>
        </template>
      </vb-table>
    </div>
    
  </div>
</template>

<script>
export default {
  name: 'TimeOffIndividualAllowances',
  components:{
    
  },
}
</script>

<style>

</style>