var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('span', {
    directives: [{
      name: "hotkey",
      rawName: "v-hotkey",
      value: _vm.keymap,
      expression: "keymap"
    }]
  }), _c('ShortcutsModal')], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }