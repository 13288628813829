<template>
  <div v-if="channel_id && !isEmpty(channelData)" class="chat-info">
    <div class="chat-top-bar d-flex align-items-center justify-content-between">
      <div class="info-area d-flex align-items-start">
        <div v-if="$store.getters.getIsMobile" class="cursor_pointer">
          <b-icon icon="chevron-left" font-scale="1.7" class="settings-back-icon cursor_pointer mx-1" style="" @click="$emit('back')" />
        </div>
        <div class="user-avatar d-block avatar border border-0 border-dark rounded-circle overflow-hidden">
          <!-- <img :src="image" @error="$event.target.src = user_male" :alt="data.name" /> -->
          <vb-avatar :id="dataIdentity" :is_blf="false" :image="image" />
        </div>
        <div class="pr-3 d-flex align-items-end w-100">
          <div class="chat-topBar-header w-100">
            <div class="name mb-1 w-100">
              <h2>{{ name }}</h2>
              <vb-icon v-if="isMuted" icon="chatMuteNotification-icon" height="15px" width="15px" class="mx-2 chatBoxHeader-chatMuteNotification-icon"/>
              <b-icon v-if="!$store.getters.getIsMobile" height="15px" width="15px" class="mx-2 cursor_pointer" icon="gear-fill" variant="primary" @click="$modal.show(`${random_id}_ChannelSettingModal`)" />
            </div>
            <div class="description">
              <h6 v-if="channelData.type === 'channel'" class="m-0 channelDescription">
                {{ channel.purpose || `Add a group description` }}
              </h6>
              <h6 v-else-if="data" class="m-0 channelDescription">
                <template v-if="channelData.type == 'team'">{{ data | get_property('ringroup.extn') }}</template>
                <template v-else>{{ data | get_property('extn') }}</template>
              </h6>
              <small v-else-if="channelData.type === 'user' && status" class="text-primary">{{ status.status }}</small>
              <a class="d-flex align-items-center ml-2" @click="isSearchBox = !isSearchBox">
                <b-icon icon="search" :class="`${channelData.type !== 'team'?'border-left border-dark':''} pl-2`" scale="1.9" />
                <h6 class="ml-2 mb-0">Find</h6>
              </a>
            </div>
          </div>
        </div>
      </div>
      <div>
        <ul class="list-unstyled mb-0 d-flex">
          
          <template v-if="!$store.getters.getIsMobile">
            <li v-if="channelData.type === 'channel' && !isEmpty(currentUserMember) && currentUserMember.roles.indexOf('channel_admin') >= 0" class="d-inline-flex px-1">
              <a @click="$modal.show('MembersModal')" class="dialer-icon channel-members-icon" >
                <b-icon icon="person" scale="1.2" />
                <span class="member_count ml-1">{{ members.length }}</span>
              </a>
            </li>
          </template>
          
          <template v-if="!$store.getters.getIsMobile">
            <li class="d-inline-flex px-1" v-if="type === 'main'">
              <a @click="setFavorite" class="dialer-icon">
                <vb-icon :icon="isFavorite ? 'chat-favouriteIcon' : 'chat-notFavouriteIcon'" width="40" height="40" />
              </a>
            </li>
          </template>
          
          <template v-if="isGroupCallActive && !isInCall && !isCallIncoming">
            <li class="d-inline-flex px-1">
              <a @click="joinCall()" class="dialer-icon" style="width: 100px !important">Join call</a>
            </li>
          </template>
          <template v-else-if="!isInCall && !isCallIncoming">
            <template v-if="!call_sent">
              <li class="d-inline-flex px-1">
                <a @click="call('video')" class="dialer-icon">
                  <vb-icon icon="dialer-chatVideoIcon" width="40" height="40" />
                </a>
              </li>
              <li class="d-inline-flex px-1">
                <a class="dialer-icon" @click="call('audio')">
                  <vb-icon icon="dialer-audioCallIcon" width="40" height="40" />
                </a>
              </li>
            </template>
            <li v-else class="d-inline-flex px-1">
              <a class="dialer-icon">
                <b-icon icon="geo" />
                
              </a>
            </li>
          </template>
          <li class="d-inline-flex pr-1 pl-3" v-if="type === 'main'">
            <a class="dialer-icon d-inline-flex align-content-center" >
              <div class="whiteBGinputWithGreyRoundedBorder dropdownVersion filterDD ul-width-fit-content buttonHeightAuto buttonWidthAuto buttonOnRight withPadding0px">
                <b-dropdown varient="link" offset="25" no-caret class="ico-filter chat-header-more-dots">
                  <template v-slot:button-content>
                    <div class="dialer-icon ">
                      <vb-icon icon="chat-viewMoreDotsIcon" width="5" height="40" />
                    </div>
                  </template>
                  <b-dropdown-item>
                    <li v-if="channelData.type === 'user' && type === 'main'" class="d-inline-flex px-1">
                      <a @click="$modal.show('CreateChannelModal', { member: getCurrentlyUSerTypeMember, })" class="dialer-icon">
                        <vb-icon icon="chat-plus" width="40" height="40" />
                        <span class="pl-3">
                          <!-- Create Channel -->
                          <!-- Add members -->
                          Create group
                        </span>
                      </a>
                    </li>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="type === 'main'" @click="pin('pin')">
                    <li class="d-inline-flex px-1" >
                      <a class="dialer-icon" >
                        <vb-icon icon="chat-pinIcon" width="40" height="40" /><span class="pl-3">Pinned messages</span>
                      </a>
                    </li>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="type === 'main'" @click="pin('members')">
                    <li class="d-inline-flex px-1" >
                      <a class="dialer-icon" >
                        <vb-icon icon="openMembersModule-ChatIcon" width="40" height="40" />
                        <!-- <span class="pl-3">Members</span> -->
                        <span class="pl-3">Participants</span>
                      </a>
                    </li>
                  </b-dropdown-item>
                  <b-dropdown-item v-if="type === 'main'" @click="pin('files')">
                    <li class="d-inline-flex px-1" >
                      <a class="dialer-icon" >
                        <vb-icon icon="openMediaModule-ChatIcon" width="40" height="40" /><span class="pl-3">Files</span>
                      </a>
                    </li>
                  </b-dropdown-item>
                  <template v-if="$store.getters.getIsMobile">
                    <b-dropdown-item v-if="type === 'main'">
                      <li class="d-inline-flex px-1" v-if="type === 'main'">
                        <a @click="setFavorite" class="dialer-icon">
                          <vb-icon :icon="isFavorite ? 'chat-favouriteIcon' : 'chat-notFavouriteIcon'" width="40" height="40" />
                          <span class="pl-3">{{isFavorite ? 'Remove' : 'Make'}} Favourite</span>
                        </a>
                      </li>
                    </b-dropdown-item>
                  </template>
                  <template v-if="$store.getters.getIsMobile">
                    <b-dropdown-item @click="$modal.show('MembersModal')" v-if="channelData.type === 'channel' && !isEmpty(currentUserMember) && currentUserMember.roles.indexOf('channel_admin') >= 0">
                      <li v-if="channelData.type === 'channel' && !isEmpty(currentUserMember) && currentUserMember.roles.indexOf('channel_admin') >= 0" class="d-inline-flex px-1">
                        <a class="dialer-icon d-flex align-items-center" >
                          <b-icon class=" channel-members-icon" style="border-radius: 9px;" icon="person" scale="1" />
                          <span class="member_count ml-2">{{ members.length }} Members</span>
                        </a>
                      </li>
                    </b-dropdown-item>
                  </template>
                  <template v-if="$store.getters.getIsMobile">
                    <b-dropdown-item @click="$modal.show(`${random_id}_ChannelSettingModal`)">
                      <li class="d-inline-flex px-1">
                        <a class="dialer-icon d-flex align-items-center" >
                          <!-- <b-icon height="15px" width="15px" class="mx-2 cursor_pointer" icon="gear-fill" variant="primary" /> -->
                          <svg xmlns="http://www.w3.org/2000/svg" width="39.998" height="39.998" viewBox="0 0 39.998 39.998">
                            <g id="Group_45664" data-name="Group 45664" transform="translate(7700 8039)">
                              <g id="Group_45663" data-name="Group 45663" transform="translate(-7700.001 -8039.001)">
                                <path id="Path_12118" data-name="Path 12118" d="M28.122,39.9a49.11,49.11,0,0,1-15.259,0A14.129,14.129,0,0,1,1.1,28.122a48.684,48.684,0,0,1,0-15.259A14.122,14.122,0,0,1,12.864,1.1a48.684,48.684,0,0,1,15.259,0A14.129,14.129,0,0,1,39.9,12.864a49.11,49.11,0,0,1,0,15.259A14.136,14.136,0,0,1,28.122,39.9Z" transform="translate(-0.497 -0.497)" fill="#ececec"/>
                              </g>
                              <path id="Icon_ionic-md-settings" data-name="Icon ionic-md-settings" d="M19.21,13.275a5.628,5.628,0,0,0,.046-.9c0-.315-.046-.585-.046-.9L21.143,9.99a.413.413,0,0,0,.092-.585L19.394,6.3a.448.448,0,0,0-.552-.18l-2.3.9a6.7,6.7,0,0,0-1.565-.9l-.322-2.385a.493.493,0,0,0-.46-.36H10.511a.493.493,0,0,0-.46.36L9.682,6.12a7.794,7.794,0,0,0-1.565.9l-2.3-.9a.431.431,0,0,0-.552.18L3.423,9.405a.543.543,0,0,0,.092.585l1.979,1.485c0,.315-.046.585-.046.9s.046.585.046.9L3.561,14.76a.413.413,0,0,0-.092.585L5.31,18.45a.448.448,0,0,0,.552.18l2.3-.9a6.7,6.7,0,0,0,1.565.9l.368,2.385a.446.446,0,0,0,.46.36h3.682a.493.493,0,0,0,.46-.36l.369-2.385a7.789,7.789,0,0,0,1.565-.9l2.3.9a.431.431,0,0,0,.552-.18l1.841-3.105a.543.543,0,0,0-.092-.585Zm-6.858,2.25a3.151,3.151,0,1,1,3.222-3.15A3.167,3.167,0,0,1,12.352,15.525Z" transform="translate(-7692.376 -8031.376)" fill="#8a8a8a"/>
                            </g>
                          </svg>
                          <span class="member_count ml-2">Settings</span>
                        </a>
                      </li>
                    </b-dropdown-item>
                  </template>
                </b-dropdown>
              </div>
            </a>
          </li>
        </ul>
      </div>
    </div>
    <transition name="slide">
      <div v-if="isSearchBox" class="searchBox mt-3 pt-3 border-top border-light">
        <b-form class="w-75 mx-auto d-flex align-items-center position-relative" @submit.prevent="jump">
          <b-form-input v-model="search" @load="loadedSearchInput" class="w-100 searchBoxInput" autofocus @keyup.up.prevent="jump('up')" @keyup.down.prevent="jump('down')" @keyup.esc.prevent="close" @keydown.ctrl.f.exact.prevent="close"/>
          <b-icon icon="search" scale="1.1" class="position-absolute top-0 left-0 ml-1 cursor_pointer" />
          <div class="right-icons position-absolute right-10 h-100 top-0 d-flex align-items-center justify-content-between">
            <p class="mb-0" style="min-width:25px;">
              <span v-if="search">{{`${matched.length === 0 ? 0 : current_index + 1}/${ matched.length }`}}</span>
            </p>
            <div class="arrows">
              <b-icon icon="chevron-up" class="cursor_pointer mx-1" :disabled="current_index == 0" @click="jump('up')" />
              <b-icon icon="chevron-down" class="cursor_pointer mx-1" :disabled="current_index == filterSearchArray.length - 1" @click="jump('down')" />
            </div>
            <b-icon icon="x-circle" class="cursor_pointer" @click="close" />
          </div>
          <b-icon icon="x-circle-fill" @click="isSearchBox = !isSearchBox" class="closeIconOfChatSearch" />
        </b-form>
      </div>
    </transition>
    <MembersModal :channel_id="channel_id" :members="members" :channel="channel" :channelData="channelData" :currentUserMember="currentUserMember" />
    <AddMembersInChatModal :members="members" :channel="channel" :channelData="channelData" />
    <CreateChannelModal @complete="onCompleteCreateChannel" />
    <ChannelSettingModal 
      :modalName="`${random_id}_ChannelSettingModal`" 
      :channel="channel" 
      :channel_id="channel_id" 
      :channelData="channelData" 
      :currentUserMember="currentUserMember"
      :name="name" 
      :type="type"
      :image="image"
      @members="pin('members')"
    />
  </div>
</template>

<script>
import { LOGGER, events } from "@/utils";
import { mattermost, MATTERMOST_API } from "@/MatterMost";
import AddMembersInChatModal from "../Modal/AddMembersInChatModal";
import MembersModal from "../Modal/MembersModal";
import CreateChannelModal from "../../Modals/chat/CreateChannelModal.vue";
import ChannelSettingModal from "../Modal/ChannelSettingModal.vue";
import _ from "lodash";
export default {
  name: "Header",
  components: {
    AddMembersInChatModal,
    MembersModal,
    CreateChannelModal,
    ChannelSettingModal,
  },
  props: {
    dataIdentity: {
      type: [String,Number],
      default: '',
    },
    channel_id: {
      type: String,
      default: "",
    },
    channel: {
      type: Object,
      default: () => ({}),
    },
    data: {
      type: Object,
      default: () => ({}),
    },
    channelData: {
      type: Object,
      default: () => ({}),
    },
    members: {
      type: Array,
      default: () => [],
    },
    type: {
      type: String,
    },
    name: {
      type: String,
      deault: "",
    },
    image: {
      type: String,
      deault: "",
    },
    currentUserMember: {
      type: Object,
      default: () => ({}),
    },
    random_id: {
      type: String,
      default: '',
    },
  },
  data() {
    return {
      search: "",
      user_male: require("@/assets/images/user-male.png"),
      chanel_image : require('@/assets/images/channel-image.svg'),
      isSearchBox: false,
      loading: {
        video: false,
        audio: false,
      },
      call_sent: false,
      current_index: 0,
      prev_index: 0,
      matched: [],
    };
  },
  inject:['isEmpty','appNotify'],
  computed: {
    isMuted(){
      return this.$store.state.mattermost.channels?.[this.channel_id]?.notification_prference?.is_mute ?? false
    },
    status() {
      const status_id = this.data?.status?.id ?? this.data?.status ?? ''
      return this.$store.state.common.statuses.find((status) => status.id == status_id);
    },
    isGroupCallActive() {
      const active_group_call =
        this.$store.state.calls.active_group_calls[this.channel.id];
      return active_group_call;
    },
    isInCall() {
      const channel_id = this.channel.id
      const is_user = this.channelData && this.channelData.type == 'user'
      const accountcode = (this.channelData && this.channelData.data && this.channelData.data.accountcode) ?? ''
      const call = this.$store.state.calls.videoCalls.find(call=>is_user ? (call.data.caller==accountcode || call.data.callee==accountcode) : call.room==channel_id);
      return call;
    },
    isCallIncoming() {
      return this.$store.state.calls.incoming_calls[this.channel_id];
    },
    getCurrentlyUSerTypeMember() {
      const [member] = this.members.filter(
        // (member) => member.user_id !== this.mattermost_current_user.id
        (member) => member.user_id !== this.$store.getters.getMattermostCurrentUser?.id
      );
      return member;
    },
    otherMembers() {
      return this.members.filter(
        // (member) => member.user_id !== this.mattermost_current_user.id
        (member) => member.user_id !== this.$store.getters.getMattermostCurrentUser?.id
      );
    },
    filterSearchArray() {
      if (!this.search || typeof this.search !== "string") return [];
      if (
        !this.$parent.$refs &&
        !this.$parent.$refs.messages &&
        !this.$parent.$refs.messages.messages
      )
        return [];
      const reg_exp = new RegExp(this.search.toLowerCase().trim(), "i");
      return this.$parent.$refs.messages.messages.filter((message) =>
        reg_exp.test(message.message)
      );
    },
    isFavorite() {
      if (this.data.type !== "user") {
        return (
          this.$store.getters.getFavoriteChannelIds.indexOf(this.channel.id) >=
          0
        );
      } else {
        return (
          this.$store.getters.getFavoriteUsersIds.indexOf(
            this.data.voipaccount
          ) >= 0
        );
      }
    },
  },
  watch: {
    matched() {
      this.current_index = 0;
    },
    channel_id(){
      this.search=''
      this.isSearchBox=false
    },
  },
  methods: {
    messagesContainerObserver() {
      this.matched = document.querySelectorAll(".chat-box mark");
      if (this.matched && this.matched.length > 0) return;
      this.current_index = 0;
    },
    jump(type) {
      if (this.matched.length === 0) return false;
      this.prev_index = this.current_index;
      if (type == "down") {
        if (this.current_index == this.matched.length - 1) return false;
        this.current_index = this.current_index + 1;
      } else if (type == "up" && this.current_index > 0) {
        this.current_index = this.current_index - 1;
      }
      this.matched[this.prev_index].classList.remove("current-search");
      this.matched[this.current_index].classList.add("current-search");
      this.matched[this.current_index].scrollIntoView(false);
      return false;
    },
    async setFavorite() {
      try {
        const user_id = this.$store.getters.getMattermostCurrentUser?.id;//this.mattermost_current_user.id;
        const data = [
          {
            user_id: user_id,
            category: "favorite_channel",
            name: this.channel.id,
            value: this.channelData.type === "user" ? this.data.voipaccount : "true",
          },
        ];
        if (this.isFavorite) {
          await MATTERMOST_API.endpoints.preferences.deleteUsersPreferences(user_id, data);
          mattermost.emitOnUserLevel(mattermost.events.preferences_deleted,{
            user_id: user_id,
            preferences: data,
          })
        } else {
          await MATTERMOST_API.endpoints.preferences.saveUsersPreferences(user_id, data);
          mattermost.emitOnUserLevel(mattermost.events.preference_changed,{
            user_id: user_id,
            preferences: data,
          })
        }
      } catch (error) {
        LOGGER.danger("error in setFavorite function", error);
      }
    },
    async call(call_type, id) {
      if (!id) {
        this.call_sent = true;
      }
      const finish = () => {
        if (!id) {
          this.call_sent = false;
        }
      };
      try {
        const accounts = [];
        if (id) {
          const user = this.$store.getters.getVoipUsersAlises[id];
          if (_.isEmpty(user)) throw new Error("User is Empty");
          accounts.push(user.voipaccount);
        } else {
          await this.members.forEach((member) => {
            //  if (member.user_id !== this.mattermost_current_user.id) {
            if (member.user_id !== this.$store.getters.getMattermostCurrentUser?.id) {
              const user = this.$store.getters.getVoipUsersAlises[member.user_id];
              if (!_.isEmpty(user)) {
                accounts.push(user.voipaccount);
              }
            }
          });
        }
        // console.log(this.members.map(member=>member.user_id),this.$store.getters.getMattermostCurrentUser?.id,accounts,call_type,id)
        this.$root.$emit(events.video_call, {
          accounts,
          call_type,
          room: this.channel.id,
          display_name: this.channelData.type !== "user" && !id ? this.name : "",
          id: this.data?.voipaccount ?? this.data?.real_id ?? this.data?.id,
          image: this.channelData.type !== "user" && !id ? this.image : this.$store.getters.getCurrentUser?.profileImg ?? '',
          is_group: this.channelData.type !== "user" && !id,
          onComplete: finish,
          onAbort: finish,
        });
      } catch (error) {
        LOGGER.danger("error in call function", error);
        finish();
      }
    },
    leave() {
      let vm = this;
      this.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to leave.`,
        variant: 'danger',
        button: {
          no: "no",
          yes: "yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            MATTERMOST_API.endpoints.channels.removeChannelMember(vm.channel_id, vm.$store.getters.getMattermostCurrentUser?.id)
              .then(() => {
                vm.appNotify({
                  message: "Successfully leaved",
                  type: 'success',
                })
              }).then(()=>{
                mattermost.emitOnUserLevel(mattermost.events.user_removed,{
                  user_id: vm.$store.getters.getMattermostCurrentUser?.id,
                  removed_channel_id: vm.channel_id,
                })
              });
          }
        },
      });
    },
    async deleteChannel() {
      let vm = this;
      this.$appConfirmation({
        title: "Warning",
        message: `Are you sure you want to delete.`,
        variant: 'danger',
        button: {
          no: "no",
          yes: "yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            MATTERMOST_API.endpoints.channels.deleteChannel(vm.channel_id).then(() => {
              vm.appNotify({
                message: "Successfully deleted",
                type: 'success',
              })
            }).then(()=>{
              mattermost.emitOnUserLevel(mattermost.events.channel_deleted,{
                channel_id: vm.channel_id
              })
            });
          }
        },
      });
    },
    pin(tab='pin') {
      this.$root.$emit(events.pin_posts, {
        channel_id: this.channel_id,
        tab: tab
      });
    },
    async chat(id) {
      try {
        const user = this.$store.getters.getVoipUsersAlises[id];
        if (_.isEmpty(user)) return;
        this.$root.$emit(events.change_chat_tab, 2);
        let channel_id = user.channel_id;
        if (!channel_id) {
          const { data: channel } =
            await MATTERMOST_API.endpoints.channels.createDirectChannel([
              user.user.user_chat_id,
              // this.mattermost_current_user.id,
              this.$store.getters.getMattermostCurrentUser?.id,
            ]);
            mattermost.emitOnUserLevel(mattermost.events.direct_added,{
              channel_id: channel.id
            })
          this.$set(
            this.$store.state.common.voipusers[user.voipaccount],
            "channel_id",
            channel.id
          );
          // this.$root.$emit(events.adding_channel, {
          //   channel_id: channel.id,
          //   data: {
          //     channel: channel,
          //     type: "user",
          //     // data: user,
          //     id: user?.voipaccount
          //   },
          // });
          channel_id = channel.id;
        }
        this.$parent.channel_id = user.channel_id;
      } catch (error) {
        LOGGER.danger("error in chat function", error);
      }
    },
    // checkIsFav() {
    //   if (this.data.type !== "user") {
    //     this.isFavorite =
    //       this.$store.getters.getFavoriteChannelIds.indexOf(this.channel.id) >=
    //       0;
    //   } else {
    //     this.isFavorite =
    //       this.$store.getters.getFavoriteUsersIds.indexOf(
    //         this.data.voipaccount
    //       ) >= 0;
    //   }
    // },
    joinCall() {
      this.$root.$emit(events.join_call,this.$store.state.calls.active_group_calls[this.channel.id]);
    },
    onCompleteCreateChannel(channel) {
      this.$root.$emit(events.change_chat_tab, 3);
      this.$parent.channel_id = channel.id;
    },
    close() {
      this.isSearchBox = false;
      this.search = "";
    },
    toggleSearch() {
      this.isSearchBox = !this.isSearchBox;
    },
    loadedSearchInput(Event) {
      LOGGER.log(Event);
      Event.target.focus();
    },
    roleChange(member, index, action) {
      let vm = this;
      if (action === "admin") {
        MATTERMOST_API.endpoints.channels
          .updateMemberRole(this.channel_id, member.user_id, {
            scheme_admin: true,
            scheme_user: true,
          })
          .then(() => {
            vm.$store.state.mattermost.channels[vm.channel_id].members[
              index
            ].roles = "channel_user channel_admin";
            vm.$store.state.mattermost.channels[vm.channel_id].members[
              index
            ].scheme_admin = true;
          });
      } else {
        MATTERMOST_API.endpoints.channels
          .updateMemberRole(member.channel_id, member.user_id, {
            scheme_admin: false,
            scheme_user: true,
          })
          .then(() => {
            vm.$store.state.mattermost.channels[vm.channel_id].members[
              index
            ].roles = "channel_user";
            vm.$store.state.mattermost.channels[vm.channel_id].members[
              index
            ].scheme_admin = false;
          });
      }
    },
  },
  created() {
    this.$root.$on(events.ctrl_f, this.toggleSearch);
    LOGGER.log(this);
    if (this.$parent.$refs.messages) {
      const observer = new MutationObserver(this.messagesContainerObserver);
      observer.observe(this.$parent.$refs.messages.$el, {
        childList: true,
        subtree: true,
      });
    }
    // this.checkIsFav();
  },
  beforeDestroy() {
    this.$root.$off(events.ctrl_f, this.toggleSearch);
  },
};
</script>

<style lang="scss">
.chat-info {
  .chat-top-bar {
    .info-area {
      // max-width: calc(100% - 226px);
      max-width: calc(100% - 265px);
      width:100%;
      .user-avatar {
        // min-width: 42px;
        min-width: 45px;
      }
      .chat-topBar-header {
        display: flex;
        align-items: flex-start;
        flex-direction: column;
        .name {
          justify-content: flex-start;
          .dialer-dropdown {
            margin-left: 10px;
            margin-right: 10px;
            .dialer-icon {
              min-width: unset;
              width: auto;
              // padding: 0px;
              height: auto;
              // styling by frank
              padding: 0px 5px;
              & > .b-icon.bi{
                vertical-align: middle;
              }
            }
          }
        }
        .description {
          display: flex;
          width: 100%;
          .channelDescription{
            // word-break: break-all;
            // white-space: unset;
            max-width: calc(50% - 16px);
          }
        }
        h2,
        h6 {
          margin-bottom: 0px;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
        }
      }
    }

    .dialer-dropdown {
      background: transparent;
      width: auto;
      &.dialpad_activity_box_menu {
        .dropdown {
          .dropdown-menu {
            min-width: 240px;
            right: 0px;
            left: unset !important;
          }
        }
      }
      .dropdown {
        width: auto;
        margin: 0px !important;
        .btn-secondary {
          padding: 0px;
          &.dropdown-toggle {
            &.b-icon {
              .bi {
                font-size: 20px;
              }
            }
          }
        }
        .dropdown-menu {
          min-width: 200px;
          max-height: 320px;
          overflow-y: auto;
          z-index: 1;
          li {
            .b-dropdown-text {
              padding: 15px 10px;
              span {
                font-weight: bold;
                text-transform: capitalize;
                font-size: 17px;
              }
            }
            a {
              padding: 0px;
            }
          }
        }
      }
    }
  }

  .searchBox {
    .form-control {
      height: 38px;
      max-height: 38px;
      font-size: 15px;
      padding-right: 34px;
      &.searchBoxInput {
        // padding-right: 90px;
        padding-right: 120px;
        padding-left: 40px;
      }
    }
    .right-icons {
      // width: 80px;
      width: 100px;
    }
    svg {
      top: 12px;
    }
  }
}

.dialer_contact_row {
  padding: 10px;
  width: 100%;
  box-sizing: border-box;
  display: flex;
  align-items: center;
  border-bottom: 1px solid #d3d2d3;
  transition: all 0.3s ease-in-out;
  .dialer_contact {
    display: flex;
    width: 100%;
    align-items: center;
    cursor: pointer;
    .dialer_contact_img {
      position: relative;
      margin-right: 10px;
      img {
        width: 41px;
        height: 41px;
        object-fit: cover;
      }
    }
    .dialer_contact_content {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      flex-grow: 1;
      max-width: calc(100% - 51px);
      .dialer_contact_name {
        font-size: 14px;
        font-weight: 500;
        color: $body-color;
        width: 100%;
        white-space: nowrap;
        overflow: hidden;
        text-overflow: ellipsis;
      }
    }
    .dialer_contact_hidden_area {
      display: none;
      align-items: center;
      transition: margin 0.3s ease-in-out, opacity 0.3s ease-in-out;
      margin-left: -10px;
      opacity: 0;
      .dialer_contact_connection_box {
        display: flex;
        align-items: center;
        justify-content: flex-start;
        button {
          display: inline-flex;
          align-items: center;
          border: none;
        }
        .dialer_contact_btn {
          svg {
            opacity: 0;
            transition: all 1s ease-in-out;
          }
        }
      }
      .dialer_contact_btn {
        border: none;
        &.active {
          padding-right: 0;
        }
      }
    }
  }
  &:hover {
    background: #f5f5f5;
    border-bottom-color: #f5f5f5;
    .dialer_contact {
      .dialer_contact_content {
        max-width: 40%;
      }
      .dialer_contact_hidden_area {
        display: flex;
        margin-left: 0;
        opacity: 1;
        .dialer_contact_connection_box {
          .dialer_contact_btn {
            svg {
              opacity: 1;
            }
          }
        }
      }
    }
  }
  &:last-child {
    border-bottom: none;
  }
}
// slide top to bottom &bottom to top animation
.slide-enter-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in;
  -webkit-transition-timing-function: ease-in;
  -o-transition-timing-function: ease-in;
  transition-timing-function: ease-in;
}

.slide-leave-active {
  -moz-transition-duration: 0.3s;
  -webkit-transition-duration: 0.3s;
  -o-transition-duration: 0.3s;
  transition-duration: 0.3s;
  -moz-transition-timing-function: ease-in-out;
  -webkit-transition-timing-function: ease-in-out;
  -o-transition-timing-function: ease-in-out;
  transition-timing-function: ease-in-out;
}

.slide-enter-to,
.slide-leave {
  max-height: 150px;
  overflow: hidden;
}

.slide-enter,
.slide-leave-to {
  overflow: hidden;
  max-height: 0;
}
</style>
