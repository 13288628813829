var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_vm.conditions.tab ? _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    },
    on: {
      "click": function ($event) {
        _vm.conditions.tab = false;
      }
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Playlist")])]) : _c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("My Sound Files")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.checkPermission(_vm.getUserPermissions.block_and_privacy, function () {
          return _vm.$modal.show('AddSoundFile', {
            suggested_name: `sound library ${_vm.response.files.length + 1}`
          });
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    }
  })], 1)]), _c('div', {
    staticClass: "innerModalIos"
  }, [_vm.conditions.tab ? [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, _vm._l(_vm.tabs, function (tab) {
    return _c('div', {
      key: tab.id,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.selected.tab = tab.id;
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(tab.text))]), _vm.selected.tab == tab.id ? _c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    }) : _vm._e()], 1);
  }), 0)] : [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_vm.selected.tab == _vm.tabs.system.id ? _c('b-form-input', {
    attrs: {
      "placeholder": "Search"
    },
    model: {
      value: _vm.filter.system.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.system, "search", $$v);
      },
      expression: "filter.system.search"
    }
  }) : _vm.selected.tab == _vm.tabs.files.id ? _c('b-form-input', {
    attrs: {
      "placeholder": "Search"
    },
    model: {
      value: _vm.filter.files.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.files, "search", $$v);
      },
      expression: "filter.files.search"
    }
  }) : _vm.selected.tab == _vm.tabs.shared.id ? _c('b-form-input', {
    attrs: {
      "placeholder": "Search"
    },
    model: {
      value: _vm.filter.shared.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.shared, "search", $$v);
      },
      expression: "filter.shared.search"
    }
  }) : _vm._e(), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item",
    on: {
      "click": function ($event) {
        _vm.conditions.tab = true;
      }
    }
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Sound Library")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight mr-8px"
  }, [_vm._v(_vm._s(_vm.selectedTab.text))]), _c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    }
  })], 1)])]), _c('div', {
    staticClass: "basicWhiteIOScard mt-16px mb-16px"
  }, [_c('div', [_vm.selected.tab == _vm.tabs.system.id ? [_vm._l(_vm.filterSystem, function (file, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.api.shift.send ? '' : _vm.shiftFile(file.id);
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(file.label))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('vb-audio-player', {
      staticClass: "mr-16px IosAudioPlayer",
      attrs: {
        "src": file.sound_file
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center forRadio-container"
    }, [_c('vb-icon', {
      style: `${_vm.api.shift.send == file.id ? 'opacity:1;' : 'opacity:0;'}`,
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)], 1)]);
  }), !_vm.filterSystem.length && !_vm.api.files.send ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Numbers',
      "design": 3
    }
  }) : _vm._e()] : _vm.selected.tab == _vm.tabs.files.id ? [_vm._l(_vm.filterFiles, function (file, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.api.shift.send ? '' : _vm.shiftFile(file.id);
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(file.label))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('vb-audio-player', {
      staticClass: "mr-16px IosAudioPlayer",
      attrs: {
        "src": file.sound_file
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center forRadio-container"
    }, [_c('vb-icon', {
      style: `${_vm.api.shift.send == file.id ? 'opacity:1;' : 'opacity:0;'}`,
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)], 1)]);
  }), !_vm.filterFiles.length && !_vm.api.files.send ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Numbers',
      "design": 3
    }
  }) : _vm._e()] : _vm.selected.tab == _vm.tabs.shared.id ? [_vm._l(_vm.filterShared, function (file, index) {
    return _c('div', {
      key: index,
      staticClass: "basicWhiteIOScard-item",
      on: {
        "click": function ($event) {
          _vm.api.shift.send ? '' : _vm.shiftFile(file.id);
        }
      }
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(file.label))]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('vb-audio-player', {
      staticClass: "mr-16px IosAudioPlayer",
      attrs: {
        "src": file.sound_file
      }
    }), _c('div', {
      staticClass: "d-flex align-items-center forRadio-container"
    }, [_c('vb-icon', {
      style: `${_vm.api.shift.send == file.id ? 'opacity:1;' : 'opacity:0;'}`,
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)], 1)]);
  }), !_vm.filterShared.length && !_vm.api.files.send ? _c('vb-no-record', {
    attrs: {
      "text": 'There is no Numbers',
      "design": 3
    }
  }) : _vm._e()] : _vm._e()], 2), _c('InfiniteLoading', {
    ref: "files_infinity_loading",
    on: {
      "infinite": function ($event) {
        return _vm.fetchFiles();
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (_ref) {
        var trigger = _ref.trigger;
        return [_c('div', [_c('span', [_vm._v(_vm._s(_vm.api.files.error_message))]), _c('b-icon', {
          staticClass: "ml-3",
          attrs: {
            "icon": "arrow-repeat",
            "variant": "primary",
            "font-scale": "1.5"
          },
          on: {
            "click": function ($event) {
              return trigger();
            }
          }
        })], 1)];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "spinner"
    },
    slot: "spinner"
  }, [_c('vb-loading', {
    attrs: {
      "type": "loader"
    }
  })], 1), _c('div', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }), _c('div', {
    attrs: {
      "slot": "no-results"
    },
    slot: "no-results"
  })])], 1)], _c('AddSoundFileModal', {
    on: {
      "update-list": function ($event) {
        return _vm.fetchFiles(true);
      }
    }
  })], 2)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }