<template>
  <div>
    <modal 
      class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
      width="50%" 
      :scrollable="true" 
      height="auto"  
      :name="modalName" 
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <template v-if="conditions.screen==screens.selected_call_plan">
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Select Call Plan</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="plan in callPlans" 
                :key="plan.value" 
                @click="forms.change_prefix.prefix=plan.value;conditions.screen=screens.main;"
              >
                <div class="headingInsideTable">{{ plan.text }}</div>
                <vb-icon icon="MyNumbers-tick-IOSIcon" v-if="forms.change_prefix.prefix==plan.value" width="16.642" height="15.275" />
              </div>
              <vb-no-record v-if="callPlans.length==0" :text="'There is no Call Plan'" :design="3" />
            </div>

          </div>
        </div>
      </template>
      <template v-if="conditions.screen==screens.selected_prefix">
        
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="api.update_addon_prefixes.send ? '' : conditions.screen=screens.main">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">Bundles</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <div class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="bundle in selectedPrefixBundles" 
                :key="bundle.id" 
                @click="selected.prefix_id=prefixe.product_id;conditions.screen=screens.selected_prefix"
              >
                <div class="headingInsideTable">{{bundle.bundle.label}}</div>
                <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ bundle.balance }}</div>
              </div>
              <vb-no-record v-if="selectedPrefixBundles.length==0" :text="'There is no playlist'" :design="3" />
            </div>

            <button :disabled="api.update_addon_prefixes.send" @click="updateAddonPrefix(selectedPrefix.product_id)" class="IosFullWidthButton mb-16px">
              <vb-spinner v-if="api.update_addon_prefixes.send" />
              <template>Purchase</template>
            </button>

          </div>
        </div>

      </template>
      <template v-else>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
          </div>
          <div class="dialer-settings-title topMainHeading">VS Packages</div>
          <div class="plus-IOSIcon-container">
          </div>
        </div>
        <div class="innerModalIos">
          <div class="lg-mar-bottom">
            
            <template v-if="conditions.call_plan && !!currentUserAccount">
              <div class="basicWhiteIOScard mt-32px mb-16px">
                <div class="basicWhiteIOScard-item" @click="api.update_prefixes.send ? '' : conditions.screen=screens.selected_call_plan">
                  <div class="headingInsideTable">{{ currentUserAccount.first_name }} {{ currentUserAccount.last_name }} </div>
                  <div class="d-flex align-items-center">
                    <div class="textInsideCard textAlignRight onRightSide mr-6px">{{ forms.change_prefix.prefix }}</div>
                    <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
                  </div>
                  <p 
                    v-if="(forms.change_prefix.submitted && $v.forms.change_prefix.prefix.$invalid) || api.update_prefixes.validation_errors.prefix" 
                    class="text text-danger animated bounceIntop"
                  >
                    <span v-if="!$v.forms.change_prefix.prefix.required">Prefix is required</span>
                    <span v-for="(em, i) in api.update_prefixes.validation_errors.prefix" :key="i">{{em}}</span>
                  </p>
                </div>
              </div>
              <button :disabled="api.update_prefixes.send" @click="updatePrefix()" class="IosFullWidthButton mb-16px">
                <vb-spinner v-if="api.update_prefixes.send" />
                <template>Change</template>
              </button>
            </template>

            <div v-if="conditions.vs_packages" class="basicWhiteIOScard mt-32px mb-16px">
              <div 
                class="basicWhiteIOScard-item" 
                v-for="prefixe in addonPrefixes" 
                :key="prefixe.product_id" 
                @click="selected.prefix_id=prefixe.product_id;conditions.screen=screens.selected_prefix"
              >
                <div class="headingInsideTable">{{ prefixe | get_property('product.product_label') }}</div>
                <vb-icon icon="rightFacingArrow-IOSIcon" class="rightFacingArrow" width="7.066" height="11.57" />
              </div>
              <vb-no-record v-if="addonPrefixes.length==0" :text="api.get_prefixes.send?'':'There is no playlist'" :design="3">
                <vb-loading v-if="api.get_prefixes.send" slot="loading" />
              </vb-no-record>
            </div>

          </div>
        </div>
      </template>
    </modal>
  </div>
</template>

<script>
import { required, } from "vuelidate/lib/validators";
import { mapGetters } from 'vuex';
import { VOIP_API } from '@/utils';
let screens = {
  selected_prefix: 'selected_prefix',
  selected_call_plan: 'selected_call_plan',
  main: 'main',
}
export default {
  name: "UserPrefixModal",
  inject: ['isEmpty','appNotify'],
  props: {
    modalName: {
      type: String,
      default: 'UserPrefix',
    }
  },
  data() {
    return {
      forms: {
        change_prefix: this.$helperFunction.formInstance({
          data: {
            prefix: '',
          },
        }),
      },
      api: {
        account_detail: this.$helperFunction.apiInstance(),
        get_prefixes: this.$helperFunction.apiInstance(),
        update_addon_prefixes: this.$helperFunction.apiInstance(),
        update_prefixes: this.$helperFunction.apiInstance({ validation_errors: true }),
        reset(){
          this.account_detail?.reset?.()
          this.get_prefixes?.reset?.()
          this.update_addon_prefixes?.reset?.()
          this.update_prefixes?.reset?.()
        },
      },
      data: this.$helperFunction.stateInstance({
        data: {
          accountcode: '',
        }
      }),
      response: this.$helperFunction.stateInstance({
        data: {
          account: {},
          prefixes: {},
        }
      }),
      selected: {
        prefix_id: '',
      },
      conditions: {
        screen: screens.main,
        vs_packages: true,
        call_plan: true,
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getIsMobile',
    ]),
    screens(){ return screens },
    selectedPrefix(){ return this.response.prefixes?.addonPrefixes?.find(prefix=>prefix.product_id==this.selected.prefix_id) },
    selectedPrefixBundles(){ return this.selectedPrefix?.product?.plan?.vspackage?.bundles ?? [] },
    addonPrefixes(){ return this.response.prefixes?.addonPrefixes ?? [] },
    callPlans(){ return this.response.prefixes?.callPrefixes?.map?.(item=>( { text: `${item.prefix.label} ${item.prefix.prefix}`, value: item.prefix.prefix, } )) ?? [] },
    currentUserAccount(){ return this.response.account?.users?.[this.data.accountcode] },
  },
  validations: {
    forms: {
      change_prefix: {
        prefix: { 
          required,
        },
      },
    },
  },
  methods: {
    onBeforeOpen(event){
      const vs_packages_only = event?.params?.vs_packages_only
      const call_plan_only = event?.params?.call_plan_only
      // console.log('vs_packages_only,call_plan_only',vs_packages_only,call_plan_only)
      if(vs_packages_only){
        this.fetchPrefixes()
        this.conditions.call_plan=false
      } else if(call_plan_only){
        this.data.accountcode=event?.params?.accountcode ?? ''
        this.fetchAccountDetail()
        this.conditions.vs_packages=false
      } else {
        this.data.accountcode=event?.params?.accountcode ?? ''
        this.fetchAccountDetail()
        this.fetchPrefixes()
      }
    },
    onBeforeClose(){
      this.data.reset()
      this.response.reset()
      this.api.reset()
      this.data.reset()
      this.selected.prefix_id=''
      this.conditions.screen=screens.main
      this.conditions.vs_packages=true
      this.conditions.call_plan=true
    },
    fetchAccountDetail(){
      let vm = this
      if(vm.api.account_detail.send) return;
      vm.api.account_detail.send=true
      VOIP_API.endpoints.users.accountdetail()
      .then(({ data }) => {
        vm.response.account = data
        vm.forms.change_prefix.prefix = vm.response.account?.users?.[vm.data.account]?.plan_prefix ?? ''
      })
      .finally(() => {
        vm.api.account_detail.send = false
      });
    },
    fetchPrefixes(){
      let vm = this
      vm.api.get_prefixes.send=true
      VOIP_API.endpoints.techprifex.get({
        uid: vm.getCurrentUser.uid,
        accountcode: vm.getCurrentUser.account,
      })
      .then(({ data: prefixes })=>{
        vm.response.prefixes = prefixes || {}
      })
      .finally(()=>{
        vm.api.get_prefixes.send=false
      })
    },
    updatePrefix(){
      let vm = this
      const prefix_obj = (vm.response.prefixes.callPrefixes || []).find(item=>item.prefix.prefix==vm.forms.change_prefix.prefix)
      vm.forms.change_prefix.submitted=true
      vm.$v.forms.change_prefix.$touch()
      if(!prefix_obj || vm.api.update_prefixes.send || vm.$v.forms.change_prefix.$invalid) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to change this`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.update_prefixes.send=true
            vm.api.update_prefixes.validation_errors={}
            VOIP_API.endpoints.techprifex.update(prefix_obj.product_id,{
              id: prefix_obj.product_id,
              prefix: vm.forms.change_prefix.prefix,
              useraccount: vm.data.account,
              accountcode: vm.data.account,
              account: vm.getCurrentUser.account,
              uid: vm.getCurrentUser.uid,
            }).then(()=>{
              vm.fetchAccountDetail()
              vm.appNotify({
                message: 'Successfully Updated',
                type: 'success',
              })
            }).catch((ex)=>{
              vm.api.update_prefixes.validation_errors=ex.own_errors
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            }).finally(()=>{
              vm.forms.change_prefix.submitted=false
              vm.api.update_prefixes.send=false
            })
          }
        },
      });
    },
    updateAddonPrefix(product_id){
      let vm = this
      const prefix_obj = (vm.response.prefixes.addonPrefixes || []).find(item=>item.product_id==product_id)
      if(!prefix_obj || vm.api.update_addon_prefixes.send) return;
      vm.$appConfirmation({
        title: "warning",
        message: `Are you sure you want to change this`,
        button: {
          no: "No",
          yes: "Yes",
        },
        callback: ({confirm}) => {
          if (confirm) {
            vm.api.update_addon_prefixes.send=true
            VOIP_API.endpoints.techprifex.update(prefix_obj.product_id,{
              id: prefix_obj.product_id,
              prefix: product_id,
              useraccount: vm.getCurrentUser?.account,
              accountcode: vm.getCurrentUser?.account,
              account: vm.getCurrentUser?.account,
              uid: vm.getCurrentUser?.uid,
            })
            .then(()=>{
              vm.appNotify({
                message: 'Successfully Updated',
                type: 'success',
              })
            })
            .catch((ex)=>{
              vm.appNotify({
                message: ex.own_message,
                type: 'danger',
              })
            })
            .finally(()=>{
              vm.api.update_addon_prefixes.send=false
            })
          }
        },
      });
    },
  },
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
