var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "panel chat-panel h-100"
  }, [_c('div', {
    class: {
      'panel-inner': true,
      'panel-listView': _vm.GET_LOCAL_SETTING_CHAT.appearence.list_view === 'accepted',
      'panel-withoutIcon': _vm.GET_LOCAL_SETTING_CHAT.appearence.is_icon_show === 'not_accepted'
    }
  }, [_c('h2', {
    staticClass: "d-flex flex-wrap align-items-center justify-content-between"
  }, [_c('span', {
    staticClass: "chat-sidebar-Title"
  }, [_vm._v("Chat")]), _c('div', {
    staticClass: "dialer-dropdown"
  }, [_c('b-dropdown', {
    ref: "dropdown",
    staticClass: "m-2",
    attrs: {
      "id": "dropdown-form",
      "menu-class": "latestDesignMenuClass",
      "text": "appearance",
      "right": ""
    },
    scopedSlots: _vm._u([{
      key: "button-content",
      fn: function () {
        return [_c('span', {
          staticClass: "sm-mar-right"
        }, [_vm._v("Appearance")]), _c('vb-svg', {
          staticClass: "callActivity-AdjustDesign-v3",
          attrs: {
            "name": "filter-icon",
            "width": "19",
            "height": "18",
            "viewBox": "0 0 19 18",
            "stroke-width": "0",
            "stroke": "",
            "fill": "none",
            "stroke-linecap": "round",
            "stroke-linejoin": "round"
          }
        }), _c('span', {
          staticClass: "sr-only"
        }, [_vm._v("Appearance")])];
      },
      proxy: true
    }])
  }, [_c('b-dropdown-form', {
    staticClass: "p-12px"
  }, [_c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    attrs: {
      "checked": _vm.GET_LOCAL_SETTING_CHAT.appearence.list_view,
      "name": "checkbox-1",
      "value": "accepted",
      "unchecked-value": "not_accepted"
    },
    on: {
      "change": function ($event) {
        return _vm.SET_LOCAL_SETTING_CHAT_APPEARENCE_LIST_VIEW(_vm.GET_LOCAL_SETTING_CHAT.appearence.list_view == 'accepted' ? 'not_accepted' : 'accepted');
      }
    }
  }, [_vm._v(" Enable compact list view ")]), _c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    attrs: {
      "checked": _vm.GET_LOCAL_SETTING_CHAT.appearence.is_icon_show,
      "name": "checkbox-2",
      "value": "accepted",
      "unchecked-value": "not_accepted"
    },
    on: {
      "change": function ($event) {
        return _vm.SET_LOCAL_SETTING_CHAT_APPEARENCE_ICON_SHOW(_vm.GET_LOCAL_SETTING_CHAT.appearence.is_icon_show == 'accepted' ? 'not_accepted' : 'accepted');
      }
    }
  }, [_vm._v(" Show user icon ")]), _c('b-form-checkbox', {
    staticClass: "makeCheckBoxesSquare",
    attrs: {
      "checked": _vm.GET_LOCAL_SETTING_CHAT.appearence.blink_unread,
      "name": "checkbox-3",
      "value": "accepted",
      "unchecked-value": "not_accepted"
    },
    on: {
      "change": function ($event) {
        return _vm.SET_LOCAL_SETTING_CHAT_APPEARENCE_BLINK_UNREAD(_vm.GET_LOCAL_SETTING_CHAT.appearence.blink_unread == 'accepted' ? 'not_accepted' : 'accepted');
      }
    }
  }, [_vm._v(" Unread message flashing ")])], 1)], 1)], 1)]), _c('div', {
    staticClass: "searchBox mt-2"
  }, [_c('b-form', {
    staticClass: "mx-auto position-relative",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_c('div', {
    class: {
      'chatFocusedSearch-container': _vm.is_searching
    }
  }, [_c('b-form-input', {
    attrs: {
      "placeholder": "Users, teams & groups"
    },
    on: {
      "focus": function ($event) {
        _vm.is_searching = true;
      }
    },
    model: {
      value: _vm.search,
      callback: function ($$v) {
        _vm.search = $$v;
      },
      expression: "search"
    }
  }), _vm.is_searching ? _c('b-icon', {
    staticClass: "position-absolute cursor_pointer",
    attrs: {
      "icon": "x-circle",
      "scale": "1"
    },
    on: {
      "click": function ($event) {
        _vm.is_searching = false;
        _vm.search = '';
      }
    }
  }) : _vm._e()], 1)])], 1), _vm.is_searching ? _c('b-tabs', {
    model: {
      value: _vm.searchtabIndex,
      callback: function ($$v) {
        _vm.searchtabIndex = $$v;
      },
      expression: "searchtabIndex"
    }
  }, [_c('PanelTab', {
    attrs: {
      "totalUnreadTitle": _vm.totalUnread,
      "totalUnread": _vm.totalUnread,
      "recentChannelsUnreadCount": _vm.recentChannelsUnreadCount,
      "recentTeamsUnreadCount": _vm.recentTeamsUnreadCount,
      "recentUsersUnreadCount": _vm.recentUsersUnreadCount,
      "recent_users": _vm.searchedUsers,
      "recent_teams": _vm.searchedTeams,
      "recent_channels": _vm.searchedChannels,
      "text": `Recent`,
      "recent_type": 'separate'
    },
    on: {
      "tab": function ($event) {
        _vm.tab = 'Recent';
      },
      "on-change-search": _vm.search
    }
  }, [_vm._l(_vm.searchedTeams, function (team) {
    return _c('Item', {
      key: team.id,
      attrs: {
        "slot": "recent-team-items",
        "type": 'team',
        "data": team,
        "tabType": "searched"
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "recent-team-items"
    });
  }), _vm._l(_vm.searchedUsers, function (user) {
    return _c('Item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account != user.voipaccount,
        expression: "$store.getters.getCurrentUser.account != user.voipaccount"
      }],
      key: user.voipaccount,
      attrs: {
        "slot": "recent-user-items",
        "type": 'user',
        "data": user,
        "tabType": "searched"
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "recent-user-items"
    });
  }), _vm._l(_vm.searchedChannels, function (channel) {
    return _c('Item', {
      key: channel.id,
      attrs: {
        "slot": "recent-channel-items",
        "type": 'channel',
        "data": channel,
        "tabType": "searched"
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "recent-channel-items"
    });
  })], 2), _c('PanelTab', {
    attrs: {
      "totalUnreadTitle": _vm.teamsUnreadCount,
      "nofav": _vm.searchedTeams,
      "text": `Teams`
    },
    on: {
      "tab": function ($event) {
        _vm.tab = 'Teams';
      },
      "on-change-search": _vm.search
    }
  }, _vm._l(_vm.searchedTeams, function (team) {
    return _c('Item', {
      key: team.id,
      attrs: {
        "slot": "not-fav-items",
        "type": 'team',
        "data": team,
        "tabType": "searched"
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "not-fav-items"
    });
  }), 1), _c('PanelTab', {
    attrs: {
      "totalUnreadTitle": _vm.usersUnreadCount,
      "nofav": _vm.searchedUsers,
      "text": `Users`
    },
    on: {
      "tab": function ($event) {
        _vm.tab = 'Users';
      },
      "on-change-search": _vm.search
    }
  }, _vm._l(_vm.searchedUsers, function (user) {
    return _c('Item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account != user.voipaccount,
        expression: "$store.getters.getCurrentUser.account != user.voipaccount"
      }],
      key: user.voipaccount,
      attrs: {
        "slot": "not-fav-items",
        "type": 'user',
        "data": user,
        "tabType": "searched"
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "not-fav-items"
    });
  }), 1), _c('PanelTab', {
    attrs: {
      "totalUnreadTitle": _vm.channelsUnreadCount,
      "nofav": _vm.searchedChannels,
      "text": `Groups`
    },
    on: {
      "tab": function ($event) {
        _vm.tab = 'Channels';
      },
      "on-change-search": _vm.search
    }
  }, _vm._l(_vm.searchedChannels, function (channel) {
    return _c('Item', {
      key: channel.id,
      attrs: {
        "slot": "not-fav-items",
        "type": 'channel',
        "data": channel,
        "tabType": "searched"
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "not-fav-items"
    });
  }), 1)], 1) : _c('b-tabs', {
    model: {
      value: _vm.tabsIndex,
      callback: function ($$v) {
        _vm.tabsIndex = $$v;
      },
      expression: "tabsIndex"
    }
  }, [_c('PanelTab', {
    attrs: {
      "totalUnreadTitle": _vm.totalUnread,
      "totalUnread": _vm.totalUnread,
      "recentChannelsUnreadCount": _vm.recentChannelsUnreadCount,
      "recentTeamsUnreadCount": _vm.recentTeamsUnreadCount,
      "recentUsersUnreadCount": _vm.recentUsersUnreadCount,
      "recent_users": _vm.recentUsers,
      "recent_teams": _vm.recentTeams,
      "recent_channels": _vm.recentChannels,
      "all_recent": _vm.mixupRecents,
      "text": `Recent`
    },
    on: {
      "tab": function ($event) {
        _vm.tab = 'Recent';
      },
      "on-change-search": _vm.search
    }
  }, [_vm._l(_vm.mixupRecents, function (data) {
    return _c('Item', {
      key: data.voipaccount ? data.voipaccount : data.id,
      attrs: {
        "slot": "recent-all-items",
        "type": data.voipaccount ? 'user' : data.channel_id ? 'team' : 'channel',
        "tabType": "recent",
        "data": data
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "recent-all-items"
    });
  }), _vm.GET_LOCAL_SETTING_CHAT.view.recent_type === 'separate' ? [_vm._l(_vm.recentTeams, function (team) {
    return _c('Item', {
      key: team.id,
      attrs: {
        "slot": "recent-team-items",
        "type": 'team',
        "tabType": "recent",
        "data": team
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "recent-team-items"
    });
  }), _vm._l(_vm.recentUsers, function (user) {
    return _c('Item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account != user.voipaccount,
        expression: "$store.getters.getCurrentUser.account != user.voipaccount"
      }],
      key: user.voipaccount,
      attrs: {
        "slot": "recent-user-items",
        "type": 'user',
        "tabType": "recent",
        "data": user
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "recent-user-items"
    });
  }), _vm._l(_vm.recentChannels, function (channel) {
    return _c('Item', {
      key: channel.id,
      attrs: {
        "slot": "recent-channel-items",
        "type": 'channel',
        "tabType": "recent",
        "data": channel
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "recent-channel-items"
    });
  })] : _vm._e()], 2), _c('PanelTab', {
    attrs: {
      "totalUnreadTitle": _vm.teamsUnreadCount,
      "fav": _vm.favoritTeams,
      "nofav": _vm.notFavoriteTeams,
      "text": `Teams`,
      "tabType": "recent"
    },
    on: {
      "tab": function ($event) {
        _vm.tab = 'Teams';
      },
      "on-change-search": _vm.search
    }
  }, [_vm._l(_vm.favoritTeams, function (team) {
    return _c('Item', {
      key: team.id,
      attrs: {
        "slot": "fav-items",
        "type": 'team',
        "tabType": "not recent",
        "data": team
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "fav-items"
    });
  }), _vm._l(_vm.notFavoriteTeams, function (team) {
    return _c('Item', {
      key: team.id,
      attrs: {
        "slot": "not-fav-items",
        "type": 'team',
        "tabType": "not recent",
        "data": team
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "not-fav-items"
    });
  })], 2), _c('PanelTab', {
    attrs: {
      "totalUnreadTitle": _vm.usersUnreadCount,
      "fav": _vm.favoritUsers,
      "nofav": _vm.notFavoriteUsers,
      "text": `Users`
    },
    on: {
      "tab": function ($event) {
        _vm.tab = 'Users';
      },
      "on-change-search": _vm.search
    }
  }, [_vm._l(_vm.favoritUsers, function (user) {
    return _c('Item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account != user.voipaccount,
        expression: "$store.getters.getCurrentUser.account != user.voipaccount"
      }],
      key: user.voipaccount,
      attrs: {
        "slot": "fav-items",
        "type": 'user',
        "tabType": "not recent",
        "data": user
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "fav-items"
    });
  }), _vm._l(_vm.notFavoriteUsers, function (user) {
    return _c('Item', {
      directives: [{
        name: "show",
        rawName: "v-show",
        value: _vm.$store.getters.getCurrentUser.account != user.voipaccount,
        expression: "$store.getters.getCurrentUser.account != user.voipaccount"
      }],
      key: user.voipaccount,
      attrs: {
        "slot": "not-fav-items",
        "type": 'user',
        "tabType": "not recent",
        "data": user
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "not-fav-items"
    });
  })], 2), _c('PanelTab', {
    attrs: {
      "totalUnreadTitle": _vm.channelsUnreadCount,
      "fav": _vm.favoritChannels,
      "nofav": _vm.notFavoriteChannels,
      "isShowAdd": "",
      "text": `Groups`
    },
    on: {
      "tab": function ($event) {
        _vm.tab = 'Channels';
      },
      "add": function ($event) {
        return _vm.$modal.show('AddChannelModal');
      },
      "on-change-search": _vm.search
    }
  }, [_vm._l(_vm.favoritChannels, function (channel) {
    return _c('Item', {
      key: channel.id,
      attrs: {
        "slot": "fav-items",
        "type": 'channel',
        "tabType": "not recent",
        "data": channel
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "fav-items"
    });
  }), _vm._l(_vm.notFavoriteChannels, function (channel) {
    return _c('Item', {
      key: channel.id,
      attrs: {
        "slot": "not-fav-items",
        "type": 'channel',
        "tabType": "not recent",
        "data": channel
      },
      on: {
        "change-channel-id": function ($event) {
          return _vm.$emit('change-channel-id', $event);
        }
      },
      slot: "not-fav-items"
    });
  })], 2)], 1), _c('AddChannelModal', {
    attrs: {
      "team_id": _vm.$store.getters.getCurrentUser.mm_team_id
    },
    on: {
      "complete": _vm.onCompleteAddingChannel
    }
  })], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }