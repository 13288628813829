var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable100VHcustom bigSquareCheckbox min-width-50percent-modal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-blocked"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form",
    attrs: {
      "novalidate": ""
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.editnumber();
      }
    }
  }, [_vm.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Edit blocked number")]), _c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.edit_number.send ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header position-relative"
  }, [_c('h2', {
    staticClass: "dialer-settings-title newer mb-0 d-flex align-items-center"
  }, [_c('a', {
    staticClass: "newBackButton mr-16px",
    attrs: {
      "type": "button",
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Edit blocked number ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.edit_number.send ? _c('vb-spinner') : [_vm._v("Done")]], 2)], 1)])]), _c('b-alert', {
    attrs: {
      "show": !!_vm.api.edit_number.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.edit_number.error_message))]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', {
    staticClass: "dialer-input-label"
  }, [_vm._v("Number")]), _c('input', {
    directives: [{
      name: "vb-input-number-formate",
      rawName: "v-vb-input-number-formate"
    }, {
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_number.callerid,
      expression: "forms.edit_number.callerid"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send
    },
    domProps: {
      "value": _vm.forms.edit_number.callerid
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_number, "callerid", $event.target.value);
      }
    }
  }), _vm.$v.forms.edit_number.callerid.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.edit_number.callerid.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.edit_number.callerid.validNumber ? _c('span', [_vm._v("* Number is not valid")]) : _vm._e()]) : _vm._e(), _vm.api.edit_number.validation_errors.callerid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.edit_number.validation_errors.callerid, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder w-100"
  }, [_c('label', {
    staticClass: "dialer-input-label"
  }, [_vm._v("Reason for blocking")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_number.displayname,
      expression: "forms.edit_number.displayname"
    }],
    staticClass: "w-100",
    attrs: {
      "type": "text",
      "maxlength": _vm.$v.forms.edit_number.displayname.$params.maxLength.max,
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send
    },
    domProps: {
      "value": _vm.forms.edit_number.displayname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_number, "displayname", $event.target.value);
      }
    }
  }), _vm.$v.forms.edit_number.displayname.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.edit_number.displayname.required ? _c('span', [_vm._v("* Reason is required")]) : _vm._e(), !_vm.$v.forms.edit_number.displayname.maxLength ? _c('span', [_vm._v("* Reason can be maximum " + _vm._s(_vm.$v.forms.edit_number.displayname.$params.maxLength.max) + " characters")]) : _vm._e()]) : _vm._e(), _vm.api.edit_number.validation_errors.displayname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.edit_number.validation_errors.displayname, function (em, i) {
    return _c('span', {
      key: i
    }, [_vm._v("* " + _vm._s(em))]);
  }), 0) : _vm._e()])])]), _c('div', {
    staticClass: "latestGreyBox-heading-main w-100 mt-20px"
  }, [_vm._v("Direction of number block")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText w-100"
  }, [_vm._v(" You can choose which direction you'd like to block this number. Inbound will prevent calls coming in whereas, Outbound will prevent calls being made to that number. Selecting both In and Outbound will prevent calls to or from this number. ")]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main w-100"
  }, [_vm._v("Block inbound calls")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send,
      "checked": ['both', 'in'].includes(_vm.forms.edit_number.direction)
    },
    on: {
      "change": function ($event) {
        _vm.forms.edit_number.direction = $event ? _vm.forms.edit_number.direction == 'out' ? 'both' : 'in' : _vm.forms.edit_number.direction == 'both' ? 'out' : '';
      }
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main w-100"
  }, [_vm._v("Block outbound calls")]), _c('b-form-checkbox', {
    staticClass: "newerSwitch",
    attrs: {
      "switch": "",
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send,
      "checked": ['both', 'out'].includes(_vm.forms.edit_number.direction)
    },
    on: {
      "change": function ($event) {
        _vm.forms.edit_number.direction = $event ? _vm.forms.edit_number.direction == 'in' ? 'both' : 'out' : _vm.forms.edit_number.direction == 'both' ? 'in' : '';
      }
    }
  })], 1), _vm.$v.forms.edit_number.direction.$error ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.edit_number.direction.required ? _c('span', [_vm._v("* Direction is required")]) : _vm._e()]) : _vm._e()]), _c('div', {
    staticClass: "mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Remove from block list")]), _c('button', {
    staticClass: "newDeleteButton",
    attrs: {
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send,
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.deleteInbound();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-delete-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDeleteButton-textPart"
  }, [_vm._v("Remove")])], 1)])])])])], 1)])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }