var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "header"
  }, [_c('h3', [_vm._v("History")]), _c('div', [_c('b-button', {
    on: {
      "click": function ($event) {
        return _vm.downloadFile();
      }
    }
  }, [_vm._v("Download Logs")])], 1), _c('vb-table', {
    attrs: {
      "isListEmpty": _vm.response.history.length == 0,
      "listLength": _vm.response.history.length,
      "loading": _vm.api.fetch_history.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.response.history, function (histo, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: histo.id
          }, [_c('td', {
            staticClass: "dialer-col-center wd-25",
            attrs: {
              "td": ""
            }
          }, [_vm._v(" " + _vm._s(histo.createdBy.lastName) + " ")]), _c('td', [_vm._v(_vm._s(histo.description))]), _c('td', [_vm._v(_vm._s(histo.performedOn))]), _c('td', {
            staticClass: "dialer-col-center wd-25",
            attrs: {
              "td": ""
            }
          }, [_vm._v(_vm._s(histo.createdAt))])]);
        });
      }
    }])
  }, [_c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-center"
  }, [_c('span', [_vm._v("Action")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Performed On")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Performed At")])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }