var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-box alt position-relative"
  }, [_c('div', {
    staticClass: "dialer-flex"
  }, [_c('div', {
    staticClass: "dialer-box-header"
  }, [_vm._v("Incoming call presentation")]), _c('div', {
    staticClass: "dialer-audio-options d-flex"
  }, [_c('div', {
    staticClass: "row"
  }, [_c('div', {
    staticClass: "col-12 col-md-12"
  }, [_c('div', {
    staticClass: "form-group mb-0"
  }, [_c('div', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip"
    }],
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.incoming_call_display
    },
    attrs: {
      "title": _vm.getUserPermissions.incoming_call_display ? '' : 'Your package does not support this feature'
    }
  }, [_c('div', {
    staticClass: "w-fit-content",
    class: {
      'for-disable': !_vm.getUserPermissions.incoming_call_display
    }
  }, [_c('button', {
    staticClass: "newButton",
    attrs: {
      "disabled": !_vm.getUserPermissions.incoming_call_display || _vm.disabled
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.show(`${_vm.accountcode}-IncommingcallDisplaySettingModal`, {
          account: _vm.accountcode
        });
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-change-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Change")])], 1)])])])])])])]), _vm._m(0), _c('div', {
    staticClass: "dialer-flex dialer-call-options mt-3"
  }, [_c('div', {
    staticClass: "selectedText-big"
  }, [_vm._v(_vm._s(_vm._f("clidText")(_vm._f("get_property")(_vm.response.user_detail, 'select_clid_display'))))])]), _c('IncommingcallDisplaySettingModal', {
    attrs: {
      "modalName": `${_vm.accountcode}-IncommingcallDisplaySettingModal`
    },
    on: {
      "latest": function ($event) {
        return _vm.fetchUserDetail();
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-flex"
  }, [_c('div', {
    staticClass: "dialer-box-text mb-0"
  }, [_vm._v("You will be set incoming CLID presentation option.")])]);

}]

export { render, staticRenderFns }