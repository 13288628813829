import { TokenService } from '@/services/storage.service';
import createPersistedState from 'vuex-persistedstate';
import presistedStateStorage from './helper/storage';


export default createPersistedState({
  key: TokenService.CRM_DATA.key,
  paths: [
    `crm.token`,
    `crm.api_url`,
  ],
  storage: presistedStateStorage(TokenService.CRM_DATA)
});