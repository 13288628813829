var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _vm.getIsMobile ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-12px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard insideWebApp"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "basicIOSIcon mr-16px"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "latest-mobileLinkedCall-icon"
    }
  })], 1), _vm._m(0)]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('vb-icon', {
    staticClass: "rightFacingArrow",
    attrs: {
      "icon": "rightFacingArrow-IOSIcon",
      "width": "7.066",
      "height": "11.57"
    },
    on: {
      "click": function ($event) {
        _vm.disabled ? '' : _vm.$emit('call-linking-modal-show', {
          task_id: _vm.task_id,
          cdr_id: _vm.cdr_id
        });
      }
    }
  })], 1)])])]) : _c('div', {
    staticClass: "d-flex flex-column align-items-start mt-45px"
  }, [_c('div', {
    staticClass: "TODO-innerHeading"
  }, [_vm._v(" Linked calls ")]), !_vm.isEmpty(_vm.response.call_log) ? _c('Item1', {
    staticClass: "mt-16px",
    attrs: {
      "call": _vm.response.call_log
    },
    on: {
      "onClickInfo1": function ($event) {
        return _vm.$modal.show(`${_vm._uid}-TaskCallInfoModalNew`, {
          callInfo: _vm.response.call_log
        });
      }
    }
  }) : _vm._e(), _c('div', {
    staticClass: "d-flex align-items-center mt-16px",
    on: {
      "click": function ($event) {
        _vm.disabled ? '' : _vm.$emit('call-linking-modal-show', {
          task_id: _vm.task_id,
          cdr_id: _vm.cdr_id
        });
      }
    }
  }, [_c('div', {
    staticClass: "linkCallToTicket-text mr-16px"
  }, [_vm._v("Link a call to the ticket")]), _c('vb-icon', {
    staticClass: "cursor_pointer_no_hover",
    attrs: {
      "icon": "linkCallToTicket-plus-icon",
      "height": "45px",
      "width": "45px"
    }
  })], 1), _c('CallInfoModalNew', {
    attrs: {
      "modalName": `${_vm._uid}-TaskCallInfoModalNew`
    },
    on: {
      "latest-record": function ($event) {
        return _vm.fetchCallActivity();
      }
    }
  })], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "d-flex flex-column"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v(" Linked calls ")])]);

}]

export { render, staticRenderFns }