var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_vm.conditions.screen == _vm.screens.edit ? [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        _vm.api.delete_number.send ? '' : _vm.conditions.screen = _vm.screens.view;
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Blocked Number")]), _c('div', {
    staticClass: "plus-IOSIcon-container"
  })]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('form', {
    attrs: {
      "novalidate": ""
    },
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.editnumber();
      }
    }
  }, [_c('b-alert', {
    attrs: {
      "show": !!_vm.api.edit_number.error_message,
      "variant": "danger"
    }
  }, [_vm._v(_vm._s(_vm.api.edit_number.error_message))]), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Number")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_number.callerid,
      expression: "forms.edit_number.callerid"
    }],
    attrs: {
      "type": "text",
      "onkeypress": "return /[0-9]/i.test(event.key)",
      "maxlength": "12",
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send,
      "label": "Number"
    },
    domProps: {
      "value": _vm.forms.edit_number.callerid
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_number, "callerid", $event.target.value);
      }
    }
  }), _vm.forms.edit_number.submitted ? [_vm.$v.forms.edit_number.callerid.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.edit_number.callerid.required ? _c('span', [_vm._v("* Number is required")]) : !_vm.$v.forms.edit_number.callerid.numeric ? _c('span', [_vm._v("* Number is can only be numeric character")]) : !_vm.$v.forms.edit_number.callerid.minLength ? _c('span', [_vm._v(" * Number is should be minimum " + _vm._s(_vm.$v.forms.edit_number.callerid.$params.minLength.min) + " character ")]) : !_vm.$v.forms.edit_number.callerid.maxLength ? _c('span', [_vm._v("* Number is can be maximum " + _vm._s(_vm.$v.forms.edit_number.callerid.$params.maxLength.max) + " character")]) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.api.edit_number.validation_errors ? [_vm.api.edit_number.validation_errors.callerid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.edit_number.validation_errors.callerid, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()] : _vm._e()], 2)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "mobileInputIOS InputIosV2"
  }, [_c('label', [_vm._v("Label")]), _c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.forms.edit_number.displayname,
      expression: "forms.edit_number.displayname"
    }],
    attrs: {
      "type": "text",
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send,
      "label": "Label ",
      "value": ""
    },
    domProps: {
      "value": _vm.forms.edit_number.displayname
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.$set(_vm.forms.edit_number, "displayname", $event.target.value);
      }
    }
  }), _vm.forms.edit_number.submitted ? [_vm.$v.forms.edit_number.displayname.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, [!_vm.$v.forms.edit_number.displayname.required ? _c('span', [_vm._v("* Label is required")]) : _vm._e()]) : _vm._e()] : _vm._e(), _vm.api.edit_number.validation_errors ? [_vm.api.edit_number.validation_errors.displayname ? _c('p', {
    staticClass: "text text-danger animated bounceIntop"
  }, _vm._l(_vm.api.edit_number.validation_errors.displayname, function (error_message, index) {
    return _c('span', {
      key: index
    }, [_vm._v("* " + _vm._s(error_message))]);
  }), 0) : _vm._e()] : _vm._e()], 2)])]), _c('div', {
    staticClass: "textAboveCard"
  }, [_vm._v("Direction Of Number Block")]), _c('div', {
    staticClass: "basicWhiteIOScard"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Block Inbound Calls")])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send,
      "switch": ""
    },
    model: {
      value: _vm.forms.edit_number.inbound,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_number, "inbound", $$v);
      },
      expression: "forms.edit_number.inbound"
    }
  })], 1)]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Block Outbound Calls")])]), _c('div', {
    staticClass: "position-relative"
  }, [_c('b-form-checkbox', {
    staticClass: "dialer-switch IosSwitch",
    attrs: {
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send,
      "switch": ""
    },
    model: {
      value: _vm.forms.edit_number.outbound,
      callback: function ($$v) {
        _vm.$set(_vm.forms.edit_number, "outbound", $$v);
      },
      expression: "forms.edit_number.outbound"
    }
  })], 1)])]), _c('button', {
    staticClass: "IosFullWidthButton forDone mt-16px mb-16px",
    attrs: {
      "disabled": _vm.api.edit_number.send || _vm.api.delete_number.send
    }
  }, [_vm.api.edit_number.send ? _c('vb-spinner') : [_vm._v(" Done ")]], 2), _c('button', {
    staticClass: "IosFullWidthButton forRemove mt-16px mb-16px",
    attrs: {
      "disabled": _vm.api.delete_number.send
    },
    on: {
      "click": function ($event) {
        return _vm.deleteInbound();
      }
    }
  }, [[_vm._v(" Delete ")]], 2), _c('div', {
    staticClass: "textBelowCard"
  }, [_vm._v(" You can choose which direction you'd like to block this number. Inbound will "), _c('br'), _vm._v("prevent calls coming in whereas, ")]), _c('div', {
    staticClass: "textBelowCard"
  }, [_vm._v(" Outbound will prevent calls being made to that "), _c('br'), _vm._v("number. ")]), _c('div', {
    staticClass: "textBelowCard"
  }, [_vm._v(" Selecting both In and Outbound will prevent calls to or from this number. ")])], 1)])] : [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Edit Blocked Number")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        _vm.conditions.screen = _vm.screens.edit;
      }
    }
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide primaryColor"
  }, [_vm._v("Edit")])])]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Label")]), _c('div', {
    staticClass: "textInsideCard onRightSide"
  }, [_vm._v(_vm._s(_vm.data.number.displayname))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Number")]), _c('div', {
    staticClass: "textInsideCard onRightSide"
  }, [_vm._v(_vm._s(_vm.data.number.callerid))])]), _c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Block")]), _c('div', {
    staticClass: "textInsideCard onRightSide"
  }, [_vm._v(_vm._s(_vm.data.number.type))])])])])]], 2)], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }