<template>
  <modal 
    class="NotesAndTagsModalInsideCallInfoModal"
    :name="modalName"
    @before-open="onBeforeOpen($event)"
    @before-close="onBeforeClose()"
  >
    <div>
      <div class="dialer-edit-header position-relative py-0 justify-content-between align-items-center">
        <h2 class="dialer-edit-title my-0">
          Update notes and tags
        </h2>
        <div class="dialer-edit-actions">
          <a class="newCloseButton" @click="$modal.hide(modalName)" type="button" >
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </a>
        </div>
      </div>
      <div class="latestGreyBox-9-9-2023 mt-20px">
        <div class="whiteBoxWithBorderInsideGreyBox">
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
            <div class="latestGreyBox-heading-main">Call tags</div>
            <div class="latestGreyBox-9-9-2023 w-100 mt-20px">
              <b-form class="whiteBGinputWithGreyRoundedBorder" @submit.prevent="''">
                <b-form-input placeholder="Search Tags" v-model="filter.tag.search" />
              </b-form>
              <p v-for="(em, i) in api.tag.validation_errors.log" :key="i">{{ em }}</p>
              <!-- <div class="d-flex flex-wrap w-100 mt-20px">
                <div
                  v-for="tag in filterTags" 
                  :key="tag.real_id" 
                  @click="api.tag.send ? '' : forms.tag.tags.includes(tag.real_id) ? forms.tag.tags.splice(forms.tag.tags.indexOf(tag.real_id),1) : forms.tag.tags.push(tag.real_id)" 
                  :class="`${forms.tag.tags.includes(tag.real_id)?'selected':''} latestTagDesignLayout`" 
                  v-vb-tag-color.border="forms.tag.tags.includes(tag.real_id) && tag.colour"
                >
                  <small v-vb-tag-color="tag.colour" :style="`border-color: ${forms.tag.tags.includes(tag.real_id)?'#3699FF':''}`">
                    <span v-vb-tag-color.text="forms.tag.tags.includes(tag.real_id) && tag.text_color">{{ tag.tag }}</span>
                  </small>
                </div>
                <div class="latestGreyBox-descriptionText" v-if="isEmpty(filterTags)">There is no tags</div>
              </div> -->
              <div class="d-flex flex-wrap w-100 mt-20px">
                <div
                  v-for="tag in filterTags" 
                  :key="tag.real_id" 
                  @click="
                    api.tag.send ? '' :
                    forms.tag.tags.includes(tag.real_id) ?
                      forms.tag.tags.splice(forms.tag.tags.indexOf(tag.real_id),1) :
                      forms.tag.tags.push(tag.real_id)" 
                  :class="`${forms.tag.tags.includes(tag.real_id)?'selected':''} latestTagDesignLayout`" 
                >
                  <!-- v-vb-tag-color.border="forms.tag.tags.includes(tag.real_id) && tag.colour" -->
                  <small v-vb-tag-color="tag.colour" :style="`border-color: ${forms.tag.tags.includes(tag.real_id)?'#3699FF':''}`">
                    <!-- forms.tag.tags.includes(tag.real_id) &&  -->
                    <span v-vb-tag-color.text="tag.text_color">
                      {{ tag.tag }}
                    </span>
                  </small>
                </div>
                <div class="latestGreyBox-descriptionText" v-if="isEmpty(filterTags)">There is no tags</div>
              </div>
              <p class="latestGreyBox-descriptionText" v-for="(em, i) in api.tag.validation_errors.log" :key="i">{{ em }}</p>
              <div class="d-flex justify-content-end mt-20px">
                <b-button :disabled="api.tag.send" class="newDoneButton" variant="primary" @click="updatetag()">
                  <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                  <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                    <vb-spinner v-if="api.tag.send" />
                    <template v-else>
                      Submit
                    </template>
                  </span>
                </b-button>
              </div>
            </div>
          </div>
        </div>
        <div class="whiteBoxWithBorderInsideGreyBox mt-20px">
          <div class="whiteBoxWithBorderInsideGreyBox-row flex-column align-items-start justify-content-center">
            <div class="latestGreyBox-heading-main">Call notes</div>
              <div class="latestGreyBox-9-9-2023 w-100 mt-20px">
                <div class="whiteBGinputWithGreyRoundedBorder w-100">
                  <label>Write some note for this call</label>
                  <b-textarea :disabled="api.note.send" rows="10" no-resize placeholder="Enter Note" v-model="forms.note.note" class="w-100" />
                </div>
                <div class="d-flex justify-content-end mt-20px">
                  <!-- <b-button :disabled="api.note.send" variant="primary" class="dialer-button-primary dialer-button" @click="updatenote()">
                    <vb-spinner v-if="api.note.send" />
                    <template v-else>Submit</template>
                  </b-button> -->
                  <b-button :disabled="api.note.send" class="newDoneButton" variant="primary" @click="updatenote()">
                    <vb-icon icon="squared-submit-icon" class="submitIcon" height="38px" width="38px" />
                    <span class="newDoneButton-textPart d-flex justify-content-center position-relative">
                      <vb-spinner v-if="api.note.send" />
                      <template v-else>
                        Submit
                      </template>
                    </span>
                  </b-button>
                </div>
              </div>
          </div>
        </div>
      </div>
      <div>
        
      </div>
    </div>
  </modal>
</template>

<script>
import { VOIP_API } from '@/utils';
import { mapGetters } from 'vuex';
import _ from 'lodash';
export default {
  name: 'UpdateCallNotesAndTagsModal',
  inject: [
    'isEmpty','appNotify'
  ],
  props: {
    modalName: {
      type: String,
      default: 'UpdateCallNotesAndTags'
    },
    callID: {
      type: String,
      default: ''
    },
  },
  data() {
    return {
      api: {
        tag: this.$helperFunction.apiInstance({
          status: true,
          error_message: true,
          validation_errors: true,
        }),
        note: this.$helperFunction.apiInstance({
          status: true,
          error_message: true,
          validation_errors: true,
        }),
      },
      forms: {
        note: this.$helperFunction.formInstance({
          data: {
            note: '',
          },
        }),
        tag: this.$helperFunction.formInstance({
          data: {
            tags: [],
          },
        }),
      },
      filter: {
        tag: this.$helperFunction.stateInstance({
          data: {
            search: '',
          },
        }),
      },
      data: {

      },
    }
  },
  computed: {
    ...mapGetters([
      'getVoipCallTags',
      'getCurrentUser'
    ]),
    filterTags() {
      const search = this.filter.tag.search.toLowerCase().trim()
      return this.getVoipCallTags.filter(item => `${item.tag}`.toLowerCase().trim().includes(search));
    },
  },
  methods: {
    onBeforeOpen(event){
      // console.log(event)
      this.forms.note.note=event?.params?.note || ''
      this.forms.tag.tags=event?.params?.tags?.map?.(i=>i.real_id) || []
    },
    onBeforeClose(){
      // TODO - 
    },
    async updatetag() {
      this.forms.tag.submitted=true
      if (this.api.tag.send) return;
      try {
        this.api.tag.send = true;
        this.api.tag.error_message = '';
        this.api.tag.validation_errors = {};
        this.api.tag.status = 0;
        await VOIP_API.endpoints.call_activity.calltagsnotes({
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
          id: this.callID,
          log: this.forms.tag.tags.toString(),
          type: "tag",
        })
        this.api.tag.status = 1;
        this.$emit('tags-updated',{
          tag_ids: _.cloneDeep(this.forms.tag.tags)
        })
        this.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
      } catch (ex) {
        this.api.tag.status = 2;
        this.api.tag.error_message = ex.own_message
        this.api.tag.validation_errors = ex.own_errors
        this.appNotify({
          message: this.api.tag.error_message,
          type: 'danger',
        })
      } finally {
        this.api.tag.send = false;
        this.forms.tag.submitted=false
      }
    },
    async updatenote() {
      this.forms.note.submitted=true
      if (this.api.note.send) return;
      try {
        this.api.note.send = true;
        this.api.note.error_message = '';
        this.api.note.validation_errors = {};
        this.api.note.status = 0;
        await VOIP_API.endpoints.call_activity.calltagsnotes({
          uid: this.getCurrentUser?.uid,
          accountcode: this.getCurrentUser?.account,
          id: this.callID,
          log: this.forms.note.note,
          type: "note",
        })
        this.api.note.status = 1;
        this.appNotify({
          message: 'Successfully updated',
          type: 'success',
        })
        this.$emit('note-updated',{
          note: this.forms.note.note
        })
      } catch (ex) {
        this.api.note.status = 2;
        this.api.note.error_message = ex.own_message
        this.api.note.validation_errors = ex.own_errors
        this.appNotify({
          message: this.api.note.error_message,
          type: 'danger',
        })
      } finally {
        this.api.note.send = false;
        this.forms.note.submitted=false
      }
    },
  },
}
</script>

<style>

</style>