<template>
    <modal class="dialer_animation right_side_popup global-modal-hrm  Scrollable-hrm" name="HRMAddEmergencyContactModal"  :clickToClose="true"  @before-open="beforeOpen" :scrollable="true">
      <form @submit.prevent="''" class="dialer-form ">
          <div class="dialer-edit-header">
            <h2 class="dialer-edit-title">Add Contact</h2>
            <div class="dialer-edit-actions">
              <b-icon v-if="$store.getters.getIsMobile" class="cursor_pointer" icon="x" font-scale="1.8" @click="$modal.hide('HRMAddEmergencyContactModal')" />
              <a id="CloseModalButton" v-else class="dialer-button dialer-button-delete" @click="$modal.hide('HRMAddEmergencyContactModal')">Close</a>
            </div>
          </div>
          <div class="sp-gm-if">
            <label for="Street" class="gm-hrm-label">Full name</label>
            <b-form-input  placeholder="" class="gm-hrm-fields"></b-form-input>
          </div>
          <div class="sp-gm-if">
            <label class="gm-hrm-label">Relationship</label>
            <div class="dd-gm-container">
              <b-dropdown no-caret class="gm-mainBorderfordropi">
                <template #button-content>
                  <div class="gm-button-content-dropi">
                    <div class="gm-dropi-contenti">Relationship</div>
                    <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
                  </div>
                </template>
                <b-dropdown-item class="dropi-dropdown-toggle">Brother</b-dropdown-item>
                <b-dropdown-item class="dropi-dropdown-toggle">Sister</b-dropdown-item>
              </b-dropdown>
            </div>
         </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Home phone</label>
          <b-form-input  placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Mobile phone</label>
          <b-form-input   placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">E-mail</label>
          <b-form-input   placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">City</label>
          <b-form-input list="input-list"  placeholder="25090" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label class="gm-hrm-label">Country</label>
          <div class="dd-gm-container">
          <b-dropdown no-caret class="gm-mainBorderfordropi">
            <template #button-content>
              <div class="gm-button-content-dropi">
                <div class="gm-dropi-contenti">Country</div>
                <b-icon class="dropi-icon" icon="caret-down-fill"></b-icon>
              </div>
            </template>
            <b-dropdown-item class="dropi-dropdown-toggle">USA</b-dropdown-item>
            <b-dropdown-item class="dropi-dropdown-toggle">UK</b-dropdown-item>
          </b-dropdown>
        </div>
        </div>
        <div class="sp-gm-if">
          <label for="Street " class="gm-hrm-label">Address 1</label>
          <b-form-input list="input-list"  placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Address 2</label>
          <b-form-input list="input-list"  placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">City</label>
          <b-form-input list="input-list"  placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Region</label>
          <b-form-input list="input-list"  placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <div class="sp-gm-if">
          <label for="Street" class="gm-hrm-label">Postcode</label>
          <b-form-input list="input-list"  placeholder="" class="gm-hrm-fields"></b-form-input>
        </div>
        <button class="btn-primarynewupdated1 mt-4 mb-4">Add</button>
      </form>
    </modal>
</template>
<script>

export default {
  name: 'HRMAddEmergencyContactModal',
}
</script>

<style>

</style>