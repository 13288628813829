import { CLEAR_ERRORS, RESET_API_ERRORS/*, RESET_CENTRIFUGO_ERRORS*/ } from "../helper/actions";
import { GET_API_ERRORS, /*GET_CENTRIFUGO_ERRORS,*/ GET_STORE_API_ERRORS/*, GET_STORE_CENTRIFUGO_ERRORS*/ } from "../helper/getters";
import { ADD_API_ERROR, /*ADD_CENTRIFUGO_ERROR,*/ SET_API_ERRORS/*, SET_CENTRIFUGO_ERRORS*/, SET_STORE_API_ERRORS/*, SET_STORE_CENTRIFUGO_ERRORS*/ } from "../helper/mutations";

export default {
  state: {
    api_errors: [],
    api_store_errors: [],
    // centrifugo_errors: [],
    // centrifugo_store_errors: [],
  },
  getters: {
    [GET_API_ERRORS](state){ return state.api_errors },
    [GET_STORE_API_ERRORS](state){ return state.api_store_errors },
    // [GET_CENTRIFUGO_ERRORS](state){ return state.centrifugo_errors },
    // [GET_STORE_CENTRIFUGO_ERRORS](state){ return state.centrifugo_store_errors },
  },
  actions: {
    [RESET_API_ERRORS](context){
      context.commit(SET_STORE_API_ERRORS,[...context.state.api_errors,...context.state.api_store_errors])
      context.commit(SET_API_ERRORS)
    },
    // [RESET_CENTRIFUGO_ERRORS](context){
    //   context.commit(SET_STORE_CENTRIFUGO_ERRORS,[...context.state.centrifugo_errors,...context.state.centrifugo_store_errors])
    //   context.commit(SET_CENTRIFUGO_ERRORS)
    // },
    [CLEAR_ERRORS](context){
      // context.commit(SET_STORE_CENTRIFUGO_ERRORS)
      // context.commit(SET_CENTRIFUGO_ERRORS)
      context.commit(SET_STORE_API_ERRORS)
      context.commit(SET_API_ERRORS)
    },
  },
  mutations: {
    [SET_STORE_API_ERRORS](state,data){ 
      state.api_store_errors=data ?? []
    },
    [SET_API_ERRORS](state,data){ 
      state.api_errors=data ?? []
    },
    [ADD_API_ERROR](state,data){ 
      state.api_errors.push(data)
    },
    // [SET_STORE_CENTRIFUGO_ERRORS](state,data){ 
    //   state.centrifugo_store_errors=data ?? []
    // },
    // [SET_CENTRIFUGO_ERRORS](state,data){ 
    //   state.centrifugo_errors=data ?? []
    // },
    // [ADD_CENTRIFUGO_ERROR](state,data){ 
    //   state.centrifugo_errors.push(data)
    // },
  },
};