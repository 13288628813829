var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-analytics-main dialer-analytics-reports"
  }, [_c('div', {
    staticClass: "dialer-box"
  }, [_c('header', {
    staticClass: "dialer-analytics-titleBar"
  }, [_vm.$store.getters.getIsMobile ? [_c('div', {
    staticClass: "dialer-analytics-title calc-title-width-analytics"
  }, [_c('b-icon', {
    staticClass: "settings-back-icon cursor_pointer",
    attrs: {
      "icon": "chevron-left",
      "font-scale": "1.5"
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }), _c('h1', {}, [_vm._v("Reporting")])], 1)] : _c('div', {
    staticClass: "dialer-analytics-title"
  }, [_c('b-icon', {
    attrs: {
      "icon": "people",
      "variant": "primary"
    }
  }), _c('h1', {
    staticClass: "md-mar-left"
  }, [_vm._v("Reporting")])], 1)], 2), _c('div', {
    staticClass: "dialer-analytics-reporting-filter my-4"
  }, [_c('div', {
    staticClass: "dialer-analyticsReportingFilter-inner dialer-flex align-items-start"
  }, [_c('div', {
    staticClass: "column col1 dialer-input-field input-consistent-inner-shadows"
  }, [_c('label', {
    staticClass: "dialer-input-label pl-2"
  }, [_vm._v("Search number")]), _c('div', {
    staticClass: "dialer-input-field"
  }, [_c('input', {
    directives: [{
      name: "model",
      rawName: "v-model",
      value: _vm.number,
      expression: "number"
    }],
    staticClass: "lighter-placeholder",
    attrs: {
      "type": "text",
      "placeholder": "Source or Destination Number"
    },
    domProps: {
      "value": _vm.number
    },
    on: {
      "input": function ($event) {
        if ($event.target.composing) return;
        _vm.number = $event.target.value;
      }
    }
  })])]), _c('div', {
    staticClass: "column col2 dialer-input-field input-consistent-inner-shadows VueCtkDateTimePicker no-label"
  }, [_c('label', {
    staticClass: "dialer-input-label pl-2"
  }, [_vm._v("Select date range")]), _c('vue-ctk-date-time-picker', _vm._b({
    staticClass: "lighter-placeholder",
    attrs: {
      "label": _vm.label,
      "format": _vm.format,
      "noButton": false,
      "range": true
    },
    model: {
      value: _vm.daterange,
      callback: function ($$v) {
        _vm.daterange = $$v;
      },
      expression: "daterange"
    }
  }, 'vue-ctk-date-time-picker', _vm.ctkDateTimePickerOptions, false))], 1), _c('div', {
    staticClass: "column col3 dialer-input-field input-consistent-inner-shadows"
  }, [_c('label', {
    staticClass: "dialer-input-label pl-2"
  }, [_vm._v("Call type")]), _c('v-select', {
    staticClass: "cursor_pointer2 lighter-placeholder",
    attrs: {
      "aria-disabled": _vm.api_sent.extensions_list,
      "options": _vm.types,
      "placeholder": "Received, dialed and missed"
    },
    model: {
      value: _vm.callType,
      callback: function ($$v) {
        _vm.callType = $$v;
      },
      expression: "callType"
    }
  })], 1), _c('div', {
    staticClass: "column col4 dialer-input-field input-consistent-inner-shadows"
  }, [_c('label', {
    staticClass: "dialer-input-label pl-2"
  }, [_vm._v("User & extension")]), _c('vSelect', {
    staticClass: "cursor_pointer2 lighter-placeholder",
    attrs: {
      "multiple": "",
      "aria-disabled": _vm.api_sent.extensions_list,
      "options": _vm.agents,
      "placeholder": "One user with multiple extensions, Limit."
    },
    model: {
      value: _vm.selected,
      callback: function ($$v) {
        _vm.selected = $$v;
      },
      expression: "selected"
    }
  })], 1), _c('div', {
    staticClass: "column col4 dialer-input-field input-consistent-inner-shadows"
  }, [_c('label', {
    staticClass: "dialer-input-label pl-2"
  }, [_vm._v("Tags")]), _c('vSelect', {
    staticClass: "cursor_pointer2 lighter-placeholder",
    attrs: {
      "multiple": "",
      "aria-disabled": _vm.api_sent.extensions_list,
      "options": _vm.tagOptions,
      "placeholder": "Select tags"
    },
    model: {
      value: _vm.selectedTags,
      callback: function ($$v) {
        _vm.selectedTags = $$v;
      },
      expression: "selectedTags"
    }
  })], 1), _c('button', {
    staticClass: "newButton ml-auto",
    attrs: {
      "disabled": _vm.loading
    },
    on: {
      "click": function ($event) {
        return _vm.search(_vm.currentPage);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-browse-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newButton-textPart"
  }, [_vm._v("Result")])], 1)])]), _c('div', {
    staticClass: "md-mar-top"
  }, [_c('table', {
    staticClass: "dialer-table"
  }, [_vm._m(0), _c('tbody', [_vm.loading ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "6"
    }
  }, [_c('vb-no-record', {
    attrs: {
      "text": '',
      "design": 3
    }
  }, [_c('vb-loading', {
    staticClass: "text-center w-100",
    attrs: {
      "slot": "loading"
    },
    slot: "loading"
  })], 1)], 1)]) : _vm.isEmpty(_vm.list) ? _c('tr', [_c('td', {
    attrs: {
      "colspan": "6"
    }
  }, [_c('vb-no-record', {
    attrs: {
      "text": 'No record',
      "design": 3
    }
  })], 1)]) : _vm._l(_vm.list, function (data, key) {
    return _c('ReportItem', {
      key: key,
      attrs: {
        "index": key,
        "data": data
      }
    });
  })], 2)]), !_vm.isEmpty(_vm.list) ? _c('div', {
    staticClass: "d-flex justify-content-center w-100"
  }, [_c('vb-pagination', {
    attrs: {
      "listLength": _vm.totalRecord,
      "conditions": {
        pagination: {
          per_page: false
        }
      },
      "perPage": 10,
      "isListEmpty": _vm.isEmpty(_vm.list)
    },
    on: {
      "latest-pagination-state": function ($event) {
        return _vm.callback($event.page);
      }
    }
  })], 1) : _vm._e()])]), _c('CreateReportModal')], 1);

}
var staticRenderFns = [function (){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('thead', [_c('tr', [_c('th', {
    staticClass: "dialer-has-sort bg-white one"
  }, [_c('span', [_vm._v("TEST")])]), _c('th', {
    staticClass: "dialer-has-sort bg-white two"
  }, [_c('span', [_vm._v("TIME & DATE")])]), _c('th', {
    staticClass: "dialer-has-sort bg-white three"
  }, [_c('span', {
    attrs: {
      "id": "timeAndDuration"
    }
  }, [_vm._v("DURATION")])]), _c('th', {
    staticClass: "dialer-has-sort bg-white four"
  }, [_c('span', [_vm._v("Call Recorded")])])])]);

}]

export { render, staticRenderFns }