var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "center-zoomIn-transition v2 SendSmsModal",
    attrs: {
      "width": "400px",
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-edit-user w-100"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header w-100 d-flex justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title mb-0 newer"
  }, [_vm._v("Pricing")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('a', {
    staticClass: "newCloseButton",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('div', {
    staticClass: "dialer-edit-step-block"
  }, [_c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_vm.api.fetch_call_balance.send ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "24px",
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "24px",
      "width": "40px"
    }
  })])] : _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Call price per destination")]), _c('div', {
    staticClass: "latestGreyBox-heading-main-24px color-343434"
  }, [_vm._v("£" + _vm._s(_vm._f("get_property")(_vm.response.call_balance, 'callRate', 0)))])]), _vm.api.fetch_sms_balance.send ? [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
  }, [_c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "24px",
      "width": "50%"
    }
  }), _c('div', {
    staticClass: "latestShimmerDesign mt-1",
    staticStyle: {
      "height": "24px",
      "width": "40px"
    }
  })])] : _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row min-height-unset"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("SMS price per destination")]), _c('div', {
    staticClass: "latestGreyBox-heading-main-24px color-343434"
  }, [_vm._v("£" + _vm._s(_vm._f("get_property")(_vm.response.sms_balance, 'smsRate', 0)))])])], 2)])])])])]);

}
var staticRenderFns = []

export { render, staticRenderFns }