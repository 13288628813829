<template>
  <modal 
    class="dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal" 
    width="50%" 
    :scrollable="true" 
    height="auto"  
    :name="modalName" 
    @before-open="onBeforeOpen" 
    @before-close="onBeforeClose"
  >
    <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
      <div class="settingsTextAndIconOnTop" @click="$modal.hide(modalName)">
        <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" />
      </div>
      <div class="dialer-settings-title topMainHeading">Playlist Files</div>
      <div class="plus-IOSIcon-container"></div>
    </div>
    <div class="innerModalIos">

 

      <div class="">
        
        <div class="basicWhiteIOScard mb-16px mt-32px">
          <div class="basicWhiteIOScard-item">
            <div class="mobileInputIOS">
              <div class="w-100">
                <label>Name</label>
                <input :value="data.name" :disabled="true" :placeholder="'Enter Name'" class="w-100" type="text" />
              </div>
            </div>
          </div>
        </div>

        <div class="textBelowCard">Following are the audio files present in the current MOH playlist.</div>
        <div class="basicWhiteIOScard mb-16px">
          <div class="basicWhiteIOScard-item" v-for="item in selectedList" :key="item.real_id">
            <div class="headingInsideTable">{{ item.label }}</div>
            <vb-audio-player class="IosAudioPlayer" :src="item.sound_file" />
          </div>
          <vb-no-record v-if="isEmpty(selectedList)" :text="api.playlists.send ? '' : 'there is no selected recording list'" :design="3">
            <vb-loading v-if="api.playlists.send" slot="loading" />
          </vb-no-record>
        </div>

      </div>

    </div>
  </modal>
</template>

<script>
import { VOIP_API } from "../../../../utils";
import { mapGetters } from 'vuex';


export default {
  name: "SystemPlaylist",
  inject:['isEmpty'],
  props: {
    modalName: {
      type: String,
      default: 'SystemPlaylist',
    },
  },
  data() {
    return {
      response: {
        available: [],
        playlists: [],
        selected: [],
      },
      api: {
        playlists: this.$helperFunction.apiInstance({  }),
      },
      data: {
        id: '',
        name: '',
      },
    };
  },
  computed: {
    ...mapGetters([
      'getCurrentUser'
    ]),
    selectedList(){
      return this.response.playlists.find(i=>i.id==this.data.id)?.playlist?.map?.(i=>{
        return {
          id: i.sound_id ?? i.id,
          label: i.sound_file?.sound_name ?? i.label,
          real_id: i.id ?? i.real_id,
          sound: i.sound_file?.sound_file ?? i.sound,
          sound_file: i.sound_file?.sound ?? i.sound_file,
        }
      }) ?? this.response.selected ?? []
    },
  },
  methods: {
    onBeforeOpen(event) {
      this.data.name = event.params?.name ?? '';
      this.data.id = event.params?.id || '';
      this.fetchPlayListFiles();
    },
    onBeforeClose(){
      this.api.playlists.reset()
      this.data.id=''
      this.data.name=''
      this.response.playlists=[]
      this.response.selected=[]
      this.response.available=[]
    },
    fetchPlayListFiles() {
      let vm = this
      if (vm.api.playlists.send) return;
      vm.api.playlists.send = true;
      VOIP_API.endpoints.music_on_hold.playlistfiles(vm.data.id, {
        account: vm.getCurrentUser.account,
      })
      .then(({ data })=>{
        vm.response.available = data?.available?.data
        vm.response.playlists = data?.playlists?.data
        vm.response.selected = data?.selected?.data
      })
      .finally(()=>{
        vm.api.playlists.send=false
      })
    },
  }
};
</script>

<style>
.buttons {
  margin-top: 35px;
}

.ghost {
  opacity: 0.5;
  background: #c8ebfb;
}
</style>
