<template>
  <div class="hrm-home-container">
    <div class="hrm-reports-container">
      <div class="reportsLeftSideBar">
        <div class="sideBarHeading-container">
          <div class="sideBarHeading">
            Settings
          </div>
        </div>
        <div class="sideBarList-container">
          <div class="sideBarList-inner">
            <div class="sideBarList">
              <template v-for="setting in settings" >
                <div 
                  :key="setting.id" 
                  @click="selected.tab=setting.childrens ? setting.childrens[0].component : setting.component" 
                  class="sideBarList-item"
                  :class="{
                    'active': selectedTab.id == setting.id
                  }"
                >
                  <div class="sideBarList-itemLeftSide">
                    <vb-icon :icon="setting.icon" height="17px" width="17px" class="sideBarList-icon" />
                    <div class="sideBarList-itemText">{{setting.text}}</div>
                  </div>
                  <b-icon v-if="setting.childrens" class="downIcon" icon="chevron-down" font-scale="1.3"/>
                </div>
                <div 
                  :key="`children-${setting.id}`"
                  v-if="setting.childrens"
                  :class="{
                    'show h-auto': selectedTab.id == setting.id
                  }"
                  class="sideBarSubItemsList"
                >
                  <div 
                    v-for="children in setting.childrens" 
                    :key="children.component"
                    @click="selected.tab=children.component"
                    class="sideBarList-item"
                    :class="{
                      'active': children.component==selected.tab
                    }"
                  >
                    <div class="sideBarList-itemLeftSide">
                      <div class="sideBarList-itemText">{{children.text}}</div>
                    </div>
                  </div>
                </div>
              </template>
            </div>
          </div>
        </div>
      </div>
      <div class="reportsMainBody ">
        {{ selected.tab }}
        <component :is="selected.tab" />
      </div>
    </div>
  </div>
</template>

<script>
import General from "@/components/HRM/settings/General.vue";
import TimeOffPolicies from "@/components/HRM/settings/TimeOff/TimeOffPolicies.vue";
import TimeOffIndividualAllowances from "@/components/HRM/settings/TimeOff/TimeOffIndividualAllowances.vue";
import TimeOffApprovers from "@/components/HRM/settings/TimeOff/TimeOffApprovers.vue";
import TimeOffAutomatedReports from "@/components/HRM/settings/TimeOff/TimeOffAutomatedReports.vue";
import TimeOffReminders from "@/components/HRM/settings/TimeOff/TimeOffReminders.vue";
import CalendarGenral from "@/components/HRM/settings/Calender/CalendarGenral.vue";
import CalendarPublicHolidayGroups from "@/components/HRM/settings/Calender/CalendarPublicHolidayGroups.vue";
import CalendarPublicHolidays from "@/components/HRM/settings/Calender/CalendarPublicHolidays.vue";
import CalendarCustomWorkingDays from "@/components/HRM/settings/Calender/CalendarCustomWorkingDays.vue";
import CalendarBlackoutDates from "@/components/HRM/settings/Calender/CalendarBlackoutDates.vue";
import PermissionsGlobal from "@/components/HRM/settings/Permissions/PermissionsGlobal.vue";
import PermissionsTeamManager from "@/components/HRM/settings/Permissions/PermissionsTeamManager.vue";
import PermissionsAdmin from "@/components/HRM/settings/Permissions/PermissionsAdmin.vue";
import PermissionsAccountants from "@/components/HRM/settings/Permissions/PermissionsAccountants.vue";
import EmployeeDataTeams from "@/components/HRM/settings/EmployeeData/EmployeeDataTeams.vue";
import EmployeeDataTraining from "@/components/HRM/settings/EmployeeData/EmployeeDataTraining.vue";
import EmployeeDataTrainingEvents from "@/components/HRM/settings/EmployeeData/EmployeeDataTrainingEvents.vue";
import EmployeeDataOrgChart from "@/components/HRM/settings/EmployeeData/EmployeeDataOrgChart.vue";
import EmployeeDataPositions from "@/components/HRM/settings/EmployeeData/EmployeeDataPositions.vue";
import EmployeeDataLocations from "@/components/HRM/settings/EmployeeData/EmployeeDataLocations.vue";
import EmployeeDataDocuments from "@/components/HRM/settings/EmployeeData/EmployeeDataDocuments.vue";

const settings = [
  {
    id: '1',
    text: 'General',
    component: 'General',
    icon: 'ReportsSideBar-AccessLogs-hrmIcon',
  },
  {
    id: '2',
    text: 'Time Off',
    icon: 'ReportsSideBar-HrDashboard-hrmIcon',
    childrens: [
      {
        text: 'Policies',
        component: 'TimeOffPolicies',
      },
      {
        text: 'Individual allowances',
        component: 'TimeOffIndividualAllowances',
      },
      {
        text: 'Time off approvers',
        component: 'TimeOffApprovers',
      },
      {
        text: 'Automated reports',
        component: 'TimeOffAutomatedReports',
      },
      {
        text: 'Reminders',
        component: 'TimeOffReminders',
      },
    ]
  },
  {
    id: '3',
    text: 'Calendar',
    icon: 'ReportsSideBar-timeOff-hrmIcon',
    childrens: [
      {
        text: 'General',
        component: 'CalendarGenral',
      },
      {
        text: 'Public holiday groups',
        component: 'CalendarPublicHolidayGroups',
      },
      {
        text: 'Public holidays',
        component: 'CalendarPublicHolidays',
      },
      {
        text: 'Custom working days',
        component: 'CalendarCustomWorkingDays',
      },
      {
        text: 'Blackout dates',
        component: 'CalendarBlackoutDates',
      },
    ]
  },
  {
    id: '4',
    text: 'Announcment',
    icon: 'ReportsSideBar-EmployeeData-hrmIcon',
    childrens: [
      {
        text: 'Assets',
        component: 'AnnouncmentAssets',
      },
      {
        text: 'Tasks',
        component: 'AnnouncmentTasks',
      },
      {
        text: 'Notes',
        component: 'AnnouncmentNotes',
      },
      {
        text: 'Training',
        component: 'AnnouncmentTraining',
      },
      {
        text: 'Training events',
        component: 'AnnouncmentTrainingEvents',
      },
      {
        text: 'Documents',
        component: 'AnnouncmentDocuments',
      },
      {
        text: 'Offboarding',
        component: 'AnnouncmentOffboarding',
      },
      {
        text: 'Emergency contacts',
        component: 'AnnouncmentEmergencyContacts',
      },
      {
        text: 'Terminated employees',
        component: 'AnnouncmentTerminatedEmployees',
      },
      {
        text: 'Custom reports',
        component: 'AnnouncmentCustomReports',
      },
    ]
  },
  {
    id: '5',
    text: 'Permissions',
    icon: 'ReportsSideBar-Onboarding-hrmIcon',
    childrens: [
      {
        text: 'Global',
        component: 'PermissionsGlobal',
      },
      {
        text: 'Team managers',
        component: 'PermissionsTeamManager',
      },
      {
        text: 'Admin',
        component: 'PermissionsAdmin',
      },
      {
        text: 'Accountants',
        component: 'PermissionsAccountants',
      },
      {
        text: 'Employees',
        component: 'PermissionsEmployees',
      },
    ]
  },
  {
    id: '6',
    text: 'Employee data',
    icon: 'ReportsSideBar-Onboarding-hrmIcon',
    childrens: [
      {
        text: 'Teams',
        component: 'EmployeeDataTeams',
      },
      {
        text: 'Training',
        component: 'EmployeeDataTraining',
      },
      {
        text: 'Training events',
        component: 'EmployeeDataTrainingEvents',
      },
      {
        text: 'Org chart',
        component: 'EmployeeDataOrgChart',
      },
      {
        text: 'Positions',
        component: 'EmployeeDataPositions',
      },
      {
        text: 'Locations',
        component: 'EmployeeDataLocations',
      },
      {
        text: 'Documents',
        component: 'EmployeeDataDocuments',
      },
      {
        text: 'Documents templates',
        component: 'EmployeeDataDocumentsTemplates',
      },
      {
        text: 'Custom fields',
        component: 'EmployeeDataCustomFields',
      },
      {
        text: 'Termination reasons',
        component: 'EmployeeDataTerminationReasons',
      },
      {
        text: 'Languages',
        component: 'EmployeeDataLanguages',
      },
      {
        text: 'Compensations',
        component: 'EmployeeDataCompensations',
      },
      {
        text: 'Mass editing',
        component: 'EmployeeDataMassEditing',
      },
      {
        text: 'Employment statuses & Working Patterns',
        component: 'EmployeeDataEmploymentStatusesANDWorkingPatterns',
      },
    ]
  },
  {
    id: '7',
    text: 'Assets',
    icon: 'ReportsSideBar-Onboarding-hrmIcon',
    childrens: [
      {
        text: 'General',
        component: 'AssetsGeneral',
      },
      {
        text: 'Inventory',
        component: 'AssetsInventory',
      },
      {
        text: 'Categories',
        component: 'AssetsCategories',
      },
    ]
  },
  {
    id: '8',
    text: 'Onboarding',
    icon: 'ReportsSideBar-Onboarding-hrmIcon',
    childrens: [
      {
        text: 'Task',
        component: 'OnboardingTask',
      },
      {
        text: 'Categories',
        component: 'OnboardingCategories',
      },
      {
        text: 'Workflow',
        component: 'OnboardingWorkflow',
      },
    ]
  },
  {
    id: '9',
    text: 'Offboarding',
    icon: 'ReportsSideBar-Onboarding-hrmIcon',
    childrens: [
      {
        text: 'Overview',
        component: 'OffboardingOverview',
      },
      {
        text: 'Task',
        component: 'OffboardingTask',
      },
    ]
  },
  {
    id: '10',
    text: 'Billing',
    icon: 'ReportsSideBar-Onboarding-hrmIcon',
    childrens: [
      {
        text: 'Overview',
        component: 'BillingOverview',
      },
      {
        text: 'Task',
        component: 'BillingTask',
      },
    ]
  },
]
export default { 
  name: 'SettingsNew',
  components: {
    General,
    TimeOffPolicies,
    TimeOffIndividualAllowances,
    TimeOffApprovers,
    TimeOffAutomatedReports,
    TimeOffReminders,
    CalendarGenral,
    CalendarPublicHolidayGroups,
    CalendarPublicHolidays,
    CalendarCustomWorkingDays,
    CalendarBlackoutDates,
    PermissionsGlobal,
    PermissionsTeamManager,
    PermissionsAdmin,
    PermissionsAccountants,
    EmployeeDataTeams,
    EmployeeDataTraining,
    EmployeeDataTrainingEvents,
    EmployeeDataOrgChart,
    EmployeeDataPositions,
    EmployeeDataLocations,
    EmployeeDataDocuments,
  },
  data(){
    return {
      selected: {
        tab: 'General',
      },
    }
  },
  computed: {
    settings(){ return settings },
    selectedTab(){
      const component = this.selected.tab
      const tab = this.settings.find(tab=>tab.childrens ? !!tab.childrens.find(c=>c.component==component) : tab.component==component)
      return tab ?? {}
    },
  },
}
</script>

<style>

</style>