<template>
  <div>
    <div class="mainHeading">Employee headcount</div>
    <div class="mainDescription mb-4">
      This report shows up-to-date list of employees in selected time period.
    </div>
    <div class="dropdownsContainer">
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All time</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All time
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All employees</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All employees
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All teams</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All teams
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All locations</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All locations
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All positions</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All positions
        </b-dropdown-item>
      </b-dropdown>
      <b-dropdown no-caret class="primaryBorderDropdown">
        <template #button-content>
          <div class="button-content">
            <div class="buttonContentText">All genders</div>
            <b-icon class="downIcon" icon="caret-down-fill" />
          </div>
        </template>
        <b-dropdown-item >
          All genders
        </b-dropdown-item>
      </b-dropdown>
    </div>
    <div class="w-100 d-flex align-items-center justify-content-center mt-5">
      <canvas id="headcount-turnover-chart"></canvas>
    </div>
  </div>
</template>

<script>
import { HRM } from '@/data'
import Chart from "chart.js";
export default {
  name: 'HeadcountTurnover',
  data() {
    return {
      headCountTurnOverChart: {}
    }
  },
  methods: {
    async barChart() {
      const ctx = document.getElementById("headcount-turnover-chart");
      this.headCountTurnOverChart?.destroy?.();
      this.headCountTurnOverChart = new Chart(ctx, {
        type: 'bar',
        data: {
            labels: HRM.charts.headCountTurnOver.labels,
            datasets: [
              {
                label: 'All',
                data: HRM.charts.headCountTurnOver.all,
                backgroundColor: [
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1'
                ],
                hoverBackgroundColor: [
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1'
                ],
                borderColor: [
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1',
                  '#5576d1'
                ],
                borderWidth: 1
            },
            {
                label: 'Full-time',
                data: HRM.charts.headCountTurnOver.fullTime,
                backgroundColor: [
                  '#c7374e',
                  '#c7374e',
                  '#c7374e',
                  '#c7374e',
                  '#c7374e',
                  '#c7374e'
                ],
                hoverBackgroundColor:[
                  '#c7374e',
                  '#c7374e',
                  '#c7374e',
                  '#c7374e',
                  '#c7374e',
                  '#c7374e'
                ],
                borderColor: [
                  '#c7374e',
                  '#c7374e',
                  '#c7374e',
                  '#c7374e',
                  '#c7374e',
                  '#c7374e'
                ],
                borderWidth: 1
            }
          ]
        },
        options: {
          lineTension: 1,
          legend:{
            position: 'bottom',
            labels: {
              usePointStyle: true,
              boxWidth: 10
            }
          },
          scales: {
            xAxes: [{
                gridLines: {
                  display:false,
                },
                scaleLabel: {
                  display: true,
                  labelString: 'Cities'
                },
                ticks: {
                  beginAtZero: true,
                  stepSize: 1,
                },
            }],
            yAxes: [{
                gridLines: {
                  // display:false,
                  drawBorder: false,
                },
                scaleLabel: {
                  beginAtZero: true,
                  display: true,
                  labelString: 'Time Slot'
                },
            }]
          }
        }
      });
      this.headCountTurnOverChart.update();
    },
  },
  mounted(){
    this.barChart()
  }
}
</script>

<style>

</style>