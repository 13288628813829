var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-600 scrollable bigSquareCheckbox IosModal",
    attrs: {
      "width": "50%",
      "scrollable": true,
      "height": "auto",
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose();
      }
    }
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  })], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Select Address")]), _c('div', {
    staticClass: "plus-IOSIcon-container",
    on: {
      "click": function ($event) {
        return _vm.$modal.show('AddLocationModal', {
          flag: 'unpurchased',
          callerId: _vm.data.number
        });
      }
    }
  }, [_c('vb-icon', {
    staticClass: "plus-IOSIcon",
    attrs: {
      "icon": "plus-IOSIcon",
      "width": "20",
      "height": "19.992"
    }
  })], 1)]), _c('div', {
    staticClass: "innerModalIos"
  }, [_c('div', {
    staticClass: "lg-mar-bottom"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard mt-32px mb-16px"
  }, [_c('div', {
    staticClass: "basicWhiteIOScard-item"
  }, [_c('div', {
    staticClass: "headingInsideTable"
  }, [_vm._v("Address type required")]), _c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('div', {
    staticClass: "textInsideCard onRightSide textAlignRight"
  }, [_vm._v(_vm._s(_vm.data.address_required || 'None'))])])])]), _vm._l(_vm.response.locations, function (location) {
    return _c('div', {
      key: location.real_id,
      staticClass: "basicWhiteIOScard mt-16px mb-16px",
      on: {
        "click": function ($event) {
          return _vm.selectLocation(location);
        }
      }
    }, [_c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "basicIOSIcon mr-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": "Locations-location-IOSIcon"
      }
    })], 1), _c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v("Use this Address")])]), _c('vb-icon', {
      staticClass: "rightFacingArrow",
      attrs: {
        "icon": "rightFacingArrow-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1), _c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v("Street")]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight"
    }, [_vm._v(_vm._s(location.street))])])]), _c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v("Town")]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight"
    }, [_vm._v(_vm._s(location.town))])])]), _c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v("Postcode")]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight"
    }, [_vm._v(_vm._s(location.postcode))])])]), _c('div', {
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v("Country")]), _c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "textInsideCard onRightSide textAlignRight"
    }, [_vm._v(_vm._s(location.country))])])]), _vm.response.locations.length == 0 ? _c('vb-no-record', {
      attrs: {
        "text": _vm.api.locations.send ? '' : 'There is no Number',
        "design": 3
      }
    }, [_vm.api.locations.send ? _c('vb-loading', {
      attrs: {
        "slot": "loading"
      },
      slot: "loading"
    }) : _vm._e()], 1) : _vm._e()], 1);
  })], 2), _c('AddLocationModal', {
    on: {
      "latest-list": function ($event) {
        return _vm.fetchLocations();
      }
    }
  })], 1)])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }