var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "crm-SettingsContainer"
  }, [_c('div', {
    staticClass: "crm-Settings-leftSideBar"
  }, [_c('h2', {
    staticClass: "crm-menu-title"
  }, [_vm._v("Settings")]), _c('div', {
    staticClass: "crm-Settings-leftSideBar-inner"
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'General' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'General';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-general-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" General ")])])]), _c('li', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.Fields",
      modifiers: {
        "Fields": true
      }
    }],
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab.includes('Fields', 'CompanyFields', 'ContactFields', 'DealFields', 'ProductFields') ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'CompanyFields';
      }
    }
  }, [_c('a', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-fields-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Fields ")])]), _c('b-icon', {
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.2"
    }
  })], 1)]), _c('b-collapse', {
    staticClass: "crm-leftsideBar-subMenu",
    attrs: {
      "id": "Fields"
    }
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    class: `${_vm.selectTab == 'CompanyFields' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'CompanyFields';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Company")])]), _c('li', {
    class: `${_vm.selectTab == 'ContactFields' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'ContactFields';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Contact")])]), _c('li', {
    class: `${_vm.selectTab == 'DealFields' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'DealFields';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Deal")])]), _c('li', {
    class: `${_vm.selectTab == 'ProductFields' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'ProductFields';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Product")])])])]), _c('li', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.Integrations",
      modifiers: {
        "Integrations": true
      }
    }],
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab.includes('Integrations', 'PusherIntegrations', 'MicrosoftIntegrations', 'GoogleIntegrations', 'TwilioIntegrations', 'ZapierIntegrations') ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'PusherIntegrations';
      }
    }
  }, [_c('a', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-integrations-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Integrations ")])]), _c('b-icon', {
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.2"
    }
  })], 1)]), _c('b-collapse', {
    staticClass: "crm-leftsideBar-subMenu",
    attrs: {
      "id": "Integrations"
    }
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    class: `${_vm.selectTab == 'PusherIntegrations' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'PusherIntegrations';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Pusher")])]), _c('li', {
    class: `${_vm.selectTab == 'MicrosoftIntegrations' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'MicrosoftIntegrations';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Microsoft")])]), _c('li', {
    class: `${_vm.selectTab == 'GoogleIntegrations' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'GoogleIntegrations';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Google")])]), _c('li', {
    class: `${_vm.selectTab == 'TwilioIntegrations' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'TwilioIntegrations';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Twilio")])]), _c('li', {
    class: `${_vm.selectTab == 'ZapierIntegrations' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'ZapierIntegrations';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Zapier")])])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Activities' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Activities';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-activities-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Activities ")])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Deals' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Deals';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-deals-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Deals ")])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Documents' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Documents';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-documents-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Documents ")])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Products' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Products';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-products-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Products ")])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Companies' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Companies';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-companies-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Companies ")])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Calls' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Calls';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-calls-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Calls ")])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Web Forms' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Web Forms';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-webForms-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Web Forms ")])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Workflows' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Workflows';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-workflows-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Workflows ")])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Users' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Users';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-users-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Users ")])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Mail Templates' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Mail Templates';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-mailTemplates-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Mail Templates ")])])]), _c('li', {
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab == 'Brands' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'Brands';
      }
    }
  }, [_c('a', [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-brands-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Brands ")])])]), _c('li', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.Security",
      modifiers: {
        "Security": true
      }
    }],
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab.includes('Security', 'GeneralSecurity', 'reCaptchaSecurity') ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'GeneralSecurity';
      }
    }
  }, [_c('a', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-security-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" Security ")])]), _c('b-icon', {
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.2"
    }
  })], 1)]), _c('b-collapse', {
    staticClass: "crm-leftsideBar-subMenu",
    attrs: {
      "id": "Security"
    }
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    class: `${_vm.selectTab == 'GeneralSecurity' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'GeneralSecurity';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("General")])]), _c('li', {
    class: `${_vm.selectTab == 'reCaptchaSecurity' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'reCaptchaSecurity';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("reCaptcha")])])])]), _c('li', {
    directives: [{
      name: "b-toggle",
      rawName: "v-b-toggle.System",
      modifiers: {
        "System": true
      }
    }],
    class: `crm-Settings-leftSideBar-item ${_vm.selectTab.includes('System', 'UpdateSystem', 'ToolsSystem', 'TranslatorSystem') ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'UpdateSystem';
      }
    }
  }, [_c('a', [_c('div', {
    staticClass: "d-flex align-items-center"
  }, [_c('span', {
    staticClass: "leftSideBar-item-icon"
  }, [_c('vb-icon', {
    attrs: {
      "icon": "crm-system-settings-icon",
      "height": "22px",
      "width": "22px"
    }
  })], 1), _c('span', {
    staticClass: "leftSideBar-item-text"
  }, [_vm._v(" System ")])]), _c('b-icon', {
    attrs: {
      "icon": "chevron-down",
      "font-scale": "1.2"
    }
  })], 1)]), _c('b-collapse', {
    staticClass: "crm-leftsideBar-subMenu",
    attrs: {
      "id": "System"
    }
  }, [_c('ul', {
    staticClass: "list-unstyled"
  }, [_c('li', {
    class: `${_vm.selectTab == 'UpdateSystem' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'UpdateSystem';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Update")])]), _c('li', {
    class: `${_vm.selectTab == 'ToolsSystem' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'ToolsSystem';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Tools")])]), _c('li', {
    class: `${_vm.selectTab == 'TranslatorSystem' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'TranslatorSystem';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Translator")])]), _c('li', {
    class: `${_vm.selectTab == 'SystemInfoSystem' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'SystemInfoSystem';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("System Info")])]), _c('li', {
    class: `${_vm.selectTab == 'LogsSystem' ? 'active' : ''}`,
    on: {
      "click": function ($event) {
        _vm.selectTab = 'LogsSystem';
      }
    }
  }, [_c('a', {
    attrs: {
      "href": "#"
    }
  }, [_vm._v("Logs")])])])])], 1)])]), _c('div', {
    staticClass: "crm-Settings-rightSide"
  }, [_c('div', {
    staticClass: "crm-Settings-rightSide-inner"
  }, [_vm.selectTab == 'General' ? _c('General') : _vm._e(), _vm.selectTab == 'CompanyFields' ? _c('CompanyFields') : _vm._e(), _vm.selectTab == 'ContactFields' ? _c('ContactFields') : _vm._e(), _vm.selectTab == 'DealFields' ? _c('DealsFields') : _vm._e(), _vm.selectTab == 'ProductFields' ? _c('ProductFields') : _vm._e(), _vm.selectTab == 'PusherIntegrations' ? _c('PusherIntegration') : _vm._e(), _vm.selectTab == 'MicrosoftIntegrations' ? _c('MicrosoftIntegration') : _vm._e(), _vm.selectTab == 'GoogleIntegrations' ? _c('GoogleIntegration') : _vm._e(), _vm.selectTab == 'TwilioIntegrations' ? _c('TwilioIntegration') : _vm._e(), _vm.selectTab == 'ZapierIntegrations' ? _c('ZapierIntegration') : _vm._e(), _vm.selectTab == 'GeneralSecurity' ? _c('GeneralSecurity') : _vm._e(), _vm.selectTab == 'reCaptchaSecurity' ? _c('ReCaptcha') : _vm._e(), _vm.selectTab == 'UpdateSystem' ? _c('Update') : _vm._e(), _vm.selectTab == 'ToolsSystem' ? _c('Tools') : _vm._e(), _vm.selectTab == 'TranslatorSystem' ? _c('Translator') : _vm._e(), _vm.selectTab == 'LogsSystem' ? _c('Logs') : _vm._e(), _vm.selectTab == 'SystemInfoSystem' ? _c('SystemInfo') : _vm._e(), _vm.selectTab == 'Activities' ? _c('Activities') : _vm._e(), _vm.selectTab == 'Deals' ? _c('Deals') : _vm._e(), _vm.selectTab == 'Documents' ? _c('Documents') : _vm._e(), _vm.selectTab == 'Products' ? _c('Products') : _vm._e(), _vm.selectTab == 'Companies' ? _c('Companies') : _vm._e(), _vm.selectTab == 'Calls' ? _c('Calls') : _vm._e(), _vm.selectTab == 'Users' ? _c('Users') : _vm._e(), _vm.selectTab == 'Brands' ? _c('Brands') : _vm._e()], 1)])]);

}
var staticRenderFns = []

export { render, staticRenderFns }