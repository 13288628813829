var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-700 updateCaller-idModal AddMemberInPickupGroupsModal min-width-50percent-modal",
    attrs: {
      "width": "500px",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "closed": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('form', {
    staticClass: "dialer-form",
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        '';
      }
    }
  }, [_vm.$store.getters.getIsMobile ? _c('div', {
    staticClass: "dialer-edit-header flex-column"
  }, [_c('div', {
    staticClass: "d-flex w-100 justify-content-between align-items-center"
  }, [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Assign extensions ")]), _vm.$store.getters.getIsMobile ? _c('b-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "x",
      "font-scale": "1.8"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "dialer-edit-actions d-flex w-100 justify-content-end mt-2"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api_sent.update
    },
    on: {
      "click": function ($event) {
        return _vm.updatemembers();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api_sent.update ? _c('b-spinner', {
    attrs: {
      "label": "spinner"
    }
  }) : [_vm._v("Done")]], 2)], 1)])]) : _c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('h2', {
    staticClass: "dialer-edit-title d-flex align-items-center"
  }, [_c('a', {
    staticClass: "newBackButton mr-16px",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('b-icon', {
    attrs: {
      "icon": "arrow-left-circle"
    }
  }), _c('span', {
    staticClass: "newBackButton-textpart"
  }, [_vm._v("Back")])], 1), _vm._v(" Assign extensions ")]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api_sent.update
    },
    on: {
      "click": function ($event) {
        return _vm.updatemembers();
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm.api_sent.update ? _c('vb-spinner', {
    attrs: {
      "small": "",
      "label": "spinner"
    }
  }) : [_vm._v("Done")]], 2)], 1)])]), _c('div', [_vm.selected.tab == 'user' ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search users"
    },
    model: {
      value: _vm.filter.user.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.user, "search", $$v);
      },
      expression: "filter.user.search"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm._l(_vm.filterUsers, function (data) {
    return _c('div', {
      key: data.accountcode,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio checkboxVersion mr-20px ${_vm.selected.extension.includes(data.accountcode) ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.selected.extension.includes(data.accountcode) ? _vm.selected.extension.splice(_vm.selected.extension.indexOf(data.accountcode), 1) : _vm.selected.extension.push(data.accountcode);
        }
      }
    }), _c('info', {
      attrs: {
        "id": data.accountcode,
        "name": data.label,
        "sub_info": data.extn,
        "is_blf": false
      }
    })], 1);
  }), _vm.isEmpty(_vm.filterUsers) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api_sent.extensions ? '' : 'Sorry, no data found',
      "design": 3
    }
  }, [_vm.api_sent.extensions ? _c('template', {
    slot: "loading"
  }, _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "160px"
      }
    })]);
  }), 0) : _vm._e()], 2) : _vm._e()], 2)]) : _vm._e(), _vm.selected.tab == 'team' ? _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_c('div', {
    staticClass: "whiteBGinputWithGreyRoundedBorder"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search teams"
    },
    model: {
      value: _vm.filter.team.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.team, "search", $$v);
      },
      expression: "filter.team.search"
    }
  })], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox mt-20px"
  }, [_vm._l(_vm.filterTeams, function (data) {
    return _c('div', {
      key: data.accountcode,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      class: `actLikeSquareRadio mr-20px ${_vm.selected.extension.includes(data.accountcode) ? 'checked' : ''}`,
      on: {
        "click": function ($event) {
          _vm.selected.extension.includes(data.accountcode) ? _vm.selected.extension.splice(_vm.selected.extension.indexOf(data.accountcode), 1) : _vm.selected.extension.push(data.accountcode);
        }
      }
    }), _c('info', {
      attrs: {
        "id": data.accountcode,
        "name": data.label,
        "sub_info": data.extn,
        "is_blf": false
      }
    })], 1);
  }), _vm.isEmpty(_vm.filterTeams) ? _c('vb-no-record', {
    attrs: {
      "text": _vm.api_sent.extensions ? '' : 'Sorry, no data found',
      "design": 3
    }
  }, [_vm.api_sent.extensions ? _c('template', {
    slot: "loading"
  }, _vm._l(5, function (n) {
    return _c('div', {
      key: n.id,
      staticClass: "whiteBoxWithBorderInsideGreyBox-row justify-content-start min-height-none"
    }, [_c('div', {
      staticClass: "latestShimmerDesign",
      staticStyle: {
        "height": "24px",
        "width": "160px"
      }
    })]);
  }), 0) : _vm._e()], 2) : _vm._e()], 2)]) : _vm._e()])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }