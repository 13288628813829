<template>
  <div>
    <div class="mainHeading withButton">
      Locations
      <button @click="$modal.show('AddNewEmployeeDataLocationsModal')" class="dialer-button dialer-button-primary mr-0 withIcon">
        <!-- <vb-icon icon="pencil-hrmIcon" class="mr-16px fillWhite" height="14px" width="14px" /> -->
        <b-icon icon="plus" class="mr-16px fillWhite" font-scale="1.3" scale="1.5" />
        Add new
      </button>
    </div>
    <div class="mainDescription mb-4">
      Here you can set up job Locations in your company and then assign employees to them.
    </div>
    <div class="documentsModule-Table mt-32px">
      <vb-table class="HrmTable">
        <template slot="header">
          <tr>
            <th class="HrmTableHead text-left one pt-1">title</th>
            <th class="HrmTableHead text-left two pt-1">Employees</th>
            <th class="HrmTableHead three pt-1">Actions</th>
          </tr>
        </template>
        <template #body>
          <tr v-for="n in 6" :key="n" class="dialer-row-select" >
            <td class="dialer-row-title one">
              No Locations assigned
            </td>
            <td class="dialer-col-right text-left two">
              0
            </td>
            <td class="dialer-col-right three">
              <b-dropdown no-caret right class="ActionsDD">
                <template #button-content>
                  <div class="button-content">
                    <b-icon class="downIcon" icon="three-dots-vertical" />
                  </div>
                </template>
                <b-dropdown-item >
                  Actions
                </b-dropdown-item>
              </b-dropdown>
            </td>
          </tr>
        </template>
      </vb-table>
    </div>
    
    
    <AddNewEmployeeDataLocationsModal />
    <!-- <AddPaymentType /> -->
  </div>
</template>

<script>
import AddNewEmployeeDataLocationsModal from '@/components/Modals/HRM/settings/EmployeeData/AddNewEmployeeDataLocationsModal.vue';
// import AddPaymentType from '@/components/Modals/HRM/settings/EmployeeData/AddPaymentType.vue';
export default {
  name: 'EmployeeDataLocations',
  components: {
    AddNewEmployeeDataLocationsModal,
    // AddPaymentType,
  },
}
</script>

<style>

</style>