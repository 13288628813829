<template>
  <div>
    <div v-for="(message, index) in messages" :key="index">
      {{message.text}}
    </div>
  </div>
</template>

<script>
export default {
  name: 'ConferenceChat',
  props: {
    messages: {
      type: Array,
      default: ()=>[]
    },
  },
}
</script>

<style>

</style>