<template>
  <div>
    <modal 
      class="center-zoomIn-transition v2 m-w-500" 
      height="auto" 
      :name="modalName"
      @before-open="onBeforeOpen($event)" 
      @before-close="onBeforeClose()"
    >
      <div class="w-100">
        <div class="d-flex align-items-center justify-content-between w-100">
          <h2 class="dialer-edit-title">Edit user details</h2>
          <button @click="$modal.hide(modalName)" class="newCloseButton">
            <vb-icon icon="squared-close-icon" height="38px" width="38px" />
            <span class="newCloseButton-textPart">Close</span>
          </button>
        </div>
        <form @submit.prevent="updateInfo()" class="latestGreyBox-9-9-2023 mt-20px w-100">
          <b-alert :show="!!api.update_info.error_message" variant="danger">{{ api.update_info.error_message }}</b-alert>
          <div class="whiteBGinputWithGreyRoundedBorder w-100">
            <label>First name</label>
            <input 
              class="w-100" 
              type="text" 
              v-model="forms.update_info.first_name"
              onkeypress="return /^[A-Za-z]+$/.test(event.key)"  
              :disabled="api.update_info.send" 
              :maxlength="$v.forms.update_info.first_name.$params.maxLength.max" 
            />
            <p class="text text-danger animated bounceIntop mb-0" v-if="forms.update_info.submitted && $v.forms.update_info.first_name.$invalid">
              <span v-if="!$v.forms.update_info.first_name.required">First name is required</span>
              <span v-else-if="!$v.forms.update_info.first_name.alpha">First name should be alphabet</span>
              <span v-else-if="!$v.forms.update_info.first_name.maxLength">First name can be maximum {{ $v.forms.update_info.first_name.$params.maxLength.max }} character</span>
            </p>
          </div>
          <div class="whiteBGinputWithGreyRoundedBorder w-100 mt-20px">
            <label>Last name</label>
            <input 
              class="w-100" 
              type="text" 
              v-model="forms.update_info.last_name" 
              onkeypress="return /^[A-Za-z]+$/.test(event.key)" 
              :disabled="api.update_info.send"
              :maxlength="$v.forms.update_info.last_name.$params.maxLength.max" 
            />
            <p class="text text-danger animated bounceIntop mb-0" v-if="forms.update_info.submitted && $v.forms.update_info.last_name.$invalid">
              <span v-if="!$v.forms.update_info.last_name.alpha">Last name should be alphabet</span>
              <span v-else-if="!$v.forms.update_info.last_name.maxLength">Last name can be maximum {{ $v.forms.update_info.last_name.$params.maxLength.max }} character</span>
            </p>
          </div>
          <div v-if="userType=='user'" class="whiteBGinputWithGreyRoundedBorder w-100 mt-20px">
            <label>Username</label>
            <input 
              class="w-100" 
              type="text" 
              v-model="forms.update_info.username" 
              :disabled="api.update_info.send" 
              :maxlength="$v.forms.update_info.username.$params.maxLength.max" 
            />
            <p class="text text-danger animated bounceIntop mb-0" v-if="forms.update_info.submitted && $v.forms.update_info.username.$invalid">
              <span v-if="!$v.forms.update_info.username.required">Username is required</span>
              <span v-else-if="!$v.forms.update_info.username.alphaStart">Username should start with an alphabet</span>
              <span v-else-if="!$v.forms.update_info.username.alphaNum">Username should be alphabet or numeric</span>
              <span v-else-if="!$v.forms.update_info.username.minLength">Username should be minimum {{ $v.forms.update_info.username.$params.minLength.min }} character</span>
              <span v-else-if="!$v.forms.update_info.username.maxLength">Username can be maximum {{ $v.forms.update_info.username.$params.maxLength.max }} character</span>
            </p>
          </div>
          <div v-if="userType=='user'" class="whiteBGinputWithGreyRoundedBorder withTextOnRightSideOnly w-100 mt-20px">
            <label>Email for notification</label>
            <input class="w-100" type="text" v-model="forms.update_info.email_for_notification" :disabled="api.update_info.send" />
            <p class="text text-danger animated bounceIntop mb-0" v-if="forms.update_info.submitted && $v.forms.update_info.email_for_notification.$invalid">
              <span v-if="!$v.forms.update_info.email_for_notification.email">Email for Notification should be valid email</span>
            </p>
          </div>
          <div v-if="userType=='main'" class="whiteBGinputWithGreyRoundedBorder withTextOnRightSideOnly w-100 mt-20px">
            <label>Email</label>
            <div class="inputContainer-inside-whiteBGinputWithGreyRoundedBorder">
              <input disabled class="w-100" type="text" :value="userEmail" />
              <div class="withTextOnRightSideOnly-text" @click="api.update_info.send ? '' : $modal.show(`${modalName}-ChangeEmail`,{ accountcode: data.accountcode })">
                Change email
              </div>
            </div>
          </div>
        </form>
        <div class="w-100 d-flex justify-content-end mt-20px">
          <button :disabled="api.update_info.send" class="fullWidthDoneButton" type="button" @click="updateInfo()" >
            Update
          </button>
        </div>
      </div>
    </modal>
    <ChangeEmailModal :modalName="`${modalName}-ChangeEmail`" @updated="$emit('updated')" />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import ChangeEmailModal from './ChangeEmailModal.vue'
import required from 'vuelidate/lib/validators/required'
import alpha from 'vuelidate/lib/validators/alpha'
import maxLength from 'vuelidate/lib/validators/maxLength'
import email from 'vuelidate/lib/validators/email'
import requiredIf from 'vuelidate/lib/validators/requiredIf'
import alphaNum from 'vuelidate/lib/validators/alphaNum'
import { helpers } from 'vuelidate/lib/validators'
import minLength from 'vuelidate/lib/validators/minLength'
export default {
  name: 'UpdateUserGeneralInfoModal',
  components: { 
    ChangeEmailModal, 
  },
  inject: [
    'appNotify',
  ],
  props: {
    modalName: {
      type: String,
      default: 'UpdateUserGeneralInfo',
    }
  },
  data() {
    return {
      forms: {
        update_info: this.$helperFunction.formInstance({
          data: {
            first_name: '',
            last_name: '',
            username: '',
            email_for_notification: '',
          },
        })
      },
      api: {
        update_info: this.$helperFunction.apiInstance({
          error_message: true,
          validation_errors: true,
        }),
      },
      data: {
        accountcode: '',
      },
    }
  },
  computed: {
    ...mapGetters([
      'getCurrentUser',
      'getVoipUsersAlises',
    ]),
    userType(){ return this.voipuser?.user?.user_type ?? '' },
    voipuser(){ return this.getVoipUsersAlises[this.data.accountcode] },
    userEmail(){
      const email = this.voipuser?.user?.email
      const email2 = this.voipuser?.user?.email2
      const ac_user = this.voipuser?.user?.ac_user
      const current_user_email = this.getCurrentUser.email
      return ((email2 || email) === current_user_email ? ac_user : (ac_user != email) ? ac_user : email2 || email || ac_user) ?? ''
    },
  },
  validations: {
    forms: {
      update_info: {
        first_name: {
          required: required,
          alpha: alpha,
          maxLength: maxLength(16),
        },
        last_name: {
          alpha: alpha,
          maxLength: maxLength(16),
        },
        username: {
          required: requiredIf(function (){
            return this.userType=='user'
          }),
          alphaNum,
          minLength: minLength(4), 
          maxLength: maxLength(40),
          alphaStart: helpers.regex('alphaStart',/^[A-Za-z]+/),
        },
        email_for_notification: {
          email: email,
        },
      },
    },
  },
  methods: {
    async onBeforeOpen(event){
      this.data.accountcode=event?.params?.accountcode ?? ''
      await this.$nextTick()
      this.forms.update_info.first_name=event?.params?.first_name ?? ''
      this.forms.update_info.last_name=event?.params?.last_name ?? ''
      if(this.userType=='user') {
        this.forms.update_info.username=event?.params?.username ?? ''
        this.forms.update_info.email_for_notification=event?.params?.email_for_notification ?? ''
      }
    },
    onBeforeClose(){
      this.data.accountcode=''
      this.api.update_info.reset()
      this.forms.update_info.reset()
    },
    async updateInfo() {
      this.forms.update_info.submitted=true
      this.$v.forms.update_info.$touch()
      if(this.api.update_info.send || this.$v.forms.update_info.$invalid) return;
      try {
        this.api.update_info.send=true
        this.api.update_info.error_message=''
        this.api.update_info.validation_errors={}
        await VOIP_API.endpoints.users.updateUserDetail({
          id: this.data.accountcode,
          uid: this.getCurrentUser.uid,
          // accountcode: this.getCurrentUser.account,
          firstname: this.forms.update_info.first_name,
          lastname: this.forms.update_info.last_name,
          username: this.forms.update_info.username,
          email_for_notification: this.forms.update_info.email_for_notification,
        })
        if(this.data.accountcode==this.getCurrentUser.account) {
          this.$store.state.common.user.display_name = `${this.forms.update_info.first_name} ${this.forms.update_info.last_name}`;
          if(this.userType=='user'){
            this.$store.state.common.user.email = `${this.forms.update_info.username}@${this.getCurrentUser.company}.com`;
          }
        }
        this.appNotify({
          message: 'Successfully Updated!',
          type: 'success',
        })
        this.$modal.hide(this.modalName)
        this.$emit('updated')
      } catch (ex) {
        this.api.update_info.error_message=ex.own_message || ex.message
        this.api.update_info.validation_errors=ex.own_errors
      } finally {
        this.api.update_info.send=false
        this.forms.update_info.submitted=false
      }
    },
  },
}
</script>

<style>

</style>