var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "CallNotification-inner-container"
  }, [_c('div', {
    staticClass: "CallNotification-inner"
  }, [_c('div', {
    staticClass: "CallNotification-left"
  }, [_c('div', {
    staticClass: "user-image"
  }, [_c('img', {
    attrs: {
      "src": _vm.callObjInfo.image
    },
    on: {
      "error": function ($event) {
        $event.target.src = _vm.userDp;
      }
    }
  })]), _c('div', {
    staticClass: "user-info"
  }, [_c('div', {
    staticClass: "call-type"
  }, [_vm._v(" " + _vm._s(!_vm.callObjInfo.incall ? _vm.callObjInfo.incoming ? 'Incoming Audio Call....' : 'Outgoing Audio Call....' : _vm.isActiveCall ? 'Active Call' : 'Call on Hold') + " ")]), _c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callObjInfo.name)))]), _c('div', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.callObjInfo.sub_info)))])])]), !_vm.callObjInfo.incall && _vm.callObjInfo.incoming ? [_c('div', {
    staticClass: "CallNotification-right",
    on: {
      "click": function ($event) {
        return _vm.onAccept();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-IncomingCall-audio-icon",
      "width": "40",
      "height": "40"
    }
  })], 1), _c('div', {
    staticClass: "CallNotification-right",
    on: {
      "click": function ($event) {
        return _vm.session.hangup();
      }
    }
  }, [_c('vb-icon', {
    staticClass: "cursor_pointer",
    attrs: {
      "icon": "video-call-hangupIcon-icon",
      "width": "40",
      "height": "40"
    }
  })], 1)] : [_c('div', {
    staticClass: "CallNotification-right",
    on: {
      "click": function ($event) {
        return _vm.$emit('call-play');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": _vm.isActiveCall ? 'mobile-currentCall-play-icon' : 'mobile-currentCall-pause-icon',
      "width": "30",
      "height": "30"
    }
  })], 1)]], 2)]);

}
var staticRenderFns = []

export { render, staticRenderFns }