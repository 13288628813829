<template>
  <div class="crm-input-container CallType">
    <label>{{label}}</label>
    <Multiselect 
      :value="value"
      @input="$emit('change',$event)"
      label="display_name" 
      track-by="id" 
      placeholder="Type to search"
      open-direction="bottom" 
      :options="response.contacts" 
      :multiple="true" 
      :searchable="true" 
      :loading="api.fetch_contacts.send"
      :internal-search="false" 
      :clear-on-select="false" 
      :close-on-select="false" 
      :options-limit="300"
      :limit="3" 
      :max-height="600" 
      :show-no-results="false" 
      :hide-selected="true"
      :disabled="disabled"
      @search-change="fetchContacts($event)"
    >
      <template #noResult>
        <span>There is no Contacts</span>
      </template>
      <template #noOptions>
        <span>There is no Contacts</span>
      </template>
    </Multiselect>
    <slot name="errors" />
  </div>
</template>

<script>
import { VOIP_API } from '@/utils';
import Multiselect from 'vue-multiselect'
export default {
  name: 'ContactSearchInput',
  components: {
    Multiselect,
  },
  model: {
    prop: 'value',
    event: 'change'
  },
  props: {
    value: {
      type: Array,
      default: ()=>([]),
    },
    label: {
      type: String,
      default: 'Contacts',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
  },
  data() {
    return {
      api: {
        fetch_contacts: this.$helperFunction.apiInstance(),
      },
      response: {
        contacts: [],
      },
    }
  },
  methods: {
    async fetchContacts(query) {
      this.api.fetch_contacts.source?.cancel?.("Requesting Again");
      this.api.fetch_contacts.source = require("axios").default.CancelToken.source()
      try {
        this.api.fetch_contacts.send=true
        const { data } = await VOIP_API.endpoints.crm.contacts.search({
          q: query
        },this.api.fetch_contacts.source.token)
        this.response.contacts=data
      } catch (ex) {
        if(!ex.cancel) {
          this.appNotify({
            message: ex.own_message,
            type: 'danger',
          })
        }
      } finally {
        this.api.fetch_contacts.send=false
      }
    }
  },
}
</script>

<style>

</style>