var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "dialer-settings-main"
  }, [_c('div', {
    staticClass: "d-flex align-items-center mb-3 calc-title-widthIOS v2"
  }, [_c('div', {
    staticClass: "settingsTextAndIconOnTop",
    on: {
      "click": function ($event) {
        return _vm.$emit('back');
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "backToSettingsList-IOSIcon",
      "width": "11.434",
      "height": "20"
    }
  }), _c('div', {
    staticClass: "settingsTextOnTop"
  }, [_vm._v("Settings")])], 1), _c('div', {
    staticClass: "dialer-settings-title topMainHeading"
  }, [_vm._v("Telephone numbers")])]), _c('section', {
    staticClass: "dialer-settings-section MyNumbers"
  }, [_c('div', {
    staticClass: "mobileInputIOS iconOnLeft mt-32px"
  }, [_c('b-input', {
    attrs: {
      "type": "text",
      "placeholder": "Search",
      "onkeypress": "return /[0-9\\-]/i.test(event.key)"
    },
    model: {
      value: _vm.filter.numbers.search,
      callback: function ($$v) {
        _vm.$set(_vm.filter.numbers, "search", $$v);
      },
      expression: "filter.numbers.search"
    }
  }), _c('vb-icon', {
    staticClass: "mobileInputIOS-icon",
    attrs: {
      "width": "13.785",
      "height": "13.726",
      "icon": "Search-IOSIcon"
    }
  })], 1), _c('div', {
    staticClass: "basicWhiteIOScard mt-32px"
  }, _vm._l(_vm.filternumbers, function (data) {
    return _c('div', {
      key: data.id,
      staticClass: "basicWhiteIOScard-item"
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "basicIOSIcon mr-16px"
    }, [_c('vb-icon', {
      attrs: {
        "icon": data.isdid ? 'MyNumbers-TelephoneNumber-IOSIcon' : 'MyNumbers-CallerID-IOSIcon'
      }
    })], 1), _c('div', {
      staticClass: "headingInsideTable"
    }, [_vm._v(_vm._s(_vm._f("number_formater")(data.number)))])]), !data.isdid ? _c('div', {
      staticClass: "rightAlignIosCheckbox"
    }, [_c('b-form-checkbox', {
      staticClass: "dialer_full_width_checkbox circle_btn_checkbox circle_btn_onRight users-lists-item-999 makeCheckBoxesSquare",
      attrs: {
        "checked": data.number == _vm.selected.caller_id,
        "value": data.number
      },
      on: {
        "change": function ($event) {
          return _vm.updateCallerID(data.number);
        }
      },
      model: {
        value: _vm.selected.caller_id,
        callback: function ($$v) {
          _vm.$set(_vm.selected, "caller_id", $$v);
        },
        expression: "selected.caller_id"
      }
    }, [_c('vb-icon', {
      attrs: {
        "icon": "MyNumbers-tick-IOSIcon",
        "width": "16.642",
        "height": "15.275"
      }
    })], 1)], 1) : _vm._e()]);
  }), 0), _c('InfiniteLoading', {
    ref: "numbers_infinity_loading",
    on: {
      "infinite": function ($event) {
        return _vm.fetchCIDAndDID();
      }
    },
    scopedSlots: _vm._u([{
      key: "error",
      fn: function (_ref) {
        var trigger = _ref.trigger;
        return [_c('div', [_c('span', [_vm._v(_vm._s(_vm.api.numbers.error_message))]), _c('b-icon', {
          staticClass: "ml-3",
          attrs: {
            "icon": "arrow-repeat",
            "variant": "primary",
            "font-scale": "1.5"
          },
          on: {
            "click": function ($event) {
              return trigger();
            }
          }
        })], 1)];
      }
    }])
  }, [_c('div', {
    attrs: {
      "slot": "spinner"
    },
    slot: "spinner"
  }, [_c('vb-loading', {
    attrs: {
      "type": "loader"
    }
  })], 1), _c('div', {
    attrs: {
      "slot": "no-more"
    },
    slot: "no-more"
  }), _c('div', {
    attrs: {
      "slot": "no-results"
    },
    slot: "no-results"
  }, [_c('vb-no-record', {
    attrs: {
      "text": 'There is no Numbers',
      "design": 3
    }
  })], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }