var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('h4', [_vm._v("Deleted Resources")]), _c('vb-table', {
    attrs: {
      "isListEmpty": _vm.response.deleted_resources.length == 0,
      "listLength": _vm.response.deleted_resources.length,
      "loading": _vm.api.fetch_deleted_resources.send
    },
    scopedSlots: _vm._u([{
      key: "body",
      fn: function (_ref) {
        var start = _ref.start,
          end = _ref.end;
        return _vm._l(_vm.response.deleted_resources, function (deleted_resource, index) {
          return _c('tr', {
            directives: [{
              name: "show",
              rawName: "v-show",
              value: index >= start && index <= end,
              expression: "index >= start && index <= end"
            }],
            key: deleted_resource.id
          }, [_c('td', {
            staticClass: "dialer-col-center wd-25",
            attrs: {
              "td": ""
            }
          }, [_vm._v(" " + _vm._s(deleted_resource.resource_type) + " ")]), _c('td', {
            staticClass: "dialer-col-center wd-25",
            attrs: {
              "td": ""
            }
          }, [_vm._v("Original Loction")]), _c('td', {
            staticClass: "dialer-col-center wd-25",
            attrs: {
              "td": ""
            }
          }, [_vm._v(" " + _vm._s(deleted_resource.createdAt) + " ")])]);
        });
      }
    }])
  }, [_c('tr', {
    attrs: {
      "slot": "header"
    },
    slot: "header"
  }, [_c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Name")])]), _c('th', {
    staticClass: "dialer-has-sort dialer-col-center"
  }, [_c('span', [_vm._v("Original Location")])]), _c('th', {
    staticClass: "dialer-has-sort"
  }, [_c('span', [_vm._v("Created Date")])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }