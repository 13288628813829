var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    class: `dialer-main-loader ${_vm.GET_LOCAL_SETTING_IS_DARK_MODE && !_vm.$store.getters.getIsMobile ? 'darkModeV2 v2' : ''}`
  }, [_c('img', {
    attrs: {
      "src": `${_vm.GET_LOCAL_SETTING_IS_DARK_MODE && !_vm.$store.getters.getIsMobile ? _vm.loaderV_5_darkMode_5 : _vm.loaderV_5}`,
      "alt": ""
    }
  })]);

}
var staticRenderFns = []

export { render, staticRenderFns }