<template>
  <div class="dialer-record-sound-modal2">
    <modal 
      class="dialer_animation center-zoomIn-popup v2 m-w-400 IosModal" 
      height="auto" 
      :name="modalName" 
      :clickToClose="false" 
      @before-open="onBeforeOpen" 
      @before-close="onBeforeClose"
    >
      <template>
        <div class="d-flex align-items-center mb-3 calc-title-widthIOS v2">
          <div class="settingsTextAndIconOnTop" @click="recorded.audio.is_start || api.upload.send ? '' : $modal.hide(modalName)">
            <vb-icon icon="backToSettingsList-IOSIcon" width="11.434" height="20" @click="recorded.audio.is_start || api.upload.send ? '' : $modal.hide(modalName)"/>
          </div>
          <div class="dialer-settings-title topMainHeading">Voice Recorder</div>
          <div class="plus-IOSIcon-container"></div>
        </div>
      </template>
      <div class="innerModalIos">
        
        <div class="d-flex justify-content-center w-100 mt-64px">
          <vb-icon icon="voiceRecorderMic-IOSIcon" width="148px" height="148px" />
        </div>
        <div class="recorderTimeHeading mt-32px" v-if="recorded.audio.is_start">
          {{ recorded | get_property('audio.time',0) | time_audio(true) }}
        </div>
        <div id="test-wave-889" :class="`bill-waves mt-32px ${recorded.audio.is_start ? '' : 'originalState'}`">
          <div 
            v-for="(data,index) in waves" 
            :key="index" 
            :id="`test-wave-889-${index}`" 
            :data-value="data" 
            class="bill-wave-item"
          />
        </div>
        <div class="headingInsideTable text-center mt-32px">{{ !recorded.audio.is_start ? 'Record Your Voice' : 'Recording...' }}</div>
        <div class="d-flex justify-content-between align-items-center w-100 mt-32px childWidth60px py-4px">
          <vb-audio-player :class="`IosAudioPlayer biggerIcon ${recorded.audio.is_start ? 'disabled':''}`" :disabled="recorded.audio.is_start" :src="recorded | get_property('audio.audio.audioUrl')" />
          <div class="IosRecordIcon-container">
            <b-icon v-if="!recorded.audio.is_start" class="circle-fill" icon="circle-fill" :class="{'disabled':api.upload.send}" @click="api.upload.send ? '' : recorded.audio.is_start=true" />
            <b-icon v-else icon="pause-fill" class="pause-fill" @click="recorded.audio.is_start=false;" />
          </div>
          <div>
            <template v-if="!recorded.audio.is_start && recorded.audio.audio.audioUrl">
              <div class="saveText" v-if="!api.upload.send" @click="onSave()">Save</div>
              <span v-else>
                <b-icon icon="arrow-clockwise" class="arrow-clockwise" animation="spin-pulse" />
                <p v-if="api.upload.upload_progress.is_process">{{ `${api.upload.upload_progress.percentage}%` }}</p>
              </span>
            </template>
          </div>
        </div>

      </div>
    </modal>
  </div>
</template>

<script>
import { $fn, permissions } from '@/utils/index';

var audio_recording_interval_id;
let volume = [100,30,80,50,130,70,150]

export default {
  name: "AudioRecording",
  props: {
    modalName: {
      type: String,
      default: 'AudioRecording',
    },
  },
  data() {
    return {
      recorded: {
        audio: {
          is_show: false,
          is_start: false,
          time: 0,
          is_cancelled: false,
          recorder: null,
          audio: {},
          reset(){
            this.is_show=false
            this.is_start=false
            this.time=0
            this.is_cancelled=false
            this.recorder=null
            this.audio={}
          },
        },
      },
      permissions: {},
      title: '',
      extra_data: {},
      api: {
        upload: this.$helperFunction.apiInstance({ error_message: true, upload_progress: true, }),
        reset(){
          this.upload?.reset?.()
        },
      },
    };
  },
  computed: {
    waves(){ 
      return new Array(25).fill(30).map(()=>volume[Math.floor(Math.random() * volume.length)]) 
    },
    modalTitle(){ return this.title || 'VoIP Record Voice' },
  },
  watch: {
    async "recorded.audio.is_start"(is_start) {
      let vm = this;
      function visulizing(frequency_array){
        const values = Object.values(frequency_array);
        vm.waves.forEach((val,i)=>{
          const value = (values[val] || 1)/255;
          const el = document.getElementById(`test-wave-889-${i}`)
          if(el){
            el.style.transform = `scaleY(${Math.min(3.5,value)})`;
            // el.style.opacity = Math.max(.25,value);
          }
        })
      }
      if (is_start) {
        this.recorded.audio.recorder = await $fn.recordAudio({
          visulizing,
        });
        this.recorded.audio.time = 0;
        this.recorded.audio.recorder.start();
        audio_recording_interval_id = setInterval(() => {
          vm.recorded.audio.time = vm.recorded.audio.time + 1;
        }, 1 * 1000);
      } else if(this.recorded.audio.recorder) {
        const audio = await this.recorded.audio.recorder.stop();
        visulizing({})
        clearInterval(audio_recording_interval_id);
        // if (!this.recorded.audio.is_cancelled) {
          this.recorded.audio.audio = audio
        // }
        this.recorded.audio.is_show = false;
      }
    },
  },
  methods: {
    onBeforeOpen(event){
      this.extra_data = event?.params?.extra_data ?? {}
      this.title = event?.params?.title ?? ''
    },
    onBeforeClose(){
      this.recorded.audio.reset()
      this.extra_data = {}
      this.title = ''
      this.api.reset()
    },
    onSave(){
      let vm = this
      vm.api.upload.send=true
      this.$emit("audio", { 
        blob: vm.recorded.audio.audio.audioBlob, 
        url: vm.recorded.audio.audio.audioUrl, 
        extra_data: {
          ...vm.extra_data,
        },
        hide(){
          vm.api.upload.send=false
          vm.$modal.hide(vm.modalName);
        },
        error(message){
          vm.api.upload.send=false
          vm.api.upload.error_message=message
        },
        uploadProgress(event){
          vm.api.upload.upload_progress.onProgress(event)
        },
      });
    },
  },
  created(){
    this.permissions = permissions
  },
};
</script>

<style lang="scss">
.bill-waves {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  .bill-wave-item {
    display: inline-block;
    width: 3px;
    height: 100px;
    margin: 0 4px;
    background: #5576D1;
    transform: scaleY(.5);
  }
}
.v2.m-w-400.IosModal{
  & > .vm--modal{
    padding: 0px;
    min-height: 100vh !important;
    height: 100vh !important;
    min-width: 100vw !important;
    width: 100vw !important;
    @include border-radius(0px !important);
    left: 0 !important;
    right: 0;
    top: 0 !important;
  }
}




















.dialer-record-sound-modal2 {
  .vm--container {
    .vm--modal {
      padding: 0 40px 20px;
      min-height: 400px;
      min-width: 300px;
      @include border-radius($border-radius);
      left: unset !important;
      right: 30%;
      top: 25% !important;
    }
  }
}
</style>

<style scoped>
.test-wave-889 {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
}
.test-wave-889 > div {
  display: inline-block;
  width: 3px;
  height: 100px;
  margin: 0 7px;

  background: #5576D1;
  transform: scaleY(.5);
}
</style>
