<template>
  <div class="top-main-hrm">
    <section>
      <div class="bg-hrm-mike scrollow1">
        <div class="d-flex justify-content-between align-items-start">
          <div class="w-75 for-home-dd-width d-flex flex-wrap">     
            
            
          </div> 

          <div class="w-25 top-btn-align">
            <button class="btn-svg-none" @click="design.grid=false">
              <svg id="Group_16231" data-name="Group 16231" xmlns="http://www.w3.org/2000/svg" class ="mr-top-sidebsvg" width="12.033" height="15.016" viewBox="0 0 12.033 15.016">
                  <path id="Icon_ionic-md-arrow-dropright" data-name="Icon ionic-md-arrow-dropright" d="M0,0,6.016,6.016,0,12.033Z" transform="translate(12.033 9) rotate(90)" fill="#5576d1"/>
                  <path id="Icon_ionic-md-arrow-dropright-2" data-name="Icon ionic-md-arrow-dropright" d="M0,0,6.016,6.016,0,12.033Z" transform="translate(0 6.016) rotate(-90)" fill="#5576d1"/>
              </svg>
            </button>
            <button class="btn-svg-none" @click="design.grid=true">
              <svg id="Icon_ionic-ios-menu" data-name="Icon ionic-ios-menu" xmlns="http://www.w3.org/2000/svg" class ="mr-top-sidebsvg" width="21.607" height="15.75" viewBox="0 0 21.607 15.75">
                  <path id="Path_16" data-name="Path 16" d="M25.207,12.375H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,12.375Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
                  <path id="Path_17" data-name="Path 17" d="M25.207,19.125H5.4A1.035,1.035,0,0,1,4.5,18h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,19.125Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
                  <path id="Path_18" data-name="Path 18" d="M25.207,25.875H5.4a1.035,1.035,0,0,1-.9-1.125h0a1.035,1.035,0,0,1,.9-1.125H25.207a1.035,1.035,0,0,1,.9,1.125h0A1.035,1.035,0,0,1,25.207,25.875Z" transform="translate(-4.5 -10.125)" fill="#5576d1"/>
              </svg>
            </button>
          </div>

        </div>
        <div class="d-flex">
          <div
            class="mr-1 d-flex" 
            v-for="(data, index) in breadcrumb" 
            :key="data.id" 
            @click="groupId==data.id ? '' : $emit('select-group-id',data.id)"
          >
            {{ data.name }} <div class="ml-1" v-if="index+1!=breadcrumb.length"> / </div>
          </div>
        </div>
        <div class="card-container-main-box mt-5">
          <template v-if="design.grid">
            <div class="card-contain" v-for="data in filterList" :key="data.unique_id" @click="data.type=='group'?$emit('select-group-id',data.id):''">
              <b-icon :icon="data.type=='group'?'folder-fill':'file-text-fill'" />
              <div class="decription-img-box-home">{{ data.name }}</div>
            </div>
            <vb-no-record v-if="filterList.length==0" :text="''" :design="3">
              <vb-loading v-if="isLoading" class="text-center w-100" slot="loading" />
            </vb-no-record>
          </template>
          <template v-else>
            <vb-table 
              :isListEmpty="filterList.length==0" 
              :listLength="filterList.length" 
              :loading="isLoading" 
            >
              <tr slot="header">
                <th class="dialer-has-sort">
                  <span>Item</span>
                </th>
                <th class="dialer-has-sort dialer-col-center">
                  <span>Date Modified</span>
                </th>
                <th class="dialer-has-sort dialer-col-center">
                  <span>Parent Folder</span>
                </th>
              </tr>
              <template #body="{ start, end }">
                <tr v-for="(data, index) in filterList" :key="data.unique_id" v-show="index >= start && index <= end">
                  <td class="dialer-row-title dialer-col-name-width wd-75">
                    <div class="d-flex dialer-numberWithCountry">
                      <span class="m-0 didNumber">{{ data.name }}</span>
                    </div>
                  </td>
                  <td class="dialer-col-center wd-25">{{ '' }}</td>
                  <td class="dialer-col-center wd-25">{{ '' }}</td>
                </tr>
              </template>
            </vb-table>
          </template>
        </div>
      
      </div>
    </section>
  </div>
</template>

<script>
import { VOIP_API } from '@/utils'
import { mapGetters } from 'vuex'
import { GET_PAMARLY_ORGANIZATION_ID } from '@/store/helper/getters'
export default {
  name: 'PamarlyFolderBoard',
  props: {
    groupId: {
      type: [String,Number],
      default: '',
    },
    breadcrumb: {
      type: Array,
      default: ()=>[],
    },
  },
  data(){
    return {
      api: {
        fetch_resources_groups: this.$helperFunction.apiInstance(),
        fetch_resources: this.$helperFunction.apiInstance(),
        fetch_main_groups: this.$helperFunction.apiInstance(),
      },
      response: {
        resources: [],
        resources_groups: [],
        main_groups: [],
      },
      design: {
        grid: true,
      },
    }
  },
  inject: [
    'appNotify',
  ],
  computed: {
    ...mapGetters([
      GET_PAMARLY_ORGANIZATION_ID
    ]),
    isLoading(){ return this.groupId ? this.api.fetch_resources.send && this.api.fetch_resources_groups.send : this.api.fetch_main_groups.send },
    filterList(){
      let list = []
      if(this.groupId){
        let resources = this.response.resources.map(i=>({
          name: i.resource_name,
          id: i.id,
          unique_id: `resource-${i.id}`,
          type: 'resource'
        }))
        let groups = this.response.resources_groups.map(i=>({
          name: i.dataValues.resource_type,
          id: i.dataValues.id,
          unique_id: `group-${i.dataValues.id}`,
          type: 'group'
        }))
        list = [...groups,...resources]
      } else {
        list = this.response.main_groups.map(i=>({
          name: i.resource_type,
          id: i.id,
          unique_id: `group-${i.id}`,
          type: 'group'
        }))
      }
      return list
    },
  },
  watch: {
    groupId: {
      immediate: true,
      handler(value){
        this.response.main_groups=[]
        this.response.resources_groups=[]
        this.response.resources=[]
        if(value){
          // TODO - against group id fetch groups and resources
          this.fetchResources()
          this.fetchResourcesGroups()
        } else {
          // TODO - main groups fetch
          this.fetchMainGroups()
        }
      },
    },
  },
  methods: {
    fetchResourcesGroups(){
      //TODO - fetch resources groups
      let vm = this 
      vm.api.fetch_resources_groups.send=true
      VOIP_API.endpoints.pamarly.resources.subGroups(vm.groupId)
      .then(({ data: { result: groups } })=>{
        vm.response.resources_groups=groups
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.fetch_resources_groups.send=false
      })
    },
    fetchResources(){
      // TODO - fetch resources
      let vm = this 
      vm.api.fetch_resources.send=true
      VOIP_API.endpoints.pamarly.resources.groupResources({
        typeId: vm.groupId
      })
      .then(({ data: { result: { rows: resources } } })=>{
        vm.response.resources=resources
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.fetch_resources.send=false
      })
    },
    fetchMainGroups(){
      // TODO - fetch main groups
      let vm = this 
      vm.api.fetch_main_groups.send=true
      VOIP_API.endpoints.pamarly.resources.mainGroups({
        organizationId: vm.GET_PAMARLY_ORGANIZATION_ID
      })
      .then(({ data: { result: groups } })=>{
        vm.response.main_groups=groups
      })
      .catch((ex)=>{
        vm.appNotify({
          message: ex.own_message,
          type: 'danger',
        })
      })
      .finally(()=>{
        vm.api.fetch_main_groups.send=false
      })
    },
  },
}
</script>

<style>

</style>