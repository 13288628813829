
import { VOIP_API } from "../index"

export const PICKUPGROUP_API_ENDPOINTS = {
  list(){
    return VOIP_API.axios.voip.request({
      url: "pickupgroups",
      method: "GET",
    })
  },
  create(data={}){
    return VOIP_API.axios.voip.request({
      url: "pickupgroups",
      method: "POST",
      data,
    })
  },
  delete(id='',data={}){
    return VOIP_API.axios.voip.request({
      url: `pickupgroups/${id}`,
      method: "DELETE",
      data,
    })
  },
  members(id){
    return VOIP_API.axios.voip.request({
      url: `pickupgroups/members/${id}`,
      method: "GET",
    })
  },
  updatemembers(id,data={}){
    return VOIP_API.axios.voip.request({
      url: `pickupgroups/${id}`,
      method: "PATCH",
      data,
    })
  },
  updatelabel(data={}){
    return VOIP_API.axios.voip.request({
      url: `update-pickup-group-basic`,
      method: "POST",
      data,
    })
  },
}