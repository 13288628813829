var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('modal', {
    staticClass: "dialer_animation right_side_popup m-w-500 EditNumbersModal min-width-50percent-modal",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": function ($event) {
        return _vm.onBeforeOpen($event);
      },
      "before-close": function ($event) {
        return _vm.onBeforeClose($event);
      }
    }
  }, [_c('section', {
    staticClass: "dialer-number-edit"
  }, [_c('div', {
    staticClass: "dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header flex-column pb-1 align-items-start"
  }, [_c('div', {
    staticClass: "d-flex justify-content-between align-items-center w-100"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-settings-title newer"
  }, [_vm._v("User based call routing")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newDoneButton",
    attrs: {
      "type": "button"
    },
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-done-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart"
  }, [_vm._v("Done")])], 1)])]), _c('p', {
    staticClass: "vm--modal-text"
  }, [_vm._v("Customise your profile preferences on this page")])]), _c('div', {
    staticClass: "latestGreyBox-9-9-2023 mt-20px"
  }, [_vm.ringgroupAccountcode ? _c('div', [_c('div', [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox"
  }, [_c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row"
  }, [_c('div', {
    staticClass: "d-flex flex-column w-100"
  }, [_c('div', {
    staticClass: "latestGreyBox-heading-main"
  }, [_vm._v("Incoming calls")]), _c('div', {
    staticClass: "latestGreyBox-descriptionText onlyfirstLetterCapital restLowerCase"
  }, [_vm._v(" When " + _vm._s(_vm._f("number_formater")(_vm.data.number)) + " is called it will ring to the following Users... ")]), _c('div', {
    staticClass: "IVR-numbers-container d-flex align-items-center flex-wrap mt-20px mb-20px"
  }, _vm._l(_vm.data.members, function (member) {
    return _c('div', {
      key: member.id,
      staticClass: "newIVRLayout-number-item hasUuersInIt"
    }, [_c('Info', {
      attrs: {
        "is_blf": false,
        "id": member.cg_item
      }
    })], 1);
  }), 0), _c('div', {
    staticClass: "latestGreyBox-descriptionText"
  }, [_c('b', [_vm._v("Note:")]), _vm._v(" The above users have access to the settings below ")])])]), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside"
  }, [!!_vm.ringgroupAccountcode ? _c('CallForwardingSetting', {
    attrs: {
      "account": _vm.ringgroupAccountcode,
      "forwardCallerId": true
    },
    on: {
      "cf-updated": function ($event) {
        return _vm.$emit('cf-updated');
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside pt-0 dialer_box_text_my-0"
  }, [!!_vm.ringgroupAccountcode ? _c('ScheduleSetting', {
    attrs: {
      "account": _vm.ringgroupAccountcode
    },
    on: {
      "ofh-days-updated": function ($event) {
        return _vm.$emit('ofh-days-updated');
      },
      "ofh-default-updated": function ($event) {
        return _vm.$emit('ofh-default-updated');
      },
      "ofh-toggle-updated": function ($event) {
        return _vm.$emit('ofh-toggle-updated');
      }
    }
  }) : _vm._e()], 1), _c('div', {
    staticClass: "whiteBoxWithBorderInsideGreyBox-row somethingInside dialer_box_text_my-0"
  }, [_vm.ringgroupAccountcode ? _c('MusicOnHoldWidget', {
    attrs: {
      "accountcode": _vm.ringgroupAccountcode
    }
  }) : _vm._e()], 1)])]), _vm.ringgroupAccountcode ? _c('VoicemailSetting', {
    staticClass: "VoicemailSetting-insideTeamsModal",
    attrs: {
      "accountcode": _vm.ringgroupAccountcode
    }
  }) : _vm._e()], 1) : _vm._e()])])])])], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }