var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', [_c('v-tour', {
    class: `${_vm.dashboard.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.dashboard.name,
      "steps": _vm.dashboard.steps,
      "options": _vm.dashboard.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.dashboard.name,
            started: true
          });
          _vm.onStartTour(_vm.dashboard.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.dashboard.name,
            started: false
          });
          _vm.onSkipTour(_vm.dashboard.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.dashboard.name,
            started: false
          });
          _vm.onFinishTour(_vm.dashboard.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.dashboard.name,
            started: false
          });
          _vm.onStopTour(_vm.dashboard.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.dialer.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.dialer.name,
      "steps": _vm.dialer.steps,
      "options": _vm.dialer.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.dialer.name,
            started: true
          });
          _vm.onStartTour(_vm.dialer.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.dialer.name,
            started: false
          });
          _vm.onSkipTour(_vm.dialer.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.dialer.name,
            started: false
          });
          _vm.onFinishTour(_vm.dialer.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.dialer.name,
            started: false
          });
          _vm.onStopTour(_vm.dialer.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.chat.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.chat.name,
      "steps": _vm.chat.steps,
      "options": _vm.chat.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.chat.name,
            started: true
          });
          _vm.onStartTour(_vm.chat.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.chat.name,
            started: false
          });
          _vm.onSkipTour(_vm.chat.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.chat.name,
            started: false
          });
          _vm.onFinishTour(_vm.chat.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.chat.name,
            started: false
          });
          _vm.onStopTour(_vm.chat.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.meeting.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.meeting.name,
      "steps": _vm.meeting.steps,
      "options": _vm.meeting.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.meeting.name,
            started: true
          });
          _vm.onStartTour(_vm.meeting.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.meeting.name,
            started: false
          });
          _vm.onSkipTour(_vm.meeting.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.meeting.name,
            started: false
          });
          _vm.onFinishTour(_vm.meeting.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.meeting.name,
            started: false
          });
          _vm.onStopTour(_vm.meeting.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.analytics.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.analytics.name,
      "steps": _vm.analytics.steps,
      "options": _vm.analytics.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.analytics.name,
            started: true
          });
          _vm.onStartTour(_vm.analytics.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.analytics.name,
            started: false
          });
          _vm.onSkipTour(_vm.analytics.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.analytics.name,
            started: false
          });
          _vm.onFinishTour(_vm.analytics.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.analytics.name,
            started: false
          });
          _vm.onStopTour(_vm.analytics.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.monitor.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.monitor.name,
      "steps": _vm.monitor.steps,
      "options": _vm.monitor.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.monitor.name,
            started: true
          });
          _vm.onStartTour(_vm.monitor.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.monitor.name,
            started: false
          });
          _vm.onSkipTour(_vm.monitor.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.monitor.name,
            started: false
          });
          _vm.onFinishTour(_vm.monitor.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.monitor.name,
            started: false
          });
          _vm.onStopTour(_vm.monitor.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.settings.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.settings.name,
      "steps": _vm.settings.steps,
      "options": _vm.settings.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.settings.name,
            started: true
          });
          _vm.onStartTour(_vm.settings.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.settings.name,
            started: false
          });
          _vm.onSkipTour(_vm.settings.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.settings.name,
            started: false
          });
          _vm.onFinishTour(_vm.settings.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.settings.name,
            started: false
          });
          _vm.onStopTour(_vm.settings.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.media_setting.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.media_setting.name,
      "steps": _vm.media_setting.steps,
      "options": _vm.media_setting.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.media_setting.name,
            started: true
          });
          _vm.onStartTour(_vm.media_setting.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.media_setting.name,
            started: false
          });
          _vm.onSkipTour(_vm.media_setting.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.media_setting.name,
            started: false
          });
          _vm.onFinishTour(_vm.media_setting.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.media_setting.name,
            started: false
          });
          _vm.onStopTour(_vm.media_setting.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.speed_dial.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.speed_dial.name,
      "steps": _vm.speed_dial.steps,
      "options": _vm.speed_dial.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.speed_dial.name,
            started: true
          });
          _vm.onStartTour(_vm.speed_dial.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.speed_dial.name,
            started: false
          });
          _vm.onSkipTour(_vm.speed_dial.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.speed_dial.name,
            started: false
          });
          _vm.onFinishTour(_vm.speed_dial.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.speed_dial.name,
            started: false
          });
          _vm.onStopTour(_vm.speed_dial.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.users.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.users.name,
      "steps": _vm.users.steps,
      "options": _vm.users.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.users.name,
            started: true
          });
          _vm.onStartTour(_vm.users.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.users.name,
            started: false
          });
          _vm.onSkipTour(_vm.users.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.users.name,
            started: false
          });
          _vm.onFinishTour(_vm.users.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.users.name,
            started: false
          });
          _vm.onStopTour(_vm.users.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.teams.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.teams.name,
      "steps": _vm.teams.steps,
      "options": _vm.teams.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.teams.name,
            started: true
          });
          _vm.onStartTour(_vm.teams.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.teams.name,
            started: false
          });
          _vm.onSkipTour(_vm.teams.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.teams.name,
            started: false
          });
          _vm.onFinishTour(_vm.teams.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.teams.name,
            started: false
          });
          _vm.onStopTour(_vm.teams.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.address_book.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.address_book.name,
      "steps": _vm.address_book.steps,
      "options": _vm.address_book.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.address_book.name,
            started: true
          });
          _vm.onStartTour(_vm.address_book.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.address_book.name,
            started: false
          });
          _vm.onSkipTour(_vm.address_book.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.address_book.name,
            started: false
          });
          _vm.onFinishTour(_vm.address_book.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.address_book.name,
            started: false
          });
          _vm.onStopTour(_vm.address_book.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.help.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.help.name,
      "steps": _vm.help.steps,
      "options": _vm.help.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.help.name,
            started: true
          });
          _vm.onStartTour(_vm.help.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.help.name,
            started: false
          });
          _vm.onSkipTour(_vm.help.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.help.name,
            started: false
          });
          _vm.onFinishTour(_vm.help.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.help.name,
            started: false
          });
          _vm.onStopTour(_vm.help.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.sip_call.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.sip_call.name,
      "steps": _vm.sip_call.steps,
      "options": _vm.sip_call.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.sip_call.name,
            started: true
          });
          _vm.onStartTour(_vm.sip_call.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.sip_call.name,
            started: false
          });
          _vm.onSkipTour(_vm.sip_call.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.sip_call.name,
            started: false
          });
          _vm.onFinishTour(_vm.sip_call.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.sip_call.name,
            started: false
          });
          _vm.onStopTour(_vm.sip_call.name);
        }
      }
    }
  }), _c('v-tour', {
    class: `${_vm.jitsi_call.started ? 'vm--overlay' : ''}`,
    attrs: {
      "name": _vm.jitsi_call.name,
      "steps": _vm.jitsi_call.steps,
      "options": _vm.jitsi_call.options,
      "callbacks": {
        onStart: function () {
          _vm.setTourStarted({
            type: _vm.jitsi_call.name,
            started: true
          });
          _vm.onStartTour(_vm.jitsi_call.name);
        },
        onSkip: function () {
          _vm.setTourStarted({
            type: _vm.jitsi_call.name,
            started: false
          });
          _vm.onSkipTour(_vm.jitsi_call.name);
        },
        onFinish: function () {
          _vm.setTourStarted({
            type: _vm.jitsi_call.name,
            started: false
          });
          _vm.onFinishTour(_vm.jitsi_call.name);
        },
        onStop: function () {
          _vm.setTourStarted({
            type: _vm.jitsi_call.name,
            started: false
          });
          _vm.onStopTour(_vm.jitsi_call.name);
        }
      }
    }
  })], 1);

}
var staticRenderFns = []

export { render, staticRenderFns }