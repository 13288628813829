
import { VOIP_API } from "../index"

export const TECH_PREFIX_API_ENDPOINTS = {
  get(params={}){
    return VOIP_API.axios.voip.request({
      method: "GET",
      url: "techprefixes",
      params,
    })
  },
  update(id,data={}){
    return VOIP_API.axios.voip.request({
      method: "PATCH",
      url: `techprefixes/${id}`,
      data,
    })
  },
  change(data={}){
    return VOIP_API.axios.voip.request({
      method: "PATCH",
      url: `techprefixes`,
      data,
    })
  },
}