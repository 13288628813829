var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('modal', {
    staticClass: "dialer_animation right_side_popup",
    attrs: {
      "width": "50%",
      "height": "auto",
      "scrollable": true,
      "name": _vm.modalName
    },
    on: {
      "before-open": _vm.onBeforeOpen,
      "before-close": _vm.onBeforeClose
    }
  }, [_c('div', {
    staticClass: "dialer-assign-to md-pad-bottom dialer-edit"
  }, [_c('div', {
    staticClass: "dialer-edit-header"
  }, [_c('div', [_c('h2', {
    staticClass: "dialer-edit-title"
  }, [_vm._v("Send Invites")])]), _c('div', {
    staticClass: "dialer-edit-actions"
  }, [_c('button', {
    staticClass: "newCloseButton w-auto",
    on: {
      "click": function ($event) {
        return _vm.$modal.hide(_vm.modalName);
      }
    }
  }, [_c('vb-icon', {
    attrs: {
      "icon": "squared-close-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newCloseButton-textPart"
  }, [_vm._v("Close")])], 1)])]), _c('b-form', {
    on: {
      "submit": function ($event) {
        $event.preventDefault();
        return _vm.inviteMember();
      }
    }
  }, [_c('div', {
    staticClass: "dialer-box grayBox inModal"
  }, [_c('div', {
    staticClass: "dialer-box-header mb-4"
  }, [_vm._v("Enter Email")]), _vm._l(_vm.forms.invite_persons.emails, function (data, index) {
    return _c('b-form-group', {
      key: index
    }, [_c('div', {
      staticClass: "d-flex align-items-center"
    }, [_c('div', {
      staticClass: "whiteBGinputWithGreyRoundedBorder white-noShadow w-100-30 mb-3"
    }, [_c('b-form-input', {
      staticClass: "w-100",
      attrs: {
        "disabled": _vm.api.invite_persons.send
      },
      model: {
        value: data.email,
        callback: function ($$v) {
          _vm.$set(data, "email", $$v);
        },
        expression: "data.email"
      }
    })], 1), _vm.forms.invite_persons.emails.length > 1 ? _c('b-icon', {
      staticClass: "ml-2 mb-3 cursor_pointer",
      attrs: {
        "font-scale": "1.3",
        "icon": "trash-fill"
      },
      on: {
        "click": function ($event) {
          return _vm.forms.invite_persons.emails.splice(index, 1);
        }
      }
    }) : _vm._e()], 1), _vm.forms.invite_persons.submitted && _vm.$v.forms.invite_persons.emails.$each[index].email.$invalid ? _c('p', {
      staticClass: "text text-danger animated bounceIntop d-flex flex-column"
    }, [!_vm.$v.forms.invite_persons.emails.$each[index].email.required ? _c('span', {
      staticClass: "w-100"
    }, [_vm._v("* email is required")]) : _vm._e(), !_vm.$v.forms.invite_persons.emails.$each[index].email.email ? _c('span', {
      staticClass: "w-100"
    }, [_vm._v("* email is invalid")]) : _vm._e()]) : _vm._e()]);
  }), _vm.forms.invite_persons.submitted && _vm.$v.forms.invite_persons.emails.$invalid ? _c('p', {
    staticClass: "text text-danger animated bounceIntop d-flex flex-column"
  }, [!_vm.$v.forms.invite_persons.emails.required ? _c('span', [_vm._v("* emails is required")]) : _vm._e(), !_vm.$v.forms.invite_persons.emails.minLength ? _c('span', [_vm._v("* emails should be minimum " + _vm._s(_vm.$v.forms.invite_persons.emails.$params.minLength.min))]) : _vm._e()]) : _vm._e(), _c('div', {
    staticClass: "d-flex justify-content-center"
  }, [_c('button', {
    staticClass: "dialer-button dialer-button-primary",
    on: {
      "click": function ($event) {
        return _vm.forms.invite_persons.emails.push({
          email: ''
        });
      }
    }
  }, [_c('b-icon', {
    staticClass: "mr-2",
    attrs: {
      "icon": "plus",
      "scale": "1.3"
    }
  }), _vm._v(" Add ")], 1)])], 2), _c('div', {
    staticClass: "d-flex justify-content-end w-100"
  }, [_c('b-button', {
    staticClass: "newDoneButton",
    attrs: {
      "disabled": _vm.api.invite_persons.send,
      "type": "submit"
    }
  }, [_c('vb-icon', {
    staticClass: "submitIcon",
    attrs: {
      "icon": "squared-submit-icon",
      "height": "38px",
      "width": "38px"
    }
  }), _c('span', {
    staticClass: "newDoneButton-textPart d-flex justify-content-center position-relative"
  }, [_vm.api.invite_persons.send ? _c('vb-spinner') : [_vm._v("Submit")]], 2)], 1)], 1)])], 1)]);

}
var staticRenderFns = []

export { render, staticRenderFns }