
import { VOIP_API } from "../index"

export const GIT_REPO_DESKTOP_APP_API_ENDPOINTS = {
  list(params={}){
    return VOIP_API.axios.git.request({
      method: "GET",
      url: `repos/:owner/:repo/releases`,
      params,
    })
  },
  latest(params={}){
    return VOIP_API.axios.git.request({
      method: "GET",
      url: `repos/:owner/:repo/releases/latest`,
      params,
    })
  },
}