var render = function render(){
  var _vm=this;
  var _h=_vm.$createElement;
  var _c=_vm._self._c||_h;

  return _c('div', {
    staticClass: "callActivity-Item-v2-container voicemailMessage",
    class: _vm.call.status === 'Dialed' ? 'Right' : 'Left'
  }, [_c('div', {
    staticClass: "callActivity-Item-v2-inner-top"
  }, [_c('div', {
    staticClass: "icon-container"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-popover",
      rawName: "v-b-popover.hover.top",
      value: 'Voicemail',
      expression: "'Voicemail'",
      modifiers: {
        "hover": true,
        "top": true
      }
    }],
    attrs: {
      "icon": 'CallActivityV2-audioMessage-icon',
      "height": "55.922",
      "width": "55.922"
    }
  })], 1), _c('div', {
    staticClass: "user-info"
  }, [_vm.call.is_shared == 'yes' ? _c('small', [_vm._v("Shared")]) : _vm._e(), _vm.call.caller_name == '-' ? [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.caller.replaceAll('"', ""))))]), _c('div', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.callee.replaceAll('"', ""))))])] : [_c('div', {
    staticClass: "user-name"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.caller_name.replaceAll('"', ""))))]), _c('div', {
    staticClass: "user-number"
  }, [_vm._v(_vm._s(_vm._f("number_formater")(_vm.call.caller.replaceAll('"', ""))))])]], 2), _c('div', {
    staticClass: "middle-section"
  }, [_c('span', {
    staticClass: "middle-section-text"
  }, [_vm.conditions.recording && _vm.GET_LOCAL_SETTING_DASHBOARD.design.width != 25 && _vm.call.voicemail ? _c('div', {
    staticClass: "dialer-audio-playback"
  }, [_c('vb-audio-player', {
    attrs: {
      "design": 'minimum',
      "src": _vm.call.voicemail
    }
  })], 1) : _vm._e()])]), _c('div', {
    staticClass: "callLogIcons-container"
  }, [_vm.conditions.actions ? [!_vm.assignedData ? _c('span', {
    staticClass: "mr-8px"
  }, [_c('vb-icon', {
    directives: [{
      name: "b-tooltip",
      rawName: "v-b-tooltip.hover",
      modifiers: {
        "hover": true
      }
    }],
    attrs: {
      "icon": "callInfo-callLog-icon",
      "width": "35px",
      "height": "35px",
      "title": `Message info`
    },
    on: {
      "click": function ($event) {
        return _vm.$emit('info');
      }
    }
  })], 1) : _vm._e()] : _vm._e()], 2), _c('div', {
    staticClass: "last-section"
  }, [_c('div', {
    staticClass: "time"
  }, [_c('span', [_vm._v(_vm._s(_vm._f("filter_date_current")(_vm.call.date, null, {
    unix: true,
    formate: 'h:mm a'
  })))]), _vm._t("more", function () {
    return [_vm.conditions.actions ? _c('b-dropdown', {
      staticClass: "ico-filter",
      attrs: {
        "varient": "link",
        "no-caret": "",
        "menu-class": "w-fit-content-imp"
      },
      scopedSlots: _vm._u([{
        key: "button-content",
        fn: function () {
          return [_c('b-icon', {
            attrs: {
              "icon": "three-dots",
              "variant": "#393939",
              "font-scale": "1.4"
            }
          })];
        },
        proxy: true
      }], null, false, 764454405)
    }, [!_vm.assignedData ? _c('b-dropdown-item', {
      on: {
        "click": function ($event) {
          return _vm.$emit('info');
        }
      }
    }, [_c('vb-icon', {
      staticClass: "callInfoIcon",
      attrs: {
        "icon": "dialer-callinfo-icon",
        "width": "15.7",
        "height": "15.701"
      }
    }), _c('span', {
      staticClass: "ml-3"
    }, [_vm._v("Message info")])], 1) : _vm._e()], 1) : _vm._e()];
  })], 2)])]), _vm.conditions.recording && _vm.GET_LOCAL_SETTING_DASHBOARD.design.width == 25 && _vm.call.voicemail ? _c('div', {
    staticClass: "callActivity-Item-v2-inner-2nd"
  }, [_c('div', {
    staticClass: "dialer-audio-playback"
  }, [_c('vb-audio-player', {
    attrs: {
      "src": _vm.call.voicemail
    }
  })], 1)]) : _vm._e()]);

}
var staticRenderFns = []

export { render, staticRenderFns }